import React from 'react';
import T from 'prop-types';

import { BottomDrawer } from '../base_ui';
import DISCLAIMER_DICTIONARY from './disclaimerDictionary';
import { StyledTertiaryHeader } from './styledComponents';

const Disclaimer = ({
  drawerType,
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { Component, title, ...propsToPassDown } = DISCLAIMER_DICTIONARY[drawerType] || {};
  const handleCloseDrawer = () => setIsDrawerOpen(false);
  return (
    <BottomDrawer
      handleClose={handleCloseDrawer}
      HeaderComponent={StyledTertiaryHeader}
      headerProps={{
        handleBack: handleCloseDrawer,
        title,
      }}
      isVisible={isDrawerOpen}
    >
      <Component handleCloseDrawer={handleCloseDrawer} {...propsToPassDown} />
    </BottomDrawer>
  );
};

Disclaimer.propTypes = {
  drawerType: T.string.isRequired,
  isDrawerOpen: T.bool.isRequired,
  setIsDrawerOpen: T.func.isRequired,
};

export default Disclaimer;
