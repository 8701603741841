import styled from 'styled-components/native';

import { P3 } from '../base_ui/Paragraphs';
import { moderateScale } from '../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  height: ${moderateScale(80)}px;
  margin: ${moderateScale(10)}px auto;
  width: 100%;
`;

export const IllustrationWrapper = styled.View`
  justify-content: center;
  margin-left: ${moderateScale(12)}px;
`;

export const MessageContainer = styled.View`
  align-self: center;
  flex: 1;
  padding: ${moderateScale(12)}px;
`;

export const StyledLink = styled(P3)`
  color: ${({ theme: { colors: { primary } } }) => primary};
  white-space: nowrap;
`;

export const StyledView = styled.View`
  padding: 0 ${moderateScale(24)}px;
`;
