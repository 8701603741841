import React, { useState } from 'react';
import T from 'prop-types';

import { LargeProgressButton } from '../../base_ui';
import Disclosures from '../../../containers/Disclosures';
import {
  ButtonsContainer,
  CheckboxContainer,
  ConditionsBorderlessButton,
  ConditionsHeading,
  PaperlessContainer,
  StyledCheckboxWithText,
  TextContainer,
} from './styledComponents';

const ConditionsView = ({
  disclosures,
  fetchDisclosureRequestStatus,
  handleOnSubmit,
  handleSetModalViewStep,
  paperlessBillingProps,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const { requestStatus } = paperlessBillingProps;

  const isDisclosureFetched =
    fetchDisclosureRequestStatus === 'succeeded' ||
    !!disclosures.estatement;

  return (
    <PaperlessContainer>
      <ConditionsHeading>E-Statements Terms & Conditions</ConditionsHeading>
      <TextContainer>
        <Disclosures disclosureName="estatement" />
        <CheckboxContainer>
          <StyledCheckboxWithText
            checked={isChecked}
            onPress={() => isDisclosureFetched ? setIsChecked(!isChecked) : null}
            title="I agree to the terms and conditions as outlined above."
          />
        </CheckboxContainer>
      </TextContainer>
      <ButtonsContainer>
        <LargeProgressButton
          disabled={!isChecked}
          label="Submit"
          onPress={() => handleOnSubmit()}
          status={requestStatus}
        />
        <ConditionsBorderlessButton
          disabled={requestStatus === 'loading'}
          isPrimary
          label="Back"
          onPress={() => handleSetModalViewStep(1)}
        />
      </ButtonsContainer>
    </PaperlessContainer>
  );
};

ConditionsView.propTypes = {
  disclosures: T.object.isRequired,
  fetchDisclosureRequestStatus: T.string.isRequired,
  handleOnSubmit: T.func.isRequired,
  handleSetModalViewStep: T.func.isRequired,
  paperlessBillingProps: T.object.isRequired,
};

export default ConditionsView;
