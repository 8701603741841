import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import {
  PayoffReasonOptionsTextWrapper,
  PayoffReasonOptionsWrapper,
  StyledIconButton,
  StyledP3,
} from './styledComponents';

const CheckedIcon = iconDictionary('approved');
const EmptyIcon = iconDictionary('unapproved');

const PayoffReasonOption = ({
  payoffReason,
  selectedPayOffReason,
  setSelectedPayOffReason,
}) => (
  <PayoffReasonOptionsWrapper>
    <StyledIconButton
      accessibilityLabel={`${payoffReason} radio button`}
      Icon={payoffReason === selectedPayOffReason ? CheckedIcon : EmptyIcon}
      onPress={() => setSelectedPayOffReason(payoffReason)}
    />
    <PayoffReasonOptionsTextWrapper>
      <StyledP3>{payoffReason}</StyledP3>
    </PayoffReasonOptionsTextWrapper>
  </PayoffReasonOptionsWrapper>
);

PayoffReasonOption.propTypes = {
  payoffReason: T.string.isRequired,
  selectedPayOffReason: T.string.isRequired,
  setSelectedPayOffReason: T.func.isRequired,
};

export default PayoffReasonOption;
