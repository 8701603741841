import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../../../base_ui';
import Link from './Link';
import Span from './Span';
import { ParagraphContainer } from './styledComponents';

/* eslint-disable jsx-a11y/anchor-is-valid, react/no-array-index-key */
const Paragraph = ({ handleNavigateLink, inlineBlocks, noMargin }) => (
  <ParagraphContainer $noMargin={noMargin}>
    {inlineBlocks.map(({
      data,
      style,
      text,
      type,
    }, i) => (
      <ConditionalRender
        key={i}
        Component={(
          <Link data={data} handleNavigateLink={handleNavigateLink} text={text} />
        )}
        FallbackComponent={<Span style={style} text={text} />}
        shouldRender={type === 'link' && !!data && !!(data.url || data.mail)}
      />
    ))}
  </ParagraphContainer>
);

Paragraph.propTypes = {
  handleNavigateLink: T.func.isRequired,
  inlineBlocks: T.array.isRequired,
  noMargin: T.bool,
};

export default Paragraph;
