import styled from 'styled-components/native';
import { Animated, PixelRatio } from 'react-native';

import {
  BASE_HEIGHT,
  LABEL_LINE_HEIGHT,
  PADDING_BOTTOM,
  PADDING_TOP,
  SCALE_FACTOR_SHRUNK,
} from './constants';
import BaseInput from '../BaseInput';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const StyledLoginRegisterInput = styled(BaseInput).attrs(({
  theme: {
    fontFamilies: { secondaryFontBold },
    fontSizes: { f5 },
  },
}) => ({
  errorStyle: {
    lineHeight: moderateScale(15),
    margin: 0,
  },
  inputContainerStyle: {
    backgroundColor: 'transparent',
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderTopWidth: 0,
    marginBottom: moderateScale(3),
  },
  inputStyle: {
    fontFamily: secondaryFontBold,
    fontSize: f5,
    height: BASE_HEIGHT + ((PixelRatio.getFontScale() * f5) - f5),
    paddingBottom: PADDING_BOTTOM,
    paddingTop: PADDING_TOP,
  },
}))``;

/*
  Used as the label
*/
export const StyledAnimatedText = styled(Animated.Text)`
  color: ${({
    $error,
    $inputDisabled,
    theme: { colors: { accentRed, primaryCarbon, primaryGray } },
  }) => {
    if ($inputDisabled) return primaryGray;
    if ($error) return accentRed;
    return primaryCarbon;
  }};
  font-family: ${({ $isShrunk, theme: { fontFamilies: { secondaryFont, secondaryFontBold } } }) =>
    ($isShrunk ? secondaryFont : secondaryFontBold)};
  font-size: ${({ $isFirstRender, $isShrunk, theme: { fontSizes: { f5 } } }) => (
    ($isFirstRender && $isShrunk) ? `${f5 * SCALE_FACTOR_SHRUNK}` : `${f5}`
  )}px;
  left: 0;
  line-height: ${LABEL_LINE_HEIGHT}px;
  position: absolute;
  top: ${({ $isRegister }) => $isRegister ? `${moderateScale(8)}px` : 0};
`;

export const LabelDimensionsPlaceholder = styled.Text`
  align-self: flex-start;
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f5 } } }) => f5}px;
  left: -9999px;
  line-height: ${LABEL_LINE_HEIGHT}px;
  position: absolute;
  top: -9999px;
`;

export const TextFieldWrapper = styled.View`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: ${moderateScale(287)}px; // TODO: determine appropriate width when finalizing screen layout
`;
