import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { Animation, H2, P2 } from '../../base_ui';

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ButtonWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(35)}px;
`;

export const ConfirmationContainer = styled.View`
  flex: 1;
  margin-top:  ${moderateScale(52)}px;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const ConfirmationH2 = styled(H2)`
  margin: ${moderateScale(24)}px ${moderateScale(20)}px 0;
  text-align: center;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(52)}px;
`;

export const PasswordContainer = styled.View`
  flex: 1;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const PasswordP2 = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(20)}px;;
`;

export const StyledAnimation = styled(Animation)`
  align-self: center;
  height: ${moderateScale(120)}px;
`;

export const TextFieldGroup = styled.View`
  align-items: center;
  flex-grow: 1;
  margin-bottom: ${moderateScale(16)}px;
`;
