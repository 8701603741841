import React from 'react';

import { Skeleton } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';
import { MainWrapper } from '../styledComponents';
import {
  SkeletonColumn,
  SkeletonHeader,
  SkeletonTable,
  StyledPrimaryDivider,
} from './styledComponents';

const PaymentHomeHistorySkeleton = () => (
  <MainWrapper>
    <SkeletonHeader>
      <Skeleton
        $bottomMargin={`${moderateScale(11)}px`}
        $leftMargin={0}
        $rightMargin={0}
        $topMargin={`${moderateScale(9)}px`}
        height={`${moderateScale(18)}px`}
        width={`${moderateScale(18)}px`}
      />
      <Skeleton
        $bottomMargin={`${moderateScale(11)}px`}
        $leftMargin={`${moderateScale(12)}px`}
        $rightMargin={0}
        $topMargin={`${moderateScale(9)}px`}
        height={`${moderateScale(16)}px`}
        width={`${moderateScale(121)}px`}
      />
    </SkeletonHeader>
    <SkeletonTable>
      <SkeletonColumn $width="20.795%">
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(29)}px`}
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
      </SkeletonColumn>
      <SkeletonColumn $width="19.266%">
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(46)}px`}
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
      </SkeletonColumn>
      <SkeletonColumn $width="38.840%">
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(93)}px`}
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
      </SkeletonColumn>
    </SkeletonTable>
    <StyledPrimaryDivider />
    <Skeleton
      $bottomMargin={`${moderateScale(14)}px`}
      $leftMargin="auto"
      $rightMargin="auto"
      $topMargin={0}
      height={`${moderateScale(14)}px`}
      width={`${moderateScale(156)}px`}
    />
  </MainWrapper>
);

export default PaymentHomeHistorySkeleton;
