import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { parseDocUploadAttachments } from '@dmi/shared/utils/globalHelpers';
import { validate } from '@dmi/shared/utils/validate';

import iconDictionary from '../../../utils/iconDictionary';
import { XSmallProgressButton } from '../../base_ui';
import FileRowButton from '../Shared/FileRowButton';
import {
  AddAttachmentContainer,
  ReplyBoxButtonContainer,
  ReplyBoxContainer,
  ReplyTitle,
  StyledReplyView,
  StyledTextInput,
} from './styledComponents';

const ChatBubble = iconDictionary('chatBubble');

const ReplyBox = ({
  attachments,
  body,
  dispatchChangeFormFieldValue,
  dispatchChangeStep,
  dispatchPostReply,
  formUploadError,
  requestStatusPostReply,
  selectedMessageId,
  setIsKeyboardVisible,
}) => {
  const textareaRef = useRef();
  useEffect(() => {
    // Fixes Android Native not prompting keyboard on initial mount/focus.
    // eslint-disable-next-line no-unused-expressions
    Platform.OS === 'ios'
      ? textareaRef.current.focus()
      : setTimeout(() => textareaRef.current.focus(), 25);
  }, [textareaRef]);

  const handleFileRowButton = () => {
    if (attachments.length) {
      dispatchChangeStep(3);
    } else {
      dispatchChangeStep(2);
    }
  };

  const onBlurHelper = (event) => {
    /**
     * The reply box onBlur event blocks the initial add attachment
     * and send reply events on mobile web. This logic checks if a
     * related target was selected, and handles the action.
     */
    if (Platform.OS === 'web' && event.relatedTarget) {
      if (event.relatedTarget.role !== 'button') {
        handleFileRowButton();
      }
      if (event.relatedTarget.ariaLabel === 'Send') {
        dispatchPostReply({
          attachments: parseDocUploadAttachments(attachments),
          body,
          messageId: selectedMessageId,
        });
      }
    }
    setIsKeyboardVisible(false);
  };

  const isBodyInvalid = validate({
    maxLength: 3000,
    type: 'message',
    value: body,
  });

  const hasAttachmentError = attachments.some(({ bannerError, removed }) =>
    !!bannerError && !removed) || !!formUploadError;
  const isSendDisabled = hasAttachmentError || isBodyInvalid;

  return (
    <ReplyBoxContainer>
      <StyledReplyView>
        {ChatBubble}
        <ReplyTitle>Reply</ReplyTitle>
      </StyledReplyView>
      <StyledTextInput
        ref={textareaRef}
        inputContainerStyle={{
          borderBottomWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
        }}
        maxLength={3000}
        multiline
        onBlur={onBlurHelper}
        onChangeText={
          (value) =>
            dispatchChangeFormFieldValue({ field: 'body', form: 'reply', value })
        }
        onFocus={() => setIsKeyboardVisible(true)}
        placeholder="Type your message here."
        value={body}
      />
      <ReplyBoxButtonContainer>
        <AddAttachmentContainer>
          <FileRowButton
            handlePress={handleFileRowButton}
            isReply
            label="Add Attachment"
            numberOfAttachments={attachments.length}
          />
        </AddAttachmentContainer>
        <XSmallProgressButton
          disabled={isSendDisabled}
          label="Send"
          onPress={() => dispatchPostReply({
            attachments: parseDocUploadAttachments(attachments),
            body,
            messageId: selectedMessageId,
          })}
          status={requestStatusPostReply}
        />
      </ReplyBoxButtonContainer>
    </ReplyBoxContainer>
  );
};

ReplyBox.propTypes = {
  attachments: T.array.isRequired,
  body: T.string.isRequired,
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchPostReply: T.func.isRequired,
  formUploadError: T.string.isRequired,
  requestStatusPostReply: T.string.isRequired,
  selectedMessageId: T.string.isRequired,
  setIsKeyboardVisible: T.func.isRequired,
};

export default ReplyBox;
