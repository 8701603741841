import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';

import {
  DefinitionContainer,
  DefinitionHeaderContainer,
  StyledDefinitionH3,
  StyledDefinitionP3,
} from '../styledComponents';

const AddEscrowDefinition = ({ icon }) => {
  const { header, text } = ADD_ESCROW_DICT.escrowResources.definition;

  return (
    <DefinitionContainer>
      <DefinitionHeaderContainer>
        {icon}
        <StyledDefinitionH3>{header}</StyledDefinitionH3>
      </DefinitionHeaderContainer>
      <StyledDefinitionP3>
        <FormattedMessage id={text} />
      </StyledDefinitionP3>
    </DefinitionContainer>
  );
};

AddEscrowDefinition.propTypes = { icon: T.object.isRequired };

export default AddEscrowDefinition;
