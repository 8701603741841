import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { makeSelectMortgage } from '@dmi/shared/redux/Mortgage/selectors';
import reducer from '@dmi/shared/redux/Mortgage/reducer';

import { StyledScrollView } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { MortgageDetails } from '../../../components/Mortgage/MortgageOverview';
import injectReducer from '../../../utils/injectReducer';
import { StyledView } from '../styledComponents';

const MortgageLoanDetailsExpandableScreen = ({
  navigation,
  selectedLoan: { label, value },
}) => (
  <StyledView>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title={label}
    />
    <StyledScrollView>
      <MortgageDetails detailsData={value} />
    </StyledScrollView>
  </StyledView>
);

MortgageLoanDetailsExpandableScreen.propTypes = {
  navigation: T.object,
  selectedLoan: T.shape({
    label: T.string.isRequired,
    value: T.array.isRequired,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Reducer: Mortgage
   */
  selectedLoan: makeSelectMortgage('selectedMortgageLoan'),
});

const withConnect = connect(mapStateToProps, null);

const withReducer = injectReducer({ key: 'mortgage', reducer });

export default compose(
  injectIntl,
  withConnect,
  withReducer,
)(MortgageLoanDetailsExpandableScreen);
