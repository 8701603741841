import React from 'react';
import T from 'prop-types';

import { Animation, ConditionalRender } from '../base_ui';
import {
  AnimationWrapper,
  StyledH2,
  StyledP2,
  StyledView,
} from './styledComponents';

const LoadingDog = ({ heading, text }) => (
  <StyledView>
    <AnimationWrapper
      accessible
      aria-label="Loading indicator"
      role="img"
    >
      <Animation
        lottieProps={{ loop: true }}
        name="dogMobile"
      />
    </AnimationWrapper>
    <ConditionalRender
      Component={StyledH2}
      propsToPassDown={{ children: heading }}
      shouldRender={!!heading}
    />
    <ConditionalRender
      Component={StyledP2}
      propsToPassDown={{ children: text }}
      shouldRender={!!text}
    />
  </StyledView>
);

LoadingDog.propTypes = { heading: T.string, text: T.string };

export default LoadingDog;
