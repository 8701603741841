/**
 * (Bottom)TabNavigator
 *
 * Meant to be nested inside the HomeDrawerNavigator and hold all our 'Home' screens. Only intended
 * to be used on our native builds, not web builds.
 */

import React from 'react';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components/native';

import { selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import IconGenerator from './IconGenerator';
import getHomeScreens from '../index';

const selector = selectIsMurabahaAccount();
const Tab = createBottomTabNavigator();

const platformSpecificStyle = Platform.OS === 'ios' ?
  { height: 60, paddingBottom: 5 } : { height: 80, paddingBottom: 20, paddingTop: 10 };

const TabNavigator = () => {
  const isMurabahaAccount = useSelector(selector);
  const homeScreens = getHomeScreens(isMurabahaAccount, Platform.OS === 'web');
  const {
    colors: {
      primaryCarbon,
      primaryGray,
    },
  } = useTheme();

  return (
    <Tab.Navigator
      initialRouteName="Payments"
      screenOptions={({ route: { name } }) => ({ // eslint-disable-line object-curly-newline
        tabBarActiveTintColor: primaryCarbon,
        tabBarButton: ['Calculators', 'Help', 'Settings'].includes(name) ? () => null : undefined,
        tabBarInactiveTintColor: primaryCarbon,
        tabBarLabelPosition: 'below-icon',
        tabBarStyle: {
          borderTopColor: primaryGray,
          borderTopWidth: 1,
          ...platformSpecificStyle,
        },
      })}
    >
      {Object.entries(homeScreens).map(([name, otherProps]) => (
        <Tab.Screen
          key={name}
          name={name}
          options={({ route }) => ({
            headerShown: false,
            tabBarIcon: ({ focused }) => IconGenerator(route, focused),
          })}
          {...otherProps}
        />
      ))}
    </Tab.Navigator>
  );
};

export default TabNavigator;
