import React, {
  useCallback,
  useRef,
  useState,
} from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { validate } from '@dmi/shared/utils/validate';
import {
  convertCurrencyStrToNum,
  convertNumToCustomCurrencyStr,
} from '@dmi/shared/utils/globalHelpers';
import { handleHelocFormValidation } from '@dmi/shared/redux/Mortgage/helpers';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender, P2 } from '../../../base_ui';
import {
  AmountField,
  ButtonContainer,
  DisclaimerRow,
  DisclaimerText,
  Divider,
  FormWrapper,
  Header,
  Row,
  RowLabel,
  RowWrapper,
  StatusBanner,
  StatusBold,
  StatusText,
  StyledButton,
  StyledScrollView,
} from './styledComponents';
import AccountInformationSection from './AccountInformationSection';
import { TextfieldGroup } from './AccountInformationSection/styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');
const PendingIcon = iconDictionary('outlinedPendingPurple');

const HelocForm = ({
  bannerPropsToPassDown,
  canUseAnyRoutingNumber,
  clientName,
  dispatchResetFormError,
  dispatchResetFormErrors,
  dispatchUpdateAmount,
  dispatchUpdateFormError,
  dispatchUpdateFormValue,
  dispatchValidateRouting,
  formConstants: {
    accountInformation,
    availableCreditDisclaimer,
    buttonLabel,
    disclaimer,
    formHeader,
    inputLabel,
  },
  formErrors,
  formValues,
  helocDrawDetails,
  isAccountRequired,
  maxHelocDrawAmount,
  minHelocDrawAmount,
  navigation,
  shouldRenderStatusBanner,
  validateRoutingError,
}) => {
  const [amountState, setAmountState] = useState('');
  const {
    accountNumber,
    accountNumberConfirm,
    accountType,
    nameOnAccount,
    routingNumber,
  } = formValues;

  const shouldDisableReviewButton = isAccountRequired ?
    (shouldRenderStatusBanner
    || !amountState
    || amountState === '0.00'
    || !accountNumber
    || !accountNumberConfirm
    || !accountType
    || !nameOnAccount
    || (canUseAnyRoutingNumber && !routingNumber))
    : !amountState || amountState === '0.00';

  const nameOnAccountRef = useRef();
  const accountNumberRef = useRef();
  const accountNumberConfirmRef = useRef();
  const routingNumberRef = useRef();

  const handleOnChangeAmount = useCallback((value) => {
    const amountValue = Number(value);
    const isValidNumber = !Number.isNaN(Number(amountValue));
    const amountToUse = amountValue >= 0 ? value : '';
    const { message } = validate({
      isOnChange: true,
      max: maxHelocDrawAmount,
      min: minHelocDrawAmount,
      type: 'helocDrawValue',
      value: value || '0.00',
    });
    if (message) {
      dispatchUpdateFormError({
        field: 'amount',
        form: 'helocDraw',
        value: message,
      });
    } else if (formErrors.amount) {
      dispatchResetFormError({ field: 'amount', form: 'helocDraw' });
    }
    if (isValidNumber) {
      setAmountState(amountToUse);
    }
  }, [
    formErrors.amount,
    dispatchResetFormError,
    dispatchUpdateFormError,
    maxHelocDrawAmount,
    minHelocDrawAmount,
  ]);

  const handleOnBlurFormat = () => {
    let tempAmountState = amountState === '' || amountState === '0.00' ? '0.00' : '';
    if (!tempAmountState) {
      const amountToUse = convertCurrencyStrToNum(amountState);
      tempAmountState = convertNumToCustomCurrencyStr(amountToUse);
    }
    setAmountState(tempAmountState);
  };

  const handleOnClearFormat = () => {
  // Remove thousand separator from formatted string/clear amount if it's 0
    if (amountState === '0.00') {
      setAmountState('');
    } else {
      const value = convertCurrencyStrToNum(amountState);
      if (value === 0 || Number.isNaN(value)) {
        setAmountState('');
      } else {
        setAmountState(value.toFixed(2).toString());
      }
    }
  };

  const handleSubmitForm = (currentAmount) => {
    dispatchResetFormErrors({ form: 'helocDraw' });
    const value = convertCurrencyStrToNum(currentAmount);
    const hasFormErrors = handleHelocFormValidation({
      canUseAnyRoutingNumber,
      currentAmount: value,
      dispatchUpdateFormError,
      formValues,
      maxHelocDrawAmount,
      minHelocDrawAmount,
    });
    if (!hasFormErrors) {
      if (canUseAnyRoutingNumber) {
        dispatchValidateRouting({ ...formValues, value });
      } else {
        dispatchUpdateAmount({ amount: value });
        navigation.navigate('HelocReview');
      }
    }
  };

  const { message, status } = bannerPropsToPassDown;

  return (
    <StyledScrollView>
      <View>
        <StatusBanner
          message={message}
          type={status === 'pending' ? 'pending' : 'error'}
          values={{
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <StatusBold>{msg}</StatusBold>,
          }}
          WrapperComponent={StatusText}
        />
        <RowWrapper>
          {helocDrawDetails.map(({ label, value }) => (
            <Row key={label}>
              <RowLabel>{label}</RowLabel>
              <P2>{value}</P2>
            </Row>
          ))}
          <DisclaimerRow>
            <View>{InfoIcon}</View>
            <DisclaimerText>{availableCreditDisclaimer}</DisclaimerText>
          </DisclaimerRow>
        </RowWrapper>
        <Divider />
        <FormWrapper>
          <View>
            <Header $validateRoutingError={!!validateRoutingError}>{formHeader}</Header>
            <ConditionalRender
              Component={(
                <StatusBanner
                  $validateRoutingError={!!validateRoutingError}
                  message={validateRoutingError}
                  type={'error'}
                  WrapperComponent={StatusText}
                />
              )}
              shouldRender={!!validateRoutingError}
            />
            <TextfieldGroup>
              <AmountField
                aria-label={inputLabel}
                disabled={shouldRenderStatusBanner}
                errorMessage={formErrors.amount}
                hasFluidWidth
                inputMode="decimal"
                keyboardType="numeric"
                label={inputLabel}
                leftIcon={<P2>$</P2>}
                onBlurSideEffects={handleOnBlurFormat}
                onChangeText={(newValue) => handleOnChangeAmount(newValue)}
                onFocusSideEffects={handleOnClearFormat}
                onSubmitEditing={() => accountNumberRef.current.focus()}
                rendersCheckbox={false}
                value={amountState}
              />
            </TextfieldGroup>
            <DisclaimerRow>
              <View>{PendingIcon}</View>
              <DisclaimerText>{disclaimer}</DisclaimerText>
            </DisclaimerRow>
            <ConditionalRender
              Component={(
                <AccountInformationSection
                  {...accountInformation}
                  accountNumberConfirmRef={accountNumberConfirmRef}
                  accountNumberRef={accountNumberRef}
                  canUseAnyRoutingNumber={canUseAnyRoutingNumber}
                  clientName={clientName}
                  disabled={shouldRenderStatusBanner}
                  dispatchUpdateFormValue={dispatchUpdateFormValue}
                  formErrors={formErrors}
                  formValues={formValues}
                  nameOnAccountRef={nameOnAccountRef}
                  routingNumberRef={routingNumberRef}
                />
              )}
              shouldRender={isAccountRequired}
            />
          </View>
        </FormWrapper>
      </View>
      <ButtonContainer>
        <StyledButton
          disabled={shouldDisableReviewButton}
          label={buttonLabel}
          onPress={() => handleSubmitForm(amountState)}
        />
      </ButtonContainer>
    </StyledScrollView>
  );
};

HelocForm.propTypes = {
  bannerPropsToPassDown: T.shape({
    message: T.string,
    status: T.string,
  }).isRequired,
  canUseAnyRoutingNumber: T.bool.isRequired,
  clientName: T.string.isRequired,
  dispatchResetFormError: T.func.isRequired,
  dispatchResetFormErrors: T.func.isRequired,
  dispatchUpdateAmount: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  dispatchUpdateFormValue: T.func.isRequired,
  dispatchValidateRouting: T.func.isRequired,
  formConstants: T.shape({
    accountInformation: T.shape({
      header: T.string.isRequired,
      subheader: T.string.isRequired,
    }),
    availableCreditDisclaimer: T.string.isRequired,
    buttonLabel: T.string.isRequired,
    disclaimer: T.string.isRequired,
    formHeader: T.string.isRequired,
    inputLabel: T.string.isRequired,
    screenHeader: T.string.isRequired,
  }).isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  helocDrawDetails: T.array.isRequired,
  isAccountRequired: T.bool.isRequired,
  maxHelocDrawAmount: T.number.isRequired,
  minHelocDrawAmount: T.number.isRequired,
  navigation: T.object.isRequired,
  shouldRenderStatusBanner: T.bool.isRequired,
  validateRoutingError: T.oneOfType([T.bool, T.string]).isRequired,
};

export default HelocForm;
