import React from 'react';
import T from 'prop-types';

import Layout from '../../../../constants/Layout';
import iconDictionary from '../../../../utils/iconDictionary';
import { SmallPrimaryButton } from '../../../base_ui';
import {
  CardHeaderContainer,
  HomeTextContainer,
  IllustrationWrapper,
  LossDraftClaimH3,
} from './styledComponents';

const { isTablet } = Layout;
const DisasterSectionFeature = iconDictionary('disasterSectionFeature');

const LossDraftClaimView = ({ navigation }) => (
  <CardHeaderContainer $center={isTablet}>
    <IllustrationWrapper>{DisasterSectionFeature}</IllustrationWrapper>
    <HomeTextContainer>
      <LossDraftClaimH3>{'Visit the Claims Center'}</LossDraftClaimH3>
      <SmallPrimaryButton
        label="Go To Claim"
        onPress={() => {
          navigation.navigate('LossDraft');
        }}
      />
    </HomeTextContainer>
  </CardHeaderContainer>
);

LossDraftClaimView.propTypes = { navigation: T.object.isRequired };

export default LossDraftClaimView;
