import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';
import { ConditionalRender, H1, Skeleton } from '../../../base_ui';
import { CardLink } from '../Card';
import { CenterContainer, Container, HeadingContainer } from './styledComponents';

const Illustration = iconDictionary('settingsRobot');

const ProfileCard = ({
  isSslAuth,
  isSso,
  navigation,
  rowsData,
  username,
  usernameLoading,
}) => (
  <Container>
    <CenterContainer>
      {Illustration}
      {!(isSslAuth || isSso) && (
        <HeadingContainer>
          <ConditionalRender
            Component={<H1>{username}</H1>}
            FallbackComponent={(
              <Skeleton height={`${moderateScale(18)}px`} width={`${moderateScale(100)}px`} />
            )}
            shouldRender={!usernameLoading}
          />
        </HeadingContainer>
      )}
    </CenterContainer>
    {rowsData.map(({ label, pressProps: { isDisabled, screen }, ...restProps }) => (
      <CardLink
        key={label}
        handlePress={() => navigation.navigate(screen)}
        isDisabled={isDisabled}
        label={label}
        {...restProps}
      />
    ))}
  </Container>
);

ProfileCard.propTypes = {
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  navigation: T.object.isRequired,
  rowsData: T.arrayOf(T.object).isRequired,
  username: T.string.isRequired,
  usernameLoading: T.bool.isRequired,
};

export default ProfileCard;
