/* eslint-disable max-len */
import React from 'react';
import Svg, {
  G,
  Line,
  Path,
  Polygon,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function PropertyTaxIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <G>
        <Polygon fill="none" points="15.2,15.7 2.8,15.7 2.8,6.4 8.9,2.3 15.2,6.4" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" x1="1.5" x2="3.4" y1="7.3" y2="6" />
        <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" x1="16.5" x2="14.6" y1="7.3" y2="6" />
        <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" x1="13.3" x2="13.3" y1="5.1" y2="2.8" />
        <G>
          <Path
            d="M7.9,6.6C8.2,7,8.3,7.4,8.3,7.8S8.2,8.7,7.9,9C7.6,9.4,7.2,9.5,6.7,9.5S5.9,9.4,5.6,9S5.1,8.3,5.1,7.8c0-0.5,0.2-0.9,0.5-1.2s0.7-0.5,1.2-0.5C7.2,6.1,7.6,6.3,7.9,6.6z M12.2,6.3l-5.5,7.5H5.4l5.5-7.5H12.2z M7.2,8.4c0.1-0.1,0.2-0.3,0.2-0.5S7.3,7.5,7.1,7.3C7,7.2,6.9,7.1,6.7,7.1S6.4,7.2,6.3,7.3C6.2,7.5,6.1,7.6,6.1,7.9s0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2S7,8.5,7.2,8.4z M12.1,11c0.3,0.3,0.5,0.7,0.5,1.2c0,0.5-0.2,0.9-0.5,1.2c-0.3,0.3-0.7,0.5-1.2,0.5c-0.5,0-0.8-0.2-1.1-0.5c-0.3-0.3-0.5-0.7-0.5-1.2c0-0.5,0.2-0.9,0.5-1.2c0.3-0.3,0.7-0.5,1.2-0.5C11.4,10.5,11.8,10.6,12.1,11zM11.4,12.7c0.1-0.1,0.2-0.3,0.2-0.5s-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.4-0.2s-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.3-0.2,0.5s0.1,0.4,0.2,0.5c0.1,0.1,0.3,0.2,0.4,0.2S11.3,12.9,11.4,12.7z"
            fill={iconStatic}
          />
        </G>
      </G>
    </Svg>
  );
}
