import React, { useState } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { StyledPressable } from './styledComponents';

const BellIcon = iconDictionary('bellDefault');

const NotificationsButton = ({ disabled, onPress }) => {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <StyledPressable
      $isOpaque={disabled}
      $pressed={isPressed}
      accessible
      aria-label="View Notifications Screen"
      disabled={disabled}
      onPress={onPress}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
      role="button"
    >
      {BellIcon}
    </StyledPressable>
  );
};

NotificationsButton.propTypes = { disabled: T.bool.isRequired, onPress: T.func.isRequired };

export default NotificationsButton;
