import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { createStructuredSelector } from 'reselect';

import {
  fetchTransactionDetail,
  updateSelectedTransaction,
} from '@dmi/shared/redux/Mortgage/actions';
import makeSelectMortgage, {
  makeSelectMortgageLoading,
  makeSelectMortgageNotifications,
  makeSelectMortgageServiceError,
  makeSelectTransactionDetails,
} from '@dmi/shared/redux/Mortgage/selectors';

import { usePrevious } from '../../../utils/customHooks';
import AsyncRender from '../../../components/AsyncRender';
import { ConditionalRender } from '../../../components/base_ui';
import {
  EscrowPaymentDetail,
  EscrowPaymentDetailSkeleton,
} from '../../../components/Mortgage/EscrowHistory';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';
import { StyledView } from './styledComponents';

const EscrowPaymentDetailScreen = ({
  dispatchFetchTransactionDetail,
  dispatchUpdateSelectedTransaction,
  error,
  loading,
  navigation,
  notifications,
  selectedTransaction,
  selectedTransactionId,
}) => {
  const previousTransactionId = usePrevious(selectedTransactionId);

  useFocusEffect(
    useCallback(() => {
      if (selectedTransactionId && selectedTransactionId !== previousTransactionId) {
        dispatchFetchTransactionDetail(selectedTransactionId);
      }
    }, [dispatchFetchTransactionDetail, previousTransactionId, selectedTransactionId]),
  );

  useEffect(() => () => {
    dispatchUpdateSelectedTransaction('');
  }, [dispatchUpdateSelectedTransaction]);

  return (
    <ConditionalRender
      Component={(
        <StyledView>
          <ScreenReaderNotifications notifications={notifications} />
          <SecondaryHeader
            handleBack={() => navigation.goBack()}
            title="Escrow Payment Detail"
          />
          <AsyncRender
            Component={EscrowPaymentDetail}
            error={error}
            loading={loading}
            LoadingComponent={EscrowPaymentDetailSkeleton}
            propsToPassDown={{ ...selectedTransaction }}
          />
        </StyledView>
      )}
      shouldRender={!!selectedTransactionId}
    />
  );
};

EscrowPaymentDetailScreen.propTypes = {
  dispatchFetchTransactionDetail: T.func.isRequired,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  loading: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  selectedTransaction: T.object.isRequired,
  selectedTransactionId: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  error: makeSelectMortgageServiceError('transactionDetail'),
  loading: makeSelectMortgageLoading('transactionDetail'),
  notifications: makeSelectMortgageNotifications(),
  selectedTransaction: makeSelectTransactionDetails(),
  selectedTransactionId: makeSelectMortgage('selectedTransactionId'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mortgage
   */
  dispatchFetchTransactionDetail: (id) => dispatch(fetchTransactionDetail(id)),
  dispatchUpdateSelectedTransaction: (id) => dispatch(updateSelectedTransaction(id)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EscrowPaymentDetailScreen);
