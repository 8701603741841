import React from 'react';
import T from 'prop-types';

import { P3 } from '../../../base_ui';
import {
  Banner,
  IllustrationWrapper,
  MessageContainer,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';

const ThumbtackIcon = iconDictionary(
  'thumbtack',
  { height: `${moderateScale(35)}px`, width: `${moderateScale(35)}px` },
);

const CalculatorDisclaimer = ({ disclaimer }) => (
  <Banner>
    <IllustrationWrapper>{ThumbtackIcon}</IllustrationWrapper>
    <MessageContainer>
      <P3>{disclaimer}</P3>
    </MessageContainer>
  </Banner>
);

CalculatorDisclaimer.propTypes = { disclaimer: T.string.isRequired };

export default CalculatorDisclaimer;
