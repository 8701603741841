import React, { useEffect, useState } from 'react';
import T from 'prop-types';

import { LANGUAGE_PICKER_OPTIONS } from '@dmi/shared/utils/constants';
import { STORAGE_KEY_LANGUAGE_PICKER_VALUE } from '@dmi/shared/redux/Main/constants';
import AsyncStorage from '@dmi/shared/utils/AsyncStorage';

import { P2 } from '../base_ui';
import {
  RadioButtonIcon,
  RadioButtonWrapper,
  RadioButtonsContainer,
} from './styledComponents';
import iconDictionary from '../../utils/iconDictionary';

const CheckedIcon = iconDictionary('approved');
const EmptyIcon = iconDictionary('unapproved');

const LanguagePicker = ({ dispatchSetLanguagePicker }) => {
  const [pickerValue, setPickerValue] = useState('');

  useEffect(() => {
    const getPickerValue = async () => {
      const value = await AsyncStorage.getItem(STORAGE_KEY_LANGUAGE_PICKER_VALUE);
      setPickerValue(value || '');
    };
    getPickerValue();
  }, []);

  const handleOnPress = (value) => {
    setPickerValue(value);
    dispatchSetLanguagePicker(value);
  };

  return (
    <RadioButtonsContainer>
      {LANGUAGE_PICKER_OPTIONS.map(({ label, value }) => (
        <RadioButtonWrapper
          key={value}
          onPress={() => handleOnPress(value)}
        >
          <RadioButtonIcon
            accessibilityLabel={`${label} radio button`}
            Icon={value === pickerValue ? CheckedIcon : EmptyIcon}
            onPress={() => handleOnPress(value)}
          />
          <P2>{label}</P2>
        </RadioButtonWrapper>
      ))}
    </RadioButtonsContainer>
  );
};

LanguagePicker.propTypes = { dispatchSetLanguagePicker: T.func.isRequired };

export default LanguagePicker;
