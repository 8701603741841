/* eslint-disable max-len */
export const TOOLTIP_PROPS = {
  forbearance: {
    content: 'Temporary forbearance plans work best for borrowers who have recently faced or are facing unexpected harships such as unemployment, divorce, death, disaster, or disability. This option may reduce or suspend your mortgage payment for a specific period of time to allow you to recover from this hardship to resolve loss of employment or make better use of your resources allowing you to pursue a permanent hardship solution.',
    linkText: 'Close',
    title: 'Temporary Forbearance',
  },
  modification: {
    content: 'A loan modification adjusts the terms of your mortgage, such as your interest rate or term, in order to bring the loan current, and if possible, reduce your monthly payment.',
    linkText: 'Close',
    title: 'Modification',
  },
  repaymentPlan: {
    content: 'Repayment plans work best for borrowers who have missed one or more payments, due to an unexpected expense, but otherwise have a steady source of income that allows them to make their future payments in full and on time. A repayment plan allows you to take the amount you are behind on your mortgage, including any late payment fees and penalties, and pay it off a little at a time, by increasing your monthly mortgage payment in small amounts.',
    linkText: 'Close',
    title: 'Repayment Plan',
  },
};
