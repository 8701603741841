import { Platform, Pressable } from 'react-native';

import styled from 'styled-components/native';

import { BaseExternalLink, P3 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  padding-bottom: ${moderateScale(8)}px;
  padding-top: ${Platform.OS === 'web' ? moderateScale(1) : 0}px;
  position: ${Platform.OS === 'web' ? 'sticky' : 'relative'};
  top: 0;
  z-index: 10;
`;

export const BannerHeading = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-weight: 700;
  margin-right: ${moderateScale(8)}px;
`;

export const Content = styled.View`
  max-width: 90%;
`;

export const ContentBody = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

export const DismissButton = styled(Pressable)`
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(-${moderateScale(12)}px, ${moderateScale(12)}px);
`;

export const DismissibleBannerContainer = styled.View`
  border: ${({ theme: { colors: { accentRed } } }) => `1px solid ${accentRed}`};
  border-radius: ${moderateScale(4)}px;
  padding: ${moderateScale(12)}px;
  padding-right: ${moderateScale(24)}px;
  position: relative;
  width: 100%;
`;

export const DismissibleContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  display: ${({ $isDismissed }) => ($isDismissed ? 'none' : 'flex')};
  margin-bottom: 0;
`;

export const HeadingContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const IconWrapper = styled.View`
  padding-right: ${moderateScale(8)}px;
`;

export const StyledExternalLink = styled(BaseExternalLink)`
${Platform.OS === 'web' ? `
  display: inline-flex;
  font-size: inherit;
` : `line-height: ${moderateScale(14)}px;`}`;
