/*
 * Confirmation Screen
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getMainClientInfo,
  getSelectedLoanUnmaskedLoanNumber,
} from '@dmi/shared/redux/Main/selectors';
import { resetRequestStatusState } from '@dmi/shared/redux/Settings/Profile/actions';
import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import { selectRequestStatus } from '@dmi/shared/redux/Settings/Profile/selectors';

import { ConditionalRender } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { ConfirmationComponent } from '../../../components/Settings/EditLegalName';
import ConfirmationErrorScene from '../../../components/DocUpload/ConfirmationErrorScene';
import { StyledView } from './styledComponents';

const ConfirmationScreen = ({
  customerServicePhoneNumber,
  dispatchResetRequestStatusState,
  navigation,
  selectedLoanNumber,
  uploadDocumentRequestStatus,
}) => {
  useEffect(() => () => {
    dispatchResetRequestStatusState({ state: 'uploadDocument' });
  }, [dispatchResetRequestStatusState]);

  const { errorText, header } = PROFILE_CONTENT_DICTIONARY.editLegalName.confirmationView.mobile;

  return (
    <StyledView>
      <ConditionalRender
        Component={(
          <Fragment>
            <SecondaryHeader
              handleDone={() => navigation.navigate('Settings')}
              title={header}
            />
            <ConfirmationComponent loanNumber={selectedLoanNumber} />
          </Fragment>
        )}
        FallbackComponent={(
          <ConfirmationErrorScene
            customerServicePhoneNumber={customerServicePhoneNumber}
            label={'Return to Settings'}
            onPress={() => navigation.navigate('Settings')}
            route={'Settings'}
            text={errorText}
          />
        )}
        shouldRender={uploadDocumentRequestStatus === 'succeeded'}
      />
    </StyledView>
  );
};

ConfirmationScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  navigation: T.object.isRequired,
  selectedLoanNumber: T.string.isRequired,
  uploadDocumentRequestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  selectedLoanNumber: getSelectedLoanUnmaskedLoanNumber(),
  /**
   * Store: Profile
   */
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Profile
   */
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConfirmationScreen);
