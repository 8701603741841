import { ScrollView } from 'react-native';

import { StyledView } from './styledComponents';

export const POSITION_OPTIONS = {
  bottom: { align: 'center', justify: 'flex-end' },
  center: { align: 'center', justify: 'center' },
  left: { align: 'flex-start', justify: 'center' },
  right: { align: 'flex-end', justify: 'center' },
  top: { align: 'center', justify: 'flex-start' },
};

export const VARIANT_OPTIONS = {
  standard: ScrollView,
  tooltip: StyledView,
};
