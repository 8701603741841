import iconDictionary from '../../../../utils/iconDictionary';

const EarthquakeIcon = iconDictionary('earthquakeIcon');
const FloodIcon = iconDictionary('floodIcon');
const HomeInsuranceIcon = iconDictionary('homeInsuranceIcon');
const InsuranceFallbackIcon = iconDictionary('navHelpDefaultM');
const PropertyTaxIcon = iconDictionary('propertyTaxIcon');
const SewerWaterIcon = iconDictionary('sewerAndWaterIcon');
const TaxesFallbackIcon = iconDictionary('taxesIcon');
const WindIcon = iconDictionary('windInsuranceIcon');

export const ICON_DICT = {
  'Earthquake Insurance': EarthquakeIcon,
  'Flood Insurance': FloodIcon,
  'Homeowners Insurance (Primary)': HomeInsuranceIcon,
  insuranceFallback: InsuranceFallbackIcon,
  'Miscellaneous Insurance': HomeInsuranceIcon,
  Other: HomeInsuranceIcon,
  'Property Tax': PropertyTaxIcon,
  taxesFallback: TaxesFallbackIcon,
  'Water And Sewer Tax': SewerWaterIcon,
  'Windstorm Insurance': WindIcon,
};

export const TAX_FIELD_LIST = [
  { field: 'payeeName', label: 'Paid to' },
  { field: 'identifier', label: 'Tax ID' },
  { field: 'installmentFrequency', label: 'Pay Frequency' },
  { field: 'amount', label: 'Expected Amount' },
  { field: 'date', label: 'Next Due' },
];
