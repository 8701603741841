import React, { useState } from 'react';
import T from 'prop-types';

import { BaseToggle } from '../../Toggle';
import { P2 } from '../../Paragraphs';
import ConditionalRender from '../../ConditionalRender';
import DisabledMessage from './DisabledMessage';
import {
  LabelContainer,
  LabelStyle,
  LabelSubText,
  MainWrapper,
  StyledPaymentInput,
  ToggleWrapper,
} from './styledComponents';

const PaymentTextField = ({
  disabledText = '',
  enabled = true,
  errorMessage,
  hasFluidWidth = false,
  inputStartsDisabled = false,
  label,
  labelSubText,
  onTogglePress,
  rendersCheckbox = true,
  ...restProps
}) => {
  const [isInputDisabled, setIsInputDisabled] = useState(inputStartsDisabled);
  const propsToPassDown = { errorMessage, ...restProps };

  const LabelWithSubText = () => (
    <LabelContainer>
      <LabelStyle>{label}</LabelStyle>
      <LabelSubText>{labelSubText}</LabelSubText>
    </LabelContainer>
  );

  return (
    <MainWrapper $hasFluidWidth={hasFluidWidth}>
      <ToggleWrapper>
        <ConditionalRender
          Component={BaseToggle}
          propsToPassDown={{
            ariaLabel: 'activate-input',
            isActive: !isInputDisabled,
            onPress: () => {
              setIsInputDisabled((prevState) => !prevState);
              if (onTogglePress) {
                onTogglePress();
              }
            },
          }}
          shouldRender={rendersCheckbox}
        />
      </ToggleWrapper>
      <ConditionalRender
        Component={DisabledMessage}
        FallbackComponent={StyledPaymentInput}
        propsToPassDown={{
          $isInputDisabled: isInputDisabled,
          ariaLabel: label,
          error: !!errorMessage,
          keyboardType: 'numeric',
          label: labelSubText && !errorMessage ? LabelWithSubText : label,
          leftIcon: <P2>$</P2>,
          text: disabledText,
          ...propsToPassDown,
        }}
        shouldRender={!!disabledText && !enabled}
      />
    </MainWrapper>
  );
};

PaymentTextField.propTypes = {
  disabledText: T.string,
  enabled: T.bool,
  errorMessage: T.oneOfType([T.array, T.string]),
  hasFluidWidth: T.bool,
  inputStartsDisabled: T.bool,
  label: T.string.isRequired,
  labelSubText: T.string,
  onTogglePress: T.func,
  rendersCheckbox: T.bool,
};

export default PaymentTextField;
