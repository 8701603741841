/* eslint-disable max-len */
import React from 'react';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function EhlIcon(props) {
  const { colors: { footerIconColor } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(38)} viewBox="0 0 38 38" width={moderateScale(38)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_6418_4164)">
        <Path clipRule="evenodd" d="M19.6979 0.000244141L0.974609 10.6524V14.5041H3.84106V27.7419H35.0799V14.5041H37.9998V10.7594L19.6979 0.000244141ZM31.4801 24.3694H7.65034V11.0782L19.6867 4.01015L31.4712 11.0782L31.4801 24.3694Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M13.2898 16.1402V12.8703H25.7541V16.1402H13.2898Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M13.2898 17.7403H25.7541V21.0102H13.2898V17.7403Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M22.2659 33.7267H20.077V37.8949H22.2146C23.9354 37.8949 24.925 37.5248 24.925 35.8888C24.9272 34.2015 23.8306 33.7267 22.2659 33.7267ZM22.1611 36.9966H21.2227V34.6227H22.2659C22.9947 34.6227 23.6746 35.044 23.6746 35.8888C23.6746 36.8383 23.0482 36.9966 22.1611 36.9966Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M35.0004 36.156C35.7827 36.049 36.1483 35.7325 36.1483 35.0415C36.1483 34.0919 35.678 33.7219 34.5301 33.7219H31.5054V37.8901H32.6533V36.156H33.7477L34.9491 37.8968H36.1996L35.0004 36.156ZM34.3161 35.2644H32.6488V34.6314H34.3696C34.8377 34.6314 35.0383 34.6826 35.0383 34.9479C35.0472 35.2644 34.8912 35.2644 34.3161 35.2644Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M13.7888 33.7286V37.8945H14.8855V35.3624L17.0743 37.8945H18.3782V33.7286H17.2838V36.312L15.0928 33.7286H13.7888Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M3.03833 33.7286V37.8945H7.15744V36.9985L4.18624 36.945V33.7286H3.03833Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M8.33704 37.8945H12.3536V36.9985H9.53622V36.1024H11.8833V35.2577H9.53622V34.6246H12.3536V33.7286H8.33704V37.8945Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M26.2091 33.7286V37.8945L30.1744 37.841V36.9985H27.357V36.1024H29.7554V35.2577H27.357V34.6246H30.1744V33.7286H26.2091Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M13.1398 28.9566H12.2482L11.2051 32.7012H11.9651L12.188 31.8587H13.1264L13.3493 32.7012H14.1629L13.1398 28.9566ZM12.3619 31.2346L12.674 29.759L13.0395 31.2346H12.3619Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M7.69105 32.1753C7.85094 31.8663 7.92339 31.5195 7.90058 31.1723V30.381C7.90058 29.4894 7.69106 28.8519 6.6479 28.8519C5.70951 28.8519 5.39746 29.3802 5.39746 30.3297V31.3305C5.39746 32.5966 6.12633 32.6501 8.2639 32.6501L8.31739 32.387L7.69105 32.1753ZM7.11376 31.3305C7.11376 32.0148 7.06249 32.1731 6.64567 32.1731C6.28012 32.1731 6.17537 31.9635 6.17537 31.2815V30.3899C6.17537 29.7034 6.22887 29.4983 6.69695 29.4983C7.0625 29.4983 7.11376 29.6031 7.11376 30.3409V31.3305Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M26.672 31.6983C26.672 32.2801 27.0375 32.7548 27.8712 32.7548C28.5399 32.7548 29.1751 32.2801 29.1751 31.6983C29.1751 30.8067 27.5079 30.381 27.5079 29.8015C27.5079 29.5786 27.7151 29.4849 27.9247 29.4849C28.2367 29.4849 28.3928 29.6945 28.551 30.011L29.0726 29.8015C29.0191 29.2732 28.6535 28.8519 27.8712 28.8519C27.2471 28.8519 26.7255 29.1684 26.7255 29.8015C26.7255 30.8557 28.3927 31.1188 28.3927 31.8075C28.3927 32.0705 28.2367 32.1753 27.9247 32.1753C27.6126 32.1753 27.4031 31.9658 27.2983 31.5958L26.672 31.7005V31.6983Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M22.2704 28.8519C21.488 28.8519 21.0199 29.2732 21.0199 30.2762V31.4888C21.0199 32.3804 21.2807 32.7548 22.2704 32.7548C23.26 32.7548 23.523 32.3313 23.523 31.3305V30.1692C23.523 29.2197 23.2622 28.8519 22.2704 28.8519ZM22.7384 31.3305C22.7384 31.9635 22.5824 32.1731 22.2681 32.1731C21.8513 32.1731 21.8 31.91 21.8 31.384V30.2227C21.8 29.748 21.8513 29.4314 22.2681 29.4314C22.6849 29.4314 22.7384 29.8015 22.7384 30.2227V31.3305Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M33.6448 31.3305C33.6448 32.445 33.9568 32.7548 35 32.7548C35.8069 32.7548 36.0431 32.3715 36.0431 31.9636V30.751H34.9576V31.3305H35.3232V31.7518C35.3232 32.0683 35.2184 32.2266 34.9576 32.2266C34.4361 32.2266 34.3826 31.8566 34.3826 31.335V30.4902C34.3826 29.8572 34.4361 29.4359 34.9576 29.4359C35.3232 29.4359 35.5305 29.6989 35.584 30.0689L36.1033 29.846C36.0521 29.213 35.6352 28.8452 34.9041 28.8452C33.8632 28.8452 33.6537 29.4248 33.6537 30.2161V31.3305H33.6448Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M23.8485 28.957V31.1192C23.8485 32.3852 24.0714 32.7886 25.1591 32.7886C25.9928 32.7886 26.3004 32.3317 26.3004 31.2284V28.957H25.5693V31.1192C25.5693 31.9639 25.518 32.1734 25.1012 32.1734C24.6844 32.1734 24.6309 31.8569 24.6309 31.059V28.9504L23.8485 28.957Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M30.816 28.9585V32.7031H31.4424V30.171L32.3273 32.7031H33.2679V28.9585H32.6416V31.2788L31.8057 28.9585H30.816Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M8.65576 28.9566V31.2234C8.65576 32.28 8.76053 32.7547 9.80368 32.7547C11.0029 32.7547 11.0541 32.173 11.0541 31.1187V28.9566H10.3252V31.1722C10.3252 31.91 10.2205 32.1217 9.85494 32.1217C9.43813 32.1217 9.38687 31.8988 9.38687 31.0652V28.9566H8.65576Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M18.1337 28.9585V32.7031H18.8648V31.0136H19.7519V32.7031H20.4808V28.9585H19.7519V30.3805H18.8648V28.9585H18.1337Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M2.98547 28.9585V32.7031H4.86226V32.1214H3.71657V31.0671H4.75972V30.5388H3.71657V29.538H4.86226V28.9585H2.98547Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M14.5133 28.9585V32.7031H16.2341V32.1214H15.1396V28.9585H14.5133Z" fill={footerIconColor} fillRule="evenodd" />
        <Path clipRule="evenodd" d="M29.5587 28.9585V32.7031H30.2385V28.9585H29.5587Z" fill={footerIconColor} fillRule="evenodd" />
      </G>
      <Defs>
        <ClipPath id="clip0_6418_4164">
          <Rect fill="white" height="37.8945" transform="translate(0.977051)" width="37.023" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
