import styled from 'styled-components/native';

import {
  BaseTextField,
  H2,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const AddLoanContainer = styled.View`
  flex: 1;
  padding: 0 ${moderateScale(24)}px;
`;

export const ButtonWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(35)}px;
`;

export const ConfirmationContent = styled.View`
  align-items: center;
  margin-top: ${moderateScale(24)}px;
  text-align: center;
`;

export const ConfirmationStatusBanner = styled(StatusBanner)`
  margin-top: ${moderateScale(24)}px;
  width: 100%;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(52)}px;
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(({ theme: { fontSizes: { f5 } } }) => ({ inputFontSize: f5 }))``;

export const StyledH2 = styled(H2)`
  text-align: center;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${moderateScale(17)}px;
`;

export const TextFieldGroup = styled.View`
  align-items: center;
  flex-grow: 1;
  margin-bottom: ${moderateScale(19.92)}px;
`;
