import React from 'react';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

const OutlinedWarning = (props) => {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  const { colors: { accentRed } } = useTheme();

  /* eslint-disable max-len */
  return (
    <Svg fill="none" height={moderateScale(16)} viewBox="0 0 16 16" width={moderateScale(16)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M15.3402 7.99991C15.3402 12.0537 12.0539 15.3399 8.00016 15.3399C3.94639 15.3399 0.660156 12.0537 0.660156 7.99991C0.660156 3.94614 3.94639 0.659912 8.00016 0.659912C12.0539 0.659912 15.3402 3.94614 15.3402 7.99991Z" stroke={accentRed} />
        <Path d="M8.00039 8.97344C7.84126 8.97344 7.68865 8.91022 7.57613 8.7977C7.4636 8.68518 7.40039 8.53257 7.40039 8.37344V3.87344C7.40039 3.71431 7.4636 3.5617 7.57613 3.44917C7.68865 3.33665 7.84126 3.27344 8.00039 3.27344C8.15952 3.27344 8.31213 3.33665 8.42465 3.44917C8.53718 3.5617 8.60039 3.71431 8.60039 3.87344V8.37344C8.60039 8.45223 8.58487 8.53025 8.55472 8.60305C8.52457 8.67584 8.48037 8.74199 8.42465 8.7977C8.36894 8.85342 8.3028 8.89761 8.23 8.92777C8.1572 8.95792 8.07918 8.97344 8.00039 8.97344V8.97344Z" fill={accentRed} />
        <Path d="M8.00047 12.4668C8.5638 12.4668 9.02047 12.0101 9.02047 11.4468C9.02047 10.8834 8.5638 10.4268 8.00047 10.4268C7.43714 10.4268 6.98047 10.8834 6.98047 11.4468C6.98047 12.0101 7.43714 12.4668 8.00047 12.4668Z" fill={accentRed} />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="16" width="16" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default OutlinedWarning;
