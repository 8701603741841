import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { ConditionalRender, Skeleton } from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  IconContainer,
  LinkContainer,
  LinkDetails,
  LinkDetailsContainer,
  LinkLabel,
} from './styledComponents';

const ArrowIcon = iconDictionary('arrowRightM');

const CardLink = ({
  details = '',
  DetailsIcon = null,
  handlePress,
  isDisabled = false,
  isLoading = false,
  isLoanMancodeY,
  label,
}) => (
  <Pressable
    aria-label={`go to ${label} screen`}
    disabled={isDisabled}
    onPress={handlePress}
  >
    <LinkContainer>
      <LinkLabel>{label}</LinkLabel>
      <LinkDetailsContainer>
        <ConditionalRender
          Component={DetailsIcon}
          shouldRender={!!DetailsIcon}
        />
        <ConditionalRender
          Component={<LinkDetails>{details}</LinkDetails>}
          shouldRender={!isLoading && !!details}
        />
        <ConditionalRender
          Component={(
            <Skeleton
              $rightMargin={`${moderateScale(8)}px`}
              height={`${moderateScale(12)}px`}
              width={`${moderateScale(90)}px`}
            />
          )}
          shouldRender={isLoading}
        />
        <ConditionalRender
          Component={(
            <IconContainer $isOpaque={isDisabled}>
              {ArrowIcon}
            </IconContainer>
          )}
          shouldRender={!isLoanMancodeY}
        />
      </LinkDetailsContainer>
    </LinkContainer>
  </Pressable>
);

CardLink.propTypes = {
  details: T.string,
  DetailsIcon: T.object,
  handlePress: T.func.isRequired,
  isDisabled: T.bool,
  isLoading: T.bool,
  isLoanMancodeY: T.bool,
  label: T.string.isRequired,
};

export default CardLink;
