import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  BaseInternalLink,
  BaseTextField,
  H2,
  H4,
  MediumProgressButton,
  P2,
  PrimaryDivider,
} from '../../base_ui';

export const Card = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: 100%;
  padding: ${moderateScale(24)}px;
`;

export const ContentContainer = styled.View`
  display: flex;
  margin-top: ${moderateScale(8)}px;
  padding: ${moderateScale(16)}px 0;
`;

export const Header = styled(H4)`
  text-align: center;
`;

export const IconWrapper = styled.View`
  height: ${moderateScale(210)}px;
  margin-top: ${({ $isAccountTransferComplete }) =>
    $isAccountTransferComplete ? moderateScale(40) : moderateScale(10)}px;
`;

export const StyledLink = styled(BaseInternalLink)`
  height: ${moderateScale(38)}px;
  max-width: ${moderateScale(375)}px;
`;

export const StyledDivider = styled.View`
  background-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  height: ${moderateScale(1)}px;
  margin-bottom: ${moderateScale(8)}px;
  width: 100%;
`;

/** Error */
export const ErrorContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: ${moderateScale(51)}px;
  text-align: center;
`;

export const ErrorHeader = styled(H2)`
  margin: ${moderateScale(16)}px 0 ${moderateScale(8)}px;
`;

export const ErrorText = styled(P2)`
  margin-bottom: ${moderateScale(33)}px;
`;

/** Form */
export const PasswordContainer = styled.View`
  max-width: 100%;
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(({ theme: { fontSizes: { f5 } } }) => ({
    inputContainerStyle: { height: moderateScale(34) },
    inputFontSize: f5,
  }))``;

export const StyledContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(5)}px 0 ${moderateScale(5)}px;
`;

export const StyledProgressButton = styled(MediumProgressButton)`
  margin-top: ${({ $isUsernameRequired }) =>
    $isUsernameRequired ? moderateScale(210) : moderateScale(35)}px;
`;

export const UsernameContainer = styled.View`
  max-width: 100%;
`;

/** Static Info */
export const Text = styled(P2)`
  margin-top: ${moderateScale(8)}px;
`;

export const TextContainer = styled.View`
  margin-bottom: ${moderateScale(20)}px;
`;

export const IllustrationWrapper = styled.View`
  height: ${moderateScale(107)}px;
  width:${moderateScale(269)}px;
  margin: ${moderateScale(20)}px 0 ${moderateScale(29)}px;
`;

/** Confirmation */
export const Container = styled.View`
  align-items: center;
  align-self: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
  text-align: center;
  width: 100%;
`;

export const StyledP2 = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  margin-top: ${moderateScale(8)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin-bottom: ${moderateScale(8)}px;
  max-width: ${moderateScale(375)}px;
  width: 86%;
`;

export const HeaderWrapper = styled.View`
  max-width: ${moderateScale(300)}px;
  text-align: center;
`;

export const TextWrapper = styled.View`
  align-items: center;
  margin: 0 0 ${moderateScale(32)}px;
  max-width: ${moderateScale(330)}px;
`;
