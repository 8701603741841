import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';
import { useIntl } from 'react-intl';

import { getCopyForDatesVsView } from '@dmi/shared/redux/Payoff/helpers';

import { ConditionalRender, FormattedMessage } from '../base_ui';
import iconDictionary from '../../utils/iconDictionary';
import PayoffHomeButtons from './PayoffHomeButtons';
import {
  BoldText,
  IllustrationContainer,
  PayoffHomeTopContainer,
  StyledH2,
  StyledP2,
  StyledPhoneLinkText,
} from './styledComponents';

const PayoffIllustration = iconDictionary('payoffHouse');
const WarningIllustration = iconDictionary('greyWarningL');

const PayoffTopCard = ({
  availablePayOffReasons,
  dateSelectorIsDisabled,
  dispatchChangePayoffStep,
  dispatchClearStatement,
  dispatchCreatePayoffStatement,
  dispatchUpdateRequestType,
  dispatchUpdateSelectedDate,
  dispatchUpdateSelectedPayOffReason,
  homeButtonsView,
  isIneligible,
  isLoanMancodeY,
  isPastServicingPaymentCutOffDate,
  isPayDownAvailable,
  loanNumber,
  navigation,
  payoffRequestBody,
  payoffStep,
  requestType,
  selectedDate,
  statement,
}) => {
  const { formatMessage } = useIntl();
  const { text, title } = getCopyForDatesVsView({
    formatMessage,
    hasStatementToView: !!statement,
    isIneligible,
    isLoanMancodeY,
    isPastServicingPaymentCutOffDate,
    isPayDownAvailable,
    payoffStep,
  });

  return (
    <PayoffHomeTopContainer $isLoanMancodeY={isLoanMancodeY}>
      <IllustrationContainer $isLoanMancodeY={isLoanMancodeY}>
        {isLoanMancodeY ? WarningIllustration : PayoffIllustration}
      </IllustrationContainer>
      <StyledH2 $isLoanMancodeY={isLoanMancodeY}>{title}</StyledH2>
      <FormattedMessage
        $isLoanMancodeY={isLoanMancodeY}
        defaultMessage={text}
        values={{
          // eslint-disable-next-line react/prop-types
          a: ({ msg }) => (
            <StyledPhoneLinkText
              // eslint-disable-next-line react/prop-types
              onPress={() => Linking.openURL(`tel://${msg.replace(/\+|-/g, '')}`)}
            >
              {msg}
            </StyledPhoneLinkText>
          ),
          // eslint-disable-next-line react/prop-types
          b: ({ msg }) => <BoldText>{msg}</BoldText>,
          mobileNewline: '\n',
        }}
        WrapperComponent={StyledP2}
      />
      <ConditionalRender
        Component={PayoffHomeButtons}
        propsToPassDown={{
          availablePayOffReasons,
          dateSelectorIsDisabled,
          dispatchChangePayoffStep,
          dispatchClearStatement,
          dispatchCreatePayoffStatement,
          dispatchUpdateRequestType,
          dispatchUpdateSelectedDate,
          dispatchUpdateSelectedPayOffReason,
          loanNumber,
          navigation,
          payoffRequestBody,
          payoffStep,
          requestType,
          selectedDate,
          statement,
          view: homeButtonsView,
        }}
        shouldRender={!isIneligible && !isPastServicingPaymentCutOffDate && !isLoanMancodeY}
      />
    </PayoffHomeTopContainer>
  );
};

PayoffTopCard.propTypes = {
  availablePayOffReasons: T.array.isRequired,
  dateSelectorIsDisabled: T.bool.isRequired,
  dispatchChangePayoffStep: T.func.isRequired,
  dispatchClearStatement: T.func.isRequired,
  dispatchCreatePayoffStatement: T.func.isRequired,
  dispatchUpdateRequestType: T.func.isRequired,
  dispatchUpdateSelectedDate: T.func.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  homeButtonsView: T.string.isRequired,
  isIneligible: T.bool.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  isPayDownAvailable: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  payoffRequestBody: T.object.isRequired,
  payoffStep: T.number.isRequired,
  requestType: T.string.isRequired,
  selectedDate: T.string.isRequired,
  statement: T.shape({
    document: T.string.isRequired,
    mobileDocument: T.oneOfType([T.object, T.string]).isRequired,
  }),
};

export default PayoffTopCard;
