import React from 'react';
import T from 'prop-types';

import { BaseInternalLink, ConditionalRender } from '../../base_ui';
import {
  ContactInfoContainer,
  ContentContainer,
  StyledP3,
} from './styledComponents';

const ContactInfo = ({ isSecureMessagingTreatmentOn, withBanner = false }) => (
  <ContactInfoContainer>
    <ConditionalRender
      Component={(
        <ContentContainer $isFooterLink={withBanner}>
          <StyledP3 $isFooterLink={withBanner}>Questions? </StyledP3>
          <BaseInternalLink
            isFooterLink={withBanner}
            label="Send a Secure Message"
            path="SecureMessaging"
            size="small"
          />
        </ContentContainer>
      )}
      shouldRender={isSecureMessagingTreatmentOn}
    />
    <ContentContainer
      $isFooterLink={withBanner}
      $shouldAddExtraPadding={isSecureMessagingTreatmentOn}
    >
      <BaseInternalLink
        isFooterLink={withBanner}
        label="Contact Information"
        path="ContactUs"
        size="small"
      />
    </ContentContainer>
  </ContactInfoContainer>
);

ContactInfo.propTypes = {
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  withBanner: T.bool,
};

export default ContactInfo;
