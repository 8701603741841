import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { pinLoginRequest, resetMainError } from '@dmi/shared/redux/Main/actions';
import { getError } from '@dmi/shared/redux/Main/selectors';
import { changeInput, toggleDrawer } from '@dmi/shared/redux/Login/actions';
import {
  getLoginPinDrawerNotifications,
  getPin,
  selectInputFocusState,
} from '@dmi/shared/redux/Login/selectors';

import { PinDrawerContent } from '../../components/Pin';

const LoginPin = ({
  dispatchChangeInput,
  dispatchPinLoginRequest,
  dispatchResetPinError,
  dispatchToggleDrawer,
  error,
  focusState,
  notifications,
  pin,
  ssoClientDetails,
}) => {
  useEffect(() => {
    dispatchChangeInput({ value: '' });
    dispatchResetPinError();
  }, [dispatchResetPinError, dispatchChangeInput]);

  const handleCompletePin = (value) => {
    dispatchPinLoginRequest({ pin: value, ssoClientDetails });
    dispatchChangeInput({ value: '' });
  };

  return (
    <PinDrawerContent
      cardTitle="Please enter your 6 digit pin number"
      error={error}
      focusState={focusState}
      handleBack={() => dispatchToggleDrawer(false)}
      handleChangeInput={dispatchChangeInput}
      handleCompletePin={handleCompletePin}
      notifications={notifications}
      passcodeInputFocusState={focusState}
      pinValue={pin}
    />
  );
};

LoginPin.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchPinLoginRequest: T.func.isRequired,
  dispatchResetPinError: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  focusState: T.oneOf([0, 1]).isRequired,
  notifications: T.array.isRequired,
  pin: T.string.isRequired,
  ssoClientDetails: T.object,
};

const mapStateToProps = createStructuredSelector({
  /**
  * Store: Login
  */
  focusState: selectInputFocusState('pin'),
  pin: getPin(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  error: getError('pin'),
  notifications: getLoginPinDrawerNotifications(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Login
  */
  dispatchChangeInput: ({ value }) => dispatch(changeInput({
    field: 'pin',
    form: 'pin',
    value,
  })),
  dispatchToggleDrawer: (payload) => dispatch(toggleDrawer(payload)),
  /**
  * Store: Main
  */
  // eslint-disable-next-line sort-keys
  dispatchPinLoginRequest: (payload) => dispatch(pinLoginRequest(payload)),
  dispatchResetPinError: () => dispatch(resetMainError('pin')),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoginPin);
