/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../scaleHelpers';

export default function BorrowerDesk(props) {
  return (
    <Svg data-name="Layer 1" height={moderateScale(120)} id="Layer_1" viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M249,56.44a19.38,19.38,0,0,1,4.83-3.22,27.34,27.34,0,0,1-3.3,10.45h-3.16Z" fill="#bac2ca" />
      <Path d="M220.9,55.3a38.31,38.31,0,0,1-2-10.56,19.3,19.3,0,0,1,4.14,3.65l1.79,10.73-2.58,2.51Z" fill="#bac2ca" />
      <Path d="M226.64,47.05s.18-9.21,1.5-12.68c0,0,3.39,7,4.37,11.12L229.22,51,226,49.4Z" fill="#bac2ca" />
      <Path d="M246.92,49.24a53.76,53.76,0,0,0,.72-8.41c-2,.78-5.35,4.79-5.35,4.79L241.61,52,245,54.52l2.22-2.93Z" fill="#bac2ca" />
      <Path d="M226.43,63.81s.68-13.1,3.18-18.48c0,0,4,5.28,5.09,18.24" fill="#e6ebef" />
      <Path d="M226.16,63.64a50.39,50.39,0,0,0-8.58-11.92s-.66,4.37,2.72,12.24" fill="#e6ebef" />
      <Path d="M223.61,60.55s-2.14-11.91-.62-21.94c0,0,5.25,8.31,7.24,16.86l-2.06,8.41-4.43,0Z" fill="#e6ebef" />
      <Path d="M230.72,64s7-22.92,11.61-30.4c0,0,1.69,6.73.91,19.48l-.59,10.82-11.93.1" fill="#e6ebef" />
      <Path d="M239.05,63.86a60.82,60.82,0,0,1,6-15.8s1.87,9.25.63,15.8" fill="#e6ebef" />
      <Path d="M249.18,63.7c1.95-8.23,1-19,1-19-4.12,5.33-4.41,7.72-4.41,7.72l-2.25,9.43.26,2.12Z" fill="#e6ebef" />
      <Path d="M238.25,45.06a88.71,88.71,0,0,0-2.87-15.48C233.23,35.15,230,51.1,230,51.1L232.74,58l5.41-1.08.23-11Z" fill="#e6ebef" />
      <Path d="M37.08,20.87H146.37A2.66,2.66,0,0,1,149,23.53V95a0,0,0,0,1,0,0H34.42a0,0,0,0,1,0,0V23.53A2.66,2.66,0,0,1,37.08,20.87Z" fill="#eff2f4" />
      <Polyline fill="#dce2e7" points="46.67 24.81 144.32 24.81 144.32 90.32 39.09 90.32 39.09 24.81 43.84 24.81" />
      <Path d="M149,95.46H34.42a.51.51,0,0,1-.5-.5V23.53a3.17,3.17,0,0,1,3.16-3.16H146.37a3.17,3.17,0,0,1,3.16,3.16V95A.51.51,0,0,1,149,95.46Zm-114.11-1H148.53V23.53a2.16,2.16,0,0,0-2.16-2.16H37.08a2.16,2.16,0,0,0-2.16,2.16Z" fill="#000000" />
      <Path d="M140.33,94.35H21.86v1.72a1.59,1.59,0,0,0,.89,1.42l3.55,1.12a3.14,3.14,0,0,0,1.35.31H155.8a3.14,3.14,0,0,0,1.35-.31l3.54-1.12a1.58,1.58,0,0,0,.9-1.42V94.35H140.33" fill="#FFFFFF" />
      <Rect fill="#dce2e7" height="2.25" width="123.02" x="22.02" y="94.43" />
      <Path d="M141.75,90.59H39.09a.28.28,0,0,1-.28-.27V24.81a.28.28,0,0,1,.28-.27h4.75a.27.27,0,0,1,.27.27.28.28,0,0,1-.27.28H39.36V90H141.75a.28.28,0,1,1,0,.55Z" fill="#000000" />
      <Path d="M144.32,87.35a.28.28,0,0,1-.28-.27v-62H46.67a.28.28,0,0,1-.27-.28.27.27,0,0,1,.27-.27h97.65a.28.28,0,0,1,.28.27V87.08A.28.28,0,0,1,144.32,87.35Z" fill="#000000" />
      <Polygon fill="#ffffff" points="97.37 89.69 88.28 89.69 131.2 25.44 140.29 25.44 97.37 89.69" />
      <Polygon fill="#ffffff" points="57.05 89.69 42.94 89.69 85.86 25.44 99.97 25.44 57.05 89.69" />
      <Path d="M97.44,49.49V65.33a1,1,0,0,1-1,1H51.2a1,1,0,0,1-1-1V39.55a1,1,0,0,1,1-1H96.44a1,1,0,0,1,1,1v7" fill="#ffffff" />
      <Path d="M96.44,66.6H51.2a1.27,1.27,0,0,1-1.27-1.27V39.55a1.27,1.27,0,0,1,1.27-1.27H96.44a1.27,1.27,0,0,1,1.27,1.27v7a.28.28,0,0,1-.55,0v-7a.72.72,0,0,0-.72-.72H51.2a.72.72,0,0,0-.72.72V65.33a.72.72,0,0,0,.72.72H96.44a.72.72,0,0,0,.72-.72V49.49a.28.28,0,1,1,.55,0V65.33A1.27,1.27,0,0,1,96.44,66.6Z" fill="#000000" />
      <Path d="M112.25,79.85H73.63a1,1,0,0,1-1-1V50a1,1,0,0,1,1-1h51.53a1,1,0,0,1,1,1v28.9a1,1,0,0,1-1,1h-9.65" fill="#ffffff" />
      <Path d="M125.16,80.12h-9.65a.28.28,0,0,1,0-.55h9.65a.72.72,0,0,0,.72-.72V50a.72.72,0,0,0-.72-.72H73.63a.72.72,0,0,0-.72.72v28.9a.72.72,0,0,0,.72.72h38.62a.28.28,0,1,1,0,.55H73.63a1.27,1.27,0,0,1-1.27-1.27V50a1.27,1.27,0,0,1,1.27-1.27h51.53A1.27,1.27,0,0,1,126.43,50v28.9A1.27,1.27,0,0,1,125.16,80.12Z" fill="#000000" />
      <Path d="M94.66,42.31H50.28A.28.28,0,0,1,50,42a.27.27,0,0,1,.27-.27H94.66a.28.28,0,0,1,.28.27A.29.29,0,0,1,94.66,42.31Z" fill="#000000" />
      <Path d="M124.28,52.46H75.52a.27.27,0,0,1-.27-.27.28.28,0,0,1,.27-.28h48.76a.28.28,0,0,1,.27.28A.27.27,0,0,1,124.28,52.46Z" fill="#000000" />
      <Path d="M155.8,99.42H27.65a3.57,3.57,0,0,1-1.57-.36L22.6,98a2.11,2.11,0,0,1-1.24-1.9V94.35a.5.5,0,0,1,.5-.5H143.3a.5.5,0,0,1,.5.5.5.5,0,0,1-.5.5H22.36v1.22A1.09,1.09,0,0,0,23,97l3.48,1.09a2.74,2.74,0,0,0,1.2.29H155.8a2.6,2.6,0,0,0,1.13-.26L160.54,97a1.09,1.09,0,0,0,.55-1V94.85H145.6a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h16a.5.5,0,0,1,.5.5v1.72a2.08,2.08,0,0,1-1.18,1.87l-3.61,1.15A3.44,3.44,0,0,1,155.8,99.42Z" fill="#000000" />
      <Path d="M155.8,99.25H27.65a3.36,3.36,0,0,1-1.49-.35l-3.5-1.1a1.89,1.89,0,0,1-.84-.73.33.33,0,0,1,.11-.45.32.32,0,0,1,.44.11,1.27,1.27,0,0,0,.53.47l3.5,1.1a2.87,2.87,0,0,0,1.25.3H155.8a2.86,2.86,0,0,0,1.21-.28l3.59-1.14a1.27,1.27,0,0,0,.48-.45.31.31,0,0,1,.44-.11.33.33,0,0,1,.11.45,2,2,0,0,1-.79.72l-3.59,1.13A3.32,3.32,0,0,1,155.8,99.25Z" fill="#000000" />
      <Path d="M37.12,97.17h-15a.27.27,0,0,1-.28-.27.28.28,0,0,1,.28-.28h15a.28.28,0,0,1,.27.28A.27.27,0,0,1,37.12,97.17Z" fill="#000000" />
      <Path d="M141,97.17H47.49a.28.28,0,0,1-.28-.27.29.29,0,0,1,.28-.28H141a.28.28,0,0,1,.27.28A.27.27,0,0,1,141,97.17Z" fill="#000000" />
      <Path d="M161.35,97.17H145.51a.27.27,0,0,1-.27-.27.28.28,0,0,1,.27-.28h15.84a.29.29,0,0,1,.28.28A.28.28,0,0,1,161.35,97.17Z" fill="#000000" />
      <Path d="M199.47,89.73l.09,13a3.65,3.65,0,0,1-1.44,3,8.13,8.13,0,0,1-5,1.38,20.93,20.93,0,0,1-5.12-.56,4.87,4.87,0,0,1-3.23-2.71l-2.05-4.47" fill="#e6ebef" />
      <Path d="M145.39,89.73l-.09,13a3.68,3.68,0,0,0,1.44,3,8.14,8.14,0,0,0,5,1.38,20.93,20.93,0,0,0,5.12-.56,4.83,4.83,0,0,0,3.22-2.71l2.12-4.61" fill="#e6ebef" />
      <Path d="M145.54,93.94s7.85,6.58,15.47,7.71l1-2.4a31.41,31.41,0,0,1-16.47-9.39Z" fill="#c9d2da" />
      <Path d="M199.24,93.94s-7.84,6.58-15.46,7.71l-1-2.4a31.37,31.37,0,0,0,16.46-9.39Z" fill="#c9d2da" />
      <Path d="M180.67,31.79a25.49,25.49,0,0,1,2.88.7s3.51-3.75,6.71-5.14,6.47-.91,8.17.3a7.89,7.89,0,0,1,3.63,5.74c.42,3-.91,8.23-.91,8.23l4.41,15.6s2.54,4.11,1.76,12.94-3.57,13.55-7.86,18.75-11.61,9.86-23.29,11.43-19.35-2.9-24.61-5.57-10.88-10.22-12.21-13.72a44.13,44.13,0,0,1-1-24.31l5-14.76s-2.18-5-.91-9.92a7.42,7.42,0,0,1,8.35-5.68c2.72.42,7.07,3.51,7.07,3.51l3,2.54a77.44,77.44,0,0,1,11.74-1.27,37.58,37.58,0,0,1,8.06.63" fill="#ffffff" />
      <Rect fill="#bac2ca" height="1.56" rx="0.78" width="10.04" x="166.77" y="33.46" />
      <Path d="M176,35.27h-8.48a1,1,0,0,1,0-2.06H176a1,1,0,1,1,0,2.06Zm-8.48-1.56a.53.53,0,0,0,0,1.06H176a.53.53,0,1,0,0-1.06Z" fill="#000000" />
      <Path d="M185.55,68.06a7.78,7.78,0,0,1,.75,3.3c0,5.66-6.21,10.25-13.86,10.25-4.86,0-9.12-1.85-11.6-4.65,1.86,4,7,6.94,13.11,6.94,7.65,0,13.86-4.58,13.86-10.24A8.44,8.44,0,0,0,185.55,68.06Z" fill="#dce2e7" />
      <Path d="M149.7,42s-2.72-7.21-1.64-10.3,7.85-3.58,15.33,3.36c0,0-6.41-9.45-14-8s-5.85,11.52-4.31,14.74Z" fill="#dce2e7" />
      <Path d="M145.06,64.78s.36,30.12,27.76,35.2c0,0-20.23.72-28.85-15.24,0,0-7.26-9.26-3.81-25.22Z" fill="#dce2e7" />
      <Path d="M172.41,48.42c2.57,0,2.76,1.5,2.76,1.5a37.38,37.38,0,0,0,5.45,10.72c3.58,4.51,10.53,7.08,19,5s7.9-13.6,8.15-15.29,2.51-2.69,2.51-2.69V42.9s-10.16-2.44-18.55-2.44a61.73,61.73,0,0,0-15,1.94,9.12,9.12,0,0,1-1,.21,27.26,27.26,0,0,1-3.05.23h-1a27.26,27.26,0,0,1-3-.23,9.12,9.12,0,0,1-1-.21,61.73,61.73,0,0,0-15-1.94c-8.39,0-18.55,2.44-18.55,2.44v4.71s2.26,1,2.51,2.69-.31,13.16,8.15,15.29,15.42-.44,19-5a37.38,37.38,0,0,0,5.45-10.72s.19-1.5,2.76-1.5Z" fill="#ffffff" />
      <Path d="M172.44,82.11c-7.92,0-14.36-4.82-14.36-10.75s6.44-10.75,14.36-10.75,14.36,4.82,14.36,10.75c0,3.44-2.22,6.69-5.93,8.71a.5.5,0,0,1-.48-.88c3.39-1.84,5.41-4.77,5.41-7.83,0-5.38-6-9.75-13.36-9.75S159.08,66,159.08,71.36s6,9.75,13.36,9.75a17.73,17.73,0,0,0,5.13-.75.51.51,0,0,1,.63.33.5.5,0,0,1-.34.62A18.37,18.37,0,0,1,172.44,82.11Z" fill="#000000" />
      <Rect fill="#000000" height="3.13" rx="0.85" width="1.69" x="167.83" y="69.57" />
      <Rect fill="#000000" height="3.13" rx="0.85" width="1.69" x="175.1" y="69.57" />
      <Path d="M142.88,44.48c3.59-2,11.24-1.94,11.24-1.94s9.64.06,11.17,4.77S162.06,59.89,158.7,62c0,0-3.82,3.12-12.17,1.71,0,0-5-.35-6.24-7.06,0,0-.88-2.18-.58-7.47,0,0-.42-2.65,3.17-4.65" fill="#9a87b5" />
      <Path d="M204.52,49.13s.42-2.65-3.17-4.65-11.24-1.94-11.24-1.94-9.64.06-11.17,4.77,3.23,12.58,6.59,14.64c0,0,3.82,3.12,12.17,1.71,0,0,5-.35,6.24-7.06C203.94,56.6,204.82,54.42,204.52,49.13Z" fill="#9a87b5" />
      <Path d="M150.73,64.35a25,25,0,0,1-4.25-.37s-5.24-.45-6.5-7.32c0,0-.89-2.24-.6-7.55,0,0-.4-2.82,3.34-4.91a12.06,12.06,0,0,1,2.92-1.07.31.31,0,0,1,.39.23.32.32,0,0,1-.24.4,11.65,11.65,0,0,0-2.75,1c-3.33,1.85-3,4.21-3,4.31-.28,5.2.56,7.38.57,7.4,1.19,6.42,5.76,6.84,6,6.85,8.12,1.38,11.91-1.6,11.95-1.63,3.43-2.11,7.89-9.94,6.48-14.29s-10.77-4.54-10.87-4.54a43.9,43.9,0,0,0-5.47.36.32.32,0,0,1-.08-.64,44.1,44.1,0,0,1,5.56-.37c.4,0,9.9.12,11.48,5s-3.46,13-6.73,15C158.77,62.32,156.2,64.35,150.73,64.35Z" fill="#000000" />
      <Path d="M193.51,64.35c-5.48,0-8.06-2-8.19-2.15-3.23-2-8.3-10-6.69-15s11.08-5,11.48-5,7.78,0,11.4,2,3.35,4.86,3.34,5c.29,5.24-.58,7.45-.61,7.54-.83,4.54-3.45,6.24-4.94,6.85a.34.34,0,0,1-.43-.18.33.33,0,0,1,.18-.42c1.67-.68,3.82-2.35,4.57-6.43,0-.08.87-2.26.58-7.39,0-.17.32-2.53-3-4.38s-11-1.89-11.07-1.9-9.43.11-10.87,4.54,3.05,12.18,6.45,14.27c.07,0,3.61,2.83,11.15,1.78a.32.32,0,0,1,.37.28.33.33,0,0,1-.28.37A25.41,25.41,0,0,1,193.51,64.35Z" fill="#000000" />
      <Path d="M143,41.29a.5.5,0,0,1-.47-.33c0-.1-3.74-10.49,3.63-14.21s15.63,5.85,16,6.26l1.1,1.39a.5.5,0,0,1-.08.7.49.49,0,0,1-.7-.08l-1.09-1.37c-.07-.08-8.14-9.34-14.76-6s-3.17,12.87-3.14,13a.49.49,0,0,1-.3.64A.47.47,0,0,1,143,41.29Z" fill="#000000" />
      <Path d="M161.19,32.75a.51.51,0,0,1-.49-.39.5.5,0,0,1,.38-.6,54.33,54.33,0,0,1,11.24-1.1.5.5,0,0,1,.5.5.51.51,0,0,1-.5.5,53.4,53.4,0,0,0-11,1.07Z" fill="#000000" />
      <Path d="M150.59,66.85h-.12a.5.5,0,0,1,0-1c5.35,0,10-2,12.82-5.52a37.07,37.07,0,0,0,5.36-10.55h0s.26-1.86,3.24-1.86h.52c3,0,3.25,1.86,3.26,1.94h0A37.14,37.14,0,0,0,181,60.33c3.84,4.85,10.93,6.68,18.48,4.78,7.07-1.78,7.53-10.52,7.7-13.81a9.06,9.06,0,0,1,.08-1.07c.23-1.56,1.82-2.57,2.5-2.93v-4A93.25,93.25,0,0,0,191.72,41a60.71,60.71,0,0,0-14.84,1.93,7.58,7.58,0,0,1-1.1.21,27.07,27.07,0,0,1-3.12.24h-1a26.7,26.7,0,0,1-3.11-.24,8.67,8.67,0,0,1-1.11-.21A60.79,60.79,0,0,0,152.58,41a93.25,93.25,0,0,0-18.05,2.34v4c.68.36,2.27,1.37,2.5,2.93a9.06,9.06,0,0,1,.08,1.07c.18,3.29.63,12,7.7,13.81a28,28,0,0,0,2.79.54.5.5,0,0,1-.14,1,27.25,27.25,0,0,1-2.89-.56c-7.79-2-8.27-11.24-8.46-14.73,0-.44,0-.78-.06-1-.21-1.39-2.2-2.3-2.22-2.31a.5.5,0,0,1-.3-.45V42.9a.49.49,0,0,1,.39-.48c.1,0,10.3-2.46,18.66-2.46a62.38,62.38,0,0,1,15.1,2,6.92,6.92,0,0,0,1,.19,25.19,25.19,0,0,0,3,.23h1a25.19,25.19,0,0,0,3-.23,6.92,6.92,0,0,0,1-.19,62.29,62.29,0,0,1,15.1-2c8.36,0,18.56,2.43,18.66,2.46a.49.49,0,0,1,.39.48v4.71a.5.5,0,0,1-.3.45s-2,.92-2.22,2.31c0,.2,0,.54-.06,1-.19,3.49-.67,12.77-8.45,14.73-8,2-15.42,0-19.51-5.13a37.43,37.43,0,0,1-5.54-10.88c0-.12-.23-1.15-2.28-1.15h-.52c-2,0-2.26,1-2.26,1.08a37.42,37.42,0,0,1-5.56,11C161.1,64.71,156.19,66.85,150.59,66.85Z" fill="#000000" />
      <Path d="M172.6,100.91h-.9c-12.42,0-22.29-4.69-29.35-13.92-8.88-11.62-5.3-27.62-5.26-27.78a.5.5,0,0,1,1,.23c0,.15-3.5,15.7,5.08,26.94,6.86,9,16.47,13.53,28.56,13.53h.9a38.77,38.77,0,0,0,14-2.43.51.51,0,0,1,.65.29.5.5,0,0,1-.29.65A39.9,39.9,0,0,1,172.6,100.91Z" fill="#000000" />
      <Path d="M193.85,94.67a.5.5,0,0,1-.28-.91,35.06,35.06,0,0,0,7.59-7.38c8.59-11.24,5.12-26.79,5.08-26.94a.5.5,0,1,1,1-.23c0,.16,3.62,16.16-5.26,27.78a35.92,35.92,0,0,1-7.81,7.59A.52.52,0,0,1,193.85,94.67Z" fill="#000000" />
      <Path d="M193.1,107.59a21.88,21.88,0,0,1-5.24-.57,5.36,5.36,0,0,1-3.56-3l-2.06-4.47a.51.51,0,0,1,.25-.67.5.5,0,0,1,.66.25l2.06,4.48a4.37,4.37,0,0,0,2.89,2.43,20.46,20.46,0,0,0,5,.54,7.7,7.7,0,0,0,4.72-1.28,3.16,3.16,0,0,0,1.24-2.56l-.09-13a.51.51,0,0,1,.5-.51h0a.51.51,0,0,1,.5.5l.09,13a4.14,4.14,0,0,1-1.64,3.36A8.68,8.68,0,0,1,193.1,107.59Z" fill="#000000" />
      <Path d="M151.77,107.59a8.71,8.71,0,0,1-5.33-1.48,4.17,4.17,0,0,1-1.64-3.36l.09-13a.51.51,0,0,1,.5-.5h0a.5.5,0,0,1,.49.51l-.09,13a3.18,3.18,0,0,0,1.24,2.56,7.75,7.75,0,0,0,4.73,1.28,20.44,20.44,0,0,0,5-.54,4.41,4.41,0,0,0,2.9-2.43l2-4.28a.49.49,0,0,1,.66-.24.5.5,0,0,1,.25.66l-2,4.27a5.38,5.38,0,0,1-3.56,3A21.94,21.94,0,0,1,151.77,107.59Z" fill="#000000" />
      <Path d="M201.77,39.85h-.12a.51.51,0,0,1-.37-.61c.65-2.62,1.59-9-3.54-11.58-6.63-3.34-14.69,5.92-14.77,6L181.9,35a.5.5,0,0,1-.79-.62L182.2,33c.36-.43,8.67-10,16-6.28,5.77,2.91,4.76,9.86,4.06,12.72A.48.48,0,0,1,201.77,39.85Z" fill="#000000" />
      <Path d="M180.71,32.3h-.08a58.58,58.58,0,0,0-8.65-.64.51.51,0,0,1-.5-.5.5.5,0,0,1,.5-.5,59,59,0,0,1,8.8.65.5.5,0,0,1-.07,1Z" fill="#000000" />
      <Path d="M247.2,64.06V82.43A15.28,15.28,0,0,1,231.92,97.7h-6a15,15,0,0,0,4.82.79h7.9A15.28,15.28,0,0,0,253.9,83.22V64.06Z" fill="#e6ebef" />
      <Rect fill="#dad3e4" height="4.5" width="37.57" x="215.87" y="72.22" />
      <Path d="M238.62,99h-7.9A15.84,15.84,0,0,1,215.3,86.56a.5.5,0,1,1,1-.21A14.86,14.86,0,0,0,230.72,98h7.9A14.79,14.79,0,0,0,253.4,83.22V64.56H215.94V83.22a.5.5,0,0,1-1,0V64.06a.5.5,0,0,1,.5-.5H253.9a.5.5,0,0,1,.5.5V83.22A15.79,15.79,0,0,1,238.62,99Z" fill="#000000" />
      <Path d="M253.71,77.24h-38a.27.27,0,0,1-.28-.27V72a.27.27,0,0,1,.28-.27h21.3a.28.28,0,0,1,0,.55H216v4.39h37.4V72.3H241.71a.28.28,0,0,1,0-.55h12A.27.27,0,0,1,254,72v5A.27.27,0,0,1,253.71,77.24Z" fill="#000000" />
      <Path d="M27.16,99.43H14.56a.28.28,0,0,1-.28-.28.27.27,0,0,1,.28-.27h12.6a.27.27,0,0,1,.27.27A.28.28,0,0,1,27.16,99.43Z" fill="#000000" />
      <Path d="M12,99.43H8.2a.28.28,0,0,1-.28-.28.27.27,0,0,1,.28-.27H12a.27.27,0,0,1,.27.27A.28.28,0,0,1,12,99.43Z" fill="#000000" />
      <Path d="M228.48,98.92h-12.6a.28.28,0,0,1-.27-.28.27.27,0,0,1,.27-.27h12.6a.27.27,0,0,1,.28.27A.28.28,0,0,1,228.48,98.92Z" fill="#000000" />
      <Path d="M212.44,98.92H207a.28.28,0,0,1-.28-.28.27.27,0,0,1,.28-.27h5.49a.28.28,0,0,1,.28.27A.29.29,0,0,1,212.44,98.92Z" fill="#000000" />
      <Path d="M147.75,106.61h-9.93a.28.28,0,1,1,0-.55h9.93a.28.28,0,0,1,0,.55Z" fill="#000000" />
      <Path d="M163.12,106.61h-5.61a.28.28,0,0,1,0-.55h5.61a.28.28,0,1,1,0,.55Z" fill="#000000" />
      <Path d="M187.12,106.61h-5.61a.28.28,0,0,1,0-.55h5.61a.28.28,0,1,1,0,.55Z" fill="#000000" />
      <Path d="M209.42,106.61H200a.28.28,0,0,1,0-.55h9.39a.28.28,0,1,1,0,.55Z" fill="#000000" />
      <Path d="M256.9,98.92H240.42a.29.29,0,0,1-.28-.28.28.28,0,0,1,.28-.27H256.9a.28.28,0,0,1,.28.27A.29.29,0,0,1,256.9,98.92Z" fill="#000000" />
      <Path d="M266,98.92h-4.2a.29.29,0,0,1-.28-.28.28.28,0,0,1,.28-.27H266a.28.28,0,0,1,.28.27A.29.29,0,0,1,266,98.92Z" fill="#000000" />
      <Path d="M226.43,64.09h0a.27.27,0,0,1-.25-.29c0-.54.71-13.22,3.2-18.59a.27.27,0,0,1,.22-.16.28.28,0,0,1,.25.11c.16.22,4,5.49,5.14,18.39a.27.27,0,0,1-.25.29.28.28,0,0,1-.3-.25c-.93-10.77-3.86-16.23-4.76-17.69-2.28,5.53-2.95,17.8-3,17.93A.26.26,0,0,1,226.43,64.09Z" fill="#000000" />
      <Path d="M220.3,64.24a.27.27,0,0,1-.25-.17c-3.37-7.86-2.77-12.21-2.75-12.39a.3.3,0,0,1,.19-.23.27.27,0,0,1,.28.08,53.78,53.78,0,0,1,7.42,9.75.28.28,0,0,1-.48.28,53.44,53.44,0,0,0-6.9-9.2c0,1.45.15,5.44,2.74,11.49a.27.27,0,0,1-.14.36A.23.23,0,0,1,220.3,64.24Z" fill="#000000" />
      <Path d="M222.5,51.47a.28.28,0,0,1-.28-.26,60.68,60.68,0,0,1,.5-12.64.27.27,0,0,1,.2-.23.26.26,0,0,1,.28.1,31.08,31.08,0,0,1,5.21,11.13.28.28,0,0,1-.54.12,31.33,31.33,0,0,0-4.7-10.38,60.71,60.71,0,0,0-.4,11.87.28.28,0,0,1-.26.29Z" fill="#000000" />
      <Path d="M223.25,58.22A.27.27,0,0,1,223,58s-.36-1.88-.61-4.77a.27.27,0,0,1,.25-.3.26.26,0,0,1,.3.25c.25,2.86.6,4.69.6,4.71a.27.27,0,0,1-.22.32Z" fill="#000000" />
      <Path d="M234.25,53.52h-.09a.29.29,0,0,1-.18-.35c1.25-4,4.54-13.86,8.11-19.67a.29.29,0,0,1,.27-.13.28.28,0,0,1,.23.19c.07.22,1.67,5.53.89,18.18a.26.26,0,0,1-.29.26.28.28,0,0,1-.26-.29c.62-10.14-.32-15.61-.71-17.35-3.41,5.81-6.51,15.15-7.71,19A.28.28,0,0,1,234.25,53.52Z" fill="#000000" />
      <Path d="M239.05,64.14H239a.27.27,0,0,1-.21-.32,61.56,61.56,0,0,1,6.08-15.88.29.29,0,0,1,.28-.14.3.3,0,0,1,.23.22c0,.08,1.54,7.75.9,14a.26.26,0,0,1-.3.24.28.28,0,0,1-.25-.3A53.39,53.39,0,0,0,245,48.86a61.19,61.19,0,0,0-5.64,15.06A.28.28,0,0,1,239.05,64.14Z" fill="#000000" />
      <Path d="M249.18,64h-.07a.27.27,0,0,1-.2-.33c1.63-6.88,1.22-15.62,1.05-18.16-3.58,4.78-3.9,7-3.9,7a.27.27,0,0,1-.31.24.28.28,0,0,1-.24-.31c0-.1.35-2.52,4.46-7.85a.28.28,0,0,1,.29-.1.29.29,0,0,1,.2.24c0,.11.93,10.87-1,19.06A.28.28,0,0,1,249.18,64Z" fill="#000000" />
      <Path d="M250.54,63.94a.31.31,0,0,1-.13,0,.29.29,0,0,1-.11-.38,27.45,27.45,0,0,0,3.21-9.84,12.25,12.25,0,0,0-2.75,1.72.3.3,0,0,1-.11.17.37.37,0,0,1-.34,0,.29.29,0,0,1-.15-.25c0-.39,1.43-1.36,3.56-2.4a.27.27,0,0,1,.39.26,27.64,27.64,0,0,1-3.33,10.57A.27.27,0,0,1,250.54,63.94Z" fill="#000000" />
      <Path d="M220.9,55.58a.29.29,0,0,1-.26-.19,39.27,39.27,0,0,1-2-10.65.27.27,0,0,1,.15-.24.26.26,0,0,1,.28,0c2.69,1.79,3.27,2.93,3.29,3a.26.26,0,0,1-.13.36.26.26,0,0,1-.36-.12,8.8,8.8,0,0,0-2.66-2.46,40.06,40.06,0,0,0,1.92,9.95.28.28,0,0,1-.17.35Z" fill="#000000" />
      <Path d="M231,47.41h0a.28.28,0,0,1-.22-.33c0-.12,2.28-12.07,4.41-17.6a.32.32,0,0,1,.27-.18.3.3,0,0,1,.26.2,83.21,83.21,0,0,1,2.13,11,.29.29,0,0,1-.24.31.28.28,0,0,1-.31-.24,82,82,0,0,0-1.89-10c-2,5.77-4.07,16.58-4.09,16.7A.28.28,0,0,1,231,47.41Z" fill="#000000" />
      <Path d="M226.77,45c-.14,0-.27-.13-.27-.28s.06-6.92,1.38-10.4a.28.28,0,0,1,.24-.18.29.29,0,0,1,.26.14,36.49,36.49,0,0,1,3.55,8.49.27.27,0,0,1-.2.33.28.28,0,0,1-.33-.2A34.54,34.54,0,0,0,228.2,35c-1.1,3.52-1.15,9.57-1.15,9.64A.28.28,0,0,1,226.77,45Z" fill="#000000" />
      <Path d="M246.92,49.51h-.06a.27.27,0,0,1-.2-.33,51,51,0,0,0,.7-7.93,13.5,13.5,0,0,0-3.61,3.14.28.28,0,1,1-.43-.35c.09-.1,2.16-2.67,4.22-3.47a.27.27,0,0,1,.26,0,.29.29,0,0,1,.12.23,55.42,55.42,0,0,1-.73,8.47A.27.27,0,0,1,246.92,49.51Z" fill="#000000" />
    </Svg>
  );
}
