/*
 * SelectLoan
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/utils/languageDictionary/navigationMessages';

import {
  changeSelectedLoan,
  resetMainError,
} from '@dmi/shared/redux/Main/actions';
import makeSelectMain, {
  getError,
  getMainNotifications,
  getSelectLoanProps,
} from '@dmi/shared/redux/Main/selectors';

import { StyledScrollView } from '../../components/base_ui';
import { SecondaryHeader } from '../../components/ScreenHeaders';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import SelectLoan from '../../components/SelectLoan';

const SelectLoanScreen = ({
  dispatchChangeSelectedLoan,
  dispatchResetMainError,
  error,
  navigation,
  notifications,
  selectLoanProps,
}) => {
  useEffect(() => () => {
    dispatchResetMainError();
  }, [dispatchResetMainError]);

  const { formatMessage } = useIntl();

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={formatMessage({ id: INTL_IDS.LOANS_MODAL_HEADER })}
      />
      <StyledScrollView showsVerticalScrollIndicator={false}>
        <ScreenReaderNotifications notifications={notifications} />
        <SelectLoan
          dispatchChangeSelectedLoan={dispatchChangeSelectedLoan}
          error={error}
          {...selectLoanProps}
        />
      </StyledScrollView>
    </Fragment>
  );
};

SelectLoanScreen.propTypes = {
  dispatchChangeSelectedLoan: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  loading: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  selectLoanProps: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  error: getError('main'),
  loading: makeSelectMain('loading'),
  notifications: getMainNotifications(),
  selectLoanProps: getSelectLoanProps(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Main
   */
  dispatchChangeSelectedLoan: (payload) => dispatch(changeSelectedLoan(payload)),
  dispatchResetMainError: () => dispatch(resetMainError()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SelectLoanScreen);
