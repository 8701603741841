import React, { forwardRef, useState } from 'react';
import { View } from 'react-native';

import { StyledIconButton } from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';
import LoginRegisterTextField from '../LoginRegisterTextField';

const VisibilityOffIcon = iconDictionary('visibilityOff');
const VisibilityOnIcon = iconDictionary('visibilityOn');

const PasswordTextField = forwardRef((props, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibilityPress = () => {
    setShowPassword(!showPassword);
  };

  return (
    <View>
      <LoginRegisterTextField
        ref={ref}
        label="Password"
        rightIcon={(
          <StyledIconButton
            accessibilityState={{ checked: showPassword }}
            aria-checked={showPassword}
            aria-label="password-visibility"
            Icon={showPassword ? VisibilityOnIcon : VisibilityOffIcon}
            onPress={handlePasswordVisibilityPress}
            role="switch"
          />
        )}
        secureTextEntry={!showPassword}
        {...props}
      />
    </View>
  );
});

export default PasswordTextField;
