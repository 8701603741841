/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function SelectLoanSpring(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 327 150"
      height={moderateScale(150)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 327 150"
      width={moderateScale(327)}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <Path
        d="M89.132,61c2.127,0,3.569-2.152,2.774-4.125c-1.684-4.179-5.246-10.126-12.023-10.828 c-2.854-0.296-5.506,0.833-7.592,2.225c-0.186,0.124-0.428,0.1-0.593-0.052c-1.44-1.334-3.516-2.172-5.825-2.172 c-4.348,0-7.874,2.969-7.874,6.631l0,0.006c0,0.285-0.242,0.508-0.526,0.483c-0.211-0.019-0.425-0.029-0.642-0.029 c-2.673,0-4.937,1.458-5.743,3.481c-0.1,0.252-0.383,0.369-0.631,0.26c-0.709-0.311-1.515-0.51-2.37-0.487 c-1.951,0.052-3.224,1.14-4.014,2.246C43.367,59.627,44.06,61,45.275,61L89.132,61z"
        fill="#DAE1E8"
      />
      <Rect fill="#E7EBEE" height="2.299" width="7.809" x="109.107" y="38.848" />
      <Path
        d="M117.549,36.565v2.129l-0.473,0.095v25.514l4.684-5.11V38.694c0,0,0.662-0.52,0.615-0.71 c-0.047-0.189-0.237-1.23-0.237-1.23l-0.331-0.331L117.549,36.565z"
        fill="#BAC2CA"
      />
      <Path
        d="M117.013,64.671h-8.047c-0.226,0-0.409-0.183-0.409-0.409V38.803c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.183,0.409,0.409v25.049h7.463l4.556-4.773V38.879c0-0.226,0.183-0.409,0.409-0.409s0.409,0.183,0.409,0.409v20.365 c0,0.105-0.041,0.207-0.113,0.282l-4.79,5.018C117.231,64.625,117.125,64.671,117.013,64.671z"
        fill="#010101"
      />
      <Path
        d="M116.991,58c-0.15,0-0.273-0.123-0.273-0.273V38.743c0-0.15,0.123-0.273,0.273-0.273 s0.273,0.123,0.273,0.273v18.984C117.264,57.877,117.141,58,116.991,58z"
        fill="#010101"
      />
      <Path
        d="M121.67,39.063h-12.998c-0.634,0-1.15-0.516-1.15-1.15v-0.739c0-0.634,0.516-1.15,1.15-1.15h12.998 c0.634,0,1.15,0.516,1.15,1.15v0.739C122.821,38.547,122.305,39.063,121.67,39.063z M108.673,36.841 c-0.183,0-0.332,0.149-0.332,0.332v0.739c0,0.183,0.149,0.332,0.332,0.332h12.998c0.183,0,0.332-0.149,0.332-0.332v-0.739 c0-0.183-0.149-0.332-0.332-0.332H108.673z"
        fill="#010101"
      />
      <Path
        d="M117.479,38.705c-0.15,0-0.273-0.123-0.273-0.273v-1.866c0-0.15,0.123-0.273,0.273-0.273 s0.273,0.123,0.273,0.273v1.866C117.752,38.582,117.63,38.705,117.479,38.705z"
        fill="#010101"
      />
      <Polygon fill="#FFFFFF" points="189.062,143.837 189.062,71.996 141.68,35.766 98.14,70.035 98.14,143.94 " />
      <Rect fill="#E7EBEE" height="71.001" width="27.604" x="187.995" y="72.689" />
      <Path
        d="M192.619,81.222h23.384v-2.076l-23.969-0.297l-3.779-3.559l-0.094,2.867l2.48,2.305 C191.163,80.949,191.875,81.222,192.619,81.222z"
        fill="#BAC2CA"
      />
      <Path
        d="M197.285,101.763c0,0-3.486,7.114,1.138,13.588c4.624,6.474,16.576,7.612,16.576,7.612v-10.743 L197.285,101.763z"
        fill="#BAC2CA"
      />
      <Path
        d="M215.362,143.734c-0.226,0-0.409-0.184-0.409-0.409V69.579c0-0.226,0.184-0.409,0.409-0.409 s0.409,0.183,0.409,0.409v73.746C215.771,143.551,215.588,143.734,215.362,143.734z"
        fill="#010101"
      />
      <Path
        d="M222.972,78.93h-27.969v-3.557h27.969c0.9,0,1.63,0.73,1.63,1.63V77.3 C224.602,78.2,223.872,78.93,222.972,78.93z"
        fill="#E7EBEE"
      />
      <Path
        d="M223.111,79.257h-29.968c-0.226,0-0.409-0.184-0.409-0.409s0.184-0.409,0.409-0.409h29.968 c0.226,0,0.409,0.184,0.409,0.409S223.337,79.257,223.111,79.257z"
        fill="#010101"
      />
      <Path
        d="M222.654,79.24h-2.475c-0.226,0-0.409-0.184-0.409-0.409s0.184-0.409,0.409-0.409h2.475 c0.812,0,1.474-0.592,1.474-1.32s-0.661-1.321-1.474-1.321h-2.475c-0.226,0-0.409-0.183-0.409-0.409s0.184-0.409,0.409-0.409h2.475 c1.264,0,2.292,0.959,2.292,2.139S223.918,79.24,222.654,79.24z"
        fill="#010101"
      />
      <G>
        <Polygon fill="#BAC2CA" points="222.974,74.814 170.982,34.002 142.099,34.002 194.651,75.372 189.965,75.372" />
        <Path
          d="M142.824,34.789c-0.372-0.376-0.918-0.588-1.455-0.579c-0.553,0.008-1.075,0.234-1.434,0.621L89.508,75.944 c-0.69,0.743-0.593,1.86,0.216,2.493c0.81,0.634,2.024,0.545,2.713-0.198l49.009-39.584l50.5,39.589 c0.38,0.384,0.901,0.579,1.425,0.579c0.462,0,0.925-0.152,1.294-0.46c0.787-0.657,0.845-1.775,0.13-2.498L142.824,34.789z"
          fill="#FFFFFF"
        />
        <Polygon
          display="none"
          fill="#FFFFFF"
          points="141.289,38.729 172.025,69.693 174.407,107.348 109.615,107.348 109.615,79.016  104.236,79.016 "
        />
        <Polygon fill="#BAC2CA" points="97.455,73.348 97.455,78.958 92.21,79.082" />
        <Path
          d="M97.443,79.306h-5.389c-0.226,0-0.409-0.184-0.409-0.409s0.183-0.409,0.409-0.409h5.389 c0.226,0,0.409,0.184,0.409,0.409S97.669,79.306,97.443,79.306z"
          fill="#010101"
        />
        <Polygon fill="#BAC2CA" points="143.441,40.604 97.915,77.1 97.915,73.739 141.391,38.978" />
        <Path
          d="M223.448,75.781h-28.674c-0.226,0-0.409-0.183-0.409-0.409s0.184-0.409,0.409-0.409h27.494L170.84,34.411 h-12.162c-0.226,0-0.409-0.183-0.409-0.409s0.183-0.409,0.409-0.409h12.303c0.092,0,0.181,0.031,0.253,0.088l52.467,41.37 c0.137,0.108,0.19,0.291,0.134,0.456C223.777,75.671,223.622,75.781,223.448,75.781z"
          fill="#010101"
        />
        <Path
          d="M155.941,34.411h-1.793c-0.226,0-0.409-0.183-0.409-0.409s0.183-0.409,0.409-0.409h1.793 c0.226,0,0.409,0.183,0.409,0.409S156.167,34.411,155.941,34.411z"
          fill="#010101"
        />
        <Path
          d="M141.328,34.48c-0.225,0-0.408-0.182-0.409-0.406c-0.001-0.226,0.181-0.411,0.406-0.412l10.274-0.069 c0.001,0,0.002,0,0.003,0c0.225,0,0.408,0.182,0.409,0.406c0.001,0.226-0.181,0.411-0.406,0.412l-10.274,0.069 C141.33,34.48,141.329,34.48,141.328,34.48z"
          fill="#010101"
        />
        <Path
          d="M90.984,79.27c-0.533,0-1.073-0.167-1.513-0.511c-0.486-0.38-0.78-0.915-0.827-1.506 c-0.046-0.582,0.153-1.146,0.563-1.587l50.469-41.151c0.392-0.429,1.022-0.703,1.686-0.713c0.672,0.002,1.289,0.244,1.733,0.682 l51.951,41.061c0.462,0.462,0.682,1.022,0.654,1.607c-0.028,0.591-0.304,1.133-0.775,1.526c-0.911,0.763-2.443,0.692-3.271-0.146 L141.45,39.178l-48.756,39.38C92.264,79.025,91.629,79.27,90.984,79.27z M141.402,34.619c-0.009,0-0.017,0-0.026,0 c-0.443,0.006-0.858,0.185-1.141,0.49L89.766,76.261c-0.21,0.232-0.333,0.576-0.306,0.928c0.029,0.359,0.211,0.688,0.515,0.926 c0.645,0.506,1.614,0.437,2.162-0.153l49.052-39.625c0.148-0.119,0.359-0.121,0.509-0.003l50.5,39.589 c0.614,0.613,1.605,0.627,2.204,0.128c0.294-0.246,0.465-0.578,0.482-0.938c0.017-0.354-0.119-0.694-0.382-0.959L142.571,35.11 c-0.013-0.01-0.026-0.021-0.037-0.033C142.249,34.79,141.828,34.619,141.402,34.619z"
          fill="#010101"
        />
      </G>
      <Path
        d="M97.338,126.072c-0.845-0.138-1.712-0.21-2.596-0.21c-5.274,0-9.947,2.562-12.85,6.507 c-0.581,0.79-1.603,1.081-2.531,0.763c-1.129-0.387-2.34-0.597-3.6-0.597c-3.505,0-6.632,1.622-8.67,4.155 c0,0-2.222,2.911-2.4,6.717h32.762L97.338,126.072z"
        fill="#FFFFFF"
      />
      <Path
        d="M68.148,135.747c0,0,3.394-2.768,8.864-1.516c5.47,1.252,6.129-0.066,7.777-2.043s8.008-4.778,12.786-3.427 v-2.603c0,0-5.569-1.219-10.249,1.549c-4.679,2.768-5.338,4.91-6.063,5.207c-0.725,0.297-1.022,0.461-3.262-0.165 s-7.349,0.132-9.952,3.131"
        fill="#DCE2E7"
      />
      <Path
        d="M64.418,143.869c-0.005,0-0.01,0-0.014,0c-0.226-0.008-0.403-0.197-0.395-0.423 c0.11-3.234,2.64-6.841,2.747-6.993c2.218-2.758,5.494-4.328,9.004-4.328c1.279,0,2.535,0.209,3.733,0.62 c0.771,0.264,1.603,0.014,2.069-0.619c3.075-4.179,8.001-6.674,13.179-6.674c0.89,0,1.785,0.072,2.662,0.216 c0.223,0.036,0.374,0.246,0.338,0.469c-0.037,0.224-0.248,0.374-0.47,0.338c-0.833-0.136-1.685-0.204-2.53-0.204 c-4.918,0-9.599,2.37-12.52,6.34c-0.68,0.923-1.883,1.289-2.993,0.908c-1.113-0.382-2.279-0.575-3.468-0.575 c-3.261,0-6.306,1.459-8.352,4.003c-0.01,0.015-2.481,3.541-2.583,6.527C64.819,143.695,64.638,143.869,64.418,143.869z"
        fill="#010101"
      />
      <Path
        d="M97.595,131.627c-0.226,0-0.409-0.184-0.409-0.409v-29.359c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.184,0.409,0.409v29.359C98.004,131.443,97.821,131.627,97.595,131.627z"
        fill="#010101"
      />
      <Path
        d="M97.595,99.138c-0.226,0-0.409-0.184-0.409-0.409V74.397c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.183,0.409,0.409v24.332C98.004,98.954,97.821,99.138,97.595,99.138z"
        fill="#010101"
      />
      <Path
        d="M187.876,143.815c-0.226,0-0.409-0.184-0.409-0.409V75.108c0-0.226,0.184-0.409,0.409-0.409 s0.409,0.183,0.409,0.409v68.298C188.285,143.632,188.102,143.815,187.876,143.815z"
        fill="#010101"
      />
      <Path
        d="M93.289,133.746c0,0,6.131-1.517,9.418,0.253c3.287,1.77,3.982,4.425,5.689,5.246 c1.707,0.822,3.224,0.506,4.867-0.569c1.643-1.075,7.459-0.569,8.786,3.793c0,0-1.201-6.258-6.89-5.942 c-5.689,0.316-3.666,2.592-6.321-0.632C106.184,132.672,101.127,128.374,93.289,133.746z"
        fill="#DCE2E7"
      />
      <Path
        d="M122.348,144.165c-0.104,0-0.208-0.039-0.287-0.118c-0.102-0.101-0.14-0.24-0.114-0.37 c0.042-1.865-0.635-3.614-1.911-4.931c-1.226-1.265-2.854-1.961-4.583-1.961c-1.303,0-2.56,0.398-3.636,1.153 c-0.416,0.29-0.934,0.373-1.425,0.229c-0.522-0.154-0.958-0.552-1.166-1.064c-1.337-3.304-5.024-5.523-9.174-5.523 c-3.234,0-6.244,1.353-8.051,3.619c-0.657,0.823-1.734,1.154-2.744,0.841c-0.91-0.282-1.864-0.426-2.834-0.426 c-0.226,0-0.409-0.184-0.409-0.409s0.183-0.409,0.409-0.409c1.052,0,2.087,0.156,3.077,0.463c0.686,0.212,1.416-0.011,1.862-0.569 c1.961-2.459,5.21-3.928,8.691-3.928c4.479,0,8.471,2.426,9.933,6.035c0.114,0.283,0.353,0.502,0.639,0.587 c0.254,0.074,0.512,0.033,0.724-0.114c1.214-0.852,2.634-1.302,4.105-1.302c1.953,0,3.79,0.785,5.171,2.21 c1.446,1.492,2.206,3.478,2.139,5.59c-0.003,0.104-0.046,0.203-0.12,0.276C122.562,144.124,122.455,144.165,122.348,144.165z"
        fill="#010101"
      />
      <G>
        <Path d="M86.422,135.204c-0.486,0-0.963,0.035-1.429,0.101" fill="#FFFFFF" />
        <Path
          d="M84.992,135.715c-0.2,0-0.375-0.146-0.404-0.351c-0.032-0.224,0.123-0.431,0.346-0.464 c0.485-0.069,0.986-0.105,1.488-0.105c0.226,0,0.409,0.184,0.409,0.409s-0.183,0.409-0.409,0.409c-0.463,0-0.924,0.032-1.371,0.098 C85.031,135.714,85.012,135.715,84.992,135.715z"
          fill="#010101"
        />
      </G>
      <Path
        d="M183.939,77.285h-83.238c-0.15,0-0.273-0.122-0.273-0.272s0.123-0.272,0.273-0.272h83.238 c0.15,0,0.272,0.122,0.272,0.272S184.09,77.285,183.939,77.285z"
        fill="#010101"
      />
      <Path
        d="M186.358,74.385h-22.481c-0.15,0-0.273-0.123-0.273-0.273s0.123-0.273,0.273-0.273h22.481 c0.15,0,0.272,0.123,0.272,0.273S186.509,74.385,186.358,74.385z"
        fill="#010101"
      />
      <Path
        d="M161.031,74.385h-55.776c-0.15,0-0.273-0.123-0.273-0.273s0.123-0.273,0.273-0.273h55.776 c0.15,0,0.273,0.123,0.273,0.273S161.182,74.385,161.031,74.385z"
        fill="#010101"
      />
      <Path
        d="M197.42,45c-1.808,0-2.781-2.122-1.603-3.495c1.985-2.314,5.965-3.893,10.552-3.893 c0.747,0,1.475,0.044,2.182,0.126c0.776,0.09,1.451-0.534,1.449-1.315l0-0.017c0-3.997,5.056-7.237,11.294-7.237 c1.737,0,3.381,0.253,4.85,0.702c0.687,0.21,1.43-0.202,1.585-0.904c1.311-5.939,7.671-10.448,15.339-10.448 c8.618,0,15.604,5.692,15.604,12.713c0,0.251-0.029,0.496-0.046,0.743c0.36-0.055,0.726-0.093,1.102-0.093 c3.222,0,5.948,2.107,6.884,5.018c0.21,0.654,0.892,1.025,1.553,0.836c0.625-0.179,1.284-0.275,1.967-0.275 c2.296,0,4.342,1.069,5.667,2.736c1.541,1.938,0.147,4.803-2.329,4.803H197.42z"
        fill="#E7EBEE"
      />
      <Path
        d="M77.447,144.409c-0.226,0-0.41-0.183-0.41-0.409v-27.166c0-0.227,0.184-0.409,0.41-0.409 s0.41,0.183,0.41,0.409V144C77.856,144.227,77.673,144.409,77.447,144.409z"
        fill="#010101"
      />
      <Path
        d="M273.478,144.343h-2.721c-0.226,0-0.409-0.184-0.409-0.409s0.183-0.409,0.409-0.409h2.721 c0.226,0,0.409,0.184,0.409,0.409S273.704,144.343,273.478,144.343z"
        fill="#010101"
      />
      <Path
        d="M268,144.289H31c-0.196,0-0.355-0.159-0.355-0.355s0.159-0.355,0.355-0.355h237 c0.196,0,0.355,0.159,0.355,0.355S268.196,144.289,268,144.289z"
        fill="#010101"
      />
      <Ellipse cx="142.391" cy="59.83" fill="#F0F1F0" rx="4.48" ry="5.276" />
      <Path
        d="M141.556,66.033c-3.421,0-6.205-2.844-6.205-6.34s2.783-6.34,6.205-6.34c3.421,0,6.204,2.844,6.204,6.34 S144.977,66.033,141.556,66.033z M141.556,54.17c-2.97,0-5.386,2.477-5.386,5.522s2.416,5.522,5.386,5.522 c2.97,0,5.386-2.477,5.386-5.522S144.526,54.17,141.556,54.17z"
        fill="#010101"
      />
      <Path
        d="M142.383,65.826c-0.019,0-0.037-0.002-0.055-0.006c-2.942-0.604-5.078-3.156-5.078-6.068 c0-2.066,1.059-3.99,2.833-5.147c0.127-0.082,0.296-0.046,0.377,0.08c0.082,0.126,0.046,0.295-0.08,0.377 c-1.619,1.056-2.585,2.809-2.585,4.69c0,2.654,1.952,4.981,4.641,5.534c0.148,0.03,0.243,0.174,0.212,0.322 C142.623,65.737,142.509,65.826,142.383,65.826z"
        fill="#010101"
      />
      <G>
        <Polyline fill={svgHighlight} points="152.725,132.505 152.725,98.282 171.425,98.282 171.425,132.505" />
        <Path
          d="M171.425,132.914c-0.226,0-0.409-0.184-0.409-0.409V98.691h-17.882v33.813c0,0.226-0.183,0.409-0.409,0.409 s-0.409-0.184-0.409-0.409V98.282c0-0.226,0.183-0.409,0.409-0.409h18.7c0.226,0,0.409,0.184,0.409,0.409v34.223 C171.834,132.73,171.65,132.914,171.425,132.914z"
          fill="#010101"
        />
      </G>
      <Path
        d="M170.808,143.958h-29.1c-0.226,0-0.409-0.184-0.409-0.409v-5.569c0-0.226,0.183-0.409,0.409-0.409h4.879 v-5.049c0-0.226,0.183-0.409,0.409-0.409h23.812c0.226,0,0.409,0.184,0.409,0.409v11.027 C171.217,143.774,171.033,143.958,170.808,143.958z M142.117,143.14h28.281v-10.209h-22.993v5.049c0,0.226-0.183,0.409-0.409,0.409 h-4.879V143.14z"
        fill="#010101"
      />
      <G>
        <Polygon
          fill="#BAC2CA"
          points="168.081,132.521 168.081,137.98 162.793,137.98 162.793,143.549 168.081,143.549 168.687,143.549  174.746,143.549 174.746,132.521 "
        />
        <Path
          d="M174.746,143.958h-11.953c-0.226,0-0.409-0.184-0.409-0.409v-5.569c0-0.226,0.183-0.409,0.409-0.409h4.879 v-5.049c0-0.226,0.184-0.409,0.409-0.409h6.665c0.226,0,0.409,0.184,0.409,0.409v11.027 C175.155,143.774,174.972,143.958,174.746,143.958z M163.202,143.14h11.135v-10.209h-5.847v5.049c0,0.226-0.184,0.409-0.409,0.409 h-4.879V143.14z"
          fill="#010101"
        />
      </G>
      <Path
        d="M142.327,63.688c-0.15,0-0.273-0.123-0.273-0.273v-7.947c0-0.15,0.123-0.273,0.273-0.273 s0.273,0.123,0.273,0.273v7.947C142.6,63.566,142.478,63.688,142.327,63.688z"
        fill="#010101"
      />
      <Path
        d="M147.171,59.715h-8.304c-0.15,0-0.273-0.123-0.273-0.273s0.123-0.273,0.273-0.273h8.304 c0.15,0,0.273,0.123,0.273,0.273S147.321,59.715,147.171,59.715z"
        fill="#010101"
      />
      <Rect fill="#E7EBEE" height="24.563" width="29.109" x="109.355" y="90.215" />
      <Polygon fill="#FFFFFF" opacity="0.7" points="108.1,109.754 115.186,90.215 108.798,90.215 108.1,91.817" />
      <Polygon fill="#FFFFFF" opacity="0.7" points="116.936,114.778 112.962,114.778 122.235,90.215 126.209,90.215" />
      <Path
        d="M138.701,115.381h-30.717c-0.226,0-0.409-0.184-0.409-0.409V88.886c0-0.226,0.183-0.409,0.409-0.409h30.717 c0.226,0,0.409,0.184,0.409,0.409v26.086C139.11,115.197,138.927,115.381,138.701,115.381z M108.393,114.562h29.899V89.295h-29.899 V114.562z"
        fill="#010101"
      />
      <Path
        d="M109.376,113.749c-0.15,0-0.273-0.122-0.273-0.272V90.235c0-0.15,0.123-0.272,0.273-0.272h29.44 c0.15,0,0.273,0.122,0.273,0.272s-0.123,0.272-0.273,0.272h-29.167v22.969C109.649,113.627,109.526,113.749,109.376,113.749z"
        fill="#010101"
      />
      <Path
        d="M118.498,113.431c-0.226,0-0.409-0.184-0.409-0.409v-12.729c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.184,0.409,0.409v12.729C118.907,113.247,118.724,113.431,118.498,113.431z"
        fill="#010101"
      />
      <Path
        d="M118.498,98.503c-0.226,0-0.409-0.184-0.409-0.409v-5.911c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.184,0.409,0.409v5.911C118.907,98.319,118.724,98.503,118.498,98.503z"
        fill="#010101"
      />
      <Path
        d="M128.155,106.613c-0.226,0-0.409-0.184-0.409-0.409V92.183c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.184,0.409,0.409v14.021C128.564,106.43,128.381,106.613,128.155,106.613z"
        fill="#010101"
      />
      <Path
        d="M128.155,113.431c-0.226,0-0.409-0.184-0.409-0.409v-4.618c0-0.226,0.183-0.409,0.409-0.409 s0.409,0.184,0.409,0.409v4.618C128.564,113.247,128.381,113.431,128.155,113.431z"
        fill="#010101"
      />
      <Path
        d="M138.685,102.542h-27.472c-0.226,0-0.409-0.184-0.409-0.409s0.183-0.409,0.409-0.409h27.472 c0.226,0,0.409,0.184,0.409,0.409S138.911,102.542,138.685,102.542z"
        fill="#010101"
      />
      <G>
        <Path
          d="M164.049,100.293h-19.12l1.264-2.893c0.24-0.549,0.522-1.075,0.844-1.574 c1.409-2.189,3.785-3.57,6.359-3.963l0,0l17.937-0.053L164.049,100.293z"
          fill="#E7EBEE"
        />
        <Path
          d="M164.049,100.702h-19.12c-0.138,0-0.267-0.069-0.342-0.186c-0.076-0.115-0.088-0.261-0.033-0.388 l1.264-2.893c0.245-0.561,0.539-1.11,0.875-1.632c1.422-2.207,3.842-3.719,6.641-4.146l17.998-0.058h0.001 c0.159,0,0.305,0.093,0.371,0.238c0.067,0.145,0.044,0.316-0.061,0.438l-7.283,8.483 C164.281,100.65,164.168,100.702,164.049,100.702z M145.554,99.884h18.307l6.579-7.662l-17.043,0.05 c-2.506,0.388-4.722,1.766-6.016,3.775c-0.312,0.485-0.585,0.996-0.812,1.517L145.554,99.884z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M171.386,100.293h-8.475l1.264-2.893c0.492-1.126,1.163-2.156,1.981-3.053 c1.197-1.312,2.849-2.123,4.605-2.391l0.616-0.094L171.386,100.293z"
          fill="#BAC2CA"
        />
        <Path
          d="M171.386,100.702h-8.475c-0.138,0-0.267-0.069-0.342-0.186c-0.076-0.115-0.088-0.261-0.033-0.388 l1.264-2.893c0.507-1.162,1.198-2.227,2.055-3.164c1.217-1.334,2.938-2.229,4.846-2.52l0.614-0.095 c0.119-0.017,0.238,0.016,0.329,0.094c0.09,0.077,0.143,0.19,0.143,0.311l0.009,8.431c0,0.108-0.043,0.212-0.12,0.289 C171.599,100.659,171.494,100.702,171.386,100.702z M163.536,99.884h7.441l-0.008-7.545l-0.145,0.022 c-1.725,0.263-3.274,1.066-4.366,2.262c-0.795,0.872-1.438,1.861-1.908,2.941L163.536,99.884z"
          fill="#010101"
        />
      </G>
      <Path
        d="M120.321,114.599c0-2.585-2.13-4.835-4.598-4.835c-1.922,0-3.556,1.273-4.187,3.057 c-0.675-1-1.782-1.657-3.039-1.657c-1.923,0-3.506,1.527-3.705,3.486"
        fill="#FFFFFF"
      />
      <Path
        d="M104.793,114.597c-0.014,0-0.029-0.001-0.043-0.002c-0.225-0.024-0.388-0.226-0.364-0.45 c0.223-2.106,1.991-3.694,4.111-3.694c1.107,0,2.164,0.451,2.935,1.231c0.842-1.559,2.491-2.567,4.291-2.567 c2.667,0,5.007,2.348,5.007,5.024c0,0.226-0.183,0.409-0.409,0.409s-0.409-0.184-0.409-0.409c0-2.24-1.957-4.206-4.188-4.206 c-1.687,0-3.215,1.065-3.803,2.651c-0.053,0.142-0.18,0.243-0.331,0.263c-0.155,0.024-0.3-0.045-0.387-0.169 c-0.624-0.883-1.635-1.409-2.705-1.409c-1.701,0-3.119,1.273-3.298,2.962C105.177,114.44,105,114.597,104.793,114.597z"
        fill="#010101"
      />
      <Path
        d="M118.411,114.603c0-2.391,1.97-4.472,4.253-4.472c1.778,0,3.289,1.178,3.872,2.827 c0.624-0.925,1.648-1.533,2.811-1.533c1.779,0,3.243,1.412,3.427,3.225"
        fill="#FFFFFF"
      />
      <Path
        d="M132.773,114.632c-0.207,0-0.384-0.156-0.406-0.366c-0.164-1.546-1.462-2.712-3.02-2.712 c-0.98,0-1.906,0.482-2.477,1.29c-0.087,0.123-0.237,0.192-0.387,0.169c-0.15-0.02-0.278-0.121-0.331-0.263 c-0.539-1.455-1.941-2.433-3.488-2.433c-2.048,0-3.844,1.804-3.844,3.859c0,0.226-0.183,0.409-0.409,0.409s-0.409-0.184-0.409-0.409 c0-2.492,2.179-4.678,4.662-4.678c1.662,0,3.186,0.924,3.976,2.355c0.717-0.71,1.689-1.119,2.708-1.119 c1.977,0,3.625,1.48,3.833,3.444c0.024,0.225-0.139,0.426-0.364,0.45C132.802,114.631,132.787,114.632,132.773,114.632z"
        fill="#010101"
      />
      <G>
        <Path d="M137.785,121.565h-31.736c-1.549,0-2.804-1.256-2.804-2.804v-3.812h34.54V121.565z" fill="#E7EBEE" />
        <Path
          d="M137.785,121.975h-31.736c-1.772,0-3.214-1.441-3.214-3.214v-3.812c0-0.226,0.183-0.409,0.409-0.409h34.541 c0.226,0,0.409,0.184,0.409,0.409v6.616C138.194,121.791,138.011,121.975,137.785,121.975z M103.654,115.358v3.402 c0,1.321,1.075,2.396,2.396,2.396h31.327v-5.798H103.654z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M137.56,121.565h-0.51c-1.549,0-2.804-1.256-2.804-2.804v-3.812h6.118v3.812 C140.364,120.31,139.109,121.565,137.56,121.565z"
          fill="#BAC2CA"
        />
        <Path
          d="M137.56,121.975h-0.509c-1.772,0-3.214-1.441-3.214-3.214v-3.812c0-0.226,0.183-0.409,0.409-0.409h6.119 c0.226,0,0.409,0.184,0.409,0.409v3.812C140.773,120.533,139.332,121.975,137.56,121.975z M134.655,115.358v3.402 c0,1.321,1.075,2.396,2.396,2.396h0.509c1.321,0,2.396-1.074,2.396-2.396v-3.402H134.655z"
          fill="#010101"
        />
      </G>
      <Path
        d="M159.639,138.249h-12.427c-0.15,0-0.273-0.122-0.273-0.272s0.123-0.272,0.273-0.272h12.427 c0.15,0,0.273,0.122,0.273,0.272S159.789,138.249,159.639,138.249z"
        fill="#010101"
      />
      <G>
        <Circle cx="161.788" cy="106.874" fill="#FFFFFF" r="0.807" />
        <Path
          d="M161.788,107.953c-0.596,0-1.081-0.484-1.081-1.079c0-0.596,0.485-1.08,1.081-1.08 c0.595,0,1.08,0.484,1.08,1.08C162.868,107.469,162.383,107.953,161.788,107.953z M161.788,106.339 c-0.295,0-0.535,0.24-0.535,0.535c0,0.294,0.24,0.534,0.535,0.534c0.294,0,0.534-0.24,0.534-0.534 C162.322,106.579,162.083,106.339,161.788,106.339z"
          fill="#010101"
        />
      </G>
      <Path
        d="M82.88,116.977H71.924c-0.226,0-0.409-0.184-0.409-0.409v-4.711c0-1.453,1.182-2.635,2.635-2.635h8.73 c0.226,0,0.409,0.184,0.409,0.409v6.937C83.289,116.793,83.106,116.977,82.88,116.977z M72.333,116.158h10.138v-6.118h-8.321 c-1.001,0-1.816,0.814-1.816,1.816V116.158z"
        fill="#010101"
      />
      <G>
        <Path
          d="M84.961,116.567h-4.856v-4.711c0-1.229,0.996-2.226,2.226-2.226h0.404c1.229,0,2.226,0.996,2.226,2.226 V116.567z"
          fill="#BAC2CA"
        />
        <Path
          d="M84.961,116.977h-4.855c-0.226,0-0.409-0.184-0.409-0.409v-4.711c0-1.453,1.182-2.635,2.635-2.635h0.404 c1.453,0,2.635,1.182,2.635,2.635v4.711C85.37,116.793,85.187,116.977,84.961,116.977z M80.515,116.158h4.037v-4.302 c0-1.002-0.815-1.816-1.816-1.816h-0.404c-1.001,0-1.816,0.814-1.816,1.816V116.158z"
          fill="#010101"
        />
      </G>
      <Path
        d="M77.864,112.762c-0.174,0-0.336-0.112-0.391-0.287l-1.473-4.714l-0.771,0.28 c-0.211,0.076-0.447-0.033-0.524-0.245c-0.077-0.212,0.032-0.447,0.245-0.524l1.174-0.427c0.104-0.039,0.221-0.031,0.321,0.019 c0.1,0.049,0.175,0.138,0.209,0.244l1.601,5.123c0.067,0.216-0.053,0.445-0.269,0.513C77.946,112.756,77.905,112.762,77.864,112.762 z"
        fill="#010101"
      />
      <Path
        d="M106.234,139.07c-0.096,0-0.189-0.051-0.239-0.141c-0.28-0.507-0.608-0.98-0.975-1.406 c-0.098-0.113-0.085-0.286,0.028-0.384c0.116-0.099,0.287-0.086,0.385,0.028c0.392,0.454,0.742,0.958,1.04,1.498 c0.073,0.132,0.025,0.298-0.107,0.371C106.324,139.06,106.279,139.07,106.234,139.07z"
        fill="#010101"
      />
      <Path
        d="M103.903,136.388c-0.057,0-0.114-0.018-0.163-0.054c-2.22-1.653-4.712-1.741-4.737-1.742 c-0.15-0.004-0.269-0.13-0.265-0.28s0.155-0.258,0.28-0.265c0.108,0.003,2.685,0.09,5.048,1.85c0.121,0.09,0.146,0.261,0.056,0.382 C104.068,136.35,103.986,136.388,103.903,136.388z"
        fill="#010101"
      />
      <Path
        d="M214.354,85.358c-0.051,0-0.103-0.014-0.148-0.043c-0.126-0.082-0.162-0.251-0.081-0.378 c0.405-0.627,0.569-2.138,0.598-2.667c0.008-0.15,0.135-0.268,0.287-0.258c0.15,0.009,0.266,0.137,0.258,0.287 c-0.005,0.084-0.117,2.056-0.684,2.935C214.53,85.314,214.442,85.358,214.354,85.358z"
        fill="#010101"
      />
      <G>
        <Path
          d="M153.104,122.697c0,0-1.458-0.036-4.233,1.565c-2.775,1.601-4.126,5.016-2.383,7.257 c1.743,2.241,4.518,0.783,4.518,0.783l7.363,0.071c0,0,3.237-0.498,3.273-3.166c0.036-2.668-0.996-6.403-0.996-6.403 L153.104,122.697z"
          fill="#FFFFFF"
        />
        <G>
          <Path
            d="M162.683,120.935c0.904-3.194,0.18-4.397,0.18-4.397c-2.241,0.053-3.588,1.414-3.588,1.414 c-1.549-0.582-3.399,0-3.399,0s-1.347-1.361-3.588-1.414c0,0-0.724,1.203,0.18,4.397c0,0-1.326,3.224,1.828,4.449 s5.203,0.432,6.669-0.09c1.466-0.522,2.481-2.139,1.959-3.696L162.683,120.935z"
            fill="#FFFFFF"
          />
          <Path
            d="M157.505,126.438c-0.969,0-2.082-0.177-3.358-0.673c-1.021-0.396-1.723-1.028-2.087-1.879 c-0.53-1.237-0.167-2.545-0.022-2.968c-0.871-3.177-0.183-4.455-0.101-4.592c0.076-0.126,0.216-0.193,0.36-0.198 c1.921,0.046,3.225,0.98,3.686,1.37c0.537-0.136,1.914-0.401,3.196-0.011c0.472-0.396,1.77-1.313,3.674-1.359 c0.135,0,0.284,0.072,0.36,0.198c0.082,0.137,0.771,1.415-0.101,4.592l0.195,0.539c0.569,1.696-0.422,3.586-2.206,4.222 l-0.113,0.04C160.118,126.031,158.978,126.438,157.505,126.438z M152.56,116.962c-0.132,0.449-0.327,1.644,0.301,3.862 c0.025,0.088,0.02,0.182-0.015,0.266c-0.005,0.014-0.528,1.329-0.032,2.479c0.275,0.639,0.824,1.121,1.629,1.435 c2.93,1.138,4.861,0.448,6.27-0.055l0.114-0.041c1.197-0.426,2.169-1.806,1.708-3.181l-0.237-0.652 c-0.029-0.081-0.032-0.169-0.009-0.251c0.627-2.215,0.434-3.408,0.3-3.862c-1.87,0.147-3.014,1.268-3.026,1.279 c-0.113,0.112-0.283,0.15-0.434,0.094c-1.396-0.525-3.115,0.002-3.132,0.008c-0.144,0.042-0.305,0.005-0.412-0.102 C155.574,118.229,154.43,117.109,152.56,116.962z"
            fill="#010101"
          />
        </G>
        <Path
          d="M152.568,121.279c-0.04,0-0.08-0.009-0.118-0.026c-0.136-0.065-0.193-0.229-0.127-0.364 c0.528-1.099,1.376-1.794,1.794-2.09c-0.714-0.963-1.796-1.795-1.808-1.804c-0.12-0.092-0.143-0.263-0.051-0.382 c0.092-0.119,0.264-0.143,0.382-0.052c0.054,0.041,1.339,1.028,2.09,2.161c0.042,0.062,0.055,0.14,0.038,0.213 c-0.017,0.073-0.064,0.136-0.129,0.174c-0.012,0.007-1.191,0.7-1.825,2.016C152.767,121.223,152.669,121.279,152.568,121.279z"
          fill="#010101"
        />
        <Path
          d="M162.585,121.279c-0.101,0-0.199-0.057-0.246-0.154c-0.636-1.321-1.812-2.009-1.824-2.016 c-0.065-0.037-0.112-0.101-0.13-0.174c-0.017-0.073-0.003-0.15,0.038-0.213c0.751-1.133,2.036-2.12,2.09-2.161 c0.12-0.091,0.291-0.067,0.382,0.052s0.068,0.29-0.051,0.382c-0.012,0.009-1.094,0.841-1.808,1.804 c0.418,0.296,1.266,0.991,1.794,2.09c0.065,0.136,0.008,0.299-0.127,0.364C162.666,121.271,162.625,121.279,162.585,121.279z"
          fill="#010101"
        />
        <Path
          d="M158.077,123.614h-0.8c-0.15,0-0.273-0.122-0.273-0.272s0.123-0.272,0.273-0.272h0.8 c0.15,0,0.273,0.122,0.273,0.272S158.227,123.614,158.077,123.614z"
          fill="#010101"
        />
        <Path
          d="M157.027,124.713c-0.004,0-0.009,0-0.014,0c-0.691-0.011-0.93-0.545-0.959-0.826 c-0.016-0.149,0.093-0.284,0.243-0.3c0.137-0.017,0.282,0.092,0.299,0.24c0.011,0.079,0.073,0.335,0.426,0.341 c0.001,0,0.002,0,0.003,0c0.335,0,0.372-0.771,0.373-0.778c0.003-0.15,0.135-0.267,0.278-0.267c0.15,0.003,0.27,0.128,0.267,0.278 C157.933,123.891,157.728,124.713,157.027,124.713z"
          fill="#010101"
        />
        <Path
          d="M158.243,124.713c-0.701,0-0.906-0.822-0.916-1.312c-0.003-0.15,0.116-0.274,0.266-0.278 c0.168,0.017,0.275,0.116,0.279,0.267c0,0.008,0.041,0.778,0.373,0.778c0.001,0,0.002,0,0.003,0 c0.354-0.006,0.416-0.263,0.426-0.342c0.021-0.147,0.157-0.264,0.303-0.236c0.148,0.018,0.254,0.148,0.239,0.297 c-0.029,0.281-0.268,0.815-0.959,0.826C158.252,124.713,158.247,124.713,158.243,124.713z"
          fill="#010101"
        />
        <Circle cx="155.022" cy="122.168" fill="#010101" r="0.333" />
        <Circle cx="160.171" cy="122.061" fill="#010101" r="0.333" />
        <Path
          d="M149.406,133.074c-0.037,0-0.074,0-0.111-0.001c-1.517-0.034-2.776-0.576-3.454-1.486 c-0.658-0.885-0.789-2.066-0.389-3.513c1.338-4.845,6.465-5.694,6.683-5.729c0.227-0.029,0.432,0.118,0.467,0.341 c0.035,0.224-0.118,0.433-0.34,0.468c-0.048,0.008-4.825,0.81-6.02,5.138c-0.329,1.191-0.243,2.135,0.256,2.806 c0.526,0.708,1.552,1.129,2.815,1.157c1.054,0.023,1.853-0.307,3.08-1.307l0.348-0.363c0.076-0.078,0.117-0.183,0.114-0.292 c-0.002-0.109-0.046-0.211-0.125-0.287c-0.152-0.146-0.396-0.148-0.554-0.006c-1.601,1.457-3.228,1.538-4.218,1.127 c-1.088-0.451-1.284-1.484-1.236-1.968c0.023-0.225,0.222-0.382,0.448-0.366c0.224,0.022,0.388,0.222,0.367,0.444 c-0.007,0.085-0.041,0.812,0.735,1.134c0.722,0.3,2.035,0.224,3.354-0.977c0.477-0.431,1.211-0.422,1.671,0.021 c0.236,0.227,0.371,0.532,0.377,0.861c0.006,0.328-0.116,0.64-0.345,0.877l-0.384,0.396 C151.784,132.5,150.797,133.074,149.406,133.074z"
          fill="#010101"
        />
        <Path
          d="M156.927,132.904c-2.579,0-5.854-0.031-5.854-0.031l0.008-0.818c0,0,3.272,0.031,5.847,0.031 c0.62,0,1.121-0.009,1.524-0.021c0.666-0.021,1.188-0.562,1.188-1.233c0-0.333-0.129-0.645-0.365-0.878 c-0.233-0.231-0.549-0.341-0.868-0.355c-1.12,0-2.001-0.039-2.603-0.135c-0.223-0.036-0.375-0.246-0.339-0.469 c0.036-0.224,0.241-0.372,0.468-0.34c0.549,0.089,1.403,0.143,2.469,0.125c0.569,0.004,1.061,0.207,1.45,0.593 c0.391,0.389,0.606,0.907,0.606,1.459c0,1.115-0.87,2.016-1.98,2.052C158.066,132.896,157.558,132.904,156.927,132.904z"
          fill="#010101"
        />
        <Path
          d="M159.074,132.732c-0.202,0-0.377-0.149-0.405-0.355c-0.03-0.224,0.127-0.43,0.351-0.459 c0.685-0.092,1.197-0.398,1.565-0.938c1.254-1.838,0.348-5.613,0.338-5.651c-0.054-0.22,0.081-0.441,0.3-0.495 c0.219-0.052,0.441,0.081,0.495,0.3c0.042,0.169,1.002,4.167-0.457,6.307c-0.5,0.732-1.217,1.166-2.133,1.288 C159.11,132.731,159.092,132.732,159.074,132.732z"
          fill="#010101"
        />
      </G>
      <Path
        d="M276.451,95.194c0-8.505-5.636-15.687-13.375-18.032c-0.863-0.261-1.217-1.247-0.742-2.013 c1.346-2.173,2.123-4.734,2.123-7.478c0-7.855-6.368-14.223-14.223-14.223c-0.083,0-0.162,0.011-0.245,0.012 c-0.906-6.706-6.639-11.881-13.594-11.881c-6.964,0-12.703,5.188-13.597,11.908c-0.256-0.014-0.509-0.039-0.769-0.039 c-7.855,0-14.223,6.368-14.223,14.223c0,2.882,0.863,5.559,2.339,7.798c0.487,0.738,0.173,1.714-0.659,2.014 c-7.259,2.618-12.454,9.549-12.454,17.711c0,7.956,4.937,14.747,11.909,17.512l-0.019,0.021c0,0,7.533,3.293,23.034,3.388 c1.547,0.061,3.158,0.097,4.831,0.097c1.532,0,3.027-0.035,4.477-0.094c15.674-0.06,23.285-3.392,23.285-3.392l-0.016-0.017 C271.509,109.947,276.451,103.154,276.451,95.194z"
        fill="#D9DFE6"
      />
      <Path
        d="M236.788,144c-0.32,0-0.579-0.261-0.579-0.583V72.731c0-0.322,0.259-0.583,0.579-0.583 c0.32,0,0.579,0.261,0.579,0.583v70.687C237.367,143.739,237.107,144,236.788,144z"
        fill="#010101"
      />
      <Path
        d="M236.512,100.324c-0.107,0-0.216-0.03-0.314-0.092l-14.468-9.303c-0.268-0.173-0.347-0.531-0.173-0.8 c0.172-0.27,0.529-0.345,0.8-0.173l14.468,9.303c0.268,0.173,0.347,0.531,0.173,0.8 C236.889,100.231,236.702,100.324,236.512,100.324z"
        fill="#010101"
      />
      <Path
        d="M236.74,86.231c-0.188,0-0.372-0.092-0.484-0.261c-0.175-0.267-0.101-0.626,0.166-0.802l7.248-4.772 c0.265-0.176,0.626-0.102,0.802,0.166c0.175,0.267,0.101,0.626-0.166,0.802l-7.249,4.772C236.96,86.201,236.85,86.231,236.74,86.231 z"
        fill="#010101"
      />
      <Path
        d="M237.021,108.084c-0.197,0-0.39-0.101-0.499-0.285c-0.161-0.274-0.071-0.63,0.205-0.793l14.214-8.405 c0.273-0.16,0.629-0.072,0.793,0.205c0.161,0.274,0.071,0.63-0.205,0.793l-14.214,8.405 C237.223,108.058,237.122,108.084,237.021,108.084z"
        fill="#010101"
      />
      <Path
        d="M236.786,116.791c-1.586,0-3.22-0.033-4.855-0.098c-15.424-0.095-22.932-3.301-23.244-3.437 c-0.048-0.021-0.092-0.048-0.133-0.08c-7.359-2.998-12.103-10.037-12.103-17.983c0-8.15,5.159-15.486,12.837-18.255 c0.228-0.083,0.393-0.251,0.467-0.473c0.075-0.225,0.039-0.471-0.096-0.677c-1.592-2.417-2.434-5.224-2.434-8.117 c0-8.162,6.641-14.802,14.803-14.802c0.093,0,0.185,0.003,0.276,0.008c1.17-6.808,7.134-11.877,14.09-11.877 c6.95,0,12.917,5.068,14.089,11.871c8.047,0.134,14.553,6.722,14.553,14.8c0,2.757-0.764,5.448-2.211,7.782 c-0.134,0.217-0.161,0.473-0.075,0.7c0.084,0.222,0.259,0.384,0.494,0.455c8.246,2.498,13.786,9.966,13.786,18.585 c0,7.96-4.758,15.006-12.137,17.995c-0.035,0.027-0.072,0.05-0.113,0.068c-0.315,0.137-7.9,3.379-23.514,3.441 C239.828,116.755,238.325,116.791,236.786,116.791z M209.306,112.259c1.147,0.452,8.665,3.191,22.651,3.277 c2.987,0.119,6.263,0.124,9.283,0.005c14.271-0.056,21.875-2.865,22.945-3.29c0.039-0.033,0.084-0.059,0.134-0.078 c7.018-2.779,11.552-9.443,11.552-16.978c0-8.105-5.209-15.127-12.963-17.477c-0.579-0.175-1.033-0.596-1.243-1.154 c-0.214-0.567-0.149-1.194,0.176-1.718c1.332-2.152,2.036-4.632,2.036-7.173c0-7.524-6.121-13.645-13.645-13.645l-0.234,0.013 c-0.271-0.008-0.546-0.211-0.585-0.501c-0.876-6.488-6.474-11.381-13.02-11.381c-6.56,0-12.159,4.903-13.023,11.406 c-0.041,0.3-0.311,0.534-0.606,0.502l-0.237-0.015c-0.166-0.011-0.332-0.023-0.5-0.023c-7.524,0-13.645,6.121-13.645,13.645 c0,2.666,0.776,5.253,2.244,7.48c0.332,0.503,0.415,1.115,0.228,1.678c-0.185,0.559-0.613,0.996-1.174,1.198 c-7.22,2.605-12.072,9.503-12.072,17.166c0,7.53,4.531,14.194,11.544,16.975C209.209,112.191,209.26,112.221,209.306,112.259z"
        fill="#010101"
      />
      <G>
        <Path
          d="M265.831,100.905c0-0.843-0.624-1.534-1.434-1.653c-0.109-0.821-0.805-1.457-1.656-1.457 c-0.845,0-1.537,0.627-1.654,1.44c-0.891,0.04-1.604,0.769-1.604,1.67c0,0.919,0.74,1.664,1.656,1.676 c0.206,0.694,0.841,1.203,1.601,1.203c0.767,0,1.407-0.518,1.607-1.22C265.182,102.466,265.831,101.765,265.831,100.905z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M220.73,63.624c0-0.574-0.425-1.045-0.976-1.126c-0.074-0.559-0.548-0.992-1.128-0.992 c-0.576,0-1.047,0.427-1.126,0.981c-0.607,0.027-1.092,0.524-1.092,1.138c0,0.626,0.504,1.133,1.128,1.141 c0.14,0.472,0.573,0.819,1.091,0.819c0.522,0,0.958-0.353,1.094-0.831C220.288,64.687,220.73,64.21,220.73,63.624z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M248.916,92.748c0-0.623-0.461-1.133-1.059-1.221c-0.081-0.607-0.594-1.076-1.223-1.076 c-0.624,0-1.136,0.463-1.222,1.064c-0.659,0.029-1.185,0.568-1.185,1.234c0,0.679,0.547,1.229,1.224,1.238 c0.152,0.512,0.621,0.889,1.183,0.889c0.567,0,1.039-0.382,1.187-0.902C248.437,93.9,248.916,93.383,248.916,92.748z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M207.851,101.778c0-0.41-0.303-0.746-0.697-0.804c-0.053-0.399-0.391-0.708-0.805-0.708 c-0.411,0-0.748,0.305-0.804,0.7c-0.434,0.019-0.78,0.374-0.78,0.812c0,0.447,0.36,0.809,0.805,0.815 c0.1,0.337,0.409,0.585,0.779,0.585c0.373,0,0.684-0.252,0.781-0.593C207.535,102.537,207.851,102.196,207.851,101.778z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M259.892,83.558c0-0.41-0.303-0.746-0.697-0.804c-0.053-0.399-0.391-0.708-0.805-0.708 c-0.411,0-0.748,0.305-0.804,0.7c-0.434,0.019-0.78,0.374-0.78,0.812c0,0.447,0.36,0.809,0.805,0.815 c0.1,0.337,0.409,0.585,0.779,0.585c0.373,0,0.684-0.252,0.781-0.593C259.576,84.317,259.892,83.976,259.892,83.558z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M251.336,108.522c0-0.41-0.303-0.746-0.697-0.804c-0.053-0.399-0.391-0.708-0.805-0.708 c-0.411,0-0.748,0.305-0.804,0.7c-0.434,0.019-0.78,0.374-0.78,0.812c0,0.447,0.36,0.809,0.805,0.815 c0.1,0.337,0.409,0.585,0.779,0.585c0.373,0,0.684-0.252,0.781-0.593C251.02,109.281,251.336,108.94,251.336,108.522z"
          fill="#BAC2CA"
        />
      </G>
      <G>
        <Path
          d="M268.75,89.527c0-0.624-0.462-1.135-1.061-1.223c-0.081-0.608-0.595-1.078-1.225-1.078 c-0.625,0-1.138,0.464-1.224,1.066c-0.66,0.029-1.187,0.569-1.187,1.236c0,0.68,0.548,1.231,1.226,1.24 c0.152,0.513,0.622,0.89,1.185,0.89c0.568,0,1.041-0.383,1.189-0.903C268.27,90.681,268.75,90.163,268.75,89.527z"
          fill="#BAC2CA"
        />
      </G>
      <G>
        <Path
          d="M254.054,75.736c0-0.624-0.462-1.135-1.061-1.223c-0.081-0.608-0.595-1.078-1.225-1.078 c-0.625,0-1.138,0.464-1.224,1.066c-0.66,0.029-1.187,0.569-1.187,1.236c0,0.68,0.548,1.231,1.226,1.24 c0.152,0.513,0.622,0.89,1.185,0.89c0.568,0,1.041-0.383,1.189-0.903C253.573,76.891,254.054,76.373,254.054,75.736z"
          fill="#BAC2CA"
        />
      </G>
      <G>
        <Path
          d="M230.399,70.573c0-0.41-0.303-0.746-0.697-0.804c-0.053-0.399-0.391-0.708-0.805-0.708 c-0.411,0-0.748,0.305-0.804,0.7c-0.434,0.019-0.78,0.374-0.78,0.812c0,0.447,0.36,0.809,0.805,0.815 c0.1,0.337,0.409,0.585,0.779,0.585c0.373,0,0.684-0.252,0.781-0.593C230.083,71.332,230.399,70.991,230.399,70.573z"
          fill="#BAC2CA"
        />
      </G>
      <G>
        <Path
          d="M241.069,51.384c0-0.677-0.501-1.233-1.152-1.328c-0.088-0.66-0.646-1.171-1.33-1.171 c-0.679,0-1.235,0.504-1.329,1.157c-0.716,0.032-1.289,0.618-1.289,1.342c0,0.739,0.594,1.337,1.331,1.346 c0.165,0.557,0.676,0.966,1.287,0.966c0.616,0,1.13-0.416,1.291-0.981C240.547,52.638,241.069,52.075,241.069,51.384z"
          fill="#BAC2CA"
        />
      </G>
      <G>
        <Path
          d="M217.917,96.782c0-0.677-0.501-1.233-1.152-1.328c-0.088-0.66-0.646-1.171-1.33-1.171 c-0.679,0-1.235,0.504-1.329,1.157c-0.716,0.032-1.289,0.618-1.289,1.342c0,0.739,0.594,1.337,1.331,1.346 c0.165,0.557,0.676,0.966,1.287,0.966c0.616,0,1.13-0.416,1.291-0.981C217.395,98.035,217.917,97.472,217.917,96.782z"
          fill="#BAC2CA"
        />
      </G>
      <G>
        <Path
          d="M233.72,59.448c0-0.758-0.56-1.379-1.288-1.486c-0.098-0.738-0.723-1.309-1.488-1.309 c-0.759,0-1.382,0.563-1.486,1.294c-0.801,0.036-1.441,0.691-1.441,1.501c0,0.826,0.665,1.495,1.488,1.506 c0.185,0.623,0.756,1.081,1.439,1.081c0.689,0,1.264-0.465,1.444-1.097C233.137,60.85,233.72,60.22,233.72,59.448z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M228.84,80.398c0-0.851-0.63-1.549-1.448-1.67c-0.11-0.829-0.813-1.472-1.672-1.472 c-0.854,0-1.553,0.633-1.67,1.454c-0.9,0.04-1.62,0.777-1.62,1.687c0,0.929,0.747,1.681,1.673,1.693 c0.208,0.701,0.849,1.215,1.617,1.215c0.775,0,1.421-0.523,1.623-1.233C228.184,81.974,228.84,81.266,228.84,80.398z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M226.469,105.481c0-0.623-0.461-1.133-1.059-1.221c-0.081-0.607-0.594-1.076-1.223-1.076 c-0.624,0-1.136,0.463-1.222,1.064c-0.659,0.029-1.185,0.568-1.185,1.234c0,0.679,0.547,1.229,1.224,1.238 c0.152,0.512,0.621,0.889,1.183,0.889c0.567,0,1.039-0.382,1.187-0.902C225.989,106.634,226.469,106.116,226.469,105.481z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M215.245,86.104c0-0.623-0.461-1.133-1.059-1.221c-0.081-0.607-0.594-1.076-1.223-1.076 c-0.624,0-1.136,0.463-1.222,1.064c-0.659,0.029-1.185,0.568-1.185,1.234c0,0.679,0.547,1.229,1.224,1.238 c0.152,0.512,0.621,0.889,1.183,0.889c0.567,0,1.039-0.382,1.187-0.902C214.766,87.257,215.245,86.739,215.245,86.104z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          d="M251.537,63.569c0-0.717-0.531-1.306-1.22-1.407c-0.093-0.699-0.685-1.24-1.409-1.24 c-0.719,0-1.308,0.534-1.408,1.225c-0.759,0.034-1.365,0.655-1.365,1.422c0,0.782,0.63,1.416,1.41,1.426 c0.175,0.59,0.716,1.024,1.363,1.024c0.653,0,1.197-0.441,1.367-1.039C250.985,64.897,251.537,64.301,251.537,63.569z"
          fill="#FFFFFF"
        />
      </G>
      <Path
        d="M112.663,32.472c-0.106,0-0.201-0.073-0.226-0.181c-0.028-0.124,0.051-0.248,0.176-0.276l1.467-0.326 l-1.462-0.307c-0.125-0.026-0.205-0.149-0.179-0.274c0.026-0.124,0.145-0.206,0.274-0.179l2.017,0.423 c0.158,0.033,0.27,0.169,0.27,0.33c0.001,0.162-0.108,0.299-0.266,0.334l-2.021,0.449C112.696,32.471,112.68,32.472,112.663,32.472z  M114.635,31.806h0.006H114.635z"
        fill="#010101"
      />
      <Path
        d="M105.576,34.83c-0.219,0-0.436-0.023-0.644-0.075c-0.094-0.024-0.164-0.105-0.173-0.202 c-0.002-0.019-0.032-0.384,0.236-0.87c-0.308-0.013-0.707-0.1-1.077-0.393c-0.067-0.053-0.099-0.139-0.084-0.223 c0.015-0.084,0.076-0.153,0.157-0.179c0.015-0.005,1.447-0.465,2.414-1.226c0.333-0.263,0.617-0.559,0.891-0.845 c0.517-0.54,1.051-1.098,1.942-1.484c0.118-0.05,0.254,0.003,0.304,0.121c0.051,0.117-0.003,0.253-0.12,0.304 c-0.805,0.348-1.284,0.849-1.792,1.379c-0.286,0.298-0.581,0.606-0.939,0.889c-0.677,0.532-1.537,0.918-2.069,1.126 c0.407,0.133,0.763,0.041,0.783,0.036c0.095-0.026,0.201,0.014,0.255,0.099c0.054,0.085,0.047,0.196-0.018,0.273 c-0.279,0.33-0.375,0.614-0.406,0.785c1.202,0.175,2.749-0.7,2.765-0.709c0.08-0.046,0.179-0.041,0.252,0.013 c0.736,0.535,1.57,0.73,2.342,0.547c0.611-0.143,1.139-0.516,1.377-0.972c0.535-1.025,0.522-2.085-0.035-2.908 c-0.57-0.841-1.997-0.69-2.013-0.689c-0.124,0.015-0.242-0.077-0.256-0.204c-0.014-0.127,0.077-0.241,0.204-0.256 c0.067-0.008,1.722-0.181,2.447,0.89c0.656,0.968,0.678,2.2,0.062,3.381c-0.298,0.572-0.942,1.035-1.681,1.208 c-0.866,0.203-1.782,0.011-2.599-0.539C107.728,34.304,106.631,34.83,105.576,34.83z"
        fill="#010101"
      />
      <Path
        d="M108.699,33.291c-0.097,0-0.198-0.009-0.303-0.028c-0.013-0.001-0.483-0.071-0.85-0.439 c-0.481,0.228-1.298,0.389-1.829-0.26c-0.081-0.099-0.066-0.245,0.033-0.325c0.099-0.08,0.245-0.066,0.326,0.033 c0.497,0.61,1.393,0.069,1.403,0.064c0.102-0.063,0.235-0.037,0.307,0.059c0.254,0.344,0.681,0.412,0.686,0.412 c1,0.191,1.596-1.006,1.602-1.018c0.055-0.115,0.195-0.163,0.309-0.107c0.115,0.056,0.163,0.194,0.106,0.309 C110.462,32.048,109.842,33.291,108.699,33.291z"
        fill="#010101"
      />
      <Circle cx="110.968" cy="30.959" fill="#010101" r="0.212" />
      <Path
        d="M109.207,36.402c-0.075,0-0.149-0.037-0.193-0.104l-0.665-1.012c-0.035-0.054-0.047-0.121-0.031-0.183 l0.236-0.942c0.031-0.124,0.156-0.198,0.281-0.168c0.124,0.031,0.199,0.157,0.168,0.281l-0.212,0.843l0.61,0.927 c0.07,0.107,0.04,0.25-0.066,0.32C109.295,36.39,109.251,36.402,109.207,36.402z"
        fill="#010101"
      />
      <Path
        d="M110.249,36.497c-0.075,0-0.149-0.037-0.193-0.104l-0.665-1.012c-0.036-0.055-0.047-0.122-0.031-0.185 l0.204-0.784c0.032-0.124,0.158-0.198,0.282-0.165c0.124,0.032,0.197,0.158,0.165,0.282l-0.179,0.685l0.609,0.926 c0.07,0.107,0.04,0.25-0.066,0.32C110.337,36.484,110.292,36.497,110.249,36.497z"
        fill="#010101"
      />
      <Path
        d="M85.32,29.055c-4.191,0-7.601-3.41-7.601-7.601s3.41-7.601,7.601-7.601s7.601,3.41,7.601,7.601 S89.512,29.055,85.32,29.055z M85.32,14.546c-3.809,0-6.908,3.099-6.908,6.908s3.099,6.908,6.908,6.908s6.908-3.099,6.908-6.908 S89.13,14.546,85.32,14.546z"
        fill="#010101"
      />
      <Path
        d="M85.032,11.722c-0.191,0-0.346-0.155-0.346-0.346V8.346C84.686,8.155,84.841,8,85.032,8 c0.191,0,0.346,0.155,0.346,0.346v3.029C85.378,11.567,85.223,11.722,85.032,11.722z"
        fill="#010101"
      />
      <Path
        d="M85.032,34.723c-0.191,0-0.346-0.155-0.346-0.346v-3.029c0-0.191,0.155-0.346,0.346-0.346 c0.191,0,0.346,0.155,0.346,0.346v3.029C85.378,34.567,85.223,34.723,85.032,34.723z"
        fill="#010101"
      />
      <Path
        d="M92.093,14.648c-0.089,0-0.177-0.034-0.245-0.101c-0.135-0.135-0.135-0.355-0.001-0.49l2.142-2.142 c0.134-0.135,0.354-0.135,0.49-0.001c0.135,0.135,0.135,0.355,0.001,0.49l-2.142,2.142C92.27,14.614,92.181,14.648,92.093,14.648z"
        fill="#010101"
      />
      <Path
        d="M75.829,30.911c-0.089,0-0.177-0.034-0.245-0.102c-0.135-0.135-0.135-0.355,0-0.49l2.142-2.142 c0.135-0.135,0.354-0.135,0.49,0c0.135,0.135,0.135,0.355,0,0.49l-2.142,2.142C76.006,30.877,75.918,30.911,75.829,30.911z"
        fill="#010101"
      />
      <Path
        d="M98.393,21.362c0,0.191-0.155,0.346-0.346,0.346h-3.029c-0.191,0-0.346-0.155-0.346-0.346 c0-0.191,0.155-0.346,0.346-0.346h3.029C98.238,21.015,98.393,21.17,98.393,21.362"
        fill="#010101"
      />
      <Path
        d="M75.046,21.708h-3.029c-0.191,0-0.346-0.155-0.346-0.346c0-0.191,0.155-0.346,0.346-0.346h3.029 c0.191,0,0.346,0.155,0.346,0.346C75.393,21.553,75.238,21.708,75.046,21.708z"
        fill="#010101"
      />
      <Path
        d="M94.234,30.911c-0.089,0-0.177-0.034-0.245-0.102l-2.142-2.142c-0.135-0.135-0.135-0.355,0-0.49 c0.135-0.135,0.354-0.135,0.49,0l2.142,2.142c0.135,0.135,0.135,0.355,0,0.49C94.412,30.877,94.323,30.911,94.234,30.911z"
        fill="#010101"
      />
      <Path
        d="M77.971,14.648c-0.089,0-0.178-0.034-0.245-0.102l-2.142-2.142c-0.135-0.135-0.135-0.355,0.001-0.49 c0.135-0.134,0.355-0.135,0.49,0.001l2.142,2.142c0.135,0.135,0.135,0.355-0.001,0.49C78.148,14.614,78.059,14.648,77.971,14.648z"
        fill="#010101"
      />
      <G>
        <G>
          <G>
            <Path d="M72.154,149.189c1.881-3.814,1.972-6.182,2.355-11.726c0,0-5.5,5.105-8.509,10.537" fill="#E7EBEE" />
            <Path
              d="M72.154,149.379c-0.028,0-0.057-0.006-0.083-0.02c-0.094-0.046-0.133-0.16-0.086-0.253 c1.785-3.619,1.943-5.921,2.289-10.967l0.018-0.261c-0.889,0.737-3.437,3.073-5.833,7.398c-0.051,0.092-0.166,0.124-0.258,0.074 c-0.091-0.051-0.124-0.166-0.074-0.257c2.995-5.407,6.241-7.76,6.273-7.783c0.06-0.043,0.139-0.048,0.203-0.011 c0.064,0.035,0.102,0.105,0.097,0.178l-0.047,0.688c-0.35,5.096-0.51,7.423-2.327,11.108 C72.291,149.34,72.224,149.379,72.154,149.379z"
              fill="#010101"
            />
          </G>
          <G>
            <Path d="M63.674,149.189c-0.064-4.66,0.709-8.562,2.614-14.865c0,0,3.423,6.859,3.262,15.199" fill="#E7EBEE" />
            <Path
              d="M69.55,149.713c-0.001,0-0.002,0-0.004,0c-0.105-0.002-0.188-0.089-0.186-0.193 c0.135-6.996-2.298-13.01-3.034-14.663c-1.897,6.35-2.521,10.012-2.462,14.329c0.001,0.105-0.082,0.191-0.187,0.193 c-0.001,0-0.002,0-0.003,0c-0.104,0-0.188-0.083-0.19-0.187c-0.061-4.469,0.601-8.234,2.622-14.922 c0.022-0.075,0.088-0.128,0.165-0.134c0.078,0.002,0.151,0.035,0.186,0.104c0.034,0.07,3.442,6.997,3.281,15.287 C69.737,149.631,69.653,149.713,69.55,149.713z"
              fill="#010101"
            />
          </G>
          <G>
            <Path d="M64.743,149.724c-1.038-4.544-3.005-8.947-6.476-14.021c0,0-1.335,5.341,0.789,13.408" fill="#E7EBEE" />
            <Path
              d="M64.742,149.913c-0.086,0-0.164-0.06-0.185-0.148c-1.033-4.523-3.005-8.849-6.195-13.584 c-0.254,1.425-0.871,6.249,0.876,12.88c0.026,0.101-0.034,0.205-0.135,0.232c-0.102,0.024-0.205-0.034-0.232-0.135 c-2.113-8.021-0.803-13.448-0.79-13.502c0.018-0.072,0.077-0.127,0.15-0.141c0.075-0.012,0.148,0.018,0.19,0.08 c3.367,4.921,5.434,9.397,6.505,14.086c0.023,0.102-0.04,0.204-0.143,0.227C64.77,149.911,64.756,149.913,64.742,149.913z"
              fill="#010101"
            />
          </G>
          <G>
            <Path d="M61.163,149.556c-2.676-3.306-5.901-6.176-10.775-8.847c0,0,0.734,3.873,4.407,8.88" fill="#E7EBEE" />
            <Path
              d="M54.794,149.78c-0.058,0-0.116-0.027-0.153-0.077c-3.655-4.985-4.433-8.918-4.44-8.957 c-0.014-0.072,0.015-0.146,0.075-0.189c0.06-0.043,0.138-0.048,0.202-0.013c4.515,2.474,7.856,5.217,10.831,8.894 c0.066,0.081,0.053,0.201-0.028,0.267c-0.081,0.066-0.201,0.054-0.267-0.028c-2.86-3.533-6.063-6.196-10.343-8.591 c0.298,1.119,1.353,4.409,4.274,8.393c0.062,0.084,0.044,0.203-0.041,0.265C54.872,149.768,54.833,149.78,54.794,149.78z"
              fill="#010101"
            />
          </G>
          <G>
            <Path d="M69.483,149.556c2.676-3.306,5.901-6.176,10.775-8.847c0,0-0.734,3.873-4.407,8.88" fill="#E7EBEE" />
            <Path
              d="M75.852,149.78c-0.039,0-0.078-0.012-0.112-0.037c-0.084-0.062-0.103-0.181-0.041-0.265 c2.922-3.984,3.977-7.274,4.274-8.393c-4.28,2.395-7.483,5.058-10.343,8.591c-0.066,0.083-0.186,0.094-0.267,0.028 c-0.081-0.066-0.094-0.186-0.028-0.267c2.976-3.677,6.316-6.42,10.831-8.894c0.064-0.035,0.144-0.03,0.202,0.013 c0.06,0.043,0.089,0.116,0.075,0.189c-0.007,0.039-0.785,3.973-4.44,8.957C75.967,149.753,75.91,149.78,75.852,149.78z"
              fill="#010101"
            />
          </G>
        </G>
        <Path
          d="M66.982,149.951H46.987c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h19.995 c0.121,0,0.219,0.098,0.219,0.219C67.201,149.853,67.103,149.951,66.982,149.951z"
          fill="#010101"
        />
        <Path
          d="M82.781,149.951h-8.459c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h8.459 c0.121,0,0.219,0.098,0.219,0.219C83,149.853,82.902,149.951,82.781,149.951z"
          fill="#010101"
        />
      </G>
    </Svg>
  );
}
