/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable react/jsx-sort-props */
const MegaphoneLg = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(64)} viewBox="0 0 64 64" width={moderateScale(64)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Polygon fill="#bac2ca" points="3.97 36.67 7 46.51 8.31 46.12 5.09 36.26 3.97 36.67" />
      <Path fill="#e6ebef" d="M50.54,48.06,38.94,10.84a2.42,2.42,0,0,0-3-1.59h0L49.32,51A2.43,2.43,0,0,0,50.54,48.06Z" />
      <Path fill="#e6ebef" d="M7.34,37a1.54,1.54,0,0,1,1-1.91l8.33-2.62-.11-.31a1.53,1.53,0,0,0-1.93-1L6.12,33.79a1.54,1.54,0,0,0-1,1.92L8.32,46.36a1.54,1.54,0,0,0,2,1l.18-.06Z" />
      <Polyline fill="#e6ebef" points="12.04 49.13 15.2 59.27 19.21 58.03 17.96 47.06" />
      <Polygon fill="#bac2ca" points="12.6 50.66 17.94 49.03 17.67 47.1 12.16 49.09 12.6 50.66" />
      <Path fill="#bac2ca" d="M43.36,24.6l.42-.13a5,5,0,0,1,6.31,3.31h0a5,5,0,0,1-3.31,6.32l-.54.17" />
      <Path fill={svgHighlight} d="M16.82,32a27.24,27.24,0,0,0,11.69-7.3c6.44-6.64,6-11.21,6-11.21L45.39,48.28s-2.25-4-11.33-5.8a27.22,27.22,0,0,0-13.76.64L17.09,32.81" />
      <Line fill={svgHighlight} x1="17.09" y1="32.81" x2="16.82" y2="31.96" />
      <Path fill={svgHighlight} d="M45.39,48.28l-2.65-8.52c-1.25-.37-2.62-.71-4.12-1a39.86,39.86,0,0,0-19.43.8l1.11,3.57a27.22,27.22,0,0,1,13.76-.64C43.14,44.28,45.39,48.28,45.39,48.28Z" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.56px" x1="45.87" y1="48.9" x2="39.02" y2="26.93" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M49.78,45.62l.76,2.44a2.42,2.42,0,0,1-1.59,3h0a2.42,2.42,0,0,1-3-1.59l-.22-.71" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M34.63,13.33l-.32-1a2.42,2.42,0,0,1,1.59-3h0a2.42,2.42,0,0,1,3,1.59l10.3,33" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.56px" x1="38.13" y1="24.03" x2="34.73" y2="13.15" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M16.82,32a27.24,27.24,0,0,0,11.69-7.3c6.44-6.64,6-11.21,6-11.21" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M34.63,42.6c8.6,1.85,10.76,5.68,10.76,5.68" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M20.3,43.12a26.77,26.77,0,0,1,12.29-.89" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.56px" x1="6.87" y1="41.53" x2="5.11" y2="35.82" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.56px" x1="8.4" y1="46.37" x2="7.41" y2="43.26" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M10.4,47.42a1.6,1.6,0,0,1-2-1.05" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.56px" x1="19.04" y1="44.6" x2="10.4" y2="47.3" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M20.09,42.73a1.61,1.61,0,0,1-1.05,2" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.56px" x1="17.28" y1="33.64" x2="20.09" y2="42.73" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M5.11,35.82a1.59,1.59,0,0,1,1-2l8.64-2.7a1.6,1.6,0,0,1,2,1.05" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M5,36.07l-2,.62a1.64,1.64,0,0,0-1.08,2.06L4.1,45.84a1.65,1.65,0,0,0,2.06,1.08l2.14-.66" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M43.36,24.6l.42-.13a5,5,0,0,1,6.31,3.31h0a5,5,0,0,1-3.31,6.32l-.54.17" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" d="M10.4,47.42l.18.6a1.21,1.21,0,0,0,1.51.79l6.41-2a1.21,1.21,0,0,0,.79-1.5l-.19-.6" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" points="12.04 49.13 15.2 59.27 19.21 58.03 17.96 47.06" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" x1="57.83" y1="24.98" x2="63.15" y2="23.3" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" x1="54.88" y1="25.9" x2="55.85" y2="25.59" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" x1="56.72" y1="43.93" x2="62.98" y2="47.16" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.88px" x1="45.25" y1="10.66" x2="48.18" y2="4.25" />
    </Svg>
  );
};

export default MegaphoneLg;
