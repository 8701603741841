import React from 'react';
import T from 'prop-types';

import { marketingTilesPropsHelper } from '@dmi/shared/redux/Main/helpers';

import Card from './Card';
import {
  CardGroup,
  HeaderText,
  WhatsNewSection,
} from './styledComponents';

const WhatsNew = ({
  isEligibleForAutopay,
  isEligibleForPaperlessBilling,
  isMurabahaAccount,
  navigation,
}) => {
  const marketingTilesArray = marketingTilesPropsHelper({
    isEligibleForAutopay,
    isEligibleForPaperlessBilling,
    isMurabahaAccount,
  });

  return (
    <WhatsNewSection>
      <HeaderText>What&apos;s New</HeaderText>
      <CardGroup>
        {marketingTilesArray.map((tile) => (
          <Card
            key={tile.title}
            navigation={navigation}
            {...tile}
          />
        ))}
      </CardGroup>
    </WhatsNewSection>
  );
};

WhatsNew.propTypes = {
  isEligibleForAutopay: T.bool.isRequired,
  isEligibleForPaperlessBilling: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default WhatsNew;
