import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, useIntl } from 'react-intl';

import {
  amortizeCalculate,
  changeAdjustmentFilter,
  changeInput,
  resetAllCalculators,
  resetCalculator,
  resetCalculatorResults,
  resetFormError,
  setIsSliderFocused,
  updateFormError,
} from '@dmi/shared/redux/Calculators/Amortize/actions';
import {
  makeSelectAmortize,
  selectCalculateRequestBody,
  selectChartDataToDisplay,
  selectForm,
  selectFormErrors,
  selectFormValues,
  selectFormattedFormValues,
  selectInitialTerms,
  selectIsAmortizationScheduleCalculated,
  selectIsCalculateButtonDisabled,
  selectIsCalculatorDataValid,
  selectIsFormValidated,
  selectIsSliderFocused,
  selectMaximumPaymentAmount,
  selectMenuItems,
  selectNotifications,
  selectResultsData,
  selectResultsSummaryBarProps,
  selectScheduleToDisplay,
} from '@dmi/shared/redux/Calculators/Amortize/selectors';
import makeSelectMain from '@dmi/shared/redux/Main/selectors';

import { downloadScheduleCSV } from './helpers';
import { StyledView } from './styledComponents';
import AsyncRender from '../../components/AsyncRender';
import Amortization from '../../components/Calculators/Amortization';
import PaymentAdjustment from '../../components/Calculators/PaymentAdjustment';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';

const AmortizeScreen = ({
  amortizationValues,
  chartData,
  dispatchAmortizeCalculate,
  dispatchChangeAdjustmentFilter,
  dispatchChangeInput,
  dispatchResetAllCalculators,
  dispatchResetCalculator,
  dispatchResetCalculatorResults,
  dispatchResetFormError,
  dispatchSetIsSliderFocused,
  dispatchUpdateFormError,
  formattedFormValues,
  formErrors,
  formValues,
  initialTerms,
  isAmortizationScheduleCalculated,
  isCalculateButtonDisabled,
  isCalculatorDataValid,
  isFormValidated,
  isSliderFocused,
  loanNumber,
  maximumPaymentAmount,
  menuItems,
  navigation,
  notifications,
  requestBody,
  resultsData,
  resultsSummaryBarProps,
  schedule,
  selectedAdjustmentFilter,
  status,
  summaryBarProps,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => () => {
    dispatchResetAllCalculators();
  }, [dispatchResetAllCalculators]);

  const { fetchStatus } = status;

  const propsToPassDown = {
    amortizationValues,
    chartData,
    dispatchAmortizeCalculate,
    dispatchChangeInput,
    dispatchResetAllCalculators,
    dispatchResetCalculator,
    dispatchResetCalculatorResults,
    dispatchResetFormError,
    dispatchSetIsSliderFocused,
    dispatchUpdateFormError,
    formattedFormValues,
    formErrors,
    formValues,
    handleChangeAdjustmentFilter: dispatchChangeAdjustmentFilter,
    handleDownloadCSV: () => downloadScheduleCSV(schedule, formatMessage),
    initialTerms,
    isAmortizationScheduleCalculated,
    isCalculateButtonDisabled,
    isCalculatorDataValid,
    isFormValidated,
    isSliderFocused,
    loanNumber,
    maximumPaymentAmount,
    menuItems,
    navigation,
    requestBody,
    resultsData,
    resultsSummaryBarProps,
    selectedAdjustmentFilter,
    status,
    summaryBarProps,
  };

  const ViewToRender = selectedAdjustmentFilter === 'amortization' ?
    Amortization :
    PaymentAdjustment;

  return (
    <StyledView>
      <ScreenReaderNotifications notifications={notifications} />
      <AsyncRender
        Component={ViewToRender}
        error={fetchStatus === 'failed'}
        loading={fetchStatus === 'loading'}
        propsToPassDown={{ ...propsToPassDown }}
      />
    </StyledView>
  );
};

AmortizeScreen.propTypes = {
  amortizationValues: T.object.isRequired,
  chartData: T.object.isRequired,
  dispatchAmortizeCalculate: T.func.isRequired,
  dispatchChangeAdjustmentFilter: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetAllCalculators: T.func.isRequired,
  dispatchResetCalculator: T.func.isRequired,
  dispatchResetCalculatorResults: T.func.isRequired,
  dispatchResetFormError: T.func.isRequired,
  dispatchSetIsSliderFocused: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  formattedFormValues: T.object.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  initialTerms: T.array.isRequired,
  isAmortizationScheduleCalculated: T.bool.isRequired,
  isCalculateButtonDisabled: T.bool.isRequired,
  isCalculatorDataValid: T.bool.isRequired,
  isFormValidated: T.bool.isRequired,
  isSliderFocused: T.bool,
  loanNumber: T.string.isRequired,
  maximumPaymentAmount: T.number.isRequired,
  menuItems: T.object.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  requestBody: T.object.isRequired,
  resultsData: T.array.isRequired,
  resultsSummaryBarProps: T.object.isRequired,
  schedule: T.array.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
  status: T.object.isRequired,
  summaryBarProps: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Amortize
   */
  amortizationValues: selectForm('amortization'),
  chartData: selectChartDataToDisplay(),
  formattedFormValues: selectFormattedFormValues(),
  formErrors: selectFormErrors(),
  formValues: selectFormValues(),
  initialTerms: selectInitialTerms(),
  isAmortizationScheduleCalculated: selectIsAmortizationScheduleCalculated(),
  isCalculateButtonDisabled: selectIsCalculateButtonDisabled(),
  isCalculatorDataValid: selectIsCalculatorDataValid(),
  isFormValidated: selectIsFormValidated(),
  isSliderFocused: selectIsSliderFocused(),
  maximumPaymentAmount: selectMaximumPaymentAmount(),
  menuItems: selectMenuItems(),
  notifications: selectNotifications(),
  requestBody: selectCalculateRequestBody(),
  resultsData: selectResultsData(),
  resultsSummaryBarProps: selectResultsSummaryBarProps(),
  schedule: selectScheduleToDisplay(),
  selectedAdjustmentFilter: makeSelectAmortize('selectedAdjustmentFilter'),
  status: makeSelectAmortize('status'),
  summaryBarProps: selectResultsSummaryBarProps(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  loanNumber: makeSelectMain('selectedLoan'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Amortize
   */
  dispatchAmortizeCalculate: (payload) => dispatch(amortizeCalculate(payload)),
  dispatchChangeAdjustmentFilter: (payload) => dispatch(changeAdjustmentFilter(payload)),
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchResetAllCalculators: () => dispatch(resetAllCalculators()),
  dispatchResetCalculator: (payload) => dispatch(resetCalculator(payload)),
  dispatchResetCalculatorResults: (payload) => dispatch(resetCalculatorResults(payload)),
  dispatchResetFormError: (payload) => dispatch(resetFormError(payload)),
  dispatchSetIsSliderFocused: (payload) => dispatch(setIsSliderFocused(payload)),
  dispatchUpdateFormError: (payload) => dispatch(updateFormError(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(AmortizeScreen);
