/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function SettingsTownscape(props) {
  return (
    <Svg fill="#000" height={moderateScale(150)} viewBox="0 0 327 150" width={moderateScale(327)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M38.42,90.93,62.85,69.42a14,14,0,0,1,9.24-3.49h0a14,14,0,0,0,10.28-4.5l1.55-1.68a38.8,38.8,0,0,1,28.53-12.5h17.94A50.74,50.74,0,0,1,163,59.09l3.82,3.2L144.71,98.21l-89,11.94Z" fill="#e6ebef" />
      <Polygon fill="#e6ebef" points="206.99 46.7 199.03 53.95 199.03 110.07 216.35 110.07 215.76 49.89 206.99 46.7" />
      <Polyline fill="#fff" points="238.69 107.64 238.69 53.87 215.2 53.87 215.2 107.64" />
      <Rect fill="#bac2ca" height="3.65" transform="translate(466.81 119.09) rotate(180)" width="2.88" x="231.96" y="57.72" />
      <Polygon fill="#bac2ca" points="215.35 53.95 238.85 53.95 230.49 46.7 206.99 46.7 215.35 53.95" />
      <Path d="M238.85,54.24h-23.5a.26.26,0,0,1-.19-.07l-8.36-7.26a.29.29,0,0,1,.19-.5h23.49a.26.26,0,0,1,.19.07L239,53.74a.27.27,0,0,1,.09.31A.29.29,0,0,1,238.85,54.24Zm-23.39-.57h22.62L230.38,47H207.75Z" />
      <Path d="M238.69,110.83H199.05a.29.29,0,0,1-.29-.29V54a.29.29,0,0,1,.09-.21l7.92-7.26a.31.31,0,0,1,.41,0,.29.29,0,0,1,0,.41l-7.83,7.17v56.17h39.08v-56a.28.28,0,0,1,.28-.29.29.29,0,0,1,.29.29v56.28A.29.29,0,0,1,238.69,110.83Z" />
      <Path d="M215.14,88.4a.19.19,0,0,1-.19-.19V76.94a.2.2,0,0,1,.19-.19.19.19,0,0,1,.19.19V88.21A.18.18,0,0,1,215.14,88.4Z" />
      <Path d="M215.14,75.3a.19.19,0,0,1-.19-.19V54.3a.19.19,0,0,1,.19-.19.18.18,0,0,1,.19.19V75.11A.18.18,0,0,1,215.14,75.3Z" />
      <Path d="M234.94,61.62H231.8a.21.21,0,0,1-.21-.21V57.68a.21.21,0,0,1,.21-.21h3.14a.21.21,0,0,1,.21.21v3.73A.21.21,0,0,1,234.94,61.62ZM232,61.2h2.72V57.89H232Z" />
      <Rect fill="#bac2ca" height="3.65" transform="translate(454.36 119.09) rotate(180)" width="2.88" x="225.74" y="57.72" />
      <Path d="M228.71,61.62h-3.14a.21.21,0,0,1-.21-.21V57.68a.21.21,0,0,1,.21-.21h3.14a.21.21,0,0,1,.21.21v3.73A.21.21,0,0,1,228.71,61.62Zm-2.93-.42h2.72V57.89h-2.72Z" />
      <Rect fill="#bac2ca" height="3.65" transform="translate(466.81 136.81) rotate(180)" width="2.88" x="231.96" y="66.58" />
      <Path d="M234.94,70.48H231.8a.21.21,0,0,1-.21-.21V66.54a.21.21,0,0,1,.21-.21h3.14a.21.21,0,0,1,.21.21v3.73A.21.21,0,0,1,234.94,70.48ZM232,70.06h2.72V66.75H232Z" />
      <Rect fill="#bac2ca" height="3.65" transform="translate(454.36 136.81) rotate(180)" width="2.88" x="225.74" y="66.58" />
      <Path d="M228.71,70.48h-3.14a.21.21,0,0,1-.21-.21V66.54a.21.21,0,0,1,.21-.21h3.14a.21.21,0,0,1,.21.21v3.73A.21.21,0,0,1,228.71,70.48Zm-2.93-.42h2.72V66.75h-2.72Z" />
      <Rect fill="#bac2ca" height="3.65" transform="translate(441.93 119.09) rotate(180)" width="2.88" x="219.52" y="57.72" />
      <Path d="M222.5,61.62h-3.15a.22.22,0,0,1-.21-.21V57.68a.21.21,0,0,1,.21-.21h3.15a.21.21,0,0,1,.21.21v3.73A.22.22,0,0,1,222.5,61.62Zm-2.94-.42h2.73V57.89h-2.73Z" />
      <Rect fill="#bac2ca" height="3.65" transform="translate(441.93 136.81) rotate(180)" width="2.88" x="219.52" y="66.58" />
      <Path d="M222.5,70.48h-3.15a.22.22,0,0,1-.21-.21V66.54a.21.21,0,0,1,.21-.21h3.15a.21.21,0,0,1,.21.21v3.73A.22.22,0,0,1,222.5,70.48Zm-2.94-.42h2.73V66.75h-2.73Z" />
      <Path d="M76,85.66h0c-2.68-16.19-6.44-15.47-6.44-15.47v0s-3.62-.74-6.3,15.45h0a9.67,9.67,0,0,0-.13,1.53c0,4.37,2.91,7.91,6.49,7.91s6.49-3.54,6.49-7.91A9.67,9.67,0,0,0,76,85.66Z" fill="#e6ebef" />
      <Path d="M76.09,87.19A9.67,9.67,0,0,0,76,85.66h0c-2.68-16.19-6.44-15.47-6.44-15.47v0s.07-.12-.32.07A51.86,51.86,0,0,1,73.57,86c.5,5.75-1.58,8.42-4,9.09h0C73.19,95.1,76.09,91.56,76.09,87.19Z" fill="#bac2ca" />
      <Path d="M69.6,95.39c-3.73,0-6.77-3.68-6.77-8.2a10.78,10.78,0,0,1,.11-1.49v-.09a69.79,69.79,0,0,1,1.75-8,.29.29,0,0,1,.35-.2.3.3,0,0,1,.2.36,69.73,69.73,0,0,0-1.72,7.89v.09a9.23,9.23,0,0,0-.12,1.48c0,4.2,2.78,7.63,6.2,7.63s6.21-3.43,6.21-7.63a9.23,9.23,0,0,0-.12-1.48.13.13,0,0,1,0-.06c-1.82-11-4.12-14-5.33-14.87a1.32,1.32,0,0,0-.7-.3.79.79,0,0,1-.13,0l-.11,0c-.29,0-1.67.35-3.27,4.52a.29.29,0,0,1-.38.16.28.28,0,0,1-.16-.37c1.77-4.61,3.43-4.92,3.87-4.89h0a1.7,1.7,0,0,1,1.21.4c1.57,1.09,3.81,4.63,5.58,15.3v.06a9.83,9.83,0,0,1,.12,1.52C76.38,91.71,73.34,95.39,69.6,95.39Z" />
      <Path d="M69.52,100.2a.28.28,0,0,1-.28-.29V83a.28.28,0,0,1,.28-.29.29.29,0,0,1,.29.29v17A.29.29,0,0,1,69.52,100.2Z" />
      <Path d="M69.45,88.51a.24.24,0,0,1-.16-.05l-2.37-1.69a.29.29,0,0,1-.07-.4.28.28,0,0,1,.4-.06L69.62,88a.29.29,0,0,1,.07.4A.3.3,0,0,1,69.45,88.51Z" />
      <Path d="M69.58,90.49a.27.27,0,0,1-.23-.12.29.29,0,0,1,.07-.4l2.36-1.69a.29.29,0,0,1,.4.07.28.28,0,0,1-.06.4l-2.37,1.69A.34.34,0,0,1,69.58,90.49Z" />
      <Path d="M62,97.13h0c-2.1-13.56-5-12.95-5-12.95v0S54.09,83.57,52,97.13h0a8.85,8.85,0,0,0-.1,1.28c0,3.66,2.28,6.62,5.08,6.62s5.09-3,5.09-6.62A8.85,8.85,0,0,0,62,97.13Z" fill="#e6ebef" />
      <Path d="M62.07,98.41a8.85,8.85,0,0,0-.1-1.28h0c-2.1-13.56-5-12.95-5-12.95v0s0-.1-.25.06a45.79,45.79,0,0,1,3.42,13.17c.39,4.81-1.24,7-3.15,7.61h0C59.79,105,62.07,102.07,62.07,98.41Z" fill="#bac2ca" />
      <Path d="M57,105.32c-3,0-5.37-3.1-5.37-6.91a8.86,8.86,0,0,1,.09-1.25v-.08a63.69,63.69,0,0,1,1.37-6.73v0L53.78,88c1.38-3.83,2.65-4.17,3.09-4.14h0a1.29,1.29,0,0,1,.93.3c1.26.87,3.05,3.81,4.45,12.88a.11.11,0,0,1,0,0,9.29,9.29,0,0,1,.09,1.28C62.35,102.22,59.94,105.32,57,105.32Zm-4.7-8.23v.08a8.62,8.62,0,0,0-.09,1.24c0,3.49,2.15,6.33,4.79,6.33s4.8-2.84,4.8-6.33a7.41,7.41,0,0,0-.1-1.24v0C59.85,85.36,57.43,84.52,57,84.47l-.11,0-.1,0c-.21,0-1.27.34-2.49,3.75l-.71,2.28A65.3,65.3,0,0,0,52.28,97.09Z" />
      <Path d="M125.2,66h0c-2.1-13.55-5-12.94-5-12.94v0s-2.83-.62-4.93,12.93h0a9,9,0,0,0-.09,1.28c0,3.66,2.27,6.63,5.08,6.63s5.08-3,5.08-6.63A9.07,9.07,0,0,0,125.2,66Z" fill="#e6ebef" />
      <Path d="M125.29,67.29A9.07,9.07,0,0,0,125.2,66h0c-2.1-13.55-5-12.94-5-12.94v0s.06-.1-.24.06a45.7,45.7,0,0,1,3.42,13.16c.39,4.82-1.24,7-3.15,7.61h0C123,73.92,125.29,71,125.29,67.29Z" fill="#bac2ca" />
      <Path d="M120.21,74.2c-3,0-5.37-3.1-5.37-6.91a8.62,8.62,0,0,1,.09-1.24.11.11,0,0,1,0-.08,61,61,0,0,1,1.37-6.74h0l.71-2.29c1.37-3.81,2.66-4.14,3.07-4.14h0a1.31,1.31,0,0,1,.94.3c1.25.87,3,3.82,4.45,12.89a.09.09,0,0,1,0,0,9,9,0,0,1,.09,1.27C125.58,71.1,123.17,74.2,120.21,74.2ZM115.51,66v.08a8.5,8.5,0,0,0-.1,1.23c0,3.5,2.16,6.34,4.8,6.34s4.8-2.84,4.8-6.34a8.62,8.62,0,0,0-.1-1.23v0c-1.83-11.76-4.25-12.6-4.66-12.66a.17.17,0,0,1-.1,0h0l-.11,0c-.2,0-1.26.33-2.49,3.75l-.7,2.28A64.17,64.17,0,0,0,115.51,66Z" />
      <Path d="M113.2,67h0c-1.59-10.24-3.82-9.78-3.82-9.78v0s-2.14-.47-3.72,9.77h0a7.5,7.5,0,0,0-.07,1c0,2.76,1.72,5,3.84,5s3.84-2.24,3.84-5A6,6,0,0,0,113.2,67Z" fill="#e6ebef" />
      <Path d="M113.28,68a6,6,0,0,0-.08-1h0c-1.59-10.24-3.82-9.78-3.82-9.78v0s0-.07-.19.05a34.2,34.2,0,0,1,2.58,9.94c.3,3.64-.93,5.32-2.37,5.75h0C111.56,73,113.28,70.72,113.28,68Z" fill="#bac2ca" />
      <Path d="M109.44,73.24c-2.27,0-4.13-2.37-4.13-5.28a7.43,7.43,0,0,1,.07-.94V67a49.77,49.77,0,0,1,1-5.1h0l.54-1.73c1-2.92,2-3.17,2.39-3.18a1.05,1.05,0,0,1,.75.23c1,.67,2.33,2.91,3.39,9.79v0a6.33,6.33,0,0,1,.07,1C113.56,70.87,111.71,73.24,109.44,73.24ZM106,67V67a5.82,5.82,0,0,0-.07.93c0,2.6,1.59,4.71,3.55,4.71S113,70.56,113,68a5.82,5.82,0,0,0-.07-.93v0c-1.36-8.72-3.14-9.43-3.44-9.49a.15.15,0,0,1-.09,0h-.09c-.14,0-.9.28-1.8,2.79L107,62A48.43,48.43,0,0,0,106,67Z" />
      <Path d="M270.73,87.39h0c-2.1-13.55-5.05-12.94-5.05-12.94v0s-2.82-.62-4.93,12.93h0c-.07.42-.1,4.82-.1,5.25,0,3.66,2.28,5.83,5.08,5.83s5.08-2.17,5.08-5.83C270.82,92.21,270.79,87.81,270.73,87.39Z" fill="#e6ebef" />
      <Path d="M270.82,92.64c0-.43,0-4.83-.09-5.25h0c-2.1-13.55-5.05-12.94-5.05-12.94v0s.06-.1-.24.06a45.79,45.79,0,0,1,3.42,13.17c.56,6.88-1.41,10.27-3.15,10.78h0C268.55,98.47,270.82,96.3,270.82,92.64Z" fill="#bac2ca" />
      <Path d="M265.74,98.76c-3.16,0-5.37-2.52-5.37-6.12,0-.27,0-4.41.09-5.2v-.09a66.57,66.57,0,0,1,1.37-6.74h0l.71-2.29c1.38-3.82,2.66-4.17,3.08-4.14h0a1.21,1.21,0,0,1,.93.29c1.26.87,3.05,3.82,4.45,12.89a.13.13,0,0,1,0,.06c.06.69.09,5,.09,5.23C271.11,96.24,268.9,98.76,265.74,98.76ZM261,87.36v.08c-.06.37-.09,4.61-.09,5.2,0,3.27,2,5.55,4.79,5.55s4.8-2.28,4.8-5.55c0-.57,0-4.83-.1-5.2v0c-1.83-11.76-4.25-12.6-4.65-12.66a.23.23,0,0,1-.11,0l-.1,0c-.21,0-1.27.33-2.49,3.75l-.71,2.28A64.17,64.17,0,0,0,261,87.36Z" />
      <Path d="M165.47,70.75h-17A31.75,31.75,0,0,0,129.64,77l-8.13,6a31.72,31.72,0,0,1-18.85,6.2H86.54a31.72,31.72,0,0,0-21,7.93l-6.37,5.62a31.76,31.76,0,0,1-21,7.94H23.53a6.13,6.13,0,0,0-6.14,6.13H166.58" fill="#e6ebef" />
      <Polygon fill="#bac2ca" points="79.86 116.48 77.66 116.48 77.66 90.56 79.86 89.88 79.86 116.48" />
      <Path d="M17.42,116.83a.29.29,0,0,1-.29-.29,6.23,6.23,0,0,1,6.4-6.18H38.2A31.44,31.44,0,0,0,59,102.5l6.37-5.62a31.8,31.8,0,0,1,18-7.85.28.28,0,0,1,.31.26.27.27,0,0,1-.25.31,31.26,31.26,0,0,0-17.69,7.71l-6.37,5.62a32,32,0,0,1-21.18,8H23.53a5.67,5.67,0,0,0-5.83,5.61A.28.28,0,0,1,17.42,116.83Z" />
      <Polyline fill="#fff" points="154.79 47.29 154.79 72.25 172.44 72.25 171.84 33.29 162.9 29.74 154.79 37.43 154.79 47.29" />
      <Polyline fill="#e6ebef" points="189.12 61.39 189.12 37.34 171.26 37.34 171.26 64.13" />
      <Rect fill="#bac2ca" height="3.81" transform="translate(368.02 87.77) rotate(180)" width="3.01" x="182.5" y="41.98" />
      <Polygon fill="#bac2ca" points="171.42 37.43 189.28 37.43 180.76 29.74 162.9 29.74 171.42 37.43" />
      <Path d="M189.28,37.72H171.42a.27.27,0,0,1-.19-.08L162.71,30a.28.28,0,0,1-.08-.31.3.3,0,0,1,.27-.19h17.86a.27.27,0,0,1,.19.08l8.52,7.69a.28.28,0,0,1,.08.31A.3.3,0,0,1,189.28,37.72Zm-17.75-.58h17L180.65,30h-17Z" />
      <Path d="M154.81,50a.29.29,0,0,1-.29-.29V37.42a.37.37,0,0,1,.09-.2l8.07-7.69a.29.29,0,0,1,.4.42l-8,7.61V49.68A.29.29,0,0,1,154.81,50Z" />
      <Path d="M189.12,74.64H154.81a.29.29,0,0,1-.29-.29v-23a.29.29,0,0,1,.58,0v22.7h33.73V37.74a.29.29,0,1,1,.58,0V74.35A.29.29,0,0,1,189.12,74.64Z" />
      <Path d="M171.21,64.77a.2.2,0,0,1-.19-.19V37.78a.19.19,0,0,1,.19-.19.18.18,0,0,1,.19.19v26.8A.19.19,0,0,1,171.21,64.77Z" />
      <Path d="M185.62,46.05h-3.29a.21.21,0,0,1-.21-.22V41.94a.21.21,0,0,1,.21-.21h3.29a.21.21,0,0,1,.21.21v3.89A.22.22,0,0,1,185.62,46.05Zm-3.08-.43h2.87V42.15h-2.87Z" />
      <Rect fill="#bac2ca" height="3.81" transform="translate(355.01 87.77) rotate(180)" width="3.01" x="176" y="41.98" />
      <Path d="M179.11,46.05h-3.29a.22.22,0,0,1-.21-.22V41.94a.21.21,0,0,1,.21-.21h3.29a.21.21,0,0,1,.21.21v3.89A.21.21,0,0,1,179.11,46.05ZM176,45.62h2.86V42.15H176Z" />
      <Rect fill="#bac2ca" height="3.81" transform="translate(324.79 87.77) rotate(180)" width="3.01" x="160.89" y="41.98" />
      <Path d="M164,46.05h-3.28a.21.21,0,0,1-.21-.22V41.94a.21.21,0,0,1,.21-.21H164a.21.21,0,0,1,.21.21v3.89A.21.21,0,0,1,164,46.05Zm-3.07-.43h2.86V42.15h-2.86Z" />
      <Rect fill="#bac2ca" height="3.81" transform="translate(368.02 103.83) rotate(180)" width="3.01" x="182.5" y="50.01" />
      <Path d="M185.62,54.07h-3.29a.21.21,0,0,1-.21-.21V50a.21.21,0,0,1,.21-.21h3.29a.21.21,0,0,1,.21.21v3.89A.21.21,0,0,1,185.62,54.07Zm-3.08-.42h2.87V50.18h-2.87Z" />
      <Rect fill="#bac2ca" height="3.81" transform="translate(355.01 103.83) rotate(180)" width="3.01" x="176" y="50.01" />
      <Path d="M179.11,54.07h-3.29a.21.21,0,0,1-.21-.21V50a.21.21,0,0,1,.21-.21h3.29a.21.21,0,0,1,.21.21v3.89A.21.21,0,0,1,179.11,54.07ZM176,53.65h2.86V50.18H176Z" />
      <Polygon fill="#fff" points="193 60.63 201.99 70.85 201.99 97.51 182.42 97.51 183.08 66.26 193 60.63" />
      <Polyline fill="#e6ebef" points="163.92 97.42 163.92 70.76 183.72 70.76 183.72 97.42" />
      <Rect fill="#bac2ca" height="4.16" width="3.28" x="168.01" y="74.44" />
      <Polygon fill="#bac2ca" points="183.55 70.85 163.75 70.85 173.19 60.63 193 60.63 183.55 70.85" />
      <Path d="M183.55,71.14h-19.8a.3.3,0,0,1-.27-.17.31.31,0,0,1,.05-.31L173,60.43a.29.29,0,0,1,.21-.09H193a.29.29,0,0,1,.26.17.27.27,0,0,1,0,.31L183.76,71A.28.28,0,0,1,183.55,71.14Zm-19.15-.58h19l8.91-9.65h-19Z" />
      <Path d="M202,97.88H163.92a.29.29,0,0,1-.29-.28V90.66a.29.29,0,0,1,.58,0v6.65h37.48V71l-8.89-10.14a.29.29,0,0,1,.44-.38l9,10.22a.31.31,0,0,1,.07.18V97.6A.29.29,0,0,1,202,97.88Z" />
      <Path d="M183.78,97.61a.19.19,0,0,1-.19-.19V83.79a.19.19,0,0,1,.19-.19.19.19,0,0,1,.2.19V97.42A.19.19,0,0,1,183.78,97.61Z" />
      <Path d="M183.78,82.22a.2.2,0,0,1-.19-.19V71.24a.19.19,0,0,1,.19-.19.19.19,0,0,1,.2.19V82A.2.2,0,0,1,183.78,82.22Z" />
      <Path d="M171.48,78.85H167.9a.21.21,0,0,1-.21-.21V74.4a.21.21,0,0,1,.21-.22h3.58a.21.21,0,0,1,.21.22v4.24A.21.21,0,0,1,171.48,78.85Zm-3.37-.42h3.16V74.61h-3.16Z" />
      <Rect fill="#bac2ca" height="4.16" width="3.28" x="175.1" y="74.44" />
      <Path d="M178.57,78.85H175a.21.21,0,0,1-.21-.21V74.4a.22.22,0,0,1,.21-.22h3.58a.22.22,0,0,1,.22.22v4.24A.21.21,0,0,1,178.57,78.85Zm-3.37-.42h3.16V74.61H175.2Z" />
      <Rect fill="#bac2ca" height="4.16" width="3.28" x="191.41" y="74.44" />
      <Path d="M194.89,78.85H191.3a.21.21,0,0,1-.21-.21V74.4a.22.22,0,0,1,.21-.22h3.59a.22.22,0,0,1,.21.22v4.24A.21.21,0,0,1,194.89,78.85Zm-3.38-.42h3.16V74.61h-3.16Z" />
      <Polygon fill="#fff" points="154.83 60.63 163.82 70.85 163.82 97.51 144.25 97.51 144.91 66.26 154.83 60.63" />
      <Polyline fill="#e6ebef" points="125.75 97.42 125.75 70.76 145.56 70.76 145.56 97.42" />
      <Path d="M140.59,62.66h-2.14a.23.23,0,0,1-.23-.23V56.3a.23.23,0,0,1,.23-.23h2.14a.23.23,0,0,1,.23.23v6.13A.23.23,0,0,1,140.59,62.66Zm-1.91-.46h1.68V56.53h-1.68Z" />
      <Polygon fill="#bac2ca" points="145.38 70.85 125.58 70.85 135.02 60.63 154.83 60.63 145.38 70.85" />
      <Path d="M145.38,71.14h-19.8a.3.3,0,0,1-.27-.17.29.29,0,0,1,.06-.31l9.44-10.23a.29.29,0,0,1,.21-.09h19.81a.28.28,0,0,1,.26.17.27.27,0,0,1-.05.31L145.6,71A.32.32,0,0,1,145.38,71.14Zm-19.15-.58h19l8.92-9.65h-19Z" />
      <Path d="M163.8,97.88H125.75a.28.28,0,0,1-.28-.28V71.19a.27.27,0,0,1,.28-.28.28.28,0,0,1,.29.28V97.31h37.48V71l-8.88-10.14a.29.29,0,0,1,0-.41.3.3,0,0,1,.41,0L164,70.66a.31.31,0,0,1,.07.18V97.6A.29.29,0,0,1,163.8,97.88Z" />
      <Path d="M145.62,97.61a.19.19,0,0,1-.2-.19V71.24a.19.19,0,0,1,.2-.19.19.19,0,0,1,.19.19V97.42A.19.19,0,0,1,145.62,97.61Z" />
      <Rect fill="#bac2ca" height="4.16" width="3.28" x="153.24" y="74.44" />
      <Path d="M156.72,78.85h-3.59a.21.21,0,0,1-.21-.21V74.4a.22.22,0,0,1,.21-.22h3.59a.22.22,0,0,1,.21.22v4.24A.21.21,0,0,1,156.72,78.85Zm-3.37-.42h3.16V74.61h-3.16Z" />
      <Path d="M106.31,70.9h17a31.73,31.73,0,0,1,18.85,6.21l8.13,6a31.76,31.76,0,0,0,18.85,6.21h16.12a31.75,31.75,0,0,1,21,7.93l6.37,5.62a31.72,31.72,0,0,0,21,7.93h20.81v6.14H105.21" fill="#e6ebef" />
      <Polygon fill="#bac2ca" points="221.57 116.53 219.28 116.53 219.28 107.66 221.57 108.36 221.57 116.53" />
      <Path d="M254.39,111.09H233.58a32,32,0,0,1-21.17-8L206,97.46a31.49,31.49,0,0,0-20.8-7.86H169.12a32.22,32.22,0,0,1-19-6.26l-8.13-6a31.62,31.62,0,0,0-18.68-6.15h-17a.29.29,0,0,1-.29-.29.28.28,0,0,1,.29-.28h17a32.2,32.2,0,0,1,19,6.26l8.13,6A31.64,31.64,0,0,0,169.12,89h16.12a32,32,0,0,1,21.17,8l6.38,5.62a31.39,31.39,0,0,0,20.79,7.86h20.81a.29.29,0,0,1,0,.58Z" />
      <Path d="M249.92,78.22h-2.15a.23.23,0,0,1-.23-.23V71.86a.23.23,0,0,1,.23-.23h2.15a.23.23,0,0,1,.23.23V78A.23.23,0,0,1,249.92,78.22ZM248,77.76h1.69V72.09H248Z" />
      <Polygon fill="#fff" points="253.31 76.28 263.13 87.43 263.13 116.52 241.77 116.52 242.5 82.42 253.31 76.28" />
      <Polyline fill="#e6ebef" points="221.59 116.42 221.59 87.33 243.19 87.33 243.19 116.42" />
      <Rect fill="#bac2ca" height="5.22" width="4.13" x="225.18" y="91.01" />
      <Polygon fill="#bac2ca" points="243.01 87.44 221.4 87.44 231.71 76.28 253.31 76.28 243.01 87.44" />
      <Path d="M243,87.72H221.4a.26.26,0,0,1-.26-.17.27.27,0,0,1,.05-.31l10.3-11.15a.29.29,0,0,1,.21-.09h21.61a.28.28,0,0,1,.27.17.3.3,0,0,1-.06.31l-10.3,11.15A.29.29,0,0,1,243,87.72Zm-21-.57h20.83l9.78-10.58H231.83Z" />
      <Path d="M263.1,116.9H221.59a.29.29,0,0,1-.29-.29V87.81a.29.29,0,0,1,.58,0v28.52h40.94V87.55l-9.7-11.08a.29.29,0,0,1,.43-.38l9.77,11.16a.29.29,0,0,1,.07.18v29.18A.29.29,0,0,1,263.1,116.9Z" />
      <Path d="M243.26,116.61a.18.18,0,0,1-.19-.19V98.26a.19.19,0,1,1,.38,0v18.16A.18.18,0,0,1,243.26,116.61Z" />
      <Path d="M243.26,95.83a.18.18,0,0,1-.19-.19V87.86a.19.19,0,0,1,.38,0v7.78A.18.18,0,0,1,243.26,95.83Z" />
      <Path d="M229.55,96.5h-4.5a.21.21,0,0,1-.21-.21V91a.21.21,0,0,1,.21-.21h4.5a.21.21,0,0,1,.21.21v5.34A.21.21,0,0,1,229.55,96.5Zm-4.29-.42h4.08V91.16h-4.08Z" />
      <Rect fill="#bac2ca" height="5.22" width="4.13" x="234.09" y="91.01" />
      <Path d="M238.46,96.5H234a.21.21,0,0,1-.21-.21V91a.21.21,0,0,1,.21-.21h4.5a.21.21,0,0,1,.21.21v5.34A.21.21,0,0,1,238.46,96.5Zm-4.29-.42h4.08V91.16h-4.08Z" />
      <Rect fill="#bac2ca" height="5.22" width="4.13" x="225.18" y="101.13" />
      <Path d="M229.55,106.62h-4.5a.21.21,0,0,1-.21-.21v-5.34a.21.21,0,0,1,.21-.21h4.5a.22.22,0,0,1,.21.21v5.34A.21.21,0,0,1,229.55,106.62Zm-4.29-.42h4.08v-4.92h-4.08Z" />
      <Rect fill="#bac2ca" height="5.22" width="4.13" x="234.09" y="101.13" />
      <Path d="M238.46,106.62H234a.21.21,0,0,1-.21-.21v-5.34a.21.21,0,0,1,.21-.21h4.5a.21.21,0,0,1,.21.21v5.34A.21.21,0,0,1,238.46,106.62Zm-4.29-.42h4.08v-4.92h-4.08Z" />
      <Polygon fill="#fff" points="119.11 65.25 128.92 74.2 128.92 116.52 107.57 116.52 108.29 69.19 119.11 65.25" />
      <Polyline fill="#e6ebef" points="80.03 116.42 80.03 74.1 108.99 74.1 108.99 116.42" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="84.77" y="78.84" />
      <Polygon fill="#bac2ca" points="108.8 74.2 79.84 74.2 90.14 65.25 119.11 65.25 108.8 74.2" />
      <Path d="M108.8,74.49h-29a.29.29,0,0,1-.27-.19.3.3,0,0,1,.08-.32L90,65a.24.24,0,0,1,.18-.07h29a.28.28,0,0,1,.18.5l-10.3,9A.32.32,0,0,1,108.8,74.49Zm-28.19-.58h28.08l9.65-8.37H90.25Z" />
      <Path d="M128.89,116.9H80a.29.29,0,0,1-.29-.29v-42a.29.29,0,0,1,.58,0v41.76h48.29v-42l-9.68-8.87a.29.29,0,0,1,.39-.42L129.08,74a.29.29,0,0,1,.09.2v42.42A.29.29,0,0,1,128.89,116.9Z" />
      <Path d="M109.05,116.61a.19.19,0,0,1-.19-.19V99.33a.2.2,0,0,1,.19-.19.19.19,0,0,1,.19.19v17.09A.18.18,0,0,1,109.05,116.61Z" />
      <Path d="M109.05,97a.2.2,0,0,1-.19-.19V74.62a.2.2,0,0,1,.19-.19.19.19,0,0,1,.19.19V96.79A.19.19,0,0,1,109.05,97Z" />
      <Path d="M127.65,120.06a7.46,7.46,0,0,0-13.53-6.25,1.26,1.26,0,0,1-1.69.51,5.41,5.41,0,0,0-2.41-.56,5.52,5.52,0,0,0-5.52,5.52,5.14,5.14,0,0,0,.07.78Z" fill="#d9e1e8" />
      <Polygon fill="#bac2ca" points="123.15 119.99 121.24 119.99 121.24 109.97 123.15 110.38 123.15 119.99" />
      <Path d="M127.65,120.25H104.57a.19.19,0,0,1-.19-.17,4.81,4.81,0,0,1-.07-.8,5.71,5.71,0,0,1,8.18-5.15,1.11,1.11,0,0,0,1.48-.44,7.64,7.64,0,0,1,6.76-4.06h0a7.64,7.64,0,0,1,7,10.5A.2.2,0,0,1,127.65,120.25Zm-22.92-.39h22.79a7.2,7.2,0,0,0,.46-3.15,7.31,7.31,0,0,0-7.2-6.69h0a7.25,7.25,0,0,0-6.42,3.85,1.51,1.51,0,0,1-2,.61A5.21,5.21,0,0,0,110,114a5.33,5.33,0,0,0-5.32,5.33C104.7,119.46,104.71,119.65,104.73,119.86Z" />
      <Path d="M88.53,83.6H84.65a.22.22,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21h3.88a.21.21,0,0,1,.21.21v4.6A.22.22,0,0,1,88.53,83.6Zm-3.67-.42h3.46V79H84.86Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="92.44" y="78.84" />
      <Path d="M96.2,83.6H92.33a.21.21,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21H96.2a.21.21,0,0,1,.21.21v4.6A.21.21,0,0,1,96.2,83.6Zm-3.66-.42H96V79H92.54Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="84.77" y="89.76" />
      <Path d="M88.53,94.52H84.65a.22.22,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21h3.88a.21.21,0,0,1,.21.21v4.6A.22.22,0,0,1,88.53,94.52Zm-3.67-.42h3.46V89.92H84.86Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="92.44" y="89.76" />
      <Path d="M96.2,94.52H92.33a.21.21,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21H96.2a.21.21,0,0,1,.21.21v4.6A.21.21,0,0,1,96.2,94.52Zm-3.66-.42H96V89.92H92.54Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="100.1" y="78.84" />
      <Path d="M103.86,83.6H100a.21.21,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21h3.87a.21.21,0,0,1,.21.21v4.6A.21.21,0,0,1,103.86,83.6Zm-3.66-.42h3.45V79H100.2Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="100.1" y="89.76" />
      <Path d="M103.86,94.52H100a.21.21,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21h3.87a.21.21,0,0,1,.21.21v4.6A.21.21,0,0,1,103.86,94.52Zm-3.66-.42h3.45V89.92H100.2Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="84.77" y="100.31" />
      <Path d="M88.53,105.07H84.65a.22.22,0,0,1-.21-.21v-4.6a.22.22,0,0,1,.21-.21h3.88a.22.22,0,0,1,.21.21v4.6A.22.22,0,0,1,88.53,105.07Zm-3.67-.42h3.46v-4.18H84.86Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="92.44" y="100.31" />
      <Path d="M96.2,105.07H92.33a.21.21,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21H96.2a.21.21,0,0,1,.21.21v4.6A.21.21,0,0,1,96.2,105.07Zm-3.66-.42H96v-4.18H92.54Z" />
      <Rect fill="#bac2ca" height="4.5" width="3.55" x="100.1" y="100.31" />
      <Path d="M103.86,105.07H100a.21.21,0,0,1-.21-.21v-4.6a.21.21,0,0,1,.21-.21h3.87a.21.21,0,0,1,.21.21v4.6A.21.21,0,0,1,103.86,105.07Zm-3.66-.42h3.45v-4.18H100.2Z" />
      <Polygon fill="#fff" points="292.94 88.64 302.75 99.78 302.75 116.51 281.4 116.51 282.12 94.78 292.94 88.64" />
      <Polyline fill="#e6ebef" points="261.21 116.41 261.21 99.69 282.82 99.69 282.82 116.41" />
      <Rect fill="#bac2ca" height="5.22" width="4.13" x="264.81" y="103.36" />
      <Polygon fill="#bac2ca" points="282.63 99.79 261.02 99.79 271.33 88.64 292.94 88.64 282.63 99.79" />
      <Path d="M282.63,100.07H261a.28.28,0,0,1-.26-.17.32.32,0,0,1,.05-.31l10.31-11.15a.27.27,0,0,1,.21-.09h6.78a.29.29,0,0,1,0,.57h-6.65L261.68,99.5h20.83l9.77-10.58H280.35a.29.29,0,1,1,0-.57h12.59a.29.29,0,0,1,.26.17.27.27,0,0,1-.05.31L282.84,100A.27.27,0,0,1,282.63,100.07Z" />
      <Path d="M293,104.93h.24a2.46,2.46,0,0,1,2.46,2.46v9a0,0,0,0,1,0,0h-5.16a0,0,0,0,1,0,0v-9A2.46,2.46,0,0,1,293,104.93Z" fill="#bac2ca" />
      <Path d="M295.71,116.6a.21.21,0,0,1-.21-.21v-8.88a2.37,2.37,0,1,0-4.73,0v8.88a.21.21,0,0,1-.21.21.21.21,0,0,1-.21-.21v-8.88a2.79,2.79,0,1,1,5.57,0v8.88A.21.21,0,0,1,295.71,116.6Z" />
      <Path d="M302.73,116.89H261.21a.29.29,0,0,1-.28-.29V100.16a.29.29,0,1,1,.57,0v16.15h40.94V99.9l-9.7-11.08a.28.28,0,0,1,0-.4.29.29,0,0,1,.41,0l9.76,11.15a.24.24,0,0,1,.07.18V116.6A.28.28,0,0,1,302.73,116.89Z" />
      <Path d="M282.88,116.6a.2.2,0,0,1-.19-.19V102.15a.19.19,0,0,1,.19-.19.19.19,0,0,1,.2.19v14.26A.2.2,0,0,1,282.88,116.6Z" />
      <Path d="M269.17,108.85h-4.5a.21.21,0,0,1-.21-.21V103.3a.21.21,0,0,1,.21-.21h4.5a.21.21,0,0,1,.21.21v5.34A.21.21,0,0,1,269.17,108.85Zm-4.29-.42H269v-4.92h-4.08Z" />
      <Rect fill="#bac2ca" height="5.22" width="4.13" x="273.72" y="103.36" />
      <Path d="M278.09,108.85h-4.51a.21.21,0,0,1-.21-.21V103.3a.22.22,0,0,1,.21-.21h4.51a.22.22,0,0,1,.21.21v5.34A.21.21,0,0,1,278.09,108.85Zm-4.29-.42h4.08v-4.92H273.8Z" />
      <Polygon fill="#e6ebef" points="24.3 88.64 14.49 99.78 14.49 116.51 35.84 116.51 35.12 94.78 24.3 88.64" />
      <Polyline fill="#fff" points="56.03 116.41 56.03 99.69 34.42 99.69 34.42 116.41" />
      <Rect fill="#bac2ca" height="5.22" transform="translate(100.74 211.94) rotate(180)" width="4.13" x="48.31" y="103.36" />
      <Polygon fill="#bac2ca" points="34.61 99.79 56.22 99.79 45.91 88.64 24.3 88.64 34.61 99.79" />
      <Path d="M56.22,100.07H34.61a.27.27,0,0,1-.21-.09L24.09,88.83a.27.27,0,0,1-.05-.31.3.3,0,0,1,.26-.17H36.89a.29.29,0,1,1,0,.57H25L34.73,99.5H55.56L45.79,88.92H39.13a.29.29,0,0,1,0-.57h6.78a.27.27,0,0,1,.21.09L56.43,99.59a.28.28,0,0,1,0,.31A.27.27,0,0,1,56.22,100.07Z" />
      <Rect fill="#bac2ca" height="11.46" transform="translate(48.21 221.32) rotate(180)" width="5.16" x="21.53" y="104.93" />
      <Path d="M26.69,116.6a.21.21,0,0,1-.21-.21V105.14H21.74v11.25a.21.21,0,1,1-.42,0V104.93a.21.21,0,0,1,.21-.21h5.16a.21.21,0,0,1,.21.21v11.46A.21.21,0,0,1,26.69,116.6Z" />
      <Path d="M56,116.89H14.51a.29.29,0,0,1-.28-.29V99.78a.29.29,0,0,1,.07-.18l9.77-11.15a.28.28,0,0,1,.4,0,.28.28,0,0,1,0,.4L14.81,99.9v16.41H55.74V100.16a.29.29,0,0,1,.29-.29.28.28,0,0,1,.28.29V116.6A.28.28,0,0,1,56,116.89Z" />
      <Path d="M34.36,114.63a.18.18,0,0,1-.19-.19V100.21a.18.18,0,0,1,.19-.19.19.19,0,0,1,.19.19v14.23A.19.19,0,0,1,34.36,114.63Z" />
      <Path d="M52.57,108.85h-4.5a.21.21,0,0,1-.21-.21V103.3a.21.21,0,0,1,.21-.21h4.5a.21.21,0,0,1,.21.21v5.34A.21.21,0,0,1,52.57,108.85Zm-4.29-.42h4.08v-4.92H48.28Z" />
      <Rect fill="#bac2ca" height="5.22" transform="translate(82.92 211.94) rotate(180)" width="4.13" x="39.39" y="103.36" />
      <Path d="M43.66,108.85H39.15a.21.21,0,0,1-.21-.21V103.3a.22.22,0,0,1,.21-.21h4.51a.22.22,0,0,1,.21.21v5.34A.21.21,0,0,1,43.66,108.85Zm-4.29-.42h4.08v-4.92H39.37Z" />
      <Polygon fill="#e6ebef" points="133.49 93.97 123.08 105.8 123.08 123.54 145.73 123.54 144.97 100.49 133.49 93.97" />
      <Polyline fill="#fff" points="177.35 123.43 177.35 105.69 166.38 105.69 161.46 101.01 156.54 105.69 144.22 105.69 144.22 123.43" />
      <Rect fill="#bac2ca" height="5.54" transform="translate(341.97 228.18) rotate(180)" width="4.38" x="168.79" y="111.32" />
      <Polygon fill="#bac2ca" points="144.42 105.8 156.64 105.8 161.51 101.17 166.4 105.8 177.44 105.8 166.51 93.97 133.49 93.97 144.42 105.8" />
      <Path d="M177.73,106.09H166.48a.32.32,0,0,1-.2-.08l-4.79-4.47L156.82,106a.32.32,0,0,1-.2.08h-12.2a.29.29,0,0,1-.21-.09L133.28,94.17a.28.28,0,0,1-.05-.31.28.28,0,0,1,.26-.18h13.36a.29.29,0,0,1,0,.58h-12.7l10.4,11.26H156.5l4.79-4.58a.28.28,0,0,1,.39,0l4.91,4.59h10.49L166.67,94.26H149.22a.29.29,0,0,1,0-.58H166.8a.28.28,0,0,1,.21.1l10.93,11.83a.29.29,0,0,1,.06.31A.3.3,0,0,1,177.73,106.09Z" />
      <Rect fill="#bac2ca" height="12.16" transform="translate(322.48 234.68) rotate(180)" width="5.47" x="158.51" y="111.26" />
      <Path d="M164,123.63a.21.21,0,0,1-.21-.21v-12h-5.05v12a.22.22,0,0,1-.21.21.21.21,0,0,1-.21-.21V111.26a.21.21,0,0,1,.21-.21H164a.21.21,0,0,1,.21.21v12.16A.22.22,0,0,1,164,123.63Z" />
      <Path d="M177.53,123.93H123.11a.29.29,0,0,1-.29-.29V105.8a.32.32,0,0,1,.07-.19l10.36-11.83a.29.29,0,0,1,.41,0,.27.27,0,0,1,0,.4L123.4,105.91v17.44h53.85V106.2a.29.29,0,0,1,.58,0v17.44A.29.29,0,0,1,177.53,123.93Z" />
      <Path d="M144.16,123.63a.2.2,0,0,1-.2-.19V106.25a.2.2,0,0,1,.2-.19.2.2,0,0,1,.19.19v17.19A.2.2,0,0,1,144.16,123.63Z" />
      <Path d="M173.37,117.13h-4.78a.21.21,0,0,1-.21-.21v-5.66a.21.21,0,0,1,.21-.21h4.78a.21.21,0,0,1,.21.21v5.66A.21.21,0,0,1,173.37,117.13Zm-4.57-.42h4.36v-5.24H168.8Z" />
      <Rect fill="#bac2ca" height="5.54" transform="translate(303.38 228.18) rotate(180)" width="4.38" x="149.5" y="111.32" />
      <Path d="M154,117.13h-4.77a.21.21,0,0,1-.21-.21v-5.66a.21.21,0,0,1,.21-.21H154a.21.21,0,0,1,.21.21v5.66A.21.21,0,0,1,154,117.13Zm-4.56-.42h4.35v-5.24h-4.35Z" />
      <Rect fill="#e6ebef" height="17.57" rx="4.04" width="8.49" x="248.63" y="93" />
      <Path d="M254.85,93.49a4.18,4.18,0,0,1,.81,2.48v9.09a4.24,4.24,0,0,1-4.25,4.24h0a4.27,4.27,0,0,1-2-.49,4.24,4.24,0,0,0,7.68-2.48V97.24A4.22,4.22,0,0,0,254.85,93.49Z" fill="#bac2ca" />
      <Path d="M252.87,110.86a4.53,4.53,0,0,1-4.53-4.53V97.24a4.53,4.53,0,1,1,9.06,0v9.09A4.53,4.53,0,0,1,252.87,110.86Zm0-17.58a4,4,0,0,0-4,4v9.09a4,4,0,0,0,7.92,0V97.24A4,4,0,0,0,252.87,93.28Z" />
      <Path d="M252.81,116.83a.29.29,0,0,1-.29-.29V100.76a.29.29,0,0,1,.58,0v15.78A.29.29,0,0,1,252.81,116.83Z" />
      <Path d="M252.76,106.38a.26.26,0,0,1-.17,0l-2.1-1.49a.28.28,0,0,1-.06-.4.29.29,0,0,1,.4-.07l2.09,1.49a.29.29,0,0,1,.07.4A.28.28,0,0,1,252.76,106.38Z" />
      <Rect fill="#e6ebef" height="17.57" rx="4.04" width="8.49" x="2.16" y="93" />
      <Path d="M8.39,93.49A4.24,4.24,0,0,1,9.19,96v9.09A4.24,4.24,0,0,1,5,109.3H5a4.24,4.24,0,0,1-2-.49,4.24,4.24,0,0,0,7.68-2.48V97.24A4.24,4.24,0,0,0,8.39,93.49Z" fill="#bac2ca" />
      <Path d="M6.41,110.86a4.53,4.53,0,0,1-4.53-4.53V97.24a4.53,4.53,0,0,1,9.06,0v9.09A4.53,4.53,0,0,1,6.41,110.86Zm0-17.58a4,4,0,0,0-4,4v9.09a4,4,0,0,0,7.91,0V97.24A4,4,0,0,0,6.41,93.28Z" />
      <Path d="M6.35,116.83a.29.29,0,0,1-.29-.29V100.76a.29.29,0,0,1,.57,0v15.78A.29.29,0,0,1,6.35,116.83Z" />
      <Path d="M6.29,106.38a.25.25,0,0,1-.16,0L4,104.84a.29.29,0,1,1,.33-.47l2.1,1.49a.29.29,0,0,1,.07.4A.3.3,0,0,1,6.29,106.38Z" />
      <Rect fill="#e6ebef" height="17.57" rx="4.04" width="8.49" x="181.87" y="100.08" />
      <Path d="M188.09,100.57a4.2,4.2,0,0,1,.81,2.48v9.09a4.24,4.24,0,0,1-4.24,4.24h0a4.24,4.24,0,0,1-2-.49,4.24,4.24,0,0,0,7.68-2.48v-9.09A4.24,4.24,0,0,0,188.09,100.57Z" fill="#bac2ca" />
      <Path d="M186.11,117.94a4.53,4.53,0,0,1-4.53-4.53v-9.09a4.53,4.53,0,1,1,9.06,0v9.09A4.53,4.53,0,0,1,186.11,117.94Zm0-17.57a4,4,0,0,0-4,3.95v9.09a4,4,0,1,0,7.91,0v-9.09A4,4,0,0,0,186.11,100.37Z" />
      <Path d="M186.05,123.91a.28.28,0,0,1-.28-.29V107.85a.28.28,0,0,1,.28-.29.29.29,0,0,1,.29.29v15.77A.29.29,0,0,1,186.05,123.91Z" />
      <Path d="M186,113.47a.27.27,0,0,1-.17-.06l-2.09-1.49a.29.29,0,0,1,.33-.47l2.1,1.5a.28.28,0,0,1,.06.4A.29.29,0,0,1,186,113.47Z" />
      <Path d="M320.22,100.06h0c-2.69-16.2-6.45-15.47-6.45-15.47v0s-3.61-.74-6.3,15.46h0a9.49,9.49,0,0,0-.12,1.53c0,4.37,2.9,7.91,6.49,7.91s6.48-3.54,6.48-7.91A9.49,9.49,0,0,0,320.22,100.06Z" fill="#e6ebef" />
      <Path d="M320.34,101.59a9.49,9.49,0,0,0-.12-1.53h0c-2.69-16.2-6.45-15.47-6.45-15.47v0s.08-.11-.31.08a52.13,52.13,0,0,1,4.36,15.73c.51,5.75-1.58,8.41-4,9.09h.06C317.44,109.5,320.34,106,320.34,101.59Z" fill="#bac2ca" />
      <Path d="M313.86,109.79c-3.74,0-6.78-3.68-6.78-8.2a10.78,10.78,0,0,1,.11-1.49V100A72.19,72.19,0,0,1,308.94,92a.28.28,0,0,1,.35-.19.29.29,0,0,1,.2.35,72.22,72.22,0,0,0-1.72,7.9.11.11,0,0,1,0,.08,9.45,9.45,0,0,0-.12,1.49c0,4.2,2.79,7.62,6.21,7.62s6.2-3.42,6.2-7.62a9.45,9.45,0,0,0-.12-1.49.43.43,0,0,1,0,0c-2.36-14.25-5.54-15.13-6-15.18a.2.2,0,0,1-.11,0h-.12c-.28,0-1.67.35-3.27,4.52a.29.29,0,0,1-.54-.21c1.77-4.6,3.42-4.91,3.87-4.89h0a1.64,1.64,0,0,1,1.2.39c1.57,1.09,3.81,4.64,5.58,15.31v0a9.86,9.86,0,0,1,.12,1.53C320.63,106.11,317.59,109.79,313.86,109.79Z" />
      <Path d="M313.77,116.73a.29.29,0,0,1-.28-.29v-17a.29.29,0,0,1,.57,0v17A.29.29,0,0,1,313.77,116.73Z" />
      <Path d="M313.7,105a.25.25,0,0,1-.16-.05l-2.37-1.68a.3.3,0,0,1-.07-.41.3.3,0,0,1,.4-.06l2.37,1.68a.29.29,0,0,1,.07.4A.3.3,0,0,1,313.7,105Z" />
      <Path d="M313.83,107a.28.28,0,0,1-.23-.12.29.29,0,0,1,.07-.4l2.37-1.68a.3.3,0,0,1,.4.06.29.29,0,0,1-.07.4L314,107A.26.26,0,0,1,313.83,107Z" />
      <Path d="M202.07,100.06h0c2.68-16.2,6.44-15.47,6.44-15.47v0s3.62-.74,6.3,15.46h0a9.47,9.47,0,0,1,.13,1.53c0,4.37-2.91,7.91-6.49,7.91S202,106,202,101.59A9.49,9.49,0,0,1,202.07,100.06Z" fill="#e6ebef" />
      <Path d="M202,101.59a9.49,9.49,0,0,1,.12-1.53h0c2.68-16.2,6.44-15.47,6.44-15.47v0s-.07-.11.32.08a51.77,51.77,0,0,0-4.37,15.73c-.5,5.75,1.59,8.41,4,9.09h-.05C204.85,109.5,202,106,202,101.59Z" fill="#bac2ca" />
      <Path d="M208.44,109.79c-3.74,0-6.78-3.68-6.78-8.2a9.86,9.86,0,0,1,.12-1.53v0c1.77-10.67,4-14.22,5.58-15.31a1.66,1.66,0,0,1,1.21-.39h0c.5,0,2.1.39,3.83,4.89l.91,2.75h0A71.88,71.88,0,0,1,215.1,100a.17.17,0,0,1,0,.1,9.22,9.22,0,0,1,.11,1.48C215.21,106.11,212.17,109.79,208.44,109.79Zm-6.08-9.74v0a9.47,9.47,0,0,0-.13,1.49c0,4.2,2.79,7.62,6.21,7.62s6.2-3.42,6.2-7.62a9.45,9.45,0,0,0-.12-1.49.14.14,0,0,1,0-.09,71.45,71.45,0,0,0-1.71-7.88l-.91-2.74c-1.59-4.14-3-4.48-3.27-4.5l-.11,0a.37.37,0,0,1-.15,0C207.82,85,204.7,86,202.36,100.05Z" />
      <Path d="M208.52,116.73a.29.29,0,0,1-.29-.29v-17a.29.29,0,0,1,.58,0v17A.29.29,0,0,1,208.52,116.73Z" />
      <Path d="M208.59,105a.27.27,0,0,1-.23-.12.28.28,0,0,1,.06-.4l2.37-1.68a.3.3,0,0,1,.4.06.3.3,0,0,1-.07.41L208.76,105A.28.28,0,0,1,208.59,105Z" />
      <Path d="M208.46,107a.26.26,0,0,1-.17,0l-2.37-1.69a.29.29,0,0,1-.06-.4.3.3,0,0,1,.4-.06l2.37,1.68a.3.3,0,0,1,.06.4A.27.27,0,0,1,208.46,107Z" />
      <Path d="M324.84,116.89H321.4a.29.29,0,0,1,0-.58h3.44a.29.29,0,0,1,0,.58Z" />
      <Path d="M319.3,116.89H302.73a.29.29,0,0,1,0-.58H319.3a.29.29,0,1,1,0,.58Z" />
      <Path d="M221.59,116.92h-24a.29.29,0,1,1,0-.57h24a.29.29,0,1,1,0,.57Z" />
      <Path d="M14.51,116.89h-11a.29.29,0,0,1,0-.58h11a.29.29,0,0,1,0,.58Z" />
      <Path d="M80.37,116.89h-11a.29.29,0,0,1,0-.58h11a.29.29,0,1,1,0,.58Z" />
      <Path d="M63.44,116.89h-11a.29.29,0,0,1,0-.58h11a.29.29,0,0,1,0,.58Z" />
      <Path d="M123.33,35.06a1.82,1.82,0,0,0,1.69-2.5c-1-2.54-3.19-6.15-7.31-6.58a7.14,7.14,0,0,0-4.61,1.35.28.28,0,0,1-.36,0A5.19,5.19,0,0,0,109.2,26c-2.64,0-4.78,1.81-4.78,4h0a.29.29,0,0,1-.32.3l-.39,0a3.67,3.67,0,0,0-3.48,2.11.29.29,0,0,1-.39.16,3.26,3.26,0,0,0-1.44-.29A3,3,0,0,0,96,33.63a.91.91,0,0,0,.73,1.43Z" fill="#d9e1e8" />
      <Path d="M59.11,62a.85.85,0,0,0,.64-1.4A5.67,5.67,0,0,0,55.52,59a6.17,6.17,0,0,0-.87.05.53.53,0,0,1-.58-.53h0c0-1.6-2-2.9-4.53-2.9a6.64,6.64,0,0,0-1.94.28.5.5,0,0,1-.64-.36c-.52-2.38-3.07-4.18-6.14-4.18-3.46,0-6.26,2.28-6.26,5.09,0,.1,0,.2,0,.3a3.13,3.13,0,0,0-.44,0,2.91,2.91,0,0,0-2.76,2,.51.51,0,0,1-.62.34A2.56,2.56,0,0,0,30,59,2.88,2.88,0,0,0,27.7,60,1.19,1.19,0,0,0,28.64,62Z" fill="#d9e1e8" />
      <Path d="M47.75,29.59a.55.55,0,0,1-.42-.91,3.7,3.7,0,0,1,2.75-1,5.44,5.44,0,0,1,.57,0,.33.33,0,0,0,.37-.34h0c0-1,1.32-1.88,2.94-1.88a4.64,4.64,0,0,1,1.27.18.32.32,0,0,0,.41-.23,3.92,3.92,0,0,1,4-2.72c2.25,0,4.07,1.48,4.07,3.31,0,.06,0,.13,0,.19l.28,0a1.9,1.9,0,0,1,1.8,1.3.32.32,0,0,0,.4.22,1.7,1.7,0,0,1,.51-.07,1.9,1.9,0,0,1,1.48.71.77.77,0,0,1-.61,1.25Z" fill="#d9e1e8" />
      <Path d="M285.62,63.47a.85.85,0,0,0,.65-1.4A5.67,5.67,0,0,0,282,60.51a8.49,8.49,0,0,0-.88.05.52.52,0,0,1-.58-.53h0c0-1.61-2-2.9-4.52-2.9a6.7,6.7,0,0,0-1.95.28.5.5,0,0,1-.63-.36c-.53-2.38-3.07-4.19-6.15-4.19-3.45,0-6.25,2.28-6.25,5.09,0,.1,0,.2,0,.3a3.13,3.13,0,0,0-.44,0,2.9,2.9,0,0,0-2.76,2,.51.51,0,0,1-.62.34,3,3,0,0,0-.79-.11,2.89,2.89,0,0,0-2.27,1.1,1.18,1.18,0,0,0,.93,1.92Z" fill="#d9e1e8" />
      <Path d="M193.56,123.93h-16a.29.29,0,0,1,0-.58h16a.29.29,0,1,1,0,.58Z" />
      <Path d="M279.91,125.82h-2a.29.29,0,0,1,0-.58h2a.29.29,0,1,1,0,.58Z" />
      <Path d="M209.35,129h-2a.28.28,0,0,1-.28-.29.27.27,0,0,1,.28-.28h2a.28.28,0,0,1,.29.28A.29.29,0,0,1,209.35,129Z" />
      <Path d="M102.47,128.82h-2a.29.29,0,1,1,0-.57h2a.29.29,0,1,1,0,.57Z" />
      <Path d="M123.11,123.93H117.6a.29.29,0,0,1,0-.58h5.51a.29.29,0,0,1,0,.58Z" />
      <Path d="M207.56,33a6,6,0,1,1,6-6A6,6,0,0,1,207.56,33Zm0-11.57A5.56,5.56,0,1,0,213.11,27,5.57,5.57,0,0,0,207.56,21.42Z" />
      <Rect fill="#e6ebef" height="17.57" rx="4.04" width="8.49" x="66.65" y="105.08" />
      <Path d="M72.87,105.57a4.18,4.18,0,0,1,.81,2.48v9.09a4.24,4.24,0,0,1-4.24,4.24h0a4.24,4.24,0,0,1-2-.49,4.24,4.24,0,0,0,7.68-2.48v-9.09A4.23,4.23,0,0,0,72.87,105.57Z" fill="#bac2ca" />
      <Path d="M70.89,122.94a4.53,4.53,0,0,1-4.53-4.53v-9.09a4.53,4.53,0,0,1,9.06,0v9.09A4.53,4.53,0,0,1,70.89,122.94Zm0-17.58a4,4,0,0,0-4,4v9.09a4,4,0,0,0,7.91,0v-9.09A4,4,0,0,0,70.89,105.36Z" />
      <Path d="M70.83,128.91a.29.29,0,0,1-.29-.29V112.84a.29.29,0,0,1,.58,0v15.78A.29.29,0,0,1,70.83,128.91Z" />
      <Path d="M70.78,118.46a.26.26,0,0,1-.17,0l-2.09-1.49a.29.29,0,1,1,.33-.47l2.1,1.49a.3.3,0,0,1,.06.4A.27.27,0,0,1,70.78,118.46Z" />
      <Rect fill="#e6ebef" height="12.22" rx="4.04" transform="translate(172.9 233.08) rotate(180)" width="8.49" x="82.21" y="110.43" />
      <Path d="M84.47,110.92a4.2,4.2,0,0,0-.81,2.48v3.74a4.24,4.24,0,0,0,4.25,4.24h0a4.27,4.27,0,0,0,2-.49,4.24,4.24,0,0,1-7.68-2.48v-3.74A4.23,4.23,0,0,1,84.47,110.92Z" fill="#bac2ca" />
      <Path d="M86.45,122.94a4.53,4.53,0,0,1-4.53-4.53v-3.74a4.53,4.53,0,0,1,9.06,0v3.74A4.53,4.53,0,0,1,86.45,122.94Zm0-12.22a4,4,0,0,0-4,4v3.74a4,4,0,0,0,7.92,0v-3.74A4,4,0,0,0,86.45,110.72Z" />
      <Path d="M86.51,128.91a.29.29,0,0,1-.29-.29V114.56a.29.29,0,0,1,.58,0v14.06A.29.29,0,0,1,86.51,128.91Z" />
      <Path d="M86.56,118.46a.28.28,0,0,1-.23-.12.29.29,0,0,1,.07-.4l2.09-1.49a.29.29,0,0,1,.4.07.28.28,0,0,1-.06.4l-2.1,1.49A.26.26,0,0,1,86.56,118.46Z" />
      <Path d="M97.43,128.89H60.73a.29.29,0,0,1,0-.58h36.7a.29.29,0,0,1,0,.58Z" />
      <Path d="M57.57,128.84H51.93a.28.28,0,0,1-.28-.29.27.27,0,0,1,.28-.28h5.64a.27.27,0,0,1,.28.28A.28.28,0,0,1,57.57,128.84Z" />
      <Path d="M241.45,125.58c-.48-1.18,1.66-4.65,2.21-5.53,2.36-3.72,4.43-6.25,8.83-6.28a8.64,8.64,0,0,1,7.69,4.6,1.45,1.45,0,0,0,1.95.59,6.37,6.37,0,0,1,9.15,5.73,6.54,6.54,0,0,1-.07.89Z" fill="#d9e1e8" />
      <Path d="M247.77,115.39s5,4.56,6.83,9.9H252s-2.87-5.74-6-8.16Z" fill="#bac2ca" />
      <Path d="M271.21,125.83H241.42a.24.24,0,0,1-.25-.23c-.07-1.22,1.6-4.45,2-5.18,2-3.57,4.55-6.87,9.3-6.9h.06a8.87,8.87,0,0,1,7.83,4.7,1.26,1.26,0,0,0,1.68.5,6.5,6.5,0,0,1,2.85-.65,6.59,6.59,0,0,1,6.55,7.55A.25.25,0,0,1,271.21,125.83Zm-29.54-.49H271a5.27,5.27,0,0,0,.05-.65,6.13,6.13,0,0,0-8.77-5.53,1.75,1.75,0,0,1-2.33-.71,8.35,8.35,0,0,0-7.39-4.43h-.06c-4.49,0-6.92,3.21-8.87,6.64A18.57,18.57,0,0,0,241.67,125.34Z" />
      <Path d="M276.12,125.82H252a.29.29,0,0,1,0-.58h24.1a.29.29,0,1,1,0,.58Z" />
      <Path d="M252.87,128.77c.55-1.37-1.93-5.4-2.57-6.43-2.74-4.32-5.14-7.26-10.26-7.29a10,10,0,0,0-8.93,5.34,1.69,1.69,0,0,1-2.27.68,7.4,7.4,0,0,0-10.63,6.66,7.07,7.07,0,0,0,.08,1Z" fill="#d9e1e8" />
      <Path d="M252.9,129H218.29a.25.25,0,0,1-.25-.21,7.23,7.23,0,0,1-.08-1.08,7.66,7.66,0,0,1,7.65-7.65,7.55,7.55,0,0,1,3.3.75,1.5,1.5,0,0,0,2-.59A10.24,10.24,0,0,1,240,114.8H240c5.5,0,8.43,3.86,10.77,8,.49.85,2.42,4.59,2.34,6A.24.24,0,0,1,252.9,129Zm-34.39-.49h34.14a21.18,21.18,0,0,0-2.27-5.49c-2.27-4-5.1-7.71-10.34-7.74H240a9.72,9.72,0,0,0-8.62,5.17,2,2,0,0,1-2.66.81,7.09,7.09,0,0,0-3.08-.7,7.16,7.16,0,0,0-7.16,7.15A5.81,5.81,0,0,0,218.51,128.53Z" />
      <Path d="M100.55,65.5a.23.23,0,0,1-.23-.23V54.6a.23.23,0,1,1,.46,0V65.27A.23.23,0,0,1,100.55,65.5Z" />
      <Path d="M97.26,65.5a.23.23,0,0,1-.23-.23V59.79a.23.23,0,1,1,.46,0v5.48A.23.23,0,0,1,97.26,65.5Z" />
      <Path d="M255.43,129H211.67a.28.28,0,0,1-.29-.28.29.29,0,0,1,.29-.29h43.76a.28.28,0,0,1,.28.29A.27.27,0,0,1,255.43,129Z" />
    </Svg>
  );
}
