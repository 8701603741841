/* eslint-disable react/jsx-sort-props */
/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

const PaperclipIcon = (props) => {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0_34956_185022)' : '';
  return (
    <Svg width={moderateScale(18)} height={moderateScale(18)} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M12 4.5L12 13.125C12 14.7825 10.6575 16.125 9 16.125C7.3425 16.125 6 14.7825 6 13.125L6 3.75C6 2.715 6.84 1.875 7.875 1.875C8.91 1.875 9.75 2.715 9.75 3.75L9.75 11.625C9.75 12.0375 9.4125 12.375 9 12.375C8.5875 12.375 8.25 12.0375 8.25 11.625L8.25 4.5L7.125 4.5L7.125 11.625C7.125 12.66 7.965 13.5 9 13.5C10.035 13.5 10.875 12.66 10.875 11.625L10.875 3.75C10.875 2.0925 9.5325 0.75 7.875 0.75C6.2175 0.75 4.875 2.0925 4.875 3.75L4.875 13.125C4.875 15.405 6.72 17.25 9 17.25C11.28 17.25 13.125 15.405 13.125 13.125L13.125 4.5L12 4.5Z" fill="#310078" />
      </G>
      <Defs>
        <ClipPath id="clip0_34956_185022">
          <Rect width={moderateScale(18)} height={moderateScale(18)} fill="white" transform="translate(0 18) rotate(-90)" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default PaperclipIcon;
