import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import {
  H3,
  H4,
  LargePrimaryButton,
} from '../base_ui';

export const Card = styled.View`
  flex-grow: 1;
`;

export const CardTitle = styled(H3)`
  font-size: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  margin-bottom: ${moderateScale(10)}px;
`;

export const ContainerTitle = styled(H4)`
  align-content: center;
  height: ${moderateScale(51)}px;
  text-align: center;
`;

export const Content = styled.View`
  align-content: stretch;
  align-items: center;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin: ${moderateScale(8)}px ${moderateScale(24)}px 0;
`;

export const HeaderText = styled.Text`
  font-size: ${moderateScale(14)}px;
  line-height: ${moderateScale(22)}px;
  margin-bottom: ${moderateScale(14)}px;
`;

export const HoursText = styled.Text`
  margin-top: ${moderateScale(14)}px;
`;

export const Illustration = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(52)}px;
`;

export const StyledPhoneLinkText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
  line-height: ${moderateScale(22)}px;
`;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  margin-bottom: ${moderateScale(40)}px;
`;
