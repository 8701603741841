import React from 'react';

import { SSO_IDP_ERROR_MESSAGE, SSO_LOGIN_ERROR_MESSAGE } from '@dmi/shared/redux/Login/constants';
import { getQueryParam } from '@dmi/shared/utils/globalHelpers';

import AsyncRender from '../../components/AsyncRender';
import { StyledScrollView } from '../../components/base_ui';

const LoginSslErrorView = () => {
  const errorCode = getQueryParam('error', null) || '49';

  return (
    <StyledScrollView>
      <AsyncRender
        Component={() => null}
        error
        errorComponentProps={{
          body: errorCode === '49' ? SSO_IDP_ERROR_MESSAGE : SSO_LOGIN_ERROR_MESSAGE,
          link: {},
          title: "You've encountered an error.", // Matches same title as Desktop
        }}
        loading={false}
      />
    </StyledScrollView>
  );
};

export default LoginSslErrorView;
