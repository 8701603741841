import React, { Fragment } from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  SplashParagraphContainer,
  StyledH4,
  StyledP3,
} from '../styledComponents';

const AddEscrowText = ({ splashText }) => {
  const textBody = Object.keys(splashText).map((paragraph) => {
    const { secondaryHeader, text } = splashText[paragraph];
    return (
      <SplashParagraphContainer key={secondaryHeader}>
        <StyledH4>{secondaryHeader}</StyledH4>
        <StyledP3>
          <FormattedMessage id={text} />
        </StyledP3>
      </SplashParagraphContainer>
    );
  });

  return (
    <Fragment>
      {textBody}
    </Fragment>
  );
};

AddEscrowText.propTypes = { splashText: T.object.isRequired };

export default AddEscrowText;
