import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getMainClientInfo,
  getSelectedLoanAddress,
  getSelectedLoanMaskedLoanNumber,
} from '@dmi/shared/redux/Main/selectors';
import { updateAmount, updateFormError } from '@dmi/shared/redux/Payments/Autopay/actions';
import {
  selectAllowedSetting,
  selectFormErrors,
  selectSelectedAdditionalPrincipal,
  selectStatusDataProp,
} from '@dmi/shared/redux/Payments/Autopay/selectors';
import { selectOtpDueDate } from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import { EditAmount as EditAmountScreen } from '../../../components/Payments/Autopay';

const EditAmount = ({
  additionalPrincipal,
  address,
  customerServicePhoneNumber,
  dispatchUpdateAmount,
  dispatchUpdateFormError,
  dueDate,
  formErrors,
  maskedLoanNumber,
  maximumAdditionalPrincipal,
  navigation,
  regularMonthlyPayment,
}) => (
  <EditAmountScreen
    additionalPrincipal={additionalPrincipal}
    address={address}
    customerServicePhoneNumber={customerServicePhoneNumber}
    dispatchUpdateAmount={dispatchUpdateAmount}
    dispatchUpdateFormError={dispatchUpdateFormError}
    dueDate={dueDate}
    formErrors={formErrors}
    loanNumber={maskedLoanNumber}
    maximumAdditionalPrincipal={maximumAdditionalPrincipal}
    navigation={navigation}
    regularMonthlyPayment={regularMonthlyPayment}
  />
);

EditAmount.propTypes = {
  additionalPrincipal: T.number.isRequired,
  address: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchUpdateAmount: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  dueDate: T.string.isRequired,
  formErrors: T.object.isRequired,
  maskedLoanNumber: T.string.isRequired,
  maximumAdditionalPrincipal: T.number.isRequired,
  navigation: T.object.isRequired,
  regularMonthlyPayment: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  additionalPrincipal: selectSelectedAdditionalPrincipal(),
  formErrors: selectFormErrors('amount'),
  maximumAdditionalPrincipal: selectAllowedSetting('maximumAdditionalPrincipal'),
  regularMonthlyPayment: selectStatusDataProp('regularMonthlyPayment'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  address: getSelectedLoanAddress(),
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  maskedLoanNumber: getSelectedLoanMaskedLoanNumber(),
  /**
   * Store: One Time Payment
   */
  // eslint-disable-next-line sort-keys
  dueDate: selectOtpDueDate(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchUpdateAmount: (amount) => dispatch(updateAmount({ amount })),
  dispatchUpdateFormError: (payload) => dispatch(updateFormError(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditAmount);
