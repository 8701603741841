import ContactInformationScreen from '../../../containers/Help/ContactInformationScreen';
import DisasterAssistanceScreen from '../../../containers/Help/DisasterAssistanceScreen';
import FaqScreen from '../../../containers/Help/FaqScreen';
import GlossaryScreen from '../../../components/Help/Glossary';
import HardshipAssistanceScreen from '../../../containers/Help/HardshipAssistanceScreen';
import LearningCenterScreen from '../../../containers/Help/LearningCenterScreen';

export default {
  ContactUs: { component: ContactInformationScreen },
  DisasterAssistance: { component: DisasterAssistanceScreen },
  Faq: { component: FaqScreen },
  Glossary: { component: GlossaryScreen },
  HardshipAssistance: { component: HardshipAssistanceScreen },
  LearningCenter: { component: LearningCenterScreen },
};
