import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeStep, chooseDocument } from '@dmi/shared/redux/LossDraft/actions';
import { MESSAGES_ATTACHMENT_UPLOAD_DICTIONARY } from '@dmi/shared/redux/LossDraft/constants';
import { selectRequestStatus } from '@dmi/shared/redux/LossDraft/selectors';

import { MessagesAttachmentUpload } from '../../../components/MessagesAttachment';
import { AttachmentView } from '../styledComponents';

const NewMessageAttachmentUpload = ({
  dispatchChangeStep,
  dispatchChooseDocument,
  navigation,
  requestStatusProcess,
}) => {
  useEffect(() => {
    if (requestStatusProcess === 'succeeded') {
      dispatchChangeStep(4);
    }
  }, [dispatchChangeStep, requestStatusProcess]);

  return (
    <AttachmentView>
      <MessagesAttachmentUpload
        dictionary={MESSAGES_ATTACHMENT_UPLOAD_DICTIONARY}
        dispatchChooseDocument={dispatchChooseDocument}
        handleBack={() => dispatchChangeStep(1)}
        navigation={navigation}
        requestStatusProcess={requestStatusProcess}
        reviewScreenName="LossDraftNewMessage"
      />
    </AttachmentView>
  );
};

NewMessageAttachmentUpload.propTypes = {
  dispatchChangeStep: T.func.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  navigation: T.object.isRequired,
  requestStatusProcess: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: LossDraft
   */
  requestStatusProcess: selectRequestStatus('chooseDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: LossDraft
   */
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageAttachmentUpload);
