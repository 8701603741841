/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../scaleHelpers';

export default function DownloadActiveIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(16)} viewBox="0 0 16 16" width={moderateScale(16)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path clipRule="evenodd" d="M10.5603 8.34994C10.6205 8.36337 10.676 8.39243 10.7213 8.4342C10.7666 8.47596 10.8001 8.52895 10.8184 8.5878C10.8366 8.64665 10.839 8.70928 10.8253 8.76936C10.8116 8.82943 10.7823 8.88483 10.7403 8.92994L8.35033 11.3199C8.35033 11.3199 8.35033 11.3199 8.22033 11.3999C8.09033 11.4799 7.86033 11.3199 7.86033 11.3199L5.45033 8.90994C5.29033 8.70994 5.35033 8.65994 5.45033 8.54994C5.47468 8.50153 5.50994 8.45942 5.55334 8.42695C5.59673 8.39449 5.64708 8.37254 5.7004 8.36285C5.75372 8.35315 5.80857 8.35597 5.86062 8.37109C5.91267 8.3862 5.96049 8.4132 6.00033 8.44994L7.80033 10.2499V4.24994C7.80033 3.98994 7.91033 3.99994 8.03033 3.95994C8.08195 3.94282 8.13685 3.93796 8.19067 3.94575C8.2445 3.95354 8.29577 3.97376 8.34041 4.00482C8.38506 4.03588 8.42185 4.07691 8.44788 4.12467C8.4739 4.17243 8.48843 4.22559 8.49033 4.27994V10.2799L10.3203 8.44994C10.4003 8.32994 10.4003 8.32994 10.5603 8.34994Z" fill={primary} fillRule="evenodd" />
      <Path d="M8.08996 14.3801C11.6135 14.3801 14.47 11.5237 14.47 8.00012C14.47 4.47654 11.6135 1.62012 8.08996 1.62012C4.56638 1.62012 1.70996 4.47654 1.70996 8.00012C1.70996 11.5237 4.56638 14.3801 8.08996 14.3801Z" stroke={primary} strokeMiterlimit="10" strokeWidth="0.71" />
    </Svg>
  );
}
