import React from 'react';

import { getWelcomeMessage } from '../helpers';
import { H1, Skeleton } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';
import { ButtonWrapper, PaymentHomeContainer } from '../styledComponents';
import {
  HeaderContainer,
  LeftContainer,
  RightContainer,
  SkeletonContent,
} from './styledComponents';

const PaymentHomeMainSkeleton = () => {
  const welcomeMessage = getWelcomeMessage();

  return (
    <PaymentHomeContainer>
      <H1>{welcomeMessage}</H1>
      <HeaderContainer>
        <Skeleton
          $bottomMargin={`${moderateScale(11)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(9)}px`}
          height={`${moderateScale(10)}px`}
          width="100%"
        />
      </HeaderContainer>
      <SkeletonContent>
        <LeftContainer>
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(40)}px`}
            width="100%"
          />
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={`${moderateScale(9)}px`}
            height={`${moderateScale(14)}px`}
            width={`${moderateScale(156)}px`}
          />
        </LeftContainer>
        <RightContainer>
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            hasPulsateWidth
            height={`${moderateScale(70)}px`}
            width="100%"
          />
        </RightContainer>
      </SkeletonContent>
      <Skeleton
        $bottomMargin={0}
        $leftMargin={0}
        $rightMargin={0}
        $topMargin={`${moderateScale(32)}px`}
        height={`${moderateScale(45)}px`}
        width="100%"
      />
      <ButtonWrapper>
        <Skeleton
          $bottomMargin={`${moderateScale(40)}px`}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={`${moderateScale(32)}px`}
          height={`${moderateScale(45)}px`}
          width="100%"
        />
      </ButtonWrapper>
    </PaymentHomeContainer>
  );
};

export default PaymentHomeMainSkeleton;
