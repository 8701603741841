import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const ChartAndLegendContainer = styled.View`
  margin: auto;
  width: ${moderateScale(330)}px;
`;

export const ChartLegendRow = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-top: ${moderateScale(5)}px;
`;

export const ChartLegendWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${moderateScale(31)}px;
`;

export const LegendColor = styled.View`
  background-color: ${({ color }) => color};
  height: ${moderateScale(12)}px;
  margin-right: ${moderateScale(8)}px;
  opacity: ${({ opacity }) => opacity || '1'};
  width: ${moderateScale(12)}px;
`;

export const RowsGroup = styled.View`
  flex-direction: ${({ $showNewValues }) => $showNewValues ? 'column' : 'row'};
  justify-content: space-between;
  width: ${({ $showNewValues }) => $showNewValues ? 'auto' : '100%'};
`;

export const WebChartContainer = styled.View`
  height: ${moderateScale(293)}px;
`;
