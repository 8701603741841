import React from 'react';
import * as WebBrowser from 'expo-web-browser';

import AppleStore from '../../../assets/images/ios-store.png';
import GoogleStore from '../../../assets/images/google-store.png';
import {
  AppleButton,
  ContentWrapper,
  GoogleButton,
  StoreLinksContainer,
  StyledImage,
  Title,
} from './styledComponents';

const appleLink = 'https://apps.apple.com/us/app/your-mortgage-online/id1169497559';
const playLink = 'https://play.google.com/store/apps/details?id=com.dovenmuehle';

const StoreLinks = () => (
  <StoreLinksContainer>
    <Title>Download the app</Title>
    <ContentWrapper>
      <AppleButton onPress={() => WebBrowser.openBrowserAsync(appleLink)}>
        <StyledImage aria-label="apple ios store" source={AppleStore} />
      </AppleButton>
      <GoogleButton onPress={() => WebBrowser.openBrowserAsync(playLink)}>
        <StyledImage aria-label="google play store" source={GoogleStore} />
      </GoogleButton>
    </ContentWrapper>
  </StoreLinksContainer>
);

export default StoreLinks;
