import React, { Fragment } from 'react';

import FilterSkeleton from './FilterSkeleton';
import ItemSkeleton from './ItemSkeleton';
import { StyledScrollView } from './styledComponents';
import { StyledPrimaryDivider } from '../styledComponents';

const SkeletonArray = [...Array(13).keys()];

const StatementsListSkeleton = () => (
  <Fragment>
    <FilterSkeleton />
    <StyledPrimaryDivider />
    <StyledScrollView showsVerticalScrollIndicator={false}>
      {SkeletonArray.map((num) => (
        <ItemSkeleton key={num} isFirst={num === 0} />
      ))}
    </StyledScrollView>
  </Fragment>
);

export default StatementsListSkeleton;
