import React from 'react';
import T from 'prop-types';

import { IconWrapper, Row, RowText } from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';

const ArrowIcon = iconDictionary('arrowRightCarbonS');

const ListItem = ({ label, onPress }) => (
  <Row onPress={onPress}>
    <RowText>{label}</RowText>
    <IconWrapper>{ArrowIcon}</IconWrapper>
  </Row>
);

ListItem.propTypes = {
  label: T.string.isRequired,
  onPress: T.func.isRequired,
};

export default ListItem;
