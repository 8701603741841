import React, { Fragment } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { INFO_COPY_DICT } from '@dmi/shared/redux/SecureMessaging/constants';
import { MOBILE_CARD_BUTTON_DICTIONARY as DICT } from '@dmi/shared/utils/marketingTilesProps';

import InfoSection from '../InfoSection';
import { SecondaryHeader } from '../../../ScreenHeaders';
import {
  CardButton,
  ConditionalRender,
  H5,
  NativeAnimation,
  P2,
  WebAnimation,
} from '../../../base_ui';
import {
  AnimationContainer,
  CardButtonWrapper,
  ConfirmationRow,
  Container,
  FlexView,
  StyledH2,
  StyledP2,
} from './styledComponents';

const SuccessComponent = ({
  confirmationData,
  isMurabahaAccount,
  navigation,
  successHeading,
  successText,
}) => {
  const { nestedScreen, screen } = isMurabahaAccount
    ? DICT.documents
    : DICT.learningCenter;

  const onPress = () =>
    navigation.navigate(screen, nestedScreen ? { nestedScreen } : undefined);

  return (
    <Fragment>
      <SecondaryHeader
        handleDone={() => navigation.navigate('SecureMessaging')}
        title="Confirmation"
      />
      <Container>
        <AnimationContainer>
          <ConditionalRender
            Component={<WebAnimation name="robotMobile" />}
            FallbackComponent={<NativeAnimation name="robotMobile" />}
            shouldRender={Platform.OS === 'web'}
          />
        </AnimationContainer>
        <StyledH2>{successHeading}</StyledH2>
        <StyledP2>{successText}</StyledP2>
        {confirmationData.map(({ label, value }) => (
          <ConfirmationRow key={label}>
            <H5>{label}</H5>
            <P2>{value}</P2>
          </ConfirmationRow>
        ))}
        <InfoSection
          data={INFO_COPY_DICT.confirmation}
        />
        <FlexView />
        <CardButtonWrapper>
          <CardButton
            onPress={onPress}
            {...(isMurabahaAccount ? DICT.documents : DICT.learningCenter)}
          />
        </CardButtonWrapper>
      </Container>
    </Fragment>
  );
};

SuccessComponent.propTypes = {
  confirmationData: T.array.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  successHeading: T.string,
  successText: T.string,
};

export default SuccessComponent;
