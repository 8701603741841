/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Ellipse,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function DogWindow(props) {
  return (
    <Svg data-name="Layer 1" fill="#000" height={moderateScale(120)} id="Layer_1" viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Polygon className="cls-1" fill="#eff3f6" points="78.45 12.02 90.42 0.47 40.44 0.47 40.44 12.17 78.45 12.02" />
      <Polygon className="cls-1" fill="#eff3f6" points="76.76 15.12 40.72 52.28 40.72 15.4 76.76 15.12" />
      <Polygon className="cls-1" fill="#eff3f6" points="245.98 86.73 187.22 86.73 246.73 29.66 245.98 33.23 245.98 86.73" />
      <Path className="cls-2" d="M167.83,64a33.6,33.6,0,0,1-11.74,7.54c-6,2.26-12.24,1.38-15.82.5l-.53.18s18.86,11.64,35.1-3Z" fill="#dce2e7" />
      <Polygon className="cls-3" fill="#c2c2c2" points="287.24 120.7 -0.01 120.7 -0.01 120.2 286.74 120.2 286.74 0.45 287.24 0.45 287.24 120.7" />
      <Path d="M268.4,103.51H19.67a.5.5,0,0,1-.5-.5V2.44a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V102.51H267.9V2.92a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V103A.5.5,0,0,1,268.4,103.51Z" />
      <Rect className="cls-1" fill="#eff3f6" height="18.02" width="272.83" x="6.65" y="97.05" />
      <Path d="M176.46,12.55H131.71a.38.38,0,1,1,0-.75h44.37V3.63a.38.38,0,0,1,.38-.38.37.37,0,0,1,.37.38v8.54A.37.37,0,0,1,176.46,12.55Z" />
      <Path d="M127.58,12.55H110.51a.38.38,0,0,1-.38-.38V2.41a.38.38,0,1,1,.75,0V11.8h16.7a.38.38,0,0,1,0,.75Z" />
      <Path d="M106.42,86.76H40.47a.37.37,0,0,1-.37-.37V47.1a.37.37,0,0,1,.37-.37.38.38,0,0,1,.38.37V86h65.2V15.62H40.85V42.69a.38.38,0,0,1-.38.37.37.37,0,0,1-.37-.37V15.25a.37.37,0,0,1,.37-.38h66a.38.38,0,0,1,.38.38V86.39A.38.38,0,0,1,106.42,86.76Z" />
      <Path className="cls-2" d="M157.38,24.76s8.1,4.55,11.7,8.22,8,11.07,7.49,15.64c0,0-3.44,1.54-3.9,1.06S167.93,30.87,157.38,24.76Z" fill="#dce2e7" />
      <Path d="M176.46,86.76H110.51a.38.38,0,0,1-.38-.37V15.25a.38.38,0,0,1,.38-.38h66a.37.37,0,0,1,.37.38V86.39A.37.37,0,0,1,176.46,86.76ZM110.88,86h65.2V15.62h-65.2Z" />
      <Path d="M174.84,115.59H49.93a.43.43,0,1,1,0-.85H174.84a.43.43,0,1,1,0,.85Z" />
      <Path d="M279.2,115.59H178a.43.43,0,1,1,0-.85H278.78V97.46H143a.43.43,0,0,1,0-.85H279.2a.42.42,0,0,1,.43.42v18.13A.43.43,0,0,1,279.2,115.59Z" />
      <Path d="M138.22,97.46H15.74a.43.43,0,0,1,0-.85H138.22a.43.43,0,0,1,0,.85Z" />
      <Path d="M44.53,115.59H7a.43.43,0,0,1-.43-.43V97A.42.42,0,0,1,7,96.61h6a.43.43,0,0,1,0,.85H7.45v17.28H44.53a.43.43,0,1,1,0,.85Z" />
      <Path d="M106.42,12.55H40.47a.37.37,0,0,1-.37-.38v-10a.37.37,0,0,1,.37-.38.38.38,0,0,1,.38.38V11.8h65.2V1.73a.38.38,0,1,1,.75,0V12.17A.38.38,0,0,1,106.42,12.55Z" />
      <Path d="M246.51,86.76H180.56a.38.38,0,0,1-.38-.37V15.25a.38.38,0,0,1,.38-.38h65.95a.37.37,0,0,1,.37.38V86.39A.37.37,0,0,1,246.51,86.76ZM180.93,86h65.2V15.62h-65.2Z" />
      <Path d="M246.51,12.55H180.56a.38.38,0,0,1-.38-.38v-10a.38.38,0,1,1,.75,0V11.8h65.2V1.73a.38.38,0,0,1,.38-.38.37.37,0,0,1,.37.38V12.17A.37.37,0,0,1,246.51,12.55Z" />
      <Path className="cls-2" d="M130.65,32s-5.51,7.49-7.27,12.32-2,13.49-1.3,14.85c0,0,4-.46,4.24-1.09S123.56,42,130.65,32Z" fill="#dce2e7" />
      <Path d="M151.21,43.3c-.38-1.79-3.83-2.59-7.7-1.78s-6.71,2.92-6.33,4.72a1.74,1.74,0,0,0,.2.49,7.83,7.83,0,0,0,7.91,4.55l0,.11.25-.11h.27v-.11A7.81,7.81,0,0,0,151.21,43.3Z" />
      <Path d="M121.63,60a7.21,7.21,0,0,1-6.55-3.42c-3.52-5.86-1.46-14,6.12-24.09a33.51,33.51,0,0,1,4-4.52c.19-.22,5.45-6.24,10-5.91a.5.5,0,1,1-.07,1c-4.06-.27-9.17,5.54-9.22,5.6A32.55,32.55,0,0,0,122,33.09c-7.31,9.76-9.35,17.49-6.06,23,2.81,4.69,9,2.45,9.31,2.35a.5.5,0,1,1,.35.93A12.49,12.49,0,0,1,121.63,60Z" />
      <Path d="M147.6,73.47c-6.26,0-16.24-1.73-20.49-12-6.31-15.29,3-31.58,3-31.74a.5.5,0,1,1,.86.5c-.09.16-9.11,16-3,30.86s24.6,11,24.79,11a.5.5,0,1,1,.2,1A30.69,30.69,0,0,1,147.6,73.47Z" />
      <Path d="M174,50a.5.5,0,0,1-.5-.47A.51.51,0,0,1,174,49c.08,0,8.11-.61,9-6.58,1.06-6.93-4.62-13.69-16.89-20.09-.51-.27-1-.52-1.5-.76a.49.49,0,0,1-.24-.66.5.5,0,0,1,.67-.24l1.54.77C179.26,28,185.12,35.13,184,42.53,183,49.3,174.13,50,174,50Z" />
      <Path d="M159.15,71a.5.5,0,0,1-.21-1c6-2.7,13.13-8,12.92-18.11-.32-16-14.94-27-15.09-27.08a.5.5,0,1,1,.59-.8c.15.11,15.17,11.32,15.5,27.86.23,11.18-8.37,16.73-13.51,19A.53.53,0,0,1,159.15,71Z" />
      <Path d="M131.66,86.85a.5.5,0,0,1-.5-.5V76.82c0-.18-.1-4.71,2.87-7.29a.51.51,0,0,1,.71,0,.51.51,0,0,1-.05.71c-2.61,2.26-2.53,6.47-2.53,6.52v9.54A.5.5,0,0,1,131.66,86.85Z" />
      <Ellipse cx="132.84" cy="40.58" rx="1.35" ry="1.85" transform="translate(-4.45 19.52) rotate(-8.27)" />
      <Ellipse cx="153.27" cy="36.29" rx="1.35" ry="1.85" transform="translate(-3.63 22.42) rotate(-8.27)" />
      <Path d="M146.57,57.19a.39.39,0,0,1-.37-.31l-1.15-6a.38.38,0,0,1,.74-.14l1.15,6a.37.37,0,0,1-.3.44Z" />
      <Path d="M141.36,60a.38.38,0,0,1-.37-.37.37.37,0,0,1,.37-.38s4.07-.08,4.9-2.28a.37.37,0,0,1,.48-.22.38.38,0,0,1,.22.48c-1,2.68-5.41,2.77-5.59,2.77Z" />
      <Path d="M149.77,58.56a3.76,3.76,0,0,1-3.32-1.42.38.38,0,0,1,.63-.41c1.26,2,5.39.67,5.43.65a.38.38,0,0,1,.23.72A11.12,11.12,0,0,1,149.77,58.56Z" />
      <Path d="M164.68,21.58a.48.48,0,0,1-.2,0c-9-3.78-13.51-.88-13.56-.85a.5.5,0,0,1-.56-.83c.2-.14,5-3.26,14.51.76a.49.49,0,0,1,.27.65A.51.51,0,0,1,164.68,21.58Z" />
      <Path d="M135.54,23a.51.51,0,0,1-.37-.84,13.7,13.7,0,0,1,7.52-3.72,11,11,0,0,1,7.89,1.5.5.5,0,1,1-.59.8,10.27,10.27,0,0,0-7.15-1.31,12.91,12.91,0,0,0-6.94,3.41A.5.5,0,0,1,135.54,23Z" />
      <Path d="M176.29,70.45a.53.53,0,0,1-.27-.08l-8.31-5.47a.5.5,0,0,1,.55-.84l8.31,5.47a.5.5,0,0,1,.14.69A.51.51,0,0,1,176.29,70.45Z" />
      <Path d="M196.89,86.66a.49.49,0,0,1-.44-.26c-3.64-6.83-15.77-13.55-15.9-13.62a.49.49,0,0,1-.19-.68.5.5,0,0,1,.68-.2c.51.28,12.51,6.94,16.29,14a.49.49,0,0,1-.2.67A.53.53,0,0,1,196.89,86.66Z" />
      <Rect className="cls-4" fill="#fff" height="26.04" width="3.35" x="176.83" y="26.94" />
      <Path d="M61.08,76.18a.36.36,0,0,1-.27-.12.38.38,0,0,1,0-.53L82.89,54.41a.38.38,0,0,1,.53,0,.37.37,0,0,1,0,.53L61.34,76.07A.38.38,0,0,1,61.08,76.18Z" />
      <Path d="M61.36,52.87a.38.38,0,0,1-.26-.65L89,25.49a.37.37,0,1,1,.52.54L61.62,52.76A.36.36,0,0,1,61.36,52.87Z" />
      <Path d="M54.7,59.05a.4.4,0,0,1-.27-.11.38.38,0,0,1,0-.53l3.41-3.26a.38.38,0,1,1,.51.55L55,59A.35.35,0,0,1,54.7,59.05Z" />
      <Path d="M188.34,58a.39.39,0,0,1-.27-.12.38.38,0,0,1,0-.53L210.16,36.2a.37.37,0,0,1,.52.54L188.6,57.87A.35.35,0,0,1,188.34,58Z" />
      <Path d="M211.72,71.83a.36.36,0,0,1-.27-.12.37.37,0,0,1,0-.53l27.87-26.74a.39.39,0,0,1,.54,0,.38.38,0,0,1,0,.53L212,71.72A.4.4,0,0,1,211.72,71.83Z" />
      <Path d="M205.06,78a.36.36,0,0,1-.27-.12.37.37,0,0,1,0-.53l3.41-3.25a.38.38,0,0,1,.52.55l-3.41,3.25A.39.39,0,0,1,205.06,78Z" />
      <Path d="M14.82,16.52H.27a.38.38,0,1,1,0-.75H14.82a.38.38,0,0,1,0,.75Z" />
      <Path d="M18.85,47.87H.27a.38.38,0,1,1,0-.75H18.85a.38.38,0,1,1,0,.75Z" />
      <Path d="M18.85,79.22h-15a.38.38,0,0,1-.38-.38.37.37,0,0,1,.38-.37h15a.37.37,0,0,1,.38.37A.38.38,0,0,1,18.85,79.22Z" />
      <Path d="M286.62,16.52H268a.38.38,0,0,1,0-.75h18.59a.38.38,0,0,1,0,.75Z" />
      <Path d="M286.62,47.87H273.85a.38.38,0,0,1,0-.75h12.77a.38.38,0,0,1,0,.75Z" />
      <Path d="M283.71,79.22H268a.38.38,0,0,1-.37-.38.37.37,0,0,1,.37-.37h15.68a.37.37,0,0,1,.37.37A.38.38,0,0,1,283.71,79.22Z" />
    </Svg>
  );
}
