import React, { useState } from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/utils/languageDictionary/navigationMessages';

import { ConditionalRender, H1, P3 } from '../base_ui';
import {
  CardContentWrapper,
  StyledLoanCard,
  StyledP3,
  StyledPressable,
} from './styledComponents';

const LoanCard = ({
  address,
  dispatchChangeSelectedLoan,
  hashedLoanNumber,
  maskedLoanNumber,
  nextPaymentDueDateMessage,
  onlineAvailabilityStatus,
  totalMonthlyPayment,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const disabled = onlineAvailabilityStatus !== 'Available';

  const ActiveLoanComponent = (
    <CardContentWrapper>
      <StyledP3>{address}</StyledP3>
      <View>
        <H1>{totalMonthlyPayment}</H1>
        <P3>{nextPaymentDueDateMessage}</P3>
      </View>
    </CardContentWrapper>
  );

  const DisabledLoanComponent = (
    <CardContentWrapper>
      <StyledP3 disabled={disabled}>
        <FormattedMessage
          id={INTL_IDS.LOANS_SELECT_UNAVAILABLE_LOANNUMBER}
          values={{ maskedLoanNumber }}
        />
      </StyledP3>
      <View>
        <H1>{onlineAvailabilityStatus}</H1>
        <P3>
          <FormattedMessage id={INTL_IDS.LOANS_SELECT_UNAVAILABLE_STATUS} />
        </P3>
      </View>
    </CardContentWrapper>
  );

  return (
    <StyledLoanCard>
      <StyledPressable
        $pressed={isPressed}
        disabled={disabled}
        onPress={() => dispatchChangeSelectedLoan(hashedLoanNumber)}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
      >
        <ConditionalRender
          Component={ActiveLoanComponent}
          FallbackComponent={DisabledLoanComponent}
          shouldRender={!disabled}
        />
      </StyledPressable>
    </StyledLoanCard>
  );
};

LoanCard.propTypes = {
  address: T.string,
  dispatchChangeSelectedLoan: T.func.isRequired,
  hashedLoanNumber: T.string,
  maskedLoanNumber: T.string,
  nextPaymentDueDateMessage: T.string,
  onlineAvailabilityStatus: T.string.isRequired,
  totalMonthlyPayment: T.string,
};

export default LoanCard;
