import React from 'react';

import { AMORTIZE_DEFAULT_ERROR_MESSAGE } from '@dmi/shared/redux/Calculators/Amortize/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { ResultsContentContainer } from './styledComponents';
import Message from '../../../Message';

const WarningIcon = iconDictionary('warning');

const ResultsError = () => (
// TODO: update the copy for error messaging
  <ResultsContentContainer>
    <Message
      bodySize="small"
      icon={WarningIcon}
      title={AMORTIZE_DEFAULT_ERROR_MESSAGE}
    />
  </ResultsContentContainer>
);

export default ResultsError;
