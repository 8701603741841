import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeInput } from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import {
  selectIsScheduledDateAfterGracePeriod,
  selectOtp,
  selectOtpForm,
  selectOtpReviewData,
  selectOtpScheduledDateShort,
  selectShouldOTPReviewShowLatePaymentWarning,
  selectTotalPayment,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import Review from '../../../components/Payments/Review';

const ReviewPaymentDetailsScreen = ({
  customerServicePhoneNumber,
  dispatchChangeInput,
  dispatchResetState,
  isAfterGracePeriod,
  isScheduledDateAfterGracePeriod,
  navigation,
  reviewData,
  reviewForm: { isLatePaymentConfirmChecked },
  scheduledDate,
  shouldOTPReviewShowLatePaymentWarning,
  totalPayment,
}) => {
  useEffect(() => () => {
    dispatchResetState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Review
      customerServicePhoneNumber={customerServicePhoneNumber}
      dispatchChangeInput={dispatchChangeInput}
      isAfterGracePeriod={isAfterGracePeriod}
      isLatePaymentConfirmChecked={isLatePaymentConfirmChecked}
      isScheduledDateAfterGracePeriod={isScheduledDateAfterGracePeriod}
      latePaymentConfirmProps={{
        checked: isLatePaymentConfirmChecked,
        onPress: () => dispatchChangeInput({
          field: 'isLatePaymentConfirmChecked',
          form: 'review',
          value: !isLatePaymentConfirmChecked,
        }),
      }}
      navigation={navigation}
      reviewData={reviewData}
      shouldOTPReviewShowLatePaymentWarning={shouldOTPReviewShowLatePaymentWarning}
      summaryProps={{
        disabled: shouldOTPReviewShowLatePaymentWarning && !isLatePaymentConfirmChecked,
        label: 'Pay Now',
        onPress: () => navigation.navigate('OtpDisclosure'),
        subText: `Scheduled for ${scheduledDate}`,
        text: totalPayment,
      }}
      variant="otp"
    />
  );
};

ReviewPaymentDetailsScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  isAfterGracePeriod: T.bool.isRequired,
  isScheduledDateAfterGracePeriod: T.bool.isRequired,
  navigation: T.object.isRequired,
  reviewData: T.array.isRequired,
  reviewForm: T.shape({ isLatePaymentConfirmChecked: T.bool.isRequired }),
  scheduledDate: T.string.isRequired,
  shouldOTPReviewShowLatePaymentWarning: T.bool.isRequired,
  totalPayment: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: One Time Payment
   */
  isAfterGracePeriod: selectOtp('isAfterGracePeriod'),
  isScheduledDateAfterGracePeriod: selectIsScheduledDateAfterGracePeriod(),
  reviewData: selectOtpReviewData(),
  reviewForm: selectOtpForm('review'),
  scheduledDate: selectOtpScheduledDateShort(),
  shouldOTPReviewShowLatePaymentWarning: selectShouldOTPReviewShowLatePaymentWarning(),
  totalPayment: selectTotalPayment(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const mapDispatchToProps = (dispatch) => ({ // eslint-disable-line object-curly-newline
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchResetState: () => dispatch(changeInput({
    field: 'isLatePaymentConfirmChecked',
    form: 'review',
    value: false,
  })),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ReviewPaymentDetailsScreen);
