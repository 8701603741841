// See https://docs.expo.dev/guides/monorepos/#change-default-entrypoint
import { registerRootComponent } from 'expo';
import { LogBox } from 'react-native';

import App from './App';

// See: https://github.com/facebook/react-native/issues/33557
LogBox.ignoreLogs(['ViewPropTypes']);

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
