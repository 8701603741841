import React, { useRef } from 'react';
import { Platform } from 'react-native';
import T from 'prop-types';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer } from '@react-navigation/native';
// import * as Analytics from 'expo-firebase-analytics';

import AccountDeletion from '../containers/AccountDeletion';
import { navigationRef } from '../utils/rootNavigation';
import LinkingConfiguration from './LinkingConfiguration';
import NotFoundScreen from '../containers/NotFoundScreen';
import PostAuthStack from './PostAuthStack';
import PreAuthStack from './PreAuthStack';
import SsoGenericLogoutStack from './SsoGenericLogoutStack';
import SsoStack from './SsoStack';

const Stack = createStackNavigator();

const Navigation = ({
  isGoldMigrationPLGeneralLogin,
  isGoldMigrationPLPreLogin,
  isGoldMigrationPLProfileTransfer,
  isLoggedIn,
  isLoggedOutViaSsoGeneric,
  isMurabahaAccount,
  isSslAuth,
  isSso,
}) => {
  const PreAuthComponent = (() => {
    if (isLoggedOutViaSsoGeneric) return SsoGenericLogoutStack;
    if ((isSslAuth || isSso) && Platform.OS === 'web') return SsoStack;
    return PreAuthStack;
  })();

  const MainScreenElement = isLoggedIn ? (
    <Stack.Screen
      component={PostAuthStack}
      name="PostAuthNavigator"
      options={{ animationTypeForReplace: 'push' }}
    />
  ) : (
    <Stack.Screen
      component={PreAuthComponent}
      name="PreAuthNavigator"
      options={{ animationEnabled: false }}
    />
  );

  // If you are not familiar with React Navigation, we recommend going through the
  // "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
  const routeNameRef = useRef();
  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration({
        isGoldMigrationPLGeneralLogin,
        isGoldMigrationPLPreLogin,
        isGoldMigrationPLProfileTransfer,
        isLoggedIn,
        isMurabahaAccount,
      })}
      onReady={() => {
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          // The line below uses the expo-firebase-analytics tracker
          // https://docs.expo.io/versions/latest/sdk/firebase-analytics/
          // Change this line to use another Mobile analytics SDK
          // await Analytics.logEvent('screen_view', { screen_name: currentRouteName });
        }

        // Save the current route name for later comparison
        routeNameRef.current = currentRouteName;
      }}
    >
      <Stack.Navigator screenOptions={{ headerShown: false }}>
        {MainScreenElement}
        <Stack.Screen component={AccountDeletion} name="AccountDeletion" />
        <Stack.Screen
          component={NotFoundScreen}
          name="NotFound"
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

Navigation.propTypes = {
  isGoldMigrationPLGeneralLogin: T.bool.isRequired,
  isGoldMigrationPLPreLogin: T.bool.isRequired,
  isGoldMigrationPLProfileTransfer: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  isLoggedOutViaSsoGeneric: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
};

export default Navigation;
