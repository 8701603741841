import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';
import { ErrorContainer } from './styledComponents';

const errorStorm = iconDictionary('errorStorm');

const ErrorComponent = ({ confirmationDictionary: { error } }) => {
  const { buttonText, header, text } = error;

  return (
    <ErrorContainer>
      <Message
        body={text}
        icon={errorStorm}
        link={{ label: buttonText, path: 'Payments' }}
        title={header}
      />
    </ErrorContainer>
  );
};

ErrorComponent.propTypes = {
  confirmationDictionary: T.shape({
    error: T.shape({
      buttonText: T.string.isRequired,
      header: T.string.isRequired,
      text: T.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default ErrorComponent;
