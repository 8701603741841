/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavLogoutActiveMIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M13.7201 1.43994H3.57008C3.4214 1.44242 3.27934 1.50191 3.17327 1.60613C3.06719 1.71034 3.00519 1.85132 3.00008 1.99994V15.9999C2.99876 16.0783 3.01304 16.1561 3.04209 16.2288C3.07115 16.3015 3.1144 16.3678 3.16932 16.4236C3.22424 16.4795 3.28973 16.5238 3.36197 16.5541C3.43421 16.5844 3.51176 16.6 3.59008 16.5999H13.7201C13.8714 16.5975 14.016 16.5369 14.124 16.4308C14.2319 16.3247 14.295 16.1812 14.3001 16.0299V1.99994C14.2925 1.85043 14.2283 1.7094 14.1207 1.60542C14.013 1.50144 13.8698 1.44229 13.7201 1.43994ZM14.0701 9.43994L11.0001 12.5699C10.9426 12.6314 10.8728 12.6801 10.7953 12.7128C10.7178 12.7455 10.6342 12.7616 10.5501 12.7599C10.466 12.761 10.3827 12.7446 10.3052 12.712C10.2278 12.6793 10.158 12.6309 10.1001 12.5699C10.0402 12.5113 9.99266 12.4413 9.96019 12.364C9.92772 12.2867 9.91099 12.2038 9.91099 12.1199C9.91099 12.0361 9.92772 11.9532 9.96019 11.8759C9.99266 11.7986 10.0402 11.7286 10.1001 11.6699L12.1001 9.66994H5.78009C5.61035 9.66994 5.44756 9.60251 5.32754 9.48249C5.20751 9.36247 5.14008 9.19968 5.14008 9.02994C5.14008 8.8602 5.20751 8.69742 5.32754 8.57739C5.44756 8.45737 5.61035 8.38994 5.78009 8.38994H12.1001L10.1001 6.38994C10.0008 6.26701 9.95056 6.11171 9.95895 5.95394C9.96733 5.79617 10.0338 5.64707 10.1455 5.53535C10.2572 5.42363 10.4063 5.35718 10.5641 5.3488C10.7219 5.34042 10.8772 5.39069 11.0001 5.48994L14.1301 8.60994C14.242 8.72555 14.3063 8.87908 14.3101 9.03994V8.99994C14.3064 9.08954 14.2829 9.17722 14.2414 9.25673C14.1999 9.33624 14.1414 9.40562 14.0701 9.45994V9.43994Z" fill={primary} />
    </Svg>
  );
}
