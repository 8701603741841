/**
 * Slider
 *
 * @prop marks - optional array of objects that contain the label value
 * @prop max - max allowable value for slider
 * @prop min - min allowable value for slider
 * @prop onChange - callback func that is called when slider thumb is dragged
 * @prop onChangeCommitted - callback func that is called when dragging ceases
 * @prop value - initial value of the slider
 */

import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import T from 'prop-types';
import { Animated, PanResponder } from 'react-native';
import SliderBar from '@react-native-community/slider';
import { useTheme } from 'styled-components/native';

import ValueLabel from './ValueLabel';
import SliderLabels from './SliderLabels';
import { SliderBarWrapper, SliderContainer } from './styledComponents';

const Slider = ({
  marks,
  max,
  min,
  onChange,
  onChangeCommitted,
  onSlidingStart,
  value,
  ...restProps
}) => {
  const [sliderBarWidth, setSliderBarWidth] = useState(0);
  const { colors: { accentGreen, primaryGray } } = useTheme();
  const panX = useRef(new Animated.Value(0)).current;
  const maxValueLabelX = Math.max(sliderBarWidth - 81, 0);

  useEffect(() => {
    panX.setValue(value * ((sliderBarWidth - 81) / max));
  }, [max, panX, sliderBarWidth, value]);

  const panResponder = useMemo(() => PanResponder.create({
    onMoveShouldSetPanResponder: () => true,
    onPanResponderGrant: () => {
      onSlidingStart();
    },
    onPanResponderMove: ((_, { dx }) => {
      const newValue = Math.max(Math.round(value + (dx * (max / (sliderBarWidth - 81)))), 0);
      onChange(Math.min(newValue, max));
    }),
    onPanResponderRelease: () => {
      onChangeCommitted(value);
    },
  }), [
    max,
    onChange,
    onChangeCommitted,
    onSlidingStart,
    sliderBarWidth,
    value,
  ]);

  return (
    <SliderContainer>
      <Animated.View
        style={{
          transform: [{
            translateX: panX.interpolate({
              extrapolate: 'clamp',
              inputRange: [0, maxValueLabelX],
              outputRange: [0, maxValueLabelX],
            }),
          }],
        }}
        {...panResponder.panHandlers}
      >
        <ValueLabel
          maximumValue={max}
          sliderBarWidth={sliderBarWidth}
          value={value}
        />
      </Animated.View>
      <SliderBarWrapper onLayout={(event) => setSliderBarWidth(event.nativeEvent.layout.width)}>
        <SliderBar
          maximumTrackTintColor={primaryGray}
          maximumValue={max}
          minimumTrackTintColor={accentGreen}
          minimumValue={min}
          onSlidingComplete={onChangeCommitted}
          onSlidingStart={onSlidingStart}
          onValueChange={onChange}
          step={1}
          style={{ height: 25 }}
          tapToSeek
          thumbStyle={{
            borderRadius: 8,
            height: 16,
            width: 16,
          }}
          thumbTintColor={accentGreen}
          value={value}
          {...restProps}
        />
        <SliderLabels marks={marks} />
      </SliderBarWrapper>
    </SliderContainer>
  );
};

Slider.propTypes = {
  marks: T.array,
  max: T.number.isRequired,
  min: T.number.isRequired,
  onChange: T.func.isRequired,
  onChangeCommitted: T.func,
  onSlidingStart: T.func.isRequired,
  value: T.oneOfType([T.string, T.number]),
};

export default Slider;
