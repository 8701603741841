import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';
import { useIntl } from 'react-intl';

import Row from './Row';
import { Container, LinkText } from './styledComponents';

const InfoSection = ({ customerServicePhoneNumber, data }) => {
  const { formatMessage } = useIntl();

  const onPhoneNumberPress = () => {
    Linking.openURL(`tel://${customerServicePhoneNumber.replace(/-/g, '')}`);
  };

  const messageValues = {
    // eslint-disable-next-line react/prop-types
    a: ({ msg }) =>
      (
        <LinkText
          onPress={onPhoneNumberPress}
          role="link"
        >
          {msg}
        </LinkText>
      ),
    customerServicePhoneNumber,
  };

  return (
    <Container>
      {data.map(({
        isReview,
        text,
        variant,
        ...restProps
      }, i) => (
        <Row
          key={text}
          isReview={isReview}
          messageValues={messageValues}
          noBottomPadding={i === data.length - 1}
          text={formatMessage({ id: text })}
          variant={variant}
          {...restProps}
        />
      ))}
    </Container>
  );
};

InfoSection.propTypes = {
  customerServicePhoneNumber: T.string,
  data: T.arrayOf(T.shape({ text: T.string, variant: T.string })).isRequired,
};

export default InfoSection;
