/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../scaleHelpers';

export default function HistoryIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg
      fill="none"
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath={clipPath}>
        <Path d="M2.59985 17.6C2.29985 17.6 1.99985 17.3 1.99985 17V1.7C1.89985 1.3 2.19985 1 2.59985 1C2.99985 1 3.19985 1.3 3.19985 1.7V17C3.19985 17.3 2.89985 17.6 2.59985 17.6Z" fill="black" />
        <Path d="M12.1996 12.3001C11.3996 12.3001 10.3996 12.1001 9.49961 11.7001C6.29961 10.3001 3.09961 11.7001 3.09961 11.7001C2.79961 11.8001 2.39961 11.7001 2.29961 11.4001C2.19961 11.1001 2.29961 10.7001 2.59961 10.6001C2.79961 10.5001 6.29961 9.0001 9.99961 10.6001C12.3996 11.6001 14.5996 10.7001 15.3996 10.2001L13.9996 7.1001C13.8996 7.0001 13.8996 6.8001 13.9996 6.6001L15.0996 3.3001C13.7996 3.8001 11.6996 4.3001 9.49961 3.3001C6.29961 1.9001 3.09961 3.3001 3.09961 3.3001C2.69961 3.5001 2.39961 3.4001 2.19961 3.1001C2.09961 2.8001 2.19961 2.4001 2.49961 2.2001C2.69961 2.1001 6.19961 0.600098 9.89961 2.2001C12.9996 3.5001 15.6996 1.5001 15.7996 1.5001C15.9996 1.3001 16.2996 1.3001 16.4996 1.5001C16.6996 1.7001 16.7996 1.9001 16.6996 2.2001L15.1996 6.8001L16.6996 10.1001C16.7996 10.4001 16.6996 10.7001 16.4996 10.9001C16.3996 11.0001 14.5996 12.3001 12.1996 12.3001Z" fill="black" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="16.6" transform="translate(2 1)" width="14.8" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
