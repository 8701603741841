import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  BorderlessButton,
  H3,
  P2,
  PrimaryDivider,
} from '../../../base_ui';

export const AnimationWrapper = styled.View`
  padding: ${moderateScale(20)}px ${moderateScale(20)}px ${moderateScale(8)}px;
`;

export const CancelWrapper = styled.View`
  align-items: center;
  margin-vertical: ${moderateScale(8)}px;
  padding-vertical: ${moderateScale(6)}px;
`;

export const CardButtonWrapper = styled.View`
  margin-top: ${moderateScale(8)}px;
`;

export const DataLabel = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const DataLine = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: ${moderateScale(8)}px;
`;

export const DataWrapper = styled.View`
  padding-bottom: ${moderateScale(36)}px;
  padding-top: ${moderateScale(8)}px;
`;

export const InfoSectionContainer = styled.View`
  margin: ${({ $isAutopay }) => $isAutopay
    ? `${moderateScale(10)}px ${moderateScale(5)}px 0`
    : `-${moderateScale(20)}px ${moderateScale(5)}px 0`};
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: f4,
  }],
}))``;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;

export const SubHeader = styled(H3)`
  padding-vertical: ${moderateScale(8)}px;
  text-align: center;
`;

export const SubHeaderSubText = styled(P2)`
  padding-vertical: ${moderateScale(8)}px;
  text-align: center;
`;
