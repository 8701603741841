import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';
import { FormattedMessage } from 'react-intl';

import {
  FAX_NUMBER_CS_DEPT,
  TDD_NUMBER_CS_DEPT,
} from '@dmi/shared/redux/Help/constants';
import { INTL_IDS } from '@dmi/shared/redux/Help/messages';

import { ConditionalRender, P2 } from '../../base_ui';
import {
  BoldText,
  StyledButton,
  StyledCard,
  StyledCardH3,
  StyledCardP2,
  StyledH2,
  StyledLink,
  StyledText,
  StyledView,
} from './styledComponents';

const TopSection = ({
  customerServicePhoneNumber,
  isSecureMessagingTreatmentOn,
  navigation,
}) => (
  <StyledView>
    <ConditionalRender
      Component={(
        <StyledCard>
          <StyledCardH3>Questions? Send a message</StyledCardH3>
          <StyledCardP2><FormattedMessage id={INTL_IDS.CONTACT_INFO_CARD_TEXT} /></StyledCardP2>
          <StyledButton
            isPrimary
            label="Send Secure Message"
            onPress={() => navigation.navigate('SecureMessaging')}
            size="medium"
          />
        </StyledCard>
      )}
      shouldRender={isSecureMessagingTreatmentOn}
    />
    <StyledCard>
      <StyledCardH3>Call us or send a fax</StyledCardH3>
      <StyledLink $isCard onPress={() => Linking.openURL(`tel://${customerServicePhoneNumber}`)}>
        {customerServicePhoneNumber}
      </StyledLink>
      <StyledText>
        <BoldText>TDD: </BoldText>
        <StyledLink
          onPress={() => Linking.openURL(`tel://${TDD_NUMBER_CS_DEPT.replace(/\+|-/g, '')}`)}
        >
          {TDD_NUMBER_CS_DEPT}
        </StyledLink>
      </StyledText>
      <StyledText>
        <BoldText>Fax: </BoldText>
        <P2>{FAX_NUMBER_CS_DEPT}</P2>
      </StyledText>
    </StyledCard>
    <StyledH2>Additional Contacts</StyledH2>
  </StyledView>
);

TopSection.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default TopSection;
