import React from 'react';
import T from 'prop-types';

import LoadingDog from '../../LoadingDog';

const processingProps = { heading: 'Uploading Files', text: 'Just a moment.' };

const LoadingScene = ({ isProcessing }) => {
  const propsToPassDown = isProcessing ? processingProps : {};
  return (<LoadingDog {...propsToPassDown} />);
};

LoadingScene.propTypes = { isProcessing: T.bool.isRequired };

export default LoadingScene;
