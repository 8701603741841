/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';

import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function CalendarLarge(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      {...props}
      height={moderateScale(85)}
      viewBox="0 0 85 85"
      width={moderateScale(85)}
    >
      <G>
        <Path
          className="st0"
          d="M-167.9,67.5h-50.8v-2.1h50.9l6.2-5.3v1.1C-161.6,64.7-164.4,67.5-167.9,67.5z"
          fill="#E6EBEF"
        />
        <Rect
          className="st0"
          fill="#E6EBEF"
          height="43"
          width="8.6"
          x="-161.8"
          y="27.3"
        />
        <Rect
          className="st1"
          fill="#DAD3E4"
          height="10.2"
          width="64.9"
          x="-218.2"
          y="17.3"
        />
        <Rect
          className="st2"
          fill="#C1B6D2"
          height="10.7"
          width="8.2"
          x="-161.5"
          y="17.1"
        />
        <Polyline
          className="st3"
          fill="none"
          points="-210.6,17.1 -218.7,17.1 -218.7,56.1"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Polyline
          className="st3"
          fill="none"
          points="-161.7,34 -161.7,17.1 -207.3,17.1"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Path
          className="st3"
          d="M-170.1,65.2c4.6,0,8.4-3.8,8.4-8.4V36.7"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Path
          className="st0"
          d="M-175.9,59C-175.9,58.9-175.9,58.9-175.9,59l-51.6-0.1c0,3.5,2.9,6.4,6.4,6.4h51.1v0
        C-173.4,65-175.9,62.3-175.9,59z"
          fill="#E6EBEF"
        />
        <Path
          className="st3"
          d="M-191.4,65.3h21.3v0c-3.3-0.2-5.8-3-5.8-6.3c0,0,0,0,0-0.1h-51.6c0,3.5,2.9,6.4,6.4,6.4h26.7"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Polyline
          className="st3"
          fill="#000000"
          points="-218.7,65.3 -218.7,71 -161.5,71 -161.5,63.6"
        />
        <Polyline
          className="st3"
          fill="none"
          points="-153.1,66.1 -153.1,71 -161.5,71"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Polyline
          className="st3"
          fill="none"
          points="-161.7,17.1 -153.1,17.1 -153.1,63.2"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Line
          className="st4"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
          x1="-169.3"
          x2="-153.4"
          y1="27.8"
          y2="27.8"
        />
        <Line
          className="st4"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
          x1="-216.4"
          x2="-171.6"
          y1="27.8"
          y2="27.8"
        />
        <Path
          className="st3"
          d="M-205.5,23.3c-2.7,0-4.9-2.9-4.9-6.5s2.2-6.5,4.9-6.5c2.7,0,4.9,2.9,4.9,6.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Path
          className="st3"
          d="M-188,23.3c-2.7,0-4.9-2.9-4.9-6.5s2.2-6.5,4.9-6.5s4.9,2.9,4.9,6.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Path
          className="st3"
          d="M-170.5,23.3c-2.7,0-4.9-2.9-4.9-6.5s2.2-6.5,4.9-6.5c2.2,0,4.1,1.9,4.7,4.6"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.1823"
        />
        <Ellipse cx="-204.9" cy="23.3" rx="1" ry="1.3" />
        <Ellipse cx="-187.8" cy="23.3" rx="1" ry="1.3" />
        <Ellipse cx="-170.2" cy="23.3" rx="1" ry="1.3" />
        <G>
          <Path
            className="st5"
            d="M-196.2,42.3c0.5-0.7,0.7-1.3,0.7-1.9c0-0.6-0.2-1.1-0.6-1.5s-0.9-0.6-1.5-0.6c-1.1,0-2.2,0.8-3.3,2.5
          l-3.3-1.9c0.9-1.3,1.8-2.3,2.8-3c1-0.7,2.3-1,3.9-1s3,0.5,4.2,1.5c1.2,1,1.8,2.4,1.8,4.1c0,0.9-0.2,1.8-0.7,2.7
          c-0.5,0.9-1.4,2-2.7,3.3l-3.3,3.4h7.2v3.7h-12.9v-3l5.4-5.5C-197.5,43.8-196.7,43-196.2,42.3z"
            fill="#B9ACCC"
          />
          <Path
            className="st5"
            d="M-180.4,38.3c-1.1,0-2,0.4-2.6,1.1c-0.6,0.8-1,1.8-1.1,3.1c1.1-0.7,2.3-1.1,3.6-1.1c1.7,0,3.1,0.6,4.3,1.7
          c1.1,1.1,1.7,2.6,1.7,4.5c0,1.9-0.6,3.3-1.8,4.5c-1.2,1.1-2.8,1.7-4.6,1.7c-1.3,0-2.4-0.2-3.4-0.7c-0.9-0.5-1.7-1.2-2.2-2.1
          c-1-1.7-1.6-3.9-1.6-6.5c0-1.7,0.2-3.1,0.6-4.4c0.4-1.3,1-2.3,1.7-3c1.4-1.5,3.1-2.2,5.2-2.2c0.9,0,1.7,0.1,2.4,0.4
          c0.8,0.3,1.4,0.5,1.8,0.8c0.4,0.3,0.9,0.6,1.4,1l-1.9,2.9c-0.1-0.1-0.3-0.2-0.5-0.4c-0.2-0.2-0.6-0.4-1.2-0.7
          S-179.8,38.3-180.4,38.3z M-180.9,44.9c-0.8,0-1.5,0.2-2,0.7c-0.5,0.5-0.8,1.1-0.8,1.9c0,0.8,0.3,1.4,0.8,2c0.5,0.5,1.2,0.8,2,0.8
          c0.8,0,1.4-0.2,2-0.7c0.5-0.5,0.8-1.1,0.8-1.9c0-0.8-0.2-1.4-0.7-2C-179.4,45.1-180.1,44.9-180.9,44.9z"
            fill="#B9ACCC"
          />
        </G>
        <Line
          className="st4"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
          x1="-224.8"
          x2="-224.8"
          y1="9.1"
          y2="13.1"
        />
        <Line
          className="st4"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
          x1="-222.8"
          x2="-226.8"
          y1="11.1"
          y2="11.1"
        />
        <Rect
          className="st4"
          fill="none"
          height="2.5"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
          width="2.5"
          x="-163.4"
          y="77.8"
        />
        <Circle
          className="st4"
          cx="-158.3"
          cy="8.7"
          fill="none"
          r="1.4"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
      </G>
      <G>
        <Path
          className="st0"
          d="M-29.5,65.5h-24.6v-1h24.7l3-2.6v0.5C-26.4,64.1-27.8,65.5-29.5,65.5z"
          fill="#E6EBEF"
        />
        <Rect
          className="st0"
          fill="#E6EBEF"
          height="20.9"
          width="4.2"
          x="-26.5"
          y="45.9"
        />
        <Rect
          className="st1"
          fill="#DAD3E4"
          height="5"
          width="31.5"
          x="-53.9"
          y="41.1"
        />
        <Rect
          className="st2"
          fill="#C1B6D2"
          height="5.2"
          width="4"
          x="-26.4"
          y="41"
        />
        <Polyline
          className="st6"
          fill="none"
          points="-50.2,41 -54.1,41 -54.1,59.9"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Polyline
          className="st6"
          fill="none"
          points="-26.5,49.2 -26.5,41 -48.6,41"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Path
          className="st6"
          d="M-30.5,64.3c2.2,0,4.1-1.8,4.1-4.1v-9.8"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Path
          className="st0"
          d="M-33.4,61.3C-33.4,61.3-33.4,61.3-33.4,61.3l-25.1,0c0,1.7,1.4,3.1,3.1,3.1h24.8v0
        C-32.1,64.2-33.4,62.9-33.4,61.3z"
          fill="#E6EBEF"
        />
        <Path
          className="st6"
          d="M-40.9,64.4h10.3v0c-1.6-0.1-2.8-1.4-2.8-3c0,0,0,0,0,0h-25.1c0,1.7,1.4,3.1,3.1,3.1h13"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Polyline
          className="st6"
          fill="none"
          points="-54.1,64.4 -54.1,67.2 -26.4,67.2 -26.4,63.6"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Polyline
          className="st6"
          fill="none"
          points="-22.3,64.8 -22.3,67.2 -26.4,67.2"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Polyline
          className="st6"
          fill="none"
          points="-26.5,41 -22.3,41 -22.3,63.4"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Line
          className="st7"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.3639"
          x1="-30.1"
          x2="-22.4"
          y1="46.2"
          y2="46.2"
        />
        <Line
          className="st7"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.3639"
          x1="-53"
          x2="-31.3"
          y1="46.2"
          y2="46.2"
        />
        <Path
          className="st6"
          d="M-47.7,44c-1.3,0-2.4-1.4-2.4-3.2s1.1-3.2,2.4-3.2s2.4,1.4,2.4,3.2"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Path
          className="st6"
          d="M-39.2,44c-1.3,0-2.4-1.4-2.4-3.2s1.1-3.2,2.4-3.2c1.3,0,2.4,1.4,2.4,3.2"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Path
          className="st6"
          d="M-30.7,44c-1.3,0-2.4-1.4-2.4-3.2s1.1-3.2,2.4-3.2c1.1,0,2,0.9,2.3,2.2"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5737"
        />
        <Ellipse cx="-47.4" cy="44" rx="0.5" ry="0.7" />
        <Ellipse cx="-39.1" cy="44" rx="0.5" ry="0.7" />
        <Ellipse cx="-30.6" cy="44" rx="0.5" ry="0.7" />
      </G>
      <G>
        <Path
          className="st0"
          d="M-22.7,119H-54v-1.3h31.4l3.8-3.3v0.7C-18.7,117.2-20.5,119-22.7,119z"
          fill="#E6EBEF"
        />
        <Rect
          className="st0"
          fill="#E6EBEF"
          height="26.5"
          width="5.3"
          x="-18.9"
          y="94.2"
        />
        <Rect
          className="st1"
          fill="#DAD3E4"
          height="6.3"
          width="40"
          x="-53.7"
          y="88"
        />
        <Rect
          className="st2"
          fill="#C1B6D2"
          height="6.6"
          width="5.1"
          x="-18.7"
          y="87.9"
        />
        <Polyline
          className="st8"
          fill="none"
          points="-49,87.9 -54,87.9 -54,111.9"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Polyline
          className="st8"
          fill="none"
          points="-18.8,98.3 -18.8,87.9 -47,87.9"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Path
          className="st8"
          d="M-24,117.6c2.9,0,5.2-2.3,5.2-5.2V100"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Path
          className="st0"
          d="M-27.6,113.7C-27.6,113.7-27.6,113.7-27.6,113.7l-31.9,0c0,2.2,1.8,3.9,3.9,3.9H-24v0
        C-26,117.4-27.6,115.8-27.6,113.7z"
          fill="#E6EBEF"
        />
        <Path
          className="st8"
          d="M-37.1,117.6H-24v0c-2-0.1-3.6-1.8-3.6-3.9c0,0,0,0,0,0h-31.9c0,2.2,1.8,3.9,3.9,3.9h16.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Polyline
          className="st8"
          fill="none"
          points="-54,117.6 -54,121.2 -18.7,121.2 -18.7,116.6"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Polyline
          className="st8"
          fill="none"
          points="-13.5,118.1 -13.5,121.2 -18.7,121.2"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Polyline
          className="st8"
          fill="none"
          points="-18.8,87.9 -13.5,87.9 -13.5,116.3"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Line
          className="st9"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.4626"
          x1="-23.5"
          x2="-13.7"
          y1="94.5"
          y2="94.5"
        />
        <Line
          className="st9"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.4626"
          x1="-52.6"
          x2="-24.9"
          y1="94.5"
          y2="94.5"
        />
        <Path
          className="st8"
          d="M-45.9,91.7c-1.7,0-3-1.8-3-4s1.4-4,3-4s3,1.8,3,4"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Path
          className="st8"
          d="M-35.1,91.7c-1.7,0-3-1.8-3-4s1.4-4,3-4s3,1.8,3,4"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Path
          className="st8"
          d="M-24.3,91.7c-1.7,0-3-1.8-3-4s1.4-4,3-4c1.4,0,2.5,1.2,2.9,2.8"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.7293"
        />
        <Ellipse cx="-45.5" cy="91.7" rx="0.6" ry="0.8" />
        <Ellipse cx="-34.9" cy="91.7" rx="0.6" ry="0.8" />
        <Ellipse cx="-24.1" cy="91.7" rx="0.6" ry="0.8" />
      </G>
      <Path
        className="st0"
        d="M63.6,67.5H12.8v-2.1h50.9l6.2-5.3v1.1C70,64.7,67.1,67.5,63.6,67.5z"
        fill="#E6EBEF"
      />
      <Rect
        className="st0"
        fill="#E6EBEF"
        height="43"
        width="8.6"
        x="69.7"
        y="27.3"
      />
      <Rect
        className="st1"
        fill={svgHighlight}
        height="10.2"
        width="64.9"
        x="13.3"
        y="17.3"
      />
      <Rect
        className="st2"
        fill={svgHighlight}
        height="10.7"
        width="8.2"
        x="70"
        y="17.1"
      />
      <Polyline
        className="st3"
        fill="none"
        points="20.9,17.1 12.9,17.1 12.9,56.1 "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Polyline
        className="st3"
        fill="none"
        points="69.8,34 69.8,17.1 24.2,17.1 "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Path
        className="st3"
        d="M61.5,65.2c4.6,0,8.4-3.8,8.4-8.4V36.7"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Path
        className="st0"
        d="M55.6,59C55.6,58.9,55.6,58.9,55.6,59L4,58.9c0,3.5,2.9,6.4,6.4,6.4h51.1v0C58.2,65,55.6,62.3,55.6,59z"
        fill="#E6EBEF"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Path
        className="st3"
        d="M40.2,65.3h21.3v0c-3.3-0.2-5.8-3-5.8-6.3c0,0,0,0,0-0.1H4c0,3.5,2.9,6.4,6.4,6.4h26.7"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Polyline
        className="st3"
        fill="none"
        points="12.9,65.3 12.9,71 70,71 70,63.6 "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Polyline
        className="st3"
        fill="none"
        points="78.4,66.1 78.4,71 70,71 "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Polyline
        className="st3"
        fill="none"
        points="69.8,17.1 78.4,17.1 78.4,63.2 "
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Line
        className="st4"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        x1="62.3"
        x2="78.2"
        y1="27.8"
        y2="27.8"
      />
      <Line
        className="st4"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.75"
        x1="15.1"
        x2="59.9"
        y1="27.8"
        y2="27.8"
      />
      <Path
        className="st3"
        d="M26,23.3c-2.7,0-4.9-2.9-4.9-6.5s2.2-6.5,4.9-6.5s4.9,2.9,4.9,6.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Path
        className="st3"
        d="M43.5,23.3c-2.7,0-4.9-2.9-4.9-6.5s2.2-6.5,4.9-6.5s4.9,2.9,4.9,6.5"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Path
        className="st3"
        d="M61,23.3c-2.7,0-4.9-2.9-4.9-6.5s2.2-6.5,4.9-6.5c2.2,0,4.1,1.9,4.7,4.6"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.1823"
      />
      <Ellipse cx="26.6" cy="23.3" rx="1" ry="1.3" />
      <Ellipse cx="43.7" cy="23.3" rx="1" ry="1.3" />
      <Ellipse cx="61.3" cy="23.3" rx="1" ry="1.3" />
    </Svg>
  );
}
