import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import {
  INFO_COPY_DICT,
  SCHEDULE_AUTOPAY_DICTIONARY,
} from '@dmi/shared/redux/Payments/Autopay/constants';

import { SecondaryHeader } from '../../../ScreenHeaders';
import { ConditionalRender, StyledScrollView, Tooltip } from '../../../base_ui';
import {
  AddressHeader,
  InfoSection,
  PaymentModal,
  PaymentSummary,
  RowButton,
} from '../../sharedComponents';
import DelayTooltip from './DelayTooltip';
import {
  ContentContainer,
  InfoSectionContainer,
  StyledBorderlessButton,
  StyledDivider,
} from './styledComponents';

const SetupOverview = ({
  accountFormErrors: { beginDate: beginDateError, delayDays: delayDaysError },
  address,
  allowedSetting,
  availableBeginDates,
  availableDelayDays,
  customerServicePhoneNumber,
  dispatchChangeInput,
  dispatchSetIsModalOpen,
  dueDate,
  flow,
  loanNumber,
  maxDelayDays,
  modalProps,
  navigation,
  payFromButtonProps: { noPaymentSource, value: payFromButtonValue },
  paymentAmount,
  requestStatus,
  selectedBeginDate,
  selectedDelayDays,
  summaryScheduledDateString,
}) => {
  const [delayTooltipOpen, setDelayTooltipOpen] = useState(false);
  const { canSetBeginDate, canSetDelayDays } = allowedSetting || {};
  const { headerText, summaryButtonLabel } = SCHEDULE_AUTOPAY_DICTIONARY[flow];

  return (
    <Fragment>
      <StyledScrollView showsVerticalScrollIndicator={false} stickyHeaderIndices={[0]}>
        <SecondaryHeader
          handleBack={() => navigation.goBack()}
          title={headerText}
        />
        <AddressHeader address={address} dueDate={dueDate} loanNumber={loanNumber} />
        <ContentContainer>
          <StyledDivider />
          <RowButton
            accessibilityName="amount"
            handlePress={() => navigation.navigate('AutopayAmount')}
            label="Amount"
            value={paymentAmount}
          />
          <StyledDivider />
          <RowButton
            accessibilityName="pay-from"
            handlePress={() => navigation.navigate('AutopaySource')}
            label="Pay From"
            noPaymentSource={noPaymentSource}
            value={payFromButtonValue}
          />
          <StyledDivider />
          <ConditionalRender
            Component={(
              <Fragment>
                <RowButton
                  accessibilityName="start-date"
                  error={beginDateError}
                  isDropDown
                  label="Start Date"
                  menuItems={availableBeginDates}
                  onChange={(value) => dispatchChangeInput({
                    field: 'beginDate',
                    form: 'account',
                    value,
                  })}
                  selected={selectedBeginDate}
                />
                <StyledDivider />
              </Fragment>
            )}
            shouldRender={!!canSetBeginDate}
          />
          <ConditionalRender
            Component={(
              <Fragment>
                <RowButton
                  accessibilityName="delay-days"
                  error={delayDaysError}
                  handleOpenTooltip={() => setDelayTooltipOpen(true)}
                  isDropDown
                  label="Delay Payment"
                  menuItems={availableDelayDays}
                  onChange={(value) => dispatchChangeInput({
                    field: 'delayDays',
                    form: 'account',
                    value,
                  })}
                  selected={selectedDelayDays}
                />
                <StyledDivider />
              </Fragment>
            )}
            shouldRender={!!canSetDelayDays}
          />
          <ConditionalRender
            Component={StyledBorderlessButton}
            propsToPassDown={{
              label: 'Unenroll from Autopay',
              onPress: () => dispatchSetIsModalOpen({ key: 'stop', value: true }),
            }}
            shouldRender={
              flow !== 'enable' &&
              !!requestStatus &&
              requestStatus !== 'Not_Available' &&
              requestStatus !== 'Not_Enabled'
            }
          />
          <InfoSectionContainer>
            <InfoSection
              customerServicePhoneNumber={customerServicePhoneNumber}
              data={INFO_COPY_DICT.setup}
            />
          </InfoSectionContainer>
        </ContentContainer>
        <PaymentSummary
          disabled={noPaymentSource}
          label={summaryButtonLabel}
          onPress={() => navigation.navigate('AutopayReview')}
          subText={summaryScheduledDateString}
          text={paymentAmount}
        />
        <ConditionalRender
          Component={(
            <Tooltip
              handleClose={() => setDelayTooltipOpen(false)}
              positionAbsolute={{ left: 'auto', top: 'auto' }}
              visible={delayTooltipOpen}
            >
              <DelayTooltip
                handleClose={() => setDelayTooltipOpen(false)}
                maxDelayDays={maxDelayDays}
              />
            </Tooltip>
          )}
          shouldRender={delayTooltipOpen}
        />
      </StyledScrollView>
      <ConditionalRender
        Component={PaymentModal}
        propsToPassDown={modalProps}
        shouldRender={!!modalProps && modalProps.isOpen}
      />
    </Fragment>
  );
};

SetupOverview.propTypes = {
  accountFormErrors: T.shape({ beginDate: T.string, delayDays: T.string }).isRequired,
  address: T.string.isRequired,
  allowedSetting: T.shape({
    canSetBeginDate: T.bool.isRequired,
    canSetDelayDays: T.bool.isRequired,
  }),
  availableBeginDates: T.array.isRequired,
  availableDelayDays: T.array.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchSetIsModalOpen: T.func,
  dueDate: T.string.isRequired,
  flow: T.string.isRequired,
  loanNumber: T.string.isRequired,
  maxDelayDays: T.number.isRequired,
  modalProps: T.object,
  navigation: T.object.isRequired,
  payFromButtonProps: T.object.isRequired,
  paymentAmount: T.string.isRequired,
  requestStatus: T.string,
  selectedBeginDate: T.string.isRequired,
  selectedDelayDays: T.number.isRequired,
  summaryScheduledDateString: T.string.isRequired,
};

export default SetupOverview;
