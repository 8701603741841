import React from 'react';
import { Image, Platform } from 'react-native';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/utils/languageDictionary/navigationMessages';

import { ConditionalRender } from '../../base_ui';

import {
  HeaderContainer,
  StyledAddress,
  StyledButton,
  StyledIconButton,
  StyledIconContainer,
} from './styledComponents';

const DrawerHeader = ({
  hasMultiLoans,
  logo: { image, type },
  navigation,
  selectedLoanAddress,
}) => {
  const { formatMessage } = useIntl();

  return (
    <HeaderContainer hasMultiLoans={hasMultiLoans}>
      <StyledIconContainer>
        <StyledIconButton
          aria-label="go to home page"
          Icon={(
            <ConditionalRender
              Component={(
                <img
                  alt="client logo"
                  src={`data:${type};base64,${image}`}
                  style={{ maxHeight: '100%', maxWidth: '100%' }}
                />
              )}
              FallbackComponent={(
                <Image
                  alt="client logo"
                  resizeMode="contain"
                  source={{ uri: `data:${type};base64,${image}` }}
                  style={{ height: '100%', resizeMode: 'contain', width: '100%' }}
                />
              )}
              shouldRender={Platform.OS === 'web'}
            />
          )}
          onPress={() => navigation.navigate('Payments')}
        />
      </StyledIconContainer>
      <StyledAddress>{selectedLoanAddress}</StyledAddress>
      <ConditionalRender
        Component={(
          <StyledButton
            label={formatMessage({ id: INTL_IDS.LOANS_BUTTON_LABEL_SELECTLOAN })}
            onPress={() => {
              navigation.navigate('SelectLoan');
              navigation.closeDrawer();
            }}
          />
        )}
        shouldRender={hasMultiLoans}
      />
    </HeaderContainer>
  );
};

DrawerHeader.propTypes = {
  hasMultiLoans: T.bool.isRequired,
  logo: T.object,
  navigation: T.object.isRequired,
  selectedLoanAddress: T.string.isRequired,
};

export default DrawerHeader;
