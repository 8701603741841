import { Platform } from 'react-native';
import styled from 'styled-components/native';

import { H1 } from '../Heading';
import { moderateScale } from '../../../utils/scaleHelpers';

/* Containers */

export const ArrowContainer = styled.View`
  width: ${moderateScale(30)}px;
`;

export const BaseContainer = styled.Pressable`
  align-items: center;
  border-color: transparent;
  border-radius: 50px;
  border-width: ${moderateScale(2)}px;
  height: ${moderateScale(32)}px;
  margin-top: ${moderateScale(5)}px;
  width: ${moderateScale(32)}px;
`;

export const DaysContainer = styled.View`
  border-bottom-width: ${moderateScale(1)}px;
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  flex-direction: row;
  height: ${moderateScale(30)}px;
  justify-content: space-around;
  margin: ${moderateScale(6)}px 0;
  width: 100%;
`;

export const HeaderContainer = styled.View`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const MarkedContainer = styled(BaseContainer)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const MonthContainer = styled.View`
  align-items: center;
  flex-direction: row;
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  justify-content: space-between;
`;

export const SelectedContainer = styled(BaseContainer)`
  background-color: ${({ theme: { colors: { primary } } }) => primary};
`;

/* Text */

export const BaseText = styled.Text`
  background-color: rgba(255, 255, 255, 0);
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  font-weight: 400;
  margin-top: ${Platform.OS === 'android' ? moderateScale(4) : moderateScale(6)}px;
`;

export const DisabledText = styled(BaseText)`
  color: ${({ theme: { colors: { disabledText } } }) => disabledText};
`;

export const MarkedText = styled(BaseText)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const MonthText = styled(H1)`
  font-size: ${({ theme: { fontSizes: { f9 } } }) => f9}px;
  margin: ${moderateScale(10)}px;
`;

export const SelectedText = styled(BaseText)`
  color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const DueText = styled(BaseText)`
  bottom: ${moderateScale(30)}px;
  color: ${({ theme: { colors: { primary } } }) => primary};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f2 } } }) => f2}px;
  position: absolute;
`;

export const WeekDayText = styled(BaseText)`
  color: ${({ theme: { colors: { accentGray } } }) => accentGray};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  margin-bottom: ${moderateScale(2)}px;
  margin-top: ${moderateScale(2)}px;
`;

/* Buttons */

export const NavigationButton = styled.TouchableOpacity`
  padding: ${moderateScale(10)}px;
`;
