/*
 * Add Escrow
 *
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeStep, resetStepState } from '@dmi/shared/redux/Mortgage/actions';
import makeSelectMortgage from '@dmi/shared/redux/Mortgage/selectors';

import AddEscrowSplash from '../../../components/Mortgage/AddEscrow/AddEscrowSplash';
import AsyncRender from '../../../components/AsyncRender';
import { StyledScrollView } from '../../../components/base_ui';
import { StyledSecondaryHeader } from './styledComponents';

const AddEscrowScreen = ({
  dispatchChangeStep,
  dispatchResetStepState,
  navigation,
  step,
}) => {
  useEffect(() => () => {
    dispatchChangeStep(1);
    dispatchResetStepState();
  }, [dispatchChangeStep, dispatchResetStepState]);

  const buttonPressHandler = (newStep = step, path) => {
    dispatchChangeStep(newStep);
    navigation.navigate(path);
  };

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={() => navigation.goBack()}
      />
      <StyledScrollView>
        <AsyncRender
          Component={AddEscrowSplash}
          error={false}
          loading={false}
          propsToPassDown={{
            buttonPressHandler,
            dispatchChangeStep,
            dispatchResetStepState,
            navigation,
            step,
          }}
        />
      </StyledScrollView>
    </Fragment>
  );
};

AddEscrowScreen.propTypes = {
  dispatchChangeStep: T.func.isRequired,
  dispatchResetStepState: T.func.isRequired,
  navigation: T.object.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({ step: makeSelectMortgage('step') });

const mapDispatchToProps = (dispatch) => ({
  /**
  * Reducer : Mortgage
  */
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchResetStepState: () => dispatch(resetStepState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(AddEscrowScreen);
