import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Platform } from 'react-native';

import reducer from '@dmi/shared/redux/Login/reducer';
import { getSsoType } from '@dmi/shared/redux/Main/selectors';

import { ConditionalRender } from '../../../components/base_ui';
import injectReducer from '../../../utils/injectReducer';
import SsoOidc from './SsoOidc';
import SsoSaml from './SsoSaml';

const LoginSsoView = ({ ssoType }) => (
  <ConditionalRender
    Component={<SsoOidc />}
    FallbackComponent={<SsoSaml />}
    shouldRender={ssoType === 'oidc' && Platform.OS === 'web'}
  />
);

LoginSsoView.propTypes = { ssoType: T.string.isRequired };

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  ssoType: getSsoType(),
});

const withConnect = connect(mapStateToProps, null);
const withReducer = injectReducer({ key: 'login', reducer });

export default compose(withConnect, withReducer)(LoginSsoView);
