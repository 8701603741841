import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P2 } from '../../../../base_ui';

export const StyledIconResize = styled.View`
  margin-bottom: ${moderateScale(6)}px;
`;

export const StyledMessageText = styled(P2)`
  padding-top: ${moderateScale(2)}px;
`;

export const StyledMessageView = styled.View`
  align-items: center;
  justify-content: center;
  margin-bottom:  ${moderateScale(10)}px;
  margin-top: ${moderateScale(2)}px;
  text-align: center;
`;
