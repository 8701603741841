import React, {
  useEffect,
  useRef,
  useState,
} from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { DOC_UPLOAD_STATUS_TITLE_DICT } from '@dmi/shared/redux/Main/constants';
import { setDismissedDocUploadStatusBanner } from '@dmi/shared/redux/Main/helpers';

import {
  BannerContainer,
  BannerHeading,
  Content,
  ContentBody,
  DismissButton,
  DismissibleBannerContainer,
  DismissibleContainer,
  HeadingContainer,
  IconWrapper,
  StyledExternalLink,
} from './styledComponents';
import {
  BaseInternalLink,
  ConditionalRender,
  P3,
} from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import { MAX_BANNER_TEXT_HEIGHT } from './constants';

const CloseXIcon = iconDictionary('close', { height: 16, width: 12 });
const WarningIcon = iconDictionary('paymentWarning');

const DismissibleDocUploadBanner = ({
  customerServicePhoneNumber,
  dispatchDismissDocUploadStatusBanner,
  docUploadNotificationFailure,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);
  const [showReadMore, setShowReadMore] = useState(false);
  const [layout, setLayout] = useState(null);

  const textRef = useRef(null);

  const { documentId, message } = docUploadNotificationFailure;

  const handleDismiss = () => {
    setIsDismissed(true);
    dispatchDismissDocUploadStatusBanner(true);
    setDismissedDocUploadStatusBanner(documentId);
  };

  useEffect(() => {
    // The layout state handles the timing between repainting and measuring
    if (textRef.current && layout) {
      textRef.current.measure((_x, _y, _width, height) => {
        if (height >= MAX_BANNER_TEXT_HEIGHT) {
          setShowReadMore(true);
        }
      });
    }
  }, [setShowReadMore, layout]);

  return (
    <BannerContainer>
      <DismissibleContainer $isDismissed={isDismissed}>
        <DismissibleBannerContainer>
          <HeadingContainer>
            <IconWrapper>{WarningIcon}</IconWrapper>
            <Content>
              <BannerHeading>
                <FormattedMessage id={DOC_UPLOAD_STATUS_TITLE_DICT.Failed} />
              </BannerHeading>
              <ContentBody>
                <P3
                  ref={textRef}
                  numberOfLines={showReadMore ? 2 : null}
                  onLayout={(event) => setLayout(event.nativeEvent.layout)}
                >
                  {message} For assistance, please contact customer service at {' '}
                  <StyledExternalLink
                    aria-label="call customer service"
                    href={`tel://${customerServicePhoneNumber.replace(/\+|-/g, '')}`}
                    label={customerServicePhoneNumber}
                    size="small"
                  />
                </P3>
                <ConditionalRender
                  Component={BaseInternalLink}
                  propsToPassDown={{
                    ariaLabel: 'go to notifications to read more',
                    label: 'Read More',
                    path: 'Notifications',
                    size: 'small',
                  }}
                  shouldRender={showReadMore}
                />
              </ContentBody>
            </Content>
          </HeadingContainer>
        </DismissibleBannerContainer>
        <DismissButton
          aria-label="banner dismiss button"
          onPress={handleDismiss}
          role="button"
          style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
        >
          {CloseXIcon}
        </DismissButton>
      </DismissibleContainer>
    </BannerContainer>
  );
};

DismissibleDocUploadBanner.propTypes = {
  customerServicePhoneNumber: T.string,
  dispatchDismissDocUploadStatusBanner: T.func.isRequired,
  docUploadNotificationFailure: T.object.isRequired,
};

export default DismissibleDocUploadBanner;
