import React from 'react';
import T from 'prop-types';

import { ConditionalRender, P3, Skeleton } from '../../../base_ui';
import { CardContainer, CardContent, CardText } from './styledComponents';
import { moderateScale } from '../../../../utils/scaleHelpers';

const Card = ({
  Icon,
  isLoading,
  label,
  value,
}) => (
  <CardContainer>
    {Icon}
    <CardContent>
      <P3>{label}</P3>
      <ConditionalRender
        Component={(
          <Skeleton
            $bottomMargin={`${moderateScale(3)}px`}
            $topMargin={`${moderateScale(3)}px`}
            height={`${moderateScale(26)}px`}
            width={`${moderateScale(161)}px`}
          />
        )}
        FallbackComponent={<CardText>{value}</CardText>}
        shouldRender={isLoading}
      />
    </CardContent>
  </CardContainer>
);

Card.propTypes = {
  Icon: T.object.isRequired,
  isLoading: T.bool.isRequired,
  label: T.string.isRequired,
  value: T.string.isRequired,
};

export default Card;
