/*
* Username/Password Form Input
*/

import React, { Fragment, useRef } from 'react';
import T from 'prop-types';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import {
  LargePrimaryButton,
  LargeProgressButton,
  LoginRegisterTextField,
  PasswordTextField,
} from '../../base_ui';
import {
  ButtonsContainer,
  RegisterInputContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const UsernamePasswordFormInput = ({
  dispatchChangeInput,
  formErrors,
  formValues,
  handleBack,
  handleNextClick,
  handleSubmit,
  isGuidedRegistration,
  status,
}) => {
  const passwordInputRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  const formKey = isGuidedRegistration ? 'guidedAccount' : 'account';
  const ButtonToRender = isGuidedRegistration ? LargeProgressButton : LargePrimaryButton;
  const propsToPassDown = isGuidedRegistration ? {
    label: 'Submit',
    onPress: () => handleNextClick({
      field: ['password', 'username', 'confirmPassword'],
      form: formKey,
      formValues: trimFormValues(formValues),
      handleSubmitStep: () => handleSubmit(2, trimFormValues(formValues)),
    }),
    status: status.guidedStepFour,
  } : {
    label: 'Next Step',
    onPress: () => handleNextClick({
      field: ['password', 'username', 'confirmPassword'],
      form: formKey,
      formValues: trimFormValues(formValues),
    }),
  };

  const handleDonePress = () => isGuidedRegistration
    ? handleNextClick({
      field: ['password', 'username', 'confirmPassword'],
      form: formKey,
      formValues: trimFormValues(formValues),
      handleSubmitStep: () => handleSubmit(2, trimFormValues(formValues)),
    })
    : handleNextClick({
      field: ['password', 'username', 'confirmPassword'],
      form: formKey,
      formValues: trimFormValues(formValues),
    });

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <LoginRegisterTextField
            error={formErrors.username}
            label="Username"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'username',
              form: formKey,
              value: newValue,
            })}
            onSubmitEditing={() => passwordInputRef.current.focus()}
            returnKeyType="next"
            value={formValues.username}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <PasswordTextField
            ref={passwordInputRef}
            error={formErrors.password}
            label="Password"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'password',
              form: formKey,
              value: newValue,
            })}
            onSubmitEditing={() => confirmPasswordRef.current.focus()}
            returnKeyType="next"
            value={formValues.password}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <PasswordTextField
            ref={confirmPasswordRef}
            error={formErrors.confirmPassword}
            label="Confirm Password"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'confirmPassword',
              form: formKey,
              value: newValue,
            })}
            onSubmitEditing={handleDonePress}
            returnKeyType="done"
            value={formValues.confirmPassword}
          />
        </TextFieldWrapper>
      </RegisterInputContainer>
      <StyledPrimaryDivider />
      <ButtonsContainer>
        <ButtonToRender
          disabled={
            !formValues.confirmPassword ||
            !formValues.password ||
            !formValues.username
          }
          {...propsToPassDown}
        />
        <StyledBaseInternalLink label="Back" onPressFunc={handleBack} size="medium" />
      </ButtonsContainer>
    </Fragment>
  );
};

UsernamePasswordFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  isGuidedRegistration: T.bool.isRequired,
  status: T.object.isRequired,
};

export default UsernamePasswordFormInput;
