import styled from 'styled-components/native';

import { H3, P2, P3 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const HistoryContainer = styled.View`
  padding: ${moderateScale(37)}px 0 ${moderateScale(28)}px;
  width: 100%;
`;

export const LeftP2 = styled(P2)`
  padding: ${moderateScale(13)}px ${moderateScale(24)}px ${moderateScale(28)}px;
  text-align: left;
`;

export const StyledH3 = styled(H3)`
  padding-left: ${moderateScale(9)}px;
`;

export const TitleWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 0 ${moderateScale(24)}px;
`;

// Payoff History Clickable Row

export const PressableRow = styled.Pressable`
  background-color: ${(
    ({
      $isPressing,
      theme: { colors: { accentSilver, dmiWhite } },
    }) => $isPressing ? accentSilver : dmiWhite
  )};
  flex-direction: row;
  margin-bottom: ${(moderateScale(8))}px;
  padding: ${(moderateScale(13))}px ${(moderateScale(24))}px;
`;

export const IconView = styled.View`
  margin-left: auto;
  width: 5%;
`;

export const RowText = styled(P3)`
  padding-right: ${moderateScale(18)}px;
  width: auto;
`;
