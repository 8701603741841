export const getTabNavigatorRouteName = (state) => {
  const { state: subRouteState } = state.routes[0];

  if (!subRouteState) {
    return '';
  }

  const { index, routeNames } = subRouteState;
  return routeNames[index];
};
