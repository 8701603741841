/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../scaleHelpers';

export default function Login(props) {
  return (
    <Svg fill="none" height={moderateScale(120)} viewBox="0 0 327 120" width={moderateScale(327)} x="0px" xmlns="http://www.w3.org/2000/svg" y="0px" {...props}>
      <Path d="M132.7,8c5.6-4.8,14.3-5.7,22.2-5.3c24.9,1.3,47.4,13,66.8,26.2c7,4.8,14,10,18.5,16.6c9.3,13.4,6.5,32-6.5,43 c-4.4,3.7-9.8,6.6-15.2,9.2c-9.8,4.5-20.3,8.1-31.3,9.1c-7.9,0.7-15.9,0.1-23.7-0.9c-21.9-2.9-43.4-9.4-61.4-20.3 c-7.9-4.8-15.3-10.8-18.6-18.5s-1.1-17.5,7-22.1c3.3-1.9,7.3-2.7,11.2-3.5c5.8-1.2,11.6-2.3,16.8-4.7c5.4-2.5,11.7-7.3,10.7-12.8 C128,17.9,127.1,12.8,132.7,8z" fill="#F2F5F7" />
      <Path d="M198.4,112.7l-60.9-0.1c-3.3,0-6-2.7-6-6l0.2-80c0-3.3,2.7-6,6-6l60.9,0.1c3.3,0,6,2.7,6,6l-0.2,80 C204.4,110,201.7,112.7,198.4,112.7z" fill="#E1E6EA" />
      <Path d="M195.2,116.2l-61.2-0.1c-3.3,0-6-2.7-6-6l0.2-80.4c0-3.3,2.7-6,6-6l61.2,0.1c3.3,0,6,2.7,6,6l-0.2,80.4 C201.2,113.6,198.5,116.2,195.2,116.2z" fill="#FFFFFF" />
      <Path d="M128.1,39l0-9.3c0-3.3,2.7-6,6-6l61.2,0.1c3.3,0,6,2.7,6,6l-0.2,80.4c0,3.3-2.7,6-6,6l-61.2-0.1 c-3.3,0-6-2.7-6-6l0.2-66.6" fill="none" stroke="#322B2C" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75" />
      <Line class="st3" x1="194.2" x2="194.2" y1="95.1" y2="99.9" />
      <Polyline fill="none" points="194.2,102.1 194.1,109.5 135,109.3 135.2,29.9 194.3,30.1 194.2,92.7" stroke="#322B2C" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75" />
      <Circle cx="164.8" cy="17.3" fill="none" r="3.3" stroke="#322B2C" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75" />
      <Path d="M183.5,30.3l-1.5-7.2c-0.3-1.6-1.7-2.7-3.3-2.7l-3.9,0c-1.6,0-2.9-1.3-3-2.9c0-0.5-0.1-0.9-0.2-1.4 c-0.8-2.8-3.3-5-6.2-5.2c-4.2-0.4-7.7,2.9-7.7,7.1v0c0,1.3-1.1,2.4-2.4,2.4l-4.2,0c-1.6,0-2.9,1.1-3.3,2.6l-1.7,7.2 c-0.5,2.1,1.1,4.2,3.3,4.2l30.8,0.1C182.3,34.3,183.9,32.3,183.5,30.3z M164.8,20.6c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3 s3.3,1.5,3.3,3.3C168.1,19.2,166.6,20.6,164.8,20.6z" fill="#EAEDF0" stroke="#010101" stroke-miterlimit="10" stroke-width="0.75" />
      <Path d="M148.4,49l1.3,0c1.7,0,3,1.3,3,3l0,1.3c0,1.7-1.3,3-3,3l-1.3,0c-1.7,0-3-1.3-3-3l0-1.3 C145.4,50.4,146.8,49,148.4,49z" fill="#DFD9E8" stroke="#322B2C" stroke-miterlimit="10" stroke-width="0.5" />
      <Line fill="#FFFFFF" stroke="#322B2C" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75" x1="157.4" x2="183.5" y1="52.7" y2="52.8" />
      <Path d="M148.4,66l1.3,0c1.7,0,3,1.3,3,3l0,1.3c0,1.7-1.3,3-3,3l-1.3,0c-1.7,0-3-1.3-3-3l0-1.3 C145.4,67.3,146.7,66,148.4,66z" fill="#DFD9E8" stroke="#322B2C" stroke-miterlimit="10" stroke-width="0.5" />
      <Line fill="#FFFFFF" stroke="#322B2C" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75" x1="157.4" x2="183.4" y1="69.7" y2="69.8" />
      <Path d="M148.4,83l1.3,0c1.7,0,3,1.3,3,3l0,1.3c0,1.7-1.3,3-3,3l-1.3,0c-1.7,0-3-1.3-3-3l0-1.3 C145.4,84.3,146.7,83,148.4,83z" fill="#DFD9E8" stroke="#322B2C" stroke-miterlimit="10" stroke-width="0.5" />
      <Line fill="#FFFFFF" stroke="#322B2C" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="0.75" x1="183.4" x2="157.3" y1="86.7" y2="86.7" />
      <G id="Layer_1_1_">
        <Rect fill="#FFFFFF" height="8" transform="matrix(0.4985 -0.8669 0.8669 0.4985 52.8304 206.4444)" width="48.7" x="180.5" y="53.5" />
        <Rect fill="#B9C1C9" height="8" transform="matrix(0.4985 -0.8669 0.8669 0.4985 36.9504 206.512)" width="17.3" x="188.3" y="67.3" />
        <Polygon fill="#FFFFFF" points="189.2,76.7 196.2,80.7 188.2,86.5 " />
        <Path d="M188.2,87c-0.1,0-0.2,0-0.2,0c-0.2-0.1-0.3-0.3-0.2-0.5l1-9.8c0-0.2,0.1-0.3,0.3-0.4c0.1-0.1,0.3-0.1,0.5,0 l7,4c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4l-8,5.7C188.4,86.9,188.3,87,188.2,87z M189.6,77.4l-0.8,8l6.5-4.7L189.6,77.4z " fill="#2C2627" />
        <Path d="M219.9,31l0.3,0.2c1.8,1.1,2.5,3.4,1.4,5.2l-1.1,2l0,0l-7-4l0,0l1.1-2C215.7,30.6,218,30,219.9,31z" fill="#B9C1C9" />
        <Path d="M220.4,30.8l-0.3-0.2c-2.1-1.2-4.7-0.5-5.9,1.6l-1.4,2.4l7.8,4.5l1.4-2.3c0,0,0,0,0-0.1 C223.2,34.6,222.5,32,220.4,30.8z M221.4,35.8l-1.1,2h0l-2.2-1.2l-4-2.3l0.8-1.5c0.9-1.6,3-2.2,4.6-1.3l0.3,0.2c0,0,0,0,0,0 c0.2,0.1,0.3,0.2,0.5,0.3v-0.1l0.3,0.3C221.7,33.1,222,34.6,221.4,35.8z" fill="#010101" />
        <Line fill="none" x1="197.8" x2="204.7" y1="61.8" y2="65.8" />
        <Path d="M204.7,66.3c-0.1,0-0.2,0-0.2,0l-7-4c-0.2-0.1-0.3-0.4-0.2-0.7c0.1-0.2,0.4-0.3,0.7-0.2l7,4 c0.2,0.1,0.3,0.4,0.2,0.6c0,0,0,0,0,0C205.1,66.2,204.9,66.3,204.7,66.3z" fill="#2C2627" />
        <Path d="M212,59.8c-0.1,0-0.2,0-0.2,0c-0.2-0.1-0.3-0.4-0.2-0.6c0,0,0,0,0,0l10.1-17.5l-2.2-1.3 c-0.2-0.1-0.3-0.4-0.2-0.7c0.1-0.2,0.4-0.3,0.7-0.2l2.6,1.5c0.2,0.1,0.3,0.4,0.2,0.7l-10.3,17.9C212.2,59.7,212.1,59.8,212,59.8z" fill="#2C2627" />
        <Path d="M196.2,81.2c-0.1,0-0.2,0-0.2-0.1l-7-4c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.3,0-0.4l24.3-42.2 c0.1-0.2,0.4-0.3,0.6-0.2c0,0,0,0,0,0l7,4c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0,0.2,0,0.4l-24.3,42.2C196.5,81.1,196.4,81.2,196.2,81.2 z M189.9,76.5l6.2,3.5l23.8-41.4l-6.2-3.5L189.9,76.5z" fill="#2C2627" />
        <Polygon fill="#DCE1E5" points="195.3,80.7 188.8,85.5 188.8,85.5 193,79.4 " />
        <Path d="M221.4,35.8l-1.1,2h0l0.9-1.6c0.8-1.4,0.5-3.2-0.8-4.2v-0.1l0.3,0.3C221.7,33.1,222,34.6,221.4,35.8z" fill="#010101" />
        <Path d="M221.2,36.2l-0.9,1.6l-2.2-1.2l2-3.4c0.2-0.4,0.3-0.8,0.3-1.2C221.6,33,222,34.8,221.2,36.2z" fill="#9BA5AC" />
        <Polygon fill="#DCE1E5" points="219.8,38.6 204.6,65.2 202.2,63.8 217.7,37.5 " />
        <Polygon fill="#9BA5AC" points="204.1,66 196,80 193.6,78.6 201.8,64.7 " />
      </G>
    </Svg>
  );
}
