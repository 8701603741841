/* eslint-disable react/jsx-sort-props */
/* eslint-disable max-len, no-tabs, no-lone-blocks */
import React from 'react';
import Svg, {
  Ellipse,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function UploadHouse(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      id="Layer_1"
      data-name="Layer 1"
      fill="#000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 327.5 120.5"
      height={moderateScale(120.5)}
      width={moderateScale(327.5)}
      {...props}
    >
      <Path d="M112.53,60.37a15,15,0,0,1-15-15A14.83,14.83,0,0,1,100.75,36a.37.37,0,0,1,.52-.06.38.38,0,0,1,.07.53,14.06,14.06,0,0,0-3.08,8.84,14.25,14.25,0,1,0,4.84-10.71.37.37,0,0,1-.49-.56,15,15,0,1,1,9.92,26.29Z" />
      <Path fill="#F8F9FA" className="cls-1" d="M111.89,58.55s50-35.08,73.7-42.52c30.08-9.42,47.78-2,61.72,5.87,10.87,6.11,44.23,48.46,79.93,55.7L327.11,114s-81.29-42.55-87.83-25.25-134.4-9.09-134.4-9.09Z" />
      <Path fill="#E6EBEF" className="cls-2" d="M124.21,68s14.08-8.38,31.9-20.66C180.23,30.7,212.39,9.46,237,16.14c0,0-27.2-11.93-64.11,4.3L111.33,58.78Z" />
      <Path fill="#E6EBEF" className="cls-2" d="M.5,84.71c4,1.06,24.33-14,28.76-17,9-6.06,10.51-11.08,40.15-16.3s42.82,6.26,49.66,10.7,35.5,29.53,35.5,29.53l-51.16,15.07L0,119.74S.12,84.6.5,84.71Z" />
      <Path d="M5.2,83.45a.37.37,0,0,1-.32-.17A.38.38,0,0,1,5,82.76L27.54,68.34a.38.38,0,0,1,.52.11.37.37,0,0,1-.12.52L5.4,83.39A.36.36,0,0,1,5.2,83.45Z" />
      <Path d="M151.88,89.64a.4.4,0,0,1-.25-.09c-8-7-27.3-23.61-32.77-27.16-.6-.39-1.26-.84-2-1.33-7.17-4.91-20.53-14-47.4-9.31-.25,0-23.1,2.73-39.69,16a.37.37,0,0,1-.52-.06.38.38,0,0,1,.05-.53C46.08,53.75,69.14,51,69.37,51c27.15-4.78,40.67,4.46,47.94,9.43.71.48,1.36.93,2,1.32C124.78,65.33,144.08,82,152.13,89a.38.38,0,0,1,0,.53A.39.39,0,0,1,151.88,89.64Z" />
      <Path fill="#E6EBEF" className="cls-2" d="M292.87,85.15h0c-2.74-17.73-6.6-16.94-6.6-16.94v0s-3.7-.81-6.45,16.92h0a11.41,11.41,0,0,0-.13,1.67c0,4.79,3,8.67,6.65,8.67S293,91.61,293,86.82A11.41,11.41,0,0,0,292.87,85.15Z" />
      <Path fill="#BAC2CA" className="cls-3" d="M293,86.82a11.41,11.41,0,0,0-.13-1.67h0c-2.74-17.73-6.6-16.94-6.6-16.94v0s.08-.13-.32.08a59.77,59.77,0,0,1,4.47,17.22c.52,6.3-1.62,9.21-4.12,10h.06C290,95.49,293,91.61,293,86.82Z" />
      <Path d="M286.35,95.86c-3.87,0-7-4-7-9a11.12,11.12,0,0,1,.11-1.62v-.11a84.68,84.68,0,0,1,1.79-8.81l.93-3c1.79-5,3.49-5.42,4-5.42h0a1.61,1.61,0,0,1,1.18.36c1.44,1,3.52,4.1,5.29,13.55a.37.37,0,1,1-.73.13c-1.82-9.68-3.9-12.34-5-13.06a1.28,1.28,0,0,0-.55-.24.35.35,0,0,1-.14,0l-.14,0c-.27,0-1.65.43-3.26,4.9l-.92,3a84,84,0,0,0-1.75,8.61v.1a11.25,11.25,0,0,0-.12,1.62c0,4.57,2.81,8.29,6.27,8.29s6.27-3.72,6.27-8.29a11.14,11.14,0,0,0-.12-1.62.37.37,0,0,1,.74-.11,11.29,11.29,0,0,1,.13,1.73C293.37,91.81,290.22,95.86,286.35,95.86Z" />
      <Path fill="#F8F9FA" className="cls-1" d="M28.22,71.52h0c-2.75-17.73-6.6-16.94-6.6-16.94v0s-3.7-.81-6.45,16.92h0a10.18,10.18,0,0,0-.13,1.67c0,4.79,3,8.66,6.65,8.66s6.64-3.87,6.64-8.66A11.42,11.42,0,0,0,28.22,71.52Z" />
      <Path fill="#BAC2CA" className="cls-3" d="M28.34,73.19a11.42,11.42,0,0,0-.12-1.67h0c-2.75-17.73-6.6-16.94-6.6-16.94v0s.08-.13-.32.08A60,60,0,0,1,25.76,71.9c.52,6.3-1.62,9.21-4.12,9.95h.06C25.37,81.85,28.34,78,28.34,73.19Z" />
      <Path d="M21.7,82.23c-3.88,0-7-4-7-9a11.14,11.14,0,0,1,.12-1.62.21.21,0,0,1,0-.11c.41-2.66.87-5.07,1.37-7.15a.37.37,0,0,1,.45-.27.38.38,0,0,1,.28.45c-.49,2-.94,4.38-1.34,7v.1a11.28,11.28,0,0,0-.13,1.62c0,4.57,2.82,8.29,6.28,8.29S28,77.76,28,73.19a11.25,11.25,0,0,0-.12-1.62.09.09,0,0,1,0,0C25.45,56.13,22.28,55,21.75,55a.32.32,0,0,1-.14,0l-.13,0c-.27,0-1.65.43-3.26,4.9l-.73,2.36a.38.38,0,0,1-.47.25.36.36,0,0,1-.24-.47l.73-2.38c1.79-5,3.48-5.41,4-5.41h0a1.64,1.64,0,0,1,1.23.38c1.64,1.14,4,5,5.82,16.86a.13.13,0,0,1,0,.06,11.83,11.83,0,0,1,.12,1.67C28.72,78.18,25.57,82.23,21.7,82.23Z" />
      <Path fill="#F8F9FA" className="cls-1" d="M.5,83.38c27.55.52,71,4.68,102,23.56l-57.42,8.8L.25,119.41Z" />
      <Path fill="#E6EBEF" className="cls-2" d="M2.46,83.47s59.74,10,78.39,27.26l22.56-4S78.37,87.38,2.46,83.47Z" />
      <Path fill="#CBD6E0" className="cls-4" d="M50.39,115.62s73.66-12,119-17.41c78.19-9.4,131.67,3.91,157.89,14.47l.39-1s-59.28-38.16-112.5-32.29c-31.56,3.48-65.21,14.91-97,23.58-21.85,6-42.67,10.21-62,11.25Z" />
      <Path d="M326.65,111.49a.39.39,0,0,1-.18,0l-3.94-2.19a.37.37,0,1,1,.36-.65c1.33.72,2.64,1.45,3.95,2.18a.37.37,0,0,1,.14.51A.39.39,0,0,1,326.65,111.49Z" />
      <Path d="M45.48,116.08a.39.39,0,0,1-.38-.34.38.38,0,0,1,.34-.41c31-3.47,55.93-7.84,74.08-13,8.71-2.48,16.65-4.88,24.32-7.21,26.39-8,47.24-14.32,76.08-16.54,37-2.85,73.14,13.84,100.54,28.7a.37.37,0,1,1-.35.66C292.79,93.13,256.71,76.49,220,79.33c-28.76,2.21-49.57,8.52-75.92,16.51-7.68,2.33-15.61,4.73-24.33,7.21-18.19,5.17-43.16,9.56-74.21,13Z" />
      <Path d="M13.52,119.1a.38.38,0,0,1,0-.75c9.06-.7,18.2-1.54,27.18-2.5a.38.38,0,0,1,.08.75c-9,1-18.14,1.79-27.2,2.5Z" />
      <Path d="M1,120a.37.37,0,0,1-.37-.35.37.37,0,0,1,.35-.4s2.86-.16,7.79-.52a.38.38,0,0,1,.4.35.36.36,0,0,1-.34.4C4,119.81,1.07,120,1,120Z" />
      <Path fill="#FFF" className="cls-5" d="M189.42,52,177,66.75h2.66V90.21h73.16V66.75h4.32L243.62,52S189.42,51.85,189.42,52Z" />
      <Polyline fill="#E6EBEF" className="cls-2" points="202.08 90.01 202.08 61.88 212.6 61.88 212.6 90.01" />
      <Polyline fill="#E6EBEF" className="cls-2" points="233.5 90.01 233.5 61.88 243.62 52.22 253.01 61.88 253.01 90.01" />
      <Rect fill="#BAC2CA" className="cls-3" x="218.59" y="72.03" width="7.72" height="11.55" />
      <Path fill={svgHighlight} className="cls-6" d="M190.49,71.77h0A4.47,4.47,0,0,1,195,76.24V89.92a.11.11,0,0,1-.11.11h-8.71a.11.11,0,0,1-.11-.11V76.24A4.47,4.47,0,0,1,190.49,71.77Z" />
      <Path d="M195,90.36a.33.33,0,0,1-.33-.33V76.12a4,4,0,0,0-4-4h-.23a4,4,0,0,0-4,4V90a.32.32,0,0,1-.32.33.33.33,0,0,1-.33-.33V76.12a4.69,4.69,0,0,1,4.68-4.68h.23a4.69,4.69,0,0,1,4.68,4.68V90A.32.32,0,0,1,195,90.36Z" />
      <Polygon fill={svgHighlight} className="cls-6" points="229.45 67.1 212.67 67.1 199.1 51.73 243.65 51.73 229.45 67.1" />
      <Path d="M229.45,67.48H212.67a.37.37,0,0,1-.28-.13L198.64,52a.39.39,0,0,1-.06-.4.38.38,0,0,1,.34-.23h24.29a.38.38,0,0,1,.38.38.37.37,0,0,1-.38.37H199.76l13.07,14.63h16.45L242.8,52.1H226.3a.36.36,0,0,1-.37-.37.37.37,0,0,1,.37-.38h17.35a.38.38,0,0,1,.35.23.37.37,0,0,1-.07.4L229.72,67.36A.4.4,0,0,1,229.45,67.48Z" />
      <Polygon fill={svgHighlight} className="cls-7" points="203.93 67.1 212.78 67.1 198.57 51.73 189.72 51.73 203.93 67.1" />
      <Path d="M212.67,67.48h-8.75a.4.4,0,0,1-.27-.12L189.44,52a.38.38,0,0,1,.28-.63H199a.37.37,0,0,1,.28.13L213,66.85a.38.38,0,0,1-.28.63Zm-8.58-.75h7.74l-13-14.63h-8.22" />
      <Path d="M257.13,67.48h-2.71a.38.38,0,0,1-.38-.38.37.37,0,0,1,.38-.37h1.88L243.39,52a.37.37,0,0,1,0-.53.38.38,0,0,1,.53,0l13.46,15.37a.39.39,0,0,1,.06.41A.38.38,0,0,1,257.13,67.48Z" />
      <Path d="M178.91,67.48H176.2a.38.38,0,0,1-.34-.22.39.39,0,0,1,.06-.41l13.46-15.37a.38.38,0,1,1,.56.5L177,66.73h1.89a.37.37,0,0,1,.38.37A.38.38,0,0,1,178.91,67.48Z" />
      <Path d="M253.12,90.65H212.35a.38.38,0,0,1-.38-.38V67.62a.38.38,0,0,1,.38-.38.37.37,0,0,1,.37.38V89.9h40V62.64a.38.38,0,0,1,.75,0V90.27A.38.38,0,0,1,253.12,90.65Z" />
      <Path d="M233.66,90.2a.25.25,0,0,1-.25-.25V82.7a.25.25,0,0,1,.5,0V90A.25.25,0,0,1,233.66,90.2Z" />
      <Path d="M233.66,80.06a.25.25,0,0,1-.25-.25V65.58a.25.25,0,0,1,.5,0V79.81A.25.25,0,0,1,233.66,80.06Z" />
      <Path d="M202.08,88.24a.25.25,0,0,1-.25-.25V65.58a.25.25,0,0,1,.5,0V88A.25.25,0,0,1,202.08,88.24Z" />
      <Path d="M226.46,83.9h-8.08a.25.25,0,0,1-.25-.25V72a.25.25,0,0,1,.25-.25h8.08a.25.25,0,0,1,.25.25v11.7A.25.25,0,0,1,226.46,83.9Zm-7.83-.5h7.58V72.2h-7.58Z" />
      <Ellipse fill="#E6EBEF" className="cls-2" cx="190.32" cy="63.55" rx="1.77" ry="2.55" />
      <Path d="M190.32,66.43c-1.15,0-2.09-1.3-2.09-2.88s.94-2.88,2.09-2.88,2.09,1.29,2.09,2.88S191.47,66.43,190.32,66.43Zm0-5.11c-.78,0-1.44,1-1.44,2.23s.66,2.23,1.44,2.23,1.44-1,1.44-2.23S191.1,61.32,190.32,61.32Z" />
      <Path d="M190.32,66.28c-1.07,0-1.94-1.23-1.94-2.73s.87-2.73,1.94-2.73,1.94,1.22,1.94,2.73S191.39,66.28,190.32,66.28Zm0-5.11c-.88,0-1.59,1.07-1.59,2.38s.71,2.38,1.59,2.38,1.59-1.07,1.59-2.38S191.2,61.17,190.32,61.17Z" />
      <Path d="M255.53,90.68h-78a.37.37,0,0,1-.38-.37.38.38,0,0,1,.38-.38h78a.38.38,0,0,1,.38.38A.37.37,0,0,1,255.53,90.68Z" />
      <Path d="M179.79,90.38a.38.38,0,0,1-.37-.37V64.64a.38.38,0,0,1,.75,0V90A.38.38,0,0,1,179.79,90.38Z" />
      <Path fill="#F8F9FA" className="cls-1" d="M11.27,76.51h0c-1.84-11.83-4.41-11.3-4.41-11.3v0s-2.47-.54-4.3,11.29h0a7,7,0,0,0-.09,1.12c0,3.2,2,5.79,4.44,5.79s4.44-2.59,4.44-5.79A7,7,0,0,0,11.27,76.51Z" />
      <Path fill="#BAC2CA" className="cls-3" d="M11.36,77.63a7,7,0,0,0-.09-1.12h0c-1.84-11.83-4.41-11.3-4.41-11.3v0s0-.09-.21,0a40.12,40.12,0,0,1,3,11.5C10,81,8.55,82.92,6.88,83.41h0C9.37,83.42,11.36,80.83,11.36,77.63Z" />
      <Path d="M6.92,83.79c-2.65,0-4.81-2.76-4.81-6.16a7.83,7.83,0,0,1,.07-1.08v-.09a58.37,58.37,0,0,1,1.2-5.9h0l.62-2c1.21-3.37,2.37-3.74,2.8-3.7a1.24,1.24,0,0,1,.9.28c1.11.77,2.7,3.37,3.94,11.34v.05a7,7,0,0,1,.08,1.12C11.73,81,9.57,83.79,6.92,83.79Zm-4-7.3v.08a6.31,6.31,0,0,0-.08,1.06c0,3,1.82,5.41,4.06,5.41S11,80.61,11,77.63a6.39,6.39,0,0,0-.08-1.06v0C9.35,66.54,7.32,65.66,7,65.59H6.75c-.27,0-1.12.66-2,3.19l-.62,2A56.27,56.27,0,0,0,2.94,76.49Z" />
      <Path d="M102.52,107.32a.45.45,0,0,1-.19,0C88.66,99,71.27,92.72,50.64,88.74a.37.37,0,0,1-.29-.44.36.36,0,0,1,.44-.29C71.5,92,89,98.27,102.72,106.62a.38.38,0,0,1,.12.52A.36.36,0,0,1,102.52,107.32Z" />
      <Path d="M47.53,88.16h-.06a300.32,300.32,0,0,0-47-4.39.38.38,0,0,1-.37-.38A.41.41,0,0,1,.51,83a300.54,300.54,0,0,1,47.09,4.4.38.38,0,0,1,.3.44A.38.38,0,0,1,47.53,88.16Z" />
      <Path d="M322.74,76.73h-.1c-23.07-6-39.34-21.49-55.07-36.43-6.51-6.19-12.67-12-19.15-17.07a.36.36,0,0,1-.07-.52.38.38,0,0,1,.53-.07c6.51,5.06,12.68,10.92,19.21,17.12C283.75,54.62,300,70,322.83,76a.38.38,0,0,1,.27.46A.36.36,0,0,1,322.74,76.73Z" />
      <Path d="M113.46,57.88a.39.39,0,0,1-.32-.16.38.38,0,0,1,.11-.52c.51-.34,51.5-33.88,62.67-38.39,25.83-10.42,52.47-9.91,69.54,1.33a.37.37,0,0,1,.1.52.38.38,0,0,1-.52.11c-16.85-11.11-43.23-11.59-68.83-1.26-11.11,4.48-62,38-62.55,38.31A.36.36,0,0,1,113.46,57.88Z" />
      <Path fill="#E6EBEF" className="cls-2" d="M277.24,70.33h0c-3.27-19.76-7.86-18.87-7.86-18.87v0s-4.41-.9-7.68,18.86h0a11.3,11.3,0,0,0-.15,1.86c0,5.33,3.54,9.65,7.91,9.65s7.92-4.32,7.92-9.65A11.3,11.3,0,0,0,277.24,70.33Z" />
      <Path fill="#BAC2CA" className="cls-3" d="M277.39,72.19a11.3,11.3,0,0,0-.15-1.86h0c-3.27-19.76-7.86-18.87-7.86-18.87v0s.09-.14-.38.09a63.51,63.51,0,0,1,5.32,19.19c.62,7-1.93,10.27-4.91,11.09h.07C273.85,81.84,277.39,77.52,277.39,72.19Z" />
      <Path d="M269.47,82.22c-4.57,0-8.29-4.5-8.29-10a12.47,12.47,0,0,1,.14-1.81.24.24,0,0,1,0-.12,87.19,87.19,0,0,1,2.13-9.81.37.37,0,0,1,.72.2,87.22,87.22,0,0,0-2.09,9.63v.1a11.24,11.24,0,0,0-.15,1.81c0,5.12,3.38,9.28,7.54,9.28S277,77.31,277,72.19a10.92,10.92,0,0,0-.14-1.8.2.2,0,0,1,0-.07C274,53,270.14,51.89,269.52,51.83a.32.32,0,0,1-.14,0h0a.39.39,0,0,1-.15,0c-.34,0-2,.44-4,5.5a.38.38,0,0,1-.49.22.38.38,0,0,1-.21-.49c2.15-5.61,4.14-6,4.73-6h0a2,2,0,0,1,1.49.48c1.92,1.34,4.66,5.66,6.81,18.69a.2.2,0,0,1,0,.07,11.74,11.74,0,0,1,.14,1.86C277.76,77.72,274.05,82.22,269.47,82.22Z" />
      <Path d="M269.37,91.25a.37.37,0,0,1-.37-.37V69.64a.38.38,0,1,1,.75,0V90.88A.37.37,0,0,1,269.37,91.25Z" />
      <Path d="M269.29,76.44a.44.44,0,0,1-.22-.07l-2.89-2.06a.38.38,0,0,1-.09-.53.39.39,0,0,1,.53-.08l2.89,2.06a.38.38,0,0,1,.08.52A.37.37,0,0,1,269.29,76.44Z" />
      <Path d="M269.45,78.85a.36.36,0,0,1-.31-.16.37.37,0,0,1,.09-.52l2.89-2.06a.37.37,0,0,1,.43.61l-2.89,2.06A.33.33,0,0,1,269.45,78.85Z" />
      <Path fill="#F8F9FA" className="cls-1" d="M101.54,82.06h0c-3.27-19.75-7.86-18.87-7.86-18.87v0s-4.4-.9-7.68,18.85h0a11.51,11.51,0,0,0-.15,1.87c0,5.33,3.55,9.65,7.92,9.65s7.91-4.32,7.91-9.65A11.51,11.51,0,0,0,101.54,82.06Z" />
      <Path fill="#BAC2CA" className="cls-3" d="M101.69,83.93a11.51,11.51,0,0,0-.15-1.87h0c-3.27-19.75-7.86-18.87-7.86-18.87v0s.1-.14-.38.09a63.22,63.22,0,0,1,5.32,19.19c.62,7-1.93,10.27-4.9,11.09h.07C98.15,93.58,101.69,89.26,101.69,83.93Z" />
      <Path d="M93.78,94c-4.57,0-8.29-4.5-8.29-10a12.24,12.24,0,0,1,.13-1.81V82a90.21,90.21,0,0,1,2.13-9.82.38.38,0,0,1,.47-.25.36.36,0,0,1,.25.46A87.22,87.22,0,0,0,86.38,82a.17.17,0,0,1,0,.1,12.36,12.36,0,0,0-.14,1.81c0,5.12,3.38,9.28,7.54,9.28s7.54-4.16,7.54-9.28a11.14,11.14,0,0,0-.15-1.81v-.06c-2.88-17.33-6.72-18.43-7.34-18.49a.4.4,0,0,1-.15,0l-.14,0c-.34,0-2,.44-4,5.5a.38.38,0,1,1-.7-.27c2.16-5.61,4.18-6,4.73-6h0a2,2,0,0,1,1.5.48C97,64.65,99.76,69,101.92,82v.07a11.76,11.76,0,0,1,.15,1.86C102.07,89.46,98.35,94,93.78,94Z" />
      <Path d="M93.68,102.49a.37.37,0,0,1-.38-.37V81.37a.38.38,0,0,1,.38-.37.37.37,0,0,1,.37.37v20.75A.36.36,0,0,1,93.68,102.49Z" />
      <Path d="M93.59,88.17a.39.39,0,0,1-.21-.06l-2.89-2.06a.38.38,0,0,1-.09-.53.38.38,0,0,1,.52-.08l2.89,2a.38.38,0,0,1,.09.53A.37.37,0,0,1,93.59,88.17Z" />
      <Path d="M93.75,90.59a.37.37,0,0,1-.3-.16.38.38,0,0,1,.08-.52l2.89-2.06a.38.38,0,0,1,.53.09.37.37,0,0,1-.09.52L94,90.52A.36.36,0,0,1,93.75,90.59Z" />
      <Path d="M274.52,92.25h-.07A38,38,0,0,0,265.14,91a.25.25,0,0,1,0-.5,38.69,38.69,0,0,1,9.46,1.28.25.25,0,0,1-.08.49Z" />
      <Rect fill="#E6EBEF" className="cls-2" x="154.89" y="60.77" width="11.7" height="24.22" rx="5.85" />
      <Path fill="#BAC2CA" className="cls-3" d="M163.47,61.45a5.78,5.78,0,0,1,1.11,3.42V77.39a5.85,5.85,0,0,1-5.85,5.85h0a5.75,5.75,0,0,1-2.73-.68,5.85,5.85,0,0,0,10.59-3.42V66.62A5.86,5.86,0,0,0,163.47,61.45Z" />
      <Path d="M160.74,85.37a6.23,6.23,0,0,1-6.22-6.23V66.62a6.22,6.22,0,0,1,12.44,0V79.14A6.23,6.23,0,0,1,160.74,85.37Zm0-24.22a5.47,5.47,0,0,0-5.47,5.47V79.14a5.47,5.47,0,1,0,10.94,0V66.62A5.47,5.47,0,0,0,160.74,61.15Z" />
      <Path d="M160.65,90.62a.37.37,0,0,1-.37-.37V71.48a.38.38,0,1,1,.75,0V90.25A.37.37,0,0,1,160.65,90.62Z" />
      <Path d="M160.58,79.2a.33.33,0,0,1-.21-.07l-2.89-2.06a.37.37,0,1,1,.43-.61l2.89,2.06a.37.37,0,0,1,.09.52A.36.36,0,0,1,160.58,79.2Z" />
      <Rect fill="#E6EBEF" className="cls-2" x="311.22" y="69.77" width="11.7" height="24.22" rx="5.85" />
      <Path fill="#BAC2CA" className="cls-3" d="M319.79,70.45a5.79,5.79,0,0,1,1.12,3.42V86.39a5.85,5.85,0,0,1-5.85,5.85h0a5.69,5.69,0,0,1-2.73-.68,5.84,5.84,0,0,0,10.58-3.42V75.62A5.84,5.84,0,0,0,319.79,70.45Z" />
      <Path d="M317.07,94.37a6.23,6.23,0,0,1-6.23-6.23V75.62a6.23,6.23,0,0,1,12.45,0V88.14A6.23,6.23,0,0,1,317.07,94.37Zm0-24.22a5.48,5.48,0,0,0-5.48,5.47V88.14a5.48,5.48,0,1,0,11,0V75.62A5.48,5.48,0,0,0,317.07,70.15Z" />
      <Path d="M317,106a.37.37,0,0,1-.37-.38V80.48a.37.37,0,0,1,.37-.38.38.38,0,0,1,.38.38v25.16A.38.38,0,0,1,317,106Z" />
      <Path d="M316.91,88.2a.36.36,0,0,1-.22-.07l-2.89-2.06a.37.37,0,0,1-.09-.52.38.38,0,0,1,.53-.09l2.89,2.06a.37.37,0,0,1,.08.52A.34.34,0,0,1,316.91,88.2Z" />
      <Rect fill="#E6EBEF" className="cls-2" x="66.2" y="62.99" width="12.37" height="25.62" rx="6.19" />
      <Path fill="#BAC2CA" className="cls-3" d="M75.27,63.71a6.15,6.15,0,0,1,1.17,3.61V80.57a6.18,6.18,0,0,1-6.18,6.18h0A6.21,6.21,0,0,1,67.37,86a6.19,6.19,0,0,0,11.2-3.62V69.17A6.17,6.17,0,0,0,75.27,63.71Z" />
      <Path d="M72.38,89a6.57,6.57,0,0,1-6.56-6.56V69.17a6.56,6.56,0,0,1,13.12,0V82.42A6.57,6.57,0,0,1,72.38,89Zm0-25.62a5.82,5.82,0,0,0-5.81,5.81V82.42a5.81,5.81,0,1,0,11.62,0V69.17A5.82,5.82,0,0,0,72.38,63.36Z" />
      <Path d="M72.29,94.54a.37.37,0,0,1-.37-.38V74.31a.37.37,0,0,1,.37-.38.38.38,0,0,1,.38.38V94.16A.38.38,0,0,1,72.29,94.54Z" />
      <Path d="M72.21,82.46a.33.33,0,0,1-.21-.07l-3.06-2.18a.37.37,0,0,1-.09-.52.38.38,0,0,1,.53-.09l3.05,2.18a.37.37,0,0,1,.09.52A.38.38,0,0,1,72.21,82.46Z" />
      <Rect fill="#E6EBEF" className="cls-2" x="136.15" y="72.35" width="11.1" height="15.98" rx="5.55" />
      <Path fill="#BAC2CA" className="cls-3" d="M144.29,73a5.5,5.5,0,0,1,1.05,3.24v4.89a5.55,5.55,0,0,1-5.55,5.55h0A5.49,5.49,0,0,1,137.2,86a5.55,5.55,0,0,0,10.05-3.24V77.9A5.53,5.53,0,0,0,144.29,73Z" />
      <Path d="M141.7,88.71a5.93,5.93,0,0,1-5.93-5.92V77.9a5.93,5.93,0,0,1,11.85,0v4.89A5.93,5.93,0,0,1,141.7,88.71Zm0-16a5.18,5.18,0,0,0-5.18,5.17v4.89a5.18,5.18,0,0,0,10.35,0V77.9A5.18,5.18,0,0,0,141.7,72.73Z" />
      <Path d="M141.62,96.52a.38.38,0,0,1-.38-.38V77.76a.38.38,0,0,1,.75,0V96.14A.38.38,0,0,1,141.62,96.52Z" />
      <Path d="M141.55,82.86a.36.36,0,0,1-.22-.07l-2.74-2a.37.37,0,1,1,.43-.61l2.74,2a.38.38,0,0,1,.09.53A.37.37,0,0,1,141.55,82.86Z" />
      <Path fill="#D9E1E8" className="cls-8" d="M55.92,40.57a2.38,2.38,0,0,0,2.2-3.28c-1.34-3.32-4.17-8-9.55-8.6a9.3,9.3,0,0,0-6,1.77.38.38,0,0,1-.47,0,6.8,6.8,0,0,0-4.63-1.73c-3.45,0-6.25,2.36-6.25,5.27h0a.39.39,0,0,1-.42.39,4.36,4.36,0,0,0-.51,0,4.81,4.81,0,0,0-4.56,2.77.38.38,0,0,1-.5.21,4.37,4.37,0,0,0-1.88-.39,4,4,0,0,0-3.19,1.78,1.18,1.18,0,0,0,.95,1.88Z" />
      <Path fill="#D9E1E8" className="cls-8" d="M309.67,25a1.12,1.12,0,0,0,.84-1.84,7.43,7.43,0,0,0-5.53-2,10.6,10.6,0,0,0-1.15.07.69.69,0,0,1-.76-.69h0c0-2.09-2.65-3.79-5.92-3.79a8.56,8.56,0,0,0-2.54.37.66.66,0,0,1-.83-.48c-.68-3.11-4-5.47-8-5.47-4.51,0-8.17,3-8.17,6.66,0,.13,0,.26,0,.39a3.68,3.68,0,0,0-.58-.05,3.79,3.79,0,0,0-3.6,2.63.67.67,0,0,1-.82.44,3.88,3.88,0,0,0-1-.14,3.81,3.81,0,0,0-3,1.43A1.55,1.55,0,0,0,269.81,25Z" />
      <Path fill="#D9E1E8" className="cls-8" d="M136.49,14.8a.72.72,0,0,1-.54-1.19,4.8,4.8,0,0,1,3.59-1.33,6,6,0,0,1,.74,0,.45.45,0,0,0,.5-.45h0c0-1.36,1.72-2.47,3.84-2.47a5.89,5.89,0,0,1,1.66.24.43.43,0,0,0,.54-.3c.44-2,2.61-3.56,5.22-3.56,2.93,0,5.31,1.94,5.31,4.33,0,.08,0,.17,0,.25a2.31,2.31,0,0,1,.37,0A2.47,2.47,0,0,1,160.06,12a.42.42,0,0,0,.53.28,2.41,2.41,0,0,1,.67-.09,2.45,2.45,0,0,1,1.93.93,1,1,0,0,1-.8,1.64Z" />
      <Path d="M112.35,27.11a.25.25,0,0,1-.25-.25V22.62a.25.25,0,0,1,.5,0v4.24A.25.25,0,0,1,112.35,27.11Z" />
      <Path d="M93,46.58H88.77a.25.25,0,0,1-.25-.25.25.25,0,0,1,.25-.25H93a.25.25,0,0,1,.25.25A.25.25,0,0,1,93,46.58Z" />
      <Path d="M98.48,33.36a.23.23,0,0,1-.17-.07l-3-3a.25.25,0,0,1,.35-.35l3,3a.25.25,0,0,1-.18.42Z" />
      <Path d="M124.91,33.36a.25.25,0,0,1-.18-.42l3-3a.25.25,0,0,1,.35.35l-3,3A.26.26,0,0,1,124.91,33.36Z" />
      <Path d="M129,39.47a.24.24,0,0,1-.23-.15.25.25,0,0,1,.13-.33l3.93-1.61a.25.25,0,0,1,.19.46l-3.93,1.62Z" />
      <Path d="M118.9,29.33a.16.16,0,0,1-.1,0,.23.23,0,0,1-.13-.32l1.63-3.91a.25.25,0,0,1,.33-.14.26.26,0,0,1,.13.33l-1.63,3.91A.25.25,0,0,1,118.9,29.33Z" />
      <Path d="M104.36,29.39a.26.26,0,0,1-.23-.15l-1.66-3.9a.25.25,0,0,1,.13-.33.25.25,0,0,1,.33.13l1.66,3.9a.24.24,0,0,1-.13.33Z" />
      <Path d="M94.36,39.61l-.09,0L90.33,38a.25.25,0,0,1-.14-.33.26.26,0,0,1,.33-.14l3.93,1.59a.24.24,0,0,1,.14.32A.25.25,0,0,1,94.36,39.61Z" />
      <Path d="M21.63,84.75a.36.36,0,0,1-.37-.37V67.94a.37.37,0,0,1,.37-.37.38.38,0,0,1,.38.37V84.38A.37.37,0,0,1,21.63,84.75Z" />
    </Svg>
  );
}
