import React, { Fragment } from 'react';

import { moderateScale } from '../../../utils/scaleHelpers';
import { Skeleton } from '../../base_ui';
import { SkeletonRowView, SkeletonView } from './styledComponents';

const SkeletonArray = [...Array(5).keys()];

const SkeletonTable = () => SkeletonArray.map((num) => (
  <Fragment key={num}>
    <SkeletonRowView>
      <SkeletonView $widthPercent={74}>
        <Skeleton
          $bottomMargin={`${moderateScale(8)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(160)}px`}
        />
        <Skeleton
          $bottomMargin={`${moderateScale(4)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(200)}px`}
        />
      </SkeletonView>
      <SkeletonView $widthPercent={26}>
        <Skeleton
          $bottomMargin={`${moderateScale(8)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(60)}px`}
        />
      </SkeletonView>
    </SkeletonRowView>
    <SkeletonRowView>
      <SkeletonView $widthPercent={100}>
        <Skeleton
          $bottomMargin={`${moderateScale(0)}px`}
          // $rightMargin={`${moderateScale(24)}px`}
          height={`${moderateScale(1)}px`}
          width={`${moderateScale(320)}px`}
        />
      </SkeletonView>
    </SkeletonRowView>
  </Fragment>
));

export default SkeletonTable;
