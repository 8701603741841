import React from 'react';
import T from 'prop-types';

import { PROFILE_TRANSFER_ERROR_PAGE_COPY } from '@dmi/shared/redux/GoldRedirect/constants';

import viewDictionary from './viewDictionary';

const ProfileTransfer = ({
  dispatchRedirectToPaymentsPage,
  profileTransferStep,
  ...restProps
}) => {
  const { isUsernameRequired } = restProps;
  const confirmationProps = { dispatchRedirectToPaymentsPage, isUsernameRequired };
  const propsToPassDownMap = {
    1: restProps,
    2: confirmationProps,
    3: {},
    4: { ...PROFILE_TRANSFER_ERROR_PAGE_COPY },
  };
  const propsToPassDown = propsToPassDownMap[profileTransferStep];
  const { Component } = viewDictionary[profileTransferStep];

  return (
    <Component {...propsToPassDown} />
  );
};

ProfileTransfer.propTypes = {
  dispatchRedirectToPaymentsPage: T.func.isRequired,
  profileTransferStep: T.number.isRequired,
};

export default ProfileTransfer;
