import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeScheduledDate,
  changeScheduledDateOnDeck,
  setIsModalOpen,
} from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import {
  selectDisableDateSelectButton,
  selectFormattedAvailableDates,
  selectIsScheduledDateFormAfterGracePeriod,
  selectMaxDelayDays,
  selectMobilePickerDates,
  selectOtp,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import PaymentDate from '../../../components/Payments/OneTimePayment/PaymentDate';
import { CustomKeyboardAvoidingView } from '../../../components/base_ui';

const PaymentDateScreen = ({
  availableDates,
  disableSelect,
  dispatchChangeInput,
  dispatchChangeScheduledDate,
  dispatchChangeScheduledDateOnDeck,
  dispatchSetIsModalOpen,
  isAfterGracePeriod,
  isScheduledDateFormAfterGracePeriod,
  maxDelayDays,
  navigation,
  payType,
  pickerDates: { dueDate, scheduledDateForm, scheduledDateOtp },
}) => {
  useEffect(() => () => {
    dispatchChangeInput({ field: 'scheduledDate', form: 'account', value: '' });
  }, [dispatchChangeInput]);

  const handleDatePress = ({ dateString }) => {
    dispatchChangeInput({
      field: 'scheduledDate',
      form: 'account',
      value:
        ![scheduledDateForm, scheduledDateOtp].includes(dateString) ?
          dateString : '',
    });
  };

  const handleSelectDate = () => {
    if (
      payType === 'Monthly'
      && isScheduledDateFormAfterGracePeriod
      && !isAfterGracePeriod
    ) {
      dispatchChangeScheduledDateOnDeck(scheduledDateForm);
      dispatchSetIsModalOpen({ key: 'latePaymentWarning', value: true });
    } else {
      dispatchChangeScheduledDate(scheduledDateForm);
    }

    navigation.goBack();
  };

  return (
    <CustomKeyboardAvoidingView>
      <PaymentDate
        availableDates={availableDates}
        disableSelect={disableSelect}
        dueDate={dueDate}
        handleDatePress={handleDatePress}
        handleSelectDate={handleSelectDate}
        maxDelayDays={maxDelayDays}
        navigation={navigation}
        scheduledDateForm={scheduledDateForm}
        scheduledDateOtp={scheduledDateOtp}
      />
    </CustomKeyboardAvoidingView>
  );
};

PaymentDateScreen.propTypes = {
  availableDates: T.object.isRequired,
  disableSelect: T.bool.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeScheduledDate: T.func.isRequired,
  dispatchChangeScheduledDateOnDeck: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  isAfterGracePeriod: T.bool.isRequired,
  isScheduledDateFormAfterGracePeriod: T.bool.isRequired,
  maxDelayDays: T.number.isRequired,
  navigation: T.object.isRequired,
  payType: T.string.isRequired,
  pickerDates: T.shape({
    dueDate: T.string,
    scheduledDateForm: T.string,
    scheduledDateOtp: T.string,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: One Time Payment
   */
  availableDates: selectFormattedAvailableDates(),
  disableSelect: selectDisableDateSelectButton(),
  isAfterGracePeriod: selectOtp('isAfterGracePeriod'),
  isScheduledDateFormAfterGracePeriod: selectIsScheduledDateFormAfterGracePeriod(),
  maxDelayDays: selectMaxDelayDays(),
  payType: selectOtp('payType'),
  pickerDates: selectMobilePickerDates(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: One Time Payment
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeScheduledDate: (payload) => dispatch(changeScheduledDate(payload)),
  dispatchChangeScheduledDateOnDeck: (payload) => dispatch(changeScheduledDateOnDeck(payload)),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PaymentDateScreen);
