import styled from 'styled-components/native';

import {
  BaseTextField,
  H2,
  P2,
  P4,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ButtonGroup = styled.View`
  align-items: center;
  margin: ${moderateScale(35)}px 0;
`;

export const ChangeAddressContainer = styled.View`
  flex: 1;
  margin-top: ${({ $isConfirmation }) => $isConfirmation ? moderateScale(20) : 0}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const ChangeMailingAddressContainer = styled.View`
  flex: 1;
  justify-content: ${({ $isConfirmation }) => $isConfirmation ? 'flex-start' : 'space-between'};
  padding: 0 ${moderateScale(24)}px;
`;

export const ConfirmationContent = styled.View`
  align-items: center;
  margin-top: ${moderateScale(16)}px;
  text-align: center;
`;

export const ConfirmationRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${moderateScale(16)}px;
  width: 100%;
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const IconWrapper = styled.View`
  height: ${moderateScale(16)}px;
  width: ${moderateScale(16)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${({ $isUpload }) => $isUpload ? 0 : moderateScale(52)}px;
`;

export const LinkText = styled(P2)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
`;

export const StatusWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  margin-top: ${moderateScale(25)}px;
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(
    ({ theme: { colors: { primaryGray }, fontSizes: { f5 } } }) =>
      ({
        inputFontSize: f5,
        inputStyle: {
          height: moderateScale(30),
          paddingBottom: moderateScale(4),
          paddingTop: moderateScale(4),
        },
        placeholderTextColor: primaryGray,
      }),
  )``;

export const StyledH2 = styled(H2)`
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(19.92)}px;
  padding-bottom: ${moderateScale(20)}px;
  text-align: center;
`;

export const StyledConfirmationP2 = styled(StyledP2)`
  text-align: center;
`;

export const StyledP4 = styled(P4)`
  flex: 1;
  margin-left: ${moderateScale(8.16)}px;
  text-align: left;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${moderateScale(17)}px;
`;

export const TextFieldGroup = styled.View`
  align-items: center;
`;

export const TextWrapper = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(19.92)}px;
  padding-bottom: ${moderateScale(10)}px;
`;

// used by Dropdown components
export const Container = styled.View`
  margin: ${moderateScale(10)}px 0;
`;

export const ErrorLabel = styled.Text`
  align-self: flex-end;
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  fontSize: ${({ theme: { fontSizes: { f2 } } }) => f2}px;
  padding-bottom: ${moderateScale(4)}px;
  width: ${moderateScale(217)}px;
`;

export const LabelInputContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: ${moderateScale(327)}px;
`;

export const StyledLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  line-height: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  min-height: ${moderateScale(18)}px;
  width: ${moderateScale(106)}px;
`;
