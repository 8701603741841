import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const ButtonWrapper = styled.View`
  flex: 0 auto;
`;

export const PaymentHomeContainer = styled.View`
  padding: 0 ${moderateScale(24)}px;
  width: 100%;
`;
