import styled from 'styled-components/native';
import { moderateScale } from '../../../../utils/scaleHelpers';

import { CheckboxWithText } from '../../../base_ui';

export const Footer = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  flex-direction: row;
  height: ${moderateScale(79)}px;
  justify-content: space-between;
  padding-left: ${moderateScale(20)}px;
  padding-right: ${moderateScale(15)}px;
`;

export const StyledCheckboxWithText = styled(CheckboxWithText)
  .attrs(({
    textStyle,
    theme: {
      colors: { primaryCarbon },
      fontSizes: { f4 },
    },
  }) => ({
    textStyle: {
      ...textStyle,
      color: primaryCarbon,
      fontSize: f4,
      fontWeight: 400,
    },
  }))``;
