import styled from 'styled-components/native';
import { Platform } from 'react-native';

import BaseHeading from './BaseHeading';
import { moderateScale } from '../../../utils/scaleHelpers';

const StyledBaseHeading = styled(BaseHeading)`
  ${({ theme: { colors: { primaryCarbon } } }) => Platform.OS === 'web' ?
    `color: ${primaryCarbon};` :
    ''}
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const H1 = styled(StyledBaseHeading)`
  font-family: ${({ theme: { fontFamilies: { primaryFont } } }) => primaryFont};
  font-size: ${({ theme: { fontSizes: { f8 } } }) => `${f8}px`};
  font-weight: 700;
  line-height: ${moderateScale(32)}px;
`;

export const H2 = styled(StyledBaseHeading)`
  font-family: ${({ theme: { fontFamilies: { primaryFont } } }) => primaryFont};
  font-size: ${({ theme: { fontSizes: { f7 } } }) => `${f7}px`};
  font-weight: 700;
  line-height: ${moderateScale(26)}px;
`;

export const H3 = styled(StyledBaseHeading)`
  font-size: ${({ theme: { fontSizes: { f6 } } }) => `${f6}px`};
  line-height: ${moderateScale(24)}px;
`;

export const H4 = styled(StyledBaseHeading)`
  font-size: ${({ theme: { fontSizes: { f5 } } }) => `${f5}px`};
  line-height: ${moderateScale(22)}px;
`;

export const H5 = styled(StyledBaseHeading)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => `${f4}px`};
  line-height: ${moderateScale(18)}px;
`;
