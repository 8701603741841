/* eslint-disable sort-keys */
import React from 'react';
import { getSeasonalIllustrations } from './illustrationHelpers';

import {
  ArrowCircleDefaultMIcon,
  ArrowCircleDefaultSIcon,
  ArrowCircleHoverMIcon,
  ArrowCircleHoverSIcon,
  ArrowLeftCarbonSIcon,
  ArrowLeftPurpleSIcon,
  ArrowRightCarbonSIcon,
  ArrowRightPurpleSIcon,
  AutopayMoney,
  BackArrowCarbonLeftMIcon,
  BackArrowCarbonMIcon,
  BackArrowPurpleMIcon,
  BorrowerDesk,
  Calculator,
  CalculatorMT,
  CalculatorsCalendar,
  CalculatorsClock,
  CalculatorsPayoffFlag,
  CalculatorsPiggybank,
  CalendarLarge,
  CalendarLgIcon,
  CalendarSmIcon,
  ChatBubble,
  ChatbotDefaultIcon,
  ChatbotHoverIcon,
  CheckmarkIcon,
  CloseXIcon,
  DesktopRegisterClipboard,
  DisasterClipboard,
  DisasterForms,
  DisasterHome,
  DisasterHurricane,
  DisasterMagnify,
  DisasterSectionFeature,
  DisasterShield,
  DisasterUmbrella,
  DisasterWildfire,
  DocumentsBuilding,
  DocumentsGeneral,
  DocumentsPayoff,
  DocumentsStatements,
  DogLookingOverFence,
  DogPlaying,
  DogSmartphone,
  DogWaiting,
  DogWindow,
  DownloadActiveIcon,
  DownloadDefaultIcon,
  DownloadLIcon,
  DropdownArrowIcon,
  DropdownCheckmarkIcon,
  EarthquakeIcon,
  EditIcon,
  EhlIcon,
  EmailChair,
  ErrorPorchlight,
  ErrorStorm,
  EscrowIcon,
  FaceID,
  FdicIcon,
  FireInsuranceIcon,
  FlagIcon,
  FloodIcon,
  FormsTownscape,
  GraphLever,
  GreyWarning,
  GreyWarningL,
  HamburgerMenuIcon,
  HardshipBook,
  HardshipClipboard,
  HardshipLighthouse,
  HardshipLighthouseS,
  HardshipTalk,
  HardshipWarning,
  HeaderAccountActiveIcon,
  HeaderAccountDefaultIcon,
  HeaderAccountHoverIcon,
  HeaderBellActiveIcon,
  HeaderBellDefaultIcon,
  HeaderBellHoverIcon,
  HeaderNewMsgDefaultIcon,
  HeaderNewMsgHoverIcon,
  HelpDog,
  HelpDogS,
  HelpLearningCenter,
  HelpLighthouse,
  HistoryIcon,
  HomeInsuranceIcon,
  HourglassIcon,
  HurricaneIcon,
  InfoTooltipSIcon,
  InfoTooltipXSIcon,
  InterimHeader,
  KeyIcon,
  LaptopSplash,
  LearningCenter,
  LegalFrames,
  LoanDetail,
  LoansSoldHome,
  LossDraftInbox,
  MagnifyingGlass,
  MegaphoneLgIcon,
  MegaphoneSmIcon,
  MilitaryHouse,
  ModernHouse,
  MortgageIcon,
  MortgageOverviewIcon,
  NavAccountActiveLIcon,
  NavAccountActiveMIcon,
  NavAccountDefaultLIcon,
  NavAccountDefaultMIcon,
  NavCalculatorActiveLIcon,
  NavCalculatorActiveMIcon,
  NavCalculatorDefaultLIcon,
  NavCalculatorDefaultMIcon,
  NavDisclosuresDefaultMIcon,
  NavDocumentsActiveLIcon,
  NavDocumentsActiveMIcon,
  NavDocumentsDefaultLIcon,
  NavDocumentsDefaultMIcon,
  NavHelpActiveLIcon,
  NavHelpActiveMIcon,
  NavHelpDefaultLIcon,
  NavHelpDefaultMIcon,
  NavLogoutActiveLIcon,
  NavLogoutActiveMIcon,
  NavLogoutDefaultLIcon,
  NavLogoutDefaultMIcon,
  NavMortgageActiveLIcon,
  NavMortgageActiveMIcon,
  NavMortgageDefaultLIcon,
  NavMortgageDefaultMIcon,
  NavPaymentsActiveLIcon,
  NavPaymentsActiveMIcon,
  NavPaymentsDefaultLIcon,
  NavPaymentsDefaultMIcon,
  OutlinedCompleteGreen,
  OutlinedCompletePurple,
  OutlinedPendingPurple,
  OutlinedPendingPurpleL,
  OutlinedWarning,
  Padlock,
  PaperClipXSIcon,
  PaperclipIcon,
  PaperlessBillingDog,
  PaperlessBillingRecycle,
  PaperlessBillingV1,
  PaperlessSm,
  PaymentHistoryActiveLIcon,
  PaymentHistoryActiveMIcon,
  PaymentHistoryDefaultLIcon,
  PaymentHistoryDefaultMIcon,
  PaymentWarning,
  PaymentWarningL,
  PaymentWarningPurple,
  PayoffHouse,
  PendingCarbon,
  PendingGray,
  PhoneIcon,
  PhoneSplash,
  Piggy,
  PiggyBankSplash,
  PlusIcon,
  PmiIcon,
  PoolHouse,
  ProgressCheckmark,
  PropertyTaxIcon,
  RedirectDog,
  RegisterClipboard,
  RegisterRobot,
  SecurityLockIcon,
  SettingsRobot,
  SettingsThumbsUp,
  SettingsTownscape,
  SewerAndWaterIcon,
  StatementsHouse,
  SvgComponent,
  TaxInsuranceIcon,
  TaxesIcon,
  ThumbtackL,
  TornadoIcon,
  TouchID,
  Treehouse,
  UncheckedIcon,
  UploadHouse,
  VisibilityOff,
  VisibilityOn,
  WarningIcon,
  WelcomeHome,
  WindInsuranceIcon,
} from './svg';

export default (name, options = {}) => {
  const { iconState, ...propsToPassDown } = options;
  const {
    DesktopSelectLoan,
    Login,
    Marketing,
    OutlinedHouse,
    PaymentHouse,
    SelectLoan,
  } = getSeasonalIllustrations();
  const icons = {
    active: {
      accountActive: HeaderAccountActiveIcon,
      bellActive: HeaderBellActiveIcon,
      downloadActiveS: DownloadActiveIcon,
      navAccountActiveL: NavAccountActiveLIcon,
      navAccountActiveM: NavAccountActiveMIcon,
      navCalculatorActiveL: NavCalculatorActiveLIcon,
      navCalculatorActiveM: NavCalculatorActiveMIcon,
      navDocumentsActiveL: NavDocumentsActiveLIcon,
      navDocumentsActiveM: NavDocumentsActiveMIcon,
      navHelpActiveL: NavHelpActiveLIcon,
      navHelpActiveM: NavHelpActiveMIcon,
      navLogoutActiveL: NavLogoutActiveLIcon,
      navLogoutActiveM: NavLogoutActiveMIcon,
      navMortgageActiveL: NavMortgageActiveLIcon,
      navMortgageActiveM: NavMortgageActiveMIcon,
      navPaymentsActiveL: NavPaymentsActiveLIcon,
      navPaymentsActiveM: NavPaymentsActiveMIcon,
      paymentHistoryActiveL: PaymentHistoryActiveLIcon,
      paymentHistoryActiveM: PaymentHistoryActiveMIcon,
    },
    default: {
      accountDefault: HeaderAccountDefaultIcon,
      addCircle: PlusIcon,
      approved: CheckmarkIcon,
      arrowCircleDefaultM: ArrowCircleDefaultMIcon,
      arrowCircleDefaultS: ArrowCircleDefaultSIcon,
      arrowDown: DropdownArrowIcon,
      arrowLeftCarbonM: BackArrowCarbonLeftMIcon,
      arrowLeftCarbonS: ArrowLeftCarbonSIcon,
      arrowLeftM: BackArrowPurpleMIcon,
      arrowLeftPurpleS: ArrowLeftPurpleSIcon,
      arrowRightCarbonS: ArrowRightCarbonSIcon,
      arrowRightM: BackArrowCarbonMIcon,
      arrowRightPurpleS: ArrowRightPurpleSIcon,
      autopayMoney: AutopayMoney,
      bellDefault: HeaderBellDefaultIcon,
      borrowerDesk: BorrowerDesk,
      calculator: Calculator,
      calculatorMT: CalculatorMT,
      calculatorsCalendar: CalculatorsCalendar,
      calculatorsClock: CalculatorsClock,
      calculatorsPayoffFlag: CalculatorsPayoffFlag,
      calculatorsPiggybank: CalculatorsPiggybank,
      calendarLarge: CalendarLarge,
      calendarLg: CalendarLgIcon,
      calendarSm: CalendarSmIcon,
      chatbotDefault: ChatbotDefaultIcon,
      chatBubble: ChatBubble,
      checkMark: DropdownCheckmarkIcon,
      close: CloseXIcon,
      desktopRegisterClipboard: DesktopRegisterClipboard,
      desktopSelectLoan: DesktopSelectLoan,
      disasterClipboard: DisasterClipboard,
      disasterForms: DisasterForms,
      disasterHome: DisasterHome,
      disasterHurricane: DisasterHurricane,
      disasterMagnify: DisasterMagnify,
      disasterSectionFeature: DisasterSectionFeature,
      disasterShield: DisasterShield,
      disasterUmbrella: DisasterUmbrella,
      disasterWildfire: DisasterWildfire,
      documentsBuilding: DocumentsBuilding,
      documentsGeneral: DocumentsGeneral,
      documentsPayoff: DocumentsPayoff,
      documentsStatements: DocumentsStatements,
      dogLookingOverFence: DogLookingOverFence,
      dogPlaying: DogPlaying,
      dogSmartphone: DogSmartphone,
      dogWaiting: DogWaiting,
      dogWindow: DogWindow,
      downloadDefaultS: DownloadDefaultIcon,
      downloadL: DownloadLIcon,
      earthquakeIcon: EarthquakeIcon,
      edit: EditIcon,
      ehlIcon: EhlIcon,
      emailChair: EmailChair,
      errorPorchlight: ErrorPorchlight,
      errorStorm: ErrorStorm,
      escrowIcon: EscrowIcon,
      faceID: FaceID,
      fdicIcon: FdicIcon,
      fireInsuranceIcon: FireInsuranceIcon,
      flag: FlagIcon,
      floodIcon: FloodIcon,
      formsTownscape: FormsTownscape,
      graphLever: GraphLever,
      greyWarning: GreyWarning,
      greyWarningL: GreyWarningL,
      hardshipBook: HardshipBook,
      hardshipClipboard: HardshipClipboard,
      hardshipLighthouse: HardshipLighthouse,
      hardshipLighthouseS: HardshipLighthouseS,
      hardshipTalk: HardshipTalk,
      hardshipWarning: HardshipWarning,
      helpDog: HelpDog,
      helpDogS: HelpDogS,
      helpLearningCenter: HelpLearningCenter,
      helpLighthouse: HelpLighthouse,
      history: HistoryIcon,
      homeInsuranceIcon: HomeInsuranceIcon,
      hourglass: HourglassIcon,
      hurricaneIcon: HurricaneIcon,
      infoTooltipSIcon: InfoTooltipSIcon,
      infoTooltipXSIcon: InfoTooltipXSIcon,
      interimHeader: InterimHeader,
      key: KeyIcon,
      laptopSplash: LaptopSplash,
      learningCenter: LearningCenter,
      legalFrames: LegalFrames,
      loanDetail: LoanDetail,
      loansSoldHome: LoansSoldHome,
      login: Login,
      lossDraftInbox: LossDraftInbox,
      magnifyingGlass: MagnifyingGlass,
      marketing: Marketing,
      megaphoneL: MegaphoneLgIcon,
      megaphoneS: MegaphoneSmIcon,
      menu: HamburgerMenuIcon,
      messageDefault: HeaderNewMsgDefaultIcon,
      militaryHouse: MilitaryHouse,
      mortgageHouse: ModernHouse,
      mortgageIcon: MortgageIcon,
      mortgageOverview: MortgageOverviewIcon,
      paperclip: PaperclipIcon,
      paperClip: PaperClipXSIcon,
      PiggyBig: Piggy,
      navAccountDefaultL: NavAccountDefaultLIcon,
      navAccountDefaultM: NavAccountDefaultMIcon,
      navCalculatorDefaultL: NavCalculatorDefaultLIcon,
      navCalculatorDefaultM: NavCalculatorDefaultMIcon,
      navDisclosuresDefaultM: NavDisclosuresDefaultMIcon,
      navDocumentsDefaultL: NavDocumentsDefaultLIcon,
      navDocumentsDefaultM: NavDocumentsDefaultMIcon,
      navHelpDefaultL: NavHelpDefaultLIcon,
      navHelpDefaultM: NavHelpDefaultMIcon,
      navLogoutDefaultL: NavLogoutDefaultLIcon,
      navLogoutDefaultM: NavLogoutDefaultMIcon,
      navMortgageDefaultL: NavMortgageDefaultLIcon,
      navMortgageDefaultM: NavMortgageDefaultMIcon,
      navPaymentsDefaultL: NavPaymentsDefaultLIcon,
      navPaymentsDefaultM: NavPaymentsDefaultMIcon,
      outlinedCompleteGreen: OutlinedCompleteGreen,
      outlinedCompletePurple: OutlinedCompletePurple,
      outlinedHouse: OutlinedHouse,
      outlinedPendingPurple: OutlinedPendingPurple,
      outlinedPendingPurpleL: OutlinedPendingPurpleL,
      outlinedWarning: OutlinedWarning,
      padlock: Padlock,
      paperlessBillingDog: PaperlessBillingDog,
      paperlessBillingRecycle: PaperlessBillingRecycle,
      paperlessBillingV1: PaperlessBillingV1,
      paperlessSm: PaperlessSm,
      paymentHistoryDefaultL: PaymentHistoryDefaultLIcon,
      paymentHistoryDefaultM: PaymentHistoryDefaultMIcon,
      paymentHouse: PaymentHouse,
      paymentWarning: PaymentWarning,
      paymentWarningL: PaymentWarningL,
      paymentWarningPurple: PaymentWarningPurple,
      payoffHouse: PayoffHouse,
      pendingCarbon: PendingCarbon,
      pendingGray: PendingGray,
      phone: PhoneIcon,
      phoneSplash: PhoneSplash,
      piggyBankSplash: PiggyBankSplash,
      pmiIcon: PmiIcon,
      poolHouse: PoolHouse,
      progressCheckmark: ProgressCheckmark,
      propertyTaxIcon: PropertyTaxIcon,
      redirectDog: RedirectDog,
      registerClipboard: RegisterClipboard,
      registerRobot: RegisterRobot,
      securityLock: SecurityLockIcon,
      selectLoan: SelectLoan,
      settingsRobot: SettingsRobot,
      settingsThumbsUp: SettingsThumbsUp,
      settingsTownscape: SettingsTownscape,
      sewerAndWaterIcon: SewerAndWaterIcon,
      statementsHouse: StatementsHouse,
      svg: SvgComponent,
      taxesIcon: TaxesIcon,
      taxInsuranceIcon: TaxInsuranceIcon,
      thumbtack: ThumbtackL,
      tornadoIcon: TornadoIcon,
      touchID: TouchID,
      treehouse: Treehouse,
      unapproved: UncheckedIcon,
      uploadHouse: UploadHouse,
      visibilityOff: VisibilityOff,
      visibilityOn: VisibilityOn,
      warning: WarningIcon,
      windInsuranceIcon: WindInsuranceIcon,
      welcomeHome: WelcomeHome,
    },
    hover: {
      accountHover: HeaderAccountHoverIcon,
      arrowCircleHoverM: ArrowCircleHoverMIcon,
      arrowCircleHoverS: ArrowCircleHoverSIcon,
      bellHover: HeaderBellHoverIcon,
      chatbotHover: ChatbotHoverIcon,
      messageHover: HeaderNewMsgHoverIcon,
    },
  };
  const status = iconState || 'default';
  const IconToRender = icons[status][name];
  return (
    <IconToRender
      accessibilityElementsHidden
      aria-hidden
      importantForAccessibility="no-hide-descendants"
      {...propsToPassDown}
    />
  );
};
