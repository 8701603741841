import React from 'react';
import T from 'prop-types';

import {
  BannerHeading,
  ContentContainer,
  NonDismissibleBannerContainer,
  NonDismissibleContainer,
} from './styledComponents';
import BodyContent from './BodyContent';

const NonDismissibleBanner = ({
  heading,
  messageText,
  ...restProps
}) => (
  <NonDismissibleContainer>
    <NonDismissibleBannerContainer>
      <BannerHeading>{heading}</BannerHeading>
      <ContentContainer>
        <BodyContent
          messageText={messageText}
          {...restProps}
        />
      </ContentContainer>
    </NonDismissibleBannerContainer>
  </NonDismissibleContainer>
);

NonDismissibleBanner.propTypes = {
  heading: T.string.isRequired,
  messageText: T.string.isRequired,
};

export default NonDismissibleBanner;
