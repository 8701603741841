import React from 'react';
import T from 'prop-types';

import { moderateScale } from '../../../../utils/scaleHelpers';
import Layout from '../../../../constants/Layout';
import { ConditionalRender, Skeleton } from '../../../base_ui';
import { CARD_ICON_DICT } from './constants';
import Link from './Link';
import LinkSkeleton from './Link/Skeleton';
import ListItems from './ListItems';
import ListItemsSkeleton from './ListItems/Skeleton';
import {
  ContentContainer,
  IllustrationContainer,
  LinkContainer,
  MessageContainer,
  SectionContainer,
  SectionDivider,
  SubHeading,
} from './styledComponents';

const { isTablet } = Layout;

const Card = ({
  heading,
  linkProps,
  listItems,
  loadingStep,
  navigation,
}) => (
  <SectionContainer>
    <ContentContainer $center={isTablet}>
      <IllustrationContainer>
        <ConditionalRender
          Component={linkProps ? CARD_ICON_DICT[linkProps.mobilePath] : undefined}
          FallbackComponent={(
            <Skeleton
              $bottomMargin={`${moderateScale(5)}px`}
              $leftMargin={`${moderateScale(12)}px`}
              $rightMargin={`${moderateScale(11)}px`}
              $topMargin={`${moderateScale(4)}px`}
              height={`${moderateScale(75)}px`}
              width={`${moderateScale(61)}px`}
            />
          )}
          shouldRender={loadingStep !== 2 && loadingStep !== 3}
        />
      </IllustrationContainer>
      <MessageContainer>
        <ConditionalRender
          Component={<SubHeading>{heading}</SubHeading>}
          FallbackComponent={(
            <Skeleton
              $bottomMargin={`${moderateScale(13)}px`}
              $leftMargin={`${moderateScale(1)}px`}
              $rightMargin={`${moderateScale(1)}px`}
              $topMargin={`${moderateScale(5)}px`}
              height={`${moderateScale(12.5)}px`}
              width={`${moderateScale(160)}px`}
            />
          )}
          shouldRender={loadingStep !== 2}
        />
        <ConditionalRender
          Component={ListItems}
          FallbackComponent={ListItemsSkeleton}
          propsToPassDown={{ data: listItems }}
          shouldRender={(
            loadingStep === 0 || (loadingStep === 5 && heading === 'Forms and Resources')
          )}
        />
      </MessageContainer>
    </ContentContainer>
    <SectionDivider />
    <LinkContainer $center={isTablet}>
      <ConditionalRender
        Component={Link}
        FallbackComponent={LinkSkeleton}
        propsToPassDown={{ linkProps, navigation }}
        shouldRender={(
          loadingStep === 0 || (loadingStep === 5 && heading === 'Forms and Resources')
        )}
      />
    </LinkContainer>
  </SectionContainer>
);

Card.propTypes = {
  heading: T.string.isRequired,
  linkProps: T.object,
  listItems: T.arrayOf(T.string).isRequired,
  loadingStep: T.number.isRequired,
  navigation: T.object.isRequired,
};

export default Card;
