import styled from 'styled-components/native';

import { BaseInternalLink, H4, PrimaryDivider } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const HeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 0 ${moderateScale(24)}px ${moderateScale(17)}px;
`;

export const PaymentDetailContainer = styled.ScrollView.attrs()`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  width: 100%;
`;

export const PaymentDetailRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(11)}px 0;
`;
export const PaymentDetailRowIndented = styled(PaymentDetailRow)`
  margin-left: ${moderateScale(20)}px;
`;

export const PaymentDetailRows = styled.View`
  padding: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  margin: 0 ${moderateScale(24)}px;
  max-width: 100%;
`;

export const StyledLink = styled(BaseInternalLink)`
  margin: ${moderateScale(14)}px auto;
`;

export const Subheader = styled(H4)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  margin: 0 ${moderateScale(24)}px;
`;
