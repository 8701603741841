import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const HeaderContainer = styled.View`
  max-width: ${moderateScale(328)}px;
  width: 100%;
`;

export const LeftContainer = styled.View`
  width: 70%;
`;

export const RightContainer = styled.View`
  align-items: flex-end;
  display: flex;
  max-width:${moderateScale(100)}px;
  padding-left: ${moderateScale(10)}px;
  width: 30%;
`;

export const SkeletonContent = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${moderateScale(24)}px;
`;
