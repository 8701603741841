import React from 'react';
import T from 'prop-types';
import camelCase from 'lodash/camelCase';

import elementDictionary from './elementDictionary';
import { ContentContainer } from './styledComponents';

/* eslint-disable react/no-array-index-key */
export const Content = ({
  content,
  dispatchSetNestedScreen,
  navigation,
  noMargin,
}) => {
  const handleNavigateLink = ({ nestedScreen, screen }) => {
    if (nestedScreen) {
      dispatchSetNestedScreen({
        mainScreen: camelCase(screen),
        nestedScreen,
      });
      navigation.navigate(screen);
    } else {
      navigation.navigate(screen);
    }
  };

  return (
    <ContentContainer $noMargin={noMargin}>
      {
        content.map((contentObj, i) => {
          const { type } = contentObj;
          const ElementToRender = elementDictionary[type];
          return (
            <ElementToRender
              key={`${type}-${i}`}
              handleNavigateLink={handleNavigateLink}
              isOrderedList={type === 'ordered-list'}
              navigation={navigation}
              {...contentObj}
            />
          );
        })
      }
    </ContentContainer>
  );
};

Content.propTypes = {
  content: T.array.isRequired,
  dispatchSetNestedScreen: T.func.isRequired,
  navigation: T.object.isRequired,
  noMargin: T.bool,
};

export default Content;
