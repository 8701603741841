/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  G,
  Path,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HardshipTalk() {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85">
      <G isolation="isolate">
        <G id="Layer_1" data-name="Layer 1">
          <Path fill="#bac2ca" d="M84.39,55.74l-.06-6.37a8.5,8.5,0,0,0-8.5-8.5H38.31a8.49,8.49,0,0,0-8.49,8.5V68.72a8.49,8.49,0,0,0,8.49,8.49H62.85L71.67,85V77.21h4.16a8.5,8.5,0,0,0,8.5-8.49v-8.1" />
          <Path fill="#bac2ca" d="M5.44,20.76v7.71a5.94,5.94,0,0,0,5.5,5.92L4.07,45.66,22.19,34.41H44.73a5.94,5.94,0,0,0,5.94-5.94V9.24A5.94,5.94,0,0,0,44.73,3.3H11.38A5.94,5.94,0,0,0,5.44,9.24V18" />
          <Path fill="#fff" d="M82,53.32l-.07-6.38a8.49,8.49,0,0,0-8.49-8.49H35.88a8.49,8.49,0,0,0-8.49,8.49V66.29a8.49,8.49,0,0,0,8.49,8.5H60.42l8.83,7.79V74.79h4.16a8.49,8.49,0,0,0,8.49-8.5V58.2" />
          <Path fill="#000" d="M69.77,83.73l-9.55-8.42H35.88a9,9,0,0,1-9-9V46.94a9,9,0,0,1,9-9H73.41a9,9,0,0,1,9,9l.07,6.37a.51.51,0,0,1-.51.53.49.49,0,0,1-.53-.52L81.38,47a8,8,0,0,0-8-8H35.88a8,8,0,0,0-8,8V66.29a8,8,0,0,0,8,8H60.62l8.1,7.15V74.27h4.69a8,8,0,0,0,8-8V58.2a.52.52,0,1,1,1,0v8.09a9,9,0,0,1-9,9H69.77Z" />
          <Path fill="#bac2ca" d="M68,50.43H41.34a1.07,1.07,0,0,1,0-2.13H68a1.07,1.07,0,0,1,0,2.13Z" />
          <Path fill="#bac2ca" d="M68,57.52H41.34a1.07,1.07,0,0,1,0-2.13H68a1.07,1.07,0,0,1,0,2.13Z" />
          <Path fill="#bac2ca" d="M45.24,64.62h-3.9a1.07,1.07,0,0,1,0-2.13h3.9a1.07,1.07,0,1,1,0,2.13Z" />
          <Path fill="#bac2ca" d="M54.11,64.62h-3.9a1.07,1.07,0,0,1,0-2.13h3.9a1.07,1.07,0,1,1,0,2.13Z" />
          <Path fill="#bac2ca" d="M68,64.62H59.08a1.07,1.07,0,0,1,0-2.13H68a1.07,1.07,0,0,1,0,2.13Z" />
          <Path fill="#eff3f6" d="M81.39,48.29V66.37a8,8,0,0,1-7.65,7.88H68.79c4.43,0,7.08-3.89,7.08-8.49v-19c0-4.6-4.65-7.75-8-7.84l5.94,0C78,39.48,81.77,41.88,81.39,48.29Z" />
          <Path fill="#fff" d="M3.06,18.38v7.71A5.93,5.93,0,0,0,8.56,32L1.69,43.28,19.81,32H42.36a5.94,5.94,0,0,0,5.94-5.94V6.86A5.94,5.94,0,0,0,42.36.92H9A5.94,5.94,0,0,0,3.06,6.86v8.72" />
          <Path fill="#000" d="M.13,44.85,7.72,32.41a6.47,6.47,0,0,1-5.17-6.32V18.38a.51.51,0,0,1,1,0v7.71a5.45,5.45,0,0,0,5,5.41l.84.06L3.25,41.71,19.67,31.52H42.36a5.43,5.43,0,0,0,5.42-5.43V6.86a5.43,5.43,0,0,0-5.42-5.43H9A5.44,5.44,0,0,0,3.57,6.86v8.72a.51.51,0,0,1-.51.52h0a.51.51,0,0,1-.51-.52V6.86A6.46,6.46,0,0,1,9,.4H42.36a6.47,6.47,0,0,1,6.45,6.46V26.09a6.47,6.47,0,0,1-6.45,6.46H20Z" />
          <G>
            <Path fill="#eff3f6" d="M47.75,7.09V25.68A5.42,5.42,0,0,1,42,31.52H36.72a5.83,5.83,0,0,0,5.74-5.84V7.09a5.62,5.62,0,0,0-5.64-5.64h0l5.27,0A5.43,5.43,0,0,1,47.75,7.09Z" />
          </G>
          <Path fill="#000" d="M82,57.12a.31.31,0,0,1-.31-.31v-2a.31.31,0,0,1,.62,0v2A.31.31,0,0,1,82,57.12Z" />
          <Path fill={svgHighlight} d="M19.27,10.92A5.44,5.44,0,0,1,20.18,8a7.11,7.11,0,0,1,2.68-2.42,8.42,8.42,0,0,1,4.1-1,8.67,8.67,0,0,1,3.84.8,6.18,6.18,0,0,1,2.58,2.18,5.37,5.37,0,0,1,.91,3,4.6,4.6,0,0,1-.52,2.24,7,7,0,0,1-1.23,1.66c-.48.46-1.33,1.25-2.56,2.35a9,9,0,0,0-.82.82,3.12,3.12,0,0,0-.46.64,2.88,2.88,0,0,0-.23.59c-.06.19-.14.54-.25,1a1.67,1.67,0,0,1-1.79,1.56A1.81,1.81,0,0,1,25.12,21a2,2,0,0,1-.54-1.52A5.46,5.46,0,0,1,26,15.69c.43-.46,1-1,1.74-1.66s1.11-1,1.39-1.27a4.22,4.22,0,0,0,.73-.95,2.36,2.36,0,0,0,.29-1.14,2.65,2.65,0,0,0-.9-2A3.24,3.24,0,0,0,27,7.82a3.2,3.2,0,0,0-2.44.83A6.58,6.58,0,0,0,23.2,11.1c-.34,1.14-1,1.7-1.93,1.7a1.9,1.9,0,0,1-1.42-.59A1.8,1.8,0,0,1,19.27,10.92ZM26.6,28.3a2.38,2.38,0,0,1-1.6-.59,2.08,2.08,0,0,1-.69-1.66A2.11,2.11,0,0,1,25,24.46a2.23,2.23,0,0,1,1.62-.65,2.22,2.22,0,0,1,2.24,2.24,2.1,2.1,0,0,1-.68,1.65A2.28,2.28,0,0,1,26.6,28.3Z" />
          <Path fill="#2c2627" d="M26.6,28.61a2.63,2.63,0,0,1-1.8-.67A2.35,2.35,0,0,1,24,26.05a2.43,2.43,0,0,1,.75-1.81,2.54,2.54,0,0,1,1.84-.74,2.51,2.51,0,0,1,2.54,2.55,2.41,2.41,0,0,1-.77,1.88A2.62,2.62,0,0,1,26.6,28.61Zm0-4.49a1.92,1.92,0,0,0-1.41.56,1.83,1.83,0,0,0-.57,1.37,1.78,1.78,0,0,0,.58,1.43,2,2,0,0,0,1.4.51A2,2,0,0,0,28,27.47a1.8,1.8,0,0,0,.57-1.42,1.9,1.9,0,0,0-1.93-1.93Zm-.17-2.28a2.1,2.1,0,0,1-1.52-.6,2.29,2.29,0,0,1-.63-1.74,6,6,0,0,1,.41-2.3,5.71,5.71,0,0,1,1.1-1.71,23.13,23.13,0,0,1,1.76-1.69c.63-.55,1.09-1,1.38-1.25a4.42,4.42,0,0,0,.67-.89,2,2,0,0,0,.25-1,2.34,2.34,0,0,0-.79-1.8A3,3,0,0,0,27,8.12a2.92,2.92,0,0,0-2.21.74,6.44,6.44,0,0,0-1.26,2.34,2.26,2.26,0,0,1-2.22,1.91,2.22,2.22,0,0,1-1.65-.69,2.1,2.1,0,0,1-.66-1.5,5.74,5.74,0,0,1,1-3,7.39,7.39,0,0,1,2.79-2.53,8.67,8.67,0,0,1,4.25-1,9.16,9.16,0,0,1,4,.83,6.58,6.58,0,0,1,2.7,2.3,5.7,5.7,0,0,1,1,3.17A4.92,4.92,0,0,1,34,13a7.39,7.39,0,0,1-1.28,1.74c-.48.46-1.32,1.23-2.58,2.36-.32.3-.59.56-.79.79a3,3,0,0,0-.42.58,2.48,2.48,0,0,0-.2.53c-.06.19-.14.53-.25,1A2,2,0,0,1,26.43,21.84ZM27,7.51a3.52,3.52,0,0,1,2.51.91,2.94,2.94,0,0,1,1,2.25A2.62,2.62,0,0,1,30.13,12a4.21,4.21,0,0,1-.77,1c-.29.3-.76.73-1.4,1.29s-1.3,1.18-1.72,1.63a5.12,5.12,0,0,0-1,1.54,5.34,5.34,0,0,0-.37,2.06,1.75,1.75,0,0,0,.44,1.3,1.54,1.54,0,0,0,1.1.42,1.38,1.38,0,0,0,1.49-1.31c.11-.51.2-.86.25-1.05a3.53,3.53,0,0,1,.26-.65,3.74,3.74,0,0,1,.5-.71c.21-.24.5-.52.84-.84,1.26-1.12,2.09-1.89,2.55-2.34a6.64,6.64,0,0,0,1.18-1.59A4.33,4.33,0,0,0,34,10.64a5.09,5.09,0,0,0-.86-2.84,5.9,5.9,0,0,0-2.45-2.07A8.37,8.37,0,0,0,27,5,8,8,0,0,0,23,5.88,6.77,6.77,0,0,0,20.44,8.2a5.21,5.21,0,0,0-.87,2.72,1.49,1.49,0,0,0,.5,1.07,1.57,1.57,0,0,0,1.2.5c.8,0,1.34-.48,1.64-1.47A6.84,6.84,0,0,1,24.3,8.44,3.48,3.48,0,0,1,27,7.51Z" />
        </G>
      </G>
    </Svg>
  );
}
