import React from 'react';
import T from 'prop-types';
import { FlatList } from 'react-native';

import { ConditionalRender } from '../../../../base_ui';
import Item from './Item';
import ListHeader from './ListHeader';
import Message from '../../../../Message';

const MultiplePaymentsHistoryList = ({
  dispatchUpdateSelectedTransaction,
  groupedTransactions,
  isMultiplePaymentsRows,
  itemsPerPage,
  navigation,
}) => {
  const renderItem = ({ item }) => (
    <Item
      dispatchUpdateSelectedTransaction={dispatchUpdateSelectedTransaction}
      expandedTransaction={item}
      isMultiplePaymentsRows
      navigation={navigation}
    />
  );

  return (
    <ConditionalRender
      Component={FlatList}
      FallbackComponent={Message}
      propsToPassDown={{
        data: groupedTransactions,
        initialNumToRender: itemsPerPage,
        keyExtractor: ({ id }, index) => `${id}-${index}`,
        ListHeaderComponent: (
          <ListHeader isMultiplePaymentsRows={!!isMultiplePaymentsRows} />
        ),
        onEndReachedThreshold: 0.3,
        renderItem,
        stickyHeaderIndices: [0],
      }}
      shouldRender={!!groupedTransactions}
    />
  );
};

MultiplePaymentsHistoryList.propTypes = {
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  groupedTransactions: T.array.isRequired,
  isMultiplePaymentsRows: T.bool,
  itemsPerPage: T.number.isRequired,
  navigation: T.object.isRequired,
};

export default MultiplePaymentsHistoryList;
