/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  G,
  Path,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

const SecurityLockIcon = (props) => (
  <Svg fill="#000" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G>
      <Path d="M13.3,16.8H4.4c-1,0-1.7-0.8-1.7-1.7V8.3c0-1,0.8-1.7,1.7-1.7h8.9c1,0,1.7,0.8,1.7,1.7v6.8C15,16,14.3,16.8,13.3,16.8zM4.4,7.8C4.1,7.8,3.9,8,3.9,8.3v6.8c0,0.3,0.2,0.5,0.5,0.5h8.9c0.3,0,0.5-0.2,0.5-0.5V8.3c0-0.3-0.2-0.5-0.5-0.5H4.4z" />
      <Path d="M5.7,7.7C5.4,7.7,5.1,7.4,5.1,7V4.9c0-2.1,1.7-3.8,3.8-3.8c2.1,0,3.8,1.7,3.8,3.8v2c0,0.3-0.3,0.6-0.6,0.6s-0.6-0.3-0.6-0.6v-2c0-1.4-1.1-2.5-2.5-2.5S6.3,3.5,6.3,4.9V7C6.3,7.4,6.1,7.7,5.7,7.7z" />
      <G>
        <Circle cx="8.9" cy="10.5" r="1" />
        <Path d="M8.9,11.8c-0.7,0-1.2-0.6-1.2-1.2s0.6-1.2,1.2-1.2s1.2,0.6,1.2,1.2S9.5,11.8,8.9,11.8z M8.9,9.8c-0.4,0-0.7,0.3-0.7,0.7s0.3,0.7,0.7,0.7s0.7-0.3,0.7-0.7S9.3,9.8,8.9,9.8z" />
      </G>
      <Path d="M8.9,14.2c-0.3,0-0.6-0.3-0.6-0.6v-2c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v2C9.5,13.9,9.2,14.2,8.9,14.2z" />
    </G>
  </Svg>
);

export default SecurityLockIcon;
