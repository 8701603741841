import styled from 'styled-components/native';

import { FormattedMessageStatusBanner } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const IllustrationWrapper = styled.View`
  margin: ${moderateScale(16)}px 0 ${moderateScale(22)}px
`;

export const DeleteMessageWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  padding: ${moderateScale(24)}px ${moderateScale(24)}px 0 ${moderateScale(24)}px;
`;

export const DeleteIconWrapper = styled.View`
  margin-right: ${moderateScale(12)}px;
`;

export const PhoneNumberWrapper = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledFormattedMessageStatusBanner = styled(FormattedMessageStatusBanner)`
  margin: ${moderateScale(12)}px ${moderateScale(25)}px;
`;
