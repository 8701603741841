/*
 * Military Status Upload Screen
 */

import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import { chooseDocument } from '@dmi/shared/redux/Settings/Profile/actions';
import { selectRequestStatus } from '@dmi/shared/redux/Settings/Profile/selectors';

import { MilitaryStatusUploadComponent } from '../../../components/Settings/EditMilitaryStatus';
import { StyledSecondaryHeader, StyledView } from './styledComponents';

const MilitaryStatusUploadDocumentScreen = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  navigation,
}) => {
  const { mobileHeader } = PROFILE_CONTENT_DICTIONARY.editMilitaryStatus.uploadView;

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={navigation.goBack}
        title={mobileHeader}
      />
      <StyledView>
        <MilitaryStatusUploadComponent
          chooseDocumentRequestStatus={chooseDocumentRequestStatus}
          dispatchChooseDocument={dispatchChooseDocument}
          reviewScreenName="MilitaryStatusUploadReviewDocument"
        />
      </StyledView>
    </Fragment>
  );
};

MilitaryStatusUploadDocumentScreen.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Profile
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MilitaryStatusUploadDocumentScreen);
