import styled from 'styled-components/native';

import { H2, MediumPrimaryButton, MediumProgressButton } from '../../../base_ui';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const StyledH2 = styled(H2)`
  color: ${({ $error, theme: { colors: { accentRed, primaryCarbon } } }) =>
    $error ? accentRed : primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
`;

export const StyledPrimaryButton = styled(MediumPrimaryButton)`
  height: ${moderateScale(39)}px;
  width: ${moderateScale(173)}px;
`;

export const StyledProgressButton = styled(MediumProgressButton)`
  height: ${moderateScale(39)}px;
  width: ${moderateScale(173)}px;
`;

export const StyledWrapper = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding:
    ${moderateScale(19)}px ${moderateScale(15)}px ${moderateScale(19)}px ${moderateScale(24)}px;
`;

export const TextWrapper = styled.View`
  flex-shrink: 1;
  padding-right: ${moderateScale(6)}px;
`;
