import styled from 'styled-components/native';
import { ScrollView } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { PrimaryDivider } from '../Dividers';

export const FilterContainer = styled.View`
  height: ${moderateScale(28)}px;
  margin: 0;
  max-width: ${moderateScale(140)}px;
  width: ${({ $width }) => $width ? `${moderateScale($width)}px` : 'auto'};;
`;

export const StatementsFilterContainer = styled.View`
  height: ${moderateScale(40)}px;
  justify-content: space-between;
  margin: 0;
  width: auto;
`;

export const StyledDivider = styled(PrimaryDivider)`
  background-color: ${({
    isSelected,
    theme: { colors: { primary, primaryGray } },
  }) => isSelected ? primary : primaryGray};
  height: ${({ isSelected }) => isSelected ? `${moderateScale(4)}px` : 0};
  width: auto;
`;

export const StyledScrollView = styled(ScrollView).attrs({
  contentContainerStyle: {
    flexGrow: 1,
    justifyContent: 'space-around',
  },
})`
  width: 100%;
`;

export const StyledStatementsDivider = styled(StyledDivider)`
  height: ${({ isSelected }) => isSelected ? `${moderateScale(4)}px` : `${moderateScale(0)}px`};
`;

export const StyledText = styled.Text`
  align-self: center;
  color: ${({ isSelected, theme: { colors: { accentGray, primaryCarbon } } }) =>
    isSelected ? primaryCarbon : accentGray};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({
    $fontSize,
    theme: { fontSizes },
  }) => $fontSize ? fontSizes[$fontSize] : fontSizes.f3}px;
  height: ${moderateScale(22)}px;
  opacity:${({ isSelected }) => isSelected ? 1 : 0.75};
`;

export const StyledStatementsText = styled(StyledText)`
  color: ${({ isSelected, theme: { colors: { accentGray, primaryCarbon } } }) =>
    isSelected ? primaryCarbon : accentGray};
  font-size: ${({ theme: { fontSizes: { f5 } } }) => f5}px;
  height: ${moderateScale(28)}px;
  padding-top: ${moderateScale(8)}px;
`;
