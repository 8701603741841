import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../base_ui';
import {
  IllustrationWrapper,
  StyledButton,
  StyledCard,
  StyledH4,
  StyledLink,
} from './styledComponents';

const DisasterCard = ({
  Icon,
  label,
  onPress,
  path,
  text,
  title,
}) => (
  <StyledCard>
    <IllustrationWrapper>
      {Icon}
    </IllustrationWrapper>
    <StyledH4>{title}</StyledH4>
    {text}
    <ConditionalRender
      Component={StyledButton}
      FallbackComponent={StyledLink}
      propsToPassDown={{
        isPrimary: true,
        label,
        onPress,
        path,
        size: 'medium',
      }}
      shouldRender={!!onPress}
    />
  </StyledCard>
);

DisasterCard.propTypes = {
  Icon: T.element.isRequired,
  label: T.string.isRequired,
  onPress: T.func,
  path: T.string,
  text: T.object.isRequired,
  title: T.string.isRequired,
};

export default DisasterCard;
