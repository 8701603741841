import React from 'react';
import T from 'prop-types';

import { ConditionalRender, P3 } from '../../../base_ui';
import PaymentStatus from './PaymentStatus';
import { ICON_DICT } from './constants';
import {
  CardContainer,
  HeaderContainer,
  HeaderText,
  HeaderWrapper,
  InternalLink,
  ItemLabel,
  ItemWrapper,
} from './styledComponents';

const InsuranceCard = ({
  amount,
  companyName,
  date,
  dispatchUpdateSelectedInsurance,
  insuranceType,
  paymentDate,
  paymentIsDue,
  policyNumber,
  type,
}) => (
  <CardContainer>
    <HeaderContainer>
      <HeaderWrapper>
        {ICON_DICT[type] || ICON_DICT.insuranceFallback}
        <HeaderText>{type}</HeaderText>
      </HeaderWrapper>
      <ConditionalRender
        Component={(
          <PaymentStatus
            paymentDate={paymentDate}
            paymentIsDue={paymentIsDue}
          />
        )}
        shouldRender={!!paymentDate && paymentIsDue !== undefined}
      />
    </HeaderContainer>
    <ItemWrapper>
      <ItemLabel>Policy Number</ItemLabel>
      <P3>{policyNumber}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Company</ItemLabel>
      <P3>{companyName}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Annual Premium</ItemLabel>
      <P3>{amount}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Expiration Date</ItemLabel>
      <P3>{date}</P3>
    </ItemWrapper>
    <InternalLink
      label="Update Policy"
      onPressFunc={() => dispatchUpdateSelectedInsurance(insuranceType)}
      path="InsuranceDetails"
    />
  </CardContainer>
);

InsuranceCard.propTypes = {
  amount: T.string.isRequired,
  companyName: T.string.isRequired,
  date: T.string.isRequired,
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  insuranceType: T.string.isRequired,
  paymentDate: T.string,
  paymentIsDue: T.bool,
  policyNumber: T.string.isRequired,
  type: T.string.isRequired,
};

export default InsuranceCard;
