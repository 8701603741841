import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import useDocPicker from '../../useDocPicker';
import { StyledBorderlessButton } from './styledComponents';

const PlusIcon = iconDictionary('addCircle');

const BorderlessDocPickerButton = ({
  dispatchChooseDocument,
  displayIcon = true,
  isSecondFile = false,
  label = 'Select a Different File',
}) => {
  const { showDocPickerOptions } = useDocPicker({ dispatchChooseDocument, isSecondFile });
  const shouldDisplayIcon = {
    Icon: displayIcon ? PlusIcon : null,
    iconPosition: displayIcon ? 'left' : null,
  };

  return (
    <StyledBorderlessButton
      {...shouldDisplayIcon}
      label={label}
      onPress={showDocPickerOptions}
    />
  );
};

BorderlessDocPickerButton.propTypes = {
  dispatchChooseDocument: T.func.isRequired,
  displayIcon: T.bool,
  isSecondFile: T.bool,
  label: T.string,
};

export default BorderlessDocPickerButton;
