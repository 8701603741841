import React from 'react';
import T from 'prop-types';

import { RESULTS_DICTIONARY } from '@dmi/shared/redux/Calculators/Amortize/constants';
import { convertNumToCurrencyStr, interpolate } from '@dmi/shared/utils/globalHelpers';

import { ConditionalRender, Skeleton } from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  ButtonContainer,
  DownloadButton,
  HeaderText,
  NoProceedButtonSpacer,
  ProceedButton,
  ResultsContainer,
  ResultsContentContainer,
} from './styledComponents';
import Card from './Card';
import { ICON_ARRAY } from './constants';
import { moderateScale } from '../../../../utils/scaleHelpers';

const DownloadIcon = iconDictionary('downloadActiveS', { iconState: 'active' });

const ResultsView = ({
  amount,
  handleDownloadCSV,
  isAmortizationScheduleCalculated,
  isLoading,
  navigation,
  resultsData,
  resultsSummaryBarProps: { isButtonDisabled, summaryLabel },
  selectedAdjustmentFilter,
}) => {
  const { cardsHeader } = RESULTS_DICTIONARY[selectedAdjustmentFilter];
  const digits = Number(amount) % 1 !== 0 ? 2 : 0;
  const noTrailingDecimalZeros = convertNumToCurrencyStr(Number(amount), digits);

  return (
    <ResultsContentContainer>
      <HeaderText>{interpolate(cardsHeader, { amount: noTrailingDecimalZeros })}</HeaderText>
      <ResultsContainer>
        {resultsData.map(({ label, value }, index) => (
          <Card
            key={label}
            Icon={ICON_ARRAY[index]}
            isLoading={isLoading}
            label={label}
            value={value}
          />
        ))}
      </ResultsContainer>
      <ButtonContainer>
        <ConditionalRender
          Component={(
            <Skeleton
              $bottomMargin={`${moderateScale(20)}px`}
              $topMargin={`${moderateScale(10)}px`}
              height={`${moderateScale(16)}px`}
              width={`${moderateScale(216)}px`}
            />
          )}
          FallbackComponent={DownloadButton}
          propsToPassDown={{
            ariaLabel: 'Download-amortization-schedule',
            Icon: DownloadIcon,
            isPrimary: true,
            label: isAmortizationScheduleCalculated ?
              'Calculated Amortization Schedule' :
              'Amortization Schedule',
            onPress: handleDownloadCSV,
          }}
          shouldRender={isLoading}
        />
        <ConditionalRender
          Component={(
            <Skeleton
              borderBottomLeftRadius={moderateScale(8)}
              borderBottomRightRadius={moderateScale(8)}
              hasPulsateWidth
              height={`${moderateScale(36)}px`}
              width="100%"
            />
          )}
          FallbackComponent={isButtonDisabled ?
            NoProceedButtonSpacer :
            ProceedButton}
          propsToPassDown={{
            isPrimary: true,
            label: summaryLabel,
            onPress: () => navigation.navigate('CalculatorsProceed'),
          }}
          shouldRender={isLoading}
        />
      </ButtonContainer>
    </ResultsContentContainer>
  );
};

ResultsView.propTypes = {
  amount: T.oneOfType([T.number, T.string]).isRequired,
  handleDownloadCSV: T.func.isRequired,
  isAmortizationScheduleCalculated: T.bool.isRequired,
  isLoading: T.bool.isRequired,
  navigation: T.object.isRequired,
  resultsData: T.array.isRequired,
  resultsSummaryBarProps: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
};

export default ResultsView;
