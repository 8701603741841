import styled from 'styled-components/native';

import { P2 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const ArrowWrapper = styled.View`
  padding-left: ${moderateScale(13)}px;
`;

export const NoPaymentSourceValue = styled(P2)`
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  padding-left: ${moderateScale(7)}px;
`;

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(74)}px;
  justify-content: space-between;
  padding: 0 ${moderateScale(20)}px;
`;

export const ValueIconWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  height: 100%;
`;

export const ValueWrapper = styled.View`
  align-items: flex-end;
  height: 100%;
  justify-content: center;
`;
