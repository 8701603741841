import React from 'react';
import T from 'prop-types';
import { FlatList } from 'react-native';

import { ConditionalRender } from '../../../../base_ui';
import iconDictionary from '../../../../../utils/iconDictionary';
import Item from './Item';
import ListHeader from './ListHeader';
import Message from '../../../../Message';

const KeyIcon = iconDictionary('key');

const PaymentHistoryList = ({
  dispatchFetchTransactions,
  dispatchSetGroupedTransactions,
  dispatchUpdateSelectedTransaction,
  flatListTransactions,
  itemsPerPage,
  navigation,
  scrollLoading,
}) => {
  const handleEndReached = () => {
    if (!scrollLoading) {
      dispatchFetchTransactions();
    }
  };

  const renderItem = ({ item }) => (
    <Item
      dispatchSetGroupedTransactions={dispatchSetGroupedTransactions}
      dispatchUpdateSelectedTransaction={dispatchUpdateSelectedTransaction}
      navigation={navigation}
      transactions={item}
    />
  );

  const noDataMessageProps = {
    body: 'Please check again after a payment has been made.',
    icon: KeyIcon,
    title: 'No Payment History.',
  };
  return (
    <ConditionalRender
      Component={FlatList}
      FallbackComponent={Message}
      propsToPassDown={{
        data: flatListTransactions,
        initialNumToRender: itemsPerPage,
        keyExtractor: ({ id }, index) => `${id}-${index}`,
        ListHeaderComponent: ListHeader,
        onEndReached: handleEndReached,
        onEndReachedThreshold: 0.3,
        renderItem,
        stickyHeaderIndices: [0],
        ...noDataMessageProps,
      }}
      shouldRender={!!flatListTransactions}
    />
  );
};

PaymentHistoryList.propTypes = {
  dispatchFetchTransactions: T.func.isRequired,
  dispatchSetGroupedTransactions: T.func.isRequired,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  flatListTransactions: T.array.isRequired,
  itemsPerPage: T.number.isRequired,
  navigation: T.object.isRequired,
  scrollLoading: T.bool.isRequired,
};

export default PaymentHistoryList;
