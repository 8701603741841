/**
 *
 * MessagesAttachmentsReview
 *
 * @description:
 *  Component to review/upload/remove Jira message attachments
 * @prop {object} dictionary
 *   required object with props to render screen copy
 * @prop {func} dispatchChooseDocument
 *   required function to execute when attachment is added
 * @prop {func} dispatchRemoveAttachment
 *   required function to execute when attachment is removed
 * @prop {func} dispatchUpdateAttachments
 *   required function to execute when attachments are submitted
 * @prop {array} formAttachments
 *   required array containing the selected attachments
 * @prop {func} handleBack
 *   required function to execute when back button is clicked
 */

import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { ConditionalRender, LargePrimaryButton, P3 } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import FileItem from '../../DocUpload/ReviewScene/FileItem';
import {
  BottomButtonWrapper,
  ContentWrapper,
  ErrorBannerContainer,
  Header,
  HeaderSubText,
  IconWrapper,
  IllustrationHeaderWrapper,
  IllustrationWrapper,
  ReviewContainer,
  StyledBorderlessDocPickerButton,
  StyledPrimaryDivider,
} from './styledComponents';

const Illustration = iconDictionary('magnifyingGlass');
const WarningIcon = iconDictionary('paymentWarningL');

const MessagesAttachmentsReview = ({
  dictionary: {
    description,
    headerLabel,
    secondaryHeader,
  },
  dispatchChooseDocument,
  dispatchRemoveAttachment,
  dispatchUpdateAttachments,
  formAttachments,
  formUploadError,
  handleBack,
}) => {
  const existingFormAttachments = formAttachments.filter(({ removed }) => !removed);
  const hasError = formAttachments.some(({ bannerError, removed }) =>
    !!bannerError && !removed) || !!formUploadError;

  const handleBackPress = () => {
    dispatchUpdateAttachments({ isSubmit: false });
    handleBack();
  };

  return (
    <ReviewContainer>
      <SecondaryHeader
        handleBack={handleBackPress}
        title={headerLabel}
      />
      <ContentWrapper>
        <ConditionalRender
          Component={(
            <ErrorBannerContainer>
              <IconWrapper>{WarningIcon}</IconWrapper>
              <P3>{formUploadError}</P3>
            </ErrorBannerContainer>
          )}
          shouldRender={!!formUploadError}
        />
        <IllustrationHeaderWrapper>
          <IllustrationWrapper>{Illustration}</IllustrationWrapper>
          <Header>{secondaryHeader}</Header>
          <HeaderSubText>{description}</HeaderSubText>
        </IllustrationHeaderWrapper>
        {formAttachments.map(({
          bannerError: fileError,
          fileName,
          removed,
        }, index) => !removed && (
          <FileItem
            // eslint-disable-next-line react/no-array-index-key
            key={`${fileName}-${index}`}
            fileName={fileName}
            isMultifile
            removeFileHandler={() => dispatchRemoveAttachment({ file: fileName, index })}
            renderInlineButton
            validationErrorMessage={fileError}
          />
        ))}
        <ConditionalRender
          Component={StyledPrimaryDivider}
          shouldRender={existingFormAttachments.length < 5}
        />
        <ConditionalRender
          Component={StyledBorderlessDocPickerButton}
          propsToPassDown={{
            dispatchChooseDocument,
            displayIcon: true,
            label: 'Add Another File',
          }}
          shouldRender={existingFormAttachments.length < 5}
        />
      </ContentWrapper>
      <BottomButtonWrapper>
        <LargePrimaryButton
          disabled={hasError}
          label="Submit"
          onPress={() => {
            dispatchUpdateAttachments({ isSubmit: true });
            handleBack();
          }}
        />
      </BottomButtonWrapper>
    </ReviewContainer>
  );
};

MessagesAttachmentsReview.propTypes = {
  dictionary: T.shape({
    description: T.string.isRequired,
    headerLabel: T.string.isRequired,
    secondaryHeader: T.string.isRequired,
  }),
  dispatchChooseDocument: T.func.isRequired,
  dispatchRemoveAttachment: T.func.isRequired,
  dispatchUpdateAttachments: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formUploadError: T.string.isRequired,
  handleBack: T.func.isRequired,
};

export default MessagesAttachmentsReview;
