/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function FireInsuranceIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M8.7,16.7c-3.3,0-6-2.7-6-6l0-0.2c0-0.2,0-3.1,1.5-4.9c0.1-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0.2,0.6,0.4,1C5.9,6.4,6.1,6,6.6,5.4c0.8-1,0.7-2.6,0.6-3.2l0-0.2c0-0.2,0.1-0.5,0.3-0.6C7.7,1.4,8,1.4,8.2,1.6c0.1,0.1,2.1,1.8,2.8,4.6c0.2-0.3,0.4-0.7,0.3-1.3c0-0.2,0.1-0.5,0.3-0.6c0.2-0.1,0.5-0.1,0.6,0c0.1,0.1,3,2.1,2.6,6.2l0,0.2C14.8,14,12.1,16.7,8.7,16.7zM4.6,7.3C3.9,8.7,4,10.5,4,10.5c0,0,0,0,0,0l0,0.2c0,2.6,2.1,4.8,4.8,4.8c2.7,0,4.8-2.1,4.8-4.8l0-0.3c0.2-2.1-0.6-3.5-1.3-4.3c-0.3,1-1.1,1.5-1.6,1.6c-0.2,0.1-0.3,0-0.5-0.1C10,7.6,10,7.4,9.9,7.2C9.7,5.7,9,4.4,8.4,3.6C8.3,4.4,8.1,5.3,7.5,6.1C6.6,7.3,6.5,8,6.5,8c0,0.2-0.2,0.4-0.3,0.4C6,8.5,5.8,8.5,5.6,8.4C5.1,8.2,4.8,7.7,4.6,7.3z" fill={iconStatic} />
      <Path d="M8.9,14.6c0,0-0.1,0-0.1,0l0,0C6.5,14.6,6,12.8,6,11.9C5.9,9.6,7.3,8.3,7.3,8.2C7.5,8.1,7.7,8,7.9,8.1c0.2,0.1,0.4,0.2,0.4,0.4C8.5,9.2,8.9,9.9,9,10c0.2,0,0.5-0.3,0.7-0.6c0.1-0.2,0.3-0.3,0.5-0.3c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.9,1.2,0.9,2.6c0,0.1,0,1.2-0.8,1.9C10.3,14.4,9.7,14.6,8.9,14.6z M7.6,9.9c-0.2,0.5-0.5,1.1-0.4,1.9c0,0.3,0.1,1.5,1.6,1.6l0,0c0.5,0,0.9-0.1,1.2-0.4c0.4-0.4,0.5-1.1,0.5-1.1c0-0.5-0.1-0.9-0.3-1.2c-0.4,0.3-0.8,0.5-1.4,0.4C8.2,11,7.8,10.5,7.6,9.9z" fill={iconStatic} />
    </Svg>
  );
}
