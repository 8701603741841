import React, { Fragment } from 'react';
import T from 'prop-types';
import { Text, View } from 'react-native';

import iconDictionary from '../../../utils/iconDictionary';
import Treehouse from '../../../utils/svg/Illustrations/Treehouse';

import {
  ConditionalRender,
  H4,
  LargeProgressButton,
} from '../../base_ui';
import { MFA_LABELS } from './constants';
import {
  FormButtonWrapper,
  RadioButtonWrapper,
  StyledDisclaimer,
  StyledIconButton,
} from './styledComponents';

const CheckedIcon = iconDictionary('approved');
const EmptyIcon = iconDictionary('unapproved');

const MFAForm = ({
  dispatchChangeInput,
  dispatchSelectMfa,
  mfaOptions,
  selectedMfaOption,
  status,
}) => (
  <Fragment>
    <H4>
      Pick your delivery method
    </H4>
    {
      mfaOptions.map(({ name, value }, i) => {
        const handlePress = () => {
          dispatchChangeInput({
            field: 'selectedOption',
            form: 'mfa',
            value: name,
          });
        };

        const isChecked = name === selectedMfaOption;

        return (
          <RadioButtonWrapper key={`radio-button-${i + 1}`}>
            <StyledIconButton
              ariaLabel={`${name} radio button, ${isChecked ? 'checked' : 'unchecked'}`}
              Icon={isChecked ? CheckedIcon : EmptyIcon}
              onPress={handlePress}
            />
            <View>
              <Text>
                {MFA_LABELS[name]}
                {`\n${value}`}
              </Text>
              <ConditionalRender
                Component={(
                  <StyledDisclaimer>
                    Message and data rates may apply.
                  </StyledDisclaimer>
                )}
                shouldRender={name === 'SMS'}
              />
            </View>
          </RadioButtonWrapper>
        );
      })
    }
    <FormButtonWrapper>
      <Treehouse />
      <LargeProgressButton
        label="Send"
        onPress={() => dispatchSelectMfa({ mfaOption: selectedMfaOption })}
        status={status}
      />
    </FormButtonWrapper>
  </Fragment>
);

MFAForm.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchSelectMfa: T.func.isRequired,
  mfaOptions: T.array.isRequired,
  selectedMfaOption: T.string.isRequired,
  status: T.string.isRequired,
};

export default MFAForm;
