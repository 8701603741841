import React, { Fragment } from 'react';
import T from 'prop-types';

import EscrowOption from './EscrowOption';

const SetUpOptions = ({ addEscrowOption, optionPressHandler, options }) => {
  const optionChildren = options.map((option) => {
    const { description, optionHeader } = option;
    return (
      <EscrowOption
        key={optionHeader}
        addEscrowOption={addEscrowOption}
        description={description}
        optionHeader={optionHeader}
        optionPressHandler={optionPressHandler}
      />
    );
  });

  return (
    <Fragment>
      {optionChildren}
    </Fragment>
  );
};

SetUpOptions.propTypes = {
  addEscrowOption: T.string.isRequired,
  optionPressHandler: T.func.isRequired,
  options: T.array.isRequired,
};

export default SetUpOptions;
