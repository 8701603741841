import React from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';
import { useWindowDimensions } from 'react-native';

import { RESOURCES_DRAWER_DETAILS } from '@dmi/shared/redux/LossDraft/constants';

import { BottomDrawer } from '../../../base_ui';
import { StyledHTML, StyledTertiaryHeader } from './styledComponents';

const ClaimsProcessDrawer = ({
  drawerType,
  isDrawerOpen,
  setIsDrawerOpen,
}) => {
  const { formatMessage } = useIntl();

  const { detail, resourceTitle } = RESOURCES_DRAWER_DETAILS[drawerType];
  const { width } = useWindowDimensions();
  const handleCloseDrawer = () => setIsDrawerOpen(false);

  return (
    <BottomDrawer
      handleClose={handleCloseDrawer}
      HeaderComponent={StyledTertiaryHeader}
      headerProps={{
        handleBack: handleCloseDrawer,
        title: resourceTitle,
      }}
      isVisible={isDrawerOpen}
    >
      <StyledHTML
        contentWidth={width}
        source={{ html: formatMessage({ id: detail }) }}
      />
    </BottomDrawer>
  );
};

ClaimsProcessDrawer.propTypes = {
  drawerType: T.string.isRequired,
  isDrawerOpen: T.bool.isRequired,
  setIsDrawerOpen: T.func.isRequired,
};

export default ClaimsProcessDrawer;
