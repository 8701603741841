/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux';
import mobileReducer from '@dmi/shared/redux/Mobile/reducer';

export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    mobile: mobileReducer,
    ...injectedReducers,
  });

  return rootReducer;
}
