import React, { useState } from 'react';
import T from 'prop-types';
import * as WebBrowser from 'expo-web-browser';
import { useIntl } from 'react-intl';

import { CHAPTER_SEVEN_BANKRUPTCY_DICT } from '@dmi/shared/redux/Main/constants';
import { INTL_IDS } from '@dmi/shared/utils/languageDictionary/footerMessages';

import { ConditionalRender } from '../../base_ui';
import { getFormattedAppVersion } from '../../../utils/globalHelpers';
import Disclaimer from '../../Disclaimers';
import FooterIcons from '../../FooterIcons';
import {
  BottomText,
  ChapterSevenBankruptcyContainer,
  ChapterSevenHeader,
  ChapterSevenText,
  DisclosureHeading,
  DisclosureLinksContainer,
  FooterContainer,
  StyledBaseExternalLink,
  StyledFooterButton,
} from './styledComponents';

const appVersion = getFormattedAppVersion();
const {
  header: chapterSevenHeader,
  text: chapterSevenText,
} = CHAPTER_SEVEN_BANKRUPTCY_DICT;

const Footer = ({
  feeStatement,
  footerIconKeys,
  hasChapterSevenBankruptcy,
  hasPDFFeeSchedule,
  selectedLoanPropertyState,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const { formatMessage } = useIntl();

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
  };

  const handleFeeSchedule = (feeStatementHref) => {
    if (hasPDFFeeSchedule) {
      WebBrowser.openBrowserAsync(feeStatementHref);
    } else {
      handleOpenDrawer('feeSchedule');
    }
  };

  return (
    <FooterContainer>
      <ConditionalRender
        Component={() => (
          <ChapterSevenBankruptcyContainer>
            <ChapterSevenHeader>{chapterSevenHeader}</ChapterSevenHeader>
            <ChapterSevenText>{chapterSevenText}</ChapterSevenText>
          </ChapterSevenBankruptcyContainer>
        )}
        shouldRender={hasChapterSevenBankruptcy}
      />
      <BottomText>
        {`© ${new Date().getFullYear()} ${formatMessage({ id: INTL_IDS.DMI_LABEL })}`}
      </BottomText>
      <BottomText>{appVersion}</BottomText>
      <DisclosureLinksContainer>
        <DisclosureHeading>Disclosures</DisclosureHeading>
        <ConditionalRender
          Component={StyledFooterButton}
          propsToPassDown={{
            isFooterButton: true,
            label: `${selectedLoanPropertyState} Foreclosure Hotline`,
            onPress: () => handleOpenDrawer(
              `foreclosureHotline${selectedLoanPropertyState.replace(' ', '')}`,
            ),
          }}
          shouldRender={!!selectedLoanPropertyState}
        />
        <StyledFooterButton
          isFooterButton
          isPrimary
          label="Foreclosure Prevention Case Escalation Unit"
          onPress={() => handleOpenDrawer('foreclosurePrevention')}
        />
        <StyledBaseExternalLink
          href="https://www.servicedisclosure.com"
          label="Loss Mitigation Disclosure for Borrowers"
        />
        <StyledFooterButton
          isFooterButton
          isPrimary
          label="Alternatives to Foreclosure"
          onPress={() => handleOpenDrawer('foreclosureAlternatives')}
        />
        <StyledFooterButton
          isFooterButton
          isPrimary
          label="New York Disclosure Notice"
          onPress={() => handleOpenDrawer('newYorkDisclosure')}
        />
        <StyledFooterButton
          isFooterButton
          isPrimary
          label="Texas Residential Loan Disclosure Notice"
          onPress={() => handleOpenDrawer('texasDisclosure')}
        />
        <StyledFooterButton
          isFooterButton
          isPrimary
          label="Fee Schedule"
          onPress={() => handleFeeSchedule(feeStatement)}
        />
        <StyledFooterButton
          isFooterButton
          isPrimary
          label="Privacy Policy/CCPA"
          onPress={() => handleOpenDrawer('privacyPolicy')}
        />
      </DisclosureLinksContainer>
      <ConditionalRender
        Component={Disclaimer}
        propsToPassDown={{ drawerType, isDrawerOpen, setIsDrawerOpen }}
        shouldRender={!!drawerType}
      />
      <ConditionalRender
        Component={FooterIcons}
        propsToPassDown={{ footerIconKeys }}
        shouldRender={!!footerIconKeys}
      />
    </FooterContainer>
  );
};

Footer.propTypes = {
  feeStatement: T.string,
  footerIconKeys: T.array.isRequired,
  hasChapterSevenBankruptcy: T.bool.isRequired,
  hasPDFFeeSchedule: T.bool.isRequired,
  selectedLoanPropertyState: T.string.isRequired,
};

export default Footer;
