import styled from 'styled-components/native';

import { BottomDrawer, H2, P2 } from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

export const FormInputText = styled(P2)`
  text-align: center;
`;

export const FormInputTitle = styled(H2)`
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const InputWrapper = styled.View`
  margin-top: ${moderateScale(50)}px;
`;

export const PinDrawer = styled(BottomDrawer)`
  margin: 0
`;

export const PinFormInputContainer = styled.View`
  align-items: center;
  padding: ${moderateScale(100)}px ${moderateScale(24)}px 0;
`;
