import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import { useIntl } from 'react-intl';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';

import {
  FormattedMessage,
  H2,
} from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import MailingAddress from './MailingAddress';
import {
  Address,
  BoldText,
  DrawerContainer,
  DrawerContent,
  HeaderWrapper,
  IconWrapper,
  IllustrationWrapper,
  StyledH5,
  StyledP2,
} from './styledComponents';

const PendingIcon = iconDictionary('outlinedPendingPurple',
  { height: `${moderateScale(17.64)}px`, width: `${moderateScale(17.64)}px` });
const DogWaitingIcon = iconDictionary('dogWaiting',
  { height: `${moderateScale(120)}px`, width: `${moderateScale(287)}px` });

const StepOneView = ({
  currentAddress,
  pendingAddress,
  streetAddress,
  unmaskedSelectedLoan,
}) => {
  const { formatMessage } = useIntl();
  const { subheader, text } = LOANS_CONTENT_DICTIONARY.cancelMailingAddress.stepOneView.mobile;

  return (
    <DrawerContainer>
      <DrawerContent>
        <IllustrationWrapper>{DogWaitingIcon}</IllustrationWrapper>
        <HeaderWrapper>
          <IconWrapper>{PendingIcon}</IconWrapper>
          <H2>{subheader}</H2>
        </HeaderWrapper>
        <FormattedMessage
          defaultMessage={formatMessage({ id: text })}
          values={{
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <BoldText>{msg}</BoldText>,
            selectedLoan: unmaskedSelectedLoan.slice(-4),
            streetAddress,
          }}
          WrapperComponent={StyledP2}
        />
        <View>
          <Address>
            <StyledH5>Current Mailing Address</StyledH5>
            <MailingAddress mailingAddress={currentAddress} />
          </Address>
          <Address>
            <StyledH5>Pending Mailing Address</StyledH5>
            <MailingAddress mailingAddress={pendingAddress} />
          </Address>
        </View>
      </DrawerContent>
    </DrawerContainer>
  );
};

StepOneView.propTypes = {
  currentAddress: T.object.isRequired,
  pendingAddress: T.object.isRequired,
  streetAddress: T.string.isRequired,
  unmaskedSelectedLoan: T.string.isRequired,
};

export default StepOneView;
