import styled from 'styled-components/native';

import { CheckboxWithText } from '../../../base_ui';

export const CheckboxContainer = styled.View`
  align-items: center;
  align-self: flex-start;
  flex-direction: row;
`;

export const StyledCheckboxWithText =
  styled(CheckboxWithText).attrs(({ textStyle, theme: { fontSizes: { f4 } } }) =>
    ({ textStyle: { ...textStyle, fontSize: f4 } }))``;
