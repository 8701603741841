import React, { useEffect } from 'react';
import T from 'prop-types';

import { BottomDrawer, StyledScrollView } from '../../base_ui';
import viewDictionary from './viewDictionary';

const NYCLanguagePreferenceModal = ({
  dispatchDisableInstantPostLoginModal,
  dispatchSetInstantPostLoginModalStep,
  isModalViewOpen,
  languagePreferenceProps,
  modalViewStep,
  ...restProps
}) => {
  const { Component } = viewDictionary[modalViewStep];
  const { languagePreference, requestStatus } = languagePreferenceProps;

  useEffect(() => {
    if (['failed', 'succeeded'].includes(requestStatus)) {
      dispatchSetInstantPostLoginModalStep(2);
    }
  }, [dispatchSetInstantPostLoginModalStep, requestStatus]);

  const handleCloseModalView = () => {
    if (requestStatus !== 'loading' && modalViewStep !== 1) {
      dispatchDisableInstantPostLoginModal();
    }
  };

  return (
    <BottomDrawer
      handleClose={handleCloseModalView}
      isVisible={isModalViewOpen}
      style={{ flexGrow: 1 }}
      touchableOpacityStyle={{ flexGrow: 1 }}
    >
      <StyledScrollView>
        <Component
          handleCloseModalView={handleCloseModalView}
          handleSetModalViewStep={dispatchSetInstantPostLoginModalStep}
          languagePreference={languagePreference}
          modalViewStep={modalViewStep}
          requestStatus={requestStatus}
          {...restProps}
        />
      </StyledScrollView>
    </BottomDrawer>
  );
};

NYCLanguagePreferenceModal.propTypes = {
  dispatchDisableInstantPostLoginModal: T.func.isRequired,
  dispatchSetInstantPostLoginModalStep: T.func.isRequired,
  isModalViewOpen: T.bool.isRequired,
  languagePreferenceProps: T.shape({
    languagePreference: T.object.isRequired,
    requestStatus: T.string.isRequired,
  }).isRequired,
  modalViewStep: T.number.isRequired,
};

export default NYCLanguagePreferenceModal;
