import React from 'react';
import { useFocusEffect } from '@react-navigation/native';
import T from 'prop-types';

import LatePaymentWarningModal from './LatePaymentWarningModal';
import { SecondaryHeader } from '../../../ScreenHeaders';
import { StyledScrollView } from '../../../base_ui';
import { AddressHeader, PaymentSummary, RowButton } from '../../sharedComponents';
import { SchedulePaymentContent, StyledDivider, StyledStatusBanner } from './styledComponents';

const SchedulePayment = ({
  address,
  bannerError,
  dispatchChangeScheduledDate,
  dispatchChangeScheduledDateOnDeck,
  dispatchResetBannerError,
  dispatchSetIsModalOpen,
  dueDate,
  isWarningModalOpen,
  loanNumber,
  navigation,
  payFromButtonProps: { noPaymentSource, value },
  route,
  scheduledDate,
  scheduledDateOnDeck,
  totalPayment,
}) => {
  const { params: { isCalculatorFlow } = {} } = route || {};

  useFocusEffect(() => dispatchResetBannerError);

  const handleLatePaymentUpdateOnPress = () => {
    navigation.navigate('PaymentDate');
    dispatchSetIsModalOpen({ key: 'latePaymentWarning', value: false });
    dispatchChangeScheduledDateOnDeck('');
  };

  const handleLatePaymentProceedOnPress = () => {
    /**
     * If Dates screen triggers LatePaymentWarning, sets scheduledDateOnDeck, which would
     * then get upgraded to the scheduledDate here.
     *
     * If Amount screen triggers LatePaymentWarning (AdditionalOnly -> Monthly, after already
     * selected late day), no scheduledDateOnDeck exists. With this case, the user just confirms
     * the scheduledDate they had already chosen before with AdditionalOnly.
     */
    if (scheduledDateOnDeck) {
      dispatchChangeScheduledDate(scheduledDateOnDeck, true);
    }
    dispatchSetIsModalOpen({ key: 'latePaymentWarning', value: false });
  };

  return (
    <StyledScrollView
      showsVerticalScrollIndicator={false}
      stickyHeaderIndices={[0]}
    >
      {/* using .navigate() instead of .goBack() since there are instances in which
    the user may enter this screen from other locations like the cancelConfirmation screen
    and we want this arrow to always navigate back to the payments home page */}
      <SecondaryHeader
        handleBack={() => isCalculatorFlow ?
          navigation.goBack() :
          navigation.navigate('Payments')}
        title="Schedule a One-Time Payment"
      />
      <StyledStatusBanner error={bannerError} />
      <AddressHeader
        address={address}
        dueDate={dueDate}
        loanNumber={loanNumber}
      />
      <SchedulePaymentContent>
        <StyledDivider />
        <RowButton
          accessibilityName="amount"
          handlePress={() => navigation.navigate('EditPayment')}
          label="Amount"
          value={totalPayment}
        />
        <StyledDivider />
        <RowButton
          accessibilityName="pay-from"
          handlePress={() => navigation.navigate('PaymentSourceForm')}
          label="Pay From"
          noPaymentSource={noPaymentSource}
          value={value}
        />
        <StyledDivider />
        <RowButton
          accessibilityName="payment-date"
          handlePress={() => navigation.navigate('PaymentDate')}
          label="Payment Date"
          value={scheduledDate}
        />
        <StyledDivider />
      </SchedulePaymentContent>
      <PaymentSummary
        disabled={noPaymentSource}
        label="Review Payment"
        onPress={() => navigation.navigate('ReviewPaymentDetails')}
        subText={`Due on ${dueDate}`}
        text={totalPayment}
      />
      <LatePaymentWarningModal
        handleCloseModal={handleLatePaymentProceedOnPress}
        handleOnPress={handleLatePaymentUpdateOnPress}
        isOpen={isWarningModalOpen}
      />
    </StyledScrollView>
  );
};

SchedulePayment.propTypes = {
  address: T.string.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeScheduledDate: T.func.isRequired,
  dispatchChangeScheduledDateOnDeck: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  dueDate: T.string.isRequired,
  isWarningModalOpen: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  payFromButtonProps: T.object.isRequired,
  route: T.object.isRequired,
  scheduledDate: T.string.isRequired,
  scheduledDateOnDeck: T.string.isRequired,
  totalPayment: T.string.isRequired,
};

export default SchedulePayment;
