/*
 * Forgot Password Form Input
 */

import React, { Fragment } from 'react';
import T from 'prop-types';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import {
  BaseInternalLink,
  LoginRegisterTextField,
} from '../../base_ui';
import {
  ForgotUsernameContainer,
  RegisterInputContainer,
  StyledLargeProgressButton,
  StyledLoginLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const ForgotPasswordFormInput = ({
  dispatchChangeInput,
  dispatchForgotPassword,
  formErrors,
  formValues,
  handleNextClick,
  status,
}) => (
  <Fragment>
    <RegisterInputContainer>
      <TextFieldWrapper>
        <LoginRegisterTextField
          error={formErrors.username}
          label="Username"
          onChangeText={(newValue) => dispatchChangeInput({
            field: 'username',
            form: 'forgotPassword',
            value: newValue,
          })}
          onSubmitEditing={() => handleNextClick({
            field: 'username',
            form: 'forgotPassword',
            formValues,
            handleSubmitStep: () => dispatchForgotPassword(formValues),
          })}
          returnKeyType="done"
          value={formValues.username}
        />
      </TextFieldWrapper>
      <ForgotUsernameContainer>
        <BaseInternalLink
          label="I forgot my username."
          path="ForgotUsername"
        />
      </ForgotUsernameContainer>
    </RegisterInputContainer>
    <StyledPrimaryDivider />
    <StyledLargeProgressButton
      disabled={!formValues.username}
      label="Next Step"
      onPress={() => handleNextClick({
        field: 'username',
        form: 'forgotPassword',
        formValues: trimFormValues(formValues),
        handleSubmitStep: () => dispatchForgotPassword(trimFormValues(formValues)),
      })}
      status={status.forgotPasswordInitiate}
    />
    <StyledLoginLink
      label="Back"
      path="Login"
      size="medium"
    />
  </Fragment>
);

ForgotPasswordFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchForgotPassword: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleNextClick: T.func.isRequired,
  status: T.object.isRequired,
};

export default ForgotPasswordFormInput;
