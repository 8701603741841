import NewMessageClaimSelect from './ClaimSelect';
import NewMessageCreate from './MessageCreate';
import NewMessageAttachmentReview from './AttachmentReview';
import NewMessageAttachmentUpload from './AttachmentUpload';
import NewMessageConfirmationSucceeded from './ConfirmationSucceeded';
import NewMessageConfirmationFailed from './ConfirmationFailed';

export default {
  1: NewMessageCreate,
  2: NewMessageClaimSelect,
  3: NewMessageAttachmentUpload,
  4: NewMessageAttachmentReview,
  5: NewMessageConfirmationSucceeded,
  6: NewMessageConfirmationFailed,
};
