import {
  ErrorComponent,
  MessageDetailsComponent,
  SecureMessageDocReview,
  SecureMessageDocUpload,
  SuccessComponent,
} from '../../../components/SecureMessaging';

export default {
  1: MessageDetailsComponent,
  2: SecureMessageDocUpload,
  3: SecureMessageDocReview,
  4: SuccessComponent,
  5: ErrorComponent,
};
