/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../scaleHelpers';

export default function BackArrowPurpleMIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(20)} viewBox="0 0 18 20" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M9 19L1 10L9 1" stroke={primary} strokeLinecap="round" />
    </Svg>
  );
}
