/* eslint-disable global-require */
import 'core-js/es/string/match-all';
import React, { useState } from 'react';
import { Provider } from 'react-redux';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { IntlProvider } from 'react-intl';
import { ActionSheetProvider } from '@expo/react-native-action-sheet';

import { LANGUAGE_ENGLISH, LOCALE_ENGLISH_US } from '@dmi/shared/utils/constants';
import languageDictionary from '@dmi/shared/utils/languageDictionary';

import configureStore from './app/utils/configureStore';
import Main from './app/containers/Main';
import HelmetComponent from './app/containers/HelmetComponent';

const initialState = {};
const store = configureStore(initialState);

export default function App() {
  const [language, setLanguage] = useState(LANGUAGE_ENGLISH);
  const { locale, messages } = languageDictionary[language];

  return (
    <Provider store={store}>
      <IntlProvider
        defaultLocale={LOCALE_ENGLISH_US}
        locale={locale}
        messages={messages}
      >
        <HelmetComponent />
        <ActionSheetProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <Main setLanguage={setLanguage} />
          </GestureHandlerRootView>
        </ActionSheetProvider>
      </IntlProvider>
    </Provider>
  );
}
