import React, { Fragment } from 'react';
import T from 'prop-types';

import { CheckboxWithText, ConditionalRender } from '../../../../base_ui';
import FormInputs from './FormInputs';
import { checkFormErrors, checkIncompleteForm } from './helpers';
import {
  ClearButton,
  FormContainer,
  StyledLargePrimaryButton,
  UseExistingContainer,
} from './styledComponents';

const Form = ({
  dispatchClearNewInfo,
  dispatchSetInput,
  dispatchSetUseExisting,
  formErrors,
  formValues,
  handleValidatePress,
  isExistingInfo,
  isNewInfo,
  useExistingInfo,
  useNewInfo,
  validateRoutingLoading,
}) => {
  const isValidateButtonDisabled =
    validateRoutingLoading ||
    checkIncompleteForm(formValues) ||
    checkFormErrors(formErrors);
  return (
    <Fragment>
      <FormContainer>
        <ConditionalRender
          Component={(
            <UseExistingContainer>
              <CheckboxWithText
                checked={useExistingInfo}
                onPress={() => dispatchSetUseExisting(!useExistingInfo)}
                title="Use Existing Account Information"
              />
            </UseExistingContainer>
          )}
          shouldRender={isExistingInfo}
        />
        <FormInputs
          disableInputs={useExistingInfo || useNewInfo}
          dispatchSetInput={dispatchSetInput}
          formErrors={formErrors}
          formValues={formValues}
          handleValidatePress={handleValidatePress}
          isValidateButtonDisabled={isValidateButtonDisabled}
        />
      </FormContainer>
      <ConditionalRender
        Component={ClearButton}
        propsToPassDown={{
          label: 'Clear',
          onPress: dispatchClearNewInfo,
        }}
        shouldRender={isNewInfo && !useExistingInfo}
      />
      <ConditionalRender
        Component={StyledLargePrimaryButton}
        propsToPassDown={{
          disabled: isValidateButtonDisabled,
          label: validateRoutingLoading ? 'Validating...' : 'Validate',
          onPress: handleValidatePress,
        }}
        shouldRender={!useExistingInfo && !useNewInfo}
      />
    </Fragment>
  );
};

Form.propTypes = {
  dispatchClearNewInfo: T.func.isRequired,
  dispatchSetInput: T.func.isRequired,
  dispatchSetUseExisting: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleValidatePress: T.func.isRequired,
  isExistingInfo: T.bool.isRequired,
  isNewInfo: T.bool.isRequired,
  useExistingInfo: T.bool.isRequired,
  useNewInfo: T.bool.isRequired,
  validateRoutingLoading: T.bool.isRequired,
};

export default Form;
