/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

const FlagIcon = (props) => {
  const { colors: { primaryCarbon } } = useTheme();

  return (
    <Svg
      fill={primaryCarbon}
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M0.59985 16.6C0.29985 16.6 -0.000149772 16.3 -0.000149772 16V0.7C-0.10015 0.3 0.19985 0 0.59985 0C0.99985 0 1.19985 0.3 1.19985 0.7V16C1.19985 16.3 0.89985 16.6 0.59985 16.6Z" fill={primaryCarbon} />
      <Path d="M10.1996 11.2996C9.39961 11.2996 8.39961 11.0996 7.49961 10.6996C4.29961 9.29961 1.09961 10.6996 1.09961 10.6996C0.799609 10.7996 0.399609 10.6996 0.299609 10.3996C0.199609 10.0996 0.299609 9.69961 0.599609 9.59961C0.799609 9.49961 4.29961 7.99961 7.99961 9.59961C10.3996 10.5996 12.5996 9.69961 13.3996 9.19961L11.9996 6.09961C11.8996 5.99961 11.8996 5.79961 11.9996 5.59961L13.0996 2.29961C11.7996 2.79961 9.69961 3.29961 7.49961 2.29961C4.29961 0.899609 1.09961 2.29961 1.09961 2.29961C0.699609 2.49961 0.399609 2.39961 0.199609 2.09961C0.0996094 1.79961 0.199609 1.39961 0.499609 1.19961C0.699609 1.09961 4.19961 -0.400391 7.89961 1.19961C10.9996 2.49961 13.6996 0.499609 13.7996 0.499609C13.9996 0.299609 14.2996 0.299609 14.4996 0.499609C14.6996 0.699609 14.7996 0.899609 14.6996 1.19961L13.1996 5.79961L14.6996 9.09961C14.7996 9.39961 14.6996 9.69961 14.4996 9.89961C14.3996 9.99961 12.5996 11.2996 10.1996 11.2996Z" fill={primaryCarbon} />
    </Svg>
  );
};

export default FlagIcon;
