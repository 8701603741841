import styled from 'styled-components/native';
import { FlatList } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { P2, PrimaryDivider } from '../../base_ui';

export const Bullet = styled.View`
  background-color: ${({ theme: { colors: { accentGreen } } }) => accentGreen};
  border-radius: ${moderateScale(3)}px;
  height: ${moderateScale(8)}px;
  width: ${moderateScale(8)}px;
`;

export const BulletWrapper = styled.View`
  padding: 0 ${moderateScale(6)}px ${moderateScale(1)}px 0;
`;

export const DividerRowView = styled.View`
  flex-direction: row;
  padding: ${moderateScale(0)}px ${moderateScale(23)}px;
  width: 100%;
`;

export const PressableRow = styled.Pressable`
  background-color: ${(
    ({
      $isPressing,
      theme: { colors: { accentSilver, dmiWhite } },
    }) => $isPressing ? accentSilver : dmiWhite
  )};
  flex-direction: row;
  padding: ${moderateScale(4)}px ${moderateScale(16)}px;
`;

export const RowCreatorSubjectView = styled.View`
  align-items: flex-start;
  flex-direction: column;
  width: 77%;
`;

export const RowText = styled(P2).attrs({ numberOfLines: 1 })`
  font-family: ${({ $isBold, theme: { fontFamilies: { secondaryFont, secondaryFontBold } } }) =>
    $isBold ? secondaryFontBold : secondaryFont};
  padding-right: ${moderateScale(2)}px;
  width: 100%;
`;

export const RowTextDate = styled(RowText)`
  flex-shrink: 1;
  margin: ${moderateScale(0)}px auto;
  text-align: right;
  width: 23%;
`;

export const RowView = styled.View`
  flex-direction: row;
  padding: ${moderateScale(8)}px;
  width: 100%;
`;

export const SkeletonRowView = styled.View`
  flex-direction: row;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px ${moderateScale(8)}px
    ${moderateScale(32)}px;
`;

export const SkeletonView = styled.View`
  width: ${({ $widthPercent }) => `${$widthPercent}%`};
`;

export const StyledFlatList = styled(FlatList).attrs(() => (
  { contentContainerStyle: { flexGrow: 1 } }
))`
  width: 100%;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
`;
