import React, { Fragment } from 'react';

import FormUI from './FormUI';
import { ImageContainer, StyledImage } from './styledComponents';
import HelperImg from './1.0-Check-Example-327x84.png';

const PaymentSourceForm = (props) => (
  <Fragment>
    <ImageContainer>
      <StyledImage aria-label="payment account info helper" source={HelperImg} />
    </ImageContainer>
    <FormUI {...props} />
  </Fragment>
);

export default PaymentSourceForm;
