import { INTL_IDS } from '@dmi/shared/redux/SecureMessaging/messages';

import iconDictionary from '../../../../../utils/iconDictionary';

const KeyIcon = iconDictionary('key');

/* eslint-disable max-len */
export const noDataMessageProps = {
  body: INTL_IDS.SECURE_MESSAGING_NO_MESSAGE_TEXT,
  icon: KeyIcon,
  isSecureMessage: true,
  title: 'Welcome to secure messaging',
};
