import React, {
  Fragment,
  useEffect,
} from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { fetchOidcConfig } from '@dmi/shared/redux/Main/actions';
import { getError, getMainClientAuthProps } from '@dmi/shared/redux/Main/selectors';

import LoadingDog from '../../../components/LoadingDog';
import AsyncRender from '../../../components/AsyncRender';
import { StyledScrollView } from '../../../components/base_ui';

const SsoOidc = ({
  clientId,
  discoveryUrl,
  dispatchFetchOidcConfig,
  error,
  oidcAuthorizationExtraQueryParams,
  oidcAuthorizationScope,
}) => {
  useEffect(() => {
    if (!error) {
      dispatchFetchOidcConfig({
        clientId,
        discoveryUrl,
        oidcAuthorizationExtraQueryParams,
        oidcAuthorizationScope,
      });
    }
  }, [
    clientId,
    discoveryUrl,
    dispatchFetchOidcConfig,
    error,
    oidcAuthorizationExtraQueryParams,
    oidcAuthorizationScope,
  ]);

  return (
    <StyledScrollView>
      <AsyncRender
        Component={() => null}
        error={error}
        errorComponentProps={{
          body: error,
          link: {},
          title: "You've encountered an error.", // Matches same title as Desktop
        }}
        loading
        LoadingComponent={(
          <Fragment>
            <LoadingDog />
          </Fragment>
        )}
      />
    </StyledScrollView>
  );
};

SsoOidc.propTypes = {
  clientId: T.string.isRequired,
  discoveryUrl: T.string.isRequired,
  dispatchFetchOidcConfig: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]),
  oidcAuthorizationExtraQueryParams: T.object,
  oidcAuthorizationScope: T.string,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  clientId: getMainClientAuthProps('clientId'),
  discoveryUrl: getMainClientAuthProps('discoveryUrl'),
  error: getError('main'),
  oidcAuthorizationExtraQueryParams: getMainClientAuthProps('oidcAuthorizationExtraQueryParams'),
  oidcAuthorizationScope: getMainClientAuthProps('oidcAuthorizationScope'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Main
   */
  dispatchFetchOidcConfig: (payload) => dispatch(fetchOidcConfig(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SsoOidc);
