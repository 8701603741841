/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterUmbrella(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(86)} viewBox="0 0 86 86" width={moderateScale(86)} {...props}>
      <Path d="M41,61c0,2,0,3.51,0,4.21,0,3.35-.39,3.45-.39,3.45-.88,3.36-4.69,3.88-7,3.33s-3.75-3.16-3.81-5.51-1.83-2.15-1.83-2.15c-1.5.13-1.57,1.69-1.57,2s.3,2.57.3,2.57c2.57,7.44,9,6.75,9,6.75,8.61-.39,8.7-8.25,8.7-8.25V61Z" fill={svgHighlight} />
      <Rect fill="#DBE2E8" height="3.2" width="3.34" x="40.96" y="38.61" />
      <Path d="M43,11C11.2,12.82,9.86,42.7,9.86,42.7l.23-.23a14.43,14.43,0,0,1,10.36-4.4,13,13,0,0,1,10.09,5,17.29,17.29,0,0,1,12.24-4.74h.91a17.3,17.3,0,0,1,12.25,4.74,13,13,0,0,1,10.08-5,14.44,14.44,0,0,1,10.37,4.4l.12.23S75.18,12.82,43.41,11Z" fill="#FFF" />
      <Path d="M44.09,11.1c-.23,0-.45,0-.68-.06H43C11.2,12.82,9.86,42.7,9.86,42.7l.23-.23a14.24,14.24,0,0,1,7-4C19,32,26,13.74,44.09,11.1Z" fill="#DBE2E8" />
      <Path d="M69.23,23.51C64.44,17.29,56.51,11.78,43.41,11H43C11.2,12.82,9.86,42.7,9.86,42.7l.23-.23a14.43,14.43,0,0,1,10.36-4.4,13,13,0,0,1,10.09,5,17.29,17.29,0,0,1,12.24-4.74h.91a17.3,17.3,0,0,1,12.25,4.74,13,13,0,0,1,10.08-5,14.44,14.44,0,0,1,10.37,4.4l.12.23a37.88,37.88,0,0,0-5.8-17.09" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M37.28,18.61a51.63,51.63,0,0,0-6.5,21.6" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" />
      <Path d="M40.83,13.29a38,38,0,0,0-2.47,3.51" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" />
      <Path d="M54.87,34.67a50.59,50.59,0,0,1,.72,5.47" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" />
      <Path d="M45,13.29a48.68,48.68,0,0,1,9.41,19.3" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" />
      <Path d="M44.45,56.06v10.6a9.07,9.07,0,0,1-9.68,9,9.28,9.28,0,0,1-8.45-9.36V66A1.71,1.71,0,0,1,28,64.32h0A1.71,1.71,0,0,1,29.74,66v.38a5.83,5.83,0,0,0,5.08,5.87A5.65,5.65,0,0,0,41,66.66V38.52h3.42V53.73" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.18px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="1.18px" x1="42.97" x2="42.97" y1="11.04" y2="7.42" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" x1="40.96" x2="44.41" y1="61.09" y2="61.09" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" x1="34.74" x2="31.71" y1="49.48" y2="54.18" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" x1="24.15" x2="25.91" y1="46.78" y2="45.08" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" x1="51.34" x2="54.37" y1="49.48" y2="54.18" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.75px" x1="61.94" x2="60.18" y1="46.78" y2="45.08" />
    </Svg>
  );
}
