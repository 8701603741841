import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';
import moment from 'moment';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Calculators/Amortize/messages';
import { isValidInputsFunc } from '@dmi/shared/redux/Calculators/Amortize/helpers';
import { DEFAULT_MOBILE_THEME_COLORS } from '@dmi/shared/redux/Main/constants';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import {
  ConditionalRender,
  H3,
  LargeProgressButton,
  OutlinedDropDown,
  P2,
} from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  ButtonContainer,
  CalculatorContainer,
  FlexContainer,
  InputContainer,
  InputLabel,
  ResetButton,
  SectionHeader,
  SelectArrowWrapper,
  SelectDateError,
  SelectDatePicker,
  SelectIconWrapper,
  StyledDivider,
  StyledPaymentTextField,
} from './styledComponents';

const ArrowDownIcon = iconDictionary('arrowRightCarbonS');
const CalendarIcon = iconDictionary('calendarSm');

const Calculator = ({
  amortizationValues: {
    amount,
    interestRate,
    monthlyPaymentAmount,
    numberOfPayments,
    paymentType,
    principalBalance,
    startDate,
    valueType,
  },
  dispatchAmortizeCalculate,
  dispatchChangeInput,
  dispatchResetCalculator,
  dispatchUpdateFormError,
  formErrors: {
    amount: amountError,
    interestRate: interestRateError,
    monthlyPaymentAmount: monthlyPaymentAmountError,
    numberOfPayments: numberOfPaymentsError,
    principalBalance: principalBalanceError,
    startDate: startDateError,
  },
  isCalculateButtonDisabled,
  loanNumber,
  menuItems: {
    paymentTypes,
    valueTypes,
  },
  navigation,
  requestBody,
  status: { amortization: amortizeStatus },
}) => {
  const { formatMessage } = useIntl();

  const ismonthlyPaymentAmount = valueType === 'Monthly Payment Amount';
  const monthlyPaymentAmountProps = {
    errorMessage: monthlyPaymentAmountError,
    keyboardType: 'decimal-pad',
    label: 'Monthly Payment',
    onBlur: () => handleOnBlurFormat('monthlyPaymentAmount', monthlyPaymentAmount),
    onChangeText: (value) => handleOnChangeCurrency({
      field: 'monthlyPaymentAmount',
      value,
    }),
    value: monthlyPaymentAmount,
  };
  const numberOfPaymentsProps = {
    errorMessage: numberOfPaymentsError,
    keyboardType: 'number-pad',
    label: 'Number of Payments',
    leftIcon: <P2>&nbsp;</P2>,
    onChangeText: (value) => handleOnChangeNumber({
      field: 'numberOfPayments',
      value,
    }),
    value: numberOfPayments,
  };
  const outlineDropDownStyle = {
    inputAndroidContainer: {
      height: moderateScale(34),
      width: moderateScale(149),
    },
    placeholder: { color: DEFAULT_MOBILE_THEME_COLORS.primaryCarbon },
    viewContainer: {
      height: moderateScale(34),
      width: moderateScale(149),
    },
  };

  const handleCalculate = () => {
    if (isValidInputsFunc(dispatchUpdateFormError, {
      amount,
      interestRate,
      monthlyPaymentAmount,
      numberOfPayments,
      paymentType,
      principalBalance,
      valueType,
    })) {
      dispatchAmortizeCalculate({ calculateStatus: 'amortization', loanNumber, requestBody });
    }
  };

  const handleOnChangeCurrency = ({ field, value }) => {
    const newValue = value.replace(/\s/g, '');
    const isValidNumber = !Number.isNaN(Number(newValue));
    if (isValidNumber) {
      dispatchChangeInput({
        field,
        form: 'amortization',
        value: newValue,
      });
    }
  };

  const handleOnChangeInterest = ({ field, value }) => {
    const newValue = value.replace(/\s/g, '');
    const num = Number(newValue);
    const isValidNumber = !Number.isNaN(num);
    if (isValidNumber && num >= 0 && num < 100) {
      dispatchChangeInput({
        field,
        form: 'amortization',
        value: newValue,
      });
    }
  };

  const handleOnChangeNumber = ({ field, value }) => {
    const newValue = value.replace(/\s/g, '');
    const isValidNumber = newValue.match(/^[\d]*$/g);
    if (isValidNumber) {
      dispatchChangeInput({
        field,
        form: 'amortization',
        value: newValue,
      });
    }
  };

  const handleOnBlurFormat = (field, value) => {
    if (value !== '') {
      const decimalValues = value.split('.')[1];
      if (!decimalValues || decimalValues.length === 1) {
        const formattedValue = Number(value).toFixed(2);
        dispatchChangeInput({
          field,
          form: 'amortization',
          value: formattedValue,
        });
      }
    }
  };

  return (
    <CalculatorContainer>
      <SectionHeader>
        <H3>Amortization Calculator</H3>
      </SectionHeader>
      <StyledDivider />
      <InputContainer>
        <InputLabel>Input Value Type</InputLabel>
        <OutlinedDropDown
          accessibilityName="Input-value-type"
          menuItems={valueTypes}
          onChange={(value) => dispatchChangeInput({
            field: 'valueType',
            form: 'amortization',
            value,
          })}
          placeholder={!valueType ? { label: 'Select', value: '' } : {}}
          selected={valueType}
          style={outlineDropDownStyle}
        />
      </InputContainer>
      <FlexContainer>
        <ConditionalRender
          Component={(
            <View>
              <StyledPaymentTextField
                hasFluidWidth
                rendersCheckbox={false}
                {... (ismonthlyPaymentAmount ?
                  monthlyPaymentAmountProps :
                  numberOfPaymentsProps
                )}
              />
              <StyledPaymentTextField
                errorMessage={principalBalanceError}
                hasFluidWidth
                keyboardType="decimal-pad"
                label={formatMessage({ id: INTL_IDS.CALCULATOR_LABEL_LOANAMOUNT })}
                onBlur={() => handleOnBlurFormat('principalBalance', principalBalance)}
                onChangeText={(value) => handleOnChangeCurrency({
                  field: 'principalBalance',
                  value,
                })}
                rendersCheckbox={false}
                value={principalBalance}
              />
              <StyledPaymentTextField
                errorMessage={interestRateError}
                hasFluidWidth
                keyboardType="decimal-pad"
                label={formatMessage({ id: INTL_IDS.CALCULATOR_LABEL_INTERESTRATE })}
                leftIcon={<P2>&nbsp;</P2>}
                onBlur={() => handleOnBlurFormat('interestRate', interestRate)}
                onChangeText={(value) => handleOnChangeInterest({
                  field: 'interestRate',
                  value,
                })}
                rendersCheckbox={false}
                rightIcon={<P2>%</P2>}
                value={interestRate}
              />
              <InputContainer>
                <InputLabel>Additional Payment Type</InputLabel>
                <OutlinedDropDown
                  accessibilityName="Additional-payment-type"
                  menuItems={paymentTypes}
                  onChange={(value) => {
                    dispatchChangeInput({
                      field: 'paymentType',
                      form: 'amortization',
                      value,
                    });
                    dispatchUpdateFormError({
                      field: 'amount',
                      form: 'amortization',
                      value: '',
                    });
                  }}
                  selected={paymentType}
                  style={outlineDropDownStyle}
                />
              </InputContainer>
              <ConditionalRender
                Component={(
                  <View>
                    <StyledPaymentTextField
                      errorMessage={interpolate(amountError, { selectedAdjustment: `$${amount}` })}
                      hasFluidWidth
                      keyboardType="decimal-pad"
                      label="Additional Payment"
                      onBlur={() => handleOnBlurFormat('amount', amount)}
                      onChangeText={(value) => handleOnChangeCurrency({
                        field: 'amount',
                        value,
                      })}
                      rendersCheckbox={false}
                      value={amount}
                    />
                    <InputContainer>
                      <InputLabel>Additional Payment Start Date</InputLabel>
                      <SelectDatePicker
                        $error={!!startDateError}
                        onPress={() => navigation.navigate('CalculatorsCalendar')}
                      >
                        <SelectIconWrapper>{CalendarIcon}</SelectIconWrapper>
                        <P2>{startDate ? moment(startDate).format('M/D/YYYY') : 'Select'}</P2>
                        <SelectArrowWrapper>{ArrowDownIcon}</SelectArrowWrapper>
                      </SelectDatePicker>
                    </InputContainer>
                    <ConditionalRender
                      Component={(
                        <SelectDateError>{startDateError}</SelectDateError>
                      )}
                      shouldRender={!!startDateError}
                    />
                  </View>
                )}
                shouldRender={paymentType !== 'Optional'}
              />
            </View>
          )}
          shouldRender={!!valueType}
        />
      </FlexContainer>
      <ConditionalRender
        Component={(
          <ButtonContainer>
            <LargeProgressButton
              disabled={isCalculateButtonDisabled}
              label="Calculate"
              onPress={handleCalculate}
              status={amortizeStatus}
            />
            <ResetButton
              isPrimary
              label="Reset"
              onPress={() => dispatchResetCalculator('amortization')}
            />
          </ButtonContainer>
        )}
        shouldRender={!!valueType}
      />
    </CalculatorContainer>
  );
};

Calculator.propTypes = {
  amortizationValues: T.object.isRequired,
  dispatchAmortizeCalculate: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetCalculator: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  formErrors: T.object.isRequired,
  isCalculateButtonDisabled: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  menuItems: T.shape({
    paymentTypes: T.array.isRequired,
    valueTypes: T.array.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
  requestBody: T.object.isRequired,
  status: T.object.isRequired,
};

export default Calculator;
