import React, { useState } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Payoff/messages';

import iconDictionary from '../../../utils/iconDictionary';
import { IconView, PressableRow, RowText } from './styledComponents';
import { moderateScale } from '../../../utils/scaleHelpers';

const DownloadIcon = iconDictionary('downloadL',
  { height: moderateScale(16), width: moderateScale(16) });
const ArrowIcon = iconDictionary('arrowRightCarbonS', { height: moderateScale(16) });

const DataItem = ({
  handleOnPressRow,
  payoffDate,
  requestType,
  token,
}) => {
  const [isPressing, setIsPressing] = useState(false);
  const { formatMessage } = useIntl();

  const IconToRender = Platform.OS === 'ios' ? ArrowIcon : DownloadIcon;
  const requestTypeText = requestType === 'PayOff'
    ? formatMessage({ id: INTL_IDS.PAYOFFHISTORY_TYPE_PAYOFF })
    : formatMessage({ id: INTL_IDS.PAYOFFHISTORY_TYPE_PAYDOWN });

  return (
    <PressableRow
      $isPressing={isPressing}
      aria-label={`download ${requestTypeText} date of ${payoffDate}`}
      onPress={() => handleOnPressRow(token)}
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      role="button"
    >
      <RowText $widthPercent={43}>
        {requestTypeText} Date of {payoffDate}
      </RowText>
      <IconView>{IconToRender}</IconView>
    </PressableRow>
  );
};

DataItem.propTypes = {
  handleOnPressRow: T.func.isRequired,
  payoffDate: T.string.isRequired,
  requestType: T.string.isRequired,
  token: T.string.isRequired,
};

export default DataItem;
