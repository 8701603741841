/* eslint-disable react/jsx-sort-props */
/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function ChatBubble(props) {
  return (
    <Svg
      fill="#282828"
      height={moderateScale(18)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <Path
        d="M1.5,17.1c-0.2,0-0.3-0.1-0.4-0.2c-0.2-0.2-0.2-0.4-0.1-0.6l1.4-4c-0.6-1.1-0.9-2.4-0.9-3.7c0-4.4,3.5-7.9,7.9-7.9
        s7.9,3.5,7.9,7.9c0,4.4-3.5,7.9-7.9,7.9c-1.1,0-2.2-0.2-3.3-0.7l-4.3,1.3C1.6,17.1,1.6,17.1,1.5,17.1z M9.2,2C5.6,2,2.6,5,2.6,8.6
        c0,1.2,0.3,2.3,0.9,3.3c0.1,0.2,0.1,0.3,0.1,0.5l-1.1,3.1l3.3-1c0.2,0,0.3,0,0.5,0c0.9,0.5,1.9,0.7,3,0.7c3.7,0,6.6-3,6.6-6.6
        S12.9,2,9.2,2z"
      />
      <Path d="M12.9,7.7H5.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h7.1c0.3,0,0.6,0.3,0.6,0.6S13.3,7.7,12.9,7.7z" />
      <Path d="M10.8,10.8H5.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h5.1c0.3,0,0.6,0.3,0.6,0.6S11.2,10.8,10.8,10.8z" />
    </Svg>
  );
}
