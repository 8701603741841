import styled from 'styled-components/native';

import { IconButton, P3 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const LinkText = styled(P3)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const LoanCardPendingContainer = styled.View`
  margin: ${moderateScale(12)}px ${moderateScale(24)}px ${moderateScale(19)}px ;
`;

export const LoanCardSelectContainer = styled.View`
  flex-direction: row;
  margin: ${moderateScale(19)}px ${moderateScale(24)}px
    ${moderateScale(19)}px ${moderateScale(14)}px;
`;

export const LoanCardWrapper = styled.View`
  flex: 1;
`;

export const PendingBannerContainer = styled.View`
  align-items: center;
  border-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: ${moderateScale(8)}px;
  border-style: solid;
  border-width: ${moderateScale(1)}px;
  flex-direction: row;
  margin-bottom: ${moderateScale(15)}px;
  padding: ${moderateScale(7)}px ${moderateScale(12)}px;
`;

export const PendingBannerText = styled(P3)`
  margin-left: ${moderateScale(8)}px;
`;

export const RadioButtonWrapper = styled.View`
  align-items: flex-end;
  justify-content: center;
  min-width: ${moderateScale(44)}px;
  width: ${moderateScale(44)}px;
`;

export const StyledIconButton = styled(IconButton)`
  align-items: flex-end;
  padding-right: ${moderateScale(12)}px;
`;
