import React, { Fragment } from 'react';
import T from 'prop-types';

import AsyncRender from '../../../AsyncRender';
import { SecondaryHeader } from '../../../ScreenHeaders';
import Form from './Form';
import Skeleton from './Skeleton';

const PaperlessForm = ({
  error,
  fetchErrorCopy,
  fetchPaperlessLoading,
  navigation,
  ...restProps
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={error ? undefined : () => navigation.goBack()}
      title={error ? 'Error' : 'Paperless Billing'}
    />
    <AsyncRender
      Component={Form}
      error={!!error}
      errorComponentProps={{
        ...fetchErrorCopy,
        link: { label: 'Return to Settings', path: 'Settings' },
      }}
      loading={fetchPaperlessLoading}
      LoadingComponent={Skeleton}
      propsToPassDown={restProps}
    />
  </Fragment>
);

PaperlessForm.propTypes = {
  error: T.oneOfType([T.bool, T.string]).isRequired,
  fetchErrorCopy: T.object.isRequired,
  fetchPaperlessLoading: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default PaperlessForm;
