/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavLogoutActiveLIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M19.73 7.10996V1.99996C19.7274 1.83462 19.6598 1.67693 19.542 1.56094C19.4241 1.44494 19.2654 1.37994 19.1 1.37996H4.63C4.54774 1.37864 4.46604 1.39369 4.38966 1.42426C4.31328 1.45482 4.24375 1.50028 4.18511 1.55798C4.12648 1.61569 4.07991 1.68449 4.04813 1.76037C4.01635 1.83625 3.99999 1.9177 4 1.99996V22C3.99998 22.1653 4.06498 22.3241 4.18097 22.4419C4.29697 22.5598 4.45465 22.6273 4.62 22.63H19.1C19.2594 22.6301 19.4127 22.569 19.5281 22.4591C19.6436 22.3492 19.7123 22.1991 19.72 22.04V16.76V7.10996H19.73ZM19.44 12.48L15 16.91C14.9363 16.9791 14.859 17.0342 14.773 17.0719C14.6869 17.1096 14.594 17.1291 14.5 17.1291C14.406 17.1291 14.3131 17.1096 14.227 17.0719C14.141 17.0342 14.0637 16.9791 14 16.91C13.876 16.7829 13.8066 16.6125 13.8066 16.435C13.8066 16.2575 13.876 16.087 14 15.96L17.3 12.66H7.79C7.69446 12.6716 7.59754 12.6629 7.50564 12.6343C7.41374 12.6057 7.32895 12.5579 7.2569 12.4941C7.18484 12.4303 7.12715 12.3519 7.08763 12.2641C7.04812 12.1764 7.02769 12.0812 7.02769 11.985C7.02769 11.8887 7.04812 11.7936 7.08763 11.7058C7.12715 11.618 7.18484 11.5397 7.2569 11.4759C7.32895 11.4121 7.41374 11.3643 7.50564 11.3357C7.59754 11.3071 7.69446 11.2983 7.79 11.31H17.37L14.05 7.99996C13.9843 7.9343 13.9323 7.85635 13.8967 7.77056C13.8612 7.68477 13.8429 7.59282 13.8429 7.49996C13.8429 7.4071 13.8612 7.31516 13.8967 7.22937C13.9323 7.14358 13.9843 7.06562 14.05 6.99996C14.1826 6.86735 14.3625 6.79286 14.55 6.79286C14.6429 6.79286 14.7348 6.81115 14.8206 6.84668C14.9064 6.88222 14.9843 6.9343 15.05 6.99996L19.51 11.46C19.5723 11.5231 19.6212 11.5981 19.6539 11.6806C19.6865 11.7631 19.7022 11.8513 19.7 11.94C19.7024 12.0441 19.68 12.1473 19.6348 12.2412C19.5896 12.335 19.5229 12.4169 19.44 12.48Z" fill={primary} />
    </Svg>
  );
}
