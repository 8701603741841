import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import makeSelectMain, { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import { selectCollectionsPhoneNumber } from '@dmi/shared/redux/Help/selectors';
import { selectIsSecureMessagingTreatmentOn } from '@dmi/shared/redux/SecureMessaging/selectors';

import { ContactInformation as ContactInformationView } from '../../components/Help';
import { StyledScrollView } from '../../components/base_ui';
import { SecondaryHeader } from '../../components/ScreenHeaders';

const ContactInformationScreen = ({
  collectionsPhoneNumber,
  customerServicePhoneNumber,
  isLoggedIn,
  isSecureMessagingTreatmentOn,
  navigation,
}) => {
  const phoneNumberToUse = customerServicePhoneNumber || '1-800-669-4268';

  return (
    <StyledScrollView>
      <SecondaryHeader
        handleBack={navigation.goBack}
        title="Contact Information"
      />
      <ContactInformationView
        collectionsPhoneNumber={collectionsPhoneNumber}
        customerServicePhoneNumber={phoneNumberToUse}
        isLoggedIn={isLoggedIn}
        isSecureMessagingTreatmentOn={isSecureMessagingTreatmentOn}
        navigation={navigation}
      />
    </StyledScrollView>
  );
};

ContactInformationScreen.propTypes = {
  collectionsPhoneNumber: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  isLoggedIn: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isLoggedIn: makeSelectMain('isLoggedIn'),
  isSecureMessagingTreatmentOn: selectIsSecureMessagingTreatmentOn(),
  /**
   * Store: Help
   */
  // eslint-disable-next-line sort-keys
  collectionsPhoneNumber: selectCollectionsPhoneNumber(),
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(ContactInformationScreen);
