import React from 'react';
import T from 'prop-types';

import { ConditionalRender, StyledScrollView } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import { PaymentModal } from '../sharedComponents';
import ErrorComponent from './ErrorComponent';
import SuccessComponent from './SuccessComponent';

const CancelConfirmation = ({
  amount,
  confirmationDictionary,
  confirmationNumber,
  confirmationType,
  customerServicePhoneNumber,
  date,
  dispatchSetIsModalOpen,
  infoSectionData,
  modalProps,
  navigation,
  status,
  title,
}) => (
  <StyledScrollView>
    <SecondaryHeader
      handleDone={confirmationNumber ? () => navigation.navigate('Payments') : null}
      title={title}
    />
    <ConditionalRender
      Component={SuccessComponent}
      FallbackComponent={ErrorComponent}
      propsToPassDown={{
        amount,
        confirmationDictionary,
        confirmationNumber,
        confirmationType,
        customerServicePhoneNumber,
        date,
        dispatchSetIsModalOpen,
        infoSectionData,
        navigation,
        status,
      }}
      shouldRender={!!confirmationNumber}
    />
    <ConditionalRender
      Component={PaymentModal}
      propsToPassDown={modalProps}
      shouldRender={!!modalProps && !!modalProps.isOpen}
    />
  </StyledScrollView>
);

CancelConfirmation.propTypes = {
  amount: T.string,
  confirmationDictionary: T.object.isRequired,
  confirmationNumber: T.string,
  confirmationType: T.string,
  customerServicePhoneNumber: T.string,
  date: T.string,
  dispatchSetIsModalOpen: T.func,
  infoSectionData: T.array,
  modalProps: T.object,
  navigation: T.object.isRequired,
  status: T.string,
  title: T.string.isRequired,
};

export default CancelConfirmation;
