import React, { useEffect } from 'react';
import T from 'prop-types';

import { BottomDrawer, StyledScrollView } from '../../base_ui';
import viewDictionary from './viewDictionary';

const PaperlessBillingModal = ({
  dispatchDisableInstantPostLoginModal,
  dispatchPutPaperlessSetting,
  dispatchSetInstantPostLoginModalStep,
  isModalViewOpen,
  loanNumber,
  modalViewStep,
  paperlessBillingProps,
  ...restProps
}) => {
  const { Component } = viewDictionary[modalViewStep];
  const { requestStatus } = paperlessBillingProps;

  useEffect(() => {
    if (requestStatus === 'succeeded') {
      dispatchSetInstantPostLoginModalStep(3);
    } else if (requestStatus === 'failed') {
      dispatchSetInstantPostLoginModalStep(4);
    }
  }, [dispatchSetInstantPostLoginModalStep, requestStatus]);

  const handleCloseModalView = () => {
    if (requestStatus !== 'loading') {
      dispatchDisableInstantPostLoginModal();
    }
  };

  const handleOnSubmit = () => dispatchPutPaperlessSetting({
    loanNumber,
    requestedOperation: 'Request_Enable',
  });

  return (
    <BottomDrawer
      handleClose={handleCloseModalView}
      isVisible={isModalViewOpen}
      pressableStyle={{ flexGrow: 1 }}
      style={{ flexGrow: 1 }}
    >
      <StyledScrollView>
        <Component
          handleCloseModalView={handleCloseModalView}
          handleOnSubmit={handleOnSubmit}
          handleSetModalViewStep={dispatchSetInstantPostLoginModalStep}
          modalViewStep={modalViewStep}
          paperlessBillingProps={paperlessBillingProps}
          {...restProps}
        />
      </StyledScrollView>
    </BottomDrawer>
  );
};

PaperlessBillingModal.propTypes = {
  dispatchDisableInstantPostLoginModal: T.func.isRequired,
  dispatchPutPaperlessSetting: T.func.isRequired,
  dispatchSetInstantPostLoginModalStep: T.func.isRequired,
  isModalViewOpen: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  modalViewStep: T.number.isRequired,
  paperlessBillingProps: T.shape({
    confirmationData: T.object.isRequired,
    requestStatus: T.string.isRequired,
  }).isRequired,
};

export default PaperlessBillingModal;
