import React from 'react';
import T from 'prop-types';

import viewDictionary from './viewDictionary';

const PayoffHomeButtons = ({
  availablePayOffReasons,
  dateSelectorIsDisabled,
  dispatchChangePayoffStep,
  dispatchClearStatement,
  dispatchCreatePayoffStatement,
  dispatchUpdateRequestType,
  dispatchUpdateSelectedDate,
  dispatchUpdateSelectedPayOffReason,
  loanNumber,
  navigation,
  payoffRequestBody,
  payoffStep,
  requestType,
  selectedDate,
  statement,
  view,
}) => {
  const ViewToRender = viewDictionary[view];
  const propsToPassDown = {
    availablePayOffReasons,
    dateSelectorIsDisabled,
    dispatchChangePayoffStep,
    dispatchClearStatement,
    dispatchCreatePayoffStatement,
    dispatchUpdateRequestType,
    dispatchUpdateSelectedDate,
    dispatchUpdateSelectedPayOffReason,
    loanNumber,
    navigation,
    payoffRequestBody,
    payoffStep,
    requestType,
    selectedDate,
    statement,
  };

  return <ViewToRender {...propsToPassDown} />;
};

PayoffHomeButtons.propTypes = {
  availablePayOffReasons: T.array.isRequired,
  dateSelectorIsDisabled: T.bool.isRequired,
  dispatchChangePayoffStep: T.func.isRequired,
  dispatchClearStatement: T.func.isRequired,
  dispatchCreatePayoffStatement: T.func.isRequired,
  dispatchUpdateRequestType: T.func.isRequired,
  dispatchUpdateSelectedDate: T.func.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  payoffRequestBody: T.object.isRequired,
  payoffStep: T.number.isRequired,
  requestType: T.string.isRequired,
  selectedDate: T.string.isRequired,
  statement: T.shape({
    document: T.string.isRequired,
    mobileDocument: T.oneOfType([T.object, T.string]).isRequired,
  }),
  view: T.string.isRequired,
};

export default PayoffHomeButtons;
