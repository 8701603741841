/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

const PendingGray = (props) => {
  const { colors: { accentGray, dmiWhite } } = useTheme();

  return (
    <Svg
      fill={accentGray}
      height={moderateScale(24)}
      viewBox="0 0 24 24"
      width={moderateScale(24)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M23.2602 12C23.2602 18.2187 18.219 23.26 12.0002 23.26C5.78151 23.26 0.740234 18.2187 0.740234 12C0.740234 5.78126 5.78151 0.73999 12.0002 0.73999C18.219 0.73999 23.2602 5.78126 23.2602 12Z" stroke={accentGray} />
      <Path d="M8.18955 15.4999C7.99525 15.5045 7.80478 15.4455 7.64722 15.3317C7.48966 15.2179 7.37369 15.0556 7.31701 14.8697C7.26033 14.6838 7.26606 14.4845 7.33333 14.3021C7.4006 14.1198 7.52571 13.9645 7.68955 13.8599L11.1296 11.5299V5.81991C11.1282 5.70088 11.1505 5.58278 11.1952 5.47243C11.2398 5.36208 11.3059 5.26168 11.3896 5.17704C11.4733 5.09241 11.573 5.02522 11.6828 4.97936C11.7927 4.93351 11.9105 4.9099 12.0296 4.90991V4.90991C12.2682 4.90991 12.4972 5.00473 12.6659 5.17352C12.8347 5.3423 12.9296 5.57122 12.9296 5.80991V11.9999C12.929 12.148 12.8922 12.2938 12.8225 12.4245C12.7528 12.5552 12.6522 12.6669 12.5296 12.7499L8.68955 15.3499C8.54111 15.4477 8.36729 15.4998 8.18955 15.4999V15.4999Z" fill={dmiWhite} />
    </Svg>
  );
};

export default PendingGray;
