/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavCalculatorDefaultLIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M18.1903 0.730079H6.00031C5.71642 0.723413 5.43406 0.773582 5.16983 0.877636C4.90561 0.98169 4.66486 1.13753 4.46175 1.33598C4.25863 1.53444 4.09725 1.77151 3.9871 2.03325C3.87694 2.29498 3.82023 2.57611 3.82031 2.86008V21.0001C3.82289 21.2824 3.88155 21.5613 3.99288 21.8207C4.10422 22.0802 4.26601 22.3148 4.46886 22.5112C4.6717 22.7075 4.91155 22.8616 5.17446 22.9644C5.43737 23.0672 5.71809 23.1167 6.00031 23.1101H18.1903C18.7527 23.1101 19.2925 22.8888 19.693 22.4939C20.0935 22.0991 20.3224 21.5624 20.3303 21.0001V2.86008C20.3277 2.29425 20.101 1.7525 19.7 1.35333C19.299 0.954163 18.7561 0.730073 18.1903 0.730079ZM19.0803 21.0001C19.0803 21.1165 19.0572 21.2317 19.0124 21.3392C18.9675 21.4466 18.9018 21.544 18.819 21.6259C18.7363 21.7077 18.6381 21.7724 18.5301 21.816C18.4222 21.8596 18.3067 21.8814 18.1903 21.8801H6.00031C5.88027 21.8884 5.75981 21.8717 5.64654 21.8311C5.53328 21.7905 5.42967 21.7268 5.34227 21.6441C5.25487 21.5614 5.18557 21.4615 5.13876 21.3506C5.09195 21.2397 5.06864 21.1204 5.07031 21.0001V2.86008C5.07281 2.74188 5.09909 2.62539 5.1476 2.51757C5.19611 2.40976 5.26585 2.31282 5.35265 2.23255C5.43946 2.15228 5.54154 2.09033 5.65282 2.05039C5.76409 2.01045 5.88228 1.99334 6.00031 2.00008H18.1903C18.4246 2.00006 18.6495 2.09246 18.8161 2.25721C18.9828 2.42196 19.0777 2.64577 19.0803 2.88008V21.0001Z" fill="black" />
      <Path d="M7.12988 8.84999H16.9999C17.1325 8.84999 17.2597 8.79731 17.3534 8.70354C17.4472 8.60978 17.4999 8.4826 17.4999 8.34999V4.23999C17.4999 4.10738 17.4472 3.98021 17.3534 3.88644C17.2597 3.79267 17.1325 3.73999 16.9999 3.73999H7.12988C6.99727 3.73999 6.8701 3.79267 6.77633 3.88644C6.68256 3.98021 6.62988 4.10738 6.62988 4.23999V8.34999C6.62988 8.4826 6.68256 8.60978 6.77633 8.70354C6.8701 8.79731 6.99727 8.84999 7.12988 8.84999ZM7.62988 4.73999H16.4699V7.84999H7.62988V4.73999Z" fill="black" />
      <Path d="M10.9999 10.1001H7.12988C6.99727 10.1001 6.8701 10.1528 6.77633 10.2465C6.68256 10.3403 6.62988 10.4675 6.62988 10.6001V14.0801C6.62988 14.2127 6.68256 14.3399 6.77633 14.4337C6.8701 14.5274 6.99727 14.5801 7.12988 14.5801H10.9999C11.1317 14.5775 11.2574 14.524 11.3506 14.4308C11.4438 14.3376 11.4973 14.2119 11.4999 14.0801V10.6001C11.4999 10.4675 11.4472 10.3403 11.3534 10.2465C11.2597 10.1528 11.1325 10.1001 10.9999 10.1001ZM10.4999 13.5801H7.62988V11.1001H10.4599L10.4999 13.5801Z" fill="black" />
      <Path d="M10.9999 15.77H7.12988C6.99727 15.77 6.8701 15.8227 6.77633 15.9165C6.68256 16.0102 6.62988 16.1374 6.62988 16.27V19.75C6.62988 19.8826 6.68256 20.0098 6.77633 20.1036C6.8701 20.1973 6.99727 20.25 7.12988 20.25H10.9999C11.1317 20.2475 11.2574 20.194 11.3506 20.1007C11.4438 20.0075 11.4973 19.8818 11.4999 19.75V16.27C11.4999 16.1374 11.4472 16.0102 11.3534 15.9165C11.2597 15.8227 11.1325 15.77 10.9999 15.77ZM10.4999 19.25H7.62988V16.77H10.4599L10.4999 19.25Z" fill="black" />
      <Path d="M17.0004 10.1001H13.1904C13.0578 10.1001 12.9306 10.1528 12.8369 10.2465C12.7431 10.3403 12.6904 10.4675 12.6904 10.6001V14.0801C12.6904 14.2127 12.7431 14.3399 12.8369 14.4337C12.9306 14.5274 13.0578 14.5801 13.1904 14.5801H17.0004C17.1322 14.5775 17.2579 14.524 17.3512 14.4308C17.4444 14.3376 17.4979 14.2119 17.5004 14.0801V10.6001C17.5004 10.4675 17.4478 10.3403 17.354 10.2465C17.2602 10.1528 17.133 10.1001 17.0004 10.1001ZM16.5004 13.5801H13.6904V11.1001H16.5204L16.5004 13.5801Z" fill="black" />
      <Path d="M17.0004 15.77H13.1904C13.0578 15.77 12.9306 15.8227 12.8369 15.9165C12.7431 16.0102 12.6904 16.1374 12.6904 16.27V19.75C12.6904 19.8826 12.7431 20.0098 12.8369 20.1036C12.9306 20.1973 13.0578 20.25 13.1904 20.25H17.0004C17.1322 20.2475 17.2579 20.194 17.3512 20.1007C17.4444 20.0075 17.4979 19.8818 17.5004 19.75V16.27C17.5004 16.1374 17.4478 16.0102 17.354 15.9165C17.2602 15.8227 17.133 15.77 17.0004 15.77ZM16.5004 19.25H13.6904V16.77H16.5204L16.5004 19.25Z" fill="black" />
    </Svg>
  );
}
