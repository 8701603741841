import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';

export const LinkContainer = styled.View`
  align-items: center;
  padding: ${moderateScale(14)}px 0;
`;

export const RowContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(14)}px 0;
`;

export const RowStatusContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding-bottom: ${moderateScale(6)}px;
`;
