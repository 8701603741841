import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import { FormattedMessage as IntlFormattedMessage, useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Help/messages';

import {
  DISASTER_ADDITIONAL_INFO_CARDS_ARRAY,
  DISASTER_CARDS_ARRAY,
} from '@dmi/shared/redux/Help/constants';

import AdditionalInfoCard from './AdditionalInfoCard';
import DisasterCard from './DisasterCard';
import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import { ConditionalRender, FormattedMessage, StyledScrollView } from '../../base_ui';
import Disclaimers from '../../Disclaimers';
import {
  DisasterContainer,
  IllustrationWrapper,
  StyledH2,
  StyledInternalLink,
  StyledP2,
  StyledP3,
  StyledSubHeader,
} from './styledComponents';

import LossDraftClaimView from './LossDraftClaim';

const DisasterHome = iconDictionary('disasterHome');

const DisasterAssistance = ({
  hasLossDraftClaims,
  isMurabahaAccount,
  navigation,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [cardToDisplay, setCardToDisplay] = useState('');
  const { formatMessage } = useIntl();

  const handlePress = (cardName) => {
    setCardToDisplay(cardName);
    setIsDrawerOpen(true);
  };

  const interpolatedMessageValues = {
    // eslint-disable-next-line react/prop-types
    link: ({ msg }) => (
      <StyledInternalLink
        isDisclosureLink
        isPrimary
        label={msg}
        path="Glossary"
        size="medium"
      />
    ),
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Disaster Assistance"
      />
      <StyledScrollView showsVerticalScrollIndicator={false}>
        <DisasterContainer>
          <ConditionalRender
            Component={LossDraftClaimView}
            FallbackComponent={(
              <Fragment>
                <IllustrationWrapper>
                  {DisasterHome}
                </IllustrationWrapper>
                <StyledH2>Get back on track after a natural disaster</StyledH2>
                <StyledP2>
                  <IntlFormattedMessage id={INTL_IDS.DISASTER_ASSISTANCE_HEADER_TEXT} />
                </StyledP2>
              </Fragment>
            )}
            propsToPassDown={{ navigation }}
            shouldRender={hasLossDraftClaims}
          />
          <StyledSubHeader>Follow the steps below</StyledSubHeader>
          {DISASTER_CARDS_ARRAY.map(({
            disclosureName,
            iconName,
            label,
            path,
            text,
            title,
          }) => (
            <DisasterCard
              key={title}
              Icon={iconDictionary(iconName)}
              label={
                isMurabahaAccount && path && path.mobile === 'LearningCenter' ?
                  '' : label
              }
              text={(
                <FormattedMessage
                  defaultMessage={formatMessage({ id: text })}
                  values={interpolatedMessageValues}
                  WrapperComponent={StyledP3}
                />
              )}
              title={title}
              {...(path && { path: path.mobile })}
              {...(disclosureName && { onPress: () => handlePress(disclosureName) })}
            />
          ))}
          <StyledSubHeader>Additional Information</StyledSubHeader>
          {DISASTER_ADDITIONAL_INFO_CARDS_ARRAY.map(({
            disclosureName,
            iconName,
            text,
            title,
          }) => (
            <AdditionalInfoCard
              key={title}
              Icon={iconDictionary(iconName)}
              onPress={() => handlePress(disclosureName)}
              text={text}
              title={title}
            />
          ))}
        </DisasterContainer>
        <ConditionalRender
          Component={Disclaimers}
          propsToPassDown={{
            drawerType: cardToDisplay,
            isDrawerOpen,
            setIsDrawerOpen,
          }}
          shouldRender={!!cardToDisplay}
        />
      </StyledScrollView>
    </Fragment>
  );
};

DisasterAssistance.propTypes = {
  hasLossDraftClaims: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default DisasterAssistance;
