/* eslint-disable max-len */
import React, { Fragment } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import PrimaryDocUploadButton from '../../DocUpload/PrimaryDocPickerButton';
import { AlertBanner } from '../../base_ui';
import {
  BannerWrapper,
  ButtonWrapper,
  Container,
  IllustrationWrapper,
  StyledH2,
  StyledP2,
} from './styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(210)}px` });

const InsuranceUploadComponent = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  reviewScreenName,
}) => (
  <Fragment>
    <Container>
      <IllustrationWrapper $isUpload>
        {UploadHouse}
      </IllustrationWrapper>
      <StyledH2>Upload your document</StyledH2>
      <StyledP2>
        Make sure your document is readable, complete, and includes any signatures if applicable.
      </StyledP2>
      <StyledP2>
        If you are submitting an insurance card, please upload an image of the front and back of the card.
      </StyledP2>
      <BannerWrapper>
        <AlertBanner
          boldText="Formats accepted: "
          text="JPEG, JPG, PDF, PNG, TIFF, or GIF (Max size 10 MB)."
        />
      </BannerWrapper>
    </Container>
    <ButtonWrapper>
      <PrimaryDocUploadButton
        chooseDocumentRequestStatus={chooseDocumentRequestStatus}
        dispatchChooseDocument={dispatchChooseDocument}
        reviewScreenName={reviewScreenName}
      />
    </ButtonWrapper>
  </Fragment>
);

InsuranceUploadComponent.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  reviewScreenName: T.string.isRequired,
};

export default InsuranceUploadComponent;
