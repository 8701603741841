import {
  AirplaneDesktop,
  AirplaneMobile,
  ConfirmationClipboardDesktop,
  ConfirmationClipboardMobile,
  DogDesktop,
  DogMobile,
  FileFolderDesktop,
  FileFolderMobile,
  FilePlant,
  FlagAndDocDesktop,
  FlagAndDocMobile,
  FlyingMoneyDesktop,
  FlyingMoneyMobile,
  PasswordLockDesktop,
  PasswordLockMobile,
  PayoffLoading,
  RegisterClipboardDesktop,
  RegisterClipboardMobile,
  RobotDesktop,
  RobotMobile,
  TownWithSun,
  UsernameNametagDesktop,
  UsernameNametagMobile,
} from './lottie';

const animations = {
  airplaneDesktop: AirplaneDesktop,
  airplaneMobile: AirplaneMobile,
  confirmationClipboardDesktop: ConfirmationClipboardDesktop,
  confirmationClipboardMobile: ConfirmationClipboardMobile,
  dogDesktop: DogDesktop,
  dogMobile: DogMobile,
  fileFolderDesktop: FileFolderDesktop,
  fileFolderMobile: FileFolderMobile,
  filePlant: FilePlant,
  flagAndDocDesktop: FlagAndDocDesktop,
  flagAndDocMobile: FlagAndDocMobile,
  flyingMoneyDesktop: FlyingMoneyDesktop,
  flyingMoneyMobile: FlyingMoneyMobile,
  passwordLockDesktop: PasswordLockDesktop,
  passwordLockMobile: PasswordLockMobile,
  payoffLoading: PayoffLoading,
  registerClipboardDesktop: RegisterClipboardDesktop,
  registerClipboardMobile: RegisterClipboardMobile,
  robotDesktop: RobotDesktop,
  robotMobile: RobotMobile,
  townWithSun: TownWithSun,
  usernameNametagDesktop: UsernameNametagDesktop,
  usernameNametagMobile: UsernameNametagMobile,
};

export default (name) => animations[name];
