import React, { Fragment } from 'react';
import T from 'prop-types';

import { H5, P2 } from '../../base_ui';
import { DocumentsContentContainer, DocumentsDivider, DocumentsHeader } from './styledComponents';

const DocumentsComponent = ({ documents }) => (
  <Fragment>
    <DocumentsDivider />
    <DocumentsHeader>Documents</DocumentsHeader>
    {documents.map(({
      date,
      number,
      status,
      type,
    }) => (
      <DocumentsContentContainer key={number}>
        <H5>{type}</H5>
        <P2>{date}</P2>
        <P2>{status}</P2>
      </DocumentsContentContainer>
    ))}
  </Fragment>
);

DocumentsComponent.propTypes = {
  documents: T.arrayOf(
    T.shape({
      date: T.string.isRequired,
      number: T.string.isRequired,
      status: T.string.isRequired,
      type: T.string.isRequired,
    }),
  ).isRequired,
};

export default DocumentsComponent;
