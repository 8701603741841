import calculatorsScreens from './calculators';
import documentsScreens from './documents';
import helpScreens from './help';
import loadingScreen from './loading';
import lossDraftScreens from './lossDraft';
import mortgageScreens from './mortgage';
import notificationsScreens from './notifications';
import paymentsScreens from './payments';
import secureMessaging from './secureMessaging';
import selectLoanScreens from './selectLoan';
import settingsScreens from './settings';
import sitemapScreens from './sitemap';

export default {
  ...calculatorsScreens,
  ...documentsScreens,
  ...helpScreens,
  ...loadingScreen,
  ...lossDraftScreens,
  ...mortgageScreens,
  ...notificationsScreens,
  ...paymentsScreens,
  ...secureMessaging,
  ...selectLoanScreens,
  ...settingsScreens,
  ...sitemapScreens,
};
