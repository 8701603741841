import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  selectCustomerServicePhoneNumber,
  selectIsMurabahaAccount,
} from '@dmi/shared/redux/Main/selectors';
import {
  changeFormFieldValue,
  changeStep,
  fetchMessages,
  postMessage,
  resetFormState,
  resetRequestStatus,
} from '@dmi/shared/redux/SecureMessaging/actions';
import {
  selectConfirmationData,
  selectForm,
  selectFormErrors,
  selectRequestStatus,
  selectSecureMessagingScreenReaderNotifications,
} from '@dmi/shared/redux/SecureMessaging/selectors';

import { NewMessageComponent } from '../../../components/SecureMessaging/index';
import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';

import { MessageContainer } from '../styledComponents';

const NewMessageScreen = ({
  confirmationNumber,
  customerServicePhoneNumber,
  dispatchChangeFormFieldValue,
  dispatchChangeStep,
  dispatchFetchMessages,
  dispatchPostMessage,
  dispatchResetForm,
  dispatchResetRequestStatus,
  formAttachments,
  formMessage,
  formUploadError,
  isMurabahaAccount,
  navigation,
  notifications,
  requestStatusPostMessage,
}) => {
  const [viewToRender, setViewToRender] = useState('Messaging');

  useEffect(() => {
    if (requestStatusPostMessage === 'failed' || requestStatusPostMessage === 'succeeded') {
      setViewToRender(requestStatusPostMessage);
    } else {
      setViewToRender('Messaging');
    }
  }, [requestStatusPostMessage, setViewToRender]);

  useEffect(() => {
    if (requestStatusPostMessage === 'succeeded') {
      dispatchFetchMessages();
    }
  }, [dispatchFetchMessages, requestStatusPostMessage]);

  useEffect(() => () => {
    dispatchResetForm({ form: 'message' });
    dispatchResetForm({ form: 'attachments' });
  }, [dispatchResetForm]);

  const handleBack = () => {
    navigation.goBack();
    dispatchResetRequestStatus({ requestStatus: 'postMessage' });
    dispatchChangeStep(1);
    setViewToRender('Messaging');
  };

  const handleOptionPress = (value) => {
    dispatchChangeFormFieldValue({ field: 'topic', form: 'message', value });
    setTimeout(() => {
      setViewToRender('Messaging');
    }, 1000);
  };

  const propsToPassDown = {
    confirmationNumber,
    customerServicePhoneNumber,
    dispatchChangeFormFieldValue,
    dispatchPostMessage,
    dispatchResetRequestStatus,
    formAttachments,
    formMessage,
    formUploadError,
    handleBack,
    handleOptionPress,
    isMurabahaAccount,
    navigation,
    requestStatusPostMessage,
    setViewToRender,
    viewToRender,
  };

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <MessageContainer>
        <NewMessageComponent
          {...propsToPassDown}
        />
      </MessageContainer>
    </Fragment>
  );
};

NewMessageScreen.propTypes = {
  confirmationNumber: T.array.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchFetchMessages: T.func.isRequired,
  dispatchPostMessage: T.func.isRequired,
  dispatchResetForm: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formMessage: T.objectOf(T.string),
  formUploadError: T.string.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  requestStatusPostMessage: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: selectCustomerServicePhoneNumber(),
  isMurabahaAccount: selectIsMurabahaAccount(),
  /**
   * Store: SecureMessaging
   */
  // eslint-disable-next-line sort-keys
  confirmationNumber: selectConfirmationData('postNewMessage'),
  formAttachments: selectForm('attachments'),
  formMessage: selectForm('message'),
  formUploadError: selectFormErrors('attachments'),
  notifications: selectSecureMessagingScreenReaderNotifications(),
  requestStatusPostMessage: selectRequestStatus('postMessage'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: SecureMessaging
   */
  dispatchChangeFormFieldValue: (payload) => dispatch(changeFormFieldValue(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchFetchMessages: () => dispatch(fetchMessages()),
  dispatchPostMessage: (payload) => dispatch(postMessage(payload)),
  dispatchResetForm: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageScreen);
