/**
* StatusBanner
* @description:  Create an banner message.
* Use Case: Display an error or success status message to user.
* Component Props: {
*   error (string) Error message to be displayed
*   success (string) Success message to be displayed
*   Note: In case the message should be partially displayed in bold
*         and regular font, include `\n` in the message string as
*         indicator for where the split should take place.
*         Example: 'This is bold font.\n This is regular font.'
* }
*/

import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import ConditionalRender from '../ConditionalRender';
import {
  IconWrapper,
  StatusBannerContainer,
  StatusBannerText,
  StatusBannerTextBold,
} from './styledComponents';

const PartialWarningIcon = iconDictionary('paymentWarningPurple');
const SuccessIcon = iconDictionary('outlinedCompleteGreen');
const WarningIcon = iconDictionary('paymentWarning');

const StatusBanner = ({
  error,
  isPartialError = false,
  success,
  ...restProps
}) => {
  const WarningIconToRender = isPartialError ? PartialWarningIcon : WarningIcon;
  const IconToRender = error ? WarningIconToRender : SuccessIcon;
  const messageText = error || success;
  const messageParts = messageText ? messageText.split(/\r?\n/) : [];

  return (
    <ConditionalRender
      Component={(
        <StatusBannerContainer error={error} isPartialError={isPartialError} {...restProps}>
          <IconWrapper>{IconToRender}</IconWrapper>
          <ConditionalRender
            Component={(
              <StatusBannerText>
                <StatusBannerTextBold>{messageParts[0]}</StatusBannerTextBold>
                {' '}
                {messageParts[1]}
              </StatusBannerText>

            )}
            FallbackComponent={(<StatusBannerText>{messageText}</StatusBannerText>)}
            shouldRender={messageParts.length === 2}
          />
        </StatusBannerContainer>
      )}
      shouldRender={!!(error || success)}
    />
  );
};

StatusBanner.propTypes = {
  error: T.oneOfType([T.bool, T.string]).isRequired,
  isPartialError: T.bool,
  success: T.oneOfType([T.bool, T.string]),
};

export default StatusBanner;
