/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  G,
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HardshipBook() {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85">
      <G className="cls-1" isolation="isolate">
        <G id="Layer_1" data-name="Layer 1">
          <Polygon className="cls-2" fill="#bac2ca" points="42.47 72.29 1.93 72.29 1.93 19.59 42.3 19.59 42.47 72.29" />
          <Polyline className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="1.93 66.36 1.93 19.59 41.41 19.59" />
          <Polyline className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="41.58 72.29 1.93 72.29 1.93 68.33" />
          <Path className="cls-4" stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill="#fff" strokeWidth="0.74" d="M8.74,13.23v3.46H5.53V69.4H38.79s3.68-.11,3.68,2.89V19.67a6.55,6.55,0,0,0-6.65-6.44Z" />
          <G className="cls-5">
            <Path className="cls-6" fill="#eff3f6" d="M37.57,66.5H8.74V16.69H5.51V69.4s31.15-.15,33.79,0c3.43.19,3.17,2.89,3.17,2.89C42.47,66.22,37.57,66.5,37.57,66.5Z" />
            <Path className="cls-6" fill="#eff3f6" d="M45.68,16.69v0Z" />
          </G>
          <Polygon className="cls-7" fill={svgHighlight} points="14.78 12.46 14.78 25.23 18.94 22.15 22.66 25.23 22.66 12.46 14.78 12.46" />
          <Polyline className="cls-8" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.74" strokeMiterlimit="10" points="22.66 16.45 22.66 12.46 14.78 12.46 14.78 25.23 18.94 22.15 22.66 25.23 22.66 18.12" />
          <Polygon className="cls-2" fill="#bac2ca" points="42.53 72.29 83.07 72.29 83.07 19.59 42.7 19.59 42.53 72.29" />
          <Polyline className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="83.07 23.88 83.07 19.59 43.59 19.59" />
          <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.52" x1="83.07" y1="26.86" x2="83.07" y2="25.17" />
          <Polyline className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="43.42 72.55 83.07 72.55 83.07 28.11" />
          <Path className="cls-10" fill="#fff" d="M76.26,13.23v3.46h3.22V69.4H46.21s-3.68-.11-3.68,2.89V19.67a6.55,6.55,0,0,1,6.65-6.44Z" />
          <Path className="cls-6" fill="#eff3f6" d="M47.43,66.5H76.26V16.69h3.23V69.4s-31.15-.15-33.79,0c-3.43.19-3.17,2.89-3.17,2.89C42.53,66.22,47.43,66.5,47.43,66.5Z" />
          <Path className="cls-6" fill="#eff3f6" d="M39.32,16.69v0Z" />
          <Path className="cls-11" fill="none" stroke="#000" strokeWidth="0.74" strokeMiterlimit="10" d="M76.26,13.23v3.46h3.22V69.4H46.21s-3.68-.11-3.68,2.89V19.67a6.55,6.55,0,0,1,6.65-6.44Z" />
          <Path className="cls-12" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.74" d="M76.26,16.69V66.36H71l-21.23.14s-5.3-.31-6.31,2.12-1,2.89-1,2.89c0-5-4.87-5-4.87-5H8.74V21.73" />
          <Line className="cls-8" fill="none" stroke="#000" strokeLinecap="round" strokeWidth="0.74" strokeMiterlimit="10" x1="8.74" y1="15.29" x2="8.74" y2="20.21" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="49.09" y1="32.12" x2="68.66" y2="32.12" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="49.09" y1="37.55" x2="68.66" y2="37.55" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="49.09" y1="42.86" x2="68.66" y2="42.86" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="49.09" y1="48.4" x2="68.66" y2="48.4" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="49.09" y1="53.83" x2="68.66" y2="53.83" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="15.7" y1="32.12" x2="35.26" y2="32.12" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="15.7" y1="37.55" x2="35.26" y2="37.55" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="15.7" y1="42.86" x2="35.26" y2="42.86" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="15.7" y1="48.4" x2="35.26" y2="48.4" />
          <Line className="cls-3" fill="none" strokeWidth="0.66" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="15.7" y1="53.83" x2="35.26" y2="53.83" />
        </G>
      </G>
    </Svg>
  );
}
