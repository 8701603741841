import React, { useState } from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { EMAIL_TOOLTIP_COPY } from '@dmi/shared/redux/Payments/Autopay/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender, P2, Tooltip } from '../../../base_ui';
import { CheckboxContainer, StyledCheckboxWithText } from './styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');

const EmailCheckbox = ({ checked, onPress }) => {
  const [isEmailTooltipOpen, setIsEmailTooltipOpen] = useState(false);

  return (
    <CheckboxContainer>
      <StyledCheckboxWithText
        checked={checked}
        onPress={onPress}
        title="Send email confirmation"
      />
      <Pressable
        onPress={() => setIsEmailTooltipOpen(true)}
        style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
      >
        {InfoIcon}
      </Pressable>
      <ConditionalRender
        Component={(
          <Tooltip
            handleClose={() => setIsEmailTooltipOpen(false)}
            positionAbsolute={{ left: 'auto', top: 'auto' }}
            visible={isEmailTooltipOpen}
          >
            <P2>{EMAIL_TOOLTIP_COPY}</P2>
          </Tooltip>
        )}
        shouldRender={isEmailTooltipOpen}
      />
    </CheckboxContainer>
  );
};

EmailCheckbox.propTypes = { checked: T.bool.isRequired, onPress: T.func.isRequired };

export default EmailCheckbox;
