import React from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { getFocusedRouteNameFromRoute, useRoute } from '@react-navigation/native';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Main/messages';

import { MOBILE_MENU_ITEMS, MOBILE_WEB_MENU_ITEMS } from './constants';
import { getTabNavigatorRouteName } from './helpers';
import { StyledNavButton } from './styledComponents';

const menuProps = Platform.OS === 'web' ? MOBILE_WEB_MENU_ITEMS : MOBILE_MENU_ITEMS;

const NavButtons = ({
  handleLogout,
  isMurabahaAccount,
  navigation,
  navigationState,
  showLogoutButton,
}) => {
  const { formatMessage } = useIntl();
  const route = useRoute();

  let routeName = getFocusedRouteNameFromRoute(route);
  if (routeName === 'TabNavigator') {
    routeName = getTabNavigatorRouteName(navigationState);
  }

  return menuProps.map(({
    iconActive,
    iconDefault,
    label,
    path,
  }) => {
    if (!showLogoutButton && label === INTL_IDS.NAVBAR_LINK_LABEL_LOGOUT) {
      return null;
    }
    return (
      <StyledNavButton
        key={`nav-${label}-button`}
        Icon={path === routeName ? iconActive : iconDefault}
        isCurrentRoute={path === routeName}
        label={formatMessage({ id: label })}
        onPress={() => {
          if (path) {
            if (
              path === 'Mortgage' &&
              isMurabahaAccount &&
              Platform.OS !== 'web'
            ) {
              navigation.navigate('Murabaha');
            } else {
              navigation.navigate(path);
            }
          } else {
            handleLogout();
          }
        }}
      />
    );
  });
};

NavButtons.propTypes = {
  handleLogout: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  navigationState: T.object.isRequired,
  showLogoutButton: T.bool.isRequired,
};

export default NavButtons;
