import React from 'react';
import T from 'prop-types';

import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { LargeProgressButton, TertiaryDivider } from '../../../base_ui';
import {
  AddEscrowButtonWrapper,
  AddEscrowContainer,
  AddEscrowHeaderContainer,
  AddEscrowIllustrationWrapper,
  AddEscrowSplashContainer,
  StyledH2,
} from '../styledComponents';
import AddEscrowDefinition from './AddEscrowDefinition';
import AddEscrowText from './AddEscrowText';

const Marketing = iconDictionary('marketing');
const EscrowIcon = iconDictionary('escrowIcon');

const AddEscrowSplash = ({ buttonPressHandler }) => {
  const { body, header } = ADD_ESCROW_DICT.splashScreen;

  return (
    <AddEscrowSplashContainer>
      <AddEscrowContainer>
        <AddEscrowIllustrationWrapper>
          {Marketing}
        </AddEscrowIllustrationWrapper>
        <AddEscrowHeaderContainer>
          <StyledH2>{header}</StyledH2>
        </AddEscrowHeaderContainer>
        <AddEscrowText splashText={body} />
        <AddEscrowButtonWrapper>
          <LargeProgressButton
            label={'Set Up Escrow'}
            onPress={() => buttonPressHandler(2, 'AddEscrowSetUp')}
          />
        </AddEscrowButtonWrapper>
      </AddEscrowContainer>
      <TertiaryDivider />
      <AddEscrowDefinition icon={EscrowIcon} />
    </AddEscrowSplashContainer>
  );
};

AddEscrowSplash.propTypes = { buttonPressHandler: T.func.isRequired };

export default AddEscrowSplash;
