import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { Checkbox } from '../../../../../base_ui';
import {
  CheckboxAlignmentContainer,
  CheckboxAlignmentSubcontainer,
  StyledPrimaryDivider,
} from '../styledComponents';
import { Container, Label } from './styledComponents';

const Row = ({
  alertDescription,
  alertId,
  dispatchChangeAlertForm,
  isEmailAllowed,
  isEmailEnabled,
  isLoanEmailAllowed,
  isLoanSmsAllowed,
  isSmsAllowed,
  isSmsEnabled,
}) => {
  const canChangeEmail = isLoanEmailAllowed && isEmailAllowed;
  const canChangeSms = isLoanSmsAllowed && isSmsAllowed;

  return (
    <View>
      <Container>
        <Label>{alertDescription}</Label>
        <CheckboxAlignmentContainer>
          <CheckboxAlignmentSubcontainer
            $isDisabled={!canChangeSms}
            pointerEvents={canChangeSms ? 'auto' : 'none'}
          >
            <Checkbox
              // "accessibilityChecked" should be replaced with
              // "aria-checked" when react-native-web is upgraded
              aria-checked={isSmsEnabled}
              aria-label="Text"
              checked={isSmsEnabled}
              onPress={() => dispatchChangeAlertForm({ alertId, field: 'isSmsEnabled' })}
            />
          </CheckboxAlignmentSubcontainer>
          <CheckboxAlignmentSubcontainer
            $isDisabled={!canChangeEmail}
            pointerEvents={canChangeEmail ? 'auto' : 'none'}
          >
            <Checkbox
              aria-checked={isEmailEnabled}
              aria-label="Email"
              checked={isEmailEnabled}
              onPress={() => dispatchChangeAlertForm({ alertId, field: 'isEmailEnabled' })}
            />
          </CheckboxAlignmentSubcontainer>
        </CheckboxAlignmentContainer>
      </Container>
      <StyledPrimaryDivider />
    </View>
  );
};

Row.propTypes = {
  alertDescription: T.string.isRequired,
  alertId: T.number.isRequired,
  dispatchChangeAlertForm: T.func.isRequired,
  isEmailAllowed: T.bool.isRequired,
  isEmailEnabled: T.bool.isRequired,
  isLoanEmailAllowed: T.bool.isRequired,
  isLoanSmsAllowed: T.bool.isRequired,
  isSmsAllowed: T.bool.isRequired,
  isSmsEnabled: T.bool.isRequired,
};

export default Row;
