import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../../base_ui';
import Tooltip from './Tooltip';
import CardMapper from './CardMapper';
import SingleLoan from './SingleLoan';

const Form = ({
  dispatchChangePaperlessForm,
  dispatchChangeStep,
  dispatchSetSelectedLoan,
  formData,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  return (
    <Fragment>
      <ConditionalRender
        Component={SingleLoan}
        FallbackComponent={CardMapper}
        propsToPassDown={{
          dispatchChangePaperlessForm,
          dispatchChangeStep,
          dispatchSetSelectedLoan,
          formData,
          setIsTooltipOpen,
        }}
        shouldRender={formData.length === 1}
      />
      <ConditionalRender
        Component={Tooltip}
        propsToPassDown={{ onClose: () => setIsTooltipOpen(false) }}
        shouldRender={isTooltipOpen}
      />
    </Fragment>
  );
};

Form.propTypes = {
  dispatchChangePaperlessForm: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  formData: T.arrayOf(T.object).isRequired,
};

export default Form;
