import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Profile/messages';
import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import { H5, P2, P4 } from '../../base_ui';
import {
  ConfirmationContent,
  ConfirmationDisclaimerContent,
  ConfirmationRow,
  ConfirmationRows,
  EditContainer,
  EditStyledP2,
  IconWrapper,
  StyledAnimation,
} from '../styledComponents';
import { StyledH2 } from './styledComponents';

const PendingClock = iconDictionary(
  'outlinedPendingPurple',
  { height: `${moderateScale(15)}px`, width: `${moderateScale(15)}px` },
);

const ConfirmationComponent = ({ loanNumber }) => {
  const { subheader, text1, text2 } = PROFILE_CONTENT_DICTIONARY.editMilitaryStatus.confirmationView.mobile;

  return (
    <EditContainer $isConfirmation>
      <StyledAnimation name="robotMobile" />
      <ConfirmationContent>
        <StyledH2 $isConfirmation>{subheader}</StyledH2>
        <EditStyledP2 $isConfirmation>{text1}</EditStyledP2>
      </ConfirmationContent>
      <ConfirmationRows>
        <ConfirmationRow>
          <H5><FormattedMessage id={INTL_IDS.CONFIRMATION_ROW_LOANNUMBER} /></H5><P2>{loanNumber}</P2>
        </ConfirmationRow>
      </ConfirmationRows>
      <ConfirmationDisclaimerContent>
        <IconWrapper>{PendingClock}</IconWrapper>
        <P4>{text2}</P4>
      </ConfirmationDisclaimerContent>
    </EditContainer>
  );
};

ConfirmationComponent.propTypes = { loanNumber: T.string.isRequired };

export default ConfirmationComponent;
