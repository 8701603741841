/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function TaxInsuranceIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M8.25,5.75a1.79,1.79,0,0,1,0,2.41,1.57,1.57,0,0,1-2.3,0A1.72,1.72,0,0,1,5.49,7,1.68,1.68,0,0,1,6,5.76,1.49,1.49,0,0,1,7.1,5.25,1.51,1.51,0,0,1,8.25,5.75Zm4.34-.32L7.13,12.89H5.75l5.46-7.46ZM7.53,7.51a.92.92,0,0,0,0-1.08.57.57,0,0,0-.44-.22.54.54,0,0,0-.43.22.92.92,0,0,0,0,1.08.54.54,0,0,0,.44.22A.5.5,0,0,0,7.53,7.51Zm5,2.56a1.68,1.68,0,0,1,.46,1.2,1.73,1.73,0,0,1-.46,1.21,1.57,1.57,0,0,1-2.3,0,1.78,1.78,0,0,1,0-2.4,1.55,1.55,0,0,1,2.29,0Zm-.69,1.78a.85.85,0,0,0,.17-.54.79.79,0,0,0-.18-.53.54.54,0,0,0-.44-.22.5.5,0,0,0-.43.22.79.79,0,0,0-.18.53.81.81,0,0,0,.19.54.53.53,0,0,0,.44.22A.51.51,0,0,0,11.79,11.85Z" />
      <Path d="M2.73,3.19a8.33,8.33,0,0,0,3.51-.52A7.19,7.19,0,0,0,9.29.9h0a7.1,7.1,0,0,0,3,1.77,8.25,8.25,0,0,0,3.49.52v7.4a5.68,5.68,0,0,1-2.94,5l-3.6,2h0l-3.61-2a5.68,5.68,0,0,1-2.94-5V3.19Z" fill="none" stroke={iconStatic} stroke-linecap="round" stroke-linejoin="round" stroke-width="1.18px" />
    </Svg>
  );
}
