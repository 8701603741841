import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import iconDictionary from '../../../../utils/iconDictionary';
import Layout from '../../../../constants/Layout';
import { H4 } from '../../../base_ui';
import { CARD_ICON_DICT } from './constants';
import {
  ArrowWrapper,
  Card,
  ContentWrapper,
  IllustrationWrapper,
  StyledP2,
} from './styledComponents';

const ArrowRight = iconDictionary('arrowRightCarbonS');
const { isTablet } = Layout;

const CalcHomeCard = ({
  ariaLabel,
  cardIconKey,
  onPress,
  text,
  title,
}) => (
  <Pressable aria-label={ariaLabel} onPress={onPress}>
    <Card $center={isTablet}>
      <IllustrationWrapper>
        {CARD_ICON_DICT[cardIconKey]}
      </IllustrationWrapper>
      <ContentWrapper>
        <H4>{title}</H4>
        <StyledP2>{text}</StyledP2>
      </ContentWrapper>
      <ArrowWrapper>{ArrowRight}</ArrowWrapper>
    </Card>
  </Pressable>
);

CalcHomeCard.propTypes = {
  ariaLabel: T.string.isRequired,
  cardIconKey: T.string.isRequired,
  onPress: T.func.isRequired,
  text: T.string.isRequired,
  title: T.string.isRequired,
};

export default CalcHomeCard;
