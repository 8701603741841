import React, { Fragment } from 'react';
import T from 'prop-types';

import { H4, P2 } from '../../../base_ui';
import { Text, TextContainer } from '../styledComponents';

const StaticInfoComponent = ({
  email,
  formText,
  username,
  usernameForLogin,
}) => (
  <Fragment>
    <TextContainer>
      <P2>{formText}</P2>
    </TextContainer>
    <TextContainer>
      <H4>Username on File</H4>
      <Text>{usernameForLogin || username}</Text>
    </TextContainer>
    <TextContainer>
      <H4>Email on File</H4>
      <Text>{email}</Text>
    </TextContainer>
  </Fragment>
);

StaticInfoComponent.propTypes = {
  email: T.string.isRequired,
  formText: T.string.isRequired,
  username: T.string.isRequired,
  usernameForLogin: T.string.isRequired,
};

export default StaticInfoComponent;
