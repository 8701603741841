import React, { Fragment } from 'react';
import T from 'prop-types';

import { PASSWORD_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import {
  EditPasswordTextField,
  FormattedMessage,
  H4,
  MediumProgressButton,
} from '../../base_ui';
import {
  BoldText,
  ButtonWrapper,
  IllustrationWrapper,
  PasswordContainer,
  PasswordP2,
  TextFieldGroup,
} from './styledComponents';

const Illustration = iconDictionary('treehouse');

const EditPassword = ({
  dispatchChangeInput,
  dispatchChangeInputError,
  formErrors,
  formValues,
  handleChangePassword,
  navigation,
  requestStatusPassword,
}) => {
  const { header, subheader, text } = PASSWORD_CONTENT_DICTIONARY;

  const handleChangeInput = ({ field, value }) => {
    if (formErrors[field]) {
      dispatchChangeInputError({
        field,
        form: 'password',
        value: '',
      });
    }
    dispatchChangeInput({
      field,
      form: 'password',
      value,
    });
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={header}
      />
      <PasswordContainer>
        <H4>{subheader}</H4>
        <FormattedMessage
          defaultMessage={text}
          values={{
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <BoldText>{msg}</BoldText>,
          }}
          WrapperComponent={PasswordP2}
        />
        <TextFieldGroup>
          <EditPasswordTextField
            error={formErrors.oldPassword}
            label="Current Password"
            onChangeText={(newValue) => handleChangeInput({
              field: 'oldPassword',
              value: newValue,
            })}
            placeholder="Required"
            value={formValues.oldPassword}
          />
          <EditPasswordTextField
            error={formErrors.newPassword}
            label="New Password"
            onChangeText={(newValue) => handleChangeInput({
              field: 'newPassword',
              value: newValue,
            })}
            placeholder="Required"
            value={formValues.newPassword}
          />
          <EditPasswordTextField
            error={formErrors.confirmNewPassword}
            label="Confirm Password"
            onChangeText={(newValue) => handleChangeInput({
              field: 'confirmNewPassword',
              value: newValue,
            })}
            placeholder="Required"
            value={formValues.confirmNewPassword}
          />
        </TextFieldGroup>
        <IllustrationWrapper>
          {Illustration}
        </IllustrationWrapper>
        <ButtonWrapper>
          <MediumProgressButton
            disabled={
              !formValues.oldPassword ||
              !formValues.newPassword ||
              !formValues.confirmNewPassword
            }
            label="Submit"
            onPress={handleChangePassword}
            status={requestStatusPassword}
          />
        </ButtonWrapper>
      </PasswordContainer>
    </Fragment>
  );
};

EditPassword.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeInputError: T.func.isRequired,
  formErrors: T.shape({
    confirmNewPassword: T.string.isRequired,
    newPassword: T.string.isRequired,
    oldPassword: T.string.isRequired,
  }).isRequired,
  formValues: T.shape({
    confirmNewPassword: T.string.isRequired,
    newPassword: T.string.isRequired,
    oldPassword: T.string.isRequired,
  }).isRequired,
  handleChangePassword: T.func.isRequired,
  navigation: T.object.isRequired,
  requestStatusPassword: T.string.isRequired,
};

export default EditPassword;
