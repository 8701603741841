export const getFontSize = (size, { f3, f4, f5 }) => {
  if (size === 'small') {
    return f3;
  }

  if (size === 'large') {
    return f5;
  }

  return f4;
};

export const getUrlScheme = (url) => {
  const [scheme] = url.match(/^([a-z][a-z0-9+\-.]*):/) || [];
  return scheme;
};
