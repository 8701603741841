import React from 'react';
import T from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { PAYOFF_EMPTY_SET, PAYOFF_HISTORY } from '@dmi/shared/redux/Payoff/constants';

import ConditionalRender from '../../base_ui/ConditionalRender';
import {
  HistoryContainer,
  LeftP2,
  StyledH3,
  TitleWrapper,
} from './styledComponents';
import DataItem from './DataItem';
import Message from '../../Message';
import iconDictionary from '../../../utils/iconDictionary';

const historyIcon = iconDictionary('history');
const KeyIcon = iconDictionary('key');

const { paydownTitle, text, title } = PAYOFF_HISTORY;

const PayoffHistoryCard = ({
  data,
  handleOnPressRow,
  isPastServicingPaymentCutOffDate,
  isPayDownAvailable,
}) => {
  const { formatMessage } = useIntl();

  const ListComponent = () => data.map((item, idx) => (
    <DataItem
      key={`payoff-statement-${idx + 1}`}
      handleOnPressRow={handleOnPressRow}
      {...item}
    />
  ));
  const { text: emptyText, title: emptyTitle } = PAYOFF_EMPTY_SET;
  const noDataMessageProps = {
    body: formatMessage({ id: emptyText }),
    icon: KeyIcon,
    title: formatMessage({ id: emptyTitle }),
  };
  const isEmptySet = data.length === 0;
  const propsToPassDown = isEmptySet || isPastServicingPaymentCutOffDate ? noDataMessageProps : {};

  return (
    <HistoryContainer>
      <TitleWrapper>
        {historyIcon}
        <StyledH3>
          <FormattedMessage id={isPayDownAvailable ? paydownTitle : title} />
        </StyledH3>
      </TitleWrapper>
      <LeftP2>
        <FormattedMessage id={text} />
      </LeftP2>
      <ConditionalRender
        Component={Message}
        FallbackComponent={ListComponent}
        propsToPassDown={propsToPassDown}
        shouldRender={isEmptySet || isPastServicingPaymentCutOffDate}
      />
    </HistoryContainer>
  );
};

PayoffHistoryCard.propTypes = {
  data: T.array.isRequired,
  handleOnPressRow: T.func.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  isPayDownAvailable: T.bool.isRequired,
};

export default PayoffHistoryCard;
