import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H4 } from '../Heading';

export const IllustrationTextWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const IllustrationWrapper = styled.View`
  margin-right: ${moderateScale(7)}px;
`;

export const StyledHeader = styled(H4)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledPressable = styled.Pressable`
  background-color: ${({ $pressed, theme: { colors: { accentSilver } } }) =>
    $pressed ? accentSilver : 'transparent'};
  width: 100%;
`;

export const StyledView = styled.View`
  align-items: center;
  border: 1px solid ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0 auto;
  min-height:${moderateScale(129)}px;
  padding: ${moderateScale(20)}px;
  width: 100%;
`;

export const TextWrapper = styled.View`
  display: flex;
  flex-shrink: 1;
`;
