import React, { useEffect, useMemo } from 'react';
import T from 'prop-types';
import { Animated, Platform, StyleSheet } from 'react-native';

import { Animation } from '../../components/base_ui';

const SplashScreenAnimation = ({
  dispatchSetShouldRenderSplashScreen,
  isInitComplete,
  onLayoutAnimation,
}) => {
  const animateOpacity = useMemo(() => new Animated.Value(1), []);

  useEffect(() => {
    if (isInitComplete) {
      Animated.timing(animateOpacity, {
        duration: 200,
        toValue: 0,
        useNativeDriver: Platform.OS !== 'web',
      }).start(() => dispatchSetShouldRenderSplashScreen(false));
    }
  }, [animateOpacity, isInitComplete, dispatchSetShouldRenderSplashScreen]);

  return (
    <Animated.View
      onLayout={onLayoutAnimation}
      pointerEvents="none"
      style={[
        StyleSheet.absoluteFill,
        {
          backgroundColor: 'white',
          flex: 1,
          justifyContent: 'center',
          opacity: animateOpacity,
        },
      ]}
    >
      <Animation
        lottieProps={{ loop: true }}
        name="townWithSun"
        resizeMode="cover"
        speed={0.5}
      />
    </Animated.View>
  );
};

SplashScreenAnimation.propTypes = {
  dispatchSetShouldRenderSplashScreen: T.func.isRequired,
  isInitComplete: T.bool.isRequired,
  onLayoutAnimation: T.func.isRequired,
};

export default SplashScreenAnimation;
