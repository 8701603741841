/*
 * Add Escrow Confirmation Screen
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';

import {
  getMainClientInfo,
  getSelectedLoanUnmaskedLoanNumber,
  selectIsMurabahaAccount,
} from '@dmi/shared/redux/Main/selectors';
import {
  resetFormState,
  resetRequestStatusState,
  resetStepState,
} from '@dmi/shared/redux/Mortgage/actions';
import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';
import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';
import { selectRequestStatus } from '@dmi/shared/redux/Mortgage/selectors';
import { selectIsEligibleForAutopay } from '@dmi/shared/redux/Payments/Autopay/selectors';

import { ConditionalRender, StyledScrollView } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { AddEscrowConfirmation } from '../../../components/Mortgage/AddEscrow';
import ConfirmationErrorScene from '../../../components/DocUpload/ConfirmationErrorScene';
import { StyledScreenWrapper, StyledView } from './styledComponents';

const AddEscrowConfirmationScreen = ({
  customerServicePhoneNumber,
  dispatchResetFormState,
  dispatchResetRequestStatusState,
  dispatchResetStepState,
  isEligibleForAutopay,
  isMurabahaAccount,
  navigation,
  unmaskedSelectedLoan,
  uploadDocumentRequestStatus,
}) => {
  const {
    errorText,
    header,
  } = ADD_ESCROW_DICT.confirmationView;

  useEffect(() => () => {
    dispatchResetFormState({ form: 'uploadDocument' });
    dispatchResetRequestStatusState({ state: 'uploadDocument' });
    dispatchResetStepState();
  }, [dispatchResetFormState, dispatchResetRequestStatusState, dispatchResetStepState]);

  const { formatMessage } = useIntl();

  return (
    <StyledScreenWrapper>
      <ConditionalRender
        Component={(
          <Fragment>
            <SecondaryHeader
              handleDone={() => navigation.navigate(isMurabahaAccount ? 'Murabaha' : 'Mortgage')}
              title={header}
            />
            <StyledView>
              <AddEscrowConfirmation
                isEligibleForAutopay={isEligibleForAutopay}
                navigation={navigation}
                unmaskedSelectedLoan={unmaskedSelectedLoan}
              />
            </StyledView>
          </Fragment>
        )}
        FallbackComponent={(
          <StyledScrollView>
            <ConfirmationErrorScene
              customerServicePhoneNumber={customerServicePhoneNumber}
              label={formatMessage({ id: INTL_IDS.MORTGAGE_SHARED_BUTTON_LABEL_RETURNTOMORTGAGE })}
              onPress={() => navigation.navigate('AddEscrow')}
              route={'AddEscrow'}
              text={errorText}
            />
          </StyledScrollView>
        )}
        shouldRender={uploadDocumentRequestStatus === 'succeeded'}
      />
    </StyledScreenWrapper>
  );
};

AddEscrowConfirmationScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  dispatchResetStepState: T.func.isRequired,
  isEligibleForAutopay: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  unmaskedSelectedLoan: T.string.isRequired,
  uploadDocumentRequestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  isEligibleForAutopay: selectIsEligibleForAutopay(),
  /**
   * Store: Mortgage
   */
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  unmaskedSelectedLoan: getSelectedLoanUnmaskedLoanNumber(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mortgage
   */
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
  dispatchResetStepState: () => dispatch(resetStepState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(AddEscrowConfirmationScreen);
