import styled from 'styled-components/native';
import Modal from 'react-native-modal';

import { moderateScale } from '../../../utils/scaleHelpers';

export const StyledModal = styled(Modal).attrs(({ theme: { colors: { primaryCarbon } } }) => ({
  backdropColor: primaryCarbon,
  backdropOpacity: 0.8,
}))`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  margin: ${moderateScale(46)}px 0.1px 0.1px;
`;

export const StyledPressable = styled.Pressable`
  flex: 1;
`;

export const StyledView = styled.View`
  flex: 1;
  margin: ${moderateScale(19)}px ${moderateScale(24)}px;
  ${({ style }) => style}
`;
