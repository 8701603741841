/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function DropdownCheckmarkIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(8)} viewBox="0 0 11 8" width={moderateScale(11)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path clipRule="evenodd" d="M9.91864 1.07708C10.0271 1.17985 10.0271 1.34647 9.91864 1.44924L4.14086 6.92292C4.08741 6.97357 4.01448 7.00138 3.93889 6.99995C3.86331 6.99852 3.79161 6.96796 3.74033 6.91534L1.07367 4.17849C0.96961 4.0717 0.97664 3.90521 1.08937 3.80663C1.2021 3.70805 1.37783 3.71471 1.48189 3.82151L3.95246 6.35709L9.5258 1.07708C9.63428 0.974308 9.81016 0.974308 9.91864 1.07708Z" fill={primary} fillRule="evenodd" stroke={primary} strokeLinecap="round" strokeLinejoin="round" />
    </Svg>
  );
}
