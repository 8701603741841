/*
 * Edit Email Screen
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeEmail,
  changeInput,
  inputErrors,
  resetFormErrorsState,
  resetFormState,
} from '@dmi/shared/redux/Settings/Profile/actions';
import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import {
  selectBannerError,
  selectForm,
  selectFormErrors,
  selectIsSubmitDisabled,
  selectProfileSettings,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Profile/selectors';
import { validateFields } from '@dmi/shared/redux/Settings/Shared/helpers';

import { StyledScrollView } from '../../../components/base_ui';
import { EditEmailComponent } from '../../../components/Settings/EditEmail';
import { StyledSecondaryHeader } from './styledComponents';

const EditEmailScreen = ({
  bannerError,
  dispatchChangeInput,
  dispatchEditEmail,
  dispatchInputErrors,
  dispatchResetFormErrorsState,
  dispatchResetFormState,
  email,
  form,
  formErrors,
  isSubmitDisabled,
  navigation,
  status,
}) => {
  const { header } = PROFILE_CONTENT_DICTIONARY.editEmail.editEmailView;

  useEffect(() => () => {
    dispatchResetFormState({ form: 'email' });
  }, [dispatchResetFormState]);

  const handleEditEmail = () => {
    const { isValidated, validationErrors } = validateFields({
      formErrors,
      values: form,
    });
    if (isValidated) {
      dispatchEditEmail({ ...form });
    }
    dispatchInputErrors({ form: 'email', formErrors: validationErrors });
  };

  return (
    <StyledScrollView>
      <StyledSecondaryHeader
        handleBack={navigation.goBack}
        title={header}
      />
      <EditEmailComponent
        bannerError={bannerError}
        dispatchChangeInput={dispatchChangeInput}
        dispatchResetFormErrorsState={dispatchResetFormErrorsState}
        email={email}
        form={form}
        formErrors={formErrors}
        handleEditEmail={handleEditEmail}
        isSubmitDisabled={isSubmitDisabled}
        status={status}
      />
    </StyledScrollView>
  );
};

EditEmailScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchEditEmail: T.func.isRequired,
  dispatchInputErrors: T.func.isRequired,
  dispatchResetFormErrorsState: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  email: T.string.isRequired,
  form: T.shape({
    confirmEmail: T.string.isRequired,
    newEmail: T.string.isRequired,
  }).isRequired,
  formErrors: T.shape({
    confirmEmail: T.string.isRequired,
    newEmail: T.string.isRequired,
  }).isRequired,
  isSubmitDisabled: T.bool.isRequired,
  navigation: T.object.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  bannerError: selectBannerError(),
  email: selectProfileSettings('email'),
  form: selectForm('email'),
  formErrors: selectFormErrors('email'),
  isSubmitDisabled: selectIsSubmitDisabled('email'),
  status: selectRequestStatus('email'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Profile
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchEditEmail: (payload) => dispatch(changeEmail(payload)),
  dispatchInputErrors: (payload) => dispatch(inputErrors(payload)),
  dispatchResetFormErrorsState: (payload) => dispatch(resetFormErrorsState(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(EditEmailScreen);
