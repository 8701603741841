import React, { Fragment } from 'react';
import T from 'prop-types';

import { interpolate } from '@dmi/shared/utils/globalHelpers';

import Message from '../../Message';
import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import { ErrorContainer } from './styledComponents';

const errorStorm = iconDictionary('errorStorm');

const MessagesConfirmationFailed = ({
  confirmationCopyDictionary: {
    heading,
    text,
  },
  confirmationFailedLinkDictionary: {
    linkLabel,
    mobilePath,
  },
  customerServicePhoneNumber,
}) => (
  <Fragment>
    <SecondaryHeader title="Confirmation" />
    <ErrorContainer>
      <Message
        body={interpolate(text, { customerServicePhoneNumber })}
        icon={errorStorm}
        link={{ label: linkLabel, path: mobilePath }}
        title={heading}
      />
    </ErrorContainer>
  </Fragment>
);

MessagesConfirmationFailed.propTypes = {
  confirmationCopyDictionary: T.shape({
    heading: T.string.isRequired,
    text: T.string.isRequired,
  }).isRequired,
  confirmationFailedLinkDictionary: T.shape({
    linkLabel: T.string.isRequired,
    mobilePath: T.string.isRequired,
  }).isRequired,
  customerServicePhoneNumber: T.string.isRequired,
};

export default MessagesConfirmationFailed;
