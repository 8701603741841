import React, { Fragment } from 'react';
import { Platform } from 'react-native';
import T from 'prop-types';

import { getFormattedAppVersion } from '../../utils/globalHelpers';
import { ConditionalRender, SecondaryDivider } from '../base_ui';
import StoreLinks from '../StoreLinks';
import {
  FooterContainer,
  FooterLinkContainer,
  VersionText,
} from './styledComponents';

const appVersion = getFormattedAppVersion();

const Footer = ({ displayAppStoreBadge }) => (
  <FooterContainer>
    <ConditionalRender
      Component={(
        <Fragment>
          <SecondaryDivider />
          <StoreLinks />
        </Fragment>
      )}
      shouldRender={Platform.OS === 'web' && displayAppStoreBadge}
    />
    <FooterLinkContainer>
      <VersionText>{appVersion}</VersionText>
    </FooterLinkContainer>
  </FooterContainer>
);

Footer.propTypes = { displayAppStoreBadge: T.bool.isRequired };

export default Footer;
