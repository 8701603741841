import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  G,
  Line,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
const Payoff = (props) => {
  const { colors: { primary } } = useTheme();

  return (
    <Svg height={moderateScale(85)} width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85" {...props}>
      <G isolation="isolate">
        <G id="Layer_1" data-name="Layer 1">
          <Rect fill="none" y="-0.22" width="85" height="85" />
          <G style={{ mixBlendMode: 'multiply' }}>
            <Path fill="#bac2ca" d="M55.29,7.26l-34.83,0A4.73,4.73,0,0,0,15.71,12L15.65,75.5a4.75,4.75,0,0,0,4.74,4.75l48.39,0a4.74,4.74,0,0,0,4.75-4.74l0-49.89Z" />
            <Line fill="#bac2ca" x1="15.7" y1="23.55" x2="15.7" y2="26.21" />
            <Path fill="#bac2ca" d="M73.58,25.66H60a4.75,4.75,0,0,1-4.74-4.75V7.26Z" />
            <Path fill="#bac2ca" d="M73.58,25.66H60a4.75,4.75,0,0,1-4.74-4.75V7.26" />
            <Path fill="#bac2ca" d="M15.69,28.17l0,47.33a4.75,4.75,0,0,0,4.74,4.75l48.39,0a4.74,4.74,0,0,0,4.75-4.74l0-49.89L55.29,7.26l-34.83,0A4.73,4.73,0,0,0,15.71,12v9.85" />
          </G>
          <Path fill="#fff" d="M52.24,4.6l-34.83,0A4.74,4.74,0,0,0,12.67,9.3l-.06,63.54a4.73,4.73,0,0,0,4.74,4.74l48.39,0a4.75,4.75,0,0,0,4.75-4.74L70.54,23Z" />
          <Line fill="#fff" x1="12.66" y1="20.88" x2="12.65" y2="23.55" />
          <Rect fill="#2c2627" x="12.35" y="20.58" width="0.6" height="3.27" rx="0.3" />
          <Path fill="#e6ebef" d="M70.54,23,57,23a4.74,4.74,0,0,1-4.74-4.74V4.6Z" />
          <Path fill="#000" d="M70.54,23.3h0L57,23.28a5,5,0,0,1-5-5V4.6a.3.3,0,0,1,.3-.3h0a.29.29,0,0,1,.3.3V18.24A4.44,4.44,0,0,0,57,22.68l13.57,0a.3.3,0,0,1,.3.3A.31.31,0,0,1,70.54,23.3Z" />
          <Path fill="#2c2627" d="M65.74,77.93h0l-48.39-.05a5,5,0,0,1-5-5l0-47.34a.31.31,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3l0,47.34a4.44,4.44,0,0,0,4.44,4.44l48.39,0h0a4.45,4.45,0,0,0,4.45-4.44l0-49.77L52.12,4.9l-34.71,0h0A4.44,4.44,0,0,0,13,9.3v9.85a.3.3,0,0,1-.3.3h0a.29.29,0,0,1-.3-.3V9.3a5.05,5.05,0,0,1,5-5h0l34.83,0a.35.35,0,0,1,.22.08l18.29,18.4a.36.36,0,0,1,.09.22l0,49.89A5.05,5.05,0,0,1,65.74,77.93Z" />
          <Path fillRule="evenodd" fill="#bac2ca" d="M60.31,35.06v-5a.41.41,0,0,0-.53-.4c-1.64.67-6.57,2.59-10.77,2.18-5.07-.51-6.47-2.78-11.41-3.27-5.31-.53-10.76,1.66-12.1,2.13a.33.33,0,0,0-.22.32V52.14a.26.26,0,0,0,.32.25c1.6-.58,7.41-2.55,12-2.09S43.92,53.06,49,53.56c4.54.45,10-1.77,11.16-2.25a.33.33,0,0,0,.2-.31Z" />
          <Line fill="#fff" x1="58.63" y1="31.69" x2="58.63" y2="30.88" />
          <Path fill="#000" d="M58.63,32a.3.3,0,0,1-.3-.3v-.81a.29.29,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3v.81a.3.3,0,0,1-.3.3Z" />
          <Path fill="#fff" d="M58.62,29.87V28a.4.4,0,0,0-.51-.4c-1.58.67-6.31,2.59-10.34,2.18-4.87-.51-6.2-2.78-11-3.27-5.1-.53-10.32,1.66-11.61,2.13A.32.32,0,0,0,25,29V50.1a.25.25,0,0,0,.31.25c1.53-.58,7.11-2.55,11.51-2.09s6.08,2.76,11,3.26c4.36.45,9.63-1.77,10.71-2.25a.32.32,0,0,0,.19-.31l0-16" />
          <Path fill="#000" d="M49,51.88q-.63,0-1.23-.06A17.6,17.6,0,0,1,41.9,50.1a15.44,15.44,0,0,0-5.11-1.54C32.47,48.11,27,50,25.41,50.63a.53.53,0,0,1-.47-.06.58.58,0,0,1-.24-.47V29a.62.62,0,0,1,.4-.6l.4-.15c1.8-.68,6.6-2.49,11.35-2a16.32,16.32,0,0,1,5.49,1.64,16.14,16.14,0,0,0,5.46,1.63c1.86.19,5.14,0,10.19-2.15a.61.61,0,0,1,.6.06.71.71,0,0,1,.33.61v1.86a.3.3,0,0,1-.3.3h0a.3.3,0,0,1-.3-.3V28a.12.12,0,0,0-.06-.11c-4.19,1.74-7.72,2.48-10.52,2.19a16.56,16.56,0,0,1-5.64-1.68,15.5,15.5,0,0,0-5.31-1.59c-4.61-.48-9.31,1.29-11.08,2l-.4.15V50C27,49.38,32.49,47.51,36.85,48a16.28,16.28,0,0,1,5.29,1.59,17.19,17.19,0,0,0,5.66,1.67c4.12.44,9.12-1.59,10.56-2.22l0-16a.31.31,0,0,1,.3-.31h0a.3.3,0,0,1,.3.3l0,16a.63.63,0,0,1-.37.59A26.93,26.93,0,0,1,49,51.88ZM25.2,50.07Z" />
          <Path fill="#2c2627" d="M31.33,68.18H19.16a.3.3,0,0,1,0-.6H31.33a.3.3,0,0,1,0,.6Z" />
          <Path fill="#2c2627" d="M25,68.18a.29.29,0,0,1-.3-.3V31a.3.3,0,1,1,.6,0V67.88A.29.29,0,0,1,25,68.18Z" />
          <Circle fill={primary} cx="42.1" cy="38.98" r="7.3" />
          <Path fillRule="evenodd" fill="#fff" d="M45.82,36.39a.24.24,0,0,1,0,.33L41,41.58a.23.23,0,0,1-.17.07.21.21,0,0,1-.17-.08l-2.25-2.43a.24.24,0,0,1,0-.33.23.23,0,0,1,.33,0l2.08,2.25,4.69-4.69A.24.24,0,0,1,45.82,36.39Z" />
          <Path fill="#fff" d="M40.79,42h0a.54.54,0,0,1-.38-.17l-2.25-2.43A.62.62,0,0,1,38,39a.58.58,0,0,1,.17-.37.6.6,0,0,1,.39-.14.58.58,0,0,1,.37.17l1.87,2,4.47-4.48a.54.54,0,0,1,.75,0h0a.53.53,0,0,1,0,.76l-4.86,4.86A.55.55,0,0,1,40.79,42Zm4.82-5.45Z" />
        </G>
      </G>
    </Svg>
  );
};

export default Payoff;
