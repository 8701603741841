import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { BaseTextField, BorderlessButton, LargePrimaryButton } from '../../../../base_ui';

export const CheckboxLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const ClearButton = styled(BorderlessButton).attrs(({
  theme: { colors: { linkText }, fontFamilies: { secondaryFont } },
  titleStyle,
}) => ({ titleStyle: [titleStyle, { color: linkText, fontFamily: secondaryFont }] }))`
  margin: ${moderateScale(10)}px 0 ${moderateScale(35)}px;
  padding: 0 ${moderateScale(14)}px;
`;

export const FormContainer = styled.View`
  align-items: center;
  flex: auto; 
  margin-top: ${moderateScale(24)}px;
`;

export const FormInputsContainer = styled.View`
  opacity: ${({ $disabled }) => $disabled ? '0.5' : '1'};
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(({ theme: { fontSizes: { f5 } } }) => ({ inputFontSize: f5 }))``;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  align-self: center;
  margin: ${moderateScale(19)}px 0 ${moderateScale(35)}px;
`;

export const TypeContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${moderateScale(16)}px;
  padding-right: ${moderateScale(16)}px;
  width: ${moderateScale(327)}px;
`;

export const UseExistingContainer = styled.View`
  align-items: center;
  padding-bottom: ${moderateScale(26)}px;
`;
