import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';

import { CONFIRMATION_DICT } from '@dmi/shared/redux/SecureMessaging/constants';

import { SecondaryHeader } from '../../../ScreenHeaders';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  ErrorContainer,
  ErrorHeader,
  ErrorIllustrationWrapper,
  ErrorText,
  PhoneNumberWrapper,
  StyledConfirmationButton,
} from '../styledComponents';

const errorStorm = iconDictionary('errorStorm');

const NewMessageError = ({ customerServicePhoneNumber, handleBack }) => {
  const {
    error: { body, defaultCustomerServicePhoneNumber, header },
    linkLabel,
  } = CONFIRMATION_DICT;
  const numberToUse = customerServicePhoneNumber || defaultCustomerServicePhoneNumber;

  return (
    <ErrorContainer>
      <SecondaryHeader
        handleBack={handleBack}
        title="Confirmation"
      />
      <ErrorIllustrationWrapper>
        {errorStorm}
      </ErrorIllustrationWrapper>
      <ErrorHeader>{header}</ErrorHeader>
      <ErrorText>
        {body}
        <PhoneNumberWrapper
          onPress={() => Linking.openURL(
            `tel://${numberToUse.replace(/\+|-/g, '')}`,
          )}
        >
          { numberToUse }
        </PhoneNumberWrapper>.
      </ErrorText>
      <StyledConfirmationButton
        label={linkLabel}
        onPress={handleBack}
      />
    </ErrorContainer>
  );
};

NewMessageError.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  handleBack: T.func.isRequired,
};

export default NewMessageError;
