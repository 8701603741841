import { Animated, Platform } from 'react-native';

const useNativeDriver = Platform.OS !== 'web';

const loopSequence = (opacityValue) => Animated.loop(
  Animated.sequence([
    Animated.timing(opacityValue, {
      duration: 850,
      toValue: 1,
      useNativeDriver,
    }),
    Animated.timing(opacityValue, {
      duration: 650,
      toValue: 0.5,
      useNativeDriver,
    }),
  ]),
);

export default loopSequence;
