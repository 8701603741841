import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import T from 'prop-types';
import { LayoutAnimation, Platform } from 'react-native';

import { DISMISSIBLE_DISCLOSURE_BANNER } from '@dmi/shared/redux/Main/constants';

import { PreLoginBanner } from '../../base_ui';
import {
  DISCLOSURE_BANNER_PXS,
  PORTION_OF_NEXT_BANNER_PXS,
  TOP_PORTION_OF_LARGE_BANNER_PXS,
} from './constants';
import { BottomBannersContainer } from './styledComponents';

const PreLoginBottomBanners = ({
  bottomBanners,
  handleDismissBanner,
  handleOpenDisclosureDrawer,
  isBannerContainerOpen,
  shouldHideBanners,
  toggleShowBanners,
}) => {
  const [initialContainerHeight, setInitialContainerHeight] = useState(0);
  const isSingleBanner = bottomBanners.length === 1;
  const baseLayoutAnimationConfig = Object.freeze({ property: 'opacity', type: 'easeInEaseOut' });

  // for native, animate the banner container height on change
  useEffect(() => {
    if (Platform.OS !== 'android') {
      LayoutAnimation.configureNext({
        create: { ...baseLayoutAnimationConfig, duration: 50 },
        duration: 250,
        update: { ...baseLayoutAnimationConfig },
      });
    }
  }, [baseLayoutAnimationConfig, isBannerContainerOpen, shouldHideBanners]);

  const handleLayout = useCallback(() => {
    const { bannerType } = bottomBanners[0];
    const initialHeight = bannerType === DISMISSIBLE_DISCLOSURE_BANNER
      ? DISCLOSURE_BANNER_PXS + PORTION_OF_NEXT_BANNER_PXS
      : TOP_PORTION_OF_LARGE_BANNER_PXS;
    setInitialContainerHeight(initialHeight);
  }, [bottomBanners]);

  const renderedBanners = useMemo(() => (
    bottomBanners.map((bannerProps, index) => {
      const isFirstBanner = index === 0;
      const isLastBanner = index === bottomBanners.length - 1;
      const { bannerName } = bannerProps;

      return (
        <PreLoginBanner
          key={`${bannerName}-banner`}
          handleDismissBanner={handleDismissBanner}
          handleOpenDrawer={handleOpenDisclosureDrawer}
          isLastBanner={isLastBanner}
          toggleShowBanners={isFirstBanner ? toggleShowBanners : null}
          {...bannerProps}
        />
      );
    })
  ), [
    bottomBanners,
    handleDismissBanner,
    handleOpenDisclosureDrawer,
    toggleShowBanners,
  ]);

  return (
    <BottomBannersContainer
      $initialContainerHeight={initialContainerHeight}
      $isBannerContainerOpen={isBannerContainerOpen}
      $isSingleBanner={isSingleBanner}
      $shouldHideBanners={shouldHideBanners}
      onLayout={handleLayout}
    >
      {renderedBanners}
    </BottomBannersContainer>
  );
};

PreLoginBottomBanners.propTypes = {
  bottomBanners: T.arrayOf(T.shape({
    bannerName: T.string.isRequired,
    bannerType: T.string.isRequired,
  })).isRequired,
  handleDismissBanner: T.func.isRequired,
  handleOpenDisclosureDrawer: T.func.isRequired,
  isBannerContainerOpen: T.bool.isRequired,
  shouldHideBanners: T.bool.isRequired,
  toggleShowBanners: T.func.isRequired,
};

export default PreLoginBottomBanners;
