import React from 'react';
import T from 'prop-types';

import { MediumPrimaryButton } from '../../base_ui';
import { FooterContainer, StyledBorderlessButton } from './styledComponents';

const FooterComponent = ({ dispatchChangeStep, handleClose }) => (
  <FooterContainer>
    <MediumPrimaryButton
      label="Cancel Request"
      onPress={() => dispatchChangeStep(2)}
    />
    <StyledBorderlessButton
      isPrimary
      label="Go Back"
      onPress={handleClose}
    />
  </FooterContainer>
);

FooterComponent.propTypes = {
  dispatchChangeStep: T.func.isRequired,
  handleClose: T.func.isRequired,
};

export default FooterComponent;
