import React, { Fragment } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { getProperBiometricLabel } from '@dmi/shared/redux/Settings/Security/helpers';
import {
  BaseToggle,
  ConditionalRender,
  ErrorSuccessBanner,
  H4,
} from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import ScreenReaderNotifications from '../../ScreenReaderNotifications';
import iconDictionary from '../../../utils/iconDictionary';
import {
  SignInContainer,
  SignInContentWrapper,
  StyledH4,
  StyledP3,
  ToggleContainer,
  ToggleWrapper,
} from './styledComponents';

const PoolHouseIllustration = iconDictionary('poolHouse');

const SignIn = ({
  dispatchDisableBiometricLogin,
  dispatchDisablePin,
  dispatchEnableBiometricLogin,
  dispatchResetBannerError,
  error,
  isBiometricLoginEnabled,
  isPinEnabled,
  navigation,
  notifications,
  setIsDrawerOpen,
  supportedBiometricAuthenticationTypes: {
    face: isFaceAuthSupported,
    fingerprint: isFingerprintAuthSupported,
    iris: isIrisAuthSupported,
  },
  supportedBiometricAuthenticationTypes,
}) => {
  const isAnyBiometricAuthSupported =
    isFaceAuthSupported
    || isFingerprintAuthSupported
    || isIrisAuthSupported;
  const biometricTypeLabel = getProperBiometricLabel(
    Platform.OS,
    supportedBiometricAuthenticationTypes,
  );
  const biometricLabel = isBiometricLoginEnabled ? 'On' : 'Off';
  const pinLabel = isPinEnabled ? 'On' : 'Off';

  const handlePressBiometric = isBiometricLoginEnabled ?
    dispatchDisableBiometricLogin :
    dispatchEnableBiometricLogin;

  const handlePressPin = isPinEnabled ?
    dispatchDisablePin :
    () => {
      dispatchResetBannerError();
      setIsDrawerOpen(true);
    };

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Sign In Methods"
      />
      <ErrorSuccessBanner error={error} />
      <SignInContainer>
        <SignInContentWrapper>
          {/* TODO: Replace this with updated copy once we receive it */}
          {/* <P2>
            A pin must be set in order to turn on Touch ID. It can also be
            used as a backup authentication method for Touch ID.
          </P2> */}
          <ToggleContainer>
            <H4>Pin Login</H4>
            <ToggleWrapper>
              <StyledP3>{pinLabel}</StyledP3>
              <BaseToggle
                ariaLabel="Pin Login"
                isActive={isPinEnabled}
                onPress={handlePressPin}
              />
            </ToggleWrapper>
          </ToggleContainer>
          <ConditionalRender
            Component={(
              <ToggleContainer>
                <StyledH4>{biometricTypeLabel}</StyledH4>
                <ToggleWrapper>
                  <StyledP3>{biometricLabel}</StyledP3>
                  <BaseToggle
                    ariaLabel="Touch ID"
                    isActive={isBiometricLoginEnabled}
                    onPress={handlePressBiometric}
                  />
                </ToggleWrapper>
              </ToggleContainer>
            )}
            shouldRender={isAnyBiometricAuthSupported}
          />
        </SignInContentWrapper>
        {PoolHouseIllustration}
      </SignInContainer>
    </Fragment>
  );
};

SignIn.propTypes = {
  dispatchDisableBiometricLogin: T.func.isRequired,
  dispatchDisablePin: T.func.isRequired,
  dispatchEnableBiometricLogin: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  setIsDrawerOpen: T.func.isRequired,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
};

export default SignIn;
