import React, { Fragment } from 'react';
import T from 'prop-types';

import { ConditionalRender, LargePrimaryButton } from '../../../../../base_ui';
import UniqueContent from '../UniqueContent';
import { ButtonContainer, Container, StyledTertiaryDivider } from './styledComponents';

const CardMapper = ({
  dispatchChangePaperlessForm,
  dispatchChangeStep,
  dispatchSetSelectedLoan,
  formData,
  setIsTooltipOpen,
}) => formData.map(({
  addressData,
  hashedLoanNumber,
  isButtonDisabled,
  isToggleDisabled,
  isToggleOn,
  loanNumber,
  toggleText,
}, i) => {
  const handleButtonPress = () => {
    dispatchSetSelectedLoan(hashedLoanNumber);
    dispatchChangeStep(2);
  };

  const handleTogglePress = () => {
    dispatchChangePaperlessForm(hashedLoanNumber);
  };

  return (
    <Fragment key={loanNumber}>
      <Container>
        <UniqueContent
          addressData={addressData}
          handleTogglePress={handleTogglePress}
          isToggleDisabled={isToggleDisabled}
          isToggleOn={isToggleOn}
          loanNumber={loanNumber}
          setIsTooltipOpen={setIsTooltipOpen}
          toggleText={toggleText}
        />
        <ButtonContainer>
          <LargePrimaryButton
            disabled={isButtonDisabled}
            label="Update"
            onPress={handleButtonPress}
          />
        </ButtonContainer>
      </Container>
      <ConditionalRender
        Component={StyledTertiaryDivider}
        shouldRender={i !== formData.length - 1}
      />
    </Fragment>
  );
});

CardMapper.propTypes = {
  dispatchChangePaperlessForm: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  formData: T.arrayOf(T.shape({
    addressData: T.arrayOf(T.string).isRequired,
    hashedLoanNumber: T.string.isRequired,
    isButtonDisabled: T.bool.isRequired,
    isToggleDisabled: T.bool.isRequired,
    isToggleOn: T.bool.isRequired,
    loanNumber: T.string.isRequired,
    toggleText: T.string.isRequired,
  })).isRequired,
  setIsTooltipOpen: T.func.isRequired,
};

export default CardMapper;
