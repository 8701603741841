import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { createStructuredSelector } from 'reselect';
import makeSelectMain, { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';

import AccountDeletionView from '../../components/AccountDeletion';

const AccountDeletion = ({
  clientCustomerServiceNumber,
  isLoggedIn,
}) => {
  const navigation = useNavigation();
  return (
    <AccountDeletionView
      customerServicePhoneNumber={clientCustomerServiceNumber}
      isLoggedIn={isLoggedIn}
      navigation={navigation}
    />
  );
};

AccountDeletion.propTypes = {
  clientCustomerServiceNumber: T.string.isRequired,
  isLoggedIn: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
  * Store : Main
  */
  clientCustomerServiceNumber: getMainClientInfo('customerServicePhoneNumber'),
  isLoggedIn: makeSelectMain('isLoggedIn'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(AccountDeletion);
