import styled from 'styled-components/native';

import { H5, P5 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const HeaderContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${moderateScale(40)}px;
  margin-top: ${moderateScale(11)}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const HeaderContent = styled.View`
  margin-left: ${moderateScale(7.5)}px;
  width: 85%;
`;

export const SmallHeader = styled(H5)`
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const StyledCircle = styled(P5)`
  font-size: ${moderateScale(6)}px;
  line-height: ${moderateScale(15)}px;
  margin: 0 ${moderateScale(8)}px;
`;

export const TextWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;
