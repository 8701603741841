import React from 'react';
import T from 'prop-types';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  DrawerContainer,
  HeaderWrapper,
  IllustrationWrapper,
  StyledBorderlessButton,
  StyledH2,
  StyledP2,
  StyledPrimaryDivider,
} from './styledComponents';

const DogPlayingIcon = iconDictionary('dogPlaying',
  { height: `${moderateScale(120)}px`, width: `${moderateScale(287)}px` });

const SuccessView = ({ handleClose, navigation }) => {
  const { subheader, text } = LOANS_CONTENT_DICTIONARY.cancelMailingAddress.confirmationView;

  return (
    <DrawerContainer>
      <IllustrationWrapper>
        {DogPlayingIcon}
      </IllustrationWrapper>
      <HeaderWrapper>
        <StyledH2>{subheader}</StyledH2>
      </HeaderWrapper>
      <StyledP2>{text}</StyledP2>
      <StyledPrimaryDivider />
      <StyledBorderlessButton
        isPrimary
        label="Return to Settings"
        onPress={() => {
          handleClose();
          navigation.navigate('Settings');
        }}
      />
    </DrawerContainer>
  );
};

SuccessView.propTypes = {
  handleClose: T.func.isRequired,
  navigation: T.object.isRequired,
};

export default SuccessView;
