import React from 'react';
import T from 'prop-types';
import { Text, View } from 'react-native';

import { SecondaryHeader } from '../../ScreenHeaders';

const AutopaySource = ({ navigation }) => (
  <View>
    <SecondaryHeader
      handleBack={() => navigation.navigate('AutopaySetupOverview')}
      title="Autopay Edit Source"
    />
    <Text>Autopay Edit Source Screen Placeholder</Text>
  </View>
);

AutopaySource.propTypes = { navigation: T.object.isRequired };

export default AutopaySource;
