import React from 'react';

import { MESSAGES_TABLE_TEXT_TOP } from '@dmi/shared/redux/LossDraft/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { CardContainer, CardText } from './styledComponents';

const lossDraftInbox = iconDictionary('lossDraftInbox');

const IllustrationCard = () => (
  <CardContainer>
    {lossDraftInbox}
    <CardText>
      {MESSAGES_TABLE_TEXT_TOP}
    </CardText>
  </CardContainer>
);

export default IllustrationCard;
