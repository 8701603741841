import StepOneView from './StepOneView';
import StepTwoView from './StepTwoView';
import ErrorView from './ErrorView';
import SuccessView from './SuccessView';

export const cancelMailingAddressDictionary = {
  1: StepOneView,
  2: StepTwoView,
  3: ErrorView,
  4: SuccessView,
};
