import React, { useState } from 'react';
import T from 'prop-types';

import { P2 } from '../../base_ui';
import { PressableRow } from './styledComponents';

const ResourceRowItem = ({
  ariaLabel,
  documentName,
  handlePress,
  IconToUse,
  rowLabel,
}) => {
  const [isPressing, setIsPressing] = useState(false);

  return (
    <PressableRow
      $isPressing={isPressing}
      aria-label={ariaLabel}
      onPress={handlePress}
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      role="button"
    >
      <P2>{documentName || rowLabel}</P2>
      {IconToUse}
    </PressableRow>
  );
};

ResourceRowItem.propTypes = {
  ariaLabel: T.string.isRequired,
  documentName: T.string,
  handlePress: T.func.isRequired,
  IconToUse: T.node.isRequired,
  rowLabel: T.string,
};

export default ResourceRowItem;
