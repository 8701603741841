import React from 'react';
import T from 'prop-types';

import glossaryData from '@dmi/shared/redux/Help/mobileGlossaryOfTerms';

import { SecondaryHeader } from '../../ScreenHeaders';
import ListItem from './ListItem';
import {
  FooterBlock,
  Heading,
  ListContainer,
  StyledSectionList,
  StyledView,
} from './styledComponents';

const Glossary = ({ data = glossaryData, navigation }) => (
  <StyledView>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Glossary of Mortgage Terms"
    />
    <ListContainer>
      <StyledSectionList
        ListFooterComponent={FooterBlock}
        renderItem={({ item: { definition, term } }) => (
          <ListItem definition={definition} term={term} />
        )}
        renderSectionHeader={({ section: { title } }) => <Heading tabIndex={0}>{title}</Heading>}
        sections={data}
        stickySectionHeadersEnabled
      />
    </ListContainer>
  </StyledView>
);

Glossary.propTypes = { data: T.array, navigation: T.object.isRequired };

export default Glossary;
