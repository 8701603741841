import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P4 } from '../../../../base_ui';

export const Container = styled.View`
  display: flex;
  flex-direction: row;
  padding-bottom: 0;
`;

export const IconWrapper = styled.View`
  padding-top: 0;
`;

export const StyledText = styled(P4)`
  flex-shrink: 1;
  padding-left: ${moderateScale(8)}px;
`;
