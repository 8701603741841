import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H5 } from '../../Heading';
import { P3 } from '../../Paragraphs';

// used by NonDismsissibleBanner

export const NonDismissibleBannerContainer = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(4)}px;
  padding: ${moderateScale(10)}px ${moderateScale(16)}px;
  width: 100%;
`;

export const NonDismissibleContainer = styled.View`
  padding: 0 ${moderateScale(8)}px;
`;

// used by DismissibleBanners

export const DismissibleBannerContainer = styled.View`
  border: ${({ $isUrgent, theme: { colors: { accentRed, primary } } }) =>
    `1px solid ${$isUrgent ? accentRed : primary}`};
  border-radius: ${moderateScale(4)}px;
  padding: ${({ $isDrawerType }) =>
    `${moderateScale($isDrawerType ? 12 : 10)}px ${moderateScale(16)}px;`};
  width: 100%;
`;

export const DismissibleContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  display: ${({ $isDismissed }) => ($isDismissed ? 'none' : 'flex')};
  margin-bottom:
    ${({ $isLastBanner, $isTopBanner }) => $isLastBanner || $isTopBanner ? 0 : moderateScale(4)}px;
  padding: 0 ${moderateScale(8)}px;
`;

export const LeftContent = styled.View`
  flex-direction: row;
`;

export const StyledDismissibleView = styled.View`
  margin-top: ${({ $isDismissed, $isTopBanner }) =>
    ($isDismissed && $isTopBanner ? moderateScale(50) : 0)}px;
`;

// used by both DismissibleBanners and NonDismissibleBanner

export const BannerHeading = styled(H5)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  margin-right: ${moderateScale(8)}px;
  max-width: 85%;
`;

export const ContentContainer = styled.View`
  margin-top: ${moderateScale(4)}px;
  padding-right: ${moderateScale(8)}px;
`;

export const HeadingContainer = styled.Pressable`
  flex-direction: row;
  justify-content: space-between;
`;

export const StyledLinkText = styled(P3)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;
