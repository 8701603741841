/* eslint-disable react/no-array-index-key */
import React from 'react';
import T from 'prop-types';

import { MOBILE_DOC_REVIEW_DICT } from '@dmi/shared/redux/SecureMessaging/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender, LargePrimaryButton, P3 } from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import FileItem from '../../../DocUpload/ReviewScene/FileItem';
import {
  BottomButtonWrapper,
  ContentWrapper,
  DocReviewContainer,
  ErrorBannerContainer,
  Header,
  HeaderSubText,
  IconWrapper,
  IllustrationHeaderWrapper,
  IllustrationWrapper,
  StyledBorderlessDocPickerButton,
  StyledPrimaryDivider,
} from './styledComponents';

const Illustration = iconDictionary('magnifyingGlass');
const WarningIcon = iconDictionary('paymentWarningL');

const SecureMessageDocReview = ({
  dispatchChooseDocument,
  dispatchRemoveDocument,
  dispatchUpdateAttachments,
  formAttachments,
  formUploadError,
  handleBack,
}) => {
  const { description, headerLabel, secondaryHeader } = MOBILE_DOC_REVIEW_DICT;
  const existingFormAttachments = formAttachments.filter(({ removed }) => !removed);
  const hasError = formAttachments.some(({ bannerError, removed }) =>
    !!bannerError && !removed) || !!formUploadError;

  const handleBackPress = () => {
    dispatchUpdateAttachments({ isSubmit: false });
    handleBack();
  };

  return (
    <DocReviewContainer>
      <SecondaryHeader
        handleBack={handleBackPress}
        title={headerLabel}
      />
      <ContentWrapper>
        <ConditionalRender
          Component={(
            <ErrorBannerContainer>
              <IconWrapper>{WarningIcon}</IconWrapper>
              <P3>{formUploadError}</P3>
            </ErrorBannerContainer>
          )}
          shouldRender={!!formUploadError}
        />
        <IllustrationHeaderWrapper>
          <IllustrationWrapper>{Illustration}</IllustrationWrapper>
          <Header>{secondaryHeader}</Header>
          <HeaderSubText>{description}</HeaderSubText>
        </IllustrationHeaderWrapper>
        {formAttachments.map(({
          bannerError: fileError,
          fileName,
          removed,
        }, index) => !removed && (
          <FileItem
            key={`${fileName}-${index}`}
            fileName={fileName}
            isMultifile
            removeFileHandler={() => dispatchRemoveDocument({ file: fileName, index })}
            renderInlineButton
            validationErrorMessage={fileError}
          />
        ))}
        <ConditionalRender
          Component={StyledPrimaryDivider}
          shouldRender={existingFormAttachments.length < 5}
        />
        <ConditionalRender
          Component={StyledBorderlessDocPickerButton}
          propsToPassDown={{
            dispatchChooseDocument,
            displayIcon: true,
            label: 'Add Another File',
          }}
          shouldRender={existingFormAttachments.length < 5}
        />
      </ContentWrapper>
      <BottomButtonWrapper>
        <LargePrimaryButton
          disabled={hasError}
          label="Submit"
          onPress={() => {
            dispatchUpdateAttachments({ isSubmit: true });
            handleBack();
          }}
        />
      </BottomButtonWrapper>
    </DocReviewContainer>
  );
};

SecureMessageDocReview.propTypes = {
  dispatchChooseDocument: T.func.isRequired,
  dispatchRemoveDocument: T.func.isRequired,
  dispatchUpdateAttachments: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formUploadError: T.string.isRequired,
  handleBack: T.func.isRequired,
};

export default SecureMessageDocReview;
