import React, { Fragment } from 'react';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { Skeleton as BaseSkeleton } from '../../../../base_ui';
import {
  CardContainer,
  CenterContainer,
  RowContainer,
  StyledPrimaryDivider,
  StyledTertiaryDivider,
} from './styledComponents';

const Skeleton = () => (
  <Fragment>
    <CardContainer>
      <BaseSkeleton
        $bottomMargin={`${moderateScale(18)}px`}
        height={`${moderateScale(16)}px`}
        width={`${moderateScale(150)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(7)}px`}
        height={`${moderateScale(14)}px`}
        width={`${moderateScale(100)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(7)}px`}
        height={`${moderateScale(10)}px`}
        width={`${moderateScale(100)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(21)}px`}
        height={`${moderateScale(10)}px`}
        width={`${moderateScale(100)}px`}
      />
      <StyledPrimaryDivider />
      <RowContainer>
        <BaseSkeleton
          height={`${moderateScale(16)}px`}
          width={`${moderateScale(100)}px`}
        />
        <BaseSkeleton
          height={`${moderateScale(16)}px`}
          width={`${moderateScale(100)}px`}
        />
      </RowContainer>
      <CenterContainer>
        <BaseSkeleton
          height={`${moderateScale(46)}px`}
          width={`${moderateScale(260)}px`}
        />
      </CenterContainer>
    </CardContainer>
    <StyledTertiaryDivider />
    <CardContainer>
      <BaseSkeleton
        $bottomMargin={`${moderateScale(18)}px`}
        height={`${moderateScale(16)}px`}
        width={`${moderateScale(150)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(7)}px`}
        height={`${moderateScale(14)}px`}
        width={`${moderateScale(100)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(7)}px`}
        height={`${moderateScale(10)}px`}
        width={`${moderateScale(100)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(21)}px`}
        height={`${moderateScale(10)}px`}
        width={`${moderateScale(100)}px`}
      />
      <StyledPrimaryDivider />
      <RowContainer>
        <BaseSkeleton
          height={`${moderateScale(16)}px`}
          width={`${moderateScale(100)}px`}
        />
        <BaseSkeleton
          height={`${moderateScale(16)}px`}
          width={`${moderateScale(100)}px`}
        />
      </RowContainer>
      <CenterContainer>
        <BaseSkeleton
          height={`${moderateScale(46)}px`}
          width={`${moderateScale(260)}px`}
        />
      </CenterContainer>
    </CardContainer>
  </Fragment>
);

export default Skeleton;
