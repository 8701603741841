import React from 'react';
import { Platform } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { enableScreens } from 'react-native-screens';

import nonHomeDrawerScreens from './nonHomeDrawerScreens';
import HomeDrawerNavigator from './HomeDrawerNavigator';

const Stack = createStackNavigator();

if (Platform.OS === 'web') {
  enableScreens(false);
}

const PostAuthStack = () => (
  <Stack.Navigator
    initialRouteName="HomeDrawerNavigator"
    screenOptions={{
      cardStyle: { ...Platform.select({ web: { height: '-webkit-fill-available' } }) },
      headerShown: false,
    }}
  >
    <Stack.Screen component={HomeDrawerNavigator} name="HomeDrawerNavigator" />
    {Object.entries(nonHomeDrawerScreens).map(([name, otherProps]) => (
      <Stack.Screen key={name} name={name} {...otherProps} />
    ))}
  </Stack.Navigator>
);

export default PostAuthStack;
