import styled from 'styled-components/native';

import { P2 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const HeaderWrapper = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: 0 ${moderateScale(24)}px ${moderateScale(17)}px;
`;

export const LabelWrapper = styled(P2)`
  margin-right: ${moderateScale(24)}px;
`;

export const PaymentDetailContainer = styled.ScrollView.attrs(
  { contentContainerStyle: { flex: 1 } },
)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  width: 100%;
`;

export const PaymentDetailRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(11)}px 0;
`;

export const PaymentDetailRows = styled.View`
  padding: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const SkeletonWrapper = styled.View`
  width: ${({ width }) => width};
`;

export const TextWrapper = styled(P2)`
  flex-shrink: 1;
  flex-wrap: wrap;
  text-align: left;
`;
