import React from 'react';
import styled, { css } from 'styled-components/native';

import BaseCheckBox from './baseCheckBox';
import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';

const CheckmarkIcon = iconDictionary('approved', { height: '100%', width: '100%' });

const boxStyle = css`
  border-radius: 4px;
  border-radius: ${({ $isRound }) => $isRound ? '14px' : '4px'};
  border-width: 1px;
  height: ${moderateScale(16)}px;
  width: ${moderateScale(16)}px;
`;

const CheckedBox = styled.View`
  ${boxStyle}
  background-color: ${({ theme: { colors: { primary } } }) => primary};
  border-color: ${({ theme: { colors: { primary } } }) => primary};
`;

const UncheckedBox = styled.View`
  ${boxStyle}
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
`;

export const Checkbox = styled(BaseCheckBox).attrs(({ isRound, theme }) => {
  const {
    colors: { primaryCarbon },
    fontFamilies: { secondaryFont },
    fontSizes: { f3 },
  } = theme;
  return {
    activeOpacity: 1,
    checkedIcon: <CheckedBox $isRound={isRound}>{CheckmarkIcon}</CheckedBox>,
    containerStyle: {
      backgroundColor: 'transparent',
      margin: 0,
      marginLeft: 0,
      marginRight: 0,
      padding: 0,
    },
    fontFamily: secondaryFont,
    size: 16,
    textStyle: {
      color: primaryCarbon,
      fontSize: f3,
      fontWeight: '400',
      marginLeft: moderateScale(8),
    },
    uncheckedIcon: <UncheckedBox $isRound={isRound} />,
  };
})``;

export const CheckboxWithText = styled(Checkbox).attrs(({ containerStyle }) => (
  { containerStyle: { ...containerStyle, borderColor: 'transparent' } }
))``;

export const CheckboxSelect = styled(Checkbox).attrs(({ theme }) => {
  const { colors: { primaryGray } } = theme;
  return {
    containerStyle: {
      alignSelf: 'flex-start',
      backgroundColor: 'transparent',
      borderColor: primaryGray,
      borderRadius: 0,
      height: moderateScale(32),
      justifyContent: 'center',
      margin: 0,
      marginLeft: 0,
      marginRight: 0,
      padding: moderateScale(8),
      position: 'relative',
    },
  };
})``;
