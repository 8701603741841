/* eslint-disable max-len */
import { FORGOT_USERNAME_CONFIRMATION_DICTIONARY } from '@dmi/shared/redux/Register/ForgotUsername/constants';
import { INTL_IDS } from '@dmi/shared/redux/Register/ForgotUsername/messages';

export const FORGOT_USERNAME_CARD_PROPS = {
  cardAnimation: {
    mobile: 'registerClipboardMobile',
    web: 'registerClipboardDesktop',
  },
  cardText: INTL_IDS.FORGOT_USERNAME_FORM_PARAGRAPH,
  cardTitle: INTL_IDS.FORGOT_USERNAME_FORM_HEADING,
  cardTooltip: false,
};

export const FORGOT_USERNAME_CONFIRMATION_PROPS = {
  cardAnimation: {
    mobile: 'airplaneMobile',
    web: 'airplaneDesktop',
  },
  cardDictionary: FORGOT_USERNAME_CONFIRMATION_DICTIONARY,
};

export const VERIFY_USERNAME_CARD_PROPS = {
  cardAnimation: {
    mobile: 'registerClipboardMobile',
    web: 'registerClipboardDesktop',
  },
  cardText: INTL_IDS.FORGOT_USERNAME_VERIFY_PARAGRAPH,
  cardTitle: INTL_IDS.FORGOT_USERNAME_VERIFY_HEADING,
  cardTooltip: false,
};

export const VERIFY_USERNAME_CONFIRMATION_PROPS = {
  cardAnimation: {
    mobile: 'usernameNametagMobile',
    web: 'usernameNametagDesktop',
  },
  cardDictionary: FORGOT_USERNAME_CONFIRMATION_DICTIONARY,
};
