/* eslint-disable max-len, no-tabs, no-lone-blocks */
import React from 'react';
import Svg, {
  Circle,
  ClipPath,
  Defs,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function EmailChair(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg data-name="Layer 1" height={moderateScale(315)} id="Layer_1" viewBox="0 0 251 315" width={moderateScale(251)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Defs>
        <ClipPath id="clip-path">
          <Rect className="cls-1" fill="none" height="23.33" width="36.78" x="160.98" y="19.94" />
        </ClipPath>
      </Defs>
      <Rect className="cls-2" fill="#bac2ca" height="2.86" width="3.47" x="110.54" y="222.03" />
      <Rect className="cls-2" fill="#bac2ca" height="2.86" width="3.47" x="138.23" y="222.03" />
      <Rect className="cls-3" fill="#e6ebef" height="26.56" width="44.51" x="159.28" y="16.99" />
      <Rect className="cls-4" fill={svgHighlight} height="2.28" width="20.89" x="195.81" y="133.88" />
      <Rect className="cls-4" fill={svgHighlight} height="2.28" width="20.34" x="196.12" y="138.05" />
      <Polygon className="cls-2" fill="#bac2ca" points="71.58 102.1 68.6 103.52 68.6 87.23 71.58 86.2 71.58 102.1" />
      <Path className="cls-5" d="M53.44,114.18s-3.14-5.53-1.72-9.83,6.07-3.23,7.34-7.78S62.39,90,66,88.3A29.84,29.84,0,0,1,72.71,86l-.15,15.6s-4.71,1.65-5.33,3.81C65.76,110.51,62.68,114.86,53.44,114.18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Polygon className="cls-3" fill="#e6ebef" points="34.78 123.24 38.24 147.63 56.17 147.63 59.57 123.11 34.78 123.24" />
      <Polygon className="cls-2" fill="#bac2ca" points="35.09 124.84 35.48 127.58 58.82 127.58 59.26 124.84 35.09 124.84" />
      <Polyline className="cls-6" fill="#bac2ca" points="205.57 165.84 182.22 297.11 177.8 297.11 202.5 158.25 204.23 158.25" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polyline className="cls-6" fill="#bac2ca" points="49.77 165.84 73.12 297.11 77.54 297.11 52.84 158.25 51.09 158.25" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polygon className="cls-2" fill="#bac2ca" points="98.57 147.77 95.31 158 102.28 158 105.41 148.09 98.57 147.77" />
      <Polygon className="cls-2" fill="#bac2ca" points="114.09 132.86 113.21 141.81 141.92 141.81 141.04 132.72 114.09 132.86" />
      <Rect className="cls-7" fill="#8a97a4" height="2.64" width="26.64" x="114.15" y="132.99" />
      <Path className="cls-8" d="M107.62,231.17C104.25,242,93.17,297,93.17,297H95.9l15.42-66.49" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-8" d="M145.87,231.44c.07.24,14.45,65.57,14.45,65.57h-2.73l-15.48-66.74" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-7" d="M152.44,60.28H75.32A3.67,3.67,0,0,0,71.65,64v58.84h111.2V64a3.66,3.66,0,0,0-3.66-3.67H152.44" fill="#8a97a4" />
      <Path className="cls-9" d="M155.89,60.28h23.3A3.66,3.66,0,0,1,182.85,64V129.2a3.66,3.66,0,0,1-3.66,3.66H75.32a3.66,3.66,0,0,1-3.67-3.66V64a3.67,3.67,0,0,1,3.67-3.67h77.31" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="98.68" x2="180.02" y1="122.83" y2="122.83" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="72.07" x2="96.09" y1="122.83" y2="122.83" />
      <Rect className="cls-10" fill={svgHighlight} height="54.36" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" width="104.01" x="75.39" y="64.38" />
      <Polygon className="cls-11" fill="#fff" opacity="0.5" points="132.91 118.35 125.33 118.35 161.16 64.72 168.74 64.72 132.91 118.35" />
      <Polygon className="cls-11" fill="#fff" opacity="0.5" points="99.26 118.35 87.48 118.35 123.31 64.72 135.09 64.72 99.26 118.35" />
      <Rect className="cls-9" fill="none" height="3.17" rx="0.63" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" width="33.76" x="110.62" y="144.54" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="114.17" x2="113.03" y1="133.39" y2="144.4" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="140.95" x2="142.09" y1="133.39" y2="144.4" />
      <Polyline className="cls-9" fill="none" points="70.02 158.16 21.74 158.16 21.74 147.81 147.01 147.81" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polyline className="cls-9" fill="none" points="152.14 147.81 232.7 147.81 232.7 158.16 73.2 158.16" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-12" d="M125.09,222.48c-17.3,0-26.9-2.66-31.28-4.18s-7.6-3.05-8.27-6.94,2-7.89,3-9.79,2.56-7.7,2.56-7.7,11.22-41.16,12.17-43.63,2.66-6.75,7.22-9,15.78-2.57,15.78-2.57h.62s11.22.29,15.78,2.57,6.28,6.56,7.23,9,12.16,43.63,12.16,43.63,1.52,5.8,2.57,7.7,3.71,5.89,3,9.79-3.9,5.41-8.27,6.94-14,4.18-31.27,4.18Z" fill="#fff" />
      <Path className="cls-3" d="M167.72,211.36c.67-3.9-2-7.89-3-9.79s-2.57-7.7-2.57-7.7S150.9,152.71,150,150.24s-2.66-6.75-7.23-9a15.8,15.8,0,0,0-3-1.05c1.68,2.82,5,8.86,6.19,14.06,1.57,7.05,8.87,49.05,8.87,49.05s3.92,9.91-1.69,12-31.7,6.52-62.22,1.69l-.64-.1a34.15,34.15,0,0,0,3.58,1.44c4.38,1.52,14,4.18,31.28,4.18h3.09c17.3,0,26.9-2.66,31.27-4.18S167.06,215.25,167.72,211.36Z" fill="#e6ebef" />
      <Path className="cls-9" d="M125.09,222.48c-17.3,0-26.9-2.66-31.28-4.18s-7.6-3.05-8.27-6.94,2-7.89,3-9.79,2.56-7.7,2.56-7.7,11.22-41.16,12.17-43.63,2.66-6.75,7.22-9,15.78-2.57,15.78-2.57h.62s11.22.29,15.78,2.57,6.28,6.56,7.23,9,12.16,43.63,12.16,43.63,1.52,5.8,2.57,7.7,3.71,5.89,3,9.79-3.9,5.41-8.27,6.94-14,4.18-31.27,4.18Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-13" d="M143.67,230.57a3.41,3.41,0,0,1,3.22,2.31L168.49,297h3.34s-19.75-64.62-20.25-66a13.55,13.55,0,0,0-1.42-2.73s-1.62-3.14-7.5-3.14H110.89c-5.87,0-7.49,3.14-7.49,3.14A13.55,13.55,0,0,0,102,231c-.5,1.42-20.25,66-20.25,66h3l21.6-64.13a3.41,3.41,0,0,1,3.22-2.31Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="105.36" x2="157.89" y1="236.07" y2="266.32" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="147.76" x2="95.23" y1="236.07" y2="266.32" />
      <Path className="cls-5" d="M143.52,213.81a97.81,97.81,0,0,0,10.42-2" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M98.52,211.83a135.08,135.08,0,0,0,41.63,2.39" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Polygon className="cls-2" fill="#bac2ca" points="50.49 161.13 46.22 161.13 46.59 158.46 50.86 158.46 50.49 161.13" />
      <Polygon className="cls-2" fill="#bac2ca" points="204.73 161.13 209 161.13 208.64 158.46 204.36 158.46 204.73 161.13" />
      <Polyline className="cls-9" fill="none" points="34.43 227.22 46.7 158.25 51.13 158.25 26.43 297.11 22 297.11 33.77 230.97" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polygon className="cls-13" fill="#fff" points="73.73 276.02 25.8 276.02 26.98 269.1 72.59 269.1 73.73 276.02" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Circle cx="29.11" cy="272.53" fill="#000" r="0.56" />
      <Circle cx="70.34" cy="272.53" fill="#000" r="0.56" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="48.01" x2="51.38" y1="175.37" y2="175.37" />
      <Polyline className="cls-9" fill="none" points="212.98 182.67 208.64 158.25 204.21 158.25 228.91 297.11 233.34 297.11 213.39 184.97" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polyline className="cls-13" fill="#fff" points="229.54 276.02 228.36 269.1 182.75 269.1 181.61 276.02 227.34 276.02" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Circle cx="226.23" cy="272.53" fill="#000" r="0.56" />
      <Circle cx="185" cy="272.53" fill="#000" r="0.56" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="207.33" x2="203.96" y1="175.37" y2="175.37" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="110.57" x2="110.57" y1="221.84" y2="225.02" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="113.94" x2="113.94" y1="222.03" y2="225.02" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="141.63" x2="141.63" y1="221.84" y2="225.02" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="138.25" x2="138.25" y1="222.18" y2="225.02" />
      <Circle className="cls-5" cx="127" cy="127.81" fill="none" r="1.11" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Rect className="cls-14" fill="#e6ebef" height="6.26" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" width="29.22" x="32.7" y="118.61" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="35.11" x2="38.17" y1="125.07" y2="147.44" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="59.24" x2="56.44" y1="125.07" y2="145.87" />
      <Rect className="cls-15" fill="#fff" height="23.18" rx="0.83" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" width="39.42" x="93.54" y="75.67" />
      <Rect className="cls-15" fill="#fff" height="25.78" rx="0.83" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" width="44.67" x="117.87" y="84.35" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="93.61" x2="130.65" y1="78.57" y2="78.57" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="120.28" x2="160.98" y1="87.05" y2="87.05" />
      <Path className="cls-5" d="M38.52,106S22.06,98.61,9.77,95.6l-1.6-.26a9.3,9.3,0,0,0,1.64,2.23c1.08,1.08,2.64.48,4.08,2.64s1.45,4.75,5.76,6.27,4.16.15,6.54,2.12,3,2.93,6.13,2.64S38.52,106,38.52,106Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-8" d="M35,72.54S31.21,79.22,33,83.18s1.88,4.46,1.19,7.53-1,6,1.63,8,2.82,3.27,3.17,4.81-.64,4.45,1.44,7.72,7.23,3.22,7.23,3.22,5.84-1.48,6.09-7.28a12.29,12.29,0,0,0-.7-3.76,10.86,10.86,0,0,1-.1-5.45c.3-1.29.65-5.35-.89-7.67s-4.8-4-5.54-7S45.23,73.43,35,72.54Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M38.52,106s6.27,2.71,6.83,12.29" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M47.48,114.37c-.27-3.1-.82-7.94-1.94-14-2-10.77-9.29-26.26-9.29-26.26" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M47.73,118.15s0-1.35-.25-3.78" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="39.54" x2="43.55" y1="91.53" y2="92.76" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="46.25" x2="48.45" y1="104.15" y2="100.83" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="21.64" x2="24.82" y1="101.95" y2="100.53" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="29.37" x2="32.55" y1="105.08" y2="103.66" />
      <Path className="cls-5" d="M52.07,118.34a37.17,37.17,0,0,1,6.06-12.18c4.55-6,13.4-13.06,13.4-13.06" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="57.54" x2="58.08" y1="103.22" y2="105.96" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="64" x2="66.74" y1="99.9" y2="100.24" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="13" x2="42.22" y1="297.26" y2="297.26" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="122.76" x2="163.78" y1="297.26" y2="297.26" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="116.19" x2="119.98" y1="297.26" y2="297.26" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="45.32" x2="52.25" y1="297.26" y2="297.26" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="175" x2="204.22" y1="297.26" y2="297.26" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="207.32" x2="210.01" y1="297.26" y2="297.26" />
      <Line className="cls-13" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="226.64" x2="239.7" y1="297.26" y2="297.26" />
      <Path className="cls-9" d="M195.2,129.22l1.54,13.83a5,5,0,0,0,5,4.44h9.16a5,5,0,0,0,5-4.39l1.67-13.88Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-9" d="M216.43,139.77H219a1.29,1.29,0,0,0,1.28-1.08l.6-3.55a1.29,1.29,0,0,0-.28-1,1.31,1.31,0,0,0-1-.46h-2.18" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-9" d="M217.43,131.78h2.18a3.15,3.15,0,0,1,3.1,3.67L222.1,139a3.14,3.14,0,0,1-3.1,2.62h-2.62" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polyline className="cls-5" fill="none" points="168.19 11.56 209.49 11.56 209.49 48.93 153.54 48.93 153.54 11.56 166.03 11.56" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Polyline className="cls-5" fill="none" points="202.18 43.55 159.26 43.55 159.26 16.94 203.77 16.94 203.77 43.55" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="154.78" x2="159.26" y1="12.77" y2="16.94" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="208.2" x2="204.48" y1="12.77" y2="16.22" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="209.35" x2="204.87" y1="48.78" y2="44.6" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" x1="154.86" x2="158.36" y1="47.63" y2="44.37" />
      <G className="cls-16" clipPath="url(#clip-path)">
        <Path className="cls-12" d="M176,32.49s-2.74-.07-8,2.94-7.75,9.42-4.47,13.62S172,50.52,172,50.52l13.82.14s6.08-.94,6.15-5.95-1.87-12-1.87-12Z" fill="#fff" />
        <Path className="cls-17" d="M193.94,29.18c1.7-6,.34-8.25.34-8.25a10.43,10.43,0,0,0-6.74,2.65,11.25,11.25,0,0,0-6.38,0,10.43,10.43,0,0,0-6.74-2.65s-1.36,2.26.34,8.25c0,0-2.49,6.06,3.43,8.36s9.77.81,12.52-.17a5.67,5.67,0,0,0,3.68-6.94Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Path className="cls-18" d="M174.63,21.23a19.47,19.47,0,0,1,3.8,3.94,10.14,10.14,0,0,0-3.06,3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Path className="cls-18" d="M193.47,21.8a17.87,17.87,0,0,0-3.18,3.41,10.17,10.17,0,0,1,3.63,4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Line className="cls-18" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" x1="183.79" x2="185.29" y1="33.7" y2="33.7" />
        <Path className="cls-18" d="M182,34.67a1.27,1.27,0,0,0,1.3,1.1c1.19,0,1.23-2,1.23-2" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Path className="cls-18" d="M187,34.67a1.26,1.26,0,0,1-1.3,1.1c-1.18,0-1.22-2-1.22-2" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Circle cx="179.56" cy="31.5" fill="#000" r="0.62" />
        <Circle cx="189.22" cy="31.3" fill="#000" r="0.62" />
        <Path className="cls-18" d="M164.74,44.7a2.93,2.93,0,0,0,1.85,2.91c1.81.75,4.57.33,7.11-2a1.53,1.53,0,0,1,2.09,0h0a1.55,1.55,0,0,1,0,2.18l-.72.74c-2.24,1.82-3.91,2.68-6.29,2.62-3.87-.08-8.23-2.11-6.49-8.41,2-7.09,8.61-9.39,11-10" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Path className="cls-18" d="M172.15,50.83s6.15.06,11,.06c1.17,0,2.12,0,2.88,0a3.08,3.08,0,0,0,3-3.09h0a3.07,3.07,0,0,0-3.08-3.08,33.65,33.65,0,0,1-4.76-.24" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
        <Path className="cls-18" d="M191.38,37.25a25.92,25.92,0,0,1,.63,5.09" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" />
      </G>
    </Svg>
  );
}
