import { moderateScale } from '../../../utils/scaleHelpers';

export const calendarTheme = ({
  colors: {
    buttonGray,
    primaryCarbon,
  },
}) => ({
  monthTextColor: primaryCarbon,
  'stylesheet.calendar.header': {
    week: {
      borderBottomColor: buttonGray,
      borderBottomWidth: 1,
      borderStyle: 'solid',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: moderateScale(7),
      marginTop: moderateScale(7),
    },
  },
});

export const DAY_NAMES_SHORT = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
