import React from 'react';
import T from 'prop-types';

import {
  ADD_MOBILE_NUMBER_LABEL,
  ADD_MOBILE_NUMBER_TEXT,
  NOTIFICATIONS_TEXT,
} from '@dmi/shared/redux/Settings/Communications/constants';

import { ConditionalRender, LargeProgressButton } from '../../../../base_ui';
import Row from './Row';
import {
  ButtonContainer,
  CheckboxAlignmentContainer,
  CheckboxAlignmentSubcontainer,
  CheckboxLabel,
  CheckboxLabelRow,
  Container,
  StyledInternalLink,
  StyledP2,
  StyledPrimaryDivider,
  StyledText,
  TextContainer,
} from './styledComponents';

const Form = ({
  dispatchChangeAlertForm,
  dispatchPutAlertSettings,
  formProps: {
    alertSettings,
    isButtonDisabled,
    isLoanEmailAllowed,
    isLoanSmsAllowed,
  },
  hasMobileNumberOnFile,
  navigation,
  putAlertsLoading,
}) => (
  <Container>
    <StyledP2>{NOTIFICATIONS_TEXT}</StyledP2>
    <ConditionalRender
      Component={(
        <TextContainer>
          <StyledText>{ADD_MOBILE_NUMBER_TEXT}&nbsp;</StyledText>
          <StyledInternalLink
            isDisclosureLink // Used for inline styling
            label={ADD_MOBILE_NUMBER_LABEL}
            onPressFunc={() => navigation.navigate('EditMobileNumber')}
          />
        </TextContainer>
      )}
      shouldRender={!hasMobileNumberOnFile}
    />
    <CheckboxLabelRow>
      <CheckboxAlignmentContainer>
        <CheckboxAlignmentSubcontainer $isDisabled={!isLoanSmsAllowed}>
          <CheckboxLabel>Text</CheckboxLabel>
        </CheckboxAlignmentSubcontainer>
        <CheckboxAlignmentSubcontainer $isDisabled={!isLoanEmailAllowed}>
          <CheckboxLabel>Email</CheckboxLabel>
        </CheckboxAlignmentSubcontainer>
      </CheckboxAlignmentContainer>
    </CheckboxLabelRow>
    <StyledPrimaryDivider />
    {alertSettings.map((rowProps) => {
      if (rowProps.alertId !== 9) {
        return (
          <Row
            key={rowProps.alertId}
            dispatchChangeAlertForm={dispatchChangeAlertForm}
            isLoanEmailAllowed={isLoanEmailAllowed}
            isLoanSmsAllowed={isLoanSmsAllowed}
            {...rowProps}
          />
        );
      }
      return null;
    })}
    <ButtonContainer>
      <LargeProgressButton
        disabled={isButtonDisabled}
        label="Update"
        loading={putAlertsLoading}
        onPress={dispatchPutAlertSettings}
      />
    </ButtonContainer>
  </Container>
);

Form.propTypes = {
  dispatchChangeAlertForm: T.func.isRequired,
  dispatchPutAlertSettings: T.func.isRequired,
  formProps: T.shape({
    alertSettings: T.arrayOf(T.object).isRequired,
    isButtonDisabled: T.bool.isRequired,
    isLoanEmailAllowed: T.bool.isRequired,
    isLoanSmsAllowed: T.bool.isRequired,
  }).isRequired,
  hasMobileNumberOnFile: T.bool.isRequired,
  navigation: T.object.isRequired,
  putAlertsLoading: T.bool.isRequired,
};

export default Form;
