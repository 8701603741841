import styled from 'styled-components/native';

import { P3 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  justify-content: center;
  margin: ${moderateScale(10)}px 0 ${moderateScale(24)}px;
`;

export const BannerImage = styled.Image`
  height: ${moderateScale(200)}px;
  width: ${moderateScale(200)}px;
`;

export const ContactInfoContainer = styled.View`
  background-color: ${({ theme: { colors: { footerBackground } } }) => footerBackground};
  padding: 0;
  width: 100%;
`;

export const ContentContainer = styled.View`
  align-items: center;
  background-color: ${({
    $isFooterLink,
    theme: { colors: { dmiWhite, footerBackground } },
  }) => $isFooterLink ? footerBackground : dmiWhite};
  flex-direction: row;
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  justify-content: center;
  padding: ${({ $shouldAddExtraPadding }) => ($shouldAddExtraPadding ?
    `${moderateScale(16)}px 0 ${moderateScale(8)}px;` :
    `${moderateScale(10)}px 0 0;`
  )};
`;

export const StyledP3 = styled(P3)`
  color: ${({
    $isFooterLink,
    theme: { colors: { footerText, primaryCarbon } },
  }) => $isFooterLink ? footerText : primaryCarbon};
`;
