import React from 'react';

import { EMAIL_ERROR_BANNER_TEXT } from '@dmi/shared/redux/Payments/Autopay/constants';

import iconDictionary from '../../../../../utils/iconDictionary';
import { Container, IconContainer, Text } from './styledComponents';

const WarningIcon = iconDictionary('paymentWarningPurple');

const EmailErrorBanner = () => (
  <Container>
    <IconContainer>{WarningIcon}</IconContainer>
    <Text>{EMAIL_ERROR_BANNER_TEXT}</Text>
  </Container>
);

export default EmailErrorBanner;
