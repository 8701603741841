/* eslint-disable object-curly-newline */
/* eslint-disable sort-keys */
import { Platform } from 'react-native';
import * as Linking from 'expo-linking';
import Constants from 'expo-constants';
import { getStateFromPath } from '@react-navigation/native';
import nonHomeDrawerScreens from './PostAuthStack/nonHomeDrawerScreens';

const getHomeScreens = (isMurabahaAccount, isWeb) => ({
  BaseUI: 'testing',
  Calculators: 'calculators',
  Documents: 'documents',
  Help: 'help',
  InactiveRedirect: 'inactiveRedirect',
  [isMurabahaAccount && !isWeb ? 'Murabaha' : 'Mortgage']: 'mortgage',
  Payments: 'payments',
  Settings: 'settings',
});

/**
 * Typically on web, directly navigating to a url/path not included in our LinkingConfiguration
 * object will lead to a 404 page. For some urls/paths that we leave out of the
 * LinkingConfiguration though, we want to skip the 404 screen and automatically redirect somewhere
 * (typically either to the login page or the payments page depending on if user is logged in).
 *  - Typical example is if someone was on a nested screen and and then clicks the browser
 *    refresh button- it would be weird if they then see a 404 screen since they were just on
 *    that page and saw that it actually does exist. But we also don't want to (or can't)
 *    successfully reload that page though because it requires certain data to be loaded that isn't
 *    ready yet. Ie, the user is only meant to be able to navigate to it via internal links. So
 *    instead we just want to redirect them to the pre-auth or post-auth dashboard, as appropriate.
 */
const unLinkedPaths = [
  'ChangeEmail',
  'ContactUs',
  'Mfa',
  'Prelogin',
  'Preregistration',
  'userlogin',
  ...Object.keys(nonHomeDrawerScreens).reduce((arr, name) => {
    arr.push(name);
    return arr;
  }, []),
];

const getPlatformSpecificHomeScreens = (isMurabahaAccount) => Platform.OS === 'web' ?
  getHomeScreens(isMurabahaAccount, Platform.OS === 'web') :
  { TabNavigator: { screens: getHomeScreens(isMurabahaAccount, Platform.OS === 'web') } };

const PREFIX_WITH_APP_SCHEME = Linking.createURL('/');
const devPrefixes = PREFIX_WITH_APP_SCHEME.startsWith('exp') ? [PREFIX_WITH_APP_SCHEME] : [];

const PREFIXES_DICTIONARY = {
  dev: devPrefixes,
  prod: [
    'http://yourmortgageonline.com',
    'http://*.yourmortgageonline.com',
    'https://yourmortgageonline.com/',
    'https://*.yourmortgageonline.com/',
  ],
  qa: [
    'http://silversite-test.dmidev.cc/',
    'https://silversite-test.dmidev.cc/',
    'http://*.silversite-test.dmidev.cc/',
    'https://*.silversite-test.dmidev.cc/',
  ],
  uat: [
    'http://yourmortgageonline.net/',
    'https://yourmortgageonline.net/',
    'http://*.yourmortgageonline.net/',
    'https://*.yourmortgageonline.net/',
  ],
  uat2: [
    'http://silver3-uat-2.dmidev.cc/',
    'https://silver3-uat-2.dmidev.cc/',
    'http://*.silver3-uat-2.dmidev.cc/',
    'https://*.silver3-uat-2.dmidev.cc/',
  ],
};

const LinkingConfiguration = ({
  isGoldMigrationPLGeneralLogin,
  isGoldMigrationPLPreLogin,
  isGoldMigrationPLProfileTransfer,
  isLoggedIn,
  isMurabahaAccount,
}) => ({
  config: {
    screens: {
      PreAuthNavigator: {
        initialRouteName: 'Login',
        screens: {
          ForgotPassword: 'forgotpassword/:view?',
          ForgotUsername: 'forgotusername/:view?',
          Login: 'login',
          LoginSslError: 'login/ssl',
          LoginSso: 'login/sso',
          OauthCallback: 'oauthcallback',
          Prelogin: 'prelogin',
          Preregistration: 'preregistration',
          ProfileTransfer: 'profiletransfer',
          Reconsent: 'login/consent',
          Register: 'register/:view?',
          SsoLoggedOut: 'loggedout/sso',
          Totp: 'login/totp',
        },
      },
      PostAuthNavigator: {
        screens: {
          HomeDrawerNavigator: { screens: { ...getPlatformSpecificHomeScreens(isMurabahaAccount) } },
        },
      },
      AccountDeletion: 'accountdeletion',
      NotFound: '*',
    },
  },
  getStateFromPath(path, config) {
    const pathArray = path.replace(/^\//g, '').split('/');
    const currentPath = pathArray.length > 1 && pathArray[0].toLowerCase() === 'account'
      ? pathArray[1].toLowerCase()
      : pathArray[0];
    let newPath = path;
    if (unLinkedPaths.includes(currentPath)) {
      newPath = '/login';
      if (isLoggedIn) {
        newPath = '/payments';
      } else if (isGoldMigrationPLPreLogin) {
        newPath = '/preregistration';
      } else if (isGoldMigrationPLProfileTransfer) {
        newPath = '/profiletransfer';
      } else if (isGoldMigrationPLGeneralLogin) {
        newPath = '/prelogin';
      }
    }
    return getStateFromPath(newPath, config);
  },
  prefixes: PREFIXES_DICTIONARY[Constants.expoConfig.extra.ENV] || [],
});

export default LinkingConfiguration;
