import React from 'react';
import T from 'prop-types';

import ErrorMessage from '../../ErrorMessage';
import { Wrapper } from './styledComponents';

const ConfirmationErrorScene = ({
  customerServicePhoneNumber,
  label,
  onPress,
  text,
}) => (
  <Wrapper>
    <ErrorMessage
      body={`For assistance, contact ${customerServicePhoneNumber}.`}
      link={{
        label,
        onPress,
      }}
      title={text}
    />
  </Wrapper>
);

ConfirmationErrorScene.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  label: T.string.isRequired,
  onPress: T.func.isRequired,
  text: T.string.isRequired,
};

export default ConfirmationErrorScene;
