/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HelpLighthouse() {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} width={moderateScale(85)} viewBox="0 0 85 85" xmlns="http://www.w3.org/2000/svg">
      <Circle className="cls-1" cx="42.04" cy="41.68" r="39.94" fill="#f9f7fc" />
      <Circle className="cls-2" cx="42.04" cy="41.68" r="28.53" fill={svgHighlight} />
      <Polygon className="cls-3" points="31 58.94 54.28 58.94 56.31 85 29.18 85 31 58.94" fill="#fff" />
      <Polygon className="cls-3" points="25.89 51.24 25.89 52.94 30.97 56.27 54.43 56.55 59.11 53.33 59.02 51.24 25.89 51.24" fill="#fff" />
      <Polygon className="cls-3" points="31.18 27.71 54.38 27.54 54.38 29.01 51.82 30.72 33.1 30.72 31.18 29.13 31.18 27.71" fill="#fff" />
      <Polygon className="cls-4" points="26.47 52.94 58.86 52.94 53.98 56.02 31.47 56.02 26.47 52.94" fill="#e6ebef" />
      <Rect className="cls-5" x="33.64" y="43.76" width="18.05" height="7.05" fill="#bac2ca" />
      <Polygon className="cls-5" points="30.96 27.71 54.63 27.71 42.66 21.56 30.96 27.71" fill="#bac2ca" />
      <Rect className="cls-4" x="33.43" y="30.75" width="18.1" height="12.69" fill="#e6ebef" />
      <Polygon className="cls-6" points="45.86 43.52 40.7 44.02 46.42 30.73 51.58 30.23 45.86 43.52" fill="#fff" opacity="0.8" />
      <Polygon className="cls-6" points="33.35 38.39 33.27 35.43 33.39 30.73 37.07 30.23 33.35 38.39" fill="#fff" opacity="0.8" />
      <Rect className="cls-5" x="33.39" y="30.97" width="18.09" height="1.02" fill="#bac2ca" />
      <Rect className="cls-5" x="31.14" y="56.44" width="23.29" height="2.5" fill="#bac2ca" />
      <Polyline className="cls-7" points="27.8 50.66 27.8 43.78 57.07 43.78 57.07 50.57" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="33.31" y1="50.62" x2="33.31" y2="30.9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="51.75" y1="50.62" x2="51.75" y2="30.9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Polygon className="cls-3" points="56.46 85 55.12 68.54 30.24 68.54 29.33 85 56.46 85" fill="#fff" />
      <Polyline className="cls-8" points="33.1 30.72 31.02 29.3 31.02 27.38 42.73 21.17 54.48 27.55 54.48 29.13 51.82 30.72" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.92" />
      <Line className="cls-7" x1="32.75" y1="68.99" x2="54.88" y2="68.99" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="29.31" y1="47.35" x2="56.94" y2="47.35" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="38.9" y1="44" x2="38.9" y2="50.66" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="45.6" y1="45.39" x2="45.6" y2="50.66" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="45.56" y1="56.27" x2="54.28" y2="56.27" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="31.18" y1="56.27" x2="43.19" y2="56.27" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="33.89" y1="30.64" x2="51.28" y2="30.64" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="36.58" y1="27.54" x2="54.38" y2="27.54" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="39.33" y1="32.02" x2="39.33" y2="42.39" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Line className="cls-7" x1="45.58" y1="30.7" x2="45.58" y2="41.12" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.65" />
      <Polyline className="cls-9" points="56.31 85 54.32 56.24 59.11 53.33 59.11 51.07 25.89 51.07 25.89 53.41 31.02 56.41 29.24 85" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.03" />
    </Svg>
  );
}
