import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import { changeInput } from '@dmi/shared/redux/Payments/Autopay/actions';
import {
  selectAutopayFlow,
  selectEditAutopayReviewData,
  selectFormattedBeginDate,
  selectPaymentAmount,
  selectSetupAutopayReviewData,
  selectSummaryScheduledDateString,
} from '@dmi/shared/redux/Payments/Autopay/selectors';
import {
  selectCurrentPaymentAlertSetting,
  selectReceivesPaymentEmails,
} from '@dmi/shared/redux/Settings/Communications/selectors';

import ReviewScreen from '../../../components/Payments/Review';

const Review = ({
  beginDate,
  currentPaymentAlertSetting: { isEmailEnabled },
  customerServicePhoneNumber,
  dispatchChangeInput,
  editReviewData,
  flow,
  navigation,
  paymentAmount,
  receivesPaymentEmails,
  setupReviewData,
  summaryScheduledDateString,
}) => {
  useEffect(() => {
    dispatchChangeInput({
      field: 'receivesPaymentEmails',
      form: 'alerts',
      value: isEmailEnabled,
    });
  }, [dispatchChangeInput, isEmailEnabled]);

  const changeReceivesPaymentEmails = () => dispatchChangeInput({
    field: 'receivesPaymentEmails',
    form: 'alerts',
    value: !receivesPaymentEmails,
  });

  const REVIEW_DATA = flow === 'change' ? editReviewData : setupReviewData;

  return (
    <ReviewScreen
      customerServicePhoneNumber={customerServicePhoneNumber}
      flow={flow}
      isEmailConfirmation={receivesPaymentEmails}
      navigation={navigation}
      paragraphDate={beginDate}
      reviewData={REVIEW_DATA}
      summaryProps={{
        label: 'Next',
        onPress: () => navigation.navigate('AutopayDisclosure'),
        subText: summaryScheduledDateString,
        text: paymentAmount,
      }}
      toggleIsEmailConfirmation={changeReceivesPaymentEmails}
      variant="autopay"
    />
  );
};

Review.propTypes = {
  beginDate: T.string.isRequired,
  currentPaymentAlertSetting: T.object.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeInput: T.func.isRequired,
  editReviewData: T.array.isRequired,
  flow: T.string.isRequired,
  navigation: T.object.isRequired,
  paymentAmount: T.string.isRequired,
  receivesPaymentEmails: T.oneOf([true, false, null]),
  setupReviewData: T.array.isRequired,
  summaryScheduledDateString: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  beginDate: selectFormattedBeginDate(),
  editReviewData: selectEditAutopayReviewData(),
  flow: selectAutopayFlow(),
  paymentAmount: selectPaymentAmount(),
  setupReviewData: selectSetupAutopayReviewData(),
  summaryScheduledDateString: selectSummaryScheduledDateString(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  /**
   * Store: Settings
   */
  // eslint-disable-next-line sort-keys
  currentPaymentAlertSetting: selectCurrentPaymentAlertSetting(),
  receivesPaymentEmails: selectReceivesPaymentEmails(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Review);
