import { Animated, Platform } from 'react-native';

import {
  BOLD_TIMING,
  INITIAL_TRANSLATE_X,
  SCALE_FACTOR_GROWN,
  SCALE_FACTOR_SHRUNK,
  TRANSITION_DURATION,
  TRANSLATE_Y_GROWN,
  TRANSLATE_Y_SHRUNK,
} from '../constants';

/**
 * Shrink is meant to be called when an Input gains focus, triggering its label
 * to "shrink" from its placeholder size/position to be smaller and positioned in
 * the top left corner.
 *
 * Shrink utilizes ReactNative's Animated API:
 *  - scaleAnim, translateXAnim, and translateYAnim are objects made via Animated.Value,
 * one of their properties being the relevant number value. Elsewhere, these are plugged
 * into the transform style for the label, which renders with their associated number value
 * in any given moment.
 *  - Here, each Animated.Value is plugged into a Animated.timing method, which defines
 * a timed easing curve along which the Animated.Value will change/animate once triggered. All three
 * Animated.timing methods are grouped into an Animated.parallel as an array. Animated.parallel is
 * then able to activate all three animations at the same time via calling its .start() method. Per
 * the API documentation, the Animated.Value's are not updated via some 'setState' to trigger a
 * rerender, but are optimized under the hood to work in some other way.
 *
 * A label's fontWeight is supposed to be bold when in the grown/placeholder position, but 'regular'
 * when in the shrunk/upper position. This cannot be animated, so it is handled in styling based
 * on the labelIsShrunk state, which we update here.
 */
export const shrink = ({
  labelTranslateX,
  scaleAnim,
  setLabelIsShrunk,
  translateXAnim,
  translateYAnim,
  useNativeDriver,
}) => {
  Animated.parallel([
    Animated.timing(scaleAnim, {
      duration: TRANSITION_DURATION,
      toValue: SCALE_FACTOR_SHRUNK,
      useNativeDriver,
    }),
    Animated.timing(translateYAnim, {
      duration: TRANSITION_DURATION,
      toValue: TRANSLATE_Y_SHRUNK,
      useNativeDriver,
    }),
    Animated.timing(translateXAnim, {
      duration: TRANSITION_DURATION,
      toValue: labelTranslateX,
      useNativeDriver,
    }),
  ]).start();

  /*
    Unable to animate fontFamily/weight, but can change it mid-animation with setTimeout.
    This looks great in web/iOS, but Android (at least the emulator) has trouble running
    the setTimeout callback in time, particularly for such a short animation. So for Android we
    can use fallback where the fontFamily is changed immediately after the animation begins.
  */
  if (Platform.OS !== 'android') {
    setTimeout(() => setLabelIsShrunk(true), BOLD_TIMING);
  } else {
    setLabelIsShrunk(true);
  }
};

/**
 * Grow is meant to be called when an Input loses focus, triggering its label
 * to "grow" from its "shrunk" size/position in the upper left corner to be larger
 * and positioned as if it were a placeholder
 *
 * See the documentation for the 'shrink' function above for discussion of Animated API
 */
export const grow = ({
  scaleAnim,
  setLabelIsShrunk,
  translateXAnim,
  translateYAnim,
  useNativeDriver,
}) => {
  Animated.parallel([
    Animated.timing(scaleAnim, {
      duration: TRANSITION_DURATION,
      toValue: SCALE_FACTOR_GROWN,
      useNativeDriver,
    }),
    Animated.timing(translateYAnim, {
      duration: TRANSITION_DURATION,
      toValue: TRANSLATE_Y_GROWN,
      useNativeDriver,
    }),
    Animated.timing(translateXAnim, {
      duration: TRANSITION_DURATION,
      toValue: INITIAL_TRANSLATE_X,
      useNativeDriver,
    }),
  ]).start();

  if (Platform.OS !== 'android') {
    setTimeout(() => setLabelIsShrunk(false), BOLD_TIMING);
  } else {
    setLabelIsShrunk(false);
  }
};

/**
 * This function generates a 'handleLabelLayout' function, which is meant to be placed
 * on the LabelDimensionsPlaceholder as its 'onLayout' prop value. 'handleLabelLayout' is a piece of
 * the logic used to make a proxy `transformOrigin: 'left'`, (since ReactNative doesn't let us
 * change the default `transformOrigin: 'center'`). It grabs the width of the label after
 * first render (and theoretically if its width ever changes again somehow), and uses it to
 * calculate the necessary translateX value to let our animation mimic having `transformOrigin:
 * 'left'`
 */
export const handleLabelLayoutGenerator = ({
  labelIsShrunk,
  setLabelTranslateX,
  translateXAnim,
}) => (e) => {
  const { width } = e.nativeEvent.layout;
  const translateX = -((width - (width * SCALE_FACTOR_SHRUNK)) / 2);

  if (labelIsShrunk) {
    translateXAnim.setValue(translateX);
  }
  setLabelTranslateX(translateX);
};
