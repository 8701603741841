/* eslint-disable max-len */
import React from 'react';
import T from 'prop-types';

import { UNENROLL_PAPERLESS_MODAL_DICTIONARY } from '@dmi/shared/redux/Settings/Communications/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import {
  BackButton,
  Container,
  Modal,
  StyledProgressButton,
  Text,
  Title,
} from './styledComponents';

const Icon = iconDictionary('registerRobot');

const PaperlessModal = ({
  handleCloseModal,
  handleOnPress,
  isModalOpen,
  loading,
}) => {
  const { text, title } = UNENROLL_PAPERLESS_MODAL_DICTIONARY;

  return (
    <Modal handleCloseModal={handleCloseModal} visible={isModalOpen}>
      <Container>
        {Icon}
        <Title>{title}</Title>
        <Text>{text}</Text>
        <StyledProgressButton
          label="Yes, Unenroll"
          loading={loading}
          onPress={handleOnPress}
        />
        <BackButton label="Go Back" onPress={handleCloseModal} />
      </Container>
    </Modal>
  );
};

PaperlessModal.propTypes = {
  handleCloseModal: T.func.isRequired,
  handleOnPress: T.func.isRequired,
  isModalOpen: T.bool.isRequired,
  loading: T.bool.isRequired,
};

export default PaperlessModal;
