/* eslint-disable max-len */
import React from 'react';

import iconDictionary from '../../../utils/iconDictionary';
import {
  AdditionalCopy,
  Container,
  IllustrationWrapper,
  Title,
} from './styledComponents';

const Illustration = iconDictionary('errorPorchlight');

const SsoLoggedOutView = () => (
  <Container>
    <IllustrationWrapper>{Illustration}</IllustrationWrapper>
    <Title>
      You have successfully ended your session
    </Title>
    <AdditionalCopy>{"For your security, please ensure you've closed all browser windows and tabs. This helps protect your personal and financial information."}</AdditionalCopy>
  </Container>
);

export default SsoLoggedOutView;
