import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';
import { injectIntl } from 'react-intl';

import { amortizeStatus } from '@dmi/shared/redux/Calculators/Amortize/actions';
import {
  makeSelectAmortize,
  selectNotifications,
  selectServiceErrorMessage,
} from '@dmi/shared/redux/Calculators/Amortize/selectors';
import reducer from '@dmi/shared/redux/Calculators/Amortize/reducer';
import saga from '@dmi/shared/redux/Calculators/Amortize/saga';
import makeSelectMain from '@dmi/shared/redux/Main/selectors';
import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { selectNestedScreen } from '@dmi/shared/redux/Mobile/selectors';

import { StyledScrollView } from '../../components/base_ui';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../utils/globalHelpers';
import { navigate } from '../../utils/rootNavigation';
import AsyncRender from '../../components/AsyncRender';
import HomeView from '../../components/Calculators/Home';
import ErrorView from '../../components/Calculators/Error';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';

const HomeScreen = ({
  dispatchAmortizeStatus,
  dispatchSetNestedScreen,
  loanNumber,
  navigation,
  nestedScreen,
  notifications,
  serviceErrorMessage,
  status: { fetchStatus },
}) => {
  useFocusEffect(
    useCallback(() => {
      dispatchAmortizeStatus({ loanNumber });
    }, [dispatchAmortizeStatus, loanNumber]),
  );

  useEffect(() => {
    if (nestedScreen && (fetchStatus === 'succeeded')) {
      navigation.navigate(nestedScreen);
    }
  }, [navigation, nestedScreen, fetchStatus]);

  useFocusEffect(
    useCallback(() => () => {
      dispatchSetNestedScreen({ mainScreen: 'calculators', nestedScreen: '' });
    }, [dispatchSetNestedScreen]),
  );

  return (
    <StyledScrollView>
      <ScreenReaderNotifications notifications={notifications} />
      <AsyncRender
        Component={HomeView}
        CustomErrorComponent={ErrorView}
        error={fetchStatus === 'failed'}
        errorComponentProps={{
          messageProps: { title: serviceErrorMessage },
          navigation,
        }}
        loading={fetchStatus === 'loading'}
        propsToPassDown={{ navigation }}
      />
    </StyledScrollView>
  );
};

HomeScreen.propTypes = {
  dispatchAmortizeStatus: T.func.isRequired,
  dispatchSetNestedScreen: T.func.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  nestedScreen: T.string,
  notifications: T.array.isRequired,
  serviceErrorMessage: T.string.isRequired,
  status: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Amortize
   */
  notifications: selectNotifications(),
  serviceErrorMessage: selectServiceErrorMessage('fetchStatus'),
  status: makeSelectAmortize('status'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  loanNumber: makeSelectMain('selectedLoan'),
  /**
   * Store: Mobile
   */
  nestedScreen: selectNestedScreen('calculators'),

});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Amortize
   */
  dispatchAmortizeStatus: (payload) => dispatch(amortizeStatus(payload)),
  /**
   * Store: Mobile
   */
  dispatchSetNestedScreen: (payload) => dispatch(setNestedScreen(payload)),

});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'amortize', reducer });
const withSaga = injectSaga({
  key: 'amortize',
  saga: saga({
    baseUrl: getBaseApplicationServerUrl(),
    navigationHandler: navigate,
  }),
});

export default compose(
  injectIntl,
  withConnect,
  withReducer,
  withSaga,
)(HomeScreen);
