import styled from 'styled-components/native';
import { Animated } from 'react-native';

import { TOGGLE_CONTAINER_WIDTH, TOGGLE_SWITCH_SIDE_LENGTH } from './constants';

export const ToggleActiveBackgroundView = styled(Animated.View)`
  background-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: 100px;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ToggleContainer = styled.View`
  background-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-radius: 100px;
  border-width: 1px;
  height: ${TOGGLE_SWITCH_SIDE_LENGTH}px;
  position: relative;
  width: ${TOGGLE_CONTAINER_WIDTH}px;
`;

export const ToggleSwitch = styled(Animated.View)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-radius: 100px;
  border-width: 1px;
  height: ${TOGGLE_SWITCH_SIDE_LENGTH}px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: ${TOGGLE_SWITCH_SIDE_LENGTH}px;
`;

export const ToggleWrapper = styled.Pressable`
  align-items: center;
  flex-direction: row;
  justify-content: center;
  min-height: 44px;
  min-width: 44px;
`;
