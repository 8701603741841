import React, { useCallback, useEffect, useState } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useNavigation } from '@react-navigation/native';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';

import { updateSelectedDate } from '@dmi/shared/redux/Payoff/actions';
import { INTL_IDS } from '@dmi/shared/redux/Payoff/messages';
import makeSelectPayoff, { selectAvailableDates } from '@dmi/shared/redux/Payoff/selectors';

import {
  Calendar,
  H2,
  P3,
  P4,
  StyledScrollView,
} from '../base_ui';
import { SecondaryHeader } from '../ScreenHeaders';
import {
  BottomContainer,
  BottomContainerText,
  CalendarContainer,
  CircleContainer,
  ClosedCircle,
  StyledPrimaryButton,
} from './styledComponents';

const SelectDateScreen = ({
  availableDates,
  dispatchUpdateSelectedDate,
  selectedDate,
}) => {
  const formattedSelectedDate = selectedDate ? moment(selectedDate).format('YYYY-MM-DD') : '';
  const [date, setDate] = useState(formattedSelectedDate);
  const [isCurrentMonth, setIsCurrentMonth] = useState(false);
  const [isFinalMonth, setIsFinalMonth] = useState(false);
  const navigation = useNavigation();
  const { formatMessage } = useIntl();

  const finalMonth = moment(Object.keys(availableDates).pop()).month() + 1;
  const currentMonth = moment().month() + 1;
  const currentYear = moment().year();
  const [initialDate] = Object.keys(availableDates);

  const handleChangeMonth = useCallback(({ month }) => {
    setIsCurrentMonth(moment(currentMonth, 'M').isSame(moment(month, 'M'), 'month'));
    setIsFinalMonth(moment(finalMonth, 'M').isSame(moment(month, 'M'), 'month'));
  }, [currentMonth, finalMonth]);

  useEffect(() => {
    handleChangeMonth({
      month: currentMonth,
      year: currentYear,
    });
  }, [currentMonth, currentYear, handleChangeMonth]);

  const handlePress = ({ dateString }) => {
    setDate(dateString);
  };

  const handleSubmit = () => {
    dispatchUpdateSelectedDate(`${date || initialDate}T00:00:00`);
    navigation.goBack();
  };

  return (
    <StyledScrollView>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={formatMessage({ id: INTL_IDS.SELECTDATE_HEADER })}
      />
      <CalendarContainer>
        <Calendar
          availableDates={availableDates}
          disabledByDefault
          handleChangeMonth={handleChangeMonth}
          handlePress={handlePress}
          isCurrentMonth={isCurrentMonth}
          isFinalMonth={isFinalMonth}
          selected={date || initialDate}
        />
        <CircleContainer>
          <ClosedCircle />
          <P3>
            <FormattedMessage id={INTL_IDS.SELECTDATE_LEGEND} />
          </P3>
        </CircleContainer>
      </CalendarContainer>
      <BottomContainer>
        <BottomContainerText>
          <H2>{moment(date || initialDate).format('MMM D, YYYY')}</H2>
          <P4>
            <FormattedMessage id={INTL_IDS.SELECTDATE_FOOTER} />
          </P4>
        </BottomContainerText>
        <StyledPrimaryButton label="Select Date" onPress={handleSubmit} />
      </BottomContainer>
    </StyledScrollView>
  );
};

SelectDateScreen.propTypes = {
  availableDates: T.object.isRequired,
  dispatchUpdateSelectedDate: T.func.isRequired,
  selectedDate: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Payoff
   */
  availableDates: selectAvailableDates(),
  selectedDate: makeSelectPayoff('selectedDate'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Payoff
   */
  dispatchUpdateSelectedDate: (date) => dispatch(updateSelectedDate(date)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SelectDateScreen);
