import Confirmation from '../../../components/Register/Confirmation';
import {
  EmailFormInput,
  LoanNumberFormInput,
  RecaptchaFormInput,
  RegisterResendEmail,
  SsnTinFormInput,
  UsernamePasswordFormInput,
  ZipCodeFormInput,
} from '../../../components/Register/FormInputs';
import SharedForm from '../../../components/Register/SharedForm';
import {
  CONFIRMATION_CARD_PROPS,
  REGISTER_EMAIL_CARD_PROPS,
  REGISTER_LOAN_CARD_PROPS,
  REGISTER_RECAPTCHA_CARD_PROPS,
  REGISTER_RESEND_EMAIL_PROPS,
  REGISTER_SSNTIN_CARD_PROPS,
  REGISTER_USERNAME_PASSWORD_CARD_PROPS,
  REGISTER_ZIPCODE_CARD_PROPS,
  VERIFY_CONFIRMATION_CARD_PROPS,
} from './constants';
import ConsentView from './ConsentView';

export const GUIDED_VIEW_DICTIONARY = {
  1: {
    viewCardProps: REGISTER_USERNAME_PASSWORD_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: UsernamePasswordFormInput,
  },
  2: {
    viewCardProps: VERIFY_CONFIRMATION_CARD_PROPS,
    ViewComponent: Confirmation,
    ViewFormInput: {},
  },
};

export const REGISTER_VIEW_DICTIONARY = {
  1: {
    viewCardProps: REGISTER_LOAN_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: LoanNumberFormInput,
  },
  2: {
    viewCardProps: REGISTER_SSNTIN_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: SsnTinFormInput,
  },
  3: {
    viewCardProps: REGISTER_ZIPCODE_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: ZipCodeFormInput,
  },
  4: {
    viewCardProps: REGISTER_RECAPTCHA_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: RecaptchaFormInput,
  },
  5: { ViewComponent: ConsentView },
  6: {
    viewCardProps: REGISTER_USERNAME_PASSWORD_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: UsernamePasswordFormInput,
  },
  7: {
    viewCardProps: REGISTER_EMAIL_CARD_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: EmailFormInput,
  },
  8: {
    viewCardProps: CONFIRMATION_CARD_PROPS,
    ViewComponent: Confirmation,
  },
  9: {
    viewCardProps: REGISTER_RESEND_EMAIL_PROPS,
    ViewComponent: SharedForm,
    ViewFormInput: RegisterResendEmail,
  },
};
