import {
  registerGuidedStepFour,
  registerGuidedStepThree,
  registerResendEmail,
  registerStepOne,
  registerStepTwo,
  registerVerify,
  verifyRecaptcha,
} from '@dmi/shared/redux/Register/Main/actions';
import {
  CONFIRMATION_DICTIONARY,
  SHARED_REGISTER_EMAIL_CARD_PROPS,
  SHARED_REGISTER_LOAN_CARD_PROPS,
  SHARED_REGISTER_RECAPTCHA_CARD_PROPS,
  SHARED_REGISTER_RESEND_EMAIL_CARD_PROPS,
  SHARED_REGISTER_SSNTIN_CARD_PROPS,
  SHARED_REGISTER_USERNAME_PASSWORD_CARD_PROPS,
  SHARED_REGISTER_ZIPCODE_CARD_PROPS,
} from '@dmi/shared/redux/Register/Main/constants';

export const GUIDED_ACTION_DICTIONARY = {
  1: registerGuidedStepThree,
  2: registerGuidedStepFour,
};

export const REGISTER_ACTION_DICTIONARY = {
  1: registerStepOne,
  2: verifyRecaptcha,
  3: registerStepTwo,
  4: registerVerify,
  5: registerResendEmail,
};

export const CONFIRMATION_CARD_PROPS = {
  cardAnimation: {
    mobile: 'airplaneMobile',
    web: 'airplaneDesktop',
  },
  cardDictionary: CONFIRMATION_DICTIONARY,
};

export const REGISTER_EMAIL_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerClipboard',
    web: 'desktopRegisterClipboard',
  },
  ...SHARED_REGISTER_EMAIL_CARD_PROPS,
};

export const REGISTER_LOAN_CARD_PROPS = {
  cardAnimation: {
    mobile: 'registerClipboardMobile',
    web: 'registerClipboardDesktop',
  },
  ...SHARED_REGISTER_LOAN_CARD_PROPS,
};

export const REGISTER_RECAPTCHA_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerRobot',
    web: 'registerRobot',
  },
  ...SHARED_REGISTER_RECAPTCHA_CARD_PROPS,
};

export const REGISTER_RESEND_EMAIL_PROPS = {
  cardIllustration: {
    mobile: 'registerRobot',
    web: 'registerRobot',
  },
  ...SHARED_REGISTER_RESEND_EMAIL_CARD_PROPS,
};

export const REGISTER_SSNTIN_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerClipboard',
    web: 'desktopRegisterClipboard',
  },
  ...SHARED_REGISTER_SSNTIN_CARD_PROPS,
};

export const REGISTER_USERNAME_PASSWORD_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerClipboard',
    web: 'desktopRegisterClipboard',
  },
  ...SHARED_REGISTER_USERNAME_PASSWORD_CARD_PROPS,
};

export const REGISTER_ZIPCODE_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerClipboard',
    web: 'desktopRegisterClipboard',
  },
  ...SHARED_REGISTER_ZIPCODE_CARD_PROPS,
};

export const VERIFY_CONFIRMATION_CARD_PROPS = {
  cardAnimation: {
    mobile: 'confirmationClipboardMobile',
    web: 'confirmationClipboardDesktop',
  },
  cardDictionary: CONFIRMATION_DICTIONARY,
};
