import React, { useRef } from 'react';
import T from 'prop-types';

import { CheckboxWithText } from '../../../../base_ui';
import {
  CheckboxLabel,
  FormInputsContainer,
  StyledBaseTextField,
  TypeContainer,
} from './styledComponents';

const FormInputs = ({
  disableInputs,
  dispatchSetInput,
  formErrors: {
    accountNumber: accountNumberError,
    accountNumberConfirm: accountNumberConfirmError,
    nameOnAccount: nameOnAccountError,
    routingNumber: routingNumberError,
  },
  formValues: {
    accountNumber,
    accountNumberConfirm,
    accountType,
    nameOnAccount,
    routingNumber,
  },
  handleValidatePress,
  isValidateButtonDisabled,
}) => {
  const accountNumberRef = useRef();
  const confirmAccountNumberRef = useRef();
  const accountHoldersNameRef = useRef();
  return (
    <FormInputsContainer
      $disabled={disableInputs}
      pointerEvents={disableInputs ? 'none' : 'auto'}
    >
      <TypeContainer>
        <CheckboxLabel>Account Type</CheckboxLabel>
        <CheckboxWithText
          checked={accountType === 'Checking'}
          isRound
          onPress={() => dispatchSetInput({ field: 'accountType', value: 'Checking' })}
          title="Checking"
        />
        <CheckboxWithText
          checked={accountType === 'Saving'}
          isRound
          onPress={() => dispatchSetInput({ field: 'accountType', value: 'Saving' })}
          title="Savings"
        />
      </TypeContainer>
      <StyledBaseTextField
        errorMessage={routingNumberError}
        label="Routing Number"
        onChangeText={(value) => (
          dispatchSetInput({ field: 'routingNumber', value })
        )}
        onSubmitEditing={() => accountNumberRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={routingNumber}
      />
      <StyledBaseTextField
        ref={accountNumberRef}
        errorMessage={accountNumberError}
        label="Account Number"
        onChangeText={(value) => dispatchSetInput({ field: 'accountNumber', value })}
        onSubmitEditing={() => confirmAccountNumberRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={accountNumber}
      />
      <StyledBaseTextField
        ref={confirmAccountNumberRef}
        errorMessage={accountNumberConfirmError}
        label="Confirm Account Number"
        onChangeText={(value) => (
          dispatchSetInput({ field: 'accountNumberConfirm', value })
        )}
        onSubmitEditing={() => accountHoldersNameRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={accountNumberConfirm}
      />
      <StyledBaseTextField
        ref={accountHoldersNameRef}
        errorMessage={nameOnAccountError}
        label="Account Holder's Name"
        onChangeText={(value) => (
          dispatchSetInput({ field: 'nameOnAccount', value })
        )}
        onSubmitEditing={isValidateButtonDisabled ? undefined : handleValidatePress}
        placeholder="Required"
        returnKeyType="done"
        value={nameOnAccount}
      />
    </FormInputsContainer>
  );
};

FormInputs.propTypes = {
  disableInputs: T.bool.isRequired,
  dispatchSetInput: T.func.isRequired,
  formErrors: T.shape({
    accountNumber: T.string,
    accountNumberConfirm: T.string,
    nameOnAccount: T.string,
    routingNumber: T.string,
  }).isRequired,
  formValues: T.shape({
    accountNumber: T.string,
    accountNumberConfirm: T.string,
    accountType: T.oneOf(['Checking', 'Saving']),
    nameOnAccount: T.string,
    routingNumber: T.string,
  }).isRequired,
  handleValidatePress: T.func.isRequired,
  isValidateButtonDisabled: T.bool.isRequired,
};

export default FormInputs;
