import React, { Fragment } from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';
import { useIntl } from 'react-intl';

import { MANCODE_Y_TEXT } from '@dmi/shared/redux/Payoff/constants';
import { INTL_IDS } from '@dmi/shared/redux/Documents/messages';

import {
  ConditionalRender,
  P3,
  SecondaryDivider,
} from '../../base_ui';
import Card from './Card';
import {
  BoldText,
  StyledFormattedMessageStatusBanner,
  StyledH1,
  StyledPhoneLinkText,
} from './styledComponents';

const Home = ({
  homeLinkProps,
  homeLoadingStep,
  isLoanMancodeY,
  navigation,
}) => {
  const { formatMessage } = useIntl();

  const { payoff, statements } = homeLinkProps || {};
  const InfoStatements = [formatMessage({ id: INTL_IDS.HOME_CARD_STATEMENTS_BODYTEXT })];
  const InfoPayoff = [formatMessage({ id: INTL_IDS.HOME_CARD_PAYOFF_BODYTEXT })];
  const InfoForms = [formatMessage({ id: INTL_IDS.HOME_CARD_FORMSANDRESOURCES_BODYTEXT })];

  return (
    <Fragment>
      <ConditionalRender
        Component={StyledFormattedMessageStatusBanner}
        propsToPassDown={{
          defaultMessage: MANCODE_Y_TEXT,
          error: true,
          values: {
            // eslint-disable-next-line react/prop-types
            a: ({ msg }) => (
              <StyledPhoneLinkText
                // eslint-disable-next-line react/prop-types
                onPress={() => Linking.openURL(`tel://${msg.replace(/\+|-/g, '')}`)}
              >
                {msg}
              </StyledPhoneLinkText>
            ),
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <BoldText>{msg}</BoldText>,
            mobileNewline: '\n',
          },
          WrapperComponent: P3,
        }}
        shouldRender={isLoanMancodeY}
      />
      <StyledH1>Documents</StyledH1>
      <Card
        heading={formatMessage({ id: INTL_IDS.HOME_CARD_STATEMENTS_HEADING })}
        linkProps={statements}
        listItems={InfoStatements}
        loadingStep={homeLoadingStep}
        navigation={navigation}
      />
      <SecondaryDivider />
      <Card
        heading={formatMessage({ id: INTL_IDS.HOME_CARD_PAYOFF_HEADING })}
        linkProps={payoff}
        listItems={InfoPayoff}
        loadingStep={homeLoadingStep}
        navigation={navigation}
      />
      <SecondaryDivider />
      <Card
        heading={formatMessage({ id: INTL_IDS.HOME_CARD_FORMSANDRESOURCES_HEADING })}
        linkProps={{
          ariaLabel: formatMessage({ id: INTL_IDS.HOME_CARD_FORMSANDRESOURCES_ARIALABEL }),
          label: formatMessage(
            { id: INTL_IDS.HOME_CARD_FORMSANDRESOURCES_NONEMPTYLABEL },
            { numberOfItems: 4 },
          ),
          mobilePath: 'GeneralDocs',
          showLink: true,
        }}
        listItems={InfoForms}
        loadingStep={homeLoadingStep}
        navigation={navigation}
      />
      <SecondaryDivider />
    </Fragment>
  );
};

Home.propTypes = {
  homeLinkProps: T.oneOfType([T.bool, T.shape({
    payoff: T.object,
    statements: T.object,
  })]).isRequired,
  homeLoadingStep: T.number.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default Home;
