/*
 * Edit Legal Name Screen
 */

import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import makeSelectProfile from '@dmi/shared/redux/Settings/Profile/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { EditLegalNameComponent } from '../../../components/Settings/EditLegalName';
import { StyledView } from './styledComponents';

const EditLegalNameScreen = ({
  legalName,
  navigation,
}) => {
  const { header } = PROFILE_CONTENT_DICTIONARY.editLegalName.editLegalNameView;

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={navigation.goBack}
        title={header}
      />
      <StyledView>
        <EditLegalNameComponent
          legalName={legalName}
          navigation={navigation}
        />
      </StyledView>
    </Fragment>
  );
};

EditLegalNameScreen.propTypes = {
  legalName: T.string.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  legalName: makeSelectProfile('legalName'),
});

const withConnect = connect(mapStateToProps);

export default withConnect(EditLegalNameScreen);
