/**
* BaseProgressButton
* @description:  Button with a progress bar
* Use Case: Shows a visual progression continuously cycling from 0-100% during the async loading
*           loading state. On async error/failure, it shows the button label again. On success, it
*           shows a checkmark icon. Default success behavior is to not show the label again. Can
*           pass enableResubmitAfterSuccess prop to show the label again after a certain amout of
*           time has passed (determined by disabledTimeAfterSuccess prop). Button is effectively
*           disabled during the progress bar animation and checkmark display periods (ie, when its
*           label is not displayed).
* Component Props: {
*   ariaLabel (string) Used as aria-label. If not provided, the 'label' will be used instead.
*   disabledTimeAfterSuccess (number) If enableResubmitAfterSuccess is true, then, during the
*     'succeeded' state, this is the amount of time (ms) the checkmark will be displayed/the button
*     will be disabled until reverting back to showing the label/enabling the button.
*     [Default: 2000]
*   enableResubmitAfterSuccess (bool) Whether the button should revert back to its label display
*     and be resubmittable after a success (ie, while the status is 'succeeded') [Default: false]
*   error (bool|string) Error state of async request.
*   label (string) The button text to display when not animating
*   loading (bool) Loading state of async request
*   onPress (func) Invoked when button is clicked when not 'loading', or when in a 'success' state
*     but enableResubmitAfterSucces is true and the disabledTimeAfterSuccess has passed. Gets
*     overriden with a noop during other states.
*   status (enum: 'idle'|'loading'|'succeeded'|'failed') Async status of request. (Optional prop:
*     Use this in case the parent/redux is using this format instead of separately storing loading,
*     error, and success states. Ie, either this prop should be present, or the entire combo of
*     loading/error/success, but not both.)
*   success (bool) Whether async request succeeded. NOTE: If using loading & error props instead
*     of status, you must also include this success prop.
*
*   Rest of props are passed to MUI's ButtonBase.
* }
*/

import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';
import { Animated, Pressable } from 'react-native';

import { usePrevious } from '../../../../utils/customHooks';
import iconDictionary from '../../../../utils/iconDictionary';
import ConditionalRender from '../../ConditionalRender';
import progressSequence from './progressSequence';
import { selectAsyncStatus } from './helpers';
import {
  ButtonLabel,
  IconWrapper,
  LabelText,
  ProgressIndicatorBackground,
  ProgressIndicatorForeground,
} from './styledComponents';

const checkmarkIcon = iconDictionary('progressCheckmark', { height: '100%' });

const BaseProgressButton = ({
  ariaLabel,
  disabledTimeAfterSuccess = 2000,
  enableResubmitAfterSuccess = false,
  error,
  label,
  loading,
  onPress,
  pressedStyle,
  pressedTitleStyle,
  status,
  style,
  success,
  titleStyle,
  ...restProps
}) => {
  const [isInEnabledStateAfterSuccess, setIsInEnabledStateAfterSuccess] = useState(false);
  const [progressBarWidth, setProgressBarWidth] = useState(0);
  const progressValue = useRef(new Animated.Value(0)).current;
  const indicatorWidth = progressValue.interpolate({
    inputRange: [0, 100],
    outputRange: [0, progressBarWidth],
  });
  const asyncStatus = status || selectAsyncStatus({ error, loading, success });
  const previousAsyncStatus = usePrevious(asyncStatus);
  const isDisabledDueToCurrentStatus =
    asyncStatus === 'loading' ||
    (asyncStatus === 'succeeded' && !isInEnabledStateAfterSuccess);
  const realOnPress = isDisabledDueToCurrentStatus ? () => {} : onPress;

  useEffect(() => {
    if (asyncStatus === 'loading') {
      progressSequence(progressValue).start();
    }
    if (enableResubmitAfterSuccess) {
      if (asyncStatus !== 'succeeded') {
        // mainly relevant for after a previous success, and now is submitting again
        setIsInEnabledStateAfterSuccess(false);
      } else if (asyncStatus === 'succeeded' && asyncStatus !== previousAsyncStatus) {
        setTimeout(() => setIsInEnabledStateAfterSuccess(true), disabledTimeAfterSuccess);
      }
    }
  }, [
    asyncStatus,
    disabledTimeAfterSuccess,
    enableResubmitAfterSuccess,
    previousAsyncStatus,
    progressValue,
  ]);

  return (
    <Pressable
      aria-label={ariaLabel || label}
      onPress={realOnPress}
      progressOpacity={1}
      role="button"
      style={({ pressed }) => ([
        ...style,
        pressed ? pressedStyle : {},
        { justifyContent: 'center' }])}
      {...restProps}
    >
      {({ pressed }) => (
        <ButtonLabel>
          <ConditionalRender
            Component={(
              <LabelText
                style={[
                  ...titleStyle,
                  pressed ? pressedTitleStyle : {},
                ]}
              >
                {label}
              </LabelText>
            )}
            shouldRender={
              asyncStatus === 'idle' ||
                asyncStatus === 'failed' ||
                isInEnabledStateAfterSuccess
            }
          />
          <ConditionalRender
            Component={(
              <ProgressIndicatorBackground
                onLayout={(event) => setProgressBarWidth(event.nativeEvent.layout.width)}
              >
                <ProgressIndicatorForeground
                  style={{ transform: [{ translateX: indicatorWidth }] }}
                />
              </ProgressIndicatorBackground>
            )}
            shouldRender={asyncStatus === 'loading'}
          />
          <ConditionalRender
            Component={(
              <IconWrapper>{checkmarkIcon}</IconWrapper>
            )}
            shouldRender={asyncStatus === 'succeeded' && !isInEnabledStateAfterSuccess}
          />
        </ButtonLabel>
      )}
    </Pressable>
  );
};

BaseProgressButton.propTypes = {
  ariaLabel: T.string,
  disabledTimeAfterSuccess: T.number,
  enableResubmitAfterSuccess: T.bool,
  error: T.oneOfType([T.bool, T.string]),
  label: T.string.isRequired,
  loading: T.bool,
  onPress: T.func.isRequired,
  pressedStyle: T.object,
  pressedTitleStyle: T.object,
  status: T.oneOf(['idle', 'loading', 'succeeded', 'failed']),
  style: T.array,
  success: T.oneOfType([T.bool, T.string]),
  titleStyle: T.array,
};

export default BaseProgressButton;
