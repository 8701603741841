import styled from 'styled-components/native';

import { PrimaryDivider, StatusBanner } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const EditPaymentContent = styled.View`
  border-bottom-width: ${moderateScale(1)}px;
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-style: solid;
  border-top-width: ${moderateScale(1)}px;
  margin-top: ${moderateScale(24)}px;
  min-height: auto;
  padding: ${moderateScale(16)}px 0 ${moderateScale(40)}px;
`;

export const EditPaymentContentWrapper = styled.View`
  flex-grow: 1;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin:
    ${moderateScale(8)}px ${moderateScale(22)}px ${moderateScale(22)}px ${moderateScale(25)}px;
  max-width: 100%;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin: ${moderateScale(10)}px ${moderateScale(24)}px;
`;
