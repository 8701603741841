/* eslint-disable max-len */
import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { DEFAULT_CLIENT_THEME_COLORS } from '../../../../../shared/redux/Main/constants';

import { moderateScale } from '../../scaleHelpers';

export default function ErrorStorm(props) {
  const { colors } = useTheme();
  const { svgHighlight } = colors || DEFAULT_CLIENT_THEME_COLORS;

  return (
    <Svg fill="none" height={moderateScale(120)} viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="white" height="120" width="287" />
      <Path d="M232.301 87.9C232.301 98.1 230.501 107.9 227.101 117H58.5008C55.2008 107.9 53.3008 98.1 53.3008 87.9C53.3008 39.9 93.4008 1 142.801 1C192.201 1 232.301 39.9 232.301 87.9Z" fill="#BAC2CA" />
      <Path d="M107.602 111.5H94.6016V117.1H107.602V111.5Z" fill="#E6EBEF" />
      <Path d="M126.502 43.6016H121.602V50.8016H126.502V43.6016Z" fill="#E6EBEF" />
      <Path d="M126.902 42.1016V43.4016L126.602 43.5016V59.4016L129.502 56.2016V43.5016C129.502 43.5016 129.902 43.2016 129.902 43.1016C129.902 43.0016 129.802 42.3016 129.802 42.3016L129.602 42.1016H126.902Z" fill="#BAC2CA" />
      <Path d="M121.5 43.5V59.4H126.5L129.5 56.3V43.6" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M126.5 55.3V43.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3881" />
      <Path d="M121.498 43.4016H129.198C129.598 43.4016 129.798 43.1016 129.798 42.8016V42.7016C129.798 42.3016 129.498 42.1016 129.198 42.1016H121.498C121.098 42.1016 120.898 42.4016 120.898 42.7016V42.8016C120.898 43.2016 121.098 43.4016 121.498 43.4016Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M126.801 42.1016V43.3016" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3881" />
      <Path d="M163.602 72.6992V117.199L185.302 117.099V72.9992L163.602 72.6992Z" fill="#E6EBEF" />
      <Path d="M186.699 74H163.199V117.1H186.699V74Z" fill="#BAC2CA" />
      <Path d="M99.1016 72.8984V117.098H163.502V73.1984L99.1016 72.8984Z" fill="#E6EBEF" />
      <Path d="M93.1016 70.6992V117.099H95.2016V70.9992L93.1016 70.6992Z" fill="#E6EBEF" />
      <Path d="M185.299 72.6992H93.1992V74.9992H185.299V72.6992Z" fill="#BAC2CA" />
      <Path d="M88.8984 70.6992L106.798 51.1992H172.598L189.998 71.0992H125.198L88.8984 70.6992Z" fill="#BAC2CA" />
      <Path d="M177.401 51.1992H163.801" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.6119" />
      <Path d="M127.898 71.1016H160.398" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.6104" />
      <Path d="M117.299 51.1992H116.199" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M161.098 51.1992H118.898" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M93.1016 72.8008V117.001" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M163.5 73V116.9" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M188.5 70.1016V116.902" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.601" />
      <Path d="M110.602 111.198V82.3984H123.802V111.198" fill={svgHighlight} />
      <Path d="M110.602 111.198V82.3984H123.802V111.198" stroke="black" strokeMiterlimit="10" strokeWidth="0.7762" />
      <Path d="M116.998 89.7C117.33 89.7 117.598 89.4314 117.598 89.1C117.598 88.7686 117.33 88.5 116.998 88.5C116.667 88.5 116.398 88.7686 116.398 89.1C116.398 89.4314 116.667 89.7 116.998 89.7Z" fill="white" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3881" />
      <Path d="M138.801 73V116.7" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5791" />
      <Path d="M154.102 82.3984H145.902V92.9984H154.102V82.3984Z" fill="#E6EBEF" />
      <Path d="M145.199 90.6984L149.399 82.3984H145.599L145.199 83.1984V90.6984Z" fill="white" opacity="0.7" />
      <Path d="M150.502 92.9984H148.102L153.602 82.3984H156.002L150.502 92.9984Z" fill="white" opacity="0.7" />
      <Path d="M154.299 82.3984H145.699V93.0984H154.299V82.3984Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.6329" />
      <Path d="M150 87.0977V91.4977" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4219" />
      <Path d="M150 83.6992V86.0992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4219" />
      <Path d="M147.602 87.8984H154.302" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4219" />
      <Path d="M156.201 80.6992H143.801V94.9992H156.201V80.6992Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4219" />
      <Path d="M163.199 111.5H93.1992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M163.5 72.8H89.1C88.8 72.8 88.5 72.5 88.5 72V71H98.5" fill="white" />
      <Path d="M163.5 72.8H89.1C88.8 72.8 88.5 72.5 88.5 72V71H98.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5125" />
      <Path d="M163.5 71.1992V72.5992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M95.1992 73.1016V111.502" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5799" />
      <Path d="M99.1016 73.1992V111.499" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5789" />
      <Path d="M136.398 73.1016V111.502" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5797" />
      <Path d="M114.502 51.1992H106.802L88.6016 71.0992H122.902" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M125.802 111.202V80.6016H108.602V111.302" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3881" />
      <Path d="M120.6 74.9008H113.4C113.2 74.9008 113.2 74.6008 113.3 74.4008L114.8 72.8008H119.2L120.7 74.4008C120.8 74.6008 120.8 74.9008 120.6 74.9008Z" fill="#BAC2CA" stroke="black" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M120.6 75H117V73H119.2L120.7 74.6C120.8 74.7 120.8 75 120.6 75Z" fill="#E6EBEF" />
      <Path d="M55.7 117H53" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.589" />
      <Path d="M227.099 117H58.6992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.589" />
      <Path d="M170.002 117.803C170.002 116.103 168.702 114.703 167.202 114.703C166.002 114.703 165.002 115.503 164.702 116.703C164.302 116.103 163.602 115.603 162.902 115.603C161.702 115.603 160.802 116.603 160.602 117.803" fill="white" />
      <Path d="M170.002 117.102C170.002 115.702 168.702 114.602 167.202 114.602C166.002 114.602 165.002 115.302 164.702 116.202C164.302 115.702 163.602 115.302 162.902 115.302C161.702 115.302 160.802 116.102 160.602 117.102" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M172.198 57.6992L176.698 51.5992C176.898 51.3992 177.098 51.1992 177.398 51.1992C177.698 51.1992 177.898 51.3992 178.098 51.5992L190.898 70.2992C191.198 70.7992 191.198 71.4992 190.798 71.9992C190.398 72.3992 189.798 72.3992 189.498 71.8992L177.398 54.1992L164.098 72.3992C163.898 72.6992 163.698 72.7992 163.398 72.7992C163.198 72.7992 162.898 72.6992 162.798 72.4992C162.398 72.0992 162.398 71.2992 162.698 70.7992L170.898 59.5992" fill="#DCE2E7" />
      <Path d="M172.398 57.4992L176.698 51.5992C176.898 51.3992 177.098 51.1992 177.398 51.1992C177.698 51.1992 177.898 51.3992 178.098 51.5992L190.898 70.2992C191.198 70.7992 191.198 71.4992 190.798 71.9992C190.398 72.3992 189.798 72.3992 189.498 71.8992L177.398 54.1992L164.098 72.3992C163.898 72.6992 163.698 72.7992 163.398 72.7992C163.198 72.7992 162.898 72.6992 162.798 72.4992C162.398 72.0992 162.398 71.2992 162.698 70.7992L170.498 60.0992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4202" />
      <Path d="M186.701 63.3984L168.801 85.0984" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M139.8 86.1992L122 107.799" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M217.999 62L200.199 83.6" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M210.799 88.6992L197.699 104.499" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M215.299 83.3008L213.199 85.8008" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M164.299 107.801L162.199 110.301" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M143.998 81.3008L141.898 83.9008" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M181.799 86.6016L166.699 104.802" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M142.801 102.5L129.301 118.8" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.9106" />
      <Path d="M231.899 117.199H229.199" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5822" />
      <Path d="M104.901 50.3984C106.801 50.3984 108.201 48.3984 107.401 46.5984C105.901 42.7984 102.601 37.2984 96.4006 36.6984C93.8006 36.3984 91.4006 37.4984 89.5006 38.6984C89.3006 38.7984 89.1006 38.7984 89.0006 38.6984C87.7006 37.4984 85.8006 36.6984 83.7006 36.6984C79.7006 36.6984 76.5006 39.3984 76.5006 42.7984C76.5006 43.0984 76.3006 43.2984 76.0006 43.1984C75.8006 43.1984 75.6006 43.1984 75.4006 43.1984C73.0006 43.1984 70.9006 44.4984 70.1006 46.3984C70.0006 46.5984 69.7006 46.6984 69.5006 46.5984C68.9006 46.2984 68.1006 46.0984 67.3006 46.1984C65.5006 46.1984 64.4006 47.1984 63.6006 48.2984C63.0006 49.1984 63.6006 50.4984 64.7006 50.4984L104.901 50.3984Z" fill="#E6EBEF" opacity="0.4" />
      <Path d="M209.102 36.4008C210.702 36.4008 211.602 34.5008 210.602 33.2008C208.802 31.1008 205.202 29.7008 201.002 29.7008C200.302 29.7008 199.702 29.7008 199.002 29.8008C198.302 29.9008 197.702 29.3008 197.702 28.6008C197.702 25.0008 193.102 22.0008 187.502 22.0008C185.902 22.0008 184.402 22.2008 183.102 22.6008C182.502 22.8008 181.802 22.4008 181.702 21.8008C180.502 16.4008 174.802 12.3008 167.802 12.3008C160.002 12.3008 153.702 17.5008 153.702 23.8008C153.702 24.0008 153.702 24.2008 153.702 24.5008C153.402 24.5008 153.002 24.4008 152.702 24.4008C149.802 24.4008 147.302 26.3008 146.502 28.9008C146.302 29.5008 145.702 29.8008 145.102 29.7008C144.502 29.5008 143.902 29.5008 143.302 29.5008C141.202 29.5008 139.402 30.5008 138.202 32.0008C136.802 33.8008 138.102 36.4008 140.302 36.4008H209.102Z" fill="#E6EBEF" opacity="0.4" />
      <Path d="M135.597 25.6992L117.797 47.3992" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M88.7984 48.5L70.8984 70.1" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M166.998 24.3008L149.098 45.9008" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M159.699 51L146.699 66.8" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M164.198 45.6016L162.098 48.1016" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M113.198 70.1016L111.098 72.6016" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M92.8969 43.6016L90.7969 46.1016" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M130.699 48.9023L115.699 67.1023" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
      <Path d="M91.6984 64.6992L73.8984 86.3992" stroke="white" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="1.0486" />
    </Svg>
  );
}
