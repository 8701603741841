import styled from 'styled-components/native';

import { H2, H5, P2 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ButtonContainer = styled.View`
  align-items: center;
  margin-top: ${moderateScale(25)}px;
  padding-bottom: ${moderateScale(35)}px;
  width: 100%;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${({ $isUpload }) => $isUpload ? 0 : moderateScale(52)}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const List = styled.View`
  display: flex;
  flex-direction: row;
  padding-right: ${moderateScale(20)}px;
  padding-top: ${moderateScale(2)}px;
`;

export const ListBullet = styled(P2)`
  padding-left: ${moderateScale(12)}px;
  padding-right: ${moderateScale(5)}px;
`;

export const StyledH2 = styled(H2)`
  margin-bottom: ${({ $isConfirmation }) => $isConfirmation ? 0 : moderateScale(9)}px;
  margin-top: ${({ $isConfirmation }) => $isConfirmation ? moderateScale(16) : 0}px;
  text-align: center;
`;

export const StyledH5 = styled(H5)`
  padding: ${moderateScale(13)}px 0;
`;

export const StyledView = styled.View`
  margin-bottom: ${moderateScale(20)}px;
`;
