/* eslint-disable object-curly-newline */
import React, { useCallback } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';

import { resetCancelConfirmation } from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import { CANCEL_OTP_DICTIONARY } from '@dmi/shared/redux/Payments/OneTimePayment/constants';
import {
  selectOtpCancelConfirmation,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import CancelConfirmation from '../../../components/Payments/CancelConfirmation';

const CancelConfirmationScreen = ({
  confirmationData,
  dispatchResetCancelConfirmation,
  navigation,
}) => {
  useFocusEffect(
    useCallback(() => () => {
      setTimeout(() => dispatchResetCancelConfirmation(), 400);
    }, [dispatchResetCancelConfirmation]),
  );

  return (
    <CancelConfirmation
      confirmationDictionary={CANCEL_OTP_DICTIONARY}
      confirmationType="successMobile"
      navigation={navigation}
      title="One-Time Payment Cancellation"
      {...confirmationData}
    />
  );
};

CancelConfirmationScreen.propTypes = {
  confirmationData: T.oneOfType([
    T.bool,
    T.shape({
      amount: T.string.isRequired,
      confirmationNumber: T.string.isRequired,
      date: T.string.isRequired,
    }),
  ]).isRequired,
  dispatchResetCancelConfirmation: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: One Time Payment
   */
  confirmationData: selectOtpCancelConfirmation(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: One Time Payment
   */
  dispatchResetCancelConfirmation: () => dispatch(resetCancelConfirmation()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CancelConfirmationScreen);
