import React, { Fragment, useMemo } from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import { useIntl } from 'react-intl';

import { DROPDOWN_TOPICS } from '@dmi/shared/redux/SecureMessaging/constants';

import { ConditionalRender, H5 } from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  IconWrapper,
  PressableRow,
  StyledScrollView,
} from './styledComponents';

const CheckedIcon = iconDictionary('approved');
const UncheckedIcon = iconDictionary('unapproved');

const SelectComponent = ({
  handleOptionPress,
  selectedOption,
  setViewToRender,
}) => {
  const { formatMessage } = useIntl();

  const dropdownTopicsArray = useMemo(() => Object.entries(DROPDOWN_TOPICS.topic)
    .map(([value, display]) => ({
      display: formatMessage({ id: display }),
      value,
    }))
    .sort(
      (a, b) => a.display.localeCompare(b.display),
    ), [formatMessage]);

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => setViewToRender('Messaging')}
        title="Select Topic"
      />
      <StyledScrollView showsVerticalScrollIndicator={false}>
        <View>
          {dropdownTopicsArray.map(({ display, value }) => (
            <PressableRow
              key={value}
              aria-label={`Select ${display} as the Topic for message`}
              onPress={() => handleOptionPress(value)}
              role="button"
            >
              <IconWrapper>
                <ConditionalRender
                  Component={CheckedIcon}
                  FallbackComponent={UncheckedIcon}
                  shouldRender={selectedOption === value}
                />
              </IconWrapper>
              <H5>{display}</H5>
            </PressableRow>
          ))}
        </View>
      </StyledScrollView>
    </Fragment>
  );
};

SelectComponent.propTypes = {
  handleOptionPress: T.func.isRequired,
  selectedOption: T.string.isRequired,
  setViewToRender: T.func.isRequired,
};

export default SelectComponent;
