import styled from 'styled-components/native';

import { LargeProgressButton, MultilineTextField, StyledScrollView } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';
import BaseInput from '../../base_ui/Input/BaseInput';

export const ButtonWrapper = styled.View`
  align-items: center;
  width: 100%;
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const MessageCreateContainer = styled(StyledScrollView)
  .attrs({ contentContainerStyle: { justifyContent: 'space-around' } })`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledBodyField = styled(MultilineTextField).attrs((
  { theme: { colors: { primaryCarbon } } },
) => ({ placeholderTextColor: primaryCarbon }))`
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  height: ${(moderateScale(207))}px;
  margin: 0;
  min-height: 0;
  min-width: 0;
  padding: ${(moderateScale(12))}px ${(moderateScale(24))}px ${(moderateScale(8))}px;
`;

export const StyledLargeProgressButton = styled(LargeProgressButton)`
  margin: ${(moderateScale(35))}px ${(moderateScale(57))}px;
`;

export const StyledSubjectField = styled(BaseInput).attrs((
  { theme: { colors: { primaryCarbon }, fontSizes: { f4 } } },
) => ({
  errorStyle: { height: 0, margin: 0 },
  inputContainerStyle: { borderBottomWidth: 0, borderWidth: 0 },
  inputFontSize: `${f4}px`,
  placeholderTextColor: primaryCarbon,
}))`
  border-style: solid;
  border-top-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-top-width: ${(moderateScale(1))}px;
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  font-weight: 700;
  height: ${(moderateScale(36))}px;
  padding: ${(moderateScale(9))}px ${(moderateScale(24))}px;
  `;
