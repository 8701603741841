import { // eslint-disable-line object-curly-newline
  convertScheduledPaymentsToCSVString,
} from '@dmi/shared/redux/Calculators/Amortize/helpers';

import { saveCSVToFile } from '../../utils/deviceHelpers';

export const downloadScheduleCSV = (schedule, formatMessage) => {
  const csvString = convertScheduledPaymentsToCSVString(schedule, formatMessage);
  saveCSVToFile({ csvString, fileName: 'AmortizationSchedule' });
};
