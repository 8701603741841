import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

const VisibilityOff = (props) => (
  <Svg height={moderateScale(24)} viewBox="0 0 26 24" width={moderateScale(24)} {...props}>
    <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <G transform="translate(-1.000000, -1.500000)">
        <Path d="M0,0 L28,0 L28,28 L0,28 L0,0 Z M0,0 L28,0 L28,28 L0,28 L0,0 Z M0,0 L28,0 L28,28 L0,28 L0,0 Z M0,0 L28,0 L28,28 L0,28 L0,0 Z" />
        <Path d="M13.82,10.5 L17.5,14.18 L17.5,14 C17.5,12.0670034 15.9329966,10.5 14,10.5 L13.82,10.5 Z" fill="#282828" fillRule="nonzero" />
        <Path d="M14,8.14 C15.9389387,8.13209609 17.7544829,9.0903123 18.8420309,10.6955491 C19.9295788,12.3007859 20.1462913,14.3422106 19.42,16.14 L22.82,19.54 C24.6030809,18.0638422 25.9798703,16.1569889 26.82,14 C24.1309211,7.03052049 16.3722536,3.47964292 9.34,6 L11.86,8.52 C12.5431431,8.26007838 13.2691336,8.13116417 14,8.14 Z" fill="#282828" fillRule="nonzero" />
        <Path d="M24.5,24.2 L24.5,24.2 L3.8,3.48 L3.8,3.48 C3.3963432,3.12126742 2.7830334,3.13932158 2.40117749,3.52117749 C2.01932158,3.9030334 2.00126742,4.5163432 2.36,4.92 L2.36,4.92 L2.36,4.92 L2.36,4.92 L4.98,7.62 L5.52,8.16 C3.56532141,9.67659882 2.05851236,11.6948935 1.16,14 C3.24315412,19.2704156 8.33283266,22.7348853 14,22.74 C15.7537989,22.7455315 17.4922078,22.4127892 19.12,21.76 L19.6,22.24 L23.02,25.66 L23.02,25.66 C23.2676537,25.976321 23.6753462,26.121718 24.0672744,26.0334936 C24.4592027,25.9452693 24.7652693,25.6392027 24.8534936,25.2472744 C24.941718,24.8553462 24.796321,24.4476537 24.48,24.2 L24.5,24.2 Z M14,19.82 C11.9823285,19.8323365 10.1032358,18.7951994 9.03825695,17.0814403 C7.97327807,15.3676812 7.87547197,13.2236018 8.78,11.42 L10.6,13.22 C10.5359496,13.4683367 10.5023693,13.7235473 10.5,13.98 C10.5,14.9082577 10.8687489,15.7984964 11.5251263,16.4548737 C12.1815036,17.1112511 13.0717423,17.48 14,17.48 C14.2524108,17.5105751 14.5075892,17.5105751 14.76,17.48 L16.56,19.28 C15.758709,19.6519575 14.8832572,19.8366231 14,19.82 Z" fill="#282828" fillRule="nonzero" />
      </G>
    </G>
  </Svg>
);

export default VisibilityOff;
