import React, { forwardRef, useImperativeHandle } from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import ConditionalRender from '../ConditionalRender';
import { DAY_NAMES_SHORT } from './constants';
import {
  ArrowContainer,
  DaysContainer,
  HeaderContainer,
  MonthContainer,
  MonthText,
  NavigationButton,
  WeekDayText,
} from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';

const arrowLeftM = iconDictionary('arrowLeftM');
const arrowRightM = iconDictionary('arrowRightM');

const Header = forwardRef(({
  addMonth: propsAddMonth,
  disableArrowLeft,
  disableArrowRight,
  month,
}, ref) => {
  const dateParts = month.toDateString().split(' ');
  const displayDate = `${dateParts[1]} ${dateParts[3]}`;

  useImperativeHandle(ref, () => ({
    onPressLeft,
    onPressRight,
  }));

  const addMonth = () => propsAddMonth(1);
  const subtractMonth = () => propsAddMonth(-1);
  const onPressLeft = () => subtractMonth();
  const onPressRight = () => addMonth();

  return (
    <HeaderContainer>
      <MonthContainer>
        <ConditionalRender
          Component={(
            <NavigationButton
              aria-label="previous month"
              onPress={onPressLeft}
              role="button"
            >
              <ArrowContainer>
                {arrowLeftM}
              </ArrowContainer>
            </NavigationButton>
          )}
          FallbackComponent={(<View></View>)}
          shouldRender={!disableArrowLeft}
        />
        <MonthText>
          {displayDate}
        </MonthText>
        <ConditionalRender
          Component={(
            <NavigationButton
              aria-label="next month"
              onPress={onPressRight}
              role="button"
            >
              <ArrowContainer>
                {arrowRightM}
              </ArrowContainer>
            </NavigationButton>
          )}
          FallbackComponent={(<View></View>)}
          shouldRender={!disableArrowRight}
        />
      </MonthContainer>
      <DaysContainer>
        {DAY_NAMES_SHORT.map(
          (day) => (
            <WeekDayText
              key={day}
              accessibilityLabel={day}
            >
              {day}
            </WeekDayText>
          ),
        )}
      </DaysContainer>
    </HeaderContainer>
  );
});

Header.propTypes = {
  addMonth: T.func,
  disableArrowLeft: T.bool,
  disableArrowRight: T.bool,
  month: T.object,
};

export default Header;
