export const getSpanStyleProps = (style = []) => style.reduce((acc, val) => {
  switch (val) {
    case 'BOLD':
      return { ...acc, $isBold: true };
    // italic doesn't work on native, we're only loading regular and bold Roboto variants
    case 'ITALIC':
      return { ...acc, $isItalic: true };
    case 'UNDERLINE':
      return { ...acc, $isUnderline: true };
    default:
      return acc;
  }
}, {});

// CMS was implemented to have external links being a full absolute url,
// whereas internal links are just the pathname and start with a leading slash.
export const isExternal = (link) => !(link[0] === '/');
