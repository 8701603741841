import React from 'react';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import { StyledAnimation } from '../styledComponents';
import {
  ConfirmationContent,
  ConfirmationWrapper,
  StyledH2,
} from './styledComponents';

const ConfirmationComponent = () => {
  const { subheader } = PROFILE_CONTENT_DICTIONARY.editMobileNumber.confirmationView.mobile;

  return (
    <ConfirmationWrapper>
      <StyledAnimation name="robotMobile" />
      <ConfirmationContent>
        <StyledH2>{subheader}</StyledH2>
      </ConfirmationContent>
    </ConfirmationWrapper>
  );
};

export default ConfirmationComponent;
