import React from 'react';
import T from 'prop-types';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  ConditionalRender,
  H4,
  LargePrimaryButton,
  Skeleton,
  StyledScrollView,
} from '../../base_ui';
import {
  ButtonWrapper,
  EditContainer,
  EditStyledP2,
  FlexView,
} from '../styledComponents';
import { IllustrationWrapper } from './styledComponents';

const MilitaryHouse = iconDictionary('militaryHouse', { height: `${moderateScale(210)}px` });

const EditMilitaryStatusComponent = ({
  militaryServiceStatus,
  navigation,
}) => {
  const { subheader, text } = PROFILE_CONTENT_DICTIONARY.editMilitaryStatus.editMilitaryStatusView;
  const militaryStatus = militaryServiceStatus === 'No_Value_Assigned' ?
    'Unknown' :
    militaryServiceStatus;

  return (
    <StyledScrollView>
      <EditContainer>
        <H4>Military Status on File</H4>
        <ConditionalRender
          Component={<EditStyledP2>{militaryStatus}</EditStyledP2>}
          FallbackComponent={(
            <Skeleton
              $bottomMargin={`${moderateScale(24)}px`}
              $rightMargin={`${moderateScale(24)}px`}
              $topMargin={`${moderateScale(8)}px`}
              height={`${moderateScale(22)}px`}
              width={`${moderateScale(150)}px`}
            />
          )}
          shouldRender={!!militaryStatus}
        />
        <H4>{subheader}</H4>
        <EditStyledP2>{text}</EditStyledP2>
        <FlexView />
        <IllustrationWrapper>
          {MilitaryHouse}
        </IllustrationWrapper>
        <ButtonWrapper>
          <LargePrimaryButton
            label="Next"
            onPress={() => navigation.navigate('MilitaryStatusUploadDocument')}
          />
        </ButtonWrapper>
      </EditContainer>
    </StyledScrollView>
  );
};

EditMilitaryStatusComponent.propTypes = {
  militaryServiceStatus: T.string.isRequired,
  navigation: T.object.isRequired,
};

export default EditMilitaryStatusComponent;
