/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../scaleHelpers';

const Padlock = (props) => (
  <Svg fill="black" height={moderateScale(120)} viewBox="0 0 327 120" width={moderateScale(327)} {...props}>
    <Rect fill="none" height="102.15" width="244.3" x="41.35" y="8.93" />
    <Path d="M132.67,105.23A46,46,0,0,0,183,89.47c4.38-5.67,13.66,2.58,20.36,5.14a27.74,27.74,0,0,0,4.44,1.28,27,27,0,0,0,31.06-19.1c4.1-14.63,1.68-40.63-21.1-43.58-6.35-.82-12.45-.82-17.84,1.68A16.1,16.1,0,0,1,181.06,31a46,46,0,0,0-79.54,21.34A46.1,46.1,0,0,0,132.67,105.23Z" fill="#eff3f6" />
    <Path d="M190.58,59.76h-7.81V44.08a11.73,11.73,0,0,0-11.71-11.71h-4.24a11.72,11.72,0,0,0-11.71,11.71V59.76H147.3V44.08a19.55,19.55,0,0,1,19.52-19.52h4.24a19.55,19.55,0,0,1,19.52,19.52Z" fill="#bac2ca" />
    <Rect fill="#bac2ca" height="47.85" rx="8.36" width="57.55" x="140.16" y="54.96" />
    <Path d="M186.33,44.5V57.11h-7.8V41.44a11.74,11.74,0,0,0-11.72-11.72h-4.23a11.74,11.74,0,0,0-11.72,11.72V57.11h-7.8V41.44a19.53,19.53,0,0,1,19.52-19.52h4.23a19.53,19.53,0,0,1,6.56,1.13,18.74,18.74,0,0,1,3.24,1.51,19.52,19.52,0,0,1,9.72,16.88" fill="#bac2ca" />
    <Path d="M186.65,57.43h-8.44v-16A11.42,11.42,0,0,0,166.81,30h-4.23a11.41,11.41,0,0,0-11.4,11.4v16h-8.44v-16A19.86,19.86,0,0,1,162.58,21.6h4.23a19.72,19.72,0,0,1,10,2.68,19.94,19.94,0,0,1,9.88,17.16.32.32,0,0,1-.32.32.33.33,0,0,1-.32-.32,19.26,19.26,0,0,0-9.56-16.6,18.14,18.14,0,0,0-3.19-1.49,19.1,19.1,0,0,0-6.45-1.11h-4.23a19.23,19.23,0,0,0-19.21,19.2V56.79h7.17V41.44a12,12,0,0,1,12-12h4.23a12,12,0,0,1,12,12V56.79H186V44.5a.33.33,0,0,1,.32-.32.32.32,0,0,1,.32.32Z" />
    <Path d="M135.92,65.5a.32.32,0,0,1-.32-.32V63.74a.32.32,0,0,1,.32-.32.32.32,0,0,1,.32.32v1.44A.32.32,0,0,1,135.92,65.5Z" />
    <Path d="M135.92,61.41V58.48A7.12,7.12,0,0,1,143,51.36h43.31a7.12,7.12,0,0,1,7.12,7.12V92.1a7.12,7.12,0,0,1-7.12,7.12H143a7.12,7.12,0,0,1-7.12-7.12V66.62" fill="#bac2ca" />
    <Path d="M186.35,99.54H143a7.45,7.45,0,0,1-7.44-7.44V66.62a.32.32,0,0,1,.32-.32.33.33,0,0,1,.32.32V92.1a6.81,6.81,0,0,0,6.8,6.8h43.31a6.81,6.81,0,0,0,6.8-6.8V58.48a6.81,6.81,0,0,0-6.8-6.8H143a6.81,6.81,0,0,0-6.8,6.8v2.93a.32.32,0,0,1-.32.32.32.32,0,0,1-.32-.32V58.48A7.45,7.45,0,0,1,143,51h43.31a7.45,7.45,0,0,1,7.44,7.44V92.1A7.45,7.45,0,0,1,186.35,99.54Z" />
    <Polygon fill="#fff" opacity="0.5" points="140.16 98.74 157.73 98.74 188.31 51.88 170.74 51.88 140.16 98.74" />
    <Path d="M170.56,69.6a5.87,5.87,0,1,0-8.76,5.1l-3,9.56h11.73l-3.39-9.35A5.86,5.86,0,0,0,170.56,69.6Z" fill="#fff" />
    <Path d="M171,84.58H158.4l3-9.74a6.19,6.19,0,1,1,6.14.23Zm-11.74-.64H170.1l-3.33-9.2.26-.12a5.54,5.54,0,1,0-5.07-.2l.23.13Z" />
  </Svg>
);

export default Padlock;
