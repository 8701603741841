/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function LoginFall(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      fill="none"
      height={moderateScale(120)}
      viewBox="0 0 287 120"
      width={moderateScale(287)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G>
        <Rect className="st0" fill="#FFFFFF" height="47.4" width="84.2" x="81.2" y="60.4" />
        <Path className="st1" d="M153.4,104c0,0,3.8,0.5,3.8,3.8l-3.2,0L153.4,104z" fill="#D8DFE5" />
        <Path className="st1" d="M109.6,104c0,0,3.8,0.5,3.8,3.8l-3.2,0L109.6,104z" fill="#D8DFE5" />
        <Path
          className="st2"
          d="M88.2,32.9c2.3,0,3.8-2.3,3-4.4C89.4,24,85.6,17.7,78.4,17c-3-0.3-5.9,0.9-8.1,2.4c-0.2,0.1-0.5,0.1-0.6-0.1
		c-1.5-1.4-3.7-2.3-6.2-2.3c-4.6,0-8.4,3.2-8.4,7.1l0,0c0,0.3-0.3,0.5-0.6,0.5c-0.2,0-0.5,0-0.7,0c-2.8,0-5.3,1.6-6.1,3.7
		c-0.1,0.3-0.4,0.4-0.7,0.3c-0.8-0.3-1.6-0.5-2.5-0.5c-2.1,0.1-3.4,1.2-4.3,2.4c-0.8,1.1,0,2.5,1.3,2.5L88.2,32.9z"
          fill="#D9E1E8"
        />
        <Path
          className="st2"
          d="M229.6,26.9c1.3,0,2-1.5,1.2-2.5c-1.4-1.7-4.3-2.8-7.6-2.8c-0.5,0-1.1,0-1.6,0.1c-0.6,0.1-1-0.4-1-1l0,0
		c0-2.9-3.7-5.2-8.2-5.2c-1.3,0-2.4,0.2-3.5,0.5c-0.5,0.2-1-0.1-1.1-0.7c-0.9-4.3-5.5-7.6-11.1-7.6c-6.2,0-11.3,4.1-11.3,9.2
		c0,0.2,0,0.4,0,0.5c-0.3,0-0.5-0.1-0.8-0.1c-2.3,0-4.3,1.5-5,3.6c-0.2,0.5-0.6,0.7-1.1,0.6c-0.5-0.1-0.9-0.2-1.4-0.2
		c-1.7,0-3.1,0.8-4.1,2c-1.1,1.4-0.1,3.5,1.7,3.5H229.6z"
          fill="#D9E1E8"
        />
        <Rect className="st3" fill="#E6EBEF" height="2.1" width="8.4" x="153.8" y="26.6" />
        <Path
          className="st0"
          d="M81.4,91.9c-0.8-0.1-1.6-0.2-2.4-0.2c-4.8,0-9.1,2.3-11.8,6c-0.5,0.7-1.5,1-2.3,0.7c-1-0.4-2.1-0.5-3.3-0.5
		c-3.2,0-6.1,1.5-7.9,3.8c0,0-2,2.7-2.2,6.2h30L81.4,91.9z"
          fill="#FFFFFF"
        />
        <Polyline className="st4" fill={svgHighlight} points="113.3,107.9 113.3,76.6 130.4,76.6 130.4,107.9 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Circle className="st5" cx="121.5" cy="84.5" fill="#FFFFFF" r="0.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st6" d="M161.5,66.4H81.1v-1.8l80.8-0.3l3-3.1l0.1,2.5l-2,2C162.6,66.2,162.1,66.4,161.5,66.4z" fill="#BAC2CA" />
        <Path
          className="st7"
          d="M54.7,100.8c0,0,3.1-2.5,8.1-1.4s5.6-0.1,7.1-1.9s7.3-4.4,11.7-3.1V92c0,0-5.1-1.1-9.4,1.4
		c-4.3,2.5-4.9,4.5-5.6,4.8c-0.7,0.3-0.9,0.4-3-0.2s-6.7,0.1-9.1,2.9"
          fill="#DCE2E7"
        />
        <Polygon className="st6" fill="#BAC2CA" points="75.9,60.9 99,35.7 183.8,35.7 160.2,61.4 122.8,61.4 	" />
        <Rect className="st0" fill="#FFFFFF" height="1.9" width="1.5" x="153" y="24.5" />
        <Rect className="st0" fill="#FFFFFF" height="15.4" width="8.5" x="153.9" y="24.6" />
        <Path className="st7" d="M162.7,24.5v2l-0.4,0.1v13.7l4.3-4.7v-9.2c0,0,0.6-0.5,0.6-0.7c0-0.2-0.2-1.1-0.2-1.1l-0.3-0.3L162.7,24.5z" fill="#DCE2E7" />
        <Path className="st7" d="M162.8,24.6v2l-0.4,0.1v13.7l4.3-4.7v-9.2c0,0,0.6-0.5,0.6-0.7c0-0.2-0.2-1.1-0.2-1.1l-0.3-0.3L162.8,24.6z" fill="#DCE2E7" />
        <Path
          className="st8"
          d="M228.1,108.2c0-0.1,0-0.3,0-0.4c0-3.1-2.5-5.6-5.6-5.6c-0.1,0-0.2,0-0.3,0c-1.8-3.1-5.2-5.2-9.1-5.2
		c-0.3,0-0.5,0-0.7,0c-1,0.1-2-0.4-2.5-1.3c-1.8-3.2-5.3-5.3-9.2-5.3v17.8"
          fill="#EFF3F6"
        />
        <Path className="st9" d="M154.6,94.7h-1.3c-0.5,0-0.9,0.4-0.9,0.9v0.2c0,0.5,0.4,0.9,0.9,0.9h1.3" fill="#E0E0E0" />
        <Path className="st9" d="M103.9,94.7h-1.3c-0.5,0-0.9,0.4-0.9,0.9v0.2c0,0.5,0.4,0.9,0.9,0.9h1.3" fill="#E0E0E0" />
        <Rect className="st3" fill="#E6EBEF" height="19.5" width="12.7" x="141.6" y="75" />
        <Polygon className="st10" fill="#FFFFFF" opacity="0.7" points="146.9,94.5 141.1,94.5 149.6,75 155.4,75 	" />
        <Rect className="st3" fill="#E6EBEF" height="19.5" width="12.7" x="90.8" y="75" />
        <Polygon className="st10" fill="#FFFFFF" opacity="0.7" points="87.7,94.5 81.8,94.5 90.3,75 96.2,75 	" />
        <Polygon className="st10" fill="#FFFFFF" opacity="0.7" points="97.8,94.5 94.1,94.5 102.6,75 106.3,75 	" />
        <Path
          className="st7"
          d="M175.8,44.5l7.4-8.1c0.3-0.3,0.7-0.5,1.2-0.5c0.4,0,0.9,0.2,1.2,0.5l21.1,24.8c0.6,0.7,0.5,1.6-0.2,2.2
		c-0.7,0.6-1.6,0.5-2.2-0.2l-20-23.4l-21.9,24.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.7-0.1-1-0.4c-0.6-0.6-0.7-1.6-0.1-2.2L173.6,47"
          fill="#DCE2E7"
        />
        <Polygon className="st3" fill="#E6EBEF" points="184.4,39.9 165.3,60.9 165.3,107.9 200.3,107.9 200.3,63.8 204.7,63.8 	" />
        <Polygon className="st6" fill="#BAC2CA" points="182.9,41.6 200.5,62.1 200.5,59.1 184.4,40.1 	" />
        <Polygon className="st6" fill="#BAC2CA" points="200.5,58.9 200.5,63.8 204.8,64 	" />
        <Ellipse className="st11" cx="184" cy="57.3" fill="#EFF1EF" rx="1.9" ry="3.3" />
        <Polyline className="st12" fill="none" points="153.9,26.6 153.9,40.3 162.3,40.3 166.7,35.7 166.7,26.7 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st13" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="162.2" x2="162.2" y1="34.3" y2="26.6" />
        <Line className="st13" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="162.2" x2="162.2" y1="37.9" y2="35.5" />
        <Path
          className="st12"
          d="M153.7,26.5h12.8c0.4,0,0.7-0.3,0.7-0.7v-0.6c0-0.4-0.3-0.7-0.7-0.7h-12.8c-0.4,0-0.7,0.3-0.7,0.7v0.6
		C152.9,26.2,153.3,26.5,153.7,26.5z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Line className="st13" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="162.7" x2="162.7" y1="24.6" y2="26.3" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="184.4" x2="168.5" y1="35.8" y2="35.8" />
        <Polyline className="st12" fill="none" points="108.9,35.7 99,35.7 75.5,61.4 119.7,61.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="112.6" x2="111.1" y1="35.7" y2="35.7" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="153.8" x2="114.6" y1="35.7" y2="35.7" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="161.7" x2="76" y1="64.4" y2="64.4" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="81.4" x2="81.4" y1="74.8" y2="107.6" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="81.4" x2="81.4" y1="65" y2="72.7" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="165.2" x2="165.2" y1="86.9" y2="107.6" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="165.2" x2="165.2" y1="60.7" y2="83.7" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="200.5" x2="200.5" y1="59.9" y2="107.6" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="204.9" x2="200.5" y1="63.8" y2="63.8" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="266.4" x2="15.4" y1="108" y2="108" />
        <Rect className="st12" fill="none" height="20.9" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" width="14.2" x="89.6" y="73.8" />
        <Polyline className="st13" fill="none" points="90.9,93.3 90.9,75 103.8,75 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st12" d="M93.4,96.8h10.4v-2.1H88.7c-0.5,0-0.9,0.4-0.9,0.9v0.2c0,0.5,0.4,0.9,0.9,0.9H92" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st13" d="M103.8,94.7h-1.3c-0.5,0-0.9,0.4-0.9,0.9v0.2c0,0.5,0.4,0.9,0.9,0.9h1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="96.9" x2="96.9" y1="83.2" y2="92.9" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="96.9" x2="96.9" y1="76.2" y2="81.2" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="92" x2="103.7" y1="84.6" y2="84.6" />
        <Polyline className="st12" fill="none" points="140.4,86.2 140.4,73.8 154.5,73.8 154.5,94.7 140.4,94.7 140.4,87.7 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Polyline className="st13" fill="none" points="141.6,93.4 141.6,75.1 153.4,75.1 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st12" d="M139.5,96.8h15.1v-2.1h-15.1c-0.5,0-0.9,0.4-0.9,0.9v0.2C138.5,96.4,138.9,96.8,139.5,96.8z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st13" d="M154.6,94.7h-1.3c-0.5,0-0.9,0.4-0.9,0.9v0.2c0,0.5,0.4,0.9,0.9,0.9h1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="147.7" x2="147.7" y1="88" y2="92.9" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="147.7" x2="147.7" y1="76.6" y2="86.4" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="143.1" x2="154.5" y1="84.6" y2="84.6" />
        <Path
          className="st12"
          d="M176,44.2l7.2-7.8c0.3-0.3,0.7-0.5,1.2-0.5c0.4,0,0.9,0.2,1.2,0.5l21.1,24.8c0.6,0.7,0.5,1.6-0.2,2.2
		c-0.7,0.6-1.6,0.5-2.2-0.2l-20-23.4l-21.9,24.1c-0.3,0.3-0.7,0.5-1.1,0.5c-0.4,0-0.7-0.1-1-0.4c-0.6-0.6-0.7-1.6-0.1-2.2L173,47.6"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="174.2" x2="174.9" y1="46.3" y2="45.5" />
        <Path className="st12" d="M78.4,61.3h-2c-0.8,0-1.5,0.7-1.5,1.5v0c0,0.8,0.7,1.5,1.5,1.5h2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st12" d="M53,102.7c-1,1.6-1.5,3.4-1.5,5.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path
          className="st12"
          d="M81.4,91.9c-0.8-0.1-1.6-0.2-2.4-0.2c-4.8,0-9.1,2.3-11.8,6c-0.5,0.7-1.5,1-2.3,0.7c-1-0.4-2.1-0.5-3.3-0.5
		c-3.2,0-6.1,1.5-7.9,3.8"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Ellipse className="st12" cx="184.5" cy="57.2" fill="none" rx="2.6" ry="3.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st13" d="M184.9,54.2c0.7,0.7,1.2,1.8,1.2,3.1c0,1.8-0.9,3.3-2.1,3.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path
          className="st12"
          d="M228.1,107.8c0-3.1-2.5-5.6-5.6-5.6c-0.1,0-0.2,0-0.3,0c-1.8-3.1-5.2-5.2-9.1-5.2c-0.2,0-0.3,0-0.5,0
		c-1.2,0.1-2.3-0.5-2.9-1.5c-1.9-3-5.2-5.1-9-5.1"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="267.9" x2="272.1" y1="108" y2="108" />
        <Path className="st0" d="M185.5,108.6c0-1.6-1.3-3.1-2.8-3.1c-1.2,0-2.2,0.8-2.5,1.9c-0.4-0.6-1.1-1.1-1.8-1.1c-1.2,0-2.1,1-2.3,2.2" fill="#FFFFFF" />
        <Path className="st12" d="M185.5,108c0-1.5-1.3-2.8-2.8-2.8c-1.2,0-2.2,0.7-2.5,1.8c-0.4-0.6-1.1-1-1.8-1c-1.2,0-2.1,0.9-2.3,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="122.3" x2="160.4" y1="61.4" y2="61.4" />
        <Path
          className="st8"
          d="M47.8,73.2L47.8,73.2c-5.4-32.9-13.1-31.5-13.1-31.5l0,0.1l0-0.1c0,0-7.3-1.5-12.8,31.4l0,0
		c-0.2,1-0.3,2-0.3,3.1c0,8.9,5.9,16.1,13.2,16.1S48,85.2,48,76.4C48,75.3,47.9,74.3,47.8,73.2z"
          fill="#EFF3F6"
        />
        <Path
          className="st1"
          d="M48,76.4c0-1.1-0.1-2.1-0.3-3.1l0,0c-5.5-32.9-13.1-31.5-13.1-31.5l0,0.1l0-0.1c0,0-0.5-0.1-1.3,0.3
		c1.9,4,8.1,17.4,8.7,29.9c0.6,11.5-3.2,17.4-7.4,20.4c0,0,0.1,0,0.1,0C42.1,92.4,48,85.2,48,76.4z"
          fill="#D8DFE5"
        />
        <Path
          className="st12"
          d="M47.8,73.2L47.8,73.2c-5.4-32.9-13.1-31.5-13.1-31.5l0,0.1l0-0.1c0,0-7.3-1.5-12.8,31.4l0,0
		c-0.2,1-0.3,2-0.3,3.1c0,8.9,5.9,16.1,13.2,16.1S48,85.2,48,76.4C48,75.3,47.9,74.3,47.8,73.2z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="34.7" x2="34.7" y1="92.6" y2="107.5" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="34.7" x2="34.7" y1="66.6" y2="81.3" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="29.7" x2="34.5" y1="69.8" y2="73.2" />
        <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="39.6" x2="34.8" y1="73.8" y2="77.3" />
        <Path className="st13" d="M29.4,52.7c-1.3,3.3-2.7,7.9-3.4,12.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st13" d="M31.6,47.5c0,0-0.6,1.3-1.5,3.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st13" d="M218.7,101.9c0.3,0.4,0.6,0.8,0.9,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st13" d="M213,99.1c0,0,2.4,0.1,4.5,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <G>
          <G fill="#000000">
            <Path
              className="st0"
              d="M205.1,99.7c0.9,2.7-1.1,3.7-1.1,3.7l0,0.1c-0.5-2-1.9-3.7-3.8-4.6l-0.1-0.1c0-0.1,0.1-0.2,0.1-0.3
				c0.1-0.4,0.1-0.7,0.1-1.1l0.2,0.2c0,0,1.3,0.2,1.7-0.8s-0.1-2.4-2-4.1c-1.9-1.7-3.1-1.1-3.1-1.1s-0.5-0.4-1.2-0.4
				c-0.7,0-1.1,0.4-1.1,0.4l-0.1,0l0,0c0,0-1.2-0.5-3.1,1.1c-1.9,1.7-2.4,3-2,4.1s1.7,0.8,1.7,0.8l0.2-0.4c0,0.4,0,0.8,0.1,1.2
				c0.1,0.6,0.3,1,0.6,1.4l0,0.1c0,0-0.5,0.6-0.6,1.6c0,0.6,0,3.6,0,5.5c0,1,0.8,1.7,1.7,1.7h0.3c1,0,1.7-0.8,1.7-1.7V105l-0.1-0.1
				c0.4,0.1,0.8,0.1,1.2,0l0,0.1l-0.1,0v2c0,1,0.8,1.7,1.7,1.7h0.2c1,0,1.7-0.8,1.7-1.7v0h0.9c0,0.1,0,0.2,0,0.3
				c0,0.7,0.6,1.3,1.3,1.3h0.8c0.6,0,1-0.5,1-1l0-2.8l0.1,0.5c1.4-0.8,1.8-2.1,1.8-2.1C206.8,101.1,205.1,99.7,205.1,99.7z"
              fill="#FFFFFF"
            />
            <Path
              className="st14"
              d="M191.7,101c0,0-1.5-0.8-2.5-0.1c-0.8,0.6-1.9,1-3,0.4l0.2-1.5c1.1,0.6,2.2,0.3,3-0.3c1-0.7,2.5,0.2,2.5,0.2
				"
              fill={svgHighlight}
            />
            <Line className="st15" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.45" x1="185.6" x2="186.4" y1="99.4" y2="99.8" />
            <Line className="st15" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.45" x1="185.4" x2="186.2" y1="100.2" y2="100.6" />
            <Line className="st15" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.45" x1="185.3" x2="186.1" y1="100.9" y2="101.3" />
            <Path
              className="st14"
              d="M192,101.3c0,0,1.2,1.6,3.5,1.7c2.3,0.1,5.2-1.2,4.9-3.8c0,0-1.5,2-3.8,2.3c-2.3,0.3-4.4-1.5-4.4-1.5
				L192,101.3z"
              fill={svgHighlight}
            />
            <G>
              <Path className="st12" d="M192.3,100c0,0-0.5,0.6-0.6,1.6c0,0.6,0,3.6,0,5.5c0,1,0.8,1.7,1.7,1.7h0.3c1,0,1.7-0.8,1.7-1.7V105" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Path className="st12" d="M194.2,104.3c0,0,1.9,1.4,3.7,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Path className="st12" d="M196.6,105.1v2c0,1,0.8,1.7,1.7,1.7h0.2c1,0,1.7-0.8,1.7-1.7v-4.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="200.3" x2="201.1" y1="107.1" y2="107.1" />
              <Path
                className="st12"
                d="M200.3,98.9L200.3,98.9c2.3,1.1,3.9,3.4,4,6l0,2.8c0,0.6-0.4,1-1,1h-0.8c-0.7,0-1.3-0.6-1.3-1.3l0,0
					c0-0.7,0.6-1.3,1.3-1.3h0.2"
                fill="none"
                stroke="#000000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="0.75"
              />
              <Path className="st12" d="M204.1,103.4c0,0,2-0.9,1.1-3.7c0,0,1.7,1.4,1,3.5c0,0-0.4,1.3-1.8,2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Path className="st13" d="M194.3,98.8c0,0,1.7,1.8,3.6,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
              <Line className="st13" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="196.1" x2="196.1" y1="99.5" y2="98" />
              <Circle cx="197.7" cy="95.4" r="0.3" />
              <Circle cx="194.6" cy="95.4" r="0.3" />
              <Ellipse cx="196.1" cy="96.9" rx="1.3" ry="0.6" />
              <Path d="M195,96.8l-0.2,0.1c0,0,0.1,1.1,1.3,1.2l0.1,0l0.1-1.1L195,96.8z" />
              <Path d="M197.2,96.8l0.2,0.1c0,0-0.1,1.1-1.3,1.2l-0.1,0l-0.1-1.1L197.2,96.8z" />
              <Path className="st12" d="M194.8,91.6c0,0-1.2-0.5-3.1,1.1c-1.9,1.7-2.4,3-2,4.1c0.4,1,1.7,0.8,1.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Path className="st12" d="M193.5,92.9c0,0-2.3,2.6-1.7,5.6s4.2,3,4.2,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Path className="st12" d="M194.8,91.7c0,0,0.4-0.4,1.1-0.4s1.2,0.4,1.2,0.4s1.2-0.5,3.1,1.1s2.4,3,2,4.1c-0.4,1-1.7,0.8-1.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
              <Path className="st12" d="M198.5,92.9c0,0,2.3,2.6,1.7,5.6s-4.2,3-4.2,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
            </G>
          </G>
          <Path className="st13" d="M192,101.3c0,0,1.2,1.6,3.5,1.7c2.3,0.1,5.2-1.2,4.9-3.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path
            className="st13"
            d="M191.7,101c0,0-1.5-0.8-2.5-0.1c-0.8,0.6-1.9,1-3,0.4l0.2-1.5c1.1,0.6,2.2,0.3,3-0.3c1-0.7,2.5,0.2,2.5,0.2"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
        </G>
        <G>
          <Path
            className="st1"
            d="M263.3,56.9c-1-0.8-1.3-2.2-0.6-3.3c1.3-2.1,2.1-4.6,2.1-7.2c0-5-2.7-9.4-6.9-12.2c-0.5-0.4-0.8-1-0.6-1.6
			c0.2-0.7,0.3-1.5,0.3-2.3c0-4.9-4.4-8.8-9.9-8.8s-9.9,3.9-9.9,8.8c0,0.8,0.1,1.5,0.3,2.3c0.2,0.6-0.1,1.2-0.6,1.6
			c-4.2,2.8-6.9,7.2-6.9,12.2c0,2.6,0.8,5.1,2.1,7.2c0.7,1.1,0.4,2.5-0.6,3.3c-4.3,3.7-7.1,8.9-7.1,14.6c0,11.2,10.1,20.2,22.7,20.2
			s22.7-9,22.7-20.2C270.3,65.8,267.6,60.6,263.3,56.9z"
            fill="#D8DFE5"
          />
          <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="247.1" x2="247.1" y1="91.8" y2="108" />
          <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="247.1" x2="247.1" y1="49.1" y2="79.8" />
          <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="236.6" x2="246.9" y1="65.3" y2="72" />
          <Line className="st12" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="252.6" x2="247.1" y1="57.6" y2="61.2" />
          <Path
            className="st12"
            d="M263.3,56.9c-1-0.8-1.3-2.2-0.6-3.3c1.3-2.1,2.1-4.6,2.1-7.2c0-5-2.7-9.4-6.9-12.2c-0.5-0.4-0.8-1-0.6-1.6
			c0.2-0.7,0.3-1.5,0.3-2.3c0-4.9-4.4-8.8-9.9-8.8s-9.9,3.9-9.9,8.8c0,0.8,0.1,1.5,0.3,2.3c0.2,0.6-0.1,1.2-0.6,1.6
			c-4.2,2.8-6.9,7.2-6.9,12.2c0,2.6,0.8,5.1,2.1,7.2c0.7,1.1,0.4,2.5-0.6,3.3c-4.3,3.7-7.1,8.9-7.1,14.6c0,11.2,10.1,20.2,22.7,20.2
			s22.7-9,22.7-20.2C270.3,65.8,267.6,60.6,263.3,56.9z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
        </G>
        <G>
          <Path
            className="st0"
            d="M142,103.5c-1.7-3.4-6-2.8-6-2.8l-0.8,0l0,0l-0.3,0l-0.3,0l0,0l-0.8,0c0,0-4.2-0.5-6,2.8
			c-1.7,3.4,0.9,6.3,2.9,7c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4C141.1,109.8,143.7,106.8,142,103.5z"
            fill="#FFFFFF"
          />
          <Path
            className="st6"
            d="M142,103.5c-1.1-2.1-3.1-2.7-4.5-2.8c0.9,0.4,1.7,1.1,2.3,2.2c1.7,3.4-0.9,6.3-2.9,7c0,0-1.1,0.4-3.1,0.4
			h-0.2h-1.6h-0.2c-0.6,0-1.1,0-1.5-0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4
			C141.1,109.8,143.7,106.8,142,103.5z"
            fill="#BAC2CA"
          />
          <Path
            className="st12"
            d="M142,103.5c-1.7-3.4-6-2.8-6-2.8l-0.8,0l0,0l-0.3,0l-0.3,0l0,0l-0.8,0c0,0-4.2-0.5-6,2.8
			c-1.7,3.4,0.9,6.3,2.9,7c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4C141.1,109.8,143.7,106.8,142,103.5z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st12" d="M134.3,100.7c0,0-3.6,0.6-3.6,5c0,4.3,3.7,5.2,3.7,5.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M135.1,100.7c0,0,3.5,0.6,3.5,5c0,4.3-3.6,5.2-3.6,5.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M133.9,98.8c0,0,0.7,0.3,0.8,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <G>
          <Path
            className="st0"
            d="M110.3,104.5c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C109.6,109.7,111.7,107.3,110.3,104.5z"
            fill="#FFFFFF"
          />
          <Path
            className="st6"
            d="M110.3,104.5c-0.9-1.7-2.5-2.2-3.7-2.3c0.7,0.3,1.4,0.9,1.9,1.8c1.4,2.8-0.7,5.1-2.4,5.7c0,0-0.9,0.4-2.6,0.4
			h-0.2h-1.3h-0.2c-0.5,0-0.9,0-1.2-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4
			C109.6,109.7,111.7,107.3,110.3,104.5z"
            fill="#BAC2CA"
          />
          <Path
            className="st12"
            d="M110.3,104.5c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C109.6,109.7,111.7,107.3,110.3,104.5z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st12" d="M101.6,108.5c0.9,1.7,2.6,2.1,2.6,2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M104,102.3c0,0-2.2,0.4-2.8,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M104.7,102.3c0,0,2.9,0.5,2.9,4s-3,4.3-3,4.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M104.9,100.7c0,0-0.6,0.2-0.7,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <G>
          <Path
            className="st0"
            d="M153.8,105.1c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C153,110.3,155.2,107.9,153.8,105.1z"
            fill="#FFFFFF"
          />
          <Path
            className="st6"
            d="M153.8,105.1c-0.9-1.7-2.5-2.2-3.7-2.3c0.7,0.3,1.4,0.9,1.9,1.8c1.4,2.8-0.7,5.1-2.4,5.7c0,0-0.9,0.4-2.6,0.4
			h-0.2h-1.3h-0.2c-0.5,0-0.9,0-1.2-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4
			C153,110.3,155.2,107.9,153.8,105.1z"
            fill="#BAC2CA"
          />
          <Path
            className="st12"
            d="M153.8,105.1c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C153,110.3,155.2,107.9,153.8,105.1z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st12" d="M145,109.1c0.9,1.7,2.6,2.1,2.6,2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M147.5,102.9c0,0-2.2,0.4-2.8,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M148.1,102.9c0,0,2.9,0.5,2.9,4s-3,4.3-3,4.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st12" d="M148.4,101.3c0,0-0.6,0.2-0.7,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <G>
          <Path
            className="st3"
            d="M265.2,87.8c0,0-1.2,0.7-2.5-0.1c-1.3-0.8-1.4-2.3-1.4-2.3l0.1-0.2c0,0,1.4-0.6,2.6,0.2
			c1.3,0.8,1.2,2.2,1.2,2.2L265.2,87.8z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M261.4,85.4c0,0,0.1,1.5,1.4,2.3c1.3,0.8,2.5,0.1,2.5,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M261.5,85.2c0,0,1.4-0.6,2.6,0.2s1.2,2.2,1.2,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M50.2,80.8c0,0-1.2,0.7-2.5-0.1c-1.3-0.8-1.4-2.3-1.4-2.3l0.1-0.2c0,0,1.4-0.6,2.6,0.2
			c1.3,0.8,1.2,2.2,1.2,2.2L50.2,80.8z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M46.4,78.4c0,0,0.1,1.5,1.4,2.3c1.3,0.8,2.5,0.1,2.5,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M46.5,78.3c0,0,1.4-0.6,2.6,0.2c1.3,0.8,1.2,2.2,1.2,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M219.2,85.6c0,0,0.2,1.4-0.9,2.3c-1.2,0.9-2.6,0.5-2.6,0.5l-0.1-0.1c0,0-0.1-1.5,1.1-2.4
			c1.2-0.9,2.4-0.4,2.4-0.4L219.2,85.6z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M215.6,88.4c0,0,1.4,0.4,2.6-0.5c1.2-0.9,0.9-2.3,0.9-2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M215.5,88.2c0,0-0.1-1.5,1.1-2.4c1.2-0.9,2.4-0.4,2.4-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M259.2,70.4c0,0,0.2,1.4-0.9,2.3c-1.2,0.9-2.6,0.5-2.6,0.5l-0.1-0.1c0,0-0.1-1.5,1.1-2.4
			c1.2-0.9,2.4-0.4,2.4-0.4L259.2,70.4z"
            fill="#FFFFFF"
          />
          <Path className="st16" d="M255.6,73.1c0,0,1.4,0.4,2.6-0.5c1.2-0.9,0.9-2.3,0.9-2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M255.5,73c0,0-0.1-1.5,1.1-2.4c1.2-0.9,2.4-0.4,2.4-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M270.3,94.8c0,0,0.2,1.4-0.9,2.3c-1.2,0.9-2.6,0.5-2.6,0.5l-0.1-0.1c0,0-0.1-1.5,1.1-2.4
			c1.2-0.9,2.4-0.4,2.4-0.4L270.3,94.8z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M266.8,97.6c0,0,1.4,0.4,2.6-0.5c1.2-0.9,0.9-2.3,0.9-2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M266.6,97.4c0,0-0.1-1.5,1.1-2.4c1.2-0.9,2.4-0.4,2.4-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st3" d="M221.4,48c0,0,1.3-0.4,2.4,0.7s0.8,2.5,0.8,2.5l-0.1,0.1c0,0-1.5,0.2-2.5-0.8s-0.6-2.4-0.6-2.4L221.4,48z" fill="#E6EBEF" />
          <Path className="st16" d="M224.5,51.3c0,0,0.3-1.5-0.8-2.5s-2.4-0.7-2.4-0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M224.4,51.4c0,0-1.5,0.2-2.5-0.8s-0.6-2.4-0.6-2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M247.3,49.3c0,0-1.2,0.7-2.5-0.1c-1.3-0.8-1.4-2.3-1.4-2.3l0.1-0.2c0,0,1.4-0.6,2.6,0.2
			c1.3,0.8,1.2,2.2,1.2,2.2L247.3,49.3z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M243.5,46.9c0,0,0.1,1.5,1.4,2.3c1.3,0.8,2.5,0.1,2.5,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M243.6,46.7c0,0,1.4-0.6,2.6,0.2c1.3,0.8,1.2,2.2,1.2,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M241.7,109.1c0,0-1.2,0.7-2.5-0.1c-1.3-0.8-1.4-2.3-1.4-2.3l0.1-0.2c0,0,1.4-0.6,2.6,0.2
			c1.3,0.8,1.2,2.2,1.2,2.2L241.7,109.1z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M237.9,106.7c0,0,0.1,1.5,1.4,2.3c1.3,0.8,2.5,0.1,2.5,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M238,106.6c0,0,1.4-0.6,2.6,0.2c1.3,0.8,1.2,2.2,1.2,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M250.1,113c0,0-0.4-1.3,0.6-2.4s2.5-0.8,2.5-0.8l0.1,0.1c0,0,0.3,1.5-0.8,2.5c-1,1.1-2.4,0.7-2.4,0.7
			L250.1,113z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M253.2,109.8c0,0-1.5-0.2-2.5,0.8c-1,1.1-0.6,2.4-0.6,2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M253.4,109.9c0,0,0.3,1.5-0.8,2.5c-1,1.1-2.4,0.7-2.4,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M247,107.3c0,0-1,1-2.4,0.6c-1.4-0.4-1.9-1.8-1.9-1.8l0.1-0.2c0,0,1.2-0.9,2.6-0.5c1.4,0.4,1.7,1.7,1.7,1.7
			L247,107.3z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M242.7,106c0,0,0.5,1.4,1.9,1.8c1.4,0.4,2.4-0.6,2.4-0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M242.7,105.9c0,0,1.2-0.9,2.6-0.5c1.4,0.4,1.7,1.7,1.7,1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st3" d="M253.5,108c0,0-1.2,1-2.8,0.5c-1.6-0.6-2-2.2-2-2.2l0.1-0.2c0,0,1.4-1,3-0.4s1.8,2.1,1.8,2.1L253.5,108z" fill="#E6EBEF" />
          <Path className="st16" d="M248.8,106.2c0,0,0.5,1.6,2,2.2s2.8-0.5,2.8-0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M248.8,106c0,0,1.4-1,3-0.4s1.8,2.1,1.8,2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M245.9,108.8c0,0,0.2-1.4,1.5-1.9c1.4-0.6,2.6,0.2,2.6,0.2l0.1,0.2c0,0-0.3,1.5-1.7,2
			c-1.4,0.6-2.4-0.3-2.4-0.3L245.9,108.8z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M250.1,107.1c0,0-1.3-0.8-2.6-0.2c-1.4,0.6-1.5,1.9-1.5,1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M250.2,107.3c0,0-0.3,1.5-1.7,2c-1.4,0.6-2.4-0.3-2.4-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M235.2,110.4c0,0-0.4,1.5-2,1.9c-1.6,0.4-2.9-0.7-2.9-0.7l0-0.2c0,0,0.6-1.6,2.3-2c1.6-0.4,2.7,0.8,2.7,0.8
			L235.2,110.4z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M230.2,111.6c0,0,1.3,1.1,2.9,0.7c1.6-0.4,2-1.9,2-1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M230.2,111.4c0,0,0.6-1.6,2.3-2c1.6-0.4,2.7,0.8,2.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M230.5,107.4c0,0-0.5,1.5-2.2,1.7c-1.6,0.3-2.8-0.9-2.8-0.9l0-0.2c0,0,0.8-1.5,2.4-1.8c1.6-0.3,2.6,1,2.6,1
			L230.5,107.4z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M225.5,108.1c0,0,1.2,1.2,2.8,0.9c1.6-0.3,2.2-1.7,2.2-1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M225.5,107.9c0,0,0.8-1.5,2.4-1.8c1.6-0.3,2.6,1,2.6,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M210.4,92c0,0,0.1,1.6-1.3,2.4c-1.4,0.9-3,0.3-3,0.3l-0.1-0.2c0,0,0.1-1.7,1.5-2.6c1.4-0.9,2.8-0.1,2.8-0.1
			L210.4,92z"
            fill="#FFFFFF"
          />
          <Path className="st16" d="M206.1,94.7c0,0,1.6,0.6,3-0.3c1.4-0.9,1.3-2.4,1.3-2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M206,94.5c0,0,0.1-1.7,1.5-2.6c1.4-0.9,2.8-0.1,2.8-0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M260.2,111.5c0,0-0.8,1.1-2.3,0.9s-2.1-1.5-2.1-1.5l0-0.2c0,0,1-1.1,2.5-0.9c1.5,0.2,1.9,1.5,1.9,1.5
			L260.2,111.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M255.7,110.8c0,0,0.7,1.3,2.1,1.5s2.3-0.9,2.3-0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M255.8,110.6c0,0,1-1.1,2.5-0.9s1.9,1.5,1.9,1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M219.4,111.9c0,0-0.5,1.3-2,1.5c-1.5,0.2-2.5-0.9-2.5-0.9l0-0.2c0,0,0.7-1.3,2.2-1.5c1.5-0.2,2.3,0.9,2.3,0.9
			L219.4,111.9z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M214.9,112.4c0,0,1,1.1,2.5,0.9c1.5-0.2,2-1.5,2-1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M214.9,112.2c0,0,0.7-1.3,2.2-1.5c1.5-0.2,2.3,0.9,2.3,0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M223.1,76.2c0,0-0.3-1.7,1.2-2.9c1.5-1.2,3.3-0.7,3.3-0.7l0.2,0.2c0,0,0.1,1.9-1.4,3.1
			c-1.5,1.2-3.1,0.5-3.1,0.5L223.1,76.2z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M227.6,72.7c0,0-1.8-0.5-3.3,0.7c-1.5,1.2-1.2,2.9-1.2,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M227.8,72.9c0,0,0.1,1.9-1.4,3.1c-1.5,1.2-3.1,0.5-3.1,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M230.9,97.3c0,0-1.7,0.2-2.9-1.3c-1.1-1.5-0.5-3.3-0.5-3.3l0.2-0.1c0,0,1.9,0,3,1.5c1.1,1.5,0.4,3.1,0.4,3.1
			L230.9,97.3z"
            fill="#FFFFFF"
          />
          <Path className="st16" d="M227.6,92.6c0,0-0.6,1.8,0.5,3.3c1.1,1.5,2.9,1.3,2.9,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M227.7,92.5c0,0,1.9,0,3,1.5c1.1,1.5,0.4,3.1,0.4,3.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M14.2,86.4c0,0,0.2,1.4-0.9,2.3c-1.2,0.9-2.6,0.5-2.6,0.5l-0.1-0.1c0,0-0.1-1.5,1.1-2.4
			c1.2-0.9,2.4-0.4,2.4-0.4L14.2,86.4z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M10.7,89.2c0,0,1.4,0.4,2.6-0.5c1.2-0.9,0.9-2.3,0.9-2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M10.5,89c0,0-0.1-1.5,1.1-2.4c1.2-0.9,2.4-0.4,2.4-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st7"
            d="M25.9,98.1c0,0-1.7,0.2-2.9-1.3c-1.1-1.5-0.5-3.3-0.5-3.3l0.2-0.1c0,0,1.9,0,3,1.5c1.1,1.5,0.4,3.1,0.4,3.1
			L25.9,98.1z"
            fill="#DCE2E7"
          />
          <Path className="st16" d="M22.6,93.5c0,0-0.6,1.8,0.5,3.3c1.1,1.5,2.9,1.3,2.9,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M22.8,93.3c0,0,1.9,0,3,1.5c1.1,1.5,0.4,3.1,0.4,3.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st7"
            d="M91.5,60.7c0,0-0.3-1.7,1.2-2.9c1.5-1.1,3.3-0.6,3.3-0.6l0.1,0.2c0,0,0,1.9-1.5,3.1c-1.5,1.1-3.1,0.4-3.1,0.4
			L91.5,60.7z"
            fill="#DCE2E7"
          />
          <Path className="st16" d="M96.1,57.2c0,0-1.8-0.6-3.3,0.6c-1.5,1.1-1.2,2.9-1.2,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M96.2,57.4c0,0,0,1.9-1.5,3.1c-1.5,1.1-3.1,0.4-3.1,0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M272.8,58.2c0,0-1.7,0.2-2.9-1.3s-0.5-3.3-0.5-3.3l0.2-0.1c0,0,1.9,0,3,1.5S273,58,273,58L272.8,58.2z" fill="#FFFFFF" />
          <Path className="st16" d="M269.5,53.5c0,0-0.6,1.8,0.5,3.3c1.1,1.5,2.9,1.3,2.9,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M269.7,53.4c0,0,1.9,0,3,1.5c1.1,1.5,0.4,3.1,0.4,3.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M19.4,73.8c0,0-0.1-1.8,1.5-2.8s3.4-0.3,3.4-0.3l0.1,0.2c0,0-0.1,1.9-1.7,2.9c-1.6,1-3.1,0.2-3.1,0.2
			L19.4,73.8z"
            fill="#FFFFFF"
          />
          <Path className="st16" d="M24.2,70.7c0,0-1.8-0.7-3.4,0.3s-1.5,2.8-1.5,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M24.4,70.9c0,0-0.1,1.9-1.7,2.9S19.5,74,19.5,74" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M261.7,44.4c0,0,0.2-1.8,1.9-2.5c1.7-0.7,3.4,0.3,3.4,0.3l0.1,0.2c0,0-0.4,1.9-2.2,2.6s-3.1-0.4-3.1-0.4
			L261.7,44.4z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M267,42.2c0,0-1.6-1-3.4-0.3c-1.7,0.7-1.9,2.5-1.9,2.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M267.1,42.4c0,0-0.4,1.9-2.2,2.6c-1.7,0.7-3.1-0.4-3.1-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M240.6,67.6c0,0-1.5-1-1.3-2.9s1.8-2.9,1.8-2.9l0.2,0c0,0,1.4,1.3,1.3,3.1c-0.2,1.9-1.8,2.6-1.8,2.6
			L240.6,67.6z"
            fill="#FFFFFF"
          />
          <Path className="st16" d="M241.2,61.9c0,0-1.6,1-1.8,2.9c-0.2,1.9,1.3,2.9,1.3,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M241.4,61.9c0,0,1.4,1.3,1.3,3.1c-0.2,1.9-1.8,2.6-1.8,2.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M241.9,111.5c0,0,0.9-1.4,2.7-1.2c1.8,0.2,2.6,1.7,2.6,1.7l0,0.2c0,0-1.2,1.3-2.9,1.2
			c-1.8-0.2-2.4-1.7-2.4-1.7L241.9,111.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M247.2,112.1c0,0-0.9-1.5-2.6-1.7c-1.8-0.2-2.7,1.2-2.7,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M247.2,112.3c0,0-1.2,1.3-2.9,1.2c-1.8-0.2-2.4-1.7-2.4-1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M259.4,107.4c0,0,0.9-1.4,2.7-1.2s2.6,1.7,2.6,1.7l0,0.2c0,0-1.2,1.3-2.9,1.2c-1.8-0.2-2.4-1.7-2.4-1.7
			L259.4,107.4z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M264.8,107.9c0,0-0.9-1.5-2.6-1.7c-1.8-0.2-2.7,1.2-2.7,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M264.7,108.2c0,0-1.2,1.3-2.9,1.2c-1.8-0.2-2.4-1.7-2.4-1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M41.1,109.1c0,0,1.2,0.7,2.5-0.1c1.3-0.8,1.4-2.3,1.4-2.3l-0.1-0.2c0,0-1.4-0.6-2.6,0.2
			C41,107.6,41,109,41,109L41.1,109.1z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M44.9,106.7c0,0-0.1,1.5-1.4,2.3c-1.3,0.8-2.5,0.1-2.5,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M44.8,106.6c0,0-1.4-0.6-2.6,0.2c-1.3,0.8-1.2,2.2-1.2,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M32.8,113c0,0,0.4-1.3-0.6-2.4c-1-1.1-2.5-0.8-2.5-0.8l-0.1,0.1c0,0-0.3,1.5,0.8,2.5c1,1.1,2.4,0.7,2.4,0.7
			L32.8,113z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M29.6,109.8c0,0,1.5-0.2,2.5,0.8c1,1.1,0.6,2.4,0.6,2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M29.5,109.9c0,0-0.3,1.5,0.8,2.5c1,1.1,2.4,0.7,2.4,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M35.9,107.3c0,0,1,1,2.4,0.6c1.4-0.4,1.9-1.8,1.9-1.8l-0.1-0.2c0,0-1.2-0.9-2.6-0.5c-1.4,0.4-1.7,1.7-1.7,1.7
			L35.9,107.3z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M40.2,106c0,0-0.5,1.4-1.9,1.8s-2.4-0.6-2.4-0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M40.1,105.9c0,0-1.2-0.9-2.6-0.5s-1.7,1.7-1.7,1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M42.4,96.7c0,0,1.1,1.1,2.7,0.7c1.6-0.5,2.2-2.1,2.2-2.1l-0.1-0.2c0,0-1.3-1.1-3-0.6c-1.6,0.5-2,2-2,2
			L42.4,96.7z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M47.3,95.3c0,0-0.6,1.6-2.2,2.1c-1.6,0.5-2.7-0.7-2.7-0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M47.3,95c0,0-1.3-1.1-3-0.6c-1.6,0.5-2,2-2,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M36.9,108.8c0,0-0.2-1.4-1.5-1.9c-1.4-0.6-2.6,0.2-2.6,0.2l-0.1,0.2c0,0,0.3,1.5,1.7,2
			c1.4,0.6,2.4-0.3,2.4-0.3L36.9,108.8z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M32.7,107.1c0,0,1.3-0.8,2.6-0.2c1.4,0.6,1.5,1.9,1.5,1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M32.7,107.3c0,0,0.3,1.5,1.7,2c1.4,0.6,2.4-0.3,2.4-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M47.7,110.4c0,0,0.4,1.5,2,1.9c1.6,0.4,2.9-0.7,2.9-0.7l0-0.2c0,0-0.6-1.6-2.3-2s-2.7,0.8-2.7,0.8L47.7,110.4
			z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M52.6,111.6c0,0-1.3,1.1-2.9,0.7c-1.6-0.4-2-1.9-2-1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M52.7,111.4c0,0-0.6-1.6-2.3-2s-2.7,0.8-2.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M86.3,59.5c0,0,0.4,1.5,2,1.9c1.6,0.4,2.9-0.7,2.9-0.7l0-0.2c0,0-0.6-1.6-2.3-2c-1.6-0.4-2.7,0.8-2.7,0.8
			L86.3,59.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M91.2,60.6c0,0-1.3,1.1-2.9,0.7c-1.6-0.4-2-1.9-2-1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M91.2,60.4c0,0-0.6-1.6-2.3-2c-1.6-0.4-2.7,0.8-2.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M108.6,59.5c0,0,0.4,1.5,2,1.9c1.6,0.4,2.9-0.7,2.9-0.7l0-0.2c0,0-0.6-1.6-2.3-2c-1.6-0.4-2.7,0.8-2.7,0.8
			L108.6,59.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M113.5,60.7c0,0-1.3,1.1-2.9,0.7c-1.6-0.4-2-1.9-2-1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M113.6,60.5c0,0-0.6-1.6-2.3-2c-1.6-0.4-2.7,0.8-2.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M109.9,57.6c0,0-1.5-0.5-2.7,0.6c-1.2,1.1-1.1,2.8-1.1,2.8l0.1,0.2c0,0,1.6,0.4,2.9-0.8
			c1.2-1.1,0.9-2.6,0.9-2.6L109.9,57.6z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M106.2,61c0,0-0.2-1.7,1.1-2.8s2.7-0.6,2.7-0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M106.4,61.2c0,0,1.6,0.4,2.9-0.8s0.9-2.6,0.9-2.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M74.3,112c0,0,0.8,1.4,2.4,1.3c1.7,0,2.6-1.4,2.6-1.4l0-0.2c0,0-1-1.3-2.7-1.3c-1.7,0-2.4,1.4-2.4,1.4
			L74.3,112z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M79.4,111.8c0,0-1,1.4-2.6,1.4s-2.4-1.3-2.4-1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M79.4,111.6c0,0-1-1.3-2.7-1.3c-1.7,0-2.4,1.4-2.4,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M58.4,108.6c0,0,0.8,1.4,2.4,1.3s2.6-1.4,2.6-1.4l0-0.2c0,0-1-1.4-2.7-1.3c-1.7,0-2.4,1.4-2.4,1.4L58.4,108.6
			z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M63.4,108.5c0,0-1,1.4-2.6,1.4s-2.4-1.3-2.4-1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M63.4,108.3c0,0-1-1.4-2.7-1.3s-2.4,1.4-2.4,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M22.7,111.5c0,0,0.8,1.1,2.3,0.9s2.1-1.5,2.1-1.5l0-0.2c0,0-1-1.1-2.5-0.9c-1.5,0.2-1.9,1.5-1.9,1.5
			L22.7,111.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M27.1,110.8c0,0-0.7,1.3-2.1,1.5c-1.5,0.2-2.3-0.9-2.3-0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M27.1,110.6c0,0-1-1.1-2.5-0.9s-1.9,1.5-1.9,1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M51.6,87.5c0,0,0.1,1.4,1.4,2c1.3,0.6,2.6-0.1,2.6-0.1l0.1-0.2c0,0-0.3-1.5-1.6-2.1c-1.3-0.6-2.4,0.2-2.4,0.2
			L51.6,87.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M55.7,89.4c0,0-1.3,0.7-2.6,0.1c-1.3-0.6-1.4-2-1.4-2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M55.8,89.2c0,0-0.3-1.5-1.6-2.1c-1.3-0.6-2.4,0.2-2.4,0.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M96.9,59.7c0,0,0.6,1.3,2,1.4c1.5,0.1,2.4-1,2.4-1l0-0.2c0,0-0.8-1.3-2.2-1.4c-1.5-0.1-2.2,1-2.2,1L96.9,59.7
			z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M101.4,60.1c0,0-1,1.1-2.4,1s-2-1.4-2-1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M101.4,59.9c0,0-0.8-1.3-2.2-1.4c-1.5-0.1-2.2,1-2.2,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M41,111.5c0,0-0.9-1.4-2.7-1.2c-1.8,0.2-2.6,1.7-2.6,1.7l0,0.2c0,0,1.2,1.3,2.9,1.2c1.8-0.2,2.4-1.7,2.4-1.7
			L41,111.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M35.6,112.1c0,0,0.9-1.5,2.6-1.7c1.8-0.2,2.7,1.2,2.7,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M35.7,112.3c0,0,1.2,1.3,2.9,1.2c1.8-0.2,2.4-1.7,2.4-1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M105.8,62.3c0,0,0.7-1.5-0.3-2.9c-1-1.5-2.8-1.5-2.8-1.5l-0.2,0.1c0,0-0.6,1.7,0.4,3.1c1,1.5,2.6,1.3,2.6,1.3
			L105.8,62.3z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M102.7,58c0,0,1.8,0,2.8,1.5c1,1.5,0.3,2.9,0.3,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M102.5,58.1c0,0-0.6,1.7,0.4,3.1c1,1.5,2.6,1.3,2.6,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M23.4,107.4c0,0-0.9-1.4-2.7-1.2s-2.6,1.7-2.6,1.7l0,0.2c0,0,1.2,1.3,2.9,1.2c1.8-0.2,2.4-1.7,2.4-1.7
			L23.4,107.4z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M18.1,107.9c0,0,0.9-1.5,2.6-1.7c1.8-0.2,2.7,1.2,2.7,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M18.1,108.2c0,0,1.2,1.3,2.9,1.2c1.8-0.2,2.4-1.7,2.4-1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M47.3,57.8c0,0-1.5-0.6-2.9,0.5c-1.3,1.1-1.2,2.9-1.2,2.9l0.1,0.2c0,0,1.7,0.4,3.1-0.7c1.3-1.1,1-2.7,1-2.7
			L47.3,57.8z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M43.2,61.3c0,0-0.1-1.8,1.2-2.9c1.3-1.1,2.9-0.5,2.9-0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M43.4,61.4c0,0,1.7,0.4,3.1-0.7c1.3-1.1,1-2.7,1-2.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st3"
            d="M73,95.5c0,0,0.6-1.5-0.6-2.9s-2.9-1.2-2.9-1.2l-0.2,0.1c0,0-0.4,1.7,0.8,3.1c1.2,1.3,2.8,1,2.8,1L73,95.5z"
            fill="#E6EBEF"
          />
          <Path className="st16" d="M69.4,91.5c0,0,1.8-0.2,2.9,1.2s0.6,2.9,0.6,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st16" d="M69.3,91.6c0,0-0.4,1.7,0.8,3.1c1.2,1.3,2.8,1,2.8,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <Path className="st12" d="M249.5,83.2c-0.9-2.8-3.5-4.9-6.6-4.9c-1.5,0-2.9,0.5-4.1,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st12" d="M255.6,83.3c-0.4-0.9-1.2-1.6-2.1-2.1c-1.5-0.7-3.1-0.6-4.4,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st12" d="M29.8,81.4c0,0,5.7-1.3,8.2,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
      </G>
    </Svg>
  );
}
