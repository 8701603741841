/**
* BaseToggle
* @description: A toggle switch button that changes color when selected/unselected
* Use Case: Use when a single selection is required and you want to offer two options
*           for an on/off type of decision
* Component Props: {
*   disabled (bool) state determining whether the button is disabled
* }
*/

import React, {
  useCallback,
  useEffect,
  useRef,
} from 'react';
import T from 'prop-types';
import { Animated, Easing, Platform } from 'react-native';

import {
  ToggleActiveBackgroundView,
  ToggleContainer,
  ToggleSwitch,
  ToggleWrapper,
} from './styledComponents';
import { SLIDE_VALUE } from './constants';

const useNativeDriver = Platform.OS !== 'web';

const BaseToggle = ({
  ariaLabel,
  disabled,
  isActive,
  onPress,
}) => {
  const isFirstRender = useRef(true);
  const { current: animatedTranslate } = useRef(new Animated.Value(isActive ? SLIDE_VALUE : 0));
  const { current: animatedOpacity } = useRef(new Animated.Value(isActive ? 1 : 0));

  const animateToggle = useCallback((active) => {
    Animated.parallel([
      Animated.timing(animatedTranslate, {
        delay: 0,
        duration: 150,
        easing: Easing.linear.inOut,
        toValue: active ? SLIDE_VALUE : 0,
        useNativeDriver,
      }),
      Animated.timing(animatedOpacity, {
        delay: 0,
        duration: 150,
        easing: Easing.linear.inOut,
        toValue: active ? 1 : 0,
        useNativeDriver,
      }),
    ]).start();
  }, [animatedOpacity, animatedTranslate]);

  useEffect(() => {
    if (!isFirstRender.current) {
      animateToggle(isActive);
    }
    isFirstRender.current = false;
  }, [animateToggle, isActive]);

  return (
    <ToggleWrapper
      accessibilityState={{ checked: isActive }}
      aria-checked={isActive}
      aria-label={ariaLabel}
      disabled={disabled}
      onPress={onPress}
      role="switch"
    >
      <ToggleContainer>
        <ToggleActiveBackgroundView style={{ opacity: animatedOpacity }} />
        <ToggleSwitch style={{ transform: [{ translateX: animatedTranslate }] }} />
      </ToggleContainer>
    </ToggleWrapper>
  );
};

BaseToggle.propTypes = {
  ariaLabel: T.string.isRequired,
  disabled: T.bool,
  isActive: T.bool.isRequired,
  onPress: T.func.isRequired,
};

export default BaseToggle;
