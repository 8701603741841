import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import { Text } from 'react-native';

import { formatDate } from '@dmi/shared/utils/globalHelpers';

import { SecondaryHeader } from '../../ScreenHeaders';
import {
  AlertBanner,
  ConditionalRender,
  H5,
  KeyboardAvoidingScrollView,
  LargePrimaryButton,
  P2,
} from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import {
  AlertContainer,
  AttachmentContainer,
  AttachmentIcon,
  BoldText,
  StyledBody,
  StyledBorderlessButton,
  StyledButtonView,
  StyledMessageHeader,
  StyledPrimaryDivider,
  StyledStatusBanner,
  StyledTitle,
  StyledView,
} from './styledComponents';
import ReplyBox from './ReplyBox';

const DownloadIcon = iconDictionary('downloadActiveS', { iconState: 'active' });

const MessageDetailsComponent = ({
  bannerError,
  dispatchChangeFormFieldValue,
  dispatchChangeStep,
  dispatchFetchAttachment,
  dispatchPostReply,
  dispatchResetState,
  dispatchSetAttachmentFile,
  dispatchToggleDrawer,
  formAttachments: attachments,
  formReply: { body },
  formUploadError,
  isMessageStatusClosed,
  messageDetails,
  navigation,
  requestStatusPostReply,
  selectedMessageId,
  setShowReplyBox,
  showReplyBox,
}) => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const handlePress = () => {
    dispatchResetState({ stateToReset: 'errors' });
    setShowReplyBox(true);
    setIsKeyboardVisible(true);
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.navigate('SecureMessaging')}
        title="Message Details"
      />
      <StyledStatusBanner error={bannerError} />
      <ConditionalRender
        Component={(
          <Fragment>
            <StyledView>
              <StyledTitle>{messageDetails.title}</StyledTitle>
              <H5>{`Ticket: #${selectedMessageId}`}</H5>
            </StyledView>
            <StyledPrimaryDivider />
          </Fragment>
        )}
        shouldRender={!isKeyboardVisible}
      />
      <KeyboardAvoidingScrollView>
        {messageDetails.messages.map(({
          attachments: replyAttachments,
          body: replyBody,
          created,
          fromToLabel,
          fromToName,
          id,
        }, i) => (
          <StyledView key={id}>
            <StyledMessageHeader>
              <P2>
                <BoldText>{fromToLabel}</BoldText>
                <Text>{fromToName}</Text>
              </P2>
              <P2>{formatDate(created, 'UTC', 'M/D/YY')}</P2>
            </StyledMessageHeader>
            <StyledBody>{replyBody}</StyledBody>
            {replyAttachments.map((attachment) => (
              <AttachmentContainer key={attachment.id}>
                <AttachmentIcon>{DownloadIcon}</AttachmentIcon>
                <StyledBorderlessButton
                  label={attachment.filename}
                  onPress={() => {
                    dispatchResetState({ stateToReset: 'errors' });
                    setTimeout(() => (dispatchFetchAttachment({
                      attachment,
                      dispatchSetFile: dispatchSetAttachmentFile,
                      dispatchToggleDrawer,
                    })), 500);
                  }}
                />
              </AttachmentContainer>
            ))}
            <ConditionalRender
              Component={StyledPrimaryDivider}
              shouldRender={i !== messageDetails.messages.length - 1}
            />
          </StyledView>
        ))}
        <ConditionalRender
          Component={ReplyBox}
          propsToPassDown={{
            attachments,
            body,
            dispatchChangeFormFieldValue,
            dispatchChangeStep,
            dispatchPostReply,
            formUploadError,
            requestStatusPostReply,
            selectedMessageId,
            setIsKeyboardVisible,
          }}
          shouldRender={showReplyBox}
        />
      </KeyboardAvoidingScrollView>
      <ConditionalRender
        Component={(
          <StyledButtonView>
            <LargePrimaryButton
              label="Reply"
              onPress={handlePress}
            />
          </StyledButtonView>
        )}
        shouldRender={!isMessageStatusClosed && !showReplyBox}
      />
      <ConditionalRender
        Component={(
          <AlertContainer>
            <AlertBanner
              boldText="This inquiry has been closed"
              lineBreak
              text="Please send a new message if you have questions related to this conversation."
            />
          </AlertContainer>
        )}
        shouldRender={isMessageStatusClosed}
      />
    </Fragment>
  );
};

MessageDetailsComponent.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchFetchAttachment: T.func.isRequired,
  dispatchPostReply: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchSetAttachmentFile: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formReply: T.object.isRequired,
  formUploadError: T.string.isRequired,
  isMessageStatusClosed: T.bool.isRequired,
  messageDetails: T.object.isRequired,
  navigation: T.object.isRequired,
  requestStatusPostReply: T.string.isRequired,
  selectedMessageId: T.string.isRequired,
  setShowReplyBox: T.func.isRequired,
  showReplyBox: T.bool.isRequired,
};

export default MessageDetailsComponent;
