import styled from 'styled-components/native';

import { H4 } from '../../Heading';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  border-bottom-width: 1px;
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  flex-direction: row;
  padding: ${moderateScale(11)}px ${moderateScale(16)}px;
`;

export const IllustrationWrapper = styled.View`
  margin: 0 ${moderateScale(17)}px 0 ${moderateScale(8)}px;
`;

export const MessageContainer = styled.View`
  flex: 1;
`;

export const Title = styled(H4)`
  margin-bottom: ${moderateScale(4)}px;
`;
