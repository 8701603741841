import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Linking } from 'react-native';

import {
  RESOURCES_DRAWER_DETAILS,
  WHERE_TO_SUBMIT_DOCUMENTS_KEY,
} from '@dmi/shared/redux/LossDraft/constants';

import { P2, StyledScrollView } from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import {
  SectionHeader,
  StyledLinkText,
  StyledView,
} from './styledComponents';

const WhereToSubmitDocuments = ({ navigation }) => {
  const {
    mobileDetail: {
      address: { content: mailingAddress, header: addressHeader },
      email: { content: emailAddress, header: emailHeader },
      fax: { content: faxNumber, header: faxHeader },
      text,
    },
    resourceTitle,
  } = RESOURCES_DRAWER_DETAILS[WHERE_TO_SUBMIT_DOCUMENTS_KEY];

  return (
    <StyledScrollView>
      <SecondaryHeader
        handleBack={navigation.goBack}
        title={resourceTitle}
      />
      <StyledView>
        <P2>
          <FormattedMessage id={text} />
        </P2>
        <SectionHeader>{addressHeader}</SectionHeader>
        {mailingAddress.map((addressRow) => (
          <P2 key={addressRow}>{addressRow}</P2>
        ))}
        <SectionHeader>{faxHeader}</SectionHeader>
        <P2>{faxNumber}</P2>
        <SectionHeader>{emailHeader}</SectionHeader>
        <StyledLinkText onPress={() => Linking.openURL(`mailto:${emailAddress}`)}>
          {emailAddress}
        </StyledLinkText>
      </StyledView>
    </StyledScrollView>
  );
};

WhereToSubmitDocuments.propTypes = { navigation: T.object.isRequired };

export default WhereToSubmitDocuments;
