import MainView from './MainView';
import ConditionsView from './ConditionsView';
import ConfirmationView from './ConfirmationView';
import ErrorView from '../Shared/ErrorView';

export default {
  1: { Component: MainView },
  2: { Component: ConditionsView },
  3: { Component: ConfirmationView },
  4: { Component: ErrorView },
};
