import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { H5 } from '../../base_ui';
import { ClaimInfoContainer, ClaimNumber, StyledDivider } from './styledComponents';

const GeneralClaimInfoComponent = ({ claimNumber, claimType }) => (
  <View>
    <ClaimInfoContainer>
      <ClaimNumber>{`Claim #: ${claimNumber}`}</ClaimNumber>
      <H5>{`${claimType} Claim`}</H5>
    </ClaimInfoContainer>
    <StyledDivider />
  </View>
);

GeneralClaimInfoComponent.propTypes = {
  claimNumber: T.string.isRequired,
  claimType: T.string.isRequired,
};

export default GeneralClaimInfoComponent;
