import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';

export const FlexView = styled.View`
  flex: 1;
`;

/* eslint-disable object-curly-newline */
export const StyledScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    alignItems: 'center',
    minHeight: '100%',
  },
})``;

export const TempStyledView = styled.View`
  padding-top: ${moderateScale(60)}px;
`;
