import styled from 'styled-components/native';

import {
  FormattedMessageStatusBanner,
  LargePrimaryButton,
  P3,
  StatusBanner,
} from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';
import PaymentStatusBlock from '../PaymentStatusBlock';

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ContentTextWrapper = styled.View`
  margin-right: ${moderateScale(16)}px;
`;

export const ContentWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(48)}px 0 ${moderateScale(24)}px;
`;

export const Date = styled.Text`
  color: ${({ theme: { colors: { accentGray } } }) => accentGray};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  line-height: ${moderateScale(18)}px;
  text-align: left;
`;

export const IllustrationWrapper = styled.View`
  align-self: flex-end;
  margin-bottom: ${moderateScale(5)}px;
`;

export const Payment = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { primaryFont } } }) => primaryFont};
  font-size: ${moderateScale(40)}px;
  line-height: ${moderateScale(55)}px;
  text-align: left;
`;

export const StyledFormattedMessageStatusBanner = styled(FormattedMessageStatusBanner)`
  margin-bottom: ${moderateScale(12)}px;
`;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  margin: ${moderateScale(24)}px 0 ${moderateScale(40)}px;
  width: 100%;
`;

export const StyledP3 = styled(P3)`
  margin-top: ${moderateScale(6)}px;
`;

export const StyledPaymentStatusBlock = styled(PaymentStatusBlock)`
  margin-top: ${({ $addMarginTop }) => $addMarginTop ? `${moderateScale(10)}px` : '0px'};
`;

export const StyledPhoneLinkText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${moderateScale(12)}px;
`;
