import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { Keyboard } from 'react-native';
import { createStructuredSelector } from 'reselect';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { validateSourceFormInputs } from '@dmi/shared/redux/Payments/Sources/helpers';
import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import AsyncRender from '../../AsyncRender';
import { ErrorSuccessBanner, KeyboardAvoidingScrollView } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import ScreenReaderNotifications from '../../ScreenReaderNotifications';
import Form from './Form';

const PaymentSourceContainer = ({
  bannerError,
  customerServicePhoneNumber,
  dispatchClearNewInfo,
  dispatchResetBannerErrors,
  dispatchResetSources,
  dispatchSetInput,
  dispatchSetInputError,
  dispatchSetUseExisting,
  dispatchSetValidateRoutingError,
  dispatchValidateRouting,
  formState: { formErrors, formValues },
  isExistingInfo,
  isNewInfo,
  isOtp,
  navigation,
  notifications,
  useExistingInfo,
  useNewInfo,
  validateRoutingError,
  validateRoutingLoading,
  validateRoutingSuccess,
}) => {
  useEffect(() => dispatchResetBannerErrors, [dispatchResetBannerErrors]);

  const handleValidatePress = () => {
    const isInputError = validateSourceFormInputs({ dispatchSetInputError, formValues });

    if (!isInputError) {
      dispatchValidateRouting({ isOtp, ...formValues });
    }

    Keyboard.dismiss();
  };

  const errorLinkProps = {
    label: 'Return to Home',
    onPress: () => {
      dispatchSetValidateRoutingError(false);
    },
    path: 'Payments',
  };

  const handleBack = () => {
    if (!validateRoutingSuccess) {
      dispatchResetSources();
    }
    navigation.goBack();
  };

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <SecondaryHeader
        handleBack={handleBack}
        title="Payment Account Information"
      />
      <KeyboardAvoidingScrollView scrollViewProps={{ showsVerticalScrollIndicator: false }}>
        <ErrorSuccessBanner
          error={bannerError ?
            interpolate(bannerError, { customerServicePhoneNumber }) : bannerError}
        />
        <AsyncRender
          Component={Form}
          error={validateRoutingError}
          errorComponentProps={{ link: errorLinkProps }}
          loading={false}
          propsToPassDown={{
            dispatchClearNewInfo,
            dispatchSetInput,
            dispatchSetInputError,
            dispatchSetUseExisting,
            dispatchValidateRouting,
            formErrors,
            formValues,
            handleValidatePress,
            isExistingInfo,
            isNewInfo,
            useExistingInfo,
            useNewInfo,
            validateRoutingLoading,
          }}
        />
      </KeyboardAvoidingScrollView>
    </Fragment>
  );
};

PaymentSourceContainer.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchClearNewInfo: T.func.isRequired,
  dispatchResetBannerErrors: T.func.isRequired,
  dispatchResetSources: T.func.isRequired,
  dispatchSetInput: T.func.isRequired,
  dispatchSetInputError: T.func.isRequired,
  dispatchSetUseExisting: T.func.isRequired,
  dispatchSetValidateRoutingError: T.func.isRequired,
  dispatchValidateRouting: T.func.isRequired,
  formState: T.shape({ formErrors: T.object, formValues: T.object }).isRequired,
  isExistingInfo: T.bool.isRequired,
  isNewInfo: T.bool.isRequired,
  isOtp: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  useExistingInfo: T.bool.isRequired,
  useNewInfo: T.bool.isRequired,
  validateRoutingError: T.oneOfType([T.bool, T.string]).isRequired,
  validateRoutingLoading: T.bool.isRequired,
  validateRoutingSuccess: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(PaymentSourceContainer);
