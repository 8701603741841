import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

/*
  TODO: confirm with designers whether the important part of the mockup is either:
    1) a consistent ~24px padding-horizontal like the current implementation, or
    2) A max-width of 375px (including the 24px paddingHorizontal) and scale to larger screens with
       'align-self: center'.
*/
export const MainWrapper = styled.View`
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(24)}px;
  width: 100%;
`;
