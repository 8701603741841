/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function InfoTooltipXSIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(12)} viewBox="0 0 12 12" width={moderateScale(12)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M0.499999 6C0.499999 2.96243 2.96243 0.5 6 0.5C9.03757 0.500001 11.5 2.96243 11.5 6C11.5 9.03757 9.03757 11.5 6 11.5C2.96243 11.5 0.499998 9.03757 0.499999 6Z" stroke={primary} />
      <Path d="M5.8791 5.14981C5.99845 5.14981 6.11291 5.19722 6.1973 5.28161C6.28169 5.366 6.3291 5.48046 6.3291 5.59981L6.3291 8.9748C6.3291 9.09415 6.28169 9.20861 6.1973 9.293C6.11291 9.37739 5.99845 9.4248 5.8791 9.4248C5.75975 9.4248 5.6453 9.37739 5.5609 9.293C5.47651 9.20861 5.4291 9.09415 5.4291 8.9748L5.4291 5.59981C5.4291 5.54071 5.44074 5.48219 5.46336 5.4276C5.48597 5.373 5.51912 5.32339 5.5609 5.28161C5.60269 5.23982 5.6523 5.20667 5.7069 5.18406C5.76149 5.16145 5.82001 5.14981 5.8791 5.14981Z" fill={primary} />
      <Path d="M5.87953 2.52981C5.45703 2.52981 5.11453 2.87232 5.11453 3.29481C5.11453 3.71731 5.45703 4.05981 5.87953 4.05981C6.30203 4.05981 6.64453 3.71731 6.64453 3.29481C6.64453 2.87232 6.30203 2.52981 5.87953 2.52981Z" fill={primary} />
    </Svg>
  );
}
