import { Dimensions, PixelRatio } from 'react-native';

const { height, width } = Dimensions.get('window');

// Guideline sizes are based on standard ~5" screen mobile device
const guidelineBaseWidth = 375;
const guidelineBaseHeight = 680;

/**
 * @description Scales a number (representing density pixels- dp) in direct proportion to device
 * screen width. Rounds to the nearest dp (may be a decimal) that corresponds to
 * an integer number of device pixels.
 *
 * Rare usage since React Native now supports flex and % units for layout elements.
 *
 * @param {number} size number to scale
 */
const scale = (size) => (
  PixelRatio.roundToNearestPixel((width / guidelineBaseWidth) * size)
);

/**
 * @description Scales a number (representing density pixels- dp) in direct proportion to device
 * screen height. Rounds to the nearest dp (may be a decimal) that corresponds to
 * an integer number of device pixels.
 *
 * Rare usage since React Native now supports flex and % units for layout elements.
 *
 * @param {number} size number to scale
 */
const verticalScale = (size) => (
  PixelRatio.roundToNearestPixel((height / guidelineBaseHeight) * size)
);

/**
 * @description Scales a number ("size", representing density pixels- dp) in correlation with device
 * screen width, but not necessarily in direct proportion. The relationship between "size"'s
 * rate of change and the device screen width's rate of change can be provided as a 'factor'
 * argument. The default is 0.3, which means "size" would grow 0.3 times as fast as the device
 * screen width (the base screen width is 350). Rounds result to the nearest dp (may be a decimal)
 * that corresponds to an integer number of device pixels.
 *
 * Definite use cases are fontSize, lineHeight, SVG size.
 * Generally want to use flex and/or % units to style big layout elements like views.
 * ModerateScale may sometimes be more useful than flex/percent for smaller elements like buttons
 * and texts.
 *
 * @param {number} size number to scale
 * @param {number} [factor=0.3] factor by which size changes in proportion to screen width change
 */
const moderateScale = (size, factor = 0.3) => (
  PixelRatio.roundToNearestPixel(size + (scale(size) - size) * factor)
);

export {
  moderateScale,
  scale,
  verticalScale,
};
