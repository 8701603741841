import React from 'react';
import T from 'prop-types';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { Skeleton } from '../../../base_ui';
import { RowView, SkeletonView } from './styledComponents';

const ItemSkeleton = ({ isFirst }) => {
  const topMargin = isFirst ? 35 : 20;

  return (
    <RowView>
      <SkeletonView $widthPercent={30}>
        <Skeleton
          $bottomMargin={`${moderateScale(19)}px`}
          $topMargin={`${moderateScale(topMargin)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
      </SkeletonView>
      <SkeletonView $widthPercent={55}>
        <Skeleton
          $bottomMargin={`${moderateScale(19)}px`}
          $topMargin={`${moderateScale(topMargin)}px`}
          height={`${moderateScale(12)}px`}
          width="100%"
        />
      </SkeletonView>
    </RowView>
  );
};

ItemSkeleton.propTypes = { isFirst: T.bool.isRequired };

export default ItemSkeleton;
