import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  DetailsTitle,
  DetailsValue,
  MortgageDetailsRow,
  MortgageDetailsRows,
} from './styledComponents';

const MortgageDetails = ({ detailsData }) => (
  <MortgageDetailsRows>
    {detailsData.map(({ label, value }) => (
      <MortgageDetailsRow key={label}>
        <DetailsTitle>
          <FormattedMessage id={label} />
        </DetailsTitle>
        <DetailsValue>{value}</DetailsValue>
      </MortgageDetailsRow>
    ))}
  </MortgageDetailsRows>
);

MortgageDetails.propTypes = { detailsData: T.arrayOf(T.object).isRequired };

export default MortgageDetails;
