import {
  Confirmation as AutopayConfirmation,
  CancelOrStopConfirmation,
} from '../../../../containers/Payments/Autopay';
import {
  CancelConfirmationScreen,
  OtpConfirmationScreen,
} from '../../../../containers/Payments/OneTimePayment';
import MakePaymentSplash from '../../../../containers/Payments/PaymentSplash';
import AutopayPreConfirmationStack from './AutopayPreConfirmationStack';
import OtpPreConfirmationStack from './OtpPreConfirmationStack';
import PaymentHistoryStack from './PaymentHistoryStack';

/* eslint-disable sort-keys */
export default {
  // Autopay Screens
  AutopayConfirmation: { component: AutopayConfirmation },
  AutopayPreConfirmation: { component: AutopayPreConfirmationStack },
  CancelOrStopConfirmation: { component: CancelOrStopConfirmation },
  // OTP Screens
  CancelConfirmation: { component: CancelConfirmationScreen },
  OtpConfirmation: { component: OtpConfirmationScreen },
  OtpPreConfirmation: { component: OtpPreConfirmationStack },
  // Payment History Screens
  PaymentHistoryStack: { component: PaymentHistoryStack },
  // Make Payment Splash
  MakePaymentSplash: { component: MakePaymentSplash },
};
