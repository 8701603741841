import React, { useCallback } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';

import {
  changeInput,
  changeStep,
  focusInput,
  resetDrawerInputsAndErrors,
  setPin,
  updateError,
} from '@dmi/shared/redux/Settings/Security/actions';
import makeSelectSecurity, {
  selectInputFocusState,
  selectPinDrawerNotifications,
  selectSecurityBannerError,
} from '@dmi/shared/redux/Settings/Security/selectors';

import { PinDrawerContent } from '../../../components/Pin';
import { getPinFormStepBasedProps } from './helpers';

const PinForm = ({
  dispatchChangeInput,
  dispatchChangeStep,
  dispatchFocusInput,
  dispatchResetDrawerInputsAndErrors,
  dispatchSetPin,
  dispatchUpdateError,
  error,
  formValues,
  notifications,
  passcodeInputFocusState,
  setIsDrawerOpen,
  step,
}) => {
  useFocusEffect(
    useCallback(() => (
      () => {
        dispatchChangeStep({ step: 1 });
        dispatchResetDrawerInputsAndErrors();
      }
    ), [dispatchChangeStep, dispatchResetDrawerInputsAndErrors]),
  );

  const stepBasedProps = getPinFormStepBasedProps({
    dispatchChangeInput,
    dispatchChangeStep,
    dispatchFocusInput,
    dispatchResetDrawerInputsAndErrors,
    dispatchSetPin,
    dispatchUpdateError,
    formValues,
    setIsDrawerOpen,
    step,
  });

  return (
    <PinDrawerContent
      error={error}
      notifications={notifications}
      passcodeInputFocusState={passcodeInputFocusState}
      {...stepBasedProps}
    />
  );
};

PinForm.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchFocusInput: T.func.isRequired,
  dispatchResetDrawerInputsAndErrors: T.func.isRequired,
  dispatchSetPin: T.func.isRequired,
  dispatchUpdateError: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  formValues: T.shape({
    confirmPin: T.string.isRequired,
    pin: T.string.isRequired,
  }).isRequired,
  notifications: T.array.isRequired,
  passcodeInputFocusState: T.oneOf([0, 1]).isRequired,
  setIsDrawerOpen: T.func.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Security
   */
  error: selectSecurityBannerError('pinDrawer'),
  formValues: makeSelectSecurity('form'),
  notifications: selectPinDrawerNotifications(),
  passcodeInputFocusState: selectInputFocusState('passcode'),
  step: makeSelectSecurity('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Security
  */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchFocusInput: (payload) => dispatch(focusInput(payload)),
  dispatchResetDrawerInputsAndErrors: () => dispatch(resetDrawerInputsAndErrors()),
  dispatchSetPin: (payload) => dispatch(setPin(payload)),
  dispatchUpdateError: (payload) => dispatch(updateError(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(PinForm);
