import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H3, P2, SecondaryCard } from '../../base_ui';

// index.js
export const MainContainer = styled.View`
  flex: 1;
  justify-content: space-between;
  margin: ${moderateScale(16)}px ${moderateScale(24)}px;
  width: 100%;
`;

export const StyledView = styled.View`
  padding: ${moderateScale(12)}px ${moderateScale(24)}px 0;
  width: 100%;
`;

// Contact card
export const ContactCard = styled(SecondaryCard)`
  height: ${moderateScale(184)}px;
  margin: ${moderateScale(20)}px 0 ${moderateScale(30)}px 0;
  padding-left: ${moderateScale(24)}px;
`;

export const ContactContainer = styled.View`
  margin-bottom: ${moderateScale(30)}px;
  margin-top: ${moderateScale(23)}px;
`;

export const ContactHeader = styled(H3)`
  margin-top: ${moderateScale(17)}px;
`;

export const ContactText = styled(P2)`
  margin-left: ${moderateScale(12)}px;
`;

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(8)}px;
`;

export const StyledLink = styled(P2)`
  color: ${({ theme: { colors: { primary } } }) => primary};
  margin-left: ${moderateScale(12)}px;
`;

// Resource Row
export const PressableRow = styled.Pressable`
  background-color: ${(
    ({
      $isPressing,
      theme: { colors: { accentSilver, dmiWhite } },
    }) => $isPressing ? accentSilver : dmiWhite
  )};
  flex-direction: row;
  justify-content: space-between;
  padding: ${(moderateScale(14))}px ${(moderateScale(24))}px;
  width: 100%;
`;
