import React from 'react';
import { Pressable } from 'react-native';
import T from 'prop-types';

import { H5 } from '../../../base_ui';
import {
  IconWrapper,
  RowButtonContainer,
  SelectedWrapper,
  StyledP2,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const ArrowIcon = iconDictionary('arrowRightCarbonS');

const RowButton = ({
  handlePress,
  label,
  selected,
}) => {
  const textToUse = selected || 'Select';

  return (
    <Pressable onPress={() => handlePress(label)}>
      <RowButtonContainer>
        <H5>{label}</H5>
        <SelectedWrapper>
          <StyledP2>{textToUse}</StyledP2>
          <IconWrapper>
            {ArrowIcon}
          </IconWrapper>
        </SelectedWrapper>
      </RowButtonContainer>
    </Pressable>
  );
};

RowButton.propTypes = {
  handlePress: T.func.isRequired,
  label: T.string.isRequired,
  selected: T.string.isRequired,
};

export default RowButton;
