/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function BackArrowCarbonMIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(20)} viewBox="0 0 18 20" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M9 1L17 10L9 19" stroke="#282828" strokeLinecap="round" />
    </Svg>
  );
}
