import styled from 'styled-components/native';

import {
  BaseInternalLink,
  PrimaryDivider,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const LoanCardWrapper = styled.View`
  margin:
    ${moderateScale(24)}px
    ${moderateScale(24)}px
    ${({ $isInactiveLoan }) =>
    $isInactiveLoan ? `${moderateScale(19)}px;` : `${moderateScale(5)}px;`}
    ${moderateScale(24)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  align-self: center;
  margin: ${moderateScale(26)}px 0;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin: 0 ${moderateScale(24)}px ${moderateScale(16)}px;
`;
