/**
 * BaseInput
 * @description: BaseUI TextField/Input that takes care of shared concerns and default styling
 * across our various TextField types. Is meant to be private to base_ui/Input folder; is not meant
 * to be rendered itself. Shared TextField logic:
 *  -Handles focus state related issues, passing down $focused prop and onFocus and onBlur methods
 *  -Passes down $error for styling error state, and ensures 'aria-invalid' is applied for web
 *  -Sets input to not autoCapitalize or autoCorrect.
 *  -Ensures disabled state gets passed to ReactNative's Input component for proper 'web' disabling.
 *
 * Component props: {
 *     disabled (bool) If true, the input will be disabled (gets passed through) [default false]
 *     error (bool|string) If truthy, passes down $error prop for styling. If string, is rendered
 *                         as red helper text [default false]
 *     onBlurSideEffects (function) Function that gets triggered in onBlur to handle things besides
 *                                  simply setting the 'focused' state/indicator
 *     onChangeText (function) Function that gets triggered when user types new input. Gets passed
 *                             new value of input as single string argument (gets passed through)
 *     onFocusSideEffects (function) Function that gets triggered in onFocus to handle things
 *                                   besides simply setting the 'focused' state/indicator
 *     value (string) The input's value (gets passed through)
 * }
 *
 * API Usage: any other props are passed through to ReactNativeElements' Input component (in
 * addition to those props noted with 'gets passed through' above), which in turn sends props down
 * to ReactNative's TextInput component.
 *  -Note: we want to use the onChangeText prop for TextInput, not onChange
 */

import React, {
  forwardRef,
  useMemo,
  useState,
} from 'react';
import { Platform, TextInput } from 'react-native';
import T from 'prop-types';

import { StyledBaseInput } from './styledComponents';

const BaseInput = forwardRef(({
  disabled = false,
  disableFocus = false,
  error = false,
  onBlurSideEffects,
  onChangeText,
  onFocusSideEffects,
  value,
  variant,
  ...restProps
}, ref) => {
  const [focused, setFocused] = useState(false);
  const { keyboardType } = restProps;

  const onFocus = () => {
    setFocused(true);
    if (onFocusSideEffects) {
      onFocusSideEffects();
    }
  };

  const onBlur = () => {
    setFocused(false);
    if (onBlurSideEffects) {
      onBlurSideEffects();
    }
  };

  const InputComponent = useMemo(
    () => (
      forwardRef((props, forwardedRef) =>
        <TextInput ref={forwardedRef} disabled={disabled} {...props} />)
    ),
    [disabled],
  );

  let ariaInvalidProp = {};
  if (error && (Platform.OS === 'web')) {
    ariaInvalidProp = { 'aria-invalid': true };
  }

  return (
    <StyledBaseInput
      ref={ref}
      $error={!!error}
      $filled={!!value}
      $focused={!disableFocus && focused}
      $variant={variant}
      // Fix for Android native bug missing decimal separator on some device keyboards
      // https://github.com/facebook/react-native/issues/22005#issuecomment-536333635
      aria-label="input"
      autoCapitalize={Platform.OS === 'android' && keyboardType === 'decimal-pad'
        ? 'words'
        : 'none'}
      autoCorrect={false}
      disabled={disabled}
      errorMessage={typeof error === 'string' ? error : undefined}
      InputComponent={InputComponent}
      onBlur={onBlur}
      onChangeText={onChangeText}
      onFocus={onFocus}
      value={value}
      {...ariaInvalidProp}
      {...restProps}
    />
  );
});

BaseInput.propTypes = {
  disabled: T.bool,
  disableFocus: T.bool,
  error: T.oneOfType([T.bool, T.string]),
  onBlurSideEffects: T.func,
  onChangeText: T.func.isRequired,
  onFocusSideEffects: T.func,
  value: T.string.isRequired,
  variant: T.string,
};

export default BaseInput;
