import Confirmation from '../../../components/Register/Confirmation';
import {
  ForgotPasswordFormInput,
  ResetPasswordFormInput,
} from '../../../components/Register/FormInputs';
import SharedForm from '../../../components/Register/SharedForm';
import {
  FORGOT_PASSWORD_CARD_PROPS,
  FORGOT_PASSWORD_CONFIRMATION_PROPS,
  RESET_PASSWORD_CARD_PROPS,
  RESET_PASSWORD_CONFIRMATION_PROPS,
} from './constants';

export default {
  init: {
    1: {
      viewCardProps: FORGOT_PASSWORD_CARD_PROPS,
      ViewComponent: SharedForm,
      ViewFormInput: ForgotPasswordFormInput,
    },
    2: {
      viewCardProps: FORGOT_PASSWORD_CONFIRMATION_PROPS,
      ViewComponent: Confirmation,
    },
  },
  verify: {
    1: {
      viewCardProps: RESET_PASSWORD_CARD_PROPS,
      ViewComponent: SharedForm,
      ViewFormInput: ResetPasswordFormInput,
    },
    2: {
      viewCardProps: RESET_PASSWORD_CONFIRMATION_PROPS,
      ViewComponent: Confirmation,
    },
  },
};
