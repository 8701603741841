import React, { Fragment } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import InfoSection from '../InfoSection';
import { SecondaryHeader } from '../../ScreenHeaders';
import {
  CardButton,
  ConditionalRender,
  H5,
  NativeAnimation,
  P2,
  WebAnimation,
} from '../../base_ui';
import {
  AnimationContainer,
  CardButtonWrapper,
  ConfirmationRow,
  Container,
  FlexView,
  StyledH2,
  StyledP2,
} from './styledComponents';

const MessagesConfirmationSucceeded = ({
  cardButtonDictionary,
  confirmationCopyDictionary: {
    heading,
    text,
  },
  confirmationData,
  handleCardButtonOnpress,
  handleConfirmationPressDone,
  infoCopyDictionary: { confirmation: infoSectionData },
}) => (
  <Fragment>
    <SecondaryHeader
      handleDone={handleConfirmationPressDone}
      title="Confirmation"
    />
    <Container>
      <AnimationContainer>
        <ConditionalRender
          Component={<WebAnimation name="robotMobile" />}
          FallbackComponent={<NativeAnimation name="robotMobile" />}
          shouldRender={Platform.OS === 'web'}
        />
      </AnimationContainer>
      <StyledH2>{heading}</StyledH2>
      <StyledP2>{text}</StyledP2>
      {confirmationData.map(({ label, value }) => (
        <ConfirmationRow key={label}>
          <H5>{label}</H5>
          <P2>{value}</P2>
        </ConfirmationRow>
      ))}
      <InfoSection
        data={infoSectionData}
      />
      <FlexView />
      <CardButtonWrapper>
        <CardButton onPress={handleCardButtonOnpress} {...cardButtonDictionary} />
      </CardButtonWrapper>
    </Container>
  </Fragment>
);

MessagesConfirmationSucceeded.propTypes = {
  cardButtonDictionary: T.object.isRequired,
  confirmationCopyDictionary: T.shape({
    heading: T.string.isRequired,
    text: T.string.isRequired,
  }).isRequired,
  confirmationData: T.array.isRequired,
  handleCardButtonOnpress: T.func.isRequired,
  handleConfirmationPressDone: T.func.isRequired,
  infoCopyDictionary: T.shape({ confirmation: T.array.isRequired }).isRequired,
};

export default MessagesConfirmationSucceeded;
