import React from 'react';
import T from 'prop-types';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import PrimaryDocUploadButton from '../../DocUpload/PrimaryDocPickerButton';
import { AlertBanner, P2, StyledScrollView } from '../../base_ui';
import { EditContainer, FlexView } from '../styledComponents';
import {
  ButtonContainer,
  IllustrationWrapper,
  List,
  ListBullet,
  StyledH2,
  StyledH5,
  StyledView,
} from './styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(210)}px` });

const MilitaryStatusUploadComponent = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  reviewScreenName,
}) => {
  const { subheader, text } = PROFILE_CONTENT_DICTIONARY.editMilitaryStatus.uploadView;

  return (
    <StyledScrollView>
      <EditContainer>
        <IllustrationWrapper $isUpload>
          {UploadHouse}
        </IllustrationWrapper>
        <StyledView>
          <StyledH2>{subheader}</StyledH2>
          <StyledH5>{text.first.header}</StyledH5>
          {text.first.bullets.map((item) => (
            <List key={item.slice(0, 10)}>
              <ListBullet key={item.slice(0, 10)}>&bull;</ListBullet>
              <P2>{item}</P2>
            </List>
          ))}
          <StyledH5>{text.second.header}</StyledH5>
          {text.second.bullets.map((item) => (
            <List key={item.slice(0, 10)}>
              <ListBullet key={item.slice(0, 10)}>&bull;</ListBullet>
              <P2>{item}</P2>
            </List>
          ))}
        </StyledView>
        <AlertBanner
          boldText="Formats accepted: "
          text="JPEG, JPG, PDF, PNG, TIFF, or GIF (Max size 10 MB)."
        />
        <FlexView />
        <ButtonContainer>
          <PrimaryDocUploadButton
            chooseDocumentRequestStatus={chooseDocumentRequestStatus}
            dispatchChooseDocument={dispatchChooseDocument}
            reviewScreenName={reviewScreenName}
          />
        </ButtonContainer>
      </EditContainer>
    </StyledScrollView>
  );
};

MilitaryStatusUploadComponent.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  reviewScreenName: T.string.isRequired,
};

export default MilitaryStatusUploadComponent;
