import React from 'react';
import Svg, {
  Circle,
  Ellipse,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
const LoanDetailIcon = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      height={moderateScale(40)}
      width={moderateScale(40)}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Circle fill="#fff" stroke="#bdc5cd" strokeMiterlimit="10" strokeWidth="0.53px" cx="20.1" cy="20" r="19.43" />
      <Polyline fill="#e6ebef" points="9.66 28.95 9.66 16.19 22.32 16.19 22.32 28.95" />
      <Rect fill="#bac2ca" x="11.78" y="20.93" width="2.47" height="4.49" />
      <Rect fill={svgHighlight} x="16.49" y="20.81" width="3.02" height="7.63" />
      <Polygon fill="#bac2ca" points="20.56 18.11 7.65 18.11 13.8 10.93 26.71 10.93 20.56 18.11" />
      <Path fill="#000" d="M20.56,18.43H7.65a.33.33,0,0,1-.29-.18.32.32,0,0,1,0-.34l6.16-7.18a.3.3,0,0,1,.24-.11h3.56a.31.31,0,1,1,0,.62H14L8.33,17.8H20.41L26,11.24H19.19a.31.31,0,1,1,0-.62h7.52a.33.33,0,0,1,.29.18.31.31,0,0,1-.05.33l-6.16,7.19A.3.3,0,0,1,20.56,18.43Z" />
      <Path fill="#000" d="M32.55,18.43a.3.3,0,0,1-.24-.12l-5.84-7.18a.32.32,0,0,1,.49-.4l5.83,7.19a.32.32,0,0,1,0,.44A.37.37,0,0,1,32.55,18.43Z" />
      <Path fill="#000" d="M30.81,29.38H9.62a.32.32,0,0,1-.32-.31V18.14a.32.32,0,0,1,.32-.31.31.31,0,0,1,.31.31V28.76H30.5V16.19a.32.32,0,1,1,.63,0V29.07A.31.31,0,0,1,30.81,29.38Z" />
      <Path fill="#000" d="M22.38,29.27a.31.31,0,0,1-.31-.31V25a.31.31,0,0,1,.62,0V29A.31.31,0,0,1,22.38,29.27Z" />
      <Path fill="#000" d="M22.38,23.75a.32.32,0,0,1-.31-.32V18.9a.31.31,0,1,1,.62,0v4.53A.32.32,0,0,1,22.38,23.75Z" />
      <Path fill="#000" d="M19.53,29.23a.32.32,0,0,1-.32-.32V21.17H16.8v6.4a.32.32,0,0,1-.63,0V20.86a.31.31,0,0,1,.32-.31h3a.31.31,0,0,1,.31.31v8.05A.31.31,0,0,1,19.53,29.23Z" />
      <Path fill="#000" d="M14.39,25.77H11.7a.31.31,0,0,1-.31-.31V20.89a.31.31,0,0,1,.31-.31h2.69a.31.31,0,0,1,.32.31v4.57A.31.31,0,0,1,14.39,25.77ZM12,25.15h2.06V21.21H12Z" />
      <Ellipse fill="#e6ebef" cx="26.87" cy="16.45" rx="0.77" ry="1.19" />
      <Path fill="#000" d="M26.87,18c-.61,0-1.08-.66-1.08-1.51s.47-1.51,1.08-1.51S28,15.61,28,16.45,27.47,18,26.87,18Zm0-2.39c-.22,0-.46.38-.46.88s.24.88.46.88.45-.37.45-.88S27.08,15.57,26.87,15.57Z" />
      <Path fill="#000" d="M26.87,18c-.61,0-1.08-.66-1.08-1.51s.47-1.51,1.08-1.51S28,15.61,28,16.45,27.47,18,26.87,18Zm0-2.39c-.22,0-.46.38-.46.88s.24.88.46.88.45-.37.45-.88S27.08,15.57,26.87,15.57Z" />
    </Svg>
  );
};

export default LoanDetailIcon;
