import React, { Fragment } from 'react';
import T from 'prop-types';

import {
  MESSAGES_TABLE_EMPTY_HEADING,
  MESSAGES_TABLE_EMPTY_MESSAGE,
  MESSAGE_TYPE_CLAIM,
  MESSAGE_TYPE_JIRA,
} from '@dmi/shared/redux/LossDraft/constants';

import { ConditionalRender } from '../../base_ui';
import MessagesTable from '../../MessagesTable';
import IllustrationCard from './IllustrationCard';
import { storeReadClaimNumber } from './helpers';
import { ButtonWrapper, StyledLargePrimaryButton } from './styledComponents';

const MessagesComponent = ({
  dispatchChangeSelectedClaimNumber,
  dispatchChangeSelectedMessageId,
  dispatchUpdateClaimNumbersRead,
  fetchMessagesServiceError,
  messages,
  navigation,
  requestStatusFetchMessages,
}) => {
  const handleOnPressMessagesTableRow = ({ messageId, messageType = MESSAGE_TYPE_JIRA }) => {
    if (messageType === MESSAGE_TYPE_CLAIM) {
      const claimNumber = messageId;
      storeReadClaimNumber(claimNumber);
      dispatchUpdateClaimNumbersRead({ claimNumber });
      dispatchChangeSelectedClaimNumber({ claimNumber });
      navigation.navigate('LossDraftClaimDetails');
    } else {
      dispatchChangeSelectedMessageId({ messageId });
      navigation.navigate('LossDraftMessageDetails');
    }
  };

  return (
    <Fragment>
      <ConditionalRender
        Component={<IllustrationCard />}
        shouldRender={!!messages.length}
      />
      <MessagesTable
        emptyHeading={MESSAGES_TABLE_EMPTY_HEADING}
        emptyMessage={MESSAGES_TABLE_EMPTY_MESSAGE}
        errorMessage={fetchMessagesServiceError || ''}
        messages={messages}
        onPressTableRow={handleOnPressMessagesTableRow}
        requestStatusFetchMessages={requestStatusFetchMessages}
      />
      <ButtonWrapper>
        <StyledLargePrimaryButton
          label="Send New Message"
          onPress={() => navigation.navigate('LossDraftNewMessage')}
        />
      </ButtonWrapper>
    </Fragment>
  );
};

MessagesComponent.propTypes = {
  dispatchChangeSelectedClaimNumber: T.func.isRequired,
  dispatchChangeSelectedMessageId: T.func.isRequired,
  dispatchUpdateClaimNumbersRead: T.func.isRequired,
  fetchMessagesServiceError: T.oneOfType([T.bool, T.string]).isRequired,
  messages: T.array.isRequired,
  navigation: T.object.isRequired,
  requestStatusFetchMessages: T.string.isRequired,
};

export default MessagesComponent;
