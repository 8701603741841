import React from 'react';

import iconDictionary from '../../../../../utils/iconDictionary';
import { StyledMessage } from './styledComponents';

const key = iconDictionary('key');

const HomeHistoryEmptySet = () => (
  <StyledMessage
    body="Please check again after a payment has been made."
    bodySize="small"
    icon={key}
    title="No Payment History."
  />
);

export default HomeHistoryEmptySet;
