import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { registerVerify } from '@dmi/shared/redux/Register/Main/actions';
import {
  getRegisterConfirmationPageProps,
  getRegisterQueryString,
  getRegisterStatusProp,
} from '@dmi/shared/redux/Register/Main/selectors';

import { VERIFY_CONFIRMATION_CARD_PROPS } from './constants';
import AsyncRender from '../../../components/AsyncRender';
import LoadingDog from '../../../components/LoadingDog';
import Confirmation from '../../../components/Register/Confirmation';

const RegisterVerifyView = ({
  confirmationProps,
  dispatchRegisterVerify,
  navigation,
  queryStringValue,
  status,
}) => {
  useEffect(() => {
    dispatchRegisterVerify({ queryStringValue });
  }, [dispatchRegisterVerify, queryStringValue]);

  const propsToPassDown = {
    handlePress: () => navigation.replace('Login'),
    viewCardProps: VERIFY_CONFIRMATION_CARD_PROPS,
    ...confirmationProps,
  };

  return (
    <AsyncRender
      Component={Confirmation}
      error={false}
      loading={status === 'idle' || status === 'loading'}
      LoadingComponent={LoadingDog}
      propsToPassDown={propsToPassDown}
    />
  );
};

RegisterVerifyView.propTypes = {
  confirmationProps: T.object.isRequired,
  dispatchRegisterVerify: T.func.isRequired,
  navigation: T.object.isRequired,
  queryStringValue: T.string.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  confirmationProps: getRegisterConfirmationPageProps(),
  queryStringValue: getRegisterQueryString(),
  status: getRegisterStatusProp('verify'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchRegisterVerify: (payload) => dispatch(registerVerify(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RegisterVerifyView);
