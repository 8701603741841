import React from 'react';
import T from 'prop-types';

import Layout from '../../../../../constants/Layout';
import iconDictionary from '../../../../../utils/iconDictionary';
import { HeaderContainer } from '../styledComponents';
import { HomeTextContainer } from './styledComponents';
import viewDictionary from './viewDictionary';

const { isTablet } = Layout;
const HouseIllustration = iconDictionary('mortgageHouse');

const HeaderSection = ({
  dispatchUpdateSelectedInsurance,
  expiredPolicies,
  headerView,
  navigation,
}) => {
  const ViewToRender = viewDictionary[headerView];
  const propsToPassDown = {
    dispatchUpdateSelectedInsurance,
    expiredPolicies,
    navigation,
  };

  return (
    <HeaderContainer $center={isTablet}>
      {HouseIllustration}
      <HomeTextContainer>
        <ViewToRender {...propsToPassDown} />
      </HomeTextContainer>
    </HeaderContainer>
  );
};
HeaderSection.propTypes = {
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  expiredPolicies: T.array.isRequired,
  headerView: T.string.isRequired,
  navigation: T.object.isRequired,
};

export default HeaderSection;
