import styled from 'styled-components/native';

import { H2, P2 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const Container = styled.View`
  align-items: center;
  background-color: #fff;
  flex: 1;
  padding: ${moderateScale(10)}px;
`;

export const IllustrationWrapper = styled.View`
  margin-top:${moderateScale(83)}px;
`;

export const Title = styled(H2)`
  font-family: ${({ theme: { fontFamilies: { primaryFont } } }) => primaryFont};
  padding: ${moderateScale(24)}px 0 ${moderateScale(8)}px;
  text-align: center;
  width: ${moderateScale(287)}px;
`;

export const AdditionalCopy = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  text-align: center;
  width: ${moderateScale(287)}px;
`;
