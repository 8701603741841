/* eslint-disable max-len */
import React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

const CalendarLg = (props) => (
  <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
    <G>
      <Rect fill="white" height="24" width="24" />
      <G>
        <Path d="M3 5H21V21H3V5Z" stroke="#282828" strokeLinejoin="round" />
        <Path d="M21 9H3" stroke="#282828" strokeLinecap="round" />
        <Path d="M7 5V3" stroke="#282828" strokeLinecap="round" />
        <Path d="M17 5V3" stroke="#282828" strokeLinecap="round" />
      </G>
    </G>
  </Svg>
);

export default CalendarLg;
