import React from 'react';
import T from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import { Animation } from '../../base_ui';
import {
  AddLoanContainer,
  ConfirmationContent,
  ConfirmationStatusBanner,
  StyledH2,
} from './styledComponents';

const ConfirmationComponent = ({ bannerError }) => {
  const { formatMessage, messages } = useIntl();
  const { subheader } = LOANS_CONTENT_DICTIONARY.addLoan.confirmationView.mobile;

  return (
    <AddLoanContainer>
      <Animation name="robotMobile" />
      <ConfirmationContent>
        <StyledH2>
          <FormattedMessage id={subheader} />
        </StyledH2>
        <ConfirmationStatusBanner
          error={resolveIntlMessage({
            formatMessage,
            message: bannerError,
            messages,
          })}
          isPartialError
        />
      </ConfirmationContent>
    </AddLoanContainer>
  );
};

ConfirmationComponent.propTypes = { bannerError: T.oneOfType([T.bool, T.string]).isRequired };

export default ConfirmationComponent;
