import styled from 'styled-components/native';

import {
  BorderlessButton,
  MediumPrimaryButton,
  MediumProgressButton,
} from '../../../base_ui';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const ScheduleButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { primary },
    fontFamilies: { regular },
  },
  titleStyle,
}) => ({
  titleStyle: [
    titleStyle,
    {
      color: primary,
      fontFamily: regular,
      lineHeight: moderateScale(22),
      paddingHorizontal: moderateScale(6),
      paddingLeft: moderateScale(6),
    },
  ],
}))``;

export const StyledCalculatorFooterWrapper = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  display: flex;
  flex-direction: row;
  height: ${moderateScale(36)}px;
  justify-content: space-around;
  width: 100%;
`;

export const StyledFooterSummaryWrapper = styled(StyledCalculatorFooterWrapper)`
  align-items: center;
  height: ${moderateScale(90)}px;
  justify-content: space-between;
  padding:
    ${moderateScale(19)}px ${moderateScale(15)}px ${moderateScale(19)}px ${moderateScale(24)}px;
`;

export const StyledPrimaryButton = styled(MediumPrimaryButton)`
  height: ${moderateScale(39)}px;
  width: ${moderateScale(173)}px;
`;

export const StyledProgressButton = styled(MediumProgressButton)`
  height: ${moderateScale(39)}px;
  width: ${moderateScale(173)}px;
`;

export const TextWrapper = styled.View`
  flex-shrink: 1;
  padding-right: ${moderateScale(6)}px;
`;
