/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
import styled from 'styled-components/native';

import { SecondaryHeader } from '../ScreenHeaders';
import { H2, H3, MediumPrimaryButton, P2 } from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

// Payoff Retrieving Statement Components

export const RetrievingAnimationContainer = styled.View`
  justify-content: center;
  margin: ${moderateScale(40)}px auto ${moderateScale(38)}px;
  width: ${moderateScale(80)}px;
`;

export const RetrievingStatementContainer = styled.ScrollView`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: 100%;
`;

export const StyledRetrievingHeader = styled(H3)`
  margin: 0 ${moderateScale(24)}px;
  text-align: center;
`;

export const StyledRetrievingText = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(90)}px;
  padding: 0 ${moderateScale(24)}px;
  text-align: center;
`;

// Payoff Top Card Components

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const IllustrationContainer = styled.View`
  margin-bottom: ${moderateScale(16)}px;
  ${({ $isLoanMancodeY }) => $isLoanMancodeY ? `margin-top: ${moderateScale(41)}px;` : null}
`;

export const PayoffHomeTopContainer = styled.View`
  align-items: center;
  padding:  0 ${moderateScale(24)}px ${moderateScale(36)}px;
  width: 100%;
  ${({ $isLoanMancodeY }) => $isLoanMancodeY ? `margin-bottom: ${moderateScale(84)}px;` : null}
`;

export const StyledH2 = styled(H2)`
  text-align: center;
  ${({ $isLoanMancodeY }) => $isLoanMancodeY ? `margin: 0 ${moderateScale(24)}px 0;` : null}
`;

export const StyledP2 = styled(P2)`
  text-align: center;
  margin: ${moderateScale(8)}px ${({ $isLoanMancodeY }) => $isLoanMancodeY ? `${moderateScale(24)}px;` : 0} ${moderateScale(24)}px;
`;

export const StyledPhoneLinkText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

// Payoff Select Date Screen Components

export const BottomContainer = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  display: flex;
  flex-direction: row;
  height: ${moderateScale(79)}px;
  justify-content: space-between;
  padding:
    ${moderateScale(19)}px ${moderateScale(15)}px ${moderateScale(19)}px ${moderateScale(24)}px;
`;

export const BottomContainerText = styled.View`
  flex: 1;
`;

export const CalendarContainer = styled.View`
  flex-grow: 1;
`;

export const CircleContainer = styled.View`
  flex-direction: row;
  margin: ${moderateScale(30)}px ${moderateScale(20)}px;
`;

export const ClosedCircle = styled.View`
  background-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: 50px;
  height: ${moderateScale(16)}px;
  margin-right: ${moderateScale(5)}px;
  width: ${moderateScale(16)}px;
`;

export const StyledPrimaryButton = styled(MediumPrimaryButton)`
  height: ${moderateScale(39)}px;
  width: ${moderateScale(173)}px;
`;

// Payoff Loading Components

export const BottomLoadingContainer = styled.View`
  padding-bottom: ${moderateScale(40)}px;
  padding-left: ${moderateScale(24)}px;
`;

export const DatePickerLoadingContainer = styled.View`
  align-items: center;
  border: 1px solid ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  display: flex;
  flex-direction: row;
  height: ${moderateScale(29)}px;
  width: ${moderateScale(229)}px;
`;

export const PastPayoffDetailLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: ${moderateScale(38)}px;
`;

export const PastPayoffTitleLoadingContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin: ${moderateScale(48)}px 0 ${moderateScale(18)}px;
`;

export const PayoffLoadingContainer = styled.ScrollView`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height:100%;
`;

export const TopLoadingContainer = styled.View`
  align-items: center;
`;

// View Statement Components

export const StyledSecondaryHeader = styled(SecondaryHeader)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const ViewStatementContainer = styled.View`
  flex-grow: 1;
`;
