import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import { NoPaymentSourceValue, ValueIconWrapper } from './styledComponents';

const Icon = iconDictionary('outlinedWarning');

const ErrorValue = ({ value }) => (
  <ValueIconWrapper>
    {Icon}
    <NoPaymentSourceValue>{value}</NoPaymentSourceValue>
  </ValueIconWrapper>
);

ErrorValue.propTypes = { value: T.string.isRequired };

export default ErrorValue;
