import React, { useCallback } from 'react';
import T from 'prop-types';
import { useFocusEffect } from '@react-navigation/native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  resetFormError,
  resetFormErrors,
  resetFormState,
  updateFormError,
  updateFormValue,
  updateHelocDrawAmount,
  validateRouting,
} from '@dmi/shared/redux/Mortgage/actions';
import { HELOC_DRAW_PROPS_DICTIONARY } from '@dmi/shared/redux/Mortgage/constants';
import makeSelectMortgage, {
  makeSelectMortgageServiceError,
  selectHelocDrawFormProps,
  selectHelocDrawNotifications,
  selectRequestStatus,
} from '@dmi/shared/redux/Mortgage/selectors';

import AsyncRender from '../../../components/AsyncRender';
import { KeyboardAvoidingScrollView } from '../../../components/base_ui';
import { HelocError, HelocForm as HelocFormScreen } from '../../../components/Mortgage/HelocDraw';
import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { StyledView } from '../styledComponents';

export const HelocForm = ({
  dispatchResetFormError,
  dispatchResetFormErrors,
  dispatchResetFormState,
  dispatchUpdateAmount,
  dispatchUpdateFormError,
  dispatchUpdateFormValue,
  dispatchValidateRouting,
  error,
  fetchHelocDrawStatus,
  helocDrawFormProps,
  navigation,
  notifications,
  validateRoutingError,
}) => {
  const { formConstants: { screenHeader } } = helocDrawFormProps;
  const { error: { pageHeading } } = HELOC_DRAW_PROPS_DICTIONARY;

  const headerProps = !error
    ? {
      handleBack: () => {
        navigation.navigate('Mortgage');
        dispatchResetFormState({ form: 'helocDraw' });
      },
      title: screenHeader,
    }
    : { title: pageHeading };

  useFocusEffect(
    useCallback(() => () => {
      dispatchResetFormError({ field: 'amount', form: 'helocDraw' });
    }, [dispatchResetFormError]),
  );

  return (
    <StyledView>
      <SecondaryHeader {...headerProps} />
      <ScreenReaderNotifications notifications={notifications} />
      <KeyboardAvoidingScrollView>
        <AsyncRender
          Component={HelocFormScreen}
          CustomErrorComponent={HelocError}
          error={!!error}
          errorComponentProps={{ navigation }}
          loading={['idle', 'loading'].includes(fetchHelocDrawStatus)}
          propsToPassDown={{
            dispatchResetFormError,
            dispatchResetFormErrors,
            dispatchUpdateAmount,
            dispatchUpdateFormError,
            dispatchUpdateFormValue,
            dispatchValidateRouting,
            navigation,
            validateRoutingError,
            ...helocDrawFormProps,
          }}
        />
      </KeyboardAvoidingScrollView>
    </StyledView>
  );
};

HelocForm.propTypes = {
  dispatchResetFormError: T.func.isRequired,
  dispatchResetFormErrors: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchUpdateAmount: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  dispatchUpdateFormValue: T.func.isRequired,
  dispatchValidateRouting: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]),
  fetchHelocDrawStatus: T.string.isRequired,
  helocDrawFormProps: T.object.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  validateRoutingError: T.oneOfType([T.bool, T.string]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  // eslint-disable-next-line sort-keys
  error: makeSelectMortgageServiceError('helocDraw'),
  fetchHelocDrawStatus: selectRequestStatus('fetchHelocDraw'),
  helocDrawFormProps: selectHelocDrawFormProps(),
  notifications: selectHelocDrawNotifications(),
  validateRoutingError: makeSelectMortgage('validateRoutingError'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mortgage
   */
  dispatchResetFormError: (payload) => dispatch(resetFormError(payload)),
  dispatchResetFormErrors: (payload) => dispatch(resetFormErrors(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchUpdateAmount: (payload) => dispatch(updateHelocDrawAmount(payload)),
  dispatchUpdateFormError: (payload) => dispatch(updateFormError(payload)),
  dispatchUpdateFormValue: (payload) => dispatch(updateFormValue(payload)),
  dispatchValidateRouting: (payload) => dispatch(validateRouting(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HelocForm);
