/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polygon,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterMagnify(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(86)} viewBox="0 0 86 86" width={moderateScale(86)} {...props}>
      <Polygon className="cls-1" fill="#bac2ca" points="51.18 54.57 54.11 51.59 52.25 49.85 49.64 53.03 51.18 54.57" />
      <Path className="cls-2" d="M43.53,9.94a26,26,0,1,0,2.6,1.45" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Circle className="cls-3" cx="32.27" cy="33.27" fill="#e6ebef" r="21.39" />
      <Path className="cls-1" d="M40.18,20.24c9.86,6.43,11.25,16.55,11,23.06A21.38,21.38,0,0,0,13.8,22.49a18.8,18.8,0,0,0-1,2C17.79,19.35,28.29,12.49,40.18,20.24Z" fill="#bac2ca" />
      <Path className="cls-4" d="M25.07,53.42a21.36,21.36,0,1,0-2.51-1.08" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-4" d="M38.42,50.4a18.38,18.38,0,0,0,6-3.57" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-4" d="M14.11,32.18A18.18,18.18,0,0,0,30.48,51.37a18,18,0,0,0,5.6-.3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-4" d="M15.35,26.57a17.68,17.68,0,0,0-1,3.47" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M64.4,55.4h7.87a0,0,0,0,1,0,0V78.27a3.93,3.93,0,0,1-3.93,3.93h0a3.93,3.93,0,0,1-3.93-3.93V55.4A0,0,0,0,1,64.4,55.4Z" fill={svgHighlight} transform="translate(-28.63 68.47) rotate(-45)" />
      <Path className="cls-6" d="M78.64,77c-2.72.78-5.19-1.74-5.19-1.74L58.39,59.79,56.08,62.1,72.25,78.27a3.92,3.92,0,0,0,5.56,0A3.75,3.75,0,0,0,78.64,77Z" fill={svgHighlight} />
      <Path className="cls-2" d="M68.25,74.28,56.08,62.1l5.56-5.56L77.81,72.71a3.94,3.94,0,0,1,0,5.56h0a3.92,3.92,0,0,1-5.56,0l-1.92-1.91" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line className="cls-2" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="52.29" x2="58.81" y1="49.67" y2="56.15" />
      <Line className="cls-2" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="49.16" x2="57.23" y1="52.93" y2="60.94" />
      <Line className="cls-4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="40.47" x2="40.47" y1="68.18" y2="73.93" />
      <Line className="cls-4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="43.35" x2="37.6" y1="71.05" y2="71.05" />
      <Path className="cls-4" d="M67.55,31.47a1.59,1.59,0,1,0,1.27.62" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Polygon className="cls-4" fill="none" points="72.47 54.07 69.21 50.8 72.47 50.8 72.47 54.07" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
    </Svg>
  );
}
