import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H2, H4, LargePrimaryButton } from '../../base_ui';

export const Container = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: 100%;
`;

export const Card = styled.View`
  align-items: center;
  padding: 0 ${moderateScale(40)}px;
  text-align: center;
`;

export const IllustrationWrapper = styled.View`
  height: ${moderateScale(120)}px;
  width: ${moderateScale(287)}px;
`;

export const StyledButton = styled(LargePrimaryButton)`
  margin: ${moderateScale(24)}px 0 ${moderateScale(32)}px;
`;

export const StyledNoFeeCopy = styled(H4)`
  margin-bottom: ${moderateScale(24)}px;
  text-align: center;
`;

export const TopHeader = styled(H2)`
  text-align: center;
`;

export const BottomHeader = styled(TopHeader)`
  margin-top: ${moderateScale(32)}px;
`;

export const ListItem = styled.View`
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  margin-top: ${moderateScale(16)}px;
  text-align: left;
`;
