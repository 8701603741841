import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../base_ui';
import DropDown from './DropDown';
import Default from './Default';

const RowButton = ({ isDropDown = false, ...restProps }) => (
  <ConditionalRender
    Component={DropDown}
    FallbackComponent={Default}
    propsToPassDown={restProps}
    shouldRender={isDropDown}
  />
);

RowButton.propTypes = { isDropDown: T.bool };

export default RowButton;
