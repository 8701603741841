/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function SelectLoanFall(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      fill="none"
      height={moderateScale(150)}
      viewBox="0 0 327 150"
      width={moderateScale(327)}
      xmlns="http://www.w3.org/2000/svg"
      y="0px"
      {...props}
    >
      <Path
        className="st0"
        d="M89.1,61c2.1,0,3.6-2.2,2.8-4.1c-1.7-4.2-5.2-10.1-12-10.8c-2.9-0.3-5.5,0.8-7.6,2.2c-0.2,0.1-0.4,0.1-0.6-0.1
	c-1.4-1.3-3.5-2.2-5.8-2.2c-4.3,0-7.9,3-7.9,6.6l0,0c0,0.3-0.2,0.5-0.5,0.5c-0.2,0-0.4,0-0.6,0c-2.7,0-4.9,1.5-5.7,3.5
	c-0.1,0.3-0.4,0.4-0.6,0.3c-0.7-0.3-1.5-0.5-2.4-0.5c-2,0.1-3.2,1.1-4,2.2c-0.7,1,0,2.4,1.2,2.4L89.1,61z"
        fill="#DAE1E8"
      />
      <Rect className="st1" fill="#E7EBEE" height="2.3" width="7.8" x="109.1" y="38.8" />
      <Path
        className="st2"
        d="M117.5,36.6v2.1l-0.5,0.1v25.5l4.7-5.1V38.7c0,0,0.7-0.5,0.6-0.7c0-0.2-0.2-1.2-0.2-1.2l-0.3-0.3L117.5,36.6z"
        fill="#BAC2CA"
      />
      <Path
        className="st3"
        d="M117,64.7h-8c-0.2,0-0.4-0.2-0.4-0.4V38.8c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v25h7.5l4.6-4.8V38.9
	c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v20.4c0,0.1,0,0.2-0.1,0.3l-4.8,5C117.2,64.6,117.1,64.7,117,64.7z"
        fill="#010101"
      />
      <Path className="st3" d="M117,58c-0.2,0-0.3-0.1-0.3-0.3v-19c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3v19C117.3,57.9,117.1,58,117,58z" fill="#010101" />
      <Path
        className="st3"
        d="M121.7,39.1h-13c-0.6,0-1.2-0.5-1.2-1.2v-0.7c0-0.6,0.5-1.2,1.2-1.2h13c0.6,0,1.2,0.5,1.2,1.2v0.7
	C122.8,38.5,122.3,39.1,121.7,39.1z M108.7,36.8c-0.2,0-0.3,0.1-0.3,0.3v0.7c0,0.2,0.1,0.3,0.3,0.3h13c0.2,0,0.3-0.1,0.3-0.3v-0.7
	c0-0.2-0.1-0.3-0.3-0.3H108.7z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M117.5,38.7c-0.2,0-0.3-0.1-0.3-0.3v-1.9c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3v1.9
	C117.8,38.6,117.6,38.7,117.5,38.7z"
        fill="#010101"
      />
      <Polygon className="st4" fill="#FFFFFF" points="189.1,143.8 189.1,72 141.7,35.8 98.1,70 98.1,143.9 " />
      <Rect className="st1" fill="#E7EBEE" height="71" width="27.6" x="188" y="72.7" />
      <Path className="st2" d="M192.6,81.2H216v-2.1l-24-0.3l-3.8-3.6l-0.1,2.9l2.5,2.3C191.2,80.9,191.9,81.2,192.6,81.2z" fill="#BAC2CA" />
      <Path
        className="st3"
        d="M215.4,143.7c-0.2,0-0.4-0.2-0.4-0.4V69.6c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v73.7
	C215.8,143.6,215.6,143.7,215.4,143.7z"
        fill="#010101"
      />
      <Path className="st1" d="M223,78.9h-28v-3.6h28c0.9,0,1.6,0.7,1.6,1.6v0.3C224.6,78.2,223.9,78.9,223,78.9z" fill="#E7EBEE" />
      <Path className="st3" d="M223.1,79.3h-30c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h30c0.2,0,0.4,0.2,0.4,0.4S223.3,79.3,223.1,79.3z" fill="#010101" />
      <Path
        className="st3"
        d="M222.7,79.2h-2.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h2.5c0.8,0,1.5-0.6,1.5-1.3s-0.7-1.3-1.5-1.3h-2.5
	c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h2.5c1.3,0,2.3,1,2.3,2.1S223.9,79.2,222.7,79.2z"
        fill="#010101"
      />
      <G>
        <Polygon className="st2" fill="#BAC2CA" points="223,74.8 171,34 142.1,34 194.7,75.4 190,75.4 	" />
        <Path
          className="st4"
          d="M142.8,34.8c-0.4-0.4-0.9-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.4,0.6L89.5,75.9c-0.7,0.7-0.6,1.9,0.2,2.5
		c0.8,0.6,2,0.5,2.7-0.2l49-39.6l50.5,39.6c0.4,0.4,0.9,0.6,1.4,0.6c0.5,0,0.9-0.2,1.3-0.5c0.8-0.7,0.8-1.8,0.1-2.5L142.8,34.8z"
          fill="#FFFFFF"
        />
        <Polygon className="st5" display="none" fill="#FFFFFF" points="141.3,38.7 172,69.7 174.4,107.3 109.6,107.3 109.6,79 104.2,79 	" />
        <Polygon className="st2" fill="#BAC2CA" points="97.5,73.3 97.5,79 92.2,79.1 	" />
        <Path className="st3" d="M97.4,79.3h-5.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h5.4c0.2,0,0.4,0.2,0.4,0.4S97.7,79.3,97.4,79.3z" fill="#010101" />
        <Polygon className="st2" fill="#BAC2CA" points="143.4,40.6 97.9,77.1 97.9,73.7 141.4,39 	" />
        <Path
          className="st3"
          d="M223.4,75.8h-28.7c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h27.5l-51.4-40.6h-12.2c-0.2,0-0.4-0.2-0.4-0.4
		s0.2-0.4,0.4-0.4H171c0.1,0,0.2,0,0.3,0.1l52.5,41.4c0.1,0.1,0.2,0.3,0.1,0.5C223.8,75.7,223.6,75.8,223.4,75.8z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M155.9,34.4h-1.8c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h1.8c0.2,0,0.4,0.2,0.4,0.4S156.2,34.4,155.9,34.4z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M141.3,34.5c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4l10.3-0.1c0,0,0,0,0,0c0.2,0,0.4,0.2,0.4,0.4
		c0,0.2-0.2,0.4-0.4,0.4L141.3,34.5C141.3,34.5,141.3,34.5,141.3,34.5z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M91,79.3c-0.5,0-1.1-0.2-1.5-0.5c-0.5-0.4-0.8-0.9-0.8-1.5c0-0.6,0.2-1.1,0.6-1.6l50.5-41.2
		c0.4-0.4,1-0.7,1.7-0.7c0.7,0,1.3,0.2,1.7,0.7l52,41.1c0.5,0.5,0.7,1,0.7,1.6c0,0.6-0.3,1.1-0.8,1.5c-0.9,0.8-2.4,0.7-3.3-0.1
		l-50.2-39.4L92.7,78.6C92.3,79,91.6,79.3,91,79.3z M141.4,34.6C141.4,34.6,141.4,34.6,141.4,34.6c-0.5,0-0.9,0.2-1.2,0.5L89.8,76.3
		c-0.2,0.2-0.3,0.6-0.3,0.9c0,0.4,0.2,0.7,0.5,0.9c0.6,0.5,1.6,0.4,2.2-0.2l49.1-39.6c0.1-0.1,0.4-0.1,0.5,0l50.5,39.6
		c0.6,0.6,1.6,0.6,2.2,0.1c0.3-0.2,0.5-0.6,0.5-0.9c0-0.4-0.1-0.7-0.4-1l-51.9-41c0,0,0,0,0,0C142.2,34.8,141.8,34.6,141.4,34.6z"
          fill="#010101"
        />
      </G>
      <Path
        className="st4"
        d="M97.3,126.1c-0.8-0.1-1.7-0.2-2.6-0.2c-5.3,0-9.9,2.6-12.8,6.5c-0.6,0.8-1.6,1.1-2.5,0.8
	c-1.1-0.4-2.3-0.6-3.6-0.6c-3.5,0-6.6,1.6-8.7,4.2c0,0-2.2,2.9-2.4,6.7h32.8L97.3,126.1z"
        fill="#FFFFFF"
      />
      <Path
        className="st6"
        d="M68.1,135.7c0,0,3.4-2.8,8.9-1.5c5.5,1.3,6.1-0.1,7.8-2s8-4.8,12.8-3.4v-2.6c0,0-5.6-1.2-10.2,1.5
	c-4.7,2.8-5.3,4.9-6.1,5.2c-0.7,0.3-1,0.5-3.3-0.2s-7.3,0.1-10,3.1"
        fill="#DCE2E7"
      />
      <Path
        className="st3"
        d="M64.4,143.9C64.4,143.9,64.4,143.9,64.4,143.9c-0.2,0-0.4-0.2-0.4-0.4c0.1-3.2,2.6-6.8,2.7-7
	c2.2-2.8,5.5-4.3,9-4.3c1.3,0,2.5,0.2,3.7,0.6c0.8,0.3,1.6,0,2.1-0.6c3.1-4.2,8-6.7,13.2-6.7c0.9,0,1.8,0.1,2.7,0.2
	c0.2,0,0.4,0.2,0.3,0.5c0,0.2-0.2,0.4-0.5,0.3c-0.8-0.1-1.7-0.2-2.5-0.2c-4.9,0-9.6,2.4-12.5,6.3c-0.7,0.9-1.9,1.3-3,0.9
	c-1.1-0.4-2.3-0.6-3.5-0.6c-3.3,0-6.3,1.5-8.4,4c0,0-2.5,3.5-2.6,6.5C64.8,143.7,64.6,143.9,64.4,143.9z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M97.6,131.6c-0.2,0-0.4-0.2-0.4-0.4v-29.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v29.4
	C98,131.4,97.8,131.6,97.6,131.6z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M97.6,99.1c-0.2,0-0.4-0.2-0.4-0.4V74.4c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v24.3C98,99,97.8,99.1,97.6,99.1
	z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M187.9,143.8c-0.2,0-0.4-0.2-0.4-0.4V75.1c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v68.3
	C188.3,143.6,188.1,143.8,187.9,143.8z"
        fill="#010101"
      />
      <Path
        className="st6"
        d="M93.3,133.7c0,0,6.1-1.5,9.4,0.3c3.3,1.8,4,4.4,5.7,5.2c1.7,0.8,3.2,0.5,4.9-0.6c1.6-1.1,7.5-0.6,8.8,3.8
	c0,0-1.2-6.3-6.9-5.9c-5.7,0.3-3.7,2.6-6.3-0.6C106.2,132.7,101.1,128.4,93.3,133.7z"
        fill="#DCE2E7"
      />
      <Path
        className="st3"
        d="M122.3,144.2c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.4c0-1.9-0.6-3.6-1.9-4.9c-1.2-1.3-2.9-2-4.6-2
	c-1.3,0-2.6,0.4-3.6,1.2c-0.4,0.3-0.9,0.4-1.4,0.2c-0.5-0.2-1-0.6-1.2-1.1c-1.3-3.3-5-5.5-9.2-5.5c-3.2,0-6.2,1.4-8.1,3.6
	c-0.7,0.8-1.7,1.2-2.7,0.8c-0.9-0.3-1.9-0.4-2.8-0.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c1.1,0,2.1,0.2,3.1,0.5
	c0.7,0.2,1.4,0,1.9-0.6c2-2.5,5.2-3.9,8.7-3.9c4.5,0,8.5,2.4,9.9,6c0.1,0.3,0.4,0.5,0.6,0.6c0.3,0.1,0.5,0,0.7-0.1
	c1.2-0.9,2.6-1.3,4.1-1.3c2,0,3.8,0.8,5.2,2.2c1.4,1.5,2.2,3.5,2.1,5.6c0,0.1,0,0.2-0.1,0.3C122.6,144.1,122.5,144.2,122.3,144.2z"
        fill="#010101"
      />
      <G>
        <Path className="st4" d="M86.4,135.2c-0.5,0-1,0-1.4,0.1" fill="#FFFFFF" />
        <Path
          className="st3"
          d="M85,135.7c-0.2,0-0.4-0.1-0.4-0.4c0-0.2,0.1-0.4,0.3-0.5c0.5-0.1,1-0.1,1.5-0.1c0.2,0,0.4,0.2,0.4,0.4
		s-0.2,0.4-0.4,0.4C86,135.6,85.5,135.6,85,135.7C85,135.7,85,135.7,85,135.7z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M183.9,77.3h-83.2c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h83.2c0.2,0,0.3,0.1,0.3,0.3S184.1,77.3,183.9,77.3z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M186.4,74.4h-22.5c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h22.5c0.2,0,0.3,0.1,0.3,0.3S186.5,74.4,186.4,74.4z"
        fill="#010101"
      />
      <Path className="st3" d="M161,74.4h-55.8c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3H161c0.2,0,0.3,0.1,0.3,0.3S161.2,74.4,161,74.4z" fill="#010101" />
      <Path
        className="st1"
        d="M197.4,45c-1.8,0-2.8-2.1-1.6-3.5c2-2.3,6-3.9,10.6-3.9c0.7,0,1.5,0,2.2,0.1c0.8,0.1,1.5-0.5,1.4-1.3l0,0
	c0-4,5.1-7.2,11.3-7.2c1.7,0,3.4,0.3,4.9,0.7c0.7,0.2,1.4-0.2,1.6-0.9c1.3-5.9,7.7-10.4,15.3-10.4c8.6,0,15.6,5.7,15.6,12.7
	c0,0.3,0,0.5,0,0.7c0.4-0.1,0.7-0.1,1.1-0.1c3.2,0,5.9,2.1,6.9,5c0.2,0.7,0.9,1,1.6,0.8c0.6-0.2,1.3-0.3,2-0.3
	c2.3,0,4.3,1.1,5.7,2.7c1.5,1.9,0.1,4.8-2.3,4.8H197.4z"
        fill="#E7EBEE"
      />
      <Path
        className="st3"
        d="M77.4,144.4c-0.2,0-0.4-0.2-0.4-0.4v-27.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V144
	C77.9,144.2,77.7,144.4,77.4,144.4z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M273.5,144.3h-2.7c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h2.7c0.2,0,0.4,0.2,0.4,0.4S273.7,144.3,273.5,144.3z
	"
        fill="#010101"
      />
      <Path className="st3" d="M268,144.3H31c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h237c0.2,0,0.4,0.2,0.4,0.4S268.2,144.3,268,144.3z" fill="#010101" />
      <Ellipse className="st7" cx="142.4" cy="59.8" fill="#F0F1F0" rx="4.5" ry="5.3" />
      <Path
        className="st3"
        d="M141.6,66c-3.4,0-6.2-2.8-6.2-6.3s2.8-6.3,6.2-6.3c3.4,0,6.2,2.8,6.2,6.3S145,66,141.6,66z M141.6,54.2
	c-3,0-5.4,2.5-5.4,5.5s2.4,5.5,5.4,5.5c3,0,5.4-2.5,5.4-5.5S144.5,54.2,141.6,54.2z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M142.4,65.8C142.4,65.8,142.3,65.8,142.4,65.8c-3-0.6-5.1-3.2-5.1-6.1c0-2.1,1.1-4,2.8-5.1
	c0.1-0.1,0.3,0,0.4,0.1c0.1,0.1,0,0.3-0.1,0.4c-1.6,1.1-2.6,2.8-2.6,4.7c0,2.7,2,5,4.6,5.5c0.1,0,0.2,0.2,0.2,0.3
	C142.6,65.7,142.5,65.8,142.4,65.8z"
        fill="#010101"
      />
      <G>
        <Polyline className="st8" fill={svgHighlight} points="152.7,132.5 152.7,98.3 171.4,98.3 171.4,132.5 	" />
        <Path
          className="st3"
          d="M171.4,132.9c-0.2,0-0.4-0.2-0.4-0.4V98.7h-17.9v33.8c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4V98.3
		c0-0.2,0.2-0.4,0.4-0.4h18.7c0.2,0,0.4,0.2,0.4,0.4v34.2C171.8,132.7,171.7,132.9,171.4,132.9z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M170.8,144h-29.1c-0.2,0-0.4-0.2-0.4-0.4V138c0-0.2,0.2-0.4,0.4-0.4h4.9v-5c0-0.2,0.2-0.4,0.4-0.4h23.8
	c0.2,0,0.4,0.2,0.4,0.4v11C171.2,143.8,171,144,170.8,144z M142.1,143.1h28.3v-10.2h-23v5c0,0.2-0.2,0.4-0.4,0.4h-4.9V143.1z"
        fill="#010101"
      />
      <G>
        <Polygon className="st2" fill="#BAC2CA" points="168.1,132.5 168.1,138 162.8,138 162.8,143.5 168.1,143.5 168.7,143.5 174.7,143.5 174.7,132.5 	" />
        <Path
          className="st3"
          d="M174.7,144h-12c-0.2,0-0.4-0.2-0.4-0.4V138c0-0.2,0.2-0.4,0.4-0.4h4.9v-5c0-0.2,0.2-0.4,0.4-0.4h6.7
		c0.2,0,0.4,0.2,0.4,0.4v11C175.2,143.8,175,144,174.7,144z M163.2,143.1h11.1v-10.2h-5.8v5c0,0.2-0.2,0.4-0.4,0.4h-4.9V143.1z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M142.3,63.7c-0.2,0-0.3-0.1-0.3-0.3v-7.9c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3v7.9
	C142.6,63.6,142.5,63.7,142.3,63.7z"
        fill="#010101"
      />
      <Path className="st3" d="M147.2,59.7h-8.3c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h8.3c0.2,0,0.3,0.1,0.3,0.3S147.3,59.7,147.2,59.7z" fill="#010101" />
      <Rect className="st1" fill="#E7EBEE" height="24.6" width="29.1" x="109.4" y="90.2" />
      <Polygon className="st9" fill="#FFFFFF" opacity="0.7" points="108.1,109.8 115.2,90.2 108.8,90.2 108.1,91.8 " />
      <Polygon className="st9" fill="#FFFFFF" opacity="0.7" points="116.9,114.8 113,114.8 122.2,90.2 126.2,90.2 " />
      <Path
        className="st3"
        d="M138.7,115.4H108c-0.2,0-0.4-0.2-0.4-0.4V88.9c0-0.2,0.2-0.4,0.4-0.4h30.7c0.2,0,0.4,0.2,0.4,0.4V115
	C139.1,115.2,138.9,115.4,138.7,115.4z M108.4,114.6h29.9V89.3h-29.9V114.6z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M109.4,113.7c-0.2,0-0.3-0.1-0.3-0.3V90.2c0-0.2,0.1-0.3,0.3-0.3h29.4c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3
	h-29.2v23C109.6,113.6,109.5,113.7,109.4,113.7z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M118.5,113.4c-0.2,0-0.4-0.2-0.4-0.4v-12.7c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4V113
	C118.9,113.2,118.7,113.4,118.5,113.4z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M118.5,98.5c-0.2,0-0.4-0.2-0.4-0.4v-5.9c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v5.9
	C118.9,98.3,118.7,98.5,118.5,98.5z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M128.2,106.6c-0.2,0-0.4-0.2-0.4-0.4v-14c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v14
	C128.6,106.4,128.4,106.6,128.2,106.6z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M128.2,113.4c-0.2,0-0.4-0.2-0.4-0.4v-4.6c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v4.6
	C128.6,113.2,128.4,113.4,128.2,113.4z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M138.7,102.5h-27.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h27.5c0.2,0,0.4,0.2,0.4,0.4
	S138.9,102.5,138.7,102.5z"
        fill="#010101"
      />
      <G>
        <Path className="st1" d="M164,100.3h-19.1l1.3-2.9c0.2-0.5,0.5-1.1,0.8-1.6c1.4-2.2,3.8-3.6,6.4-4l0,0l17.9-0.1L164,100.3z" fill="#E7EBEE" />
        <Path
          className="st3"
          d="M164,100.7h-19.1c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.3,0-0.4l1.3-2.9c0.2-0.6,0.5-1.1,0.9-1.6
		c1.4-2.2,3.8-3.7,6.6-4.1l18-0.1h0c0.2,0,0.3,0.1,0.4,0.2c0.1,0.1,0,0.3-0.1,0.4l-7.3,8.5C164.3,100.7,164.2,100.7,164,100.7z
		M145.6,99.9h18.3l6.6-7.7l-17,0c-2.5,0.4-4.7,1.8-6,3.8c-0.3,0.5-0.6,1-0.8,1.5L145.6,99.9z"
          fill="#010101"
        />
      </G>
      <G>
        <Path className="st2" d="M171.4,100.3h-8.5l1.3-2.9c0.5-1.1,1.2-2.2,2-3.1c1.2-1.3,2.8-2.1,4.6-2.4l0.6-0.1L171.4,100.3z" fill="#BAC2CA" />
        <Path
          className="st3"
          d="M171.4,100.7h-8.5c-0.1,0-0.3-0.1-0.3-0.2c-0.1-0.1-0.1-0.3,0-0.4l1.3-2.9c0.5-1.2,1.2-2.2,2.1-3.2
		c1.2-1.3,2.9-2.2,4.8-2.5l0.6-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,8.4c0,0.1,0,0.2-0.1,0.3
		C171.6,100.7,171.5,100.7,171.4,100.7z M163.5,99.9h7.4l0-7.5l-0.1,0c-1.7,0.3-3.3,1.1-4.4,2.3c-0.8,0.9-1.4,1.9-1.9,2.9
		L163.5,99.9z"
          fill="#010101"
        />
      </G>
      <G>
        <Path className="st1" d="M137.8,121.6H106c-1.5,0-2.8-1.3-2.8-2.8v-3.8h34.5V121.6z" fill="#E7EBEE" />
        <Path
          className="st3"
          d="M137.8,122H106c-1.8,0-3.2-1.4-3.2-3.2v-3.8c0-0.2,0.2-0.4,0.4-0.4h34.5c0.2,0,0.4,0.2,0.4,0.4v6.6
		C138.2,121.8,138,122,137.8,122z M103.7,115.4v3.4c0,1.3,1.1,2.4,2.4,2.4h31.3v-5.8H103.7z"
          fill="#010101"
        />
      </G>
      <G>
        <Path className="st2" d="M137.6,121.6h-0.5c-1.5,0-2.8-1.3-2.8-2.8v-3.8h6.1v3.8C140.4,120.3,139.1,121.6,137.6,121.6z" fill="#BAC2CA" />
        <Path
          className="st3"
          d="M137.6,122h-0.5c-1.8,0-3.2-1.4-3.2-3.2v-3.8c0-0.2,0.2-0.4,0.4-0.4h6.1c0.2,0,0.4,0.2,0.4,0.4v3.8
		C140.8,120.5,139.3,122,137.6,122z M134.7,115.4v3.4c0,1.3,1.1,2.4,2.4,2.4h0.5c1.3,0,2.4-1.1,2.4-2.4v-3.4H134.7z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M159.6,138.2h-12.4c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h12.4c0.2,0,0.3,0.1,0.3,0.3
	S159.8,138.2,159.6,138.2z"
        fill="#010101"
      />
      <G>
        <Circle className="st4" cx="161.8" cy="106.9" fill="#FFFFFF" r="0.8" />
        <Path
          className="st3"
          d="M161.8,108c-0.6,0-1.1-0.5-1.1-1.1c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1
		C162.9,107.5,162.4,108,161.8,108z M161.8,106.3c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5
		C162.3,106.6,162.1,106.3,161.8,106.3z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M82.9,117h-11c-0.2,0-0.4-0.2-0.4-0.4v-4.7c0-1.5,1.2-2.6,2.6-2.6h8.7c0.2,0,0.4,0.2,0.4,0.4v6.9
	C83.3,116.8,83.1,117,82.9,117z M72.3,116.2h10.1V110h-8.3c-1,0-1.8,0.8-1.8,1.8V116.2z"
        fill="#010101"
      />
      <G>
        <Path className="st2" d="M85,116.6h-4.9v-4.7c0-1.2,1-2.2,2.2-2.2h0.4c1.2,0,2.2,1,2.2,2.2V116.6z" fill="#BAC2CA" />
        <Path
          className="st3"
          d="M85,117h-4.9c-0.2,0-0.4-0.2-0.4-0.4v-4.7c0-1.5,1.2-2.6,2.6-2.6h0.4c1.5,0,2.6,1.2,2.6,2.6v4.7
		C85.4,116.8,85.2,117,85,117z M80.5,116.2h4v-4.3c0-1-0.8-1.8-1.8-1.8h-0.4c-1,0-1.8,0.8-1.8,1.8V116.2z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M77.9,112.8c-0.2,0-0.3-0.1-0.4-0.3l-1.5-4.7l-0.8,0.3c-0.2,0.1-0.4,0-0.5-0.2c-0.1-0.2,0-0.4,0.2-0.5l1.2-0.4
	c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.2l1.6,5.1c0.1,0.2-0.1,0.4-0.3,0.5C77.9,112.8,77.9,112.8,77.9,112.8z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M106.2,139.1c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.5-0.6-1-1-1.4c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4,0
	c0.4,0.5,0.7,1,1,1.5c0.1,0.1,0,0.3-0.1,0.4C106.3,139.1,106.3,139.1,106.2,139.1z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M103.9,136.4c-0.1,0-0.1,0-0.2-0.1c-2.2-1.7-4.7-1.7-4.7-1.7c-0.2,0-0.3-0.1-0.3-0.3s0.2-0.3,0.3-0.3
	c0.1,0,2.7,0.1,5,1.8c0.1,0.1,0.1,0.3,0.1,0.4C104.1,136.3,104,136.4,103.9,136.4z"
        fill="#010101"
      />
      <G>
        <Path
          className="st4"
          d="M153.1,122.7c0,0-1.5,0-4.2,1.6c-2.8,1.6-4.1,5-2.4,7.3c1.7,2.2,4.5,0.8,4.5,0.8l7.4,0.1c0,0,3.2-0.5,3.3-3.2
		c0-2.7-1-6.4-1-6.4L153.1,122.7z"
          fill="#FFFFFF"
        />
        <G>
          <Path
            className="st4"
            d="M162.7,120.9c0.9-3.2,0.2-4.4,0.2-4.4c-2.2,0.1-3.6,1.4-3.6,1.4c-1.5-0.6-3.4,0-3.4,0s-1.3-1.4-3.6-1.4
			c0,0-0.7,1.2,0.2,4.4c0,0-1.3,3.2,1.8,4.4s5.2,0.4,6.7-0.1c1.5-0.5,2.5-2.1,2-3.7L162.7,120.9z"
            fill="#FFFFFF"
          />
          <Path
            className="st3"
            d="M157.5,126.4c-1,0-2.1-0.2-3.4-0.7c-1-0.4-1.7-1-2.1-1.9c-0.5-1.2-0.2-2.5,0-3c-0.9-3.2-0.2-4.5-0.1-4.6
			c0.1-0.1,0.2-0.2,0.4-0.2c1.9,0,3.2,1,3.7,1.4c0.5-0.1,1.9-0.4,3.2,0c0.5-0.4,1.8-1.3,3.7-1.4c0.1,0,0.3,0.1,0.4,0.2
			c0.1,0.1,0.8,1.4-0.1,4.6l0.2,0.5c0.6,1.7-0.4,3.6-2.2,4.2l-0.1,0C160.1,126,159,126.4,157.5,126.4z M152.6,117
			c-0.1,0.4-0.3,1.6,0.3,3.9c0,0.1,0,0.2,0,0.3c0,0-0.5,1.3,0,2.5c0.3,0.6,0.8,1.1,1.6,1.4c2.9,1.1,4.9,0.4,6.3-0.1l0.1,0
			c1.2-0.4,2.2-1.8,1.7-3.2l-0.2-0.7c0-0.1,0-0.2,0-0.3c0.6-2.2,0.4-3.4,0.3-3.9c-1.9,0.1-3,1.3-3,1.3c-0.1,0.1-0.3,0.2-0.4,0.1
			c-1.4-0.5-3.1,0-3.1,0c-0.1,0-0.3,0-0.4-0.1C155.6,118.2,154.4,117.1,152.6,117z"
            fill="#010101"
          />
        </G>
        <Path
          className="st3"
          d="M152.6,121.3c0,0-0.1,0-0.1,0c-0.1-0.1-0.2-0.2-0.1-0.4c0.5-1.1,1.4-1.8,1.8-2.1c-0.7-1-1.8-1.8-1.8-1.8
		c-0.1-0.1-0.1-0.3-0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1c0.1,0,1.3,1,2.1,2.2c0,0.1,0.1,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2
		c0,0-1.2,0.7-1.8,2C152.8,121.2,152.7,121.3,152.6,121.3z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M162.6,121.3c-0.1,0-0.2-0.1-0.2-0.2c-0.6-1.3-1.8-2-1.8-2c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2
		c0.8-1.1,2-2.1,2.1-2.2c0.1-0.1,0.3-0.1,0.4,0.1s0.1,0.3-0.1,0.4c0,0-1.1,0.8-1.8,1.8c0.4,0.3,1.3,1,1.8,2.1c0.1,0.1,0,0.3-0.1,0.4
		C162.7,121.3,162.6,121.3,162.6,121.3z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M158.1,123.6h-0.8c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h0.8c0.2,0,0.3,0.1,0.3,0.3S158.2,123.6,158.1,123.6
		z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M157,124.7C157,124.7,157,124.7,157,124.7c-0.7,0-0.9-0.5-1-0.8c0-0.1,0.1-0.3,0.2-0.3c0.1,0,0.3,0.1,0.3,0.2
		c0,0.1,0.1,0.3,0.4,0.3c0,0,0,0,0,0c0.3,0,0.4-0.8,0.4-0.8c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3
		C157.9,123.9,157.7,124.7,157,124.7z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M158.2,124.7c-0.7,0-0.9-0.8-0.9-1.3c0-0.2,0.1-0.3,0.3-0.3c0.2,0,0.3,0.1,0.3,0.3c0,0,0,0.8,0.4,0.8
		c0,0,0,0,0,0c0.4,0,0.4-0.3,0.4-0.3c0-0.1,0.2-0.3,0.3-0.2c0.1,0,0.3,0.1,0.2,0.3C159.2,124.2,158.9,124.7,158.2,124.7
		C158.3,124.7,158.2,124.7,158.2,124.7z"
          fill="#010101"
        />
        <Circle className="st3" cx="155" cy="122.2" fill="#010101" r="0.3" />
        <Circle className="st3" cx="160.2" cy="122.1" fill="#010101" r="0.3" />
        <Path
          className="st3"
          d="M149.4,133.1c0,0-0.1,0-0.1,0c-1.5,0-2.8-0.6-3.5-1.5c-0.7-0.9-0.8-2.1-0.4-3.5c1.3-4.8,6.5-5.7,6.7-5.7
		c0.2,0,0.4,0.1,0.5,0.3c0,0.2-0.1,0.4-0.3,0.5c0,0-4.8,0.8-6,5.1c-0.3,1.2-0.2,2.1,0.3,2.8c0.5,0.7,1.6,1.1,2.8,1.2
		c1.1,0,1.9-0.3,3.1-1.3l0.3-0.4c0.1-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c-0.2-0.1-0.4-0.1-0.6,0c-1.6,1.5-3.2,1.5-4.2,1.1
		c-1.1-0.5-1.3-1.5-1.2-2c0-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.8,0.7,1.1c0.7,0.3,2,0.2,3.4-1
		c0.5-0.4,1.2-0.4,1.7,0c0.2,0.2,0.4,0.5,0.4,0.9c0,0.3-0.1,0.6-0.3,0.9l-0.4,0.4C151.8,132.5,150.8,133.1,149.4,133.1z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M156.9,132.9c-2.6,0-5.9,0-5.9,0l0-0.8c0,0,3.3,0,5.8,0c0.6,0,1.1,0,1.5,0c0.7,0,1.2-0.6,1.2-1.2
		c0-0.3-0.1-0.6-0.4-0.9c-0.2-0.2-0.5-0.3-0.9-0.4c-1.1,0-2,0-2.6-0.1c-0.2,0-0.4-0.2-0.3-0.5c0-0.2,0.2-0.4,0.5-0.3
		c0.5,0.1,1.4,0.1,2.5,0.1c0.6,0,1.1,0.2,1.4,0.6c0.4,0.4,0.6,0.9,0.6,1.5c0,1.1-0.9,2-2,2.1C158.1,132.9,157.6,132.9,156.9,132.9z"
          fill="#010101"
        />
        <Path
          className="st3"
          d="M159.1,132.7c-0.2,0-0.4-0.1-0.4-0.4c0-0.2,0.1-0.4,0.4-0.5c0.7-0.1,1.2-0.4,1.6-0.9c1.3-1.8,0.3-5.6,0.3-5.7
		c-0.1-0.2,0.1-0.4,0.3-0.5c0.2-0.1,0.4,0.1,0.5,0.3c0,0.2,1,4.2-0.5,6.3C160.8,132.2,160,132.6,159.1,132.7
		C159.1,132.7,159.1,132.7,159.1,132.7z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          className="st4"
          d="M220.7,63.6c0-0.6-0.4-1-1-1.1c-0.1-0.6-0.5-1-1.1-1c-0.6,0-1,0.4-1.1,1c-0.6,0-1.1,0.5-1.1,1.1
		c0,0.6,0.5,1.1,1.1,1.1c0.1,0.5,0.6,0.8,1.1,0.8c0.5,0,1-0.4,1.1-0.8C220.3,64.7,220.7,64.2,220.7,63.6z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          className="st4"
          d="M228.8,80.4c0-0.9-0.6-1.5-1.4-1.7c-0.1-0.8-0.8-1.5-1.7-1.5c-0.9,0-1.6,0.6-1.7,1.5c-0.9,0-1.6,0.8-1.6,1.7
		c0,0.9,0.7,1.7,1.7,1.7c0.2,0.7,0.8,1.2,1.6,1.2c0.8,0,1.4-0.5,1.6-1.2C228.2,82,228.8,81.3,228.8,80.4z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          className="st4"
          d="M226.5,105.5c0-0.6-0.5-1.1-1.1-1.2c-0.1-0.6-0.6-1.1-1.2-1.1c-0.6,0-1.1,0.5-1.2,1.1c-0.7,0-1.2,0.6-1.2,1.2
		c0,0.7,0.5,1.2,1.2,1.2c0.2,0.5,0.6,0.9,1.2,0.9c0.6,0,1-0.4,1.2-0.9C226,106.6,226.5,106.1,226.5,105.5z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          className="st4"
          d="M192.2,137.4c-1.7-3.4-6-2.8-6-2.8l-0.8,0l0,0l-0.3,0l-0.3,0l0,0l-0.8,0c0,0-4.2-0.5-6,2.8
		c-1.7,3.4,0.9,6.3,2.9,7c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4C191.3,143.7,193.9,140.8,192.2,137.4z"
          fill="#FFFFFF"
        />
        <Path
          className="st2"
          d="M192.2,137.4c-1.1-2.1-3.1-2.7-4.5-2.8c0.9,0.4,1.7,1.1,2.3,2.2c1.7,3.4-0.9,6.3-2.9,7c0,0-1.1,0.4-3.1,0.4
		h-0.2h-1.6h-0.2c-0.6,0-1.1,0-1.5-0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4
		C191.3,143.7,193.9,140.8,192.2,137.4z"
          fill="#BAC2CA"
        />
        <Path
          className="st10"
          d="M192.2,137.4c-1.7-3.4-6-2.8-6-2.8l-0.8,0l0,0l-0.3,0l-0.3,0l0,0l-0.8,0c0,0-4.2-0.5-6,2.8
		c-1.7,3.4,0.9,6.3,2.9,7c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4C191.3,143.7,193.9,140.8,192.2,137.4z"
          fill="none"
          stroke="#010101"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path className="st10" d="M184.5,134.6c0,0-3.6,0.6-3.6,5c0,4.3,3.7,5.2,3.7,5.2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M185.3,134.6c0,0,3.5,0.6,3.5,5c0,4.3-3.6,5.2-3.6,5.2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M184.1,132.7c0,0,0.7,0.3,0.8,1.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
      </G>
      <G>
        <Path
          className="st4"
          d="M138.5,139.5c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
		c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C137.8,144.6,140,142.2,138.5,139.5z"
          fill="#FFFFFF"
        />
        <Path
          className="st2"
          d="M138.5,139.5c-0.9-1.7-2.5-2.2-3.7-2.3c0.7,0.3,1.4,0.9,1.9,1.8c1.4,2.8-0.7,5.1-2.4,5.7c0,0-0.9,0.4-2.6,0.4
		h-0.2h-1.3h-0.2c-0.5,0-0.9,0-1.2-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4
		C137.8,144.6,140,142.2,138.5,139.5z"
          fill="#BAC2CA"
        />
        <Path
          className="st10"
          d="M138.5,139.5c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
		c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C137.8,144.6,140,142.2,138.5,139.5z"
          fill="none"
          stroke="#010101"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path className="st10" d="M129.8,143.4c0.9,1.7,2.6,2.1,2.6,2.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M132.3,137.2c0,0-2.2,0.4-2.8,2.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M132.9,137.2c0,0,2.9,0.5,2.9,4s-3,4.3-3,4.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M133.2,135.7c0,0-0.6,0.2-0.7,1.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
      </G>
      <G>
        <Path
          className="st4"
          d="M204,139c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
		c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C203.3,144.2,205.4,141.8,204,139z"
          fill="#FFFFFF"
        />
        <Path
          className="st2"
          d="M204,139c-0.9-1.7-2.5-2.2-3.7-2.3c0.7,0.3,1.4,0.9,1.9,1.8c1.4,2.8-0.7,5.1-2.4,5.7c0,0-0.9,0.4-2.6,0.4h-0.2
		h-1.3h-0.2c-0.5,0-0.9,0-1.2-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4
		C203.3,144.2,205.4,141.8,204,139z"
          fill="#BAC2CA"
        />
        <Path
          className="st10"
          d="M204,139c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
		c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C203.3,144.2,205.4,141.8,204,139z"
          fill="none"
          stroke="#010101"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path className="st10" d="M195.3,143c0.9,1.7,2.6,2.1,2.6,2.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M197.7,136.8c0,0-2.2,0.4-2.8,2.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M198.4,136.8c0,0,2.9,0.5,2.9,4s-3,4.3-3,4.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st10" d="M198.6,135.2c0,0-0.6,0.2-0.7,1.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
      </G>
      <G>
        <Path
          className="st6"
          d="M197.6,74.9c0,0-0.3-1.7,1.2-2.9c1.5-1.1,3.3-0.6,3.3-0.6l0.1,0.2c0,0,0,1.9-1.5,3.1c-1.5,1.1-3.1,0.4-3.1,0.4
		L197.6,74.9z"
          fill="#DCE2E7"
        />
        <Path className="st11" d="M202.2,71.5c0,0-1.8-0.6-3.3,0.6c-1.5,1.1-1.2,2.9-1.2,2.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M202.4,71.7c0,0,0,1.9-1.5,3.1c-1.5,1.1-3.1,0.4-3.1,0.4" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M214.7,73.8c0,0,0.4,1.5,2,1.9c1.6,0.4,2.9-0.7,2.9-0.7l0-0.2c0,0-0.6-1.6-2.3-2c-1.6-0.4-2.7,0.8-2.7,0.8
		L214.7,73.8z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M219.7,74.9c0,0-1.3,1.1-2.9,0.7c-1.6-0.4-2-1.9-2-1.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M219.7,74.7c0,0-0.6-1.6-2.3-2c-1.6-0.4-2.7,0.8-2.7,0.8" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M216.1,71.9c0,0-1.5-0.5-2.7,0.6s-1.1,2.8-1.1,2.8l0.1,0.2c0,0,1.6,0.4,2.9-0.8s0.9-2.6,0.9-2.6L216.1,71.9z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M212.4,75.3c0,0-0.2-1.7,1.1-2.8s2.7-0.6,2.7-0.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M212.5,75.4c0,0,1.6,0.4,2.9-0.8s0.9-2.6,0.9-2.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path className="st1" d="M203,74c0,0,0.6,1.3,2,1.4s2.4-1,2.4-1l0-0.2c0,0-0.8-1.3-2.2-1.4s-2.2,1-2.2,1L203,74z" fill="#E7EBEE" />
        <Path className="st11" d="M207.5,74.3c0,0-1,1.1-2.4,1s-2-1.4-2-1.4" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M207.5,74.1c0,0-0.8-1.3-2.2-1.4c-1.5-0.1-2.2,1-2.2,1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M212,76.6c0,0,0.7-1.5-0.3-2.9c-1-1.5-2.8-1.5-2.8-1.5l-0.2,0.1c0,0-0.6,1.7,0.4,3.1c1,1.5,2.6,1.3,2.6,1.3
		L212,76.6z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M208.9,72.2c0,0,1.8,0,2.8,1.5s0.3,2.9,0.3,2.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M208.7,72.3c0,0-0.6,1.7,0.4,3.1c1,1.5,2.6,1.3,2.6,1.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st12"
          d="M258.1,98.4c-0.9-0.7-1.1-2-0.5-2.9c1.2-1.9,1.8-4.1,1.8-6.5c0-4.5-2.4-8.4-6.1-10.9
		c-0.5-0.3-0.7-0.9-0.6-1.4c0.2-0.6,0.3-1.3,0.3-2c0-4.3-3.9-7.9-8.8-7.9s-8.8,3.5-8.8,7.9c0,0.7,0.1,1.4,0.3,2
		c0.2,0.5-0.1,1.1-0.6,1.4c-3.7,2.5-6.1,6.4-6.1,10.9c0,2.3,0.7,4.5,1.8,6.5c0.6,1,0.3,2.2-0.5,2.9c-3.9,3.3-6.3,7.9-6.3,13.1
		c0,10,9.1,18,20.2,18c11.2,0,20.2-8.1,20.2-18C264.4,106.3,261.9,101.7,258.1,98.4z"
          fill="#D9DFE6"
        />
        <Line className="st10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="243.7" x2="243.7" y1="129.5" y2="143.9" />
        <Line className="st10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="243.7" x2="243.7" y1="91.3" y2="118.8" />
        <Line className="st10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="234.3" x2="243.4" y1="105.8" y2="111.9" />
        <Line className="st10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="248.5" x2="243.6" y1="98.9" y2="102.2" />
        <Path
          className="st10"
          d="M258.1,98.4c-0.9-0.7-1.1-2-0.5-2.9c1.2-1.9,1.8-4.1,1.8-6.5c0-4.5-2.4-8.4-6.1-10.9
		c-0.5-0.3-0.7-0.9-0.6-1.4c0.2-0.6,0.3-1.3,0.3-2c0-4.3-3.9-7.9-8.8-7.9s-8.8,3.5-8.8,7.9c0,0.7,0.1,1.4,0.3,2
		c0.2,0.5-0.1,1.1-0.6,1.4c-3.7,2.5-6.1,6.4-6.1,10.9c0,2.3,0.7,4.5,1.8,6.5c0.6,1,0.3,2.2-0.5,2.9c-3.9,3.3-6.3,7.9-6.3,13.1
		c0,10,9.1,18,20.2,18c11.2,0,20.2-8.1,20.2-18C264.4,106.3,261.9,101.7,258.1,98.4z"
          fill="none"
          stroke="#010101"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
      </G>
      <G>
        <Path
          className="st1"
          d="M259.8,125.9c0,0-1.1,0.6-2.2-0.1c-1.1-0.7-1.2-2-1.2-2l0.1-0.1c0,0,1.2-0.5,2.4,0.2s1.1,1.9,1.1,1.9
		L259.8,125.9z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M256.4,123.8c0,0,0.1,1.3,1.2,2c1.1,0.7,2.2,0.1,2.2,0.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M256.5,123.6c0,0,1.2-0.5,2.4,0.2c1.1,0.7,1.1,1.9,1.1,1.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M227.7,124c0,0,0.2,1.2-0.8,2s-2.3,0.5-2.3,0.5l-0.1-0.1c0,0-0.1-1.3,1-2.2c1-0.8,2.2-0.3,2.2-0.3L227.7,124z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M224.6,126.4c0,0,1.3,0.4,2.3-0.5c1-0.8,0.8-2,0.8-2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M224.5,126.3c0,0-0.1-1.3,1-2.2c1-0.8,2.2-0.3,2.2-0.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st4"
          d="M254.4,110.4c0,0,0.2,1.2-0.8,2c-1,0.8-2.3,0.5-2.3,0.5l-0.1-0.1c0,0-0.1-1.3,1-2.2s2.2-0.3,2.2-0.3
		L254.4,110.4z"
          fill="#FFFFFF"
        />
        <Path className="st11" d="M251.2,112.8c0,0,1.3,0.4,2.3-0.5c1-0.8,0.8-2,0.8-2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M251.1,112.7c0,0-0.1-1.3,1-2.2c1-0.8,2.2-0.3,2.2-0.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M264.4,132.2c0,0,0.2,1.2-0.8,2s-2.3,0.5-2.3,0.5l-0.1-0.1c0,0-0.1-1.3,1-2.2c1-0.8,2.2-0.3,2.2-0.3
		L264.4,132.2z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M261.2,134.6c0,0,1.3,0.4,2.3-0.5s0.8-2,0.8-2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M261.1,134.5c0,0-0.1-1.3,1-2.2c1-0.8,2.2-0.3,2.2-0.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M243.8,91.6c0,0-1.1,0.6-2.2-0.1c-1.1-0.7-1.2-2-1.2-2l0.1-0.1c0,0,1.2-0.5,2.4,0.2c1.1,0.7,1.1,1.9,1.1,1.9
		L243.8,91.6z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M240.4,89.4c0,0,0.1,1.3,1.2,2c1.1,0.7,2.2,0.1,2.2,0.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M240.5,89.3c0,0,1.2-0.5,2.4,0.2c1.1,0.7,1.1,1.9,1.1,1.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path className="st1" d="M222.2,115.6c0,0-0.3-1.6,1-2.6s3-0.6,3-0.6l0.1,0.2c0,0,0.1,1.7-1.2,2.8s-2.8,0.5-2.8,0.5L222.2,115.6z" fill="#E7EBEE" />
        <Path className="st11" d="M226.3,112.4c0,0-1.6-0.5-3,0.6c-1.3,1.1-1,2.6-1,2.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M226.4,112.6c0,0,0.1,1.7-1.2,2.8c-1.3,1.1-2.8,0.5-2.8,0.5" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st4"
          d="M229.2,134.4c0,0-1.6,0.2-2.6-1.1s-0.5-3-0.5-3l0.2-0.1c0,0,1.7,0,2.7,1.3c1,1.4,0.3,2.8,0.3,2.8L229.2,134.4z
		"
          fill="#FFFFFF"
        />
        <Path className="st11" d="M226.2,130.2c0,0-0.5,1.6,0.5,3c1,1.4,2.6,1.1,2.6,1.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M226.4,130.1c0,0,1.7,0,2.7,1.3c1,1.4,0.3,2.8,0.3,2.8" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st4"
          d="M266.6,99.5c0,0-1.6,0.2-2.6-1.1c-1-1.4-0.5-3-0.5-3l0.2-0.1c0,0,1.7,0,2.7,1.3c1,1.4,0.3,2.8,0.3,2.8
		L266.6,99.5z"
          fill="#FFFFFF"
        />
        <Path className="st11" d="M263.6,95.3c0,0-0.5,1.6,0.5,3c1,1.4,2.6,1.1,2.6,1.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M263.8,95.2c0,0,1.7,0,2.7,1.3c1,1.4,0.3,2.8,0.3,2.8" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M256.7,87.2c0,0,0.2-1.6,1.7-2.2c1.6-0.6,3,0.2,3,0.2l0.1,0.2c0,0-0.4,1.7-1.9,2.3c-1.6,0.6-2.8-0.3-2.8-0.3
		L256.7,87.2z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M261.4,85.3c0,0-1.5-0.9-3-0.2c-1.6,0.7-1.7,2.2-1.7,2.2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M261.5,85.4c0,0-0.4,1.7-1.9,2.3s-2.8-0.3-2.8-0.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st4"
          d="M237.8,107.9c0,0-1.3-0.9-1.2-2.6c0.2-1.7,1.6-2.5,1.6-2.5l0.2,0c0,0,1.3,1.1,1.1,2.8
		c-0.2,1.7-1.6,2.3-1.6,2.3L237.8,107.9z"
          fill="#FFFFFF"
        />
        <Path className="st11" d="M238.3,102.8c0,0-1.5,0.9-1.6,2.5c-0.2,1.7,1.2,2.6,1.2,2.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M238.6,102.8c0,0,1.3,1.1,1.1,2.8c-0.2,1.7-1.6,2.3-1.6,2.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <Path className="st10" d="M245.8,121.8c-0.8-2.5-3.1-4.4-5.9-4.4c-1.4,0-2.6,0.4-3.6,1.2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
      <Path className="st10" d="M251.2,121.9c-0.4-0.8-1-1.4-1.9-1.9c-1.3-0.7-2.8-0.6-4,0.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
      <G>
        <Path
          className="st4"
          d="M225.3,119.1c0-0.3-0.2-0.5-0.4-0.5c0-0.2-0.2-0.4-0.5-0.4c-0.3,0-0.5,0.2-0.5,0.4c-0.3,0-0.5,0.2-0.5,0.5
		c0,0.3,0.2,0.5,0.5,0.5c0.1,0.2,0.3,0.4,0.5,0.4c0.2,0,0.4-0.2,0.5-0.4C225.1,119.5,225.3,119.3,225.3,119.1z"
          fill="#FFFFFF"
        />
      </G>
      <G>
        <Path
          className="st1"
          d="M237.1,145.8c0,0-1.2,0.7-2.4-0.1c-1.2-0.8-1.3-2.2-1.3-2.2l0.1-0.2c0,0,1.3-0.6,2.6,0.2
		c1.2,0.8,1.2,2.1,1.2,2.1L237.1,145.8z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M233.4,143.4c0,0,0.1,1.5,1.3,2.2c1.2,0.8,2.4,0.1,2.4,0.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M233.5,143.3c0,0,1.3-0.6,2.6,0.2c1.2,0.8,1.2,2.1,1.2,2.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M245.3,148.5c0,0-0.4-1.3,0.6-2.3s2.4-0.8,2.4-0.8l0.1,0.1c0,0,0.3,1.4-0.7,2.5s-2.3,0.7-2.3,0.7L245.3,148.5z
		"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M248.3,145.4c0,0-1.4-0.2-2.4,0.8s-0.6,2.3-0.6,2.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M248.5,145.5c0,0,0.3,1.4-0.7,2.5c-1,1-2.3,0.7-2.3,0.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M242.3,144c0,0-0.9,1-2.3,0.6c-1.4-0.4-1.9-1.8-1.9-1.8l0-0.2c0,0,1.1-0.9,2.5-0.5c1.4,0.4,1.7,1.7,1.7,1.7
		L242.3,144z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M238.1,142.7c0,0,0.5,1.4,1.9,1.8c1.4,0.4,2.3-0.6,2.3-0.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M238.1,142.6c0,0,1.1-0.9,2.5-0.5c1.4,0.4,1.7,1.7,1.7,1.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M248.6,144.7c0,0-1.1,1-2.7,0.4c-1.5-0.6-2-2.2-2-2.2l0.1-0.2c0,0,1.4-0.9,2.9-0.4c1.5,0.6,1.8,2.1,1.8,2.1
		L248.6,144.7z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M244,142.9c0,0,0.4,1.6,2,2.2s2.7-0.4,2.7-0.4" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M244.1,142.7c0,0,1.4-0.9,2.9-0.4c1.5,0.6,1.8,2.1,1.8,2.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M241.2,145.4c0,0,0.1-1.3,1.5-1.9c1.3-0.5,2.6,0.2,2.6,0.2l0.1,0.2c0,0-0.3,1.4-1.7,2s-2.4-0.3-2.4-0.3
		L241.2,145.4z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M245.3,143.8c0,0-1.2-0.8-2.6-0.2c-1.3,0.5-1.5,1.9-1.5,1.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M245.4,144c0,0-0.3,1.4-1.7,2c-1.3,0.5-2.4-0.3-2.4-0.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M230.7,147c0,0-0.4,1.5-2,1.8c-1.6,0.4-2.8-0.7-2.8-0.7l0-0.2c0,0,0.6-1.5,2.2-1.9c1.6-0.4,2.6,0.7,2.6,0.7
		L230.7,147z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M225.9,148.1c0,0,1.2,1.1,2.8,0.7c1.6-0.4,2-1.8,2-1.8" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M225.9,147.9c0,0,0.6-1.5,2.2-1.9c1.6-0.4,2.6,0.7,2.6,0.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M267.2,147.3c0,0-0.5,1.4-2.1,1.7c-1.6,0.3-2.8-0.9-2.8-0.9l0-0.2c0,0,0.7-1.5,2.3-1.7
		c1.6-0.3,2.5,0.9,2.5,0.9L267.2,147.3z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M262.3,148c0,0,1.2,1.2,2.8,0.9c1.6-0.3,2.1-1.7,2.1-1.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M262.3,147.8c0,0,0.7-1.5,2.3-1.7c1.6-0.3,2.5,0.9,2.5,0.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M255.1,148.1c0,0-0.8,1.1-2.2,0.9c-1.4-0.2-2.1-1.5-2.1-1.5l0-0.2c0,0,1-1,2.4-0.8s1.9,1.5,1.9,1.5
		L255.1,148.1z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M250.8,147.4c0,0,0.7,1.3,2.1,1.5s2.2-0.9,2.2-0.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M250.8,147.2c0,0,1-1,2.4-0.8s1.9,1.5,1.9,1.5" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M215.4,147.4c0,0-0.5,1.3-1.9,1.4C212,149,211,148,211,148l0-0.2c0,0,0.7-1.3,2.1-1.4s2.2,0.9,2.2,0.9
		L215.4,147.4z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M211,147.9c0,0,1,1.1,2.4,0.9c1.4-0.2,1.9-1.4,1.9-1.4" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M211,147.7c0,0,0.7-1.3,2.1-1.4c1.4-0.2,2.2,0.9,2.2,0.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M237.3,147.1c0,0,0.9-1.3,2.6-1.2c1.7,0.2,2.6,1.7,2.6,1.7l0,0.2c0,0-1.2,1.3-2.9,1.1s-2.3-1.7-2.3-1.7
		L237.3,147.1z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M242.5,147.6c0,0-0.9-1.5-2.6-1.7s-2.6,1.2-2.6,1.2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M242.5,147.8c0,0-1.2,1.3-2.9,1.1c-1.7-0.2-2.3-1.7-2.3-1.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
      <G>
        <Path
          className="st1"
          d="M254.4,144c0,0,0.9-1.3,2.6-1.2c1.7,0.2,2.6,1.7,2.6,1.7l0,0.2c0,0-1.2,1.3-2.9,1.1c-1.7-0.2-2.3-1.7-2.3-1.7
		L254.4,144z"
          fill="#E7EBEE"
        />
        <Path className="st11" d="M259.6,144.6c0,0-0.9-1.5-2.6-1.7c-1.7-0.2-2.6,1.2-2.6,1.2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st11" d="M259.6,144.8c0,0-1.2,1.3-2.9,1.1c-1.7-0.2-2.3-1.7-2.3-1.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
      </G>
    </Svg>
  );
}
