import React from 'react';
import T from 'prop-types';

import { ValueLabelBox, ValueLabelText, ValueLabelTriangle } from './styledComponents';

const ValueLabel = ({ maximumValue, value }) => {
  const triangleMarginLeft = (value * ((81 - 30) / maximumValue));

  return (
    <ValueLabelBox>
      <ValueLabelText>${value}</ValueLabelText>
      <ValueLabelTriangle style={{ marginLeft: triangleMarginLeft }} />
    </ValueLabelBox>
  );
};

ValueLabel.propTypes = {
  maximumValue: T.number.isRequired,
  value: T.number.isRequired,
};

export default ValueLabel;
