import { TextInput } from 'react-native';

const { State: TextInputState } = TextInput;

/**
 * Checks to see if the currentlyFocusedInput has been scrolled up and out of view of a given
 * ScrollView. If so, then it scrolls the input back down into view to rest at the top of the
 * visible ScrollView area.
 */
export const scrollInputBackIntoView = async ({ scrollViewRef }) => {
  try {
    const currentlyFocusedInput = TextInputState.currentlyFocusedInput();

    // Start async measurements
    const gettingInputTopFullScrollViewYOffset = new Promise((resolve, reject) => {
      currentlyFocusedInput.measureLayout(
        scrollViewRef.current,
        (...diffMeasurements) => resolve(diffMeasurements[1]),
        () => reject(new Error('measurement failed')),
      );
    });
    const gettingScrollViewTopPageYOffset = new Promise((resolve) => {
      scrollViewRef.current.measure((...measurements) => resolve(measurements[5]));
    });

    const gettingInputTopPageYOffset = new Promise((resolve) => {
      currentlyFocusedInput.measure((...measurements) => resolve(measurements[5]));
    });

    // Wait for measuring to finish
    const [
      inputTopFullScrollViewYOffset,
      scrollViewTopPageYOffset,
      inputTopPageYOffset,
    ] = await Promise.all([
      gettingInputTopFullScrollViewYOffset,
      gettingScrollViewTopPageYOffset,
      gettingInputTopPageYOffset,
    ]);

    // Scroll the input back down if necessary
    if (inputTopPageYOffset < scrollViewTopPageYOffset) {
      scrollViewRef.current.scrollTo({ animated: true, x: 0, y: inputTopFullScrollViewYOffset });
    }
  } catch {
    /**
     * If reached here, likely it's because the async functions took too long and at some point
     * currentlyFocusedInput or a 'ref.current' became undefined/null, so calling '.measure' threw
     * an error. That's fine. We just don't end up performing the .scrollTo() action, which is
     * probably for the best since it likely would no longer be relevant anyway.
     */
  }
};
