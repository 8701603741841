import styled from 'styled-components/native';

import { moderateScale } from '../../../../../../utils/scaleHelpers';

export const ButtonContainer = styled.View`
  align-items: center;
  padding-bottom: ${moderateScale(30)}px;
`;

export const Container = styled.View`
  flex-grow: 1;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const IllustrationContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: ${moderateScale(56)}px;
`;
