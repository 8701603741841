import React from 'react';
import T from 'prop-types';

import { ADDRESS_TYPE_DROPWDOWN_PROPS } from '@dmi/shared/redux/Settings/Loans/constants';

import { OutlinedDropDown } from '../../../base_ui';
import {
  Container,
  LabelInputContainer,
  StyledLabel,
} from '../styledComponents';
import { useSharedDropdownStyle } from '../helpers';

const AddressTypeDropdown = ({ dispatchChangeMailingAddressType, selected }) => {
  const dropdownStyle = useSharedDropdownStyle();
  const { items, label } = ADDRESS_TYPE_DROPWDOWN_PROPS;

  return (
    <Container>
      <LabelInputContainer>
        <StyledLabel>{label}</StyledLabel>
        <OutlinedDropDown
          accessibilityName="address type dropdown"
          hasLargerFont
          menuItems={items}
          onChange={(value) => dispatchChangeMailingAddressType(value)}
          selected={selected}
          style={dropdownStyle}
        />
      </LabelInputContainer>
    </Container>
  );
};

AddressTypeDropdown.propTypes = {
  dispatchChangeMailingAddressType: T.func.isRequired,
  selected: T.string.isRequired,
};

export default AddressTypeDropdown;
