import React from 'react';
import T from 'prop-types';

import { ConditionalRender, P4 } from '../../../base_ui';
import {
  StyledH2,
  StyledPrimaryButton,
  StyledProgressButton,
  StyledWrapper,
  TextWrapper,
} from './styledComponents';

const PaymentSummary = ({
  bannerError = '',
  hasProgressButton = false,
  subText,
  text,
  ...restProps
}) => (
  <StyledWrapper>
    <TextWrapper>
      <StyledH2 $error={!!bannerError}>{text}</StyledH2>
      <P4>{subText}</P4>
    </TextWrapper>
    <ConditionalRender
      Component={StyledPrimaryButton}
      FallbackComponent={StyledProgressButton}
      propsToPassDown={restProps}
      shouldRender={!hasProgressButton}
    />
  </StyledWrapper>
);

PaymentSummary.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]),
  disabled: T.bool,
  hasProgressButton: T.bool,
  subText: T.string.isRequired,
  text: T.string.isRequired,
};

export default PaymentSummary;
