import styled from 'styled-components/native';

import { P2, P3, PrimaryDivider } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const AmountContainer = styled.View`
  flex: 1;
`;

export const ContentContainer = styled.View`
  flex-direction: row;
  padding-vertical: ${moderateScale(20)}px;
`;

export const DescriptionsContainer = styled.View`
  flex: 3;
  padding-right: ${moderateScale(20)}px;
`;

export const DescriptionText = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  margin-bottom: ${moderateScale(6)}px;
`;

export const HeaderContainer = styled.View`
  flex-direction: row;
  padding-bottom: ${moderateScale(20)}px;
`;

export const HeaderText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin-top: ${moderateScale(1)}px;
  max-width: 100%;
`;
