import React from 'react';
import T from 'prop-types';

import { HEADER_DICT } from './constants';
import { getHeaderText } from './helpers';

const Header = ({ inlineBlocks, noMargin, type }) => {
  const HeaderToRender = HEADER_DICT[type];
  return <HeaderToRender $noMargin={noMargin}>{getHeaderText(inlineBlocks)}</HeaderToRender>;
};

Header.propTypes = {
  inlineBlocks: T.array.isRequired,
  noMargin: T.bool,
  type: T.string.isRequired,
};

export default Header;
