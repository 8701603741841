import React, { Fragment } from 'react';
import T from 'prop-types';

import { SecondaryDivider } from '../../base_ui';
import ContactInfo from './ContactInfo';
import iconDictionary from '../../../utils/iconDictionary';

const OutlinedHouseIllustration = iconDictionary('outlinedHouse');

const WithoutBanner = ({ isSecureMessagingTreatmentOn }) => (
  <Fragment>
    {OutlinedHouseIllustration}
    <ContactInfo isSecureMessagingTreatmentOn={isSecureMessagingTreatmentOn} />
    <SecondaryDivider />
  </Fragment>
);

WithoutBanner.propTypes = { isSecureMessagingTreatmentOn: T.bool.isRequired };

export default WithoutBanner;
