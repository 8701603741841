import styled from 'styled-components/native';

import {
  Animation,
  H2,
  P2,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ButtonWrapper = styled.View`
  align-items: center;
  padding-bottom: ${moderateScale(35)}px;
`;

export const Container = styled.View`
  flex: 1;
  padding: 0 ${moderateScale(24)}px;
`;

// Insurance Details

export const DetailsBannerWrapper = styled.View`
  margin: 0 ${moderateScale(24)}px ${moderateScale(33)}px;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin: ${moderateScale(8)}px ${moderateScale(24)}px ${moderateScale(35)}px;
`;

export const StyledExpirationDate = styled(P2)`
  color: ${({
    $isExpired,
    theme: { colors: { accentRed, primaryCarbon } },
  }) => $isExpired ? accentRed : primaryCarbon};
`;

export const StyledLabel = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const StyledRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${moderateScale(20)}px;
`;

// Insurance Upload

export const BannerWrapper = styled.View`
  margin-top: ${moderateScale(20)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
`;

export const StyledH2 = styled(H2)`
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

// Confirmation

export const ConfirmationDisclaimerContent = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: ${moderateScale(24)}px;
`;

export const ConfirmationH2 = styled(H2)`
  margin: ${moderateScale(16)}px 0 ${moderateScale(8)}px;
  text-align: center;
`;

export const ConfirmationP2 = styled(P2)`
  margin-bottom: ${moderateScale(24)}px;
  text-align: center;
`;

export const ConfirmationRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${moderateScale(16)}px;
`;

export const ConfirmationRows = styled.View`
  margin-bottom: ${moderateScale(24)}px;
`;

export const IconWrapper = styled.View`
  padding-right: ${moderateScale(8)}px;
`;

export const StyledAnimation = styled(Animation)`
  align-self: center;
  height: ${moderateScale(120)}px;
  width: ${moderateScale(287)}px;
`;
