import React, { Fragment } from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import {
  INFO_COPY_DICT as INFO_COPY_DICT_AUTOPAY,
  REVIEW_DICTIONARY_AUTOPAY,
} from '@dmi/shared/redux/Payments/Autopay/constants';
import {
  INFO_COPY_DICT as INFO_COPY_DICT_OTP,
  REVIEW_DICTIONARY_OTP,
} from '@dmi/shared/redux/Payments/OneTimePayment/constants';
import { REVIEW_PAYMENT_NO_FEE } from '@dmi/shared/utils/constants';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import iconDictionary from '../../../utils/iconDictionary';
import { InfoSection, PaymentSummary } from '../sharedComponents';
import { SecondaryHeader } from '../../ScreenHeaders';
import {
  ConditionalRender,
  FormattedMessage,
  P2,
  P3,
} from '../../base_ui';
import EmailCheckbox from './EmailCheckbox';
import LatePaymentConfirmationRow from './LatePaymentConfirmationRow';
import {
  BoldText,
  Container,
  Content,
  InfoSectionContainer,
  NoFeeCopy,
  NoFeeCopyView,
  Row,
  RowItem,
  SubRow,
  Text,
  Title,
} from './styledComponents';

const MagnifyingGlass = iconDictionary('magnifyingGlass');

const Review = ({
  customerServicePhoneNumber,
  flow = '',
  isEmailConfirmation,
  latePaymentConfirmProps,
  navigation,
  paragraphDate,
  reviewData,
  shouldOTPReviewShowLatePaymentWarning = false,
  summaryProps,
  toggleIsEmailConfirmation,
  variant,
}) => {
  const isOtp = variant === 'otp';
  const isAutopay = variant === 'autopay';
  const { headerTitle } = isOtp ? REVIEW_DICTIONARY_OTP.mobile : REVIEW_DICTIONARY_AUTOPAY[flow];
  const { heading, paragraphText } =
    isOtp ? REVIEW_DICTIONARY_OTP.shared : REVIEW_DICTIONARY_AUTOPAY[flow];
  const hasContent = !!headerTitle && !!heading && !!paragraphText;

  return (
    <ConditionalRender
      Component={(
        <Fragment>
          <SecondaryHeader
            handleBack={() => navigation.goBack()}
            title={headerTitle}
          />
          <Container>
            <Content>
              <View>{MagnifyingGlass}</View>
              <Title>{heading}</Title>
              <FormattedMessage
                defaultMessage={interpolate(paragraphText, { customerServicePhoneNumber })}
                // eslint-disable-next-line react/prop-types
                values={{ b: ({ msg }) => <BoldText>{msg}</BoldText>, date: paragraphDate }}
                WrapperComponent={Text}
              />
              {reviewData.map(({ label, previousValue, value }) => (
                <ConditionalRender
                  key={label}
                  Component={(
                    <Fragment key={label}>
                      <Row $isEdit>
                        <SubRow>
                          <RowItem>{label}</RowItem>
                          <P2>{value}</P2>
                        </SubRow>
                        <SubRow $isPrevValue>
                          <P3>Previous: </P3>
                          <P3>{previousValue}</P3>
                        </SubRow>
                      </Row>
                    </Fragment>
                  )}
                  FallbackComponent={(
                    <Row key={label}>
                      <RowItem>{label}</RowItem>
                      <P2>{value}</P2>
                    </Row>
                  )}
                  shouldRender={isAutopay && flow === 'change'}
                />
              ))}
              <NoFeeCopyView>
                <NoFeeCopy>{REVIEW_PAYMENT_NO_FEE}</NoFeeCopy>
              </NoFeeCopyView>
              <ConditionalRender
                Component={EmailCheckbox}
                propsToPassDown={{
                  checked: isEmailConfirmation,
                  onPress: toggleIsEmailConfirmation,
                }}
                shouldRender={isAutopay && isEmailConfirmation !== null}
              />
              <ConditionalRender
                Component={LatePaymentConfirmationRow}
                propsToPassDown={{
                  ...latePaymentConfirmProps,
                  navigation,
                }}
                shouldRender={!isAutopay && shouldOTPReviewShowLatePaymentWarning}
              />
              <ConditionalRender
                Component={(
                  <InfoSectionContainer>
                    <InfoSection
                      customerServicePhoneNumber={customerServicePhoneNumber}
                      data={INFO_COPY_DICT_OTP.review}
                    />
                  </InfoSectionContainer>
                )}
                shouldRender={isOtp}
              />
              <ConditionalRender
                Component={(
                  <InfoSectionContainer
                    $isEmailCheckboxDisplayed={isAutopay && isEmailConfirmation !== null}
                  >
                    <InfoSection
                      customerServicePhoneNumber={customerServicePhoneNumber}
                      data={INFO_COPY_DICT_AUTOPAY.review}
                    />
                  </InfoSectionContainer>
                )}
                shouldRender={isAutopay && flow === 'change'}
              />
            </Content>
          </Container>
          <PaymentSummary {...summaryProps} />
        </Fragment>
      )}
      shouldRender={hasContent}
    />
  );
};

Review.propTypes = {
  customerServicePhoneNumber: T.string,
  flow: T.string,
  isEmailConfirmation: T.oneOf([true, false, null]),
  latePaymentConfirmProps: T.object,
  navigation: T.object.isRequired,
  paragraphDate: T.string,
  reviewData: T.arrayOf(T.shape({ label: T.string, value: T.string })),
  shouldOTPReviewShowLatePaymentWarning: T.bool,
  summaryProps: T.object.isRequired,
  toggleIsEmailConfirmation: T.func,
  toggleIsLatePaymentConfirmChecked: T.func,
  variant: T.string.isRequired,
};

export default Review;
