import React from 'react';
import { Pressable, View } from 'react-native';
import T from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { interpolate } from '@dmi/shared/utils/globalHelpers';
import { TOOLTIP_PROPS } from '@dmi/shared/redux/Payments/Autopay/constants';

import { P2 } from '../../../base_ui';
import { TooltipHeading, TooltipLink } from './styledComponents';

const DelayTooltip = ({ handleClose, maxDelayDays }) => {
  const { formatMessage } = useIntl();

  const { content, linkText, title } = TOOLTIP_PROPS.delayDays;

  return (
    <View>
      <TooltipHeading>
        <FormattedMessage id={title} />
      </TooltipHeading>
      <P2>
        {interpolate(formatMessage({ id: content }), { maxDelayDays })}
      </P2>
      <Pressable onPress={handleClose}>
        <TooltipLink>
          <FormattedMessage id={linkText} />
        </TooltipLink>
      </Pressable>
    </View>
  );
};

DelayTooltip.propTypes = { handleClose: T.func.isRequired, maxDelayDays: T.number.isRequired };

export default DelayTooltip;
