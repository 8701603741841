import React from 'react';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Calculators/messages';

import { P2 } from '../../base_ui';
import Layout from '../../../constants/Layout';
import iconDictionary from '../../../utils/iconDictionary';
import {
  HeaderContainer,
  HeaderContentWrapper,
  StyledHeader,
} from './styledComponents';

const { isTablet } = Layout;
const CalculatorIllustration = iconDictionary('interimHeader');

const HeaderSection = () => (
  <HeaderContainer $center={isTablet}>
    {CalculatorIllustration}
    <HeaderContentWrapper>
      <StyledHeader>
        <FormattedMessage id={INTL_IDS.HOME_TOPSECTION_TITLE} />
      </StyledHeader>
      <P2>
        <FormattedMessage id={INTL_IDS.HOME_TOPSECTION_TEXT} />
      </P2>
    </HeaderContentWrapper>
  </HeaderContainer>
);

export default HeaderSection;
