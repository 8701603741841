/* eslint-disable max-len */
import React from 'react';
import
Svg, {
  Line,
  Path,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HomeInsuranceIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M9.05,8.38v7a1.59,1.59,0,0,1-1.59,1.58h0a1.58,1.58,0,0,1-1.58-1.58V15" fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="9.05" x2="9.05" y1="1.42" y2="2.41" />
      <Path d="M16.24,9.92A2.39,2.39,0,0,0,13.93,8.4,2.48,2.48,0,0,0,11.58,10h-.22A2.4,2.4,0,0,0,9,8.4,2.41,2.41,0,0,0,6.64,10H6.45A2.41,2.41,0,0,0,4.09,8.4,2.45,2.45,0,0,0,1.88,9.68v0a7.2,7.2,0,1,1,14.39.06Z" fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
    </Svg>
  );
}
