import React, { useEffect, useState } from 'react';
import T from 'prop-types';

import { MULTIPLE_PAYMENTS } from '@dmi/shared/redux/Payments/History/constants';
import { generateTotalPaymentsProps } from '@dmi/shared/redux/Payments/History/helpers';

import iconDictionary from '../../../../../../utils/iconDictionary';
import { ConditionalRender, P3 } from '../../../../../base_ui';
import {
  IconView,
  PressableRow,
  RowText,
  RowType,
} from '../../styledComponents';

const ArrowIcon = iconDictionary('arrowRightM');

const DataItem = ({
  dispatchSetGroupedTransactions,
  dispatchUpdateSelectedTransaction,
  expandedTransaction,
  isMultiplePaymentsRows,
  navigation,
  transactions,
}) => {
  const [isPressing, setIsPressing] = useState(false);
  const [rowData, setRowData] = useState({
    amount: '',
    date: '',
    id: '',
    type: '',
  });
  useEffect(() => {
    if (expandedTransaction) {
      setRowData(expandedTransaction);
    } else {
      setRowData(
        transactions.length > 1 ? generateTotalPaymentsProps(transactions) : transactions[0],
      );
    }
  }, [expandedTransaction, transactions, setRowData]);

  const handleRowPress = () => {
    if (type === MULTIPLE_PAYMENTS) {
      dispatchSetGroupedTransactions(transactions);
      navigation.navigate('MultiplePaymentsHistory');
    } else {
      dispatchUpdateSelectedTransaction(id);
      navigation.navigate('PaymentDetail');
    }
  };

  const {
    amount,
    date,
    id,
    type,
  } = rowData;

  return (
    <PressableRow
      $isPressing={isPressing}
      aria-label={`view transaction details, ${type}, ${date}`}
      onPress={() => handleRowPress(id)}
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      role="button"
    >
      <ConditionalRender
        Component={<RowText>{date}</RowText>}
        shouldRender={!isMultiplePaymentsRows}
      />
      <RowType>{type}</RowType>
      <P3>{amount}</P3>
      <IconView>
        {ArrowIcon}
      </IconView>
    </PressableRow>
  );
};

DataItem.propTypes = {
  dispatchSetGroupedTransactions: T.func,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  expandedTransaction: T.object,
  isMultiplePaymentsRows: T.bool,
  navigation: T.object.isRequired,
  transactions: T.arrayOf(T.object),
};

export default DataItem;
