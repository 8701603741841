import styled from 'styled-components/native';

import {
  Animation,
  BorderlessButton,
  H2,
  H3,
  P2,
  P3,
} from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

/**
 * Utilized by Confirmation
 */

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ConfirmationCard = styled.View`
  align-items: center;
  padding-top: ${moderateScale(83)}px;
`;

export const ConfirmationParagraph = styled(P2)`
  padding-bottom: ${({ $lastParagraph }) => $lastParagraph ? 0 : `${moderateScale(8)}px`};
  text-align: center;
`;

export const ConfirmationParagraphsWrapper = styled.View`
  margin: 0 ${moderateScale(44)}px ${moderateScale(24)}px;
`;

export const StyledAnimation = styled(Animation)`
  width: ${moderateScale(287)}px;
`;

export const StyledConfirmationHeader = styled(H2)`
  margin: 0 ${moderateScale(44)}px ${moderateScale(8)}px;
  text-align: center;
`;

export const StyledPhoneLinkText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

/**
 * Utilized by Shared Form
 */

export const MediaWrapper = styled.View`
  align-self: center;
  margin: ${moderateScale(16)}px 0;
`;

export const RegisterContent = styled.View`
  flex-grow: 1;
  width: 100%;
`;

export const RegisterHeader = styled(H3)`
  margin: 0 ${moderateScale(44)}px ${moderateScale(8)}px;
  text-align: center;
`;

export const RegisterP3 = styled(P3)`
  margin: 0 ${moderateScale(44)}px;
  text-align: center;
`;

export const RegisterText = styled.View`
  margin-bottom: ${moderateScale(17)}px;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { primary },
    fontFamilies: { regular },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: primary,
    fontFamily: regular,
    fontSize: f4,
  }],
}))`
  font-weight: 700;
  margin-top: ${({ $shouldRenderParagraphs }) =>
    $shouldRenderParagraphs ? `${moderateScale(8)}px` : `${moderateScale(24)}px`};

  .label {
    font-weight: 700;
    line-height: ${moderateScale(22)}px;
  }
`;
