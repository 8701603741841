/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

const HelpLearningCenter = () => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} width={moderateScale(85)} viewBox="0 0 85 85" xmlns="http://www.w3.org/2000/svg">
      <Polygon fill="#fff" stroke="#fff" strokeMiterlimit="10" strokeWidth="0.64px" points="25.81 66.55 9.09 57.91 22.12 28.05 38.84 36.69 25.81 66.55" />
      <Polygon fill="#bac2ca" points="30.49 60.79 13.92 60.79 26.87 31.11 43.52 37.49 30.49 60.79" />
      <Polyline strokeWidth="0.64px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="28.05 31.11 38.84 36.69 25.81 66.55 13.92 60.41" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.85px" points="20.05 32.78 22.12 28.05 26.95 30.55 13.92 60.41 9.09 57.91 19.52 34.01" />
      <Rect fill="#fff" x="27.2" y="21.1" width="25.68" height="39.69" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.06px" x="27.2" y="21.1" width="25.68" height="39.69" />
      <Path fill="#bac2ca" d="M35.51,30.72h-5a1.56,1.56,0,0,1,0-3.11h5a1.56,1.56,0,0,1,0,3.11Z" />
      <Path fill="#bac2ca" d="M35.51,35.89h-5a1.56,1.56,0,0,1,0-3.11h5a1.56,1.56,0,0,1,0,3.11Z" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.06px" x="27.2" y="21.1" width="11.64" height="39.69" />
      <Polygon fill="#e6eaed" points="47.84 27.43 52.35 27.35 52.34 25.12 44.07 25.15 44.11 60.25 47.55 60.25 47.84 27.43" />
      <Rect fill={svgHighlight} x="47.82" y="27.73" width="26.34" height="33.06" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.06px" points="74.16 54.07 74.16 61.85 60.99 61.85" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.06px" points="54.33 27.73 74.16 27.73 74.16 51.68" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.06px" x="47.92" y="27.73" width="6.41" height="33.06" />
      <Rect fill="#fff" x="0.65" y="60.11" width="76.19" height="8.03" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.7px" points="6.46 68.14 0.65 68.14 0.65 60.11 76.84 60.11 76.84 68.14 8.76 68.14" />
      <Rect stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill="#e6eaed" strokeWidth="0.7px" x="67.18" y="60.11" width="16.69" height="8.03" />
      <Path fill="#e6eaed" d="M79,76.57H75.18l0-1.47a3.12,3.12,0,0,0-3-3.19H64.12a1.94,1.94,0,0,1-1.86-2V68.14H79Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6px" d="M64.93,71.91h-.81a1.94,1.94,0,0,1-1.86-2V68.14H79v8.43H75.18l0-1.47a3.12,3.12,0,0,0-3-3.19H66.32" />
      <Path stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill="#e6eaed" strokeWidth="0.6px" d="M31.46,76.57H27.68l0-1.47a3.12,3.12,0,0,0-3-3.19h-8a1.94,1.94,0,0,1-1.86-2V68.14H31.46Z" />
      <Line strokeWidth="0.64px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="52.88" y1="27.73" x2="54.33" y2="27.73" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x1="22.14" y1="28.01" x2="27.02" y2="28.01" />
    </Svg>
  );
};

export default HelpLearningCenter;
