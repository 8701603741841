import iconDictionary from '../../../utils/iconDictionary';

const faceIDIcon = iconDictionary('faceID');
const touchIDIcon = iconDictionary('touchID');

const biometricIconDictionary = {
  face: faceIDIcon,
  fingerprint: touchIDIcon,
  // Unlikely that a device with only 'iris' authentication exists. But if so, face icon works fine.
  iris: faceIDIcon,
};

/**
 * Determines proper icon for the biometric icon on the Login page based the supported biometric
 * authentication types on the device.
 * @param {{
 *  face: boolean,
 *  fingerprint: boolean,
 *  iris: boolean,
 * }} supportedBiometricAuthenticationTypes
 * @returns {string|null} proper biometric icon (null as fallback)
 */
export const getProperBiometricIcon = (supportedBiometricAuthenticationTypes = {}) => {
  const supportedTypesEntries = Object
    .entries(supportedBiometricAuthenticationTypes)
    .filter(([, isSupported]) => isSupported);
  if (!supportedTypesEntries.length) return null;
  const { fingerprint: isFingerprintSupported } = supportedBiometricAuthenticationTypes;
  if (supportedTypesEntries.length > 1) {
    if (isFingerprintSupported) return biometricIconDictionary.fingerprint;
    return biometricIconDictionary.face;
  }
  const [[onlySupportedType]] = supportedTypesEntries;
  return biometricIconDictionary[onlySupportedType];
};

/** Determine amount of padding to add to login view illustraion based on if banner
 * is present and size of device */
export const paddingTopToAdd = (isPreLoginBannerRendered, isSmallDevice) => {
  if (isSmallDevice && isPreLoginBannerRendered) {
    return 0;
  }
  if (isPreLoginBannerRendered) {
    return 58;
  }
  return 100;
};
