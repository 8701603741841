import styled from 'styled-components/native';

import { SecondaryHeader } from '../../../components/ScreenHeaders';

export const StyledSecondaryHeader = styled(SecondaryHeader)`
  background: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const StyledView = styled.View`
  background: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
  height: 100%;
`;
