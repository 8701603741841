import {
  StyledH2,
  StyledH3,
  StyledH4,
  StyledH5,
} from './styledComponents';

export const HEADER_DICT = {
  'header-four': StyledH5,
  'header-one': StyledH2,
  'header-three': StyledH4,
  'header-two': StyledH3,
};
