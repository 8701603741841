/* eslint-disable react/jsx-sort-props */
/* eslint-disable max-len, no-tabs, no-lone-blocks */
import React from 'react';
import Svg, {
  Circle,
  ClipPath,
  Defs,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function LegalFrames(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 251 315"
      fill="#000"
      height={moderateScale(315)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 251 315"
      width={moderateScale(251)}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Rect fill="#D9E1E8" x="28.8" y="89.7" className="st0" width="63.7" height="2.6" />
        <Rect fill="#D9E1E8" x="117.1" y="93.7" className="st0" width="86.5" height="2.6" />
        <Rect fill="#D9E1E8" x="22.8" y="286" className="st0" width="94.4" height="2.6" />
        <Rect fill="#D9E1E8" x="138.2" y="300.8" className="st0" width="59.7" height="2.6" />
        <Rect fill="#D9E1E8" x="48.5" y="213.2" className="st0" width="176.8" height="2.6" />
        <Rect fill="#FFFFFF" x="112.7" y="10.7" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 321.5992 104.5048)" className="st1" width="96.3" height="83" />
        <Rect fill="#BAC2CA" x="121.3" y="20.1" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 321.5992 104.5048)" className="st2" width="79" height="64.3" />
        <G>
          <Defs>
            <ClipPath id="SVGID_1_">
              <Rect x="121.3" y="20.1" width="79" height="64.3" />
            </ClipPath>
          </Defs>
          <G className="st3" clipPath="url(#SVGID_1_)">
            <Path
              fill="#FFFFFF"
              className="st1"
              d="M188.8,62.2c3.3,10.2-4.1,13.7-4.1,13.7l0,0.3c-1.9-7.5-7.1-13.9-14.3-17.2l-0.4-0.2
                c0.1-0.4,0.2-0.7,0.3-1.1c0.3-1.3,0.3-2.7,0.3-4l0.8,0.9c0,0,5,0.8,6.4-3.2s-0.3-8.9-7.5-15.2c-7.2-6.3-11.5-4.3-11.5-4.3
                s-1.8-1.4-4.5-1.4c-2.7,0-4.2,1.5-4.2,1.5l-0.3,0l0.1-0.1c0,0-4.3-2-11.5,4.3c-7.2,6.3-8.9,11.2-7.5,15.2s6.4,3.2,6.4,3.2
                l0.9-1.5c-0.1,1.5,0,3,0.3,4.6c0.4,2.2,1.3,3.9,2.3,5.3l0,0.3c0,0.1-2,2.4-2.1,5.9c-0.1,2.4,0,13.3,0,20.5c0,3.6,2.9,6.4,6.5,6.4
                h1c3.6,0,6.5-2.9,6.5-6.5l4.3,0.1c0,3.6,2.9,6.5,6.5,6.5h0.8c3.6,0,6.5-2.9,6.5-6.5v-0.1h3.5c-0.1,0.3-0.1,0.7-0.1,1
                c0,2.7,2.2,4.9,4.9,4.9h3.1c2.1,0,3.8-1.7,3.8-3.8l-0.1-10.5l0.3,1.7c5.2-3.1,6.7-7.9,6.7-7.9C195.2,67.4,188.8,62.2,188.8,62.2z
                "
            />
            <G>
              <Path fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" d="M141,63.3c0,0-2,2.3-2.1,5.9c-0.1,2.4,0,13.3,0,20.5c0,3.6,2.9,6.4,6.5,6.4h1c3.6,0,6.5-2.9,6.5-6.5v-5.3" />
              <Path fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" d="M157,84.4v5.4c0,3.6,2.9,6.5,6.5,6.5h0.8c3.6,0,6.5-2.9,6.5-6.5V79.1" />
              <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="170.8" y1="89.8" x2="173.9" y2="89.8" />
              <Path
                fill="none"
                stroke="#000000"
                strokeWidth="0.75px"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                className="st4"
                d="M170.6,59L170.6,59c8.8,4.1,14.6,12.6,15.1,22.3l0.1,10.6c0,2.1-1.7,3.8-3.8,3.8h-3.1
                c-2.7,0-4.9-2.2-4.9-4.9v0c0-2.7,2.2-4.9,4.9-4.9h0.6"
              />
              <Path fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" d="M184.9,75.9c0,0,7.4-3.5,4.1-13.7c0,0,6.4,5.2,3.7,13c0,0-1.5,4.8-6.7,7.9" />
              <Path fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" d="M148.5,58.8c0,0,6.2,6.6,13.5,0" />
              <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="155" y1="61.6" x2="155" y2="55.8" />
              <Circle cx="161" cy="46" r="1.1" />
              <Circle cx="149.6" cy="46" r="1.1" />
              <Ellipse cx="155.1" cy="51.6" rx="5" ry="2.3" />
              <Path d="M151,51.3l-0.9,0.2c0,0,0.4,4,4.9,4.6l0.3,0.1l0.5-4L151,51.3z" />
              <Path d="M159.2,51.3l0.9,0.2c0,0-0.4,4-4.9,4.6l-0.3,0.1l-0.5-4L159.2,51.3z" />
              <Path fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" d="M150.1,32c0,0-4.3-2-11.5,4.3c-7.2,6.3-8.9,11.2-7.5,15.2s6.4,3.2,6.4,3.2" />
              <Path fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" d="M145.2,36.8c0,0-8.6,9.6-6.5,20.9s15.7,11.3,15.7,11.3" />
              <Path
                fill="none"
                stroke="#000000"
                strokeWidth="0.75px"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                className="st4"
                d="M150.3,32.1c0,0,1.5-1.5,4.2-1.5S159,32,159,32s4.3-2,11.5,4.3s8.9,11.2,7.5,15.2c-1.4,3.9-6.4,3.2-6.4,3.2
              "
              />
              <Path fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" d="M164,36.8c0,0,8.6,9.6,6.5,20.9s-15.7,11.3-15.7,11.3" />
            </G>
          </G>
        </G>

        <Rect fill={svgHighlight} x="43.4" y="104.9" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 273.7238 318.2169)" className="st6" width="186.8" height="108.3" />

        <Rect fill="#FFFFFF" x="60" y="121.9" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 273.6749 317.9724)" className="st1" width="153.7" height="74.3" />
        <Polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st7" points="148.3,213.2 43.4,213.2 43.4,104.8 230.3,104.8 230.3,213.2 154.6,213.2 	" />
        <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="101.2,121.9 213.7,121.9 213.7,196.1 60,196.1 60,121.9 95.9,121.9 	" />
        <Polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st7" points="201.2,278.1 201.2,300.5 135.7,300.5 135.7,223.9 201.2,223.9 201.2,273.7 	" />
        <Polyline fill="#E6EBEF" className="st8" points="141.2,253.8 141.2,229.5 195.6,229.5 195.6,294.9 141.2,294.9 141.2,253.8 	" />
        <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="141.2,247.5 141.2,229.5 195.6,229.5 195.6,294.9 141.2,294.9 141.2,251.4 	" />
        <Polyline fill="#E6EBEF" className="st8" points="69,89.6 24.6,89.6 24.6,18.7 96.5,18.7 96.5,89.6 69,89.6 	" />

        <Rect fill="#FFFFFF" x="39" y="32.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 121.1604 108.3349)" className="st1" width="43.1" height="43.1" />
        <Polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st7" points="65.1,89.6 24.6,89.6 24.6,18.7 96.5,18.7 96.5,89.6 69.9,89.6 	" />

        <Rect fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x="39" y="32.6" transform="matrix(-1 -1.224647e-16 1.224647e-16 -1 121.1604 108.3349)" className="st4" width="43.1" height="43.1" />

        <Rect fill="none" stroke="#97A4B1" strokeWidth="2px" strokeMiterlimit="10" x="48.7" y="42.3" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 65.1146 135.3063)" className="st9" width="23.8" height="23.8" />

        <Rect fill="none" stroke="#97A4B1" strokeWidth="3px" strokeMiterlimit="10" x="54.7" y="48.3" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 65.1146 135.3063)" className="st10" width="11.7" height="11.7" />

        <Rect fill="#97A4B1" x="58.2" y="51.8" transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 65.1146 135.3063)" className="st11" width="4.7" height="4.7" />
        <Polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st7" points="178,10.7 208.9,10.7 208.9,93.8 112.7,93.8 112.7,10.7 173.5,10.7 	" />
        <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="121.3,58.7 121.3,20.1 200.3,20.1 200.3,84.4 121.3,84.4 121.3,62.8 	" />

        <Rect fill="#BAC2CA" x="39.1" y="203.9" transform="matrix(6.123234e-17 -1 1 6.123234e-17 -184.3864 325.0681)" className="st2" width="62.5" height="101.7" />
        <Polyline fill="#FFFFFF" className="st1" points="41.6,280.3 114.7,280.3 114.7,229.2 26,229.2 26,280.3 41.6,280.3 	" />
        <Polyline fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st7" points="47.4,223.5 121.2,223.5 121.2,286 19.5,286 19.5,223.5 41.8,223.5 	" />
        <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="49.3,280.3 114.7,280.3 114.7,229.2 26,229.2 26,280.3 45.2,280.3 	" />
        <G>
          <G>
            <Path
              fill="#FFFFFF"
              className="st1"
              d="M164.9,258.6c0,0-3.6-0.1-10.4,3.9c-6.8,3.9-10.2,12.4-5.9,17.9c4.3,5.5,11.1,1.9,11.1,1.9l18.2,0.2
            c0,0,8-1.2,8.1-7.8s-2.5-15.8-2.5-15.8L164.9,258.6z"
            />
            <Path
              fill="#FFFFFF"
              stroke="#000000"
              strokeWidth="0.65px"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              className="st12"
              d="M188.5,254.2c2.2-7.9,0.4-10.8,0.4-10.8c-5.5,0.1-8.8,3.5-8.8,3.5c-3.8-1.4-8.4,0-8.4,0s-3.3-3.4-8.8-3.5
            c0,0-1.8,3,0.4,10.8c0,0-3.3,8,4.5,11c7.8,3,12.8,1.1,16.4-0.2c3.6-1.3,6.1-5.3,4.8-9.1L188.5,254.2z"
            />
            <Path fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" d="M163.3,244c0,0,3.2,2.4,5,5.2c0,0-3.3,1.8-5,5.3" />
            <Path fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" d="M188.5,244c0,0-3.2,2.4-5,5.2c0,0,3.1,1.8,4.8,5.3" />
            <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="175.1" y1="260.2" x2="177" y2="260.2" />
            <Path fill="none" stroke="#000000" strokeWidth="0.45px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st13" d="M172.7,261.4c0,0,0.1,1.4,1.7,1.4c1.6,0,1.6-2.6,1.6-2.6" />
            <Path fill="none" stroke="#000000" strokeWidth="0.45px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st13" d="M179.3,261.4c0,0-0.1,1.4-1.7,1.4c-1.6,0-1.6-2.6-1.6-2.6" />
            <Path
              fill="none"
              stroke="#000000"
              strokeWidth="0.65px"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              className="st14"
              d="M150.1,274.6c0,0-0.3,2.7,2.4,3.8c2.4,1,6,0.4,9.3-2.6c0.8-0.7,2-0.7,2.7,0l0,0c0.8,0.8,0.8,2.1,0.1,2.9
            l-0.9,1c-2.9,2.4-5.1,3.5-8.3,3.4c-5.1-0.1-10.8-2.8-8.5-11c3.2-11.5,15.7-13.4,15.7-13.4"
            />
            <Path
              fill="none"
              stroke="#000000"
              strokeWidth="0.65px"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              className="st14"
              d="M159.9,282.7c0,0,8.1,0.1,14.4,0.1c1.5,0,2.8,0,3.8-0.1c2.2-0.1,3.9-1.9,3.9-4.1l0,0c0-2.2-1.8-4.1-4.1-4.1
            c-2.1,0-4.5,0-6.3-0.3"
            />
            <Path fill="none" stroke="#000000" strokeWidth="0.65px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st14" d="M185.1,264.8c0,0,4,16.2-5.5,17.5" />
          </G>
          <Circle cx="169.8" cy="255.8" r="0.8" />
          <Circle cx="181.5" cy="255.8" r="0.8" />
        </G>
        <Polyline fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" points="56.9,171.4 56.9,199.4 80.7,199.4 	" />
        <Polyline fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" points="216.9,146.7 216.9,118.8 172.3,118.8 	" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="168.2" y1="118.8" x2="145.7" y2="118.8" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="56.8" y1="168.3" x2="56.8" y2="161.4" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="84.6" y1="199.3" x2="106.7" y2="199.3" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="27" y1="87.3" x2="37.3" y2="77.1" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="93.9" y1="87.3" x2="83.6" y2="77.1" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="34.7" y1="28.9" x2="37.3" y2="31.4" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="27" y1="21.2" x2="33.2" y2="27.4" />
        <Line fill="none" stroke="#000000" strokeWidth="0.55px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st15" x1="93.9" y1="21.2" x2="83.6" y2="31.4" />
        <G>
          <Polyline fill="#E6EBEF" className="st8" points="113.9,180.5 113.9,152.4 124.4,152.4 124.4,180.5 		" />
          <Polyline fill="#E6EBEF" className="st8" points="158.3,180.5 158.3,152.4 168.4,142.7 177.8,152.4 177.8,180.5 		" />
          <Rect fill="#BAC2CA" x="129.4" y="162.5" className="st2" width="7.7" height="11.5" />
          <Path
            fill="#BAC2CA"
            className="st2"
            d="M106.7,180.6H98c-0.1,0-0.1-0.1-0.1-0.1v-13.7c0-2.5,2-4.5,4.5-4.5h0c2.5,0,4.5,2,4.5,4.5v13.7
          C106.8,180.5,106.7,180.6,106.7,180.6z"
          />
          <Path fill="none" stroke="#000000" strokeWidth="0.65px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st14" d="M97.8,180.6v-13.9c0-2.4,2-4.4,4.4-4.4h0.2c2.4,0,4.4,2,4.4,4.4v13.9" />
          <Polygon fill="#BAC2CA" className="st2" points="154.3,157.6 124.5,157.6 110.9,142.3 168.5,142.3 		" />
          <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="151.1,142.3 168.5,142.3 154.3,157.6 124.5,157.6 110.7,142.3 148,142.3 		" />
          <Polygon fill="#BAC2CA" className="st2" points="115.7,157.6 124.6,157.6 110.4,142.3 101.5,142.3 		" />
          <Polygon fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="101.5,142.3 115.7,157.6 124.5,157.6 110.8,142.3 		" />
          <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="168.5,142.3 182,157.6 179.2,157.6 		" />
          <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="101.5,142.3 88,157.6 90.7,157.6 		" />
          <Polyline fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" points="124.2,158.1 124.2,180.8 177.9,180.8 177.9,153.2 		" />
          <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="158.5" y1="173.2" x2="158.5" y2="180.5" />
          <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="158.5" y1="156.1" x2="158.5" y2="170.3" />
          <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="113.9" y1="156.1" x2="113.9" y2="178.5" />
          <Rect fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x="129.3" y="162.5" className="st5" width="8.1" height="11.7" />
          <Rect fill="#BAC2CA" x="143.8" y="162.5" className="st2" width="7.7" height="11.5" />
          <Rect fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x="143.7" y="162.5" className="st5" width="8.1" height="11.7" />
          <Ellipse fill="#E6EBEF" stroke="#000000" strokeWidth="0.65px" strokeMiterlimit="10" className="st16" cx="102.1" cy="154.1" rx="1.8" ry="2.6" />
          <Ellipse fill="none" stroke="#000000" strokeWidth="0.35px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st17" cx="102.1" cy="154.1" rx="1.8" ry="2.6" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="202.4" y1="180.8" x2="206" y2="180.8" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="72.7" y1="180.8" x2="199" y2="180.8" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="68.1" y1="180.8" x2="69.8" y2="180.8" />
          <Path
            fill="#E6EBEF"
            className="st8"
            d="M201,160.3L201,160.3c-3.3-19.8-7.9-18.9-7.9-18.9l0,0.1l0-0.1c0,0-4.4-0.9-7.7,18.9l0,0
          c-0.1,0.6-0.2,1.2-0.2,1.9c0,5.3,3.5,9.7,7.9,9.7c4.4,0,7.9-4.3,7.9-9.7C201.1,161.6,201.1,160.9,201,160.3z"
          />
          <Path
            className="st2"
            fill="#BAC2CA"
            d="M201.1,162.2c0-0.6-0.1-1.3-0.2-1.9l0,0c-3.3-19.8-7.9-18.9-7.9-18.9l0,0.1l0-0.1c0,0,0.1-0.1-0.4,0.1
          c1.2,2.4,4.6,10.9,5.3,19.2c0.6,7-1.9,10.3-4.9,11.1c0,0,0,0,0.1,0C197.6,171.9,201.1,167.5,201.1,162.2z"
          />
          <Path
            fill="none"
            stroke="#000000"
            strokeWidth="0.75px"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            className="st4"
            d="M187.5,150.6c-0.7,2.5-1.4,5.7-2.1,9.8l0,0c-0.1,0.6-0.2,1.2-0.2,1.9c0,5.3,3.5,9.7,7.9,9.7
          c4.4,0,7.9-4.3,7.9-9.7c0-0.6-0.1-1.3-0.2-1.9l0,0c-3.3-19.8-7.9-18.9-7.9-18.9l0,0.1l0-0.1c0,0-2.1-0.4-4.5,5.7"
          />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="193.1" y1="159.6" x2="193.1" y2="180.9" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="190.1" y1="164" x2="193" y2="166.1" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="196.1" y1="166.4" x2="193.2" y2="168.5" />
          <Path
            fill="#E6EBEF"
            className="st8"
            d="M77.9,175.2L77.9,175.2c-3.2,0-5.8-2.6-5.8-5.8v-12.5c0-3.2,2.6-5.8,5.8-5.8h0c3.2,0,5.8,2.6,5.8,5.8v12.5
          C83.8,172.6,81.1,175.2,77.9,175.2z"
          />
          <Path
            className="st2"
            fill="#BAC2CA"
            d="M80.6,151.7c0.7,1,1.1,2.1,1.1,3.4v12.5c0,3.2-2.6,5.8-5.8,5.8l0,0c-1,0-1.9-0.2-2.7-0.7
          c1.1,1.5,2.8,2.4,4.7,2.4c3.2,0,5.8-2.6,5.8-5.8v-12.5C83.8,154.6,82.5,152.7,80.6,151.7z"
          />
          <Path
            fill="none"
            stroke="#000000"
            strokeWidth="0.75px"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            className="st4"
            d="M77.9,175.2L77.9,175.2c-3.2,0-5.8-2.6-5.8-5.8v-12.5c0-3.2,2.6-5.8,5.8-5.8h0c3.2,0,5.8,2.6,5.8,5.8v12.5
          C83.8,172.6,81.1,175.2,77.9,175.2z"
          />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="77.8" y1="161.7" x2="77.8" y2="180.5" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="74.9" y1="167" x2="77.7" y2="169.1" />
          <Line fill="none" stroke="#000000" strokeWidth="0.75px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st4" x1="91.6" y1="155.2" x2="91.6" y2="180.5" />
        </G>
        <Circle fill={svgHighlight} className="st6" cx="64.7" cy="251.8" r="14" />
        <Polygon fill="#BAC2CA" className="st2" points="65.4,269.5 83.5,243.8 101.6,269.5 	" />
        <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="38.7" y1="260.7" x2="80.7" y2="260.7" />
        <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="38.7" y1="256.9" x2="80.7" y2="256.9" />
        <Line fill="none" stroke="#000000" strokeWidth="0.5px" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" className="st5" x1="38.7" y1="253.1" x2="80.7" y2="253.1" />
      </G>
    </Svg>
  );
}
