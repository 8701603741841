import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P4 } from '../../../../base_ui';

export const Container = styled.View`
  align-items: ${({ isReview }) => isReview ? 'flex-start' : 'center'};
  display: flex;
  flex-direction: row;
  padding-bottom: ${({ noBottomPadding }) => noBottomPadding ? '0' : `${moderateScale(16)}px`};
`;

export const IconWrapper = styled.View`
  padding-top: ${({ isReview }) => isReview ? `${moderateScale(5)}px;` : 0};
`;

export const StyledText = styled(P4)`
  flex-shrink: 1;
  padding-left: ${moderateScale(8)}px;
`;
