import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeInput } from '@dmi/shared/redux/Calculators/Amortize/actions';
import {
  makeSelectAmortize,
  selectCalendarProps,
  selectFormValues,
} from '@dmi/shared/redux/Calculators/Amortize/selectors';

import { StyledScrollView } from '../../components/base_ui';
import CalendarView from '../../components/Calculators/Calendar';

const CalendarScreen = ({
  calendarProps,
  dispatchChangeInput,
  formValues: { startDate },
  navigation,
  selectedAdjustmentFilter,
}) => {
  const handleUpdateSelectedDate = (value) => {
    dispatchChangeInput({
      field: 'startDate',
      form: selectedAdjustmentFilter,
      value,
    });
  };

  return (
    <StyledScrollView>
      <CalendarView
        calendarProps={calendarProps}
        handleUpdateSelectedDate={handleUpdateSelectedDate}
        navigation={navigation}
        selectedAdjustmentFilter={selectedAdjustmentFilter}
        selectedDate={startDate}
      />
    </StyledScrollView>
  );
};

CalendarScreen.propTypes = {
  calendarProps: T.object.isRequired,
  dispatchChangeInput: T.func.isRequired,
  formValues: T.object.isRequired,
  navigation: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Amortize
   */
  calendarProps: selectCalendarProps(),
  formValues: selectFormValues(),
  selectedAdjustmentFilter: makeSelectAmortize('selectedAdjustmentFilter'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Amortize
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(CalendarScreen);
