/* eslint-disable max-len */
import { CHANGE_EMAIL_CONFIRMATION_DICTIONARY } from '@dmi/shared/redux/Register/ChangeEmail/constants';
import { INTL_IDS } from '@dmi/shared/redux/Register/ChangeEmail/messages';

export const CHANGE_EMAIL_CONFIRMATION_PROPS = {
  cardAnimation: {
    mobile: 'robotMobile',
    web: 'robotDesktop',
  },
  cardDictionary: CHANGE_EMAIL_CONFIRMATION_DICTIONARY,
};

export const CHANGE_EMAIL_STEP_ONE_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerClipboard',
    web: 'desktopRegisterClipboard',
  },
  cardText: INTL_IDS.CHANGE_EMAIL_STEP_ONE_FORM_PARAGRAPH,
  cardTitle: INTL_IDS.CHANGE_EMAIL_STEP_ONE_FORM_HEADING,
  cardTooltip: false,
};

export const CHANGE_EMAIL_STEP_TWO_CARD_PROPS = {
  cardIllustration: {
    mobile: 'registerClipboard',
    web: 'desktopRegisterClipboard',
  },
  cardText: INTL_IDS.CHANGE_EMAIL_STEP_TWO_FORM_PARAGRAPH,
  cardTitle: INTL_IDS.CHANGE_EMAIL_STEP_TWO_FORM_HEADING,
  cardTooltip: false,
};
