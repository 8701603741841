import React from 'react';
import T from 'prop-types';

import {
  MOBILE_REDIRECT_LINK_DICT,
  REDIRECT_CARD_PROPS_DICT,
} from '@dmi/shared/redux/GoldRedirect/constants';

import { H2 } from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import {
  Container,
  IllustrationWrapper,
  StyledLink,
  StyledP2,
  StyledPrimaryDivider,
  TextWrapper,
} from './styledComponents';

const RedirectDogIllustration = iconDictionary('redirectDog');

const GoldRedirectCard = ({ linkOnPress, navigation, routeName }) => {
  const {
    header,
    label,
    mobilePath,
    text,
  } = REDIRECT_CARD_PROPS_DICT[MOBILE_REDIRECT_LINK_DICT[routeName]];

  return (
    <Container>
      <IllustrationWrapper>{RedirectDogIllustration}</IllustrationWrapper>
      <TextWrapper>
        <H2>{header}</H2>
        <StyledP2>{text}</StyledP2>
      </TextWrapper>
      <StyledPrimaryDivider />
      <StyledLink
        label={label}
        onPressFunc={() => {
          navigation.replace(mobilePath);
          if (linkOnPress) linkOnPress();
        }}
        path={mobilePath}
        size="medium"
      />
    </Container>
  );
};

GoldRedirectCard.propTypes = {
  linkOnPress: T.func,
  navigation: T.object.isRequired,
  routeName: T.string.isRequired,
};

export default GoldRedirectCard;
