import React from 'react';
import T from 'prop-types';

import { P3 } from '../../Paragraphs';
import { DisabledMessageContainer, StyledDisabledText } from './styledComponents';

const DisabledMessage = ({ label, text }) => (
  <DisabledMessageContainer>
    <P3>{label}</P3>
    <StyledDisabledText>{text}</StyledDisabledText>
  </DisabledMessageContainer>
);

DisabledMessage.propTypes = {
  label: T.string.isRequired,
  text: T.string.isRequired,
};

export default DisabledMessage;
