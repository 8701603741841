import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectHasLossDraftClaims } from '@dmi/shared/redux/LossDraft/selectors';
import { selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import DisasterAssistance from '../../components/Help/DisasterAssistance';

const DisasterAssistanceScreen = ({
  hasLossDraftClaims,
  isMurabahaAccount,
  navigation,
}) => (
  <DisasterAssistance
    hasLossDraftClaims={hasLossDraftClaims}
    isMurabahaAccount={isMurabahaAccount}
    navigation={navigation}
  />
);

DisasterAssistanceScreen.propTypes = {
  hasLossDraftClaims: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  hasLossDraftClaims: selectHasLossDraftClaims(),
  /**
   * Store: Main
   */
  isMurabahaAccount: selectIsMurabahaAccount(),
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(DisasterAssistanceScreen);
