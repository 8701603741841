import React from 'react';
import T from 'prop-types';

import { H3 } from '../../../base_ui';
import {
  HeaderWrapper,
  LabelWrapper,
  PaymentDetailContainer,
  PaymentDetailRow,
  PaymentDetailRows,
  TextWrapper,
} from './styledComponents';

const EscrowPaymentDetail = ({
  amountPaid = '',
  transactionDate = '',
  transactionDescription = '',
}) => (
  <PaymentDetailContainer>
    <HeaderWrapper>
      <H3>{transactionDate}</H3>
      <H3>{amountPaid}</H3>
    </HeaderWrapper>
    <PaymentDetailRows>
      <PaymentDetailRow>
        <LabelWrapper>Description</LabelWrapper>
        <TextWrapper>{transactionDescription}</TextWrapper>
      </PaymentDetailRow>
    </PaymentDetailRows>
  </PaymentDetailContainer>
);

EscrowPaymentDetail.propTypes = {
  amountPaid: T.string,
  transactionDate: T.string,
  transactionDescription: T.string,
};

export default EscrowPaymentDetail;
