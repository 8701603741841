/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavDocumentsDefaultMIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M14.7702 3.93L11.9102 1.19C11.8023 1.07734 11.6559 1.0095 11.5002 1H3.75016C3.59368 1 3.44361 1.06216 3.33296 1.17281C3.22232 1.28345 3.16016 1.43352 3.16016 1.59V16.39C3.16016 16.5465 3.22232 16.6965 3.33296 16.8072C3.44361 16.9178 3.59368 16.98 3.75016 16.98H14.3602C14.5176 16.98 14.6687 16.9182 14.7809 16.8078C14.8932 16.6974 14.9575 16.5474 14.9602 16.39V4.35C14.9595 4.27062 14.9422 4.19226 14.9095 4.11994C14.8768 4.04761 14.8293 3.98292 14.7702 3.93ZM11.5802 2.52L13.2702 4.13H11.5802V2.52ZM13.7702 15.82H4.34016V2.21H10.6402V4.6C10.6401 4.72559 10.6893 4.84619 10.7772 4.93592C10.8651 5.02566 10.9846 5.07738 11.1102 5.08H13.7702V15.82Z" fill="black" />
        <Path d="M5.99976 6.81004C5.99976 6.87308 6.01217 6.93549 6.0363 6.99373C6.06042 7.05197 6.09577 7.10488 6.14035 7.14945C6.18492 7.19403 6.23783 7.22938 6.29607 7.2535C6.35431 7.27763 6.41672 7.29004 6.47976 7.29004H11.3998C11.5324 7.29004 11.6595 7.23736 11.7533 7.1436C11.8471 7.04983 11.8998 6.92265 11.8998 6.79004C11.8998 6.65743 11.8471 6.53026 11.7533 6.43649C11.6595 6.34272 11.5324 6.29004 11.3998 6.29004H6.49976C6.43155 6.28695 6.36347 6.29845 6.30006 6.32377C6.23665 6.34909 6.17937 6.38764 6.13205 6.43686C6.08472 6.48608 6.04844 6.54482 6.02563 6.60917C6.00282 6.67353 5.994 6.74201 5.99976 6.81004Z" fill="black" />
        <Path d="M11.4203 9.00012H6.50027C6.43212 8.9902 6.36262 8.99507 6.29651 9.01439C6.23041 9.03371 6.16923 9.06704 6.11714 9.1121C6.06505 9.15716 6.02327 9.2129 5.99463 9.27554C5.96599 9.33818 5.95117 9.40624 5.95117 9.47512C5.95117 9.54399 5.96599 9.61206 5.99463 9.6747C6.02327 9.73734 6.06505 9.79308 6.11714 9.83814C6.16923 9.8832 6.23041 9.91652 6.29651 9.93584C6.36262 9.95516 6.43212 9.96003 6.50027 9.95012H11.4203C11.5344 9.93351 11.6387 9.87637 11.7142 9.78915C11.7896 9.70192 11.8312 9.59045 11.8312 9.47512C11.8312 9.35979 11.7896 9.24831 11.7142 9.16109C11.6387 9.07386 11.5344 9.01672 11.4203 9.00012Z" fill="black" />
        <Path d="M11.4195 11.6399H6.49953C6.37394 11.6399 6.25334 11.6891 6.16361 11.7769C6.07387 11.8648 6.02215 11.9843 6.01953 12.1099C6.02209 12.2364 6.07348 12.357 6.16295 12.4465C6.25242 12.5359 6.37303 12.5873 6.49953 12.5899H11.4195C11.5451 12.5873 11.6646 12.5356 11.7525 12.4458C11.8404 12.3561 11.8896 12.2355 11.8895 12.1099C11.8895 11.9852 11.84 11.8657 11.7519 11.7776C11.6637 11.6894 11.5442 11.6399 11.4195 11.6399Z" fill="black" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="18" width="18" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
