import React, { Fragment } from 'react';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { Skeleton as BaseSkeleton } from '../../../../base_ui';
import {
  CenterContainer,
  Container,
  RowContainer,
  RowSubcontainer,
  SmallLabelContainer,
  StyledPrimaryDivider,
} from './styledComponents';

const rowArr = new Array(6).fill(null);

/* eslint-disable react/no-array-index-key */
const Skeleton = () => (
  <Container>
    <BaseSkeleton
      $bottomMargin={`${moderateScale(7)}px`}
      height={`${moderateScale(14)}px`}
      width="100%"
    />
    <BaseSkeleton
      $bottomMargin={`${moderateScale(26)}px`}
      height={`${moderateScale(14)}px`}
      width={`${moderateScale(100)}px`}
    />
    <SmallLabelContainer>
      <BaseSkeleton
        $rightMargin={`${moderateScale(40)}px`}
        height={`${moderateScale(10)}px`}
        width={`${moderateScale(30)}px`}
      />
      <BaseSkeleton
        height={`${moderateScale(10)}px`}
        width={`${moderateScale(30)}px`}
      />
    </SmallLabelContainer>
    <StyledPrimaryDivider />
    {rowArr.map((v, i) => (
      <Fragment key={i}>
        <RowContainer>
          <BaseSkeleton
            height={`${moderateScale(14)}px`}
            width={`${moderateScale(150)}px`}
          />
          <RowSubcontainer>
            <BaseSkeleton
              $rightMargin={`${moderateScale(52)}px`}
              height={`${moderateScale(16)}px`}
              width={`${moderateScale(16)}px`}
            />
            <BaseSkeleton
              height={`${moderateScale(16)}px`}
              width={`${moderateScale(16)}px`}
            />
          </RowSubcontainer>
        </RowContainer>
        <StyledPrimaryDivider />
      </Fragment>
    ))}
    <CenterContainer>
      <BaseSkeleton
        height={`${moderateScale(46)}px`}
        width={`${moderateScale(260)}px`}
      />
    </CenterContainer>
  </Container>
);

export default Skeleton;
