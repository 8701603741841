/*
 * Add Escrow Upload Review Screen
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  chooseDocument,
  processDocument,
  resetBannerError,
  resetDocumentState,
  resetFormState,
  resetRequestStatusState,
  resetStepState,
  uploadDocument,
} from '@dmi/shared/redux/Mortgage/actions';
import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';
import {
  selectAddEscrowOption,
  selectBannerError,
  selectDocumentRequestBody,
  selectFormErrors,
  selectRequestStatus,
} from '@dmi/shared/redux/Mortgage/selectors';

import ReviewScene from '../../../components/DocUpload/ReviewScene';

import { StyledScreenWrapper, StyledView } from './styledComponents';

const AddEscrowReviewDocScreen = ({
  addEscrowOption,
  bannerError,
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  dispatchProcessDocument,
  dispatchResetBannerError,
  dispatchResetDocumentState,
  dispatchResetFormState,
  dispatchResetStepState,
  dispatchResetUploadDocumentRequestStatus,
  dispatchUploadDocument,
  navigation,
  processDocumentRequestStatus,
  uploadDocumentBody,
  uploadDocumentFormErrors: {
    fileInput: validationErrorMessage,
    secondFileInput: validationErrorMessage2,
  },
  uploadDocumentRequestStatus,
}) => {
  const dictToUse = addEscrowOption === 'Both' ? 'multiDocReviewDict' : 'singleDocReviewDict';
  const isFirstFileChosen = uploadDocumentBody.documentSize > 0;
  const isSecondFileChosen = !!uploadDocumentBody.doc2;
  const isSecondFileStep = addEscrowOption === 'Both' && isSecondFileChosen > 0;
  const twoFilesChosen = isFirstFileChosen && isSecondFileChosen;
  const secondFileName = isSecondFileChosen ? uploadDocumentBody.doc2.documentName : null;
  const indexToUse = addEscrowOption === 'Both' && isSecondFileChosen ? 1 : 0;
  const buttonLabelToUse = (addEscrowOption === 'Both' && !twoFilesChosen) ? 'Next Step' : 'Submit';

  const {
    header,
    text,
  } = ADD_ESCROW_DICT.docReview[dictToUse][indexToUse];

  useEffect(() => {
    dispatchResetUploadDocumentRequestStatus({ state: 'chooseDocument' });
    dispatchResetUploadDocumentRequestStatus({ state: 'processDocument' });
    dispatchResetUploadDocumentRequestStatus({ state: 'uploadDocument' });
    dispatchResetStepState();
  }, [
    dispatchResetUploadDocumentRequestStatus,
    dispatchResetStepState,
    dispatchProcessDocument,
    uploadDocumentBody,
  ]);

  const customBackHandler = () => {
    if (isSecondFileChosen) {
      dispatchResetDocumentState({ doc: 'secondFileInput' });
    } else {
      dispatchResetFormState({ form: 'uploadDocument' });
    }
    navigation.navigate('AddEscrowUploadDoc');
  };

  const handleButtonPress = () => {
    if (addEscrowOption !== 'Both' || twoFilesChosen) {
      dispatchUploadDocument(uploadDocumentBody);
    } else if (isFirstFileChosen && !isSecondFileChosen) {
      navigation.navigate('AddEscrowUploadDoc');
    }
  };

  const startOverHandler = () => {
    dispatchResetBannerError();
    dispatchResetFormState({ form: 'uploadDocument' });
    navigation.navigate('AddEscrowSetUp');
  };

  return (
    <StyledScreenWrapper>
      <StyledView>
        <ReviewScene
          bannerError={bannerError}
          bottomButtonProps={{
            label: buttonLabelToUse,
            onPress: handleButtonPress,
            status: uploadDocumentRequestStatus,
          }}
          chooseDocumentRequestStatus={chooseDocumentRequestStatus}
          customBackHandler={customBackHandler}
          dispatchChooseDocument={dispatchChooseDocument}
          errorStartOverHandler={startOverHandler}
          fileName={uploadDocumentBody.documentName}
          fileName2={secondFileName}
          header={header}
          headerSubText={text}
          isMultiDoc={addEscrowOption === 'Both'}
          isSecondFileStep={isSecondFileStep}
          navigation={navigation}
          processDocumentRequestStatus={processDocumentRequestStatus}
          validationErrorMessage={validationErrorMessage}
          validationErrorMessage2={validationErrorMessage2}
        />
      </StyledView>
    </StyledScreenWrapper>
  );
};

AddEscrowReviewDocScreen.propTypes = {
  addEscrowOption: T.string.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  dispatchProcessDocument: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetDocumentState: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetStepState: T.func.isRequired,
  dispatchResetUploadDocumentRequestStatus: T.func.isRequired,
  dispatchUploadDocument: T.func.isRequired,
  navigation: T.object.isRequired,
  processDocumentRequestStatus: T.string.isRequired,
  uploadDocumentBody: T.object.isRequired,
  uploadDocumentFormErrors: T.object.isRequired,
  uploadDocumentRequestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  addEscrowOption: selectAddEscrowOption(),
  bannerError: selectBannerError(),
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
  errors: selectFormErrors('uploadDocument'),
  processDocumentRequestStatus: selectRequestStatus('processDocument'),
  status: selectRequestStatus('uploadDocument'),
  uploadDocumentBody: selectDocumentRequestBody('addEscrow'),
  uploadDocumentFormErrors: selectFormErrors('uploadDocument'),
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Mortgage
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchProcessDocument: (payload) => dispatch(processDocument(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetDocumentState: (payload) => dispatch(resetDocumentState(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetStepState: () => dispatch(resetStepState()),
  dispatchResetUploadDocumentRequestStatus: (payload) => dispatch(resetRequestStatusState(payload)),
  dispatchUploadDocument: (payload) => dispatch(uploadDocument(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEscrowReviewDocScreen);
