import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import { useFocusEffect } from '@react-navigation/native';

import {
  changeFormFieldValue,
  changeSelectedMessageId,
  changeStep,
  chooseDocument,
  fetchAttachment,
  fetchMessageDetails,
  fetchMessages,
  postReply,
  processDocument,
  removeAttachment,
  resetFormState,
  resetRequestStatus,
  resetState,
  resetStep,
  setAttachmentFile,
  toggleAttachmentDrawer,
  updateAttachments,
} from '@dmi/shared/redux/SecureMessaging/actions';
import makeSelectMessages, {
  selectAttachment,
  selectConfirmationData,
  selectErrors,
  selectForm,
  selectFormErrors,
  selectIsMessageCached,
  selectIsMessageStatusClosed,
  selectRequestStatus,
  selectSecureMessagingScreenReaderNotifications,
  selectSelectedMessageDetails,
  selectStep,
} from '@dmi/shared/redux/SecureMessaging/selectors';
import {
  ERROR_CONFIRMATION_TEXT,
  POST_MESSAGE_FAILURE_ERROR_MESSAGE,
  POST_MESSAGE_SUCCESS_MESSAGE,
  SUCCESS_CONFIRMATION_TEXT,
} from '@dmi/shared/redux/SecureMessaging/constants';
import { getMainClientInfo, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import PDFDisplay from '../../../components/PDFDisplay';
import { ConditionalRender } from '../../../components/base_ui';
import AsyncRender from '../../../components/AsyncRender';
import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';
import viewDictionary from './viewDictionary';
import { StyledView } from '../styledComponents';

const MessageDetails = ({
  attachment,
  bannerError,
  confirmationData,
  customerServicePhoneNumber,
  dispatchChangeFormFieldValue,
  dispatchChangeSelectedMessageId,
  dispatchChangeStep,
  dispatchChooseDocument,
  dispatchFetchAttachment,
  dispatchFetchMessageDetails,
  dispatchFetchMessages,
  dispatchPostReply,
  dispatchProcessDocument,
  dispatchRemoveAttachment,
  dispatchResetFormState,
  dispatchResetRequestStatus,
  dispatchResetState,
  dispatchResetStep,
  dispatchSetAttachmentFile,
  dispatchToggleDrawer,
  dispatchUpdateAttachments,
  formAttachments,
  formReply,
  formUploadError,
  isDrawerOpen,
  isMessageCached,
  isMessageStatusClosed,
  isMurabahaAccount,
  messageDetails,
  navigation,
  notifications,
  requestStatusChooseDoc,
  requestStatusFetchMessageDetails,
  requestStatusPostReply,
  selectedMessageId,
  step,
}) => {
  const [showReplyBox, setShowReplyBox] = useState(false);

  useFocusEffect(
    useCallback(() => () => {
      dispatchFetchMessages();
      dispatchResetRequestStatus({ requestStatus: 'fetchMessageDetails' });
      dispatchResetRequestStatus({ requestStatus: 'postReply' });
      dispatchResetRequestStatus({ requestStatus: 'processDocument' });
      dispatchResetRequestStatus({ requestStatus: 'chooseDocument' });
      dispatchResetStep();
      dispatchResetFormState({ form: 'reply' });
    }, [
      dispatchFetchMessages,
      dispatchResetFormState,
      dispatchResetRequestStatus,
      dispatchResetStep,
    ]),
  );

  useEffect(() => {
    if (!!selectedMessageId && requestStatusFetchMessageDetails !== 'failed') {
      if (!isMessageCached || requestStatusFetchMessageDetails === 'idle') {
        dispatchFetchMessageDetails({ messageId: selectedMessageId });
      }
    }
  }, [
    dispatchFetchMessageDetails,
    isMessageCached,
    requestStatusFetchMessageDetails,
    selectedMessageId,
  ]);

  const handleBackPress = () => {
    dispatchResetRequestStatus({ isAttachment: true, requestStatus: 'chooseDocument' });
    dispatchResetRequestStatus({ isAttachment: true, requestStatus: 'processDocument' });
    dispatchChangeStep(1);
    setShowReplyBox(true);
  };

  const ViewToRender = viewDictionary[step];

  const isLoading = ['idle', 'loading'].includes(requestStatusFetchMessageDetails);

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <StyledView>
        <AsyncRender
          Component={ViewToRender}
          error={
            (!selectedMessageId && !isLoading) ||
            requestStatusFetchMessageDetails === 'failed'
          }
          loading={isLoading}
          propsToPassDown={{
            bannerError,
            confirmationData,
            customerServicePhoneNumber,
            dispatchChangeFormFieldValue,
            dispatchChangeSelectedMessageId,
            dispatchChangeStep,
            dispatchChooseDocument,
            dispatchFetchAttachment,
            dispatchPostReply,
            dispatchProcessDocument,
            dispatchRemoveDocument: dispatchRemoveAttachment,
            dispatchResetFormState,
            dispatchResetRequestStatus,
            dispatchResetState,
            dispatchSetAttachmentFile,
            dispatchToggleDrawer,
            dispatchUpdateAttachments,
            errorSubHeader: POST_MESSAGE_FAILURE_ERROR_MESSAGE,
            errorText: ERROR_CONFIRMATION_TEXT,
            formAttachments,
            formReply,
            formUploadError,
            handleBack: handleBackPress,
            isMessageCached,
            isMessageStatusClosed,
            isMurabahaAccount,
            messageDetails,
            navigation,
            requestStatusFetchMessageDetails,
            requestStatusPostReply,
            requestStatusProcessDoc: requestStatusChooseDoc,
            reviewScreenName: 'MessageDetails',
            selectedMessageId,
            setShowReplyBox,
            showReplyBox,
            successHeading: POST_MESSAGE_SUCCESS_MESSAGE,
            successText: SUCCESS_CONFIRMATION_TEXT,
          }}
        />
      </StyledView>
      <ConditionalRender
        Component={PDFDisplay}
        propsToPassDown={{
          dispatchSetFile: dispatchSetAttachmentFile,
          dispatchToggleDrawer,
          file: attachment,
          isDrawerOpen,
        }}
        shouldRender={Platform.OS === 'ios' && !isEmpty(attachment)}
      />
    </Fragment>
  );
};

MessageDetails.propTypes = {
  attachment: T.object.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  confirmationData: T.array.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeSelectedMessageId: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  dispatchFetchAttachment: T.func.isRequired,
  dispatchFetchMessageDetails: T.func.isRequired,
  dispatchFetchMessages: T.func.isRequired,
  dispatchPostReply: T.func.isRequired,
  dispatchProcessDocument: T.func.isRequired,
  dispatchRemoveAttachment: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchResetStep: T.func.isRequired,
  dispatchSetAttachmentFile: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  dispatchUpdateAttachments: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formReply: T.object.isRequired,
  formUploadError: T.string.isRequired,
  isDrawerOpen: T.bool.isRequired,
  isMessageCached: T.bool.isRequired,
  isMessageStatusClosed: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  messageDetails: T.object.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  requestStatusChooseDoc: T.string.isRequired,
  requestStatusFetchMessageDetails: T.string.isRequired,
  requestStatusPostReply: T.string.isRequired,
  selectedMessageId: T.string.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  /**
   * Store: SecureMessaging
   */
  // eslint-disable-next-line sort-keys
  attachment: selectAttachment('file'),
  bannerError: selectErrors('bannerError'),
  confirmationData: selectConfirmationData('postReply'),
  formAttachments: selectForm('attachments'),
  formReply: selectForm('reply'),
  formUploadError: selectFormErrors('attachments'),
  isDrawerOpen: selectAttachment('isDrawerOpen'),
  isMessageCached: selectIsMessageCached(),
  isMessageStatusClosed: selectIsMessageStatusClosed(),
  messageDetails: selectSelectedMessageDetails(),
  notifications: selectSecureMessagingScreenReaderNotifications(),
  requestStatusChooseDoc: selectRequestStatus('chooseDocument'),
  requestStatusFetchMessageDetails: selectRequestStatus('fetchMessageDetails'),
  requestStatusPostReply: selectRequestStatus('postReply'),
  selectedMessageId: makeSelectMessages('selectedMessageId'),
  step: selectStep(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: SecureMessaging
   */
  dispatchChangeFormFieldValue: (payload) => dispatch(changeFormFieldValue(payload)),
  dispatchChangeSelectedMessageId: (payload) => dispatch(changeSelectedMessageId(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchFetchAttachment: (payload) => dispatch(fetchAttachment(payload)),
  dispatchFetchMessageDetails: (payload) => dispatch(fetchMessageDetails(payload)),
  dispatchFetchMessages: (payload) => dispatch(fetchMessages(payload)),
  dispatchPostReply: (payload) => dispatch(postReply(payload)),
  dispatchProcessDocument: (payload) => dispatch(processDocument(payload)),
  dispatchRemoveAttachment: (payload) => dispatch(removeAttachment(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
  dispatchResetState: (payload) => dispatch(resetState(payload)),
  dispatchResetStep: () => dispatch(resetStep()),
  dispatchSetAttachmentFile: (payload) => dispatch(setAttachmentFile(payload)),
  dispatchToggleDrawer: (payload) => dispatch(toggleAttachmentDrawer(payload)),
  dispatchUpdateAttachments: (payload) => dispatch(updateAttachments(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MessageDetails);
