import { SelectDateScreen, ViewStatement } from '../../../components/Payoff';
import GeneralDocsScreen from '../../../containers/Documents/GeneralDocsScreen';
import PayoffHomeScreen from '../../../containers/Payoff';
import StatementsScreen from '../../../containers/Documents/StatementsScreen';

export default {
  GeneralDocs: { component: GeneralDocsScreen },
  Statements: { component: StatementsScreen },
  // Payoff Screens
  // eslint-disable-next-line sort-keys
  Payoff: { component: PayoffHomeScreen },
  SelectDateScreen: { component: SelectDateScreen },
  ViewStatement: { component: ViewStatement },
};
