/**
 * StatusBannerV2
 *
 * @description
 * Display a formatted status message to user.
 *
 * This component was created to be less opinionated than its predecessors,
 * FormattedMessageStatusBanner and StatusBanner.
 *
 * @prop {String} color
 * The color of the border. It will take priority over the color defined in
 * predefinedTypes if parameter is passed with type.
 * Can be either:
 * The color of the border. Can be either:
 *  - color defined in { theme: { colors } } } e.g. primary, accentGreen, accentRed, hoverGray, etc
 *  - css value e.g. #310078, red, #FFFFFF
 *
 * @prop {String} message
 * The message to display in the status banner. Can be formatted if used with values prop.
 * (see values example below)
 *
 * @prop {Object} IconToRender
 * The FC Component to render as the icon. It will take priority over the icon
 * defined in predefinedTypes if parameter is passed with type prop.
 *
 * Common icons used are:
 * The name of the icon in the utils/iconDictionary. Common icons used are:
 * - paymentWarningL -- Red exclamation point in a circle typically used for ERROR
 * - outlinedPendingPurpleL -- Purple circular clock typically used for PENDING
 * - outlinedCompleteGreen: -- green checkmark in a circle typically used for SUCCESS
 *
 * @prop {String} type
 * The predefined type of status banner defined in predefined schemes.
 * Can be 'error', 'pending', or 'success'.
 *
 * @prop {Object} values
 * The object that contains keys to formatting the message. Optional if no formatting required.
 * See base_ui/FormattedMessage for more information.
 *
 * e.g.
 *  message="<a>Click Here</a> for the <b>lorem ipsum</b>"
 *  values={{
 *    a: ({ msg }) => <a href="https://test.com">{msg}</a>,
 *    b: ({ msg }) => <LessBoldText>{msg}<LessBoldText/>,
 *  }}
 *
 * @prop {Object} WrapperComponent
 * The React Component for the status message. P2 by default, typically P3 for smaller text
 *
 * Example:
 *
 * <StatusBannerV2
 *   iconName="paymentWarningPurple"
 *   message="<h3>You have encountered an error</h3><b>Please try again<b>}
 *   type="error"
 *   values={{
 *     h3: ({ msg }) => <H3>{msg}</H3>,
 *     b: ({msg}) => <P3>{msg}</P3>
 *   }}
 * />
 *
 */

import React from 'react';
import T from 'prop-types';

import { predefinedSchemes } from './constants';
import ConditionalRender from '../../ConditionalRender';
import FormattedMessage from '../../FormattedMessage';
import { P2 } from '../../Paragraphs';
import {
  MessageWrapper,
  StatusContainer,
  StatusIconWrapper,
} from './styledComponents';

const StatusBannerV2 = ({
  color,
  IconToRender,
  message,
  type,
  values = {},
  WrapperComponent = P2,
  ...containerProps
}) => {
  let selectedColor;
  let SelectedIcon;
  if (type in predefinedSchemes) {
    selectedColor = predefinedSchemes[type].color;
    SelectedIcon = predefinedSchemes[type].IconToRender;
  }
  selectedColor = color || selectedColor || predefinedSchemes.pending.color;
  SelectedIcon = IconToRender || SelectedIcon || predefinedSchemes.pending.IconToRender;

  return (
    <ConditionalRender
      Component={() => (
        <StatusContainer $color={selectedColor} {...containerProps}>
          <StatusIconWrapper>{SelectedIcon}</StatusIconWrapper>
          <MessageWrapper>
            <FormattedMessage
              defaultMessage={message}
              values={values}
              WrapperComponent={WrapperComponent}
            />
          </MessageWrapper>
        </StatusContainer>
      )}
      shouldRender={!!message}
    />
  );
};

StatusBannerV2.propTypes = {
  color: T.string,
  IconToRender: T.object,
  message: T.oneOfType([T.bool, T.string]),
  type: T.oneOf(['success', 'error', 'pending']),
  values: T.object,
  WrapperComponent: T.oneOfType([T.func, T.object]),
};

export default StatusBannerV2;
