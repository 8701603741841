/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

const OutlinedCompletePurple = (props) => {
  const { color } = props || {};
  const { colors: { primary } } = useTheme();
  const strokeColor = color || primary;

  return (
    <Svg fill="none" height={Math.floor(moderateScale(24))} viewBox="0 0 24 24" width={Math.floor(moderateScale(24))} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z" stroke={strokeColor} />
      <Path d="M9.50088 17C9.38232 16.9988 9.2653 16.9716 9.1573 16.9203C9.0493 16.8689 8.9527 16.7945 8.87366 16.7017L5.26711 12.6495C5.18123 12.5619 5.11327 12.4568 5.06736 12.3407C5.02144 12.2246 4.99855 12.0999 5.00007 11.9742C5.00159 11.8485 5.02749 11.7244 5.0762 11.6096C5.1249 11.4947 5.19539 11.3915 5.28336 11.3062C5.37133 11.2208 5.47494 11.1552 5.58787 11.1133C5.7008 11.0714 5.82069 11.054 5.94021 11.0623C6.05974 11.0706 6.17641 11.1044 6.2831 11.1615C6.38979 11.2187 6.48426 11.298 6.56076 11.3948L9.50088 14.7373L16.4886 7.27058C16.6555 7.09723 16.8809 7 17.1158 7C17.3507 7 17.5761 7.09723 17.743 7.27058C17.9076 7.44405 18 7.67887 18 7.92366C18 8.16845 17.9076 8.40327 17.743 8.57675L10.1085 16.7326C9.94592 16.8999 9.72853 16.9955 9.50088 17V17Z" fill={strokeColor} />
    </Svg>
  );
};

export default OutlinedCompletePurple;
