import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../../base_ui';
import { HeaderText, RowView } from '../styledComponents';

const ListHeader = ({ isMultiplePaymentsRows }) => (
  <RowView>
    <ConditionalRender
      Component={<HeaderText $widthPercent={28}>Date</HeaderText>}
      shouldRender={!isMultiplePaymentsRows}
    />
    <HeaderText $widthPercent={42}>Type</HeaderText>
    <HeaderText $rightAlign $widthPercent={isMultiplePaymentsRows ? 48 : 20}>Amount</HeaderText>
  </RowView>
);

ListHeader.propTypes = { isMultiplePaymentsRows: T.bool };

export default ListHeader;
