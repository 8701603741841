import React, { useRef } from 'react';
import T from 'prop-types';
// import { Platform } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { createStructuredSelector } from 'reselect';

import reducer from '@dmi/shared/redux/Main/reducer';
import makeSelectMain, {
  getHasMultiLoans,
  getIsLoanAvailable,
  getIsSso,
  getMainClientInfo,
  getSelectedLoanAddress,
  getSelectedLoanPropertyState,
  selectClientMobileFeatures,
  selectHasChapterSevenBankruptcy,
  selectIsMurabahaAccount,
} from '@dmi/shared/redux/Main/selectors';
import { logoutRequest } from '@dmi/shared/redux/Main/actions';
import { selectClientBanner } from '@dmi/shared/redux/Notifications/selectors';
import makeSelectFees, { selectHasPDFFeeStatement } from '@dmi/shared/redux/Fees/selectors';
import { selectIsSecureMessagingTreatmentOn } from '@dmi/shared/redux/SecureMessaging/selectors';

import injectReducer from '../../utils/injectReducer';
// import { OidcSingleLogout } from '../../utils/deviceHelpers';
import ClientSection from './ClientSection';
import Header from './Header';
import NavMenu from './NavMenu';
import Footer from './Footer';
import SiteMapLink from './SiteMapLink';

import {
  FlexView,
  StyledScrollView,
} from './styledComponents';

const NavigationDrawer = ({
  clientBanner,
  clientMobileFeatures,
  dispatchLogoutRequest,
  feeStatement,
  footerIconKeys,
  hasChapterSevenBankruptcy,
  hasMultiLoans,
  hasPDFFeeSchedule,
  isLoanAvailable,
  isMurabahaAccount,
  isSecureMessagingTreatmentOn,
  isSslAuth,
  isSso,
  logo,
  navigation,
  navigationState,
  selectedLoanAddress,
  selectedLoanPropertyState,
  // ssoLogoutUrl,
}) => {
  const scrollViewRef = useRef(null);

  useFocusEffect(() => () => {
    // eslint-disable-next-line no-unused-expressions
    scrollViewRef.current?.scrollTo({ animated: true, x: 0, y: 0 });
  });

  const handleLogout = async () => {
    // if (isSso && ssoLogoutUrl && Platform.OS !== 'web') {
    //   await new OidcSingleLogout(ssoLogoutUrl).initiate();
    // }
    dispatchLogoutRequest();
  };

  return (
    <StyledScrollView ref={scrollViewRef}>
      <Header
        hasMultiLoans={hasMultiLoans}
        logo={logo}
        navigation={navigation}
        selectedLoanAddress={selectedLoanAddress}
      />
      <NavMenu
        clientMobileFeatures={clientMobileFeatures}
        handleLogout={handleLogout}
        isLoanAvailable={isLoanAvailable}
        isMurabahaAccount={isMurabahaAccount}
        isSslAuth={isSslAuth}
        isSso={isSso}
        navigation={navigation}
        navigationState={navigationState}
      />
      <FlexView />
      <ClientSection
        clientBanner={clientBanner}
        isSecureMessagingTreatmentOn={isSecureMessagingTreatmentOn}
      />
      <SiteMapLink navigation={navigation} />
      <Footer
        feeStatement={feeStatement}
        footerIconKeys={footerIconKeys}
        hasChapterSevenBankruptcy={hasChapterSevenBankruptcy}
        hasPDFFeeSchedule={hasPDFFeeSchedule}
        selectedLoanPropertyState={selectedLoanPropertyState}
      />
    </StyledScrollView>
  );
};

NavigationDrawer.propTypes = {
  clientBanner: T.object,
  clientMobileFeatures: T.shape({ showLogoutButton: T.bool.isRequired }),
  dispatchLogoutRequest: T.func.isRequired,
  feeStatement: T.string,
  footerIconKeys: T.array.isRequired,
  hasChapterSevenBankruptcy: T.bool.isRequired,
  hasMultiLoans: T.bool.isRequired,
  hasPDFFeeSchedule: T.bool.isRequired,
  isLoanAvailable: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  logo: T.object,
  navigation: T.object.isRequired,
  navigationState: T.object.isRequired,
  selectedLoanAddress: T.string.isRequired,
  selectedLoanPropertyState: T.string.isRequired,
  // ssoLogoutUrl: T.string,
};

const mapStateToProps = createStructuredSelector({
  /**
  * Store: Main
  */
  clientMobileFeatures: selectClientMobileFeatures(),
  footerIconKeys: getMainClientInfo('footerIconKeys'),
  hasChapterSevenBankruptcy: selectHasChapterSevenBankruptcy(),
  hasMultiLoans: getHasMultiLoans(),
  isLoanAvailable: getIsLoanAvailable(),
  isMurabahaAccount: selectIsMurabahaAccount(),
  isSecureMessagingTreatmentOn: selectIsSecureMessagingTreatmentOn(),
  isSslAuth: makeSelectMain('isSslAuth'),
  isSso: getIsSso(),
  logo: getMainClientInfo('logo'),
  selectedLoanAddress: getSelectedLoanAddress(true),
  selectedLoanPropertyState: getSelectedLoanPropertyState(),
  ssoLogoutUrl: makeSelectMain('ssoLogoutUrl'),
  /**
   * Store: Notifications
   */
  // eslint-disable-next-line sort-keys
  clientBanner: selectClientBanner(),
  /**
   * Reducer: Fees
   */
  // eslint-disable-next-line sort-keys
  feeStatement: makeSelectFees('feeStatement'),
  hasPDFFeeSchedule: selectHasPDFFeeStatement(),
});

/* eslint-disable object-curly-newline */
const mapDispatchToProps = (dispatch) => ({
  dispatchLogoutRequest: () => dispatch(logoutRequest()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'main', reducer });

export default compose(
  withConnect,
  withReducer,
)(NavigationDrawer);
