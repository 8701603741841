import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { LargePrimaryButton, P2 } from '../../base_ui';

export const ButtonWrapper = styled.View`
  align-items: center;
  justify-content: center;
`;

export const CardContainer = styled.View`
  align-items: center;
  margin: ${moderateScale(16)}px ${moderateScale(24)}px;
`;

export const CardText = styled(P2)`
  padding-top: ${moderateScale(2)}px;
  text-align: center;
`;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  margin: ${moderateScale(24)}px auto ${moderateScale(36)}px;
`;
