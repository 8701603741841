/* eslint-disable max-len */
import React from 'react';
import T from 'prop-types';

import { PROFILE_TRANSFER_CONFIRMATION_PROPS } from '@dmi/shared/redux/GoldRedirect/constants';

import { H2 } from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import {
  Container,
  HeaderWrapper,
  IllustrationWrapper,
  StyledLink,
  StyledP2,
  StyledPrimaryDivider,
  TextWrapper,
} from './styledComponents';

const RedirectDogIllustration = iconDictionary('redirectDog');

const ProfileTransferConfirmation = ({
  dispatchRedirectToPaymentsPage,
  isUsernameRequired,
}) => {
  let header = PROFILE_TRANSFER_CONFIRMATION_PROPS.getHeader(isUsernameRequired);
  if (isUsernameRequired) header = header.replace('\n', ' ').replace('! Y', '!\nY');
  const { linkLabel, text } = PROFILE_TRANSFER_CONFIRMATION_PROPS;

  return (
    <Container>
      <SecondaryHeader
        title="Confirmation"
      />
      <IllustrationWrapper>{RedirectDogIllustration}</IllustrationWrapper>
      <TextWrapper>
        <HeaderWrapper>
          <H2>{header}</H2>
        </HeaderWrapper>
        <StyledP2>{text}</StyledP2>
      </TextWrapper>
      <StyledPrimaryDivider />
      <StyledLink
        label={linkLabel}
        onPressFunc={() => dispatchRedirectToPaymentsPage()}
        size="medium"
      />
    </Container>
  );
};

ProfileTransferConfirmation.propTypes = {
  dispatchRedirectToPaymentsPage: T.func.isRequired,
  isUsernameRequired: T.bool.isRequired,
};

export default ProfileTransferConfirmation;
