/* eslint-disable max-len, no-param-reassign, no-restricted-globals */
import React from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import {
  AdditionalPaymentContent,
  ButtonSpacer,
  RegularPaymentRow,
  StyledPaymentTextField,
} from './styledComponents';

const Content = ({
  amountFormErrors,
  fields,
  formState,
  formStateErrors,
  minimumPaymentForAdditionalPrincipalOrEscrow,
  setFormState,
  setFormStateErrors,
}) => {
  const { formatMessage } = useIntl();
  const handleChange = ({ field, newValue }) => {
    const isValidNumber = !Number.isNaN(Number(newValue));
    if (isValidNumber) {
      setFormStateErrors((formErrors) => ({ ...formErrors, [field]: '' }));
      setFormState({ ...formState, [field]: newValue });
    }
  };

  const clearOnFocus = (field) => {
    if (formState[field] === '0') {
      setFormState({ ...formState, [field]: '' });
    }
  };

  const formatOnBlur = (field) => {
    const value = formState[field];
    if (value === '') {
      setFormState({ ...formState, [field]: '0' });
    } else if (value !== '0') {
      const formattedValue = parseFloat(value).toFixed(2);
      if (formattedValue === '0.00') {
        setFormState({ ...formState, [field]: '0' });
      } else {
        setFormState({ ...formState, [field]: formattedValue });
      }
    }
  };

  return (
    <AdditionalPaymentContent>
      {fields.map(({
        disabledText,
        enabled,
        field,
        label,
        labelSubText,
      }) => {
        if (field === 'additionalPrincipal' || field === 'additionalEscrow') {
          const minNumPaymentMet = minimumPaymentForAdditionalPrincipalOrEscrow <= formState.numberOfPayments;
          const text = `Number of payments must be at least ${minimumPaymentForAdditionalPrincipalOrEscrow}`;
          disabledText = enabled ? text : disabledText;
          enabled = enabled && minNumPaymentMet;
        }

        return (
          <RegularPaymentRow key={label}>
            <ButtonSpacer />
            <StyledPaymentTextField
              disabledText={disabledText}
              enabled={enabled}
              errorMessage={formStateErrors[field] || amountFormErrors[field]}
              keyboardType="decimal-pad"
              label={formatMessage({ id: `payments.oneTimePayment.paymentFields.${field}.label` })}
              labelSubText={labelSubText}
              onBlurSideEffects={() => formatOnBlur(field)}
              onChangeText={(newValue) => handleChange({ field, newValue })}
              onFocusSideEffects={() => clearOnFocus(field)}
              rendersCheckbox={false}
              value={formState[field]}
            />
          </RegularPaymentRow>
        );
      })}
    </AdditionalPaymentContent>
  );
};

Content.propTypes = {
  amountFormErrors: T.object.isRequired,
  fields: T.arrayOf(
    T.shape({
      disabledText: T.string.isRequired,
      enabled: T.bool.isRequired,
      field: T.string.isRequired,
      label: T.string.isRequired,
      labelSubText: T.string,
      required: T.bool.isRequired,
    }),
  ).isRequired,
  formState: T.shape({
    additionalEscrow: T.string.isRequired,
    additionalPrincipal: T.string.isRequired,
    lateCharge: T.string.isRequired,
    nsfFees: T.string.isRequired,
    numberOfPayments: T.number.isRequired,
    otherFees: T.string.isRequired,
  }).isRequired,
  formStateErrors: T.shape({
    additionalEscrow: T.string.isRequired,
    additionalPrincipal: T.string.isRequired,
    lateCharge: T.string.isRequired,
    nsfFees: T.string.isRequired,
    numberOfPayments: T.string.isRequired,
    otherFees: T.string.isRequired,
  }).isRequired,
  minimumPaymentForAdditionalPrincipalOrEscrow: T.number,
  setFormState: T.func.isRequired,
  setFormStateErrors: T.func.isRequired,
};

export default Content;
