import styled from 'styled-components/native';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  H2,
  H4,
  P2,
} from '../../base_ui';

export const Container = styled.ScrollView.attrs({ contentContainerStyle: { flexGrow: 1 } })`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const Content = styled.View`
  margin: 0 ${moderateScale(24)}px ${moderateScale(16)}px;
`;

export const DisclaimerText = styled(H4)`
  font-weight: 700;
  text-decoration: underline;
  margin-bottom: ${moderateScale(24)}px;
`;

export const Header = styled(H2)`
  margin-bottom: ${moderateScale(24)}px;
  text-align: center;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin: ${moderateScale(20)}px ${moderateScale(20)}px ${moderateScale(16)}px;
`;

export const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${moderateScale(16)}px;
`;

export const RowValue = styled(P2)`
  flex: 1;
  flex-wrap: wrap;
  text-align: right;
`;

export const Subheader = styled(P2)`
  margin-bottom: ${moderateScale(24)}px;
  text-align: center;
`;
