/**
 * Converts our classic redux state combo of individual loading, error, and success states
 * into a single enum 'status' state
 * @param {object} individualStates
 * @param {boolean} individualStates.error
 * @param {boolean} individualStates.loading
 * @param {boolean} individualStates.success
 * @return {'idle'|'loading'|'succeeded'|'failed'}
 */
const selectAsyncStatus = ({ error, loading, success }) => {
  if (!error && !loading && !success) {
    return 'idle';
  }
  if (error) {
    return 'failed';
  }
  if (loading) {
    return 'loading';
  }
  return 'succeeded';
};

export { selectAsyncStatus };
