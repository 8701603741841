import styled, { css } from 'styled-components/native';

import { moderateScale } from '../../../../../../../utils/scaleHelpers';
import {
  H2,
  H3,
  H4,
  H5,
} from '../../../../../../base_ui';

const bottomPaddingStyle = css`
  padding-bottom: ${moderateScale(10)}px;
`;

export const StyledH2 = styled(H2)`${bottomPaddingStyle}`;

export const StyledH3 = styled(H3)`${bottomPaddingStyle}`;

export const StyledH4 = styled(H4)`
${({ $noMargin }) => $noMargin ? 'padding-bottom: 0' : `${bottomPaddingStyle}`};
`;

export const StyledH5 = styled(H5)`${bottomPaddingStyle}`;
