import React from 'react';
import T from 'prop-types';

import ConditionalRender from '../ConditionalRender';
import { StyledBannerText, StyledErrorSuccessBanner } from './styledComponents';

const ErrorSuccessBanner = ({
  error,
  success,
  ...restProps
}) => (
  <ConditionalRender
    Component={(
      <StyledErrorSuccessBanner error={error} {...restProps}>
        <StyledBannerText>{ error || success }
        </StyledBannerText>
      </StyledErrorSuccessBanner>
    )}
    shouldRender={!!(error || success)}
  />
);

ErrorSuccessBanner.propTypes = {
  error: T.oneOfType([T.bool, T.string]).isRequired,
  success: T.oneOfType([T.bool, T.string]),
};

export default ErrorSuccessBanner;
