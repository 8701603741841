import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender, P2 } from '../../../base_ui';
import StaticInfoComponent from './StaticInfoComponent';
import PasswordComponent from './PasswordComponent';
import {
  Card,
  ContentContainer,
  Header,
  IconWrapper,
  StyledBaseTextField,
  StyledContainer,
  StyledDivider,
  StyledLink,
  StyledProgressButton,
  UsernameContainer,
} from '../styledComponents';

const Icon = iconDictionary('emailChair');

const ProfileTransferForm = ({
  accountTransferredLinkLabel,
  confirmPasswordInputRef,
  dispatchChangeInputValue,
  email,
  formErrors,
  formHeader,
  formText,
  formValues,
  goldRedirectPLLoginStatus,
  handleSubmit,
  isAccountTransferComplete,
  isPasswordRequired,
  isSubmitButtonDisabled,
  isUsernameRequired,
  passwordInputRef,
  submitButtonLabel,
  username,
  usernameForLogin,
}) => (
  <Card>
    <Header>{formHeader}</Header>
    <ContentContainer>
      <StaticInfoComponent
        email={email}
        formText={formText}
        username={username}
        usernameForLogin={usernameForLogin}
      />
      <ConditionalRender
        Component={(
          <StyledContainer>
            <P2>New Username</P2>
            <UsernameContainer>
              <StyledBaseTextField
                ariaLabel="Username"
                error={formErrors.username}
                id="username-field"
                isAutoWidth
                label=""
                onChangeText={(value) => dispatchChangeInputValue({
                  field: 'username',
                  value,
                })}
                onSubmitEditing={() => passwordInputRef.current.focus()}
                placeholder="Required"
                value={formValues.username}
              />
            </UsernameContainer>
          </StyledContainer>
        )}
        shouldRender={isUsernameRequired}
      />
      <ConditionalRender
        Component={PasswordComponent}
        propsToPassDown={{
          confirmPasswordInputRef,
          dispatchChangeInputValue,
          formErrors,
          formValues,
          handleSubmit,
          passwordInputRef,
        }}
        shouldRender={isPasswordRequired}
      />
    </ContentContainer>
    <ConditionalRender
      Component={(
        <StyledDivider />
      )}
      shouldRender={isAccountTransferComplete}
    />
    <ConditionalRender
      Component={(
        <IconWrapper>{Icon}</IconWrapper>
      )}
      shouldRender={!isUsernameRequired && isPasswordRequired}
    />
    <ConditionalRender
      Component={(
        <StyledLink
          label={accountTransferredLinkLabel}
          onPressFunc={handleSubmit}
        />
      )}
      FallbackComponent={(
        <StyledProgressButton
          $isAccountTransferComplete={isAccountTransferComplete}
          $isUsernameRequired={isUsernameRequired}
          disabled={isSubmitButtonDisabled}
          label={submitButtonLabel}
          onPress={handleSubmit}
          status={goldRedirectPLLoginStatus}
        />
      )}
      shouldRender={isAccountTransferComplete}
    />
    <ConditionalRender
      Component={(
        <IconWrapper $isAccountTransferComplete={isAccountTransferComplete}>{Icon}</IconWrapper>
      )}
      shouldRender={isAccountTransferComplete}
    />
  </Card>
);

ProfileTransferForm.propTypes = {
  accountTransferredLinkLabel: T.string.isRequired,
  confirmPasswordInputRef: T.object.isRequired,
  dispatchChangeInputValue: T.func.isRequired,
  email: T.string.isRequired,
  formErrors: T.object.isRequired,
  formHeader: T.string.isRequired,
  formText: T.string.isRequired,
  formValues: T.object.isRequired,
  goldRedirectPLLoginStatus: T.string.isRequired,
  handleSubmit: T.func.isRequired,
  isAccountTransferComplete: T.bool.isRequired,
  isPasswordRequired: T.bool.isRequired,
  isSubmitButtonDisabled: T.bool.isRequired,
  isUsernameRequired: T.bool.isRequired,
  passwordInputRef: T.object.isRequired,
  submitButtonLabel: T.string.isRequired,
  username: T.string.isRequired,
  usernameForLogin: T.string.isRequired,
};

export default ProfileTransferForm;
