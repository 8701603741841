import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import makeSelectMain, { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import { createOtp } from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import {
  selectCreateOtpRequestBody,
  selectOtpConfirmation,
  selectOtpLoading,
  selectOtpScheduledDate,
  selectOtpServiceError,
  selectTotalPayment,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import Disclosure from '../../../components/Payments/Disclosure';

const DisclosureScreen = ({
  clientName,
  createOtpRequestBody,
  dispatchCreateOtp,
  error,
  loading,
  loanNumber,
  navigation,
  otpConfirmation,
  scheduledDate,
  totalPayment,
}) => (
  <Disclosure
    clientName={clientName}
    navigation={navigation}
    summaryProps={{
      error,
      hasProgressButton: true,
      label: 'Accept Terms',
      loading,
      onPress: () => dispatchCreateOtp({ createOtpRequestBody, loanNumber }),
      subText: `Scheduled for ${scheduledDate}`,
      success: !!otpConfirmation,
      text: totalPayment,
    }}
    type="otp"
  />
);

DisclosureScreen.propTypes = {
  clientName: T.string.isRequired,
  createOtpRequestBody: T.object.isRequired,
  dispatchCreateOtp: T.func.isRequired,
  error: T.bool.isRequired,
  loading: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  otpConfirmation: T.oneOfType([T.bool, T.array]).isRequired,
  scheduledDate: T.string.isRequired,
  totalPayment: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  clientName: getMainClientInfo('clientName'),
  loanNumber: makeSelectMain('selectedLoan'),
  /**
   * Store: One Time Payment
   */
  // eslint-disable-next-line sort-keys
  createOtpRequestBody: selectCreateOtpRequestBody(),
  error: selectOtpServiceError('createOtp'),
  loading: selectOtpLoading('createOtp'),
  otpConfirmation: selectOtpConfirmation(),
  scheduledDate: selectOtpScheduledDate(),
  totalPayment: selectTotalPayment(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: One Time Payment
   */
  dispatchCreateOtp: (payload) => dispatch(createOtp(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(DisclosureScreen);
