import React, { Fragment } from 'react';
import T from 'prop-types';

import { INFORMATION_NOT_AVAILABLE } from '@dmi/shared/utils/constants';

import { ConditionalRender, P2 } from '../../base_ui';

const MailingAddress = ({
  mailingAddress: {
    addressLine2,
    addressLine3,
    apartment,
    cityProvincePostalCode,
    cityStateZip,
    companyOrCareOf,
    country,
    streetAddress,
  },
}) => (
  <ConditionalRender
    Component={(
      <Fragment>
        {companyOrCareOf && <P2>{companyOrCareOf}</P2>}
        <P2>{streetAddress || INFORMATION_NOT_AVAILABLE}</P2>
        {apartment && <P2>{apartment}</P2>}
        {cityStateZip && <P2>{cityStateZip}</P2>}
      </Fragment>
    )}
    FallbackComponent={(
      <Fragment>
        <P2>{streetAddress}</P2>
        {addressLine2 && <P2>{addressLine2}</P2>}
        {addressLine3 && <P2>{addressLine3}</P2>}
        {cityProvincePostalCode && <P2>{cityProvincePostalCode}</P2>}
        {country && <P2>{country}</P2>}
      </Fragment>
    )}
    shouldRender={!country}
  />
);

MailingAddress.propTypes = {
  mailingAddress: T.shape({
    addressLine2: T.string,
    addressLine3: T.string,
    apartment: T.string,
    cityProvincePostalCode: T.string,
    cityStateZip: T.string,
    companyOrCareOf: T.string,
    country: T.string,
    streetAddress: T.string,
  }).isRequired,
};

export default MailingAddress;
