import React, { Fragment, useState } from 'react';
import { View } from 'react-native';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import {
  ConditionalRender,
  Tooltip,
} from '../../base_ui';
import {
  LoanCardWrapper,
  StyledPrimaryDivider,
  StyledStatusBanner,
} from './styledComponents';
import { LoanCard, LoanCardSkeleton } from '../Shared/LoanCards';
import { PrimaryLoanTooltip } from '../Shared/LoanCards/Sections';

const Loans = ({
  bannerError,
  dispatchChangePrimaryLoan,
  dispatchSetSelectedLoan,
  isLoading,
  loanNumbers,
  loansIsPrimaryStatus,
  mailingAddressProps,
  propertyAddresses,
  selectedLoan,
  success,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { formatMessage, messages } = useIntl();

  return (
    <Fragment>
      <StyledStatusBanner
        error={resolveIntlMessage({ formatMessage, message: bannerError, messages })}
        success={resolveIntlMessage({ formatMessage, message: success, messages })}
      />
      {propertyAddresses.map(({
        loan,
        onlineAvailabilityStatus,
        property: propertyAddress,
      }, i) => {
        const { mailingAddress } = mailingAddressProps[loan] || {};
        const isPrimaryLoan = selectedLoan ? loan === selectedLoan : loansIsPrimaryStatus[loan];
        const hasMultipleLoans = propertyAddresses.length > 1;
        const isActive = onlineAvailabilityStatus === 'Available';

        return (
          <View key={loan}>
            <StyledPrimaryDivider />
            <LoanCardWrapper $isInactiveLoan={!isActive}>
              <ConditionalRender
                Component={(
                  <LoanCard
                    dispatchChangePrimaryLoan={dispatchChangePrimaryLoan}
                    dispatchSetSelectedLoan={dispatchSetSelectedLoan}
                    hashedLoanNumber={loan}
                    hasMultipleLoans={hasMultipleLoans}
                    isActive={isActive}
                    isPrimaryLoan={isPrimaryLoan}
                    mailingAddress={mailingAddress}
                    propertyAddress={propertyAddress}
                    setIsTooltipOpen={setIsTooltipOpen}
                    unmaskedLoanNumber={loanNumbers[loan]}
                  />
                )}
                FallbackComponent={(<LoanCardSkeleton />)}
                shouldRender={!isLoading}
              />
            </LoanCardWrapper>
            <ConditionalRender
              Component={StyledPrimaryDivider}
              shouldRender={i === propertyAddresses.length - 1}
            />
          </View>
        );
      })}
      <ConditionalRender
        Component={(
          <Tooltip
            handleClose={() => setIsTooltipOpen(false)}
            positionAbsolute={{ left: 'auto', top: 'auto' }}
            visible={isTooltipOpen}
          >
            <PrimaryLoanTooltip
              handleCloseTooltip={() => setIsTooltipOpen(false)}
            />
          </Tooltip>
        )}
        shouldRender={isTooltipOpen}
      />
    </Fragment>
  );
};

Loans.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangePrimaryLoan: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  isLoading: T.bool.isRequired,
  loanNumbers: T.object.isRequired,
  loansIsPrimaryStatus: T.object.isRequired,
  mailingAddressProps: T.object.isRequired,
  propertyAddresses: T.array.isRequired,
  selectedLoan: T.string.isRequired,
  success: T.string.isRequired,
};

export default Loans;
