import React from 'react';
import T from 'prop-types';

import { BANNER_TEXT } from '@dmi/shared/redux/Payments/History/constants';

import { P3 } from '../base_ui/Paragraphs';
import {
  BannerContainer,
  IllustrationWrapper,
  MessageContainer,
  StyledLink,
  StyledView,
} from './styledComponents';
import iconDictionary from '../../utils/iconDictionary';
import { moderateScale } from '../../utils/scaleHelpers';

const Illustration = iconDictionary('thumbtack', { width: `${moderateScale(45)}px` });

const SecureMessagingBanner = ({
  details,
  dispatchSecureMessagingChangeFormFieldValue,
  navigation,
  screen,
}) => {
  const onPress = () => {
    navigation.push('SecureMessaging');
    navigation.navigate('NewMessage');
    dispatchSecureMessagingChangeFormFieldValue({
      field: 'topic',
      form: 'message',
      value: 'Lost/Misapplied Payment',
    });
    if (details) {
      const { date, type } = details;
      dispatchSecureMessagingChangeFormFieldValue({
        field: 'subject',
        form: 'message',
        value: `${type} - ${date}`,
      });
    }
  };

  return (
    <StyledView>
      <BannerContainer>
        <IllustrationWrapper>
          {Illustration}
        </IllustrationWrapper>
        <MessageContainer>
          <P3>
            {BANNER_TEXT[screen]}
            <StyledLink onPress={onPress}>
              Send Message
            </StyledLink>
          </P3>
        </MessageContainer>
      </BannerContainer>
    </StyledView>
  );
};

SecureMessagingBanner.propTypes = {
  details: T.shape({ date: T.string, type: T.string }),
  dispatchSecureMessagingChangeFormFieldValue: T.func.isRequired,
  navigation: T.object.isRequired,
  screen: T.string.isRequired,
};

export default SecureMessagingBanner;
