import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';

import { SS2_ROUTES_DICTIONARY } from '@dmi/shared/redux/Notifications/constants';

import { InlineLink } from './styledComponents';
import { isExternal } from './helpers';

const Link = ({
  data: { mail, url },
  handleNavigateLink,
  text,
}) => {
  if (mail) {
    return <InlineLink onPress={() => Linking.openURL(mail)}>{text}</InlineLink>;
  }
  if (isExternal(url)) {
    return <InlineLink onPress={() => Linking.openURL(url)}>{text}</InlineLink>;
  }
  const {
    mobile: screen,
    mobileNestedScreen: nestedScreen,
  } = SS2_ROUTES_DICTIONARY[url] || SS2_ROUTES_DICTIONARY.fallback;

  return (
    <InlineLink onPress={() => handleNavigateLink({ nestedScreen, screen })}>
      {text}
    </InlineLink>
  );
};

Link.propTypes = {
  data: T.shape({ mail: T.string, url: T.string.isRequired }),
  handleNavigateLink: T.func.isRequired,
  text: T.string.isRequired,
};

export default Link;
