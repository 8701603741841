import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';

const EmptyIcon = iconDictionary('key');

const EmptyCard = ({ emptyHeading, emptyMessage }) => (
  <Message
    body={emptyMessage}
    icon={EmptyIcon}
    title={emptyHeading}
  />
);

EmptyCard.propTypes = {
  emptyHeading: T.string.isRequired,
  emptyMessage: T.string.isRequired,
};

export default EmptyCard;
