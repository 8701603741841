import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';
import { HelocForm, HelocReview } from '../../../../containers/Mortgage/HelocDraw';

const Stack = createStackNavigator();

const HelocDrawPreConfirmationStack = () => (
  <Stack.Navigator
    initialRouteName="HelocForm"
    screenOptions={{
      cardStyle: { ...Platform.select({ web: { height: '-webkit-fill-available' } }) },
      headerShown: false,
    }}
  >
    <Stack.Screen component={HelocForm} name="HelocForm" />
    <Stack.Screen component={HelocReview} name="HelocReview" />
  </Stack.Navigator>
);

export default HelocDrawPreConfirmationStack;
