/* eslint-disable max-len */
import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function EarthquakeIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <G>
        <Path d="M15.1,16H2.8c-0.3,0-0.6-0.3-0.6-0.6V6.2c0-0.2,0.1-0.4,0.3-0.5l6.1-4.1c0.2-0.1,0.5-0.1,0.7,0l6.3,4.1c0.2,0.1,0.3,0.3,0.3,0.5v9.2C15.7,15.8,15.5,16,15.1,16z M3.4,14.8h11.2V6.5L8.9,2.8L3.4,6.5V14.8z" fill={iconStatic} />
        <Path d="M1.4,7.6C1.2,7.6,1,7.5,0.9,7.4C0.7,7.1,0.8,6.7,1.1,6.6L3,5.3c0.3-0.2,0.6-0.1,0.8,0.2C4,5.8,3.9,6.1,3.7,6.3L1.7,7.6C1.6,7.6,1.5,7.6,1.4,7.6z" fill={iconStatic} />
        <Path d="M16.5,7.6c-0.1,0-0.2,0-0.3-0.1l-1.9-1.2c-0.3-0.2-0.4-0.5-0.2-0.8c0.2-0.3,0.5-0.4,0.8-0.2l1.9,1.2c0.3,0.2,0.4,0.5,0.2,0.8C16.9,7.5,16.7,7.6,16.5,7.6z" fill={iconStatic} />
        <Path d="M13.3,5.5c-0.3,0-0.6-0.3-0.6-0.6V2.6c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v2.3C13.9,5.3,13.6,5.5,13.3,5.5z" fill={iconStatic} />
        <Path d="M8.5,15.6c-0.1,0-0.2,0-0.2-0.1C8,15.4,7.9,15.1,8,14.9l1.3-2.4H8.1c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2-0.1-0.3,0-0.5l1.6-2.5H7.9c-0.2,0-0.3-0.1-0.4-0.2s-0.1-0.3,0-0.5l1.6-2.7c0.1-0.2,0.4-0.3,0.6-0.2C10,5.8,10,6.1,9.9,6.4l-1.2,2h1.4c0.2,0,0.3,0.1,0.4,0.2c0.1,0.2,0.1,0.3,0,0.5l-1.6,2.5h1.2c0.2,0,0.3,0.1,0.4,0.2s0.1,0.3,0,0.5l-1.7,3.1C8.8,15.5,8.6,15.6,8.5,15.6z" fill={iconStatic} />
      </G>
    </Svg>
  );
}
