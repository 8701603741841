/* eslint-disable max-len */
import React from 'react';
import T from 'prop-types';

import HelpCard from './HelpCard';
import { ConditionalRender, H3, SecondaryDivider } from '../base_ui';
import iconDictionary from '../../utils/iconDictionary';
import Layout from '../../constants/Layout';
import {
  ContentWrapper,
  HelpHomeContainer,
  IllustrationWrapper,
  StyledButton,
  StyledH1,
  TopCard,
} from './styledComponents';

const DisasterUmbrella = iconDictionary('disasterUmbrella');
const HelpDog = iconDictionary('helpDog');
const HelpLearningCenter = iconDictionary('helpLearningCenter');
const HelpLighthouse = iconDictionary('helpLighthouse');
const Megaphone = iconDictionary('megaphoneL');
const { isTablet } = Layout;

const HelpHome = ({
  // hasLossDraftClaims,
  isMurabahaAccount,
  isSecureMessagingTreatmentOn,
  navigation,
}) => (
  <HelpHomeContainer>
    <StyledH1>Help</StyledH1>
    <TopCard $center={isTablet}>
      <IllustrationWrapper>{HelpDog}</IllustrationWrapper>
      <ConditionalRender
        Component={(
          <ContentWrapper>
            <H3>Questions? Send a secure message</H3>
            <StyledButton label="Send Secure Message" onPress={() => navigation.navigate('SecureMessaging')} />
          </ContentWrapper>
        )}
        FallbackComponent={(
          <ContentWrapper>
            <H3>Learn about mortgage related terms</H3>
            <StyledButton label="View Glossary" onPress={() => navigation.navigate('Glossary')} />
          </ContentWrapper>
        )}
        shouldRender={isSecureMessagingTreatmentOn}
      />
    </TopCard>
    <SecondaryDivider />
    <HelpCard
      ariaLabel="hardship-assistance-button"
      Icon={HelpLighthouse}
      onPress={() => navigation.navigate('HardshipAssistance')}
      text="Explore all of the options that may help you keep your home during these difficult financial times."
      title="Hardship Assistance"
    />
    <SecondaryDivider />
    <HelpCard
      ariaLabel="disaster-assistance-button"
      Icon={DisasterUmbrella}
      onPress={() => navigation.navigate('DisasterAssistance')}
      text="Learn about the resources available to help you repair your home after a natural disaster."
      title="Disaster Assistance"
    />
    <SecondaryDivider />
    <ConditionalRender
      Component={(
        <React.Fragment>
          <HelpCard
            ariaLabel="accessibilityLabel"
            Icon={HelpLearningCenter}
            onPress={() => navigation.navigate('LearningCenter')}
            text="View educational videos, frequently asked questions, a glossary and more."
            title="Learning Center"
          />
          <SecondaryDivider />
        </React.Fragment>
      )}
      shouldRender={!isMurabahaAccount}
    />
    <HelpCard
      ariaLabel="contact-us-button"
      Icon={Megaphone}
      onPress={() => navigation.navigate('ContactUs')}
      text="Details on how to get in touch with different departments."
      title="Contact Information"
    />
    <SecondaryDivider />
  </HelpHomeContainer>
);

HelpHome.propTypes = {
  // hasLossDraftClaims: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default HelpHome;
