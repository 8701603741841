import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const GeneralDocsContainer = styled.View`
  height: 100%;
  justify-content: space-between;
`;

export const PressableRow = styled.Pressable`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(moderateScale(14))}px ${(moderateScale(24))}px;
`;

export const StyledIllustration = styled.View`
  align-self: center;
  max-height: 33%;
  padding: ${(moderateScale(24))}px;
  width: 100%;
`;
