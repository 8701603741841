/*
 * Username/Password Input Group
 */

import React, { Fragment, useRef } from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import {
  ConditionalRender,
  LargeProgressButton,
  LoginRegisterTextField,
  PasswordTextField,
} from '../../base_ui';

import {
  ButtonsContainer,
  RegisterInputContainer,
  StyledBorderlessButton,
} from './styledComponents';

const RegisterResendEmail = ({
  dispatchChangeInput,
  formErrors,
  formValues,
  handleBack,
  handleNextClick,
  handleSubmit,
  isChangeEmail,
  status,
}) => {
  const confirmEmailRef = useRef(null);
  const passwordRef = useRef(null);
  const usernameRef = useRef(null);

  const formKey = 'account';
  const fieldToUse = isChangeEmail ?
    ['confirmEmail', 'email', 'isChangeEmail', 'password', 'username'] : ['password', 'username'];
  const propsToPassDown = {
    label: 'Resend',
    onPress: () => handleNextClick({
      field: fieldToUse,
      form: formKey,
      formValues: trimFormValues(formValues),
      handleSubmitStep: () => handleSubmit(5, trimFormValues(formValues)),
    }),
    status: status.registerResendEmail,
  };

  const handleDonePress = () => handleNextClick({
    field: fieldToUse,
    form: formKey,
    formValues: trimFormValues(formValues),
    handleSubmitStep: () => handleSubmit(5, trimFormValues(formValues)),
  });

  return (
    <Fragment>
      <RegisterInputContainer>
        <ConditionalRender
          Component={(
            <View>
              <LoginRegisterTextField
                error={formErrors.email}
                label="Email Address"
                onChangeText={(newValue) => dispatchChangeInput({
                  field: 'email',
                  form: formKey,
                  value: newValue,
                })}
                onSubmitEditing={() => confirmEmailRef.current.focus()}
                returnKeyType="next"
                value={formValues.email}
              />
              <LoginRegisterTextField
                ref={confirmEmailRef}
                error={formErrors.confirmEmail}
                label="Confirm Email Address"
                onChangeText={(newValue) => dispatchChangeInput({
                  field: 'confirmEmail',
                  form: formKey,
                  value: newValue,
                })}
                onSubmitEditing={() => usernameRef.current.focus()}
                returnKeyType="next"
                value={formValues.confirmEmail}
              />
            </View>
          )}
          shouldRender={isChangeEmail}
        />
        <LoginRegisterTextField
          ref={usernameRef}
          error={formErrors.username}
          label="Username"
          onChangeText={(newValue) => dispatchChangeInput({
            field: 'username',
            form: formKey,
            value: newValue,
          })}
          onSubmitEditing={() => passwordRef.current.focus()}
          returnKeyType="next"
          value={formValues.username}
        />
        <PasswordTextField
          ref={passwordRef}
          error={formErrors.password}
          label="Password"
          onChangeText={(newValue) => dispatchChangeInput({
            field: 'password',
            form: formKey,
            value: newValue,
          })}
          onSubmitEditing={() => handleDonePress()}
          returnKeyType="done"
          value={formValues.password}
        />
      </RegisterInputContainer>
      <ButtonsContainer>
        <ConditionalRender
          Component={(
            <LargeProgressButton
              disabled={
                !formValues.password ||
                !formValues.username
              }
              {...propsToPassDown}
            />
          )}
          FallbackComponent={(
            <LargeProgressButton
              disabled={
                !formValues.password ||
                !formValues.username ||
                !formValues.email ||
                !formValues.confirmEmail
              }
              {...propsToPassDown}
            />
          )}
          shouldRender={!isChangeEmail}
        />
        <StyledBorderlessButton label="Back" onPress={handleBack} />
      </ButtonsContainer>
    </Fragment>
  );
};

RegisterResendEmail.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  isChangeEmail: T.bool.isRequired,
  status: T.object.isRequired,
};

export default RegisterResendEmail;
