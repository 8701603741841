import React from 'react';
import T from 'prop-types';

import { getReactMessages } from './helpers';

/**
 * FormattedMessage
 *
 * @description Allows for splitting up a string into various interpolated React elements (and
 * substring values), similar to react-intl's FormattedMessage. (See
 * https://formatjs.io/docs/react-intl/components/#usage).
 *
 * Usage / Formatting rules:
 *  - Will look for two types of delimiter pairs in the defaultMessage:
 *    - Double curly brackets: `{{ ... }}`
 *    - html-like tags: `<xyz>...</xyz>`
 *  - Will use the 'values' object to interpolate strings/React elements:
 *    - To interpolate a string:
 *        1) include the variable name in the defaultMessage like: "Hello {{name}}."
 *        2) and ensure the 'values' object has the shape: `{ name: 'Bob' }`.
 *          - Ie, ensure the property value is a string (or can be coerced into a string);
 *    - To interpolate a React element:
 *        1) include a variable name in the default message like: "Hello <b>Bob</b>."
 *        2) And ensure the 'values' object has the shape:
 *           `{ b: ({ msg }) => <BoldText>{msg}</BoldText> }`
 *          - Ie, ensure the property value is a FunctionComponent that accepts a `msg` prop
 *          - Note: the interpolation logic will look for a matching startTag/endTag combo, and then
 *            search in 'values' for a property that matches the tag name. It will then pass the
 *            text wrapped by the tags to the 'value' FunctionComponent as a `msg` prop.
 *  - Nesting:
 *    - Can nest brackets inside tags:
 *      - Good: `<b>{{name}}</b>`
 *    - Cannot do any other nesting. Most importantly, cannot nest tags:
 *      - Bad: `<b><i></i></b>`
 *
 * @example
 *   ({
 *      defaultMessage: "<b>Hello</b> world! I'm <i>{{name}}</i>",
 *      values: {
 *        b: ({ msg }) => <BoldText>{msg}</BoldText>,
 *        i: ({ msg }) => <i>{msg}</i>,
 *        name: 'Bob'
 *      },
 *      WrapperComponent: P3,
 *   })
 *   --->
 *   <P3>
 *     <Fragment></Fragment>
 *     <BoldText>Hello</BoldText>
 *     <Fragment> world! I'm </Fragment>
 *     <i>Bob</i>
 *     <Fragment></Fragment>
 *   </P3>
 */

const FormattedMessage = ({
  defaultMessage,
  values = {},
  WrapperComponent,
  ...restProps
}) => (
  <WrapperComponent {...restProps}>
    {getReactMessages(defaultMessage, values)}
  </WrapperComponent>
);

FormattedMessage.propTypes = {
  defaultMessage: T.string.isRequired,
  values: T.object,
  WrapperComponent: T.elementType.isRequired,
};

export default FormattedMessage;
