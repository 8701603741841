import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';

export const ContentContainer = styled.View`
  align-items: center;
  display: flex;
  flex-grow: 1;
  margin-top: ${moderateScale(8)}px;
  padding-horizontal: ${moderateScale(24)}px;
`;
