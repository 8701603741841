import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import lottie from 'lottie-web';

import animationDictionary from '../../../utils/animationDictionary';
import { StyledView } from './styledComponents';

/**
 * If you want screenreader to have access to animation, wrap component in a view and give it
 * aria/accessibility props. The native animation component doesn't handle accessibility props
 * correctly, so, to keep interface the same, both web and native animations should be wrapped
 * with an accessible view instead of getting passed accessibility props directly. (Such wrapping
 * could be handled within this component itself...but we're foregoing that since very few of our
 * animations should be accessible and that refactor would require even more regression testing.)
 *
 * Eg,
 * <View accessible aria-label="Loading indicator" role="img" >
 *  <Animation name="loadingDog" />
 * </View>
 */

const WebAnimation = ({ lottieProps, name, ...restProps }) => {
  const animationDOMNodeRef = useRef(null);
  const path = animationDictionary(name);

  useEffect(() => {
    const animation = lottie.loadAnimation({
      animationData: path,
      autoplay: true,
      container: animationDOMNodeRef.current,
      loop: false,
      renderer: 'svg',
      ...lottieProps,
    });
    return () => animation.destroy();
  }, [lottieProps, path]);

  return (
    <StyledView
      ref={animationDOMNodeRef}
      accessibilityElementsHidden
      aria-hidden
      importantForAccessibility="no-hide-descendants"
      {...restProps}
    />
  );
};

WebAnimation.propTypes = { lottieProps: T.object, name: T.string.isRequired };

export default WebAnimation;
