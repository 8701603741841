import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const MessageContainer = styled.View`
  bottom: ${moderateScale(60)}px;
  flex-grow: 1;
  min-height: 100%;
`;

export const StyledPhoneLinkText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;
