import React, { Fragment } from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';

import { P2 } from '../../../../../base_ui';
import { ButtonText, StyledH5 } from './styledComponents';

const PrimaryLoanTooltip = ({ handleCloseTooltip }) => (
  <Fragment>
    <StyledH5>
      <FormattedMessage id={INTL_IDS.HOME_LOANCARD_PRIMARYLOAN_TOOLTIP_TITLE} />
    </StyledH5>
    <P2>
      <FormattedMessage id={INTL_IDS.HOME_LOANCARD_PRIMARYLOAN_TOOLTIP_TEXT} />
    </P2>
    <Pressable onPress={handleCloseTooltip}>
      <ButtonText>Got it</ButtonText>
    </Pressable>
  </Fragment>
);

PrimaryLoanTooltip.propTypes = { handleCloseTooltip: T.func.isRequired };

export default PrimaryLoanTooltip;
