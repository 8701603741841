import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { H5, PaymentTextField } from '../../../../base_ui';

export const AdditionalPaymentContent = styled.View`
  width: 100%;
`;

export const ButtonSpacer = styled.View`
  min-width: 44px;
  width: ${moderateScale(33)}px;
`;

export const ButtonWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  width: ${({ $width }) => $width || 'auto'};
`;
export const EditPaymentRow = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(55)}px;
  justify-content: space-between;
  padding-left: ${moderateScale(25)}px;
  padding-right: ${moderateScale(22)}px;
`;

export const RegularPaymentRow = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(55)}px;
  justify-content: space-between;
  padding:
    ${moderateScale(11)}px ${moderateScale(22)}px ${moderateScale(12)}px ${moderateScale(33)}px;
`;

export const StyledH5 = styled(H5)`
  color: ${({
    $disabled,
    $error,
    theme: { colors: { accentRed, primaryCarbon } },
  }) => !$disabled && $error ? accentRed : primaryCarbon};
  max-width: ${moderateScale(180)}px;
  opacity: ${({ $disabled }) => $disabled ? 0.5 : 1};
  padding: 0 ${moderateScale(8)}px;
`;

export const StyledPaymentTextField = styled(PaymentTextField).attrs(({
  theme: {
    fontFamilies: { secondaryFont },
    fontSizes: { f3 },
  },
}) => ({
  containerStyle: {
    alignSelf: 'center',
    justifyContent: 'flex-end',
  },
  errorStyle: {
    fontFamily: secondaryFont,
    fontSize: f3,
    left: 0,
    lineHeight: moderateScale(15),
    position: 'absolute',
    top: moderateScale(31),
  },
  inputContainerStyle: {
    height: moderateScale(32),
    width: moderateScale(80),
  },
  inputStyle: { textAlign: 'right' },
  labelStyle: {
    fontFamily: secondaryFont,
    fontSize: f3,
    left: 0,
    lineHeight: moderateScale(18),
    position: 'absolute',
    width: 'auto',
  },
}))``;
