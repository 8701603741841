import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import iconDictionary from '../../../utils/iconDictionary';
import ConditionalRender from '../ConditionalRender';
import {
  AlertBannerContainer,
  AlertHeading,
  AlertLinkText,
  AlertTextContainer,
} from './styledComponents';

const WarningIcon = iconDictionary('warning', { height: 48, width: 48 });

const DisasterAssistanceBanner = ({
  disclosureName = 'hurricaneIan',
  handleOpenDrawer,
  heading = 'Disaster Assistance',
  linkText = 'If you are impacted by Hurricane Ian, please click here for assistance',
}) => (
  <AlertBannerContainer>
    {WarningIcon}
    <AlertTextContainer>
      <ConditionalRender
        Component={<AlertHeading>{heading}</AlertHeading>}
        shouldRender={!!heading}
      />
      <Pressable onPress={() => handleOpenDrawer(disclosureName)}>
        <AlertLinkText>{linkText}</AlertLinkText>
      </Pressable>
    </AlertTextContainer>
  </AlertBannerContainer>
);

DisasterAssistanceBanner.propTypes = {
  disclosureName: T.string,
  handleOpenDrawer: T.func.isRequired,
  heading: T.string,
  linkText: T.string,
};

export default DisasterAssistanceBanner;
