/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  Line,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function Calendar(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(48)} viewBox="0 0 84 84" width={moderateScale(48)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M64.09,68.05H13.33V65.91H64.25l6.22-5.33v1.09A6.38,6.38,0,0,1,64.09,68.05Z" fill="#e6ebef" />
      <Rect fill="#e6ebef" height="43.02" width="8.56" x="70.25" y="27.79" />
      <Rect fill={svgHighlight} height="10.24" width="64.91" x="13.8" y="17.79" />
      <Rect fill={svgHighlight} height="10.71" width="8.21" x="70.51" y="17.64" />
      <Polyline fill="none" points="21.39 17.63 13.36 17.63 13.36 56.61" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Polyline fill="none" points="70.34 34.5 70.34 17.63 24.73 17.63" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M62,65.75a8.39,8.39,0,0,0,8.38-8.39V37.24" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M56.11,59.47s0,0,0-.07H4.47a6.37,6.37,0,0,0,6.37,6.36h51.1v0A6.29,6.29,0,0,1,56.11,59.47Z" fill="#e6ebef" />
      <Path d="M40.67,65.76H61.94v0a6.29,6.29,0,0,1-5.83-6.27s0,0,0-.07H4.47a6.37,6.37,0,0,0,6.37,6.36H37.55" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Polyline fill="none" points="13.38 65.84 13.38 71.55 70.55 71.55 70.55 64.15" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Polyline fill="none" points="78.91 66.6 78.91 71.54 70.55 71.54" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Polyline fill="none" points="70.34 17.63 78.91 17.63 78.91 63.72" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="62.77" x2="78.67" y1="28.26" y2="28.26" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="15.63" x2="60.45" y1="28.26" y2="28.26" />
      <Path d="M26.51,23.76c-2.71,0-4.91-2.91-4.91-6.5s2.2-6.51,4.91-6.51,4.92,2.92,4.92,6.51" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M44,23.76c-2.71,0-4.91-2.91-4.91-6.5s2.2-6.51,4.91-6.51,4.92,2.92,4.92,6.51" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M61.49,23.76c-2.71,0-4.91-2.91-4.91-6.5s2.2-6.51,4.91-6.51c2.21,0,4.07,1.92,4.69,4.57" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Ellipse cx="27.13" cy="23.78" rx="1" ry="1.34" />
      <Ellipse cx="44.25" cy="23.78" rx="1" ry="1.34" />
      <Ellipse cx="61.79" cy="23.78" rx="1" ry="1.34" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="7.23" x2="7.23" y1="9.63" y2="13.64" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="9.24" x2="5.23" y1="11.64" y2="11.64" />
      <Rect fill="none" height="2.54" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" width="2.54" x="68.67" y="78.34" />
      <Circle cx="73.75" cy="9.24" r="1.37" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
    </Svg>
  );
}
