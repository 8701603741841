/* eslint-disable sort-keys */
/* eslint-disable max-len */
import InboxScreen from '../../../containers/SecureMessaging/Inbox';
import MessageDetailsScreen from '../../../containers/SecureMessaging/MessageDetails/MessageDetails';
import NewMessageDocUploadScreen from '../../../containers/SecureMessaging/NewMessage/NewMessageUploadDocScreen';
import NewMessageDocReviewScreen from '../../../containers/SecureMessaging/NewMessage/NewMessageDocReviewScreen';
import NewMessageScreen from '../../../containers/SecureMessaging/NewMessage/NewMessageScreen';
import { SecureMessageDocReview } from '../../../components/SecureMessaging';

export default {
  // Message Details
  MessageDetails: { component: MessageDetailsScreen },
  SecureMessageDocReview: { component: SecureMessageDocReview },
  // New Message
  NewMessage: { component: NewMessageScreen },
  NewMessageDocReview: { component: NewMessageDocReviewScreen },
  NewMessageUploadDoc: { component: NewMessageDocUploadScreen },
  // Inbox
  SecureMessaging: { component: InboxScreen },
};
