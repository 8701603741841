import React, { useState } from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';
import { View } from 'react-native';

import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import { P2 } from '../Paragraphs';
import iconDictionary from '../../../utils/iconDictionary';
import {
  IllustrationTextWrapper,
  IllustrationWrapper,
  StyledHeader,
  StyledPressable,
  StyledView,
  TextWrapper,
} from './styledComponents';

const ArrowIcon = iconDictionary('arrowRightCarbonS');

const CardButton = ({
  header,
  name,
  onPress,
  text,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const { formatMessage, messages } = useIntl();

  return (
    <StyledPressable
      $pressed={isPressed}
      onPress={onPress}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
    >
      <StyledView>
        <IllustrationTextWrapper>
          <IllustrationWrapper>{iconDictionary(name)}</IllustrationWrapper>
          <TextWrapper>
            <StyledHeader>{header}</StyledHeader>
            <P2>{
              resolveIntlMessage({
                formatMessage,
                message: text,
                messages,
              })
            }
            </P2>
          </TextWrapper>
        </IllustrationTextWrapper>
        <View>
          {ArrowIcon}
        </View>
      </StyledView>
    </StyledPressable>
  );
};

CardButton.propTypes = {
  header: T.string.isRequired,
  name: T.string.isRequired,
  onPress: T.func.isRequired,
  text: T.string.isRequired,
};

export default CardButton;
