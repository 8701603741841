/*
 * Add Escrow Set Up Screen
 *
 */

import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeStep,
  chooseDocument,
  processDocument,
  resetFormState,
  resetRequestStatusState,
} from '@dmi/shared/redux/Mortgage/actions';
import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';
import makeSelectMortgage, {
  selectAddEscrowOption,
  selectDocumentRequestBody,
  selectFormErrors,
  selectRequestStatus,
} from '@dmi/shared/redux/Mortgage/selectors';

import EscrowUploadDoc from '../../../components/Mortgage/AddEscrow/EscrowUploadDoc';
import { StyledSecondaryHeader, StyledView } from './styledComponents';

const AddEscrowUploadDocScreen = ({
  addEscrowOption,
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  dispatchResetFormState,
  dispatchResetUploadDocumentRequestStatus,
  navigation,
  uploadDocumentBody,
}) => {
  const [uploadIndex, setUploadIndex] = useState(0);
  const isFirstFileChosen = uploadDocumentBody.documentSize > 0;
  const isSecondFileChosen = !!uploadDocumentBody.doc2;

  const { header } = ADD_ESCROW_DICT.docUpload;

  const handleStartOver = () => {
    navigation.navigate('AddEscrowSetUp');
  };

  const propsToPassDown = {
    addEscrowOption,
    chooseDocumentRequestStatus,
    dispatchChooseDocument,
    handleStartOver,
    isFirstFileChosen,
    isSecondFileChosen,
    reviewScreenName: 'AddEscrowReviewDoc',
    setUploadIndex,
    uploadIndex,
  };

  useEffect(() => {
    setUploadIndex(0);
    if (chooseDocumentRequestStatus === 'failed') {
      setUploadIndex(0);
      dispatchResetUploadDocumentRequestStatus({ state: 'processDocument' });
    }
    if (isFirstFileChosen) {
      setUploadIndex(1);
    } else if (isFirstFileChosen && isSecondFileChosen) {
      setUploadIndex(2);
    }
  }, [
    addEscrowOption,
    dispatchResetFormState,
    dispatchResetUploadDocumentRequestStatus,
    chooseDocumentRequestStatus,
    uploadDocumentBody,
    isFirstFileChosen,
    isSecondFileChosen,
  ]);

  const handleBackbutton = () => {
    if (isFirstFileChosen && !isSecondFileChosen) {
      navigation.navigate('AddEscrowReviewDoc');
    } else {
      navigation.navigate('AddEscrowSetUp');
    }
  };

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={() => handleBackbutton()}
        title={header}
      />
      <StyledView>
        <EscrowUploadDoc {...propsToPassDown} />
      </StyledView>
    </Fragment>
  );
};

AddEscrowUploadDocScreen.propTypes = {
  addEscrowOption: T.string.isRequired,
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  dispatchProcessDocument: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetUploadDocumentRequestStatus: T.func.isRequired,
  navigation: T.object.isRequired,
  uploadDocumentBody: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  addEscrowOption: selectAddEscrowOption(),
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
  errors: selectFormErrors('uploadDocument'),
  step: makeSelectMortgage('step'),
  uploadDocumentBody: selectDocumentRequestBody('addEscrow'),
});

const mapDispatchToProps = (dispatch) => ({
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchProcessDocument: (payload) => dispatch(processDocument(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetUploadDocumentRequestStatus:
    (payload) => dispatch(resetRequestStatusState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(AddEscrowUploadDocScreen);
