/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavMortgageDefaultLIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M11.6798 8C11.4329 8.00013 11.1916 8.07398 10.9869 8.21208C10.7822 8.35018 10.6234 8.54626 10.5308 8.77518C10.4383 9.0041 10.4162 9.25544 10.4674 9.497C10.5185 9.73857 10.6407 9.95935 10.8181 10.1311C10.9955 10.3028 11.2202 10.4176 11.4633 10.4608C11.7064 10.5041 11.9569 10.4737 12.1827 10.3737C12.4084 10.2737 12.5992 10.1086 12.7305 9.89946C12.8619 9.69035 12.9277 9.4468 12.9198 9.2C12.9094 8.87811 12.7742 8.5729 12.5428 8.34893C12.3113 8.12497 12.0018 7.99983 11.6798 8Z" fill="black" />
      <Path d="M22.9096 9.27012L20.6896 7.86012L18.6096 6.51012V3.51012C18.6209 3.42141 18.6131 3.33132 18.5868 3.24584C18.5605 3.16037 18.5164 3.08146 18.4573 3.01437C18.3981 2.94729 18.3254 2.89355 18.2439 2.85675C18.1624 2.81994 18.074 2.8009 17.9846 2.8009C17.8952 2.8009 17.8068 2.81994 17.7253 2.85675C17.6438 2.89355 17.5711 2.94729 17.512 3.01437C17.4528 3.08146 17.4087 3.16037 17.3824 3.24584C17.3561 3.33132 17.3484 3.42141 17.3596 3.51012V5.70012L12.0596 2.25012C11.9566 2.1842 11.8369 2.14917 11.7146 2.14917C11.5923 2.14917 11.4726 2.1842 11.3696 2.25012L3.51961 7.51012L0.739608 9.27012C0.625136 9.34368 0.537558 9.45233 0.489973 9.57981C0.442389 9.70728 0.437357 9.84674 0.475629 9.97732C0.513902 10.1079 0.593422 10.2226 0.702294 10.3042C0.811166 10.3858 0.943538 10.43 1.07961 10.4301C1.19645 10.4261 1.31022 10.3917 1.40961 10.3301L2.40961 9.69012V21.7601C2.41223 21.9255 2.47976 22.0832 2.59763 22.1991C2.71549 22.3151 2.87424 22.3801 3.03961 22.3801H20.6396C20.805 22.3801 20.9637 22.3151 21.0816 22.1991C21.1995 22.0832 21.267 21.9255 21.2696 21.7601V9.76012L22.2696 10.3701C22.369 10.4317 22.4828 10.4661 22.5996 10.4701C22.705 10.4692 22.8085 10.4422 22.901 10.3916C22.9934 10.341 23.072 10.2684 23.1296 10.1801C23.1795 10.1073 23.2135 10.0248 23.2294 9.93797C23.2454 9.85115 23.2429 9.76195 23.2221 9.67616C23.2014 9.59036 23.1629 9.50988 23.109 9.43993C23.0552 9.36998 22.9872 9.31213 22.9096 9.27012ZM13.7996 21.1301H9.89961V14.5901H13.7996V21.1301ZM19.9996 8.92012V21.1301H14.7996V14.1301C14.797 13.9983 14.7435 13.8726 14.6503 13.7794C14.5571 13.6862 14.4314 13.6327 14.2996 13.6301H9.39961C9.2678 13.6327 9.14211 13.6862 9.04889 13.7794C8.95567 13.8726 8.90217 13.9983 8.89961 14.1301V21.1301H3.66961V8.92012L11.7196 3.53012L19.4696 8.53012L19.9996 8.87012V8.92012Z" fill="black" />
    </Svg>
  );
}
