import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  H3,
  P2,
  PrimaryDivider,
  SecondaryCard,
} from '../../base_ui';

// index.js
export const ClaimContentContainer = styled.View`
  padding-horizontal: ${moderateScale(24)}px;
`;

// Claim Info
export const ClaimInfoContainer = styled.View`
  padding-horizontal: ${moderateScale(24)}px;
`;

export const ClaimNumber = styled(H3)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  margin-top: ${moderateScale(8)}px;
  max-width: 100%;
`;

// Contact card
export const ContactCard = styled(SecondaryCard)`
  height: ${moderateScale(184)}px;
  margin: ${moderateScale(20)}px 0 ${moderateScale(30)}px 0;
  padding-left: ${moderateScale(24)}px;
`;

export const ContactContainer = styled.View`
  margin-bottom: ${moderateScale(30)}px;
  margin-top: ${moderateScale(23)}px;
`;

export const ContactHeader = styled(H3)`
  margin-top: ${moderateScale(17)}px;
`;

export const ContactText = styled(P2)`
  margin-left: ${moderateScale(12)}px;
`;

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(8)}px;
`;

export const PhoneNumber = styled(P2)`
  color: ${({ theme: { colors: { primary } } }) => primary};
  margin-left: ${moderateScale(12)}px;
`;

// Disbursements
export const ContentContainer = styled.View`
  margin-bottom: ${moderateScale(12)}px;
`;

export const DisbursedContainer = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(24)}px;
`;

export const DisbursementsContainer = styled.View`
  margin-top: ${moderateScale(20)}px;
`;

// Documents
export const DocumentsContentContainer = styled.View`
  margin-bottom: ${moderateScale(12)}px;
`;

export const DocumentsDivider = styled(PrimaryDivider)`
  margin-bottom: ${moderateScale(18)}px;
`;

export const DocumentsHeader = styled(H3)`
  margin-bottom: ${moderateScale(16)}px;
`;
