import React from 'react';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { Skeleton } from '../../../base_ui';
import { RowView, SkeletonView } from './styledComponents';

const FilterSkeleton = () => (
  <RowView isFilter>
    <SkeletonView $widthPercent={18}>
      <Skeleton
        $bottomMargin={`${moderateScale(14)}px`}
        $topMargin={`${moderateScale(9)}px`}
        height={`${moderateScale(16)}px`}
        width="100%"
      />
    </SkeletonView>
    <SkeletonView $widthPercent={16}>
      <Skeleton
        $bottomMargin={`${moderateScale(14)}px`}
        $topMargin={`${moderateScale(9)}px`}
        height={`${moderateScale(16)}px`}
        width="100%"
      />
    </SkeletonView>
    <SkeletonView $widthPercent={14}>
      <Skeleton
        $bottomMargin={`${moderateScale(14)}px`}
        $topMargin={`${moderateScale(9)}px`}
        height={`${moderateScale(16)}px`}
        width="100%"
      />
    </SkeletonView>
    <SkeletonView $widthPercent={17}>
      <Skeleton
        $bottomMargin={`${moderateScale(14)}px`}
        $topMargin={`${moderateScale(9)}px`}
        height={`${moderateScale(16)}px`}
        width="100%"
      />
    </SkeletonView>
  </RowView>
);

export default FilterSkeleton;
