/* eslint-disable max-len */
import React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function FloodIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <G>
        <Circle cx="8.8" cy="5.8" r="0.7" />
        <Path d="M10.3,14c-0.3,0-0.5-0.2-0.5-0.5V8.8h-2v4.7c0,0.3-0.2,0.5-0.5,0.5s-0.5-0.2-0.5-0.5V8.3c0-0.3,0.2-0.5,0.5-0.5h3c0.3,0,0.5,0.2,0.5,0.5v5.2C10.8,13.8,10.6,14,10.3,14z" fill={iconStatic} />
        <Path d="M14.2,14.3H3.5c-0.3,0-0.6-0.3-0.6-0.6v-8c0-0.2,0.1-0.4,0.3-0.5l5.2-3.5c0.2-0.1,0.5-0.1,0.7,0l5.4,3.5c0.2,0.1,0.3,0.3,0.3,0.5v8C14.8,14,14.5,14.3,14.2,14.3z M4.2,13h9.4V6L8.8,2.9L4.2,6V13z" fill={iconStatic} />
        <Path d="M2.7,11.3l0.1,3.1h12.5l0.1-3c0,0-0.7-1-2.7-0.9s-1.6,1.4-4.8,0.9C4.7,11,4.9,9.5,2.7,11.3z" fill="#ffffff" />
        <Path d="M2.3,7.1C2.1,7.1,1.9,7,1.8,6.8C1.6,6.5,1.7,6.1,2,5.9l1.7-1.1C4,4.7,4.3,4.8,4.5,5c0.2,0.3,0.1,0.7-0.2,0.9L2.7,7C2.6,7,2.5,7.1,2.3,7.1z" fill={iconStatic} />
        <Path d="M15.3,7.1c-0.1,0-0.2,0-0.3-0.1l-1.7-1.1C13.1,5.7,13,5.3,13.2,5c0.2-0.3,0.6-0.4,0.9-0.2l1.7,1.1c0.3,0.2,0.4,0.6,0.2,0.9C15.8,7,15.6,7.1,15.3,7.1z" fill={iconStatic} />
        <Path d="M16.7,12.2c-0.1,0-0.8,0-1.6-0.6c-0.7-0.5-1.4-0.7-2.3-0.7c-0.7,0-1.5,0.4-1.9,0.7l-0.3,0.2c-0.4,0.2-0.8,0.4-1.7,0.4c-0.9,0-1.3-0.3-1.7-0.4l-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.4-0.2-1.2-0.7-1.8-0.7c-0.7,0-1.7,0.3-2.6,0.9c-1,0.6-1.5,0.4-1.7,0.2c-0.2-0.2-0.3-0.6,0-0.8c0.2-0.2,0.4-0.2,0.6-0.2c0.1,0,0.2-0.1,0.5-0.2c0.5-0.3,1.9-1,3.2-1c1,0,1.9,0.5,2.4,0.8c0.1,0,0.1,0.1,0.2,0.1l0.1,0.1C8.1,10.9,8.4,11,8.9,11c0.6,0,0.9-0.1,1.2-0.3l0.3-0.2c0.5-0.3,1.4-0.8,2.4-0.8c1,0,2,0.3,2.9,0.9c0.6,0.4,1,0.4,1,0.4c0.3,0,0.6,0.3,0.6,0.6S17,12.2,16.7,12.2C16.7,12.2,16.7,12.2,16.7,12.2z M1.6,11.1C1.6,11.1,1.6,11.1,1.6,11.1C1.6,11.1,1.6,11.1,1.6,11.1z" fill="#ffffff" />
        <Path d="M1.3,13.4c-0.2,0-0.3,0-0.3,0c-0.3-0.1-0.5-0.4-0.5-0.7c0.1-0.3,0.4-0.5,0.7-0.5l0,0c0,0,0.4,0,0.9-0.3c0.7-0.5,1.7-1,2.9-1c1,0,1.9,0.6,2.4,0.9c0.1,0.1,0.2,0.1,0.2,0.1c0.2,0.1,0.5,0.2,1.2,0.2c0.7,0,1-0.1,1.2-0.2c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,1.4-0.9,2.4-0.9c1.2,0,2.2,0.5,2.9,1c0.5,0.3,0.9,0.3,0.9,0.3c0.3,0,0.6,0.2,0.7,0.5c0.1,0.3-0.1,0.6-0.5,0.7c-0.1,0-0.8,0.2-1.8-0.5c-0.6-0.4-1.3-0.8-2.2-0.8c-0.6,0-1.4,0.4-1.8,0.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.9,0.4-1.8,0.4c-0.9,0-1.4-0.1-1.8-0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.3-1.1-0.7-1.8-0.7c-0.9,0-1.6,0.4-2.2,0.8C2.2,13.3,1.6,13.4,1.3,13.4z" fill={iconStatic} />
        <Path d="M1.3,16.8c-0.2,0-0.3,0-0.3,0c-0.3-0.1-0.5-0.4-0.5-0.7c0.1-0.3,0.4-0.5,0.7-0.5l0,0c0,0,0.4,0,0.9-0.3c0.7-0.5,1.7-1,2.9-1c1,0,1.9,0.6,2.4,0.9c0.1,0.1,0.2,0.1,0.2,0.1c0.2,0.1,0.5,0.2,1.2,0.2c0.7,0,1-0.1,1.2-0.2c0.1,0,0.1-0.1,0.2-0.1c0.5-0.3,1.4-0.9,2.4-0.9c1.2,0,2.2,0.5,2.9,1c0.5,0.3,0.9,0.3,0.9,0.3c0.3,0,0.6,0.2,0.7,0.5c0.1,0.3-0.1,0.6-0.5,0.7c-0.1,0-0.8,0.2-1.8-0.5c-0.6-0.4-1.3-0.8-2.2-0.8c-0.6,0-1.4,0.4-1.8,0.7c-0.1,0.1-0.2,0.1-0.3,0.2c-0.4,0.2-0.9,0.4-1.8,0.4c-0.9,0-1.4-0.1-1.8-0.4c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.3-1.1-0.7-1.8-0.7c-0.9,0-1.6,0.4-2.2,0.8C2.2,16.7,1.6,16.8,1.3,16.8z" fill={iconStatic} />
        <Path d="M1.9,4.3c-0.1,0-0.1,0-0.2,0C1.5,4.2,1.3,3.9,1.4,3.6l0.4-0.9c0.1-0.3,0.4-0.4,0.6-0.3c0.3,0.1,0.4,0.4,0.3,0.6L2.4,4C2.3,4.2,2.1,4.3,1.9,4.3z " fill={iconStatic} />
        <Path d="M15.5,4.3c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.4-0.3-0.6l0.4-0.9c0.1-0.3,0.4-0.4,0.6-0.3c0.3,0.1,0.4,0.4,0.3,0.6L16,4C15.9,4.2,15.7,4.3,15.5,4.3z " fill={iconStatic} />
        <Path d="M5,2.9c-0.1,0-0.1,0-0.2,0C4.5,2.7,4.4,2.4,4.5,2.2l0.4-0.9C5,1,5.3,0.9,5.5,1c0.3,0.1,0.4,0.4,0.3,0.6L5.4,2.5C5.4,2.7,5.2,2.9,5,2.9z " fill={iconStatic} />
        <Path d="M12.3,2.9c-0.1,0-0.1,0-0.2,0c-0.3-0.1-0.4-0.4-0.3-0.6l0.4-0.9C12.3,1,12.6,0.9,12.9,1c0.3,0.1,0.4,0.4,0.3,0.6l-0.4,0.9C12.7,2.7,12.5,2.9,12.3,2.9z " fill={iconStatic} />
      </G>
    </Svg>
  );
}
