import styled from 'styled-components/native';

import {
  H3,
  IconButton,
  MediumPrimaryButton,
  P3,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

// PaydownView Components

export const RadioButton = styled.Pressable`
  margin-right: ${moderateScale(8)}px;
`;

export const PaydownRadioWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-left: ${moderateScale(20)}px;
  width: 100%;
`;

export const PayoffRadioWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: ${moderateScale(16)}px;
  margin-left: ${moderateScale(20)}px;
  width: 100%;
`;

export const StyledPaydownText = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${moderateScale(14)}px;
  margin: 0 0 ${moderateScale(24)}px ${moderateScale(67)}px;
  width: 100%;
`;

// PayoffView Components

export const ArrowDownIconWrapper = styled.View`
  margin-left: auto;
  margin-right: ${moderateScale(13)}px;
`;

export const CalendarIconWrapper = styled.View`
  margin-left: ${moderateScale(11)}px;
  margin-right: ${moderateScale(8)}px;
`;

export const StyledDatePicker = styled.Pressable`
  align-items: center;
  border: 1px solid ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  display: flex;
  flex-direction: row;
  height: ${moderateScale(29)}px;
  margin-bottom: ${({ $isPaydown }) => $isPaydown ? moderateScale(16) : moderateScale(24)}px;
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
  width: ${({ $isPaydown }) => $isPaydown ? moderateScale(240) : moderateScale(222)}px;
`;

// StatementView Components

export const StyledTextContainer = styled.Pressable`
  margin: ${moderateScale(16)}px 0;
`;

export const StyledText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  line-height: ${moderateScale(22)}px;
`;

// Payoff Reasons
export const PayoffReasonOptionsTextWrapper = styled.View`
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: ${moderateScale(36)}px;
`;

export const PayoffReasonOptionsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-vertical: ${moderateScale(12)}px;
  min-height: ${moderateScale(16)}px;
`;

export const StyledH3 = styled(H3)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledP3 = styled(P3)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-height: ${moderateScale(36)}px;
  min-width: ${moderateScale(36)}px;
`;

export const StyledPayoffReason = styled.View`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: ${moderateScale(16)}px;
  width: 100%;
`;

export const StyledPayoffReasonHeader = styled(H3)`
  margin-bottom: ${moderateScale(8)}px;
`;

export const StyledNextButton = styled(MediumPrimaryButton)`
  margin-top: ${moderateScale(24)}px;
`;

export const StyledCancelLink = styled.Text`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  font-size: ${moderateScale(14)}px;
  margin-top: ${moderateScale(16)}px;
`;
