const numCircles = 12;
const numCirclesArray = new Array(numCircles).fill(null);
const defaultOpaqueStartKeyframe = 40;
const defaultOpaqueEndKeyframe = 100;
const totalOpaqueKeyframes = defaultOpaqueEndKeyframe - defaultOpaqueStartKeyframe;

/**
 * Determines what the opacity of each circle in the LoadingIndicator should be at any given
 * keyframe to mimic the Desktop's version, which is able to use simpler css keyframes and css
 * animations.
 *
 * @param {object} keyframe Animated.Value representing a keyframe that loops from 0-100
 * @returns {object[]} Array of AnimatedInterpolations representing opacities at any given moment's
 *                     animation keyframe.
 */
export const getOpacityInterpolations = (keyframe) => (
  numCirclesArray.map((_, index) => {
    const keyframeIncrement = 100 * (index / numCircles);
    const newOpaqueStartKeyframe = defaultOpaqueStartKeyframe + keyframeIncrement;
    const newOpaqueEndKeyframe = (defaultOpaqueEndKeyframe % 100) + keyframeIncrement;
    if (newOpaqueStartKeyframe <= 100) {
      const newOpacityAtKeyframe100 = keyframeIncrement / totalOpaqueKeyframes;
      return keyframe.interpolate({
        inputRange: [
          0,
          newOpaqueEndKeyframe,
          newOpaqueStartKeyframe,
          newOpaqueStartKeyframe,
          100,
        ],
        outputRange: [
          newOpacityAtKeyframe100,
          0,
          0,
          1,
          newOpacityAtKeyframe100,
        ],
      });
    }

    const moduloedNewOpaqueStartKeyframe = newOpaqueStartKeyframe % 100;
    return keyframe.interpolate({
      inputRange: [
        0,
        moduloedNewOpaqueStartKeyframe,
        moduloedNewOpaqueStartKeyframe,
        newOpaqueEndKeyframe,
        100,
      ],
      outputRange: [
        0,
        0,
        1,
        0,
        0,
      ],
    });
  })
);
