import React from 'react';
import Svg, { Circle, Rect } from 'react-native-svg';

import { moderateScale } from '../scaleHelpers';

export default function SvgComponent(props) {
  return (
    <Svg height={moderateScale(20)} viewBox="0 0 100 100" width={moderateScale(20)} {...props}>
      <Circle cx="50" cy="50" fill="green" r="45" stroke="blue" strokeWidth="2.5" />
      <Rect fill="yellow" height="70" stroke="red" strokeWidth="2" width="70" x="15" y="15" />
    </Svg>
  );
}
