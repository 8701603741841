/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterWildfire(props) {
  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} {...props}>
      <Rect className="cls-1" fill="#e6eaed" height="24.81" width="40.37" x="23.89" y="33.17" />
      <Path className="cls-1" d="M26.79,43.86s-11-8.07-1.23-19.13c0,0,6.35-5.09,4.35-11.06,0,0-.11-2.88-3.35-6.2,0,0,10.89,4.44,8.66,10.8-1.52,4.32-1.33,6.36,2.15,3.91,0,0,9.57-8.85,6.79-15.93A7.81,7.81,0,0,1,42.94.61s.66,3,4.67,7.08c0,0,7.51,7,3.39,14.61,0,0,1.81-5.57,9.36-8.07,0,0-5.28,10.22,1.65,14.16a9.19,9.19,0,0,1,4.35-7.76s-3.22,4.49.23,8.58c0,0,2.81,4.5-1.94,12C64.65,41.21,55.41,48.29,26.79,43.86Z" fill="#e6eaed" />
      <Path className="cls-2" d="M21.93,36.43l-5.85,11c-.11.2.06.43.32.44l.83,0a.33.33,0,0,1,.33.43L11.55,62a.32.32,0,0,0,.34.43h1a.34.34,0,0,1,.36.4l-2.38,7.52a1.09,1.09,0,0,0,1.3,1.29h0c.25,0,.45.16.41.36l-.34,2A1.65,1.65,0,0,0,14,75.74h15.7a1.56,1.56,0,0,0,1.69-1.67h0a.33.33,0,0,1,.4-.36h0c1.33.12,2.39-.93,2-2L31.75,65c-.08-.24.19-.46.46-.39l1,.27c.28.08.55-.15.46-.39L29.34,52.11c-.08-.24.17-.46.45-.4l.7.16c.3.06.56-.19.44-.43l-4.32-8.91c-.11-.22.11-.47.39-.44h0c.3,0,.52-.23.38-.46l-3.2-5.25A1.35,1.35,0,0,0,21.93,36.43Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line className="cls-2" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="22.75" x2="22.75" y1="75.95" y2="80.95" />
      <Path className="cls-2" d="M63.85,36.43,58,47.45c-.11.2.06.43.32.44l.83,0a.33.33,0,0,1,.33.43L53.47,62a.32.32,0,0,0,.34.43h1a.34.34,0,0,1,.36.4l-2.38,7.52a1.09,1.09,0,0,0,1.3,1.29h0c.25,0,.45.16.41.36l-.34,2a1.65,1.65,0,0,0,1.79,1.77h15.7a1.56,1.56,0,0,0,1.69-1.67h0a.33.33,0,0,1,.4-.36h0c1.33.12,2.39-.93,2-2L73.67,65c-.08-.24.19-.46.46-.39l1,.27c.28.08.55-.15.46-.39L71.26,52.11c-.08-.24.17-.46.45-.4l.7.16c.3.06.56-.19.44-.43l-4.32-8.91c-.11-.22.11-.47.39-.44h0c.3,0,.52-.23.38-.46l-3.2-5.25A1.35,1.35,0,0,0,63.85,36.43Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line className="cls-2" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="64.67" x2="64.67" y1="75.95" y2="80.95" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="78.06" x2="7.64" y1="81.33" y2="81.33" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="79.34" x2="80.12" y1="81.34" y2="81.34" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="5.36" x2="6.15" y1="81.36" y2="81.36" />
      <Path className="cls-2" d="M42.76,23.8,34.93,38.52c-.15.28.08.59.43.61l1.11,0a.43.43,0,0,1,.44.57L28.87,58c-.12.28.12.57.47.57h1.27a.44.44,0,0,1,.48.54l-3.17,10c-.3.94.61,1.84,1.74,1.73h0a.46.46,0,0,1,.55.48L29.75,74a2.19,2.19,0,0,0,2.38,2.37h21a2.08,2.08,0,0,0,2.25-2.24h0a.46.46,0,0,1,.54-.48h0c1.78.17,3.19-1.24,2.73-2.72L55.87,62c-.1-.32.26-.61.63-.52l1.38.37c.38.1.73-.21.62-.53L52.66,44.76c-.11-.32.22-.62.6-.53l.94.21c.39.08.74-.25.58-.58L49,32c-.15-.31.14-.63.52-.59h0c.4.05.69-.31.51-.62l-4.29-7A1.8,1.8,0,0,0,42.76,23.8Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line className="cls-2" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="43.84" x2="43.84" y1="40.77" y2="80.95" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" x1="37.27" x2="43.66" y1="64.13" y2="68.83" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" x1="50.06" x2="44.04" y1="53.64" y2="58.41" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5px" x1="46.94" x2="43.81" y1="45.01" y2="46.94" />
    </Svg>
  );
}
