import { Animated, Platform } from 'react-native';

const useNativeDriver = Platform.OS !== 'web';

const progressSequence = (value) => Animated.loop(
  Animated.sequence([
    Animated.timing(value, {
      duration: 0,
      toValue: 0,
      useNativeDriver,
    }),
    Animated.timing(value, {
      duration: 400,
      toValue: 25,
      useNativeDriver,
    }),
    Animated.timing(value, {
      duration: 400,
      toValue: 50,
      useNativeDriver,
    }),
    Animated.timing(value, {
      duration: 400,
      toValue: 75,
      useNativeDriver,
    }),
    Animated.timing(value, {
      duration: 400,
      toValue: 100,
      useNativeDriver,
    }),
    Animated.timing(value, {
      duration: 100,
      toValue: 100,
      useNativeDriver,
    }),
  ]),
);
export default progressSequence;
