import styled from 'styled-components/native';
import { Animated } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const CirclePrimitive = styled.Text`
  background-color: #999;
  // '%' unit not allowed; a px equivalent of 50% width works cross-platform on sm/lg screens
  borderRadius: ${moderateScale(3)}px;
  height: ${moderateScale(6)}px;
  overflow: hidden;
  margin: 0 auto;
  width: ${moderateScale(6)}px;
`;

export const LoadingSection = styled.View`
  height: ${moderateScale(40)}px;
  margin: ${moderateScale(20)}px auto;
  position: relative;
  width: ${moderateScale(40)}px;
`;

export const StyledAnimatedView = styled(Animated.View)`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transform: rotate(${({ $rotate }) => $rotate}deg);
  width: 100%;
`;

export const Wrapper = styled.View`
  flex-grow: 1;
  justify-content: ${({ $isCentered }) => $isCentered ? 'center' : 'flex-start'};
`;
