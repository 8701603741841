import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import { formatDate } from '@dmi/shared/utils/globalHelpers';
import { MESSAGE_TYPE_CLAIM } from '@dmi/shared/redux/LossDraft/constants';

import { ConditionalRender } from '../../base_ui';
import {
  Bullet,
  BulletWrapper,
  DividerRowView,
  PressableRow,
  RowCreatorSubjectView,
  RowText,
  RowTextDate,
  RowView,
  StyledPrimaryDivider,
} from './styledComponents';

const TableRow = ({
  author,
  isUnreadClaimEvent,
  lastOpened,
  messageId,
  messageType,
  onPressTableRow,
  subject,
  unreadReplyCount,
  updated,
}) => {
  const [isPressing, setIsPressing] = useState(false);
  const createdFormated = formatDate(updated, 'UTC', 'MM/DD/YY');
  const isUnread = messageType === MESSAGE_TYPE_CLAIM
    ? isUnreadClaimEvent
    : !lastOpened || !!unreadReplyCount;

  return (
    <Fragment>
      <PressableRow
        $isPressing={isPressing}
        aria-label={`view message details, ${subject}, ${updated}`}
        onPress={() => onPressTableRow({ messageId, messageType })}
        onPressIn={() => setIsPressing(true)}
        onPressOut={() => setIsPressing(false)}
        role="button"
      >
        <RowView>
          <RowCreatorSubjectView>
            <RowText $isBold={isUnread}>
              <ConditionalRender
                Component={(
                  <BulletWrapper>
                    <Bullet />
                  </BulletWrapper>
                )}
                shouldRender={isUnread}
              />
              {author}
            </RowText>
            <RowText $isBold={isUnread}>{subject}</RowText>
          </RowCreatorSubjectView>
          <RowTextDate $isBold={isUnread}>{createdFormated}</RowTextDate>
        </RowView>
      </PressableRow>
      <DividerRowView>
        <StyledPrimaryDivider />
      </DividerRowView>
    </Fragment>
  );
};

TableRow.propTypes = {
  author: T.string.isRequired,
  isUnreadClaimEvent: T.bool,
  lastOpened: T.string,
  messageId: T.string.isRequired,
  messageType: T.string,
  onPressTableRow: T.func.isRequired,
  subject: T.string.isRequired,
  unreadReplyCount: T.number,
  updated: T.string.isRequired,
};

export default TableRow;
