import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { PrimaryDivider } from '../../../../base_ui';

export const CenterContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: ${moderateScale(36)}px;
`;

export const Container = styled.View`
  flex-grow: 1;
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(8)}px;
`;

export const RowContainer = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(52)}px;
  justify-content: space-between;
`;

export const RowSubcontainer = styled.View`
  flex-direction: row;
  padding-right: ${moderateScale(14)}px;
`;

export const SmallLabelContainer = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  padding-bottom: ${moderateScale(3)}px;
  padding-right: ${moderateScale(6)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
