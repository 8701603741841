import React, { Fragment, useCallback, useRef } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import * as StoreReview from 'expo-store-review';
import { useFocusEffect } from '@react-navigation/native';

import {
  CONFIRMATION_DICTIONARY_AUTOPAY,
  INFO_COPY_DICT,
  PAYMENT_FLOW_TYPE_AUTOPAY,
} from '@dmi/shared/redux/Payments/Autopay/constants';
import {
  CONFIRMATION_DICTIONARY_OTP,
  INFO_COPY_DICT as OTP_INFO_COPY_DICT,
  PAYMENT_FLOW_TYPE_OTP,
} from '@dmi/shared/redux/Payments/OneTimePayment/constants';

import { delay } from '../../../../utils/globalHelpers';
import {
  CardButton,
  ConditionalRender,
  NativeAnimation,
  P2,
  WebAnimation,
} from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import { InfoSection } from '../../sharedComponents';
import { ContentWrapper } from '../../styledComponents';
import EmailErrorBanner from './EmailErrorBanner';
import { cardButtonPropsHelper } from './helpers';
import {
  AnimationWrapper,
  CancelWrapper,
  CardButtonWrapper,
  DataLabel,
  DataLine,
  DataWrapper,
  InfoSectionContainer,
  StyledBorderlessButton,
  StyledPrimaryDivider,
  SubHeader,
  SubHeaderSubText,
} from './styledComponents';

const ConfirmationSuccess = ({
  confirmationType,
  customerServicePhoneNumber,
  data,
  dispatchSetIsModalOpen,
  hasLateCharges,
  isEligibleForAutopay,
  isInAppReviewSupported = false,
  isMurabahaAccount,
  modalProps,
  navigation,
  prevConfirmationType,
  showEmailErrorMessage,
  type,
}) => {
  const { isOpen } = modalProps;
  const isOtp = type === PAYMENT_FLOW_TYPE_OTP;
  const CONFIRMATION_DICTIONARY =
    isOtp ? CONFIRMATION_DICTIONARY_OTP : CONFIRMATION_DICTIONARY_AUTOPAY[confirmationType];
  const {
    modalToOpen,
    successHeader,
    successHeaderMobile,
    successLinkLabel,
    successSubHeader,
    successText,
  } = CONFIRMATION_DICTIONARY;
  const isInitialMount = useRef(true);
  const infoSectionData = isOtp
    ? OTP_INFO_COPY_DICT.review
    : INFO_COPY_DICT.confirmation[prevConfirmationType || confirmationType];
  const cardButtonProps = cardButtonPropsHelper({
    confirmationType,
    isEligibleForAutopay,
    isMurabahaAccount,
    isOtp,
    navigation,
  });

  useFocusEffect(
    // eslint-disable-next-line consistent-return
    useCallback(() => {
      /*
        We only want the requestReview flow to happen on first render, not every time the useEffect
        dependencies change. We listen for any change of dependencies (namely isCancelModalOpen)
        simply to trigger the cleanup/cancellation.
      */
      if (
        isInitialMount.current
        && type === 'otp'
        && isInAppReviewSupported
        && !isOpen
      ) {
        isInitialMount.current = false;
        let isCancelled = false;

        (async () => {
          await delay(2500);
          if (!isCancelled && !hasLateCharges) {
            StoreReview.requestReview();
          }
        })();

        // Prevent requestReview call if CancelModal opens or if screen blurs/unmounts
        return () => {
          isCancelled = true;
        };
      }
    }, [
      hasLateCharges,
      isInAppReviewSupported,
      isOpen,
      type,
    ]),
  );

  return (
    <Fragment>
      <SecondaryHeader
        handleDone={() => navigation.navigate('Payments')}
        title={successHeaderMobile || successHeader}
      />
      <ContentWrapper>
        <AnimationWrapper>
          <ConditionalRender
            Component={<WebAnimation name="flyingMoneyMobile" />}
            FallbackComponent={<NativeAnimation name="flyingMoneyMobile" />}
            shouldRender={Platform.OS === 'web'}
          />
        </AnimationWrapper>
        <SubHeader>{successSubHeader}</SubHeader>
        <SubHeaderSubText>{successText}</SubHeaderSubText>
        <ConditionalRender Component={EmailErrorBanner} shouldRender={!!showEmailErrorMessage} />
        <DataWrapper>
          {data.map(({ label, value }) => (
            <ConditionalRender
              key={label}
              Component={(
                <DataLine>
                  <DataLabel>{label}</DataLabel>
                  <P2>{value}</P2>
                </DataLine>
              )}
              shouldRender={!!value}
            />
          ))}
        </DataWrapper>
        <StyledPrimaryDivider />
        <CancelWrapper>
          <StyledBorderlessButton
            label={successLinkLabel}
            onPress={() => dispatchSetIsModalOpen({ key: modalToOpen, value: true })}
          />
        </CancelWrapper>
        <ConditionalRender
          Component={() => (
            <InfoSectionContainer $isAutopay={type === PAYMENT_FLOW_TYPE_AUTOPAY}>
              <InfoSection
                customerServicePhoneNumber={customerServicePhoneNumber}
                data={infoSectionData}
              />
            </InfoSectionContainer>
          )}
          shouldRender={!!infoSectionData}
        />
        <ConditionalRender
          Component={() => (
            <CardButtonWrapper>
              <CardButton {...cardButtonProps} />
            </CardButtonWrapper>
          )}
          shouldRender={!!cardButtonProps}
        />
      </ContentWrapper>
    </Fragment>
  );
};

ConfirmationSuccess.propTypes = {
  confirmationType: T.string,
  customerServicePhoneNumber: T.string,
  data: T.arrayOf(T.shape({
    label: T.string.isRequired,
    value: T.string.isRequired,
  })).isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  hasLateCharges: T.bool,
  isEligibleForAutopay: T.bool,
  isInAppReviewSupported: T.bool,
  isMurabahaAccount: T.bool.isRequired,
  modalProps: T.shape({ isOpen: T.bool.isRequired }).isRequired,
  navigation: T.object.isRequired,
  prevConfirmationType: T.string,
  showEmailErrorMessage: T.bool,
  type: T.oneOf(['autopay', 'otp']).isRequired,
};

export default ConfirmationSuccess;
