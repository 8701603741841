import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

import {
  MultiplePaymentsHistoryScreen,
  PaymentDetailScreen,
  PaymentHistoryScreen,
} from '../../../../containers/Payments/History';

const Stack = createStackNavigator();

const PaymentHistoryStack = () => (
  <Stack.Navigator
    initialRouteName="PaymentHistory"
    screenOptions={{
      cardStyle: { ...Platform.select({ web: { height: '-webkit-fill-available' } }) },
      headerShown: false,
    }}
  >
    <Stack.Screen component={PaymentHistoryScreen} name="PaymentHistory" />
    <Stack.Screen component={MultiplePaymentsHistoryScreen} name="MultiplePaymentsHistory" />
    <Stack.Screen component={PaymentDetailScreen} name="PaymentDetail" />
  </Stack.Navigator>
);

export default PaymentHistoryStack;
