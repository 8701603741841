import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';

import { P2 } from '../../../../base_ui';
import { HomeH2 } from './styledComponents';

const DefaultMortgageHeader = () => (
  <Fragment>
    <HomeH2>
      <FormattedMessage id={INTL_IDS.MORTGAGE_HOME_HEADER_TITLE} />
    </HomeH2>
    <P2>
      <FormattedMessage id={INTL_IDS.MORTGAGE_HOME_HEADER_TEXT} />
    </P2>
  </Fragment>
);

export default DefaultMortgageHeader;
