import React, { Fragment } from 'react';
import T from 'prop-types';

import { ConditionalRender, H3, P2 } from '../../../../base_ui';
import SecureMessagingBanner from '../../../../SecureMessagingBanner';
import {
  HeaderWrapper,
  PaymentDetailContainer,
  PaymentDetailRow,
  PaymentDetailRowIndented,
  PaymentDetailRows,
  StyledDivider,
  StyledLink,
  Subheader,
} from '../styledComponents';

const PaymentDetail = ({
  dispatchSecureMessagingChangeFormFieldValue,
  isAllowedToScheduleOTP,
  isMurabahaAccount,
  navigation,
  transaction: {
    aHInsuranceAmount,
    feeAmount,
    lifeInsuranceAmount,
    miscellaneousAmount,
    monthlyPayment,
    paymentEscrowAmount,
    paymentInstallmentAmount,
    paymentInterestAmount,
    paymentPrincipalAmount,
    replacementResAmount,
    restrictedEscrowAmount,
    segmentNumber,
    suspenseAmount,
    transactionDate,
    transactionTotalAmount,
    type,
  },
}) => (
  <PaymentDetailContainer>
    <HeaderWrapper>
      <H3>{transactionDate || ''}</H3>
      <H3>{transactionTotalAmount || ''}</H3>
    </HeaderWrapper>
    <Subheader>Payment Breakdown</Subheader>
    <PaymentDetailRows>
      <ConditionalRender
        Component={() => (
          <PaymentDetailRow>
            <P2>Segment Number</P2><P2>{segmentNumber}</P2>
          </PaymentDetailRow>
        )}
        shouldRender={!!Number(segmentNumber)}
      />
      <PaymentDetailRow>
        <P2>Monthly Payment</P2><P2>{monthlyPayment}</P2>
      </PaymentDetailRow>
      {isMurabahaAccount ? (
        <PaymentDetailRowIndented>
          <P2>Installment Amount</P2><P2>{paymentInstallmentAmount}</P2>
        </PaymentDetailRowIndented>
      ) : (
        <Fragment>
          <PaymentDetailRowIndented>
            <P2>Principal</P2><P2>{paymentPrincipalAmount}</P2>
          </PaymentDetailRowIndented>
          <PaymentDetailRowIndented>
            <P2>Interest</P2><P2>{paymentInterestAmount}</P2>
          </PaymentDetailRowIndented>
        </Fragment>
      )}
      <PaymentDetailRow>
        <P2>Escrow</P2><P2>{paymentEscrowAmount}</P2>
      </PaymentDetailRow>
    </PaymentDetailRows>
    <Subheader>Other Fees</Subheader>
    <PaymentDetailRows>
      <PaymentDetailRow>
        <P2>Fees/Late Charge</P2><P2>{feeAmount}</P2>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <P2>A&amp;H Insurance</P2><P2>{aHInsuranceAmount}</P2>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <P2>Life Insurance</P2><P2>{lifeInsuranceAmount}</P2>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <P2>Miscellaneous</P2><P2>{miscellaneousAmount}</P2>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <P2>Suspense</P2><P2>{suspenseAmount}</P2>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <P2>Restricted Escrow</P2><P2>{restrictedEscrowAmount}</P2>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <P2>Replacement Reserve</P2><P2>{replacementResAmount}</P2>
      </PaymentDetailRow>
    </PaymentDetailRows>
    <SecureMessagingBanner
      details={{ date: transactionDate, type }}
      dispatchSecureMessagingChangeFormFieldValue={dispatchSecureMessagingChangeFormFieldValue}
      navigation={navigation}
      screen="detail"
    />
    <StyledDivider />
    <ConditionalRender
      Component={StyledLink}
      propsToPassDown={{
        label: 'Make Another Payment',
        options: { screen: 'SchedulePayment' },
        path: 'OtpPreConfirmation',
        replace: true,
        size: 'medium',
      }}
      shouldRender={isAllowedToScheduleOTP}
    />
  </PaymentDetailContainer>
);

PaymentDetail.propTypes = {
  dispatchSecureMessagingChangeFormFieldValue: T.func.isRequired,
  isAllowedToScheduleOTP: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  transaction: T.object.isRequired,
};

export default PaymentDetail;
