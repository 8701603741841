/**
 *  ButtonFooter
 *
 * @description the next or submit button for the form inputs
 * @prop {bool} disabled determine whether the button is disabled
 * @prop {func} handleBack handles back button press
 * @prop {handleNextClick} handleNextClick handles primary button click
 * @prop {string} label label for the primary button
*/
import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { LargePrimaryButton } from '../../base_ui';

import {
  ButtonsContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
} from './styledComponents';

const ButtonFooter = ({
  disabled,
  handleBack,
  handleNextClick,
  label,
}) => (
  <View>
    <StyledPrimaryDivider />
    <ButtonsContainer>
      <LargePrimaryButton
        disabled={disabled}
        label={label}
        onPress={handleNextClick}
      />
      <StyledBaseInternalLink label="Back" onPressFunc={handleBack} size="medium" />
    </ButtonsContainer>
  </View>
);

ButtonFooter.propTypes = {
  disabled: T.bool.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  label: T.string.isRequired,
};

export default ButtonFooter;
