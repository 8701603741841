import styled from 'styled-components/native';
import { defaultStyles } from 'react-native-picker-select';

export const InputAccessoryContainer = styled.View.attrs(() => ({
  ...defaultStyles.modalViewMiddle,
  justifyContent: 'flex-end',
}))``;

export const StyledText = styled.Text`
  ${defaultStyles.done}
`;
