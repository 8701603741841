import styled from 'styled-components/native';

import { H4, H5, P3 } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';

export const AddressContainer = styled.View`
  margin-top: ${moderateScale(8)}px;
`;

export const AddressLabel = styled(H5)`
  margin-bottom: ${moderateScale(2)}px;
`;

export const CardHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const CardH4 = styled(H4)`
  margin-left: ${moderateScale(8)}px;
`;

export const PendingContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const PendingP3 = styled(P3)`
  margin-left: ${moderateScale(4)}px;
`;

export const StyledH5 = styled(H5)`
  margin-right: ${moderateScale(8)}px;
`;

export const StyledPressable = styled.Pressable`
  align-self: center;
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
  overflow: visible;
`;

export const ToggleContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(10)}px 0 ${moderateScale(2)}px;
  width: ${({ $width }) => $width || '100%'};
`;

export const ToggleLabel = styled(H5)`
  margin-right: ${moderateScale(5)}px;
`;

export const ToggleLabelWrapper = styled.View`
  flex-direction: row;
`;

export const ToggleWrapper = styled.View`
  align-items: center;
  flex-direction: row;
`;
