import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  BaseInternalLink,
  H2,
  H3,
  H4,
  IconButton,
  P2,
  P3,
  P4,
} from '../../base_ui';

export const AddEscrowButtonWrapper = styled.View`
  align-items: center;
  margin: ${moderateScale(16)}px 0 ${moderateScale(32)}px ;
`;

export const AddEscrowContainer = styled.View`
  display: flex;
  flex-direction: column;
  height: ${({ $isDocView }) => $isDocView ? '100%' : 'auto'};
  justify-content: space-between;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const AddEscrowHeaderContainer = styled.View`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const AddEscrowIllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(24)}px;
`;

export const AddEscrowOptionsTextWrapper = styled.View`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

export const AddEscrowOptionsWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-vertical: ${moderateScale(12)}px;
`;

export const AddEscrowSplashContainer = styled.View`
  height: auto;
  width: 100%;
`;

export const AlertWrapper = styled.View`
  margin-top: ${({ $isUpload }) => $isUpload ? moderateScale(20) : moderateScale(28)}px;
`;

export const AnimationContainer = styled.View`
  margin: ${moderateScale(16)}px 0 ${moderateScale(4)}px;
`;

export const BannerWrapper = styled.View`
  margin-top: ${moderateScale(20)}px;
`;

export const CardButtonWrapper = styled.View`
  margin-bottom: ${moderateScale(25)}px;
`;

export const ConfirmationContent = styled.View`
  align-items: center;
  margin-top: ${moderateScale(16)}px;
  text-align: center;
`;

export const DefinitionContainer = styled.View`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-horizontal: ${moderateScale(28)}px;
  margin-vertical: ${moderateScale(16)}px;
`;

export const DefinitionHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
  margin-vertical: ${moderateScale(13)}px;
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const IconWrapper = styled.View`
  height: ${moderateScale(16)}px;
  margin-right: ${moderateScale(8)}px;
  width: ${moderateScale(16)}px;
`;

export const LoanNumberWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(10)}px 0 ${moderateScale(41)}px;
  width: 100%;
`;

export const MultiDocHeaderWrapper = styled.View`
  margin-top: ${moderateScale(24)}px;
`;

export const SplashParagraphContainer = styled.View`
  margin-vertical: ${moderateScale(8)}px;
`;

export const SplashResourcesContainer = styled.View`
  padding-top: ${moderateScale(5)}px;
`;

export const StatusWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(12)}px;
  width: 100%;
`;

export const StyledIconButton = styled(IconButton)`
  display: flex;
  height: 100%;
  justify-content: flex-start;
  margin-right: ${moderateScale(12)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  padding-vertical: ${moderateScale(5)}px;
`;

export const StyledConfirmationH2 = styled(H2)`
  margin-bottom: ${moderateScale(8)}px;
`;

export const StyledDefinitionH3 = styled(H3)`
  margin-left: ${moderateScale(13)}px;
`;

export const StyledDefinitionP3 = styled(P3)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  line-height: ${moderateScale(22)}px;
`;

export const StyledH2 = styled(H2)`
  font-size: ${({ theme: { fontSizes: { f7 } } }) => f7}px;
  margin-bottom: ${({ $isUpload }) => $isUpload ? moderateScale(8) : moderateScale(14)}px;
  text-align: center;
`;

export const StyledH3 = styled(H3)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledH4 = styled(H4)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledP2 = styled(P2)`
  margin-bottom: ${({ $isConfirmation }) => $isConfirmation ?
    moderateScale(8) : moderateScale(14)}px;
  text-align: center;
`;

export const StyledP3 = styled(P3)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
`;

export const StyledP4 = styled(P4)`
  line-height: ${moderateScale(15)}px;
  text-align: left;
`;

export const StyledP4UploadDocDescription = styled(P4)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => `${f4}px`};
  text-align: center;
`;
