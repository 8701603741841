import React, { useRef } from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Login/messages';

import Layout from '../../../constants/Layout';
import iconDictionary from '../../../utils/iconDictionary';
import {
  BaseInternalLink,
  ErrorSuccessBanner,
  LargeProgressButton,
  LoginRegisterTextField,
  PasswordTextField,
} from '../../base_ui';
import BiometricPinButtonGroup from './BiometricPinButtonGroup';
import {
  LinksContainer,
  LoginFormContainer,
  LoginFormProper,
  LoginIllustrationContainer,
  MurabahaStyledH1,
  StyledH1,
} from './styledComponents';
import { paddingTopToAdd } from './helpers';

const LoginIllustration = iconDictionary('login');

const LoginForm = ({
  dispatchBiometricLoginRequest,
  dispatchOpenPinDrawer,
  error,
  formRef,
  handleInputChange,
  handleLoginPress,
  isBiometricLoginEnabled,
  isMurabahaAccount,
  isPinEnabled,
  isPreLoginBannerRendered,
  password,
  status,
  success,
  supportedBiometricAuthenticationTypes,
  username,
}) => {
  const passwordInputRef = useRef(null);
  const { window: { height: windowHeight } } = Layout;
  const isSmallDevice = windowHeight <= 568;
  const paddingTop = paddingTopToAdd(isPreLoginBannerRendered, isSmallDevice);
  const StyledH1ToUse = isMurabahaAccount ? MurabahaStyledH1 : StyledH1;

  return (
    <LoginFormContainer>
      <ErrorSuccessBanner error={error} success={success} />
      <LoginIllustrationContainer $paddingTop={paddingTop}>
        {LoginIllustration}
      </LoginIllustrationContainer>
      <StyledH1ToUse>
        <FormattedMessage id={INTL_IDS.FORM_HEADING} />
      </StyledH1ToUse>
      <LoginFormProper ref={formRef} collapsable={false}>
        <LoginRegisterTextField
          autoComplete="username"
          enterKeyHint="next"
          label="Username"
          onChangeText={(value) => handleInputChange({ field: 'username', value })}
          onSubmitEditing={() => passwordInputRef.current.focus()}
          value={username}
        />
        <PasswordTextField
          ref={passwordInputRef}
          autoComplete="current-password"
          enterKeyHint={username ? 'done' : null}
          onChangeText={(value) => handleInputChange({ field: 'password', value })}
          onSubmitEditing={username ? handleLoginPress : undefined}
          value={password}
        />
        <BiometricPinButtonGroup
          dispatchBiometricLoginRequest={dispatchBiometricLoginRequest}
          dispatchOpenPinDrawer={dispatchOpenPinDrawer}
          isBiometricLoginEnabled={isBiometricLoginEnabled}
          isPinEnabled={isPinEnabled}
          supportedBiometricAuthenticationTypes={supportedBiometricAuthenticationTypes}
        />
        <LargeProgressButton
          label="Login"
          onPress={handleLoginPress}
          status={status}
        />
      </LoginFormProper>
      <LinksContainer>
        <BaseInternalLink
          label="Forgot Username"
          path="ForgotUsername"
          size="small"
        />
        <BaseInternalLink
          label="Forgot Password"
          path="ForgotPassword"
          size="small"
        />
      </LinksContainer>
    </LoginFormContainer>
  );
};

LoginForm.propTypes = {
  dispatchBiometricLoginRequest: T.func.isRequired,
  dispatchOpenPinDrawer: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  formRef: T.shape({ current: T.object }).isRequired,
  handleInputChange: T.func.isRequired,
  handleLoginPress: T.func.isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  isPreLoginBannerRendered: T.bool.isRequired,
  password: T.string.isRequired,
  status: T.string.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
  supportedBiometricAuthenticationTypes: T.object.isRequired,
  username: T.string.isRequired,
};

export default LoginForm;
