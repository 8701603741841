/**
 *
 * MessagesTable
 *
 * @description:
 *  Component to display table with Jira messages
 * @prop {string} emptyHeading
 *   required string to overwrite the default empty heading text
 * @prop {string} emptyMessage
 *   required string to overwrite the default empty message text
 * @prop {string} errorHeading
 *   optional string to overwrite the default error heading text
 * @prop {string} errorMessage
 *   optional string to overwrite the default error message text
 * @prop {array} messages
 *   required array containing the message objects to display
 * @prop {func} onPressTableRow
 *   required function to execute when message row is clicked
 * @prop {string} requestStatusFetchMessages
 *   required status of async fetch messages request
 */

import React from 'react';
import T from 'prop-types';

import AsyncRender from '../AsyncRender';
import { ErrorCard } from './Cards';
import { SkeletonTable, Table } from './Table';

const MessagesTable = ({
  emptyHeading,
  emptyMessage,
  errorHeading,
  errorMessage = '',
  messages,
  onPressTableRow,
  requestStatusFetchMessages,
  ...restProps
}) => (
  <AsyncRender
    Component={Table}
    CustomErrorComponent={ErrorCard}
    error={requestStatusFetchMessages === 'failed'}
    loading={requestStatusFetchMessages === 'loading'}
    LoadingComponent={SkeletonTable}
    loadingComponentProps={{ errorHeading, errorMessage }}
    messages={messages}
    propsToPassDown={{
      emptyHeading,
      emptyMessage,
      messages,
      onPressTableRow,
      scrollLoading: requestStatusFetchMessages !== 'loading',
      ...restProps,
    }}
  />
);

MessagesTable.propTypes = {
  emptyHeading: T.string.isRequired,
  emptyMessage: T.string.isRequired,
  errorHeading: T.string,
  errorMessage: T.string,
  messages: T.array.isRequired,
  onPressTableRow: T.func.isRequired,
  requestStatusFetchMessages: T.string.isRequired,
  tableTextTop: T.string,
};

export default MessagesTable;
