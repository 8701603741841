import React, { Fragment } from 'react';
import T from 'prop-types';

import { interpolate } from '@dmi/shared/utils/globalHelpers';
import { UNAVAILABLE_LOANS_CONTENT } from '@dmi/shared/redux/Main/constants';

import { ConditionalRender } from '../base_ui';
import {
  Heading,
  Link,
  Text,
} from './styledComponents';
import { UNAVAILABLE_LOAN_ILLUSTRATION_DICT } from './constants';

const InactiveRedirect = ({
  customerServicePhoneNumber,
  inactiveRedirectUrl,
  onlineAvailabilityStatus,
}) => {
  const {
    heading,
    illustration,
    linkLabel,
    text,
  } = UNAVAILABLE_LOANS_CONTENT[onlineAvailabilityStatus];
  const Illustration = UNAVAILABLE_LOAN_ILLUSTRATION_DICT[illustration];
  const customerServiceText = interpolate(text, { customerServicePhoneNumber });

  return (
    <Fragment>
      {Illustration}
      <Heading>{heading}</Heading>
      <ConditionalRender
        Component={<Link href={inactiveRedirectUrl} label={linkLabel} />}
        shouldRender={!!inactiveRedirectUrl && !!linkLabel}
      />
      <Text>{customerServiceText}</Text>
    </Fragment>
  );
};

InactiveRedirect.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  inactiveRedirectUrl: T.string,
  onlineAvailabilityStatus: T.string.isRequired,
};

export default InactiveRedirect;
