/* eslint-disable max-len */
import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../../utils/iconDictionary';
import { BannerText, Container, IconContainer } from './styledComponents';

const Icon = iconDictionary('megaphoneS');

const Banner = ({ maxDelayDays }) => (
  <Container>
    <IconContainer>{Icon}</IconContainer>
    <BannerText>
      Payment can be delayed up to {maxDelayDays} day{maxDelayDays === 1 ? '' : 's'} after the due date without incurring fines.
    </BannerText>
  </Container>
);

Banner.propTypes = { maxDelayDays: T.number.isRequired };

export default Banner;
