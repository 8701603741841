/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  Circle,
  G,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function PaymentHistoryActiveMIcon(props) {
  const { colors: { primary } } = useTheme();
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';

  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx="9.2" cy="8.8" fill={primary} r="7.3" />
      <G clipPath={clipPath}>
        <Path d="M9,17.5c-4.7,0-8.5-3.8-8.5-8.5c0-0.6,0.5-1.1,1.1-1.1S2.7,8.3,2.7,9c0,3.5,2.8,6.3,6.3,6.3s6.3-2.8,6.3-6.3c0-3.5-2.8-6.3-6.3-6.3c-2.4,0-4.7,1.4-5.7,3.7C3,6.9,2.3,7.2,1.8,6.9C1.2,6.6,1,6,1.2,5.4c1.4-3,4.4-5,7.7-5c4.7,0,8.5,3.8,8.5,8.5C17.5,13.6,13.7,17.5,9,17.5z" fill="#FFFFFF" />
        <Path d="M9,17.7c-4.8,0-8.8-3.9-8.8-8.8c0-0.8,0.6-1.4,1.4-1.4S2.9,8.2,2.9,9c0,3.3,2.7,6,6,6s6-2.7,6-6s-2.7-6-6-6c-2.3,0-4.5,1.4-5.5,3.5c-0.3,0.7-1.1,1-1.8,0.7C1,6.8,0.7,6,1,5.3c1.4-3.1,4.5-5.1,8-5.1c4.8,0,8.8,3.9,8.8,8.8S13.8,17.7,9,17.7zM1.6,8.1C1.1,8.1,0.7,8.5,0.7,9c0,4.5,3.7,8.3,8.3,8.3c4.6,0,8.3-3.7,8.3-8.3S13.5,0.7,9,0.7c-3.2,0-6.2,1.9-7.5,4.8C1.3,6,1.5,6.5,1.9,6.7c0.4,0.2,0.9,0,1.1-0.4C4.1,3.9,6.4,2.4,9,2.4c3.6,0,6.5,2.9,6.5,6.5s-2.9,6.5-6.5,6.5S2.4,12.6,2.4,9C2.4,8.5,2.1,8.1,1.6,8.1z" fill="#FFFFFF" />
      </G>
      <G clipPath={clipPath}>
        <Path d="M5.1,7.6H1.7C1,7.6,0.5,7.1,0.5,6.5v-4C0.5,2,1,1.5,1.7,1.5S2.8,2,2.8,2.6v2.8h2.3c0.6,0,1.1,0.5,1.1,1.1S5.7,7.6,5.1,7.6z" fill="#FFFFFF" />
        <Path d="M5.1,7.9H1.7c-0.8,0-1.4-0.6-1.4-1.4v-4c0-0.8,0.6-1.4,1.4-1.4S3,1.8,3,2.6v2.6h2.1c0.8,0,1.4,0.6,1.4,1.4S5.9,7.9,5.1,7.9z M1.7,1.7c-0.5,0-0.9,0.4-0.9,0.9v4c0,0.5,0.4,0.9,0.9,0.9h3.4C5.6,7.4,6,7,6,6.5S5.6,5.7,5.1,5.7H2.5V2.6C2.5,2.1,2.1,1.7,1.7,1.7z" fill="#FFFFFF" />
      </G>
      <Path d="M9,17c-4.4,0-8-3.6-8-8c0-0.3,0.3-0.6,0.6-0.6S2.2,8.6,2.2,9c0,3.7,3,6.8,6.8,6.8s6.8-3,6.8-6.8s-3-6.8-6.8-6.8c-2.6,0-5.1,1.5-6.2,3.9C2.7,6.5,2.3,6.6,2,6.5C1.7,6.3,1.5,5.9,1.7,5.6C3,2.8,5.8,0.9,9,0.9c4.4,0,8,3.6,8,8S13.4,17,9,17z" fill={primary} />
      <G clipPath={clipPath}>
        <Path d="M9,12.7V12c-0.8-0.1-1.6-0.4-2.3-1l0.7-0.8c0.5,0.4,1,0.7,1.6,0.8V9.2C8.3,9,7.8,8.8,7.5,8.6C7.2,8.3,7,7.9,7,7.4s0.2-1,0.6-1.3C7.9,5.7,8.4,5.6,9,5.5V5.1h0.6v0.5c0.7,0,1.3,0.3,1.9,0.7l-0.6,0.8c-0.4-0.3-0.8-0.5-1.3-0.5v1.7h0c0.7,0.2,1.2,0.4,1.5,0.7c0.3,0.3,0.5,0.7,0.5,1.2s-0.2,1-0.6,1.3c-0.4,0.3-0.9,0.5-1.5,0.5v0.7H9z M8.4,6.7C8.2,6.9,8.1,7.1,8.1,7.3c0,0.2,0.1,0.4,0.2,0.5S8.7,8,9,8.1V6.5C8.7,6.5,8.5,6.6,8.4,6.7z M10.3,10.8c0.2-0.1,0.3-0.3,0.3-0.5s-0.1-0.4-0.2-0.5C10.2,9.6,10,9.5,9.6,9.4V11C9.9,11,10.1,10.9,10.3,10.8z" fill="#FFFFFF" />
      </G>
      <Path d="M5.1,7.2H1.7C1.3,7.2,1,6.9,1,6.5v-4c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v3.3h2.8c0.3,0,0.6,0.3,0.6,0.6S5.4,7.2,5.1,7.2z" fill={primary} />
      <Rect height={moderateScale(1.6)} width={moderateScale(0.7)} x={moderateScale(2.2)} y={moderateScale(7.7)} />
    </Svg>
  );
}
