import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  BaseInternalLink,
  CardButton,
  H1,
  H3,
  P2,
  P4,
  PrimaryDivider,
} from '../../../base_ui';

// Utilized by HomeCard

export const CardContainer = styled.View`
  padding-top: ${moderateScale(8)}px;
`;

export const CardContentContainer = styled.View`
  align-self: center;
  padding-horizontal: ${({ $center }) => $center ? 0 : `${moderateScale(24)}px`};
  width:  ${({ $center }) => $center ? `${moderateScale(327)}px` : '100%'};
`;

export const CardDivider = styled(PrimaryDivider)`
  background-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  min-width: 100%;
`;

export const CardHeader = styled(H3)`
  padding-left: ${moderateScale(8)}px;
`;

export const CardHeaderContainer = styled.View`
  align-items: center;
  align-self: ${({ $center }) => $center ? 'center' : 'flex-start'};
  flex-direction: row;
  padding-horizontal: ${({ $center }) => $center ? 0 : `${moderateScale(24)}px`};
  padding-vertical: ${moderateScale(13)}px;
  width:  ${({ $center }) => $center ? `${moderateScale(327)}px` : '100%'};
`;

export const CardLink = styled(BaseInternalLink)`
  align-items: center;
  padding: ${moderateScale(14)}px 0;
  text-align: center;
`;

// Utilized by MortgageCardSection

export const EscrowCardContainer = styled.View`
  align-items: center;
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(16)}px;
`;

export const EscrowCardButtonContainer = styled.View`
  justify-content: center;
  margin-bottom: ${moderateScale(18)}px;
  max-width: ${moderateScale(600)}px;
  width: 100%;
`;

export const StyledCardButton = styled(CardButton)`
  width: ${({ $center }) => $center ? `${moderateScale(327)}px` : '100%'};
`;

// Utilized by MortgageHome

export const HeaderContainer = styled(CardHeaderContainer)`
  margin-bottom: ${moderateScale(24)}px;
  padding-vertical: ${moderateScale(16)}px;
`;

export const HomeContainer = styled.View`
  height: 100%;
  width: 100%;
`;

export const HomeH1 = styled(H1)`
  padding: 0 ${moderateScale(24)}px;
`;

// Utilized by MortgageOverview

export const DataBox = styled.View`
  align-items: center;
`;

export const DataContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(19)}px 0 ${moderateScale(24)}px;
`;

export const DataDivider = styled(PrimaryDivider)`
  background-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  height: ${moderateScale(40)}px;
  width: ${moderateScale(1)}px;
`;

export const DataLabel = styled(P4)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const DataValue = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  line-height: ${moderateScale(24)}px;
`;

// Utilized by TaxesAndInsuranceOverview

export const Bullet = styled.View`
  background-color: ${({ $bulletColor, theme: { colors } }) => colors[$bulletColor]};
  border-radius: ${moderateScale(3)}px;
  height: ${moderateScale(6)}px;
  width: ${moderateScale(6)}px;
`;

export const BulletContainer = styled.View`
  padding-right: ${moderateScale(4)}px;
`;

export const DateContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding-top: ${moderateScale(6)}px;
`;

export const Row = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(14)}px 0;
`;

export const RowDivider = styled(PrimaryDivider)`
  background-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  min-width: 100%;
`;

export const TableContainer = styled.View`
  padding-top: ${moderateScale(5)}px;
`;

export const TaxTypeText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;
