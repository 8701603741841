import React, { Fragment } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { getSelectedLoanPropertyState, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';
import { selectIsSecureMessagingTreatmentOn } from '@dmi/shared/redux/SecureMessaging/selectors';

import { StyledScrollView } from '../../components/base_ui';
import { SecondaryHeader } from '../../components/ScreenHeaders';
import SiteMap from '../../components/SiteMap';

const SiteMapScreen = ({
  dispatchSetNestedScreen,
  isMurabahaAccount,
  isSecureMessagingTreatmentOn,
  navigation,
  selectedLoanPropertyState,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Site Map"
    />
    <StyledScrollView>
      <SiteMap
        dispatchSetNestedScreen={dispatchSetNestedScreen}
        isMurabahaAccount={isMurabahaAccount}
        isSecureMessagingTreatmentOn={isSecureMessagingTreatmentOn}
        navigation={navigation}
        selectedLoanPropertyState={selectedLoanPropertyState}
      />
    </StyledScrollView>
  </Fragment>
);

SiteMapScreen.propTypes = {
  dispatchSetNestedScreen: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
  selectedLoanPropertyState: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  isMurabahaAccount: selectIsMurabahaAccount(),
  isSecureMessagingTreatmentOn: selectIsSecureMessagingTreatmentOn(),
  selectedLoanPropertyState: getSelectedLoanPropertyState(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mobile
   */
  dispatchSetNestedScreen: (payload) => dispatch(setNestedScreen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SiteMapScreen);
