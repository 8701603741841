import Disclosures from '../../containers/Disclosures';
import FeeScheduleScreen from '../../containers/FeeSchedule/FeeScheduleScreen';

export default {
  californiaWildfires: {
    Component: Disclosures,
    disclosureName: 'californiawildfires',
    title: 'California Wild Fires',
  },
  disasterAssistance: {
    Component: Disclosures,
    disclosureName: 'disasterassistance',
    title: 'Plan Ahead for Disasters',
  },
  disasterAssistanceStep2: {
    Component: Disclosures,
    disclosureName: 'disasterassistancestep2',
    title: 'Understanding Insurance Claims',
  },
  disasterAssistanceStep3: {
    Component: Disclosures,
    disclosureName: 'disasterassistancestep3',
    title: 'Payment Assistance',
  },
  disasterAssistanceStep4: {
    Component: Disclosures,
    disclosureName: 'disasterassistancestep4',
    title: 'Federal Disaster Assistance',
  },
  ecommsPolicy: {
    Component: Disclosures,
    disclosureName: 'electroniccommunications',
    title: 'Electronic Communications Policy',
  },
  estatementPolicy: {
    Component: Disclosures,
    disclosureName: 'estatement',
    title: 'E-Statement Policy',
  },
  feeSchedule: {
    Component: FeeScheduleScreen,
    feeScheduleType: 'GENERIC',
    title: 'Mortgage Servicing Fee Schedule',
  },
  foreclosureAlternatives: {
    Component: Disclosures,
    disclosureName: 'foreclosurealternatives',
    title: 'Foreclosure Alternatives',
  },
  foreclosureHotlineColorado: {
    Component: Disclosures,
    disclosureName: 'colorado',
    title: 'Colorado State Foreclosure Hotline',
  },
  foreclosureHotlineConnecticut: {
    Component: Disclosures,
    disclosureName: 'connecticut',
    title: 'Connecticut State Foreclosure Hotline',
  },
  foreclosureHotlineIllinois: {
    Component: Disclosures,
    disclosureName: 'illinois',
    title: 'Illinois State Foreclosure Hotline',
  },
  foreclosureHotlineIowa: {
    Component: Disclosures,
    disclosureName: 'iowa',
    title: 'Iowa State Foreclosure Hotline',
  },
  foreclosureHotlineNewYork: {
    Component: Disclosures,
    disclosureName: 'newyork',
    title: 'New York State Foreclosure Hotline',
  },
  foreclosureHotlineWashington: {
    Component: Disclosures,
    disclosureName: 'washington',
    title: 'Washington State Foreclosure Hotline',
  },
  foreclosurePrevention: {
    Component: Disclosures,
    disclosureName: 'foreclosureprevention',
    title: 'Foreclosure Prevention',
  },
  hardshipAssistance: {
    Component: Disclosures,
    disclosureName: 'hardshipassistance',
    title: 'Hardship Assistance',
  },
  hawaiiFeeSchedule: {
    Component: FeeScheduleScreen,
    feeScheduleType: 'HAWAII',
    title: 'Mortgage Servicing Fee Schedule',
  },
  hurricaneHelene: {
    Component: Disclosures,
    disclosureName: 'hurricanehelene',
    title: 'Hurricane Helene Disaster Assistance',
  },
  hurricaneIan: {
    Component: Disclosures,
    disclosureName: 'hurricaneian',
    title: 'Disaster Assistance - Hurricane Ian',
  },
  hurricaneIda: {
    Component: Disclosures,
    disclosureName: 'hurricaneida',
    title: 'Hurricane Ida',
  },
  newYorkDisclosure: {
    Component: Disclosures,
    disclosureName: 'nydisclosure',
    title: 'New York Disclosure',
  },
  newYorkFeeSchedule: {
    Component: FeeScheduleScreen,
    feeScheduleType: 'NEWYORK',
    title: 'Mortgage Servicing Fee Schedule',
  },
  privacyPolicy: {
    Component: Disclosures,
    disclosureName: 'privacypolicy',
    title: 'Privacy Policy',
  },
  termsOfUse: {
    Component: Disclosures,
    disclosureName: 'terms',
    title: 'Terms of Use',
  },
  texasDisclosure: {
    Component: Disclosures,
    disclosureName: 'texasdisclosure',
    title: 'Texas Disclosure',
  },
  texasSevereWeather: {
    Component: Disclosures,
    disclosureName: 'texassevereweather',
    title: 'Texas Severe Weather',
  },
};
