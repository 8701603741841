import React from 'react';
import T from 'prop-types';

import { FormattedMessage } from '../../../../base_ui';
import { ICON_DICT } from './constants';
import { Container, IconWrapper, StyledText } from './styledComponents';

const Row = ({
  iconAlignSelf = 'center',
  isReview = false,
  messageValues,
  noBottomPadding,
  text,
  variant,
}) => (
  <Container isReview={isReview} noBottomPadding={noBottomPadding}>
    <IconWrapper iconAlignSelf={iconAlignSelf} isReview={isReview}>
      {ICON_DICT[variant]}
    </IconWrapper>
    <FormattedMessage
      defaultMessage={text}
      values={messageValues}
      WrapperComponent={StyledText}
    />
  </Container>
);

Row.propTypes = {
  iconAlignSelf: T.string,
  isReview: T.bool,
  messageValues: T.object.isRequired,
  noBottomPadding: T.bool.isRequired,
  text: T.string.isRequired,
  variant: T.oneOf(['info', 'pending', 'warning']).isRequired,
};

export default Row;
