import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../utils/iconDictionary';
import {
  StyledFooterIconContainer,
  StyledFooterIconWrapper,
  StyledIconWrapper,
} from './styledComponents';

const ehlIcon = iconDictionary('ehlIcon');
const fdicIcon = iconDictionary('fdicIcon');
const icons = { ehlIcon, fdicIcon };

export const FooterIcons = ({ footerIconKeys, isLogin }) => (
  <StyledFooterIconContainer $isLogin={isLogin}>
    <StyledFooterIconWrapper>
      {footerIconKeys.map((iconKey) => {
        const IconToRender = icons[iconKey];

        return (
          <StyledIconWrapper key={iconKey}>
            {IconToRender}
          </StyledIconWrapper>
        );
      })}
    </StyledFooterIconWrapper>
  </StyledFooterIconContainer>
);

FooterIcons.propTypes = {
  footerIconKeys: T.arrayOf(T.string).isRequired,
  isLogin: T.bool,
};

export default FooterIcons;
