import React from 'react';

import { P2 } from '../../Paragraphs';
import {
  BannerContainer,
  IllustrationWrapper,
  MessageContainer,
  Title,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const Illustration = iconDictionary('dogLookingOverFence');

const SsoPretransferBanner = () => (
  <BannerContainer>
    <IllustrationWrapper>
      {Illustration}
    </IllustrationWrapper>
    <MessageContainer>
      <Title>Good things are coming soon</Title>
      <P2>We&apos;re improving the login experience for you. Stay tuned for more details.</P2>
    </MessageContainer>
  </BannerContainer>
);

export default SsoPretransferBanner;
