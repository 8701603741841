/* eslint-disable react/no-array-index-key */
import React from 'react';
import T from 'prop-types';

import InlineBlocks from './InlineBlocks';
import { ListItemWrapper } from './styledComponents';

const ListBlocks = ({ handleCloseDrawer, listItems, listType }) => listItems.map((
  { inlineBlocks },
  listItemIndex,
) => {
  const listMarker = listType === 'ordered-list' ? `${listItemIndex + 1}.\u0020` : '\u2022\u0020';
  return (
    <ListItemWrapper key={`list-item-${listItemIndex}`}>
      {listMarker}
      <InlineBlocks
        handleCloseDrawer={handleCloseDrawer}
        inlineBlocks={inlineBlocks}
        listType={listType}
      />
    </ListItemWrapper>
  );
});

ListBlocks.propTypes = {
  handleCloseDrawer: T.func.isRequired,
  listItems: T.arrayOf(
    T.shape({
      inlineBlocks: T.array.isRequired,
      type: T.string.isRequired,
    }).isRequired,
  ).isRequired,
  listType: T.oneOf(['ordered-list', 'unordered-list']).isRequired,
};

export default ListBlocks;
