import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import {
  H2,
  ModalDialog,
  P2,
  SmallPrimaryButton,
  SmallSecondaryButton,
} from '../base_ui';

export const StyledDialogContent = styled.View`
  align-items: center;
  padding: ${moderateScale(24)}px ${moderateScale(20)}px;
`;

export const StyledImage = styled.Image`
  height: ${({ $height }) => moderateScale($height)}px;
  width: ${({ $width }) => moderateScale($width)}px;
`;

export const StyledPrimaryButton = styled(SmallPrimaryButton)`
  margin: ${moderateScale(16)}px 0;
`;

export const StyledSecondaryButton = styled(SmallSecondaryButton)`
  margin: ${moderateScale(16)}px 0;
`;

export const StyledText = styled(P2)`
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const StyledTitle = styled(H2)`
  padding: ${moderateScale(16)}px 0 ${moderateScale(8)}px;
  text-align: center;
`;

/* eslint-disable object-curly-newline */
export const StyledModalDialog = styled(ModalDialog).attrs(() => ({
  modalStyles: {
    height: 'auto',
  },
}))``;
