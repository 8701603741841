import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H2, H4, P2 } from '../../base_ui';

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

/* eslint-disable object-curly-newline */
export const Container = styled.ScrollView.attrs({
  contentContainerStyle: { flexGrow: 1 },
})`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const Content = styled.View`
  align-items: center;
  flex-grow: 1;
  margin: 0 ${moderateScale(24)}px;
`;

export const InfoSectionContainer = styled.View`
  margin: ${({ $isEmailCheckboxDisplayed }) => $isEmailCheckboxDisplayed
    ? `0 ${moderateScale(2)}px 0`
    : `-${moderateScale(20)}px ${moderateScale(5)}px 0`};
`;

export const NoFeeCopy = styled(H4)`
  text-decoration: underline;
`;

export const NoFeeCopyView = styled.View`
  margin: ${moderateScale(8)}px 0 ${moderateScale(24)}px;
  width: 100%;
`;

export const Row = styled.View`
  align-items: center;
  display: flex;
  flex-direction: ${({ $isEdit }) => $isEdit ? 'column' : 'row'};
  justify-content: space-between;
  margin-bottom: ${moderateScale(16)}px;
  width: 100%;
`;

export const RowItem = styled.Text`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  font-weight: 700;
  line-height: ${moderateScale(20)}px;
`;

export const SubRow = styled(Row)`
  justify-content: ${({ $isPrevValue }) => $isPrevValue ? 'flex-start' : 'space-between'};
  margin-bottom: 0;
`;

export const Text = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(24)}px;
  text-align: center;
`;

export const Title = styled(H2)`
  margin-top: ${moderateScale(16)}px;
  text-align: center;
`;
