import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import {
  changeInput,
  changeStep,
  resetInputs,
} from '@dmi/shared/redux/Register/Main/actions';

import Consent from '../../../components/Consent';

const ConsentView = ({
  dispatchChangeInput,
  dispatchChangeStep,
  dispatchResetInputs,
  formInputProps: {
    formValues,
    step,
  },
}) => {
  const handleCancelPress = () => {
    dispatchResetInputs();
  };

  const handleCheckboxPress = (field, value) => dispatchChangeInput({
    field,
    form: 'consent',
    value,
  });

  const handleSubmitPress = () => dispatchChangeStep(step + 1);

  return (
    <Consent
      checkboxValues={formValues}
      error={false}
      handleCancelPress={handleCancelPress}
      handleCheckboxPress={handleCheckboxPress}
      handleSubmitPress={handleSubmitPress}
      isReconsent={false}
      loading={false}
      submitLabel="Accept Terms"
      success={false}
    />
  );
};

ConsentView.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchResetInputs: T.func.isRequired,
  formInputProps: T.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store : Register
  */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchResetInputs: () => dispatch(resetInputs()),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(ConsentView);
