/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function OutlinedHouseFall(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      height={moderateScale(40)}
      viewBox="0 0 96 40"
      width={moderateScale(96)}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <Polyline className="st0" fill="#E7EBEE" points="30.2,38.3 30.2,20 48.5,20 48.5,38.3 " />
      <Rect className="st1" fill="#BAC2CA" height="7.5" width="3.7" x="33.3" y="26.6" />
      <Rect className="st2" fill={svgHighlight} height="11.9" width="4.5" x="40.4" y="26.5" />
      <Polygon className="st1" fill="#BAC2CA" points="45.8,23.4 27.1,23.4 36.4,13.4 55.1,13.4 " />
      <Path
        className="st3"
        d="M45.8,23.7H27.1c-0.1,0-0.2-0.1-0.3-0.2c0-0.1,0-0.2,0-0.3l9.3-10c0.1-0.1,0.1-0.1,0.2-0.1h6.1
	c0.2,0,0.3,0.1,0.3,0.3s-0.1,0.3-0.3,0.3h-6l-8.8,9.5h17.9l8.8-9.5h-10c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h10.6
	c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0,0.2,0,0.3l-9.3,10C46,23.7,45.9,23.7,45.8,23.7z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M63.9,23.7h-1.8c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h1.2l-8.4-9.6c-0.1-0.1-0.1-0.3,0-0.4
	c0.1-0.1,0.3-0.1,0.4,0l8.8,10c0.1,0.1,0.1,0.2,0,0.3C64.1,23.6,64,23.7,63.9,23.7z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M61.2,38.8H30.1c-0.2,0-0.3-0.1-0.3-0.3V23.8c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3v14.5H61V20.5
	c0-0.2,0.1-0.3,0.3-0.3s0.3,0.1,0.3,0.3v18C61.5,38.7,61.4,38.8,61.2,38.8z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M48.6,38.5c-0.1,0-0.2-0.1-0.2-0.2v-4.7c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v4.7
	C48.7,38.4,48.7,38.5,48.6,38.5z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M48.6,31.9c-0.1,0-0.2-0.1-0.2-0.2v-9.3c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v9.3
	C48.7,31.8,48.7,31.9,48.6,31.9z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M45,38.6c-0.1,0-0.2-0.1-0.2-0.2V26.8h-4.1v10.4c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2V26.5
	c0-0.1,0.1-0.2,0.2-0.2H45c0.1,0,0.2,0.1,0.2,0.2v11.8C45.2,38.5,45.1,38.6,45,38.6z"
        fill="#010101"
      />
      <Path
        className="st3"
        d="M37.3,34.4h-4c-0.1,0-0.2-0.1-0.2-0.2v-7.6c0-0.1,0.1-0.2,0.2-0.2h4c0.1,0,0.2,0.1,0.2,0.2v7.6
	C37.5,34.3,37.4,34.4,37.3,34.4z M33.5,34H37v-7.2h-3.6V34z"
        fill="#010101"
      />
      <G>
        <Ellipse className="st0" cx="55.3" cy="21.1" fill="#E7EBEE" rx="1.2" ry="1.7" />
        <Path
          className="st3"
          d="M55.3,23c-0.8,0-1.4-0.8-1.4-1.9s0.6-1.9,1.4-1.9s1.4,0.8,1.4,1.9C56.7,22.1,56.1,23,55.3,23z M55.3,19.7
		c-0.5,0-0.9,0.7-0.9,1.4s0.4,1.4,0.9,1.4s0.9-0.7,0.9-1.4C56.2,20.3,55.8,19.7,55.3,19.7z"
          fill="#010101"
        />
      </G>
      <Path
        className="st3"
        d="M55.3,22.9c-0.7,0-1.3-0.8-1.3-1.8s0.6-1.8,1.3-1.8c0.7,0,1.3,0.8,1.3,1.8S56,22.9,55.3,22.9z M55.3,19.6
	c-0.5,0-1,0.7-1,1.5c0,0.8,0.4,1.5,1,1.5s1-0.7,1-1.5C56.3,20.3,55.8,19.6,55.3,19.6z"
        fill="#010101"
      />
      <Path className="st3" d="M81.1,38.8h-3.2c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h3.2c0.2,0,0.3,0.1,0.3,0.3S81.3,38.8,81.1,38.8z" fill="#010101" />
      <Path className="st3" d="M76.6,38.8H14.3c-0.2,0-0.3-0.1-0.3-0.3s0.1-0.3,0.3-0.3h62.3c0.2,0,0.3,0.1,0.3,0.3S76.7,38.8,76.6,38.8z" fill="#010101" />
      <G>
        <Path className="st4" d="M66.4,19.9c0,0,0.5-0.1,0.9,0.3s0.3,1,0.3,1l-0.1,0.1c0,0-0.6,0.1-1-0.3S66.4,20,66.4,19.9L66.4,19.9z" fill="#E6EBEF" />
        <Path className="st5" d="M67.7,21.2c0,0,0.1-0.6-0.3-1s-0.9-0.3-0.9-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3104" />
        <Path className="st5" d="M67.6,21.3c0,0-0.6,0.1-1-0.3c-0.4-0.4-0.2-1-0.2-1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3104" />
      </G>
      <G>
        <Path
          className="st6"
          d="M78.5,23.8c-0.3-0.2-0.4-0.6-0.2-0.9c0.4-0.6,0.6-1.3,0.6-2c0-1.4-0.7-2.6-1.9-3.4c-0.1-0.1-0.2-0.3-0.2-0.4
		c0.1-0.2,0.1-0.4,0.1-0.6c0-1.3-1.2-2.4-2.7-2.4c-1.5,0-2.7,1.1-2.7,2.4c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0,0.3-0.2,0.4
		c-1.2,0.8-1.9,2-1.9,3.4c0,0.7,0.2,1.4,0.6,2c0.2,0.3,0.1,0.7-0.2,0.9c-1.2,1-2,2.4-2,4c0,3.1,2.8,5.6,6.3,5.6
		c3.5,0,6.3-2.5,6.3-5.6C80.4,26.2,79.7,24.8,78.5,23.8z"
          fill="#D8DFE5"
        />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3936" x1="74" x2="74" y1="33.5" y2="38.4" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3936" x1="74" x2="74" y1="20.9" y2="30.3" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3936" x1="70.8" x2="73.9" y1="25.9" y2="28" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3936" x1="75.7" x2="74" y1="23.5" y2="24.6" />
        <Path className="st7" d="M74.7,31.4c-0.3-0.9-1.1-1.5-2-1.5c-0.5,0-0.9,0.2-1.3,0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3936" />
        <Path className="st7" d="M76.6,31.4c-0.1-0.3-0.4-0.5-0.6-0.6c-0.4-0.2-1-0.2-1.4,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3936" />
        <Path
          className="st7"
          d="M78.5,23.8c-0.3-0.2-0.4-0.6-0.2-0.9c0.4-0.6,0.6-1.3,0.6-2c0-1.4-0.7-2.6-1.9-3.4c-0.1-0.1-0.2-0.3-0.2-0.4
		c0.1-0.2,0.1-0.4,0.1-0.6c0-1.3-1.2-2.4-2.7-2.4c-1.5,0-2.7,1.1-2.7,2.4c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0,0.3-0.2,0.4
		c-1.2,0.8-1.9,2-1.9,3.4c0,0.7,0.2,1.4,0.6,2c0.2,0.3,0.1,0.7-0.2,0.9c-1.2,1-2,2.4-2,4c0,3.1,2.8,5.6,6.3,5.6
		c3.5,0,6.3-2.5,6.3-5.6C80.4,26.2,79.7,24.8,78.5,23.8z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.3936"
        />
      </G>
      <G>
        <Path
          className="st4"
          d="M78.3,36.5c0,0-0.5-0.2-0.6-0.7c-0.1-0.6,0.3-1,0.3-1l0.1,0c0,0,0.5,0.2,0.7,0.8
		C78.8,36.1,78.4,36.4,78.3,36.5L78.3,36.5z"
          fill="#E6EBEF"
        />
        <Path className="st8" d="M78,34.7c0,0-0.4,0.4-0.3,1c0.1,0.6,0.6,0.7,0.6,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
        <Path className="st8" d="M78,34.7c0,0,0.5,0.2,0.7,0.8s-0.3,0.9-0.3,0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
      </G>
      <G>
        <Path
          className="st4"
          d="M67.6,32.6c0,0,0.1,0.6-0.4,1c-0.5,0.4-1.1,0.2-1.1,0.2L66,33.7c0,0,0-0.7,0.5-1.1S67.6,32.5,67.6,32.6
		L67.6,32.6z"
          fill="#E6EBEF"
        />
        <Path className="st8" d="M66.1,33.8c0,0,0.6,0.2,1.1-0.2s0.4-1,0.4-1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
        <Path className="st8" d="M66,33.7c0,0,0-0.7,0.5-1.1c0.5-0.4,1.1-0.2,1.1-0.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
      </G>
      <G>
        <Path
          className="st4"
          d="M78.5,30.1c0,0,0.6-0.4,1.3,0c0.7,0.4,0.7,1.1,0.7,1.1l0,0.1c0,0-0.7,0.3-1.4-0.1S78.5,30.2,78.5,30.1
		L78.5,30.1z"
          fill="#E6EBEF"
        />
        <Path className="st8" d="M80.5,31.3c0,0-0.1-0.8-0.7-1.1c-0.7-0.4-1.3,0-1.3,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
        <Path className="st8" d="M80.5,31.4c0,0-0.7,0.3-1.4-0.1c-0.7-0.4-0.7-1.1-0.7-1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
      </G>
      <G>
        <Path
          className="st9"
          d="M71.5,36.5c0,0-0.7,0.1-1.1-0.5c-0.4-0.6-0.2-1.3-0.2-1.3l0.1-0.1c0,0,0.7,0,1.1,0.6
		C71.9,35.9,71.6,36.5,71.5,36.5L71.5,36.5z"
          fill="#FFFFFF"
        />
        <Path className="st8" d="M70.3,34.8c0,0-0.2,0.7,0.2,1.3c0.4,0.6,1.1,0.5,1.1,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
        <Path className="st8" d="M70.3,34.7c0,0,0.7,0,1.1,0.6c0.4,0.6,0.1,1.2,0.1,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
      </G>
      <G>
        <Path className="st9" d="M78,20c0,0,0-0.5,0.5-0.8s1,0,1,0l0,0.1c0,0-0.1,0.6-0.6,0.9C78.5,20.3,78.1,20.1,78,20L78,20z" fill="#FFFFFF" />
        <Path className="st8" d="M79.6,19.2c0,0-0.5-0.3-1,0C78,19.5,78,20,78,20" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
        <Path className="st8" d="M79.6,19.2c0,0-0.1,0.6-0.6,0.9c-0.5,0.3-1,0-1,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2886" />
      </G>
      <G>
        <Path
          className="st9"
          d="M61.8,35.2c-0.8-1.5-2.6-1.2-2.6-1.2l-0.3,0l0,0l-0.1,0l-0.1,0l0,0l-0.3,0c0,0-1.8-0.2-2.6,1.2
		c-0.8,1.5,0.4,2.7,1.3,3c0,0,0.5,0.2,1.4,0.2h0.1H59h0.1c0.9,0,1.4-0.2,1.4-0.2C61.4,37.9,62.5,36.6,61.8,35.2z"
          fill="#FFFFFF"
        />
        <Path
          className="st1"
          d="M61.8,35.2c-0.5-0.9-1.3-1.2-2-1.2c0.4,0.2,0.7,0.5,1,1c0.8,1.5-0.4,2.7-1.3,3c0,0-0.5,0.2-1.4,0.2h-0.1h-0.7
		h-0.1c-0.3,0-0.5,0-0.7,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0.5,0.2,1.4,0.2h0.1H59h0.1c0.9,0,1.4-0.2,1.4-0.2
		C61.4,37.9,62.5,36.6,61.8,35.2z"
          fill="#BAC2CA"
        />
        <Path
          className="st10"
          d="M61.8,35.2c-0.8-1.5-2.6-1.2-2.6-1.2l-0.3,0l0,0l-0.1,0l-0.1,0l0,0l-0.3,0c0,0-1.8-0.2-2.6,1.2
		c-0.8,1.5,0.4,2.7,1.3,3c0,0,0.5,0.2,1.4,0.2h0.1H59h0.1c0.9,0,1.4-0.2,1.4-0.2C61.4,37.9,62.5,36.6,61.8,35.2z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.466"
        />
        <Path className="st11" d="M58.8,34c0,0,1.5,0.3,1.5,2.2s-1.6,2.3-1.6,2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3106" />
        <Path className="st11" d="M58.4,34c0,0-1.5,0.3-1.5,2.2s1.6,2.3,1.6,2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3106" />
        <Path className="st10" d="M58.9,33.1c0,0-0.3,0.1-0.4,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.466" />
      </G>
      <G>
        <Path
          className="st9"
          d="M64.8,36.4c-0.5-0.9-1.6-0.8-1.6-0.8l-0.2,0l0,0l-0.1,0l-0.1,0l0,0l-0.2,0c0,0-1.1-0.1-1.6,0.8
		c-0.5,0.9,0.2,1.7,0.8,1.9c0,0,0.3,0.1,0.8,0.1h0.1h0.4h0.1c0.6,0,0.8-0.1,0.8-0.1C64.6,38.1,65.3,37.3,64.8,36.4z"
          fill="#FFFFFF"
        />
        <Path
          className="st1"
          d="M64.8,36.4c-0.3-0.6-0.8-0.7-1.2-0.8c0.2,0.1,0.5,0.3,0.6,0.6c0.5,0.9-0.2,1.7-0.8,1.9c0,0-0.3,0.1-0.8,0.1
		h-0.1h-0.4H62c-0.2,0-0.3,0-0.4,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.3,0.1,0.8,0.1h0.1h0.4h0.1c0.6,0,0.8-0.1,0.8-0.1
		C64.6,38.1,65.3,37.3,64.8,36.4z"
          fill="#BAC2CA"
        />
        <Path
          className="st12"
          d="M64.8,36.4c-0.5-0.9-1.6-0.8-1.6-0.8l-0.2,0l0,0l-0.1,0l-0.1,0l0,0l-0.2,0c0,0-1.1-0.1-1.6,0.8
		c-0.5,0.9,0.2,1.7,0.8,1.9c0,0,0.3,0.1,0.8,0.1h0.1h0.4h0.1c0.6,0,0.8-0.1,0.8-0.1C64.6,38.1,65.3,37.3,64.8,36.4z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.286"
        />
        <Path className="st13" d="M63,35.7c0,0,0.9,0.2,0.9,1.3s-1,1.4-1,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1907" />
        <Path className="st13" d="M62.7,35.7c0,0-0.9,0.2-0.9,1.3s1,1.4,1,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1907" />
        <Path className="st12" d="M63,35.2c0,0-0.2,0.1-0.2,0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.286" />
      </G>
      <G>
        <Path
          className="st14"
          d="M33,22.9c0,0-0.1-0.7,0.5-1.2s1.4-0.2,1.4-0.2l0.1,0.1c0,0,0,0.8-0.6,1.3C33.7,23.3,33,23,33,22.9L33,22.9z"
          fill="#DCE2E7"
        />
        <Path className="st15" d="M34.9,21.5c0,0-0.8-0.2-1.4,0.2C32.9,22.2,33,23,33,23" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
        <Path className="st15" d="M35,21.6c0,0,0,0.8-0.6,1.3C33.7,23.4,33,23,33,23" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
      </G>
      <G>
        <Path
          className="st4"
          d="M30.8,22.4c0,0,0.2,0.6,0.8,0.8c0.7,0.2,1.2-0.3,1.2-0.3l0-0.1c0,0-0.3-0.7-0.9-0.8S30.8,22.4,30.8,22.4
		L30.8,22.4z"
          fill="#E6EBEF"
        />
        <Path className="st15" d="M32.9,22.9c0,0-0.5,0.5-1.2,0.3c-0.7-0.2-0.8-0.8-0.8-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
        <Path className="st15" d="M32.9,22.8c0,0-0.3-0.7-0.9-0.8c-0.7-0.2-1.1,0.3-1.1,0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
      </G>
      <G>
        <Path
          className="st4"
          d="M40.1,22.5c0,0,0.2,0.6,0.8,0.8c0.7,0.2,1.2-0.3,1.2-0.3l0-0.1c0,0-0.3-0.7-0.9-0.8
		C40.6,21.9,40.2,22.4,40.1,22.5L40.1,22.5z"
          fill="#E6EBEF"
        />
        <Path className="st15" d="M42.2,22.9c0,0-0.5,0.5-1.2,0.3c-0.7-0.2-0.8-0.8-0.8-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
        <Path className="st15" d="M42.2,22.9c0,0-0.3-0.7-0.9-0.8c-0.7-0.2-1.1,0.3-1.1,0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
      </G>
      <G>
        <Path
          className="st4"
          d="M40.7,21.7c0,0-0.6-0.2-1.1,0.2c-0.5,0.5-0.4,1.2-0.4,1.2l0.1,0.1c0,0,0.7,0.2,1.2-0.3
		C40.9,22.4,40.8,21.7,40.7,21.7L40.7,21.7z"
          fill="#E6EBEF"
        />
        <Path className="st15" d="M39.1,23.1c0,0-0.1-0.7,0.4-1.2c0.5-0.5,1.1-0.2,1.1-0.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
        <Path className="st15" d="M39.2,23.2c0,0,0.7,0.2,1.2-0.3c0.5-0.5,0.4-1.1,0.4-1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
      </G>
      <G>
        <Path
          className="st4"
          d="M35.2,22.6c0,0,0.2,0.5,0.9,0.6s1-0.4,1-0.4l0-0.1c0,0-0.3-0.5-0.9-0.6C35.6,22,35.2,22.5,35.2,22.6L35.2,22.6
		z"
          fill="#E6EBEF"
        />
        <Path className="st15" d="M37.1,22.7c0,0-0.4,0.5-1,0.4c-0.6,0-0.9-0.6-0.9-0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
        <Path className="st15" d="M37.1,22.6c0,0-0.3-0.5-0.9-0.6c-0.6,0-0.9,0.4-0.9,0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
      </G>
      <G>
        <Path
          className="st4"
          d="M44.3,22.6c0,0,0.2,0.5,0.7,0.5c0.5,0,0.9-0.4,0.9-0.4l0-0.1c0,0-0.3-0.5-0.8-0.5S44.3,22.6,44.3,22.6
		L44.3,22.6z"
          fill="#E6EBEF"
        />
        <Path className="st16" d="M45.9,22.8c0,0-0.3,0.4-0.9,0.4c-0.5,0-0.7-0.5-0.7-0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1965" />
        <Path className="st16" d="M45.9,22.7c0,0-0.3-0.5-0.8-0.5c-0.5,0-0.8,0.4-0.8,0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1965" />
      </G>
      <G>
        <Path
          className="st4"
          d="M38.8,23.3c0,0,0.3-0.6-0.1-1.2c-0.4-0.6-1.2-0.6-1.2-0.6l-0.1,0.1c0,0-0.3,0.7,0.2,1.3
		C38,23.4,38.7,23.3,38.8,23.3L38.8,23.3z"
          fill="#E6EBEF"
        />
        <Path className="st15" d="M37.5,21.5c0,0,0.7,0,1.2,0.6c0.4,0.6,0.1,1.2,0.1,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
        <Path className="st15" d="M37.4,21.5c0,0-0.3,0.7,0.2,1.3s1.1,0.5,1.1,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2301" />
      </G>
      <Path
        className="st17"
        d="M25.2,25.8L25.2,25.8c-2-12.3-4.9-11.8-4.9-11.8l0,0l0,0c0,0-2.7-0.6-4.8,11.8l0,0c-0.1,0.4-0.1,0.8-0.1,1.2
	c0,3.3,2.2,6,4.9,6s4.9-2.7,4.9-6C25.3,26.5,25.3,26.2,25.2,25.8z"
        fill="#EFF3F6"
      />
      <Path
        className="st6"
        d="M25.3,26.9c0-0.4,0-0.8-0.1-1.2l0,0c-2-12.3-4.9-11.8-4.9-11.8l0,0l0,0c0,0-0.2,0-0.5,0.1
	c0.7,1.5,3,6.5,3.3,11.2c0.2,4.3-1.2,6.5-2.8,7.6c0,0,0,0,0,0C23.1,33,25.3,30.3,25.3,26.9z"
        fill="#D8DFE5"
      />
      <Path
        className="st18"
        d="M25.2,25.8L25.2,25.8c-2-12.3-4.9-11.8-4.9-11.8l0,0l0,0c0,0-2.7-0.6-4.8,11.8l0,0c-0.1,0.4-0.1,0.8-0.1,1.2
	c0,3.3,2.2,6,4.9,6s4.9-2.7,4.9-6C25.3,26.5,25.3,26.2,25.2,25.8z"
        fill="none"
        stroke="#000000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.2806"
      />
      <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2806" x1="20.3" x2="20.3" y1="33" y2="38.6" />
      <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2806" x1="20.3" x2="20.3" y1="23.3" y2="28.8" />
      <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2806" x1="18.5" x2="20.3" y1="24.5" y2="25.8" />
      <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2806" x1="22.2" x2="20.4" y1="26" y2="27.3" />
      <Path className="st19" d="M18.4,18.1c-0.5,1.2-1,2.9-1.3,4.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.187" />
      <Path className="st19" d="M19.2,16.1c0,0-0.2,0.5-0.6,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.187" />
      <G>
        <Path className="st4" d="M26.1,28.6c0,0-0.4,0.3-0.9,0s-0.5-0.9-0.5-0.9l0-0.1c0,0,0.5-0.2,1,0.1S26.2,28.6,26.1,28.6L26.1,28.6z" fill="#E6EBEF" />
        <Path className="st20" d="M24.7,27.7c0,0,0,0.6,0.5,0.9s0.9,0,0.9,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M24.7,27.7c0,0,0.5-0.2,1,0.1s0.4,0.8,0.4,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <G>
        <Path
          className="st4"
          d="M12.7,30.7c0,0,0.1,0.5-0.3,0.9c-0.4,0.3-1,0.2-1,0.2l0-0.1c0,0,0-0.6,0.4-0.9C12.1,30.5,12.6,30.7,12.7,30.7
		L12.7,30.7z"
          fill="#E6EBEF"
        />
        <Path className="st20" d="M11.3,31.7c0,0,0.5,0.2,1-0.2c0.4-0.3,0.3-0.9,0.3-0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M11.3,31.7c0,0,0-0.6,0.4-0.9c0.4-0.3,0.9-0.1,0.9-0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <G>
        <Path
          className="st14"
          d="M17.1,35.9c0,0-0.7,0.1-1.1-0.5c-0.4-0.6-0.2-1.2-0.2-1.2l0.1-0.1c0,0,0.7,0,1.1,0.6S17.2,35.9,17.1,35.9
		L17.1,35.9z"
          fill="#DCE2E7"
        />
        <Path className="st20" d="M15.9,34.2c0,0-0.2,0.7,0.2,1.2s1.1,0.5,1.1,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M15.9,34.1c0,0,0.7,0,1.1,0.6c0.4,0.6,0.1,1.2,0.1,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <G>
        <Path className="st9" d="M14.6,26c0,0,0-0.7,0.6-1s1.3-0.1,1.3-0.1l0,0.1c0,0,0,0.7-0.6,1.1C15.2,26.4,14.6,26,14.6,26L14.6,26z" fill="#FFFFFF" />
        <Path className="st20" d="M16.4,24.8c0,0-0.7-0.3-1.3,0.1c-0.6,0.4-0.6,1-0.6,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M16.5,24.9c0,0,0,0.7-0.6,1.1s-1.2,0.1-1.2,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <G>
        <Path
          className="st4"
          d="M23.8,36.1c0,0,0.4,0.4,1,0.3c0.6-0.2,0.8-0.8,0.8-0.8l0-0.1c0,0-0.5-0.4-1.1-0.2C23.9,35.5,23.8,36,23.8,36.1
		L23.8,36.1z"
          fill="#E6EBEF"
        />
        <Path className="st20" d="M25.7,35.6c0,0-0.2,0.6-0.8,0.8c-0.6,0.2-1-0.3-1-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M25.6,35.5c0,0-0.5-0.4-1.1-0.2c-0.6,0.2-0.7,0.7-0.7,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <G>
        <Path className="st4" d="M26.7,31.1c0,0,0,0.5,0.5,0.8c0.5,0.2,1,0,1,0l0-0.1c0,0-0.1-0.6-0.6-0.8S26.7,31,26.7,31.1L26.7,31.1z" fill="#E6EBEF" />
        <Path className="st20" d="M28.2,31.8c0,0-0.5,0.3-1,0c-0.5-0.2-0.5-0.8-0.5-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M28.2,31.7c0,0-0.1-0.6-0.6-0.8c-0.5-0.2-0.9,0.1-0.9,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <G>
        <Path
          className="st4"
          d="M25,20c0,0-0.6-0.2-1.1,0.2c-0.5,0.4-0.5,1.1-0.5,1.1l0.1,0.1c0,0,0.6,0.2,1.2-0.3C25.2,20.7,25.1,20.1,25,20
		L25,20z"
          fill="#E6EBEF"
        />
        <Path className="st20" d="M23.5,21.3c0,0,0-0.7,0.5-1.1c0.5-0.4,1.1-0.2,1.1-0.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
        <Path className="st20" d="M23.6,21.4c0,0,0.6,0.2,1.2-0.3s0.4-1,0.4-1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2058" />
      </G>
      <Path className="st18" d="M18.5,28.8c0,0,2.1-0.5,3.1,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2806" />
    </Svg>
  );
}
