import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import moment from 'moment';

import iconDictionary from '../../../utils/iconDictionary';
import { P2 } from '../../base_ui';
import {
  ArrowDownIconWrapper,
  CalendarIconWrapper,
  StyledDatePicker,
} from './styledComponents';

const ArrowDownIcon = iconDictionary('arrowDown');
const CalendarIcon = iconDictionary('calendarSm');

const DatePicker = ({
  dateSelectorIsDisabled,
  dispatchUpdateSelectedPayOffReason,
  isPaydown,
  navigation,
  selectedDate,
  selectedPayOffReason,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const dateText = selectedDate ? moment(selectedDate).format('LL') : 'Select Date';

  return (
    <StyledDatePicker
      $isPaydown={isPaydown}
      $pressed={isPressed}
      disabled={dateSelectorIsDisabled}
      onPress={() => {
        // Store local state reason value in reducer before switching to date screen
        dispatchUpdateSelectedPayOffReason(selectedPayOffReason);
        navigation.navigate('SelectDateScreen');
      }}
      onPressIn={() => setIsPressed(true)}
      onPressOut={() => setIsPressed(false)}
    >
      <Fragment>
        <CalendarIconWrapper>{CalendarIcon}</CalendarIconWrapper>
        <P2>{dateText}</P2>
        <ArrowDownIconWrapper>{ArrowDownIcon}</ArrowDownIconWrapper>
      </Fragment>
    </StyledDatePicker>
  );
};

DatePicker.propTypes = {
  dateSelectorIsDisabled: T.bool.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  isPaydown: T.bool.isRequired,
  navigation: T.object.isRequired,
  selectedDate: T.string.isRequired,
  selectedPayOffReason: T.string.isRequired,
};

export default DatePicker;
