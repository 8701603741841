import {
  AmortizeScreen,
  CalculatorsCalendarScreen,
  CalculatorsProceedScreen,
} from '../../../containers/Calculators';

export default {
  Amortize: { component: AmortizeScreen },
  CalculatorsCalendar: { component: CalculatorsCalendarScreen },
  CalculatorsProceed: { component: CalculatorsProceedScreen },
};
