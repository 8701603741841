import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';

import AsyncRender from '../../../AsyncRender';
import { SecondaryHeader } from '../../../ScreenHeaders';
import Success from './Success';

const Confirmation = ({
  dispatchResetStatusState,
  error,
  fetchErrorCopy,
  navigation,
  ...restProps
}) => {
  useEffect(() => () => {
    dispatchResetStatusState();
  }, [dispatchResetStatusState]);

  return (
    <Fragment>
      <SecondaryHeader
        handleDone={error ? undefined : () => navigation.navigate('Settings')}
        title={error ? 'Error' : 'Confirmation'}
      />
      <AsyncRender
        Component={Success}
        error={!!error}
        errorComponentProps={{
          ...fetchErrorCopy,
          link: { label: 'Return to Settings', path: 'Settings' },
        }}
        loading={false}
        propsToPassDown={restProps}
      />
    </Fragment>
  );
};

Confirmation.propTypes = {
  dispatchResetStatusState: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  fetchErrorCopy: T.object.isRequired,
  navigation: T.object.isRequired,
};

export default Confirmation;
