/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavDocumentsActiveMIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M15.08 3.89L12.22 1.16C12.1337 1.06759 12.0161 1.01057 11.89 1H4.14003C4.07746 0.999986 4.01551 1.01247 3.95783 1.03671C3.90014 1.06095 3.84788 1.09647 3.8041 1.14118C3.76033 1.18588 3.72592 1.23888 3.70289 1.29707C3.67987 1.35525 3.6687 1.41744 3.67003 1.48V16.3C3.67003 16.4247 3.71955 16.5442 3.80769 16.6323C3.89583 16.7205 4.01538 16.77 4.14003 16.77H14.75C14.8756 16.77 14.9962 16.7208 15.086 16.633C15.1757 16.5451 15.2274 16.4256 15.23 16.3V4.24C15.2271 4.10834 15.1733 3.98292 15.08 3.89ZM11.81 12.47H6.89003C6.75742 12.47 6.63024 12.4173 6.53647 12.3236C6.44271 12.2298 6.39003 12.1026 6.39003 11.97C6.39003 11.8374 6.44271 11.7102 6.53647 11.6164C6.63024 11.5227 6.75742 11.47 6.89003 11.47H11.81C11.9426 11.47 12.0698 11.5227 12.1636 11.6164C12.2573 11.7102 12.31 11.8374 12.31 11.97C12.31 12.1026 12.2573 12.2298 12.1636 12.3236C12.0698 12.4173 11.9426 12.47 11.81 12.47ZM11.81 9.82H6.89003C6.76444 9.82003 6.64384 9.77083 6.5541 9.68297C6.46437 9.5951 6.41264 9.47556 6.41003 9.35C6.41258 9.2235 6.46398 9.10289 6.55345 9.01342C6.64292 8.92395 6.76353 8.87256 6.89003 8.87H11.81C11.9356 8.87262 12.0551 8.92434 12.143 9.01408C12.2309 9.10381 12.2801 9.22441 12.28 9.35C12.28 9.41172 12.2679 9.47284 12.2443 9.52986C12.2206 9.58688 12.186 9.6387 12.1424 9.68234C12.0987 9.72598 12.0469 9.7606 11.9899 9.78422C11.9329 9.80784 11.8717 9.82 11.81 9.82ZM11.81 7.17H6.89003C6.76444 7.17003 6.64384 7.12083 6.5541 7.03297C6.46437 6.9451 6.41264 6.82556 6.41003 6.7C6.41258 6.5735 6.46398 6.45289 6.55345 6.36342C6.64292 6.27395 6.76353 6.22256 6.89003 6.22H11.81C11.9356 6.22262 12.0551 6.27434 12.143 6.36408C12.2309 6.45381 12.2801 6.57441 12.28 6.7C12.28 6.82465 12.2305 6.9442 12.1424 7.03234C12.0542 7.12048 11.9347 7.17 11.81 7.17ZM12 4V2.24L13.83 4H12Z" fill={primary} />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="18" width="18" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
