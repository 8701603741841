import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';

import {
  getMainClientInfo,
  getSelectedLoanUnmaskedLoanNumber,
  selectIsMurabahaAccount,
} from '@dmi/shared/redux/Main/selectors';
import { resetRequestStatusState } from '@dmi/shared/redux/Mortgage/actions';
import { INSURANCE_UPLOAD_DICT } from '@dmi/shared/redux/Mortgage/constants';
import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';
import { selectRequestStatus } from '@dmi/shared/redux/Mortgage/selectors';

import { ConditionalRender } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { ConfirmationComponent } from '../../../components/Mortgage/InsuranceUpload';
import ConfirmationErrorScene from '../../../components/DocUpload/ConfirmationErrorScene';
import { StyledView } from '../styledComponents';

const ConfirmationScreen = ({
  customerServicePhoneNumber,
  dispatchResetRequestStatusState,
  isMurabahaAccount,
  navigation,
  selectedLoanNumber,
  uploadDocumentRequestStatus,
}) => {
  useEffect(() => () => {
    dispatchResetRequestStatusState({ state: 'uploadDocument' });
  }, [dispatchResetRequestStatusState]);

  const { formatMessage } = useIntl();
  const { errorText, header } = INSURANCE_UPLOAD_DICT.confirmation;
  const route = isMurabahaAccount ? 'Murabaha' : 'Mortgage';

  return (
    <StyledView>
      <ConditionalRender
        Component={(
          <Fragment>
            <SecondaryHeader
              handleDone={() => navigation.navigate(route)}
              title={header}
            />
            <ConfirmationComponent loanNumber={selectedLoanNumber} />
          </Fragment>
        )}
        FallbackComponent={(
          <ConfirmationErrorScene
            customerServicePhoneNumber={customerServicePhoneNumber}
            label={formatMessage({ id: INTL_IDS.MORTGAGE_SHARED_BUTTON_LABEL_RETURNTOMORTGAGE })}
            onPress={() => navigation.navigate(route)}
            route={route}
            text={errorText}
          />
        )}
        shouldRender={uploadDocumentRequestStatus === 'succeeded'}
      />
    </StyledView>
  );
};

ConfirmationScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  selectedLoanNumber: T.string.isRequired,
  uploadDocumentRequestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  selectedLoanNumber: getSelectedLoanUnmaskedLoanNumber(),
  /**
   * Store: Mortgage
   */
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Mortgage
   */
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConfirmationScreen);
