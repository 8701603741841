import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import {
  CLAIMS_PROCESS_KEY,
  CLAIMS_PROCESS_MENU,
  RESOURCES_DRAWER_DETAILS,
} from '@dmi/shared/redux/LossDraft/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import ResourceRowItem from '../ResourceRowItem';
import { Heading } from './styledComponents';
import { SecondaryHeader } from '../../../ScreenHeaders';

const ArrowIcon = iconDictionary('arrowRightM');

const ClaimsProcessMenu = ({ handleOpenDrawer, navigation }) => {
  const { resourceTitle } = RESOURCES_DRAWER_DETAILS[CLAIMS_PROCESS_KEY];

  return (
    <View>
      <SecondaryHeader
        handleBack={navigation.goBack}
        title={resourceTitle}
      />
      {CLAIMS_PROCESS_MENU.map(({ menu, title }) => (
        <View key={title}>
          <Heading>{title}</Heading>
          {menu.map(({ rowLabel }) => (
            <ResourceRowItem
              key={rowLabel}
              aria-label={rowLabel}
              handleOpenDrawer={handleOpenDrawer}
              handlePress={() => handleOpenDrawer(rowLabel)}
              IconToUse={ArrowIcon}
              rowLabel={rowLabel}
            />
          ))}
        </View>
      ))}
    </View>
  );
};

ClaimsProcessMenu.propTypes = {
  handleOpenDrawer: T.func.isRequired,
  navigation: T.object.isRequired,
};

export default ClaimsProcessMenu;
