import styled from 'styled-components/native';

import BaseButton from './BaseButton';
import BaseProgressButton from './BaseProgressButton';
import {
  disabledButtonStyles,
  disabledTitleStyles,
  largeButtonDimensions,
  largeTitleStyles,
  mediumButtonDimensions,
  mediumTitleStyles,
  primaryButtonStyles,
  primaryPressedButtonStyles,
  primaryPressedTitleStyles,
  primaryTitleStyles,
  secondaryButtonStyles,
  secondaryPressedButtonStyles,
  secondaryTitleStyles,
  smallButtonDimensions,
  smallTitleStyles,
  xSmallButtonDimensions,
  xSmallTitleStyles,
} from './helpers';
import { moderateScale } from '../../../utils/scaleHelpers';

// Primary Buttons
export const LargePrimaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  theme,
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    largeTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${largeButtonDimensions}
`;

export const MediumPrimaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    mediumTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => mediumButtonDimensions(theme)}
`;

export const SmallPrimaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    smallTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => smallButtonDimensions(theme)}
`;

export const XSmallPrimaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    xSmallTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => xSmallButtonDimensions(theme)}
`;

// Secondary Buttons
export const LargeSecondaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  titleStyle,
}) => ({
  pressedStyle: secondaryPressedButtonStyles(theme),
  titleStyle: [
    !disabled ? secondaryTitleStyles(theme) : disabledTitleStyles(theme),
    largeTitleStyles(theme),
    titleStyle,
  ],
}))`
  ${({ disabled, theme }) => !disabled ? secondaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => largeButtonDimensions(theme)}
`;

export const MediumSecondaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  titleStyle,
}) => ({
  pressedStyle: secondaryPressedButtonStyles(theme),
  titleStyle: [
    !disabled ? secondaryTitleStyles(theme) : disabledTitleStyles(theme),
    mediumTitleStyles(theme),
    titleStyle,
  ],
}))`
  ${({ disabled, theme }) => !disabled ? secondaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => mediumButtonDimensions(theme)}
`;

export const SmallSecondaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  titleStyle,
}) => ({
  pressedStyle: secondaryPressedButtonStyles(theme),
  titleStyle: [
    !disabled ? secondaryTitleStyles(theme) : disabledTitleStyles(theme),
    smallTitleStyles(theme),
    titleStyle,
  ],
}))`
  ${({ disabled, theme }) => !disabled ? secondaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => smallButtonDimensions(theme)}
`;

export const XSmallSecondaryButton = styled(BaseButton).attrs(({
  disabled,
  theme,
  titleStyle,
}) => ({
  pressedStyle: secondaryPressedButtonStyles(theme),
  titleStyle: [
    !disabled ? secondaryTitleStyles(theme) : disabledTitleStyles(theme),
    xSmallTitleStyles(theme),
    titleStyle,
  ],
}))`
  ${({ disabled, theme }) => !disabled ? secondaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => xSmallButtonDimensions(theme)}
`;

// Tertiary Buttons
export const TertiaryButton = styled(BaseButton).attrs(({
  theme,
  theme: {
    colors: { primary },
    fontSizes: { f3 },
  },
  titleStyle,
}) => ({
  pressedStyle: secondaryPressedButtonStyles(theme),
  titleStyle: [
    {
      color: primary,
      fontSize: f3,
    },
    titleStyle,
  ],
}))`
  align-self: flex-start;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-radius: 4px;
  border-width: 1px;
  height: ${moderateScale(24)}px;
  padding: 0 16px;
`;

export const SmallTertiaryButton = styled(TertiaryButton).attrs(({
  theme: { fontSizes: f2 },
  titleStyle,
}) => ({
  titleStyle: [
    { fontSize: f2 },
    titleStyle,
  ],
}))`
  height: ${moderateScale(19)}px;
  padding: 0 14px;
`;

// Borderless Buttons
export const BorderlessButton = styled(BaseButton).attrs(({
  disabled,
  isFooterButton,
  isPrimary,
  theme,
  theme: {
    colors: {
      accentRed,
      linkText,
      primaryCarbon,
    },
    fontFamilies: { secondaryFont, secondaryFontBold },
    fontSizes: { f4 },
  },
  titleStyle,
}) => {
  const conditionalStyles = isPrimary ?
    { color: linkText, fontFamily: secondaryFont } :
    { color: accentRed, fontFamily: secondaryFontBold };
  const disabledStyle = { color: primaryCarbon, opacity: 0.5 };
  return {
    pressedStyle: secondaryPressedButtonStyles(theme, isFooterButton),
    titleStyle: [
      {
        ...conditionalStyles,
        fontSize: f4,
        paddingVertical: 10,
      },
      disabled ? disabledStyle : {},
      titleStyle,
    ],
  };
})``;

// Progress Buttons
export const LargeProgressButton = styled(BaseProgressButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    largeTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => largeButtonDimensions(theme)}
`;

export const MediumProgressButton = styled(BaseProgressButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    mediumTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => mediumButtonDimensions(theme)}
`;

export const SmallProgressButton = styled(BaseProgressButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    smallTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => smallButtonDimensions(theme)}
`;

export const XSmallProgressButton = styled(BaseProgressButton).attrs(({
  disabled,
  theme,
  theme: { colors: { primaryButtonHover } },
  titleStyle,
}) => ({
  pressedStyle: primaryPressedButtonStyles(theme),
  pressedTitleStyle: primaryPressedTitleStyles(theme),
  titleStyle: [
    !disabled ? primaryTitleStyles(theme) : disabledTitleStyles(theme),
    xSmallTitleStyles(theme),
    titleStyle,
  ],
  underlayColor: primaryButtonHover,
}))`
  ${({ disabled, theme }) => !disabled ? primaryButtonStyles(theme) : disabledButtonStyles(theme)}
  ${({ theme }) => xSmallButtonDimensions(theme)}
`;
