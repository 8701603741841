/* eslint-disable max-len */
import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function TouchID() {
  const { colors: { primary } } = useTheme();

  return (
    <Svg
      color={primary}
      enableBackground="new 0 0 24 24"
      height={moderateScale(24)}
      viewBox="0 0 24 24"
      width={moderateScale(24)}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
    >
      <G>
        <Path
          d="M0.875,14.911c-0.182,0-0.338-0.13-0.39-0.312c-0.208-0.858-0.286-1.742-0.286-2.6
          c0-2.521,0.78-4.913,2.261-6.94c0.13-0.182,0.364-0.208,0.546-0.078c0.182,0.13,0.208,0.364,0.078,0.546
          c-1.377,1.897-2.105,4.133-2.105,6.499c0,0.832,0.104,1.638,0.286,2.444c0.052,0.208-0.078,0.416-0.286,0.468L0.875,14.911z"
          fill="currentColor"
        />
        <Path
          d="M3.786,4.488c-0.104,0-0.182-0.026-0.26-0.104c-0.156-0.156-0.156-0.39,0-0.546
          c0.416-0.416,0.858-0.832,1.326-1.17c0.182-0.13,0.416-0.104,0.546,0.078s0.104,0.416-0.078,0.546
          C4.877,3.63,4.462,3.994,4.098,4.384C3.968,4.462,3.89,4.488,3.786,4.488z"
          fill="currentColor"
        />
        <Path
          d="M23.411,12.39c-0.208,0-0.39-0.182-0.39-0.39c0-6.083-4.938-11.021-11.021-11.021
          c-1.793,0-3.587,0.442-5.147,1.3c-0.182,0.104-0.416,0.026-0.52-0.156C6.229,1.94,6.307,1.707,6.489,1.603
          C8.179,0.719,10.077,0.225,12,0.225c6.498,0,11.802,5.303,11.802,11.801C23.802,12.208,23.619,12.39,23.411,12.39z"
          fill="currentColor"
        />
        <Path
          d="M20.474,20.006h-0.077c-0.208-0.025-0.364-0.233-0.312-0.441c0.806-4.497,0.65-7.694,0.624-8.058
          c-0.13-2.444-1.3-4.705-3.197-6.265c-0.156-0.13-0.182-0.39-0.052-0.546c0.13-0.156,0.39-0.182,0.546-0.052
          c2.054,1.69,3.327,4.159,3.483,6.811c0.025,0.364,0.182,3.665-0.65,8.24C20.812,19.876,20.656,20.006,20.474,20.006z"
          fill="currentColor"
        />
        <Path
          d="M2.252,18.265H2.148c-0.208-0.052-0.338-0.26-0.286-0.468c0.754-2.964,0.65-5.199,0.65-5.225
          C2.2,7.347,6.229,2.824,11.454,2.538c1.404-0.078,2.781,0.13,4.081,0.65c0.208,0.078,0.286,0.312,0.208,0.494
          c-0.078,0.208-0.312,0.286-0.494,0.208c-1.195-0.468-2.443-0.676-3.743-0.598c-4.809,0.286-8.5,4.419-8.214,9.228
          c0,0.026,0.104,2.366-0.676,5.458C2.564,18.16,2.408,18.265,2.252,18.265z"
          fill="currentColor"
        />
        <Path
          d="M17.667,22.294h-0.104c-0.208-0.053-0.338-0.26-0.286-0.468c0.391-1.508,0.677-3.067,0.884-4.627
          c0.026-0.208,0.208-0.364,0.442-0.338c0.208,0.025,0.364,0.233,0.338,0.441c-0.208,1.56-0.52,3.146-0.91,4.679
          C18.005,22.164,17.822,22.294,17.667,22.294z"
          fill="currentColor"
        />
        <Path
          d="M18.811,14.625L18.811,14.625c-0.234,0-0.39-0.182-0.39-0.415c0.077-1.508,0.025-2.47,0.025-2.574
          c-0.208-3.562-3.275-6.265-6.81-6.083c-0.676,0.052-1.352,0.182-1.976,0.442C9.453,6.073,9.245,5.969,9.167,5.788
          C9.089,5.58,9.193,5.372,9.375,5.293C10.077,5.008,10.83,4.852,11.61,4.8c3.952-0.234,7.382,2.807,7.617,6.784
          c0,0.182,0.052,1.144-0.026,2.678C19.2,14.47,19.019,14.625,18.811,14.625z"
          fill="currentColor"
        />
        <Path
          d="M3.942,20.474l-0.13-0.025c-0.208-0.078-0.312-0.286-0.26-0.494c1.352-4.133,1.196-7.512,1.196-7.538
          c-0.13-2.105,0.676-4.185,2.209-5.641c0.156-0.156,0.39-0.156,0.546,0c0.156,0.156,0.156,0.39,0,0.546
          c-1.377,1.299-2.08,3.145-1.976,5.042c0,0.026,0.182,3.536-1.248,7.825C4.28,20.37,4.124,20.474,3.942,20.474z"
          fill="currentColor"
        />
        <Path
          d="M14.989,23.386l-0.13-0.026c-0.208-0.052-0.312-0.286-0.26-0.494c1.17-3.743,1.741-7.798,1.56-11.125
          c-0.052-0.728-0.26-1.43-0.676-2.028c-0.13-0.182-0.078-0.416,0.104-0.546c0.183-0.13,0.416-0.078,0.546,0.104
          c0.468,0.728,0.729,1.56,0.78,2.417c0.208,3.406-0.39,7.564-1.586,11.412C15.301,23.281,15.146,23.386,14.989,23.386z"
          fill="currentColor"
        />
        <Path
          d="M5.839,21.981l-0.13-0.025c-0.208-0.078-0.312-0.286-0.234-0.494c1.768-4.835,1.585-9.02,1.585-9.202
          c-0.13-2.729,1.976-5.042,4.679-5.199c0.753-0.052,1.508,0.078,2.209,0.39c0.208,0.078,0.286,0.312,0.208,0.52
          c-0.078,0.208-0.312,0.286-0.52,0.208c-0.598-0.26-1.222-0.364-1.846-0.312c-2.288,0.13-4.055,2.08-3.951,4.367
          c0.026,0.468,0.156,4.601-1.611,9.514C6.151,21.878,5.996,21.981,5.839,21.981z"
          fill="currentColor"
        />
        <Path
          d="M12.39,23.775l-0.13-0.026c-0.208-0.078-0.312-0.285-0.234-0.493c2.131-6.187,1.872-11.308,1.872-11.36
          c-0.052-1.04-0.936-1.819-2.001-1.768c-1.04,0.052-1.846,0.962-1.768,2.001c0,0.104,0.026,0.702,0,1.638
          c0,0.208-0.182,0.39-0.416,0.364c-0.208,0-0.39-0.183-0.364-0.416c0.026-0.91,0-1.456,0-1.56c-0.078-1.456,1.04-2.729,2.521-2.807
          c1.481-0.078,2.729,1.04,2.808,2.521c0,0.052,0.285,5.303-1.898,11.671C12.702,23.671,12.546,23.775,12.39,23.775z"
          fill="currentColor"
        />
        <Path
          d="M7.919,23.021l-0.13-0.026c-0.208-0.077-0.312-0.312-0.234-0.493c0.753-2.002,1.273-4.134,1.56-6.343
          c0.026-0.208,0.234-0.364,0.442-0.338c0.208,0.025,0.364,0.233,0.338,0.441c-0.286,2.262-0.832,4.445-1.612,6.524
          C8.205,22.943,8.075,23.021,7.919,23.021z"
          fill="currentColor"
        />
        <Path
          d="M10.077,23.619l-0.13-0.025c-0.208-0.078-0.312-0.286-0.234-0.494c0.182-0.468,0.338-0.988,0.494-1.481
          c0.052-0.208,0.286-0.312,0.494-0.261c0.208,0.053,0.312,0.286,0.26,0.494c-0.156,0.521-0.338,1.014-0.52,1.508
          C10.388,23.516,10.232,23.619,10.077,23.619z"
          fill="currentColor"
        />
        <Path
          d="M11.22,19.746h-0.078c-0.208-0.052-0.338-0.26-0.286-0.468c0.936-4.133,0.78-7.2,0.78-7.252
          c0-0.208,0.156-0.39,0.364-0.416c0.208,0,0.39,0.156,0.416,0.364c0,0.13,0.182,3.223-0.78,7.46
          C11.558,19.616,11.402,19.746,11.22,19.746z"
          fill="currentColor"
        />
      </G>
    </Svg>
  );
}
