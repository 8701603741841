/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavLogoutDefaultMIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M14.5 1.88989C14.5 1.81156 14.4844 1.73402 14.4542 1.66178C14.4239 1.58953 14.3795 1.52404 14.3237 1.46912C14.2678 1.4142 14.2016 1.37096 14.1289 1.3419C14.0561 1.31285 13.9783 1.29856 13.9 1.29989H3.76001C3.68216 1.29854 3.60483 1.31287 3.53263 1.34204C3.46044 1.37122 3.39486 1.41462 3.3398 1.46968C3.28474 1.52474 3.24133 1.59032 3.21216 1.66252C3.18299 1.73471 3.16866 1.81204 3.17001 1.88989V15.8899C3.16866 15.9677 3.18299 16.0451 3.21216 16.1173C3.24133 16.1895 3.28474 16.255 3.3398 16.3101C3.39486 16.3652 3.46044 16.4086 3.53263 16.4377C3.60483 16.4669 3.68216 16.4812 3.76001 16.4799H13.9C13.9783 16.4812 14.0561 16.4669 14.1289 16.4379C14.2016 16.4088 14.2678 16.3656 14.3237 16.3107C14.3795 16.2557 14.4239 16.1903 14.4542 16.118C14.4844 16.0458 14.5 15.9682 14.5 15.8899V12.2099C14.4812 12.0654 14.4105 11.9326 14.301 11.8364C14.1915 11.7402 14.0508 11.6872 13.905 11.6872C13.7593 11.6872 13.6185 11.7402 13.509 11.8364C13.3995 11.9326 13.3288 12.0654 13.31 12.2099V15.3399H4.31001V2.47989H13.31V5.47989C13.3288 5.62442 13.3995 5.75717 13.509 5.85336C13.6185 5.94955 13.7593 6.00259 13.905 6.00259C14.0508 6.00259 14.1915 5.94955 14.301 5.85336C14.4105 5.75717 14.4812 5.62442 14.5 5.47989V1.88989Z" fill="black" />
      <Path d="M10.2802 12.4C10.3923 12.5099 10.5431 12.5715 10.7002 12.5715C10.8572 12.5715 11.008 12.5099 11.1202 12.4L14.2302 9.28996C14.2887 9.23441 14.3356 9.16781 14.3682 9.09399C14.4009 9.02018 14.4185 8.94063 14.4202 8.85996C14.4206 8.78281 14.4048 8.70644 14.3738 8.63581C14.3428 8.56517 14.2972 8.50185 14.2402 8.44996L11.1202 5.30996C11.0088 5.19857 10.8577 5.13599 10.7002 5.13599C10.5426 5.13599 10.3915 5.19857 10.2802 5.30996C10.1688 5.42135 10.1062 5.57243 10.1062 5.72996C10.1062 5.88749 10.1688 6.03857 10.2802 6.14996L12.4002 8.26996H6.00016C5.84368 8.26996 5.69361 8.33212 5.58296 8.44276C5.47232 8.55341 5.41016 8.70348 5.41016 8.85996C5.41016 9.01643 5.47232 9.1665 5.58296 9.27715C5.69361 9.3878 5.84368 9.44996 6.00016 9.44996H12.4202L10.3102 11.56C10.1961 11.6681 10.1291 11.8167 10.1235 11.9738C10.1179 12.1309 10.1741 12.2839 10.2802 12.4Z" fill="black" />
    </Svg>
  );
}
