import styled from 'styled-components/native';

import { H4, P3 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const SignInContainer = styled.View`
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const SignInContentWrapper = styled.View`
  flex: 1;
  width: 100%;
`;

export const StyledH4 = styled(H4)`
  flex: 1;
`;

export const StyledP3 = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  margin-right: ${moderateScale(10)}px;
`;

export const ToggleContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(32)}px 0 0;
  width: 100%;
`;

export const ToggleWrapper = styled.View`
  align-items: center;
  flex-direction: row;
`;
