import React, { Fragment } from 'react';
import T from 'prop-types';

import Fees from './Fees';
import Disclaimer from './Disclaimer';

const FeeSchedule = ({
  asOfDate,
  customerServicePhoneNumber,
  disclaimer,
  feeSchedule,
}) => (
  <Fragment>
    <Fees feeSchedule={feeSchedule} />
    <Disclaimer
      asOfDate={asOfDate}
      customerServicePhoneNumber={customerServicePhoneNumber}
      disclaimer={disclaimer}
    />
  </Fragment>
);

FeeSchedule.propTypes = {
  asOfDate: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  disclaimer: T.array.isRequired,
  feeSchedule: T.arrayOf(T.shape({
    feeAmount: T.string.isRequired,
    feeDescription: T.string.isRequired,
    feeDetailedDescription: T.string.isRequired,
  })).isRequired,
};

export default FeeSchedule;
