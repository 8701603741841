import styled from 'styled-components/native';

import { H3, P3 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const DetailsTitle = styled(P3)`
  flex-shrink: 1;
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  margin-right: ${moderateScale(5)}px;
  max-width: ${moderateScale(178)}px;
`;

export const DetailsValue = styled(P3)`
  flex-shrink: 1;
  text-align: right;
`;

export const MortgageDetailsPressableRow = styled.Pressable`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(11)}px 0;
`;

export const MortgageDetailsRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(11)}px 0;
`;

export const MortgageDetailsRows = styled.View`
  padding: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const MortgageHeadingRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${moderateScale(12)}px;
  padding-top: ${moderateScale(20)}px;
`;

export const IconWrapper = styled.View`
  pointer-events: none;
`;

export const StyledSubheader = styled(H3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  flex-grow: 1;
  flex-shrink: 1;
`;

export const StyledSubheaderValue = styled(H3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;
