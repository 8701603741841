import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import { PrimaryDivider } from '../../components/base_ui';

export const AttachmentView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
  height: 100%;
`;

export const FilterWrapper = styled.View`
  height: ${moderateScale(27)}px;
  margin-top: ${moderateScale(8)}px;
  width: 100%;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
  width: 100%;
`;

export const StyledView = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
  height: 100%;
`;
