import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  BorderlessButton,
  PaymentTextField,
  PrimaryDivider,
} from '../../../base_ui';

export const BannerContainer = styled.View`
  margin-bottom: ${moderateScale(23)}px;
`;

export const ButtonContainer = styled.View`
  align-items: center;
  margin: ${moderateScale(66)}px 0 ${moderateScale(14)}px;
`;

export const CalculatorContainer = styled.View`
  margin-bottom: ${moderateScale(28)}px;
  flex-grow: 1;
`;

export const ContentContainer = styled.View`
  flex-grow: 1;
  justify-content: flex-start;
  padding: ${moderateScale(16)}px ${moderateScale(24)}px;
`;

export const DownloadButton = styled(BorderlessButton).attrs(({ titleStyle }) => ({
  titleStyle: [
    titleStyle,
    {
      paddingLeft: moderateScale(4),
      paddingVertical: 0,
    },
  ],
}))``;

export const FlexContainer = styled.View`
  flex-grow: 1;
`;

export const InputContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${moderateScale(16)}px;
`;

export const InputLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const ResetButton = styled(BorderlessButton)`
  margin-top: ${moderateScale(8)}px;
`;

export const SectionHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
`;

export const SelectArrowWrapper = styled.View`
  margin-left: auto;
  margin-right: ${moderateScale(13)}px;
`;

export const SelectDateError = styled.Text`
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  margin-top: ${-moderateScale(8)}px;
  width: 56%;
`;

export const SelectDatePicker = styled.Pressable`
  align-items: center;
  border: 1px solid ${({
    $error,
    theme: { colors: { accentRed, primaryGray } },
  }) => $error ? accentRed : primaryGray};
  display: flex;
  flex-direction: row;
  height: ${moderateScale(32)}px;
  width:${moderateScale(149)}px;
`;

export const SelectIconWrapper = styled.View`
  margin-left: ${moderateScale(11)}px;
  margin-right: ${moderateScale(8)}px;
`;

export const StyledPaymentTextField = styled(PaymentTextField).attrs(({
  theme: {
    fontFamilies: { secondaryFont, secondaryFontBold },
    fontSizes: { f3 },
  },
}) => ({
  containerStyle: {
    alignSelf: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    width: '100%',
  },
  errorStyle: {
    flexBasis: '100%',
    fontFamily: secondaryFont,
    fontSize: f3,
    lineHeight: moderateScale(13),
    paddingRight: '44%',
  },
  inputContainerStyle: {
    flex: 1,
    height: moderateScale(32),
    maxWidth: moderateScale(149),
    minWidth: moderateScale(149),
  },
  labelStyle: {
    flex: 1,
    fontFamily: secondaryFontBold,
    fontSize: f3,
    lineHeight: moderateScale(18),
  },
}))``;

export const TermLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const TermRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-vertical: ${moderateScale(4)}px;
`;

export const TermsContainer = styled.View`
  margin-bottom: ${moderateScale(28)}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  margin-vertical: ${moderateScale(8)}px;
  max-width: 100%;
  width: 100%;
`;
