import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { updateAmount as autopayUpdateAmount } from '@dmi/shared/redux/Payments/Autopay/actions';
import { updateAmount as otpUpdateAmount } from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import {
  makeSelectAmortize,
  selectAutopayAmount,
  selectFormattedFormValues,
  selectIsAutopaySetUp,
  selectOtpAmount,
  selectProceedSummaryBarProps,
} from '@dmi/shared/redux/Calculators/Amortize/selectors';

import { StyledView } from './styledComponents';
import ProceedView from '../../components/Calculators/Proceed';

const ProceedScreen = ({
  autopayAmount,
  dispatchAutopayUpdateAmount,
  dispatchOtpUpdateAmount,
  formattedFormValues,
  isAutopaySetUp,
  navigation,
  otpAmount,
  selectedAdjustmentFilter,
  summaryBarProps,
}) => {
  const handleClickButton = () => {
    switch (selectedAdjustmentFilter) {
      case 'payLumpSum':
        dispatchOtpUpdateAmount(otpAmount);
        navigation.navigate('OtpPreConfirmation', {
          params: { isCalculatorFlow: true },
          screen: 'SchedulePayment',
        });
        break;
      case 'payMoreMonthly':
        dispatchAutopayUpdateAmount(autopayAmount);
        navigation.navigate('AutopayPreConfirmation');
        break;
      default:
    }
  };

  return (
    <StyledView>
      <ProceedView
        formattedFormValues={formattedFormValues}
        handleClickButton={handleClickButton}
        isAutopaySetUp={isAutopaySetUp}
        navigation={navigation}
        selectedAdjustmentFilter={selectedAdjustmentFilter}
        summaryBarProps={summaryBarProps}
      />
    </StyledView>
  );
};

ProceedScreen.propTypes = {
  autopayAmount: T.object.isRequired,
  dispatchAutopayUpdateAmount: T.func.isRequired,
  dispatchOtpUpdateAmount: T.func.isRequired,
  formattedFormValues: T.object.isRequired,
  isAutopaySetUp: T.bool.isRequired,
  navigation: T.object.isRequired,
  otpAmount: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
  summaryBarProps: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Amortize
   */
  autopayAmount: selectAutopayAmount(),
  formattedFormValues: selectFormattedFormValues(),
  isAutopaySetUp: selectIsAutopaySetUp(),
  otpAmount: selectOtpAmount(),
  selectedAdjustmentFilter: makeSelectAmortize('selectedAdjustmentFilter'),
  summaryBarProps: selectProceedSummaryBarProps(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchAutopayUpdateAmount: (payload) => dispatch(autopayUpdateAmount(payload)),
  dispatchOtpUpdateAmount: (payload) => dispatch(otpUpdateAmount(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ProceedScreen);
