import React from 'react';

import { HELOC_DRAW_PROPS_DICTIONARY } from '@dmi/shared/redux/Mortgage/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import {
  Header,
  Subheader,
} from '../styledComponents';
import {
  Content,
  ErrorContainer,
  IllustrationWrapper,
  StyledInternalLink,
} from './styledComponents';

const errorStorm = iconDictionary('errorStorm');

const HelocError = () => {
  const {
    label,
    mobilePath,
    subHeading,
    textString,
  } = HELOC_DRAW_PROPS_DICTIONARY.error;

  return (
    <ErrorContainer>
      <Content>
        <IllustrationWrapper>{errorStorm}</IllustrationWrapper>
        <Header>{subHeading}</Header>
        <Subheader>{textString}</Subheader>
      </Content>
      <StyledInternalLink
        label={label}
        path={mobilePath}
      />
    </ErrorContainer>
  );
};

export default HelocError;
