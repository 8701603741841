import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H4, P2, P3 } from '../../../base_ui';

export const CardContainer = styled.View`
  padding: ${moderateScale(24)}px ${moderateScale(24)}px ${moderateScale(16)}px;
`;

export const CardContentContainer = styled.View`
  margin-top: ${moderateScale(14)}px;
  padding-left: ${moderateScale(2)}px;
`;

export const CardHeader = styled(H4)`
  margin-left: ${moderateScale(8)}px;
`;

export const CardHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const IconContainer = styled.View`
  opacity: ${({ $isOpaque }) => $isOpaque ? 0.3 : 1};
`;

export const LinkContainer = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(44)}px;
  justify-content: space-between;
`;

export const LinkDetails = styled(P3)`
  margin: 0 ${moderateScale(8)}px 0 ${moderateScale(6)}px;
`;

export const LinkDetailsContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const LinkLabel = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  line-height: ${moderateScale(18)}px;
`;
