import styled from 'styled-components/native';

import {
  BaseInternalLink,
  H2,
  H4,
  P2,
} from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const CardContainer = styled.View`
  align-items: center;
  border-radius: ${moderateScale(8)}px;
  border: ${({ theme: { colors: { primaryGray } } }) => (
    `${moderateScale(1)}px solid ${primaryGray};`
  )};
  height: ${moderateScale(250)}px;
  margin-bottom: ${moderateScale(16)}px;
  width: ${moderateScale(250)}px;
`;

export const CardContent = styled.View`
  flex: 1;
  height: ${moderateScale(212)}px;
  justify-content: center;
  text-align: center;
`;

export const CardIllustration = styled.View`
  align-items: center;
  padding: ${moderateScale(20)}px ${moderateScale(16)}px 0;
`;

export const CardFooter = styled.View`
  align-items: center;
  height: ${moderateScale(37)}px;
  justify-content: center;
  width: 100%;
`;

export const CardHeading = styled(H4)`
  font-weight: 700;
  margin: ${moderateScale(10)}px 0 ${moderateScale(6)}px;
  text-align: center;
`;

export const CardText = styled(P2)`
  padding: 0 ${moderateScale(16)}px ${moderateScale(24)}px;
  text-align: center;
`;

export const Divider = styled.View`
  background-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  height: ${moderateScale(1)}px;
  width: 100%;
`;

export const HeaderText = styled(H2)`
  margin-bottom: ${moderateScale(32)}px;
`;

export const StyledMediumInternalLink = styled(BaseInternalLink)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const WhatsNewSection = styled.View`
  margin: ${moderateScale(32)}px ${moderateScale(24)}px 0;
`;

export const CardGroup = styled.View`
  align-items: center;
`;
