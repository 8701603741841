import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender, H5 } from '../../../base_ui';
import ErrorValue from './ErrorValue';
import RowButtonValue from './RowButtonValue';
import { ArrowWrapper, Row, ValueIconWrapper } from './styledComponents';

const ArrowIcon = iconDictionary('arrowRightM');

const RowButton = ({
  handlePress,
  label,
  noPaymentSource,
  value,
}) => (
  <Pressable onPress={handlePress}>
    <Row>
      <H5>{label}</H5>
      <ValueIconWrapper>
        <ConditionalRender
          Component={RowButtonValue}
          FallbackComponent={ErrorValue}
          propsToPassDown={{ value: noPaymentSource ? 'Add Payment Source' : value }}
          shouldRender={!noPaymentSource}
        />
        <ArrowWrapper>{ArrowIcon}</ArrowWrapper>
      </ValueIconWrapper>
    </Row>
  </Pressable>
);

RowButton.propTypes = {
  handlePress: T.func.isRequired,
  label: T.string.isRequired,
  noPaymentSource: T.bool,
  value: T.string.isRequired,
};

export default RowButton;
