import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const useSharedDropdownStyle = (options = {}) => {
  const { isPlaceholderVisible, isStateDropdown } = options;
  const {
    colors: { primaryCarbon, primaryGray },
    fontFamilies: { secondaryFont },
    fontSizes: { f5, f8 },
  } = useTheme();

  const sharedStyle = {
    color: isPlaceholderVisible ? primaryGray : primaryCarbon,
    fontFamily: secondaryFont,
    fontSize: f5,
    height: moderateScale(30),
    paddingLeft: moderateScale(8),
    paddingVertical: 0,
  };

  const inputAndroidContainer = { width: isStateDropdown ? moderateScale(60) : moderateScale(222) };
  const viewContainer = { width: isStateDropdown ? moderateScale(60) : moderateScale(222) };

  return {
    inputAndroid: { ...sharedStyle },
    inputAndroidContainer,
    inputIOS: { ...sharedStyle },
    inputWeb: { lineHeight: f8, ...sharedStyle },
    viewContainer,
  };
};
