import React from 'react';

import iconDictionary from '../../../../utils/iconDictionary';
import { Container, Heading } from './styledComponents';

const Icon = iconDictionary('megaphoneL');

const EmptySet = () => (
  <Container>
    {Icon}
    <Heading>There are no new notifications at the moment.</Heading>
  </Container>
);

export default EmptySet;
