/**
 * BaseTextField
 * @description: BaseUI Text Field input component.
 *
 * Component props: {
 *     disabled (bool) If true, the text field will be disabled (gets passed through)
 *     errorMessage (string) If present, error text appears above text field and sets error
 *                           state to true
 *     label (string [required]) Text that appears to the left of the text field
 *     placeholder (string) Hint displayed in the text field before the user enters a value
 *                         (gets passed through)
 *     value (string [required]) Text field's value (gets passed through)
 * }
 *
 * API Usage: Any other props are passed through to the BaseInput (in addition to those props
 * noted with 'gets passed through' above)
 */

import React, { forwardRef } from 'react';
import T from 'prop-types';

import ConditionalRender from '../../ConditionalRender';
import {
  Container,
  ErrorLabel,
  LabelInputContainer,
  StyledLabel,
  StyledTextField,
  TextFieldWrapper,
} from './styledComponents';

const BaseTextField = forwardRef(({
  disabled = false,
  errorMessage,
  isAutoWidth = false,
  label,
  placeholder = '',
  value,
  width,
  ...restProps
}, ref) => (
  <Container>
    <ConditionalRender
      Component={<ErrorLabel>{errorMessage}</ErrorLabel>}
      shouldRender={!!errorMessage}
    />
    <LabelInputContainer $isAutoWidth={isAutoWidth}>
      <ConditionalRender
        Component={<StyledLabel>{label}</StyledLabel>}
        shouldRender={!!label}
      />
      <TextFieldWrapper $width={width}>
        <StyledTextField
          ref={ref}
          aria-label={errorMessage || label}
          disabled={disabled}
          error={!!errorMessage}
          placeholder={placeholder}
          renderErrorMessage={false}
          value={value}
          variant="outline"
          {...restProps}
        />
      </TextFieldWrapper>
    </LabelInputContainer>
  </Container>
));

BaseTextField.propTypes = {
  disabled: T.bool,
  errorMessage: T.string,
  isAutoWidth: T.bool,
  label: T.string.isRequired,
  placeholder: T.string,
  value: T.string.isRequired,
  width: T.number,
};

export default BaseTextField;
