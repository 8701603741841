/*
 * Forgot Username Form Input
 */

import React, { Fragment } from 'react';
import T from 'prop-types';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import { BaseInternalLink, LoginRegisterTextField } from '../../base_ui';
import {
  ChangeEmailContainer,
  RegisterInputContainer,
  StyledLargeProgressButton,
  StyledLoginLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const ForgotUsernameFormInput = ({
  dispatchChangeInput,
  dispatchForgotUsername,
  formErrors,
  formValues,
  handleNextClick,
  status,
}) => (
  <Fragment>
    <RegisterInputContainer>
      <TextFieldWrapper>
        <LoginRegisterTextField
          error={formErrors.email}
          keyboardType="email-address"
          label="Email Address"
          onChangeText={(newValue) => dispatchChangeInput({
            field: 'email',
            form: 'forgotUsername',
            value: newValue,
          })}
          onSubmitEditing={() => handleNextClick({
            field: 'email',
            form: 'forgotUsername',
            formValues,
            handleSubmitStep: () => dispatchForgotUsername(formValues.email),
          })}
          returnKeyType="done"
          value={formValues.email}
        />
      </TextFieldWrapper>
      <ChangeEmailContainer>
        <BaseInternalLink
          label="I want to update my email address."
          path="ChangeEmail"
        />
      </ChangeEmailContainer>
    </RegisterInputContainer>
    <StyledPrimaryDivider />
    <StyledLargeProgressButton
      disabled={!formValues.email}
      label="Next Step"
      onPress={() => handleNextClick({
        field: 'email',
        form: 'forgotUsername',
        formValues: trimFormValues(formValues),
        handleSubmitStep: () => dispatchForgotUsername(formValues.email.trim()),
      })}
      status={status.forgotUsernameInitiate}
    />
    <StyledLoginLink
      label="Back"
      path="Login"
      size="medium"
    />
  </Fragment>
);

ForgotUsernameFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchForgotUsername: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleNextClick: T.func.isRequired,
  status: T.object.isRequired,
};

export default ForgotUsernameFormInput;
