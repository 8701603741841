import React from 'react';
import T from 'prop-types';
import { Calendar } from 'react-native-calendars';
import { useTheme } from 'styled-components/native';

import iconDictionary from '../../../utils/iconDictionary';
import { calendarTheme } from './constants';
import Day from './Day';
import Header from './Header';

const arrowLeftIcon = iconDictionary('arrowLeftCarbonS');
const arrowRightIcon = iconDictionary('arrowRightM');

const BaseCalendar = ({
  availableDates = {},
  disabledByDefault = false,
  dueDate = '',
  handleChangeMonth,
  handleLongPress,
  handlePress,
  isCurrentMonth,
  isFinalMonth = false,
  maxDate,
  minDate,
  selected,
}) => {
  const theme = useTheme();
  const calendarThemeObject = calendarTheme(theme);

  const renderArrow = (direction) => {
    if (isCurrentMonth && direction === 'left') return undefined;
    if (isFinalMonth && direction === 'right') return undefined;
    return direction === 'left' ? arrowLeftIcon : arrowRightIcon;
  };

  const markingObj = {
    ...availableDates,
    [dueDate]: { disabled: !availableDates[dueDate], marked: true },
    [selected]: { disabled: false, marked: selected === dueDate, selected: true },
  };

  return (
    <Calendar
      customHeader={Header}
      dayComponent={Day}
      disableArrowLeft={isCurrentMonth}
      disableArrowRight={isFinalMonth}
      disabledByDefault={disabledByDefault}
      displayLoadingIndicator={false}
      firstDay={7}
      hideExtraDays
      markedDates={markingObj}
      maxDate={maxDate}
      minDate={minDate}
      monthFormat={'MMM yyyy'}
      onDayLongPress={handleLongPress}
      onDayPress={handlePress}
      onMonthChange={handleChangeMonth}
      renderArrow={renderArrow}
      theme={calendarThemeObject}
    />
  );
};

BaseCalendar.propTypes = {
  availableDates: T.object,
  disabledByDefault: T.bool,
  dueDate: T.string,
  handleChangeMonth: T.func.isRequired,
  handleLongPress: T.func,
  handlePress: T.func.isRequired,
  isCurrentMonth: T.bool,
  isFinalMonth: T.bool,
  maxDate: T.string,
  minDate: T.string,
  selected: T.string.isRequired,
};

export default BaseCalendar;
