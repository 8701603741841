import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P3 } from '../../../../base_ui';

export const Container = styled.View`
  align-items: center;
  border-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: ${moderateScale(8)}px;
  border-style: solid;
  border-width: 1px;
  flex-direction: row;
  margin-vertical: ${moderateScale(8)}px;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px;
`;

export const IconContainer = styled.View`
  padding-right: ${moderateScale(12)}px;
`;

export const Text = styled(P3)`
  flex-shrink: 1;
`;
