import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P3 } from '../../../../base_ui';

const { width: deviceWidth } = Dimensions.get('window');

export const AbsoluteContainer = styled.View`
  left: ${moderateScale(Math.max(150, deviceWidth * 0.4))}px;
  position: absolute;
  top: 0;
  width: ${moderateScale(deviceWidth * 0.6)}px;
  z-index: 1;
`;

export const ErrorText = styled(P3)`
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
`;

export const LabelIconContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const TooltipButton = styled.Pressable`
  margin-left: ${moderateScale(8)}px;
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
`;
