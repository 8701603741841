import styled from 'styled-components/native';

import { P2, P3 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const StyledP2 = styled(P2)`
  line-height: ${moderateScale(22)}px;
`;

export const Delimiter = styled(StyledP2)`
  padding: 0 ${moderateScale(5)}px;
`;

export const ListItem = styled.View`
  display: flex;
  flex-direction: row;
`;

export const TooltipContent = styled.View`
  padding: 0 0 ${moderateScale(5)}px;
  text-align: left;
`;

export const TooltipHeader = styled(StyledP2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  padding-bottom: ${moderateScale(8)}px;
`;

export const TooltipLink = styled(P3)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  text-align: center;
`;
