import React from 'react';
import T from 'prop-types';

import { P2 } from '../../../../base_ui';

const ListItems = ({ data }) => data.map(
  (item, indx, ary) =>
    ary.length > 1 ? <P2 key={item}>&bull; {item}</P2> : <P2 key={item}>{item}</P2>,
);

ListItems.propTypes = { data: T.arrayOf(T.string).isRequired };

export default ListItems;
