import React, { Fragment } from 'react';
import T from 'prop-types';

import { HELOC_DRAW_PROPS_DICTIONARY } from '@dmi/shared/redux/Mortgage/constants';

import { SmallPrimaryButton } from '../../../../base_ui';
import { HomeH2, HomeP2 } from './styledComponents';

const HelocDrawHeader = ({ navigation }) => {
  const { mortgageHomeCard: { cardHeader, cardText } } = HELOC_DRAW_PROPS_DICTIONARY;

  return (
    <Fragment>
      <HomeH2>{cardHeader}</HomeH2>
      <HomeP2>{cardText}</HomeP2>
      <SmallPrimaryButton
        label={'Get Started'}
        onPress={() => navigation.navigate('HelocDrawPreConfirmation')}
      />
    </Fragment>
  );
};

HelocDrawHeader.propTypes = { navigation: T.object.isRequired };

export default HelocDrawHeader;
