import React from 'react';
import T from 'prop-types';

import { SliderLabel, SliderLabelsContainer } from './styledComponents';

const SliderLabels = ({ marks }) => (
  <SliderLabelsContainer>
    {marks.map(({ label }) => <SliderLabel key={label}>{label}</SliderLabel>)}
  </SliderLabelsContainer>
);

SliderLabels.propTypes = { marks: T.array.isRequired };

export default SliderLabels;
