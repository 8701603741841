/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavAccountActiveMIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M8.99963 1.45996C7.50115 1.45997 6.03639 1.90471 4.79086 2.73785C3.54534 3.57099 2.5751 4.75503 2.00304 6.14002C1.43098 7.525 1.28283 9.04861 1.57736 10.5179C1.87189 11.9871 2.59584 13.3359 3.65753 14.3934C4.71922 15.4509 6.07087 16.1695 7.54127 16.4581C9.01168 16.7468 10.5347 16.5926 11.9174 16.0151C13.3001 15.4375 14.4803 14.4626 15.3085 13.2138C16.1366 11.9649 16.5756 10.4984 16.5696 8.99996C16.5565 6.9991 15.7537 5.08439 14.3361 3.67235C12.9184 2.26031 11.0005 1.46519 8.99963 1.45996ZM13.6996 13.34C13.2809 12.446 12.6168 11.6894 11.7847 11.1583C10.9526 10.6272 9.98678 10.3434 8.99963 10.34C8.01065 10.3467 7.04352 10.6317 6.20884 11.1622C5.37416 11.6927 4.70561 12.4474 4.27963 13.34C3.43799 12.4251 2.88099 11.285 2.67667 10.0588C2.47235 8.83256 2.62956 7.57339 3.12909 6.43507C3.62862 5.29674 4.44885 4.32854 5.48959 3.6487C6.53033 2.96887 7.74653 2.60684 8.98963 2.60684C10.2327 2.60684 11.4489 2.96887 12.4897 3.6487C13.5304 4.32854 14.3506 5.29674 14.8502 6.43507C15.3497 7.57339 15.5069 8.83256 15.3026 10.0588C15.0983 11.285 14.5413 12.4251 13.6996 13.34Z" fill={primary} />
      <Path d="M9.0001 3.82009C8.43162 3.81811 7.87536 3.985 7.40186 4.2996C6.92836 4.6142 6.55896 5.06233 6.3405 5.58716C6.12204 6.11199 6.06436 6.68987 6.17478 7.24753C6.2852 7.80519 6.55874 8.31749 6.96072 8.71947C7.3627 9.12144 7.875 9.39499 8.43266 9.50541C8.99031 9.61583 9.5682 9.55815 10.093 9.33969C10.6179 9.12123 11.066 8.75182 11.3806 8.27832C11.6952 7.80483 11.8621 7.24856 11.8601 6.68009C11.8601 6.30451 11.7861 5.9326 11.6424 5.58561C11.4987 5.23862 11.288 4.92334 11.0224 4.65776C10.7569 4.39218 10.4416 4.18152 10.0946 4.03779C9.74758 3.89406 9.37568 3.82009 9.0001 3.82009Z" fill={primary} />
    </Svg>
  );
}
