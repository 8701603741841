import styled from 'styled-components/native';

import {
  BaseInternalLink,
  BorderlessButton,
  LargePrimaryButton,
  LargeProgressButton,
  PrimaryDivider,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

const largeButtonStyles = `margin: ${moderateScale(27)}px auto ${moderateScale(24)}px;`;

/**
 * Utilized by Form Inputs
 */

export const ButtonsContainer = styled.View`
  align-items: center;
  margin: ${moderateScale(26)}px 0 ${moderateScale(14)}px;
`;

export const ChangeEmailContainer = styled.View`
  margin-top: ${moderateScale(14)}px;
  width: 100%;
`;

// TODO will use for future implementation of "Next" button
// export const InputAccessoryViewButtonGroup = styled.View`
//   align-items: center;
//   flex-direction: row;
//   height: ${moderateScale(45)}px;
//   justify-content: flex-end;
//   padding-right: ${moderateScale(15)}px;
// `;

export const ForgotUsernameContainer = styled.View`
  margin-top: ${moderateScale(14)}px;
  width: 100%;
`;

export const RegisterInputContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  margin: auto;
  padding-bottom: ${moderateScale(36)}px;
  width: ${moderateScale(287)}px;
`;

export const StyledBaseInternalLink = styled(BaseInternalLink)`
  margin-top: ${moderateScale(12)}px;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { primary },
    fontFamilies: { regular },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: primary,
    fontFamily: regular,
    fontSize: f4,
  }],
}))`
  font-weight: 700;
  margin-top: ${moderateScale(8)}px;

  .label {
    font-weight: 700;
    line-height: ${moderateScale(22)}px;
  }
`;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  ${largeButtonStyles}
`;

export const StyledLargeProgressButton = styled(LargeProgressButton)`
  ${largeButtonStyles}
`;

export const StyledLoginLink = styled(BaseInternalLink)`
  margin: 0 auto ${moderateScale(8)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
  width: 100%;
`;

export const TextFieldWrapper = styled.View`
  margin-top: ${moderateScale(8)}px;
`;

export const RecaptchaChangeEmailWrapper = styled.View`
  margin-top: ${moderateScale(40)}px;
`;
