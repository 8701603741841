import styled from 'styled-components/native';

import { moderateScale } from '../../../../../../utils/scaleHelpers';
import { P3 } from '../../../../../base_ui';

export const Container = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(52)}px;
  justify-content: space-between;
`;

export const Label = styled(P3)`
  flex-shrink: 1;
  padding-right: ${moderateScale(8)}px;
`;
