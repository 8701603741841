import React, { Fragment, useCallback } from 'react';
import { Platform } from 'react-native';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { injectIntl } from 'react-intl';

import { useFocusEffect } from '@react-navigation/native';
import {
  getLossDraftNotificationsWithLinks,
  getServiceTransferNotification,
} from '@dmi/shared/utils/globalHelpers';
import { setNotificationsModalAutoOpenedLoans } from '@dmi/shared/redux/Notifications/actions';
import { selectPostAuthNotifications } from '@dmi/shared/redux/Notifications/selectors';
import {
  fetchRespaLetter,
  resetMainError,
  setRespaFile,
  toggleRespaDrawer,
} from '@dmi/shared/redux/Main/actions';
import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import makeSelectMain, {
  getError,
  selectTransferStatusBannerProps,
} from '@dmi/shared/redux/Main/selectors';
import makeSelectLossDraft from '@dmi/shared/redux/LossDraft/selectors';
import { changeSelectedClaimNumber } from '@dmi/shared/redux/LossDraft/actions';

import { ConditionalRender } from '../../components/base_ui';
import PDFDisplay from '../../components/PDFDisplay';
import Notifications from '../../components/Notifications/NotificationsScreen';

export const NotificationsScreen = ({
  dispatchChangeSelectedClaimNumber,
  dispatchFetchRespaLetter,
  dispatchResetMainError,
  dispatchSetNestedScreen,
  dispatchSetNotificationsModalAutoOpenedLoans,
  dispatchSetRespaFile,
  dispatchToggleRespaDrawer,
  isRespaDrawerOpen,
  loanNumber,
  lossDraftClaims,
  navigation,
  postAuthNotifications,
  respaFile,
  respaLetterError,
  transferStatus,
  transferStatusBannerProps: {
    shouldTransferBannerDisplay,
    transferStatusBannerText,
    transferStatusBannerTitle,
  },
}) => {
  useFocusEffect(
    useCallback(() => () => {
      dispatchSetNotificationsModalAutoOpenedLoans(loanNumber);
      dispatchResetMainError('respaLetter');
    }, [dispatchResetMainError, dispatchSetNotificationsModalAutoOpenedLoans, loanNumber]),
  );

  const handleRowPress = () => {
    dispatchFetchRespaLetter({
      dispatchSetFile: dispatchSetRespaFile,
      dispatchToggleDrawer: dispatchToggleRespaDrawer,
      statementId: transferStatus.respaLetterId,
    });
  };

  const handleButton = ({ claimNumber }) => {
    dispatchChangeSelectedClaimNumber({ claimNumber });
    navigation.navigate('LossDraftNewMessage');
  };

  const lossDraftNotifications = getLossDraftNotificationsWithLinks({
    handleButton,
    lossDraftClaims,
  });

  const notificationsToUse = shouldTransferBannerDisplay ?
    [getServiceTransferNotification({
      handleButton: handleRowPress,
      transferStatus,
      transferStatusBannerText,
      transferStatusBannerTitle,
    }), ...postAuthNotifications]
    : postAuthNotifications.concat(...lossDraftNotifications);

  const orderedNotifications = notificationsToUse.sort((a, b) =>
    moment(b.date, 'MMM DD, YYYY').valueOf() - moment(a.date, 'MMM DD, YYYY').valueOf());

  return (
    <Fragment>
      <Notifications
        dispatchSetNestedScreen={dispatchSetNestedScreen}
        navigation={navigation}
        notifications={orderedNotifications}
        respaLetterError={respaLetterError}
      />
      <ConditionalRender
        Component={PDFDisplay}
        propsToPassDown={{
          dispatchSetFile: dispatchSetRespaFile,
          dispatchToggleDrawer: dispatchToggleRespaDrawer,
          file: respaFile,
          isDrawerOpen: isRespaDrawerOpen,
        }}
        shouldRender={Platform.OS === 'ios' && !isEmpty(respaFile)}
      />
    </Fragment>
  );
};

NotificationsScreen.propTypes = {
  dispatchChangeSelectedClaimNumber: T.func.isRequired,
  dispatchFetchRespaLetter: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchSetNestedScreen: T.func.isRequired,
  dispatchSetNotificationsModalAutoOpenedLoans: T.func.isRequired,
  dispatchSetRespaFile: T.func.isRequired,
  dispatchToggleRespaDrawer: T.func.isRequired,
  isRespaDrawerOpen: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  lossDraftClaims: T.array.isRequired,
  navigation: T.object.isRequired,
  postAuthNotifications: T.array.isRequired,
  respaFile: T.object.isRequired,
  respaLetterError: T.oneOfType([T.bool, T.string]).isRequired,
  transferStatus: T.object.isRequired,
  transferStatusBannerProps: T.shape({
    shouldTransferBannerDisplay: T.bool.isRequired,
    transferStatusBannerText: T.string.isRequired,
    transferStatusBannerTitle: T.string.isRequired,
  }),
};

const mapStateToProps =
  createStructuredSelector({
    /**
     * Store: Loss Draft
     */
    lossDraftClaims: makeSelectLossDraft('claims'),
    /**
     * Store: Main
     */
    // eslint-disable-next-line sort-keys
    isRespaDrawerOpen: makeSelectMain('isDrawerOpen'),
    loanNumber: makeSelectMain('selectedLoan'),
    respaFile: makeSelectMain('file'),
    respaLetterError: getError('respaLetter'),
    transferStatus: makeSelectMain('transferStatus'),
    transferStatusBannerProps: selectTransferStatusBannerProps(),
    /**
     * Store: Notifications
     */
    // eslint-disable-next-line sort-keys
    postAuthNotifications: selectPostAuthNotifications(),
  });

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchChangeSelectedClaimNumber: (payload) => dispatch(changeSelectedClaimNumber(payload)),
  /**
   * Store: Main
   */
  dispatchFetchRespaLetter: (payload) => dispatch(fetchRespaLetter(payload)),
  dispatchResetMainError: (payload) => dispatch(resetMainError(payload)),
  dispatchSetRespaFile: (payload) => dispatch(setRespaFile(payload)),
  dispatchToggleRespaDrawer: (payload) => dispatch(toggleRespaDrawer(payload)),
  /**
   * Store: Mobile
   */
  // eslint-disable-next-line sort-keys
  dispatchSetNestedScreen: (payload) => dispatch(setNestedScreen(payload)),
  /**
   * Store: Notifications
   */
  dispatchSetNotificationsModalAutoOpenedLoans:
    (payload) => dispatch(setNotificationsModalAutoOpenedLoans(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(NotificationsScreen);
