/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable react/jsx-sort-props */

const AutopayMoney = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(86)} viewBox="0 0 86 86" width={moderateScale(86)} {...props}>
      <Path fill="#BAC2CA" d="M70,10.6h2.1a0,0,0,0,1,0,0V40.66a.6.6,0,0,1-.6.6H70a0,0,0,0,1,0,0V10.6a0,0,0,0,1,0,0Z" transform="translate(-2.8 42.25) rotate(-32.53)" />
      <Rect fill="#BAC2CA" x="19.94" y="54.31" width="64.1" height="1.76" transform="translate(-21.52 36.61) rotate(-32.53)" />
      <Rect strokeWidth="1.18px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" x="6.41" y="24.82" width="67.09" height="33.45" transform="translate(-16.07 28) rotate(-32.53)" />
      <Polyline strokeWidth="1.18px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="61.77 13.39 65.45 11.04 83.44 39.24 26.88 75.31 24.39 71.41" />
      <Path fill={svgHighlight} d="M61.05,20.71a6,6,0,0,1-8.22-1.82c0-.08-.09-.16-.13-.24l-39,24.85c0,.08.11.15.16.22a6,6,0,0,1-1.78,8.2l6.74,10.57A6,6,0,0,1,27,64.33l.09.16,39-24.84L66,39.5a6,6,0,0,1,1.82-8.22l0,0L61.09,20.68Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M61.05,20.71a6,6,0,0,1-8.22-1.82c0-.08-.09-.16-.13-.24l-39,24.85c0,.08.11.15.16.22a6,6,0,0,1-1.78,8.2l6.74,10.57A6,6,0,0,1,27,64.33l.09.16,39-24.84L66,39.5a6,6,0,0,1,1.82-8.22l0,0L61.09,20.68Z" />
      <Circle fill="#FFF" cx="39.95" cy="41.55" r="7.71" />
      <Circle strokeWidth="0.75px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" cx="39.95" cy="41.55" r="7.71" />
      <Circle cx="25.45" fill="black" cy="50.68" r="1.08" />
      <Circle cx="53.86" fill="black" cy="32.57" r="1.08" />
      <Path strokeWidth="0.75px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M40.11,7.93A34.26,34.26,0,0,1,52.33,9c.53.15,1.07.3,1.59.47" />
      <Path strokeWidth="0.75px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M33.47,9.23a35.4,35.4,0,0,1,3.69-.89" />
      <Path strokeWidth="0.75px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M9.3,37.05a34.41,34.41,0,0,1,19.37-26" />
      <Path strokeWidth="0.75px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M45.1,76.62a34.41,34.41,0,0,1-10.74-1.15c-.71-.19-1.4-.4-2.08-.63" />
      <Path strokeWidth="0.75px" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M77.42,47.22a35.42,35.42,0,0,1-.84,4A34.41,34.41,0,0,1,52.16,75.52" />
    </Svg>
  );
};

export default AutopayMoney;
