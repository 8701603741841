/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function DropdownArrowIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(8)} viewBox="0 0 15 8" width={moderateScale(15)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M14 1L7.5 7L1 0.999999" stroke="#282828" strokeLinecap="round" />
    </Svg>
  );
}
