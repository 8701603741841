import React, { Fragment } from 'react';
import T from 'prop-types';

import { moderateScale } from '../../../utils/scaleHelpers';
import ConditionalRender from '../ConditionalRender';
import { StyledScrollView } from '../Wrappers';
import { StyledView } from './styledComponents';

const ChildComponent = ({
  children,
  FooterComponent,
  footerProps,
  HeaderComponent,
  headerProps,
  style = {},
}) => {
  const styleRadius = HeaderComponent ? {} : {
    borderTopLeftRadius: moderateScale(16),
    borderTopRightRadius: moderateScale(16),
  };

  return (
    <Fragment>
      <ConditionalRender
        Component={HeaderComponent}
        propsToPassDown={headerProps}
        shouldRender={!!HeaderComponent}
      />
      <StyledScrollView style={styleRadius}>
        <StyledView onStartShouldSetResponder={() => true} style={style}>
          {children}
        </StyledView>
      </StyledScrollView>
      <ConditionalRender
        Component={FooterComponent}
        propsToPassDown={footerProps}
        shouldRender={!!FooterComponent}
      />
    </Fragment>
  );
};

ChildComponent.propTypes = {
  children: T.node.isRequired,
  FooterComponent: T.elementType,
  footerProps: T.object,
  HeaderComponent: T.elementType,
  headerProps: T.object,
  style: T.oneOfType([T.object, T.array]),
};

export default ChildComponent;
