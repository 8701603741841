import React from 'react';
import T from 'prop-types';

import {
  H3,
  P3,
} from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  DownloadButton,
  SectionHeader,
  StyledDivider,
  TermLabel,
  TermRow,
  TermsContainer,
} from './styledComponents';

const DownloadIcon = iconDictionary('downloadActiveS', { iconState: 'active' });

const InitialTerms = ({ handleDownloadCSV, initialTerms }) => (
  <TermsContainer>
    <SectionHeader>
      <H3>Initial Terms</H3>
      <DownloadButton
        aria-label="Download-amortization-schedule"
        Icon={DownloadIcon}
        isPrimary
        label="Amortization Schedule"
        onPress={handleDownloadCSV}
      />
    </SectionHeader>
    <StyledDivider />
    {initialTerms.map(({ label, value }) => (
      <TermRow key={`${label}-row`}>
        <TermLabel>{label}</TermLabel>
        <P3>{value}</P3>
      </TermRow>
    ))}
  </TermsContainer>
);

InitialTerms.propTypes = {
  handleDownloadCSV: T.func.isRequired,
  initialTerms: T.array.isRequired,
};

export default InitialTerms;
