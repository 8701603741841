import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { StyledLinkText } from './styledComponents';

const LinkComponent = ({
  ariaLabel = 'disclosure link',
  disclosureName,
  handleOpenDrawer,
  linkLabel,
}) => (
  <Pressable
    aria-label={ariaLabel}
    onPress={() => handleOpenDrawer(disclosureName)}
    role="link"
  >
    <StyledLinkText>{linkLabel}</StyledLinkText>
  </Pressable>
);

LinkComponent.propTypes = {
  ariaLabel: T.string,
  disclosureName: T.string.isRequired,
  handleOpenDrawer: T.func.isRequired,
  linkLabel: T.string.isRequired,
};

export default LinkComponent;
