/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Ellipse,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function PoolHouse(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(210)} viewBox="0 0 165 210" width={moderateScale(165)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path className="cls-1" d="M26.05,66.62h0C21.15,34,14.26,35.41,14.26,35.41v0S7.63,34,2.73,66.62h0a22.25,22.25,0,0,0-.22,3.09c0,8.81,5.31,16,11.87,16s11.87-7.15,11.87-16A22.25,22.25,0,0,0,26.05,66.62Z" fill="#eff3f6" />
      <Path className="cls-2" d="M26.27,69.71a22.25,22.25,0,0,0-.22-3.09h0C21.15,34,14.26,35.41,14.26,35.41v0a1.74,1.74,0,0,0-1.13.29C14.86,39.65,20.4,53,21,65.39c.5,11.39-2.91,17.26-6.65,20.27h.1C21,85.67,26.27,78.52,26.27,69.71Z" fill="#dce2e7" />
      <Path className="cls-3" d="M26.05,66.62h0C21.15,34,14.26,35.41,14.26,35.41v0S7.63,34,2.73,66.62h0a22.25,22.25,0,0,0-.22,3.09c0,8.81,5.31,16,11.87,16s11.87-7.15,11.87-16A22.25,22.25,0,0,0,26.05,66.62Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Line className="cls-4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" x1="13.54" x2="13.54" y1="69.54" y2="106.4" />
      <Line className="cls-4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" x1="9.08" x2="13.41" y1="72.7" y2="76.11" />
      <Line className="cls-4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" x1="17.98" x2="13.65" y1="76.69" y2="80.1" />
      <Path className="cls-5" d="M9.55,46.24A70.66,70.66,0,0,0,6.47,58.52" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M11.51,41s-.56,1.3-1.32,3.4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M137.89,21s-8.27,18.15-5.58,38.56" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M139.05,22.07s-8.27,18.15-5.58,38.55" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-6" d="M139.36,20.6s-15.61-4.37-17.18,14.89Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-6" d="M139.33,20.56s-5.87-13-18.48-.5C120.85,20.06,131.88,16.7,139.33,20.56Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-6" d="M139,20.75s16.15-1.26,14,17.94Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-6" d="M139.07,20.72s8.29-11.64,18.22,3.09C157.29,23.81,147.12,18.37,139.07,20.72Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-6" d="M139.26,20.56s-5,7.34-.49,22C138.77,42.51,145.54,28.51,139.26,20.56Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M132.69,23.28a25.45,25.45,0,0,1,4.12-2" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M126.11,28.92a23.16,23.16,0,0,1,5.58-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M131.06,16.62A14.19,14.19,0,0,1,138.78,20" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M128.08,16.71a8.93,8.93,0,0,1,1.95-.15" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M143.26,18.58a12.15,12.15,0,0,1,9.16,1.68" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M147.26,26.14a15.6,15.6,0,0,1,3.27,5.79" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M142.61,22.55a14.21,14.21,0,0,1,3.46,2.39" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Rect className="cls-7" fill="#fff" height="46.47" width="136.17" x="17.33" y="60.27" />
      <Rect className="cls-8" fill="#e6ebef" height="46.56" width="12.96" x="140.3" y="60.01" />
      <Polygon className="cls-9" fill="#bac2ca" points="81.73 28.99 112.13 45.95 153.62 60.27 141.21 60.27 18.87 60.27 17.56 60.27 53.86 45.59 81.64 28.92 81.73 28.99" />
      <Polygon className="cls-10" fill="#8a97a4" points="81.63 28.99 104.69 45.44 140.69 60.29 153.28 60.29 111.84 46.01 81.63 28.99" />
      <Path className="cls-11" d="M111.15,20.34a1.53,1.53,0,0,0,1.41-2.1c-.86-2.12-2.67-5.14-6.11-5.5a6,6,0,0,0-3.85,1.13.23.23,0,0,1-.3,0,4.36,4.36,0,0,0-3-1.1c-2.21,0-4,1.5-4,3.36h0a.25.25,0,0,1-.27.24h-.32a3.09,3.09,0,0,0-2.92,1.76.24.24,0,0,1-.32.14,2.8,2.8,0,0,0-1.2-.25,2.53,2.53,0,0,0-2,1.14.76.76,0,0,0,.61,1.2Z" fill="#d9e1e8" />
      <Path className="cls-11" d="M58.33,29.32a1.05,1.05,0,0,0,.8-1.74,7,7,0,0,0-5.26-1.95,9.51,9.51,0,0,0-1.09.07.66.66,0,0,1-.73-.66h0c0-2-2.52-3.61-5.63-3.61a8.42,8.42,0,0,0-2.42.35.61.61,0,0,1-.79-.45c-.66-3-3.83-5.21-7.66-5.21-4.3,0-7.79,2.84-7.79,6.34,0,.13,0,.25,0,.37a3.41,3.41,0,0,0-.55-.05,3.62,3.62,0,0,0-3.44,2.51.64.64,0,0,1-.78.42,3.52,3.52,0,0,0-1-.14,3.63,3.63,0,0,0-2.83,1.36,1.49,1.49,0,0,0,1.17,2.4Z" fill="#d9e1e8" />
      <Path className="cls-12" d="M47.6,127.56q3.6.22,7,.66a19.53,19.53,0,0,0,7.83-.65c7.63-2.16,17.65-3.66,28.6-4.34m3.07-.17c3.38-.16,6.83-.24,10.32-.24,14.77,0,28.72,1.43,39.27,4,13.33,3.28,19.81,8,19.81,14.49s-6.48,11.22-19.81,14.5c-10.55,2.6-24.5,4-39.27,4-18,0-34.54-2.11-45.32-5.78a19,19,0,0,0-8-1c-3.82.36-7.84.54-12,.54a104.75,104.75,0,0,1-23.94-2.46c-3.33-.82-13.46-3.31-13.46-10.73s10.13-9.9,13.46-10.72a104.71,104.71,0,0,1,23.94-2.47" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-8" d="M129.77,128a180.93,180.93,0,0,0-26-1.77c-15.67,0-29.71,1.76-39.24,4.55a23.88,23.88,0,0,1-9.69.81,109.44,109.44,0,0,0-14.28-.89c-17.14,0-31,3.53-31,7.88s13.88,7.88,31,7.88c3.9,0,7.64-.18,11.08-.52a23,23,0,0,1,9.94,1.23c9.44,3.32,24.82,5.48,42.19,5.48,28.73,0,52-5.91,52-13.21,0-4.89-10.46-9.16-26-11.44" fill="#e6ebef" />
      <Path className="cls-13" d="M77.59,150.81a181.14,181.14,0,0,0,26.16,1.79c28.73,0,52-5.91,52-13.21,0-4.6-9.25-8.65-23.28-11" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.66" />
      <Path className="cls-13" d="M129.77,128a180.93,180.93,0,0,0-26-1.77c-15.67,0-29.71,1.76-39.24,4.55a23.88,23.88,0,0,1-9.69.81,109.44,109.44,0,0,0-14.28-.89c-17.14,0-31,3.53-31,7.88s13.88,7.88,31,7.88c3.9,0,7.64-.18,11.08-.52a23,23,0,0,1,9.94,1.23,80.46,80.46,0,0,0,12.4,3.1" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.66" />
      <Path className="cls-5" d="M33.4,132.42c-12.7.76-22.38,3.5-23.72,6.86" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M89.85,128.22a124.9,124.9,0,0,0-25.34,4.06,23.54,23.54,0,0,1-9.69.81c-2.39-.31-5-.55-7.66-.7" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M155.67,140.15c-1.62-6.92-24.24-12.41-51.92-12.41-3.88,0-7.66.11-11.3.31" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="53.27" x2="81.06" y1="158.69" y2="158.69" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="2.01" x2="50.15" y1="158.69" y2="158.69" />
      <Line className="cls-15" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.37" x1="139.59" x2="162.92" y1="158.69" y2="158.69" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="105.39" x2="154.09" y1="167.53" y2="167.53" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="35.13" x2="99.93" y1="167.53" y2="167.53" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="4.69" x2="14.61" y1="167.53" y2="167.53" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="149.35" x2="163.05" y1="117.44" y2="117.44" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="102.4" x2="146.04" y1="117.44" y2="117.44" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="46.26" x2="88.7" y1="117.44" y2="117.44" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="2.01" x2="24.11" y1="117.44" y2="117.44" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="68.5" x2="152.79" y1="112.39" y2="112.39" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="11.17" x2="63.99" y1="112.39" y2="112.39" />
      <Line className="cls-16" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" x1="2.33" x2="5.97" y1="106.78" y2="106.78" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="39.02" x2="84.61" y1="123.6" y2="123.6" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="21.1" x2="32.01" y1="123.6" y2="123.6" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="6.29" x2="18.85" y1="123.6" y2="123.6" />
      <Line className="cls-17" fill="#fff" stroke="#000" strokeMiterlimit="10" strokeWidth="0.37" x1="135.58" x2="160.48" y1="123.6" y2="123.6" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="1.88" x2="162.92" y1="177.35" y2="177.35" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="88.25" x2="158.12" y1="187.83" y2="187.83" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="27.59" x2="77.08" y1="187.83" y2="187.83" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="4.69" x2="19.02" y1="187.83" y2="187.83" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="117.51" x2="163.14" y1="200.31" y2="200.31" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="12.53" x2="113.53" y1="200.31" y2="200.31" />
      <Line className="cls-14" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.37" x1="1.88" x2="8.24" y1="200.31" y2="200.31" />
      <Polyline className="cls-4" fill="none" points="46.04 60.27 18.76 60.27 17.45 60.27 53.75 45.59 81.53 28.92" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" />
      <Line className="cls-4" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" x1="141.1" x2="48.86" y1="60.27" y2="60.27" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" x1="120.74" x2="141.1" y1="52.03" y2="60.27" />
      <Polyline className="cls-3" fill="none" points="81.53 28.92 104.8 45.59 118.47 51.12" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Polyline className="cls-4" fill="none" points="153.51 96.31 153.51 106.74 17.33 106.74 17.33 60.27 46.56 60.27" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" />
      <Polyline className="cls-4" fill="none" points="50.03 60.27 153.51 60.27 153.51 92.37" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" />
      <Line className="cls-12" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" x1="140.38" x2="140.38" y1="72.54" y2="105.14" />
      <Line className="cls-12" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" x1="140.38" x2="140.38" y1="62.44" y2="69.9" />
      <Polyline className="cls-4" fill="none" points="81.53 28.92 112.02 45.95 153.51 60.27" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" />
      <Rect className="cls-8" fill="#e6ebef" height="32.86" width="15.97" x="108.84" y="71.96" />
      <Rect className="cls-8" fill="#e6ebef" height="32.86" width="5.82" x="126.25" y="71.96" />
      <Polygon className="cls-18" fill="#fff" opacity="0.6" points="115.15 104.8 110.12 104.8 131.91 71.98 136.95 71.98 115.15 104.8" />
      <Polygon className="cls-18" fill="#fff" opacity="0.6" points="98.14 104.8 93.1 104.8 114.9 71.98 119.93 71.98 98.14 104.8" />
      <Rect className="cls-8" fill="#e6ebef" height="32.86" width="15.97" x="24.37" y="71.96" />
      <Polygon className="cls-18" fill="#fff" opacity="0.6" points="20.84 84.82 20.84 78.13 24.77 71.98 29.8 71.98 20.84 84.82" />
      <Rect className="cls-8" fill="#e6ebef" height="32.86" width="5.82" x="41.77" y="71.96" />
      <Polygon className="cls-18" fill="#fff" opacity="0.6" points="30.52 104.8 25.48 104.8 47.28 71.98 52.31 71.98 30.52 104.8" />
      <Rect className="cls-8" fill="#e6ebef" height="32.86" width="15.97" x="55.89" y="71.96" />
      <Rect className="cls-8" fill="#e6ebef" height="32.86" width="27.06" x="73.3" y="71.96" />
      <Polygon className="cls-18" fill="#fff" opacity="0.6" points="78.33 104.8 73.3 104.8 95.09 71.98 100.13 71.98 78.33 104.8" />
      <Polygon className="cls-18" fill="#fff" opacity="0.6" points="60.93 104.8 55.89 104.8 77.69 71.98 82.72 71.98 60.93 104.8" />
      <Rect className="cls-19" fill="#fff" height="2.24" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" width="0.62" x="69.93" y="87.82" />
      <Rect className="cls-19" fill="#fff" height="2.24" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" width="0.62" x="74.62" y="87.82" />
      <Line className="cls-19" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" x1="57.62" x2="99.59" y1="45.58" y2="45.58" />
      <Path className="cls-20" d="M45.88,135a2.12,2.12,0,0,1-.82.11,2.24,2.24,0,0,1-.84-.11V122.19a3.36,3.36,0,1,0-6.72,0v3H35.84v-3a5,5,0,0,1,10,0Z" fill={svgHighlight} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.43" />
      <Path className="cls-20" d="M35.93,126.39h8.36a.7.7,0,0,1,.7.7v0a.7.7,0,0,1-.7.7H35.93a0,0,0,0,1,0,0v-1.39A0,0,0,0,1,35.93,126.39Z" fill={svgHighlight} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.43" />
      <Path className="cls-20" d="M35.93,130.58h8.36a.7.7,0,0,1,.7.7v0a.7.7,0,0,1-.7.7H35.93a0,0,0,0,1,0,0v-1.39A0,0,0,0,1,35.93,130.58Z" fill={svgHighlight} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.43" />
      <Path className="cls-21" d="M36.35,135a2.12,2.12,0,0,1-.82.11,2.24,2.24,0,0,1-.84-.11V122.19a3.36,3.36,0,1,0-6.72,0v3H26.31v-3a5,5,0,0,1,10,0Z" fill={svgHighlight} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.43" />
      <Path className="cls-22" d="M37.31,134.71c.59.14,1,.35,1,.59,0,.43-1.24.77-2.78.77s-2.77-.34-2.77-.77c0-.24.38-.46,1-.6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" />
      <Path className="cls-22" d="M46.79,134.71c.59.14,1,.35,1,.59,0,.43-1.24.77-2.77.77s-2.77-.34-2.77-.77c0-.24.37-.46,1-.6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" />
      <Polyline className="cls-5" fill="none" points="108.84 84.8 108.84 71.96 124.82 71.96 124.82 104.82 108.84 104.82 108.84 86.96" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Polyline className="cls-5" fill="none" points="126.25 95.62 126.25 71.96 132.07 71.96 132.07 104.82 126.25 104.82 126.25 97.09" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Rect className="cls-5" fill="none" height="32.86" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" width="15.97" x="24.37" y="71.96" />
      <Rect className="cls-5" fill="none" height="32.86" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" width="5.82" x="41.77" y="71.96" />
      <Rect className="cls-5" fill="none" height="32.86" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" width="15.97" x="55.89" y="71.96" />
      <Rect className="cls-5" fill="none" height="32.86" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" width="27.06" x="73.3" y="71.96" />
      <Rect className="cls-3" fill="none" height="36.15" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" width="26.35" x="107.32" y="70.43" />
      <Polyline className="cls-3" fill="none" points="22.84 91.44 22.84 70.43 49.19 70.43 49.19 106.58 22.84 106.58 22.84 94.3" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Polyline className="cls-3" fill="none" points="88.05 70.43 101.95 70.43 101.95 106.58 54.37 106.58 54.37 70.43 85.58 70.43" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Line className="cls-16" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66" x1="8.63" x2="162.88" y1="106.78" y2="106.78" />
      <Path className="cls-6" d="M137.35,89.85s3.14,11.27,11.36,16.51C148.71,106.36,147.77,98.79,137.35,89.85Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-23" d="M146.81,86.84s-2.56,11.4,2.21,19.91C149,106.75,151.77,99.63,146.81,86.84Z" fill="#e6ebef" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-6" d="M157,90.42s-7.4,7.11-8,15.65C149,106.07,154.42,102.19,157,90.42Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-19" d="M162.88,95.71S153.16,99,149,106.48C149,106.48,155.57,105.29,162.88,95.71Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-19" d="M134.57,96.61s6.1,8.25,14.49,10C149.06,106.58,145.92,100.66,134.57,96.61Z" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Circle className="cls-7" cx="127.12" cy="146.79" fill="#fff" r="9.05" />
      <Path className="cls-24" d="M118.92,150.74s.13-2.73,1.63-3.47l-.09-1.5a2.45,2.45,0,0,1-2.11-1.62,13.74,13.74,0,0,0,.49,6.48Z" fill={svgHighlight} />
      <Path className="cls-24" d="M133.72,153c-6.44-.15-10.73-5.41-10.73-5.41l-1,.55s-.52,5,3.45,7.46a8.83,8.83,0,0,0,4.24-.26,16.62,16.62,0,0,0,4-2.1Z" fill={svgHighlight} />
      <Path className="cls-24" d="M121.91,144.61s2.16-7.52,9.79-5.6c0,0,3.38,1.85,4.62,7.26l-.16,0a32.13,32.13,0,0,0-12.9-.23Z" fill={svgHighlight} />
      <Circle className="cls-3" cx="127.12" cy="146.79" fill="none" r="9.05" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Ellipse className="cls-12" cx="121.86" cy="146.38" fill="#fff" rx="1.37" ry="1.7" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-3" d="M122,144.61s2.15-7.52,9.78-5.6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-3" d="M123.3,146.07a32.09,32.09,0,0,1,12.89.23" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-3" d="M122.88,147.61s4.29,5.26,10.73,5.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-3" d="M121.9,148.16s-.52,5,3.45,7.46" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-3" d="M119,150.74s.12-2.73,1.63-3.47" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-3" d="M118.44,144.15a2.45,2.45,0,0,0,2.11,1.62" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-24" d="M100.13,121.73c-.12-.72-.38-1.73-.38-1.73a3.83,3.83,0,0,0,.26-2.2,2,2,0,0,0-.43-.73,3.63,3.63,0,0,0-2-1.14,6.53,6.53,0,0,0-3.4,0,6.43,6.43,0,0,0-4.39,4.39,7.82,7.82,0,0,0,.61,5.76c1.54,3.11,2.26,3,2.56,4.28a54.32,54.32,0,0,0-5.78-.29c-6.17,0-10.53.68-11.32,2.15a6.26,6.26,0,0,0-.39,2.24,4.93,4.93,0,0,0,.4,2.1c.69,1.35,5.17,2.14,11.31,2.14s10.66-.88,11.28-2.13a4.49,4.49,0,0,0,.38-2.11c0-.24,0-.46,0-.67h0a6.66,6.66,0,0,0-.29-1.52.82.82,0,0,0-.18-.29,10.4,10.4,0,0,0-2.75-3.7,13.26,13.26,0,0,1-3.21-4.6c-.29-.58-.78-3,1-4s4,.9,4,.9c1,.72,1.66,1.2,2.1,1.48A.35.35,0,0,0,100.13,121.73Z" fill={svgHighlight} />
      <Path className="cls-25" d="M75.65,133.94a37.43,37.43,0,0,0,13.86,3.31c8,.36,8.93-5,8.93-5s1.62,4-1.07,5.06-14.32,2.21-19.94.13C77.43,137.48,75.65,137.29,75.65,133.94Z" fill={svgHighlight} />
      <Path className="cls-3" d="M100.13,121.73c-.12-.72-.38-1.73-.38-1.73a3.83,3.83,0,0,0,.26-2.2,2,2,0,0,0-.43-.73,3.63,3.63,0,0,0-2-1.14,6.53,6.53,0,0,0-3.4,0,6.43,6.43,0,0,0-4.39,4.39,7.82,7.82,0,0,0,.61,5.76c1.54,3.11,2.26,3,2.56,4.28a54.32,54.32,0,0,0-5.78-.29c-6.17,0-10.53.68-11.32,2.15a6.26,6.26,0,0,0-.39,2.24,4.93,4.93,0,0,0,.4,2.1c.69,1.35,5.17,2.14,11.31,2.14s10.66-.88,11.28-2.13a4.49,4.49,0,0,0,.38-2.11c0-.24,0-.46,0-.67h0a6.66,6.66,0,0,0-.29-1.52.82.82,0,0,0-.18-.29,10.4,10.4,0,0,0-2.75-3.7,13.26,13.26,0,0,1-3.21-4.6c-.29-.58-.78-3,1-4s4,.9,4,.9c1,.72,1.66,1.2,2.1,1.48A.35.35,0,0,0,100.13,121.73Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Circle cx="98.5" cy="118.18" r="0.32" />
      <Path d="M99.73,119.91a2,2,0,0,0-2.07.72l2.07,1.45s.33.1.33-.23S99.73,119.91,99.73,119.91Z" />
      <Ellipse className="cls-26" cx="86.76" cy="132.3" fill={svgHighlight} rx="6.82" ry="0.69" stroke="#000" strokeMiterlimit="10" strokeWidth="0.37" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" x1="93.03" x2="93.22" y1="130.43" y2="131.27" />
      <Path className="cls-22" d="M100.09,136.69c.94.36,1.47.78,1.47,1.21,0,1.53-6.43,2.76-14.35,2.76s-14.35-1.23-14.35-2.76c0-.47.62-.92,1.71-1.31" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" />
      <Path className="cls-22" d="M77.4,141.57a55.8,55.8,0,0,0,10.75.78,59.78,59.78,0,0,0,9.38-.71" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" />
      <Path className="cls-22" d="M72.18,139.93a10.07,10.07,0,0,0,2.45,1" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" />
      <Line className="cls-22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" x1="18.2" x2="29.89" y1="141.03" y2="141.03" />
      <Line className="cls-22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" x1="23.41" x2="33.72" y1="142.72" y2="142.72" />
      <Line className="cls-22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" x1="36.3" x2="38.63" y1="142.72" y2="142.72" />
      <Line className="cls-22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" x1="126.73" x2="118.07" y1="132.2" y2="132.2" />
      <Line className="cls-22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" x1="131.48" x2="121.17" y1="133.89" y2="133.89" />
      <Line className="cls-22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.23" x1="118.59" x2="116.26" y1="133.89" y2="133.89" />
      <Polygon className="cls-12" fill="#fff" points="86.65 193.68 83.66 193.68 76.52 181.34 79.51 181.34 86.65 193.68" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Polygon className="cls-27" fill="#bac2ca" points="89.25 185.5 86.26 185.5 79.12 173.16 82.1 173.16 89.25 185.5" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Polygon className="cls-12" fill="#fff" points="20.61 193.68 23.59 193.68 30.74 181.34 27.75 181.34 20.61 193.68" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Polygon className="cls-27" fill="#bac2ca" points="30.28 185.5 33.27 185.5 40.41 173.16 37.43 173.16 30.28 185.5" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path className="cls-7" d="M26.94,182.85l53.41-.07a1.3,1.3,0,0,0,1.32-1l1.87-9.19a1.22,1.22,0,0,0,0-.26v-1.18a1.3,1.3,0,0,0-1.3-1.3H33.51a1.32,1.32,0,0,1-1.14-.67l-9.72-17.52a1.29,1.29,0,0,0-1.66-.56l-.85.37a1.27,1.27,0,0,0-.66.65l-4.27,9.26a1.31,1.31,0,0,0,.11,1.27L25.6,181.42C25.84,181.77,26.53,182.84,26.94,182.85Z" fill="#fff" />
      <Path className="cls-2" d="M62.53,183l16.93,0s1.67-.06,1.87-1a1.27,1.27,0,0,0,0-.34h0a1.31,1.31,0,0,0-1.31-1.31H28.77a1.3,1.3,0,0,1-1.14-.67l-9.72-17.52a1.3,1.3,0,0,0-1.66-.56h0a1.31,1.31,0,0,0-.78,1.28l.31.6,10.47,19.31a1.88,1.88,0,0,0,.65.3L62.53,183" fill="#dce2e7" />
      <Path className="cls-24" d="M81.73,171.82H32.65a1.31,1.31,0,0,1-1.15-.68l-9.78-17.63a1.31,1.31,0,0,0-1.67-.57l-.86.37a1.35,1.35,0,0,0-.67.66l-.78,1.95a1.31,1.31,0,0,1,.67-.66l.86-.38a1.33,1.33,0,0,1,1.68.57l9.83,17.72a1.31,1.31,0,0,0,1.15.68h49.3a1.31,1.31,0,0,1,1.31,1.31v1.19a2.11,2.11,0,0,1,0,.26l.5-2a2.45,2.45,0,0,0,0-.27v-1.18A1.31,1.31,0,0,0,81.73,171.82Z" fill={svgHighlight} />
      <Path className="cls-24" d="M80.74,176H31a1.31,1.31,0,0,1-1.16-.69l-9.93-17.88a1.33,1.33,0,0,0-1.69-.58l-.87.38a1.37,1.37,0,0,0-.68.66l-.79,2a1.35,1.35,0,0,1,.68-.67l.87-.38a1.36,1.36,0,0,1,1.71.58l10,18a1.34,1.34,0,0,0,1.17.69h50a1.34,1.34,0,0,1,1.33,1.34v1.2a2.45,2.45,0,0,1,0,.27l.5-2.07a1.09,1.09,0,0,0,0-.26v-1.2A1.33,1.33,0,0,0,80.74,176Z" fill={svgHighlight} />
      <Path className="cls-28" d="M80.49,182.78a1.3,1.3,0,0,0,1.18-1l1.87-9.19a1.22,1.22,0,0,0,0-.26v-1.18a1.3,1.3,0,0,0-1.3-1.3H33.51a1.32,1.32,0,0,1-1.14-.67l-9.72-17.52a1.29,1.29,0,0,0-1.66-.56l-.85.37a1.27,1.27,0,0,0-.66.65l-4.27,9.26a1.31,1.31,0,0,0,.11,1.27" fill="none" stroke="#000" strokeMiterlimit="10" strokeWidth="0.6" />
      <Path className="cls-5" d="M20.22,166.25l-2.31-4.16a1.3,1.3,0,0,0-1.66-.56h0a1.31,1.31,0,0,0-.78,1.28" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-5" d="M81.33,181.93a1.27,1.27,0,0,0,0-.34h0a1.31,1.31,0,0,0-1.31-1.31H28.77a1.3,1.3,0,0,1-1.14-.67L21.08,167.8" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" />
      <Path className="cls-29" d="M61.36,182.89H80.07a1.3,1.3,0,0,0,1.26-1" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" />
      <Path className="cls-29" d="M15.23,162.46a4.13,4.13,0,0,0,.4.89l10.29,18.86a1.31,1.31,0,0,0,1.15.68h32" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.33" x1="29.28" x2="32.72" y1="179.11" y2="171.05" />
    </Svg>
  );
}
