import React, { Fragment } from 'react';
import T from 'prop-types';

import { INFORMATION_NOT_AVAILABLE } from '@dmi/shared/utils/constants';

import { ConditionalRender, P3 } from '../../../../base_ui';
import iconDictionary from '../../../../../utils/iconDictionary';
import {
  AddressContainer,
  AddressLabel,
  PendingContainer,
  PendingP3,
} from './styledComponents';

const PendingIcon = iconDictionary('outlinedPendingPurple');

const MailingAddress = ({
  isActive,
  isPending,
  mailingAddress: {
    addressLine2,
    addressLine3,
    apartment,
    cityProvincePostalCode,
    cityStateZip,
    companyOrCareOf,
    country,
    streetAddress,
  },
}) => {
  const InternationalMailingAddress = (
    <Fragment>
      <P3>{streetAddress}</P3>
      {addressLine2 && <P3>{addressLine2}</P3>}
      {addressLine3 && <P3>{addressLine3}</P3>}
      {cityProvincePostalCode && <P3>{cityProvincePostalCode}</P3>}
      {country && <P3>{country}</P3>}
    </Fragment>
  );

  const DomesticMailingAddress = (
    <Fragment>
      {companyOrCareOf && <P3>{companyOrCareOf}</P3>}
      <P3>{streetAddress || INFORMATION_NOT_AVAILABLE}</P3>
      {apartment && <P3>{apartment}</P3>}
      {cityStateZip && <P3>{cityStateZip}</P3>}
    </Fragment>
  );

  const InactiveMailingAddress = (
    <Fragment>
      <AddressLabel>Mailing Address</AddressLabel>
      <P3>{INFORMATION_NOT_AVAILABLE}</P3>
    </Fragment>
  );

  return (
    <AddressContainer>
      <ConditionalRender
        Component={(
          <Fragment>
            <AddressLabel>Mailing Address</AddressLabel>
            <ConditionalRender
              Component={(
                <ConditionalRender
                  Component={DomesticMailingAddress}
                  FallbackComponent={InternationalMailingAddress}
                  shouldRender={!country}
                />
              )}
              FallbackComponent={(
                <PendingContainer>
                  {PendingIcon}
                  <PendingP3>Pending</PendingP3>
                </PendingContainer>
              )}
              shouldRender={!isPending}
            />
          </Fragment>
        )}
        FallbackComponent={InactiveMailingAddress}
        shouldRender={isActive}
      />
    </AddressContainer>
  );
};

MailingAddress.propTypes = {
  isActive: T.bool,
  isPending: T.bool.isRequired,
  mailingAddress: T.shape({
    addressLine2: T.string,
    addressLine3: T.string,
    apartment: T.string,
    cityProvincePostalCode: T.string,
    cityStateZip: T.string,
    companyOrCareOf: T.string,
    country: T.string,
    streetAddress: T.string,
  }).isRequired,
};

export default MailingAddress;
