import React from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Calculators/Amortize/messages';

import { ConditionalRender } from '../../../base_ui';
import Chart from '../../Chart';
import { BannerContainer, ContentContainer } from './styledComponents';
import InitialTerms from './InitialTerms';
import Calculator from './Calculator';
import CalculatorDisclaimer from '../../Shared/CalculatorDisclaimer';

const Form = ({
  amortizationValues,
  chartData,
  dispatchAmortizeCalculate,
  dispatchChangeInput,
  dispatchResetCalculator,
  dispatchUpdateFormError,
  formErrors,
  handleDownloadCSV,
  initialTerms,
  isCalculateButtonDisabled,
  loanNumber,
  menuItems,
  navigation,
  requestBody,
  status,
}) => {
  const { valueType } = amortizationValues;
  const { formatMessage } = useIntl();

  return (
    <ContentContainer>
      <BannerContainer>
        <CalculatorDisclaimer
          disclaimer={formatMessage({ id: INTL_IDS.AMORTIZATION_BANNER_DISCLAIMER })}
        />
      </BannerContainer>
      <ConditionalRender
        Component={() => (
          <Chart
            aria-label="Amortization Chart"
            chartData={chartData}
            label="Amortization Chart"
          />
        )}
        shouldRender={!valueType}
      />
      <InitialTerms
        handleDownloadCSV={handleDownloadCSV}
        initialTerms={initialTerms}
      />
      <Calculator
        amortizationValues={amortizationValues}
        dispatchAmortizeCalculate={dispatchAmortizeCalculate}
        dispatchChangeInput={dispatchChangeInput}
        dispatchResetCalculator={dispatchResetCalculator}
        dispatchUpdateFormError={dispatchUpdateFormError}
        formErrors={formErrors}
        isCalculateButtonDisabled={isCalculateButtonDisabled}
        loanNumber={loanNumber}
        menuItems={menuItems}
        navigation={navigation}
        requestBody={requestBody}
        status={status}
      />
    </ContentContainer>
  );
};

Form.propTypes = {
  amortizationValues: T.shape({ valueType: T.string.isRequired }).isRequired,
  chartData: T.object.isRequired,
  dispatchAmortizeCalculate: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetCalculator: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  formErrors: T.object.isRequired,
  handleDownloadCSV: T.func.isRequired,
  initialTerms: T.array.isRequired,
  isCalculateButtonDisabled: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  menuItems: T.object.isRequired,
  navigation: T.object.isRequired,
  requestBody: T.object.isRequired,
  status: T.object.isRequired,
};

export default Form;
