import styled from 'styled-components/native';

import { moderateScale } from '../../../../../../utils/scaleHelpers';
import { TertiaryDivider } from '../../../../../base_ui';

export const ButtonContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: ${moderateScale(30)}px;
`;

export const Container = styled.View`
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(16)}px;
`;

export const StyledTertiaryDivider = styled(TertiaryDivider)`
  max-width: 100%;
`;
