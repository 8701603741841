/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function PaymentHistoryDefaultMIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M9,17.1c-4.4,0-8-3.6-8-8c0-0.3,0.3-0.6,0.6-0.6S2.2,8.7,2.2,9c0,3.7,3,6.8,6.8,6.8s6.8-3,6.8-6.8c0-3.7-3-6.8-6.8-6.8c-2.6,0-5.1,1.5-6.2,3.9C2.7,6.5,2.3,6.7,2,6.5C1.7,6.4,1.5,6,1.7,5.7C3,2.9,5.8,1,9,1c4.4,0,8,3.6,8,8C17,13.5,13.4,17.1,9,17.1z" fill="black" />
        <G clipPath={clipPath}>
          <Path d="M9,13.1v-0.7c-0.9-0.1-1.8-0.5-2.5-1.1l0.7-0.9c0.6,0.5,1.2,0.8,1.8,0.9v-2C8.2,9.1,7.7,8.9,7.3,8.6C7,8.3,6.8,7.8,6.8,7.2c0-0.6,0.2-1.1,0.6-1.4C7.8,5.4,8.3,5.2,9,5.2V4.7h0.7v0.5c0.7,0.1,1.5,0.3,2.1,0.8l-0.7,0.9c-0.4-0.3-0.9-0.5-1.5-0.6v1.9h0C10.5,8.4,11,8.6,11.4,9c0.4,0.3,0.5,0.8,0.5,1.4c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-1,0.6-1.7,0.6v0.7H9z M8.3,6.5C8.1,6.7,8,6.9,8,7.1s0.1,0.4,0.2,0.5C8.4,7.8,8.6,7.9,9,8V6.3C8.7,6.3,8.4,6.4,8.3,6.5z M10.4,11c0.2-0.2,0.3-0.4,0.3-0.6c0-0.2-0.1-0.4-0.2-0.6c-0.1-0.1-0.4-0.3-0.8-0.4v1.8C10,11.3,10.2,11.2,10.4,11z" fill="black" />
        </G>
        <Path d="M5.1,7.2H1.7C1.3,7.2,1,7,1,6.6v-4C1,2.3,1.3,2,1.7,2s0.6,0.3,0.6,0.6V6h2.8c0.3,0,0.6,0.3,0.6,0.6S5.4,7.2,5.1,7.2z" fill="black" />
      </G>
    </Svg>
  );
}
