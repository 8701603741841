/*
 * Payment History
 *
 */

import React, { Fragment } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import {
  fetchTransactions,
  setGroupedTransactions,
  updateSelectedTransaction,
} from '@dmi/shared/redux/Payments/History/actions';
import makeSelectPaymentHistory, {
  makeSelectError,
  makeSelectFlatListTransactions,
  makeSelectPaymentHistoryNotifications,
} from '@dmi/shared/redux/Payments/History/selectors';
import { changeFormFieldValue } from '@dmi/shared/redux/SecureMessaging/actions';

import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';
import SecureMessagingBanner from '../../../components/SecureMessagingBanner';
import {
  PaymentHistoryList,
  PaymentHistoryListSkeleton,
} from '../../../components/Payments/History';
import AsyncRender from '../../../components/AsyncRender';
import { StyledSecondaryHeader, StyledView } from './styledComponents';

const PaymentHistoryScreen = ({
  dispatchFetchTransactions,
  dispatchSecureMessagingChangeFormFieldValue,
  dispatchSetGroupedTransactions,
  dispatchUpdateSelectedTransaction,
  flatListTransactions,
  itemsPerPage,
  loading: { transactions: transactionsLoading },
  navigation,
  notifications,
  serviceError: { transactions: serviceError },
  totalPages,
}) => {
  const isInitialRender = totalPages === -1 && !serviceError;

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <StyledSecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Payment History"
      />
      <StyledView>
        <AsyncRender
          Component={PaymentHistoryList}
          error={serviceError}
          errorComponentType="cardLevel"
          loading={isInitialRender}
          LoadingComponent={PaymentHistoryListSkeleton}
          loadingComponentProps={{ navigation }}
          propsToPassDown={{
            dispatchFetchTransactions,
            dispatchSetGroupedTransactions,
            dispatchUpdateSelectedTransaction,
            flatListTransactions,
            itemsPerPage,
            navigation,
            scrollLoading: !isInitialRender && transactionsLoading,
          }}
        />
        <SecureMessagingBanner
          dispatchSecureMessagingChangeFormFieldValue={dispatchSecureMessagingChangeFormFieldValue}
          navigation={navigation}
          screen="history"
        />
      </StyledView>
    </Fragment>
  );
};

PaymentHistoryScreen.propTypes = {
  dispatchFetchTransactions: T.func.isRequired,
  dispatchSecureMessagingChangeFormFieldValue: T.func.isRequired,
  dispatchSetGroupedTransactions: T.func.isRequired,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  flatListTransactions: T.array.isRequired,
  itemsPerPage: T.number.isRequired,
  loading: T.shape({ transactions: T.bool.isRequired }).isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  serviceError: T.oneOfType([T.bool, T.object, T.string]).isRequired,
  totalPages: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Payment History
   */
  // eslint-disable-next-line sort-keys
  flatListTransactions: makeSelectFlatListTransactions(),
  itemsPerPage: makeSelectPaymentHistory('itemsPerPage'),
  loading: makeSelectPaymentHistory('loading'),
  notifications: makeSelectPaymentHistoryNotifications(),
  serviceError: makeSelectError('serviceError'),
  totalPages: makeSelectPaymentHistory('totalPages'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Payment History
  */
  dispatchFetchTransactions: () => dispatch(fetchTransactions()),
  dispatchSetGroupedTransactions: (payload) => dispatch(setGroupedTransactions(payload)),
  dispatchUpdateSelectedTransaction: (payload) => dispatch(updateSelectedTransaction(payload)),
  /**
   * Store: SecureMessaging
   */
  // eslint-disable-next-line sort-keys
  dispatchSecureMessagingChangeFormFieldValue: (payload) => dispatch(changeFormFieldValue(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(PaymentHistoryScreen);
