import React, { Fragment } from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { ButtonText, StyledH5 } from '../styledComponents';
import { P2 } from '../../../base_ui';
import { TOOLTIP_PROPS } from './constants';

const HardshipTooltip = ({ handleCloseTooltip, selectedTooltip }) => {
  const { content, linkText, title } = TOOLTIP_PROPS[selectedTooltip];

  return (
    <Fragment>
      <StyledH5>{title}</StyledH5>
      <P2>{content}</P2>
      <Pressable onPress={handleCloseTooltip}>
        <ButtonText>{linkText}</ButtonText>
      </Pressable>
    </Fragment>
  );
};

HardshipTooltip.propTypes = {
  handleCloseTooltip: T.func.isRequired,
  selectedTooltip: T.string.isRequired,
};

export default HardshipTooltip;
