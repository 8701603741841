import React from 'react';
import T from 'prop-types';
import { camelCase } from 'lodash';

import {
  EscrowCardButtonContainer,
  EscrowCardContainer,
  StyledCardButton,
} from './styledComponents';
import Layout from '../../../../constants/Layout';

const { isTablet } = Layout;

const MortgageCardSection = ({
  dispatchSetNestedScreen,
  marketingCards,
  navigation,
}) => {
  /**
   * Notes/Business Rules:
   * There should always be atleast two cards displayed.
   * 1. If the current loan is not enrolled in escrow, the card should take first position.
   * 2. If the current loan has expired policies and heloc draw is avaialble, the view needs an
   *    entry point for heloc draw (e.g. the header will display the expired policies). When the
   *    current loan does not have expired policies, the heloc draw entry point will be displayed in
   *    the header.
   * 3. The amortization card and the glossary card will fill the rest of the display array if the
   *    two cases are not filled.
   */

  const handleNavigateLink = (screen, nestedScreen) => {
    if (nestedScreen) {
      dispatchSetNestedScreen({ mainScreen: camelCase(screen), nestedScreen });
      navigation.navigate(screen);
    } else {
      navigation.navigate(screen);
    }
  };

  return (
    <EscrowCardContainer>
      {marketingCards.map(({
        header,
        nestedScreen,
        screen,
        ...restCardProps
      }) => (
        <EscrowCardButtonContainer key={`${screen}-${header}`}>
          <StyledCardButton
            $center={isTablet}
            header={header}
            onPress={() => handleNavigateLink(screen, nestedScreen)}
            {...restCardProps}
          />
        </EscrowCardButtonContainer>
      ))}
    </EscrowCardContainer>
  );
};

MortgageCardSection.propTypes = {
  dispatchSetNestedScreen: T.func.isRequired,
  marketingCards: T.array.isRequired,
  navigation: T.object.isRequired,
};

export default MortgageCardSection;
