/*
* Loan Number Form Input
*/

import React, { Fragment, useEffect, useRef } from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Register/Main/messages';
import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import { LoginRegisterTextField } from '../../base_ui';
import {
  RegisterInputContainer,
  TextFieldWrapper,
} from './styledComponents';
import ButtonFooter from './ButtonFooter';

const LoanNumberFormInput = ({
  dispatchChangeInput,
  formErrors,
  formValues,
  handleBack,
  handleNextClick,
  loanNumberQueryParam,
}) => {
  const { formatMessage } = useIntl();
  const loanNumberInputRef = useRef(null);

  useEffect(() => {
    if (loanNumberQueryParam) {
      dispatchChangeInput({
        field: 'loanNumber',
        form: 'initial',
        value: loanNumberQueryParam,
      });
      // Slightly delay focus to allow the loan number field value to be updated
      // or else the animated label positioning is off.
      setTimeout(() => loanNumberInputRef.current.focus(), 100);
    }
  }, [dispatchChangeInput, loanNumberInputRef, loanNumberQueryParam]);

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={loanNumberInputRef}
            error={formErrors.loanNumber}
            keyboardType="number-pad"
            label={formatMessage({ id: INTL_IDS.REGISTER_FORM_LOAN_NUMBER_LABEL })}
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'loanNumber',
              form: 'initial',
              value: newValue,
            })}
            onSubmitEditing={() => handleNextClick({
              field: 'loanNumber',
              form: 'initial',
              formValues,
            })}
            returnKeyType="done"
            value={formValues.loanNumber}
          />
        </TextFieldWrapper>
      </RegisterInputContainer>
      <ButtonFooter
        disabled={!formValues.loanNumber}
        handleBack={handleBack}
        handleNextClick={() => handleNextClick({
          field: 'loanNumber',
          form: 'initial',
          formValues: trimFormValues(formValues),
        })}
        label="Next Step"
      />
    </Fragment>
  );
};

LoanNumberFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  loanNumberQueryParam: T.string.isRequired,
};

export default LoanNumberFormInput;
