import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import moment from 'moment';

import { ADJUSTMENT_FILTER_DICTIONARY } from '@dmi/shared/redux/Calculators/Amortize/constants';

import {
  Calendar,
  P3,
} from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import { FooterSummary } from '../Shared/FooterSummary';
import {
  CalendarContainer,
  CircleContainer,
  ClosedCircle,
} from './styledComponents';

const CalendarView = ({
  calendarProps: { maxDate, minDate },
  handleUpdateSelectedDate,
  navigation,
  selectedAdjustmentFilter,
  selectedDate,
}) => {
  const [date, setDate] = useState(moment(selectedDate).format('YYYY-MM-DD'));
  const [isCurrentMonth, setIsCurrentMonth] = useState(true);
  const [isFinalMonth, setIsFinalMonth] = useState(false);

  const { calendarSubText } = ADJUSTMENT_FILTER_DICTIONARY[selectedAdjustmentFilter];

  const handleChangeMonth = ({ month, year }) => {
    const newMonth = moment().set('month', month - 1).set('year', year);
    setIsCurrentMonth(newMonth.isSame(minDate, 'month') && newMonth.isSame(minDate, 'year'));
    setIsFinalMonth(newMonth.isSame(maxDate, 'month') && newMonth.isSame(maxDate, 'year'));
  };

  const handlePress = ({ dateString }) => {
    setDate(dateString);
  };

  const handleSubmit = () => {
    handleUpdateSelectedDate(`${date}T00:00:00`);
    navigation.pop();
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Select Date"
      />
      <CalendarContainer>
        <Calendar
          handleChangeMonth={handleChangeMonth}
          handlePress={handlePress}
          isCurrentMonth={isCurrentMonth}
          isFinalMonth={isFinalMonth}
          maxDate={maxDate}
          minDate={minDate}
          selected={date}
        />
        <CircleContainer>
          <ClosedCircle />
          <P3>Payment will be scheduled for selected date</P3>
        </CircleContainer>
      </CalendarContainer>
      <FooterSummary
        label="Select Date"
        onPress={handleSubmit}
        subText={calendarSubText}
        text={moment(date).format('MMM D, YYYY')}
      />
    </Fragment>
  );
};

CalendarView.propTypes = {
  calendarProps: T.object.isRequired,
  handleUpdateSelectedDate: T.func.isRequired,
  navigation: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
  selectedDate: T.string.isRequired,
};

export default CalendarView;
