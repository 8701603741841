/* eslint-disable no-mixed-spaces-and-tabs, max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

const MarketingWinter = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      height={moderateScale(120)}
      viewBox="0 0 287.25 120.25"
      width={moderateScale(287)}
      {...props}
    >
      <G fill="#000000">
        <Path
          d="M18.432,116.625c0,0-14.903-3.053-14.903-23.477c0-25.111,27.585-31.065,38.993-46.955
		C59.671,22.306,67.329,14.457,89.581,7.516s55.017-4.808,72.574,3.154c7.82,3.546,23.273,11.841,39.197,13.066
		c15.924,1.225,55.325,6.533,72.27,27.152c17.213,20.946,11.637,60.021-21.844,65.533
		C251.778,116.421,18.126,116.318,18.432,116.625z"
          fill="#E6EBEF"
        />
        <Polygon
          fill="#FFFFFF"
          points="137.271,4.924 112.277,24.685 112.277,103.728 98.483,103.728 98.483,113.853 165.739,114.342
		165.69,20.527 143.63,5.119 	"
        />
        <Polygon fill="#FFFFFF" points="273.445,113.413 220.864,113.413 239.548,116.934 258.282,117.179 	" />
        <Rect fill="#FFFFFF" height="2.446" width="118.956" x="68.451" y="114.636" />
        <Path
          d="M238.57,116.788c0,0-21.971-4.105-28.2-4.752c-6.229-0.647-12.238-1.43-20.465,1.052
		c-10.703,3.229-17.465,3.407-17.465,3.407"
          fill="#FFFFFF"
        />
        <Path
          d="M80.679,115.712c0,0-24.45-4.105-30.678-4.752s-12.238-1.43-20.465,1.052
		c-10.703,3.229-17.465,3.407-17.465,3.407v2.837h67.956L80.679,115.712z"
          fill="#FFFFFF"
        />
        <G>
          <Path
            d="M99.983,114.469H83.606v-0.026c-0.147,0.021-0.295,0.036-0.448,0.036c-1.72,0-3.114-1.394-3.114-3.114
			c0-1.289,0.783-2.395,1.9-2.868c0.139-1.979,1.784-3.542,3.798-3.542c0.494,0,0.964,0.097,1.397,0.268
			c0.11-2.749,2.368-4.946,5.144-4.946c1.321,0,2.521,0.501,3.433,1.318c0,0,1.47-2.757,5.652-2.562"
            fill="#E6EBEF"
          />
          <Path
            d="M83.159,114.729c-1.855,0-3.364-1.509-3.364-3.364c0-1.304,0.747-2.479,1.915-3.036c0.22-2.045,1.963-3.625,4.034-3.625
			c0.397,0,0.789,0.059,1.17,0.174c0.277-2.746,2.575-4.852,5.371-4.852c1.229,0,2.417,0.421,3.375,1.19
			c0.495-0.709,2.154-2.599,5.722-2.435c0.138,0.007,0.245,0.124,0.238,0.262c-0.006,0.138-0.121,0.237-0.261,0.238
			c-3.963-0.19-5.363,2.323-5.42,2.43c-0.036,0.067-0.102,0.114-0.177,0.128c-0.073,0.01-0.152-0.009-0.21-0.06
			c-0.902-0.809-2.062-1.254-3.266-1.254c-2.639,0-4.789,2.066-4.894,4.705c-0.003,0.081-0.046,0.155-0.114,0.199
			c-0.068,0.045-0.152,0.053-0.228,0.023c-0.42-0.166-0.859-0.25-1.305-0.25c-1.86,0-3.419,1.454-3.549,3.311
			c-0.007,0.094-0.065,0.176-0.152,0.213c-1.062,0.449-1.748,1.484-1.748,2.638c0,1.579,1.285,2.864,2.864,2.864
			c0.14,0,0.276-0.014,0.411-0.033c0.048-0.008,0.102,0,0.148,0.023h16.266c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25
			H83.606c-0.028,0-0.056-0.005-0.081-0.014C83.405,114.721,83.283,114.729,83.159,114.729z"
          />
        </G>
        <Path
          d="M82.385,110.77c-0.001,0-0.002,0-0.004,0c-0.083-0.002-0.148-0.07-0.146-0.153c0.001-0.043,0.035-1.061,0.792-1.706
		c0.501-0.428,1.192-0.586,2.055-0.47c0.082,0.011,0.14,0.086,0.128,0.168s-0.081,0.142-0.168,0.129
		c-0.772-0.103-1.385,0.03-1.818,0.398c-0.655,0.558-0.688,1.479-0.688,1.488C82.532,110.706,82.466,110.77,82.385,110.77z"
        />
        <Circle cx="103.913" cy="67.913" fill="#FFFFFF" r="0.702" />
        <Path
          d="M167.555,103.431h-55.329c-0.138,0-0.25-0.112-0.25-0.25V26.389c0-0.077,0.036-0.15,0.097-0.197l26.154-20.344
		c0.04-0.031,0.088-0.049,0.138-0.052l17.61-1.068c0.058-0.002,0.112,0.013,0.159,0.045l11.565,8.179
		c0.066,0.047,0.105,0.123,0.105,0.204v90.026C167.805,103.319,167.692,103.431,167.555,103.431z M112.476,102.931h54.829V13.285
		l-11.388-8.054l-17.445,1.058l-25.996,20.222V102.931z"
        />
        <Rect fill="#E6EBEF" height="2.579" width="8.127" x="145.717" y="75.918" />
        <Rect fill="#E6EBEF" height="11.071" width="14.015" x="156.833" y="103.425" />
        <Rect fill="#BAC2CA" height="2.449" width="53.245" x="112.133" y="66.409" />
        <Rect fill="#E6EBEF" height="13.481" width="8.237" x="121.819" y="36.237" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="121.048,46.594 125.289,36.237 121.487,36.237 121.048,37.308 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="126.331,49.718 123.965,49.718 129.486,36.237 131.852,36.237 	" />
        <Rect fill="#BAC2CA" height="78.103" width="17.737" x="165.622" y="25.485" />
        <Path
          d="M170.173,31.545h13.249V30.36l-13.595-0.169l-4.162-3.163l-0.056,1.637l3.395,2.447
		C169.313,31.389,169.734,31.545,170.173,31.545z"
          fill="#BAC2CA"
        />
        <Path
          d="M187.885,30.251h-16.467v-2.032h16.467c0.561,0,1.016,0.455,1.016,1.016v0
		C188.9,29.796,188.446,30.251,187.885,30.251z"
          fill="#FFFFFF"
        />
        <Path
          d="M188.019,30.578h-17.7c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h17.7c0.207,0,0.375,0.168,0.375,0.375
		S188.226,30.578,188.019,30.578z"
        />
        <G>
          <Polygon fill="#FFFFFF" points="188.213,28.182 155.573,3.388 138.514,3.388 171.21,28.218 168.442,28.218 		" />
          <Path
            d="M138.942,3.838c-0.22-0.215-0.542-0.336-0.859-0.331c-0.327,0.005-0.635,0.134-0.847,0.355l-30.825,24.286
			c-0.407,0.425-0.35,1.062,0.127,1.424c0.478,0.362,1.196,0.311,1.603-0.113l29.987-23.413l31.484,23.813
			c0.224,0.219,0.532,0.331,0.842,0.331c0.273,0,0.547-0.087,0.764-0.263c0.465-0.375,0.499-1.014,0.077-1.427L138.942,3.838z"
            fill="#FFFFFF"
          />
          <Polygon fill="#BAC2CA" points="112.145,26.326 112.145,29.888 107.742,29.845 		" />
          <Path
            d="M111.996,30.175h-4.437c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h4.437c0.138,0,0.25,0.112,0.25,0.25
			S112.134,30.175,111.996,30.175z"
          />
          <Polygon fill="#BAC2CA" points="139.307,7.159 112.417,28.004 112.417,26.084 138.096,6.231 		" />
          <Path
            d="M188.219,28.469h-17.263c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h17.263c0.138,0,0.25,0.112,0.25,0.25
			S188.356,28.469,188.219,28.469z"
          />
          <Path
            d="M188.219,28.594c-0.079,0-0.159-0.025-0.227-0.077L155.447,3.764l-17.386,0.04c-0.001,0-0.001,0-0.002,0
			c-0.206,0-0.374-0.167-0.375-0.373c-0.001-0.207,0.166-0.376,0.373-0.377l10.25-0.04h7.267c0.082,0,0.162,0.027,0.227,0.077
			l32.646,24.83c0.165,0.125,0.197,0.36,0.072,0.525C188.443,28.543,188.332,28.594,188.219,28.594z"
          />
          <Path
            d="M170.454,30.565c-0.416,0-0.818-0.16-1.104-0.438L138.132,6.519l-29.76,23.235c-0.503,0.53-1.426,0.597-2.06,0.116
			c-0.321-0.242-0.514-0.585-0.544-0.966c-0.03-0.374,0.102-0.734,0.372-1.016l10.054-7.926l0,0l20.809-16.395
			c0.243-0.258,0.648-0.429,1.074-0.435c0.409,0.014,0.84,0.157,1.126,0.438l32.318,24.632c0.314,0.304,0.459,0.662,0.441,1.038
			c-0.019,0.38-0.199,0.728-0.511,0.979C171.177,30.442,170.822,30.565,170.454,30.565z M138.128,5.671
			c0.08,0,0.159,0.025,0.226,0.076l31.484,23.812c0.343,0.327,0.838,0.322,1.144,0.076c0.142-0.115,0.225-0.268,0.232-0.431
			c0.007-0.159-0.058-0.313-0.182-0.436L138.714,4.137c-0.179-0.172-0.424-0.244-0.625-0.254c-0.228,0.003-0.439,0.09-0.583,0.239
			l-30.863,24.321c-0.081,0.09-0.14,0.245-0.127,0.403c0.013,0.164,0.101,0.316,0.249,0.428c0.326,0.245,0.832,0.212,1.106-0.074
			l30.027-23.449C137.965,5.698,138.047,5.671,138.128,5.671z"
          />
        </G>
        <Path
          d="M112.228,103.477c-0.083,0-0.15-0.066-0.15-0.149v-14.51c0-0.083,0.067-0.149,0.15-0.149s0.15,0.066,0.15,0.149v14.51
		C112.378,103.411,112.31,103.477,112.228,103.477z"
        />
        <Path
          d="M112.228,88.657c-0.207,0-0.375-0.168-0.375-0.375V26.46c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v61.822
		C112.603,88.489,112.435,88.657,112.228,88.657z"
        />
        <Path
          d="M165.552,103.676c-0.138,0-0.25-0.112-0.25-0.25V83.747c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v19.679
		C165.802,103.563,165.689,103.676,165.552,103.676z"
        />
        <Path
          d="M165.552,82.422c-0.138,0-0.25-0.112-0.25-0.25V26.866c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v55.306
		C165.802,82.309,165.689,82.422,165.552,82.422z"
        />
        <Path
          d="M183.3,103.801c-0.207,0-0.375-0.168-0.375-0.375V63.897c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v39.529
		C183.675,103.633,183.507,103.801,183.3,103.801z"
        />
        <Path
          d="M183.3,63.203c-0.207,0-0.375-0.168-0.375-0.375v-32.47c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v32.47
		C183.675,63.035,183.507,63.203,183.3,63.203z"
        />
        <Path
          d="M187.788,30.58h-1.473c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.473c0.411,0,0.745-0.278,0.745-0.62
		c0-0.342-0.334-0.621-0.745-0.621c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375c0.824,0,1.495,0.615,1.495,1.371
		C189.283,29.965,188.612,30.58,187.788,30.58z"
        />
        <G>
          <Line fill="#FFFFFF" x1="119.101" x2="119.101" y1="50.682" y2="34.82" />
          <Path
            d="M119.101,50.931c-0.138,0-0.25-0.112-0.25-0.25V34.82c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
			C119.351,50.82,119.239,50.931,119.101,50.931z"
          />
        </G>
        <G>
          <Line fill="#FFFFFF" x1="132.64" x2="132.64" y1="34.82" y2="50.682" />
          <Path
            d="M132.64,50.931c-0.138,0-0.25-0.112-0.25-0.25V34.82c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
			C132.89,50.82,132.778,50.931,132.64,50.931z"
          />
        </G>
        <Path
          d="M133.605,35.077h-0.951c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.701V33.38h-14.882v1.198h0.657
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.907c-0.138,0-0.25-0.112-0.25-0.25V33.13c0-0.138,0.112-0.25,0.25-0.25
		h15.382c0.138,0,0.25,0.112,0.25,0.25v1.698C133.855,34.966,133.743,35.077,133.605,35.077z"
        />
        <Path
          d="M133.605,52.759h-3.624c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h3.374v-1.198h-0.701
		c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.951c0.138,0,0.25,0.112,0.25,0.25v1.698
		C133.855,52.647,133.743,52.759,133.605,52.759z"
        />
        <Path
          d="M128.913,52.759h-10.69c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25h0.907
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.657v1.198h10.44c0.138,0,0.25,0.112,0.25,0.25
		S129.051,52.759,128.913,52.759z"
        />
        <Rect fill={svgHighlight} height="6.868" width="8.228" x="121.829" y="42.792" />
        <Path
          d="M130.094,49.949h-8.284c-0.138,0-0.25-0.112-0.25-0.25V36.241c0-0.138,0.112-0.25,0.25-0.25h8.284
		c0.138,0,0.25,0.112,0.25,0.25v13.458C130.344,49.837,130.232,49.949,130.094,49.949z M122.06,49.449h7.784V36.491h-7.784V49.449z"
        />
        <Path
          d="M130.094,42.867h-8.284c-0.083,0-0.15-0.067-0.15-0.15s0.067-0.15,0.15-0.15h8.284c0.083,0,0.15,0.067,0.15,0.15
		S130.177,42.867,130.094,42.867z"
        />
        <Path
          d="M126.009,42.809c-0.083,0-0.15-0.067-0.15-0.15v-6.438c0-0.083,0.067-0.15,0.15-0.15s0.15,0.067,0.15,0.15v6.438
		C126.159,42.743,126.092,42.809,126.009,42.809z"
        />
        <Rect fill="#E6EBEF" height="13.481" width="8.237" x="145.732" y="36.08" />
        <Rect fill="#E6EBEF" height="14.734" width="8.784" x="121.481" y="74.971" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="144.961,46.438 149.202,36.08 145.4,36.08 144.961,37.152 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="150.245,49.562 147.879,49.562 153.399,36.08 155.765,36.08 	" />
        <Rect fill="#E6EBEF" height="10.977" width="7.593" x="146.136" y="81" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="146.154,89.434 149.607,81 147.292,81 146.115,83.713 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="149.811,91.977 147.884,91.977 152.38,81 154.306,81 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="120.95,87.464 126.304,74.399 122.38,74.399 120.95,77.881 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="126.01,91.664 123.569,91.664 130.635,74.399 133.076,74.399 	" />
        <Path
          d="M153.955,49.949h-8.284c-0.138,0-0.25-0.112-0.25-0.25V36.241c0-0.138,0.112-0.25,0.25-0.25h8.284
		c0.138,0,0.25,0.112,0.25,0.25v13.458C154.205,49.837,154.093,49.949,153.955,49.949z M145.921,49.449h7.784V36.491h-7.784V49.449z
		"
        />
        <Path
          d="M153.955,42.867h-8.284c-0.083,0-0.15-0.067-0.15-0.15s0.067-0.15,0.15-0.15h8.284c0.083,0,0.15,0.067,0.15,0.15
		S154.038,42.867,153.955,42.867z"
        />
        <Path
          d="M149.87,42.809c-0.083,0-0.15-0.067-0.15-0.15v-6.438c0-0.083,0.067-0.15,0.15-0.15c0.083,0,0.15,0.067,0.15,0.15v6.438
		C150.02,42.743,149.953,42.809,149.87,42.809z"
        />
        <Path
          d="M119.101,92.385c-0.138,0-0.25-0.112-0.25-0.25V73.877c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v18.258
		C119.351,92.272,119.239,92.385,119.101,92.385z"
        />
        <G>
          <Line fill="#FFFFFF" x1="132.64" x2="132.64" y1="73.877" y2="92.135" />
          <Path
            d="M132.64,92.284c-0.083,0-0.15-0.066-0.15-0.149V73.877c0-0.083,0.067-0.15,0.15-0.15s0.15,0.067,0.15,0.15v18.258
			C132.79,92.218,132.723,92.284,132.64,92.284z"
          />
        </G>
        <Path
          d="M119.13,74.134h-0.907c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25h14.01
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-13.76v1.198h0.657c0.138,0,0.25,0.112,0.25,0.25
		S119.268,74.134,119.13,74.134z"
        />
        <Path
          d="M141.887,92.818c-0.138,0-0.25-0.112-0.25-0.25V73.877c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v18.691
		C142.137,92.706,142.025,92.818,141.887,92.818z"
        />
        <G>
          <Line fill="#FFFFFF" x1="141.887" x2="141.887" y1="103.275" y2="93.842" />
          <Path
            d="M141.887,103.425c-0.083,0-0.15-0.066-0.15-0.149v-9.434c0-0.083,0.067-0.149,0.15-0.149s0.15,0.066,0.15,0.149v9.434
			C142.037,103.358,141.969,103.425,141.887,103.425z"
          />
        </G>
        <G>
          <Line fill="#FFFFFF" x1="157.05" x2="157.05" y1="73.877" y2="103.275" />
          <Path
            d="M157.051,103.425c-0.083,0-0.149-0.066-0.149-0.149V73.877c0-0.083,0.066-0.15,0.149-0.15s0.149,0.067,0.149,0.15v29.398
			C157.2,103.358,157.134,103.425,157.051,103.425z"
          />
        </G>
        <Path
          d="M158.016,74.134h-0.95c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.7v-1.198h-16.507v1.198h0.657
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.907c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25
		h17.007c0.138,0,0.25,0.112,0.25,0.25v1.698C158.266,74.022,158.153,74.134,158.016,74.134z"
        />
        <Path
          d="M133.605,94.112h-15.077c-0.083,0-0.15-0.066-0.15-0.149v-1.698c0-0.083,0.067-0.149,0.15-0.149h0.603
		c0.083,0,0.15,0.066,0.15,0.149s-0.067,0.149-0.15,0.149h-0.453v1.399h14.777v-1.399h-0.801c-0.083,0-0.15-0.066-0.15-0.149
		s0.067-0.149,0.15-0.149h0.951c0.083,0,0.15,0.066,0.15,0.149v1.698C133.755,94.046,133.687,94.112,133.605,94.112z"
        />
        <Path
          d="M130.226,90.023h-8.671c-0.138,0-0.25-0.112-0.25-0.25V75.028c0-0.138,0.112-0.25,0.25-0.25h8.671
		c0.138,0,0.25,0.112,0.25,0.25v14.745C130.476,89.911,130.365,90.023,130.226,90.023z M121.805,89.523h8.171V75.278h-8.171V89.523z
		"
        />
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="125.991" y="89.359" />
          <Path
            d="M127.111,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C127.361,103.107,127.249,103.22,127.111,103.22z M126.241,102.72h0.62V89.608h-0.62V102.72z
			"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.936" width="1.36" x="161.782" y="89.359" />
          <Path
            d="M163.143,103.57h-1.36c-0.152,0-0.275-0.123-0.275-0.275V89.358c0-0.152,0.123-0.275,0.275-0.275h1.36
			c0.152,0,0.275,0.123,0.275,0.275v13.937C163.418,103.447,163.295,103.57,163.143,103.57z M162.058,103.019h0.81V89.634h-0.81
			V103.019z"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="129.716" y="89.359" />
          <Path
            d="M130.836,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C131.086,103.107,130.974,103.22,130.836,103.22z M129.966,102.72h0.62V89.608h-0.62V102.72z
			"
          />
        </G>
        <Path
          d="M155.712,103.539h-11.67c-0.138,0-0.25-0.112-0.25-0.25V74.324c0-0.138,0.112-0.25,0.25-0.25h11.67
		c0.138,0,0.25,0.112,0.25,0.25v28.965C155.962,103.427,155.85,103.539,155.712,103.539z M144.292,103.039h11.17V74.574h-11.17
		V103.039z"
        />
        <Path
          d="M130.211,82.902h-8.635c-0.069,0-0.125-0.056-0.125-0.125s0.056-0.125,0.125-0.125h8.635c0.069,0,0.125,0.056,0.125,0.125
		S130.28,82.902,130.211,82.902z"
        />
        <Path
          d="M125.826,82.844c-0.069,0-0.125-0.056-0.125-0.125v-7.642c0-0.069,0.056-0.125,0.125-0.125s0.125,0.056,0.125,0.125v7.642
		C125.951,82.788,125.894,82.844,125.826,82.844z"
        />
        <G>
          <Line fill="#FFFFFF" x1="142.887" x2="142.887" y1="50.682" y2="34.82" />
          <Path
            d="M142.887,50.931c-0.138,0-0.25-0.112-0.25-0.25V34.82c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
			C143.137,50.82,143.025,50.931,142.887,50.931z"
          />
        </G>
        <G>
          <Line fill="#FFFFFF" x1="156.426" x2="156.426" y1="34.82" y2="50.682" />
          <Path
            d="M156.427,50.931c-0.138,0-0.25-0.112-0.25-0.25V34.82c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
			C156.677,50.82,156.564,50.931,156.427,50.931z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="152.689,33.13 157.391,33.13 157.391,34.827 156.441,34.827 		" />
          <Path
            d="M157.392,35.077h-0.951c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.701V33.38h-4.452
			c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h4.702c0.138,0,0.25,0.112,0.25,0.25v1.698
			C157.642,34.966,157.529,35.077,157.392,35.077z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="142.917,34.827 142.009,34.827 142.009,33.13 151.509,33.13 		" />
          <Path
            d="M142.917,35.077h-0.908c-0.138,0-0.25-0.112-0.25-0.25V33.13c0-0.138,0.112-0.25,0.25-0.25h9.5
			c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-9.25v1.198h0.658c0.138,0,0.25,0.112,0.25,0.25
			S143.055,35.077,142.917,35.077z"
          />
        </G>
        <G>
          <Polyline
            fill="#FFFFFF"
            points="142.917,50.811 142.009,50.811 142.009,52.509 157.391,52.509 157.391,50.811 156.441,50.811
			"
          />
          <Path
            d="M157.392,52.759h-15.382c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25h0.908
			c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.658v1.198h14.882v-1.198h-0.701c-0.138,0-0.25-0.112-0.25-0.25
			s0.112-0.25,0.25-0.25h0.951c0.138,0,0.25,0.112,0.25,0.25v1.698C157.642,52.647,157.529,52.759,157.392,52.759z"
          />
        </G>
        <G>
          <Polygon fill="#FFFFFF" points="182.995,103.364 98.219,103.364 98.219,114.477 183.312,114.477 183.312,103.37 		" />
          <Path
            d="M183.312,114.851H98.219c-0.207,0-0.375-0.168-0.375-0.375v-11.112c0-0.207,0.168-0.375,0.375-0.375h84.776
			c0.002,0,0.005,0,0.007,0l0.317,0.006c0.204,0.004,0.368,0.171,0.368,0.375v11.106
			C183.687,114.683,183.519,114.851,183.312,114.851z M98.594,114.101h84.344v-10.362H98.594V114.101z"
          />
        </G>
        <Path
          d="M153.891,78.763h-8.173c-0.097,0-0.175-0.078-0.175-0.175v-2.657c0-0.097,0.078-0.175,0.175-0.175h8.173
		c0.097,0,0.175,0.078,0.175,0.175v2.657C154.065,78.685,153.987,78.763,153.891,78.763z M145.892,78.413h7.824v-2.308h-7.824
		V78.413z"
        />
        <Path
          d="M153.725,92.124h-7.629c-0.097,0-0.175-0.078-0.175-0.175v-11.03c0-0.097,0.078-0.175,0.175-0.175h7.629
		c0.097,0,0.175,0.078,0.175,0.175v11.03C153.899,92.046,153.821,92.124,153.725,92.124z M146.27,91.774h7.28v-10.68h-7.28V91.774z"
        />
        <G>
          <Polygon
            fill="#FFFFFF"
            points="101.452,95.743 101.452,67.655 104.787,67.655 104.787,102.136 101.452,102.136 101.452,97.033
			"
          />
          <Path
            d="M104.787,102.511h-3.334c-0.207,0-0.375-0.168-0.375-0.375V67.655c0-0.207,0.168-0.375,0.375-0.375h3.334
			c0.207,0,0.375,0.168,0.375,0.375v34.481C105.162,102.343,104.994,102.511,104.787,102.511z M101.828,101.761h2.584V68.03h-2.584
			V101.761z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="99.785,64.742 99.785,66.409 154.554,66.409 154.554,64.371 		" />
          <Path
            d="M154.554,66.784H99.785c-0.207,0-0.375-0.168-0.375-0.375v-1.667c0-0.207,0.168-0.375,0.375-0.375
			c0.207,0,0.375,0.168,0.375,0.375v1.292h54.019v-1.663c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.038
			C154.929,66.616,154.761,66.784,154.554,66.784z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="165.699,58.121 152.147,63.133 98.655,63.025 111.806,58.121 		" />
          <G>
            <Polyline fill="#FFFFFF" points="98.331,63.072 98.796,63.072 152.076,63.135 			" />
            <Path
              d="M152.076,63.385L152.076,63.385L98.33,63.322c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.466l53.28,0.063
				c0.139,0,0.25,0.112,0.25,0.25C152.326,63.273,152.214,63.385,152.076,63.385z"
            />
          </G>
          <Path
            d="M99.82,65.119h-1.49c-0.693,0-1.257-0.543-1.257-1.211s0.564-1.211,1.257-1.211c0.207,0,0.375,0.168,0.375,0.375
			s-0.168,0.375-0.375,0.375c-0.279,0-0.507,0.207-0.507,0.461s0.228,0.461,0.507,0.461h1.49c0.207,0,0.375,0.168,0.375,0.375
			S100.027,65.119,99.82,65.119z"
          />
          <G>
            <Line fill="#FFFFFF" x1="129.307" x2="99.82" y1="64.745" y2="64.745" />
            <Path
              d="M129.307,64.994H99.82c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h29.486c0.138,0,0.25,0.112,0.25,0.25
				S129.445,64.994,129.307,64.994z"
            />
          </G>
          <G>
            <Line fill="#FFFFFF" x1="152.424" x2="130.656" y1="64.745" y2="64.745" />
            <Path
              d="M152.425,64.994h-21.769c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h21.769c0.138,0,0.25,0.112,0.25,0.25
				S152.562,64.994,152.425,64.994z"
            />
          </G>
          <Path
            d="M153.203,62.568l1.511,1.015c0,0-1.304,1.038-1.858,1.06s-1.291-0.392-0.738-1.305
			C152.476,62.746,153.203,62.568,153.203,62.568z"
            fill="#FFFFFF"
          />
          <Path
            d="M164.46,60.823c-0.062,0-0.119-0.038-0.141-0.099c-0.028-0.078,0.012-0.164,0.09-0.192l0.952-0.346l0.018-2.145
			c0-0.082,0.067-0.148,0.149-0.148h0.001c0.083,0.001,0.149,0.068,0.148,0.151l-0.019,2.249c0,0.062-0.039,0.118-0.099,0.139
			l-1.05,0.381C164.494,60.82,164.476,60.823,164.46,60.823z"
          />
          <Path
            d="M152.767,65.034c-0.062,0-0.12-0.003-0.172-0.011c-0.261,0.007-0.412-0.121-0.571-0.251l-0.05-0.041
			c-0.278-0.225-0.419-0.682-0.342-1.112c0.074-0.416,0.332-0.727,0.707-0.853l13.101-4.957c0.13-0.048,0.274,0.016,0.322,0.146
			c0.049,0.129-0.016,0.273-0.145,0.322l-13.109,4.959c-0.278,0.094-0.359,0.335-0.384,0.471c-0.05,0.275,0.045,0.539,0.164,0.635
			l0.052,0.042c0.145,0.119,0.176,0.141,0.289,0.141c0.074,0.009,0.146,0.012,0.267,0.002c0.356-0.03,0.71-0.154,1.28-0.389
			l11.232-4.097c0.129-0.046,0.273,0.02,0.321,0.149c0.047,0.129-0.02,0.273-0.149,0.32l-11.224,4.094
			c-0.518,0.213-0.958,0.381-1.418,0.42C152.877,65.032,152.82,65.034,152.767,65.034z"
          />
          <Path
            d="M98.123,63.462c-0.152,0-0.296-0.094-0.352-0.245c-0.072-0.194,0.027-0.41,0.222-0.481l13.752-5.077
			c0.042-0.016,0.085-0.023,0.13-0.023l0,0l53.521,0.033c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375l0,0
			l-53.455-0.033l-13.689,5.054C98.21,63.455,98.166,63.462,98.123,63.462z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="100.84,66.755 100.84,67.81 105.36,67.81 105.36,66.742 		" />
          <Path
            d="M105.36,68.059h-4.52c-0.138,0-0.25-0.112-0.25-0.25v-1.055c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.806
			h4.021v-0.819c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.068C105.609,67.948,105.497,68.059,105.36,68.059z"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="34.602" width="3.334" x="132.399" y="67.547" />
          <Path
            d="M135.733,102.399h-3.334c-0.138,0-0.25-0.112-0.25-0.25V67.547c0-0.138,0.112-0.25,0.25-0.25h3.334
			c0.138,0,0.25,0.112,0.25,0.25v34.603C135.983,102.287,135.872,102.399,135.733,102.399z M132.649,101.899h2.834V67.797h-2.834
			V101.899z"
          />
        </G>
        <G>
          <Line fill="#FFFFFF" x1="154.554" x2="165.413" y1="66.409" y2="66.409" />
          <Path
            d="M165.413,66.784h-10.859c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h10.859
			c0.207,0,0.375,0.168,0.375,0.375S165.62,66.784,165.413,66.784z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="100.202,103.006 100.202,101.945 106.063,101.945 106.063,102.99 		" />
          <Path
            d="M100.202,103.256c-0.138,0-0.25-0.112-0.25-0.25v-1.061c0-0.138,0.112-0.25,0.25-0.25h5.861c0.138,0,0.25,0.112,0.25,0.25
			v1.045c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-0.795h-5.361v0.811C100.452,103.143,100.34,103.256,100.202,103.256z
			"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="130.94,103 130.94,101.906 136.801,101.906 136.801,102.984 		" />
          <Path
            d="M130.94,103.25c-0.138,0-0.25-0.112-0.25-0.25v-1.094c0-0.138,0.112-0.25,0.25-0.25h5.861c0.138,0,0.25,0.112,0.25,0.25
			v1.078c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-0.828h-5.361V103C131.19,103.138,131.079,103.25,130.94,103.25z"
          />
        </G>
        <G>
          <Polyline
            fill="#FFFFFF"
            points="159.021,77.786 159.021,102.149 155.687,102.149 155.687,67.547 159.021,67.547 159.021,76.83
			"
          />
          <Path
            d="M159.021,102.399h-3.335c-0.138,0-0.25-0.112-0.25-0.25V67.547c0-0.138,0.112-0.25,0.25-0.25h3.335
			c0.138,0,0.25,0.112,0.25,0.25v9.283c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-9.033h-2.835v34.103h2.835V77.785
			c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v24.364C159.271,102.287,159.159,102.399,159.021,102.399z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="154.228,102.99 154.228,101.955 160.089,101.955 160.089,102.974 		" />
          <Path
            d="M154.228,103.24c-0.138,0-0.25-0.112-0.25-0.25v-1.035c0-0.138,0.112-0.25,0.25-0.25h5.86c0.138,0,0.25,0.112,0.25,0.25
			v1.02c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-0.77h-5.36v0.785C154.478,103.128,154.366,103.24,154.228,103.24z"
          />
        </G>
        <G>
          <Polyline
            fill="#FFFFFF"
            points="128.361,111.338 128.361,103.087 135.74,96.724 135.759,94.966 127.197,102.358 127.197,111.689
					"
          />
          <Path
            d="M127.197,111.939c-0.138,0-0.25-0.112-0.25-0.25v-9.331c0-0.072,0.032-0.142,0.086-0.189l8.562-7.393
			c0.074-0.064,0.179-0.079,0.269-0.037c0.089,0.041,0.146,0.131,0.145,0.229l-0.02,1.759c-0.001,0.071-0.032,0.14-0.087,0.187
			l-7.292,6.287v8.137c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-8.251c0-0.072,0.032-0.142,0.087-0.189l7.293-6.288
			l0.012-1.092l-8.056,6.955v9.217C127.447,111.827,127.335,111.939,127.197,111.939z"
          />
        </G>
        <G>
          <G>
            <G>
              <Polyline
                fill="#FFFFFF"
                points="135.056,106.059 132.502,106.059 132.502,108.828 129.663,108.828 129.663,111.483
					127.209,111.483 127.209,114.366 130.58,114.366 130.966,114.366 152.027,114.366 152.027,109.124 154.507,109.124
					154.507,106.059 151.584,106.059 				"
              />
              <Path
                d="M152.027,114.616h-24.818c-0.138,0-0.25-0.112-0.25-0.25v-2.884c0-0.138,0.112-0.25,0.25-0.25h2.204v-2.404
					c0-0.138,0.112-0.25,0.25-0.25h2.588v-2.52c0-0.138,0.112-0.25,0.25-0.25h2.554c0.138,0,0.25,0.112,0.25,0.25
					s-0.112,0.25-0.25,0.25h-2.304v2.52c0,0.138-0.112,0.25-0.25,0.25h-2.588v2.404c0,0.138-0.112,0.25-0.25,0.25h-2.204v2.384
					h24.318v-4.991c0-0.138,0.112-0.25,0.25-0.25h2.229v-2.566h-2.673c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h2.923
					c0.138,0,0.25,0.112,0.25,0.25v3.066c0,0.138-0.112,0.25-0.25,0.25h-2.229v4.991
					C152.277,114.504,152.165,114.616,152.027,114.616z"
              />
            </G>
            <G>
              <Line fill="#FFFFFF" x1="131.115" x2="144.53" y1="111.425" y2="111.425" />
              <Path
                d="M144.53,111.676h-13.415c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h13.415c0.138,0,0.25,0.112,0.25,0.25
					S144.668,111.676,144.53,111.676z"
              />
            </G>
            <G>
              <Line fill="#FFFFFF" x1="132.136" x2="149.329" y1="108.808" y2="108.808" />
              <Path
                d="M149.329,109.058h-17.193c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h17.193c0.138,0,0.25,0.112,0.25,0.25
					S149.467,109.058,149.329,109.058z"
              />
            </G>
            <G>
              <Line fill="#FFFFFF" x1="151.929" x2="136.733" y1="106.059" y2="106.059" />
              <Path
                d="M151.929,106.308h-15.195c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h15.195c0.138,0,0.25,0.112,0.25,0.25
					S152.066,106.308,151.929,106.308z"
              />
            </G>
          </G>
        </G>
        <G>
          <Polyline
            fill="#FFFFFF"
            points="148.094,111.338 148.094,103.087 155.707,96.541 155.698,94.771 146.93,102.358 146.93,111.689
					"
          />
          <Path
            d="M146.93,111.939c-0.138,0-0.25-0.112-0.25-0.25v-9.331c0-0.072,0.031-0.142,0.086-0.189l8.769-7.588
			c0.075-0.064,0.18-0.076,0.268-0.038c0.089,0.04,0.146,0.129,0.146,0.227l0.009,1.771c0,0.073-0.031,0.143-0.087,0.19
			l-7.526,6.472v8.136c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-8.251c0-0.073,0.032-0.142,0.087-0.189l7.525-6.471
			l-0.005-1.111l-8.271,7.157v9.217C147.18,111.827,147.067,111.939,146.93,111.939z"
          />
        </G>
        <G>
          <Polygon
            fill="#E6EBEF"
            points="154.247,103.464 154.247,106.1 151.636,106.1 151.636,108.873 149.32,108.873 149.32,111.427
			146.919,111.427 146.919,114.444 157.282,114.454 157.282,103.464 		"
          />
          <Path
            d="M157.282,114.704L157.282,114.704l-10.363-0.01c-0.139,0-0.25-0.112-0.25-0.25v-3.017c0-0.138,0.112-0.25,0.25-0.25h2.15
			v-2.305c0-0.138,0.112-0.25,0.25-0.25h2.066v-2.523c0-0.138,0.112-0.25,0.25-0.25h2.361v-2.385c0-0.138,0.112-0.25,0.25-0.25
			h3.035c0.138,0,0.25,0.112,0.25,0.25v10.989c0,0.066-0.026,0.13-0.073,0.177S157.349,114.704,157.282,114.704z M147.169,114.194
			l9.863,0.01v-10.489h-2.535v2.385c0,0.138-0.112,0.25-0.25,0.25h-2.361v2.523c0,0.138-0.112,0.25-0.25,0.25h-2.066v2.305
			c0,0.138-0.112,0.25-0.25,0.25h-2.15V114.194z"
          />
        </G>
        <G>
          <Line fill="#FFFFFF" x1="135.03" x2="135.03" y1="103.477" y2="105.926" />
          <Path
            d="M135.03,106.176c-0.138,0-0.25-0.112-0.25-0.25v-2.448c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.448
			C135.28,106.063,135.168,106.176,135.03,106.176z"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="1.417" width="6.461" x="159.073" y="87.942" />
          <Path
            d="M165.534,89.608h-6.461c-0.138,0-0.25-0.112-0.25-0.25v-1.416c0-0.138,0.112-0.25,0.25-0.25h6.461
			c0.138,0,0.25,0.112,0.25,0.25v1.416C165.784,89.496,165.672,89.608,165.534,89.608z M159.323,89.108h5.961v-0.916h-5.961V89.108z
			"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="107.366" y="89.359" />
          <Path
            d="M108.486,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C108.736,103.107,108.624,103.22,108.486,103.22z M107.616,102.72h0.62V89.608h-0.62V102.72z
			"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="111.091" y="89.359" />
          <Path
            d="M112.211,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C112.461,103.107,112.349,103.22,112.211,103.22z M111.341,102.72h0.62V89.608h-0.62V102.72z
			"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="114.816" y="89.359" />
          <Path
            d="M115.936,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C116.186,103.107,116.074,103.22,115.936,103.22z M115.066,102.72h0.62V89.608h-0.62V102.72z
			"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="118.541" y="89.359" />
          <Path
            d="M119.661,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C119.911,103.107,119.799,103.22,119.661,103.22z M118.79,102.72h0.62V89.608h-0.62V102.72z"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="122.266" y="89.359" />
          <Path
            d="M123.386,103.22h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.358c0-0.138,0.112-0.25,0.25-0.25h1.12
			c0.138,0,0.25,0.112,0.25,0.25v13.611C123.636,103.107,123.524,103.22,123.386,103.22z M122.516,102.72h0.62V89.608h-0.62V102.72z
			"
          />
        </G>
        <G>
          <Path
            d="M145.963,98.084c0.502,1.559-0.624,2.096-0.624,2.096l0.007,0.047c-0.291-1.147-1.083-2.12-2.181-2.628
			l-0.068-0.033c0.016-0.057,0.034-0.112,0.046-0.173c0.039-0.206,0.053-0.407,0.049-0.603l0.127,0.133c0,0,0.767,0.117,0.975-0.481
			c0.208-0.598-0.052-1.351-1.143-2.313c-1.091-0.962-1.754-0.65-1.754-0.65s-0.271-0.214-0.686-0.214s-0.643,0.227-0.643,0.227
			l-0.041,0.002l0.009-0.015c0,0-0.663-0.312-1.754,0.65c-1.091,0.962-1.351,1.715-1.143,2.313s0.975,0.481,0.975,0.481l0.131-0.235
			c-0.011,0.228-0.001,0.463,0.045,0.705c0.062,0.332,0.191,0.599,0.356,0.815l0,0.041c-0.007,0.008-0.304,0.362-0.317,0.902
			c-0.009,0.36-0.006,2.033-0.003,3.132c0.001,0.543,0.442,0.981,0.984,0.981h0.146c0.544,0,0.984-0.441,0.984-0.984v-1.173
			l-0.047-0.075c0.206,0.049,0.447,0.066,0.703,0.019l0.025,0.068l-0.031,0.02v1.16c0,0.544,0.441,0.984,0.984,0.984h0.12
			c0.544,0,0.984-0.441,0.984-0.984v-0.009h0.527c-0.011,0.05-0.017,0.102-0.017,0.155c0,0.413,0.334,0.747,0.747,0.747h0.473
			c0.319,0,0.577-0.26,0.574-0.58l-0.015-1.605l0.04,0.263c0.797-0.468,1.022-1.204,1.022-1.204
			C146.933,98.881,145.963,98.084,145.963,98.084z"
            fill="#FFFFFF"
          />
          <Path
            d="M138.375,99.167c-0.437,0.135-0.589,0.623-0.867,0.949c-0.17,0.199-0.565,0.354-0.565,0.354l-0.327-0.629
			c0,0,0.444-0.098,0.586-0.317c0.142-0.22,0.405-0.664,0.85-0.772"
            fill={svgHighlight}
          />
          <Path
            d="M138.482,99.03c0,0-0.89-0.479-1.48-0.062c-0.499,0.352-1.121,0.569-1.802,0.239l0.123-0.876
			c0.671,0.35,1.299,0.152,1.809-0.185c0.603-0.399,1.478,0.107,1.478,0.107"
            fill={svgHighlight}
          />
          <Path
            d="M135.298,98.402c-0.014,0-0.028-0.003-0.042-0.011l-0.446-0.234c-0.044-0.022-0.061-0.077-0.038-0.121
			c0.023-0.043,0.078-0.063,0.122-0.037l0.446,0.234c0.044,0.022,0.061,0.077,0.038,0.121
			C135.362,98.385,135.33,98.402,135.298,98.402z"
          />
          <Path
            d="M135.185,98.83c-0.014,0-0.028-0.003-0.042-0.011l-0.447-0.234c-0.044-0.022-0.061-0.077-0.038-0.121
			s0.076-0.062,0.122-0.037l0.446,0.234c0.044,0.022,0.061,0.077,0.038,0.121C135.248,98.812,135.217,98.83,135.185,98.83z"
          />
          <Path
            d="M135.135,99.281c-0.014,0-0.028-0.003-0.041-0.01l-0.477-0.244c-0.044-0.022-0.062-0.077-0.039-0.121
			s0.077-0.062,0.121-0.039l0.477,0.244c0.044,0.022,0.062,0.077,0.039,0.121C135.199,99.264,135.167,99.281,135.135,99.281z"
          />
          <Path
            d="M138.404,98.943c0,0,0.715,0.948,2.115,1.021c1.4,0.073,3.077-0.744,2.917-2.246l-0.201-0.021
			c0,0-1.073,1.271-2.055,1.396c-1.371,0.175-2.618-0.895-2.618-0.895L138.404,98.943z"
            fill={svgHighlight}
          />
          <Path
            d="M140.698,100.068c-0.062,0-0.124-0.002-0.185-0.005c-1.43-0.074-2.159-1.02-2.189-1.06
			c-0.033-0.044-0.024-0.106,0.02-0.14c0.043-0.034,0.106-0.024,0.14,0.019c0.007,0.01,0.707,0.911,2.041,0.981
			c0.907,0.045,1.839-0.28,2.365-0.835c0.35-0.369,0.5-0.807,0.447-1.301c-0.006-0.055,0.034-0.104,0.088-0.11
			c0.058-0.002,0.104,0.034,0.11,0.089c0.059,0.548-0.115,1.052-0.501,1.46C142.506,99.724,141.605,100.068,140.698,100.068z"
          />
          <Path
            d="M135.885,99.471c-0.247,0-0.49-0.058-0.729-0.174c-0.039-0.019-0.062-0.061-0.056-0.104l0.124-0.876
			c0.005-0.032,0.024-0.061,0.054-0.075c0.029-0.014,0.063-0.014,0.092,0c0.532,0.279,1.106,0.217,1.707-0.179
			c0.647-0.429,1.545,0.083,1.583,0.104c0.048,0.028,0.064,0.089,0.037,0.137c-0.028,0.048-0.09,0.062-0.136,0.037
			c-0.008-0.006-0.831-0.472-1.373-0.111c-0.619,0.411-1.218,0.493-1.784,0.25l-0.094,0.668c0.521,0.225,1.071,0.138,1.635-0.261
			c0.632-0.448,1.547,0.033,1.585,0.056c0.048,0.026,0.066,0.087,0.041,0.135c-0.026,0.05-0.086,0.067-0.135,0.041
			c-0.009-0.004-0.842-0.444-1.375-0.068C136.662,99.33,136.269,99.471,135.885,99.471z"
          />
          <G>
            <Path
              d="M139.476,103.514h-0.146c-0.679,0-1.232-0.552-1.234-1.229c-0.003-1.102-0.005-2.778,0.003-3.139
				c0.015-0.624,0.282-0.923,0.312-0.954c0.096-0.1,0.254-0.105,0.354-0.009c0.099,0.094,0.104,0.25,0.012,0.35
				c-0.012,0.014-0.167,0.204-0.178,0.625c-0.008,0.358-0.006,2.029-0.003,3.125c0.001,0.403,0.331,0.731,0.734,0.731h0.146
				c0.405,0,0.734-0.329,0.734-0.734v-1.173c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.173
				C140.71,102.961,140.157,103.514,139.476,103.514z"
            />
            <Path
              d="M140.834,101.23c-0.676,0-1.189-0.373-1.197-0.379c-0.066-0.049-0.08-0.143-0.031-0.209
				c0.049-0.067,0.144-0.079,0.209-0.031c0.042,0.029,0.992,0.715,1.939,0c0.065-0.048,0.16-0.035,0.209,0.03
				c0.05,0.065,0.037,0.16-0.03,0.21C141.556,101.136,141.174,101.23,140.834,101.23z"
            />
            <Path
              d="M142.214,103.433h-0.12c-0.625,0-1.134-0.509-1.134-1.134v-1.159c0-0.083,0.067-0.149,0.15-0.149s0.15,0.066,0.15,0.149
				v1.159c0,0.46,0.375,0.835,0.834,0.835h0.12c0.46,0,0.834-0.375,0.834-0.835v-2.518c0-0.083,0.067-0.149,0.15-0.149
				s0.15,0.066,0.15,0.149v2.518C143.349,102.925,142.84,103.433,142.214,103.433z"
            />
            <Path
              d="M143.689,102.453h-0.47c-0.083,0-0.15-0.066-0.15-0.149s0.067-0.149,0.15-0.149h0.47c0.083,0,0.15,0.066,0.15,0.149
				S143.772,102.453,143.689,102.453z"
            />
            <Path
              d="M144.929,103.443h-0.473c-0.55,0-0.997-0.447-0.997-0.997s0.447-0.997,0.997-0.997h0.097c0.138,0,0.25,0.112,0.25,0.25
				s-0.112,0.25-0.25,0.25h-0.097c-0.274,0-0.497,0.223-0.497,0.497s0.223,0.497,0.497,0.497h0.473c0.087,0,0.169-0.034,0.23-0.096
				c0.061-0.062,0.095-0.145,0.094-0.231l-0.015-1.612c-0.071-1.374-0.896-2.596-2.154-3.178c-0.125-0.058-0.18-0.206-0.122-0.331
				c0.059-0.126,0.207-0.18,0.332-0.122c1.427,0.66,2.363,2.046,2.444,3.616l0.015,1.623c0.002,0.221-0.083,0.43-0.239,0.587
				C145.358,103.356,145.15,103.443,144.929,103.443z"
            />
            <Path
              d="M145.528,101.522c-0.085,0-0.169-0.044-0.216-0.123c-0.07-0.119-0.03-0.272,0.089-0.343
				c0.705-0.413,0.907-1.055,0.909-1.062c0.115-0.336,0.104-0.635,0.037-0.886c-0.126,0.926-0.867,1.292-0.877,1.297
				c-0.125,0.059-0.274,0.006-0.333-0.118s-0.007-0.272,0.116-0.332c0.039-0.02,0.92-0.476,0.495-1.794
				c-0.034-0.106,0.005-0.222,0.097-0.284c0.092-0.061,0.214-0.056,0.299,0.014c0.045,0.037,1.099,0.923,0.641,2.258
				c-0.008,0.026-0.261,0.827-1.132,1.339C145.615,101.512,145.571,101.522,145.528,101.522z"
            />
            <Path
              d="M140.847,98.122c-0.651,0-1.103-0.474-1.109-0.48c-0.038-0.041-0.035-0.104,0.005-0.142
				c0.04-0.037,0.102-0.035,0.141,0.005c0.035,0.038,0.889,0.929,1.926-0.006c0.041-0.038,0.103-0.035,0.141,0.008
				c0.037,0.04,0.034,0.104-0.007,0.141C141.548,98.004,141.172,98.122,140.847,98.122z"
            />
            <Path
              d="M140.811,98.089c-0.055,0-0.1-0.045-0.1-0.1v-0.877c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v0.877
				C140.911,98.044,140.866,98.089,140.811,98.089z"
            />
            <Circle cx="141.721" cy="95.618" r="0.174" />
            <Circle cx="139.973" cy="95.618" r="0.174" />
            <Ellipse cx="140.814" cy="96.462" rx="0.761" ry="0.353" />
            <Path d="M140.194,96.43l-0.14,0.032c0,0,0.065,0.604,0.741,0.702l0.049,0.01l0.075-0.611L140.194,96.43z" />
            <Path d="M141.438,96.43l0.14,0.032c0,0-0.065,0.604-0.741,0.702l-0.049,0.01l-0.075-0.611L141.438,96.43z" />
            <Path
              d="M137.981,97.184c-0.36,0-0.875-0.134-1.058-0.659c-0.267-0.769,0.142-1.638,1.214-2.583
				c1.18-1.04,1.942-0.729,2.026-0.688c0.125,0.059,0.179,0.208,0.12,0.333c-0.058,0.123-0.205,0.178-0.327,0.122
				c-0.017-0.006-0.567-0.202-1.488,0.608c-0.905,0.798-1.266,1.486-1.072,2.044c0.137,0.396,0.693,0.314,0.701,0.315
				c0.135-0.021,0.264,0.073,0.285,0.209c0.021,0.137-0.073,0.265-0.209,0.285C138.117,97.18,138.052,97.184,137.981,97.184z"
            />
            <Path
              d="M140.7,99.264c-0.022,0-2.195-0.021-2.537-1.843c-0.333-1.771,0.972-3.252,1.028-3.313
				c0.055-0.062,0.149-0.066,0.211-0.012c0.062,0.055,0.066,0.149,0.011,0.211c-0.012,0.015-1.262,1.435-0.957,3.06
				c0.296,1.576,2.164,1.598,2.243,1.599c0.083,0,0.15,0.067,0.15,0.149C140.85,99.197,140.783,99.264,140.7,99.264z"
            />
            <Path
              d="M143.496,97.184c-0.071,0-0.136-0.005-0.191-0.014c-0.136-0.021-0.229-0.147-0.209-0.283
				c0.02-0.137,0.144-0.236,0.283-0.211c0.022,0.003,0.565,0.075,0.702-0.315c0.194-0.558-0.167-1.246-1.073-2.044
				c-0.932-0.821-1.483-0.608-1.488-0.608c-0.083,0.033-0.185,0.023-0.256-0.032c0,0-0.214-0.16-0.531-0.16
				c-0.299,0-0.463,0.15-0.47,0.156c-0.101,0.094-0.258,0.091-0.352-0.008c-0.095-0.098-0.095-0.253,0.002-0.349
				c0.031-0.031,0.315-0.3,0.82-0.3c0.332,0,0.585,0.111,0.724,0.19c0.287-0.062,0.955-0.08,1.881,0.735
				c1.073,0.945,1.481,1.814,1.215,2.583C144.371,97.05,143.856,97.184,143.496,97.184z"
            />
            <Path
              d="M140.777,99.264c-0.083,0-0.149-0.066-0.15-0.149c0-0.082,0.067-0.149,0.15-0.149c0.079-0.001,1.946-0.022,2.243-1.599
				c0.305-1.625-0.945-3.045-0.957-3.06c-0.055-0.062-0.05-0.156,0.011-0.211c0.061-0.056,0.156-0.051,0.211,0.012
				c0.056,0.062,1.362,1.542,1.028,3.313C142.972,99.243,140.799,99.264,140.777,99.264z"
            />
          </G>
        </G>
        <G>
          <Path
            d="M125.34,46.644c0,0-0.534-0.013-1.55,0.573c-1.016,0.586-1.511,1.836-0.873,2.657s1.654,0.287,1.654,0.287
			l2.696,0.026c0,0,1.185-0.182,1.198-1.159c0.013-0.977-0.365-2.344-0.365-2.344L125.34,46.644z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              d="M128.847,45.999c0.331-1.169,0.066-1.61,0.066-1.61c-0.821,0.02-1.314,0.518-1.314,0.518
				c-0.567-0.213-1.244,0-1.244,0s-0.493-0.498-1.314-0.518c0,0-0.265,0.44,0.066,1.61c0,0-0.485,1.18,0.669,1.629
				c1.155,0.449,1.905,0.158,2.442-0.033c0.537-0.191,0.908-0.783,0.717-1.353L128.847,45.999z"
              fill="#FFFFFF"
            />
            <Path
              d="M126.947,48.114c-0.366,0-0.783-0.067-1.262-0.253c-0.4-0.155-0.676-0.405-0.82-0.742
				c-0.196-0.457-0.082-0.937-0.021-1.129c-0.317-1.183-0.05-1.677-0.018-1.73c0.046-0.077,0.134-0.128,0.22-0.121
				c0.683,0.016,1.165,0.329,1.372,0.493c0.224-0.051,0.68-0.125,1.123-0.006c0.211-0.166,0.689-0.471,1.365-0.487
				c0.079-0.002,0.174,0.044,0.22,0.121c0.032,0.053,0.299,0.547-0.018,1.729l0.061,0.168c0.226,0.675-0.164,1.423-0.868,1.674
				C127.948,47.956,127.508,48.114,126.947,48.114z M125.217,44.652c-0.037,0.191-0.063,0.595,0.13,1.278
				c0.015,0.053,0.012,0.111-0.009,0.162c-0.001,0.004-0.177,0.451-0.012,0.832c0.09,0.208,0.272,0.366,0.54,0.47
				c1.039,0.404,1.697,0.168,2.226-0.021c0.435-0.155,0.755-0.606,0.605-1.053l-0.085-0.237c-0.018-0.049-0.02-0.103-0.006-0.153
				c0.193-0.681,0.168-1.084,0.13-1.278c-0.595,0.076-0.957,0.427-0.96,0.431c-0.069,0.068-0.172,0.093-0.265,0.057
				c-0.477-0.18-1.077,0.002-1.082,0.005c-0.09,0.027-0.187,0.003-0.252-0.063C126.174,45.079,125.811,44.727,125.217,44.652z"
            />
          </G>
          <Path
            d="M125.143,46.125c-0.015,0-0.029-0.003-0.043-0.01c-0.049-0.024-0.07-0.083-0.046-0.133
			c0.193-0.402,0.503-0.657,0.657-0.765c-0.262-0.354-0.657-0.658-0.662-0.661c-0.044-0.034-0.052-0.096-0.019-0.14
			c0.034-0.044,0.096-0.053,0.14-0.019c0.02,0.016,0.49,0.377,0.765,0.792c0.016,0.023,0.021,0.051,0.014,0.078
			s-0.023,0.05-0.047,0.063c-0.004,0.002-0.436,0.256-0.668,0.738C125.216,46.104,125.181,46.125,125.143,46.125z"
          />
          <Path
            d="M128.811,46.125c-0.037,0-0.073-0.021-0.09-0.057c-0.232-0.482-0.664-0.736-0.668-0.738
			c-0.024-0.014-0.041-0.037-0.047-0.063s-0.001-0.055,0.014-0.078c0.275-0.415,0.745-0.776,0.765-0.792
			c0.044-0.033,0.107-0.025,0.14,0.019c0.034,0.044,0.025,0.106-0.019,0.14c-0.004,0.003-0.4,0.308-0.662,0.661
			c0.153,0.108,0.464,0.363,0.657,0.765c0.024,0.05,0.003,0.109-0.046,0.133C128.841,46.121,128.826,46.125,128.811,46.125z"
          />
          <Path
            d="M127.161,46.98h-0.293c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h0.293c0.055,0,0.1,0.045,0.1,0.1
			S127.216,46.98,127.161,46.98z"
          />
          <Path
            d="M126.776,47.372c-0.001,0-0.003,0-0.005,0c-0.246-0.004-0.331-0.194-0.342-0.293c-0.005-0.049,0.03-0.094,0.08-0.099
			c0.055-0.009,0.093,0.03,0.099,0.079c0.003,0.023,0.026,0.131,0.166,0.134c0,0,0.001,0,0.001,0c0.133,0,0.146-0.292,0.146-0.294
			c0.001-0.049,0.041-0.088,0.09-0.088c0,0,0.001,0,0.002,0c0.05,0.001,0.089,0.042,0.088,0.092
			C127.098,47.077,127.025,47.372,126.776,47.372z"
          />
          <Path
            d="M127.222,47.372c-0.249,0-0.322-0.295-0.326-0.47c-0.001-0.05,0.038-0.09,0.088-0.092c0,0,0.001,0,0.002,0
			c0.049,0,0.088,0.039,0.09,0.088c0,0.003,0.014,0.294,0.147,0.294c0,0,0,0,0.001,0c0.141-0.002,0.163-0.112,0.166-0.134
			c0.006-0.049,0.056-0.075,0.1-0.079c0.049,0.006,0.084,0.049,0.079,0.098c-0.01,0.1-0.095,0.29-0.341,0.294
			C127.225,47.372,127.224,47.372,127.222,47.372z"
          />
          <Circle cx="126.042" cy="46.45" r="0.122" />
          <Circle cx="127.927" cy="46.411" r="0.122" />
          <Path
            d="M123.987,50.543c-0.015,0-0.029,0-0.044,0c-0.345-0.008-0.978-0.094-1.343-0.584c-0.26-0.35-0.313-0.811-0.159-1.372
			c0.507-1.836,2.446-2.158,2.528-2.17c0.133-0.022,0.263,0.071,0.285,0.208c0.021,0.136-0.071,0.264-0.208,0.286
			c-0.069,0.011-1.704,0.289-2.124,1.809c-0.112,0.405-0.085,0.721,0.078,0.941c0.247,0.332,0.748,0.378,0.952,0.383
			c0.378,0.013,0.635-0.107,1.062-0.456l0.119-0.125c0,0-0.001-0.07-0.002-0.071c-0.018-0.017-0.046-0.017-0.065,0
			c-0.62,0.566-1.26,0.594-1.65,0.431c-0.453-0.188-0.534-0.62-0.514-0.823c0.014-0.137,0.141-0.239,0.273-0.224
			c0.136,0.013,0.236,0.134,0.224,0.27c-0.002,0.039-0.003,0.228,0.208,0.314c0.237,0.098,0.675,0.069,1.122-0.339
			c0.213-0.194,0.542-0.19,0.75,0.01c0.105,0.101,0.166,0.238,0.168,0.385c0.003,0.147-0.052,0.287-0.154,0.393l-0.14,0.145
			C124.903,50.324,124.526,50.543,123.987,50.543z"
          />
          <Path
            d="M127.42,50.338c-0.059,0-0.112-0.041-0.126-0.1c-0.016-0.07,0.027-0.14,0.097-0.156c0.213-0.05,0.362-0.239,0.362-0.459
			c0-0.127-0.049-0.246-0.139-0.336c-0.088-0.087-0.206-0.136-0.33-0.136c-0.018,0.01-0.057,0-0.085,0
			c-0.372,0-0.663-0.016-0.868-0.049c-0.071-0.012-0.119-0.078-0.108-0.149c0.012-0.071,0.079-0.118,0.149-0.108
			c0.203,0.033,0.519,0.043,0.907,0.046c0.001,0,0.003,0,0.004,0c0.193,0,0.375,0.075,0.512,0.211
			c0.14,0.139,0.216,0.323,0.216,0.52c0,0.342-0.231,0.635-0.562,0.712C127.44,50.337,127.43,50.338,127.42,50.338z"
          />
          <Path
            d="M126.74,50.481c-0.944,0-2.145-0.011-2.145-0.011l0.005-0.5c0,0,1.197,0.011,2.14,0.011c0.226,0,0.408-0.003,0.555-0.007
			c0.023,0,0.046-0.004,0.069-0.009c0.136-0.03,0.269,0.053,0.3,0.188c0.031,0.134-0.053,0.269-0.188,0.3
			c-0.053,0.012-0.108,0.02-0.166,0.022C127.159,50.478,126.971,50.481,126.74,50.481z"
          />
          <Path
            d="M127.525,50.418c-0.123,0-0.23-0.091-0.247-0.217c-0.019-0.137,0.078-0.263,0.214-0.281
			c0.221-0.03,0.386-0.128,0.504-0.301c0.36-0.527,0.201-1.614,0.109-1.989c-0.033-0.134,0.049-0.27,0.183-0.302
			c0.13-0.033,0.269,0.048,0.302,0.183c0.016,0.063,0.377,1.569-0.182,2.389c-0.2,0.293-0.486,0.466-0.851,0.515
			C127.548,50.418,127.537,50.418,127.525,50.418z"
          />
        </G>
        <Path
          d="M148.544,91.347c-0.055,0-0.1-0.045-0.1-0.1v-9.742c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v9.742
		C148.643,91.303,148.599,91.347,148.544,91.347z"
        />
        <Path
          d="M151.201,91.347c-0.055,0-0.1-0.045-0.1-0.1v-9.742c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v9.742
		C151.301,91.303,151.256,91.347,151.201,91.347z"
        />
        <Path
          d="M153.206,86.45h-6.694c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h6.694c0.055,0,0.1,0.045,0.1,0.1
		S153.261,86.45,153.206,86.45z"
        />
        <Path
          d="M148.234,78.422c-0.055,0-0.1-0.045-0.1-0.1V76.28c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v2.042
		C148.334,78.377,148.289,78.422,148.234,78.422z"
        />
        <Path
          d="M150.959,78.422c-0.055,0-0.1-0.045-0.1-0.1V76.28c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v2.042
		C151.059,78.377,151.014,78.422,150.959,78.422z"
        />
        <Path
          d="M173.507,32.908h-8.052c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h8.052c0.055,0,0.1,0.045,0.1,0.1
		S173.561,32.908,173.507,32.908z"
        />
        <Path
          d="M183.195,36.045h-14.05c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.05c0.055,0,0.1,0.045,0.1,0.1
		S183.25,36.045,183.195,36.045z"
        />
        <Path
          d="M183.195,39.183h-7.82c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.82c0.055,0,0.1,0.045,0.1,0.1
		S183.25,39.183,183.195,39.183z"
        />
        <Path
          d="M172.828,39.183h-5.592c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.592c0.055,0,0.1,0.045,0.1,0.1
		S172.883,39.183,172.828,39.183z"
        />
        <Path
          d="M181.736,42.32h-17.396c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h17.396c0.055,0,0.1,0.045,0.1,0.1
		S181.791,42.32,181.736,42.32z"
        />
        <Path
          d="M180.382,64.022h-11.236c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.236c0.055,0,0.1,0.045,0.1,0.1
		S180.436,64.022,180.382,64.022z"
        />
        <Path
          d="M183.195,67.16h-7.82c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.82c0.055,0,0.1,0.045,0.1,0.1
		S183.25,67.16,183.195,67.16z"
        />
        <Path
          d="M172.828,67.16h-5.592c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.592c0.055,0,0.1,0.045,0.1,0.1
		S172.883,67.16,172.828,67.16z"
        />
        <Path
          d="M179.092,70.297h-10.394c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h10.394c0.055,0,0.1,0.045,0.1,0.1
		S179.146,70.297,179.092,70.297z"
        />
        <Path
          d="M183.195,45.412h-11.321c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.321c0.055,0,0.1,0.045,0.1,0.1
		S183.25,45.412,183.195,45.412z"
        />
        <Path
          d="M178.558,48.343h-15.733c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h15.733c0.055,0,0.1,0.045,0.1,0.1
		S178.612,48.343,178.558,48.343z"
        />
        <Path
          d="M183.195,51.48h-14.05c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.05c0.055,0,0.1,0.045,0.1,0.1
		S183.25,51.48,183.195,51.48z"
        />
        <Path
          d="M183.195,54.617h-7.82c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.82c0.055,0,0.1,0.045,0.1,0.1
		S183.25,54.617,183.195,54.617z"
        />
        <Path
          d="M172.828,54.617h-5.592c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.592c0.055,0,0.1,0.045,0.1,0.1
		S172.883,54.617,172.828,54.617z"
        />
        <Path
          d="M183.195,57.754h-12.868c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h12.868c0.055,0,0.1,0.045,0.1,0.1
		S183.25,57.754,183.195,57.754z"
        />
        <Path
          d="M183.349,60.846h-6.864c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h6.864c0.055,0,0.1,0.045,0.1,0.1
		S183.403,60.846,183.349,60.846z"
        />
        <Path
          d="M181.736,73.814h-17.396c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h17.396c0.055,0,0.1,0.045,0.1,0.1
		S181.791,73.814,181.736,73.814z"
        />
        <Path
          d="M170.264,95.515h-2.188c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h2.188c0.055,0,0.1,0.045,0.1,0.1
		S170.318,95.515,170.264,95.515z"
        />
        <Path
          d="M168.076,98.653h-2.599c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h2.599c0.055,0,0.1,0.045,0.1,0.1
		S168.131,98.653,168.076,98.653z"
        />
        <Path
          d="M183.195,76.905h-11.321c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.321c0.055,0,0.1,0.045,0.1,0.1
		S183.25,76.905,183.195,76.905z"
        />
        <Path
          d="M178.558,79.836h-15.733c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h15.733c0.055,0,0.1,0.045,0.1,0.1
		S178.612,79.836,178.558,79.836z"
        />
        <Path
          d="M183.195,82.972h-14.05c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.05c0.055,0,0.1,0.045,0.1,0.1
		S183.25,82.972,183.195,82.972z"
        />
        <Path
          d="M183.195,86.11h-3.165c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h3.165c0.055,0,0.1,0.045,0.1,0.1
		S183.25,86.11,183.195,86.11z"
        />
        <Path
          d="M172.828,86.11h-5.592c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.592c0.055,0,0.1,0.045,0.1,0.1
		S172.883,86.11,172.828,86.11z"
        />
        <Path
          d="M183.195,89.247h-13.48c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h13.48c0.055,0,0.1,0.045,0.1,0.1
		S183.25,89.247,183.195,89.247z"
        />
        <Path
          d="M173.976,92.339H165.7c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h8.275c0.055,0,0.1,0.045,0.1,0.1
		S174.03,92.339,173.976,92.339z"
        />
        <Path
          d="M142.017,9.368h-7.665c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.665c0.055,0,0.1,0.045,0.1,0.1
		S142.072,9.368,142.017,9.368z"
        />
        <Path
          d="M141.336,12.227h-11.113c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.113c0.055,0,0.1,0.045,0.1,0.1
		S141.391,12.227,141.336,12.227z"
        />
        <Path
          d="M150.19,15.287h-18.417c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h18.417c0.055,0,0.1,0.045,0.1,0.1
		S150.245,15.287,150.19,15.287z"
        />
        <Path
          d="M150.19,25.603h-18.417c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h18.417c0.055,0,0.1,0.045,0.1,0.1
		S150.245,25.603,150.19,25.603z"
        />
        <Path
          d="M128.817,25.603h-2.436c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h2.436c0.055,0,0.1,0.045,0.1,0.1
		S128.872,25.603,128.817,25.603z"
        />
        <Path
          d="M144.285,21.976h-24.49c-0.055,0-0.1-0.045-0.1-0.1s0.044-0.1,0.1-0.1h24.49c0.055,0,0.1,0.045,0.1,0.1
		S144.34,21.976,144.285,21.976z"
        />
        <Path
          d="M152.089,21.976h-4.873c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h4.873c0.055,0,0.1,0.045,0.1,0.1
		S152.143,21.976,152.089,21.976z"
        />
        <Path
          d="M128.662,18.481h-6.345c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h6.345c0.055,0,0.1,0.045,0.1,0.1
		S128.717,18.481,128.662,18.481z"
        />
        <Path
          d="M154.28,18.481h-14.379c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.379c0.055,0,0.1,0.045,0.1,0.1
		S154.335,18.481,154.28,18.481z"
        />
        <Path
          d="M162.76,42.309h-1.661c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h1.661c0.055,0,0.1,0.045,0.1,0.1
		S162.814,42.309,162.76,42.309z"
        />
        <Path
          d="M93.679,115.59c0,0,5.527-2.177,9.826-2.624c4.299-0.447,8.374-0.726,14.125,0.726s9.601,1.911,9.601,1.911
		"
          fill="#FFFFFF"
        />
        <Path
          d="M90.814,116.519c0,0,9.936-0.981,13.001-1.679c3.065-0.698,7.162-2.297,17.792-0.124
		c0,0-9.272-2.716-14.315-2.095C102.248,113.242,93.84,114.967,90.814,116.519z"
          fill="#DCE2E7"
        />
        <Path
          d="M90.501,116.769c-0.103,0-0.199-0.063-0.235-0.166c-0.046-0.13,0.021-0.273,0.151-0.319
		c0.356-0.127,8.783-3.122,13.062-3.567l0.564-0.059c4.021-0.423,8.182-0.86,13.659,0.794c7.223,2.179,9.736,2.072,9.761,2.062
		c0.143,0.006,0.256,0.098,0.264,0.234c0.009,0.138-0.096,0.257-0.233,0.265c-0.102,0.008-2.593,0.133-9.937-2.082
		c-5.38-1.624-9.306-1.21-13.462-0.775l-0.564,0.06c-4.22,0.438-12.859,3.509-12.946,3.54
		C90.557,116.764,90.529,116.769,90.501,116.769z"
        />
        <Path
          d="M137.003,100.647c-0.036,0-0.07-0.019-0.087-0.052l-0.387-0.707c-0.015-0.027-0.017-0.061-0.003-0.089
		c0.013-0.029,0.039-0.05,0.069-0.057c0.112-0.024,0.426-0.123,0.523-0.273c0.029-0.045,0.055-0.097,0.083-0.15
		c0.107-0.209,0.253-0.496,0.647-0.591c0.051-0.015,0.107,0.019,0.121,0.073c0.013,0.054-0.02,0.107-0.074,0.12
		c-0.304,0.074-0.412,0.284-0.516,0.488c-0.031,0.061-0.06,0.117-0.092,0.167c-0.113,0.175-0.366,0.277-0.521,0.327l0.282,0.516
		c0.119-0.054,0.337-0.164,0.445-0.29c0.09-0.106,0.156-0.231,0.22-0.353c0.127-0.242,0.258-0.492,0.581-0.593
		c0.054-0.017,0.109,0.014,0.125,0.066c0.017,0.053-0.013,0.108-0.065,0.125c-0.244,0.075-0.346,0.27-0.464,0.494
		c-0.069,0.131-0.14,0.266-0.244,0.389c-0.184,0.216-0.588,0.375-0.605,0.382C137.028,100.645,137.016,100.647,137.003,100.647z"
        />
        <Path
          d="M136.221,100.028c-0.04,0-0.076-0.026-0.087-0.067c-0.013-0.048,0.016-0.097,0.064-0.109l0.372-0.098
		c0.052-0.013,0.097,0.017,0.11,0.064s-0.016,0.097-0.064,0.109l-0.372,0.098C136.236,100.027,136.228,100.028,136.221,100.028z"
        />
        <Path
          d="M136.38,100.387c-0.04,0-0.076-0.026-0.087-0.067c-0.013-0.048,0.016-0.097,0.064-0.109l0.371-0.098
		c0.051-0.012,0.097,0.016,0.11,0.064c0.013,0.048-0.016,0.097-0.064,0.109l-0.371,0.098
		C136.395,100.386,136.387,100.387,136.38,100.387z"
        />
        <Path
          d="M136.604,100.76c-0.039,0-0.074-0.025-0.086-0.064c-0.015-0.047,0.013-0.098,0.06-0.111l0.368-0.11
		c0.05-0.016,0.098,0.013,0.112,0.061c0.015,0.047-0.013,0.098-0.06,0.111l-0.368,0.11
		C136.621,100.759,136.612,100.76,136.604,100.76z"
        />
        <G>
          <Path
            d="M181.825,29.6v2.192c0,0.3,0.164,1.592-0.542,1.59l0,0c-0.601-0.008-0.542-1.29-0.542-1.59v-0.024
			c0-0.289-0.234-0.523-0.523-0.523l0,0c-0.289,0-0.523,0.234-0.523,0.523v2c0,0.308-0.25,0.558-0.558,0.558l0,0
			c-0.308,0-0.558-0.25-0.558-0.558v-2.302c0-0.288-0.233-0.521-0.521-0.521l0,0c-0.288,0-0.521,0.233-0.521,0.521v0.237
			c0,0.296-0.24,0.537-0.537,0.537l0,0c-0.296,0-0.537-0.24-0.537-0.537v-2.925"
            fill="#FFFFFF"
          />
          <Path
            d="M179.137,34.577c-0.446,0-0.809-0.362-0.809-0.808v-2.302c0-0.149-0.121-0.271-0.27-0.271s-0.271,0.122-0.271,0.271v0.237
			c0,0.434-0.354,0.786-0.787,0.786s-0.786-0.353-0.786-0.786v-1.568c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.568
			c0,0.158,0.128,0.286,0.286,0.286s0.287-0.128,0.287-0.286v-0.237c0-0.425,0.346-0.771,0.771-0.771s0.77,0.346,0.77,0.771v2.302
			c0,0.17,0.139,0.308,0.309,0.308s0.309-0.138,0.309-0.308v-2c0-0.426,0.347-0.773,0.772-0.773s0.772,0.347,0.772,0.773v1.071
			c0,0.161,0.132,0.292,0.293,0.292s0.292-0.131,0.292-0.292v-2.741c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.741
			c0,0.437-0.355,0.792-0.792,0.792c-0.438,0-0.793-0.355-0.793-0.792v-1.071c0-0.15-0.122-0.273-0.272-0.273
			s-0.272,0.123-0.272,0.273v2C179.945,34.215,179.583,34.577,179.137,34.577z"
          />
        </G>
        <Path
          d="M148.793,64.288v1.979c0,0.282-0.228,0.51-0.51,0.51l0,0c-0.282,0-0.51-0.228-0.51-0.51v-0.022
		c0-0.271-0.22-0.491-0.491-0.491l0,0c-0.271,0-0.491,0.22-0.491,0.491v0.601c0,0.29-0.235,0.525-0.525,0.525l0,0
		c-0.29,0-0.525-0.235-0.525-0.525v-0.885c0-0.27-0.219-0.49-0.49-0.49l0,0c-0.27,0-0.49,0.219-0.49,0.49v0.223
		c0,0.279-0.226,0.504-0.504,0.504l0,0c-0.279,0-0.504-0.226-0.504-0.504v-1.802"
          fill="#FFFFFF"
        />
        <Path
          d="M146.265,67.62c-0.427,0-0.775-0.347-0.775-0.774v-0.885c0-0.132-0.107-0.24-0.239-0.24c-0.132,0-0.24,0.107-0.24,0.24
		v0.223c0,0.416-0.338,0.754-0.754,0.754s-0.754-0.338-0.754-0.754v-1.578c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.578
		c0,0.14,0.114,0.254,0.254,0.254s0.254-0.114,0.254-0.254v-0.223c0-0.408,0.332-0.74,0.74-0.74s0.739,0.332,0.739,0.74v0.885
		c0,0.151,0.124,0.274,0.275,0.274c0.152,0,0.275-0.123,0.275-0.274v-0.601c0-0.409,0.332-0.741,0.741-0.741
		s0.741,0.333,0.741,0.741c0,0.166,0.117,0.282,0.261,0.282s0.26-0.117,0.26-0.26v-1.651c0-0.138,0.112-0.25,0.25-0.25
		s0.25,0.112,0.25,0.25v1.651c0,0.419-0.341,0.76-0.76,0.76c-0.42,0-0.761-0.341-0.761-0.76c0-0.155-0.108-0.264-0.241-0.264
		s-0.241,0.108-0.241,0.241v0.601C147.04,67.273,146.692,67.62,146.265,67.62z"
        />
        <G>
          <Path
            d="M114.11,64.119v1.791c0,0.3-0.164,1.592,0.542,1.59l0,0c0.601-0.008,0.542-1.29,0.542-1.59v-0.024
			c0-0.289,0.234-0.523,0.523-0.523l0,0c0.289,0,0.523,0.234,0.523,0.523v2c0,0.308,0.25,0.558,0.558,0.558l0,0
			c0.308,0,0.558-0.25,0.558-0.558v-2.302c0-0.288,0.233-0.521,0.521-0.521l0,0c0.288,0,0.521,0.233,0.521,0.521v0.237
			c0,0.296,0.24,0.537,0.537,0.537l0,0c0.296,0,0.537-0.24,0.537-0.537v-1.974"
            fill="#FFFFFF"
          />
          <Path
            d="M116.799,68.695c-0.446,0-0.808-0.363-0.808-0.809v-2c0-0.15-0.123-0.272-0.273-0.272s-0.272,0.122-0.272,0.272v1.071
			c0,0.437-0.355,0.792-0.792,0.792s-0.792-0.355-0.792-0.792v-2.25c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.25
			c0,0.161,0.131,0.292,0.292,0.292s0.292-0.131,0.292-0.292v-1.071c0-0.426,0.347-0.772,0.772-0.772
			c0.426,0,0.773,0.347,0.773,0.772v2c0,0.17,0.138,0.309,0.308,0.309s0.309-0.139,0.309-0.309v-2.302
			c0-0.425,0.346-0.771,0.771-0.771c0.425,0,0.771,0.346,0.771,0.771v0.237c0,0.158,0.128,0.287,0.287,0.287
			c0.158,0,0.286-0.128,0.286-0.287v-1.153c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.153
			c0,0.434-0.353,0.787-0.786,0.787s-0.787-0.353-0.787-0.787v-0.237c0-0.149-0.122-0.271-0.271-0.271
			c-0.149,0-0.271,0.122-0.271,0.271v2.302C117.607,68.332,117.245,68.695,116.799,68.695z"
          />
        </G>
        <Path
          d="M115.505,22.81v6.098c0,0.305-0.247,0.553-0.553,0.553l0,0c-0.305,0-0.553-0.247-0.553-0.553v-0.904
		c0-0.281-0.228-0.509-0.509-0.509l0,0c-0.281,0-0.509,0.228-0.509,0.509v2.705c0,0.318-0.258,0.576-0.576,0.576l0,0
		c-0.318,0-0.576-0.258-0.576-0.576v-5.167"
          fill="#FFFFFF"
        />
        <Path
          d="M112.806,31.535c-0.456,0-0.826-0.371-0.826-0.826V25.62c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v5.089
		c0,0.18,0.146,0.326,0.326,0.326s0.326-0.146,0.326-0.326v-2.705c0-0.418,0.34-0.759,0.759-0.759s0.759,0.34,0.759,0.759v0.904
		c0,0.167,0.136,0.303,0.303,0.303s0.303-0.136,0.303-0.303v-5.489c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v5.489
		c0,0.442-0.36,0.803-0.803,0.803s-0.803-0.36-0.803-0.803v-0.904c0-0.143-0.116-0.259-0.259-0.259s-0.259,0.116-0.259,0.259v2.705
		C113.632,31.165,113.261,31.535,112.806,31.535z"
        />
        <G>
          <Path
            d="M155.36,18.081v4.212c0,0.3-0.164,0.625,0.542,0.623l0,0c0.601-0.008,0.542-0.831,0.542-1.131v-0.024
			c0-0.289,0.234-0.523,0.523-0.523l0,0c0.289,0,0.523,0.234,0.523,0.523v2.508c0,0.308,0.25,0.558,0.558,0.558l0,0
			c0.308,0,0.558-0.25,0.558-0.558v-1.466c0-0.288,0.233-0.521,0.521-0.521l0,0c0.288,0,0.521,0.233,0.521,0.521v0.999
			c0,0.296,0.24,0.537,0.537,0.537l0,0c0.296,0,0.537-0.24,0.537-0.537v-2.669"
            fill="#FFFFFF"
          />
          <Path
            d="M158.049,25.078c-0.446,0-0.809-0.362-0.809-0.808v-2.508c0-0.15-0.122-0.272-0.272-0.272s-0.272,0.122-0.272,0.272v0.612
			c0,0.437-0.355,0.792-0.793,0.792c-0.437,0-0.792-0.355-0.792-0.792v-3.425c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25
			v3.425c0,0.161,0.131,0.292,0.292,0.292s0.293-0.131,0.293-0.292v-0.612c0-0.426,0.347-0.772,0.772-0.772s0.772,0.347,0.772,0.772
			v2.508c0,0.17,0.139,0.308,0.309,0.308s0.309-0.138,0.309-0.308v-1.466c0-0.425,0.346-0.771,0.771-0.771
			c0.425,0,0.771,0.346,0.771,0.771v0.999c0,0.158,0.129,0.287,0.287,0.287s0.286-0.128,0.286-0.287v-0.976
			c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.976c0,0.434-0.353,0.787-0.786,0.787s-0.787-0.353-0.787-0.787v-0.999
			c0-0.149-0.121-0.271-0.271-0.271s-0.271,0.122-0.271,0.271v1.466C158.857,24.716,158.495,25.078,158.049,25.078z"
          />
        </G>
        <Rect fill="#FFFFFF" height="1.149" width="1.736" x="111.396" y="63.338" />
        <G>
          <Polyline fill="#FFFFFF" points="155.075,66.792 155.075,67.755 159.594,67.755 159.594,66.778 		" />
          <Path
            d="M159.595,68.005h-4.52c-0.138,0-0.25-0.112-0.25-0.25v-0.964c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.714
			h4.02v-0.727c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.977C159.845,67.893,159.732,68.005,159.595,68.005z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="131.787,66.815 131.787,67.87 136.306,67.87 136.306,66.802 		" />
          <Path
            d="M136.306,68.119h-4.52c-0.138,0-0.25-0.112-0.25-0.25v-1.055c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.805
			h4.02v-0.818c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.068C136.556,68.008,136.444,68.119,136.306,68.119z"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="1.412" width="27.56" x="104.839" y="88.369" />
          <Path
            d="M132.399,90.031h-27.56c-0.138,0-0.25-0.112-0.25-0.25v-1.412c0-0.138,0.112-0.25,0.25-0.25h27.56
			c0.138,0,0.25,0.112,0.25,0.25v1.412C132.648,89.919,132.537,90.031,132.399,90.031z M105.088,89.531h27.061v-0.912h-27.061
			V89.531z"
          />
        </G>
        <Path
          d="M215.329,94.408c0,0,6.698,4.899,5.399,11.997c-1.3,7.098-11.147,7.248-11.147,7.248h-0.317
		c0,0-8.372-0.15-9.672-7.248s5.399-11.997,5.399-11.997"
          fill="#FFFFFF"
        />
        <G>
          <Ellipse cx="205.937" cy="101.679" rx="0.472" ry="0.552" />
          <Ellipse cx="205.663" cy="107.783" rx="0.472" ry="0.552" />
          <Ellipse cx="205.29" cy="104.689" rx="0.472" ry="0.552" />
          <Path
            d="M214.926,97.749c0,0,3.221,3.865,2.791,8.846s-3.392,5.926-3.392,5.926s1.932-0.129,3.521-1.503
			c1.589-1.374,3.306-3.521,2.834-8.545c-0.472-5.024-5.926-7.601-5.926-7.601L214.926,97.749z"
            fill="#DCE2E7"
          />
          <Path
            d="M209.264,114.027c-0.094-0.002-8.701-0.239-10.041-7.556c-1.326-7.245,5.478-12.315,5.546-12.366
			c0.169-0.122,0.402-0.087,0.524,0.081c0.122,0.167,0.086,0.401-0.081,0.524c-0.065,0.048-6.492,4.849-5.251,11.626
			c1.229,6.709,8.98,6.935,9.31,6.94h0.311c0.09-0.002,9.55-0.23,10.777-6.94c1.243-6.788-5.186-11.578-5.251-11.626
			c-0.167-0.122-0.203-0.356-0.081-0.524s0.356-0.203,0.524-0.081c0.068,0.051,6.872,5.121,5.546,12.366
			c-1.339,7.316-11.408,7.554-11.51,7.556H209.264z"
          />
          <G>
            <Path
              d="M204.228,93.184c0,0-0.731,1.21-0.816,1.857c-0.084,0.647,0.028,1.913,2.054,3.066
				s7.258,2.025,12.237-1.407c0,0,0.169-1.266-1.8-3.488"
              fill={svgHighlight}
            />
            <Path
              d="M210.067,99.497c-2.037,0-3.752-0.476-4.787-1.064c-2.177-1.24-2.338-2.688-2.24-3.439
				c0.093-0.706,0.788-1.873,0.867-2.004c0.109-0.177,0.336-0.232,0.516-0.127c0.177,0.107,0.233,0.338,0.127,0.516
				c-0.192,0.317-0.705,1.246-0.766,1.712c-0.129,0.991,0.517,1.922,1.867,2.69c2.054,1.171,7.015,1.839,11.674-1.276
				c-0.038-0.372-0.277-1.435-1.703-3.043c-0.138-0.155-0.123-0.393,0.031-0.529c0.155-0.137,0.394-0.122,0.529,0.031
				c2.025,2.285,1.912,3.64,1.893,3.787c-0.015,0.104-0.072,0.199-0.159,0.259C215.235,98.856,212.434,99.497,210.067,99.497z"
            />
          </G>
          <Path
            d="M210.066,99.374c-2.012,0-3.703-0.469-4.725-1.051c-2.007-1.143-2.292-2.434-2.178-3.314
			c0.091-0.691,0.819-1.903,0.851-1.954c0.072-0.118,0.227-0.155,0.343-0.085c0.118,0.071,0.156,0.225,0.085,0.343
			c-0.196,0.325-0.719,1.273-0.782,1.761c-0.095,0.729,0.163,1.811,1.93,2.816c2.09,1.189,7.143,1.872,11.865-1.323
			c-0.018-0.328-0.199-1.45-1.74-3.188c-0.091-0.104-0.082-0.262,0.021-0.354c0.104-0.092,0.262-0.081,0.354,0.021
			c2.021,2.282,1.868,3.631,1.861,3.688c-0.01,0.07-0.048,0.133-0.106,0.173C215.186,98.738,212.409,99.374,210.066,99.374z"
          />
          <Ellipse cx="210.108" cy="91.116" fill="#FFFFFF" rx="6.245" ry="5.471" />
          <Path
            d="M210.107,96.963c-3.65,0-6.62-2.623-6.62-5.847s2.97-5.847,6.62-5.847s6.62,2.623,6.62,5.847
			S213.758,96.963,210.107,96.963z M210.107,86.019c-3.236,0-5.87,2.286-5.87,5.097s2.634,5.097,5.87,5.097s5.87-2.286,5.87-5.097
			S213.344,86.019,210.107,86.019z"
          />
          <Ellipse cx="211.38" cy="89.33" rx="0.373" ry="0.436" />
          <Ellipse cx="206.753" cy="89.33" rx="0.373" ry="0.436" />
          <Path
            d="M208.922,94.054c-1.622,0-2.68-1.089-2.695-1.105c-0.095-0.101-0.09-0.258,0.01-0.353s0.258-0.091,0.354,0.009
			c0.083,0.087,2.081,2.118,4.812-0.024c0.107-0.085,0.266-0.066,0.351,0.042c0.086,0.108,0.066,0.266-0.042,0.351
			C210.681,93.782,209.73,94.054,208.922,94.054z"
          />
          <Path d="M208.124,90.103l-5.739,0.675l5.781,1.392l0,0c0.516-0.552,0.532-1.404,0.038-1.975L208.124,90.103z" fill="#BAC2CA" />
          <Path
            d="M208.167,92.421c-0.02,0-0.039-0.002-0.059-0.007l-5.781-1.393c-0.118-0.028-0.198-0.137-0.191-0.258
			c0.008-0.121,0.101-0.22,0.221-0.233l5.738-0.675c0.083-0.008,0.165,0.021,0.219,0.085l0.08,0.093
			c0.579,0.669,0.561,1.661-0.044,2.309C208.302,92.392,208.235,92.421,208.167,92.421z M203.806,90.863l4.27,1.028
			c0.336-0.454,0.321-1.085-0.054-1.524L203.806,90.863z"
          />
          <G>
            <Circle cx="213.483" cy="82.382" fill={svgHighlight} r="1.252" />
            <Path
              d="M213.483,84.009c-0.897,0-1.627-0.729-1.627-1.627c0-0.897,0.729-1.627,1.627-1.627s1.627,0.73,1.627,1.627
				C215.11,83.279,214.381,84.009,213.483,84.009z M213.483,81.505c-0.483,0-0.877,0.394-0.877,0.877s0.394,0.877,0.877,0.877
				s0.877-0.394,0.877-0.877S213.967,81.505,213.483,81.505z"
            />
          </G>
          <G>
            <Path d="M207.673,84.489c0,0,1.721-2.513,5.134-2.158c3.414,0.355,3.25,4.834,3.25,4.834L207.673,84.489z" fill={svgHighlight} />
            <Path
              d="M216.058,87.541c-0.038,0-0.077-0.006-0.114-0.018l-8.384-2.677c-0.112-0.036-0.202-0.123-0.24-0.235
				c-0.039-0.112-0.021-0.236,0.045-0.334c0.075-0.11,1.866-2.698,5.482-2.318c2.977,0.31,3.646,3.55,3.586,5.221
				c-0.005,0.117-0.063,0.226-0.159,0.293C216.209,87.517,216.134,87.541,216.058,87.541z M208.319,84.302l7.353,2.348
				c-0.069-1.115-0.49-3.694-2.903-3.944C210.415,82.459,208.939,83.649,208.319,84.302z"
            />
          </G>
          <Path
            d="M206.247,86.707c0,0-0.084-1.35,0.97-1.983c1.055-0.633,3.671-1.16,6.245-0.169
			c2.574,0.992,3.038,3.523,2.658,4.831c0,0-1.603-3.101-6.161-3.481C209.96,85.905,207.913,85.842,206.247,86.707z"
            fill="#FFFFFF"
          />
          <Path
            d="M216.12,89.762c-0.139,0-0.268-0.077-0.333-0.202c-0.062-0.119-1.579-2.924-5.858-3.28l0,0
			c-0.021-0.001-1.961-0.043-3.509,0.761c-0.112,0.059-0.245,0.056-0.357-0.007c-0.11-0.062-0.182-0.176-0.189-0.303
			c-0.004-0.064-0.085-1.587,1.15-2.328c0.146-0.088,0.319-0.174,0.517-0.255c0.19-0.08,0.41,0.012,0.489,0.204
			c0.079,0.191-0.013,0.41-0.204,0.489c-0.158,0.065-0.298,0.134-0.415,0.204c-0.445,0.267-0.641,0.7-0.726,1.054
			c1.575-0.612,3.212-0.567,3.287-0.568c3.235,0.27,5.045,1.832,5.888,2.835c-0.012-0.321-0.067-0.657-0.164-0.979
			c-0.061-0.197,0.052-0.407,0.25-0.467c0.196-0.064,0.407,0.052,0.467,0.25c0.241,0.794,0.267,1.641,0.068,2.321
			c-0.043,0.147-0.171,0.253-0.323,0.269C216.144,89.761,216.132,89.762,216.12,89.762z"
          />
          <Path
            d="M216.005,87.7c-0.107,0-0.207-0.069-0.239-0.178c-0.208-0.686-0.802-1.932-2.442-2.562
			c-2.189-0.845-4.383-0.562-5.595-0.063c-0.129,0.05-0.273-0.009-0.326-0.137c-0.053-0.127,0.009-0.273,0.137-0.326
			c1.297-0.531,3.638-0.838,5.964,0.06c1.363,0.524,2.337,1.549,2.741,2.885c0.04,0.132-0.034,0.271-0.167,0.312
			C216.053,87.697,216.028,87.7,216.005,87.7z"
          />
        </G>
        <Path
          d="M238.556,116.788c0,0-23.667-0.82-28.052-2.048c-8.693-2.436-11.81-4.123-25.565-0.424
		c0,0,12.79-3.696,20.097-2.797C212.343,112.418,234.834,116.158,238.556,116.788z"
          fill="#DCE2E7"
        />
        <Path
          d="M238.57,117.163c-0.022,0-0.046-0.002-0.069-0.007c-0.219-0.041-22.027-4.109-28.17-4.747l-0.81-0.085
		c-6.024-0.632-11.712-1.229-19.508,1.123c-10.648,3.211-14.259,3.024-14.401,3.017c-0.207-0.013-0.363-0.19-0.351-0.396
		c0.014-0.206,0.198-0.383,0.396-0.352c0.038,0.001,3.681,0.168,14.139-2.987c7.941-2.396,13.972-1.762,19.803-1.15l0.81,0.085
		c6.172,0.641,28.01,4.715,28.23,4.757c0.203,0.037,0.337,0.233,0.299,0.438C238.905,117.037,238.748,117.163,238.57,117.163z"
        />
        <G>
          <Line fill="#E6EBEF" x1="80.614" x2="68.32" y1="113.266" y2="113.266" />
          <Path
            d="M80.614,113.516H68.32c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h12.293c0.138,0,0.25,0.112,0.25,0.25
			S80.752,113.516,80.614,113.516z"
          />
        </G>
        <G>
          <Line fill="#E6EBEF" x1="267.331" x2="219.739" y1="113.266" y2="113.266" />
          <Path
            d="M267.331,113.516h-47.593c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h47.593c0.138,0,0.25,0.112,0.25,0.25
			S267.469,113.516,267.331,113.516z"
          />
        </G>
        <Path
          d="M56.894,113.219c-0.276,0-0.5-0.224-0.5-0.5V77.593c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v35.125
		C57.394,112.995,57.17,113.219,56.894,113.219z"
        />
        <Polygon
          fill="#BAC2CA"
          points="33.163,100.463 39.646,80.059 35.451,80.059 44.413,56.223 40.027,56.223 56.427,15.416
		57.386,15.416 73.786,56.223 69.4,56.223 78.362,80.059 74.167,80.059 80.65,100.463 	"
        />
        <Path
          d="M62.154,26.626c0,0-3.662,8.482-14.685,11.584l8.958-22.794c0,0,1.019-0.075,1.131,0.224
		C57.67,15.939,62.154,26.626,62.154,26.626z"
          fill="#FFFFFF"
        />
        <Path
          d="M77.733,78.709c0,0-2.915-3.288-8.071-3.139c-5.157,0.149-5.231,3.363-10.537,1.644
		s-8.445-0.598-10.089,0.374c-1.644,0.972-4.559,2.391-4.559,2.391h33.78L77.733,78.709z"
          fill="#FFFFFF"
        />
        <Path
          d="M34.009,98.433c0,0,2.871-4.672,7.949-4.46s5.152,4.779,10.378,2.336s8.317-0.85,9.937,0.531
		c1.619,1.381,4.49,3.398,4.49,3.398h-33.27L34.009,98.433z"
          fill="#FFFFFF"
        />
        <Path
          d="M40.369,56.146h17.917c0,0-2.177-0.192-5.241-1.238c-3.064-1.046-3.972-2.427-6.675-2.152
		c-3.386,0.345-5.552,2.044-5.552,2.044L40.369,56.146z"
          fill="#FFFFFF"
        />
        <Path
          d="M80.65,100.838H33.163c-0.12,0-0.232-0.057-0.303-0.153c-0.07-0.097-0.091-0.222-0.055-0.335l6.328-19.916h-3.682
		c-0.123,0-0.238-0.061-0.308-0.162c-0.07-0.101-0.086-0.23-0.043-0.345l8.771-23.329h-3.844c-0.125,0-0.241-0.062-0.311-0.165
		s-0.084-0.234-0.038-0.35l16.399-40.807c0.057-0.142,0.195-0.235,0.348-0.235h0.959c0.153,0,0.291,0.093,0.348,0.235l16.399,40.807
		c0.046,0.115,0.032,0.247-0.038,0.35s-0.186,0.165-0.311,0.165h-3.844l8.772,23.329c0.043,0.115,0.027,0.244-0.043,0.345
		c-0.07,0.101-0.185,0.162-0.308,0.162H74.68l6.328,19.916c0.036,0.113,0.016,0.238-0.055,0.335
		C80.882,100.781,80.77,100.838,80.65,100.838z M33.675,100.088h46.462l-6.328-19.915c-0.036-0.114-0.016-0.239,0.055-0.335
		c0.071-0.097,0.183-0.154,0.303-0.154h3.654l-8.772-23.329c-0.043-0.115-0.027-0.244,0.043-0.345
		c0.07-0.101,0.185-0.162,0.308-0.162h3.831L57.133,15.791H56.68L40.582,55.848h3.831c0.123,0,0.238,0.061,0.308,0.162
		c0.07,0.101,0.086,0.23,0.043,0.345l-8.771,23.329h3.653c0.12,0,0.232,0.057,0.303,0.154c0.07,0.096,0.091,0.221,0.055,0.335
		L33.675,100.088z"
        />
        <Path
          d="M58.375,56.349H44.274c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h14.101c0.138,0,0.25,0.112,0.25,0.25
		S58.513,56.349,58.375,56.349z"
        />
        <Path
          d="M74.761,80.179H55.315c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h19.445c0.138,0,0.25,0.112,0.25,0.25
		S74.899,80.179,74.761,80.179z"
        />
        <Path
          d="M57.034,37.985L57.034,37.985c0.722,0,1.308-0.586,1.308-1.308v-5.456h-2.616v5.456
		C55.727,37.4,56.312,37.985,57.034,37.985z"
          fill="#FFFFFF"
        />
        <Path
          d="M53.298,39.315L53.298,39.315c0.472,0,0.854-0.382,0.854-0.854v-3.562h-1.708v3.562
		C52.444,38.933,52.826,39.315,53.298,39.315z"
          fill="#FFFFFF"
        />
        <Circle cx="55.952" cy="89.469" fill="#FFFFFF" r="1.624" />
        <Circle cx="49.028" cy="64.931" fill="#FFFFFF" r="1.521" />
        <Circle cx="62.644" cy="48.508" fill="#FFFFFF" r="1.431" />
        <Circle cx="45.657" cy="85.842" fill="#FFFFFF" r="0.772" />
        <Circle cx="70.244" cy="89.364" fill="#FFFFFF" r="0.772" />
        <Circle cx="62.614" cy="68.233" fill="#FFFFFF" r="0.772" />
        <Circle cx="62.644" cy="41.005" fill="#FFFFFF" r="0.772" />
        <Circle cx="52.061" cy="46.89" fill="#FFFFFF" r="0.625" />
        <Path
          d="M78.904,115.549c0,0-23.667-0.82-28.052-2.048c-8.693-2.436-11.81-4.123-25.565-0.424
		c0,0,12.79-3.696,20.097-2.797C52.691,111.179,75.182,114.919,78.904,115.549z"
          fill="#DCE2E7"
        />
        <Path
          d="M78.919,115.924c-0.023,0-0.046-0.002-0.069-0.007c-0.22-0.041-22.028-4.108-28.17-4.747l-0.811-0.085
		c-6.022-0.633-11.711-1.23-19.507,1.123c-10.649,3.211-14.254,3.021-14.402,3.017c-0.206-0.013-0.362-0.19-0.35-0.396
		c0.012-0.207,0.178-0.389,0.397-0.352c0.036,0.001,3.675,0.17,14.139-2.987c7.941-2.396,13.971-1.762,19.803-1.15l0.809,0.085
		c6.172,0.642,28.011,4.715,28.23,4.757c0.204,0.037,0.338,0.233,0.3,0.438C79.253,115.798,79.096,115.924,78.919,115.924z"
        />
        <Path
          d="M257.717,76.659l0.012,0c-6.763-40.829-16.253-39-16.253-39l-0.015,0.139l-0.012-0.105
		c0,0-9.108-1.862-15.871,38.967l0.039-0.001c-0.201,1.249-0.312,2.537-0.312,3.858c0,11.018,7.326,19.949,16.362,19.949
		c9.036,0,16.362-8.931,16.362-19.949C258.029,79.196,257.918,77.908,257.717,76.659z"
          fill="#DCE2E7"
        />
        <Path
          d="M258.029,80.516c0-1.32-0.111-2.608-0.312-3.857l0.012,0c-6.763-40.829-16.253-39-16.253-39l-0.015,0.139
		l-0.012-0.105c0,0-0.583-0.118-1.563,0.364c2.412,4.904,10.051,21.545,10.808,37.062c0.694,14.23-4.013,21.576-9.167,25.338
		c0.047,0,0.093,0.009,0.141,0.009C250.704,100.465,258.029,91.534,258.029,80.516z"
          fill="#BAC2CA"
        />
        <Path
          d="M233.793,46.026c0,0,1.051,4.77,6.387,5.093c5.336,0.323,12.369,4.608,14.309,10.105
		c0,0-3.234-14.956-8.569-20.534c-5.336-5.578-7.842-1.051-8.569-0.081C236.622,41.579,233.793,46.026,233.793,46.026z"
          fill="#FFFFFF"
        />
        <Path
          d="M241.668,100.84c-9.229,0-16.737-9.117-16.737-20.324c0-1.241,0.099-2.506,0.292-3.766
		c-0.017-0.049-0.023-0.101-0.015-0.152c4.712-28.449,10.634-36.285,13.744-38.425c1.189-0.818,2.041-0.885,2.399-0.868
		c0.018-0.006,0.035-0.011,0.054-0.015c0.115-0.022,1.163-0.183,2.777,0.94c3.192,2.222,9.245,10.162,13.917,38.368
		c0.005,0.032,0.006,0.063,0.003,0.095c0.2,1.278,0.302,2.563,0.302,3.822C258.404,91.722,250.896,100.84,241.668,100.84z
		 M225.973,76.57c0.017,0.048,0.022,0.098,0.015,0.148c-0.203,1.27-0.307,2.547-0.307,3.798c0,10.793,7.172,19.574,15.987,19.574
		c8.814,0,15.986-8.78,15.986-19.574c0-1.25-0.104-2.528-0.307-3.797c-0.005-0.03-0.006-0.061-0.004-0.091
		c-6.012-36.214-14.164-38.486-15.587-38.599c-0.068,0.087-0.174,0.143-0.292,0.144c-0.121-0.022-0.205-0.044-0.274-0.116
		c-0.307,0.017-0.981,0.14-1.909,0.803C236.295,40.992,230.601,48.726,225.973,76.57z"
        />
        <Path
          d="M241.463,113.478c-0.207,0-0.375-0.168-0.375-0.375V80.302c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v32.801C241.838,113.31,241.67,113.478,241.463,113.478z"
        />
        <Path
          d="M241.286,88.887c-0.075,0-0.151-0.022-0.218-0.069l-5.973-4.254c-0.169-0.12-0.208-0.354-0.088-0.523
		c0.12-0.168,0.354-0.207,0.523-0.088l5.973,4.254c0.169,0.12,0.208,0.354,0.088,0.523
		C241.518,88.832,241.403,88.887,241.286,88.887z"
        />
        <Path
          d="M241.613,93.877c-0.117,0-0.232-0.055-0.306-0.157c-0.12-0.169-0.081-0.403,0.088-0.523l5.972-4.254
		c0.169-0.12,0.402-0.08,0.523,0.088c0.12,0.169,0.081,0.403-0.088,0.523l-5.972,4.254
		C241.765,93.854,241.688,93.877,241.613,93.877z"
        />
        <Path
          d="M230.732,66.791c-0.013,0-0.025-0.001-0.039-0.003c-0.137-0.021-0.229-0.15-0.208-0.286
		c0.91-5.762,2.82-11.551,4.263-15.392c0.049-0.129,0.19-0.197,0.322-0.146c0.129,0.048,0.194,0.192,0.146,0.322
		c-1.435,3.819-3.334,9.574-4.237,15.294C230.96,66.703,230.853,66.791,230.732,66.791z"
        />
        <Path
          d="M240.425,57.979L240.425,57.979c-0.781,0-1.415-0.633-1.415-1.415v-5.902h2.83v5.902
		C241.84,57.345,241.206,57.979,240.425,57.979z"
          fill="#FFFFFF"
        />
        <Path
          d="M244.467,55.537L244.467,55.537c-0.51,0-0.924-0.414-0.924-0.924V50.76h1.847v3.853
		C245.391,55.123,244.977,55.537,244.467,55.537z"
          fill="#FFFFFF"
        />
        <Circle cx="251.326" cy="74.181" fill="#FFFFFF" r="1.459" />
        <Circle cx="232.377" cy="77.745" fill="#FFFFFF" r="2.715" />
        <Circle cx="239.086" cy="65.146" fill="#FFFFFF" r="1.073" />
        <Circle cx="84.643" cy="45.964" fill="#FFFFFF" r="2.053" />
        <Circle cx="26.86" cy="86.79" fill="#FFFFFF" r="2.053" />
        <Circle cx="230.976" cy="106.306" fill="#FFFFFF" r="2.053" />
        <Circle cx="86.077" cy="76.225" fill="#FFFFFF" r="2.053" />
        <Circle cx="192.462" cy="95.183" fill="#FFFFFF" r="2.053" />
        <Circle cx="89.436" cy="59.855" fill="#FFFFFF" r="1.589" />
        <Circle cx="275.289" cy="77.044" fill="#FFFFFF" r="1.589" />
        <Circle cx="266.291" cy="95.37" fill="#FFFFFF" r="2.053" />
        <Circle cx="256.345" cy="106.188" fill="#FFFFFF" r="1.589" />
        <Circle cx="26.045" cy="106.812" fill="#FFFFFF" r="1.402" />
        <Circle cx="26.958" cy="69.377" fill="#FFFFFF" r="1.589" />
        <Circle cx="101.5" cy="46.572" fill="#FFFFFF" r="0.881" />
        <Circle cx="15.071" cy="81.799" fill="#FFFFFF" r="0.881" />
        <Circle cx="71.81" cy="106.973" fill="#FFFFFF" r="0.881" />
        <Circle cx="92.207" cy="35.517" fill="#FFFFFF" r="0.881" />
        <Circle cx="266.409" cy="72.444" fill="#FFFFFF" r="0.881" />
        <Circle cx="222.322" cy="41.661" fill="#FFFFFF" r="0.881" />
        <Circle cx="72.793" cy="35.502" fill="#FFFFFF" r="1.779" />
        <Circle cx="262.764" cy="53.765" fill="#FFFFFF" r="1.779" />
        <Circle cx="15.401" cy="99.415" fill="#FFFFFF" r="1.779" />
        <Circle cx="94.575" cy="90.154" fill="#FFFFFF" r="1.779" />
        <Circle cx="120.336" cy="10.068" fill="#FFFFFF" r="1.779" />
        <Circle cx="216.675" cy="65.089" fill="#FFFFFF" r="1.789" />
        <Circle cx="195.757" cy="41.892" fill="#FFFFFF" r="2.477" />
        <Circle cx="201.055" cy="74.125" fill="#FFFFFF" r="1.184" />
        <Circle cx="210.555" cy="48.133" fill="#FFFFFF" r="1.598" />
        <Circle cx="195.326" cy="59.373" fill="#FFFFFF" r="2.11" />
      </G>
    </Svg>
  );
};

export default MarketingWinter;
