import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import { BaseExternalLink, H2, P2 } from '../base_ui';

export const Heading = styled(H2)`
  font-size: ${moderateScale(22)}px;
  margin: ${moderateScale(26)}px 0 ${moderateScale(10)}px;
  text-align: center;
`;

export const Link = styled(BaseExternalLink)`
  font-size: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
`;

export const Text = styled(P2)`
  margin-top: ${moderateScale(12)}px;
  text-align: center;
`;
