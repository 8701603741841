import React, { Fragment } from 'react';
import T from 'prop-types';
import { Linking, Text } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';
import { StyledPhoneLinkText } from './styledComponents';

const warning = iconDictionary('warning');

const CardLevelError = ({ customerServicePhoneNumber, ...props }) => {
  const body = (
    <Fragment>
      {/* eslint-disable-next-line max-len */}
      <Text>An error has occurred while trying to retrieve your documents. If this issue persists, please call customer service at </Text>
      <StyledPhoneLinkText
        onPress={() => Linking.openURL(`tel://${customerServicePhoneNumber.replace(/\+|-/g, '')}`)}
      >
        {customerServicePhoneNumber}
      </StyledPhoneLinkText>
      <Text>.</Text>
    </Fragment>
  );
  return (
    <Message
      body={body}
      icon={warning}
      title="Try Again Later."
      {...props}
    />
  );
};

CardLevelError.propTypes = { customerServicePhoneNumber: T.string.isRequired };

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(CardLevelError);
