/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavPaymentsDefaultMIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M17.2796 4H0.689609C0.533935 4.00257 0.385357 4.06556 0.275263 4.17565C0.16517 4.28575 0.102183 4.43433 0.0996094 4.59V13.59C0.0996094 13.7465 0.16177 13.8965 0.272416 14.0072C0.383063 14.1178 0.533132 14.18 0.689609 14.18H17.2796C17.3575 14.1814 17.4348 14.167 17.507 14.1378C17.5792 14.1087 17.6448 14.0653 17.6998 14.0102C17.7549 13.9552 17.7983 13.8896 17.8275 13.8174C17.8566 13.7452 17.871 13.6679 17.8696 13.59V4.59C17.8696 4.43352 17.8074 4.28345 17.6968 4.17281C17.5862 4.06216 17.4361 4 17.2796 4ZM16.6896 13H1.28961V5.21H16.6896V13Z" fill="black" />
        <Path d="M9.00029 12C9.54007 11.998 10.0672 11.8366 10.5156 11.536C10.964 11.2355 11.3136 10.8092 11.5206 10.3107C11.7275 9.81217 11.7827 9.26362 11.679 8.73389C11.5754 8.20415 11.3176 7.71683 10.938 7.33309C10.5583 6.94934 10.0739 6.68627 9.54528 6.57688C9.0167 6.46749 8.46759 6.51666 7.96685 6.71822C7.46612 6.91978 7.03607 7.26474 6.73069 7.70983C6.4253 8.15491 6.25818 8.68028 6.25029 9.22C6.24498 9.58402 6.31225 9.94544 6.4482 10.2832C6.58414 10.6209 6.78602 10.9281 7.04205 11.187C7.29807 11.4458 7.60311 11.651 7.93934 11.7906C8.27556 11.9302 8.63624 12.0014 9.00029 12ZM9.00029 7.44C9.35431 7.44198 9.6998 7.5489 9.99305 7.74723C10.2863 7.94556 10.5142 8.2264 10.6478 8.55423C10.7815 8.88206 10.8149 9.24216 10.7439 9.58899C10.6729 9.93583 10.5006 10.2538 10.2489 10.5027C9.99717 10.7517 9.67727 10.9204 9.32967 10.9875C8.98207 11.0546 8.62236 11.0172 8.29605 10.8799C7.96973 10.7426 7.69146 10.5116 7.49641 10.2161C7.30137 9.92068 7.19832 9.57402 7.20029 9.22C7.20028 8.98456 7.24697 8.75145 7.33767 8.53417C7.42838 8.3169 7.56128 8.11978 7.72869 7.95423C7.89611 7.78867 8.0947 7.65798 8.31297 7.56971C8.53125 7.48144 8.76486 7.43736 9.00029 7.44Z" fill="black" />
        <Path d="M3.80951 10.1199C3.98158 10.1199 4.14979 10.0689 4.29286 9.97326C4.43593 9.87766 4.54744 9.74179 4.61329 9.58282C4.67914 9.42385 4.69636 9.24892 4.6628 9.08015C4.62923 8.91139 4.54637 8.75637 4.42469 8.6347C4.30302 8.51303 4.148 8.43017 3.97924 8.3966C3.81048 8.36303 3.63555 8.38026 3.47658 8.44611C3.31761 8.51196 3.18173 8.62347 3.08613 8.76654C2.99054 8.90961 2.93951 9.07781 2.93951 9.24988C2.93817 9.3645 2.95976 9.47824 3.00301 9.5844C3.04625 9.69056 3.11029 9.787 3.19134 9.86805C3.2724 9.94911 3.36884 10.0131 3.475 10.0564C3.58116 10.0996 3.69489 10.1212 3.80951 10.1199Z" fill="black" />
        <Path d="M14.1503 10.1199C14.3223 10.1199 14.4905 10.0689 14.6336 9.97326C14.7767 9.87766 14.8882 9.74179 14.954 9.58282C15.0199 9.42385 15.0371 9.24892 15.0036 9.08015C14.97 8.91139 14.8871 8.75637 14.7655 8.6347C14.6438 8.51303 14.4888 8.43017 14.32 8.3966C14.1512 8.36303 13.9763 8.38026 13.8173 8.44611C13.6584 8.51196 13.5225 8.62347 13.4269 8.76654C13.3313 8.90961 13.2803 9.07781 13.2803 9.24988C13.2803 9.48062 13.3719 9.70191 13.5351 9.86507C13.6982 10.0282 13.9195 10.1199 14.1503 10.1199Z" fill="black" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="18" width="18" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
