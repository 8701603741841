/**
*
* BaseInternalLink
* @description Internal link used as base
* API Usage: {
  Icon: Optional icon displayed to the left or right of the label
  isRightIcon: Optional boolean, positions icon to the left or right
  label: Text link label
  path: Screen name to link to
  size: Size of the label text, small or medium
* }
*/

import React, { useCallback, useState } from 'react';
import T from 'prop-types';
import { useNavigation } from '@react-navigation/native';

import ConditionalRender from '../ConditionalRender';
import {
  ContainerWrapper,
  DisclosureLinkContainer,
  DisclosureStyledText,
  LinkContainer,
  StyledIcon,
  StyledText,
} from './styledComponents';

const BaseInternalLink = ({
  Icon,
  isDisclosureLink = false,
  isFooterLink = false,
  isRightIcon = true,
  label,
  onPressFunc,
  options = {},
  path,
  replace = false,
  size,
  ...restProps
}) => {
  const navigation = useNavigation();
  const [isPressed, setIsPressed] = useState(false);
  const handleOpenWithLinking = useCallback(() => {
    const navigationMethod = replace ? 'replace' : 'navigate';
    if (path) { navigation[navigationMethod](path, options); }
    if (onPressFunc) { onPressFunc(); }
  }, [navigation, onPressFunc, options, path, replace]);

  if (isDisclosureLink) {
    /**
     * Due to the Pressable wrapper, the BaseUI external link component does not easily
     * style dynamically within the EditorContent (does not remain inline with text) so a custom
     * link component was created for the Disclosure pages.
     */
    return (
      <DisclosureLinkContainer onPress={handleOpenWithLinking} role="link">
        <DisclosureStyledText {...restProps}>{label}</DisclosureStyledText>
      </DisclosureLinkContainer>
    );
  }

  return (
    <ContainerWrapper role="link" {...restProps}>
      <LinkContainer
        $pressed={isPressed}
        isFooterLink={isFooterLink}
        isRightIcon={isRightIcon}
        onPress={handleOpenWithLinking}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
      >
        <ConditionalRender
          Component={StyledIcon}
          propsToPassDown={{ Icon, isRightIcon }}
          shouldRender={!!Icon}
        />
        <StyledText
          isFooterLink={isFooterLink}
          size={size}
        >
          {label}
        </StyledText>
      </LinkContainer>
    </ContainerWrapper>
  );
};

BaseInternalLink.propTypes = {
  Icon: T.element,
  isDisclosureLink: T.bool,
  isFooterLink: T.bool,
  isRightIcon: T.bool,
  label: T.string.isRequired,
  onPressFunc: T.func,
  options: T.object,
  path: T.string,
  replace: T.bool,
  size: T.oneOf(['small', 'medium', 'large']),
};

export default BaseInternalLink;
