import React from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Profile/messages';
/* eslint-disable object-curly-newline */
import {
  PUT_COMMUNICATIONS_SUCCESS_DICT,
} from '@dmi/shared/redux/Settings/Communications/constants';

import { ConditionalRender, H5, NativeAnimation, P2, WebAnimation } from '../../../../base_ui';
import {
  AnimationContainer,
  ConfirmationRow,
  Container,
  StyledH2,
  StyledP2,
} from './styledComponents';

const Success = ({ flowId, selectedLoan }) => {
  const { heading, text } = PUT_COMMUNICATIONS_SUCCESS_DICT[flowId] || {};

  return (
    <Container>
      <AnimationContainer>
        <ConditionalRender
          Component={<WebAnimation name="robotMobile" />}
          FallbackComponent={<NativeAnimation name="robotMobile" />}
          shouldRender={Platform.OS === 'web'}
        />
      </AnimationContainer>
      <StyledH2>{heading}</StyledH2>
      <StyledP2>{text}</StyledP2>
      <ConditionalRender
        Component={(
          <ConfirmationRow>
            <H5><FormattedMessage id={INTL_IDS.CONFIRMATION_ROW_LOANNUMBER} /></H5>
            <P2>{selectedLoan}</P2>
          </ConfirmationRow>
        )}
        shouldRender={flowId !== 'alerts'}
      />
    </Container>
  );
};

Success.propTypes = {
  flowId: T.string.isRequired,
  selectedLoan: T.string,
};

export default Success;
