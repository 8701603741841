import React, { useState } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../../../utils/iconDictionary';
import { P3 } from '../../../../../base_ui';
import {
  IconView,
  PressableRow,
  RowText,
  RowType,
} from '../../styledComponents';

const ArrowIcon = iconDictionary('arrowRightM');

const DataItem = ({
  amount,
  date,
  handleRowPress,
  id,
  type,
}) => {
  const [isPressing, setIsPressing] = useState(false);

  return (
    <PressableRow
      $isPressing={isPressing}
      aria-label={`view transaction details, ${type}, ${date}`}
      onPress={() => handleRowPress(id)}
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      role="button"
    >
      <RowText>{date}</RowText>
      <RowType>{type}</RowType>
      <P3>{amount}</P3>
      <IconView>
        {ArrowIcon}
      </IconView>
    </PressableRow>
  );
};

DataItem.propTypes = {
  amount: T.string.isRequired,
  date: T.string.isRequired,
  handleRowPress: T.func.isRequired,
  id: T.string.isRequired,
  type: T.string.isRequired,
};

export default DataItem;
