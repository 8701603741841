import React from 'react';
import T from 'prop-types';
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
} from 'victory';
import { useTheme } from 'styled-components/native';

import { chartAxisFormatter } from '@dmi/shared/redux/Calculators/Amortize/helpers';
import { AMORTIZATION_CHART_DESCRIPTION } from '@dmi/shared/redux/Calculators/Amortize/constants';
import { DEFAULT_MOBILE_THEME_COLORS } from '@dmi/shared/redux/Main/constants';

import { moderateScale } from '../../../utils/scaleHelpers';
import { WebChartContainer } from './styledComponents';

const WebChart = ({ chartLines, label }) => {
  const { fontFamilies, fontSizes } = useTheme();
  const { secondaryFont } = fontFamilies;
  const { primaryCarbon, primaryGray } = DEFAULT_MOBILE_THEME_COLORS;
  const { f2 } = fontSizes;

  const axisStyle = {
    axis: { stroke: primaryGray },
    grid: { stroke: primaryGray, strokeDasharray: '2, 2' },
    tickLabels: {
      color: primaryCarbon,
      fontFamily: secondaryFont,
      fontSize: f2,
      padding: 3,
    },
  };

  return (
    <WebChartContainer>
      <VictoryChart
        aria-label={`${label}`}
        desc={`${label} ${AMORTIZATION_CHART_DESCRIPTION}`}
        height={moderateScale(293)}
        padding={{
          bottom: moderateScale(25),
          left: moderateScale(30),
          right: moderateScale(5),
          top: moderateScale(8),
        }}
        width={moderateScale(327)}
      >
        <VictoryAxis
          dependentAxis
          style={axisStyle}
          tickFormat={chartAxisFormatter}
        />
        <VictoryAxis
          crossAxis
          style={axisStyle}
        />
        {
          chartLines.map(({
            data,
            mobileLineColor,
            name,
            opacity,
            strokeWidth,
          }) => (
            <VictoryLine
              key={name}
              data={data}
              style={{
                data: {
                  opacity,
                  stroke: DEFAULT_MOBILE_THEME_COLORS[mobileLineColor],
                  strokeWidth,
                },
              }}
            />
          ))
        }
      </VictoryChart>
    </WebChartContainer>
  );
};

WebChart.propTypes = {
  chartLines: T.arrayOf(T.shape({
    data: T.array.isRequired,
    mobileLineColor: T.string.isRequired,
    name: T.string.isRequired,
    opacity: T.string,
  })).isRequired,
  label: T.string.isRequired,
};

export default WebChart;
