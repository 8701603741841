/*
 * Add Loan Screen
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';

import {
  addLoan,
  changeInput,
  inputErrors,
  resetErrorState,
  resetFormState,
} from '@dmi/shared/redux/Settings/Loans/actions';
import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import { validateFields } from '@dmi/shared/redux/Settings/Shared/helpers';
import {
  selectBannerError,
  selectForm,
  selectFormErrors,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Loans/selectors';

import { StyledScrollView } from '../../../components/base_ui';
import { AddLoanComponent } from '../../../components/Settings/AddLoan';
import { StyledSecondaryHeader } from './styledComponents';

const AddLoanScreen = ({
  bannerError,
  dispatchAddLoan,
  dispatchChangeInput,
  dispatchInputErrors,
  dispatchResetErrorState,
  dispatchResetFormState,
  form,
  formErrors,
  navigation,
  status,
}) => {
  const { formatMessage } = useIntl();
  const { header } = LOANS_CONTENT_DICTIONARY.addLoan.addLoanView;

  useEffect(() => () => {
    dispatchResetErrorState();
    dispatchResetFormState(['addLoan']);
  }, [dispatchResetErrorState, dispatchResetFormState]);

  const handleAddLoan = () => {
    const { isValidated, validationErrors } = validateFields({
      formErrors,
      values: form,
    });
    if (isValidated) {
      dispatchAddLoan({ ...form });
    }
    dispatchInputErrors({ form: 'addLoan', formErrors: validationErrors });
  };

  return (
    <StyledScrollView>
      <StyledSecondaryHeader
        handleBack={navigation.goBack}
        title={formatMessage({ id: header })}
      />
      <AddLoanComponent
        bannerError={bannerError}
        dispatchChangeInput={dispatchChangeInput}
        form={form}
        formErrors={formErrors}
        handleAddLoan={handleAddLoan}
        status={status}
      />
    </StyledScrollView>
  );
};

AddLoanScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchAddLoan: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchInputErrors: T.func.isRequired,
  dispatchResetErrorState: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  form: T.object.isRequired,
  formErrors: T.object.isRequired,
  navigation: T.object.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  bannerError: selectBannerError(),
  form: selectForm('addLoan'),
  formErrors: selectFormErrors('addLoan'),
  status: selectRequestStatus('addLoan'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loans
   */
  dispatchAddLoan: (payload) => dispatch(addLoan(payload)),
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchInputErrors: (payload) => dispatch(inputErrors(payload)),
  dispatchResetErrorState: () => dispatch(resetErrorState()),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(AddLoanScreen);
