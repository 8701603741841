import styled from 'styled-components/native';

import { StatusBanner } from '../../../components/base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const StyledStatusBanner = styled(StatusBanner)`
  margin: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const StyledView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
`;
