import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

import { H2 } from '../../base_ui';

export const HeaderText = styled(H2)`
  margin: ${moderateScale(24)}px 0 ${moderateScale(24)}px;
  text-align: center;
`;

export const ProceedWrapper = styled.View`
  display: flex;
  height: 100%;
  justify-content: space-between;
`;
