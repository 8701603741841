import styled, { css } from 'styled-components/native';

import ModalDialog from '../ModalDialog';
import { ARROW_SIDE } from './constants';
import { moderateScale } from '../../../utils/scaleHelpers';

export const StyledTriangle = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-bottom-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-left-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-right-color: transparent;
  border-style: solid;
  border-top-color: transparent;
  border-width: 1px;
  height: ${ARROW_SIDE}px;
  position: absolute;
  transform:
    rotate(${({ rotate }) => rotate}deg)
    translateX(${({ translateX }) => translateX || 0}px)
    translateY(${({ translateY }) => translateY || 0}px);
  width: ${ARROW_SIDE}px;
  ${({
    bottom,
    left,
    right,
    top,
  }) => {
    const bottomProp = bottom ? `bottom: ${bottom};` : '';
    const leftProp = left ? `left: ${left};` : '';
    const rightProp = right ? `right: ${right};` : '';
    const topProp = top ? `top: ${top};` : '';
    return css`${bottomProp} ${leftProp} ${rightProp} ${topProp}`;
  }}
`;

export const MainView = styled.View`
  align-items: flex-start;
  height: 100%;
  justify-content: space-between;
  padding: ${moderateScale(16)}px;
`;

export const StyledModalDialog = styled(ModalDialog).attrs(({
  $positionAbsolute: {
    left,
    top,
  },
}) => ({
  modalStyles: {
    height: 'auto',
    left,
    position: 'absolute',
    top,
    width: moderateScale(258),
  },
}))``;
