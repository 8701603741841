/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterSectionFeature() {
  const { colors: { svgHighlight } } = useTheme();
  return (
    <Svg data-name="Layer 1" id="Layer_1" viewBox="0 0 85 85" xmlns="http://www.w3.org/2000/svg">
      <Rect fill="#e6ebef" height={moderateScale(48.35)} width={moderateScale(57.73)} x="17.71" y="12.37" />
      <Polygon fill="#fff" opacity="0.7" points="15.24 50.83 29.19 12.37 16.62 12.37 15.24 15.52 15.24 50.83" />
      <Polygon fill="#fff" opacity="0.7" points="32.63 60.71 24.81 60.71 43.06 12.37 50.89 12.37 32.63 60.71" />
      <Polyline fill="none" points="32.81 9.75 75.47 9.75 75.47 61.09 15.01 61.09 15.01 9.75 27.18 9.75" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" x1="28.81" x2="31.16" y1="9.75" y2="9.75" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" x1="35.71" x2="35.71" y1="16.24" y2="27.87" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" x1="54.72" x2="54.72" y1="57.26" y2="48.17" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" x1="54.72" x2="54.72" y1="43.84" y2="16.24" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" x1="21.37" x2="75.44" y1="35.82" y2="35.82" />
      <Polygon fill="#fff" points="62.14 46.64 61.45 41.93 57.99 40.97 51.48 39.01 51.48 33.55 48.41 29.02 45.9 25.08 45.24 21.63 42.35 18.13 43.06 13.2 57.2 12.41 62.14 20.35 60.34 27.87 63.81 29.2 62.55 32.04 71.13 32.04 70.67 39.73 62.55 47.47 62.14 46.64" />
      <Polyline fill="none" points="57.7 13.2 62.14 20.35 61.63 22.48" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.42px" />
      <Polyline fill="none" points="17.75 58.15 17.75 12.41 74.04 12.41" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.42px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" x1="35.71" x2="35.71" y1="32.2" y2="57.26" />
      <Path d="M39.3,60.83c0-6.54-4.19-10.26-9-10.26a8.81,8.81,0,0,0-8.24,6,7.25,7.25,0,0,0-6-3.26c-3.78,0-7.29,2.43-7.29,7.61" fill={svgHighlight} />
      <Path d="M39.3,59.46a9.27,9.27,0,0,0-9-9.09A8.78,8.78,0,0,0,22,56.11,7.33,7.33,0,0,0,8.73,59.55" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" />
      <Path d="M35.54,60.67c0-5.71,3.88-9.74,8.37-9.74a8.16,8.16,0,0,1,7.62,5.56,6.67,6.67,0,0,1,5.53-3c3.5,0,6.39,3.71,6.75,7.28" fill={svgHighlight} />
      <Path d="M35.54,59.53a8.57,8.57,0,0,1,8.37-8.4,8.13,8.13,0,0,1,7.62,5.31,6.77,6.77,0,0,1,12.28,3.18" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" />
      <Path d="M48.9,55a6.22,6.22,0,0,1,.77,1.11" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.42px" />
      <Path d="M44.16,52.71a7.24,7.24,0,0,1,3.74,1.37" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.42px" />
      <Path d="M5.69,61.05h68a0,0,0,0,1,0,0v13a0,0,0,0,1,0,0H8.24a2.56,2.56,0,0,1-2.56-2.56V61.05a0,0,0,0,1,0,0Z" fill="#fff" />
      <Path d="M11.55,74.07H7.81A2.12,2.12,0,0,1,5.69,72V61.05h68v13H13.21" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" />
      <Path d="M66.71,61.05h12a0,0,0,0,1,0,0V71.52a2.56,2.56,0,0,1-2.56,2.56H69.26a2.56,2.56,0,0,1-2.56-2.56V61.05a0,0,0,0,1,0,0Z" fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.62px" />
      <Polyline fill="none" points="62.14 46.64 61.45 41.93 57.99 40.97 51.48 39.01 51.48 33.55 48.41 29.02 45.9 25.08 45.24 21.63 42.35 18.13 43.06 13.2" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.42px" />
      <Polyline fill="none" points="61.28 23.95 60.34 27.87 63.81 29.2 62.55 32.04 71.13 32.04 70.67 39.73 62.55 47.47" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.42px" />
    </Svg>
  );
}
