/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../scaleHelpers';

export default function ProgressIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(32)} viewBox="0 0 32 32" width={moderateScale(32)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path clipRule="evenodd" d="M23.85 9.99639C24.0502 10.1967 24.0502 10.5214 23.85 10.7216L13.1833 21.3883C13.0846 21.487 12.95 21.5412 12.8104 21.5384C12.6709 21.5356 12.5385 21.4761 12.4438 21.3735L7.52077 16.0402C7.32866 15.8321 7.34164 15.5076 7.54975 15.3155C7.75787 15.1234 8.08231 15.1364 8.27441 15.3445L12.8355 20.2856L23.1247 9.99639C23.325 9.79612 23.6497 9.79612 23.85 9.99639Z" fill="#ffffff" fillRule="evenodd" stroke="#ffffff" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M30.7333 15.9999C30.7333 24.1369 24.1369 30.7333 15.9999 30.7333C7.86294 30.7333 1.2666 24.1369 1.2666 15.9999C1.2666 7.86294 7.86294 1.2666 15.9999 1.2666C24.1369 1.2666 30.7333 7.86294 30.7333 15.9999Z" fill={primary} stroke="#ffffff" strokeWidth="2" />
      <Path d="M13.1999 22.4C12.7999 22.4 12.5332 22.2667 12.2665 22L7.3332 16.8C6.9332 16.2667 6.9332 15.6 7.3332 15.0667C7.99987 14.6667 8.79987 14.6667 9.19987 15.2L13.1999 19.4667L22.6665 9.73335C23.1999 9.33335 23.9999 9.33335 24.3999 9.73335C24.9332 10.1333 24.9332 10.9333 24.3999 11.4667L13.9999 22C13.8665 22.2667 13.5999 22.4 13.1999 22.4Z" fill="#ffffff" />
    </Svg>
  );
}
