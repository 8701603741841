import React from 'react';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { Skeleton as BaseSkeleton } from '../../../../base_ui';

const Skeleton = () => (
  <BaseSkeleton
    $bottomMargin={`${moderateScale(4)}px`}
    $leftMargin={`${moderateScale(1)}px`}
    $rightMargin={`${moderateScale(1)}px`}
    $topMargin={`${moderateScale(4)}px`}
    height={`${moderateScale(10)}px`}
    width={`${moderateScale(160)}px`}
  />
);

export default Skeleton;
