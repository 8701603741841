import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../../../base_ui';
import Link from './Link';
import Span from './Span';
import { ListAdornment, ListItemContainer } from './styledComponents';

/* eslint-disable jsx-a11y/anchor-is-valid, react/no-array-index-key */
const ListItem = ({ handleNavigateLink, inlineBlocks, itemNumber }) => (
  <ListItemContainer>
    <ConditionalRender
      Component={<ListAdornment>{itemNumber}. </ListAdornment>}
      FallbackComponent={<ListAdornment>&bull;</ListAdornment>}
      shouldRender={!!itemNumber}
    />
    {inlineBlocks.map(({
      data,
      style,
      text,
      type,
    }, i) => (
      <ConditionalRender
        key={i}
        Component={(
          <Link data={data} handleNavigateLink={handleNavigateLink} text={text} />
        )}
        FallbackComponent={<Span style={style} text={text} />}
        shouldRender={type === 'link' && !!data && !!data.url}
      />
    ))}
  </ListItemContainer>
);

ListItem.propTypes = {
  handleNavigateLink: T.func.isRequired,
  inlineBlocks: T.array.isRequired,
  itemNumber: T.number,
};

export default ListItem;
