import styled from 'styled-components/native';
import { Pressable } from 'react-native';

import { CheckboxWithText, P2 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const CheckboxContainer = styled.View`
  align-items: center;
  align-self: flex-start;
  flex-direction: row;
`;

export const Container = styled.View`
  margin-bottom: ${moderateScale(26)}px;
  width: 100%;
`;

export const LinkText = styled(P2)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledCheckboxWithText = styled(CheckboxWithText)
  .attrs(({ textStyle, theme: { fontSizes: { f4 } } }) => (
    { textStyle: { ...textStyle, fontSize: f4 } }
  ))``;

export const StyledTextButton = styled(Pressable)`
  margin-left: ${moderateScale(26)}px;
`;
