import React from 'react';
import T from 'prop-types';

import {
  ContentContainer,
  DisbursedContainer,
  DisbursementsContainer,
} from './styledComponents';
import { H5, P2 } from '../../base_ui';

const DisbursementsComponent = ({ disbursed, disbursements }) => (
  <DisbursementsContainer>
    <DisbursedContainer>
      <H5>Disbursed: </H5><P2>{disbursed}</P2>
    </DisbursedContainer>
    {disbursements.map(({
      formattedDate,
      number,
      text,
      title,
    }) => (
      <ContentContainer key={number}>
        <H5>{title}</H5>
        <P2>{formattedDate}</P2>
        <P2>{text}</P2>
      </ContentContainer>
    ))}
  </DisbursementsContainer>
);

DisbursementsComponent.propTypes = {
  disbursed: T.string.isRequired,
  disbursements: T.arrayOf(
    T.shape({
      formattedDate: T.string.isRequired,
      number: T.string.isRequired,
      text: T.string.isRequired,
      title: T.string.isRequired,
    }),
  ).isRequired,
};

export default DisbursementsComponent;
