import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { UNAVAILABLE_LOANS_CONTENT_ERROR_TEXT } from '@dmi/shared/redux/Main/constants';
import makeSelectMain, {
  getMainClientInfo,
  getSelectedLoanData,
} from '@dmi/shared/redux/Main/selectors';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import AsyncRender from '../../components/AsyncRender';
import InactiveRedirect from '../../components/InactiveRedirect';
import { Container } from './styledComponents';

const InactiveRedirectScreen = ({
  customerServicePhoneNumber,
  loading,
  selectedLoanData: {
    inactiveRedirectUrl,
    onlineAvailabilityStatus,
  },
}) => (
  <Container>
    <AsyncRender
      Component={InactiveRedirect}
      error={!loading && !onlineAvailabilityStatus}
      errorComponentProps={{
        body: interpolate(UNAVAILABLE_LOANS_CONTENT_ERROR_TEXT, { customerServicePhoneNumber }),
        link: {},
      }}
      loading={loading}
      propsToPassDown={{
        customerServicePhoneNumber,
        inactiveRedirectUrl,
        onlineAvailabilityStatus,
      }}
    />
  </Container>
);

InactiveRedirectScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  loading: T.bool.isRequired,
  selectedLoanData: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  loading: makeSelectMain('loading'),
  selectedLoanData: getSelectedLoanData(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(InactiveRedirectScreen);
