/*
 * Change Email Step One Form Input
 */

import React, { Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import T from 'prop-types';
import {
  Platform,
  View,
} from 'react-native';
import Constants from 'expo-constants';

import { INTL_IDS } from '@dmi/shared/redux/Register/Main/messages';
import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import {
  BaseRecaptcha,
  ConditionalRender,
  LargeProgressButton,
  LoginRegisterTextField,
  PasswordTextField,
} from '../../base_ui';
import {
  ButtonsContainer,
  RecaptchaChangeEmailWrapper,
  RegisterInputContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const ChangeEmailStepOne = ({
  dispatchChangeEmail,
  dispatchChangeInput,
  dispatchResetState,
  dispatchVerifyRecaptcha,
  formErrors,
  formValues,
  handleNextClick,
  navigation,
  status,
}) => {
  const intl = useIntl();
  const handleBack = () => {
    dispatchResetState();
    navigation.goBack();
  };

  const loanNumberRef = useRef(null);
  const ssnTinRef = useRef(null);
  const zipCodeRef = useRef(null);
  const recaptchaRef = useRef(null);

  const handleChange = () => {
    const response = recaptchaRef.current.getValue();
    dispatchVerifyRecaptcha({ response });
  };

  const isSubmitDisabled = (
    !formValues.loanNumber ||
    !formValues.ssnTin ||
    !formValues.zipCode ||
    (Platform.OS === 'web' && !formValues.isRecaptchaVerified)
  );

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={loanNumberRef}
            error={formErrors.loanNumber}
            keyboardType="number-pad"
            label={intl.formatMessage({ id: INTL_IDS.REGISTER_FORM_LOAN_NUMBER_LABEL })}
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'loanNumber',
              form: 'changeEmailStepOne',
              value: newValue,
            })}
            onSubmitEditing={() => ssnTinRef.current.focus()}
            returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
            value={formValues.loanNumber}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <PasswordTextField
            ref={ssnTinRef}
            error={formErrors.ssnTin}
            keyboardType="number-pad"
            label="Last 4 Digits of SSN"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'ssnTin',
              form: 'changeEmailStepOne',
              value: newValue,
            })}
            onSubmitEditing={() => zipCodeRef.current.focus()}
            returnKeyType={Platform.OS === 'ios' ? 'done' : 'next'}
            value={formValues.ssnTin}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={zipCodeRef}
            error={formErrors.zipCode}
            keyboardType="number-pad"
            label="Property Zip Code"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'zipCode',
              form: 'changeEmailStepOne',
              value: newValue,
            })}
            returnKeyType="done"
            value={formValues.zipCode}
            /**
             * Recaptcha is only on web for now. So on web, Zip keyboard "Done"
             * shouldn't trigger submit, but on native can/should.
             *
             * (TODO/FIXME: on web, onSubmitEditing doesn't seem to be doing anything anyway
             * at the moment for any of these inputs...But theoretically we expect it to for the
             * other inputs.)
             */
            {...(Platform.OS !== 'web' && !isSubmitDisabled && {
              onSubmitEditing: () => handleNextClick({
                field: ['loanNumber', 'ssnTin', 'zipCode'],
                form: 'changeEmailStepOne',
                formValues,
                handleSubmitStep: () => dispatchChangeEmail(formValues),
              }),
            })}
          />
        </TextFieldWrapper>
        <RecaptchaChangeEmailWrapper>
          <ConditionalRender
            Component={(
              <BaseRecaptcha
                ref={recaptchaRef}
                onChange={handleChange}
                onExpired={() => dispatchChangeInput({
                  field: 'isRecaptchaVerified',
                  form: 'initial',
                  value: false,
                })}
                sitekey={Constants.expoConfig.extra.RECAPTCHA_SITE_KEY}
              />
            )}
            shouldRender={Platform.OS === 'web'}
          />
        </RecaptchaChangeEmailWrapper>
      </RegisterInputContainer>
      <View>
        <StyledPrimaryDivider />
        <ButtonsContainer>
          <LargeProgressButton
            disabled={isSubmitDisabled}
            label="Next Step"
            onPress={() => handleNextClick({
              field: ['loanNumber', 'ssnTin', 'zipCode'],
              form: 'changeEmailStepOne',
              formValues: trimFormValues(formValues),
              handleSubmitStep: () => dispatchChangeEmail(trimFormValues(formValues)),
            })}
            status={status.changeEmailStepOne}
          />
          <StyledBaseInternalLink
            label="Back"
            onPressFunc={handleBack}
            size="medium"
          />
        </ButtonsContainer>
      </View>
    </Fragment>
  );
};

ChangeEmailStepOne.propTypes = {
  dispatchChangeEmail: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchVerifyRecaptcha: T.func.isRequired,
  formErrors: T.shape({
    loanNumber: T.string.isRequired,
    ssnTin: T.string.isRequired,
    zipCode: T.string.isRequired,
  }).isRequired,
  formValues: T.shape({
    isRecaptchaVerified: T.bool.isRequired,
    loanNumber: T.string.isRequired,
    ssnTin: T.string.isRequired,
    zipCode: T.string.isRequired,
  }).isRequired,
  handleNextClick: T.func.isRequired,
  navigation: T.object.isRequired,
  status: T.object.isRequired,
};

export default ChangeEmailStepOne;
