import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  BorderlessButton,
  H2,
  MediumProgressButton,
  ModalDialog,
  P2,
} from '../../../base_ui';

export const BackButton = styled(BorderlessButton).attrs(({
  theme: { colors: { linkText }, fontFamilies: { secondaryFont } },
  titleStyle,
}) => ({ titleStyle: [...titleStyle, { color: linkText, fontFamily: secondaryFont }] }))``;

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const Container = styled.View`
  align-items: center;
  padding: ${moderateScale(20)}px;
  text-align: center;
`;

/* eslint-disable object-curly-newline */
export const Modal = styled(ModalDialog).attrs(() => ({
  modalStyles: { height: 'auto' },
}))``;

export const StyledProgressButton = styled(MediumProgressButton)`
  height: ${moderateScale(40)}px;
  margin: ${moderateScale(24)}px 0 ${moderateScale(14)}px;
  width: ${moderateScale(172)}px;
`;

export const Text = styled(P2)`
  text-align: center;
`;

export const Title = styled(H2)`
  padding: ${moderateScale(16)}px 0 ${moderateScale(8)}px;
  text-align: center;
`;
