import React from 'react';
import T from 'prop-types';

import { getSpanStyleProps } from './helpers';
import { StyledP2 } from './styledComponents';

const Span = ({ style, text }) => <StyledP2 {...getSpanStyleProps(style)}>{text}</StyledP2>;

Span.propTypes = { style: T.array, text: T.string.isRequired };

export default Span;
