import React from 'react';

import { moderateScale } from '../../utils/scaleHelpers';
import {
  BottomLoadingContainer,
  DatePickerLoadingContainer,
  PastPayoffDetailLoadingContainer,
  PastPayoffTitleLoadingContainer,
  PayoffLoadingContainer,
  TopLoadingContainer,
} from './styledComponents';
import { SecondaryDivider, Skeleton } from '../base_ui';

const PayoffLoading = () => (
  <PayoffLoadingContainer>
    <TopLoadingContainer>
      <Skeleton
        $topMargin={`${moderateScale(24)}px`}
        height={`${moderateScale(120)}px`}
        width={`${moderateScale(287)}px`}
      />
      <Skeleton
        $bottomMargin={`${moderateScale(14)}px`}
        $topMargin={`${moderateScale(28)}px`}
        height={`${moderateScale(18)}px`}
        width={`${moderateScale(267)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(320)}px`}
      />
      <Skeleton
        $bottomMargin={`${moderateScale(26)}px`}
        $topMargin={`${moderateScale(6)}px`}
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(222)}px`}
      />
      <DatePickerLoadingContainer>
        <Skeleton
          $leftMargin={`${moderateScale(11)}px`}
          $rightMargin={`${moderateScale(8.5)}px`}
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(18)}px`}
        />
        <Skeleton
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(60)}px`}
        />
      </DatePickerLoadingContainer>
      <Skeleton
        $borderRadius={`${moderateScale(8)}px`}
        $bottomMargin={`${moderateScale(48)}px`}
        $topMargin={`${moderateScale(24)}px`}
        height={`${moderateScale(45)}px`}
        width={`${moderateScale(225)}px`}
      />
      <SecondaryDivider />
    </TopLoadingContainer>
    <BottomLoadingContainer>
      <PastPayoffTitleLoadingContainer>
        <Skeleton
          $rightMargin={`${moderateScale(8)}px`}
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(18)}px`}
        />
        <Skeleton
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(267)}px`}
        />
      </PastPayoffTitleLoadingContainer>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(320)}px`}
      />
      <Skeleton
        $bottomMargin={`${moderateScale(45)}px`}
        $topMargin={`${moderateScale(6)}px`}
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(222)}px`}
      />
      {Array(4).fill().map((val, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PastPayoffDetailLoadingContainer key={`skeleton-${index}`}>
          <Skeleton
            $rightMargin={`${moderateScale(27)}px`}
            height={`${moderateScale(12)}px`}
            width={`${moderateScale(82)}px`}
          />
          <Skeleton
            height={`${moderateScale(12)}px`}
            width={`${moderateScale(127)}px`}
          />
        </PastPayoffDetailLoadingContainer>
      ))}
    </BottomLoadingContainer>
  </PayoffLoadingContainer>
);

export default PayoffLoading;
