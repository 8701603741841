import React, { Fragment } from 'react';
import { Platform } from 'react-native';
import T from 'prop-types';
import * as Sharing from 'expo-sharing';
import * as WebBrowser from 'expo-web-browser';

import { LargePrimaryButton } from '../../base_ui';
import {
  StyledText,
  StyledTextContainer,
} from './styledComponents';

const StatementView = ({
  dispatchClearStatement,
  navigation,
  requestType,
  statement,
}) => (
  <Fragment>
    <LargePrimaryButton
      label="View Statement"
      onPress={() => {
        const { document, mobileDocument } = statement;
        if (Platform.OS === 'android') {
          Sharing.shareAsync(mobileDocument, { mimeType: 'application/pdf' });
        } else if (Platform.OS === 'web') {
          const arrayBuffer = Buffer.from(document, 'base64');
          const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
          const fileUrl = URL.createObjectURL(blob);
          WebBrowser.openBrowserAsync(fileUrl);
        } else {
          navigation.push('ViewStatement', { requestType, uri: mobileDocument });
        }
      }}
    />
    <StyledTextContainer onPress={dispatchClearStatement}>
      <StyledText>{requestType === 'paydown' ? 'Get New Statement' : 'Select New Date'}</StyledText>
    </StyledTextContainer>
  </Fragment>
);

StatementView.propTypes = {
  dispatchClearStatement: T.func.isRequired,
  navigation: T.object.isRequired,
  requestType: T.string.isRequired,
  statement: T.shape({
    document: T.string.isRequired,
    mobileDocument: T.oneOfType([T.object, T.string]).isRequired,
  }),
};

export default StatementView;
