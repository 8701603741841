import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  fetchFaq,
  resetState,
  updateSelectedCategory,
  updateSelectedQuestion,
  updateStep,
} from '@dmi/shared/redux/Help/actions';
import makeSelectMain from '@dmi/shared/redux/Main/selectors';
import selectHelp, {
  selectFaqCategories,
  selectFaqCurrentCategory,
  selectFaqCurrentQuestion,
  selectFaqQuestions,
  selectHelpErrors,
  selectHelpNotifications,
} from '@dmi/shared/redux/Help/selectors';

import List from '../../components/Help/Faq/List';
import ListSkeleton from '../../components/Help/Faq/ListSkeleton';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import AsyncRender from '../../components/AsyncRender';
import { StyledScrollView } from '../../components/base_ui';
import { SecondaryHeader } from '../../components/ScreenHeaders';

const FaqScreen = ({
  categories,
  currentCategory,
  currentQuestion,
  dispatchFetchFaq,
  dispatchResetState,
  dispatchUpdateSelectedCategory,
  dispatchUpdateSelectedQuestion,
  dispatchUpdateStep,
  loading,
  loanNumber,
  navigation,
  notifications,
  questions,
  serviceError,
  step,
}) => {
  useEffect(() => dispatchResetState, [dispatchResetState]);

  useEffect(() => {
    dispatchFetchFaq(loanNumber);
  }, [dispatchFetchFaq, loanNumber]);

  const handleNavigationBack = () => {
    if (step === 1) {
      navigation.goBack();
    } else {
      dispatchUpdateStep(step - 1);
    }
  };

  const handleOnPressCategory = (id) => {
    dispatchUpdateSelectedCategory(id);
    dispatchUpdateStep(2);
  };

  const handleOnPressQuestion = (id) => {
    dispatchUpdateSelectedQuestion(id);
    dispatchUpdateStep(3);
  };

  const listTitle = step === 1 ? 'FAQ' : currentCategory;

  return (
    <StyledScrollView>
      <ScreenReaderNotifications notifications={notifications} />
      <SecondaryHeader
        handleBack={handleNavigationBack}
        title={listTitle}
      />
      <AsyncRender
        Component={List}
        error={serviceError}
        errorComponentProps={{
          link: {
            label: 'Return Back',
            path: 'Help',
          },
        }}
        loading={loading}
        LoadingComponent={ListSkeleton}
        propsToPassDown={{
          categories,
          currentCategory,
          currentQuestion,
          handleNavigationBack,
          handleOnPressCategory,
          handleOnPressQuestion,
          questions,
          step,
        }}
      />
    </StyledScrollView>
  );
};

FaqScreen.propTypes = {
  categories: T.array.isRequired,
  currentCategory: T.string.isRequired,
  currentQuestion: T.object.isRequired,
  dispatchFetchFaq: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchUpdateSelectedCategory: T.func.isRequired,
  dispatchUpdateSelectedQuestion: T.func.isRequired,
  dispatchUpdateStep: T.func.isRequired,
  loading: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  questions: T.array.isRequired,
  serviceError: T.oneOfType([T.bool, T.object, T.string]).isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  loanNumber: makeSelectMain('selectedLoan'),
  /**
   * Store: Help
   */
  // eslint-disable-next-line sort-keys
  categories: selectFaqCategories(),
  currentCategory: selectFaqCurrentCategory(),
  currentQuestion: selectFaqCurrentQuestion(),
  loading: selectHelp('loading'),
  notifications: selectHelpNotifications(),
  questions: selectFaqQuestions(),
  serviceError: selectHelpErrors('serviceError'),
  step: selectHelp('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Help
   */
  dispatchFetchFaq: (loanNumber) => dispatch(fetchFaq(loanNumber)),
  dispatchResetState: () => dispatch(resetState()),
  dispatchUpdateSelectedCategory: (id) => dispatch(updateSelectedCategory(id)),
  dispatchUpdateSelectedQuestion: (id) => dispatch(updateSelectedQuestion(id)),
  dispatchUpdateStep: (step) => dispatch(updateStep(step)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(FaqScreen);
