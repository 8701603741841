import React from 'react';
import T from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { ILLUSTRATION_DICT } from './constants';
import {
  CardContainer,
  CardContent,
  CardFooter,
  CardHeading,
  CardIllustration,
  CardText,
  Divider,
  StyledMediumInternalLink,
} from './styledComponents';

const Card = ({
  icon,
  linkText,
  navigation,
  nestedScreen,
  screen,
  text,
  title,
}) => {
  const { formatMessage } = useIntl();
  const onPressFunc = () => navigation.navigate(screen, { nestedScreen });

  return (
    <CardContainer key={title}>
      <CardContent>
        <CardIllustration>
          {ILLUSTRATION_DICT[icon]}
        </CardIllustration>
        <CardHeading>
          <FormattedMessage id={title} />
        </CardHeading>
        <CardText>
          <FormattedMessage id={text} />
        </CardText>
      </CardContent>
      <Divider />
      <CardFooter>
        <StyledMediumInternalLink
          label={formatMessage({ id: linkText })}
          onPressFunc={onPressFunc}
        />
      </CardFooter>
    </CardContainer>
  );
};

Card.propTypes = {
  icon: T.string.isRequired,
  linkText: T.string.isRequired,
  navigation: T.object.isRequired,
  nestedScreen: T.string,
  screen: T.string.isRequired,
  text: T.string.isRequired,
  title: T.string.isRequired,
};

export default Card;
