import React, { Fragment } from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ConditionalRender } from '../../base_ui';
import {
  DetailsTitle,
  DetailsValue,
  MortgageDetailsRow,
  MortgageHeadingRow,
  StyledSubheader,
  StyledSubheaderValue,
} from './styledComponents';
import MortgageLoanDetailsExpandableRows from './MortgageLoanDetailsExpandableRows';

const MultipleMortgageRows = ({
  balance,
  details,
  dispatchSetSelectedMortgageLoan,
  navigation,
  subheader,
}) => (
  <Fragment>
    <MortgageHeadingRow>
      <StyledSubheader>
        <FormattedMessage id={subheader} />
      </StyledSubheader>
      <StyledSubheaderValue>{balance}</StyledSubheaderValue>
    </MortgageHeadingRow>
    {details.map(({ label, value }) => (
      <ConditionalRender
        key={label}
        Component={(
          <MortgageDetailsRow key={label}>
            <DetailsTitle>
              <FormattedMessage id={label} />
            </DetailsTitle>
            <DetailsValue>{value}</DetailsValue>
          </MortgageDetailsRow>
        )}
        FallbackComponent={MortgageLoanDetailsExpandableRows}
        propsToPassDown={{ dispatchSetSelectedMortgageLoan, navigation, value }}
        shouldRender={!Array.isArray(value)}
      />
    ))}
  </Fragment>
);

MultipleMortgageRows.propTypes = {
  balance: T.string,
  details: T.array.isRequired,
  dispatchSetSelectedMortgageLoan: T.func,
  navigation: T.object,
  subheader: T.string.isRequired,
};

export default MultipleMortgageRows;
