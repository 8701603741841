import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl, useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';
import { setSelectedMortgageLoan } from '@dmi/shared/redux/Mortgage/actions';
import {
  getLoanDetails,
  makeSelectMortgageDetails,
  makeSelectMortgageLoading,
  makeSelectMortgageServiceError,
} from '@dmi/shared/redux/Mortgage/selectors';
import reducer from '@dmi/shared/redux/Mortgage/reducer';

import AsyncRender from '../../../components/AsyncRender';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { StyledScrollView } from '../../../components/base_ui';
import {
  MortgageDetails,
  MortgageDetailsLoading,
  MultipleMortgageDetails,
} from '../../../components/Mortgage/MortgageOverview';
import injectReducer from '../../../utils/injectReducer';
import { StyledView } from '../styledComponents';

const MortgageDetailsScreen = ({
  detailsData,
  dispatchSetSelectedMortgageLoan,
  loanDetails,
  navigation,
  summaryError,
  summaryLoading,
}) => {
  const { formatMessage } = useIntl();
  const { hasOtherDetails } = loanDetails;

  const viewToRender = hasOtherDetails ? MultipleMortgageDetails : MortgageDetails;

  return (
    <StyledView>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={formatMessage({ id: INTL_IDS.MORTGAGE_DETAILS_TITLE })}
      />
      <StyledScrollView>
        <AsyncRender
          Component={viewToRender}
          error={summaryError}
          loading={summaryLoading}
          LoadingComponent={MortgageDetailsLoading}
          propsToPassDown={{
            detailsData,
            dispatchSetSelectedMortgageLoan,
            loanDetails,
            navigation,
          }}
        />
      </StyledScrollView>
    </StyledView>
  );
};

MortgageDetailsScreen.propTypes = {
  detailsData: T.arrayOf(T.object).isRequired,
  dispatchSetSelectedMortgageLoan: T.func.isRequired,
  loanDetails: T.shape({
    hasOtherDetails: T.bool.isRequired,
    loans: T.array,
  }).isRequired,
  navigation: T.object.isRequired,
  summaryError: T.oneOfType([T.bool, T.string]).isRequired,
  summaryLoading: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Reducer: Mortgage
   */
  detailsData: makeSelectMortgageDetails(),
  loanDetails: getLoanDetails(),
  summaryError: makeSelectMortgageServiceError('summary'),
  summaryLoading: makeSelectMortgageLoading('summary'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mortgage
   */
  dispatchSetSelectedMortgageLoan: (payload) => dispatch(setSelectedMortgageLoan(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'mortgage', reducer });

export default compose(
  injectIntl,
  withConnect,
  withReducer,
)(MortgageDetailsScreen);
