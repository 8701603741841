import React, { useState } from 'react';
import T from 'prop-types';

import ClaimsProcessDrawer from './ClaimsProcessDrawer';
import ClaimsProcessMenu from './ClaimsProcessMenu';
import { ConditionalRender, StyledScrollView } from '../../../base_ui';

const ClaimsProcess = ({ navigation }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
  };

  return (
    <StyledScrollView>
      <ClaimsProcessMenu
        handleOpenDrawer={handleOpenDrawer}
        navigation={navigation}
      />
      <ConditionalRender
        Component={ClaimsProcessDrawer}
        propsToPassDown={{
          drawerType,
          isDrawerOpen,
          setIsDrawerOpen,
        }}
        shouldRender={!!drawerType}
      />
    </StyledScrollView>
  );
};

ClaimsProcess.propTypes = { navigation: T.object.isRequired };

export default ClaimsProcess;
