import React from 'react';
import T from 'prop-types';

import AsyncRender from '../../../AsyncRender';
import StatusBlock from './StatusBlock';
import StatusBlockSkeleton from './StatusBlockSkeleton';

const PaymentStatusBlock = ({
  fetchStatusLoading = false,
  ...restProps
}) => (
  <AsyncRender
    Component={StatusBlock}
    error={false}
    loading={fetchStatusLoading}
    LoadingComponent={StatusBlockSkeleton}
    propsToPassDown={restProps}
  />
);

PaymentStatusBlock.propTypes = { fetchStatusLoading: T.bool };

export default PaymentStatusBlock;
