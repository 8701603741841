/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function ModernHouse(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      fill="#000"
      height={moderateScale(100)}
      viewBox="0 0 100 100"
      width={moderateScale(100)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M95.54,52.7a5.72,5.72,0,0,1-1.31-3.81c0-.11,0-.21,0-.31,0-4.52-4-8.18-9-8.18s-9,3.66-9,8.18c0,.1,0,.21,0,.31a5.77,5.77,0,0,1-1.31,3.81,10.76,10.76,0,0,0-2.39,6.72C72.54,65.78,78.22,71,85.23,71s12.7-5.17,12.7-11.53A10.76,10.76,0,0,0,95.54,52.7Z"
        fill="#eff3f6"
      />
      <Path d="M85.23,71.17c-7.12,0-12.91-5.27-12.91-11.75a11,11,0,0,1,2.43-6.86A5.56,5.56,0,0,0,76,48.9c0-.11,0-.22,0-.32,0-4.64,4.14-8.4,9.22-8.4s9.23,3.76,9.23,8.4c0,.1,0,.21,0,.32a5.55,5.55,0,0,0,1.26,3.66h0a11,11,0,0,1,2.44,6.86C98.15,65.9,92.35,71.17,85.23,71.17Zm0-30.55c-4.84,0-8.78,3.57-8.78,8,0,.1,0,.2,0,.3a6,6,0,0,1-1.36,4,10.51,10.51,0,0,0-2.34,6.58c0,6.23,5.6,11.31,12.47,11.31S97.7,65.65,97.7,59.42a10.45,10.45,0,0,0-2.34-6.58,6,6,0,0,1-1.35-4v-.3C94,44.19,90.07,40.62,85.23,40.62Z" />
      <Path d="M84.9,86.6a.22.22,0,0,1-.22-.22V50.83a.22.22,0,0,1,.22-.23.22.22,0,0,1,.22.23V86.38A.22.22,0,0,1,84.9,86.6Z" />
      <Path d="M84.8,60.9a.22.22,0,0,1-.13,0l-3.26-2.32a.22.22,0,1,1,.26-.36l3.26,2.32a.22.22,0,0,1,0,.31A.21.21,0,0,1,84.8,60.9Z" />
      <Path d="M84.91,55.83a.22.22,0,0,1-.18-.1.21.21,0,0,1,0-.31L88,53.1a.22.22,0,0,1,.31,0,.23.23,0,0,1,0,.31L85,55.78A.21.21,0,0,1,84.91,55.83Z" />
      <Path d="M85,65.32a.21.21,0,0,1-.18-.09.22.22,0,0,1,0-.31l3.26-2.32a.22.22,0,1,1,.26.36l-3.26,2.32A.22.22,0,0,1,85,65.32Z" />
      <Path
        d="M61.46,21.32h1.4a1,1,0,0,0,1-1.08h0a1,1,0,0,0-1-1.07H30.46a2.77,2.77,0,0,0-1.87.73l-25,20.53a1,1,0,0,0-.13,1.29l.11.12A.78.78,0,0,0,4.74,42l.87-.8v45.5H48.54"
        fill="#fff"
      />
      <Rect fill="#e6ebef" height="32.06" width="30.75" x="47.44" y="54.36" />
      <Path
        d="M49.16,55.55H77.87V54.34l-29.05-.17-1.19-1.32.07,2,.31.29A1.72,1.72,0,0,0,49.16,55.55Z"
        fill="#bac2ca"
      />
      <Rect fill="#e6ebef" height="16.71" width="31.69" x="30.09" y="21.25" />
      <Polygon
        fill="#bac2ca"
        points="6.07 42.52 30.21 22.98 61.94 22.98 61.94 21.56 30.18 21.54 6.11 41.19 6.07 42.52"
      />
      <Path d="M4.37,42.39h-.1A1,1,0,0,1,3.54,42l-.1-.12a1.21,1.21,0,0,1,.15-1.59l25-20.54a3,3,0,0,1,2-.78H63a1.3,1.3,0,0,1,0,2.59h-1.4a.22.22,0,0,1,0-.44H63a.82.82,0,0,0,.76-.86.81.81,0,0,0-.76-.85H30.63a2.59,2.59,0,0,0-1.73.68l-25,20.54a.76.76,0,0,0-.08,1l.1.11a.6.6,0,0,0,.43.27.61.61,0,0,0,.44-.15l.87-.8a.22.22,0,1,1,.3.32l-.87.8A1,1,0,0,1,4.37,42.39Z" />
      <Polyline
        fill={svgHighlight}
        points="22.37 82.52 22.37 65.87 32.48 65.87 32.48 82.52"
      />
      <Path d="M32.48,82.74a.22.22,0,0,1-.22-.22V66.09H22.59V82.52a.22.22,0,1,1-.43,0V65.87a.21.21,0,0,1,.21-.22H32.48a.21.21,0,0,1,.21.22V82.52A.21.21,0,0,1,32.48,82.74Z" />
      <Path d="M47.62,86.46a.21.21,0,0,1-.22-.21V73.68a.22.22,0,1,1,.44,0V86.25A.21.21,0,0,1,47.62,86.46Z" />
      <Path d="M47.62,71.19A.22.22,0,0,1,47.4,71V53.17a.22.22,0,0,1,.44,0V71A.22.22,0,0,1,47.62,71.19Z" />
      <Path d="M5.86,86.62a.22.22,0,0,1-.22-.22V41.08a.25.25,0,0,1,.08-.18L30,21.16a.23.23,0,0,1,.23,0,.23.23,0,0,1,.13.2V38.19l18.4,15.39a.22.22,0,0,1,0,.31.23.23,0,0,1-.31,0L30,38.46a.19.19,0,0,1-.08-.17V21.8L6.09,41.18V86.4A.22.22,0,0,1,5.86,86.62Z" />
      <Path d="M62,38.29a.22.22,0,0,1-.22-.22V21.55H30.09a.22.22,0,1,1,0-.44H62a.22.22,0,0,1,.22.22V38.07A.22.22,0,0,1,62,38.29Z" />
      <Path d="M78.12,86.61a.22.22,0,0,1-.22-.22V54.31a.22.22,0,1,1,.44,0V86.39A.22.22,0,0,1,78.12,86.61Z" />
      <Polyline
        fill="#bac2ca"
        points="33.15 38.35 50.32 52.28 79.03 51.95 62.4 38.35"
      />
      <Path
        d="M49.93,54.08H79a1,1,0,0,0,1.06-1h0A1,1,0,0,0,79,52h-.56l-28.1.07"
        fill="#e6ebef"
      />
      <Path d="M79,54.3H49.93a.22.22,0,1,1,0-.44H79a.83.83,0,0,0,.84-.82.83.83,0,0,0-.84-.81h-.56l-28.1.08h0a.23.23,0,1,1,0-.45L79,51.79a1.26,1.26,0,1,1,0,2.51Z" />
      <Path d="M79.26,52.25a.24.24,0,0,1-.14,0L62,38.38a.22.22,0,1,1,.27-.34L79.4,51.85a.22.22,0,0,1,0,.31A.21.21,0,0,1,79.26,52.25Z" />
      <Path d="M62.08,38.29H30.36a.22.22,0,0,1-.22-.22.22.22,0,0,1,.22-.22H62.08a.22.22,0,0,1,.22.22A.22.22,0,0,1,62.08,38.29Z" />
      <Path d="M31.14,86.6H17.85a.22.22,0,0,1-.23-.22V84.49a.22.22,0,0,1,.23-.22h1.57V82.63a.22.22,0,0,1,.22-.22h11.5a.22.22,0,0,1,.22.22v3.75A.22.22,0,0,1,31.14,86.6Zm-13.07-.44H30.92V82.85H19.86v1.64a.22.22,0,0,1-.22.22H18.07Z" />
      <Polygon
        fill="#bac2ca"
        points="30.21 82.63 30.21 84.49 28.41 84.49 28.41 86.38 30.21 86.38 30.42 86.38 32.48 86.38 32.48 82.63 30.21 82.63"
      />
      <Path d="M32.48,86.6H28.41a.22.22,0,0,1-.22-.22V84.49a.22.22,0,0,1,.22-.22H30V82.63a.22.22,0,0,1,.22-.22h2.27a.22.22,0,0,1,.22.22v3.75A.22.22,0,0,1,32.48,86.6Zm-3.85-.44h3.62V82.85H30.43v1.64a.22.22,0,0,1-.22.22H28.63Z" />
      <Path d="M27.2,84.7H19.58a.15.15,0,0,1,0-.3H27.2a.15.15,0,0,1,0,.3Z" />
      <Path d="M29.42,22.08a.25.25,0,0,1-.17-.08.23.23,0,0,1,0-.31l1-.8a.74.74,0,0,0-.34-1.29.76.76,0,0,0-.58.14l-.56.44a.22.22,0,1,1-.27-.35l.56-.44a1.19,1.19,0,0,1,.93-.22,1.21,1.21,0,0,1,.79.53,1.19,1.19,0,0,1-.25,1.54l-1,.79A.24.24,0,0,1,29.42,22.08Z" />
      <Rect fill="#e6ebef" height="7.41" width="5.14" x="10.95" y="43.27" />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="9.81 49.07 12.76 43.27 10.1 43.27 9.81 43.79 9.81 49.07"
      />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="13.49 50.69 11.84 50.69 15.69 43.27 17.35 43.27 13.49 50.69"
      />
      <Path d="M16.19,51h-6a.22.22,0,0,1-.22-.22V43.29a.22.22,0,0,1,.22-.22h6a.22.22,0,0,1,.22.22v7.46A.22.22,0,0,1,16.19,51Zm-5.79-.44H16v-7H10.4Z" />
      <Rect fill="#e6ebef" height="7.41" width="5.1" x="10.99" y="54.57" />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="9.81 60.37 12.76 54.57 10.1 54.57 9.81 55.09 9.81 60.37"
      />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="13.49 61.99 11.84 61.99 15.69 54.57 17.35 54.57 13.49 61.99"
      />
      <Path d="M16.19,62.27h-6a.22.22,0,0,1-.22-.22V54.59a.22.22,0,0,1,.22-.22h6a.22.22,0,0,1,.22.22v7.46A.22.22,0,0,1,16.19,62.27Zm-5.79-.44H16v-7H10.4Z" />
      <Rect fill="#e6ebef" height="7.41" width="5.09" x="11" y="65.87" />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="9.81 71.67 12.76 65.87 10.1 65.87 9.81 66.39 9.81 71.67"
      />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="13.49 73.29 11.84 73.29 15.69 65.87 17.35 65.87 13.49 73.29"
      />
      <Path d="M16.19,73.57h-6a.22.22,0,0,1-.22-.22V65.89a.22.22,0,0,1,.22-.22h6a.22.22,0,0,1,.22.22v7.46A.22.22,0,0,1,16.19,73.57Zm-5.79-.44H16v-7H10.4Z" />
      <Rect fill="#fff" height="8.07" width="18.69" x="36.89" y="25.74" />
      <Path d="M55.58,34H36.89a.22.22,0,0,1-.22-.22V25.74a.22.22,0,0,1,.22-.23H55.58a.22.22,0,0,1,.22.23v8.07A.22.22,0,0,1,55.58,34Zm-18.47-.44H55.36V26H37.11Z" />
      <Polygon
        fill="#e6ebef"
        points="48.71 33.58 52.87 33.58 49.89 25.96 45.73 25.96 48.71 33.58"
      />
      <Polygon
        fill="#e6ebef"
        points="41.4 33.6 45.72 33.6 42.79 25.95 38.46 25.95 41.4 33.6"
      />
      <Path d="M54.53,29.89H37.91a.15.15,0,0,1-.15-.14.15.15,0,0,1,.15-.15H54.53a.15.15,0,0,1,.15.15A.15.15,0,0,1,54.53,29.89Z" />
      <Path d="M46.48,33.79h0a.15.15,0,0,1-.14-.15V26.39a.15.15,0,0,1,.14-.15h0a.15.15,0,0,1,.15.15v7.25A.14.14,0,0,1,46.48,33.79Z" />
      <Rect
        fill="#fff"
        height="18.78"
        transform="translate(126.2 145.76) rotate(180)"
        width="13.55"
        x="56.32"
        y="63.49"
      />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="70.63 71.9 67.48 63.49 70.3 63.49 70.63 64.36 70.63 71.9"
      />
      <Polygon
        fill="#e6ebef"
        points="69.71 82.28 69.63 77.42 64.37 63.49 62.62 63.49 69.71 82.28"
      />
      <Polygon
        fill="#e6ebef"
        points="62.15 82.34 66.48 82.34 59.68 63.8 55.35 63.8 62.15 82.34"
      />
      <Path d="M70.66,82.67H56.3a.22.22,0,0,1-.22-.22V62.81a.22.22,0,0,1,.22-.22H70.66a.22.22,0,0,1,.22.22V82.45A.22.22,0,0,1,70.66,82.67Zm-14.14-.44H70.44V63H56.52Z" />
      <Path d="M69.81,82.49a.15.15,0,0,1-.14-.15V63.85H57.18a.15.15,0,0,1,0-.3H69.81a.15.15,0,0,1,.15.15V82.34A.15.15,0,0,1,69.81,82.49Z" />
      <Path d="M63.64,81.66a.15.15,0,0,1-.15-.15V64.07a.15.15,0,0,1,.15-.14.14.14,0,0,1,.14.14V81.51A.15.15,0,0,1,63.64,81.66Z" />
      <Path d="M11,50.15a.15.15,0,0,1-.15-.14V43.37a.15.15,0,0,1,.15-.15.15.15,0,0,1,.14.15V50A.14.14,0,0,1,11,50.15Z" />
      <Path d="M11,62a.15.15,0,0,1-.15-.14V55.26a.15.15,0,0,1,.15-.15.15.15,0,0,1,.14.15V61.9A.14.14,0,0,1,11,62Z" />
      <Path d="M11,73.22a.15.15,0,0,1-.15-.15V66.43a.15.15,0,0,1,.15-.15.15.15,0,0,1,.14.15v6.64A.15.15,0,0,1,11,73.22Z" />
      <Path d="M18.47,82.64H6a.15.15,0,0,1,0-.3h12.5a.15.15,0,1,1,0,.3Z" />
      <Path d="M78.2,82.64H34.37a.15.15,0,1,1,0-.3H78.2a.15.15,0,0,1,0,.3Z" />
      <Path d="M54.72,33.85a.14.14,0,0,1-.14-.14V26.32a.14.14,0,0,1,.14-.14.14.14,0,0,1,.15.14v7.39A.14.14,0,0,1,54.72,33.85Z" />
      <Path d="M72.84,81.45a.15.15,0,0,1-.15-.15V60.54H59.58a.15.15,0,0,1-.15-.14.15.15,0,0,1,.15-.15H72.84a.15.15,0,0,1,.14.15V81.3A.15.15,0,0,1,72.84,81.45Z" />
      <Path d="M53.87,82.53a.15.15,0,0,1-.15-.15v-22a.15.15,0,0,1,.15-.15h4.42a.15.15,0,0,1,.15.15.15.15,0,0,1-.15.14H54V82.38A.15.15,0,0,1,53.87,82.53Z" />
      <Polyline fill="#e6ebef" points="24.56 54.6 24.56 42.71 38.05 54.49" />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="30.18 55.5 27.88 54.91 31.23 48.49 33 50.04 30.18 55.5"
      />
      <Polygon
        fill="#fff"
        opacity={0.7}
        points="24.67 55.42 29.18 46.76 27.43 45.19 24.67 50.14 24.67 55.42"
      />
      <Path d="M24.56,55.05a.22.22,0,0,1-.23-.22V42.71a.24.24,0,0,1,.14-.2.24.24,0,0,1,.24,0l13.49,12a.22.22,0,0,1,0,.31.23.23,0,0,1-.32,0L24.78,43.21V54.83A.22.22,0,0,1,24.56,55.05Z" />
      <Rect fill="#fff" height="7.34" width="18.94" x="21.16" y="54.83" />
      <Path d="M40.1,62.38H21.16a.22.22,0,0,1-.22-.22V54.83a.22.22,0,0,1,.22-.23H40.1a.22.22,0,0,1,.22.23v7.33A.22.22,0,0,1,40.1,62.38Zm-18.72-.44h18.5V55.05H21.38Z" />
      <Rect fill="#e6ebef" height="7.34" width="4.54" x="39.84" y="54.83" />
      <Path d="M44.37,62.38H39.84a.22.22,0,0,1-.22-.22V54.83a.22.22,0,0,1,.22-.23h4.53a.23.23,0,0,1,.23.23v7.33A.22.22,0,0,1,44.37,62.38Zm-4.31-.44h4.09V55.05H40.06Z" />
      <Path d="M44.3,60.41H22.14a.15.15,0,1,1,0-.3H44.3a.15.15,0,0,1,0,.3Z" />
      <Path d="M49.58,54.34a1.8,1.8,0,0,1-1.35-.62L37.05,44.44a.22.22,0,1,1,.28-.34l11.18,9.28a1.41,1.41,0,0,0,1.16.52.79.79,0,0,0,.53-.25,1,1,0,0,0,.22-.86.78.78,0,0,0-.42-.6l-.06,0-17-13.65a.22.22,0,1,1,.27-.34l17,13.63a1.15,1.15,0,0,1,.66.93A1.39,1.39,0,0,1,50.49,54a1.19,1.19,0,0,1-.81.35Z" />
      <Path d="M95.05,86.62H2.52a.19.19,0,0,1-.17-.2.19.19,0,0,1,.17-.21H95.05a.19.19,0,0,1,.17.21A.19.19,0,0,1,95.05,86.62Z" />
    </Svg>
  );
}
