/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

const MarketingSpring = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      height={moderateScale(120)}
      viewBox="0 0 287.25 120.25"
      width={moderateScale(287)}
      {...props}
    >
      <G>
        <Polygon
          fill="#FFFFFF"
          points="136.98,5.214 111.986,24.975 111.986,104.018 98.192,104.018 98.192,114.143 165.447,114.632
      165.399,20.817 143.339,5.409"
        />
        <Polygon fill="#FFFFFF" points="273.154,113.703 220.572,113.703 239.257,117.224 257.991,117.469" />
        <Circle cx="103.622" cy="68.202" fill="#FFFFFF" r="0.702" />
        <Path
          d="M167.264,103.722h-55.329c-0.138,0-0.25-0.112-0.25-0.25V26.679c0-0.077,0.036-0.15,0.097-0.197l26.153-20.344
      c0.04-0.031,0.088-0.049,0.138-0.052l17.609-1.068c0.072-0.002,0.113,0.013,0.16,0.045l11.565,8.18
      c0.066,0.047,0.105,0.123,0.105,0.204v90.026C167.514,103.609,167.401,103.722,167.264,103.722z M112.185,103.222h54.829V13.575
      l-11.388-8.054l-17.445,1.058l-25.996,20.222V103.222z"
          fill="#000000"
        />
        <Rect fill="#E6EBEF" height="2.579" width="8.127" x="145.426" y="76.208" />
        <Rect fill="#E6EBEF" height="11.071" width="14.015" x="156.542" y="103.715" />
        <Rect fill="#BAC2CA" height="2.449" width="53.245" x="111.841" y="66.699" />
        <Rect fill="#E6EBEF" height="13.481" width="8.237" x="121.527" y="36.527" />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="120.757,46.884 124.998,36.527 121.196,36.527
      120.757,37.598"
        />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="126.04,50.008 123.674,50.008 129.195,36.527
      131.561,36.527"
        />
        <Rect fill="#E6EBEF" height="78.103" width="17.737" x="165.331" y="25.775" />
        <Path
          d="M169.882,31.835h13.249V30.65l-13.595-0.169l-4.162-3.163l-0.056,1.637l3.395,2.447
      C169.022,31.679,169.443,31.835,169.882,31.835z"
          fill="#BAC2CA"
        />
        <Path
          d="M187.593,30.541h-16.467v-2.032h16.467c0.561,0,1.016,0.455,1.016,1.016l0,0
      C188.609,30.086,188.154,30.541,187.593,30.541z"
          fill="#FFFFFF"
        />
        <Path
          d="M187.728,30.868h-17.7c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h17.7c0.207,0,0.375,0.168,0.375,0.375
      S187.935,30.868,187.728,30.868z"
          fill="#000000"
        />
        <G>
          <Polygon fill="#BAC2CA" points="187.921,28.472 155.282,3.678 138.223,3.678 170.919,28.508 168.151,28.508" />
          <Path
            d="M138.651,4.128c-0.22-0.215-0.542-0.336-0.859-0.331c-0.327,0.005-0.635,0.134-0.847,0.355L106.12,28.438
        c-0.407,0.425-0.35,1.062,0.127,1.424c0.478,0.362,1.196,0.311,1.603-0.113l29.987-23.413l31.484,23.813
        c0.224,0.219,0.532,0.331,0.842,0.331c0.273,0,0.547-0.087,0.764-0.263c0.465-0.375,0.499-1.014,0.077-1.427L138.651,4.128z"
            fill="#FFFFFF"
          />
          <Polygon fill="#BAC2CA" points="111.854,26.616 111.854,30.178 107.45,30.135" />
          <Path
            d="M111.705,30.465h-4.437c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h4.437c0.138,0,0.25,0.112,0.25,0.25
        S111.843,30.465,111.705,30.465z"
            fill="#000000"
          />
          <Polygon fill="#BAC2CA" points="139.015,7.449 112.126,28.294 112.126,26.374 137.804,6.52" />
          <Path
            d="M187.928,28.758h-17.263c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h17.263c0.138,0,0.25,0.112,0.25,0.25
        S188.065,28.758,187.928,28.758z"
            fill="#000000"
          />
          <Path
            d="M187.928,28.883c-0.079,0-0.159-0.025-0.227-0.077L155.156,4.053l-17.387,0.04c-0.001,0-0.001,0-0.002,0
        c-0.206,0-0.374-0.166-0.375-0.373s0.166-0.376,0.373-0.377l10.25-0.04h7.268c0.082,0,0.162,0.027,0.227,0.077l32.646,24.83
        c0.165,0.125,0.197,0.36,0.072,0.525C188.152,28.832,188.041,28.883,187.928,28.883z"
            fill="#000000"
          />
          <Rect fill="#FFFFFF" height="9.608" width="6.054" x="165.388" y="9.197" />
          <Path
            d="M170.162,30.854c-0.417,0-0.819-0.16-1.104-0.438L137.84,6.809l-29.76,23.235c-0.502,0.53-1.425,0.597-2.06,0.117
        c-0.321-0.243-0.514-0.587-0.544-0.968c-0.03-0.374,0.103-0.734,0.372-1.015l10.054-7.926l0,0l20.81-16.395
        c0.243-0.258,0.648-0.429,1.074-0.435c0.403,0.005,0.839,0.156,1.126,0.438l32.318,24.631c0.315,0.305,0.459,0.663,0.441,1.039
        c-0.019,0.38-0.2,0.728-0.511,0.979C170.887,30.731,170.531,30.854,170.162,30.854z M137.837,5.961
        c0.08,0,0.159,0.025,0.226,0.076l31.485,23.813c0.341,0.328,0.84,0.321,1.143,0.076c0.143-0.115,0.226-0.268,0.233-0.431
        c0.008-0.159-0.058-0.314-0.183-0.437L138.423,4.427c-0.179-0.172-0.422-0.271-0.625-0.254c-0.228,0.003-0.44,0.09-0.583,0.239
        l-30.864,24.321c-0.081,0.089-0.139,0.245-0.127,0.402c0.013,0.165,0.102,0.317,0.249,0.429c0.325,0.245,0.832,0.211,1.105-0.074
        l30.028-23.45C137.674,5.987,137.755,5.961,137.837,5.961z"
            fill="#000000"
          />
        </G>
        <Path
          d="M111.936,103.766c-0.083,0-0.15-0.066-0.15-0.149V89.108c0-0.083,0.067-0.149,0.15-0.149s0.15,0.066,0.15,0.149v14.509
      C112.086,103.7,112.019,103.766,111.936,103.766z"
          fill="#000000"
        />
        <Path
          d="M111.936,88.947c-0.207,0-0.375-0.168-0.375-0.375V26.75c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v61.822
      C112.311,88.779,112.143,88.947,111.936,88.947z"
          fill="#000000"
        />
        <Path
          d="M165.261,103.966c-0.138,0-0.25-0.112-0.25-0.25V84.037c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v19.679
      C165.511,103.853,165.398,103.966,165.261,103.966z"
          fill="#000000"
        />
        <Path
          d="M165.261,82.712c-0.138,0-0.25-0.112-0.25-0.25V27.156c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v55.306
      C165.511,82.599,165.398,82.712,165.261,82.712z"
          fill="#000000"
        />
        <Path
          d="M183.008,104.091c-0.207,0-0.375-0.168-0.375-0.375v-39.53c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
      v39.529C183.383,103.923,183.215,104.091,183.008,104.091z"
          fill="#000000"
        />
        <Path
          d="M183.008,63.493c-0.207,0-0.375-0.168-0.375-0.375v-32.47c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v32.47
      C183.383,63.325,183.215,63.493,183.008,63.493z"
          fill="#000000"
        />
        <Path
          d="M187.497,30.87h-1.473c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.473c0.411,0,0.745-0.278,0.745-0.621
      c0-0.342-0.334-0.62-0.745-0.62c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375c0.824,0,1.495,0.615,1.495,1.37
      C188.992,30.255,188.321,30.87,187.497,30.87z"
          fill="#000000"
        />
        <G>
          <Line fill="none" x1="118.81" x2="118.81" y1="50.972" y2="35.11" />
          <Path
            d="M118.81,51.222c-0.138,0-0.25-0.112-0.25-0.25V35.11c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
        C119.06,51.11,118.948,51.222,118.81,51.222z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="132.349" x2="132.349" y1="35.11" y2="50.972" />
          <Path
            d="M132.349,51.222c-0.138,0-0.25-0.112-0.25-0.25V35.11c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
        C132.599,51.11,132.487,51.222,132.349,51.222z"
            fill="#000000"
          />
        </G>
        <Path
          d="M133.314,35.368h-0.951c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.701V33.67h-14.882v1.198h0.657
      c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.907c-0.138,0-0.25-0.112-0.25-0.25V33.42c0-0.138,0.112-0.25,0.25-0.25
      h15.382c0.138,0,0.25,0.112,0.25,0.25v1.698C133.564,35.256,133.452,35.368,133.314,35.368z"
          fill="#000000"
        />
        <Path
          d="M133.314,53.049h-3.624c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h3.374v-1.198h-0.701
      c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.951c0.138,0,0.25,0.112,0.25,0.25v1.698
      C133.564,52.937,133.452,53.049,133.314,53.049z"
          fill="#000000"
        />
        <Path
          d="M128.622,53.049h-10.69c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25h0.907
      c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.657v1.198h10.44c0.138,0,0.25,0.112,0.25,0.25
      S128.76,53.049,128.622,53.049z"
          fill="#000000"
        />
        <Rect fill={svgHighlight} height="6.868" width="8.228" x="121.537" y="43.082" />
        <Path
          d="M129.802,50.239h-8.284c-0.138,0-0.25-0.112-0.25-0.25V36.531c0-0.138,0.112-0.25,0.25-0.25h8.284
      c0.138,0,0.25,0.112,0.25,0.25v13.458C130.052,50.127,129.94,50.239,129.802,50.239z M121.768,49.739h7.784V36.781h-7.784
      C121.768,36.781,121.768,49.739,121.768,49.739z"
          fill="#000000"
        />
        <Path
          d="M129.802,43.157h-8.284c-0.083,0-0.15-0.067-0.15-0.15s0.067-0.15,0.15-0.15h8.284c0.083,0,0.15,0.067,0.15,0.15
      S129.885,43.157,129.802,43.157z"
          fill="#000000"
        />
        <Path
          d="M125.718,43.099c-0.083,0-0.15-0.067-0.15-0.15v-6.438c0-0.083,0.067-0.15,0.15-0.15s0.15,0.067,0.15,0.15v6.438
      C125.868,43.033,125.801,43.099,125.718,43.099z"
          fill="#000000"
        />
        <Rect fill="#E6EBEF" height="13.481" width="8.237" x="145.44" y="36.37" />
        <Rect fill="#E6EBEF" height="14.734" width="8.784" x="121.19" y="75.261" />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="144.67,46.728 148.911,36.37 145.109,36.37
      144.67,37.442"
        />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="149.953,49.852 147.587,49.852 153.108,36.37
      155.474,36.37"
        />
        <Rect fill="#E6EBEF" height="10.977" width="7.593" x="145.845" y="81.29" />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="145.862,89.723 149.316,81.29 147.001,81.29
      145.823,84.003"
        />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="149.52,92.267 147.593,92.267 152.088,81.29
      154.015,81.29"
        />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="120.659,87.753 126.012,74.689 122.089,74.689
      120.659,78.171"
        />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="125.719,91.954 123.278,91.954 130.343,74.689
      132.785,74.689"
        />
        <Path
          d="M153.663,50.239h-8.283c-0.138,0-0.25-0.112-0.25-0.25V36.531c0-0.138,0.112-0.25,0.25-0.25h8.283
      c0.138,0,0.25,0.112,0.25,0.25v13.458C153.913,50.127,153.801,50.239,153.663,50.239z M145.63,49.739h7.783V36.781h-7.783V49.739z"
          fill="#000000"
        />
        <Path
          d="M153.663,43.157h-8.283c-0.083,0-0.15-0.067-0.15-0.15s0.067-0.15,0.15-0.15h8.283c0.083,0,0.15,0.067,0.15,0.15
      S153.746,43.157,153.663,43.157z"
          fill="#000000"
        />
        <Path
          d="M149.579,43.099c-0.083,0-0.15-0.067-0.15-0.15v-6.438c0-0.083,0.067-0.15,0.15-0.15s0.15,0.067,0.15,0.15v6.438
      C149.729,43.033,149.662,43.099,149.579,43.099z"
          fill="#000000"
        />
        <Path
          d="M118.81,92.675c-0.138,0-0.25-0.112-0.25-0.25V74.167c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v18.258
      C119.06,92.562,118.948,92.675,118.81,92.675z"
          fill="#000000"
        />
        <G>
          <Line fill="none" x1="132.349" x2="132.349" y1="74.167" y2="92.425" />
          <Path
            d="M132.349,92.574c-0.083,0-0.15-0.066-0.15-0.149V74.167c0-0.083,0.067-0.15,0.15-0.15s0.15,0.067,0.15,0.15v18.258
        C132.498,92.508,132.431,92.574,132.349,92.574z"
            fill="#000000"
          />
        </G>
        <Path
          d="M118.839,74.424h-0.907c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25h14.01
      c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-13.76v1.198h0.657c0.138,0,0.25,0.112,0.25,0.25
      S118.977,74.424,118.839,74.424z"
          fill="#000000"
        />
        <Path
          d="M141.595,93.108c-0.138,0-0.25-0.112-0.25-0.25V74.167c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v18.691
      C141.845,92.996,141.733,93.108,141.595,93.108z"
          fill="#000000"
        />
        <G>
          <Line fill="none" x1="141.595" x2="141.595" y1="103.565" y2="94.132" />
          <Path
            d="M141.595,103.715c-0.083,0-0.15-0.066-0.15-0.149v-9.434c0-0.083,0.067-0.149,0.15-0.149s0.15,0.066,0.15,0.149v9.434
        C141.745,103.648,141.678,103.715,141.595,103.715z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="156.759" x2="156.759" y1="74.167" y2="103.565" />
          <Path
            d="M156.76,103.715c-0.083,0-0.149-0.066-0.149-0.149V74.167c0-0.083,0.066-0.15,0.149-0.15s0.149,0.067,0.149,0.15v29.398
        C156.909,103.648,156.843,103.715,156.76,103.715z"
            fill="#000000"
          />
        </G>
        <Path
          d="M157.725,74.424h-0.951c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.701v-1.198h-16.507v1.198h0.657
      c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.907c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25
      h17.007c0.138,0,0.25,0.112,0.25,0.25v1.698C157.975,74.312,157.862,74.424,157.725,74.424z"
          fill="#000000"
        />
        <Path
          d="M133.314,94.402h-15.078c-0.083,0-0.15-0.066-0.15-0.149v-1.698c0-0.083,0.067-0.149,0.15-0.149h0.603
      c0.083,0,0.15,0.066,0.15,0.149s-0.067,0.149-0.15,0.149h-0.453v1.399h14.778v-1.399h-0.801c-0.083,0-0.15-0.066-0.15-0.149
      s0.067-0.149,0.15-0.149h0.951c0.083,0,0.15,0.066,0.15,0.149v1.698C133.464,94.336,133.396,94.402,133.314,94.402z"
          fill="#000000"
        />
        <Path
          d="M129.935,90.312h-8.671c-0.138,0-0.25-0.112-0.25-0.25V75.318c0-0.138,0.112-0.25,0.25-0.25h8.671
      c0.138,0,0.25,0.112,0.25,0.25v14.744C130.185,90.2,130.073,90.312,129.935,90.312z M121.514,89.812h8.171V75.568h-8.171V89.812z"
          fill="#000000"
        />
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="125.7" y="89.649" />
          <Path
            d="M126.82,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C127.07,103.397,126.958,103.51,126.82,103.51z M125.95,103.01h0.62V89.898h-0.62V103.01z"
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.936" width="1.36" x="161.491" y="89.649" />
          <Path
            d="M162.851,103.86h-1.36c-0.152,0-0.275-0.123-0.275-0.275V89.648c0-0.152,0.123-0.275,0.275-0.275h1.36
        c0.152,0,0.275,0.123,0.275,0.275v13.937C163.127,103.737,163.004,103.86,162.851,103.86z M161.767,103.309h0.81V89.924h-0.81
        V103.309z"
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="129.425" y="89.649" />
          <Path
            d="M130.545,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C130.795,103.397,130.683,103.51,130.545,103.51z M129.675,103.01h0.62V89.898h-0.62V103.01z
        "
            fill="#000000"
          />
        </G>
        <Path
          d="M155.42,103.828h-11.669c-0.138,0-0.25-0.112-0.25-0.25V74.614c0-0.138,0.112-0.25,0.25-0.25h11.669
      c0.138,0,0.25,0.112,0.25,0.25v28.964C155.67,103.716,155.558,103.828,155.42,103.828z M144.001,103.328h11.169V74.864h-11.169
      V103.328z"
          fill="#000000"
        />
        <Path
          d="M129.919,83.192h-8.635c-0.069,0-0.125-0.056-0.125-0.125s0.056-0.125,0.125-0.125h8.635c0.069,0,0.125,0.056,0.125,0.125
      S129.988,83.192,129.919,83.192z"
          fill="#000000"
        />
        <Path
          d="M125.535,83.134c-0.069,0-0.125-0.056-0.125-0.125v-7.642c0-0.069,0.056-0.125,0.125-0.125s0.125,0.056,0.125,0.125v7.642
      C125.66,83.078,125.603,83.134,125.535,83.134z"
          fill="#000000"
        />
        <G>
          <Line fill="none" x1="142.596" x2="142.596" y1="50.972" y2="35.11" />
          <Path
            d="M142.596,51.222c-0.138,0-0.25-0.112-0.25-0.25V35.11c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
        C142.846,51.11,142.734,51.222,142.596,51.222z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="156.135" x2="156.135" y1="35.11" y2="50.972" />
          <Path
            d="M156.135,51.222c-0.138,0-0.25-0.112-0.25-0.25V35.11c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v15.861
        C156.385,51.11,156.272,51.222,156.135,51.222z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="152.398,33.419 157.1,33.419 157.1,35.117 156.149,35.117" />
          <Path
            d="M157.101,35.368h-0.951c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.701V33.67h-4.452
        c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h4.702c0.138,0,0.25,0.112,0.25,0.25v1.698
        C157.351,35.256,157.238,35.368,157.101,35.368z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="142.625,35.117 141.718,35.117 141.718,33.419 151.217,33.419" />
          <Path
            d="M142.625,35.368h-0.907c-0.138,0-0.25-0.112-0.25-0.25V33.42c0-0.138,0.112-0.25,0.25-0.25h9.5
        c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-9.25v1.198h0.657c0.138,0,0.25,0.112,0.25,0.25
        S142.764,35.368,142.625,35.368z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="142.625,51.101 141.718,51.101 141.718,52.799 157.1,52.799 157.1,51.101 156.149,51.101" />
          <Path
            d="M157.101,53.049h-15.382c-0.138,0-0.25-0.112-0.25-0.25v-1.698c0-0.138,0.112-0.25,0.25-0.25h0.907
        c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-0.657v1.198h14.882v-1.198h-0.701c-0.138,0-0.25-0.112-0.25-0.25
        s0.112-0.25,0.25-0.25h0.951c0.138,0,0.25,0.112,0.25,0.25v1.698C157.351,52.937,157.238,53.049,157.101,53.049z"
            fill="#000000"
          />
        </G>
        <G>
          <Polygon fill="#FFFFFF" points="182.704,103.654 97.927,103.654 97.927,114.767 183.021,114.767 183.021,103.66" />
          <Path
            d="M183.02,115.141H97.927c-0.207,0-0.375-0.168-0.375-0.375v-11.112c0-0.207,0.168-0.375,0.375-0.375h84.777
        c0.002,0,0.005,0,0.007,0l0.316,0.006c0.204,0.004,0.368,0.171,0.368,0.375v11.106
        C183.395,114.973,183.227,115.141,183.02,115.141z M98.302,114.391h84.343v-10.362H98.302V114.391z"
            fill="#000000"
          />
        </G>
        <Path
          d="M153.6,79.053h-8.173c-0.097,0-0.175-0.078-0.175-0.175v-2.657c0-0.097,0.078-0.175,0.175-0.175h8.173
      c0.097,0,0.175,0.078,0.175,0.175v2.657C153.774,78.974,153.696,79.053,153.6,79.053z M145.601,78.703h7.824v-2.307h-7.824V78.703z
      "
          fill="#000000"
        />
        <Path
          d="M153.433,92.414h-7.628c-0.097,0-0.175-0.078-0.175-0.175v-11.03c0-0.097,0.078-0.175,0.175-0.175h7.628
      c0.097,0,0.175,0.078,0.175,0.175v11.03C153.607,92.336,153.529,92.414,153.433,92.414z M145.979,92.064h7.279v-10.68h-7.279
      V92.064z"
          fill="#000000"
        />
        <G>
          <Polygon
            fill="#FFFFFF"
            points="101.161,96.033 101.161,67.945 104.495,67.945 104.495,102.426 101.161,102.426 101.161,97.322
        "
          />
          <Path
            d="M104.496,102.801h-3.334c-0.207,0-0.375-0.168-0.375-0.375V67.945c0-0.207,0.168-0.375,0.375-0.375h3.334
        c0.207,0,0.375,0.168,0.375,0.375v34.481C104.871,102.633,104.703,102.801,104.496,102.801z M101.536,102.051h2.584V68.32h-2.584
        V102.051z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="99.494,65.032 99.494,66.699 154.263,66.699 154.263,64.661" />
          <Path
            d="M154.263,67.074H99.494c-0.207,0-0.375-0.168-0.375-0.375v-1.667c0-0.207,0.168-0.375,0.375-0.375
        s0.375,0.168,0.375,0.375v1.292h54.019v-1.663c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.038
        C154.638,66.906,154.47,67.074,154.263,67.074z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#BAC2CA" points="165.408,58.411 151.856,63.423 98.364,63.315 111.514,58.411" />
          <G>
            <Polyline fill="#FFFFFF" points="98.039,63.362 98.505,63.362 151.785,63.425" />
            <Path
              d="M151.785,63.675L151.785,63.675l-53.746-0.063c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h0.466l53.28,0.063
          c0.139,0,0.25,0.112,0.25,0.25S151.923,63.675,151.785,63.675z"
              fill="#000000"
            />
          </G>
          <Path
            d="M99.529,65.41h-1.49c-0.693,0-1.257-0.543-1.257-1.211s0.564-1.211,1.257-1.211c0.207,0,0.375,0.168,0.375,0.375
        s-0.168,0.375-0.375,0.375c-0.279,0-0.507,0.207-0.507,0.461s0.228,0.461,0.507,0.461h1.49c0.207,0,0.375,0.168,0.375,0.375
        S99.736,65.41,99.529,65.41z"
            fill="#000000"
          />
          <G>
            <Line fill="none" x1="129.016" x2="99.529" y1="65.035" y2="65.035" />
            <Path
              d="M129.016,65.285H99.529c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h29.486c0.138,0,0.25,0.112,0.25,0.25
          S129.154,65.285,129.016,65.285z"
              fill="#000000"
            />
          </G>
          <G>
            <Line fill="none" x1="152.133" x2="130.365" y1="65.035" y2="65.035" />
            <Path
              d="M152.134,65.285h-21.769c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h21.769c0.138,0,0.25,0.112,0.25,0.25
          S152.271,65.285,152.134,65.285z"
              fill="#000000"
            />
          </G>
          <Path
            d="M152.912,62.858l1.511,1.015c0,0-1.304,1.038-1.858,1.06s-1.291-0.392-0.738-1.305
        C152.185,63.036,152.912,62.858,152.912,62.858z"
            fill="#FFFFFF"
          />
          <Path
            d="M164.168,61.113c-0.062,0-0.119-0.038-0.141-0.099c-0.028-0.078,0.012-0.164,0.09-0.192l0.953-0.346l0.018-2.145
        c0-0.082,0.067-0.148,0.149-0.148h0.001c0.083,0.001,0.149,0.068,0.148,0.151l-0.019,2.249c0,0.062-0.039,0.118-0.099,0.139
        l-1.051,0.381C164.202,61.11,164.184,61.113,164.168,61.113z"
            fill="#000000"
          />
          <Path
            d="M152.476,65.324c-0.062,0-0.119-0.003-0.172-0.01c-0.002,0-0.004,0-0.007,0c-0.258,0-0.407-0.123-0.565-0.252l-0.05-0.041
        c-0.278-0.225-0.419-0.683-0.342-1.113c0.074-0.416,0.332-0.727,0.706-0.853l13.102-4.957c0.128-0.048,0.273,0.016,0.322,0.146
        c0.049,0.129-0.016,0.273-0.145,0.322l-13.11,4.96c-0.277,0.094-0.358,0.334-0.383,0.47c-0.05,0.275,0.045,0.539,0.164,0.636
        l0.052,0.042c0.145,0.119,0.176,0.141,0.289,0.141c0.075,0.007,0.149,0.012,0.266,0.002c0.358-0.031,0.712-0.155,1.281-0.389
        l11.232-4.098c0.129-0.047,0.273,0.02,0.32,0.149c0.048,0.129-0.019,0.273-0.148,0.32l-11.224,4.094
        c-0.517,0.212-0.956,0.381-1.419,0.42C152.587,65.322,152.53,65.324,152.476,65.324z"
            fill="#000000"
          />
          <Path
            d="M97.832,63.752c-0.152,0-0.296-0.094-0.352-0.245c-0.072-0.194,0.027-0.41,0.222-0.481l13.751-5.077
        c0.042-0.016,0.085-0.023,0.13-0.023l0,0l53.522,0.033c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375l0,0
        l-53.455-0.033L97.962,63.73C97.919,63.745,97.875,63.752,97.832,63.752z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="100.549,67.045 100.549,68.1 105.068,68.1 105.068,67.032" />
          <Path
            d="M105.068,68.349h-4.52c-0.138,0-0.25-0.112-0.25-0.25v-1.055c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.805
        h4.021v-0.818c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.068C105.318,68.237,105.206,68.349,105.068,68.349z"
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="34.602" width="3.334" x="132.108" y="67.836" />
          <Path
            d="M135.442,102.688h-3.334c-0.138,0-0.25-0.112-0.25-0.25V67.836c0-0.138,0.112-0.25,0.25-0.25h3.334
        c0.138,0,0.25,0.112,0.25,0.25v34.602C135.692,102.576,135.58,102.688,135.442,102.688z M132.358,102.188h2.834V68.086h-2.834
        V102.188z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="154.263" x2="165.121" y1="66.699" y2="66.699" />
          <Path
            d="M165.121,67.074h-10.858c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h10.858
        c0.207,0,0.375,0.168,0.375,0.375S165.328,67.074,165.121,67.074z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="99.911,103.296 99.911,102.235 105.772,102.235 105.772,103.28" />
          <Path
            d="M99.911,103.546c-0.138,0-0.25-0.112-0.25-0.25v-1.062c0-0.138,0.112-0.25,0.25-0.25h5.861c0.138,0,0.25,0.112,0.25,0.25
        v1.046c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-0.796h-5.361v0.812C100.161,103.433,100.049,103.546,99.911,103.546z
        "
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="130.649,103.29 130.649,102.196 136.51,102.196 136.51,103.274" />
          <Path
            d="M130.649,103.54c-0.138,0-0.25-0.112-0.25-0.25v-1.094c0-0.138,0.112-0.25,0.25-0.25h5.861c0.138,0,0.25,0.112,0.25,0.25
        v1.078c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-0.828h-5.361v0.844C130.899,103.428,130.787,103.54,130.649,103.54z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="158.73,78.075 158.73,102.439 155.396,102.439 155.396,67.836 158.73,67.836 158.73,77.12" />
          <Path
            d="M158.73,102.688h-3.335c-0.138,0-0.25-0.112-0.25-0.25V67.836c0-0.138,0.112-0.25,0.25-0.25h3.335
        c0.138,0,0.25,0.112,0.25,0.25v9.283c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-9.033h-2.835v34.102h2.835V78.076
        c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v24.363C158.98,102.576,158.868,102.688,158.73,102.688z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="153.937,103.28 153.937,102.245 159.798,102.245 159.798,103.264" />
          <Path
            d="M153.936,103.53c-0.138,0-0.25-0.112-0.25-0.25v-1.035c0-0.138,0.112-0.25,0.25-0.25h5.861c0.138,0,0.25,0.112,0.25,0.25
        v1.02c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-0.77h-5.361v0.785C154.186,103.418,154.074,103.53,153.936,103.53z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline
            fill="#FFFFFF"
            points="128.07,111.628 128.07,103.376 135.448,97.014 135.468,95.256 126.906,102.648 126.906,111.979
            "
          />
          <Path
            d="M126.906,112.229c-0.138,0-0.25-0.112-0.25-0.25v-9.331c0-0.072,0.032-0.142,0.086-0.189l8.562-7.393
        c0.074-0.065,0.18-0.079,0.269-0.037c0.089,0.041,0.146,0.131,0.145,0.229l-0.02,1.758c-0.001,0.071-0.032,0.14-0.087,0.187
        l-7.292,6.288v8.137c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-8.251c0-0.072,0.032-0.142,0.087-0.189l7.293-6.289
        l0.012-1.091l-8.056,6.955v9.217C127.156,112.117,127.044,112.229,126.906,112.229z"
            fill="#000000"
          />
        </G>
        <G>
          <G>
            <G>
              <Polyline
                fill="#FFFFFF"
                points="134.765,106.349 132.211,106.349 132.211,109.118 129.372,109.118 129.372,111.772
            126.918,111.772 126.918,114.656 130.289,114.656 130.675,114.656 151.736,114.656 151.736,109.414 154.216,109.414
            154.216,106.349 151.293,106.349"
              />
              <Path
                d="M151.736,114.906h-24.818c-0.138,0-0.25-0.112-0.25-0.25v-2.884c0-0.138,0.112-0.25,0.25-0.25h2.204v-2.404
            c0-0.138,0.112-0.25,0.25-0.25h2.589v-2.52c0-0.138,0.112-0.25,0.25-0.25h2.554c0.138,0,0.25,0.112,0.25,0.25
            s-0.112,0.25-0.25,0.25h-2.304v2.52c0,0.138-0.112,0.25-0.25,0.25h-2.589v2.404c0,0.138-0.112,0.25-0.25,0.25h-2.204v2.384
            h24.318v-4.992c0-0.138,0.112-0.25,0.25-0.25h2.229v-2.565h-2.673c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h2.923
            c0.138,0,0.25,0.112,0.25,0.25v3.065c0,0.138-0.112,0.25-0.25,0.25h-2.229v4.992
            C151.986,114.794,151.874,114.906,151.736,114.906z"
                fill="#000000"
              />
            </G>
            <G>
              <Line fill="none" x1="130.824" x2="144.239" y1="111.715" y2="111.715" />
              <Path
                d="M144.239,111.965h-13.415c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h13.415c0.138,0,0.25,0.112,0.25,0.25
            S144.377,111.965,144.239,111.965z"
                fill="#000000"
              />
            </G>
            <G>
              <Line fill="none" x1="131.845" x2="149.038" y1="109.098" y2="109.098" />
              <Path
                d="M149.038,109.348h-17.193c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h17.193c0.138,0,0.25,0.112,0.25,0.25
            S149.176,109.348,149.038,109.348z"
                fill="#000000"
              />
            </G>
            <G>
              <Line fill="none" x1="151.638" x2="136.442" y1="106.349" y2="106.349" />
              <Path
                d="M151.638,106.598h-15.196c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h15.196c0.138,0,0.25,0.112,0.25,0.25
            S151.775,106.598,151.638,106.598z"
                fill="#000000"
              />
            </G>
          </G>
        </G>
        <G>
          <Polyline
            fill="#FFFFFF"
            points="147.802,111.628 147.802,103.376 155.415,96.831 155.407,95.061 146.638,102.648
        146.638,111.979"
          />
          <Path
            d="M146.639,112.229c-0.138,0-0.25-0.112-0.25-0.25v-9.331c0-0.072,0.031-0.142,0.086-0.189l8.768-7.588
        c0.075-0.064,0.18-0.077,0.268-0.038c0.089,0.04,0.146,0.129,0.146,0.227l0.01,1.771c0,0.073-0.031,0.143-0.087,0.19l-7.526,6.472
        v8.136c0,0.138-0.112,0.25-0.25,0.25s-0.25-0.112-0.25-0.25v-8.251c0-0.073,0.032-0.142,0.087-0.189l7.525-6.471l-0.006-1.112
        l-8.271,7.158v9.217C146.889,112.117,146.776,112.229,146.639,112.229z"
            fill="#000000"
          />
        </G>
        <G>
          <Polygon
            fill="#E6EBEF"
            points="153.956,103.754 153.956,106.389 151.345,106.389 151.345,109.163 149.028,109.163
        149.028,111.717 146.628,111.717 146.628,114.734 156.991,114.744 156.991,103.754"
          />
          <Path
            d="M156.991,114.994L156.991,114.994l-10.363-0.01c-0.139,0-0.25-0.112-0.25-0.25v-3.018c0-0.138,0.112-0.25,0.25-0.25h2.15
        v-2.304c0-0.138,0.112-0.25,0.25-0.25h2.066v-2.523c0-0.138,0.112-0.25,0.25-0.25h2.361v-2.386c0-0.138,0.112-0.25,0.25-0.25
        h3.035c0.138,0,0.25,0.112,0.25,0.25v10.99c0,0.066-0.026,0.13-0.073,0.177S157.058,114.994,156.991,114.994z M146.878,114.484
        l9.863,0.01v-10.49h-2.535v2.386c0,0.138-0.112,0.25-0.25,0.25h-2.361v2.523c0,0.138-0.112,0.25-0.25,0.25h-2.066v2.304
        c0,0.138-0.112,0.25-0.25,0.25h-2.15v2.517H146.878z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="134.739" x2="134.739" y1="103.767" y2="106.216" />
          <Path
            d="M134.739,106.466c-0.138,0-0.25-0.112-0.25-0.25v-2.448c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.448
        C134.989,106.353,134.877,106.466,134.739,106.466z"
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="1.417" width="6.461" x="158.782" y="88.232" />
          <Path
            d="M165.243,89.898h-6.461c-0.138,0-0.25-0.112-0.25-0.25v-1.416c0-0.138,0.112-0.25,0.25-0.25h6.461
        c0.138,0,0.25,0.112,0.25,0.25v1.416C165.493,89.786,165.381,89.898,165.243,89.898z M159.032,89.398h5.961v-0.916h-5.961V89.398z
        "
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="107.074" y="89.649" />
          <Path
            d="M108.194,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C108.444,103.397,108.332,103.51,108.194,103.51z M107.324,103.01h0.62V89.898h-0.62V103.01z
        "
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="110.799" y="89.649" />
          <Path
            d="M111.919,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C112.169,103.397,112.058,103.51,111.919,103.51z M111.049,103.01h0.62V89.898h-0.62V103.01z
        "
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="114.524" y="89.649" />
          <Path
            d="M115.644,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C115.894,103.397,115.783,103.51,115.644,103.51z M114.774,103.01h0.62V89.898h-0.62V103.01z
        "
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="118.249" y="89.649" />
          <Path
            d="M119.37,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C119.62,103.397,119.508,103.51,119.37,103.51z M118.499,103.01h0.62V89.898h-0.62V103.01z"
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="13.611" width="1.12" x="121.974" y="89.649" />
          <Path
            d="M123.095,103.51h-1.12c-0.138,0-0.25-0.112-0.25-0.25V89.648c0-0.138,0.112-0.25,0.25-0.25h1.12
        c0.138,0,0.25,0.112,0.25,0.25v13.611C123.345,103.397,123.233,103.51,123.095,103.51z M122.225,103.01h0.62V89.898h-0.62V103.01z
        "
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M145.672,98.374c0.502,1.559-0.624,2.096-0.624,2.096l0.007,0.047c-0.291-1.147-1.083-2.12-2.181-2.628
        l-0.068-0.033c0.016-0.057,0.034-0.112,0.046-0.173c0.039-0.206,0.053-0.407,0.049-0.603l0.127,0.133c0,0,0.767,0.117,0.975-0.481
        c0.208-0.598-0.052-1.351-1.143-2.313c-1.091-0.962-1.754-0.65-1.754-0.65s-0.271-0.214-0.686-0.214
        c-0.416,0-0.643,0.227-0.643,0.227l-0.041,0.002l0.009-0.015c0,0-0.663-0.312-1.754,0.65s-1.351,1.715-1.143,2.313
        c0.208,0.598,0.975,0.481,0.975,0.481l0.131-0.235c-0.011,0.228-0.001,0.463,0.045,0.705c0.062,0.332,0.191,0.599,0.356,0.815
        v0.041c-0.007,0.008-0.304,0.362-0.317,0.902c-0.009,0.36-0.006,2.033-0.003,3.132c0.001,0.543,0.442,0.981,0.984,0.981h0.146
        c0.544,0,0.984-0.441,0.984-0.984v-1.173l-0.047-0.075c0.206,0.049,0.447,0.066,0.703,0.019l0.025,0.068l-0.031,0.02v1.16
        c0,0.544,0.441,0.984,0.984,0.984h0.12c0.544,0,0.984-0.441,0.984-0.984v-0.009h0.527c-0.011,0.05-0.017,0.102-0.017,0.155
        c0,0.413,0.335,0.747,0.747,0.747h0.473c0.319,0,0.577-0.26,0.574-0.58l-0.015-1.605l0.04,0.263
        c0.797-0.468,1.022-1.204,1.022-1.204C146.642,99.171,145.672,98.374,145.672,98.374z"
            fill="#FFFFFF"
          />
          <Path
            d="M138.083,99.457c-0.437,0.135-0.589,0.623-0.867,0.949c-0.17,0.199-0.565,0.354-0.565,0.354l-0.327-0.629
        c0,0,0.444-0.098,0.586-0.317s0.405-0.664,0.85-0.772"
            fill={svgHighlight}
          />
          <Path
            d="M138.191,99.32c0,0-0.89-0.479-1.48-0.062c-0.499,0.352-1.121,0.569-1.802,0.239l0.123-0.876
        c0.671,0.35,1.299,0.152,1.809-0.185c0.603-0.399,1.478,0.107,1.478,0.107"
            fill={svgHighlight}
          />
          <Path
            d="M135.007,98.691c-0.014,0-0.028-0.003-0.042-0.011l-0.447-0.234c-0.044-0.022-0.061-0.077-0.038-0.121
        c0.023-0.044,0.076-0.062,0.122-0.037l0.446,0.234c0.044,0.022,0.061,0.077,0.038,0.121
        C135.071,98.674,135.039,98.691,135.007,98.691z"
            fill="#000000"
          />
          <Path
            d="M134.893,99.12c-0.014,0-0.028-0.003-0.042-0.011l-0.446-0.234c-0.044-0.022-0.061-0.077-0.038-0.121
        s0.078-0.062,0.122-0.037l0.446,0.234c0.044,0.022,0.061,0.077,0.038,0.121C134.957,99.102,134.926,99.12,134.893,99.12z"
            fill="#000000"
          />
          <Path
            d="M134.843,99.571c-0.014,0-0.028-0.003-0.041-0.01l-0.477-0.244c-0.044-0.022-0.062-0.077-0.039-0.121
        c0.022-0.045,0.077-0.062,0.121-0.039l0.477,0.244c0.044,0.022,0.062,0.077,0.039,0.121
        C134.907,99.554,134.876,99.571,134.843,99.571z"
            fill="#000000"
          />
          <Path
            d="M138.112,99.233c0,0,0.715,0.948,2.115,1.021c1.4,0.073,3.077-0.744,2.917-2.246l-0.201-0.021
        c0,0-1.073,1.271-2.055,1.396c-1.371,0.175-2.618-0.895-2.618-0.895L138.112,99.233z"
            fill={svgHighlight}
          />
          <Path
            d="M140.405,100.358c-0.061,0-0.122-0.002-0.183-0.005c-1.43-0.074-2.159-1.021-2.189-1.061
        c-0.033-0.044-0.024-0.106,0.02-0.14s0.106-0.025,0.14,0.019c0.007,0.01,0.707,0.912,2.041,0.982
        c0.903,0.044,1.84-0.281,2.365-0.835c0.349-0.369,0.5-0.807,0.447-1.301c-0.006-0.055,0.034-0.104,0.088-0.11
        c0.065,0,0.104,0.034,0.11,0.089c0.059,0.547-0.115,1.052-0.5,1.459C142.215,100.014,141.313,100.358,140.405,100.358z"
            fill="#000000"
          />
          <Path
            d="M135.592,99.76c-0.247,0-0.489-0.058-0.728-0.173c-0.039-0.019-0.062-0.061-0.056-0.104l0.123-0.876
        c0.005-0.032,0.024-0.061,0.053-0.075c0.031-0.013,0.064-0.013,0.092,0c0.532,0.279,1.107,0.217,1.707-0.179
        c0.65-0.429,1.545,0.083,1.583,0.103c0.048,0.028,0.064,0.089,0.037,0.137c-0.028,0.048-0.09,0.062-0.136,0.037
        c-0.008-0.006-0.83-0.466-1.373-0.11c-0.62,0.41-1.218,0.493-1.784,0.25l-0.094,0.668c0.521,0.226,1.071,0.137,1.635-0.262
        c0.633-0.445,1.546,0.035,1.585,0.057c0.048,0.026,0.066,0.087,0.041,0.135c-0.026,0.05-0.085,0.067-0.135,0.041
        c-0.008-0.004-0.844-0.444-1.375-0.068C136.37,99.62,135.977,99.76,135.592,99.76z"
            fill="#000000"
          />
          <G>
            <Path
              d="M139.184,103.805h-0.146c-0.679,0-1.232-0.552-1.234-1.229c-0.003-1.241-0.005-2.792,0.003-3.139
          c0.015-0.624,0.282-0.923,0.312-0.954c0.097-0.1,0.254-0.105,0.354-0.009c0.099,0.094,0.104,0.25,0.012,0.35
          c-0.013,0.015-0.167,0.205-0.177,0.625c-0.008,0.341-0.006,1.888-0.003,3.125c0.001,0.403,0.331,0.731,0.734,0.731h0.146
          c0.405,0,0.734-0.329,0.734-0.734v-1.173c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.173
          C140.419,103.251,139.865,103.805,139.184,103.805z"
              fill="#000000"
            />
            <Path
              d="M140.542,101.52c-0.675,0-1.189-0.374-1.197-0.38c-0.066-0.049-0.08-0.143-0.031-0.209
          c0.049-0.068,0.144-0.079,0.209-0.031c0.04,0.03,0.993,0.713,1.938,0.001c0.067-0.049,0.161-0.037,0.21,0.029
          c0.05,0.065,0.037,0.16-0.029,0.209C141.265,101.425,140.883,101.52,140.542,101.52z"
              fill="#000000"
            />
            <Path
              d="M141.923,103.723h-0.12c-0.625,0-1.134-0.509-1.134-1.134v-1.159c0-0.083,0.067-0.149,0.15-0.149s0.15,0.066,0.15,0.149
          v1.159c0,0.46,0.375,0.835,0.834,0.835h0.12c0.46,0,0.834-0.375,0.834-0.835v-2.518c0-0.083,0.067-0.149,0.15-0.149
          s0.15,0.066,0.15,0.149v2.518C143.058,103.215,142.549,103.723,141.923,103.723z"
              fill="#000000"
            />
            <Path
              d="M143.398,102.742h-0.47c-0.083,0-0.15-0.066-0.15-0.149s0.067-0.149,0.15-0.149h0.47c0.083,0,0.15,0.066,0.15,0.149
          S143.48,102.742,143.398,102.742z"
              fill="#000000"
            />
            <Path
              d="M144.638,103.733h-0.473c-0.55,0-0.997-0.447-0.997-0.997s0.447-0.997,0.997-0.997h0.098c0.138,0,0.25,0.112,0.25,0.25
          s-0.112,0.25-0.25,0.25h-0.098c-0.274,0-0.497,0.223-0.497,0.497s0.223,0.497,0.497,0.497h0.473c0.087,0,0.169-0.034,0.23-0.096
          c0.062-0.062,0.095-0.145,0.094-0.232l-0.015-1.612c-0.07-1.373-0.896-2.595-2.154-3.177c-0.125-0.058-0.18-0.206-0.122-0.331
          c0.059-0.126,0.207-0.18,0.332-0.122c1.427,0.66,2.363,2.045,2.443,3.615l0.016,1.623c0.002,0.222-0.083,0.431-0.239,0.588
          C145.067,103.646,144.859,103.733,144.638,103.733z"
              fill="#000000"
            />
            <Path
              d="M145.237,101.812c-0.085,0-0.169-0.044-0.216-0.123c-0.07-0.119-0.03-0.272,0.089-0.343
          c0.705-0.413,0.907-1.055,0.909-1.062c0.115-0.336,0.104-0.635,0.037-0.886c-0.126,0.926-0.867,1.292-0.877,1.297
          c-0.126,0.059-0.274,0.006-0.333-0.118s-0.007-0.272,0.116-0.332c0.039-0.02,0.92-0.476,0.495-1.794
          c-0.034-0.106,0.005-0.222,0.097-0.284c0.092-0.061,0.214-0.055,0.299,0.014c0.045,0.037,1.099,0.923,0.641,2.258
          c-0.008,0.026-0.261,0.827-1.132,1.339C145.324,101.802,145.28,101.812,145.237,101.812z"
              fill="#000000"
            />
            <Path
              d="M140.556,98.412c-0.651,0-1.103-0.474-1.109-0.48c-0.038-0.041-0.035-0.104,0.005-0.142
          c0.04-0.038,0.103-0.034,0.141,0.005c0.036,0.039,0.89,0.929,1.926-0.006c0.041-0.038,0.103-0.035,0.141,0.008
          c0.037,0.04,0.034,0.104-0.007,0.141C141.257,98.294,140.881,98.412,140.556,98.412z"
              fill="#000000"
            />
            <Path
              d="M140.52,98.379c-0.055,0-0.1-0.045-0.1-0.1v-0.877c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v0.877
          C140.62,98.334,140.575,98.379,140.52,98.379z"
              fill="#000000"
            />
            <Circle cx="141.429" cy="95.908" fill="#000000" r="0.174" />
            <Circle cx="139.682" cy="95.908" fill="#000000" r="0.174" />
            <Ellipse cx="140.523" cy="96.752" fill="#000000" rx="0.761" ry="0.353" />
            <Path d="M139.903,96.72l-0.14,0.032c0,0,0.065,0.604,0.741,0.702l0.049,0.01l0.075-0.611L139.903,96.72z" fill="#000000" />
            <Path d="M141.146,96.72l0.14,0.032c0,0-0.065,0.604-0.741,0.702l-0.049,0.01l-0.075-0.611L141.146,96.72z" fill="#000000" />
            <Path
              d="M137.69,97.474c-0.36,0-0.875-0.134-1.058-0.659c-0.267-0.769,0.141-1.638,1.215-2.583
          c1.179-1.039,1.941-0.729,2.025-0.688c0.125,0.059,0.179,0.208,0.12,0.333c-0.058,0.123-0.205,0.177-0.327,0.122
          c-0.021-0.009-0.57-0.2-1.488,0.608c-0.906,0.798-1.267,1.486-1.072,2.044c0.138,0.396,0.693,0.313,0.701,0.315
          c0.138-0.018,0.264,0.073,0.285,0.209c0.021,0.137-0.073,0.265-0.209,0.285C137.826,97.47,137.761,97.474,137.69,97.474z"
              fill="#000000"
            />
            <Path
              d="M140.409,99.554c-0.022,0-2.195-0.021-2.537-1.843c-0.333-1.771,0.973-3.252,1.029-3.313
          c0.055-0.062,0.149-0.065,0.211-0.012c0.062,0.055,0.066,0.149,0.011,0.211c-0.012,0.015-1.263,1.435-0.958,3.06
          c0.296,1.576,2.164,1.598,2.243,1.599c0.083,0,0.15,0.067,0.15,0.149C140.558,99.487,140.491,99.554,140.409,99.554z"
              fill="#000000"
            />
            <Path
              d="M143.205,97.474c-0.071,0-0.136-0.005-0.191-0.014c-0.136-0.021-0.229-0.147-0.209-0.283
          c0.02-0.137,0.145-0.236,0.283-0.211c0.022,0.002,0.565,0.076,0.702-0.315c0.194-0.558-0.167-1.246-1.072-2.044
          c-0.932-0.821-1.482-0.609-1.488-0.608c-0.083,0.034-0.185,0.023-0.256-0.033c0,0-0.214-0.16-0.531-0.16
          c-0.299,0-0.462,0.15-0.469,0.157c-0.101,0.092-0.259,0.09-0.352-0.007c-0.095-0.098-0.096-0.253,0.001-0.35
          c0.031-0.03,0.314-0.301,0.82-0.301c0.333,0,0.586,0.111,0.725,0.191c0.286-0.063,0.955-0.08,1.88,0.735
          c1.074,0.945,1.482,1.814,1.215,2.583C144.08,97.341,143.565,97.474,143.205,97.474z"
              fill="#000000"
            />
            <Path
              d="M140.486,99.554c-0.083,0-0.149-0.066-0.15-0.149c0-0.082,0.067-0.149,0.15-0.149c0.079-0.001,1.946-0.022,2.243-1.599
          c0.305-1.625-0.945-3.045-0.957-3.06c-0.055-0.062-0.05-0.156,0.011-0.211s0.156-0.051,0.211,0.012
          c0.056,0.062,1.362,1.542,1.028,3.313C142.681,99.533,140.508,99.554,140.486,99.554z"
              fill="#000000"
            />
          </G>
        </G>
        <G>
          <Path
            d="M125.049,46.933c0,0-0.534-0.013-1.55,0.573c-1.016,0.586-1.511,1.836-0.873,2.657
        c0.638,0.821,1.654,0.287,1.654,0.287l2.696,0.026c0,0,1.185-0.182,1.198-1.159s-0.365-2.344-0.365-2.344L125.049,46.933z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              d="M128.556,46.288c0.331-1.169,0.066-1.61,0.066-1.61c-0.821,0.02-1.314,0.518-1.314,0.518
          c-0.567-0.213-1.244,0-1.244,0s-0.493-0.498-1.314-0.518c0,0-0.265,0.44,0.066,1.61c0,0-0.485,1.18,0.669,1.629
          c1.155,0.449,1.905,0.158,2.442-0.033s0.908-0.783,0.717-1.353L128.556,46.288z"
              fill="#FFFFFF"
            />
            <Path
              d="M126.656,48.403c-0.366,0-0.783-0.066-1.261-0.252c-0.4-0.155-0.676-0.405-0.82-0.742
          c-0.196-0.457-0.082-0.937-0.021-1.129c-0.318-1.183-0.051-1.677-0.019-1.73c0.046-0.077,0.113-0.127,0.22-0.121
          c0.684,0.016,1.165,0.328,1.373,0.493c0.224-0.051,0.681-0.125,1.123-0.005c0.211-0.166,0.689-0.471,1.365-0.487
          c0.081-0.001,0.174,0.044,0.22,0.121c0.032,0.053,0.299,0.547-0.018,1.729l0.061,0.168c0.226,0.674-0.164,1.422-0.868,1.673
          C127.657,48.246,127.216,48.403,126.656,48.403z M124.925,44.942c-0.037,0.191-0.062,0.595,0.131,1.278
          c0.015,0.053,0.012,0.111-0.009,0.162c-0.001,0.004-0.177,0.451-0.012,0.832c0.09,0.208,0.272,0.366,0.54,0.47
          c1.038,0.401,1.696,0.167,2.225-0.021c0.436-0.156,0.756-0.606,0.606-1.054l-0.086-0.238c-0.018-0.049-0.02-0.102-0.005-0.152
          c0.192-0.681,0.167-1.084,0.129-1.278c-0.594,0.076-0.956,0.427-0.96,0.431c-0.069,0.069-0.173,0.092-0.265,0.057
          c-0.473-0.178-1.076,0.003-1.082,0.004c-0.091,0.028-0.188,0.004-0.253-0.062C125.883,45.369,125.521,45.017,124.925,44.942z"
              fill="#000000"
            />
          </G>
          <Path
            d="M124.852,46.415c-0.015,0-0.029-0.003-0.043-0.01c-0.049-0.024-0.07-0.083-0.046-0.133
        c0.193-0.402,0.504-0.657,0.657-0.765c-0.262-0.353-0.658-0.658-0.662-0.661c-0.044-0.034-0.052-0.096-0.019-0.14
        c0.034-0.044,0.096-0.053,0.14-0.019c0.02,0.015,0.49,0.376,0.765,0.792c0.016,0.023,0.021,0.051,0.014,0.078
        s-0.023,0.05-0.047,0.063c-0.004,0.002-0.437,0.256-0.668,0.738C124.925,46.394,124.889,46.415,124.852,46.415z"
            fill="#000000"
          />
          <Path
            d="M128.52,46.415c-0.037,0-0.073-0.021-0.09-0.057c-0.232-0.482-0.664-0.736-0.668-0.738
        c-0.024-0.014-0.041-0.037-0.047-0.063s-0.001-0.055,0.014-0.078c0.275-0.415,0.745-0.776,0.765-0.792
        c0.044-0.032,0.107-0.024,0.14,0.019c0.033,0.044,0.025,0.106-0.019,0.14c-0.004,0.003-0.4,0.308-0.662,0.661
        c0.153,0.108,0.463,0.362,0.657,0.765c0.024,0.05,0.003,0.109-0.046,0.133C128.549,46.411,128.535,46.415,128.52,46.415z"
            fill="#000000"
          />
          <Path
            d="M126.869,47.27h-0.293c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h0.293c0.055,0,0.1,0.045,0.1,0.1
        S126.924,47.27,126.869,47.27z"
            fill="#000000"
          />
          <Path
            d="M126.485,47.662c-0.001,0-0.003,0-0.005,0c-0.247-0.004-0.332-0.194-0.342-0.294c-0.005-0.049,0.031-0.093,0.08-0.099
        c0.047-0.009,0.093,0.03,0.099,0.079c0.003,0.022,0.024,0.131,0.166,0.134h0.001c0.133,0,0.146-0.292,0.146-0.295
        c0.001-0.049,0.042-0.095,0.092-0.088c0.05,0.001,0.089,0.042,0.088,0.092C126.807,47.367,126.734,47.662,126.485,47.662z"
            fill="#000000"
          />
          <Path
            d="M126.93,47.662c-0.249,0-0.322-0.295-0.325-0.471c-0.001-0.05,0.038-0.091,0.088-0.092c0,0,0.001,0,0.002,0
        c0.049,0,0.089,0.039,0.09,0.088c0,0.003,0.013,0.295,0.146,0.295c0,0,0,0,0.001,0c0.148-0.002,0.165-0.12,0.166-0.133
        c0.005-0.049,0.052-0.092,0.099-0.08c0.049,0.005,0.085,0.049,0.08,0.099c-0.01,0.1-0.095,0.29-0.342,0.294
        C126.934,47.662,126.932,47.662,126.93,47.662z"
            fill="#000000"
          />
          <Circle cx="125.751" cy="46.74" fill="#000000" r="0.122" />
          <Circle cx="127.636" cy="46.701" fill="#000000" r="0.122" />
          <Path
            d="M123.696,50.833c-0.015,0-0.03,0-0.044,0c-0.345-0.008-0.978-0.094-1.343-0.585c-0.26-0.35-0.313-0.812-0.159-1.372
        c0.507-1.836,2.446-2.157,2.528-2.17c0.132-0.02,0.264,0.071,0.285,0.208c0.021,0.136-0.072,0.264-0.208,0.286
        c-0.069,0.011-1.704,0.289-2.123,1.809c-0.112,0.405-0.086,0.721,0.078,0.941c0.247,0.332,0.749,0.379,0.953,0.383
        c0.359,0.023,0.634-0.106,1.062-0.456l0.118-0.125l0.172-0.251l-0.173,0.181c-0.019-0.018-0.048-0.017-0.066,0
        c-0.621,0.564-1.26,0.593-1.65,0.431c-0.453-0.188-0.534-0.62-0.514-0.823c0.013-0.138,0.138-0.236,0.273-0.224
        c0.136,0.013,0.236,0.134,0.224,0.271c-0.002,0.039-0.002,0.227,0.208,0.314c0.238,0.098,0.674,0.068,1.123-0.339
        c0.212-0.193,0.541-0.189,0.748,0.009c0.106,0.103,0.167,0.24,0.169,0.387c0.003,0.147-0.052,0.287-0.154,0.392l-0.14,0.145
        C124.611,50.614,124.233,50.833,123.696,50.833z"
            fill="#000000"
          />
          <Path
            d="M127.129,50.628c-0.059,0-0.112-0.041-0.126-0.1c-0.016-0.07,0.027-0.14,0.097-0.156c0.213-0.05,0.362-0.239,0.362-0.459
        c0-0.127-0.049-0.246-0.139-0.336c-0.088-0.087-0.206-0.136-0.33-0.136c-0.016-0.001-0.045,0-0.068,0
        c-0.384,0-0.681-0.017-0.885-0.049c-0.071-0.012-0.119-0.078-0.108-0.149s0.081-0.119,0.149-0.108
        c0.202,0.032,0.504,0.05,0.908,0.046c0.001,0,0.003,0,0.004,0c0.193,0,0.375,0.075,0.512,0.211c0.14,0.139,0.216,0.323,0.216,0.52
        c0,0.342-0.231,0.635-0.562,0.712C127.149,50.627,127.139,50.628,127.129,50.628z"
            fill="#000000"
          />
          <Path
            d="M126.448,50.771c-0.944,0-2.145-0.011-2.145-0.011l0.005-0.5c0,0,1.197,0.011,2.14,0.011c0.226,0,0.408-0.003,0.555-0.008
        c0.024,0,0.047-0.003,0.07-0.009c0.137-0.03,0.269,0.053,0.3,0.188c0.031,0.134-0.053,0.269-0.188,0.3
        c-0.056,0.013-0.112,0.02-0.168,0.021C126.868,50.768,126.681,50.771,126.448,50.771z"
            fill="#000000"
          />
          <Path
            d="M127.234,50.708c-0.123,0-0.23-0.091-0.248-0.217c-0.018-0.137,0.078-0.263,0.215-0.281
        c0.221-0.029,0.386-0.127,0.503-0.3c0.36-0.527,0.201-1.615,0.109-1.989c-0.033-0.134,0.049-0.27,0.183-0.302
        c0.131-0.036,0.269,0.048,0.302,0.183c0.016,0.063,0.378,1.57-0.182,2.39c-0.199,0.293-0.485,0.466-0.85,0.515
        C127.257,50.707,127.246,50.708,127.234,50.708z"
            fill="#000000"
          />
        </G>
        <Path
          d="M148.252,91.638c-0.055,0-0.1-0.045-0.1-0.1v-9.742c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v9.742
      C148.351,91.593,148.307,91.638,148.252,91.638z"
          fill="#000000"
        />
        <Path
          d="M150.909,91.638c-0.055,0-0.1-0.045-0.1-0.1v-9.742c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v9.742
      C151.009,91.593,150.964,91.638,150.909,91.638z"
          fill="#000000"
        />
        <Path
          d="M152.915,86.74h-6.694c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h6.694c0.055,0,0.1,0.045,0.1,0.1
      S152.97,86.74,152.915,86.74z"
          fill="#000000"
        />
        <Path
          d="M147.943,78.712c-0.055,0-0.1-0.045-0.1-0.1V76.57c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v2.042
      C148.043,78.667,147.998,78.712,147.943,78.712z"
          fill="#000000"
        />
        <Path
          d="M150.667,78.712c-0.055,0-0.1-0.045-0.1-0.1V76.57c0-0.055,0.045-0.1,0.1-0.1s0.1,0.045,0.1,0.1v2.042
      C150.767,78.667,150.722,78.712,150.667,78.712z"
          fill="#000000"
        />
        <Path
          d="M173.216,33.198h-8.052c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h8.052c0.055,0,0.1,0.045,0.1,0.1
      S173.27,33.198,173.216,33.198z"
          fill="#000000"
        />
        <Path
          d="M182.903,36.335h-14.049c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.049c0.055,0,0.1,0.045,0.1,0.1
      S182.958,36.335,182.903,36.335z"
          fill="#000000"
        />
        <Path
          d="M182.903,39.472h-7.82c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.82c0.055,0,0.1,0.045,0.1,0.1
      S182.958,39.472,182.903,39.472z"
          fill="#000000"
        />
        <Path
          d="M172.537,39.472h-5.593c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.593c0.055,0,0.1,0.045,0.1,0.1
      S172.592,39.472,172.537,39.472z"
          fill="#000000"
        />
        <Path
          d="M181.445,42.61H164.05c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h17.396c0.055,0,0.1,0.045,0.1,0.1
      S181.5,42.61,181.445,42.61z"
          fill="#000000"
        />
        <Path
          d="M180.091,64.312h-11.236c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.236c0.055,0,0.1,0.045,0.1,0.1
      S180.145,64.312,180.091,64.312z"
          fill="#000000"
        />
        <Path
          d="M182.903,67.45h-7.82c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.82c0.055,0,0.1,0.045,0.1,0.1
      S182.958,67.45,182.903,67.45z"
          fill="#000000"
        />
        <Path
          d="M172.537,67.45h-5.593c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.593c0.055,0,0.1,0.045,0.1,0.1
      S172.592,67.45,172.537,67.45z"
          fill="#000000"
        />
        <Path
          d="M178.801,70.587h-10.394c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h10.394c0.055,0,0.1,0.045,0.1,0.1
      S178.855,70.587,178.801,70.587z"
          fill="#000000"
        />
        <Path
          d="M182.903,45.701h-11.321c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.321c0.055,0,0.1,0.045,0.1,0.1
      S182.958,45.701,182.903,45.701z"
          fill="#000000"
        />
        <Path
          d="M178.266,48.632h-15.732c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h15.732c0.055,0,0.1,0.045,0.1,0.1
      S178.32,48.632,178.266,48.632z"
          fill="#000000"
        />
        <Path
          d="M182.903,51.769h-14.049c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.049c0.055,0,0.1,0.045,0.1,0.1
      S182.958,51.769,182.903,51.769z"
          fill="#000000"
        />
        <Path
          d="M182.903,54.907h-7.82c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.82c0.055,0,0.1,0.045,0.1,0.1
      S182.958,54.907,182.903,54.907z"
          fill="#000000"
        />
        <Path
          d="M172.537,54.907h-5.593c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.593c0.055,0,0.1,0.045,0.1,0.1
      S172.592,54.907,172.537,54.907z"
          fill="#000000"
        />
        <Path
          d="M182.903,58.044h-12.868c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h12.868c0.055,0,0.1,0.045,0.1,0.1
      S182.958,58.044,182.903,58.044z"
          fill="#000000"
        />
        <Path
          d="M183.058,61.136h-6.864c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h6.864c0.055,0,0.1,0.045,0.1,0.1
      S183.112,61.136,183.058,61.136z"
          fill="#000000"
        />
        <Path
          d="M181.445,74.103H164.05c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h17.396c0.055,0,0.1,0.045,0.1,0.1
      S181.5,74.103,181.445,74.103z"
          fill="#000000"
        />
        <Path
          d="M169.973,95.806h-2.188c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h2.188c0.055,0,0.1,0.045,0.1,0.1
      S170.027,95.806,169.973,95.806z"
          fill="#000000"
        />
        <Path
          d="M167.784,98.942h-2.598c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h2.598c0.055,0,0.1,0.045,0.1,0.1
      S167.839,98.942,167.784,98.942z"
          fill="#000000"
        />
        <Path
          d="M182.903,77.195h-11.321c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.321c0.055,0,0.1,0.045,0.1,0.1
      S182.958,77.195,182.903,77.195z"
          fill="#000000"
        />
        <Path
          d="M178.266,80.126h-15.732c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h15.732c0.055,0,0.1,0.045,0.1,0.1
      S178.32,80.126,178.266,80.126z"
          fill="#000000"
        />
        <Path
          d="M182.903,83.263h-14.049c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.049c0.055,0,0.1,0.045,0.1,0.1
      S182.958,83.263,182.903,83.263z"
          fill="#000000"
        />
        <Path
          d="M182.903,86.4h-3.164c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h3.164c0.055,0,0.1,0.045,0.1,0.1
      S182.958,86.4,182.903,86.4z"
          fill="#000000"
        />
        <Path
          d="M172.537,86.4h-5.593c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h5.593c0.055,0,0.1,0.045,0.1,0.1
      S172.592,86.4,172.537,86.4z"
          fill="#000000"
        />
        <Path
          d="M182.903,89.537h-13.48c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h13.48c0.055,0,0.1,0.045,0.1,0.1
      S182.958,89.537,182.903,89.537z"
          fill="#000000"
        />
        <Path
          d="M173.684,92.629h-8.276c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h8.276c0.055,0,0.1,0.045,0.1,0.1
      S173.739,92.629,173.684,92.629z"
          fill="#000000"
        />
        <Path
          d="M141.726,9.658h-7.665c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h7.665c0.055,0,0.1,0.045,0.1,0.1
      S141.781,9.658,141.726,9.658z"
          fill="#000000"
        />
        <Path
          d="M141.045,12.517h-11.113c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h11.113c0.055,0,0.1,0.045,0.1,0.1
      S141.1,12.517,141.045,12.517z"
          fill="#000000"
        />
        <Path
          d="M149.899,15.577h-18.417c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h18.417c0.055,0,0.1,0.045,0.1,0.1
      S149.954,15.577,149.899,15.577z"
          fill="#000000"
        />
        <Path
          d="M149.899,25.893h-18.417c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h18.417c0.055,0,0.1,0.045,0.1,0.1
      S149.954,25.893,149.899,25.893z"
          fill="#000000"
        />
        <Path
          d="M128.526,25.893h-2.436c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h2.436c0.055,0,0.1,0.045,0.1,0.1
      S128.581,25.893,128.526,25.893z"
          fill="#000000"
        />
        <Path
          d="M143.994,22.266h-24.49c-0.055,0-0.1-0.045-0.1-0.1s0.044-0.1,0.1-0.1h24.49c0.055,0,0.1,0.045,0.1,0.1
      S144.049,22.266,143.994,22.266z"
          fill="#000000"
        />
        <Path
          d="M151.798,22.266h-4.874c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h4.874c0.055,0,0.1,0.045,0.1,0.1
      S151.852,22.266,151.798,22.266z"
          fill="#000000"
        />
        <Path
          d="M128.371,18.771h-6.345c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h6.345c0.055,0,0.1,0.045,0.1,0.1
      S128.426,18.771,128.371,18.771z"
          fill="#000000"
        />
        <Path
          d="M153.989,18.771H139.61c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h14.379c0.055,0,0.1,0.045,0.1,0.1
      S154.044,18.771,153.989,18.771z"
          fill="#000000"
        />
        <Path
          d="M162.468,42.599h-1.66c-0.055,0-0.1-0.045-0.1-0.1s0.045-0.1,0.1-0.1h1.66c0.055,0,0.1,0.045,0.1,0.1
      S162.522,42.599,162.468,42.599z"
          fill="#000000"
        />
        <Path
          d="M136.712,100.937c-0.036,0-0.07-0.019-0.087-0.052l-0.387-0.707c-0.015-0.027-0.017-0.061-0.003-0.089
      c0.013-0.029,0.039-0.05,0.069-0.057c0.112-0.024,0.426-0.123,0.523-0.273c0.029-0.045,0.055-0.097,0.083-0.151
      c0.107-0.209,0.254-0.495,0.647-0.59c0.052-0.015,0.107,0.019,0.121,0.073c0.013,0.054-0.02,0.107-0.074,0.12
      c-0.304,0.074-0.412,0.284-0.516,0.488c-0.031,0.061-0.061,0.117-0.093,0.168c-0.113,0.175-0.366,0.277-0.521,0.326l0.282,0.516
      c0.119-0.054,0.337-0.165,0.445-0.29c0.09-0.106,0.156-0.231,0.219-0.353c0.127-0.242,0.258-0.493,0.582-0.593
      c0.052-0.017,0.108,0.014,0.125,0.066s-0.013,0.108-0.066,0.125c-0.244,0.075-0.345,0.27-0.463,0.494
      c-0.069,0.131-0.14,0.266-0.244,0.389c-0.184,0.215-0.587,0.375-0.604,0.382C136.736,100.935,136.724,100.937,136.712,100.937z"
          fill="#000000"
        />
        <Path
          d="M135.93,100.318c-0.04,0-0.076-0.026-0.087-0.067c-0.013-0.048,0.016-0.097,0.064-0.109l0.371-0.098
      c0.05-0.013,0.097,0.017,0.11,0.064s-0.016,0.097-0.064,0.109l-0.371,0.098C135.945,100.317,135.937,100.318,135.93,100.318z"
          fill="#000000"
        />
        <Path
          d="M136.088,100.677c-0.04,0-0.076-0.026-0.087-0.067c-0.013-0.048,0.016-0.097,0.064-0.109l0.371-0.098
      c0.052-0.014,0.097,0.016,0.11,0.064c0.013,0.048-0.016,0.097-0.064,0.109l-0.371,0.098
      C136.103,100.676,136.096,100.677,136.088,100.677z"
          fill="#000000"
        />
        <Path
          d="M136.312,101.049c-0.039,0-0.075-0.025-0.086-0.064c-0.014-0.047,0.013-0.098,0.061-0.111l0.368-0.109
      c0.046-0.017,0.097,0.013,0.111,0.061c0.014,0.047-0.013,0.098-0.061,0.111l-0.368,0.109
      C136.33,101.048,136.321,101.049,136.312,101.049z"
          fill="#000000"
        />
        <Rect fill="#FFFFFF" height="1.149" width="1.736" x="111.105" y="63.628" />
        <G>
          <Polyline fill="#FFFFFF" points="154.784,67.081 154.784,68.045 159.303,68.045 159.303,67.068" />
          <Path
            d="M159.303,68.295h-4.52c-0.138,0-0.25-0.112-0.25-0.25v-0.964c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.714
        h4.02v-0.727c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.977C159.553,68.183,159.44,68.295,159.303,68.295z"
            fill="#000000"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="131.495,67.104 131.495,68.159 136.015,68.159 136.015,67.091" />
          <Path
            d="M136.015,68.41h-4.52c-0.138,0-0.25-0.112-0.25-0.25v-1.055c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.805
        h4.02v-0.818c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.068C136.265,68.298,136.153,68.41,136.015,68.41z"
            fill="#000000"
          />
        </G>
        <G>
          <Rect fill="#FFFFFF" height="1.412" width="27.56" x="104.547" y="88.659" />
          <Path
            d="M132.108,90.32h-27.561c-0.138,0-0.25-0.112-0.25-0.25v-1.411c0-0.138,0.112-0.25,0.25-0.25h27.561
        c0.138,0,0.25,0.112,0.25,0.25v1.411C132.357,90.208,132.246,90.32,132.108,90.32z M104.797,89.82h27.062v-0.911h-27.062V89.82z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="272.58" x2="182.035" y1="114.823" y2="114.823" />
          <Path
            d="M272.58,115.198h-90.545c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h90.545
        c0.207,0,0.375,0.168,0.375,0.375S272.787,115.198,272.58,115.198z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="281.637" x2="275.208" y1="114.823" y2="114.823" />
          <Path
            d="M281.637,115.198h-6.429c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h6.429
        c0.207,0,0.375,0.168,0.375,0.375S281.844,115.198,281.637,115.198z"
            fill="#000000"
          />
        </G>
        <Circle cx="84.351" cy="46.254" fill="#FFFFFF" r="2.053" />
        <Circle cx="26.569" cy="87.08" fill="#FFFFFF" r="2.053" />
        <Circle cx="85.786" cy="76.515" fill="#FFFFFF" r="2.053" />
        <Circle cx="89.145" cy="60.145" fill="#FFFFFF" r="1.589" />
        <Circle cx="25.754" cy="107.102" fill="#FFFFFF" r="1.402" />
        <Circle cx="26.667" cy="69.667" fill="#FFFFFF" r="1.589" />
        <Circle cx="101.209" cy="46.862" fill="#FFFFFF" r="0.881" />
        <Circle cx="14.779" cy="82.089" fill="#FFFFFF" r="0.881" />
        <Circle cx="91.916" cy="35.807" fill="#FFFFFF" r="0.881" />
        <Circle cx="15.11" cy="99.705" fill="#FFFFFF" r="1.779" />
        <Circle cx="94.284" cy="90.444" fill="#FFFFFF" r="1.779" />
        <Circle cx="120.045" cy="10.357" fill="#FFFFFF" r="1.779" />
        <G>
          <Path
            d="M243.22,93.546l6.043-3.882c0.917-0.589,2.087-0.609,3.024-0.051l0.86,0.512
        c0.908,0.54,2.039,0.539,2.945-0.003l0,0c0.922-0.551,2.075-0.542,2.988,0.023l5.593,3.462"
            fill="#BAC2CA"
          />
          <Path
            d="M264.674,93.857c-0.045,0-0.091-0.012-0.132-0.037l-5.593-3.463c-0.837-0.519-1.884-0.526-2.729-0.021
        c-0.985,0.589-2.214,0.59-3.201,0.003l-0.86-0.513c-0.859-0.512-1.917-0.492-2.761,0.047l-6.043,3.882
        c-0.112,0.076-0.269,0.042-0.345-0.075c-0.075-0.116-0.041-0.271,0.075-0.345l6.043-3.882c1.002-0.646,2.262-0.665,3.286-0.057
        l0.86,0.513c0.83,0.493,1.861,0.492,2.689-0.003c1.006-0.603,2.25-0.593,3.248,0.024l5.593,3.463
        c0.117,0.073,0.153,0.228,0.081,0.345C264.839,93.815,264.757,93.857,264.674,93.857z"
            fill="#000000"
          />
          <Path
            d="M241.628,105.714h-2.044c-0.282,0-0.527-0.181-0.609-0.45l-3.574-11.653
        c-0.108-0.354-0.429-0.591-0.799-0.591h-2.82c-0.166,0-0.301,0.134-0.301,0.301c0,0.166,0.134,0.301,0.301,0.301h2.82
        c0.104,0,0.194,0.067,0.225,0.166l3.574,11.653c0.16,0.523,0.636,0.875,1.184,0.875h2.148c0.015,0,0.029-0.007,0.044-0.009
        L241.628,105.714z"
            fill="#BAC2CA"
          />
          <Path
            d="M245.588,114.287c-0.638,0-1.19-0.433-1.342-1.053l-1.632-6.679l0.595-0.008l1.621,6.545
        c0.086,0.35,0.397,0.595,0.758,0.595c0.359,0,0.67-0.243,0.757-0.591l1.624-6.584l0.644-0.012l-1.685,6.741
        C246.774,113.856,246.223,114.287,245.588,114.287z"
            fill="#BAC2CA"
          />
          <Path
            d="M234.602,93.02c0.37,0,0.691,0.237,0.799,0.591l3.574,11.653c0.083,0.269,0.327,0.45,0.609,0.45h2.044l0.148,0.592
        c-0.015,0.002-0.028,0.009-0.044,0.009h-2.148c-0.548,0-1.024-0.352-1.184-0.875l-3.574-11.653
        c-0.031-0.1-0.121-0.166-0.224-0.166h-2.82c-0.166,0-0.301-0.134-0.301-0.301s0.134-0.301,0.301-0.301L234.602,93.02
        M234.602,92.52h-2.82c-0.441,0-0.801,0.359-0.801,0.801c0,0.441,0.359,0.801,0.801,0.801h2.624l3.516,11.466
        c0.225,0.735,0.893,1.228,1.662,1.228h2.148c0.045,0,0.117-0.011,0.161-0.023c0.139-0.021,0.242-0.096,0.32-0.213
        s0.082-0.258,0.048-0.394l-0.148-0.592c-0.056-0.223-0.256-0.379-0.485-0.379h-2.044c-0.063,0-0.113-0.037-0.131-0.096
        l-3.574-11.653C235.706,92.9,235.193,92.52,234.602,92.52L234.602,92.52z"
            fill="#000000"
          />
          <Path
            d="M248.614,106.499l-1.685,6.741c-0.154,0.616-0.705,1.046-1.34,1.046c-0.638,0-1.19-0.433-1.342-1.053l-1.632-6.68
        l0.595-0.008l1.621,6.545c0.086,0.35,0.397,0.595,0.758,0.595c0.359,0,0.67-0.243,0.757-0.591l1.624-6.584L248.614,106.499
        M249.579,105.731l-0.979,0.018l-0.644,0.012l-0.576,0.011l-0.138,0.56l-1.624,6.584v-0.001l-0.059-0.003l-1.621-6.545
        l-0.143-0.577l-0.595,0.008l-0.595,0.008l-0.942,0.012l0.224,0.916l1.632,6.68c0.234,0.957,1.086,1.625,2.071,1.625
        c0.98,0,1.83-0.664,2.068-1.615l1.685-6.741L249.579,105.731L249.579,105.731z"
            fill="#000000"
          />
          <Polygon fill="#FFFFFF" points="263.048,106.454 241.834,106.454 238.449,93.591 269.874,93.591" />
          <Polygon fill={svgHighlight} points="263.019,99.461 240.094,99.461 239.788,98.143 263.601,98.143" />
          <Polygon fill={svgHighlight} points="263.632,103.445 241.044,103.445 240.738,102.127 264.214,102.127" />
          <Polygon fill="#E6EBEF" points="263.048,106.454 259.31,106.454 266.053,93.591 269.874,93.591" />
          <Circle cx="265.273" cy="110.74" fill="#000000" r="4.139" />
          <Circle cx="265.273" cy="110.74" fill="#FFFFFF" r="2.942" />
          <Path
            d="M265.591,110.375l-6.599-3.892h-1.724c0.04,0.081,0.102,0.153,0.186,0.202l7.651,4.513
        c0.076,0.045,0.16,0.066,0.242,0.066c0.163,0,0.323-0.084,0.412-0.235C265.894,110.802,265.818,110.509,265.591,110.375z"
            fill="#000000"
          />
          <Path
            d="M263.048,106.829h-21.214c-0.17,0-0.319-0.115-0.362-0.279l-3.385-12.863c-0.03-0.112-0.006-0.232,0.065-0.324
        c0.071-0.093,0.181-0.146,0.297-0.146h31.425c0.132,0,0.254,0.069,0.321,0.182c0.068,0.113,0.071,0.253,0.01,0.369l-6.826,12.863
        C263.314,106.753,263.186,106.829,263.048,106.829z M242.123,106.079h20.699l6.429-12.113h-30.315L242.123,106.079z"
            fill="#000000"
          />
          <Path
            d="M259.338,106.647c-0.04,0-0.08-0.01-0.117-0.029c-0.122-0.064-0.168-0.216-0.104-0.338l6.771-12.778
        c0.065-0.122,0.218-0.166,0.338-0.104c0.122,0.064,0.168,0.216,0.104,0.338l-6.771,12.778
        C259.514,106.599,259.428,106.647,259.338,106.647z"
            fill="#000000"
          />
          <Path
            d="M267.861,95.824h-28.728c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h28.728c0.138,0,0.25,0.112,0.25,0.25
        S267.999,95.824,267.861,95.824z"
            fill="#000000"
          />
        </G>
        <G>
          <Line fill="none" x1="7.773" x2="4.113" y1="114.764" y2="114.764" />
          <Path
            d="M7.773,115.139h-3.66c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h3.66c0.207,0,0.375,0.168,0.375,0.375
        S7.98,115.139,7.773,115.139z"
            fill="#000000"
          />
        </G>
        <G>
          <G>
            <Path d="M41.377,114.412c1.515-3.072,1.588-4.979,1.897-9.445c0,0-2.584,1.844-5.008,6.219" fill="#E6EBEF" />
            <Path
              d="M41.377,114.737c-0.048,0-0.098-0.011-0.144-0.034c-0.161-0.079-0.228-0.273-0.148-0.435
          c1.399-2.837,1.544-4.667,1.812-8.568c-0.882,0.788-2.661,2.599-4.348,5.643c-0.087,0.156-0.285,0.213-0.442,0.127
          c-0.157-0.087-0.213-0.285-0.126-0.441c2.432-4.389,4.996-6.249,5.104-6.326c0.104-0.072,0.238-0.081,0.348-0.019
          c0.11,0.061,0.174,0.181,0.166,0.306l-0.038,0.554c-0.283,4.129-0.413,6.014-1.892,9.013
          C41.612,114.671,41.497,114.737,41.377,114.737z"
              fill="#000000"
            />
          </G>
          <G>
            <Path d="M34.547,114.412c-0.051-3.754,0.571-6.896,2.105-11.973c0,0,2.757,5.524,2.627,12.242" fill="#E6EBEF" />
            <Path
              d="M39.28,115.006c-0.002,0-0.004,0-0.006,0c-0.179-0.004-0.322-0.152-0.318-0.331c0.099-5.102-1.511-9.553-2.242-11.302
          c-1.417,4.818-1.887,7.682-1.841,11.034c0.002,0.18-0.141,0.327-0.321,0.33c-0.001,0-0.003,0-0.004,0
          c-0.178,0-0.323-0.143-0.325-0.32c-0.049-3.619,0.485-6.665,2.12-12.072c0.039-0.128,0.151-0.219,0.284-0.23
          c0.134,0.003,0.258,0.061,0.318,0.179c0.028,0.057,2.791,5.674,2.661,12.394C39.601,114.864,39.456,115.006,39.28,115.006z"
              fill="#000000"
            />
          </G>
          <G>
            <Path d="M35.396,114.699c-0.836-3.66-2.408-7.063-5.204-11.15c0,0-1.076,4.302,0.636,10.799" fill="#E6EBEF" />
            <Path
              d="M35.395,115.024c-0.148,0-0.282-0.103-0.316-0.253c-0.785-3.435-2.246-6.662-4.708-10.376
          c-0.214,1.502-0.485,5.1,0.771,9.869c0.045,0.174-0.058,0.352-0.231,0.397c-0.174,0.043-0.352-0.058-0.397-0.231
          c-1.713-6.503-0.681-10.781-0.637-10.961c0.031-0.124,0.132-0.218,0.258-0.241c0.128-0.018,0.253,0.031,0.326,0.137
          c2.783,4.067,4.403,7.54,5.253,11.262c0.04,0.175-0.069,0.35-0.245,0.389C35.443,115.021,35.419,115.024,35.395,115.024z"
              fill="#000000"
            />
          </G>
          <G>
            <Path d="M32.524,114.707c-2.155-2.662-4.753-4.974-8.679-7.125c0,0,0.592,3.119,3.549,7.152" fill="#E6EBEF" />
            <Path
              d="M27.395,115.059c-0.1,0-0.199-0.046-0.263-0.133c-2.967-4.046-3.582-7.154-3.606-7.284
          c-0.023-0.123,0.026-0.249,0.128-0.323s0.237-0.082,0.347-0.022c3.658,2.005,6.364,4.227,8.775,7.206
          c0.113,0.139,0.092,0.344-0.048,0.457c-0.14,0.114-0.345,0.093-0.457-0.049c-2.207-2.726-4.669-4.805-7.915-6.669
          c0.349,1.136,1.249,3.503,3.3,6.3c0.106,0.145,0.075,0.349-0.07,0.454C27.529,115.039,27.462,115.059,27.395,115.059z"
              fill="#000000"
            />
          </G>
          <G>
            <Path d="M39.226,114.707c2.155-2.662,4.753-4.974,8.679-7.125c0,0-0.592,3.119-3.549,7.152" fill="#E6EBEF" />
            <Path
              d="M44.355,115.059c-0.066,0-0.134-0.021-0.192-0.063c-0.145-0.105-0.176-0.31-0.07-0.454c2.052-2.797,2.951-5.164,3.3-6.3
          c-3.246,1.864-5.708,3.943-7.915,6.669c-0.113,0.142-0.317,0.163-0.457,0.049c-0.14-0.113-0.161-0.318-0.048-0.457
          c2.411-2.979,5.117-5.201,8.775-7.206c0.11-0.06,0.246-0.052,0.347,0.022c0.102,0.073,0.152,0.199,0.128,0.323
          c-0.025,0.13-0.639,3.238-3.606,7.284C44.554,115.014,44.455,115.059,44.355,115.059z"
              fill="#000000"
            />
          </G>
        </G>
        <G>
          <Line fill="none" x1="98.667" x2="10.683" y1="114.764" y2="114.764" />
          <Path
            d="M98.667,115.139H10.683c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h87.984
        c0.207,0,0.375,0.168,0.375,0.375S98.874,115.139,98.667,115.139z"
            fill="#000000"
          />
        </G>
        <G>
          <Path d="M225.446,73.53c0,0,3.683-0.347,2.892-4.197C228.337,69.333,224.974,69.622,225.446,73.53z" fill="#E6EBEF" />
          <Path
            d="M225.445,73.704c-0.088,0-0.163-0.065-0.174-0.154c-0.157-1.306,0.085-2.363,0.719-3.143
        c0.906-1.115,2.274-1.244,2.333-1.249c0.086-0.007,0.168,0.052,0.187,0.139c0.252,1.226,0.089,2.239-0.482,3.012
        c-0.906,1.224-2.498,1.387-2.565,1.394C225.456,73.704,225.45,73.704,225.445,73.704z M228.197,69.531
        c-0.365,0.067-1.297,0.309-1.938,1.101c-0.541,0.667-0.763,1.575-0.659,2.697c0.418-0.077,1.5-0.353,2.147-1.229
        C228.231,71.444,228.383,70.58,228.197,69.531z"
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M88.643,24.817c-3.627,0-6.578-2.951-6.578-6.578s2.951-6.578,6.578-6.578s6.578,2.951,6.578,6.578
        S92.27,24.817,88.643,24.817z M88.643,12.261c-3.296,0-5.978,2.682-5.978,5.978s2.682,5.978,5.978,5.978s5.978-2.682,5.978-5.978
        S91.939,12.261,88.643,12.261z"
            fill="#000000"
          />
          <Path
            d="M88.393,9.818c-0.166,0-0.3-0.134-0.3-0.3V6.896c0-0.166,0.134-0.3,0.3-0.3s0.3,0.134,0.3,0.3v2.622
        C88.693,9.683,88.559,9.818,88.393,9.818z"
            fill="#000000"
          />
          <Path
            d="M88.393,29.721c-0.166,0-0.3-0.134-0.3-0.3V26.8c0-0.166,0.134-0.3,0.3-0.3s0.3,0.134,0.3,0.3v2.622
        C88.693,29.587,88.559,29.721,88.393,29.721z"
            fill="#000000"
          />
          <Path
            d="M94.503,12.349c-0.077,0-0.153-0.029-0.212-0.087c-0.117-0.117-0.117-0.307,0-0.424l1.854-1.854
        c0.116-0.117,0.307-0.117,0.424,0c0.117,0.117,0.117,0.307,0,0.424l-1.854,1.854C94.657,12.32,94.58,12.349,94.503,12.349z"
            fill="#000000"
          />
          <Path
            d="M80.43,26.422c-0.077,0-0.153-0.029-0.212-0.088c-0.117-0.117-0.117-0.307,0-0.424l1.854-1.854
        c0.117-0.117,0.307-0.117,0.424,0c0.117,0.117,0.117,0.307,0,0.424l-1.854,1.854C80.583,26.393,80.506,26.422,80.43,26.422z"
            fill="#000000"
          />
          <Path
            d="M99.656,18.459h-2.622c-0.166,0-0.3-0.134-0.3-0.3s0.134-0.3,0.3-0.3h2.622c0.166,0,0.3,0.134,0.3,0.3
        S99.821,18.459,99.656,18.459z"
            fill="#000000"
          />
          <Path
            d="M79.752,18.459H77.13c-0.166,0-0.3-0.134-0.3-0.3s0.134-0.3,0.3-0.3h2.622c0.166,0,0.3,0.134,0.3,0.3
        S79.918,18.459,79.752,18.459z"
            fill="#000000"
          />
          <Path
            d="M96.357,26.422c-0.077,0-0.153-0.029-0.212-0.088l-1.854-1.854c-0.117-0.117-0.117-0.307,0-0.424s0.307-0.117,0.424,0
        l1.854,1.854c0.117,0.117,0.117,0.307,0,0.424C96.51,26.393,96.434,26.422,96.357,26.422z"
            fill="#000000"
          />
          <Path
            d="M82.283,12.349c-0.077,0-0.154-0.029-0.212-0.088l-1.854-1.854c-0.117-0.117-0.117-0.307,0-0.424
        c0.117-0.116,0.308-0.117,0.424,0l1.854,1.854c0.117,0.117,0.117,0.307,0,0.424C82.436,12.32,82.36,12.349,82.283,12.349z"
            fill="#000000"
          />
        </G>
        <Path
          d="M268.818,30.739c0.917,0,1.41-1.076,0.813-1.772c-1.006-1.173-3.024-1.974-5.349-1.974
      c-0.378,0-0.748,0.022-1.106,0.064c-0.393,0.046-0.736-0.271-0.735-0.667v-0.008c0-2.026-2.563-3.669-5.725-3.669
      c-0.881,0-1.714,0.128-2.459,0.356c-0.348,0.107-0.725-0.103-0.803-0.458c-0.665-3.011-3.889-5.297-7.776-5.297
      c-4.369,0-7.911,2.885-7.911,6.445c0,0.127,0.014,0.251,0.023,0.377c-0.183-0.028-0.368-0.047-0.558-0.047
      c-1.633,0-3.015,1.068-3.49,2.544c-0.107,0.332-0.452,0.52-0.787,0.424c-0.317-0.091-0.651-0.14-0.997-0.14
      c-1.164,0-2.201,0.542-2.873,1.387c-0.781,0.983-0.075,2.435,1.181,2.435C230.266,30.739,268.818,30.739,268.818,30.739z"
          fill="#E6EBEF"
        />
        <Path
          d="M45.58,21.542c1.248,0,2.094-1.263,1.628-2.421c-0.988-2.452-3.079-5.942-7.056-6.354
      c-1.675-0.173-3.231,0.489-4.456,1.306c-0.109,0.073-0.251,0.059-0.348-0.031c-0.845-0.783-2.063-1.274-3.418-1.274
      c-2.552,0-4.621,1.742-4.621,3.891v0.003c0,0.167-0.142,0.298-0.309,0.283c-0.124-0.011-0.25-0.017-0.377-0.017
      c-1.568,0-2.897,0.856-3.37,2.043c-0.059,0.148-0.225,0.217-0.37,0.153c-0.416-0.182-0.889-0.299-1.391-0.286
      c-1.145,0.03-1.892,0.669-2.356,1.318c-0.415,0.58-0.009,1.386,0.705,1.386H45.58z"
          fill="#E6EBEF"
        />
        <G>
          <Path
            d="M228.281,59.925c-0.973-0.854-1.284-2.306-0.619-3.434c1.323-2.244,2.081-4.813,2.081-7.55
        c0-5.22-2.731-9.84-6.936-12.732c-0.534-0.367-0.811-1.031-0.626-1.668c0.218-0.752,0.336-1.541,0.336-2.357
        c0-5.074-4.447-9.188-9.932-9.188s-9.932,4.113-9.932,9.188c0,0.816,0.118,1.605,0.336,2.357c0.185,0.637-0.092,1.3-0.626,1.668
        c-4.205,2.891-6.936,7.512-6.936,12.732c0,2.737,0.758,5.305,2.081,7.55c0.665,1.128,0.353,2.58-0.619,3.434
        c-4.373,3.84-7.11,9.256-7.11,15.274c0,11.652,10.211,21.097,22.806,21.097s22.806-9.445,22.806-21.097
        C235.391,69.181,232.655,63.765,228.281,59.925z"
            fill="#D8DFE5"
          />
          <Path
            d="M212.031,114.876c-0.207,0-0.375-0.168-0.375-0.375V51.693c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
        v62.808C212.406,114.708,212.238,114.876,212.031,114.876z"
            fill="#000000"
          />
          <Path
            d="M211.77,78.564c-0.073,0-0.146-0.021-0.211-0.065l-10.35-7.049c-0.171-0.117-0.216-0.35-0.099-0.521
        c0.117-0.173,0.351-0.214,0.521-0.099l10.35,7.049c0.171,0.117,0.216,0.35,0.099,0.521
        C212.008,78.507,211.89,78.564,211.77,78.564z"
            fill="#000000"
          />
          <Path
            d="M211.986,64.744c-0.119,0-0.236-0.057-0.309-0.163c-0.118-0.17-0.074-0.404,0.096-0.521l5.516-3.792
        c0.172-0.116,0.404-0.074,0.521,0.097c0.118,0.17,0.074,0.404-0.096,0.521l-5.516,3.792
        C212.134,64.723,212.059,64.744,211.986,64.744z"
            fill="#000000"
          />
          <Path
            d="M212.251,86.175c-0.126,0-0.249-0.063-0.32-0.18c-0.107-0.177-0.052-0.407,0.125-0.516l11.722-7.157
        c0.175-0.107,0.406-0.053,0.516,0.125c0.107,0.177,0.052,0.407-0.125,0.515l-11.722,7.158
        C212.386,86.157,212.318,86.175,212.251,86.175z"
            fill="#000000"
          />
          <G>
            <Path
              d="M229.379,66.917c0-0.6-0.428-1.092-0.984-1.177c-0.075-0.585-0.552-1.037-1.136-1.037
          c-0.58,0-1.055,0.446-1.135,1.025c-0.612,0.028-1.101,0.548-1.101,1.189c0,0.655,0.508,1.185,1.137,1.193
          c0.141,0.494,0.577,0.857,1.099,0.857c0.526,0,0.965-0.369,1.103-0.869C228.933,68.028,229.379,67.529,229.379,66.917z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M204.75,82.284c0-0.611-0.436-1.111-1.001-1.198c-0.076-0.595-0.562-1.056-1.156-1.056
          c-0.59,0-1.074,0.454-1.155,1.043c-0.623,0.029-1.12,0.557-1.12,1.21c0,0.666,0.517,1.205,1.157,1.214
          c0.144,0.503,0.587,0.871,1.118,0.871c0.536,0,0.982-0.375,1.122-0.884C204.297,83.415,204.75,82.907,204.75,82.284z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M196.656,75.782c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C196.357,76.526,196.656,76.192,196.656,75.782z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M216.36,29.482c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C216.062,30.227,216.36,29.892,216.36,29.482z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M224.496,45.319c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C224.197,46.063,224.496,45.729,224.496,45.319z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M217.379,72.493c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C217.081,73.237,217.379,72.903,217.379,72.493z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M228.609,84.293c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C228.311,85.037,228.609,84.703,228.609,84.293z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M231.653,75.045c0-0.621-0.443-1.129-1.017-1.217c-0.077-0.605-0.571-1.073-1.175-1.073
          c-0.6,0-1.091,0.462-1.174,1.06c-0.633,0.029-1.138,0.566-1.138,1.23c0,0.677,0.525,1.225,1.175,1.234
          c0.146,0.511,0.597,0.886,1.136,0.886c0.544,0,0.998-0.381,1.14-0.898C231.192,76.194,231.653,75.678,231.653,75.045z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M218.056,39.098c0-0.612-0.436-1.113-1.003-1.2c-0.076-0.596-0.563-1.057-1.158-1.057
          c-0.591,0-1.075,0.455-1.157,1.045c-0.624,0.029-1.122,0.558-1.122,1.212c0,0.667,0.518,1.208,1.159,1.216
          c0.144,0.503,0.588,0.873,1.12,0.873c0.537,0,0.984-0.376,1.124-0.886C217.602,40.23,218.056,39.722,218.056,39.098z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M207.799,90.35c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C207.501,91.094,207.799,90.76,207.799,90.35z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M207.891,57.256c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C207.592,58,207.891,57.666,207.891,57.256z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M210.176,32.79c0-0.402-0.287-0.732-0.659-0.788c-0.05-0.392-0.37-0.695-0.761-0.695
          c-0.388,0-0.707,0.299-0.76,0.687c-0.41,0.019-0.737,0.367-0.737,0.797c0,0.438,0.34,0.793,0.761,0.799
          c0.095,0.331,0.387,0.574,0.736,0.574c0.353,0,0.647-0.247,0.739-0.582C209.877,33.534,210.176,33.2,210.176,32.79z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M204.321,49.897c0-0.545-0.389-0.992-0.893-1.069c-0.068-0.531-0.501-0.942-1.032-0.942
          c-0.527,0-0.958,0.405-1.03,0.931c-0.555,0.026-0.999,0.497-0.999,1.08c0,0.594,0.461,1.075,1.032,1.083
          c0.128,0.448,0.524,0.777,0.998,0.777c0.478,0,0.876-0.335,1.001-0.789C203.917,50.906,204.321,50.453,204.321,49.897z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M209.417,42.918c0-0.605-0.431-1.101-0.991-1.186c-0.076-0.589-0.556-1.045-1.145-1.045
          c-0.584,0-1.063,0.45-1.144,1.033c-0.616,0.028-1.109,0.552-1.109,1.198c0,0.66,0.512,1.194,1.145,1.202
          c0.142,0.498,0.581,0.863,1.107,0.863c0.53,0,0.973-0.371,1.111-0.875C208.969,44.037,209.417,43.534,209.417,42.918z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M203.663,65.167c0-0.593-0.423-1.079-0.972-1.163c-0.074-0.577-0.545-1.025-1.122-1.025
          c-0.573,0-1.042,0.441-1.121,1.012c-0.604,0.028-1.087,0.541-1.087,1.175c0,0.646,0.502,1.17,1.123,1.178
          c0.14,0.488,0.57,0.846,1.085,0.846c0.52,0,0.953-0.364,1.089-0.858C203.223,66.264,203.663,65.772,203.663,65.167z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M221.137,89.112c0-0.557-0.397-1.014-0.913-1.092c-0.07-0.543-0.512-0.963-1.054-0.963
          c-0.538,0-0.979,0.414-1.053,0.951c-0.568,0.026-1.021,0.508-1.021,1.104c0,0.607,0.471,1.099,1.055,1.107
          c0.131,0.458,0.536,0.795,1.02,0.795c0.488,0,0.896-0.342,1.023-0.806C220.723,90.143,221.137,89.68,221.137,89.112z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M220.693,51.877c0-0.559-0.399-1.017-0.916-1.096c-0.07-0.544-0.514-0.966-1.058-0.966
          c-0.54,0-0.982,0.416-1.057,0.954c-0.57,0.026-1.025,0.51-1.025,1.107c0,0.609,0.473,1.103,1.058,1.111
          c0.132,0.46,0.537,0.797,1.023,0.797c0.49,0,0.899-0.343,1.027-0.809C220.278,52.912,220.693,52.447,220.693,51.877z"
              fill="#FFFFFF"
            />
          </G>
          <Path
            d="M212.585,96.672c-12.782,0-23.182-9.633-23.182-21.472c0-5.933,2.57-11.458,7.238-15.556
        c0.859-0.755,1.094-2.029,0.543-2.962c-1.396-2.369-2.133-5.045-2.133-7.74c0-5.186,2.588-9.938,7.099-13.041
        c0.42-0.289,0.612-0.792,0.479-1.254c-0.232-0.804-0.351-1.632-0.351-2.462c0-5.273,4.624-9.562,10.307-9.562
        s10.307,4.29,10.307,9.562c0,0.83-0.118,1.658-0.351,2.462c-0.134,0.461,0.059,0.965,0.479,1.254
        c4.511,3.103,7.099,7.855,7.099,13.041c0,2.697-0.737,5.373-2.133,7.74c-0.55,0.934-0.316,2.207,0.543,2.962l0,0
        c4.668,4.098,7.238,9.623,7.238,15.556C235.767,87.039,225.367,96.672,212.585,96.672z M212.585,23.372
        c-5.27,0-9.557,3.953-9.557,8.812c0,0.759,0.108,1.517,0.321,2.253c0.224,0.772-0.087,1.609-0.773,2.081
        c-4.306,2.96-6.774,7.488-6.774,12.423c0,2.561,0.702,5.106,2.029,7.359c0.742,1.258,0.449,2.901-0.695,3.906
        c-4.503,3.954-6.982,9.278-6.982,14.993c0,11.426,10.062,20.722,22.432,20.722s22.432-9.296,22.432-20.722
        c0-5.714-2.479-11.039-6.982-14.993l0,0c-1.145-1.004-1.437-2.647-0.695-3.906c1.328-2.252,2.029-4.796,2.029-7.359
        c0-4.935-2.469-9.462-6.774-12.423c-0.687-0.473-0.997-1.309-0.773-2.081c0.213-0.736,0.321-1.494,0.321-2.253
        C222.142,27.325,217.854,23.372,212.585,23.372z"
            fill="#000000"
          />
        </G>
        <Path
          d="M90.093,75.804c0-6.937-4.597-12.795-10.909-14.707c-0.704-0.213-0.993-1.017-0.606-1.642
      c1.098-1.772,1.732-3.861,1.732-6.099c0-6.407-5.194-11.601-11.601-11.601c-0.067,0-0.132,0.009-0.2,0.01
      c-0.739-5.47-5.415-9.691-11.087-9.691c-5.68,0-10.361,4.232-11.09,9.712c-0.209-0.011-0.416-0.032-0.627-0.032
      c-6.407,0-11.601,5.194-11.601,11.601c0,2.351,0.704,4.534,1.907,6.361c0.397,0.602,0.141,1.398-0.538,1.642
      c-5.921,2.135-10.158,7.789-10.158,14.445c0,6.489,4.027,12.028,9.713,14.283l-0.015,0.017c0,0,6.144,2.686,18.787,2.764
      c1.262,0.049,2.575,0.079,3.941,0.079c1.25,0,2.469-0.029,3.651-0.076c12.784-0.049,18.991-2.766,18.991-2.766L80.37,90.09
      C86.062,87.836,90.093,82.296,90.093,75.804z"
          fill="#D8DFE5"
        />
        <Path
          d="M57.744,115.121c-0.207,0-0.375-0.168-0.375-0.375V57.479c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v57.267
      C58.119,114.953,57.951,115.121,57.744,115.121z"
          fill="#000000"
        />
        <Path
          d="M57.518,79.891c-0.069,0-0.14-0.019-0.202-0.06l-11.8-7.587c-0.174-0.112-0.225-0.344-0.113-0.518
      c0.112-0.175,0.345-0.226,0.518-0.113l11.8,7.587c0.174,0.112,0.225,0.344,0.113,0.518C57.762,79.83,57.641,79.891,57.518,79.891z"
          fill="#000000"
        />
        <Path
          d="M57.705,68.397c-0.122,0-0.242-0.059-0.313-0.169c-0.114-0.173-0.066-0.405,0.107-0.519l5.911-3.893
      c0.173-0.114,0.405-0.066,0.519,0.107c0.114,0.173,0.066,0.405-0.107,0.519l-5.911,3.893
      C57.847,68.377,57.776,68.397,57.705,68.397z"
          fill="#000000"
        />
        <Path
          d="M57.934,86.221c-0.128,0-0.253-0.065-0.323-0.185c-0.105-0.178-0.046-0.408,0.132-0.514l11.594-6.855
      c0.179-0.107,0.409-0.046,0.514,0.132s0.046,0.408-0.132,0.514l-11.594,6.856C58.064,86.204,57.998,86.221,57.934,86.221z"
          fill="#000000"
        />
        <Path
          d="M57.742,93.321c-1.29,0-2.62-0.026-3.955-0.079c-12.563-0.077-18.669-2.685-18.923-2.795
      c-0.036-0.016-0.068-0.036-0.096-0.062c-5.974-2.426-9.827-8.135-9.827-14.582c0-6.607,4.182-12.554,10.406-14.798
      c0.211-0.077,0.373-0.239,0.441-0.446c0.07-0.211,0.038-0.444-0.089-0.637c-1.289-1.955-1.97-4.226-1.97-6.567
      c0-6.604,5.373-11.976,11.976-11.976c0.103,0,0.205,0.004,0.307,0.011c0.912-5.549,5.756-9.691,11.411-9.691
      c5.648,0,10.494,4.138,11.409,9.681c6.548,0.066,11.854,5.413,11.854,11.975c0,2.231-0.618,4.408-1.788,6.296
      c-0.125,0.203-0.151,0.442-0.07,0.657c0.079,0.21,0.245,0.362,0.465,0.429c6.685,2.025,11.176,8.08,11.176,15.066
      c0,6.457-3.863,12.171-9.851,14.591c-0.024,0.021-0.052,0.038-0.082,0.052c-0.256,0.112-6.424,2.749-19.141,2.798
      C60.155,93.296,58.921,93.321,57.742,93.321z M35.278,89.806c0.895,0.354,7.042,2.615,18.525,2.686
      c2.561,0.102,4.97,0.109,7.574,0.003c11.708-0.045,17.926-2.362,18.76-2.695c0.028-0.024,0.06-0.045,0.096-0.059
      c5.762-2.281,9.484-7.752,9.484-13.937c0-6.654-4.277-12.42-10.643-14.349c-0.442-0.134-0.789-0.456-0.95-0.882
      c-0.164-0.435-0.115-0.914,0.134-1.316c1.096-1.77,1.676-3.811,1.676-5.902c0-6.19-5.036-11.226-11.226-11.226
      c-0.006,0-0.185,0.01-0.191,0.01c-0.003,0-0.006,0-0.008,0c-0.188,0-0.346-0.139-0.372-0.325c-0.722-5.34-5.329-9.366-10.716-9.366
      c-5.398,0-10.006,4.036-10.719,9.387c-0.026,0.194-0.193,0.349-0.392,0.325l-0.2-0.013c-0.135-0.009-0.27-0.019-0.407-0.019
      c-6.19,0-11.226,5.036-11.226,11.226c0,2.194,0.638,4.322,1.846,6.154c0.254,0.387,0.318,0.855,0.175,1.287
      c-0.142,0.427-0.469,0.76-0.898,0.915c-5.928,2.138-9.911,7.801-9.911,14.093c0,6.182,3.72,11.651,9.477,13.934
      C35.208,89.755,35.246,89.777,35.278,89.806z"
          fill="#000000"
        />
        <G>
          <Path
            d="M81.431,80.462c0-0.688-0.509-1.251-1.169-1.348c-0.089-0.67-0.656-1.188-1.35-1.188
        c-0.689,0-1.254,0.511-1.349,1.174c-0.727,0.032-1.308,0.627-1.308,1.362c0,0.75,0.603,1.357,1.351,1.367
        c0.168,0.566,0.686,0.981,1.306,0.981c0.626,0,1.147-0.422,1.311-0.995C80.902,81.735,81.431,81.163,81.431,80.462z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M44.646,50.055c0-0.468-0.346-0.852-0.796-0.918c-0.061-0.456-0.447-0.809-0.92-0.809
        c-0.469,0-0.854,0.348-0.919,0.8c-0.495,0.022-0.891,0.427-0.891,0.928c0,0.511,0.411,0.924,0.92,0.931
        c0.114,0.385,0.467,0.668,0.89,0.668c0.426,0,0.781-0.288,0.893-0.678C44.285,50.922,44.646,50.532,44.646,50.055z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M67.635,73.809c0-0.508-0.376-0.924-0.864-0.996c-0.066-0.495-0.485-0.878-0.998-0.878
        c-0.509,0-0.926,0.378-0.997,0.868c-0.537,0.024-0.966,0.463-0.966,1.007c0,0.554,0.446,1.003,0.998,1.01
        c0.124,0.418,0.507,0.725,0.965,0.725c0.462,0,0.848-0.312,0.968-0.735C67.244,74.749,67.635,74.327,67.635,73.809z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M34.142,81.174c0-0.334-0.247-0.608-0.569-0.656c-0.043-0.326-0.319-0.578-0.657-0.578
        c-0.335,0-0.61,0.249-0.656,0.571c-0.354,0.016-0.636,0.305-0.636,0.663c0,0.365,0.293,0.66,0.657,0.665
        c0.082,0.275,0.333,0.477,0.635,0.477c0.304,0,0.558-0.205,0.637-0.484C33.884,81.793,34.142,81.515,34.142,81.174z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M76.588,66.314c0-0.334-0.247-0.608-0.569-0.656c-0.043-0.326-0.319-0.578-0.657-0.578
        c-0.335,0-0.61,0.249-0.656,0.571c-0.354,0.016-0.636,0.305-0.636,0.663c0,0.365,0.293,0.66,0.657,0.665
        c0.082,0.275,0.333,0.477,0.635,0.477c0.304,0,0.558-0.205,0.637-0.484C76.33,66.932,76.588,66.654,76.588,66.314z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M69.609,86.674c0-0.334-0.247-0.608-0.569-0.656c-0.043-0.326-0.319-0.578-0.657-0.578
        c-0.335,0-0.61,0.249-0.656,0.571c-0.354,0.016-0.636,0.305-0.636,0.663c0,0.365,0.293,0.66,0.657,0.665
        c0.082,0.275,0.333,0.477,0.635,0.477c0.304,0,0.558-0.205,0.637-0.484C69.352,87.293,69.609,87.015,69.609,86.674z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M83.812,71.181c0-0.509-0.376-0.926-0.865-0.998c-0.066-0.496-0.486-0.879-0.999-0.879
        c-0.51,0-0.928,0.378-0.998,0.869c-0.538,0.024-0.968,0.464-0.968,1.008c0,0.555,0.447,1.004,1,1.011
        c0.124,0.419,0.508,0.726,0.967,0.726c0.463,0,0.849-0.312,0.97-0.737C83.421,72.123,83.812,71.7,83.812,71.181z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M71.826,59.934c0-0.509-0.376-0.926-0.865-0.998c-0.066-0.496-0.486-0.879-0.999-0.879
        c-0.51,0-0.928,0.378-0.998,0.869c-0.538,0.024-0.968,0.464-0.968,1.008c0,0.555,0.447,1.004,1,1.011
        c0.124,0.419,0.508,0.726,0.967,0.726c0.463,0,0.849-0.312,0.97-0.737C71.434,60.875,71.826,60.453,71.826,59.934z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M52.532,55.723c0-0.334-0.247-0.608-0.569-0.656c-0.043-0.326-0.319-0.578-0.657-0.578
        c-0.335,0-0.61,0.249-0.656,0.571c-0.354,0.016-0.636,0.305-0.636,0.663c0,0.365,0.293,0.66,0.657,0.665
        c0.082,0.275,0.333,0.477,0.635,0.477c0.304,0,0.558-0.205,0.637-0.484C52.275,56.341,52.532,56.064,52.532,55.723z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M61.235,40.071c0-0.552-0.409-1.005-0.939-1.083c-0.072-0.538-0.527-0.955-1.085-0.955
        c-0.554,0-1.007,0.411-1.084,0.944c-0.584,0.026-1.051,0.504-1.051,1.095c0,0.602,0.485,1.09,1.085,1.098
        c0.135,0.455,0.551,0.788,1.049,0.788c0.503,0,0.922-0.339,1.053-0.8C60.809,41.094,61.235,40.635,61.235,40.071z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M42.352,77.099c0-0.552-0.409-1.005-0.939-1.083c-0.072-0.538-0.527-0.955-1.085-0.955
        c-0.554,0-1.007,0.411-1.084,0.944c-0.584,0.026-1.051,0.504-1.051,1.095c0,0.602,0.485,1.09,1.085,1.098
        c0.135,0.455,0.551,0.788,1.049,0.788c0.503,0,0.922-0.339,1.053-0.8C41.926,78.121,42.352,77.662,42.352,77.099z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M55.241,46.648c0-0.618-0.457-1.124-1.051-1.212c-0.08-0.602-0.59-1.068-1.213-1.068
        c-0.619,0-1.127,0.46-1.212,1.055c-0.653,0.029-1.175,0.564-1.175,1.224c0,0.674,0.542,1.22,1.214,1.228
        c0.151,0.508,0.616,0.882,1.174,0.882c0.562,0,1.031-0.379,1.178-0.894C54.766,47.792,55.241,47.278,55.241,46.648z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M51.26,63.736c0-0.694-0.514-1.264-1.181-1.362c-0.09-0.676-0.663-1.2-1.364-1.2
        c-0.696,0-1.266,0.516-1.362,1.186c-0.734,0.033-1.321,0.634-1.321,1.376c0,0.757,0.61,1.371,1.365,1.38
        c0.17,0.571,0.693,0.991,1.319,0.991c0.632,0,1.159-0.426,1.324-1.005C50.726,65.021,51.26,64.444,51.26,63.736z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M49.327,84.194c0-0.508-0.376-0.924-0.864-0.996c-0.066-0.495-0.485-0.878-0.998-0.878
        c-0.509,0-0.926,0.378-0.997,0.868c-0.537,0.024-0.966,0.463-0.966,1.007c0,0.554,0.446,1.003,0.998,1.01
        c0.124,0.418,0.507,0.725,0.965,0.725c0.462,0,0.848-0.312,0.968-0.735C48.936,85.134,49.327,84.712,49.327,84.194z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M40.173,68.39c0-0.508-0.376-0.924-0.864-0.996c-0.066-0.495-0.485-0.878-0.998-0.878
        c-0.509,0-0.926,0.378-0.997,0.868c-0.537,0.024-0.966,0.463-0.966,1.007c0,0.554,0.446,1.003,0.998,1.01
        c0.124,0.418,0.507,0.725,0.965,0.725c0.462,0,0.848-0.312,0.968-0.735C39.782,69.33,40.173,68.908,40.173,68.39z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M69.773,50.01c0-0.585-0.433-1.065-0.995-1.148c-0.076-0.57-0.559-1.011-1.149-1.011
        c-0.587,0-1.067,0.435-1.148,0.999c-0.619,0.028-1.113,0.534-1.113,1.159c0,0.638,0.514,1.155,1.15,1.163
        c0.143,0.481,0.584,0.835,1.112,0.835c0.532,0,0.976-0.359,1.115-0.847C69.323,51.093,69.773,50.607,69.773,50.01z"
            fill="#FFFFFF"
          />
        </G>
        <Path
          d="M79.405,104.641c0,0,0.232-2.098-1.041-2.768s-3.707-0.424-3.707-0.424s0.491,4.712,3.975,3.841v4.132
      h0.999v-4.855L79.405,104.641z"
          fill="#FFFFFF"
        />
        <Path d="M78.342,101.627c0,0,0.625-2.925,4.868-2.613c0,0,0.581,5.873-3.506,6.208" fill="#FFFFFF" />
        <Path
          d="M69.826,104.641c0,0,0.232-2.098-1.041-2.768s-3.707-0.424-3.707-0.424s0.491,4.712,3.975,3.841v4.132
      h0.999v-4.855L69.826,104.641z"
          fill="#FFFFFF"
        />
        <Path d="M68.763,101.627c0,0,0.625-2.925,4.868-2.613c0,0,0.581,5.873-3.506,6.208" fill="#FFFFFF" />
        <Path
          d="M88.965,104.641c0,0,0.232-2.098-1.041-2.768s-3.707-0.424-3.707-0.424s0.491,4.712,3.975,3.841v4.132
      h0.999v-4.855L88.965,104.641z"
          fill="#FFFFFF"
        />
        <G>
          <Polyline fill="#FFFFFF" points="66.114,114.607 65.127,108.683 94.247,108.683 93.26,114.414" />
          <Polygon
            fill="#000000"
            points="65.867,114.648 64.831,108.432 94.544,108.432 93.506,114.456 93.013,114.372 93.95,108.932 65.421,108.932
        66.36,114.566"
          />
        </G>
        <Polygon fill="#E6EBEF" points="89.965,108.747 90.567,114.628 93.344,114.628 94.022,108.699" />
        <Path
          d="M93.194,114.874c-0.015,0-0.029-0.001-0.044-0.004c-0.136-0.024-0.227-0.153-0.203-0.29l1.001-5.647H65.361l0.939,5.634
      c0.023,0.136-0.069,0.265-0.206,0.288c-0.133,0.018-0.265-0.07-0.288-0.206l-0.987-5.925c-0.012-0.072,0.008-0.146,0.056-0.202
      c0.048-0.057,0.117-0.089,0.191-0.089h29.181c0.074,0,0.144,0.033,0.191,0.09c0.048,0.057,0.068,0.131,0.055,0.204l-1.053,5.941
      C93.418,114.789,93.313,114.874,93.194,114.874z"
          fill="#000000"
        />
        <Path
          d="M90.571,114.814c-0.114,0-0.211-0.086-0.223-0.201l-0.606-5.843c-0.013-0.124,0.077-0.234,0.2-0.247
      c0.126-0.014,0.234,0.077,0.247,0.2l0.606,5.843c0.013,0.124-0.077,0.234-0.2,0.247C90.586,114.814,90.579,114.814,90.571,114.814z
      "
          fill="#000000"
        />
        <G>
          <Path
            d="M69.031,108.763c-0.138,0-0.25-0.112-0.25-0.25v-2.915c-0.812,0.137-1.53-0.009-2.14-0.437
        c-1.546-1.083-1.823-3.58-1.833-3.686c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.168-0.091
        c0.103-0.009,2.524-0.245,3.848,0.451c1.411,0.743,1.191,2.545,1.182,2.621c-0.018,0.137-0.142,0.227-0.28,0.216
        c-0.137-0.019-0.233-0.144-0.216-0.28c0.002-0.016,0.182-1.534-0.918-2.113c-0.955-0.503-2.655-0.458-3.304-0.416
        c0.11,0.639,0.494,2.31,1.586,3.074c0.563,0.393,1.249,0.489,2.041,0.295c0.074-0.019,0.153-0.002,0.214,0.045
        c0.061,0.048,0.096,0.12,0.096,0.197v3.223C69.281,108.65,69.169,108.763,69.031,108.763z"
            fill="#000000"
          />
          <Path
            d="M70.102,105.472c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
        c0.889-0.073,1.612-0.436,2.148-1.079c1.207-1.446,1.181-3.91,1.144-4.644c-3.805-0.194-4.383,2.402-4.389,2.43
        c-0.029,0.136-0.164,0.223-0.297,0.191c-0.135-0.028-0.221-0.161-0.192-0.297c0.006-0.031,0.708-3.148,5.131-2.81
        c0.121,0.009,0.218,0.104,0.23,0.225c0.013,0.136,0.312,3.36-1.243,5.225c-0.625,0.75-1.463,1.173-2.491,1.257
        C70.117,105.472,70.109,105.472,70.102,105.472z"
            fill="#000000"
          />
          <Path
            d="M69.835,103.279c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266
        c0.036-0.076,0.892-1.874,2.378-2.767c0.094-0.055,0.217-0.026,0.274,0.069s0.026,0.217-0.068,0.274
        c-1.381,0.828-2.214,2.575-2.222,2.593C69.982,103.236,69.91,103.279,69.835,103.279z"
            fill="#000000"
          />
          <Path
            d="M70.051,108.867c-0.138,0-0.25-0.112-0.25-0.25v-3.409c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v3.409
        C70.301,108.755,70.189,108.867,70.051,108.867z"
            fill="#000000"
          />
          <Path
            d="M69.769,104.617c-0.067,0-0.133-0.034-0.17-0.096c-0.341-0.557-1.807-1.348-2.343-1.605
        c-0.099-0.048-0.141-0.168-0.093-0.268s0.167-0.139,0.267-0.094c0.083,0.04,2.038,0.987,2.51,1.758
        c0.058,0.095,0.028,0.218-0.066,0.275C69.841,104.607,69.805,104.617,69.769,104.617z"
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M78.672,108.763c-0.138,0-0.25-0.112-0.25-0.25v-2.915c-0.811,0.137-1.53-0.009-2.14-0.437
        c-1.546-1.083-1.823-3.58-1.833-3.686c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.169-0.091
        c0.102-0.009,2.523-0.245,3.848,0.451c1.411,0.743,1.191,2.545,1.181,2.621c-0.018,0.137-0.139,0.227-0.28,0.216
        c-0.137-0.019-0.233-0.144-0.216-0.28c0.002-0.016,0.182-1.534-0.918-2.113c-0.955-0.503-2.656-0.458-3.304-0.416
        c0.11,0.639,0.494,2.31,1.586,3.074c0.562,0.393,1.25,0.489,2.041,0.295c0.075-0.019,0.153-0.002,0.214,0.045
        c0.061,0.048,0.096,0.12,0.096,0.197v3.223C78.922,108.65,78.81,108.763,78.672,108.763z"
            fill="#000000"
          />
          <Path
            d="M79.743,105.472c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
        c0.889-0.073,1.612-0.436,2.148-1.079c1.207-1.446,1.181-3.91,1.144-4.644c-3.796-0.194-4.383,2.402-4.389,2.429
        c-0.029,0.137-0.165,0.224-0.297,0.192c-0.135-0.028-0.221-0.161-0.192-0.296c0.006-0.032,0.708-3.149,5.131-2.811
        c0.121,0.009,0.218,0.104,0.23,0.225c0.013,0.136,0.312,3.36-1.243,5.225c-0.625,0.75-1.463,1.173-2.491,1.257
        C79.757,105.472,79.75,105.472,79.743,105.472z"
            fill="#000000"
          />
          <Path
            d="M79.476,103.279c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266
        c0.036-0.076,0.892-1.874,2.378-2.767c0.094-0.055,0.217-0.026,0.274,0.069s0.026,0.217-0.068,0.274
        c-1.381,0.828-2.214,2.575-2.222,2.593C79.622,103.236,79.551,103.279,79.476,103.279z"
            fill="#000000"
          />
          <Path
            d="M79.691,108.867c-0.138,0-0.25-0.112-0.25-0.25v-3.409c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v3.409
        C79.941,108.755,79.83,108.867,79.691,108.867z"
            fill="#000000"
          />
          <Path
            d="M79.41,104.617c-0.067,0-0.133-0.034-0.17-0.096c-0.341-0.557-1.807-1.348-2.343-1.605
        c-0.099-0.048-0.141-0.168-0.093-0.268c0.047-0.1,0.167-0.139,0.267-0.094c0.083,0.04,2.038,0.987,2.51,1.758
        c0.058,0.095,0.028,0.218-0.066,0.275C79.482,104.607,79.446,104.617,79.41,104.617z"
            fill="#000000"
          />
        </G>
        <Path d="M87.902,101.627c0,0,0.625-2.925,4.868-2.613c0,0,0.581,5.873-3.506,6.208" fill="#FFFFFF" />
        <G>
          <Path
            d="M88.192,108.763c-0.138,0-0.25-0.112-0.25-0.25v-2.915c-0.812,0.137-1.529-0.009-2.14-0.437
        c-1.546-1.083-1.823-3.58-1.833-3.686c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.169-0.091
        c0.103-0.009,2.525-0.245,3.848,0.451c1.411,0.743,1.191,2.545,1.181,2.621c-0.018,0.137-0.138,0.227-0.28,0.216
        c-0.137-0.019-0.233-0.144-0.216-0.28c0.002-0.016,0.182-1.534-0.918-2.113c-0.955-0.503-2.655-0.458-3.304-0.416
        c0.11,0.639,0.494,2.31,1.586,3.074c0.562,0.393,1.25,0.489,2.041,0.295c0.075-0.019,0.153-0.002,0.214,0.045
        c0.061,0.048,0.096,0.12,0.096,0.197v3.223C88.442,108.65,88.33,108.763,88.192,108.763z"
            fill="#000000"
          />
          <Path
            d="M89.264,105.472c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
        c0.889-0.073,1.612-0.436,2.148-1.079c1.207-1.446,1.181-3.91,1.144-4.644c-3.797-0.194-4.383,2.402-4.389,2.429
        c-0.029,0.137-0.164,0.224-0.297,0.192c-0.135-0.028-0.221-0.161-0.192-0.296c0.006-0.032,0.71-3.149,5.131-2.811
        c0.121,0.009,0.218,0.104,0.23,0.225c0.013,0.136,0.312,3.36-1.243,5.225c-0.625,0.75-1.463,1.173-2.491,1.257
        C89.278,105.472,89.27,105.472,89.264,105.472z"
            fill="#000000"
          />
          <Path
            d="M88.997,103.279c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266
        c0.036-0.076,0.891-1.874,2.378-2.767c0.095-0.055,0.217-0.026,0.274,0.069s0.026,0.217-0.068,0.274
        c-1.381,0.828-2.213,2.575-2.222,2.593C89.143,103.236,89.072,103.279,88.997,103.279z"
            fill="#000000"
          />
          <Path
            d="M89.212,108.867c-0.138,0-0.25-0.112-0.25-0.25v-3.409c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v3.409
        C89.462,108.755,89.351,108.867,89.212,108.867z"
            fill="#000000"
          />
          <Path
            d="M88.931,104.617c-0.067,0-0.133-0.034-0.17-0.096c-0.341-0.557-1.807-1.348-2.343-1.605
        c-0.099-0.048-0.141-0.168-0.093-0.268s0.167-0.139,0.267-0.094c0.083,0.04,2.038,0.987,2.51,1.758
        c0.058,0.094,0.028,0.218-0.066,0.275C89.003,104.607,88.967,104.617,88.931,104.617z"
            fill="#000000"
          />
        </G>
        <Path
          d="M95.835,115.002H63.73c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h32.104c0.138,0,0.25,0.112,0.25,0.25
      S95.973,115.002,95.835,115.002z"
          fill="#000000"
        />
        <Path
          d="M197.319,107.545c0.001-0.038,0.006-0.075,0.006-0.113c0-2.975-2.377-5.386-5.308-5.386
      c-0.643,0-1.257,0.122-1.827,0.334c0.022-0.164,0.038-0.331,0.038-0.501c0-2.009-1.605-3.638-3.586-3.638
      c-1.267,0-2.379,0.669-3.016,1.676c-1.029-0.935-2.383-1.508-3.873-1.508c-3.132,0-5.678,2.514-5.803,5.661
      c-0.488-0.195-1.019-0.306-1.576-0.306c-2.272,0-4.128,1.79-4.285,4.055c-1.26,0.542-2.143,1.808-2.143,3.283
      c0,1.968,1.573,3.564,3.513,3.564c0.172,0,0.339-0.017,0.505-0.041v0.03h26.557v-0.049c0.204,0.037,0.413,0.06,0.627,0.06
      c1.94,0,3.513-1.596,3.513-3.564C200.66,109.191,199.179,107.637,197.319,107.545z"
          fill="#FFFFFF"
        />
        <G>
          <Path
            d="M190.223,101.934c0-0.018,0.004-0.036,0.004-0.055c0-0.065-0.006-0.128-0.009-0.192l-0.027,0.013
        L190.223,101.934z"
            fill="#E6EBEF"
          />
          <Path
            d="M196.52,114.605c0.204,0.037,0.413,0.06,0.627,0.06c1.94,0,3.513-1.596,3.513-3.564
        c0-1.91-1.481-3.464-3.341-3.555c0.001-0.038,0.006-0.075,0.006-0.113c0-2.975-2.377-5.386-5.308-5.386
        c-0.61,0-1.191,0.112-1.736,0.305l0.021,0.154c0,0,1.168,0.872,1.454,1.342c0,0,2.16-0.358,3.637,1.409
        c1.477,1.767,1.212,3.131,1.212,3.131s-1.3,0.022-2.182,0.76c0,0,2.138-0.358,3.24,0.716c1.102,1.073,0.595,4.472-2.931,4.674
        l0.016,0.118h1.772V114.605z"
            fill="#E6EBEF"
          />
        </G>
        <Path
          d="M179.105,100.067c0,0,2.711-0.291,4.672,2.169c0,0,1.41-2.661,3.725-2.214
      c2.314,0.447,2.579,2.415,2.579,2.415l0.113-0.118c0.018-0.145,0.033-0.291,0.033-0.44c0-2.009-1.605-3.638-3.586-3.638
      c-1.267,0-2.379,0.669-3.016,1.676c-1.029-0.935-2.383-1.508-3.873-1.508c-2.794,0-5.117,2.003-5.675,4.669
      C174.981,102.131,177.144,100.102,179.105,100.067z"
          fill="#E6EBEF"
        />
        <Path
          d="M197.147,114.915c-0.172,0-0.346-0.013-0.526-0.04c-0.031,0.019-0.065,0.029-0.101,0.029h-26.558
      c-0.031,0-0.061-0.006-0.089-0.016c-0.137,0.016-0.275,0.026-0.416,0.026c-2.075,0-3.763-1.711-3.763-3.814
      c0-1.483,0.842-2.821,2.157-3.45c0.237-2.332,2.195-4.138,4.521-4.138c0.457,0,0.908,0.07,1.348,0.21
      c0.292-3.146,2.879-5.564,6.03-5.564c1.396,0,2.742,0.49,3.825,1.387c0.725-0.979,1.85-1.554,3.064-1.554
      c2.115,0,3.836,1.744,3.836,3.888c0,0.048-0.001,0.096-0.003,0.143c0.507-0.149,1.023-0.226,1.542-0.226
      c1.851,0,3.573,0.93,4.609,2.486c0.076,0.115,0.046,0.271-0.069,0.347c-0.116,0.077-0.271,0.046-0.347-0.069
      c-0.943-1.418-2.511-2.264-4.193-2.264c-0.586,0-1.172,0.107-1.74,0.318c-0.083,0.031-0.175,0.017-0.243-0.039
      s-0.104-0.143-0.092-0.229c0.021-0.152,0.036-0.308,0.036-0.467c0-1.868-1.496-3.388-3.336-3.388c-1.138,0-2.187,0.583-2.806,1.56
      c-0.039,0.062-0.104,0.104-0.178,0.114c-0.072,0.008-0.147-0.013-0.201-0.063c-1.024-0.931-2.341-1.443-3.705-1.443
      c-2.993,0-5.433,2.381-5.553,5.421c-0.004,0.081-0.046,0.156-0.114,0.2c-0.067,0.044-0.152,0.053-0.229,0.022
      c-0.479-0.192-0.978-0.289-1.482-0.289c-2.114,0-3.887,1.679-4.036,3.822c-0.006,0.093-0.064,0.175-0.15,0.212
      c-1.21,0.521-1.992,1.72-1.992,3.054c0,1.827,1.464,3.314,3.263,3.314c0.159,0,0.315-0.016,0.469-0.038
      c0.053-0.011,0.108,0.002,0.155,0.027h26.323c0.05-0.033,0.112-0.055,0.16-0.045c0.204,0.037,0.395,0.056,0.582,0.056
      c1.799,0,3.263-1.487,3.263-3.314c0-1.768-1.363-3.22-3.104-3.306c-0.135-0.007-0.24-0.121-0.237-0.257l0.256-0.106h-0.25
      c0-0.676-0.127-1.332-0.378-1.951c-0.052-0.128,0.01-0.273,0.138-0.325c0.126-0.055,0.273,0.009,0.325,0.138
      c0.261,0.643,0.4,1.322,0.414,2.022c1.89,0.216,3.336,1.833,3.336,3.785C200.91,113.204,199.223,114.915,197.147,114.915z"
          fill="#000000"
        />
        <G>
          <Path d="M186.334,102.281c0,0,4.496-1.543,5.973,2.751" fill="#FFFFFF" />
          <Path
            d="M192.307,105.282c-0.104,0-0.201-0.065-0.236-0.169c-1.379-4.011-5.481-2.655-5.655-2.596
        c-0.126,0.047-0.272-0.024-0.317-0.155s0.024-0.272,0.155-0.317c0.047-0.017,4.749-1.574,6.29,2.906
        c0.045,0.13-0.024,0.272-0.155,0.317C192.361,105.278,192.334,105.282,192.307,105.282z"
            fill="#000000"
          />
        </G>
        <G>
          <Path d="M170.113,107.477c0,0,0.066-2.728,3.019-2.326" fill="#FFFFFF" />
          <Path
            d="M170.113,107.726c-0.002,0-0.005,0-0.007,0c-0.138-0.004-0.246-0.118-0.243-0.256c0.001-0.052,0.041-1.262,0.935-2.022
        c0.582-0.496,1.376-0.678,2.369-0.545c0.137,0.019,0.232,0.145,0.214,0.282c-0.019,0.136-0.133,0.23-0.282,0.214
        c-0.842-0.116-1.505,0.029-1.974,0.427c-0.724,0.613-0.762,1.646-0.762,1.657C170.359,107.619,170.248,107.726,170.113,107.726z"
            fill="#000000"
          />
        </G>
        <Path
          d="M198.902,111.896c-0.024,0-0.049-0.004-0.073-0.011c-0.132-0.041-0.206-0.181-0.166-0.312
      c0.064-0.211,0.098-0.431,0.098-0.652c0-1.208-0.968-2.191-2.156-2.191c-0.239,0-0.473,0.039-0.696,0.117
      c-0.13,0.043-0.272-0.023-0.318-0.154c-0.045-0.13,0.023-0.272,0.154-0.318c0.275-0.096,0.565-0.145,0.86-0.145
      c1.465,0,2.656,1.207,2.656,2.691c0,0.271-0.04,0.54-0.119,0.799C199.108,111.827,199.01,111.896,198.902,111.896z"
          fill="#000000"
        />
        <Rect fill="#E6EBEF" height="1.174" width="5.846" x="165.625" y="9.765" />
        <Path
          d="M165.302,8.624v1.087l0.242,0.048v9.084l-2.391-1.847V9.711c0,0-0.338-0.266-0.314-0.362
      c0.024-0.097,0.121-0.628,0.121-0.628l0.169-0.169L165.302,8.624z"
          fill="#DCE2E7"
        />
        <Path
          d="M171.436,19.073h-5.861c-0.054,0-0.105-0.017-0.148-0.049l-2.445-1.8c-0.063-0.047-0.102-0.122-0.102-0.201V9.806
      c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v7.091l2.277,1.676h5.529V9.767c0-0.138,0.112-0.25,0.25-0.25
      s0.25,0.112,0.25,0.25v9.056C171.686,18.961,171.574,19.073,171.436,19.073z"
          fill="#000000"
        />
        <Path
          d="M165.586,15.661c-0.097,0-0.175-0.078-0.175-0.175v-5.75c0-0.097,0.078-0.175,0.175-0.175s0.175,0.078,0.175,0.175v5.75
      C165.761,15.583,165.683,15.661,165.586,15.661z"
          fill="#000000"
        />
        <Path
          d="M165.586,17.624c-0.097,0-0.175-0.078-0.175-0.175v-1.286c0-0.097,0.078-0.175,0.175-0.175s0.175,0.078,0.175,0.175v1.286
      C165.761,17.546,165.683,17.624,165.586,17.624z"
          fill="#000000"
        />
        <Path
          d="M171.552,9.941h-8.319c-0.365,0-0.663-0.297-0.663-0.663V8.97c0-0.366,0.298-0.663,0.663-0.663h8.319
      c0.365,0,0.663,0.297,0.663,0.663v0.309C172.215,9.643,171.917,9.941,171.552,9.941z M163.232,8.806
      c-0.09,0-0.163,0.073-0.163,0.163v0.309c0,0.09,0.073,0.163,0.163,0.163h8.319c0.09,0,0.163-0.073,0.163-0.163V8.97
      c0-0.09-0.073-0.163-0.163-0.163h-8.319V8.806z"
          fill="#000000"
        />
        <Path
          d="M165.337,9.827c-0.138,0-0.25-0.112-0.25-0.25V8.625c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.953
      C165.587,9.716,165.475,9.827,165.337,9.827z"
          fill="#000000"
        />
        <Path
          d="M167.957,6.388c-0.009,0-0.017-0.001-0.026-0.003l-1.09-0.243c-0.083-0.018-0.141-0.09-0.14-0.176
      c0-0.085,0.059-0.157,0.142-0.174l1.088-0.229c0.07-0.014,0.13,0.028,0.143,0.093c0.014,0.065-0.028,0.13-0.093,0.143l-0.807,0.17
      l0.81,0.18c0.065,0.014,0.105,0.079,0.092,0.144C168.062,6.35,168.012,6.388,167.957,6.388z"
          fill="#000000"
        />
        <Path
          d="M171.781,7.66c-0.571,0-1.164-0.286-1.363-0.391c-0.442,0.298-0.937,0.402-1.402,0.292
      c-0.397-0.094-0.743-0.343-0.904-0.649c-0.332-0.636-0.321-1.299,0.033-1.82c0.389-0.576,1.279-0.483,1.317-0.478
      c0.066,0.008,0.114,0.067,0.106,0.133c-0.008,0.066-0.069,0.116-0.133,0.106c-0.007,0.001-0.782-0.081-1.09,0.374
      c-0.301,0.445-0.308,1.019-0.018,1.573c0.129,0.247,0.414,0.449,0.744,0.527c0.42,0.1,0.871-0.006,1.268-0.296
      c0.039-0.028,0.09-0.03,0.131-0.007c0.009,0.005,0.833,0.481,1.5,0.383c-0.017-0.092-0.068-0.248-0.221-0.429
      c-0.034-0.041-0.038-0.098-0.01-0.143c0.028-0.044,0.083-0.065,0.133-0.052c0.011,0.003,0.212,0.054,0.435-0.023
      c-0.285-0.112-0.757-0.321-1.126-0.611c-0.194-0.152-0.354-0.318-0.507-0.48c-0.273-0.286-0.532-0.556-0.967-0.744
      c-0.061-0.026-0.09-0.097-0.063-0.159c0.027-0.061,0.099-0.089,0.159-0.063c0.48,0.207,0.768,0.508,1.046,0.799
      c0.149,0.155,0.302,0.315,0.483,0.457c0.522,0.412,1.296,0.66,1.303,0.662c0.043,0.014,0.074,0.05,0.082,0.094
      c0.008,0.043-0.009,0.088-0.043,0.116c-0.202,0.159-0.419,0.205-0.586,0.212c0.148,0.264,0.131,0.463,0.13,0.474
      c-0.005,0.051-0.041,0.093-0.09,0.105C172.015,7.648,171.899,7.66,171.781,7.66z"
          fill="#000000"
        />
        <Path
          d="M170.092,6.83c-0.611,0-0.944-0.669-0.959-0.7c-0.029-0.06-0.004-0.132,0.055-0.161c0.061-0.028,0.132-0.004,0.161,0.056
      c0.003,0.006,0.327,0.651,0.864,0.552c0.008-0.001,0.24-0.038,0.378-0.225c0.038-0.05,0.107-0.063,0.16-0.031
      c0.02,0.012,0.49,0.296,0.762-0.035c0.043-0.052,0.118-0.059,0.17-0.017c0.051,0.042,0.059,0.118,0.017,0.17
      c-0.286,0.349-0.727,0.261-0.984,0.138c-0.197,0.2-0.453,0.237-0.465,0.239C170.197,6.825,170.144,6.83,170.092,6.83z"
          fill="#000000"
        />
        <Circle cx="168.871" cy="5.576" fill="#000000" r="0.114" />
        <Path
          d="M169.822,8.509c-0.023,0-0.045-0.006-0.066-0.019c-0.056-0.037-0.072-0.112-0.035-0.168l0.33-0.502l-0.114-0.457
      c-0.016-0.065,0.023-0.13,0.088-0.146c0.068-0.017,0.13,0.023,0.147,0.088l0.128,0.508c0.008,0.032,0.002,0.068-0.016,0.096
      l-0.359,0.546C169.9,8.489,169.861,8.509,169.822,8.509z"
          fill="#000000"
        />
        <Path
          d="M169.26,8.559c-0.023,0-0.046-0.006-0.066-0.019c-0.056-0.037-0.072-0.112-0.035-0.168l0.329-0.502l-0.097-0.372
      c-0.017-0.064,0.021-0.13,0.086-0.147c0.065-0.017,0.13,0.021,0.147,0.086l0.11,0.423c0.009,0.033,0.003,0.068-0.015,0.097
      l-0.359,0.546C169.337,8.54,169.299,8.559,169.26,8.559z"
          fill="#000000"
        />
      </G>
    </Svg>
  );
};

export default MarketingSpring;
