import React from 'react';
import T from 'prop-types';

import { ConditionalRender, Skeleton } from '../../../base_ui';
import {
  LoanCardSelectContainer,
  LoanCardWrapper,
  RadioButtonWrapper,
  StyledIconButton,
} from './styledComponents';
import LoanCard from './LoanCard';
import LoanCardSkeleton from './LoanCardSkeleton';
import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';

const CheckedIcon = iconDictionary('approved');
const EmptyIcon = iconDictionary('unapproved');

const LoanCardSelect = ({
  canEditMailingAddress = false,
  dispatchSetSelectedLoan,
  hasCoBorrower = false,
  hashedSelectedLoan,
  isActive,
  isLoading,
  isLoanMancodeY,
  isPending = false,
  loanNumber,
  mailingAddress,
  propertyAddress,
  unmaskedLoanNumber,
}) => {
  const cardPropsToPassDown = {
    isActive,
    isLoading,
    isPending,
    loanNumber,
    mailingAddress,
    propertyAddress,
    unmaskedLoanNumber,
  };
  const isSelected = loanNumber === hashedSelectedLoan;
  const iconPropsToPassDown = {
    ariaLabel: `loan ${unmaskedLoanNumber} radio button`,
    Icon: isSelected ? CheckedIcon : EmptyIcon,
    onPress: () => dispatchSetSelectedLoan(isSelected ? { loanNumber: '' } : { loanNumber }),
  };

  /**
   *   Loans with co-borrowers will return a false canEditMailingAddress from the API. These loans
   *   should still be selectable.
   */
  const isLoanSelectable = isActive && !isLoanMancodeY && (canEditMailingAddress || hasCoBorrower);

  return (
    <LoanCardSelectContainer>
      <RadioButtonWrapper>
        <ConditionalRender
          Component={StyledIconButton}
          FallbackComponent={(
            <ConditionalRender
              Component={(
                <Skeleton
                  $borderRadius={`${moderateScale(12)}px`}
                  $rightMargin={`${moderateScale(12)}px`}
                  height={`${moderateScale(24)}px`}
                  width={`${moderateScale(24)}px`}
                />
              )}
              shouldRender={isLoanSelectable}
            />
          )}
          propsToPassDown={iconPropsToPassDown}
          shouldRender={!isLoading && isLoanSelectable}
        />
      </RadioButtonWrapper>
      <LoanCardWrapper>
        <ConditionalRender
          Component={LoanCard}
          FallbackComponent={LoanCardSkeleton}
          propsToPassDown={cardPropsToPassDown}
          shouldRender={!isLoading}
        />
      </LoanCardWrapper>
    </LoanCardSelectContainer>
  );
};

LoanCardSelect.propTypes = {
  canEditMailingAddress: T.bool,
  dispatchSetSelectedLoan: T.func.isRequired,
  hasCoBorrower: T.bool,
  hashedSelectedLoan: T.string.isRequired,
  isActive: T.bool,
  isLoading: T.bool.isRequired,
  isLoanMancodeY: T.bool,
  isPending: T.bool,
  loanNumber: T.string.isRequired,
  mailingAddress: T.object,
  propertyAddress: T.object.isRequired,
  unmaskedLoanNumber: T.string.isRequired,
};

export default LoanCardSelect;
