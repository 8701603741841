import { PixelRatio, Platform } from 'react-native';
import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const SliderBarWrapper = styled.View`
  margin-top: ${Platform.OS === 'ios' ? moderateScale(16) : moderateScale(10)}px;
  paddingHorizontal: ${Platform.OS === 'web' ? moderateScale(6) : 0}px;
`;

export const SliderContainer = styled.View`
  paddingVertical: ${moderateScale(8)}px;
`;

export const SliderLabel = styled.Text`
  font-size: ${({ theme: { fontSizes: { f2 } } }) => `${f2}px`};
  min-width: ${moderateScale(25)}px;
`;

export const SliderLabelsContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${Platform.OS === 'ios' ? moderateScale(6) : 0}px;
  marginHorizontal: ${Platform.OS === 'android' ? moderateScale(10) : 0}px;
`;

export const ValueLabelBox = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-radius: 4px;
  border-width: 1px;
  height: ${moderateScale(41)}px;
  justify-content: center;
  width: ${moderateScale(81)}px;
`;

export const ValueLabelText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f8 } } }) => `${f8}px`};
  line-height: ${moderateScale(32)}px;
`;

export const ValueLabelTriangle = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-bottom-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-left-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-right-color: transparent;
  border-style: solid;
  border-top-color: transparent;
  border-width: 1px;
  bottom: 0px;
  height: ${PixelRatio.roundToNearestPixel(moderateScale(16) / 1.41)}px;
  left: 0px;
  position: absolute;
  transform: rotate(-45deg) translateX(8px) translateY(6px);
  width: ${PixelRatio.roundToNearestPixel(moderateScale(16) / 1.41)}px;
`;
