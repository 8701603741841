import styled from 'styled-components/native';

import { H2 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const AnimationWrapper = styled.View`
  align-self: center;
  justify-content: center;
  width: ${moderateScale(85)}px;
`;

export const LoadingDogHouse = styled.View`
  margin-top: ${moderateScale(200)}px;
`;

export const NachaContainer = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-grow: 1;
  min-height: 100%;
`;

export const NachaHeader = styled(H2)`
  margin: ${moderateScale(24)}px 0 ${moderateScale(8)}px;
  text-align: center;
  width: ${moderateScale(287)}px;
`;
