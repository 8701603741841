import iconDictionary from '../../../../utils/iconDictionary';

export const ILLUSTRATION_DICT = {
  autopayMoney: iconDictionary('autopayMoney'),
  calculatorMT: iconDictionary('calculatorMT'),
  documentsStatements: iconDictionary('documentsStatements'),
  helpLearningCenter: iconDictionary('helpLearningCenter'),
  paperlessBillingRecycle: iconDictionary('paperlessBillingRecycle'),
  settingsThumbsUp: iconDictionary('settingsThumbsUp'),
};
