import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { H5, P4 } from '../base_ui';
import { TertiaryHeader } from '../ScreenHeaders';
import { moderateScale } from '../../utils/scaleHelpers';

export const BoldText = styled(P4)`
  font-weight: 700;
`;

export const Delimiter = styled(P4)`
  padding: 0 ${moderateScale(5)}px;
`;

export const ListItem = styled.View`
  display: flex;
  flex-direction: row;
`;

export const StyledH5 = styled(H5)`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  padding: ${moderateScale(5)}px 0;
`;

export const StyledLink = styled(P4)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  ${Platform.select({
    android: css`
      top: ${moderateScale(4)}px;
    `,
    ios: css`
      top: ${moderateScale(8)}px;
    `,
  })}
`;

export const StyledList = styled.View`
  padding: 0 0 ${moderateScale(5)}px;
`;

export const StyledP4 = styled(P4)`
  padding: ${moderateScale(5)}px 0;
`;

export const StyledSubHeader = styled(H5)`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  padding: ${moderateScale(5)}px 0;
  text-align: center;
  text-decoration: underline;
`;

export const StyledTertiaryHeader = styled(TertiaryHeader)`
  min-height: ${moderateScale(51)}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const TextWrapper = styled(P4)`
  width: 100%;
`;
