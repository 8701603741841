/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavLogoutDefaultLIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M19.4301 22.6999H5.00012C4.83303 22.6999 4.67279 22.6336 4.55464 22.5154C4.43649 22.3973 4.37012 22.237 4.37012 22.0699V1.99993C4.38272 1.84165 4.45462 1.69397 4.57143 1.58643C4.68824 1.47889 4.84134 1.41942 5.00012 1.41993H19.4301C19.5955 1.4199 19.7542 1.4849 19.8721 1.6009C19.99 1.71689 20.0575 1.87458 20.0601 2.03993V7.10993C20.0714 7.19864 20.0636 7.28873 20.0373 7.3742C20.0111 7.45968 19.9669 7.53858 19.9078 7.60567C19.8487 7.67276 19.7759 7.72649 19.6944 7.7633C19.6129 7.8001 19.5245 7.81914 19.4351 7.81914C19.3457 7.81914 19.2573 7.8001 19.1758 7.7633C19.0943 7.72649 19.0216 7.67276 18.9625 7.60567C18.9033 7.53858 18.8592 7.45968 18.8329 7.3742C18.8066 7.28873 18.7989 7.19864 18.8101 7.10993V2.66993H5.59012V21.4499H18.8101V16.7599C18.8294 16.6078 18.9035 16.4679 19.0186 16.3665C19.1337 16.2651 19.2817 16.2091 19.4351 16.2091C19.5885 16.2091 19.7366 16.2651 19.8516 16.3665C19.9667 16.4679 20.0408 16.6078 20.0601 16.7599V22.0699C20.0601 22.237 19.9937 22.3973 19.8756 22.5154C19.7574 22.6336 19.5972 22.6999 19.4301 22.6999Z" fill="black" />
      <Path d="M14.8696 17.06C14.7478 17.058 14.6291 17.0207 14.528 16.9526C14.4269 16.8845 14.3477 16.7885 14.3001 16.6763C14.2525 16.5641 14.2384 16.4405 14.2596 16.3205C14.2808 16.2004 14.3364 16.0891 14.4196 16L18.4196 12L14.4196 8.00004C14.3244 7.87838 14.2772 7.72596 14.287 7.57176C14.2968 7.41756 14.3629 7.27234 14.4728 7.1637C14.5826 7.05507 14.7286 6.9906 14.8829 6.98255C15.0372 6.9745 15.1891 7.02343 15.3096 7.12004L19.7696 11.57C19.8851 11.6894 19.9497 11.849 19.9497 12.015C19.9497 12.1811 19.8851 12.3407 19.7696 12.46L15.3096 16.92C15.1855 17.0198 15.0286 17.0698 14.8696 17.06Z" fill="black" />
      <Path d="M19.3301 12.6101H8.12012C7.96797 12.5908 7.82808 12.5167 7.72668 12.4016C7.62528 12.2866 7.56934 12.1385 7.56934 11.9851C7.56934 11.8317 7.62528 11.6836 7.72668 11.5686C7.82808 11.4535 7.96797 11.3794 8.12012 11.3601H19.3301C19.4823 11.3794 19.6222 11.4535 19.7236 11.5686C19.825 11.6836 19.8809 11.8317 19.8809 11.9851C19.8809 12.1385 19.825 12.2866 19.7236 12.4016C19.6222 12.5167 19.4823 12.5908 19.3301 12.6101Z" fill="black" />
    </Svg>
  );
}
