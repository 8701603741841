import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changePaperlessForm,
  changeStep,
  closeModalState,
  openModalState,
  putPaperlessSetting,
  resetFormState,
  resetStatusState,
  setSelectedLoan,
} from '@dmi/shared/redux/Settings/Communications/actions';
import makeSelectCommunications, {
  selectError,
  selectFetchErrorCopy,
  selectFormPaperlessSettings,
  selectPaperlessFormData,
  selectRequestStatus,
  selectSelectedLoan,
} from '@dmi/shared/redux/Settings/Communications/selectors';
import makeSelectDisclosures, { makeSelectDisclosuresContent }
from '@dmi/shared/redux/Disclosures/selectors';

import { ConditionalRender, StyledScrollView } from '../../../../components/base_ui';
import PaperlessModal from '../../../../components/Settings/Communications/Modal';
import VIEW_DICTIONARY from './viewDictionary';

const Paperless = ({
  disclosureRequestStatus,
  disclosures,
  dispatchChangePaperlessForm,
  dispatchChangeStep,
  dispatchCloseModal,
  dispatchOpenModal,
  dispatchPutPaperlessSetting,
  dispatchResetFormState,
  dispatchResetStatusState,
  dispatchSetSelectedLoan,
  error,
  fetchErrorCopy,
  fetchPaperlessStatus,
  formData,
  isModalOpen,
  modalState,
  navigation,
  paperlessStatus,
  putPaperlessStatus,
  selectedLoan,
  step,
}) => {
  const scrollRef = useRef(null);

  useEffect(() => () => {
    if (fetchPaperlessStatus === 'failed') {
      dispatchResetStatusState();
    }
  }, [dispatchResetStatusState, fetchPaperlessStatus]);

  useEffect(() => {
    if (step === 2 && scrollRef.current) {
      scrollRef.current.scrollTo({ animated: false, y: 0 });
    }
  }, [step]);

  useEffect(() => () => {
    dispatchResetFormState();
  }, [dispatchResetFormState]);

  const ViewToRender = VIEW_DICTIONARY[step];
  const isEnrollPaperlessFlow = paperlessStatus === 'Enabled';
  const isPutPaperlessLoading = putPaperlessStatus === 'loading';

  const propsToPassDown = {
    disclosureRequestStatus,
    disclosures,
    dispatchChangePaperlessForm,
    dispatchChangeStep,
    dispatchOpenModal,
    dispatchPutPaperlessSetting,
    dispatchResetStatusState,
    dispatchSetSelectedLoan,
    error,
    fetchErrorCopy,
    fetchPaperlessLoading: ['idle', 'loading'].includes(fetchPaperlessStatus),
    flowId: isEnrollPaperlessFlow ? 'enrollPaperless' : 'unenrollPaperless',
    formData,
    isEnrollPaperlessFlow,
    isPutPaperlessLoading,
    navigation,
    selectedLoan,
  };

  return (
    <StyledScrollView ref={scrollRef}>
      <ViewToRender {...propsToPassDown} />
      <ConditionalRender
        Component={PaperlessModal}
        propsToPassDown={{
          handleCloseModal: dispatchCloseModal,
          handleOnPress: dispatchPutPaperlessSetting,
          isModalOpen,
          loading: isPutPaperlessLoading,
        }}
        shouldRender={modalState === 'unenrollPaperless'}
      />
    </StyledScrollView>
  );
};

Paperless.propTypes = {
  disclosureRequestStatus: T.string.isRequired,
  disclosures: T.objectOf(
    T.shape({ content: T.array.isRequired }),
  ).isRequired,
  dispatchChangePaperlessForm: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchCloseModal: T.func.isRequired,
  dispatchOpenModal: T.func.isRequired,
  dispatchPutPaperlessSetting: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetStatusState: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  fetchErrorCopy: T.shape({ body: T.string.isRequired, title: T.string.isRequired }).isRequired,
  fetchPaperlessStatus: T.string.isRequired,
  formData: T.arrayOf(T.shape({
    addressData: T.arrayOf(T.string).isRequired,
    hashedLoanNumber: T.string.isRequired,
    isButtonDisabled: T.bool.isRequired,
    isToggleDisabled: T.bool.isRequired,
    isToggleOn: T.bool.isRequired,
    loanNumber: T.string.isRequired,
    toggleText: T.string.isRequired,
  })).isRequired,
  isModalOpen: T.bool.isRequired,
  modalState: T.string.isRequired,
  navigation: T.object.isRequired,
  paperlessStatus: T.string.isRequired,
  putPaperlessStatus: T.string.isRequired,
  selectedLoan: T.string.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Communications
   */
  error: selectError('paperless'),
  fetchErrorCopy: selectFetchErrorCopy(),
  fetchPaperlessStatus: selectRequestStatus('fetchPaperlessSettingAll'),
  formData: selectPaperlessFormData(),
  isModalOpen: makeSelectCommunications('isModalOpen'),
  modalState: makeSelectCommunications('modalState'),
  paperlessStatus: selectFormPaperlessSettings(),
  putPaperlessStatus: selectRequestStatus('putPaperlessSetting'),
  selectedLoan: selectSelectedLoan(),
  step: makeSelectCommunications('step'),
  /**
   * Store: Disclosures
   */
  // eslint-disable-next-line sort-keys
  disclosureRequestStatus: makeSelectDisclosures('requestStatus'),
  disclosures: makeSelectDisclosuresContent(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Communications
   */
  dispatchChangePaperlessForm: (payload) => dispatch(changePaperlessForm(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchCloseModal: () => dispatch(closeModalState()),
  dispatchOpenModal: (payload) => dispatch(openModalState(payload)),
  dispatchPutPaperlessSetting: () => dispatch(putPaperlessSetting()),
  dispatchResetFormState: () => dispatch(resetFormState()),
  dispatchResetStatusState: () => dispatch(resetStatusState()),
  dispatchSetSelectedLoan: (payload) => dispatch(setSelectedLoan(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Paperless);
