/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function PaymentHistoryDefaultLIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M12,22.6C6.2,22.6,1.5,17.8,1.5,12c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6c0,5.1,4.2,9.3,9.3,9.3c5.1,0,9.3-4.2,9.3-9.3c0-5.1-4.2-9.3-9.3-9.3c-3.6,0-7,2.1-8.5,5.4C3.4,8.4,3,8.5,2.7,8.4C2.4,8.3,2.3,7.9,2.4,7.6C4.1,3.8,7.9,1.4,12,1.4c5.8,0,10.6,4.7,10.6,10.6S17.9,22.6,12,22.6z" fill="black" />
        <G clipPath={clipPath}>
          <Path d="M12.1,17.5v-1c-1.3-0.1-2.4-0.6-3.4-1.5l1-1.2c0.8,0.7,1.6,1.1,2.4,1.2v-2.7c-1-0.2-1.8-0.6-2.3-1c-0.5-0.4-0.7-1-0.7-1.8c0-0.8,0.3-1.4,0.8-1.9s1.3-0.8,2.2-0.8V6.1h0.9v0.7c1,0.1,2,0.4,2.9,1l-0.9,1.3c-0.6-0.4-1.3-0.7-2-0.8v2.6h0c1.1,0.2,1.8,0.6,2.3,1s0.7,1,0.7,1.8s-0.3,1.5-0.9,2c-0.6,0.5-1.3,0.8-2.3,0.8v1H12.1z M11.1,8.6c-0.2,0.2-0.4,0.5-0.4,0.8s0.1,0.5,0.3,0.7s0.5,0.4,1,0.5V8.3C11.6,8.3,11.3,8.4,11.1,8.6z M14,14.6c0.3-0.2,0.4-0.5,0.4-0.8s-0.1-0.6-0.3-0.8s-0.6-0.4-1.1-0.5V15C13.4,15,13.7,14.8,14,14.6z" fill="black" />
        </G>
        <Path d="M6.8,9.3H2.2c-0.3,0-0.6-0.3-0.6-0.6V3.4c0-0.3,0.3-0.6,0.6-0.6S2.8,3,2.8,3.4v4.7h4c0.3,0,0.6,0.3,0.6,0.6S7.2,9.3,6.8,9.3z" fill="black" />
      </G>
    </Svg>
  );
}
