import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../../../utils/iconDictionary';
import { LargePrimaryButton } from '../../../../../base_ui';
import UniqueContent from '../UniqueContent';
import { ButtonContainer, Container, IllustrationContainer } from './styledComponents';

const Illustration = iconDictionary('paperlessSm');

const SingleLoan = ({
  dispatchChangePaperlessForm,
  dispatchChangeStep,
  dispatchSetSelectedLoan,
  formData,
  setIsTooltipOpen,
}) => {
  const {
    addressData,
    hashedLoanNumber,
    isButtonDisabled,
    isToggleDisabled,
    isToggleOn,
    loanNumber,
    toggleText,
  } = formData[0];

  const handleButtonPress = () => {
    dispatchSetSelectedLoan(hashedLoanNumber);
    dispatchChangeStep(2);
  };

  const handleTogglePress = () => {
    dispatchChangePaperlessForm(hashedLoanNumber);
  };

  return (
    <Container>
      <UniqueContent
        addressData={addressData}
        handleTogglePress={handleTogglePress}
        isToggleDisabled={isToggleDisabled}
        isToggleOn={isToggleOn}
        loanNumber={loanNumber}
        setIsTooltipOpen={setIsTooltipOpen}
        toggleText={toggleText}
      />
      <IllustrationContainer>
        {Illustration}
      </IllustrationContainer>
      <ButtonContainer>
        <LargePrimaryButton
          disabled={isButtonDisabled}
          label="Update"
          onPress={handleButtonPress}
        />
      </ButtonContainer>
    </Container>
  );
};

SingleLoan.propTypes = {
  dispatchChangePaperlessForm: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  formData: T.arrayOf(T.shape({
    addressData: T.arrayOf(T.string).isRequired,
    hashedLoanNumber: T.string.isRequired,
    isButtonDisabled: T.bool.isRequired,
    isToggleDisabled: T.bool.isRequired,
    isToggleOn: T.bool.isRequired,
    loanNumber: T.string.isRequired,
    toggleText: T.string.isRequired,
  })).isRequired,
  setIsTooltipOpen: T.func.isRequired,
};

export default SingleLoan;
