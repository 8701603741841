import styled from 'styled-components/native';

import { BaseInternalLink, P2, PrimaryDivider } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const IllustrationWrapper = styled.View`
  height: ${moderateScale(107)}px;
  width:${moderateScale(269)}px;
  margin: ${moderateScale(51)}px 0 ${moderateScale(29)}px;
`;

export const Container = styled.View`
  align-items: center;
  align-self: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
  padding:  ${moderateScale(20)}px ${moderateScale(24)}px 0;
  text-align: center;
  width: 100%;
`;

export const StyledLink = styled(BaseInternalLink)`
  height: ${moderateScale(38)}px;
  max-width: ${moderateScale(375)}px;
`;

export const StyledP2 = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  margin-top: ${moderateScale(8)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin-bottom: ${moderateScale(8)}px;
  max-width: ${moderateScale(375)}px;
  width: 100%;
`;

export const TextWrapper = styled.View`
  margin: 0 0 ${moderateScale(32)}px;
  max-width: ${moderateScale(375)}px;
`;
