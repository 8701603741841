/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function PaymentHouseWinter(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg enableBackground="new 0 0 100.25 70.25" height={moderateScale(70)} id="Layer_1" version="1.1" viewBox="0 0 100.25 70.25" width={moderateScale(100)} x="0px" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" y="0px" {...props}>
      <G>
        <Path d="M11.007,63.503c0,0-9.061-3.02-9.165-15.726c-0.104-12.706,7.29-17.185,11.665-20.621 s9.061-6.666,15.935-13.331s16.664-9.165,25.516-7.707s14.164,7.707,21.871,9.998s21.038,11.248,21.246,27.599 s-9.373,19.163-12.602,19.684C82.245,63.92,11.007,63.503,11.007,63.503z" fill="#E6EBEF" />
        <Polygon fill="#BAC2CA" points="69.454,42.895 72.969,42.895 69.316,38.638" />
        <Polyline fill="#FFFFFF" points="25.868,63.487 25.868,38.452 51.854,38.452 51.854,63.487" />
        <Rect fill="#BAC2CA" height="9.699" width="5.064" x="30.222" y="48.071" />
        <Polygon fill="#BAC2CA" points="69.316,63.41 52.015,63.41 52.015,29.3 60.92,29.3 69.316,38.638" />
        <Rect fill={svgHighlight} height="15.343" width="6.21" x="39.892" y="47.855" />
        <Polygon fill="#FFFFFF" points="48.239,42.785 21.729,42.785 34.373,29.102 60.883,29.102" />
        <Path d="M48.239,43.16h-26.51c-0.149,0-0.284-0.088-0.344-0.225c-0.06-0.137-0.033-0.296,0.068-0.405l12.644-13.683 c0.071-0.076,0.171-0.12,0.275-0.12h8.315c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-8.151L22.586,42.41h25.489 l11.951-12.933H45.438c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h15.446c0.149,0,0.284,0.088,0.344,0.225 s0.033,0.296-0.068,0.405L48.515,43.04C48.444,43.116,48.344,43.16,48.239,43.16z" fill="#000000" />
        <Path d="M30.053,42.408v3.03c0,0.4-0.302,0.725-0.674,0.725l0,0c-0.373,0-0.674-0.325-0.674-0.725V44.63 c0-0.369-0.278-0.667-0.621-0.667h0c-0.343,0-0.621,0.299-0.621,0.667v3.548c0,0.417-0.315,0.756-0.703,0.756h0 c-0.388,0-0.703-0.338-0.703-0.756V42.27" fill="#FFFFFF" />
        <Path d="M72.878,43.16h-2.416c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.589L60.614,29.35 c-0.137-0.156-0.121-0.393,0.035-0.529s0.393-0.121,0.529,0.035l11.982,13.683c0.097,0.11,0.12,0.268,0.06,0.402 C73.159,43.074,73.026,43.16,72.878,43.16z" fill="#000000" />
        <Path d="M69.311,63.786H25.773c-0.207,0-0.375-0.168-0.375-0.375V43.243c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375 v19.793h42.788v-24.22c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v24.595C69.686,63.618,69.518,63.786,69.311,63.786 z" fill="#000000" />
        <Path d="M18.141,63.764c0,0,3.072-0.729,4.478-1.198c1.406-0.469,3.645-0.469,4.895-0.052 c1.25,0.417,3.098,0.989,4.661,1.068v0.703H18.219L18.141,63.764z" fill="#FFFFFF" />
        <Path d="M51.985,63.374c-0.138,0-0.25-0.112-0.25-0.25v-6.457c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v6.457 C52.235,63.262,52.124,63.374,51.985,63.374z" fill="#000000" />
        <Path d="M51.985,54.351c-0.138,0-0.25-0.112-0.25-0.25V41.428c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v12.673 C52.235,54.238,52.124,54.351,51.985,54.351z" fill="#000000" />
        <Path d="M46.125,63.514c-0.18,0-0.325-0.146-0.325-0.325V48.233h-5.592v13.299c0,0.18-0.146,0.325-0.325,0.325 s-0.325-0.146-0.325-0.325V47.908c0-0.18,0.146-0.325,0.325-0.325h6.243c0.18,0,0.325,0.146,0.325,0.325v15.28 C46.45,63.368,46.305,63.514,46.125,63.514z" fill="#000000" />
        <Path d="M35.58,57.843h-5.523c-0.18,0-0.325-0.146-0.325-0.325v-9.545c0-0.18,0.146-0.325,0.325-0.325h5.523 c0.18,0,0.325,0.146,0.325,0.325v9.545C35.905,57.697,35.76,57.843,35.58,57.843z M30.382,57.192h4.873v-8.895h-4.873V57.192z" fill="#000000" />
        <G>
          <Ellipse cx="61.203" cy="39.621" fill="#E6EBEF" rx="1.574" ry="2.273" />
          <Path d="M61.203,42.22c-1.065,0-1.899-1.142-1.899-2.599c0-1.433,0.853-2.599,1.899-2.599s1.899,1.166,1.899,2.599 C63.102,41.078,62.268,42.22,61.203,42.22z M61.203,37.673c-0.677,0-1.249,0.892-1.249,1.948s0.572,1.948,1.249,1.948 s1.249-0.892,1.249-1.948S61.879,37.673,61.203,37.673z" fill="#000000" />
        </G>
        <Path d="M61.203,42.144c-1.006,0-1.824-1.132-1.824-2.523s0.818-2.523,1.824-2.523s1.824,1.132,1.824,2.523 S62.209,42.144,61.203,42.144z M61.203,37.598c-0.718,0-1.324,0.927-1.324,2.023c0,1.116,0.594,2.023,1.324,2.023 s1.324-0.907,1.324-2.023C62.527,38.524,61.92,37.598,61.203,37.598z" fill="#000000" />
        <Path d="M95.433,63.818h-4.386c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.386c0.207,0,0.375,0.168,0.375,0.375 S95.64,63.818,95.433,63.818z" fill="#000000" />
        <Path d="M88.038,63.818h-56.49c-0.637,0-1.272-0.089-1.891-0.265l-2.941-0.839c-1.118-0.318-2.3-0.313-3.417,0.014l-2.757,0.81 c-0.634,0.187-1.288,0.28-1.943,0.28H8.702c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h9.897 c0.584,0,1.167-0.084,1.732-0.25l2.756-0.809c1.253-0.369,2.58-0.375,3.834-0.016l2.941,0.839c0.551,0.156,1.118,0.235,1.685,0.235 h56.49c0.207,0,0.375,0.168,0.375,0.375S88.245,63.818,88.038,63.818z" fill="#000000" />
        <Path d="M6.193,63.943H4.641c-0.276,0-0.5-0.224-0.5-0.5s0.224-0.5,0.5-0.5h1.553c0.276,0,0.5,0.224,0.5,0.5 S6.47,63.943,6.193,63.943z" fill="#000000" />
        <Path d="M84.579,63.93c-0.207,0-0.375-0.168-0.375-0.375V49.017c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v14.538 C84.954,63.762,84.786,63.93,84.579,63.93z" fill="#000000" />
        <Polygon fill="#BAC2CA" points="94.4,58.482 91.717,50.037 93.453,50.037 89.744,40.172 91.559,40.172 84.772,23.283  84.375,23.283 77.587,40.172 79.403,40.172 75.693,50.037 77.43,50.037 74.746,58.482" />
        <Path d="M82.402,27.922c0,0,1.516,3.511,6.078,4.794l-3.708-9.434c0,0-0.422-0.031-0.468,0.093 C84.257,23.499,82.402,27.922,82.402,27.922z" fill="#FFFFFF" />
        <Path d="M76.014,49.326c0,0,1.206-1.361,3.341-1.299c2.134,0.062,2.165,1.392,4.361,0.68s3.495-0.247,4.176,0.155 s1.887,0.99,1.887,0.99H75.798L76.014,49.326z" fill="#FFFFFF" />
        <Path d="M93.966,57.667c0,0-1.657-1.87-4.59-1.785c-2.932,0.085-2.975,1.912-5.992,0.935 c-3.017-0.977-4.802-0.34-5.737,0.212c-0.935,0.552-2.592,1.36-2.592,1.36h19.208L93.966,57.667z" fill="#FFFFFF" />
        <Path d="M91.418,40.14h-9.279c0,0,2.01-0.217,3.279-0.65c1.268-0.433,1.949-0.959,3.341-0.742 c1.392,0.217,2.474,0.835,2.474,0.835L91.418,40.14z" fill="#FFFFFF" />
        <Path d="M94.4,58.856H74.747c-0.119,0-0.232-0.057-0.303-0.153s-0.091-0.222-0.055-0.335l2.527-7.956h-1.224 c-0.123,0-0.238-0.061-0.309-0.161c-0.069-0.102-0.086-0.23-0.042-0.346l3.519-9.358h-1.273c-0.125,0-0.241-0.062-0.311-0.165 c-0.07-0.104-0.084-0.234-0.037-0.35l6.787-16.889c0.057-0.142,0.194-0.235,0.348-0.235h0.397c0.153,0,0.291,0.093,0.348,0.235 l6.787,16.889c0.047,0.115,0.033,0.246-0.037,0.35c-0.069,0.104-0.186,0.165-0.311,0.165h-1.273l3.519,9.358 c0.044,0.115,0.027,0.244-0.042,0.346c-0.07,0.101-0.186,0.161-0.309,0.161H92.23l2.527,7.956c0.036,0.113,0.016,0.238-0.055,0.335 S94.519,58.856,94.4,58.856z M75.259,58.106h18.628L91.36,50.15c-0.036-0.113-0.016-0.238,0.055-0.335s0.184-0.153,0.303-0.153 h1.194l-3.519-9.358c-0.044-0.115-0.027-0.244,0.042-0.346c0.07-0.101,0.186-0.161,0.309-0.161h1.261l-6.432-16.003l-6.431,16.003 h1.261c0.123,0,0.238,0.061,0.309,0.161c0.069,0.102,0.086,0.23,0.042,0.346l-3.519,9.358h1.194c0.119,0,0.232,0.057,0.303,0.153 s0.091,0.222,0.055,0.335L75.259,58.106z" fill="#000000" />
        <Path d="M89.801,40.37h-4.407c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h4.407c0.138,0,0.25,0.112,0.25,0.25 S89.939,40.37,89.801,40.37z" fill="#000000" />
        <Path d="M81.78,50.233h-4.407c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h4.407c0.138,0,0.25,0.112,0.25,0.25 S81.918,50.233,81.78,50.233z" fill="#000000" />
        <Path d="M85.232,50.233h-2.655c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h2.655c0.138,0,0.25,0.112,0.25,0.25 S85.37,50.233,85.232,50.233z" fill="#000000" />
        <Path d="M85.127,34.054L85.127,34.054c-0.461,0-0.834-0.373-0.834-0.834v-3.479h1.668v3.479 C85.961,33.681,85.587,34.054,85.127,34.054z" fill="#FFFFFF" />
        <Path d="M13.593,58.446L13.593,58.446c-2.875,0-5.206-2.331-5.206-5.206V38.716c0-2.875,2.331-5.206,5.206-5.206h0 c2.875,0,5.206,2.331,5.206,5.206V53.24C18.799,56.115,16.468,58.446,13.593,58.446z" fill="#E6EBEF" />
        <Path d="M16.022,34.113c0.62,0.857,0.989,1.906,0.989,3.045v14.524c0,2.875-2.331,5.206-5.206,5.206l0,0 c-0.878,0-1.704-0.219-2.43-0.603c0.946,1.307,2.48,2.162,4.217,2.162c2.875,0,5.206-2.331,5.206-5.206V38.716 C18.799,36.719,17.673,34.986,16.022,34.113z" fill="#BAC2CA" />
        <Path d="M13.593,58.821c-3.078,0-5.582-2.504-5.582-5.581V38.716c0-3.077,2.504-5.581,5.582-5.581s5.581,2.504,5.581,5.581V53.24 C19.174,56.317,16.67,58.821,13.593,58.821z M13.593,33.885c-2.664,0-4.832,2.167-4.832,4.831V53.24 c0,2.664,2.167,4.831,4.832,4.831s4.831-2.167,4.831-4.831V38.716C18.424,36.052,16.257,33.885,13.593,33.885z" fill="#000000" />
        <Path d="M13.517,63.499c-0.207,0-0.375-0.168-0.375-0.375V46.415c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v16.709 C13.892,63.331,13.724,63.499,13.517,63.499z" fill="#000000" />
        <Path d="M13.453,53.333c-0.075,0-0.151-0.022-0.217-0.069l-2.572-1.832c-0.168-0.12-0.208-0.354-0.088-0.523 s0.354-0.208,0.523-0.088l2.572,1.832c0.168,0.12,0.208,0.354,0.088,0.523C13.686,53.278,13.57,53.333,13.453,53.333z" fill="#000000" />
        <Path d="M46.001,64.102c0,0,7.18-2.748,12.177-3.267s9.735-0.844,16.42,0.844 c6.685,1.687,11.162,2.221,11.162,2.221" fill="#FFFFFF" />
        <Path d="M47.652,63.591c0,0,7.323,0.235,10.886-0.577c3.563-0.812,8.327-2.67,20.684-0.145 c0,0-10.779-3.157-16.642-2.435C56.718,61.156,51.17,61.787,47.652,63.591z" fill="#DCE2E7" />
        <Path d="M47.292,63.876c-0.153,0-0.297-0.095-0.353-0.247c-0.071-0.194,0.03-0.409,0.224-0.48c0.06-0.021,5.991-2.168,10.976-2.687 c5.106-0.529,9.875-0.832,16.552,0.854c6.549,1.653,9.493,1.74,9.522,1.741c0.207,0.005,0.371,0.177,0.366,0.384 s-0.162,0.367-0.384,0.366c-0.121-0.003-3.039-0.086-9.688-1.765c-6.561-1.654-11.26-1.356-16.29-0.834 c-4.893,0.509-10.739,2.624-10.797,2.646C47.378,63.869,47.334,63.876,47.292,63.876z" fill="#000000" />
        <Path d="M43.151,46.499c-0.651,0-1.182-0.53-1.182-1.183v-1.359c0-0.208-0.169-0.378-0.377-0.378s-0.377,0.17-0.377,0.378v0.342 c0,0.635-0.516,1.15-1.15,1.15s-1.15-0.516-1.15-1.15v-1.562c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.562 c0,0.221,0.18,0.4,0.4,0.4s0.4-0.18,0.4-0.4v-0.342c0-0.622,0.506-1.128,1.127-1.128s1.127,0.506,1.127,1.128v1.359 c0,0.238,0.194,0.433,0.432,0.433c0.238,0,0.432-0.194,0.432-0.433v-0.923c0-0.624,0.507-1.131,1.13-1.131 c0.623,0,1.13,0.507,1.13,1.131c0,0.259,0.183,0.442,0.409,0.442s0.409-0.184,0.409-0.408v-1.553c0-0.207,0.168-0.375,0.375-0.375 s0.375,0.168,0.375,0.375v1.553c0,0.639-0.52,1.158-1.159,1.158c-0.639,0-1.159-0.52-1.159-1.158c0-0.244-0.17-0.415-0.38-0.415 s-0.38,0.171-0.38,0.381v0.923C44.334,45.969,43.803,46.499,43.151,46.499z" fill="#000000" />
        <Path d="M47.037,41.839v2.588c0,0.433-0.351,0.784-0.784,0.784l0,0c-0.433,0-0.784-0.351-0.784-0.784v-0.034 c0-0.417-0.338-0.755-0.755-0.755h0c-0.417,0-0.755,0.338-0.755,0.755v0.924c0,0.446-0.361,0.807-0.807,0.807l0,0 c-0.446,0-0.807-0.361-0.807-0.807v-1.36c0-0.416-0.337-0.753-0.753-0.753l0,0c-0.416,0-0.753,0.337-0.753,0.753v0.342 c0,0.428-0.347,0.775-0.775,0.775h0c-0.428,0-0.775-0.347-0.775-0.775v-2.387" fill="#FFFFFF" />
        <Path d="M47.511,43.166h-1.406c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.406c0.207,0,0.375,0.168,0.375,0.375 S47.718,43.166,47.511,43.166z" fill="#000000" />
        <Path d="M26.526,49.616c-0.624,0-1.131-0.507-1.131-1.131v-0.809c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v0.809 c0,0.21,0.171,0.381,0.381,0.381s0.381-0.171,0.381-0.381v-3.548c0-0.575,0.468-1.043,1.042-1.043s1.042,0.468,1.042,1.043v0.809 c0,0.192,0.157,0.35,0.35,0.35s0.35-0.157,0.35-0.35v-3.03c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v3.03 c0,0.606-0.493,1.1-1.1,1.1s-1.1-0.493-1.1-1.1v-0.809c0-0.161-0.131-0.293-0.292-0.293s-0.292,0.132-0.292,0.293v3.548 C27.657,49.109,27.149,49.616,26.526,49.616z" fill="#000000" />
        <Circle cx="53.691" cy="12.405" fill="#FFFFFF" r="1.675" />
        <Circle cx="18.306" cy="29.277" fill="#FFFFFF" r="1.675" />
        <Circle cx="36.54" cy="14.938" fill="#FFFFFF" r="0.957" />
        <Circle cx="72.706" cy="32.904" fill="#FFFFFF" r="0.957" />
        <Circle cx="25.058" cy="32.982" fill="#FFFFFF" r="0.957" />
        <Circle cx="72.471" cy="48.839" fill="#FFFFFF" r="0.957" />
        <Circle cx="5.374" cy="45.558" fill="#FFFFFF" r="0.957" />
        <Circle cx="22.177" cy="48.167" fill="#FFFFFF" r="0.957" />
        <Circle cx="44.711" cy="13.985" fill="#FFFFFF" r="0.704" />
        <Circle cx="93.337" cy="35.279" fill="#FFFFFF" r="0.973" />
        <Circle cx="48.107" cy="23.735" fill="#FFFFFF" r="1.218" />
        <Circle cx="67.95" cy="24.187" fill="#FFFFFF" r="1.218" />
        <Circle cx="56.168" cy="45.451" fill="#FFFFFF" r="0.94" />
        <Circle cx="85.772" cy="54.043" fill="#FFFFFF" r="0.94" />
        <Circle cx="63.335" cy="51.292" fill="#FFFFFF" r="1.48" />
        <Circle cx="57.562" cy="55.744" fill="#FFFFFF" r="0.691" />
        <Circle cx="82.87" cy="44.34" fill="#FFFFFF" r="0.691" />
      </G>
    </Svg>
  );
}
