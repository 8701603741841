/**
* FormattedMessageStatusBanner
* @description:  Create an formatted banner message.
* Use Case: Display an error or success status message to user.
* Component Props: {
*  defaultMessage (string),
*  error (bool),
*  values (object),
*  WrapperComponent (object),
* }
*/

import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import FormattedMessage from '../FormattedMessage';
import {
  BannerText,
  IconWrapper,
  StatusBannerContainer,
} from './styledComponents';

const SuccessIcon = iconDictionary('outlinedCompleteGreen');
const WarningIcon = iconDictionary('paymentWarning');

const FormattedMessageStatusBanner = ({
  defaultMessage,
  error,
  values,
  WrapperComponent,
  ...restProps
}) => {
  const IconToRender = error ? WarningIcon : SuccessIcon;

  return (
    <StatusBannerContainer error={error} {...restProps}>
      <IconWrapper>{IconToRender}</IconWrapper>
      <BannerText>
        <FormattedMessage
          defaultMessage={defaultMessage}
          values={values}
          WrapperComponent={WrapperComponent}
        />
      </BannerText>
    </StatusBannerContainer>
  );
};

FormattedMessageStatusBanner.propTypes = {
  defaultMessage: T.string.isRequired,
  error: T.bool,
  values: T.object.isRequired,
  WrapperComponent: T.object.isRequired,
};

export default FormattedMessageStatusBanner;
