import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import ssoGenericLogoutScreens from './ssoGenericLogoutScreens';

const Stack = createStackNavigator();

const SsoStack = () => (
  <Stack.Navigator initialRouteName="LogoutSso" screenOptions={{ headerShown: false }}>
    {Object.entries(ssoGenericLogoutScreens).map(([name, otherProps]) => (
      <Stack.Screen key={name} name={name} {...otherProps} />
    ))}
  </Stack.Navigator>
);

export default SsoStack;
