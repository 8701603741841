import styled from 'styled-components/native';

import {
  BaseInternalLink,
  BorderlessButton,
  H4,
  SecondaryCard,
} from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const IllustrationWrapper = styled.View`
  margin: 0 auto;
`;

export const StyledCard = styled(SecondaryCard)`
  margin-bottom: ${moderateScale(24)}px;
  padding: ${moderateScale(20)}px;
`;

export const StyledH4 = styled(H4)`
  margin-top: ${moderateScale(16)}px;
  text-align: center;
`;

export const StyledLink = styled(BaseInternalLink)`
  margin: 0 auto;
`;

export const StyledButton = styled(BorderlessButton).attrs(({ titleStyle }) => ({
  titleStyle: [
    titleStyle,
    { paddingVertical: 0 },
  ],
  underlayColor: 'transparent',
}))``;
