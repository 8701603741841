import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { MOBILE_CARD_BUTTON_DICTIONARY as DICT } from '@dmi/shared/utils/marketingTilesProps';
import { fetchMessages as fetchLossDraftMessages } from '@dmi/shared/redux/LossDraft/actions';
import {
  INFO_COPY_DICT,
  POST_MESSAGE_SUCCESS_MESSAGE,
  SUCCESS_CONFIRMATION_TEXT,
} from '@dmi/shared/redux/LossDraft/constants';
import { selectConfirmationData } from '@dmi/shared/redux/LossDraft/selectors';
import { selectCustomerServicePhoneNumber, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import { StyledScrollView } from '../../../components/base_ui';
import MessagesConfirmationSucceeded from '../../../components/MessagesConfirmation/Succeeded';

const NewMessageConfirmationSucceeded = ({
  confirmationData,
  dispatchFetchLossDraftMessages,
  isMurabahaAccount,
  navigation,
}) => {
  useEffect(() => () => dispatchFetchLossDraftMessages(), [dispatchFetchLossDraftMessages]);

  const confirmationCopyDictionary = {
    heading: POST_MESSAGE_SUCCESS_MESSAGE,
    text: SUCCESS_CONFIRMATION_TEXT,
  };

  const handleConfirmationPressDone = () => {
    navigation.navigate('LossDraft');
  };

  const { nestedScreen, screen } = isMurabahaAccount
    ? DICT.documents
    : DICT.learningCenter;

  const handleCardButtonOnpress = () =>
    navigation.navigate(screen, nestedScreen ? { nestedScreen } : undefined);

  return (
    <StyledScrollView>
      <MessagesConfirmationSucceeded
        cardButtonDictionary={isMurabahaAccount ? DICT.documents : DICT.learningCenter}
        confirmationCopyDictionary={confirmationCopyDictionary}
        confirmationData={confirmationData}
        handleCardButtonOnpress={handleCardButtonOnpress}
        handleConfirmationPressDone={handleConfirmationPressDone}
        infoCopyDictionary={INFO_COPY_DICT}
        isMurabahaAccount={isMurabahaAccount}
      />
    </StyledScrollView>
  );
};

NewMessageConfirmationSucceeded.propTypes = {
  confirmationData: T.array.isRequired,
  dispatchFetchLossDraftMessages: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  confirmationData: selectConfirmationData('postReply'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: selectCustomerServicePhoneNumber(),
  isMurabahaAccount: selectIsMurabahaAccount(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchFetchLossDraftMessages: () => dispatch(fetchLossDraftMessages()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageConfirmationSucceeded);
