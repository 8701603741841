/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function PaymentHistoryActiveLIcon(props) {
  const { colors: { svgHighlight } } = useTheme();
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';

  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M6.8,10.5h-3v1.6c0,4.5,3.7,8.1,8.2,8.1c4.5,0,8.2-3.7,8.2-8.2c0-4.5-3.7-8.2-8.2-8.2C9.5,3.8,7.1,5,5.6,7h1.2c1,0,1.8,0.8,1.8,1.8S7.8,10.5,6.8,10.5z" fill={svgHighlight} />
        <G clipPath={clipPath}>
          <Path d="M12.1,17.2v-0.9c-1.2-0.1-2.2-0.6-3.2-1.4l0.9-1.1c0.7,0.6,1.5,1,2.2,1.1v-2.5c-1-0.2-1.7-0.5-2.1-0.9c-0.5-0.4-0.7-0.9-0.7-1.7S9.5,8.4,10,7.9c0.5-0.5,1.2-0.7,2-0.8V6.5h0.8v0.7c1,0.1,1.8,0.4,2.7,1l-0.8,1.2c-0.6-0.4-1.2-0.7-1.9-0.8V11h0c1,0.2,1.7,0.5,2.2,0.9c0.5,0.4,0.7,1,0.7,1.7S15.6,15,15,15.5c-0.5,0.5-1.2,0.7-2.1,0.8v0.9H12.1zM11.2,8.9c-0.2,0.2-0.3,0.4-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,1,0.5V8.5C11.7,8.6,11.4,8.7,11.2,8.9z M13.9,14.5c0.2-0.2,0.4-0.4,0.4-0.8c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-1.1-0.5v2.3C13.3,14.9,13.7,14.7,13.9,14.5z" fill="#FFFFFF" />
        </G>
        <Path d="M12,1.4c-3.8,0-7.4,2.1-9.2,5.4V3.4c0-0.3-0.3-0.6-0.6-0.6S1.6,3.1,1.6,3.4v5.3c0,0.3,0.3,0.6,0.6,0.6h4.6c0.3,0,0.6-0.3,0.6-0.6S7.2,8.1,6.8,8.1H3.6C5.1,4.8,8.4,2.7,12,2.7c5.1,0,9.3,4.2,9.3,9.3c0,5.1-4.2,9.3-9.3,9.3S2.7,17.2,2.7,12c0-0.3-0.3-0.6-0.6-0.6S1.5,11.7,1.5,12c0,5.8,4.7,10.6,10.6,10.6c5.8,0,10.6-4.7,10.6-10.6S17.9,1.4,12,1.4z" fill={svgHighlight} />
      </G>
    </Svg>
  );
}
