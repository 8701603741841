import React, { memo } from 'react';
import T from 'prop-types';
import { Platform, View } from 'react-native';

import { SCHEDULE_CHART_LINES } from '@dmi/shared/redux/Calculators/Amortize/constants';

import { ConditionalRender } from '../../base_ui';
import ChartComponent from './Chart';
import ChartLegend from './ChartLegend';
import { ChartAndLegendContainer } from './styledComponents';

const NativeChart = ChartComponent;
const WebChart = ChartComponent;

export const Chart = ({
  chartData: { initialScheduleData, newScheduleData },
  ...restProps
}) => {
  const chartNewScheduleLines = !!newScheduleData;

  const chartLinesToRender = () => SCHEDULE_CHART_LINES.reduce((acc, { mobileLineColor, name }) => {
    if (chartNewScheduleLines) {
      // eslint-disable-next-line no-param-reassign
      acc = acc.concat([
        {
          data: newScheduleData[name],
          mobileLineColor,
          name,
          strokeWidth: 4,
        },
        {
          data: initialScheduleData[name],
          mobileLineColor,
          name: `initial${name}`,
          opacity: '0.3',
        },
      ]);
    } else {
      acc.push({
        data: initialScheduleData[name],
        mobileLineColor,
        name,
        opacity: '0.3',
      });
    }
    return acc;
  }, []);

  return (
    <View>
      <ChartAndLegendContainer>
        <ConditionalRender
          Component={NativeChart}
          FallbackComponent={WebChart}
          propsToPassDown={{
            chartLines: chartLinesToRender(),
            showNewValues: chartNewScheduleLines,
            ...restProps,
          }}
          shouldRender={Platform.OS === 'android' || Platform.OS === 'ios'}
        />
        <ChartLegend showNewValues={chartNewScheduleLines} />
      </ChartAndLegendContainer>
    </View>
  );
};

Chart.propTypes = { chartData: T.object.isRequired };

export default memo(Chart);
