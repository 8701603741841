import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeInput as changeInputValueLogin } from '@dmi/shared/redux/Login/actions';
import {
  changeInputValue,
  goldMigrationLoginRequest,
  goldMigrationLoginRequestFailure,
  redirectToPaymentsPage,
  resetFormErrors,
} from '@dmi/shared/redux/GoldRedirect/actions';
import { handleFormSubmit } from '@dmi/shared/redux/GoldRedirect/helpers';
import makeSelectGoldRedirect, {
  selectGoldMigrationPLValidGoldRedirect,
  selectGoldRedirectFormErrorsProp,
  selectGoldRedirectFormValuesProp,
  selectGoldRedirectStatusProp,
  selectProfileTransferFormProps,
  selectSessionProp,
} from '@dmi/shared/redux/GoldRedirect/selectors';
import goldRedirectReducer from '@dmi/shared/redux/GoldRedirect/reducer';

import injectReducer from '../../utils/injectReducer';
import routeDictionary from './routeDictionary';

const GoldRedirect = ({
  dispatchChangeInputValue,
  dispatchChangeLoginUsernameInputValue,
  dispatchGoldMigrationLogin,
  dispatchGoldMigrationLoginError,
  dispatchRedirectToPaymentsPage,
  dispatchResetFormErrors,
  formErrors,
  formValues,
  goldRedirectPLLoginStatus,
  isValidGoldRedirect,
  navigation,
  profileTransferFormProps,
  profileTransferStep,
  route: { name: routeName },
  usernameSilverGeneralLogin,
}) => {
  const confirmPasswordInputRef = useRef(null);
  const passwordInputRef = useRef(null);
  const {
    isPasswordRequired,
    isUsernameRequired,
    operation,
    username,
    usernameForLogin,
  } = profileTransferFormProps;
  useEffect(() => {
    if (!isValidGoldRedirect) {
      navigation.replace('Login');
    }
  }, [navigation, isValidGoldRedirect]);

  const handleSubmit = () => handleFormSubmit({
    currentUsername: username,
    dispatchGoldMigrationLogin,
    dispatchGoldMigrationLoginError,
    dispatchResetFormErrors,
    formValues,
    isPasswordRequired,
    isUsernameRequired,
    operation,
    usernameForLogin,
  });

  const { Component } = routeDictionary[routeName];

  const profileTransferProps = {
    confirmPasswordInputRef,
    dispatchChangeInputValue,
    dispatchRedirectToPaymentsPage,
    formErrors,
    formValues,
    goldRedirectPLLoginStatus,
    handleSubmit,
    navigation,
    passwordInputRef,
    ...profileTransferFormProps,
    profileTransferStep,
  };
  const baseRedirectCardProps = { navigation, routeName };
  const propsToPassDown = (() => {
    if (routeName === 'ProfileTransfer') return profileTransferProps;
    if (routeName === 'Preregistration') return baseRedirectCardProps;
    if (routeName === 'Prelogin') {
      return {
        ...baseRedirectCardProps,
        linkOnPress: () => dispatchChangeLoginUsernameInputValue(usernameSilverGeneralLogin),
      };
    }
    return {};
  })();

  return (
    <Component {...propsToPassDown} />
  );
};

GoldRedirect.propTypes = {
  dispatchChangeInputValue: T.func.isRequired,
  dispatchChangeLoginUsernameInputValue: T.func.isRequired,
  dispatchGoldMigrationLogin: T.func.isRequired,
  dispatchGoldMigrationLoginError: T.func.isRequired,
  dispatchRedirectToPaymentsPage: T.func.isRequired,
  dispatchResetFormErrors: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  goldRedirectPLLoginStatus: T.string.isRequired,
  isValidGoldRedirect: T.bool.isRequired,
  navigation: T.object.isRequired,
  profileTransferFormProps: T.object.isRequired,
  profileTransferStep: T.number.isRequired,
  route: T.object.isRequired,
  usernameSilverGeneralLogin: T.string,
};

const mapStateToProps = createStructuredSelector({
  /**
  * Store : GoldRedirect
  */
  formErrors: selectGoldRedirectFormErrorsProp('profileTransfer'),
  formValues: selectGoldRedirectFormValuesProp('profileTransfer'),
  goldRedirectPLLoginStatus: selectGoldRedirectStatusProp('login'),
  isValidGoldRedirect: selectGoldMigrationPLValidGoldRedirect(),
  profileTransferFormProps: selectProfileTransferFormProps(),
  profileTransferStep: makeSelectGoldRedirect('profileTransferStep'),
  usernameSilverGeneralLogin: selectSessionProp('usernameSilverGeneralLogin'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: GoldRedirect
   */
  dispatchChangeInputValue: (payload) => dispatch(changeInputValue(payload)),
  dispatchGoldMigrationLogin: (payload) => dispatch(goldMigrationLoginRequest(payload)),
  dispatchGoldMigrationLoginError: (payload) => dispatch(goldMigrationLoginRequestFailure(payload)),
  dispatchRedirectToPaymentsPage: () => dispatch(redirectToPaymentsPage()),
  dispatchResetFormErrors: () => dispatch(resetFormErrors()),
  /**
   * Store: Login
   */
  // eslint-disable-next-line sort-keys
  dispatchChangeLoginUsernameInputValue: (payload) => dispatch(changeInputValueLogin({
    field: 'username',
    form: 'login',
    value: payload,
  })),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'goldRedirect', reducer: goldRedirectReducer });

export default compose(
  withConnect,
  withReducer,
)(GoldRedirect);
