import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H1, H3 } from '../../base_ui';

export const HeaderContainer = styled.View`
  align-items: center;
  align-self: ${({ $center }) => $center ? 'center' : 'flex-start'};
  flex-direction: row;
  margin-bottom: ${moderateScale(20)}px;
  padding-horizontal: ${({ $center }) => $center ? 0 : `${moderateScale(24)}px`};
  padding-vertical: ${moderateScale(18)}px;
  width:  ${({ $center }) => $center ? `${moderateScale(327)}px` : '100%'};
`;

export const HeaderContentWrapper = styled.View`
  flex-shrink: 1;
  justify-content: space-between;
  margin-left: ${moderateScale(20)}px;
  max-width: ${moderateScale(207)}px;
`;

export const HomeContainer = styled.View`
  height: 100%;
  width: 100%;
`;

export const HomeH1 = styled(H1)`
  padding: 0 ${moderateScale(24)}px;
`;

export const StyledHeader = styled(H3)`
  padding-bottom: ${moderateScale(8)}px;
`;
