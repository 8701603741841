/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HardshipLighthouseS(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="black" height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} {...props}>
      <Circle cx="42.04" cy="41.68" fill="#f9f7fc" r="39.94" />
      <Circle cx="42.04" cy="41.68" fill={svgHighlight} r="28.53" />
      <Polygon fill="#fff" points="30.74 61.06 52.12 61.06 53.99 85 29.07 85 30.74 61.06" />
      <Polygon fill="#fff" points="26.05 53.99 26.05 55.55 30.72 58.61 52.26 58.87 56.56 55.91 56.48 53.99 26.05 53.99" />
      <Polygon fill="#fff" points="30.91 32.38 52.22 32.23 52.22 33.58 49.86 35.14 32.67 35.14 30.91 33.68 30.91 32.38" />
      <Polygon fill="#e6ebef" points="26.58 55.55 56.33 55.55 51.85 58.38 31.18 58.38 26.58 55.55" />
      <Rect fill="#bac2ca" height="6.47" width="16.58" x="33.17" y="47.13" />
      <Polygon fill="#bac2ca" points="30.71 32.38 52.45 32.38 41.45 26.73 30.71 32.38" />
      <Rect fill="#e6ebef" height="11.66" width="16.63" x="32.98" y="35.17" />
      <Polygon fill="#fff" opacity="0.8" points="44.39 46.9 39.65 47.35 44.9 35.15 49.64 34.69 44.39 46.9" />
      <Polygon fill="#fff" opacity="0.8" points="32.9 42.19 32.83 39.47 32.94 35.15 36.32 34.69 32.9 42.19" />
      <Rect fill="#bac2ca" height="0.93" width="16.62" x="32.94" y="35.37" />
      <Rect fill="#bac2ca" height="2.3" width="21.39" x="30.87" y="58.77" />
      <Path d="M27.81,53.76a.3.3,0,0,1-.3-.3V47.14a.29.29,0,0,1,.3-.3H54.69a.3.3,0,0,1,.3.3v6.24a.31.31,0,0,1-.3.3.3.3,0,0,1-.3-.3V47.44H28.11v6A.3.3,0,0,1,27.81,53.76Z" />
      <Path d="M32.86,53.72a.31.31,0,0,1-.3-.3V35.31a.31.31,0,0,1,.3-.3.3.3,0,0,1,.3.3V53.42A.3.3,0,0,1,32.86,53.72Z" />
      <Path d="M49.81,53.72a.3.3,0,0,1-.3-.3V35.31a.3.3,0,0,1,.3-.3.31.31,0,0,1,.3.3V53.42A.31.31,0,0,1,49.81,53.72Z" />
      <Polygon fill="#fff" points="54.13 85 52.9 69.88 30.04 69.88 29.21 85 54.13 85" />
      <Path d="M49.86,35.44a.27.27,0,0,1-.25-.15.3.3,0,0,1,.1-.41L52,33.51v-1.1l-10.5-5.7L31.06,32.26v1.42l1.78,1.21a.3.3,0,1,1-.34.5l-1.91-1.3a.31.31,0,0,1-.13-.25V32.08a.3.3,0,0,1,.16-.27l10.76-5.7a.28.28,0,0,1,.28,0L52.46,32a.3.3,0,0,1,.15.26v1.46a.29.29,0,0,1-.14.25L50,35.4A.29.29,0,0,1,49.86,35.44Z" />
      <Path d="M52.68,70.59H32.35a.3.3,0,1,1,0-.6H52.68a.3.3,0,0,1,0,.6Z" />
      <Path d="M54.57,50.72H29.19a.3.3,0,0,1-.3-.3.29.29,0,0,1,.3-.3H54.57a.29.29,0,0,1,.3.3A.3.3,0,0,1,54.57,50.72Z" />
      <Path d="M38,53.76a.3.3,0,0,1-.3-.3V47.35a.3.3,0,0,1,.3-.3.29.29,0,0,1,.3.3v6.11A.29.29,0,0,1,38,53.76Z" />
      <Path d="M44.15,53.76a.29.29,0,0,1-.3-.3V48.62a.29.29,0,0,1,.3-.3.3.3,0,0,1,.3.3v4.84A.3.3,0,0,1,44.15,53.76Z" />
      <Path d="M52.12,58.91h-8a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.3h8a.3.3,0,0,1,.3.3A.29.29,0,0,1,52.12,58.91Z" />
      <Path d="M41.94,58.91h-11a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.3h11a.31.31,0,0,1,.3.3A.3.3,0,0,1,41.94,58.91Z" />
      <Path d="M49.37,35.37h-16a.29.29,0,0,1-.3-.3.3.3,0,0,1,.3-.3h16a.31.31,0,0,1,.3.3A.3.3,0,0,1,49.37,35.37Z" />
      <Path d="M52.22,32.53H35.87a.31.31,0,0,1-.3-.3.3.3,0,0,1,.3-.3H52.22a.3.3,0,0,1,.3.3A.31.31,0,0,1,52.22,32.53Z" />
      <Path d="M38.39,46.16a.3.3,0,0,1-.3-.3V36.34a.3.3,0,0,1,.3-.3.29.29,0,0,1,.3.3v9.52A.29.29,0,0,1,38.39,46.16Z" />
      <Path d="M44.14,45a.29.29,0,0,1-.3-.3V35.12a.3.3,0,0,1,.3-.3.31.31,0,0,1,.3.3v9.57A.3.3,0,0,1,44.14,45Z" />
      <Path d="M54,85.3a.3.3,0,0,1-.3-.28L51.86,58.61a.31.31,0,0,1,.14-.28l4.27-2.59v-1.6H26.35v1.67l4.56,2.67a.32.32,0,0,1,.15.28L29.43,85a.3.3,0,0,1-.6,0l1.62-26.07L25.9,56.24a.28.28,0,0,1-.15-.26V53.84a.29.29,0,0,1,.3-.3H56.56a.29.29,0,0,1,.3.3v2.07a.29.29,0,0,1-.14.25l-4.25,2.59L54.29,85a.31.31,0,0,1-.28.32Z" />
    </Svg>
  );
}
