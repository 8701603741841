/* eslint-disable react/no-array-index-key */
import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Platform, View } from 'react-native';

import { INTL_IDS } from '@dmi/shared/utils/languageDictionary/navigationMessages';

import iconDictionary from '../../utils/iconDictionary';
import { H1 } from '../base_ui';
import LoanCard from './LoanCard';
import {
  IllustrationWrapper,
  SelectLoanContainer,
  StyledP2,
  StyledStatusBanner,
} from './styledComponents';

const DesktopSelectLoanIllustration = iconDictionary('desktopSelectLoan');
const SelectLoanIllustration = iconDictionary('selectLoan');

const SelectLoan = ({
  dispatchChangeSelectedLoan,
  error,
  loans,
  userDisplayName,
}) => {
  const IllustrationToRender = Platform.OS === 'web' ?
    DesktopSelectLoanIllustration : SelectLoanIllustration;

  return (
    <SelectLoanContainer>
      <View>
        <StyledStatusBanner error={error} />
        <H1>
          <FormattedMessage id={INTL_IDS.LOANS_SELECT_HEADING} values={{ userDisplayName }} />
        </H1>
        <StyledP2>
          <FormattedMessage id={INTL_IDS.LOANS_SELECT_PARAGRAPH} />
        </StyledP2>
        {loans.map(({
          address,
          hashedLoanNumber,
          maskedLoanNumber,
          nextPaymentDueDateMessage,
          onlineAvailabilityStatus,
          totalMonthlyPayment,
        }, index) => (
          <LoanCard
            key={index}
            address={address}
            dispatchChangeSelectedLoan={dispatchChangeSelectedLoan}
            hashedLoanNumber={hashedLoanNumber}
            maskedLoanNumber={maskedLoanNumber}
            nextPaymentDueDateMessage={nextPaymentDueDateMessage}
            onlineAvailabilityStatus={onlineAvailabilityStatus}
            totalMonthlyPayment={totalMonthlyPayment}
          />
        ))}
      </View>
      <IllustrationWrapper>
        {IllustrationToRender}
      </IllustrationWrapper>
    </SelectLoanContainer>
  );
};

SelectLoan.propTypes = {
  dispatchChangeSelectedLoan: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  loans: T.array.isRequired,
  userDisplayName: T.string.isRequired,
};

export default SelectLoan;
