// TODO: update icons when proper home page screens have been added
export const TAB_BAR_ICON_DICTIONARY = {
  active: {
    Documents: 'navDocumentsActiveL',
    Mortgage: 'navMortgageActiveL',
    Murabaha: 'navMortgageActiveL',
    Payments: 'navPaymentsActiveL',
  },
  default: {
    Documents: 'navDocumentsDefaultL',
    Mortgage: 'navMortgageDefaultL',
    Murabaha: 'navMortgageDefaultL',
    Payments: 'navPaymentsDefaultL',
  },
};
