/*
 * Confirmation Screen
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';

import { resetRequestStatusState } from '@dmi/shared/redux/Settings/Profile/actions';
import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { ConfirmationComponent } from '../../../components/Settings/EditEmail';
import { StyledView } from './styledComponents';

const ConfirmationScreen = ({
  dispatchResetRequestStatusState,
  navigation,
}) => {
  const { header } = PROFILE_CONTENT_DICTIONARY.editEmail.confirmationView.mobile;

  useEffect(() => () => {
    dispatchResetRequestStatusState({ state: 'email' });
  }, [dispatchResetRequestStatusState]);

  return (
    <Fragment>
      <SecondaryHeader
        handleDone={() => navigation.navigate('Settings')}
        title={header}
      />
      <StyledView>
        <ConfirmationComponent />
      </StyledView>
    </Fragment>
  );
};

ConfirmationScreen.propTypes = {
  dispatchResetRequestStatusState: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Profile
   */
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
});

const withConnect = connect(null, mapDispatchToProps);

export default compose(withConnect)(ConfirmationScreen);
