/* eslint-disable max-len */
import React from 'react';
import
Svg, {
  Circle,
  Line,
  Polygon,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function EscrowIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Line className="cls-1" fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5px" x1="15.69" x2="2.52" y1="16.31" y2="16.31" />
      <Line className="cls-2" fill="none" stroke={iconStatic} strokeLinejoin="round" strokeWidth="1.35px" x1="10.83" x2="10.83" y1="8.13" y2="14.79" />
      <Line className="cls-2" fill="none" stroke={iconStatic} strokeLinejoin="round" strokeWidth="1.35px" x1="7.38" x2="7.38" y1="8.13" y2="14.79" />
      <Line className="cls-2" fill="none" stroke={iconStatic} strokeLinejoin="round" strokeWidth="1.35px" x1="3.93" x2="3.93" y1="8.13" y2="14.79" />
      <Line className="cls-2" fill="none" stroke={iconStatic} strokeLinejoin="round" strokeWidth="1.35px" x1="14.28" x2="14.28" y1="8.13" y2="14.79" />
      <Polygon className="cls-3" fill="none" points="16.64 6.84 1.57 6.84 9.03 1.92 16.64 6.84" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Circle cx="9.07" cy="4.66" r="0.87" />
    </Svg>
  );
}
