import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';
import { MessageContainer } from './styledComponents';

const errorStorm = iconDictionary('errorStorm');

const PageLevelError = ({
  containerStyle,
  error,
  link = {
    label: 'Return to Home',
    path: 'Payments',
  },
  ...restProps
}) => (
  <MessageContainer style={containerStyle}>
    <Message
      icon={errorStorm}
      link={link}
      title={error === true ? 'Uh oh. Something unexpected occurred.' : error}
      {...restProps}
    />
  </MessageContainer>
);

PageLevelError.propTypes = {
  containerStyle: T.object,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  link: T.shape({
    label: T.string,
    path: T.string,
  }),
};

export default PageLevelError;
