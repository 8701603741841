import styled from 'styled-components/native';
import { Platform } from 'react-native';
import { Input } from 'react-native-elements';

const commonBorderColor = ({
  $error,
  $focused,
  colors: { accentRed, primary, primaryGray },
}) => {
  if ($focused) return primary;
  if ($error) return accentRed;
  return primaryGray;
};

const outlineInputBorder = ({
  $error,
  $filled,
  $focused,
  $variant,
  colors: { accentRed, primaryGray },
}) => {
  if ($variant === 'outline' && $error) {
    if ($error && $focused) return { borderBottomWidth: 1 };
    if ($error && !$filled) return { borderBottomWidth: 3 };
    if ($error && $filled) {
      return { borderBottomColor: accentRed, borderBottomWidth: 3, borderColor: primaryGray };
    }
  }
  return {};
};

/*
  The main TextField component
*/
export const StyledBaseInput = styled(Input).attrs(({
  $filled,
  $error,
  $focused,
  $variant,
  containerStyle = {},
  errorStyle = {},
  inputContainerStyle = {},
  inputStyle = {},
  labelStyle = {},
  placeholderTextColor = {},
  theme: {
    colors,
    colors: {
      accentRed,
      disabledText,
      primary,
      primaryCarbon,
      primaryGray,
    },
    fontFamilies: { secondaryFont },
    fontSizes: { f2, f3 },
  },
}) => ({
  containerStyle: {
    paddingHorizontal: 0, // overwrite opinionated default
    ...containerStyle,
  },
  disabledInputStyle: { color: disabledText },
  errorStyle: {
    color: accentRed,
    fontFamily: secondaryFont,
    fontSize: f2,
    ...errorStyle,
  },
  inputContainerStyle: {
    borderColor: commonBorderColor({ $error, $focused, colors }),
    borderStyle: 'solid',
    borderWidth: 1,
    ...inputContainerStyle,
    ...outlineInputBorder({
      $error,
      $filled,
      $focused,
      $variant,
      colors,
    }),
  },
  inputStyle: {
    color: primaryCarbon,
    fontFamily: secondaryFont,
    fontSize: f3,
    margin: 0, // overwrite Safari specific opinionated default
    minHeight: 0, // overwrite opinionated default 40px
    minWidth: 0, // weird necessity for web to make input width respect container width
    ...Platform.select({
      web: {
        caretColor: primary,
        outlineStyle: 'none',
        outlineWidth: 0,
      },
    }),
    ...inputStyle,
  },
  labelStyle: {
    color: primaryCarbon,
    fontFamily: secondaryFont,
    fontSize: f3,
    /*
      RNE provides a default fontWeight 'bold', which has inconsistent cross-platform behavior.
      Our fontFamily of 'regular' or 'bold' generally seems to overwrite whatever fontWeight
      is set to, except in Safari iOS fontWeight of 'bold' makes whichever fontFamily we use
      both be bolder and have oddly wide letter spacing
    */
    fontWeight: 'normal',
    ...labelStyle,
  },
  placeholderTextColor: placeholderTextColor || primaryGray,
  ...Platform.select({ ios: { selectionColor: primary } }),
}))``;
