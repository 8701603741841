import styled from 'styled-components/native';

import { H2, H4 } from '../Heading';
import { P2, P3 } from '../Paragraphs';
import { moderateScale } from '../../../utils/scaleHelpers';

export const AlertBannerContainer = styled.View`
  align-items: center;
  border-bottom-width: 1px;
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
`;

export const AlertHeading = styled(H2)`
   margin-bottom: ${moderateScale(4)}px;
 `;

export const AlertLinkText = styled(P2)`
  color: ${({ theme: { colors: { primary } } }) => primary};
  text-align: center;
`;

export const AlertTextContainer = styled.View`
  align-items: center;
  padding: ${moderateScale(8)}px ${moderateScale(24)}px ${moderateScale(17)}px;
`;

export const BannerText = styled.View`
  flex-shrink: 1;
`;

export const IconWrapper = styled.View`
  padding-right: ${moderateScale(8)}px;
`;

export const StatusBannerContainer = styled.View`
  align-items: center;
  border: ${moderateScale(1)}px solid ${({
  error,
  isPartialError,
  theme: { colors: { accentGreen, accentRed, primary } },
}) => {
  if (error && isPartialError) return primary;
  if (error) return accentRed;
  return accentGreen;
}};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px;
`;

export const StyledBanner = styled.View`
   padding: ${moderateScale(16)}px ${moderateScale(24)}px;
   width: 100%;
`;

export const StyledBannerText = styled(H4)`
  color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  text-align: center;
`;

export const StyledErrorSuccessBanner = styled(StyledBanner).attrs(({
  error,
  theme: { colors: { accentRed, primary } },
}) => ({ bannerColor: error ? accentRed : primary }))`
  background-color: ${({ bannerColor }) => bannerColor};
`;

export const StatusBannerText = styled(P3)`
  flex-shrink: 1;
`;

export const StatusBannerTextBold = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;
