/*
 * Verify Mobile Number Screen
 */

import React, { Fragment, useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeMobileNumber,
  inputErrors,
  resetBannerError,
  resetFormErrorsState,
  resetFormState,
  resetRequestStatusState,
  verifyMobileNumber,
} from '@dmi/shared/redux/Settings/Profile/actions';
import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import makeSelectProfile, {
  selectBannerError,
  selectFormConfirmation,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Profile/selectors';
import { validateFields } from '@dmi/shared/redux/Settings/Shared/helpers';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { VerifyMobileNumberComponent } from '../../../components/Settings/EditMobileNumber';

const VerifyMobileNumberScreen = ({
  bannerError,
  dispatchChangeInput,
  dispatchEditMobileNumber,
  dispatchInputErrors,
  dispatchResetBannerError,
  dispatchResetFormErrorsState,
  dispatchResetFormState,
  dispatchResetRequestStatusState,
  dispatchVerifyMobileNumber,
  formErrors,
  forms,
  mobileNumberVerifyToken,
  navigation,
  success,
  verifyMobileNumberStatus,
}) => {
  const {
    mobileNumber: mobileNumberFormErrors,
    verifyMobileNumber: verifyMobileNumberFormErrors,
  } = formErrors;
  const { mobileNumber: mobileNumberForm, verifyMobileNumber: verifyMobileNumberForm } = forms;
  const { header } = PROFILE_CONTENT_DICTIONARY.editMobileNumber.verifyMobileNumberView;

  useEffect(() => () => {
    dispatchResetBannerError();
    dispatchResetFormState({ form: 'verifyMobileNumber' });
    dispatchResetRequestStatusState({ state: 'mobileNumber' });
    dispatchResetRequestStatusState({ state: 'verifyMobileNumber' });
  }, [
    dispatchResetBannerError,
    dispatchResetFormState,
    dispatchResetRequestStatusState,
  ]);

  const handleEditMobileNumber = () => {
    const { isValidated, validationErrors } = validateFields({
      formErrors: mobileNumberFormErrors,
      values: mobileNumberForm,
    });
    if (isValidated) {
      dispatchEditMobileNumber({ isVerify: true, mobileNumber: mobileNumberForm.newMobileNumber });
    }
    dispatchInputErrors({ form: 'mobileNumber', formErrors: validationErrors });
  };

  const handleVerifyMobileNumber = useCallback(() => {
    const { isValidated, validationErrors } = validateFields({
      formErrors: verifyMobileNumberFormErrors,
      values: verifyMobileNumberForm,
    });
    if (isValidated) {
      dispatchVerifyMobileNumber({
        mobileNumber: mobileNumberForm.newMobileNumber,
        passCode: verifyMobileNumberForm.passCode,
        token: mobileNumberVerifyToken,
      });
    }
    dispatchInputErrors({ form: 'verifyMobileNumber', formErrors: validationErrors });
  }, [
    dispatchInputErrors,
    dispatchVerifyMobileNumber,
    mobileNumberForm,
    mobileNumberVerifyToken,
    verifyMobileNumberForm,
    verifyMobileNumberFormErrors,
  ]);

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={navigation.goBack}
        title={header}
      />
      <VerifyMobileNumberComponent
        bannerError={bannerError}
        dispatchChangeInput={dispatchChangeInput}
        dispatchResetFormErrorsState={dispatchResetFormErrorsState}
        formErrors={formErrors}
        forms={forms}
        handleEditMobileNumber={handleEditMobileNumber}
        handleVerifyMobileNumber={handleVerifyMobileNumber}
        success={success}
        verifyMobileNumberStatus={verifyMobileNumberStatus}
      />
    </Fragment>
  );
};

VerifyMobileNumberScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchEditMobileNumber: T.func.isRequired,
  dispatchInputErrors: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetFormErrorsState: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  dispatchVerifyMobileNumber: T.func.isRequired,
  formErrors: T.object.isRequired,
  forms: T.object.isRequired,
  mobileNumberVerifyToken: T.string.isRequired,
  navigation: T.object.isRequired,
  success: T.string.isRequired,
  verifyMobileNumberStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  bannerError: selectBannerError(),
  formErrors: makeSelectProfile('formErrors'),
  forms: makeSelectProfile('forms'),
  mobileNumberVerifyToken: makeSelectProfile('mobileNumberVerifyToken'),
  success: selectFormConfirmation('mobileNumber'),
  verifyMobileNumberStatus: selectRequestStatus('verifyMobileNumber'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Profile
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchEditMobileNumber: (payload) => dispatch(changeMobileNumber(payload)),
  dispatchInputErrors: (payload) => dispatch(inputErrors(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetFormErrorsState: (payload) => dispatch(resetFormErrorsState(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
  dispatchVerifyMobileNumber: (payload) => dispatch(verifyMobileNumber(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(VerifyMobileNumberScreen);
