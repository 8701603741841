import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../base_ui';
import WithBanner from './WithBanner';
import WithoutBanner from './WithoutBanner';

const ClientSection = ({ clientBanner, isSecureMessagingTreatmentOn }) => {
  const { image, url } = clientBanner || {};

  return (
    <ConditionalRender
      Component={WithBanner}
      FallbackComponent={WithoutBanner}
      propsToPassDown={{
        image,
        isSecureMessagingTreatmentOn,
        url,
      }}
      shouldRender={!!image && !!url}
    />
  );
};

ClientSection.propTypes = {
  clientBanner: T.shape({
    image: T.string,
    url: T.string,
  }),
  isSecureMessagingTreatmentOn: T.bool.isRequired,
};

export default ClientSection;
