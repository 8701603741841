/* eslint-disable max-len */
import React from 'react';
import
Svg, {
  Line,
  Path,
  Polygon,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function MortgageIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      fill={iconStatic}
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Polygon fill="none" points="15.35 15.77 3 15.77 3 6.53 9.08 2.46 15.35 6.53 15.35 15.77" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="1.62" x2="3.55" y1="7.38" y2="6.16" />
      <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="16.69" x2="14.76" y1="7.38" y2="6.16" />
      <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="13.47" x2="13.47" y1="5.26" y2="2.96" />
      <Path d="M8.8,14.23v-.77a4.6,4.6,0,0,1-2.62-1.17L7,11.36a3.74,3.74,0,0,0,1.85.93V10.22A4.32,4.32,0,0,1,7,9.46a1.71,1.71,0,0,1-.56-1.39,2,2,0,0,1,.65-1.51A2.64,2.64,0,0,1,8.8,5.92V5.39h.68v.54a4.54,4.54,0,0,1,2.22.8l-.69,1A3.39,3.39,0,0,0,9.48,7.1v2h0a4.33,4.33,0,0,1,1.8.78,1.78,1.78,0,0,1,.57,1.43,2,2,0,0,1-.66,1.52,2.77,2.77,0,0,1-1.75.64v.76ZM8.05,7.35A.82.82,0,0,0,8,8.51a2.14,2.14,0,0,0,.81.4V7.06A1.28,1.28,0,0,0,8.05,7.35ZM10.29,12a.78.78,0,0,0,.3-.62.81.81,0,0,0-.23-.6,2.49,2.49,0,0,0-.88-.41v1.92A1.48,1.48,0,0,0,10.29,12Z" />
    </Svg>
  );
}
