import React, { Fragment } from 'react';

import { interpolate } from '@dmi/shared/utils/globalHelpers';

/**
 * 2 regex objects matching all occurences of a pattern with an opening and closing html-like tag.
 * for example: '<b>Hello</b> <span>world</span>!' matches: '<b>Hello</b>' & '<span>world</span>'
 * One version provides capture groups, the other does not.
 * The capture groups are:
 *   1: startTag (e.g. '<b>')
 *   2: innerText/msg between startTag and endTag (e.g. 'Hello')
 *   3: endTag (e.g. '</b>')
 */
const regexWithCaptureGroups = /(<.*?>)(.*?)(<\/.*?>)/gs;
const regexWithoutCaptureGroups = /<.*?>.*?<\/.*?>/gs;

export const getReactMessages = (str, values) => {
  const interpolatedString = interpolate(str, values);
  const reactifiedMatches = getReactifiedMatches(interpolatedString, values);
  const reactifiedNonMatches = getReactifiedNonMatches(interpolatedString);
  return combineReactifiedMessages(reactifiedMatches, reactifiedNonMatches);
};

const getReactifiedMatches = (str, values) => {
  const matches = [...str.matchAll(regexWithCaptureGroups)];
  const isValid = validateSyntax(matches);
  if (!isValid) {
    throw new Error('Invalid syntax. Ensure each startTag has matching endTag. Do not nest tags.');
  }
  return matches.map((match, index) => reactifyMatch(match, index, values));
};

const getReactifiedNonMatches = (str) => {
  const nonMatchedStrings = str.split(regexWithoutCaptureGroups);
  return nonMatchedStrings.map(
    // eslint-disable-next-line react/no-array-index-key
    (val, ind) => <Fragment key={`nonMatch-${ind}-${val}`}>{val}</Fragment>,
  );
};

/**
 * Maps through reactifiedNonMatches, interjecting the next reactifiedMatch after each
 * reactifiedNonMatch. Assumes `reactifiedNonMatches.length > reactifiedMatches.length`.
 * @example (using numbers instead of React elements for simplicity)
 *   ([1,2,3], [4,5,6,7]) -> [4,1,5,2,6,3,7]
 */
const combineReactifiedMessages = (reactifiedMatches, reactifiedNonMatches) => (
  reactifiedNonMatches.reduce((acc, nonMatchElement, ind) => {
    acc.push(nonMatchElement);
    const nextReactifiedMatch = reactifiedMatches[ind];
    if (nextReactifiedMatch) {
      acc.push(nextReactifiedMatch);
    }
    return acc;
  }, [])
);

/**
 * Checks that the startTag and endTag are the same tags
 * @param {array} matches - result of [...str.matchAll(regex)], where each match's first and third
 *                          capturedGroups are the startTag and endTag, respectively
 * @example
 *   ([,'<b>',,'</b>']) -> true
 *   ([,'<b>',,'</i>']) -> false
 */
const validateSyntax = (matches) => (
  matches.every(([, startTag,, endTag]) => {
    const formattedEndTag = endTag.replace('/', '');
    return startTag === formattedEndTag;
  })
);

/**
 * Converts matched string to a ReactElement based on the tagType in the `values` object. If the
 * tagType cannot be found in `values`, returns the matched string unaltered.
 * @param {array} match - entry from [...str.matchAll(regex)], where the 1st and 2nd capturedGroups
 *                        are the startTag and the text wrapped by the tags (aka, `msg`)
 * @param {number} index - index of match in the matchAll array (used to help assign a `key` prop)
 * @param {object} values - dictionary that should have a property matching the tagType whose value
 *                          is a FunctionComponent that accepts the `msg` as a prop
 * @returns {string|JSX.Element}
 */
const reactifyMatch = (match, index, values) => {
  const [msgWithTags, startTag, msg] = match;
  const tagType = getTagType(startTag);
  const FunctionComponent = values[tagType];
  if (!FunctionComponent) {
    return msgWithTags;
  }
  return <FunctionComponent key={`match-${index}-${msg}`} msg={msg} />;
};

/**
 * Grabs the tag type, ie, the text inside of a tag.
 * @example
 *   ('<b>') -> 'b'
 */
const getTagType = (tag) => {
  const regex = /<(.*?)>/gs;
  const match = regex.exec(tag);
  const tagType = match[1];
  return tagType;
};
