import React, { useEffect, useRef, useState } from 'react';
import T from 'prop-types';

import {
  InputLine,
  InputLineContainer,
  InputText,
  PasscodeInputContainer,
  StyledInput,
} from './styledComponents';

const PasscodeInputField = ({
  focusState = 0,
  length = 6,
  onChange,
  onComplete = () => {},
  value,
}) => {
  const [editable, setEditable] = useState(true);
  const inputRef = useRef(null);

  useEffect(() => {
    // Handles both autofocus on mount, and receiving focus whenever 'focusState' changes
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [focusState]);

  const handleChangeInput = ({ input }) => {
    if (input.match(/^[0-9]*$/)) {
      if (onChange) {
        onChange(input);
      }

      if (onComplete && input.length === length) {
        setEditable(false);
        setTimeout(() => {
          setEditable(true);
          onComplete(input);
        }, 100);
      }
    }
  };

  const renderInputLines = () => {
    const elements = [];
    let i = 0;
    const vals = value.split('');
    while (i < length) {
      const active = i < value.length;

      elements.push(
        <InputLineContainer
          key={i}
          accessibilityElementsHidden
          importantForAccessibility="no-hide-descendants"
          maxLength={length}
        >
          <InputText>{vals[i] || ''}</InputText>
          <InputLine active={active} />
        </InputLineContainer>,
      );
      i += 1;
    }
    return elements;
  };

  return (
    <PasscodeInputContainer>
      {renderInputLines()}
      <StyledInput
        ref={inputRef}
        autoCapitalize="none"
        autoCorrect={false}
        editable={editable}
        keyboardType="numeric"
        maxLength={length}
        onChangeText={(input) => handleChangeInput({ input })}
        underlineColorAndroid="transparent"
        value={value}
      />
    </PasscodeInputContainer>
  );
};

PasscodeInputField.propTypes = {
  focusState: T.oneOf([0, 1]),
  length: T.number,
  onChange: T.func.isRequired,
  onComplete: T.func,
  value: T.string.isRequired,
};

export default PasscodeInputField;
