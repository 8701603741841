import React from 'react';
import T from 'prop-types';
import {
  Platform,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useTheme } from 'styled-components/native';

import makeSelectMain from '@dmi/shared/redux/Main/selectors';

const NotFoundScreen = ({ isLoggedIn, navigation }) => {
  const theme = useTheme();
  const navigationArgs = isLoggedIn ?
    ['PostAuthNavigator', { screen: Platform.OS === 'web' ? 'Payments' : 'TabNavigator' }] :
    ['PreAuthNavigator', { screen: 'Login' }];
  const linkText = isLoggedIn ? 'Go to home screen!' : 'Return to Login screen';

  return (
    <View style={styles(theme).container}>
      <Text style={styles(theme).title}>This screen doesn&apos;t exist.</Text>
      <Pressable onPress={() => navigation.replace(...navigationArgs)} style={styles(theme).link}>
        <Text style={styles(theme).linkText}>{linkText}</Text>
      </Pressable>
    </View>
  );
};

NotFoundScreen.propTypes = {
  isLoggedIn: T.bool.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({ isLoggedIn: makeSelectMain('isLoggedIn') });

const withConnect = connect(mapStateToProps, null);
export default withConnect(NotFoundScreen);

const styles = ({ colors: { linkText } }) => StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'center',
    padding: 20,
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    color: linkText,
    fontSize: 14,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
});
