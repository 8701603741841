import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H3, P3, PrimaryDivider } from '../../../base_ui';

export const NotificationContainer = styled.View`
  padding: ${moderateScale(20)}px ${moderateScale(24)}px 0;
`;

export const NotificationDate = styled(P3)`
  padding-bottom: ${moderateScale(10)}px;
`;

export const NotificationName = styled(H3)`
  padding-bottom: ${({ $noMargin }) => $noMargin ? '0' : `${moderateScale(6)}px`};
`;

export const StyledDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
