/* eslint-disable sort-keys */
import CalculatorsHomeScreen from '../../../../containers/Calculators/HomeScreen';
import DocumentsHomeScreen from '../../../../containers/Documents/DocumentsScreen';
import HelpHomeScreen from '../../../../containers/Help/HelpHomeScreen';
import MortgageHomeScreen from '../../../../containers/Mortgage';
import PaymentHomeScreen from '../../../../containers/Payments/Home';
import SettingsHomeScreen from '../../../../containers/Settings/SettingsHome';

/**
 * 'Home' screens are ones which are nested inside/can access the HomeDrawerNavigator.
 * On native builds, they will be ne nested even deeper, inside the TabNavigator.
 * On web, they will be direct screens on the HomeDrawerNavigator.
 */
const getHomeScreens = (isMurabahaAccount, isWeb) => ({
  Payments: { component: PaymentHomeScreen },
  [isMurabahaAccount && !isWeb ? 'Murabaha' : 'Mortgage']: { component: MortgageHomeScreen },
  Documents: { component: DocumentsHomeScreen },
  Calculators: { component: CalculatorsHomeScreen },
  Help: { component: HelpHomeScreen },
  Settings: { component: SettingsHomeScreen },
});

export default getHomeScreens;
