import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { chooseDocument } from '@dmi/shared/redux/Mortgage/actions';
import { selectRequestStatus } from '@dmi/shared/redux/Mortgage/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { InsuranceUploadComponent } from '../../../components/Mortgage/InsuranceUpload';
import { StyledView } from '../styledComponents';

const InsuranceUploadScreen = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  navigation,
}) => (
  <StyledView>
    <SecondaryHeader
      handleBack={navigation.goBack}
      title="Upload Document"
    />
    <InsuranceUploadComponent
      chooseDocumentRequestStatus={chooseDocumentRequestStatus}
      dispatchChooseDocument={dispatchChooseDocument}
      reviewScreenName="InsuranceUploadReview"
    />
  </StyledView>
);

InsuranceUploadScreen.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Mortgage
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InsuranceUploadScreen);
