import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeInputError,
  changePassword,
  changeStep,
  inputErrors,
  resetFormState,
  resetRequestStatusState,
} from '@dmi/shared/redux/Settings/Profile/actions';
import makeSelectProfile, {
  selectForm,
  selectFormErrors,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Profile/selectors';
import { validateFields } from '@dmi/shared/redux/Settings/Shared/helpers';

import { StyledScrollView } from '../../../components/base_ui';
import viewDictionary from './viewDictionary';

const PasswordScreen = ({
  dispatchChangeInput,
  dispatchChangeInputError,
  dispatchChangePassword,
  dispatchChangeStep,
  dispatchInputErrors,
  dispatchResetFormState,
  dispatchResetRequestStatusState,
  formErrors,
  formValues,
  navigation,
  requestStatusPassword,
  step,
}) => {
  useEffect(() => () => {
    dispatchChangeStep(1);
    dispatchResetFormState({ form: 'password' });
    dispatchResetRequestStatusState({ state: 'password' });
  }, [
    dispatchChangeStep,
    dispatchResetFormState,
    dispatchResetRequestStatusState,
  ]);

  const handleChangePassword = () => {
    const { isValidated, validationErrors } = validateFields({
      formErrors,
      values: formValues,
    });
    if (isValidated) {
      dispatchChangePassword({ ...formValues });
    }
    dispatchInputErrors({
      form: 'password',
      formErrors: validationErrors,
    });
  };

  const { ViewToRender } = viewDictionary[step];
  const propsToPassDown = {
    dispatchChangeInput,
    dispatchChangeInputError,
    formErrors,
    formValues,
    handleChangePassword,
    navigation,
    requestStatusPassword,
    step,
  };

  return (
    <StyledScrollView>
      <ViewToRender {...propsToPassDown} />
    </StyledScrollView>
  );
};

PasswordScreen.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeInputError: T.func.isRequired,
  dispatchChangePassword: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchInputErrors: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  formErrors: T.shape({
    confirmNewPassword: T.string.isRequired,
    newPassword: T.string.isRequired,
    oldPassword: T.string.isRequired,
  }).isRequired,
  formValues: T.shape({
    confirmNewPassword: T.string.isRequired,
    newPassword: T.string.isRequired,
    oldPassword: T.string.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
  requestStatusPassword: T.string.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  formErrors: selectFormErrors('password'),
  formValues: selectForm('password'),
  requestStatusPassword: selectRequestStatus('password'),
  step: makeSelectProfile('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Profile
  */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeInputError: (payload) => dispatch(changeInputError(payload)),
  dispatchChangePassword: (payload) => dispatch(changePassword(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchInputErrors: (payload) => dispatch(inputErrors(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(PasswordScreen);
