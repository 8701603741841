import { moderateScale } from '../../../utils/scaleHelpers';
import iconDictionary from '../../../utils/iconDictionary';

export const STATUS_BLOCK_ICON_DICTIONARY = {
  approved: iconDictionary('approved', { themeColorKey: 'accentGreen' }),
  outlinedPendingPurple: iconDictionary('outlinedPendingPurple',
    { height: `${moderateScale(24)}px`, width: `${moderateScale(24)}px` }),
  outlinedWarning: iconDictionary('outlinedWarning',
    { height: `${moderateScale(24)}px`, width: `${moderateScale(24)}px` }),
  pendingGray: iconDictionary('pendingGray'),
};
