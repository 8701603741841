import React, { Fragment, useRef } from 'react';
import T from 'prop-types';

import StateDropdown from './StateDropdown';
import { StyledBaseTextField } from '../styledComponents';

const DomesticForm = ({
  allowedStates,
  formErrors,
  handleChangeInput,
  handleChangeMailingAddress,
  isSubmitDisabled,
  requestBody,
}) => {
  const apartmentRef = useRef();
  const cityRef = useRef();
  const streetAddressRef = useRef();

  return (
    <Fragment>
      <StyledBaseTextField
        error={formErrors.company}
        label="Company or c/o"
        onChangeText={(value) => handleChangeInput('company', value)}
        onSubmitEditing={() => streetAddressRef.current.focus()}
        placeholder="Optional"
        returnKeyType="next"
        value={requestBody.company}
      />
      <StyledBaseTextField
        ref={streetAddressRef}
        error={formErrors.streetAddress}
        label="Street Address"
        onChangeText={(value) => handleChangeInput('streetAddress', value)}
        onSubmitEditing={() => apartmentRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={requestBody.streetAddress}
      />
      <StyledBaseTextField
        ref={apartmentRef}
        error={formErrors.apartment}
        label="Apartment, Suite, Other"
        onChangeText={(value) => handleChangeInput('apartment', value)}
        onSubmitEditing={() => cityRef.current.focus()}
        placeholder="Optional"
        returnKeyType="next"
        value={requestBody.apartment}
      />
      <StyledBaseTextField
        ref={cityRef}
        error={formErrors.city}
        label="City"
        onChangeText={(value) => handleChangeInput('city', value)}
        placeholder="Required"
        value={requestBody.city}
      />
      <StateDropdown
        allowedStates={allowedStates}
        error={formErrors.state}
        handleChangeInput={handleChangeInput}
        selected={requestBody.state}
      />
      <StyledBaseTextField
        errorMessage={formErrors.zip}
        keyboardType="number-pad"
        label="Zip Code"
        onChangeText={(value) => handleChangeInput('zip', value)}
        onSubmitEditing={isSubmitDisabled ? undefined : handleChangeMailingAddress}
        placeholder="Required"
        returnKeyType="done"
        value={requestBody.zip}
        width={109}
      />
    </Fragment>
  );
};

DomesticForm.propTypes = {
  allowedStates: T.array.isRequired,
  formErrors: T.shape({
    apartment: T.string.isRequired,
    city: T.string.isRequired,
    company: T.string.isRequired,
    state: T.string.isRequired,
    streetAddress: T.string.isRequired,
    zip: T.string.isRequired,
  }).isRequired,
  handleChangeInput: T.func.isRequired,
  handleChangeMailingAddress: T.func.isRequired,
  isSubmitDisabled: T.bool.isRequired,
  requestBody: T.shape({
    apartment: T.string.isRequired,
    city: T.string.isRequired,
    company: T.string.isRequired,
    state: T.string.isRequired,
    streetAddress: T.string.isRequired,
    zip: T.string.isRequired,
  }).isRequired,
};

export default DomesticForm;
