import styled from 'styled-components/native';

import { PrimaryDivider, StatusBanner } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const SchedulePaymentContent = styled.View`
  flex: 1;
  margin-top: ${moderateScale(24)}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  max-width: auto;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin: ${moderateScale(10)}px ${moderateScale(24)}px;
`;
