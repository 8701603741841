import React, { Fragment } from 'react';
import T from 'prop-types';

import AsyncRender from '../../../components/AsyncRender';
import { SecondaryDivider } from '../../../components/base_ui';
import { HomeMain, HomeMainSkeleton, WhatsNew } from '../../../components/Payments/Home';
import { HomeHistory, HomeHistorySkeleton } from '../../../components/Payments/History/HomeHistory';

const PaymentHomeView = ({
  address,
  autopayAllowedOperation,
  autopayStatus,
  autopayStatusProps,
  customerServicePhoneNumber,
  dispatchDismissDocUploadStatusBanner,
  dispatchResetMainError,
  docUploadNotificationFailure,
  fetchStatusLoading,
  fetchTransferRequestStatus,
  handleRowPress,
  historyData,
  homeHistoryError,
  homeHistoryLoading,
  isAutopayEnabledOrPending,
  isEligibleForAutopay,
  isEligibleForPaperlessBilling,
  isLoanMancodeY,
  isMurabahaAccount,
  isPastServicingPaymentCutOffDate,
  modalProps,
  navigation,
  otpAllowedOperation,
  otpData,
  paymentHomeLoading,
  paymentHomeServiceError,
  respaLetterError,
  respaLetterId,
  status,
  transferStatusBannerProps,
  userDisplayName,
}) => (
  <Fragment>
    <AsyncRender
      Component={HomeMain}
      error={paymentHomeServiceError}
      errorComponentProps={{ bodySize: 'small', style: { flexGrow: 0 } }}
      errorComponentType="cardLevel"
      loading={(
        ['idle', 'loading'].includes(paymentHomeLoading) ||
        ['idle', 'loading'].includes(fetchTransferRequestStatus)
      )}
      LoadingComponent={HomeMainSkeleton}
      propsToPassDown={{
        address,
        autopayAllowedOperation,
        autopayStatus,
        autopayStatusProps,
        customerServicePhoneNumber,
        dispatchDismissDocUploadStatusBanner,
        dispatchResetMainError,
        docUploadNotificationFailure,
        fetchStatusLoading,
        handleRowPress,
        isAutopayEnabledOrPending,
        isLoanMancodeY,
        isPastServicingPaymentCutOffDate,
        modalProps,
        navigation,
        otpAllowedOperation,
        otpData,
        respaLetterError,
        respaLetterId,
        status,
        transferStatusBannerProps,
        userDisplayName,
      }}
    />
    <SecondaryDivider />
    <AsyncRender
      Component={HomeHistory}
      error={homeHistoryError}
      errorComponentProps={{ bodySize: 'small', style: { flexGrow: 0 } }}
      errorComponentType="cardLevel"
      loading={homeHistoryLoading}
      LoadingComponent={HomeHistorySkeleton}
      propsToPassDown={{ historyData }}
    />
    <SecondaryDivider />
    <WhatsNew
      isEligibleForAutopay={isEligibleForAutopay}
      isEligibleForPaperlessBilling={isEligibleForPaperlessBilling}
      isMurabahaAccount={isMurabahaAccount}
      navigation={navigation}
    />
  </Fragment>
);

PaymentHomeView.propTypes = {
  address: T.string.isRequired,
  autopayAllowedOperation: T.string,
  autopayStatus: T.string.isRequired,
  autopayStatusProps: T.shape({
    button: T.object,
    iconType: T.string.isRequired,
    message: T.string.isRequired,
  }),
  customerServicePhoneNumber: T.string.isRequired,
  dispatchDismissDocUploadStatusBanner: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  docUploadNotificationFailure: T.object,
  fetchStatusLoading: T.bool.isRequired,
  fetchTransferRequestStatus: T.string.isRequired,
  handleRowPress: T.func.isRequired,
  historyData: T.array.isRequired,
  homeHistoryError: T.bool.isRequired,
  homeHistoryLoading: T.bool.isRequired,
  isAutopayEnabledOrPending: T.bool.isRequired,
  isEligibleForAutopay: T.bool.isRequired,
  isEligibleForPaperlessBilling: T.bool.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  modalProps: T.object.isRequired,
  navigation: T.object.isRequired,
  otpAllowedOperation: T.string.isRequired,
  otpData: T.object.isRequired,
  paymentHomeLoading: T.string.isRequired,
  paymentHomeServiceError: T.oneOfType([T.bool, T.string]).isRequired,
  respaLetterError: T.oneOfType([T.bool, T.string]).isRequired,
  respaLetterId: T.oneOfType([T.bool, T.string]),
  status: T.string.isRequired,
  transferStatusBannerProps: T.shape({
    shouldTransferBannerDisplay: T.bool.isRequired,
    transferStatusBannerText: T.string.isRequired,
    transferStatusBannerTitle: T.string.isRequired,
  }),
  userDisplayName: T.string.isRequired,
};

export default PaymentHomeView;
