/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function HamburgerMenuIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M1 3H23" stroke="#282828" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
      <Path d="M1 12H23" stroke="#282828" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
      <Path d="M1 21H23" stroke="#282828" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.8" />
    </Svg>
  );
}
