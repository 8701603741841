import React from 'react';
import T from 'prop-types';

import { CirclePrimitive, StyledAnimatedView } from './styledComponents';

const Circle = ({ opacity, rotate }) => (
  // styled-components can't handle Animated.Value, so must uniquely apply opacity via inline style
  <StyledAnimatedView $rotate={rotate} style={{ opacity }}>
    <CirclePrimitive />
  </StyledAnimatedView>
);

Circle.propTypes = {
  opacity: T.object.isRequired, // AnimatedInterpolation from an Animated.Value
  rotate: T.number.isRequired,
};

export default Circle;
