import React from 'react';
import { ScrollView } from 'react-native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { Skeleton } from '../../../../base_ui';
import { RowView, SkeletonView } from '../styledComponents';
import SkeletonItem from './SkeletonItem';

const SkeletonArray = [...Array(5).keys()];

const InboxListSkeleton = () => (
  <ScrollView>
    <RowView>
      <SkeletonView $widthPercent={100}>
        <Skeleton
          $bottomMargin={`${moderateScale(15)}px`}
          $leftMargin={'auto'}
          $rightMargin={'auto'}
          height={`${moderateScale(85)}px`}
          width={`${moderateScale(85)}px`}
        />
        <Skeleton
          $bottomMargin={`${moderateScale(15)}px`}
          $leftMargin={'auto'}
          $rightMargin={'auto'}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(250)}px`}
        />
        <Skeleton
          $bottomMargin={`${moderateScale(15)}px`}
          $leftMargin={'auto'}
          $rightMargin={'auto'}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(280)}px`}
        />
      </SkeletonView>
    </RowView>
    {SkeletonArray.map((num) => (
      <SkeletonItem key={num} />
    ))}
  </ScrollView>
);

export default InboxListSkeleton;
