import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';

const ErrorIcon = iconDictionary('warning');

const ErrorCard = ({
  errorHeading = 'Unable to load messages.\nTry again later',
  errorMessage = 'An error has occurred while trying to retrieve your messages.',
}) => (
  <Message
    body={errorMessage}
    icon={ErrorIcon}
    title={errorHeading}
  />
);

ErrorCard.propTypes = {
  errorHeading: T.string,
  errorMessage: T.string,
};

export default ErrorCard;
