/* eslint-disable max-len */
import React from 'react';
import Svg, {
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function PaperlessBillingV1(props) {
  return (
    <Svg
      fill="#000"
      height={moderateScale(160)}
      viewBox="0 0 327 160"
      width={moderateScale(327)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G>
        <Path
          d="M-690.5-1071.2c-1.1-0.2-2.1-0.3-3.2-0.3c-6.6,0-12.4,3.2-16,8.1c-0.7,1-2,1.3-3.2,1c-1.4-0.5-2.9-0.7-4.5-0.7c-4.4,0-8.3,2-10.8,5.2c0,0-2.8,3.6-3,8.4h40.8L-690.5-1071.2z"
          display="none"
          fill="#EFF3F6"
        />
        <Path
          d="M-726.9-1059.1c0,0,4.2-3.4,11-1.9c6.8,1.6,7.6-0.1,9.7-2.5c2.1-2.5,10-6,15.9-4.3v-3.2c0,0-6.9-1.5-12.8,1.9c-5.8,3.4-6.7,6.1-7.6,6.5c-0.9,0.4-1.3,0.6-4.1-0.2s-9.2,0.2-12.4,3.9"
          display="none"
          fill="#DCE2E7"
        />
        <Path
          d="M-512-1049c0-0.2,0-0.4,0-0.6c0-4.2-3.4-7.6-7.6-7.6c-0.2,0-0.3,0-0.5,0c-2.5-4.3-7.1-7.1-12.4-7.1c-0.3,0-0.7,0-1,0c-1.4,0.1-2.8-0.6-3.5-1.8c-2.5-4.3-7.1-7.2-12.5-7.2v24.2"
          display="none"
          fill="#EFF3F6"
        />
        <Path
          d="M-690.5-1071.2c-1.1-0.2-2.1-0.3-3.2-0.3c-6.6,0-12.4,3.2-16,8.1c-0.7,1-2,1.3-3.2,1c-1.4-0.5-2.9-0.7-4.5-0.7c-4.4,0-8.3,2-10.8,5.2"
          display="none"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M-512-1049.4c0-0.2,0,0,0-0.2c0-4.2-3.4-7.6-7.6-7.6c-0.2,0-0.3,0-0.5,0c-2.5-4.3-7.1-7.1-12.4-7.1c-0.2,0-0.5,0-0.7,0c-1.6,0.1-3.1-0.7-3.9-2.1c-2.5-4.1-7.1-6.9-12.3-6.9"
          display="none"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path d="M-524.7-1057.6c0.5,0.5,0.9,1.1,1.3,1.8" display="none" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path d="M-532.5-1061.4c0,0,3.2,0.1,6.1,2.2" display="none" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path d="M226.6,107.2c0,0-3.5-1.1-6.5,1.7c-3.1,2.9-3.7,9.4-1.3,16.1l1.7,5.3c0.9,2.9,3.6,4.8,6.6,4.8l0.9-0.1" fill="#DCE2E7" />
        <Path d="M225.4,49.8c0,0-6,8.1-7.9,13.4s-2.2,14.6-1.4,16.1c0,0,4.4-0.5,4.6-1.2C221,77.3,217.7,60.5,225.4,49.8z" fill="#DCE2E7" />
        <Path d="M254.9,43.7c0,0,8.8,4.9,12.7,8.9s8,12.4,7.9,14.1c0,0-4.2,1.4-4.7,0.8S266.3,50.3,254.9,43.7z" fill="#DCE2E7" />
        <Path
          d="M202.7,113.1c0,0,1.6,7.5,8,9.8c6.3,2.3,8.1,2.1,8.1,2.1l2.6,7.1c0,0-8.2-3-13-7.4C203.5,120.4,202.7,113.1,202.7,113.1z"
          fill="#DCE2E7"
        />
        <Path
          d="M244.5,114.5c0,0,0.6,14.7,11,16.5l0.8,2c0,0-3,1.6-7.2,1c0,0-4.1-0.8-4.8-1.2C243.5,132.5,244.5,114.5,244.5,114.5z"
          fill="#DCE2E7"
        />
        <Path
          d="M240,106.6l1.9,4.2l19.3-10.3l-1.9-3.6l4.5-0.3l-1.8-2.4c0,0,4.8-4.3,5.8-6.5l-1.8-1.5l-0.3-5.9l-0.8,0l-1.2,2.1l-2.7-1.4l-2.7,9.2l-3.5,1.1l1.7,3.6l-4.3,0.7l2.9,3.8L240,106.6z"
          fill="#DCE2E7"
        />
        <Path
          d="M137.5,136.6h-5.2c-0.2,0-0.4-0.1-0.4-0.3c-0.2-1.7-1.6-3-3.3-3c-1.1,0-2.1,0.5-2.7,1.4c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.3-0.2c-0.6-1.6-2.1-2.7-3.8-2.7c-2.2,0-4.2,2-4.2,4.2c0,0.2-0.2,0.4-0.4,0.4h-12.1c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h11.7c0.2-2.5,2.4-4.6,4.9-4.6c1.8,0,3.4,1,4.3,2.6c0.8-0.8,1.8-1.2,2.9-1.2c2,0,3.6,1.4,4,3.3h4.9c0.2,0,0.4,0.2,0.4,0.4S137.8,136.6,137.5,136.6z"
        />
        <Path
          d="M308.5,36.4c0.7,0,1.1-0.9,0.7-1.4c-0.8-1-2.5-1.6-4.4-1.6c-0.3,0-0.6,0-0.9,0.1c-0.3,0-0.6-0.2-0.6-0.5v0c0-1.6-2.1-3-4.7-3c-0.7,0-1.4,0.1-2,0.3c-0.3,0.1-0.6-0.1-0.7-0.4c-0.5-2.4-3.2-4.3-6.3-4.3c-3.6,0-6.4,2.3-6.4,5.2c0,0.1,0,0.2,0,0.3c-0.1,0-0.3,0-0.5,0c-1.3,0-2.5,0.9-2.8,2.1c-0.1,0.3-0.4,0.4-0.6,0.3c-0.3-0.1-0.5-0.1-0.8-0.1c-0.9,0-1.8,0.4-2.3,1.1c-0.6,0.8-0.1,2,1,2H308.5z"
          fill="#E6EBEF"
        />
        <Path
          d="M212.5,31.9c2.1,0,3.5-2.1,2.7-4.1c-1.7-4.1-5.2-10-11.9-10.7c-2.8-0.3-5.4,0.8-7.5,2.2c-0.2,0.1-0.4,0.1-0.6-0.1c-1.4-1.3-3.5-2.1-5.8-2.1c-4.3,0-7.8,2.9-7.8,6.6v0c0,0.3-0.2,0.5-0.5,0.5c-0.2,0-0.4,0-0.6,0c-2.6,0-4.9,1.4-5.7,3.4c-0.1,0.2-0.4,0.4-0.6,0.3c-0.7-0.3-1.5-0.5-2.3-0.5c-1.9,0.1-3.2,1.1-4,2.2c-0.7,1,0,2.3,1.2,2.3L212.5,31.9z"
          fill="#E6EBEF"
        />
        <Path
          d="M46.9,96.2C46,96.1,45.1,96,44.1,96c-5.6,0-10.6,2.7-13.7,6.9c-0.6,0.8-1.7,1.2-2.7,0.8c-1.2-0.4-2.5-0.6-3.8-0.6c-3.7,0-7.1,1.7-9.2,4.4c0,0-2.4,3.1-2.6,7.2H47L46.9,96.2z"
          fill="#EFF3F6"
        />
        <Path
          d="M15.8,106.5c0,0,3.6-2.9,9.4-1.6s6.5-0.1,8.3-2.2c1.8-2.1,8.5-5.1,13.6-3.6v-2.8c0,0-5.9-1.3-10.9,1.6s-5.7,5.2-6.5,5.5s-1.1,0.5-3.5-0.2s-7.8,0.1-10.6,3.3"
          fill="#DCE2E7"
        />
        <Path
          d="M14.7,107.9c-0.1,0-0.2,0-0.2-0.1c-0.2-0.1-0.2-0.4-0.1-0.5c2.3-2.9,5.8-4.6,9.5-4.6c1.4,0,2.7,0.2,4,0.7c0.8,0.3,1.8,0,2.3-0.7c3.3-4.4,8.5-7.1,14-7.1c0.9,0,1.9,0.1,2.8,0.2c0.2,0,0.3,0.2,0.3,0.4c0,0.2-0.2,0.3-0.4,0.3c-0.9-0.1-1.8-0.2-2.7-0.2c-5.3,0-10.3,2.5-13.4,6.8c-0.7,1-2,1.3-3.1,0.9c-1.2-0.4-2.4-0.6-3.7-0.6c-3.5,0-6.7,1.6-8.9,4.3C14.9,107.9,14.8,107.9,14.7,107.9z"
        />
        <Path
          d="M183.7,114.8c0-0.2,0-0.3,0-0.5c0-3.6-2.9-6.5-6.5-6.5c-0.1,0-0.3,0-0.4,0c-2.1-3.6-6.1-6.1-10.6-6.1c-0.3,0-0.6,0-0.9,0c-1.2,0.1-2.4-0.5-3-1.5c-2.1-3.7-6.1-6.1-10.6-6.1v20.6"
          fill="#E6EBEF"
        />
        <Rect fill="#E6EBEF" height="8.5" width="104.2" x="52.4" y="106.4" />
        <Rect fill="#8A97A4" height="8.5" width="7.4" x="154.3" y="106.3" />
        <Rect fill="#BAC2CA" height="8.5" width="7.4" x="112.8" y="106.3" />
        <Rect fill="#8A97A4" height="8.5" width="7.4" x="82.2" y="106.3" />
        <Rect fill="#BAC2CA" height="8.5" width="7.4" x="47.3" y="106.3" />
        <Rect fill="#8A97A4" height="2" width="7.4" x="47.3" y="106.3" />
        <Rect fill="#8A97A4" height="2" width="7.4" x="112.6" y="106.3" />
        <Path
          d="M183.7,114.9C183.7,114.9,183.7,114.9,183.7,114.9c-0.2,0-0.4-0.2-0.4-0.4h0c0-0.1,0-0.1,0-0.1c0-3.4-2.7-6.1-6.1-6.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.3-0.2c-2.1-3.6-6.1-5.9-10.3-5.9c-0.2,0-0.4,0-0.6,0c-1.5,0.1-2.9-0.7-3.7-1.9c-2.2-3.6-6-5.7-10.2-5.7c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c4.5,0,8.5,2.3,10.8,6.1c0.6,1,1.8,1.6,3,1.6c0.2,0,0.4,0,0.6,0c4.4,0,8.5,2.3,10.8,6.1c0.1,0,0.1,0,0.2,0c3.8,0,6.9,3.1,6.9,6.9c0,0.1,0,0.2,0,0.2C184.1,114.7,183.9,114.9,183.7,114.9z M183.7,114.5L183.7,114.5L183.7,114.5L183.7,114.5z"
        />
        <Path
          d="M173.9,109.3c-0.1,0-0.2,0-0.2-0.1c-0.3-0.5-0.6-1-1-1.5c-0.1-0.1-0.1-0.3,0-0.4c0.1-0.1,0.3-0.1,0.4,0c0.4,0.5,0.8,1,1.1,1.6c0.1,0.1,0,0.3-0.1,0.3C174,109.3,174,109.3,173.9,109.3z"
        />
        <Path
          d="M171.5,106.4c-0.1,0-0.1,0-0.1,0c-2.4-1.8-5-1.9-5.1-1.9c-0.1,0-0.2-0.1-0.2-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,2.8,0.1,5.4,2c0.1,0.1,0.1,0.2,0.1,0.3C171.6,106.4,171.5,106.4,171.5,106.4z"
        />
        <Path d="M111.5,61.6H47v-2.1l65.1-0.3l3.9-3.6l0.1,2.9l-2.6,2.3C113,61.3,112.3,61.6,111.5,61.6z" fill="#BAC2CA" />
        <Polygon fill="#BAC2CA" points="40,55.6 69.9,25.9 140.1,25.9 109.9,55.7 100.3,55.7" />
        <Path d="M140.1,26.5H89.3c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h50.7c0.2,0,0.4,0.2,0.4,0.4S140.3,26.5,140.1,26.5z" />
        <Path
          d="M129.9,36.2l9.5-9.5c0.4-0.4,0.9-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6l27,28.8c0.7,0.8,0.6,1.9-0.2,2.5c-0.8,0.6-2.1,0.6-2.8-0.2l-25.6-27.2l-28,28c-0.4,0.4-0.9,0.6-1.5,0.6c-0.5,0-1-0.2-1.3-0.5c-0.8-0.7-0.9-1.8-0.1-2.5L127,39"
          fill="#DCE2E7"
        />
        <Polygon fill="#E6EBEF" points="140.8,30.8 116.4,55.1 116.4,106.4 161.3,106.4 161.3,58.6 166.8,58.6" />
        <Polyline fill="#BAC2CA" points="126,106.3 126,60.8 153.1,60.8 153.1,106.2" />
        <Rect fill="#8A97A4" height="42.5" width="20.6" x="129.3" y="63.7" />
        <Polygon fill="#BAC2CA" points="139,32.8 161.5,56.6 161.5,53.1 140.8,31" />
        <Polygon fill="#BAC2CA" points="161.5,52.9 161.5,58.6 167,58.7" />
        <Path
          d="M96.4,56.1H39.8c-0.2,0-0.3-0.1-0.3-0.2c-0.1-0.1,0-0.3,0.1-0.4l30.1-29.8c0.1-0.1,0.2-0.1,0.3-0.1h12.7c0.2,0,0.4,0.2,0.4,0.4s-0.2,0.4-0.4,0.4H70l-29.3,29h55.7c0.2,0,0.4,0.2,0.4,0.4S96.6,56.1,96.4,56.1z"
        />
        <Path d="M111.8,59.6H40.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h71.3c0.2,0,0.4,0.2,0.4,0.4S112,59.6,111.8,59.6z" />
        <Path
          d="M47.3,106.4c-0.2,0-0.4-0.2-0.4-0.4V84.1c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v21.9C47.6,106.3,47.5,106.4,47.3,106.4z"
        />
        <Path d="M47.3,82c-0.2,0-0.4-0.2-0.4-0.4V60c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v21.7C47.6,81.9,47.5,82,47.3,82z" />
        <Path
          d="M116.4,114.9c-0.1,0-0.2-0.1-0.2-0.2v-8.3c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v8.3C116.6,114.8,116.5,114.9,116.4,114.9z"
        />
        <Path
          d="M157.7,114.9c-0.1,0-0.2-0.1-0.2-0.2v-8.3c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v8.3C157.9,114.8,157.8,114.9,157.7,114.9z"
        />
        <Path
          d="M116.4,106.8c-0.2,0-0.4-0.2-0.4-0.4v-21c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v21C116.7,106.6,116.6,106.8,116.4,106.8z"
        />
        <Path d="M116.3,82c-0.2,0-0.4-0.2-0.4-0.4V55c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v26.6C116.6,81.8,116.5,82,116.3,82z" />
        <Path
          d="M161.6,106.4c-0.2,0-0.4-0.2-0.4-0.4V54c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v52C161.9,106.3,161.8,106.4,161.6,106.4z"
        />
        <Path d="M167.1,58.9h-5.6c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h5.6c0.2,0,0.4,0.2,0.4,0.4S167.3,58.9,167.1,58.9z" />
        <Path d="M201.5,115.4H4.5c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h197c0.2,0,0.4,0.2,0.4,0.4S201.8,115.4,201.5,115.4z" />
        <Path d="M297.9,115.4H283c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h14.9c0.2,0,0.4,0.2,0.4,0.4S298.1,115.4,297.9,115.4z" />
        <Path d="M321.6,115.4h-16.4c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h16.4c0.2,0,0.4,0.2,0.4,0.4S321.8,115.4,321.6,115.4z" />
        <Path
          d="M111.3,59.6c-0.6,0-1.1-0.2-1.6-0.6c-0.5-0.4-0.8-0.9-0.8-1.5c0-0.6,0.2-1.1,0.6-1.6L126,39.5c0.1-0.1,0.4-0.1,0.5,0c0.1,0.1,0.1,0.4,0,0.5l-16.5,16.4c-0.3,0.3-0.4,0.6-0.4,1c0,0.4,0.2,0.7,0.5,1c0.6,0.5,1.7,0.5,2.3-0.1l28-28c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0.1l25.6,27.2c0.6,0.6,1.6,0.7,2.3,0.2c0.3-0.2,0.5-0.6,0.5-1c0-0.4-0.1-0.7-0.4-1l-27-28.8c-0.3-0.3-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5l-9.2,9.1c-0.1,0.1-0.4,0.1-0.5,0s-0.1-0.4,0-0.5l9.2-9.1c0.4-0.4,1.1-0.7,1.8-0.7c0.7,0,1.3,0.3,1.7,0.8l27,28.8c0.4,0.4,0.6,1,0.6,1.6c0,0.6-0.3,1.1-0.8,1.5c-1,0.8-2.5,0.6-3.3-0.2l-25.3-26.9L113,58.9C112.6,59.4,111.9,59.6,111.3,59.6z"
        />
        <Path
          d="M127.8,38.6c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.4,0-0.5l0.9-0.9c0.1-0.1,0.4-0.1,0.5,0s0.1,0.4,0,0.5l-0.9,0.9C128,38.6,127.9,38.6,127.8,38.6z"
        />
        <Path
          d="M43.5,59.6h-2.6c-1.3,0-2.3-1-2.3-2.1c0-1.2,1-2.1,2.3-2.1h2.6c0.2,0,0.4,0.2,0.4,0.4s-0.2,0.4-0.4,0.4h-2.6c-0.9,0-1.6,0.6-1.6,1.4s0.7,1.4,1.6,1.4h2.6c0.2,0,0.4,0.2,0.4,0.4S43.7,59.6,43.5,59.6z"
        />
        <Path d="M24.4,115.4c0-1.8,1.5-3.4,3.2-3.4c1.4,0,2.5,0.9,3,2.2c0.5-0.7,1.3-1.2,2.1-1.2c1.4,0,2.5,1.1,2.6,2.5" fill="#FFFFFF" />
        <Path
          d="M35.4,115.5c-0.2,0-0.4-0.1-0.4-0.3c-0.1-1.1-1.1-2-2.2-2c-0.7,0-1.4,0.4-1.8,1c-0.1,0.1-0.2,0.2-0.4,0.2c-0.1,0-0.3-0.1-0.3-0.2c-0.4-1.1-1.5-1.8-2.6-1.8c-1.5,0-2.9,1.3-2.9,2.9c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4c0-2,1.7-3.6,3.6-3.6c1.3,0,2.4,0.7,3.1,1.8c0.6-0.5,1.3-0.8,2.1-0.8c1.5,0,2.8,1.2,3,2.7C35.8,115.3,35.6,115.5,35.4,115.5C35.4,115.5,35.4,115.5,35.4,115.5z"
        />
        <Path d="M110.1,56.1H99.7c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h10.4c0.2,0,0.4,0.2,0.4,0.4S110.3,56.1,110.1,56.1z" />
        <Path d="M116,68.2H81.3c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2H116c0.1,0,0.2,0.1,0.2,0.2S116.1,68.2,116,68.2z" />
        <Path d="M78.7,68.2H52.5c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h26.2c0.1,0,0.2,0.1,0.2,0.2S78.9,68.2,78.7,68.2z" />
        <Path d="M116,87.1H74.1c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2H116c0.1,0,0.2,0.1,0.2,0.2S116.1,87.1,116,87.1z" />
        <Path d="M69.8,87.1H52.5c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h17.2c0.1,0,0.2,0.1,0.2,0.2S69.9,87.1,69.8,87.1z" />
        <Path d="M116,97.1H96.6c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2H116c0.1,0,0.2,0.1,0.2,0.2S116.1,97.1,116,97.1z" />
        <Path d="M93.2,97.1H47.3c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h45.8c0.1,0,0.2,0.1,0.2,0.2S93.3,97.1,93.2,97.1z" />
        <Path d="M111.2,77.6h-8.5c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h8.5c0.1,0,0.2,0.1,0.2,0.2S111.3,77.6,111.2,77.6z" />
        <Path d="M100.1,77.6H47.7c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h52.5c0.1,0,0.2,0.1,0.2,0.2S100.3,77.6,100.1,77.6z" />
        <Path
          d="M126,106.7c-0.2,0-0.4-0.2-0.4-0.4V60.8c0-0.2,0.2-0.4,0.4-0.4h27.1c0.2,0,0.4,0.2,0.4,0.4v45.4c0,0.2-0.2,0.4-0.4,0.4s-0.4-0.2-0.4-0.4v-45h-26.3v45.1C126.4,106.5,126.3,106.7,126,106.7z"
        />
        <Path
          d="M149.9,106.4c-0.1,0-0.2-0.1-0.2-0.2V64.1h-20.1v42c0,0.1-0.1,0.2-0.2,0.2s-0.2-0.1-0.2-0.2V63.8c0-0.1,0.1-0.2,0.2-0.2h20.6c0.1,0,0.2,0.1,0.2,0.2v42.3C150.2,106.3,150,106.4,149.9,106.4z"
        />
        <Path d="M161.7,106.8H47.3c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h114.4c0.2,0,0.4,0.2,0.4,0.4S161.9,106.8,161.7,106.8z" />
        <Path
          d="M120.1,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C120.4,114.9,120.3,115.1,120.1,115.1z"
        />
        <Path
          d="M112.8,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C113.1,114.9,113,115.1,112.8,115.1z"
        />
        <Path
          d="M50.9,114.9c-0.1,0-0.2-0.1-0.2-0.2v-8.3c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v8.3C51.2,114.8,51.1,114.9,50.9,114.9z"
        />
        <Path d="M54.6,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C55,114.9,54.8,115.1,54.6,115.1z" />
        <Path
          d="M47.3,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C47.7,114.9,47.5,115.1,47.3,115.1z"
        />
        <Path d="M85.7,114.9c-0.1,0-0.2-0.1-0.2-0.2v-8.3c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v8.3C86,114.8,85.9,114.9,85.7,114.9z" />
        <Path
          d="M89.4,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C89.8,114.9,89.6,115.1,89.4,115.1z"
        />
        <Path
          d="M82.1,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C82.5,114.9,82.3,115.1,82.1,115.1z"
        />
        <Path
          d="M161.6,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C162,114.9,161.8,115.1,161.6,115.1z"
        />
        <Path
          d="M154.3,115.1c-0.2,0-0.4-0.2-0.4-0.4v-8.2c0-0.2,0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4v8.2C154.7,114.9,154.5,115.1,154.3,115.1z"
        />
        <Path
          d="M12.1,115.1C12.1,115.1,12.1,115.1,12.1,115.1c-0.2,0-0.4-0.2-0.4-0.4c0.3-3.5,1.4-5.3,1.5-5.3c0.1-0.2,0.3-0.2,0.5-0.1c0.2,0.1,0.2,0.3,0.1,0.5c0,0-1.1,1.7-1.4,5C12.5,114.9,12.3,115.1,12.1,115.1z"
        />
        <G>
          <Line fill="#FFFFFF" x1="247.1" x2="245.3" y1="78.9" y2="70.2" />
          <Path
            d="M247.1,79.3c-0.2,0-0.3-0.1-0.4-0.3l-1.8-8.8c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.3l1.8,8.8C247.5,79.1,247.4,79.3,247.1,79.3C247.1,79.3,247.1,79.3,247.1,79.3z"
          />
        </G>
        <Path
          d="M251.5,62.1c-0.4-1.9-4.1-2.8-8.3-1.9c-4.2,0.9-7.3,3.2-6.9,5.1c0,0.2,0.1,0.4,0.2,0.5c0.7,1.4,3,5.2,8.6,4.9l0,0.1l0.3-0.1l0.3,0l0-0.1C252.2,68.2,251.5,62.1,251.5,62.1z"
        />
        <Path
          d="M215.6,80c-2.4,0-5.3-0.7-7.1-3.7c-3.8-6.3-1.6-15.1,6.6-26.1c9-12,16.4-10.4,16.7-10.3c0.3,0.1,0.4,0.3,0.4,0.6c-0.1,0.3-0.3,0.4-0.6,0.4l0,0c-0.1,0-7.1-1.5-15.7,10c-7.9,10.6-10.1,19-6.6,25c3.1,5.1,9.9,2.7,10.1,2.6c0.3-0.1,0.5,0,0.6,0.3c0.1,0.3,0,0.5-0.3,0.6C219.9,79.3,218,80,215.6,80z"
        />
        <Path
          d="M244.2,96.4c-6.8,0-17.6-1.9-22.2-13c-6.8-16.6,3.2-34.2,3.3-34.4c0.1-0.2,0.4-0.3,0.7-0.2c0.2,0.1,0.3,0.4,0.2,0.7c-0.1,0.2-9.9,17.4-3.2,33.5c6.6,15.9,26.7,11.9,26.9,11.9c0.3-0.1,0.5,0.1,0.6,0.4s-0.1,0.5-0.4,0.6C250,95.9,247.6,96.4,244.2,96.4z"
        />
        <Path
          d="M271.8,68.5c-0.3,0-0.5-0.2-0.5-0.5c0-0.3,0.2-0.5,0.5-0.5c0.3,0,7.5-0.5,8.3-6.4c0.9-6.9-4.5-13.7-16-20.2c-12.4-7.1-18.3-2.9-18.3-2.9c-0.2,0.1-0.4,0.1-0.5,0c0,0-3.1-1.5-7-0.7c-3.9,0.8-5.6,3.4-5.6,3.4c-0.2,0.2-0.5,0.3-0.7,0.1c-0.2-0.2-0.3-0.5-0.1-0.7c0.1-0.1,2-2.9,6.3-3.8c3.6-0.8,6.5,0.3,7.4,0.6c1.3-0.8,7.4-3.7,19.2,3c11.9,6.8,17.5,13.9,16.5,21.2C280.2,67.9,271.9,68.5,271.8,68.5C271.9,68.5,271.8,68.5,271.8,68.5z"
        />
        <Path
          d="M250.8,95.7c-0.2,0-0.4-0.2-0.5-0.4c-0.1-0.3,0.1-0.5,0.4-0.6c0.2,0,20.3-4.4,19.9-21.7c-0.4-17.4-16.2-29.3-16.4-29.4c-0.2-0.2-0.3-0.5-0.1-0.7c0.2-0.2,0.5-0.3,0.7-0.1c0.2,0.1,16.4,12.3,16.8,30.2c0.4,18.1-20.5,22.6-20.7,22.7C250.8,95.7,250.8,95.7,250.8,95.7z"
        />
        <Path
          d="M236.5,138.2h-1.9c-4.5,0-8.2-3.7-8.2-8.2v-30c0-0.2-0.1-5.1,3.1-7.9c0.2-0.2,0.5-0.2,0.7,0.1c0.2,0.2,0.2,0.5-0.1,0.7c-2.9,2.5-2.8,7.1-2.8,7.1v30c0,4,3.2,7.2,7.2,7.2h1.9c4,0,7.2-3.2,7.2-7.2v-17.3c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V130C244.7,134.5,241.1,138.2,236.5,138.2z"
        />
        <Path
          d="M265.1,138.2h-1.9c-4.5,0-8.2-3.7-8.2-8.2v-17.3c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5V130c0,4,3.2,7.2,7.2,7.2h1.9c4,0,7.2-3.2,7.2-7.2v-30c0-0.1,0.4-7.8-6.3-13.3c-0.2-0.2-0.2-0.5-0.1-0.7c0.2-0.2,0.5-0.2,0.7-0.1c7.2,5.9,6.7,13.8,6.7,14.1l0,30C273.3,134.5,269.6,138.2,265.1,138.2z"
        />
        <Path
          d="M229,135.6h-2.1c-3.2,0-6.1-2.1-7-5.2l-1.7-5.3c-2.4-6.9-1.8-13.6,1.5-16.7c3.2-3,6.9-1.9,7-1.8c0.3,0.1,0.4,0.4,0.3,0.6s-0.4,0.4-0.6,0.3c-0.1,0-3.2-1-6,1.6c-2.9,2.8-3.4,9.2-1.2,15.6l1.7,5.3c0.8,2.7,3.3,4.5,6.1,4.5h2.1c0.3,0,0.5,0.2,0.5,0.5S229.3,135.6,229,135.6z"
        />
        <Path
          d="M273,135.6h-2.1c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h2.1c2.8,0,5.2-1.8,6.1-4.5l1.7-5.3c2.2-6.5,1.8-12.9-1.2-15.6c-2.8-2.6-6-1.6-6-1.6c-0.3,0.1-0.5-0.1-0.6-0.3s0.1-0.5,0.3-0.6c0.2,0,3.8-1.2,7,1.8c3.3,3.1,3.9,9.8,1.5,16.7l-1.7,5.3C279.1,133.6,276.2,135.6,273,135.6z"
        />
        <Path
          d="M250.4,135c-3.9,0-6.9-1.3-7-1.4c-0.2-0.1-0.4-0.4-0.2-0.7c0.1-0.2,0.4-0.4,0.7-0.2c0.1,0,6.1,2.8,12.4,0.2c0.3-0.1,0.5,0,0.7,0.3c0.1,0.3,0,0.5-0.3,0.7C254.4,134.7,252.2,135,250.4,135z"
        />
        <Path
          d="M220.8,132c0,0-0.1,0-0.1,0c-23.9-6.3-18.7-28.3-18.6-28.5c0.1-0.2,0.2-0.4,0.4-0.4c0.2,0,0.4,0.1,0.5,0.3c0.1,0.1,5.8,11.9,13.8,13.7c0.3,0.1,0.4,0.3,0.4,0.6c-0.1,0.3-0.3,0.4-0.6,0.4c-6.9-1.5-12-9.7-13.8-13c-0.7,4.8-1.6,20.7,18.2,25.9c0.3,0.1,0.4,0.3,0.4,0.6C221.3,131.9,221.1,132,220.8,132z"
        />
        <Path
          d="M229.4,61C229.4,61,229.4,61,229.4,61c-0.2,0-0.4-0.2-0.4-0.4c0-0.8,0.5-2.3,2.2-2.8c2.2-0.7,3.4,0.8,3.5,0.8c0.1,0.2,0.1,0.4,0,0.5c-0.2,0.1-0.4,0.1-0.5,0c0,0-1-1.1-2.7-0.6c-1.7,0.5-1.7,2.1-1.7,2.1C229.7,60.8,229.6,61,229.4,61z"
        />
        <Path
          d="M250.4,56.8C250.4,56.8,250.4,56.8,250.4,56.8c-0.3,0-0.4-0.2-0.4-0.4c0.1-0.7,0.8-2.2,2.6-2.5c2.2-0.4,3.3,1.2,3.3,1.3c0.1,0.2,0.1,0.4-0.1,0.5c-0.2,0.1-0.4,0.1-0.5-0.1c0,0-0.8-1.2-2.6-1c-1.7,0.3-1.9,1.8-2,1.9C250.7,56.7,250.6,56.8,250.4,56.8z"
        />
        <G>
          <Polygon
            fill="#FFFFFF"
            points="262.8,69.5 227.2,85.8 237.3,107.7 254.9,99.6 252.5,95.6 256.2,94.8 255.2,91.3 257.7,90.4 260.9,81.1 263.7,82.7 264.7,80.4 267.8,80.4"
          />
          <Path
            d="M237.3,108.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.3l-10-21.9c-0.1-0.1-0.1-0.3,0-0.4c0-0.1,0.1-0.2,0.3-0.3L262.6,69c0.3-0.1,0.5,0,0.7,0.2l5,11c0.1,0.2,0.1,0.3,0,0.5c-0.1,0.1-0.3,0.2-0.4,0.2l-2.8-0.1l-0.8,2c-0.1,0.1-0.2,0.2-0.3,0.3c-0.1,0.1-0.3,0-0.4,0l-2.3-1.3l-3,8.8c-0.1,0.1-0.2,0.3-0.3,0.3l-2.1,0.7l0.9,3.1c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.2,0.2-0.3,0.2l-3,0.6l2,3.4c0.1,0.1,0.1,0.3,0,0.4c0,0.1-0.1,0.2-0.3,0.3l-17.6,8.1C237.4,108.2,237.3,108.2,237.3,108.2z M227.9,86l9.6,21l16.7-7.6l-2.1-3.6c-0.1-0.1-0.1-0.3,0-0.5c0.1-0.1,0.2-0.3,0.4-0.3l3.2-0.7l-0.9-3c-0.1-0.3,0.1-0.5,0.3-0.6l2.3-0.8l3.1-9.1c0-0.1,0.2-0.3,0.3-0.3c0.1-0.1,0.3,0,0.4,0l2.3,1.3l0.7-1.8c0.1-0.2,0.3-0.3,0.5-0.3l2.3,0l-4.5-9.8L227.9,86z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="231,86.5 252,88.7 259.6,76.4" />
          <Path
            d="M252,89.1C252,89.1,252,89.1,252,89.1l-21-2.2c-0.2,0-0.4-0.2-0.3-0.4c0-0.2,0.2-0.3,0.4-0.3l20.7,2.2l7.5-12.1c0.1-0.2,0.3-0.2,0.5-0.1c0.2,0.1,0.2,0.3,0.1,0.5l-7.7,12.3C252.2,89.1,252.1,89.1,252,89.1z"
          />
        </G>
        <G>
          <Path d="M227.7,75.1c0,0,0,3.9,6.8,5c6.7,1.1,12.7-0.4,11.9-4.5" fill="#FFFFFF" />
          <Path
            d="M238.5,80.8c-1.3,0-2.7-0.1-4.1-0.4c-6.9-1.2-7.1-5.2-7.1-5.4c0-0.2,0.2-0.4,0.4-0.4c0,0,0,0,0,0c0.2,0,0.4,0.2,0.4,0.4c0,0.2,0.2,3.6,6.4,4.6c4.8,0.8,9,0.2,10.8-1.5c0.7-0.7,0.9-1.5,0.7-2.5c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.3c0.2,1.2-0.1,2.3-0.9,3.1C244.5,80,241.8,80.8,238.5,80.8z"
          />
        </G>
        <G>
          <Path d="M263.4,67.8c0,0,1.4,3.6-4.3,7.2c-5.8,3.6-11.9,4.5-12.7,0.5" fill="#FFFFFF" />
          <Path
            d="M250.3,78.5c-0.8,0-1.6-0.1-2.2-0.4c-1.1-0.5-1.8-1.3-2.1-2.5c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.3c0.2,1,0.8,1.6,1.6,2c2.3,0.9,6.4-0.1,10.5-2.7c5.4-3.4,4.2-6.6,4.2-6.7c-0.1-0.2,0-0.4,0.2-0.5c0.2-0.1,0.4,0,0.5,0.2c0.1,0.2,1.5,3.9-4.5,7.7C256.1,77.4,252.8,78.5,250.3,78.5z"
          />
        </G>
        <G>
          <Polygon
            fill="#FFFFFF"
            points="290.2,135.6 296.8,145 277.2,145.8 280.5,143.5 274.5,141.4 282.2,140.1 281.5,138.2 286.6,138"
          />
          <Path
            d="M277.2,146.2c-0.2,0-0.3-0.1-0.4-0.3c-0.1-0.2,0-0.3,0.1-0.4l2.7-1.9l-5.3-1.9c-0.2-0.1-0.3-0.2-0.2-0.4c0-0.2,0.1-0.3,0.3-0.3l7.2-1.2l-0.5-1.4c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.3-0.2l5-0.3l3.5-2.3c0.2-0.1,0.4-0.1,0.5,0.1l6.7,9.4c0.1,0.1,0.1,0.3,0,0.4s-0.2,0.2-0.3,0.2L277.2,146.2C277.2,146.2,277.2,146.2,277.2,146.2z M276,141.5l4.6,1.7c0.1,0,0.2,0.2,0.2,0.3c0,0.1,0,0.3-0.2,0.4l-2.3,1.6l17.7-0.7l-6-8.6l-3.3,2.2c-0.1,0-0.1,0.1-0.2,0.1l-4.6,0.3l0.5,1.4c0,0.1,0,0.2,0,0.3s-0.2,0.2-0.3,0.2L276,141.5z M286.6,138L286.6,138L286.6,138z"
          />
        </G>
        <G>
          <Polygon
            fill="#FFFFFF"
            points="197,132 180.2,137.6 194.5,143.4 199.2,141.9 201.8,146.8 204.7,147.7 217.5,140.7 210.1,139.8204.8,139.3 199.3,138.7 198.5,135.9"
          />
          <Path
            d="M204.7,148.1c0,0-0.1,0-0.1,0l-2.9-0.9c-0.1,0-0.2-0.1-0.2-0.2l-2.5-4.7l-4.4,1.5c-0.1,0-0.2,0-0.3,0l-14.3-5.9c-0.1-0.1-0.2-0.2-0.2-0.4c0-0.2,0.1-0.3,0.3-0.3l16.8-5.6c0.2-0.1,0.4,0,0.5,0.2l1.4,4l0.8,2.5l10.5,1.1l7.4,0.9c0.2,0,0.3,0.1,0.3,0.3c0,0.2,0,0.3-0.2,0.4l-12.8,7C204.8,148.1,204.8,148.1,204.7,148.1z M202.1,146.5l2.6,0.8l11.6-6.4l-6.3-0.8l-10.8-1.1c-0.1,0-0.3-0.1-0.3-0.3l-0.8-2.7l-1.3-3.6l-15.5,5.2l13.3,5.4l4.6-1.5c0.2-0.1,0.4,0,0.5,0.2L202.1,146.5z"
          />
        </G>
        <G>
          <Polyline fill="#FFFFFF" points="184.5,140.5 178.8,143.8 201.5,146.7" />
          <Path
            d="M201.5,147C201.4,147,201.4,147,201.5,147l-22.7-2.8c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0-0.3,0.2-0.4l5.8-3.3c0.2-0.1,0.4,0,0.5,0.1c0.1,0.2,0,0.4-0.1,0.5l-4.8,2.8l21.6,2.7c0.2,0,0.4,0.2,0.3,0.4C201.8,146.9,201.6,147,201.5,147z"
          />
        </G>
        <G>
          <Polygon fill="#DCE2E7" points="190.8,126.3 184,129.7 183.1,126.7 174.3,127 179.3,125 172.9,122.2 193.8,124.8" />
          <Path
            d="M184,130.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2l-0.8-2.7l-8.5,0.3c-0.2,0-0.3-0.1-0.4-0.3c0-0.2,0.1-0.4,0.2-0.4l4.1-1.6l-5.6-2.5c-0.2-0.1-0.3-0.3-0.2-0.4c0.1-0.2,0.2-0.3,0.4-0.3l20.9,2.6c0.2,0,0.3,0.1,0.3,0.3c0,0.2-0.1,0.3-0.2,0.4l-3,1.6c0,0,0,0,0,0l-6.8,3.4C184.1,130.1,184,130.1,184,130.1z M183.1,126.4c0.2,0,0.3,0.1,0.4,0.3l0.8,2.6l8.3-4.2l-17.2-2.1l4.1,1.8c0.1,0.1,0.2,0.2,0.2,0.4c0,0.2-0.1,0.3-0.2,0.3l-3,1.2L183.1,126.4C183.1,126.4,183.1,126.4,183.1,126.4z M190.8,126.3L190.8,126.3L190.8,126.3z"
          />
        </G>
        <G>
          <Path d="M197.8,131.8c0,0,4.9,3,8,4.5c3.1,1.5,11.3,4.2,11.3,4.2" fill="#FFFFFF" />
          <Path
            d="M217.1,140.9c0,0-0.1,0-0.1,0c-0.3-0.1-8.3-2.7-11.4-4.2c-3-1.5-7.9-4.5-8-4.5c-0.2-0.1-0.2-0.3-0.1-0.5s0.3-0.2,0.5-0.1c0,0,4.9,3,7.9,4.5c3,1.5,11.2,4.1,11.3,4.2c0.2,0.1,0.3,0.3,0.2,0.5C217.4,140.8,217.3,140.9,217.1,140.9z"
          />
        </G>
        <G>
          <Polygon
            fill="#FFFFFF"
            points="226.6,141.5 220.7,145.6 226.7,145.2 227.4,147.3 230.1,144.4 231.3,145.5 237.7,143.6 232.2,142.9228.5,139.9"
          />
          <Path
            d="M227.4,147.7C227.4,147.7,227.3,147.7,227.4,147.7c-0.2,0-0.3-0.1-0.4-0.3l-0.6-1.9l-5.7,0.4c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.2,0-0.3,0.1-0.4l5.9-4.1l1.8-1.6c0.1-0.1,0.3-0.1,0.5,0l3.6,3l5.4,0.7c0.2,0,0.3,0.2,0.3,0.3c0,0.2-0.1,0.3-0.3,0.4l-6.4,1.9c-0.1,0-0.3,0-0.4-0.1l-0.9-0.8l-2.5,2.7C227.6,147.7,227.5,147.7,227.4,147.7z M226.7,144.8c0.2,0,0.3,0.1,0.4,0.3l0.5,1.5l2.3-2.5c0.1-0.2,0.4-0.2,0.5,0l1,0.9l4.4-1.3l-3.7-0.5c-0.1,0-0.1,0-0.2-0.1l-3.5-2.8l-1.6,1.4c0,0,0,0,0,0l-4.8,3.3L226.7,144.8C226.7,144.8,226.7,144.8,226.7,144.8z M226.6,141.5L226.6,141.5L226.6,141.5z"
          />
        </G>
        <G>
          <Path d="M257.7,142c-0.7,0.2-5.1,1.5-6.7,1.5l4,2.9l3.5-2.2h4.9L257.7,142z" fill="#DCE2E7" />
          <Path
            d="M255.1,146.7c-0.1,0-0.2,0-0.2-0.1l-4-2.9c-0.1-0.1-0.2-0.3-0.1-0.4s0.2-0.3,0.4-0.3c0.9,0,3.3-0.5,6.5-1.4c0.1,0,0.2,0,0.3,0l5.8,2.2c0.2,0.1,0.3,0.2,0.2,0.4s-0.2,0.3-0.4,0.3h-4.8l-3.4,2.2C255.2,146.7,255.1,146.7,255.1,146.7z M252.1,143.7l3,2.2l3.3-2.1c0.1,0,0.1-0.1,0.2-0.1h2.8l-3.7-1.4C256.1,142.8,253.7,143.5,252.1,143.7z M257.7,142l0.1,0.4L257.7,142z"
          />
        </G>
        <G>
          <Polygon fill="#DCE2E7" points="303.1,125.1 291.2,125.1 295.5,126.5 286.7,128.9 303,130.6 304.2,132.4 309.2,127.9" />
          <Path
            d="M304.2,132.8C304.2,132.8,304.1,132.8,304.2,132.8c-0.2,0-0.3-0.1-0.3-0.2l-1.1-1.6l-16.2-1.7c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.1-0.3,0.3-0.4l7.6-2l-3.1-1c-0.2-0.1-0.3-0.2-0.3-0.4s0.2-0.3,0.4-0.3h11.9c0.1,0,0.1,0,0.2,0l6.1,2.7c0.1,0.1,0.2,0.2,0.2,0.3s0,0.3-0.1,0.3l-5,4.5C304.4,132.7,304.3,132.8,304.2,132.8z M288.7,128.7l14.3,1.5c0.1,0,0.2,0.1,0.3,0.2l0.9,1.4l4.2-3.8l-5.5-2.5h-9.4l2,0.6c0.2,0.1,0.3,0.2,0.3,0.4c0,0.2-0.1,0.3-0.3,0.4L288.7,128.7z"
          />
        </G>
        <Path d="M71.6,136.4h-5.8c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h5.8c0.2,0,0.4,0.2,0.4,0.4S71.9,136.4,71.6,136.4z" />
        <Path d="M89.4,136.4H76c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4h13.4c0.2,0,0.4,0.2,0.4,0.4S89.6,136.4,89.4,136.4z" />
      </G>
    </Svg>
  );
}
