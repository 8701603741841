/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HelpDog(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="#000" height={moderateScale(100)} viewBox="0 0 100 100" width={moderateScale(100)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G className="cls-1" isolation="isolate">
        <G data-name="Layer 1" id="Layer_1">
          <Path className="cls-2" d="M69.31,44.93c-7.71-6.79-12.73-5.34-13.63-5a8,8,0,0,0-4.92-1.6,9.67,9.67,0,0,0-5.21,1.52c-1.33-.41-6.2-1.28-13.41,5.08-7.6,6.69-10.53,12.73-8.72,17.94,1.14,3.26,4.4,4.09,6.7,4.09a9.59,9.59,0,0,0,1.27-.08.82.82,0,0,0,.24-.1,22.33,22.33,0,0,0,.36,3.1c2.48,13.19,18.29,13.35,18.45,13.35H51c.16,0,16-.16,18.45-13.35a22.33,22.33,0,0,0,.36-3.1.64.64,0,0,0,.24.1,9.32,9.32,0,0,0,1.26.08c2.31,0,5.57-.83,6.7-4.09C79.83,57.66,76.9,51.62,69.31,44.93Z" fill="#fff" />
          <Path className="cls-3" d="M57.81,71.34s-7.19,7.73-15.77,0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="50.17" x2="50.17" y1="74.51" y2="67.82" />
          <Circle cx="43.23" cy="56.41" r="1.33" />
          <Circle cx="56.57" cy="56.41" r="1.33" />
          <Ellipse cx="50.14" cy="62.86" rx="5.81" ry="2.7" />
          <Path d="M54.88,62.61l1.07.25a6.36,6.36,0,0,1-5.66,5.35l-.37.08-.57-4.66Z" />
          <Path d="M45.39,62.61l-1.07.25A6.36,6.36,0,0,0,50,68.21l.38.08.57-4.66Z" />
          <Path className="cls-4" d="M55.92,40.09s5.06-2.38,13.39,5S79.63,58.15,78,62.71s-7.44,3.67-7.44,3.67" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" />
          <Path className="cls-4" d="M55.67,40.19a7.24,7.24,0,0,0-4.91-1.73,9.08,9.08,0,0,0-5.24,1.63s-5.05-2.38-13.39,5-10.31,13.1-8.72,17.66,7.43,3.67,7.43,3.67" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" />
          <Path className="cls-4" d="M39.77,45.64S29.72,56.84,32.19,70,50.43,83.1,50.43,83.1H51S66.79,83.1,69.26,70s-7.58-24.33-7.58-24.33" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.8" />
          <Path className="cls-5" d="M39.47,45.16S25,56.54,27.45,65.57c.36,1.36,4.16.45,4.16.45S34.06,50.94,39.47,45.16Z" fill="#e6ebef" mixblendmode="multiply" />
          <Path className="cls-5" d="M61.9,45.16s14.45,11.38,12,20.41c-.36,1.36-4.16.45-4.16.45S67.31,50.94,61.9,45.16Z" fill="#e6ebef" mixblendmode="multiply" />
          <Polygon className="cls-2" fill="#fff" points="74.31 68.17 66.26 73.03 66.14 73.03 58.2 68.17 50.14 73.03 50.03 73.03 42.09 68.17 34.03 73.03 33.92 73.03 25.98 68.17 17.92 73.03 9.98 68.17 1.92 73.03 0 71.74 0 100 100 100 99.96 71.67 98.08 72.82 90.14 67.96 82.25 73.03 74.31 68.17" />
          <Polygon className="cls-6" fill="#bac2ca" points="20.82 71.34 17.98 72.8 17.98 100 20.82 100 20.82 71.34" />
          <Polygon className="cls-6" fill="#bac2ca" points="36.79 71.34 33.94 72.82 33.94 100 36.79 100 36.79 71.34" />
          <Polygon className="cls-6" fill="#bac2ca" points="53 71.34 50.16 72.82 50.16 100 53 100 53 71.34" />
          <Polygon className="cls-6" fill="#bac2ca" points="69.13 71.34 66.28 72.82 66.28 100 69.13 100 69.13 71.34" />
          <Polygon className="cls-6" fill="#bac2ca" points="84.93 71.34 82.08 72.82 82.08 100 84.93 100 84.93 71.34" />
          <Polygon className="cls-6" fill="#bac2ca" points="4.78 71.34 1.93 72.8 1.93 100 4.78 100 4.78 71.34" />
          <Path className="cls-7" d="M54.54,23.42a1.41,1.41,0,0,0,1.06-2.33c-1.32-1.53-4-2.58-7-2.58a11.53,11.53,0,0,0-1.45.08.88.88,0,0,1-1-.88h0c0-2.65-3.36-4.81-7.51-4.81a11.05,11.05,0,0,0-3.23.47.83.83,0,0,1-1-.6c-.87-3.95-5.1-6.95-10.2-6.95-5.73,0-10.38,3.78-10.38,8.45,0,.17,0,.33,0,.5a4.4,4.4,0,0,0-.73-.06A4.8,4.8,0,0,0,8.52,18a.84.84,0,0,1-1,.56,4.76,4.76,0,0,0-1.31-.18,4.82,4.82,0,0,0-3.77,1.81A2,2,0,0,0,4,23.42Z" fill="#e6ebef" />
          <Path className="cls-7" d="M95.29,33A1,1,0,0,0,96,31.37a6.51,6.51,0,0,0-4.87-1.8,8.43,8.43,0,0,0-1,.06.61.61,0,0,1-.66-.61h0c0-1.85-2.34-3.35-5.22-3.35A7.62,7.62,0,0,0,82,26a.58.58,0,0,1-.73-.42c-.6-2.74-3.54-4.82-7.08-4.82-4,0-7.2,2.63-7.2,5.87,0,.11,0,.23,0,.34a2.92,2.92,0,0,0-.51,0,3.34,3.34,0,0,0-3.18,2.31.58.58,0,0,1-.72.39,3.3,3.3,0,0,0-3.52,1.14A1.36,1.36,0,0,0,60.19,33Z" fill="#e6ebef" />
          <Polyline className="cls-8" fill="none" points="17.98 72.82 26.03 67.96 33.97 72.82" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
          <Polyline className="cls-8" fill="none" points="17.98 72.82 10.04 67.96 1.98 72.82" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
          <Polyline className="cls-8" fill="none" points="34.09 72.82 42.15 67.96 50.09 72.82" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
          <Polyline className="cls-8" fill="none" points="50.2 72.82 58.26 67.96 66.2 72.82" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
          <Polyline className="cls-8" fill="none" points="82.08 72.82 74.37 67.96 66.31 72.82" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
          <Polyline className="cls-8" fill="none" points="98.08 72.82 90.14 67.96 82.08 72.82" stroke="#000" strokeLinecap="round" strokeLinejoin="round" />
          <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" x1="99.96" x2="98.06" y1="71.67" y2="72.82" />
          <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" x1="1.77" y1="72.82" y2="71.74" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="66.31" x2="66.31" y1="72.82" y2="94.45" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="50.12" x2="50.12" y1="80.02" y2="100" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="33.97" x2="33.97" y1="86.95" y2="100" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="33.97" x2="33.97" y1="72.82" y2="83.03" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="17.98" x2="17.98" y1="76.07" y2="100" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="1.98" x2="1.98" y1="72.82" y2="100" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="82.08" x2="82.08" y1="84.1" y2="100" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="82.08" x2="82.08" y1="81.34" y2="82.48" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="82.08" x2="82.08" y1="73.06" y2="79.45" />
          <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.6" x1="98.08" x2="98.08" y1="72.82" y2="100" />
          <Path className="cls-10" d="M39.71,12.05a7.43,7.43,0,0,1,1.26-4,9.71,9.71,0,0,1,3.66-3.32,11.53,11.53,0,0,1,5.62-1.32,12,12,0,0,1,5.26,1.1,8.5,8.5,0,0,1,3.53,3,7.33,7.33,0,0,1,1.25,4.11,6.41,6.41,0,0,1-.71,3.07A9.8,9.8,0,0,1,57.89,17q-1,1-3.51,3.22a14.66,14.66,0,0,0-1.12,1.12,4.92,4.92,0,0,0-.63.88,5.44,5.44,0,0,0-.32.81c-.07.26-.18.73-.34,1.41a2.29,2.29,0,0,1-2.44,2.14,2.51,2.51,0,0,1-1.8-.7A2.74,2.74,0,0,1,47,23.8a7.57,7.57,0,0,1,.53-3A7.65,7.65,0,0,1,49,18.58c.59-.63,1.39-1.39,2.39-2.27s1.51-1.35,1.9-1.74a6,6,0,0,0,1-1.3,3.16,3.16,0,0,0,.4-1.57A3.64,3.64,0,0,0,53.4,8.92a4.46,4.46,0,0,0-3.15-1.13,4.4,4.4,0,0,0-3.34,1.14A9.36,9.36,0,0,0,45.1,12.3c-.46,1.55-1.35,2.32-2.65,2.32a2.59,2.59,0,0,1-1.94-.81A2.49,2.49,0,0,1,39.71,12.05Zm10,23.8A3.26,3.26,0,0,1,47.56,35a2.86,2.86,0,0,1-.93-2.28,2.94,2.94,0,0,1,.9-2.18,3.05,3.05,0,0,1,2.22-.88,3,3,0,0,1,3.07,3.06A2.88,2.88,0,0,1,51.89,35,3.1,3.1,0,0,1,49.75,35.85Z" fill={svgHighlight} stroke="#2c2627" strokeMiterlimit="10" strokeWidth="0.8" />
        </G>
      </G>
    </Svg>
  );
}
