import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import {
  BaseInternalLink,
  P2,
  P3,
  PrimaryDivider,
} from '../../../../base_ui';

export const ButtonContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: ${moderateScale(36)}px;
`;

export const CheckboxAlignmentContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-left: ${moderateScale(20)}px;
  width: ${moderateScale(104)}px;
`;

export const CheckboxAlignmentSubcontainer = styled.View`
  align-items: center;
  opacity: ${({ $isDisabled }) => $isDisabled ? 0.4 : 1};
  width: ${moderateScale(36)}px;
`;

export const CheckboxLabel = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const CheckboxLabelRow = styled.View`
  flex-direction: row;
  justify-content: flex-end;
`;

export const Container = styled.View`
  flex-grow: 1;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  display: inline;
`;

export const StyledP2 = styled(P2)`
  margin-bottom: ${moderateScale(16)}px;
  margin-top: ${moderateScale(4)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;

export const StyledText = styled.Text`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => `${f4}px`};
  line-height: ${moderateScale(22)}px;
`;

export const TextContainer = styled.Text`
  margin-bottom: ${moderateScale(16)}px;
`;
