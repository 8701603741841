import React from 'react';

import { moderateScale } from '../../../utils/scaleHelpers';
import { Skeleton } from '../../base_ui';
import {
  MortgageDetailsRow,
  MortgageDetailsRows,
} from './styledComponents';

const MortgageDetailsLoading = () => (
  <MortgageDetailsRows>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
    <MortgageDetailsRow>
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(131)}px`}
      />
      <Skeleton
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(102)}px`}
      />
    </MortgageDetailsRow>
  </MortgageDetailsRows>
);

export default MortgageDetailsLoading;
