import styled from 'styled-components/native';

import {
  H4,
  IconButton,
  P4,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const HeaderSection = styled.View`
  padding: ${moderateScale(8)}px 0 ${moderateScale(27)}px;
`;

export const MFAContainer = styled.View`
  flex: 1;
  margin: 0 ${moderateScale(22)}px ${moderateScale(35)}px;
`;

export const StyledHeader = styled(H4)`
  padding: ${moderateScale(8)}px 0;
`;

// Used in MFAForm
export const FormButtonWrapper = styled.View`
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

export const RadioButtonWrapper = styled.View`
  flex-direction: row;
  padding-top: ${moderateScale(24)}px;
`;

export const StyledIconButton = styled(IconButton)`
  padding-right: ${moderateScale(16)}px;
`;

export const StyledDisclaimer = styled(P4)`
  padding-top: ${moderateScale(8)}px;
`;
