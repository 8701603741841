import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';

import { P3 } from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  CardContainer,
  HeaderContainer,
  HeaderText,
  HeaderWrapper,
  InternalLink,
  ItemLabel,
  ItemWrapper,
} from './styledComponents';

const cardIcon = iconDictionary('escrowIcon');

const EscrowCard = ({
  currentEscrowBalance,
  effectiveDate,
  lastEscrowAnalysisDate,
  loanOriginationDate,
  loanType,
  projectedEscrowPayment,
}) => (
  <CardContainer>
    <HeaderContainer>
      <HeaderWrapper>
        {cardIcon}<HeaderText>Escrow</HeaderText>
      </HeaderWrapper>
    </HeaderContainer>
    <ItemWrapper>
      <ItemLabel>Current Escrow Balance</ItemLabel>
      <P3>{currentEscrowBalance}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Last Analysis</ItemLabel>
      <P3>{lastEscrowAnalysisDate}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Projected Payment</ItemLabel>
      <P3>{projectedEscrowPayment}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Projected Payment Effective Date</ItemLabel>
      <P3>{effectiveDate}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>
        <FormattedMessage id={INTL_IDS.MORTGAGE_TAXESINSURANCE_ESCROW_CARD_LABEL_ORIGINALLOANDATE} />
      </ItemLabel>
      <P3>{loanOriginationDate}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>
        <FormattedMessage id={INTL_IDS.MORTGAGE_TAXESINSURANCE_ESCROW_CARD_LABEL_LOANTYPE} />
      </ItemLabel>
      <P3>{loanType}</P3>
    </ItemWrapper>
    <InternalLink label="All Escrow History" path="EscrowHistory" size="medium" />
  </CardContainer>
);

EscrowCard.propTypes = {
  currentEscrowBalance: T.string.isRequired,
  effectiveDate: T.string.isRequired,
  lastEscrowAnalysisDate: T.string.isRequired,
  loanOriginationDate: T.string.isRequired,
  loanType: T.string.isRequired,
  projectedEscrowPayment: T.string.isRequired,
};

export default EscrowCard;
