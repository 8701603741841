/* eslint-disable react/no-array-index-key */
import React from 'react';
import T from 'prop-types';

import { INTERNAL_LINK_DICTIONARY } from '@dmi/shared/redux/Disclosures/constants';

import { HTML_ELEMENT_DICTIONARY } from './constants';
import { isExternal } from './helpers';
import { RichStyledElement } from './styledComponents';

const InlineBlocks = ({
  handleCloseDrawer,
  inlineBlocks,
  listType,
}) => inlineBlocks.map(({
  data,
  style,
  text,
  type,
}, index) => {
  const { url } = { ...data };
  let asElementType = type;
  let linkProps = {};

  if (type === 'link' && url) {
    const isExternalUrl = isExternal(url);
    if (isExternalUrl) {
      asElementType = 'externalLink';
      linkProps = {
        href: url,
        isDisclosureLink: true,
        label: text,
        rel: 'noopener noreferrer',
        size: 'small',
        target: '_blank',
      };
    } else {
      asElementType = 'internalLink';
      linkProps = {
        isDisclosureLink: true,
        label: text,
        onPressFunc: handleCloseDrawer,
        path: INTERNAL_LINK_DICTIONARY.mobile[url],
        size: 'small',
      };
    }
  }

  const styleProps = style && style.reduce((acc, styleType) => {
    acc[styleType.toLowerCase()] = true;
    return acc;
  }, {});

  return (
    <RichStyledElement
      key={`${type}-${index}`}
      as={HTML_ELEMENT_DICTIONARY[asElementType]}
      listType={listType}
      {...linkProps}
      {...styleProps}
    >
      {text}
    </RichStyledElement>
  );
});

InlineBlocks.propTypes = {
  handleCloseDrawer: T.func,
  inlineBlocks: T.arrayOf(
    T.shape({
      data: T.object,
      style: T.array,
      text: T.string.isRequired,
      type: T.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default InlineBlocks;
