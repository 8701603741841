import React, { Fragment } from 'react';
import { Dimensions } from 'react-native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import Layout from '../../../../../constants/Layout';
import { Skeleton as BaseSkeleton } from '../../../../base_ui';

const { isTablet } = Layout;

const Skeleton = () => {
  const blockWidth = Dimensions.get('window').width * (isTablet ? 0.3 : 0.6);

  return (
    <Fragment>
      <BaseSkeleton
        $bottomMargin={`${moderateScale(5)}px`}
        $leftMargin={`${moderateScale(1)}px`}
        $rightMargin={`${moderateScale(1)}px`}
        $topMargin={`${moderateScale(5)}px`}
        height={`${moderateScale(11.8)}px`}
        width={`${moderateScale(blockWidth)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(5)}px`}
        $leftMargin={`${moderateScale(1)}px`}
        $rightMargin={`${moderateScale(1)}px`}
        $topMargin={`${moderateScale(5)}px`}
        height={`${moderateScale(11.8)}px`}
        width={`${moderateScale(blockWidth)}px`}
      />
      <BaseSkeleton
        $bottomMargin={`${moderateScale(5)}px`}
        $leftMargin={`${moderateScale(1)}px`}
        $rightMargin={`${moderateScale(1)}px`}
        $topMargin={`${moderateScale(5)}px`}
        height={`${moderateScale(11.5)}px`}
        width={`${moderateScale(blockWidth)}px`}
      />
    </Fragment>
  );
};

export default Skeleton;
