import React from 'react';
import T from 'prop-types';

import LoginFooter from './LoginFooter';
import LoginForm from './LoginForm';
import Footer from '../../Footer';
import { LoginContainer } from './styledComponents';

const Login = ({
  dispatchBiometricLoginRequest,
  dispatchOpenPinDrawer,
  displayAppStoreBadge,
  error,
  footerIconKeys,
  formRef,
  handleInputChange,
  handleLoginPress,
  handleOpenDisclosureDrawer,
  isBiometricLoginEnabled,
  isBottomBannerRendered,
  isMurabahaAccount,
  isPinEnabled,
  isPreLoginBannerRendered,
  password,
  preauthFees,
  status,
  success,
  supportedBiometricAuthenticationTypes,
  username,
}) => (
  <LoginContainer $isBottomBannerRendered={isBottomBannerRendered}>
    <LoginForm
      dispatchBiometricLoginRequest={dispatchBiometricLoginRequest}
      dispatchOpenPinDrawer={dispatchOpenPinDrawer}
      error={error}
      formRef={formRef}
      handleInputChange={handleInputChange}
      handleLoginPress={handleLoginPress}
      isBiometricLoginEnabled={isBiometricLoginEnabled}
      isMurabahaAccount={isMurabahaAccount}
      isPinEnabled={isPinEnabled}
      isPreLoginBannerRendered={isPreLoginBannerRendered}
      password={password}
      status={status}
      success={success}
      supportedBiometricAuthenticationTypes={supportedBiometricAuthenticationTypes}
      username={username}
    />
    <LoginFooter
      footerIconKeys={footerIconKeys}
      handleOpenDrawer={handleOpenDisclosureDrawer}
      preauthFees={preauthFees}
    />
    <Footer displayAppStoreBadge={displayAppStoreBadge} />
  </LoginContainer>
);

Login.propTypes = {
  dispatchBiometricLoginRequest: T.func.isRequired,
  dispatchOpenPinDrawer: T.func.isRequired,
  displayAppStoreBadge: T.bool.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  footerIconKeys: T.array.isRequired,
  formRef: T.shape({ current: T.object }).isRequired,
  handleInputChange: T.func.isRequired,
  handleLoginPress: T.func.isRequired,
  handleOpenDisclosureDrawer: T.func.isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isBottomBannerRendered: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  isPreLoginBannerRendered: T.bool.isRequired,
  password: T.string.isRequired,
  preauthFees: T.object.isRequired,
  status: T.string.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
  supportedBiometricAuthenticationTypes: T.object.isRequired,
  username: T.string.isRequired,
};

export default Login;
