import React from 'react';
import { Text } from 'react-native';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';

const warning = iconDictionary('warning');

const Error = () => {
  const body = (
    <Text>An error has occurred while trying to retrieve your messages.</Text>
  );
  return (
    <Message
      body={body}
      icon={warning}
      title="Unable to load messages. Try again later"
    />
  );
};

export default Error;
