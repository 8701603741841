import React, { Fragment } from 'react';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { Skeleton } from '../../../../base_ui';
import { RowView } from '../styledComponents';
import { SkeletonView } from './styledComponents';
import SkeletonItem from './SkeletonItem';

const SkeletonArray = [...Array(12).keys()];

const PaymentHistoryListSkeleton = () => (
  <Fragment>
    <RowView>
      <SkeletonView $widthPercent={31}>
        <Skeleton
          $bottomMargin={`${moderateScale(15)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(33)}px`}
        />
      </SkeletonView>
      <SkeletonView $widthPercent={48}>
        <Skeleton
          $bottomMargin={`${moderateScale(15)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(38)}px`}
        />
      </SkeletonView>
      <SkeletonView $widthPercent={26}>
        <Skeleton
          $bottomMargin={`${moderateScale(15)}px`}
          height={`${moderateScale(12)}px`}
          width={`${moderateScale(47)}px`}
        />
      </SkeletonView>
    </RowView>
    {SkeletonArray.map((num) => (
      <SkeletonItem key={num} />
    ))}
  </Fragment>
);

export default PaymentHistoryListSkeleton;
