/* eslint-disable react/no-array-index-key */
import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../base_ui';
import { Container, Footer } from './styledComponents';
import DisclaimerRow from './DisclaimerRow';

const Disclaimer = ({ asOfDate, customerServicePhoneNumber, disclaimer }) => (
  <Container>
    {disclaimer.map((html, i) => (
      <ConditionalRender
        key={i}
        Component={DisclaimerRow}
        propsToPassDown={{
          customerServicePhoneNumber,
          html,
        }}
        shouldRender={!!html}
      />
    ))}
    <Footer>Current as of {asOfDate}</Footer>
  </Container>
);

Disclaimer.propTypes = {
  asOfDate: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  disclaimer: T.array.isRequired,
};

export default Disclaimer;
