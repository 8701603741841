/* eslint-disable react/no-array-index-key */
import React, { Fragment } from 'react';
import T from 'prop-types';
/* eslint-disable object-curly-newline */
import {
  SHARED_USERNAME_AND_PASSWORD_TOOLTIP_DATA,
} from '@dmi/shared/redux/Register/Main/constants';

import { ConditionalRender } from '../../base_ui';
import {
  Delimiter,
  ListItem,
  StyledP2,
  TooltipContent,
  TooltipHeader,
} from './styledComponents';

const TooltipContentComponent = ({ displayUsernameCriteria }) => {
  const {
    password: { criteria: passwordCriteria, title: passwordTitle },
    username: { criteria: usernameCriteria, title: usernameTitle },
  } = SHARED_USERNAME_AND_PASSWORD_TOOLTIP_DATA;

  return (
    <Fragment>
      <ConditionalRender
        Component={(
          <TooltipContent>
            <TooltipHeader>{usernameTitle}</TooltipHeader>
            {
              usernameCriteria.map((criteria, index) => (
                <ListItem key={`list-item-${index}`}>
                  <Delimiter>{'\u2022'}</Delimiter>
                  <StyledP2>{criteria}</StyledP2>
                </ListItem>
              ))
            }
          </TooltipContent>
        )}
        shouldRender={!!displayUsernameCriteria}
      />
      <TooltipContent>
        <TooltipHeader>{passwordTitle}</TooltipHeader>
        {
          passwordCriteria.map((criteria, index) => (
            <ListItem key={`list-item-${index}`}>
              <Delimiter>{'\u2022'}</Delimiter>
              <StyledP2>{criteria}</StyledP2>
            </ListItem>
          ))
        }
      </TooltipContent>
    </Fragment>
  );
};

TooltipContentComponent.propTypes = { displayUsernameCriteria: T.bool };

export default TooltipContentComponent;
