import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import { H2, P2 } from '../base_ui';

export const AnimationWrapper = styled.View`
  width: ${moderateScale(85)}px;
`;

export const StyledH2 = styled(H2)`
  margin-top: ${moderateScale(24)}px;
`;

export const StyledP2 = styled(P2)`
  margin-top: ${moderateScale(8)}px;
`;

export const StyledView = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-grow: 1;
  justify-content: center;
  min-height: 100%;
`;
