import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { changeSelectedTab } from '@dmi/shared/redux/LossDraft/actions';
import { TABS_DICTIONARY } from '@dmi/shared/redux/LossDraft/constants';
import makeSelectLossDraft,
{ selectLossDraftScreenReaderNotifications }
from '@dmi/shared/redux/LossDraft/selectors';

import { FilterGroup } from '../../components/base_ui';
import { SecondaryHeader } from '../../components/ScreenHeaders';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import { FilterWrapper, StyledPrimaryDivider, StyledView } from './styledComponents';
import viewDictionary from './viewDictionary';
import NotFoundScreen from '../NotFoundScreen';

const ClaimCenter = ({
  dispatchChangeSelectedTab,
  navigation,
  notifications,
  selectedTab,
}) => {
  const ViewToRender = viewDictionary[selectedTab] || NotFoundScreen;
  const filterKeys = Object.fromEntries(
    Object.entries(TABS_DICTIONARY).map(([key, { label }]) => [label, key]),
  );

  useEffect(() => () => dispatchChangeSelectedTab({ selectedTab: 'messages' }),
    [dispatchChangeSelectedTab]);

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Claim Center"
      />
      <StyledView>
        <FilterWrapper>
          <FilterGroup
            containerWidth={117}
            fontSize="f5"
            handleOnPress={(label) => dispatchChangeSelectedTab({ selectedTab: filterKeys[label] })}
            labels={Object.keys(filterKeys)}
            selectedFilter={TABS_DICTIONARY[selectedTab].label}
            showsHorizontalScrollIndicator={false}
          />
          <StyledPrimaryDivider />
        </FilterWrapper>
        <ViewToRender navigation={navigation} />
      </StyledView>
    </Fragment>
  );
};

ClaimCenter.propTypes = {
  dispatchChangeSelectedTab: T.func.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  selectedTab: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: LossDraft
   */
  notifications: selectLossDraftScreenReaderNotifications(),
  selectedTab: makeSelectLossDraft('selectedTab'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: LossDraft
   */
  dispatchChangeSelectedTab: (payload) => dispatch(changeSelectedTab(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default injectIntl(withConnect(ClaimCenter));
