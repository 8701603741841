import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H3 } from '../../../base_ui';

export const CardHeaderContainer = styled.View`
  align-items: center;
  align-self: ${({ $center }) => $center ? 'center' : 'flex-start'};
  flex-direction: row;
  margin-bottom: ${moderateScale(24)}px;
  padding-vertical: ${moderateScale(13)}px;
  width: ${({ $center }) => $center ? `${moderateScale(327)}px` : '100%'};
`;

export const LossDraftClaimH3 = styled(H3)`
  margin-bottom: ${moderateScale(18)}px;
`;

export const HeaderContainer = styled(CardHeaderContainer)`
  margin-bottom: ${moderateScale(24)}px;
  padding-vertical: ${moderateScale(16)}px;
`;

export const HomeTextContainer = styled.View`
  flex: 1;
  margin: 0 ${moderateScale(18)}px 0 0;
  min-width: ${moderateScale(210)}px;
  padding-bottom: ${moderateScale(18)}px;
  width: auto;
`;

export const IllustrationWrapper = styled.View`
  height: ${moderateScale(105)}px;
  margin: 0 ${moderateScale(24)}px 0 0;
  width: ${moderateScale(105)}px;
`;
