import { useCallback, useEffect, useRef } from 'react';
import { Animated, Easing, Platform } from 'react-native';

import { getOpacityInterpolations } from './helpers';

/**
 * Initializes a single looping animation whose Animated.Value represents a keyframe like in css
 * going from 0-100. Converts that keyframe value to several different values at any given moment
 * representing the various opacities of the 12 different circles making up the LoadingIndicator.
 *
 * @returns {object[]} Array of AnimatedInterpolations representing opacities at any given moment's
 *                     animation keyframe.
 */
const useAnimatedOpacities = () => {
  /*
    Implementation Note:
    styled-components does not support keyframes in react-native. The docs explicitly say to use
    the ReactNative.Animated API instead.

    Initially attempted to use a separate animation for each circle, and combine them via
    Animated.stagger(). The animation generally looked great, except for 2 main problems:
    1) No equivalent functionality as css animation negative delay values that allow an animation
    to start mid-motion. So the LoadingIndicator would start out completely transparent and only
    look good after part way through the first loop.
    2) If the user went to another browser tab or native app for a bit, the app's JS would pause
    the animations in such a way that the staggered-ness of the multiple animations would be
    off/janky when the user came back.

    Then resorted to this keyframe mimicking method. It solves both problems above, at the expense
    of requiring some semi-complicated math/logic (see getOpacityInterpolations helper function).
  */
  const keyframe = useRef(new Animated.Value(0)).current;

  const animate = useCallback(() => {
    Animated.loop(
      Animated.timing(keyframe, {
        duration: 1200,
        easing: Easing.linear,
        toValue: 100,
        useNativeDriver: Platform.OS !== 'web',
      }),
    ).start();
  }, [keyframe]);

  useEffect(() => {
    animate();
  }, [animate]);

  return getOpacityInterpolations(keyframe);
};

export default useAnimatedOpacities;
