import React from 'react';
import { Pressable } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';

import { ConditionalRender } from '../../../components/base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import InactiveRedirectScreen from '../../../containers/Main/InactiveRedirect';
import SelectLoanScreen from '../../../containers/SelectLoan';
import NotificationsButton from '../../../components/Notifications/NotificationsButton';
import TabNavigator from './homeScreens/TabNavigator';
import getHomeScreens from './homeScreens';

import { navigationRef } from '../../../utils/rootNavigation';

const HamburgerIcon = iconDictionary('menu');

const Drawer = createDrawerNavigator();

export const getNavigatorProps = (isLoanAvailable, isMurabahaAccount, isWeb) => {
  if (!isLoanAvailable) {
    return {
      ComponentToRender: (
        [
          <Drawer.Screen
            key={'InactiveRedirect'}
            component={InactiveRedirectScreen}
            name="InactiveRedirect"
          />,
          <Drawer.Screen key={'SelectLoan'} component={SelectLoanScreen} name="SelectLoan" />,
        ]
      ),
      Drawer,
      initialRouteName: 'Inactive',
    };
  }

  if (isWeb) {
    const homeScreens = getHomeScreens(isMurabahaAccount, isWeb);
    return {
      ComponentToRender: (
        Object.entries(homeScreens).map(([name, otherProps]) => (
          <Drawer.Screen key={name} name={name} {...otherProps} />
        ))
      ),
      Drawer,
      initialRouteName: 'Payments',
    };
  }

  return {
    ComponentToRender: (
      <Drawer.Screen
        component={TabNavigator}
        name="TabNavigator"
      />
    ),
    Drawer,
    initialRouteName: 'TabNavigator',
  };
};

export const headerOptions = ({
  isLoanAvailable,
  navigation,
  notificationsError,
  notificationsLoading,
  theme,
}) => {
  const { getCurrentRoute } = navigationRef.current || {};
  const { name: currentRoute } = getCurrentRoute ? getCurrentRoute() : {};
  const { fontFamilies, fontSizes } = theme;

  return {
    drawerType: 'front',
    headerLeft: () => (
      <Pressable
        accessible
        aria-label="Toggle Navigation Drawer"
        onPress={() => navigation.toggleDrawer()}
        role="button"
        style={({ pressed }) => ([
          pressed ? { opacity: 0.2 } : {},
          { marginLeft: moderateScale(24) }])}
      >
        {HamburgerIcon}
      </Pressable>
    ),
    headerRight: () => (
      <ConditionalRender
        Component={(
          <NotificationsButton
            disabled={!!notificationsError || notificationsLoading}
            onPress={() => navigation.navigate('Notifications')}
          />
        )}
        shouldRender={isLoanAvailable}
      />

    ),
    headerShown: true,
    headerStatusBarHeight: 2,
    headerStyle: {
      borderBottomColor: 'white',
      elevation: 0,
      height: moderateScale(42),
      shadowOffset: { height: 0, width: 0 },
    },
    headerTitle: currentRoute === 'Settings' ? currentRoute : '',
    headerTitleAlign: 'center',
    headerTitleStyle: {
      fontFamily: fontFamilies.secondaryFontBold,
      fontSize: fontSizes.f5,
    },
  };
};
