import styled from 'styled-components/native';
import HTML from 'react-native-render-html';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H3 } from '../../../base_ui';
import { TertiaryHeader } from '../../../ScreenHeaders';

// Menu
export const Heading = styled(H3)`
  padding: ${(moderateScale(14))}px ${(moderateScale(24))}px;
`;

// Drawer
export const StyledHTML = styled(HTML).attrs(({
  theme: {
    colors: { linkText, primaryCarbon },
    fontFamilies: { secondaryFont },
    fontSizes: { f4 },
  },
}) => ({
  baseFontStyle: {
    color: primaryCarbon,
    fontFamily: secondaryFont,
    fontSize: f4,
    lineHeight: moderateScale(22),
  },
  tagsStyles: {
    a: {
      color: linkText,
      textDecorationLine: 'none',
    },
  },
}))``;

export const StyledTertiaryHeader = styled(TertiaryHeader)`
  min-height: ${moderateScale(51)}px;
  padding: 0 ${moderateScale(24)}px;
`;
