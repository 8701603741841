import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { useFocusEffect } from '@react-navigation/native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import {
  resetFormState,
  submitHelocDraw,
  updateFormValue,
} from '@dmi/shared/redux/Mortgage/actions';
import {
  selectHelocDrawReviewProps,
  selectIsInHelocDrawFlow,
} from '@dmi/shared/redux/Mortgage/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { HelocReview as HelocReviewScreen } from '../../../components/Mortgage/HelocDraw';
import { StyledView } from '../styledComponents';

export const HelocReview = ({
  dispatchResetForm,
  dispatchSubmitHelocDraw,
  dispatchUpdateFormValue,
  helocDrawReviewProps,
  isInHelocDrawFlow,
  navigation,
}) => {
  const { reviewConstants: { header } } = helocDrawReviewProps;

  useEffect(() => () => {
    dispatchUpdateFormValue({
      field: 'isHelocDrawConfirmChecked',
      form: 'review',
      value: false,
    });
  }, [dispatchUpdateFormValue]);

  useFocusEffect(
    useCallback(() => {
      if (!isInHelocDrawFlow) {
        navigation.navigate('Mortgage');
      }
    }, [isInHelocDrawFlow, navigation]),
  );

  return (
    <StyledView>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={header}
      />
      <HelocReviewScreen
        dispatchResetForm={dispatchResetForm}
        dispatchSubmitHelocDraw={dispatchSubmitHelocDraw}
        dispatchUpdateFormValue={dispatchUpdateFormValue}
        navigation={navigation}
        {...helocDrawReviewProps}
      />
    </StyledView>
  );
};
HelocReview.propTypes = {
  dispatchResetForm: T.func.isRequired,
  dispatchSubmitHelocDraw: T.func.isRequired,
  dispatchUpdateFormValue: T.func.isRequired,
  helocDrawReviewProps: T.object.isRequired,
  isInHelocDrawFlow: T.bool.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  helocDrawReviewProps: selectHelocDrawReviewProps(),
  isInHelocDrawFlow: selectIsInHelocDrawFlow(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mortgage
   */
  dispatchResetForm: (payload) => dispatch(resetFormState(payload)),
  dispatchSubmitHelocDraw: (payload) => dispatch(submitHelocDraw(payload)),
  dispatchUpdateFormValue: (payload) => dispatch(updateFormValue(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HelocReview);
