import React from 'react';
import T from 'prop-types';

import {
  DetailsTitle,
  IconWrapper,
  MortgageDetailsPressableRow,
} from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';

const ArrowRightIcon = iconDictionary('arrowRightCarbonS');

const MortgageLoanDetailsExpandableRows = ({
  dispatchSetSelectedMortgageLoan,
  navigation,
  value,
}) => {
  const handlePress = (loan) => {
    dispatchSetSelectedMortgageLoan({ loan });
    navigation.navigate('MortgageLoanDetailsExpandableScreen');
  };

  return value.map((loan) => (
    <MortgageDetailsPressableRow
      key={loan.label}
      onPress={() => handlePress(loan)}
    >
      <DetailsTitle>{loan.label}</DetailsTitle>
      <IconWrapper>{ArrowRightIcon}</IconWrapper>
    </MortgageDetailsPressableRow>
  ));
};

MortgageLoanDetailsExpandableRows.propTypes = {
  dispatchSetSelectedMortgageLoan: T.func.isRequired,
  navigation: T.object.isRequired,
  value: T.array.isRequired,
};

export default MortgageLoanDetailsExpandableRows;
