import styled from 'styled-components/native';

import { BorderlessButton } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const NavMenuContainer = styled.View`
  padding: ${moderateScale(12)}px 0;
  width: 100%;
`;

export const StyledNavButton = styled(BorderlessButton).attrs(({
  isCurrentRoute,
  theme: {
    colors: { navigationMenuActive, primary, primaryCarbon },
    fontFamilies: { secondaryFontBold },
    fontSizes: { f5 },
  },
}) => ({
  titleStyle: [{
    color: isCurrentRoute ? navigationMenuActive || primary : primaryCarbon,
    fontFamily: secondaryFontBold,
    fontSize: f5,
    marginLeft: moderateScale(10),
  }],
}))`
  align-items: flex-start;
  padding: ${moderateScale(20)}px 0 ${moderateScale(10)}px ${moderateScale(30)}px;
`;
