import React, { Fragment } from 'react';
import T from 'prop-types';

import { EditPasswordTextField, P2 } from '../../../base_ui';
import {
  PasswordContainer,
  StyledContainer,
} from '../styledComponents';

const PasswordComponent = ({
  confirmPasswordInputRef,
  dispatchChangeInputValue,
  formErrors,
  formValues,
  handleSubmit,
  passwordInputRef,
}) => (
  <Fragment>
    <StyledContainer>
      <P2>New Password</P2>
      <PasswordContainer>
        <EditPasswordTextField
          ref={passwordInputRef}
          ariaLabel="Password"
          error={formErrors.password}
          id="password-field"
          label=""
          onChangeText={(value) => dispatchChangeInputValue({
            field: 'password',
            value,
          })}
          onSubmitEditing={() => confirmPasswordInputRef.current.focus()}
          placeholder="Required"
          value={formValues.password}
        />
      </PasswordContainer>
    </StyledContainer>
    <StyledContainer>
      <P2>Confirm <br />New Password</P2>
      <PasswordContainer>
        <EditPasswordTextField
          ref={confirmPasswordInputRef}
          ariaLabel="Confirm password"
          error={formErrors.confirmPassword}
          id="confirm-password-field"
          label=""
          onChangeText={(value) => dispatchChangeInputValue({
            field: 'confirmPassword',
            value,
          })}
          onSubmitEditing={handleSubmit}
          placeholder="Required"
          value={formValues.confirmPassword}
        />
      </PasswordContainer>
    </StyledContainer>
  </Fragment>
);

PasswordComponent.propTypes = {
  confirmPasswordInputRef: T.object.isRequired,
  dispatchChangeInputValue: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleSubmit: T.func.isRequired,
  passwordInputRef: T.object.isRequired,
};

export default PasswordComponent;
