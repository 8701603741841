import React from 'react';
import T from 'prop-types';

import { ADJUSTMENT_FILTER_DICTIONARY } from '@dmi/shared/redux/Calculators/Amortize/constants';

import { FilterGroup } from '../../../base_ui';
import { FilterWrapper, StyledPrimaryDivider } from './styledComponents';

const filterKeys = Object.fromEntries(
  Object.entries(ADJUSTMENT_FILTER_DICTIONARY).map(([key, { filterLabel }]) => [filterLabel, key]),
);
const filterLabels = Object.keys(filterKeys);

const Filter = ({ handleChangeAdjustmentFilter, selectedAdjustmentFilter }) => {
  const { filterLabel } = ADJUSTMENT_FILTER_DICTIONARY[selectedAdjustmentFilter];

  return (
    <FilterWrapper>
      <FilterGroup
        handleOnPress={(label) => handleChangeAdjustmentFilter(filterKeys[label])}
        labels={filterLabels}
        selectedFilter={filterLabel}
        showsHorizontalScrollIndicator={false}
      />
      <StyledPrimaryDivider />
    </FilterWrapper>
  );
};

Filter.propTypes = {
  handleChangeAdjustmentFilter: T.func.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
};

export default Filter;
