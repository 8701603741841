import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  Animation,
  BorderlessButton,
  CardButton,
  H2,
  H5,
  LargeProgressButton,
  MultilineTextField,
  P2,
  StyledScrollView,
} from '../../base_ui';
import BaseInput from '../../base_ui/Input/BaseInput';

// Confirmation Component

export const CardButtonContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${(moderateScale(25))}px ${(moderateScale(24))}px;
  width: 100%;
`;

export const ConfirmationBodyContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: ${(moderateScale(20))}px ${(moderateScale(24))}px ${(moderateScale(8))}px;
  width: 100%;
`;

export const ConfirmationContainer = styled.View`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`;

export const ConfirmationHeader = styled(H2)`
  line-height: ${(moderateScale(26))}px;
  margin-bottom: ${(moderateScale(8))}px;
  text-align: center;
`;

export const ConfirmationText = styled(P2)`
  margin-bottom: ${(moderateScale(24))}px;
  text-align: center;
`;

export const DataLine = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${(moderateScale(41))}px;
`;

export const DataLabel = styled(H5)`
  font-weight: 700;
`;

export const PendingIconWrapper = styled.View`
  height: ${(moderateScale(15))}px;
  margin-right: ${(moderateScale(8))}px;
  width: ${(moderateScale(15))}px;
`;

export const InfoMessageWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: ${(moderateScale(24))}px;
`;

export const StyledAnimation = styled(Animation)`
  align-self: center;
  width: ${moderateScale(287)}px;
`;

export const StyledCardButton = styled(CardButton)`
  margin-top: ${(moderateScale(20))}px;
`;

export const TextContainer = styled.View`
  display: flex;
  flex-direction: column;
  margin-top: ${(moderateScale(16))}px;
`;

// Error Component

export const ErrorContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const ErrorHeader = styled(H2)`
  line-height: ${(moderateScale(26))}px;
  margin-top: ${(moderateScale(24))}px;
  padding: ${(moderateScale(25))}px ${(moderateScale(24))}px;
  text-align: center;
`;

export const ErrorText = styled(P2)`
  line-height: ${(moderateScale(22))}px;
  margin-top: ${(moderateScale(8))}px;
  padding: ${(moderateScale(25))}px ${(moderateScale(24))}px;
  text-align: center;
`;

export const ErrorIllustrationWrapper = styled.View`
  margin-top: ${(moderateScale(52))}px;
`;

export const PhoneNumberWrapper = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledConfirmationButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: `${f4}px`,
  }],
}))``;

// New Secure Messaging Component

export const ButtonWrapper = styled.View`
  align-items: center;
  width: 100%;
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const NewSecureMessagingContainer = styled(StyledScrollView)
  .attrs({ contentContainerStyle: { justifyContent: 'space-around' } })`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const StyledBodyField = styled(MultilineTextField).attrs((
  {
    $disabled,
    theme: { colors: { disabledText, primaryCarbon } },
  },
) => ({ placeholderTextColor: $disabled ? disabledText : primaryCarbon }))`
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  height: ${(moderateScale(207))}px;
  margin: 0;
  min-height: 0;
  min-width: 0;
  padding: ${(moderateScale(12))}px ${(moderateScale(24))}px ${(moderateScale(8))}px;
`;

export const StyledLargeProgressButton = styled(LargeProgressButton)`
  margin: ${(moderateScale(35))}px ${(moderateScale(57))}px;
`;

export const StyledSubjectField = styled(BaseInput).attrs((
  {
    $disabled,
    theme: { colors: { disabledText, primaryCarbon }, fontSizes: { f4 } },
  },
) => ({
  errorStyle: { height: 0, margin: 0 },
  inputContainerStyle: { borderBottomWidth: 0, borderWidth: 0 },
  inputFontSize: `${f4}px`,
  placeholderTextColor: $disabled ? disabledText : primaryCarbon,
}))`
  border-style: solid;
  border-top-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-top-width: ${(moderateScale(1))}px;
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  font-weight: 700;
  height: ${(moderateScale(36))}px;
  opacity: 1;
  padding: ${(moderateScale(9))}px ${(moderateScale(24))}px;
`;

// New Message Component
export const MessageWrapper = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: 100%;
`;
