import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import { Animation, P2 } from '../base_ui';

export const ButtonWrapper = styled.View`
  align-items: center;
  padding-bottom: ${moderateScale(35)}px;
`;

export const ConfirmationContent = styled.View`
  align-items: center;
  margin: ${moderateScale(8)}px 0;
  padding-bottom: ${moderateScale(16)}px
  text-align: center;
`;

export const ConfirmationDisclaimerContent = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: ${moderateScale(24)}px;
  padding-top: ${moderateScale(24)}px;
`;

export const ConfirmationRow = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${moderateScale(16)}px;
`;

export const ConfirmationRows = styled.View`
  padding: ${moderateScale(8)}px 0;
`;

export const EditContainer = styled.View`
  flex: 1;
  margin-top: ${({ $isConfirmation }) => $isConfirmation ? moderateScale(20) : 0}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const EditStyledP2 = styled(P2)`
  margin: ${({ $isConfirmation }) => ($isConfirmation ?
    `${moderateScale(8)}px 0 0;` :
    `${moderateScale(8)}px 0 ${moderateScale(24)}px;`)};
  text-align: ${({ $isConfirmation }) => $isConfirmation ? 'center' : 'left'};
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const IconWrapper = styled.View`
  padding-right: ${moderateScale(8)}px;
`;

export const StyledAnimation = styled(Animation)`
  align-self: center;
  height: ${moderateScale(120)}px;
  width: ${moderateScale(287)}px;
`;
