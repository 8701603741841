/* eslint-disable max-len */
import React from 'react';
import Svg, { Line, Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

const Warning = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(86)} viewBox="0 0 86 86" width={moderateScale(86)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M20.59,46.86,10.14,62.72A4.05,4.05,0,0,0,13.52,69H73.4a4,4,0,0,0,3.41-6.23l-27-42.34a4,4,0,0,0-3.3-1.87H43.32l-1,0c-1.71-.07-3.29.29-4.27,1.78L21.82,45" fill="#FFF" stroke="#000" strokeMiterlimit={10} />
      <Path d="M49.77,20.43a4,4,0,0,0-3.3-1.87H43.08a13.08,13.08,0,0,1,2.19,2.49l26,40.63s3.37,4.76-1.48,7.31H73.4a4,4,0,0,0,3.41-6.23Z" fill={svgHighlight} />
      <Path d="M69.05,58.32l2.84,4.45a4.06,4.06,0,0,1-1.7,5.86" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="44.84" x2="67.54" y1="20.43" y2="55.96" />
      <Rect fill={svgHighlight} height="8.17" rx="4.08" width="8.17" x="37.7" y="57.11" />
      <Path d="M42.65,54.72H40.91a1.75,1.75,0,0,1-1.74-1.62L37.65,33.52a4,4,0,0,1,4-4.35h.23a4,4,0,0,1,4,4.35L44.39,53.1A1.75,1.75,0,0,1,42.65,54.72Z" fill={svgHighlight} />
      <Path d="M45.71,62.3a4,4,0,0,0,.16-1.1h0a4.09,4.09,0,0,0-4.09-4.09h0A4.09,4.09,0,0,0,37.7,61.2h0a4.08,4.08,0,0,0,4.08,4.08h0a4.08,4.08,0,0,0,3-1.28" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path d="M42.65,54.72H40.91a1.75,1.75,0,0,1-1.74-1.62L37.65,33.52a4,4,0,0,1,4-4.35h.23a4,4,0,0,1,4,4.35L44.39,53.1A1.75,1.75,0,0,1,42.65,54.72Z" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="43.54" x2="43.54" y1="13.08" y2="6.76" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="37.12" x2="35.03" y1="15.43" y2="13.12" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="49.97" x2="52.05" y1="15.43" y2="13.12" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="19.04" x2="30.07" y1="53.85" y2="36.71" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="17.06" x2="17.98" y1="56.91" y2="55.49" />
      <Path d="M16.54,35.5a2.06,2.06,0,1,0-1.13,1.24" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path d="M54.13,76.13a1.33,1.33,0,0,0,1.18,2.3,1.33,1.33,0,0,0,1-1.61,1.36,1.36,0,0,0-.81-.92" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path d="M72.88,22.16a2.62,2.62,0,0,0-2.55-2,2.6,2.6,0,0,0-2.59,2.34" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path d="M68.36,24.44a2.61,2.61,0,0,0,4.34-.62" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
    </Svg>
  );
};

export default Warning;
