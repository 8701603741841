import React, { useState } from 'react';
import T from 'prop-types';
import { Dimensions } from 'react-native';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';
import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  ConditionalRender,
  LargeProgressButton,
  Tooltip,
} from '../../base_ui';
import { PrimaryLoanToggle, PrimaryLoanTooltip } from '../Shared/LoanCards/Sections';
import {
  AddLoanContainer,
  ButtonWrapper,
  IllustrationWrapper,
  StyledBaseTextField,
  StyledStatusBanner,
  TextFieldGroup,
} from './styledComponents';

const AddLoanIllustration = iconDictionary('loansSoldHome');

const AddLoanComponent = ({
  bannerError,
  dispatchChangeInput,
  form,
  formErrors,
  handleAddLoan,
  status,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { formatMessage, messages } = useIntl();

  const isSubmitDisabled = !(
    form.lastFourSsn &&
    form.loanNumber &&
    form.propertyZip);

  const windowHeight = Dimensions.get('window').height;

  return (
    <AddLoanContainer>
      <StyledStatusBanner
        error={resolveIntlMessage({
          formatMessage,
          message: bannerError,
          messages,
        })}
      />
      <TextFieldGroup>
        <StyledBaseTextField
          errorMessage={formErrors.loanNumber}
          keyboardType="number-pad"
          label={formatMessage(
            { id: INTL_IDS.LOANSCONTENT_ADDLOAN_ADDLOANVIEW_LOANNUMBER_LABEL },
          )}
          onChangeText={(value) => dispatchChangeInput({
            field: 'loanNumber',
            form: 'addLoan',
            value,
          })}
          placeholder="Required"
          value={form.loanNumber}
        />
        <StyledBaseTextField
          errorMessage={formErrors.lastFourSsn}
          keyboardType="number-pad"
          label="Last 4 Digits of Social Security"
          onChangeText={(value) => dispatchChangeInput({
            field: 'lastFourSsn',
            form: 'addLoan',
            value,
          })}
          placeholder="Required"
          value={form.lastFourSsn}
        />
        <StyledBaseTextField
          errorMessage={formErrors.propertyZip}
          keyboardType="number-pad"
          label="Property Zip Code"
          onChangeText={(value) => dispatchChangeInput({
            field: 'propertyZip',
            form: 'addLoan',
            value,
          })}
          placeholder="Required"
          value={form.propertyZip}
        />
        <PrimaryLoanToggle
          handleChangePrimaryLoan={() => dispatchChangeInput({
            field: 'isPrimaryLoan',
            form: 'addLoan',
            value: !form.isPrimaryLoan,
          })}
          isPrimaryLoan={form.isPrimaryLoan}
          setIsTooltipOpen={setIsTooltipOpen}
          width={`${moderateScale(327)}px`}
        />
      </TextFieldGroup>
      <ConditionalRender
        Component={(
          <IllustrationWrapper>
            {AddLoanIllustration}
          </IllustrationWrapper>
        )}
        shouldRender={windowHeight > 800}
      />
      <ButtonWrapper>
        <LargeProgressButton
          disabled={isSubmitDisabled}
          label="Submit"
          onPress={handleAddLoan}
          status={status}
        />
      </ButtonWrapper>
      <ConditionalRender
        Component={(
          <Tooltip
            handleClose={() => setIsTooltipOpen(false)}
            positionAbsolute={{ left: 'auto', top: 'auto' }}
            visible={isTooltipOpen}
          >
            <PrimaryLoanTooltip
              handleCloseTooltip={() => setIsTooltipOpen(false)}
            />
          </Tooltip>
        )}
        shouldRender={isTooltipOpen}
      />
    </AddLoanContainer>
  );
};

AddLoanComponent.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  form: T.object.isRequired,
  formErrors: T.object.isRequired,
  handleAddLoan: T.func.isRequired,
  status: T.string.isRequired,
};

export default AddLoanComponent;
