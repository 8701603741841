/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavAccountDefaultMIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M9.07977 1.58008C7.58256 1.58008 6.11898 2.02405 4.8741 2.85585C3.62922 3.68766 2.65896 4.86993 2.086 6.25316C1.51305 7.6364 1.36313 9.15848 1.65522 10.6269C1.94731 12.0953 2.66829 13.4442 3.72697 14.5029C4.78565 15.5616 6.1345 16.2825 7.60294 16.5746C9.07137 16.8667 10.5934 16.7168 11.9767 16.1438C13.3599 15.5709 14.5422 14.6006 15.374 13.3557C16.2058 12.1109 16.6498 10.6473 16.6498 9.15008C16.6471 7.1432 15.8487 5.21928 14.4296 3.8002C13.0106 2.38112 11.0866 1.58272 9.07977 1.58008ZM9.07977 15.5801C7.72166 15.578 6.40012 15.1398 5.30977 14.3301C5.58392 13.5476 6.09445 12.8697 6.77074 12.39C7.44703 11.9104 8.25566 11.6527 9.08477 11.6527C9.91388 11.6527 10.7225 11.9104 11.3988 12.39C12.0751 12.8697 12.5856 13.5476 12.8598 14.3301C11.7608 15.1257 10.4365 15.5496 9.07977 15.5401V15.5801ZM13.7898 13.5001C13.3703 12.6046 12.7047 11.847 11.8706 11.3157C11.0366 10.7845 10.0686 10.5016 9.07977 10.5001C8.09223 10.5076 7.12674 10.7929 6.29378 11.3234C5.46083 11.854 4.79406 12.6083 4.36977 13.5001C3.53162 12.5859 2.97762 11.4476 2.77534 10.224C2.57305 9.00033 2.73123 7.74428 3.23057 6.60901C3.72991 5.47374 4.54884 4.50831 5.58747 3.83049C6.6261 3.15267 7.83953 2.79175 9.07977 2.79175C10.32 2.79175 11.5334 3.15267 12.5721 3.83049C13.6107 4.50831 14.4296 5.47374 14.929 6.60901C15.4283 7.74428 15.5865 9.00033 15.3842 10.224C15.1819 11.4476 14.6279 12.5859 13.7898 13.5001Z" fill="black" />
      <Path d="M9.07971 3.69997C8.46419 3.69997 7.86251 3.88262 7.35085 4.2248C6.8392 4.56697 6.44059 5.05328 6.2055 5.62214C5.97041 6.191 5.90941 6.81683 6.03022 7.42039C6.15103 8.02394 6.44823 8.57807 6.88417 9.01261C7.32011 9.44715 7.8752 9.74256 8.47913 9.86142C9.08307 9.98029 9.7087 9.91728 10.2768 9.68035C10.8449 9.44343 11.3299 9.04326 11.6705 8.53051C12.011 8.01776 12.1917 7.41549 12.1897 6.79997C12.1897 6.39203 12.1092 5.9881 11.9528 5.61133C11.7964 5.23457 11.5671 4.89239 11.2782 4.60439C10.9893 4.3164 10.6464 4.08826 10.2691 3.93306C9.89184 3.77786 9.48765 3.69865 9.07971 3.69997ZM9.07971 8.69997C8.69631 8.70191 8.32101 8.58961 8.00169 8.37739C7.68237 8.16517 7.43349 7.86265 7.28679 7.50842C7.1401 7.15418 7.10224 6.76427 7.17804 6.38843C7.25384 6.01259 7.43986 5.66784 7.71239 5.39815C7.98492 5.12846 8.3316 4.94605 8.70821 4.87419C9.08483 4.80233 9.47432 4.84427 9.827 4.99467C10.1797 5.14506 10.4796 5.3971 10.6884 5.71862C10.8973 6.04015 11.0057 6.4166 10.9997 6.79997C10.9997 7.31011 10.7978 7.7995 10.438 8.16116C10.0782 8.52282 9.58984 8.72732 9.07971 8.72997V8.69997Z" fill="black" />
    </Svg>
  );
}
