import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeFormFieldValue,
  changeStep,
  postMessage,
} from '@dmi/shared/redux/LossDraft/actions';
import {
  selectDropdownClaimNumberItems,
  selectDropdownDefaultClaimNumber,
  selectForm, selectFormErrors, selectRequestStatus,
} from '@dmi/shared/redux/LossDraft/selectors';
import { selectCustomerServicePhoneNumber } from '@dmi/shared/redux/Main/selectors';

import MessageCreateComponent from '../../../components/LossDraft/NewMessage/MessageCreate';
import { StyledScrollView } from '../../../components/base_ui';

const NewMessageCreate = ({
  dispatchChangeFormFieldValue,
  dispatchChangeStep,
  dispatchPostMessage,
  dropdownClaimNumberItems,
  dropdownDefaultClaimNumber,
  formAttachments,
  formMessage,
  formUploadError,
  handleResetState,
  navigation,
  requestStatusPostMessage,
}) => (
  <StyledScrollView>
    <MessageCreateComponent
      dispatchChangeFormFieldValue={dispatchChangeFormFieldValue}
      dispatchChangeStep={dispatchChangeStep}
      dispatchPostMessage={dispatchPostMessage}
      dropdownClaimNumberItems={dropdownClaimNumberItems}
      dropdownDefaultClaimNumber={dropdownDefaultClaimNumber}
      formAttachments={formAttachments}
      formMessage={formMessage}
      formUploadError={formUploadError}
      handleResetState={handleResetState}
      navigation={navigation}
      requestStatusPostMessage={requestStatusPostMessage}
    />
  </StyledScrollView>
);

NewMessageCreate.propTypes = {
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchPostMessage: T.func.isRequired,
  dropdownClaimNumberItems: T.array.isRequired,
  dropdownDefaultClaimNumber: T.string.isRequired,
  formAttachments: T.array.isRequired,
  formMessage: T.object.isRequired,
  formUploadError: T.string.isRequired,
  handleResetState: T.func.isRequired,
  navigation: T.object.isRequired,
  requestStatusPostMessage: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  dropdownClaimNumberItems: selectDropdownClaimNumberItems(),
  dropdownDefaultClaimNumber: selectDropdownDefaultClaimNumber(),
  formAttachments: selectForm('attachments'),
  formMessage: selectForm('message'),
  formUploadError: selectFormErrors('attachments'),
  requestStatusPostMessage: selectRequestStatus('postMessage'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: selectCustomerServicePhoneNumber(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchChangeFormFieldValue: (payload) => dispatch(changeFormFieldValue(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchPostMessage: (payload) => dispatch(postMessage(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageCreate);
