/* eslint-disable max-len */
import React from 'react';
import Svg, { Ellipse, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function HourglassIcon(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(120)} viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M157.18,33S154,44.86,149.89,50.33s-7.29,8.42-6.79,11.81a21.72,21.72,0,0,1,4.59-6.34c3.27-3.21,8.3-7.55,8.3-7.55a28.67,28.67,0,0,0,4.15-6.09c1.51-3.21,3.64-9,4-11.44Z" fill="#e6ebef" />
      <Path d="M116.5,25.54v2.92c0,4,11.41,7.33,25.48,7.33s25.48-3.28,25.48-7.33V25.75" fill={svgHighlight} />
      <Ellipse cx="141.8" cy="25.14" fill={svgHighlight} rx="25.05" ry="7.39" />
      <Path d="M116.5,25.54v2.92c0,4,11.41,7.33,25.48,7.33s25.48-3.28,25.48-7.33V25.75" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M124.73,30.48c-5.06-1.35-8.23-3.27-8.23-5.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M129.73,31.51c-.8-.13-1.56-.26-2.3-.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M167.46,25.07c0,4.06-11.41,7.34-25.48,7.34a81.81,81.81,0,0,1-9.85-.57" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M154.3,18.65c7.84,1.25,13.16,3.66,13.16,6.42" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M116.5,25.07c0-4.05,11.41-7.34,25.48-7.34a82.76,82.76,0,0,1,9.91.58" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M116.61,92v2.91c0,4,11.4,7.34,25.48,7.34s25.47-3.29,25.47-7.34V92.22" fill={svgHighlight} />
      <Ellipse cx="142.02" cy="91.61" fill="#fff" rx="24.93" ry="7.39" />
      <Path d="M116.61,92v2.91c0,4,11.4,7.34,25.48,7.34s25.47-3.29,25.47-7.34V92.22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M148.1,98.68c-1.93.13-3.94.2-6,.2-14.08,0-25.48-3.28-25.48-7.34" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M152.18,98.29c-.55.06-1.1.13-1.67.18" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M167.56,91.54c0,2.73-5.15,5.11-12.79,6.37" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M161.87,86.92c3.56,1.26,5.69,2.87,5.69,4.62" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M124.24,86.27c.74-.22,1.52-.43,2.35-.62" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M116.61,91.54c0-1.6,1.79-3.09,4.83-4.3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M120.13,32.61a42.32,42.32,0,0,0,9.78,17.52c7.41,7.66,10,8.4,10.18,12.07" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M131.21,73c6.42-6.43,8.73-7.29,8.88-10.73" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M120.13,91.86a39,39,0,0,1,9.42-17.18" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M163.65,91.86a42.32,42.32,0,0,0-9.78-17.52c-7.41-7.66-10-8.4-10.18-12.06" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M155.52,48.3c-.52.62-1.07,1.24-1.65,1.83-7.41,7.66-10,8.4-10.18,12.07" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M163.65,32.61a43,43,0,0,1-6.54,13.66" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.32" />
      <Path d="M120.38,92.26S127.27,96,140.9,96s20.29-2.87,20.29-2.87" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M125.58,88.53A2,2,0,0,0,127,91.12a61.9,61.9,0,0,0,14.89,1.76,61.14,61.14,0,0,0,14.72-1.61,2.49,2.49,0,0,0,1.79-3.12c-.57-2-1.58-4.55-3.12-5.77-2.62-2.07-12.13-4.53-13.06-6.61a6.71,6.71,0,0,1-3.28,2.51c-1.89.53-7.81,1.86-10.22,4.27C127.36,83.86,126.24,86.63,125.58,88.53Z" fill="#e6ebef" />
      <Path d="M128.29,83s5,5.36,15.68,6.45,14.27.16,14.27.16-.49,3-15.36,3.17-17.16-2.46-17.16-2.46a2.79,2.79,0,0,1,0-2.13C126.1,87,128.29,83,128.29,83Z" fill="#bac2ca" />
      <Path d="M138.09,92.76q1.77.1,3.75.12a61.14,61.14,0,0,0,14.72-1.61,2.49,2.49,0,0,0,1.79-3.12c-.57-2-1.58-4.55-3.12-5.77a15,15,0,0,0-2.86-1.55" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M150.89,80.21c-3.66-1.5-8.1-3-8.72-4.44a6.71,6.71,0,0,1-3.28,2.51c-1.89.53-7.81,1.86-10.22,4.27-1.31,1.31-2.43,4.08-3.09,6A2,2,0,0,0,127,91.12a56,56,0,0,0,8.64,1.45" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M127.92,42.12c1.75,2.89,4.41,6.78,7.47,9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M126,38.81s.35.68,1,1.73" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M118.44,61.09a2.3,2.3,0,1,0-1.27,1.39" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M164.17,73a1.49,1.49,0,1,0,2.41.77,1.5,1.5,0,0,0-.9-1" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M173.07,51.21a2.91,2.91,0,0,0-5.74.34" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
      <Path d="M168,53.77a2.91,2.91,0,0,0,4.84-.7" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.84" />
    </Svg>
  );
}
