import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { Card, CardLink } from './Card';

const CommsIcon = iconDictionary('navCalculatorDefaultM');

const CommunicationsCard = ({ navigation, paperlessButtonDetails }) => (
  <Card HeaderIcon={CommsIcon} headerText="Communications">
    <CardLink
      details={paperlessButtonDetails}
      handlePress={() => navigation.navigate('Paperless')}
      label="Paperless Billing"
    />
    <CardLink
      handlePress={() => navigation.navigate('CommunicationNotifications')}
      label="Text & Email Notifications"
    />
  </Card>
);

CommunicationsCard.propTypes = {
  navigation: T.object.isRequired,
  paperlessButtonDetails: T.string.isRequired,
};

export default CommunicationsCard;
