/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G, Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

const LoginWinter = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg enableBackground="new 0 0 287.25 120.25" fill="#000000" height={moderateScale(120)} id="Layer_1" version="1.1" viewBox="0 0 287.25 120.25" width={moderateScale(287)} x="0px" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" y="0px" {...props}>
      <G>
        <Path
          d="M-282.223,113.172c0,0-14.283-2.926-14.283-22.5c0-24.065,26.436-29.771,37.369-45
		c16.435-22.891,23.478-28.37,44.804-35.022s53.022-6.652,69.848,0.978c7.494,3.399,22.304,11.348,37.565,12.522
		s53.022,6.261,69.261,26.022c16.496,20.074,11.152,57.522-20.935,62.804C-58.593,112.976-282.516,112.879-282.223,113.172z"
          fill="#E6EBEF"
        />
        <Path
          d="M-96.006,113.802c0,0,7.239-2.022,8.804-2.087c1.565-0.065,5.022,0.652,9.065,1.109
		s7.957-1.239,11.413-1.239c3.457,0,13.109,1.761,13.109,1.761l-6.457,2.283l-35.413-0.065L-96.006,113.802z"
          fill="#FFFFFF"
        />
        <Rect fill="#FFFFFF" height="47.446" width="84.228" x="-231.647" y="66.02" />
        <Path
          d="M-147.911,69.308l-1.861,1.785c-0.344,0.33-0.802,0.514-1.278,0.514h-2.926v-1.834h3.228l2.715-2.69
		L-147.911,69.308z"
          fill="#BAC2CA"
        />
        <Rect fill="#BAC2CA" height="1.63" width="53.674" x="-223.506" y="69.781" />
        <Path
          d="M-231.125,69.672h-5.429c-0.711,0-1.288-0.577-1.288-1.288l0,0c0-0.711,0.577-1.288,1.288-1.288h5.429
		V69.672z"
          fill="#FFFFFF"
        />
        <Path
          d="M-216.778,33.162c2.263,0,3.796-2.289,2.95-4.387c-1.791-4.445-5.58-10.77-12.788-11.517
		c-3.035-0.314-5.856,0.886-8.076,2.367c-0.198,0.132-0.455,0.106-0.63-0.056c-1.532-1.418-3.739-2.31-6.195-2.31
		c-4.625,0-8.375,3.158-8.375,7.053v0.006c0,0.303-0.258,0.541-0.559,0.514c-0.224-0.02-0.452-0.031-0.683-0.031
		c-2.843,0-5.252,1.551-6.108,3.702c-0.107,0.268-0.407,0.393-0.671,0.277c-0.754-0.331-1.611-0.542-2.52-0.518
		c-2.075,0.055-3.429,1.212-4.27,2.389c-0.752,1.052-0.016,2.512,1.277,2.512L-216.778,33.162z"
          fill="#FFFFFF"
        />
        <Path
          d="M-216.778,33.412h-46.648c-0.698,0-1.305-0.37-1.624-0.989c-0.317-0.617-0.262-1.352,0.143-1.919
		c1.131-1.583,2.676-2.446,4.466-2.493c0.871-0.013,1.759,0.159,2.627,0.54c0.065,0.029,0.136,0.03,0.198,0.003
		c0.064-0.026,0.114-0.077,0.14-0.144c0.919-2.309,3.467-3.86,6.341-3.86c0.238,0,0.474,0.011,0.706,0.032
		c0.074,0.012,0.146-0.018,0.2-0.068c0.056-0.051,0.086-0.121,0.086-0.197c0-4.033,3.869-7.309,8.625-7.309
		c2.415,0,4.734,0.866,6.365,2.376c0.093,0.086,0.222,0.099,0.322,0.031c2.808-1.874,5.579-2.686,8.24-2.407
		c3.947,0.409,9.405,2.766,12.994,11.672c0.43,1.066,0.303,2.27-0.339,3.22C-214.573,32.848-215.636,33.412-216.778,33.412z
		M-260.293,28.509c-0.045,0-0.09,0-0.135,0.002c-1.65,0.043-3.021,0.812-4.072,2.284c-0.301,0.42-0.34,0.943-0.105,1.399
		c0.231,0.449,0.672,0.717,1.179,0.717h46.648c0.99,0,1.875-0.47,2.429-1.29c0.548-0.812,0.657-1.842,0.289-2.754
		c-1.93-4.789-5.679-10.646-12.582-11.362c-2.537-0.263-5.203,0.52-7.911,2.326c-0.292,0.195-0.679,0.16-0.939-0.081
		c-1.539-1.425-3.735-2.243-6.025-2.243c-4.48,0-8.125,3.051-8.125,6.802c0,0.221-0.091,0.428-0.249,0.572
		c-0.157,0.144-0.366,0.215-0.582,0.197c-0.218-0.02-0.438-0.03-0.661-0.03c-2.671,0-5.032,1.424-5.876,3.544
		c-0.077,0.193-0.224,0.343-0.415,0.421c-0.191,0.078-0.4,0.075-0.589-0.008C-258.77,28.677-259.536,28.509-260.293,28.509z"
        />
        <Rect fill="#E6EBEF" height="2.108" width="1.768" x="-159.158" y="32.021" />
        <Rect fill="#E6EBEF" height="2.108" width="4.88" x="-155.639" y="32.021" />
        <Path
          d="M-231.555,97.315c-0.774-0.127-1.569-0.193-2.38-0.193c-4.835,0-9.118,2.348-11.779,5.965
		c-0.533,0.724-1.47,0.991-2.32,0.7c-1.035-0.355-2.145-0.548-3.3-0.548c-3.213,0-6.079,1.486-7.948,3.809
		c0,0-2.037,2.669-2.2,6.158h30.033L-231.555,97.315z"
          fill="#FFFFFF"
        />
        <Path d="M-272.614,113.427c0,0,8.56-2.446,13.109-1.467c4.549,0.978,3.13,2.543,3.13,2.543L-272.614,113.427z" fill="#FFFFFF" />
        <G>
          <Polyline fill={svgHighlight} points="-199.699,113.345 -199.699,81.973 -182.557,81.973 -182.557,113.345 		" />
          <Path
            d="M-182.557,113.72c-0.207,0-0.375-0.168-0.375-0.375V82.349h-16.393v30.996c0,0.207-0.168,0.375-0.375,0.375
			s-0.375-0.168-0.375-0.375V81.974c0-0.207,0.168-0.375,0.375-0.375h17.143c0.207,0,0.375,0.168,0.375,0.375v31.371
			C-182.182,113.552-182.35,113.72-182.557,113.72z"
          />
        </G>
        <G>
          <Circle cx="-191.456" cy="89.886" fill="#FFFFFF" r="0.74" />
          <Path
            d="M-191.456,90.876c-0.546,0-0.99-0.444-0.99-0.99s0.444-0.989,0.99-0.989s0.99,0.443,0.99,0.989
			S-190.91,90.876-191.456,90.876z M-191.456,89.397c-0.27,0-0.49,0.22-0.49,0.489c0,0.271,0.22,0.49,0.49,0.49
			c0.271,0,0.49-0.22,0.49-0.49C-190.965,89.616-191.185,89.397-191.456,89.397z"
          />
        </G>
        <Path
          d="M-258.313,106.185c0,0,3.111-2.537,8.126-1.39c5.015,1.148,5.619-0.06,7.129-1.873
		c1.51-1.813,7.341-4.38,11.721-3.142v-2.386c0,0-5.105-1.118-9.395,1.42c-4.29,2.537-4.894,4.501-5.558,4.773
		s-0.936,0.423-2.991-0.151c-2.054-0.574-6.736,0.121-9.123,2.87"
          fill="#DCE2E7"
        />
        <Polygon fill="#FFFFFF" points="-237.056,66.273 -213.965,41.118 -129.192,41.118 -152.734,66.763 -190.215,66.763 	" />
        <Rect fill="#FFFFFF" height="1.875" width="1.484" x="-159.837" y="29.933" />
        <Rect fill="#FFFFFF" height="15.383" width="12.693" x="-158.965" y="30.006" />
        <Rect fill="#FFFFFF" height="5.378" width="8.298" x="-159.075" y="40.192" />
        <Path
          d="M-150.227,29.971v1.952l-0.434,0.087v13.749l4.294-4.684v-9.151c0,0,0.607-0.477,0.564-0.651
		c-0.043-0.173-0.217-1.128-0.217-1.128l-0.304-0.304L-150.227,29.971z"
          fill="#DCE2E7"
        />
        <Path
          d="M-84.925,113.627c0.01-0.136,0.015-0.274,0.015-0.412c0-3.092-2.506-5.598-5.598-5.598
		c-0.116,0-0.231,0.005-0.346,0.012c-1.84-3.137-5.246-5.245-9.146-5.245c-0.251,0-0.501,0.009-0.748,0.026
		c-1.036,0.073-2.028-0.435-2.55-1.332c-1.836-3.154-5.252-5.275-9.165-5.275v17.775"
          fill="#FFFFFF"
        />
        <Path
          d="M-75.43,29.06c1.308,0,2.011-1.535,1.159-2.528c-1.436-1.673-4.314-2.816-7.632-2.816
		c-0.54,0-1.067,0.032-1.578,0.091c-0.561,0.065-1.049-0.386-1.048-0.951l0-0.012c0-2.891-3.657-5.234-8.168-5.234
		c-1.256,0-2.445,0.183-3.508,0.508c-0.497,0.152-1.034-0.146-1.146-0.654c-0.948-4.295-5.548-7.556-11.094-7.556
		c-6.233,0-11.286,4.117-11.286,9.195c0,0.182,0.021,0.359,0.033,0.538c-0.26-0.04-0.525-0.067-0.797-0.067
		c-2.33,0-4.302,1.524-4.979,3.629c-0.152,0.473-0.645,0.741-1.123,0.605c-0.452-0.129-0.929-0.199-1.422-0.199
		c-1.66,0-3.14,0.773-4.099,1.979c-1.115,1.402-0.107,3.474,1.684,3.474H-75.43z"
          fill="#FFFFFF"
        />
        <Path
          d="M-75.431,29.31h-55.003c-0.928,0-1.755-0.52-2.159-1.357c-0.402-0.833-0.295-1.799,0.279-2.522
		c1.048-1.317,2.613-2.073,4.295-2.073c0.505,0,1.007,0.07,1.49,0.209c0.354,0.098,0.705-0.096,0.816-0.441
		c0.732-2.274,2.829-3.802,5.218-3.802c0.166,0,0.339,0.01,0.522,0.029c-0.006-0.083-0.01-0.166-0.01-0.25
		c0-5.208,5.175-9.444,11.536-9.444c5.578,0,10.347,3.261,11.338,7.753c0.038,0.171,0.145,0.315,0.3,0.406
		c0.161,0.094,0.354,0.116,0.529,0.062c1.125-0.344,2.329-0.519,3.581-0.519c4.642,0,8.418,2.46,8.418,5.484
		c0,0.215,0.086,0.409,0.238,0.544c0.148,0.132,0.331,0.193,0.532,0.17c0.528-0.062,1.069-0.093,1.606-0.093
		c3.288,0,6.285,1.112,7.821,2.903c0.459,0.535,0.562,1.264,0.268,1.904C-74.108,28.913-74.728,29.31-75.431,29.31z
		M-128.019,23.858c-1.528,0-2.951,0.687-3.904,1.884c-0.46,0.579-0.542,1.325-0.22,1.993c0.32,0.663,0.975,1.075,1.709,1.075
		h55.003c0.506,0,0.951-0.285,1.162-0.745c0.212-0.46,0.139-0.985-0.191-1.37c-1.444-1.683-4.296-2.729-7.442-2.729
		c-0.518,0-1.039,0.03-1.549,0.09c-0.336,0.036-0.67-0.069-0.923-0.294c-0.258-0.23-0.406-0.561-0.405-0.906
		c0-2.76-3.552-4.996-7.918-4.996c-1.202,0-2.357,0.167-3.435,0.497c-0.311,0.094-0.647,0.055-0.928-0.109
		c-0.277-0.162-0.468-0.421-0.536-0.729c-0.941-4.265-5.504-7.361-10.85-7.361c-6.085,0-11.036,4.012-11.036,8.944
		c0,0.129,0.011,0.255,0.022,0.382l0.011,0.137c0.006,0.076-0.023,0.15-0.079,0.203c-0.057,0.052-0.133,0.075-0.208,0.063
		c-0.28-0.043-0.528-0.064-0.759-0.064c-2.171,0-4.076,1.389-4.741,3.456c-0.194,0.604-0.822,0.942-1.43,0.769
		C-127.106,23.922-127.561,23.858-128.019,23.858z"
        />
        <Path
          d="M-158.429,100.146h-1.349c-0.508,0-0.921,0.412-0.921,0.921v0.241c0,0.508,0.412,0.921,0.921,0.921h1.349"
          fill="#E0E0E0"
        />
        <Path
          d="M-209.087,100.146h-1.349c-0.508,0-0.921,0.412-0.921,0.921v0.241c0,0.508,0.412,0.921,0.921,0.921h1.349"
          fill="#E0E0E0"
        />
        <Rect fill="#E6EBEF" height="19.517" width="12.684" x="-171.402" y="80.412" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="-166.058,99.929 -171.913,99.929 -163.412,80.412 -157.557,80.412 	" />
        <Rect fill="#E6EBEF" height="19.517" width="12.684" x="-222.146" y="80.412" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="-225.303,99.929 -231.158,99.929 -222.657,80.412 -216.802,80.412 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="-215.197,99.929 -218.84,99.929 -210.34,80.412 -206.696,80.412 	" />
        <Path
          d="M-137.172,49.946l7.395-8.137c0.3-0.33,0.74-0.516,1.173-0.508c0.446,0.007,0.867,0.205,1.156,0.545
		l21.107,24.755c0.556,0.652,0.478,1.632-0.174,2.188c-0.653,0.556-1.632,0.478-2.188-0.174L-128.666,45.2l-21.888,24.082
		c-0.306,0.337-0.727,0.508-1.149,0.508c-0.372,0-0.746-0.133-1.043-0.404c-0.634-0.576-0.681-1.558-0.105-2.192l13.462-14.812"
          fill="#FFFFFF"
        />
        <Polygon
          fill="#BAC2CA"
          points="-128.628,45.281 -147.712,66.273 -147.712,113.331 -112.668,113.331 -112.668,69.222
		-108.331,69.222 	"
        />
        <Polygon fill="#BAC2CA" points="-130.059,47.044 -112.462,67.498 -112.462,64.549 -128.621,45.484 	" />
        <Polygon fill="#BAC2CA" points="-112.451,64.321 -112.451,69.244 -108.222,69.352 	" />
        <Ellipse cx="-128.996" cy="62.716" fill="#EFF1EF" rx="1.929" ry="3.318" />
        <Path
          d="M-150.719,46.096h-8.376c-0.207,0-0.375-0.168-0.375-0.375V32.023c0-0.207,0.168-0.375,0.375-0.375
		s0.375,0.168,0.375,0.375v13.323h7.841l4.177-4.375v-8.878c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.028
		c0,0.097-0.037,0.189-0.104,0.259l-4.392,4.6C-150.519,46.054-150.616,46.096-150.719,46.096z"
        />
        <Path
          d="M-150.738,39.98c-0.138,0-0.25-0.112-0.25-0.25v-7.762c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v7.762
		C-150.488,39.868-150.601,39.98-150.738,39.98z"
        />
        <Path
          d="M-150.738,43.504c-0.138,0-0.25-0.112-0.25-0.25v-2.31c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.31
		C-150.488,43.392-150.601,43.504-150.738,43.504z"
        />
        <Path
          d="M-146.511,32.261h-8.514c-0.207,0-0.375-0.168-0.375-0.375c0-0.207,0.168-0.375,0.375-0.375h8.514
		c0.202,0,0.366-0.164,0.366-0.366v-0.554c0-0.202-0.164-0.366-0.366-0.366h-12.791c-0.202,0-0.366,0.164-0.366,0.366v0.554
		c0,0.202,0.164,0.366,0.366,0.366h1.691c0.207,0,0.375,0.168,0.375,0.375c0,0.207-0.168,0.375-0.375,0.375h-1.691
		c-0.615,0-1.116-0.5-1.116-1.116v-0.554c0-0.615,0.501-1.116,1.116-1.116h12.791c0.615,0,1.116,0.501,1.116,1.116v0.554
		C-145.395,31.761-145.896,32.261-146.511,32.261z"
        />
        <Path
          d="M-150.29,31.933c-0.138,0-0.25-0.112-0.25-0.25v-1.711c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.711
		C-150.04,31.821-150.152,31.933-150.29,31.933z"
        />
        <Path
          d="M-128.57,41.554h-15.887c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h15.887
		c0.207,0,0.375,0.168,0.375,0.375S-128.363,41.554-128.57,41.554z"
        />
        <Path
          d="M-193.252,67.138h-44.188c-0.148,0-0.283-0.088-0.343-0.224c-0.06-0.137-0.034-0.295,0.066-0.404l23.474-25.646
		c0.071-0.077,0.172-0.122,0.277-0.122h9.919c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-9.754l-22.787,24.895
		h43.336c0.207,0,0.375,0.168,0.375,0.375S-193.045,67.138-193.252,67.138z"
        />
        <Path
          d="M-200.393,41.493h-1.446c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.446c0.207,0,0.375,0.168,0.375,0.375
		S-200.186,41.493-200.393,41.493z"
        />
        <Path
          d="M-159.138,41.493h-39.204c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h39.204
		c0.207,0,0.375,0.168,0.375,0.375S-158.931,41.493-159.138,41.493z"
        />
        <Path
          d="M-231.59,70.153h-5.357c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h5.357c0.207,0,0.375,0.168,0.375,0.375
		S-231.383,70.153-231.59,70.153z"
        />
        <Path
          d="M-151.3,70.153h-4.551c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.551c0.207,0,0.375,0.168,0.375,0.375
		S-151.093,70.153-151.3,70.153z"
        />
        <Path
          d="M-231.62,111.943c-0.207,0-0.375-0.168-0.375-0.375V69.95c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v41.618
		C-231.245,111.775-231.413,111.943-231.62,111.943z"
        />
        <Path
          d="M-147.797,113.419c-0.207,0-0.375-0.168-0.375-0.375V92.296c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v20.748C-147.422,113.251-147.59,113.419-147.797,113.419z"
        />
        <Path
          d="M-147.797,89.433c-0.207,0-0.375-0.168-0.375-0.375V66.14c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v22.918
		C-147.422,89.265-147.59,89.433-147.797,89.433z"
        />
        <Path
          d="M-112.442,113.419c-0.207,0-0.375-0.168-0.375-0.375V65.307c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v47.737C-112.067,113.251-112.234,113.419-112.442,113.419z"
        />
        <Path
          d="M-108.097,69.565h-4.345c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.345c0.207,0,0.375,0.168,0.375,0.375
		S-107.89,69.565-108.097,69.565z"
        />
        <Path
          d="M-288.876,113.761c-0.171,0-0.325-0.117-0.365-0.291c-0.046-0.202,0.08-0.403,0.282-0.449l3.846-0.883
		c3.005-0.689,6.14-0.544,9.066,0.429c0.889,0.295,1.815,0.444,2.754,0.444l6.58-1.369c2.078-0.431,4.2-0.508,6.307-0.234l9.1,1.188
		c2.087,0.273,4.198,0.243,6.274-0.091l6.617-1.062c3.243-0.521,6.624-0.317,9.78,0.588l0.48,0.138
		c1.951,0.56,3.97,0.843,6.002,0.843h124.912c1.869,0,3.728-0.259,5.522-0.768l0.26-0.074c3.289-0.934,6.796-1.048,10.139-0.328
		l0.866,0.187c2.966,0.636,6.091,0.597,9.034-0.121c2.63-0.639,5.319-0.765,7.998-0.373l8.64,1.265
		c0.965,0.142,1.951,0.213,2.93,0.213h6.601c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-6.601
		c-1.015,0-2.037-0.074-3.039-0.221l-8.64-1.265c-2.581-0.379-5.178-0.258-7.711,0.359c-3.055,0.742-6.296,0.788-9.37,0.125
		l-0.866-0.187c-3.226-0.692-6.605-0.582-9.775,0.316l-0.259,0.074c-1.863,0.529-3.79,0.797-5.729,0.797h-124.912
		c-2.102,0-4.191-0.293-6.208-0.872l-0.48-0.138c-3.051-0.875-6.322-1.071-9.454-0.568l-6.617,1.062
		c-2.149,0.345-4.333,0.376-6.49,0.095l-9.099-1.188c-2.024-0.264-4.062-0.188-6.058,0.225l-6.656,1.377
		c-1.125-0.004-2.101-0.155-3.066-0.476c-2.794-0.928-5.79-1.068-8.662-0.409l-3.846,0.883
		C-288.819,113.758-288.848,113.761-288.876,113.761z"
        />
        <Path
          d="M-209.246,100.481h-14.158c-0.207,0-0.375-0.168-0.375-0.375V79.193c0-0.207,0.168-0.375,0.375-0.375h14.158
		c0.207,0,0.375,0.168,0.375,0.375v20.913C-208.871,100.314-209.039,100.481-209.246,100.481z M-223.029,99.731h13.408V79.568
		h-13.408V99.731z"
        />
        <Path
          d="M-222.127,98.986c-0.138,0-0.25-0.112-0.25-0.25V80.431c0-0.138,0.112-0.25,0.25-0.25h12.987c0.138,0,0.25,0.112,0.25,0.25
		s-0.112,0.25-0.25,0.25h-12.737v18.056C-221.877,98.874-221.989,98.986-222.127,98.986z"
        />
        <Path
          d="M-209.159,102.603h-10.375c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h10v-1.332h-14.771
		c-0.301,0-0.546,0.245-0.546,0.546v0.24c0,0.301,0.245,0.546,0.546,0.546h3.296c0.207,0,0.375,0.168,0.375,0.375
		s-0.168,0.375-0.375,0.375h-3.296c-0.714,0-1.296-0.581-1.296-1.296v-0.24c0-0.715,0.582-1.296,1.296-1.296h15.146
		c0.207,0,0.375,0.168,0.375,0.375v2.082C-208.784,102.435-208.952,102.603-209.159,102.603z"
        />
        <Path
          d="M-209.202,102.478h-1.349c-0.646,0-1.171-0.525-1.171-1.171v-0.24c0-0.646,0.525-1.171,1.171-1.171h1.349
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-1.349c-0.37,0-0.671,0.301-0.671,0.671v0.24c0,0.37,0.301,0.671,0.671,0.671
		h1.349c0.138,0,0.25,0.112,0.25,0.25S-209.064,102.478-209.202,102.478z"
        />
        <Path
          d="M-216.065,98.693c-0.207,0-0.375-0.168-0.375-0.375V88.63c0-0.207,0.168-0.375,0.375-0.375
		c0.207,0,0.375,0.168,0.375,0.375v9.688C-215.69,98.525-215.858,98.693-216.065,98.693z"
        />
        <Path
          d="M-216.065,86.989c-0.207,0-0.375-0.168-0.375-0.375v-5.008c0-0.207,0.168-0.375,0.375-0.375
		c0.207,0,0.375,0.168,0.375,0.375v5.008C-215.69,86.821-215.858,86.989-216.065,86.989z"
        />
        <Path
          d="M-209.26,90.424h-11.72c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h11.72c0.207,0,0.375,0.168,0.375,0.375
		S-209.053,90.424-209.26,90.424z"
        />
        <Path
          d="M-158.473,100.481h-14.158c-0.207,0-0.375-0.168-0.375-0.375v-7.001c0-0.207,0.168-0.375,0.375-0.375
		s0.375,0.168,0.375,0.375v6.626h13.408V79.568h-13.408v12.033c0,0.207-0.168,0.375-0.375,0.375s-0.375-0.168-0.375-0.375V79.193
		c0-0.207,0.168-0.375,0.375-0.375h14.158c0.207,0,0.375,0.168,0.375,0.375v20.913
		C-158.098,100.314-158.266,100.481-158.473,100.481z"
        />
        <Path
          d="M-171.354,99.054c-0.138,0-0.25-0.112-0.25-0.25V80.465c0-0.138,0.112-0.25,0.25-0.25h11.734c0.138,0,0.25,0.112,0.25,0.25
		s-0.112,0.25-0.25,0.25h-11.484v18.089C-171.104,98.941-171.216,99.054-171.354,99.054z"
        />
        <Path
          d="M-158.386,102.603h-15.146c-0.714,0-1.295-0.581-1.295-1.296v-0.24c0-0.715,0.581-1.296,1.295-1.296h15.146
		c0.207,0,0.375,0.168,0.375,0.375v2.082C-158.011,102.435-158.179,102.603-158.386,102.603z M-173.532,100.521
		c-0.301,0-0.545,0.245-0.545,0.546v0.24c0,0.301,0.245,0.546,0.545,0.546h14.771v-1.332H-173.532z"
        />
        <Path
          d="M-158.429,102.478h-1.35c-0.646,0-1.171-0.525-1.171-1.171v-0.24c0-0.646,0.525-1.171,1.171-1.171h1.35
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-1.35c-0.37,0-0.671,0.301-0.671,0.671v0.24c0,0.37,0.301,0.671,0.671,0.671
		h1.35c0.138,0,0.25,0.112,0.25,0.25S-158.291,102.478-158.429,102.478z"
        />
        <Path
          d="M-165.292,98.693c-0.207,0-0.375-0.168-0.375-0.375v-4.902c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v4.902
		C-164.917,98.525-165.085,98.693-165.292,98.693z"
        />
        <Path
          d="M-165.292,92.197c-0.207,0-0.375-0.168-0.375-0.375v-9.826c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.826
		C-164.917,92.029-165.085,92.197-165.292,92.197z"
        />
        <Path
          d="M-158.487,90.424h-11.388c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h11.388
		c0.207,0,0.375,0.168,0.375,0.375S-158.28,90.424-158.487,90.424z"
        />
        <Path
          d="M-151.702,70.166c-0.48,0-0.94-0.179-1.296-0.502c-0.381-0.346-0.604-0.819-0.628-1.334
		c-0.024-0.514,0.152-1.007,0.499-1.388l12.861-14.155c0.141-0.154,0.378-0.165,0.529-0.025c0.153,0.139,0.165,0.376,0.025,0.529
		l-12.861,14.156c-0.212,0.232-0.32,0.533-0.305,0.848c0.015,0.313,0.151,0.603,0.384,0.814c0.464,0.422,1.242,0.383,1.662-0.079
		l21.888-24.083c0.072-0.08,0.174-0.135,0.283-0.123c0.107,0.002,0.21,0.05,0.279,0.132l19.964,23.414
		c0.204,0.239,0.488,0.385,0.802,0.409c0.32,0.028,0.619-0.074,0.857-0.277c0.493-0.422,0.553-1.166,0.132-1.659l-21.107-24.755
		c-0.22-0.257-0.539-0.408-0.877-0.413c-0.007,0-0.014,0-0.02,0c-0.325,0-0.648,0.143-0.869,0.385l-7.171,7.83
		c-0.141,0.154-0.377,0.164-0.529,0.023c-0.153-0.14-0.163-0.377-0.023-0.529l7.17-7.83c0.367-0.404,0.958-0.632,1.455-0.629
		c0.553,0.008,1.076,0.255,1.435,0.677l21.107,24.755c0.689,0.808,0.593,2.026-0.216,2.716c-0.392,0.334-0.896,0.494-1.402,0.455
		c-0.514-0.041-0.98-0.279-1.313-0.671l-19.688-23.09l-21.602,23.767C-150.641,69.936-151.16,70.166-151.702,70.166z"
        />
        <Path
          d="M-138.775,52.083c-0.09,0-0.181-0.032-0.252-0.098c-0.153-0.14-0.165-0.377-0.025-0.529l0.698-0.768
		c0.141-0.154,0.378-0.164,0.529-0.025c0.153,0.14,0.165,0.377,0.025,0.529l-0.698,0.768
		C-138.571,52.042-138.673,52.083-138.775,52.083z"
        />
        <Path
          d="M-234.584,70.14h-1.995c-1.044,0-1.893-0.85-1.893-1.894s0.849-1.893,1.893-1.893h1.995c0.207,0,0.375,0.168,0.375,0.375
		s-0.168,0.375-0.375,0.375h-1.995c-0.63,0-1.143,0.513-1.143,1.143c0,0.631,0.513,1.144,1.143,1.144h1.995
		c0.207,0,0.375,0.168,0.375,0.375S-234.377,70.14-234.584,70.14z"
        />
        <Path
          d="M-261.377,112.039c-0.021,0-0.043-0.002-0.065-0.006c-0.204-0.035-0.341-0.229-0.305-0.434
		c0.23-1.316,0.704-2.562,1.407-3.703c0.108-0.176,0.339-0.232,0.516-0.123c0.176,0.108,0.231,0.34,0.123,0.516
		c-0.653,1.06-1.093,2.217-1.306,3.439C-261.04,111.911-261.198,112.039-261.377,112.039z"
        />
        <Path
          d="M-259.283,107.423c-0.083,0-0.166-0.027-0.235-0.083c-0.161-0.13-0.187-0.366-0.057-0.527
		c2.02-2.509,5.023-3.948,8.24-3.948c1.173,0,2.324,0.191,3.422,0.568c0.708,0.243,1.47,0.014,1.896-0.567
		c2.819-3.831,7.335-6.118,12.082-6.118c0.814,0,1.636,0.066,2.44,0.197c0.205,0.033,0.343,0.226,0.31,0.431
		c-0.033,0.204-0.232,0.338-0.43,0.31c-0.765-0.124-1.545-0.188-2.32-0.188c-4.508,0-8.799,2.173-11.477,5.812
		c-0.623,0.846-1.725,1.18-2.744,0.832c-1.02-0.35-2.089-0.527-3.179-0.527c-2.989,0-5.779,1.337-7.656,3.669
		C-259.065,107.375-259.174,107.423-259.283,107.423z"
        />
        <Path
          d="M-128.498,66.734c-1.665,0-3.021-1.841-3.021-4.104c0-2.264,1.355-4.105,3.021-4.105s3.021,1.842,3.021,4.105
		C-125.478,64.894-126.833,66.734-128.498,66.734z M-128.498,59.274c-1.252,0-2.271,1.505-2.271,3.355
		c0,1.85,1.019,3.354,2.271,3.354s2.271-1.505,2.271-3.354C-126.228,60.779-127.246,59.274-128.498,59.274z"
        />
        <Path
          d="M-128.992,66.565c-0.107,0-0.207-0.07-0.239-0.178c-0.04-0.133,0.035-0.272,0.167-0.312
		c1.109-0.333,1.914-1.767,1.914-3.409c0-1.174-0.407-2.263-1.089-2.912c-0.1-0.095-0.104-0.253-0.008-0.354
		c0.094-0.099,0.252-0.105,0.354-0.008c0.778,0.741,1.243,1.966,1.243,3.273c0,1.889-0.934,3.487-2.27,3.888
		C-128.944,66.563-128.969,66.565-128.992,66.565z"
        />
        <Path
          d="M-85.118,112.073c-0.164,0-0.314-0.108-0.361-0.273c-0.629-2.242-2.697-3.809-5.029-3.809
		c-0.108,0-0.217,0.005-0.323,0.011c-0.138,0.023-0.274-0.062-0.346-0.185c-1.831-3.121-5.212-5.06-8.823-5.06
		c-0.168,0-0.336,0.004-0.502,0.013c-1.33,0.062-2.555-0.584-3.232-1.693c-1.874-3.068-5.138-4.9-8.729-4.9
		c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375c3.854,0,7.356,1.966,9.369,5.26c0.534,0.874,1.519,1.404,2.554,1.334
		c0.179-0.009,0.358-0.013,0.54-0.013c3.801,0,7.363,2,9.353,5.235c2.73-0.05,5.159,1.744,5.892,4.354
		c0.056,0.199-0.061,0.406-0.26,0.463C-85.051,112.068-85.085,112.073-85.118,112.073z"
        />
        <Path
          d="M-42.012,113.769h-1.702c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.702c0.207,0,0.375,0.168,0.375,0.375
		S-41.805,113.769-42.012,113.769z"
        />
        <Path
          d="M-152.627,67.168h-38.051c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h38.051
		c0.207,0,0.375,0.168,0.375,0.375S-152.42,67.168-152.627,67.168z"
        />
        <Path
          d="M-259.118,78.645l0.01,0c-5.456-32.937-13.111-31.462-13.111-31.462l-0.012,0.112l-0.01-0.085
		c0,0-7.348-1.502-12.803,31.435l0.032-0.001c-0.162,1.007-0.252,2.047-0.252,3.112c0,8.888,5.91,16.093,13.199,16.093
		c7.29,0,13.199-7.205,13.199-16.093C-258.867,80.692-258.956,79.653-259.118,78.645z"
          fill="#DCE2E7"
        />
        <Path
          d="M-258.867,81.757c0-1.065-0.09-2.104-0.252-3.112l0.01,0c-5.456-32.937-13.111-31.462-13.111-31.462
		l-0.012,0.112l-0.01-0.085c0,0-0.471-0.095-1.261,0.294c1.946,3.956,8.108,17.381,8.719,29.898
		c0.56,11.48-3.237,17.405-7.395,20.44c0.038,0,0.075,0.007,0.114,0.007C-264.776,97.85-258.867,90.645-258.867,81.757z"
          fill="#BAC2CA"
        />
        <Path
          d="M-278.419,53.933c0,0,0.848,3.848,5.152,4.109c4.304,0.261,9.978,3.717,11.543,8.152
		c0,0-2.609-12.065-6.913-16.565c-4.304-4.5-6.326-0.848-6.913-0.065C-276.136,50.346-278.419,53.933-278.419,53.933z"
          fill="#FFFFFF"
        />
        <Path
          d="M-272.066,98.225c-7.485,0-13.574-7.388-13.574-16.468c0-0.999,0.079-2.019,0.234-3.032
		c-0.013-0.046-0.018-0.093-0.01-0.141c3.806-22.976,8.6-29.312,11.118-31.045c0.956-0.658,1.657-0.725,1.965-0.713
		c0.013-0.004,0.027-0.008,0.042-0.01c0.095-0.019,0.969-0.153,2.294,0.77c2.585,1.8,7.484,8.22,11.258,30.999
		c0.005,0.029,0.006,0.059,0.004,0.088c0.161,1.027,0.243,2.065,0.243,3.085C-258.492,90.837-264.581,98.225-272.066,98.225z
		M-284.652,78.568c0.012,0.044,0.016,0.09,0.009,0.136c-0.164,1.021-0.247,2.048-0.247,3.053c0,8.667,5.753,15.718,12.824,15.718
		s12.824-7.051,12.824-15.718c0-1.008-0.083-2.035-0.247-3.052c-0.004-0.027-0.006-0.056-0.004-0.083
		c-3.727-22.438-8.448-28.691-10.932-30.421c-0.728-0.506-1.268-0.624-1.536-0.647c-0.069,0.072-0.163,0.092-0.272,0.117
		c-0.093,0-0.178-0.034-0.244-0.09C-273.55,47.677-279.822,49.523-284.652,78.568z"
        />
        <Path
          d="M-272.231,113.287c-0.207,0-0.375-0.168-0.375-0.375V81.584c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v31.328C-271.856,113.119-272.024,113.287-272.231,113.287z"
        />
        <Path
          d="M-60.535,112.612c-0.207,0-0.375-0.168-0.375-0.375V81.584c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v30.653C-60.16,112.444-60.328,112.612-60.535,112.612z"
        />
        <Path
          d="M-272.375,88.582c-0.075,0-0.151-0.022-0.217-0.069l-4.817-3.432c-0.168-0.12-0.208-0.354-0.088-0.523
		c0.12-0.168,0.354-0.208,0.523-0.088l4.817,3.432c0.168,0.12,0.208,0.354,0.088,0.523
		C-272.142,88.527-272.257,88.582-272.375,88.582z"
        />
        <Path
          d="M-272.109,92.607c-0.117,0-0.233-0.055-0.306-0.157c-0.12-0.169-0.081-0.403,0.088-0.523l4.818-3.432
		c0.169-0.12,0.404-0.08,0.523,0.088c0.12,0.169,0.081,0.403-0.088,0.523l-4.818,3.432
		C-271.959,92.585-272.034,92.607-272.109,92.607z"
        />
        <Path
          d="M-280.887,70.732c-0.013,0-0.026-0.001-0.04-0.003c-0.136-0.021-0.229-0.149-0.208-0.286
		c0.736-4.655,2.278-9.326,3.442-12.426c0.048-0.13,0.192-0.194,0.322-0.146c0.129,0.049,0.195,0.193,0.146,0.322
		c-1.155,3.077-2.686,7.715-3.416,12.328C-280.66,70.645-280.767,70.732-280.887,70.732z"
        />
        <Polygon
          fill="#BAC2CA"
          points="-39.826,101.542 -45.484,83.736 -41.823,83.736 -49.644,62.935 -45.817,62.935 -60.128,27.324
		-60.965,27.324 -75.276,62.935 -71.449,62.935 -79.27,83.736 -75.609,83.736 -81.267,101.542 	"
        />
        <Path
          d="M-65.126,37.107c0,0,3.196,7.402,12.815,10.109l-7.817-19.891c0,0-0.889-0.065-0.987,0.196
		C-61.213,27.781-65.126,37.107-65.126,37.107z"
          fill="#FFFFFF"
        />
        <Path
          d="M-78.593,82.237c0,0,2.543-2.87,7.043-2.739s4.565,2.935,9.196,1.435c4.63-1.5,7.37-0.522,8.804,0.326
		s3.978,2.087,3.978,2.087h-29.478L-78.593,82.237z"
          fill="#FFFFFF"
        />
        <Path
          d="M-40.742,99.823c0,0-3.494-3.942-9.677-3.763c-6.183,0.179-6.272,4.032-12.634,1.971
		s-10.125-0.717-12.096,0.448c-1.971,1.165-5.466,2.867-5.466,2.867h40.5L-40.742,99.823z"
          fill="#FFFFFF"
        />
        <Path
          d="M-46.115,62.868H-65.68c0,0,4.239-0.457,6.913-1.37c2.674-0.913,4.109-2.022,7.043-1.565
		c2.935,0.457,5.217,1.761,5.217,1.761L-46.115,62.868z"
          fill="#FFFFFF"
        />
        <Path
          d="M-39.826,101.917h-41.44c-0.119,0-0.232-0.057-0.303-0.153s-0.091-0.222-0.055-0.335l5.502-17.317h-3.147
		c-0.123,0-0.238-0.061-0.309-0.161c-0.069-0.102-0.086-0.23-0.042-0.346l7.629-20.294h-3.285c-0.125,0-0.241-0.062-0.311-0.165
		c-0.07-0.104-0.084-0.234-0.037-0.35l14.312-35.611c0.057-0.142,0.194-0.235,0.348-0.235h0.837c0.153,0,0.291,0.093,0.348,0.235
		l14.312,35.611c0.047,0.115,0.033,0.246-0.037,0.35c-0.069,0.104-0.186,0.165-0.311,0.165h-3.285l7.629,20.294
		c0.044,0.115,0.027,0.244-0.042,0.346c-0.07,0.101-0.186,0.161-0.309,0.161h-3.147l5.502,17.317
		c0.036,0.113,0.016,0.238-0.055,0.335S-39.707,101.917-39.826,101.917z M-80.754,101.167h40.415l-5.502-17.317
		c-0.036-0.113-0.016-0.238,0.055-0.335s0.184-0.153,0.303-0.153h3.118l-7.629-20.294c-0.044-0.115-0.027-0.244,0.042-0.346
		c0.07-0.101,0.186-0.161,0.309-0.161h3.272l-14.011-34.861h-0.329l-14.011,34.861h3.272c0.123,0,0.238,0.061,0.309,0.161
		c0.069,0.102,0.086,0.23,0.042,0.346l-7.629,20.294h3.118c0.119,0,0.232,0.057,0.303,0.153s0.091,0.222,0.055,0.335
		L-80.754,101.167z"
        />
        <G>
          <Path
            d="M-107.866,105.163c0.88,2.732-1.093,3.673-1.093,3.673l0.012,0.082c-0.51-2.009-1.898-3.715-3.822-4.605
			l-0.119-0.057c0.028-0.1,0.06-0.196,0.08-0.303c0.068-0.36,0.092-0.713,0.087-1.057l0.222,0.234c0,0,1.343,0.205,1.708-0.842
			c0.364-1.047-0.091-2.368-2.004-4.053c-1.913-1.685-3.074-1.138-3.074-1.138s-0.474-0.376-1.203-0.376
			c-0.729,0-1.127,0.398-1.127,0.398l-0.072,0.004l0.015-0.027c0,0-1.161-0.546-3.074,1.138c-1.913,1.685-2.368,3.005-2.004,4.053
			c0.364,1.047,1.708,0.842,1.708,0.842l0.23-0.412c-0.019,0.4-0.001,0.812,0.079,1.235c0.109,0.582,0.334,1.05,0.624,1.429
			l-0.001,0.073c-0.012,0.014-0.533,0.634-0.556,1.58c-0.015,0.631-0.01,3.563-0.005,5.488c0.002,0.951,0.774,1.719,1.725,1.719
			h0.256c0.953,0,1.725-0.772,1.725-1.725v-2.055l-0.082-0.131c0.362,0.086,0.784,0.115,1.232,0.033l0.043,0.119l-0.055,0.036v2.032
			c0,0.953,0.772,1.725,1.725,1.725h0.211c0.953,0,1.725-0.772,1.725-1.725v-0.015h0.923c-0.018,0.088-0.029,0.178-0.029,0.271
			c0,0.723,0.586,1.309,1.309,1.309h0.829c0.559,0,1.011-0.456,1.006-1.015l-0.026-2.812l0.07,0.461
			c1.396-0.82,1.791-2.11,1.791-2.11C-106.166,106.559-107.866,105.163-107.866,105.163z"
            fill="#FFFFFF"
          />
          <Path
            d="M-121.271,106.497c0,0-1.492-0.803-2.483-0.105c-0.836,0.59-1.88,0.954-3.022,0.401l0.207-1.469
			c1.125,0.587,2.179,0.255,3.033-0.31c1.011-0.668,2.478,0.179,2.478,0.179"
            fill={svgHighlight}
          />
          <Path
            d="M-126.609,105.517c-0.035,0-0.071-0.008-0.104-0.025l-0.749-0.393c-0.11-0.058-0.152-0.193-0.095-0.304
			c0.058-0.111,0.193-0.152,0.304-0.095l0.749,0.393c0.11,0.058,0.152,0.193,0.095,0.304
			C-126.449,105.474-126.527,105.517-126.609,105.517z"
          />
          <Path
            d="M-126.799,106.234c-0.035,0-0.071-0.008-0.104-0.025l-0.749-0.393c-0.11-0.058-0.152-0.193-0.095-0.304
			s0.193-0.152,0.304-0.095l0.749,0.393c0.11,0.058,0.152,0.193,0.095,0.304C-126.64,106.191-126.718,106.234-126.799,106.234z"
          />
          <Path
            d="M-126.884,106.991c-0.035,0-0.069-0.008-0.103-0.024l-0.799-0.409c-0.11-0.057-0.154-0.192-0.098-0.303
			s0.193-0.152,0.303-0.098l0.799,0.409c0.11,0.057,0.154,0.192,0.098,0.303C-126.724,106.947-126.802,106.991-126.884,106.991z"
          />
          <Path
            d="M-120.96,106.702c0,0,1.198,1.59,3.546,1.712c2.348,0.122,5.16-1.247,4.891-3.766
			c0,0-1.484,2.014-3.783,2.307c-2.299,0.293-4.389-1.501-4.389-1.501L-120.96,106.702z"
            fill={svgHighlight}
          />
          <G>
            <Path
              d="M-119.233,114.614h-0.256c-1.155,0-2.098-0.938-2.1-2.092c-0.006-2.173-0.009-4.891,0.005-5.498
				c0.026-1.077,0.622-1.787,0.647-1.816c0.134-0.159,0.372-0.178,0.528-0.042c0.157,0.134,0.177,0.369,0.043,0.527l0,0
				c-0.004,0.005-0.449,0.547-0.469,1.349c-0.014,0.6-0.011,3.311-0.005,5.479c0.002,0.741,0.607,1.344,1.35,1.344h0.256
				c0.744,0,1.35-0.605,1.35-1.35v-2.055c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.055
				C-117.133,113.673-118.074,114.614-119.233,114.614z"
            />
            <Path
              d="M-116.875,110.787c-0.919,0-1.717-0.37-2.143-0.687c-0.166-0.123-0.2-0.357-0.077-0.523
				c0.122-0.166,0.358-0.201,0.523-0.079c0.068,0.051,1.673,1.199,3.263,0.002c0.163-0.124,0.399-0.092,0.525,0.074
				c0.124,0.165,0.091,0.4-0.074,0.525C-115.531,110.606-116.233,110.787-116.875,110.787z"
            />
            <Path
              d="M-114.435,114.648h-0.21c-1.158,0-2.1-0.941-2.1-2.1v-2.032c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
				v2.032c0,0.744,0.605,1.35,1.35,1.35h0.21c0.744,0,1.35-0.605,1.35-1.35v-4.411c0-0.207,0.168-0.375,0.375-0.375
				s0.375,0.168,0.375,0.375v4.411C-112.335,113.707-113.276,114.648-114.435,114.648z"
            />
            <Path
              d="M-111.85,112.93h-0.824c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h0.824
				c0.207,0,0.375,0.168,0.375,0.375S-111.643,112.93-111.85,112.93z"
            />
            <Path
              d="M-109.678,114.489h-0.829c-0.929,0-1.684-0.755-1.684-1.684s0.755-1.685,1.684-1.685h0.171
				c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-0.171c-0.515,0-0.934,0.419-0.934,0.935
				c0,0.515,0.419,0.934,0.934,0.934h0.829c0.17,0,0.328-0.066,0.448-0.187c0.119-0.12,0.185-0.28,0.183-0.451l-0.026-2.824
				c-0.125-2.434-1.585-4.595-3.811-5.624c-0.188-0.088-0.271-0.311-0.184-0.498s0.312-0.27,0.498-0.184
				c2.479,1.147,4.106,3.555,4.245,6.282l0.027,2.841c0.003,0.372-0.139,0.723-0.4,0.986
				C-108.958,114.344-109.307,114.489-109.678,114.489z"
            />
            <Path
              d="M-108.628,111.124c-0.128,0-0.254-0.066-0.323-0.186c-0.105-0.178-0.045-0.408,0.134-0.513
				c1.256-0.738,1.618-1.886,1.622-1.896c0.25-0.731,0.163-1.369-0.032-1.871c-0.089,1.805-1.51,2.508-1.528,2.517
				c-0.184,0.088-0.409,0.01-0.5-0.178c-0.089-0.186-0.01-0.409,0.176-0.499c0.069-0.034,1.662-0.853,0.899-3.22
				c-0.052-0.158,0.008-0.332,0.146-0.425c0.137-0.094,0.32-0.086,0.449,0.02c0.077,0.064,1.892,1.588,1.104,3.888
				c-0.014,0.046-0.45,1.428-1.956,2.312C-108.498,111.107-108.564,111.124-108.628,111.124z"
            />
            <Path
              d="M-116.83,105.306c-1.173,0-1.987-0.854-1.999-0.868c-0.095-0.101-0.089-0.258,0.012-0.353
				c0.102-0.094,0.26-0.088,0.354,0.012c0.062,0.065,1.515,1.567,3.271-0.015c0.102-0.093,0.261-0.085,0.353,0.019
				c0.093,0.103,0.084,0.26-0.019,0.353C-115.568,105.093-116.243,105.306-116.83,105.306z"
            />
            <Path
              d="M-116.894,105.246c-0.138,0-0.25-0.112-0.25-0.25v-1.537c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.537
				C-116.644,105.134-116.756,105.246-116.894,105.246z"
            />
            <Circle cx="-115.3" cy="100.841" r="0.305" />
            <Circle cx="-118.362" cy="100.841" r="0.305" />
            <Ellipse cx="-116.888" cy="102.32" rx="1.333" ry="0.619" />
            <Path d="M-117.975,102.264l-0.246,0.057c0,0,0.115,1.059,1.299,1.229l0.085,0.017l0.131-1.07L-117.975,102.264z" />
            <Path d="M-115.796,102.264l0.246,0.057c0,0-0.115,1.059-1.299,1.229l-0.085,0.017l-0.131-1.07L-115.796,102.264z" />
            <Path
              d="M-121.853,103.523c-0.612,0-1.484-0.227-1.793-1.113c-0.459-1.32,0.251-2.819,2.11-4.457
				c2.074-1.828,3.426-1.224,3.48-1.197c0.188,0.089,0.269,0.312,0.181,0.499c-0.088,0.187-0.308,0.264-0.493,0.184
				c-0.034-0.014-1.027-0.37-2.672,1.077c-1.608,1.417-2.247,2.644-1.897,3.648c0.255,0.737,1.255,0.602,1.299,0.595
				c0.198-0.033,0.395,0.111,0.425,0.316c0.03,0.204-0.109,0.395-0.313,0.426C-121.621,103.514-121.732,103.523-121.853,103.523z"
            />
            <Path
              d="M-117.088,107.342c-0.039,0-3.939-0.038-4.556-3.32c-0.594-3.159,1.73-5.792,1.829-5.903
				c0.139-0.152,0.377-0.166,0.529-0.028c0.154,0.139,0.167,0.375,0.029,0.529c-0.021,0.024-2.176,2.474-1.651,5.264
				c0.504,2.678,3.786,2.709,3.819,2.709c0.207,0,0.375,0.168,0.375,0.375S-116.881,107.342-117.088,107.342z"
            />
            <Path
              d="M-112.188,103.523c-0.121,0-0.231-0.009-0.326-0.022c-0.204-0.031-0.344-0.222-0.313-0.426s0.218-0.346,0.426-0.316
				c0.041,0.007,1.042,0.143,1.297-0.595c0.35-1.004-0.288-2.231-1.896-3.648c-1.67-1.471-2.665-1.079-2.674-1.077
				c-0.126,0.052-0.279,0.036-0.385-0.049c-0.002-0.001-0.391-0.294-0.971-0.294c-0.551,0-0.853,0.28-0.865,0.292
				c-0.149,0.142-0.385,0.139-0.528-0.009c-0.144-0.146-0.144-0.38,0.001-0.525c0.053-0.052,0.533-0.508,1.393-0.508
				c0.584,0,1.031,0.206,1.263,0.342c0.467-0.105,1.643-0.16,3.263,1.266c1.858,1.638,2.568,3.137,2.109,4.457
				C-110.704,103.296-111.576,103.523-112.188,103.523z"
            />
            <Path
              d="M-116.953,107.342c-0.207,0-0.374-0.168-0.375-0.374c0-0.207,0.168-0.375,0.374-0.376c0.136,0,3.318-0.041,3.82-2.709
				c0.524-2.79-1.63-5.239-1.651-5.264c-0.138-0.154-0.125-0.391,0.029-0.529c0.153-0.138,0.392-0.124,0.529,0.028
				c0.099,0.111,2.423,2.744,1.829,5.903C-113.014,107.304-116.914,107.342-116.953,107.342z"
            />
          </G>
        </G>
        <Path
          d="M-93.329,108.902c-0.088,0-0.174-0.047-0.219-0.13c-0.255-0.462-0.555-0.896-0.894-1.289
		c-0.09-0.104-0.078-0.263,0.026-0.353c0.104-0.091,0.262-0.078,0.353,0.026c0.361,0.419,0.682,0.882,0.952,1.375
		c0.066,0.121,0.022,0.272-0.099,0.339C-93.247,108.893-93.288,108.902-93.329,108.902z"
        />
        <Path
          d="M-95.465,106.442c-0.052,0-0.104-0.016-0.149-0.05c-2.035-1.516-4.32-1.596-4.343-1.597
		c-0.138-0.004-0.247-0.119-0.243-0.257c0.004-0.136,0.115-0.243,0.25-0.243c0.002,0,0.004,0,0.007,0
		c0.1,0.003,2.461,0.082,4.628,1.696c0.11,0.082,0.134,0.239,0.051,0.35C-95.314,106.407-95.389,106.442-95.465,106.442z"
        />
        <Path
          d="M-230.207,82.727c-1.002,0-1.817-0.815-1.817-1.817v-1.545c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.545
		c0,0.589,0.479,1.067,1.067,1.067s1.067-0.479,1.067-1.067v-6.772c0-0.909,0.74-1.648,1.649-1.648s1.649,0.739,1.649,1.648v2.263
		c0,0.557,0.453,1.009,1.009,1.009s1.009-0.452,1.009-1.009v-6.502c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v6.502
		c0,0.97-0.789,1.759-1.759,1.759s-1.759-0.789-1.759-1.759v-2.263c0-0.495-0.403-0.898-0.899-0.898s-0.899,0.403-0.899,0.898v6.772
		C-228.389,81.911-229.205,82.727-230.207,82.727z"
        />
        <Path
          d="M-161.951,77.13c-1.122,0-2.035-0.913-2.035-2.035v-2.798c0-0.647-0.526-1.174-1.173-1.174
		c-0.647,0-1.174,0.526-1.174,1.174V73c0,1.087-0.884,1.971-1.97,1.971s-1.971-0.884-1.971-1.971v-2.841l-0.994-0.006h-52.192
		c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375l53.563,0.008c0.207,0.001,0.373,0.169,0.373,0.375V73
		c0,0.673,0.547,1.221,1.221,1.221c0.673,0,1.22-0.548,1.22-1.221v-0.703c0-1.061,0.863-1.924,1.924-1.924
		c1.061,0,1.923,0.863,1.923,1.924v2.798c0,0.709,0.576,1.285,1.285,1.285s1.285-0.576,1.285-1.285v-1.9
		c0-1.064,0.865-1.93,1.93-1.93c1.063,0,1.929,0.865,1.929,1.93c0,0.753,0.556,1.309,1.238,1.309s1.238-0.556,1.238-1.238v-3.502
		c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v3.502c0,1.097-0.892,1.988-1.988,1.988s-1.988-0.892-1.988-1.988
		c0-0.721-0.528-1.25-1.179-1.25s-1.18,0.529-1.18,1.18v1.9C-159.916,76.217-160.829,77.13-161.951,77.13z"
        />
        <Path
          d="M-156.213,35.319c-0.885,0-1.604-0.72-1.604-1.604v-1.871c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.871
		c0,0.471,0.384,0.854,0.854,0.854s0.854-0.383,0.854-0.854v-1.763c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.763
		C-154.609,34.6-155.329,35.319-156.213,35.319z"
        />
        <Path d="M-177.977,113.967c0,0,9.291-3.66,16.518-4.411s14.078-1.22,23.745,1.22s16.14,3.212,16.14,3.212" fill="#FFFFFF" />
        <Path
          d="M-176.68,113.542c0,0,10.59,0.34,15.742-0.834c5.152-1.174,12.041-3.861,29.91-0.209
		c0,0-15.587-4.565-24.065-3.522C-163.571,110.02-171.593,110.933-176.68,113.542z"
          fill="#DCE2E7"
        />
        <Path
          d="M-177.201,113.786c-0.153,0-0.297-0.095-0.353-0.247c-0.071-0.194,0.03-0.409,0.224-0.48
		c0.085-0.031,8.643-3.128,15.833-3.875c7.366-0.766,14.246-1.202,23.875,1.229c9.497,2.397,13.766,2.521,13.808,2.522
		c0.207,0.005,0.371,0.177,0.366,0.384s-0.184,0.382-0.384,0.366c-0.174-0.004-4.376-0.123-13.974-2.546
		c-9.508-2.399-16.32-1.97-23.613-1.21c-7.097,0.737-15.569,3.803-15.654,3.834C-177.115,113.779-177.159,113.786-177.201,113.786z"
        />
        <Path
          d="M-49.523,63.077h-9.293c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h9.293c0.138,0,0.25,0.112,0.25,0.25
		S-49.385,63.077-49.523,63.077z"
        />
        <Path
          d="M-66.437,83.872h-9.293c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h9.293c0.138,0,0.25,0.112,0.25,0.25
		S-66.299,83.872-66.437,83.872z"
        />
        <Path
          d="M-59.158,83.872h-5.599c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h5.599c0.138,0,0.25,0.112,0.25,0.25
		S-59.021,83.872-59.158,83.872z"
        />
        <Path
          d="M-60.658,47.02L-60.658,47.02c-0.63,0-1.141-0.511-1.141-1.141v-4.761h2.283v4.761
		C-59.517,46.509-60.028,47.02-60.658,47.02z"
          fill="#FFFFFF"
        />
        <Path
          d="M-57.397,48.18L-57.397,48.18c-0.412,0-0.745-0.334-0.745-0.745v-3.108h1.49v3.108
		C-56.652,47.847-56.986,48.18-57.397,48.18z"
          fill="#FFFFFF"
        />
        <Path
          d="M-273.068,63.576L-273.068,63.576c-0.63,0-1.141-0.511-1.141-1.141v-4.761h2.283v4.761
		C-271.927,63.065-272.438,63.576-273.068,63.576z"
          fill="#FFFFFF"
        />
        <Path
          d="M-269.807,61.606L-269.807,61.606c-0.412,0-0.745-0.334-0.745-0.745v-3.108h1.49v3.108
		C-269.062,61.272-269.396,61.606-269.807,61.606z"
          fill="#FFFFFF"
        />
        <Path
          d="M-115.754,60.387v1.763c0,0.679-0.55,1.229-1.229,1.229l0,0c-0.679,0-1.229-0.55-1.229-1.229v-0.889
		c0-0.543-0.44-0.982-0.982-0.982l0,0c-0.543,0-0.982-0.44-0.982-0.982v-5.787l4.035,4.158l0.416,1.37L-115.754,60.387z"
          fill="#FFFFFF"
        />
        <Path
          d="M-116.984,63.754c-0.884,0-1.604-0.72-1.604-1.604v-0.914c0-0.321-0.262-0.582-0.583-0.582
		c-0.735,0-1.333-0.598-1.333-1.333v-4.761c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v4.761
		c0,0.321,0.262,0.583,0.583,0.583c0.735,0,1.333,0.598,1.333,1.332v0.914c0,0.471,0.383,0.854,0.854,0.854s0.854-0.383,0.854-0.854
		v-2.919c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.919C-115.379,63.034-116.099,63.754-116.984,63.754z"
        />
        <Circle cx="-249.919" cy="49.205" fill="#FFFFFF" r="1.076" />
        <Circle cx="-278.614" cy="105.031" fill="#FFFFFF" r="1.076" />
        <Circle cx="-238.962" cy="86.933" fill="#FFFFFF" r="1.348" />
        <Circle cx="-242.835" cy="61.531" fill="#FFFFFF" r="2.002" />
        <Circle cx="-39.748" cy="58.596" fill="#FFFFFF" r="2.002" />
        <Circle cx="-254.704" cy="96.749" fill="#FFFFFF" r="1.779" />
        <Circle cx="-85.767" cy="66.52" fill="#FFFFFF" r="1.779" />
        <Circle cx="-288.748" cy="96.749" fill="#FFFFFF" r="2.002" />
        <Circle cx="-255.723" cy="62.02" fill="#FFFFFF" r="0.791" />
        <Circle cx="-96.881" cy="76.853" fill="#FFFFFF" r="1.177" />
        <Circle cx="-60.72" cy="90.781" fill="#FFFFFF" r="1.177" />
        <Circle cx="-37.242" cy="75.716" fill="#FFFFFF" r="1.177" />
        <Circle cx="-89.131" cy="90.339" fill="#FFFFFF" r="2.19" />
        <Circle cx="-136.235" cy="99.005" fill="#FFFFFF" r="1.779" />
        <Circle cx="-126.113" cy="87.699" fill="#FFFFFF" r="1.177" />
        <Circle cx="-135.065" cy="74.979" fill="#FFFFFF" r="2.19" />
        <Circle cx="-103.231" cy="90.873" fill="#FFFFFF" r="0.865" />
        <Circle cx="-264.274" cy="76.646" fill="#FFFFFF" r="1.177" />
        <Circle cx="-279.561" cy="79.521" fill="#FFFFFF" r="2.19" />
        <Circle cx="-274.149" cy="69.358" fill="#FFFFFF" r="0.865" />
        <Circle cx="-118.441" cy="75.52" fill="#FFFFFF" r="0.865" />
        <Circle cx="-67.571" cy="70.711" fill="#FFFFFF" r="0.865" />
        <Circle cx="-240.536" cy="39.585" fill="#FFFFFF" r="0.791" />
        <Circle cx="-250.188" cy="79.759" fill="#FFFFFF" r="0.791" />
        <Circle cx="-83.07" cy="43.825" fill="#FFFFFF" r="1.589" />
        <Circle cx="-57.202" cy="72.781" fill="#FFFFFF" r="1.589" />
        <Circle cx="-101.633" cy="55.4" fill="#FFFFFF" r="2.098" />
        <Circle cx="-162.676" cy="20.769" fill="#FFFFFF" r="2.098" />
        <Circle cx="-96.307" cy="35.366" fill="#FFFFFF" r="0.933" />
        <Circle cx="-228.919" cy="46.824" fill="#FFFFFF" r="1.369" />
        <Circle cx="-188.754" cy="18.562" fill="#FFFFFF" r="1.199" />
        <Circle cx="-143.484" cy="21.716" fill="#FFFFFF" r="1.199" />
        <Circle cx="-209.284" cy="16.827" fill="#FFFFFF" r="0.881" />
        <Circle cx="-114.136" cy="44.02" fill="#FFFFFF" r="0.881" />
        <Circle cx="-179.304" cy="11.988" fill="#FFFFFF" r="0.881" />
        <Circle cx="-200.227" cy="34.219" fill="#FFFFFF" r="0.881" />
        <Circle cx="-177.3" cy="29.285" fill="#FFFFFF" r="1.525" />
        <Path
          d="M-117.114,108.672c-0.104,0-0.208-0.003-0.312-0.008c-2.437-0.127-3.682-1.742-3.733-1.812
		c-0.083-0.11-0.062-0.267,0.049-0.35c0.109-0.083,0.267-0.062,0.35,0.048c0.012,0.016,1.166,1.499,3.36,1.613
		c1.501,0.071,3.037-0.461,3.902-1.374c0.568-0.601,0.813-1.312,0.727-2.116c-0.015-0.137,0.085-0.261,0.223-0.275
		c0.131-0.021,0.26,0.084,0.275,0.223c0.101,0.942-0.197,1.812-0.862,2.513C-114.035,108.085-115.57,108.672-117.114,108.672z"
        />
        <Path
          d="M-125.626,107.317c-0.426,0-0.846-0.1-1.258-0.3c-0.099-0.047-0.154-0.152-0.139-0.26l0.207-1.469
		c0.011-0.08,0.061-0.15,0.133-0.188c0.073-0.037,0.158-0.036,0.229,0.001c0.877,0.457,1.784,0.36,2.779-0.297
		c1.127-0.745,2.676,0.133,2.741,0.171c0.119,0.069,0.16,0.222,0.092,0.342c-0.07,0.12-0.226,0.158-0.342,0.092
		c-0.014-0.008-1.347-0.766-2.216-0.187c-1.027,0.679-2.026,0.832-2.969,0.467l-0.135,0.951c0.841,0.338,1.693,0.189,2.604-0.454
		c1.104-0.781,2.679,0.053,2.745,0.089c0.122,0.064,0.167,0.216,0.102,0.338c-0.064,0.122-0.219,0.165-0.338,0.103
		c-0.014-0.008-1.37-0.719-2.22-0.121C-124.29,107.076-124.965,107.317-125.626,107.317z"
        />
      </G>
      <G>
        <Path
          d="M25.027,113.172c0,0-14.283-2.926-14.283-22.5c0-24.065,26.436-29.771,37.369-45
		c16.435-22.891,23.478-28.37,44.804-35.022c21.326-6.652,53.022-6.652,69.848,0.978c7.494,3.399,22.304,11.348,37.565,12.522
		c15.261,1.174,53.021,6.261,69.26,26.022c16.496,20.074,11.152,57.521-20.935,62.804
		C248.657,112.976,24.734,112.878,25.027,113.172"
          fill="#E6EBEF"
        />
        <Path
          d="M211.244,113.802c0,0,7.239-2.022,8.804-2.087c1.565-0.065,5.022,0.652,9.065,1.109
		c4.043,0.457,7.956-1.239,11.413-1.239s13.109,1.761,13.109,1.761l-6.456,2.283l-35.413-0.065L211.244,113.802z"
          fill="#FFFFFF"
        />
        <Polyline fill="#FFFFFF" points="159.831,113.465 75.603,113.465 75.603,66.02 159.831,66.02 159.831,113.465 	" />
        <Path
          d="M159.339,69.308l-1.861,1.785c-0.344,0.33-0.802,0.514-1.278,0.514h-2.926v-1.834h3.228l2.715-2.69
		L159.339,69.308z"
          fill="#BAC2CA"
        />
        <Rect fill="#BAC2CA" height="1.63" width="53.674" x="83.744" y="69.781" />
        <Path d="M76.125,69.672h-5.429c-0.711,0-1.288-0.577-1.288-1.288s0.577-1.288,1.288-1.288h5.429V69.672z" fill="#FFFFFF" />
        <Path
          d="M90.472,33.162c2.263,0,3.796-2.289,2.95-4.387c-1.791-4.445-5.58-10.77-12.788-11.517
		c-3.035-0.314-5.856,0.886-8.075,2.367c-0.198,0.132-0.455,0.106-0.63-0.056c-1.532-1.418-3.739-2.31-6.195-2.31
		c-4.625,0-8.375,3.158-8.375,7.053v0.006c0,0.303-0.258,0.541-0.559,0.514c-0.224-0.02-0.452-0.031-0.683-0.031
		c-2.843,0-5.252,1.551-6.108,3.702c-0.107,0.268-0.407,0.393-0.671,0.277c-0.754-0.331-1.611-0.542-2.52-0.518
		c-2.075,0.055-3.429,1.212-4.27,2.389c-0.752,1.052-0.016,2.512,1.277,2.512H90.472z"
          fill="#FFFFFF"
        />
        <Path
          d="M90.472,33.412H43.824c-0.698,0-1.305-0.37-1.624-0.989c-0.317-0.617-0.262-1.352,0.143-1.919
		c1.131-1.583,2.676-2.446,4.466-2.493c0.871-0.013,1.759,0.159,2.627,0.54l0.198,0.003l0.14-0.144
		c0.919-2.309,3.467-3.86,6.341-3.86c0.238,0,0.474,0.011,0.706,0.032l0.2-0.068l0.086-0.197c0-4.033,3.869-7.309,8.625-7.309
		c2.415,0,4.734,0.866,6.365,2.376c0.093,0.086,0.222,0.099,0.322,0.031c2.808-1.874,5.579-2.686,8.24-2.407
		c3.947,0.409,9.405,2.766,12.994,11.672c0.43,1.066,0.303,2.27-0.339,3.22C92.677,32.848,91.614,33.412,90.472,33.412z
		M46.957,28.509l-0.135,0.002c-1.65,0.043-3.021,0.812-4.072,2.284c-0.301,0.42-0.34,0.943-0.105,1.399
		c0.231,0.449,0.672,0.717,1.179,0.717h46.648c0.99,0,1.875-0.47,2.429-1.29c0.548-0.812,0.657-1.842,0.289-2.754
		c-1.93-4.789-5.679-10.646-12.582-11.362c-2.537-0.263-5.203,0.52-7.911,2.326c-0.292,0.195-0.679,0.16-0.939-0.081
		c-1.539-1.425-3.735-2.243-6.025-2.243c-4.48,0-8.125,3.051-8.125,6.802c0,0.221-0.091,0.428-0.249,0.572
		c-0.157,0.144-0.366,0.215-0.582,0.197c-0.218-0.02-0.438-0.03-0.661-0.03c-2.671,0-5.032,1.424-5.876,3.544
		c-0.077,0.193-0.224,0.343-0.415,0.421c-0.191,0.078-0.4,0.075-0.589-0.008C48.48,28.677,47.714,28.509,46.957,28.509z"
        />
        <Rect fill="#E6EBEF" height="2.108" width="1.768" x="148.092" y="32.02" />
        <Rect fill="#E6EBEF" height="2.108" width="4.88" x="151.611" y="32.02" />
        <Path
          d="M75.695,97.315c-0.774-0.127-1.569-0.193-2.38-0.193c-4.835,0-9.118,2.348-11.779,5.965
		c-0.533,0.724-1.47,0.991-2.32,0.7c-1.035-0.355-2.145-0.548-3.3-0.548c-3.213,0-6.079,1.486-7.948,3.809
		c0,0-2.037,2.669-2.2,6.158h30.033L75.695,97.315z"
          fill="#FFFFFF"
        />
        <Path d="M34.636,113.427c0,0,8.56-2.446,13.109-1.467c4.549,0.978,3.13,2.543,3.13,2.543L34.636,113.427z" fill="#FFFFFF" />
        <Polyline fill={svgHighlight} points="107.551,113.345 107.551,81.973 124.693,81.973 124.693,113.345 	" />
        <Path
          d="M124.693,113.72c-0.207,0-0.375-0.168-0.375-0.375V82.349h-16.393v30.996c0,0.207-0.168,0.375-0.375,0.375
		s-0.375-0.168-0.375-0.375V81.974c0-0.207,0.168-0.375,0.375-0.375h17.143c0.207,0,0.375,0.168,0.375,0.375v31.371
		C125.068,113.552,124.9,113.72,124.693,113.72z"
        />
        <Path
          d="M116.534,89.886c0-0.409-0.331-0.74-0.74-0.74c-0.409,0-0.74,0.331-0.74,0.74c0,0.409,0.331,0.74,0.74,0.74
		C116.203,90.626,116.534,90.295,116.534,89.886z"
          fill="#FFFFFF"
        />
        <Path
          d="M115.794,90.876c-0.546,0-0.99-0.444-0.99-0.99s0.444-0.989,0.99-0.989s0.99,0.443,0.99,0.989
		S116.34,90.876,115.794,90.876z M115.794,89.397c-0.27,0-0.49,0.22-0.49,0.489c0,0.271,0.22,0.49,0.49,0.49
		c0.271,0,0.49-0.22,0.49-0.49C116.285,89.616,116.065,89.397,115.794,89.397z"
        />
        <Path
          d="M48.937,106.185c0,0,3.111-2.537,8.126-1.39c5.015,1.148,5.619-0.06,7.129-1.873
		c1.51-1.812,7.341-4.38,11.721-3.142v-2.386c0,0-5.105-1.118-9.395,1.42c-4.29,2.537-4.894,4.501-5.558,4.773
		s-0.937,0.423-2.991-0.151c-2.054-0.574-6.736,0.121-9.123,2.87"
          fill="#DCE2E7"
        />
        <Polygon fill="#FFFFFF" points="70.194,66.273 93.285,41.118 178.058,41.118 154.516,66.763 117.035,66.763 	" />
        <Rect fill="#FFFFFF" height="1.875" width="1.484" x="147.413" y="29.933" />
        <Rect fill="#FFFFFF" height="15.383" width="12.693" x="148.285" y="30.006" />
        <Rect fill="#FFFFFF" height="5.378" width="8.298" x="148.175" y="40.192" />
        <Path
          d="M157.023,29.971v1.952l-0.434,0.087v13.749l4.294-4.684v-9.151c0,0,0.607-0.477,0.564-0.651
		c-0.043-0.174-0.217-1.128-0.217-1.128l-0.304-0.304L157.023,29.971z"
          fill="#DCE2E7"
        />
        <Path
          d="M222.325,113.627c0.01-0.136,0.015-0.274,0.015-0.412c0-3.092-2.506-5.598-5.598-5.598
		c-0.116,0-0.231,0.005-0.346,0.012c-1.84-3.137-5.246-5.246-9.146-5.246c-0.251,0-0.501,0.009-0.748,0.026
		c-1.036,0.073-2.028-0.435-2.55-1.332c-1.836-3.154-5.252-5.275-9.165-5.275v17.775"
          fill="#FFFFFF"
        />
        <Path
          d="M231.82,29.06c1.308,0,2.011-1.535,1.159-2.528c-1.436-1.673-4.314-2.816-7.632-2.816
		c-0.54,0-1.067,0.032-1.578,0.091c-0.561,0.065-1.049-0.386-1.048-0.951v-0.012c0-2.891-3.657-5.234-8.168-5.234
		c-1.256,0-2.445,0.183-3.508,0.508c-0.497,0.152-1.034-0.146-1.146-0.654c-0.948-4.295-5.548-7.556-11.094-7.556
		c-6.233,0-11.286,4.117-11.286,9.195c0,0.182,0.021,0.359,0.033,0.538c-0.26-0.04-0.525-0.067-0.797-0.067
		c-2.33,0-4.302,1.524-4.979,3.629c-0.152,0.473-0.645,0.741-1.123,0.605c-0.452-0.129-0.929-0.199-1.422-0.199
		c-1.66,0-3.14,0.773-4.099,1.979c-1.115,1.402-0.107,3.474,1.684,3.474H231.82z"
          fill="#FFFFFF"
        />
        <Path
          d="M231.819,29.31h-55.003c-0.928,0-1.755-0.52-2.159-1.357c-0.402-0.833-0.295-1.799,0.279-2.522
		c1.048-1.317,2.613-2.073,4.295-2.073c0.505,0,1.007,0.07,1.49,0.209c0.354,0.098,0.705-0.096,0.816-0.441
		c0.732-2.274,2.829-3.802,5.218-3.802c0.166,0,0.339,0.01,0.522,0.029l-0.01-0.25c0-5.208,5.175-9.444,11.536-9.444
		c5.578,0,10.347,3.261,11.338,7.753c0.038,0.171,0.145,0.315,0.3,0.406c0.161,0.094,0.354,0.116,0.529,0.062
		c1.125-0.344,2.329-0.519,3.581-0.519c4.642,0,8.418,2.46,8.418,5.484c0,0.215,0.086,0.409,0.238,0.544
		c0.148,0.132,0.331,0.193,0.532,0.17c0.528-0.062,1.069-0.093,1.606-0.093c3.288,0,6.285,1.112,7.821,2.903
		c0.459,0.535,0.562,1.264,0.268,1.904C233.142,28.913,232.522,29.31,231.819,29.31z M179.231,23.858
		c-1.528,0-2.951,0.687-3.904,1.884c-0.46,0.579-0.542,1.325-0.22,1.993c0.32,0.663,0.975,1.075,1.709,1.075h55.003
		c0.506,0,0.951-0.285,1.162-0.745c0.212-0.46,0.139-0.985-0.191-1.37c-1.444-1.683-4.296-2.729-7.442-2.729
		c-0.518,0-1.039,0.03-1.549,0.09c-0.336,0.036-0.67-0.069-0.923-0.294c-0.258-0.23-0.406-0.561-0.405-0.906
		c0-2.76-3.552-4.996-7.918-4.996c-1.202,0-2.357,0.167-3.435,0.497c-0.311,0.094-0.647,0.055-0.928-0.109
		c-0.277-0.162-0.468-0.421-0.536-0.729c-0.941-4.265-5.504-7.361-10.85-7.361c-6.085,0-11.036,4.012-11.036,8.944
		c0,0.129,0.011,0.255,0.022,0.382l0.011,0.137l-0.079,0.203l-0.208,0.063c-0.28-0.043-0.528-0.064-0.759-0.064
		c-2.171,0-4.076,1.389-4.741,3.456c-0.194,0.604-0.822,0.942-1.43,0.769C180.144,23.922,179.689,23.858,179.231,23.858z"
        />
        <Path d="M148.821,100.146h-1.349c-0.508,0-0.921,0.412-0.921,0.921v0.241c0,0.508,0.412,0.921,0.921,0.921h1.349" fill="#E0E0E0" />
        <Path d="M98.163,100.146h-1.349c-0.508,0-0.921,0.412-0.921,0.921v0.241c0,0.508,0.412,0.921,0.921,0.921h1.349" fill="#E0E0E0" />
        <Polyline fill="#E6EBEF" points="148.532,99.929 135.848,99.929 135.848,80.412 148.532,80.412 148.532,99.929 	" />
        <Path d="M135.848,98.756l-0.511,1.173h0.511V98.756 M149.693,80.412h-5.855h4.694v2.667L149.693,80.412" fill="#FFFFFF" />
        <Polyline
          fill="#F8F9FA"
          points="148.532,80.412 143.838,80.412 135.848,98.756 135.848,99.929 141.192,99.929 148.532,83.078
		148.532,80.412 	"
        />
        <Polyline fill="#E6EBEF" points="97.787,99.929 85.104,99.929 85.104,80.412 97.787,80.412 97.787,99.929 	" />
        <Polyline
          fill="#FFFFFF"
          points="90.448,80.412 84.593,80.412 76.092,99.929 81.947,99.929 85.104,92.681 85.104,80.412
		90.448,80.412 	"
        />
        <Polyline fill="#F8F9FA" points="90.448,80.412 85.104,80.412 85.104,92.681 90.448,80.412 	" />
        <Polyline fill="#FFFFFF" points="100.553,80.412 96.91,80.412 97.787,80.412 97.787,86.763 100.553,80.412 	" />
        <Polyline fill="#F8F9FA" points="97.787,80.412 96.91,80.412 88.41,99.929 92.053,99.929 97.787,86.763 97.787,80.412 	" />
        <Path
          d="M170.078,49.946l7.395-8.137c0.3-0.33,0.74-0.516,1.173-0.508c0.446,0.007,0.867,0.205,1.156,0.545
		L200.909,66.6c0.556,0.652,0.478,1.632-0.174,2.188c-0.653,0.556-1.632,0.478-2.188-0.174L178.584,45.2l-21.888,24.082
		c-0.306,0.337-0.727,0.508-1.149,0.508c-0.372,0-0.746-0.133-1.043-0.404c-0.634-0.576-0.681-1.558-0.105-2.192l13.462-14.812"
          fill="#FFFFFF"
        />
        <Polygon
          fill="#BAC2CA"
          points="178.621,45.281 159.538,66.273 159.538,113.331 194.582,113.331 194.582,69.222 198.919,69.222
		"
        />
        <Polygon fill="#BAC2CA" points="177.191,47.044 194.788,67.498 194.788,64.549 178.629,45.484 	" />
        <Polygon fill="#BAC2CA" points="194.799,64.321 194.799,69.244 199.028,69.352 	" />
        <Path
          d="M180.183,62.716c0,1.832-0.864,3.318-1.929,3.318s-1.929-1.485-1.929-3.318
		c0-1.832,0.864-3.318,1.929-3.318S180.183,60.884,180.183,62.716z"
          fill="#EFF1EF"
        />
        <Path
          d="M156.531,46.096h-8.376c-0.207,0-0.375-0.168-0.375-0.375V32.023c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v13.323h7.841l4.177-4.375v-8.878c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.028c0,0.097-0.037,0.189-0.104,0.259
		l-4.392,4.6C156.731,46.054,156.634,46.096,156.531,46.096z"
        />
        <Path
          d="M156.512,39.98c-0.138,0-0.25-0.112-0.25-0.25v-7.762c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v7.762
		C156.762,39.868,156.649,39.98,156.512,39.98z"
        />
        <Path
          d="M156.512,43.504c-0.138,0-0.25-0.112-0.25-0.25v-2.31c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.31
		C156.762,43.392,156.649,43.504,156.512,43.504z"
        />
        <Path
          d="M160.739,32.261h-8.514c-0.207,0-0.375-0.168-0.375-0.375c0-0.207,0.168-0.375,0.375-0.375h8.514
		c0.202,0,0.366-0.164,0.366-0.366v-0.554c0-0.202-0.164-0.366-0.366-0.366h-12.791c-0.202,0-0.366,0.164-0.366,0.366v0.554
		c0,0.202,0.164,0.366,0.366,0.366h1.691c0.207,0,0.375,0.168,0.375,0.375c0,0.207-0.168,0.375-0.375,0.375h-1.691
		c-0.615,0-1.116-0.5-1.116-1.116v-0.554c0-0.615,0.501-1.116,1.116-1.116h12.791c0.615,0,1.116,0.501,1.116,1.116v0.554
		C161.855,31.761,161.354,32.261,160.739,32.261z"
        />
        <Path
          d="M156.96,31.933c-0.138,0-0.25-0.112-0.25-0.25v-1.711c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.711
		C157.21,31.821,157.098,31.933,156.96,31.933z"
        />
        <Path
          d="M178.68,41.554h-15.887c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h15.887c0.207,0,0.375,0.168,0.375,0.375
		S178.887,41.554,178.68,41.554z"
        />
        <Path
          d="M113.998,67.138H69.811c-0.148,0-0.283-0.088-0.343-0.224c-0.06-0.137-0.034-0.295,0.066-0.404l23.474-25.646
		c0.071-0.077,0.172-0.122,0.277-0.122h9.919c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375H93.45L70.662,66.388h43.336
		c0.207,0,0.375,0.168,0.375,0.375S114.205,67.138,113.998,67.138z"
        />
        <Path
          d="M106.857,41.493h-1.446c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.446c0.207,0,0.375,0.168,0.375,0.375
		S107.064,41.493,106.857,41.493z"
        />
        <Path
          d="M148.112,41.493h-39.204c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h39.204
		c0.207,0,0.375,0.168,0.375,0.375S148.319,41.493,148.112,41.493z"
        />
        <Path
          d="M75.66,70.153h-5.357c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h5.357c0.207,0,0.375,0.168,0.375,0.375
		S75.867,70.153,75.66,70.153z"
        />
        <Path
          d="M155.95,70.153h-4.551c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.551c0.207,0,0.375,0.168,0.375,0.375
		S156.157,70.153,155.95,70.153z"
        />
        <Path
          d="M75.63,111.943c-0.207,0-0.375-0.168-0.375-0.375V69.95c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v41.618
		C76.005,111.775,75.837,111.943,75.63,111.943z"
        />
        <Path
          d="M159.453,113.419c-0.207,0-0.375-0.168-0.375-0.375V92.296c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v20.748C159.828,113.251,159.66,113.419,159.453,113.419z"
        />
        <Path
          d="M159.453,89.433c-0.207,0-0.375-0.168-0.375-0.375V66.14c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v22.918
		C159.828,89.265,159.66,89.433,159.453,89.433z"
        />
        <Path
          d="M194.808,113.419c-0.207,0-0.375-0.168-0.375-0.375V65.307c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v47.737C195.183,113.251,195.016,113.419,194.808,113.419z"
        />
        <Path
          d="M199.153,69.565h-4.345c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.345c0.207,0,0.375,0.168,0.375,0.375
		S199.36,69.565,199.153,69.565z"
        />
        <Path
          d="M18.374,113.761c-0.171,0-0.325-0.117-0.365-0.291c-0.046-0.202,0.08-0.403,0.282-0.449l3.846-0.883
		c3.005-0.689,6.14-0.544,9.066,0.429c0.889,0.295,1.815,0.444,2.754,0.444l6.58-1.369c2.078-0.431,4.2-0.508,6.307-0.234l9.1,1.188
		c2.087,0.273,4.198,0.243,6.274-0.091l6.617-1.062c3.243-0.521,6.624-0.317,9.78,0.588l0.48,0.138
		c1.951,0.56,3.97,0.843,6.002,0.843h124.912c1.869,0,3.728-0.259,5.522-0.768l0.26-0.074c3.289-0.934,6.796-1.048,10.139-0.328
		l0.866,0.187c2.966,0.636,6.091,0.597,9.034-0.121c2.63-0.639,5.319-0.765,7.998-0.373l8.64,1.265
		c0.965,0.142,1.951,0.213,2.93,0.213h6.601c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-6.601
		c-1.015,0-2.037-0.074-3.039-0.221l-8.64-1.265c-2.581-0.379-5.178-0.258-7.711,0.359c-3.055,0.742-6.296,0.788-9.37,0.125
		l-0.866-0.187c-3.226-0.692-6.605-0.582-9.775,0.316l-0.259,0.074c-1.863,0.529-3.79,0.797-5.729,0.797H85.096
		c-2.102,0-4.191-0.293-6.208-0.872l-0.48-0.138c-3.051-0.875-6.322-1.071-9.454-0.568l-6.617,1.062
		c-2.149,0.345-4.333,0.376-6.49,0.095l-9.099-1.188c-2.024-0.264-4.062-0.188-6.058,0.225l-6.656,1.377
		c-1.125-0.004-2.101-0.155-3.066-0.476c-2.794-0.928-5.79-1.068-8.662-0.409l-3.846,0.883L18.374,113.761z"
        />
        <Path
          d="M98.004,100.481H83.846c-0.207,0-0.375-0.168-0.375-0.375V79.193c0-0.207,0.168-0.375,0.375-0.375h14.158
		c0.207,0,0.375,0.168,0.375,0.375v20.913C98.379,100.314,98.211,100.481,98.004,100.481z M84.221,99.731h13.408V79.568H84.221
		V99.731z"
        />
        <Path
          d="M85.123,98.986c-0.138,0-0.25-0.112-0.25-0.25V80.431c0-0.138,0.112-0.25,0.25-0.25H98.11c0.138,0,0.25,0.112,0.25,0.25
		s-0.112,0.25-0.25,0.25H85.373v18.056C85.373,98.874,85.261,98.986,85.123,98.986z"
        />
        <Path
          d="M98.091,102.603H87.716c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h10v-1.332H82.945
		c-0.301,0-0.546,0.245-0.546,0.546v0.24c0,0.301,0.245,0.546,0.546,0.546h3.296c0.207,0,0.375,0.168,0.375,0.375
		s-0.168,0.375-0.375,0.375h-3.296c-0.714,0-1.296-0.581-1.296-1.296v-0.24c0-0.715,0.582-1.296,1.296-1.296h15.146
		c0.207,0,0.375,0.168,0.375,0.375v2.082C98.466,102.435,98.298,102.603,98.091,102.603z"
        />
        <Path
          d="M98.048,102.478h-1.349c-0.646,0-1.171-0.525-1.171-1.171v-0.24c0-0.646,0.525-1.171,1.171-1.171h1.349
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-1.349c-0.37,0-0.671,0.301-0.671,0.671v0.24c0,0.37,0.301,0.671,0.671,0.671
		h1.349c0.138,0,0.25,0.112,0.25,0.25S98.186,102.478,98.048,102.478z"
        />
        <Path
          d="M91.185,98.693c-0.207,0-0.375-0.168-0.375-0.375V88.63c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.688
		C91.56,98.525,91.392,98.693,91.185,98.693z"
        />
        <Path
          d="M91.185,86.989c-0.207,0-0.375-0.168-0.375-0.375v-5.008c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v5.008
		C91.56,86.821,91.392,86.989,91.185,86.989z"
        />
        <Path
          d="M97.99,90.424H86.27c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h11.72c0.207,0,0.375,0.168,0.375,0.375
		S98.197,90.424,97.99,90.424z"
        />
        <Path
          d="M148.777,100.481h-14.158c-0.207,0-0.375-0.168-0.375-0.375v-7.001c0-0.207,0.168-0.375,0.375-0.375
		s0.375,0.168,0.375,0.375v6.626h13.408V79.568h-13.408v12.033c0,0.207-0.168,0.375-0.375,0.375s-0.375-0.168-0.375-0.375V79.193
		c0-0.207,0.168-0.375,0.375-0.375h14.158c0.207,0,0.375,0.168,0.375,0.375v20.913C149.152,100.314,148.984,100.481,148.777,100.481
		z"
        />
        <Path
          d="M135.896,99.054c-0.138,0-0.25-0.112-0.25-0.25V80.465c0-0.138,0.112-0.25,0.25-0.25h11.734c0.138,0,0.25,0.112,0.25,0.25
		s-0.112,0.25-0.25,0.25h-11.484v18.089C136.146,98.941,136.034,99.054,135.896,99.054z"
        />
        <Path
          d="M148.864,102.603h-15.146c-0.714,0-1.295-0.581-1.295-1.296v-0.24c0-0.715,0.581-1.296,1.295-1.296h15.146
		c0.207,0,0.375,0.168,0.375,0.375v2.082C149.239,102.435,149.071,102.603,148.864,102.603z M133.718,100.521
		c-0.301,0-0.545,0.245-0.545,0.546v0.24c0,0.301,0.245,0.546,0.545,0.546h14.771v-1.332H133.718z"
        />
        <Path
          d="M148.821,102.478h-1.35c-0.646,0-1.171-0.525-1.171-1.171v-0.24c0-0.646,0.525-1.171,1.171-1.171h1.35
		c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-1.35c-0.37,0-0.671,0.301-0.671,0.671v0.24c0,0.37,0.301,0.671,0.671,0.671
		h1.35c0.138,0,0.25,0.112,0.25,0.25S148.959,102.478,148.821,102.478z"
        />
        <Path
          d="M141.958,98.693c-0.207,0-0.375-0.168-0.375-0.375v-4.902c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v4.902
		C142.333,98.525,142.165,98.693,141.958,98.693z"
        />
        <Path
          d="M141.958,92.197c-0.207,0-0.375-0.168-0.375-0.375v-9.826c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.826
		C142.333,92.029,142.165,92.197,141.958,92.197z"
        />
        <Path
          d="M148.763,90.424h-11.388c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h11.388
		c0.207,0,0.375,0.168,0.375,0.375S148.97,90.424,148.763,90.424z"
        />
        <Path
          d="M155.548,70.166c-0.48,0-0.94-0.179-1.296-0.502c-0.381-0.346-0.604-0.819-0.628-1.334
		c-0.024-0.514,0.152-1.007,0.499-1.388l12.861-14.155c0.141-0.154,0.378-0.165,0.529-0.025c0.153,0.139,0.165,0.376,0.025,0.529
		l-12.861,14.156c-0.212,0.232-0.32,0.533-0.305,0.848c0.015,0.313,0.151,0.603,0.384,0.814c0.464,0.422,1.242,0.383,1.662-0.079
		l21.888-24.083c0.072-0.08,0.174-0.135,0.283-0.123c0.107,0.002,0.21,0.05,0.279,0.132l19.964,23.414
		c0.204,0.239,0.488,0.385,0.802,0.409c0.32,0.028,0.619-0.074,0.857-0.277c0.493-0.422,0.553-1.166,0.132-1.659l-21.107-24.755
		c-0.22-0.257-0.539-0.408-0.877-0.413h-0.02c-0.325,0-0.648,0.143-0.869,0.385l-7.171,7.83c-0.141,0.154-0.377,0.164-0.529,0.023
		c-0.153-0.14-0.163-0.377-0.023-0.529l7.17-7.83c0.367-0.404,0.958-0.632,1.455-0.629c0.553,0.008,1.076,0.255,1.435,0.677
		l21.107,24.755c0.689,0.808,0.593,2.026-0.216,2.716c-0.392,0.334-0.896,0.494-1.402,0.455c-0.514-0.041-0.98-0.279-1.313-0.671
		l-19.688-23.09l-21.602,23.767C156.609,69.936,156.09,70.166,155.548,70.166z"
        />
        <Path
          d="M168.475,52.083l-0.252-0.098c-0.153-0.14-0.165-0.377-0.025-0.529l0.698-0.768c0.141-0.154,0.378-0.164,0.529-0.025
		c0.153,0.14,0.165,0.377,0.025,0.529l-0.698,0.768C168.679,52.042,168.577,52.083,168.475,52.083z"
        />
        <Path
          d="M72.666,70.14h-1.995c-1.044,0-1.893-0.85-1.893-1.894s0.849-1.893,1.893-1.893h1.995c0.207,0,0.375,0.168,0.375,0.375
		s-0.168,0.375-0.375,0.375h-1.995c-0.63,0-1.143,0.513-1.143,1.143c0,0.631,0.513,1.144,1.143,1.144h1.995
		c0.207,0,0.375,0.168,0.375,0.375S72.873,70.14,72.666,70.14z"
        />
        <Path
          d="M45.873,112.039l-0.065-0.006c-0.204-0.035-0.341-0.229-0.305-0.434c0.23-1.316,0.704-2.562,1.407-3.703
		c0.108-0.176,0.339-0.232,0.516-0.123c0.176,0.108,0.231,0.34,0.123,0.516c-0.653,1.06-1.093,2.217-1.306,3.439
		C46.21,111.911,46.052,112.039,45.873,112.039z"
        />
        <Path
          d="M47.967,107.423l-0.235-0.083c-0.161-0.13-0.187-0.366-0.057-0.527c2.02-2.509,5.023-3.948,8.24-3.948
		c1.173,0,2.324,0.191,3.422,0.568c0.708,0.243,1.47,0.014,1.896-0.567c2.819-3.831,7.335-6.118,12.082-6.118
		c0.814,0,1.636,0.066,2.44,0.197c0.205,0.033,0.343,0.226,0.31,0.431c-0.033,0.204-0.232,0.338-0.43,0.31
		c-0.765-0.124-1.545-0.188-2.32-0.188c-4.508,0-8.799,2.173-11.477,5.812c-0.623,0.846-1.725,1.18-2.744,0.832
		c-1.02-0.35-2.089-0.527-3.179-0.527c-2.989,0-5.779,1.337-7.656,3.669C48.185,107.375,48.076,107.423,47.967,107.423z"
        />
        <Path
          d="M178.752,66.734c-1.665,0-3.021-1.841-3.021-4.104c0-2.264,1.355-4.105,3.021-4.105s3.021,1.842,3.021,4.105
		C181.772,64.894,180.417,66.734,178.752,66.734z M178.752,59.274c-1.252,0-2.271,1.505-2.271,3.355c0,1.85,1.019,3.354,2.271,3.354
		s2.271-1.505,2.271-3.354C181.022,60.779,180.004,59.274,178.752,59.274z"
        />
        <Path
          d="M178.258,66.565c-0.107,0-0.207-0.07-0.239-0.178c-0.04-0.133,0.035-0.272,0.167-0.312
		c1.109-0.333,1.914-1.767,1.914-3.409c0-1.174-0.407-2.263-1.089-2.912c-0.1-0.095-0.104-0.253-0.008-0.354
		c0.094-0.099,0.252-0.105,0.354-0.008c0.778,0.741,1.243,1.966,1.243,3.273c0,1.889-0.934,3.487-2.27,3.888L178.258,66.565z"
        />
        <Path
          d="M222.132,112.073c-0.164,0-0.314-0.108-0.361-0.273c-0.629-2.242-2.697-3.809-5.029-3.809
		c-0.108,0-0.217,0.005-0.323,0.011c-0.138,0.023-0.274-0.062-0.346-0.185c-1.831-3.121-5.212-5.06-8.823-5.06
		c-0.168,0-0.336,0.004-0.502,0.013c-1.33,0.062-2.555-0.584-3.232-1.693c-1.874-3.068-5.138-4.9-8.729-4.9
		c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375c3.854,0,7.356,1.966,9.369,5.26c0.534,0.874,1.519,1.404,2.554,1.334
		c0.179-0.009,0.358-0.013,0.54-0.013c3.801,0,7.363,2,9.353,5.235c2.73-0.05,5.159,1.744,5.892,4.354
		c0.056,0.199-0.061,0.406-0.26,0.463L222.132,112.073z"
        />
        <Path
          d="M265.238,113.769h-1.702c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.702c0.207,0,0.375,0.168,0.375,0.375
		S265.445,113.769,265.238,113.769z"
        />
        <Path
          d="M154.623,67.168h-38.051c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h38.051
		c0.207,0,0.375,0.168,0.375,0.375S154.83,67.168,154.623,67.168z"
        />
        <Path
          d="M48.132,78.645l0.01,0C42.686,45.708,35.03,47.184,35.03,47.184l-0.012,0.112l-0.01-0.085
		c0,0-7.348-1.502-12.803,31.435l0.032-0.001c-0.162,1.008-0.252,2.047-0.252,3.112c0,8.888,5.91,16.093,13.199,16.093
		s13.199-7.205,13.199-16.093C48.383,80.692,48.294,79.653,48.132,78.645z"
          fill="#DCE2E7"
        />
        <Path
          d="M48.383,81.757c0-1.065-0.09-2.104-0.252-3.112l0.01,0C42.686,45.708,35.03,47.184,35.03,47.184
		l-0.012,0.112l-0.01-0.085c0,0-0.471-0.095-1.261,0.294c1.946,3.956,8.108,17.381,8.719,29.898
		c0.56,11.48-3.237,17.405-7.395,20.44l0.114,0.007C42.474,97.85,48.383,90.645,48.383,81.757z"
          fill="#BAC2CA"
        />
        <Path
          d="M28.831,53.933c0,0,0.848,3.848,5.152,4.109s9.978,3.717,11.543,8.152c0,0-2.609-12.065-6.913-16.565
		s-6.326-0.848-6.913-0.065C31.114,50.346,28.831,53.933,28.831,53.933z"
          fill="#FFFFFF"
        />
        <Path
          d="M35.184,98.225c-7.485,0-13.574-7.388-13.574-16.468c0-0.999,0.079-2.019,0.234-3.032l-0.01-0.141
		c3.806-22.976,8.6-29.312,11.118-31.045c0.956-0.658,1.657-0.725,1.965-0.713l0.042-0.01c0.095-0.019,0.969-0.153,2.294,0.77
		c2.585,1.8,7.484,8.22,11.258,30.999l0.004,0.088c0.161,1.027,0.243,2.065,0.243,3.085C48.758,90.837,42.669,98.225,35.184,98.225z
		M22.598,78.568l0.009,0.136c-0.164,1.021-0.247,2.048-0.247,3.053c0,8.667,5.753,15.718,12.824,15.718s12.824-7.051,12.824-15.718
		c0-1.008-0.083-2.035-0.247-3.052l-0.004-0.083c-3.727-22.438-8.448-28.691-10.932-30.421c-0.728-0.506-1.268-0.624-1.536-0.647
		c-0.069,0.072-0.163,0.092-0.272,0.117c-0.093,0-0.178-0.034-0.244-0.09C33.7,47.677,27.428,49.523,22.598,78.568z"
        />
        <Path
          d="M35.019,113.287c-0.207,0-0.375-0.168-0.375-0.375V81.584c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v31.328
		C35.394,113.119,35.226,113.287,35.019,113.287z"
        />
        <Path
          d="M246.715,112.612c-0.207,0-0.375-0.168-0.375-0.375V81.584c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v30.653C247.09,112.444,246.922,112.612,246.715,112.612z"
        />
        <Path
          d="M34.875,88.582l-0.217-0.069l-4.817-3.432c-0.168-0.12-0.208-0.354-0.088-0.523c0.12-0.168,0.354-0.208,0.523-0.088
		l4.817,3.432c0.168,0.12,0.208,0.354,0.088,0.523C35.108,88.527,34.993,88.582,34.875,88.582z"
        />
        <Path
          d="M35.141,92.607c-0.117,0-0.233-0.055-0.306-0.157c-0.12-0.169-0.081-0.403,0.088-0.523l4.818-3.432
		c0.169-0.12,0.404-0.08,0.523,0.088c0.12,0.169,0.081,0.403-0.088,0.523l-4.818,3.432L35.141,92.607z"
        />
        <Path
          d="M26.363,70.732l-0.04-0.003c-0.136-0.021-0.229-0.149-0.208-0.286c0.736-4.655,2.278-9.326,3.442-12.426
		c0.048-0.13,0.192-0.194,0.322-0.146c0.129,0.049,0.195,0.193,0.146,0.322c-1.155,3.077-2.686,7.715-3.416,12.328
		C26.59,70.645,26.483,70.732,26.363,70.732z"
        />
        <Polygon
          fill="#BAC2CA"
          points="267.424,101.542 261.766,83.736 265.427,83.736 257.606,62.935 261.433,62.935 247.122,27.324
		246.285,27.324 231.974,62.935 235.801,62.935 227.98,83.736 231.641,83.736 225.983,101.542 	"
        />
        <Path
          d="M242.124,37.107c0,0,3.196,7.402,12.815,10.109l-7.817-19.891c0,0-0.889-0.065-0.987,0.196
		C246.037,27.781,242.124,37.107,242.124,37.107z"
          fill="#FFFFFF"
        />
        <Path
          d="M228.657,82.237c0,0,2.543-2.87,7.043-2.739s4.565,2.935,9.196,1.435c4.63-1.5,7.37-0.522,8.804,0.326
		c1.435,0.848,3.978,2.087,3.978,2.087h-29.478L228.657,82.237z"
          fill="#FFFFFF"
        />
        <Path
          d="M266.508,99.823c0,0-3.495-3.942-9.677-3.763c-6.182,0.179-6.272,4.032-12.634,1.971
		s-10.125-0.717-12.096,0.448c-1.971,1.165-5.466,2.867-5.466,2.867h40.5L266.508,99.823z"
          fill="#FFFFFF"
        />
        <Path
          d="M261.135,62.868H241.57c0,0,4.239-0.456,6.913-1.37c2.674-0.913,4.109-2.022,7.043-1.565
		c2.935,0.457,5.217,1.761,5.217,1.761L261.135,62.868z"
          fill="#FFFFFF"
        />
        <Path
          d="M267.424,101.917h-41.44c-0.119,0-0.232-0.057-0.303-0.153s-0.091-0.222-0.055-0.335l5.502-17.317h-3.147
		c-0.123,0-0.238-0.061-0.309-0.161c-0.069-0.102-0.086-0.23-0.042-0.346l7.629-20.294h-3.285c-0.125,0-0.241-0.062-0.311-0.165
		c-0.07-0.104-0.084-0.234-0.037-0.35l14.312-35.611c0.057-0.142,0.194-0.235,0.348-0.235h0.837c0.153,0,0.291,0.093,0.348,0.235
		l14.312,35.611c0.047,0.115,0.033,0.246-0.037,0.35c-0.069,0.104-0.186,0.165-0.311,0.165h-3.285l7.629,20.294
		c0.044,0.115,0.027,0.244-0.042,0.346c-0.07,0.101-0.186,0.161-0.309,0.161h-3.147l5.502,17.317
		c0.036,0.113,0.016,0.238-0.055,0.335S267.543,101.917,267.424,101.917z M226.496,101.167h40.415l-5.502-17.317
		c-0.036-0.113-0.016-0.238,0.055-0.335s0.184-0.153,0.303-0.153h3.118l-7.629-20.294c-0.044-0.115-0.027-0.244,0.042-0.346
		c0.07-0.101,0.186-0.161,0.309-0.161h3.272l-14.011-34.861h-0.329l-14.011,34.861h3.272c0.123,0,0.238,0.061,0.309,0.161
		c0.069,0.102,0.086,0.23,0.042,0.346l-7.629,20.294h3.118c0.119,0,0.232,0.057,0.303,0.153s0.091,0.222,0.055,0.335
		L226.496,101.167z"
        />
        <Path
          d="M199.384,105.163c0.88,2.732-1.093,3.673-1.093,3.673l0.012,0.082c-0.51-2.009-1.898-3.715-3.822-4.605
		l-0.119-0.057c0.028-0.1,0.06-0.196,0.08-0.303c0.068-0.36,0.092-0.713,0.087-1.057l0.222,0.234c0,0,1.343,0.205,1.708-0.842
		c0.364-1.047-0.091-2.368-2.004-4.053c-1.912-1.685-3.074-1.138-3.074-1.138s-0.474-0.376-1.203-0.376
		c-0.729,0-1.127,0.398-1.127,0.398l-0.072,0.004l0.015-0.027c0,0-1.161-0.546-3.074,1.138c-1.913,1.685-2.368,3.005-2.004,4.053
		c0.364,1.047,1.708,0.842,1.708,0.842l0.23-0.412c-0.019,0.4-0.001,0.812,0.078,1.235c0.109,0.582,0.334,1.05,0.624,1.429
		l-0.001,0.073c-0.012,0.014-0.533,0.634-0.556,1.58c-0.015,0.631-0.01,3.563-0.005,5.488c0.002,0.951,0.774,1.719,1.725,1.719
		h0.256c0.953,0,1.725-0.772,1.725-1.725v-2.055l-0.082-0.131c0.362,0.086,0.784,0.115,1.232,0.033l0.043,0.119l-0.055,0.036v2.032
		c0,0.953,0.772,1.725,1.725,1.725h0.211c0.953,0,1.725-0.772,1.725-1.725v-0.015h0.922c-0.018,0.088-0.029,0.178-0.029,0.271
		c0,0.723,0.586,1.309,1.309,1.309h0.829c0.559,0,1.011-0.456,1.006-1.015l-0.026-2.812l0.07,0.461
		c1.396-0.82,1.791-2.11,1.791-2.11C201.084,106.559,199.384,105.163,199.384,105.163z"
          fill="#FFFFFF"
        />
        <Path
          d="M185.979,106.497c0,0-1.492-0.803-2.483-0.105c-0.836,0.59-1.88,0.954-3.022,0.401l0.207-1.469
		c1.125,0.587,2.179,0.255,3.033-0.31c1.011-0.668,2.478,0.179,2.478,0.179"
          fill={svgHighlight}
        />
        <Path
          d="M180.641,105.517l-0.104-0.025l-0.749-0.393c-0.11-0.058-0.152-0.193-0.095-0.304c0.058-0.111,0.193-0.152,0.304-0.095
		l0.749,0.393c0.11,0.058,0.152,0.193,0.095,0.304L180.641,105.517z"
        />
        <Path
          d="M180.451,106.234l-0.104-0.025l-0.749-0.393c-0.11-0.058-0.152-0.193-0.095-0.304s0.193-0.152,0.304-0.095l0.749,0.393
		c0.11,0.058,0.152,0.193,0.095,0.304L180.451,106.234z"
        />
        <Path
          d="M180.366,106.991l-0.103-0.024l-0.799-0.409c-0.11-0.057-0.154-0.192-0.098-0.303s0.193-0.152,0.303-0.098l0.799,0.409
		c0.11,0.057,0.154,0.192,0.098,0.303L180.366,106.991z"
        />
        <Path
          d="M186.29,106.702c0,0,1.198,1.59,3.546,1.712c2.348,0.122,5.16-1.247,4.891-3.766
		c0,0-1.484,2.014-3.783,2.307c-2.299,0.293-4.389-1.501-4.389-1.501L186.29,106.702z"
          fill={svgHighlight}
        />
        <Path
          d="M188.017,114.614h-0.256c-1.155,0-2.098-0.938-2.1-2.092c-0.006-2.173-0.009-4.891,0.005-5.498
		c0.026-1.077,0.622-1.787,0.647-1.816c0.134-0.159,0.372-0.178,0.528-0.042c0.157,0.134,0.177,0.369,0.043,0.527
		c-0.004,0.005-0.449,0.547-0.469,1.349c-0.014,0.6-0.011,3.311-0.005,5.479c0.002,0.741,0.607,1.344,1.35,1.344h0.256
		c0.744,0,1.35-0.605,1.35-1.35v-2.055c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.055
		C190.117,113.673,189.176,114.614,188.017,114.614z"
        />
        <Path
          d="M190.375,110.787c-0.919,0-1.717-0.37-2.143-0.687c-0.166-0.123-0.2-0.357-0.077-0.523
		c0.122-0.166,0.358-0.201,0.523-0.079c0.068,0.051,1.673,1.199,3.263,0.002c0.163-0.124,0.399-0.092,0.525,0.074
		c0.124,0.165,0.091,0.4-0.074,0.525C191.719,110.606,191.017,110.787,190.375,110.787z"
        />
        <Path
          d="M192.815,114.648h-0.21c-1.158,0-2.1-0.941-2.1-2.1v-2.032c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.032
		c0,0.744,0.605,1.35,1.35,1.35h0.21c0.744,0,1.35-0.605,1.35-1.35v-4.411c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
		v4.411C194.915,113.707,193.974,114.648,192.815,114.648z"
        />
        <Path
          d="M195.4,112.93h-0.824c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h0.824c0.207,0,0.375,0.168,0.375,0.375
		S195.607,112.93,195.4,112.93z"
        />
        <Path
          d="M197.572,114.489h-0.829c-0.929,0-1.684-0.755-1.684-1.684s0.755-1.685,1.684-1.685h0.171c0.207,0,0.375,0.168,0.375,0.375
		s-0.168,0.375-0.375,0.375h-0.171c-0.515,0-0.934,0.419-0.934,0.935c0,0.515,0.419,0.934,0.934,0.934h0.829
		c0.17,0,0.328-0.066,0.448-0.187c0.119-0.12,0.185-0.28,0.183-0.451l-0.026-2.824c-0.125-2.434-1.585-4.595-3.811-5.624
		c-0.188-0.088-0.271-0.311-0.184-0.498s0.312-0.27,0.498-0.184c2.479,1.147,4.106,3.555,4.245,6.282l0.027,2.841
		c0.003,0.372-0.139,0.723-0.4,0.986C198.292,114.344,197.943,114.489,197.572,114.489z"
        />
        <Path
          d="M198.622,111.124c-0.128,0-0.254-0.066-0.323-0.186c-0.105-0.178-0.045-0.408,0.134-0.513
		c1.256-0.738,1.618-1.886,1.622-1.896c0.25-0.731,0.163-1.369-0.032-1.871c-0.089,1.805-1.51,2.508-1.528,2.517
		c-0.184,0.088-0.409,0.01-0.5-0.178c-0.089-0.186-0.01-0.409,0.176-0.499c0.069-0.034,1.662-0.853,0.899-3.22
		c-0.052-0.158,0.008-0.332,0.146-0.425c0.137-0.094,0.32-0.086,0.449,0.02c0.077,0.064,1.892,1.588,1.104,3.888
		c-0.014,0.046-0.45,1.428-1.956,2.312L198.622,111.124z"
        />
        <Path
          d="M190.42,105.306c-1.173,0-1.987-0.854-1.999-0.868c-0.095-0.101-0.089-0.258,0.012-0.353
		c0.102-0.094,0.26-0.088,0.354,0.012c0.062,0.065,1.515,1.567,3.271-0.015c0.102-0.093,0.261-0.085,0.353,0.019
		c0.093,0.103,0.084,0.26-0.019,0.353C191.682,105.093,191.007,105.306,190.42,105.306z"
        />
        <Path
          d="M190.356,105.246c-0.138,0-0.25-0.112-0.25-0.25v-1.537c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.537
		C190.606,105.134,190.494,105.246,190.356,105.246z"
        />
        <Path
          d="M192.256,100.841c0-0.169-0.137-0.305-0.305-0.305c-0.168,0-0.305,0.137-0.305,0.305s0.137,0.305,0.305,0.305
		C192.119,101.146,192.256,101.009,192.256,100.841z"
        />
        <Path
          d="M189.193,100.841c0-0.169-0.137-0.305-0.305-0.305c-0.168,0-0.305,0.137-0.305,0.305s0.137,0.305,0.305,0.305
		C189.057,101.146,189.193,101.009,189.193,100.841z"
        />
        <Path
          d="M191.695,102.32c0,0.342-0.597,0.619-1.333,0.619c-0.736,0-1.333-0.277-1.333-0.619s0.597-0.619,1.333-0.619
		C191.098,101.701,191.695,101.979,191.695,102.32z"
        />
        <Path d="M189.275,102.264l-0.246,0.057c0,0,0.115,1.059,1.299,1.229l0.085,0.017l0.131-1.07L189.275,102.264z" />
        <Path d="M191.454,102.264l0.246,0.057c0,0-0.115,1.059-1.299,1.229l-0.085,0.017l-0.131-1.07L191.454,102.264z" />
        <Path
          d="M185.397,103.523c-0.612,0-1.484-0.227-1.793-1.113c-0.459-1.32,0.251-2.819,2.11-4.457
		c2.074-1.828,3.426-1.224,3.48-1.197c0.188,0.089,0.269,0.312,0.181,0.499c-0.088,0.187-0.308,0.264-0.493,0.184
		c-0.034-0.014-1.027-0.37-2.672,1.077c-1.608,1.417-2.247,2.644-1.897,3.648c0.255,0.737,1.255,0.602,1.299,0.595
		c0.198-0.033,0.395,0.111,0.425,0.316c0.03,0.204-0.109,0.395-0.313,0.426C185.629,103.514,185.518,103.523,185.397,103.523z"
        />
        <Path
          d="M190.162,107.342c-0.039,0-3.939-0.038-4.556-3.32c-0.594-3.159,1.73-5.792,1.829-5.903
		c0.139-0.152,0.377-0.166,0.529-0.028c0.154,0.139,0.167,0.375,0.029,0.529c-0.021,0.024-2.176,2.474-1.651,5.264
		c0.504,2.678,3.786,2.709,3.819,2.709c0.207,0,0.375,0.168,0.375,0.375S190.369,107.342,190.162,107.342z"
        />
        <Path
          d="M195.062,103.523c-0.121,0-0.231-0.009-0.326-0.022c-0.204-0.031-0.344-0.222-0.313-0.426s0.218-0.346,0.426-0.316
		c0.041,0.007,1.042,0.143,1.297-0.595c0.35-1.004-0.288-2.231-1.896-3.648c-1.67-1.471-2.665-1.079-2.674-1.077
		c-0.126,0.052-0.279,0.036-0.385-0.049c-0.002-0.001-0.391-0.294-0.971-0.294c-0.551,0-0.853,0.28-0.865,0.292
		c-0.149,0.142-0.385,0.139-0.528-0.009c-0.144-0.146-0.144-0.38,0.001-0.525c0.053-0.052,0.533-0.508,1.393-0.508
		c0.584,0,1.031,0.206,1.263,0.342c0.467-0.105,1.643-0.16,3.263,1.266c1.858,1.638,2.568,3.137,2.109,4.457
		C196.546,103.296,195.674,103.523,195.062,103.523z"
        />
        <Path
          d="M190.297,107.342c-0.207,0-0.374-0.168-0.375-0.374c0-0.207,0.168-0.375,0.374-0.376c0.136,0,3.318-0.041,3.82-2.709
		c0.524-2.79-1.63-5.239-1.651-5.264c-0.138-0.154-0.125-0.391,0.029-0.529c0.153-0.138,0.392-0.124,0.529,0.028
		c0.099,0.111,2.423,2.744,1.829,5.903C194.236,107.304,190.336,107.342,190.297,107.342z"
        />
        <Path
          d="M213.921,108.902l-0.219-0.13c-0.255-0.462-0.555-0.896-0.894-1.289c-0.09-0.104-0.078-0.263,0.026-0.353
		c0.104-0.091,0.262-0.078,0.353,0.026c0.361,0.419,0.682,0.882,0.952,1.375c0.066,0.121,0.022,0.272-0.099,0.339L213.921,108.902z"
        />
        <Path
          d="M211.785,106.442l-0.149-0.05c-2.035-1.516-4.32-1.596-4.343-1.597c-0.138-0.004-0.247-0.119-0.243-0.257
		c0.004-0.136,0.115-0.243,0.25-0.243h0.007c0.1,0.003,2.461,0.082,4.628,1.696c0.11,0.082,0.134,0.239,0.051,0.35L211.785,106.442z
		"
        />
        <Path
          d="M77.043,82.727c-1.002,0-1.817-0.815-1.817-1.817v-1.545c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.545
		c0,0.589,0.479,1.067,1.067,1.067s1.067-0.479,1.067-1.067v-6.772c0-0.909,0.74-1.648,1.649-1.648s1.649,0.739,1.649,1.648v2.263
		c0,0.557,0.453,1.009,1.009,1.009s1.009-0.452,1.009-1.009v-6.502c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v6.502
		c0,0.97-0.789,1.759-1.759,1.759s-1.759-0.789-1.759-1.759v-2.263c0-0.495-0.403-0.898-0.899-0.898s-0.899,0.403-0.899,0.898v6.772
		C78.861,81.911,78.045,82.727,77.043,82.727z"
        />
        <Path
          d="M145.299,77.13c-1.122,0-2.035-0.913-2.035-2.035v-2.798c0-0.647-0.526-1.174-1.173-1.174
		c-0.647,0-1.174,0.526-1.174,1.174V73c0,1.087-0.884,1.971-1.97,1.971s-1.971-0.884-1.971-1.971v-2.841l-0.994-0.006H83.789
		c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375l53.563,0.008c0.207,0.001,0.373,0.169,0.373,0.375V73
		c0,0.673,0.547,1.221,1.221,1.221c0.673,0,1.22-0.548,1.22-1.221v-0.703c0-1.061,0.863-1.924,1.924-1.924
		c1.061,0,1.923,0.863,1.923,1.924v2.798c0,0.709,0.576,1.285,1.285,1.285s1.285-0.576,1.285-1.285v-1.9
		c0-1.064,0.865-1.93,1.93-1.93c1.063,0,1.929,0.865,1.929,1.93c0,0.753,0.556,1.309,1.238,1.309s1.238-0.556,1.238-1.238v-3.502
		c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v3.502c0,1.097-0.892,1.988-1.988,1.988s-1.988-0.892-1.988-1.988
		c0-0.721-0.528-1.25-1.179-1.25s-1.18,0.529-1.18,1.18v1.9C147.334,76.217,146.421,77.13,145.299,77.13z"
        />
        <Path
          d="M151.037,35.319c-0.885,0-1.604-0.72-1.604-1.604v-1.871c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.871
		c0,0.471,0.384,0.854,0.854,0.854s0.854-0.383,0.854-0.854v-1.763c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v1.763
		C152.641,34.6,151.921,35.319,151.037,35.319z"
        />
        <Path
          d="M129.273,113.967c0,0,9.292-3.66,16.518-4.411c7.227-0.751,14.078-1.22,23.745,1.22
		s16.14,3.212,16.14,3.212"
          fill="#FFFFFF"
        />
        <Path
          d="M130.57,113.542c0,0,10.59,0.34,15.742-0.834c5.152-1.174,12.041-3.861,29.91-0.209
		c0,0-15.587-4.565-24.065-3.522S135.657,110.933,130.57,113.542z"
          fill="#DCE2E7"
        />
        <Path
          d="M130.049,113.786c-0.153,0-0.297-0.095-0.353-0.247c-0.071-0.194,0.03-0.409,0.224-0.48
		c0.085-0.031,8.643-3.128,15.833-3.875c7.366-0.766,14.246-1.202,23.875,1.229c9.497,2.397,13.766,2.521,13.808,2.522
		c0.207,0.005,0.371,0.177,0.366,0.384s-0.184,0.382-0.384,0.366c-0.174-0.004-4.376-0.123-13.974-2.546
		c-9.508-2.399-16.32-1.97-23.613-1.21c-7.097,0.737-15.569,3.803-15.654,3.834L130.049,113.786z"
        />
        <Path
          d="M257.727,63.077h-9.293c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h9.293c0.138,0,0.25,0.112,0.25,0.25
		S257.865,63.077,257.727,63.077z"
        />
        <Path
          d="M240.813,83.872h-9.293c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h9.293c0.138,0,0.25,0.112,0.25,0.25
		S240.951,83.872,240.813,83.872z"
        />
        <Path
          d="M248.092,83.872h-5.599c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h5.599c0.138,0,0.25,0.112,0.25,0.25
		S248.229,83.872,248.092,83.872z"
        />
        <Path
          d="M246.592,47.02L246.592,47.02c-0.63,0-1.141-0.511-1.141-1.141v-4.761h2.283v4.761
		C247.733,46.509,247.222,47.02,246.592,47.02z"
          fill="#FFFFFF"
        />
        <Path
          d="M249.853,48.18L249.853,48.18c-0.411,0-0.745-0.334-0.745-0.745v-3.108h1.49v3.108
		C250.598,47.847,250.264,48.18,249.853,48.18z"
          fill="#FFFFFF"
        />
        <Path
          d="M34.182,63.576L34.182,63.576c-0.63,0-1.141-0.511-1.141-1.141v-4.761h2.283v4.761
		C35.323,63.065,34.812,63.576,34.182,63.576z"
          fill="#FFFFFF"
        />
        <Path
          d="M37.443,61.606L37.443,61.606c-0.411,0-0.745-0.334-0.745-0.745v-3.108h1.49v3.108
		C38.188,61.272,37.854,61.606,37.443,61.606z"
          fill="#FFFFFF"
        />
        <Path
          d="M191.496,60.387v1.763c0,0.679-0.55,1.229-1.229,1.229s-1.229-0.55-1.229-1.229v-0.889
		c0-0.542-0.44-0.982-0.982-0.982c-0.543,0-0.982-0.44-0.982-0.982v-5.787l4.035,4.158l0.416,1.37L191.496,60.387z"
          fill="#FFFFFF"
        />
        <Path
          d="M190.266,63.754c-0.884,0-1.604-0.72-1.604-1.604v-0.914c0-0.321-0.262-0.582-0.583-0.582
		c-0.735,0-1.333-0.598-1.333-1.333v-4.761c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v4.761
		c0,0.321,0.262,0.583,0.583,0.583c0.735,0,1.333,0.598,1.333,1.332v0.914c0,0.471,0.383,0.854,0.854,0.854s0.854-0.383,0.854-0.854
		v-2.919c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.919C191.871,63.034,191.151,63.754,190.266,63.754z"
        />
        <Path
          d="M58.407,49.205c0-0.594-0.482-1.076-1.076-1.076c-0.594,0-1.076,0.482-1.076,1.076s0.482,1.076,1.076,1.076
		C57.926,50.281,58.407,49.799,58.407,49.205z"
          fill="#FFFFFF"
        />
        <Path
          d="M29.712,105.031c0-0.594-0.482-1.076-1.076-1.076s-1.076,0.482-1.076,1.076s0.482,1.076,1.076,1.076
		S29.712,105.625,29.712,105.031z"
          fill="#FFFFFF"
        />
        <Circle cx="68.288" cy="86.933" fill="#FFFFFF" r="1.348" />
        <Circle cx="64.415" cy="61.531" fill="#FFFFFF" r="2.002" />
        <Path
          d="M269.503,58.596c0-1.105-0.896-2.002-2.002-2.002s-2.002,0.896-2.002,2.002s0.896,2.002,2.002,2.002
		S269.503,59.702,269.503,58.596z"
          fill="#FFFFFF"
        />
        <Path
          d="M54.325,96.749c0-0.983-0.797-1.779-1.779-1.779s-1.779,0.797-1.779,1.779c0,0.983,0.797,1.779,1.779,1.779
		S54.325,97.731,54.325,96.749z"
          fill="#FFFFFF"
        />
        <Path
          d="M223.263,66.52c0-0.983-0.797-1.779-1.78-1.779s-1.779,0.797-1.779,1.779c0,0.983,0.797,1.779,1.779,1.779
		S223.263,67.503,223.263,66.52z"
          fill="#FFFFFF"
        />
        <Path
          d="M20.504,96.749c0-1.105-0.896-2.002-2.002-2.002s-2.002,0.896-2.002,2.002s0.896,2.002,2.002,2.002
		S20.504,97.854,20.504,96.749z"
          fill="#FFFFFF"
        />
        <Circle cx="51.527" cy="62.02" fill="#FFFFFF" r="0.791" />
        <Path
          d="M211.546,76.853c0-0.65-0.527-1.177-1.177-1.177c-0.65,0-1.177,0.527-1.177,1.177s0.527,1.177,1.177,1.177
		C211.019,78.03,211.546,77.503,211.546,76.853z"
          fill="#FFFFFF"
        />
        <Path
          d="M247.707,90.781c0-0.65-0.527-1.177-1.177-1.177c-0.65,0-1.177,0.527-1.177,1.177s0.527,1.177,1.177,1.177
		C247.18,91.958,247.707,91.431,247.707,90.781z"
          fill="#FFFFFF"
        />
        <Path
          d="M271.186,75.716c0-0.65-0.527-1.177-1.177-1.177c-0.65,0-1.177,0.527-1.177,1.177s0.527,1.177,1.177,1.177
		C270.658,76.893,271.186,76.366,271.186,75.716z"
          fill="#FFFFFF"
        />
        <Path
          d="M220.309,90.339c0-1.209-0.98-2.19-2.19-2.19s-2.19,0.98-2.19,2.19c0,1.209,0.98,2.19,2.19,2.19
		S220.309,91.548,220.309,90.339z"
          fill="#FFFFFF"
        />
        <Path
          d="M169.243,99.168c0.09,0.979,0.956,1.699,1.935,1.609c0.979-0.09,1.699-0.956,1.609-1.935
		c-0.09-0.979-0.956-1.699-1.935-1.609C169.874,97.323,169.153,98.19,169.243,99.168z"
          fill="#FFFFFF"
        />
        <Path
          d="M179.965,87.807c0.059,0.647,0.633,1.124,1.28,1.065c0.647-0.06,1.124-0.633,1.065-1.28
		c-0.06-0.647-0.633-1.124-1.28-1.065S179.905,87.159,179.965,87.807z"
          fill="#FFFFFF"
        />
        <Path
          d="M170.004,75.179c0.111,1.204,1.177,2.091,2.381,1.98c1.204-0.111,2.091-1.177,1.98-2.381
		c-0.111-1.204-1.177-2.091-2.381-1.98C170.78,72.909,169.893,73.975,170.004,75.179z"
          fill="#FFFFFF"
        />
        <Path
          d="M204.884,90.873c0-0.478-0.387-0.865-0.865-0.865c-0.478,0-0.865,0.387-0.865,0.865
		s0.387,0.865,0.865,0.865C204.497,91.739,204.884,91.351,204.884,90.873z"
          fill="#FFFFFF"
        />
        <Path
          d="M42.588,77.758c0.614,0.214,1.285-0.11,1.5-0.724c0.214-0.614-0.11-1.285-0.724-1.499
		c-0.614-0.214-1.285,0.11-1.5,0.724C41.65,76.872,41.974,77.543,42.588,77.758z"
          fill="#FFFFFF"
        />
        <Path
          d="M26.968,81.589c1.142,0.398,2.391-0.204,2.789-1.346c0.398-1.142-0.204-2.391-1.346-2.789
		c-1.142-0.398-2.391,0.204-2.789,1.346C25.223,79.942,25.826,81.19,26.968,81.589z"
          fill="#FFFFFF"
        />
        <Path
          d="M32.816,70.175c0.451,0.157,0.945-0.081,1.102-0.532c0.157-0.451-0.081-0.945-0.532-1.102
		s-0.945,0.081-1.102,0.532C32.127,69.524,32.365,70.017,32.816,70.175z"
          fill="#FFFFFF"
        />
        <Path
          d="M189.675,75.52c0-0.478-0.388-0.865-0.865-0.865s-0.865,0.387-0.865,0.865s0.387,0.865,0.865,0.865
		S189.675,75.998,189.675,75.52z"
          fill="#FFFFFF"
        />
        <Path
          d="M240.544,70.711c0-0.478-0.387-0.865-0.865-0.865c-0.478,0-0.865,0.387-0.865,0.865
		s0.387,0.865,0.865,0.865C240.157,71.577,240.544,71.189,240.544,70.711z"
          fill="#FFFFFF"
        />
        <Circle cx="66.714" cy="39.585" fill="#FFFFFF" r="0.791" />
        <Circle cx="57.062" cy="79.759" fill="#FFFFFF" r="0.791" />
        <Path
          d="M225.769,43.825c0-0.878-0.711-1.589-1.589-1.589s-1.589,0.711-1.589,1.589
		c0,0.878,0.711,1.589,1.589,1.589S225.769,44.703,225.769,43.825z"
          fill="#FFFFFF"
        />
        <Path
          d="M251.637,72.781c0-0.878-0.711-1.589-1.589-1.589s-1.589,0.711-1.589,1.589
		c0,0.878,0.711,1.589,1.589,1.589S251.637,73.658,251.637,72.781z"
          fill="#FFFFFF"
        />
        <Path
          d="M207.715,55.4c0-1.159-0.939-2.098-2.098-2.098c-1.159,0-2.098,0.939-2.098,2.098s0.939,2.098,2.098,2.098
		C206.776,57.498,207.715,56.559,207.715,55.4z"
          fill="#FFFFFF"
        />
        <Path
          d="M146.672,20.769c0-1.159-0.939-2.098-2.098-2.098c-1.159,0-2.098,0.939-2.098,2.098
		s0.939,2.098,2.098,2.098C145.732,22.868,146.672,21.928,146.672,20.769z"
          fill="#FFFFFF"
        />
        <Circle cx="210.943" cy="35.366" fill="#FFFFFF" r="0.933" />
        <Path
          d="M79.7,46.824c0-0.756-0.613-1.369-1.369-1.369s-1.369,0.613-1.369,1.369s0.613,1.369,1.369,1.369
		S79.7,47.58,79.7,46.824z"
          fill="#FFFFFF"
        />
        <Path
          d="M119.253,19.491c0.513-0.418,0.59-1.173,0.172-1.687c-0.418-0.513-1.173-0.59-1.687-0.172
		c-0.513,0.418-0.59,1.173-0.172,1.687C117.985,19.832,118.74,19.91,119.253,19.491z"
          fill="#FFFFFF"
        />
        <Path
          d="M164.523,22.645c0.513-0.418,0.59-1.173,0.172-1.687c-0.418-0.513-1.173-0.59-1.687-0.172
		s-0.59,1.173-0.172,1.687C163.255,22.986,164.01,23.063,164.523,22.645z"
          fill="#FFFFFF"
        />
        <Path
          d="M98.522,17.51c0.377-0.307,0.434-0.862,0.127-1.24c-0.307-0.377-0.862-0.434-1.24-0.127
		s-0.434,0.862-0.127,1.24C97.59,17.761,98.145,17.817,98.522,17.51z"
          fill="#FFFFFF"
        />
        <Path
          d="M193.67,44.703c0.377-0.307,0.434-0.862,0.127-1.24c-0.307-0.377-0.862-0.434-1.24-0.127
		s-0.434,0.862-0.127,1.24S193.293,45.011,193.67,44.703z"
          fill="#FFFFFF"
        />
        <Path
          d="M128.503,12.671c0.377-0.307,0.434-0.862,0.127-1.24c-0.307-0.377-0.862-0.434-1.24-0.127
		c-0.377,0.307-0.434,0.862-0.127,1.24C127.571,12.921,128.126,12.978,128.503,12.671z"
          fill="#FFFFFF"
        />
        <Path
          d="M107.579,34.902c0.377-0.307,0.434-0.862,0.127-1.24c-0.307-0.377-0.862-0.434-1.24-0.127
		c-0.377,0.307-0.434,0.862-0.127,1.24C106.647,35.153,107.202,35.21,107.579,34.902z"
          fill="#FFFFFF"
        />
        <Path
          d="M130.913,30.467c0.653-0.532,0.751-1.493,0.219-2.146c-0.532-0.653-1.493-0.751-2.146-0.219
		c-0.653,0.532-0.751,1.493-0.219,2.146S130.26,30.999,130.913,30.467z"
          fill="#FFFFFF"
        />
        <Path
          d="M190.136,108.672c-0.104,0-0.208-0.003-0.312-0.008c-2.437-0.127-3.682-1.742-3.733-1.812
		c-0.083-0.11-0.062-0.267,0.049-0.35c0.109-0.083,0.267-0.062,0.35,0.048c0.012,0.016,1.166,1.499,3.36,1.613
		c1.501,0.071,3.037-0.461,3.902-1.374c0.568-0.601,0.813-1.312,0.727-2.116c-0.015-0.137,0.085-0.261,0.223-0.275
		c0.131-0.021,0.26,0.084,0.275,0.223c0.101,0.942-0.197,1.812-0.862,2.513C193.215,108.085,191.68,108.672,190.136,108.672z"
        />
        <Path
          d="M181.624,107.317c-0.426,0-0.846-0.1-1.258-0.3c-0.099-0.047-0.154-0.152-0.139-0.26l0.207-1.469l0.133-0.188l0.229,0.001
		c0.877,0.457,1.784,0.36,2.779-0.297c1.127-0.745,2.676,0.133,2.741,0.171c0.119,0.069,0.16,0.222,0.092,0.342
		c-0.07,0.12-0.226,0.158-0.342,0.092c-0.014-0.008-1.347-0.766-2.216-0.187c-1.027,0.679-2.026,0.832-2.969,0.467l-0.135,0.951
		c0.841,0.338,1.693,0.189,2.604-0.454c1.104-0.781,2.679,0.053,2.745,0.089c0.122,0.064,0.167,0.216,0.102,0.338
		c-0.064,0.122-0.219,0.165-0.338,0.103c-0.014-0.008-1.37-0.719-2.22-0.121C182.96,107.076,182.285,107.317,181.624,107.317z"
        />
      </G>
    </Svg>
  );
};

export default LoginWinter;
