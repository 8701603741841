import MessageDetails from './MessageDetails';
import ReplyAttachmentReview from './AttachmentReview';
import ReplyAttachmentUpload from './AttachmentUpload';
import ReplyConfirmationSucceeded from './ConfirmationSucceeded';
import ReplyConfirmationFailed from './ConfirmationFailed';

export default {
  1: MessageDetails,
  2: ReplyAttachmentUpload,
  3: ReplyAttachmentReview,
  4: ReplyConfirmationSucceeded,
  5: ReplyConfirmationFailed,
};
