import React from 'react';
import { View } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { Skeleton } from '../../base_ui';
import { SkeletonRow } from './styledComponents';

const SkeletonArray = [...Array(15).keys()];

const ListSkeleton = () => (
  <View>
    {SkeletonArray.map((num) => (
      <SkeletonRow key={num}>
        <Skeleton
          height={`${moderateScale(14)}px`}
          width="100%"
        />
      </SkeletonRow>
    ))}
  </View>
);

export default ListSkeleton;
