/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Defs,
  LinearGradient,
  Path,
  Rect,
  Stop,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

export default function ErrorPorchlight(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(120)} viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="white" height="120" width="287" />
      <Path d="M231.899 87.6C231.899 97.8 230.099 107.5 226.799 116.6H58.7992C55.4992 107.5 53.6992 97.7 53.6992 87.6C53.6992 39.8 93.5992 1 142.799 1C191.999 1 231.899 39.8 231.899 87.6Z" fill="#BAC2CA" />
      <Path d="M114.898 111.199H101.898V116.699H114.898V111.199Z" fill="#E6EBEF" />
      <Path d="M133.499 43.6016H128.699V50.8016H133.499V43.6016Z" fill="#E6EBEF" />
      <Path d="M133.999 42.2016V43.5016L133.699 43.6016V59.4016L136.599 56.2016V43.5016C136.599 43.5016 136.999 43.2016 136.999 43.1016C136.999 43.0016 136.899 42.3016 136.899 42.3016L136.699 42.1016L133.999 42.2016Z" fill="#BAC2CA" />
      <Path d="M128.699 43.6016V59.4016H133.699L136.599 56.3016V43.6016" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M133.602 55.3016V43.6016" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3864" />
      <Path d="M128.701 43.4992H136.401C136.801 43.4992 137.001 43.1992 137.001 42.8992V42.7992C137.001 42.3992 136.701 42.1992 136.401 42.1992H128.701C128.301 42.1992 128.101 42.4992 128.101 42.7992V42.8992C128.001 43.1992 128.301 43.4992 128.701 43.4992Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M133.898 42.1992V43.3992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3864" />
      <Path d="M170.5 72.6992L170.6 116.899H192.2L192.1 72.8992L170.5 72.6992Z" fill="#E6EBEF" />
      <Path d="M193.199 73.8984H170.199V116.798H193.199V73.8984Z" fill="#BAC2CA" />
      <Path d="M106.398 72.8984V116.798H170.498V73.1984L106.398 72.8984Z" fill="#E6EBEF" />
      <Path d="M100.301 70.6992V116.799H102.501V70.9992L100.301 70.6992Z" fill="#E6EBEF" />
      <Path d="M192.2 72.6016H100.5V74.9016H192.2V72.6016Z" fill="#BAC2CA" />
      <Path d="M96.1992 70.6992L113.999 51.1992H179.499L196.799 70.9992H132.399L96.1992 70.6992Z" fill="#BAC2CA" />
      <Path d="M183.901 51.3008H170.801" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5997" />
      <Path d="M135.102 71.1016H167.402" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.6077" />
      <Path d="M124.498 51.1992H123.398" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M168.102 51.1992H126.102" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M100.398 72.8008V116.701" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M170.5 72.8984V116.698" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M195.301 70V116.7" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5983" />
      <Path d="M117.801 111.001V82.3008H130.901V111.001" fill={svgHighlight} />
      <Path d="M117.801 111.001V82.3008H130.901V111.001" stroke="black" strokeMiterlimit="10" strokeWidth="0.7727" />
      <Path d="M124.202 89.5008C124.533 89.5008 124.802 89.2322 124.802 88.9008C124.802 88.5694 124.533 88.3008 124.202 88.3008C123.87 88.3008 123.602 88.5694 123.602 88.9008C123.602 89.2322 123.87 89.5008 124.202 89.5008Z" fill="white" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3864" />
      <Path d="M145.898 72.8984V116.598" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5773" />
      <Path d="M161.2 82.3008H153V92.9008H161.2V82.3008Z" fill="#E6EBEF" />
      <Path d="M152.199 90.6008L156.399 82.3008H152.699L152.199 83.0008V90.6008Z" fill="white" opacity="0.7" />
      <Path d="M157.498 92.9008H155.098L160.598 82.3008H162.998L157.498 92.9008Z" fill="white" opacity="0.7" />
      <Path d="M161.397 82.3008H152.797V92.9008H161.397V82.3008Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.63" />
      <Path d="M157 86.9023V91.4023" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.42" />
      <Path d="M157 83.6016V85.9016" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.42" />
      <Path d="M154.598 87.8008H161.298" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.42" />
      <Path d="M163.198 80.6016H150.898V94.9016H163.198V80.6016Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.42" />
      <Path d="M170.2 111.199H100.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M170.498 72.8H96.4984C96.1984 72.8 95.8984 72.5 95.8984 72V71H105.798" fill="white" />
      <Path d="M170.498 72.8H96.4984C96.1984 72.8 95.8984 72.5 95.8984 72V71H105.798" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5102" />
      <Path d="M170.5 71.1016V72.6016" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M102.5 73V111.2" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5773" />
      <Path d="M106.398 73.1992V111.199" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5762" />
      <Path d="M143.5 73.1016V111.202" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.577" />
      <Path d="M121.698 51.1992H113.998L95.8984 70.9992H129.998" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M132.901 110.9V80.5H115.801V111.1" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3864" />
      <Path d="M147.799 111.1H101.199L122.199 72.5H126.499L147.799 111.1Z" fill="url(#paint0_linear)" />
      <Path d="M100.899 41.8995C102.499 45.8995 105.899 48.6995 109.799 49.5995C109.599 49.6995 109.399 49.7995 109.099 49.8995C102.199 52.4995 94.4987 49.0995 91.8987 42.2995C89.1987 35.3995 92.6987 27.6995 99.4987 25.0995C102.899 23.7995 106.399 23.9995 109.499 25.2995C108.999 25.3995 108.599 25.5995 108.099 25.6995C101.599 28.0995 98.3987 35.3995 100.899 41.8995Z" fill="white" />
      <Path d="M127.702 74.7992H120.502C120.302 74.7992 120.302 74.4992 120.402 74.2992L121.902 72.6992H126.302L127.802 74.2992C128.002 74.4992 127.902 74.7992 127.702 74.7992Z" fill="#BAC2CA" stroke="black" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M127.702 74.9984H124.102V72.8984H126.302L127.802 74.4984C128.002 74.5984 127.902 74.9984 127.702 74.9984Z" fill="#E6EBEF" />
      <Path d="M55.7 116.801H53" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.587" />
      <Path d="M232.098 116.801H229.398" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.587" />
      <Path d="M226.699 116.801H58.6992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.587" />
      <Path d="M176.902 117.502C176.902 115.802 175.602 114.402 174.102 114.402C172.902 114.402 171.902 115.202 171.602 116.402C171.202 115.802 170.502 115.302 169.802 115.302C168.602 115.302 167.702 116.302 167.602 117.502" fill="white" />
      <Path d="M176.902 116.801C176.902 115.501 175.602 114.301 174.102 114.301C172.902 114.301 171.902 115.001 171.602 115.901C171.202 115.401 170.502 115.001 169.802 115.001C168.602 115.001 167.702 115.801 167.602 116.801" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5795" />
      <Path d="M179.2 57.6992L183.7 51.5992C183.9 51.3992 184.1 51.1992 184.4 51.1992C184.7 51.1992 184.9 51.3992 185.1 51.5992L197.8 70.1992C198.1 70.6992 198.1 71.3992 197.7 71.7992C197.3 72.1992 196.7 72.1992 196.4 71.6992L184.4 54.0992L171.2 72.1992C171 72.4992 170.8 72.5992 170.5 72.5992C170.3 72.5992 170 72.4992 169.9 72.2992C169.5 71.8992 169.5 71.0992 169.8 70.6992L177.9 59.5992" fill="#DCE2E7" />
      <Path d="M179.298 57.4992L183.598 51.5992C183.798 51.3992 183.998 51.1992 184.298 51.1992C184.598 51.1992 184.798 51.3992 184.998 51.5992L197.698 70.1992C197.998 70.6992 197.998 71.3992 197.598 71.7992C197.198 72.1992 196.598 72.1992 196.298 71.6992L184.298 54.0992L171.098 72.1992C170.898 72.4992 170.698 72.5992 170.398 72.5992C170.198 72.5992 169.898 72.4992 169.798 72.2992C169.398 71.8992 169.398 71.0992 169.698 70.6992L177.498 60.0992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4183" />
      <Path d="M214.1 73.3984C213 71.9984 212.3 70.1984 212.4 68.3984C212.4 68.2984 212.4 68.0984 212.4 67.9984C212.4 62.0984 207.1 57.3984 200.6 57.3984C194.1 57.3984 188.8 62.1984 188.8 67.9984C188.8 68.0984 188.8 68.2984 188.8 68.3984C188.9 70.1984 188.2 71.8984 187.1 73.3984C185.1 75.8984 184 78.8984 184 82.0984C184 90.3984 191.5 97.0984 200.6 97.0984C209.8 97.0984 217.2 90.3984 217.2 82.0984C217.2 78.8984 216 75.7984 214.1 73.3984Z" fill="#D7DEE2" />
      <Path d="M214.1 73.3984C213 71.9984 212.3 70.1984 212.4 68.3984C212.4 68.2984 212.4 68.0984 212.4 67.9984C212.4 62.0984 207.1 57.3984 200.6 57.3984C194.1 57.3984 188.8 62.1984 188.8 67.9984C188.8 68.0984 188.8 68.2984 188.8 68.3984C188.9 70.1984 188.2 71.8984 187.1 73.3984C185.1 75.8984 184 78.8984 184 82.0984C184 90.3984 191.5 97.0984 200.6 97.0984C209.8 97.0984 217.2 90.3984 217.2 82.0984C217.2 78.8984 216 75.7984 214.1 73.3984Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5771" />
      <Path d="M200.102 70.8984V116.598" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5771" />
      <Path d="M195.699 80.6992L199.999 83.7992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5771" />
      <Path d="M204.402 74.0977L200.102 77.1977" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5771" />
      <Path d="M204.499 86.5L200.199 89.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5771" />
      <Defs>
        <LinearGradient gradientUnits="userSpaceOnUse" id="paint0_linear" x1="124.519" x2="124.519" y1="111.069" y2="72.5154">
          <Stop stopColor="white" stopOpacity="0" />
          <Stop offset="0.1063" stopColor="white" stopOpacity="0.1093" />
          <Stop offset="0.9727" stopColor="white" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
