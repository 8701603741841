import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeSelectedClaimNumber,
  changeStep,
} from '@dmi/shared/redux/LossDraft/actions';
import {
  selectDropdownClaimNumberItems,
  selectDropdownDefaultClaimNumber,
} from '@dmi/shared/redux/LossDraft/selectors';
import { selectCustomerServicePhoneNumber } from '@dmi/shared/redux/Main/selectors';

import ClaimSelectComponent from '../../../components/LossDraft/NewMessage/ClaimSelect';
import { StyledScrollView } from '../../../components/base_ui';

const NewMessageClaimSelect = ({
  dispatchChangeSelectedClaimNumber,
  dispatchChangeStep,
  dropdownClaimNumberItems,
  dropdownDefaultClaimNumber,
}) => (
  <StyledScrollView>
    <ClaimSelectComponent
      dispatchChangeSelectedClaimNumber={dispatchChangeSelectedClaimNumber}
      dispatchChangeStep={dispatchChangeStep}
      dropdownClaimNumberItems={dropdownClaimNumberItems}
      dropdownDefaultClaimNumber={dropdownDefaultClaimNumber}
    />
  </StyledScrollView>
);

NewMessageClaimSelect.propTypes = {
  dispatchChangeSelectedClaimNumber: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dropdownClaimNumberItems: T.array.isRequired,
  dropdownDefaultClaimNumber: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  dropdownClaimNumberItems: selectDropdownClaimNumberItems(),
  dropdownDefaultClaimNumber: selectDropdownDefaultClaimNumber(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: selectCustomerServicePhoneNumber(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchChangeSelectedClaimNumber: (payload) => dispatch(changeSelectedClaimNumber(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageClaimSelect);
