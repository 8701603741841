import React from 'react';
import T from 'prop-types';
import { Text } from 'react-native';

import ConditionalRender from '../../ConditionalRender';
import FormattedMessage from '../../FormattedMessage';
import { BaseInlineExternalLink } from '../../Links';
import { P3 } from '../../Paragraphs';
import LinkComponent from './LinkComponent';

const BodyContent = ({
  ariaLabel = 'disclosure link',
  disclosureName,
  handleOpenDrawer,
  linkText,
  messageText,
}) => (
  <Text>
    <FormattedMessage
      defaultMessage={messageText}
      values={{
        // eslint-disable-next-line react/prop-types
        extLinkBasic: ({ msg: urlWithoutProtocol }) => (
          <BaseInlineExternalLink
            href={`https://${urlWithoutProtocol}`}
            label={urlWithoutProtocol}
          />
        ),
      }}
      WrapperComponent={P3}
    />
    {' '}
    <ConditionalRender
      Component={LinkComponent}
      propsToPassDown={{
        ariaLabel,
        disclosureName,
        handleOpenDrawer,
        linkText,
      }}
      shouldRender={!!linkText && !!disclosureName && !!handleOpenDrawer}
    />
  </Text>
);

BodyContent.propTypes = {
  ariaLabel: T.string,
  disclosureName: T.string,
  handleOpenDrawer: T.func,
  linkText: T.string,
  messageText: T.string.isRequired,
};

export default BodyContent;
