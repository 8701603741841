import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Platform } from 'react-native';

import { selectStatusDataProp } from '@dmi/shared/redux/Payments/Autopay/selectors';
import { selectOtp } from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import PaymentSplashView from '../../components/Payments/MakePaymentSplash';

const PaymentSplash = ({
  autopayAllowedOperation,
  navigation,
  otpAllowedOperation,
}) => {
  const isEligibleForOtp = otpAllowedOperation === 'Schedule_Payment';
  const isEligibleForAutopay = autopayAllowedOperation === 'Request_Enable';

  useEffect(() => {
    if (Platform.OS === 'web' && !isEligibleForAutopay) {
      navigation.navigate('Payments');
    }
  }, [isEligibleForAutopay, navigation]);

  const propsToPassDown = { isEligibleForOtp, navigation };

  return <PaymentSplashView {...propsToPassDown} />;
};

PaymentSplash.propTypes = {
  autopayAllowedOperation: T.string,
  navigation: T.object.isRequired,
  otpAllowedOperation: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  autopayAllowedOperation: selectStatusDataProp('allowedOperation'),
  /**
  * Store: One-Time Payment
  */
  otpAllowedOperation: selectOtp('allowedOperation'),
});

export default connect(mapStateToProps)(PaymentSplash);
