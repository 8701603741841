import React from 'react';
import T from 'prop-types';

import { StyledIconButtonContainer } from './styledComponents';

const BaseIconButton = ({
  ariaLabel = 'button',
  Icon,
  onPress,
  role = 'button',
  ...restProps
}) => (
  <StyledIconButtonContainer
    aria-label={ariaLabel}
    onPress={onPress}
    role={role}
    {...restProps}
  >
    {Icon}
  </StyledIconButtonContainer>
);

BaseIconButton.propTypes = {
  ariaLabel: T.string,
  Icon: T.element.isRequired,
  onPress: T.func.isRequired,
  role: T.string,
};

export default BaseIconButton;
