import React, { Fragment } from 'react';
import T from 'prop-types';

import {
  ConditionalRender,
  ErrorSuccessBanner,
  PasscodeInputField,
} from '../base_ui';
import { SecondaryHeader } from '../ScreenHeaders';
import ScreenReaderNotifications from '../ScreenReaderNotifications';
import {
  FormInputText,
  FormInputTitle,
  InputWrapper,
  PinFormInputContainer,
} from './styledComponents';

const PinDrawerContent = ({
  cardText,
  cardTitle,
  error,
  handleBack,
  handleChangeInput,
  handleCompletePin,
  notifications,
  passcodeInputFocusState,
  pinValue,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={handleBack}
      title=""
    />
    <ScreenReaderNotifications notifications={notifications} />
    <ErrorSuccessBanner error={error} />
    <PinFormInputContainer>
      <FormInputTitle>{cardTitle}</FormInputTitle>
      <ConditionalRender
        Component={<FormInputText>{cardText}</FormInputText>}
        shouldRender={!!cardText}
      />
      <InputWrapper>
        <PasscodeInputField
          focusState={passcodeInputFocusState}
          onChange={(value) => handleChangeInput({ value })}
          onComplete={handleCompletePin}
          value={pinValue}
        />
      </InputWrapper>
    </PinFormInputContainer>
  </Fragment>
);

PinDrawerContent.propTypes = {
  cardText: T.string,
  cardTitle: T.string.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  handleBack: T.func.isRequired,
  handleChangeInput: T.func.isRequired,
  handleCompletePin: T.func.isRequired,
  notifications: T.array.isRequired,
  passcodeInputFocusState: T.oneOf([0, 1]).isRequired,
  pinValue: T.string.isRequired,
};

export default PinDrawerContent;
