import styled from 'styled-components/native';

import {
  BaseTextField,
  H2,
  P2,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ButtonWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(35)}px;
`;

export const ConfirmationContent = styled.View`
  align-items: center;
  margin: ${moderateScale(24)}px ${moderateScale(20)}px 0;
  text-align: center;
`;

export const EditEmailContainer = styled.View`
  flex: 1;
  margin-top: ${({ $isConfirmation }) => $isConfirmation ? moderateScale(52) : 0}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: ${moderateScale(52)}px;
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(({ theme: { fontSizes: { f5 } } }) => ({ inputFontSize: f5 }))``;

export const StyledH2 = styled(H2)`
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(38)}px;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${moderateScale(17)}px;
`;

export const TextFieldGroup = styled.View`
  align-items: center;
  flex-grow: ${({ $isFlexGrow }) => $isFlexGrow ? 1 : 0};
  margin: ${moderateScale(6)}px 0 ${moderateScale(19.92)}px;
`;
