import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { StyledLabel, StyledText } from './styledComponents';
import ConditionalRender from '../../ConditionalRender';

const BaseButton = ({
  disabled = false,
  Icon,
  iconPosition = 'left',
  label,
  onPress,
  pressedStyle,
  pressedTitleStyle,
  style,
  titleStyle,
}) => (
  <Pressable
    disabled={disabled}
    onPress={onPress}
    role="button"
    style={({ pressed }) => ([
      ...style,
      pressed ? pressedStyle : {},
      { justifyContent: 'center' }])}
  >
    {({ pressed }) => (
      <StyledLabel>
        <ConditionalRender
          Component={Icon}
          shouldRender={!!Icon && iconPosition === 'left'}
        />
        <StyledText
          style={[
            ...titleStyle,
            pressed ? pressedTitleStyle : {},
          ]}
        >
          {label}
        </StyledText>
        <ConditionalRender
          Component={Icon}
          shouldRender={!!Icon && iconPosition === 'right'}
        />
      </StyledLabel>
    )}
  </Pressable>
);

BaseButton.propTypes = {
  disabled: T.bool,
  Icon: T.element,
  iconPosition: T.oneOf(['left', 'right']),
  label: T.string.isRequired,
  onPress: T.func.isRequired,
  pressedStyle: T.object,
  pressedTitleStyle: T.object,
  style: T.array,
  titleStyle: T.array,
};

export default BaseButton;
