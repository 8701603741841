import React from 'react';
import T from 'prop-types';

import { Skeleton } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';
import { AddressContainer, AddressLabel } from './styledComponents';

const AddressSkeleton = ({ label }) => (
  <AddressContainer>
    <AddressLabel>{ label }</AddressLabel>
    <Skeleton
      $bottomMargin={`${moderateScale(2)}px`}
      $leftMargin={0}
      $rightMargin={0}
      $topMargin={`${moderateScale(2)}px`}
      height={`${moderateScale(14)}px`}
      width={`${moderateScale(291)}px`}
    />
    <Skeleton
      $bottomMargin={`${moderateScale(2)}px`}
      $leftMargin={0}
      $rightMargin={0}
      $topMargin={`${moderateScale(2)}px`}
      height={`${moderateScale(14)}px`}
      width={`${moderateScale(291)}px`}
    />
  </AddressContainer>
);

AddressSkeleton.propTypes = { label: T.string.isRequired };

export default AddressSkeleton;
