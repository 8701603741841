/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Defs, G, LinearGradient, Path, Rect, Stop,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

export default function LaptopSplash(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(150)} viewBox="0 0 327 150" width={moderateScale(327)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="white" height="150" width="327" />
      <Path d="M131.28 113.73H255.95C256.746 113.73 257.509 114.046 258.071 114.609C258.634 115.171 258.95 115.934 258.95 116.73V121.1C258.95 121.896 258.634 122.659 258.071 123.221C257.509 123.784 256.746 124.1 255.95 124.1H134.28C133.484 124.1 132.721 123.784 132.159 123.221C131.596 122.659 131.28 121.896 131.28 121.1V113.73Z" fill="#E6EBEF" />
      <Path d="M219.51 113.73L220.41 122.48C220.472 123.062 220.678 123.619 221.01 124.1H246.37C246.702 123.619 246.909 123.062 246.97 122.48L247.87 113.73H219.51Z" fill="#BAC2CA" />
      <Path d="M67.9999 56.68C66.8099 56.55 65.7499 56.88 66.1399 58.01C66.6977 59.5808 67.0866 61.2067 67.2999 62.86C67.3194 63.1639 67.2217 63.4637 67.0269 63.6977C66.8321 63.9318 66.555 64.0822 66.2525 64.1181C65.9501 64.154 65.6455 64.0726 65.4013 63.8906C65.1571 63.7086 64.992 63.44 64.9399 63.14C63.3999 54.05 56.0699 53.29 51.7599 53.66C51.5192 53.6834 51.2913 53.7797 51.1067 53.9359C50.9221 54.0921 50.7894 54.3009 50.7264 54.5344C50.6634 54.7679 50.6731 55.0151 50.7541 55.2429C50.8351 55.4708 50.9836 55.6686 51.1799 55.81C53.3799 57.42 56.1799 60.15 55.9999 63.63C55.7599 69.36 54.5299 67 54.5299 67C54.5299 67 50.9099 58 46.9999 57C43.6699 56.11 34.1699 60.27 31.4999 61.49C31.2883 61.5864 31.1099 61.7432 30.9872 61.9407C30.8644 62.1382 30.8027 62.3675 30.8099 62.6C30.8999 65.39 31.5799 74.76 36.8099 77.32C39.388 78.4285 42.2216 78.8056 44.9999 78.41C45.2794 78.375 45.5623 78.4404 45.7981 78.5944C46.034 78.7485 46.2076 78.9812 46.288 79.2512C46.3683 79.5211 46.3504 79.8109 46.2372 80.0689C46.124 80.3268 45.923 80.5363 45.6699 80.66C43.0799 81.91 40.0899 82.84 37.5999 82.2C37.3686 82.1415 37.125 82.1545 36.9013 82.2373C36.6775 82.3201 36.4841 82.4688 36.3466 82.6637C36.209 82.8586 36.1338 83.0907 36.1308 83.3292C36.1279 83.5678 36.1973 83.8017 36.3299 84C38.9099 88.19 44.1299 93.37 52.7499 88.49C52.9987 88.3451 53.2916 88.2953 53.5742 88.3498C53.8569 88.4044 54.1103 88.5596 54.2873 88.7867C54.4643 89.0137 54.553 89.2972 54.5369 89.5847C54.5208 89.8722 54.4011 90.144 54.1999 90.35C52.8228 91.8174 51.1056 92.9233 49.1999 93.57C48.9845 93.6335 48.7916 93.7568 48.6433 93.9254C48.495 94.094 48.3974 94.3011 48.3618 94.5228C48.3263 94.7445 48.3542 94.9718 48.4423 95.1783C48.5304 95.3848 48.6752 95.5622 48.8599 95.69C48.8599 95.69 56.7799 103.69 64.8599 97.97C72.9399 92.25 68.4299 85 68.4299 85C68.4299 85 67.2299 81.62 69.6799 81.1C72.1299 80.58 75.3099 82.58 78.6099 77.82C81.9099 73.06 82.2399 58.23 67.9999 56.68Z" fill={svgHighlight} />
      <Path d="M39.87 64.85C39.87 64.85 78.7 75.79 78.61 105.24" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M90.1799 124.1H65.2899C65.0633 124.1 64.8444 124.018 64.6735 123.869C64.5026 123.72 64.3913 123.514 64.3599 123.29L61.7999 104.37C61.7812 104.237 61.7913 104.102 61.8296 103.974C61.8679 103.846 61.9334 103.727 62.0217 103.627C62.11 103.526 62.219 103.446 62.3412 103.391C62.4634 103.336 62.596 103.309 62.7299 103.31H92.7299C92.8633 103.31 92.9951 103.339 93.1165 103.394C93.2379 103.449 93.3462 103.53 93.4342 103.63C93.5222 103.73 93.5878 103.848 93.6267 103.976C93.6656 104.103 93.6769 104.238 93.6599 104.37L91.0999 123.29C91.0582 123.508 90.9451 123.706 90.7786 123.852C90.612 123.999 90.4014 124.086 90.1799 124.1Z" fill="white" />
      <Path d="M63.1399 114.3L61.7999 104.37C61.7812 104.237 61.7913 104.102 61.8296 103.974C61.8679 103.846 61.9334 103.727 62.0217 103.627C62.11 103.526 62.219 103.446 62.3412 103.391C62.4634 103.336 62.596 103.309 62.7299 103.31H94.7299C94.8633 103.31 94.9951 103.339 95.1165 103.394C95.2379 103.449 95.3462 103.53 95.4342 103.63C95.5222 103.73 95.5878 103.848 95.6267 103.976C95.6656 104.103 95.6769 104.238 95.6599 104.37L93.0999 123.29C93.0577 123.51 92.9432 123.709 92.7746 123.855C92.6061 124.002 92.3932 124.088 92.1699 124.1H65.2899C65.0633 124.1 64.8444 124.018 64.6735 123.869C64.5026 123.72 64.3913 123.514 64.3599 123.29L63.4699 116.73" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M93.1399 103.31H62.3199C60.8398 103.31 59.6399 104.51 59.6399 105.99V107.32C59.6399 108.8 60.8398 110 62.3199 110H93.1399C94.62 110 95.8199 108.8 95.8199 107.32V105.99C95.8199 104.51 94.62 103.31 93.1399 103.31Z" fill="white" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M131.28 113.73H255.95C256.746 113.73 257.509 114.046 258.071 114.609C258.634 115.171 258.95 115.934 258.95 116.73V121.1C258.95 121.896 258.634 122.659 258.071 123.221C257.509 123.784 256.746 124.1 255.95 124.1H134.28C133.484 124.1 132.721 123.784 132.159 123.221C131.596 122.659 131.28 121.896 131.28 121.1V113.73Z" stroke="#2C2627" strokeLinejoin="round" strokeWidth="0.6" />
      <Path d="M252.41 66.4H225.62C224.914 66.3983 224.223 66.609 223.638 67.0049C223.053 67.4009 222.601 67.9636 222.34 68.62L219.17 76.57C219.098 76.7481 219.07 76.9413 219.09 77.1324C219.111 77.3236 219.177 77.5068 219.285 77.666C219.393 77.8251 219.538 77.9553 219.708 78.0449C219.878 78.1346 220.068 78.181 220.26 78.18H257.77C257.962 78.181 258.152 78.1346 258.322 78.0449C258.492 77.9553 258.637 77.8251 258.745 77.666C258.852 77.5068 258.919 77.3236 258.939 77.1324C258.959 76.9413 258.932 76.7481 258.86 76.57L255.69 68.62C255.428 67.9644 254.975 67.4024 254.391 67.0067C253.806 66.6109 253.116 66.3996 252.41 66.4V66.4Z" fill="white" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M220.44 73.6801H257.71" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M245.23 21.38C250.35 38.16 240.23 41.49 240.23 45.98C240.23 50.47 246.72 51.27 246.72 55.59C246.72 59.91 241.4 60.28 241.4 64.23H233.57C233.57 60.23 238.89 60.91 238.89 56.59C238.89 52.27 231.41 51.31 231.41 46.82C231.41 42.33 237.41 39.5 237.41 33.51C237.41 28.7 231.41 26.7 231.41 21.38H245.23Z" fill="url(#paint0_linear)" />
      <Path d="M256.67 78.1801H221.36L225.73 120.93C225.821 121.798 226.23 122.602 226.878 123.187C227.526 123.772 228.367 124.097 229.24 124.1H248.78C249.654 124.1 250.496 123.775 251.144 123.19C251.793 122.604 252.201 121.799 252.29 120.93L256.67 78.1801Z" fill="white" />
      <Path d="M222.41 88.4801L225.73 120.93C225.821 121.798 226.23 122.602 226.878 123.187C227.526 123.772 228.367 124.097 229.24 124.1H248.78C249.654 124.1 250.496 123.775 251.144 123.19C251.793 122.604 252.201 121.799 252.29 120.93L256.67 78.1801H221.36L222.14 85.8201" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M253.41 107.49L255.7 85.2401C254.101 84.4638 252.347 84.0604 250.57 84.0601C248.979 84.0139 247.395 84.2875 245.911 84.8645C244.428 85.4415 243.075 86.3103 241.933 87.4194C240.792 88.5285 239.884 89.8554 239.264 91.3216C238.644 92.7877 238.325 94.3633 238.325 95.9551C238.325 97.5468 238.644 99.1224 239.264 100.589C239.884 102.055 240.792 103.382 241.933 104.491C243.075 105.6 244.428 106.469 245.911 107.046C247.395 107.623 248.979 107.896 250.57 107.85C251.528 107.847 252.482 107.726 253.41 107.49V107.49Z" fill="#DDD6E7" />
      <Path d="M255.7 85.2401C254.101 84.4638 252.347 84.0604 250.57 84.0601C248.979 84.0139 247.395 84.2875 245.911 84.8645C244.428 85.4415 243.075 86.3103 241.933 87.4194C240.792 88.5285 239.884 89.8554 239.264 91.3216C238.644 92.7877 238.325 94.3633 238.325 95.9551C238.325 97.5468 238.644 99.1224 239.264 100.589C239.884 102.055 240.792 103.382 241.933 104.491C243.075 105.6 244.428 106.469 245.911 107.046C247.395 107.623 248.979 107.896 250.57 107.85C251.531 107.847 252.488 107.727 253.42 107.49" stroke="#2C2627" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5" />
      <G style={{ mixBlendMode: 'multiply' }}>
        <Path d="M92.67 104.37C92.687 104.238 92.6757 104.103 92.6368 103.976C92.5979 103.848 92.5323 103.73 92.4443 103.63C92.3563 103.53 92.248 103.449 92.1266 103.394C92.0052 103.339 91.8734 103.31 91.74 103.31H80L82.48 117.87C82.7609 119.522 83.5891 121.032 84.8315 122.156C86.0739 123.281 87.6583 123.955 89.33 124.07C89.74 124.07 92.7 124.07 92.7 124.07L92.67 104.37Z" fill="#BAC2CA" />
      </G>
      <Path d="M207.84 124.1H94.8399C93.072 124.101 91.3607 123.476 90.0097 122.336C88.6587 121.195 87.7554 119.613 87.4599 117.87L74.2399 40.57C74.0564 39.496 74.1097 38.3949 74.3961 37.3438C74.6825 36.2926 75.1951 35.3166 75.898 34.4842C76.6009 33.6517 77.4772 32.9829 78.4655 32.5244C79.4539 32.066 80.5304 31.829 81.6199 31.83H185.8C187.567 31.8327 189.276 32.4592 190.626 33.599C191.976 34.7387 192.881 36.3186 193.18 38.06L207.84 124.1Z" fill="#E6EBEF" />
      <Path d="M98.3199 31.83H185.8C187.567 31.8327 189.276 32.4592 190.626 33.599C191.976 34.7387 192.881 36.3186 193.18 38.06L207.84 124.06H94.8399C93.072 124.061 91.3607 123.436 90.0097 122.296C88.6587 121.155 87.7554 119.573 87.4599 117.83L74.2399 40.57C74.0564 39.496 74.1097 38.3949 74.3961 37.3438C74.6825 36.2926 75.1951 35.3166 75.898 34.4842C76.6009 33.6517 77.4772 32.9829 78.4655 32.5244C79.4539 32.066 80.5304 31.829 81.6199 31.83H91.8399" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.96" />
      <Path d="M94.33 31.83H95.94" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M176.54 33.77H184.92C186.446 33.7698 187.923 34.3099 189.089 35.2947C190.255 36.2795 191.035 37.6453 191.29 39.15L205.29 121.47" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path d="M141.149 83.9185C144.427 81.8386 144.929 76.7539 142.269 72.5613C139.61 68.3687 134.796 66.656 131.517 68.7358C128.239 70.8156 127.737 75.9003 130.397 80.0929C133.056 84.2855 137.87 85.9983 141.149 83.9185Z" fill="white" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.5" />
      <Path d="M279.77 124.1H301.02" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M275.79 124.1H277.62" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M26.3199 124.1H273.46" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Defs>
        <LinearGradient gradientUnits="userSpaceOnUse" id="paint0_linear" x1="239.07" x2="239.07" y1="65.81" y2="20.57">
          <Stop stopColor="#E6EBEF" />
          <Stop offset="1" stopColor="white" />
        </LinearGradient>
      </Defs>
    </Svg>
  );
}
