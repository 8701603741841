import React, { Fragment } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import {
  ConditionalRender,
  LargePrimaryButton,
  LargeProgressButton,
  NestedStyledScrollView,
} from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import BorderlessDocPickerButton from './BorderlessDocPickerButton';
import { StyledBorderlessButton } from './BorderlessDocPickerButton/styledComponents';
import FileItem from './FileItem';
import {
  BottomButtonWrapper,
  ContentWrapper,
  Header,
  HeaderSubText,
  IllustrationHeaderWrapper,
  IllustrationWrapper,
  OuterWrapper,
  StyledPrimaryDivider,
  StyledStatusBanner,
} from './styledComponents';

const Illustration = iconDictionary('magnifyingGlass');
const PlusIcon = iconDictionary('addCircle');

const Scene = ({
  bannerError,
  bottomButtonProps: {
    status: uploadRequestStatus,
    ...restButtonProps
  },
  customBackHandler,
  dispatchChooseDocument,
  errorStartOverHandler,
  fileName,
  fileName2,
  header,
  headerSubText,
  isMultiDoc,
  isSecondFileStep,
  navigation,
  validationErrorMessage,
  validationErrorMessage2,
}) => {
  // if there are two validation errors, don't disable button
  const hasError = (!!bannerError || !!validationErrorMessage || !!validationErrorMessage2);
  const labelForMultiFiles = hasError ?
    'Start Over' :
    'Select Different Files';

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => customBackHandler ? customBackHandler() : navigation.goBack()}
        title="Review Document Upload"
      />
      <NestedStyledScrollView>
        <OuterWrapper>
          <StyledStatusBanner error={bannerError} />
          <ContentWrapper>
            <IllustrationHeaderWrapper>
              <IllustrationWrapper>{Illustration}</IllustrationWrapper>
              <Header>{header || 'Review your document'}</Header>
              <HeaderSubText>{headerSubText}</HeaderSubText>
            </IllustrationHeaderWrapper>
            <ConditionalRender
              Component={FileItem}
              propsToPassDown={{
                dispatchChooseDocument,
                fileName,
                isMultiDoc: !!isMultiDoc,
                isSecondFile: false,
                isSecondFileStep,
                validationErrorMessage,
              }}
              shouldRender={!!fileName}
            />
            <ConditionalRender
              Component={FileItem}
              propsToPassDown={{
                dispatchChooseDocument,
                fileName: fileName2,
                isMultiDoc: !!isMultiDoc,
                isSecondFile: true,
                isSecondFileStep,
                validationErrorMessage: validationErrorMessage2,
              }}
              shouldRender={!!fileName && !!fileName2}
            />
            <StyledPrimaryDivider />
            <ConditionalRender
              Component={StyledBorderlessButton}
              propsToPassDown={{
                Icon: !!fileName2 && !hasError ? PlusIcon : null,
                iconPosition: 'left',
                label: labelForMultiFiles,
                onPress: errorStartOverHandler,
              }}
              shouldRender={!!fileName2}
            />
            <ConditionalRender
              Component={BorderlessDocPickerButton}
              propsToPassDown={{
                dispatchChooseDocument,
                displayIcon: true,
                isSecondFile: !!fileName2,
              }}
              shouldRender={!fileName2}
            />
          </ContentWrapper>
          <BottomButtonWrapper>
            <ConditionalRender
              Component={LargeProgressButton}
              FallbackComponent={LargePrimaryButton}
              propsToPassDown={{
                disabled: hasError,
                status: uploadRequestStatus,
                ...restButtonProps,
              }}
              shouldRender={!!uploadRequestStatus}
            />
          </BottomButtonWrapper>
        </OuterWrapper>
      </NestedStyledScrollView>
    </Fragment>
  );
};

Scene.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  bottomButtonProps: T.shape({
    label: T.string.isRequired,
    onPress: T.func.isRequired,
    status: T.string,
  }),
  customBackHandler: T.func,
  dispatchChooseDocument: T.func,
  errorStartOverHandler: T.func,
  fileName: T.string.isRequired,
  fileName2: T.string,
  header: T.string,
  headerSubText: T.string.isRequired,
  isMultiDoc: T.bool,
  isSecondFileStep: T.bool,
  navigation: T.object.isRequired,
  validationErrorMessage: T.string.isRequired,
  validationErrorMessage2: T.string,
};

export default Scene;
