/*
 * Change Email Step One Screen
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeEmailStepOne,
  resetFormState,
  verifyRecaptcha,
} from '@dmi/shared/redux/Register/ChangeEmail/actions';
import reducer from '@dmi/shared/redux/Register/ChangeEmail/reducer';
import saga from '@dmi/shared/redux/Register/ChangeEmail/saga';
import {
  getChangeEmailForm,
  getChangeEmailFormErrors,
} from '@dmi/shared/redux/Register/ChangeEmail/selectors';

import injectReducer from '../../../utils/injectReducer';
import { useInjectSaga } from '../../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../../utils/globalHelpers';
import { navigate, replace } from '../../../utils/rootNavigation';
import { ChangeEmailStepOneFormInput } from '../../../components/Register/FormInputs';
import { CHANGE_EMAIL_STEP_ONE_CARD_PROPS } from './constants';
import SharedView from './SharedView';

const ChangeEmailStepOneView = ({
  dispatchChangeEmailStepOne,
  dispatchResetFormState,
  dispatchVerifyRecaptcha,
  formErrors,
  formValues,
  navigation,
}) => {
  useInjectSaga({
    key: 'changeEmail',
    saga: saga({
      baseUrl: getBaseApplicationServerUrl(),
      navigationHandler: navigate,
      navigationReplaceHandler: replace,
    }),
  });

  useEffect(() => () => dispatchResetFormState({ form: 'changeEmailStepOne' }),
    [dispatchResetFormState]);

  return (
    <SharedView
      dispatchChangeEmail={dispatchChangeEmailStepOne}
      dispatchVerifyRecaptcha={dispatchVerifyRecaptcha}
      formErrors={formErrors}
      formValues={formValues}
      handleNextStep={() => navigation.navigate('ChangeEmailStepTwo')}
      navigation={navigation}
      viewCardProps={CHANGE_EMAIL_STEP_ONE_CARD_PROPS}
      ViewFormInput={ChangeEmailStepOneFormInput}
    />
  );
};

ChangeEmailStepOneView.propTypes = {
  dispatchChangeEmailStepOne: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchVerifyRecaptcha: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  formErrors: getChangeEmailFormErrors('changeEmailStepOne'),
  formValues: getChangeEmailForm('changeEmailStepOne'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchChangeEmailStepOne: (requestBody) => dispatch(
    changeEmailStepOne({ navigationType: 'replace', requestBody }),
  ),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchVerifyRecaptcha: (payload) => dispatch(
    verifyRecaptcha({ navigationType: 'replace', ...payload }),
  ),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'changeEmail', reducer });

export default compose(
  withConnect,
  withReducer,
)(ChangeEmailStepOneView);
