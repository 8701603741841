import React from 'react';
import T from 'prop-types';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import iconDictionary from '../../../../../utils/iconDictionary';
import Layout from '../../../../../constants/Layout';
import { ConditionalRender, P3 } from '../../../../base_ui';
import { LinkText, StyledLink } from './styledComponents';

const { isTablet } = Layout;
const ArrowIcon = iconDictionary('arrowRightPurpleS', { width: `${moderateScale(10)}px` });

const Link = ({ linkProps, navigation }) => {
  const {
    ariaLabel,
    label,
    mobilePath,
    showLink,
  } = linkProps || {};

  return (
    <ConditionalRender
      Component={(
        <StyledLink
          aria-label={ariaLabel}
          onPress={() => navigation.navigate(mobilePath)}
          role="button"
        >
          <LinkText>{label}</LinkText>
          {ArrowIcon}
        </StyledLink>
      )}
      FallbackComponent={<P3 $center={isTablet}>{label}</P3>}
      shouldRender={!!showLink}
    />
  );
};

Link.propTypes = {
  linkProps: T.shape({
    ariaLabel: T.string,
    label: T.string,
    mobilePath: T.string,
    showLink: T.bool,
  }),
  navigation: T.object.isRequired,
};

export default Link;
