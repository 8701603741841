import styled from 'styled-components/native';
import { FlatList } from 'react-native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  H2,
  P2,
  P3,
  NestedStyledScrollView as ScrollView,
} from '../../../base_ui';

export const ContactTextWrapper = styled.View`
  padding-top: ${moderateScale(20)}px;
  width: 100%;
`;

export const CouponBookFallbackWrapper = styled.View`
  align-items: center;
  flex-grow: 1;
  padding: 0 ${moderateScale(44)}px;
  width: 100%;
`;

export const FilterWrapper = styled.View`
  height: ${moderateScale(41)}px;
  margin-top: ${moderateScale(8)}px;
  width: 100%;
`;

export const IconView = styled.View`
  width: 8%;
`;

export const IconWrapper = styled.View`
  padding-bottom: ${moderateScale(16)}px;
  padding-top: ${moderateScale(65)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const PressableRow = styled.Pressable`
  align-items: center;
  background-color: ${(
    ({
      $isPressing,
      theme: { colors: { accentSilver, dmiWhite } },
    }) => $isPressing ? accentSilver : dmiWhite
  )};
  flex-direction: row;
  padding: ${(moderateScale(11))}px ${(moderateScale(24))}px;
`;

/* eslint-disable object-curly-newline */
export const RowText = styled(P3)`
  padding-right: ${moderateScale(18)}px;
  width: ${({ $widthPercent }) => `${$widthPercent}%` || 'auto'};
`;

export const PhoneNumber = styled(P2)`
  align-self: center;
  color: ${({ theme: { colors: { primary } } }) => primary};
  line-height: ${moderateScale(22)}px;
`;

export const StyledFlatList = styled(FlatList).attrs(() => ({
  contentContainerStyle: { flexGrow: 1 },
  ListFooterComponentStyle: { flex: 1 },
}))`
  width: 100%;
`;

export const StyledScrollView = styled(ScrollView)`
  width: 100%;
`;

export const StyledText = styled(P2)`
  text-align: center;
`;

export const Title = styled(H2)`
  padding-bottom: ${moderateScale(8)}px;
  text-align: center;
`;
