import React, { Fragment } from 'react';
import T from 'prop-types';

import {
  InboxList,
  InboxListSkeleton,
} from './InboxList';
import AsyncRender from '../../AsyncRender';
import {
  StyledButtonView,
  StyledLargePrimaryButton,
  StyledSecondaryHeader,
  StyledView,
} from './styledComponents';
import Error from './Error';

const InboxComponent = ({
  dispatchChangeSelectedMessageId,
  messages,
  navigation,
  secureMessagingInboxProps: {
    buttonLabel,
    buttonPath,
    error,
    headingLabel,
    loading,
    shouldDisableNewMessageButton,
  },
}) => (
  <Fragment>
    <StyledSecondaryHeader
      handleBack={navigation.goBack}
      title={headingLabel}
    />
    <StyledView>
      <AsyncRender
        Component={InboxList}
        CustomErrorComponent={Error}
        error={error}
        loading={loading}
        LoadingComponent={InboxListSkeleton}
        messages={messages}
        propsToPassDown={{
          dispatchChangeSelectedMessageId,
          messages,
          navigation,
          scrollLoading: loading,
        }}
      />
      <StyledButtonView>
        <StyledLargePrimaryButton
          disabled={shouldDisableNewMessageButton}
          label={buttonLabel}
          onPress={() => navigation.navigate(buttonPath)}
        />
      </StyledButtonView>
    </StyledView>
  </Fragment>
);

InboxComponent.propTypes = {
  dispatchChangeSelectedMessageId: T.func.isRequired,
  messages: T.array.isRequired,
  navigation: T.object.isRequired,
  secureMessagingInboxProps: T.object.isRequired,
};

export default InboxComponent;
