import React from 'react';
import T from 'prop-types';
// import { Platform } from 'react-native';
import { connect } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import { createStructuredSelector } from 'reselect';

// import { makeSelectNativeClients } from '@dmi/shared/redux/Mobile/selectors';
import { selectGoldMigrationPLCookiesBoolean } from '@dmi/shared/redux/GoldRedirect/selectors';

import preAuthScreens from './preAuthScreens';

const Stack = createStackNavigator();

// eslint-disable-next-line no-unused-vars
const PreAuthStack = ({
  // clients,
  isGoldMigrationPLGeneralLogin,
  isGoldMigrationPLPreLogin,
  isGoldMigrationPLProfileTransfer,
}) => {
  /*
  TODO: When integrated with Registration, we will need to
  keep initialRoute as Login for deep linking to work.
  */
  let initialRoute = 'Login';
  if (isGoldMigrationPLPreLogin) {
    initialRoute = 'Preregistration';
  } else if (isGoldMigrationPLGeneralLogin) {
    initialRoute = 'Prelogin';
  } else if (isGoldMigrationPLProfileTransfer) {
    initialRoute = 'ProfileTransfer';
  }

  // if (Platform.OS !== 'web') {
  //   const hasClientList = !!clients.length;
  //   const hasSsoClient = !!clients.filter(
  //     ({ clientAuthentication: { grantType } }) => grantType === 'sso_oidc',
  //   ).length;
  //   if (!hasClientList) {
  //     initialRoute = 'Welcome';
  //   } else if (hasSsoClient) {
  //     initialRoute = 'ClientSelect';
  //   }
  // }
  return (
    <Stack.Navigator initialRouteName={initialRoute} screenOptions={{ headerShown: false }}>
      {Object.entries(preAuthScreens).map(([name, otherProps]) => (
        <Stack.Screen key={name} name={name} {...otherProps} />
      ))}
    </Stack.Navigator>
  );
};

PreAuthStack.propTypes = {
  // clients: T.array,
  isGoldMigrationPLGeneralLogin: T.bool.isRequired,
  isGoldMigrationPLPreLogin: T.bool.isRequired,
  isGoldMigrationPLProfileTransfer: T.bool.isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: GoldRedirect
   */
  isGoldMigrationPLGeneralLogin: selectGoldMigrationPLCookiesBoolean('goldMigrationPLGeneralLogin'),
  isGoldMigrationPLPreLogin: selectGoldMigrationPLCookiesBoolean('goldMigrationPLPreLogin'),
  isGoldMigrationPLProfileTransfer:
    selectGoldMigrationPLCookiesBoolean('goldMigrationPLProfileTransfer'),
  /**
   * Store: Mobile
   */
  // eslint-disable-next-line sort-keys
  // clients: makeSelectNativeClients(),
});

export default connect(mapStateToProps)(PreAuthStack);
