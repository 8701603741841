import styled, { css } from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H4, PrimaryDivider } from '../../../base_ui';

export const ContentContainer = styled.View`
  align-self: ${({ $center }) => $center ? 'center' : 'flex-start'};
  flex-direction: row;
  min-height: ${moderateScale(148)}px;
  padding-left: ${moderateScale(18)}px;
  padding-vertical: ${moderateScale(24)}px;
  ${({ $center }) => $center && css`width: 70%;`}
  ${({ $center }) => $center && css`margin: auto;`}
 `;

export const IllustrationContainer = styled.View`
  justify-content: center;
`;

export const LinkContainer = styled.View`
  align-items:  ${({ $center }) => $center ? 'center' : 'flex-end'};
  padding-right: ${moderateScale(24)}px;
  padding-vertical: ${moderateScale(10)}px;
  width: 100%;
`;

export const MessageContainer = styled.View`
  align-items: flex-start;
  max-width: 75%;
  padding-left: ${moderateScale(8)}px;
  padding-right: ${moderateScale(26)}px;
`;

export const SectionContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const SectionDivider = styled(PrimaryDivider)`
  min-width: 100%;
  width: 100%;
`;

export const SubHeading = styled(H4)`
  margin-bottom: ${moderateScale(8)}px;
`;
