import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useRoute } from '@react-navigation/native';

import {
  cancel,
  resetConfirmation,
  setIsInAutopayFlow,
  setIsModalOpen,
} from '@dmi/shared/redux/Payments/Autopay/actions';
import { generateCancelAutopayModalProps } from '@dmi/shared/redux/Payments/Autopay/helpers';
import makeSelectAutopay, {
  selectError,
  selectIsModalOpen,
  selectStatusDataProp,
  selectStatusProp,
} from '@dmi/shared/redux/Payments/Autopay/selectors';
import { getMainClientInfo, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import ConfirmationScreen from '../../../components/Payments/Confirmation';
import { StyledScrollView } from '../../../components/base_ui';

const Confirmation = ({
  autopayStatus,
  confirmationType,
  customerServicePhoneNumber,
  dispatchCancelAutopay,
  dispatchResetConfirmation,
  dispatchSetIsInAutopayFlow,
  dispatchSetIsModalOpen,
  isMurabahaAccount,
  isOpen,
  navigation,
  prevConfirmationType,
  serviceErrorState: { updateEmailAlerts: updateEmailAlertsError },
  status,
}) => {
  const route = useRoute();

  useEffect(() => () => {
    dispatchResetConfirmation();
    dispatchSetIsInAutopayFlow({ value: false });
  }, [dispatchResetConfirmation, dispatchSetIsInAutopayFlow]);

  const cancelAutopayModalProps = generateCancelAutopayModalProps({
    autopayStatus,
    dispatchCancelAutopay,
    dispatchSetIsModalOpen,
    isOpen,
    status,
  });

  return (
    <StyledScrollView>
      <ConfirmationScreen
        confirmationType={confirmationType}
        customerServicePhoneNumber={customerServicePhoneNumber}
        data={route.params.confirmationData}
        dispatchSetIsInAutopayFlow={dispatchSetIsInAutopayFlow}
        dispatchSetIsModalOpen={dispatchSetIsModalOpen}
        isMurabahaAccount={isMurabahaAccount}
        modalProps={cancelAutopayModalProps}
        navigation={navigation}
        prevConfirmationType={prevConfirmationType}
        showEmailErrorMessage={updateEmailAlertsError}
        type="autopay"
      />
    </StyledScrollView>
  );
};

Confirmation.propTypes = {
  autopayStatus: T.string,
  confirmationType: T.string,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchCancelAutopay: T.func.isRequired,
  dispatchResetConfirmation: T.func.isRequired,
  dispatchSetIsInAutopayFlow: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isOpen: T.bool.isRequired,
  navigation: T.object.isRequired,
  prevConfirmationType: T.string,
  serviceErrorState: T.object.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  autopayStatus: selectStatusDataProp('status'),
  confirmationType: makeSelectAutopay('confirmationType'),
  isOpen: selectIsModalOpen('cancel'),
  prevConfirmationType: makeSelectAutopay('prevConfirmationType'),
  serviceErrorState: selectError('serviceError'),
  status: selectStatusProp('cancel'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isMurabahaAccount: selectIsMurabahaAccount(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchCancelAutopay: () => dispatch(cancel({ navigationType: 'replace' })),
  dispatchResetConfirmation: () => dispatch(resetConfirmation()),
  dispatchSetIsInAutopayFlow: (payload) => dispatch(setIsInAutopayFlow(payload)),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Confirmation);
