import ClientSelect from '../../containers/Login/ClientSelect';
import ContactInformation from '../../containers/Help/ContactInformationScreen';
import GoldRedirect from '../../containers/GoldRedirect';
import Login from '../../containers/Login/LoginView';
import Mfa from '../../containers/Login/MFAView';
import ReconsentView from '../../containers/Login/ReconsentView';
import {
  ForgotPassword,
  ForgotUsername,
  Register,
} from '../../containers/Register';
import SsoAccountError from '../../components/Login/SsoAccountError';
import SsoLoggedOutView from '../../components/Login/SsoLoggedOutView';
import { SsoMigrationModal as SsoMigrationModalWeb } from '../../components/ModalViews';
import Totp from '../../containers/Login/TotpView';
// import Welcome from '../../containers/Login/Welcome';
import ChangeEmailStack from './ChangeEmailStack';

const preAuthScreens = {
  ChangeEmail: { component: ChangeEmailStack },
  ClientSelect: { component: ClientSelect },
  ContactUs: { component: ContactInformation },
  ForgotPassword: { component: ForgotPassword },
  ForgotUsername: { component: ForgotUsername },
  Login: { component: Login },
  Mfa: { component: Mfa },
  Prelogin: { component: GoldRedirect },
  Preregistration: { component: GoldRedirect },
  ProfileTransfer: { component: GoldRedirect },
  Reconsent: { component: ReconsentView },
  Register: { component: Register },
  SsoAccountError: { component: SsoAccountError },
  SsoLoggedOut: { component: SsoLoggedOutView },
  SsoMigrationModal: { component: SsoMigrationModalWeb },
  Totp: { component: Totp },
  // Welcome: { component: Welcome },
};

export default preAuthScreens;
