/**
* Message
* @description: Simple Message component
* Use Case: For common pages/sections meant to convey a simple message
* (e.g. 404 Not Found page, AsyncRender Error page)
*/

import React from 'react';
import T from 'prop-types';

import {
  IconWrapper,
  LinkWrapper,
  MediumBodyText,
  SmallBodyText,
  Spacing,
  Title,
  Wrapper,
} from './styledComponents';
import { BaseInternalLink } from '../base_ui';

const Message = ({
  body,
  bodySize = 'medium',
  icon,
  link: { label: linkLabel, onPress, path } = {},
  title,
  ...restProps
}) => {
  const BodyText = bodySize === 'small' ? SmallBodyText : MediumBodyText;
  return (
    <Wrapper {...restProps}>
      <Spacing />
      <IconWrapper>{icon}</IconWrapper>
      <Title>{title}</Title>
      {body && <BodyText {...restProps}>{body}</BodyText>}
      {linkLabel && (
        <LinkWrapper>
          <BaseInternalLink
            label={linkLabel}
            onPressFunc={onPress}
            path={path}
            size="medium"
          />
        </LinkWrapper>
      )}
      <Spacing />
    </Wrapper>
  );
};

Message.propTypes = {
  body: T.oneOfType([T.string, T.element]),
  bodySize: T.oneOf(['small', 'medium']),
  icon: T.element.isRequired,
  link: T.shape({ label: T.string, onPress: T.func, path: T.string }),
  title: T.string.isRequired,
};

export default Message;
