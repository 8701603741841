import React from 'react';
import T from 'prop-types';

import { PreLoginBanner } from '../../base_ui';
import { TopBannersContainer } from './styledComponents';

const PreLoginTopBanners = ({
  handleDismissBanner,
  handleOpenDisclosureDrawer,
  hasBanner,
  topBanners,
}) => (
  <TopBannersContainer $hasBanner={hasBanner}>
    {topBanners.map((bannerProps) => {
      const { bannerName } = bannerProps;
      return (
        <PreLoginBanner
          key={`${bannerName}-banner`}
          handleDismissBanner={handleDismissBanner}
          handleOpenDrawer={handleOpenDisclosureDrawer}
          isTopBanner
          {...bannerProps}
        />
      );
    })}
  </TopBannersContainer>
);

PreLoginTopBanners.propTypes = {
  handleDismissBanner: T.func.isRequired,
  handleOpenDisclosureDrawer: T.func.isRequired,
  hasBanner: T.bool.isRequired,
  topBanners: T.arrayOf(T.shape({ bannerName: T.string.isRequired })).isRequired,
};

export default PreLoginTopBanners;
