import styled from 'styled-components/native';

import {
  BorderlessButton,
  BottomDrawer,
  H2,
  H5,
  P2,
  PrimaryDivider,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const Address = styled.View`
  margin-top: ${moderateScale(16)}px;
`;

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const DrawerButtons = styled.View`
  align-items: center;
  margin: ${moderateScale(26)}px 0 ${moderateScale(4)}px;
`;

export const DrawerContainer = styled.View`
  height: 100%;
`;

export const DrawerContent = styled.View`
  flex-grow: 1;
`;

export const FooterContainer = styled.View`
  align-items: center;
  margin: ${moderateScale(26)}px 0 ${moderateScale(12)}px;
`;

export const HeaderWrapper = styled.View`
  align-items: center;
  align-self: center;
  flex-direction: row;
`;

export const IconWrapper = styled.View`
  margin-right: ${moderateScale(7.18)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(16)}px;
`;

export const StyledBorderlessButton = styled(BorderlessButton)`
  margin-top: ${moderateScale(2)}px;
`;

export const StyledBottomDrawer = styled(BottomDrawer)`
  flex: 1;
  margin: ${moderateScale(56)}px ${moderateScale(24)}px ${moderateScale(8)}px;
`;

export const StyledH2 = styled(H2)`
  text-align: center;
`;

export const StyledH5 = styled(H5)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledP2 = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(20)}px;
  text-align: center;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
