import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { updateSelectedInsurance } from '@dmi/shared/redux/Mortgage/actions';
import { makeSelectTaxesAndInsuranceDetails } from '@dmi/shared/redux/Mortgage/selectors';

import { StyledScrollView } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { TaxesInsuranceDetails } from '../../../components/Mortgage/TaxesInsurance';

const TaxesInsuranceDetailsScreen = ({
  detailsData,
  dispatchUpdateSelectedInsurance,
  navigation,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Taxes & Insurance Details"
    />
    <StyledScrollView showsVerticalScrollIndicator={false}>
      <TaxesInsuranceDetails
        dispatchUpdateSelectedInsurance={dispatchUpdateSelectedInsurance}
        {...detailsData}
      />
    </StyledScrollView>
  </Fragment>
);

TaxesInsuranceDetailsScreen.propTypes = {
  detailsData: T.object.isRequired,
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  detailsData: makeSelectTaxesAndInsuranceDetails(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Mortgage
   */
  dispatchUpdateSelectedInsurance: (payload) => dispatch(updateSelectedInsurance(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(TaxesInsuranceDetailsScreen);
