import React from 'react';
import T from 'prop-types';

import ConditionalRender from '../../ConditionalRender';
import { P3 } from '../../Paragraphs';
import {
  BannerContainer,
  BoldText,
  IllustrationWrapper,
  MessageContainer,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const Illustration = iconDictionary('megaphoneS');

const AlertBanner = ({ boldText, lineBreak, text }) => (
  <BannerContainer>
    <IllustrationWrapper>
      {Illustration}
    </IllustrationWrapper>
    <MessageContainer>
      <ConditionalRender
        Component={(
          <P3>
            <BoldText>{boldText}</BoldText>
            {lineBreak ? '\n' : ' '}
            {text}
          </P3>
        )}
        FallbackComponent={(<P3>{text}</P3>)}
        shouldRender={!!boldText}
      />
    </MessageContainer>
  </BannerContainer>
);

AlertBanner.propTypes = {
  boldText: T.string,
  lineBreak: T.bool,
  text: T.oneOfType([T.string, T.element]).isRequired,
};

export default AlertBanner;
