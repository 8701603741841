import React from 'react';
import T from 'prop-types';

import { FilterGroup } from '../../../base_ui';
import { FilterWrapper } from './styledComponents';
import { StyledPrimaryDivider } from '../styledComponents';

const Filter = ({ dispatchChangeFilter, selectedFilter }) => (
  <FilterWrapper>
    <FilterGroup
      handleOnPress={dispatchChangeFilter}
      isStatementsFilter
      labels={['All Types', 'Monthly', 'Escrow', 'Year-End']}
      selectedFilter={selectedFilter}
    />
    <StyledPrimaryDivider />
  </FilterWrapper>
);

Filter.propTypes = {
  dispatchChangeFilter: T.func.isRequired,
  selectedFilter: T.string.isRequired,
};

export default Filter;
