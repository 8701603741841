import React from 'react';
import T from 'prop-types';

import PulsateWrapper from '../PulsateWrapper';
import { SkeletonBlock } from './styledComponents';

const Skeleton = ({
  hasPulsateWidth = false,
  height,
  width,
  ...restProps
}) => (
  <PulsateWrapper hasWidth={hasPulsateWidth}>
    <SkeletonBlock
      $height={height}
      $width={width}
      {...restProps}
    />
  </PulsateWrapper>
);

Skeleton.propTypes = {
  hasPulsateWidth: T.bool,
  height: T.string.isRequired,
  width: T.string.isRequired,
};

export default Skeleton;
