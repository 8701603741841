import React from 'react';
import T from 'prop-types';

import { REVIEW_DICTIONARY_OTP } from '@dmi/shared/redux/Payments/OneTimePayment/constants';

import {
  CheckboxContainer,
  Container,
  LinkText,
  StyledCheckboxWithText,
  StyledTextButton,
} from './styledComponents';

const LatePaymentConfirmationRow = ({ checked, navigation, onPress }) => {
  const { shared: { latePaymentCheckboxLabel } } = REVIEW_DICTIONARY_OTP;

  return (
    <Container>
      <CheckboxContainer>
        <StyledCheckboxWithText
          checked={checked}
          onPress={onPress}
          title={latePaymentCheckboxLabel}
        />
      </CheckboxContainer>
      <StyledTextButton onPress={() => navigation.goBack()}>
        <LinkText>Edit Payment</LinkText>
      </StyledTextButton>
    </Container>
  );
};

LatePaymentConfirmationRow.propTypes = {
  checked: T.bool.isRequired,
  navigation: T.object.isRequired,
  onPress: T.func.isRequired,
};

export default LatePaymentConfirmationRow;
