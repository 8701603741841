import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { CheckboxWithText } from '../base_ui';
import ConsentFormattedMessage from './ConsentFormattedMessage';
import {
  CheckboxContainer,
  CheckboxLabel,
  StyledH5,
  StyledP3,
} from './styledComponents';

const ConsentForm = ({
  checkboxAValues: {
    checked: checkedA,
    labelId: labelIdA,
    setChecked: setCheckedA,
    shouldRender: shouldRenderA,
  },
  checkboxBValues: {
    checked: checkedB,
    labelId: labelIdB,
    setChecked: setCheckedB,
    shouldRender: shouldRenderB,
  },
  descId,
  disclaimerButtonHandler,
  headingText,
}) => (
  <View>
    <StyledH5>{headingText}</StyledH5>
    <ConsentFormattedMessage
      pressHandler={disclaimerButtonHandler}
      textId={descId}
      WrapperComponent={StyledP3}
    />
    <CheckboxContainer $shouldRender={shouldRenderA}>
      <CheckboxWithText
        checked={checkedA}
        onPress={() => setCheckedA(!checkedA)}
        title={(
          <CheckboxLabel>
            <ConsentFormattedMessage
              pressHandler={disclaimerButtonHandler}
              textId={labelIdA}
              WrapperComponent={StyledP3}
            />
          </CheckboxLabel>
        )}
      />
    </CheckboxContainer>
    <CheckboxContainer $addBottomMargin $shouldRender={shouldRenderB}>
      <CheckboxWithText
        checked={checkedB}
        onPress={() => setCheckedB(!checkedB)}
        title={(
          <CheckboxLabel>
            <ConsentFormattedMessage
              pressHandler={disclaimerButtonHandler}
              textId={labelIdB}
              WrapperComponent={StyledP3}
            />
          </CheckboxLabel>
        )}
      />
    </CheckboxContainer>
  </View>
);

ConsentForm.propTypes = {
  checkboxAValues: T.shape({
    checked: T.bool,
    labelId: T.string,
    setChecked: T.func,
    shouldRender: T.bool,
  }).isRequired,
  checkboxBValues: T.shape({
    checked: T.bool,
    labelId: T.string,
    setChecked: T.func,
    shouldRender: T.bool,
  }).isRequired,
  descId: T.string.isRequired,
  disclaimerButtonHandler: T.func.isRequired,
  headingText: T.string.isRequired,
};

export default ConsentForm;
