import React from 'react';
import T from 'prop-types';

import useDocPicker from '../useDocPicker';
import { StyledLargeProgressButton } from './styledComponents';

const PrimaryDocPickerButton = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  isSecondFile = false,
  label = 'Get Started',
  reviewScreenName,
}) => {
  const { showDocPickerOptions } = useDocPicker({
    dispatchChooseDocument,
    isSecondFile,
    reviewScreenName,
  });

  return (
    <StyledLargeProgressButton
      label={label}
      onPress={showDocPickerOptions}
      status={chooseDocumentRequestStatus}
    />
  );
};

PrimaryDocPickerButton.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  isSecondFile: T.bool,
  label: T.string,
  reviewScreenName: T.string.isRequired,
};

export default PrimaryDocPickerButton;
