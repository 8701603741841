/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function PaperlessBillingDog(props) {
  return (
    <Svg
      fill="none"
      height={moderateScale(85)}
      viewBox="0 0 85 85"
      width={moderateScale(85)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M34.0001 6.0002C36.5 2.5 43.0001 3.99997 44.5001 4.0002C46.0001 4.00043 46.0859 2.37058 50.5001 3.50027C55.0001 3.50027 64.4998 8.5 68.5 13.5C70.4448 15.931 73.5 19.5 71.5 23.5C70.1986 26.6238 66.0001 28 64.0001 27C64.0001 27 64.5 33.5 64.0001 36C63.5003 38.5 61.0001 43.0004 61.0001 43.0004C61.0001 43.0004 65.5001 47.0004 66.0001 53.5002C66.4088 58.8135 65.481 59.7836 65.1286 59.9606C65.8805 59.74 69.7501 58.7501 72 61C74.5 63.5 74.0001 68.0002 74.0001 68.0002C74.0001 68.0002 71.5 78.6667 69.5 80C67.5 81.3333 65.5001 81 65.5001 81C65.5001 81 62.5001 83.0002 59.0001 83.0002C55.5001 83.0002 53 79.5 53 79.5C53 79.5 49.0001 80.5002 47.5001 80.5002C46.0001 80.5002 43.0001 79.0002 43.0001 79.0002C43.0001 79.0002 40.5001 83.0002 37.0001 83.0002C33.5001 83.0002 30.0001 80.5002 30.0001 80.5002C30.0001 80.5002 28.0001 81.9998 25.5001 79.5C23.0001 77.0002 15.0001 75 11.5001 69.5002C8.00011 64.0004 9.99996 58 9.99996 58C9.99996 58 12.5 61.5 14 63.5C15.5 65.5 21 68 21 68C21 68 22.0003 64.0004 24.0001 61.5002C26 59 29.5001 60.0002 29.5001 60.0002V52.5C29.5001 51.4998 31.5001 48.0002 31.5001 48.0002C31.5001 48.0002 24.0001 37.5 24.0001 35.5002C24.0001 34.5003 23.875 36 22.5 36.5C21.1249 37 18.5 36.5001 16.5001 35.5002C12.5002 33.5004 14.0001 24.5002 14.0001 24.5002C14.0001 24.5002 17.6585 16.3278 23 11.5C28.5 5 32.9217 7.51009 34.0001 6.0002Z" fill="white" />
      <Path d="M34.0001 6.0002C36.5 2.5 43.0001 3.99997 44.5001 4.0002C46.0001 4.00043 46.0859 2.37058 50.5001 3.50027C55.0001 3.50027 64.4998 8.5 68.5 13.5C70.4448 15.931 73.5 19.5 71.5 23.5C70.1986 26.6238 66.0001 28 64.0001 27C64.0001 27 64.5 33.5 64.0001 36C63.5003 38.5 61.0001 43.0004 61.0001 43.0004C61.0001 43.0004 65.5001 47.0004 66.0001 53.5002C66.5001 60 65 60.0002 65 60.0002C65 60.0002 69.5 58.5 72 61C74.5 63.5 74.0001 68.0002 74.0001 68.0002C74.0001 68.0002 71.5 78.6667 69.5 80C67.5 81.3333 65.5001 81 65.5001 81C65.5001 81 62.5001 83.0002 59.0001 83.0002C55.5001 83.0002 53 79.5 53 79.5C53 79.5 49.0001 80.5002 47.5001 80.5002C46.0001 80.5002 43.0001 79.0002 43.0001 79.0002C43.0001 79.0002 40.5001 83.0002 37.0001 83.0002C33.5001 83.0002 30.0001 80.5002 30.0001 80.5002C30.0001 80.5002 28.0001 81.9998 25.5001 79.5C23.0001 77.0002 15.0001 75 11.5001 69.5002C8.00011 64.0004 9.99996 58 9.99996 58C9.99996 58 12.5 61.5 14 63.5C15.5 65.5 21 68 21 68C21 68 22.0003 64.0004 24.0001 61.5002C26 59 29.5001 60.0002 29.5001 60.0002C29.5001 60.0002 29.5001 53.5002 29.5001 52.5C29.5001 51.4998 31.5001 48.0002 31.5001 48.0002C31.5001 48.0002 24.0001 37.5 24.0001 35.5002C24.0001 34.5003 23.875 36 22.5 36.5C21.1249 37 18.5 36.5001 16.5001 35.5002C12.5002 33.5004 14.0001 24.5002 14.0001 24.5002C14.0001 24.5002 17.6585 16.3278 23 11.5C28.5 5 32.9217 7.51009 34.0001 6.0002Z" stroke="white" />
      <Path d="M28.9567 58.2191C28.0296 57.9825 27.0568 57.9856 26.1312 58.2282C25.2057 58.4707 24.3586 58.9446 23.6708 59.6043C21.1976 61.8944 20.7046 67.131 22.604 72.5278L23.9375 76.7636C24.2918 77.8821 24.997 78.8594 25.9506 79.5535C26.9041 80.2476 28.0563 80.6223 29.2396 80.623L29.9427 80.567" fill="#DCE2E7" />
      <Path d="M28.0195 12.25C28.0195 12.25 23.1701 18.7518 21.6506 22.9475C20.1311 27.1432 19.8725 34.6539 20.5029 35.831C20.5029 35.831 24.0268 35.4386 24.2208 34.8861C24.4148 34.3336 21.8123 20.8657 28.0195 12.25Z" fill="#DCE2E7" />
      <Path d="M51.8301 7.38965C51.8301 7.38965 58.9264 11.3372 62.0785 14.524C65.2306 17.7108 68.5444 24.4608 68.4959 25.79C68.4959 25.79 65.1255 26.871 64.7214 26.4546C64.3173 26.0382 61.0682 12.6984 51.8301 7.38965Z" fill="#DCE2E7" />
      <Path d="M9.64795 62.9751C9.64795 62.9751 10.9654 68.9804 16.1138 70.8541C21.2623 72.7278 22.6444 72.5276 22.6444 72.5276L24.7054 78.2206C24.7054 78.2206 18.1183 75.8185 14.1983 72.3194C10.2784 68.8203 9.64795 62.9751 9.64795 62.9751Z" fill="#DCE2E7" />
      <Path d="M43.4325 64.1201C43.4325 64.1201 43.9093 75.8666 52.3231 77.3319L52.9616 78.9333C51.1362 79.7752 49.1015 80.0675 47.11 79.774C45.7896 79.5215 44.486 79.19 43.2062 78.7812C42.6404 78.4769 43.4325 64.1201 43.4325 64.1201Z" fill="#DCE2E7" />
      <Path d="M39.7954 57.8023L41.3391 61.1332L56.9138 52.9019L55.3701 50.0754L58.9667 49.8032L57.5766 47.8174C57.5766 47.8174 61.4965 44.3663 62.2482 42.6448L60.7853 41.4678L60.5428 36.7676H59.9124L58.9263 38.4571L56.7522 37.3681L54.578 44.7186L51.7654 45.5834L53.1555 48.442L49.672 49.0265L52.0483 52.0852L39.7954 57.8023Z" fill="#DCE2E7" />
      <Path d="M45.5259 35.6145L44.0469 28.5923" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M49.1141 22.1066C48.7827 20.5051 45.768 19.8566 42.3734 20.5532C38.9788 21.2498 36.4975 23.0914 36.8208 24.6528C36.8561 24.8055 36.9161 24.9515 36.9986 25.0852C37.5944 26.3759 38.5821 27.45 39.8239 28.1577C41.0657 28.8654 42.4995 29.1713 43.9252 29.0327V29.1208L44.1434 29.0327H44.3778V28.9366C45.8183 28.4769 47.0644 27.5573 47.9213 26.3215C48.7782 25.0858 49.1977 23.6035 49.1141 22.1066V22.1066Z" fill="black" />
      <Path d="M33.1676 4.77155C33.1676 4.77155 27.2837 3.38632 20.0823 12.9068C12.8809 22.4273 12.0646 28.921 14.748 33.3329C17.4313 37.7449 23.4365 35.5509 23.4365 35.5509" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M28.2944 11.8496C28.2944 11.8496 20.1393 25.79 25.6514 39.0178C31.1636 52.2455 47.8779 48.7945 47.8779 48.7945" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M33.4507 4.81919C34.6362 3.29653 36.3498 2.26371 38.2597 1.92062C40.2412 1.50283 42.3046 1.70137 44.1679 2.48912C44.1679 2.48912 49.0173 -1.11408 59.4435 4.76314C69.8698 10.6404 73.2563 16.2293 72.5854 21.3619C71.9146 26.4945 65.5214 26.8548 65.5214 26.8548" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M51.4907 7.05322C51.4907 7.05322 64.6003 16.5897 64.8994 30.8984C65.1984 45.2071 48.4922 48.6662 48.4922 48.6662" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M43.206 62.6548V76.4992C43.206 78.128 42.5529 79.6901 41.3903 80.8418C40.2277 81.9936 38.651 82.6406 37.0068 82.6406H35.3904C33.7463 82.6406 32.1695 81.9936 31.0069 80.8418C29.8443 79.6901 29.1912 78.128 29.1912 76.4992V52.4778C29.1912 52.4778 29.0781 48.6104 31.5593 46.4805" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M52.3394 62.655V76.4993C52.3415 78.1289 52.9964 79.6909 54.1603 80.8425C55.3241 81.994 56.9017 82.6408 58.5466 82.6408H60.1146C61.7594 82.6408 63.3371 81.994 64.5009 80.8425C65.6648 79.6909 66.3197 78.1289 66.3218 76.4993V52.478C66.3323 50.3731 65.8649 48.2929 64.9541 46.3913C64.0433 44.4897 62.7125 42.8153 61.0602 41.4922" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M28.9567 58.2191C28.0296 57.9825 27.0567 57.9856 26.1312 58.2282C25.2057 58.4707 24.3586 58.9446 23.6708 59.6043C21.1976 61.8944 20.7046 67.131 22.604 72.5278L23.9375 76.7636C24.2918 77.8821 24.997 78.8594 25.9506 79.5535C26.9041 80.2476 28.0563 80.6223 29.2396 80.623H30.9045" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M66.7578 58.2191C67.6849 57.9825 68.6578 57.9856 69.5833 58.2282C70.5088 58.4707 71.3559 58.9446 72.0437 59.6043C74.5169 61.8944 75.0099 67.131 73.1106 72.5278L71.777 76.7636C71.4227 77.8821 70.7175 78.8594 69.7639 79.5535C68.8104 80.2476 67.6582 80.6223 66.475 80.623H64.7534" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M42.6401 79.0054C45.9536 80.377 49.673 80.4315 53.0259 79.1575" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M20.923 66.6025C14.2147 65.1292 9.5269 55.3926 9.5269 55.3926C9.5269 55.3926 5.17051 72.752 24.3095 77.7164" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M31.1875 20.9616C31.2083 20.5032 31.3763 20.0634 31.6671 19.7062C31.9578 19.349 32.3562 19.0931 32.804 18.9759C33.23 18.8276 33.6899 18.8031 34.1296 18.9052C34.5692 19.0074 34.9702 19.2319 35.2852 19.5524" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M48.2012 17.599C48.2794 17.1515 48.4993 16.7404 48.8292 16.4251C49.1591 16.1098 49.5817 15.9068 50.0359 15.8454C50.477 15.7556 50.9349 15.7922 51.3558 15.9511C51.7767 16.1099 52.1431 16.3844 52.4121 16.7422" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M58.191 28.04L29.4663 41.0836L37.5891 58.6352L51.8463 52.1655L49.9227 48.9306L52.9213 48.298L52.0807 45.4795L54.1336 44.7989L56.72 37.3363L58.983 38.6415L59.7266 36.7598L62.2564 36.8158L58.191 28.04Z" fill="white" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
      <Path d="M32.5537 41.692L49.4862 43.4696L55.6773 33.6128" fill="white" />
      <Path d="M32.5537 41.692L49.4862 43.4696L55.6773 33.6128" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M29.8701 32.5C29.8701 32.5 29.9105 35.5907 35.3257 36.5036C40.7409 37.4164 45.566 36.1512 44.976 32.9404" fill="white" />
      <Path d="M29.8701 32.5C29.8701 32.5 29.9105 35.5907 35.3257 36.5036C40.7409 37.4164 45.566 36.1512 44.976 32.9404" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M58.7404 26.7188C58.7404 26.7188 59.9042 29.5853 55.2488 32.4759C50.5934 35.3664 45.655 36.047 44.96 32.8602" fill="white" />
      <Path d="M58.7404 26.7188C58.7404 26.7188 59.9042 29.5853 55.2488 32.4759C50.5934 35.3664 45.655 36.047 44.96 32.8602" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
    </Svg>
  );
}
