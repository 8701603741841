/**
 * @param {object} options
 * @param {boolean} options.isSecondFile
 * @param {function} options.dispatchChooseDocument
 * @param {string=} options.reviewScreenName
 */
const useDocPicker = ({ dispatchChooseDocument, isSecondFile, reviewScreenName }) => ({ // eslint-disable-line object-curly-newline, max-len
  showDocPickerOptions: () => dispatchChooseDocument({
    isSecondFile,
    reviewScreenName,
    type: 'file',
  }),
});

export default useDocPicker;
