import React, { Fragment } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getSelectedLoanAddress,
  getSelectedLoanMaskedLoanNumber,
} from '@dmi/shared/redux/Main/selectors';
import {
  resetBannerError,
  setBannerError,
  setIsModalOpen,
  updateAmount,
} from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import {
  selectFormattedBannerError,
  selectIsScheduledDateAfterGracePeriod,
  selectOtp,
  selectOtpDueDate,
  selectOtpFields,
  selectOtpForm,
  selectOtpFormErrors,
  selectRegularMonthlyPayment,
  selectTotalPayment,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';

import { EditPaymentAmount } from '../../../components/Payments/OneTimePayment';

const EditPaymentAmountScreen = ({
  address,
  amountForm,
  amountFormErrors,
  availableNumberOfPayments,
  bannerError,
  dispatchResetBannerError,
  dispatchSetBannerError,
  dispatchSetIsModalOpen,
  dispatchUpdateAmount,
  dueDate,
  fields,
  isAfterGracePeriod,
  isScheduledDateAfterGracePeriod,
  maskedLoanNumber,
  maxAdditionalPrincipal,
  maxTotalPayment,
  navigation,
  nsfFees,
  otherFees,
  outstandingLateCharges,
  paymentLogic,
  payType,
  regularMonthlyPayment,
  totalPayment,
}) => (
  <Fragment>
    <EditPaymentAmount
      address={address}
      amountForm={amountForm}
      amountFormErrors={amountFormErrors}
      availableNumberOfPayments={availableNumberOfPayments}
      bannerError={bannerError}
      dispatchResetBannerError={dispatchResetBannerError}
      dispatchSetBannerError={dispatchSetBannerError}
      dispatchSetIsModalOpen={dispatchSetIsModalOpen}
      dispatchUpdateAmount={dispatchUpdateAmount}
      dueDate={dueDate}
      fields={fields}
      isAfterGracePeriod={isAfterGracePeriod}
      isScheduledDateAfterGracePeriod={isScheduledDateAfterGracePeriod}
      loanNumber={maskedLoanNumber}
      maxEqualValues={{
        additionalPrincipal: maxAdditionalPrincipal,
        lateCharge: outstandingLateCharges,
        nsfFees,
        otherFees,
      }}
      maxTotalPayment={maxTotalPayment}
      navigation={navigation}
      paymentLogic={paymentLogic}
      payType={payType}
      regularMonthlyPayment={regularMonthlyPayment}
      totalPayment={totalPayment}
    />
  </Fragment>
);

EditPaymentAmountScreen.propTypes = {
  address: T.string.isRequired,
  amountForm: T.object.isRequired,
  amountFormErrors: T.object.isRequired,
  availableNumberOfPayments: T.array.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchSetBannerError: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  dispatchUpdateAmount: T.func.isRequired,
  dueDate: T.string.isRequired,
  fields: T.array.isRequired,
  isAfterGracePeriod: T.bool.isRequired,
  isScheduledDateAfterGracePeriod: T.bool.isRequired,
  maskedLoanNumber: T.string.isRequired,
  maxAdditionalPrincipal: T.number.isRequired,
  maxTotalPayment: T.number.isRequired,
  navigation: T.object.isRequired,
  nsfFees: T.number,
  otherFees: T.number,
  outstandingLateCharges: T.number,
  paymentLogic: T.object.isRequired,
  payType: T.string.isRequired,
  regularMonthlyPayment: T.string.isRequired,
  totalPayment: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  address: getSelectedLoanAddress(),
  maskedLoanNumber: getSelectedLoanMaskedLoanNumber(),
  /**
   * Store: One Time Payment
   */
  // eslint-disable-next-line sort-keys
  amountForm: selectOtpForm('amount'),
  amountFormErrors: selectOtpFormErrors('amount'),
  availableNumberOfPayments: selectOtp('availableNumberOfPayments'),
  bannerError: selectFormattedBannerError(),
  dueDate: selectOtpDueDate(),
  fields: selectOtpFields(),
  isAfterGracePeriod: selectOtp('isAfterGracePeriod'),
  isScheduledDateAfterGracePeriod: selectIsScheduledDateAfterGracePeriod(),
  maxAdditionalPrincipal: selectOtp('maximumAllowedAdditionalPrincipal'),
  maxTotalPayment: selectOtp('maximumAllowedTotalPayment'),
  nsfFees: selectOtp('nsfFees'),
  otherFees: selectOtp('otherFees'),
  outstandingLateCharges: selectOtp('outstandingLateCharges'),
  paymentLogic: selectOtp('paymentLogic'),
  payType: selectOtp('payType'),
  regularMonthlyPayment: selectRegularMonthlyPayment(),
  totalPayment: selectTotalPayment(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: One Time Payment
   */
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchSetBannerError: (payload) => dispatch(setBannerError(payload)),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
  dispatchUpdateAmount: (payload) => dispatch(updateAmount(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditPaymentAmountScreen);
