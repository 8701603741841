import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import {
  BodyInnerWrapper,
  StyledInternalLink,
  StyledPrimaryDivider,
  TH,
  TR,
  Table,
} from './styledComponents';

const HomeHistoryBody = ({ historyData }) => (
  <BodyInnerWrapper>
    <Table>
      <View>
        <TH>Date</TH>
        {historyData.map(({ date, id }) => (
          <TR key={id}>{date}</TR>
        ))}
      </View>
      <View>
        <TH>Amount</TH>
        {historyData.map(({ amount, id }) => (
          <TR key={id}>{amount}</TR>
        ))}
      </View>
    </Table>
    <StyledPrimaryDivider />
    <StyledInternalLink
      label="See All Payment History"
      path="PaymentHistoryStack"
      size="medium"
    />
  </BodyInnerWrapper>
);

HomeHistoryBody.propTypes = {
  historyData: T.arrayOf(T.shape({
    amount: T.string.isRequired,
    date: T.string.isRequired,
    id: T.number.isRequired,
  })).isRequired,
};

export default HomeHistoryBody;
