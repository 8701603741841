import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H2, P2 } from '../../../base_ui';

export const AnimationContainer = styled.View`
  align-self: center;
  margin-bottom: ${moderateScale(16)}px;
  margin-top: ${moderateScale(22)}px;
  max-width: ${moderateScale(287)}px;
`;

export const CardButtonWrapper = styled.View`
  margin-top: ${moderateScale(8)}px;
  margin-bottom: ${moderateScale(25)}px;
`;

export const Container = styled.View`
  flex: 1;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  padding-horizontal: ${moderateScale(24)}px;
`;

export const ConfirmationRow = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(24)}px ${moderateScale(6)}px ${moderateScale(21)}px;
  width: 100%;
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const StyledH2 = styled(H2)`
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  text-align: center;
`;
