import { moderateScale } from '../../utils/scaleHelpers';

// CMS was implemented to have external links being a full absolute url,
// whereas internal links are just the pathname and start with a leading slash.
export const isExternal = (link) => !(link[0] === '/');

// Moving this constant to constants.js will cause dependency cycle.
export const HEADING_LINE_HEIGHT_DICT = {
  'header-five': `line-height: ${moderateScale(18)}px;`,
  'header-four': `line-height: ${moderateScale(22)}px;`,
  'header-one': `line-height: ${moderateScale(32)}px;`,
  'header-three': `line-height: ${moderateScale(24)}px;`,
  'header-two': `line-height: ${moderateScale(26)}px;`,
};
