import styled from 'styled-components/native';

import { LargePrimaryButton, P3 } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BoldText = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { bold } } }) => bold};
`;

export const StyledButtonView = styled.View`
  align-items: center;
  justify-content: center;
`;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  margin: ${moderateScale(24)}px auto ${moderateScale(36)}px;
  width: 70%;
`;

export const StyledSecondaryHeader = styled(SecondaryHeader)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const StyledView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
  height: 100%;
`;
