/* eslint-disable object-curly-newline */
/*
 * Change Email Confirmation Screen
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resetState } from '@dmi/shared/redux/Register/ChangeEmail/actions';
import {
  getChangeEmailConfirmationPageProps,
} from '@dmi/shared/redux/Register/ChangeEmail/selectors';

import { StyledScrollView } from '../../../components/base_ui';
import Confirmation from '../../../components/Register/Confirmation';
import { CHANGE_EMAIL_CONFIRMATION_PROPS } from './constants';

const ChangeEmailConfirmationView = ({
  confirmationPageProps,
  dispatchResetState,
  navigation,
}) => {
  useEffect(() => () => dispatchResetState(), [dispatchResetState]);

  return (
    <StyledScrollView>
      <Confirmation
        dispatchResetState={dispatchResetState}
        handlePress={() => navigation.navigate('Login')}
        viewCardProps={CHANGE_EMAIL_CONFIRMATION_PROPS}
        {...confirmationPageProps}
      />
    </StyledScrollView>
  );
};
ChangeEmailConfirmationView.propTypes = {
  confirmationPageProps: T.object.isRequired,
  dispatchResetState: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  confirmationPageProps: getChangeEmailConfirmationPageProps(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchResetState: () => dispatch(resetState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ChangeEmailConfirmationView);
