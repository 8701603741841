import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import { ConditionalRender, LargePrimaryButton } from '../../base_ui';
import DatePicker from './DatePicker';
import PayoffReason from './PayoffReason';

const PayoffView = ({
  availablePayOffReasons,
  dateSelectorIsDisabled,
  dispatchCreatePayoffStatement,
  dispatchUpdateSelectedPayOffReason,
  loanNumber,
  navigation,
  payoffRequestBody: {
    payoffDate,
    payoffReason,
    requestType,
  },
  selectedDate,
}) => {
  const [selectedPayOffReason, setSelectedPayOffReason] = useState(payoffReason);

  return (
    <Fragment>
      <DatePicker
        dateSelectorIsDisabled={dateSelectorIsDisabled}
        dispatchUpdateSelectedPayOffReason={dispatchUpdateSelectedPayOffReason}
        isPaydown={false}
        navigation={navigation}
        selectedDate={selectedDate}
        selectedPayOffReason={selectedPayOffReason}
      />
      <ConditionalRender
        Component={PayoffReason}
        propsToPassDown={{
          availablePayOffReasons,
          selectedPayOffReason,
          setSelectedPayOffReason,
        }}
        shouldRender={!!selectedDate}
      />
      <LargePrimaryButton
        disabled={!selectedDate || !selectedPayOffReason}
        label="Submit Request"
        onPress={() => dispatchCreatePayoffStatement({
          loanNumber,
          payoffDate,
          payoffReason: selectedPayOffReason,
          requestType,
        })}
      />
    </Fragment>
  );
};

PayoffView.propTypes = {
  availablePayOffReasons: T.array.isRequired,
  dateSelectorIsDisabled: T.bool.isRequired,
  dispatchCreatePayoffStatement: T.func.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  payoffRequestBody: T.shape({
    payoffDate: T.string.isRequired,
    payoffReason: T.string.isRequired,
    requestType: T.string.isRequired,
  }).isRequired,
  selectedDate: T.string.isRequired,
};

export default PayoffView;
