import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  Prompt,
  fetchDiscoveryAsync,
  loadAsync,
  makeRedirectUri,
} from 'expo-auth-session';

import {
  biometricLoginRequest,
  initializeOidcLogin,
  initializeOidcLoginCancelled,
  initializeOidcLoginFailure,
  oauthLogin,
  openPinDrawer,
  resetMainError,
  resetMainSuccess,
} from '@dmi/shared/redux/Main/actions';
import makeSelectMain, { getError } from '@dmi/shared/redux/Main/selectors';
import makeSelectSettings, { // eslint-disable-line object-curly-newline
  selectPrettySupportedBiometricAuthenticationTypes,
} from '@dmi/shared/redux/Settings/Security/selectors';
import { makeSelectNativeClients } from '@dmi/shared/redux/Mobile/selectors';
import reducer from '@dmi/shared/redux/Login/reducer';
import { toggleDrawer } from '@dmi/shared/redux/Login/actions';
import makeSelectLogin from '@dmi/shared/redux/Login/selectors';
import { DEFAULT_ERROR_MSG } from '@dmi/shared/utils/constants';

import AsyncRender from '../../components/AsyncRender';
import { ClientSelectView } from '../../components/Login';
import LoadingDog from '../../components/LoadingDog';
import { ConditionalRender, StyledScrollView } from '../../components/base_ui';
import injectReducer from '../../utils/injectReducer';
import { PinDrawer } from '../../components/Pin';
import LoginPin from './LoginPin';

const redirectUri = makeRedirectUri();

const ClientSelect = ({
  clients,
  dispatchBiometricLogin,
  dispatchInitializeOidcLogin,
  dispatchInitializeOidcLoginCancelled,
  dispatchInitializeOidcLoginFailure,
  dispatchOauthLogin,
  dispatchOpenPinDrawer,
  dispatchResetMainError,
  dispatchResetMainSuccess,
  dispatchToggleDrawer,
  error,
  isBiometricLoginEnabled,
  isDrawerOpen,
  isPinEnabled,
  loading,
  navigation,
  success,
  supportedBiometricAuthenticationTypes,
}) => {
  useEffect(() => () => {
    dispatchResetMainError();
    dispatchResetMainSuccess();
  }, [dispatchResetMainError, dispatchResetMainSuccess]);
  const [authSession, setAuthSession] = useState({});

  const handleLinkAccountClick = () => navigation.push('Welcome');

  const handlePasswordLogin = async ({
    clientName,
    connectionInformation,
    grantType,
  }) => {
    if (grantType === 'sso_oidc') {
      try {
        dispatchInitializeOidcLogin();
        let discovery;
        const {
          clientId,
          clientSecret,
          connectionResultType,
        } = connectionInformation;

        if (connectionResultType === 'DiscoveryUrl') {
          const { discoveryUrl } = connectionInformation;
          const [origin] = discoveryUrl.split('/.well-known/openid-configuration');
          discovery = await fetchDiscoveryAsync(origin);
        } else {
          const {
            authorizationEndpoint,
            endSessionEndpoint,
            issuer,
            jwksUri,
            tokenEndpoint,
            userInfoEndpoint,
          } = connectionInformation;
          discovery = {
            authorizationEndpoint,
            discoveryDocument: { issuer, jwks_uri: jwksUri },
            endSessionEndpoint,
            tokenEndpoint,
            userInfoEndpoint,
          };
        }
        const request = await loadAsync(
          {
            clientId,
            prompt: Prompt.Login,
            redirectUri,
            scopes: ['openid profile email offline_access'],
          },
          discovery,
        );
        const response = await request.promptAsync(discovery);
        setAuthSession({
          clientName,
          clientSecret,
          discovery,
          request,
          response,
        });
      } catch (err) {
        dispatchInitializeOidcLoginFailure(DEFAULT_ERROR_MSG);
      }
    } else {
      navigation.push('Login'); // Should be grantType 'password'
    }
  };

  const handleBiometricPinLogin = async ({
    clientName,
    connectionInformation,
    grantType,
    refreshToken,
    type,
  }) => {
    try {
      if (grantType === 'sso_oidc' && refreshToken) {
        let discovery;
        const {
          clientId,
          clientSecret,
          connectionResultType,
        } = connectionInformation;

        if (connectionResultType === 'DiscoveryUrl') {
          const { discoveryUrl } = connectionInformation;
          const [origin] = discoveryUrl.split('/.well-known/openid-configuration');
          discovery = await fetchDiscoveryAsync(origin);
        } else {
          const {
            authorizationEndpoint,
            endSessionEndpoint,
            issuer,
            jwksUri,
            tokenEndpoint,
            userInfoEndpoint,
          } = connectionInformation;
          discovery = {
            authorizationEndpoint,
            discoveryDocument: { issuer, jwks_uri: jwksUri },
            endSessionEndpoint,
            tokenEndpoint,
            userInfoEndpoint,
          };
        }
        const ssoClientDetails = {
          clientId,
          clientName,
          clientSecret,
          endSessionEndpoint: discovery.endSessionEndpoint,
          grantType: 'refresh_token',
          issuer: discovery.discoveryDocument.issuer,
          jwksUri: discovery.discoveryDocument.jwks_uri,
          refreshToken,
          tokenEndpoint: discovery.tokenEndpoint,
          userInfoEndpoint: discovery.userInfoEndpoint,
        };
        if (type === 'biometric') {
          dispatchBiometricLogin({ ssoClientDetails });
        } else if (type === 'pin') {
          setAuthSession(ssoClientDetails);
          dispatchOpenPinDrawer();
        }
      }
    } catch (err) {
      dispatchInitializeOidcLoginFailure(DEFAULT_ERROR_MSG);
    }
  };

  useEffect(() => {
    const {
      clientName,
      clientSecret,
      discovery,
      request,
      response,
    } = authSession || {};
    const {
      discoveryDocument = {},
      endSessionEndpoint,
      tokenEndpoint,
      userInfoEndpoint,
    } = discovery || {};
    const { clientId, codeVerifier } = request || {};
    const { params, type } = response || {};
    if (type === 'success' && params.code) {
      const { code } = params;
      dispatchOauthLogin({
        clientId,
        clientName,
        clientSecret,
        code,
        codeVerifier,
        endSessionEndpoint,
        grantType: 'authorization_code',
        issuer: discoveryDocument.issuer,
        jwksUri: discoveryDocument.jwks_uri,
        redirectUri,
        tokenEndpoint,
        userInfoEndpoint,
      });
    } else if (type === 'cancel' || type === 'dismiss') {
      dispatchInitializeOidcLoginCancelled();
    }
  }, [
    authSession,
    dispatchOauthLogin,
    dispatchInitializeOidcLoginCancelled,
  ]);

  const PinBottomDrawerComponent = (
    <PinDrawer
      handleClose={() => dispatchToggleDrawer(false)}
      isVisible={isDrawerOpen}
    >
      <LoginPin
        dispatchToggleDrawer={dispatchToggleDrawer}
        ssoClientDetails={authSession}
      />
    </PinDrawer>
  );

  return (
    <StyledScrollView>
      <AsyncRender
        Component={ClientSelectView}
        error={false}
        loading={loading}
        LoadingComponent={LoadingDog}
        propsToPassDown={{
          clients,
          dispatchToggleDrawer,
          error,
          handleBiometricPinLogin,
          handleLinkAccountClick,
          handlePasswordLogin,
          isBiometricLoginEnabled,
          isPinEnabled,
          success,
          supportedBiometricAuthenticationTypes,
        }}
      />
      <ConditionalRender
        Component={PinBottomDrawerComponent}
        shouldRender={isPinEnabled}
      />
    </StyledScrollView>
  );
};

ClientSelect.propTypes = {
  clients: T.array,
  dispatchBiometricLogin: T.func.isRequired,
  dispatchInitializeOidcLogin: T.func.isRequired,
  dispatchInitializeOidcLoginCancelled: T.func.isRequired,
  dispatchInitializeOidcLoginFailure: T.func.isRequired,
  dispatchOauthLogin: T.func.isRequired,
  dispatchOpenPinDrawer: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchResetMainSuccess: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isDrawerOpen: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  loading: T.bool.isRequired,
  navigation: T.object.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  error: getError('main'),
  loading: makeSelectMain('loading'),
  success: makeSelectMain('success'),
  /**
   * Store: Mobile
   */
  clients: makeSelectNativeClients(),
  /**
   * Store: Settings
   */
  isBiometricLoginEnabled: makeSelectSettings('isBiometricLoginEnabled'),
  isPinEnabled: makeSelectSettings('isPinEnabled'),
  supportedBiometricAuthenticationTypes: selectPrettySupportedBiometricAuthenticationTypes(),
  /**
   * Store: Login
   */
  isDrawerOpen: makeSelectLogin('isDrawerOpen'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Main
   */
  dispatchBiometricLogin: (payload) => dispatch(biometricLoginRequest(payload)),
  dispatchInitializeOidcLogin: () => dispatch(initializeOidcLogin()),
  dispatchInitializeOidcLoginCancelled: () => dispatch(initializeOidcLoginCancelled()),
  dispatchInitializeOidcLoginFailure: (error) => dispatch(initializeOidcLoginFailure(error)),
  dispatchOauthLogin: (payload) => dispatch(oauthLogin(payload)),
  dispatchResetMainError: () => dispatch(resetMainError('main')),
  dispatchResetMainSuccess: () => dispatch(resetMainSuccess()),
  dispatchOpenPinDrawer: () => dispatch(openPinDrawer()),
  /**
   * Store: Login
   */
  dispatchToggleDrawer: (payload) => dispatch(toggleDrawer(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'login', reducer });

export default compose(withConnect, withReducer)(ClientSelect);
