/*
 * Help
 */

import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import reducer from '@dmi/shared/redux/Help/reducer';
import saga from '@dmi/shared/redux/Help/saga';
import { selectIsSecureMessagingTreatmentOn } from '@dmi/shared/redux/SecureMessaging/selectors';
import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { selectNestedScreen } from '@dmi/shared/redux/Mobile/selectors';
import { selectHasLossDraftClaims } from '@dmi/shared/redux/LossDraft/selectors';

import { selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../utils/globalHelpers';
import { HelpHome } from '../../components/Help';
import { StyledScrollView } from '../../components/base_ui';

const HelpHomeScreen = ({
  dispatchSetReduxNestedScreen,
  hasLossDraftClaims,
  isMurabahaAccount,
  isSecureMessagingTreatmentOn,
  navigation,
  reduxNestedScreen,
  route,
}) => {
  useEffect(() => {
    const { params } = route;
    const { nestedScreen: routeNestedScreen } = params || {};
    if (routeNestedScreen || reduxNestedScreen) {
      if (Platform.OS === 'web') {
        setTimeout(() => {
          navigation.navigate(routeNestedScreen || reduxNestedScreen);
          navigation.setParams({ nestedScreen: undefined });
        }, 0);
      } else if (routeNestedScreen) {
        navigation.navigate(routeNestedScreen);
        navigation.setParams({ nestedScreen: undefined });
      }
    }
  }, [
    navigation,
    reduxNestedScreen,
    route,
  ]);

  useFocusEffect(
    useCallback(() => () => {
      dispatchSetReduxNestedScreen({ mainScreen: 'help', nestedScreen: '' });
    }, [dispatchSetReduxNestedScreen]),
  );

  return (
    <StyledScrollView>
      <HelpHome
        hasLossDraftClaims={hasLossDraftClaims}
        isMurabahaAccount={isMurabahaAccount}
        isSecureMessagingTreatmentOn={isSecureMessagingTreatmentOn}
        navigation={navigation}
      />
    </StyledScrollView>
  );
};

HelpHomeScreen.propTypes = {
  dispatchSetReduxNestedScreen: T.func.isRequired,
  hasLossDraftClaims: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
  reduxNestedScreen: T.string,
  route: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  hasLossDraftClaims: selectHasLossDraftClaims(),
  /**
   * Store: Main
   */
  isMurabahaAccount: selectIsMurabahaAccount(),
  isSecureMessagingTreatmentOn: selectIsSecureMessagingTreatmentOn(),
  /**
   * Store: Mobile
   */
  reduxNestedScreen: selectNestedScreen('help'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mobile
   */
  dispatchSetReduxNestedScreen: (payload) => dispatch(setNestedScreen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'help', reducer });
const withSaga = injectSaga({
  key: 'help',
  saga: saga({ baseUrl: getBaseApplicationServerUrl() }),
});

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(HelpHomeScreen);
