import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  resetFormState,
  resetState as resetLossDraftState,
  resetRequestStatus,
  resetState,
  resetStep,
} from '@dmi/shared/redux/LossDraft/actions';
import makeSelectLossDraft,
{ selectLossDraftScreenReaderNotifications }
from '@dmi/shared/redux/LossDraft/selectors';

import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';

import viewDictionary from './viewDictionary';

const MessageDetailsIndex = ({
  dispatchResetFormState,
  dispatchResetLossDraftState,
  dispatchResetRequestStatus,
  dispatchResetStep,
  navigation,
  notifications,
  step,
}) => {
  const [showReplyBox, setShowReplyBox] = useState(false);
  const ComponentToRender = viewDictionary[step];

  useEffect(() => () => {
    dispatchResetRequestStatus({ requestStatus: 'fetchMessageDetails' });
    dispatchResetRequestStatus({ requestStatus: 'postReply' });
    dispatchResetRequestStatus({ requestStatus: 'processDocument' });
    dispatchResetRequestStatus({ requestStatus: 'chooseDocument' });
    dispatchResetLossDraftState({ stateToReset: 'selectedMessageId' });
    dispatchResetLossDraftState({ stateToReset: 'errors' });
    dispatchResetFormState({ form: 'reply' });
    dispatchResetStep();
  }, [
    dispatchResetFormState,
    dispatchResetLossDraftState,
    dispatchResetRequestStatus,
    dispatchResetStep,
  ]);

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <ComponentToRender
        dispatchResetLossDraftState={dispatchResetLossDraftState}
        navigation={navigation}
        setShowReplyBox={setShowReplyBox}
        showReplyBox={showReplyBox}
      />
    </Fragment>
  );
};

MessageDetailsIndex.propTypes = {
  dispatchResetFormState: T.func.isRequired,
  dispatchResetLossDraftState: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  dispatchResetStep: T.func.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  notifications: selectLossDraftScreenReaderNotifications(),
  step: makeSelectLossDraft('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetLossDraftState: (payload) => dispatch(resetLossDraftState(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
  dispatchResetState: (payload) => dispatch(resetState(payload)),
  dispatchResetStep: () => dispatch(resetStep()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MessageDetailsIndex);
