import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import { useFocusEffect } from '@react-navigation/native';

import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { selectNestedScreen } from '@dmi/shared/redux/Mobile/selectors';
import {
  fetchHelocDraw,
  fetchLoan,
  fetchMortgage,
  updateSelectedInsurance,
} from '@dmi/shared/redux/Mortgage/actions';
import makeSelectMain from '@dmi/shared/redux/Main/selectors';
import makeSelectMortgage, {
  makeSelectMortgageHomeHeaderView,
  makeSelectMortgageLoading,
  makeSelectMortgageOverview,
  makeSelectMortgageServiceError,
  makeSelectTaxesAndInsuranceOverview,
  selectExpiredPolicies,
  selectHasEscrow,
  selectMobileMarketingCards,
  selectRequestStatus,
} from '@dmi/shared/redux/Mortgage/selectors';
import reducer from '@dmi/shared/redux/Mortgage/reducer';
import saga from '@dmi/shared/redux/Mortgage/saga';

import { StyledScrollView } from '../../components/base_ui';
import AsyncRender from '../../components/AsyncRender';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../utils/globalHelpers';
import { navigate, replace } from '../../utils/rootNavigation';
import MortgageHome from '../../components/Mortgage/MortgageOverview/MortgageHome';
// eslint-disable-next-line max-len
import MortgageHomeLoading from '../../components/Mortgage/MortgageOverview/MortgageHome/MortgageHomeLoading';
import { usePrevious } from '../../utils/customHooks';

export const Mortgage = ({
  dispatchFetchHelocDraw,
  dispatchFetchLoan,
  dispatchFetchMortgage,
  dispatchSetNestedScreen,
  dispatchUpdateSelectedInsurance,
  escrow,
  escrowError,
  escrowLoading,
  expiredPolicies,
  hasEscrow,
  headerView,
  helocDrawRequestStatus,
  isHelocDrawAvailable,
  loanNumber,
  marketingCards,
  mortgageOverviewData,
  navigation,
  nestedScreen,
  summaryError,
  summaryLoading,
}) => {
  const prevLoanNumber = usePrevious(loanNumber);
  useEffect(() => {
    dispatchFetchLoan({ loanNumber });
    dispatchFetchMortgage({ loanNumber });
  }, [
    dispatchFetchLoan,
    dispatchFetchMortgage,
    loanNumber,
  ]);

  useEffect(() => {
    if (helocDrawRequestStatus === 'idle' || prevLoanNumber !== loanNumber) {
      dispatchFetchHelocDraw({ loanNumber });
    }
  }, [
    helocDrawRequestStatus,
    dispatchFetchHelocDraw,
    loanNumber,
    prevLoanNumber,
  ]);

  useEffect(() => {
    if (nestedScreen) {
      navigation.navigate(nestedScreen);
    }
  }, [navigation, nestedScreen]);

  useFocusEffect(
    useCallback(() => () => {
      dispatchSetNestedScreen({ mainScreen: 'mortgage', nestedScreen: '' });
    }, [dispatchSetNestedScreen]),
  );

  return (
    <StyledScrollView>
      <AsyncRender
        Component={MortgageHome}
        error={escrowError || summaryError}
        errorComponentType="cardLevel"
        loading={escrowLoading || summaryLoading || ['idle', 'loading'].includes(helocDrawRequestStatus)}
        LoadingComponent={MortgageHomeLoading}
        propsToPassDown={{
          dispatchSetNestedScreen,
          dispatchUpdateSelectedInsurance,
          escrow,
          expiredPolicies,
          hasEscrow,
          headerView,
          isHelocDrawAvailable,
          marketingCards,
          mortgageOverviewData,
          navigation,
        }}
      />
    </StyledScrollView>
  );
};

Mortgage.propTypes = {
  dispatchFetchHelocDraw: T.func.isRequired,
  dispatchFetchLoan: T.func.isRequired,
  dispatchFetchMortgage: T.func.isRequired,
  dispatchSetNestedScreen: T.func.isRequired,
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  escrow: T.array.isRequired,
  escrowError: T.oneOfType([T.bool, T.string]).isRequired,
  escrowLoading: T.bool.isRequired,
  expiredPolicies: T.array.isRequired,
  hasEscrow: T.bool.isRequired,
  headerView: T.string.isRequired,
  helocDrawRequestStatus: T.string.isRequired,
  isHelocDrawAvailable: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  marketingCards: T.array.isRequired,
  mortgageOverviewData: T.object.isRequired,
  navigation: T.object.isRequired,
  nestedScreen: T.string,
  summaryError: T.oneOfType([T.bool, T.string]).isRequired,
  summaryLoading: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  loanNumber: makeSelectMain('selectedLoan'),
  /**
   * Store: Mobile
   */
  nestedScreen: selectNestedScreen('mortgage'),
  /**
   * Store: Mortgage
   */
  // eslint-disable-next-line sort-keys
  escrow: makeSelectTaxesAndInsuranceOverview(),
  escrowError: makeSelectMortgageServiceError('escrow'),
  escrowLoading: makeSelectMortgageLoading('escrow'),
  expiredPolicies: selectExpiredPolicies(),
  hasEscrow: selectHasEscrow(),
  headerView: makeSelectMortgageHomeHeaderView(),
  helocDrawRequestStatus: selectRequestStatus('fetchHelocDraw'),
  isHelocDrawAvailable: makeSelectMortgage('isHelocDrawAvailable'),
  marketingCards: selectMobileMarketingCards(),
  mortgageOverviewData: makeSelectMortgageOverview(),
  summaryError: makeSelectMortgageServiceError('summary'),
  summaryLoading: makeSelectMortgageLoading('summary'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mobile
   */
  dispatchSetNestedScreen: (payload) => dispatch(setNestedScreen(payload)),
  /**
   * Store: Mortgage
   */
  // eslint-disable-next-line sort-keys
  dispatchFetchHelocDraw: (payload) => dispatch(fetchHelocDraw(payload)),
  dispatchFetchLoan: (payload) => dispatch(fetchLoan(payload)),
  dispatchFetchMortgage: (payload) => dispatch(fetchMortgage(payload)),
  dispatchUpdateSelectedInsurance: (payload) => dispatch(updateSelectedInsurance(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'mortgage', reducer });
const withSaga = injectSaga({
  key: 'mortgage',
  saga: saga({
    baseUrl: getBaseApplicationServerUrl(),
    navigationHandler: navigate,
    navigationReplaceHandler: replace,
  }),
});

export default compose(
  injectIntl,
  withConnect,
  withReducer,
  withSaga,
)(Mortgage);
