/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

const MarketingFall = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      height={moderateScale(120)}
      viewBox="0 0 287.25 120.25"
      width={moderateScale(287)}
      {...props}
    >
      <G>
        <Path
          className="st0"
          d="M258.2,57.7c-1-0.9-1.3-2.3-0.6-3.4c1.3-2.2,2.1-4.8,2.1-7.5c0-5.2-2.7-9.8-6.9-12.7c-0.5-0.4-0.8-1-0.6-1.7
		c0.2-0.8,0.3-1.5,0.3-2.4c0-5.1-4.4-9.2-9.9-9.2c-5.5,0-9.9,4.1-9.9,9.2c0,0.8,0.1,1.6,0.3,2.4c0.2,0.6-0.1,1.3-0.6,1.7
		c-4.2,2.9-6.9,7.5-6.9,12.7c0,2.7,0.8,5.3,2.1,7.5c0.7,1.1,0.4,2.6-0.6,3.4c-4.4,3.8-7.1,9.3-7.1,15.3c0,11.7,10.2,21.1,22.8,21.1
		c12.6,0,22.8-9.4,22.8-21.1C265.3,67,262.6,61.6,258.2,57.7z"
          fill="#D8DFE5"
        />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="241.9" x2="241.9" y1="94.2" y2="112.3" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="241.9" x2="241.9" y1="44.7" y2="85.1" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="231.3" x2="241.7" y1="64.2" y2="71.2" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="247.4" x2="241.9" y1="53.6" y2="57.4" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="253.9" x2="242.2" y1="71.7" y2="78.8" />
        <Path
          className="st1"
          d="M258.2,57.7c-1-0.9-1.3-2.3-0.6-3.4c1.3-2.2,2.1-4.8,2.1-7.5c0-5.2-2.7-9.8-6.9-12.7c-0.5-0.4-0.8-1-0.6-1.7
		c0.2-0.8,0.3-1.5,0.3-2.4c0-5.1-4.4-9.2-9.9-9.2c-5.5,0-9.9,4.1-9.9,9.2c0,0.8,0.1,1.6,0.3,2.4c0.2,0.6-0.1,1.3-0.6,1.7
		c-4.2,2.9-6.9,7.5-6.9,12.7c0,2.7,0.8,5.3,2.1,7.5c0.7,1.1,0.4,2.6-0.6,3.4c-4.4,3.8-7.1,9.3-7.1,15.3c0,11.7,10.2,21.1,22.8,21.1
		c12.6,0,22.8-9.4,22.8-21.1C265.3,67,262.6,61.6,258.2,57.7z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Polygon className="st2" fill="#FFFFFF" points="137.1,4.4 112.1,24.1 112.1,103.2 98.3,103.2 98.3,113.3 165.6,113.8 165.6,20 143.5,4.6 	" />
        <Polygon className="st2" fill="#FFFFFF" points="273.3,112.9 220.7,112.9 239.4,116.4 258.1,116.6 	" />
        <Path
          className="st3"
          d="M99.8,113.9H83.5v0c-0.1,0-0.3,0-0.4,0c-1.7,0-3.1-1.4-3.1-3.1c0-1.3,0.8-2.4,1.9-2.9c0.1-2,1.8-3.5,3.8-3.5
		c0.5,0,1,0.1,1.4,0.3c0.1-2.7,2.4-4.9,5.1-4.9c1.3,0,2.5,0.5,3.4,1.3c0,0,1.5-2.8,5.7-2.6"
          fill="#E6EBEF"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Path className="st4" d="M82.2,110.1c0,0,0.1-2.4,2.7-2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
        <Circle className="st2" cx="103.8" cy="67.4" fill="#FFFFFF" r="0.7" />
        <Polygon className="st5" fill="none" points="167.4,102.6 112.1,102.6 112.1,25.8 138.2,5.5 155.9,4.4 167.4,12.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Rect className="st6" fill="#E6EBEF" height="2.6" width="8.1" x="145.6" y="75.4" />
        <Rect className="st6" fill="#E6EBEF" height="11.1" width="14" x="156.7" y="102.9" />
        <Rect className="st7" fill="#BAC2CA" height="2.4" width="53.2" x="112" y="65.9" />
        <Rect className="st6" fill="#E6EBEF" height="13.5" width="8.2" x="121.7" y="35.7" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="120.9,46 125.2,35.7 121.4,35.7 120.9,36.8 	" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="126.2,49.2 123.8,49.2 129.4,35.7 131.7,35.7 	" />
        <Rect className="st6" fill="#E6EBEF" height="78.1" width="17.7" x="165.5" y="24.9" />
        <Path className="st7" d="M170,31h13.2v-1.2l-13.6-0.2l-4.2-3.2l-0.1,1.6l3.4,2.4C169.2,30.8,169.6,31,170,31z" fill="#BAC2CA" />
        <Path className="st2" d="M187.7,29.7h-16.5v-2h16.5c0.6,0,1,0.5,1,1v0C188.8,29.2,188.3,29.7,187.7,29.7z" fill="#FFFFFF" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="170.2" x2="187.9" y1="29.7" y2="29.7" />
        <G>
          <Polygon className="st7" fill="#BAC2CA" points="188.1,27.6 155.4,2.8 138.4,2.8 171.1,27.7 168.3,27.7 		" />
          <Path
            className="st2"
            d="M138.8,3.3c-0.2-0.2-0.5-0.3-0.9-0.3c-0.3,0-0.6,0.1-0.8,0.4l-30.8,24.3c-0.4,0.4-0.4,1.1,0.1,1.4
			c0.5,0.4,1.2,0.3,1.6-0.1l30-23.4l31.5,23.8c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.5-0.4,0.5-1,0.1-1.4L138.8,3.3z"
            fill="#FFFFFF"
          />
          <Polygon className="st7" fill="#BAC2CA" points="112,25.8 112,29.3 107.6,29.3 		" />
          <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="107.4" x2="111.9" y1="29.4" y2="29.4" />
          <Polygon className="st7" fill="#BAC2CA" points="139.2,6.6 112.3,27.5 112.3,25.5 138,5.7 		" />
          <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="188.1" x2="170.8" y1="27.7" y2="27.7" />
          <Polyline className="st1" fill="none" points="137.9,2.9 144,2.8 148.2,2.8 155.4,2.8 179.3,21 180.3,21.7 188.1,27.7 		" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path
            className="st1"
            d="M116.3,19.7l-10,7.9c-0.4,0.4-0.4,1.1,0.1,1.4c0.5,0.4,1.2,0.3,1.6-0.1l30-23.4l31.5,23.8
			c0.2,0.2,0.5,0.3,0.8,0.3c0.3,0,0.5-0.1,0.8-0.3c0.5-0.4,0.5-1,0.1-1.4L138.8,3.3c-0.2-0.2-0.5-0.3-0.9-0.3
			c-0.3,0-0.6,0.1-0.8,0.4l-19.8,15.6L116.3,19.7z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
        </G>
        <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" x1="112.1" x2="112.1" y1="88.3" y2="102.8" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="112.1" x2="112.1" y1="25.9" y2="87.7" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="165.4" x2="165.4" y1="83.2" y2="102.9" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="165.4" x2="165.4" y1="26.3" y2="81.6" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="183.2" x2="183.2" y1="63.3" y2="102.9" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="183.2" x2="183.2" y1="29.8" y2="62.3" />
        <Path className="st1" d="M187.7,27.7L187.7,27.7c0.6,0,1.1,0.4,1.1,1v0c0,0.5-0.5,1-1.1,1h-1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="119" x2="119" y1="50.1" y2="34.3" />
        <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="132.5" x2="132.5" y1="34.3" y2="50.1" />
        <Polyline className="st5" fill="none" points="119,34.3 118.1,34.3 118.1,32.6 133.5,32.6 133.5,34.3 132.5,34.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st5" fill="none" points="129.8,52 133.5,52 133.5,50.3 132.5,50.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st5" fill="none" points="119,50.3 118.1,50.3 118.1,52 128.8,52 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Rect className="st10" fill={svgHighlight} height="6.9" width="8.2" x="121.7" y="42.2" />
        <Rect className="st5" fill="none" height="13.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="8.3" x="121.7" y="35.7" />
        <Line className="st11" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3" x1="121.7" x2="130" y1="42.2" y2="42.2" />
        <Line className="st11" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3" x1="125.9" x2="125.9" y1="35.7" y2="42.1" />
        <Rect className="st6" fill="#E6EBEF" height="13.5" width="8.2" x="145.6" y="35.5" />
        <Rect className="st6" fill="#E6EBEF" height="14.7" width="8.8" x="121.3" y="74.4" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="144.8,45.9 149.1,35.5 145.3,35.5 144.8,36.6 	" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="150.1,49 147.7,49 153.3,35.5 155.6,35.5 	" />
        <Rect className="st6" fill="#E6EBEF" height="11" width="7.6" x="146" y="80.4" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="146,88.9 149.5,80.4 147.2,80.4 146,83.2 	" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="149.7,91.4 147.7,91.4 152.2,80.4 154.2,80.4 	" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="120.8,86.9 126.2,73.8 122.2,73.8 120.8,77.3 	" />
        <Polygon className="st8" fill="#FFFFFF" opacity="0.7" points="125.9,91.1 123.4,91.1 130.5,73.8 132.9,73.8 	" />
        <Rect className="st5" fill="none" height="13.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="8.3" x="145.5" y="35.7" />
        <Line className="st11" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3" x1="145.5" x2="153.8" y1="42.2" y2="42.2" />
        <Line className="st11" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.3" x1="149.7" x2="149.7" y1="35.7" y2="42.1" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="119" x2="119" y1="91.6" y2="73.3" />
        <Line className="st12" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" x1="132.5" x2="132.5" y1="73.3" y2="91.6" />
        <Polyline className="st5" fill="none" points="119,73.3 118.1,73.3 118.1,71.6 132.1,71.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="141.8" x2="141.8" y1="92" y2="73.3" />
        <Line className="st12" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" x1="141.8" x2="141.8" y1="102.7" y2="93.3" />
        <Line className="st12" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" x1="156.9" x2="156.9" y1="73.3" y2="102.7" />
        <Polyline className="st5" fill="none" points="141.8,73.3 140.9,73.3 140.9,71.6 157.9,71.6 157.9,73.3 156.9,73.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st4" fill="none" points="119,91.7 118.4,91.7 118.4,93.4 133.5,93.4 133.5,91.7 132.5,91.7 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
        <Rect className="st5" fill="none" height="14.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="8.7" x="121.4" y="74.5" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="125.9" y="88.8" />
        <Rect className="st13" fill="#FFFFFF" height="13.9" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.551" width="1.4" x="161.6" y="88.8" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="129.6" y="88.8" />
        <Rect className="st5" fill="none" height="29" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="11.7" x="143.9" y="73.8" />
        <Line className="st14" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.25" x1="121.4" x2="130.1" y1="82.2" y2="82.2" />
        <Line className="st14" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.25" x1="125.7" x2="125.7" y1="74.5" y2="82.2" />
        <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="142.8" x2="142.8" y1="50.1" y2="34.3" />
        <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="156.3" x2="156.3" y1="34.3" y2="50.1" />
        <Polyline className="st9" fill="#FFFFFF" points="152.6,32.6 157.3,32.6 157.3,34.3 156.3,34.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="142.8,34.3 141.9,34.3 141.9,32.6 151.4,32.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="142.8,50.3 141.9,50.3 141.9,52 157.3,52 157.3,50.3 156.3,50.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polygon className="st15" fill="#FFFFFF" points="182.9,102.8 98.1,102.8 98.1,113.9 183.2,113.9 183.2,102.8 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Rect className="st16" fill="none" height="2.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.35" width="8.2" x="145.6" y="75.4" />
        <Rect className="st16" fill="none" height="11" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.35" width="7.6" x="146" y="80.4" />
        <Polygon className="st15" fill="#FFFFFF" points="101.3,95.2 101.3,67.1 104.7,67.1 104.7,101.6 101.3,101.6 101.3,96.5 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Polyline className="st15" fill="#FFFFFF" points="99.6,64.2 99.6,65.9 154.4,65.9 154.4,63.8 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <G>
          <Polyline className="st7" fill="#BAC2CA" points="165.6,57.6 152,62.6 98.5,62.5 111.7,57.6 		" />
          <Polyline className="st9" fill="#FFFFFF" points="98.2,62.5 98.7,62.5 151.9,62.6 		" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path className="st1" d="M99.7,64.2h-1.5c-0.5,0-0.9-0.4-0.9-0.8v0c0-0.5,0.4-0.8,0.9-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="129.2" x2="99.7" y1="64.2" y2="64.2" />
          <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="152.3" x2="130.5" y1="64.2" y2="64.2" />
          <Path className="st2" d="M153.1,62l1.5,1c0,0-1.3,1-1.9,1.1c-0.6,0-1.3-0.4-0.7-1.3C152.3,62.2,153.1,62,153.1,62z" fill="#FFFFFF" />
          <Polyline className="st4" fill="none" points="164.3,60.1 165.4,59.7 165.4,57.5 		" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
          <Path
            className="st5"
            d="M165.4,57.5l-13.1,5c-0.7,0.2-0.7,1.2-0.3,1.5c0.2,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3,0
			c0.4,0,0.8-0.2,1.4-0.4l11.2-4.1"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <Polyline className="st1" fill="none" points="98,62.5 111.7,57.5 165.3,57.5 		" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <Polyline className="st17" fill="#FFFFFF" points="100.7,66.2 100.7,67.3 105.2,67.3 105.2,66.2 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4992" />
        <Rect className="st9" fill="#FFFFFF" height="34.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="3.3" x="132.3" y="67" />
        <Line className="st15" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="154.4" x2="165.3" y1="65.9" y2="65.9" />
        <Polyline className="st9" fill="#FFFFFF" points="100.1,102.5 100.1,101.4 105.9,101.4 105.9,102.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="130.8,102.4 130.8,101.4 136.7,101.4 136.7,102.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="158.9,77.2 158.9,101.6 155.6,101.6 155.6,67 158.9,67 158.9,76.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="154.1,102.4 154.1,101.4 160,101.4 160,102.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="128.2,110.8 128.2,102.5 135.6,96.2 135.6,94.4 127.1,101.8 127.1,111.1 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <G>
          <G>
            <Polyline
              className="st9"
              fill="#FFFFFF"
              points="134.9,105.5 132.4,105.5 132.4,108.3 129.5,108.3 129.5,110.9 127.1,110.9 127.1,113.8
				130.4,113.8 130.8,113.8 151.9,113.8 151.9,108.6 154.4,108.6 154.4,105.5 151.4,105.5 			"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="131" x2="144.4" y1="110.9" y2="110.9" />
            <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="132" x2="149.2" y1="108.3" y2="108.3" />
            <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="151.8" x2="136.6" y1="105.5" y2="105.5" />
          </G>
        </G>
        <Polyline className="st9" fill="#FFFFFF" points="148,110.8 148,102.5 155.6,96 155.6,94.2 146.8,101.8 146.8,111.1 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polygon
          className="st3"
          fill="#E6EBEF"
          points="154.1,102.9 154.1,105.5 151.5,105.5 151.5,108.3 149.2,108.3 149.2,110.9 146.8,110.9 146.8,113.9
		157.1,113.9 157.1,102.9 	"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Line className="st9" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="134.9" x2="134.9" y1="102.9" y2="105.4" />
        <Rect className="st9" fill="#FFFFFF" height="1.4" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="6.5" x="158.9" y="87.4" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="107.2" y="88.8" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="111" y="88.8" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="114.7" y="88.8" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="118.4" y="88.8" />
        <Rect className="st9" fill="#FFFFFF" height="13.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" width="1.1" x="122.1" y="88.8" />
        <G>
          <Path
            className="st2"
            d="M145.8,97.5c0.5,1.6-0.6,2.1-0.6,2.1l0,0c-0.3-1.1-1.1-2.1-2.2-2.6l-0.1,0c0-0.1,0-0.1,0-0.2
			c0-0.2,0.1-0.4,0-0.6l0.1,0.1c0,0,0.8,0.1,1-0.5c0.2-0.6-0.1-1.4-1.1-2.3c-1.1-1-1.8-0.6-1.8-0.6s-0.3-0.2-0.7-0.2
			s-0.6,0.2-0.6,0.2l0,0l0,0c0,0-0.7-0.3-1.8,0.6c-1.1,1-1.4,1.7-1.1,2.3c0.2,0.6,1,0.5,1,0.5l0.1-0.2c0,0.2,0,0.5,0,0.7
			c0.1,0.3,0.2,0.6,0.4,0.8l0,0c0,0-0.3,0.4-0.3,0.9c0,0.4,0,2,0,3.1c0,0.5,0.4,1,1,1h0.1c0.5,0,1-0.4,1-1v-1.2l0-0.1
			c0.2,0,0.4,0.1,0.7,0l0,0.1l0,0v1.2c0,0.5,0.4,1,1,1h0.1c0.5,0,1-0.4,1-1v0h0.5c0,0.1,0,0.1,0,0.2c0,0.4,0.3,0.7,0.7,0.7h0.5
			c0.3,0,0.6-0.3,0.6-0.6l0-1.6l0,0.3c0.8-0.5,1-1.2,1-1.2C146.8,98.3,145.8,97.5,145.8,97.5z"
            fill="#FFFFFF"
          />
          <Path
            className="st10"
            d="M138.2,98.6c-0.4,0.1-0.6,0.6-0.9,0.9c-0.2,0.2-0.6,0.4-0.6,0.4l-0.3-0.6c0,0,0.4-0.1,0.6-0.3
			c0.1-0.2,0.4-0.7,0.8-0.8"
            fill={svgHighlight}
          />
          <Path
            className="st10"
            d="M138.3,98.5c0,0-0.9-0.5-1.5-0.1c-0.5,0.4-1.1,0.6-1.8,0.2l0.1-0.9c0.7,0.4,1.3,0.2,1.8-0.2
			c0.6-0.4,1.5,0.1,1.5,0.1"
            fill={svgHighlight}
          />
          <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" x1="134.7" x2="135.2" y1="97.5" y2="97.8" />
          <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" x1="134.6" x2="135" y1="98" y2="98.2" />
          <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" x1="134.5" x2="135" y1="98.4" y2="98.6" />
          <Path
            className="st10"
            d="M138.3,98.4c0,0,0.7,0.9,2.1,1c1.4,0.1,3.1-0.7,2.9-2.2l-0.2,0c0,0-1.1,1.3-2.1,1.4
			c-1.4,0.2-2.6-0.9-2.6-0.9L138.3,98.4z"
            fill={svgHighlight}
          />
          <Path className="st19" d="M138.3,98.4c0,0,0.7,0.9,2.1,1c1.4,0.1,3.1-0.7,2.9-2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" />
          <Path
            className="st19"
            d="M138.3,98.5c0,0-0.9-0.5-1.5-0.1c-0.5,0.4-1.1,0.6-1.8,0.2l0.1-0.9c0.7,0.4,1.3,0.2,1.8-0.2
			c0.6-0.4,1.5,0.1,1.5,0.1"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.1997"
          />
          <G>
            <Path className="st5" d="M138.5,97.8c0,0-0.2,0.2-0.2,0.8c0,0.4,0,2,0,3.1c0,0.5,0.4,1,1,1h0.1c0.5,0,1-0.4,1-1v-1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
            <Path className="st4" d="M139.6,100.2c0,0,1.1,0.8,2.1,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
            <Path className="st4" d="M141,100.6v1.2c0,0.5,0.4,1,1,1h0.1c0.5,0,1-0.4,1-1v-2.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
            <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" x1="143.1" x2="143.6" y1="101.8" y2="101.8" />
            <Path
              className="st5"
              d="M143.1,97L143.1,97c1.3,0.6,2.2,1.9,2.3,3.4l0,1.6c0,0.3-0.3,0.6-0.6,0.6h-0.5c-0.4,0-0.7-0.3-0.7-0.7l0,0
				c0-0.4,0.3-0.7,0.7-0.7h0.1"
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="0.5"
            />
            <Path className="st5" d="M145.2,99.6c0,0,1.1-0.5,0.6-2.1c0,0,1,0.8,0.6,2c0,0-0.2,0.7-1,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
            <Path className="st19" d="M139.7,97c0,0,0.9,1,2.1,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" />
            <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="140.7" x2="140.7" y1="97.4" y2="96.6" />
            <Circle cx="141.6" cy="95.1" r="0.2" />
            <Circle cx="139.8" cy="95.1" r="0.2" />
            <Ellipse cx="140.7" cy="95.9" rx="0.8" ry="0.4" />
            <Path d="M140.1,95.9l-0.1,0c0,0,0.1,0.6,0.7,0.7l0,0l0.1-0.6L140.1,95.9z" />
            <Path d="M141.3,95.9l0.1,0c0,0-0.1,0.6-0.7,0.7l0,0l-0.1-0.6L141.3,95.9z" />
            <Path className="st5" d="M139.9,92.9c0,0-0.7-0.3-1.8,0.6s-1.4,1.7-1.1,2.3c0.2,0.6,1,0.5,1,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
            <Path className="st4" d="M139.2,93.7c0,0-1.3,1.5-1,3.2c0.3,1.7,2.4,1.7,2.4,1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
            <Path className="st5" d="M140,92.9c0,0,0.2-0.2,0.6-0.2s0.7,0.2,0.7,0.2s0.7-0.3,1.8,0.6s1.4,1.7,1.1,2.3c-0.2,0.6-1,0.5-1,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
            <Path className="st4" d="M142,93.7c0,0,1.3,1.5,1,3.2s-2.4,1.7-2.4,1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2996" />
          </G>
        </G>
        <G>
          <Path
            className="st2"
            d="M125.2,46.1c0,0-0.5,0-1.5,0.6s-1.5,1.8-0.9,2.7s1.7,0.3,1.7,0.3l2.7,0c0,0,1.2-0.2,1.2-1.2
			c0-1-0.4-2.3-0.4-2.3L125.2,46.1z"
            fill="#FFFFFF"
          />
          <Path
            className="st9"
            d="M128.7,45.4c0.3-1.2,0.1-1.6,0.1-1.6c-0.8,0-1.3,0.5-1.3,0.5c-0.6-0.2-1.2,0-1.2,0s-0.5-0.5-1.3-0.5
			c0,0-0.3,0.4,0.1,1.6c0,0-0.5,1.2,0.7,1.6c1.2,0.4,1.9,0.2,2.4,0c0.5-0.2,0.9-0.8,0.7-1.4L128.7,45.4z"
            fill="#FFFFFF"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <Path className="st19" d="M125,43.9c0,0,0.5,0.4,0.7,0.8c0,0-0.5,0.3-0.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" />
          <Path className="st19" d="M128.7,43.9c0,0-0.5,0.4-0.7,0.8c0,0,0.5,0.3,0.7,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" />
          <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="126.7" x2="127" y1="46.3" y2="46.3" />
          <Path className="st18" d="M126.4,46.5c0,0,0,0.2,0.3,0.2c0.2,0,0.2-0.4,0.2-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" />
          <Path className="st18" d="M127.3,46.5c0,0,0,0.2-0.3,0.2c-0.2,0-0.2-0.4-0.2-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" />
          <Circle cx="125.9" cy="45.9" r="0.1" />
          <Circle cx="127.8" cy="45.9" r="0.1" />
          <Path
            className="st5"
            d="M123,48.5c0,0,0,0.4,0.4,0.6c0.4,0.1,0.9,0.1,1.4-0.4c0.1-0.1,0.3-0.1,0.4,0l0,0c0.1,0.1,0.1,0.3,0,0.4
			l-0.1,0.1c-0.4,0.4-0.8,0.5-1.2,0.5c-0.8,0-1.6-0.4-1.3-1.6c0.5-1.7,2.3-2,2.3-2"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.5"
          />
          <Path className="st20" d="M127.3,49.7c0.3-0.1,0.5-0.3,0.5-0.6l0,0c0-0.3-0.3-0.6-0.6-0.6c-0.3,0-0.7,0-0.9,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.2596" />
          <Path className="st5" d="M124.5,49.7c0,0,1.2,0,2.1,0c0.2,0,0.4,0,0.6,0c0,0,0.1,0,0.1,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path className="st5" d="M128.2,47c0,0,0.6,2.4-0.8,2.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        </G>
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="148.4" x2="148.4" y1="81" y2="90.7" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="151.1" x2="151.1" y1="81" y2="90.7" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="146.4" x2="153.1" y1="85.8" y2="85.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="148.1" x2="148.1" y1="75.7" y2="77.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="150.8" x2="150.8" y1="75.7" y2="77.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="165.3" x2="173.4" y1="32.3" y2="32.3" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="169" x2="183.1" y1="35.4" y2="35.4" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="175.2" x2="183.1" y1="38.5" y2="38.5" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="167.1" x2="172.7" y1="38.5" y2="38.5" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="164.2" x2="181.6" y1="41.7" y2="41.7" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="169" x2="180.2" y1="63.4" y2="63.4" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="175.2" x2="183.1" y1="66.5" y2="66.5" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="167.1" x2="172.7" y1="66.5" y2="66.5" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="168.6" x2="179" y1="69.6" y2="69.6" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="183.1" x2="171.7" y1="44.8" y2="44.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="162.7" x2="178.4" y1="47.7" y2="47.7" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="169" x2="183.1" y1="50.8" y2="50.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="175.2" x2="183.1" y1="54" y2="54" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="167.1" x2="172.7" y1="54" y2="54" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="170.2" x2="183.1" y1="57.1" y2="57.1" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="183.2" x2="176.3" y1="60.2" y2="60.2" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="164.2" x2="181.6" y1="73.2" y2="73.2" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="167.9" x2="170.1" y1="94.9" y2="94.9" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="165.3" x2="167.9" y1="98" y2="98" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="183.1" x2="171.7" y1="76.3" y2="76.3" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="162.7" x2="178.4" y1="79.2" y2="79.2" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="169" x2="183.1" y1="82.3" y2="82.3" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="179.9" x2="183.1" y1="85.5" y2="85.5" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="167.1" x2="172.7" y1="85.5" y2="85.5" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="169.6" x2="183.1" y1="88.6" y2="88.6" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="173.8" x2="165.6" y1="91.7" y2="91.7" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="134.2" x2="141.9" y1="8.7" y2="8.7" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="130.1" x2="141.2" y1="11.6" y2="11.6" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="131.6" x2="150.1" y1="14.6" y2="14.6" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="131.6" x2="150.1" y1="25" y2="25" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="126.2" x2="128.7" y1="25" y2="25" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="119.7" x2="144.1" y1="21.3" y2="21.3" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="147.1" x2="152" y1="21.3" y2="21.3" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="122.2" x2="128.5" y1="17.8" y2="17.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="139.8" x2="154.1" y1="17.8" y2="17.8" />
        <Line className="st19" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1997" x1="162.6" x2="161" y1="41.7" y2="41.7" />
        <Path
          className="st19"
          d="M138.2,98.7c-0.4,0.1-0.5,0.6-0.8,0.9c-0.2,0.2-0.6,0.4-0.6,0.4l-0.4-0.7c0,0,0.4-0.1,0.6-0.3
		c0.1-0.2,0.2-0.6,0.7-0.7"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.1997"
        />
        <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" x1="136.1" x2="136.5" y1="99.4" y2="99.3" />
        <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" x1="136.2" x2="136.6" y1="99.7" y2="99.6" />
        <Line className="st18" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.1797" x1="136.5" x2="136.8" y1="100.1" y2="100" />
        <Rect className="st2" fill="#FFFFFF" height="1.1" width="1.7" x="111.3" y="62.8" />
        <Polyline className="st9" fill="#FFFFFF" points="154.9,66.2 154.9,67.2 159.5,67.2 159.5,66.2 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polyline className="st9" fill="#FFFFFF" points="131.7,66.3 131.7,67.3 136.2,67.3 136.2,66.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Rect className="st17" fill="#FFFFFF" height="1.4" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.4992" width="27.6" x="104.7" y="87.8" />
        <Line className="st15" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="80.5" x2="15.8" y1="112.7" y2="112.7" />
        <Line className="st15" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="279.6" x2="183.8" y1="112.7" y2="112.7" />
        <G>
          <Path
            className="st6"
            d="M262.1,98.2c0,0-1.1,0.7-2.3-0.1s-1.3-2.1-1.3-2.1l0.1-0.2c0,0,1.3-0.5,2.5,0.2c1.2,0.7,1.1,2,1.1,2
			L262.1,98.2z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M258.5,95.9c0,0,0.1,1.4,1.3,2.1c1.2,0.7,2.3,0.1,2.3,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M258.6,95.8c0,0,1.3-0.5,2.5,0.2s1.1,2,1.1,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M245.4,42.1c0,0,0.2,1.3-0.9,2.2c-1.1,0.9-2.5,0.5-2.5,0.5l-0.1-0.1c0,0-0.1-1.4,1-2.3
			c1.1-0.9,2.3-0.4,2.3-0.4L245.4,42.1z"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M242,44.7c0,0,1.4,0.4,2.5-0.5c1.1-0.9,0.9-2.2,0.9-2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M241.9,44.6c0,0-0.1-1.4,1-2.3c1.1-0.9,2.3-0.4,2.3-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M253.4,101.4c0,0,0.2,1.3-0.9,2.2c-1.1,0.9-2.5,0.5-2.5,0.5l-0.1-0.1c0,0-0.1-1.4,1-2.3s2.3-0.4,2.3-0.4
			L253.4,101.4z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M250,104c0,0,1.4,0.4,2.5-0.5s0.9-2.2,0.9-2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M249.9,103.9c0,0-0.1-1.4,1-2.3s2.3-0.4,2.3-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M217.5,55.2c0,0,1.3-0.4,2.2,0.7s0.7,2.4,0.7,2.4l-0.1,0.1c0,0-1.4,0.2-2.4-0.8c-1-1-0.6-2.3-0.6-2.3
			L217.5,55.2z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M220.4,58.3c0,0,0.3-1.4-0.7-2.4s-2.2-0.7-2.2-0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M220.3,58.4c0,0-1.4,0.2-2.4-0.8s-0.6-2.3-0.6-2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M230,39.3c0,0-1.5,0.9-3.1-0.1c-1.6-1-1.8-2.9-1.8-2.9l0.1-0.2c0,0,1.8-0.7,3.4,0.2s1.5,2.8,1.5,2.8L230,39.3
			z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M225.2,36.3c0,0,0.1,1.9,1.8,2.9s3.1,0.1,3.1,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M225.3,36.1c0,0,1.8-0.7,3.4,0.2s1.5,2.8,1.5,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M236.2,114.1c0,0-1.1,0.7-2.3-0.1c-1.2-0.7-1.3-2.1-1.3-2.1l0.1-0.2c0,0,1.3-0.5,2.5,0.2c1.2,0.7,1.1,2,1.1,2
			L236.2,114.1z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M232.6,111.8c0,0,0.1,1.4,1.3,2.1c1.2,0.7,2.3,0.1,2.3,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M232.7,111.7c0,0,1.3-0.5,2.5,0.2c1.2,0.7,1.1,2,1.1,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M244.5,116.4c0,0-0.4-1.2,0.6-2.2s2.4-0.8,2.4-0.8l0.1,0.1c0,0,0.3,1.4-0.7,2.4s-2.2,0.7-2.2,0.7L244.5,116.4
			z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M247.5,113.4c0,0-1.4-0.2-2.4,0.8c-1,1-0.6,2.2-0.6,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M247.6,113.5c0,0,0.3,1.4-0.7,2.4s-2.2,0.7-2.2,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M241.1,112.3c0,0-0.9,0.9-2.2,0.6c-1.3-0.4-1.8-1.7-1.8-1.7l0-0.2c0,0,1.1-0.9,2.4-0.5
			c1.3,0.4,1.6,1.6,1.6,1.6L241.1,112.3z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M237.1,111.1c0,0,0.5,1.3,1.8,1.7s2.2-0.6,2.2-0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M237.1,111c0,0,1.1-0.9,2.4-0.5s1.6,1.6,1.6,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M247.3,113c0,0-1.1,1-2.6,0.4s-1.9-2.1-1.9-2.1l0.1-0.2c0,0,1.3-0.9,2.8-0.3c1.5,0.5,1.7,2,1.7,2L247.3,113z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M242.8,111.3c0,0,0.4,1.5,1.9,2.1c1.5,0.5,2.6-0.4,2.6-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M242.9,111.1c0,0,1.3-0.9,2.8-0.3c1.5,0.5,1.7,2,1.7,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M240.1,113.7c0,0,0.1-1.3,1.4-1.8s2.5,0.2,2.5,0.2l0.1,0.2c0,0-0.3,1.4-1.6,1.9c-1.3,0.5-2.3-0.3-2.3-0.3
			L240.1,113.7z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M244.1,112.2c0,0-1.2-0.7-2.5-0.2c-1.3,0.5-1.4,1.8-1.4,1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M244.1,112.3c0,0-0.3,1.4-1.6,1.9c-1.3,0.5-2.3-0.3-2.3-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M229.7,112.6c0,0-0.4,1.4-1.9,1.8c-1.5,0.4-2.7-0.7-2.7-0.7l0-0.2c0,0,0.6-1.5,2.1-1.8
			c1.5-0.4,2.5,0.7,2.5,0.7L229.7,112.6z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M225.1,113.7c0,0,1.2,1,2.7,0.7c1.5-0.4,1.9-1.8,1.9-1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M225,113.5c0,0,0.6-1.5,2.1-1.8c1.5-0.4,2.5,0.7,2.5,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M254.1,115.8c0,0-0.8,1-2.2,0.8c-1.4-0.2-2-1.5-2-1.5l0-0.2c0,0,1-1,2.4-0.8c1.4,0.2,1.8,1.4,1.8,1.4
			L254.1,115.8z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M250,115.1c0,0,0.6,1.3,2,1.5c1.4,0.2,2.2-0.8,2.2-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M250,114.9c0,0,1-1,2.4-0.8c1.4,0.2,1.8,1.4,1.8,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M219.1,81.8c0,0-0.3-1.6,1.1-2.8c1.4-1.1,3.1-0.6,3.1-0.6l0.1,0.2c0,0,0.1,1.8-1.3,2.9
			c-1.4,1.1-2.9,0.5-2.9,0.5L219.1,81.8z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M223.4,78.4c0,0-1.7-0.5-3.1,0.6c-1.4,1.1-1.1,2.8-1.1,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M223.5,78.6c0,0,0.1,1.8-1.3,2.9c-1.4,1.1-2.9,0.5-2.9,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M223.3,101.5c0,0-0.7-1.5,0.3-3c1-1.5,2.8-1.5,2.8-1.5l0.2,0.1c0,0,0.6,1.7-0.4,3.2s-2.7,1.3-2.7,1.3
			L223.3,101.5z"
            fill="#D8DFE5"
          />
          <Path className="st21" d="M226.5,97c0,0-1.8,0-2.8,1.5s-0.3,3-0.3,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M226.7,97.2c0,0,0.6,1.7-0.4,3.2c-1,1.5-2.7,1.3-2.7,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st2" d="M214.5,90.1c0,0-1.7,0-2.5-1.6s0-3.2,0-3.2l0.2-0.1c0,0,1.8,0.3,2.6,1.9c0.8,1.6-0.1,3-0.1,3L214.5,90.1z" fill="#FFFFFF" />
          <Path className="st21" d="M212.2,85.2c0,0-0.8,1.6,0,3.2s2.5,1.6,2.5,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M212.4,85.1c0,0,1.8,0.3,2.6,1.9s-0.1,3-0.1,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M71.5,36c0,0-1.7,0-2.5-1.6c-0.8-1.6,0-3.2,0-3.2l0.2-0.1c0,0,1.8,0.3,2.6,1.9c0.8,1.6-0.1,3-0.1,3L71.5,36z"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M69.1,31.2c0,0-0.8,1.6,0,3.2c0.8,1.6,2.5,1.6,2.5,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M69.3,31.1c0,0,1.8,0.3,2.6,1.9c0.8,1.6-0.1,3-0.1,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M256.3,46.2c0,0,0.2-1.7,1.8-2.3c1.6-0.7,3.2,0.3,3.2,0.3l0.1,0.2c0,0-0.4,1.8-2.1,2.4
			c-1.6,0.7-2.9-0.4-2.9-0.4L256.3,46.2z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M261.3,44.2c0,0-1.5-0.9-3.2-0.3c-1.6,0.7-1.8,2.3-1.8,2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M261.4,44.4c0,0-0.4,1.8-2.1,2.4c-1.6,0.7-2.9-0.4-2.9-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M267.2,84.9c0,0,0.8-1.5,2.6-1.4s2.8,1.5,2.8,1.5l0,0.2c0,0-1.1,1.4-2.9,1.4c-1.8,0-2.6-1.5-2.6-1.5
			L267.2,84.9z"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M272.6,85.1c0,0-1-1.5-2.8-1.5s-2.6,1.4-2.6,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M272.6,85.3c0,0-1.1,1.4-2.9,1.4s-2.6-1.5-2.6-1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M253.9,71.9c0,0,1.6,0.6,2.9-0.6c1.3-1.2,1.2-3,1.2-3l-0.1-0.2c0,0-1.8-0.4-3.1,0.7s-1,2.8-1,2.8L253.9,71.9z
			"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M257.9,68.4c0,0,0.2,1.8-1.2,3s-2.9,0.6-2.9,0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M257.8,68.2c0,0-1.8-0.4-3.1,0.7c-1.3,1.2-1,2.8-1,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M69.4,98.2c0,0-1.1,0.7-2.3-0.1c-1.2-0.7-1.3-2.1-1.3-2.1l0.1-0.2c0,0,1.3-0.5,2.5,0.2c1.2,0.7,1.1,2,1.1,2
			L69.4,98.2z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M65.8,95.9c0,0,0.1,1.4,1.3,2.1c1.2,0.7,2.3,0.1,2.3,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M65.9,95.8c0,0,1.3-0.5,2.5,0.2s1.1,2,1.1,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M60.7,101.4c0,0,0.2,1.3-0.9,2.2c-1.1,0.9-2.5,0.5-2.5,0.5l-0.1-0.1c0,0-0.1-1.4,1-2.3
			c1.1-0.9,2.3-0.4,2.3-0.4L60.7,101.4z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M57.3,104c0,0,1.4,0.4,2.5-0.5c1.1-0.9,0.9-2.2,0.9-2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M57.2,103.9c0,0-0.1-1.4,1-2.3c1.1-0.9,2.3-0.4,2.3-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M30.7,101.5c0,0-0.7-1.5,0.3-3s2.8-1.5,2.8-1.5l0.2,0.1c0,0,0.6,1.7-0.4,3.2s-2.7,1.3-2.7,1.3L30.7,101.5z" fill="#D8DFE5" />
          <Path className="st21" d="M33.8,97c0,0-1.8,0-2.8,1.5s-0.3,3-0.3,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M34,97.2c0,0,0.6,1.7-0.4,3.2s-2.7,1.3-2.7,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M21.9,90.1c0,0-1.7,0-2.5-1.6c-0.8-1.6,0-3.2,0-3.2l0.2-0.1c0,0,1.8,0.3,2.6,1.9c0.8,1.6-0.1,3-0.1,3
			L21.9,90.1z"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M19.5,85.2c0,0-0.8,1.6,0,3.2c0.8,1.6,2.5,1.6,2.5,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M19.7,85.1c0,0,1.8,0.3,2.6,1.9c0.8,1.6-0.1,3-0.1,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M236.8,115c0,0,0.9-1.3,2.5-1.1c1.7,0.2,2.5,1.6,2.5,1.6l0,0.2c0,0-1.1,1.3-2.8,1.1c-1.7-0.2-2.3-1.6-2.3-1.6
			L236.8,115z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M241.8,115.6c0,0-0.8-1.5-2.5-1.6s-2.5,1.1-2.5,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M241.8,115.8c0,0-1.1,1.3-2.8,1.1c-1.7-0.2-2.3-1.6-2.3-1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M256.7,112.1c0,0,0.9-1.3,2.5-1.1c1.7,0.2,2.5,1.6,2.5,1.6l0,0.2c0,0-1.1,1.3-2.8,1.1
			c-1.7-0.2-2.3-1.6-2.3-1.6L256.7,112.1z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M261.8,112.7c0,0-0.8-1.5-2.5-1.6c-1.7-0.2-2.5,1.1-2.5,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M261.7,112.9c0,0-1.1,1.3-2.8,1.1c-1.7-0.2-2.3-1.6-2.3-1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <Path className="st21" d="M244,88.3c-0.8-2.7-3.3-4.6-6.3-4.6c-1.4,0-2.8,0.5-3.8,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st21" d="M249.7,88.5c-0.4-0.8-1.1-1.5-2-2c-1.4-0.7-2.9-0.6-4.2,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path
          className="st6"
          d="M227.8,19.9c0.9,0,1.4-1.1,0.8-1.8c-1-1.2-3-2-5.3-2c-0.4,0-0.7,0-1.1,0.1c-0.4,0-0.7-0.3-0.7-0.7l0,0
		c0-2-2.6-3.7-5.7-3.7c-0.9,0-1.7,0.1-2.5,0.4c-0.3,0.1-0.7-0.1-0.8-0.5c-0.7-3-3.9-5.3-7.8-5.3c-4.4,0-7.9,2.9-7.9,6.4
		c0,0.1,0,0.3,0,0.4c-0.2,0-0.4,0-0.6,0c-1.6,0-3,1.1-3.5,2.5c-0.1,0.3-0.5,0.5-0.8,0.4c-0.3-0.1-0.7-0.1-1-0.1
		c-1.2,0-2.2,0.5-2.9,1.4c-0.8,1-0.1,2.4,1.2,2.4H227.8z"
          fill="#E6EBEF"
        />
        <Path
          className="st6"
          d="M96.2,22.8c1.7,0,2.9-1.7,2.3-3.4c-1.4-3.4-4.3-8.2-9.8-8.8c-2.3-0.2-4.5,0.7-6.2,1.8c-0.2,0.1-0.3,0.1-0.5,0
		c-1.2-1.1-2.9-1.8-4.7-1.8c-3.5,0-6.4,2.4-6.4,5.4l0,0c0,0.2-0.2,0.4-0.4,0.4c-0.2,0-0.3,0-0.5,0c-2.2,0-4,1.2-4.7,2.8
		c-0.1,0.2-0.3,0.3-0.5,0.2c-0.6-0.3-1.2-0.4-1.9-0.4c-1.6,0-2.6,0.9-3.3,1.8c-0.6,0.8,0,1.9,1,1.9L96.2,22.8z"
          fill="#E6EBEF"
        />
        <Path
          className="st0"
          d="M85.8,73.6c0-6.9-4.6-12.8-10.9-14.7c-0.7-0.2-1-1-0.6-1.6c1.1-1.8,1.7-3.9,1.7-6.1c0-6.4-5.2-11.6-11.6-11.6
		c-0.1,0-0.1,0-0.2,0c-0.7-5.5-5.4-9.7-11.1-9.7c-5.7,0-10.4,4.2-11.1,9.7c-0.2,0-0.4,0-0.6,0c-6.4,0-11.6,5.2-11.6,11.6
		c0,2.4,0.7,4.5,1.9,6.4c0.4,0.6,0.1,1.4-0.5,1.6c-5.9,2.1-10.2,7.8-10.2,14.4c0,6.5,4,12,9.7,14.3l0,0c0,0,6.1,2.7,18.8,2.8
		c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.5,0,3.7-0.1c12.8,0,19-2.8,19-2.8l0,0C81.8,85.6,85.8,80.1,85.8,73.6z"
          fill="#D8DFE5"
        />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="53.5" x2="53.5" y1="91.2" y2="112.6" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="53.5" x2="53.5" y1="50.7" y2="81.3" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="41.5" x2="53.3" y1="62.3" y2="69.9" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="59.4" x2="53.5" y1="57.4" y2="61.3" />
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="65.3" x2="53.7" y1="69.4" y2="76.3" />
        <Path
          className="st1"
          d="M85.8,73.6c0-6.9-4.6-12.8-10.9-14.7c-0.7-0.2-1-1-0.6-1.6c1.1-1.8,1.7-3.9,1.7-6.1c0-6.4-5.2-11.6-11.6-11.6
		c-0.1,0-0.1,0-0.2,0c-0.7-5.5-5.4-9.7-11.1-9.7c-5.7,0-10.4,4.2-11.1,9.7c-0.2,0-0.4,0-0.6,0c-6.4,0-11.6,5.2-11.6,11.6
		c0,2.4,0.7,4.5,1.9,6.4c0.4,0.6,0.1,1.4-0.5,1.6c-5.9,2.1-10.2,7.8-10.2,14.4c0,6.5,4,12,9.7,14.3l0,0c0,0,6.1,2.7,18.8,2.8
		c1.3,0,2.6,0.1,3.9,0.1c1.2,0,2.5,0,3.7-0.1c12.8,0,19-2.8,19-2.8l0,0C81.8,85.6,85.8,80.1,85.8,73.6z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          className="st2"
          d="M210.8,106.9c0,0,0-0.1,0-0.1c0-3-2.4-5.4-5.3-5.4c-0.6,0-1.3,0.1-1.8,0.3c0-0.2,0-0.3,0-0.5
		c0-2-1.6-3.6-3.6-3.6c-1.3,0-2.4,0.7-3,1.7c-1-0.9-2.4-1.5-3.9-1.5c-3.1,0-5.7,2.5-5.8,5.7c-0.5-0.2-1-0.3-1.6-0.3
		c-2.3,0-4.1,1.8-4.3,4.1c-1.3,0.5-2.1,1.8-2.1,3.3c0,2,1.6,3.6,3.5,3.6c0.2,0,0.3,0,0.5,0v0H210v0c0.2,0,0.4,0.1,0.6,0.1
		c1.9,0,3.5-1.6,3.5-3.6C214.2,108.5,212.7,107,210.8,106.9z"
          fill="#FFFFFF"
        />
        <G>
          <Path className="st6" d="M203.7,101.2C203.7,101.2,203.7,101.2,203.7,101.2c0-0.1,0-0.2,0-0.2l0,0L203.7,101.2z" fill="#E6EBEF" />
          <Path
            className="st6"
            d="M210,113.9c0.2,0,0.4,0.1,0.6,0.1c1.9,0,3.5-1.6,3.5-3.6c0-1.9-1.5-3.5-3.3-3.6c0,0,0-0.1,0-0.1
			c0-3-2.4-5.4-5.3-5.4c-0.6,0-1.2,0.1-1.7,0.3l0,0.2c0,0,1.2,0.9,1.5,1.3c0,0,2.2-0.4,3.6,1.4c1.5,1.8,1.2,3.1,1.2,3.1
			s-1.3,0-2.2,0.8c0,0,2.1-0.4,3.2,0.7c1.1,1.1,0.6,4.5-2.9,4.7l0,0.1L210,113.9L210,113.9z"
            fill="#E6EBEF"
          />
        </G>
        <Path
          className="st6"
          d="M192.6,99.4c0,0,2.7-0.3,4.7,2.2c0,0,1.4-2.7,3.7-2.2c2.3,0.4,2.6,2.4,2.6,2.4l0.1-0.1c0-0.1,0-0.3,0-0.4
		c0-2-1.6-3.6-3.6-3.6c-1.3,0-2.4,0.7-3,1.7c-1-0.9-2.4-1.5-3.9-1.5c-2.8,0-5.1,2-5.7,4.7C188.5,101.4,190.6,99.4,192.6,99.4z"
          fill="#E6EBEF"
        />
        <Path
          className="st5"
          d="M209.9,103.7c-1-1.4-2.6-2.4-4.4-2.4c-0.6,0-1.3,0.1-1.8,0.3c0-0.2,0-0.3,0-0.5c0-2-1.6-3.6-3.6-3.6
		c-1.3,0-2.4,0.7-3,1.7c-1-0.9-2.4-1.5-3.9-1.5c-3.1,0-5.7,2.5-5.8,5.7c-0.5-0.2-1-0.3-1.6-0.3c-2.3,0-4.1,1.8-4.3,4.1
		c-1.3,0.5-2.1,1.8-2.1,3.3c0,2,1.6,3.6,3.5,3.6c0.2,0,0.3,0,0.5,0v0H210v0c0.2,0,0.4,0.1,0.6,0.1c1.9,0,3.5-1.6,3.5-3.6
		c0-1.9-1.5-3.5-3.3-3.6c0,0,0-0.1,0-0.1c0-0.7-0.1-1.4-0.4-2"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Path className="st9" d="M199.8,101.6c0,0,4.5-1.5,6,2.8" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st9" d="M183.6,106.8c0,0,0.1-2.7,3-2.3" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st5" d="M212.4,111c0.1-0.2,0.1-0.5,0.1-0.7c0-1.3-1.1-2.4-2.4-2.4c-0.3,0-0.5,0-0.8,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st21" d="M51.7,84.4c0.8-2.7,3.3-4.6,6.3-4.6c1.4,0,2.8,0.5,3.8,1.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <Path className="st21" d="M45.9,84.5c0.4-0.8,1.1-1.5,2-2c1.4-0.7,2.9-0.6,4.2,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        <G>
          <Path
            className="st6"
            d="M60,114.1c0,0,1.1,0.7,2.3-0.1c1.2-0.7,1.3-2.1,1.3-2.1l-0.1-0.2c0,0-1.3-0.5-2.5,0.2c-1.2,0.7-1.1,2-1.1,2
			L60,114.1z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M63.5,111.8c0,0-0.1,1.4-1.3,2.1c-1.2,0.7-2.3,0.1-2.3,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M63.4,111.7c0,0-1.3-0.5-2.5,0.2c-1.2,0.7-1.1,2-1.1,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M51.6,116.4c0,0,0.4-1.2-0.6-2.2c-1-1-2.4-0.8-2.4-0.8l-0.1,0.1c0,0-0.3,1.4,0.7,2.4s2.2,0.7,2.2,0.7
			L51.6,116.4z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M48.6,113.4c0,0,1.4-0.2,2.4,0.8c1,1,0.6,2.2,0.6,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M48.5,113.5c0,0-0.3,1.4,0.7,2.4c1,1,2.2,0.7,2.2,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M55,112.3c0,0,0.9,0.9,2.2,0.6c1.3-0.4,1.8-1.7,1.8-1.7l0-0.2c0,0-1.1-0.9-2.4-0.5c-1.3,0.4-1.6,1.6-1.6,1.6
			L55,112.3z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M59.1,111.1c0,0-0.5,1.3-1.8,1.7s-2.2-0.6-2.2-0.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M59,111c0,0-1.1-0.9-2.4-0.5s-1.6,1.6-1.6,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M48.8,113c0,0,1.1,1,2.6,0.4c1.5-0.5,1.9-2.1,1.9-2.1l-0.1-0.2c0,0-1.3-0.9-2.8-0.3c-1.5,0.5-1.7,2-1.7,2
			L48.8,113z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M53.3,111.3c0,0-0.4,1.5-1.9,2.1s-2.6-0.4-2.6-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M53.2,111.1c0,0-1.3-0.9-2.8-0.3c-1.5,0.5-1.7,2-1.7,2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M56,113.7c0,0-0.1-1.3-1.4-1.8s-2.5,0.2-2.5,0.2l-0.1,0.2c0,0,0.3,1.4,1.6,1.9c1.3,0.5,2.3-0.3,2.3-0.3
			L56,113.7z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M52,112.2c0,0,1.2-0.7,2.5-0.2c1.3,0.5,1.4,1.8,1.4,1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M52,112.3c0,0,0.3,1.4,1.6,1.9c1.3,0.5,2.3-0.3,2.3-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M66.4,112.6c0,0,0.4,1.4,1.9,1.8c1.5,0.4,2.7-0.7,2.7-0.7l0-0.2c0,0-0.6-1.5-2.1-1.8
			c-1.5-0.4-2.5,0.7-2.5,0.7L66.4,112.6z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M71.1,113.7c0,0-1.2,1-2.7,0.7c-1.5-0.4-1.9-1.8-1.9-1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M71.1,113.5c0,0-0.6-1.5-2.1-1.8c-1.5-0.4-2.5,0.7-2.5,0.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M22.3,112.1c0,0-0.2,1.5,1.1,2.4c1.3,0.9,2.8,0.4,2.8,0.4l0.1-0.2c0,0,0-1.6-1.3-2.5
			c-1.3-0.9-2.6-0.2-2.6-0.2L22.3,112.1z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M26.2,114.8c0,0-1.5,0.5-2.8-0.4c-1.3-0.9-1.1-2.4-1.1-2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M26.4,114.6c0,0,0-1.6-1.3-2.5c-1.3-0.9-2.6-0.2-2.6-0.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st6" d="M42,115.8c0,0,0.8,1,2.2,0.8c1.4-0.2,2-1.5,2-1.5l0-0.2c0,0-1-1-2.4-0.8s-1.8,1.4-1.8,1.4L42,115.8z" fill="#E6EBEF" />
          <Path className="st21" d="M46.2,115.1c0,0-0.6,1.3-2,1.5s-2.2-0.8-2.2-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M46.1,114.9c0,0-1-1-2.4-0.8c-1.4,0.2-1.8,1.4-1.8,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M59.3,115c0,0-0.9-1.3-2.5-1.1c-1.7,0.2-2.5,1.6-2.5,1.6l0,0.2c0,0,1.1,1.3,2.8,1.1c1.7-0.2,2.3-1.6,2.3-1.6
			L59.3,115z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M54.3,115.6c0,0,0.8-1.5,2.5-1.6s2.5,1.1,2.5,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M54.3,115.8c0,0,1.1,1.3,2.8,1.1s2.3-1.6,2.3-1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M39.4,112.1c0,0-0.9-1.3-2.5-1.1c-1.7,0.2-2.5,1.6-2.5,1.6l0,0.2c0,0,1.1,1.3,2.8,1.1
			c1.7-0.2,2.3-1.6,2.3-1.6L39.4,112.1z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M34.4,112.7c0,0,0.8-1.5,2.5-1.6c1.7-0.2,2.5,1.1,2.5,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M34.4,112.9c0,0,1.1,1.3,2.8,1.1c1.7-0.2,2.3-1.6,2.3-1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <Line className="st1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="12.2" x2="8.4" y1="112.7" y2="112.7" />
        <G>
          <Path
            className="st6"
            d="M82.5,68.5c0,0-0.3-1.6,1.1-2.8c1.4-1.1,3.1-0.6,3.1-0.6l0.1,0.2c0,0,0.1,1.8-1.3,2.9
			c-1.4,1.1-2.9,0.5-2.9,0.5L82.5,68.5z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M86.7,65.1c0,0-1.7-0.5-3.1,0.6s-1.1,2.8-1.1,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M86.8,65.3c0,0,0.1,1.8-1.3,2.9c-1.4,1.1-2.9,0.5-2.9,0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M78,90.3c0,0-1.4-0.9-1.2-2.7c0.2-1.8,1.7-2.7,1.7-2.7l0.2,0c0,0,1.4,1.2,1.2,3c-0.2,1.8-1.7,2.4-1.7,2.4
			L78,90.3z"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M78.5,84.9c0,0-1.6,0.9-1.7,2.7s1.2,2.7,1.2,2.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M78.8,84.9c0,0,1.4,1.2,1.2,3c-0.2,1.8-1.7,2.4-1.7,2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st6"
            d="M35,45.3c0,0-1.5,0.9-3.1-0.1s-1.8-2.9-1.8-2.9l0.1-0.2c0,0,1.8-0.7,3.4,0.2c1.6,1,1.5,2.8,1.5,2.8L35,45.3z"
            fill="#E6EBEF"
          />
          <Path className="st21" d="M30.2,42.3c0,0,0.1,1.9,1.8,2.9c1.6,1,3.1,0.1,3.1,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M30.3,42.1c0,0,1.8-0.7,3.4,0.2c1.6,1,1.5,2.8,1.5,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M41.5,62.3c0,0,0.2,1.3-0.9,2.2c-1.1,0.9-2.5,0.5-2.5,0.5l-0.1-0.1c0,0-0.1-1.4,1-2.3
			c1.1-0.9,2.3-0.4,2.3-0.4L41.5,62.3z"
            fill="#FFFFFF"
          />
          <Path className="st21" d="M38.1,64.9c0,0,1.4,0.4,2.5-0.5c1.1-0.9,0.9-2.2,0.9-2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M38,64.8c0,0-0.1-1.4,1-2.3c1.1-0.9,2.3-0.4,2.3-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st2" d="M53.6,50.7c0,0-1.7,0-2.5-1.6s0-3.2,0-3.2l0.2-0.1c0,0,1.8,0.3,2.6,1.9c0.8,1.6-0.1,3-0.1,3L53.6,50.7z" fill="#FFFFFF" />
          <Path className="st21" d="M51.2,45.8c0,0-0.8,1.6,0,3.2c0.8,1.6,2.5,1.6,2.5,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M51.4,45.7c0,0,1.8,0.3,2.6,1.9s-0.1,3-0.1,3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st2"
            d="M169.4,109.4c-1.7-3.4-6-2.8-6-2.8l-0.8,0l0,0l-0.3,0l-0.3,0l0,0l-0.8,0c0,0-4.2-0.5-6,2.8
			c-1.7,3.4,0.9,6.3,2.9,7c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4C168.5,115.7,171.1,112.8,169.4,109.4z"
            fill="#FFFFFF"
          />
          <Path
            className="st7"
            d="M169.4,109.4c-1.1-2.1-3.1-2.7-4.5-2.8c0.9,0.4,1.7,1.1,2.3,2.2c1.7,3.4-0.9,6.3-2.9,7c0,0-1.1,0.4-3.1,0.4
			H161h-1.6h-0.2c-0.6,0-1.1,0-1.5-0.1c0.2,0.1,0.4,0.2,0.6,0.3c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4
			C168.5,115.7,171.1,112.8,169.4,109.4z"
            fill="#BAC2CA"
          />
          <Path
            className="st1"
            d="M169.4,109.4c-1.7-3.4-6-2.8-6-2.8l-0.8,0l0,0l-0.3,0l-0.3,0l0,0l-0.8,0c0,0-4.2-0.5-6,2.8
			c-1.7,3.4,0.9,6.3,2.9,7c0,0,1.1,0.4,3.1,0.4h0.2h1.6h0.2c2.1,0,3.1-0.4,3.1-0.4C168.5,115.7,171.1,112.8,169.4,109.4z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st21" d="M161.8,106.7c0,0-3.6,0.6-3.6,5c0,4.3,3.7,5.2,3.7,5.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M162.6,106.7c0,0,3.5,0.6,3.5,5c0,4.3-3.6,5.2-3.6,5.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M161.3,104.7c0,0,0.7,0.3,0.8,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <G>
          <Path
            className="st2"
            d="M120.7,110.5c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			s0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C120,115.7,122.1,113.3,120.7,110.5z"
            fill="#FFFFFF"
          />
          <Path
            className="st7"
            d="M120.7,110.5c-0.9-1.7-2.5-2.2-3.7-2.3c0.7,0.3,1.4,0.9,1.9,1.8c1.4,2.8-0.7,5.1-2.4,5.7c0,0-0.9,0.4-2.6,0.4
			h-0.2h-1.3h-0.2c-0.5,0-0.9,0-1.2-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4
			C120,115.7,122.1,113.3,120.7,110.5z"
            fill="#BAC2CA"
          />
          <Path
            className="st1"
            d="M120.7,110.5c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			s0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C120,115.7,122.1,113.3,120.7,110.5z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st21" d="M112,114.5c0.9,1.7,2.6,2.1,2.6,2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M114.4,108.3c0,0-2.2,0.4-2.8,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M115.1,108.3c0,0,2.9,0.5,2.9,4s-3,4.3-3,4.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M115.3,106.7c0,0-0.6,0.2-0.7,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <G>
          <Path
            className="st2"
            d="M181.2,111.1c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C180.5,116.2,182.6,113.8,181.2,111.1z"
            fill="#FFFFFF"
          />
          <Path
            className="st7"
            d="M181.2,111.1c-0.9-1.7-2.5-2.2-3.7-2.3c0.7,0.3,1.4,0.9,1.9,1.8c1.4,2.8-0.7,5.1-2.4,5.7c0,0-0.9,0.4-2.6,0.4
			h-0.2H173h-0.2c-0.5,0-0.9,0-1.2-0.1c0.2,0.1,0.3,0.2,0.5,0.2c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4
			C180.5,116.2,182.6,113.8,181.2,111.1z"
            fill="#BAC2CA"
          />
          <Path
            className="st1"
            d="M181.2,111.1c-1.4-2.8-4.9-2.3-4.9-2.3l-0.7,0l0,0l-0.2,0l-0.2,0l0,0l-0.7,0c0,0-3.5-0.4-4.9,2.3
			c-1.4,2.8,0.7,5.1,2.4,5.7c0,0,0.9,0.4,2.6,0.4h0.2h1.3h0.2c1.7,0,2.6-0.4,2.6-0.4C180.5,116.2,182.6,113.8,181.2,111.1z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st21" d="M172.5,115c0.9,1.7,2.6,2.1,2.6,2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M174.9,108.8c0,0-2.2,0.4-2.8,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st21" d="M175.6,108.8c0,0,2.9,0.5,2.9,4s-3,4.3-3,4.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M175.8,107.3c0,0-0.6,0.2-0.7,1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
      </G>
    </Svg>
  );
};

export default MarketingFall;
