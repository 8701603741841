/*
 * Change Mailing Address Screen
 */

import React, { Fragment, useCallback, useEffect } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import T from 'prop-types';
import { Keyboard } from 'react-native';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeMailingAddress,
  changeMailingAddressType,
  inputErrors,
  resetErrorState,
  resetFormState,
  resetFormType,
  uploadDocument,
} from '@dmi/shared/redux/Settings/Loans/actions';
import { validateFields } from '@dmi/shared/redux/Settings/Shared/helpers';
import makeSelectLoans, {
  selectBannerError,
  selectCanUploadMailingAddress,
  selectForm,
  selectFormErrors,
  selectFormToUse,
  selectFormValuesToSubmit,
  selectInternationalFormType,
  selectIsSubmitMailingAddressChangeButtonDisabled,
  selectMultiBorrowerChangeMailingJiraRequestBody,
  selectRequestBody,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Loans/selectors';

import { CHANGE_MAILING_ADDRESS_PROPS } from '@dmi/shared/redux/Settings/Loans/constants';
import AsyncRender from '../../../components/AsyncRender';
import { KeyboardAvoidingScrollView } from '../../../components/base_ui';
import { ChangeMailingAddressComponent } from '../../../components/Settings/ChangeMailingAddress';
import { StyledSecondaryHeader } from './styledComponents';

const ChangeMailingAddressScreen = ({
  allowedCountries,
  allowedProvinces,
  allowedStates,
  bannerError,
  canUpload,
  dispatchChangeInput,
  dispatchChangeMailingAddress,
  dispatchChangeMailingAddressType,
  dispatchInputErrors,
  dispatchResetErrorState,
  dispatchResetFormState,
  dispatchResetFormType,
  dispatchUploadDocument,
  documentRequestBody,
  fetchMailingAddressStatus,
  formErrors,
  formRequestStatus,
  formToUse,
  formType,
  formValuesToSubmit,
  internationalFormErrors,
  internationalFormType: { isAddressCanadian, isAddressInternational },
  internationalRequestBody,
  isSubmitDisabled,
  navigation,
  requestBody,
  uploadStatus,
}) => {
  useFocusEffect(
    useCallback(() => () => {
      dispatchResetErrorState();
      dispatchResetFormState([
        'changeMailingAddress',
        'changeMailingAddressInternational',
        'uploadDocument',
      ]);
      dispatchResetFormType();
    }, [dispatchResetErrorState, dispatchResetFormState, dispatchResetFormType]),
  );

  useEffect(() => {
    dispatchResetFormState(['changeMailingAddress', 'changeMailingAddressInternational']);
  }, [dispatchResetFormState, formType]);

  const handleChangeMailingAddress = () => {
    const formErrorsToUse = isAddressInternational ? internationalFormErrors : formErrors;
    const { isValidated, validationErrors } = validateFields({
      formErrors: formErrorsToUse,
      values: formValuesToSubmit,
    });
    if (isValidated) {
      if (canUpload) {
        dispatchUploadDocument(documentRequestBody);
      } else {
        dispatchChangeMailingAddress(formValuesToSubmit);
      }
    }
    Keyboard.dismiss();
    dispatchInputErrors({ form: formToUse, formErrors: validationErrors });
  };

  const handleChangeInput = (field, value) => {
    dispatchChangeInput({ field, form: formToUse, value });
  };

  const { alertText, heading, multiBorrowerText } = CHANGE_MAILING_ADDRESS_PROPS;

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={() => { navigation.goBack(); navigation.goBack(); }}
        title={heading}
      />
      <KeyboardAvoidingScrollView>
        <AsyncRender
          Component={ChangeMailingAddressComponent}
          error={fetchMailingAddressStatus === 'failed'}
          loading={fetchMailingAddressStatus === 'loading'}
          propsToPassDown={{
            alertText,
            allowedCountries,
            allowedProvinces,
            allowedStates,
            bannerError,
            canUpload,
            dispatchChangeMailingAddressType,
            formErrors,
            formType,
            handleChangeInput,
            handleChangeMailingAddress,
            internationalFormErrors,
            internationalRequestBody,
            isAddressCanadian,
            isAddressInternational,
            isSubmitDisabled,
            multiBorrowerText,
            requestBody,
            status: canUpload ? uploadStatus : formRequestStatus,
          }}
        />
      </KeyboardAvoidingScrollView>
    </Fragment>
  );
};

ChangeMailingAddressScreen.propTypes = {
  allowedCountries: T.array.isRequired,
  allowedProvinces: T.array.isRequired,
  allowedStates: T.array.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  canUpload: T.bool.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeMailingAddress: T.func.isRequired,
  dispatchChangeMailingAddressType: T.func.isRequired,
  dispatchInputErrors: T.func.isRequired,
  dispatchResetErrorState: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetFormType: T.func.isRequired,
  dispatchUploadDocument: T.func.isRequired,
  documentRequestBody: T.object.isRequired,
  fetchMailingAddressStatus: T.string.isRequired,
  formErrors: T.object.isRequired,
  formRequestStatus: T.string.isRequired,
  formToUse: T.string.isRequired,
  formType: T.string.isRequired,
  formValuesToSubmit: T.object.isRequired,
  internationalFormErrors: T.object.isRequired,
  internationalFormType: T.shape({
    isAddressCanadian: T.bool.isRequired,
    isAddressInternational: T.bool.isRequired,
  }).isRequired,
  internationalRequestBody: T.object.isRequired,
  isSubmitDisabled: T.bool.isRequired,
  navigation: T.object.isRequired,
  requestBody: T.object.isRequired,
  uploadStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  allowedCountries: makeSelectLoans('allowedCountries'),
  allowedProvinces: makeSelectLoans('allowedProvinces'),
  allowedStates: makeSelectLoans('allowedStates'),
  bannerError: selectBannerError(),
  canUpload: selectCanUploadMailingAddress(),
  documentRequestBody: selectMultiBorrowerChangeMailingJiraRequestBody(),
  fetchMailingAddressStatus: selectRequestStatus('fetchMailingAddress'),
  formErrors: selectFormErrors('changeMailingAddress'),
  formRequestStatus: selectRequestStatus('changeMailingAddress'),
  formToUse: selectFormToUse(),
  formType: makeSelectLoans('changeMailingAddressFormType'),
  formValuesToSubmit: selectFormValuesToSubmit(),
  internationalFormErrors: selectFormErrors('changeMailingAddressInternational'),
  internationalFormType: selectInternationalFormType(),
  internationalRequestBody: selectForm('changeMailingAddressInternational'),
  isSubmitDisabled: selectIsSubmitMailingAddressChangeButtonDisabled(),
  requestBody: selectRequestBody(),
  uploadStatus: selectRequestStatus('uploadDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loans
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeMailingAddress: (payload) => dispatch(changeMailingAddress(payload)),
  dispatchChangeMailingAddressType: (payload) => dispatch(changeMailingAddressType(payload)),
  dispatchInputErrors: (payload) => dispatch(inputErrors(payload)),
  dispatchResetErrorState: () => dispatch(resetErrorState()),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetFormType: () => dispatch(resetFormType()),
  dispatchUploadDocument: (payload) => dispatch(uploadDocument(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(ChangeMailingAddressScreen);
