import { INTL_IDS } from '@dmi/shared/utils/languageDictionary/sitemapMessages';

import iconDictionary from '../../utils/iconDictionary';

const CalculatorsIcon = iconDictionary('navCalculatorDefaultM');
const DisclosuresIcon = iconDictionary('navDisclosuresDefaultM');
const DocumentsIcon = iconDictionary('navDocumentsDefaultM');
const HelpIcon = iconDictionary('navHelpDefaultM');
const MorgageIcon = iconDictionary('navMortgageDefaultM');
const PaymentsIcon = iconDictionary('navPaymentsDefaultM');
const SettingsIcon = iconDictionary('navAccountDefaultM');
const TermsAndPrivacyIcon = iconDictionary('navAccountDefaultM');

export const MOBILE_SITE_MAP_POST_AUTH = {
  calculators: {
    Icon: CalculatorsIcon,
    label: 'Calculators',
    links: [
      {
        label: 'Overview',
        path: 'Calculators',
      },
      {
        label: 'Payment Adjustment',
        mainPath: 'Calculators',
        path: 'Amortize',
      },
    ],
  },
  disclosures: {
    Icon: DisclosuresIcon,
    label: 'Disclosures',
    links: [
      {
        label: 'Foreclosure Prevention Case Escalation Unit',
        path: 'foreclosurePrevention',
      },
      {
        href: 'https://www.servicedisclosure.com',
        label: 'Loss Mitigation Disclosure for Borrowers',
      },
      {
        label: 'Foreclosure Alternatives',
        path: 'foreclosureAlternatives',
      },
      {
        label: 'New York Disclosure Notice',
        path: 'newYorkDisclosure',
      },
      {
        label: 'Texas Residential Loan Disclosure Notice',
        path: 'texasDisclosure',
      },
    ],
  },
  documents: {
    Icon: DocumentsIcon,
    label: 'Documents',
    links: [
      {
        label: 'Overview',
        path: 'Documents',
      },
      {
        label: 'Statements',
        mainPath: 'Documents',
        path: 'Statements',
      },
      {
        label: INTL_IDS.DOCUMENTS_PAYOFF_LABEL,
        mainPath: 'Documents',
        path: 'Payoff',
      },
      {
        label: 'Forms & Resources',
        mainPath: 'Documents',
        path: 'GeneralDocs',
      },
    ],
  },
  help: {
    Icon: HelpIcon,
    label: 'Help',
    links: [
      {
        label: 'Overview',
        path: 'Help',
      },
      {
        label: 'Hardship Assistance',
        mainPath: 'Help',
        path: 'HardshipAssistance',
      },
      {
        label: 'Disaster Assistance',
        mainPath: 'Help',
        path: 'DisasterAssistance',
      },
      {
        label: 'Learning Center',
        mainPath: 'Help',
        path: 'LearningCenter',
      },
      {
        label: 'Secure Messaging',
        path: 'SecureMessaging',
      },
      {
        label: 'Contact Information',
        path: 'ContactUs',
      },
    ],
  },
  mortgage: {
    Icon: MorgageIcon,
    label: INTL_IDS.MORTGAGE_LABEL,
    links: [
      {
        label: 'Overview',
        path: 'Mortgage',
      },
      {
        label: INTL_IDS.MORTGAGE_MORTGAGEDETAILS_LABEL,
        mainPath: 'Mortgage',
        path: 'MortgageDetails',
      },
      {
        label: 'Taxes & Insurance Details',
        mainPath: 'Mortgage',
        path: 'TaxesInsuranceDetails',
      },
      {
        label: 'Escrow History',
        mainPath: 'Mortgage',
        path: 'EscrowHistory',
      },
    ],
  },
  payments: {
    Icon: PaymentsIcon,
    label: 'Payments',
    links: [
      {
        label: 'Payments',
        path: 'Payments',
      },
      {
        label: 'Payment History',
        mainPath: 'Payments',
        path: 'PaymentHistoryStack',
      },
    ],
  },
  settings: {
    Icon: SettingsIcon,
    label: 'Settings',
    links: [
      {
        label: 'Profile & Communications',
        path: 'Settings',
      },
    ],

  },
  termsAndPrivacy: {
    Icon: TermsAndPrivacyIcon,
    label: 'Terms & Privacy',
    links: [
      {
        label: 'Privacy Policy/CCPA',
        path: 'privacyPolicy',
      },
      {
        label: 'Electronic Communications Policy',
        path: 'ecommsPolicy',
      },
      {
        label: 'E-Statement Policy',
        path: 'estatementPolicy',
      },
      {
        label: 'Fee Schedule',
        path: 'feeSchedule',
      },
      {
        label: 'Terms of Use',
        path: 'termsOfUse',
      },
    ],
  },
};
