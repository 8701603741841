import React, { useEffect, useRef } from 'react';
import { Keyboard, View } from 'react-native';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { parseDocUploadAttachments } from '@dmi/shared/utils/globalHelpers';
import { validate } from '@dmi/shared/utils/validate';
import { DROPDOWN_TOPICS } from '@dmi/shared/redux/SecureMessaging/constants';

import { SecondaryHeader } from '../../../ScreenHeaders';
import FileRowButton from '../../Shared/FileRowButton';
import RowButton from '../../Shared/RowButton';
import {
  ButtonWrapper,
  FlexView,
  NewSecureMessagingContainer,
  StyledBodyField,
  StyledLargeProgressButton,
  StyledSubjectField,
} from '../styledComponents';

const NewSecureMessagingComponent = ({
  dispatchChangeFormFieldValue,
  dispatchPostMessage,
  formAttachments,
  formMessage: {
    body,
    subject,
    subtopic,
    topic,
  },
  formUploadError,
  handleBack,
  navigation,
  requestStatusPostMessage,
  setViewToRender,
}) => {
  const bodyRef = useRef();
  const { formatMessage } = useIntl();

  const handleFileRowButton = () => {
    navigation.navigate('NewMessageUploadDoc');
    if (formAttachments.length) navigation.navigate('NewMessageDocReview');
  };
  const hasAttachmentError = formAttachments.some(({ bannerError, removed }) =>
    !!bannerError && !removed) || !!formUploadError;

  useEffect(() => {
    if (hasAttachmentError) {
      navigation.navigate('NewMessageUploadDoc');
      navigation.navigate('NewMessageDocReview');
    }
  }, [
    formUploadError,
    hasAttachmentError,
    navigation,
  ]);

  const isBodyValid = validate({
    maxLength: 3000,
    type: 'message',
    value: body,
  });
  const isSubjectValid = validate({
    maxLength: 100,
    type: 'message',
    value: subject,
  });
  const isSendDisabled = hasAttachmentError || isBodyValid || isSubjectValid || !topic;

  return (
    // TODO: Update subtopics when they become available
    <NewSecureMessagingContainer>
      <View>
        <SecondaryHeader
          handleBack={handleBack}
          title="New Message"
        />
        <RowButton
          handlePress={setViewToRender}
          label="Topic"
          selected={topic !== '' ? formatMessage({ id: DROPDOWN_TOPICS.topic[topic] }) : ''}
        />
        {/* <RowButton
          handlePress={handleChangeView}
          label="Subtopic"
          selected={topic}
        /> */}
        <View>
          <StyledSubjectField
            $disabled={!topic}
            disabled={!topic}
            maxLength={100}
            onChangeText={
              (value) =>
                dispatchChangeFormFieldValue({ field: 'subject', form: 'message', value })
            }
            onSubmitEditing={() => bodyRef.current.focus()}
            placeholder="Subject line"
            returnKeyType="next"
            value={subject}
          />
        </View>
        <StyledBodyField
          ref={bodyRef}
          $disabled={!topic}
          disableFocus
          editable={!!topic}
          inputContainerStyle={{
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
          }}
          keyboardType="default"
          maxLength={3000}
          numberOfLines={12}
          onBlur={Keyboard.dismiss}
          onChangeText={
            (value) =>
              dispatchChangeFormFieldValue({ field: 'body', form: 'message', value })
          }
          placeholder="Type your message here."
          textAlignVertical="top"
          value={body}
        />
        <FileRowButton
          disabled={!topic}
          handlePress={handleFileRowButton}
          label="Add Attachment"
          numberOfAttachments={formAttachments.length}
        />
      </View>
      <FlexView />
      <ButtonWrapper>
        <StyledLargeProgressButton
          disabled={isSendDisabled}
          label="Send"
          loading={requestStatusPostMessage === 'loading'}
          onPress={() => dispatchPostMessage({
            attachments: parseDocUploadAttachments(formAttachments),
            body,
            subject,
            subtopic,
            topic,
          })}
          status={requestStatusPostMessage}
        />
      </ButtonWrapper>
    </NewSecureMessagingContainer>
  );
};

NewSecureMessagingComponent.propTypes = {
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchPostMessage: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formMessage: T.objectOf(T.string),
  formUploadError: T.string.isRequired,
  handleBack: T.func.isRequired,
  navigation: T.object.isRequired,
  requestStatusPostMessage: T.string.isRequired,
  setViewToRender: T.func.isRequired,
};

export default NewSecureMessagingComponent;
