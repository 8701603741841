import React, {
  Fragment,
  useEffect,
} from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';

import {
  changeFormFieldValue,
  changeSelectedMessageId,
  changeStep,
  fetchAttachment,
  fetchMessageDetails,
  fetchMessages,
  postReply,
  resetFormState,
  resetRequestStatus,
  resetStep,
  setAttachmentFile,
  setBannerError,
  toggleAttachmentDrawer,
} from '@dmi/shared/redux/LossDraft/actions';
import makeSelectLossDraft, {
  selectAttachment,
  selectErrors,
  selectForm,
  selectFormErrors,
  selectIsMessageCached,
  selectIsMessageStatusClosed,
  selectRequestStatus,
  selectSelectedMessageDetails,
} from '@dmi/shared/redux/LossDraft/selectors';

import PDFDisplay from '../../../components/PDFDisplay';
import AsyncRender from '../../../components/AsyncRender';
import { ConditionalRender } from '../../../components/base_ui';
import { StyledStatusBanner, StyledView } from './styledComponents';
import { MessageDetailsComponent } from '../../../components/LossDraft';

const MessageDetails = ({
  attachmentFile,
  bannerError,
  dispatchChangeFormFieldValue,
  dispatchChangeSelectedMessageId,
  dispatchChangeStep,
  dispatchFetchAttachment,
  dispatchFetchMessageDetails,
  dispatchFetchMessages,
  dispatchPostReply,
  dispatchResetLossDraftState,
  dispatchSetAttachmentFile,
  dispatchToggleAttachmentDrawer,
  formAttachments,
  formReply,
  formUploadError,
  isAttachmentDrawerOpen,
  isMessageCached,
  isMessageStatusClosed,
  messageDetails,
  navigation,
  requestStatusFetchMessageDetails,
  requestStatusPostReply,
  selectedMessageId,
  setShowReplyBox,
  showReplyBox,
}) => {
  useEffect(() => {
    if (!!selectedMessageId && requestStatusFetchMessageDetails !== 'failed') {
      if (!isMessageCached || requestStatusFetchMessageDetails === 'idle') {
        dispatchFetchMessageDetails({ messageId: selectedMessageId });
      }
    }
  }, [
    isMessageCached,
    selectedMessageId,
    requestStatusFetchMessageDetails,
    dispatchFetchMessageDetails,
    dispatchChangeSelectedMessageId,
  ]);

  const isLoading = ['idle', 'loading'].includes(requestStatusFetchMessageDetails);

  return (
    <Fragment>
      <StyledView>
        <StyledStatusBanner error={bannerError} />
        <AsyncRender
          Component={MessageDetailsComponent}
          error={
            (!selectedMessageId && !isLoading) || requestStatusFetchMessageDetails === 'failed'
          }
          loading={isLoading}
          propsToPassDown={{
            dispatchChangeFormFieldValue,
            dispatchChangeStep,
            dispatchFetchAttachment,
            dispatchFetchMessages,
            dispatchPostReply,
            dispatchResetLossDraftState,
            dispatchSetAttachmentFile,
            dispatchToggleAttachmentDrawer,
            formAttachments,
            formReply,
            formUploadError,
            isMessageCached,
            isMessageStatusClosed,
            messageDetails,
            navigation,
            requestStatusPostReply,
            selectedMessageId,
            setShowReplyBox,
            showReplyBox,
          }}
        />
      </StyledView>
      <ConditionalRender
        Component={PDFDisplay}
        propsToPassDown={{
          dispatchSetFile: dispatchSetAttachmentFile,
          dispatchToggleDrawer: dispatchToggleAttachmentDrawer,
          file: attachmentFile,
          isDrawerOpen: isAttachmentDrawerOpen,
        }}
        shouldRender={Platform.OS === 'ios' && !isEmpty(attachmentFile)}
      />
    </Fragment>
  );
};

MessageDetails.propTypes = {
  attachmentFile: T.object.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeSelectedMessageId: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchFetchAttachment: T.func.isRequired,
  dispatchFetchMessageDetails: T.func.isRequired,
  dispatchFetchMessages: T.func.isRequired,
  dispatchPostReply: T.func.isRequired,
  dispatchResetLossDraftState: T.func.isRequired,
  dispatchSetAttachmentFile: T.func.isRequired,
  dispatchToggleAttachmentDrawer: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formReply: T.object.isRequired,
  formUploadError: T.string.isRequired,
  isAttachmentDrawerOpen: T.bool.isRequired,
  isMessageCached: T.bool.isRequired,
  isMessageStatusClosed: T.bool.isRequired,
  messageDetails: T.object.isRequired,
  navigation: T.object.isRequired,
  requestStatusFetchMessageDetails: T.string.isRequired,
  requestStatusPostReply: T.string.isRequired,
  selectedMessageId: T.string.isRequired,
  setShowReplyBox: T.func.isRequired,
  showReplyBox: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  attachmentFile: selectAttachment('file'),
  bannerError: selectErrors('bannerError'),
  formAttachments: selectForm('attachments'),
  formReply: selectForm('reply'),
  formUploadError: selectFormErrors('attachments'),
  isAttachmentDrawerOpen: selectAttachment('isDrawerOpen'),
  isMessageCached: selectIsMessageCached(),
  isMessageStatusClosed: selectIsMessageStatusClosed(),
  messageDetails: selectSelectedMessageDetails(),
  requestStatusFetchMessageDetails: selectRequestStatus('fetchMessageDetails'),
  requestStatusPostReply: selectRequestStatus('postReply'),
  selectedMessageId: makeSelectLossDraft('selectedMessageId'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchChangeFormFieldValue: (payload) => dispatch(changeFormFieldValue(payload)),
  dispatchChangeSelectedMessageId: (payload) => dispatch(changeSelectedMessageId(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchFetchAttachment: (payload) => dispatch(fetchAttachment(payload)),
  dispatchFetchMessageDetails: (payload) => dispatch(fetchMessageDetails(payload)),
  dispatchFetchMessages: (payload) => dispatch(fetchMessages(payload)),
  dispatchPostReply: (payload) => dispatch(postReply(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
  dispatchResetStep: () => dispatch(resetStep()),
  dispatchSetAttachmentFile: (payload) => dispatch(setAttachmentFile(payload)),
  dispatchSetBannerError: (payload) => dispatch(setBannerError(payload)),
  dispatchToggleAttachmentDrawer: (payload) => dispatch(toggleAttachmentDrawer(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(MessageDetails);
