/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function IconArrowLeftPurple(props) {
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 16 18" width={moderateScale(16)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M8 17L0.88889 9L8 1" stroke="#282828" strokeLinecap="round" />
    </Svg>
  );
}
