import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Platform } from 'react-native';
import isEmpty from 'lodash/isEmpty';
import { useIntl } from 'react-intl';

import makeSelectMain,
{ selectIsPastServicingPaymentCutOffDate } from '@dmi/shared/redux/Main/selectors';
import {
  changePayoffStep,
  clearStatement,
  createPayoffStatement,
  fetchPayoffDates,
  fetchPayoffDocument,
  fetchPayoffHistory,
  resetState,
  setFile,
  toggleDrawer,
  updateRequestType,
  updateSelectedDate,
  updateSelectedPayOffReason,
} from '@dmi/shared/redux/Payoff/actions';
import { FETCH_PAYOFF_ERROR } from '@dmi/shared/redux/Payoff/constants';
import { INTL_IDS } from '@dmi/shared/redux/Payoff/messages';
import makeSelectPayoff, {
  selectAvailablePayOffReasons,
  selectIsPayDownAvailable,
  selectPayoffError,
  selectPayoffHistoryData,
  selectPayoffHomeButtonsView,
  selectPayoffNotifications,
  selectPayoffRequestBody,
} from '@dmi/shared/redux/Payoff/selectors';

import { ConditionalRender, StyledScrollView } from '../../components/base_ui';
import { PayoffHomeScreen, PayoffLoading } from '../../components/Payoff';
import { StyledStatusBanner } from './styledComponents';
import AsyncRender from '../../components/AsyncRender';
import PDFDisplay from '../../components/PDFDisplay';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import { SecondaryHeader } from '../../components/ScreenHeaders';
import LoadingDog from '../../components/LoadingDog';

const Payoff = ({
  availablePayOffReasons,
  bannerError: {
    payoffDates: payoffDatesBannerError,
    payoffDocument: payoffDocumentBannerError,
  },
  dispatchChangePayoffStep,
  dispatchClearStatement,
  dispatchCreatePayoffStatement,
  dispatchFetchPayoffDates,
  dispatchFetchPayoffDocument,
  dispatchFetchPayoffHistory,
  dispatchResetState,
  dispatchSetFile,
  dispatchToggleDrawer,
  dispatchUpdateRequestType,
  dispatchUpdateSelectedDate,
  dispatchUpdateSelectedPayOffReason,
  file,
  homeButtonsView,
  isDrawerOpen,
  isIneligible,
  isLoanMancodeY,
  isPastServicingPaymentCutOffDate,
  isPayDownAvailable,
  loading: {
    createStatement: createStatementLoading,
    fetchHistoryDocument: fetchHistoryDocumentLoading,
    fetchStatement: fetchStatementLoading,
    init: initLoading,
    payoffHistory: payoffHistoryLoading,
  },
  loanNumber,
  navigation,
  needSpecialist,
  notifications,
  payoffHistoryData,
  payoffRequestBody,
  payoffStep,
  requestType,
  selectedDate,
  serviceError: {
    init: initServiceError,
    payoffHistory: payoffHistoryError,
    statement: statementError,
  },
  statement,
}) => {
  const { formatMessage } = useIntl();

  useEffect(() => dispatchResetState, [dispatchResetState]);

  useEffect(() => {
    dispatchFetchPayoffDates(loanNumber);
    dispatchFetchPayoffHistory(loanNumber);
  }, [
    dispatchFetchPayoffDates,
    dispatchFetchPayoffHistory,
    loanNumber,
  ]);

  const handleOnPressRow = (token) => {
    dispatchFetchPayoffDocument({
      dispatchSetFile,
      dispatchToggleDrawer,
      loanNumber,
      token,
    });
  };

  return (
    <AsyncRender
      Component={() => (
        <Fragment>
          <SecondaryHeader
            handleBack={() => {
              if (payoffStep === 2) {
                dispatchUpdateRequestType('');
                dispatchUpdateSelectedDate('');
                dispatchUpdateSelectedPayOffReason('');
                dispatchChangePayoffStep(0);
              } else navigation.goBack();
            }}
            title={formatMessage({ id: INTL_IDS.HOME_HEADER }, { isPayDownAvailable })}
          />
          <StyledScrollView showsVerticalScrollIndicator={false}>
            <ScreenReaderNotifications notifications={notifications} />
            <StyledStatusBanner error={payoffDatesBannerError} />
            <StyledStatusBanner error={payoffDocumentBannerError} />
            <AsyncRender
              Component={PayoffHomeScreen}
              error={initServiceError || payoffHistoryError || statementError}
              errorComponentProps={statementError ? FETCH_PAYOFF_ERROR : null}
              errorComponentType="cardLevel"
              loading={initLoading || payoffHistoryLoading}
              LoadingComponent={PayoffLoading}
              propsToPassDown={{
                availablePayOffReasons,
                createStatementLoading,
                dateSelectorIsDisabled: !!payoffDatesBannerError,
                dispatchChangePayoffStep,
                dispatchClearStatement,
                dispatchCreatePayoffStatement,
                dispatchUpdateRequestType,
                dispatchUpdateSelectedDate,
                dispatchUpdateSelectedPayOffReason,
                fetchStatementLoading,
                handleOnPressRow,
                homeButtonsView,
                isIneligible,
                isLoanMancodeY,
                isPastServicingPaymentCutOffDate,
                isPayDownAvailable,
                loanNumber,
                navigation,
                needSpecialist,
                payoffHistoryData,
                payoffRequestBody,
                payoffStep,
                requestType,
                selectedDate,
                statement,
              }}
            />
            <ConditionalRender
              Component={PDFDisplay}
              propsToPassDown={{
                dispatchSetFile,
                dispatchToggleDrawer,
                file,
                isDrawerOpen,
              }}
              shouldRender={Platform.OS === 'ios' && !isEmpty(file)}
            />
          </StyledScrollView>
        </Fragment>
      )}
      error={false}
      loading={fetchHistoryDocumentLoading}
      LoadingComponent={LoadingDog}
    />
  );
};

Payoff.propTypes = {
  availablePayOffReasons: T.array.isRequired,
  bannerError: T.shape({
    payoffDates: T.oneOfType([T.bool, T.string]).isRequired,
    payoffDocument: T.oneOfType([T.bool, T.string]).isRequired,
  }).isRequired,
  dispatchChangePayoffStep: T.func.isRequired,
  dispatchClearStatement: T.func.isRequired,
  dispatchCreatePayoffStatement: T.func.isRequired,
  dispatchFetchPayoffDates: T.func.isRequired,
  dispatchFetchPayoffDocument: T.func.isRequired,
  dispatchFetchPayoffHistory: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchSetFile: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  dispatchUpdateRequestType: T.func.isRequired,
  dispatchUpdateSelectedDate: T.func.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  file: T.object.isRequired,
  homeButtonsView: T.string.isRequired,
  isDrawerOpen: T.bool.isRequired,
  isIneligible: T.bool.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  isPayDownAvailable: T.bool.isRequired,
  loading: T.shape({
    createStatement: T.bool.isRequired,
    fetchHistoryDocument: T.bool.isRequired,
    fetchStatement: T.bool.isRequired,
    init: T.bool.isRequired,
    payoffHistory: T.bool.isRequired,
  }).isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  needSpecialist: T.bool.isRequired,
  notifications: T.array.isRequired,
  payoffHistoryData: T.array.isRequired,
  payoffRequestBody: T.object.isRequired,
  payoffStep: T.number.isRequired,
  requestType: T.string.isRequired,
  selectedDate: T.string.isRequired,
  serviceError: T.shape({
    init: T.bool.isRequired,
    payoffHistory: T.bool.isRequired,
    statement: T.oneOfType([T.bool, T.string]).isRequired,
  }).isRequired,
  statement: T.shape({
    document: T.string.isRequired,
    mobileDocument: T.oneOfType([T.object, T.string]).isRequired,
  }),
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  isPastServicingPaymentCutOffDate: selectIsPastServicingPaymentCutOffDate(),
  loanNumber: makeSelectMain('selectedLoan'),
  /**
   * Store: Payoff
   */
  // eslint-disable-next-line sort-keys
  availablePayOffReasons: selectAvailablePayOffReasons(),
  bannerError: selectPayoffError('bannerError'),
  file: makeSelectPayoff('file'),
  homeButtonsView: selectPayoffHomeButtonsView(),
  isDrawerOpen: makeSelectPayoff('isDrawerOpen'),
  isIneligible: makeSelectPayoff('isIneligible'),
  isLoanMancodeY: makeSelectPayoff('isLoanMancodeY'),
  isPayDownAvailable: selectIsPayDownAvailable(),
  loading: makeSelectPayoff('loading'),
  needSpecialist: makeSelectPayoff('needSpecialist'),
  notifications: selectPayoffNotifications(),
  payoffHistoryData: selectPayoffHistoryData(),
  payoffRequestBody: selectPayoffRequestBody(),
  payoffStep: makeSelectPayoff('payoffStep'),
  requestType: makeSelectPayoff('requestType'),
  selectedDate: makeSelectPayoff('selectedDate'),
  selectedPayOffReason: makeSelectPayoff('selectedPayOffReason'),
  serviceError: selectPayoffError('serviceError'),
  statement: makeSelectPayoff('statement'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Payoff
   */
  dispatchChangePayoffStep: (payload) => dispatch(changePayoffStep(payload)),
  dispatchClearStatement: () => dispatch(clearStatement()),
  dispatchCreatePayoffStatement: (payload) => dispatch(createPayoffStatement(payload)),
  dispatchFetchPayoffDates: (loanNumber) => dispatch(fetchPayoffDates(loanNumber)),
  dispatchFetchPayoffDocument: (payload) => dispatch(fetchPayoffDocument(payload)),
  dispatchFetchPayoffHistory: (loanNumber) => dispatch(fetchPayoffHistory(loanNumber)),
  dispatchResetState: () => dispatch(resetState()),
  dispatchSetFile: (payload) => dispatch(setFile(payload)),
  dispatchToggleDrawer: (payload) => dispatch(toggleDrawer(payload)),
  dispatchUpdateRequestType: (payload) => dispatch(updateRequestType(payload)),
  dispatchUpdateSelectedDate: (date) => dispatch(updateSelectedDate(date)),
  dispatchUpdateSelectedPayOffReason: (reason) => dispatch(updateSelectedPayOffReason(reason)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(Payoff);
