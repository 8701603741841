import styled from 'styled-components/native';
import { Dimensions, Text } from 'react-native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { BorderlessButton, P3 } from '../../../base_ui';

const windowWidth = Dimensions.get('window').width;

export const FileName = styled(Text)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  font-weight: 700;
  max-width: ${({ $renderDocPicker }) => $renderDocPicker ?
    `${windowWidth * 0.50}px`
    : `${windowWidth * 0.8}px`};
  padding-left: ${moderateScale(9)}px;
`;

export const FileNameWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: auto;
`;

export const FileWrapper = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { primary },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: primary,
    fontSize: f4,
  }],
}))``;

export const ValidationErrorText = styled(P3)`
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  margin-top: ${moderateScale(0)}px;
  padding-left: ${moderateScale(33)}px;
`;

export const Container = styled.View`
  margin-bottom: ${({ $multiFile }) => $multiFile ?
    `${moderateScale(10)}px` :
    `${moderateScale(24)}px`};
`;
