/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavCalculatorDefaultMIcon(props) {
  return (
    <Svg height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12.7,16.6H2.3c-0.3,0-0.6-0.3-0.6-0.6V2c0-0.3,0.3-0.6,0.6-0.6h10.4c0.3,0,0.6,0.3,0.6,0.6v1.7c0,0.3-0.3,0.6-0.6,0.6S12.1,4,12.1,3.7V2.6H2.9v12.8h9.2V8.9c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6V16C13.3,16.3,13,16.6,12.7,16.6z"
      />
      <Path
        d="M7.7,12.9c-0.2,0-0.3-0.1-0.4-0.2c-0.1-0.1-0.2-0.3-0.2-0.5l0.2-1.5c0-0.1,0.1-0.3,0.2-0.3L15,2.9c0.2-0.2,0.6-0.2,0.9,0l1.3,1.3c0.1,0.1,0.2,0.3,0.2,0.4c0,0.2-0.1,0.3-0.2,0.4l-7.5,7.4c-0.1,0.1-0.2,0.2-0.3,0.2L7.7,12.9C7.8,12.9,7.7,12.9,7.7,12.9zM8.5,11.1l-0.1,0.5l0.5-0.1l6.9-6.9l-0.4-0.4L8.5,11.1z"
      />
      <Path
        d="M15,6.8c-0.2,0-0.3-0.1-0.4-0.2l-1.1-1.1c-0.2-0.2-0.2-0.6,0-0.9c0.2-0.2,0.6-0.2,0.9,0l1.1,1.1c0.2,0.2,0.2,0.6,0,0.9C15.3,6.7,15.2,6.8,15,6.8z"
      />
      <Path d="M9.7,6.7H4.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h4.9c0.3,0,0.6,0.3,0.6,0.6S10,6.7,9.7,6.7z" />
      <Path d="M7.2,9.7H4.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h2.4c0.3,0,0.6,0.3,0.6,0.6S7.5,9.7,7.2,9.7z" />
      <Path d="M5.6,12.7H4.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h0.8c0.3,0,0.6,0.3,0.6,0.6S5.9,12.7,5.6,12.7z" />
    </Svg>
  );
}
