import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const AndroidGradientView = styled.View`
  background-color: ${({ $color }) => $color};
  height: ${moderateScale(1)}px;
  width: 100%;
`;

export const Container = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: ${moderateScale(6)}px;
  overflow: hidden;
  width: 100%;
`;

export const IOSShadow = styled.View`
  background-color: #ffffff;
  box-shadow: 0 0 4px #777777;
  height: ${moderateScale(1)}px;
  left: -${moderateScale(100)}px;
  position: absolute;
  top: -${moderateScale(1)}px;
  width: 200%;
`;

export const WebShadow = styled(IOSShadow)`
  box-shadow: 0 0 8px #555555;
`;
