import React from 'react';
import { Pressable, View } from 'react-native';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TOOLTIP_DICTIONARY } from '@dmi/shared/redux/Calculators/Amortize/constants';

import {
  ItemText,
  ListItem,
  TooltipContainer,
  TooltipHeading,
  TooltipLink,
} from './styledComponents';

const TooltipContent = ({ handleClose, selectedAdjustmentFilter }) => {
  const { headingText, linkText, paragraphs } = TOOLTIP_DICTIONARY[selectedAdjustmentFilter];

  return (
    <TooltipContainer>
      <TooltipHeading>{headingText}</TooltipHeading>
      <View>
        { paragraphs.map((text) => (
          <ListItem key={text}>
            <ItemText>
              <FormattedMessage id={text} />
            </ItemText>
          </ListItem>
        ))}
      </View>
      <Pressable onPress={handleClose}>
        <TooltipLink>{linkText}</TooltipLink>
      </Pressable>
    </TooltipContainer>
  );
};

TooltipContent.propTypes = {
  handleClose: T.func.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
};

export default TooltipContent;
