import styled from 'styled-components/native';
import { PixelRatio } from 'react-native';

import {
  BASE_HEIGHT,
  CONTAINER_WIDTH,
  PADDING_RIGHT,
  PADDING_SIDE,
} from './constants';
import BaseInput from '../BaseInput';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const Container = styled.View`
  margin: ${moderateScale(10)}px 0;
`;

export const ErrorLabel = styled.Text`
  align-self: flex-end;
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  fontSize: ${({ theme: { fontSizes: { f2 } } }) => f2}px;
  padding-bottom: ${moderateScale(4)}px;
  width: ${moderateScale(217)}px;
`;

export const LabelInputContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  width: ${({ $isAutoWidth }) => $isAutoWidth ? 'auto' : `${CONTAINER_WIDTH}px`};
`;

export const StyledLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  fontSize: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  lineHeight: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  min-height: ${moderateScale(18)}px;
  width: ${moderateScale(106)}px;
`;

export const StyledTextField = styled(BaseInput).attrs(({
  error,
  inputContainerStyle,
  inputFontSize,
  inputStyle,
  theme: {
    fontFamilies: { secondaryFont },
    fontSizes: { f3 },
  },
}) => {
  const fontSizeToUse = inputFontSize || f3;

  return {
    inputContainerStyle: {
      backgroundColor: 'transparent',
      borderBottomWidth: error ? 3 : 1,
      ...inputContainerStyle,
    },
    inputStyle: {
      fontFamily: secondaryFont,
      fontSize: fontSizeToUse,
      height: BASE_HEIGHT + ((PixelRatio.getFontScale() * fontSizeToUse) - fontSizeToUse),
      paddingBottom: PADDING_SIDE,
      paddingLeft: PADDING_SIDE,
      paddingRight: PADDING_RIGHT,
      paddingTop: PADDING_SIDE,
      ...inputStyle,
    },
  };
})``;

export const TextFieldWrapper = styled.View`
  width: ${({ $width }) => moderateScale($width || 221)}px;
`;
