import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { VIDEO_URL_DICT } from '@dmi/shared/redux/Help/constants';

import { P2 } from '../../../../base_ui';
import { IMAGE_DICT } from './constants';
import { Container, Heading, StyledImage } from './styledComponents';

const Card = ({ dispatchSetVideoModalData, heading, text }) => (
  <Container>
    <Pressable
      onPress={() => dispatchSetVideoModalData({ heading, ...VIDEO_URL_DICT[heading] })}
    >
      <StyledImage aria-label={`view video: ${heading}`} source={IMAGE_DICT[heading]} />
    </Pressable>
    <Heading>{heading}</Heading>
    <P2>{text}</P2>
  </Container>
);

Card.propTypes = {
  dispatchSetVideoModalData: T.func.isRequired,
  heading: T.string.isRequired,
  text: T.string.isRequired,
};

export default Card;
