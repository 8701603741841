/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

export default function PiggyBankSplash(props) {
  const { colors: { svgHighlight } } = useTheme();
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';

  return (
    <Svg fill="none" height={moderateScale(150)} viewBox="0 0 327 150" width={moderateScale(327)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="white" height="150" width="327" />
      <G clipPath={clipPath}>
        <Path d="M147.58 21.53H69.79C68.7793 21.53 67.96 22.3493 67.96 23.36V122.21C67.96 123.221 68.7793 124.04 69.79 124.04H147.58C148.591 124.04 149.41 123.221 149.41 122.21V23.36C149.41 22.3493 148.591 21.53 147.58 21.53Z" fill="#BAC2CA" />
        <Path d="M143.91 21.53H69.79C68.7793 21.53 67.96 22.3493 67.96 23.36V122.21C67.96 123.221 68.7793 124.04 69.79 124.04H143.91C144.921 124.04 145.74 123.221 145.74 122.21V23.36C145.74 22.3493 144.921 21.53 143.91 21.53Z" fill="white" />
        <Path d="M145.74 27.63C145.7 27.6314 145.661 27.6246 145.624 27.61C145.587 27.5954 145.553 27.5734 145.525 27.5452C145.497 27.5171 145.475 27.4835 145.46 27.4464C145.445 27.4094 145.439 27.3698 145.44 27.33V24C145.44 23.4324 145.215 22.8881 144.813 22.4868C144.412 22.0855 143.868 21.86 143.3 21.86H70.4C70.3204 21.86 70.2441 21.8284 70.1878 21.7721C70.1316 21.7159 70.1 21.6396 70.1 21.56C70.1 21.4804 70.1316 21.4041 70.1878 21.3479C70.2441 21.2916 70.3204 21.26 70.4 21.26H143.3C144.02 21.2705 144.706 21.5638 145.212 22.0765C145.717 22.5893 146 23.2802 146 24V27.36C145.994 27.4278 145.965 27.4915 145.917 27.5406C145.87 27.5897 145.808 27.6212 145.74 27.63V27.63Z" fill="#2C2627" />
        <Path d="M143.3 124.33C143.257 124.338 143.212 124.336 143.17 124.325C143.127 124.314 143.087 124.294 143.054 124.266C143.02 124.237 142.993 124.202 142.974 124.162C142.955 124.122 142.945 124.079 142.945 124.035C142.945 123.991 142.955 123.947 142.974 123.908C142.993 123.868 143.02 123.832 143.054 123.804C143.087 123.776 143.127 123.756 143.17 123.745C143.212 123.734 143.257 123.732 143.3 123.74C143.868 123.74 144.412 123.514 144.813 123.113C145.215 122.712 145.44 122.168 145.44 121.6V31.38C145.44 31.3004 145.472 31.2241 145.528 31.1678C145.584 31.1116 145.66 31.08 145.74 31.08C145.819 31.0825 145.894 31.1149 145.949 31.1706C146.005 31.2263 146.037 31.3012 146.04 31.38V121.6C146.037 122.325 145.747 123.019 145.234 123.531C144.72 124.043 144.025 124.33 143.3 124.33Z" fill="#2C2627" />
        <Path d="M85.0401 74.24H93.3601C94.437 74.24 95.3101 73.3669 95.3101 72.29V63.97C95.3101 62.893 94.437 62.02 93.3601 62.02H85.0401C83.9631 62.02 83.0901 62.893 83.0901 63.97V72.29C83.0901 73.3669 83.9631 74.24 85.0401 74.24Z" fill={svgHighlight} />
        <Path d="M93.3499 74.54H84.9999C84.7032 74.54 84.4093 74.4815 84.1351 74.3679C83.8609 74.2543 83.6117 74.0879 83.4019 73.878C83.192 73.6682 83.0256 73.419 82.912 73.1448C82.7984 72.8706 82.7399 72.5767 82.7399 72.28V64C82.736 63.7007 82.7915 63.4035 82.9033 63.1259C83.0151 62.8482 83.1809 62.5955 83.3912 62.3825C83.6014 62.1694 83.8519 62.0003 84.1281 61.8848C84.4043 61.7694 84.7006 61.7099 84.9999 61.71H93.3099C93.6126 61.7046 93.9133 61.76 94.1941 61.8731C94.4749 61.9861 94.7302 62.1545 94.9447 62.3681C95.1593 62.5816 95.3287 62.8362 95.443 63.1165C95.5572 63.3968 95.614 63.6973 95.6099 64V72.31C95.6021 72.9041 95.3605 73.4713 94.9375 73.8887C94.5145 74.306 93.9442 74.54 93.3499 74.54V74.54ZM84.9999 62.31C84.5614 62.3205 84.1449 62.5041 83.8413 62.8208C83.5378 63.1374 83.3719 63.5614 83.3799 64V72.31C83.3876 72.7382 83.5604 73.1468 83.8623 73.4506C84.1642 73.7544 84.5718 73.9297 84.9999 73.94H93.3099C93.5301 73.9426 93.7486 73.9017 93.9529 73.8196C94.1571 73.7374 94.3432 73.6157 94.5002 73.4615C94.6573 73.3072 94.7823 73.1234 94.8681 72.9206C94.9538 72.7178 94.9986 72.5001 94.9999 72.28V64C94.9999 63.5597 94.8251 63.1375 94.5137 62.8262C94.2024 62.5148 93.7802 62.34 93.3399 62.34L84.9999 62.31Z" fill="#2C2627" />
        <Path d="M85.0401 91.9399H93.3601C94.437 91.9399 95.3101 91.0669 95.3101 89.9899V81.6699C95.3101 80.593 94.437 79.7199 93.3601 79.7199H85.0401C83.9631 79.7199 83.0901 80.593 83.0901 81.6699V89.9899C83.0901 91.0669 83.9631 91.9399 85.0401 91.9399Z" fill={svgHighlight} />
        <Path d="M93.35 92.24H85C84.411 92.2296 83.8494 91.9897 83.4347 91.5713C83.0201 91.1529 82.7852 90.5891 82.78 90V81.67C82.7826 81.0793 83.0163 80.5129 83.4313 80.0924C83.8462 79.6718 84.4093 79.4305 85 79.42H93.31C93.9077 79.42 94.481 79.6568 94.9046 80.0784C95.3281 80.5001 95.5674 81.0724 95.57 81.67V90C95.5649 90.5891 95.33 91.1529 94.9153 91.5713C94.5007 91.9897 93.939 92.2296 93.35 92.24V92.24ZM85 80C84.7825 79.9987 84.5669 80.0404 84.3655 80.1228C84.1642 80.2051 83.9811 80.3264 83.8268 80.4798C83.6726 80.6331 83.5501 80.8155 83.4666 81.0163C83.383 81.2171 83.34 81.4325 83.34 81.65V90C83.3413 90.2167 83.3853 90.431 83.4694 90.6307C83.5536 90.8304 83.6762 91.0116 83.8304 91.1638C83.9845 91.3161 84.1671 91.4366 84.3678 91.5183C84.5685 91.6 84.7833 91.6414 85 91.64H93.31C93.5289 91.644 93.7463 91.6047 93.9499 91.5243C94.1535 91.4439 94.3391 91.324 94.4962 91.1716C94.6533 91.0192 94.7786 90.8372 94.8651 90.6361C94.9516 90.4351 94.9974 90.2189 95 90V81.67C95.0013 81.452 94.9597 81.2359 94.8775 81.034C94.7953 80.8321 94.6741 80.6484 94.5209 80.4933C94.3677 80.3382 94.1854 80.2149 93.9845 80.1302C93.7836 80.0456 93.568 80.0014 93.35 80H85Z" fill="#2C2627" />
        <Path d="M85.0401 109.65H93.3601C94.437 109.65 95.3101 108.777 95.3101 107.7V99.38C95.3101 98.3031 94.437 97.43 93.3601 97.43H85.0401C83.9631 97.43 83.0901 98.3031 83.0901 99.38V107.7C83.0901 108.777 83.9631 109.65 85.0401 109.65Z" fill={svgHighlight} />
        <Path d="M93.35 110H85C84.4023 110 83.829 109.763 83.4055 109.342C82.9819 108.92 82.7426 108.348 82.74 107.75V99.38C82.7426 98.7824 82.9819 98.2101 83.4055 97.7884C83.829 97.3667 84.4023 97.13 85 97.13H93.31C93.9077 97.13 94.481 97.3667 94.9045 97.7884C95.3281 98.2101 95.5673 98.7824 95.57 99.38V107.7C95.5753 107.997 95.5221 108.292 95.4134 108.568C95.3047 108.844 95.1426 109.096 94.9364 109.31C94.7303 109.523 94.4841 109.694 94.2119 109.813C93.9397 109.931 93.6468 109.995 93.35 110ZM85 97.73C84.7825 97.7287 84.5668 97.7704 84.3655 97.8527C84.1642 97.9351 83.9811 98.0564 83.8268 98.2097C83.6725 98.3631 83.5501 98.5454 83.4665 98.7463C83.383 98.9471 83.34 99.1625 83.34 99.38V107.7C83.34 107.918 83.383 108.133 83.4665 108.334C83.5501 108.535 83.6725 108.717 83.8268 108.87C83.9811 109.024 84.1642 109.145 84.3655 109.227C84.5668 109.31 84.7825 109.351 85 109.35H93.31C93.5297 109.354 93.7479 109.314 93.9521 109.233C94.1564 109.152 94.3425 109.032 94.4997 108.878C94.6569 108.725 94.7821 108.541 94.8679 108.339C94.9538 108.137 94.9987 107.92 95 107.7V99.38C94.9974 98.9415 94.8213 98.5218 94.5103 98.2127C94.1992 97.9035 93.7785 97.73 93.34 97.73H85Z" fill="#2C2627" />
        <Path d="M103.56 74.24H111.88C112.957 74.24 113.83 73.3669 113.83 72.29V63.97C113.83 62.893 112.957 62.02 111.88 62.02H103.56C102.483 62.02 101.61 62.893 101.61 63.97V72.29C101.61 73.3669 102.483 74.24 103.56 74.24Z" fill={svgHighlight} />
        <Path d="M111.88 74.54H103.56C102.962 74.5373 102.39 74.2981 101.968 73.8745C101.547 73.451 101.31 72.8776 101.31 72.28V64C101.31 63.4023 101.547 62.829 101.968 62.4055C102.39 61.9819 102.962 61.7426 103.56 61.74H111.88C112.478 61.7426 113.05 61.9819 113.472 62.4055C113.893 62.829 114.13 63.4023 114.13 64V72.31C114.122 72.9024 113.882 73.4681 113.461 73.8852C113.04 74.3023 112.473 74.5374 111.88 74.54V74.54ZM103.56 62.31C103.34 62.3099 103.122 62.3539 102.919 62.4393C102.716 62.5247 102.533 62.6498 102.379 62.8073C102.225 62.9648 102.105 63.1514 102.024 63.3563C101.943 63.5611 101.905 63.78 101.91 64V72.31C101.91 72.7485 102.084 73.1692 102.393 73.4803C102.702 73.7913 103.122 73.9673 103.56 73.97H111.88C112.319 73.9673 112.738 73.7913 113.047 73.4803C113.357 73.1692 113.53 72.7485 113.53 72.31V64C113.531 63.7825 113.49 63.5668 113.407 63.3655C113.325 63.1641 113.204 62.9811 113.05 62.8268C112.897 62.6725 112.715 62.5501 112.514 62.4665C112.313 62.383 112.098 62.34 111.88 62.34L103.56 62.31Z" fill="#2C2627" />
        <Path d="M103.65 91.9399H111.97C113.047 91.9399 113.92 91.0669 113.92 89.9899V81.6699C113.92 80.593 113.047 79.7199 111.97 79.7199H103.65C102.573 79.7199 101.7 80.593 101.7 81.6699V89.9899C101.7 91.0669 102.573 91.9399 103.65 91.9399Z" fill={svgHighlight} />
        <Path d="M112 92.24H103.68C103.383 92.244 103.088 92.1891 102.812 92.0784C102.536 91.9677 102.285 91.8035 102.073 91.5953C101.861 91.387 101.693 91.1388 101.577 90.865C101.461 90.5912 101.401 90.2972 101.4 90V81.67C101.4 81.0733 101.637 80.501 102.059 80.0791C102.481 79.6571 103.053 79.42 103.65 79.42H112C112.597 79.42 113.169 79.6571 113.591 80.0791C114.013 80.501 114.25 81.0733 114.25 81.67V90C114.247 90.595 114.009 91.1648 113.587 91.5846C113.166 92.0044 112.595 92.24 112 92.24V92.24ZM103.65 80C103.432 80 103.215 80.0434 103.014 80.1275C102.812 80.2117 102.63 80.335 102.476 80.4904C102.323 80.6457 102.202 80.83 102.12 81.0325C102.038 81.235 101.997 81.4517 102 81.67V90C102 90.4377 102.174 90.8573 102.483 91.1668C102.793 91.4762 103.212 91.65 103.65 91.65H112C112.432 91.6422 112.844 91.4649 113.147 91.1563C113.45 90.8477 113.62 90.4325 113.62 90V81.67C113.625 81.2341 113.458 80.8138 113.154 80.5009C112.851 80.188 112.436 80.0079 112 80H103.65Z" fill="#2C2627" />
        <Path d="M103.65 109.65H111.97C113.047 109.65 113.92 108.777 113.92 107.7V99.38C113.92 98.3031 113.047 97.43 111.97 97.43H103.65C102.573 97.43 101.7 98.3031 101.7 99.38V107.7C101.7 108.777 102.573 109.65 103.65 109.65Z" fill={svgHighlight} />
        <Path d="M112 110H103.68C103.083 110 102.511 109.763 102.089 109.341C101.667 108.919 101.43 108.347 101.43 107.75V99.38C101.43 98.7833 101.667 98.211 102.089 97.789C102.511 97.3671 103.083 97.13 103.68 97.13H112C112.597 97.13 113.169 97.3671 113.591 97.789C114.013 98.211 114.25 98.7833 114.25 99.38V107.7C114.257 108 114.203 108.298 114.093 108.576C113.983 108.855 113.819 109.109 113.609 109.323C113.399 109.538 113.149 109.708 112.873 109.824C112.597 109.94 112.3 110 112 110V110ZM103.68 97.78C103.246 97.7719 102.826 97.9354 102.511 98.2349C102.197 98.5344 102.013 98.9459 102 99.38V107.7C102 108.138 102.174 108.557 102.483 108.867C102.793 109.176 103.212 109.35 103.65 109.35H112C112.438 109.35 112.857 109.176 113.167 108.867C113.476 108.557 113.65 108.138 113.65 107.7V99.38C113.65 98.9424 113.476 98.5227 113.167 98.2133C112.857 97.9038 112.438 97.73 112 97.73L103.68 97.78Z" fill="#2C2627" />
        <Path d="M122.09 74.24H130.41C131.487 74.24 132.36 73.3669 132.36 72.29V63.97C132.36 62.893 131.487 62.02 130.41 62.02H122.09C121.013 62.02 120.14 62.893 120.14 63.97V72.29C120.14 73.3669 121.013 74.24 122.09 74.24Z" fill={svgHighlight} />
        <Path d="M130.4 74.54H122.09C121.491 74.54 120.916 74.3019 120.492 73.878C120.068 73.4542 119.83 72.8794 119.83 72.28V64C119.83 63.4006 120.068 62.8258 120.492 62.4019C120.916 61.9781 121.491 61.74 122.09 61.74H130.4C130.999 61.74 131.574 61.9781 131.998 62.4019C132.422 62.8258 132.66 63.4006 132.66 64V72.31C132.65 72.9033 132.407 73.469 131.985 73.8858C131.562 74.3026 130.993 74.5375 130.4 74.54V74.54ZM122.09 62.31C121.869 62.31 121.651 62.3539 121.448 62.4392C121.244 62.5244 121.06 62.6494 120.905 62.8068C120.751 62.9641 120.629 63.1506 120.548 63.3555C120.466 63.5604 120.426 63.7795 120.43 64V72.31C120.43 72.7502 120.605 73.1725 120.916 73.4838C121.227 73.7951 121.65 73.97 122.09 73.97H130.4C130.839 73.9674 131.26 73.7916 131.571 73.4809C131.882 73.1701 132.057 72.7494 132.06 72.31V64C132.06 63.5597 131.885 63.1375 131.574 62.8262C131.262 62.5149 130.84 62.34 130.4 62.34L122.09 62.31Z" fill="#2C2627" />
        <Path d="M122.09 109.65H130.41C131.487 109.65 132.36 108.777 132.36 107.7V81.67C132.36 80.5931 131.487 79.72 130.41 79.72H122.09C121.013 79.72 120.14 80.5931 120.14 81.67V107.7C120.14 108.777 121.013 109.65 122.09 109.65Z" fill={svgHighlight} />
        <Path d="M130.4 110H122.09C121.492 110 120.919 109.763 120.495 109.342C120.072 108.92 119.833 108.348 119.83 107.75V81.75C119.833 81.1523 120.072 80.5801 120.495 80.1584C120.919 79.7367 121.492 79.5 122.09 79.5H130.4C130.998 79.5 131.571 79.7367 131.994 80.1584C132.418 80.5801 132.657 81.1523 132.66 81.75V107.75C132.657 108.348 132.418 108.92 131.994 109.342C131.571 109.763 130.998 110 130.4 110ZM122.09 80C121.872 79.9987 121.657 80.0404 121.455 80.1227C121.254 80.205 121.071 80.3264 120.917 80.4797C120.762 80.6331 120.64 80.8154 120.557 81.0163C120.473 81.2171 120.43 81.4325 120.43 81.65V107.65C120.43 107.868 120.473 108.083 120.557 108.284C120.64 108.485 120.762 108.667 120.917 108.82C121.071 108.974 121.254 109.095 121.455 109.177C121.657 109.26 121.872 109.301 122.09 109.3H130.4C130.838 109.3 131.259 109.126 131.57 108.817C131.881 108.508 132.057 108.089 132.06 107.65V81.65C132.057 81.2115 131.881 80.7918 131.57 80.4827C131.259 80.1735 130.838 80 130.4 80H122.09Z" fill="#2C2627" />
        <Path d="M137.53 30.21H78.1C77.625 30.21 77.24 30.595 77.24 31.07V50.66C77.24 51.1349 77.625 51.52 78.1 51.52H137.53C138.005 51.52 138.39 51.1349 138.39 50.66V31.07C138.39 30.595 138.005 30.21 137.53 30.21Z" fill="#E6EBEF" />
        <Path d="M147 124.33H70.4C69.6751 124.33 68.9797 124.043 68.4661 123.531C67.9525 123.019 67.6627 122.325 67.66 121.6V50.69C67.6625 50.6112 67.6949 50.5364 67.7507 50.4807C67.8064 50.4249 67.8813 50.3925 67.96 50.39C68.0396 50.39 68.1159 50.4216 68.1722 50.4779C68.2284 50.5341 68.26 50.6104 68.26 50.69V121.6C68.26 122.168 68.4855 122.712 68.8868 123.113C69.2882 123.515 69.8325 123.74 70.4 123.74H147C147.568 123.74 148.112 123.515 148.513 123.113C148.915 122.712 149.14 122.168 149.14 121.6V101.21C149.14 101.13 149.172 101.054 149.228 100.998C149.284 100.942 149.36 100.91 149.44 100.91C149.48 100.909 149.519 100.915 149.556 100.93C149.594 100.945 149.627 100.967 149.655 100.995C149.683 101.023 149.705 101.057 149.72 101.094C149.735 101.131 149.741 101.17 149.74 101.21V121.6C149.74 121.959 149.669 122.315 149.531 122.647C149.393 122.979 149.191 123.28 148.937 123.534C148.682 123.788 148.38 123.988 148.048 124.125C147.715 124.262 147.359 124.331 147 124.33V124.33Z" fill="#2C2627" />
        <Path d="M149.41 98.32C149.331 98.3175 149.256 98.2851 149.201 98.2294C149.145 98.1737 149.112 98.0988 149.11 98.02V93.78C149.11 93.7406 149.118 93.7016 149.133 93.6652C149.148 93.6288 149.17 93.5958 149.198 93.5679C149.226 93.54 149.259 93.5179 149.295 93.5029C149.332 93.4878 149.371 93.48 149.41 93.48C149.45 93.4786 149.489 93.4854 149.526 93.5C149.563 93.5146 149.597 93.5367 149.625 93.5648C149.653 93.5929 149.675 93.6266 149.69 93.6636C149.705 93.7006 149.711 93.7403 149.71 93.78V98C149.713 98.0411 149.707 98.0823 149.693 98.121C149.679 98.1597 149.658 98.1952 149.63 98.2252C149.601 98.2552 149.567 98.2791 149.53 98.2954C149.492 98.3118 149.451 98.3201 149.41 98.32V98.32Z" fill="#2C2627" />
        <Path d="M149.41 91.09C149.371 91.09 149.332 91.0822 149.295 91.0672C149.259 91.0521 149.226 91.03 149.198 91.0021C149.17 90.9743 149.148 90.9412 149.133 90.9048C149.118 90.8684 149.11 90.8294 149.11 90.79V24C149.114 23.719 149.063 23.4399 148.959 23.1788C148.855 22.9176 148.7 22.6795 148.505 22.478C148.309 22.2764 148.075 22.1155 147.817 22.0043C147.559 21.8932 147.281 21.8339 147 21.83H70.4C70.1165 21.83 69.8357 21.8863 69.5741 21.9957C69.3125 22.1051 69.0752 22.2655 68.8761 22.4674C68.677 22.6693 68.52 22.9087 68.4142 23.1719C68.3085 23.435 68.2561 23.7165 68.26 24V47.48C68.26 47.5596 68.2284 47.6359 68.1722 47.6921C68.1159 47.7484 68.0396 47.78 67.96 47.78C67.8813 47.7775 67.8064 47.7451 67.7507 47.6894C67.6949 47.6336 67.6625 47.5588 67.66 47.48V24C67.66 23.2733 67.9487 22.5764 68.4626 22.0625C68.9764 21.5487 69.6733 21.26 70.4 21.26H147C147.722 21.2679 148.411 21.5601 148.918 22.0731C149.426 22.5861 149.71 23.2785 149.71 24V90.79C149.711 90.8298 149.705 90.8694 149.69 90.9064C149.675 90.9435 149.653 90.9771 149.625 91.0052C149.597 91.0334 149.564 91.0554 149.526 91.07C149.489 91.0846 149.45 91.0914 149.41 91.09V91.09Z" fill="#2C2627" />
        <Path d="M100.59 51.53H85.95L95.24 30.21H109.88L100.59 51.53Z" fill="white" opacity="0.7" />
        <Path d="M113.43 51.53H107.04L116.32 30.21H122.72L113.43 51.53Z" fill="white" opacity="0.7" />
        <Path d="M137.44 51.83H78.18C77.8511 51.83 77.5357 51.6993 77.3032 51.4668C77.0706 51.2342 76.94 50.9188 76.94 50.59V30.91C76.9386 30.7468 76.9698 30.5849 77.0317 30.4339C77.0935 30.2828 77.1848 30.1456 77.3002 30.0302C77.4156 29.9148 77.5528 29.8235 77.7039 29.7616C77.8549 29.6998 78.0168 29.6686 78.18 29.67H137.44C137.603 29.6686 137.765 29.6998 137.916 29.7616C138.067 29.8235 138.204 29.9148 138.32 30.0302C138.435 30.1456 138.526 30.2828 138.588 30.4339C138.65 30.5849 138.681 30.7468 138.68 30.91V50.59C138.68 50.9188 138.549 51.2342 138.317 51.4668C138.084 51.6993 137.769 51.83 137.44 51.83ZM78.18 30.27C78.0956 30.2686 78.0117 30.2842 77.9335 30.3159C77.8552 30.3476 77.7841 30.3947 77.7244 30.4544C77.6647 30.5141 77.6176 30.5852 77.5859 30.6635C77.5543 30.7417 77.5386 30.8255 77.54 30.91V50.59C77.54 50.7597 77.6074 50.9225 77.7274 51.0425C77.8475 51.1625 78.0102 51.23 78.18 51.23H137.44C137.61 51.23 137.773 51.1625 137.893 51.0425C138.013 50.9225 138.08 50.7597 138.08 50.59V30.91C138.081 30.8255 138.066 30.7417 138.034 30.6635C138.002 30.5852 137.955 30.5141 137.896 30.4544C137.836 30.3947 137.765 30.3476 137.686 30.3159C137.608 30.2842 137.524 30.2686 137.44 30.27H78.18Z" fill="#2C2627" />
        <Path d="M259.76 124V74.39C259.761 74.1861 259.722 73.9839 259.645 73.7953C259.567 73.6066 259.453 73.4352 259.309 73.291C259.165 73.1468 258.993 73.0327 258.805 72.9553C258.616 72.8779 258.414 72.8387 258.21 72.84H240.21C240.006 72.8387 239.803 72.8778 239.614 72.9551C239.425 73.0324 239.253 73.1464 239.108 73.2904C238.963 73.4345 238.847 73.6058 238.769 73.7945C238.69 73.9832 238.65 74.1856 238.65 74.39V124" fill="#BAC2CA" />
        <Path d="M260.06 124H259.46V74.3899C259.46 74.0584 259.328 73.7405 259.094 73.5061C258.859 73.2716 258.542 73.1399 258.21 73.1399H240.21C239.886 73.1528 239.58 73.2898 239.355 73.5226C239.129 73.7554 239.002 74.066 239 74.3899V124H238.4V74.3899C238.4 74.1462 238.448 73.9048 238.542 73.6797C238.635 73.4546 238.773 73.2502 238.945 73.0782C239.118 72.9063 239.323 72.7703 239.549 72.6779C239.775 72.5855 240.016 72.5386 240.26 72.5399H258.26C258.751 72.5399 259.221 72.7349 259.568 73.0818C259.915 73.4287 260.11 73.8993 260.11 74.3899L260.06 124Z" fill="black" />
        <Path d="M204.46 50.1H221.64C222.17 50.1 222.679 50.3107 223.054 50.6858C223.429 51.0608 223.64 51.5695 223.64 52.1V124H202.51V52.06C202.52 51.545 202.729 51.0539 203.092 50.6888C203.455 50.3237 203.945 50.1128 204.46 50.1Z" fill="#BAC2CA" />
        <Path d="M223.9 124.29H202.21V52.0601C202.21 51.4624 202.447 50.8891 202.868 50.4655C203.29 50.042 203.862 49.8027 204.46 49.8H221.64C222.239 49.8 222.814 50.0382 223.238 50.462C223.662 50.8858 223.9 51.4607 223.9 52.0601V124.29ZM202.81 123.69H223.3V52.0601C223.3 51.6198 223.125 51.1976 222.814 50.8863C222.502 50.5749 222.08 50.4001 221.64 50.4001H204.46C204.021 50.4027 203.602 50.5787 203.293 50.8898C202.983 51.2008 202.81 51.6215 202.81 52.0601V123.69Z" fill="black" />
        <Path d="M167.24 26.7399H184.42C184.95 26.7399 185.459 26.9507 185.834 27.3257C186.209 27.7008 186.42 28.2095 186.42 28.7399V124H165.29V28.6899C165.29 28.1595 165.501 27.6508 165.876 27.2757C166.251 26.9007 166.76 26.6899 167.29 26.6899L167.24 26.7399Z" fill="#BAC2CA" />
        <Path d="M186.67 124.29H165V28.6899C165 28.0932 165.237 27.5209 165.659 27.099C166.081 26.677 166.653 26.4399 167.25 26.4399H184.43C185.027 26.4399 185.599 26.677 186.021 27.099C186.443 27.5209 186.68 28.0932 186.68 28.6899L186.67 124.29ZM165.59 123.69H186.07V28.6899C186.075 28.4699 186.037 28.251 185.956 28.0462C185.876 27.8414 185.755 27.6547 185.601 27.4973C185.447 27.3398 185.264 27.2147 185.061 27.1293C184.858 27.0438 184.64 26.9999 184.42 26.9999H167.24C166.802 26.9999 166.383 27.1738 166.073 27.4832C165.764 27.7927 165.59 28.2123 165.59 28.6499V123.69Z" fill="black" />
        <Path d="M236.17 124V74.39C236.169 74.1861 236.208 73.9839 236.285 73.7953C236.363 73.6066 236.477 73.4352 236.621 73.291C236.765 73.1468 236.937 73.0327 237.125 72.9553C237.314 72.8779 237.516 72.8387 237.72 72.84H255.72C255.924 72.8387 256.126 72.8779 256.315 72.9553C256.503 73.0327 256.675 73.1468 256.819 73.291C256.963 73.4352 257.077 73.6066 257.155 73.7953C257.232 73.9839 257.271 74.1861 257.27 74.39V124" fill="#EFF3F6" />
        <Path d="M257.25 78.85V74.39C257.251 74.1861 257.212 73.9839 257.135 73.7953C257.057 73.6066 256.943 73.4352 256.799 73.291C256.655 73.1468 256.483 73.0327 256.295 72.9553C256.106 72.8779 255.904 72.8387 255.7 72.84H237.7C237.496 72.8387 237.294 72.8779 237.105 72.9553C236.917 73.0327 236.745 73.1468 236.601 73.291C236.457 73.4352 236.343 73.6066 236.265 73.7953C236.188 73.9839 236.149 74.1861 236.15 74.39V124" fill="#EFF3F6" />
        <Path d="M236.17 124.29C236.13 124.291 236.091 124.285 236.054 124.27C236.017 124.255 235.983 124.233 235.955 124.205C235.927 124.177 235.905 124.144 235.89 124.106C235.875 124.069 235.869 124.03 235.87 123.99V74.39C235.87 73.8994 236.065 73.4288 236.412 73.0819C236.759 72.735 237.229 72.54 237.72 72.54H255.72C256.211 72.54 256.681 72.735 257.028 73.0819C257.375 73.4288 257.57 73.8994 257.57 74.39V78.85C257.57 78.9296 257.538 79.0059 257.482 79.0622C257.426 79.1184 257.35 79.15 257.27 79.15C257.191 79.1475 257.116 79.1151 257.061 79.0594C257.005 79.0037 256.973 78.9288 256.97 78.85V74.39C256.97 74.0585 256.838 73.7406 256.604 73.5062C256.37 73.2717 256.052 73.14 255.72 73.14H237.72C237.389 73.14 237.071 73.2717 236.836 73.5062C236.602 73.7406 236.47 74.0585 236.47 74.39V124C236.467 124.078 236.435 124.152 236.379 124.206C236.323 124.26 236.248 124.29 236.17 124.29V124.29Z" fill="#2C2627" />
        <Path d="M257.25 124.29C257.17 124.29 257.094 124.258 257.038 124.202C256.982 124.146 256.95 124.07 256.95 123.99V82.3C256.952 82.2212 256.985 82.1464 257.041 82.0906C257.096 82.0349 257.171 82.0025 257.25 82C257.33 82 257.406 82.0316 257.462 82.0879C257.518 82.1441 257.55 82.2204 257.55 82.3V124C257.55 124.039 257.542 124.077 257.527 124.113C257.512 124.149 257.489 124.182 257.461 124.209C257.433 124.236 257.4 124.257 257.364 124.271C257.328 124.285 257.289 124.291 257.25 124.29Z" fill="#2C2627" />
        <Path d="M221.09 56.16V51.66C221.09 51.248 220.927 50.8527 220.637 50.5604C220.346 50.2681 219.952 50.1026 219.54 50.1H201.54C201.13 50.1052 200.738 50.2719 200.45 50.5639C200.162 50.8559 200 51.2497 200 51.66V124H221.09V60.18" fill="#BAC2CA" />
        <Path d="M221.39 124.29H199.7V51.66C199.699 51.4163 199.746 51.1746 199.838 50.949C199.93 50.7234 200.066 50.5183 200.238 50.3454C200.41 50.1726 200.615 50.0354 200.84 49.9418C201.065 49.8482 201.306 49.8 201.55 49.8H219.55C219.794 49.8 220.035 49.8482 220.26 49.9418C220.485 50.0354 220.69 50.1726 220.862 50.3454C221.034 50.5183 221.17 50.7234 221.262 50.949C221.354 51.1746 221.401 51.4163 221.4 51.66V56.16C221.4 56.2396 221.368 56.3159 221.312 56.3722C221.256 56.4284 221.18 56.46 221.1 56.46C221.02 56.46 220.944 56.4284 220.888 56.3722C220.832 56.3159 220.8 56.2396 220.8 56.16V51.66C220.8 51.3276 220.669 51.0086 220.434 50.7726C220.2 50.5366 219.882 50.4027 219.55 50.4001H201.55C201.218 50.4027 200.9 50.5366 200.665 50.7726C200.431 51.0086 200.3 51.3276 200.3 51.66V123.66H220.79V60.18C220.79 60.1005 220.822 60.0242 220.878 59.9679C220.934 59.9117 221.01 59.8801 221.09 59.8801C221.17 59.8801 221.246 59.9117 221.302 59.9679C221.358 60.0242 221.39 60.1005 221.39 60.18V124.29Z" fill="#2C2627" />
        <Path d="M183.87 32.53V28.29C183.87 28.0856 183.83 27.8833 183.751 27.6945C183.673 27.5058 183.558 27.3345 183.413 27.1905C183.268 27.0464 183.095 26.9324 182.906 26.8551C182.717 26.7778 182.514 26.7387 182.31 26.74H164.31C164.106 26.7387 163.904 26.7779 163.715 26.8553C163.527 26.9327 163.355 27.0468 163.211 27.191C163.067 27.3352 162.953 27.5066 162.875 27.6953C162.798 27.884 162.759 28.0861 162.76 28.29V124H183.85V36.79" fill={svgHighlight} />
        <Path d="M184.17 124.29H162.48V28.29C162.48 27.7993 162.675 27.3288 163.022 26.9818C163.369 26.6349 163.839 26.44 164.33 26.44H182.33C182.574 26.4387 182.815 26.4855 183.041 26.5779C183.267 26.6703 183.472 26.8064 183.645 26.9783C183.817 27.1502 183.955 27.3546 184.048 27.5797C184.142 27.8048 184.19 28.0462 184.19 28.29V32.53C184.19 32.6095 184.158 32.6858 184.102 32.7421C184.046 32.7984 183.97 32.83 183.89 32.83C183.85 32.8314 183.811 32.8246 183.774 32.81C183.737 32.7954 183.703 32.7733 183.675 32.7452C183.647 32.7171 183.625 32.6834 183.61 32.6464C183.595 32.6094 183.589 32.5697 183.59 32.53V28.29C183.594 28.1203 183.564 27.9516 183.501 27.7939C183.438 27.6362 183.344 27.4928 183.225 27.3723C183.105 27.2518 182.963 27.1568 182.805 27.0928C182.648 27.0288 182.48 26.9972 182.31 27H164.31C163.978 27 163.661 27.1317 163.426 27.3661C163.192 27.6005 163.06 27.9184 163.06 28.25V123.65H183.55V36.79C183.55 36.7104 183.582 36.6341 183.638 36.5778C183.694 36.5216 183.77 36.49 183.85 36.49C183.929 36.4925 184.004 36.5249 184.059 36.5806C184.115 36.6363 184.147 36.7112 184.15 36.79L184.17 124.29Z" fill="#2C2627" />
        <Path d="M206 88.65C205.92 88.6566 205.841 88.6314 205.78 88.5798C205.72 88.5282 205.682 88.4545 205.675 88.375C205.668 88.2954 205.694 88.2165 205.745 88.1555C205.797 88.0946 205.87 88.0566 205.95 88.05C207.517 87.7974 208.939 86.9834 209.95 85.76C210.187 85.4486 210.392 85.1135 210.56 84.76C209.866 84.784 209.172 84.7303 208.49 84.6C207.677 84.4844 206.903 84.1796 206.23 83.71C205.903 83.4561 205.633 83.136 205.439 82.7707C205.244 82.4053 205.128 82.003 205.1 81.59C205.072 81.1954 205.127 80.7993 205.261 80.4273C205.395 80.0553 205.606 79.7155 205.88 79.43C206.512 78.8539 207.329 78.5231 208.184 78.4975C209.039 78.4718 209.874 78.7529 210.54 79.29C211.163 79.8802 211.563 80.6683 211.67 81.52C211.801 82.3694 211.739 83.2375 211.49 84.06C211.886 83.9873 212.275 83.8768 212.65 83.73C213.225 83.5223 213.74 83.1765 214.149 82.7232C214.559 82.2698 214.851 81.7227 215 81.13C215.019 81.0581 215.063 80.9958 215.125 80.9549C215.187 80.9141 215.262 80.8977 215.336 80.9089C215.409 80.9201 215.476 80.9581 215.523 81.0155C215.57 81.073 215.594 81.1458 215.59 81.22C215.435 81.9299 215.101 82.5883 214.619 83.1326C214.138 83.677 213.526 84.0892 212.84 84.33C212.317 84.5397 211.769 84.6809 211.21 84.75C210.996 85.2478 210.717 85.7152 210.38 86.14C209.267 87.4762 207.707 88.3642 205.99 88.64L206 88.65ZM208.33 79.15C207.591 79.1323 206.875 79.4011 206.33 79.9C206.119 80.127 205.956 80.3951 205.853 80.6876C205.75 80.98 205.708 81.2906 205.73 81.6C205.752 81.9268 205.842 82.2454 205.996 82.5349C206.149 82.8244 206.362 83.0783 206.62 83.28C207.215 83.6951 207.901 83.9625 208.62 84.06C209.354 84.2034 210.104 84.2505 210.85 84.2C211.143 83.3905 211.225 82.52 211.09 81.67C211.001 80.9533 210.674 80.2874 210.16 79.78C209.638 79.3499 208.976 79.1258 208.3 79.15H208.33Z" fill="#2C2627" />
        <Path d="M160.6 80.64C159.803 79.2542 158.73 78.0472 157.446 77.0941C156.163 76.141 154.697 75.4622 153.14 75.1C147.77 73.82 144.76 75.1 144.76 75.1C144.76 75.1 148.82 79.22 148.43 83.6" fill="white" />
        <Path d="M148.43 83.9001C148.391 83.8976 148.353 83.8872 148.318 83.8696C148.283 83.852 148.252 83.8275 148.227 83.7976C148.201 83.7678 148.183 83.7331 148.171 83.6957C148.16 83.6583 148.156 83.619 148.16 83.5801C148.53 79.3901 144.62 75.3601 144.58 75.3201C144.546 75.2866 144.522 75.2453 144.508 75.2C144.494 75.1547 144.491 75.1066 144.5 75.0601C144.508 75.0124 144.528 74.9675 144.558 74.9292C144.588 74.8909 144.626 74.8603 144.67 74.84C144.8 74.78 147.84 73.53 153.24 74.84C154.842 75.2052 156.35 75.8996 157.669 76.8792C158.987 77.8588 160.088 79.1021 160.9 80.5301C160.934 80.6017 160.939 80.684 160.913 80.759C160.886 80.834 160.831 80.8955 160.76 80.9301C160.725 80.9488 160.687 80.9603 160.648 80.9639C160.609 80.9675 160.569 80.9631 160.532 80.9509C160.495 80.9388 160.46 80.9191 160.43 80.8932C160.401 80.8673 160.377 80.8356 160.36 80.8001C159.578 79.4588 158.531 78.291 157.283 77.3677C156.035 76.4444 154.611 75.7852 153.1 75.4301C150.565 74.7208 147.892 74.6657 145.33 75.27C146.33 76.37 149.09 79.8801 148.75 83.6501C148.74 83.7253 148.701 83.7937 148.641 83.8404C148.581 83.8872 148.505 83.9086 148.43 83.9001V83.9001Z" fill="#2C2627" />
        <Path d="M167.77 109.7H162.01C160.132 109.7 158.61 111.222 158.61 113.1V120.7C158.61 122.578 160.132 124.1 162.01 124.1H167.77C169.648 124.1 171.17 122.578 171.17 120.7V113.1C171.17 111.222 169.648 109.7 167.77 109.7Z" fill="white" />
        <Path d="M167.77 124.4H162C161.019 124.4 160.078 124.01 159.384 123.316C158.69 122.622 158.3 121.681 158.3 120.7V113.1C158.3 112.119 158.69 111.178 159.384 110.484C160.078 109.79 161.019 109.4 162 109.4H167.76C168.741 109.4 169.682 109.79 170.376 110.484C171.07 111.178 171.46 112.119 171.46 113.1V120.7C171.46 121.68 171.072 122.619 170.38 123.313C169.688 124.006 168.75 124.397 167.77 124.4V124.4ZM162 110C161.178 110 160.389 110.327 159.808 110.908C159.227 111.489 158.9 112.278 158.9 113.1V120.7C158.9 121.522 159.227 122.311 159.808 122.892C160.389 123.473 161.178 123.8 162 123.8H167.76C168.582 123.8 169.371 123.473 169.952 122.892C170.533 122.311 170.86 121.522 170.86 120.7V113.1C170.86 112.278 170.533 111.489 169.952 110.908C169.371 110.327 168.582 110 167.76 110H162Z" fill="black" />
        <Path d="M192.47 109.7H186.31C184.543 109.7 183.11 111.133 183.11 112.9V120.9C183.11 122.667 184.543 124.1 186.31 124.1H192.47C194.237 124.1 195.67 122.667 195.67 120.9V112.9C195.67 111.133 194.237 109.7 192.47 109.7Z" fill="white" />
        <Path d="M192.47 124.4H186.32C185.389 124.4 184.496 124.03 183.838 123.372C183.18 122.714 182.81 121.821 182.81 120.89V112.89C182.81 111.959 183.18 111.066 183.838 110.408C184.496 109.75 185.389 109.38 186.32 109.38H192.47C193.401 109.38 194.294 109.75 194.952 110.408C195.61 111.066 195.98 111.959 195.98 112.89V120.89C195.98 121.821 195.61 122.714 194.952 123.372C194.294 124.03 193.401 124.4 192.47 124.4V124.4ZM186.32 110C185.549 110.003 184.81 110.31 184.265 110.855C183.72 111.4 183.413 112.139 183.41 112.91V120.91C183.41 121.682 183.717 122.422 184.262 122.968C184.808 123.513 185.548 123.82 186.32 123.82H192.47C193.242 123.82 193.982 123.513 194.528 122.968C195.073 122.422 195.38 121.682 195.38 120.91V112.91C195.38 112.138 195.073 111.398 194.528 110.852C193.982 110.307 193.242 110 192.47 110H186.32Z" fill="black" />
        <Path d="M200.9 80.5C203.182 82.194 205.043 84.3919 206.337 86.9224C207.63 89.453 208.323 92.2481 208.36 95.09V97.59C208.36 108.25 198.69 116.9 186.76 116.9H165.2C153.28 116.9 143.61 108.25 143.61 97.59V95.09C143.61 84.43 153.28 75.78 165.2 75.78H186.76C191.168 75.7551 195.493 76.9827 199.23 79.32" fill="white" />
        <Path d="M186.76 117.2H165.2C153.13 117.2 143.31 108.4 143.31 97.59V95.09C143.31 84.28 153.13 75.48 165.2 75.48H186.76C191.225 75.4535 195.606 76.6987 199.39 79.07C199.424 79.0904 199.453 79.1173 199.475 79.1493C199.498 79.1812 199.514 79.2174 199.523 79.2558C199.531 79.2941 199.532 79.3337 199.524 79.3723C199.517 79.4108 199.502 79.4475 199.48 79.48C199.439 79.5473 199.372 79.5956 199.296 79.6143C199.219 79.633 199.138 79.6207 199.07 79.58C195.382 77.2682 191.112 76.0542 186.76 76.08H165.2C153.46 76.08 143.91 84.61 143.91 95.08V97.58C143.91 108.06 153.46 116.58 165.2 116.58H186.76C198.5 116.58 208.06 108.05 208.06 97.58V95.08C208.019 92.284 207.336 89.5347 206.063 87.0448C204.791 84.5548 202.962 82.3908 200.72 80.72C200.688 80.6965 200.661 80.6667 200.641 80.6325C200.622 80.5982 200.609 80.5602 200.604 80.5209C200.599 80.4815 200.603 80.4416 200.614 80.4036C200.626 80.3656 200.645 80.3304 200.67 80.3C200.726 80.2451 200.802 80.2143 200.88 80.2143C200.959 80.2143 201.034 80.2451 201.09 80.3C203.405 82.0257 205.292 84.2607 206.604 86.8323C207.917 89.4039 208.62 92.2431 208.66 95.13V97.63C208.66 108.4 198.83 117.2 186.76 117.2Z" fill="#2C2627" />
        <Path d="M201.22 81.45C203.424 83.2611 205.205 85.5324 206.438 88.1045C207.672 90.6767 208.328 93.4875 208.36 96.34V96.34C208.36 107.7 198.06 116.9 185.36 116.9H166.6C166.6 116.9 181.23 116.34 189.4 112.32C197.57 108.3 200.4 106.74 202.97 100.22C206.54 91.16 201.22 81.45 201.22 81.45Z" fill="#E6EBEF" />
        <Path d="M186.76 117.2H165.2C153.13 117.2 143.31 108.4 143.31 97.59V95.09C143.31 84.28 153.13 75.48 165.2 75.48H186.76C190.094 75.4605 193.396 76.1419 196.45 77.48L196.2 78.03C193.229 76.7057 190.012 76.0241 186.76 76.03H165.2C153.46 76.03 143.91 84.56 143.91 95.03V97.53C143.91 108.01 153.46 116.53 165.2 116.53H186.76C198.5 116.53 208.06 108 208.06 97.53V95.03C208.038 92.4671 207.468 89.9386 206.386 87.615C205.304 85.2914 203.737 83.2267 201.79 81.56L202.18 81.11C204.194 82.8311 205.814 84.9645 206.932 87.366C208.05 89.7675 208.64 92.3811 208.66 95.03V97.53C208.66 108.4 198.83 117.2 186.76 117.2Z" fill="#2C2627" />
        <Path d="M150.98 93.71H144.79C142.343 93.71 140.36 95.6933 140.36 98.14V100.27C140.36 102.717 142.343 104.7 144.79 104.7H150.98C153.427 104.7 155.41 102.717 155.41 100.27V98.14C155.41 95.6933 153.427 93.71 150.98 93.71Z" fill="#EFF3F6" />
        <Path d="M151 105H144.81C143.555 105 142.352 104.502 141.465 103.615C140.578 102.728 140.08 101.525 140.08 100.27V98.14C140.083 96.8864 140.582 95.6848 141.468 94.7983C142.355 93.9119 143.556 93.4127 144.81 93.41H151C152.254 93.4127 153.455 93.9119 154.342 94.7983C155.228 95.6848 155.727 96.8864 155.73 98.14V100.27C155.73 101.525 155.232 102.728 154.345 103.615C153.458 104.502 152.254 105 151 105ZM144.81 94C143.715 94.0027 142.666 94.4386 141.893 95.2126C141.119 95.9865 140.683 97.0355 140.68 98.13V100.26C140.68 101.356 141.115 102.408 141.889 103.184C142.663 103.96 143.714 104.397 144.81 104.4H151C152.096 104.397 153.147 103.96 153.921 103.184C154.695 102.408 155.13 101.356 155.13 100.26V98.14C155.13 97.0438 154.695 95.9923 153.921 95.2161C153.147 94.44 152.096 94.0027 151 94H144.81Z" fill="#2C2627" />
        <Path d="M145.33 100.22C145.954 100.22 146.46 99.7678 146.46 99.21C146.46 98.6521 145.954 98.2 145.33 98.2C144.706 98.2 144.2 98.6521 144.2 99.21C144.2 99.7678 144.706 100.22 145.33 100.22Z" fill="#2C2627" />
        <Path d="M150.79 91.77C151.414 91.77 151.92 90.8746 151.92 89.77C151.92 88.6654 151.414 87.77 150.79 87.77C150.166 87.77 149.66 88.6654 149.66 89.77C149.66 90.8746 150.166 91.77 150.79 91.77Z" fill="#2C2627" />
        <Path d="M157.48 91.77C158.104 91.77 158.61 90.8746 158.61 89.77C158.61 88.6654 158.104 87.77 157.48 87.77C156.856 87.77 156.35 88.6654 156.35 89.77C156.35 90.8746 156.856 91.77 157.48 91.77Z" fill="#2C2627" />
        <Path d="M149.66 100.22C150.284 100.22 150.79 99.7678 150.79 99.21C150.79 98.6521 150.284 98.2 149.66 98.2C149.036 98.2 148.53 98.6521 148.53 99.21C148.53 99.7678 149.036 100.22 149.66 100.22Z" fill="#2C2627" />
        <Path d="M152.2 107.52C152.124 107.52 152.05 107.491 151.995 107.439C151.939 107.387 151.905 107.316 151.9 107.24C151.897 107.159 151.926 107.081 151.98 107.021C152.034 106.961 152.11 106.925 152.19 106.92C152.32 106.92 155.44 106.74 156.06 103.92C156.064 103.877 156.076 103.836 156.097 103.798C156.118 103.761 156.147 103.729 156.181 103.703C156.216 103.678 156.255 103.66 156.297 103.651C156.339 103.643 156.383 103.643 156.425 103.652C156.466 103.662 156.506 103.68 156.54 103.706C156.574 103.732 156.602 103.764 156.623 103.802C156.643 103.84 156.655 103.881 156.658 103.924C156.661 103.967 156.655 104.01 156.64 104.05C155.93 107.34 152.25 107.54 152.22 107.54L152.2 107.52Z" fill="#2C2627" />
        <Path d="M168.52 78.5C167.678 77.2068 166.583 76.0973 165.301 75.2381C164.019 74.3788 162.576 73.7876 161.06 73.5C155.69 72.33 152.68 73.5 152.68 73.5C152.68 73.5 156.74 77.25 156.34 81.23" fill="white" />
        <Path d="M156.34 81.49C156.302 81.4863 156.265 81.475 156.231 81.4569C156.197 81.4387 156.167 81.414 156.143 81.3843C156.119 81.3545 156.101 81.3203 156.09 81.2835C156.079 81.2467 156.076 81.2081 156.08 81.17C156.45 77.38 152.55 73.73 152.51 73.69C152.472 73.6549 152.443 73.6111 152.426 73.5623C152.408 73.5136 152.403 73.4613 152.41 73.41C152.42 73.3603 152.443 73.314 152.477 73.2755C152.51 73.237 152.552 73.2076 152.6 73.19C152.73 73.19 155.76 72 161.15 73.19C162.713 73.4832 164.2 74.0942 165.517 74.9852C166.835 75.8763 167.956 77.0283 168.81 78.37C168.829 78.4048 168.841 78.443 168.845 78.4825C168.85 78.5219 168.846 78.5618 168.835 78.5999C168.824 78.6379 168.805 78.6734 168.78 78.7044C168.756 78.7353 168.725 78.761 168.69 78.78C168.655 78.7991 168.617 78.8111 168.578 78.8153C168.538 78.8196 168.498 78.8161 168.46 78.8049C168.422 78.7938 168.387 78.7753 168.356 78.7504C168.325 78.7255 168.299 78.6948 168.28 78.66C167.457 77.3968 166.389 76.312 165.138 75.4702C163.887 74.6284 162.48 74.0468 161 73.76C158.465 73.1253 155.818 73.074 153.26 73.61C154.26 74.61 156.99 77.82 156.65 81.22C156.647 81.2581 156.637 81.2954 156.62 81.3296C156.603 81.3638 156.58 81.3943 156.551 81.4194C156.522 81.4445 156.489 81.4637 156.453 81.4758C156.416 81.4879 156.378 81.4927 156.34 81.49V81.49Z" fill="#2C2627" />
        <Path d="M189.68 79.21H173.47C172.896 79.21 172.43 79.6756 172.43 80.25V80.74C172.43 81.3143 172.896 81.78 173.47 81.78H189.68C190.254 81.78 190.72 81.3143 190.72 80.74V80.25C190.72 79.6756 190.254 79.21 189.68 79.21Z" fill="#E6EBEF" />
        <Path d="M189.68 82.08H173.47C173.116 82.08 172.777 81.9402 172.526 81.6911C172.275 81.442 172.133 81.1037 172.13 80.75V80.25C172.13 79.8946 172.271 79.5538 172.522 79.3025C172.774 79.0512 173.115 78.91 173.47 78.91H189.68C190.032 78.9153 190.368 79.0588 190.615 79.3095C190.862 79.5602 191 79.8981 191 80.25V80.75C190.997 81.1002 190.858 81.4355 190.611 81.6841C190.364 81.9327 190.03 82.0748 189.68 82.08V82.08ZM173.47 79.51C173.274 79.51 173.086 79.588 172.947 79.7268C172.808 79.8655 172.73 80.0538 172.73 80.25V80.75C172.733 80.9446 172.812 81.1302 172.95 81.2668C173.089 81.4035 173.275 81.4801 173.47 81.48H189.68C189.874 81.48 190.059 81.4031 190.196 81.2662C190.333 81.1293 190.41 80.9436 190.41 80.75V80.25C190.41 80.0555 190.333 79.8688 190.197 79.7303C190.06 79.5918 189.875 79.5127 189.68 79.51H173.47Z" fill="#2C2627" />
        <Path d="M181.57 77.16C186.248 77.16 190.04 73.7708 190.04 69.59C190.04 65.4092 186.248 62.02 181.57 62.02C176.892 62.02 173.1 65.4092 173.1 69.59C173.1 73.7708 176.892 77.16 181.57 77.16Z" fill="#EFF3F6" />
        <Path d="M181.57 77.46C176.74 77.46 172.8 73.93 172.8 69.59C172.8 65.25 176.74 61.71 181.57 61.71C186.4 61.71 190.34 65.24 190.34 69.59C190.34 73.94 186.41 77.46 181.57 77.46ZM181.57 62.31C177.07 62.31 173.4 65.57 173.4 69.59C173.4 73.61 177.07 76.86 181.57 76.86C186.07 76.86 189.74 73.6 189.74 69.59C189.74 65.58 186.08 62.31 181.57 62.31V62.31Z" fill="#2C2627" />
        <Path d="M301.15 124.47H279.91C279.861 124.471 279.811 124.463 279.765 124.445C279.719 124.427 279.677 124.4 279.642 124.365C279.606 124.331 279.578 124.289 279.559 124.244C279.54 124.198 279.53 124.149 279.53 124.1C279.53 123.999 279.57 123.903 279.641 123.831C279.713 123.76 279.809 123.72 279.91 123.72H301.15C301.251 123.72 301.347 123.76 301.419 123.831C301.49 123.903 301.53 123.999 301.53 124.1C301.53 124.149 301.52 124.198 301.501 124.244C301.482 124.289 301.454 124.331 301.418 124.365C301.383 124.4 301.341 124.427 301.295 124.445C301.249 124.463 301.199 124.471 301.15 124.47V124.47Z" fill="#2C2627" />
        <Path d="M277.75 124.47H275.92C275.871 124.471 275.821 124.463 275.775 124.445C275.729 124.427 275.687 124.4 275.652 124.365C275.616 124.331 275.588 124.289 275.569 124.244C275.55 124.198 275.54 124.149 275.54 124.1C275.54 123.999 275.58 123.903 275.651 123.831C275.723 123.76 275.819 123.72 275.92 123.72H277.75C277.799 123.72 277.848 123.73 277.894 123.749C277.94 123.768 277.981 123.796 278.015 123.832C278.05 123.867 278.077 123.909 278.095 123.955C278.113 124.001 278.121 124.051 278.12 124.1C278.121 124.149 278.113 124.198 278.095 124.243C278.077 124.289 278.049 124.33 278.015 124.365C277.98 124.399 277.939 124.427 277.893 124.445C277.848 124.463 277.799 124.471 277.75 124.47V124.47Z" fill="#2C2627" />
        <Path d="M273.59 124.47H26.45C26.401 124.471 26.3523 124.463 26.3068 124.445C26.2613 124.427 26.2199 124.399 26.1853 124.365C26.1506 124.33 26.1234 124.289 26.1053 124.243C26.0872 124.198 26.0786 124.149 26.08 124.1C26.0786 124.051 26.0872 124.001 26.1052 123.955C26.1232 123.909 26.1503 123.867 26.1847 123.832C26.2192 123.796 26.2605 123.768 26.306 123.749C26.3516 123.73 26.4005 123.72 26.45 123.72H273.59C273.691 123.72 273.787 123.76 273.859 123.831C273.93 123.903 273.97 123.999 273.97 124.1C273.97 124.149 273.96 124.198 273.941 124.244C273.922 124.289 273.894 124.331 273.858 124.365C273.823 124.4 273.781 124.427 273.735 124.445C273.689 124.463 273.639 124.471 273.59 124.47V124.47Z" fill="#2C2627" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="150" width="327" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
