import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Platform } from 'react-native';
import { useTheme } from 'styled-components/native';

import { getIsLoanAvailable, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';
import makeSelectNotifications from '@dmi/shared/redux/Notifications/selectors';
import makeSelectLossDraft from '@dmi/shared/redux/LossDraft/selectors';

import NavigationDrawer from '../../../components/NavigationDrawer';
import { getNavigatorProps, headerOptions } from './helpers';

const HomeDrawerNavigator = ({
  fetchLossDraftLoadingStatus,
  isLoanAvailable,
  isMurabahaAccount,
  notificationsError,
  notificationsLoading,
}) => {
  const theme = useTheme();
  const { ComponentToRender, Drawer, initialRouteName } =
    getNavigatorProps(isLoanAvailable, isMurabahaAccount, Platform.OS === 'web');

  return (
    <Drawer.Navigator
      drawerContent={({ navigation, state }) => (
        <NavigationDrawer navigation={navigation} navigationState={state} />
      )}
      initialRouteName={initialRouteName}
      screenOptions={
        ({ navigation }) =>
          headerOptions({
            isLoanAvailable,
            navigation,
            notificationsError,
            notificationsLoading: notificationsLoading || fetchLossDraftLoadingStatus,
            theme,
          })
      }
    >
      {ComponentToRender}
    </Drawer.Navigator>
  );
};

HomeDrawerNavigator.propTypes = {
  fetchLossDraftLoadingStatus: T.bool.isRequired,
  isLoanAvailable: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  notificationsError: T.oneOfType([T.bool, T.string]).isRequired,
  notificationsLoading: T.bool.isRequired,
};

/* eslint-disable object-curly-newline */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  // eslint-disable-next-line sort-keys
  fetchLossDraftLoadingStatus: makeSelectLossDraft('loading'),
  /**
   * Store: Main
   */
  isLoanAvailable: getIsLoanAvailable(),
  isMurabahaAccount: selectIsMurabahaAccount(),
  /**
   * Store: Notifications
   */
  notificationsError: makeSelectNotifications('error'),
  notificationsLoading: makeSelectNotifications('loading'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(HomeDrawerNavigator);
