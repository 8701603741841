import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  chooseDocument,
  removeAttachment,
  resetRequestStatus,
  updateAttachments,
} from '@dmi/shared/redux/SecureMessaging/actions';
import { selectForm, selectFormErrors } from '@dmi/shared/redux/SecureMessaging/selectors';

import { SecureMessageDocReview } from '../../../components/SecureMessaging/Shared';
import { MessageContainer } from '../styledComponents';

const NewMessageDocReviewScreen = ({
  dispatchChooseDocument,
  dispatchRemoveDocument,
  dispatchResetRequestStatus,
  dispatchUpdateAttachments,
  formAttachments,
  formUploadError,
  navigation,
}) => {
  const handleBack = () => {
    dispatchResetRequestStatus({ requestStatus: 'chooseDocument' });
    navigation.goBack();
    navigation.goBack();
  };

  const propsToPassDown = {
    dispatchChooseDocument,
    dispatchRemoveDocument,
    dispatchResetRequestStatus,
    dispatchUpdateAttachments,
    formAttachments,
    formUploadError,
    handleBack,
    navigation,
  };

  return (
    <MessageContainer>
      <SecureMessageDocReview
        {...propsToPassDown}
      />
    </MessageContainer>
  );
};

NewMessageDocReviewScreen.propTypes = {
  dispatchChooseDocument: T.func.isRequired,
  dispatchRemoveDocument: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  dispatchUpdateAttachments: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formUploadError: T.string.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: SecureMessaging
   */
  formAttachments: selectForm('attachments'),
  formMessage: selectForm('message'),
  formUploadError: selectFormErrors('attachments'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: SecureMessaging
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchRemoveDocument: (payload) => dispatch(removeAttachment(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
  dispatchUpdateAttachments: (payload) => dispatch(updateAttachments(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageDocReviewScreen);
