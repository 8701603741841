import React from 'react';
import { Pressable } from 'react-native';
import T from 'prop-types';

import { ConditionalRender, H5 } from '../../../base_ui';
import {
  IconWrapper,
  RowContainer,
  SelectedWrapper,
  StyledP2,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const ArrowIcon = iconDictionary('arrowRightCarbonS');

const ClaimRow = ({
  handlePress,
  hasMultipleClaims,
  label,
  selected,
}) => (
  <Pressable disabled={!hasMultipleClaims} onPress={handlePress}>
    <RowContainer>
      <H5>{label}</H5>
      <SelectedWrapper>
        <StyledP2>{selected}</StyledP2>
        <IconWrapper>
          <ConditionalRender
            Component={ArrowIcon}
            shouldRender={hasMultipleClaims}
          />
        </IconWrapper>
      </SelectedWrapper>
    </RowContainer>
  </Pressable>
);

ClaimRow.propTypes = {
  handlePress: T.func.isRequired,
  hasMultipleClaims: T.bool.isRequired,
  label: T.string.isRequired,
  selected: T.string.isRequired,
};

export default ClaimRow;
