import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

import {
  Disclosure,
  EditAmount,
  EditSource,
  Review,
  SetupOverview,
} from '../../../../containers/Payments/Autopay';

const Stack = createStackNavigator();

const AutopayPreConfirmationStack = () => (
  <Stack.Navigator
    initialRouteName="AutopaySetupOverview"
    screenOptions={{
      cardStyle: { ...Platform.select({ web: { height: '-webkit-fill-available' } }) },
      headerShown: false,
    }}
  >
    <Stack.Screen component={Disclosure} name="AutopayDisclosure" />
    <Stack.Screen component={EditAmount} name="AutopayAmount" />
    <Stack.Screen component={EditSource} name="AutopaySource" />
    <Stack.Screen component={Review} name="AutopayReview" />
    <Stack.Screen component={SetupOverview} name="AutopaySetupOverview" />
  </Stack.Navigator>
);

export default AutopayPreConfirmationStack;
