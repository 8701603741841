/* eslint-disable object-curly-newline */
/*
 * Change Email Shared Screen
 */

import React, { useCallback, useRef } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeInputError,
  resetInputErrors,
  resetState,
  resetStatus,
} from '@dmi/shared/redux/Register/ChangeEmail/actions';
import makeSelectChangeEmail, {
  getChangeEmailError,
} from '@dmi/shared/redux/Register/ChangeEmail/selectors';
import { handleNext } from '@dmi/shared/redux/Register/Shared/helpers';

import { KeyboardAvoidingScrollView } from '../../../components/base_ui';
import SharedForm from '../../../components/Register/SharedForm';

const ChangeEmailSharedView = ({
  bannerError,
  dispatchChangeEmail,
  dispatchChangeInput,
  dispatchChangeInputError,
  dispatchResetInputErrors,
  dispatchResetState,
  dispatchResetStatus,
  dispatchVerifyRecaptcha,
  formErrors,
  formValues,
  handleNextStep,
  navigation,
  status,
  token,
  viewCardProps,
  ViewFormInput,
}) => {
  const scrollViewRef = useRef();

  const handleNextClick = ({
    field,
    form,
    formValues: values,
    handleSubmitStep,
  }) => {
    handleNext({
      dispatchError: dispatchChangeInputError,
      dispatchNext: handleNextStep,
      dispatchResetInputErrors,
      field,
      form,
      formValues: values,
      handleSubmitStep,
    });
  };

  const handleScrollToBanner = useCallback(() => {
    scrollViewRef.current.scrollTo({ animated: true, x: 0, y: 0 });
  }, [scrollViewRef]);

  const formInputProps = {
    dispatchChangeEmail,
    dispatchChangeInput,
    dispatchResetState,
    dispatchResetStatus,
    dispatchVerifyRecaptcha,
    formErrors,
    formValues,
    handleNextClick,
    navigation,
    status,
    token,
  };

  return (
    <KeyboardAvoidingScrollView
      bannerError={bannerError}
      scrollViewRef={scrollViewRef}
    >
      <SharedForm
        bannerError={bannerError}
        formInputProps={formInputProps}
        handleScrollToBanner={handleScrollToBanner}
        navigation={navigation}
        viewCardProps={viewCardProps}
        ViewFormInput={ViewFormInput}
      />
    </KeyboardAvoidingScrollView>
  );
};

ChangeEmailSharedView.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeEmail: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeInputError: T.func.isRequired,
  dispatchResetInputErrors: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchResetStatus: T.func.isRequired,
  dispatchVerifyRecaptcha: T.func,
  formErrors: T.object,
  formValues: T.object,
  handleNextStep: T.func.isRequired,
  navigation: T.object.isRequired,
  status: T.object.isRequired,
  token: T.string,
  viewCardProps: T.object.isRequired,
  ViewFormInput: T.elementType.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  bannerError: getChangeEmailError('bannerError'),
  status: makeSelectChangeEmail('status'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeInputError: (payload) => dispatch(changeInputError(payload)),
  dispatchResetInputErrors: (payload) => dispatch(resetInputErrors(payload)),
  dispatchResetState: () => dispatch(resetState()),
  dispatchResetStatus: () => dispatch(resetStatus()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ChangeEmailSharedView);
