import React from 'react';
import T from 'prop-types';

import {
  MODAL_NYC_LANGUAGE_PREFERENCE_CONFIRMATION_CONTENT,
  MODAL_NYC_LANGUAGE_PREFERENCE_ERROR_CONTENT,
} from '@dmi/shared/redux/Main/constants';

import { LargePrimaryButton } from '../../base_ui';
import {
  ButtonContainer,
  FlexContainer,
  Heading,
  IllustrationWrapper,
  Text,
} from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';

const Illustration = iconDictionary('magnifyingGlass');

const ConfirmationView = ({ handleCloseModalView, requestStatus }) => {
  const { heading, text } = requestStatus === 'succeeded'
    ? MODAL_NYC_LANGUAGE_PREFERENCE_CONFIRMATION_CONTENT
    : MODAL_NYC_LANGUAGE_PREFERENCE_ERROR_CONTENT;

  return (
    <FlexContainer>
      <IllustrationWrapper>
        {Illustration}
      </IllustrationWrapper>
      <Heading>{heading}</Heading>
      <Text>{text}</Text>
      <ButtonContainer>
        <LargePrimaryButton
          label="Done"
          onPress={handleCloseModalView}
        />
      </ButtonContainer>
    </FlexContainer>
  );
};

ConfirmationView.propTypes = {
  handleCloseModalView: T.func.isRequired,
  requestStatus: T.string.isRequired,
};

export default ConfirmationView;
