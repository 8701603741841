/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavDocumentsDefaultLIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M20.2601 4.99993L16.1801 1.09993C16.0594 0.990999 15.9027 0.930463 15.7401 0.929932H4.74012C4.57477 0.932557 4.41708 1.00009 4.30109 1.11795C4.1851 1.23582 4.1201 1.39456 4.12012 1.55993V22.5999C4.1201 22.7653 4.1851 22.924 4.30109 23.0419C4.41708 23.1598 4.57477 23.2273 4.74012 23.2299H19.8301C19.9955 23.2273 20.1532 23.1598 20.2691 23.0419C20.3851 22.924 20.4501 22.7653 20.4501 22.5999V5.39993C20.4364 5.24824 20.369 5.10641 20.2601 4.99993ZM18.7701 5.30993H15.6901V2.31993L18.7701 5.30993ZM5.32012 21.9999V2.12993H14.6901V5.75993C14.6901 5.89254 14.7428 6.01972 14.8366 6.11349C14.9303 6.20725 15.0575 6.25993 15.1901 6.25993H19.1901V21.9999H5.32012Z" fill="black" />
      <Path d="M15.6299 8.40991H8.62988C8.49727 8.40991 8.3701 8.46259 8.27633 8.55636C8.18256 8.65013 8.12988 8.7773 8.12988 8.90991C8.12988 9.04252 8.18256 9.1697 8.27633 9.26347C8.3701 9.35723 8.49727 9.40991 8.62988 9.40991H15.6299C15.7625 9.40991 15.8897 9.35723 15.9834 9.26347C16.0772 9.1697 16.1299 9.04252 16.1299 8.90991C16.1299 8.7773 16.0772 8.65013 15.9834 8.55636C15.8897 8.46259 15.7625 8.40991 15.6299 8.40991Z" fill="black" />
      <Path d="M15.6299 12.1899H8.62988C8.49727 12.1899 8.3701 12.2426 8.27633 12.3364C8.18256 12.4302 8.12988 12.5573 8.12988 12.6899C8.12988 12.8225 8.18256 12.9497 8.27633 13.0435C8.3701 13.1373 8.49727 13.1899 8.62988 13.1899H15.6299C15.7617 13.1874 15.8874 13.1339 15.9806 13.0407C16.0738 12.9474 16.1273 12.8217 16.1299 12.6899C16.1299 12.6243 16.117 12.5593 16.0918 12.4986C16.0667 12.4379 16.0299 12.3828 15.9834 12.3364C15.937 12.29 15.8819 12.2531 15.8212 12.228C15.7606 12.2029 15.6955 12.1899 15.6299 12.1899Z" fill="black" />
      <Path d="M15.6299 16H8.62988C8.49727 16 8.3701 16.0527 8.27633 16.1464C8.18256 16.2402 8.12988 16.3674 8.12988 16.5C8.12988 16.6326 8.18256 16.7598 8.27633 16.8536C8.3701 16.9473 8.49727 17 8.62988 17H15.6299C15.7617 16.9974 15.8874 16.9439 15.9806 16.8507C16.0738 16.7575 16.1273 16.6318 16.1299 16.5C16.1299 16.4343 16.117 16.3693 16.0918 16.3087C16.0667 16.248 16.0299 16.1929 15.9834 16.1464C15.937 16.1 15.8819 16.0632 15.8212 16.0381C15.7606 16.0129 15.6955 16 15.6299 16Z" fill="black" />
    </Svg>
  );
}
