import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeSelectedClaimNumber,
  changeSelectedMessageId,
  updateClaimNumbersRead,
} from '@dmi/shared/redux/LossDraft/actions';
import {
  selectClaimEventsAndMessages,
  selectRequestStatus,
  selectServiceError,
} from '@dmi/shared/redux/LossDraft/selectors';

import MessagesComponent from '../../../components/LossDraft/Messages';

const Messages = ({
  dispatchChangeSelectedClaimNumber,
  dispatchChangeSelectedMessageId,
  dispatchUpdateClaimNumbersRead,
  fetchMessagesServiceError,
  messages,
  navigation,
  requestStatusFetchMessages,
}) => (
  <MessagesComponent
    dispatchChangeSelectedClaimNumber={dispatchChangeSelectedClaimNumber}
    dispatchChangeSelectedMessageId={dispatchChangeSelectedMessageId}
    dispatchUpdateClaimNumbersRead={dispatchUpdateClaimNumbersRead}
    fetchMessagesServiceError={fetchMessagesServiceError}
    messages={messages}
    navigation={navigation}
    requestStatusFetchMessages={requestStatusFetchMessages}
  />
);

Messages.propTypes = {
  dispatchChangeSelectedClaimNumber: T.func.isRequired,
  dispatchChangeSelectedMessageId: T.func.isRequired,
  dispatchUpdateClaimNumbersRead: T.func.isRequired,
  fetchMessagesServiceError: T.oneOfType([T.bool, T.string]).isRequired,
  messages: T.array.isRequired,
  navigation: T.object.isRequired,
  requestStatusFetchMessages: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: LossDraft
   */
  fetchMessagesServiceError: selectServiceError('fetchMessages'),
  messages: selectClaimEventsAndMessages(),
  requestStatusFetchMessages: selectRequestStatus('fetchMessages'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: LossDraft
   */
  dispatchChangeSelectedClaimNumber: (payload) => dispatch(changeSelectedClaimNumber(payload)),
  dispatchChangeSelectedMessageId: (payload) => dispatch(changeSelectedMessageId(payload)),
  dispatchUpdateClaimNumbersRead: (payload) => dispatch(updateClaimNumbersRead(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Messages);
