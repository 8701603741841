/**
 * CustomKeyboardAvoidingView (for non-iOS)
 *
 * @description This is just a View wrapper with 'flex: 1', since Android and Web natively handle
 * keeping a focused Input in view above the keyboard fairly well. They each have their own small
 * issues (eg, the Android keyboard also gets pretty flush with the bottom of an Input, but it never
 * seems to partially cover it like iOS sometimes does). But any minor improvements would require
 * different logic than the iOS version (eg, Android doesn't produce the keyboardWillShow/Hide
 * events).
 */

import React from 'react';
import T from 'prop-types';

import { FlexView } from './styledComponents';

const CustomKeyboardAvoidingView = ({ children, style }) => (
  <FlexView style={{ ...style }}>
    {children}
  </FlexView>
);

CustomKeyboardAvoidingView.propTypes = {
  children: T.node,
  style: T.object,
};

export default CustomKeyboardAvoidingView;
