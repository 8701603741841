import styled from 'styled-components/native';

import { moderateScale } from '../../../../../../utils/scaleHelpers';
import { BorderlessButton, P2 } from '../../../../../base_ui';

export const InlineLink = styled(P2)`
  color: ${({ theme: { colors: { linkText, primary } } }) => linkText || primary};
`;

export const ListAdornment = styled(P2)`
  padding-left: ${moderateScale(20)}px;
  padding-right: ${moderateScale(5)}px;
`;

export const ListContainer = styled.View`
  padding-bottom: ${moderateScale(10)}px;
`;

export const ListItemContainer = styled.View`
  flex-direction: row;
`;

export const ParagraphContainer = styled(P2)`
  flex-direction: row;
  padding-bottom: ${({ $noMargin }) => $noMargin ? '0' : `${moderateScale(10)}px`};
`;

export const StyledP2 = styled(P2)`
  align-items: baseline;
  flex-shrink: 1;
  font-family: ${({ $isBold, theme: { fontFamilies: { secondaryFont, secondaryFontBold } } }) =>
    $isBold ? secondaryFontBold : secondaryFont};
  font-style: ${({ $isItalic }) => $isItalic ? 'italic' : 'normal'};
  text-decoration: ${({ $isUnderline }) => $isUnderline ? 'underline' : 'none'};
`;

export const StyledMessageTextButton = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(12)}px;
`;

export const StyledTextButton = styled(BorderlessButton).attrs(({
  theme: { colors: { primary }, fontFamilies: { secondaryFont }, fontSizes: { f4 } },
  titleStyle,
}) => ({
  titleStyle: [...titleStyle, {
    color: primary,
    fontFamily: secondaryFont,
    fontSize: f4,
    paddingVertical: 5,
  }],
}))`
`;
