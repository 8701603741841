/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function InterimHeader(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(100)} viewBox="0 0 85 85" width={moderateScale(100)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M67.72,18.71a15,15,0,0,0-.5-1.7,16.68,16.68,0,0,0-32.07,2.17L6.61,27.76a1.28,1.28,0,0,0-.75.6L1.09,36.71A1.19,1.19,0,0,0,1,37a30.9,30.9,0,0,1,7.2-6.48L37.6,21.81S39.93,8.24,52.81,9.15A17.69,17.69,0,0,1,67.72,18.71Z" fill="#dbe2e8" />
      <Path d="M10,26.75l-3.38,1a1.28,1.28,0,0,0-.75.6L1.09,36.71a1.3,1.3,0,0,0,.56,1.81l8.19,4a1.19,1.19,0,0,0,1.6-.57l1.75-3.74a1.19,1.19,0,0,1,1.69-.52l3.39,2A1.18,1.18,0,0,0,20,39.22l1.69-3.66A1.18,1.18,0,0,1,23.33,35l3.35,2a1.19,1.19,0,0,0,1.7-.56l1.43-3.37a1.19,1.19,0,0,1,1.74-.53l3.13,2.05a2.88,2.88,0,0,0,2.44.34l2.4-.76A16.68,16.68,0,0,0,68.16,23.32" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.29" />
      <Path d="M55.19,6.32a16.6,16.6,0,0,0-20,12.86L12.26,26.05" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.29" />
      <Path d="M68.08,20.76A16.8,16.8,0,0,0,67.22,17,15.88,15.88,0,0,0,57.7,7.1" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.29" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" x1="12.13" x2="36.52" y1="33.23" y2="25.91" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" x1="6.17" x2="9.6" y1="35.03" y2="34" />
      <Circle cx="56.25" cy="20.39" fill="none" r="2.39" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.37" x1="48.21" x2="48.22" y1="54.85" y2="54.73" />
      <Polygon fill="#fff" points="60.9 77.96 77.37 80.22 80.29 58.92 83.9 59.41 84 58.75 81.44 56.61 80.69 56.01 66.22 43.16 66.06 43.13 48.65 51.61 47.77 51.99 44.73 53.35 44.65 54.02 48.25 54.52 45.33 75.83 60.1 77.85 60.9 77.96" />
      <Polyline fill={svgHighlight} points="57.07 76.84 58.95 63.34 67.94 64.58 66.07 78.21" />
      <Polyline fill="none" points="78.76 70.05 80.29 58.92 83.9 59.41 84 58.75 81.44 56.61 80.69 56.01 66.22 43.16 66.06 43.13 48.65 51.61 47.77 51.99 44.73 53.35 44.65 54.02 48.25 54.52 45.33 75.83 60.1 77.85 60.9 77.96 77.37 80.22 78.33 72.9" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.37" />
      <Polyline fill="none" points="57.3 75.37 58.95 63.34 67.94 64.58 66.07 78.21" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" />
      <Circle cx="64.98" cy="51.6" fill="#fff" r="2.46" />
      <Circle cx="64.98" cy="51.6" fill="none" r="2.46" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" />
      <Path d="M46.23,40.51a16.3,16.3,0,0,0,6.66,8.92" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" />
      <Path d="M64,51.93a16.25,16.25,0,1,0-8.14-31.22" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.82" />
    </Svg>
  );
}
