/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function HardshipLighthouse() {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(150)} width={moderateScale(327)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 327 150">
      <Path className="cls-1" fill="#e6ebef" d="M164.37,83.18c8.36-4,15.79-8,27.36-4.26s21.41,19.25,35.62,22.8,33.78-4.93,33.78-4.93,12.95-6,27.38,3.32,22.1,17.53,22.1,17.53H165.29" />
      <Polygon className="cls-2" fill="#bac2ca" points="169.26 81.1 172.13 108.49 165.16 108.49 163.13 83.51 169.26 81.1" />
      <Path className="cls-1" fill="#e6ebef" d="M73.46,140.33H59.89" />
      <Path className="cls-1" fill="#e6ebef" d="M74,140.33h-.52" />
      <Path className="cls-1" fill="#e6ebef" d="M59.89,140.32c-25.18,0-43.12,0-43.1-.07s32.6-5.09,53-22c17.4-14.4,45.91-20.35,67.56-7,0,0,30.39,16,45.92,16.82s21.78,1.44,26.73,4.31a31.5,31.5,0,0,1,8.61,8.09s-85-.06-144.65-.12" />
      <Path className="cls-2" fill="#bac2ca" d="M94,106.21s31.57-5.87,44.55,11.28l.39-5.35S120.55,98.45,94,106.21Z" />
      <Path className="cls-2" fill="#bac2ca" d="M195.56,130.73s10.37,2.1,14.33,9.44h8.42s-4.84-9.69-22.65-11Z" />
      <Polygon className="cls-3" fill="#fff" points="161.57 128.77 133.81 128.77 134.47 123.29 137.06 121.53 161.57 121.53 161.57 128.77" />
      <Rect className="cls-3" fill="#fff" x="160.3" y="107.14" width="11.15" height="25.89" />
      <Polygon className="cls-1" fill="#e6ebef" points="138.91 34.53 164.24 34.53 160.43 36.95 142.82 36.95 138.91 34.53" />
      <Rect className="cls-2" fill="#bac2ca" x="144.51" y="27.36" width="14.12" height="5.51" />
      <Polygon className="cls-2" fill="#bac2ca" points="142.42 14.8 160.93 14.8 151.57 9.99 142.42 14.8" />
      <Rect className="cls-1" fill="#e6ebef" x="144.35" y="17.18" width="14.16" height="9.93" />
      <Polygon className="cls-4" fill="#fff" opacity="0.8" points="154.07 27.16 150.03 27.55 154.51 17.16 158.54 16.77 154.07 27.16" />
      <Polygon className="cls-4" fill="#fff" opacity="0.8" points="144.29 23.15 144.22 20.84 144.32 17.16 147.19 16.77 144.29 23.15" />
      <Rect className="cls-2" fill="#bac2ca" x="144.32" y="17.35" width="14.15" height="0.8" />
      <Rect className="cls-2" fill="#bac2ca" x="142.56" y="37.27" width="18.22" height="1.96" />
      <Polygon className="cls-5" fill={svgHighlight} points="164.85 91.89 139.17 91.89 140 76.83 163.62 76.83 164.85 91.89" />
      <Polygon className="cls-5" fill={svgHighlight} points="162.54 61.8 141.02 61.8 141.85 46.74 161.32 46.74 162.54 61.8" />
      <Polygon className="cls-5" fill={svgHighlight} points="138.16 106.92 165.19 106.92 160.2 115.04 160.2 121.76 137.01 121.76 138.16 106.92" />
      <Rect className="cls-1" fill="#e6ebef" x="171.29" y="113.32" width="24.26" height="19.47" />
      <Polygon className="cls-2" fill="#bac2ca" points="171.48 117.88 172.98 119.51 195.09 119.51 195.09 117.65 173.34 117.65 171.35 115.37 171.48 117.88" />
      <Polygon className="cls-2" fill="#bac2ca" points="165.56 106.39 173.58 117.4 197.3 117.4 189.72 106.47 165.56 106.39" />
      <Path fill="#000" d="M197.91,117.8h-24.5a.38.38,0,0,1-.3-.15l-8-10.53a.37.37,0,0,1,.3-.6h9.33a.38.38,0,0,1,0,.75h-8.58l7.46,9.78h23.56l-7.46-9.78H177a.38.38,0,0,1,0-.75h12.91a.38.38,0,0,1,.3.15l8,10.53a.37.37,0,0,1,0,.39A.39.39,0,0,1,197.91,117.8Z" />
      <Path fill="#000" d="M158,117.9a.36.36,0,0,1-.22-.07.37.37,0,0,1-.09-.52l7.37-10.63a.37.37,0,0,1,.52-.09.38.38,0,0,1,.1.52l-7.37,10.63A.4.4,0,0,1,158,117.9Z" />
      <Path fill="#000" d="M195.61,133.45H160.1a.37.37,0,0,1-.37-.37v-18.3a.37.37,0,0,1,.37-.37.38.38,0,0,1,.38.37V132.7h34.76V117.57a.36.36,0,0,1,.37-.37.37.37,0,0,1,.38.37v15.51A.38.38,0,0,1,195.61,133.45Z" />
      <Path fill="#000" d="M133.84,129.05h-.06a.37.37,0,0,1-.32-.42l.79-5.62a.36.36,0,0,1,.17-.27l2.46-1.48,5.19-83.79-3.81-2.23a.37.37,0,0,1-.19-.33V33.08a.38.38,0,0,1,.38-.38h26a.37.37,0,0,1,.37.38v1.76a.36.36,0,0,1-.18.32l-3.55,2.16,4.78,69a.38.38,0,0,1-.35.4.37.37,0,0,1-.4-.34l-4.8-69.25a.38.38,0,0,1,.18-.35l3.57-2.17V33.45H138.82v1.24l3.83,2.24a.36.36,0,0,1,.18.34l-5.21,84.22a.4.4,0,0,1-.18.3L135,123.28l-.75,5.45A.38.38,0,0,1,133.84,129.05Z" />
      <Path fill="#000" d="M140,33.12a.37.37,0,0,1-.38-.37V27.37A.38.38,0,0,1,140,27h22.89a.38.38,0,0,1,.38.38v5.32a.38.38,0,0,1-.38.37.37.37,0,0,1-.37-.37V27.74H140.32v5A.36.36,0,0,1,140,33.12Z" />
      <Path fill="#000" d="M144.25,33.09a.38.38,0,0,1-.37-.37V17.3a.38.38,0,1,1,.75,0V32.72A.38.38,0,0,1,144.25,33.09Z" />
      <Path fill="#000" d="M158.68,33.09a.38.38,0,0,1-.37-.37V17.3a.38.38,0,1,1,.75,0V32.72A.38.38,0,0,1,158.68,33.09Z" />
      <Path fill="#000" d="M158.73,17.53a.38.38,0,0,1-.19-.7l1.9-1.13v-.8l-8.82-4.79-8.79,4.66v1.08l1.47,1a.37.37,0,0,1-.42.62l-1.63-1.11a.38.38,0,0,1-.17-.31v-1.5a.38.38,0,0,1,.2-.33l9.17-4.86a.39.39,0,0,1,.35,0l9.2,5a.39.39,0,0,1,.19.33v1.24a.39.39,0,0,1-.18.33l-2.09,1.23A.33.33,0,0,1,158.73,17.53Z" />
      <Path fill="#000" d="M171.4,131.05a.37.37,0,0,1-.38-.37V117.57a.37.37,0,0,1,.38-.37.36.36,0,0,1,.37.37v13.11A.36.36,0,0,1,171.4,131.05Z" />
      <Path fill="#000" d="M155.7,122H137.55a.38.38,0,0,1-.37-.38.37.37,0,0,1,.37-.37H155.7a.36.36,0,0,1,.37.37A.37.37,0,0,1,155.7,122Z" />
      <Path fill="#000" d="M162.79,107.18h-24.5a.37.37,0,0,1-.38-.37.38.38,0,0,1,.38-.38h24.5a.38.38,0,0,1,.38.38A.37.37,0,0,1,162.79,107.18Z" />
      <Path fill="#000" d="M164.21,92.17H140.68a.38.38,0,0,1,0-.75h23.53a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M160.35,77.3h-20a.38.38,0,0,1,0-.75h20a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M162.2,62.28H141a.38.38,0,0,1,0-.75H162.2a.38.38,0,1,1,0,.75Z" />
      <Path fill="#000" d="M161.13,47.46H143.81a.38.38,0,0,1,0-.75h17.32a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M159.91,129.15H121.46a.38.38,0,0,1-.38-.38.37.37,0,0,1,.38-.37h38.45a.37.37,0,0,1,.37.37A.38.38,0,0,1,159.91,129.15Z" />
      <Path fill="#000" d="M261.77,124.71H246.29a.38.38,0,1,1,0-.75h15.48a.38.38,0,1,1,0,.75Z" />
      <Path fill="#000" d="M267.85,124.71h-3a.38.38,0,0,1,0-.75h3a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M283,128h-3a.38.38,0,0,1,0-.75h3a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M240.86,131.44h-3a.38.38,0,0,1,0-.75h3a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M252,131.44h-8.87a.38.38,0,0,1,0-.75H252a.38.38,0,1,1,0,.75Z" />
      <Path fill="#000" d="M240.94,124.71H211.69a.38.38,0,1,1,0-.75h29.25a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M257.31,128.08H228.06a.38.38,0,1,1,0-.75h29.25a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M273.74,128.08h-12a.38.38,0,0,1,0-.75h12a.38.38,0,1,1,0,.75Z" />
      <Path fill="#000" d="M207.25,118h-3.62a.38.38,0,0,1,0-.75h3.62a.38.38,0,1,1,0,.75Z" />
      <Path fill="#000" d="M310.61,118H210.48a.38.38,0,0,1,0-.75h99.24a131.25,131.25,0,0,0-21.42-16.83c-14.07-9.05-26.88-3.36-27-3.3s-19.88,8.49-34,5c-7.79-2-14.2-7.37-20.4-12.61-5.15-4.37-10-8.48-15.24-10.19-10.88-3.56-17.07-.57-24.91,3.2l-1.16.56a.38.38,0,0,1-.5-.17.37.37,0,0,1,.18-.5l1.16-.56c8-3.85,14.29-6.89,25.46-3.24,5.36,1.75,10.28,5.91,15.5,10.32,6.13,5.19,12.47,10.56,20.1,12.46,13.91,3.48,33.35-4.82,33.54-4.9s13.29-5.94,27.73,3.35,22.1,17.5,22.18,17.58a.38.38,0,0,1,.07.41A.39.39,0,0,1,310.61,118Z" />
      <Path fill="#000" d="M64.07,140.69H16.39a.38.38,0,1,1,0-.75H64.07a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M73.35,140.69H69.81a.38.38,0,1,1,0-.75h3.54a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M218.76,140.69H77a.38.38,0,1,1,0-.75H218c-8.08-10.84-21.94-10.6-22.08-10.6a.39.39,0,0,1-.39-.36.39.39,0,0,1,.37-.39c.14,0,14.9-.26,23.14,11.51a.39.39,0,0,1,0,.39A.4.4,0,0,1,218.76,140.69Z" />
      <Path fill="#000" d="M16.79,140.62a.37.37,0,0,1-.06-.74c.32,0,32.74-5.27,52.84-21.9,16.84-13.93,45.6-20.84,68-7.07a.37.37,0,0,1-.39.64c-22.1-13.58-50.5-6.75-67.13,7-20.26,16.76-52.88,22-53.21,22.06Z" />
      <Path className="cls-1" fill="#e6ebef" d="M251.81,59.21A3.44,3.44,0,0,0,255,54.47c-1.93-4.8-6-11.63-13.81-12.44a13.47,13.47,0,0,0-8.73,2.56.56.56,0,0,1-.68-.06,9.85,9.85,0,0,0-6.69-2.5c-5,0-9,3.41-9,7.62h0a.55.55,0,0,1-.61.55c-.24,0-.49,0-.74,0a7,7,0,0,0-6.59,4,.55.55,0,0,1-.73.3,6.25,6.25,0,0,0-2.72-.56,5.73,5.73,0,0,0-4.61,2.58,1.7,1.7,0,0,0,1.38,2.71Z" />
      <Path className="cls-1" fill="#e6ebef" d="M41.75,79.6a1.94,1.94,0,0,1-1.47-3.21c1.82-2.12,5.47-3.57,9.68-3.57a16.4,16.4,0,0,1,2,.12,1.19,1.19,0,0,0,1.32-1.21h0c0-3.67,4.64-6.64,10.36-6.64a14.93,14.93,0,0,1,4.44.65,1.16,1.16,0,0,0,1.46-.83c1.2-5.45,7-9.58,14.06-9.58,7.9,0,14.3,5.22,14.3,11.65,0,.23,0,.46,0,.69a6.53,6.53,0,0,1,7.32,4.51,1.16,1.16,0,0,0,1.42.77,6.38,6.38,0,0,1,1.81-.25,6.61,6.61,0,0,1,5.19,2.5,2.72,2.72,0,0,1-2.13,4.41Z" />
      <Path fill="#000" d="M162.74,30.54H141.12a.38.38,0,0,1,0-.75h21.62a.38.38,0,1,1,0,.75Z" />
      <Path fill="#000" d="M148.63,33.13a.37.37,0,0,1-.37-.37V27.55a.37.37,0,0,1,.37-.38.38.38,0,0,1,.38.38v5.21A.38.38,0,0,1,148.63,33.13Z" />
      <Path fill="#000" d="M153.86,33.13a.38.38,0,0,1-.37-.37V28.63a.38.38,0,0,1,.75,0v4.13A.38.38,0,0,1,153.86,33.13Z" />
      <Path fill="#000" d="M160.66,37.52h-6.82a.37.37,0,0,1-.37-.38.36.36,0,0,1,.37-.37h6.82a.37.37,0,0,1,.37.37A.38.38,0,0,1,160.66,37.52Z" />
      <Path fill="#000" d="M152,37.52h-9.39a.37.37,0,0,1-.37-.38.36.36,0,0,1,.37-.37H152a.37.37,0,0,1,.38.37A.38.38,0,0,1,152,37.52Z" />
      <Path fill="#000" d="M158.31,17.47h-13.6a.38.38,0,1,1,0-.75h13.6a.38.38,0,0,1,0,.75Z" />
      <Path fill="#000" d="M160.74,15H146.82a.37.37,0,0,1-.38-.37.38.38,0,0,1,.38-.38h13.92a.38.38,0,0,1,.37.38A.37.37,0,0,1,160.74,15Z" />
      <Path fill="#000" d="M149,26.66a.38.38,0,0,1-.37-.38V18.17a.38.38,0,1,1,.75,0v8.11A.38.38,0,0,1,149,26.66Z" />
      <Path fill="#000" d="M153.85,25.66a.37.37,0,0,1-.37-.37V17.14a.38.38,0,1,1,.75,0v8.15A.37.37,0,0,1,153.85,25.66Z" />
      <Path fill="#000" d="M119.09,129.15h-2.15a.38.38,0,0,1-.38-.38.37.37,0,0,1,.38-.37h2.15a.37.37,0,0,1,.37.37A.38.38,0,0,1,119.09,129.15Z" />
      <Rect className="cls-2" fill="#bac2ca" x="177.18" y="121.67" width="3.19" height="4.97" />
      <Path fill="#000" d="M180.56,127.06h-3.48a.38.38,0,0,1-.38-.38v-5.05a.38.38,0,0,1,.38-.38h3.48a.38.38,0,0,1,.37.38v5.05A.38.38,0,0,1,180.56,127.06Zm-3.11-.75h2.73V122h-2.73Z" />
      <Rect className="cls-2" fill="#bac2ca" x="186.51" y="121.67" width="3.19" height="4.97" />
      <Path fill="#000" d="M189.88,127.06h-3.47a.38.38,0,0,1-.38-.38v-5.05a.38.38,0,0,1,.38-.38h3.47a.38.38,0,0,1,.38.38v5.05A.38.38,0,0,1,189.88,127.06Zm-3.1-.75h2.73V122h-2.73Z" />
      <Path fill="#000" d="M87.14,112.8a.38.38,0,0,1-.35-.24.37.37,0,0,1,.21-.48c7.32-2.83,18.29-6,29-4.75a.37.37,0,1,1-.08.74c-10.54-1.18-21.39,1.91-28.63,4.7A.31.31,0,0,1,87.14,112.8Z" />
      <Path fill="#000" d="M79.9,116a.37.37,0,0,1-.18-.7s1.77-1,4.66-2.18a.37.37,0,1,1,.29.69C81.82,115,80.09,116,80.07,116A.33.33,0,0,1,79.9,116Z" />
      <Path fill="#000" d="M288.45,104.35a.42.42,0,0,1-.21-.06c-13.8-9.08-25.54-3.89-25.66-3.84a.37.37,0,0,1-.49-.19.36.36,0,0,1,.18-.49c.12-.06,12.24-5.41,26.38,3.89a.38.38,0,0,1-.2.69Z" />
      <Path fill="#000" d="M292.43,107.27a.44.44,0,0,1-.22-.07l-1.79-1.28a.37.37,0,1,1,.43-.61l1.79,1.28a.37.37,0,0,1,.09.52A.38.38,0,0,1,292.43,107.27Z" />
    </Svg>
  );
}
