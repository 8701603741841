import React from 'react';
import T from 'prop-types';
import { Modal } from 'react-native';

import ChildComponent from './ChildComponent';

const ModalDialog = ({
  children,
  handleCloseModal,
  visible,
  ...restProps
}) => (
  <Modal
    animationType="fade"
    onRequestClose={handleCloseModal}
    transparent
    useNativeDriver
    visible={visible}
    {...restProps}
  >
    <ChildComponent handleCloseModal={handleCloseModal} {...restProps}>
      {children}
    </ChildComponent>
  </Modal>
);

ModalDialog.propTypes = {
  children: T.node.isRequired,
  handleCloseModal: T.func,
  visible: T.bool.isRequired,
};

export default ModalDialog;
