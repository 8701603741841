import React from 'react';
// import { Platform } from 'react-native';
import T from 'prop-types';
import * as WebBrowser from 'expo-web-browser';
import { FormattedMessage } from 'react-intl';

import { CHAPTER_SEVEN_BANKRUPTCY_DICT } from '@dmi/shared/redux/Main/constants';
import { INTL_IDS } from '@dmi/shared/redux/Login/messages';
import { PREAUTH_GENERIC_FEE_STATE } from '@dmi/shared/redux/Fees/constants';

import { BaseInternalLink, ConditionalRender, P3 } from '../../base_ui';
import FooterIcons from '../../FooterIcons';
import {
  ChapterSevenBankruptcyContainer,
  ChapterSevenHeader,
  ChapterSevenText,
  DisclosureButton,
  DisclosuresContainer,
  DisclosuresHeading,
  FooterLinkContainer,
  LoginFooterContainer,
  StyledBaseExternalLink,
} from './styledComponents';

const {
  header: chapterSevenHeader,
  text: chapterSevenText,
} = CHAPTER_SEVEN_BANKRUPTCY_DICT;

const LoginFooter = ({
  footerIconKeys,
  handleOpenDrawer,
  preauthFees,
}) => {
  const handleFeeSchedule = ({ href, screen }) => {
    if (href) {
      WebBrowser.openBrowserAsync(href);
    } else {
      handleOpenDrawer(screen);
    }
  };

  return (
    <LoginFooterContainer>
      {/* <ConditionalRender
        Component={(
          <FooterLinkContainer>
            <P3>Logging in from a new device?   </P3>
            <BaseInternalLink
              label="Set up first"
              path="Welcome"
              size="small"
            />
          </FooterLinkContainer>
        )}
        shouldRender={Platform.OS === 'ios' || Platform.OS === 'android'}
      /> */}
      <FooterLinkContainer>
        <P3>Need Help?   </P3>
        <BaseInternalLink
          label="Contact Us"
          path="ContactUs"
          size="small"
        />
      </FooterLinkContainer>
      <FooterLinkContainer>
        <P3><FormattedMessage id={INTL_IDS.REGISTER_LABEL} />   </P3>
        <BaseInternalLink label="Register" path="Register" size="small" />
      </FooterLinkContainer>
      <FooterLinkContainer>
        <P3>Need to change your email address?   </P3>
        <BaseInternalLink
          label="Update"
          path="ChangeEmail"
          size="small"
        />
      </FooterLinkContainer>
      <DisclosuresContainer>
        <DisclosuresHeading>Disclosures</DisclosuresHeading>
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Foreclosure Prevention Case Escalation Unit"
          onPress={() => handleOpenDrawer('foreclosurePrevention')}
        />
        <StyledBaseExternalLink
          href="https://www.servicedisclosure.com"
          isFooterLink
          label="Loss Mitigation Disclosure for Borrowers"
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Alternatives to Foreclosure"
          onPress={() => handleOpenDrawer('foreclosureAlternatives')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="New York Disclosure Notice"
          onPress={() => handleOpenDrawer('newYorkDisclosure')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Texas Residential Loan Disclosure Notice"
          onPress={() => handleOpenDrawer('texasDisclosure')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Fee Schedule for New York Borrowers"
          onPress={() => handleFeeSchedule({
            href: preauthFees.NY,
            screen: 'feeSchedule',
          })}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Fee Schedule for Hawaii Borrowers"
          onPress={() => handleFeeSchedule({
            href: preauthFees.HI,
            screen: 'feeSchedule',
          })}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Fee Schedule"
          onPress={() => handleFeeSchedule({
            href: preauthFees[PREAUTH_GENERIC_FEE_STATE],
            screen: 'feeSchedule',
          })}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Privacy Policy / CCPA"
          onPress={() => handleOpenDrawer('privacyPolicy')}
        />
      </DisclosuresContainer>
      <DisclosuresContainer>
        <DisclosuresHeading>State Foreclosure Hotlines</DisclosuresHeading>
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Colorado"
          onPress={() => handleOpenDrawer('foreclosureHotlineColorado')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Connecticut"
          onPress={() => handleOpenDrawer('foreclosureHotlineConnecticut')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Illinois"
          onPress={() => handleOpenDrawer('foreclosureHotlineIllinois')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Iowa"
          onPress={() => handleOpenDrawer('foreclosureHotlineIowa')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="New York"
          onPress={() => handleOpenDrawer('foreclosureHotlineNewYork')}
        />
        <DisclosureButton
          isFooterButton
          isPrimary
          label="Washington"
          onPress={() => handleOpenDrawer('foreclosureHotlineWashington')}
        />
      </DisclosuresContainer>

      <ChapterSevenBankruptcyContainer>
        <ChapterSevenHeader>{chapterSevenHeader}</ChapterSevenHeader>
        <ChapterSevenText>{chapterSevenText}</ChapterSevenText>
      </ChapterSevenBankruptcyContainer>
      <ConditionalRender
        Component={FooterIcons}
        propsToPassDown={{ footerIconKeys, isLogin: true }}
        shouldRender={!!footerIconKeys}
      />
    </LoginFooterContainer>
  );
};

LoginFooter.propTypes = {
  footerIconKeys: T.array.isRequired,
  handleOpenDrawer: T.func.isRequired,
  preauthFees: T.object.isRequired,
};

export default LoginFooter;
