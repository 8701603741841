/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable react/jsx-sort-props */
const MegaphoneLg = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Polygon fill="#bac2ca" points="4.5 48.78 8.58 62.02 10.34 61.5 6 48.23 4.5 48.78" />
      <Path fill="#e6ebef" d="M67.17,64.12,51.56,14a3.26,3.26,0,0,0-4.09-2.15h0L65.52,68A3.26,3.26,0,0,0,67.17,64.12Z" />
      <Path fill="#e6ebef" d="M9,49.18A2.07,2.07,0,0,1,10.4,46.6l11.2-3.52-.14-.42a2.08,2.08,0,0,0-2.6-1.35L7.39,44.91A2.07,2.07,0,0,0,6,47.49l4.33,14.34A2.07,2.07,0,0,0,13,63.2l.24-.08Z" />
      <Polyline fill="#e6ebef" points="15.36 65.55 19.61 79.2 25 77.53 23.33 62.77" />
      <Polygon fill="#bac2ca" points="16.11 67.61 23.29 65.42 22.94 62.82 15.53 65.49 16.11 67.61" />
      <Path fill="#bac2ca" d="M57.51,32.54l.56-.17a6.78,6.78,0,0,1,8.5,4.46h0a6.79,6.79,0,0,1-4.46,8.5l-.72.22" />
      <Path fill={svgHighlight} d="M21.79,42.44a36.62,36.62,0,0,0,15.73-9.81c8.67-8.94,8.11-15.09,8.11-15.09L60.24,64.4S57.21,59,45,56.6a36.69,36.69,0,0,0-18.52.86L22.15,43.59" />
      <Line fill={svgHighlight} x1="22.15" y1="43.59" x2="21.79" y2="42.44" />
      <Path fill={svgHighlight} d="M60.24,64.4,56.67,52.94c-1.69-.49-3.52-.95-5.54-1.35A53.57,53.57,0,0,0,25,52.66l1.49,4.8A36.69,36.69,0,0,1,45,56.6C57.21,59,60.24,64.4,60.24,64.4Z" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="60.88" y1="65.25" x2="51.66" y2="35.67" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M66.15,60.83l1,3.29A3.26,3.26,0,0,1,65,68.21h0a3.28,3.28,0,0,1-4.1-2.15l-.29-1" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M45.76,17.38,45.32,16a3.26,3.26,0,0,1,2.15-4.09h0A3.26,3.26,0,0,1,51.56,14l13.85,44.4" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="50.46" y1="31.78" x2="45.9" y2="17.13" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M21.79,42.44a36.62,36.62,0,0,0,15.73-9.81c8.67-8.94,8.11-15.09,8.11-15.09" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M45.76,56.76c11.58,2.5,14.48,7.64,14.48,7.64" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M26.47,57.46A36.08,36.08,0,0,1,43,56.26" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="8.41" y1="55.33" x2="6.03" y2="47.65" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="10.46" y1="61.84" x2="9.13" y2="57.65" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M13.15,63.25a2.14,2.14,0,0,1-2.69-1.41" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="24.78" y1="59.46" x2="13.15" y2="63.09" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M26.19,56.94a2.15,2.15,0,0,1-1.41,2.69" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="22.4" y1="44.7" x2="26.19" y2="56.94" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M6,47.65A2.15,2.15,0,0,1,7.44,45l11.64-3.63a2.16,2.16,0,0,1,2.69,1.41" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M5.84,48l-2.69.84A2.21,2.21,0,0,0,1.7,51.58l3,9.54a2.21,2.21,0,0,0,2.77,1.45l2.89-.88" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M57.51,32.54l.56-.17a6.78,6.78,0,0,1,8.5,4.46h0a6.79,6.79,0,0,1-4.46,8.5l-.72.22" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M13.15,63.25l.25.81a1.62,1.62,0,0,0,2,1.06l8.63-2.69a1.62,1.62,0,0,0,1.06-2l-.25-.81" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" points="15.36 65.55 19.61 79.2 25 77.53 23.33 62.77" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="76.98" y1="33.05" x2="84.13" y2="30.79" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="73" y1="34.29" x2="74.32" y2="33.88" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="75.48" y1="58.55" x2="83.9" y2="62.91" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="60.05" y1="13.78" x2="64" y2="5.16" />
    </Svg>
  );
};

export default MegaphoneLg;
