import styled, { css } from 'styled-components/native';

import {
  H3,
  MultilineTextField,
} from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

const topBorder = css`
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-top-width: ${moderateScale(1)}px;
`;

export const ReplyAttachmentContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const ReplyButtonContainer = styled.View`
  ${topBorder}
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(10)}px ${moderateScale(24)}px;
`;

export const ReplyBoxContainer = styled.View`
  ${topBorder}
  padding-top: ${moderateScale(15)}px;
`;

export const ReplyTitle = styled(H3)`
  margin-left: ${moderateScale(8)}px;
`;

export const ReplyView = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-direction: row;
  margin-bottom: ${moderateScale(8)}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const ReplyTextInput = styled(MultilineTextField)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  height: ${moderateScale(150)}px;
  margin: 0;
  overflow: scroll;
  padding: 0 ${moderateScale(24)}px;
`;
