/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Ellipse,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function DogPlaying(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg data-name="Layer 1" fill="#000" height={moderateScale(120)} id="Layer_1" viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path className="cls-1" d="M161.31,43.29s-3.63-.56-4.72-.66-2.39.07-2.71,1.68,2.29,4.37,5.07,5.88,6.83,1.06,8.23-.07.71-3.38.29-3.87-3.91-3.31-3.91-3.31Z" fill="#dce2e7" />
      <Path className="cls-2" d="M273.15,53.26h0C267.6,19.7,259.8,21.2,259.8,21.2v0s-7.49-1.53-13,32h0a20.65,20.65,0,0,0-.25,3.17c0,9.06,6,16.4,13.45,16.4s13.45-7.34,13.45-16.4A19.47,19.47,0,0,0,273.15,53.26Z" fill="#e6ebef" />
      <Path className="cls-3" d="M273.41,56.43a19.47,19.47,0,0,0-.26-3.17h0C267.6,19.7,259.8,21.2,259.8,21.2v0s.16-.24-.65.15c2,4,7.8,18.49,9.05,32.61,1.05,11.92-3.28,17.44-8.34,18.84H260C267.39,72.83,273.41,65.49,273.41,56.43Z" fill="#bac2ca" />
      <Path d="M260,73.21c-7.62,0-13.83-7.53-13.83-16.78a20.2,20.2,0,0,1,.24-3.09.32.32,0,0,1,0-.14A148.53,148.53,0,0,1,250,36.55a.37.37,0,0,1,.46-.26.39.39,0,0,1,.26.47,145.14,145.14,0,0,0-3.57,16.43.29.29,0,0,1,0,.13,20.58,20.58,0,0,0-.25,3.11c0,8.84,5.87,16,13.08,16S273,65.27,273,56.43a19.36,19.36,0,0,0-.25-3.11v-.08c-4.9-29.5-11.51-31.54-12.72-31.66a.35.35,0,0,1-.27.11.38.38,0,0,1-.25-.09c-.71.06-3.65.85-7,9.51a.39.39,0,0,1-.49.22.38.38,0,0,1-.21-.49c3.65-9.52,7.08-10,7.82-10l0,0a3.35,3.35,0,0,1,2.33.79c2.64,1.83,7.63,8.37,11.47,31.58a.28.28,0,0,1,0,.09,21,21,0,0,1,.24,3.14C273.78,65.68,267.58,73.21,260,73.21Z" />
      <Path d="M259.79,83.17a.36.36,0,0,1-.37-.37V47.66a.37.37,0,0,1,.37-.37.38.38,0,0,1,.38.37V82.8A.37.37,0,0,1,259.79,83.17Z" />
      <Path d="M259.65,59a.41.41,0,0,1-.22-.07l-4.91-3.5a.37.37,0,1,1,.43-.61l4.91,3.49a.38.38,0,0,1,.09.53A.38.38,0,0,1,259.65,59Z" />
      <Path d="M259.91,63.06a.38.38,0,0,1-.3-.16.37.37,0,0,1,.09-.52l4.91-3.5a.37.37,0,0,1,.43.61L260.13,63A.34.34,0,0,1,259.91,63.06Z" />
      <Path className="cls-4" d="M267.22,89.19h0a10.3,10.3,0,0,0-10.1-10.8,9.81,9.81,0,0,0-5.67,1.8,2,2,0,0,1-2.89-1c-2-4.92-7.5-8.46-14-8.46a15.63,15.63,0,0,0-12.26,5.52,3.11,3.11,0,0,1-3.37,1,14.31,14.31,0,0,0-4.33-.65s-11.46.66-12.29,12.5" fill="#fff" />
      <Path className="cls-1" d="M224.68,74.53s9-2.22,13.79.37,5.83,6.48,8.33,7.68,4.72.74,7.12-.83,10.92-.83,12.87,5.55c0,0-1.76-9.16-10.09-8.7s-5.37,3.8-9.26-.92S236.15,66.66,224.68,74.53Z" fill="#dce2e7" />
      <Path d="M267.22,89.56a.34.34,0,0,1-.26-.11.36.36,0,0,1-.1-.35A10.18,10.18,0,0,0,264,81.7a9.5,9.5,0,0,0-12.32-1.22,2.21,2.21,0,0,1-1.9.31,2.46,2.46,0,0,1-1.56-1.43c-2-4.92-7.47-8.23-13.64-8.23a15.27,15.27,0,0,0-12,5.39,3.49,3.49,0,0,1-3.78,1.15,14.19,14.19,0,0,0-4.22-.63c-.43,0-11.12.79-11.91,12.16a.38.38,0,0,1-.75,0c.84-12,12.53-12.85,12.64-12.86h0a15,15,0,0,1,4.45.67,2.74,2.74,0,0,0,3-.91,16,16,0,0,1,12.55-5.67c6.47,0,12.23,3.5,14.33,8.7a1.71,1.71,0,0,0,1.08,1,1.41,1.41,0,0,0,1.25-.2,10.26,10.26,0,0,1,13.3,1.3,11,11,0,0,1,3.07,8,.47.47,0,0,1-.11.26A.43.43,0,0,1,267.22,89.56Z" />
      <Path d="M243.63,82.18a.26.26,0,0,1-.22-.13A12.7,12.7,0,0,0,242,80a.25.25,0,0,1,0-.35.24.24,0,0,1,.35,0,12.35,12.35,0,0,1,1.51,2.17.25.25,0,0,1-.1.34A.27.27,0,0,1,243.63,82.18Z" />
      <Path d="M240.22,78.25a.27.27,0,0,1-.15,0,13.71,13.71,0,0,0-7-2.58.24.24,0,0,1-.24-.26.25.25,0,0,1,.25-.24h0a13.92,13.92,0,0,1,7.31,2.68.25.25,0,0,1,0,.35A.28.28,0,0,1,240.22,78.25Z" />
      <Path d="M209.3,89.81H7.75a.38.38,0,1,1,0-.75H209.3a.38.38,0,0,1,0,.75Z" />
      <Path d="M276.1,89.81H216.18a.38.38,0,1,1,0-.75H276.1a.38.38,0,1,1,0,.75Z" />
      <Path className="cls-4" d="M86.59,45.75s-8.34-.43-13.36-4.89A21.16,21.16,0,0,1,66.92,31s-.87,9.14,1.47,13.33A25,25,0,0,0,78.61,54.66L81.06,56" fill="#fff" />
      <Path d="M81.06,56.47a.46.46,0,0,1-.23-.06L78.38,55.1C78.15,55,72.22,52.25,68,44.61,65.56,40.34,66.39,31.38,66.42,31a.5.5,0,0,1,.46-.46.52.52,0,0,1,.53.39,20.77,20.77,0,0,0,6.15,9.56c4.83,4.28,13,4.75,13.06,4.76a.5.5,0,0,1,.47.52.51.51,0,0,1-.53.48c-.34,0-8.57-.49-13.66-5a22.52,22.52,0,0,1-5.62-7.7c-.08,3,0,7.89,1.55,10.58a24.64,24.64,0,0,0,10,10.08l2.48,1.33a.5.5,0,0,1,.2.68A.51.51,0,0,1,81.06,56.47Z" />
      <Path className="cls-4" d="M111.31,78.4a21.37,21.37,0,0,1-7.4,3c-9,1.68-8.73.92-14.13,6.54s-4.53,7.6-8.43,8.37S72.26,92.58,74,88.42c.75-1.77,3.07-4.88,5.5-7.86" fill="#fff" />
      <Path className="cls-2" d="M155.79,55.53l15.6,9.64s4,2,5.1,4.69.91,4.1-1.26,6-4.7,2.41-9.42-.11-16-7.66-16-7.66l-6.85-6.32,2.69-3.5,7.78-2.53Z" fill="#e6ebef" />
      <Path d="M171,78a12.49,12.49,0,0,1-5.72-1.82c-4.63-2.5-15.85-7.63-16-7.68a.38.38,0,0,1-.13-.09l-6.82-6.33a.49.49,0,0,1-.05-.67l.26-.34A13.28,13.28,0,0,1,149,56.51l2.48-.8a15.31,15.31,0,0,1,3.2-.67l.81-.08a.57.57,0,0,1,.31.07L171.4,64.7c.13.07,4.1,2.12,5.28,4.94s.89,4.54-1.41,6.59A6.21,6.21,0,0,1,171,78ZM149.81,67.6c1,.45,11.49,5.27,16,7.68,4.69,2.53,6.92,1.91,8.83.21s2.22-2.93,1.15-5.47-4.81-4.43-4.84-4.45L155.46,56l-.64.07a14.58,14.58,0,0,0-3,.62l-2.48.8a12.22,12.22,0,0,0-6,4.18Z" />
      <Path className="cls-2" d="M111,79a27.37,27.37,0,0,1-9.33,3.39,18,18,0,0,0-9.94,4.31c-2.77,2.16-5.59,6.75-5.59,6.75s-2.89,3.44-5.7,3.22a6.38,6.38,0,0,1-6-4.6,7,7,0,0,1,1.09-5.23l4.81-6.93s11.83-.73,17.1-5.13A23.18,23.18,0,0,0,111,79Z" fill="#e6ebef" />
      <Path d="M80.44,96.79h-.31a6.87,6.87,0,0,1-6.41-5,7.41,7.41,0,0,1,1.18-5.63l4.82-6.89a.47.47,0,0,1,.38-.21c.12,0,11.72-.74,16.81-5a.49.49,0,0,1,.63,0,23,23,0,0,0,13.22,4.15.49.49,0,0,1,.47.37.5.5,0,0,1-.22.56,27.87,27.87,0,0,1-9.53,3.42,17.45,17.45,0,0,0-9.7,4.17c-2.66,2.07-5.45,6.55-5.48,6.6C86.14,93.55,83.36,96.79,80.44,96.79ZM80.4,80l-4.69,6.7a6.52,6.52,0,0,0-1,4.82c.58,2.38,2.7,4,5.52,4.23,2.52.19,5.25-3,5.28-3s2.87-4.62,5.68-6.8a18.47,18.47,0,0,1,10.19-4.37,24.86,24.86,0,0,0,7.55-2.45,23.47,23.47,0,0,1-11.67-4C92.14,79,82.14,79.9,80.4,80Z" />
      <Path className="cls-4" d="M138.79,20.41s-5.08-1.5-7.87-4-5.09-6.58-7.69-6.53a6.23,6.23,0,0,0-6.14,4.15c-1.07,2.93,1.24,6.74,1.24,6.74l1.89,3.14s4.41-1.37,6.35-1.27a10.54,10.54,0,0,1,3.21.61l2.83,1.51Z" fill="#fff" />
      <Path d="M132.61,25.28a.53.53,0,0,1-.24-.06l-2.83-1.51h0a10.45,10.45,0,0,0-3-.55c-1.82-.09-6.13,1.24-6.17,1.25a.5.5,0,0,1-.58-.22l-1.89-3.14c-.09-.16-2.42-4.05-1.28-7.17a6.73,6.73,0,0,1,6.6-4.48c2,0,3.68,1.91,5.48,4a31.38,31.38,0,0,0,2.55,2.7c2.67,2.37,7.63,3.85,7.68,3.87a.5.5,0,0,1,.15.89l-6.18,4.37A.52.52,0,0,1,132.61,25.28Zm-6.24-3.13h.22a10.41,10.41,0,0,1,3.37.65l2.61,1.38,5.12-3.62a22.65,22.65,0,0,1-7.1-3.77A30.33,30.33,0,0,1,128,14c-1.63-1.86-3.17-3.62-4.68-3.62h0a5.78,5.78,0,0,0-5.67,3.82c-1,2.68,1.18,6.28,1.2,6.31l1.69,2.81A27.33,27.33,0,0,1,126.37,22.15Z" />
      <Path className="cls-2" d="M138.87,20.49s-5.1-1.48-7.89-4S125.86,10,123.25,10a6.22,6.22,0,0,0-6.13,4.17c-1.06,2.94,1.27,6.75,1.27,6.75l1.89,3.14s4.41-1.38,6.36-1.3a10.75,10.75,0,0,1,3.21.6l2.84,1.51Z" fill="#e6ebef" />
      <Path d="M120.16,23.87a.52.52,0,0,1-.37-.16c-4.5-4.88-3.46-9.88-2.25-11.53a6.48,6.48,0,0,1,4.73-2.84c2,0,4,1.17,6,3.62,5,6.2,10.83,6.91,10.89,6.92a.5.5,0,0,1,.44.55.48.48,0,0,1-.55.44c-.25,0-6.26-.75-11.56-7.28-1.78-2.2-3.51-3.27-5.21-3.25a5.49,5.49,0,0,0-4,2.43c-.68.93-2.31,5.4,2.18,10.26a.5.5,0,0,1,0,.7A.51.51,0,0,1,120.16,23.87Z" />
      <Path className="cls-4" d="M132.84,24.6s5.4-5.05,11.09-4.64,11.15,5.09,18.3,4,10.79.66,11.83,4.53S174,38.8,170.58,40.72s-9.18,3.05-13.22,2-4,2.07-3.51,3.15a9.9,9.9,0,0,0,1.67,2.36" fill="#fff" />
      <Path d="M155.52,48.77a.52.52,0,0,1-.36-.16,10.47,10.47,0,0,1-1.77-2.51,3.27,3.27,0,0,1,.37-3.19c.74-.85,2-1.07,3.72-.63,4.05,1,9.72-.22,12.85-2s4.28-7.8,3.24-11.66-4.7-5.15-11.26-4.16c-4.4.66-8.08-.74-11.64-2.1a24.65,24.65,0,0,0-6.78-1.9c-5.38-.37-10.65,4.46-10.71,4.51a.48.48,0,0,1-.7,0,.49.49,0,0,1,0-.7c.23-.22,5.62-5.19,11.47-4.77a25.22,25.22,0,0,1,7.06,2c3.44,1.32,7,2.67,11.13,2,7.06-1.07,11.22.58,12.38,4.89,1,3.78.07,10.64-3.72,12.79-3.3,1.87-9.29,3.17-13.58,2.1-.92-.23-2.14-.36-2.72.31a2.32,2.32,0,0,0-.21,2.15,9.61,9.61,0,0,0,1.57,2.21.5.5,0,0,1,0,.71A.54.54,0,0,1,155.52,48.77Z" />
      <Path className="cls-4" d="M169.58,51a7.93,7.93,0,0,1-4.78,4.27s-2.56,1-8.87.3" fill="#fff" />
      <Path d="M160.13,56.37a37.73,37.73,0,0,1-4.26-.26.5.5,0,0,1-.44-.56.51.51,0,0,1,.55-.44c6.07.71,8.6-.26,8.63-.27a7.54,7.54,0,0,0,4.51-4,.5.5,0,0,1,.92.38,8.35,8.35,0,0,1-5.11,4.56A14.25,14.25,0,0,1,160.13,56.37Z" />
      <Path className="cls-4" d="M143.72,62.73A111.14,111.14,0,0,1,161.93,81.9s3.55,4.55-1.26,7.83-9.27-.09-11.56-2-15.8-14.18-23.23-17.11" fill="#fff" />
      <Path d="M156.47,91.59c-3.55,0-6.49-2.5-7.68-3.52l-1.06-.92c-3.83-3.35-15.45-13.51-22-16.11a.48.48,0,0,1-.28-.64.5.5,0,0,1,.64-.29c6.75,2.66,18.47,12.91,22.32,16.28l1.06.92c2.65,2.26,6.66,4.92,10.95,2a4.74,4.74,0,0,0,2.25-3.15,5.82,5.82,0,0,0-1.11-4,110.74,110.74,0,0,0-18.12-19.09.5.5,0,0,1-.08-.7.5.5,0,0,1,.7-.09,112.47,112.47,0,0,1,18.3,19.27,6.77,6.77,0,0,1,1.3,4.7A5.75,5.75,0,0,1,161,90.14,7.83,7.83,0,0,1,156.47,91.59Z" />
      <Path d="M171,78.11c-2.13,0-4.17-1.09-5.76-1.93l-1.11-.58c-.6-.28-12.46-5.91-14.19-6.63a.5.5,0,0,1-.27-.66.52.52,0,0,1,.66-.27c1.74.73,13.64,6.38,14.23,6.66.36.17.74.38,1.15.6,2.78,1.47,6.24,3.31,9.29-.21a4.8,4.8,0,0,0,1.37-3.63,5.85,5.85,0,0,0-2.08-3.54c-3.42-2.74-14.4-9.4-18.31-11.6a.5.5,0,1,1,.49-.87c3.94,2.21,15,8.92,18.43,11.69a6.71,6.71,0,0,1,2.46,4.21,5.71,5.71,0,0,1-1.6,4.39A6.13,6.13,0,0,1,171,78.11Z" />
      <Path className="cls-4" d="M132,25.26a6.57,6.57,0,0,0-6-2.94c-4.1.35-9.65,3.25-13.65,1.88s-8-4.74-11.62-1.26-.31,10.67,3.75,13.9,11.42,4.1,16.12,3.5" fill="#fff" />
      <Path d="M117.5,41c-4.67,0-10.1-1.17-13.4-3.8-3-2.41-5.35-6.61-5.5-10a5.91,5.91,0,0,1,1.71-4.68c3-2.91,6.29-1.43,9.42,0a27.39,27.39,0,0,0,2.71,1.14c2.42.83,5.51,0,8.51-.83a28.21,28.21,0,0,1,4.94-1.06A7,7,0,0,1,132.4,25a.49.49,0,0,1-.17.69.51.51,0,0,1-.69-.18A6,6,0,0,0,126,22.82a27.92,27.92,0,0,0-4.76,1c-3.14.86-6.38,1.75-9.09.82a29.84,29.84,0,0,1-2.81-1.18c-3.08-1.41-5.75-2.63-8.31-.19a4.87,4.87,0,0,0-1.4,3.91c.14,3.1,2.29,7,5.13,9.24,3.8,3,11,4,15.74,3.4a.5.5,0,0,1,.13,1A25.43,25.43,0,0,1,117.5,41Z" />
      <Path className="cls-4" d="M109.35,31.56a22.74,22.74,0,0,0,6.63.62c3.24-.27,9.27-1.29,10.07,1.73S122.91,39,122.91,39" fill="#fff" />
      <Path d="M122.91,39.5a.5.5,0,0,1-.23-1s3.58-1.92,2.89-4.52c-.6-2.24-5.21-1.78-8.27-1.47l-1.28.12a23.8,23.8,0,0,1-6.79-.63.5.5,0,0,1,.25-1,22.34,22.34,0,0,0,6.46.6l1.26-.12c3.6-.36,8.52-.85,9.34,2.22.89,3.36-3.22,5.57-3.4,5.66A.46.46,0,0,1,122.91,39.5Z" />
      <Path className="cls-4" d="M129.05,72.7s-15.7,10.72-30.57,2.63" fill="#fff" />
      <Path d="M110.48,78.83a25.33,25.33,0,0,1-12.24-3.06.5.5,0,1,1,.48-.88c14.44,7.85,29.89-2.5,30.05-2.6a.5.5,0,0,1,.56.82C129.22,73.19,120.8,78.83,110.48,78.83Z" />
      <Path className="cls-4" d="M102.39,69.43S95.52,77.65,86,78.81s-9.25.38-15.31,5.93S65.38,92.42,61.23,93s-9.31-4.54-7.18-8.8c1.49-3,7.81-9.56,11.41-13.17a17.67,17.67,0,0,1,4.68-3.42A24.39,24.39,0,0,0,81.55,55.7" fill="#fff" />
      <Path d="M60.61,93.51A8.14,8.14,0,0,1,54.35,90,5.81,5.81,0,0,1,53.6,84c1.53-3.06,7.87-9.65,11.51-13.31a18.57,18.57,0,0,1,4.8-3.51A23.86,23.86,0,0,0,81.09,55.49a.5.5,0,0,1,.92.41A25,25,0,0,1,70.36,68a17,17,0,0,0-4.54,3.32C62.22,75,56,81.46,54.49,84.39a4.87,4.87,0,0,0,.67,5c1.48,2.06,4,3.33,6,3,2.3-.31,2.9-1.07,4.37-2.92a41.49,41.49,0,0,1,4.79-5.19c5.25-4.8,5.86-5,12.26-5.67l3.33-.38c9.23-1.12,16-9.13,16.09-9.21a.52.52,0,0,1,.71-.06.49.49,0,0,1,.06.7c-.28.35-7.11,8.39-16.74,9.56-1.28.16-2.38.28-3.34.38-6.19.68-6.56.72-11.69,5.42a39.8,39.8,0,0,0-4.68,5.07c-1.51,1.89-2.33,2.93-5,3.29A5.07,5.07,0,0,1,60.61,93.51Z" />
      <Path className="cls-4" d="M174.12,31.52s-4.36,2.59-7.87,1-2.41-5.57,2.46-8.34C168.71,24.17,173.93,24.19,174.12,31.52Z" fill="#fff" />
      <Path d="M168.82,33.53A6.57,6.57,0,0,1,166,33a3.58,3.58,0,0,1-2.34-3.09c-.09-2.09,1.69-4.39,4.76-6.13.08,0,.17,0,.25-.07s5.71.11,5.91,7.83a.53.53,0,0,1-.24.45A12.33,12.33,0,0,1,168.82,33.53Zm0-8.85c-2.62,1.52-4.21,3.49-4.13,5.15a2.59,2.59,0,0,0,1.76,2.22c2.77,1.27,6.2-.33,7.15-.82C173.33,25.32,169.59,24.74,168.83,24.68Z" />
      <Path className="cls-4" d="M146.92,31.49a3.32,3.32,0,0,0-3.87-.7c-2.16,1.15-1.74,4.22-1.74,4.22" fill="#fff" />
      <Path d="M141.3,35.51a.5.5,0,0,1-.49-.44c0-.14-.44-3.43,2-4.72a3.78,3.78,0,0,1,4.47.8.5.5,0,1,1-.73.68,2.8,2.8,0,0,0-3.27-.59c-1.82,1-1.48,3.67-1.48,3.7a.5.5,0,0,1-.43.56Z" />
      <Path d="M169.82,51a.57.57,0,0,1-.18,0,.5.5,0,0,1-.28-.65c.63-1.59-3.33-4.89-6-6.6a.5.5,0,0,1,.55-.84c.77.5,7.5,4.95,6.36,7.81A.5.5,0,0,1,169.82,51Z" />
      <Path d="M163.46,52c-2.35,0-4.84-1.3-6.69-2.53a.5.5,0,0,1-.13-.7.49.49,0,0,1,.69-.13c2.14,1.43,5.2,3,7.61,2.12a3.71,3.71,0,0,0,2.74-2.8.5.5,0,0,1,.55-.45.5.5,0,0,1,.44.55,4.68,4.68,0,0,1-3.4,3.65A5.53,5.53,0,0,1,163.46,52Z" />
      <Path d="M163,48.24a.45.45,0,0,1-.34-.14c-3.21-3.09-6.72-.34-6.87-.22a.5.5,0,1,1-.62-.78s4.35-3.41,8.18.28a.5.5,0,0,1-.35.86Z" />
      <Path d="M159,46.38h-.12a.5.5,0,0,1-.36-.61,4,4,0,0,1,4.14-3.06.5.5,0,0,1,.46.54.51.51,0,0,1-.53.46,3,3,0,0,0-3.1,2.3A.5.5,0,0,1,159,46.38Z" />
      <Path className="cls-4" d="M111.7,40.07a25.74,25.74,0,0,1-11.06,1.48c-6.4-.5-14.9,2-18.68,12.24" fill="#fff" />
      <Path d="M82,54.29l-.17,0a.5.5,0,0,1-.3-.64c4.69-12.69,15.87-12.83,19.19-12.57a25.29,25.29,0,0,0,10.82-1.44.5.5,0,1,1,.39.92,26.09,26.09,0,0,1-11.29,1.52C97.46,41.8,86.87,41.93,82.43,54A.49.49,0,0,1,82,54.29Z" />
      <Ellipse className="cls-5" cx="208.34" cy="37.39" fill={svgHighlight} rx="26.6" ry="13.15" transform="translate(-3.32 39.48) rotate(-10.74)" />
      <Path className="cls-6" d="M208.94,40.83c9.21-1.74,16.12-6.06,15.44-9.65a2.92,2.92,0,0,0-.25-.7c-1.39,3.21-7.8,7-15.55,8.45s-15,.13-17.54-2.32a2.48,2.48,0,0,0,0,.9C191.72,41.09,199.73,42.58,208.94,40.83Z" fill={svgHighlight} />
      <Path className="cls-6" d="M234.16,31.34c-1.29,6.41-11.34,13-24,15.37-12.88,2.45-24.65-.29-28-6a6.79,6.79,0,0,0,.1,1.62c1.36,7.14,14.16,10.7,28.59,8s25-10.74,23.69-17.87A6,6,0,0,0,234.16,31.34Z" fill={svgHighlight} />
      <Path d="M201.25,51.74a37,37,0,0,1-9.79-1.22c-5.59-1.56-9.06-4.43-9.75-8.08s1.48-7.58,6.11-11.08,10.92-6,18-7.38,13.95-1.23,19.42.29,9.06,4.42,9.75,8.07h0c1.4,7.4-9.4,15.68-24.09,18.47A52.19,52.19,0,0,1,201.25,51.74Zm14.18-27.69A51.18,51.18,0,0,0,206,25c-6.91,1.31-13.14,3.87-17.55,7.2s-6.35,6.84-5.74,10.1,3.83,5.84,9,7.29,12.06,1.58,19,.27c14.14-2.68,24.59-10.44,23.29-17.29h0c-.62-3.26-3.83-5.85-9-7.3A35.76,35.76,0,0,0,215.43,24.05Z" />
      <Path d="M199.5,48c-9.74,0-16.67-3.05-17.68-8.35l.5-.09c1.29,6.8,13,9.69,27.74,6.89,7.09-1.35,13.36-3.71,17.66-6.67s6.44-6.42,5.81-9.75L234,30c.68,3.54-1.41,7.09-6,10.26-4.35,3-10.69,5.38-17.85,6.74A57.28,57.28,0,0,1,199.5,48Z" />
      <Path d="M201.52,41.82a27,27,0,0,1-4.77-.4c-3.5-.65-5.61-2-6-3.87-.71-3.77,6.15-8.14,15.64-9.94a34.59,34.59,0,0,1,12.24-.34c3.49.65,5.6,2,6,3.87s-1.11,3.89-4.12,5.77A34.44,34.44,0,0,1,209,41.08,40.62,40.62,0,0,1,201.52,41.82Zm12.37-14.45a40.41,40.41,0,0,0-7.37.73c-9.05,1.72-15.89,5.92-15.24,9.36.31,1.6,2.33,2.86,5.56,3.46a33.75,33.75,0,0,0,12-.33,34.08,34.08,0,0,0,11.34-4.1c2.79-1.74,4.21-3.66,3.9-5.26h0c-.3-1.6-2.33-2.87-5.55-3.46A25.31,25.31,0,0,0,213.89,27.37Z" />
      <Path d="M201.11,39.92c-5.1,0-9.05-1.18-10.31-3.31l.43-.25c1.7,2.87,9.14,3.87,17.3,2.32s14.67-5.18,15.24-8.46l.49.08c-.61,3.54-7.19,7.27-15.64,8.87A40,40,0,0,1,201.11,39.92Z" />
      <Path d="M174.27,31.5s-4.35,2.62-7.87,1S164,27,168.84,24.17C168.84,24.17,174.06,24.17,174.27,31.5Z" />
      <Path d="M143.49,59.09c-16.37,0-22.14-17.71-22.2-17.9a.39.39,0,0,1,.24-.47A.38.38,0,0,1,122,41c.06.18,6.06,18.55,23.16,17.31a.38.38,0,0,1,.4.34.36.36,0,0,1-.34.4C144.63,59.07,144.05,59.09,143.49,59.09Z" /><Path d="M140.66,62c-16.38,0-22.15-17.71-22.21-17.89a.38.38,0,0,1,.25-.47.36.36,0,0,1,.47.24c.06.19,6,18.57,23.16,17.31a.37.37,0,0,1,.4.35.38.38,0,0,1-.35.4C141.79,61.94,141.22,62,140.66,62Z" />
      <Path className="cls-7" d="M47.75,40.64a9.79,9.79,0,0,1-2.22-6.46c0-.18,0-.36,0-.54,0-7.66-6.84-13.88-15.27-13.88S15,26,15,33.64c0,.18,0,.36,0,.54a9.79,9.79,0,0,1-2.22,6.46,18.23,18.23,0,0,0-4,11.4c0,10.81,9.64,19.57,21.54,19.57S51.81,62.85,51.81,52A18.24,18.24,0,0,0,47.75,40.64Z" fill="#eff3f6" />
      <Path d="M30.27,72C18.18,72,8.35,63,8.35,52a18.6,18.6,0,0,1,4.13-11.63,9.4,9.4,0,0,0,2.15-6.22c0-.18,0-.36,0-.55,0-7.86,7-14.25,15.66-14.25s15.65,6.39,15.65,14.25c0,.19,0,.37,0,.55a9.44,9.44,0,0,0,2.14,6.22A18.6,18.6,0,0,1,52.18,52C52.18,63,42.35,72,30.27,72Zm0-51.84c-8.22,0-14.91,6.06-14.91,13.5,0,.18,0,.35,0,.52a10.15,10.15,0,0,1-2.3,6.72A17.8,17.8,0,0,0,9.1,52c0,10.58,9.5,19.19,21.17,19.19S51.43,62.62,51.43,52a17.8,17.8,0,0,0-4-11.16h0a10.21,10.21,0,0,1-2.3-6.72c0-.17,0-.34,0-.52C45.17,26.2,38.48,20.14,30.27,20.14Z" />
      <Path d="M29.7,90a.37.37,0,0,1-.38-.37V37.46a.38.38,0,1,1,.75,0v52.2A.37.37,0,0,1,29.7,90Z" />
      <Path d="M29.53,54.57a.38.38,0,0,1-.22-.07l-5.53-3.94a.37.37,0,1,1,.43-.61l5.54,3.94a.37.37,0,0,1,.09.52A.4.4,0,0,1,29.53,54.57Z" />
      <Path d="M29.71,46a.38.38,0,0,1-.22-.68L35,41.33a.37.37,0,0,1,.52.08.38.38,0,0,1-.09.53l-5.53,3.94A.36.36,0,0,1,29.71,46Z" />
      <Path d="M29.83,62.07a.38.38,0,0,1-.21-.69l5.53-3.94a.37.37,0,0,1,.52.09.38.38,0,0,1-.08.52l-5.54,4A.41.41,0,0,1,29.83,62.07Z" />
      <Rect className="cls-1" fill="#dce2e7" height="5.07" rx="2.53" width="102.3" x="60.43" y="99.99" />
      <Path className="cls-2" d="M220,69.12a2,2,0,0,0,1.89-2.81c-1.14-2.85-3.57-6.89-8.18-7.37a8,8,0,0,0-5.17,1.51.32.32,0,0,1-.4,0,5.84,5.84,0,0,0-4-1.48c-3,0-5.36,2-5.36,4.51h0a.32.32,0,0,1-.35.32l-.44,0a4.14,4.14,0,0,0-3.91,2.37.32.32,0,0,1-.43.18,3.71,3.71,0,0,0-1.61-.33,3.35,3.35,0,0,0-2.73,1.53,1,1,0,0,0,.81,1.61Z" fill="#e6ebef" />
      <Path className="cls-2" d="M88.91,22.18a1,1,0,0,0,.75-1.62,6.59,6.59,0,0,0-4.91-1.81,8.47,8.47,0,0,0-1,.06.61.61,0,0,1-.67-.62h0c0-1.86-2.35-3.37-5.25-3.37a7.76,7.76,0,0,0-2.26.33.6.6,0,0,1-.74-.42c-.6-2.77-3.56-4.86-7.13-4.86-4,0-7.26,2.64-7.26,5.91,0,.12,0,.23,0,.35a2.76,2.76,0,0,0-.51-.05,3.36,3.36,0,0,0-3.2,2.34.6.6,0,0,1-.72.39,3.1,3.1,0,0,0-.92-.13A3.33,3.33,0,0,0,52.46,20a1.38,1.38,0,0,0,1.08,2.23Z" fill="#e6ebef" />
      <Path d="M238.73,98.58h-5.35a.37.37,0,0,1-.37-.37.38.38,0,0,1,.37-.38h5.35a.38.38,0,0,1,.38.38A.37.37,0,0,1,238.73,98.58Z" />
      <Path d="M228.69,98.58h-20a.36.36,0,0,1-.37-.37.37.37,0,0,1,.37-.38h20a.37.37,0,0,1,.37.38A.36.36,0,0,1,228.69,98.58Z" />
      <Path d="M39.89,109.66H34.54a.38.38,0,0,1,0-.75h5.35a.38.38,0,0,1,0,.75Z" />
      <Path d="M64.54,109.66h-20a.38.38,0,0,1,0-.75h20a.38.38,0,1,1,0,.75Z" />
      <Path d="M238.92,40.55a.27.27,0,0,1-.17-.06.25.25,0,0,1,0-.36c3-3.25,4.33-6.61,3.74-9.71-1-5.25-7.26-9.11-16.76-10.33a.25.25,0,1,1,.06-.5c9.73,1.25,16.15,5.27,17.19,10.74.62,3.26-.72,6.77-3.87,10.14A.23.23,0,0,1,238.92,40.55Z" />
      <Path d="M222.84,19.81h0c-1.68-.12-3.43-.15-5.21-.1a.24.24,0,0,1-.25-.24.25.25,0,0,1,.24-.26c1.79-.05,3.56,0,5.25.1a.25.25,0,0,1,0,.5Z" />
    </Svg>
  );
}
