import React, { Fragment } from 'react';
import T from 'prop-types';
import { Linking, useWindowDimensions } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { FAQ_BASE_URL, FAQ_IGNORED_TAGS } from './constants';
import { DetailHeader, StyledHTML } from './styledComponents';

const Detail = ({ answer, question }) => {
  const { width } = useWindowDimensions();

  const handleOnLinkPress = (_, href) => {
    const regexMailto = RegExp('mailto*', 'i');
    if (regexMailto.test(href)) {
      return Linking.openURL(href);
    }
    const regexHttp = RegExp('^(http|https)://', 'i');
    const absoluteHref = regexHttp.test(href) ? href : `${FAQ_BASE_URL}${href}`;
    return WebBrowser.openBrowserAsync(absoluteHref);
  };

  return (
    <Fragment>
      <DetailHeader>{question}</DetailHeader>
      <StyledHTML
        contentWidth={width}
        ignoredTags={FAQ_IGNORED_TAGS}
        onLinkPress={handleOnLinkPress}
        source={{ html: answer }}
      />
    </Fragment>
  );
};

Detail.propTypes = {
  answer: T.string.isRequired,
  question: T.string.isRequired,
};

export default Detail;
