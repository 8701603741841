import React from 'react';
import T from 'prop-types';

import {
  PaymentStatusIcon,
  PaymentStatusText,
  PaymentStatusWrapper,
} from './styledComponents';

const PaymentStatus = ({
  paymentDate,
  paymentIsDue,
}) => (
  <PaymentStatusWrapper>
    <PaymentStatusIcon paymentIsDue={paymentIsDue}></PaymentStatusIcon>
    <PaymentStatusText>{
      paymentIsDue ? `Past Due ${paymentDate}` : `Paid ${paymentDate}`
    }
    </PaymentStatusText>
  </PaymentStatusWrapper>
);

PaymentStatus.propTypes = {
  paymentDate: T.string,
  paymentIsDue: T.bool,
};

export default PaymentStatus;
