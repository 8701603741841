/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function Clock(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(48)} viewBox="0 0 86 86" width={moderateScale(48)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M42.8,18A31.26,31.26,0,1,0,74.05,49.26,31.27,31.27,0,0,0,42.8,18Zm0,56A24.7,24.7,0,1,1,67.49,49.26,24.7,24.7,0,0,1,42.8,74Z" fill={svgHighlight} />
      <Path d="M39.33,17.37A3.41,3.41,0,0,1,42.74,14h0a3.41,3.41,0,0,1,3.41,3.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" />
      <Path d="M19.18,27.68,18,26.28a1.15,1.15,0,0,1,.08-1.62l4.8-4a1.16,1.16,0,0,1,1.62.24l1.15,1.39" fill="#e6ebef" />
      <Path d="M66.23,27.68l1.15-1.4a1.15,1.15,0,0,0-.08-1.62l-4.8-4a1.15,1.15,0,0,0-1.61.24l-1.15,1.39" fill="#e6ebef" />
      <Path d="M19.18,27.68,18,26.28a1.15,1.15,0,0,1,.08-1.62l4.8-4a1.16,1.16,0,0,1,1.62.24l1.15,1.39" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" />
      <Path d="M66.23,27.68l1.15-1.4a1.15,1.15,0,0,0-.08-1.62l-4.8-4a1.15,1.15,0,0,0-1.61.24l-1.15,1.39" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" />
      <Path d="M19.87,50.43A24.69,24.69,0,0,1,57.3,29.28,24.69,24.69,0,1,0,30.05,70.41,24.66,24.66,0,0,1,19.87,50.43Z" fill="#bac2ca" />
      <Path d="M22,72.56a31.26,31.26,0,1,0-2.39-2.38" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" />
      <Circle cx="42.8" cy="49.26" fill={svgHighlight} r="2.81" />
      <Circle cx="42.8" cy="49.26" fill="none" r="2.81" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M61.08,32.65A24.63,24.63,0,1,0,62.94,35" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="42.8" x2="42.8" y1="28.42" y2="32.37" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="42.8" x2="42.8" y1="66.07" y2="70.03" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="63.6" x2="59.65" y1="49.22" y2="49.22" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="25.95" x2="21.99" y1="49.22" y2="49.22" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="57.51" x2="54.71" y1="63.93" y2="61.14" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="30.88" x2="28.09" y1="37.31" y2="34.51" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="28.09" x2="30.88" y1="63.93" y2="61.14" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="54.71" x2="57.51" y1="37.31" y2="34.51" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="42.82" x2="42.82" y1="46.08" y2="38.98" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18" x1="44.82" x2="51.1" y1="51.6" y2="57.57" />
      <Path d="M58.46,72.44A27.78,27.78,0,0,0,71.07,49.16" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Path d="M52.53,75.37A27.5,27.5,0,0,0,55.83,74" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" x1="42.59" x2="42.59" y1="10.18" y2="5.09" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" x1="36.17" x2="34.08" y1="12.53" y2="10.21" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75" x1="49.01" x2="51.1" y1="12.53" y2="10.21" />
    </Svg>
  );
}
