import React, { Fragment } from 'react';
import T from 'prop-types';

import { SecondaryHeader } from '../../ScreenHeaders';
import {
  ConfirmationContainer,
  ConfirmationH2,
  StyledAnimation,
} from './styledComponents';

const Confirmation = ({ navigation }) => (
  <Fragment>
    <SecondaryHeader
      handleDone={() => navigation.navigate('Settings')}
      title="Confirmation"
    />
    <ConfirmationContainer>
      <StyledAnimation name="robotMobile" />
      <ConfirmationH2>
        Success! Your password has been changed
      </ConfirmationH2>
    </ConfirmationContainer>
  </Fragment>
);

Confirmation.propTypes = { navigation: T.object.isRequired };

export default Confirmation;
