import styled from 'styled-components/native';

import { P3 } from '../base_ui';

import { moderateScale } from '../../utils/scaleHelpers';

export const FooterContainer = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { footerBackground } } }) => footerBackground};
  display: flex;
  justify-content: center;
`;

export const FooterLinkContainer = styled.View`
  display: flex;
  flex-direction: row;
  padding: ${moderateScale(8)}px;
`;

export const VersionText = styled(P3)`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
`;
