import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  BaseTextField,
  H3,
  MediumPrimaryButton,
  P2,
  P4,
  PrimaryDivider,
  StatusBannerV2,
} from '../../../base_ui';

export const AmountField = styled(BaseTextField).attrs(({
  theme: {
    fontFamilies: { secondaryFont },
    fontSizes: { f4 },
  },
}) => ({
  containerStyle: { justifyContent: 'flex-start' },
  errorStyle: {
    marginTop: moderateScale(4),
    width: '100%',
  },
  inputContainerStyle: {
    height: moderateScale(30),
    width: moderateScale(218),
  },
  inputStyle: { fontSize: f4 },
  labelStyle: {
    fontFamily: secondaryFont,
    fontSize: moderateScale(12),
    fontWeight: 400,
    width: moderateScale(106),
  },
  leftIconContainerStyle: {
    height: '100%',
    margin: `0 ${moderateScale(6)}px`,
    paddingLeft: moderateScale(6),
    paddingRight: moderateScale(2),
  },
}))``;

export const ButtonContainer = styled.View`
  align-items: center;
  justify-content: center;
  margin-bottom: ${moderateScale(35)}px;
  margin-top: auto;
  width: 100%;
`;

export const DisclaimerRow = styled.View`
  align-items: flex-start;
  flex-direction: row;
  margin-top: ${moderateScale(8)}px;
`;

export const DisclaimerText = styled(P4)`
   margin-left: ${moderateScale(8)}px;
`;

export const Divider = styled(PrimaryDivider)`
  height: ${moderateScale(6)}px;
  margin-bottom: ${moderateScale(16)}px;
  min-width: 100%;
`;

export const FormWrapper = styled.View`
  margin: 0 ${moderateScale(24)}px;
`;

export const Header = styled(H3)`
  margin-bottom: ${({ $validateRoutingError }) =>
    $validateRoutingError ? 0 : `${moderateScale(8)}px`};
`;

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(40)}px;
  justify-content: space-between;
`;

export const RowLabel = styled(P2)`
  font-weight: 700;
`;

export const RowWrapper = styled.View`
  margin: ${moderateScale(8)}px ${moderateScale(24)}px ${moderateScale(16)}px;
`;

export const StatusBanner = styled(StatusBannerV2)`
  margin: ${({ $validateRoutingError }) => $validateRoutingError ?
    `${moderateScale(8)}px 0px` : `${moderateScale(8)}px ${moderateScale(24)}px`};
  padding: ${({ $validateRoutingError }) => $validateRoutingError ?
    `${moderateScale(8)}px ${moderateScale(18)}px` :
    `${moderateScale(8)}px ${moderateScale(12)}px`};
`;

export const StatusBold = styled.Text`
  font-size: ${({ theme: { fontSizes: { f3 } } }) => `${f3}px`};
  font-weight: 700;
  line-height: ${moderateScale(18)}px;
`;

export const StatusText = styled.Text`
  font-size: ${({ theme: { fontSizes: { f3 } } }) => `${f3}px`};
  line-height: ${moderateScale(18)}px;
`;

export const StyledButton = styled(MediumPrimaryButton)`
  width: ${moderateScale(261)}px;
`;

export const StyledScrollView = styled.ScrollView.attrs(
  { contentContainerStyle: { flexGrow: 1 } },
)``;
