/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  G,
  Line,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterClipboard(props) {
  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} {...props}>
      <G className="cls-1" isolation="isolate">
        <G data-name="Layer 1" id="Layer_1">
          <Rect className="cls-2" fill="none" height="75.57" width="75.57" x="4.71" y="4.71" />
          <Rect className="cls-3" fill="#DCE2E7" height="64.79" mixblendmode="multiply" rx="6" width="51.36" x="18.04" y="11.83" />
          <Rect className="cls-4" fill="#FFF" height="65.05" rx="6" width="51.56" x="15.6" y="14.07" />
          <Path className="cls-5" d="M15.6,24.84V18.3a4.23,4.23,0,0,1,4.23-4.23H62.94a4.22,4.22,0,0,1,4.22,4.23V74.89a4.22,4.22,0,0,1-4.22,4.23H19.83a4.23,4.23,0,0,1-4.23-4.23V28" fill="none" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" />
          <Line className="cls-5" fill="none" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" x1="62.2" x2="62.2" y1="64.23" y2="67.59" />
          <Polyline className="cls-5" fill="none" points="62.2 69.15 62.2 74.36 20.56 74.36 20.56 18.43 62.2 18.43 62.2 62.51" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" />
          <Circle className="cls-5" cx="41.38" cy="9.49" fill="none" r="2.34" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" />
          <Path className="cls-6" d="M54.55,18.57l-1-5.05a2.38,2.38,0,0,0-2.33-1.89H48.47a2.13,2.13,0,0,1-2.11-2,5.2,5.2,0,0,0-.17-1,5.06,5.06,0,0,0-4.4-3.66,5,5,0,0,0-5.44,5v0a1.68,1.68,0,0,1-1.67,1.68H31.75a2.37,2.37,0,0,0-2.31,1.82L28.23,18.5a2.37,2.37,0,0,0,2.31,2.92H52.23A2.37,2.37,0,0,0,54.55,18.57ZM41.38,11.83a2.34,2.34,0,1,1,2.34-2.34A2.34,2.34,0,0,1,41.38,11.83Z" fill="#E6EBEF" stroke="#000" strokeMiterlimit="10" strokeWidth="0.67px" />
          <Line className="cls-7" fill="#FFF" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" x1="36.24" x2="54.6" y1="34.44" y2="34.44" />
          <Line className="cls-7" fill="#FFF" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" x1="36.24" x2="54.6" y1="46.4" y2="46.4" />
          <Line className="cls-7" fill="#FFF" stroke="#2C2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.67px" x1="54.6" x2="36.24" y1="58.35" y2="58.35" />
          <Path className="cls-8" d="M32.42,32.61a.18.18,0,0,1,0,.25l-3.76,3.77a.24.24,0,0,1-.14,0,.16.16,0,0,1-.12-.06l-1.74-1.88a.18.18,0,0,1,0-.26.18.18,0,0,1,.25,0l1.61,1.75,3.64-3.63A.18.18,0,0,1,32.42,32.61Z" fillRule="evenodd" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.38px" />
          <Path className="cls-8" d="M32.42,44.39a.18.18,0,0,1,0,.25l-3.76,3.77a.24.24,0,0,1-.14.05.16.16,0,0,1-.12-.06l-1.74-1.88a.18.18,0,0,1,0-.26.18.18,0,0,1,.25,0L28.53,48l3.64-3.63A.18.18,0,0,1,32.42,44.39Z" fillRule="evenodd" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.38px" />
          <Path className="cls-8" d="M32.42,56.17a.18.18,0,0,1,0,.25l-3.76,3.77a.24.24,0,0,1-.14.05.16.16,0,0,1-.12-.06L26.66,58.3a.18.18,0,0,1,0-.26.18.18,0,0,1,.25,0l1.61,1.75,3.64-3.63A.18.18,0,0,1,32.42,56.17Z" fillRule="evenodd" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.38px" />
        </G>
      </G>
    </Svg>
  );
}
