/* eslint-disable max-len */
import React, { Fragment } from 'react';
import T from 'prop-types';
import moment from 'moment';

import {
  EXPIRED_POLICY_BANNER_MESSAGE,
  INSURANCE_TYPE_DICT,
} from '@dmi/shared/redux/Mortgage/constants';
import { interpolate, maskedCharacterGenerator } from '@dmi/shared/utils/globalHelpers';

import {
  AlertBanner,
  ConditionalRender,
  LargePrimaryButton,
  P2,
} from '../../base_ui';
import {
  ButtonWrapper,
  Container,
  DetailsBannerWrapper,
  StyledExpirationDate,
  StyledLabel,
  StyledRow,
  StyledStatusBanner,
} from './styledComponents';

const InsuranceDetailsComponent = ({
  insurance: {
    companyName,
    insuranceType,
    policyExpirationDate,
    policyNumber,
  },
  navigation,
}) => {
  const policy = INSURANCE_TYPE_DICT[insuranceType];
  const isExpired = moment(policyExpirationDate).isBefore(moment());

  return (
    <Fragment>
      <ConditionalRender
        Component={StyledStatusBanner}
        propsToPassDown={{ error: interpolate(EXPIRED_POLICY_BANNER_MESSAGE, { policy }) }}
        shouldRender={isExpired}
      />
      <Container>
        <StyledRow>
          <StyledLabel>Company</StyledLabel>
          <P2>{companyName}</P2>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Policy Number</StyledLabel>
          <P2>{maskedCharacterGenerator(policyNumber)}</P2>
        </StyledRow>
        <StyledRow>
          <StyledLabel>Expiration Date</StyledLabel>
          <StyledExpirationDate $isExpired={isExpired}>
            {moment(policyExpirationDate).format('LL')}
          </StyledExpirationDate>
        </StyledRow>
      </Container>
      <DetailsBannerWrapper>
        <AlertBanner
          text="If you have already submitted a new insurance policy, you don’t need to upload your document again. Your policy will be updated in several business days."
        />
      </DetailsBannerWrapper>
      <ButtonWrapper>
        <LargePrimaryButton label="Update" onPress={() => navigation.navigate('InsuranceUpload')} />
      </ButtonWrapper>
    </Fragment>
  );
};

InsuranceDetailsComponent.propTypes = {
  insurance: T.shape({
    companyName: T.string.isRequired,
    insuranceType: T.string.isRequired,
    policyExpirationDate: T.string.isRequired,
    policyNumber: T.string.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
};

export default InsuranceDetailsComponent;
