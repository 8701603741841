/* eslint-disable max-len */
import React, { Fragment } from 'react';
import T from 'prop-types';
import { Linking, Text } from 'react-native';
import { FormattedMessage } from 'react-intl';

import {
  CONTACT_ADDRESS_LINE1,
  CONTACT_ADDRESS_LINE2,
  FAX_NUMBER_CS_DEPT,
  TDD_NUMBER_COLLECTIONS,
  TDD_NUMBER_CS_DEPT,
} from '@dmi/shared/redux/Help/constants';
import { INTL_IDS } from '@dmi/shared/redux/Help/messages';

import { AlertBanner, ConditionalRender, P2 } from '../../base_ui';
import {
  BoldText,
  ContactInformationContainer,
  StyledH3,
  StyledLink,
  StyledP2,
  UnderlinedText,
} from './styledComponents';
import TopSection from './TopSection';

const ContactInformation = ({
  collectionsPhoneNumber,
  customerServicePhoneNumber,
  isLoggedIn,
  isSecureMessagingTreatmentOn,
  navigation,
}) => (
  <ContactInformationContainer>
    <ConditionalRender
      Component={TopSection}
      propsToPassDown={{
        customerServicePhoneNumber,
        isSecureMessagingTreatmentOn,
        navigation,
      }}
      shouldRender={isLoggedIn}
    />
    <StyledP2><FormattedMessage id={INTL_IDS.CONTACT_INFO_TEXT} /></StyledP2>
    <AlertBanner
      boldText="RESPA Notices of Error and Requests for Information"
      text={(<Text>must be sent only to the address indicated below, <UnderlinedText>including the specific Attention line noted</UnderlinedText>.</Text>)}
    />
    <StyledH3>Correspondence</StyledH3>
    <P2>Mail Stop 1290</P2>
    <P2>{CONTACT_ADDRESS_LINE1}</P2>
    <P2>{CONTACT_ADDRESS_LINE2}</P2>

    <StyledH3>
      <FormattedMessage id={INTL_IDS.CONTACT_PAYOFFOVERNIGHT_HEADER} />
    </StyledH3>
    <P2>Mail Stop 1270</P2>
    <P2>{CONTACT_ADDRESS_LINE1}</P2>
    <P2>{CONTACT_ADDRESS_LINE2}</P2>

    <StyledH3>RESPA Notice of Error/Request for Information</StyledH3>
    <BoldText>Attention: Mail Stop NOE 1290</BoldText>
    <P2>{CONTACT_ADDRESS_LINE1}</P2>
    <P2>{CONTACT_ADDRESS_LINE2}</P2>

    <ConditionalRender
      Component={(
        <Fragment>
          <StyledH3>Customer Service Department</StyledH3>
          <StyledLink onPress={() => Linking.openURL(`tel://${customerServicePhoneNumber}`)}>
            {customerServicePhoneNumber}
          </StyledLink>
          <Text>
            <BoldText>TDD: </BoldText>
            <StyledLink
              onPress={() => Linking.openURL(`tel://${TDD_NUMBER_CS_DEPT.replace(/\+|-/g, '')}`)}
            >
              {TDD_NUMBER_CS_DEPT}
            </StyledLink>
          </Text>
          <Text>
            <BoldText>Fax: </BoldText>
            <P2>{FAX_NUMBER_CS_DEPT}</P2>
          </Text>
        </Fragment>
      )}
      shouldRender={!isLoggedIn}
    />

    <StyledH3>Collection Department</StyledH3>
    <StyledLink
      onPress={() => Linking.openURL(`tel://${collectionsPhoneNumber.replace(/\+|-/g, '')}`)}
    >
      {collectionsPhoneNumber}
    </StyledLink>
    <Text>
      <BoldText>TDD: </BoldText>
      <StyledLink
        onPress={() => Linking.openURL(`tel://${TDD_NUMBER_COLLECTIONS.replace(/\+|-/g, '')}`)}
      >
        {TDD_NUMBER_COLLECTIONS}
      </StyledLink>
    </Text>
  </ContactInformationContainer>
);

ContactInformation.propTypes = {
  collectionsPhoneNumber: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  isLoggedIn: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default ContactInformation;
