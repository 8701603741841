import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

import {
  ChangeEmailConfirmation,
  ChangeEmailStepOne,
  ChangeEmailStepTwo,
} from '../../containers/Register/ChangeEmail';

const Stack = createStackNavigator();

const ChangeEmailStack = () => (
  <Stack.Navigator
    initialRouteName="ChangeEmailStepOne"
    screenOptions={{
      cardStyle: { ...Platform.select({ web: { height: '-webkit-fill-available' } }) },
      headerShown: false,
    }}
  >
    <Stack.Screen component={ChangeEmailConfirmation} name="ChangeEmailConfirmation" />
    <Stack.Screen component={ChangeEmailStepOne} name="ChangeEmailStepOne" />
    <Stack.Screen component={ChangeEmailStepTwo} name="ChangeEmailStepTwo" />
  </Stack.Navigator>
);

export default ChangeEmailStack;
