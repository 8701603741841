/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function PmiIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M2.45,3.05A8.29,8.29,0,0,0,6,2.53,7.1,7.1,0,0,0,9,.76H9a7.19,7.19,0,0,0,3.05,1.77,8.29,8.29,0,0,0,3.5.52v7.4a5.67,5.67,0,0,1-2.93,5L9,17.41H9l-3.6-2a5.66,5.66,0,0,1-2.94-5V3.05Z" fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Polygon fill="none" points="12.24 12 5.79 12 5.79 7.17 8.96 5.04 12.24 7.17 12.24 12" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" />
      <Line fill="none" stroke={iconStatic} strokeLinejoin="round" strokeWidth="1.25px" x1="9.01" x2="9.01" y1="9.3" y2="11.52" />
    </Svg>
  );
}
