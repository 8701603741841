import React from 'react';
import T from 'prop-types';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  ConditionalRender,
  H4,
  LargePrimaryButton,
  Skeleton,
  StyledScrollView,
} from '../../base_ui';
import {
  EditContainer,
  EditStyledP2,
  FlexView,
} from '../styledComponents';
import { ButtonWrapper, IllustrationWrapper } from './styledComponents';

const LegalFramesIllustration = iconDictionary(
  'legalFrames',
  { height: `${moderateScale(210)}px` },
);

const EditLegalNameComponent = ({
  legalName,
  navigation,
}) => {
  const { subheader, text } = PROFILE_CONTENT_DICTIONARY.editLegalName.editLegalNameView;

  return (
    <StyledScrollView>
      <EditContainer>
        <H4>Legal Name on File</H4>
        <ConditionalRender
          Component={<EditStyledP2>{legalName}</EditStyledP2>}
          FallbackComponent={(
            <Skeleton
              $bottomMargin={`${moderateScale(24)}px`}
              $rightMargin={`${moderateScale(24)}px`}
              $topMargin={`${moderateScale(8)}px`}
              height={`${moderateScale(22)}px`}
              width={`${moderateScale(150)}px`}
            />
          )}
          shouldRender={!!legalName}
        />
        <H4>{subheader}</H4>
        <EditStyledP2>{text}</EditStyledP2>
        <FlexView />
        <IllustrationWrapper>
          {LegalFramesIllustration}
        </IllustrationWrapper>
        <ButtonWrapper>
          <LargePrimaryButton
            label="Next"
            onPress={() => navigation.navigate('LegalNameUploadDocument')}
          />
        </ButtonWrapper>
      </EditContainer>
    </StyledScrollView>
  );
};

EditLegalNameComponent.propTypes = {
  legalName: T.string.isRequired,
  navigation: T.object.isRequired,
};

export default EditLegalNameComponent;
