import React from 'react';
import T from 'prop-types';

import {
  AbsoluteContainer,
  BackButton,
  DoneButton,
  SecondaryHeaderWrapper,
  StyledTitle,
} from './styledComponents';
import { ConditionalRender } from '../base_ui';
import iconDictionary from '../../utils/iconDictionary';

const arrowLeftM = iconDictionary('arrowLeftM');

const SecondaryHeader = ({
  handleBack,
  handleDone,
  title = '',
  ...restProps
}) => (
  <SecondaryHeaderWrapper {...restProps}>
    <ConditionalRender
      Component={BackButton}
      propsToPassDown={{
        ariaLabel: 'arrow left',
        Icon: arrowLeftM,
        onPress: handleBack,
      }}
      shouldRender={!!handleBack}
    />
    <ConditionalRender
      Component={(
        <AbsoluteContainer>
          <StyledTitle>{title}</StyledTitle>
        </AbsoluteContainer>
      )}
      shouldRender={!!title}
    />
    <ConditionalRender
      Component={DoneButton}
      propsToPassDown={{ label: 'Done', onPress: handleDone }}
      shouldRender={!!handleDone}
    />
  </SecondaryHeaderWrapper>
);

SecondaryHeader.propTypes = { handleBack: T.func, handleDone: T.func, title: T.string };

export default SecondaryHeader;
