/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

const Key = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} viewBox="0 0 86 86" width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M65.76,20.1a13,13,0,0,0-.46-1.55,15.26,15.26,0,0,0-29.34,2L9.85,28.39a1.18,1.18,0,0,0-.69.55L4.8,36.58a1.42,1.42,0,0,0-.1.29,28.76,28.76,0,0,1,6.59-5.93l26.91-8s2.13-12.41,13.92-11.58A16.16,16.16,0,0,1,65.76,20.1Z" fill="#DBE2E8" />
      <Path d="M12.94,27.46l-3.09.93a1.18,1.18,0,0,0-.69.55L4.8,36.58a1.18,1.18,0,0,0,.51,1.65l7.49,3.65a1.08,1.08,0,0,0,1.46-.51l1.61-3.43a1.08,1.08,0,0,1,1.55-.47l3.09,1.87a1.08,1.08,0,0,0,1.55-.47l1.55-3.35A1.09,1.09,0,0,1,25.15,35l3.06,1.8a1.07,1.07,0,0,0,1.55-.51l1.32-3.08a1.08,1.08,0,0,1,1.59-.49l2.86,1.88a2.62,2.62,0,0,0,2.23.3L40,34.25a15.26,15.26,0,0,0,26.21-9.92" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M54.3,8.77a15.09,15.09,0,0,0-7.13.06A15.26,15.26,0,0,0,36,20.54L15,26.83" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M66.09,22a15.7,15.7,0,0,0-.79-3.44,14.52,14.52,0,0,0-8.71-9.06" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="14.9" x2="37.21" y1="33.4" y2="26.69" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="9.45" x2="12.58" y1="35.04" y2="34.09" />
      <Circle cx="55.27" cy="21.64" fill="none" r="2.19" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25px" x1="47.91" x2="47.92" y1="53.17" y2="53.06" />
      <Polygon fill="#FFFFFF" points="59.52 74.32 74.59 76.39 77.26 56.9 80.56 57.35 80.66 56.74 78.31 54.78 77.63 54.24 64.38 42.47 64.24 42.45 48.31 50.21 47.51 50.56 44.73 51.8 44.65 52.42 47.95 52.87 45.27 72.36 58.79 74.22 59.52 74.32" />
      <Polyline fill={svgHighlight} points="56.02 73.29 57.73 60.95 65.96 62.08 64.25 74.55" />
      <Polyline fill="none" points="75.86 67.08 77.26 56.9 80.56 57.35 80.66 56.74 78.31 54.78 77.63 54.24 64.38 42.47 64.24 42.45 48.31 50.21 47.51 50.56 44.73 51.8 44.65 52.42 47.95 52.87 45.27 72.36 58.79 74.22 59.52 74.32 74.59 76.39 75.47 69.69" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.25px" />
      <Polyline fill="none" points="56.23 71.95 57.73 60.95 65.96 62.08 64.25 74.55" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Circle cx="63.25" cy="50.2" fill="#FFFFFF" r="2.25" />
      <Circle cx="63.25" cy="50.2" fill="none" r="2.25" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path d="M46.1,40.06a14.87,14.87,0,0,0,6.09,8.15" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path d="M62.36,50.5A14.86,14.86,0,1,0,54.9,21.94" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="29.09" x2="29.09" y1="47.02" y2="45.4" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="29.09" x2="29.09" y1="50.55" y2="48.93" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="30.05" x2="31.67" y1="47.98" y2="47.98" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="26.52" x2="28.14" y1="47.98" y2="47.98" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="34.86" x2="32.36" y1="70.98" y2="68.45" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="38.85" x2="36.4" y1="74.94" y2="72.57" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="36.23" x2="38.85" y1="71.1" y2="68.45" />
      <Line fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="32.36" x2="34.86" y1="74.94" y2="72.47" />
      <Path d="M16.46,59.16A1.34,1.34,0,0,0,16,60.49a1.33,1.33,0,0,0,2.58-.63,1.37,1.37,0,0,0-.8-.93" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
    </Svg>
  );
};

export default Key;
