/* eslint-disable consistent-return */
import { MOBILE_CARD_BUTTON_DICTIONARY as DICT }
from '@dmi/shared/utils/marketingTilesProps';

export const cardButtonPropsHelper = ({
  confirmationType,
  isEligibleForAutopay,
  isMurabahaAccount,
  isOtp,
  navigation,
}) => {
  if (isOtp && isEligibleForAutopay) {
    const { nestedScreen, screen } = DICT.autopay;
    const onPress = () => navigation.navigate(screen, { nestedScreen });
    return { ...DICT.autopay, onPress };
  }
  if (isOtp || confirmationType === 'enable') {
    const { screen } = DICT.calculator;
    const onPress = () => navigation.navigate(screen);
    return { ...DICT.calculator, onPress };
  }
  if (confirmationType === 'change') {
    if (isMurabahaAccount) {
      const { screen } = DICT.documents;
      const onPress = () => navigation.navigate(screen);
      return { ...DICT.documents, onPress };
    }
    const { nestedScreen, screen } = DICT.learningCenter;
    const onPress = () => navigation.navigate(screen, { nestedScreen });
    return { ...DICT.learningCenter, onPress };
  }
};
