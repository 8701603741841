import styled from 'styled-components/native';
import { PixelRatio } from 'react-native';

import {
  BASE_HEIGHT,
  CONTAINER_WIDTH,
  PADDING_RIGHT,
  PADDING_SIDE,
} from './constants';
import BaseInput from '../BaseInput';
import { IconButton } from '../../Buttons';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const Container = styled.View`
  margin: ${moderateScale(10)}px 0;
`;

export const ErrorLabel = styled.Text`
  align-self: flex-end;
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  font-size: ${({ theme: { fontSizes: { f2 } } }) => f2}px;
  padding-bottom: ${moderateScale(4)}px;
  width: ${moderateScale(217)}px;
`;

export const LabelInputContainer = styled.View`
  display: flex;
  flex-direction: row;
  width: ${({ $noLabel }) => $noLabel ? 'auto' : `${CONTAINER_WIDTH}px`};
`;

export const StyledIconButton = styled(IconButton)`
  min-height: ${moderateScale(24)}px;
  min-width: ${moderateScale(26)}px;
`;

export const StyledLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  line-height: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  min-height: ${moderateScale(18)}px;
  padding-top: ${moderateScale(9)}px;
  width: ${moderateScale(106)}px;
  display: ${({ $noLabel }) => $noLabel ? 'none' : 'flex'};
`;

export const StyledTextField = styled(BaseInput).attrs(({
  error,
  inputFontSize,
  theme: {
    fontFamilies: { secondaryFont },
    fontSizes: { f5 },
  },
}) => {
  const fontSizeToUse = inputFontSize || f5;

  return {
    inputContainerStyle: { backgroundColor: 'transparent', borderBottomWidth: error ? 3 : 1 },
    inputStyle: {
      fontFamily: secondaryFont,
      fontSize: fontSizeToUse,
      height: BASE_HEIGHT + ((PixelRatio.getFontScale() * fontSizeToUse) - fontSizeToUse),
      paddingBottom: PADDING_SIDE,
      paddingLeft: PADDING_SIDE,
      paddingRight: PADDING_RIGHT,
      paddingTop: PADDING_SIDE,
    },
    rightIconContainerStyle: {
      height: 30,
      marginVertical: 0,
      paddingRight: PADDING_RIGHT,
    },
  };
})``;

export const TextFieldWrapper = styled.View`
  width: ${moderateScale(221)}px;
`;
