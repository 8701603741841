import React, {
  Fragment,
  useCallback,
  useEffect,
} from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Keyboard } from 'react-native';

import {
  resetMainError,
  resetStatus,
  totpCancelRequest,
  totpRequest,
} from '@dmi/shared/redux/Main/actions';
import { TOTP_VALIDATION_ERROR_MESSAGE } from '@dmi/shared/redux/Main/constants';
import { getError, selectMainStatus } from '@dmi/shared/redux/Main/selectors';
import { changeInput, resetTotp, updateValidationError } from '@dmi/shared/redux/Login/actions';
import reducer from '@dmi/shared/redux/Login/reducer';
import {
  getLoginNotifications,
  getPasscode,
  getPasscodeValidationError,
  getSelectedMfaOption,
} from '@dmi/shared/redux/Login/selectors';
import { GENERAL_SERVER_ERROR_MSG } from '@dmi/shared/utils/constants';
import { validate } from '@dmi/shared/utils/validate';

import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import { ConditionalRender, KeyboardAvoidingScrollView } from '../../components/base_ui';
import { PageLevelError } from '../../components/AsyncRender/Errors';
import { TotpForm } from '../../components/Login';
import injectReducer from '../../utils/injectReducer';

const TotpView = ({
  dispatchChangeInput,
  dispatchResetMainError,
  dispatchResetStatus,
  dispatchResetTotp,
  dispatchTotpCancelRequest,
  dispatchTotpRequest,
  dispatchValidationError,
  error,
  navigation,
  notifications,
  passcode,
  selectedMfaOption,
  status,
  validationError,
}) => {
  useEffect(() => () => {
    dispatchResetMainError();
    dispatchResetStatus();
    dispatchResetTotp();
  }, [dispatchResetMainError, dispatchResetStatus, dispatchResetTotp]);

  const handleSubmit = useCallback(() => {
    const hasValidationError = !!validate({
      length: 6,
      required: true,
      type: ['textInput', 'length'],
      value: passcode,
    });
    if (hasValidationError) {
      dispatchValidationError(TOTP_VALIDATION_ERROR_MESSAGE);
    } else {
      dispatchTotpRequest({ passcode });
    }
  }, [dispatchTotpRequest, dispatchValidationError, passcode]);

  useEffect(() => {
    if (passcode.length === 6) {
      handleSubmit();
      Keyboard.dismiss();
    }
  }, [handleSubmit, passcode]);

  const handleCancel = () => {
    if (status !== 'succeeded') {
      dispatchTotpCancelRequest();
      navigation.navigate('Login');
    }
  };

  const shouldRenderTotpForm = error !== GENERAL_SERVER_ERROR_MSG;
  const propsToPassDown = shouldRenderTotpForm ?
    {
      error,
      handleCancel,
      handleInputChange: dispatchChangeInput,
      handleSubmit,
      passcode,
      selectedMfaOption,
      status,
      validationError,
    } :
    {
      error,
      link: {
        label: 'Return to Login screen',
        path: 'Login',
      },
    };

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <KeyboardAvoidingScrollView bannerError={error}>
        <ConditionalRender
          Component={TotpForm}
          FallbackComponent={PageLevelError}
          propsToPassDown={propsToPassDown}
          shouldRender={shouldRenderTotpForm}
        />
      </KeyboardAvoidingScrollView>
    </Fragment>
  );
};

TotpView.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchResetStatus: T.func.isRequired,
  dispatchResetTotp: T.func.isRequired,
  dispatchTotpCancelRequest: T.func.isRequired,
  dispatchTotpRequest: T.func.isRequired,
  dispatchValidationError: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  passcode: T.string.isRequired,
  selectedMfaOption: T.string,
  status: T.string.isRequired,
  validationError: T.oneOfType([T.bool, T.string]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Login
   */
  notifications: getLoginNotifications(),
  passcode: getPasscode(),
  selectedMfaOption: getSelectedMfaOption(),
  validationError: getPasscodeValidationError(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  error: getError('main'),
  status: selectMainStatus('totp'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Login
   */
  dispatchChangeInput:
    (value) => dispatch(changeInput({ field: 'passcode', form: 'totp', value })),
  dispatchResetTotp: () => dispatch(resetTotp()),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  dispatchResetMainError: () => dispatch(resetMainError()),
  dispatchResetStatus: () => dispatch(resetStatus('totp')),
  dispatchTotpCancelRequest: () => dispatch(totpCancelRequest()),
  dispatchTotpRequest: (payload) => dispatch(totpRequest(payload)),
  dispatchValidationError:
    (error) => dispatch(updateValidationError({ error, field: 'passcode', form: 'totp' })),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'login', reducer });

export default compose(withConnect, withReducer)(TotpView);
