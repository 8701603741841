import invariant from 'invariant';
import conformsTo from 'lodash/conformsTo';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import isString from 'lodash/isString';

import { DAEMON, ONCE_TILL_UNMOUNT, RESTART_ON_REMOUNT } from '@dmi/shared/utils/constants';

import checkStore from './checkStore';

const allowedModes = [RESTART_ON_REMOUNT, DAEMON, ONCE_TILL_UNMOUNT];

const checkKey = (key) =>
  invariant(
    isString(key) && !isEmpty(key),
    '(app/utils...) injectSaga: Expected `key` to be a non empty string',
  );

const checkDescriptor = (descriptor) => {
  const shape = {
    mode: (mode) => isString(mode) && allowedModes.includes(mode),
    saga: isFunction,
  };
  invariant(
    conformsTo(descriptor, shape),
    '(app/utils...) injectSaga: Expected a valid saga descriptor',
  );
};

export function injectSagaFactory(store, isValid) {
  return function injectSaga(key, descriptor = {}, args) {
    if (!isValid) checkStore(store);

    /*
      Updated default mode value from DAEMON to RESTART_ON_REMOUNT to
      automatically cancel running saga processes when container unmounts.
      Current use case is to handle 401 Unauthorized errors which force logout while
      any post-auth saga processes are still running.

      Pre-auth sagas and sagas that do not unmount on logout will have the DAEMON mode
      passed in when injected.
    */
    const newDescriptor = {
      ...descriptor,
      mode: descriptor.mode || RESTART_ON_REMOUNT,
    };
    const { mode, saga } = newDescriptor;

    checkKey(key);
    checkDescriptor(newDescriptor);

    let hasSaga = Reflect.has(store.injectedSagas, key);

    if (process.env.NODE_ENV !== 'production') {
      const oldDescriptor = store.injectedSagas[key];
      // enable hot reloading of daemon and once-till-unmount sagas
      if (hasSaga && oldDescriptor.saga !== saga) {
        oldDescriptor.task.cancel();
        hasSaga = false;
      }
    }

    if (
      !hasSaga ||
      (hasSaga && mode !== DAEMON && mode !== ONCE_TILL_UNMOUNT)
    ) {
      /* eslint-disable no-param-reassign */
      store.injectedSagas[key] = {
        ...newDescriptor,
        task: store.runSaga(saga, args),
      };
      /* eslint-enable no-param-reassign */
    }
  };
}

export function ejectSagaFactory(store, isValid) {
  return function ejectSaga(key) {
    if (!isValid) checkStore(store);

    checkKey(key);

    if (Reflect.has(store.injectedSagas, key)) {
      const descriptor = store.injectedSagas[key];
      if (descriptor.mode && descriptor.mode !== DAEMON) {
        descriptor.task.cancel();
        /**
         * Clean up in production;
         * in development we need `descriptor.sage` for hot reloading
         */
        if (process.env.NODE_ENV === 'production') {
          // Need some value to be able to detect
          // `ONCE_TILL_UNMOUNT` sagas in `injectSaga`
          // eslint-disable-next-line no-param-reassign
          store.injectedSagas[key] = 'done';
        }
      }
    }
  };
}

export default function getInjectors(store) {
  checkStore(store);

  return {
    ejectSaga: ejectSagaFactory(store, true),
    injectSaga: injectSagaFactory(store, true),
  };
}
