import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H2 } from '../../../base_ui';

export const Container = styled.View`
  padding-vertical: ${moderateScale(8)}px;
  width: 100%;
`;

export const Heading = styled(H2)`
  margin-bottom: ${moderateScale(26)}px;
`;
