import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';
import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';

import iconDictionary from '../../../../utils/iconDictionary';
import {
  Animation,
  CardButton,
  ConditionalRender,
  H5,
  P2,
  StyledScrollView,
} from '../../../base_ui';
import {
  AddEscrowContainer,
  AnimationContainer,
  CardButtonWrapper,
  ConfirmationContent,
  IconWrapper,
  LoanNumberWrapper,
  StatusWrapper,
  StyledH2,
  StyledP2,
  StyledP4,
} from '../styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');
const PendingIcon = iconDictionary('outlinedPendingPurple');

const AddEscrowConfirmation = ({
  isEligibleForAutopay,
  navigation,
  unmaskedSelectedLoan,
}) => {
  const {
    autoPayCard: {
      cardHeader,
      cardText,
      iconName,
    },
    infoText,
    pendingText,
    subheader,
    text,
  } = ADD_ESCROW_DICT.confirmationView;

  const handleCardPress = () => {
    navigation.navigate('AutopayPreConfirmation', { screen: 'AutopaySetupOverview' });
  };

  return (
    <StyledScrollView>
      <AddEscrowContainer $isDocView>
        <ConfirmationContent>
          <AnimationContainer>
            <Animation name="robotMobile" />
          </AnimationContainer>
          <StyledH2>{subheader}</StyledH2>
          <StyledP2 isConfirmation>{text}</StyledP2>
          <LoanNumberWrapper>
            <H5>
              <FormattedMessage id={INTL_IDS.MORTGAGE_ESCROW_ADD_CONFIRMATION_LABEL_LOANNUMBER} />
            </H5>
            <P2>{unmaskedSelectedLoan}</P2>
          </LoanNumberWrapper>
          <StatusWrapper>
            <IconWrapper>{PendingIcon}</IconWrapper>
            <StyledP4>{pendingText}</StyledP4>
          </StatusWrapper>
          <StatusWrapper>
            <IconWrapper>{InfoIcon}</IconWrapper>
            <StyledP4>{infoText}</StyledP4>
          </StatusWrapper>
        </ConfirmationContent>
        <CardButtonWrapper>
          <ConditionalRender
            Component={CardButton}
            propsToPassDown={{
              header: cardHeader,
              name: iconName,
              onPress: handleCardPress,
              text: cardText,
            }}
            shouldRender={isEligibleForAutopay}
          />
        </CardButtonWrapper>
      </AddEscrowContainer>
    </StyledScrollView>
  );
};

AddEscrowConfirmation.propTypes = {
  isEligibleForAutopay: T.bool.isRequired,
  navigation: T.object.isRequired,
  unmaskedSelectedLoan: T.string.isRequired,
};

export default AddEscrowConfirmation;
