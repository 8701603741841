import React, { Fragment } from 'react';
import T from 'prop-types';

import { MOBILE_CARD_BUTTON_DICTIONARY } from '@dmi/shared/utils/marketingTilesProps';

import {
  Animation,
  CardButton,
  ConditionalRender,
  FormattedMessage,
} from '../../base_ui';
import { InfoSection } from '../sharedComponents';
import {
  BoldText,
  CardButtonWrapper,
  DataLabel,
  DataLine,
  DataValue,
  InfoSectionContainer,
  StyledBorderlessButton,
  StyledPrimaryDivider,
  SuccessContainer,
  SuccessH2,
  SuccessText,
} from './styledComponents';

const SuccessComponent = ({
  amount,
  confirmationDictionary,
  confirmationNumber,
  confirmationType,
  customerServicePhoneNumber,
  date,
  dispatchSetIsModalOpen,
  infoSectionData = null,
  navigation,
  status,
}) => {
  const {
    buttonText,
    header,
    modalToOpen,
    text,
  } = status ?
    confirmationDictionary[confirmationType][status] :
    confirmationDictionary[confirmationType];
  const { nestedScreen, screen } = MOBILE_CARD_BUTTON_DICTIONARY.hardship;
  const onPress = () => navigation.navigate(screen, { nestedScreen });

  return (
    <Fragment>
      <SuccessContainer>
        <Animation name={confirmationType === 'stop' ? 'flyingMoneyMobile' : 'robotMobile'} />
        <SuccessH2>{header}</SuccessH2>
        <FormattedMessage
          defaultMessage={text}
          // eslint-disable-next-line react/prop-types
          values={{ amount, b: ({ msg }) => <BoldText>{msg}</BoldText>, date }}
          WrapperComponent={SuccessText}
        />
        <DataLine>
          <DataLabel>Confirmation Details</DataLabel>
          <DataValue>{confirmationNumber}</DataValue>
        </DataLine>
        <StyledPrimaryDivider />
        <ConditionalRender
          Component={(
            <StyledBorderlessButton
              label={buttonText}
              onPress={() => dispatchSetIsModalOpen({ key: modalToOpen, value: true })}
            />
          )}
          shouldRender={!!modalToOpen}
        />
        <ConditionalRender
          Component={() => (
            <InfoSectionContainer>
              <InfoSection
                customerServicePhoneNumber={customerServicePhoneNumber}
                data={infoSectionData}
              />
            </InfoSectionContainer>
          )}
          shouldRender={!!infoSectionData}
        />
        <ConditionalRender
          Component={(
            <CardButtonWrapper>
              <CardButton
                onPress={onPress}
                {...MOBILE_CARD_BUTTON_DICTIONARY.hardship}
              />
            </CardButtonWrapper>
          )}
          shouldRender={confirmationType === 'stop'}
        />
      </SuccessContainer>
    </Fragment>
  );
};

SuccessComponent.propTypes = {
  amount: T.string,
  confirmationDictionary: T.object.isRequired,
  confirmationNumber: T.string.isRequired,
  confirmationType: T.string.isRequired,
  customerServicePhoneNumber: T.string,
  date: T.string,
  dispatchSetIsModalOpen: T.func,
  infoSectionData: T.array,
  navigation: T.object.isRequired,
  status: T.string,
};

export default SuccessComponent;
