/*
 * Multiple Payments History
 *
 */

import React, { Fragment } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { updateSelectedTransaction } from '@dmi/shared/redux/Payments/History/actions';
import makeSelectPaymentHistory from '@dmi/shared/redux/Payments/History/selectors';

import { MultiplePaymentsHistoryList } from '../../../components/Payments/History';
import { StyledSecondaryHeader, StyledView } from './styledComponents';

const MultiplePaymentHistoryScreen = ({
  dispatchUpdateSelectedTransaction,
  groupedTransactions,
  itemsPerPage,
  navigation,
}) => (
  <Fragment>
    <StyledSecondaryHeader
      handleBack={() => navigation.goBack()}
      title={groupedTransactions[0].date}
    />
    <StyledView>
      <MultiplePaymentsHistoryList
        dispatchUpdateSelectedTransaction={dispatchUpdateSelectedTransaction}
        groupedTransactions={groupedTransactions}
        isMultiplePaymentsRows={!!groupedTransactions}
        itemsPerPage={itemsPerPage}
        navigation={navigation}
      />
    </StyledView>
  </Fragment>
);

MultiplePaymentHistoryScreen.propTypes = {
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  groupedTransactions: T.array.isRequired,
  itemsPerPage: T.number.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Payment History
   */
  // eslint-disable-next-line sort-keys
  groupedTransactions: makeSelectPaymentHistory('groupedTransactions'),
  itemsPerPage: makeSelectPaymentHistory('itemsPerPage'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Payment History
  */
  dispatchUpdateSelectedTransaction: (payload) => dispatch(updateSelectedTransaction(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(MultiplePaymentHistoryScreen);
