/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavAccountDefaultLIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M19.1389 20.0784C21.217 18.2449 22.4899 15.667 22.6822 12.9023C22.8745 10.1376 21.9708 7.40833 20.1665 5.30476C18.3623 3.20118 15.8024 1.89239 13.0407 1.66146C10.279 1.43052 7.53733 2.29601 5.40874 4.07072C3.28016 5.84544 1.93573 8.38673 1.66622 11.145C1.39672 13.9032 2.22381 16.6567 3.9686 18.8098C5.7134 20.963 8.23565 22.3428 10.9899 22.6508C13.7441 22.9589 16.5088 22.1703 18.6862 20.4558L19.1389 20.0784ZM2.84301 12.1371C2.84111 10.6056 3.21627 9.09718 3.93538 7.745C4.65448 6.39282 5.69541 5.23847 6.96628 4.38384C8.23715 3.52921 9.69887 3.0006 11.2224 2.84466C12.746 2.68871 14.2845 2.91024 15.7021 3.48969C17.1198 4.06913 18.373 4.98867 19.3511 6.16713C20.3292 7.34558 21.0022 8.74672 21.3106 10.2469C21.619 11.747 21.5533 13.3 21.1194 14.7687C20.6855 16.2375 19.8967 17.5768 18.8226 18.6685C18.273 17.3589 17.3486 16.2409 16.1656 15.455C14.9826 14.6691 13.5937 14.2504 12.1735 14.2513C10.7532 14.2523 9.36488 14.673 8.18295 15.4605C7.00101 16.248 6.07816 17.3672 5.53033 18.6776C3.80796 16.9365 2.84224 14.5861 2.84301 12.1371ZM6.53101 19.5499C6.92928 18.3697 7.6878 17.3442 8.69971 16.6179C9.71162 15.8916 10.9259 15.501 12.1715 15.5013C13.4171 15.5017 14.6312 15.8928 15.6427 16.6196C16.6543 17.3464 17.4123 18.3722 17.81 19.5526C16.192 20.7941 14.2094 21.4667 12.17 21.4662C10.1306 21.4657 8.14843 20.7921 6.53101 19.5499Z" fill="black" />
      <Path d="M12.1719 12.9998C13.0044 12.9998 13.8181 12.7529 14.5103 12.2904C15.2025 11.828 15.7419 11.1706 16.0605 10.4015C16.3791 9.63241 16.4624 8.78612 16.3 7.96965C16.1376 7.15319 15.7367 6.40322 15.1481 5.81458C14.5595 5.22594 13.8095 4.82507 12.993 4.66266C12.1766 4.50026 11.3303 4.58361 10.5612 4.90218C9.79208 5.22075 9.13473 5.76023 8.67223 6.45239C8.20974 7.14456 7.96289 7.95833 7.96289 8.79079C7.96419 9.90669 8.40805 10.9765 9.19711 11.7656C9.98617 12.5546 11.056 12.9985 12.1719 12.9998ZM12.1719 5.83179C12.7571 5.83179 13.3292 6.00533 13.8158 6.33047C14.3024 6.65561 14.6817 7.11774 14.9057 7.65843C15.1296 8.19912 15.1882 8.79407 15.074 9.36806C14.9599 9.94205 14.678 10.4693 14.2642 10.8831C13.8504 11.2969 13.3232 11.5788 12.7492 11.6929C12.1752 11.8071 11.5802 11.7485 11.0395 11.5245C10.4988 11.3006 10.0367 10.9213 9.71157 10.4347C9.38643 9.94812 9.21289 9.37602 9.21289 8.79079C9.21389 8.00632 9.52596 7.25427 10.0807 6.69957C10.6354 6.14487 11.3874 5.83281 12.1719 5.83182V5.83179Z" fill="black" />
    </Svg>
  );
}
