import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P3 } from '../../../../base_ui';

export const LinkText = styled(P3)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  margin-right: ${moderateScale(6)}px;
`;

export const StyledLink = styled.Pressable`
  flex-direction: row;
`;
