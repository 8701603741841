/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavHelpDefaultMIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M8.93012 1.29004C7.43334 1.29202 5.97075 1.73767 4.72718 2.57067C3.48362 3.40368 2.51491 4.58664 1.94349 5.97005C1.37206 7.35346 1.22358 8.87522 1.5168 10.343C1.81002 11.8108 2.53178 13.1587 3.59086 14.2164C4.64994 15.274 5.9988 15.994 7.46696 16.2853C8.93512 16.5766 10.4567 16.4261 11.8393 15.8528C13.222 15.2796 14.4037 14.3093 15.235 13.0647C16.0664 11.82 16.5101 10.3568 16.5101 8.86004C16.5075 6.85143 15.7077 4.926 14.2865 3.50663C12.8652 2.08727 10.9387 1.29004 8.93012 1.29004ZM8.93012 15.29C7.6663 15.29 6.43086 14.9153 5.38003 14.2131C4.3292 13.511 3.51017 12.513 3.02653 11.3454C2.54289 10.1778 2.41634 8.89295 2.6629 7.65341C2.90946 6.41387 3.51805 5.27528 4.41171 4.38163C5.30537 3.48797 6.44395 2.87938 7.68349 2.63282C8.92303 2.38626 10.2078 2.5128 11.3755 2.99645C12.5431 3.48009 13.5411 4.29912 14.2432 5.34995C14.9454 6.40078 15.3201 7.63622 15.3201 8.90004C15.3069 10.587 14.6282 12.2006 13.4316 13.3897C12.2349 14.5789 10.6171 15.2475 8.93012 15.25V15.29Z" fill="black" />
      <Path d="M8.84007 11.78C8.70799 11.7757 8.57649 11.7994 8.45415 11.8493C8.33181 11.8993 8.22139 11.9745 8.13007 12.07C8.03521 12.1571 7.95947 12.263 7.90766 12.3809C7.85585 12.4988 7.8291 12.6262 7.8291 12.755C7.8291 12.8838 7.85585 13.0112 7.90766 13.1291C7.95947 13.2471 8.03521 13.3529 8.13007 13.44C8.22552 13.5321 8.33834 13.6043 8.46194 13.6524C8.58555 13.7004 8.71749 13.7234 8.85007 13.72C8.98168 13.7208 9.11214 13.6956 9.23398 13.6458C9.35582 13.596 9.46663 13.5227 9.56007 13.43C9.65632 13.3438 9.73329 13.2382 9.78599 13.1201C9.83869 13.0021 9.86593 12.8743 9.86593 12.745C9.86593 12.6158 9.83869 12.488 9.78599 12.3699C9.73329 12.2519 9.65632 12.1463 9.56007 12.06C9.46463 11.968 9.35181 11.8958 9.2282 11.8477C9.1046 11.7996 8.97266 11.7766 8.84007 11.78Z" fill="black" />
      <Path d="M8.94027 4.26996C8.21287 4.23235 7.49823 4.47175 6.94027 4.93996C6.408 5.40648 6.08104 6.06399 6.03027 6.76996H7.66027C7.68933 6.47353 7.82849 6.19877 8.05027 5.99996C8.27502 5.79088 8.57349 5.6794 8.88027 5.68996C9.02543 5.68458 9.17018 5.70854 9.30587 5.7604C9.44155 5.81226 9.56538 5.89096 9.66994 5.99179C9.7745 6.09261 9.85765 6.2135 9.91441 6.34721C9.97117 6.48092 10.0004 6.6247 10.0003 6.76996C10.0083 7.06851 9.90082 7.35865 9.70027 7.57996L8.70027 8.57996C8.48564 8.77683 8.30341 9.00631 8.16027 9.25996C8.05765 9.50654 8.01317 9.77343 8.03027 10.04V10.84H9.57027V10.25C9.53845 9.94787 9.62046 9.64478 9.80027 9.39996C9.87795 9.30803 9.96146 9.22118 10.0503 9.13996L10.4103 8.78996L10.7603 8.44996C10.8962 8.30807 11.0232 8.15777 11.1403 7.99996C11.4379 7.62944 11.5939 7.16503 11.5803 6.68996C11.5979 6.3568 11.5406 6.02393 11.4125 5.71587C11.2845 5.40781 11.0889 5.1324 10.8403 4.90996C10.3086 4.46773 9.63116 4.23954 8.94027 4.26996Z" fill="black" />
    </Svg>
  );
}
