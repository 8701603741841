/*
 * Upload Review Screen
 */
import React, { useCallback } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  chooseDocument,
  processDocument,
  resetBannerError,
  resetStatusState,
  uploadDocument,
} from '@dmi/shared/redux/Settings/Loans/actions';
import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import {
  selectBannerError,
  selectForm,
  selectFormErrors,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Loans/selectors';

import ReviewScene from '../../../components/DocUpload/ReviewScene';

const {
  header,
  text: headerSubText,
} = LOANS_CONTENT_DICTIONARY.changeMailingAddress.reviewUploadView;

const UploadReviewScreen = ({
  bannerError,
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  dispatchResetBannerError,
  dispatchResetStatusState,
  navigation,
  processDocumentRequestStatus,
  status,
  uploadDocumentForm: { fileInput: { fileName } },
  uploadDocumentFormErrors: { fileInput: validationErrorMessage },
}) => {
  useFocusEffect(
    useCallback(() => () => {
      dispatchResetBannerError();
      dispatchResetStatusState(['chooseDocument', 'processDocument', 'uploadDocument']);
    }, [dispatchResetBannerError, dispatchResetStatusState]),
  );

  return (
    <ReviewScene
      bannerError={bannerError}
      bottomButtonProps={{
        label: 'Next Step',
        onPress: () => navigation.navigate('ChangeMailingAddress'),
        status,
      }}
      chooseDocumentRequestStatus={chooseDocumentRequestStatus}
      dispatchChooseDocument={dispatchChooseDocument}
      fileName={fileName}
      header={header}
      headerSubText={headerSubText}
      navigation={navigation}
      processDocumentRequestStatus={processDocumentRequestStatus}
      validationErrorMessage={validationErrorMessage}
    />
  );
};

UploadReviewScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetStatusState: T.func.isRequired,
  navigation: T.object.isRequired,
  processDocumentRequestStatus: T.string.isRequired,
  status: T.string.isRequired,
  uploadDocumentForm: T.shape({ fileInput: T.shape({ fileName: T.string.isRequired }) }).isRequired,
  uploadDocumentFormErrors: T.shape({ fileInput: T.string.isRequired }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  bannerError: selectBannerError(),
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
  processDocumentRequestStatus: selectRequestStatus('processDocument'),
  status: selectRequestStatus('changeMailingAddress'),
  uploadDocumentForm: selectForm('uploadDocument'),
  uploadDocumentFormErrors: selectFormErrors('uploadDocument'),
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Loans
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchProcessDocument: (payload) => dispatch(processDocument(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetStatusState: (payload) => dispatch(resetStatusState(payload)),
  dispatchUploadDocument: (payload) => dispatch(uploadDocument(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadReviewScreen);
