import styled from 'styled-components/native';

export const NotificationsWrapper = styled.Text`
  border: 0;
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;
