import React from 'react';

import { Container, Shadow } from './styledComponents';

const TertiaryDivider = () => (
  <Container>
    <Shadow />
  </Container>
);

export default TertiaryDivider;
