/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

export default function Treehouse(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(210)} viewBox="0 0 165 210" width={moderateScale(165)} {...props}>
      <Polygon fill="#e6ebef" points="63.67 123.15 82.38 148.09 84.1 124.14 63.67 123.15" />
      <Path d="M150.32,73.1a24.41,24.41,0,0,0-21.57-35.82h-.17a14.66,14.66,0,0,0-25.14-13,30.93,30.93,0,0,0-55.88,6.55,24.39,24.39,0,0,0-27,27.7,17.42,17.42,0,0,0-6.83,33.43A29.4,29.4,0,0,0,39.14,136.1c7.64,0,17.23-.86,23.19-7.71h22a13.1,13.1,0,0,0,25.13,0h3.76a26.61,26.61,0,0,0,3,.16,27.41,27.41,0,0,0,27-22.69,16.61,16.61,0,0,0,1.74.09,16.87,16.87,0,0,0,5.44-32.85Z" fill="#eef1f4" />
      <Path d="M101.74,130.54c-5,1.54-11.36-.52-11.36-.52l-.53,5.82a13.08,13.08,0,0,0,19.42-7.45h0c.21-1,.35-1.79.43-2.45a.46.46,0,0,0-.82-.35A15.37,15.37,0,0,1,101.74,130.54Z" fill="#d2dbe2" />
      <Rect fill="#bac2ca" height="1.36" width="15.59" x="40.42" y="121.12" />
      <Rect fill="#bac2ca" height="1.36" width="20.43" x="62.96" y="121.12" />
      <Rect fill="#bac2ca" height="1.36" width="29.15" x="92.45" y="121.12" />
      <Path d="M128.57,36.93l.1-.09a13.48,13.48,0,0,0-.27-6.34,14.83,14.83,0,0,0-17-10.73,14.63,14.63,0,0,0-7.94,4.49A31,31,0,0,0,66.06,11.37v.11S86.36,7.28,97,23c0,0,2.19,3.45,3.8,6.32a1.77,1.77,0,0,0,2.84.34c2-2.16,5.73-5.33,10.26-5.3C120.8,24.36,126.9,27.84,128.57,36.93Z" fill="#d2dbe2" />
      <Path d="M44.69,30.64a24.41,24.41,0,0,0-24.4,24.41,24.72,24.72,0,0,0,.25,3.46A17.39,17.39,0,0,0,4.62,82.9l.08,0S4,73.39,9.79,68C14.94,63.25,21,63.7,24.16,63.77h0a1.49,1.49,0,0,0,1.47-1.6c-.3-4-.14-14.14,4.56-19.41C36,36.25,43.69,34,52.5,34.23a.82.82,0,0,0,.38-1.57,29.19,29.19,0,0,0-5.69-1.88C46.37,30.7,45.54,30.64,44.69,30.64Z" fill="#d2dbe2" />
      <Path d="M60.78,122.83,57,123.1c-5.2,5.56-21.73,9.49-33.71.77s-9.45-30.32-9.45-30.32a16.66,16.66,0,0,0,0-1.67l-.67-.18.51.24A29.4,29.4,0,0,0,39.14,136.1c7.64,0,17.23-.86,23.19-7.71h4Z" fill="#d2dbe2" />
      <Path d="M137.36,63.41,125,65.73a8,8,0,0,0-3.94,1.75L107.74,80.67,103.8,81l16-15.68a5.53,5.53,0,0,0,1-2.6l3.35-15.06a.71.71,0,0,1,.9-.54h0a.72.72,0,0,1,.52.85l-3.36,15.57a1,1,0,0,0,1.16,1.19l13.76-2.58a.65.65,0,0,1,.76.56h0A.64.64,0,0,1,137.36,63.41Z" fill="#bac2ca" />
      <Path d="M87.74,31.5l-4.39,13A11.19,11.19,0,0,0,82,47.07a4.43,4.43,0,0,0,.61,4.07L93.94,71.83l-3.83,4.34L78,49.44c-.61-1.24-1.46-2.77-2.09-3.8L64,26.38a1.07,1.07,0,0,1,.4-1.49h0a1.06,1.06,0,0,1,1.41.37L78.18,45.07a1.46,1.46,0,0,0,2.44.07l5.57-14.73a.94.94,0,0,1,1.37-.19h0A.94.94,0,0,1,87.74,31.5Z" fill="#bac2ca" />
      <Path d="M39.14,136.29a29.39,29.39,0,0,1-7.77-1,.19.19,0,0,1-.13-.23.19.19,0,0,1,.23-.14,29.11,29.11,0,0,0,7.67,1c7.63,0,17.16-.87,23-7.64a.17.17,0,0,1,.14-.07H71a.2.2,0,0,1,.19.19.19.19,0,0,1-.19.19H62.42C56.42,135.41,46.83,136.29,39.14,136.29Z" fill="#000" />
      <Path d="M28.32,134.24l-.08,0A29.58,29.58,0,0,1,13.44,92,17.6,17.6,0,0,1,8.11,63.49a.18.18,0,0,1,.27,0,.19.19,0,0,1,0,.26,17.22,17.22,0,0,0,5.41,28,.19.19,0,0,1,.1.12.16.16,0,0,1,0,.15,29.22,29.22,0,0,0,14.51,41.84.19.19,0,0,1-.07.37Z" fill="#000" />
      <Path d="M9.58,62.6a.18.18,0,0,1-.15-.07.2.2,0,0,1,0-.27,17.68,17.68,0,0,1,10.86-3.94,24.28,24.28,0,0,1-.22-3.27,24.62,24.62,0,0,1,24.59-24.6,24.1,24.1,0,0,1,2.89.18.19.19,0,0,1,.17.21.18.18,0,0,1-.21.16,25.59,25.59,0,0,0-2.85-.17A24.24,24.24,0,0,0,20.48,55.05a23.72,23.72,0,0,0,.25,3.44.21.21,0,0,1-.05.15.18.18,0,0,1-.14.06A17.3,17.3,0,0,0,9.7,62.56.19.19,0,0,1,9.58,62.6Z" fill="#000" />
      <Path d="M128.82,36h0a.2.2,0,0,1-.17-.21,13.16,13.16,0,0,0,.1-1.65,14.47,14.47,0,0,0-25.15-9.78.23.23,0,0,1-.16.06.19.19,0,0,1-.14-.09,30.74,30.74,0,0,0-54.93,4.8.18.18,0,0,1-.24.1A.18.18,0,0,1,48,29a31.12,31.12,0,0,1,55.47-5.08,14.85,14.85,0,0,1,25.64,10.23,13.69,13.69,0,0,1-.1,1.69A.19.19,0,0,1,128.82,36Z" fill="#000" />
      <Path d="M133.55,122.51a.21.21,0,0,1-.15-.07.18.18,0,0,1,0-.27A27.25,27.25,0,0,0,143,105.83a.19.19,0,0,1,.21-.16,16.51,16.51,0,0,0,1.72.09,16.69,16.69,0,0,0,5.38-32.48.18.18,0,0,1-.12-.11.22.22,0,0,1,0-.16,24.22,24.22,0,0,0-21.4-35.54h-.17c-.09,0-.19-.09-.19-.19a.18.18,0,0,1,.19-.19h.17A24.59,24.59,0,0,1,150.59,73a17.06,17.06,0,0,1-5.71,33.14c-.52,0-1,0-1.58-.07a27.6,27.6,0,0,1-9.63,16.39A.21.21,0,0,1,133.55,122.51Z" fill="#000" />
      <Path d="M96.84,138a13.23,13.23,0,0,1-4-.6.18.18,0,0,1-.12-.23A.19.19,0,0,1,93,137a12.7,12.7,0,0,0,3.83.58,13,13,0,0,0,12.39-9.26.18.18,0,0,1,.19-.14l3.57.11a20.31,20.31,0,0,0,2.36.06h.79a27.07,27.07,0,0,0,15.15-4.6.19.19,0,0,1,.27,0,.19.19,0,0,1-.06.26,27.39,27.39,0,0,1-15.36,4.67h-.78a20.78,20.78,0,0,1-2.39-.06l-3.42-.1A13.36,13.36,0,0,1,96.84,138Z" fill="#000" />
      <Path d="M20.63,106l12.78,1.12a7.43,7.43,0,0,1,3.07,1l10.33,6.55,3.3-1.57-12.94-6.86a7.58,7.58,0,0,0-3.16-.87l-13.27-.8a.71.71,0,0,0-.75.68h0A.71.71,0,0,0,20.63,106Z" fill="#bac2ca" />
      <Rect fill="#fff" height="35.29" width="34.25" x="82.88" y="82.66" />
      <Rect fill="#bcb1ce" height="1.97" width="8.88" x="108.11" y="90.17" />
      <Path d="M82.93,86.48l5.69,5.26a2,2,0,0,0,1.36.53h2.74l0-2.91-9.8-6.88Z" fill="#bcb1ce" />
      <Rect fill={svgHighlight} height="11.18" width="15.24" x="92.87" y="90.14" />
      <Polygon fill="#fff" points="46.46 80.31 46.46 119.22 82.99 119.22 82.99 73.8 67.42 63.7 62.16 64.99 46.46 80.31" />
      <Polygon fill="#bac2ca" points="45.78 83.47 46.5 83.65 64.67 67.11 63.63 65.85 45.51 82.34 45.78 83.47" />
      <Polygon fill="#bac2ca" points="45.78 83.47 46.5 83.65 64.67 67.11 63.63 65.85 45.51 82.34 45.78 83.47" />
      <Path d="M91.44,87.78h34.8a1.14,1.14,0,0,1,1.14,1.14V89a1.14,1.14,0,0,1-1.14,1.14H91.44a0,0,0,0,1,0,0V87.78a0,0,0,0,1,0,0Z" fill="#fff" />
      <Path d="M126.2,90.38H91.44a.26.26,0,0,1-.26-.26V87.78a.25.25,0,0,1,.26-.26H126.2a1.43,1.43,0,1,1,0,2.86Zm-34.5-.52h34.5a.92.92,0,1,0,0-1.83H91.7Z" fill="#000" />
      <Path d="M126.12,90.32H90.21a.26.26,0,0,1-.26-.26.26.26,0,0,1,.26-.26h35.91a.78.78,0,0,0,.78-.78v-.19a.78.78,0,0,0-.78-.78h-.82a.26.26,0,0,1,0-.52h.82a1.3,1.3,0,0,1,1.3,1.3V89A1.3,1.3,0,0,1,126.12,90.32Z" fill="#000" />
      <Polygon fill="#fff" points="126.3 87.41 99.35 62.69 63.88 62.69 91.2 87.77 88.12 87.77 126.3 87.41" />
      <Path d="M64.35,63.2a1.34,1.34,0,0,0-1.9,0L36.53,87a1.1,1.1,0,0,0,.14,1.64,1.33,1.33,0,0,0,1.78-.13l25-22.78,26,23.92a1.34,1.34,0,0,0,.94.38,1.29,1.29,0,0,0,.85-.31,1.09,1.09,0,0,0,.09-1.64Z" fill="#fff" />
      <Line fill="#fff" x1="38.2" x2="46.19" y1="88.96" y2="88.96" />
      <Path d="M46.19,89.22h-8a.26.26,0,1,1,0-.52h8a.26.26,0,0,1,0,.52Z" fill="#000" />
      <Path d="M126.62,88H91.29a.25.25,0,0,1-.26-.26.26.26,0,0,1,.26-.26H126L99.24,62.94H74.79a.26.26,0,0,1-.26-.26.26.26,0,0,1,.26-.26H99.35a.28.28,0,0,1,.17.07l27.27,25.09a.27.27,0,0,1,.07.29A.26.26,0,0,1,126.62,88Z" fill="#000" />
      <Line fill="#fff" x1="71.81" x2="72.99" y1="62.68" y2="62.68" />
      <Path d="M73,62.94H71.81a.26.26,0,0,1-.26-.26.26.26,0,0,1,.26-.26H73a.26.26,0,0,1,.26.26A.26.26,0,0,1,73,62.94Z" fill="#000" />
      <Line fill="#000" x1="63.37" x2="70.13" y1="62.73" y2="62.68" />
      <Path d="M63.37,63a.26.26,0,0,1,0-.52l6.76,0a.26.26,0,0,1,0,.52L63.37,63Z" fill="#000" />
      <Path d="M48,76.48,36.53,87a1.1,1.1,0,0,0,.14,1.64,1.33,1.33,0,0,0,1.78-.13l25-22.78,26,23.92a1.34,1.34,0,0,0,.94.38,1.29,1.29,0,0,0,.85-.31,1.09,1.09,0,0,0,.09-1.64L64.35,63.2a1.34,1.34,0,0,0-1.9,0L49.66,75" fill="#fff" />
      <Path d="M90.36,90.31a1.6,1.6,0,0,1-1.12-.46L63.45,66.1,38.63,88.72a1.6,1.6,0,0,1-2.12.14,1.38,1.38,0,0,1-.54-1,1.36,1.36,0,0,1,.37-1l11.5-10.56a.26.26,0,1,1,.35.39L36.7,87.21a.82.82,0,0,0-.21.63.81.81,0,0,0,.34.61,1.07,1.07,0,0,0,1.43-.1l25-22.79a.26.26,0,0,1,.35,0l26,23.91a1.1,1.1,0,0,0,1.45.07.86.86,0,0,0,.32-.62.85.85,0,0,0-.26-.64L64.18,63.39a1.15,1.15,0,0,0-.78-.31,1.07,1.07,0,0,0-.76.33L49.84,75.16a.26.26,0,0,1-.37,0,.25.25,0,0,1,0-.36L62.28,63a1.58,1.58,0,0,1,1.11-.48h0a1.58,1.58,0,0,1,1.12.46L91.47,87.91a1.39,1.39,0,0,1,.42,1,1.36,1.36,0,0,1-.51,1A1.56,1.56,0,0,1,90.36,90.31Z" fill="#000" />
      <Path d="M53.46,120.26l10.67,9.2a20.26,20.26,0,0,1,4.62,5.79L89.4,174.18a20.25,20.25,0,0,1,2.37,9.52v17.81h17.82V183.16a22,22,0,0,0-2.5-10.17L92.46,145.06a11.69,11.69,0,0,1-1.28-6.41L92.78,121l-10.31.09-1.69,18.21a1.16,1.16,0,0,1-2.17.46l-4.43-7.93A22.22,22.22,0,0,0,69,125.57l-7.13-5.95Z" fill="#bac2ca" />
      <Polygon fill="#8a97a4" points="93.57 164.5 84.42 164.5 83.64 163.01 93.57 163.01 93.57 164.5" />
      <Polygon fill="#8a97a4" points="102.55 186.32 91.87 186.32 91.84 184.83 102.55 184.83 102.55 186.32" />
      <Polygon fill="#8a97a4" points="98.92 196.32 91.76 196.32 91.74 194.83 98.92 194.83 98.92 196.32" />
      <Polygon fill="#8a97a4" points="94.92 174.19 107.58 174.19 106.9 172.7 94.92 172.7 94.92 174.19" />
      <Polygon fill="#8a97a4" points="88.42 154.91 79.27 154.91 78.49 153.42 88.42 153.42 88.42 154.91" />
      <Polygon fill="#8a97a4" points="81.73 146.23 74.62 146.23 73.84 144.74 81.73 144.74 81.73 146.23" />
      <Polygon fill="#8a97a4" points="76.57 136.2 69.29 136.2 68.51 134.71 75.77 134.71 76.57 136.2" />
      <Polygon fill="#8a97a4" points="71.5 128.11 62.69 128.11 60.94 126.62 70.07 126.62 71.5 128.11" />
      <Polygon fill="#8a97a4" points="65.01 122.5 56.2 122.5 54.45 121.01 63.58 121.01 65.01 122.5" />
      <Polygon fill="#8a97a4" points="92.68 122.5 82.44 122.5 82.35 121.01 92.58 121.01 92.68 122.5" />
      <Path d="M109.59,201.7H91.77a.2.2,0,0,1-.19-.19V183.7a20.18,20.18,0,0,0-2.35-9.44L68.58,135.34A19.84,19.84,0,0,0,64,129.61L53.34,120.4a.19.19,0,0,1-.06-.2.17.17,0,0,1,.17-.13l8.4-.64a.24.24,0,0,1,.14,0l7.13,5.94a22.51,22.51,0,0,1,5.23,6.3l4.42,7.92a1,1,0,0,0,1.82-.38l1.69-18.21a.19.19,0,0,1,.19-.17l10.31-.09h0a.2.2,0,0,1,.14.06.25.25,0,0,1,0,.15l-1.6,17.66A11.4,11.4,0,0,0,92.63,145l5.66,10.81A.19.19,0,0,1,98,156l-5.66-10.81A11.65,11.65,0,0,1,91,138.63l1.57-17.46-9.92.09-1.68,18a1.31,1.31,0,0,1-1.07,1.2,1.32,1.32,0,0,1-1.46-.66L74,131.91a22.07,22.07,0,0,0-5.14-6.19l-7.08-5.9-7.87.59,10.32,8.91a20.33,20.33,0,0,1,4.67,5.84l20.65,38.93A20.48,20.48,0,0,1,92,183.7v17.62H109.4V183.16a21.87,21.87,0,0,0-2.48-10.08l-7.77-14.84a.18.18,0,0,1,.08-.25.19.19,0,0,1,.26.08l7.76,14.83a22.12,22.12,0,0,1,2.53,10.26v18.35A.2.2,0,0,1,109.59,201.7Z" fill="#000" />
      <Rect fill="#fff" height="2.4" width="44.38" x="37.1" y="118.18" />
      <Rect fill="#fff" height="2.61" width="43.9" x="80.25" y="118.2" />
      <Path d="M124.22,121.14H37.12a.26.26,0,0,1-.26-.26v-2.79a.25.25,0,0,1,.26-.26h87.1a.25.25,0,0,1,.26.26v2.79A.26.26,0,0,1,124.22,121.14Zm-86.84-.52H124v-2.27H37.38Z" fill="#000" />
      <Path d="M46.46,118.35a.26.26,0,0,1-.26-.26V82.48a.25.25,0,0,1,.26-.26.26.26,0,0,1,.26.26v35.61A.26.26,0,0,1,46.46,118.35Z" fill="#000" />
      <Path d="M82.86,118.31a.26.26,0,0,1-.26-.26V105.19a.25.25,0,0,1,.26-.26.26.26,0,0,1,.26.26v12.86A.26.26,0,0,1,82.86,118.31Z" fill="#000" />
      <Path d="M82.86,102.53a.26.26,0,0,1-.26-.26V83.49a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v18.78A.26.26,0,0,1,82.86,102.53Z" fill="#000" />
      <Path d="M117,118.26a.26.26,0,0,1-.26-.26V90.14a.26.26,0,0,1,.26-.26.25.25,0,0,1,.26.26V118A.26.26,0,0,1,117,118.26Z" fill="#000" />
      <Path d="M128.91,201.67h-1.56a.2.2,0,0,1-.2-.19.19.19,0,0,1,.2-.19h1.56a.19.19,0,0,1,.19.19A.2.2,0,0,1,128.91,201.67Z" fill="#000" />
      <Path d="M53.44,201.67H49.58a.2.2,0,0,1-.2-.19.19.19,0,0,1,.2-.19h3.86a.19.19,0,0,1,.19.19A.2.2,0,0,1,53.44,201.67Z" fill="#000" />
      <Path d="M45.37,201.67h-2a.19.19,0,0,1-.19-.19.18.18,0,0,1,.19-.19h2a.18.18,0,0,1,.19.19A.19.19,0,0,1,45.37,201.67Z" fill="#000" />
      <Path d="M125.83,201.67h-8.89a.19.19,0,0,1-.19-.19.18.18,0,0,1,.19-.19h8.89a.18.18,0,0,1,.19.19A.19.19,0,0,1,125.83,201.67Z" fill="#000" />
      <Path d="M115.3,201.67H56.22a.19.19,0,0,1-.19-.19.18.18,0,0,1,.19-.19H115.3a.18.18,0,0,1,.19.19A.19.19,0,0,1,115.3,201.67Z" fill="#000" />
      <Path d="M16.44,77.08l10,7.59A7.85,7.85,0,0,1,29.24,88l6,17.74,3.39,2L31.28,86.59a5.61,5.61,0,0,1,.3-2.76l3.71-15a.73.73,0,0,0-.57-.89h0a.74.74,0,0,0-.84.54L30,83.94a1,1,0,0,1-1.57.55L17.22,76.05a.64.64,0,0,0-.93.16h0A.63.63,0,0,0,16.44,77.08Z" fill="#bac2ca" />
      <Path d="M37.19,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v13.61A.26.26,0,0,1,37.19,117.9Z" fill="#000" />
      <Path d="M43.88,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.52,0v13.61A.26.26,0,0,1,43.88,117.9Z" fill="#000" />
      <Path d="M50.57,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.52,0v13.61A.26.26,0,0,1,50.57,117.9Z" fill="#000" />
      <Path d="M51.52,103.93H36a.26.26,0,0,1-.26-.26.26.26,0,0,1,.26-.26H51.52a.26.26,0,0,1,.26.26A.26.26,0,0,1,51.52,103.93Z" fill="#000" />
      <Path d="M73.59,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v13.61A.26.26,0,0,1,73.59,117.9Z" fill="#000" />
      <Path d="M79.33,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v13.61A.26.26,0,0,1,79.33,117.9Z" fill="#000" />
      <Path d="M86,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.52,0v13.61A.26.26,0,0,1,86,117.9Z" fill="#000" />
      <Path d="M94.34,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v13.61A.26.26,0,0,1,94.34,117.9Z" fill="#000" />
      <Path d="M102.65,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.52,0v13.61A.26.26,0,0,1,102.65,117.9Z" fill="#000" />
      <Path d="M111,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v13.61A.26.26,0,0,1,111,117.9Z" fill="#000" />
      <Path d="M119.28,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.52,0v13.61A.26.26,0,0,1,119.28,117.9Z" fill="#000" />
      <Path d="M123.89,117.9a.26.26,0,0,1-.26-.26V104a.26.26,0,0,1,.52,0v13.61A.26.26,0,0,1,123.89,117.9Z" fill="#000" />
      <Path d="M125.18,103.93H72.62a.26.26,0,0,1-.26-.26.26.26,0,0,1,.26-.26h52.56a.26.26,0,0,1,.26.26A.26.26,0,0,1,125.18,103.93Z" fill="#000" />
      <Path d="M54.77,117.76V96.86a8.14,8.14,0,0,1,8.14-8.15h0A8.14,8.14,0,0,1,71,96.86v20.9" fill={svgHighlight} />
      <Path d="M71,118a.26.26,0,0,1-.26-.26V96.85a7.88,7.88,0,1,0-15.75,0v20a.25.25,0,0,1-.26.26.26.26,0,0,1-.26-.26v-20a8.4,8.4,0,1,1,16.79,0v20.88A.26.26,0,0,1,71,118Z" fill="#000" />
      <Path d="M56.13,118a.18.18,0,0,1-.18-.18v-21a8.33,8.33,0,0,1,7.25-8.24.16.16,0,0,1,.19.15.17.17,0,0,1-.15.19,8,8,0,0,0-6.94,7.9v21A.18.18,0,0,1,56.13,118Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="24.33" x="93.06" y="170.56" />
      <Path d="M117.39,172.68H93.06a.19.19,0,0,1-.19-.19v-1.93a.2.2,0,0,1,.19-.19h24.33a.2.2,0,0,1,.2.19v1.93A.19.19,0,0,1,117.39,172.68Zm-24.14-.38h24v-1.55h-24Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="12.89" x="90.51" y="182.79" />
      <Path d="M103.4,184.9H90.51a.18.18,0,0,1-.19-.19v-1.92a.18.18,0,0,1,.19-.19H103.4a.18.18,0,0,1,.19.19v1.92A.18.18,0,0,1,103.4,184.9Zm-12.7-.38h12.51V183H90.7Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="12.89" x="87.23" y="192.84" />
      <Path d="M100.13,195H87.23a.2.2,0,0,1-.19-.19v-1.93a.19.19,0,0,1,.19-.19h12.9a.19.19,0,0,1,.19.19v1.93A.2.2,0,0,1,100.13,195Zm-12.71-.38H99.94V193H87.42Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="13.73" x="69.09" y="142.77" />
      <Path d="M82.82,144.89H69.09a.19.19,0,0,1-.19-.19v-1.93a.19.19,0,0,1,.19-.19H82.82a.19.19,0,0,1,.19.19v1.93A.19.19,0,0,1,82.82,144.89Zm-13.54-.38H82.63V143H69.28Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="14.31" x="62.31" y="132.67" />
      <Path d="M76.62,134.79H62.31a.19.19,0,0,1-.19-.19v-1.93a.19.19,0,0,1,.19-.19H76.62a.19.19,0,0,1,.19.19v1.93A.19.19,0,0,1,76.62,134.79Zm-14.12-.38H76.43v-1.55H62.5Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="14.31" x="55.83" y="124.61" />
      <Path d="M70.14,126.73H55.83a.2.2,0,0,1-.19-.19v-1.93a.2.2,0,0,1,.19-.19H70.14a.2.2,0,0,1,.2.19v1.93A.2.2,0,0,1,70.14,126.73ZM56,126.35H70V124.8H56Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="13.73" x="80.75" y="160.94" />
      <Path d="M94.48,163.06H80.75a.19.19,0,0,1-.19-.2v-1.92a.18.18,0,0,1,.19-.19H94.48a.18.18,0,0,1,.19.19v1.92A.19.19,0,0,1,94.48,163.06Zm-13.54-.39H94.29v-1.54H80.94Z" fill="#000" />
      <Rect fill="#fff" height="1.93" width="14.31" x="75.1" y="151.31" />
      <Path d="M89.41,153.43H75.1a.19.19,0,0,1-.19-.19v-1.93a.19.19,0,0,1,.19-.19H89.41a.19.19,0,0,1,.19.19v1.93A.19.19,0,0,1,89.41,153.43Zm-14.12-.38H89.22V151.5H75.29Z" fill="#000" />
      <Polygon fill="#bac2ca" points="107.7 176.8 105.74 176.8 108.72 172.5 110.68 172.5 107.7 176.8" />
      <Path d="M107.7,177h-2a.19.19,0,0,1-.16-.3l3-4.3a.21.21,0,0,1,.16-.08h2a.19.19,0,0,1,.16.3l-3,4.3A.21.21,0,0,1,107.7,177Zm-1.6-.38h1.5l2.72-3.92h-1.5Z" fill="#000" />
      <Path d="M29.69,154.76a10.12,10.12,0,1,0,10.12,10.12A10.12,10.12,0,0,0,29.69,154.76Zm0,16a5.87,5.87,0,1,1,5.87-5.87A5.87,5.87,0,0,1,29.69,170.75Z" fill="#bac2ca" />
      <Path d="M29.69,175.26a10.4,10.4,0,0,1-10.38-10.38,10.17,10.17,0,0,1,.31-2.52.26.26,0,1,1,.5.12,10.17,10.17,0,0,0-.29,2.4,9.83,9.83,0,1,0,.87-4.07.26.26,0,0,1-.47-.21,10.38,10.38,0,1,1,9.46,14.66Zm0-4.25a6.13,6.13,0,1,1,6.13-6.13,6.06,6.06,0,0,1-1.8,4.33.25.25,0,0,1-.36-.36,5.72,5.72,0,1,0-1.23.92.26.26,0,0,1,.26.46A6.16,6.16,0,0,1,29.69,171Z" fill="#000" />
      <Path d="M29.92,159a.26.26,0,0,1-.26-.26V105.07a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v53.71A.26.26,0,0,1,29.92,159Z" fill="#000" />
      <Path d="M63.42,44.2c-1.84-.7-2.67-.16-4.29.2s-3.7-.93-3.7-.93l1.47,2.78-1.5,1.49s2,1.31,3.63.95,2.56-.91,4.41-.22" fill="#fff" />
      <Path d="M58.44,49a6.77,6.77,0,0,1-3.16-1.06.21.21,0,0,1,0-.34l1.37-1.37-1.39-2.64a.23.23,0,0,1,0-.27.21.21,0,0,1,.27,0s2,1.23,3.54.9c.33-.07.63-.15.91-.23a4.82,4.82,0,0,1,3.51,0,.21.21,0,0,1,.13.29.23.23,0,0,1-.29.13,4.39,4.39,0,0,0-3.23,0l-.93.24A5.37,5.37,0,0,1,56,44l1.13,2.14a.23.23,0,0,1,0,.26l-1.29,1.28a5.17,5.17,0,0,0,3.22.78c.32-.07.62-.15.9-.23a5.13,5.13,0,0,1,3.63,0,.23.23,0,0,1,.13.29.22.22,0,0,1-.29.13,4.65,4.65,0,0,0-3.35,0c-.29.08-.59.16-.93.23A2.73,2.73,0,0,1,58.44,49Z" fill="#000" />
      <Path d="M63.49,63.05a.26.26,0,0,1-.26-.26V43.35a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26V62.79A.26.26,0,0,1,63.49,63.05Z" fill="#000" />
      <Path d="M61.91,76.05a.17.17,0,0,1-.17-.17V67.2a.18.18,0,0,1,.17-.17.18.18,0,0,1,.18.17v8.68A.17.17,0,0,1,61.91,76.05Z" fill="#000" />
      <Path d="M61.91,81.54a.17.17,0,0,1-.17-.17V77.82a.18.18,0,0,1,.17-.17.18.18,0,0,1,.18.17v3.55A.17.17,0,0,1,61.91,81.54Z" fill="#000" />
      <Path d="M69.64,82a.17.17,0,0,1-.17-.18V80.29a.17.17,0,0,1,.17-.18.18.18,0,0,1,.17.18v1.53A.18.18,0,0,1,69.64,82Z" fill="#000" />
      <Path d="M57.57,84.91a.18.18,0,0,1-.17-.17V71a.17.17,0,0,1,.17-.17.17.17,0,0,1,.18.17V84.74A.18.18,0,0,1,57.57,84.91Z" fill="#000" />
      <Path d="M52.65,81.79a.17.17,0,0,1-.18-.17V75.56a.18.18,0,0,1,.18-.17.18.18,0,0,1,.17.17v6.06A.17.17,0,0,1,52.65,81.79Z" fill="#000" />
      <Path d="M69.64,79.15a.17.17,0,0,1-.17-.17V71.63a.17.17,0,0,1,.17-.17.18.18,0,0,1,.17.17V79A.18.18,0,0,1,69.64,79.15Z" fill="#000" />
      <Path d="M75.24,84.3a.18.18,0,0,1-.17-.17V76.78a.18.18,0,0,1,.17-.17.18.18,0,0,1,.18.17v7.35A.18.18,0,0,1,75.24,84.3Z" fill="#000" />
      <Path d="M65.69,74.06a.17.17,0,0,1-.18-.17V69.73a.18.18,0,0,1,.18-.17.18.18,0,0,1,.17.17v4.16A.17.17,0,0,1,65.69,74.06Z" fill="#000" />
      <Path d="M109.33,101.82H92.75a.26.26,0,0,1-.25-.26V90.17a.26.26,0,1,1,.51,0V101.3h16.06V90.17a.26.26,0,0,1,.26-.26.26.26,0,0,1,.26.26v11.39A.26.26,0,0,1,109.33,101.82Z" fill="#000" />
      <Path d="M108.07,100.48a.18.18,0,0,1-.17-.17v-10a.18.18,0,0,1,.17-.17.18.18,0,0,1,.18.17v10A.18.18,0,0,1,108.07,100.48Z" fill="#000" />
      <Path d="M102.45,67.67h-1.87a.18.18,0,0,1-.17-.17.18.18,0,0,1,.17-.18h1.87a.18.18,0,0,1,.17.18A.18.18,0,0,1,102.45,67.67Z" fill="#000" />
      <Path d="M99,67.67H70.93a.18.18,0,0,1-.18-.17.18.18,0,0,1,.18-.18H99a.18.18,0,0,1,.17.18A.18.18,0,0,1,99,67.67Z" fill="#000" />
      <Path d="M121.07,82.85H89.91a.17.17,0,0,1-.18-.17.18.18,0,0,1,.18-.17h31.16a.18.18,0,0,1,.17.17A.17.17,0,0,1,121.07,82.85Z" />
      <Path d="M111.8,78H97.53a.17.17,0,0,1-.17-.17.18.18,0,0,1,.17-.17H111.8a.18.18,0,0,1,.18.17A.17.17,0,0,1,111.8,78Z" fill="#000" />
      <Path d="M95.77,78H80.28a.17.17,0,0,1-.17-.17.18.18,0,0,1,.17-.17H95.77a.17.17,0,0,1,.17.17A.16.16,0,0,1,95.77,78Z" fill="#000" />
      <Path d="M110.11,72.62H78.59a.18.18,0,0,1-.18-.17.18.18,0,0,1,.18-.18h31.52a.18.18,0,0,1,.17.18A.18.18,0,0,1,110.11,72.62Z" fill="#000" />
      <Path d="M80.21,120.75a.18.18,0,0,1-.17-.18v-2.35a.18.18,0,0,1,.17-.17.18.18,0,0,1,.18.17v2.35A.18.18,0,0,1,80.21,120.75Z" fill="#000" />
      <Path d="M52.24,32.53l-.09,0A17.51,17.51,0,0,0,47.4,31a.19.19,0,0,1-.16-.22.2.2,0,0,1,.22-.16,17.54,17.54,0,0,1,4.87,1.56.2.2,0,0,1,.08.26A.21.21,0,0,1,52.24,32.53Z" fill="#000" />
      <Path d="M54.35,33.68a.19.19,0,0,1-.1,0c-.1-.07-.81-.45-1.07-.59a.19.19,0,0,1-.08-.26.21.21,0,0,1,.26-.08l1.1.6a.22.22,0,0,1,.06.27A.2.2,0,0,1,54.35,33.68Z" fill="#000" />
      <Path d="M17.9,93.46h0a20.42,20.42,0,0,1-4.34-1.37.2.2,0,0,1-.09-.25.2.2,0,0,1,.26-.1,18.82,18.82,0,0,0,4.23,1.34.19.19,0,0,1,.16.22A.18.18,0,0,1,17.9,93.46Z" fill="#000" />
      <Path d="M20.63,94h0c-.28,0-1.33-.23-1.37-.24a.19.19,0,0,1-.15-.22.19.19,0,0,1,.22-.15s1.08.2,1.34.23a.2.2,0,0,1,.17.21A.19.19,0,0,1,20.63,94Z" fill="#000" />
      <Path d="M123.89,37.82a.19.19,0,0,1-.19-.17.19.19,0,0,1,.17-.21,42.83,42.83,0,0,1,4.54-.34h0a.19.19,0,0,1,0,.38,42.1,42.1,0,0,0-4.49.34Z" fill="#000" />
      <Path d="M120.48,38.4a.19.19,0,0,1,0-.37s1.27-.26,1.68-.31a.18.18,0,0,1,.21.17.2.2,0,0,1-.17.21c-.39,0-1.63.3-1.64.3Z" fill="#000" />
      <Path d="M109.5,127.61h0a.19.19,0,0,1-.16-.22s.23-1.2.29-1.67a.19.19,0,0,1,.22-.16.19.19,0,0,1,.16.21c-.06.48-.28,1.64-.29,1.69A.19.19,0,0,1,109.5,127.61Z" fill="#000" />
      <Path d="M88.44,136.94h0a.19.19,0,0,1-.17-.21l.7-7.81a.2.2,0,0,1,.2-.17.19.19,0,0,1,.18.21l-.7,7.81A.19.19,0,0,1,88.44,136.94Z" fill="#000" />
      <Path d="M89.32,127.1h0a.19.19,0,0,1-.17-.21l.25-2.81a.2.2,0,0,1,.21-.17.19.19,0,0,1,.17.21l-.25,2.81A.19.19,0,0,1,89.32,127.1Z" fill="#000" />
      <Path d="M91.92,174.77a.18.18,0,0,1-.17-.11l-1.06-2.16a.2.2,0,0,1,.09-.26.2.2,0,0,1,.25.09l1.06,2.16a.19.19,0,0,1-.09.26Z" fill="#000" />
      <Path d="M90.3,171.46a.21.21,0,0,1-.17-.1L86,163.07a.19.19,0,0,1,.09-.26.2.2,0,0,1,.25.09l4.12,8.29a.18.18,0,0,1-.08.25A.15.15,0,0,1,90.3,171.46Z" fill="#000" />
      <Path d="M85.26,161a.21.21,0,0,1-.17-.11L83.64,158a.2.2,0,0,1,.08-.26.19.19,0,0,1,.26.09l1.45,2.86a.2.2,0,0,1-.08.26Z" fill="#000" />
      <Path d="M105,200.24a.19.19,0,0,1-.19-.19v-5.39a.2.2,0,0,1,.19-.19.19.19,0,0,1,.19.19v5.39A.18.18,0,0,1,105,200.24Z" fill="#000" />
      <Path d="M105,192.62a.2.2,0,0,1-.19-.19v-2.51a.19.19,0,0,1,.19-.19.18.18,0,0,1,.19.19v2.51A.19.19,0,0,1,105,192.62Z" fill="#000" />
      <Path d="M105,188.85a.19.19,0,0,1-.19-.19v-1.49A.2.2,0,0,1,105,187a.19.19,0,0,1,.19.19v1.49A.18.18,0,0,1,105,188.85Z" fill="#000" />
      <Path d="M57.5,22a.18.18,0,0,1-.13-.05.2.2,0,0,1,0-.27A25.79,25.79,0,0,1,74,13.08a.19.19,0,1,1,0,.38,25.45,25.45,0,0,0-16.42,8.43A.18.18,0,0,1,57.5,22Z" fill="#000" />
      <Path d="M55,25.19a.17.17,0,0,1-.1,0,.19.19,0,0,1-.07-.26A18.61,18.61,0,0,1,56.18,23a.18.18,0,0,1,.27,0,.18.18,0,0,1,0,.26,21.29,21.29,0,0,0-1.32,1.84A.19.19,0,0,1,55,25.19Z" fill="#000" />
      <Path d="M148.09,53.34a.18.18,0,0,1-.17-.11A19.71,19.71,0,0,0,137,42.55a.18.18,0,0,1-.11-.24.18.18,0,0,1,.24-.11,20,20,0,0,1,11.16,10.87.18.18,0,0,1-.09.25Z" fill="#000" />
      <Path d="M135.88,42.18h0a10.19,10.19,0,0,0-1.37-.31.19.19,0,0,1,0-.38,13.61,13.61,0,0,1,1.42.33.18.18,0,0,1,.13.23A.18.18,0,0,1,135.88,42.18Z" fill="#000" />
    </Svg>
  );
}
