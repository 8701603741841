import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { ConditionalRender } from '../../../base_ui';
import {
  CardHeader,
  MailingAddress,
  PrimaryLoanToggle,
  PropertyAddress,
} from './Sections';

const LoanCard = ({
  dispatchChangePrimaryLoan,
  dispatchSetSelectedLoan,
  hashedLoanNumber,
  hasMultipleLoans,
  isActive,
  isPending = false,
  isPrimaryLoan,
  mailingAddress,
  propertyAddress,
  setIsTooltipOpen,
  unmaskedLoanNumber,
}) => {
  const handleChangePrimaryLoan = () => {
    dispatchChangePrimaryLoan(hashedLoanNumber);
    dispatchSetSelectedLoan({ loanNumber: hashedLoanNumber });
  };

  return (
    <View>
      <CardHeader unmaskedLoanNumber={unmaskedLoanNumber} />
      <PropertyAddress isActive={isActive} propertyAddress={propertyAddress} />
      <MailingAddress
        isActive={isActive}
        isPending={isPending}
        mailingAddress={mailingAddress}
      />
      <ConditionalRender
        Component={PrimaryLoanToggle}
        propsToPassDown={{
          handleChangePrimaryLoan,
          isPrimaryLoan,
          setIsTooltipOpen,
        }}
        shouldRender={!!hasMultipleLoans && isActive}
      />
    </View>
  );
};

LoanCard.propTypes = {
  dispatchChangePrimaryLoan: T.func,
  dispatchSetSelectedLoan: T.func,
  hashedLoanNumber: T.string,
  hasMultipleLoans: T.bool,
  isActive: T.bool,
  isPending: T.bool,
  isPrimaryLoan: T.bool,
  mailingAddress: T.object,
  propertyAddress: T.shape({
    addressFirstLine: T.string,
    addressSecondLine: T.string,
  }).isRequired,
  setIsTooltipOpen: T.func,
  unmaskedLoanNumber: T.string,
};

export default LoanCard;
