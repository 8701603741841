/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function HurricaneIcon(props) {
  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M9.9,15.3c-0.1,0-0.2,0-0.2,0c-4.9-0.3-5.6-4.5-5.6-4.5c-0.6-3.3,1.9-5,2-5.1c2.5-1.5,4.5,0,4.6,0C11,6,11,6.4,10.9,6.6c-0.2,0.3-0.6,0.3-0.8,0.1c-0.1,0-1.5-1.1-3.3,0c-0.1,0-2,1.4-1.5,3.9c0,0.2,0.6,3.3,4.5,3.6c0.1,0,3.2,0.2,4.9-3.2c1.8-3.8-0.6-6.9-1.3-7.5c-0.2-0.2-0.3-0.6-0.1-0.8s0.6-0.3,0.8-0.1c1.4,1.2,3.5,4.8,1.6,8.9C13.9,15.1,10.8,15.3,9.9,15.3z" />
      <Path d="M4.2,15.3c-0.1,0-0.3,0-0.4-0.1C2.5,14,0.4,10.3,2.3,6.3c2-4.2,5.9-3.9,6-3.9c4.9,0.3,5.6,4.5,5.6,4.5c0.6,3.3-1.9,5-2,5.1c-2.5,1.5-4.5,0-4.6,0c-0.3-0.2-0.3-0.6-0.1-0.8c0.2-0.3,0.6-0.3,0.8-0.1c0.1,0,1.5,1.1,3.3,0c0.1,0,2-1.4,1.5-3.9c0-0.2-0.6-3.3-4.5-3.6C8.1,3.6,5,3.4,3.4,6.8c-1.8,3.7,0.6,6.9,1.3,7.5c0.2,0.2,0.3,0.6,0.1,0.8C4.6,15.2,4.4,15.3,4.2,15.3z" />
      <Path d="M12,2.5c-0.1,0-0.2,0-0.3-0.1c-0.4-0.2-1.8-0.9-3.6-0.8c-0.3,0-0.6-0.2-0.6-0.6s0.2-0.6,0.6-0.6c2-0.1,3.6,0.7,4.2,1c0.3,0.2,0.4,0.5,0.2,0.8C12.4,2.4,12.2,2.5,12,2.5z" />
      <Path d="M9.4,17.4C9.3,17.4,9.3,17.4,9.4,17.4c-0.7,0-2.3-0.2-4-1.2c-0.3-0.2-0.4-0.5-0.2-0.8c0.2-0.3,0.5-0.4,0.8-0.2c1.4,0.9,3,1,3.4,1c0.3,0,0.6,0.3,0.6,0.6C9.9,17.2,9.7,17.4,9.4,17.4z" />
    </Svg>
  );
}
