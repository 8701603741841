/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable react/jsx-sort-props */
const MegaphoneSm = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(32)} viewBox="0 0 32 32" width={moderateScale(32)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Polygon fill="#bac2ca" points="1.57 18.5 3.08 23.48 3.76 23.29 2.14 18.27 1.57 18.5" />
      <Path fill="#e7ebee" d="M25.19,24.27,19.3,5.37a1.2,1.2,0,0,0-1.54-.79h0l6.83,21.16A1.22,1.22,0,0,0,25.19,24.27Z" />
      <Path fill="#e7ebee" d="M3.27,18.65a.81.81,0,0,1,.53-1L8,16.35l0-.15a.74.74,0,0,0-1-.49L2.63,17a.79.79,0,0,0-.53,1L3.72,23.4a.8.8,0,0,0,1,.53l.08,0Z" />
      <Polyline fill="#e7ebee" points="5.65 24.8 7.27 29.97 9.31 29.32 8.66 23.78" />
      <Polygon fill="#bac2ca" points="5.95 25.59 8.63 24.76 8.51 23.78 5.72 24.8 5.95 25.59" />
      <Path fill="#bac2ca" d="M21.57,12.35l.22-.08A2.57,2.57,0,0,1,25,14h0a2.56,2.56,0,0,1-1.7,3.21l-.26.07" />
      <Path fill={svgHighlight} d="M8.06,16.08A13.58,13.58,0,0,0,14,12.39C17.27,9,17,6.69,17,6.69l5.51,17.69s-1.13-2-5.74-2.94a13.77,13.77,0,0,0-7,.34L8.21,16.54" />
      <Line fill="none" x1="8.21" y1="16.54" x2="8.06" y2="16.08" />
      <Path fill={svgHighlight} d="M22.59,24.38,21.23,20c-.64-.18-1.32-.37-2.08-.52a20,20,0,0,0-9.88.41l.56,1.81a13.83,13.83,0,0,1,7-.34C21.45,22.35,22.59,24.38,22.59,24.38Z" />
      <Path fill="#010101" d="M22.81,24.84c-.07,0-.11,0-.15-.12L19.19,13.55a.13.13,0,0,1,.08-.18.13.13,0,0,1,.18.07l3.48,11.17a.15.15,0,0,1-.08.19S22.85,24.84,22.81,24.84Z" />
      <Path fill="#010101" d="M24,26.08a1.41,1.41,0,0,1-1.4-1l-.11-.38a.21.21,0,0,1,.15-.26.22.22,0,0,1,.27.15L23,25a1,1,0,1,0,2-.61l-.38-1.24a.22.22,0,0,1,.15-.26A.21.21,0,0,1,25,23l.38,1.24a1.45,1.45,0,0,1-1,1.81A1.71,1.71,0,0,1,24,26.08Z" />
      <Path fill="#010101" d="M24.55,22.35a.26.26,0,0,1-.23-.16L19.08,5.44a1,1,0,1,0-2,.61l.15.53a.22.22,0,0,1-.15.26.22.22,0,0,1-.27-.15l-.15-.53a1.46,1.46,0,0,1,.94-1.81,1.4,1.4,0,0,1,1.1.11,1.46,1.46,0,0,1,.71.87l5.29,16.75a.23.23,0,0,1-.15.27Z" />
      <Path fill="#010101" d="M18.89,12.2c-.08,0-.11,0-.15-.12L17,6.58a.13.13,0,0,1,.08-.19.13.13,0,0,1,.18.07L19,12a.14.14,0,0,1-.08.19S18.93,12.2,18.89,12.2Z" />
      <Path fill="#010101" d="M8.06,16.31a.28.28,0,0,1-.23-.15A.23.23,0,0,1,8,15.89a13.57,13.57,0,0,0,5.81-3.66c3.16-3.24,3-5.5,3-5.5a.21.21,0,0,1,.42,0c0,.11.19,2.45-3.13,5.85a14.36,14.36,0,0,1-6,3.77Z" />
      <Path fill="#010101" d="M22.59,24.61a.2.2,0,0,1-.19-.11s-1.13-1.89-5.32-2.8a.27.27,0,0,1-.19-.26.29.29,0,0,1,.26-.19c4.42.94,5.55,2.91,5.62,3a.2.2,0,0,1-.07.3C22.66,24.61,22.62,24.61,22.59,24.61Z" />
      <Path fill="#010101" d="M9.83,22a.24.24,0,0,1-.22-.15.24.24,0,0,1,.11-.3,13.75,13.75,0,0,1,6.34-.46c.11,0,.19.12.19.27a.22.22,0,0,1-.27.19A12.57,12.57,0,0,0,9.87,22Z" />
      <Path fill="#010101" d="M3,21.1c-.07,0-.11,0-.15-.11L2,18.08a.14.14,0,0,1,.07-.19.14.14,0,0,1,.19.08l.91,2.9a.14.14,0,0,1-.08.19Z" />
      <Path fill="#010101" d="M3.8,23.55a.15.15,0,0,1-.15-.11l-.49-1.59a.13.13,0,0,1,.07-.18.14.14,0,0,1,.19.07l.49,1.59a.13.13,0,0,1-.07.18A0,0,0,0,1,3.8,23.55Z" />
      <Path fill="#010101" d="M4.59,24.19a1,1,0,0,1-1-.71.22.22,0,0,1,.15-.27.22.22,0,0,1,.26.15.55.55,0,0,0,.31.34.59.59,0,0,0,.45,0,.21.21,0,0,1,.26.15.21.21,0,0,1-.15.27A1.13,1.13,0,0,1,4.59,24.19Z" />
      <Path fill="#010101" d="M4.82,24a.14.14,0,0,1-.15-.11.14.14,0,0,1,.07-.19l4.38-1.36a.15.15,0,0,1,.11.27L4.85,24A0,0,0,0,1,4.82,24Z" />
      <Path fill="#010101" d="M9.19,22.8A.25.25,0,0,1,9,22.65a.21.21,0,0,1,.15-.27.53.53,0,0,0,.34-.3.64.64,0,0,0,0-.45.23.23,0,0,1,.16-.27.22.22,0,0,1,.26.16,1,1,0,0,1-.68,1.28Z" />
      <Path fill="#010101" d="M9.72,21.7c-.07,0-.11,0-.15-.11L8.14,17a.14.14,0,0,1,.26-.12l1.47,4.65a.13.13,0,0,1-.07.18Z" />
      <Path fill="#010101" d="M2.14,18.27a.25.25,0,0,1-.23-.15,1,1,0,0,1,.68-1.28L7,15.48a1,1,0,0,1,.79.07,1,1,0,0,1,.49.61.21.21,0,0,1-.15.26.22.22,0,0,1-.27-.15.53.53,0,0,0-.3-.34.7.7,0,0,0-.45,0L2.74,17.25a.6.6,0,0,0-.38.76.21.21,0,0,1-.15.26Z" />
      <Path fill="#010101" d="M2.4,24a1.11,1.11,0,0,1-.49-.12,1,1,0,0,1-.53-.6L.25,19.67a1.05,1.05,0,0,1,.68-1.32L2,18a.21.21,0,0,1,.26.16.21.21,0,0,1-.15.26l-1,.3a.62.62,0,0,0-.41.76L1.76,23.1a.63.63,0,0,0,.3.38.66.66,0,0,0,.46,0l1.09-.33a.2.2,0,0,1,.26.15.21.21,0,0,1-.15.26l-1.09.34A.76.76,0,0,1,2.4,24Z" />
      <Path fill="#010101" d="M23,17.48a.28.28,0,0,1-.23-.15.23.23,0,0,1,.16-.27l.26-.07a2.33,2.33,0,0,0,1.36-1.13,2.42,2.42,0,0,0,.15-1.78,2.33,2.33,0,0,0-1.13-1.36,2.3,2.3,0,0,0-1.78-.15l-.22.08a.22.22,0,0,1-.27-.15.23.23,0,0,1,.15-.27l.23-.07A2.79,2.79,0,0,1,25.15,14,2.71,2.71,0,0,1,25,16.08a2.9,2.9,0,0,1-1.62,1.36l-.26,0Z" />
      <Path fill="#010101" d="M5.5,24.91a.66.66,0,0,1-.38-.11,1,1,0,0,1-.42-.49L4.59,24a.22.22,0,0,1,.15-.27.22.22,0,0,1,.27.15l.11.3a.41.41,0,0,0,.49.27l3.24-1A.43.43,0,0,0,9.12,23L9,22.65a.23.23,0,0,1,.16-.27.22.22,0,0,1,.26.15l.11.31A.84.84,0,0,1,9,23.89l-3.28,1C5.65,24.87,5.57,24.91,5.5,24.91Z" />
      <Path fill="#010101" d="M7.27,30.19a.12.12,0,0,1-.11,0L7,30,5.42,24.87a.21.21,0,0,1,.15-.26.22.22,0,0,1,.27.15L7.38,29.7,9,29.17l-.6-5.39a.29.29,0,0,1,.19-.27.29.29,0,0,1,.26.19l.6,5.62a.2.2,0,0,1-.15.23l-2,.64Z" />
      <Path fill="#010101" d="M28.89,12.76a.25.25,0,0,1-.23-.15.21.21,0,0,1,.15-.26l2.72-.87a.21.21,0,0,1,.26.15.21.21,0,0,1-.15.26l-2.72.87Z" />
      <Path fill="#010101" d="M27.41,13.25a.24.24,0,0,1-.22-.15.21.21,0,0,1,.15-.26l.49-.15a.2.2,0,0,1,.26.15.21.21,0,0,1-.15.26l-.49.15Z" />
      <Path fill="#010101" d="M31.53,24a.18.18,0,0,1-.12,0l-3.17-1.66a.25.25,0,0,1-.11-.31.24.24,0,0,1,.3-.11l3.17,1.66a.24.24,0,0,1,.12.3A.19.19,0,0,1,31.53,24Z" />
      <Path fill="#010101" d="M22.51,5.52s-.08,0-.08,0a.24.24,0,0,1-.11-.3l1.47-3.25a.24.24,0,0,1,.3-.11.25.25,0,0,1,.12.3L22.7,5.37C22.66,5.44,22.59,5.52,22.51,5.52Z" />
    </Svg>
  );
};
export default MegaphoneSm;
