import React from 'react';
import T from 'prop-types';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';

import { FormattedMessage, H2 } from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import {
  DrawerContainer,
  HeaderWrapper,
  IllustrationWrapper,
  StyledBorderlessButton,
  StyledP2,
} from './styledComponents';

const ErrorStormIcon = iconDictionary('errorStorm');

const ErrorView = ({ customerServicePhoneNumber, handleClose }) => {
  const { subheader, text } = LOANS_CONTENT_DICTIONARY.cancelMailingAddress.errorView.mobile;

  return (
    <DrawerContainer>
      <IllustrationWrapper>
        {ErrorStormIcon}
      </IllustrationWrapper>
      <HeaderWrapper>
        <H2>{subheader}</H2>
      </HeaderWrapper>
      <FormattedMessage
        defaultMessage={text}
        values={{ customerServicePhoneNumber }}
        WrapperComponent={StyledP2}
      />
      <StyledBorderlessButton
        isPrimary
        label="Return to Settings"
        onPress={handleClose}
      />
    </DrawerContainer>
  );
};

ErrorView.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  handleClose: T.func.isRequired,
};

export default ErrorView;
