import styled from 'styled-components/native';

import {
  P2,
  P3,
  PrimaryCard,
  StatusBanner,
} from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-top: ${moderateScale(26)}px;
`;

export const SelectLoanContainer = styled.View`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin: ${moderateScale(10)}px ${moderateScale(24)}px ${moderateScale(24)}px;
`;

export const StyledLoanCard = styled(PrimaryCard)`
  margin-top: ${moderateScale(15)}px;
  min-height: ${moderateScale(125)}px;
`;

export const StyledP2 = styled(P2)`
  margin: ${moderateScale(6)}px 0;
`;

export const StyledP3 = styled(P3)`
  font-weight: 700;
  opacity : ${({ disabled }) => disabled ? 0.5 : 1};
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${moderateScale(24)}px;
`;

export const StyledPressable = styled.Pressable`
  background-color: ${({ $pressed, theme: { colors: { accentSilver } } }) =>
    $pressed ? accentSilver : 'transparent'};
  border-radius: 8px;
  min-height: ${moderateScale(125)}px;
  padding: ${moderateScale(23)}px ${moderateScale(16)}px;
`;

export const CardContentWrapper = styled.View`
  justify-content: space-between;
  min-height: ${moderateScale(79)}px;
`;
