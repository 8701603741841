import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getFormattedMobileStringFeeSchedule } from '@dmi/shared/redux/Fees/selectors';
import makeSelectMain, { selectCustomerServicePhoneNumber } from '@dmi/shared/redux/Main/selectors';

import FeeSchedule from '../../components/FeeSchedule';

const FeeScheduleScreen = ({
  customerServicePhoneNumber,
  feeScheduleType,
  isLoggedIn,
  stringFeeScheduleProps,
}) => (
  <FeeSchedule
    customerServicePhoneNumber={customerServicePhoneNumber}
    {...stringFeeScheduleProps[isLoggedIn ? 'LOGGEDIN' : feeScheduleType]}
  />
);

FeeScheduleScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  feeScheduleType: T.string.isRequired,
  isLoggedIn: T.bool.isRequired,
  stringFeeScheduleProps: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Fees
   */
  stringFeeScheduleProps: getFormattedMobileStringFeeSchedule(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: selectCustomerServicePhoneNumber(),
  isLoggedIn: makeSelectMain('isLoggedIn'),
  selectedLoan: makeSelectMain('selectedLoan'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(FeeScheduleScreen);
