/* eslint-disable react/no-array-index-key */
import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { ConditionalRender } from '../base_ui';
import { HTML_ELEMENT_DICTIONARY } from './constants';
import InlineBlocks from './InlineBlocks';
import ListBlocks from './ListBlocks';

const EditorContent = ({ content, handleCloseDrawer }) => (
  <View>
    {
      content.map(({ inlineBlocks, listItems, type }, index) => {
        const BlockElement = HTML_ELEMENT_DICTIONARY[type];

        return (
          <BlockElement key={`${type}-${index}`}>
            <ConditionalRender
              Component={ListBlocks}
              FallbackComponent={InlineBlocks}
              propsToPassDown={{
                handleCloseDrawer,
                inlineBlocks,
                listItems,
                listType: type,
              }}
              shouldRender={!!listItems}
            />
          </BlockElement>
        );
      })
    }
  </View>
);

EditorContent.propTypes = {
  content: T.arrayOf(
    T.shape({
      inlineBlocks: T.array,
      listItems: T.array,
      type: T.string.isRequired,
    }).isRequired,
  ).isRequired,
  handleCloseDrawer: T.func,
};

export default EditorContent;
