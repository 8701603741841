import React, { useState } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import {
  DEFAULT_MOBILE_THEME_COLORS,
  MODAL_NYC_LANGUAGE_PREFERENCE_MAIN_CONTENT,
} from '@dmi/shared/redux/Main/constants';

import { LargePrimaryButton, OutlinedDropDown } from '../../base_ui';
import {
  ButtonContainer,
  FlexContainer,
  Heading,
  IllustrationWrapper,
  Text,
} from './styledComponents';
import { moderateScale } from '../../../utils/scaleHelpers';
import iconDictionary from '../../../utils/iconDictionary';

const Illustration = iconDictionary('magnifyingGlass');

const MainView = ({
  dispatchPutLanguagePreference,
  languagePreference: { allowedLanguages },
  loanNumber,
  requestStatus,
}) => {
  const [selectedValue, setSelectedValue] = useState('');
  const { heading, text } = MODAL_NYC_LANGUAGE_PREFERENCE_MAIN_CONTENT;

  const handleOnSubmit = () => {
    /**
     * In selector we add an index to dropdown values as the values from
     * api are not unique. Here we remove the index from the value.
     */
    const [languageCode] = selectedValue.split('-');
    dispatchPutLanguagePreference({
      languageCode,
      loanNumber,
    });
  };

  return (
    <FlexContainer>
      <IllustrationWrapper>
        {Illustration}
      </IllustrationWrapper>
      <Heading>{heading}</Heading>
      <Text>{text}</Text>
      <OutlinedDropDown
        /**
         * Fix for iPad specific issue on mobile web where dropdown displays wrong
         * value on consecutive selections, most probably caused by the index
         * being off after the 'Select' placeholder is removed. A re-render of the
         * component after a selection has been made by changing the key value
         * resolves the issue.
         */
        {...(Platform.OS === 'web' ? {
          key: selectedValue
            ? 'dropdown-initial-render'
            : 'dropdown-consecutive-render',
        } : {})}
        accessibilityName="select-language-preference"
        disabled={requestStatus === 'loading'}
        menuItems={allowedLanguages}
        onChange={(value) => setSelectedValue(value)}
        placeholder={!selectedValue ? { label: 'Select', value: '' } : {}}
        selected={selectedValue}
        style={{
          inputAndroidContainer: {
            alignSelf: 'center',
            height: moderateScale(34),
            width: moderateScale(222),
          },
          placeholder: { color: DEFAULT_MOBILE_THEME_COLORS.primaryCarbon },
          viewContainer: {
            alignSelf: 'center',
            height: moderateScale(34),
            width: moderateScale(222),
          },
        }}
      />
      <ButtonContainer>
        <LargePrimaryButton
          disabled={!selectedValue || requestStatus === 'loading'}
          label="Submit"
          onPress={handleOnSubmit}
        />
      </ButtonContainer>
    </FlexContainer>
  );
};

MainView.propTypes = {
  dispatchPutLanguagePreference: T.func.isRequired,
  languagePreference: T.shape({ allowedLanguages: T.array.isRequired }).isRequired,
  loanNumber: T.string.isRequired,
  requestStatus: T.string.isRequired,
};

export default MainView;
