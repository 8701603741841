import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { INFO_COPY_DICT } from '@dmi/shared/redux/Payments/Autopay/constants';
import { INTL_IDS } from '@dmi/shared/redux/Payments/Autopay/messages';
import { convertNumToCurrencyStr } from '@dmi/shared/utils/globalHelpers';
import { validate } from '@dmi/shared/utils/validate';

import Layout from '../../../../constants/Layout';
import { SecondaryHeader } from '../../../ScreenHeaders';
import { KeyboardAvoidingScrollView, P2 } from '../../../base_ui';
import {
  AddressHeader,
  InfoSection,
  PaymentSummary,
} from '../../sharedComponents';
import {
  AdditionalPrincipalContainer,
  ContentContainer,
  InfoSectionContainer,
  RegularMonthlyPaymentContainer,
  RegularMonthlyPaymentLabel,
  StyledDivider,
  StyledPaymentTextField,
} from './styledComponents';

const { isTablet } = Layout;

const AutopayAmount = ({
  additionalPrincipal,
  address,
  customerServicePhoneNumber,
  dispatchUpdateAmount,
  dispatchUpdateFormError,
  dueDate,
  formErrors,
  loanNumber,
  maximumAdditionalPrincipal,
  navigation,
  regularMonthlyPayment,
}) => {
  const [additionalToggle, setAdditionalToggle] = useState(true);
  const { formatMessage } = useIntl();

  // Form Input state, will be committed to Redux on form submission
  const defaultValue = additionalPrincipal === 0 ? '0' : additionalPrincipal.toFixed(2);
  const [formState, setFormState] = useState(defaultValue);
  const [formStateError, setFormStateError] = useState(formErrors.additionalPrincipal);

  const calculateTotalPayment = () => {
    const { message } = validate({
      maxEqual: maximumAdditionalPrincipal,
      type: 'currencyOnChange',
      value: Number(formState),
    });
    if (message) {
      if (formStateError !== message) {
        setFormStateError(message);
      }
      return convertNumToCurrencyStr(regularMonthlyPayment);
    }
    return convertNumToCurrencyStr(Number(formState) + regularMonthlyPayment);
  };

  const clearOnFocus = () => {
    if (formState === '0') {
      setFormState('');
    }
  };

  const formatOnBlur = () => {
    const value = formState;
    if (value === '') {
      setFormState('0');
    } else if (value !== '0') {
      const formattedValue = parseFloat(value).toFixed(2);
      if (formattedValue === '0.00') {
        setFormState('0');
      } else {
        setFormState(formattedValue);
      }
    }
  };

  const handleFormChange = (newValue) => {
    const isValidNumber = !Number.isNaN(Number(newValue));
    if (isValidNumber) {
      setFormStateError('');
      setFormState(newValue);
    }
  };

  const handleUpdate = () => {
    const { message } = validate({
      maxEqual: maximumAdditionalPrincipal,
      type: 'currency',
      value: formState,
    });
    if (message) {
      dispatchUpdateFormError({
        field: 'additionalPrincipal',
        form: 'amount',
        value: message,
      });
    } else {
      dispatchUpdateAmount({ additionalPrincipal: Number(formState) });
      navigation.goBack();
    }
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => {
          navigation.navigate('AutopaySetupOverview');
          dispatchUpdateFormError({
            field: 'additionalPrincipal',
            form: 'amount',
            value: '',
          });
        }}
        title="Edit Autopay Amount"
      />
      <KeyboardAvoidingScrollView scrollViewProps={{ showsVerticalScrollIndicator: false }}>
        <AddressHeader address={address} dueDate={dueDate} loanNumber={loanNumber} />
        <ContentContainer>
          <StyledDivider />
          <RegularMonthlyPaymentContainer>
            <RegularMonthlyPaymentLabel>Regular Monthly Payment</RegularMonthlyPaymentLabel>
            <P2>{convertNumToCurrencyStr(regularMonthlyPayment)}</P2>
          </RegularMonthlyPaymentContainer>
          <AdditionalPrincipalContainer>
            <StyledPaymentTextField
              $isInputEnabled={additionalToggle}
              $isTablet={isTablet}
              errorMessage={formStateError}
              keyboardType="decimal-pad"
              label={formatMessage({ id: INTL_IDS.SETUP_ADDITIONALPRINCIPAL_LABEL })}
              onBlurSideEffects={() => formatOnBlur()}
              onChangeText={(newValue) => handleFormChange(newValue)}
              onFocusSideEffects={() => clearOnFocus()}
              onTogglePress={() => {
                setFormState('0');
                setAdditionalToggle(!additionalToggle);
                setFormStateError('');
              }}
              value={formState}
            />
          </AdditionalPrincipalContainer>
          <StyledDivider />
          <InfoSectionContainer>
            <InfoSection
              customerServicePhoneNumber={customerServicePhoneNumber}
              data={INFO_COPY_DICT.setup}
            />
          </InfoSectionContainer>
        </ContentContainer>
        <PaymentSummary
          label="Update Amount"
          onPress={handleUpdate}
          subText={`Due on ${dueDate}`}
          text={calculateTotalPayment()}
        />
      </KeyboardAvoidingScrollView>
    </Fragment>
  );
};

AutopayAmount.propTypes = {
  additionalPrincipal: T.number.isRequired,
  address: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchUpdateAmount: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  dueDate: T.string.isRequired,
  formErrors: T.object.isRequired,
  loanNumber: T.string.isRequired,
  maximumAdditionalPrincipal: T.number.isRequired,
  navigation: T.object.isRequired,
  regularMonthlyPayment: T.number.isRequired,
};

export default AutopayAmount;
