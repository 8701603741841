import React from 'react';
import T from 'prop-types';

import { MODAL_PAPERLESS_BILLING_MAIN_CONTENT } from '@dmi/shared/redux/Main/constants';

import { H4, LargePrimaryButton, P2 } from '../../base_ui';

import iconDictionary from '../../../utils/iconDictionary';
import {
  ButtonsContainer,
  IllustrationWrapper,
  MainBorderlessButton,
  MainHeading,
  MainIconWrapper,
  MainItemContainer,
  MainItemContent,
  PaperlessContainer,
  TextContainer,
} from './styledComponents';

const Illustration = iconDictionary('paperlessBillingV1');
const Icon = iconDictionary('outlinedCompletePurple', { height: 30, width: 30 });

const { heading, items } = MODAL_PAPERLESS_BILLING_MAIN_CONTENT;

const MainView = ({
  handleCloseModalView,
  handleSetModalViewStep,
}) => (
  <PaperlessContainer>
    <IllustrationWrapper>
      {Illustration}
    </IllustrationWrapper>
    <MainHeading>{heading}</MainHeading>
    <TextContainer>
      {items.map(({ subHeading, text }) => (
        <MainItemContainer key={text.slice(0, 10)}>
          <MainIconWrapper>
            {Icon}
          </MainIconWrapper>
          <MainItemContent>
            <H4>{subHeading}</H4>
            <P2>{text}</P2>
          </MainItemContent>
        </MainItemContainer>
      ))}
    </TextContainer>
    <ButtonsContainer>
      <LargePrimaryButton
        label="Enroll Now"
        onPress={() => handleSetModalViewStep(2)}
      />
      <MainBorderlessButton
        isPrimary
        label="Skip"
        onPress={handleCloseModalView}
      />
    </ButtonsContainer>
  </PaperlessContainer>
);

MainView.propTypes = {
  handleCloseModalView: T.func.isRequired,
  handleSetModalViewStep: T.func.isRequired,
};

export default MainView;
