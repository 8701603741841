import React from 'react';
import T from 'prop-types';

import { SecondaryDivider } from '../../../base_ui';
import {
  CardContainer,
  CardContentContainer,
  CardDivider,
  CardHeader,
  CardHeaderContainer,
  CardLink,
} from './styledComponents';
import Layout from '../../../../constants/Layout';

const { isTablet } = Layout;

const HomeCard = ({
  children,
  HeaderIcon,
  headerText,
  linkLabel,
  linkPath,
}) => (
  <CardContainer>
    <CardHeaderContainer $center={isTablet}>
      {HeaderIcon}
      <CardHeader>{headerText}</CardHeader>
    </CardHeaderContainer>
    <CardContentContainer $center={isTablet}>
      {children}
    </CardContentContainer>
    <CardDivider />
    <CardLink
      label={linkLabel}
      path={linkPath}
      size="medium"
    />
    <SecondaryDivider />
  </CardContainer>
);

HomeCard.propTypes = {
  children: T.object.isRequired,
  HeaderIcon: T.object.isRequired,
  headerText: T.string.isRequired,
  linkLabel: T.string.isRequired,
  linkPath: T.string.isRequired,
};

export default HomeCard;
