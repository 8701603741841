import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  BorderlessButton,
  H2,
  P2,
  PrimaryDivider,
} from '../../base_ui';

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const CardButtonWrapper = styled.View`
  margin-top: ${moderateScale(32)}px;
`;

export const DataLabel = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const DataLine = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${moderateScale(24)}px 0 ${moderateScale(45)}px;
  width: 100%;
`;

export const DataValue = styled.Text`
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const ErrorContainer = styled.View`
  bottom: ${moderateScale(85)}px;
  flex-grow: 1;
  margin-top: ${moderateScale(24)}px;
  min-height: 100%;
`;

export const InfoSectionContainer = styled.View`
  margin: ${moderateScale(25)}px ${moderateScale(5)}px 0;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: f4,
    textAlign: 'center',
  }],
}))`
  margin-top: ${moderateScale(16)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  min-width: 100%;
  padding: 0 ${moderateScale(20)}px;
`;

export const SuccessContainer = styled.View`
  align-items: center;
  margin: ${moderateScale(24)}px auto;
  padding: 0 ${moderateScale(20)}px;
  width: 100%;
`;

export const SuccessH2 = styled(H2)`
  font-size: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  margin: ${moderateScale(16)}px 0 ${moderateScale(8)}px;
  text-align: center;
`;

export const SuccessText = styled(P2)`
  text-align: center;
`;
