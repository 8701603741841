import React from 'react';
import T from 'prop-types';

import {
  ConditionalRender,
  StyledScrollView,
} from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import CalculatedResults from './CalculatedResults';
import Filter from '../Shared/Filter';
import Form from './Form';

const Amortization = ({
  amortizationValues,
  chartData,
  dispatchAmortizeCalculate,
  dispatchChangeInput,
  dispatchResetCalculator,
  dispatchResetCalculatorResults,
  dispatchUpdateFormError,
  formErrors,
  handleChangeAdjustmentFilter,
  handleDownloadCSV,
  initialTerms,
  isCalculateButtonDisabled,
  loanNumber,
  menuItems,
  navigation,
  requestBody,
  selectedAdjustmentFilter,
  status,
}) => {
  const { amortization: amortizeStatus } = status;
  const propsToPassDown = {
    amortizationValues,
    chartData,
    dispatchAmortizeCalculate,
    dispatchChangeInput,
    dispatchResetCalculator,
    dispatchResetCalculatorResults,
    dispatchUpdateFormError,
    formErrors,
    handleChangeAdjustmentFilter,
    handleDownloadCSV,
    initialTerms,
    isCalculateButtonDisabled,
    loanNumber,
    menuItems,
    navigation,
    requestBody,
    selectedAdjustmentFilter,
    status,
  };

  return (
    <StyledScrollView>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Explore Payment Adjustments"
      />
      <Filter
        handleChangeAdjustmentFilter={handleChangeAdjustmentFilter}
        selectedAdjustmentFilter={selectedAdjustmentFilter}
      />
      <ConditionalRender
        Component={CalculatedResults}
        FallbackComponent={Form}
        propsToPassDown={propsToPassDown}
        shouldRender={amortizeStatus === 'succeeded'}
      />
    </StyledScrollView>
  );
};

Amortization.propTypes = {
  amortizationValues: T.shape({ valueType: T.string.isRequired }).isRequired,
  chartData: T.object.isRequired,
  dispatchAmortizeCalculate: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetCalculator: T.func.isRequired,
  dispatchResetCalculatorResults: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  formErrors: T.object.isRequired,
  handleChangeAdjustmentFilter: T.func.isRequired,
  handleDownloadCSV: T.func.isRequired,
  initialTerms: T.array.isRequired,
  isCalculateButtonDisabled: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  menuItems: T.object.isRequired,
  navigation: T.object.isRequired,
  requestBody: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
  status: T.object.isRequired,
};

export default Amortization;
