import React from 'react';
import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';

import {
  BaseExternalLink,
  BaseInternalLink,
  H1,
  H2,
  H3,
  H4,
  P3,
} from '../base_ui';
import { HEADING_LINE_HEIGHT_DICT } from './helpers';
import { moderateScale } from '../../utils/scaleHelpers';

const headingStyles = css`
  flex-grow: 1;
  margin: ${moderateScale(10)}px 0;
  padding-top: ${moderateScale(10)}px;
`;
export const StyledH1 = styled(H1)`${headingStyles}`;
export const StyledH2 = styled(H2)`${headingStyles}`;
export const StyledH3 = styled(H3)`${headingStyles}`;
export const StyledH4 = styled(H4)`${headingStyles}`;

export const ListContainer = styled.View`
  margin: ${moderateScale(14)}px 0;
  padding-left: ${moderateScale(25)}px;
`;

export const ListItemWrapper = styled(P3)`
  padding: 0 0 ${moderateScale(5)}px ${moderateScale(5)}px;
`;

export const InlineElement = styled.Text`
  ${Platform.OS === 'web' ? 'display: inline;' : ''}
`;

export const RichStyledElement = styled(
  ({
    bold,
    href,
    italic,
    underline,
    ...restProps
  }) => <InlineElement {...restProps} />,
)`
  ${({ italic }) => italic ? 'font-style: italic;' : ''}
  ${({ bold }) => bold ? 'font-weight: 700;' : ''}
  ${({ href, underline }) => href || underline ? 'text-decoration: underline;' : ''}
  ${({ listType }) => HEADING_LINE_HEIGHT_DICT[listType] || ''}
`;

export const StyledExternalLink = styled(BaseExternalLink)`
  ${Platform.OS === 'web' ? `
    display: inline-flex;
    font-size: inherit;
  ` : `
    font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
    line-height: ${moderateScale(18)}px;
  `}
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  ${Platform.OS === 'web' ? `
    display: inline-flex;
    font-size: inherit;
  ` : `
    font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
    line-height: ${moderateScale(18)}px;
  `}
`;

export const StyledParagraph = styled(P3)`
  ${Platform.OS === 'web' ? 'display: block;' : ''}
`;
