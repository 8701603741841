import { Dimensions, Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import {
  BorderlessButton,
  H4,
  IconButton,
  PrimaryDivider,
} from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

const { width: screenWidth } = Dimensions.get('window');

export const AbsoluteContainer = styled.View`
  align-items: center;
  left: 0;
  position: absolute;
  right: 0;
`;

export const BackButton = styled(IconButton).attrs(({
  buttonStyle,
  containerStyle,
}) => ({
  buttonStyle: [
    buttonStyle,
    { padding: 0 },
  ],
  containerStyle: [
    containerStyle,
    {
      height: moderateScale(18),
      width: moderateScale(18),
    },
  ],
}))`
  align-items: flex-start;
  align-self: center;
  z-index: 1;
`;

export const DoneButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontFamilies: { secondaryFont },
    fontSizes: { f5 },
  },
  titleStyle,
}) => ({
  titleStyle: [
    titleStyle,
    {
      color: linkText,
      fontFamily: secondaryFont,
      fontSize: f5,
    },
  ],
}))`
  position: absolute;
  right: ${moderateScale(24)}px;
`;

export const DownloadButton = styled(BackButton)`
  align-items: flex-end;
`;

export const SecondaryHeaderWrapper = styled.View`
  ${Platform.select({
    android: css`
      position: relative;
    `,
    default: css`
      position: sticky;
      top: 0;
    `,
    ios: css`
      position: relative;
    `,
  })}
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  display: flex;
  flex-direction: row;
  // min-height necessary when no button, only title- which is absolutely positioned
  min-height: ${moderateScale(51)}px;
  padding: ${moderateScale(7)}px ${moderateScale(24)}px;
  width: 100%;
  z-index: 1;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;

export const StyledTitle = styled(H4)`
  max-width: ${screenWidth - moderateScale(120)}px;
  text-align: center;
`;

export const TertiaryHeaderContent = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(17)}px ${moderateScale(24)}px;
`;
