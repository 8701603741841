import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import BorderlessDocPickerButton from '../../DocUpload/ReviewScene/BorderlessDocPickerButton';
import {
  H2,
  P2,
  PrimaryDivider,
} from '../../base_ui';

export const BottomButtonWrapper = styled.View`
  align-items: center;
  margin: ${moderateScale(16)}px 0 ${moderateScale(32)}px;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const ContentWrapper = styled.View`
  flex-grow: 1;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const ErrorBannerContainer = styled.View`
  align-items: center;
  border: ${moderateScale(1)}px solid ${({ theme: { colors: { accentRed } } }) => accentRed};
  border-radius: ${moderateScale(4)}px;
  flex-direction: row;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px;
`;

export const Header = styled(H2)`
  padding-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const HeaderSubText = styled(P2)`
  text-align: center;
`;

export const IconWrapper = styled.View`
  padding-right: ${moderateScale(8)}px;
`;

export const IllustrationHeaderWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(48)}px;
`;

export const IllustrationWrapper = styled.View`
  padding: ${moderateScale(20)}px ${moderateScale(20)}px ${moderateScale(16)}px;
`;

export const OuterWrapper = styled.View`
  flex: 1;
  flex-shrink: 1;
  margin: 0 ${moderateScale(24)}px ${moderateScale(35)}px;
`;

export const ReviewContainer = styled.View`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const StyledBorderlessDocPickerButton = styled(BorderlessDocPickerButton)`
  .label {
    color: ${({ theme: { colors: { primary } } }) => primary};
    font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
    font-weight: 700;
    line-height: 1.8rem;
    text-transform: capitalize;
  }
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin: ${moderateScale(30)}px 0 ${moderateScale(14)}px;
  max-width: 100%;
`;
