/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function CalculatorMT(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(86)} viewBox="0 0 86 86" width={moderateScale(86)} {...props}>
      <Rect fill="#fff" height="63.06" rx="2.44" width="47.61" x="18.51" y="11.61" />
      <Path d="M63.79,11.61h-3l1.74,2.53V72.23L61.1,74.67h2.69a2.32,2.32,0,0,0,2.32-2.32V13.93A2.32,2.32,0,0,0,63.79,11.61Z" fill="#e6ebef" />
      <Path d="M18.51,40.53V13.93a2.32,2.32,0,0,1,2.32-2.32h43a2.32,2.32,0,0,1,2.32,2.32V72.35a2.32,2.32,0,0,1-2.32,2.32h-43a2.32,2.32,0,0,1-2.32-2.32V43.27" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.13px" />
      <Path d="M62.35,70.43v1.92A2.32,2.32,0,0,1,60,74.67" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="62.35" x2="62.35" y1="19.24" y2="67.51" />
      <Path d="M60,11.61a2.32,2.32,0,0,1,2.32,2.32v3.21" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" />
      <Rect fill="#e6ebef" height="8.95" width="32.38" x="24.36" y="21.09" />
      <Polyline fill="none" points="28.24 30.04 24.36 30.04 24.36 21.09 56.73 21.09 56.73 30.04 29.85 30.04" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="51.14" x2="56.82" y1="16.02" y2="16.02" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="24.08" x2="48.59" y1="16.02" y2="16.02" />
      <Circle cx="29.49" cy="25.51" r="0.7" />
      <Circle cx="34.75" cy="25.51" r="0.7" />
      <Circle cx="40" cy="25.51" r="0.7" />
      <Circle cx="45.25" cy="25.51" r="0.7" />
      <Circle cx="50.51" cy="25.51" r="0.7" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.36" y="37.13" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.44" y="37.13" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="48.52" y="37.13" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.36" y="48.82" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.44" y="48.82" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.36" y="60.51" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.44" y="60.51" />
      <Path d="M55.91,48.82H49.4a.87.87,0,0,0-.88.88V66.77a.88.88,0,0,0,.88.89h6.51a.89.89,0,0,0,.89-.89V49.7A.88.88,0,0,0,55.91,48.82Z" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.36" y="37.13" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.44" y="37.13" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="48.52" y="37.13" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.36" y="48.82" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.44" y="48.82" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.36" y="60.51" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.44" y="60.51" />
      <Path d="M55.91,48.82H49.4a.87.87,0,0,0-.88.88V66.77a.88.88,0,0,0,.88.89h6.51a.89.89,0,0,0,.89-.89V49.7A.88.88,0,0,0,55.91,48.82Z" fill={svgHighlight} />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="24.36" y="37.13" />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="36.44" y="37.13" />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="48.52" y="37.13" />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="24.36" y="48.82" />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="36.44" y="48.82" />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="24.36" y="60.51" />
      <Rect fill="#fff" height="7.15" rx="0.93" width="8.28" x="36.44" y="60.51" />
      <Path d="M55.91,48.82H49.4a.87.87,0,0,0-.88.88V66.77a.88.88,0,0,0,.88.89h6.51a.89.89,0,0,0,.89-.89V49.7A.88.88,0,0,0,55.91,48.82Z" fill="#fff" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="48.56" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="60.46" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="60.46" />
      <Path d="M56,48.77H49.44a.89.89,0,0,0-.88.89V66.73a.88.88,0,0,0,.88.88H56a.87.87,0,0,0,.88-.88V49.66A.88.88,0,0,0,56,48.77Z" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="48.56" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="60.46" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="60.46" />
      <Path d="M56,48.77H49.44a.89.89,0,0,0-.88.89V66.73a.88.88,0,0,0,.88.88H56a.87.87,0,0,0,.88-.88V49.66A.88.88,0,0,0,56,48.77Z" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="48.56" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="60.46" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="60.46" />
      <Path d="M48.56,57.82v8.91a.88.88,0,0,0,.88.88H56a.87.87,0,0,0,.88-.88V49.66a.88.88,0,0,0-.88-.89H49.44a.89.89,0,0,0-.88.89v8.16" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="48.56" y="37.09" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="48.77" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="24.4" y="60.46" />
      <Rect fill={svgHighlight} height="7.15" rx="0.93" width="8.28" x="36.48" y="60.46" />
      <Path d="M48.56,58.37v8.36a.88.88,0,0,0,.88.88H56a.87.87,0,0,0,.88-.88V49.66a.88.88,0,0,0-.88-.89H49.44a.89.89,0,0,0-.88.89v8.71" fill="#e6ebef" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="24.36" y="37.13" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="36.44" y="37.13" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="48.52" y="37.13" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="24.36" y="48.82" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="36.44" y="48.82" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="24.36" y="60.51" />
      <Rect fill="none" height="7.15" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" width="8.28" x="36.44" y="60.51" />
      <Path d="M48.52,60.34v6.43a.88.88,0,0,0,.88.89h6.51a.89.89,0,0,0,.89-.89V49.7a.88.88,0,0,0-.89-.88H49.4a.87.87,0,0,0-.88.88v8.61" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="11.69" x2="11.69" y1="75.02" y2="73.48" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="11.69" x2="11.69" y1="78.39" y2="76.85" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="12.6" x2="14.15" y1="75.93" y2="75.93" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="9.24" x2="10.78" y1="75.93" y2="75.93" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="74.75" x2="76.76" y1="19.87" y2="17.86" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="71.02" x2="73.03" y1="23.6" y2="21.59" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="74.75" x2="76.76" y1="21.59" y2="23.6" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.71px" x1="71.02" x2="73.03" y1="17.86" y2="19.87" />
    </Svg>
  );
}
