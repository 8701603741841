import styled from 'styled-components/native';

import { BorderlessButton, H1, PrimaryCard } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ClientSelectPageContainer = styled.View`
  margin: ${moderateScale(16)}px ${moderateScale(24)}px 0;
`;

export const StyledH1 = styled(H1)`
  margin: ${moderateScale(36)}px auto 0;
`;

export const StyledSubheader = styled.Text`
  font-size: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  margin: ${moderateScale(8)}px auto;
`;

export const ClientCard = styled(PrimaryCard)`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: ${moderateScale(156)}px;
  justify-content: space-between;
  margin-top: ${moderateScale(16)}px;
  padding: ${moderateScale(12)}px ${moderateScale(24)}px 0;
`;

export const StyledLogo = styled.Image`
  height: 100%;
  max-height: ${moderateScale(93)}px;
  max-width: ${moderateScale(262)}px;
  opacity: ${({ isLoading }) => isLoading ? '0' : '1'};
  position: ${({ isLoading }) => isLoading ? 'absolute' : 'relative'};
  width: 100%;
`;

export const AddAccountButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: f4,
    paddingHorizontal: 5,
    paddingVertical: 10,
  }],
  underlayColor: 'transparent',
}))`
  margin: ${moderateScale(14)}px 0 ${moderateScale(62)}px;
`;

export const IllustrationWrapper = styled.View`
  margin: 0 auto;
`;

/**
 * ButtonGroup styled components
 */

export const ButtonGroupWrapper = styled.View`
  align-items: center;
  border-style: solid;
  border-top-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-top-width: 1px;
  flex-direction: row;
  height: ${moderateScale(38)}px;
  justify-content: space-between;
  width: ${moderateScale(287)}px;
`;

export const StyledLoginButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { primary },
    fontFamilies: { regular },
    fontSizes: { f3 },
  },
  titleStyle,
}) => ({
  titleStyle: [
    titleStyle,
    {
      color: primary,
      fontFamily: regular,
      fontSize: f3,
      lineHeight: moderateScale(18),
      paddingHorizontal: 0,
    },
  ],
}))``;
