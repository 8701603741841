/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavHelpDefaultLIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M11.7802 1.56006C9.68767 1.56006 7.64214 2.18056 5.90227 3.34311C4.1624 4.50566 2.80633 6.15803 2.00555 8.09127C1.20478 10.0245 0.99526 12.1518 1.40349 14.2041C1.81172 16.2564 2.81937 18.1416 4.29901 19.6212C5.77865 21.1009 7.66383 22.1085 9.71614 22.5168C11.7685 22.925 13.8957 22.7155 15.829 21.9147C17.7622 21.1139 19.4146 19.7579 20.5771 18.018C21.7397 16.2781 22.3602 14.2326 22.3602 12.1401C22.3549 9.33569 21.2385 6.6477 19.2556 4.66471C17.2726 2.68172 14.5846 1.56534 11.7802 1.56006ZM11.7802 21.4701C9.9349 21.4701 8.13104 20.9229 6.59673 19.8977C5.06242 18.8725 3.86657 17.4153 3.1604 15.7105C2.45424 14.0057 2.26947 12.1297 2.62947 10.3199C2.98947 8.51002 3.87807 6.84758 5.18289 5.54275C6.48772 4.23793 8.15016 3.34933 9.96001 2.98933C11.7699 2.62933 13.6458 2.8141 15.3506 3.52026C17.0555 4.22643 18.5126 5.42228 19.5378 6.95659C20.563 8.4909 21.1102 10.2948 21.1102 12.1401C21.1076 14.6137 20.1237 16.9853 18.3746 18.7344C16.6254 20.4836 14.2539 21.4674 11.7802 21.4701Z" fill="black" />
      <Path d="M11.6396 16.2999C11.4542 16.2991 11.2704 16.3349 11.0988 16.4053C10.9272 16.4756 10.7711 16.5791 10.6396 16.7099C10.5038 16.8381 10.3962 16.9933 10.3239 17.1655C10.2516 17.3377 10.2161 17.5231 10.2196 17.7099C10.2127 17.898 10.2477 18.0854 10.322 18.2583C10.3964 18.4313 10.5083 18.5855 10.6496 18.7099C10.9187 18.9676 11.2771 19.1109 11.6496 19.1099C11.8355 19.1135 12.0201 19.079 12.1921 19.0085C12.3641 18.938 12.5198 18.833 12.6496 18.6999C12.7878 18.573 12.8975 18.4182 12.9716 18.2459C13.0457 18.0736 13.0825 17.8875 13.0796 17.6999C13.0832 17.5113 13.0457 17.3241 12.9698 17.1514C12.8938 16.9787 12.7811 16.8247 12.6396 16.6999C12.3723 16.4395 12.0127 16.2957 11.6396 16.2999Z" fill="black" />
      <Path d="M11.7802 5.60004C10.7698 5.56341 9.781 5.89776 9.0002 6.54004C8.24082 7.20969 7.77412 8.15027 7.7002 9.16004H10.0002C10.022 8.72186 10.2076 8.30785 10.5202 8.00004C10.6797 7.8518 10.867 7.7366 11.0712 7.66109C11.2755 7.58557 11.4926 7.55123 11.7102 7.56004C11.9235 7.54893 12.1368 7.58231 12.3365 7.65806C12.5362 7.7338 12.7179 7.85026 12.8702 8.00004C13.019 8.15135 13.1357 8.33127 13.213 8.52893C13.2904 8.72659 13.3268 8.93789 13.3202 9.15004C13.3349 9.57819 13.1804 9.9949 12.8902 10.31L11.5202 11.68C11.2118 11.9726 10.9488 12.3097 10.7402 12.68C10.6053 13.0372 10.5473 13.4189 10.5702 13.8V14.94H12.6802V14.1C12.6435 13.6781 12.7568 13.2567 13.0002 12.91L13.3502 12.54L13.8702 12.04L14.3702 11.56C14.4902 11.43 14.6702 11.22 14.9102 10.92C15.3326 10.3902 15.5524 9.72725 15.5302 9.05004C15.5595 8.57657 15.4822 8.10256 15.3039 7.66296C15.1256 7.22336 14.851 6.82937 14.5002 6.51004C13.7372 5.8809 12.7682 5.5567 11.7802 5.60004Z" fill="black" />
    </Svg>
  );
}
