import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeInputError,
  forgotUsernameInitiate,
  forgotUsernameVerify,
  resetInputErrors,
  resetState,
} from '@dmi/shared/redux/Register/ForgotUsername/actions';
import reducer from '@dmi/shared/redux/Register/ForgotUsername/reducer';
import saga from '@dmi/shared/redux/Register/ForgotUsername/saga';
import makeSelectForgotUsername, {
  getForgotUsernameConfirmationPageProps,
  getForgotUsernameError,
  getForgotUsernameForm,
  getForgotUsernameFormErrors,
  getForgotUsernamePageView,
  getForgotUsernameQueryString,
  getForgotUsernameStep,
} from '@dmi/shared/redux/Register/ForgotUsername/selectors';
import { handleNext } from '@dmi/shared/redux/Register/Shared/helpers';

import injectReducer from '../../../utils/injectReducer';
import injectSaga from '../../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../../utils/globalHelpers';
import { KeyboardAvoidingScrollView } from '../../../components/base_ui';
import viewDictionary from './viewDictionary';
import NotFoundScreen from '../../NotFoundScreen';

const ForgotUsername = ({
  bannerError,
  confirmationPageProps,
  dispatchChangeInput,
  dispatchChangeInputError,
  dispatchForgotUsername,
  dispatchResetInputErrors,
  dispatchResetState,
  dispatchVerifyUsername,
  formErrors,
  formValues,
  linkQueryString,
  navigation,
  status,
  step,
  view,
}) => {
  const scrollViewRef = useRef();
  const {
    viewCardProps,
    ViewComponent,
    ViewFormInput,
  } = view ? viewDictionary[view][step] : { ViewComponent: NotFoundScreen };

  useEffect(() => dispatchResetState, [dispatchResetState]);

  const handleNextClick = ({
    field,
    form,
    formValues: values,
    handleSubmitStep,
    skipStep,
  }) => {
    handleNext({
      dispatchError: dispatchChangeInputError,
      dispatchNext: () => null,
      dispatchResetInputErrors,
      field,
      form,
      formValues: values,
      handleSubmitStep,
      skipStep,
      step,
    });
  };

  const handleScrollToBanner = () => {
    scrollViewRef.current.scrollTo({ animated: true, x: 0, y: 0 });
  };

  const formInputProps = {
    dispatchChangeInput,
    dispatchForgotUsername,
    dispatchVerifyUsername,
    formErrors,
    formValues,
    handleNextClick,
    linkQueryString,
    navigation,
    status,
    step,
  };

  return (
    <KeyboardAvoidingScrollView scrollViewRef={scrollViewRef}>
      <ViewComponent
        bannerError={bannerError}
        formInputProps={formInputProps}
        handlePress={() => navigation.replace('Login')}
        handleScrollToBanner={handleScrollToBanner}
        navigation={navigation}
        viewCardProps={viewCardProps}
        ViewFormInput={ViewFormInput}
        {...confirmationPageProps}
      />
    </KeyboardAvoidingScrollView>
  );
};

ForgotUsername.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  confirmationPageProps: T.object.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeInputError: T.func.isRequired,
  dispatchForgotUsername: T.func.isRequired,
  dispatchResetInputErrors: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchVerifyUsername: T.func.isRequired,
  formErrors: T.object,
  formValues: T.object,
  linkQueryString: T.string.isRequired,
  navigation: T.object.isRequired,
  route: T.object.isRequired,
  status: T.object.isRequired,
  step: T.number.isRequired,
  view: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: ForgotUsername
   */
  bannerError: getForgotUsernameError('bannerError'),
  confirmationPageProps: getForgotUsernameConfirmationPageProps(),
  formErrors: getForgotUsernameFormErrors(),
  formValues: getForgotUsernameForm(),
  linkQueryString: getForgotUsernameQueryString(),
  status: makeSelectForgotUsername('status'),
  step: getForgotUsernameStep(),
  view: getForgotUsernamePageView(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: ForgotUsername
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeInputError: (payload) => dispatch(changeInputError(payload)),
  dispatchForgotUsername: (payload) => dispatch(forgotUsernameInitiate(payload)),
  dispatchResetInputErrors: (payload) => dispatch(resetInputErrors(payload)),
  dispatchResetState: () => dispatch(resetState()),
  dispatchVerifyUsername: (payload) => dispatch(forgotUsernameVerify(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'forgotUsername', reducer });
const withSaga = injectSaga({
  key: 'forgotUsername',
  saga: saga({ baseUrl: getBaseApplicationServerUrl() }),
});

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(ForgotUsername);
