import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeStep,
  chooseDocument,
  removeAttachment,
  resetRequestStatus,
  updateAttachments,
} from '@dmi/shared/redux/LossDraft/actions';
import { MESSAGES_ATTACHMENT_REVIEW_DICTIONARY } from '@dmi/shared/redux/LossDraft/constants';
import { selectForm, selectFormErrors } from '@dmi/shared/redux/LossDraft/selectors';

import { MessagesAttachmentReview } from '../../../components/MessagesAttachment';
import { AttachmentView } from '../styledComponents';

const NewMessageAttachmentReview = ({
  dispatchChangeStep,
  dispatchChooseDocument,
  dispatchRemoveAttachment,
  dispatchResetRequestStatus,
  dispatchUpdateAttachments,
  formAttachments,
  formUploadError,
  navigation,
}) => {
  const handleBack = () => {
    dispatchResetRequestStatus({ requestStatus: 'chooseDocument' });
    dispatchChangeStep(1);
  };

  return (
    <AttachmentView>
      <MessagesAttachmentReview
        dictionary={MESSAGES_ATTACHMENT_REVIEW_DICTIONARY}
        dispatchChooseDocument={dispatchChooseDocument}
        dispatchRemoveAttachment={dispatchRemoveAttachment}
        dispatchResetRequestStatus={dispatchResetRequestStatus}
        dispatchUpdateAttachments={dispatchUpdateAttachments}
        formAttachments={formAttachments}
        formUploadError={formUploadError}
        handleBack={handleBack}
        navigation={navigation}
      />
    </AttachmentView>
  );
};

NewMessageAttachmentReview.propTypes = {
  dispatchChangeStep: T.func.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  dispatchRemoveAttachment: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  dispatchUpdateAttachments: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formUploadError: T.string.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: LossDraft
   */
  formAttachments: selectForm('attachments'),
  formMessage: selectForm('message'),
  formUploadError: selectFormErrors('attachments'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: LossDraft
   */
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchRemoveAttachment: (payload) => dispatch(removeAttachment(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
  dispatchUpdateAttachments: (payload) => dispatch(updateAttachments(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageAttachmentReview);
