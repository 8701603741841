import { useState } from 'react';
import { Platform } from 'react-native';

import { INITIAL_TRANSLATE_X } from '../constants';
import useAnimatedValues from './useAnimatedValues';
import { grow, handleLabelLayoutGenerator, shrink } from './helpers';

/**
 * @description Custom hook to set up and handle animation logic for LoginRegisterTextField label
 *
 * @param {object} props
 * @param {boolean} props.inputHasValue
 */
const useLabelAnimation = ({ inputHasValue }) => {
  /*
    State variables
  */
  const [labelTranslateX, setLabelTranslateX] = useState(INITIAL_TRANSLATE_X);
  const [labelIsShrunk, setLabelIsShrunk] = useState(inputHasValue);
  const labelShouldGrow = !inputHasValue;

  /*
    Animated API variables that use Animated.Value
  */
  const { scaleAnim, translateXAnim, translateYAnim } = useAnimatedValues({ labelIsShrunk });

  /*
    Animation performs much better on native when using native driver. When setting it to true
    for web, there is a console warning saying it can't useNativeDriver because the
    native animated module is missing, linking to the following GH issue:
    https://github.com/facebook/react-native/issues/11094#issuecomment-263240420.
    It runs fine with useNativeDriver set to false, though
  */
  const useNativeDriver = Platform.OS !== 'web';

  /*
    Functions to add to TextField/Input's onFocus and onBlur to trigger the animations
  */
  const onFocusLabelAnimation = () => {
    if (!labelIsShrunk) {
      shrink({
        labelTranslateX,
        scaleAnim,
        setLabelIsShrunk,
        translateXAnim,
        translateYAnim,
        useNativeDriver,
      });
    }
  };

  const onBlurLabelAnimation = () => {
    if (labelShouldGrow) {
      grow({
        scaleAnim,
        setLabelIsShrunk,
        translateXAnim,
        translateYAnim,
        useNativeDriver,
      });
    }
  };

  /*
    Used to calculate the translateX value necessary to imitate `transformOrigin: 'left'`
  */
  const handleLabelLayout = handleLabelLayoutGenerator({
    labelIsShrunk,
    setLabelTranslateX,
    translateXAnim,
  });

  return {
    handleLabelLayout,
    isFirstRender: labelTranslateX === 0,
    labelIsShrunk,
    onBlurLabelAnimation,
    onFocusLabelAnimation,
    scaleAnim,
    translateXAnim,
    translateYAnim,
  };
};

export default useLabelAnimation;
