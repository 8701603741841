import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { initialState } from '@dmi/shared/redux/Payments/OneTimePayment/reducer';
import {
  MAX_TOTAL_AMOUNT_ERROR,
  ZERO_AMOUNT_ERROR,
} from '@dmi/shared/redux/Payments/OneTimePayment/constants';
import { convertCurrencyStrToNum, convertNumToCurrencyStr } from '@dmi/shared/utils/globalHelpers';
import { validate } from '@dmi/shared/utils/validate';

import { SecondaryHeader } from '../../../ScreenHeaders';
import { ConditionalRender, KeyboardAvoidingScrollView, Tooltip } from '../../../base_ui';
import { AddressHeader, PaymentSummary } from '../../sharedComponents';
import AdditionalPaymentToggle from './AdditionalPaymentToggle';
import NumberPaymentsRow from './NumberPaymentsRow';
import RegularPaymentRow from './RegularPaymentRow';
import SchedulePaymentTooltip from './Tooltips';
import {
  EditPaymentContent,
  EditPaymentContentWrapper,
  StyledPrimaryDivider,
  StyledStatusBanner,
} from './styledComponents';

const EditPaymentAmount = ({
  address,
  amountForm,
  amountFormErrors,
  availableNumberOfPayments,
  bannerError,
  dispatchResetBannerError,
  dispatchSetBannerError,
  dispatchSetIsModalOpen,
  dispatchUpdateAmount,
  dueDate,
  fields,
  isAfterGracePeriod,
  isScheduledDateAfterGracePeriod,
  loanNumber,
  maxEqualValues,
  maxTotalPayment,
  navigation,
  paymentLogic: {
    canMakeAdditionalPayment,
    canMakeMonthlyPayment,
    minimumPaymentForAdditionalPrincipalOrEscrow,
  },
  payType,
  regularMonthlyPayment,
}) => {
  const [monthlyToggle, setMonthlyToggle] = useState(payType === 'Monthly');
  const [additionalToggle, setAdditionalToggle] = useState(true);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [selectedTooltip, setSelectedTooltip] = useState('');

  // Form Input state, will be committed to Redux on form submission
  // Convert currency inputs dataType from number to string
  const initialFormState = Object.entries(amountForm).reduce((acc, [field, value]) => {
    acc[field] = value === 0 ? '0' : value.toFixed(2);
    return acc;
  }, {});
  const [formState, setFormState] = useState({
    ...initialFormState,
    numberOfPayments: amountForm.numberOfPayments,
  });
  const [formStateErrors, setFormStateErrors] = useState(initialState.otp.formErrors.amount);

  const handleFormValidation = ({ numberOfPayments, ...restInputs }) => {
    const validationErrors = Object.entries(restInputs).reduce((acc, [field, value]) => {
      const { message } = validate({
        maxEqual: maxEqualValues[field],
        type: 'currency',
        value,
      }) || {};
      if (message) {
        acc[field] = message;
      }
      return acc;
    }, {});
    const hasErrors = !isEmpty(validationErrors);
    if (hasErrors) {
      setFormStateErrors({ ...formStateErrors, ...validationErrors });
    }
    return hasErrors;
  };

  const handleBack = () => {
    dispatchResetBannerError();
    navigation.goBack();
  };

  const handleClose = () => {
    setIsTooltipOpen(false);
    setSelectedTooltip('');
  };

  const handleOpen = (tooltipName) => {
    setIsTooltipOpen(true);
    setSelectedTooltip(tooltipName);
  };

  const handleNumPaymentsUpdate = (newValue) => {
    const resetField = newValue < minimumPaymentForAdditionalPrincipalOrEscrow;
    setFormState({
      ...formState,
      additionalEscrow: resetField ? '0' : formState.additionalEscrow,
      additionalPrincipal: resetField ? '0' : formState.additionalPrincipal,
      numberOfPayments: newValue,
    });
  };

  const handleUpdateAmount = () => {
    const hasValidationErrors = handleFormValidation(formState);

    if (!hasValidationErrors) {
      if (convertCurrencyStrToNum(calculateTotalPayment()) === 0) {
        dispatchSetBannerError(ZERO_AMOUNT_ERROR);
        return;
      }
      if (convertCurrencyStrToNum(calculateTotalPayment()) > maxTotalPayment) {
        dispatchSetBannerError(MAX_TOTAL_AMOUNT_ERROR);
        return;
      }
      // Convert currency inputs dataType from string to number
      dispatchResetBannerError();
      const convertedFormState = Object.entries(formState).reduce((acc, [field, value]) => {
        acc[field] = Number(value);
        return acc;
      }, {});
      dispatchUpdateAmount({
        amount: convertedFormState,
        payType: monthlyToggle ? 'Monthly' : 'Additional',
      });
      if (
        payType !== 'Monthly'
        && monthlyToggle
        && !isAfterGracePeriod
        && isScheduledDateAfterGracePeriod
      ) {
        dispatchSetIsModalOpen({ key: 'latePaymentWarning', value: true });
      }
      navigation.goBack();
    }
  };

  const calculateTotalPayment = () => {
    const { numberOfPayments, ...restPayment } = formState;
    const monthlyPayment =
      convertCurrencyStrToNum(regularMonthlyPayment) * numberOfPayments;
    const [additionalPayment, newErrors] = Object.entries(restPayment)
      .reduce((acc, [key, amount]) => {
        const { message } = validate({
          maxEqual: maxEqualValues[key],
          type: 'currencyOnChange',
          value: amount,
        });
        if (message) {
          acc[1][key] = message;
        } else {
          acc[0] += convertCurrencyStrToNum(amount);
        }
        return acc;
      }, [0, {}]);
    const newErrorState = { ...formStateErrors, ...newErrors };
    if (!isEqual(formStateErrors, newErrorState)) {
      setFormStateErrors(newErrorState);
    }
    const total = monthlyToggle ? monthlyPayment + additionalPayment : additionalPayment;
    return convertNumToCurrencyStr(total);
  };

  const handleRegularTogglePress = () => {
    if (payType === 'Monthly' && monthlyToggle && !canMakeAdditionalPayment) return;
    if (monthlyToggle) {
      setFormState({ ...formState, numberOfPayments: 1 });
      setFormStateErrors(initialState.otp.formErrors.amount);
    }
    setMonthlyToggle(!monthlyToggle);
  };

  const menuItems = availableNumberOfPayments.map((num) => ({ label: num.toString(), value: num }));

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={handleBack}
        title="Edit Payment Amount"
      />
      <KeyboardAvoidingScrollView scrollViewProps={{ showsVerticalScrollIndicator: false }}>
        <StyledStatusBanner error={bannerError} success={false} />
        <AddressHeader
          address={address}
          dueDate={dueDate}
          loanNumber={loanNumber}
        />
        <EditPaymentContentWrapper>
          <EditPaymentContent>
            <RegularPaymentRow
              canMakeAdditionalPayment={canMakeAdditionalPayment}
              canMakeMonthlyPayment={canMakeMonthlyPayment}
              handleOpen={handleOpen}
              handleTogglePress={handleRegularTogglePress}
              monthlyToggle={monthlyToggle}
              regularMonthlyPayment={regularMonthlyPayment}
            />
            <ConditionalRender
              Component={(
                <NumberPaymentsRow
                  handleNumPaymentsUpdate={handleNumPaymentsUpdate}
                  handleOpen={handleOpen}
                  menuItems={menuItems}
                  numberOfPayments={formState.numberOfPayments}
                />
              )}
              shouldRender={canMakeMonthlyPayment && monthlyToggle}
            />
            <StyledPrimaryDivider />
            <AdditionalPaymentToggle
              additionalToggle={additionalToggle}
              amountFormErrors={amountFormErrors}
              bannerError={bannerError}
              canMakeMonthlyPayment={canMakeMonthlyPayment}
              fields={fields}
              formState={formState}
              formStateErrors={formStateErrors}
              handleOpen={handleOpen}
              // eslint-disable-next-line max-len
              minimumPaymentForAdditionalPrincipalOrEscrow={minimumPaymentForAdditionalPrincipalOrEscrow}
              payType={payType}
              setAdditionalToggle={setAdditionalToggle}
              setFormState={setFormState}
              setFormStateErrors={setFormStateErrors}
            />
          </EditPaymentContent>
        </EditPaymentContentWrapper>
        <PaymentSummary
          bannerError={bannerError}
          label="Update Amount"
          onPress={handleUpdateAmount}
          subText={`Due on ${dueDate}`}
          text={calculateTotalPayment()}
        />
      </KeyboardAvoidingScrollView>
      <ConditionalRender
        Component={(
          <Tooltip
            handleClose={handleClose}
            positionAbsolute={{ left: 'auto', top: 'auto' }}
            visible={isTooltipOpen}
          >
            <SchedulePaymentTooltip
              handleCloseTooltip={handleClose}
              maxMonth={(menuItems[menuItems.length - 1] || {}).label}
              selectedTooltip={selectedTooltip}
            />
          </Tooltip>
        )}
        shouldRender={isTooltipOpen}
      />
    </Fragment>
  );
};

EditPaymentAmount.propTypes = {
  address: T.string.isRequired,
  amountForm: T.object.isRequired,
  amountFormErrors: T.object.isRequired,
  availableNumberOfPayments: T.array.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchSetBannerError: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  dispatchUpdateAmount: T.func.isRequired,
  dueDate: T.string.isRequired,
  fields: T.array.isRequired,
  isAfterGracePeriod: T.bool.isRequired,
  isScheduledDateAfterGracePeriod: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  maxEqualValues: T.object.isRequired,
  maxTotalPayment: T.number.isRequired,
  navigation: T.object.isRequired,
  paymentLogic: T.object.isRequired,
  payType: T.string.isRequired,
  regularMonthlyPayment: T.string.isRequired,
};

export default EditPaymentAmount;
