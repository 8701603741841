import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P3 } from '../../../../base_ui';
import { getBackgroundColor } from './helpers';

export const Circle = styled.View`
  background-color: ${getBackgroundColor};
  border-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: ${moderateScale(50)}px;
  border-style: solid;
  border-width: ${moderateScale(2)}px;
  height: ${moderateScale(18)}px;
  margin-right: ${moderateScale(5)}px;
  width: ${moderateScale(18)}px;
`;

export const Container = styled.View`
  padding: ${moderateScale(28)}px ${moderateScale(20)}px;
`;

export const Row = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: ${moderateScale(10)}px;
`;

export const Text = styled(P3)`
  padding-left: ${moderateScale(6)}px;
`;
