/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavMortgageDefaultMIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M8.99988 6.20996C8.82781 6.20996 8.65961 6.26099 8.51654 6.35658C8.37347 6.45218 8.26196 6.58805 8.19611 6.74703C8.13026 6.906 8.11303 7.08093 8.1466 7.24969C8.18017 7.41845 8.26303 7.57347 8.3847 7.69514C8.50637 7.81682 8.66139 7.89968 8.83015 7.93324C8.99892 7.96681 9.17385 7.94958 9.33282 7.88374C9.49179 7.81789 9.62766 7.70638 9.72326 7.56331C9.81886 7.42024 9.86988 7.25203 9.86988 7.07996C9.86988 6.84922 9.77822 6.62793 9.61507 6.46478C9.45191 6.30162 9.23062 6.20996 8.99988 6.20996Z" fill="black" />
      <Path d="M16.9199 7L15.4299 6.06L13.9999 5.11V3.11C14.0012 3.03215 13.9869 2.95482 13.9577 2.88263C13.9285 2.81043 13.8851 2.74485 13.8301 2.68979C13.775 2.63474 13.7094 2.59133 13.6372 2.56216C13.5651 2.53298 13.4877 2.51865 13.4099 2.52C13.2534 2.52 13.1033 2.58216 12.9927 2.69281C12.882 2.80346 12.8199 2.95353 12.8199 3.11V4.34L9.30987 2.08C9.21444 2.01377 9.10104 1.97827 8.98487 1.97827C8.8687 1.97827 8.75531 2.01377 8.65987 2.08L3.15987 5.77L1.20987 7C1.14138 7.03923 1.08146 7.0918 1.03365 7.1546C0.985836 7.2174 0.951113 7.28915 0.931531 7.36561C0.91195 7.44207 0.907909 7.52168 0.919648 7.59973C0.931386 7.67778 0.958666 7.75269 0.999872 7.82C1.05232 7.90481 1.12609 7.97437 1.21383 8.02175C1.30156 8.06913 1.40019 8.09266 1.49987 8.09C1.61993 8.0973 1.73914 8.06574 1.83987 8L2.30987 7.7V15.89C2.31249 16.0474 2.37687 16.1974 2.4891 16.3078C2.60134 16.4182 2.75246 16.48 2.90987 16.48H15.2499C15.4073 16.48 15.5584 16.4182 15.6706 16.3078C15.7829 16.1974 15.8472 16.0474 15.8499 15.89V7.72L16.2799 8C16.3764 8.05857 16.487 8.08969 16.5999 8.09C16.6994 8.09168 16.7976 8.06772 16.8852 8.02043C16.9728 7.97314 17.0467 7.90412 17.0999 7.82C17.1806 7.68626 17.2069 7.52667 17.1734 7.37411C17.1399 7.22155 17.0492 7.08765 16.9199 7ZM10.3199 15.29H7.83987V11H10.3199V15.29ZM14.6599 15.29H11.2699V10.51C11.2754 10.445 11.2674 10.3796 11.2463 10.3178C11.2251 10.2561 11.1914 10.1995 11.1472 10.1515C11.103 10.1035 11.0493 10.0653 10.9895 10.0392C10.9297 10.0131 10.8651 9.99977 10.7999 10H7.35987C7.23522 10 7.11567 10.0495 7.02753 10.1377C6.93939 10.2258 6.88987 10.3454 6.88987 10.47V15.25H3.49987V7L8.99987 3.28L14.3499 6.76L14.6499 6.96L14.6599 15.29Z" fill="black" />
    </Svg>
  );
}
