import React from 'react';
import T from 'prop-types';
import * as WebBrowser from 'expo-web-browser';
import { useIntl } from 'react-intl';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import { AlertBanner, StyledScrollView } from '../../base_ui';
import PrimaryDocUploadButton from '../../DocUpload/PrimaryDocPickerButton';
import { ButtonWrapper } from '../styledComponents';
import {
  ChangeAddressContainer,
  FlexView,
  IllustrationWrapper,
  LinkText,
  StyledH2,
  StyledP2,
} from './styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(210)}px` });

const ChangeMailingAddressUploadComponent = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  documentUrl,
  reviewScreenName,
}) => {
  const { formatMessage } = useIntl();
  const { mobileSubheader } = LOANS_CONTENT_DICTIONARY.changeMailingAddress.uploadView;
  const handleOpenWithWebBrowser = (href) => {
    WebBrowser.openBrowserAsync(href);
  };

  return (
    <StyledScrollView>
      <ChangeAddressContainer>
        <IllustrationWrapper $isUpload>
          {UploadHouse}
        </IllustrationWrapper>
        <StyledH2>{mobileSubheader}</StyledH2>
        <StyledP2>To update your mailing address, you must complete the&nbsp;
          <LinkText onPress={() => handleOpenWithWebBrowser(documentUrl)}>
            Change of Mailing Address Form
          </LinkText>
          &nbsp;and obtain signatures from all {formatMessage(
            { id: INTL_IDS.CHANGEMAILINGADDRESS_UPLOADDOCUMENT_TEXT_BORROWERS },
          )}.
        </StyledP2>
        <AlertBanner
          boldText="Formats accepted: "
          text="JPEG, JPG, PDF, PNG, TIFF, or GIF (Max size 10 MB)."
        />
        <FlexView />
        <ButtonWrapper>
          <PrimaryDocUploadButton
            chooseDocumentRequestStatus={chooseDocumentRequestStatus}
            dispatchChooseDocument={dispatchChooseDocument}
            reviewScreenName={reviewScreenName}
          />
        </ButtonWrapper>
      </ChangeAddressContainer>
    </StyledScrollView>
  );
};

ChangeMailingAddressUploadComponent.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  documentUrl: T.string.isRequired,
  reviewScreenName: T.string.isRequired,
};

export default ChangeMailingAddressUploadComponent;
