/**
 * AnimatedLabel
 * @description: The label element used for LoginRegisterTextField.
 * Component props: {
 *     display (string) the label's text
 *     error (bool) If true, label gets red styling and will stay in shrunk state [default false]
 *     inputDisabled (bool) If true, label gets gray styling [default false]
 *     isFirstRender (bool) Whether component is still on 1st render, in which case we
 *                              calculate its width
 *     isShrunk (bool) Whether label is in upper, shrunk state. Affects styling.
 *     scale (object) An Animated.Value object with current scale value for transform
 *     translateX (object) An Animated.Value object with current translateX value for transform
 *     translateY (object) An Animated.Value object with current translateY value for transform
 * }
 */

import React from 'react';
import { Platform } from 'react-native';
import T from 'prop-types';

import { StyledAnimatedText } from './styledComponents';

const AnimatedLabel = ({
  display,
  error = false,
  inputDisabled = false,
  isFirstRender,
  isRegister,
  isShrunk,
  scale,
  translateX,
  translateY,
}) => {
  let transform;
  if (isFirstRender) {
    transform = [{ translateY }];
  } else {
    transform = [
      { translateX },
      { translateY },
      { scale },
    ];
  }

  /*
    'label' is not a valid built in accessibilityRole for React Native and will throw
    an error in Android, but it is used by react-native-web to convert the element into
    a semantic <label> element. Fallback for mobile can just be a plain 'text' role.
  */
  const role = Platform.OS === 'web' ? 'label' : null;

  return (
    <StyledAnimatedText
      $error={error}
      $inputDisabled={inputDisabled}
      $isFirstRender={isFirstRender}
      $isRegister={isRegister}
      $isShrunk={isShrunk}
      role={role}
      style={{ transform }}
    >
      {display}
    </StyledAnimatedText>
  );
};

AnimatedLabel.propTypes = {
  display: T.oneOfType([T.object, T.string]).isRequired,
  error: T.bool,
  inputDisabled: T.bool,
  isFirstRender: T.bool.isRequired,
  isRegister: T.bool,
  isShrunk: T.bool.isRequired,
  scale: T.object.isRequired,
  translateX: T.object.isRequired,
  translateY: T.object.isRequired,
};

export default AnimatedLabel;
