import React, { useEffect } from 'react';
import T from 'prop-types';
import { Keyboard } from 'react-native';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import { formatPhoneNumber } from '@dmi/shared/utils/globalHelpers';
import { usePrevious } from '../../../utils/customHooks';

import {
  FormattedMessage,
  H4,
  KeyboardAvoidingScrollView,
  MediumProgressButton,
  P2,
} from '../../base_ui';
import {
  BoldText,
  ButtonWrapper,
  PaddingWrapper,
  SendNewCodeWrapper,
  StyledBaseTextField,
  StyledBorderlessButton,
  StyledPrimaryDivider,
  StyledStatusBanner,
  TextFieldWrapper,
  TopVerifyP2,
} from './styledComponents';

const VerifyMobileNumberComponent = ({
  bannerError,
  dispatchChangeInput,
  dispatchResetFormErrorsState,
  formErrors,
  forms,
  handleEditMobileNumber,
  handleVerifyMobileNumber,
  success,
  verifyMobileNumberStatus,
}) => {
  const { mobileNumber: mobileNumberForm, verifyMobileNumber: verifyMobileNumberForm } = forms;
  const { verifyMobileNumber: verifyMobileNumberFormErrors } = formErrors;
  const { newMobileNumber } = mobileNumberForm;
  const { passCode } = verifyMobileNumberForm;
  const { subheader, text } = PROFILE_CONTENT_DICTIONARY.editMobileNumber.verifyMobileNumberView;
  const previousPassCode = usePrevious(passCode);

  useEffect(() => {
    /**
     * Want to auto submit when user types in the 6th digit. Do not want to trigger submission again
     * simply by handleVerifyMobileNumber dependency changing while passCode.length is still 6.
     */
    if (passCode !== previousPassCode && passCode.length === 6) {
      handleVerifyMobileNumber();
      Keyboard.dismiss();
    }
  }, [handleVerifyMobileNumber, passCode, previousPassCode]);

  const handleChangeText = ({ field, value }) => {
    const formName = 'verifyMobileNumber';
    dispatchResetFormErrorsState({ field, form: formName });
    dispatchChangeInput({
      field,
      form: formName,
      value,
    });
  };

  return (
    <KeyboardAvoidingScrollView scrollViewProps={{ showsVerticalScrollIndicator: false }}>
      <PaddingWrapper>
        <StyledStatusBanner error={bannerError} success={success} />
        <H4>{subheader}</H4>
        <FormattedMessage
          defaultMessage={text}
          values={{
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <BoldText>{msg}</BoldText>,
            newMobileNumber: formatPhoneNumber(newMobileNumber),
          }}
          WrapperComponent={TopVerifyP2}
        />
      </PaddingWrapper>
      <StyledPrimaryDivider />
      <PaddingWrapper>
        <TextFieldWrapper>
          <StyledBaseTextField
            errorMessage={verifyMobileNumberFormErrors.passCode}
            keyboardType="numeric"
            label="Temporary Code"
            onChangeText={(value) => handleChangeText({
              field: 'passCode',
              value,
            })}
            placeholder="Required"
            value={passCode}
          />
        </TextFieldWrapper>
      </PaddingWrapper>
      <StyledPrimaryDivider />
      <PaddingWrapper $isFlex>
        <P2>It can take up to a few minutes to receive the text message.</P2>
        <SendNewCodeWrapper>
          <P2>Haven&apos;t received it yet?&nbsp;</P2>
          <StyledBorderlessButton
            label="Resend code"
            onPress={handleEditMobileNumber}
          />
        </SendNewCodeWrapper>
      </PaddingWrapper>
      <ButtonWrapper>
        <MediumProgressButton
          disabled={!passCode}
          label="Submit"
          onPress={handleVerifyMobileNumber}
          status={verifyMobileNumberStatus}
        />
      </ButtonWrapper>
    </KeyboardAvoidingScrollView>
  );
};

VerifyMobileNumberComponent.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetFormErrorsState: T.func.isRequired,
  formErrors: T.shape({ verifyMobileNumber: T.object.isRequired }).isRequired,
  forms: T.shape({
    mobileNumber: T.object.isRequired,
    verifyMobileNumber: T.object.isRequired,
  }).isRequired,
  handleEditMobileNumber: T.func.isRequired,
  handleVerifyMobileNumber: T.func.isRequired,
  success: T.string.isRequired,
  verifyMobileNumberStatus: T.string.isRequired,
};

export default VerifyMobileNumberComponent;
