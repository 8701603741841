import React, { Fragment, useRef } from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../base_ui';
import ProvinceDropdown from './ProvinceDropdown';
import CountryDropdown from './CountryDropdown';
import { StyledBaseTextField } from '../styledComponents';

const InternationalForm = ({
  allowedCountries,
  allowedProvinces,
  formErrors,
  handleChangeInput,
  isAddressCanadian,
  requestBody,
}) => {
  const addressLine2Ref = useRef();
  const addressLine3Ref = useRef();
  const cityRef = useRef();
  const postalCodeRef = useRef();
  const streetAddressRef = useRef();

  return (
    <Fragment>
      <StyledBaseTextField
        ref={streetAddressRef}
        error={formErrors.streetAddress}
        label="Street Address"
        onChangeText={(value) => handleChangeInput('streetAddress', value)}
        onSubmitEditing={() => addressLine2Ref.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={requestBody.streetAddress}
      />
      <StyledBaseTextField
        ref={addressLine2Ref}
        error={formErrors.addressLine2}
        label="Address Line 2"
        onChangeText={(value) => handleChangeInput('addressLine2', value)}
        onSubmitEditing={() => addressLine3Ref.current.focus()}
        placeholder="Optional"
        returnKeyType="next"
        value={requestBody.addressLine2}
      />
      <StyledBaseTextField
        ref={addressLine3Ref}
        error={formErrors.addressLine3}
        label="Address Line 3"
        onChangeText={(value) => handleChangeInput('addressLine3', value)}
        onSubmitEditing={() => cityRef.current.focus()}
        placeholder="Optional"
        returnKeyType="next"
        value={requestBody.addressLine3}
      />
      <StyledBaseTextField
        ref={cityRef}
        error={formErrors.city}
        label="City"
        onChangeText={(value) => handleChangeInput('city', value)}
        onSubmitEditing={() => postalCodeRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={requestBody.city}
      />
      <StyledBaseTextField
        ref={postalCodeRef}
        error={formErrors.postalCode}
        label="Postal Code"
        onChangeText={(value) => handleChangeInput('postalCode', value)}
        placeholder={isAddressCanadian ? 'Required' : 'Optional'}
        returnKeyType="done"
        value={requestBody.postalCode}
        width={109}
      />
      <CountryDropdown
        allowedCountries={allowedCountries}
        handleChangeInput={handleChangeInput}
        selected={requestBody.country}
      />
      <ConditionalRender
        Component={(
          <ProvinceDropdown
            allowedProvinces={allowedProvinces}
            handleChangeInput={handleChangeInput}
            selected={requestBody.province}
          />
        )}
        shouldRender={isAddressCanadian}
      />
    </Fragment>
  );
};

InternationalForm.propTypes = {
  allowedCountries: T.array.isRequired,
  allowedProvinces: T.array.isRequired,
  formErrors: T.shape({
    addressLine2: T.string.isRequired,
    addressLine3: T.string.isRequired,
    city: T.string.isRequired,
    country: T.string.isRequired,
    postalCode: T.string.isRequired,
    province: T.string.isRequired,
    streetAddress: T.string.isRequired,
  }).isRequired,
  handleChangeInput: T.func.isRequired,
  isAddressCanadian: T.bool.isRequired,
  requestBody: T.shape({
    addressLine2: T.string.isRequired,
    addressLine3: T.string.isRequired,
    city: T.string.isRequired,
    country: T.string.isRequired,
    postalCode: T.string.isRequired,
    province: T.string.isRequired,
    streetAddress: T.string.isRequired,
  }).isRequired,
};

export default InternationalForm;
