import styled from 'styled-components/native';
import { TextInput } from 'react-native';

import { H1 } from '../../Heading';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const InputLine = styled.View`
  background-color: ${({
    active,
    theme: { colors: { primary, primaryGray } },
  }) => active ? primary : primaryGray};
  height: ${({ active }) => active ? moderateScale(2) : moderateScale(1)}px;
  margin-top: ${({ active }) => active ? -moderateScale(0.5) : moderateScale(0)}px;
  width: 100%;
`;

export const InputLineContainer = styled.View`
  padding-horizontal: ${moderateScale(4)}px;
  width: ${({ maxLength }) => 100 / maxLength}%;
`;

export const InputText = styled(H1)`
  margin-bottom: ${moderateScale(7)}px;
  min-height: ${moderateScale(26)}px;
  text-align: center;
  width: 100%;
`;

export const PasscodeInputContainer = styled.View`
  align-items: center;
  flex-direction: row;
  position: relative;
`;

export const StyledInput = styled(TextInput)`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f8 } } }) => f8}px;
  height: ${moderateScale(48)}px;
  line-height: ${({ theme: { fontSizes: { f9 } } }) => f9}px;
  max-width: ${({ maxLength }) => `${moderateScale((48 * maxLength) + (8 * maxLength))}`}px;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 100;
`;
