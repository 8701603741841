/**
 * KeyboardAvoidingScrollView
 *
 * @description Wraps children in a ScrollView that, when the virtual keyboard shows, will scroll
 * the form up into view if the keyboard hid some of it. It will scroll the bottom of the form to
 * be just above the keyboard.
 *  - Edge case: If the form is so large (and/or screen so small) that both the currently focused
 * input towards the top of the form and the action button at the bottom of the form cannot be
 * visible at the same time, it favors keeping the currently focused input visible.
 *
 * Usage Notes:
 *  - Most fundamentally, this is a ScrollView. The `children` prop will be wrapped inside the
 *    ScrollView.
 *  - There are 2 main categories of use cases for this component.
 *    - One is where there is a large ScrollView with a form in the middle somewhere, and the form's
 *      inputs and action button are fixed together. (Eg/namely, our Login screen.)
 *      - You should pass a `formRef` prop for this option. (And you should attach the formRef
 *        to the form component. You'll probably also need to give that form component a
 *        'collapsable = false' prop for the sake of Android.)
 *    - Another is where the ScrollView's main/only purpose is to show the form, and the inputs and
 *      action button are not fixed together. Rather, the input section has flexGrow that pushes
 *      the action button down to the bottom of the screen.
 *      - This is the default option (ie, if you don't pass `formRef`).
 *  - ownsCurrentKeyboard prop
 *    - A ScrollView with a form might have a Drawer/Modal component that can render above it, and
 *      the Drawer/Modal also has its own form/inputs. When the Drawer/Modal pops open and its input
 *      is focused, we don't want the underlying ScrollView to try to scroll at all. (Also, we want
 *      to avoid calling `currentlyFocusedInput.measureLayout(scrollViewRef, ...)` when the
 *      currentlyFocusedInput is not an ancestor of the scrollViewRef, since that throws an
 *      uncatchable error.) To prevent that, the `ownsCurrentKeyboard` prop should only be `true`
 *      when the Drawer/Modal is not open.
 *  - bannerError handling
 *    - It also handles the not uncommon use case where the ScrollView should autoscroll to the
 *      top of the screen to show a new bannerError.
 *      - To trigger this behavior, just pass in a `bannerError` prop.
 *      - If the bannerError isn't at the top of the ScrollView, then also pass in its y-position
 *        within the ScrollView.
 *
 * How it works / General strategy:
 *  - My understanding is that the KeyboardAvoidingView, which wraps the ScrollView, receives
 * paddingBottom when the virtual keyboard shows. This forces its child ScrollView to decrease in
 * height. But the scroll position doesn't change automatically, so if the form is at the bottom
 * of the scroll view, now part of the form is in the hidden bottom overflow area of the ScrollView.
 * So this component adds some extra custom logic to then scroll the form up into view.
 *  - We get that KeyboardAvoidingView behavior on iOS by setting the 'behavior' prop as 'padding'.
 * However, Android works best in this ScrollView situation with the 'behavior' prop as 'undefined'.
 * Setting it has 'height' worked decently, but it was a bit janky. (Setting it as 'padding' or
 * 'position' was very janky.) Actually, setting `behavior: undefined` for Android seems to function
 * the same way as just using a normal View instead of a KeyboardAvoidingView. The Android
 * documentation below (see esp. last paragraph) seems to indicate that's just automatic behavior
 * for Android in this type of situation.
 *    - https://developer.android.com/training/keyboard-input/visibility#Respond
 */

import React, { useCallback, useEffect, useRef } from 'react';
import T from 'prop-types';
import { Keyboard, Platform } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
import { useSafeAreaInsets } from 'react-native-safe-area-context';

import { delay } from '../../../utils/globalHelpers';
import { NestedStyledScrollView } from '../Wrappers';
import { scrollFormIntoView, scrollInputBackIntoView } from './helpers';
import { KeyboardAvoidingFlexView } from './styledComponents';

const KeyboardAvoidingScrollView = ({
  bannerError,
  bannerErrorYPosition = 0,
  children,
  formRef,
  ownsCurrentKeyboard = true,
  scrollViewProps,
  scrollViewRef: scrollViewRefProp,
  ...restProps
}) => {
  const { top: insetTop } = useSafeAreaInsets();
  const fallbackScrollViewRef = useRef();
  const scrollViewRef = scrollViewRefProp || fallbackScrollViewRef;

  useEffect(() => {
    /*
      Handle scrolling to top in case of a new bannerError popping up.
      Currently this is continuing the hacky method of manually providing the bannerErrorYPosition
      to scroll to. At some point, we may want to refactor to use
      bannerErrorRef.current.measure(...) to determine that. Frequently it will just be 0 though
      (the default).
    */
    if (bannerError) {
      scrollViewRef.current.scrollTo({ animated: true, x: 0, y: bannerErrorYPosition });
    }
  }, [bannerError, bannerErrorYPosition, scrollViewRef]);

  const handleKeyboardShow = useCallback((keyboardEvent) => {
    (async () => {
      // If keyboard isn't associated with an input in this ScrollView, do nothing.
      if (!ownsCurrentKeyboard) return;

      // Scroll bottom of form into view
      if (formRef) {
        const { endCoordinates: { screenY: keyboardTopPageYOffset } } = keyboardEvent;
        await scrollFormIntoView({ formRef, keyboardTopPageYOffset, scrollViewRef });
      } else {
        scrollViewRef.current.scrollToEnd();
      }

      /**
       * - If the form is large, then autoscrolling the action button up into view can cause the
       * currently focused input to get pushed too far up and off the page. It seems more important
       * to see the currentlyFocusedInput than to see the action button if we have to choose one or
       * the other, so now we check for that edge case and scroll down a bit if necessary. (iOS
       * sometimes does this behavior automatically, but Android never seems to do so.)
       * - But we wait a split second to try to let the above scrolling finish before we measure
       * whether the currentlyFocusedInput is now out of view.
       */
      await delay(200);
      scrollInputBackIntoView({ scrollViewRef });
    })();
  }, [formRef, ownsCurrentKeyboard, scrollViewRef]);

  useFocusEffect(
    useCallback(() => {
      const eventName = Platform.OS === 'ios' ? 'keyboardWillShow' : 'keyboardDidShow';
      const keyboardSubscription = Keyboard.addListener(eventName, handleKeyboardShow);

      return () => {
        keyboardSubscription.remove();
      };
    }, [handleKeyboardShow]),
  );

  return (
    <KeyboardAvoidingFlexView
      behavior={Platform.OS === 'ios' ? 'padding' : undefined}
      keyboardVerticalOffset={insetTop}
      {...restProps}
    >
      <NestedStyledScrollView
        ref={scrollViewRef}
        keyboardShouldPersistTaps="handled"
        scrollToOverflowEnabled
        {...scrollViewProps}
      >
        {children}
      </NestedStyledScrollView>
    </KeyboardAvoidingFlexView>
  );
};

KeyboardAvoidingScrollView.propTypes = {
  bannerError: T.oneOfType([T.string, T.bool]),
  bannerErrorYPosition: T.number,
  children: T.node,
  formRef: T.shape({ current: T.object }),
  ownsCurrentKeyboard: T.bool,
  scrollViewProps: T.object,
  scrollViewRef: T.shape({ current: T.object }),
};

export default KeyboardAvoidingScrollView;
