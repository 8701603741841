import React from 'react';
import { Pressable } from 'react-native';
import T from 'prop-types';

import { ConditionalRender } from '../../../base_ui';
import {
  AttachmentTextWrapper,
  EditText,
  FileRowButtonContainer,
  IconWrapper,
  StyledP3,
  TextWrapper,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const PaperclipIcon = iconDictionary('paperClip');

const FileRowButton = ({
  disabled,
  handlePress,
  isReply,
  label,
  numberOfAttachments,
}) => (
  <Pressable disabled={disabled} onPress={() => handlePress('docUpload')}>
    <FileRowButtonContainer $isReply={isReply}>
      <IconWrapper>
        {PaperclipIcon}
      </IconWrapper>
      <TextWrapper $disabled={disabled}>
        <ConditionalRender
          Component={<StyledP3>{label}</StyledP3>}
          FallbackComponent={(
            <AttachmentTextWrapper>
              <StyledP3>
                {`${numberOfAttachments} Attachment${numberOfAttachments > 1 ? 's' : ''}`}
              </StyledP3>
              <EditText>
                Edit
              </EditText>
            </AttachmentTextWrapper>
          )}
          shouldRender={numberOfAttachments === 0}
        />
      </TextWrapper>
    </FileRowButtonContainer>
  </Pressable>
);

FileRowButton.propTypes = {
  disabled: T.bool,
  handlePress: T.func.isRequired,
  isReply: T.bool,
  label: T.string.isRequired,
  numberOfAttachments: T.number.isRequired,
};

export default FileRowButton;
