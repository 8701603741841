/* eslint-disable max-len, no-tabs */
import React from 'react';
import Svg, {
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DogSmartphone(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 251 315"
      fill="#000"
      height={moderateScale(315)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 251 315"
      width={moderateScale(251)}
      x="0px"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <Path className="st0" d="M-1264.3-181.8h15v-2l-15.6-0.3l-3.9-4l0.1,3.4l2.4,2.3C-1265.7-182.1-1265-181.8-1264.3-181.8z" display="none" fill="#BAC2CA" />
      <G>
        <Path
          className="st1"
          d="M203.8,131.9h-6c2.6,8.9,11.7,46.8-24.2,48.6l0.1,0.3c2,0.4,4.1,0.6,6.4,0.6h1c17.9,0,26-12.5,27.4-24.3
		C209.8,145.3,203.8,131.9,203.8,131.9z"
          fill="#E6EBEF"
        />
        <Polyline className="st2" fill={svgHighlight} points="31.5,147.3 19.5,197.2 34.4,197.2 	" />
        <Polygon className="st3" fill={svgHighlight} points="28.4,159.4 30.4,197.1 35.1,197.1 31.6,148 	" />
        <Polygon className="st1" fill="#E6EBEF" points="94.5,237.2 198.7,230.1 210.3,233.7 74.9,243.2 	" />
        <Polygon className="st1" fill="#E6EBEF" points="70.8,251.8 220.5,241.7 196.6,249.7 87.1,257.3 	" />
        <Path
          className="st4"
          d="M236.7,236.9L111,276.6c-2.4,0.8-5,0.7-7.4-0.1l-61.7-20.4c-0.3-0.1-0.4-0.2-0.6-0.4l-0.3,0v8.1c0,0,0,0,0,0
		v0.1l0,0c0,0.5,0.3,1,0.9,1.3l61.7,20.4c2.4,0.8,5,0.8,7.4,0.1l125.7-39.6c0.7-0.2,1.1-0.9,1.2-1.5l0,0v0c0-0.1,0-0.1,0-0.1v-8
		l-0.3,0C237.3,236.6,237,236.8,236.7,236.9z"
          fill="#D2DBE2"
        />
        <Polyline className="st2" fill={svgHighlight} points="33.2,92.7 115.2,92.7 121.7,203.3 39.7,203.3 	" />
        <Path className="st5" d="M153.5,144.2h54.1c-0.2-0.9-0.4-1.9-0.6-2.7h-52.8C153.9,142.3,153.7,143.3,153.5,144.2z" fill={svgHighlight} />
        <Path className="st5" d="M152.6,151h56c-0.1-0.9-0.1-1.8-0.3-2.7h-55.5C152.7,149.2,152.6,150.1,152.6,151z" fill={svgHighlight} />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="7.9" x2="10.1" y1="197.2" y2="197.2" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="12.9" x2="34.6" y1="197.2" y2="197.2" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="121.3" x2="130" y1="197.2" y2="197.2" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="134.5" x2="147.3" y1="197.2" y2="197.2" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="38" x2="33.2" y1="174.5" y2="92.7" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="39.7" x2="38.2" y1="203.3" y2="177.8" />
        <Polyline className="st6" fill="none" points="92.2,92.7 115.2,92.7 121.7,203.3 39.7,203.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="33.2" x2="89.4" y1="92.7" y2="92.7" />
        <Polyline className="st8" fill="#FFFFFF" points="52.7,99.3 109.5,99.3 115.1,195.5 45.7,195.5 40.1,99.3 49.7,99.3 	" />
        <Polygon className="st4" fill="#D2DBE2" points="113.3,195.5 115.1,195.5 109.5,99.3 107.7,99.3 	" />
        <Polyline className="st6" fill="none" points="52.7,99.3 109.5,99.3 115.1,195.5 45.7,195.5 40.1,99.3 49.7,99.3 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Polyline className="st6" fill="none" points="117.1,203.3 35.1,203.3 28.6,94.3 33.2,92.7 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Polyline className="st6" fill="none" points="31.5,147.3 19.5,197.2 34.4,197.2 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="110.2" x2="113.2" y1="141.8" y2="193.9" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="107.7" x2="110" y1="99.4" y2="138.7" />
        <Path
          className="st6"
          d="M154.9,165.6c-1.2-2.8-1.9-5.6-2.2-8.5c-1.3-11.8,4.7-25.2,4.7-25.2h46.4c0,0,6.1,13.4,4.7,25.2
		c-1.3,11.8-9.5,24.3-27.4,24.3h-1c-12.2,0-19.9-5.9-24-13.3"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          className="st1"
          d="M165.2,121.6c0,0-5.3-19.1-14.9-30.7c-9.6-11.6-22.4-16.2-22.4-16.2s2.3,19.8,12.9,33.2
		C151.4,121.4,160.4,117.5,165.2,121.6z"
          fill="#E6EBEF"
        />
        <Path
          className="st1"
          d="M202.5,37.6c0,0-12.2,16.4-16.6,25.6c-4.4,9.2-6.3,19.5-5.9,26.5s0.3,8.1,0.3,8.1s2.7-5.5,11.2-9.8
		c8.4-4.3,12.2-21.8,11.7-27.9S202.5,37.6,202.5,37.6z"
          fill="#E6EBEF"
        />
        <Path
          className="st1"
          d="M191.8,112.6c0,0,2,7,10.3,6c8.3-1.1,8.4-2.2,11.6,3.5c3.2,5.7,1.8,9.5,9.6,11.5c7.8,2,8.6,2.6,8.6,2.6
		s1-10-4-18.8S209.9,101,191.8,112.6z"
          fill="#E6EBEF"
        />
        <Path
          className="st6"
          d="M142.1,109.6c10,11.5,18.5,8.1,23.1,12c0,0-5.3-19.1-14.9-30.7c-9.6-11.6-22.4-16.2-22.4-16.2
		s2.2,19.1,12.3,32.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          className="st4"
          d="M128.1,24.2c0,0,0.1,9.4,0.7,16.1s0.2,12.4,9.2,19.8c9,7.5,18.8,14.3,21.4,23.5s4,16.7,10.1,24.5
		c0,0,5.7-13.5,3.7-24.8s-11.3-16-14.9-19.3c-3.6-3.3-7.7-7.5-8.3-16.1S138.6,28.6,128.1,24.2z"
          fill="#D2DBE2"
        />
        <Path
          className="st6"
          d="M151.2,71.3c3.9,3.8,6.9,7.8,8.2,12.3c2.6,9.2,4,16.7,10.1,24.5c0,0,5.7-13.5,3.7-24.8s-11.3-16-14.9-19.3
		c-3.6-3.3-7.7-7.5-8.3-16.1s-11.4-19.3-21.9-23.7c0,0,0.1,9.4,0.7,16.1s0.2,12.4,9.2,19.8c3.9,3.3,8,6.4,11.6,9.7"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          className="st6"
          d="M202,70.2c1-4.1,1.4-7.8,1.2-10c-0.5-6.1-0.7-22.6-0.7-22.6S190.4,54,185.9,63.2c-4.4,9.2-6.3,19.5-5.9,26.5
		s0.3,8.1,0.3,8.1s2.7-5.5,11.2-9.8c4.8-2.4,8-9.1,9.9-15.5"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          className="st6"
          d="M231.8,65.6c0,0-20.5,9.1-28.8,21.3c0,0-5,6.8-6.8,16.6c0,0,2.1-2,8.8-3.9c6.7-1.9,17.7-6.1,22.2-19
		S231.8,65.6,231.8,65.6z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          className="st4"
          d="M231.8,65.6c0,0-20.5,9.1-28.8,21.3c0,0-5,6.8-6.8,16.6c0,0,2.1-2,8.8-3.9c6.7-1.9,17.7-6.1,22.2-19
		S231.8,65.6,231.8,65.6z"
          fill="#D2DBE2"
        />
        <Path
          className="st6"
          d="M231.8,65.6c0,0-20.5,9.1-28.8,21.3c0,0-5,6.8-6.8,16.6c0,0,2.1-2,8.8-3.9c6.7-1.9,17.7-6.1,22.2-19
		S231.8,65.6,231.8,65.6z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path className="st6" d="M164.9,120.8c0,0,2.7,8.1,3.4,10.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st6" d="M169.4,108.5c0,0-1.5,10.2,5.8,23.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st6" d="M180.6,96.7c0,0-5.3,15.7-1.7,35" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st7" d="M140.5,90.1c0,0,19.9,19.1,23.8,30.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st7" d="M145.8,56.3c0,0,4.7,9,13.8,16.6c9,7.6,10.3,14.7,9.8,31.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st7" d="M136.3,35.1c0,0,6.4,9.4,8.6,17.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st7" d="M180.7,95.7c0,0,7-21.5,14.4-34.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path
          className="st6"
          d="M225.3,113.7c-6.2-6.9-18-11-33.4-1.1c0,0,2,7,10.3,6c8.3-1.1,8.4-2.2,11.6,3.5c3.2,5.7,1.8,9.5,9.6,11.5
		c7.8,2,8.6,2.6,8.6,2.6s1-10-4-18.8c-0.3-0.5-0.6-1.1-1-1.6"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path className="st6" d="M184.8,131.6c0,0-3.3-12.5,7-19.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st6" d="M194.3,117.2c-0.2,3.9-0.2,8.7,0.2,14.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st6" d="M196.5,103.2c0,0-0.9,1.6-1.6,6.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st7" d="M201.3,97c0,0,15.6-17.9,24.4-24.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Path className="st7" d="M193.5,111.7c0,0,22.7-7.2,32.7,14.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="226.4" x2="233.1" y1="181.5" y2="181.5" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="138.9" x2="142.3" y1="181.5" y2="181.5" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="146.3" x2="222.3" y1="181.5" y2="181.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="210.1" x2="215.6" y1="111.3" y2="110.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="219.2" x2="218.8" y1="116.4" y2="120.1" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="208.3" x2="213.1" y1="89.2" y2="89.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="214.8" x2="214.4" y1="82.4" y2="79.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="184" x2="185.3" y1="79.8" y2="82.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="188.5" x2="193.4" y1="74.8" y2="73.8" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="189.3" x2="191" y1="64.9" y2="68.8" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="137.9" x2="142" y1="44.2" y2="45.4" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="163.5" x2="168.3" y1="84.3" y2="86.6" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="157.4" x2="156.9" y1="71" y2="66.4" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="150.2" x2="155.8" y1="106.6" y2="106.9" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="148" x2="147.9" y1="97.8" y2="93.8" />
        <Path className="st9" d="M86.9,239.3l83.4-26.3c1.3-0.4,2.7-0.4,3.9,0l62.5,20.7c0.8,0.3,1.2,0.9,1.2,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
        <Line className="st9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" x1="79.1" x2="83.8" y1="241.8" y2="240.3" />
        <Path className="st9" d="M40.9,254.8c0-0.6,0.3-1.1,1-1.3l35.4-11.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
        <Path className="st6" d="M237.9,235.4c0,0.7-0.4,1.4-1.2,1.6L111,276.6c-2.4,0.8-5,0.7-7.4-0.1l-61.7-20.4c-0.6-0.2-0.9-0.8-0.9-1.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path className="st9" d="M220.2,251.1l16.5-5.2c0.7-0.2,1.1-0.9,1.2-1.5l0,0v0c0-0.1,0-0.1,0-0.1v-9.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
        <Path className="st9" d="M40.9,254.5v9.3c0,0,0,0,0,0v0.1l0,0c0,0.5,0.3,1,0.9,1.3l61.7,20.4c2.4,0.8,5,0.8,7.4,0.1l105.4-33.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="161.2" x2="224.5" y1="217.3" y2="238.3" />
        <Path
          className="st7"
          d="M204.5,227.9c-0.1,0-0.1,0-0.2,0l-16-5.3c-0.3-0.1-0.4-0.4-0.3-0.7c0.1-0.3,0.4-0.4,0.7-0.3l16,5.3
		c0.3,0.1,0.4,0.4,0.3,0.7C204.9,227.7,204.7,227.9,204.5,227.9z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="64.3" x2="127.5" y1="249.9" y2="270.9" />
        <Path className="st7" d="M81.3,264.4c-2.2-0.2-3.8-0.9-3.8-1.8c0-1,2.2-1.8,4.8-1.8c2.7,0,4.8,0.8,4.8,1.8c0,0.9-1.7,1.6-4,1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polygon className="st7" fill="none" points="76.8,272.7 64,268.6 64,267.3 76.8,271.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polygon className="st7" fill="none" points="95.7,278.8 93.5,278 93.5,276.7 95.7,277.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Polygon className="st7" fill="none" points="100.3,280.2 98,279.5 98,278.1 100.3,278.9 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="107.6" x2="107.6" y1="278.6" y2="284.7" />
        <G>
          <Path
            className="st8"
            d="M52.7,154.3c-2.2,9.2,4.7,12.4,4.7,12.4l0,0.3c1.2-6.8,5.4-12.6,11.7-15.6l0.4-0.2c-0.1-0.3-0.3-0.7-0.4-1
			c-0.3-1.2-0.5-2.4-0.6-3.6l-0.7,0.8c0,0-4.5,0.7-6-2.8c-1.5-3.5-0.3-8,5.7-13.7c6-5.7,10.1-3.8,10.1-3.8s1.5-1.3,4-1.3
			c2.5,0,3.9,1.3,3.9,1.3l0.2,0l-0.1-0.1c0,0,3.8-1.8,10.7,3.8c6.9,5.7,8.8,10.2,7.9,13.7s-5.5,2.8-5.5,2.8l-0.9-1.4
			c0.2,1.4,0.2,2.7,0.1,4.2c-0.2,2-0.8,3.5-1.7,4.8l0,0.2c0,0,2,2.1,2.3,5.3c0.2,2.1,1,12,1.5,18.5c0.2,3.2-2.2,5.8-5.4,5.8h-0.9
			c-3.2,0-6-2.6-6.3-5.8l-0.5-6.9l0.2-0.4c-1.2,0.3-2.6,0.4-4.2,0.1l-0.1,0.4l0.2,0.1l0.5,6.9c0.3,3.2-2.1,5.8-5.4,5.8h-0.7
			c-3.2,0-6-2.6-6.3-5.8l0-0.1h-3.1c0.1,0.3,0.1,0.6,0.2,0.9c0.2,2.4-1.6,4.4-4.1,4.4h-2.8c-1.9,0-3.5-1.5-3.7-3.4l-0.7-9.5
			l-0.1,1.6c-4.9-2.8-6.6-7.1-6.6-7.1C47.3,159,52.7,154.3,52.7,154.3z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              className="st6"
              d="M96,155.3c0,0,2,2.1,2.3,5.3c0.2,2.1,1,12,1.5,18.5c0.2,3.2-2.2,5.8-5.4,5.8h-0.9c-3.2,0-6-2.6-6.3-5.8
				l-0.5-6.9"
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <Path className="st6" d="M90.9,170c0,0-5.9,4.7-12.5,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
            <Path className="st6" d="M82.9,172.4l0.5,6.9c0.3,3.2-2.1,5.8-5.4,5.8h-0.7c-3.2,0-6-2.6-6.3-5.8l-1.2-14.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
            <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="70.9" x2="68.1" y1="179.3" y2="179.3" />
            <Path
              className="st6"
              d="M68.9,151.4L68.9,151.4c-7.6,3.7-12.3,11.4-12,20.1l0.7,9.5c0.1,1.9,1.8,3.4,3.7,3.4H64c2.4,0,4.3-2,4.1-4.4
				l0,0c-0.2-2.4-2.3-4.4-4.8-4.4h-0.6"
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <Path className="st6" d="M57.2,166.7c0,0-6.9-3.2-4.7-12.4c0,0-5.4,4.7-2.4,11.7c0,0,1.7,4.4,6.6,7.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
            <Path className="st7" d="M88.9,151.3c0,0-5.1,6-12.2,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
            <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="83.2" x2="82.8" y1="153.7" y2="148.5" />
            <Ellipse cx="76.7" cy="139.7" rx="1" ry="1.1" transform="matrix(0.693 -0.7209 0.7209 0.693 -77.1644 98.1522)" />
            <Ellipse cx="87" cy="139.7" rx="1" ry="1.1" transform="matrix(0.693 -0.7209 0.7209 0.693 -73.9874 105.613)" />

            <Ellipse cx="82.4" cy="144.7" rx="2.1" ry="4.5" transform="matrix(2.167409e-02 -0.9998 0.9998 2.167409e-02 -64.0065 223.9579)" />
            <Path d="M86.1,144.5l0.8,0.2c0,0-0.1,3.6-4.1,4.2l-0.3,0.1l-0.7-3.6L86.1,144.5z" />
            <Path d="M78.7,144.5l-0.8,0.2c0,0,0.7,3.6,4.7,4.2l0.3,0.1l0.2-3.6L78.7,144.5z" />
            <Path className="st6" d="M85.5,127c0,0,3.8-1.8,10.7,3.8c6.9,5.7,8.8,10.2,7.9,13.7c-1,3.5-5.5,2.8-5.5,2.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
            <Path className="st6" d="M90.3,131.3c0,0,8.5,8.7,7.4,18.9c-1.1,10.2-13.3,10.2-13.3,10.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
            <Path
              className="st6"
              d="M85.3,127.1c0,0-1.5-1.3-3.9-1.3s-4,1.3-4,1.3s-4.1-1.8-10.1,3.8c-6,5.7-7.2,10.2-5.7,13.7
				c1.5,3.5,6,2.8,6,2.8"
              fill="none"
              stroke="#000000"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeMiterlimit="10"
              strokeWidth="0.75"
            />
            <Path className="st6" d="M73.3,131.3c0,0-7.1,8.7-4.4,18.9c2.7,10.2,15,10.2,15,10.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          </G>
        </G>
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="52.2" x2="70.7" y1="184.4" y2="184.7" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="84.9" x2="103.3" y1="184.9" y2="185.2" />
        <Line className="st6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="105.4" x2="108.4" y1="185.2" y2="185.3" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="182.1" x2="193.9" y1="237.9" y2="237.1" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="108.3" x2="145" y1="252.6" y2="250.3" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="120.3" x2="178.8" y1="242.1" y2="238.2" />
        <G>
          <Path
            className="st10"
            d="M40.9,111.3c0.8-0.3,1.7-0.5,2.7-0.5c2.2,0,4.2,1,5.6,2.5c1.5-1.9,3.9-3.1,6.5-3.1c4.6,0,8.4,3.8,8.4,8.4
			c0,0.3,0,0.7-0.1,1c0.9-0.5,1.9-0.7,3-0.7c3.7,0,6.7,3,6.7,6.7"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.55"
          />
          <Path className="st10" d="M58.9,120.3c0.9-0.6,2-0.9,3.1-0.9c2.3,0,4.2,1.3,5.2,3.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
      </G>
    </Svg>
  );
}
