import styled from 'styled-components/native';

import {
  BaseInternalLink,
  H2,
  P2,
  P3,
  SecondaryCard,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const StyledInternalLink = styled(BaseInternalLink)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
`;

export const DisasterContainer = styled.View`
  padding: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const IllustrationWrapper = styled.View`
  margin: 0 auto;
`;

export const StyledCard = styled(SecondaryCard)`
  height: ${moderateScale(20)}px;
`;

export const StyledH2 = styled(H2)`
  margin: ${moderateScale(10)}px auto ${moderateScale(8)}px;
  padding: 0 ${moderateScale(20)}px;
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin-bottom: ${moderateScale(30)}px;
  padding: 0 ${moderateScale(20)}px;
  text-align: center;
`;

export const StyledP3 = styled(P3)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(16)}px;
  text-align: center;
`;

export const StyledSubHeader = styled(H2)`
  margin: 0 0 ${moderateScale(30)}px;
`;
