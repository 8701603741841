import React, { useRef } from 'react';
import { Keyboard, View } from 'react-native';
import T from 'prop-types';

import { parseDocUploadAttachments } from '@dmi/shared/utils/globalHelpers';
import { validate } from '@dmi/shared/utils/validate';

import RowButton from './ClaimRow';
import { SecondaryHeader } from '../../ScreenHeaders';
import FileRowButton from '../Shared/FileRowButton';
import {
  ButtonWrapper,
  FlexView,
  MessageCreateContainer,
  StyledBodyField,
  StyledLargeProgressButton,
  StyledSubjectField,
} from './styledComponents';

const MessageCreateComponent = ({
  dispatchChangeFormFieldValue,
  dispatchChangeStep,
  dispatchPostMessage,
  dropdownClaimNumberItems,
  dropdownDefaultClaimNumber,
  formAttachments,
  formMessage: {
    body,
    subject,
  },
  formUploadError,
  handleResetState,
  navigation,
  requestStatusPostMessage,
}) => {
  const bodyRef = useRef();

  const handleFileRowButton = () => {
    const nextStep = formAttachments.length ? 4 : 3;
    dispatchChangeStep(nextStep);
  };

  const handleNavigateBack = () => {
    navigation.goBack();
    handleResetState();
  };

  const isBodyValid = validate({
    maxLength: 3000,
    type: 'message',
    value: body,
  });
  const isSubjectValid = validate({
    maxLength: 100,
    type: 'message',
    value: subject,
  });
  const hasAttachmentError = formAttachments.some(({ bannerError, removed }) =>
    !!bannerError && !removed) || !!formUploadError;
  const isSendDisabled = hasAttachmentError || isBodyValid || isSubjectValid;
  const hasMultipleClaims = dropdownClaimNumberItems.length > 1;
  const { display: selectedClaimNumberDisplay } =
    dropdownClaimNumberItems.find(({ value }) => value === dropdownDefaultClaimNumber);

  return (
    <MessageCreateContainer>
      <View>
        <SecondaryHeader
          handleBack={handleNavigateBack}
          title="New Message"
        />
        <RowButton
          handlePress={() => dispatchChangeStep(2)}
          hasMultipleClaims={hasMultipleClaims}
          label="Claim #:"
          selected={selectedClaimNumberDisplay}
        />
        <View>
          <StyledSubjectField
            maxLength={100}
            onChangeText={
              (value) =>
                dispatchChangeFormFieldValue({ field: 'subject', form: 'message', value })
            }
            onSubmitEditing={() => bodyRef.current.focus()}
            placeholder="Subject line"
            returnKeyType="next"
            value={subject}
          />
        </View>
        <StyledBodyField
          ref={bodyRef}
          disableFocus
          inputContainerStyle={{
            borderBottomWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
          }}
          keyboardType="default"
          maxLength={3000}
          numberOfLines={12}
          onBlur={Keyboard.dismiss}
          onChangeText={
            (value) =>
              dispatchChangeFormFieldValue({ field: 'body', form: 'message', value })
          }
          placeholder="Type your message here."
          textAlignVertical="top"
          value={body}
        />
        <FileRowButton
          handlePress={handleFileRowButton}
          label="Add Attachment"
          numberOfAttachments={formAttachments.length}
        />
      </View>
      <FlexView />
      <ButtonWrapper>
        <StyledLargeProgressButton
          disabled={isSendDisabled}
          label="Send"
          loading={requestStatusPostMessage === 'loading'}
          onPress={() => dispatchPostMessage({
            attachments: parseDocUploadAttachments(formAttachments),
            body,
            claimNumber: dropdownDefaultClaimNumber,
            subject,
          })}
          status={requestStatusPostMessage}
        />
      </ButtonWrapper>
    </MessageCreateContainer>
  );
};

MessageCreateComponent.propTypes = {
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchPostMessage: T.func.isRequired,
  dropdownClaimNumberItems: T.array.isRequired,
  dropdownDefaultClaimNumber: T.string.isRequired,
  formAttachments: T.array.isRequired,
  formMessage: T.shape({
    body: T.string.isRequired,
    subject: T.string.isRequired,
  }).isRequired,
  formUploadError: T.string.isRequired,
  handleResetState: T.func.isRequired,
  navigation: T.object.isRequired,
  requestStatusPostMessage: T.string.isRequired,
};

export default MessageCreateComponent;
