import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';
import { injectIntl } from 'react-intl';

import { fetchDocumentsHome } from '@dmi/shared/redux/Documents/actions';
import makeSelectDocuments, {
  selectDocumentsNotifications,
  selectError,
  selectHomeLinkProps,
} from '@dmi/shared/redux/Documents/selectors';
import reducer from '@dmi/shared/redux/Documents/reducer';
import saga from '@dmi/shared/redux/Documents/saga';
import makeSelectMain from '@dmi/shared/redux/Main/selectors';
import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { selectNestedScreen } from '@dmi/shared/redux/Mobile/selectors';
import { fetchPayoffDates } from '@dmi/shared/redux/Payoff/actions';
import makeSelectPayoff from '@dmi/shared/redux/Payoff/selectors';

import { StyledScrollView } from '../../components/base_ui';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../utils/globalHelpers';
import AsyncRender from '../../components/AsyncRender';
import Home from '../../components/Documents/Home';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import LoadingDog from '../../components/LoadingDog';

const DocumentsScreen = ({
  dispatchFetchDocumentsHome,
  dispatchFetchPayoffDates,
  dispatchSetNestedScreen,
  homeLinkProps,
  homeLoadingStep,
  isLoanMancodeY,
  navigation,
  nestedScreen,
  notifications,
  selectedLoan,
  serviceError,
  shouldDocumentsHomeRefetch,
}) => {
  useEffect(() => {
    dispatchFetchDocumentsHome();
  }, [dispatchFetchDocumentsHome, selectedLoan, shouldDocumentsHomeRefetch]);

  useEffect(() => {
    if (nestedScreen) {
      navigation.navigate(nestedScreen);
    }
  }, [navigation, nestedScreen]);

  useFocusEffect(
    useCallback(() => () => {
      dispatchSetNestedScreen({ mainScreen: 'documents', nestedScreen: '' });
    }, [dispatchSetNestedScreen]),
  );

  useEffect(() => {
    dispatchFetchPayoffDates(selectedLoan);
  }, [
    dispatchFetchPayoffDates,
    selectedLoan,
  ]);

  return (
    <StyledScrollView>
      <ScreenReaderNotifications notifications={notifications} />
      <AsyncRender
        Component={Home}
        error={serviceError}
        errorComponentType="cardLevel"
        loading={(!homeLinkProps && homeLoadingStep === 0) || homeLoadingStep === 1}
        LoadingComponent={LoadingDog}
        propsToPassDown={{
          homeLinkProps,
          homeLoadingStep,
          isLoanMancodeY,
          navigation,
        }}
      />
    </StyledScrollView>
  );
};

DocumentsScreen.propTypes = {
  dispatchFetchDocumentsHome: T.func.isRequired,
  dispatchFetchPayoffDates: T.func.isRequired,
  dispatchSetNestedScreen: T.func.isRequired,
  homeLinkProps: T.oneOfType([T.bool, T.object]).isRequired,
  homeLoadingStep: T.number.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  navigation: T.object.isRequired,
  nestedScreen: T.string,
  notifications: T.array.isRequired,
  selectedLoan: T.string.isRequired,
  serviceError: T.oneOfType([T.bool, T.object, T.string]).isRequired,
  shouldDocumentsHomeRefetch: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Documents
   */
  homeLinkProps: selectHomeLinkProps(),
  homeLoadingStep: makeSelectDocuments('homeLoadingStep'),
  notifications: selectDocumentsNotifications(),
  serviceError: selectError('serviceError'),
  shouldDocumentsHomeRefetch: makeSelectDocuments('shouldDocumentsHomeRefetch'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  selectedLoan: makeSelectMain('selectedLoan'),
  /**
   * Store: Mobile
   */
  // eslint-disable-next-line sort-keys
  nestedScreen: selectNestedScreen('documents'),
  /**
   * Store: Payoff
   */
  // eslint-disable-next-line sort-keys
  isLoanMancodeY: makeSelectPayoff('isLoanMancodeY'),
});

/* eslint-disable object-curly-newline */
const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Documents
   */
  dispatchFetchDocumentsHome: () => dispatch(fetchDocumentsHome()),
  /**
   * Store: Mobile
   */
  dispatchSetNestedScreen: (payload) => dispatch(setNestedScreen(payload)),
  /**
   * Store: Payoff
   */
  // eslint-disable-next-line sort-keys
  dispatchFetchPayoffDates: (loanNumber) => dispatch(fetchPayoffDates(loanNumber)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'documents', reducer });
const withSaga = injectSaga({
  key: 'documents',
  saga: saga({ baseUrl: getBaseApplicationServerUrl() }),
});

export default compose(
  injectIntl,
  withConnect,
  withReducer,
  withSaga,
)(DocumentsScreen);
