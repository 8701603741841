import React, { Fragment, useCallback, useState } from 'react';
import T from 'prop-types';

import { DISCLOSURE_TEXT_ARRAYS } from '@dmi/shared/redux/Payments/constants';
import { interpolate } from '@dmi/shared/utils/globalHelpers';
import { useFocusEffect } from '@react-navigation/native';

import { CheckboxWithText, ConditionalRender } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import { PaymentModal, PaymentSummary } from '../sharedComponents';
import {
  CheckboxWithTextContainer,
  ContentContainer,
  StyledP4,
} from './styledComponents';

const PaymentDisclosure = ({
  clientName,
  modalProps,
  navigation,
  summaryProps,
  type,
}) => {
  const [checked, setChecked] = useState(summaryProps.loading);

  useFocusEffect(
    useCallback(() => () => {
      setChecked(false);
    }, []),
  );

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={`${type === 'autopay' ? 'Automatic Payment ' : ''}Terms and Conditions`}
      />
      <ContentContainer>
        {DISCLOSURE_TEXT_ARRAYS[type].map((text, index) => {
          const finalText = index === 3 ?
            interpolate(text, { clientName }) :
            text;
          return <StyledP4 key={finalText}>{finalText}</StyledP4>;
        })}
        <CheckboxWithTextContainer>
          <CheckboxWithText
            checked={checked}
            disabled={summaryProps.loading}
            onPress={() => setChecked(!checked)}
            title="I agree to the terms and conditions as outlined above."
          />
        </CheckboxWithTextContainer>
      </ContentContainer>
      <PaymentSummary disabled={!checked} {...summaryProps} />
      <ConditionalRender
        Component={PaymentModal}
        propsToPassDown={modalProps}
        shouldRender={!!modalProps && !!modalProps.isOpen}
      />
    </Fragment>
  );
};

PaymentDisclosure.propTypes = {
  clientName: T.string.isRequired,
  modalProps: T.object,
  navigation: T.object.isRequired,
  summaryProps: T.object.isRequired,
  type: T.oneOf(['otp', 'autopay']).isRequired,
};

export default PaymentDisclosure;
