import React from 'react';
import T from 'prop-types';

import ConditionalRender from '../../ConditionalRender';
import { P3 } from '../../Paragraphs';
import {
  BannerContainer,
  BoldText,
  IllustrationWrapper,
  MessageContainer,
  StyledTextButton,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';

const Illustration = iconDictionary('thumbtack', { width: `${moderateScale(36)}px` });

const TransferStatusBanner = ({
  handleButtonPress,
  hasError,
  hasRespaLetter,
  text,
  title,
}) => (
  <BannerContainer $hasError={hasError}>
    <IllustrationWrapper>
      {Illustration}
    </IllustrationWrapper>
    <MessageContainer>
      <BoldText>{title}</BoldText>
      <P3>{text}</P3>
      <ConditionalRender
        Component={StyledTextButton}
        propsToPassDown={{
          label: 'View Details',
          onPress: handleButtonPress,
        }}
        shouldRender={hasRespaLetter}
      />
    </MessageContainer>
  </BannerContainer>
);

TransferStatusBanner.propTypes = {
  handleButtonPress: T.func,
  hasError: T.bool.isRequired,
  hasRespaLetter: T.bool.isRequired,
  text: T.string.isRequired,
  title: T.string.isRequired,
};

export default TransferStatusBanner;
