import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { P3 } from '../../../base_ui';

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const IconContainer = styled.View`
  padding-right: ${moderateScale(10)}px;
`;

/* eslint-disable max-len */
export const StatusBlockContainer = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border: ${({ $isAutopayEnabledOrPending, theme: { colors: { primaryGray } } }) =>
    $isAutopayEnabledOrPending ? `1px solid ${primaryGray}` : 'none'};
  border-radius: ${moderateScale(4)}px;
  flex-direction: row;
  padding: ${moderateScale(8)}px;
`;

export const StatusBlockContent = styled.View`
  flex-direction: column;
  flex-shrink: 1;
`;

export const StyledText = styled(P3)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
`;
