/* eslint-disable max-len */
/*
 * Mailing Address Upload Screen
 */
import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { CHANGE_MAILING_ADDRESS_FORM_DOCUMENT_URL, LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import { chooseDocument } from '@dmi/shared/redux/Settings/Loans/actions';
import { selectRequestStatus } from '@dmi/shared/redux/Settings/Loans/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { ChangeMailingAddressUploadComponent } from '../../../components/Settings/ChangeMailingAddress';
import { StyledView } from './styledComponents';

const MailingAddressUploadScreen = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  navigation,
}) => {
  const { mobileHeader } = LOANS_CONTENT_DICTIONARY.changeMailingAddress.uploadView;

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={mobileHeader}
      />
      <StyledView>
        <ChangeMailingAddressUploadComponent
          chooseDocumentRequestStatus={chooseDocumentRequestStatus}
          dispatchChooseDocument={dispatchChooseDocument}
          documentUrl={CHANGE_MAILING_ADDRESS_FORM_DOCUMENT_URL}
          reviewScreenName="MailingAddressUploadReviewDocument"
        />
      </StyledView>
    </Fragment>
  );
};

MailingAddressUploadScreen.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Loans
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MailingAddressUploadScreen);
