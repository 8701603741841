import { moderateScale } from '../../../../utils/scaleHelpers';

/*
  Input Constants to manually set the height of the TextField/Input. These get
  utilized in StyledTextField's inputStyle attr prop.
*/
export const CONTAINER_WIDTH = moderateScale(327);
export const INPUT_LINE_HEIGHT = moderateScale(18);
export const PADDING_RIGHT = moderateScale(7);
export const PADDING_SIDE = moderateScale(8);
export const BASE_HEIGHT = 2 * PADDING_SIDE + INPUT_LINE_HEIGHT;
