/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

export default function FormsTownscape(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="#000000" height={moderateScale(150)} viewBox="0 0 327 150" width={moderateScale(327)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G style={{ isolation: 'isolate' }}>
        <G data-name="Layer 1" id="Layer_1">
          <Rect fill="#e5eaee" height="21.78" width="2.8" x="199.48" y="96.58" />
          <Path d="M58.63,69s11-.55,18-9.12c8.65-10.62,12.58-14.27,19-14,6.92.33,14.66,9.39,14.66,9.39l3.12,19.22L79.29,79.77Z" fill="#e5eaee" />
          <Path d="M179,45.72s20.35-14,52.78,12.43,48.58,10.12,53,7.34c6.39-4,10.77,1.31,17.61,10.86s2.89,18.56,2.89,18.56l-114-14.42L189.07,56Z" fill="#e5eaee" />
          <Path d="M205.88,36.25l-6.94,15.24a.43.43,0,0,0,.38.62l1,0a.43.43,0,0,1,.39.59l-7.12,18.87a.43.43,0,0,0,.41.59h1.13a.44.44,0,0,1,.42.56l-2.81,10.41A1.42,1.42,0,0,0,194.26,85h0a.44.44,0,0,1,.49.5l-.41,2.73a2.14,2.14,0,0,0,2.12,2.45h18.62a2,2,0,0,0,2-2.31h0a.45.45,0,0,1,.49-.5h0A2.25,2.25,0,0,0,220,85l-2.47-9.26a.44.44,0,0,1,.56-.53l1.22.37a.44.44,0,0,0,.55-.55l-5.18-17.1a.44.44,0,0,1,.53-.55l.84.22a.44.44,0,0,0,.51-.6l-5.12-12.32a.44.44,0,0,1,.47-.6h0a.45.45,0,0,0,.45-.64l-3.8-7.27A1.48,1.48,0,0,0,205.88,36.25Z" fill="#e5eaee" />
          <Path d="M215.08,90.88H196.46a2.37,2.37,0,0,1-2.35-2.72l.4-2.74a.18.18,0,0,0,0-.16.19.19,0,0,0-.17-.06,1.69,1.69,0,0,1-1.46-.57,1.64,1.64,0,0,1-.34-1.52L195.3,72.7a.17.17,0,0,0,0-.17.21.21,0,0,0-.16-.08H194a.68.68,0,0,1-.64-.92l7.13-18.86a.22.22,0,0,0,0-.18.19.19,0,0,0-.16-.09l-1,0a.68.68,0,0,1-.58-1l6.94-15.24a1.73,1.73,0,0,1,1.52-1,1.68,1.68,0,0,1,1.57.92l3.8,7.27a.67.67,0,0,1-.05.71.65.65,0,0,1-.64.28.21.21,0,0,0-.22.28l5.12,12.32a.68.68,0,0,1-.11.71.66.66,0,0,1-.69.21l-.83-.22a.19.19,0,0,0-.19.06.18.18,0,0,0-.05.2L220.07,75a.67.67,0,0,1-.17.68.66.66,0,0,1-.68.17L218,75.45a.2.2,0,0,0-.2,0,.22.22,0,0,0-.06.2L220.21,85a2.49,2.49,0,0,1-2.68,3.12.19.19,0,0,0-.17.06.2.2,0,0,0-.05.17,2.23,2.23,0,0,1-.52,1.8A2.26,2.26,0,0,1,215.08,90.88Zm-20.77-6.16a.68.68,0,0,1,.49.2.64.64,0,0,1,.18.57l-.4,2.74a1.9,1.9,0,0,0,1.88,2.17h18.62a1.76,1.76,0,0,0,1.34-.61,1.79,1.79,0,0,0,.42-1.42.66.66,0,0,1,.18-.58.69.69,0,0,1,.56-.2,2,2,0,0,0,1.75-.69,2,2,0,0,0,.42-1.82l-2.47-9.27a.68.68,0,0,1,.19-.66.64.64,0,0,1,.67-.16l1.22.37a.18.18,0,0,0,.2,0,.2.2,0,0,0,.05-.2L214.43,58a.68.68,0,0,1,.82-.85l.84.22a.19.19,0,0,0,.2-.07.19.19,0,0,0,0-.2L211.2,44.78a.67.67,0,0,1,.09-.67.66.66,0,0,1,.63-.26.2.2,0,0,0,.19-.09.19.19,0,0,0,0-.2l-3.79-7.27a1.24,1.24,0,0,0-2.23.06h0l-6.95,15.24a.21.21,0,0,0,0,.19.19.19,0,0,0,.16.09l1,.05a.67.67,0,0,1,.6.92L193.79,71.7A.19.19,0,0,0,194,72h1.13a.68.68,0,0,1,.54.27.69.69,0,0,1,.12.59L193,83.24a1.18,1.18,0,0,0,.25,1.08,1.16,1.16,0,0,0,1,.4Zm11.57-48.47h0Z" />
          <Path d="M206.84,97.32a.32.32,0,0,1-.32-.32V53.82a.32.32,0,0,1,.32-.32.32.32,0,0,1,.32.32V97A.32.32,0,0,1,206.84,97.32Z" />
          <Path d="M206.68,83.13a.27.27,0,0,1-.17-.06l-5.67-4.88a.25.25,0,0,1,0-.37.26.26,0,0,1,.37,0l5.68,4.87a.28.28,0,0,1,0,.38A.29.29,0,0,1,206.68,83.13Z" />
          <Path d="M206.75,72.28a.23.23,0,0,1-.2-.1.25.25,0,0,1,0-.37l5.67-4.88a.27.27,0,0,1,.37,0,.27.27,0,0,1,0,.38l-5.68,4.87A.23.23,0,0,1,206.75,72.28Z" />
          <Path d="M206.82,60.47a.27.27,0,0,1-.22-.11.26.26,0,0,1,.06-.37l2.77-2a.27.27,0,1,1,.31.43l-2.77,2A.3.3,0,0,1,206.82,60.47Z" />
          <Polygon fill="#ffffff" points="220.53 69.32 214.83 76.83 214.83 118.69 284.43 118.69 288.27 89.02 257.18 78.42 233.74 51.79 220.53 69.32" />
          <Path d="M310.27,56l6.34,13.91a.39.39,0,0,1-.35.56l-.9,0A.4.4,0,0,0,315,71l6.5,17.22a.4.4,0,0,1-.38.54h-1a.41.41,0,0,0-.39.51l2.57,9.5a1.29,1.29,0,0,1-1.4,1.62h0a.4.4,0,0,0-.45.46l.37,2.49a2,2,0,0,1-1.93,2.24h-17a1.85,1.85,0,0,1-1.83-2.11h0a.4.4,0,0,0-.44-.46h0a2.05,2.05,0,0,1-2.21-2.56L299.65,92a.4.4,0,0,0-.5-.49l-1.12.35a.41.41,0,0,1-.5-.5l4.73-15.61a.41.41,0,0,0-.49-.51l-.76.2a.4.4,0,0,1-.47-.54l4.67-11.25a.4.4,0,0,0-.42-.55h0a.4.4,0,0,1-.41-.58l3.46-6.64A1.35,1.35,0,0,1,310.27,56Z" fill="#eef2f5" />
          <Path d="M318.87,105.84h-17a2.08,2.08,0,0,1-1.58-.72,2.06,2.06,0,0,1-.48-1.66.14.14,0,0,0-.05-.14.18.18,0,0,0-.13,0,2.28,2.28,0,0,1-2-.78,2.33,2.33,0,0,1-.48-2.09L299.42,92a.16.16,0,0,0,0-.16.17.17,0,0,0-.16,0l-1.12.34a.65.65,0,0,1-.64-.15.64.64,0,0,1-.16-.64L302,75.69a.16.16,0,0,0-.2-.2l-.76.19a.62.62,0,0,1-.65-.2.61.61,0,0,1-.1-.66L305,63.57a.15.15,0,0,0,0-.16.18.18,0,0,0-.15-.06.64.64,0,0,1-.61-.27.62.62,0,0,1,0-.67l3.46-6.63a1.58,1.58,0,0,1,1.41-.85h0a1.57,1.57,0,0,1,1.41.93l6.34,13.91a.67.67,0,0,1,0,.6.63.63,0,0,1-.52.3l-.9,0a.15.15,0,0,0-.12.07.16.16,0,0,0,0,.15l6.5,17.21a.62.62,0,0,1-.07.59.63.63,0,0,1-.53.28h-1a.16.16,0,0,0-.13.06.2.2,0,0,0,0,.14l2.57,9.5a1.54,1.54,0,0,1-1.66,1.93.16.16,0,0,0-.14,0,.17.17,0,0,0,0,.14l.37,2.49a2.19,2.19,0,0,1-2.17,2.51Zm-19.21-3a.64.64,0,0,1,.63.74,1.59,1.59,0,0,0,1.59,1.83h17a1.74,1.74,0,0,0,1.3-.59,1.71,1.71,0,0,0,.39-1.37l-.37-2.49a.63.63,0,0,1,.18-.54.59.59,0,0,1,.53-.19,1.06,1.06,0,0,0,1.15-1.33l-2.57-9.5a.66.66,0,0,1,.11-.56.63.63,0,0,1,.51-.25h1a.18.18,0,0,0,.14-.07.17.17,0,0,0,0-.15l-6.5-17.22a.61.61,0,0,1,.07-.57.6.6,0,0,1,.5-.29l.9,0a.14.14,0,0,0,.13-.07.17.17,0,0,0,0-.15l-6.34-13.91a1.07,1.07,0,0,0-1-.65,1.1,1.1,0,0,0-1,.59l-3.47,6.64a.16.16,0,0,0,0,.16.16.16,0,0,0,.16.07.64.64,0,0,1,.67.88L300.76,75a.16.16,0,0,0,0,.17.16.16,0,0,0,.16,0l.76-.2a.65.65,0,0,1,.63.18.66.66,0,0,1,.15.63l-4.73,15.6a.16.16,0,0,0,0,.17.16.16,0,0,0,.16,0l1.12-.35a.64.64,0,0,1,.63.15.65.65,0,0,1,.18.63l-2.26,8.46a1.84,1.84,0,0,0,.38,1.65,1.78,1.78,0,0,0,1.58.61Z" />
          <Path d="M309.39,115.08a.32.32,0,0,1-.32-.32V74a.32.32,0,1,1,.64,0v40.76A.32.32,0,0,1,309.39,115.08Z" />
          <Path d="M309.54,98.77a.25.25,0,0,1-.2-.09.27.27,0,0,1,0-.38l5.18-4.45a.27.27,0,1,1,.35.41l-5.18,4.45A.27.27,0,0,1,309.54,98.77Z" />
          <Path d="M309.54,84.7a.27.27,0,0,1-.18-.47L312.93,81a.27.27,0,0,1,.36.4l-3.57,3.28A.31.31,0,0,1,309.54,84.7Z" />
          <Path d="M309.42,78.09a.3.3,0,0,1-.16,0l-2.53-1.83a.27.27,0,0,1-.06-.37.27.27,0,0,1,.37-.06l2.53,1.82a.27.27,0,0,1,.06.38A.26.26,0,0,1,309.42,78.09Z" />
          <Path d="M7.16,118.79a2.35,2.35,0,0,1,0-.26,3.58,3.58,0,0,1,3.58-3.59H11a6.79,6.79,0,0,1,5.86-3.36l.48,0a1.75,1.75,0,0,0,1.63-.85,6.77,6.77,0,0,1,5.86-3.38v11.38" fill="#ffffff" />
          <Path d="M7.16,118.56a.23.23,0,0,1-.23-.22,2.44,2.44,0,0,1,0-.27,3.72,3.72,0,0,1,3.75-3.67h.1a7,7,0,0,1,5.88-3.21H17a1.8,1.8,0,0,0,1.61-.82,6.91,6.91,0,0,1,5.89-3.23.23.23,0,0,1,.24.23.24.24,0,0,1-.24.24,6.41,6.41,0,0,0-5.49,3,2.26,2.26,0,0,1-2,1h-.32a6.5,6.5,0,0,0-5.56,3.1.24.24,0,0,1-.21.12h-.21a3.26,3.26,0,0,0-3.29,3.2c0,.08,0,.16,0,.24a.23.23,0,0,1-.21.25Z" />
          <Path d="M12.54,115.77l-.08,0a.15.15,0,0,1-.06-.22,4.93,4.93,0,0,1,.61-.88.16.16,0,0,1,.22,0,.16.16,0,0,1,0,.23,4.62,4.62,0,0,0-.57.82A.16.16,0,0,1,12.54,115.77Z" />
          <Path d="M13.9,114.19a.16.16,0,0,1-.12-.06.16.16,0,0,1,0-.23,5.63,5.63,0,0,1,3-1.08.16.16,0,0,1,.16.15.15.15,0,0,1-.15.17,5.4,5.4,0,0,0-2.78,1A.17.17,0,0,1,13.9,114.19Z" />
          <Polygon fill="#ffffff" points="72.63 118.33 72.63 85.11 47.7 66.05 24.78 84.08 24.78 118.39 72.63 118.33" />
          <Rect fill="#e5eaee" height="6.8" width="47.35" x="24.49" y="111.53" />
          <Polygon fill="#ffffff" points="26.06 84.49 26.06 111.46 70.14 111.46 70.14 85.14 47.34 67.7 26.06 84.49" />
          <Rect fill="#e5eaee" height="32.77" width="14.53" x="72.07" y="85.48" />
          <Path d="M79.19,106.65s-.92,2.82.93,3.33a11.29,11.29,0,0,0,1.75.33c.63,0,4.63,0,4.63,0v-4.69Z" fill="#b9c1c9" />
          <Path d="M77,101.57s-.93,2.82.93,3.33a11.29,11.29,0,0,0,1.75.33c.62,0,4.62,0,4.62,0v-4.68Z" fill="#b9c1c9" />
          <Path d="M74.5,90H86.81V88.88L74.2,88.72l-2-1.87,0,1.51,1.3,1.21A1.52,1.52,0,0,0,74.5,90Z" fill="#b9c1c9" />
          <Path d="M75.76,86.89H90.4a.94.94,0,0,1,.94.94v0a.94.94,0,0,1-.94.94H75.76a0,0,0,0,1,0,0V86.89A0,0,0,0,1,75.76,86.89Z" fill="#e5eaee" />
          <Path d="M90.33,89H74.78a.24.24,0,0,1,0-.48H90.33a.58.58,0,0,0,.59-.59v-.16a.59.59,0,0,0-.59-.59h-.65a.24.24,0,1,1,0-.47h.65a1.06,1.06,0,0,1,1.07,1.06v.16A1.07,1.07,0,0,1,90.33,89Z" />
          <Polygon fill="#b9c1c9" points="90.48 86.6 63.12 65.12 47.92 65.12 75.57 86.89 73.11 86.89 90.48 86.6" />
          <Path d="M48.3,65.53a1.14,1.14,0,0,0-.77-.3,1,1,0,0,0-.75.33L20.24,87.19a.89.89,0,0,0,.11,1.32,1.08,1.08,0,0,0,1.43-.11L47.57,67.57,74.15,88.4a1,1,0,0,0,.75.31,1.07,1.07,0,0,0,.68-.24.89.89,0,0,0,.07-1.32Z" fill="#ffffff" />
          <Polygon fill="#b9c1c9" points="24.42 85.83 24.42 88.78 21.66 88.84 24.42 85.83" />
          <Path d="M24.41,89H21.58a.24.24,0,0,1-.24-.24.24.24,0,0,1,.24-.24h2.83a.23.23,0,0,1,.24.24A.24.24,0,0,1,24.41,89Z" />
          <Path d="M90.73,87.13H75.64a.24.24,0,0,1,0-.48H90L63,65.36H56.64a.24.24,0,0,1,0-.48h6.48a.24.24,0,0,1,.14.05L90.88,86.7A.25.25,0,0,1,91,87,.25.25,0,0,1,90.73,87.13Z" />
          <Path d="M55.2,65.36h-.94a.24.24,0,1,1,0-.48h.94a.24.24,0,0,1,0,.48Z" />
          <Path d="M47.51,65.4a.24.24,0,1,1,0-.48l5.41,0a.23.23,0,0,1,.24.24.24.24,0,0,1-.24.24l-5.41,0Z" />
          <Path d="M21,89a1.31,1.31,0,0,1-.82-.28,1.11,1.11,0,0,1-.44-.81,1.09,1.09,0,0,1,.3-.85L46.63,65.37a1.21,1.21,0,0,1,.9-.38,1.36,1.36,0,0,1,.93.36L75.8,87a1.11,1.11,0,0,1,.35.87,1.09,1.09,0,0,1-.42.82A1.35,1.35,0,0,1,74,88.57L47.57,67.88,21.93,88.59A1.24,1.24,0,0,1,21,89Zm26.53-23.5h0a.8.8,0,0,0-.59.25L20.39,87.38a.62.62,0,0,0-.15.47.66.66,0,0,0,.26.47.84.84,0,0,0,1.1-.08L47.42,67.38a.24.24,0,0,1,.3,0L74.3,88.22a.86.86,0,0,0,1.13.06.64.64,0,0,0,.24-.47.66.66,0,0,0-.19-.49L48.15,65.72l0,0A.86.86,0,0,0,47.55,65.47Z" />
          <Path d="M24.49,118.44a.24.24,0,0,1-.24-.24V86.38a.24.24,0,0,1,.24-.24.24.24,0,0,1,.24.24V118.2A.24.24,0,0,1,24.49,118.44Z" />
          <Path d="M72,118.63a.25.25,0,0,1-.24-.25V86.76a.24.24,0,1,1,.48,0v31.62A.25.25,0,0,1,72,118.63Z" />
          <Path d="M86.72,118.63a.24.24,0,0,1-.24-.25V88.82a.24.24,0,0,1,.24-.24.24.24,0,0,1,.24.24v29.56A.25.25,0,0,1,86.72,118.63Z" />
          <Polyline fill={svgHighlight} points="54 111.69 54 92.38 63.84 92.38 63.84 111.69" />
          <Path d="M63.84,111.94a.25.25,0,0,1-.24-.25V92.63H54.24v19.06a.24.24,0,1,1-.48,0V92.38a.24.24,0,0,1,.24-.24h9.84a.24.24,0,0,1,.24.24v19.31A.25.25,0,0,1,63.84,111.94Z" />
          <Polygon fill="#ffffff" points="64.91 111.7 51.92 111.7 51.92 113.96 49.58 113.96 49.58 116.13 47.55 116.13 47.55 118.49 50.33 118.49 50.65 118.49 62.87 118.49 62.87 114.2 64.91 114.2 64.91 111.7" />
          <Path d="M62.87,118.73H47.55a.24.24,0,0,1-.24-.24v-2.36a.24.24,0,0,1,.24-.24h1.79V114a.24.24,0,0,1,.24-.24h2.1v-2a.24.24,0,0,1,.24-.24h13a.23.23,0,0,1,.24.24v2.5a.23.23,0,0,1-.24.24h-1.8v4A.24.24,0,0,1,62.87,118.73Zm-15.08-.48H62.63v-4a.24.24,0,0,1,.24-.24h1.8v-2H52.16v2a.24.24,0,0,1-.24.24h-2.1v1.93a.24.24,0,0,1-.24.24H47.79Z" />
          <Path d="M58.67,116.29H50.45a.16.16,0,0,1,0-.32h8.22a.16.16,0,0,1,0,.32Z" />
          <Path d="M59.84,114.11H51.62a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h8.22A.16.16,0,0,1,60,114,.16.16,0,0,1,59.84,114.11Z" />
          <Polygon fill="#b9c1c9" points="64.93 114.02 64.93 111.73 62.54 111.73 62.54 114.02 60.63 114.02 60.63 116.13 58.65 116.13 58.65 118.49 61.43 118.49 61.75 118.49 64.94 118.49 64.94 114.02 64.93 114.02" />
          <Path d="M64.94,118.73H58.65a.24.24,0,0,1-.24-.24v-2.36a.24.24,0,0,1,.24-.24h1.74V114a.24.24,0,0,1,.24-.24H62.3v-2a.24.24,0,0,1,.24-.24h2.39a.24.24,0,0,1,.24.24V114s0,0,0,.07v4.47A.24.24,0,0,1,64.94,118.73Zm-6-.48H64.7v-4.16a.15.15,0,0,1,0-.07v-2H62.78v2a.24.24,0,0,1-.24.24H60.87v1.87a.24.24,0,0,1-.24.24H58.89Z" />
          <Path d="M50.9,111.69H24.49a.16.16,0,1,1,0-.32H50.9a.16.16,0,0,1,0,.32Z" />
          <Path d="M86.84,111.69H73.4a.16.16,0,0,1,0-.32H86.84a.16.16,0,0,1,0,.32Z" />
          <Path d="M71.83,111.67H65.69a.16.16,0,1,1,0-.32h6.14a.16.16,0,0,1,0,.32Z" />
          <Path d="M26.14,111.62a.16.16,0,0,1-.16-.16v-26a.16.16,0,1,1,.32,0v26A.16.16,0,0,1,26.14,111.62Z" />
          <Path d="M70.2,111.62a.16.16,0,0,1-.16-.16v-26a.16.16,0,0,1,.32,0v26A.16.16,0,0,1,70.2,111.62Z" />
          <Rect fill="#ffffff" height="1.68" width="11.57" x="53.07" y="90.67" />
          <Path d="M64.64,92.59H53.07a.24.24,0,0,1-.24-.24V90.67a.24.24,0,0,1,.24-.24H64.64a.24.24,0,0,1,.24.24v1.68A.24.24,0,0,1,64.64,92.59Zm-11.33-.48H64.4v-1.2H53.31Z" />
          <Path d="M62.06,109.86H59a.16.16,0,1,1,0-.32H61.9v-6.49h-6v6.49h2.09a.16.16,0,0,1,0,.32H55.69a.16.16,0,0,1-.16-.16v-6.81a.16.16,0,0,1,.16-.16h6.37a.16.16,0,0,1,.16.16v6.81A.16.16,0,0,1,62.06,109.86Z" />
          <Path d="M62.06,101.88H55.69a.16.16,0,0,1-.16-.16v-8a.16.16,0,0,1,.16-.16h4.47a.16.16,0,0,1,.16.16.16.16,0,0,1-.16.16H55.85v7.66h6V93.9h-.72a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h.88a.16.16,0,0,1,.16.16v8A.16.16,0,0,1,62.06,101.88Z" />
          <Rect fill="#ffffff" height="5.31" width="13.54" x="40.6" y="78.82" />
          <Path d="M54.14,84.37H40.6a.24.24,0,0,1-.24-.24V78.82a.24.24,0,0,1,.24-.24H54.14a.24.24,0,0,1,.24.24v5.31A.24.24,0,0,1,54.14,84.37Zm-13.3-.48H53.9V79.06H40.84Z" />
          <Rect fill="#ffffff" height="15.69" width="13.48" x="32.46" y="90.81" />
          <Path d="M45.94,106.74H32.46a.24.24,0,0,1-.24-.24V90.81a.24.24,0,0,1,.24-.24H45.94a.24.24,0,0,1,.24.24V106.5A.24.24,0,0,1,45.94,106.74Zm-13.24-.48h13V91.05h-13Z" />
          <Rect fill="#b9c1c9" height="11.71" width="5.11" x="33.61" y="93.47" />
          <Rect fill="#b9c1c9" height="11.71" width="5.33" x="39.46" y="93.47" />
          <Rect fill="#ffffff" height="1.68" width="15.25" x="31.62" y="90.67" />
          <Path d="M46.87,92.59H31.62a.24.24,0,0,1-.24-.24V90.67a.24.24,0,0,1,.24-.24H46.87a.24.24,0,0,1,.24.24v1.68A.24.24,0,0,1,46.87,92.59Zm-15-.48H46.63v-1.2H31.86Z" />
          <Polygon fill="#ffffff" opacity="0.4" points="33.65 103.12 37.49 93.5 34.45 93.5 33.65 95.33 33.65 103.12" />
          <Polygon fill="#ffffff" opacity="0.4" points="40.19 105.18 38.22 105.18 42.81 93.53 44.78 93.53 40.19 105.18" />
          <Path d="M38.6,105.34h-5a.16.16,0,0,1-.16-.16V93.47a.16.16,0,0,1,.16-.16h5a.16.16,0,0,1,.16.16v11.71A.16.16,0,0,1,38.6,105.34ZM33.77,105h4.67V93.63H33.77Z" />
          <Path d="M44.79,105.34H39.63a.16.16,0,0,1-.16-.16V93.47a.16.16,0,0,1,.16-.16h5.16a.16.16,0,0,1,.16.16v11.71A.16.16,0,0,1,44.79,105.34Zm-5-.32h4.84V93.63H39.79Z" />
          <Rect fill="#b9c1c9" height="2.81" width="5" x="41.73" y="80.03" />
          <Rect fill="#b9c1c9" height="2.81" width="5.3" x="47.61" y="80.03" />
          <Path d="M46.73,83h-5a.16.16,0,0,1-.16-.16V80a.16.16,0,0,1,.16-.16h5a.16.16,0,0,1,.16.16v2.81A.16.16,0,0,1,46.73,83Zm-4.84-.32h4.68V80.19H41.89Z" />
          <Path d="M52.9,83H47.61a.16.16,0,0,1-.16-.16V80a.16.16,0,0,1,.16-.16H52.9a.16.16,0,0,1,.16.16v2.81A.16.16,0,0,1,52.9,83Zm-5.13-.32h5V80.19h-5Z" />
          <Path d="M52.06,71.46H45.32a.16.16,0,0,1,0-.32h6.74a.16.16,0,0,1,0,.32Z" />
          <Path d="M51.46,74H40.19a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16H51.46a.16.16,0,0,1,.16.16A.16.16,0,0,1,51.46,74Z" />
          <Path d="M58.8,76.66H40a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16H58.8a.16.16,0,0,1,.16.16A.16.16,0,0,1,58.8,76.66Z" />
          <Path d="M70.07,85.87H48.17a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h21.9a.16.16,0,0,1,.16.16A.16.16,0,0,1,70.07,85.87Z" />
          <Path d="M44.57,85.87H26.3a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16H44.57a.16.16,0,0,1,.16.16A.16.16,0,0,1,44.57,85.87Z" />
          <Path d="M70.07,89.13H59.66a.16.16,0,0,1,0-.32H70.07a.16.16,0,1,1,0,.32Z" />
          <Path d="M57.6,89.13H26.3a.16.16,0,1,1,0-.32H57.6a.16.16,0,0,1,0,.32Z" />
          <Path d="M38.84,79.47H33.27a.16.16,0,0,1,0-.32h5.57a.16.16,0,0,1,0,.32Z" />
          <Path d="M29.56,93H26.24a.16.16,0,1,1,0-.32h3.32a.16.16,0,0,1,0,.32Z" />
          <Path d="M28.61,96.94H26.24a.16.16,0,1,1,0-.32h2.37a.16.16,0,0,1,0,.32Z" />
          <Path d="M32.36,101.26H27.83a.16.16,0,1,1,0-.32h4.53a.16.16,0,0,1,0,.32Z" />
          <Path d="M28.45,105.59H26.24a.16.16,0,1,1,0-.32h2.21a.16.16,0,0,1,0,.32Z" />
          <Path d="M70.19,93H66.88a.16.16,0,1,1,0-.32h3.31a.16.16,0,0,1,0,.32Z" />
          <Path d="M82.41,93H72.16a.16.16,0,1,1,0-.32H82.41a.16.16,0,0,1,0,.32Z" />
          <Path d="M70.19,96.94H67.83a.16.16,0,0,1,0-.32h2.36a.16.16,0,0,1,0,.32Z" />
          <Path d="M74.64,96.94H72.27a.16.16,0,0,1,0-.32h2.37a.16.16,0,1,1,0,.32Z" />
          <Path d="M68.6,101.26H64.07a.16.16,0,1,1,0-.32H68.6a.16.16,0,1,1,0,.32Z" />
          <Path d="M53.85,101.26H50.34a.16.16,0,0,1,0-.32h3.51a.16.16,0,1,1,0,.32Z" />
          <Path d="M70.19,105.59H68a.16.16,0,1,1,0-.32h2.2a.16.16,0,0,1,0,.32Z" />
          <Path d="M78.15,105.59H72.07a.16.16,0,1,1,0-.32h6.08a.16.16,0,0,1,0,.32Z" />
          <Path d="M50.9,96.94h-5a.16.16,0,0,1,0-.32h5a.16.16,0,0,1,0,.32Z" />
          <Path d="M48.23,105.59H46a.16.16,0,1,1,0-.32h2.2a.16.16,0,1,1,0,.32Z" />
          <Path d="M39,109.91H26.24a.16.16,0,1,1,0-.32H39a.16.16,0,0,1,0,.32Z" />
          <Path d="M62.54,79.47H58.65a.16.16,0,0,1,0-.32h3.89a.16.16,0,0,1,0,.32Z" />
          <Path d="M40.42,82.39H33.27a.16.16,0,1,1,0-.32h7.15a.16.16,0,0,1,0,.32Z" />
          <Path d="M66,82.39H58.09a.16.16,0,1,1,0-.32H66a.16.16,0,0,1,0,.32Z" />
          <Polyline fill={svgHighlight} points="170.63 118.42 170.63 97.44 182.09 97.44 182.09 118.42" />
          <Rect fill="#e5eaee" height="27.66" width="15.85" x="284.97" y="90.9" />
          <Path d="M288.64,118.21a6.29,6.29,0,0,1,2.14-3.29,4.5,4.5,0,0,1,4.34-.45s.61-2.66,5.43-3v3.87l-9.64,2.89Z" fill="#b9c1c9" />
          <Rect fill="#b9c1c9" height="2.06" width="15.8" x="285.09" y="90.62" />
          <Polyline fill={svgHighlight} points="253 118.42 253 97.2 263.99 97.2 263.99 118.42" />
          <Path d="M264.18,118.65a.24.24,0,0,1-.24-.24v-21h-10.5v21a.24.24,0,0,1-.24.24.23.23,0,0,1-.24-.24V97.2a.24.24,0,0,1,.24-.24h11a.24.24,0,0,1,.24.24v21.21A.23.23,0,0,1,264.18,118.65Z" />
          <Ellipse cx="260.22" cy="101.87" fill="#ffffff" rx="0.49" ry="0.52" />
          <Path d="M260.22,102.55a.68.68,0,0,1,0-1.36.68.68,0,0,1,0,1.36Zm0-1a.36.36,0,1,0,.33.36A.35.35,0,0,0,260.22,101.51Z" />
          <Polyline fill="#ffffff" points="258.54 99.71 261.85 99.71 261.85 107.38 255.04 107.38 255.04 99.71 257.44 99.71" />
          <Path d="M261.85,107.54H255a.16.16,0,0,1-.16-.16V99.71a.16.16,0,0,1,.16-.16h2.4a.16.16,0,1,1,0,.32H255.2v7.35h6.49V99.87h-3.16a.16.16,0,0,1,0-.32h3.32a.16.16,0,0,1,.16.16v7.67A.16.16,0,0,1,261.85,107.54Z" />
          <Rect fill="#e5eaee" height="54.2" width="10.16" x="189.08" y="64.23" />
          <Rect fill="#e5eaee" height="54.33" width="7.98" x="156.5" y="64.1" />
          <Path d="M191.73,65.78l7.46,0v-1.4l-7.76-.25L189,62.36l-.06,1.81,1.57,1.13A1.82,1.82,0,0,0,191.73,65.78Z" fill="#b9c1c9" />
          <Path d="M199.23,118.65a.27.27,0,0,1-.27-.26V64.2a.27.27,0,1,1,.53,0v54.19A.26.26,0,0,1,199.23,118.65Z" />
          <Polyline fill={svgHighlight} points="170.83 118.42 170.83 97.44 182.29 97.44 182.29 118.42" />
          <Path d="M182.29,118.65a.24.24,0,0,1-.24-.24V97.68h-11v20.73a.24.24,0,0,1-.48,0v-21a.24.24,0,0,1,.24-.24h11.46a.24.24,0,0,1,.24.24v21A.24.24,0,0,1,182.29,118.65Z" />
          <Path d="M179.85,107.38h-7.09a.16.16,0,0,1-.16-.16V99.64a.16.16,0,0,1,.16-.16h2.5a.16.16,0,0,1,0,.32h-2.34v7.26h6.77V99.8H176.4a.16.16,0,1,1,0-.32h3.45a.16.16,0,0,1,.16.16v7.58A.16.16,0,0,1,179.85,107.38Z" />
          <Path d="M179.85,116.57h-1a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h.82v-7.57h-6.77v7.57h4.81a.16.16,0,0,1,.16.16.16.16,0,0,1-.16.16h-5a.16.16,0,0,1-.16-.16v-7.89a.16.16,0,0,1,.16-.16h7.09a.16.16,0,0,1,.16.16v7.89A.16.16,0,0,1,179.85,116.57Z" />
          <Polygon fill="#b9c1c9" points="203.71 61.77 159.43 33.86 147.85 33.86 192.49 62.12 203.71 61.77" />
          <Path d="M148.31,34.35a1.28,1.28,0,0,0-.92-.36,1.23,1.23,0,0,0-.9.39L130.23,45.07a1.06,1.06,0,0,0,.14,1.57,1.29,1.29,0,0,0,1.71-.13l15.36-9.72,43.64,26.93a1.27,1.27,0,0,0,.9.36,1.22,1.22,0,0,0,.81-.29,1,1,0,0,0,.08-1.57Z" fill="#ffffff" />
          <Polygon fill="#b9c1c9" points="148.93 37.93 132.67 47.92 131.87 46.53 147.41 36.99 148.93 37.93" />
          <Path d="M204.31,62.17H192.86a.27.27,0,1,1,0-.53h10.52l-44-27.52h-5.51l-6.48.05h0a.27.27,0,0,1-.27-.27.27.27,0,0,1,.26-.27l12.07,0a.23.23,0,0,1,.14,0l44.88,28.05a.25.25,0,0,1,.11.3A.26.26,0,0,1,204.31,62.17Z" />
          <Path d="M191.69,64.12h11.78a1.11,1.11,0,0,0,1.12-1.1h0a1.11,1.11,0,0,0-1.12-1.1H192.61" fill="#ffffff" />
          <Path d="M203.47,64.39H191.69a.28.28,0,0,1-.27-.27.28.28,0,0,1,.27-.27h11.78a.83.83,0,1,0,0-1.66H192.61a.27.27,0,1,1,0-.54h10.86a1.37,1.37,0,1,1,0,2.74Z" />
          <Path d="M192,64.35a1.5,1.5,0,0,1-1.09-.45L147.44,37.1l-15.13,9.4a1.57,1.57,0,0,1-2.05.13,1.36,1.36,0,0,1-.52-1,1.3,1.3,0,0,1,.36-1l.05-.05,16.17-10.45a1.56,1.56,0,0,1,2.16,0L193,62a1.32,1.32,0,0,1,0,2A1.56,1.56,0,0,1,192,64.35ZM147.44,36.52a.33.33,0,0,1,.14,0l43.64,26.93a1,1,0,0,0,1.4.1.83.83,0,0,0,.3-.59.78.78,0,0,0-.24-.6L148.17,34.58a.1.1,0,0,1,0,0,1.06,1.06,0,0,0-.72-.29,1,1,0,0,0-.72.31.1.1,0,0,1-.05,0L130.47,45.05a.75.75,0,0,0-.2.58.82.82,0,0,0,.32.58,1,1,0,0,0,1.35-.1l.06,0,15.3-9.51A.33.33,0,0,1,147.44,36.52Z" />
          <Polyline fill={svgHighlight} points="108.92 64.8 104.72 64.8 104.72 79.3 109.12 79.3" />
          <Path d="M304.1,44.91a2.45,2.45,0,0,0,2.26-3.36c-1.37-3.41-4.28-8.25-9.79-8.82a9.57,9.57,0,0,0-6.19,1.81.39.39,0,0,1-.48,0,7,7,0,0,0-4.74-1.77c-3.55,0-6.42,2.42-6.42,5.4h0a.39.39,0,0,1-.42.4,4.71,4.71,0,0,0-.53,0,4.93,4.93,0,0,0-4.67,2.84.4.4,0,0,1-.52.21,4.69,4.69,0,0,0-1.93-.4A4.1,4.1,0,0,0,267.4,43a1.22,1.22,0,0,0,1,1.93Z" fill="#e5eaee" />
          <Path d="M17.15,51.85a1.24,1.24,0,0,1-.94-2.05c1.16-1.35,3.49-2.28,6.19-2.28.43,0,.86,0,1.28.07a.77.77,0,0,0,.85-.77h0c0-2.34,3-4.24,6.62-4.24A9.84,9.84,0,0,1,34,43a.73.73,0,0,0,.93-.53c.77-3.48,4.5-6.13,9-6.13,5.06,0,9.15,3.34,9.15,7.46,0,.14,0,.29,0,.43a4.48,4.48,0,0,1,.64-.05,4.24,4.24,0,0,1,4,2.94.73.73,0,0,0,.91.49,4.37,4.37,0,0,1,1.15-.16A4.25,4.25,0,0,1,63.12,49a1.74,1.74,0,0,1-1.37,2.81Z" fill="#e5eaee" />
          <Path d="M325.14,118.73h-1.68a.24.24,0,1,1,0-.47h1.68a.24.24,0,1,1,0,.47Z" />
          <Path d="M4.1,118.74H2.5a.24.24,0,0,1-.24-.24.24.24,0,0,1,.24-.24H4.1a.24.24,0,0,1,.24.24A.24.24,0,0,1,4.1,118.74Z" />
          <Path d="M158.82,65.6h6V64.29l-6.35-.18-2.38-2.25L156,63.67l1.56,1.45A1.82,1.82,0,0,0,158.82,65.6Z" fill="#b9c1c9" />
          <Path d="M90.2,53.78,83.26,69a.45.45,0,0,0,.38.63l1,0a.44.44,0,0,1,.39.6L77.89,89.15a.44.44,0,0,0,.41.6h1.13a.44.44,0,0,1,.43.55L77,100.71a1.42,1.42,0,0,0,1.54,1.78h0a.45.45,0,0,1,.49.5l-.4,2.74a2.14,2.14,0,0,0,2.11,2.45H99.4a2,2,0,0,0,2-2.31h0a.44.44,0,0,1,.48-.51h0a2.24,2.24,0,0,0,2.42-2.81l-2.46-9.27a.44.44,0,0,1,.55-.53l1.22.38a.44.44,0,0,0,.55-.55L99,75.48a.44.44,0,0,1,.54-.55l.83.21a.44.44,0,0,0,.52-.59L95.75,62.22a.44.44,0,0,1,.46-.6h0a.44.44,0,0,0,.45-.64l-3.8-7.27A1.48,1.48,0,0,0,90.2,53.78Z" fill="#ffffff" />
          <Path d="M99.4,108.42H80.78a2.38,2.38,0,0,1-2.35-2.73l.4-2.73a.22.22,0,0,0,0-.17.21.21,0,0,0-.17-.06,1.64,1.64,0,0,1-1.45-.56,1.68,1.68,0,0,1-.35-1.52l2.82-10.41a.21.21,0,0,0,0-.17.19.19,0,0,0-.16-.08H78.3a.7.7,0,0,1-.56-.29.71.71,0,0,1-.08-.63L84.79,70.2a.19.19,0,0,0,0-.18.24.24,0,0,0-.16-.09l-1,0a.72.72,0,0,1-.55-.33.67.67,0,0,1,0-.63L90,53.68h0a1.7,1.7,0,0,1,1.51-1,1.67,1.67,0,0,1,1.57.92l3.8,7.27a.68.68,0,0,1,0,.7.7.7,0,0,1-.65.29.23.23,0,0,0-.19.07.21.21,0,0,0,0,.2l5.12,12.33a.66.66,0,0,1-.12.7.68.68,0,0,1-.68.22l-.83-.22a.2.2,0,0,0-.25.25l5.18,17.1a.66.66,0,0,1-.17.68.69.69,0,0,1-.68.17L102.32,93a.2.2,0,0,0-.2,0,.19.19,0,0,0-.05.19l2.46,9.27a2.48,2.48,0,0,1-2.68,3.11.23.23,0,0,0-.16.06.19.19,0,0,0-.06.17,2.22,2.22,0,0,1-.52,1.8A2.25,2.25,0,0,1,99.4,108.42Zm-20.76-6.17a.64.64,0,0,1,.48.21.67.67,0,0,1,.19.57l-.41,2.73a1.9,1.9,0,0,0,1.88,2.18H99.4a1.78,1.78,0,0,0,1.76-2,.66.66,0,0,1,.18-.57.68.68,0,0,1,.57-.2,2,2,0,0,0,2.16-2.52l-2.47-9.26a.69.69,0,0,1,.19-.67.69.69,0,0,1,.67-.16l1.22.38a.2.2,0,0,0,.2-.05.2.2,0,0,0,.05-.2l-5.18-17.1a.69.69,0,0,1,.16-.67.7.7,0,0,1,.67-.19l.83.22a.18.18,0,0,0,.2-.06.19.19,0,0,0,0-.21L95.53,62.32a.67.67,0,0,1,.08-.68.7.7,0,0,1,.63-.26.17.17,0,0,0,.19-.08.2.2,0,0,0,0-.21l-3.8-7.27a1.23,1.23,0,0,0-1.13-.66,1.21,1.21,0,0,0-1.1.72L83.48,69.12a.18.18,0,0,0,0,.19.2.2,0,0,0,.16.1l1,0a.69.69,0,0,1,.54.31.66.66,0,0,1,.07.61L78.11,89.24a.19.19,0,0,0,0,.18.17.17,0,0,0,.16.09h1.13a.69.69,0,0,1,.54.26.68.68,0,0,1,.12.6l-2.82,10.4a1.19,1.19,0,0,0,1.29,1.49Z" />
          <Path d="M91.17,118.54a.32.32,0,0,1-.32-.32V73.56a.32.32,0,0,1,.32-.32.32.32,0,0,1,.32.32v44.66A.33.33,0,0,1,91.17,118.54Z" />
          <Path d="M91,100.67a.24.24,0,0,1-.18-.07l-5.67-4.87a.28.28,0,0,1,0-.38.27.27,0,0,1,.38,0l5.67,4.87a.27.27,0,0,1,0,.37A.25.25,0,0,1,91,100.67Z" />
          <Path d="M91,85.25a.29.29,0,0,1-.18-.07l-3.92-3.6a.27.27,0,0,1,.36-.39l3.92,3.6a.26.26,0,0,1,0,.37A.25.25,0,0,1,91,85.25Z" />
          <Path d="M91,93.42a.29.29,0,0,1-.21-.09.27.27,0,0,1,0-.37l5.68-4.88a.27.27,0,0,1,.37,0,.27.27,0,0,1,0,.38l-5.67,4.87A.27.27,0,0,1,91,93.42Z" />
          <Path d="M91.14,78a.26.26,0,0,1-.22-.11.27.27,0,0,1,.06-.37l2.77-2a.27.27,0,1,1,.32.44L91.3,78A.25.25,0,0,1,91.14,78Z" />
          <Rect fill="#ffffff" height="57.92" width="28.1" x="98.84" y="60.71" />
          <Polygon fill="#b9c1c9" points="168.36 61.56 135.58 41.33 126.96 41.33 160.1 61.91 157.15 61.91 168.36 61.56" />
          <Path d="M127.42,41.82a1.3,1.3,0,0,0-.92-.36,1.23,1.23,0,0,0-.9.39L93.8,62.27a1.06,1.06,0,0,0,.13,1.58,1.29,1.29,0,0,0,1.71-.13l30.91-19.46,31.84,19.46a1.24,1.24,0,0,0,.9.37,1.3,1.3,0,0,0,.82-.29,1.06,1.06,0,0,0,.08-1.58Z" fill="#ffffff" />
          <Polygon fill="#ffffff" points="126.45 42.75 145.83 58.33 147.34 82.08 106.48 82.08 106.48 64.21 101.06 62.41 126.45 42.75" />
          <Polygon fill="#b9c1c9" points="98.81 61.81 98.81 64.17 95.4 64.25 98.81 61.81" />
          <Path d="M98.8,64.4H95.4a.27.27,0,0,1,0-.53h3.4a.27.27,0,1,1,0,.53Z" />
          <Polygon fill="#b9c1c9" points="127.81 45.49 99.1 63.65 99.1 61.53 126.51 44.47 127.81 45.49" />
          <Path d="M168.66,62.18h-8.48a.27.27,0,1,1,0-.53h7.55L135.5,41.59h-3.2a.26.26,0,0,1-.27-.26.27.27,0,0,1,.27-.27h3.28a.33.33,0,0,1,.14,0L168.8,61.69a.26.26,0,0,1-.14.49Z" />
          <Path d="M130.57,41.59h-1.13a.26.26,0,0,1-.27-.26.27.27,0,0,1,.27-.27h1.13a.27.27,0,0,1,.27.27A.26.26,0,0,1,130.57,41.59Z" />
          <Path d="M126.47,41.64a.26.26,0,0,1-.26-.26.25.25,0,0,1,.26-.27l1.36,0a.27.27,0,0,1,.27.26.25.25,0,0,1-.26.27l-1.36,0Z" />
          <Path d="M94.72,64.38a1.55,1.55,0,0,1-1-.32,1.38,1.38,0,0,1-.53-1,1.32,1.32,0,0,1,.36-1l.05,0,31.78-20.41a1.55,1.55,0,0,1,2.15,0L160.33,62a1.31,1.31,0,0,1,.44,1,1.33,1.33,0,0,1-.49,1,1.6,1.6,0,0,1-2.05-.06L126.55,44.58,95.81,63.93A1.51,1.51,0,0,1,94.72,64.38ZM94,62.48a.82.82,0,0,0-.2.58.85.85,0,0,0,.33.58,1,1,0,0,0,1.35-.1.1.1,0,0,1,0,0L126.41,44a.26.26,0,0,1,.28,0L158.53,63.5a.1.1,0,0,1,.05,0,1.06,1.06,0,0,0,1.36.05.81.81,0,0,0,.3-.58.78.78,0,0,0-.24-.6L127.28,42.05a.1.1,0,0,1,0,0,1,1,0,0,0-.72-.28,1,1,0,0,0-.72.3l-.05,0Z" />
          <Path d="M98.9,118.63a.27.27,0,0,1-.27-.27V61.86a.27.27,0,1,1,.53,0v56.5A.27.27,0,0,1,98.9,118.63Z" />
          <Path d="M156.23,118.63a.26.26,0,0,1-.26-.27V83.43a.27.27,0,0,1,.26-.27.28.28,0,0,1,.27.27v34.93A.27.27,0,0,1,156.23,118.63Z" />
          <Path d="M156.23,81.72a.26.26,0,0,1-.26-.26v-19a.26.26,0,0,1,.26-.27.27.27,0,0,1,.27.27v19A.27.27,0,0,1,156.23,81.72Z" />
          <Path d="M164.69,118.63a.27.27,0,0,1-.27-.27V64.19a.27.27,0,1,1,.53,0v54.17A.27.27,0,0,1,164.69,118.63Z" />
          <Path d="M189,118.65a.27.27,0,0,1-.27-.26V62.58a.27.27,0,0,1,.54,0v55.81A.27.27,0,0,1,189,118.65Z" />
          <Path d="M168.06,64.39h-8.24a.27.27,0,0,1-.27-.27.28.28,0,0,1,.27-.27h8.24a.83.83,0,1,0,0-1.66h-.6a.27.27,0,1,1,0-.54h.6a1.37,1.37,0,1,1,0,2.74Z" />
          <Polyline fill={svgHighlight} points="106.29 118.63 106.29 96.02 147.75 96.02 147.75 118.63" />
          <Path d="M147.75,118.49a.26.26,0,0,1-.26-.26V96.29H106.56v21.94a.27.27,0,0,1-.54,0V96a.27.27,0,0,1,.27-.27h41.46A.27.27,0,0,1,148,96v22.21A.27.27,0,0,1,147.75,118.49Z" />
          <Path d="M147.51,100.74H120.2a.16.16,0,0,1,0-.32h27.31a.16.16,0,0,1,0,.32Z" />
          <Path d="M119.08,100.74h-1a.16.16,0,0,1,0-.32h1a.16.16,0,0,1,0,.32Z" />
          <Path d="M116.4,100.74h-9a.16.16,0,0,1,0-.32h9a.16.16,0,0,1,0,.32Z" />
          <Path d="M147.51,105H127.74a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h19.77a.16.16,0,0,1,.16.16A.16.16,0,0,1,147.51,105Z" />
          <Path d="M126.64,105H107.39a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h19.25a.16.16,0,0,1,.16.16A.16.16,0,0,1,126.64,105Z" />
          <Path d="M147.51,109.17H113.56a.16.16,0,0,1-.16-.16.17.17,0,0,1,.16-.16h33.95a.16.16,0,0,1,.16.16A.16.16,0,0,1,147.51,109.17Z" />
          <Path d="M112.27,109.17h-4.88a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h4.88a.16.16,0,0,1,.16.16A.16.16,0,0,1,112.27,109.17Z" />
          <Path d="M147.51,113.39H107.39a.16.16,0,0,1,0-.32h40.12a.16.16,0,1,1,0,.32Z" />
          <Path d="M107.3,118.16a.16.16,0,0,1-.16-.16V96.34a.16.16,0,0,1,.32,0V118A.16.16,0,0,1,107.3,118.16Z" />
          <Path d="M149.24,96.2H104.8a.26.26,0,0,1-.27-.26V93a.27.27,0,0,1,.27-.27h44.44a.27.27,0,0,1,.27.27v3A.26.26,0,0,1,149.24,96.2Zm-44.18-.53H149V93.26H105.06Z" />
          <Path d="M139.69,117.34a.16.16,0,0,1-.16-.16v-20a.16.16,0,1,1,.32,0v20A.16.16,0,0,1,139.69,117.34Z" />
          <Path d="M131.33,117.34a.16.16,0,0,1-.16-.16v-20a.16.16,0,1,1,.32,0v20A.16.16,0,0,1,131.33,117.34Z" />
          <Path d="M123,117.34a.16.16,0,0,1-.16-.16v-20a.16.16,0,1,1,.32,0v20A.16.16,0,0,1,123,117.34Z" />
          <Path d="M114.61,117.34a.16.16,0,0,1-.16-.16v-20a.16.16,0,1,1,.32,0v20A.16.16,0,0,1,114.61,117.34Z" />
          <Rect fill="#e5eaee" height="13.86" width="8.07" x="110.25" y="65.6" />
          <Polygon fill="#ffffff" opacity="0.7" points="109.5 76.25 113.65 65.6 109.93 65.6 109.5 66.7 109.5 76.25" />
          <Polygon fill="#ffffff" opacity="0.7" points="114.67 79.46 112.36 79.46 117.77 65.6 120.08 65.6 114.67 79.46" />
          <Polyline fill={svgHighlight} points="108.92 64.8 104.72 64.8 104.72 79.3 109.12 79.3" />
          <Path d="M109.12,79.57h-4.4a.27.27,0,0,1-.27-.27V64.8a.27.27,0,0,1,.27-.27h4.2a.27.27,0,0,1,0,.54H105V79h4.14a.27.27,0,0,1,0,.54Z" />
          <Path d="M118.42,79.78h-9.26a.24.24,0,0,1-.24-.24V64.82a.24.24,0,0,1,.24-.24h9.26a.24.24,0,0,1,.24.24V79.54A.24.24,0,0,1,118.42,79.78Zm-9-.48h8.78V65.06H109.4Z" />
          <Path d="M110,79.49a.16.16,0,0,1-.16-.16V65.68a.16.16,0,0,1,.16-.16h7.46a.16.16,0,0,1,.16.16.16.16,0,0,1-.16.16h-7.3V79.33A.16.16,0,0,1,110,79.49Z" />
          <Path d="M114.16,78.73a.16.16,0,0,1-.16-.16v-12a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v12A.16.16,0,0,1,114.16,78.73Z" />
          <Path d="M107.77,78.18a.16.16,0,0,1-.16-.16V66a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16V78A.16.16,0,0,1,107.77,78.18Z" />
          <Path d="M106.23,79.22a.16.16,0,0,1-.16-.16V66a.16.16,0,1,1,.32,0V79.06A.16.16,0,0,1,106.23,79.22Z" />
          <Path d="M118.41,69.19h-7.6a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h7.6a.16.16,0,0,1,.16.16A.17.17,0,0,1,118.41,69.19Z" />
          <Path d="M118.41,72.64h-7.6a.16.16,0,1,1,0-.32h7.6a.16.16,0,0,1,0,.32Z" />
          <Path d="M118.41,76.09h-7.6a.16.16,0,1,1,0-.32h7.6a.16.16,0,0,1,0,.32Z" />
          <Polyline fill={svgHighlight} points="118.62 64.8 122.82 64.8 122.82 79.3 118.42 79.3" />
          <Path d="M119.76,78.18a.16.16,0,0,1-.16-.16V66a.16.16,0,1,1,.32,0V78A.16.16,0,0,1,119.76,78.18Z" />
          <Path d="M121.31,79.22a.16.16,0,0,1-.16-.16V66a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16V79.06A.16.16,0,0,1,121.31,79.22Z" />
          <Path d="M122.82,79.57h-4.4a.27.27,0,0,1,0-.54h4.13v-14h-3.93a.27.27,0,1,1,0-.54h4.2a.27.27,0,0,1,.27.27V79.3A.27.27,0,0,1,122.82,79.57Z" />
          <Path d="M119.16,81.38H108.89a.27.27,0,1,1,0-.53h10.27a.27.27,0,1,1,0,.53Z" />
          <Path d="M119.16,63.78H108.89a.27.27,0,0,1-.27-.27.26.26,0,0,1,.27-.26h10.27a.26.26,0,0,1,.27.26A.27.27,0,0,1,119.16,63.78Z" />
          <Polyline fill={svgHighlight} points="135.33 64.8 131.13 64.8 131.13 79.3 135.53 79.3" />
          <Rect fill="#e5eaee" height="13.86" width="8.07" x="136.67" y="65.6" />
          <Polygon fill="#ffffff" opacity="0.7" points="135.91 76.25 140.07 65.6 136.34 65.6 135.91 66.7 135.91 76.25" />
          <Polygon fill="#ffffff" opacity="0.7" points="141.09 79.46 138.77 79.46 144.18 65.6 146.5 65.6 141.09 79.46" />
          <Path d="M144.83,79.78h-9.26a.24.24,0,0,1-.24-.24V64.82a.24.24,0,0,1,.24-.24h9.26a.24.24,0,0,1,.24.24V79.54A.24.24,0,0,1,144.83,79.78Zm-9-.48h8.78V65.06h-8.78Z" />
          <Path d="M136.36,79.49a.16.16,0,0,1-.16-.16V65.68a.16.16,0,0,1,.16-.16h7.46a.16.16,0,0,1,.16.16.16.16,0,0,1-.16.16h-7.3V79.33A.16.16,0,0,1,136.36,79.49Z" />
          <Path d="M140.58,78.73a.16.16,0,0,1-.16-.16v-12a.16.16,0,1,1,.32,0v12A.16.16,0,0,1,140.58,78.73Z" />
          <Path d="M134.19,78.18A.16.16,0,0,1,134,78V66a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16V78A.16.16,0,0,1,134.19,78.18Z" />
          <Path d="M132.64,79.22a.16.16,0,0,1-.16-.16V66a.16.16,0,0,1,.32,0V79.06A.16.16,0,0,1,132.64,79.22Z" />
          <Path d="M144.82,69.19h-7.6a.17.17,0,0,1-.16-.16.16.16,0,0,1,.16-.16h7.6A.16.16,0,0,1,145,69,.16.16,0,0,1,144.82,69.19Z" />
          <Path d="M144.82,72.64h-7.6a.16.16,0,1,1,0-.32h7.6a.16.16,0,0,1,0,.32Z" />
          <Path d="M144.82,76.09h-7.6a.16.16,0,1,1,0-.32h7.6a.16.16,0,0,1,0,.32Z" />
          <Polyline fill={svgHighlight} points="145.03 64.8 149.23 64.8 149.23 79.3 144.83 79.3" />
          <Path d="M135.53,79.57h-4.4a.27.27,0,0,1-.27-.27V64.8a.27.27,0,0,1,.27-.27h4.2a.27.27,0,1,1,0,.54H131.4V79h4.13a.27.27,0,0,1,0,.54Z" />
          <Path d="M146.17,78.18A.16.16,0,0,1,146,78V66a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16V78A.16.16,0,0,1,146.17,78.18Z" />
          <Path d="M147.72,79.22a.16.16,0,0,1-.16-.16V66a.16.16,0,0,1,.32,0V79.06A.16.16,0,0,1,147.72,79.22Z" />
          <Path d="M149.23,79.57h-4.4a.27.27,0,1,1,0-.54H149v-14H145a.27.27,0,1,1,0-.54h4.2a.27.27,0,0,1,.27.27V79.3A.27.27,0,0,1,149.23,79.57Z" />
          <Path d="M145.57,81.38H135.3a.27.27,0,1,1,0-.53h10.27a.27.27,0,1,1,0,.53Z" />
          <Path d="M145.57,63.78H135.3a.27.27,0,0,1-.27-.27.26.26,0,0,1,.27-.26h10.27a.26.26,0,0,1,.27.26A.27.27,0,0,1,145.57,63.78Z" />
          <Polyline fill={svgHighlight} points="172.35 67.57 168.53 67.57 168.53 80.75 172.53 80.75" />
          <Rect fill="#e5eaee" height="12.6" width="7.34" x="173.57" y="68.3" />
          <Polygon fill="#ffffff" opacity="0.7" points="172.88 77.98 176.66 68.3 173.27 68.3 172.88 69.3 172.88 77.98" />
          <Polygon fill="#ffffff" opacity="0.7" points="177.59 80.9 175.48 80.9 180.4 68.3 182.51 68.3 177.59 80.9" />
          <Path d="M181,81.21h-8.42a.24.24,0,0,1-.24-.24V67.59a.24.24,0,0,1,.24-.24H181a.24.24,0,0,1,.24.24V81A.24.24,0,0,1,181,81.21Zm-8.18-.48h7.94V67.83h-7.94Z" />
          <Path d="M173.29,80.94a.16.16,0,0,1-.16-.16V68.37a.16.16,0,0,1,.16-.16h6.78a.16.16,0,0,1,0,.32h-6.62V80.78A.16.16,0,0,1,173.29,80.94Z" />
          <Path d="M177.12,80.25a.16.16,0,0,1-.16-.16v-11a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v11A.16.16,0,0,1,177.12,80.25Z" />
          <Path d="M171.31,79.75a.16.16,0,0,1-.16-.16v-11a.16.16,0,0,1,.32,0v11A.16.16,0,0,1,171.31,79.75Z" />
          <Path d="M169.91,80.69a.16.16,0,0,1-.16-.16V68.63a.16.16,0,0,1,.16-.16.17.17,0,0,1,.16.16v11.9A.16.16,0,0,1,169.91,80.69Z" />
          <Path d="M181,71.57h-6.9a.16.16,0,0,1,0-.32H181a.16.16,0,0,1,0,.32Z" />
          <Path d="M181,74.71h-6.9a.16.16,0,0,1,0-.32H181a.16.16,0,1,1,0,.32Z" />
          <Path d="M181,77.85h-6.9a.16.16,0,1,1,0-.32H181a.16.16,0,1,1,0,.32Z" />
          <Polyline fill={svgHighlight} points="181.17 67.57 184.99 67.57 184.99 80.75 180.99 80.75" />
          <Path d="M172.53,81h-4a.27.27,0,0,1-.27-.27V67.57a.27.27,0,0,1,.27-.27h3.82a.27.27,0,0,1,.27.27.26.26,0,0,1-.27.26H168.8V80.49h3.73a.27.27,0,1,1,0,.53Z" />
          <Path d="M182.21,79.75a.16.16,0,0,1-.16-.16v-11a.17.17,0,0,1,.16-.16.16.16,0,0,1,.16.16v11A.16.16,0,0,1,182.21,79.75Z" />
          <Path d="M183.59,80.69a.16.16,0,0,1-.16-.16V68.63a.16.16,0,0,1,.32,0v11.9A.16.16,0,0,1,183.59,80.69Z" />
          <Path d="M185,81h-4a.27.27,0,1,1,0-.53h3.74V67.83h-3.56a.26.26,0,0,1-.26-.26.27.27,0,0,1,.26-.27H185a.27.27,0,0,1,.27.27V80.75A.27.27,0,0,1,185,81Z" />
          <Path d="M181.67,82.67h-9.35a.27.27,0,0,1,0-.53h9.35a.27.27,0,0,1,0,.53Z" />
          <Path d="M181.67,66.66h-9.35a.27.27,0,0,1,0-.53h9.35a.27.27,0,0,1,0,.53Z" />
          <Polygon fill="#b9c1c9" points="274.73 78.21 251.34 50.13 234.02 50.13 257.75 78.54 254.94 78.54 274.73 78.21" />
          <Path d="M239,56l-4.49-5.4a1.24,1.24,0,0,0-.88-.35,1.2,1.2,0,0,0-.86.38l-23,29.22a1,1,0,0,0,.13,1.5,1.22,1.22,0,0,0,1.63-.12l22.19-28.31,22.5,27.34a1.21,1.21,0,0,0,.86.35,1.23,1.23,0,0,0,.77-.27,1,1,0,0,0,.08-1.5l-17.42-21" fill="#ffffff" />
          <Polygon fill="#b9c1c9" points="214.45 77.79 214.45 81.66 211.31 81.73 214.45 77.79" />
          <Path d="M214.44,81.86h-3.23a.24.24,0,0,1-.24-.24.24.24,0,0,1,.24-.24h3.23a.24.24,0,0,1,.24.24A.23.23,0,0,1,214.44,81.86Z" />
          <Polygon fill="#b9c1c9" points="234.67 54.18 214.72 79.83 214.72 77.45 233.6 53.11 234.67 54.18" />
          <Path d="M275,78.78H258.11a.24.24,0,0,1,0-.48h16.4L251.23,50.37H244a.24.24,0,0,1,0-.48h7.38a.23.23,0,0,1,.18.08L275.2,78.39a.23.23,0,0,1,0,.25A.24.24,0,0,1,275,78.78Z" />
          <Path d="M242.32,50.37h-1.07a.24.24,0,0,1,0-.48h1.07a.24.24,0,0,1,0,.48Z" />
          <Path d="M233.56,50.41a.24.24,0,0,1,0-.48l6.16,0h0a.24.24,0,0,1,.24.24.24.24,0,0,1-.24.24l-6.16,0Z" />
          <Path d="M257.29,78.52a.25.25,0,0,1-.19-.09L240.21,58a.24.24,0,0,1,0-.34.25.25,0,0,1,.34,0l16.89,20.43a.24.24,0,0,1,0,.34A.27.27,0,0,1,257.29,78.52Z" />
          <Path d="M210.56,81.84a1.43,1.43,0,0,1-.89-.3,1.29,1.29,0,0,1-.5-.9,1.21,1.21,0,0,1,.34-1l23-29.21a1.43,1.43,0,0,1,1-.47h0a1.48,1.48,0,0,1,1,.42l4.79,5.87a.23.23,0,0,1,0,.33.24.24,0,0,1-.34,0l-4.78-5.85a1,1,0,0,0-.68-.26,1,1,0,0,0-.69.3L209.87,80a.77.77,0,0,0,.09,1.16,1,1,0,0,0,1.31-.09l22.17-28.3a.25.25,0,0,1,.19-.09c.06,0,.14,0,.19.09L256.16,80a.25.25,0,0,1,0,.34.24.24,0,0,1-.34,0l-22.15-27-22,28.08A1.48,1.48,0,0,1,210.56,81.84Z" />
          <Polyline fill="#b9c1c9" points="257.46 78.3 287.9 88.88 304.11 88.52 274.26 78.3" />
          <Line fill="#b9c1c9" x1="274.91" x2="258.11" y1="78.04" y2="78.04" />
          <Path d="M287.48,90.83h17a1.13,1.13,0,0,0,1.15-1.13h0a1.13,1.13,0,0,0-1.15-1.12h-.6l-16,.08" fill="#ffffff" />
          <Path d="M304.53,91.1h-17a.27.27,0,1,1,0-.54h17a.86.86,0,1,0,0-1.71h-.6l-16,.08h0a.27.27,0,1,1,0-.53l16.6-.09a1.4,1.4,0,1,1,0,2.79Z" />
          <Path d="M287,91.15a4.92,4.92,0,0,1-1.19-.21L256,80.43a.27.27,0,1,1,.17-.51L286,90.43a3.53,3.53,0,0,0,1.18.18.8.8,0,0,0,.59-.27,1,1,0,0,0,.22-.9.81.81,0,0,0-.43-.64L257.27,78.53a.28.28,0,0,1-.17-.34.26.26,0,0,1,.34-.16L287.73,88.3a1.31,1.31,0,0,1,.78,1,1.53,1.53,0,0,1-.41,1.4,1.31,1.31,0,0,1-.89.39Z" />
          <Path d="M304.8,88.87l-.08,0L274.59,78.4a.26.26,0,1,1,.17-.5l30.13,10.45a.26.26,0,0,1,.17.34A.27.27,0,0,1,304.8,88.87Z" />
          <Path d="M214.58,118.7a.27.27,0,0,1-.27-.27V77.26a.27.27,0,0,1,.54,0v41.17A.27.27,0,0,1,214.58,118.7Z" />
          <Path d="M285,118.57a.26.26,0,0,1-.26-.27V90.46a.26.26,0,0,1,.26-.26.27.27,0,0,1,.27.26V118.3A.27.27,0,0,1,285,118.57Z" />
          <Path d="M300.88,118.34a.27.27,0,0,1-.27-.27V90.9a.27.27,0,0,1,.53,0v27.17A.27.27,0,0,1,300.88,118.34Z" />
          <Rect fill="#ffffff" height="21.25" width="1.02" x="245.56" y="97.25" />
          <Path d="M246.58,118.65h-1a.16.16,0,0,1-.16-.16V97.25a.16.16,0,0,1,.16-.16h1a.16.16,0,0,1,.16.16v21.24A.16.16,0,0,1,246.58,118.65Zm-.86-.32h.7V97.41h-.7Z" />
          <Rect fill="#ffffff" height="21.25" width="1.02" x="256.66" y="97.25" />
          <Path d="M257.69,118.65h-1a.16.16,0,0,1-.16-.16V97.25a.16.16,0,0,1,.16-.16h1a.16.16,0,0,1,.16.16v21.24A.16.16,0,0,1,257.69,118.65Zm-.87-.32h.71V97.41h-.71Z" />
          <Polyline fill="#b9c1c9" points="267.9 88.52 257.61 95.64 245.4 95.53 255.3 88.52" />
          <Path d="M257.88,97.27h-12.8a.86.86,0,0,1-.86-.85h0a.86.86,0,0,1,.86-.84h.46l12,.06" fill="#ffffff" />
          <Path d="M257.88,97.54h-12.8a1.12,1.12,0,1,1,0-2.23h.46l12,.06a.28.28,0,0,1,.27.27.27.27,0,0,1-.27.27h0l-12.46-.06a.58.58,0,1,0,0,1.15h12.8a.27.27,0,1,1,0,.54Z" />
          <Path d="M258.64,95.07l1.47,1s-1.27,1-1.81,1.06-1.26-.39-.72-1.31A1.88,1.88,0,0,1,258.64,95.07Z" fill="#ffffff" />
          <Path d="M258.21,97.58H258a1,1,0,0,1-.66-.31,1.1,1.1,0,0,1-.29-1,1.27,1.27,0,0,1,.66-1l9.16-6.57-11.45,0L245,95.82a.27.27,0,0,1-.37-.07.28.28,0,0,1,.07-.38l10.49-7.19a.24.24,0,0,1,.15,0h0l12.36,0h0a.24.24,0,0,1,.17.07h0l0,0h0a.22.22,0,0,1,.05.14v0l0,2.4a.27.27,0,0,1-.11.22l-8.38,6a2.26,2.26,0,0,1-1.11.56ZM267.46,89,258,95.73l-.06,0a.72.72,0,0,0-.36.56.64.64,0,0,0,.1.53c.17.13.24.18.37.18a1.12,1.12,0,0,0,.26,0,2,2,0,0,0,.83-.45l.15-.11,8.13-5.77Z" />
          <Rect fill="#e5eaee" height="10.35" width="15.86" x="223.6" y="94.04" />
          <Polygon fill="#ffffff" opacity="0.7" points="222.92 102.27 226.78 94.04 223.3 94.04 222.92 94.72 222.92 102.27" />
          <Polygon fill="#ffffff" opacity="0.7" points="227.73 104.39 225.57 104.39 230.62 94.04 232.78 94.04 227.73 104.39" />
          <Path d="M239.58,104.71H223.4a.24.24,0,0,1-.24-.24V94.06a.24.24,0,0,1,.24-.24h16.18a.24.24,0,0,1,.24.24v10.41A.24.24,0,0,1,239.58,104.71Zm-15.94-.48h15.7V94.3h-15.7Z" />
          <Path d="M227.27,103.81a.16.16,0,0,1-.16-.16V98.29a.16.16,0,0,1,.32,0v5.36A.16.16,0,0,1,227.27,103.81Z" />
          <Path d="M227.27,97.52a.16.16,0,0,1-.16-.16V95.21a.16.16,0,0,1,.32,0v2.15A.16.16,0,0,1,227.27,97.52Z" />
          <Path d="M235.78,100.94a.16.16,0,0,1-.16-.16V94.87a.16.16,0,0,1,.32,0v5.91A.16.16,0,0,1,235.78,100.94Z" />
          <Path d="M231.55,104.57a.16.16,0,0,1-.16-.16V94.06a.16.16,0,1,1,.32,0v10.35A.16.16,0,0,1,231.55,104.57Z" />
          <Path d="M235.78,104.33a.16.16,0,0,1-.16-.16V101.7a.16.16,0,0,1,.32,0v2.47A.16.16,0,0,1,235.78,104.33Z" />
          <Path d="M239.58,99.22H225.11a.16.16,0,1,1,0-.32h14.47a.16.16,0,1,1,0,.32Z" />
          <Path d="M241.3,106.4H221.66a.16.16,0,0,1-.16-.16V92.49a.16.16,0,0,1,.16-.16H241.3a.17.17,0,0,1,.16.16v13.75A.16.16,0,0,1,241.3,106.4Zm-19.48-.32h19.32V92.65H221.82Z" />
          <Rect fill="#e5eaee" height="8.05" width="6.25" x="231.24" y="68.73" />
          <Polygon fill="#ffffff" opacity="0.7" points="230.68 75.03 233.88 68.73 230.99 68.73 230.68 69.29 230.68 75.03" />
          <Polygon fill="#ffffff" opacity="0.7" points="234.67 76.78 232.87 76.78 237.06 68.73 238.86 68.73 234.67 76.78" />
          <Path d="M237.6,77.09h-6.52a.24.24,0,0,1-.24-.24v-8.1a.24.24,0,0,1,.24-.24h6.52a.24.24,0,0,1,.24.24v8.1A.24.24,0,0,1,237.6,77.09Zm-6.28-.48h6V69h-6Z" />
          <Path d="M234.28,75.8a.16.16,0,0,1-.16-.16V72.25a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v3.39A.16.16,0,0,1,234.28,75.8Z" />
          <Path d="M234.28,71.64a.16.16,0,0,1-.16-.16V69.7a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v1.78A.16.16,0,0,1,234.28,71.64Z" />
          <Path d="M237.59,73.06h-5.1a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h5.1a.16.16,0,0,1,.16.16A.16.16,0,0,1,237.59,73.06Z" />
          <Path d="M239,78.48h-9.39a.16.16,0,0,1-.16-.16V67.45a.16.16,0,0,1,.16-.16H239a.16.16,0,0,1,.16.16V78.32A.16.16,0,0,1,239,78.48Zm-9.23-.32h9.07V67.61h-9.07Z" />
          <Rect fill="#e5eaee" height="9.8" width="6.25" x="272.2" y="94.88" />
          <Polygon fill="#ffffff" opacity="0.7" points="271.63 102.54 274.84 94.88 271.95 94.88 271.63 95.56 271.63 102.54" />
          <Polygon fill="#ffffff" opacity="0.7" points="275.63 104.68 273.83 104.68 278.02 94.88 279.82 94.88 275.63 104.68" />
          <Path d="M278.56,105H272a.24.24,0,0,1-.24-.24V94.89a.24.24,0,0,1,.24-.24h6.52a.24.24,0,0,1,.24.24v9.87A.24.24,0,0,1,278.56,105Zm-6.28-.48h6V95.13h-6Z" />
          <Path d="M275.24,103.45a.16.16,0,0,1-.16-.16V99.16a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v4.13A.16.16,0,0,1,275.24,103.45Z" />
          <Path d="M275.24,98.39a.16.16,0,0,1-.16-.16V96.05a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v2.18A.16.16,0,0,1,275.24,98.39Z" />
          <Path d="M278.55,100.1h-5.1a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h5.1a.16.16,0,0,1,.16.16A.16.16,0,0,1,278.55,100.1Z" />
          <Path d="M280,106.7h-9.39a.16.16,0,0,1-.16-.16V93.31a.16.16,0,0,1,.16-.16H280a.17.17,0,0,1,.16.16v13.23A.16.16,0,0,1,280,106.7Zm-9.23-.32h9.07V93.47h-9.07Z" />
          <Rect fill="#e5eaee" height="24.74" width="3.51" x="274.58" y="55.29" />
          <Path d="M274.25,54.1v1.25l.28.06V80.2l-5.87-1.72V55.35s-.39-.3-.36-.41.14-.72.14-.72l.19-.2Z" fill="#ffffff" />
          <Rect fill="#e5eaee" height="1.31" width="4.33" x="274.25" y="54.01" />
          <Rect fill="#b9c1c9" height="1.35" transform="translate(546.87 112.18) rotate(180)" width="9.38" x="268.74" y="55.42" />
          <Path d="M278.08,80.42h-3.75l-5.76-1.67a.24.24,0,0,1-.17-.23V55.46a.24.24,0,1,1,.47,0V78.33l5.56,1.61h3.41V55.42a.24.24,0,0,1,.24-.24.23.23,0,0,1,.24.24V80.18A.24.24,0,0,1,278.08,80.42Z" />
          <Path d="M274.51,76.5a.16.16,0,0,1-.16-.16v-21a.16.16,0,0,1,.32,0v21A.16.16,0,0,1,274.51,76.5Z" />
          <Path d="M274.51,78.76a.16.16,0,0,1-.16-.16V77.12a.16.16,0,1,1,.32,0V78.6A.16.16,0,0,1,274.51,78.76Z" />
          <Path d="M278.22,55.57h-9.47a.71.71,0,0,1-.71-.71V54.5a.71.71,0,0,1,.71-.71h9.47a.71.71,0,0,1,.71.71v.36A.71.71,0,0,1,278.22,55.57Zm-9.47-1.3a.23.23,0,0,0-.23.23v.36a.23.23,0,0,0,.23.23h9.47a.23.23,0,0,0,.23-.23V54.5a.23.23,0,0,0-.23-.23Z" />
          <Path d="M274.22,55.36a.16.16,0,0,1-.16-.16V54.1a.16.16,0,0,1,.32,0v1.1A.16.16,0,0,1,274.22,55.36Z" />
          <Path d="M296.29,93.52h-13.6a.16.16,0,0,1,0-.32h13.6a.16.16,0,0,1,0,.32Z" />
          <Path d="M300.88,96.63H287a.16.16,0,1,1,0-.32h13.92a.16.16,0,1,1,0,.32Z" />
          <Path d="M300.88,99.73h-7.75a.16.16,0,0,1-.16-.16.17.17,0,0,1,.16-.16h7.75a.16.16,0,0,1,.16.16A.16.16,0,0,1,300.88,99.73Z" />
          <Path d="M290.61,99.73h-5.54a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h5.54a.16.16,0,0,1,.16.16A.16.16,0,0,1,290.61,99.73Z" />
          <Path d="M217,99.73h-2.33a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16H217a.16.16,0,0,1,.16.16A.16.16,0,0,1,217,99.73Z" />
          <Path d="M300.88,102.84H282.21a.16.16,0,1,1,0-.32h18.67a.16.16,0,0,1,0,.32Z" />
          <Path d="M219.54,102.84h-5a.16.16,0,1,1,0-.32h5a.16.16,0,1,1,0,.32Z" />
          <Path d="M300.88,105.9H289.67a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h11.21a.16.16,0,0,1,.16.16A.16.16,0,0,1,300.88,105.9Z" />
          <Path d="M217.92,105.9h-3.47a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h3.47a.16.16,0,0,1,.16.16A.16.16,0,0,1,217.92,105.9Z" />
          <Path d="M219.27,90.28h-4.64a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.16h4.64a.16.16,0,0,1,.16.16A.16.16,0,0,1,219.27,90.28Z" />
          <Path d="M226.21,82.89H214.63a.16.16,0,1,1,0-.32h11.58a.16.16,0,1,1,0,.32Z" />
          <Path d="M226.21,78.79H214.63a.16.16,0,0,1,0-.32h11.58a.16.16,0,0,1,0,.32Z" />
          <Path d="M221.93,74.69h-5.05a.16.16,0,0,1,0-.32h5.05a.16.16,0,0,1,0,.32Z" />
          <Path d="M237.24,86.49h-4.68a.16.16,0,0,1,0-.32h4.68a.16.16,0,0,1,0,.32Z" />
          <Path d="M230.58,86.49h-16a.16.16,0,0,1,0-.32h16a.16.16,0,1,1,0,.32Z" />
          <Path d="M217.92,96.45h-3.47a.16.16,0,1,1,0-.32h3.47a.16.16,0,0,1,0,.32Z" />
          <Path d="M299,109H277.22a.16.16,0,1,1,0-.32H299a.16.16,0,0,1,0,.32Z" />
          <Path d="M270.62,112.12h-2a.16.16,0,0,1,0-.32h2a.16.16,0,0,1,0,.32Z" />
          <Path d="M292.82,112.12h-19.5a.16.16,0,0,1,0-.32h19.5a.16.16,0,1,1,0,.32Z" />
          <Path d="M288,115.23h-5.64a.16.16,0,0,1,0-.32H288a.16.16,0,0,1,0,.32Z" />
          <Path d="M279.44,115.23h-3.9a.16.16,0,1,1,0-.32h3.9a.16.16,0,1,1,0,.32Z" />
          <Path d="M301.24,110.85a6.91,6.91,0,0,1,6.68,2.73,1,1,0,0,0,1.1.33,4.73,4.73,0,0,1,1.56-.26,4.8,4.8,0,0,1,3.75,1.8,5.77,5.77,0,0,1,1,2.9H301.19Z" fill="#ffffff" />
          <Path d="M313.87,115a4.64,4.64,0,0,0-3.83-.66c-2.37.54-2.66,0-3.37-.88a6.52,6.52,0,0,0-5.53-1.49v-1.12a6.78,6.78,0,0,1,4.43.67,11,11,0,0,1,2.63,2.25c.31.13.44.2,1.41-.07a4.93,4.93,0,0,1,4.31,1.36" fill="#dbe1e6" />
          <Path d="M315.49,118.62a.24.24,0,0,1-.24-.24,6.45,6.45,0,0,0-1.12-2.8,4.54,4.54,0,0,0-3.55-1.69,4.59,4.59,0,0,0-1.48.24,1.2,1.2,0,0,1-1.37-.41,6.7,6.7,0,0,0-5.37-2.72,6.47,6.47,0,0,0-1.08.09.24.24,0,0,1-.28-.2.25.25,0,0,1,.2-.28,7.46,7.46,0,0,1,1.16-.09,7.2,7.2,0,0,1,5.76,2.91.73.73,0,0,0,.82.25,5.07,5.07,0,0,1,5.58,1.62,6.84,6.84,0,0,1,1.21,3.07.25.25,0,0,1-.23.25Z" />
          <Path d="M301.15,113.1s-3.93-.21-5.43,2.64l-.27.15s-1.8-.93-2.89-.33a3.56,3.56,0,0,0-2.07,2.77h11.44Z" fill="#ffffff" />
          <Path d="M303,114.17s-2.65-.65-4.08.11-1.72,1.92-2.46,2.27a2,2,0,0,1-2.1-.24,3,3,0,0,0-3.81,1.64s.52-2.71,3-2.57,1.58,1.12,2.73-.28S299.6,111.85,303,114.17Z" fill="#dbe1e6" />
          <Path d="M290.41,118.75a.28.28,0,0,1-.17-.07v0a.25.25,0,0,1-.06-.15,3.33,3.33,0,0,1,.95-2.46,3.15,3.15,0,0,1,4.09-.41.3.3,0,0,0,.26,0,.43.43,0,0,0,.24-.22,4.61,4.61,0,0,1,4.35-2.65,4.86,4.86,0,0,1,3.81,1.72.7.7,0,0,0,.74.23,4.43,4.43,0,0,1,1.35-.21.24.24,0,0,1,.24.24.23.23,0,0,1-.24.24,4,4,0,0,0-1.2.18,1.14,1.14,0,0,1-1.26-.38,4.38,4.38,0,0,0-3.44-1.54,4.13,4.13,0,0,0-3.91,2.35.89.89,0,0,1-.54.5.79.79,0,0,1-.67-.11,2.67,2.67,0,0,0-3.48.34,2.9,2.9,0,0,0-.81,2.1.2.2,0,0,1-.07.19A.23.23,0,0,1,290.41,118.75Z" />
          <Path d="M306,114.8a4.21,4.21,0,0,1,.62,0" fill="#ffffff" />
          <Path d="M306.58,115.09h0a5.65,5.65,0,0,0-.59,0,.24.24,0,0,1-.24-.24.24.24,0,0,1,.24-.24,4.89,4.89,0,0,1,.66,0,.24.24,0,0,1,.2.27A.25.25,0,0,1,306.58,115.09Z" />
          <Path d="M297.39,116.52l-.08,0a.16.16,0,0,1-.06-.22,3.41,3.41,0,0,1,.45-.65.16.16,0,0,1,.23,0,.16.16,0,0,1,0,.22,3.67,3.67,0,0,0-.42.61A.17.17,0,0,1,297.39,116.52Z" />
          <Path d="M298.4,115.36a.15.15,0,0,1-.13-.07.16.16,0,0,1,0-.22,4.2,4.2,0,0,1,2.21-.81h0a.16.16,0,0,1,.16.16.15.15,0,0,1-.15.16,4,4,0,0,0-2,.75A.16.16,0,0,1,298.4,115.36Z" />
          <Rect fill="#ffffff" height="2.34" width="23.11" x="207.33" y="111.6" />
          <Path d="M230.44,114.21H207.33a.27.27,0,0,1-.26-.27V111.6a.27.27,0,0,1,.26-.27h23.11a.27.27,0,0,1,.27.27v2.34A.27.27,0,0,1,230.44,114.21Zm-22.84-.54h22.57v-1.8H207.6Z" />
          <Rect fill="#b9c1c9" height="2.34" width="3.67" x="226.77" y="111.6" />
          <Path d="M230.44,114.18h-3.67a.24.24,0,0,1-.24-.24V111.6a.24.24,0,0,1,.24-.24h3.67a.24.24,0,0,1,.24.24v2.34A.24.24,0,0,1,230.44,114.18ZM227,113.7h3.19v-1.86H227Z" />
          <Rect fill="#b9c1c9" height="4.5" width="2.19" x="209.57" y="113.98" />
          <Path d="M211.76,118.73h-2.19a.25.25,0,0,1-.24-.25V114a.25.25,0,0,1,.24-.25h2.19a.24.24,0,0,1,.24.25v4.5A.24.24,0,0,1,211.76,118.73Zm-1.95-.5h1.71v-4h-1.71Z" />
          <Rect fill="#b9c1c9" height="4.47" width="2.19" x="225.07" y="113.98" />
          <Path d="M227.26,118.7h-2.19a.25.25,0,0,1-.24-.25V114a.24.24,0,0,1,.24-.25h2.19a.25.25,0,0,1,.24.25v4.47A.25.25,0,0,1,227.26,118.7Zm-1.95-.5H227v-4h-1.71Z" />
          <Path d="M190.11,118.74H5.54a.24.24,0,0,1-.24-.24.24.24,0,0,1,.24-.24h320.7a.24.24,0,1,1,0,.48H190.11Z" />
          <Path d="M210.23,106.64a2,2,0,0,1-1.28-.55.17.17,0,0,1,0-.23.16.16,0,0,1,.23,0,1.44,1.44,0,0,0,2.09,0,.16.16,0,1,1,.21.24A1.88,1.88,0,0,1,210.23,106.64Z" />
          <Path d="M210.19,106.6a.16.16,0,0,1-.16-.16v-1a.16.16,0,0,1,.16-.16.16.16,0,0,1,.16.16v1A.16.16,0,0,1,210.19,106.6Z" />
          <Circle cx="211.21" cy="103.78" r="0.2" />
          <Circle cx="209.25" cy="103.78" r="0.2" />
          <Ellipse cx="210.2" cy="104.73" rx="0.85" ry="0.4" />
          <Path d="M209.5,104.69l-.16,0a1,1,0,0,0,.83.79h.06l.08-.69Z" />
          <Path d="M210.9,104.69l.15,0a.93.93,0,0,1-.83.79h-.05l-.09-.69Z" />
          <Path d="M207,105.5a1.15,1.15,0,0,1-1.15-.71c-.29-.85.16-1.81,1.35-2.85s2.19-.79,2.23-.77a.24.24,0,0,1-.2.44s-.66-.24-1.71.69-1.44,1.69-1.21,2.33c.16.47.8.39.83.38a.24.24,0,0,1,.27.2.25.25,0,0,1-.2.28Z" />
          <Path d="M210.07,107.94s-2.52,0-2.92-2.12a4.9,4.9,0,0,1,1.17-3.78.25.25,0,0,1,.34,0,.24.24,0,0,1,0,.34,4.5,4.5,0,0,0-1.05,3.37c.32,1.71,2.35,1.73,2.44,1.73a.24.24,0,0,1,.24.24A.24.24,0,0,1,210.07,107.94Z" />
          <Path d="M213.2,105.5H213a.25.25,0,0,1-.21-.28.25.25,0,0,1,.28-.2s.66.09.83-.38c.22-.64-.19-1.43-1.22-2.33s-1.7-.7-1.71-.69a.25.25,0,0,1-.24,0,1.1,1.1,0,0,0-.62-.19.91.91,0,0,0-.56.18.24.24,0,1,1-.34-.34,1.37,1.37,0,0,1,.9-.32,1.53,1.53,0,0,1,.8.22,2.39,2.39,0,0,1,2.09.81c1.19,1,1.64,2,1.35,2.85A1.15,1.15,0,0,1,213.2,105.5Z" />
          <Path d="M210.15,107.94a.24.24,0,0,1,0-.48c.09,0,2.13,0,2.45-1.73a4.49,4.49,0,0,0-1.06-3.37.24.24,0,0,1,0-.34.25.25,0,0,1,.34,0,4.9,4.9,0,0,1,1.17,3.78C212.68,107.92,210.18,107.94,210.15,107.94Z" />
          <Path d="M213.3,105.34s3.47.25,4.53,1.46-1.15,4.55-1.15,4.55l-4.14-.07Z" fill="#ffffff" />
          <Path d="M217.13,111.77a3.09,3.09,0,0,1-2-.88l-.24-.25a.71.71,0,0,1-.21-.51.77.77,0,0,1,.22-.51.73.73,0,0,1,1,0,1.93,1.93,0,0,0,2,.57.65.65,0,0,0,.43-.66.24.24,0,0,1,.22-.26.23.23,0,0,1,.26.21,1.15,1.15,0,0,1-.72,1.15,2.36,2.36,0,0,1-2.48-.66.23.23,0,0,0-.32,0,.21.21,0,0,0-.08.17.28.28,0,0,0,.07.17l.23.23a2.52,2.52,0,0,0,1.78.75,2.1,2.1,0,0,0,1.65-.68A1.85,1.85,0,0,0,219,109a4.56,4.56,0,0,0-3.53-3s-1.26-.22-2.27-.33a.23.23,0,0,1-.21-.26.22.22,0,0,1,.26-.21c1,.11,2.29.32,2.3.32a5,5,0,0,1,3.92,3.36,2.33,2.33,0,0,1-.23,2.06,2.54,2.54,0,0,1-2,.88Z" />
          <Path d="M210.17,111.67l-.9,0a1.2,1.2,0,0,1-.81-2.05,1.15,1.15,0,0,1,.84-.35h.13a14.08,14.08,0,0,0,1.72-.08.24.24,0,0,1,.27.2.24.24,0,0,1-.2.28,16.92,16.92,0,0,1-1.91.08.69.69,0,0,0-.51.21.7.7,0,0,0-.22.51.72.72,0,0,0,.7.72l.89,0,6,0v.48Z" />
          <Path d="M207,111.67l-.91,0a1.19,1.19,0,0,1-1.16-1.2,1.17,1.17,0,0,1,.35-.85,1.19,1.19,0,0,1,.85-.35h.13c.48,0,1.17,0,1.56-.06l.52-1.84a.24.24,0,0,1,.3-.17.25.25,0,0,1,.17.3l-.57,2a.24.24,0,0,1-.19.17,17.2,17.2,0,0,1-1.92.08h0a.71.71,0,0,0-.51.21.69.69,0,0,0-.21.51.71.71,0,0,0,.69.72l.9,0,6,0v.48Z" />
          <Path d="M214.41,101.11a.41.41,0,0,1-.18,0A26.77,26.77,0,0,0,199.5,96.6a.32.32,0,1,1,0-.63,27.49,27.49,0,0,1,15.12,4.56.32.32,0,0,1-.17.58Z" />
          <Polygon fill="#e5eaee" points="98.62 108.05 96.68 108.05 96.68 65.45 98.62 70.09 98.62 108.05" style={{ isolation: 'isolate' }} />
          <Rect fill="#e5eaee" height="10.77" style={{ isolation: 'isolate' }} width="1.92" x="22.51" y="107.41" />
        </G>
      </G>
    </Svg>
  );
}
