import React, { Fragment } from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import FeesHeader from './FeesHeader';
import FeesRow from './FeesRow';
import { StyledPrimaryDivider } from './styledComponents';

const Fees = ({ feeSchedule }) => (
  <Fragment>
    <FeesHeader />
    {feeSchedule.map(({ feeAmount, feeDescription, feeDetailedDescription }) => (
      <View key={`${feeAmount.slice(0, 5)}-${feeDescription.slice(0, 10)}`}>
        <StyledPrimaryDivider />
        <FeesRow
          feeAmount={feeAmount}
          feeDescription={feeDescription}
          feeDetailedDescription={feeDetailedDescription}
        />
      </View>
    ))}
    <StyledPrimaryDivider />
  </Fragment>
);

Fees.propTypes = {
  feeSchedule: T.arrayOf(T.shape({
    feeAmount: T.string.isRequired,
    feeDescription: T.string.isRequired,
    feeDetailedDescription: T.string.isRequired,
  })).isRequired,
};

export default Fees;
