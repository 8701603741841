import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Payoff/messages';

import PayoffReasonOption from './PayoffReasonOption';
import {
  StyledPayoffReason,
  StyledPayoffReasonHeader,
} from './styledComponents';

const PayoffReason = ({
  availablePayOffReasons,
  selectedPayOffReason,
  setSelectedPayOffReason,
}) => (
  <StyledPayoffReason>
    <StyledPayoffReasonHeader>
      <FormattedMessage id={INTL_IDS.PAYOFFREASON_HEADER} />
    </StyledPayoffReasonHeader>
    { availablePayOffReasons.map(({ name, value }) => (
      <PayoffReasonOption
        key={value}
        aria-label={name}
        payoffReason={name}
        role="button"
        selectedPayOffReason={selectedPayOffReason}
        setSelectedPayOffReason={setSelectedPayOffReason}
      />
    ))}
  </StyledPayoffReason>
);

PayoffReason.propTypes = {
  availablePayOffReasons: T.arrayOf(T.shape({
    name: T.string.isRequired,
    value: T.oneOfType([T.string, T.number]).isRequired,
  })).isRequired,
  selectedPayOffReason: T.string.isRequired,
  setSelectedPayOffReason: T.func.isRequired,
};

export default PayoffReason;
