import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../../base_ui';
import iconDictionary from '../../../../../utils/iconDictionary';
import HomeHistoryBody from './Body';
import HomeHistoryEmptySet from './EmptySet';
import {
  BodyOuterWrapper,
  HistoryHeaderWrapper,
  StyledH3,
} from './styledComponents';
import { MainWrapper } from '../styledComponents';

const headerIcon = iconDictionary('paymentHistoryDefaultM');

const PaymentHomeHistory = ({ historyData }) => {
  const shouldRender = !!historyData.length;

  return (
    <MainWrapper>
      <HistoryHeaderWrapper>
        {headerIcon}
        <StyledH3>Payment History</StyledH3>
      </HistoryHeaderWrapper>
      <BodyOuterWrapper>
        <ConditionalRender
          Component={HomeHistoryBody}
          FallbackComponent={HomeHistoryEmptySet}
          propsToPassDown={shouldRender ? { historyData } : {}}
          shouldRender={shouldRender}
        />
      </BodyOuterWrapper>
    </MainWrapper>
  );
};

PaymentHomeHistory.propTypes = { historyData: T.array.isRequired };

export default PaymentHomeHistory;
