import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { P4 } from '../../../base_ui';

export const Container = styled.View`
  padding: ${moderateScale(24)}px 0;
`;

export const LinkText = styled(P4)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;
