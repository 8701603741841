import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';

import { IconButton } from '../base_ui';

export const RadioButtonIcon = styled(IconButton)`
  align-self: center;
  height: ${moderateScale(16)}px;
  margin-right: ${moderateScale(8)}px;
  min-height: ${moderateScale(16)}px;
  min-width: ${moderateScale(16)}px;
  width: ${moderateScale(16)}px;
`;

export const RadioButtonsContainer = styled.View`
  display: flex;
  flex-direction: row;
  margin: ${moderateScale(8)}px ${moderateScale(16)}px;
`;

export const RadioButtonWrapper = styled.Pressable`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: ${moderateScale(16)}px;
`;
