import React from 'react';
import Svg, {
  G,
  Line,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
const Statements = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} {...props}>
      <G isolation="isolate">
        <G>
          <Rect fill="none" y="-0.22" width="85" height="85" />
          <G mixblendmode="multiply">
            <Path fill="#bac2ca" d="M55.29,7.26l-34.83,0A4.73,4.73,0,0,0,15.71,12L15.65,75.5a4.75,4.75,0,0,0,4.74,4.75l48.39,0a4.74,4.74,0,0,0,4.75-4.74l0-49.89Z" />
            <Line fill="#bac2ca" x1="15.7" y1="23.55" x2="15.7" y2="26.21" />
            <Path fill="#bac2ca" d="M73.58,25.66H60a4.75,4.75,0,0,1-4.74-4.75V7.26Z" />
            <Path fill="#bac2ca" d="M73.58,25.66H60a4.75,4.75,0,0,1-4.74-4.75V7.26" />
            <Path fill="#bac2ca" d="M15.69,28.17l0,47.33a4.75,4.75,0,0,0,4.74,4.75l48.39,0a4.74,4.74,0,0,0,4.75-4.74l0-49.89L55.29,7.26l-34.83,0A4.73,4.73,0,0,0,15.71,12v9.85" />
          </G>
          <Path fill="#fff" d="M52.24,4.6l-34.83,0A4.74,4.74,0,0,0,12.67,9.3l-.06,63.54a4.73,4.73,0,0,0,4.74,4.74l48.39,0a4.75,4.75,0,0,0,4.75-4.74L70.54,23Z" />
          <Line fill="#fff" x1="12.66" y1="20.88" x2="12.65" y2="23.55" />
          <Rect fill="#2c2627" x="12.35" y="20.58" width="0.6" height="3.27" rx="0.3" />
          <Path fill="#e6ebef" d="M70.54,23,57,23a4.74,4.74,0,0,1-4.74-4.74V4.6Z" />
          <Path fill="#2c2627" d="M70.54,23.3h0L57,23.28a5,5,0,0,1-5-5V4.6a.3.3,0,0,1,.3-.3h0a.29.29,0,0,1,.3.3V18.24A4.44,4.44,0,0,0,57,22.68l13.57,0a.3.3,0,0,1,.3.3A.31.31,0,0,1,70.54,23.3Z" />
          <Path fill="#2c2627" d="M65.74,77.93h0l-48.39-.05a5,5,0,0,1-5-5l0-47.34a.31.31,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3l0,47.34a4.44,4.44,0,0,0,4.44,4.44l48.39,0h0a4.45,4.45,0,0,0,4.45-4.44l0-49.77L52.12,4.9l-34.71,0h0A4.44,4.44,0,0,0,13,9.3v9.85a.3.3,0,0,1-.3.3h0a.29.29,0,0,1-.3-.3V9.3a5.05,5.05,0,0,1,5-5h0l34.83,0a.35.35,0,0,1,.22.08l18.29,18.4a.36.36,0,0,1,.09.22l0,49.89A5.05,5.05,0,0,1,65.74,77.93Z" />
          <Polygon fill="#fff" points="25.21 60.53 57.9 60.53 57.9 39.37 41.65 25.02 25.21 39.37 25.21 60.53" />
          <Rect fill="#fff" x="29.23" y="23.5" width="3.61" height="9.48" transform="translate(62.06 56.47) rotate(-180)" />
          <Path fill="#bac2ca" d="M23.61,41.52a1.44,1.44,0,0,0,1-.39L41.65,25l16.67,16.1a1.43,1.43,0,0,0,2-2L42.65,22a1.43,1.43,0,0,0-2,0l-18,17.05a1.42,1.42,0,0,0,1,2.46Z" />
          <Path fill="#2c2627" d="M23.61,41.82a1.73,1.73,0,0,1-1.18-3l18-17.05a1.73,1.73,0,0,1,2.39,0L51,29.64a.3.3,0,0,1,0,.42.29.29,0,0,1-.42,0l-8.1-7.83a1.12,1.12,0,0,0-1.55,0l-18,17.05a1.05,1.05,0,0,0-.35.78,1.15,1.15,0,0,0,.3.81,1.16,1.16,0,0,0,1.59,0L41.65,24.6,58.53,40.9a1.12,1.12,0,0,0,1.59,0,1.14,1.14,0,0,0,0-1.59l-8.74-8.43a.3.3,0,0,1,0-.43.3.3,0,0,1,.42,0l8.74,8.44a1.73,1.73,0,0,1-2.4,2.48L41.65,25.43,24.79,41.35A1.72,1.72,0,0,1,23.61,41.82Z" />
          <Rect fill={svgHighlight} x="43.58" y="41.52" width="9.48" height="19.04" />
          <Path fill="#2c2627" d="M53.36,60.86H43.28V41.22H53.36Zm-9.48-.6h8.87V41.82H43.88Z" />
          <Rect fill="#fff" x="27.82" y="22.01" width="6.41" height="2.14" rx="0.98" transform="translate(62.06 46.15) rotate(-180)" />
          <Path fill="#2c2627" d="M33.26,24.44H28.81a1.28,1.28,0,0,1-1.29-1.28V23a1.28,1.28,0,0,1,1.29-1.28h4.45A1.28,1.28,0,0,1,34.54,23v.17A1.28,1.28,0,0,1,33.26,24.44Zm-4.45-2.13a.68.68,0,0,0-.69.68v.17a.68.68,0,0,0,.69.68h4.45a.68.68,0,0,0,.68-.68V23a.68.68,0,0,0-.68-.68Z" />
          <Path fill="#2c2627" d="M62.41,60.83H31.15a.3.3,0,0,1-.3-.3.31.31,0,0,1,.3-.3H62.41a.31.31,0,0,1,.3.3A.3.3,0,0,1,62.41,60.83Z" />
          <Path fill="#2c2627" d="M29.71,60.83H20.58a.3.3,0,0,1-.3-.3.31.31,0,0,1,.3-.3h9.13a.31.31,0,0,1,.3.3A.3.3,0,0,1,29.71,60.83Z" />
          <Rect fill="#e6ebef" x="31.15" y="43.6" width="6.54" height="10.07" />
          <Polygon opacity="0.7" fill="#fff" points="33.91 53.67 30.89 53.67 35.27 43.6 38.3 43.6 33.91 53.67" />
          <Path fill="#2c2627" d="M37.82,54.07h-7.3a.3.3,0,0,1-.3-.3v-2a.3.3,0,0,1,.3-.3.31.31,0,0,1,.3.3v1.74h6.7V43.28h-6.7v7.34a.31.31,0,0,1-.3.3.3.3,0,0,1-.3-.3V43a.29.29,0,0,1,.3-.3h7.3a.29.29,0,0,1,.3.3V53.77A.3.3,0,0,1,37.82,54.07Z" />
          <Path fill="#2c2627" d="M34.3,53.14a.3.3,0,0,1-.3-.3V50.31a.31.31,0,0,1,.3-.3.3.3,0,0,1,.3.3v2.53A.29.29,0,0,1,34.3,53.14Z" />
          <Path fill="#2c2627" d="M34.3,49.79a.3.3,0,0,1-.3-.3V43a.3.3,0,0,1,.3-.3.29.29,0,0,1,.3.3v6.51A.29.29,0,0,1,34.3,49.79Z" />
          <Path fill="#2c2627" d="M37.81,48.88H31.94a.3.3,0,0,1-.3-.3.29.29,0,0,1,.3-.3h5.87a.3.3,0,0,1,.31.3A.31.31,0,0,1,37.81,48.88Z" />
          <Path fill="#2c2627" d="M51.05,47.46H45.58v-.3a2.74,2.74,0,0,1,5.47,0Zm-4.85-.6h4.23a2.14,2.14,0,0,0-4.23,0Z" />
          <Path fill="#2c2627" d="M29.23,27.67a.3.3,0,0,1-.3-.3V24.14a.3.3,0,0,1,.3-.3.31.31,0,0,1,.3.3v3.23A.31.31,0,0,1,29.23,27.67Z" />
          <Path fill="#2c2627" d="M29.23,32.91a.3.3,0,0,1-.3-.3V28.66a.29.29,0,0,1,.3-.3.3.3,0,0,1,.3.3v3.95A.31.31,0,0,1,29.23,32.91Z" />
          <Path fill="#2c2627" d="M32.84,29.72a.3.3,0,0,1-.3-.3V24.14a.3.3,0,0,1,.3-.3.31.31,0,0,1,.3.3v5.28A.31.31,0,0,1,32.84,29.72Z" />
          <Path fill="#2c2627" d="M25.21,60.83a.3.3,0,0,1-.3-.3v-20a.3.3,0,0,1,.3-.3.29.29,0,0,1,.3.3v20A.29.29,0,0,1,25.21,60.83Z" />
          <Path fill="#2c2627" d="M57.9,43.9a.3.3,0,0,1-.3-.3V41a.3.3,0,0,1,.3-.3.29.29,0,0,1,.3.3V43.6A.29.29,0,0,1,57.9,43.9Z" />
          <Path fill="#2c2627" d="M57.9,60.83a.3.3,0,0,1-.3-.3V45.14a.3.3,0,0,1,.3-.3.29.29,0,0,1,.3.3V60.53A.29.29,0,0,1,57.9,60.83Z" />
        </G>
      </G>
    </Svg>
  );
};

export default Statements;
