import React, { Fragment } from 'react';
import T from 'prop-types';

import { SecondaryHeader } from '../../ScreenHeaders';
import { ConditionalRender, StyledScrollView } from '../../base_ui';
import Content from './Content';
import EmptySet from './EmptySet';
import { StyledStatusBanner } from './styledComponents';

const Notifications = ({
  dispatchSetNestedScreen,
  navigation,
  notifications,
  respaLetterError,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Notifications"
    />
    <StyledScrollView>
      <StyledStatusBanner error={respaLetterError} />
      <ConditionalRender
        Component={Content}
        FallbackComponent={EmptySet}
        propsToPassDown={{
          dispatchSetNestedScreen,
          navigation,
          notifications,
        }}
        shouldRender={!!notifications.length}
      />
    </StyledScrollView>
  </Fragment>
);

Notifications.propTypes = {
  dispatchSetNestedScreen: T.func.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  respaLetterError: T.oneOfType([T.bool, T.string]).isRequired,
};

export default Notifications;
