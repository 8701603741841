import React from 'react';
import T from 'prop-types';
import { useWindowDimensions } from 'react-native';

import { interpolate } from '@dmi/shared/utils/globalHelpers';

import { StyledHTML } from './styledComponents';

const DisclaimerRow = ({ customerServicePhoneNumber, html }) => {
  const { width } = useWindowDimensions();

  return (
    <StyledHTML
      contentWidth={width}
      source={{ html: interpolate(html, { customerServicePhoneNumber }) }}
    />
  );
};

DisclaimerRow.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  html: T.string.isRequired,
};

export default DisclaimerRow;
