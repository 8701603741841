import React from 'react';
import T from 'prop-types';

import ConditionalRender from '../ConditionalRender';
import { ARROW_STYLES } from './constants';
import { MainView, StyledModalDialog, StyledTriangle } from './styledComponents';

const BaseTooltip = ({
  arrow,
  children,
  handleClose = () => null,
  positionAbsolute,
  visible,
  ...restProps
}) => (
  <StyledModalDialog
    $positionAbsolute={positionAbsolute}
    handleCloseModal={handleClose}
    variant="tooltip"
    visible={visible}
    {...restProps}
  >
    <ConditionalRender
      Component={StyledTriangle}
      propsToPassDown={ARROW_STYLES[arrow]}
      shouldRender={!!arrow}
    />
    <MainView>
      {children}
    </MainView>
  </StyledModalDialog>
);

BaseTooltip.propTypes = {
  arrow: T.oneOf(Object.keys(ARROW_STYLES)),
  children: T.node.isRequired,
  handleClose: T.func,
  positionAbsolute: T.shape({
    left: T.oneOfType([T.number, T.string]),
    top: T.oneOfType([T.number, T.string]).isRequired,
  }).isRequired,
  visible: T.bool.isRequired,
};

export default BaseTooltip;
