import styled, { css } from 'styled-components/native';

import {
  BaseExternalLink,
  BorderlessButton,
  H1,
  IconButton,
  P3,
  PrimaryDivider,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BiometricPinButtonGroupWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: ${({ $hasMultipleButtons }) => (
    $hasMultipleButtons ? 'space-between' : 'flex-end'
  )};
  margin-bottom: ${moderateScale(35)}px;
  margin-top: ${moderateScale(3)}px;
  width: ${moderateScale(287)}px;

  ${({ $hasAnyButtons }) => !$hasAnyButtons && css`
    height: ${moderateScale(44)}px;
  `}
`;

export const ChapterSevenBankruptcyContainer = styled.View`
  flex-direction: column;
  margin: ${moderateScale(16)}px auto ${moderateScale(8)}px;
  width: ${moderateScale(327)}px;
`;

export const ChapterSevenHeader = styled.Text`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
  font-size: ${moderateScale(9)}px;
  font-weight: 700;
  line-height: ${moderateScale(15)}px;
  margin: 0 auto ${moderateScale(2)}px;
  text-align: center;
`;

export const ChapterSevenText = styled.Text`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
  font-size: ${moderateScale(9)}px;
  line-height: ${moderateScale(13)}px;
  margin: 0;
  text-align: center;
`;

export const DisclosureButton = styled(BorderlessButton).attrs(({
  isFooterButton,
  theme: { colors: { footerLinkText, linkText }, fontSizes: { f3 } },
  titleStyle,
}) => ({
  isFooterButton,
  titleStyle: [
    titleStyle,
    {
      color: footerLinkText || linkText,
      fontSize: f3,
      paddingVertical: moderateScale(8),
    },
  ],
}))``;

export const DisclosuresHeading = styled(P3)`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  padding-vertical: ${moderateScale(8)}px;
  text-align: center;
`;

export const DisclosuresContainer = styled.View`
  margin: ${moderateScale(4)}px 0 ${moderateScale(12)}px;
`;

export const FooterLinkContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${moderateScale(8)}px;
  width: 100%;
`;

export const LinksContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  margin-vertical: ${moderateScale(30)}px;
  width: ${moderateScale(261)}px;
`;

export const LoginContainer = styled.View`
  padding-bottom: ${({ $isBottomBannerRendered }) =>
    $isBottomBannerRendered ? moderateScale(50) : 0}px;
`;

export const LoginFooterContainer = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { footerBackground } } }) => footerBackground};
  display: flex;
  justify-content: center;
`;

export const LoginFormContainer = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-grow: 1;
`;

export const LoginFormProper = styled.View`
  align-items: center;
`;

export const LoginIllustrationContainer = styled.View`
  align-items: center;
  padding-top: ${({ $paddingTop }) => `${moderateScale($paddingTop)}px;`};
  width: 100%;
`;

export const MurabahaStyledH1 = styled(H1)`
  margin-vertical: ${moderateScale(10)}px;
  padding-horizontal: ${moderateScale(48)}px;
  text-align: center;
`;

export const StyledBaseExternalLink = styled(BaseExternalLink)`
  align-items: center;
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontFamilies: { secondaryFont },
    fontSizes: { f3 },
  },
  titleStyle,
}) => ({
  titleStyle: [
    titleStyle,
    {
      color: linkText,
      fontFamily: secondaryFont,
      fontSize: f3,
      lineHeight: moderateScale(18),
      paddingLeft: 0,
      paddingRight: 0,
      paddingVertical: moderateScale(13),
    },
  ],
}))``;

export const StyledIconButton = styled(IconButton)`
  align-self: center;
`;

export const StyledH1 = styled(H1)`
  margin-vertical: ${moderateScale(10)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin-top: ${moderateScale(16)}px;
  max-width: 100%;
  width: 100%;
 `;
