/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function CalculatorsPayoffFlag(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="#000000" height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="none" height="85" width="85" />
      <Path d="M75,22.19l0-5.35V13.67a.69.69,0,0,0-.9-.67c-2.8,1.13-11.18,4.41-18.32,3.7-8.62-.86-11-4.72-19.39-5.55-9-.9-18.29,2.82-20.58,3.61a.57.57,0,0,0-.37.54V51.23a.43.43,0,0,0,.55.42c2.71-1,12.59-4.33,20.4-3.55s10.77,4.69,19.39,5.55c7.72.76,17.05-3,19-3.83a.56.56,0,0,0,.34-.53Z" fill="#bac2ca" fillRule="evenodd" />
      <Line fill="#fff" x1="72.12" x2="72.11" y1="16.46" y2="15.08" />
      <Path d="M72.12,16.76a.29.29,0,0,1-.3-.3V15.08a.31.31,0,0,1,.3-.3h0a.3.3,0,0,1,.3.3v1.38a.3.3,0,0,1-.3.3Z" />
      <Path d="M72.11,13.37V10.2a.67.67,0,0,0-.86-.67c-2.69,1.13-10.73,4.41-17.59,3.7-8.27-.85-10.54-4.72-18.6-5.55-8.68-.9-17.56,2.82-19.75,3.61a.55.55,0,0,0-.35.54V47.76a.41.41,0,0,0,.52.42c2.6-1,12.09-4.33,19.58-3.55s10.33,4.7,18.6,5.55c7.42.77,16.37-3,18.21-3.83a.55.55,0,0,0,.33-.53l-.07-27.1" fill="#fff" />
      <Path d="M55.7,50.58a20.41,20.41,0,0,1-2.08-.1,30,30,0,0,1-9.86-2.91A26.44,26.44,0,0,0,35,44.93c-7.39-.77-16.75,2.51-19.44,3.53a.63.63,0,0,1-.61-.08.75.75,0,0,1-.32-.62V11.83A.84.84,0,0,1,15.2,11l.65-.25C18.91,9.61,27,6.56,35.08,7.38a27.39,27.39,0,0,1,9.26,2.77,27.52,27.52,0,0,0,9.34,2.78c3.19.33,8.8,0,17.44-3.67a.84.84,0,0,1,.82.09,1,1,0,0,1,.46.85v3.17a.3.3,0,0,1-.3.3h0a.29.29,0,0,1-.3-.3V10.2a.41.41,0,0,0-.19-.35.25.25,0,0,0-.26,0c-7,3-13,4.21-17.73,3.72A28.16,28.16,0,0,1,44.1,10.7,26.7,26.7,0,0,0,35,8c-7.91-.82-15.93,2.21-19,3.35l-.67.24a.28.28,0,0,0-.15.26V47.76a.16.16,0,0,0,.06.13c2.78-1,12.26-4.33,19.77-3.56A27.3,27.3,0,0,1,44,47a29.49,29.49,0,0,0,9.67,2.86c7,.73,15.61-2.72,18.06-3.8a.26.26,0,0,0,.15-.26l-.07-27.1a.3.3,0,0,1,.3-.3h0a.29.29,0,0,1,.3.3l.07,27.1a.87.87,0,0,1-.5.81C69.73,47.62,62.4,50.58,55.7,50.58Z" />
      <Path d="M25.7,78.29H5a.3.3,0,1,1,0-.6H25.7a.3.3,0,0,1,0,.6Z" fill="#2c2627" />
      <Path d="M15,78.29a.29.29,0,0,1-.3-.3V15.31A.29.29,0,0,1,15,15a.3.3,0,0,1,.3.3V78A.3.3,0,0,1,15,78.29Z" fill="#2c2627" />
      <Circle cx="44.02" cy="28.86" fill={primary} r="12.4" />
      <Path d="M50.34,24.45a.39.39,0,0,1,0,.56l-8.27,8.27a.38.38,0,0,1-.29.12.41.41,0,0,1-.28-.13l-3.82-4.14a.4.4,0,0,1,0-.56.41.41,0,0,1,.57,0l3.53,3.84,8-8A.39.39,0,0,1,50.34,24.45Z" fill="#fff" fillRule="evenodd" />
      <Path d="M41.79,33.7h0a.73.73,0,0,1-.5-.23l-3.82-4.13a.7.7,0,1,1,1-.95L41.81,32l7.76-7.75a.69.69,0,1,1,1,1l-8.27,8.27A.71.71,0,0,1,41.79,33.7ZM38,28.77l-.06,0a.11.11,0,0,0,0,.14l3.82,4.14s0,0,.07,0a.09.09,0,0,0,.07,0l8.27-8.27a.1.1,0,0,0-.14-.14l-8,8a.23.23,0,0,1-.21.09.35.35,0,0,1-.22-.1L38.05,28.8A.11.11,0,0,0,38,28.77Z" fill="#fff" />
    </Svg>
  );
}
