import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  BaseInternalLink,
  H2,
  P2,
  PrimaryDivider,
  StatusBanner,
} from '../../base_ui';

export const BottomButtonWrapper = styled.View`
  align-self: center;
`;

export const ContentWrapper = styled.View`
  flex-grow: 1;
`;

export const DocPickerLinkWrapper = styled.View`
  justify-content: center;
  width: 100%;
`;

export const Header = styled(H2)`
  padding-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const HeaderSubText = styled(P2)`
  text-align: center;
`;

export const IllustrationHeaderWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(48)}px;
`;

export const IllustrationWrapper = styled.View`
  padding: ${moderateScale(20)}px ${moderateScale(20)}px ${moderateScale(16)}px;
`;

export const OuterWrapper = styled.View`
  flex-shrink: 1;
  flex: 1;
  margin: 0 ${moderateScale(24)}px ${moderateScale(35)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  align-self: center;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin-bottom: ${moderateScale(15)}px;
  max-width: 100%;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(7)}px;
`;
