import { AddLoanConfirmationScreen, AddLoanScreen } from '../../../containers/Settings/AddLoan';
import {
  ChangeMailingAddressConfirmationScreen,
  ChangeMailingAddressScreen,
  MailingAddressUploadReviewDocumentScreen,
  MailingAddressUploadScreen,
} from '../../../containers/Settings/ChangeMailingAddress';
import {
  EditEmailConfirmationScreen,
  EditEmailScreen,
} from '../../../containers/Settings/EditEmail';
import {
  EditLegalNameConfirmationScreen,
  EditLegalNameScreen,
  LegalNameUploadDocumentScreen,
  LegalNameUploadReviewDocumentScreen,
} from '../../../containers/Settings/EditLegalName';
import {
  EditMilitaryStatusConfirmationScreen,
  EditMilitaryStatusScreen,
  MilitaryStatusUploadDocumentScreen,
  MilitaryStatusUploadReviewDocumentScreen,
} from '../../../containers/Settings/EditMilitaryStatus';
import {
  EditMailingAddressDisabledFeature as EditMailingAddressDisabledFeatureScreen,
  SelectMailingAddress as SelectMailingAddressScreen,
} from '../../../containers/Settings/MailingAddresses';
import {
  EditMobileNumberConfirmationScreen,
  EditMobileNumberScreen,
  VerifyMobileNumberScreen,
} from '../../../containers/Settings/EditMobileNumber';
import LoansScreen from '../../../containers/Settings/Loans';
import NotificationsScreen from '../../../containers/Settings/Communications/Notifications';
import PaperlessScreen from '../../../containers/Settings/Communications/Paperless';
import PasswordScreen from '../../../containers/Settings/Password';
import SignInMethodScreen from '../../../containers/Settings/SignInMethod';

export default {
  AddLoan: { component: AddLoanScreen },
  AddLoanConfirmation: { component: AddLoanConfirmationScreen },
  ChangeMailingAddress: { component: ChangeMailingAddressScreen },
  ChangeMailingAddressConfirmation: { component: ChangeMailingAddressConfirmationScreen },
  CommunicationNotifications: { component: NotificationsScreen },
  EditEmail: { component: EditEmailScreen },
  EditEmailConfirmation: { component: EditEmailConfirmationScreen },
  EditLegalName: { component: EditLegalNameScreen },
  EditLegalNameConfirmation: { component: EditLegalNameConfirmationScreen },
  EditMailingAddressDisabledFeature: { component: EditMailingAddressDisabledFeatureScreen },
  EditMilitaryStatus: { component: EditMilitaryStatusScreen },
  EditMilitaryStatusConfirmation: { component: EditMilitaryStatusConfirmationScreen },
  EditMobileNumber: { component: EditMobileNumberScreen },
  EditMobileNumberConfirmation: { component: EditMobileNumberConfirmationScreen },
  LegalNameUploadDocument: { component: LegalNameUploadDocumentScreen },
  LegalNameUploadReviewDocument: { component: LegalNameUploadReviewDocumentScreen },
  Loans: { component: LoansScreen },
  MailingAddressUploadDocument: { component: MailingAddressUploadScreen },
  MailingAddressUploadReviewDocument: { component: MailingAddressUploadReviewDocumentScreen },
  MilitaryStatusUploadDocument: { component: MilitaryStatusUploadDocumentScreen },
  MilitaryStatusUploadReviewDocument: { component: MilitaryStatusUploadReviewDocumentScreen },
  Paperless: { component: PaperlessScreen },
  Password: { component: PasswordScreen },
  SelectMailingAddress: { component: SelectMailingAddressScreen },
  SignInMethod: { component: SignInMethodScreen },
  VerifyMobileNumber: { component: VerifyMobileNumberScreen },
};
