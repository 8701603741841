import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  chooseDocument,
  resetBannerError,
  resetFormState,
  resetRequestStatusState,
  uploadDocument,
} from '@dmi/shared/redux/Mortgage/actions';
import { INSURANCE_UPLOAD_DICT } from '@dmi/shared/redux/Mortgage/constants';
import {
  selectBannerError,
  selectDocumentRequestBody,
  selectForm,
  selectFormErrors,
  selectRequestStatus,
} from '@dmi/shared/redux/Mortgage/selectors';

import ReviewScene from '../../../components/DocUpload/ReviewScene';

const { uploadReviewHeaderSubText } = INSURANCE_UPLOAD_DICT;

const UploadReviewScreen = ({
  bannerError,
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  dispatchResetBannerError,
  dispatchResetFormState,
  dispatchResetRequestStatusState,
  dispatchUploadDocument,
  documentRequestBody,
  navigation,
  processDocumentRequestStatus,
  uploadDocumentForm: { fileInput: { fileName } },
  uploadDocumentFormErrors: { fileInput: validationErrorMessage },
  uploadDocumentRequestStatus,
}) => {
  useEffect(() => () => {
    dispatchResetBannerError();
    dispatchResetFormState({ form: 'uploadDocument' });
    dispatchResetRequestStatusState({ state: 'chooseDocument' });
    dispatchResetRequestStatusState({ state: 'processDocument' });
  }, [dispatchResetBannerError, dispatchResetFormState, dispatchResetRequestStatusState]);

  return (
    <ReviewScene
      bannerError={bannerError}
      bottomButtonProps={{
        label: 'Submit',
        onPress: () => dispatchUploadDocument(documentRequestBody),
        status: uploadDocumentRequestStatus,
      }}
      chooseDocumentRequestStatus={chooseDocumentRequestStatus}
      dispatchChooseDocument={dispatchChooseDocument}
      fileName={fileName}
      headerSubText={uploadReviewHeaderSubText}
      navigation={navigation}
      processDocumentRequestStatus={processDocumentRequestStatus}
      validationErrorMessage={validationErrorMessage}
    />
  );
};

UploadReviewScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  dispatchUploadDocument: T.func.isRequired,
  documentRequestBody: T.shape({
    document: T.string.isRequired,
    documentCategory: T.string.isRequired,
    documentSize: T.number.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
  processDocumentRequestStatus: T.string.isRequired,
  uploadDocumentForm: T.shape({ fileInput: T.shape({ fileName: T.string.isRequired }) }).isRequired,
  uploadDocumentFormErrors: T.shape({ fileInput: T.string.isRequired }).isRequired,
  uploadDocumentRequestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  bannerError: selectBannerError(),
  chooseDocumentRequestStatus: selectRequestStatus('chooseDocument'),
  documentRequestBody: selectDocumentRequestBody('addInsurance'),
  processDocumentRequestStatus: selectRequestStatus('processDocument'),
  uploadDocumentForm: selectForm('uploadDocument'),
  uploadDocumentFormErrors: selectFormErrors('uploadDocument'),
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Mortgage
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
  dispatchUploadDocument: (payload) => dispatch(uploadDocument(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadReviewScreen);
