import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../base_ui';
import LoadingScene from './LoadingScene';
import Scene from './Scene';

const ReviewScene = (props) => {
  const { chooseDocumentRequestStatus, processDocumentRequestStatus } = props;
  const isChoosing = chooseDocumentRequestStatus === 'loading';
  const isProcessing = processDocumentRequestStatus === 'loading';
  const isLoading = isChoosing || isProcessing;

  return (
    <ConditionalRender
      Component={Scene}
      FallbackComponent={LoadingScene}
      propsToPassDown={!isLoading ? props : { isProcessing }}
      shouldRender={!isLoading}
    />
  );
};

ReviewScene.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  processDocumentRequestStatus: T.string.isRequired,
};

export default ReviewScene;
