import React from 'react';
import T from 'prop-types';
import * as WebBrowser from 'expo-web-browser';
import { View } from 'react-native';

import { RESOURCES_DOWNLOAD_LIST, RESOURCES_ROW_LIST } from '@dmi/shared/redux/LossDraft/constants';

import iconDictionary from '../../../utils/iconDictionary';
import ContactInformationCard from './ContactCard';
import ResourceRowItem from './ResourceRowItem';
import { MainContainer, StyledView } from './styledComponents';

const DownloadIcon = iconDictionary('downloadL');
const ArrowIcon = iconDictionary('arrowRightM');

const ResourcesComponent = ({ navigation }) => {
  const handleOpenWithWebBrowser = (href) => {
    WebBrowser.openBrowserAsync(href);
  };

  return (
    <MainContainer>
      <View>
        {RESOURCES_DOWNLOAD_LIST.map(({
          ariaLabel,
          documentName,
          fileUrl,
          id,
        }) => (
          <ResourceRowItem
            key={id}
            aria-label={ariaLabel}
            documentName={documentName}
            handlePress={() => handleOpenWithWebBrowser(fileUrl)}
            IconToUse={DownloadIcon}
          />
        ))}
        {RESOURCES_ROW_LIST.map(({ label, screenToNavigateTo }) => (
          <ResourceRowItem
            key={label}
            aria-label={label}
            handlePress={() => navigation.navigate(screenToNavigateTo)}
            IconToUse={ArrowIcon}
            rowLabel={label}
          />
        ))}
      </View>
      <StyledView>
        <ContactInformationCard />
      </StyledView>
    </MainContainer>
  );
};

ResourcesComponent.propTypes = { navigation: T.object.isRequired };

export default ResourcesComponent;
