import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { Animated } from 'react-native';

import loopSequence from './loopSequence';

const PulsateWrapper = ({ children, hasWidth = false }) => {
  const opacityValue = useRef(new Animated.Value(0.5)).current;
  const width = hasWidth ? '100%' : 'auto';

  useEffect(() => {
    loopSequence(opacityValue).start();
  }, [opacityValue]);

  return (
    <Animated.View style={{ opacity: opacityValue, width }}>
      {children}
    </Animated.View>
  );
};

PulsateWrapper.propTypes = { children: T.node.isRequired, hasWidth: T.bool };

export default PulsateWrapper;
