import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import {
  Checkbox,
  ConditionalRender,
  LargePrimaryButton,
  LargeProgressButton,
} from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import Disclosures from '../../../../containers/Disclosures';
import {
  ButtonContainer,
  CheckboxContainer,
  Container,
  StyledH3,
  StyledP3,
} from './styledComponents';

/* eslint-disable max-len */
const Disclosure = ({
  disclosureRequestStatus,
  disclosures,
  dispatchChangeStep,
  dispatchOpenModal,
  dispatchPutPaperlessSetting,
  isEnrollPaperlessFlow,
  isPutPaperlessLoading,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const isDisclosureFetched =
    disclosureRequestStatus === 'succeeded' ||
    !!disclosures.estatement;
  const isCheckboxDisabled = isPutPaperlessLoading || !isDisclosureFetched;

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => isPutPaperlessLoading ? null : dispatchChangeStep(1)}
        title="Paperless Terms & Conditions"
      />
      <Container>
        <StyledH3>E-Statement Policy</StyledH3>
        <Disclosures disclosureName="estatement" />
        <CheckboxContainer>
          <Checkbox
            checked={isChecked}
            onPress={() => isCheckboxDisabled ? null : setIsChecked(!isChecked)}
          />
          <StyledP3>I agree to the terms and conditions as outlined above</StyledP3>
        </CheckboxContainer>
        <ButtonContainer>
          <ConditionalRender
            Component={(
              <LargeProgressButton
                aria-label="accept-terms"
                disabled={!isChecked}
                label="Accept Terms"
                loading={isPutPaperlessLoading}
                onPress={dispatchPutPaperlessSetting}
              />
            )}
            FallbackComponent={(
              <LargePrimaryButton
                aria-label="accept-terms"
                disabled={!isChecked}
                label="Accept Terms"
                onPress={() => dispatchOpenModal({ modalState: 'unenrollPaperless' })}
              />
            )}
            shouldRender={isEnrollPaperlessFlow}
          />
        </ButtonContainer>
      </Container>
    </Fragment>
  );
};

Disclosure.propTypes = {
  disclosureRequestStatus: T.string.isRequired,
  disclosures: T.object.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchOpenModal: T.func.isRequired,
  dispatchPutPaperlessSetting: T.func.isRequired,
  isEnrollPaperlessFlow: T.bool.isRequired,
  isPutPaperlessLoading: T.bool.isRequired,
};

export default Disclosure;
