import styled from 'styled-components/native';

import { StyledScrollView as ScrollView } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const RowView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-direction: row;
  justify-content: ${({ isFilter }) => isFilter ? 'space-between' : 'flex-start'};
  padding: 0 ${moderateScale(24)}px;
  width: 100%;
`;

export const SkeletonView = styled.View`
  margin-right: ${moderateScale(17)}px;
  width: ${({ $widthPercent }) => `${$widthPercent}%`};
`;

export const StyledScrollView = styled(ScrollView)`
  width: 100%;
`;
