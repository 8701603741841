import styled from 'styled-components/native';
import HTML from 'react-native-render-html';

import { P3 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const Container = styled.View`
  padding-vertical: ${moderateScale(20)}px;
`;

export const Footer = styled(P3)`
  padding: ${moderateScale(20)}px 0;
  text-align: right;
`;

export const StyledHTML = styled(HTML).attrs(({
  theme: {
    colors: { primaryCarbon },
    fontFamilies: { secondaryFont },
    fontSizes: { f3 },
  },
}) => ({
  baseFontStyle: {
    color: primaryCarbon,
    fontFamily: secondaryFont,
    fontSize: f3,
    lineHeight: moderateScale(22),
  },
}))``;
