import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  BorderlessButton,
  H5,
  P2,
  PrimaryDivider,
} from '../../../base_ui';

export const ContentContainer = styled.View`
  flex-grow: 1;
  margin-top: ${moderateScale(24)}px;
`;

export const InfoSectionContainer = styled.View`
  margin: ${moderateScale(16)}px ${moderateScale(24)}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  max-width: auto;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: { fontFamilies: { secondaryFont } },
  titleStyle,
}) => ({
  titleStyle: [
    titleStyle,
    { fontFamily: secondaryFont },
  ],
}))`
  margin: ${moderateScale(37)}px auto 0;
`;

export const TooltipHeading = styled(H5)`
  max-width: auto;
`;

export const TooltipLink = styled(P2)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  padding-top: ${moderateScale(4)}px;
`;
