import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Communications/messages';
import { INFORMATION_NOT_AVAILABLE } from '@dmi/shared/utils/constants';

import iconDictionary from '../../../../../../utils/iconDictionary';
import {
  BaseToggle,
  ConditionalRender,
  P3,
} from '../../../../../base_ui';
import {
  Container,
  Heading,
  HeadingContainer,
  PaperlessHeaderContainer,
  PaperlessHeading,
  PaperlessHeadingContainer,
  StyledPressable,
  StyledPrimaryDivider,
  ToggleContainer,
  ToggleLabel,
  UnavailableContainer,
} from './styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');
const LoanIcon = iconDictionary('navMortgageDefaultM');

const UniqueContent = ({
  addressData,
  handleTogglePress,
  isToggleDisabled,
  isToggleOn,
  loanNumber,
  setIsTooltipOpen,
  toggleText,
}) => {
  const [isPressed, setIsPressed] = useState(false);

  return (
    <Container>
      <HeadingContainer>
        {LoanIcon}
        <Heading>
          <FormattedMessage
            id={INTL_IDS.PAPERLESS_LOANCARD_HEADING}
            values={{ loanNumber }}
          />
        </Heading>
      </HeadingContainer>
      <ConditionalRender
        Component={(
          <Fragment>
            <P3>{addressData[0]}</P3>
            <P3>{addressData[1]}</P3>
          </Fragment>
        )}
        FallbackComponent={(
          <UnavailableContainer>
            <P3>{INFORMATION_NOT_AVAILABLE}</P3>
          </UnavailableContainer>
        )}
        shouldRender={!!addressData.length}
      />
      <StyledPrimaryDivider />
      <PaperlessHeaderContainer>
        <PaperlessHeadingContainer>
          <PaperlessHeading>Paperless Billing</PaperlessHeading>
          <StyledPressable
            $pressed={isPressed}
            onPress={() => setIsTooltipOpen(true)}
            onPressIn={() => setIsPressed(true)}
            onPressOut={() => setIsPressed(false)}
          >
            {InfoIcon}
          </StyledPressable>
        </PaperlessHeadingContainer>
        <ToggleContainer>
          <ToggleLabel>{toggleText}</ToggleLabel>
          <BaseToggle
            ariaLabel="toggle-paperless"
            disabled={isToggleDisabled}
            isActive={isToggleOn}
            onPress={handleTogglePress}
          />
        </ToggleContainer>
      </PaperlessHeaderContainer>
    </Container>
  );
};

UniqueContent.propTypes = {
  addressData: T.arrayOf(T.string).isRequired,
  handleTogglePress: T.func.isRequired,
  isToggleDisabled: T.bool.isRequired,
  isToggleOn: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  setIsTooltipOpen: T.func.isRequired,
  toggleText: T.string.isRequired,
};

export default UniqueContent;
