/* eslint-disable max-len */
import ClaimCenterScreen from '../../../containers/LossDraft';
import ClaimDetailsScreen from '../../../containers/LossDraft/ClaimDetails';
import ClaimsProcessScreen from '../../../components/LossDraft/Resources/ClaimsProcess';
import MessageDetailsScreen from '../../../containers/LossDraft/MessageDetails';
import NewMessageScreen from '../../../containers/LossDraft/NewMessage';
import WhereToSubmitDocumentsScreen from
'../../../components/LossDraft/Resources/WhereToSubmitDocuments';

export default {
  LossDraft: { component: ClaimCenterScreen },
  LossDraftClaimDetails: { component: ClaimDetailsScreen },
  LossDraftClaimsProcess: { component: ClaimsProcessScreen },
  LossDraftMessageDetails: { component: MessageDetailsScreen },
  LossDraftNewMessage: { component: NewMessageScreen },
  LossDraftWhereToSubmitDocuments: { component: WhereToSubmitDocumentsScreen },
};
