/* eslint-disable max-len */
import React from 'react';
import
Svg, {
  G,
  Path,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function TornadoIcon(props) {
  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <G>
        <Path d="M9.6,8.1c-1.9,0-5.1-0.4-5.1-1.9c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6c0.1,0.2,1.4,0.7,3.9,0.7c2.2,0,3.5-0.5,3.8-0.7c-0.2-0.2-1-0.5-2.3-0.7c-0.3,0-0.6-0.3-0.5-0.7c0-0.3,0.3-0.6,0.7-0.5c1.3,0.1,3.4,0.6,3.4,1.8C14.6,7.7,11.4,8.1,9.6,8.1z" />
        <Path d="M10.1,10.5c-1.6,0-4.4-0.4-4.4-1.8c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6C7,8.8,8.1,9.3,10.1,9.3c1.7,0,2.8-0.4,3.1-0.6c-0.2-0.2-1-0.5-2.3-0.6c-0.3,0-0.6-0.3-0.5-0.6c0-0.3,0.3-0.6,0.6-0.5c0.8,0.1,3.4,0.4,3.4,1.7C14.5,10.1,11.7,10.5,10.1,10.5z M13.3,8.8L13.3,8.8L13.3,8.8z" />
        <Path d="M9.5,12.8c-1.6,0-3.5-0.4-3.5-1.5c0-0.3,0.3-0.6,0.6-0.6c0.3,0,0.5,0.2,0.6,0.5c0.2,0.2,1,0.5,2.3,0.5c1.1,0,2-0.2,2.3-0.4c0.3-0.2,0.6-0.1,0.8,0.2c0.2,0.3,0.1,0.6-0.2,0.8C11.8,12.5,10.7,12.8,9.5,12.8z M7.1,11.2C7.1,11.2,7.1,11.2,7.1,11.2C7.1,11.2,7.1,11.2,7.1,11.2z" />
        <Path d="M8.6,5.6c-2.7,0-5.6-0.7-5.6-2.2c0-1.5,2.9-2.2,5.6-2.2s5.6,0.7,5.6,2.2C14.2,4.9,11.3,5.6,8.6,5.6z M8.6,2.4c-2.9,0-4.4,0.7-4.5,1c0.1,0.3,1.6,1,4.5,1c2.8,0,4.3-0.7,4.5-1C12.9,3.1,11.4,2.4,8.6,2.4z M13,3.4L13,3.4L13,3.4z" />
        <Path d="M8.7,16.7c-0.4,0-0.6-0.1-0.7-0.1c-0.3-0.1-0.4-0.5-0.3-0.8c0.1-0.3,0.5-0.4,0.8-0.3c0,0,0.2,0.1,0.5,0c0.3-0.1,0.6,0.1,0.7,0.4c0.1,0.3-0.1,0.6-0.4,0.7C9,16.7,8.8,16.7,8.7,16.7z" />
        <Path d="M8.9,14.8c-1.3,0-1.9-0.3-2-0.3c-0.3-0.1-0.4-0.5-0.3-0.8c0.1-0.3,0.5-0.4,0.8-0.3c0,0,1,0.4,2.9,0.1c0.3-0.1,0.6,0.2,0.7,0.5s-0.2,0.6-0.5,0.7C9.9,14.8,9.4,14.8,8.9,14.8z" />
      </G>
    </Svg>
  );
}
