import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { useIntl } from 'react-intl';

import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';
import iconDictionary from '../../utils/iconDictionary';
import {
  ConditionalRender,
  ErrorSuccessBanner,
  MemoizedNativeAnimation,
  MemoizedWebAnimation,
} from '../base_ui';
import { PasswordTooltip } from './Tooltips';
import {
  MediaWrapper,
  RegisterContent,
  RegisterHeader,
  RegisterP3,
  RegisterText,
  StyledBorderlessButton,
} from './styledComponents';

const SharedForm = ({
  bannerError,
  currentEmail,
  formInputProps,
  handleChangeEmail,
  handleScrollToBanner,
  isChangeEmail,
  viewCardProps: {
    cardAnimation,
    cardIllustration,
    cardLinkText,
    cardText,
    cardTitle,
    cardTooltip,
    displayUsernameCriteria,
  },
  ViewFormInput,
}) => {
  const { formatMessage, messages } = useIntl();
  useEffect(() => {
    if (bannerError) {
      handleScrollToBanner();
    }
  }, [bannerError, handleScrollToBanner]);

  const resolvedIntlText = resolveIntlMessage({
    formatMessage,
    message: cardText,
    messages,
    values: { currentEmail },
  });
  const resolvedIntlHeader = resolveIntlMessage({
    formatMessage,
    message: cardTitle,
    messages,
  });

  const registerSubHeaderTextToUse = cardText ? resolvedIntlText : '';

  let MediaToDisplay;
  if (cardAnimation) {
    MediaToDisplay = (
      <ConditionalRender
        Component={<MemoizedWebAnimation name={cardAnimation.web} />}
        FallbackComponent={<MemoizedNativeAnimation name={cardAnimation.mobile} />}
        shouldRender={Platform.OS === 'web'}
      />
    );
  }
  if (cardIllustration) {
    const illustration = Platform.OS === 'web' ? cardIllustration.web : cardIllustration.mobile;
    MediaToDisplay = iconDictionary(illustration);
  }

  return (
    <Fragment>
      <ErrorSuccessBanner
        error={resolveIntlMessage({
          formatMessage,
          message: bannerError,
          messages,
        })}
        success={false}
      />
      <MediaWrapper>
        {MediaToDisplay}
      </MediaWrapper>
      <RegisterContent>
        <RegisterText>
          <RegisterHeader>{resolvedIntlHeader}</RegisterHeader>
          <ConditionalRender
            Component={RegisterP3}
            propsToPassDown={{ children: registerSubHeaderTextToUse }}
            shouldRender={!!cardText}
          />
          <ConditionalRender
            Component={StyledBorderlessButton}
            propsToPassDown={{
              label: cardLinkText,
              onPress: handleChangeEmail,
            }}
            shouldRender={!isChangeEmail && !!cardLinkText}
          />
          <ConditionalRender
            Component={PasswordTooltip}
            propsToPassDown={{ displayUsernameCriteria }}
            shouldRender={cardTooltip}
          />
        </RegisterText>
        <ViewFormInput {...formInputProps} />
      </RegisterContent>
    </Fragment>
  );
};

SharedForm.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  currentEmail: T.string,
  formInputProps: T.object.isRequired,
  handleChangeEmail: T.func,
  handleScrollToBanner: T.func.isRequired,
  isChangeEmail: T.bool,
  viewCardProps: T.shape({
    cardAnimation: T.object,
    cardIllustration: T.object,
    cardLinkText: T.string,
    cardText: T.string,
    cardTitle: T.string.isRequired,
    cardTooltip: T.bool,
    displayUsernameCriteria: T.bool,
  }).isRequired,
  ViewFormInput: T.elementType.isRequired,
};

export default SharedForm;
