import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import { ConditionalRender } from '../../base_ui';
import { getProperBiometricIcon } from './helpers';
import {
  BiometricPinButtonGroupWrapper,
  StyledBorderlessButton,
  StyledIconButton,
} from './styledComponents';

const arrowRightIcon = iconDictionary(
  'arrowRightCarbonS',
  { height: moderateScale(11), width: moderateScale(11) },
);

const BiometricPinButtonGroup = ({
  dispatchBiometricLoginRequest,
  dispatchOpenPinDrawer,
  isBiometricLoginEnabled,
  isPinEnabled,
  supportedBiometricAuthenticationTypes,
}) => {
  const biometricIcon = getProperBiometricIcon(supportedBiometricAuthenticationTypes);

  return (
    <BiometricPinButtonGroupWrapper
      $hasAnyButtons={isBiometricLoginEnabled || isPinEnabled}
      $hasMultipleButtons={isBiometricLoginEnabled && isPinEnabled}
    >
      <ConditionalRender
        Component={(
          <StyledBorderlessButton
            Icon={arrowRightIcon}
            iconPosition="right"
            label="Log in with PIN"
            onPress={dispatchOpenPinDrawer}
          />
        )}
        shouldRender={isPinEnabled}
      />
      <ConditionalRender
        Component={(
          <StyledIconButton
            aria-label="Login with biometrics"
            Icon={biometricIcon}
            onPress={dispatchBiometricLoginRequest}
          />
        )}
        shouldRender={isBiometricLoginEnabled}
      />
    </BiometricPinButtonGroupWrapper>
  );
};

BiometricPinButtonGroup.propTypes = {
  dispatchBiometricLoginRequest: T.func.isRequired,
  dispatchOpenPinDrawer: T.func.isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
};

export default BiometricPinButtonGroup;
