import React from 'react';
import { Text } from 'react-native';
import T from 'prop-types';

import { ConditionalRender } from '../../../../../base_ui';

import {
  StyledMessageTextButton,
  StyledTextButton,
} from './styledComponents';

/* eslint-disable react/no-array-index-key */
const Button = ({ inlineBlocks }) => (
  inlineBlocks.map(({ buttonPrefixText, handleButton, text }, i) => (
    <ConditionalRender
      key={i}
      Component={(
        <StyledMessageTextButton>
          <Text>{buttonPrefixText}</Text>
          <StyledTextButton
            label={text}
            onPress={handleButton}
          />
        </StyledMessageTextButton>
      )}
      FallbackComponent={(
        <StyledTextButton
          label={text}
          onPress={handleButton}
        />
      )}
      shouldRender={!!buttonPrefixText}
    />
  ))
);

Button.propTypes = {
  inlineBlocks: T.arrayOf(T.shape({
    buttonPrefixText: T.string,
    handleButton: T.func,
    text: T.string,
  })).isRequired,
};

export default Button;
