import styled from 'styled-components/native';
import { Text, View } from 'react-native';

export const StyledLabel = styled(View)`
  align-items: center;
  flex-direction: row;
  justify-content: center;
`;

export const StyledText = styled(Text)`
  align-self: center;
  justify-content: center;
`;
