import React from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { ConditionalRender } from '../../base_ui';
import { Container, StyledBorderlessButton } from './styledComponents';

const SiteMapLink = ({ navigation }) => (
  <Container>
    <ConditionalRender
      Component={(
        <StyledBorderlessButton
          isFooterButton
          isPrimary
          label="Site Map"
          onPress={() => navigation.navigate('SiteMap')}
        />
      )}
      shouldRender={Platform.OS === 'web'}
    />
  </Container>
);

SiteMapLink.propTypes = { navigation: T.object.isRequired };

export default SiteMapLink;
