import { STORAGE_KEY_LOSS_DRAFT_READ_CLAIM_NUMBERS } from '@dmi/shared/redux/Main/constants';
import AsyncStorage from '@dmi/shared/utils/AsyncStorage';

export const storeReadClaimNumber = async (claimNumber) => {
  const readClaimNumbers = JSON.parse(
    await AsyncStorage.getItem(STORAGE_KEY_LOSS_DRAFT_READ_CLAIM_NUMBERS),
  ) || [];
  if (!readClaimNumbers.includes(claimNumber)) {
    readClaimNumbers.push(claimNumber);
    await AsyncStorage.setItem(
      STORAGE_KEY_LOSS_DRAFT_READ_CLAIM_NUMBERS,
      JSON.stringify(readClaimNumbers),
    );
  }
};
