import React, { Fragment } from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../base_ui';
import {
  AbsoluteContainer,
  BackButton,
  DownloadButton,
  StyledPrimaryDivider,
  StyledTitle,
  TertiaryHeaderContent,
} from './styledComponents';
import iconDictionary from '../../utils/iconDictionary';

const arrowLeftM = iconDictionary('arrowLeftM');
const downloadL = iconDictionary('downloadL');

const TertiaryHeader = ({
  handleBack,
  handleDownload,
  title,
  ...restProps
}) => (
  <Fragment>
    <TertiaryHeaderContent {...restProps}>
      <BackButton
        aria-label="go back"
        Icon={arrowLeftM}
        onPress={handleBack}
      />
      <AbsoluteContainer>
        <StyledTitle>{title}</StyledTitle>
      </AbsoluteContainer>
      <ConditionalRender
        Component={DownloadButton}
        propsToPassDown={{
          $renderDefault: true,
          ariaLabel: 'download',
          Icon: downloadL,
          onPress: handleDownload,
        }}
        shouldRender={!!handleDownload}
      />
    </TertiaryHeaderContent>
    <StyledPrimaryDivider />
  </Fragment>
);

TertiaryHeader.propTypes = {
  handleBack: T.func.isRequired,
  handleDownload: T.func,
  title: T.string.isRequired,
};

export default TertiaryHeader;
