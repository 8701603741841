import React, { Fragment } from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { NEED_SPECIALIST_ERROR } from '@dmi/shared/redux/Payoff/constants';

import { ConditionalRender, SecondaryDivider } from '../base_ui';
import AsyncRender from '../AsyncRender';
import PayoffHistoryCard from './PayoffHistory';
import PayoffTopCard from './PayoffTopCard';
import RetrievingStatement from './RetrievingStatement';
import { moderateScale } from '../../utils/scaleHelpers';

const PayoffHomeScreen = ({
  availablePayOffReasons,
  createStatementLoading,
  dateSelectorIsDisabled,
  dispatchChangePayoffStep,
  dispatchClearStatement,
  dispatchCreatePayoffStatement,
  dispatchUpdateRequestType,
  dispatchUpdateSelectedDate,
  dispatchUpdateSelectedPayOffReason,
  fetchStatementLoading,
  handleOnPressRow,
  homeButtonsView,
  isIneligible,
  isLoanMancodeY,
  isPastServicingPaymentCutOffDate,
  isPayDownAvailable,
  loanNumber,
  navigation,
  needSpecialist,
  payoffHistoryData,
  payoffRequestBody,
  payoffStep,
  requestType,
  selectedDate,
  statement,
}) => {
  const { formatMessage } = useIntl();

  const Component = () => (
    <ConditionalRender
      Component={PayoffTopCard}
      FallbackComponent={RetrievingStatement}
      propsToPassDown={{
        availablePayOffReasons,
        dateSelectorIsDisabled,
        dispatchChangePayoffStep,
        dispatchClearStatement,
        dispatchCreatePayoffStatement,
        dispatchUpdateRequestType,
        dispatchUpdateSelectedDate,
        dispatchUpdateSelectedPayOffReason,
        homeButtonsView,
        isIneligible,
        isLoanMancodeY,
        isPastServicingPaymentCutOffDate,
        isPayDownAvailable,
        loanNumber,
        navigation,
        payoffRequestBody,
        payoffStep,
        requestType,
        selectedDate,
        statement,
      }}
      shouldRender={!fetchStatementLoading && !createStatementLoading}
    />
  );
  const { body, title } = NEED_SPECIALIST_ERROR;
  const isPaydownRequest = requestType === 'paydown';

  return (
    <Fragment>
      <AsyncRender
        Component={Component}
        error={needSpecialist}
        errorComponentProps={{
          body: formatMessage({ id: body }, { isPaydownRequest }),
          bodySize: 'small',
          marginBottom: moderateScale(54),
          title: formatMessage({ id: title }, { isPaydownRequest }),
        }}
        errorComponentType="cardLevel"
        loading={false}
      />
      <SecondaryDivider />
      <PayoffHistoryCard
        data={payoffHistoryData}
        handleOnPressRow={handleOnPressRow}
        isPastServicingPaymentCutOffDate={isPastServicingPaymentCutOffDate}
        isPayDownAvailable={isPayDownAvailable}
      />
    </Fragment>
  );
};

PayoffHomeScreen.propTypes = {
  availablePayOffReasons: T.array.isRequired,
  createStatementLoading: T.bool.isRequired,
  dateSelectorIsDisabled: T.bool.isRequired,
  dispatchChangePayoffStep: T.func.isRequired,
  dispatchClearStatement: T.func.isRequired,
  dispatchCreatePayoffStatement: T.func.isRequired,
  dispatchUpdateRequestType: T.func.isRequired,
  dispatchUpdateSelectedDate: T.func.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  fetchStatementLoading: T.bool.isRequired,
  handleOnPressRow: T.func.isRequired,
  homeButtonsView: T.string.isRequired,
  isIneligible: T.bool.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  isPayDownAvailable: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  needSpecialist: T.bool.isRequired,
  payoffHistoryData: T.array.isRequired,
  payoffRequestBody: T.object.isRequired,
  payoffStep: T.number.isRequired,
  requestType: T.string.isRequired,
  selectedDate: T.string.isRequired,
  statement: T.shape({
    document: T.string.isRequired,
    mobileDocument: T.oneOfType([T.object, T.string]).isRequired,
  }),
};

export default PayoffHomeScreen;
