import styled from 'styled-components/native';

export const SkeletonBlock = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${({ $borderRadius }) => $borderRadius || 0};
  height: ${({ $height }) => $height};
  margin: ${({
    $bottomMargin,
    $leftMargin,
    $rightMargin,
    $topMargin,
  }) => `${$topMargin || 0} ${$rightMargin || 0} ${$bottomMargin || 0} ${$leftMargin || 0}`};
  text-align: center;
  width: ${({ $width }) => $width};
`;
