import styled, { css } from 'styled-components/native';

import { H2, P2, P3 } from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

const commonBodyTextStyles = css`
  text-align: center;
  ${({ isSecureMessage }) => isSecureMessage && `
    padding: 0 ${moderateScale(11)}px;
  `}
`;

export const IconWrapper = styled.View`
  padding-bottom: ${moderateScale(16)}px;
`;

export const LinkWrapper = styled.View`
  min-height: ${moderateScale(34)}px;
  padding-top: ${moderateScale(24)}px;
`;

export const MediumBodyText = styled(P2)`
  ${commonBodyTextStyles}
`;

export const SmallBodyText = styled(P3)`
  ${commonBodyTextStyles}
`;

/**
 * Meant to sandwich the main wrapped content to allow the Message to accomodate certain vertical
 * positioning when inside a tall or flexGrow container, namely:
 * 1) Stay vertically centered as long as the container is tall enough to fit the content and
 * top/bottom spacers up to max-height.
 * 2) Once the spacers max-height has been reached, keep the Message close to the top rather
 * than continuing to be vertically centered.
 */
export const Spacing = styled.View`
  flex-grow: 1;
  max-height: ${moderateScale(120)}px;
`;

export const Title = styled(H2)`
  padding-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const Wrapper = styled.View`
  align-items: center;
  align-self: center;
  flex-grow: 1;
  margin: 0 auto;
  max-width: ${moderateScale(327)}px;
  padding:  ${moderateScale(20)}px ${moderateScale(10)}px;
  width: 100%;
  ${({ isSecureMessage }) => isSecureMessage && `
    padding-top: ${moderateScale(2)}px;
  `}
`;
