import React, { useState } from 'react';
import { Linking } from 'react-native';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectHardshipAssistancePhoneNumber } from '@dmi/shared/redux/Help/selectors';
import { getMainClientInfo, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import HardshipView from '../../components/Help/HardshipAssistance';

const HardshipAssistanceScreen = ({
  hardshipAssistancePhoneNumber,
  isMurabahaAccount,
  navigation,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [selectedTooltip, setSelectedTooltip] = useState('');

  const tooltipProps = {
    handleCloseTooltip: () => {
      setIsTooltipOpen(false);
      setSelectedTooltip('');
    },
    handleOpenTooltip: (name) => {
      setIsTooltipOpen(true);
      setSelectedTooltip(name);
    },
    isTooltipOpen,
    selectedTooltip,
  };

  const handleCallLinking = () => {
    const phoneNumber = hardshipAssistancePhoneNumber.replace(/\+|-/g, '');
    Linking.openURL(`tel://${phoneNumber}`);
  };

  return (
    <HardshipView
      handleCallLinking={handleCallLinking}
      hardshipAssistancePhoneNumber={hardshipAssistancePhoneNumber}
      isMurabahaAccount={isMurabahaAccount}
      navigation={navigation}
      tooltipProps={tooltipProps}
    />
  );
};

HardshipAssistanceScreen.propTypes = {
  collectionsPhoneNumber: T.shape({ text: T.string, url: T.string }),
  hardshipAssistancePhoneNumber: T.string.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  /**
   * Store: Help
   */
  // eslint-disable-next-line sort-keys
  hardshipAssistancePhoneNumber: selectHardshipAssistancePhoneNumber(),
});

const withConnect = connect(mapStateToProps, null);

export default compose(
  withConnect,
)(HardshipAssistanceScreen);
