/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';

import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function Piggy(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="none" height={`${moderateScale(85)}`} viewBox="0 0 85 85" xmlns="http://www.w3.org/2000/svg" {...props} width={`${moderateScale(85)}`}>
      <G clipPath="url(#clip0_28722_238460)">
        <Path d="M21.8008 25.9992V14.6992H23.4008C28.2008 14.6992 32.0008 18.5992 32.0008 23.2992V26.0992" fill="#BAC2CA" />
        <Path d="M21.8008 20.7992V14.6992H23.4008C28.2008 14.6992 32.0008 18.5992 32.0008 23.2992V26.0992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M33.9992 79.4H29.0992C27.9992 79.4 27.1992 78.5 27.1992 77.5V61.5" fill="#BAC2CA" />
        <Path d="M33.9992 79.3992H29.0992C27.9992 79.3992 27.1992 78.4992 27.1992 77.4992V68.1992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M59.4016 79.4H54.5016C53.4016 79.4 52.6016 78.5 52.6016 77.5V61.5" fill="#BAC2CA" />
        <Path d="M59.4016 79.3992H54.5016C53.4016 79.3992 52.6016 78.4992 52.6016 77.4992V68.1992" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M56.1992 19.5H34.0992C24.2992 19.5 15.9992 26.2 13.5992 35.3C12.8992 37.2 11.0992 38.6 8.89922 38.6H8.49922C7.19922 38.6 6.19922 39.6 6.19922 40.9V44.8V44.9V48.8C6.19922 50.1 7.19922 51.1 8.49922 51.1H8.89922C10.9992 51.1 12.6992 52.4 13.4992 54.2C15.3992 62 21.4992 68.1 29.2992 69.9C29.4992 69.9 30.7992 70.1 30.7992 70.3V77C30.7992 78.3 31.7992 79.3 33.0992 79.3H36.5992C37.8992 79.3 38.8992 78.3 38.8992 77V73C38.8992 71.5 40.0992 70.3 41.5992 70.3H53.9992C55.4992 70.3 56.6992 71.5 56.6992 73V77C56.6992 78.3 57.6992 79.3 58.9992 79.3H62.1992C63.4992 79.3 64.4992 78.3 64.4992 77V70C64.4992 69.8 64.5992 69.6 64.7992 69.6C73.8992 67.2 79.3992 58.9 79.3992 49V42.5C79.1992 29.8 68.8992 19.5 56.1992 19.5Z" fill="white" />
        <Path d="M27.8016 63.4992C26.8016 63.0992 25.7016 62.5992 24.4016 61.7992C24.3016 61.7992 24.3016 61.6992 24.2016 61.6992C20.6016 59.8992 17.8016 56.4992 16.4016 55.0992C15.7016 54.3992 14.6016 53.4992 13.6016 52.6992C15.1016 59.9992 19.4016 67.8992 30.8016 70.2992L30.6016 67.3992C30.5016 65.6992 29.4016 64.0992 27.8016 63.4992Z" fill="#E6EBEF" />
        <Path d="M66.6013 62.0988C71.0013 59.5988 79.5013 52.8988 78.4013 38.4988L77.8013 36.2988C77.8013 36.2988 86.1013 61.6988 64.4013 69.2988L64.3013 66.3988C64.2013 64.6988 65.0013 62.9988 66.6013 62.0988Z" fill="#E6EBEF" />
        <Path d="M8.2 50.9996H7.4C6.1 50.9996 5 49.8996 5 48.5996V40.9996C5 39.6996 6.1 38.5996 7.4 38.5996H8.2C9.5 38.5996 10.6 39.6996 10.6 40.9996V48.5996C10.7 49.8996 9.6 50.9996 8.2 50.9996Z" fill="white" />
        <Path d="M21.7008 35.8C22.474 35.8 23.1008 35.1732 23.1008 34.4C23.1008 33.6268 22.474 33 21.7008 33C20.9276 33 20.3008 33.6268 20.3008 34.4C20.3008 35.1732 20.9276 35.8 21.7008 35.8Z" fill="black" />
        <Path d="M48.8008 70.3996H53.9008C55.4008 70.3996 56.6008 71.5996 56.6008 73.0996V77.0996C56.6008 78.3996 57.6008 79.3996 58.9008 79.3996H62.1008C63.4008 79.3996 64.4008 78.3996 64.4008 77.0996V70.0996" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M30.8984 70.1992V76.9992C30.8984 78.2992 31.8984 79.2992 33.1984 79.2992H36.3984C37.6984 79.2992 38.6984 78.2992 38.6984 76.9992V72.9992C38.6984 71.4992 39.8984 70.2992 41.3984 70.2992H45.9984" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M75.3992 29.9C71.2992 23.7 64.1992 19.5 56.1992 19.5H34.0992C24.2992 19.5 15.9992 26.2 13.5992 35.3C12.8992 37.2 11.0992 38.6 8.89922 38.6H8.49922C7.19922 38.6 6.19922 39.6 6.19922 40.9V44.8V44.9V48.8C6.19922 50.1 7.19922 51.1 8.49922 51.1H8.89922C10.9992 51.1 12.6992 52.4 13.4992 54.2C15.3992 61.8 21.0992 67.7 28.6992 69.6" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M66.8008 68.3996C74.1008 64.9996 79.2008 57.6996 79.2008 49.0996V42.5996C79.2008 38.7996 78.3008 35.1996 76.6008 32.0996" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M30.8984 70.4V66.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M64.3984 70.4V66.5" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M10.9992 40.2988C11.0992 40.4988 11.0992 40.7988 11.0992 40.9988V48.3988C11.0992 49.6988 9.99922 50.7988 8.69922 50.7988" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path d="M7.80078 43.5V45.7" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path d="M9.39844 43.5V45.7" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path d="M26.6992 24.2004V12.9004H28.2992C33.0992 12.9004 36.8992 16.8004 36.8992 21.5004V24.3004" fill="white" />
        <Path d="M26.6992 24.2004V12.9004H28.2992C33.0992 12.9004 36.8992 16.8004 36.8992 21.5004V24.3004" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
        <Path d="M56.6992 25.5988H42.7992C42.1992 25.5988 41.6992 25.0988 41.6992 24.4988V24.3988C41.6992 23.7988 42.1992 23.2988 42.7992 23.2988H56.6992C57.2992 23.2988 57.7992 23.7988 57.7992 24.3988V24.4988C57.7992 25.0988 57.2992 25.5988 56.6992 25.5988Z" fill="#9A87B5" />
        <Path d="M56.6992 25.5988H42.7992C42.1992 25.5988 41.6992 25.0988 41.6992 24.4988V24.3988C41.6992 23.7988 42.1992 23.2988 42.7992 23.2988H56.6992C57.2992 23.2988 57.7992 23.7988 57.7992 24.3988V24.4988C57.7992 25.0988 57.2992 25.5988 56.6992 25.5988Z" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Path d="M49.5984 20.9996C53.851 20.9996 57.2984 17.5522 57.2984 13.2996C57.2984 9.04702 53.851 5.59961 49.5984 5.59961C45.3458 5.59961 41.8984 9.04702 41.8984 13.2996C41.8984 17.5522 45.3458 20.9996 49.5984 20.9996Z" fill={svgHighlight} />
        <Path d="M41.8984 13.2996C41.8984 17.5996 45.3984 20.9996 49.5984 20.9996C53.7984 20.9996 57.2984 17.4996 57.2984 13.2996C57.2984 9.09961 53.7984 5.59961 49.5984 5.59961C46.1984 5.59961 43.2984 7.79961 42.2984 10.8996" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.1823" />
      </G>
      <Defs>
        <ClipPath id="clip0_28722_238460">
          <Rect fill="white" height="74.9" transform="translate(5 5)" width="74.8" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
