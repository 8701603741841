import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import { formatDate } from '@dmi/shared/utils/globalHelpers';

import {
  Bullet,
  BulletWrapper,
  DividerRowView,
  PressableRow,
  RowCreatorSubjectView,
  RowText,
  RowTextDate,
  RowView,
  StyledPrimaryDivider,
} from '../../styledComponents';
import { ConditionalRender } from '../../../../../base_ui';

const DataItem = ({
  author,
  handleRowPress,
  messageId,
  subject,
  unreadReplyCount,
  updated,
}) => {
  const [isPressing, setIsPressing] = useState(false);
  const createdFormated = formatDate(updated, 'UTC', 'MM/DD/YY');

  return (
    <Fragment>
      <PressableRow
        $isPressing={isPressing}
        aria-label={`view message details, ${subject}, ${updated}`}
        onPress={() => handleRowPress(messageId)}
        onPressIn={() => setIsPressing(true)}
        onPressOut={() => setIsPressing(false)}
        role="button"
      >
        <RowView>
          <RowCreatorSubjectView>
            <RowText $isBold={!!unreadReplyCount}>
              <ConditionalRender
                Component={(
                  <BulletWrapper>
                    <Bullet />
                  </BulletWrapper>
                )}
                shouldRender={!!unreadReplyCount}
              />
              {author}
            </RowText>
            <RowText $isBold={!!unreadReplyCount}>{subject}</RowText>
          </RowCreatorSubjectView>
          <RowTextDate $isBold={!!unreadReplyCount}>{createdFormated}</RowTextDate>
        </RowView>
      </PressableRow>
      <DividerRowView>
        <StyledPrimaryDivider />
      </DividerRowView>
    </Fragment>
  );
};

DataItem.propTypes = {
  author: T.string.isRequired,
  handleRowPress: T.func.isRequired,
  messageId: T.string.isRequired,
  subject: T.string.isRequired,
  unreadReplyCount: T.number.isRequired,
  updated: T.string.isRequired,
};

export default DataItem;
