/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function OutlinedHouseSummer(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      height={moderateScale(64)}
      viewBox="0 0 96 64"
      width={moderateScale(96)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Defs>
        <ClipPath id="clip1">
          <Path d="M 25 39 L 50 39 L 50 63.695312 L 25 63.695312 Z M 25 39 " />
        </ClipPath>
        <ClipPath id="clip2">
          <Path d="M 38 47 L 45 47 L 45 63.695312 L 38 63.695312 Z M 38 47 " />
        </ClipPath>
        <ClipPath id="clip3">
          <Path d="M 24 39 L 67 39 L 67 63.695312 L 24 63.695312 Z M 24 39 " />
        </ClipPath>
        <ClipPath id="clip4">
          <Path d="M 49 56 L 51 56 L 51 63.695312 L 49 63.695312 Z M 49 56 " />
        </ClipPath>
        <ClipPath id="clip5">
          <Path d="M 38 47 L 45 47 L 45 63.695312 L 38 63.695312 Z M 38 47 " />
        </ClipPath>
        <ClipPath id="clip6">
          <Path d="M 87 63 L 91.886719 63 L 91.886719 63.695312 L 87 63.695312 Z M 87 63 " />
        </ClipPath>
        <ClipPath id="clip7">
          <Path d="M 8 63 L 85 63 L 85 63.695312 L 8 63.695312 Z M 8 63 " />
        </ClipPath>
        <ClipPath id="clip8">
          <Path d="M 4.570312 62 L 7 62 L 7 63.695312 L 4.570312 63.695312 Z M 4.570312 62 " />
        </ClipPath>
        <ClipPath id="clip9">
          <Path d="M 79 45 L 80 45 L 80 63.695312 L 79 63.695312 Z M 79 45 " />
        </ClipPath>
        <ClipPath id="clip10">
          <Path d="M 13 46 L 14 46 L 14 63.695312 L 13 63.695312 Z M 13 46 " />
        </ClipPath>
        <ClipPath id="clip11">
          <Path d="M 56 12.191406 L 85 12.191406 L 85 21 L 56 21 Z M 56 12.191406 " />
        </ClipPath>
      </Defs>
      <G id="surface1">
        <Rect
          height={64}
          style={{
            fill: 'rgb(255, 255, 255)',
            fillOpacity: 1,
            stroke: 'none',
          }}
          width={96}
          x={0}
          y={0}
        />
        <G clipPath="url(#clip1)" clipRule="nonzero">
          <Path
            d="M 25.21875 63.011719 L 25.21875 39.164062 L 49.980469 39.164062 L 49.980469 63.011719 "
            style={{
              fill: 'rgb(230, 235, 239)',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
          />
        </G>
        <Path
          d="M 34.132812 47.769531 L 29.332031 47.769531 L 29.332031 57.597656 L 34.132812 57.597656 Z M 34.132812 47.769531 "
          style={{
            fill: 'rgb(186, 194, 202)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <G clipPath="url(#clip2)" clipRule="nonzero">
          <Path
            d="M 44.496094 47.542969 L 38.550781 47.542969 L 38.550781 63.085938 L 44.496094 63.085938 Z M 44.496094 47.542969 "
            style={{
              fill: svgHighlight,
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
          />
        </G>
        <Path
          d="M 46.550781 43.582031 L 21.257812 43.582031 L 33.296875 30.550781 L 58.589844 30.550781 Z M 46.550781 43.582031 "
          style={{
            fill: 'rgb(186, 194, 202)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <Path
          d="M 57.601318 40.0979 L 76.89917 40.0979 L 61.0979 57.201416 L 27.900879 57.201416 L 43.702148 40.0979 L 54.099609 40.0979 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <Path
          d="M 77.001709 40.0979 L 91.998047 57.201416 L 88.901367 57.201416 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <G clipPath="url(#clip3)" clipRule="nonzero">
          <Path
            d="M 33.002197 57.80127 L 33.002197 83.000244 L 87.501709 83.000244 L 87.501709 52.19751 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.75,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <G clipPath="url(#clip4)" clipRule="nonzero">
          <Path
            d="M 65.799316 74.597168 L 65.799316 82.697754 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.5,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <Path
          d="M 65.799316 55.499268 L 65.799316 71.397949 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.5,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <G clipPath="url(#clip5)" clipRule="nonzero">
          <Path
            d="M 58.498535 82.800293 L 58.498535 62.497559 L 50.5979 62.497559 L 50.5979 80.698242 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.65,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <Path
          d="M 45.199219 62.600098 L 38.29834 62.600098 L 38.29834 75.602051 L 45.199219 75.602051 Z M 45.199219 62.600098 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.65,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <Path
          d="M 77.299072 56.001709 C 78.406494 56.001709 79.298584 54.745605 79.298584 53.202393 C 79.298584 51.654053 78.406494 50.397949 77.299072 50.397949 C 76.196777 50.397949 75.299561 51.654053 75.299561 53.202393 C 75.299561 54.745605 76.196777 56.001709 77.299072 56.001709 Z M 77.299072 56.001709 "
          style={{
            fill: 'rgb(230, 235, 239)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.65,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <Path
          d="M 77.299072 56.001709 C 78.406494 56.001709 79.298584 54.745605 79.298584 53.202393 C 79.298584 51.654053 78.406494 50.397949 77.299072 50.397949 C 76.196777 50.397949 75.299561 51.654053 75.299561 53.202393 C 75.299561 54.745605 76.196777 56.001709 77.299072 56.001709 Z M 77.299072 56.001709 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.5,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <G clipPath="url(#clip6)" clipRule="nonzero">
          <Path
            d="M 114.700195 83.097656 L 120.201416 83.097656 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.75,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <G clipPath="url(#clip7)" clipRule="nonzero">
          <Path
            d="M 11.597168 83.097656 L 110.901123 83.097656 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.75,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <G clipPath="url(#clip8)" clipRule="nonzero">
          <Path
            d="M 6.500977 83.097656 L 8.397949 83.097656 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 1,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <Path
          d="M 86.246094 45.867188 C 83.503906 29.105469 79.542969 29.867188 79.542969 29.867188 L 79.542969 29.941406 L 79.542969 29.867188 C 79.542969 29.867188 75.808594 29.105469 72.988281 45.867188 C 72.914062 46.398438 72.835938 46.933594 72.835938 47.46875 C 72.835938 51.960938 75.886719 55.695312 79.542969 55.695312 C 83.277344 55.695312 86.246094 52.039062 86.246094 47.46875 C 86.324219 46.933594 86.324219 46.398438 86.246094 45.867188 Z M 86.246094 45.867188 "
          style={{
            fill: 'rgb(230, 235, 239)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <Path
          d="M 86.324219 47.464844 C 86.324219 46.933594 86.246094 46.398438 86.171875 45.867188 C 83.429688 29.105469 79.464844 29.867188 79.464844 29.867188 L 79.464844 29.941406 L 79.464844 29.867188 C 79.464844 29.867188 79.542969 29.714844 79.160156 29.941406 C 80.152344 31.925781 83.046875 39.160156 83.65625 46.246094 C 84.191406 52.191406 81.980469 54.933594 79.464844 55.695312 L 79.542969 55.695312 C 83.351562 55.695312 86.324219 52.039062 86.324219 47.464844 Z M 86.324219 47.464844 "
          style={{
            fill: 'rgb(186, 194, 202)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <Path
          d="M 98.20166 49.300781 C 97.401855 52.100098 96.602051 55.699219 95.797119 60.200684 C 95.699707 60.897949 95.597168 61.600342 95.597168 62.297607 C 95.597168 68.19873 99.601318 73.100098 104.400146 73.100098 C 109.301514 73.100098 113.197998 68.30127 113.197998 62.297607 C 113.197998 61.600342 113.100586 60.897949 112.998047 60.200684 C 109.398926 38.200928 104.200195 39.200684 104.200195 39.200684 L 104.200195 39.298096 L 104.200195 39.200684 C 104.200195 39.200684 101.898193 38.698242 99.201416 45.599121 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <G clipPath="url(#clip9)" clipRule="nonzero">
          <Path
            d="M 104.400146 59.498291 L 104.400146 83.097656 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.75,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <Path
          d="M 101.098389 64.297119 L 104.297607 66.599121 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <Path
          d="M 107.701904 66.999023 L 104.497559 69.301025 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <Path
          d="M 13.484375 58.511719 C 10.742188 58.511719 8.535156 56.304688 8.535156 53.5625 L 8.535156 42.894531 C 8.535156 40.152344 10.742188 37.941406 13.484375 37.941406 C 16.230469 37.941406 18.4375 40.152344 18.4375 42.894531 L 18.4375 53.5625 C 18.4375 56.304688 16.230469 58.511719 13.484375 58.511719 Z M 13.484375 58.511719 "
          style={{
            fill: 'rgb(230, 235, 239)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <Path
          d="M 15.847656 38.550781 C 16.457031 39.390625 16.761719 40.378906 16.761719 41.449219 L 16.761719 52.113281 C 16.761719 54.855469 14.550781 57.066406 11.808594 57.066406 C 10.972656 57.066406 10.210938 56.835938 9.523438 56.457031 C 10.4375 57.675781 11.886719 58.515625 13.5625 58.515625 C 16.304688 58.515625 18.515625 56.304688 18.515625 53.5625 L 18.515625 42.894531 C 18.4375 40.988281 17.371094 39.390625 15.847656 38.550781 Z M 15.847656 38.550781 "
          style={{
            fill: 'rgb(186, 194, 202)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <Path
          d="M 17.698242 76.796631 C 14.099121 76.796631 11.202393 73.899902 11.202393 70.300781 L 11.202393 56.299072 C 11.202393 52.699951 14.099121 49.798096 17.698242 49.798096 C 21.30249 49.798096 24.199219 52.699951 24.199219 56.299072 L 24.199219 70.300781 C 24.199219 73.899902 21.30249 76.796631 17.698242 76.796631 Z M 17.698242 76.796631 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
        <G clipPath="url(#clip10)" clipRule="nonzero">
          <Path
            d="M 17.60083 61.800293 L 17.60083 82.697754 "
            style={{
              fill: 'none',
              stroke: 'rgb(0,0,0)',
              strokeLinecap: 'round',
              strokeLinejoin: 'round',
              strokeMiterlimit: 10,
              strokeOpacity: 1,
              strokeWidth: 0.75,
            }}
            transform="matrix(0.761905,0,0,0.761905,0,0)"
          />
        </G>
        <G clipPath="url(#clip11)" clipRule="nonzero">
          <Path
            d="M 82.894531 20.953125 C 84.113281 20.953125 84.953125 19.734375 84.496094 18.515625 C 83.503906 16.074219 81.449219 12.648438 77.484375 12.191406 C 75.808594 12.039062 74.285156 12.648438 73.066406 13.484375 C 72.992188 13.5625 72.835938 13.5625 72.683594 13.484375 C 71.847656 12.722656 70.703125 12.191406 69.332031 12.191406 C 66.820312 12.191406 64.761719 13.941406 64.761719 16.074219 C 64.761719 16.226562 64.609375 16.378906 64.457031 16.378906 C 64.304688 16.378906 64.226562 16.378906 64.074219 16.378906 C 62.550781 16.378906 61.179688 17.21875 60.722656 18.4375 C 60.648438 18.589844 60.496094 18.667969 60.34375 18.589844 C 59.960938 18.363281 59.429688 18.210938 58.972656 18.285156 C 57.828125 18.285156 57.066406 18.972656 56.609375 19.582031 C 56.226562 20.191406 56.609375 20.953125 57.296875 20.953125 Z M 82.894531 20.953125 "
            style={{
              fill: 'rgb(236, 238, 241)',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
            }}
          />
        </G>
        <Path
          d="M 37.484375 26.132812 C 38.171875 26.132812 38.550781 25.296875 38.09375 24.761719 C 37.332031 23.847656 35.808594 23.238281 34.058594 23.238281 C 33.753906 23.238281 33.523438 23.238281 33.21875 23.3125 C 32.914062 23.3125 32.6875 23.085938 32.6875 22.78125 C 32.6875 21.257812 30.703125 19.960938 28.34375 19.960938 C 27.65625 19.960938 27.046875 20.039062 26.515625 20.265625 C 26.285156 20.34375 25.980469 20.191406 25.90625 19.886719 C 25.296875 17.753906 22.855469 16 19.886719 16 C 16.609375 16 13.867188 18.210938 13.867188 20.875 C 13.867188 20.953125 13.867188 21.105469 13.867188 21.179688 C 13.714844 21.179688 13.5625 21.179688 13.410156 21.179688 C 12.191406 21.179688 11.125 22.019531 10.742188 23.085938 C 10.667969 23.3125 10.363281 23.464844 10.132812 23.390625 C 9.90625 23.3125 9.675781 23.3125 9.371094 23.3125 C 8.457031 23.3125 7.695312 23.695312 7.160156 24.382812 C 6.550781 25.144531 7.085938 26.210938 8.078125 26.210938 L 37.484375 26.210938 Z M 37.484375 26.132812 "
          style={{
            fill: 'rgb(236, 238, 241)',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
          }}
        />
        <Path
          d="M 14.299072 67.701416 L 17.498291 69.900879 "
          style={{
            fill: 'none',
            stroke: 'rgb(0,0,0)',
            strokeLinecap: 'round',
            strokeLinejoin: 'round',
            strokeMiterlimit: 10,
            strokeOpacity: 1,
            strokeWidth: 0.75,
          }}
          transform="matrix(0.761905,0,0,0.761905,0,0)"
        />
      </G>
    </Svg>
  );
}
