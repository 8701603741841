import React, { Fragment } from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { SecondaryDivider } from '../../base_ui';
import ContactInfo from './ContactInfo';
import { BannerContainer, BannerImage } from './styledComponents';

const WithBanner = ({
  image,
  isSecureMessagingTreatmentOn,
  url,
}) => (
  <Fragment>
    <BannerContainer>
      <Pressable
        onPress={() => WebBrowser.openBrowserAsync(url)}
        style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
      >
        <BannerImage source={{ uri: image }} />
      </Pressable>
    </BannerContainer>
    <SecondaryDivider />
    <ContactInfo isSecureMessagingTreatmentOn={isSecureMessagingTreatmentOn} withBanner />
  </Fragment>
);

WithBanner.propTypes = {
  image: T.string.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  url: T.string.isRequired,
};

export default WithBanner;
