import React from 'react';

import {
  AmountContainer,
  DescriptionsContainer,
  HeaderContainer,
  HeaderText,
} from './styledComponents';

const FeesHeader = () => (
  <HeaderContainer>
    <DescriptionsContainer>
      <HeaderText>Mortgage Servicing Related Fees</HeaderText>
    </DescriptionsContainer>
    <AmountContainer>
      <HeaderText>Fee Amount</HeaderText>
    </AmountContainer>
  </HeaderContainer>
);

export default FeesHeader;
