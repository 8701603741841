/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function DownloadDefaultIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(16)} viewBox="0 0 16 16" width={moderateScale(16)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path clipRule="evenodd" d="M10.5603 8.47996C10.6205 8.49338 10.676 8.52245 10.7213 8.56421C10.7666 8.60598 10.8001 8.65896 10.8184 8.71781C10.8366 8.77666 10.839 8.83929 10.8253 8.89937C10.8116 8.95945 10.7823 9.01484 10.7403 9.05996L8.35033 11.46C8.35033 11.46 8.35033 11.46 8.22033 11.54C8.09033 11.62 7.86033 11.46 7.86033 11.46L5.45033 9.04996C5.29033 8.83996 5.35033 8.78996 5.45033 8.67996C5.47561 8.63229 5.51157 8.59111 5.55541 8.55964C5.59924 8.52817 5.64976 8.50726 5.70301 8.49855C5.75626 8.48984 5.8108 8.49355 5.86238 8.50941C5.91396 8.52527 5.96117 8.55283 6.00033 8.58996L7.80033 10.39V4.33996C7.80033 4.07996 7.91033 4.08996 8.03033 4.04996C8.08193 4.03049 8.1375 4.02387 8.19223 4.03067C8.24696 4.03746 8.29922 4.05747 8.34449 4.08897C8.38977 4.12046 8.4267 4.1625 8.45211 4.21145C8.47752 4.26041 8.49063 4.31481 8.49033 4.36996V10.37L10.3203 8.53996C10.4003 8.46996 10.4003 8.45996 10.5603 8.47996Z" fill="#282828" fillRule="evenodd" />
      <Path d="M8.08996 14.52C11.6135 14.52 14.47 11.6636 14.47 8.14001C14.47 4.61643 11.6135 1.76001 8.08996 1.76001C4.56638 1.76001 1.70996 4.61643 1.70996 8.14001C1.70996 11.6636 4.56638 14.52 8.08996 14.52Z" stroke="#282828" strokeMiterlimit="10" strokeWidth="0.71" />
    </Svg>
  );
}
