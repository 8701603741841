import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';

export const StyledFooterIconContainer = styled.View`
  align-items: center;
  justify-content: flex-end;
  margin-bottom: ${({ $isLogin }) => $isLogin ? `${moderateScale(16)}px` : `${moderateScale(0)}px`};
  margin-top: ${({ $isLogin }) => $isLogin ? `${moderateScale(24)}px` : `${moderateScale(12)}px`};
  width: 100%;
`;

export const StyledFooterIconWrapper = styled.View`
  flex-direction: row;
  justify-content: center;
`;

export const StyledIconWrapper = styled.View`
  align-self: flex-end;
  flex: 1;
  margin-horizontal: ${moderateScale(8)}px;
`;
