import React from 'react';
import T from 'prop-types';

import ConditionalRender from '../../../../../base_ui/ConditionalRender';
import DataItem from './DataItem';
import SkeletonItem from '../../Skeleton/SkeletonItem';

const Item = ({ isSkeletonData, ...restItem }) => (
  <ConditionalRender
    Component={DataItem}
    FallbackComponent={SkeletonItem}
    propsToPassDown={restItem}
    shouldRender={!isSkeletonData}
  />
);

Item.propTypes = { isSkeletonData: T.bool };

export default Item;
