import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { ConditionalRender } from '../../base_ui';
import {
  EscrowCard,
  InsuranceCard,
  PMICard,
  TaxCard,
} from './Cards';

const TaxesInsuranceDetails = ({
  dispatchUpdateSelectedInsurance,
  escrowSummary,
  hasEscrow,
  insuranceData,
  loanOriginationDate,
  loanType,
  mortgageInsuranceData,
  taxData,
}) => (
  <View>
    <ConditionalRender
      Component={EscrowCard}
      propsToPassDown={{
        loanOriginationDate,
        loanType,
        ...escrowSummary,
      }}
      shouldRender={hasEscrow}
    />
    {taxData.map(({
      date,
      identifier,
      ...restProps
    }) => (
      <TaxCard
        key={`${identifier}-${date}`}
        date={date}
        identifier={identifier}
        {...restProps}
      />
    ))}
    {insuranceData.map(({
      date,
      policyNumber,
      ...restProps
    }) => (
      <InsuranceCard
        key={`${policyNumber}-${date}`}
        date={date}
        dispatchUpdateSelectedInsurance={dispatchUpdateSelectedInsurance}
        policyNumber={policyNumber}
        {...restProps}
      />
    ))}
    {mortgageInsuranceData.map(({
      companyName,
      date,
      ...restProps
    }) => (
      <PMICard
        key={`${companyName}-${date}`}
        companyName={companyName}
        date={date}
        {...restProps}
      />
    ))}
  </View>
);

TaxesInsuranceDetails.propTypes = {
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  escrowSummary: T.object.isRequired,
  hasEscrow: T.bool.isRequired,
  insuranceData: T.array.isRequired,
  loanOriginationDate: T.string.isRequired,
  loanType: T.string.isRequired,
  mortgageInsuranceData: T.array.isRequired,
  taxData: T.array.isRequired,
};

export default TaxesInsuranceDetails;
