/*
 * Edit Mobile Number Screen
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeMobileNumber,
  inputErrors,
  resetBannerError,
  resetFormErrorsState,
  resetFormState,
  resetRequestStatusState,
} from '@dmi/shared/redux/Settings/Profile/actions';
import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import {
  selectBannerError,
  selectForm,
  selectFormErrors,
  selectIsSubmitDisabled,
  selectProfileSettings,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Profile/selectors';
import { validateFields } from '@dmi/shared/redux/Settings/Shared/helpers';

import { EditMobileNumberComponent } from '../../../components/Settings/EditMobileNumber';
import { StyledSecondaryHeader } from './styledComponents';

const EditMobileNumberScreen = ({
  bannerError,
  dispatchChangeInput,
  dispatchEditMobileNumber,
  dispatchInputErrors,
  dispatchResetBannerError,
  dispatchResetFormErrorsState,
  dispatchResetFormState,
  dispatchResetRequestStatusState,
  fetchProfileStatus,
  form,
  formErrors,
  isSubmitDisabled,
  mobileNumber,
  navigation,
  status,
}) => {
  const { header } = PROFILE_CONTENT_DICTIONARY.editMobileNumber.editMobileNumberView;

  useEffect(() => () => {
    dispatchResetBannerError();
    dispatchResetFormState({ form: 'mobileNumber' });
    dispatchResetRequestStatusState({ state: 'mobileNumber' });
  }, [
    dispatchResetBannerError,
    dispatchResetFormState,
    dispatchResetRequestStatusState,
  ]);

  const handleEditMobileNumber = () => {
    const { isValidated, validationErrors } = validateFields({
      formErrors,
      values: form,
    });
    if (isValidated) {
      dispatchEditMobileNumber({ isVerify: false, mobileNumber: form.newMobileNumber });
    }
    dispatchInputErrors({ form: 'mobileNumber', formErrors: validationErrors });
  };

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={navigation.goBack}
        title={header}
      />
      <EditMobileNumberComponent
        bannerError={bannerError}
        dispatchChangeInput={dispatchChangeInput}
        dispatchResetFormErrorsState={dispatchResetFormErrorsState}
        fetchProfileStatus={fetchProfileStatus}
        form={form}
        formErrors={formErrors}
        handleEditMobileNumber={handleEditMobileNumber}
        isSubmitDisabled={isSubmitDisabled}
        mobileNumber={mobileNumber}
        status={status}
      />
    </Fragment>
  );
};

EditMobileNumberScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchEditMobileNumber: T.func.isRequired,
  dispatchInputErrors: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetFormErrorsState: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  fetchProfileStatus: T.string.isRequired,
  form: T.shape({
    confirmMobileNumber: T.string.isRequired,
    newMobileNumber: T.string.isRequired,
  }).isRequired,
  formErrors: T.shape({
    confirmMobileNumber: T.string.isRequired,
    newMobileNumber: T.string.isRequired,
  }).isRequired,
  isSubmitDisabled: T.bool.isRequired,
  mobileNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  bannerError: selectBannerError(),
  fetchProfileStatus: selectRequestStatus('fetchProfile'),
  form: selectForm('mobileNumber'),
  formErrors: selectFormErrors('mobileNumber'),
  isSubmitDisabled: selectIsSubmitDisabled('mobileNumber'),
  mobileNumber: selectProfileSettings('mobileNumber'),
  status: selectRequestStatus('mobileNumber'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Profile
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchEditMobileNumber: (payload) => dispatch(changeMobileNumber(payload)),
  dispatchInputErrors: (payload) => dispatch(inputErrors(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetFormErrorsState: (payload) => dispatch(resetFormErrorsState(payload)),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatusState: (payload) => dispatch(resetRequestStatusState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(EditMobileNumberScreen);
