import React, { Fragment, useState } from 'react';
import T from 'prop-types';

import {
  ConditionalRender,
  ErrorSuccessBanner,
  MediumPrimaryButton,
  MediumProgressButton,
} from '../base_ui';
import {
  ButtonsContainer,
  ConsentContainer,
  StyledBaseInternalLink,
  StyledH3,
  StyledH5,
  StyledPrimaryDivider,
} from './styledComponents';
import ConsentForm from './ConsentForm';
import Disclaimers from '../Disclaimers';

const Consent = ({
  acknowledgmentsNeeded: {
    contactAcknowledgment: contactNeeded = true,
    electronicAcknowledgment: electronicNeeded = true,
    privacyAcknowledgment: privacyNeeded = true,
    termsAcknowledgment: termsNeeded = true,
  } = {},
  checkboxValues: {
    contactAcknowledgment: contactCheckbox,
    electronicAcknowledgment: electronicCheckbox,
    privacyAcknowledgment: privacyCheckbox,
    termsAcknowledgment: termsCheckbox,
  },
  error,
  handleCancelPress,
  handleCheckboxPress,
  handleSubmitPress,
  isReconsent,
  loading,
  status,
  submitLabel,
  success,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [type, setType] = useState('termsOfUse');

  const headingText = isReconsent ?
    'Updated Policies and Terms of Use' : 'Policies and Terms of Use';

  const disclaimerButtonHandler = (name) => {
    setIsOpen(true);
    setType(name);
  };

  const submitActive =
    (!privacyNeeded || privacyCheckbox) &&
    (!termsNeeded || termsCheckbox) &&
    (!contactNeeded || contactCheckbox) &&
    (!electronicNeeded || electronicCheckbox);

  return (
    <Fragment>
      <ErrorSuccessBanner error={error} success={success} />
      <ConsentContainer>
        <StyledH3>{headingText}</StyledH3>
        <ConditionalRender
          Component={ConsentForm}
          propsToPassDown={{
            checkboxAValues: {
              checked: termsCheckbox,
              labelId: 'termsAcknowledgment',
              setChecked:
              () => handleCheckboxPress('termsAcknowledgment', !termsCheckbox),
              shouldRender: termsNeeded,
            },
            checkboxBValues: {
              checked: privacyCheckbox,
              labelId: 'privacyAcknowledgment',
              setChecked:
                () => handleCheckboxPress('privacyAcknowledgment', !privacyCheckbox),
              shouldRender: privacyNeeded,
            },
            descId: 'privacyTermsDesc',
            disclaimerButtonHandler,
            headingText: 'Privacy Policy and Terms of Use',
          }}
          shouldRender={privacyNeeded || termsNeeded}
        />
        <ConditionalRender
          Component={ConsentForm}
          propsToPassDown={{
            checkboxAValues: {
              checked: contactCheckbox,
              labelId: 'contactAcknowledgment',
              setChecked:
                () => handleCheckboxPress('contactAcknowledgment', !contactCheckbox),
              shouldRender: contactNeeded,
            },
            checkboxBValues: {
              checked: electronicCheckbox,
              labelId: 'electronicAcknowledgment',
              setChecked:
                () => handleCheckboxPress('electronicAcknowledgment', !electronicCheckbox),
              shouldRender: electronicNeeded,
            },
            descId: 'ecommsEstatementDesc',
            disclaimerButtonHandler,
            headingText: 'Contact Methods and E-Statement Policy',
          }}
          shouldRender={contactNeeded || electronicNeeded}
        />
        <ConditionalRender
          Component={<StyledH5 $center>No consents required.</StyledH5>}
          shouldRender={(
            !contactNeeded && !electronicNeeded && !privacyNeeded && !termsNeeded
          )}
        />
      </ConsentContainer>
      <StyledPrimaryDivider />
      <ButtonsContainer>
        <ConditionalRender
          Component={MediumProgressButton}
          FallbackComponent={MediumPrimaryButton}
          propsToPassDown={{
            disabled: loading || !submitActive,
            label: submitLabel,
            loading,
            onPress: handleSubmitPress,
            status,
          }}
          shouldRender={!!status}
        />
        <StyledBaseInternalLink
          label="Cancel"
          onPressFunc={handleCancelPress}
          path="Login"
          size="medium"
        />
      </ButtonsContainer>
      <Disclaimers
        drawerType={type}
        isDrawerOpen={isOpen}
        setIsDrawerOpen={setIsOpen}
      />
    </Fragment>
  );
};

Consent.propTypes = {
  acknowledgmentsNeeded: T.shape({
    contactAcknowledgment: T.bool,
    electronicAcknowledgment: T.bool,
    privacyAcknowledgment: T.bool,
    termsAcknowledgment: T.bool,
  }),
  checkboxValues: T.shape({
    contactAcknowledgment: T.bool,
    electronicAcknowledgment: T.bool,
    privacyAcknowledgment: T.bool,
    termsAcknowledgment: T.bool,
  }).isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  handleCancelPress: T.func,
  handleCheckboxPress: T.func.isRequired,
  handleSubmitPress: T.func.isRequired,
  isReconsent: T.bool.isRequired,
  loading: T.bool,
  status: T.string,
  submitLabel: T.string.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
};

export default Consent;
