/**
*
* Confirmation
* @description: Confirmation page that renders after creating a one time payment.
* Either the success page or the error page will render depending on the otpConfirmation prop.
* otpConfirmation can either be the false boolean (indicating an error) or an array.
*/
import React, { Fragment } from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../base_ui';
import { PaymentModal } from '../sharedComponents';
import ErrorComponent from './ErrorComponent';
import SuccessComponent from './SuccessComponent';

const Confirmation = ({ data, modalProps, ...restProps }) => (
  <Fragment>
    <ConditionalRender
      Component={SuccessComponent}
      FallbackComponent={ErrorComponent}
      propsToPassDown={{ data, modalProps, ...restProps }}
      shouldRender={!!data}
    />
    <ConditionalRender
      Component={PaymentModal}
      propsToPassDown={modalProps}
      shouldRender={!!modalProps && !!modalProps.isOpen}
    />
  </Fragment>
);

Confirmation.propTypes = {
  data: T.oneOfType([T.array, T.bool]).isRequired,
  modalProps: T.shape({ isOpen: T.bool.isRequired }).isRequired,
};

export default Confirmation;
