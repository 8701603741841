import React, { Fragment } from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { BottomDrawer, ConditionalRender } from '../../base_ui';
import Detail from './Detail';
import ListItem from './ListItem';
import { TertiaryHeader } from '../../ScreenHeaders';

const List = ({
  categories,
  currentCategory,
  currentQuestion: { answer, question },
  handleNavigationBack,
  handleOnPressCategory,
  handleOnPressQuestion,
  questions,
  step,
}) => {
  const listItems = step === 1 ? categories : questions;
  const handleOnPress = step === 1 ? handleOnPressCategory : handleOnPressQuestion;

  return (
    <Fragment>
      <View>
        { listItems.map(({ id, label }) => (
          <ListItem
            key={id}
            id={id}
            label={label}
            onPress={() => handleOnPress(id)}
          />
        ))}
      </View>
      <ConditionalRender
        Component={(
          <BottomDrawer
            handleClose={handleNavigationBack}
            HeaderComponent={TertiaryHeader}
            headerProps={{
              handleBack: handleNavigationBack,
              title: currentCategory,
            }}
            isVisible={step === 3}
          >
            <Detail answer={answer} question={question} />
          </BottomDrawer>
        )}
        shouldRender={!!answer && !!question}
      />
    </Fragment>
  );
};

List.propTypes = {
  categories: T.array.isRequired,
  currentCategory: T.string.isRequired,
  currentQuestion: T.shape({
    answer: T.string.isRequired,
    question: T.string.isRequired,
  }).isRequired,
  handleNavigationBack: T.func.isRequired,
  handleOnPressCategory: T.func.isRequired,
  handleOnPressQuestion: T.func.isRequired,
  questions: T.array.isRequired,
  step: T.number.isRequired,
};

export default List;
