/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavCalculatorDefaultMIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M13.2997 1.00003H4.70969C4.27553 0.999997 3.85885 1.17109 3.54998 1.47621C3.24111 1.78133 3.06495 2.1959 3.05969 2.63003V15.33C3.05704 15.5484 3.09777 15.7651 3.1795 15.9676C3.26124 16.1701 3.38236 16.3544 3.53584 16.5097C3.68932 16.665 3.87211 16.7884 4.07361 16.8725C4.27511 16.9567 4.49132 17 4.70969 17H13.2997C13.5222 17.0068 13.7438 16.9684 13.9512 16.8873C14.1585 16.8062 14.3473 16.6839 14.5061 16.5279C14.6649 16.3718 14.7906 16.1853 14.8754 15.9794C14.9602 15.7736 15.0025 15.5527 14.9997 15.33V2.63003C14.9971 2.41076 14.9509 2.19421 14.8637 1.99302C14.7765 1.79183 14.65 1.61004 14.4918 1.45828C14.3335 1.30652 14.1465 1.18783 13.9419 1.10915C13.7372 1.03046 13.5189 0.993369 13.2997 1.00003ZM13.7597 15.35C13.761 15.4113 13.7501 15.4722 13.7276 15.5291C13.7051 15.5861 13.6714 15.638 13.6285 15.6818C13.5857 15.7256 13.5345 15.7603 13.4781 15.7841C13.4216 15.8078 13.361 15.82 13.2997 15.82H4.70969C4.58504 15.82 4.46549 15.7705 4.37735 15.6824C4.28921 15.5942 4.23969 15.4747 4.23969 15.35V2.63003C4.23969 2.50538 4.28921 2.38583 4.37735 2.29769C4.46549 2.20955 4.58504 2.16003 4.70969 2.16003H13.2997C13.361 2.16001 13.4216 2.17224 13.4781 2.19598C13.5345 2.21972 13.5857 2.2545 13.6285 2.29828C13.6714 2.34206 13.7051 2.39395 13.7276 2.45092C13.7501 2.50789 13.761 2.56878 13.7597 2.63003V15.35Z" fill="black" />
        <Path d="M5.5403 6.99998H12.4403C12.565 6.99998 12.6845 6.95046 12.7726 6.86232C12.8608 6.77418 12.9103 6.65463 12.9103 6.52998V3.58998C12.9103 3.46533 12.8608 3.34578 12.7726 3.25764C12.6845 3.1695 12.565 3.11998 12.4403 3.11998H5.5403C5.47775 3.11865 5.41555 3.12982 5.35737 3.15284C5.29919 3.17587 5.24619 3.21028 5.20148 3.25405C5.15677 3.29783 5.12125 3.35009 5.09701 3.40778C5.07277 3.46546 5.06029 3.52741 5.0603 3.58998V6.47998C5.05315 6.54662 5.06033 6.61402 5.08136 6.67766C5.1024 6.74129 5.1368 6.79969 5.18226 6.84894C5.22772 6.89819 5.28319 6.93715 5.34494 6.9632C5.40669 6.98925 5.4733 7.00179 5.5403 6.99998ZM6.0003 4.05998H12.0003V5.99998H6.0003V4.05998Z" fill="black" />
        <Path d="M8.21957 7.57994H5.53957C5.47701 7.57861 5.41482 7.58978 5.35664 7.61281C5.29845 7.63583 5.24545 7.67024 5.20075 7.71401C5.15604 7.75779 5.12052 7.81005 5.09628 7.86774C5.07204 7.92542 5.05956 7.98737 5.05957 8.04994V10.4899C5.05956 10.5525 5.07204 10.6145 5.09628 10.6721C5.12052 10.7298 5.15604 10.7821 5.20075 10.8259C5.24545 10.8696 5.29845 10.9041 5.35664 10.9271C5.41482 10.9501 5.47701 10.9613 5.53957 10.9599H8.21957C8.34516 10.96 8.46576 10.9108 8.55549 10.8229C8.64523 10.735 8.69695 10.6155 8.69957 10.4899V8.04994C8.69695 7.92438 8.64523 7.80484 8.55549 7.71698C8.46576 7.62911 8.34516 7.57991 8.21957 7.57994ZM7.74957 9.99994H5.99957V8.51994H7.74957V9.99994Z" fill="black" />
        <Path d="M8.21957 11.55H5.53957C5.41227 11.55 5.29018 11.6006 5.20016 11.6906C5.11014 11.7807 5.05957 11.9027 5.05957 12.03V14.47C5.05956 14.5326 5.07204 14.5946 5.09628 14.6523C5.12052 14.7099 5.15604 14.7622 5.20075 14.806C5.24545 14.8498 5.29845 14.8842 5.35664 14.9072C5.41482 14.9302 5.47701 14.9414 5.53957 14.94H8.21957C8.34516 14.9401 8.46576 14.8909 8.55549 14.803C8.64523 14.7151 8.69695 14.5956 8.69957 14.47V12C8.68966 11.8789 8.63504 11.7657 8.54633 11.6825C8.45762 11.5993 8.34115 11.5521 8.21957 11.55ZM7.74957 14H5.99957V12.5H7.74957V14Z" fill="black" />
        <Path d="M12.4696 7.57994H9.78957C9.72701 7.57861 9.66482 7.58978 9.60664 7.61281C9.54845 7.63583 9.49545 7.67024 9.45075 7.71401C9.40604 7.75779 9.37052 7.81005 9.34628 7.86774C9.32204 7.92542 9.30956 7.98737 9.30957 8.04994V10.4899C9.30956 10.5525 9.32204 10.6145 9.34628 10.6721C9.37052 10.7298 9.40604 10.7821 9.45075 10.8259C9.49545 10.8696 9.54845 10.9041 9.60664 10.9271C9.66482 10.9501 9.72701 10.9613 9.78957 10.9599H12.4696C12.5952 10.96 12.7158 10.9108 12.8055 10.8229C12.8952 10.735 12.947 10.6155 12.9496 10.4899V8.04994C12.947 7.92438 12.8952 7.80484 12.8055 7.71698C12.7158 7.62911 12.5952 7.57991 12.4696 7.57994ZM11.9996 9.99994H10.2596V8.51994H11.9996V9.99994Z" fill="black" />
        <Path d="M12.4696 11.55H9.78957C9.66227 11.55 9.54018 11.6006 9.45016 11.6906C9.36014 11.7807 9.30957 11.9027 9.30957 12.03V14.47C9.30956 14.5326 9.32204 14.5946 9.34628 14.6523C9.37052 14.7099 9.40604 14.7622 9.45075 14.806C9.49545 14.8498 9.54845 14.8842 9.60664 14.9072C9.66482 14.9302 9.72701 14.9414 9.78957 14.94H12.4696C12.5952 14.9401 12.7158 14.8909 12.8055 14.803C12.8952 14.7151 12.947 14.5956 12.9496 14.47V12C12.9397 11.8789 12.885 11.7657 12.7963 11.6825C12.7076 11.5993 12.5912 11.5521 12.4696 11.55ZM11.9996 14H10.2596V12.5H11.9996V14Z" fill="black" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="18" width="18" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
