import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeInput, resetReconsent } from '@dmi/shared/redux/Login/actions';
import { reconsentRequest, resetMainError, resetStatus } from '@dmi/shared/redux/Main/actions';
import makeSelectMain, { getError, selectMainStatus } from '@dmi/shared/redux/Main/selectors';
import reducer from '@dmi/shared/redux/Login/reducer';
import makeSelectLogin, {
  getConsentsAgreed,
  getLoginForm,
} from '@dmi/shared/redux/Login/selectors';

import injectReducer from '../../utils/injectReducer';
import Consent from '../../components/Consent';
import { StyledScrollView } from '../../components/base_ui';

const ReconsentView = ({
  acknowledgmentsNeeded,
  consentsAgreed,
  dispatchChangeInput,
  dispatchReconsentRequest,
  dispatchResetMainError,
  dispatchResetReconsent,
  dispatchResetStatus,
  error,
  formValues,
  status,
  success,
}) => {
  useEffect(() => () => {
    dispatchResetMainError();
    dispatchResetReconsent();
    dispatchResetStatus();
  }, [dispatchResetMainError, dispatchResetReconsent, dispatchResetStatus]);

  const handleCheckboxPress = (field, value) => dispatchChangeInput({
    field,
    form: 'loginReconsent',
    value,
  });

  return (
    <StyledScrollView>
      <Consent
        acknowledgmentsNeeded={acknowledgmentsNeeded}
        checkboxValues={formValues}
        error={error}
        handleCheckboxPress={handleCheckboxPress}
        handleSubmitPress={() => dispatchReconsentRequest({ consentsAgreed })}
        isReconsent
        status={status}
        submitLabel="Accept Terms"
        success={success}
      />
    </StyledScrollView>
  );
};

ReconsentView.propTypes = {
  acknowledgmentsNeeded: T.object,
  consentsAgreed: T.string.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchReconsentRequest: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchResetReconsent: T.func.isRequired,
  dispatchResetStatus: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  formValues: T.object.isRequired,
  status: T.string.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Login
   */
  acknowledgmentsNeeded: makeSelectLogin('acknowledgmentsNeeded'),
  consentsAgreed: getConsentsAgreed(),
  formValues: getLoginForm('loginReconsent'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  error: getError('main'),
  status: selectMainStatus('reconsent'),
  success: makeSelectMain('success'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store : Login
  */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchReconsentRequest: (payload) => dispatch(reconsentRequest(payload)),
  dispatchResetReconsent: () => dispatch(resetReconsent()),
  /**
  * Store : Main
  */
  // eslint-disable-next-line sort-keys
  dispatchResetMainError: () => dispatch(resetMainError()),
  dispatchResetStatus: () => dispatch(resetStatus('reconsent')),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'login', reducer });

export default compose(
  withReducer,
  withConnect,
)(ReconsentView);
