import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { POSITION_OPTIONS, VARIANT_OPTIONS } from './constants';
import {
  ContentWrapper,
  MainContainer,
  ModalContainer,
  ModalWrapper,
} from './styledComponents';

const ChildComponent = ({
  children,
  handleCloseModal,
  modalStyles = {},
  position = 'center',
  variant = 'standard',
}) => {
  const positionProps = POSITION_OPTIONS[position];
  const MainView = VARIANT_OPTIONS[variant];

  return (
    <Pressable onPress={handleCloseModal} style={{ flex: 1 }}>
      <MainContainer>
        <ModalWrapper {...positionProps}>
          <ModalContainer style={modalStyles}>
            <MainView>
              <Pressable>
                <ContentWrapper>
                  {children}
                </ContentWrapper>
              </Pressable>
            </MainView>
          </ModalContainer>
        </ModalWrapper>
      </MainContainer>
    </Pressable>
  );
};

ChildComponent.propTypes = {
  children: T.node.isRequired,
  handleCloseModal: T.func,
  modalStyles: T.object,
  position: T.oneOf(Object.keys(POSITION_OPTIONS)),
  variant: T.oneOf(Object.keys(VARIANT_OPTIONS)),
};

export default ChildComponent;
