import React from 'react';
import Svg, {
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
const StatementsHouse = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg data-name="Layer 1" fill="#000" height={moderateScale(150)} id="Layer_1" viewBox="0 0 327 150" width={moderateScale(327)} {...props}>
      <Path className="cls-1" d="M251,44a1.17,1.17,0,0,0,.89-1.94A7.82,7.82,0,0,0,246,39.94a9.76,9.76,0,0,0-1.22.07.73.73,0,0,1-.8-.73h0c0-2.22-2.81-4-6.27-4a9.2,9.2,0,0,0-2.69.39.69.69,0,0,1-.88-.5c-.73-3.3-4.26-5.8-8.52-5.8-4.78,0-8.66,3.16-8.66,7.06,0,.14,0,.27,0,.41a4.18,4.18,0,0,0-.61-.05,4,4,0,0,0-3.83,2.78.7.7,0,0,1-.86.47,3.88,3.88,0,0,0-1.09-.16,4,4,0,0,0-3.15,1.52,1.65,1.65,0,0,0,1.3,2.67Z" fill="#e6ebef" />
      <Path className="cls-2" d="M301.46,82.6a6,6,0,0,1-1.37-4v-.33c0-4.75-4.23-8.59-9.46-8.59s-9.46,3.84-9.46,8.59c0,.11,0,.22,0,.33a6.08,6.08,0,0,1-1.38,4,11.26,11.26,0,0,0-2.51,7.05c0,6.7,6,12.12,13.34,12.12S304,96.35,304,89.65A11.26,11.26,0,0,0,301.46,82.6Z" fill="#eff3f6" />
      <Path d="M290.63,102c-7.48,0-13.57-5.54-13.57-12.35a11.53,11.53,0,0,1,2.56-7.2,5.82,5.82,0,0,0,1.32-3.85v-.34c0-4.87,4.35-8.83,9.69-8.83s9.69,4,9.69,8.83v.34a5.82,5.82,0,0,0,1.32,3.85h0a11.47,11.47,0,0,1,2.56,7.2C304.2,96.46,298.11,102,290.63,102Zm0-32.1c-5.09,0-9.23,3.75-9.23,8.36,0,.11,0,.21,0,.32A6.31,6.31,0,0,1,280,82.74a11.1,11.1,0,0,0-2.46,6.91c0,6.56,5.88,11.89,13.11,11.89s13.11-5.33,13.11-11.89a11,11,0,0,0-2.46-6.91,6.31,6.31,0,0,1-1.43-4.16c0-.11,0-.21,0-.32C299.86,73.65,295.72,69.9,290.63,69.9Z" />
      <Path d="M290.28,118.22a.23.23,0,0,1-.23-.23V80.63a.23.23,0,1,1,.46,0V118A.23.23,0,0,1,290.28,118.22Z" />
      <Path d="M290.18,91.22a.33.33,0,0,1-.14,0l-3.43-2.45a.23.23,0,0,1-.05-.32.23.23,0,0,1,.32,0l3.43,2.44a.23.23,0,0,1,.06.32A.26.26,0,0,1,290.18,91.22Z" />
      <Path d="M290.29,85.88a.21.21,0,0,1-.19-.1.23.23,0,0,1,0-.32L293.58,83a.23.23,0,0,1,.32,0,.24.24,0,0,1,0,.33l-3.43,2.44A.22.22,0,0,1,290.29,85.88Z" />
      <Path d="M290.36,95.86a.23.23,0,0,1-.13-.42L293.66,93a.23.23,0,0,1,.32,0,.25.25,0,0,1-.05.33l-3.43,2.44A.23.23,0,0,1,290.36,95.86Z" />
      <Polygon className="cls-1" fill="#e6ebef" points="26.69 70.99 71.18 70.99 164.99 85.58 164.99 117.84 35.78 117.84 31.77 72.86 26.69 70.99" />
      <Polyline className="cls-3" fill="#fff" points="126.8 117.84 126.8 88.89 29.86 74.06 29.86 117.84" />
      <Polygon className="cls-4" fill="#bac2ca" points="30.03 76.11 82.05 84.02 82.05 81.98 29.86 74.02 30.03 76.11" />
      <Polygon className="cls-4" fill="#bac2ca" points="100.53 87.18 152.66 95.09 152.66 93.05 100.47 85.09 100.53 87.18" />
      <Path d="M126.8,118a.23.23,0,0,1-.23-.24V89.06L30.09,74.33v43.42a.24.24,0,1,1-.47,0V74.06a.23.23,0,0,1,.27-.23l96.94,14.8a.23.23,0,0,1,.2.23v28.89A.23.23,0,0,1,126.8,118Z" />
      <Polyline className="cls-3" fill="#fff" points="83.44 79.69 129.76 86.83 129.83 89.33 26.98 73.52 26.98 70.99 80.72 79.27" />
      <Path d="M129.83,89.57h0L27,73.75a.24.24,0,0,1-.2-.23V71a.23.23,0,0,1,.27-.23L80.75,79a.23.23,0,0,1,.2.26.24.24,0,0,1-.27.2L27.22,71.26v2.06L129.59,89.06l-.05-2L83.41,79.92a.23.23,0,0,1-.19-.27.23.23,0,0,1,.26-.19L129.8,86.6a.24.24,0,0,1,.2.22l.06,2.51a.24.24,0,0,1-.23.24Z" />
      <Path d="M136.59,87H130a.24.24,0,0,1,0-.47h6.61a.24.24,0,0,1,0,.47Z" />
      <Rect className="cls-1" fill="#e6ebef" height="80.74" width="16.44" x="191.37" y="37.48" />
      <Polygon className="cls-4" fill="#bac2ca" points="207.01 73.2 202.36 76.71 202.36 117.84 208.98 117.84 208.63 74.02 207.01 73.2" />
      <Path className="cls-1" d="M110.45,63.22a2.82,2.82,0,0,0,2.61-3.88c-1.58-3.92-4.93-9.51-11.29-10.17a11.08,11.08,0,0,0-7.13,2.09.44.44,0,0,1-.56,0,8.05,8.05,0,0,0-5.47-2c-4.09,0-7.4,2.79-7.4,6.23h0a.44.44,0,0,1-.49.45l-.6,0a5.7,5.7,0,0,0-5.4,3.27.44.44,0,0,1-.59.25,5.27,5.27,0,0,0-2.23-.46A4.69,4.69,0,0,0,68.13,61a1.4,1.4,0,0,0,1.13,2.22Z" fill="#e6ebef" />
      <Path className="cls-3" d="M265.64,49.61h1.48a1.08,1.08,0,0,0,1-1.13h0a1.09,1.09,0,0,0-1-1.13H233.07a2.94,2.94,0,0,0-2,.78L204.8,69.7a1,1,0,0,0-.13,1.35l.12.14a.83.83,0,0,0,1.25.14l.91-.85v47.83h45.12" fill="#fff" />
      <Rect className="cls-1" fill="#e6ebef" height="33.59" width="32.32" x="250.92" y="84.34" />
      <Path className="cls-4" d="M252.72,85.59h30.17V84.32l-30.53-.18-1.24-1.39.07,2.08.32.29A1.78,1.78,0,0,0,252.72,85.59Z" fill="#bac2ca" />
      <Rect className="cls-1" fill="#e6ebef" height="17.57" width="33.3" x="232.68" y="49.54" />
      <Polygon className="cls-4" fill="#bac2ca" points="207.44 71.9 232.81 51.37 266.15 51.37 266.15 49.87 232.77 49.85 207.48 70.5 207.44 71.9" />
      <Path d="M205.65,71.76h-.1a1.05,1.05,0,0,1-.77-.46l-.11-.12a1.26,1.26,0,0,1,.16-1.67l26.3-21.58a3.17,3.17,0,0,1,2.11-.83H267.3a1.37,1.37,0,0,1,0,2.73h-1.48a.24.24,0,0,1,0-.47h1.48a.9.9,0,0,0,0-1.79H233.24a2.72,2.72,0,0,0-1.81.71l-26.3,21.58a.8.8,0,0,0-.09,1l.1.12a.67.67,0,0,0,.45.28.63.63,0,0,0,.47-.16l.92-.85a.22.22,0,0,1,.32,0,.24.24,0,0,1,0,.33l-.92.84A1,1,0,0,1,205.65,71.76Z" />
      <Polyline className="cls-5" fill={svgHighlight} points="224.57 113.79 224.57 96.43 235.19 96.43 235.19 113.79" />
      <Path d="M235.19,114a.23.23,0,0,1-.23-.23V96.66H224.8v17.13a.23.23,0,1,1-.46,0V96.43a.23.23,0,0,1,.23-.23h10.62a.24.24,0,0,1,.23.23v17.36A.24.24,0,0,1,235.19,114Z" />
      <Polygon className="cls-1" fill="#e6ebef" points="100.36 117.96 82.28 117.96 82.28 84.65 100.36 85.77 100.36 117.96" />
      <Polygon className="cls-4" fill="#bac2ca" points="82.22 87.19 100.38 89.98 100.38 85.15 82.16 82.3 82.22 87.19" />
      <Polyline className="cls-5" fill={svgHighlight} points="87.64 118 87.64 96.43 98.25 96.43 98.25 118" />
      <Path d="M98.25,118.23A.23.23,0,0,1,98,118V96.67H87.87V118a.23.23,0,0,1-.23.23.23.23,0,0,1-.24-.23V96.43a.23.23,0,0,1,.24-.23H98.25a.23.23,0,0,1,.24.23V118A.23.23,0,0,1,98.25,118.23Z" />
      <Path d="M251.1,118a.23.23,0,0,1-.23-.23V104.35a.23.23,0,0,1,.46,0v13.44A.23.23,0,0,1,251.1,118Z" />
      <Path d="M251.1,102a.23.23,0,0,1-.23-.23V83.09a.23.23,0,0,1,.46,0V101.8A.23.23,0,0,1,251.1,102Z" />
      <Path d="M207.22,118.25A.23.23,0,0,1,207,118V70.38a.23.23,0,0,1,.08-.18l25.46-20.75a.23.23,0,0,1,.25,0,.23.23,0,0,1,.13.21V67.35l19.33,16.17a.22.22,0,0,1,0,.32.22.22,0,0,1-.32,0L232.53,67.63a.2.2,0,0,1-.09-.17V50.12l-25,20.37V118A.23.23,0,0,1,207.22,118.25Z" />
      <Path d="M266.21,67.45a.23.23,0,0,1-.23-.23V49.86h-33.3a.23.23,0,1,1,0-.46h33.53a.23.23,0,0,1,.23.23V67.22A.23.23,0,0,1,266.21,67.45Z" />
      <Path d="M283.15,118a.24.24,0,0,1-.23-.24V84.28a.24.24,0,0,1,.47,0v33.46A.24.24,0,0,1,283.15,118Z" />
      <Polyline className="cls-4" fill="#bac2ca" points="235.9 67.51 253.94 82.15 284.11 81.81 266.63 67.51" />
      <Path className="cls-1" d="M253.53,84h30.56A1.09,1.09,0,0,0,285.2,83h0a1.09,1.09,0,0,0-1.11-1.09h-.58L254,82" fill="#e6ebef" />
      <Path d="M284.09,84.28H253.53a.24.24,0,0,1,0-.47h30.56a.86.86,0,1,0,0-1.71h-.58L254,82.18h0a.23.23,0,1,1,0-.46l30.12-.09a1.33,1.33,0,1,1,0,2.65Z" />
      <Path d="M284.35,82.12a.24.24,0,0,1-.14-.05l-18-14.52a.24.24,0,0,1,0-.33.22.22,0,0,1,.32,0l18,14.52a.22.22,0,0,1,0,.32A.21.21,0,0,1,284.35,82.12Z" />
      <Path d="M266.29,67.46H233a.24.24,0,1,1,0-.47h33.32a.24.24,0,1,1,0,.47Z" />
      <Path d="M233.78,118.22h-14a.23.23,0,0,1-.23-.23v-2a.23.23,0,0,1,.23-.23h1.66v-1.72a.23.23,0,0,1,.23-.23h12.08a.23.23,0,0,1,.23.23V118A.23.23,0,0,1,233.78,118.22ZM220,117.76h13.51v-3.47H221.93V116a.23.23,0,0,1-.23.23H220Z" />
      <Polygon className="cls-4" fill="#bac2ca" points="232.81 114.05 232.81 116 230.92 116 230.92 117.99 232.81 117.99 233.02 117.99 235.19 117.99 235.19 114.05 232.81 114.05" />
      <Path d="M235.19,118.22h-4.27a.23.23,0,0,1-.23-.23v-2a.23.23,0,0,1,.23-.23h1.65v-1.72a.23.23,0,0,1,.24-.23h2.38a.23.23,0,0,1,.23.23V118A.23.23,0,0,1,235.19,118.22Zm-4-.46H235v-3.47H233V116a.23.23,0,0,1-.23.23h-1.66Z" />
      <Path d="M229.79,116.16h-8a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h8a.16.16,0,0,1,.16.15A.16.16,0,0,1,229.79,116.16Z" />
      <Path d="M232,50.41a.23.23,0,0,1-.15-.41l1-.83a.77.77,0,0,0,.16-1,.76.76,0,0,0-.52-.35.8.8,0,0,0-.61.15l-.59.46a.23.23,0,0,1-.32,0,.24.24,0,0,1,0-.33l.59-.46a1.24,1.24,0,0,1,1.54,1.94l-1,.83A.23.23,0,0,1,232,50.41Z" />
      <Rect className="cls-1" fill="#e6ebef" height="7.79" width="5.4" x="212.57" y="72.69" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="211.37 78.78 214.47 72.69 211.67 72.69 211.37 73.23 211.37 78.78" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="215.23 80.48 213.49 80.48 217.55 72.69 219.29 72.69 215.23 80.48" />
      <Path d="M218.07,80.78h-6.31a.23.23,0,0,1-.23-.23V72.7a.23.23,0,0,1,.23-.23h6.31a.23.23,0,0,1,.23.23v7.85A.23.23,0,0,1,218.07,80.78ZM212,80.32h5.85V72.94H212Z" />
      <Rect className="cls-1" fill="#e6ebef" height="7.79" width="5.36" x="212.61" y="84.57" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="211.37 90.66 214.47 84.56 211.67 84.56 211.37 85.11 211.37 90.66" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="215.23 92.36 213.49 92.36 217.55 84.56 219.29 84.56 215.23 92.36" />
      <Path d="M218.07,92.65h-6.31a.23.23,0,0,1-.23-.23V84.58a.23.23,0,0,1,.23-.23h6.31a.23.23,0,0,1,.23.23v7.84A.23.23,0,0,1,218.07,92.65ZM212,92.19h5.85V84.81H212Z" />
      <Rect className="cls-1" fill="#e6ebef" height="7.79" width="5.35" x="212.62" y="96.44" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="211.37 102.53 214.47 96.44 211.67 96.44 211.37 96.98 211.37 102.53" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="215.23 104.23 213.49 104.23 217.55 96.44 219.29 96.44 215.23 104.23" />
      <Path d="M218.07,104.53h-6.31a.23.23,0,0,1-.23-.23V96.45a.23.23,0,0,1,.23-.23h6.31a.23.23,0,0,1,.23.23v7.85A.23.23,0,0,1,218.07,104.53Zm-6.08-.46h5.85V96.69H212Z" />
      <Rect className="cls-3" fill="#fff" height="8.49" width="19.64" x="239.82" y="54.26" />
      <Path d="M259.47,63H239.82a.24.24,0,0,1-.23-.24V54.26a.24.24,0,0,1,.23-.24h19.65a.23.23,0,0,1,.23.24v8.48A.23.23,0,0,1,259.47,63Zm-19.41-.47h19.18v-8H240.06Z" />
      <Polygon className="cls-1" fill="#e6ebef" points="252.25 62.5 256.62 62.5 253.48 54.49 249.11 54.49 252.25 62.5" />
      <Polygon className="cls-1" fill="#e6ebef" points="244.56 62.52 249.11 62.52 246.03 54.48 241.48 54.48 244.56 62.52" />
      <Path d="M258.37,58.63H240.9a.15.15,0,0,1-.15-.16.15.15,0,0,1,.15-.15h17.47a.15.15,0,0,1,.15.15A.16.16,0,0,1,258.37,58.63Z" />
      <Path d="M249.91,62.72h0a.16.16,0,0,1-.16-.15V54.94a.16.16,0,0,1,.16-.15h0a.15.15,0,0,1,.15.15v7.63A.15.15,0,0,1,249.91,62.72Z" />
      <Rect className="cls-3" fill="#fff" height="19.73" transform="translate(534.74 207.61) rotate(180)" width="14.24" x="260.25" y="93.94" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="275.28 102.77 271.98 93.94 274.94 93.94 275.28 94.85 275.28 102.77" />
      <Polygon className="cls-1" fill="#e6ebef" points="274.32 113.69 274.23 108.57 268.71 93.94 266.86 93.94 274.32 113.69" />
      <Polygon className="cls-1" fill="#e6ebef" points="266.37 113.74 270.92 113.74 263.78 94.26 259.23 94.26 266.37 113.74" />
      <Path d="M275.32,114.09h-15.1a.23.23,0,0,1-.23-.23V93.22a.23.23,0,0,1,.23-.23h15.1a.23.23,0,0,1,.23.23v20.64A.23.23,0,0,1,275.32,114.09Zm-14.87-.46h14.63V93.45H260.45Z" />
      <Path d="M274.43,113.9a.16.16,0,0,1-.16-.16V94.31H261.15a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h13.28a.15.15,0,0,1,.15.15v19.59A.16.16,0,0,1,274.43,113.9Z" />
      <Path d="M267.93,113a.16.16,0,0,1-.15-.16V94.55a.16.16,0,0,1,.15-.16.16.16,0,0,1,.16.16v18.32A.16.16,0,0,1,267.93,113Z" />
      <Path d="M212.6,79.92a.15.15,0,0,1-.15-.15v-7a.15.15,0,0,1,.15-.15.16.16,0,0,1,.16.15v7A.16.16,0,0,1,212.6,79.92Z" />
      <Path d="M212.6,92.42a.16.16,0,0,1-.15-.16v-7a.16.16,0,0,1,.15-.16.16.16,0,0,1,.16.16v7A.16.16,0,0,1,212.6,92.42Z" />
      <Path d="M212.6,104.16a.16.16,0,0,1-.15-.16V97a.16.16,0,0,1,.15-.16.16.16,0,0,1,.16.16v7A.16.16,0,0,1,212.6,104.16Z" />
      <Path d="M220.47,114.06H207.34a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h13.13a.16.16,0,0,1,.16.15A.16.16,0,0,1,220.47,114.06Z" />
      <Path d="M283.24,114.06H237.17a.16.16,0,0,1-.15-.16.15.15,0,0,1,.15-.15h46.07a.16.16,0,0,1,.16.15A.16.16,0,0,1,283.24,114.06Z" />
      <Path d="M258.57,62.79a.16.16,0,0,1-.16-.16V54.87a.16.16,0,0,1,.16-.15.15.15,0,0,1,.15.15v7.76A.16.16,0,0,1,258.57,62.79Z" />
      <Path d="M277.6,112.81a.16.16,0,0,1-.15-.16V90.84H263.67a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15H277.6a.16.16,0,0,1,.16.15v22A.16.16,0,0,1,277.6,112.81Z" />
      <Path d="M257.67,113.94a.16.16,0,0,1-.16-.15V90.68a.16.16,0,0,1,.16-.15h4.65a.15.15,0,0,1,.15.15.16.16,0,0,1-.15.16h-4.5v23A.15.15,0,0,1,257.67,113.94Z" />
      <Polyline className="cls-1" fill="#e6ebef" points="226.86 84.6 226.86 72.1 241.04 84.48" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="232.78 85.54 230.36 84.92 233.88 78.17 235.74 79.8 232.78 85.54" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="226.98 85.45 231.73 76.35 229.89 74.7 226.98 79.9 226.98 85.45" />
      <Path d="M226.86,85.06a.23.23,0,0,1-.23-.23V72.1a.23.23,0,0,1,.39-.17L241.2,84.54a.23.23,0,0,1,0,.33.25.25,0,0,1-.33,0L227.1,72.62V84.83A.23.23,0,0,1,226.86,85.06Z" />
      <Rect className="cls-3" fill="#fff" height="7.71" width="19.9" x="223.3" y="84.83" />
      <Path d="M243.2,92.77H223.3a.23.23,0,0,1-.24-.23V84.83a.23.23,0,0,1,.24-.23h19.9a.23.23,0,0,1,.23.23v7.71A.23.23,0,0,1,243.2,92.77Zm-19.67-.46H243V85.06H223.53Z" />
      <Rect className="cls-1" fill="#e6ebef" height="7.71" width="4.77" x="242.92" y="84.83" />
      <Path d="M247.69,92.77h-4.77a.23.23,0,0,1-.23-.23V84.83a.23.23,0,0,1,.23-.23h4.77a.23.23,0,0,1,.23.23v7.71A.23.23,0,0,1,247.69,92.77Zm-4.53-.46h4.3V85.06h-4.3Z" />
      <Path d="M247.61,90.69H224.33a.16.16,0,0,1-.16-.15.16.16,0,0,1,.16-.16h23.28a.16.16,0,0,1,.15.16A.15.15,0,0,1,247.61,90.69Z" />
      <Rect className="cls-3" fill="#fff" height="81.18" width="55" x="136.46" y="37.05" />
      <Rect className="cls-4" fill="#bac2ca" height="1.28" width="70.74" x="136.97" y="38.02" />
      <Path className="cls-1" d="M134.84,36.17h0a1.74,1.74,0,0,0,1.74,1.74h55.86V34.42H136.58A1.74,1.74,0,0,0,134.84,36.17Z" fill="#e6ebef" />
      <Path d="M192.44,38.14H136.58a2,2,0,0,1,0-4h55.86a.23.23,0,0,1,.24.23v3.49A.23.23,0,0,1,192.44,38.14Zm-55.86-3.49a1.52,1.52,0,0,0,0,3h55.63v-3Z" />
      <Rect className="cls-4" fill="#bac2ca" height="3.72" rx="1.86" width="19.92" x="190" y="34.3" />
      <Path d="M207.81,65a.23.23,0,0,1-.23-.23V37.85a.23.23,0,0,1,.23-.24h.29a1.49,1.49,0,1,0,0-3H191.71a1.52,1.52,0,0,0,0,3h2.65a.24.24,0,0,1,0,.47h-2.65a2,2,0,0,1,0-4H208.1a2,2,0,0,1,0,3.92H208V64.75A.23.23,0,0,1,207.81,65Z" />
      <Path d="M207.81,38.06H196.66a.24.24,0,0,1,0-.47h11.15a.24.24,0,0,1,0,.47Z" />
      <Path d="M191.37,117.9a.23.23,0,0,1-.24-.23V37.9a.24.24,0,1,1,.47,0v79.77A.23.23,0,0,1,191.37,117.9Z" />
      <Path className="cls-4" d="M136.83,117.75v-18l-10,.22.09,4.75a13.34,13.34,0,0,0,7.54.18v12.81Z" fill="#bac2ca" />
      <Path d="M136.74,117.9a.23.23,0,0,1-.24-.23V37.9a.24.24,0,1,1,.47,0v79.77A.23.23,0,0,1,136.74,117.9Z" />
      <Rect className="cls-1" fill="#e6ebef" height="14.64" transform="translate(233.9 201.11) rotate(180)" width="19.64" x="107.13" y="93.23" />
      <Polygon className="cls-3" fill="#fff" points="112.23 93.32 107.86 93.32 113.11 107.64 117.48 107.64 112.23 93.32" />
      <Polygon className="cls-3" fill="#fff" points="119.73 93.06 115.18 93.06 120.57 107.65 125.11 107.65 119.73 93.06" />
      <Path d="M126.77,108.11H107.13a.24.24,0,0,1-.24-.24V93.23a.24.24,0,0,1,.24-.23h19.64a.23.23,0,0,1,.23.23v14.64A.23.23,0,0,1,126.77,108.11Zm-19.41-.47h19.18V93.47H107.36Z" />
      <Path d="M108,107.41a.16.16,0,0,1-.16-.15V93.34a.16.16,0,0,1,.16-.15.15.15,0,0,1,.15.15v13.92A.15.15,0,0,1,108,107.41Z" />
      <Path d="M100.36,117.73a.16.16,0,0,1-.16-.15V85.15a.16.16,0,0,1,.16-.16.16.16,0,0,1,.15.16v32.43A.15.15,0,0,1,100.36,117.73Z" />
      <Path d="M82.22,117.73a.15.15,0,0,1-.15-.15V82.18a.15.15,0,0,1,.15-.15.16.16,0,0,1,.16.15v35.4A.16.16,0,0,1,82.22,117.73Z" />
      <Path className="cls-2" d="M141.67,79.39a8.69,8.69,0,0,1-1.37-4.93c0-.14,0-.27,0-.41,0-5.84-4.24-10.58-9.47-10.58s-9.46,4.74-9.46,10.58c0,.14,0,.27,0,.41A8.62,8.62,0,0,1,120,79.39a16,16,0,0,0-2.5,8.68c0,8.24,6,14.91,13.33,14.91s13.34-6.67,13.34-14.91A16,16,0,0,0,141.67,79.39Z" fill="#eff3f6" />
      <Path d="M130.84,103.22c-7.48,0-13.57-6.8-13.57-15.15a16.16,16.16,0,0,1,2.55-8.81,8.48,8.48,0,0,0,1.34-4.8c0-.13,0-.27,0-.41,0-6,4.35-10.81,9.69-10.81s9.7,4.85,9.7,10.81c0,.14,0,.28,0,.41a8.4,8.4,0,0,0,1.34,4.8,16.16,16.16,0,0,1,2.55,8.81C144.42,96.42,138.33,103.22,130.84,103.22Zm0-39.51c-5.08,0-9.22,4.64-9.22,10.34v.4a8.86,8.86,0,0,1-1.41,5.06,15.73,15.73,0,0,0-2.47,8.56c0,8.09,5.88,14.68,13.1,14.68S144,96.16,144,88.07a15.73,15.73,0,0,0-2.47-8.56,8.86,8.86,0,0,1-1.41-5.06v-.4C140.07,68.35,135.93,63.71,130.84,63.71Z" />
      <Path d="M130.49,118.07a.23.23,0,0,1-.23-.23V79.49a.23.23,0,1,1,.46,0v38.35A.23.23,0,0,1,130.49,118.07Z" />
      <Path d="M130.39,90.15a.23.23,0,0,1-.14,0l-3.42-2.46a.23.23,0,1,1,.27-.38l3.43,2.46a.23.23,0,0,1,.05.32A.23.23,0,0,1,130.39,90.15Z" />
      <Path d="M130.5,84.78a.26.26,0,0,1-.19-.1.23.23,0,0,1,.05-.32l3.43-2.46a.23.23,0,1,1,.27.38l-3.42,2.45A.24.24,0,0,1,130.5,84.78Z" />
      <Path d="M130.58,94.82a.22.22,0,0,1-.19-.09.24.24,0,0,1,.05-.33l3.43-2.46a.23.23,0,0,1,.27.38l-3.43,2.46A.22.22,0,0,1,130.58,94.82Z" />
      <Path d="M308.9,108.74a.31.31,0,0,1-.31-.31V81.13A1.42,1.42,0,0,1,310,79.71l3.84-.49a.31.31,0,0,1,.35.27.3.3,0,0,1-.27.34l-3.88.5a.81.81,0,0,0-.84.8v27.3A.31.31,0,0,1,308.9,108.74Z" />
      <Path d="M309.88,118.14h-2a.23.23,0,0,1-.23-.23v-9.44a.23.23,0,0,1,.23-.23h2a.23.23,0,0,1,.23.23v9.44A.23.23,0,0,1,309.88,118.14Zm-1.77-.47h1.54v-9h-1.54Z" />
      <Path d="M316.09,81.39h-1.75a1.14,1.14,0,1,1,0-2.27h1.75a1.14,1.14,0,0,1,0,2.27Zm-1.75-1.65a.52.52,0,0,0-.52.52.53.53,0,0,0,.52.52h1.75a.52.52,0,0,0,.51-.52.51.51,0,0,0-.51-.52Z" />
      <Path d="M194.54,108.74a.31.31,0,0,1-.31-.31V81.13a.8.8,0,0,0-.81-.8l-3.92-.5a.31.31,0,0,1,.08-.61l3.88.49h0a1.42,1.42,0,0,1,1.38,1.42v27.3A.31.31,0,0,1,194.54,108.74Z" />
      <Rect className="cls-3" fill="#fff" height="9.44" transform="translate(389.11 226.38) rotate(180)" width="2.01" x="193.55" y="108.47" />
      <Path d="M195.56,118.14h-2a.23.23,0,0,1-.23-.23v-9.44a.23.23,0,0,1,.23-.23h2a.23.23,0,0,1,.23.23v9.44A.23.23,0,0,1,195.56,118.14Zm-1.77-.47h1.54v-9h-1.54Z" />
      <Path d="M189.09,81.39h-1.74a1.14,1.14,0,1,1,0-2.27h1.74a1.14,1.14,0,1,1,0,2.27Zm-1.74-1.65a.52.52,0,0,0-.52.52.52.52,0,0,0,.52.52h1.74a.52.52,0,0,0,.52-.52.52.52,0,0,0-.52-.52Z" />
      <Path d="M22.27,108.74a.31.31,0,0,1-.31-.31V81.13a.8.8,0,0,0-.8-.8l-3.92-.5a.3.3,0,0,1-.27-.34.31.31,0,0,1,.34-.27l3.88.49h0a1.42,1.42,0,0,1,1.38,1.42v27.3A.31.31,0,0,1,22.27,108.74Z" />
      <Path d="M23.29,118.14h-2a.23.23,0,0,1-.24-.23v-9.44a.23.23,0,0,1,.24-.23h2a.23.23,0,0,1,.23.23v9.44A.23.23,0,0,1,23.29,118.14Zm-1.77-.47h1.54v-9H21.52Z" />
      <Path d="M16.82,81.39H15.08a1.14,1.14,0,1,1,0-2.27h1.74a1.14,1.14,0,1,1,0,2.27Zm-1.74-1.65a.52.52,0,0,0-.52.52.52.52,0,0,0,.52.52h1.74a.52.52,0,0,0,.52-.52.52.52,0,0,0-.52-.52Z" />
      <Polyline className="cls-5" fill={svgHighlight} points="35.69 118 35.69 96.11 75.81 96.11 75.81 118" />
      <Path d="M75.82,118.22a.27.27,0,0,1-.27-.26V96.37H36V118a.27.27,0,0,1-.53,0V96.11a.26.26,0,0,1,.26-.26H75.82a.26.26,0,0,1,.26.26V118A.26.26,0,0,1,75.82,118.22Z" />
      <Path d="M75.58,100.68H49.15a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15H75.58a.16.16,0,0,1,.16.15A.16.16,0,0,1,75.58,100.68Z" />
      <Path d="M48.06,100.68h-1a.16.16,0,0,1-.15-.16.15.15,0,0,1,.15-.15h1a.16.16,0,0,1,.16.15A.16.16,0,0,1,48.06,100.68Z" />
      <Path d="M45.47,100.68H36.75a.15.15,0,0,1-.15-.16.15.15,0,0,1,.15-.15h8.72a.15.15,0,0,1,.15.15A.16.16,0,0,1,45.47,100.68Z" />
      <Path d="M75.58,104.76H56.44a.16.16,0,0,1-.15-.16.15.15,0,0,1,.15-.15H75.58a.16.16,0,0,1,.16.15A.16.16,0,0,1,75.58,104.76Z" />
      <Path d="M55.38,104.76H36.75a.15.15,0,0,1-.15-.16.15.15,0,0,1,.15-.15H55.38a.15.15,0,0,1,.15.15A.16.16,0,0,1,55.38,104.76Z" />
      <Path d="M75.58,108.84H42.73a.16.16,0,0,1-.16-.15.16.16,0,0,1,.16-.16H75.58a.16.16,0,0,1,.16.16A.16.16,0,0,1,75.58,108.84Z" />
      <Path d="M41.47,108.84H36.75a.15.15,0,0,1-.15-.15.15.15,0,0,1,.15-.16h4.72a.16.16,0,0,1,.16.16A.16.16,0,0,1,41.47,108.84Z" />
      <Path d="M75.58,112.93H36.75a.15.15,0,0,1-.15-.16.14.14,0,0,1,.15-.15H75.58a.15.15,0,0,1,.16.15A.16.16,0,0,1,75.58,112.93Z" />
      <Path d="M36.67,117.54a.16.16,0,0,1-.16-.16v-21a.16.16,0,0,1,.16-.16.16.16,0,0,1,.15.16v21A.16.16,0,0,1,36.67,117.54Z" />
      <Path d="M77.26,96.29h-43A.26.26,0,0,1,34,96V93.18a.26.26,0,0,1,.26-.26h43a.26.26,0,0,1,.25.26V96A.25.25,0,0,1,77.26,96.29ZM34.5,95.77H77V93.44H34.5Z" />
      <Path d="M118,78.32h0l-46-7.15H27.06a.23.23,0,1,1,0-.46H72L118,77.86a.23.23,0,0,1,.19.26A.22.22,0,0,1,118,78.32Z" />
      <Rect className="cls-1" fill="#e6ebef" height="9.53" width="10.02" x="146.44" y="47.13" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="145.62 54.45 150.11 47.13 146.09 47.13 145.62 47.89 145.62 54.45" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="151.21 56.66 148.71 56.66 155.86 47.13 158.36 47.13 151.21 56.66" />
      <Path d="M156.61,57h-11a.23.23,0,0,1-.23-.23V46.53a.23.23,0,0,1,.23-.23h11a.23.23,0,0,1,.23.23V56.75A.23.23,0,0,1,156.61,57Zm-10.81-.46h10.58V46.77H145.8Z" />
      <Path d="M146.4,56.71a.15.15,0,0,1-.15-.15V47.31a.15.15,0,0,1,.15-.15h8.95a.15.15,0,0,1,.15.15.16.16,0,0,1-.15.16h-8.79v9.09A.16.16,0,0,1,146.4,56.71Z" />
      <Path d="M151.24,56a.16.16,0,0,1-.15-.16V48.06a.16.16,0,0,1,.15-.16.16.16,0,0,1,.16.16v7.81A.16.16,0,0,1,151.24,56Z" />
      <Path d="M156.6,52h-9.39a.15.15,0,0,1-.15-.15.16.16,0,0,1,.15-.16h9.39a.16.16,0,0,1,.15.16A.15.15,0,0,1,156.6,52Z" />
      <Rect className="cls-1" fill="#e6ebef" height="9.53" width="10.02" x="170.15" y="47.13" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="169.33 54.45 173.82 47.13 169.8 47.13 169.33 47.89 169.33 54.45" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="174.92 56.66 172.42 56.66 179.57 47.13 182.07 47.13 174.92 56.66" />
      <Path d="M180.32,57h-11a.23.23,0,0,1-.24-.23V46.53a.24.24,0,0,1,.24-.23h11a.23.23,0,0,1,.23.23V56.75A.23.23,0,0,1,180.32,57Zm-10.8-.46h10.57V46.77H169.52Z" />
      <Path d="M170.12,56.71a.16.16,0,0,1-.16-.15V47.31a.16.16,0,0,1,.16-.15h8.94a.16.16,0,0,1,.16.15.16.16,0,0,1-.16.16h-8.79v9.09A.15.15,0,0,1,170.12,56.71Z" />
      <Path d="M175,56a.16.16,0,0,1-.16-.16V48.06a.16.16,0,0,1,.16-.16.16.16,0,0,1,.15.16v7.81A.16.16,0,0,1,175,56Z" />
      <Path d="M180.31,52h-9.38a.16.16,0,0,1-.16-.15.16.16,0,0,1,.16-.16h9.38a.16.16,0,0,1,.16.16A.16.16,0,0,1,180.31,52Z" />
      <Rect className="cls-1" fill="#e6ebef" height="9.53" width="10.02" x="146.44" y="68.92" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="145.62 76.25 150.11 68.92 146.09 68.92 145.62 69.68 145.62 76.25" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="151.21 78.46 148.71 78.46 155.86 68.92 158.36 68.92 151.21 78.46" />
      <Path d="M156.61,78.78h-11a.23.23,0,0,1-.23-.24V68.33a.23.23,0,0,1,.23-.23h11a.23.23,0,0,1,.23.23V78.54A.23.23,0,0,1,156.61,78.78Zm-10.81-.47h10.58V68.56H145.8Z" />
      <Path d="M146.4,78.51a.16.16,0,0,1-.15-.16V69.11a.16.16,0,0,1,.15-.16h8.95a.16.16,0,0,1,.15.16.15.15,0,0,1-.15.15h-8.79v9.09A.16.16,0,0,1,146.4,78.51Z" />
      <Path d="M151.24,77.82a.15.15,0,0,1-.15-.15V69.85a.15.15,0,0,1,.15-.15.16.16,0,0,1,.16.15v7.82A.16.16,0,0,1,151.24,77.82Z" />
      <Path d="M156.6,73.79h-9.39a.16.16,0,0,1-.15-.16.15.15,0,0,1,.15-.15h9.39a.15.15,0,0,1,.15.15A.16.16,0,0,1,156.6,73.79Z" />
      <Rect className="cls-1" fill="#e6ebef" height="9.53" width="10.02" x="170.15" y="68.92" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="169.33 76.25 173.82 68.92 169.8 68.92 169.33 69.68 169.33 76.25" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="174.92 78.46 172.42 78.46 179.57 68.92 182.07 68.92 174.92 78.46" />
      <Path d="M180.32,78.78h-11a.24.24,0,0,1-.24-.24V68.33a.23.23,0,0,1,.24-.23h11a.23.23,0,0,1,.23.23V78.54A.23.23,0,0,1,180.32,78.78Zm-10.8-.47h10.57V68.56H169.52Z" />
      <Path d="M170.12,78.51a.16.16,0,0,1-.16-.16V69.11a.16.16,0,0,1,.16-.16h8.94a.16.16,0,0,1,.16.16.16.16,0,0,1-.16.15h-8.79v9.09A.16.16,0,0,1,170.12,78.51Z" />
      <Path d="M175,77.82a.16.16,0,0,1-.16-.15V69.85a.16.16,0,0,1,.16-.15.15.15,0,0,1,.15.15v7.82A.15.15,0,0,1,175,77.82Z" />
      <Path d="M180.31,73.79h-9.38a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h9.38a.16.16,0,0,1,.16.15A.16.16,0,0,1,180.31,73.79Z" />
      <Rect className="cls-1" fill="#e6ebef" height="9.53" width="10.02" x="146.44" y="90.72" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="145.62 98.04 150.11 90.72 146.09 90.72 145.62 91.48 145.62 98.04" />
      <Polygon className="cls-6" fill="#fff" opacity={0.7} points="151.21 100.25 148.71 100.25 155.86 90.72 158.36 90.72 151.21 100.25" />
      <Path d="M156.61,100.57h-11a.23.23,0,0,1-.23-.23V90.13a.23.23,0,0,1,.23-.24h11a.23.23,0,0,1,.23.24v10.21A.23.23,0,0,1,156.61,100.57Zm-10.81-.46h10.58V90.36H145.8Z" />
      <Path d="M146.4,100.3a.15.15,0,0,1-.15-.15V90.9a.15.15,0,0,1,.15-.15h8.95a.15.15,0,0,1,.15.15.16.16,0,0,1-.15.16h-8.79v9.09A.16.16,0,0,1,146.4,100.3Z" />
      <Path d="M151.24,99.62a.15.15,0,0,1-.15-.15V91.65a.16.16,0,0,1,.15-.16.16.16,0,0,1,.16.16v7.82A.16.16,0,0,1,151.24,99.62Z" />
      <Path d="M156.6,95.58h-9.39a.15.15,0,0,1-.15-.15.16.16,0,0,1,.15-.16h9.39a.16.16,0,0,1,.15.16A.15.15,0,0,1,156.6,95.58Z" />
      <Polyline className="cls-5" fill={svgHighlight} points="167.06 113.74 167.06 92.32 182.45 92.32 182.45 113.74" />
      <Path d="M182.45,114a.23.23,0,0,1-.23-.23V92.55H167.3v21.19a.24.24,0,0,1-.24.23.23.23,0,0,1-.23-.23V92.32a.23.23,0,0,1,.23-.23h15.39a.23.23,0,0,1,.23.23v21.42A.23.23,0,0,1,182.45,114Z" />
      <Rect className="cls-3" fill="#fff" height="9.45" width="3.3" x="170.17" y="95.5" />
      <Path d="M173.46,105.1h-3.3A.15.15,0,0,1,170,105V95.5a.16.16,0,0,1,.15-.16h3.3a.16.16,0,0,1,.16.16V105A.15.15,0,0,1,173.46,105.1Zm-3.14-.3h3V95.65h-3Z" />
      <Rect className="cls-3" fill="#fff" height="9.45" width="3.3" x="175.81" y="95.5" />
      <Path d="M179.11,105.1h-3.3a.15.15,0,0,1-.16-.15V95.5a.16.16,0,0,1,.16-.16h3.3a.16.16,0,0,1,.15.16V105A.15.15,0,0,1,179.11,105.1Zm-3.15-.3h3V95.65h-3Z" />
      <Path d="M181.13,118.22H161.89a.23.23,0,0,1-.23-.23v-2a.23.23,0,0,1,.23-.23h1.66v-1.72a.23.23,0,0,1,.23-.23h17.35a.23.23,0,0,1,.23.23V118A.23.23,0,0,1,181.13,118.22Zm-19-.46H180.9v-3.47H164V116a.23.23,0,0,1-.23.23h-1.66Z" />
      <Polygon className="cls-4" fill="#bac2ca" points="180.15 114.05 180.15 116 178.26 116 178.26 117.99 180.15 117.99 180.37 117.99 182.53 117.99 182.53 114.05 180.15 114.05" />
      <Path d="M182.53,118.22h-4.26A.23.23,0,0,1,178,118v-2a.23.23,0,0,1,.24-.23h1.65v-1.72a.23.23,0,0,1,.23-.23h2.38a.23.23,0,0,1,.24.23V118A.23.23,0,0,1,182.53,118.22Zm-4-.46h3.8v-3.47h-1.91V116a.23.23,0,0,1-.24.23H178.5Z" />
      <Path d="M177.14,116.16H163.86a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h13.28a.15.15,0,0,1,.15.15A.16.16,0,0,1,177.14,116.16Z" />
      <Path d="M182.3,90.09H166.9a.24.24,0,1,1,0-.47h15.4a.24.24,0,0,1,0,.47Z" />
      <Path d="M163.88,109.76h-27.1a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h27.1a.15.15,0,0,1,.15.15A.15.15,0,0,1,163.88,109.76Z" />
      <Path d="M193.23,109.54h-8.89a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h8.89a.16.16,0,0,1,.16.15A.16.16,0,0,1,193.23,109.54Z" />
      <Path d="M207.14,109.54H195.76a.16.16,0,0,1-.16-.16.16.16,0,0,1,.16-.15h11.38a.15.15,0,0,1,.15.15A.16.16,0,0,1,207.14,109.54Z" />
      <Path d="M319.34,118.23h-1a.24.24,0,1,1,0-.47h1a.24.24,0,0,1,0,.47Z" />
      <Path d="M8.25,118.23H6.7a.23.23,0,1,1,0-.46H8.25a.23.23,0,0,1,0,.46Z" />
      <Path d="M317,118.22H188a.23.23,0,1,1,0-.46H317a.23.23,0,1,1,0,.46Z" />
      <Path d="M188.88,118.23H10.25a.23.23,0,1,1,0-.46H188.88a.23.23,0,1,1,0,.46Z" />
      <Path d="M253.16,84.32a1.89,1.89,0,0,1-1.41-.65L240,73.91a.22.22,0,0,1,0-.32.24.24,0,0,1,.33,0L252,83.3a1.42,1.42,0,0,0,1.22.55.78.78,0,0,0,.56-.26,1,1,0,0,0,.22-.89.78.78,0,0,0-.44-.64l-.06,0L235.65,67.67a.23.23,0,0,1,0-.32.24.24,0,0,1,.33,0l17.87,14.33a1.23,1.23,0,0,1,.69,1,1.45,1.45,0,0,1-.39,1.33,1.24,1.24,0,0,1-.84.37Z" />
    </Svg>
  );
};

export default StatementsHouse;
