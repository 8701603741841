/* eslint-disable max-len */
import React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function GraphLever(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 35 35"
      height={moderateScale(35)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 35 35"
      width={moderateScale(35)}
      x="0px"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Circle cx="17.5" cy="18.184" fill="#FFFFFF" r="16.023" />
      </G>
      <Path
        d="M9.746,18.61l4.253,4.134l-0.576,0.56l-5.266-5.12l5.266-5.12l0.576,0.56l-4.253,4.135l-0.438,0.425L9.746,18.61z M21.004,22.744l4.253-4.134l0.438-0.425l-0.438-0.425l-4.253-4.135l0.576-0.56l5.266,5.12l-5.266,5.12L21.004,22.744z"
        fill="#282828"
        stroke={primary}
        strokeWidth="1.1869"
      />
      <Path d="M10.217,18.184h14.566" fill="none" stroke={primary} strokeWidth="1.7803" />
    </Svg>
  );
}
