import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const Banner = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px
  width: 100%;
`;

export const IllustrationWrapper = styled.View`
  justify-content: center;
`;

export const MessageContainer = styled.View`
  align-self: center;
  flex: 1;
  margin-left: ${moderateScale(12)}px;
`;
