/**
* AsyncRender
* @description: allows the async rendering of components based on data fetch from the server,
* conditionally rendering either a loading component, error component, or the component passed in.
* Use Case: used by components that complete a data fetch onMount
*/

import React from 'react';
import T from 'prop-types';

import ConditionalRender from '../base_ui/ConditionalRender';
import LoadingIndicator from '../base_ui/LoadingIndicator';
import errorComponentDictionary from './errorComponentDictionary';

const AsyncRender = ({
  Component,
  CustomErrorComponent,
  error,
  errorComponentProps = {},
  errorComponentType = 'pageLevel',
  loading,
  LoadingComponent,
  loadingComponentProps,
  propsToPassDown = {},
}) => {
  if (error) {
    const ErrorComponent = CustomErrorComponent || errorComponentDictionary[errorComponentType];
    return (
      <ErrorComponent error={error} {...errorComponentProps} />
    );
  }
  if (loading) {
    return (
      <ConditionalRender
        Component={LoadingComponent}
        CustomErrorComponent={CustomErrorComponent}
        FallbackComponent={LoadingIndicator}
        propsToPassDown={loadingComponentProps}
        shouldRender={!!LoadingComponent}
      />
    );
  }
  return <Component {...propsToPassDown} />;
};

AsyncRender.propTypes = {
  Component: T.oneOfType([T.func, T.object]).isRequired,
  CustomErrorComponent: T.oneOfType([T.func, T.object]),
  error: T.oneOfType([T.bool, T.object, T.string]).isRequired,
  errorComponentProps: T.object,
  errorComponentType: T.oneOf(['cardLevel', 'pageLevel']),
  loading: T.bool.isRequired,
  LoadingComponent: T.oneOfType([T.func, T.object]),
  loadingComponentProps: T.object,
  propsToPassDown: T.object,
};

export default AsyncRender;
