import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getLoanAddresses,
  getLoanNumberDictionary,
  getMainClientInfo,
} from '@dmi/shared/redux/Main/selectors';
import {
  cancelChangeMailingAddress,
  changeStep,
  closeModalState,
  fetchMailingAddressAll,
  openModalState,
  resetStatusState,
  resetStepState,
  setSelectedLoan,
} from '@dmi/shared/redux/Settings/Loans/actions';
import makeSelectLoans, {
  selectEditableMailingAddress,
  selectLoanMailingAddress,
  selectPropertyAddress,
  selectPropertyAddressesPendingFirst,
  selectRequestStatus,
  selectSelectedLoan,
  selectServiceError,
} from '@dmi/shared/redux/Settings/Loans/selectors';

import { ConditionalRender, StyledScrollView } from '../../../components/base_ui';
import AsyncRender from '../../../components/AsyncRender';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { SelectMailingAddress } from '../../../components/Settings/MailingAddresses';
import CancelMailingAddressDrawer from '../../../components/Settings/CancelMailingAddressDrawer';
import { SelectButtonWrapper, StyledPrimaryButton } from './styledComponents';

const SelectMailingAddressScreen = ({
  cancelChangeMailingAddressStatus,
  currentAddress,
  customerServicePhoneNumber,
  dispatchCancelMailingAddress,
  dispatchChangeStep,
  dispatchCloseModal,
  dispatchOpenModal,
  dispatchResetStatusState,
  dispatchResetStepState,
  dispatchSetSelectedLoan,
  hashedSelectedLoan,
  isOpen,
  loanNumbers,
  mailingAddressProps,
  modalState,
  navigation,
  pendingAddress,
  propertyAddresses,
  propertyAddressesPendingFirst,
  requestStatusFetchAll,
  serviceError,
  step,
  streetAddress,
  unmaskedSelectedLoan,
}) => {
  useEffect(() => () => {
    dispatchSetSelectedLoan({ loanNumber: '' });
  }, [dispatchSetSelectedLoan]);

  const handleOpenModal = ({ loanNumber }) => {
    dispatchOpenModal({ modalState: 'cancelMailingAddress' });
    dispatchSetSelectedLoan({ loanNumber });
  };

  const handleClose = () => {
    if (cancelChangeMailingAddressStatus !== 'loading') {
      dispatchCloseModal();
      dispatchResetStatusState();
      dispatchResetStepState();
    }
  };

  const {
    canEditOnline,
    canUpload,
  } = mailingAddressProps[hashedSelectedLoan] || {};

  const isLoading = requestStatusFetchAll === 'loading' || requestStatusFetchAll === 'idle';

  const isSelectButtonDisabled = isLoading ||
    hashedSelectedLoan === '' ||
    (!canEditOnline && !canUpload);

  const handleSelect = () => {
    if (canEditOnline) {
      navigation.navigate('ChangeMailingAddress');
    }
    if (canUpload) {
      navigation.navigate('MailingAddressUploadDocument');
    }
  };

  const propsToPassDown = {
    dispatchSetSelectedLoan,
    handleOpenModal,
    hashedSelectedLoan,
    isLoading,
    loanNumbers,
    mailingAddressProps: isLoading ? {} : mailingAddressProps,
    propertyAddresses: isLoading ? propertyAddresses : propertyAddressesPendingFirst,
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Edit Mailing Address"
      />
      <StyledScrollView>
        <AsyncRender
          Component={SelectMailingAddress}
          error={serviceError}
          errorComponentProps={{
            body: `Please try again later. For assistance, contact ${customerServicePhoneNumber}.`,
            link: {
              label: 'Return to Settings',
              path: 'Settings',
            },
          }}
          loading={isLoading}
          LoadingComponent={SelectMailingAddress}
          loadingComponentProps={propsToPassDown}
          propsToPassDown={propsToPassDown}
        />
      </StyledScrollView>
      <SelectButtonWrapper>
        <StyledPrimaryButton
          disabled={isSelectButtonDisabled}
          label="Select"
          onPress={handleSelect}
        />
      </SelectButtonWrapper>
      <ConditionalRender
        Component={CancelMailingAddressDrawer}
        propsToPassDown={{
          currentAddress,
          customerServicePhoneNumber,
          dispatchCancelMailingAddress,
          dispatchChangeStep,
          handleClose,
          isOpen,
          navigation,
          pendingAddress,
          status: cancelChangeMailingAddressStatus,
          step,
          streetAddress,
          unmaskedSelectedLoan,
        }}
        shouldRender={modalState === 'cancelMailingAddress'}
      />
    </Fragment>
  );
};

SelectMailingAddressScreen.propTypes = {
  cancelChangeMailingAddressStatus: T.string.isRequired,
  currentAddress: T.object.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchCancelMailingAddress: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchCloseModal: T.func.isRequired,
  dispatchOpenModal: T.func.isRequired,
  dispatchResetStatusState: T.func.isRequired,
  dispatchResetStepState: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  hashedSelectedLoan: T.string.isRequired,
  isOpen: T.bool.isRequired,
  loanNumbers: T.object.isRequired,
  mailingAddressProps: T.object.isRequired,
  modalState: T.string.isRequired,
  navigation: T.object.isRequired,
  pendingAddress: T.object.isRequired,
  propertyAddresses: T.array.isRequired,
  propertyAddressesPendingFirst: T.array.isRequired,
  requestStatusFetchAll: T.string.isRequired,
  serviceError: T.oneOfType([T.bool, T.string]).isRequired,
  step: T.number.isRequired,
  streetAddress: T.string.isRequired,
  unmaskedSelectedLoan: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  cancelChangeMailingAddressStatus: selectRequestStatus('cancelChangeMailingAddress'),
  currentAddress: selectLoanMailingAddress('current'),
  hashedSelectedLoan: selectSelectedLoan('hashedLoanNumber'),
  isOpen: makeSelectLoans('isModalOpen'),
  mailingAddressProps: selectEditableMailingAddress(),
  modalState: makeSelectLoans('modalState'),
  pendingAddress: selectLoanMailingAddress('pending'),
  requestStatusFetchAll: selectRequestStatus('fetchMailingAddressAll'),
  serviceError: selectServiceError('mailingAddress'),
  step: makeSelectLoans('step'),
  streetAddress: selectPropertyAddress(true),
  unmaskedSelectedLoan: selectSelectedLoan('unmaskedLoanNumber'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  loanNumbers: getLoanNumberDictionary(),
  propertyAddresses: getLoanAddresses(),
  propertyAddressesPendingFirst: selectPropertyAddressesPendingFirst(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store : Loans
   */
  dispatchCancelMailingAddress: () => dispatch(cancelChangeMailingAddress()),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchCloseModal: () => dispatch(closeModalState()),
  dispatchFetchMailingAddressAll: () => dispatch(fetchMailingAddressAll()),
  dispatchOpenModal: (payload) => dispatch(openModalState(payload)),
  dispatchResetStatusState: () => dispatch(resetStatusState()),
  dispatchResetStepState: () => dispatch(resetStepState()),
  dispatchSetSelectedLoan: (payload) => dispatch(setSelectedLoan(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SelectMailingAddressScreen);
