import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  ERROR_CONFIRMATION_TEXT,
  POST_MESSAGE_FAILURE_ERROR_MESSAGE,
} from '@dmi/shared/redux/LossDraft/constants';
import {
  selectConfirmationData,
  selectRequestStatus,
} from '@dmi/shared/redux/LossDraft/selectors';
import { selectCustomerServicePhoneNumber } from '@dmi/shared/redux/Main/selectors';

import { StyledScrollView } from '../../../components/base_ui';
import MessagesConfirmationFailed from '../../../components/MessagesConfirmation/Failed';

const NewMessageConfirmationFailed = ({
  customerServicePhoneNumber,
  handleResetState,
}) => {
  const confirmationCopyDictionary = {
    heading: POST_MESSAGE_FAILURE_ERROR_MESSAGE,
    text: ERROR_CONFIRMATION_TEXT,
  };
  const confirmationFailedLinkDictionary = {
    linkLabel: 'Return to Claims Center',
    mobilePath: 'LossDraft',
  };

  useEffect(() => () => {
    handleResetState();
  }, [handleResetState]);

  return (
    <StyledScrollView>
      <MessagesConfirmationFailed
        confirmationCopyDictionary={confirmationCopyDictionary}
        confirmationFailedLinkDictionary={confirmationFailedLinkDictionary}
        customerServicePhoneNumber={customerServicePhoneNumber}
      />
    </StyledScrollView>
  );
};

NewMessageConfirmationFailed.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  handleResetState: T.func.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  confirmationData: selectConfirmationData('postNewMessage'),
  requestStatusPostMessage: selectRequestStatus('postMessage'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: selectCustomerServicePhoneNumber(),
});

const withConnect = connect(mapStateToProps);

export default withConnect(NewMessageConfirmationFailed);
