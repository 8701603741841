import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';
import { Platform } from 'react-native';
import isEmpty from 'lodash/isEmpty';

import makeSelectDisclosures,
{ makeSelectDisclosuresContent } from '@dmi/shared/redux/Disclosures/selectors';
import {
  fetchLossDraft,
  fetchMessages as fetchLossDraftMessages,
} from '@dmi/shared/redux/LossDraft/actions';
import {
  disableInstantPostLoginModal,
  dismissDocUploadStatusBanner,
  fetchRespaLetter,
  fetchTransferStatus,
  putLanguagePreference,
  putPaperlessSetting,
  resetMainError,
  setInstantPostLoginModalStep,
  setRespaFile,
  toggleRespaDrawer,
} from '@dmi/shared/redux/Main/actions';
import makeSelectMain, {
  getError,
  getMainClientInfo,
  getMostRecentDocUploadNotificationFailure,
  getSelectedLoanAddress,
  getSsoClientImageProps,
  getUserDisplayName,
  selectInstantPostLoginModalViewProps,
  selectIsMurabahaAccount,
  selectIsPastServicingPaymentCutOffDate,
  selectLanguagePreferenceProps,
  selectMainStatus,
  selectPaperlessBillingProps,
  selectTransferStatusBannerProps,
} from '@dmi/shared/redux/Main/selectors';
import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { selectNestedScreen } from '@dmi/shared/redux/Mobile/selectors';
import { fetchPostAuthNotifications } from '@dmi/shared/redux/Notifications/actions';
import {
  selectNotificationsModalFetchRequestsLoading,
  selectShouldNotificationsModalAutoOpen,
} from '@dmi/shared/redux/Notifications/selectors';
import {
  cancel,
  fetchStatus,
  setIsModalOpen as setIsAutopayModalOpen,
  setIsInAutopayFlow,
} from '@dmi/shared/redux/Payments/Autopay/actions';
import { generateCancelAutopayModalProps } from '@dmi/shared/redux/Payments/Autopay/helpers';
import autopayReducer from '@dmi/shared/redux/Payments/Autopay/reducer';
import autopaySaga from '@dmi/shared/redux/Payments/Autopay/saga';
import {
  selectAllowedOperationIsInAutoapyFlow,
  selectAutopayStatus,
  selectIsAutopayEnabledOrPending,
  selectIsModalOpen as selectIsAutopayModalOpen,
  selectIsEligibleForAutopay,
  selectStatusDataProp,
  selectStatusMessageProps,
  selectStatusProp,
} from '@dmi/shared/redux/Payments/Autopay/selectors';
import {
  fetchTransactions,
  resetPaymentHistoryState,
} from '@dmi/shared/redux/Payments/History/actions';
import historyReducer from '@dmi/shared/redux/Payments/History/reducer';
import historySaga from '@dmi/shared/redux/Payments/History/saga';
import makeSelectPaymentHistory, {
  makeSelectError,
  selectPaymentHomeHistoryData,
} from '@dmi/shared/redux/Payments/History/selectors';
import {
  cancelOtp,
  fetchPaymentHome,
  setIsModalOpen as setIsOtpModalOpen,
  updatePaymentHomeInit,
} from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import { generateCancelOtpModalProps } from '@dmi/shared/redux/Payments/OneTimePayment/helpers';
import { fetchMessages } from '@dmi/shared/redux/SecureMessaging/actions';
import {
  fetchAlertSettings,
  fetchPaperlessSettingAll,
} from '@dmi/shared/redux/Settings/Communications/actions';
import {
  selectIsEligibleForPaperlessBilling,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Communications/selectors';
import otpReducer from '@dmi/shared/redux/Payments/OneTimePayment/reducer';
import otpSaga from '@dmi/shared/redux/Payments/OneTimePayment/saga';
import makeSelectPayments, {
  selectCancelModalProps,
  selectIsModalOpen as selectIsOtpModalOpen,
  selectOtp,
  selectOtpError,
  selectOtpForm,
  selectPaymentHomeData,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';
import sourcesReducer from '@dmi/shared/redux/Payments/Sources/reducer';
import sourcesSaga from '@dmi/shared/redux/Payments/Sources/saga';
import { fetchFees } from '@dmi/shared/redux/Fees/actions';

import { usePrevious } from '../../../utils/customHooks';
import injectReducer from '../../../utils/injectReducer';
import { getBaseApplicationServerUrl } from '../../../utils/globalHelpers';
import injectSaga, { useInjectSaga } from '../../../utils/injectSaga';
import { navigate, replace } from '../../../utils/rootNavigation';
import PDFDisplay from '../../../components/PDFDisplay';
import AsyncRender from '../../../components/AsyncRender';
import { ConditionalRender, StyledScrollView } from '../../../components/base_ui';
import LoginLoadingComponent from '../../../components/LoadingDog';
import {
  NYCLanguagePreferenceModal,
  PaperlessBillingModal,
  SsoPretransferModal,
} from '../../../components/ModalViews';
import PaymentHomeView from './HomeView';

const baseUrl = getBaseApplicationServerUrl();

const PaymentHomeScreen = ({
  address,
  autopayAllowedOperation,
  autopayFetchStatus,
  autopayStatus,
  autopayStatusProps,
  cancelAutopayStatus,
  cancelModalProps,
  customerServicePhoneNumber,
  disclosures,
  dispatchCancelAutopay,
  dispatchCancelOtp,
  dispatchDisableInstantPostLoginModal,
  dispatchDismissDocUploadStatusBanner,
  dispatchFetchAlertSettings,
  dispatchFetchAutopayStatus,
  dispatchFetchFees,
  dispatchFetchLossDraft,
  dispatchFetchLossDraftMessages,
  dispatchFetchMessages,
  dispatchFetchPaperlessSettingAll,
  dispatchFetchPaymentHome,
  dispatchFetchPostAuthNotifications,
  dispatchFetchRespaLetter,
  dispatchFetchTransactions,
  dispatchFetchTransferStatus,
  dispatchPutLanguagePreference,
  dispatchPutPaperlessSetting,
  dispatchResetMainError,
  dispatchResetPaymentHistoryState,
  dispatchSetInstantPostLoginModalStep,
  dispatchSetIsAutopayModalOpen,
  dispatchSetIsInAutopayFlow,
  dispatchSetIsOtpModalOpen,
  dispatchSetReduxNestedScreen,
  dispatchSetRespaFile,
  dispatchToggleRespaDrawer,
  dispatchUpdatePaymentHomeInit,
  docUploadNotificationFailure,
  fetchDisclosureRequestStatus,
  fetchPaperlessStatus,
  fetchTransferRequestStatus,
  historyData,
  historyLoading: { transactions: homeHistoryLoading },
  historyServiceError: { transactions: homeHistoryError },
  instantPostLoginModalViewProps: {
    isModalViewOpen,
    modalViewName,
    modalViewStep,
  },
  isAutopayEnabledOrPending,
  isCancelAutopayModalOpen,
  isCancelOtpModalOpen,
  isEligibleForAutopay,
  isEligibleForPaperlessBilling,
  isInAutopayFlow,
  isLoanMancodeY,
  isMurabahaAccount,
  isPastServicingPaymentCutOffDate,
  isPaymentHomeInit,
  isRespaDrawerOpen,
  languagePreferenceProps,
  loanNumber,
  navigation,
  notificationsModalFetchRequestsLoading,
  otpAllowedOperation,
  otpData,
  otpLoading: { otp: otpLoading },
  otpRequestStatus: { paymentHome: paymentHomeLoading },
  otpServiceError: { paymentHome: paymentHomeServiceError },
  paperlessBillingProps,
  reduxNestedScreen,
  respaFile,
  respaLetterError,
  route,
  shouldNotificationsModalAutoOpen,
  ssoClientImageProps,
  status,
  transferStatus,
  transferStatusBannerProps,
  userDisplayName,
}) => {
  const [modalProps, setModalProps] = useState({});
  const prevIsCancelAutopayModalOpen = usePrevious(isCancelAutopayModalOpen);
  const prevIsCancelOtpModalOpen = usePrevious(isCancelOtpModalOpen);
  const prevLoanNumber = usePrevious(loanNumber);

  useInjectSaga({
    key: 'autopay',
    saga: autopaySaga({
      baseUrl,
      navigationHandler: navigate,
      navigationReplaceHandler: replace,
    }),
  });

  useInjectSaga({
    key: 'oneTimePayment',
    saga: otpSaga({
      baseUrl,
      navigationHandler: navigate,
      navigationReplaceHandler: replace,
    }),
  });

  useEffect(() => {
    if (isPaymentHomeInit && paymentHomeLoading !== 'loading' && !homeHistoryLoading) {
      dispatchUpdatePaymentHomeInit(false);
    }
  }, [
    dispatchUpdatePaymentHomeInit,
    isPaymentHomeInit,
    paymentHomeLoading,
    homeHistoryLoading,
  ]);

  useEffect(() => {
    if (prevLoanNumber !== loanNumber) {
      dispatchResetPaymentHistoryState();
      dispatchFetchAutopayStatus();
      dispatchFetchPaymentHome(loanNumber);
      dispatchFetchPostAuthNotifications();
      dispatchFetchTransactions(loanNumber);
      dispatchFetchTransferStatus();
      dispatchFetchLossDraftMessages();
      dispatchFetchLossDraft(loanNumber);
      dispatchFetchMessages();
      dispatchFetchFees({ isLoggedIn: true, loanNumber });
      dispatchFetchLossDraft(loanNumber);
    }
    if (fetchTransferRequestStatus === 'idle') {
      dispatchFetchTransferStatus();
    }
  }, [
    dispatchFetchAutopayStatus,
    dispatchFetchFees,
    dispatchFetchLossDraft,
    dispatchFetchLossDraftMessages,
    dispatchFetchMessages,
    dispatchFetchPaymentHome,
    dispatchFetchPostAuthNotifications,
    dispatchFetchTransactions,
    dispatchFetchTransferStatus,
    dispatchResetMainError,
    dispatchResetPaymentHistoryState,
    fetchTransferRequestStatus,
    loanNumber,
    prevLoanNumber,
  ]);

  useEffect(() => {
    if (shouldNotificationsModalAutoOpen) {
      navigation.navigate('Notifications');
      dispatchDisableInstantPostLoginModal();
    }
  }, [
    dispatchDisableInstantPostLoginModal,
    navigation,
    shouldNotificationsModalAutoOpen,
  ]);

  useEffect(() => {
    if (autopayFetchStatus === 'idle') {
      dispatchFetchAutopayStatus();
      dispatchFetchAlertSettings();
    }
    if (fetchPaperlessStatus === 'idle') {
      dispatchFetchPaperlessSettingAll();
    }
  }, [
    autopayFetchStatus,
    dispatchFetchAlertSettings,
    dispatchFetchAutopayStatus,
    dispatchFetchPaperlessSettingAll,
    fetchPaperlessStatus,
    loanNumber,
    prevLoanNumber,
  ]);

  useEffect(() => {
    const cancelOtpModalProps = generateCancelOtpModalProps({
      ...cancelModalProps,
      dispatchCancelOtp,
      dispatchSetIsModalOpen: dispatchSetIsOtpModalOpen,
      isOpen: isCancelOtpModalOpen,
      loading: otpLoading,
    });

    const cancelAutopayModalProps = generateCancelAutopayModalProps({
      autopayStatus,
      handleCloseModal: () => dispatchSetIsAutopayModalOpen({ key: 'cancel', value: false }),
      handleOnPress: dispatchCancelAutopay,
      isOpen: isCancelAutopayModalOpen,
      status: cancelAutopayStatus,
    });

    if (
      isCancelOtpModalOpen ||
      prevIsCancelOtpModalOpen !== isCancelOtpModalOpen
    ) setModalProps(cancelOtpModalProps);
    if (
      isCancelAutopayModalOpen ||
      prevIsCancelAutopayModalOpen !== isCancelAutopayModalOpen
    ) setModalProps(cancelAutopayModalProps);
  }, [
    autopayStatus,
    cancelAutopayStatus,
    cancelModalProps,
    dispatchCancelAutopay,
    dispatchCancelOtp,
    dispatchSetIsAutopayModalOpen,
    dispatchSetIsOtpModalOpen,
    isCancelAutopayModalOpen,
    isCancelOtpModalOpen,
    otpLoading,
    prevIsCancelAutopayModalOpen,
    prevIsCancelOtpModalOpen,
  ]);

  useEffect(() => {
    const { params } = route;
    const { nestedScreen: routeNestedScreen } = params || {};
    if (routeNestedScreen || reduxNestedScreen) {
      if (Platform.OS === 'web') {
        setTimeout(() => {
          navigation.navigate(routeNestedScreen || reduxNestedScreen);
          navigation.setParams({ nestedScreen: undefined });
        }, 0);
      } else if (routeNestedScreen) {
        navigation.navigate(routeNestedScreen);
        navigation.setParams({ nestedScreen: undefined });
      }
    }
  }, [
    navigation,
    reduxNestedScreen,
    route,
  ]);

  useFocusEffect(
    useCallback(() => {
      if (paymentHomeLoading === 'idle') {
        dispatchFetchPaymentHome(loanNumber);
        dispatchFetchTransactions();
      }
      return () => dispatchSetReduxNestedScreen({ mainScreen: 'payments', nestedScreen: '' });
    }, [
      dispatchFetchPaymentHome,
      dispatchFetchTransactions,
      dispatchSetReduxNestedScreen,
      loanNumber,
      paymentHomeLoading,
    ]),
  );

  useEffect(() => {
    if (isInAutopayFlow) {
      dispatchSetIsInAutopayFlow({ value: true });
    }
  }, [dispatchSetIsInAutopayFlow, isInAutopayFlow]);

  const fetchStatusLoading = autopayFetchStatus === 'loading';

  const handleRowPress = () => {
    dispatchFetchRespaLetter({
      dispatchSetFile: dispatchSetRespaFile,
      dispatchToggleDrawer: dispatchToggleRespaDrawer,
      statementId: transferStatus.respaLetterId,
    });
  };

  return (
    <StyledScrollView>
      <AsyncRender
        Component={PaymentHomeView}
        error={false}
        loading={isPaymentHomeInit &&
          (['idle', 'loading'].includes(paymentHomeLoading) ||
          homeHistoryLoading)}
        LoadingComponent={LoginLoadingComponent}
        propsToPassDown={{
          address,
          autopayAllowedOperation,
          autopayStatus,
          autopayStatusProps,
          customerServicePhoneNumber,
          dispatchDismissDocUploadStatusBanner,
          dispatchResetMainError,
          docUploadNotificationFailure,
          fetchStatusLoading,
          fetchTransferRequestStatus,
          handleRowPress,
          historyData,
          homeHistoryError,
          homeHistoryLoading,
          isAutopayEnabledOrPending,
          isEligibleForAutopay,
          isEligibleForPaperlessBilling,
          isLoanMancodeY,
          isMurabahaAccount,
          isPastServicingPaymentCutOffDate,
          modalProps,
          navigation,
          otpAllowedOperation,
          otpData,
          paymentHomeLoading,
          paymentHomeServiceError,
          respaLetterError,
          respaLetterId: transferStatus.respaLetterId,
          status,
          transferStatusBannerProps,
          userDisplayName,
        }}
      />
      <ConditionalRender
        Component={NYCLanguagePreferenceModal}
        propsToPassDown={{
          dispatchDisableInstantPostLoginModal,
          dispatchPutLanguagePreference,
          dispatchSetInstantPostLoginModalStep,
          isModalViewOpen,
          languagePreferenceProps,
          loanNumber,
          modalViewStep,
        }}
        shouldRender={
          modalViewName === 'languagePreference'
          && !notificationsModalFetchRequestsLoading
        }
      />
      <ConditionalRender
        Component={SsoPretransferModal}
        propsToPassDown={{
          dispatchDisableInstantPostLoginModal,
          isModalViewOpen,
          ssoClientImageProps,
        }}
        shouldRender={modalViewName === 'ssoPretransfer'
        && !notificationsModalFetchRequestsLoading
        && !shouldNotificationsModalAutoOpen}
      />
      <ConditionalRender
        Component={PaperlessBillingModal}
        propsToPassDown={{
          disclosures,
          dispatchDisableInstantPostLoginModal,
          dispatchPutPaperlessSetting,
          dispatchSetInstantPostLoginModalStep,
          fetchDisclosureRequestStatus,
          isModalViewOpen,
          loanNumber,
          modalViewStep,
          paperlessBillingProps,
        }}
        shouldRender={
          modalViewName === 'paperlessBilling'
          && !notificationsModalFetchRequestsLoading
          && !shouldNotificationsModalAutoOpen
        }
      />
      <ConditionalRender
        Component={PDFDisplay}
        propsToPassDown={{
          dispatchSetFile: dispatchSetRespaFile,
          dispatchToggleDrawer: dispatchToggleRespaDrawer,
          file: respaFile,
          isDrawerOpen: isRespaDrawerOpen,
        }}
        shouldRender={Platform.OS === 'ios' && !isEmpty(respaFile)}
      />
    </StyledScrollView>
  );
};

PaymentHomeScreen.propTypes = {
  address: T.string.isRequired,
  autopayAllowedOperation: T.string,
  autopayFetchStatus: T.string.isRequired,
  autopayStatus: T.string,
  autopayStatusProps: T.object,
  cancelAutopayStatus: T.string.isRequired,
  cancelModalProps: T.object.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  disclosures: T.object.isRequired,
  dispatchCancelAutopay: T.func.isRequired,
  dispatchCancelOtp: T.func.isRequired,
  dispatchDisableInstantPostLoginModal: T.func.isRequired,
  dispatchDismissDocUploadStatusBanner: T.func.isRequired,
  dispatchFetchAlertSettings: T.func.isRequired,
  dispatchFetchAutopayStatus: T.func.isRequired,
  dispatchFetchFees: T.func.isRequired,
  dispatchFetchLossDraft: T.func.isRequired,
  dispatchFetchLossDraftMessages: T.func.isRequired,
  dispatchFetchMessages: T.func.isRequired,
  dispatchFetchPaperlessSettingAll: T.func.isRequired,
  dispatchFetchPaymentHome: T.func.isRequired,
  dispatchFetchPostAuthNotifications: T.func.isRequired,
  dispatchFetchRespaLetter: T.func.isRequired,
  dispatchFetchTransactions: T.func.isRequired,
  dispatchFetchTransferStatus: T.func.isRequired,
  dispatchPutLanguagePreference: T.func.isRequired,
  dispatchPutPaperlessSetting: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchResetPaymentHistoryState: T.func.isRequired,
  dispatchSetInstantPostLoginModalStep: T.func.isRequired,
  dispatchSetIsAutopayModalOpen: T.func.isRequired,
  dispatchSetIsInAutopayFlow: T.func.isRequired,
  dispatchSetIsOtpModalOpen: T.func.isRequired,
  dispatchSetReduxNestedScreen: T.func.isRequired,
  dispatchSetRespaFile: T.func.isRequired,
  dispatchToggleRespaDrawer: T.func.isRequired,
  dispatchUpdatePaymentHomeInit: T.func.isRequired,
  docUploadNotificationFailure: T.object,
  fetchDisclosureRequestStatus: T.string.isRequired,
  fetchPaperlessStatus: T.string.isRequired,
  fetchTransferRequestStatus: T.string.isRequired,
  historyData: T.array.isRequired,
  historyLoading: T.shape({ transactions: T.bool.isRequired }).isRequired,
  historyServiceError: T.shape({ transactions: T.bool.isRequired }).isRequired,
  instantPostLoginModalViewProps: T.shape({
    isModalViewOpen: T.bool.isRequired,
    modalViewName: T.string.isRequired,
    modalViewStep: T.number.isRequired,
  }).isRequired,
  isAutopayEnabledOrPending: T.bool.isRequired,
  isCancelAutopayModalOpen: T.bool.isRequired,
  isCancelOtpModalOpen: T.bool.isRequired,
  isEligibleForAutopay: T.bool.isRequired,
  isEligibleForPaperlessBilling: T.bool.isRequired,
  isInAutopayFlow: T.bool.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  isPaymentHomeInit: T.bool.isRequired,
  isRespaDrawerOpen: T.bool.isRequired,
  languagePreferenceProps: T.object.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  notificationsModalFetchRequestsLoading: T.bool.isRequired,
  otpAllowedOperation: T.string.isRequired,
  otpData: T.object.isRequired,
  otpLoading: T.shape({ otp: T.bool, paymentHome: T.bool }).isRequired,
  otpRequestStatus: T.object.isRequired,
  otpServiceError: T.shape({ paymentHome: T.oneOfType([T.bool, T.string]).isRequired }).isRequired,
  paperlessBillingProps: T.object.isRequired,
  reduxNestedScreen: T.string.isRequired,
  respaFile: T.object.isRequired,
  respaLetterError: T.oneOfType([T.bool, T.string]).isRequired,
  route: T.object.isRequired,
  shouldNotificationsModalAutoOpen: T.bool.isRequired,
  ssoClientImageProps: T.object.isRequired,
  status: T.string.isRequired,
  transferStatus: T.object.isRequired,
  transferStatusBannerProps: T.shape({
    shouldTransferBannerDisplay: T.bool.isRequired,
    transferStatusBannerText: T.string.isRequired,
    transferStatusBannerTitle: T.string.isRequired,
  }),
  userDisplayName: T.string.isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  autopayAllowedOperation: selectStatusDataProp('allowedOperation'),
  autopayFetchStatus: selectStatusProp('fetchStatus'),
  autopayStatus: selectAutopayStatus(),
  autopayStatusProps: selectStatusMessageProps(),
  cancelAutopayStatus: selectStatusProp('cancel'),
  isAutopayEnabledOrPending: selectIsAutopayEnabledOrPending('status'),
  isCancelAutopayModalOpen: selectIsAutopayModalOpen('cancel'),
  isEligibleForAutopay: selectIsEligibleForAutopay(),
  isInAutopayFlow: selectAllowedOperationIsInAutoapyFlow(),
  isLoanMancodeY: selectStatusDataProp('isLoanMancodeY'),
  /**
   * Store: Communications
   */
  fetchPaperlessStatus: selectRequestStatus('fetchPaperlessSettingAll'),
  /**
   * Store: Disclosures
   */
  disclosures: makeSelectDisclosuresContent(),
  fetchDisclosureRequestStatus: makeSelectDisclosures('requestStatus'),
  /**
   * Store: Main
   */
  address: getSelectedLoanAddress(),
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  docUploadNotificationFailure: getMostRecentDocUploadNotificationFailure(),
  fetchTransferRequestStatus: selectMainStatus('fetchTransferStatus'),
  instantPostLoginModalViewProps: selectInstantPostLoginModalViewProps(),
  isEligibleForPaperlessBilling: selectIsEligibleForPaperlessBilling(),
  isMurabahaAccount: selectIsMurabahaAccount(),
  isPastServicingPaymentCutOffDate: selectIsPastServicingPaymentCutOffDate(),
  isRespaDrawerOpen: makeSelectMain('isDrawerOpen'),
  loanNumber: makeSelectMain('selectedLoan'),
  languagePreferenceProps: selectLanguagePreferenceProps(),
  paperlessBillingProps: selectPaperlessBillingProps(),
  respaFile: makeSelectMain('file'),
  respaLetterError: getError('respaLetter'),
  ssoClientImageProps: getSsoClientImageProps(),
  transferStatus: makeSelectMain('transferStatus'),
  transferStatusBannerProps: selectTransferStatusBannerProps(),
  userDisplayName: getUserDisplayName(),
  /**
   * Store: Mobile
   */
  reduxNestedScreen: selectNestedScreen('payments'),
  /**
  * Store: Notifications
  */
  // eslint-disable-next-line sort-keys
  notificationsModalFetchRequestsLoading: selectNotificationsModalFetchRequestsLoading(),
  shouldNotificationsModalAutoOpen: selectShouldNotificationsModalAutoOpen(),
  /**
   * Store: One Time Payment
   */
  accountForm: selectOtpForm('account'),
  cancelModalProps: selectCancelModalProps(),
  isCancelOtpModalOpen: selectIsOtpModalOpen('cancel'),
  isPaymentHomeInit: makeSelectPayments('isPaymentHomeInit'),
  otpAllowedOperation: selectOtp('allowedOperation'),
  otpData: selectPaymentHomeData(),
  otpLoading: makeSelectPayments('loading'),
  otpRequestStatus: makeSelectPayments('requestStatus'),
  otpServiceError: selectOtpError('serviceError'),
  status: selectOtp('status'),
  /**
   * Store: Payment History
   */
  historyData: selectPaymentHomeHistoryData(),
  historyLoading: makeSelectPaymentHistory('loading'),
  historyServiceError: makeSelectError('serviceError'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchCancelAutopay: () => dispatch(cancel({ navigationType: 'navigate' })),
  dispatchFetchAutopayStatus: () => dispatch(fetchStatus()),
  dispatchSetIsAutopayModalOpen: (payload) => dispatch(setIsAutopayModalOpen(payload)),
  dispatchSetIsInAutopayFlow: (payload) => dispatch(setIsInAutopayFlow(payload)),
  /**
   * Store: Communications
   */
  dispatchFetchAlertSettings: () => dispatch(fetchAlertSettings()),
  dispatchFetchPaperlessSettingAll: () => dispatch(fetchPaperlessSettingAll()),
  /**
   * Store: Loss Draft
   */
  dispatchFetchLossDraft: (payload) => dispatch(fetchLossDraft(payload)),
  dispatchFetchLossDraftMessages: () => dispatch(fetchLossDraftMessages()),
  /**
   * Store: Main
   */
  dispatchDisableInstantPostLoginModal: () => dispatch(disableInstantPostLoginModal()),
  dispatchDismissDocUploadStatusBanner: (payload) =>
    dispatch(dismissDocUploadStatusBanner(payload)),
  dispatchFetchRespaLetter: (payload) => dispatch(fetchRespaLetter(payload)),
  dispatchFetchTransferStatus: () => dispatch(fetchTransferStatus()),
  dispatchPutLanguagePreference: (payload) => dispatch(putLanguagePreference(payload)),
  dispatchPutPaperlessSetting: (payload) => dispatch(putPaperlessSetting(payload)),
  dispatchResetMainError: (payload) => dispatch(resetMainError(payload)),
  dispatchSetInstantPostLoginModalStep: (payload) =>
    dispatch(setInstantPostLoginModalStep(payload)),
  dispatchSetRespaFile: (payload) => dispatch(setRespaFile(payload)),
  dispatchToggleRespaDrawer: (payload) => dispatch(toggleRespaDrawer(payload)),
  /**
   * Store: Mobile
   */
  dispatchSetReduxNestedScreen: (payload) => dispatch(setNestedScreen(payload)),

  /**
  * Store: Notifications
  */
  dispatchFetchPostAuthNotifications: () => dispatch(fetchPostAuthNotifications()),
  /**
   * Store: One Time Payment
   */
  // eslint-disable-next-line sort-keys
  dispatchCancelOtp: () => dispatch(cancelOtp({ navigationType: 'navigate' })),
  dispatchFetchPaymentHome: (loanNumber) => dispatch(fetchPaymentHome(loanNumber)),
  dispatchSetIsOtpModalOpen: (payload) => dispatch(setIsOtpModalOpen(payload)),
  dispatchUpdatePaymentHomeInit: (payload) => dispatch(updatePaymentHomeInit(payload)),
  /**
  * Store: Payment History
  */
  dispatchFetchTransactions: () => dispatch(fetchTransactions()),
  dispatchResetPaymentHistoryState: () => dispatch(resetPaymentHistoryState()),
  /**
   * Store: Secure Messages
   */
  dispatchFetchMessages: () => dispatch(fetchMessages()),
  /**
   * Store: Fees
   */
  // eslint-disable-next-line sort-keys
  dispatchFetchFees: (payload) => dispatch(fetchFees(payload)),
});
/* eslint-enable sort-keys */

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withAutopayReducer = injectReducer({ key: 'autopay', reducer: autopayReducer });
const withHistoryReducer = injectReducer({ key: 'paymentHistory', reducer: historyReducer });
const withHistorySaga = injectSaga({ key: 'paymentHistory', saga: historySaga({ baseUrl }) });
const withOtpReducer = injectReducer({ key: 'oneTimePayment', reducer: otpReducer });
const withSourcesReducer = injectReducer({ key: 'paymentSources', reducer: sourcesReducer });
const withSourcesSaga = injectSaga({
  key: 'paymentSources',
  saga: sourcesSaga({
    baseUrl,
    navigationHandler: navigate,
  }),
});

export default compose(
  withConnect,
  withAutopayReducer,
  withHistoryReducer,
  withHistorySaga,
  withOtpReducer,
  withSourcesReducer,
  withSourcesSaga,
)(PaymentHomeScreen);
