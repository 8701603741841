import React, { Fragment } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import ConditionalRender from '../../../base_ui/ConditionalRender';
import Message from '../../../Message';
import CouponBookFallBack from './CouponBookFallBack';
import Filter from './Filter';
import Item from './Item';
import { IllustrationWrapper, StyledFlatList } from './styledComponents';

const DocumentsStatementsIllustration = iconDictionary('documentsStatements');
const StatementsHouseIllustration = iconDictionary('statementsHouse');

const StatementsList = ({
  customerServicePhoneNumber,
  dispatchChangeFilter,
  handleEndReached,
  handleRowPress,
  itemsPerPage,
  paymentMethod,
  selectedFilter,
  statementsToRender,
}) => {
  const renderItem = ({ item }) => (
    <Item handleRowPress={handleRowPress} {...item} />
  );

  const title = selectedFilter === 'All Types' ?
    'There are no Statements.' :
    `There are no ${selectedFilter} Statements.`;
  const noDataMessageProps = {
    icon: DocumentsStatementsIllustration,
    title,
  };
  const showPaymentMethodCoupons = paymentMethod === 'Coupons' && selectedFilter === 'Monthly';

  return (
    <Fragment>
      <Filter dispatchChangeFilter={dispatchChangeFilter} selectedFilter={selectedFilter} />
      <ConditionalRender
        Component={(
          <ConditionalRender
            Component={StyledFlatList}
            FallbackComponent={Message}
            propsToPassDown={{
              customerServicePhoneNumber,
              data: statementsToRender,
              initialNumToRender: itemsPerPage,
              keyExtractor: ({ id }) => id,
              ListFooterComponent: (
                <IllustrationWrapper>{StatementsHouseIllustration}</IllustrationWrapper>
              ),
              onEndReached: handleEndReached,
              onEndReachedThreshold: 0.3,
              renderItem,
              ...noDataMessageProps,
            }}
            shouldRender={!!statementsToRender.length}
          />
        )}
        FallbackComponent={CouponBookFallBack}
        propsToPassDown={{ customerServicePhoneNumber }}
        shouldRender={!showPaymentMethodCoupons}
      />
      <ConditionalRender
        Component={<IllustrationWrapper>{StatementsHouseIllustration}</IllustrationWrapper>}
        shouldRender={!statementsToRender.length || showPaymentMethodCoupons}
      />
    </Fragment>
  );
};

StatementsList.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeFilter: T.func.isRequired,
  handleEndReached: T.func.isRequired,
  handleRowPress: T.func.isRequired,
  itemsPerPage: T.number.isRequired,
  paymentMethod: T.string.isRequired,
  selectedFilter: T.string.isRequired,
  statementsToRender: T.arrayOf(T.shape({
    date: T.string,
    id: T.string,
    type: T.string,
    url: T.string,
  })).isRequired,
};

export default StatementsList;
