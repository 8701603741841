import React from 'react';
import T from 'prop-types';

import { MODAL_PAPERLESS_BILLING_CONFIRMATION_CONTENT } from '@dmi/shared/redux/Main/constants';

import { Animation, P2 } from '../../base_ui';

import {
  AnimationContainer,
  ConfirmationHeading,
  ConfirmationText,
  DataLabel,
  DataLine,
  PaperlessContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
  TextContainer,
} from './styledComponents';

const { heading, label, text } = MODAL_PAPERLESS_BILLING_CONFIRMATION_CONTENT;

const ConfirmationView = ({
  handleCloseModalView,
  paperlessBillingProps: {
    confirmationData: {
      loanNumber,
      propertyAddress,
    },
  },
}) => (
  <PaperlessContainer>
    <AnimationContainer>
      <Animation name="robotMobile" />
    </AnimationContainer>
    <TextContainer>
      <ConfirmationHeading>{heading}</ConfirmationHeading>
      <ConfirmationText>{text}</ConfirmationText>
      <DataLine>
        <DataLabel>Loan Number</DataLabel>
        <P2>{loanNumber}</P2>
      </DataLine>
      <DataLine>
        <DataLabel>Property Address</DataLabel>
        <P2>{propertyAddress}</P2>
      </DataLine>
      <StyledPrimaryDivider />
      <StyledBaseInternalLink
        label={label}
        onPressFunc={handleCloseModalView}
        size="medium"
      />
    </TextContainer>
  </PaperlessContainer>
);

ConfirmationView.propTypes = {
  handleCloseModalView: T.func.isRequired,
  paperlessBillingProps: T.object.isRequired,
};

export default ConfirmationView;
