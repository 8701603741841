import styled from 'styled-components/native';

import { PrimaryDivider } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';

export const SkeletonColumn = styled.View`
  width: ${({ $width }) => $width};
`;

export const SkeletonHeader = styled.View`
  display: flex;
  flex-direction: row;
`;

export const SkeletonTable = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: ${moderateScale(18)}px;
  width: 100%;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin: ${moderateScale(28)}px 0 ${moderateScale(14)}px;
  max-width: 100%;
`;
