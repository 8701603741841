import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';

import iconDictionary from '../../../utils/iconDictionary';
import { P2 } from '../../base_ui';
import {
  ContactCard,
  ContactContainer,
  ContactHeader,
  ContactText,
  PhoneNumber,
  Row,
} from './styledComponents';

const ClockIcon = iconDictionary('pendingCarbon');
const FlagIcon = iconDictionary('flag');
const PhoneIcon = iconDictionary('phone');

const ContactInformationCard = ({
  pointOfContact: {
    contactName,
    ext,
    fallbackExt,
    hours,
    phoneNumber,
  },
}) => (
  <ContactCard>
    <ContactHeader>Contact Information</ContactHeader>
    <ContactContainer>
      <Row>
        {FlagIcon}
        <ContactText>
          {contactName}
        </ContactText>
      </Row>
      <Row>
        {PhoneIcon}
        <PhoneNumber
          onPress={() =>
            Linking.openURL(`tel://${phoneNumber.replace(/\+|-/g, '')}`)}
        >
          {phoneNumber}
        </PhoneNumber>
        <P2>, {ext || fallbackExt}</P2>
      </Row>
      <Row>
        {ClockIcon}
        <ContactText>{hours}</ContactText>
      </Row>
    </ContactContainer>
  </ContactCard>
);

ContactInformationCard.propTypes = {
  pointOfContact: T.shape({
    contactName: T.string.isRequired,
    ext: T.string.isRequired,
    fallbackExt: T.string.isRequired,
    hours: T.string.isRequired,
    phoneNumber: T.string.isRequired,
  }).isRequired,
};

export default ContactInformationCard;
