import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import {
  BaseInternalLink,
  H3,
  P3,
  PrimaryDivider,
} from '../../../../base_ui';
import Message from '../../../../Message';

export const BodyInnerWrapper = styled.View`
  width: 100%;
`;

export const BodyOuterWrapper = styled.View`
  align-items: center;
`;

export const HistoryHeaderWrapper = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const StyledH3 = styled(H3)`
  padding: ${moderateScale(14)}px 0 ${moderateScale(14)}px ${moderateScale(12)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  align-items: center;
  margin-vertical: ${moderateScale(8)}px;
  padding-vertical: ${moderateScale(6)}px;
`;

export const StyledMessage = styled(Message)`
  flex-grow: 0;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;

export const Table = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${moderateScale(16)}px;
  padding-top: ${moderateScale(8)}px;
`;

export const TH = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  padding-vertical: ${moderateScale(8)}px;
`;

export const TR = styled(P3)`
  padding-vertical: ${moderateScale(8)}px;
`;
