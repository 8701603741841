import React from 'react';
import T from 'prop-types';
import { Dimensions } from 'react-native';

import { StyledWebView, WebViewContainer } from './styledComponents';

const deviceHeight = Dimensions.get('window').height;
const deviceWidth = Dimensions.get('window').width;

const WebViewComponent = ({
  route: { params: { uri: uriFromRoute } = {} } = {},
  uri: uriProp,
}) => {
  const uri = uriProp || uriFromRoute;

  return (
    <WebViewContainer>
      <StyledWebView
        $deviceHeight={deviceHeight}
        $deviceWidth={deviceWidth}
        allowFileAccess
        allowUniversalAccessFromFileURLs
        javaScriptEnabled
        originWhitelist={['http://*', 'https://*', 'file://*', 'data:*']}
        source={{ uri }}
      />
    </WebViewContainer>
  );
};

WebViewComponent.propTypes = {
  route: T.object,
  uri: T.string,
};

export default WebViewComponent;
