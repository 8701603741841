import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { GENERAL_SERVER_ERROR_MSG } from '@dmi/shared/utils/constants';
import { changeInput } from '@dmi/shared/redux/Login/actions';
import reducer from '@dmi/shared/redux/Login/reducer';
import makeSelectLogin, { getSelectedMfaOption } from '@dmi/shared/redux/Login/selectors';
import {
  mfaOptionRequest,
  mfaOptionRequestCancel,
  resetMainError,
  resetStatus,
} from '@dmi/shared/redux/Main/actions';
import { getError, selectMainStatus } from '@dmi/shared/redux/Main/selectors';

import injectReducer from '../../utils/injectReducer';
import { PageLevelError } from '../../components/AsyncRender/Errors';
import MultiFactorAuthentication from '../../components/Login/MFA';
import { ConditionalRender, StyledScrollView } from '../../components/base_ui';

const MFAView = ({
  dispatchChangeInput,
  dispatchMfaOptionRequestCancel,
  dispatchResetMainError,
  dispatchResetStatus,
  dispatchSelectMfa,
  error,
  mfaOptions,
  navigation,
  selectedMfaOption,
  status,
}) => {
  useEffect(() => () => {
    dispatchResetMainError();
    dispatchResetStatus();
  }, [dispatchResetMainError, dispatchResetStatus]);

  const mfaBackHandler = () => {
    if (status !== 'succeeded') {
      dispatchMfaOptionRequestCancel();
      navigation.goBack();
    }
  };

  const shouldRenderMfaForm = error !== GENERAL_SERVER_ERROR_MSG;
  const propsToPassDown = shouldRenderMfaForm ?
    {
      dispatchChangeInput,
      dispatchSelectMfa,
      mfaOptions,
      selectedMfaOption,
    } : {
      body: 'Please try again later.',
      error,
      link: {
        label: 'Return to Login screen',
        path: 'Login',
      },
    };

  return (
    <StyledScrollView>
      <ConditionalRender
        Component={(
          <MultiFactorAuthentication
            dispatchChangeInput={dispatchChangeInput}
            dispatchSelectMfa={dispatchSelectMfa}
            mfaBackHandler={mfaBackHandler}
            mfaOptions={mfaOptions}
            navigation={navigation}
            selectedMfaOption={selectedMfaOption}
            status={status}
          />
        )}
        FallbackComponent={PageLevelError}
        propsToPassDown={propsToPassDown}
        shouldRender={shouldRenderMfaForm}
      />
    </StyledScrollView>
  );
};

MFAView.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchMfaOptionRequestCancel: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchResetStatus: T.func.isRequired,
  dispatchSelectMfa: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  mfaOptions: T.array.isRequired,
  navigation: T.object.isRequired,
  selectedMfaOption: T.string.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Login
   */
  mfaOptions: makeSelectLogin('mfaOptions'),
  selectedMfaOption: getSelectedMfaOption(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  error: getError('main'),
  status: selectMainStatus('mfa'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store : Login
  */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  /**
  * Store : Main
  */
  // eslint-disable-next-line sort-keys
  dispatchMfaOptionRequestCancel: () => dispatch(mfaOptionRequestCancel()),
  dispatchResetMainError: () => dispatch(resetMainError()),
  dispatchResetStatus: () => dispatch(resetStatus('mfa')),
  dispatchSelectMfa: (payload) => dispatch(mfaOptionRequest(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'login', reducer });

export default compose(
  withConnect,
  withReducer,
)(MFAView);
