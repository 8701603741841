/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavMortgageActiveMIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M16.7804 7.11011L15.2604 6.11011L13.8404 5.19011V3.19011C13.8404 3.03363 13.7783 2.88357 13.6676 2.77292C13.557 2.66227 13.4069 2.60011 13.2504 2.60011C13.1726 2.59876 13.0952 2.61309 13.023 2.64226C12.9509 2.67143 12.8853 2.71484 12.8302 2.7699C12.7752 2.82496 12.7317 2.89054 12.7026 2.96274C12.6734 3.03493 12.6591 3.11226 12.6604 3.19011V4.45011L9.18042 2.19011C9.08398 2.12646 8.97097 2.09253 8.85542 2.09253C8.73987 2.09253 8.62686 2.12646 8.53042 2.19011L3.00042 5.88011L1.07042 7.11011C0.941144 7.19776 0.850411 7.33166 0.816922 7.48422C0.783434 7.63678 0.809741 7.79637 0.890423 7.93011C0.944963 8.01293 1.01918 8.08094 1.10643 8.12806C1.19369 8.17517 1.29126 8.19993 1.39042 8.20011C1.50329 8.1998 1.61394 8.16868 1.71042 8.11011L2.18042 7.81011V16.0001C2.18042 16.1566 2.24258 16.3067 2.35323 16.4173C2.46388 16.528 2.61395 16.5901 2.77042 16.5901H15.1204C15.2769 16.5901 15.427 16.528 15.5376 16.4173C15.6483 16.3067 15.7104 16.1566 15.7104 16.0001V7.83011L16.1504 8.11011C16.2469 8.16868 16.3576 8.1998 16.4704 8.20011C16.5751 8.20826 16.6799 8.18737 16.7734 8.13973C16.867 8.09208 16.9455 8.01955 17.0004 7.93011C17.0412 7.86219 17.0679 7.78675 17.0789 7.70828C17.09 7.62981 17.085 7.54993 17.0645 7.47341C17.044 7.39688 17.0082 7.32527 16.9594 7.26285C16.9106 7.20043 16.8497 7.14849 16.7804 7.11011ZM8.78042 6.32011C8.95519 6.30194 9.13137 6.33711 9.28576 6.421C9.44016 6.50488 9.56553 6.63356 9.64538 6.79007C9.72523 6.94659 9.75582 7.12362 9.73312 7.29786C9.71041 7.4721 9.63548 7.63538 9.51819 7.76621C9.40089 7.89704 9.24673 7.98929 9.07599 8.03081C8.90526 8.07233 8.72595 8.06118 8.56168 7.99882C8.3974 7.93647 8.25586 7.82583 8.15567 7.68148C8.05549 7.53713 8.00137 7.36582 8.00042 7.19011C7.99767 6.96541 8.08299 6.74855 8.23811 6.58595C8.39323 6.42335 8.60584 6.32793 8.83042 6.32011H8.78042ZM10.4504 15.8201H7.42042V10.7601H10.5004L10.4504 15.8201Z" fill={primary} />
    </Svg>
  );
}
