/**
*
* Confirmation
* @description Confirmation page for Register
*
*/
import React from 'react';
import T from 'prop-types';
import { Linking, Platform } from 'react-native';
import { useIntl } from 'react-intl';

import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import iconDictionary from '../../utils/iconDictionary';
import {
  ConditionalRender,
  FormattedMessage,
} from '../base_ui';
import {
  BoldText,
  ConfirmationCard,
  ConfirmationParagraph,
  ConfirmationParagraphsWrapper,
  StyledAnimation,
  StyledBorderlessButton,
  StyledConfirmationHeader,
  StyledPhoneLinkText,
} from './styledComponents';

const emailErrorIllustration = iconDictionary('welcomeHome');
const serviceErrorIllustration = iconDictionary('errorStorm');

const Confirmation = ({
  errorOrSuccess,
  handlePress,
  message,
  recoveredUsername,
  viewCardProps: { cardAnimation, cardDictionary },
}) => {
  const { formatMessage, messages } = useIntl();
  const {
    header,
    illustration,
    isSuccess,
    link: { label, path },
    needsFormatting,
    text = '',
  } = cardDictionary[errorOrSuccess];
  const errorIllustration = errorOrSuccess === 'serviceErrorEmailInUse' ?
    emailErrorIllustration :
    serviceErrorIllustration;

  const illustrationToRender = illustration ?
    iconDictionary(illustration) :
    errorIllustration;

  const resolvedIntlMessage = resolveIntlMessage({
    formatMessage,
    message,
    messages,
  });
  const resolvedIntlText = resolveIntlMessage({
    formatMessage,
    message: text,
    messages,
    values: null,
  });
  const resolvedIntlHeader = resolveIntlMessage({
    formatMessage,
    message: header,
    messages,
    values: { recoveredUsername, spacing: '\n' },
  });

  const paragraphsToRender = isSuccess ?
    resolvedIntlText.split('{{mobileParagraphSplit}}') :
    [resolvedIntlText || resolvedIntlMessage];
  const shouldRenderParagraphs = !!(text || message);

  return (
    <ConfirmationCard>
      <ConditionalRender
        Component={StyledAnimation}
        FallbackComponent={illustrationToRender}
        propsToPassDown={{ name: Platform.OS === 'web' ? cardAnimation.web : cardAnimation.mobile }}
        shouldRender={isSuccess}
      />
      <StyledConfirmationHeader>{resolvedIntlHeader}</StyledConfirmationHeader>
      <ConditionalRender
        Component={(
          <ConfirmationParagraphsWrapper>
            {paragraphsToRender.map((paragraph, index) => (
              <ConditionalRender
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                Component={(
                  <FormattedMessage
                    $lastParagraph={index === (paragraphsToRender.length - 1)}
                    defaultMessage={paragraph}
                    values={{
                      // eslint-disable-next-line react/prop-types
                      a: ({ msg }) => (
                        <StyledPhoneLinkText onPress={() => Linking.openURL('tel://18006694268')}>
                          {msg}
                        </StyledPhoneLinkText>
                      ),
                      // eslint-disable-next-line react/prop-types
                      b: ({ msg }) => <BoldText>{msg}</BoldText>,
                      mobileNewline: '\n',
                    }}
                    WrapperComponent={ConfirmationParagraph}
                  />
                )}
                FallbackComponent={(
                  <ConfirmationParagraph
                    $lastParagraph={index === (paragraphsToRender.length - 1)}
                  >
                    {paragraph}
                  </ConfirmationParagraph>
                )}
                shouldRender={!!needsFormatting}
              />
            ))}
          </ConfirmationParagraphsWrapper>
        )}
        shouldRender={shouldRenderParagraphs}
      />
      <StyledBorderlessButton
        $shouldRenderParagraphs={shouldRenderParagraphs}
        label={formatMessage({ id: label })}
        onPress={() => handlePress(path.mobile)}
      />
    </ConfirmationCard>
  );
};

Confirmation.propTypes = {
  errorOrSuccess: T.oneOf([
    'bannerError',
    'forgotPasswordInitiateError',
    'forgotPasswordInitiateSuccess',
    'forgotPasswordVerifyError',
    'forgotPasswordVerifySuccess',
    'forgotUsernameInitiateError',
    'forgotUsernameInitiateSuccess',
    'forgotUsernameVerifyError',
    'forgotUsernameVerifySuccess',
    'guidedStepFourError',
    'registerChangeEmailError',
    'serviceError',
    'serviceErrorEmailInUse',
    'ssoLoanError',
    'stepOneError',
    'stepTwoError',
    'stepTwoSuccess',
    'stepTwoValidationError',
    'success',
    'verifyError',
    'verifySuccess',
  ]).isRequired,
  handlePress: T.func.isRequired,
  message: T.string.isRequired,
  recoveredUsername: T.string,
  viewCardProps: T.shape({ cardAnimation: T.object, cardDictionary: T.object }).isRequired,
};

export default Confirmation;
