import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { FormattedMessageStatusBanner, H1, H3 } from '../../base_ui';

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ContentContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const MessageContainer = styled.View`
  margin: ${moderateScale(20)}px 0 ${moderateScale(20)}px ${moderateScale(20)}px;
  width: ${moderateScale(173)}px;
`;

export const SectionContainer = styled.View`
  align-self: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  padding: ${moderateScale(14)}px ${moderateScale(20)}px ${moderateScale(24)}px;
  width: ${moderateScale(327)}px;
`;

export const StyledFormattedMessageStatusBanner = styled(FormattedMessageStatusBanner)`
  margin: 0 ${moderateScale(24)}px ${moderateScale(14)}px;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px;
`;

export const StyledH1 = styled(H1)`
  padding: 0 ${moderateScale(24)}px;
`;

export const StyledPhoneLinkText = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const SubHeading = styled(H3)`
  padding-bottom: ${moderateScale(14)}px;
`;
