import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { Dimensions } from 'react-native';

import { StyledVideo } from './styledComponents.web';

const deviceWidth = Dimensions.get('window').width;

const WebPlayer = ({ dispatchSetVideoRemainingDuration, languages, videoUrl }) => {
  const videoPlayerRef = useRef();

  // unmount clean-up to prevent memory leaks
  useEffect(() => () => {
    if (videoPlayerRef.current) {
      videoPlayerRef.current.pause();
      videoPlayerRef.current.removeAttribute('src');
      videoPlayerRef.current.load();
    }
    dispatchSetVideoRemainingDuration(0);
  }, [dispatchSetVideoRemainingDuration]);

  const onPlay = () => {
    const { current: { currentTime, duration } } = videoPlayerRef;
    dispatchSetVideoRemainingDuration(Math.ceil(duration - currentTime) * 1000);
  };

  const resetRemainingDuration = () => {
    dispatchSetVideoRemainingDuration(0);
  };

  return (
    <StyledVideo
      ref={videoPlayerRef}
      autoPlay
      controls
      controlsList="nodownload"
      crossOrigin="anonymous"
      height={`${Math.ceil((deviceWidth / 16) * 9)}px`}
      onEnded={resetRemainingDuration}
      onPause={resetRemainingDuration}
      onPlay={onPlay}
      playsInline
      src={videoUrl}
      width={`${deviceWidth}px`}
    >
      {languages.map(({ captionUrl, label, tag }) => (
        <track
          key={label}
          kind="captions"
          label={label}
          src={captionUrl}
          srcLang={tag}
        />
      ))}
    </StyledVideo>
  );
};

WebPlayer.propTypes = {
  dispatchSetVideoRemainingDuration: T.func.isRequired,
  languages: T.arrayOf(T.shape({
    captionUrl: T.string.isRequired,
    label: T.string.isRequired,
    tag: T.string.isRequired,
  })).isRequired,
  videoUrl: T.string.isRequired,
};

export default WebPlayer;
