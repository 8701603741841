import React from 'react';
import { Platform, View } from 'react-native';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { ConditionalRender } from '../../base_ui';
import { Card, CardLink } from './Card';

const SettingsIcon = iconDictionary('securityLock');

const SecurityCard = ({
  navigation,
  showEditPassword,
  signInMethodButtonDetails,
}) => (
  <Card
    HeaderIcon={SettingsIcon}
    headerText="Security"
  >
    <View>
      <ConditionalRender
        Component={(
          <CardLink
            handlePress={() => navigation.navigate('Password')}
            label="Password"
          />
        )}
        shouldRender={showEditPassword}
      />
      <ConditionalRender
        Component={CardLink}
        propsToPassDown={{
          details: signInMethodButtonDetails,
          handlePress: () => navigation.navigate('SignInMethod'),
          label: 'Sign In Methods',
        }}
        shouldRender={Platform.OS !== 'web'}
      />
    </View>
  </Card>
);

SecurityCard.propTypes = {
  navigation: T.object.isRequired,
  showEditPassword: T.bool.isRequired,
  signInMethodButtonDetails: T.string.isRequired,
};

export default SecurityCard;
