import React, { useState } from 'react';
import T from 'prop-types';
import camelCase from 'lodash/camelCase';

import { MOBILE_SITE_MAP_POST_AUTH } from './constants';

import { ConditionalRender, StyledScrollView } from '../base_ui';
import Layout from '../../constants/Layout';
import Disclaimer from '../Disclaimers';
import MapSection from './MapSection';
import {
  Panel,
  PanelContainer,
} from './styledComponents';

const { isTablet } = Layout;

const SiteMap = ({
  dispatchSetNestedScreen,
  isMurabahaAccount,
  isSecureMessagingTreatmentOn,
  navigation,
  selectedLoanPropertyState,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerType, setDrawerType] = useState('');
  const {
    calculators,
    disclosures: {
      Icon: disclosuresIcon,
      label: disclosuresLabel,
      links: disclosuresLinks,
    },
    documents,
    help,
    mortgage,
    payments,
    settings,
    termsAndPrivacy,
  } = MOBILE_SITE_MAP_POST_AUTH;
  const addDisclosuresLinks = selectedLoanPropertyState ?
    [{
      label: `${selectedLoanPropertyState} Foreclosure Hotline`,
      path: `foreclosureHotline${selectedLoanPropertyState.replace(' ', '')}`,
    }] :
    [];

  let dynamicHelpLinks = help.links;

  if (!isSecureMessagingTreatmentOn) {
    dynamicHelpLinks = help.links.filter(({ path }) => path !== 'SecureMessaging');
  }

  if (isMurabahaAccount) {
    dynamicHelpLinks = help.links.filter(({ path }) => path !== 'LearningCenter');
  }

  const handleNavigateLink = ({ mainPath, path }) => {
    if (mainPath) {
      dispatchSetNestedScreen({
        mainScreen: camelCase(mainPath),
        nestedScreen: path,
      });
      navigation.navigate(mainPath);
    } else {
      navigation.navigate(path);
    }
  };

  const handleOpenDrawer = (type) => {
    setDrawerType(type);
    setIsDrawerOpen(true);
  };

  return (
    <StyledScrollView>
      <PanelContainer $isTablet={isTablet}>
        <Panel $isTablet={isTablet}>
          <MapSection {...payments} handleNavigateLink={handleNavigateLink} />
          <MapSection {...mortgage} handleNavigateLink={handleNavigateLink} />
          <MapSection {...documents} handleNavigateLink={handleNavigateLink} />
          <MapSection {...calculators} handleNavigateLink={handleNavigateLink} />
        </Panel>
        <Panel $isTablet={isTablet}>
          <MapSection {...help} handleNavigateLink={handleNavigateLink} links={dynamicHelpLinks} />
          <MapSection {...settings} handleNavigateLink={handleNavigateLink} />
          <MapSection
            handleOpenDrawer={handleOpenDrawer}
            Icon={disclosuresIcon}
            label={disclosuresLabel}
            links={[...addDisclosuresLinks, ...disclosuresLinks]}
          />
          <MapSection {...termsAndPrivacy} handleOpenDrawer={handleOpenDrawer} />
        </Panel>
      </PanelContainer>
      <ConditionalRender
        Component={Disclaimer}
        propsToPassDown={{ drawerType, isDrawerOpen, setIsDrawerOpen }}
        shouldRender={!!drawerType}
      />
    </StyledScrollView>
  );
};

SiteMap.propTypes = {
  dispatchSetNestedScreen: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSecureMessagingTreatmentOn: T.bool.isRequired,
  navigation: T.object.isRequired,
  selectedLoanPropertyState: T.string.isRequired,
};

export default SiteMap;
