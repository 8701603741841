import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import ErrorMessage from '../../ErrorMessage';

const ErrorPorchlight = iconDictionary('errorPorchlight');

const Confirmation = ({ navigation }) => (
  <ErrorMessage
    icon={ErrorPorchlight}
    link={{
      label: 'Return to Settings',
      onPress: () => navigation.navigate('Settings'),
    }}
  />
);

Confirmation.propTypes = { navigation: T.object.isRequired };

export default Confirmation;
