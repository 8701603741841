/* eslint-disable max-len */
import React from 'react';
import Svg, { G, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function FaceID() {
  const { colors: { primary } } = useTheme();

  return (
    <Svg
      color={primary}
      enableBackground="new 0 0 24 24"
      height={moderateScale(24)}
      viewBox="0 0 24 24"
      width={moderateScale(24)}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
    >
      <G>
        <Path
          d="M17.004,2.118c-0.287,0-0.52-0.233-0.52-0.52s0.232-0.521,0.52-0.521h2.798
          c0.859,0,1.639,0.351,2.204,0.917s0.917,1.346,0.917,2.205v2.74c0,0.287-0.233,0.52-0.521,0.52s-0.52-0.233-0.52-0.52v-2.74
          c0-0.572-0.234-1.092-0.612-1.469c-0.377-0.377-0.896-0.611-1.469-0.611H17.004z M21.883,17.11c0-0.287,0.232-0.52,0.52-0.52
          s0.521,0.232,0.521,0.52v2.691c0,0.859-0.352,1.639-0.917,2.204s-1.345,0.917-2.204,0.917h-2.816c-0.287,0-0.521-0.233-0.521-0.521
          s0.233-0.52,0.521-0.52h2.816c0.572,0,1.092-0.234,1.469-0.612c0.377-0.377,0.612-0.896,0.612-1.469V17.11z M6.884,21.883
          c0.287,0,0.521,0.232,0.521,0.52s-0.233,0.521-0.521,0.521H4.198c-0.859,0-1.639-0.352-2.205-0.917s-0.917-1.345-0.917-2.204V17.11
          c0-0.287,0.233-0.52,0.521-0.52s0.52,0.232,0.52,0.52v2.691c0,0.572,0.234,1.092,0.611,1.469s0.897,0.612,1.469,0.612H6.884z
          M2.118,6.894c0,0.287-0.233,0.52-0.52,0.52s-0.521-0.233-0.521-0.52V4.198c0-0.859,0.351-1.639,0.917-2.205
          s1.346-0.917,2.205-0.917h2.765c0.287,0,0.521,0.233,0.521,0.521s-0.233,0.52-0.521,0.52H4.198c-0.572,0-1.092,0.234-1.469,0.611
          C2.352,3.106,2.118,3.626,2.118,4.198V6.894z M7.513,7.562L7.513,7.562c0.263,0,0.479,0.215,0.479,0.479v1.527
          c0,0.263-0.215,0.479-0.479,0.479c-0.263,0-0.479-0.215-0.479-0.479V8.041C7.034,7.778,7.25,7.562,7.513,7.562z M11.838,8.142
          c0.001-0.286,0.234-0.517,0.52-0.516c0.286,0.001,0.517,0.234,0.516,0.52l-0.03,4.274c-0.002,0.312-0.017,0.739-0.21,1.124
          c-0.225,0.45-0.64,0.793-1.416,0.772c-0.286-0.007-0.513-0.244-0.506-0.53c0.007-0.286,0.244-0.514,0.531-0.507
          c0.275,0.008,0.406-0.081,0.465-0.198c0.091-0.182,0.099-0.459,0.101-0.666L11.838,8.142z M8.003,16.453
          c-0.169-0.231-0.12-0.557,0.112-0.726c0.231-0.17,0.556-0.12,0.726,0.111c0.099,0.136,0.216,0.26,0.346,0.371
          c0.636,0.547,1.601,0.831,2.603,0.843c1.011,0.013,2.044-0.255,2.803-0.81c0.187-0.138,0.355-0.291,0.501-0.461
          c0.187-0.218,0.515-0.243,0.731-0.057c0.218,0.187,0.243,0.514,0.057,0.731c-0.2,0.233-0.428,0.441-0.676,0.622
          c-0.944,0.692-2.207,1.024-3.429,1.01c-1.233-0.015-2.441-0.382-3.264-1.09C8.321,16.835,8.149,16.652,8.003,16.453z M16.414,7.562
          L16.414,7.562c0.263,0,0.479,0.215,0.479,0.479v1.527c0,0.263-0.215,0.479-0.479,0.479s-0.479-0.215-0.479-0.479V8.041
          C15.935,7.778,16.15,7.562,16.414,7.562z"
          fill="currentColor"
        />
      </G>
    </Svg>
  );
}
