import React from 'react';
import T from 'prop-types';
import { Platform, StyleSheet } from 'react-native';
import { withTheme } from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import iconDictionary from '../../../utils/iconDictionary';
import BaseDropDown from './BaseDropDown';

const ArrowDownIcon = iconDictionary('arrowDown');

const OutlinedDropDown = ({
  hasLargerFont = false,
  style,
  theme,
  ...restProps
}) => (
  <BaseDropDown
    Icon={() => ArrowDownIcon}
    style={pickerSelectStyles(hasLargerFont, style, theme)}
    useNativeAndroidPickerStyle={false}
    {...restProps}
  />
);

const pickerSelectStyles = (hasLargerFont, style, { colors, fontSizes }) => {
  const {
    inputAndroid,
    inputIOS,
    inputWeb,
    ...restStyles
  } = style || {};

  return StyleSheet.create({
    ...Platform.select({
      android: {
        inputAndroid: {
          backgroundColor: colors.dmiWhite,
          borderColor: colors.primaryGray,
          borderRadius: 0,
          borderWidth: 1,
          color: colors.primaryCarbon,
          fontSize: hasLargerFont ? fontSizes.f5 : fontSizes.f4,
          height: moderateScale(29),
          paddingLeft: hasLargerFont ? moderateScale(4) : moderateScale(10),
          paddingRight: moderateScale(26),
          paddingVertical: moderateScale(6),
          ...inputAndroid,
        },
        inputAndroidContainer: { width: moderateScale(222) },
      },
      ios: {
        inputIOS: {
          backgroundColor: colors.dmiWhite,
          borderColor: colors.primaryGray,
          borderRadius: 0,
          borderWidth: 1,
          color: colors.primaryCarbon,
          fontSize: hasLargerFont ? fontSizes.f5 : fontSizes.f4,
          height: moderateScale(29),
          paddingLeft: hasLargerFont ? moderateScale(4) : moderateScale(10),
          paddingRight: moderateScale(26),
          paddingVertical: moderateScale(6),
          ...inputIOS,
        },
      },
      web: {
        inputWeb: {
        // A reset of styles, including removing the default dropDown arrow
          '-moz-appearance': 'none',
          '-ms-appearance': 'none',
          '-o-appearance': 'none',
          '-webkit-appearance': 'none',
          appearance: 'none',
          backgroundColor: colors.dmiWhite,
          borderColor: colors.primaryGray,
          borderRadius: 0,
          borderWidth: 1,
          color: colors.primaryCarbon,
          cursor: 'pointer',
          fontSize: hasLargerFont ? fontSizes.f5 : fontSizes.f4,
          height: moderateScale(29),
          lineHeight: hasLargerFont ? fontSizes.f5 : fontSizes.f4,
          paddingLeft: hasLargerFont ? moderateScale(4) : moderateScale(10),
          paddingRight: moderateScale(26),
          paddingVertical: moderateScale(6),
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          ...inputWeb,
        },
      },
    }),
    ...Platform.select({
      android: {},
      default: { viewContainer: { width: moderateScale(222) } },
    }),
    iconContainer: {
      height: moderateScale(6),
      right: moderateScale(12),
      top: '40%',
      width: moderateScale(13),
      ...Platform.select({ web: { pointerEvents: 'none' } }),
    },
    ...restStyles,
  });
};

OutlinedDropDown.propTypes = {
  hasLargerFont: T.bool,
  style: T.object,
  theme: T.object,
};

export default withTheme(OutlinedDropDown);
