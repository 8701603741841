import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import { Platform, Text } from 'react-native';

import { formatDate, parseDocUploadAttachments } from '@dmi/shared/utils/globalHelpers';

import { SecondaryHeader } from '../../ScreenHeaders';
import {
  AlertBanner,
  ConditionalRender,
  H5,
  KeyboardAvoidingScrollView,
  LargePrimaryButton,
  P2,
} from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import {
  AlertContainer,
  AttachmentContainer,
  AttachmentIcon,
  BoldText,
  StyledBody,
  StyledBorderlessButton,
  StyledButtonView,
  StyledMessageHeader,
  StyledPrimaryDivider,
  StyledTitle,
  StyledView,
} from './styledComponents';
import MessagesReplyComponent from '../../MessagesReply';

const DownloadIcon = iconDictionary('downloadActiveS', { iconState: 'active' });

const MessageDetailsComponent = ({
  dispatchChangeFormFieldValue,
  dispatchChangeStep,
  dispatchFetchAttachment,
  dispatchFetchMessages,
  dispatchPostReply,
  dispatchResetLossDraftState,
  dispatchSetAttachmentFile,
  dispatchToggleAttachmentDrawer,
  formAttachments,
  formReply,
  formUploadError,
  isMessageStatusClosed,
  messageDetails: {
    claimNumber,
    messages,
    title,
  },
  navigation,
  requestStatusPostReply,
  selectedMessageId,
  setShowReplyBox,
  showReplyBox,
}) => {
  const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);

  const handlePressFileRowButton = () => {
    if (formAttachments.length) {
      dispatchChangeStep(3);
    } else {
      dispatchChangeStep(2);
    }
  };

  const handlePressReplyButton = () => {
    dispatchResetLossDraftState({ stateToReset: 'errors' });
    setShowReplyBox(true);
    setIsKeyboardVisible(true);
  };

  const onBlurHelper = (event) => {
    /**
     * The reply box onBlur event blocks the initial add attachment
     * and send reply events on mobile web. This logic checks if a
     * related target was selected, and handles the action.
     */
    if (Platform.OS === 'web' && event.relatedTarget) {
      if (event.relatedTarget.role !== 'button') {
        handlePressFileRowButton();
      }
      if (event.relatedTarget.ariaLabel === 'Send') {
        dispatchPostReply({
          attachments: parseDocUploadAttachments(formAttachments),
          body: formReply.body,
          messageId: selectedMessageId,
        });
      }
    }
    setIsKeyboardVisible(false);
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => {
          dispatchFetchMessages();
          navigation.navigate('LossDraft');
        }}
        title="Message Details"
      />
      <ConditionalRender
        Component={(
          <Fragment>
            <StyledView>
              <StyledTitle>{title}</StyledTitle>
              <H5>{`Claim #: ${claimNumber}`}</H5>
            </StyledView>
            <StyledPrimaryDivider />
          </Fragment>
        )}
        shouldRender={!isKeyboardVisible}
      />
      <KeyboardAvoidingScrollView>
        {messages.map(({
          attachments,
          body,
          created,
          fromToLabel,
          fromToName,
          id,
        }, i) => (
          <StyledView key={id}>
            <StyledMessageHeader>
              <P2>
                <BoldText>{fromToLabel}</BoldText>
                <Text>{fromToName}</Text>
              </P2>
              <P2>{formatDate(created, 'UTC', 'M/D/YY')}</P2>
            </StyledMessageHeader>
            <StyledBody>{body}</StyledBody>
            {attachments.map((attachment) => (
              <AttachmentContainer key={attachment.id}>
                <AttachmentIcon>{DownloadIcon}</AttachmentIcon>
                <StyledBorderlessButton
                  label={attachment.filename}
                  onPress={() => {
                    dispatchResetLossDraftState({ stateToReset: 'errors' });
                    setTimeout(() => (
                      dispatchFetchAttachment({
                        attachment,
                        dispatchSetFile: dispatchSetAttachmentFile,
                        dispatchToggleDrawer: dispatchToggleAttachmentDrawer,
                      })), 500);
                  }}
                />
              </AttachmentContainer>
            ))}
            <ConditionalRender
              Component={StyledPrimaryDivider}
              shouldRender={i !== messages.length - 1}
            />
          </StyledView>
        ))}
        <ConditionalRender
          Component={MessagesReplyComponent}
          propsToPassDown={{
            dispatchChangeFormFieldValue,
            dispatchChangeStep,
            dispatchPostReply,
            formAttachments,
            formReply,
            formUploadError,
            handlePressFileRowButton,
            onBlur: onBlurHelper,
            requestStatusPostReply,
            selectedMessageId,
            setIsKeyboardVisible,
          }}
          shouldRender={showReplyBox}
        />
      </KeyboardAvoidingScrollView>
      <ConditionalRender
        Component={(
          <StyledButtonView>
            <LargePrimaryButton
              label="Reply"
              onPress={handlePressReplyButton}
            />
          </StyledButtonView>
        )}
        shouldRender={!isMessageStatusClosed && !showReplyBox}
      />
      <ConditionalRender
        Component={(
          <AlertContainer>
            <AlertBanner
              boldText="This inquiry has been closed"
              lineBreak
              text="Please send a new message if you have questions related to this conversation."
            />
          </AlertContainer>
        )}
        shouldRender={isMessageStatusClosed}
      />
    </Fragment>
  );
};

MessageDetailsComponent.propTypes = {
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchFetchAttachment: T.func.isRequired,
  dispatchFetchMessages: T.func.isRequired,
  dispatchPostReply: T.func.isRequired,
  dispatchResetLossDraftState: T.func.isRequired,
  dispatchSetAttachmentFile: T.func.isRequired,
  dispatchToggleAttachmentDrawer: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formReply: T.object.isRequired,
  formUploadError: T.string.isRequired,
  isMessageStatusClosed: T.bool.isRequired,
  messageDetails: T.shape({
    author: T.string.isRequired,
    claimNumber: T.string.isRequired,
    messages: T.arrayOf(T.shape({
      attachments: T.array.isRequired,
      body: T.string.isRequired,
      created: T.string.isRequired,
      fromToLabel: T.string.isRequired,
      fromToName: T.string.isRequired,
      id: T.string.isRequired,
    })).isRequired,
    title: T.string.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
  requestStatusPostReply: T.string.isRequired,
  selectedMessageId: T.string.isRequired,
  setShowReplyBox: T.func.isRequired,
  showReplyBox: T.bool.isRequired,
};

export default MessageDetailsComponent;
