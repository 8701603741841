import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';

export const Container = styled.View`
  align-items: center;
  flex-shrink: 1;
  flex-direction: row;
  padding:
    ${moderateScale(11)}px ${moderateScale(22)}px ${moderateScale(12)}px ${moderateScale(78)}px;
`;

export const DropDownLabel = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  flex-shrink: 1;
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  line-height: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  margin-right: ${moderateScale(8)}px;
  min-height: ${moderateScale(18)}px;
  padding: 0;
`;

export const LabelContainer = styled.View`
  align-items: center;
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
`;
