import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { PrimaryDivider, TertiaryDivider } from '../../../../base_ui';

export const CardContainer = styled.View`
  padding-bottom: ${moderateScale(31)}px;
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(17)}px;
`;

export const CenterContainer = styled.View`
  align-items: center;
  padding-top: ${moderateScale(6)}px;
`;

export const RowContainer = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: ${moderateScale(17)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;

export const StyledTertiaryDivider = styled(TertiaryDivider)`
  max-width: 100%;
`;
