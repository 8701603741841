import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import iconDictionary from '../../../../utils/iconDictionary';
import { P3 } from '../../../base_ui';
import {
  StyledCard,
  StyledH5,
  StyledIconButton,
  TextContainer,
} from './styledComponents';

const ArrowRight = iconDictionary('arrowRightCarbonS');

const AdditionalInfoCard = ({
  Icon,
  onPress,
  text,
  title,
}) => (
  <Pressable aria-label={`${title}-button`} onPress={onPress}>
    <StyledCard>
      {Icon}
      <TextContainer>
        <StyledH5>{title}</StyledH5>
        <P3>{text}</P3>
      </TextContainer>
      <StyledIconButton
        aria-label={`${title}-button`}
        Icon={ArrowRight}
        onPress={onPress}
      />
    </StyledCard>
  </Pressable>
);

AdditionalInfoCard.propTypes = {
  Icon: T.element.isRequired,
  onPress: T.func.isRequired,
  text: T.string.isRequired,
  title: T.string.isRequired,
};

export default AdditionalInfoCard;
