import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import ErrorMessage from '../../ErrorMessage';

const ErrorView = ({ handleCloseModalView }) => (
  <View>
    <ErrorMessage
      body="Please try again later."
      link={{
        label: 'Continue to Dashboard',
        onPress: handleCloseModalView,
      }}
      title="Sorry, something unexpected happened."
    />
  </View>
);

ErrorView.propTypes = { handleCloseModalView: T.func.isRequired };

export default ErrorView;
