import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const CenterContainer = styled.View`
  align-items: center;
`;

export const Container = styled.View`
  padding-bottom: ${moderateScale(16)}px;
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(4)}px;
`;

export const HeadingContainer = styled.View`
  height: ${moderateScale(72)}px;
  justify-content: center;
`;
