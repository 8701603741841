/**
* Filter
* @description: An underlined button
* Use Case: Use to filter data and display selected filter
* Component Props: {
*   isSelected (bool) state determining whether the button is currently selected
*   label (string) label associatied with the button
*   onPress (func) function that is invoked when filter is pressed
* }
*/

import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import ConditionalRender from '../ConditionalRender';
import {
  FilterContainer,
  StatementsFilterContainer,
  StyledDivider,
  StyledStatementsDivider,
  StyledStatementsText,
  StyledText,
} from './styledComponents';

const Filter = ({
  containerWidth,
  fontSize,
  isSelected,
  isStatementsFilter = false,
  label,
  onPress,
}) => (
  <Pressable
    // accessibilitySelected is deprecated (replaced with aria-selected)
    // in react-native-web version 0.19. accessibilitySelected can be removed
    // when the react-native-web version is updated
    accessibilitySelected={isSelected}
    aria-selected={isSelected}
    onPress={onPress}
    role="tab"
    style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
  >
    <ConditionalRender
      Component={(
        <StatementsFilterContainer>
          <StyledStatementsText isSelected={isSelected}>{label}</StyledStatementsText>
          <StyledStatementsDivider isSelected={isSelected} />
        </StatementsFilterContainer>
      )}
      FallbackComponent={(
        <FilterContainer $width={containerWidth}>
          <StyledText $fontSize={fontSize} isSelected={isSelected}>{label}</StyledText>
          <StyledDivider isSelected={isSelected} />
        </FilterContainer>
      )}
      shouldRender={isStatementsFilter}
    />
  </Pressable>
);

Filter.propTypes = {
  containerWidth: T.number,
  fontSize: T.string,
  isSelected: T.bool.isRequired,
  isStatementsFilter: T.bool,
  label: T.string.isRequired,
  onPress: T.func.isRequired,
};

export default Filter;
