import styled from 'styled-components/native';
import { Platform } from 'react-native';

import {
  BorderlessButton,
  H3,
  P2,
  PrimaryDivider,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const AlertContainer = styled.View`
  margin: ${moderateScale(24)}px;
`;

export const AttachmentContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: ${moderateScale(18)}px;
`;

export const AttachmentIcon = styled.View`
  margin-right: ${moderateScale(5)}px;
  width: ${moderateScale(16)}px;
`;

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const StyledBody = styled(P2)`
  padding-bottom: ${moderateScale(8)}px;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: f4,
  }],
}))`
    padding-right: ${moderateScale(20)}px;
    ${Platform.OS === 'web' ? `
      width: fit-content;
      word-break: break-all;
      `
    : ''}
  `;

export const StyledButtonView = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  justify-content: center;
  padding: ${moderateScale(16)}px ${moderateScale(57)}px ${moderateScale(35)}px;
`;

export const StyledMessageHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(14)}px 0;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
`;

export const StyledTitle = styled(H3)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  margin-bottom: ${moderateScale(8)}px;
  padding: 0 ${moderateScale(24)}px;
`;
