import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { P2 } from '../../../base_ui';

export const IconWrapper = styled.View`
  align-self: center;
  margin-right: ${(moderateScale(25))}px;
`;

export const RowButtonContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${(moderateScale(42))}px;
  justify-content: space-between;
  margin-bottom: ${(moderateScale(5))}px;
  padding-left: ${(moderateScale(24))}px;
  width: 100%;
`;

export const SelectedWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 80%;
`;

export const StyledP2 = styled(P2)`
  margin-right: ${(moderateScale(9))}px;
  text-align: right;
  width: 80%;
`;
