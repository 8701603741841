/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

const PendingCarbon = (props) => {
  const { colors: { primaryCarbon } } = useTheme();

  return (
    <Svg
      fill="none"
      height={moderateScale(16)}
      viewBox="0 0 16 16"
      width={moderateScale(16)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M15.2402 8.00016C15.2402 11.9987 11.9987 15.2402 8.00016 15.2402C4.00161 15.2402 0.760156 11.9987 0.760156 8.00016C0.760156 4.00161 4.00161 0.760156 8.00016 0.760156C11.9987 0.760156 15.2402 4.00161 15.2402 8.00016Z" stroke={primaryCarbon} strokeWidth="1.2" />
      <Path d="M5.46003 10.3334C5.33049 10.3365 5.20351 10.2971 5.09847 10.2213C4.99343 10.1454 4.91612 10.0372 4.87833 9.9133C4.84054 9.78937 4.84437 9.65647 4.88921 9.53491C4.93406 9.41334 5.01747 9.30981 5.12669 9.2401L7.42003 7.68677V3.8801C7.41914 3.80075 7.43401 3.72201 7.46377 3.64845C7.49353 3.57488 7.53758 3.50795 7.59339 3.45152C7.64919 3.3951 7.71563 3.35031 7.78887 3.31974C7.8621 3.28917 7.94067 3.27343 8.02003 3.27344C8.17916 3.27344 8.33177 3.33665 8.44429 3.44917C8.55681 3.5617 8.62003 3.71431 8.62003 3.87344V8.0001C8.61963 8.09886 8.59513 8.19602 8.54866 8.28315C8.50219 8.37029 8.43515 8.44476 8.35336 8.5001L5.79336 10.2334C5.6944 10.2986 5.57852 10.3334 5.46003 10.3334Z" fill={primaryCarbon} />
    </Svg>
  );
};

export default PendingCarbon;
