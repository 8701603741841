/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Ellipse,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function DogWaiting(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg data-name="Layer 1" fill="#000" height={moderateScale(120)} id="Layer_1" viewBox="0 0 287 120" width={moderateScale(287)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect className="cls-1" fill="#e6ebef" height="89.39" width="110.52" x="83.8" y="0.36" />
      <Rect className="cls-2" fill="#f0f3f5" height="55.92" width="29.78" x="103.32" y="18.21" />
      <Rect className="cls-2" fill="#f0f3f5" height="55.92" width="29.78" x="145.01" y="18.21" />
      <Polyline className="cls-3" fill="none" points="83.8 0.98 83.8 89.75 194.32 89.75 194.32 0.11" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" />
      <Rect className="cls-3" fill="none" height="55.92" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" width="29.78" x="103.32" y="18.21" />
      <Rect className="cls-3" fill="none" height="55.92" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" width="29.78" x="145.01" y="18.21" />
      <Polyline className="cls-3" fill="none" points="133.1 0.33 133.1 7.86 103.32 7.86 103.32 0.38" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" />
      <Polyline className="cls-3" fill="none" points="174.8 0.81 174.8 7.86 145.01 7.86 145.01 0.49" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" />
      <Polyline className="cls-3" fill="none" points="40.49 91.34 82.69 91.34 83.9 89.76" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="70.18" x2="70.18" y1="88.92" y2="3.5" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="209.77" x2="209.77" y1="88.92" y2="3.5" />
      <Rect className="cls-4" fill="#fff" height="16.74" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.51px" width="6.08" x="183.11" y="5.03" />
      <Ellipse className="cls-4" cx="186.15" cy="10.18" fill="#fff" rx="3.53" ry="2.86" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.51px" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.51px" x1="186.12" x2="186.12" y1="15.73" y2="18.03" />
      <Ellipse cx="186.12" cy="15.87" rx="0.53" ry="0.43" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" x1="156.68" x2="152.46" y1="72.19" y2="72.19" />
      <Polyline className="cls-5" fill="none" points="172.59 40.23 172.59 72.19 157.99 72.19" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" x1="172.59" x2="172.59" y1="36.88" y2="38.98" />
      <Polyline className="cls-5" fill="none" points="147.03 44.71 147.03 20.16 171.95 20.16" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" x1="147.04" x2="147.04" y1="46.18" y2="47.26" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" x1="115.06" x2="110.85" y1="20.15" y2="20.15" />
      <Polyline className="cls-5" fill="none" points="130.98 41.37 130.98 20.16 116.38 20.16" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" x1="130.98" x2="130.98" y1="44.71" y2="42.61" />
      <Polyline className="cls-5" fill="none" points="105.41 36.88 105.41 72.19 130.34 72.19" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" x1="105.43" x2="105.43" y1="35.42" y2="34.33" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.46px" x1="81.92" x2="81.92" y1="90.36" y2="1.73" />
      <Polyline className="cls-3" fill="none" points="235.49 91.34 195.76 91.34 194.55 89.76" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.46px" x1="196.53" x2="196.53" y1="30.92" y2="2.38" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.46px" x1="196.53" x2="196.53" y1="90.36" y2="36.62" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="238.11" x2="255.63" y1="91.27" y2="91.27" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="265.09" x2="273.21" y1="91.27" y2="91.27" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="275.57" x2="287" y1="91.27" y2="91.27" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="7.8" x2="1" y1="91.37" y2="91.37" />
      <Line className="cls-3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.69px" x1="32.2" x2="12.46" y1="91.37" y2="91.37" />
      <Polyline className="cls-5" fill="none" points="152.98 5.96 172.61 5.96 172.61 3.44" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" />
      <Polyline className="cls-5" fill="none" points="105.31 4.41 105.31 6.04 118.83 6.04" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.41px" />
      <Path d="M166.37,89.48a.15.15,0,1,1,0-.3" />
      <Path d="M119.06,89.18a.15.15,0,0,1,0,.3" />
      <Path d="M24.85,89.48a.15.15,0,0,1,0-.3" />
      <Path d="M166.72,103.32c-.27,0-.5-.07-.5-.15s.23-.15.5-.15" />
      <Path d="M108.17,103c.28,0,.5.07.5.15s-.22.15-.5.15" />
      <Path d="M119.06,103a.15.15,0,0,1,0,.3" />
      <Path d="M139.53,69.8a.22.22,0,0,1-.22.22m-.22-.44" />
      <Path d="M146.48,70a.22.22,0,0,1-.22-.22m.44-.22" />
      <Path d="M13,82.8a.22.22,0,0,1-.22-.22.22.22,0,0,1,.22-.23" />
      <Path d="M154.3,68.49a.13.13,0,0,1-.14-.13.14.14,0,0,1,.14-.14" />
      <Path d="M147.83,48a.14.14,0,0,1-.14.14.13.13,0,0,1-.13-.14" />
      <Path d="M137.55,45.47a.13.13,0,0,1-.14.13.13.13,0,0,1-.13-.13" />
      <Path d="M137.65,68.22a.13.13,0,0,1,.13.14.13.13,0,0,1-.13.13" />
      <Path d="M283.16,82.35a.22.22,0,0,1,.22.23.22.22,0,0,1-.22.22" />
      <Polygon className="cls-7" fill="#d9e1e8" points="192.57 118.72 93.96 118.72 106.34 97.94 179.36 97.94 192.57 118.72" />
      <Polygon className="cls-8" fill={svgHighlight} points="192.57 117.34 93.96 117.34 106.34 96.56 179.36 96.56 192.57 117.34" />
      <Path d="M192.58,117.56H94a.21.21,0,0,1-.19-.11.22.22,0,0,1,0-.22l12.38-20.79a.25.25,0,0,1,.2-.1h73a.21.21,0,0,1,.19.1l13.21,20.78a.22.22,0,0,1-.18.34Zm-98.23-.44h97.82L179.24,96.78H106.47Z" />
      <Path d="M125.24,112.49H103.15a.15.15,0,0,1-.13-.07.12.12,0,0,1,0-.14l6.54-12.48a.17.17,0,0,1,.13-.07h8.82a.15.15,0,0,1,.15.14.15.15,0,0,1-.15.15h-8.73l-6.39,12.18h21.85a.14.14,0,0,1,.14.15A.14.14,0,0,1,125.24,112.49Z" />
      <Path d="M181.64,112.49H171.25a.15.15,0,0,1-.15-.14.15.15,0,0,1,.15-.15H181.4L175,100h-38.6a.15.15,0,0,1-.15-.15.15.15,0,0,1,.15-.14H175.1a.17.17,0,0,1,.13.07l6.54,12.48a.17.17,0,0,1,0,.14A.15.15,0,0,1,181.64,112.49Z" />
      <Ellipse className="cls-9" cx="143.05" cy="105.88" fill={svgHighlight} rx="20.73" ry="3.95" />
      <Path className="cls-10" d="M129.85,66.12a7.47,7.47,0,0,1-4.32.72,4.84,4.84,0,0,1-3.41-3.35,10.75,10.75,0,0,1,.23-5.74,27.58,27.58,0,0,1,5.29-9.64c2.6-2.9,4.1-3.9,5.12-4.21a4.45,4.45,0,0,1,1.5-.25,8.67,8.67,0,0,1,4-2.38,9.62,9.62,0,0,1,5,.53s2.3-1.64,6.71-.64a23.52,23.52,0,0,1,10.58,6.23,8.16,8.16,0,0,1,2.07,7.48c-.61,2.61-5.15,2.83-5.15,2.83a23.53,23.53,0,0,1,.42,5.62c-.28,2-2.91,5.68-2.91,5.68s1.61,7.87,1.88,9.45,1.66,9.8,1.66,9.8,2.72-.47,4.24,1,2,4.35,1.75,6.48a34.24,34.24,0,0,1-2.5,8.36,4.26,4.26,0,0,1-3.26,1.83c-.59,0-3.3,0-3.3,0a12.85,12.85,0,0,1-8.84,2l-8.53,0a13.12,13.12,0,0,1-5.37-1.63l-.69-.53a23.68,23.68,0,0,1-3.58,0,4.7,4.7,0,0,1-2.91-1.75c-.83-1.19-2.29-7.28-2.29-7.28a10.15,10.15,0,0,1,.19-4.79c.75-2.44,1.74-3.22,2.58-3.49a11.51,11.51,0,0,1,2.82-.2l1.61-9.22,1.77-8.86A12.45,12.45,0,0,1,129.85,66.12Z" fill="#fff" />
      <Path className="cls-11" d="M122.18,63.16s2,2.16,4.1,1.77,5.48-4.38,6.31-10.64c0,0,.61,12.69-5.76,12.69C126.83,67,123.79,67.64,122.18,63.16Z" fill="#dce2e7" />
      <Path className="cls-11" d="M128.77,91.08a3.64,3.64,0,0,0-4,1.1c-1.49,2,.34,10.31.34,10.31s-3.22-6.43-1.22-11.75c1-2.81,5.1-2.47,5.1-2.47Z" fill="#dce2e7" />
      <Path className="cls-11" d="M144,41.61s5.76-.73,10.8,4.65,3.16,7.92,1.83,8.39-4,0-6-1.58c0,0,2.3,3.66,6,4.49a5.46,5.46,0,0,0,6.24-2.83c.41-1,.08-4.57-1.53-6.64S151.73,38.75,144,41.61Z" fill="#dce2e7" />
      <Path className="cls-11" d="M152,55a20,20,0,0,1,.81,11.18c-1.46,5.61-9.57,8.23-9.57,8.23s8,.12,12.67-6.48A10.51,10.51,0,0,0,157.08,57Z" fill="#dce2e7" />
      <Path className="cls-11" d="M151.65,71.74a49.63,49.63,0,0,1,3.66,16.07c.22,8.14-1.05,17.5-8,19.22l-.72,1s6.53.22,9.41-2.77c0,0,.22.74,2.58.7s3.43-.93,5.19-6.39.15-9.49-2.06-10.93S158.3,88,158.3,88L155,69.15Z" fill="#dce2e7" />
      <Path d="M144.51,108.4h-6.44a11,11,0,0,1-8.36-4.3.44.44,0,1,1,.71-.53,10,10,0,0,0,7.66,4H149.5a10.1,10.1,0,0,0,7.81-4.18.45.45,0,0,1,.62-.11.43.43,0,0,1,.11.61,10.89,10.89,0,0,1-8.54,4.56Z" />
      <Path className="cls-10" d="M155.28,69.65c.61,3.41,1.36,7.58,1.67,9,.35,1.59,1.52,7.79,2.07,13.45" fill="#fff" />
      <Path d="M159,92.53a.44.44,0,0,1-.44-.4c-.57-5.87-1.79-12.15-2.06-13.39-.32-1.44-1.09-5.7-1.68-9a.45.45,0,0,1,.88-.16c.58,3.31,1.35,7.56,1.66,9,.28,1.25,1.51,7.58,2.08,13.5a.45.45,0,0,1-.4.48Z" />
      <Path className="cls-10" d="M128.35,94.78a127.74,127.74,0,0,1,2.27-16.14c.27-1.22.87-4.49,1.42-7.56" fill="#fff" />
      <Path d="M128.35,95.22h0a.44.44,0,0,1-.42-.46,126.76,126.76,0,0,1,2.29-16.21c.22-1,.69-3.55,1.41-7.54a.45.45,0,0,1,.51-.36.44.44,0,0,1,.36.51c-.72,4-1.19,6.56-1.42,7.58a127.88,127.88,0,0,0-2.26,16.07A.45.45,0,0,1,128.35,95.22Z" />
      <Path className="cls-10" d="M140.87,104.91c-2.14,5.51-9.55,8.94-9.55,8.94s13.33,3.28,16.92-9.9" fill="#fff" />
      <Path className="cls-11" d="M133,113.81s5.31-.27,8.2-3.4a15.86,15.86,0,0,0,3.22-4.81,3.88,3.88,0,0,1,3-2.44h0a.71.71,0,0,1,.79.94c-.79,2.13-2.82,6.63-6.41,8.49S133,113.81,133,113.81Z" fill="#dce2e7" />
      <Path d="M131.94,106.41h-2.75a4.76,4.76,0,0,1-4.57-3.36l-1.05-3.34c-1.53-4.4-1.15-8.69,1-10.66a4.8,4.8,0,0,1,4.57-1.19.46.46,0,0,1,.29.56.44.44,0,0,1-.56.28,3.94,3.94,0,0,0-3.69,1c-1.83,1.71-2.13,5.71-.73,9.73l1.06,3.36a3.88,3.88,0,0,0,3.72,2.73h2.75a.45.45,0,1,1,0,.89Z" />
      <Path d="M158.55,106.41h-2.72a.45.45,0,0,1,0-.89h2.72a3.89,3.89,0,0,0,3.72-2.73l1-3.35c1.4-4,1.11-8-.72-9.74a4,4,0,0,0-3.69-1,.44.44,0,0,1-.56-.28.44.44,0,0,1,.28-.56,4.8,4.8,0,0,1,4.57,1.19c2.11,2,2.49,6.26,1,10.67l-1,3.33A4.75,4.75,0,0,1,158.55,106.41Z" />
      <Path d="M134.82,114.63a17.52,17.52,0,0,1-3.61-.35.45.45,0,0,1,0-.85c.08,0,8.17-3,9.27-8.6a.43.43,0,0,1,.51-.35.44.44,0,0,1,.35.52c-.9,4.63-5.91,7.5-8.41,8.67,3.49.42,12.15.36,14.92-9.84a.44.44,0,0,1,.54-.31.43.43,0,0,1,.31.54C146.2,113.14,139.17,114.63,134.82,114.63Z" />
      <Path d="M126.57,67.42a4.48,4.48,0,0,1-3.9-2c-2.41-3.54-1.42-8.67,2.94-15.23a21.2,21.2,0,0,1,2.3-3c.1-.13,3.15-4,6-4H134a.44.44,0,0,1,.43.45.44.44,0,0,1-.44.44h0c-2.45,0-5.33,3.69-5.36,3.73a20.78,20.78,0,0,0-2.23,2.86c-4.15,6.23-5.14,11-2.95,14.24,1.84,2.71,5.51,1.15,5.67,1.08a.44.44,0,0,1,.35.81A8,8,0,0,1,126.57,67.42Z" />
      <Path d="M142.26,74.89a16.84,16.84,0,0,1-2-.11c-4.69-.5-8.16-2.82-10-6.73a16.27,16.27,0,0,1-.79-1.93.44.44,0,1,1,.83-.29,16.53,16.53,0,0,0,.76,1.84c4.21,8.76,15.42,5.86,15.53,5.83a.44.44,0,0,1,.54.32.45.45,0,0,1-.31.54A19.52,19.52,0,0,1,142.26,74.89Z" />
      <Path d="M158.26,58.06a.43.43,0,0,1-.43-.39.43.43,0,0,1,.39-.49c.16,0,4.12-.51,4.36-3.77.28-3.88-3-7.54-9.68-10.86l-.83-.39a.45.45,0,0,1,.37-.81c.28.13.56.26.85.41,7.06,3.49,10.49,7.44,10.17,11.71-.29,4-5.1,4.58-5.15,4.59Z" />
      <Path d="M150.47,72.9a.44.44,0,0,1-.39-.24.44.44,0,0,1,.19-.6c3.57-1.86,7.74-5.36,7.27-11.5a16,16,0,0,0-.44-2.73.46.46,0,0,1,.33-.54.43.43,0,0,1,.53.32,17.82,17.82,0,0,1,.47,2.88c.53,7-4.63,10.73-7.76,12.36A.46.46,0,0,1,150.47,72.9Z" />
      <Path d="M152.17,42.2l-.15,0c-5.6-2-8.25-.09-8.28-.07a.46.46,0,0,1-.62-.09.45.45,0,0,1,.09-.62c.12-.09,3-2.22,9.11,0a.44.44,0,0,1,.27.56A.45.45,0,0,1,152.17,42.2Z" />
      <Path d="M134.23,44.08a.46.46,0,0,1-.29-.1.45.45,0,0,1-.06-.62,8.65,8.65,0,0,1,4.59-2.61,7,7,0,0,1,5,.67.44.44,0,0,1-.47.75h0a6.26,6.26,0,0,0-4.36-.55,7.76,7.76,0,0,0-4.09,2.3A.44.44,0,0,1,134.23,44.08Z" />
      <Path d="M129.59,66.53a.31.31,0,0,1-.25-.13.3.3,0,0,1,.08-.41s3.14-2.3,3-12.11a.29.29,0,0,1,.29-.3.3.3,0,0,1,.3.29c.14,10.21-3.12,12.52-3.26,12.61A.33.33,0,0,1,129.59,66.53Z" />
      <Path d="M157.83,58h0c-6.11-.11-10-8.31-10.17-8.66a.29.29,0,0,1,.14-.39.3.3,0,0,1,.4.14c0,.08,3.9,8.21,9.64,8.32a.3.3,0,0,1,0,.59Z" />
      <Path d="M169.42,112.5h-4a.15.15,0,0,1,0-.3h4a.15.15,0,0,1,0,.3Z" />
      <Path d="M114.92,111.07h0a.15.15,0,0,1-.14-.16l0-.6a.15.15,0,0,1,.15-.14.14.14,0,0,1,.14.15l0,.6A.15.15,0,0,1,114.92,111.07Z" />
      <Path d="M113.35,108.71a.16.16,0,0,1-.09,0,.15.15,0,0,1,0-.21l.27-.35a.14.14,0,0,1,.2,0,.15.15,0,0,1,0,.21l-.27.34A.14.14,0,0,1,113.35,108.71Z" />
      <Path d="M111,109.54a.15.15,0,0,1-.14-.1l-.13-.41a.15.15,0,0,1,.1-.18.15.15,0,0,1,.19.1l.12.4a.15.15,0,0,1-.09.19Z" />
      <Path d="M107.26,110.88a.15.15,0,0,1-.14-.1.15.15,0,0,1,.08-.19l.4-.15a.15.15,0,0,1,.11.27l-.4.16Z" />
      <Path d="M110.07,107.44a.13.13,0,0,1-.12-.07.14.14,0,0,1,0-.2l.35-.23a.15.15,0,0,1,.21,0,.15.15,0,0,1,0,.2l-.36.24Z" />
      <Path d="M118.86,110.82h0l-.41-.12a.14.14,0,0,1-.09-.19.14.14,0,0,1,.18-.09l.41.12a.15.15,0,0,1,.09.19A.14.14,0,0,1,118.86,110.82Z" />
      <Path d="M108.49,108.86a.15.15,0,0,1,0-.29l.36-.07a.15.15,0,0,1,.17.12.14.14,0,0,1-.12.17l-.36.07Z" />
      <Path d="M112.44,111.06a.16.16,0,0,1-.11,0l-.19-.22a.14.14,0,0,1,0-.2.14.14,0,0,1,.21,0l.19.21a.15.15,0,0,1,0,.21A.21.21,0,0,1,112.44,111.06Z" />
      <Path d="M120.2,117.83a.15.15,0,0,1,.19-.08.15.15,0,0,1,.08.19" />
      <Path d="M165.24,117.94a.14.14,0,0,1,.08-.19.15.15,0,0,1,.19.08" />
    </Svg>
  );
}
