import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H1 } from '../../base_ui';

export const FooterBlock = styled.View`
  height: ${moderateScale(120)}px;
`;

export const Heading = styled(H1)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  padding-bottom: ${moderateScale(12)}px;
  z-index: 10;
`;

export const ListContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: 100%;
  margin-top: -${moderateScale(7)}px;
`;

export const StyledSectionList = styled.SectionList`
  padding-horizontal: ${moderateScale(24)}px;
`;

export const StyledView = styled.View`
  flex: 1;
`;
