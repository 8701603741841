import React, { Fragment } from 'react';
import T from 'prop-types';

import { SecondaryHeader } from '../../ScreenHeaders';
import { P3 } from '../../base_ui';
import MFAForm from './MFAForm';
import {
  HeaderSection,
  MFAContainer,
  StyledHeader,
} from './styledComponents';

const MultiFactorAuthentication = ({
  dispatchChangeInput,
  dispatchSelectMfa,
  mfaBackHandler,
  mfaOptions,
  selectedMfaOption,
  status,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={mfaBackHandler}
      title="Two-Factor Authentication"
    />
    <MFAContainer>
      <HeaderSection>
        <StyledHeader>
          Receiving Two-Factor Authentication codes.
        </StyledHeader>
        <P3>
          For your security, a temporary code is needed to make changes to your account.
        </P3>
      </HeaderSection>
      <MFAForm
        dispatchChangeInput={dispatchChangeInput}
        dispatchSelectMfa={dispatchSelectMfa}
        mfaOptions={mfaOptions}
        selectedMfaOption={selectedMfaOption}
        status={status}
      />
    </MFAContainer>
  </Fragment>
);

MultiFactorAuthentication.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchSelectMfa: T.func.isRequired,
  mfaBackHandler: T.func.isRequired,
  mfaOptions: T.array.isRequired,
  selectedMfaOption: T.string.isRequired,
  status: T.string.isRequired,
};

export default MultiFactorAuthentication;
