/* eslint-disable max-len */
import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

const CalendarSm = (props) => (
  <Svg fill="none" height={moderateScale(18)} viewBox="0 0 19 18" width={moderateScale(19)} xmlns="http://www.w3.org/2000/svg" {...props}>
    <Rect fill="white" height="18" transform="translate(0.5)" width="18" />
    <Path d="M2.75 3.75H16.25V15.75H2.75V3.75Z" stroke="#282828" strokeLinejoin="round" />
    <Path d="M16.25 6.75H2.75" stroke="#282828" strokeLinecap="round" />
    <Path d="M5.75 3.75V2.25" stroke="#282828" strokeLinecap="round" />
    <Path d="M13.25 3.75V2.25" stroke="#282828" strokeLinecap="round" />
  </Svg>
);

export default CalendarSm;
