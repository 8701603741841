import React from 'react';
import T from 'prop-types';
import { useTheme } from 'styled-components/native';

import {
  SSO_PRETRANSFER_MODAL_TEXT,
  SSO_PRETRANSFER_MODAL_TITLE,
} from '@dmi/shared/redux/Main/constants';

import MainModal from '../../MainModal';

const SsoPretransferModal = ({
  dispatchDisableInstantPostLoginModal,
  isModalViewOpen,
  ssoClientImageProps,
}) => {
  const { colors: { linkText }, fontFamilies: { secondaryFont } } = useTheme();

  const handleCloseModal = () => {
    dispatchDisableInstantPostLoginModal();
  };

  return (
    <MainModal
      handleCloseModal={handleCloseModal}
      imageProps={ssoClientImageProps}
      secondaryButtonProps={{
        label: 'Got it',
        onPress: handleCloseModal,
        style: { borderWidth: 0 },
        titleStyle: {
          color: linkText,
          fontFamily: secondaryFont,
        },
      }}
      text={SSO_PRETRANSFER_MODAL_TEXT}
      title={SSO_PRETRANSFER_MODAL_TITLE}
      visible={isModalViewOpen}
    />
  );
};

SsoPretransferModal.propTypes = {
  dispatchDisableInstantPostLoginModal: T.func.isRequired,
  isModalViewOpen: T.bool.isRequired,
  ssoClientImageProps: T.shape({
    height: T.number.isRequired,
    source: T.object.isRequired,
    width: T.number.isRequired,
  }).isRequired,
};

export default SsoPretransferModal;
