/* eslint-disable sort-keys */
import React, { useCallback } from 'react';
import T from 'prop-types';
import { Linking, useWindowDimensions } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';

import { OTP_PAYMENT_STATUSES } from '@dmi/shared/redux/Payments/OneTimePayment/statusDictionary';
import { MANCODE_Y_TEXT } from '@dmi/shared/redux/Payments/Autopay/constants';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import { getSeasonalIllustrations } from '../../../../utils/illustrationHelpers';
import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  ConditionalRender,
  H1,
  P3,
  TransferStatusBanner,
} from '../../../base_ui';
import DismissibleDocUploadBanner from '../DismissibleDocUploadBanner';
import { PaymentModal } from '../../sharedComponents';
import { getWelcomeMessage } from '../helpers';
import PaymentStatusBlock from '../PaymentStatusBlock';
import {
  ButtonWrapper,
  PaymentHomeContainer,
} from '../styledComponents';
import {
  BoldText,
  ContentTextWrapper,
  ContentWrapper,
  Date,
  IllustrationWrapper,
  Payment,
  StyledFormattedMessageStatusBanner,
  StyledLargePrimaryButton,
  StyledP3,
  StyledPaymentStatusBlock,
  StyledPhoneLinkText,
  StyledStatusBanner,
} from './styledComponents';

const PaymentHomeMain = ({
  address,
  autopayAllowedOperation,
  autopayStatus,
  autopayStatusProps,
  customerServicePhoneNumber,
  dispatchDismissDocUploadStatusBanner,
  dispatchResetMainError,
  docUploadNotificationFailure,
  fetchStatusLoading,
  handleRowPress,
  isAutopayEnabledOrPending,
  isLoanMancodeY,
  isPastServicingPaymentCutOffDate,
  modalProps,
  navigation,
  otpAllowedOperation,
  otpData: {
    dueDateMessage,
    monthlyAmountDue,
    pendingPaymentTotal,
    pendingScheduledDate,
  },
  respaLetterError,
  respaLetterId,
  status,
  transferStatusBannerProps: {
    shouldTransferBannerDisplay,
    transferStatusBannerText,
    transferStatusBannerTitle,
  },
  userDisplayName,
}) => {
  useFocusEffect(
    useCallback(() => () => {
      dispatchResetMainError('respaLetter');
    }, [dispatchResetMainError]),
  );

  const { PaymentHouse } = getSeasonalIllustrations();
  const welcomeMessage = getWelcomeMessage(userDisplayName);
  const windowWidth = useWindowDimensions().width;
  const width = windowWidth < 350 ? moderateScale(80) : moderateScale(100);
  const propsToPassDown = { width };

  const isEligibleForOtp = otpAllowedOperation === 'Schedule_Payment';
  const isEligibleForAutopay = autopayAllowedOperation === 'Request_Enable';
  const isEligibleForPayment = isEligibleForOtp || isEligibleForAutopay;
  const makePaymentButtonLabel = autopayStatus === 'Not_Enabled' ?
    'Make a Payment' :
    'Make a One-Time Payment';

  const statusToUse = isPastServicingPaymentCutOffDate ? 'Scheduled_Payment_Not_Eligible' : status;
  const { text = '', ...restStatusProps } = OTP_PAYMENT_STATUSES.mobile[statusToUse] || {};
  const textToRender = interpolate(text, {
    customerServicePhoneNumber,
    pendingPaymentTotal,
    pendingScheduledDate,
  });
  const otpStatusProps = {
    iconType: status === 'Scheduled_Payment_Accepted' ? 'outlinedPendingPurple' : 'outlinedWarning',
    message: textToRender,
    ...restStatusProps,
  };
  const handleMakeAPaymentClick = () => {
    const screen = isEligibleForAutopay ? 'MakePaymentSplash' : 'OtpPreConfirmation';
    navigation.navigate(screen);
  };

  return (
    <PaymentHomeContainer>
      <ConditionalRender
        Component={DismissibleDocUploadBanner}
        propsToPassDown={{
          customerServicePhoneNumber,
          dispatchDismissDocUploadStatusBanner,
          docUploadNotificationFailure,
          navigation,
        }}
        shouldRender={!!docUploadNotificationFailure}
      />

      <ConditionalRender
        Component={TransferStatusBanner}
        propsToPassDown={{
          handleButtonPress: handleRowPress,
          hasError: !!respaLetterError,
          hasRespaLetter: !!respaLetterId,
          text: transferStatusBannerText,
          title: transferStatusBannerTitle,
        }}
        shouldRender={shouldTransferBannerDisplay}
      />
      <StyledStatusBanner error={respaLetterError} />
      <ConditionalRender
        Component={StyledFormattedMessageStatusBanner}
        propsToPassDown={{
          defaultMessage: MANCODE_Y_TEXT,
          error: true,
          values: {
            // eslint-disable-next-line react/prop-types
            a: ({ msg }) => (
              <StyledPhoneLinkText
                // eslint-disable-next-line react/prop-types
                onPress={() => Linking.openURL(`tel://${msg.replace(/\+|-/g, '')}`)}
              >
                {msg}
              </StyledPhoneLinkText>
            ),
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <BoldText>{msg}</BoldText>,
            mobileNewline: '\n',
          },
          WrapperComponent: P3,
        }}
        shouldRender={isLoanMancodeY}
      />
      <H1 numberOfLines={2}>{welcomeMessage}</H1>
      <StyledP3>{address}</StyledP3>
      <ContentWrapper>
        <ContentTextWrapper>
          <Payment>{monthlyAmountDue}</Payment>
          <Date>{dueDateMessage}</Date>
        </ContentTextWrapper>
        <IllustrationWrapper>
          <PaymentHouse {...propsToPassDown} />
        </IllustrationWrapper>
      </ContentWrapper>
      <ConditionalRender
        Component={PaymentStatusBlock}
        propsToPassDown={otpStatusProps}
        shouldRender={!isEligibleForOtp || isPastServicingPaymentCutOffDate}
      />
      <ConditionalRender
        Component={StyledPaymentStatusBlock}
        propsToPassDown={{
          $addMarginTop: !isEligibleForOtp || isPastServicingPaymentCutOffDate,
          autopayStatus,
          fetchStatusLoading,
          isAutopayEnabledOrPending,
          navigation,
          ...autopayStatusProps,
        }}
        shouldRender={!!autopayStatusProps && !isLoanMancodeY}
      />
      <ButtonWrapper>
        <StyledLargePrimaryButton
          disabled={!isEligibleForPayment || isPastServicingPaymentCutOffDate}
          label={makePaymentButtonLabel}
          onPress={handleMakeAPaymentClick}
        />
      </ButtonWrapper>
      <ConditionalRender
        Component={PaymentModal}
        propsToPassDown={modalProps}
        shouldRender={!!modalProps && !!modalProps.isOpen}
      />
    </PaymentHomeContainer>
  );
};

PaymentHomeMain.propTypes = {
  address: T.string.isRequired,
  autopayAllowedOperation: T.string,
  autopayStatus: T.string.isRequired,
  autopayStatusProps: T.object,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchDismissDocUploadStatusBanner: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  docUploadNotificationFailure: T.object,
  fetchStatusLoading: T.bool.isRequired,
  handleRowPress: T.func.isRequired,
  isAutopayEnabledOrPending: T.bool.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isPastServicingPaymentCutOffDate: T.bool.isRequired,
  modalProps: T.object.isRequired,
  navigation: T.object.isRequired,
  otpAllowedOperation: T.string.isRequired,
  otpData: T.shape({
    dueDateMessage: T.string.isRequired,
    monthlyAmountDue: T.string.isRequired,
    pendingPaymentTotal: T.string.isRequired,
    pendingScheduledDate: T.string.isRequired,
  }).isRequired,
  respaLetterError: T.oneOfType([T.bool, T.string]).isRequired,
  respaLetterId: T.oneOfType([T.bool, T.string]),
  status: T.string.isRequired,
  transferStatusBannerProps: T.shape({
    shouldTransferBannerDisplay: T.bool.isRequired,
    transferStatusBannerText: T.string.isRequired,
    transferStatusBannerTitle: T.string.isRequired,
  }),
  userDisplayName: T.string.isRequired,
};

export default PaymentHomeMain;
