import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import T from 'prop-types';
import {
  AppState,
  PanResponder,
  Platform,
  View,
} from 'react-native';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import throttle from 'lodash/throttle';

import { INACTIVITY_MODAL_TEXT, INACTIVITY_MODAL_TITLE } from '@dmi/shared/redux/Main/constants';
import { closeModal, logoutRequest, openModal } from '@dmi/shared/redux/Main/actions';
import makeSelectMain, { getIsSso } from '@dmi/shared/redux/Main/selectors';
import { setVideoModalData } from '@dmi/shared/redux/Help/actions';
import selectHelp from '@dmi/shared/redux/Help/selectors';

import { usePrevious } from '../../utils/customHooks';
// import { OidcSingleLogout } from '../../utils/deviceHelpers';
import { ConditionalRender } from '../../components/base_ui';
import MainModal from '../../components/MainModal';

const MAX_INACTIVE_TIME = process.env.APP === 'csr' ? 14400000 : 240000;
const MAX_LOGOUT_TIME = 60000;

const InactivityTimer = ({
  children,
  dispatchCloseModal,
  dispatchLogout,
  dispatchOpenModal,
  dispatchSetVideoModalData,
  isInactivityModalOpen,
  isLoggedIn,
  isSso,
  ssoLogoutUrl,
  videoModalData,
  videoRemainingDuration,
  ...restProps
}) => {
  const [isActive, setIsActive] = useState(true);
  const [lastActiveTimestamp, _setLastActiveTimestamp] = useState(Date.now());
  const timerRef = useRef();
  const previousIsLoggedIn = usePrevious(isLoggedIn);

  // Need reference to these values in the AppState event listener
  const lastActiveTimestampRef = useRef(lastActiveTimestamp);
  const appState = useRef(AppState.currentState);

  const setLastActiveTimestamp = useCallback((value) => {
    if (Platform.OS === 'web') {
      window.localStorage.setItem('lastActiveTimestamp', value);
    }
    lastActiveTimestampRef.current = value;
    _setLastActiveTimestamp(value);
  }, []);

  const clearTimer = useCallback(() => clearTimeout(timerRef.current), []);
  const createTimer = useCallback((callback, time) => {
    clearTimer();
    timerRef.current = setTimeout(callback, time);
  }, [clearTimer]);

  const handleAppStateChange = useCallback(async (nextAppState) => {
    if (isLoggedIn) {
      if (appState.current === 'active' && nextAppState !== 'active') {
        clearTimer();
        if (isInactivityModalOpen) dispatchCloseModal();
        setIsActive(false);
        setLastActiveTimestamp(Date.now());
      } else if (appState.current !== 'active' && nextAppState === 'active') {
        const lastActiveTime = Platform.OS === 'web' ?
          window.localStorage.getItem('lastActiveTimestamp') : lastActiveTimestampRef.current;
        const maxInactiveTime = MAX_INACTIVE_TIME + MAX_LOGOUT_TIME;
        const sessionExpired = (Date.now() - lastActiveTime) >= maxInactiveTime;
        if (sessionExpired) {
          // if (isSso && ssoLogoutUrl && Platform.OS !== 'web') {
          //   await new OidcSingleLogout(ssoLogoutUrl).initiate();
          // }
          dispatchLogout({ isInactive: true, wasInBackground: true });
        } else {
          setIsActive(true);
          setLastActiveTimestamp(Date.now());
        }
      }
    }
    appState.current = nextAppState;
  }, [
    clearTimer,
    dispatchCloseModal,
    dispatchLogout,
    isInactivityModalOpen,
    isLoggedIn,
    // isSso,
    setLastActiveTimestamp,
    // ssoLogoutUrl,
  ]);

  const onLogout = useCallback(async () => {
    clearTimer();
    dispatchCloseModal();
    // if (isSso && ssoLogoutUrl && Platform.OS !== 'web') {
    //   await new OidcSingleLogout(ssoLogoutUrl).initiate();
    // }
    dispatchLogout({ isInactive: true });
  }, [
    clearTimer,
    dispatchCloseModal,
    dispatchLogout,
    // isSso,
    // ssoLogoutUrl,
  ]);

  const onIdle = useCallback(() => {
    setIsActive(false);
    // temporary fix, currently on iOS native only one modal can be open at a time
    if (Platform.OS === 'ios' && videoModalData) {
      dispatchSetVideoModalData(null);
    }
    dispatchOpenModal();
    createTimer(onLogout, MAX_LOGOUT_TIME);
  }, [
    createTimer,
    dispatchOpenModal,
    dispatchSetVideoModalData,
    onLogout,
    setIsActive,
    videoModalData,
  ]);

  const handleActivity = useCallback(() => {
    setIsActive(true);
    setLastActiveTimestamp(Date.now());

    return false;
  }, [setIsActive, setLastActiveTimestamp]);

  // Leveraging useState to initialize PanResponder only once, not on each re-render
  const [panResponder] = useState(
    PanResponder.create({
      onMoveShouldSetPanResponderCapture: throttle(handleActivity, 1000),
      onPanResponderTerminationRequest: throttle(handleActivity, 1000),
      onStartShouldSetPanResponderCapture: throttle(handleActivity, 1000),
    }),
  );

  useEffect(() => {
    const appStateSubscription = AppState.addEventListener('change', handleAppStateChange);

    return () => {
      appStateSubscription.remove();
    };
  }, [handleAppStateChange]);

  useEffect(() => () => clearTimer(), [clearTimer]);

  useEffect(() => {
    if (appState.current !== 'active' || !isLoggedIn) {
      clearTimer();
    } else if (
      (isActive && !isInactivityModalOpen)
      /*
        Handles edge case: user is logged out from inactivity after re-foregrounding app (in which
        case `isActive` will remain as `false`), and then user uses the auto-opened biometric prompt
        to login.
      */
      || (previousIsLoggedIn !== isLoggedIn && previousIsLoggedIn !== undefined)
    ) {
      clearTimer();
      createTimer(onIdle, MAX_INACTIVE_TIME);
    }
  }, [
    clearTimer,
    createTimer,
    isActive,
    isInactivityModalOpen,
    isLoggedIn,
    lastActiveTimestamp,
    onIdle,
    previousIsLoggedIn,
  ]);

  useEffect(() => {
    if (isLoggedIn) {
      createTimer(onIdle, videoRemainingDuration + MAX_INACTIVE_TIME);
    }
  }, [
    clearTimer,
    createTimer,
    isLoggedIn,
    onIdle,
    videoRemainingDuration,
  ]);

  const handleCloseModal = () => {
    setIsActive(true);
    dispatchCloseModal();
  };

  return (
    <View style={{ flex: 1 }} {...panResponder.panHandlers} {...restProps}>
      <ConditionalRender
        Component={MainModal}
        propsToPassDown={{
          handleCloseModal,
          iconName: 'hourglass',
          primaryButtonProps: {
            label: 'Continue Session',
            onPress: () => handleCloseModal(),
          },
          text: INACTIVITY_MODAL_TEXT,
          title: INACTIVITY_MODAL_TITLE,
          visible: isInactivityModalOpen,
        }}
        shouldRender={isInactivityModalOpen}
      />
      {children}
    </View>
  );
};

InactivityTimer.propTypes = {
  children: T.node.isRequired,
  dispatchCloseModal: T.func.isRequired,
  dispatchLogout: T.func.isRequired,
  dispatchOpenModal: T.func.isRequired,
  dispatchSetVideoModalData: T.func.isRequired,
  isInactivityModalOpen: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  isSso: T.bool.isRequired,
  ssoLogoutUrl: T.string,
  videoModalData: T.object,
  videoRemainingDuration: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Help
   */
  videoModalData: selectHelp('videoModalData'),
  videoRemainingDuration: selectHelp('videoRemainingDuration'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  isInactivityModalOpen: makeSelectMain('isInactivityModalOpen'),
  isLoggedIn: makeSelectMain('isLoggedIn'),
  isSso: getIsSso(),
  ssoLogoutUrl: makeSelectMain('ssoLogoutUrl'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Help
   */
  dispatchSetVideoModalData: (payload) => dispatch(setVideoModalData(payload)),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  dispatchCloseModal: () => dispatch(closeModal({ modalState: 'isInactivityModalOpen' })),
  dispatchLogout: (payload) => dispatch(logoutRequest(payload)),
  dispatchOpenModal: () => dispatch(openModal({ modalState: 'isInactivityModalOpen' })),
});

export default connect(mapStateToProps, mapDispatchToProps)(InactivityTimer);
