import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H3, P3 } from '../../../base_ui';

export const ButtonContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: ${moderateScale(28)}px;
`;

export const CheckboxContainer = styled.View`
  align-items: center;
  flex-direction: row;
  padding-bottom: ${moderateScale(28)}px;
  padding-top: ${moderateScale(16)}px;
`;

export const Container = styled.View`
  padding-horizontal: ${moderateScale(24)}px;
  padding-top: ${moderateScale(10)}px;
`;

export const StyledH3 = styled(H3)`
  padding-bottom: ${moderateScale(8)}px;
`;

export const StyledP3 = styled(P3)`
  margin-left: ${moderateScale(10)}px;
`;
