import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeAlertForm,
  fetchAlertSettings,
  putAlertSettings,
  resetFormState,
  resetStatusState,
} from '@dmi/shared/redux/Settings/Communications/actions';
import makeSelectCommunications, {
  selectError,
  selectFetchErrorCopy,
  selectHasMobileNumberOnFile,
  selectNotificationsFormProps,
  selectRequestStatus,
} from '@dmi/shared/redux/Settings/Communications/selectors';

import { StyledScrollView } from '../../../../components/base_ui';
import VIEW_DICTIONARY from './viewDictionary';

const Notifications = ({
  dispatchChangeAlertForm,
  dispatchFetchAlertSettings,
  dispatchPutAlertSettings,
  dispatchResetFormState,
  dispatchResetStatusState,
  error,
  fetchAlertsStatus,
  fetchErrorCopy,
  formProps,
  hasMobileNumberOnFile,
  navigation,
  putAlertsStatus,
  step,
}) => {
  useEffect(() => {
    dispatchFetchAlertSettings();
  }, [dispatchFetchAlertSettings]);

  useEffect(() => () => {
    dispatchResetFormState();
  }, [dispatchResetFormState]);

  const ViewToRender = VIEW_DICTIONARY[step];

  const propsToPassDown = {
    dispatchChangeAlertForm,
    dispatchPutAlertSettings,
    dispatchResetFormState,
    dispatchResetStatusState,
    error,
    fetchAlertsLoading: ['idle', 'loading'].includes(fetchAlertsStatus),
    fetchErrorCopy,
    flowId: 'alerts',
    formProps,
    hasMobileNumberOnFile,
    navigation,
    putAlertsLoading: putAlertsStatus === 'loading',
  };

  return (
    <StyledScrollView>
      <ViewToRender {...propsToPassDown} />
    </StyledScrollView>
  );
};

Notifications.propTypes = {
  dispatchChangeAlertForm: T.func.isRequired,
  dispatchFetchAlertSettings: T.func.isRequired,
  dispatchPutAlertSettings: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  dispatchResetStatusState: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  fetchAlertsStatus: T.string.isRequired,
  fetchErrorCopy: T.shape({ body: T.string.isRequired, title: T.string.isRequired }).isRequired,
  formProps: T.shape({
    alertSettings: T.arrayOf(T.shape({
      alertDescription: T.string.isRequired,
      alertId: T.number.isRequired,
      isEmailAllowed: T.bool.isRequired,
      isEmailEnabled: T.bool.isRequired,
      isSmsAllowed: T.bool.isRequired,
      isSmsEnabled: T.bool.isRequired,
    })).isRequired,
    isButtonDisabled: T.bool.isRequired,
    isLoanEmailAllowed: T.bool.isRequired,
    isLoanSmsAllowed: T.bool.isRequired,
  }).isRequired,
  hasMobileNumberOnFile: T.bool.isRequired,
  navigation: T.object.isRequired,
  putAlertsStatus: T.string.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Communications
   */
  error: selectError('alerts'),
  fetchAlertsStatus: selectRequestStatus('fetchAlertSettings'),
  fetchErrorCopy: selectFetchErrorCopy(),
  formProps: selectNotificationsFormProps(),
  hasMobileNumberOnFile: selectHasMobileNumberOnFile(),
  putAlertsStatus: selectRequestStatus('putAlertSettings'),
  step: makeSelectCommunications('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Communications
   */
  dispatchChangeAlertForm: (payload) => dispatch(changeAlertForm(payload)),
  dispatchFetchAlertSettings: () => dispatch(fetchAlertSettings()),
  dispatchPutAlertSettings: () => dispatch(putAlertSettings()),
  dispatchResetFormState: () => dispatch(resetFormState()),
  dispatchResetStatusState: () => dispatch(resetStatusState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Notifications);
