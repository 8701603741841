import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { PrimaryDivider } from '../../../base_ui';

export const FilterWrapper = styled.View`
  height: ${moderateScale(27)}px;
  margin-top: ${moderateScale(8)}px;
  width: 100%;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
  width: 100%;
`;
