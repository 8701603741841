import React, { useState } from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import iconDictionary from '../../../../../utils/iconDictionary';
import { ConditionalRender, H5 } from '../../../../base_ui';
import ErrorValue from '../ErrorValue';
import RowButtonValue from '../RowButtonValue';
import { ArrowWrapper, Row, ValueIconWrapper } from '../styledComponents';
import GhostDropDown from './GhostDropDown';
import { ErrorText, LabelIconContainer, TooltipButton } from './styledComponents';

const ArrowIcon = iconDictionary('arrowRightM');
const InfoIcon = iconDictionary('infoTooltipSIcon');

const DropDown = ({
  accessibilityName,
  error,
  handleOpenTooltip,
  label,
  menuItems,
  onChange,
  selected,
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const { label: selectedLabel } =
    menuItems.find(({ value }) => value === selected) || { label: '', value: '' };

  return (
    <View>
      <GhostDropDown
        accessibilityName={accessibilityName}
        menuItems={menuItems}
        onChange={onChange}
        selected={selected}
      />
      <Row>
        <LabelIconContainer>
          <View>
            <H5>{label}</H5>
            <ConditionalRender
              Component={<ErrorText>{error}</ErrorText>}
              propsToPassDown={{ error }}
              shouldRender={!!error}
            />
          </View>
          <ConditionalRender
            Component={(
              <TooltipButton
                $pressed={isPressed}
                onPress={handleOpenTooltip}
                onPressIn={() => setIsPressed(true)}
                onPressOut={() => setIsPressed(false)}
              >
                {InfoIcon}
              </TooltipButton>
            )}
            shouldRender={!!handleOpenTooltip}
          />
        </LabelIconContainer>
        <ValueIconWrapper>
          <ConditionalRender
            Component={RowButtonValue}
            FallbackComponent={ErrorValue}
            propsToPassDown={{ value: selectedLabel }}
            shouldRender={!error}
          />
          <ArrowWrapper>{ArrowIcon}</ArrowWrapper>
        </ValueIconWrapper>
      </Row>
    </View>
  );
};

DropDown.propTypes = {
  accessibilityName: T.string.isRequired,
  error: T.string,
  handleOpenTooltip: T.func,
  label: T.string.isRequired,
  menuItems: T.arrayOf(T.shape({
    label: T.string,
    value: T.oneOfType([T.string, T.number]),
  })).isRequired,
  onChange: T.func.isRequired,
  selected: T.oneOfType([T.string, T.number]).isRequired,
};

export default DropDown;
