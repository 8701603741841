/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function SewerAndWaterIcon(props) {
  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M14,9.2h-2.4c-0.3,0-0.6-0.3-0.6-0.6V7.1H3c-0.3,0-0.6-0.3-0.6-0.6V4.1c0-0.3,0.3-0.6,0.6-0.6h8.2c1.8,0,3.3,1.5,3.3,3.3v1.8C14.6,8.9,14.3,9.2,14,9.2z M12.1,8h1.3V6.8c0-1.1-0.9-2.1-2.1-2.1H3.6v1.3h7.7c0.5,0,0.8,0.4,0.8,0.8V8z" />
      <Path d="M10.4,2.3H6.8c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h3.6c0.3,0,0.6,0.3,0.6,0.6S10.8,2.3,10.4,2.3z" />
      <Path d="M8.6,4.4c-0.3,0-0.6-0.3-0.6-0.6v-2c0-0.3,0.3-0.6,0.6-0.6s0.6,0.3,0.6,0.6v2C9.2,4.1,9,4.4,8.6,4.4z" />
      <Path d="M12.8,16.9c-1.5,0-2.8-1.2-2.8-2.8c0-0.6,0.2-1.1,0.5-1.6c0,0,0-0.1,0-0.1l1.8-2.4c0.2-0.3,0.7-0.3,1,0l1.7,2.4c0,0,0,0.1,0.1,0.1c0.3,0.5,0.5,1,0.5,1.6C15.5,15.6,14.3,16.9,12.8,16.9z M11.6,13.1C11.5,13.1,11.5,13.1,11.6,13.1c-0.2,0.3-0.4,0.7-0.4,1c0,0.9,0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6c0-0.3-0.1-0.7-0.3-0.9c0,0,0-0.1,0-0.1l-1.2-1.7L11.6,13.1z" />
    </Svg>
  );
}
