import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { MOBILE_DOC_UPLOAD_DICT } from '@dmi/shared/redux/SecureMessaging/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';
import { AlertBanner } from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import PrimaryDocPickerButton from '../../../DocUpload/PrimaryDocPickerButton';
import {
  AlertWrapper,
  ContentContainer,
  DocUploadButtonWrapper,
  DocUploadContainer,
  DocUploadIllustrationWrapper,
  FlexView,
  StyledH2,
  StyledP2,
} from './styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(150)}px` });

const SecureMessageDocUpload = ({
  dispatchChooseDocument,
  handleBack,
  requestStatusProcessDoc,
  reviewScreenName,
}) => {
  const { description, headerLabel, secondaryHeader } = MOBILE_DOC_UPLOAD_DICT;

  return (
    <DocUploadContainer>
      <SecondaryHeader
        handleBack={handleBack}
        title={headerLabel}
      />
      <ContentContainer>
        <View>
          <DocUploadIllustrationWrapper>
            {UploadHouse}
          </DocUploadIllustrationWrapper>
          <StyledH2>
            {secondaryHeader}
          </StyledH2>
          <StyledP2>
            {description}
          </StyledP2>
          <AlertWrapper>
            <AlertBanner
              boldText="Formats accepted: "
              text="JPEG, JPG, PDF, PNG, TIF, TIFF, or GIF (Max size 10 MB)."
            />
          </AlertWrapper>
          <FlexView />
        </View>
        <DocUploadButtonWrapper>
          <PrimaryDocPickerButton
            chooseDocumentRequestStatus={requestStatusProcessDoc}
            dispatchChooseDocument={dispatchChooseDocument}
            label="Get Started"
            reviewScreenName={reviewScreenName}
          />
        </DocUploadButtonWrapper>
      </ContentContainer>
    </DocUploadContainer>
  );
};

SecureMessageDocUpload.propTypes = {
  dispatchChooseDocument: T.func.isRequired,
  handleBack: T.func.isRequired,
  requestStatusProcessDoc: T.string.isRequired,
  reviewScreenName: T.string.isRequired,
};

export default SecureMessageDocUpload;
