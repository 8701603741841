import styled from 'styled-components/native';
import { Platform } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';
import {
  BaseTextField,
  BorderlessButton,
  BottomDrawer,
  H1,
  H3,
} from '../../base_ui';

export const ButtonWrapper = styled.View`
   align-items: center;
   display: flex;
   flex-direction: column;
   flex: 1;
   justify-content: ${Platform.OS === 'web' ? 'flex-start' : 'flex-end'};
   margin-top: ${moderateScale(48)}px;
`;

export const CopyButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { primary },
    fontFamilies: { secondaryFontBold },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: primary,
    fontFamily: secondaryFontBold,
    fontSize: f4,
  }],
}))`
  border-radius: 4px;
  border: 1px solid ${({ theme: { colors: { primary } } }) => primary};
  height:  ${moderateScale(32)}px;
  margin-left: ${moderateScale(231)}px;
  position: absolute;
  width: ${moderateScale(77)}px;
`;

export const CredentialsContainer = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(26)}px;
  margin-top: ${moderateScale(14)}px;
  position: relative;
`;

export const FlexContainer = styled.View`
  align-items: center;
  flex-direction: column;
  flex: 1;
  margin-top: ${moderateScale(8)}px;
  padding-bottom: ${moderateScale(35)}px;
`;

export const FormImageWrapper = styled.View`
  height: ${moderateScale(140)}px;
  margin-top: ${moderateScale(14)}px;
  max-width: ${moderateScale(327)}px;
`;

export const IconWrapper = styled.View`
  margin-right: ${moderateScale(12)}px;
`;

export const ListItem = styled.View`
  align-self: flex-start;
  flex-direction: row;
  margin-top: ${moderateScale(16)}px;
  text-align: left;
`;

export const MainHeader = styled(H1)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f8, f9 } } }) => Platform.OS === 'web' ? f9 : f8}px;
  margin-bottom: ${moderateScale(30)}px;
  text-align: center;
`;

export const NativeDrawerHeader = styled.View`
  padding-top: ${moderateScale(24)}px;
`;

export const Section = styled.View`
  flex-direction: column;
  max-width: ${moderateScale(327)}px;
  width: 100%;
`;

export const StyledBaseTextField = styled(BaseTextField).attrs(
  ({ theme: { fontSizes: { f4 } } }) => ({
    inputContainerStyle: { borderWidth: 0 },
    inputFontSize: f4,
    numberOfLines: 1,
  }),
)`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  ${Platform.OS === 'web' ? 'text-overflow: ellipsis' : ''}
  width: ${moderateScale(242)}px;
`;

export const StyledBottomDrawer = styled(BottomDrawer)`
  flex: 1;
  margin-top: ${moderateScale(40)}px;
`;

export const StyledImage = styled.Image`
  height: ${moderateScale(141)}px;
  width: ${moderateScale(269)}px;
`;

export const StyledFormImage = styled.Image`
  height: 100%;
  width: 100%;
`;

export const SubSection = styled.View`
  flex-direction: row;
`;

export const SubSectionContent = styled.View`
  flex-direction: column;
`;

export const SubSectionHeader = styled(H3)`
  margin: ${moderateScale(3)}px 0 0;
`;
