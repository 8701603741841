import {
  InlineElement,
  ListContainer,
  StyledExternalLink,
  StyledH1,
  StyledH3,
  StyledH4,
  StyledInternalLink,
  StyledParagraph,
} from './styledComponents';

export const HTML_ELEMENT_DICTIONARY = {
  externalLink: StyledExternalLink,
  'header-four': StyledH4,
  'header-one': StyledH1,
  'header-three': StyledH3,
  'header-two': StyledH4,
  internalLink: StyledInternalLink,
  'ordered-list': ListContainer,
  paragraph: StyledParagraph,
  span: InlineElement,
  'unordered-list': ListContainer,
};
