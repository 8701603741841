import React, { useState } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import { IconView, PressableRow, RowText } from './styledComponents';

const DownloadIcon = iconDictionary('downloadL');

const Item = ({
  date,
  handleRowPress,
  id,
  type,
  url,
}) => {
  const [isPressing, setIsPressing] = useState(false);

  return (
    <PressableRow
      $isPressing={isPressing}
      aria-label={`view statement details, ${type}, ${date}`}
      onPress={() => handleRowPress(id, url)}
      onPressIn={() => setIsPressing(true)}
      onPressOut={() => setIsPressing(false)}
      role="button"
    >
      <RowText $widthPercent={30}>{date}</RowText>
      <RowText $widthPercent={65}>{type}</RowText>
      <IconView>{DownloadIcon}</IconView>
    </PressableRow>
  );
};

Item.propTypes = {
  date: T.string.isRequired,
  handleRowPress: T.func.isRequired,
  id: T.string.isRequired,
  type: T.string.isRequired,
  url: T.string.isRequired,
};

export default Item;
