/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function OutlinedHouseSpring(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 96 40"
      height={moderateScale(40)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 96 40"
      width={moderateScale(96)}
      x="0px"
      xmlns="http://www.w3.org/2000/Svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Polyline fill="#E7EBEE" points="30.155,38.35 30.155,20.011 48.46,20.011 48.46,38.35" />
        <Rect fill="#BAC2CA" height="7.529" width="3.71" x="33.344" y="26.624" />
        <Rect fill={svgHighlight} height="11.91" width="4.549" x="40.428" y="26.456" />
        <Polygon fill="#BAC2CA" points="45.813,23.414 27.123,23.414 36.385,13.391 55.075,13.391" />
        <Path
          d="M45.813,23.687h-18.69c-0.109,0-0.207-0.064-0.251-0.164c-0.043-0.1-0.024-0.216,0.05-0.296l9.262-10.022
      c0.052-0.056,0.125-0.088,0.201-0.088h6.091c0.151,0,0.274,0.123,0.274,0.274s-0.123,0.274-0.274,0.274h-5.971l-8.756,9.476h17.945
      l8.756-9.476H44.49c-0.151,0-0.274-0.123-0.274-0.274s0.123-0.274,0.274-0.274h10.585c0.109,0,0.207,0.064,0.251,0.164
      c0.043,0.1,0.024,0.216-0.05,0.295L46.014,23.6C45.962,23.655,45.889,23.687,45.813,23.687z"
          fill="#010101"
        />
        <Path
          d="M63.862,23.687h-1.77c-0.151,0-0.274-0.123-0.274-0.274s0.123-0.274,0.274-0.274h1.166l-8.38-9.569
      c-0.1-0.114-0.088-0.286,0.026-0.386c0.114-0.099,0.286-0.088,0.386,0.026l8.777,10.023c0.071,0.081,0.088,0.195,0.043,0.294
      C64.066,23.625,63.969,23.687,63.862,23.687z"
          fill="#010101"
        />
        <Path
          d="M61.249,38.797H30.085c-0.151,0-0.274-0.123-0.274-0.274V23.75c0-0.151,0.123-0.274,0.274-0.274
      s0.274,0.123,0.274,0.274v14.499h30.616V20.507c0-0.151,0.123-0.274,0.274-0.274s0.274,0.123,0.274,0.274v18.016
      C61.522,38.674,61.4,38.797,61.249,38.797z"
          fill="#010101"
        />
        <Path
          d="M48.557,38.495c-0.101,0-0.182-0.082-0.182-0.182v-4.731c0-0.1,0.082-0.182,0.182-0.182
      c0.101,0,0.182,0.082,0.182,0.182v4.731C48.739,38.413,48.658,38.495,48.557,38.495z"
          fill="#010101"
        />
        <Path
          d="M48.557,31.885c-0.101,0-0.182-0.082-0.182-0.182V22.42c0-0.1,0.082-0.182,0.182-0.182
      c0.101,0,0.182,0.082,0.182,0.182v9.283C48.739,31.803,48.658,31.885,48.557,31.885z"
          fill="#010101"
        />
        <Path
          d="M44.994,38.596c-0.131,0-0.237-0.106-0.237-0.237V26.763h-4.098v10.384c0,0.131-0.106,0.237-0.237,0.237
      s-0.237-0.106-0.237-0.237V26.526c0-0.131,0.106-0.237,0.237-0.237h4.573c0.131,0,0.237,0.106,0.237,0.237v11.833
      C45.231,38.49,45.125,38.596,44.994,38.596z"
          fill="#010101"
        />
        <Path
          d="M37.27,34.443h-4.046c-0.131,0-0.237-0.106-0.237-0.237v-7.633c0-0.131,0.106-0.237,0.237-0.237h4.046
      c0.131,0,0.237,0.106,0.237,0.237v7.633C37.507,34.337,37.401,34.443,37.27,34.443z M33.461,33.968h3.571V26.81h-3.571V33.968z"
          fill="#010101"
        />
        <G>
          <Ellipse cx="55.309" cy="21.096" fill="#E7EBEE" rx="1.153" ry="1.665" />
          <Path
            d="M55.309,22.999c-0.779,0-1.391-0.836-1.391-1.903c0-1.067,0.611-1.902,1.391-1.902s1.39,0.836,1.39,1.902
        C56.699,22.146,56.076,22.999,55.309,22.999z M55.309,19.669c-0.497,0-0.916,0.654-0.916,1.428c0,0.774,0.42,1.428,0.916,1.428
        s0.915-0.654,0.915-1.428C56.225,20.323,55.806,19.669,55.309,19.669z"
            fill="#010101"
          />
        </G>
        <Path
          d="M55.309,22.944c-0.737,0-1.336-0.829-1.336-1.848s0.599-1.847,1.336-1.847c0.736,0,1.335,0.829,1.335,1.847
      S56.045,22.944,55.309,22.944z M55.309,19.614c-0.536,0-0.971,0.665-0.971,1.483c0,0.804,0.445,1.483,0.971,1.483
      c0.526,0,0.97-0.679,0.97-1.483C56.279,20.293,55.835,19.614,55.309,19.614z"
          fill="#010101"
        />
        <Path
          d="M81.113,38.819H77.9c-0.151,0-0.274-0.123-0.274-0.274s0.123-0.274,0.274-0.274h3.212
      c0.151,0,0.274,0.123,0.274,0.274S81.264,38.819,81.113,38.819z"
          fill="#010101"
        />
        <Path
          d="M76.567,38.819H14.275c-0.151,0-0.274-0.123-0.274-0.274s0.123-0.274,0.274-0.274h62.292
      c0.151,0,0.274,0.123,0.274,0.274S76.718,38.819,76.567,38.819z"
          fill="#010101"
        />
        <G>
          <Path d="M65.796,39.788c0.705-1.43,0.739-2.318,0.883-4.397c0,0-1.203,0.858-2.332,2.895" fill="#E7EBEE" />
          <Path
            d="M65.796,39.971c-0.027,0-0.055-0.006-0.081-0.019c-0.09-0.045-0.128-0.154-0.083-0.244
        c0.64-1.297,0.715-2.142,0.836-3.901c-0.425,0.389-1.212,1.216-1.961,2.568c-0.049,0.088-0.16,0.12-0.248,0.071
        c-0.088-0.049-0.12-0.16-0.071-0.248c1.136-2.05,2.335-2.92,2.385-2.955c0.059-0.041,0.135-0.046,0.195-0.011
        c0.061,0.034,0.098,0.101,0.093,0.172l-0.017,0.256c-0.133,1.926-0.193,2.806-0.884,4.208
        C65.928,39.934,65.863,39.971,65.796,39.971z"
            fill="#010101"
          />
        </G>
        <G>
          <Path d="M62.617,39.788c-0.024-1.748,0.266-3.211,0.98-5.574c0,0,1.284,2.572,1.223,5.7" fill="#E7EBEE" />
          <Path
            d="M64.82,40.096c-0.001,0-0.002,0-0.004,0c-0.1-0.002-0.181-0.085-0.179-0.186
        c0.044-2.294-0.655-4.306-1.007-5.168c-0.639,2.187-0.852,3.504-0.831,5.044c0.001,0.1-0.079,0.183-0.18,0.185
        c-0.001,0-0.001,0-0.002,0c-0.1,0-0.181-0.08-0.182-0.18c-0.023-1.69,0.227-3.11,0.988-5.63c0.021-0.071,0.085-0.123,0.159-0.129
        c0.085-0.011,0.145,0.033,0.179,0.1c0.053,0.106,1.303,2.649,1.242,5.785C65,40.017,64.919,40.096,64.82,40.096z"
            fill="#010101"
          />
        </G>
        <G>
          <Path d="M63.017,39.988c-0.389-1.704-1.127-3.355-2.429-5.258c0,0-0.501,2.003,0.296,5.028" fill="#E7EBEE" />
          <Path
            d="M63.017,40.171c-0.083,0-0.158-0.057-0.178-0.142c-0.366-1.606-1.052-3.147-2.148-4.817
        c-0.096,0.74-0.192,2.367,0.37,4.5c0.026,0.098-0.033,0.197-0.13,0.223c-0.1,0.024-0.197-0.033-0.223-0.13
        c-0.799-3.035-0.318-5.035-0.296-5.118c0.017-0.07,0.074-0.123,0.145-0.135c0.07-0.016,0.142,0.017,0.182,0.076
        c1.271,1.857,2.052,3.548,2.456,5.32c0.022,0.098-0.039,0.196-0.138,0.219C63.044,40.169,63.03,40.171,63.017,40.171z"
            fill="#010101"
          />
        </G>
        <G>
          <Path d="M61.675,39.926c-1.003-1.24-2.213-2.316-4.041-3.317c0,0,0.275,1.452,1.652,3.33" fill="#E7EBEE" />
          <Path
            d="M59.287,40.121c-0.056,0-0.111-0.026-0.147-0.075c-1.386-1.889-1.674-3.343-1.686-3.403
        c-0.013-0.07,0.015-0.14,0.072-0.182c0.057-0.042,0.132-0.047,0.195-0.013c1.706,0.935,2.969,1.972,4.095,3.363
        c0.063,0.078,0.051,0.193-0.027,0.256c-0.077,0.063-0.192,0.053-0.256-0.027c-1.01-1.247-2.134-2.203-3.607-3.058
        c0.177,0.551,0.598,1.608,1.508,2.848c0.06,0.081,0.042,0.195-0.039,0.254C59.362,40.11,59.324,40.121,59.287,40.121z"
            fill="#010101"
          />
        </G>
        <G>
          <Path d="M64.795,39.926c1.003-1.24,2.213-2.316,4.041-3.317c0,0-0.275,1.452-1.652,3.33" fill="#E7EBEE" />
          <Path
            d="M67.183,40.121c-0.038,0-0.076-0.011-0.108-0.036c-0.081-0.059-0.099-0.173-0.039-0.254
        c0.909-1.24,1.33-2.298,1.508-2.848c-1.473,0.856-2.598,1.81-3.607,3.058c-0.064,0.08-0.18,0.09-0.256,0.027
        c-0.078-0.063-0.09-0.178-0.027-0.256c1.126-1.391,2.388-2.428,4.094-3.363c0.062-0.033,0.139-0.029,0.194,0.013
        c0.058,0.041,0.085,0.112,0.073,0.182c-0.012,0.061-0.299,1.514-1.685,3.403C67.294,40.095,67.239,40.121,67.183,40.121z"
            fill="#010101"
          />
        </G>
        <G>
          <Path
            d="M30.341,9.377c-1.675,0-3.037-1.363-3.037-3.037s1.362-3.037,3.037-3.037s3.037,1.362,3.037,3.037
        S32.015,9.377,30.341,9.377z M30.341,3.668c-1.473,0-2.672,1.199-2.672,2.672c0,1.474,1.199,2.672,2.672,2.672
        c1.474,0,2.672-1.199,2.672-2.672C33.013,4.866,31.814,3.668,30.341,3.668z"
            fill="#010101"
          />
          <Path
            d="M30.227,2.557c-0.101,0-0.182-0.082-0.182-0.182V1.182C30.045,1.082,30.126,1,30.227,1
        s0.182,0.082,0.182,0.182v1.192C30.41,2.475,30.328,2.557,30.227,2.557z"
            fill="#010101"
          />
          <Path
            d="M30.227,11.607c-0.101,0-0.182-0.082-0.182-0.182v-1.192c0-0.101,0.082-0.182,0.182-0.182
        s0.182,0.082,0.182,0.182v1.192C30.41,11.525,30.328,11.607,30.227,11.607z"
            fill="#010101"
          />
          <Path
            d="M33.005,3.708c-0.047,0-0.093-0.018-0.129-0.053c-0.071-0.071-0.071-0.187,0-0.258l0.843-0.843
        c0.071-0.071,0.187-0.071,0.258,0s0.071,0.187,0,0.258l-0.843,0.843C33.099,3.69,33.052,3.708,33.005,3.708z"
            fill="#010101"
          />
          <Path
            d="M26.606,10.107c-0.047,0-0.093-0.018-0.129-0.053c-0.071-0.071-0.071-0.187,0-0.258l0.843-0.843
        c0.071-0.071,0.187-0.071,0.258,0c0.071,0.071,0.071,0.187,0,0.258l-0.843,0.843C26.699,10.089,26.653,10.107,26.606,10.107z"
            fill="#010101"
          />
          <Path
            d="M35.348,6.486h-1.192c-0.101,0-0.182-0.082-0.182-0.182s0.082-0.182,0.182-0.182h1.192
        c0.101,0,0.182,0.082,0.182,0.182S35.449,6.486,35.348,6.486z"
            fill="#010101"
          />
          <Path
            d="M26.298,6.486h-1.192c-0.101,0-0.182-0.082-0.182-0.182s0.082-0.182,0.182-0.182h1.192
        c0.101,0,0.182,0.082,0.182,0.182S26.399,6.486,26.298,6.486z"
            fill="#010101"
          />
          <Path
            d="M33.848,10.107c-0.047,0-0.093-0.018-0.129-0.053L32.876,9.21c-0.071-0.071-0.071-0.187,0-0.258
        s0.187-0.071,0.258,0l0.843,0.843c0.071,0.071,0.071,0.187,0,0.258C33.942,10.089,33.895,10.107,33.848,10.107z"
            fill="#010101"
          />
          <Path
            d="M27.449,3.708c-0.047,0-0.093-0.018-0.129-0.053l-0.843-0.843c-0.071-0.071-0.071-0.187,0-0.258
        s0.187-0.071,0.258,0l0.843,0.843c0.071,0.071,0.071,0.187,0,0.258C27.542,3.69,27.495,3.708,27.449,3.708z"
            fill="#010101"
          />
        </G>
        <G>
          <Path
            d="M13.27,23.587c0.273-0.231,0.36-0.624,0.174-0.929c-0.371-0.607-0.584-1.302-0.584-2.043
        c0-1.412,0.767-2.663,1.947-3.445c0.15-0.099,0.228-0.279,0.176-0.451c-0.061-0.203-0.094-0.417-0.094-0.638
        c0-1.373,1.248-2.486,2.788-2.486c1.54,0,2.788,1.113,2.788,2.486c0,0.221-0.033,0.434-0.094,0.638
        c-0.052,0.172,0.026,0.352,0.176,0.451c1.181,0.782,1.947,2.033,1.947,3.445c0,0.741-0.213,1.436-0.584,2.043
        c-0.187,0.305-0.099,0.698,0.174,0.929c1.228,1.039,1.996,2.505,1.996,4.133c0,3.153-2.866,5.709-6.402,5.709
        s-6.402-2.556-6.402-5.709C11.274,26.092,12.042,24.626,13.27,23.587z"
            fill="#D9DFE6"
          />
          <Path
            d="M17.831,38.654c-0.151,0-0.274-0.123-0.274-0.274V21.36c0-0.151,0.123-0.274,0.274-0.274
        s0.274,0.123,0.274,0.274v17.021C18.105,38.532,17.983,38.654,17.831,38.654z"
            fill="#010101"
          />
          <Path
            d="M17.905,28.803c-0.089,0-0.176-0.043-0.229-0.123c-0.083-0.126-0.048-0.296,0.078-0.379l2.905-1.908
        c0.126-0.084,0.296-0.047,0.379,0.078c0.083,0.126,0.048,0.296-0.078,0.379l-2.905,1.908
        C18.009,28.789,17.956,28.803,17.905,28.803z"
            fill="#010101"
          />
          <Path
            d="M17.844,25.064c-0.052,0-0.104-0.015-0.151-0.046l-1.548-1.027c-0.126-0.083-0.16-0.253-0.077-0.379
        c0.083-0.127,0.253-0.161,0.379-0.077l1.548,1.027c0.126,0.083,0.16,0.253,0.077,0.379C18.019,25.021,17.932,25.064,17.844,25.064
        z"
            fill="#010101"
          />
          <Path
            d="M17.769,30.862c-0.047,0-0.095-0.012-0.139-0.038l-3.29-1.936c-0.13-0.077-0.173-0.244-0.097-0.375
        c0.076-0.13,0.243-0.175,0.375-0.097l3.29,1.936c0.13,0.077,0.173,0.244,0.097,0.375C17.954,30.814,17.863,30.862,17.769,30.862z"
            fill="#010101"
          />
          <G>
            <Path
              d="M14.7,26.067c0-0.231,0.171-0.42,0.392-0.452c0.03-0.225,0.22-0.399,0.453-0.399
          c0.231,0,0.421,0.171,0.452,0.394c0.244,0.011,0.439,0.21,0.439,0.457c0,0.251-0.202,0.455-0.453,0.458
          c-0.056,0.19-0.23,0.329-0.438,0.329c-0.21,0-0.385-0.142-0.439-0.334C14.877,26.494,14.7,26.302,14.7,26.067z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M19.875,29.638c0-0.165,0.122-0.301,0.281-0.324c0.021-0.161,0.158-0.286,0.325-0.286
          c0.166,0,0.301,0.123,0.324,0.282c0.175,0.008,0.314,0.151,0.314,0.327c0,0.18-0.145,0.326-0.325,0.329
          c-0.04,0.136-0.165,0.236-0.314,0.236c-0.15,0-0.276-0.101-0.315-0.239C20.003,29.944,19.875,29.806,19.875,29.638z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M22.148,27.878c0-0.109,0.08-0.198,0.185-0.213c0.014-0.106,0.104-0.188,0.214-0.188
          c0.109,0,0.198,0.081,0.213,0.186c0.115,0.005,0.207,0.099,0.207,0.216c0,0.119-0.095,0.215-0.214,0.216
          c-0.027,0.09-0.109,0.155-0.207,0.155c-0.099,0-0.182-0.067-0.207-0.157C22.231,28.08,22.148,27.989,22.148,27.878z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M16.616,15.35c0-0.109,0.08-0.198,0.185-0.213c0.014-0.106,0.104-0.188,0.214-0.188
          c0.109,0,0.198,0.081,0.213,0.186c0.115,0.005,0.207,0.099,0.207,0.216c0,0.119-0.095,0.215-0.214,0.216
          c-0.027,0.09-0.109,0.155-0.207,0.155c-0.099,0-0.182-0.067-0.207-0.157C16.7,15.551,16.616,15.461,16.616,15.35z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M12.349,27.961c0-0.168,0.124-0.306,0.286-0.329c0.022-0.164,0.16-0.29,0.33-0.29
          c0.168,0,0.306,0.125,0.329,0.287c0.178,0.008,0.32,0.153,0.32,0.333c0,0.183-0.147,0.331-0.33,0.334
          c-0.041,0.138-0.168,0.24-0.319,0.24c-0.153,0-0.28-0.103-0.32-0.243C12.478,28.272,12.349,28.132,12.349,27.961z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M15.627,18.285c0-0.166,0.122-0.301,0.282-0.325c0.021-0.161,0.158-0.286,0.325-0.286
          c0.166,0,0.302,0.123,0.325,0.283c0.175,0.008,0.315,0.151,0.315,0.328c0,0.181-0.145,0.327-0.325,0.329
          c-0.04,0.136-0.165,0.236-0.314,0.236c-0.151,0-0.276-0.102-0.316-0.24C15.754,18.592,15.627,18.454,15.627,18.285z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M19.019,31.82c0-0.109,0.08-0.198,0.185-0.213c0.014-0.106,0.104-0.188,0.214-0.188
          c0.109,0,0.198,0.081,0.213,0.186c0.115,0.005,0.207,0.099,0.207,0.216c0,0.119-0.095,0.215-0.214,0.216
          c-0.027,0.09-0.109,0.155-0.207,0.155c-0.099,0-0.182-0.067-0.207-0.157C19.103,32.022,19.019,31.931,19.019,31.82z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M19.874,20.878c0-0.18,0.133-0.327,0.306-0.353c0.023-0.175,0.172-0.311,0.353-0.311
          c0.18,0,0.328,0.134,0.353,0.307c0.19,0.008,0.342,0.164,0.342,0.356c0,0.196-0.158,0.355-0.353,0.357
          c-0.044,0.148-0.179,0.256-0.341,0.256c-0.164,0-0.3-0.11-0.343-0.26C20.013,21.211,19.874,21.062,19.874,20.878z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M18.245,18.477c0-0.201,0.149-0.366,0.342-0.394c0.026-0.196,0.192-0.347,0.395-0.347
          c0.202,0,0.367,0.15,0.394,0.343c0.213,0.009,0.382,0.183,0.382,0.398c0,0.219-0.176,0.397-0.395,0.4
          c-0.049,0.165-0.201,0.287-0.382,0.287c-0.183,0-0.335-0.123-0.383-0.291C18.4,18.849,18.245,18.682,18.245,18.477z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M19.113,24.117c0-0.226,0.167-0.411,0.384-0.443c0.029-0.22,0.216-0.391,0.444-0.391
          c0.227,0,0.412,0.168,0.443,0.386c0.239,0.011,0.43,0.206,0.43,0.448c0,0.246-0.198,0.446-0.444,0.449
          c-0.055,0.186-0.225,0.322-0.429,0.322c-0.206,0-0.377-0.139-0.431-0.327C19.287,24.535,19.113,24.347,19.113,24.117z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M13.424,30.666c0-0.165,0.122-0.301,0.281-0.324c0.021-0.161,0.158-0.286,0.325-0.286
          c0.166,0,0.301,0.123,0.324,0.282c0.175,0.008,0.314,0.151,0.314,0.327c0,0.18-0.145,0.326-0.325,0.329
          c-0.04,0.136-0.165,0.236-0.314,0.236c-0.15,0-0.276-0.101-0.315-0.239C13.552,30.972,13.424,30.835,13.424,30.666z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M14.585,20.953c0-0.19,0.141-0.346,0.324-0.373c0.025-0.185,0.182-0.329,0.374-0.329
          c0.191,0,0.347,0.142,0.374,0.325c0.201,0.009,0.362,0.174,0.362,0.377c0,0.208-0.167,0.376-0.374,0.378
          c-0.047,0.157-0.19,0.272-0.362,0.272c-0.173,0-0.318-0.117-0.363-0.276C14.732,21.306,14.585,21.147,14.585,20.953z"
              fill="#FFFFFF"
            />
          </G>
          <Path
            d="M17.676,33.703c-3.681,0-6.676-2.684-6.676-5.982c0-1.658,0.743-3.2,2.093-4.342
        c0.18-0.152,0.229-0.395,0.117-0.578c-0.408-0.668-0.624-1.423-0.624-2.185c0-1.441,0.774-2.815,2.07-3.673
        c0.045-0.03,0.082-0.086,0.065-0.145c-0.071-0.235-0.106-0.476-0.106-0.717c0-1.522,1.374-2.76,3.062-2.76s3.062,1.238,3.062,2.76
        c0,0.242-0.036,0.484-0.106,0.717c-0.017,0.058,0.02,0.115,0.066,0.145c1.296,0.858,2.069,2.232,2.069,3.673
        c0,0.762-0.216,1.517-0.624,2.185c-0.111,0.183-0.062,0.426,0.118,0.578c1.349,1.142,2.092,2.684,2.092,4.342
        C24.352,31.019,21.357,33.703,17.676,33.703z M17.676,13.868c-1.386,0-2.515,0.993-2.515,2.212c0,0.187,0.028,0.375,0.083,0.559
        c0.085,0.282-0.03,0.587-0.286,0.758c-1.16,0.769-1.825,1.941-1.825,3.217c0,0.661,0.188,1.318,0.544,1.9
        c0.253,0.415,0.156,0.953-0.23,1.281c0,0,0,0,0,0c-1.225,1.037-1.899,2.43-1.899,3.924c0,2.997,2.749,5.435,6.129,5.435
        s6.129-2.438,6.129-5.435c0-1.494-0.674-2.887-1.899-3.924c-0.387-0.328-0.484-0.867-0.23-1.281
        c0.356-0.582,0.544-1.239,0.544-1.9c0-1.276-0.665-2.449-1.825-3.217c-0.256-0.17-0.372-0.474-0.287-0.758
        c0.055-0.182,0.083-0.37,0.083-0.559C20.19,14.861,19.062,13.868,17.676,13.868z M13.269,23.588h0.007H13.269z"
            fill="#010101"
          />
        </G>
        <G>
          <Path
            d="M80.555,18.078c-0.37-0.313-0.488-0.845-0.235-1.259c0.503-0.823,0.791-1.764,0.791-2.767
        c0-1.913-1.038-3.606-2.637-4.666c-0.203-0.135-0.308-0.378-0.238-0.611c0.083-0.276,0.128-0.565,0.128-0.864
        c0-1.86-1.691-3.367-3.776-3.367s-3.776,1.508-3.776,3.367c0,0.299,0.045,0.588,0.128,0.864c0.07,0.233-0.035,0.477-0.238,0.611
        c-1.599,1.06-2.637,2.753-2.637,4.666c0,1.003,0.288,1.944,0.791,2.767c0.253,0.413,0.134,0.946-0.235,1.259
        c-1.663,1.407-2.703,3.392-2.703,5.598c0,4.27,3.882,7.732,8.671,7.732s8.671-3.462,8.671-7.732
        C83.259,21.471,82.218,19.486,80.555,18.078z"
            fill="#D9DFE6"
          />
          <Path
            d="M74.377,38.645c-0.151,0-0.274-0.123-0.274-0.274v-23.31c0-0.151,0.123-0.274,0.274-0.274
        c0.151,0,0.274,0.123,0.274,0.274v23.31C74.65,38.522,74.528,38.645,74.377,38.645z"
            fill="#010101"
          />
          <Path
            d="M74.278,25.046c-0.052,0-0.104-0.014-0.15-0.045l-3.935-2.584c-0.126-0.083-0.161-0.253-0.078-0.379
        c0.083-0.126,0.253-0.162,0.379-0.078l3.935,2.584c0.126,0.083,0.161,0.253,0.078,0.379
        C74.454,25.003,74.367,25.046,74.278,25.046z"
            fill="#010101"
          />
          <Path
            d="M74.36,19.981c-0.088,0-0.175-0.043-0.228-0.123c-0.083-0.126-0.049-0.296,0.077-0.379l2.097-1.389
        c0.125-0.083,0.295-0.05,0.379,0.077c0.083,0.126,0.049,0.296-0.077,0.379l-2.097,1.389C74.464,19.966,74.412,19.981,74.36,19.981
        z"
            fill="#010101"
          />
          <Path
            d="M74.461,27.835c-0.093,0-0.185-0.048-0.236-0.135c-0.077-0.13-0.033-0.298,0.097-0.375l4.456-2.623
        c0.13-0.076,0.298-0.033,0.375,0.097c0.077,0.13,0.033,0.298-0.097,0.375L74.6,27.797C74.556,27.823,74.508,27.835,74.461,27.835z
        "
            fill="#010101"
          />
          <G>
            <Path
              d="M78.619,21.437c0-0.312-0.231-0.568-0.531-0.612c-0.04-0.304-0.298-0.54-0.613-0.54
          c-0.313,0-0.57,0.232-0.613,0.533c-0.33,0.015-0.594,0.285-0.594,0.619c0,0.341,0.274,0.616,0.614,0.621
          c0.076,0.257,0.311,0.446,0.593,0.446c0.284,0,0.521-0.192,0.595-0.452C78.378,22.015,78.619,21.755,78.619,21.437z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M71.609,26.273c0-0.224-0.166-0.407-0.381-0.439c-0.029-0.218-0.214-0.387-0.44-0.387
          c-0.224,0-0.408,0.166-0.439,0.382c-0.237,0.011-0.426,0.204-0.426,0.444c0,0.244,0.196,0.442,0.44,0.445
          c0.055,0.184,0.223,0.319,0.425,0.319c0.204,0,0.373-0.137,0.427-0.324C71.436,26.687,71.609,26.501,71.609,26.273z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M68.531,23.89c0-0.147-0.109-0.268-0.251-0.289c-0.019-0.144-0.141-0.255-0.289-0.255
          c-0.148,0-0.269,0.11-0.289,0.252c-0.156,0.007-0.28,0.134-0.28,0.292c0,0.161,0.129,0.291,0.289,0.293
          c0.036,0.121,0.147,0.21,0.28,0.21c0.134,0,0.246-0.09,0.281-0.213C68.418,24.163,68.531,24.04,68.531,23.89z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M76.023,6.921c0-0.147-0.109-0.268-0.251-0.289c-0.019-0.144-0.141-0.255-0.289-0.255
          c-0.148,0-0.269,0.11-0.289,0.252c-0.156,0.007-0.28,0.134-0.28,0.292c0,0.161,0.129,0.291,0.289,0.293
          c0.036,0.121,0.147,0.21,0.28,0.21c0.134,0,0.246-0.09,0.281-0.213C75.909,7.194,76.023,7.071,76.023,6.921z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M81.802,24.002c0-0.227-0.168-0.414-0.387-0.446c-0.029-0.222-0.217-0.393-0.447-0.393
          c-0.228,0-0.415,0.169-0.446,0.388c-0.241,0.011-0.433,0.208-0.433,0.451c0,0.248,0.2,0.449,0.447,0.452
          c0.056,0.187,0.227,0.325,0.432,0.325c0.207,0,0.38-0.14,0.434-0.329C81.627,24.423,81.802,24.234,81.802,24.002z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M77.363,10.897c0-0.224-0.166-0.408-0.381-0.44c-0.029-0.218-0.214-0.388-0.44-0.388
          c-0.225,0-0.409,0.167-0.44,0.383c-0.237,0.011-0.427,0.205-0.427,0.444c0,0.245,0.197,0.443,0.441,0.446
          c0.055,0.184,0.224,0.32,0.426,0.32c0.204,0,0.374-0.138,0.427-0.325C77.19,11.312,77.363,11.126,77.363,10.897z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M72.768,29.229c0-0.147-0.109-0.268-0.251-0.289c-0.019-0.144-0.141-0.255-0.289-0.255
          c-0.148,0-0.269,0.11-0.289,0.252c-0.156,0.007-0.28,0.134-0.28,0.292c0,0.161,0.129,0.291,0.289,0.293
          c0.036,0.121,0.147,0.21,0.28,0.21c0.134,0,0.246-0.09,0.281-0.213C72.654,29.502,72.768,29.379,72.768,29.229z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M71.61,14.409c0-0.243-0.18-0.443-0.414-0.477c-0.032-0.237-0.232-0.421-0.478-0.421
          c-0.244,0-0.444,0.181-0.478,0.416c-0.257,0.011-0.463,0.222-0.463,0.482c0,0.265,0.214,0.48,0.478,0.484
          c0.059,0.2,0.243,0.347,0.462,0.347c0.221,0,0.406-0.149,0.464-0.352C71.423,14.86,71.61,14.657,71.61,14.409z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M73.817,11.157c0-0.272-0.201-0.495-0.463-0.534c-0.035-0.265-0.26-0.471-0.535-0.471
          c-0.273,0-0.497,0.202-0.534,0.465c-0.288,0.013-0.518,0.248-0.518,0.54c0,0.297,0.239,0.537,0.535,0.541
          c0.067,0.224,0.272,0.388,0.517,0.388c0.248,0,0.454-0.167,0.519-0.394C73.607,11.661,73.817,11.435,73.817,11.157z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M72.642,18.795c0-0.306-0.226-0.557-0.52-0.6c-0.04-0.298-0.292-0.529-0.601-0.529
          c-0.307,0-0.558,0.228-0.6,0.523c-0.324,0.014-0.582,0.279-0.582,0.606c0,0.334,0.269,0.604,0.601,0.608
          c0.075,0.252,0.305,0.437,0.581,0.437c0.278,0,0.511-0.188,0.583-0.443C72.406,19.362,72.642,19.107,72.642,18.795z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M80.346,27.666c0-0.224-0.166-0.407-0.381-0.439c-0.029-0.218-0.214-0.387-0.44-0.387
          c-0.224,0-0.408,0.166-0.439,0.382c-0.237,0.011-0.426,0.204-0.426,0.444c0,0.244,0.196,0.442,0.44,0.445
          c0.055,0.184,0.223,0.319,0.425,0.319c0.204,0,0.373-0.137,0.427-0.324C80.174,28.08,80.346,27.894,80.346,27.666z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M78.774,14.511c0-0.258-0.191-0.469-0.438-0.506c-0.033-0.251-0.246-0.446-0.506-0.446
          c-0.259,0-0.47,0.192-0.506,0.44c-0.273,0.012-0.491,0.235-0.491,0.511c0,0.281,0.226,0.509,0.507,0.513
          c0.063,0.212,0.257,0.368,0.49,0.368c0.235,0,0.43-0.158,0.491-0.373C78.575,14.988,78.774,14.774,78.774,14.511z"
              fill="#FFFFFF"
            />
          </G>
          <Path
            d="M74.588,31.682c-4.932,0-8.945-3.591-8.945-8.006c0-2.217,0.995-4.28,2.8-5.807
        c0.28-0.237,0.355-0.618,0.179-0.908c-0.544-0.889-0.831-1.895-0.831-2.91c0-1.919,1.032-3.748,2.76-4.894
        c0.109-0.072,0.16-0.195,0.127-0.305c-0.093-0.309-0.14-0.626-0.14-0.943c0-2.007,1.817-3.641,4.05-3.641
        c2.233,0,4.049,1.633,4.049,3.641c0,0.318-0.047,0.635-0.139,0.943c-0.033,0.11,0.018,0.232,0.127,0.305
        c1.728,1.145,2.76,2.974,2.76,4.894c0,1.015-0.288,2.02-0.831,2.91c-0.177,0.289-0.101,0.67,0.179,0.908l0,0
        c1.805,1.528,2.8,3.591,2.8,5.807C83.532,28.091,79.52,31.682,74.588,31.682z M74.588,4.818c-1.931,0-3.503,1.388-3.503,3.093
        c0,0.263,0.039,0.527,0.117,0.786c0.103,0.342-0.038,0.711-0.349,0.918c-1.598,1.059-2.515,2.677-2.515,4.438
        c0,0.913,0.26,1.821,0.752,2.625c0.317,0.521,0.194,1.198-0.293,1.61c-1.681,1.422-2.606,3.336-2.606,5.39
        c0,4.113,3.767,7.458,8.397,7.458s8.397-3.346,8.397-7.458c0-2.053-0.925-3.967-2.606-5.39c-0.487-0.412-0.611-1.089-0.293-1.61
        c0.492-0.804,0.752-1.711,0.752-2.625c0-1.761-0.917-3.379-2.515-4.438c-0.312-0.207-0.452-0.576-0.349-0.918
        c0.077-0.256,0.116-0.52,0.116-0.785C78.09,6.206,76.519,4.818,74.588,4.818z"
            fill="#010101"
          />
        </G>
      </G>
    </Svg>
  );
}
