import styled from 'styled-components/native';

import { P3 } from '../../Paragraphs';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  padding: ${moderateScale(8)}px; ${moderateScale(16)}px;
`;

export const BoldText = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const IllustrationWrapper = styled.View`
  justify-content: center;
  margin-right: ${moderateScale(15)}px;
`;

export const MessageContainer = styled.View`
  align-self: center;
  flex: 1;
`;
