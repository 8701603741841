/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../scaleHelpers';

export default function PlusIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(16)} viewBox="0 0 16 16" width={moderateScale(16)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path clipRule="evenodd" d="M8.00033 15.3334C3.95024 15.3334 0.666992 12.0502 0.666992 8.00008C0.666992 3.94999 3.95024 0.666748 8.00033 0.666748C12.0504 0.666748 15.3337 3.94999 15.3337 8.00008C15.3337 12.0502 12.0504 15.3334 8.00033 15.3334ZM8.00025 14.0001C11.314 14.0001 14.0002 11.3138 14.0002 8.00008C14.0002 4.68637 11.314 2.00008 8.00025 2.00008C4.68654 2.00008 2.00025 4.68637 2.00025 8.00008C2.00025 11.3138 4.68654 14.0001 8.00025 14.0001ZM11.3337 7.33341H8.66708V4.66675H7.33374V7.33341H4.66708V8.66675H7.33374V11.3334H8.66708V8.66675H11.3337V7.33341Z" fill={primary} fillRule="evenodd" />
    </Svg>
  );
}
