import React, { Fragment } from 'react';
import T from 'prop-types';

import { CONFIRMATION_DICTIONARY_OTP } from '@dmi/shared/redux/Payments/OneTimePayment/constants';

import Message from '../../Message';
import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import { ErrorContainer } from './styledComponents';

const errorStorm = iconDictionary('errorStorm');

const { errorLinkLabel, errorSubHeader, errorText } = CONFIRMATION_DICTIONARY_OTP;

const ConfirmationError = ({ type }) => {
  const headerTitle = type === 'otp' ? 'Payment Error' : 'Autopay Error';
  return (
    <Fragment>
      <SecondaryHeader title={headerTitle} />
      <ErrorContainer>
        <Message
          body={errorText}
          icon={errorStorm}
          link={{ label: errorLinkLabel, path: 'Payments' }}
          title={errorSubHeader}
        />
      </ErrorContainer>
    </Fragment>
  );
};

ConfirmationError.propTypes = { type: T.oneOf(['autopay', 'otp']).isRequired };

export default ConfirmationError;
