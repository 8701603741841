import React, { forwardRef } from 'react';
import { Keyboard } from 'react-native';

import {
  NestedStyledScrollView as NestedScrollView,
  StyledScrollView as ScrollView,
} from './styledComponents';

const StyledScrollView = forwardRef((props, ref) => (
  <ScrollView ref={ref} onScrollBeginDrag={Keyboard.dismiss} {...props} />
));

const NestedStyledScrollView = forwardRef((props, ref) => (
  <NestedScrollView ref={ref} onScrollBeginDrag={Keyboard.dismiss} {...props} />
));

export { NestedStyledScrollView, StyledScrollView };
