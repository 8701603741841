import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import DisabledFeature from '../../../components/Settings/Shared/DisabledFeature';
import { StyledScrollView } from '../../../components/base_ui';

const EditMailingAddressDisabledFeatureScreen = ({
  customerServicePhoneNumber,
  navigation,
}) => (
  <StyledScrollView>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Edit Mailing Address"
    />
    <DisabledFeature
      customerServicePhoneNumber={customerServicePhoneNumber}
      navigation={navigation}
    />
  </StyledScrollView>
);

EditMailingAddressDisabledFeatureScreen.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(EditMailingAddressDisabledFeatureScreen);
