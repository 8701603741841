import React from 'react';
import T from 'prop-types';
import { useDispatch } from 'react-redux';
import { Text } from 'react-native';

import { STATUS_BLOCK_ICON_DICTIONARY } from '../../../../containers/Payments/Home/constants';
import {
  ConditionalRender,
  FormattedMessage,
  H5,
  P3,
} from '../../../base_ui';
import {
  BoldText,
  IconContainer,
  StatusBlockContainer,
  StatusBlockContent,
  StyledText,
} from './styledComponents';

const StatusBlock = ({
  autopayStatus,
  button,
  header,
  iconType,
  isAutopayEnabledOrPending,
  message,
  navigation,
  ...restProps
}) => {
  const { label, operation, operationResponse } = button || {};
  const dispatch = useDispatch();

  const operationToUse = operation ?
    () => dispatch(operation(operationResponse)) :
    () => navigation.navigate(operationResponse);

  return (
    <StatusBlockContainer
      $isAutopayEnabledOrPending={isAutopayEnabledOrPending}
      {...restProps}
    >
      <IconContainer>{STATUS_BLOCK_ICON_DICTIONARY[iconType]}</IconContainer>
      <StatusBlockContent>
        <ConditionalRender
          Component={<H5>{header}</H5>}
          shouldRender={!!header}
        />
        <Text>
          <FormattedMessage
            defaultMessage={`${message} `}
            // eslint-disable-next-line react/prop-types
            values={{ b: ({ msg }) => <BoldText>{msg}</BoldText> }}
            WrapperComponent={P3}
          />
          <ConditionalRender
            Component={(
              <StyledText onPress={operationToUse}>
                {label}
              </StyledText>
            )}
            shouldRender={!!label && !!operationToUse}
          />
        </Text>
      </StatusBlockContent>
    </StatusBlockContainer>
  );
};

StatusBlock.propTypes = {
  autopayStatus: T.string,
  button: T.shape({
    label: T.string,
    operation: T.oneOfType([T.func, T.string]),
    operationResponse: T.oneOfType([T.bool, T.object, T.string]),
  }),
  header: T.string,
  iconType: T.string.isRequired,
  isAutopayEnabledOrPending: T.bool,
  message: T.string.isRequired,
  navigation: T.object,
};

export default StatusBlock;
