/*
 * Change Email Step Two Form Input
 */

import React, { Fragment, useRef } from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import {
  LargeProgressButton,
  LoginRegisterTextField,
} from '../../base_ui';
import {
  ButtonsContainer,
  RegisterInputContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const ChangeEmailStepTwoFormInput = ({
  dispatchChangeEmail,
  dispatchChangeInput,
  dispatchResetStatus,
  formErrors,
  formValues,
  handleNextClick,
  navigation,
  status,
  token,
}) => {
  const handleBack = () => {
    dispatchResetStatus();
    navigation.goBack();
  };
  const emailRef = useRef(null);
  const confirmEmailRef = useRef(null);

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={emailRef}
            error={formErrors.email}
            keyboardType="email-address"
            label="Email Address"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'email',
              form: 'changeEmailStepTwo',
              value: newValue,
            })}
            onSubmitEditing={() => confirmEmailRef.current.focus()}
            returnKeyType="next"
            value={formValues.email}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={confirmEmailRef}
            error={formErrors.confirmEmail}
            keyboardType="email-address"
            label="Confirm Email Address"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'confirmEmail',
              form: 'changeEmailStepTwo',
              value: newValue,
            })}
            onSubmitEditing={() => formValues.email
              ? handleNextClick({
                field: ['confirmEmail', 'email'],
                form: 'changeEmailStepTwo',
                formValues: trimFormValues(formValues),
                handleSubmitStep: () =>
                  dispatchChangeEmail({
                    email: formValues.email.trim(),
                    token,
                  }),
              })
              : emailRef.current.focus()}
            returnKeyType="done"
            value={formValues.confirmEmail}
          />
        </TextFieldWrapper>
      </RegisterInputContainer>
      <View>
        <StyledPrimaryDivider />
        <ButtonsContainer>
          <LargeProgressButton
            disabled={!formValues.email || !formValues.confirmEmail}
            label="Submit"
            onPress={() => handleNextClick({
              field: ['confirmEmail', 'email'],
              form: 'changeEmailStepTwo',
              formValues: trimFormValues(formValues),
              handleSubmitStep: () => dispatchChangeEmail({
                email: formValues.email.trim(),
                token,
              }),
            })}
            status={status.changeEmailStepTwo}
          />
          <StyledBaseInternalLink
            label="Back"
            onPressFunc={handleBack}
            size="medium"
          />
        </ButtonsContainer>
      </View>
    </Fragment>
  );
};

ChangeEmailStepTwoFormInput.propTypes = {
  dispatchChangeEmail: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetStatus: T.func.isRequired,
  formErrors: T.shape({
    confirmEmail: T.string.isRequired,
    email: T.string.isRequired,
  }).isRequired,
  formValues: T.shape({
    confirmEmail: T.string.isRequired,
    email: T.string.isRequired,
  }).isRequired,
  handleNextClick: T.func.isRequired,
  navigation: T.object.isRequired,
  status: T.object.isRequired,
  token: T.string.isRequired,
};

export default ChangeEmailStepTwoFormInput;
