import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const CalendarContainer = styled.View`
  flex-grow: 1;
`;

export const CircleContainer = styled.View`
  flex-direction: row;
  margin: ${moderateScale(30)}px ${moderateScale(20)}px;
`;

export const ClosedCircle = styled.View`
  background-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: 50px;
  height: ${moderateScale(16)}px;
  margin-right: ${moderateScale(5)}px;
  width: ${moderateScale(16)}px;
`;
