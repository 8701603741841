import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

import {
  Animation,
  BaseInternalLink,
  BorderlessButton,
  CheckboxWithText,
  H1,
  H2,
  H4,
  P2,
  PrimaryDivider,
} from '../../base_ui';

// Shared
export const ButtonsContainer = styled.View`
  align-items: center;
  margin: ${moderateScale(26)}px 0 ${moderateScale(14)}px;
`;

export const IllustrationWrapper = styled.View`
  align-self: center;
`;

export const PaperlessContainer = styled.View`
  flex-grow: 1;
`;

export const TextContainer = styled.View`
  flex-direction: column;
  flex-grow: 1;
`;

// Main View
export const MainBorderlessButton = styled(BorderlessButton)`
  margin-top: ${moderateScale(16)}px;
`;

export const MainHeading = styled(H1)`
  align-self: center;
  margin: ${moderateScale(12)}px 0 ${moderateScale(30)}px;
`;

export const MainIconWrapper = styled.View`
  justify-content: center;
  margin-right: ${moderateScale(16)}px;
  min-width: ${moderateScale(30)}px;
`;

export const MainItemContainer = styled.View`
  flex-direction: row;
  margin-bottom: ${moderateScale(16)}px;
  padding-horizontal: ${moderateScale(17)}px;
`;

export const MainItemContent = styled.View`
  flex: 1;
  flex-direction: column;
`;

// Conditions View

export const ConditionsBorderlessButton = styled(BorderlessButton)`
  margin-top: ${moderateScale(6)}px;
`;

export const ConditionsHeading = styled(H4)`
  align-self: center;
  margin: ${moderateScale(13)}px 0 ${moderateScale(41)}px;
`;

export const CheckboxContainer = styled.View`
  padding: ${moderateScale(24)}px 0;
`;

export const StyledCheckboxWithText = styled(CheckboxWithText).attrs(
  ({ textStyle, theme: { fontSizes: { f3 } } }) =>
    ({ textStyle: { ...textStyle, fontSize: f3 } }),
)``;

// Confirmation View

export const AnimationContainer = styled.View`
  justify-content: center;
  margin: ${moderateScale(20)}px auto ${moderateScale(16)}px;
  width: ${moderateScale(287)}px;
`;

export const ConfirmationHeading = styled(H2)`
  align-self: center;
`;

export const ConfirmationText = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(16)}px;
  text-align: center;
`;

export const DataLabel = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const DataLine = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-vertical: ${moderateScale(8)}px;
`;

export const StyledAnimation = styled(Animation)`
  margin: ${moderateScale(20)}px 0${moderateScale(16)}px;
  width: ${moderateScale(287)}px;
`;

export const StyledBaseInternalLink = styled(BaseInternalLink)`
  align-self: center;
  margin-top: ${moderateScale(12)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin: ${moderateScale(44)}px 0 ${moderateScale(8)}px;
  min-width: 100%;
`;
