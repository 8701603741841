import styled from 'styled-components/native';
import { Animated } from 'react-native';

export const ButtonLabel = styled.View`
  align-items: center;
  flex: 1;
  justify-content: center;
`;

export const IconWrapper = styled.View`
  align-items: center;
  height: 70%;
  justify-content: center;
`;

export const LabelText = styled.Text`
  align-self: center;
  justify-content: center;
`;

export const ProgressIndicatorBackground = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-radius: 8px;
  height: 18%;
  overflow: hidden;
  position: relative;
  width: 80%;
`;

export const ProgressIndicatorForeground = styled(Animated.View)`
  background-color: ${({ theme: { colors: { primary } } }) => primary};
  border-radius: 8px;
  bottom: 0;
  height: 100%;
  left: -100%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  width: 100%;
`;
