import React from 'react';
import { Alert, Linking } from 'react-native';
import T from 'prop-types';

const UpdateAlert = ({
  dispatchHandleDismissUpdateModal,
  storeUrl,
}) => {
  const onPress = () => {
    Linking.openURL(storeUrl);
    dispatchHandleDismissUpdateModal();
  };
  const Alerts = () => (
    Alert.alert(
      'Update Available',
      'A new version is available. Update now for the best experience.',
      [
        {
          onPress,
          style: 'default',
          text: 'Update',
        },
      ],
    ));
  return (
    <Alerts />
  );
};

export default UpdateAlert;

UpdateAlert.propTypes = {
  dispatchHandleDismissUpdateModal: T.func.isRequired,
  storeUrl: T.string,
};
