import React, { useState } from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { setDismissedPreLoginBanner } from '@dmi/shared/redux/Main/helpers';

import iconDictionary from '../../../../../utils/iconDictionary';
import LinkComponent from '../LinkComponent';
import {
  BannerHeading,
  DismissibleBannerContainer,
  DismissibleContainer,
  HeadingContainer,
  LeftContent,
  StyledDismissibleView,
} from '../styledComponents';

const CloseXIcon = iconDictionary('close', { height: 16, width: 12 });

const DismissibleDrawerBanner = ({
  ariaLabel,
  bannerName,
  disclosureName,
  handleDismissBanner,
  handleOpenDrawer,
  heading,
  isLastBanner = false,
  isTopBanner = false,
  isUrgent = false,
  linkLabel,
  toggleShowBanners,
}) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const handleDismiss = () => {
    setDismissedPreLoginBanner(bannerName);
    handleDismissBanner(bannerName);
    setIsDismissed(true);
  };

  return (
    <StyledDismissibleView
      $isDismissed={isDismissed}
      $isTopBanner={isTopBanner}
    >
      <DismissibleContainer
        $isDismissed={isDismissed}
        $isLastBanner={isLastBanner}
        $isTopBanner={isTopBanner}
      >
        <DismissibleBannerContainer
          $isDrawerType
          $isUrgent={isUrgent}
        >
          <HeadingContainer onPress={toggleShowBanners}>
            <LeftContent>
              <BannerHeading>{heading}</BannerHeading>
              <LinkComponent
                aria-label={ariaLabel}
                disclosureName={disclosureName}
                handleOpenDrawer={handleOpenDrawer}
                linkLabel={linkLabel}
              />
            </LeftContent>
            <Pressable
              aria-label="banner dismiss button"
              onPress={handleDismiss}
              role="button"
              style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
            >
              {CloseXIcon}
            </Pressable>
          </HeadingContainer>
        </DismissibleBannerContainer>
      </DismissibleContainer>
    </StyledDismissibleView>
  );
};

DismissibleDrawerBanner.propTypes = {
  ariaLabel: T.string,
  bannerName: T.string.isRequired,
  disclosureName: T.string.isRequired,
  handleDismissBanner: T.func.isRequired,
  handleOpenDrawer: T.func.isRequired,
  heading: T.string.isRequired,
  isLastBanner: T.bool,
  isTopBanner: T.bool,
  isUrgent: T.bool,
  linkLabel: T.string.isRequired,
  toggleShowBanners: T.func,
};

export default DismissibleDrawerBanner;
