import styled from 'styled-components/native';

import {
  BaseTextField,
  BorderlessButton,
  H2,
  P2,
  P3,
  PrimaryDivider,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const AlertIcon = styled.View`
  height: ${moderateScale(32)}px;
  width: ${moderateScale(32)}px;
`;

export const AlertWrapper = styled.View`
  align-items: center;
  background: ${(({ theme: { colors: { accentSilver } } }) => accentSilver)};
  border-radius: ${moderateScale(4)}px;
  flex-direction: row;
  padding: ${moderateScale(10.24)}px;
`;

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ButtonWrapper = styled.View`
  align-items: center;
  padding: ${moderateScale(52)}px 0 ${moderateScale(35)}px;
`;

export const ConfirmationContent = styled.View`
  align-items: center;
  margin: ${moderateScale(24)}px ${moderateScale(20)}px 0;
  text-align: center;
`;

export const ConfirmationWrapper = styled.View`
  flex: 1;
  padding: ${moderateScale(52)}px ${moderateScale(24)}px 0;
`;

export const EditContainer = styled.View`
  flex: 1;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const PaddingWrapper = styled.View`
  align-items: flex-start;
  flex-grow: ${({ $isFlex }) => $isFlex ? 1 : 0};
  padding: 0 ${moderateScale(24)}px;
`;

export const SendNewCodeWrapper = styled.View`
  flex-direction: row;
  padding-top:${moderateScale(34)}px;
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(({ theme: { fontSizes: { f5 } } }) => ({ inputFontSize: f5 }))``;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: f4,
  }],
}))``;

export const StyledH2 = styled(H2)`
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(38)}px;
`;

export const StyledP3 = styled(P3)`
  align-self: center;
  flex: 1;
  margin-left: ${moderateScale(10.24)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin: ${moderateScale(24)}px 0;
  max-width: 100%;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin-bottom: ${moderateScale(17)}px;
  width: 100%;
`;

export const TextFieldGroup = styled.View`
  align-items: center;
  flex-grow: ${({ $isFlexGrow }) => $isFlexGrow ? 1 : 0};
  margin: ${moderateScale(6)}px 0 ${moderateScale(19.92)}px;
`;

export const TextFieldWrapper = styled.View`
  align-items: center;
`;

export const TopVerifyP2 = styled(P2)`
  margin: ${moderateScale(8)}px 0 ${moderateScale(5)}px;
`;
