/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Ellipse,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterHome(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="#000" height={moderateScale(150)} viewBox="0 0 327 150" width={moderateScale(327)} {...props}>
      <Path className="cls-1" d="M92.43,41.05a3.44,3.44,0,0,0,3.18-4.74c-1.93-4.8-6-11.63-13.81-12.44a13.44,13.44,0,0,0-8.72,2.56.54.54,0,0,1-.68-.06,9.86,9.86,0,0,0-6.69-2.49c-5,0-9,3.41-9,7.61h0a.55.55,0,0,1-.6.55c-.25,0-.49,0-.74,0a7,7,0,0,0-6.6,4,.55.55,0,0,1-.73.3,6.25,6.25,0,0,0-2.72-.56,5.74,5.74,0,0,0-4.61,2.58A1.71,1.71,0,0,0,42,41.05Z" fill="#e6ebef" />
      <Path className="cls-2" d="M75.89,117a13.12,13.12,0,0,0-2.19-.18,13.47,13.47,0,0,0-10.86,5.5,1.88,1.88,0,0,1-2.13.65,9.38,9.38,0,0,0-10.37,3,11.21,11.21,0,0,0-2,5.68H76Z" fill="#fff" />
      <Path className="cls-3" d="M51.23,125.16a9.1,9.1,0,0,1,7.49-1.29c4.62,1.06,5.18-.05,6.57-1.72s6.77-4,10.8-2.9v-2.2a13.26,13.26,0,0,0-8.65,1.31c-4,2.34-4.51,4.15-5.13,4.4s-.86.39-2.75-.14a9.58,9.58,0,0,0-8.41,2.65" fill="#dce2e7" />
      <Path d="M48.27,132.34a.5.5,0,0,1-.5-.5,10,10,0,0,1,1.46-5.19.51.51,0,0,1,.86.53,8.76,8.76,0,0,0-1.32,4.66A.5.5,0,0,1,48.27,132.34Z" />
      <Path d="M50.94,125.78a.5.5,0,0,1-.36-.85,9.8,9.8,0,0,1,7.08-3,10,10,0,0,1,3.21.53,1.38,1.38,0,0,0,1.57-.47,14,14,0,0,1,11.26-5.7,14.29,14.29,0,0,1,2.27.19.5.5,0,0,1,.42.57.51.51,0,0,1-.58.41,13.45,13.45,0,0,0-2.11-.17,13,13,0,0,0-10.45,5.3,2.4,2.4,0,0,1-2.71.82,8.89,8.89,0,0,0-9.25,2.21A.49.49,0,0,1,50.94,125.78Z" />
      <Polygon className="cls-4" fill="#bac2ca" points="247.88 63.18 247.93 73.11 256.05 76.44 255.66 73.55 247.88 63.18" />
      <Polygon className="cls-5" fill="#6d7d8e" points="171.46 69.38 175.44 69.85 174.53 73.5 179.55 77.22 178.83 84.53 181.7 76.44 177.72 73.18 181.25 68.68 180.82 65.24 184.25 61.81 179.21 53.21 180.24 50.22 173.93 42.35 174.27 47.48 172.75 48.8 173.49 50.71 169.72 54.43 173.44 59.22 171.43 66.31 171.46 69.38" />
      <Polygon className="cls-4" fill="#bac2ca" points="175.3 69.79 142.38 69.38 142.38 72.26 174.61 72.26 175.3 69.79" />
      <Polygon className="cls-4" fill="#bac2ca" points="171.43 66.43 136.48 66.43 86.7 65.95 107.51 41.45 173.78 41.45 173.97 47.68 172.51 49.24 173.78 50.81 169.57 54.53 173.58 59.42 171.43 66.43" />
      <Path d="M204,129.54a.36.36,0,0,1-.37-.37V74.09a.36.36,0,0,1,.37-.37.37.37,0,0,1,.38.37v55.08A.37.37,0,0,1,204,129.54Z" />
      <Path className="cls-6" d="M281.27,132.18c0-.17,0-.33,0-.5a6.72,6.72,0,0,0-6.72-6.72l-.42,0a12.71,12.71,0,0,0-11-6.3q-.45,0-.9,0a3.26,3.26,0,0,1-3.06-1.6,12.69,12.69,0,0,0-11-6.33v21.34" fill="#eff3f6" />
      <Path d="M281.27,132.68h0a.5.5,0,0,1-.47-.54c0-.15,0-.3,0-.46a6.23,6.23,0,0,0-6.22-6.22h-.38a.5.5,0,0,1-.47-.24,12.28,12.28,0,0,0-10.55-6h-.6a4.31,4.31,0,0,1-3.92-2,12.17,12.17,0,0,0-10.44-5.86.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5,13.15,13.15,0,0,1,11.29,6.34,3.34,3.34,0,0,0,3,1.57h.65a13.3,13.3,0,0,1,11.26,6.28,7.24,7.24,0,0,1,7.36,7.22c0,.18,0,.36,0,.53A.5.5,0,0,1,281.27,132.68Z" />
      <Path d="M271.18,126.46a.27.27,0,0,1-.22-.13,8.65,8.65,0,0,0-1.08-1.56.25.25,0,0,1,.38-.33,9.41,9.41,0,0,1,1.14,1.64.24.24,0,0,1-.1.34A.17.17,0,0,1,271.18,126.46Z" />
      <Path d="M268.62,123.5a.21.21,0,0,1-.15,0,10.22,10.22,0,0,0-5.24-1.92.26.26,0,0,1-.25-.26.25.25,0,0,1,.25-.24h0a10.48,10.48,0,0,1,5.53,2,.25.25,0,0,1,.05.35A.25.25,0,0,1,268.62,123.5Z" />
      <Path d="M171.46,69.75h-28a.36.36,0,0,1-.37-.37.37.37,0,0,1,.37-.38h27.64l-.14-2.08H142.19a.38.38,0,1,1,0-.75h29.08a.37.37,0,0,1,.37.35l.2,2.83a.38.38,0,0,1-.1.29A.39.39,0,0,1,171.46,69.75Z" />
      <Polygon className="cls-1" fill="#e6ebef" points="143.75 132.4 129.29 132.4 129.29 55.84 143.75 63.64 143.75 132.4" />
      <Polygon className="cls-1" fill="#e6ebef" points="247.64 132.4 204.43 132.4 204.43 55.84 234.53 49.31 247.64 63.64 247.64 132.4" />
      <Polygon className="cls-4" fill="#bac2ca" points="148.31 66.28 113.29 27.62 100.77 27.62 136.1 66.48 132.57 66.48 148.31 66.28" />
      <Path className="cls-2" d="M108.23,35.87l-6.92-7.6a1.45,1.45,0,0,0-2.18,0L66.42,67.43a1.45,1.45,0,0,0,2.21,1.88l31.64-37.87,33.79,37.4a1.47,1.47,0,0,0,1.08.47,1.38,1.38,0,0,0,1-.38,1.44,1.44,0,0,0,.1-2.05l-25.9-28.73" fill="#fff" />
      <Polygon className="cls-2" fill="#fff" points="100.15 31.5 129.34 63.64 129.34 134.62 73.84 134.62 73.84 64.53 72.2 64.53 100.15 31.5" />
      <Path className="cls-4" d="M129.74,68l2.84,3.14a1.52,1.52,0,0,0,1.09.51h9.6V69l-8.62.13-4.94-5.2Z" fill="#bac2ca" />
      <Polygon className="cls-4" fill="#bac2ca" points="73.65 63.65 73.65 69.93 68.46 70 73.65 63.65" />
      <Path d="M73.65,70.35H68.34a.5.5,0,0,1,0-1h5.31a.5.5,0,0,1,0,1Z" />
      <Polygon className="cls-4" fill="#bac2ca" points="101.92 33.55 71.93 69.25 71.93 65.72 100.23 31.7 101.92 33.55" />
      <Path d="M148.56,67H136.2a.5.5,0,0,1,0-1h11.24L113.07,28.12h-7.82l-5.06.06h0a.5.5,0,1,1,0-1l5.07-.06h8a.5.5,0,0,1,.37.17l35.27,38.85a.49.49,0,0,1,.09.54A.51.51,0,0,1,148.56,67Z" />
      <Path className="cls-3" d="M135.33,69.4h12.74a1.41,1.41,0,0,0,1.35-1.46h0a1.92,1.92,0,0,0-.7-1.46H137.29" fill="#dce2e7" />
      <Path d="M148.07,69.9H135.33a.5.5,0,0,1,0-1h12.74a.91.91,0,0,0,.85-1,1.61,1.61,0,0,0-.38-1H137.29a.5.5,0,0,1,0-1h11.43c.54,0,1.2,1.08,1.2,2A1.91,1.91,0,0,1,148.07,69.9Z" />
      <Path d="M67.52,70.32a2,2,0,0,1-1.26-.47A1.95,1.95,0,0,1,66,67.1L98.75,28a1.95,1.95,0,0,1,2.93-.05l6.67,7.31a.48.48,0,0,1,0,.7.5.5,0,0,1-.71,0l-6.67-7.3a.94.94,0,0,0-.7-.32h0a1,1,0,0,0-.71.34L66.8,67.75a1,1,0,0,0-.22.69.9.9,0,0,0,.33.65,1,1,0,0,0,1.34-.1L99.89,31.12a.49.49,0,0,1,.37-.18.53.53,0,0,1,.38.16l33.8,37.4a1,1,0,0,0,1.34.06.91.91,0,0,0,.31-.65,1,1,0,0,0-.25-.69L110.54,39.1a.5.5,0,1,1,.74-.66l25.3,28.11a1.93,1.93,0,0,1-.13,2.75,2,2,0,0,1-2.76-.13l-33.4-37L69,69.63A2,2,0,0,1,67.52,70.32Z" />
      <Path d="M73.59,132.6a.5.5,0,0,1-.5-.5V63.54a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5V132.1A.5.5,0,0,1,73.59,132.6Z" />
      <Path d="M129.36,132.77a.38.38,0,0,1-.38-.37V98.57a.38.38,0,1,1,.75,0V132.4A.38.38,0,0,1,129.36,132.77Z" />
      <Path d="M129.36,95.92a.38.38,0,0,1-.38-.37v-32a.38.38,0,1,1,.75,0v32A.38.38,0,0,1,129.36,95.92Z" />
      <Path d="M143.62,132.5a.5.5,0,0,1-.5-.5V69.56a.5.5,0,0,1,.5-.5.51.51,0,0,1,.5.5V132A.5.5,0,0,1,143.62,132.5Z" />
      <Path className="cls-7" d="M110.1,64.25h-1.55a1.05,1.05,0,0,0-1,1.06v.27a1.06,1.06,0,0,0,1,1.06h1.55" fill="#e0e0e0" />
      <Path className="cls-1" d="M101.8,53.65H103a6.67,6.67,0,0,1,6.67,6.67v9.85a0,0,0,0,1,0,0H95.14a0,0,0,0,1,0,0V60.32A6.67,6.67,0,0,1,101.8,53.65Z" fill="#e6ebef" />
      <Polygon className="cls-8" fill="#fff" opacity="0.7" points="91.52 76.01 84.82 76.01 94.55 53.65 101.26 53.65 91.52 76.01" />
      <Polygon className="cls-8" fill="#fff" opacity="0.7" points="103.1 76.01 98.92 76.01 108.66 53.65 112.83 53.65 103.1 76.01" />
      <Path d="M109.91,70.75H93.7a.38.38,0,0,1-.38-.38V60.26a8.39,8.39,0,0,1,8.38-8.38h.21a8.39,8.39,0,0,1,8.38,8.38V70.37A.38.38,0,0,1,109.91,70.75ZM94.07,70h15.47V60.26a7.64,7.64,0,0,0-7.63-7.63h-.21a7.64,7.64,0,0,0-7.63,7.63Z" />
      <Path d="M95.8,69.46a.25.25,0,0,1-.25-.25V60.87a7.05,7.05,0,0,1,7-7h.84a8.08,8.08,0,0,1,6.1,2.84.25.25,0,0,1-.37.33,7.75,7.75,0,0,0-5.72-2.67h-.85a6.54,6.54,0,0,0-6.54,6.54v8.34A.25.25,0,0,1,95.8,69.46Z" />
      <Path d="M110,73.18H98.13a.38.38,0,1,1,0-.75h11.51V70.79h-17a.68.68,0,0,0-.68.68v.28a.68.68,0,0,0,.68.68h3.77a.38.38,0,1,1,0,.75H92.67a1.43,1.43,0,0,1-1.43-1.43v-.28A1.43,1.43,0,0,1,92.67,70H110a.38.38,0,0,1,.38.38V72.8A.38.38,0,0,1,110,73.18Z" />
      <Path d="M110,73h-1.54a1.31,1.31,0,0,1-1.31-1.31v-.27a1.31,1.31,0,0,1,1.31-1.31H110a.25.25,0,0,1,0,.5h-1.54a.81.81,0,0,0-.81.81v.27a.82.82,0,0,0,.81.81H110a.25.25,0,0,1,.25.25A.25.25,0,0,1,110,73Z" />
      <Path className="cls-7" d="M109.67,111.59h-1.59a1.08,1.08,0,0,0-1.09,1.08V113a1.08,1.08,0,0,0,1.09,1.08h1.59" fill="#e0e0e0" />
      <Rect className="cls-1" fill="#e6ebef" height="18.36" width="14.97" x="94.26" y="92.97" />
      <Polygon className="cls-8" fill="#fff" opacity="0.7" points="90.53 116 83.62 116 93.65 92.97 100.56 92.97 90.53 116" />
      <Polygon className="cls-8" fill="#fff" opacity="0.7" points="102.46 116 98.16 116 108.19 92.97 112.49 92.97 102.46 116" />
      <Path d="M109.48,111.92H92.77a.38.38,0,0,1-.37-.38v-20a.38.38,0,0,1,.37-.38h16.71a.38.38,0,0,1,.38.38v20A.38.38,0,0,1,109.48,111.92Zm-16.33-.75h16V91.9h-16Z" />
      <Path d="M94.73,110.74a.25.25,0,0,1-.25-.25V93.56a.25.25,0,0,1,.25-.25h14.83a.25.25,0,0,1,.25.25.25.25,0,0,1-.25.25H95v16.68A.25.25,0,0,1,94.73,110.74Z" />
      <Path d="M109.58,114.42H97.34a.38.38,0,1,1,0-.75h11.87V112H91.71a.71.71,0,0,0-.71.71V113a.71.71,0,0,0,.71.71H95.6a.38.38,0,0,1,0,.75H91.71A1.46,1.46,0,0,1,90.25,113v-.29a1.46,1.46,0,0,1,1.46-1.46h17.87a.38.38,0,0,1,.38.38V114A.38.38,0,0,1,109.58,114.42Z" />
      <Path d="M109.53,114.29h-1.59A1.33,1.33,0,0,1,106.6,113v-.29a1.34,1.34,0,0,1,1.34-1.33h1.59a.25.25,0,0,1,0,.5h-1.59a.84.84,0,0,0-.84.83V113a.84.84,0,0,0,.84.83h1.59a.25.25,0,0,1,.25.25A.25.25,0,0,1,109.53,114.29Z" />
      <Path className="cls-1" d="M310.82,50.69A1.6,1.6,0,0,0,312,48.05c-1.5-1.75-4.51-2.94-8-2.94a14.93,14.93,0,0,0-1.65.09,1,1,0,0,1-1.09-1h0c0-3-3.82-5.46-8.53-5.46a12.54,12.54,0,0,0-3.66.53.94.94,0,0,1-1.19-.69c-1-4.48-5.8-7.88-11.58-7.88-6.51,0-11.78,4.29-11.78,9.59,0,.19,0,.38,0,.56a5.69,5.69,0,0,0-.83-.07,5.47,5.47,0,0,0-5.2,3.79.94.94,0,0,1-1.17.63,5.57,5.57,0,0,0-1.48-.2,5.44,5.44,0,0,0-4.28,2.06,2.24,2.24,0,0,0,1.76,3.63Z" fill="#e6ebef" />
      <Path d="M308,133H22.58a.5.5,0,1,1,0-1H308a.5.5,0,0,1,0,1Z" />
      <Polyline className="cls-9" fill={svgHighlight} points="161.33 131.8 161.33 91.5 183.64 91.5 183.64 131.8" />
      <Path d="M183.64,132.18a.37.37,0,0,1-.37-.38V91.88H161.71V131.8a.38.38,0,1,1-.75,0V91.5a.38.38,0,0,1,.37-.37h22.31a.38.38,0,0,1,.38.37v40.3A.38.38,0,0,1,183.64,132.18Z" />
      <Ellipse className="cls-2" cx="172.34" cy="100.83" fill="#fff" rx="0.81" ry="0.8" />
      <Path d="M172.34,101.88a1.05,1.05,0,1,1,1.07-1A1.06,1.06,0,0,1,172.34,101.88Zm0-1.6a.55.55,0,0,0-.56.55.56.56,0,0,0,.56.55.57.57,0,0,0,.57-.55A.56.56,0,0,0,172.34,100.28Z" />
      <Path className="cls-2" d="M216.56,85.07h19.26a0,0,0,0,1,0,0v17.07a9.63,9.63,0,0,1-9.63,9.63h0a9.63,9.63,0,0,1-9.63-9.63V85.07a0,0,0,0,1,0,0Z" fill="#fff" transform="translate(452.38 196.83) rotate(180)" />
      <Path d="M236.94,112.37H216.37A.38.38,0,0,1,216,112V94.27a10.66,10.66,0,0,1,21.32,0V112A.38.38,0,0,1,236.94,112.37Zm-20.2-.75h19.82V94.27a9.91,9.91,0,0,0-19.82,0Z" />
      <Path d="M235.28,109a.25.25,0,0,1-.25-.25V94.81a9.2,9.2,0,1,0-18.4,0V95a.25.25,0,0,1-.25.25.25.25,0,0,1-.25-.25v-.23a9.7,9.7,0,1,1,19.4,0v13.94A.25.25,0,0,1,235.28,109Z" />
      <Path className="cls-2" d="M228.28,111.81a3.93,3.93,0,0,1,3.75-3.94,3.64,3.64,0,0,1,3.4,2.49,3,3,0,0,1,2.48-1.35,3.08,3.08,0,0,1,3,2.84" fill="#fff" />
      <Path d="M240.92,111.85a.38.38,0,0,1-.37-.34,2.65,2.65,0,0,0-4.81-1.24.36.36,0,0,1-.35.15.37.37,0,0,1-.31-.24,3.28,3.28,0,0,0-3.05-2.13,3.46,3.46,0,0,0-3.37,3.38.38.38,0,1,1-.75,0A4.23,4.23,0,0,1,232,107.3a4,4,0,0,1,3.5,2.06,3.41,3.41,0,0,1,5.77,2.07.38.38,0,0,1-.34.42Z" />
      <Path className="cls-2" d="M229.84,111.81a3.63,3.63,0,0,0-3.46-3.64,3.39,3.39,0,0,0-3.16,2.3,2.77,2.77,0,0,0-2.29-1.25,2.87,2.87,0,0,0-2.79,2.63" fill="#fff" />
      <Path d="M218.14,111.88h0a.39.39,0,0,1-.34-.42,3.18,3.18,0,0,1,5.36-2,3.76,3.76,0,0,1,3.25-1.89,3.94,3.94,0,0,1,3.83,3.85.38.38,0,1,1-.75,0,3.17,3.17,0,0,0-3.08-3.1,3,3,0,0,0-2.81,2,.36.36,0,0,1-.3.24.38.38,0,0,1-.35-.16,2.46,2.46,0,0,0-2-1,2.42,2.42,0,0,0-2.41,2.17A.39.39,0,0,1,218.14,111.88Z" />
      <Path className="cls-1" d="M221,112.09h21.19a0,0,0,0,1,0,0v5.39a0,0,0,0,1,0,0H218.5a0,0,0,0,1,0,0v-2.9A2.49,2.49,0,0,1,221,112.09Z" fill="#e6ebef" transform="translate(460.69 229.57) rotate(-180)" />
      <Path d="M239.69,117.85H218.5a.37.37,0,0,1-.37-.37v-5.39a.38.38,0,0,1,.37-.37h23.68a.38.38,0,0,1,.38.37V115A2.87,2.87,0,0,1,239.69,117.85Zm-20.81-.75h20.81a2.11,2.11,0,0,0,2.12-2.11v-2.52H218.88Z" />
      <Path className="cls-4" d="M218.9,112.09h0a2.49,2.49,0,0,1,2.49,2.49v2.9a0,0,0,0,1,0,0h-5a0,0,0,0,1,0,0v-2.9a2.49,2.49,0,0,1,2.49-2.49Z" fill="#bac2ca" transform="translate(437.79 229.57) rotate(-180)" />
      <Path d="M218.9,117.85A2.87,2.87,0,0,1,216,115v-2.9a.38.38,0,0,1,.37-.37h5a.38.38,0,0,1,.37.37V115A2.86,2.86,0,0,1,218.9,117.85Zm-2.12-5.38V115a2.12,2.12,0,0,0,4.23,0v-2.52Z" />
      <Polygon className="cls-4" fill="#bac2ca" points="234.62 52.24 247.67 69.91 248.03 66.98 236.41 50.89 234.62 52.24" />
      <Polygon className="cls-4" fill="#bac2ca" points="190.46 40.81 238.53 46.75 208.38 68.85 180.44 65.4 188.07 58.48 186.34 54.53 190.93 50.95 190.02 49.32 191.12 48.55 190.46 40.81" />
      <Path className="cls-3" d="M226.33,54.13l9.25-7.05a1.89,1.89,0,0,1,2.59.36l20.5,27.46a1.44,1.44,0,0,1-.47,2.15,1.89,1.89,0,0,1-2.41-.47l-19.39-26L209,71.47a1.88,1.88,0,0,1-1.34.35,1.92,1.92,0,0,1-1.12-.55,1.44,1.44,0,0,1,.16-2.19l16.82-12.83" fill="#dce2e7" />
      <Path d="M257.27,77.8a2.34,2.34,0,0,1-1.88-.93L236.3,51.31l-27,20.56a2.3,2.3,0,0,1-1.7.44,2.39,2.39,0,0,1-1.42-.69,2,2,0,0,1-.57-1.53,1.93,1.93,0,0,1,.78-1.4l16.08-12.27a.5.5,0,0,1,.7.09.51.51,0,0,1-.09.7L207,69.48a.93.93,0,0,0-.1,1.44,1.36,1.36,0,0,0,.82.4,1.4,1.4,0,0,0,1-.25L236.09,50.2a.52.52,0,0,1,.38-.09.45.45,0,0,1,.33.19l19.39,26a1.41,1.41,0,0,0,1.76.34,1,1,0,0,0,.5-.66,1,1,0,0,0-.18-.76l-20.5-27.46a1.34,1.34,0,0,0-.89-.5,1.37,1.37,0,0,0-1,.24l-9,6.78a.5.5,0,0,1-.6-.8l9-6.77a2.4,2.4,0,0,1,3.3.45l20.5,27.46a2,2,0,0,1,.36,1.56,2,2,0,0,1-1,1.32A2.31,2.31,0,0,1,257.27,77.8Z" />
      <Path className="cls-4" d="M179.84,71l24.32,3.4a1.73,1.73,0,0,0,1.49-.51l1.68-1.76-25.82-3.67Z" fill="#bac2ca" />
      <Path d="M206.69,72.19h-.07l-25.18-3.28a.48.48,0,0,1-.42-.41l-.59-3.32a.51.51,0,0,1,.14-.45l6.82-6.62-1.27-3.55a.52.52,0,0,1,.16-.56l4-3.19-.71-1.48a.51.51,0,0,1,.22-.66l.79-.42-.41-7.52a.55.55,0,0,1,.16-.4.54.54,0,0,1,.41-.13L236.36,46a.5.5,0,0,1,.43.56.51.51,0,0,1-.56.43l-45-5.72.38,7.25a.5.5,0,0,1-.26.47l-.65.34.67,1.41a.49.49,0,0,1-.14.6l-4.05,3.22,1.25,3.52a.5.5,0,0,1-.13.52l-6.85,6.67.48,2.7,24.81,3.22a.5.5,0,0,1,.43.56A.5.5,0,0,1,206.69,72.19Z" />
      <Path d="M206.51,69.11h0l-24.69-3a.38.38,0,0,1-.33-.42.39.39,0,0,1,.42-.33l24.69,3.06a.36.36,0,0,1,.32.41A.37.37,0,0,1,206.51,69.11Z" />
      <Path d="M248,133.09a.5.5,0,0,1-.5-.5V66.53a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v66.06A.5.5,0,0,1,248,133.09Z" />
      <Path d="M256,77.36a.52.52,0,0,1-.21,0L248,73.76a.49.49,0,0,1-.24-.66.5.5,0,0,1,.66-.25l7.76,3.56a.5.5,0,0,1,.25.66A.52.52,0,0,1,256,77.36Z" />
      <Path d="M171.14,66.83l-.15,0a.51.51,0,0,1-.33-.63l2.2-6.91-3.67-4.42a.51.51,0,0,1,0-.69l3.7-3.45-.8-1.49a.52.52,0,0,1,.1-.6l1.46-1.4L173.22,42H125.94a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h47.74a.5.5,0,0,1,.5.46l.52,5.95a.49.49,0,0,1-.15.41l-1.36,1.3.8,1.49a.49.49,0,0,1-.1.6l-3.63,3.4,3.55,4.27a.53.53,0,0,1,.09.47l-2.29,7.17A.49.49,0,0,1,171.14,66.83Z" />
      <Path d="M184.05,61.74a.5.5,0,0,1-.43-.24l-4.89-8.22a.52.52,0,0,1,0-.47l1.11-2.33-6.53-8.43a.5.5,0,1,1,.79-.61l6.71,8.67a.49.49,0,0,1,.06.52L179.73,53l4.75,8a.5.5,0,0,1-.17.68A.48.48,0,0,1,184.05,61.74Z" />
      <Path d="M178.83,84.9h-.08a.37.37,0,0,1-.29-.4l.7-7.09-4.85-3.59a.38.38,0,0,1-.14-.4l.81-3.24-3.56-.42a.37.37,0,0,1-.33-.42.39.39,0,0,1,.42-.32l4,.47a.37.37,0,0,1,.26.16.4.4,0,0,1,.06.3L175,73.35l4.82,3.57a.36.36,0,0,1,.15.34l-.41,4.25,1.75-5-3.77-3.09a.37.37,0,0,1-.06-.52l3.52-4.5a.37.37,0,1,1,.59.46l-3.29,4.21,3.69,3a.38.38,0,0,1,.12.41l-2.87,8.09A.39.39,0,0,1,178.83,84.9Z" />
      <Path d="M179.84,88.5a.36.36,0,0,1-.14,0,.37.37,0,0,1-.2-.49l.55-1.32-1.43-1.88a.37.37,0,0,1,.07-.53.38.38,0,0,1,.52.07l1.57,2.06a.37.37,0,0,1,.05.37l-.64,1.51A.37.37,0,0,1,179.84,88.5Z" />
      <Path d="M231.35,101.61a.32.32,0,0,1-.19,0l-4.64-2.7a.4.4,0,0,1-.13-.13l-2-3.22a.38.38,0,0,1,.05-.46l3.93-4-2.9-5.61a.38.38,0,0,1,.16-.51.39.39,0,0,1,.51.16l3,5.85a.38.38,0,0,1-.07.44l-3.91,4L227,98.26l4.36,2.54,3.57-2.32a.38.38,0,1,1,.41.63l-3.77,2.44A.36.36,0,0,1,231.35,101.61Z" />
      <Path d="M225.77,106a.35.35,0,0,1-.32-.2l-2.4-4.35a.37.37,0,0,1,.09-.47l3-2.5a.38.38,0,0,1,.48.58l-2.74,2.3,2.24,4.08a.37.37,0,0,1-.15.51A.35.35,0,0,1,225.77,106Z" />
      <Path d="M221.81,106.16a.34.34,0,0,1-.23-.08.37.37,0,0,1-.06-.52l2.34-3a.37.37,0,0,1,.53-.07.38.38,0,0,1,.07.52l-2.35,3A.41.41,0,0,1,221.81,106.16Z" />
      <Path d="M230.91,103.77h0a.37.37,0,0,1-.32-.42l.34-2.45a.37.37,0,1,1,.74.11l-.34,2.44A.38.38,0,0,1,230.91,103.77Z" />
      <Path d="M102.07,70.36a.38.38,0,0,1-.34-.23l-1.37-3.28a.37.37,0,0,1,.1-.43l2.68-2.27-1.32-3.27a.37.37,0,0,1,.12-.43l3.18-2.55a.37.37,0,0,1,.46.59l-3,2.37,1.32,3.26a.37.37,0,0,1-.11.43l-2.67,2.26,1.26,3a.37.37,0,0,1-.2.49A.4.4,0,0,1,102.07,70.36Z" />
      <Path d="M102.17,61.11h0l-2.93-.39a.38.38,0,0,1-.33-.32l-.44-3.13a.37.37,0,0,1,.32-.42.36.36,0,0,1,.43.31l.4,2.85,2.65.36a.37.37,0,0,1,0,.74Z" />
      <Path d="M105.06,65.49a.33.33,0,0,1-.14,0l-1.47-1a.25.25,0,1,1,.28-.42l1.46,1a.25.25,0,0,1,.07.35A.23.23,0,0,1,105.06,65.49Z" />
      <Path d="M80.4,132.82a.31.31,0,0,1-.13,0,.37.37,0,0,1-.21-.48l3-7.84-4.9-4.65a.37.37,0,0,1-.07-.46l1.8-3.13-6.55-5.79a.38.38,0,0,1,0-.53.37.37,0,0,1,.53,0l6.78,6a.38.38,0,0,1,.08.46l-1.81,3.14,4.87,4.62a.37.37,0,0,1,.09.41l-3.15,8.07A.36.36,0,0,1,80.4,132.82Z" />
      <Path d="M83.88,124.75a.37.37,0,0,1-.35-.25.36.36,0,0,1,.22-.48l4.11-1.49.49-2.11a.38.38,0,0,1,.45-.28.37.37,0,0,1,.28.45l-.53,2.3a.37.37,0,0,1-.24.27L84,124.73Z" />
      <Path d="M92.58,125.43a.45.45,0,0,1-.16,0l-4.2-2a.37.37,0,0,1-.18-.5.39.39,0,0,1,.5-.18l4.2,2a.37.37,0,0,1,.18.5A.38.38,0,0,1,92.58,125.43Z" />
      <Path className="cls-6" d="M53.66,94h0c-6.22-37.57-15-35.89-15-35.89v0S30.31,56.38,24.08,94h0a21.71,21.71,0,0,0-.29,3.55c0,10.14,6.74,18.36,15.06,18.36S54,107.64,54,97.5A22.88,22.88,0,0,0,53.66,94Z" fill="#eff3f6" />
      <Path className="cls-3" d="M54,97.5A22.88,22.88,0,0,0,53.66,94h0c-6.22-37.57-15-35.89-15-35.89v0a2.45,2.45,0,0,0-1.44.34c2.22,4.51,9.25,19.82,10,34.1.63,13.1-3.7,19.86-8.44,23.32h.13C47.21,115.86,54,107.64,54,97.5Z" fill="#dce2e7" />
      <Path d="M38.89,116.36c-8.58,0-15.56-8.46-15.56-18.86A22.6,22.6,0,0,1,23.6,94a.32.32,0,0,1,0-.17c4.35-26.24,9.83-33.48,12.71-35.46a4,4,0,0,1,2.27-.83h0a3.76,3.76,0,0,1,2.67.89c3,2.06,8.57,9.4,12.87,35.41a.37.37,0,0,1,0,.11,23.2,23.2,0,0,1,.28,3.52C54.45,107.9,47.47,116.36,38.89,116.36ZM24.61,93.86a.48.48,0,0,1,0,.17,22.75,22.75,0,0,0-.28,3.47c0,9.84,6.53,17.86,14.56,17.86s14.56-8,14.56-17.86A22.55,22.55,0,0,0,53.17,94a.41.41,0,0,1,0-.11C47.72,61.2,40.43,58.73,39,58.56a.49.49,0,0,1-.33.13h0a.51.51,0,0,1-.3-.1C37.15,58.72,30.08,61,24.61,93.86Z" />
      <Path d="M38.7,132.23a.51.51,0,0,1-.5-.5V97.3a.51.51,0,0,1,.5-.5.5.5,0,0,1,.5.5v34.43A.5.5,0,0,1,38.7,132.23Z" />
      <Path d="M38.54,105.36a.46.46,0,0,1-.29-.1l-5.5-3.91a.5.5,0,0,1,.58-.82l5.5,3.92a.5.5,0,0,1,.11.7A.5.5,0,0,1,38.54,105.36Z" />
      <Path d="M38.84,110a.5.5,0,0,1-.29-.91L44,105.13a.51.51,0,0,1,.7.11.5.5,0,0,1-.12.7l-5.49,3.92A.52.52,0,0,1,38.84,110Z" />
      <Path d="M28.83,85h-.06a.37.37,0,0,1-.31-.43,76,76,0,0,1,3.93-14.19.37.37,0,0,1,.48-.22.38.38,0,0,1,.22.48,75.13,75.13,0,0,0-3.89,14A.38.38,0,0,1,28.83,85Z" />
      <Path d="M33.54,68.82l-.14,0a.38.38,0,0,1-.21-.49c1-2.39,1.69-3.91,1.7-3.93a.37.37,0,1,1,.67.33s-.72,1.51-1.67,3.88A.38.38,0,0,1,33.54,68.82Z" />
      <Path d="M18.43,132.92H11.68a.5.5,0,0,1-.5-.5.5.5,0,0,1,.5-.5h6.75a.51.51,0,0,1,.5.5A.5.5,0,0,1,18.43,132.92Z" />
      <Path d="M317.09,132.92h-4.31a.5.5,0,0,1-.5-.5.51.51,0,0,1,.5-.5h4.31a.5.5,0,0,1,.5.5A.5.5,0,0,1,317.09,132.92Z" />
      <Path d="M290.36,132.48a.38.38,0,0,1-.38-.38V107.25a.38.38,0,0,1,.75,0V132.1A.38.38,0,0,1,290.36,132.48Z" />
      <Path d="M295.34,107.38h-10a.37.37,0,0,1-.37-.37v-4.13a2.59,2.59,0,0,1,2.6-2.6h7.82a.37.37,0,0,1,.37.37V107A.37.37,0,0,1,295.34,107.38Zm-9.67-.75H295V101h-7.44a1.85,1.85,0,0,0-1.85,1.85Z" />
      <Path className="cls-4" d="M295,100.65h0a2.23,2.23,0,0,1,2.23,2.23V107a0,0,0,0,1,0,0h-4.45a0,0,0,0,1,0,0v-4.13A2.23,2.23,0,0,1,295,100.65Z" fill="#bac2ca" />
      <Path d="M297.24,107.38h-4.45a.37.37,0,0,1-.37-.37v-4.13a2.6,2.6,0,0,1,5.2,0V107A.37.37,0,0,1,297.24,107.38Zm-4.07-.75h3.7v-3.75a1.85,1.85,0,0,0-3.7,0Z" />
      <Path d="M290.74,103.52a.38.38,0,0,1-.36-.26L289,98.94l-.71.25a.37.37,0,0,1-.48-.22.38.38,0,0,1,.23-.48l1.07-.39a.39.39,0,0,1,.3,0,.39.39,0,0,1,.19.23L291.1,103a.38.38,0,0,1-.25.47Z" />
    </Svg>
  );
}
