/**
 *
 * EditPasswordTextField
 * @description: Reusable masked/unmasked text field with fixed label.
 * Accepts same props as base Text Field input component
 *
 */

import React, { forwardRef, useState } from 'react';
import T from 'prop-types';

import ConditionalRender from '../../ConditionalRender';
import {
  Container,
  ErrorLabel,
  LabelInputContainer,
  StyledIconButton,
  StyledLabel,
  StyledTextField,
  TextFieldWrapper,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const VisibilityOffIcon = iconDictionary('visibilityOff', { width: 15 });
const VisibilityOnIcon = iconDictionary('visibilityOn', { width: 15 });

const EditPasswordTextField = forwardRef(({
  disabled = false,
  errorMessage,
  label,
  placeholder = '',
  value,
  ...restProps
}, ref) => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibilityPress = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container>
      <ConditionalRender
        Component={<ErrorLabel>{errorMessage}</ErrorLabel>}
        shouldRender={!!errorMessage}
      />
      <LabelInputContainer $noLabel={!label}>
        <StyledLabel $noLabel={!label}>{label}</StyledLabel>
        <TextFieldWrapper>
          <StyledTextField
            ref={ref}
            aria-label={errorMessage || label}
            disabled={disabled}
            error={!!errorMessage}
            placeholder={placeholder}
            renderErrorMessage={false}
            rightIcon={(
              <StyledIconButton
                accessibilityState={{ checked: showPassword }}
                aria-checked={showPassword}
                aria-label="password-visibility"
                Icon={showPassword ? VisibilityOnIcon : VisibilityOffIcon}
                onPress={handlePasswordVisibilityPress}
                role="switch"
              />
            )}
            secureTextEntry={!showPassword}
            value={value}
            variant="outline"
            {...restProps}
          />
        </TextFieldWrapper>
      </LabelInputContainer>
    </Container>
  );
});

EditPasswordTextField.propTypes = {
  disabled: T.bool,
  errorMessage: T.string,
  label: T.string.isRequired,
  placeholder: T.string,
  value: T.string.isRequired,
};

export default EditPasswordTextField;
