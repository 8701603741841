/* eslint-disable max-len */
import React, { Fragment } from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Pressable } from 'react-native';

import { INTL_IDS } from '@dmi/shared/redux/Help/messages';

import {
  BaseExternalLink,
  ConditionalRender,
  StyledScrollView,
  Tooltip,
} from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import iconDictionary from '../../../utils/iconDictionary';
import HardshipTooltip from './Tooltip';
import {
  ButtonText,
  Card,
  CardText,
  CardTitle,
  ContentWrapper,
  EmptyCovidLink,
  LinkContainer,
  LinkText,
  SubTitle,
  Title,
} from './styledComponents';

const LighthouseIllustration = iconDictionary('hardshipLighthouse');
const TalkIcon = iconDictionary('hardshipTalk');
const ClipboardIcon = iconDictionary('hardshipClipboard');
const WarningIcon = iconDictionary('hardshipWarning');

const HardshipView = ({
  handleCallLinking,
  hardshipAssistancePhoneNumber,
  isMurabahaAccount,
  navigation,
  tooltipProps: {
    handleCloseTooltip,
    handleOpenTooltip,
    isTooltipOpen,
    selectedTooltip,
  },
}) => {
  const [modificationContent, repaymentPlanContent, temporaryForbearanceContent] = isMurabahaAccount
    ? ['Modification', 'Repayment Plan', 'Temporary Forbearance']
    : [
      <LinkText onPress={() => handleOpenTooltip('modification')}>Modification</LinkText>,
      <LinkText onPress={() => handleOpenTooltip('repaymentPlan')}>Repayment Plan</LinkText>,
      <LinkText onPress={() => handleOpenTooltip('forbearance')}>Temporary Forbearance</LinkText>,
    ];

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Hardship Assistance"
      />
      <StyledScrollView showsVerticalScrollIndicator={false}>
        <ContentWrapper>
          {LighthouseIllustration}
          <Title>Help is on the way</Title>
          <SubTitle>
            <FormattedMessage id={INTL_IDS.HARDSHIP_ASSISTANCE_HEADER_TEXT} />
          </SubTitle>
          <Card>
            {ClipboardIcon}
            <CardTitle>What to do next?</CardTitle>
            <CardText>
              To start the process of determining if you are eligible for payment assistance options such as a&nbsp;
              {repaymentPlanContent},&nbsp; {modificationContent}, or&nbsp; {temporaryForbearanceContent},
              please apply for hardship assistance and update the financial data that we have on file for you.
            </CardText>
            <LinkContainer>
              <BaseExternalLink href="https://loansolutioncenter.com" label="Apply Online" />
            </LinkContainer>
          </Card>
          <Card>
            {WarningIcon}
            <CardTitle>Stay Vigilant Against Fraud</CardTitle>
            <CardText>
              <FormattedMessage id={INTL_IDS.HARDSHIP_ASSISTANCE_CARD_FRAUD_TEXT} />
            </CardText>
            <EmptyCovidLink />
          </Card>
          <Card>
            {TalkIcon}
            <CardTitle>Contact us for more information</CardTitle>
            <CardText>
              Call us during regular business hours at&nbsp;
              <LinkText onPress={handleCallLinking}>{hardshipAssistancePhoneNumber}</LinkText>
            </CardText>
            <LinkContainer>
              <Pressable onPress={handleCallLinking}>
                <ButtonText>Call Now</ButtonText>
              </Pressable>
            </LinkContainer>
          </Card>
        </ContentWrapper>
        <ConditionalRender
          Component={(
            <Tooltip
              handleClose={handleCloseTooltip}
              positionAbsolute={{ left: 'auto', top: 'auto' }}
              visible={isTooltipOpen}
            >
              <HardshipTooltip
                handleCloseTooltip={handleCloseTooltip}
                selectedTooltip={selectedTooltip}
              />
            </Tooltip>
          )}
          shouldRender={isTooltipOpen}
        />
      </StyledScrollView>
    </Fragment>
  );
};

HardshipView.propTypes = {
  handleCallLinking: T.func.isRequired,
  hardshipAssistancePhoneNumber: T.string.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  tooltipProps: T.shape({
    handleCloseTooltip: T.func.isRequired,
    handleOpenTooltip: T.func.isRequired,
    isTooltipOpen: T.bool.isRequired,
    selectedTooltip: T.string.isRequired,
  }).isRequired,
};

export default HardshipView;
