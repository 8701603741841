import styled from 'styled-components/native';

import { BaseInternalLink, H4, P3 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

/**
 * Utilized by EscrowCard, InsuranceCard, PMICard, TaxCard
 */

export const CardContainer = styled.View`
  padding: ${moderateScale(16)}px ${moderateScale(24)}px ${moderateScale(8)}px;
  width: 100%;
`;

export const HeaderContainer = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(14)}px 0;
`;

export const HeaderWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
`;

export const HeaderText = styled(H4)`
  flex-shrink: 1;
  margin-left: ${moderateScale(12)}px;
`;

export const InternalLink = styled(BaseInternalLink)`
  align-items: center;
  height: ${moderateScale(34)}px;
`;

export const ItemLabel = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ItemWrapper = styled.View`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(11)}px 0;
`;

/**
 * Utilized by PaymentStatus
 */

export const PaymentStatusIcon = styled.View.attrs(({
  paymentIsDue,
  theme: { colors: { accentRed, primary } },
}) => ({ iconColor: paymentIsDue ? accentRed : primary }))`
  background-color: ${({ iconColor }) => iconColor};
  border-radius: ${moderateScale(3)}px;
  height: ${moderateScale(6)}px;
  width: ${moderateScale(6)}px;
`;

export const PaymentStatusText = styled.Text`
  font-size: ${({ theme: { fontSizes: { f2 } } }) => `${f2}px`};
  font-weight: 700;
  line-height: ${moderateScale(18)}px;
  margin-left: ${moderateScale(4)}px;
`;

export const PaymentStatusWrapper = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(4)}px;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  height: ${moderateScale(26)}px;
  padding: ${moderateScale(4)}px ${moderateScale(6)}px;
`;
