import React from 'react';
import T from 'prop-types';

import { cancelMailingAddressDictionary } from './constants';
import FooterComponent from './FooterComponent';
import { StyledBottomDrawer } from './styledComponents';

const CancelMailingAddressDrawer = ({
  currentAddress,
  customerServicePhoneNumber,
  dispatchCancelMailingAddress,
  dispatchChangeStep,
  handleClose,
  isOpen,
  navigation,
  pendingAddress,
  status,
  step,
  streetAddress,
  unmaskedSelectedLoan,
}) => {
  const ViewToRender = cancelMailingAddressDictionary[step];

  return (
    <StyledBottomDrawer
      {...(step === 1 && { FooterComponent })}
      {...(step === 1 && { footerProps: { dispatchChangeStep, handleClose } })}
      handleClose={handleClose}
      isVisible={isOpen}
    >
      <ViewToRender
        currentAddress={currentAddress}
        customerServicePhoneNumber={customerServicePhoneNumber}
        dispatchCancelMailingAddress={dispatchCancelMailingAddress}
        dispatchChangeStep={dispatchChangeStep}
        handleClose={handleClose}
        navigation={navigation}
        pendingAddress={pendingAddress}
        status={status}
        streetAddress={streetAddress}
        unmaskedSelectedLoan={unmaskedSelectedLoan}
      />
    </StyledBottomDrawer>
  );
};

CancelMailingAddressDrawer.propTypes = {
  currentAddress: T.object.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchCancelMailingAddress: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  handleClose: T.func.isRequired,
  isOpen: T.bool.isRequired,
  navigation: T.object.isRequired,
  pendingAddress: T.object.isRequired,
  status: T.string.isRequired,
  step: T.number.isRequired,
  streetAddress: T.string.isRequired,
  unmaskedSelectedLoan: T.string.isRequired,
};

export default CancelMailingAddressDrawer;
