import React from 'react';

import { Skeleton } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';
import {
  HeaderWrapper,
  PaymentDetailContainer,
  PaymentDetailRow,
  PaymentDetailRows,
  Subheader,
} from '../styledComponents';
import { SkeletonWrapper } from './styledComponents';

const PaymentDetailSkeleton = () => (
  <PaymentDetailContainer>
    <HeaderWrapper>
      <SkeletonWrapper width="44.8%">
        <Skeleton
          $bottomMargin={0}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={0}
          height={`${moderateScale(18)}px`}
          width="100%"
        />
      </SkeletonWrapper>
      <SkeletonWrapper width="26.93%">
        <Skeleton
          $bottomMargin={0}
          $leftMargin={0}
          $rightMargin={0}
          $topMargin={0}
          height={`${moderateScale(18)}px`}
          width="100%"
        />
      </SkeletonWrapper>
    </HeaderWrapper>
    <Subheader>
      <Skeleton
        $bottomMargin={0}
        $leftMargin={0}
        $rightMargin={0}
        $topMargin={0}
        hasPulsateWidth
        height={`${moderateScale(14)}px`}
        width={`${moderateScale(138)}px`}
      />
    </Subheader>
    <PaymentDetailRows>
      <PaymentDetailRow>
        <SkeletonWrapper width="30.67%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
        <SkeletonWrapper width="26.93%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <SkeletonWrapper width="30.67%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
        <SkeletonWrapper width="26.93%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <SkeletonWrapper width="30.67%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
        <SkeletonWrapper width="26.93%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
      </PaymentDetailRow>
      <PaymentDetailRow>
        <SkeletonWrapper width="30.67%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
        <SkeletonWrapper width="26.93%">
          <Skeleton
            $bottomMargin={0}
            $leftMargin={0}
            $rightMargin={0}
            $topMargin={0}
            height={`${moderateScale(12)}px`}
            width="100%"
          />
        </SkeletonWrapper>
      </PaymentDetailRow>
    </PaymentDetailRows>
  </PaymentDetailContainer>
);

export default PaymentDetailSkeleton;
