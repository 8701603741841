import React, { Fragment } from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { EXPIRED_POLICY_DICT, INSURANCE_TYPE_DICT } from '@dmi/shared/redux/Mortgage/constants';

import {
  BaseInternalLink,
  ConditionalRender,
  SmallPrimaryButton,
} from '../../../../base_ui';
import { ExpiredH2, HomeH2 } from './styledComponents';

const ExpiredPolicyView = ({
  dispatchUpdateSelectedInsurance,
  expiredPolicies,
  navigation,
}) => {
  const { insuranceType } = expiredPolicies[0];
  const policy = EXPIRED_POLICY_DICT[insuranceType];

  return (
    <ConditionalRender
      Component={(
        <Fragment>
          <ExpiredH2>{`Your ${policy} expired. Update now`}</ExpiredH2>
          <SmallPrimaryButton
            label="Update"
            onPress={() => {
              dispatchUpdateSelectedInsurance(insuranceType);
              navigation.navigate('InsuranceDetails');
            }}
          />
        </Fragment>
      )}
      FallbackComponent={(
        <Fragment>
          <HomeH2>Please update your expired policies:</HomeH2>
          <View>
            {expiredPolicies.map((insurance) => {
              const label = INSURANCE_TYPE_DICT[insurance.insuranceType];
              return (
                <BaseInternalLink
                  key={`${insurance.insuranceType}-internal-link`}
                  label={label}
                  onPressFunc={() => dispatchUpdateSelectedInsurance(insurance.insuranceType)}
                  path="InsuranceDetails"
                />
              );
            })}
          </View>
        </Fragment>
      )}
      shouldRender={expiredPolicies.length === 1}
    />
  );
};

ExpiredPolicyView.propTypes = {
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  expiredPolicies: T.array.isRequired,
  navigation: T.object.isRequired,
};

export default ExpiredPolicyView;
