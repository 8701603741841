import moment from 'moment';

import {
  DesktopSelectLoanFall,
  DesktopSelectLoanSpring,
  DesktopSelectLoanSummer,
  DesktopSelectLoanWinter,
  LoginFall,
  LoginSpring,
  LoginSummer,
  LoginWinter,
  MarketingFall,
  MarketingSpring,
  MarketingSummer,
  MarketingWinter,
  OutlinedHouseFall,
  OutlinedHouseSpring,
  OutlinedHouseSummer,
  OutlinedHouseWinter,
  PaymentHouseFall,
  PaymentHouseSpring,
  PaymentHouseSummer,
  PaymentHouseWinter,
  SelectLoanFall,
  SelectLoanSpring,
  SelectLoanSummer,
  SelectLoanWinter,
} from './svg';

export const getSeasonalIllustrations = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const seasonsArray = [
    {
      endDate: `12/20/${currentYear}`,
      illustrations: {
        DesktopSelectLoan: DesktopSelectLoanFall,
        Login: LoginFall,
        Marketing: MarketingFall,
        OutlinedHouse: OutlinedHouseFall,
        PaymentHouse: PaymentHouseFall,
        SelectLoan: SelectLoanFall,
      },
      startDate: `09/22/${currentYear}`,
    },
    {
      endDate: `06/20/${currentYear}`,
      illustrations: {
        DesktopSelectLoan: DesktopSelectLoanSpring,
        Login: LoginSpring,
        Marketing: MarketingSpring,
        OutlinedHouse: OutlinedHouseSpring,
        PaymentHouse: PaymentHouseSpring,
        SelectLoan: SelectLoanSpring,
      },
      startDate: `03/20/${currentYear}`,
    },
    {
      endDate: `09/21/${currentYear}`,
      illustrations: {
        DesktopSelectLoan: DesktopSelectLoanSummer,
        Login: LoginSummer,
        Marketing: MarketingSummer,
        OutlinedHouse: OutlinedHouseSummer,
        PaymentHouse: PaymentHouseSummer,
        SelectLoan: SelectLoanSummer,
      },
      startDate: `06/21/${currentYear}`,
    },
    {
      endDate: `12/31/${currentYear}`,
      illustrations: {
        DesktopSelectLoan: DesktopSelectLoanWinter,
        Login: LoginWinter,
        Marketing: MarketingWinter,
        OutlinedHouse: OutlinedHouseWinter,
        PaymentHouse: PaymentHouseWinter,
        SelectLoan: SelectLoanWinter,
      },
      startDate: `12/21/${currentYear}`,
    },
    {
      endDate: `03/19/${currentYear}`,
      illustrations: {
        DesktopSelectLoan: DesktopSelectLoanWinter,
        Login: LoginWinter,
        Marketing: MarketingWinter,
        OutlinedHouse: OutlinedHouseWinter,
        PaymentHouse: PaymentHouseWinter,
        SelectLoan: SelectLoanWinter,
      },
      startDate: `01/01/${currentYear}`,
    },
  ];
  const formattedDate = moment(currentDate).format('L');
  const currentSeason = Object.values(seasonsArray).find((value) => (
    moment(formattedDate, 'MM/DD/YYYY').isBetween(
      moment(value.startDate, 'MM/DD/YYYY'), moment(value.endDate, 'MM/DD/YYYY'), 'days', '[]',
    )
  ));
  return currentSeason.illustrations;
};
