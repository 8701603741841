import React, { forwardRef } from 'react';
import T from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';

const BaseRecaptcha = forwardRef(({
  onChange,
  onExpired,
  sitekey,
  ...restProps
}, ref) => (
  <ReCAPTCHA
    ref={ref}
    onChange={onChange}
    onExpired={onExpired}
    sitekey={sitekey}
    {...restProps}
  />
));

BaseRecaptcha.propTypes = {
  onChange: T.func.isRequired,
  onExpired: T.func.isRequired,
  sitekey: T.string.isRequired,
};

export default BaseRecaptcha;
