/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

const SelectLoanWinter = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 287 120"
      x="0px"
      xmlns="http://www.w3.org/2000/Svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Path
          d="M30.932,113.678c0,0-13.353-2.736-13.353-21.036c0-22.499,25.853-37.001,36.075-51.238
				C69.019,20.002,75.881,12.969,95.819,6.75s49.295-4.308,65.026,2.826c7.007,3.177,20.853,10.609,35.121,11.707
				s48.434,15.02,63.616,33.495c15.423,18.768,10.426,53.779-19.572,58.717C240.01,113.495,30.657,113.404,30.932,113.678z"
          fill="#E7EBEE"
        />
        <Circle cx="90.256" cy="50.366" fill="#FFFFFF" r="1.84" />
        <Circle cx="38.483" cy="86.946" fill="#FFFFFF" r="1.84" />
        <Circle cx="221.371" cy="104.433" fill="#FFFFFF" r="1.84" />
        <Circle cx="91.542" cy="77.48" fill="#FFFFFF" r="1.84" />
        <Circle cx="186.863" cy="94.467" fill="#FFFFFF" r="1.84" />
        <Circle cx="94.551" cy="62.813" fill="#FFFFFF" r="1.424" />
        <Circle cx="261.076" cy="78.214" fill="#FFFFFF" r="1.424" />
        <Circle cx="253.014" cy="94.634" fill="#FFFFFF" r="1.84" />
        <Circle cx="244.102" cy="104.327" fill="#FFFFFF" r="1.424" />
        <Circle cx="37.753" cy="104.886" fill="#FFFFFF" r="1.256" />
        <Circle cx="38.571" cy="71.344" fill="#FFFFFF" r="1.424" />
        <Circle cx="105.361" cy="50.911" fill="#FFFFFF" r="0.79" />
        <Circle cx="27.92" cy="82.474" fill="#FFFFFF" r="0.79" />
        <Circle cx="78.758" cy="105.03" fill="#FFFFFF" r="0.79" />
        <Circle cx="97.034" cy="41.006" fill="#FFFFFF" r="0.79" />
        <Circle cx="253.119" cy="74.092" fill="#FFFFFF" r="0.79" />
        <Circle cx="213.618" cy="46.511" fill="#FFFFFF" r="0.79" />
        <Circle cx="79.639" cy="40.993" fill="#FFFFFF" r="1.594" />
        <Circle cx="249.853" cy="57.356" fill="#FFFFFF" r="1.594" />
        <Circle cx="28.216" cy="98.259" fill="#FFFFFF" r="1.594" />
        <Circle cx="99.156" cy="89.961" fill="#FFFFFF" r="1.594" />
        <Circle cx="122.238" cy="18.203" fill="#FFFFFF" r="1.594" />
        <Circle cx="208.558" cy="67.502" fill="#FFFFFF" r="1.603" />
        <Circle cx="189.815" cy="46.718" fill="#FFFFFF" r="2.22" />
        <Circle cx="194.562" cy="75.598" fill="#FFFFFF" r="1.061" />
        <Circle cx="203.074" cy="52.31" fill="#FFFFFF" r="1.432" />
        <Circle cx="189.429" cy="62.381" fill="#FFFFFF" r="1.89" />
      </G>
      <Rect fill="#E7EBEE" height="2.127" width="7.224" x="96.273" y="16.255" />
      <Path
        d="M104.082,14.143v1.97l-0.438,0.088v23.603l4.333-4.727V16.113c0,0,0.613-0.481,0.569-0.657
			c-0.044-0.175-0.219-1.138-0.219-1.138l-0.306-0.306L104.082,14.143z"
        fill="#BAC2CA"
      />
      <Path
        d="M103.586,40.143h-7.444c-0.209,0-0.378-0.169-0.378-0.378V16.214c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v23.173h6.904l4.215-4.415V16.284c0-0.209,0.169-0.378,0.378-0.378s0.378,0.169,0.378,0.378v18.839
			c0,0.097-0.038,0.191-0.105,0.261l-4.431,4.642C103.788,40.101,103.689,40.143,103.586,40.143z"
        fill="#010101"
      />
      <Path
        d="M103.566,33.972c-0.139,0-0.252-0.113-0.252-0.252V16.158c0-0.139,0.113-0.252,0.252-0.252
			s0.252,0.113,0.252,0.252v17.561C103.818,33.858,103.705,33.972,103.566,33.972z"
        fill="#010101"
      />
      <Path
        d="M107.916,16.454H95.849c-0.575,0-1.043-0.468-1.043-1.043v-0.727c0-0.575,0.468-1.043,1.043-1.043h12.067
			c0.575,0,1.043,0.468,1.043,1.043v0.727C108.959,15.987,108.491,16.454,107.916,16.454z M95.849,14.399
			c-0.158,0-0.286,0.128-0.286,0.286v0.727c0,0.158,0.128,0.286,0.286,0.286h12.067c0.157,0,0.286-0.128,0.286-0.286v-0.727
			c0-0.158-0.128-0.286-0.286-0.286H95.849z"
        fill="#010101"
      />
      <Path
        d="M104.018,16.123c-0.139,0-0.252-0.113-0.252-0.252v-1.726c0-0.139,0.113-0.252,0.252-0.252
			c0.139,0,0.252,0.113,0.252,0.252v1.726C104.27,16.009,104.157,16.123,104.018,16.123z"
        fill="#010101"
      />
      <Polygon fill="#FFFFFF" points="170.237,113.378 170.237,46.92 126.406,13.404 86.128,45.105 86.128,113.474 " />
      <Rect fill="#E7EBEE" height="65.682" width="25.536" x="169.251" y="47.561" />
      <Path
        d="M173.528,55.455h21.632v-1.921l-22.173-0.274l-3.496-3.292l-0.087,2.652l2.294,2.133
			C172.181,55.202,172.84,55.455,173.528,55.455z"
        fill="#BAC2CA"
      />
      <Path
        d="M194.567,113.284c-0.209,0-0.378-0.169-0.378-0.378V44.684c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v68.221C194.945,113.114,194.776,113.284,194.567,113.284z"
        fill="#010101"
      />
      <Path
        d="M201.583,53.334h-25.85v-3.291h25.85c0.846,0,1.532,0.686,1.532,1.532v0.228
			C203.115,52.649,202.429,53.334,201.583,53.334z"
        fill="#E7EBEE"
      />
      <Path
        d="M201.736,53.637h-27.723c-0.209,0-0.378-0.169-0.378-0.378s0.169-0.378,0.378-0.378h27.723
			c0.209,0,0.378,0.169,0.378,0.378S201.945,53.637,201.736,53.637z"
        fill="#010101"
      />
      <Path
        d="M201.313,53.622h-2.289c-0.209,0-0.378-0.169-0.378-0.378s0.169-0.378,0.378-0.378h2.289
			c0.752,0,1.363-0.548,1.363-1.222c0-0.674-0.612-1.222-1.363-1.222h-2.289c-0.209,0-0.378-0.169-0.378-0.378
			s0.169-0.378,0.378-0.378h2.289c1.169,0,2.12,0.888,2.12,1.979C203.433,52.734,202.482,53.622,201.313,53.622z"
        fill="#010101"
      />
      <G>
        <Polygon fill="#BAC2CA" points="201.609,49.527 153.512,11.772 126.793,11.772 175.408,50.043 171.073,50.043 	" />
        <Path
          d="M127.464,12.501c-0.344-0.348-0.849-0.544-1.346-0.535c-0.512,0.007-0.995,0.217-1.327,0.574L78.142,50.572
				c-0.638,0.688-0.549,1.72,0.199,2.306c0.749,0.586,1.873,0.504,2.51-0.183l45.337-36.618L172.905,52.7
				c0.351,0.355,0.834,0.536,1.318,0.536c0.427,0,0.856-0.14,1.197-0.425c0.728-0.608,0.781-1.642,0.12-2.311L127.464,12.501z"
          fill="#FFFFFF"
        />
        <Polygon
          display="none"
          fill="#FFFFFF"
          points="126.043,16.145 154.477,44.789 156.68,79.623 96.743,79.623 96.743,53.414
				91.766,53.414 	"
        />
        <Polygon fill="#BAC2CA" points="85.494,48.17 85.494,53.36 80.641,53.475 	" />
        <Path
          d="M85.483,53.682h-4.985c-0.209,0-0.378-0.169-0.378-0.378s0.169-0.378,0.378-0.378h4.985
				c0.209,0,0.378,0.169,0.378,0.378S85.692,53.682,85.483,53.682z"
          fill="#010101"
        />
        <Polygon fill="#BAC2CA" points="128.034,17.879 85.919,51.642 85.919,48.532 126.138,16.375 	" />
        <Path
          d="M202.048,50.421h-26.526c-0.209,0-0.378-0.169-0.378-0.378s0.169-0.378,0.378-0.378h25.435l-47.576-37.514
				H142.13c-0.209,0-0.378-0.169-0.378-0.378c0-0.209,0.169-0.378,0.378-0.378h11.382c0.085,0,0.168,0.028,0.234,0.081l48.536,38.271
				c0.126,0.1,0.176,0.269,0.123,0.421C202.352,50.319,202.209,50.421,202.048,50.421z"
          fill="#010101"
        />
        <Path
          d="M139.598,12.151h-1.659c-0.209,0-0.378-0.169-0.378-0.378c0-0.209,0.169-0.378,0.378-0.378h1.659
				c0.209,0,0.378,0.169,0.378,0.378C139.976,11.981,139.807,12.151,139.598,12.151z"
          fill="#010101"
        />
        <Path
          d="M126.08,12.215c-0.208,0-0.377-0.168-0.378-0.376c-0.001-0.209,0.167-0.38,0.376-0.381l9.505-0.064
				c0.001,0,0.002,0,0.003,0c0.208,0,0.377,0.168,0.378,0.376c0.001,0.209-0.167,0.38-0.376,0.381l-9.505,0.064
				C126.082,12.215,126.081,12.215,126.08,12.215z"
          fill="#010101"
        />
        <Path
          d="M79.507,53.648c-0.493,0-0.992-0.154-1.399-0.472c-0.45-0.352-0.721-0.847-0.765-1.394
				c-0.042-0.538,0.143-1.06,0.521-1.467l46.687-38.068c0.364-0.397,0.947-0.651,1.56-0.66c0.618,0.001,1.193,0.226,1.603,0.63
				l48.059,37.985c0.428,0.429,0.631,0.946,0.605,1.487c-0.026,0.546-0.281,1.047-0.717,1.411c-0.844,0.704-2.261,0.641-3.026-0.135
				L126.192,16.56L81.089,52.989C80.691,53.422,80.104,53.648,79.507,53.648z M126.147,12.343c-0.008,0-0.015,0-0.023,0
				c-0.409,0.006-0.793,0.171-1.055,0.454L78.381,50.865c-0.195,0.215-0.309,0.532-0.283,0.857c0.026,0.333,0.196,0.637,0.476,0.858
				c0.597,0.465,1.493,0.402,1.999-0.142l45.377-36.656c0.136-0.111,0.332-0.113,0.471-0.003l46.717,36.623
				c0.568,0.567,1.485,0.581,2.038,0.118c0.272-0.227,0.431-0.535,0.446-0.866c0.015-0.327-0.11-0.643-0.353-0.888l-48.042-37.968
				c-0.012-0.01-0.024-0.02-0.035-0.031C126.932,12.501,126.541,12.343,126.147,12.343z"
          fill="#010101"
        />
      </G>
      <Path
        d="M86.385,96.944c-0.782-0.128-1.584-0.194-2.401-0.194c-4.879,0-9.202,2.37-11.887,6.019
			c-0.538,0.731-1.483,1-2.342,0.706c-1.044-0.358-2.165-0.553-3.33-0.553c-3.243,0-6.135,1.5-8.021,3.844c0,0-2.056,2.693-2.22,6.214
			h30.307L86.385,96.944z"
        fill="#FFFFFF"
      />
      <Path
        d="M58.383,105.895c0,0,3.14-2.561,8.2-1.402s5.67-0.061,7.194-1.89s7.408-4.42,11.828-3.17v-2.408
			c0,0-5.152-1.128-9.481,1.433c-4.329,2.561-4.939,4.542-5.609,4.817s-0.945,0.427-3.018-0.152c-2.073-0.579-6.798,0.122-9.206,2.896
			"
        fill="#DCE2E7"
      />
      <Path
        d="M54.932,113.408c-0.004,0-0.009,0-0.013,0c-0.209-0.007-0.373-0.182-0.365-0.391
			c0.101-2.992,2.442-6.329,2.541-6.469c2.052-2.551,5.083-4.004,8.33-4.004c1.182,0,2.343,0.193,3.453,0.573
			c0.714,0.243,1.483,0.014,1.914-0.572c2.844-3.866,7.402-6.174,12.192-6.174c0.825,0,1.653,0.067,2.462,0.199
			c0.206,0.034,0.346,0.228,0.313,0.434c-0.034,0.207-0.226,0.342-0.434,0.313c-0.769-0.126-1.556-0.189-2.341-0.189
			c-4.55,0-8.88,2.192-11.582,5.865c-0.628,0.854-1.742,1.192-2.769,0.84c-1.031-0.353-2.11-0.532-3.208-0.532
			c-3.017,0-5.833,1.35-7.726,3.703c-0.009,0.014-2.295,3.276-2.389,6.039C55.303,113.247,55.135,113.408,54.932,113.408z"
        fill="#010101"
      />
      <Path
        d="M85.623,102.083c-0.209,0-0.378-0.169-0.378-0.378v-27.16c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v27.16C86.002,101.914,85.832,102.083,85.623,102.083z"
        fill="#010101"
      />
      <Path
        d="M85.623,72.027c-0.209,0-0.378-0.169-0.378-0.378V49.141c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v22.508C86.002,71.858,85.832,72.027,85.623,72.027z"
        fill="#010101"
      />
      <Path
        d="M169.141,113.358c-0.209,0-0.378-0.169-0.378-0.378V49.799c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v63.181C169.519,113.189,169.35,113.358,169.141,113.358z"
        fill="#010101"
      />
      <Path
        d="M81.639,104.044c0,0,5.672-1.403,8.713,0.234s3.684,4.093,5.263,4.853c1.579,0.76,2.982,0.468,4.503-0.526
			c1.52-0.994,6.9-0.526,8.128,3.508c0,0-1.111-5.789-6.374-5.497s-3.392,2.397-5.847-0.585
			C93.568,103.05,88.89,99.073,81.639,104.044z"
        fill="#DCE2E7"
      />
      <Path
        d="M108.525,113.666c-0.095,0-0.189-0.034-0.26-0.104c-0.088-0.088-0.128-0.203-0.116-0.312
			c0.044-1.733-0.583-3.358-1.768-4.581c-1.134-1.17-2.639-1.814-4.24-1.814c-1.206,0-2.369,0.369-3.363,1.067
			c-0.384,0.268-0.864,0.345-1.319,0.212c-0.483-0.143-0.886-0.511-1.078-0.985c-1.237-3.056-4.648-5.11-8.487-5.11
			c-2.992,0-5.776,1.252-7.448,3.349c-0.608,0.763-1.605,1.068-2.538,0.777c-0.842-0.261-1.724-0.394-2.622-0.394
			c-0.209,0-0.378-0.169-0.378-0.378s0.169-0.378,0.378-0.378c0.973,0,1.931,0.144,2.846,0.428c0.637,0.198,1.311-0.01,1.722-0.526
			c1.814-2.275,4.82-3.634,8.04-3.634c4.144,0,7.837,2.244,9.189,5.583c0.106,0.262,0.327,0.466,0.591,0.543
			c0.235,0.068,0.473,0.032,0.669-0.105c1.122-0.787,2.436-1.204,3.798-1.204c1.807,0,3.506,0.726,4.784,2.044
			c1.337,1.38,2.04,3.216,1.979,5.171c-0.003,0.086-0.035,0.169-0.091,0.235C108.737,113.625,108.63,113.666,108.525,113.666z"
        fill="#010101"
      />
      <G>
        <Path d="M75.287,105.392c-0.45,0-0.891,0.032-1.322,0.094" fill="#FFFFFF" />
        <Path
          d="M73.965,105.864c-0.186,0-0.347-0.136-0.374-0.325c-0.03-0.207,0.114-0.399,0.321-0.428
				c0.453-0.064,0.916-0.097,1.376-0.097c0.209,0,0.378,0.169,0.378,0.378s-0.169,0.378-0.378,0.378c-0.424,0-0.851,0.03-1.269,0.089
				C74,105.863,73.982,105.864,73.965,105.864z"
          fill="#010101"
        />
      </G>
      <Path
        d="M165.499,51.813H88.497c-0.139,0-0.252-0.113-0.252-0.252s0.113-0.252,0.252-0.252h77.002
			c0.139,0,0.252,0.113,0.252,0.252S165.638,51.813,165.499,51.813z"
        fill="#010101"
      />
      <Path
        d="M167.736,49.13h-20.797c-0.139,0-0.252-0.113-0.252-0.252c0-0.139,0.113-0.252,0.252-0.252h20.797
			c0.139,0,0.252,0.113,0.252,0.252C167.989,49.017,167.876,49.13,167.736,49.13z"
        fill="#010101"
      />
      <Path
        d="M144.307,49.13H92.709c-0.139,0-0.252-0.113-0.252-0.252c0-0.139,0.113-0.252,0.252-0.252h51.598
			c0.139,0,0.252,0.113,0.252,0.252C144.559,49.017,144.446,49.13,144.307,49.13z"
        fill="#010101"
      />
      <Path
        d="M66.984,113.323c-0.209,0-0.378-0.169-0.378-0.378V87.869c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v25.075C67.363,113.153,67.194,113.323,66.984,113.323z"
        fill="#010101"
      />
      <Path
        d="
			M44.369,113.468"
        fill="none"
        stroke="#010101"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.7569"
      />
      <Path
        d="
			M240.141,113.4"
        fill="none"
        stroke="#010101"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="0.6312"
      />
      <Ellipse cx="127.063" cy="35.665" fill="#F0F1F0" rx="4.145" ry="4.881" />
      <Path
        d="M126.291,41.403c-3.165,0-5.74-2.631-5.74-5.865s2.575-5.865,5.74-5.865c3.164,0,5.739,2.631,5.739,5.865
			S129.455,41.403,126.291,41.403z M126.291,30.43c-2.748,0-4.983,2.292-4.983,5.108s2.235,5.108,4.983,5.108
			c2.747,0,4.982-2.292,4.982-5.108S129.038,30.43,126.291,30.43z"
        fill="#010101"
      />
      <Path
        d="M127.055,41.212c-0.017,0-0.034-0.002-0.051-0.005c-2.722-0.56-4.697-2.921-4.697-5.614
			c0-1.911,0.98-3.691,2.621-4.761c0.117-0.075,0.273-0.043,0.349,0.073c0.076,0.117,0.043,0.273-0.074,0.349
			c-1.498,0.977-2.392,2.598-2.392,4.338c0,2.455,1.806,4.608,4.294,5.12c0.136,0.028,0.224,0.161,0.196,0.298
			C127.278,41.13,127.173,41.212,127.055,41.212z"
        fill="#010101"
      />
      <G>
        <Polyline fill={svgHighlight} points="136.623,102.895 136.623,71.237 153.922,71.237 153.922,102.895" />
        <Path
          d="M153.922,103.274c-0.209,0-0.378-0.169-0.378-0.378v-31.28h-16.542v31.28c0,0.209-0.169,0.378-0.378,0.378
				c-0.209,0-0.378-0.169-0.378-0.378V71.237c0-0.209,0.169-0.378,0.378-0.378h17.299c0.209,0,0.378,0.169,0.378,0.378v31.658
				C154.3,103.104,154.131,103.274,153.922,103.274z"
          fill="#010101"
        />
      </G>
      <Path
        d="M153.35,113.49h-26.919c-0.209,0-0.378-0.169-0.378-0.378v-5.152c0-0.209,0.169-0.378,0.378-0.378h4.514
			v-4.671c0-0.209,0.169-0.378,0.378-0.378h22.027c0.209,0,0.378,0.169,0.378,0.378v10.201
			C153.729,113.321,153.56,113.49,153.35,113.49z M126.81,112.733h26.163v-9.444h-21.27v4.671c0,0.209-0.169,0.378-0.378,0.378h-4.514
			V112.733z"
        fill="#010101"
      />
      <G>
        <Polygon
          fill="#BAC2CA"
          points="150.828,102.91 150.828,107.96 145.936,107.96 145.936,113.112 150.828,113.112 151.389,113.112
				156.994,113.112 156.994,102.91 	"
        />
        <Path
          d="M156.994,113.49h-11.058c-0.209,0-0.378-0.169-0.378-0.378v-5.152c0-0.209,0.169-0.378,0.378-0.378h4.514
				v-4.671c0-0.209,0.169-0.378,0.378-0.378h6.166c0.209,0,0.378,0.169,0.378,0.378v10.201
				C157.372,113.321,157.203,113.49,156.994,113.49z M146.315,112.733h10.301v-9.444h-5.409v4.671c0,0.209-0.169,0.378-0.378,0.378
				h-4.514V112.733z"
          fill="#010101"
        />
      </G>
      <Path
        d="M127.004,39.235c-0.139,0-0.252-0.113-0.252-0.252V31.63c0-0.139,0.113-0.252,0.252-0.252
			c0.139,0,0.252,0.113,0.252,0.252v7.353C127.256,39.122,127.143,39.235,127.004,39.235z"
        fill="#010101"
      />
      <Path
        d="M131.485,35.559h-7.682c-0.139,0-0.252-0.113-0.252-0.252s0.113-0.252,0.252-0.252h7.682
			c0.139,0,0.252,0.113,0.252,0.252S131.624,35.559,131.485,35.559z"
        fill="#010101"
      />
      <Rect fill="#E7EBEE" height="22.723" width="26.928" x="96.502" y="63.773" />
      <Polygon fill="#FFFFFF" opacity="0.7" points="95.341,81.849 101.896,63.773 95.987,63.773 95.341,65.255 " />
      <Polygon fill="#FFFFFF" opacity="0.7" points="103.515,86.496 99.839,86.496 108.417,63.773 112.094,63.773 " />
      <Path
        d="M123.649,87.054H95.233c-0.209,0-0.378-0.169-0.378-0.378V62.544c0-0.209,0.169-0.378,0.378-0.378h28.416
			c0.209,0,0.378,0.169,0.378,0.378v24.132C124.027,86.885,123.858,87.054,123.649,87.054z M95.612,86.297h27.659V62.922H95.612
			V86.297z"
        fill="#010101"
      />
      <Path
        d="M96.522,85.545c-0.139,0-0.252-0.113-0.252-0.252v-21.5c0-0.139,0.113-0.252,0.252-0.252h27.234
			c0.139,0,0.252,0.113,0.252,0.252s-0.113,0.252-0.252,0.252H96.774v21.248C96.774,85.432,96.661,85.545,96.522,85.545z"
        fill="#010101"
      />
      <Path
        d="M104.959,85.25c-0.209,0-0.378-0.169-0.378-0.378V73.097c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v11.774C105.338,85.081,105.169,85.25,104.959,85.25z"
        fill="#010101"
      />
      <Path
        d="M104.959,71.441c-0.209,0-0.378-0.169-0.378-0.378v-5.468c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v5.468C105.338,71.271,105.169,71.441,104.959,71.441z"
        fill="#010101"
      />
      <Path
        d="M113.894,78.943c-0.209,0-0.378-0.169-0.378-0.378V65.594c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v12.971C114.272,78.774,114.103,78.943,113.894,78.943z"
        fill="#010101"
      />
      <Path
        d="M113.894,85.25c-0.209,0-0.378-0.169-0.378-0.378V80.6c0-0.209,0.169-0.378,0.378-0.378
			c0.209,0,0.378,0.169,0.378,0.378v4.272C114.272,85.081,114.103,85.25,113.894,85.25z"
        fill="#010101"
      />
      <Path
        d="M123.635,75.177H98.221c-0.209,0-0.378-0.169-0.378-0.378c0-0.209,0.169-0.378,0.378-0.378h25.414
			c0.209,0,0.378,0.169,0.378,0.378C124.013,75.008,123.844,75.177,123.635,75.177z"
        fill="#010101"
      />
      <G>
        <Path
          d="M147.099,73.097h-17.687l1.169-2.676c0.222-0.508,0.483-0.995,0.781-1.456
				c1.304-2.025,3.502-3.303,5.882-3.666l0,0l16.593-0.049L147.099,73.097z"
          fill="#E7EBEE"
        />
        <Path
          d="M147.099,73.475h-17.688c-0.128,0-0.247-0.064-0.317-0.171c-0.07-0.107-0.081-0.242-0.03-0.359l1.169-2.677
				c0.228-0.522,0.501-1.03,0.809-1.51c1.315-2.043,3.554-3.441,6.143-3.835l16.649-0.054c0,0,0.001,0,0.001,0
				c0.147,0,0.282,0.086,0.343,0.22c0.062,0.134,0.04,0.293-0.056,0.405l-6.738,7.848C147.314,73.427,147.209,73.475,147.099,73.475z
				M129.989,72.719h16.936l6.086-7.089l-15.766,0.047c-2.319,0.358-4.368,1.632-5.565,3.492c-0.287,0.446-0.54,0.918-0.752,1.403
				L129.989,72.719z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M153.886,73.097h-7.84l1.169-2.676c0.455-1.042,1.076-1.994,1.833-2.824
				c1.108-1.214,2.636-1.964,4.26-2.212l0.569-0.087L153.886,73.097z"
          fill="#BAC2CA"
        />
        <Path
          d="M153.886,73.475h-7.84c-0.128,0-0.247-0.064-0.317-0.171c-0.07-0.107-0.081-0.242-0.03-0.359l1.169-2.677
				c0.47-1.076,1.11-2.061,1.901-2.928c1.125-1.233,2.717-2.061,4.483-2.331l0.57-0.087c0.108-0.017,0.219,0.015,0.303,0.087
				c0.084,0.072,0.132,0.176,0.132,0.287l0.008,7.799c0,0.101-0.04,0.197-0.111,0.268C154.082,73.436,153.986,73.475,153.886,73.475z
				M146.624,72.719h6.883l-0.007-6.98l-0.134,0.021c-1.595,0.244-3.029,0.987-4.038,2.093c-0.735,0.806-1.329,1.721-1.766,2.72
				L146.624,72.719z"
          fill="#010101"
        />
      </G>
      <Path
        d="M106.646,86.331c0-2.392-1.97-4.472-4.253-4.472c-1.778,0-3.289,1.178-3.873,2.828
			c-0.624-0.925-1.648-1.533-2.812-1.533c-1.779,0-3.244,1.412-3.427,3.225"
        fill="#FFFFFF"
      />
      <Path
        d="M92.282,86.329c-0.013,0-0.027-0.001-0.04-0.002c-0.208-0.022-0.359-0.208-0.336-0.416
			c0.207-1.949,1.842-3.418,3.804-3.418c1.025,0,2.002,0.417,2.715,1.139c0.778-1.442,2.304-2.375,3.969-2.375
			c2.467,0,4.631,2.172,4.631,4.648c0,0.209-0.169,0.378-0.378,0.378c-0.209,0-0.378-0.169-0.378-0.378
			c0-2.072-1.81-3.891-3.875-3.891c-1.561,0-2.975,0.986-3.518,2.453c-0.049,0.132-0.166,0.225-0.306,0.244
			c-0.136,0.017-0.277-0.042-0.358-0.157c-0.577-0.816-1.512-1.303-2.503-1.303c-1.574,0-2.885,1.178-3.051,2.741
			C92.637,86.185,92.473,86.329,92.282,86.329z"
        fill="#010101"
      />
      <Path
        d="M104.879,86.335c0-2.212,1.822-4.137,3.934-4.137c1.644,0,3.042,1.09,3.582,2.615
			c0.577-0.855,1.524-1.418,2.6-1.418c1.646,0,3,1.306,3.17,2.983"
        fill="#FFFFFF"
      />
      <Path
        d="M118.165,86.361c-0.191,0-0.355-0.144-0.376-0.339c-0.152-1.43-1.353-2.509-2.793-2.509
			c-0.907,0-1.764,0.446-2.292,1.194c-0.08,0.115-0.218,0.178-0.358,0.157c-0.139-0.018-0.257-0.112-0.306-0.244
			c-0.499-1.346-1.796-2.25-3.227-2.25c-1.894,0-3.556,1.669-3.556,3.57c0,0.209-0.169,0.378-0.378,0.378s-0.378-0.169-0.378-0.378
			c0-2.305,2.015-4.327,4.312-4.327c1.538,0,2.948,0.854,3.678,2.178c0.663-0.656,1.562-1.035,2.505-1.035
			c1.829,0,3.353,1.37,3.546,3.186c0.022,0.208-0.129,0.394-0.336,0.416C118.192,86.36,118.179,86.361,118.165,86.361z"
        fill="#010101"
      />
      <G>
        <Path d="M122.802,92.775H93.654c-1.549,0-2.804-1.256-2.804-2.804v-3.316h31.953V92.775z" fill="#E7EBEE" />
        <Path
          d="M122.802,93.154h-29.44c-1.594,0-2.891-1.297-2.891-2.891v-3.608c0-0.209,0.169-0.378,0.378-0.378h31.953
				c0.209,0,0.378,0.169,0.378,0.378v6.121C123.181,92.984,123.012,93.154,122.802,93.154z M91.228,87.033v3.229
				c0,1.177,0.958,2.135,2.135,2.135h29.061v-5.364H91.228z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M122.554,92.775h-0.391c-1.455,0-2.634-1.179-2.634-2.634v-3.486h5.66v3.486
				C125.188,91.596,124.009,92.775,122.554,92.775z"
          fill="#BAC2CA"
        />
        <Path
          d="M122.828,93.154h-0.939c-1.51,0-2.739-1.229-2.739-2.739v-3.76c0-0.209,0.169-0.378,0.378-0.378h5.66
				c0.209,0,0.378,0.169,0.378,0.378v3.76C125.567,91.925,124.338,93.154,122.828,93.154z M119.907,87.033v3.382
				c0,1.093,0.889,1.982,1.982,1.982h0.939c1.093,0,1.982-0.889,1.982-1.982v-3.382H119.907z"
          fill="#010101"
        />
      </G>
      <Path
        d="M143.018,108.209h-11.496c-0.139,0-0.252-0.113-0.252-0.252s0.113-0.252,0.252-0.252h11.496
			c0.139,0,0.252,0.113,0.252,0.252S143.158,108.209,143.018,108.209z"
        fill="#010101"
      />
      <G>
        <Circle cx="145.007" cy="79.184" fill="#FFFFFF" r="0.747" />
        <Path
          d="M145.006,80.183c-0.551,0-0.999-0.448-0.999-0.999c0-0.551,0.448-0.999,0.999-0.999
				c0.551,0,0.999,0.448,0.999,0.999C146.006,79.735,145.557,80.183,145.006,80.183z M145.006,78.69c-0.273,0-0.494,0.222-0.494,0.494
				c0,0.273,0.221,0.494,0.494,0.494c0.273,0,0.494-0.221,0.494-0.494C145.501,78.912,145.279,78.69,145.006,78.69z"
          fill="#010101"
        />
      </G>
      <Path
        d="M72.01,88.001H61.875c-0.209,0-0.378-0.169-0.378-0.378v-4.423c0-1.308,1.064-2.372,2.373-2.372h8.141
			c0.209,0,0.378,0.169,0.378,0.378v6.417C72.389,87.832,72.22,88.001,72.01,88.001z M62.254,87.244h9.378v-5.66h-7.763
			c-0.891,0-1.616,0.725-1.616,1.615V87.244z"
        fill="#010101"
      />
      <G>
        <Path
          d="M73.936,87.622h-4.492v-4.326c0-1.155,0.936-2.091,2.091-2.091h0.311c1.155,0,2.091,0.936,2.091,2.091
				V87.622z"
          fill="#BAC2CA"
        />
        <Path
          d="M73.935,88.001h-4.492c-0.209,0-0.378-0.169-0.378-0.378v-4.544c0-1.241,1.01-2.251,2.252-2.251h0.745
				c1.241,0,2.251,1.01,2.251,2.251v4.544C74.314,87.832,74.145,88.001,73.935,88.001z M69.822,87.244h3.735v-4.165
				c0-0.824-0.67-1.494-1.494-1.494h-0.745c-0.824,0-1.495,0.67-1.495,1.494V87.244z"
          fill="#010101"
        />
      </G>
      <Path
        d="M67.371,84.101c-0.161,0-0.311-0.104-0.361-0.266l-1.363-4.361l-0.714,0.259
			c-0.194,0.072-0.413-0.03-0.485-0.227c-0.071-0.196,0.03-0.413,0.227-0.485l1.086-0.395c0.097-0.035,0.204-0.029,0.297,0.017
			c0.092,0.046,0.162,0.127,0.193,0.226l1.481,4.739c0.062,0.2-0.049,0.412-0.248,0.474C67.446,84.096,67.408,84.101,67.371,84.101z"
        fill="#010101"
      />
      <Path
        d="M93.615,108.969c-0.089,0-0.175-0.047-0.221-0.131c-0.257-0.468-0.561-0.905-0.902-1.3
			c-0.091-0.105-0.08-0.265,0.026-0.356c0.105-0.091,0.264-0.08,0.356,0.026c0.364,0.422,0.688,0.889,0.962,1.387
			c0.067,0.122,0.023,0.276-0.099,0.343C93.698,108.959,93.656,108.969,93.615,108.969z"
        fill="#010101"
      />
      <Path
        d="M91.459,106.488c-0.052,0-0.105-0.016-0.15-0.05c-2.054-1.53-4.359-1.611-4.382-1.612
			c-0.139-0.003-0.249-0.119-0.245-0.259c0.004-0.139,0.112-0.26,0.259-0.245c0.1,0.003,2.483,0.083,4.669,1.712
			c0.112,0.083,0.135,0.241,0.052,0.353C91.611,106.452,91.536,106.488,91.459,106.488z"
        fill="#010101"
      />
      <G>
        <Path
          d="M136.974,93.822c0,0-1.349-0.033-3.916,1.448c-2.567,1.481-3.817,4.64-2.205,6.713
				c1.612,2.073,4.179,0.724,4.179,0.724l6.812,0.066c0,0,2.995-0.461,3.027-2.929c0.033-2.468-0.921-5.923-0.921-5.923
				L136.974,93.822z"
          fill="#FFFFFF"
        />
        <G>
          <Path
            d="M145.835,92.192c0.836-2.955,0.166-4.067,0.166-4.067c-2.073,0.049-3.319,1.308-3.319,1.308
					c-1.433-0.539-3.144,0-3.144,0s-1.246-1.259-3.319-1.308c0,0-0.67,1.113,0.166,4.067c0,0-1.226,2.983,1.691,4.116
					s4.813,0.4,6.169-0.084c1.357-0.483,2.295-1.979,1.812-3.419L145.835,92.192z"
            fill="#FFFFFF"
          />
          <Path
            d="M141.045,97.283c-0.897,0-1.926-0.163-3.107-0.622c-0.944-0.367-1.593-0.952-1.931-1.738
					c-0.49-1.144-0.154-2.355-0.021-2.746c-0.806-2.938-0.169-4.12-0.093-4.247c0.07-0.116,0.197-0.195,0.333-0.183
					c1.778,0.042,2.983,0.907,3.41,1.267c0.497-0.123,1.771-0.372,2.957-0.01c0.437-0.366,1.637-1.215,3.399-1.257
					c0.133-0.015,0.263,0.067,0.333,0.183c0.076,0.126,0.713,1.309-0.094,4.248l0.181,0.499c0.526,1.568-0.39,3.316-2.041,3.905
					l-0.105,0.038C143.461,96.907,142.407,97.283,141.045,97.283z M136.47,88.517c-0.122,0.416-0.302,1.52,0.279,3.573
					c0.023,0.081,0.018,0.168-0.014,0.246c-0.005,0.012-0.489,1.23-0.029,2.294c0.255,0.591,0.762,1.037,1.507,1.327
					c2.711,1.053,4.495,0.416,5.8-0.05l0.106-0.038c1.107-0.395,2.006-1.671,1.58-2.943l-0.219-0.605
					c-0.027-0.074-0.03-0.155-0.008-0.232c0.579-2.048,0.401-3.152,0.278-3.573c-1.73,0.136-2.788,1.173-2.799,1.183
					c-0.105,0.105-0.262,0.14-0.401,0.088c-1.288-0.484-2.882,0.003-2.898,0.007c-0.135,0.042-0.282,0.005-0.382-0.094
					C139.259,89.689,138.201,88.653,136.47,88.517z"
            fill="#010101"
          />
        </G>
        <Path
          d="M136.477,92.511c-0.037,0-0.074-0.008-0.109-0.025c-0.126-0.06-0.178-0.211-0.118-0.337
				c0.489-1.017,1.273-1.66,1.66-1.933c-0.661-0.893-1.662-1.66-1.673-1.668c-0.111-0.085-0.132-0.243-0.047-0.354
				c0.084-0.111,0.242-0.132,0.354-0.047c0.05,0.038,1.239,0.951,1.933,1.999c0.039,0.058,0.051,0.129,0.035,0.197
				s-0.059,0.126-0.119,0.161c-0.011,0.006-1.102,0.647-1.687,1.865C136.661,92.459,136.571,92.511,136.477,92.511z"
          fill="#010101"
        />
        <Path
          d="M145.745,92.511c-0.094,0-0.184-0.053-0.227-0.143c-0.585-1.218-1.677-1.859-1.688-1.865
				c-0.06-0.035-0.104-0.093-0.119-0.161c-0.016-0.068-0.004-0.139,0.035-0.197c0.695-1.047,1.883-1.96,1.933-1.998
				c0.111-0.085,0.27-0.064,0.354,0.047c0.085,0.111,0.063,0.269-0.047,0.354c-0.011,0.008-1.012,0.778-1.673,1.669
				c0.388,0.273,1.172,0.917,1.661,1.933c0.06,0.126,0.007,0.277-0.118,0.337C145.818,92.503,145.781,92.511,145.745,92.511z"
          fill="#010101"
        />
        <Path
          d="M141.574,94.671h-0.741c-0.139,0-0.252-0.113-0.252-0.252c0-0.139,0.113-0.252,0.252-0.252h0.741
				c0.139,0,0.252,0.113,0.252,0.252C141.826,94.558,141.713,94.671,141.574,94.671z"
          fill="#010101"
        />
        <Path
          d="M140.603,95.687c-0.004,0-0.009,0-0.014,0c-0.639-0.01-0.861-0.504-0.888-0.764
				c-0.015-0.139,0.086-0.263,0.224-0.277c0.135-0.023,0.261,0.084,0.277,0.221c0.01,0.073,0.067,0.311,0.395,0.316
				c0.001,0,0.002,0,0.003,0c0.307,0,0.344-0.712,0.344-0.719c0.004-0.139,0.124-0.229,0.258-0.246
				c0.139,0.003,0.249,0.118,0.247,0.257C141.44,94.926,141.251,95.687,140.603,95.687z"
          fill="#010101"
        />
        <Path
          d="M141.727,95.687c-0.648,0-0.837-0.761-0.847-1.213c-0.003-0.139,0.108-0.254,0.247-0.257
				c0.143,0.01,0.255,0.107,0.258,0.246c0,0.007,0.037,0.719,0.344,0.719c0.001,0,0.002,0,0.003,0
				c0.328-0.005,0.385-0.243,0.395-0.316c0.018-0.136,0.144-0.234,0.28-0.219c0.137,0.016,0.235,0.138,0.221,0.275
				c-0.028,0.26-0.248,0.755-0.888,0.765C141.736,95.687,141.731,95.687,141.727,95.687z"
          fill="#010101"
        />
        <Circle cx="138.748" cy="93.333" fill="#010101" r="0.308" />
        <Circle cx="143.511" cy="93.234" fill="#010101" r="0.308" />
        <Path
          d="M133.555,103.422c-0.035,0-0.07,0-0.106-0.001c-1.404-0.031-2.568-0.532-3.195-1.375
				c-0.608-0.818-0.729-1.911-0.36-3.248c1.237-4.482,5.981-5.268,6.182-5.3c0.206-0.034,0.399,0.109,0.432,0.315
				c0.032,0.207-0.109,0.4-0.315,0.433c-0.045,0.007-4.464,0.749-5.569,4.753c-0.304,1.102-0.224,1.974,0.238,2.595
				c0.486,0.654,1.436,1.044,2.604,1.07c1.008,0.035,1.715-0.283,2.849-1.208l0.321-0.336c0.071-0.073,0.109-0.168,0.107-0.27
				c-0.002-0.102-0.043-0.196-0.116-0.266c-0.141-0.136-0.365-0.137-0.512-0.005c-1.481,1.347-2.987,1.421-3.902,1.042
				c-1.007-0.417-1.188-1.372-1.144-1.82c0.021-0.208,0.209-0.356,0.414-0.339c0.207,0.021,0.358,0.205,0.339,0.411
				c-0.006,0.078-0.038,0.751,0.68,1.048c0.668,0.277,1.883,0.207,3.103-0.903c0.44-0.399,1.119-0.391,1.545,0.019
				c0.219,0.21,0.343,0.494,0.349,0.798s-0.108,0.592-0.319,0.811l-0.354,0.366C135.752,102.891,134.84,103.421,133.555,103.422z"
          fill="#010101"
        />
        <Path
          d="M140.51,103.264c-2.386,0-5.416-0.028-5.416-0.028l0.007-0.757c0,0,3.027,0.028,5.409,0.028
				c0.573,0,1.036-0.008,1.41-0.02c0.616-0.019,1.099-0.521,1.099-1.141c0-0.308-0.12-0.596-0.337-0.812
				c-0.216-0.214-0.546-0.338-0.803-0.329c-1.037,0.005-1.852-0.036-2.408-0.125c-0.206-0.033-0.347-0.227-0.314-0.433
				c0.033-0.206,0.225-0.349,0.433-0.314c0.507,0.081,1.3,0.127,2.284,0.115c0.501,0.015,0.982,0.192,1.341,0.549
				c0.362,0.36,0.561,0.839,0.561,1.349c0,1.032-0.805,1.866-1.832,1.898C141.564,103.257,141.093,103.264,140.51,103.264z"
          fill="#010101"
        />
        <Path
          d="M142.497,103.105c-0.187,0-0.349-0.138-0.374-0.329c-0.028-0.207,0.118-0.397,0.325-0.425
				c0.633-0.084,1.107-0.368,1.447-0.867c1.16-1.7,0.322-5.193,0.313-5.229c-0.05-0.203,0.074-0.408,0.277-0.458
				c0.199-0.05,0.408,0.074,0.458,0.277c0.039,0.157,0.928,3.855-0.422,5.835c-0.462,0.678-1.126,1.078-1.974,1.191
				C142.53,103.104,142.513,103.105,142.497,103.105z"
          fill="#010101"
        />
      </G>
      <Path
        d="M33.963,113.811h-2.68c-0.223,0-0.403-0.18-0.403-0.403s0.18-0.403,0.403-0.403h2.68
			c0.223,0,0.403,0.18,0.403,0.403S34.185,113.811,33.963,113.811z"
        fill="#010101"
      />
      <Path
        d="M246.8,113.403c0,0.224-0.179,0.412-0.403,0.412H36.275c-0.224,0-0.403-0.188-0.403-0.412
			c0-0.224,0.179-0.403,0.403-0.403h210.121C246.621,113,246.8,113.179,246.8,113.403z"
        fill="#010101"
      />
      <G>
        <Path
          d="M252.129,113.72h-2.68c-0.223,0-0.403-0.18-0.403-0.403c0-0.223,0.18-0.403,0.403-0.403h2.68
				c0.223,0,0.403,0.18,0.403,0.403C252.532,113.54,252.352,113.72,252.129,113.72z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M110.246,92.153v1.964c0,0.268,0.147,1.426-0.486,1.424l0,0c-0.538-0.007-0.486-1.156-0.486-1.424v-0.021
				c0-0.259-0.21-0.468-0.468-0.468l0,0c-0.259,0-0.468,0.21-0.468,0.468v1.792c0,0.276-0.224,0.5-0.5,0.5l0,0
				c-0.276,0-0.5-0.224-0.5-0.5v-2.062c0-0.258-0.209-0.467-0.467-0.467l0,0c-0.258,0-0.467,0.209-0.467,0.467v0.212
				c0,0.266-0.215,0.481-0.481,0.481l0,0c-0.266,0-0.481-0.215-0.481-0.481v-2.621"
          fill="#E7EBEE"
        />
        <Path
          d="M107.837,96.612c-0.4,0-0.725-0.325-0.725-0.724v-2.062c0-0.134-0.108-0.243-0.242-0.243
				c-0.134,0-0.242,0.109-0.242,0.243v0.212c0,0.389-0.317,0.704-0.705,0.704c-0.388,0-0.704-0.316-0.704-0.704v-1.405
				c0-0.124,0.101-0.224,0.224-0.224s0.224,0.1,0.224,0.224v1.405c0,0.141,0.115,0.256,0.256,0.256c0.142,0,0.257-0.115,0.257-0.256
				v-0.212c0-0.381,0.31-0.691,0.69-0.691c0.381,0,0.69,0.31,0.69,0.691v2.062c0,0.152,0.124,0.276,0.277,0.276
				s0.276-0.124,0.276-0.276v-1.792c0-0.382,0.311-0.693,0.692-0.693s0.692,0.311,0.692,0.693v0.959c0,0.144,0.118,0.262,0.262,0.262
				c0.144,0,0.262-0.118,0.262-0.262V92.6c0-0.124,0.101-0.224,0.224-0.224s0.224,0.1,0.224,0.224v2.456c0,0.392-0.319,0.71-0.71,0.71
				c-0.392,0-0.71-0.318-0.71-0.71v-0.959c0-0.135-0.109-0.245-0.244-0.245s-0.244,0.11-0.244,0.245v1.792
				C108.561,96.288,108.237,96.612,107.837,96.612z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M181.96,52.313V55.3c0,0.5-0.274,2.655,0.905,2.652l0,0c1.002-0.014,0.905-2.152,0.905-2.652v-0.039
				c0-0.482,0.39-0.872,0.872-0.872l0,0c0.482,0,0.872,0.39,0.872,0.872v3.336c0,0.514,0.417,0.931,0.931,0.931l0,0
				c0.514,0,0.931-0.417,0.931-0.931v-3.839c0-0.48,0.389-0.869,0.869-0.869l0,0c0.48,0,0.869,0.389,0.869,0.869v0.395
				c0,0.494,0.401,0.895,0.895,0.895l0,0c0.494,0,0.895-0.401,0.895-0.895V51.86"
          fill="#E7EBEE"
        />
        <Path
          d="M186.444,59.945c-0.744,0-1.348-0.605-1.348-1.349V55.26c0-0.251-0.204-0.454-0.455-0.454
				c-0.251,0-0.454,0.204-0.454,0.454v1.787c0,0.729-0.593,1.322-1.322,1.322s-1.322-0.593-1.322-1.322v-3.753
				c0-0.23,0.187-0.417,0.417-0.417c0.23,0,0.417,0.187,0.417,0.417v3.753c0,0.269,0.219,0.488,0.488,0.488s0.488-0.219,0.488-0.488
				V55.26c0-0.71,0.578-1.288,1.288-1.288c0.711,0,1.289,0.578,1.289,1.288v3.336c0,0.283,0.23,0.515,0.514,0.515
				c0.283,0,0.515-0.231,0.515-0.515v-3.839c0-0.709,0.577-1.285,1.285-1.285c0.709,0,1.286,0.577,1.286,1.285v0.395
				c0,0.264,0.214,0.478,0.478,0.478c0.263,0,0.477-0.214,0.477-0.478v-1.923c0-0.23,0.187-0.417,0.417-0.417
				c0.23,0,0.417,0.187,0.417,0.417v1.923c0,0.723-0.588,1.312-1.311,1.312c-0.723,0-1.312-0.589-1.312-1.312v-0.395
				c0-0.248-0.203-0.451-0.452-0.451c-0.248,0-0.451,0.203-0.451,0.451v3.839C187.793,59.34,187.188,59.945,186.444,59.945z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M101.798,34.717v5.464c0,0.274-0.222,0.495-0.495,0.495l0,0c-0.274,0-0.495-0.222-0.495-0.495v-0.81
				c0-0.252-0.204-0.456-0.456-0.456l0,0c-0.252,0-0.456,0.204-0.456,0.456v2.424c0,0.285-0.231,0.516-0.516,0.516l0,0
				c-0.285,0-0.516-0.231-0.516-0.516v-4.63"
          fill="#FFFFFF"
        />
        <Path
          d="M99.38,42.535c-0.408,0-0.74-0.332-0.74-0.74v-4.56c0-0.124,0.1-0.224,0.224-0.224
				c0.124,0,0.224,0.1,0.224,0.224v4.56c0,0.161,0.131,0.292,0.292,0.292c0.161,0,0.292-0.131,0.292-0.292v-2.424
				c0-0.375,0.305-0.68,0.68-0.68s0.68,0.305,0.68,0.68v0.81c0,0.15,0.122,0.271,0.271,0.271s0.271-0.122,0.271-0.271v-4.918
				c0-0.124,0.1-0.224,0.224-0.224c0.124,0,0.224,0.1,0.224,0.224v4.918c0,0.396-0.322,0.719-0.719,0.719s-0.719-0.323-0.719-0.719
				v-0.81c0-0.128-0.104-0.232-0.232-0.232c-0.128,0-0.232,0.104-0.232,0.232v2.424C100.12,42.203,99.788,42.535,99.38,42.535z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M158.949,40.768v3.774c0,0.268-0.147,0.56,0.486,0.558l0,0c0.538-0.007,0.486-0.745,0.486-1.013v-0.021
				c0-0.259,0.21-0.468,0.468-0.468l0,0c0.259,0,0.468,0.21,0.468,0.468v2.247c0,0.276,0.224,0.5,0.5,0.5l0,0
				c0.276,0,0.5-0.224,0.5-0.5V45c0-0.258,0.209-0.467,0.467-0.467l0,0c0.258,0,0.467,0.209,0.467,0.467v0.895
				c0,0.266,0.215,0.481,0.481,0.481l0,0c0.266,0,0.481-0.215,0.481-0.481v-2.391"
          fill="#FFFFFF"
        />
        <Path
          d="M161.358,47.038c-0.4,0-0.724-0.325-0.724-0.724v-2.247c0-0.135-0.109-0.244-0.244-0.244
				s-0.244,0.109-0.244,0.244v0.549c0,0.392-0.319,0.71-0.711,0.71c-0.391,0-0.71-0.319-0.71-0.71v-3.069
				c0-0.124,0.101-0.224,0.224-0.224c0.123,0,0.224,0.1,0.224,0.224v3.069c0,0.144,0.117,0.262,0.262,0.262s0.262-0.118,0.262-0.262
				v-0.549c0-0.382,0.311-0.692,0.692-0.692s0.692,0.311,0.692,0.692v2.247c0,0.152,0.124,0.276,0.276,0.276
				c0.152,0,0.277-0.124,0.277-0.276V45c0-0.381,0.31-0.69,0.69-0.69c0.381,0,0.69,0.31,0.69,0.69v0.895
				c0,0.142,0.116,0.257,0.257,0.257c0.142,0,0.256-0.115,0.256-0.257V45.02c0-0.124,0.101-0.224,0.224-0.224
				c0.123,0,0.224,0.1,0.224,0.224v0.875c0,0.389-0.316,0.705-0.704,0.705c-0.388,0-0.705-0.316-0.705-0.705V45
				c0-0.133-0.109-0.242-0.242-0.242c-0.134,0-0.242,0.109-0.242,0.242v1.314C162.083,46.713,161.758,47.038,161.358,47.038z"
          fill="#010101"
        />
      </G>
      <Circle cx="63.709" cy="41.796" fill="#FFFFFF" r="1.455" />
      <G>
        <Path
          d="M233.138,80.829l0.011,0c-6.06-36.583-14.563-34.944-14.563-34.944l-0.013,0.124l-0.011-0.094
				c0,0-8.161-1.668-14.221,34.915l0.035-0.001c-0.18,1.119-0.28,2.273-0.28,3.457c0,9.872,6.564,17.874,14.66,17.874
				s14.66-8.003,14.66-17.874C233.418,83.102,233.318,81.948,233.138,80.829z"
          fill="#DCE2E7"
        />
        <Path
          d="M233.418,84.285c0-1.183-0.1-2.337-0.28-3.456l0.011,0c-6.06-36.583-14.563-34.944-14.563-34.944
				l-0.013,0.124l-0.011-0.094c0,0-0.523-0.106-1.401,0.327c2.161,4.394,9.006,19.305,9.684,33.208
				c0.622,12.75-3.596,19.332-8.214,22.703c0.042,0,0.084,0.008,0.126,0.008C226.854,102.16,233.418,94.157,233.418,84.285z"
          fill="#BAC2CA"
        />
        <Path
          d="M211.702,53.382c0,0,0.942,4.274,5.722,4.563c4.781,0.29,11.083,4.129,12.821,9.054
				c0,0-2.897-13.401-7.678-18.399s-7.026-0.942-7.678-0.072C214.237,49.398,211.702,53.382,211.702,53.382z"
          fill="#FFFFFF"
        />
        <Path
          d="M218.758,102.495c-8.269,0-14.997-8.169-14.997-18.21c0-1.112,0.088-2.246,0.262-3.374
				c-0.015-0.044-0.021-0.09-0.013-0.136c4.222-25.49,9.528-32.512,12.315-34.429c1.066-0.733,1.829-0.793,2.15-0.778
				c0.016-0.006,0.032-0.01,0.048-0.013c0.103-0.02,1.042-0.164,2.489,0.842c2.86,1.991,8.284,9.105,12.47,34.377
				c0.004,0.028,0.005,0.057,0.003,0.085c0.179,1.145,0.27,2.296,0.27,3.425C233.754,94.326,227.027,102.495,218.758,102.495z
				M204.695,80.75c0.015,0.043,0.02,0.088,0.013,0.133c-0.182,1.138-0.275,2.282-0.275,3.403c0,9.671,6.426,17.538,14.325,17.538
				c7.898,0,14.324-7.867,14.324-17.538c0-1.12-0.093-2.265-0.275-3.402c-0.004-0.027-0.005-0.055-0.003-0.081
				c-5.387-32.448-12.691-34.484-13.966-34.584c-0.061,0.078-0.156,0.128-0.262,0.129c-0.109-0.02-0.184-0.039-0.246-0.104
				c-0.275,0.015-0.879,0.126-1.711,0.719C213.944,48.872,208.841,55.801,204.695,80.75z"
          fill="#010101"
        />
        <Path
          d="M218.574,113.82c-0.186,0-0.336-0.151-0.336-0.336v-29.39c0-0.186,0.15-0.336,0.336-0.336
				s0.336,0.15,0.336,0.336v29.39C218.91,113.669,218.76,113.82,218.574,113.82z"
          fill="#010101"
        />
        <Path
          d="M218.416,91.785c-0.067,0-0.136-0.02-0.195-0.062l-5.352-3.812c-0.151-0.108-0.186-0.318-0.079-0.469
				c0.108-0.151,0.317-0.186,0.469-0.079l5.352,3.812c0.151,0.108,0.186,0.318,0.079,0.469
				C218.624,91.736,218.521,91.785,218.416,91.785z"
          fill="#010101"
        />
        <Path
          d="M218.709,96.257c-0.105,0-0.208-0.049-0.274-0.141c-0.108-0.151-0.073-0.361,0.079-0.469l5.351-3.812
				c0.151-0.108,0.36-0.072,0.469,0.079c0.108,0.151,0.073,0.361-0.079,0.469l-5.351,3.812
				C218.844,96.236,218.776,96.257,218.709,96.257z"
          fill="#010101"
        />
        <Path
          d="M208.96,71.987c-0.011,0-0.023-0.001-0.035-0.003c-0.122-0.019-0.206-0.134-0.186-0.256
				c0.815-5.163,2.527-10.35,3.819-13.791c0.044-0.116,0.171-0.176,0.289-0.131c0.116,0.043,0.174,0.172,0.131,0.288
				c-1.285,3.422-2.987,8.578-3.797,13.704C209.163,71.909,209.068,71.987,208.96,71.987z"
          fill="#010101"
        />
        <Path
          d="M217.644,64.092L217.644,64.092c-0.7,0-1.268-0.568-1.268-1.268v-5.288h2.535v5.288
				C218.912,63.524,218.344,64.092,217.644,64.092z"
          fill="#FFFFFF"
        />
        <Path
          d="M221.266,61.904L221.266,61.904c-0.457,0-0.828-0.371-0.828-0.828v-3.452h1.655v3.452
				C222.093,61.533,221.723,61.904,221.266,61.904z"
          fill="#FFFFFF"
        />
      </G>
      <Circle cx="241.559" cy="76.79" fill="#FFFFFF" r="2.432" />
      <Circle cx="64.425" cy="61.389" fill="#FFFFFF" r="1.84" />
      <Circle cx="56.938" cy="72.08" fill="#FFFFFF" r="1.84" />
      <Circle cx="78.758" cy="33.274" fill="#FFFFFF" r="1.424" />
      <Circle cx="175.625" cy="21.882" fill="#FFFFFF" r="1.424" />
      <Circle cx="83.326" cy="23.099" fill="#FFFFFF" r="1.84" />
      <Circle cx="92.096" cy="30.43" fill="#FFFFFF" r="1.424" />
      <Circle cx="50.727" cy="96.664" fill="#FFFFFF" r="0.79" />
      <Circle cx="76.284" cy="69.552" fill="#FFFFFF" r="0.79" />
      <Circle cx="181.131" cy="26.907" fill="#FFFFFF" r="0.79" />
      <Circle cx="53.827" cy="59.122" fill="#FFFFFF" r="0.79" />
      <Circle cx="201.039" cy="65.249" fill="#FFFFFF" r="0.79" />
      <Circle cx="229.116" cy="44.498" fill="#FFFFFF" r="0.79" />
      <Circle cx="196.53" cy="26.907" fill="#FFFFFF" r="1.603" />
      <Circle cx="204.506" cy="37.78" fill="#FFFFFF" r="2.22" />
      <Circle cx="127.063" cy="6.069" fill="#FFFFFF" r="1.061" />
      <Circle cx="198.882" cy="99.329" fill="#FFFFFF" r="1.432" />
      <Circle cx="114.871" cy="10.26" fill="#FFFFFF" r="1.89" />
      <G>
        <Path
          d="M98.5,114c0,0-22.64-4.298-28.817-4.94c-6.177-0.642-12.137-1.418-20.295,1.043
				c-10.614,3.202-16.766,3.88-16.766,3.88l-0.555,2.312H99.46L98.5,114z"
          fill="#FFFFFF"
        />
        <Path
          d="M98.346,113.781c0,0-23.47-0.813-27.819-2.031c-8.621-2.415-11.712-4.089-25.353-0.42
				c0,0,12.684-3.665,19.93-2.773C72.351,109.447,94.656,113.156,98.346,113.781z"
          fill="#DCE2E7"
        />
        <Path
          d="M98.361,114.153c-0.023,0-0.046-0.002-0.069-0.007c-0.218-0.041-21.845-4.074-27.936-4.708l-0.804-0.084
				c-5.972-0.628-11.614-1.22-19.345,1.114c-10.561,3.184-14.136,2.996-14.283,2.992c-0.204-0.013-0.359-0.189-0.347-0.393
				c0.012-0.205,0.177-0.385,0.394-0.349c0.035,0.001,3.645,0.169,14.021-2.963c7.875-2.377,13.855-1.747,19.638-1.141l0.802,0.084
				c6.121,0.636,27.778,4.676,27.996,4.717c0.202,0.037,0.335,0.231,0.297,0.434C98.693,114.028,98.536,114.153,98.361,114.153z"
          fill="#010101"
        />
      </G>
      <G>
        <Path
          d="M115.143,114.934c0,0,4.952-1.951,8.804-2.351s7.503-0.65,12.656,0.65c5.152,1.301,8.603,1.712,8.603,1.712
				"
          fill="#FFFFFF"
        />
        <Path
          d="M112.576,115.767c0,0,8.903-0.879,11.649-1.504c2.746-0.626,6.417-2.058,15.942-0.111
				c0,0-8.308-2.433-12.826-1.877C122.821,112.83,115.287,114.376,112.576,115.767z"
          fill="#DCE2E7"
        />
        <Path
          d="M112.296,115.991c-0.092,0-0.178-0.057-0.211-0.149c-0.042-0.116,0.019-0.245,0.136-0.286
				c0.319-0.114,7.869-2.797,11.704-3.196l0.505-0.053c3.603-0.379,7.331-0.771,12.238,0.711c6.472,1.952,8.724,1.857,8.746,1.847
				c0.128,0.005,0.229,0.087,0.237,0.21c0.008,0.123-0.086,0.23-0.209,0.237c-0.091,0.007-2.323,0.119-8.903-1.866
				c-4.821-1.455-8.338-1.084-12.062-0.695l-0.505,0.053c-3.781,0.393-11.522,3.144-11.599,3.172
				C112.346,115.986,112.321,115.991,112.296,115.991z"
          fill="#010101"
        />
      </G>
      <Circle cx="226.469" cy="78.609" fill="#FFFFFF" r="1.308" />
      <Circle cx="209.49" cy="81.802" fill="#FFFFFF" r="2.432" />
      <Circle cx="215.501" cy="70.514" fill="#FFFFFF" r="0.961" />
      <G>
        <Path
          d="M235.563,115.332c0,0-19.686-3.678-25.267-4.258s-10.966-1.281-18.337,0.942
				c-9.59,2.893-15.649,3.053-15.649,3.053"
          fill="#FFFFFF"
        />
        <Path
          d="M235.55,115.332c0,0-21.206-0.734-25.135-1.835c-7.789-2.182-10.582-3.694-22.906-0.38
				c0,0,11.46-3.312,18.007-2.506S232.216,114.767,235.55,115.332z"
          fill="#DCE2E7"
        />
        <Path
          d="M235.563,115.668c-0.02,0-0.041-0.002-0.062-0.006c-0.196-0.037-19.737-3.682-25.24-4.253l-0.725-0.076
				c-5.398-0.566-10.494-1.102-17.479,1.006c-9.541,2.877-12.776,2.71-12.904,2.703c-0.186-0.011-0.326-0.171-0.314-0.355
				c0.012-0.185,0.178-0.343,0.355-0.315c0.034,0.001,3.298,0.15,12.668-2.677c7.116-2.146,12.519-1.579,17.743-1.031l0.725,0.076
				c5.53,0.574,25.097,4.225,25.295,4.262c0.182,0.033,0.302,0.209,0.268,0.392C235.863,115.555,235.722,115.668,235.563,115.668z"
          fill="#010101"
        />
      </G>
      <Circle cx="228.763" cy="66.255" fill="#FFFFFF" r="1.308" />
      <Circle cx="217.796" cy="58.16" fill="#FFFFFF" r="0.961" />
    </Svg>
  );
};

export default SelectLoanWinter;
