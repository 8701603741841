/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function PaymentHouseSpring(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 100.25 70.25"
      height={moderateScale(70)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 100.25 70.25"
      width={moderateScale(100)}
      x="0px"
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Polyline fill="#E6EBEF" points="26.241,56.992 26.241,31.854 51.334,31.854 51.334,56.992" />
        <Rect fill="#BAC2CA" height="10.32" width="5.085" x="30.613" y="40.919" />
        <Rect fill={svgHighlight} height="16.326" width="6.235" x="40.323" y="40.689" />
        <Polygon fill="#BAC2CA" points="47.705,36.518 22.085,36.518 34.781,22.78 60.4,22.78" />
        <Path
          d="M47.705,36.893H22.086c-0.149,0-0.284-0.088-0.344-0.225c-0.06-0.137-0.033-0.296,0.068-0.405l12.695-13.738
        c0.071-0.077,0.171-0.121,0.275-0.121h8.349c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-8.185L22.942,36.143
        h24.598l12.002-12.989H45.891c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375H60.4c0.149,0,0.284,0.088,0.344,0.225
        c0.06,0.137,0.033,0.295-0.068,0.405L47.98,36.773C47.909,36.85,47.809,36.893,47.705,36.893z"
          fill="#000000"
        />
        <Path
          d="M72.445,36.893h-2.426c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.599L60.131,23.027
        c-0.137-0.156-0.121-0.393,0.035-0.529c0.156-0.135,0.393-0.121,0.529,0.035l12.031,13.739c0.097,0.11,0.12,0.268,0.06,0.402
        C72.725,36.808,72.592,36.893,72.445,36.893z"
          fill="#000000"
        />
        <Path
          d="M68.863,57.604H26.146c-0.207,0-0.375-0.168-0.375-0.375v-20.25c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
        v19.875h41.967v-24.32c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v24.695C69.238,57.436,69.07,57.604,68.863,57.604z"
          fill="#000000"
        />
        <Path
          d="M51.466,57.191c-0.138,0-0.25-0.112-0.25-0.25v-6.484c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v6.484
        C51.716,57.079,51.604,57.191,51.466,57.191z"
          fill="#000000"
        />
        <Path
          d="M51.466,48.131c-0.138,0-0.25-0.112-0.25-0.25V35.156c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v12.725
        C51.716,48.018,51.604,48.131,51.466,48.131z"
          fill="#000000"
        />
        <Path
          d="M46.582,57.33c-0.18,0-0.325-0.146-0.325-0.325V41.109h-5.618v14.233c0,0.18-0.146,0.325-0.325,0.325
        s-0.325-0.146-0.325-0.325V40.784c0-0.18,0.146-0.325,0.325-0.325h6.268c0.18,0,0.325,0.146,0.325,0.325v16.221
        C46.907,57.185,46.761,57.33,46.582,57.33z"
          fill="#000000"
        />
        <Path
          d="M35.994,51.637h-5.546c-0.18,0-0.325-0.146-0.325-0.325V40.849c0-0.18,0.146-0.325,0.325-0.325h5.546
        c0.18,0,0.325,0.146,0.325,0.325v10.463C36.319,51.491,36.173,51.637,35.994,51.637z M30.773,50.986h4.896v-9.812h-4.896V50.986z"
          fill="#000000"
        />
        <G>
          <Ellipse cx="60.721" cy="33.342" fill="#E6EBEF" rx="1.58" ry="2.283" />
          <Path
            d="M60.721,35.95c-1.068,0-1.906-1.146-1.906-2.608c0-1.462,0.838-2.607,1.906-2.607s1.905,1.146,1.905,2.607
          C62.627,34.78,61.772,35.95,60.721,35.95z M60.721,31.385c-0.681,0-1.256,0.896-1.256,1.957c0,1.062,0.575,1.958,1.256,1.958
          s1.255-0.896,1.255-1.958C61.976,32.281,61.402,31.385,60.721,31.385z"
            fill="#000000"
          />
        </G>
        <Path
          d="M60.721,35.875c-1.01,0-1.831-1.137-1.831-2.533c0-1.396,0.821-2.532,1.831-2.532c1.009,0,1.83,1.136,1.83,2.532
        C62.551,34.738,61.73,35.875,60.721,35.875z M60.721,31.31c-0.734,0-1.331,0.912-1.331,2.032c0,1.103,0.609,2.033,1.331,2.033
        c0.721,0,1.33-0.931,1.33-2.033C62.051,32.24,61.442,31.31,60.721,31.31z"
          fill="#000000"
        />
        <Path
          d="M96.091,57.636h-4.403c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.403c0.207,0,0.375,0.168,0.375,0.375
        S96.298,57.636,96.091,57.636z"
          fill="#000000"
        />
        <Path
          d="M89.86,57.636H4.474c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375H89.86c0.207,0,0.375,0.168,0.375,0.375
        S90.067,57.636,89.86,57.636z"
          fill="#000000"
        />
        <G>
          <Path d="M75.097,58.964c0.967-1.961,1.014-3.178,1.211-6.028c0,0-1.649,1.177-3.196,3.969" fill="#E6EBEF" />
          <Path
            d="M75.096,59.214c-0.037,0-0.075-0.008-0.11-0.025c-0.124-0.062-0.175-0.211-0.114-0.335
          c0.877-1.777,0.979-2.936,1.146-5.347c-0.582,0.533-1.661,1.667-2.688,3.52c-0.067,0.12-0.22,0.165-0.34,0.098
          c-0.121-0.067-0.164-0.219-0.098-0.34c1.557-2.811,3.201-4.002,3.27-4.051c0.081-0.057,0.185-0.062,0.268-0.015
          c0.084,0.047,0.134,0.139,0.127,0.235l-0.023,0.352c-0.182,2.641-0.265,3.847-1.212,5.769
          C75.277,59.163,75.188,59.214,75.096,59.214z"
            fill="#000000"
          />
        </G>
        <G>
          <Path d="M70.738,58.964c-0.033-2.396,0.364-4.401,1.344-7.641c0,0,1.76,3.526,1.677,7.813" fill="#E6EBEF" />
          <Path
            d="M73.758,59.386c-0.002,0-0.003,0-0.005,0c-0.138-0.003-0.248-0.117-0.245-0.255c0.061-3.145-0.898-5.902-1.381-7.084
          c-0.876,2.998-1.168,4.803-1.14,6.914c0.002,0.138-0.108,0.251-0.247,0.253c-0.001,0-0.002,0-0.003,0
          c-0.137,0-0.248-0.109-0.25-0.247c-0.031-2.316,0.311-4.264,1.354-7.717c0.029-0.098,0.116-0.168,0.218-0.177
          c0.116-0.015,0.199,0.046,0.245,0.138c0.073,0.146,1.786,3.631,1.703,7.93C74.005,59.277,73.894,59.386,73.758,59.386z"
            fill="#000000"
          />
        </G>
        <G>
          <Path d="M71.287,59.238c-0.533-2.336-1.544-4.599-3.329-7.207c0,0-0.686,2.746,0.406,6.892" fill="#E6EBEF" />
          <Path
            d="M71.287,59.488c-0.114,0-0.217-0.078-0.244-0.194c-0.502-2.201-1.442-4.313-2.944-6.604
          c-0.132,1.014-0.263,3.245,0.508,6.169c0.035,0.134-0.045,0.271-0.179,0.306c-0.138,0.033-0.27-0.046-0.306-0.179
          c-1.095-4.16-0.436-6.901-0.406-7.016c0.023-0.096,0.102-0.168,0.198-0.186c0.096-0.021,0.194,0.023,0.25,0.104
          c1.742,2.546,2.812,4.863,3.367,7.293c0.03,0.135-0.054,0.269-0.188,0.3C71.324,59.486,71.305,59.488,71.287,59.488z"
            fill="#000000"
          />
        </G>
        <G>
          <Path d="M69.447,59.152c-1.375-1.699-3.033-3.175-5.539-4.547c0,0,0.378,1.991,2.265,4.565" fill="#E6EBEF" />
          <Path
            d="M66.173,59.42c-0.077,0-0.152-0.035-0.201-0.103c-1.899-2.59-2.294-4.582-2.311-4.665
          c-0.018-0.096,0.021-0.192,0.099-0.249c0.078-0.058,0.181-0.064,0.268-0.018c2.339,1.281,4.07,2.703,5.613,4.609
          c0.087,0.107,0.07,0.265-0.037,0.352c-0.105,0.086-0.263,0.072-0.352-0.037c-1.384-1.71-2.925-3.02-4.944-4.191
          c0.242,0.755,0.82,2.204,2.066,3.904c0.082,0.111,0.058,0.268-0.054,0.349C66.277,59.404,66.225,59.42,66.173,59.42z"
            fill="#000000"
          />
        </G>
        <G>
          <Path d="M73.724,59.152c1.375-1.699,3.033-3.175,5.539-4.547c0,0-0.378,1.991-2.265,4.565" fill="#E6EBEF" />
          <Path
            d="M76.998,59.42c-0.052,0-0.104-0.016-0.147-0.049c-0.111-0.081-0.136-0.237-0.054-0.349c1.246-1.7,1.823-3.149,2.066-3.904
          c-2.02,1.173-3.561,2.481-4.944,4.191c-0.088,0.109-0.246,0.123-0.352,0.037c-0.107-0.087-0.124-0.244-0.037-0.352
          c1.543-1.906,3.273-3.328,5.612-4.609c0.085-0.046,0.19-0.04,0.267,0.018c0.079,0.057,0.117,0.153,0.1,0.249
          c-0.017,0.083-0.41,2.075-2.31,4.665C77.15,59.385,77.075,59.42,76.998,59.42z"
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M26.496,17.277c-2.295,0-4.163-1.868-4.163-4.163s1.867-4.163,4.163-4.163s4.163,1.867,4.163,4.163
          S28.791,17.277,26.496,17.277z M26.496,9.452c-2.02,0-3.663,1.643-3.663,3.663c0,2.02,1.643,3.663,3.663,3.663
          c2.02,0,3.663-1.643,3.663-3.663C30.159,11.095,28.516,9.452,26.496,9.452z"
            fill="#000000"
          />
          <Path
            d="M26.34,7.929c-0.138,0-0.25-0.112-0.25-0.25V6.045c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.634
          C26.59,7.817,26.479,7.929,26.34,7.929z"
            fill="#000000"
          />
          <Path
            d="M26.34,20.334c-0.138,0-0.25-0.112-0.25-0.25V18.45c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.634
          C26.59,20.222,26.479,20.334,26.34,20.334z"
            fill="#000000"
          />
          <Path
            d="M30.149,9.506c-0.064,0-0.128-0.024-0.177-0.073c-0.098-0.098-0.098-0.256,0-0.354l1.155-1.156
          c0.098-0.098,0.256-0.098,0.354,0c0.098,0.098,0.098,0.256,0,0.354l-1.155,1.156C30.276,9.482,30.212,9.506,30.149,9.506z"
            fill="#000000"
          />
          <Path
            d="M21.377,18.278c-0.064,0-0.128-0.024-0.177-0.073c-0.098-0.098-0.098-0.256,0-0.354l1.155-1.156
          c0.098-0.098,0.256-0.098,0.354,0s0.098,0.256,0,0.354l-1.155,1.156C21.504,18.254,21.44,18.278,21.377,18.278z"
            fill="#000000"
          />
          <Path
            d="M33.36,13.314h-1.634c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h1.634c0.138,0,0.25,0.112,0.25,0.25
          S33.498,13.314,33.36,13.314z"
            fill="#000000"
          />
          <Path
            d="M20.955,13.314H19.32c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h1.634c0.138,0,0.25,0.112,0.25,0.25
          S21.093,13.314,20.955,13.314z"
            fill="#000000"
          />
          <Path
            d="M31.304,18.278c-0.064,0-0.128-0.024-0.177-0.073l-1.155-1.156c-0.098-0.098-0.098-0.256,0-0.354s0.256-0.098,0.354,0
          l1.155,1.156c0.098,0.098,0.098,0.256,0,0.354C31.432,18.254,31.368,18.278,31.304,18.278z"
            fill="#000000"
          />
          <Path
            d="M22.532,9.506c-0.064,0-0.128-0.024-0.177-0.073L21.2,8.277c-0.098-0.098-0.098-0.256,0-0.354
          c0.098-0.098,0.256-0.098,0.354,0l1.155,1.156c0.098,0.098,0.098,0.256,0,0.354C22.66,9.482,22.596,9.506,22.532,9.506z"
            fill="#000000"
          />
        </G>
        <Path
          d="M53.064,61.171H31.966c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h21.098c0.138,0,0.25,0.112,0.25,0.25
        S53.202,61.171,53.064,61.171z"
          fill="#000000"
        />
        <G>
          <Path
            d="M5.975,36.756c0.374-0.317,0.494-0.855,0.238-1.274c-0.509-0.833-0.801-1.785-0.801-2.8
          c0-1.936,1.051-3.65,2.669-4.723c0.206-0.136,0.312-0.382,0.241-0.619c-0.084-0.279-0.129-0.572-0.129-0.874
          c0-1.882,1.711-3.408,3.822-3.408s3.822,1.526,3.822,3.408c0,0.303-0.046,0.595-0.129,0.874c-0.071,0.236,0.035,0.482,0.241,0.619
          c1.618,1.072,2.669,2.786,2.669,4.723c0,1.015-0.292,1.968-0.801,2.8c-0.256,0.418-0.136,0.957,0.238,1.274
          c1.683,1.424,2.736,3.433,2.736,5.666c0,4.322-3.929,7.825-8.776,7.825s-8.776-3.504-8.776-7.825
          C3.239,40.19,4.292,38.181,5.975,36.756z"
            fill="#D8DFE5"
          />
          <Path
            d="M12.228,57.409c-0.207,0-0.375-0.168-0.375-0.375V33.703c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v23.331
          C12.603,57.241,12.435,57.409,12.228,57.409z"
            fill="#000000"
          />
          <Path
            d="M12.329,43.906c-0.122,0-0.242-0.06-0.314-0.169c-0.114-0.173-0.065-0.406,0.107-0.52l3.982-2.615
          c0.173-0.115,0.406-0.064,0.52,0.107c0.114,0.173,0.065,0.406-0.107,0.52l-3.982,2.615C12.471,43.887,12.399,43.906,12.329,43.906
          z"
            fill="#000000"
          />
          <Path
            d="M12.245,38.78c-0.071,0-0.143-0.021-0.207-0.062L9.916,37.31c-0.173-0.114-0.22-0.347-0.105-0.52
          c0.114-0.174,0.346-0.221,0.52-0.105l2.123,1.407c0.173,0.114,0.22,0.347,0.105,0.52C12.485,38.722,12.366,38.78,12.245,38.78z"
            fill="#000000"
          />
          <Path
            d="M12.143,46.728c-0.064,0-0.13-0.017-0.19-0.052l-4.51-2.654c-0.178-0.105-0.238-0.335-0.133-0.514
          c0.104-0.178,0.333-0.239,0.514-0.133l4.51,2.654c0.178,0.105,0.238,0.335,0.133,0.514C12.397,46.662,12.271,46.728,12.143,46.728
          z"
            fill="#000000"
          />
          <G>
            <Path
              d="M7.935,40.156c0-0.316,0.234-0.575,0.537-0.62c0.041-0.308,0.302-0.546,0.621-0.546
            c0.317,0,0.576,0.235,0.62,0.54c0.334,0.015,0.601,0.288,0.601,0.626c0,0.345-0.277,0.624-0.621,0.628
            c-0.077,0.26-0.315,0.451-0.6,0.451c-0.288,0-0.527-0.194-0.602-0.458C8.178,40.741,7.935,40.478,7.935,40.156z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M15.03,45.05c0-0.227,0.168-0.412,0.385-0.444c0.029-0.221,0.216-0.392,0.445-0.392
            c0.227,0,0.413,0.168,0.444,0.387c0.24,0.011,0.431,0.207,0.431,0.449c0,0.247-0.199,0.447-0.445,0.45
            c-0.055,0.186-0.226,0.323-0.43,0.323c-0.206,0-0.378-0.139-0.432-0.328C15.204,45.469,15.03,45.281,15.03,45.05z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M18.144,42.638c0-0.149,0.11-0.271,0.254-0.292c0.019-0.145,0.142-0.258,0.293-0.258
            c0.149,0,0.272,0.111,0.293,0.255c0.158,0.007,0.284,0.136,0.284,0.295c0,0.163-0.131,0.294-0.293,0.296
            c-0.036,0.123-0.149,0.213-0.283,0.213c-0.136,0-0.249-0.092-0.284-0.216C18.259,42.914,18.144,42.79,18.144,42.638z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M10.562,25.465c0-0.149,0.11-0.271,0.254-0.292c0.019-0.145,0.142-0.258,0.293-0.258
            c0.149,0,0.272,0.111,0.293,0.255c0.158,0.007,0.284,0.136,0.284,0.295c0,0.163-0.131,0.294-0.293,0.296
            c-0.036,0.123-0.149,0.213-0.283,0.213c-0.136,0-0.249-0.092-0.284-0.216C10.677,25.741,10.562,25.617,10.562,25.465z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M4.713,42.752c0-0.23,0.17-0.419,0.391-0.451c0.03-0.224,0.22-0.398,0.452-0.398
            c0.231,0,0.42,0.171,0.452,0.393c0.243,0.011,0.438,0.21,0.438,0.456c0,0.251-0.202,0.454-0.452,0.458
            c-0.056,0.189-0.23,0.328-0.437,0.328c-0.209,0-0.384-0.141-0.439-0.333C4.89,43.178,4.713,42.986,4.713,42.752z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M9.206,29.488c0-0.227,0.168-0.413,0.386-0.445c0.029-0.221,0.217-0.392,0.446-0.392
            c0.227,0,0.414,0.169,0.445,0.388c0.24,0.011,0.432,0.207,0.432,0.45c0,0.247-0.199,0.448-0.446,0.451
            c-0.055,0.187-0.226,0.324-0.431,0.324c-0.206,0-0.379-0.139-0.433-0.329C9.381,29.908,9.206,29.72,9.206,29.488z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M13.856,48.042c0-0.149,0.11-0.271,0.254-0.292c0.019-0.145,0.142-0.258,0.293-0.258
            c0.149,0,0.272,0.111,0.293,0.255c0.158,0.007,0.284,0.136,0.284,0.295c0,0.163-0.131,0.294-0.293,0.296
            c-0.036,0.123-0.149,0.213-0.283,0.213c-0.136,0-0.249-0.092-0.284-0.216C13.971,48.318,13.856,48.194,13.856,48.042z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M15.028,33.043c0-0.246,0.182-0.448,0.419-0.483c0.032-0.24,0.235-0.426,0.484-0.426
            c0.247,0,0.449,0.183,0.483,0.421c0.261,0.012,0.469,0.225,0.469,0.488c0,0.269-0.216,0.486-0.484,0.49
            c-0.06,0.203-0.246,0.352-0.468,0.352c-0.224,0-0.411-0.151-0.47-0.357C15.218,33.499,15.028,33.294,15.028,33.043z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M12.795,29.751c0-0.276,0.204-0.501,0.469-0.54c0.036-0.268,0.263-0.476,0.541-0.476
            c0.276,0,0.503,0.205,0.541,0.471c0.291,0.013,0.524,0.251,0.524,0.546c0,0.301-0.242,0.544-0.541,0.548
            c-0.067,0.227-0.275,0.393-0.523,0.393c-0.251,0-0.46-0.169-0.525-0.399C13.007,30.261,12.795,30.032,12.795,29.751z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M13.984,37.482c0-0.31,0.229-0.564,0.527-0.607c0.04-0.302,0.296-0.535,0.608-0.535
            c0.31,0,0.565,0.23,0.608,0.529c0.328,0.015,0.589,0.283,0.589,0.614c0,0.338-0.272,0.611-0.609,0.616
            c-0.076,0.255-0.309,0.442-0.588,0.442c-0.282,0-0.517-0.19-0.59-0.448C14.223,38.055,13.984,37.798,13.984,37.482z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M6.187,46.46c0-0.227,0.168-0.412,0.385-0.444c0.029-0.221,0.216-0.392,0.445-0.392
            c0.227,0,0.413,0.168,0.444,0.387c0.24,0.011,0.431,0.207,0.431,0.449c0,0.247-0.199,0.447-0.445,0.45
            c-0.055,0.186-0.226,0.323-0.43,0.323c-0.206,0-0.378-0.139-0.432-0.328C6.361,46.879,6.187,46.691,6.187,46.46z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M7.778,33.146c0-0.261,0.193-0.475,0.444-0.512c0.034-0.254,0.249-0.451,0.513-0.451
            c0.262,0,0.476,0.194,0.512,0.446c0.276,0.012,0.496,0.238,0.496,0.517c0,0.285-0.229,0.515-0.513,0.519
            c-0.064,0.215-0.26,0.372-0.496,0.372c-0.237,0-0.435-0.16-0.497-0.378C7.979,33.629,7.778,33.412,7.778,33.146z"
              fill="#FFFFFF"
            />
          </G>
          <Path
            d="M12.015,50.622c-5.046,0-9.151-3.679-9.151-8.2c0-2.272,1.019-4.386,2.869-5.951c0.246-0.209,0.313-0.542,0.161-0.793
          c-0.56-0.915-0.855-1.95-0.855-2.995c0-1.976,1.061-3.858,2.837-5.035c0.061-0.041,0.113-0.118,0.089-0.198
          c-0.097-0.322-0.146-0.652-0.146-0.982c0-2.086,1.883-3.783,4.197-3.783s4.197,1.697,4.197,3.783c0,0.332-0.049,0.663-0.146,0.982
          c-0.024,0.08,0.028,0.157,0.09,0.198c1.776,1.177,2.836,3.06,2.836,5.035c0,1.044-0.296,2.08-0.856,2.995
          c-0.153,0.251-0.085,0.584,0.161,0.793c1.85,1.565,2.868,3.679,2.868,5.951C21.166,46.943,17.061,50.622,12.015,50.622z
          M12.015,23.434c-1.9,0-3.447,1.361-3.447,3.033c0,0.257,0.038,0.515,0.114,0.767c0.117,0.387-0.041,0.805-0.393,1.039
          c-1.59,1.054-2.501,2.661-2.501,4.41c0,0.906,0.258,1.807,0.745,2.604c0.347,0.568,0.214,1.307-0.315,1.756c0,0,0,0,0,0
          c-1.679,1.421-2.603,3.331-2.603,5.379c0,4.108,3.769,7.45,8.401,7.45s8.401-3.342,8.401-7.45c0-2.048-0.924-3.958-2.603-5.379
          c-0.53-0.45-0.663-1.188-0.316-1.756c0.488-0.798,0.746-1.698,0.746-2.604c0-1.749-0.912-3.356-2.501-4.41
          c-0.352-0.233-0.51-0.65-0.394-1.039c0.076-0.25,0.114-0.508,0.114-0.767C15.461,24.795,13.915,23.434,12.015,23.434z
          M5.975,36.757h0.009H5.975z"
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M93.888,29.205c-0.507-0.429-0.669-1.159-0.323-1.725c0.689-1.128,1.084-2.418,1.084-3.793
          c0-2.622-1.423-4.944-3.615-6.396c-0.279-0.185-0.422-0.518-0.326-0.838c0.114-0.378,0.175-0.774,0.175-1.184
          c0-2.549-2.317-4.616-5.176-4.616s-5.176,2.066-5.176,4.616c0,0.41,0.062,0.806,0.175,1.184c0.096,0.32-0.048,0.653-0.326,0.838
          c-2.192,1.453-3.615,3.774-3.615,6.396c0,1.375,0.395,2.665,1.084,3.793c0.346,0.567,0.184,1.296-0.323,1.725
          c-2.279,1.929-3.705,4.65-3.705,7.673c0,5.853,5.321,10.599,11.886,10.599s11.886-4.745,11.886-10.599
          C97.593,33.855,96.167,31.134,93.888,29.205z"
            fill="#D8DFE5"
          />
          <Path
            d="M85.419,57.396c-0.207,0-0.375-0.168-0.375-0.375V25.069c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v31.952
          C85.794,57.228,85.626,57.396,85.419,57.396z"
            fill="#000000"
          />
          <Path
            d="M85.283,38.756c-0.071,0-0.143-0.02-0.206-0.062l-5.394-3.542c-0.173-0.113-0.221-0.347-0.107-0.52
          c0.114-0.173,0.347-0.222,0.52-0.107l5.394,3.542c0.173,0.113,0.221,0.347,0.107,0.52C85.524,38.696,85.405,38.756,85.283,38.756z
          "
            fill="#000000"
          />
          <Path
            d="M85.395,31.812c-0.121,0-0.24-0.059-0.312-0.168c-0.114-0.173-0.067-0.405,0.105-0.52l2.875-1.904
          c0.171-0.113,0.404-0.068,0.52,0.105c0.114,0.173,0.067,0.405-0.105,0.52l-2.875,1.904C85.539,31.792,85.466,31.812,85.395,31.812
          z"
            fill="#000000"
          />
          <Path
            d="M85.534,42.579c-0.128,0-0.253-0.066-0.323-0.185c-0.105-0.179-0.046-0.408,0.133-0.514l6.108-3.596
          c0.178-0.104,0.408-0.045,0.514,0.133c0.105,0.179,0.046,0.408-0.133,0.514l-6.108,3.596
          C85.665,42.562,85.599,42.579,85.534,42.579z"
            fill="#000000"
          />
          <G>
            <Path
              d="M91.233,33.809c0-0.428-0.317-0.779-0.728-0.84c-0.055-0.417-0.409-0.74-0.841-0.74
            c-0.429,0-0.781,0.318-0.84,0.731c-0.453,0.02-0.814,0.391-0.814,0.848c0,0.467,0.376,0.845,0.841,0.851
            c0.105,0.352,0.427,0.611,0.813,0.611c0.389,0,0.714-0.263,0.816-0.62C90.904,34.601,91.233,34.245,91.233,33.809z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M81.624,40.438c0-0.307-0.227-0.558-0.522-0.602c-0.04-0.299-0.293-0.53-0.603-0.53
            c-0.308,0-0.56,0.228-0.602,0.524c-0.324,0.014-0.584,0.28-0.584,0.608c0,0.335,0.269,0.606,0.603,0.61
            c0.075,0.252,0.306,0.438,0.583,0.438c0.279,0,0.512-0.188,0.585-0.444C81.388,41.006,81.624,40.751,81.624,40.438z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M77.406,37.171c0-0.202-0.149-0.368-0.343-0.396c-0.026-0.197-0.193-0.349-0.397-0.349
            c-0.202,0-0.368,0.15-0.396,0.345c-0.214,0.009-0.384,0.184-0.384,0.4c0,0.22,0.177,0.399,0.397,0.401
            c0.049,0.166,0.201,0.288,0.384,0.288c0.184,0,0.337-0.124,0.385-0.292C77.25,37.545,77.406,37.377,77.406,37.171z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M87.675,13.911c0-0.202-0.149-0.368-0.343-0.396c-0.026-0.197-0.193-0.349-0.397-0.349
            c-0.202,0-0.368,0.15-0.396,0.345c-0.214,0.009-0.384,0.184-0.384,0.4c0,0.22,0.177,0.399,0.397,0.401
            c0.049,0.166,0.201,0.288,0.384,0.288c0.184,0,0.337-0.124,0.385-0.292C87.519,14.285,87.675,14.117,87.675,13.911z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M95.597,37.325c0-0.312-0.231-0.567-0.53-0.611c-0.04-0.304-0.298-0.539-0.612-0.539
            c-0.313,0-0.569,0.232-0.612,0.533c-0.33,0.015-0.593,0.284-0.593,0.618c0,0.34,0.274,0.615,0.613,0.62
            c0.076,0.257,0.311,0.445,0.592,0.445c0.284,0,0.52-0.191,0.594-0.451C95.357,37.902,95.597,37.643,95.597,37.325z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M89.511,19.361c0-0.307-0.227-0.559-0.523-0.603c-0.04-0.299-0.293-0.531-0.604-0.531
            c-0.308,0-0.561,0.229-0.603,0.525c-0.325,0.014-0.585,0.28-0.585,0.609c0,0.335,0.27,0.607,0.604,0.611
            c0.075,0.253,0.307,0.439,0.584,0.439c0.28,0,0.513-0.189,0.586-0.445C89.275,19.93,89.511,19.675,89.511,19.361z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M83.213,44.49c0-0.202-0.149-0.368-0.343-0.396c-0.026-0.197-0.193-0.349-0.397-0.349
            c-0.202,0-0.368,0.15-0.396,0.345c-0.214,0.009-0.384,0.184-0.384,0.4c0,0.22,0.177,0.399,0.397,0.401
            c0.049,0.166,0.201,0.288,0.384,0.288c0.184,0,0.337-0.124,0.385-0.292C83.058,44.864,83.213,44.696,83.213,44.49z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M81.626,24.175c0-0.334-0.247-0.607-0.567-0.654c-0.043-0.325-0.319-0.577-0.655-0.577
            c-0.335,0-0.609,0.248-0.655,0.57c-0.353,0.016-0.635,0.304-0.635,0.661c0,0.364,0.293,0.659,0.656,0.663
            c0.082,0.275,0.333,0.476,0.634,0.476c0.304,0,0.557-0.205,0.636-0.483C81.369,24.793,81.626,24.516,81.626,24.175z"
              fill="#BAC2CA"
            />
          </G>
          <G>
            <Path
              d="M84.651,19.718c0-0.373-0.276-0.679-0.635-0.732c-0.048-0.364-0.356-0.645-0.733-0.645
            c-0.374,0-0.681,0.278-0.732,0.637c-0.395,0.018-0.71,0.341-0.71,0.74c0,0.407,0.328,0.737,0.733,0.742
            c0.091,0.307,0.372,0.533,0.709,0.533c0.34,0,0.623-0.229,0.711-0.54C84.364,20.408,84.651,20.098,84.651,19.718z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M83.04,30.188c0-0.419-0.31-0.763-0.713-0.823c-0.054-0.409-0.4-0.725-0.824-0.725
            c-0.421,0-0.765,0.312-0.823,0.716c-0.444,0.02-0.798,0.383-0.798,0.831c0,0.457,0.368,0.828,0.824,0.834
            c0.102,0.345,0.418,0.599,0.797,0.599c0.382,0,0.7-0.258,0.8-0.607C82.717,30.964,83.04,30.616,83.04,30.188z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M93.601,42.347c0-0.307-0.227-0.558-0.522-0.602c-0.04-0.299-0.293-0.53-0.603-0.53
            c-0.308,0-0.56,0.228-0.602,0.524c-0.324,0.014-0.584,0.28-0.584,0.608c0,0.335,0.269,0.606,0.603,0.61
            c0.075,0.252,0.306,0.438,0.583,0.438c0.279,0,0.512-0.188,0.585-0.444C93.365,42.915,93.601,42.66,93.601,42.347z"
              fill="#FFFFFF"
            />
          </G>
          <G>
            <Path
              d="M91.445,24.314c0-0.353-0.261-0.643-0.601-0.693c-0.046-0.344-0.337-0.611-0.694-0.611
            c-0.354,0-0.645,0.263-0.693,0.604c-0.374,0.017-0.672,0.322-0.672,0.7c0,0.385,0.31,0.698,0.694,0.703
            c0.086,0.291,0.353,0.504,0.671,0.504c0.322,0,0.59-0.217,0.674-0.512C91.173,24.969,91.445,24.675,91.445,24.314z"
              fill="#FFFFFF"
            />
          </G>
          <Path
            d="M85.708,47.852c-6.761,0-12.261-4.923-12.261-10.974c0-3.039,1.363-5.866,3.838-7.96c0.384-0.324,0.486-0.848,0.245-1.244
          c-0.745-1.219-1.14-2.598-1.14-3.988c0-2.631,1.414-5.138,3.783-6.708c0.149-0.099,0.219-0.267,0.174-0.418
          c-0.127-0.424-0.191-0.858-0.191-1.292c0-2.751,2.49-4.99,5.552-4.99c3.061,0,5.551,2.239,5.551,4.99
          c0,0.436-0.064,0.87-0.19,1.292c-0.046,0.151,0.024,0.318,0.174,0.417c2.369,1.57,3.783,4.077,3.783,6.708
          c0,1.391-0.395,2.77-1.14,3.988c-0.242,0.396-0.139,0.919,0.245,1.244l0,0c2.475,2.095,3.838,4.922,3.838,7.96
          C97.968,42.93,92.468,47.852,85.708,47.852z M85.708,11.028c-2.647,0-4.802,1.902-4.802,4.24c0,0.36,0.054,0.723,0.16,1.077
          c0.141,0.469-0.052,0.975-0.479,1.258c-2.19,1.452-3.447,3.669-3.447,6.083c0,1.252,0.356,2.496,1.03,3.598
          c0.435,0.714,0.267,1.643-0.401,2.207c-2.304,1.949-3.572,4.573-3.572,7.388c0,5.638,5.164,10.224,11.511,10.224
          s11.511-4.586,11.511-10.224c0-2.813-1.269-5.437-3.572-7.388c-0.668-0.565-0.837-1.493-0.401-2.207
          c0.674-1.102,1.03-2.346,1.03-3.598c0-2.414-1.257-4.631-3.447-6.083c-0.428-0.284-0.62-0.79-0.479-1.259
          c0.105-0.351,0.159-0.713,0.159-1.076C90.508,12.931,88.355,11.028,85.708,11.028z"
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M26.173,63.378H15.168c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h11.005c0.138,0,0.25,0.112,0.25,0.25
          S26.311,63.378,26.173,63.378z"
            fill="#000000"
          />
          <Path
            d="M29.591,63.378h-1.799c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h1.799c0.138,0,0.25,0.112,0.25,0.25
          S29.73,63.378,29.591,63.378z"
            fill="#000000"
          />
          <Path
            d="M18.885,59.601c0,0-1.095,0.33-2.099-0.48c-1.005-0.81-1.874-3.554-1.874-3.554s0.81-0.27,1.979-0.15
          c1.17,0.12,1.874,0.39,1.874,0.39s0.33-1.619,1.814-2.354c1.484-0.735,3.284-0.6,3.284-0.6s0.195,1.349-0.09,2.939
          c-0.285,1.589-1.349,3.344-3.389,3.599c0,0-0.15,0.075-0.15,0.57c0,0.495,0.045,2.819,0.045,2.819l-1.155-0.015l0.045-2.969
          C19.17,59.796,19.005,59.526,18.885,59.601z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              d="M19.124,63.139c-0.138,0-0.25-0.112-0.25-0.25v-3.083c-0.856,0.146-1.615-0.004-2.257-0.455
            c-1.622-1.136-1.913-3.758-1.924-3.868c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.169-0.091
            c0.107-0.01,2.652-0.257,4.042,0.474c1.476,0.777,1.246,2.665,1.236,2.744c-0.018,0.137-0.143,0.228-0.28,0.216
            c-0.137-0.019-0.233-0.144-0.216-0.28c0.002-0.016,0.193-1.623-0.973-2.236c-1.016-0.536-2.827-0.48-3.498-0.439
            c0.113,0.663,0.516,2.445,1.678,3.258c0.595,0.416,1.32,0.521,2.158,0.312c0.074-0.021,0.153-0.002,0.214,0.045
            c0.061,0.048,0.096,0.12,0.096,0.197v3.392C19.374,63.026,19.262,63.139,19.124,63.139z"
              fill="#000000"
            />
            <Path
              d="M20.493,59.593c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
            c0.943-0.077,1.696-0.459,2.236-1.135c1.213-1.516,1.075-4.092,1.006-4.828c-4.013-0.195-4.646,2.537-4.652,2.564
            c-0.029,0.137-0.164,0.223-0.297,0.192c-0.135-0.028-0.221-0.161-0.192-0.296c0.007-0.034,0.748-3.303,5.385-2.946
            c0.117,0.009,0.213,0.098,0.229,0.215c0.02,0.141,0.464,3.471-1.088,5.411c-0.63,0.787-1.5,1.231-2.586,1.32
            C20.507,59.593,20.5,59.593,20.493,59.593z"
              fill="#000000"
            />
            <Path
              d="M19.97,57.371c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266c0.038-0.08,0.937-1.97,2.498-2.906
            c0.094-0.055,0.217-0.025,0.274,0.069s0.026,0.217-0.068,0.274c-1.456,0.872-2.333,2.714-2.342,2.732
            C20.117,57.328,20.045,57.371,19.97,57.371z"
              fill="#000000"
            />
            <Path
              d="M20.478,63.248c-0.138,0-0.25-0.112-0.25-0.25v-3.587c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v3.587
            C20.728,63.136,20.616,63.248,20.478,63.248z"
              fill="#000000"
            />
            <Path
              d="M19.968,58.712c-0.067,0-0.133-0.034-0.17-0.096c-0.358-0.586-2.056-1.426-2.68-1.7
            c-0.101-0.044-0.147-0.162-0.103-0.263c0.044-0.102,0.162-0.145,0.264-0.104c0.241,0.106,2.372,1.06,2.86,1.857
            c0.058,0.095,0.028,0.218-0.066,0.275C20.04,58.702,20.004,58.712,19.968,58.712z"
              fill="#000000"
            />
          </G>
        </G>
      </G>
    </Svg>
  );
}
