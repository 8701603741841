import React from 'react';
import { useIntl } from 'react-intl';
import T from 'prop-types';

import { INTL_IDS } from '@dmi/shared/redux/Calculators/messages';

import { SecondaryDivider } from '../../base_ui';
import Card from './Card';
import HeaderSection from './HeaderSection';
import { HomeContainer, HomeH1 } from './styledComponents';

const HomeView = ({ navigation }) => {
  const { formatMessage } = useIntl();

  return (
    <HomeContainer>
      <HomeH1>Calculators</HomeH1>
      <HeaderSection navigation={navigation} />
      <SecondaryDivider />
      <Card
        ariaLabel="calculator-button"
        cardIconKey="calculator"
        onPress={() => navigation.navigate('Amortize')}
        text={formatMessage({ id: INTL_IDS.HOME_CARD_CALCULATE_TEXT })}
        title={formatMessage({ id: INTL_IDS.HOME_CARD_CALCULATE_TITLE })}
      />
      <SecondaryDivider />
      <Card
        ariaLabel="payoff-request-button"
        cardIconKey="calculatorsPayoffFlag"
        onPress={() => navigation.navigate('Payoff')}
        text={formatMessage({ id: INTL_IDS.HOME_CARD_PAYOFF_TEXT })}
        title={formatMessage({ id: INTL_IDS.HOME_CARD_PAYOFF_TITLE })}
      />
      <SecondaryDivider />
    </HomeContainer>
  );
};

HomeView.propTypes = { navigation: T.object.isRequired };

export default HomeView;
