/* eslint-disable max-len */
import React, { Fragment } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { BaseInternalLink, StyledScrollView } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import VideoLibrary from './VideoLibrary';
import {
  Card,
  CardText,
  CardTitle,
  ContentWrapper,
  LinkContainer,
  SubTitle,
  Title,
} from './styledComponents';

const HardshipBookIllustration = iconDictionary('hardshipBook');
// const HardshipTalkIllustration = iconDictionary('hardshipTalk');
const LearningCenterIllustration = iconDictionary('learningCenter');

const LearningCenter = ({
  dispatchSetVideoModalData,
  dispatchSetVideoRemainingDuration,
  navigation,
  videoModalData,
  videoRemainingDuration,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Learning Center"
    />
    <StyledScrollView showsVerticalScrollIndicator={false}>
      <ContentWrapper>
        {LearningCenterIllustration}
        <Title>Understanding Mortgages</Title>
        <SubTitle>Educational resources and helpful explanations for all things mortgage-related.</SubTitle>
        {/* <Card>
          {HardshipTalkIllustration}
          <CardTitle>Frequently Asked Questions</CardTitle>
          <CardText>Answers to the most commonly asked questions related to mortgages.</CardText>
          <LinkContainer>
            <BaseInternalLink label="Read FAQs" path="Faq" />
          </LinkContainer>
        </Card> */}
        <Card>
          {HardshipBookIllustration}
          <CardTitle>Glossary of Terms</CardTitle>
          <CardText>Get explanations for important terms that you will encounter as a homeowner.</CardText>
          <LinkContainer>
            <BaseInternalLink label="Explore Glossary" path="Glossary" />
          </LinkContainer>
        </Card>
        <VideoLibrary
          dispatchSetVideoModalData={dispatchSetVideoModalData}
          dispatchSetVideoRemainingDuration={dispatchSetVideoRemainingDuration}
          videoModalData={videoModalData}
          videoRemainingDuration={videoRemainingDuration}
        />
      </ContentWrapper>
    </StyledScrollView>
  </Fragment>
);

LearningCenter.propTypes = {
  dispatchSetVideoModalData: T.func.isRequired,
  dispatchSetVideoRemainingDuration: T.func.isRequired,
  navigation: T.object.isRequired,
  videoModalData: T.object,
  videoRemainingDuration: T.number.isRequired,
};

export default LearningCenter;
