/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  G,
  Line,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function HardshipClipboard() {
  return (
    <Svg height={moderateScale(85)} width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85">
      <G isolation="isolate">
        <G id="Layer_1" data-name="Layer 1">
          <Rect fill="#dce2e7" x="9.41" y="8.45" width="57.76" height="72.87" rx="6" />
          <Rect fill="#fff" x="6.66" y="10.97" width="57.99" height="73.16" rx="6" />
          <Path fill="#2c2627" d="M59.9,84.51H11.41a5.13,5.13,0,0,1-5.13-5.13V26.64a.38.38,0,0,1,.75,0V79.38a4.39,4.39,0,0,0,4.38,4.38H59.9a4.39,4.39,0,0,0,4.38-4.38V15.73a4.39,4.39,0,0,0-4.38-4.38H11.41A4.39,4.39,0,0,0,7,15.73v7.36a.38.38,0,0,1-.75,0V15.73a5.13,5.13,0,0,1,5.13-5.13H59.9A5.13,5.13,0,0,1,65,15.73V79.38A5.13,5.13,0,0,1,59.9,84.51Z" />
          <Path fill="#2c2627" d="M59.07,71.54a.38.38,0,0,1-.37-.38V67.38a.38.38,0,0,1,.75,0v3.78A.38.38,0,0,1,59.07,71.54Z" />
          <Path fill="#2c2627" d="M59.07,79.16H12.23a.38.38,0,0,1-.37-.38V15.88a.37.37,0,0,1,.37-.37H59.07a.37.37,0,0,1,.38.37V65.45a.38.38,0,1,1-.75,0V16.26H12.61V78.41H58.7V72.92a.38.38,0,1,1,.75,0v5.86A.38.38,0,0,1,59.07,79.16Z" />
          <Path fill="#2c2627" d="M35.65,8.83a3,3,0,1,1,3-3A3,3,0,0,1,35.65,8.83Zm0-5.27a2.26,2.26,0,1,0,2.26,2.26A2.26,2.26,0,0,0,35.65,3.56Z" />
          <Path fill="#e6ebef" d="M50.46,16,49.3,10.36a2.67,2.67,0,0,0-2.61-2.14H43.63A2.41,2.41,0,0,1,41.25,6a5,5,0,0,0-.19-1.13A5.69,5.69,0,0,0,36.12.7,5.63,5.63,0,0,0,30,6.31v0a1.89,1.89,0,0,1-1.89,1.89H24.82a2.68,2.68,0,0,0-2.6,2L20.86,16a2.67,2.67,0,0,0,2.6,3.29H47.85A2.66,2.66,0,0,0,50.46,16ZM35.65,8.45a2.63,2.63,0,1,1,2.64-2.63A2.63,2.63,0,0,1,35.65,8.45Z" />
          <Path fill="#000" d="M47.85,19.62H23.46a3,3,0,0,1-3-3.76l1.36-5.68a3,3,0,0,1,3-2.33h3.29a1.51,1.51,0,0,0,1.07-.45,1.46,1.46,0,0,0,.44-1.06,6.07,6.07,0,0,1,1.94-4.45,6,6,0,0,1,9.86,2.83,6.39,6.39,0,0,1,.21,1.21,2,2,0,0,0,2,1.92h3.06a3,3,0,0,1,3,2.43L50.83,16a3,3,0,0,1-3,3.66Zm-23-11a2.28,2.28,0,0,0-2.23,1.76L21.23,16a2.3,2.3,0,0,0,2.23,2.83H47.85a2.3,2.3,0,0,0,2.25-2.76l-1.16-5.68A2.31,2.31,0,0,0,46.69,8.6H43.63A2.79,2.79,0,0,1,40.88,6a6.52,6.52,0,0,0-.18-1.06,5.33,5.33,0,0,0-4.61-3.84,5.23,5.23,0,0,0-4,1.36,5.29,5.29,0,0,0-1.7,3.87,2.23,2.23,0,0,1-.66,1.62,2.26,2.26,0,0,1-1.6.67Zm10.83.23a3,3,0,1,1,3-3A3,3,0,0,1,35.65,8.83Zm0-5.27a2.26,2.26,0,1,0,2.26,2.26A2.26,2.26,0,0,0,35.65,3.56Z" />
          <Path fill="#2c2627" d="M50.52,34.26H29.87a.38.38,0,0,1,0-.75H50.52a.38.38,0,0,1,0,.75Z" />
          <Path fill="#2c2627" d="M50.52,47.71H29.87a.37.37,0,0,1-.37-.38.36.36,0,0,1,.37-.37H50.52a.36.36,0,0,1,.37.37A.37.37,0,0,1,50.52,47.71Z" />
          <Path fill="#2c2627" d="M50.52,61.15H29.87a.38.38,0,0,1,0-.75H50.52a.38.38,0,0,1,0,.75Z" />
          <Rect fill="#fff" x="64.11" y="19.22" width="6.28" height="37.99" transform="translate(28.01 -28.44) rotate(29.9)" />
          <Rect fill="#bac2ca" x="57.96" y="42.18" width="6.28" height="13.53" transform="translate(32.54 -23.94) rotate(29.9)" />
          <Polygon fill="#fff" points="55.06 53.12 60.51 56.25 54.28 60.78 55.06 53.12" />
          <Path fill="#2c2627" d="M54.28,61.15a.29.29,0,0,1-.18,0,.36.36,0,0,1-.19-.36l.78-7.66a.36.36,0,0,1,.2-.29.36.36,0,0,1,.36,0l5.45,3.13a.35.35,0,0,1,.18.3.39.39,0,0,1-.15.33L54.5,61.08A.34.34,0,0,1,54.28,61.15Zm1.1-7.42L54.74,60l5.08-3.69Z" />
          <Path fill="#bac2ca" d="M77.78,17.27h.28a3,3,0,0,1,3,3v1.8a0,0,0,0,1,0,0H74.78a0,0,0,0,1,0,0v-1.8A3,3,0,0,1,77.78,17.27Z" transform="translate(20.18 -36.23) rotate(29.9)" />
          <Path fill="#000" d="M79.59,23.83l-6.1-3.51,1.08-1.88a3.38,3.38,0,0,1,4.61-1.24l.25.14A3.38,3.38,0,0,1,80.67,22Zm-5.07-3.78,4.79,2.75L80,21.57a2.62,2.62,0,0,0-1-3.58h0l-.24-.14a2.64,2.64,0,0,0-3.59,1Z" />
          <Line fill="#fff" x1="61.73" y1="41.52" x2="67.18" y2="44.65" />
          <Path fill="#2c2627" d="M67.18,45A.45.45,0,0,1,67,45l-5.44-3.14a.37.37,0,1,1,.37-.65l5.45,3.14a.37.37,0,0,1,.14.51A.39.39,0,0,1,67.18,45Z" />
          <Path fill="#2c2627" d="M72.81,39.94a.32.32,0,0,1-.19,0,.37.37,0,0,1-.14-.51l7.88-13.69-1.71-1a.39.39,0,0,1-.14-.52.38.38,0,0,1,.51-.14l2,1.17a.38.38,0,0,1,.13.51l-8.06,14A.37.37,0,0,1,72.81,39.94Z" />
          <Path fill="#2c2627" d="M60.51,56.63a.41.41,0,0,1-.19-.05l-5.44-3.13a.41.41,0,0,1-.18-.23.37.37,0,0,1,0-.29L73.68,20a.37.37,0,0,1,.51-.14L79.64,23a.36.36,0,0,1,.17.23.35.35,0,0,1,0,.28L60.83,56.44A.37.37,0,0,1,60.51,56.63ZM55.57,53l4.8,2.76L78.94,23.45l-4.8-2.75Z" />
          <Polygon fill="#dce2e7" points="59.82 56.29 54.74 59.98 54.74 59.98 58.02 55.25 59.82 56.29" />
          <Path fill="#9ca6ad" d="M80.2,21.25l-.88,1.55-1.7-1,1.56-2.64a2.1,2.1,0,0,0,.23-1l.23.22A2.6,2.6,0,0,1,80.2,21.25Z" />
          <Polygon fill="#dce2e7" points="78.94 23.45 67.04 44.14 65.21 43.09 77.31 22.52 78.94 23.45" />
          <Polygon fill="#9ca6ad" points="66.67 44.79 60.37 55.74 58.49 54.65 64.86 43.75 66.67 44.79" />
          <Path fillRule="evenodd" d="M25.58,31.82a.21.21,0,0,1,0,.29l-4.24,4.23a.2.2,0,0,1-.15.06.2.2,0,0,1-.14-.06l-2-2.12a.21.21,0,0,1,0-.29.2.2,0,0,1,.28,0l1.81,2,4.09-4.08A.21.21,0,0,1,25.58,31.82Z" />
          <Path fill="#000" d="M21.2,37.18h0a1,1,0,0,1-.7-.32l-2-2.11a1,1,0,0,1-.26-.71,1,1,0,0,1,1.7-.63l1.27,1.38,3.51-3.52a1,1,0,0,1,1.38,0,1,1,0,0,1,.29.69,1,1,0,0,1-.28.69l-4.24,4.24A1,1,0,0,1,21.2,37.18Zm-1.54-3.49ZM25,32.37h0Z" />
          <Path fillRule="evenodd" d="M25.58,45.07a.21.21,0,0,1,0,.29l-4.24,4.23a.2.2,0,0,1-.15.06.2.2,0,0,1-.14-.06l-2-2.12a.21.21,0,0,1,0-.29.2.2,0,0,1,.28,0l1.81,2,4.09-4.08A.21.21,0,0,1,25.58,45.07Z" />
          <Path fill="#000" d="M21.2,50.43h0a1,1,0,0,1-.7-.31l-2-2.12a1,1,0,0,1-.26-.71,1,1,0,0,1,.32-.68.92.92,0,0,1,.7-.26,1,1,0,0,1,.68.31L21.23,48l3.51-3.52a1,1,0,0,1,1.39,0,1,1,0,0,1,.28.69,1,1,0,0,1-.28.69l-4.24,4.24A1,1,0,0,1,21.2,50.43Zm-1.54-3.49Zm6.18-1.32Z" />
          <Path fillRule="evenodd" d="M25.58,58.32a.21.21,0,0,1,0,.29l-4.24,4.23a.2.2,0,0,1-.15.06.2.2,0,0,1-.14-.06l-2-2.12a.21.21,0,0,1,0-.29.2.2,0,0,1,.28,0l1.81,2,4.09-4.08A.21.21,0,0,1,25.58,58.32Z" />
          <Path fill="#000" d="M21.2,63.68h0a1,1,0,0,1-.7-.31l-2-2.12a1,1,0,0,1-.26-.71,1,1,0,0,1,.32-.68,1,1,0,0,1,.7-.26,1,1,0,0,1,.68.31l1.27,1.38,3.51-3.52a1,1,0,0,1,1.39,0,1,1,0,0,1,.28.69,1,1,0,0,1-.28.69l-4.24,4.24A1,1,0,0,1,21.2,63.68Zm-1.54-3.49Zm6.18-1.32Z" />
        </G>
      </G>
    </Svg>
  );
}
