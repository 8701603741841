import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { H2, P2 } from '../../../../base_ui';

export const ExpiredH2 = styled(H2)`
  margin-bottom: ${moderateScale(16)}px;
`;

export const HomeH2 = styled(H2)`
  margin-bottom: ${moderateScale(8)}px;
`;

export const HomeP2 = styled(P2)`
  margin-bottom: ${moderateScale(8)}px;
`;

export const HomeTextContainer = styled.View`
  flex: 1;
  margin-left: ${moderateScale(20)}px;
`;
