/**
  *
  * BaseInlineExternalLink
  * @description External link used as base, with inline/underline styling
  * API Usage: same as BaseExternalLink, but it handles setting props to get default inline and
  *   underline styles.
  *
  * TODO: Currently this involves setting isDisclosureLink and hasUnderline props.
  *   The prop "isDisclosureLink" likely has an overly opinionated name and should probably
  *   (1) be renamed more broadly to "isInline" and (2) by default get underline styling so we don't
  *   really need a dedicated "hasUnderline" prop.
  *   But at time of this component's creation, we don't want to scope creep and commit to
  *   converting any/all disclosure links to have underlines quite yet (though we probably
  *   ultimately will).
  *
  */

import React from 'react';

import BaseExternalLink from './baseExternalLink';

const BaseInlineExternalLink = ({ ...restProps }) => (
  <BaseExternalLink
    hasUnderline
    isDisclosureLink
    {...restProps}
  />
);

export default BaseInlineExternalLink;
