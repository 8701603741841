/* eslint-disable global-require, no-console */
import * as Font from 'expo-font';
import * as React from 'react';

export default function useCachedResources(primaryFont, secondaryFont, props) {
  React.useEffect(() => {
    const { isInitComplete, setIsFontLoading } = props;
    const { name: primaryFontName } = primaryFont;
    const { name: secondaryFontName } = secondaryFont;

    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        if (primaryFontName === 'Roboto' && secondaryFontName === 'Roboto') {
          await Font.loadAsync({
            Roboto: require('../../assets/fonts/Roboto-Bold.ttf'),
            RobotoBold: require('../../assets/fonts/Roboto-Bold.ttf'),
            RobotoRegular: require('../../assets/fonts/Roboto-Regular.ttf'),
          });
        } else if (primaryFontName === 'Arial' && secondaryFontName === 'Arial') {
          await Font.loadAsync({
            Arial: require('../../assets/fonts/arial.ttf'),
            ArialBold: require('../../assets/fonts/FontsFree-Net-arial-bold.ttf'),
            ArialRegular: require('../../assets/fonts/arial.ttf'),
          });
        } else {
          const { boldSrc: primarySrc } = primaryFont;
          const { boldSrc: secondaryBoldSrc, src: secondarySrc } = secondaryFont;
          await Font.loadAsync({
            [primaryFontName]: primarySrc,
            [`${secondaryFontName}Regular`]: secondarySrc,
            [`${secondaryFontName}Bold`]: secondaryBoldSrc,
          });
        }
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      }
    }
    if (isInitComplete) {
      loadResourcesAndDataAsync();
      setIsFontLoading(false);
    }
  }, [
    primaryFont,
    props,
    secondaryFont,
  ]);
}
