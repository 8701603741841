import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const MessageWrapper = styled.View`
  flex-shrink: 1;
`;

export const StatusContainer = styled.View`
  align-items: center;
  border: ${moderateScale(1)}px solid ${({ $color, theme: { colors } }) =>
  $color in colors ? colors[$color] : $color};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  margin: ${moderateScale(8)}px;
  padding: ${moderateScale(8)}px ${moderateScale(12)}px;
`;

export const StatusIconWrapper = styled.View`
  padding-right: ${moderateScale(8)}px;
`;
