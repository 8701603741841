import React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

const VisibilityOn = (props) => (
  <Svg height={moderateScale(24)} viewBox="0 0 26 18" width={moderateScale(24)} {...props}>
    <G fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <G transform="translate(-1.000000, -4.500000)">
        <Path d="M0,0 L28,0 L28,28 L0,28 L0,0 Z M0,0 L28,0 L28,28 L0,28 L0,0 Z M0,0 L28,0 L28,28 L0,28 L0,0 Z M0,0 L28,0 L28,28 L0,28 L0,0 Z" />
        <Path d="M14,5.26 C8.33283266,5.26511472 3.24315412,8.72958438 1.16,14 C3.23766948,19.2751979 8.33039476,22.7428862 14,22.7428862 C19.6696052,22.7428862 24.7623306,19.2751979 26.84,14 C24.7568458,8.72958438 19.6671673,5.26511472 14,5.26 Z M14,19.86 C10.7772647,19.86 8.16370436,17.2493771 8.16003031,14.0266438 C8.1563717,10.8039106 10.7639854,8.18736674 13.9867123,8.17998422 C17.2094392,8.17266364 19.8289632,10.7772836 19.8400682,14 C19.8453162,15.5523267 19.23238,17.0428967 18.1365908,18.1424386 C17.0408016,19.2419805 15.5523358,19.86 14,19.86 L14,19.86 Z M14,10.52 C12.5825671,10.52 11.305093,11.374951 10.7645523,12.6852684 C10.2240116,13.9955858 10.5271407,15.5025544 11.5322849,16.5019549 C12.537429,17.5013553 14.04611,17.7958436 15.3533083,17.2478029 C16.6605065,16.6997621 17.5080996,15.4174098 17.5001143,14 C17.4889988,12.0748184 15.925213,10.52 14,10.52 L14,10.52 Z" fill="#282828" fillRule="nonzero" />
      </G>
    </G>
  </Svg>
);

export default VisibilityOn;
