import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
const Statements = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(65)} viewBox="0 0 64 65" width={moderateScale(64)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="white" height="64" transform="translate(0 1)" width="64" />
      <Path d="M55.3219 29.7044H45.9483C45.9483 29.7044 41.5964 29.9554 39.4204 26.5241C37.2444 23.0927 36.9096 17.4016 36.9933 15.393C37.1607 13.3844 37.2444 10.9573 35.4031 10.6226C33.5619 10.2878 30.549 14.2213 29.9632 16.9832C29.3773 19.745 29.7121 23.6785 30.4653 25.9382C31.2185 28.1142 31.1348 27.7795 31.1348 27.7795H18.0789C18.0789 27.7795 13.3084 26.8588 11.1324 30.625C11.1324 30.625 9.12383 33.6379 11.2998 36.5671C11.2998 36.5671 8.3706 40.2496 9.12383 42.844C9.87706 45.4385 12.2204 45.6059 12.2204 45.6059C12.2204 45.6059 10.1281 49.7905 12.3878 51.6317C12.3878 51.6317 13.978 52.7197 14.8986 52.8034C14.8986 52.8034 13.6432 56.0674 16.154 57.5738C18.6647 59.0803 34.6499 58.5781 34.6499 58.5781C34.6499 58.5781 40.1736 58.6618 43.1028 54.8957H55.7403C55.7403 54.8957 58 51.6317 58 42.1745C58 32.7173 55.3219 29.7044 55.3219 29.7044Z" fill={svgHighlight} />
      <Path d="M34.8172 43.1801C34.8172 43.1801 31.051 39.0792 32.4738 34.2251C33.8966 29.3709 35.0682 30.2079 34.2313 28.0319C33.3944 25.8559 29.2935 18.742 33.9802 11.7119C33.9802 11.7119 27.1175 16.4824 31.1347 27.446C31.1347 27.446 31.8879 28.4503 31.3021 29.8731C30.7163 31.2959 26.699 38.7445 34.8172 43.1801Z" fill="#C1B6D2" />
      <Path d="M26.7817 55.5674C26.7817 55.5674 33.1423 55.9022 35.402 55.4837C37.6616 55.0653 41.0093 53.8936 43.1016 50.7133C45.1939 47.533 45.6961 49.1231 45.6961 49.1231C45.6961 49.1231 44.6081 54.1446 40.7582 56.8228C36.9084 59.5009 23.2666 58.4966 23.2666 58.4966C23.2666 58.4966 26.1958 58.664 26.7817 55.5674Z" fill="#C1B6D2" />
      <Path d="M37.1621 20.082C37.5806 22.1743 38.3338 23.9319 39.3381 26.1916C41.0956 29.7903 46.0335 29.7066 46.0335 29.7066H54.8211" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M31.8053 28.7864C29.9641 25.7734 29.5457 20.7519 29.5457 20.7519C29.462 15.2282 32.4749 11.9643 34.3161 10.9599C35.4878 10.2904 36.4084 10.9599 36.8269 11.6295C36.9942 11.9643 37.0779 12.1316 37.0779 12.1316L37.1616 12.8012L36.9942 15.8141C36.9942 16.651 36.9942 17.4879 36.9942 18.1575" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M32.8918 42.2592C26.3638 36.8192 31.8875 28.9521 31.8875 28.9521" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M34.7337 43.5158C34.3989 43.3484 34.2315 43.2647 33.9805 43.0137" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M11.7197 37.0712C14.3979 39.4146 20.0889 38.9125 20.0889 38.9125C20.0889 38.9125 23.8551 38.9125 24.4409 36.9875C25.0268 35.0626 24.8594 32.8866 22.5997 32.6356L17.8292 32.3008" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M25.2767 27.7822H15.5684C15.5684 27.7822 10.1284 28.4518 10.2958 34.1428C10.2958 35.4819 10.8816 36.4025 11.6349 37.1557" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M27.7867 27.7822H27.1172" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M18.2461 45.8582C20.0873 45.8582 21.1753 45.8582 21.1753 45.8582C21.1753 45.8582 23.6861 45.6071 24.1045 43.3474C24.523 41.0877 23.435 39.3302 21.1753 39.0791" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M12.6396 45.6904C13.2255 45.7741 13.895 45.7741 14.5646 45.7741C15.1504 45.7741 15.8199 45.7741 16.4895 45.7741" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M10.5472 38.3252C9.9614 38.9947 8.8734 40.5849 9.20817 42.7609C9.54293 44.8532 10.9657 45.439 12.6395 45.6901" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M14.9834 52.3864C15.904 52.5538 16.992 52.6375 18.2474 52.6375C22.1809 52.6375 24.8591 52.219 25.1101 49.5409C25.2775 47.7833 23.8548 45.9421 21.5114 45.8584" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M12.4721 45.9434C12.4721 45.9434 11.2167 47.2824 11.6352 49.5421C11.8863 50.6301 12.221 51.216 13.309 51.7181" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M23.4336 58.4131C25.6933 58.0784 26.5302 56.9904 26.6139 55.5676C26.6976 54.3959 26.5302 53.1405 25.5259 52.5547" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M14.8978 52.5547C14.8978 52.5547 13.8098 54.8144 15.2325 56.5719C16.6553 58.3294 18.4965 58.5805 21.9279 58.5805C22.5138 58.5805 23.0159 58.5805 23.4344 58.4968" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M43.7715 53.4734C44.2736 52.7201 44.7758 51.8832 45.1106 50.8789" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M23.8545 58.4135H33.228C33.228 58.4135 39.2538 59.3341 42.9363 54.8984" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M54.4023 54.9824H55.323" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M43.2695 54.9824H52.7267" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.989491" />
      <Path d="M34.4844 6.52368V1" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M25.5283 7.44434L27.9554 9.87141" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
      <Path d="M40.5098 9.87091L43.2716 7.8623" stroke="black" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.627691" />
    </Svg>
  );
};

export default Statements;
