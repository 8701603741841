/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterShield(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(86)} viewBox="0 0 86 86" width={moderateScale(86)} {...props}>
      <Path className="cls-1" d="M71.48,28.24V13.94a.87.87,0,0,0-1.38-.71c-3.67,2.6-14.3,8.34-26.78-1.08-12.48,9.42-23.11,3.68-26.78,1.08a.87.87,0,0,0-1.38.71V43.79c0,15.59,11.84,26.64,28.09,33.52h.06C59.59,70.43,71.48,59.4,71.48,43.79V28.24" fill="#fff" />
      <Path className="cls-2" d="M24.81,43.19V16.89a22.89,22.89,0,0,1-8.27-3.66.87.87,0,0,0-1.38.71V43.79c0,15.59,11.84,26.64,28.09,33.52h.06L44.1,77C26.07,65.3,24.81,43.19,24.81,43.19Z" fill={svgHighlight} />
      <Path className="cls-3" d="M28.75,69a64.93,64.93,0,0,0,14.5,8.28h.06C59.59,70.43,71.48,59.4,71.48,43.79V28.52" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path className="cls-3" d="M23.27,64a40.67,40.67,0,0,0,3.17,3.12" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path className="cls-3" d="M71.48,25.69V13.94a.87.87,0,0,0-1.38-.71c-3.67,2.6-14.3,8.34-26.78-1.08-12.48,9.42-23.11,3.68-26.78,1.08a.87.87,0,0,0-1.38.71V43.79A29,29,0,0,0,21.67,62.1" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="43.49" x2="43.49" y1="60.18" y2="71.87" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="43.49" x2="43.49" y1="24.21" y2="56.4" />
      <Line className="cls-4" fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="43.49" x2="43.49" y1="18.34" y2="21.31" />
      <Path className="cls-5" d="M28.44,20.13a9.55,9.55,0,0,0,1.07,0" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M17.63,30.13V17.2a20.55,20.55,0,0,0,9.05,2.87" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Line className="cls-5" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="17.63" x2="17.63" y1="35.54" y2="31.89" />
      <Path className="cls-5" d="M24.25,7.67A1.35,1.35,0,0,0,23.82,9a1.33,1.33,0,0,0,2.59-.64,1.36,1.36,0,0,0-.81-.92" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M70.17,71a2.61,2.61,0,0,0-5.14.3" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M65.66,73.27A2.61,2.61,0,0,0,70,72.65" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
    </Svg>
  );
}
