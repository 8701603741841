/* eslint-disable max-len, no-param-reassign, no-restricted-globals */
import React from 'react';
import { Pressable, View } from 'react-native';
import T from 'prop-types';

import { initialState } from '@dmi/shared/redux/Payments/OneTimePayment/reducer';

import iconDictionary from '../../../../../utils/iconDictionary';
import { BaseToggle, ConditionalRender } from '../../../../base_ui';
import Content from './Content';
import { ButtonWrapper, EditPaymentRow, StyledH5 } from './styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');

const AdditionalPaymentToggle = ({
  additionalToggle,
  amountFormErrors,
  bannerError,
  canMakeMonthlyPayment,
  fields,
  formState,
  formStateErrors,
  handleOpen,
  minimumPaymentForAdditionalPrincipalOrEscrow,
  payType,
  setAdditionalToggle,
  setFormState,
  setFormStateErrors,
}) => (
  <View>
    <EditPaymentRow>
      <ButtonWrapper $width="100%">
        <BaseToggle
          ariaLabel="additional payments"
          isActive={additionalToggle}
          onPress={() => {
            if (payType === 'Additional' && !canMakeMonthlyPayment) return;
            if (additionalToggle) {
              setFormState({
                additionalEscrow: '0',
                additionalPrincipal: '0',
                lateCharge: '0',
                nsfFees: '0',
                numberOfPayments: formState.numberOfPayments,
                otherFees: '0',
              });
              setFormStateErrors(initialState.otp.formErrors.amount);
            }
            setAdditionalToggle(!additionalToggle);
          }}
        />
        <StyledH5 $disabled={!additionalToggle} $error={!!bannerError}>Additional Payment</StyledH5>
        <Pressable
          onPress={() => handleOpen('additionalPayments')}
          style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
        >
          {InfoIcon}
        </Pressable>
      </ButtonWrapper>
    </EditPaymentRow>
    <ConditionalRender
      Component={Content}
      propsToPassDown={{
        amountFormErrors,
        fields,
        formState,
        formStateErrors,
        minimumPaymentForAdditionalPrincipalOrEscrow,
        setFormState,
        setFormStateErrors,
      }}
      shouldRender={additionalToggle}
    />
  </View>
);

AdditionalPaymentToggle.propTypes = {
  additionalToggle: T.bool.isRequired,
  amountFormErrors: T.object.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  canMakeMonthlyPayment: T.bool.isRequired,
  fields: T.arrayOf(
    T.shape({
      disabledText: T.string.isRequired,
      enabled: T.bool.isRequired,
      field: T.string.isRequired,
      label: T.string.isRequired,
      labelSubText: T.string,
      required: T.bool.isRequired,
    }),
  ).isRequired,
  formState: T.shape({
    additionalEscrow: T.string.isRequired,
    additionalPrincipal: T.string.isRequired,
    lateCharge: T.string.isRequired,
    nsfFees: T.string.isRequired,
    numberOfPayments: T.number.isRequired,
    otherFees: T.string.isRequired,
  }).isRequired,
  formStateErrors: T.shape({
    additionalEscrow: T.string.isRequired,
    additionalPrincipal: T.string.isRequired,
    lateCharge: T.string.isRequired,
    nsfFees: T.string.isRequired,
    numberOfPayments: T.string.isRequired,
    otherFees: T.string.isRequired,
  }).isRequired,
  handleOpen: T.func.isRequired,
  minimumPaymentForAdditionalPrincipalOrEscrow: T.number,
  payType: T.string.isRequired,
  setAdditionalToggle: T.func.isRequired,
  setFormState: T.func.isRequired,
  setFormStateErrors: T.func.isRequired,
};

export default AdditionalPaymentToggle;
