import React, { Fragment, useState } from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Calculators/Amortize/messages';

import {
  BorderlessButton,
  ConditionalRender,
  H3,
  Tooltip,
} from '../../../base_ui';
import Chart from '../../Chart';
import CalculatorDisclaimer from '../../Shared/CalculatorDisclaimer';
import TooltipContent from '../../Shared/TooltipContent';
import {
  BannerContainer,
  ContentContainer,
  DownloadButton,
  HeaderContainer,
  StyledDivider,
  TooltipButton,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const DownloadIcon = iconDictionary('downloadActiveS', { iconState: 'active' });
const InfoIcon = iconDictionary('infoTooltipSIcon');

const CalculatedResults = ({
  chartData,
  dispatchResetCalculator,
  dispatchResetCalculatorResults,
  handleDownloadCSV,
  selectedAdjustmentFilter,
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const { formatMessage } = useIntl();

  return (
    <Fragment>
      <ContentContainer>
        <BannerContainer>
          <CalculatorDisclaimer
            disclaimer={formatMessage({ id: INTL_IDS.AMORTIZATION_BANNER_DISCLAIMER })}
          />
        </BannerContainer>
        <HeaderContainer>
          <H3>Calculated Amortization Chart</H3>
          <TooltipButton
            $pressed={isPressed}
            onPress={() => setIsTooltipOpen(true)}
            onPressIn={() => setIsPressed(true)}
            onPressOut={() => setIsPressed(false)}
          >
            {InfoIcon}
          </TooltipButton>
        </HeaderContainer>
        <Chart
          chartData={chartData}
          label="Calculated Amortization Chart"
        />
        <DownloadButton
          aria-label="Download-calculated-amortization-schedule"
          Icon={DownloadIcon}
          isPrimary
          label="Calculated Amortization Schedule"
          onPress={handleDownloadCSV}
        />
        <StyledDivider />
        <BorderlessButton
          aria-label="Download-calculated-amortization-schedule"
          isPrimary
          label="Reset Calculator"
          onPress={() => {
            dispatchResetCalculator('amortization');
            dispatchResetCalculatorResults('amortization');
          }}
        />
      </ContentContainer>
      <ConditionalRender
        Component={(
          <Tooltip
            handleClose={() => setIsTooltipOpen(false)}
            positionAbsolute={{ left: 'auto', top: 'auto' }}
            visible={isTooltipOpen}
          >
            <TooltipContent
              handleClose={() => setIsTooltipOpen(false)}
              selectedAdjustmentFilter={selectedAdjustmentFilter}
            />
          </Tooltip>
        )}
        shouldRender={isTooltipOpen}
      />
    </Fragment>
  );
};

CalculatedResults.propTypes = {
  chartData: T.object.isRequired,
  dispatchResetCalculator: T.func.isRequired,
  dispatchResetCalculatorResults: T.func.isRequired,
  handleDownloadCSV: T.func.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
};

export default CalculatedResults;
