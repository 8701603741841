/**
 *
 * MessagesAttachmentsUpload
 *
 * @description:
 *  Component to upload initial Jira message attachment
 * @prop {object} dictionary
 *   required object with props to render screen copy
 * @prop {func} dispatchChooseDocument
 *   required function to execute when attachment is added
 * @prop {func} dispatchUpdateAttachments
 *   required function to execute when attachments are submitted
 * @prop {func} handleBack
 *   required function to execute when back button is clicked
 * @prop {string} requestStatusProcess
 *   required string containing the request status
 * @prop {string} reviewScreenName
 *   required string with the attachment review screen name
 */

import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import { AlertBanner } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import PrimaryDocPickerButton from '../../DocUpload/PrimaryDocPickerButton';
import {
  AlertWrapper,
  ContentContainer,
  FlexView,
  StyledH2,
  StyledP2,
  UploadButtonWrapper,
  UploadContainer,
  UploadIllustrationWrapper,
} from './styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(150)}px` });

const MessagesAttachmentsUpload = ({
  dictionary: {
    description,
    headerLabel,
    secondaryHeader,
  },
  dispatchChooseDocument,
  handleBack,
  requestStatusProcess,
  reviewScreenName,
}) => (
  <UploadContainer>
    <SecondaryHeader
      handleBack={handleBack}
      title={headerLabel}
    />
    <ContentContainer>
      <View>
        <UploadIllustrationWrapper>
          {UploadHouse}
        </UploadIllustrationWrapper>
        <StyledH2>
          {secondaryHeader}
        </StyledH2>
        <StyledP2>
          {description}
        </StyledP2>
        <AlertWrapper>
          <AlertBanner
            boldText="Formats accepted: "
            text="JPEG, JPG, PDF, PNG, TIF, TIFF, or GIF (Max size 10 MB)."
          />
        </AlertWrapper>
        <FlexView />
      </View>
      <UploadButtonWrapper>
        <PrimaryDocPickerButton
          chooseDocumentRequestStatus={requestStatusProcess}
          dispatchChooseDocument={dispatchChooseDocument}
          label="Get Started"
          reviewScreenName={reviewScreenName}
        />
      </UploadButtonWrapper>
    </ContentContainer>
  </UploadContainer>
);

MessagesAttachmentsUpload.propTypes = {
  dictionary: T.shape({
    description: T.string.isRequired,
    headerLabel: T.string.isRequired,
    secondaryHeader: T.string.isRequired,
  }).isRequired,
  dispatchChooseDocument: T.func.isRequired,
  handleBack: T.func.isRequired,
  requestStatusProcess: T.string.isRequired,
  reviewScreenName: T.string.isRequired,
};

export default MessagesAttachmentsUpload;
