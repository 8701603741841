/* eslint-disable max-len */
import React from 'react';
import Svg, { Circle } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function UncheckedIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx="12" cy="12" fill="white" r="11.5" stroke="#BDC5CD" />
    </Svg>
  );
}
