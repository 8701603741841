/**
 * LoginRegisterTextField
 * @description: BaseUI LoginRegister Text Field input component.
 *
 * Component props: {
 *     disabled (bool) If true, the input will be disabled (gets passed through) [default false]
 *     error (string) Error text to render, and will set error state to true for style purposes
 *     label (string) The text for the input label if provided (overwritten by errorMessage if
 *                    that is present)
 *     value (string) The input's value (gets passed through)
 * }
 *
 * API Usage: any other props are passed through to our BaseInput (in addition to those props
 * noted with 'gets passed through' above)
 *  -Note re: placeholders
 *    - LoginRegisterTextField uses the label to mimic a placeholder. Thus, you should not give
 * it a placeholder prop. If you do, the ReactNative TextInput will end up rendering both the
 * placeholder and label, and they will at times overlap each other.
 */

import React, { forwardRef } from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import AnimatedLabel from './AnimatedLabel';
import { useLabelAnimation } from './animationLogic';
import {
  LabelDimensionsPlaceholder,
  StyledLoginRegisterInput,
  TextFieldWrapper,
} from './styledComponents';

const LoginRegisterTextField = forwardRef(({
  disabled = false,
  error,
  isRegister,
  label,
  value,
  ...restProps
}, ref) => {
  const {
    handleLabelLayout,
    isFirstRender,
    labelIsShrunk,
    onBlurLabelAnimation,
    onFocusLabelAnimation,
    scaleAnim,
    translateXAnim,
    translateYAnim,
  } = useLabelAnimation({ inputHasValue: !!value });

  const AnimatedLabelElement = (
    <AnimatedLabel
      display={label}
      error={!!error}
      inputDisabled={disabled}
      isFirstRender={isFirstRender}
      isRegister={isRegister}
      isShrunk={labelIsShrunk}
      scale={scaleAnim}
      translateX={translateXAnim}
      translateY={translateYAnim}
    />
  );

  return (
    <TextFieldWrapper>
      <StyledLoginRegisterInput
        ref={ref}
        aria-label={error || label}
        disabled={disabled}
        error={error}
        label={AnimatedLabelElement}
        onBlurSideEffects={onBlurLabelAnimation}
        onFocusSideEffects={onFocusLabelAnimation}
        renderErrorMessage={false}
        value={value}
        {...restProps}
      />
      <View
        accessibilityElementsHidden // hides from screenreader for iOS
        importantForAccessibility="no-hide-descendants" // hides from sr for Android and web
      >
        <LabelDimensionsPlaceholder onLayout={handleLabelLayout}>
          {label}
        </LabelDimensionsPlaceholder>
      </View>
    </TextFieldWrapper>
  );
});

LoginRegisterTextField.propTypes = {
  disabled: T.bool,
  error: T.string,
  isRegister: T.bool,
  label: T.string.isRequired,
  value: T.string.isRequired,
};

export default LoginRegisterTextField;
