import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectClaimByClaimNumber } from '@dmi/shared/redux/LossDraft/selectors';
import { resetState } from '@dmi/shared/redux/LossDraft/actions';

import { StyledScrollView } from '../../../components/base_ui';
import ClaimDetailsComponent from '../../../components/LossDraft/ClaimDetails';

const ClaimDetails = ({
  claimDetails,
  dispatchResetState,
  navigation,
}) => {
  useEffect(() => () => (
    dispatchResetState({ stateToReset: 'selectedClaimNumber' })
  ), [dispatchResetState]);

  return (
    <StyledScrollView>
      <ClaimDetailsComponent claimDetails={claimDetails} navigation={navigation} />
    </StyledScrollView>
  );
};

ClaimDetails.propTypes = {
  claimDetails: T.object.isRequired,
  dispatchResetState: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: LossDraft
   */
  claimDetails: selectClaimByClaimNumber(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: LossDraft
   */
  dispatchResetState: (payload) => dispatch(resetState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ClaimDetails);
