import React from 'react';
import T from 'prop-types';

import { P2 } from '../../Paragraphs';
import {
  BannerContainer,
  IllustrationWrapper,
  MessageContainer,
  Title,
} from './styledComponents';
import iconDictionary from '../../../../utils/iconDictionary';

const Illustration = iconDictionary('megaphoneL', { height: 48, width: 48 });

const OutageBanner = ({ body, title }) => (
  <BannerContainer>
    <IllustrationWrapper>
      {Illustration}
    </IllustrationWrapper>
    <MessageContainer>
      <Title>{title}</Title>
      <P2>{body}</P2>
    </MessageContainer>
  </BannerContainer>
);

OutageBanner.propTypes = {
  body: T.string.isRequired,
  title: T.string.isRequired,
};

export default OutageBanner;
