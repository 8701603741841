import React from 'react';
import T from 'prop-types';

import { ConditionalRender, P3 } from '../../../base_ui';
import PaymentStatus from './PaymentStatus';
import { ICON_DICT, TAX_FIELD_LIST } from './constants';
import {
  CardContainer,
  HeaderContainer,
  HeaderText,
  HeaderWrapper,
  ItemLabel,
  ItemWrapper,
} from './styledComponents';

const TaxCard = ({
  paymentDate,
  paymentIsDue,
  type,
  ...restProps
}) => (
  <CardContainer>
    <HeaderContainer>
      <HeaderWrapper>
        {ICON_DICT[type] || ICON_DICT.taxesFallback}
        <HeaderText>{type}</HeaderText>
      </HeaderWrapper>
      <ConditionalRender
        Component={(
          <PaymentStatus
            paymentDate={paymentDate}
            paymentIsDue={paymentIsDue}
          />
        )}
        shouldRender={!!paymentDate && paymentIsDue !== undefined}
      />
    </HeaderContainer>
    {TAX_FIELD_LIST.map(({ field, label }) => (
      <ConditionalRender
        key={field}
        Component={(
          <ItemWrapper>
            <ItemLabel>{label}</ItemLabel>
            <P3>{restProps[field]}</P3>
          </ItemWrapper>
        )}
        shouldRender={!!restProps[field]}
      />
    ))}
  </CardContainer>
);

TaxCard.propTypes = {
  amount: T.string.isRequired,
  date: T.string.isRequired,
  identifier: T.string,
  installmentFrequency: T.string,
  payeeName: T.string.isRequired,
  paymentDate: T.string,
  paymentIsDue: T.bool,
  type: T.string.isRequired,
};

export default TaxCard;
