import styled from 'styled-components/native';

import { H2, P2 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ButtonWrapper = styled.View`
  align-items: center;
  margin-top: ${moderateScale(25)}px;
  padding-bottom: ${moderateScale(35)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${({ $isUpload }) => $isUpload ? 0 : moderateScale(52)}px;
`;

export const StyledH2 = styled(H2)`
  margin-bottom: ${({ $isConfirmation }) => $isConfirmation ? 0 : moderateScale(24)}px;
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin: 0 0 ${moderateScale(24)}px;
`;
