/* eslint-disable react/jsx-sort-props */
/* eslint-disable max-len */
import React from 'react';
import Svg, { Line, Path } from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

const GreyWarningL = (props) => (
  <Svg
    enableBackground="new 0 0 113 113"
    height={moderateScale(113)}
    id="Layer_1"
    version="1.1"
    viewBox="0 0 113 113"
    width={moderateScale(113)}
    x="0px"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    y="0px"
    {...props}
  >
    <Path
      fill="#FFFFFF"
      stroke="#010101"
      strokeWidth="1.4436"
      strokeMiterlimit="10"
      d="M25.993,63.664L10.961,86.471
    c-1.77,2.688-1.026,6.302,1.662,8.072c0.953,0.628,2.07,0.962,3.212,0.961h86.086c3.218,0.002,5.829-2.605,5.831-5.824
    c0.001-1.114-0.318-2.204-0.918-3.143L67.95,25.614c-1.042-1.616-2.819-2.608-4.741-2.649h-4.543h-1.43
    c-2.45-0.093-4.728,0.424-6.145,2.569L27.755,60.989"
    />
    <Path
      fill="#BAC2CA"
      d="M67.95,25.654c-1.042-1.616-2.819-2.608-4.741-2.649h-4.874c1.19,1.066,2.248,2.271,3.152,3.589
    l37.388,58.393c0,0,4.847,6.847-2.146,10.516h5.192c3.218,0.002,5.829-2.605,5.831-5.824c0.001-1.114-0.318-2.204-0.918-3.143
    L67.95,25.654z"
    />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M95.67,80.14
    l4.092,6.397c1.734,2.711,0.942,6.315-1.769,8.049c-0.218,0.14-0.446,0.265-0.681,0.374"
    />
    <Line fill="none" stroke="#010101" strokeWidth="1.0728" strokeLinecap="round" strokeLinejoin="round" x1="60.865" y1="25.654" x2="93.511" y2="76.75" />
    <Path
      fill="#D6DDE4"
      d="M55.991,78.405h0.94c2.984,0,5.404,2.419,5.404,5.404v0.94c0,2.984-2.419,5.404-5.404,5.404h-0.94
    c-2.984,0-5.404-2.419-5.404-5.404v-0.94C50.587,80.824,53.007,78.405,55.991,78.405z"
    />
    <Path
      fill="#D6DDE4"
      d="M57.713,74.962h-2.49c-1.316,0.004-2.412-1.006-2.516-2.318l-2.185-28.17
    c-0.249-3.194,2.139-5.985,5.333-6.234c0.147-0.011,0.294-0.017,0.441-0.017h0.331c3.211,0.006,5.81,2.613,5.804,5.824
    c0,0.142-0.006,0.285-0.016,0.427l-2.199,28.17C60.118,73.953,59.026,74.965,57.713,74.962z"
    />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M62.189,85.875
    c0.138-0.519,0.209-1.053,0.212-1.589l0,0c0.007-3.24-2.614-5.873-5.854-5.88c-0.004,0-0.009,0-0.013,0l0,0
    c-3.248,0-5.88,2.633-5.88,5.88l0,0c0,3.24,2.627,5.867,5.867,5.867c0.004,0,0.009,0,0.013,0l0,0
    c1.615,0.004,3.16-0.663,4.265-1.841"
    />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M57.713,74.962h-2.49
    c-1.316,0.004-2.412-1.006-2.516-2.318l-2.185-28.17c-0.249-3.194,2.139-5.985,5.333-6.234c0.147-0.011,0.294-0.017,0.441-0.017
    h0.331c3.211,0.006,5.81,2.613,5.804,5.824c0,0.142-0.006,0.285-0.016,0.427l-2.199,28.17
    C60.118,73.953,59.026,74.965,57.713,74.962z"
    />
    <Line fill="none" stroke="#010101" strokeWidth="1.0728" strokeLinecap="round" strokeLinejoin="round" x1="58.997" y1="15.099" x2="58.997" y2="6" />
    <Line fill="none" stroke="#010101" strokeWidth="1.0728" strokeLinecap="round" strokeLinejoin="round" x1="49.753" y1="18.476" x2="46.76" y2="15.138" />
    <Line fill="none" stroke="#010101" strokeWidth="1.0728" strokeLinecap="round" strokeLinejoin="round" x1="68.228" y1="18.476" x2="71.235" y2="15.138" />
    <Line fill="none" stroke="#010101" strokeWidth="1.0728" strokeLinecap="round" strokeLinejoin="round" x1="23.755" y1="73.717" x2="39.634" y2="49.07" />
    <Line fill="none" stroke="#010101" strokeWidth="1.0728" strokeLinecap="round" strokeLinejoin="round" x1="20.921" y1="78.114" x2="22.232" y2="76.074" />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M20.179,47.321
    c0.537-1.556-0.288-3.252-1.844-3.79c-1.556-0.537-3.252,0.288-3.79,1.844c-0.537,1.556,0.288,3.252,1.844,3.79
    c0.682,0.236,1.426,0.216,2.094-0.056"
    />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M74.215,105.754
    c-0.537,0.476-0.771,1.208-0.609,1.907c0.321,1.011,1.401,1.57,2.412,1.248c0.916-0.291,1.476-1.215,1.31-2.162
    c-0.15-0.6-0.578-1.093-1.152-1.324"
    />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M101.179,28.144
    c-0.443-2.022-2.441-3.302-4.463-2.859c-1.583,0.347-2.763,1.671-2.927,3.283"
    />
    <Path
      fill="none"
      stroke="#010101"
      strokeWidth="1.0728"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M94.69,31.429
    c1.358,1.562,3.725,1.728,5.288,0.37c0.404-0.351,0.728-0.784,0.95-1.271"
    />
  </Svg>
);

export default GreyWarningL;
