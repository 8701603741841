/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function WindInsuranceIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M4.7,12.4c-2,0-3.7-1.6-3.7-3.6c0-2,1.6-3.6,3.7-3.6c1.2,0,2.3,0.6,3,1.6c0.2,0.3,0.1,0.6-0.1,0.8C7.3,7.8,6.9,7.7,6.7,7.5C6.3,6.8,5.5,6.4,4.7,6.4c-1.4,0-2.5,1.1-2.5,2.4s1.1,2.4,2.5,2.4c0.3,0,0.6,0.3,0.6,0.6S5,12.4,4.7,12.4z" fill={iconStatic} />
      <Path d="M5.3,6.4C5,6.4,4.8,6.2,4.7,5.9c0-0.1,0-0.3,0-0.4c0-2.2,1.8-4,4-4s4,1.8,4,4c0,0.1,0,0.3,0,0.4c0,0.3-0.3,0.6-0.6,0.5c-0.3,0-0.6-0.3-0.5-0.6c0-0.1,0-0.2,0-0.3c0-1.6-1.3-2.8-2.8-2.8S5.9,3.9,5.9,5.5c0,0.1,0,0.2,0,0.3C5.9,6.1,5.7,6.4,5.3,6.4C5.4,6.4,5.3,6.4,5.3,6.4z" fill={iconStatic} />
      <Path d="M13.1,12.4c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5c-0.3,0-0.5,0-0.7,0.1C12,6.4,11.7,6.3,11.6,6c-0.1-0.3,0.1-0.6,0.4-0.7c0.4-0.1,0.7-0.2,1.1-0.2c2,0,3.7,1.6,3.7,3.7C16.7,10.8,15.1,12.4,13.1,12.4z" fill={iconStatic} />
      <Path d="M10.6,12.5H4.9c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h5.7c0.3,0,0.6,0.3,0.6,0.6S11,12.5,10.6,12.5z" fill={iconStatic} />
      <Path d="M9.2,16.4H8.4c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h0.8c0.2,0,0.4-0.2,0.4-0.4s-0.2-0.4-0.4-0.4h-5c-0.3,0-0.6-0.3-0.6-0.6s0.3-0.6,0.6-0.6h5c0.9,0,1.6,0.7,1.6,1.6S10.1,16.4,9.2,16.4z" fill={iconStatic} />
      <Path d="M12.3,10.3h-5C7,10.3,6.7,10,6.7,9.7S7,9.1,7.3,9.1h5c0.2,0,0.4-0.2,0.4-0.4c0-0.2-0.2-0.4-0.4-0.4h-0.8c-0.3,0-0.6-0.3-0.6-0.6c0-0.3,0.3-0.6,0.6-0.6h0.8c0.9,0,1.6,0.7,1.6,1.6S13.2,10.3,12.3,10.3z" fill={iconStatic} />
    </Svg>
  );
}
