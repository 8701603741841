/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Ellipse,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterHurricane(props) {
  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} {...props}>
      <Path className="cls-1" d="M77.48,28.58c2.7-1,3.12-4.48,1.72-6.45s-3.7-2.2-6.3-2.2a16.42,16.42,0,0,0-2,.13,1.27,1.27,0,0,1-1.34-1.38c0-4.17-4.67-7.55-10.42-7.55a14,14,0,0,0-4.48.73,1.15,1.15,0,0,1-1.46-.94C52,4.73,46.11,0,39,0,31.09,0,24.64,6,24.64,13.28c0,.26,0,.52,0,.78a5.58,5.58,0,0,0-1-.1,6.82,6.82,0,0,0-6.35,5.23,1.16,1.16,0,0,1-1.44.87,6,6,0,0,0-1.81-.28,6.4,6.4,0,0,0-5.23,2.85c-1.42,2-.14,5,2.08,6.17H38c12.23,0,24.47-.15,36.69,0A7.91,7.91,0,0,0,77.48,28.58Z" fill="#e6eaed" />
      <Path className="cls-2" d="M25.19,43.13,13.06,57a.7.7,0,0,0,.53,1.14l35.86,0,9.38-12.46,1.84-2.44Z" fill="#fff" />
      <Rect className="cls-2" fill="#fff" height="23.17" width="32.96" x="17.19" y="58.34" />
      <Rect className="cls-3" fill="none" height="23.21" width="34.75" x="16.08" y="57.91" />
      <Path className="cls-4" d="M49,59.21H17.07v-1l32.18-.13,1.55-1.64,0,1.32-1,1.06A1.15,1.15,0,0,1,49,59.21Z" fill="#b9c1c9" />
      <Path className="cls-5" d="M56.31,47.75l3.76-4.26a.77.77,0,0,1,.6-.26.81.81,0,0,1,.59.28L72,56.47a.84.84,0,0,1-.09,1.15.77.77,0,0,1-1.11-.09L60.64,45.27,49.49,57.88a.78.78,0,0,1-.58.26.77.77,0,0,1-.53-.21.83.83,0,0,1-.06-1.15L55.18,49" fill="#dbe1e6" />
      <Polygon className="cls-2" fill="#fff" points="60.81 45.57 51.09 56.56 51.09 81.19 68.93 81.19 68.93 58.1 71.14 58.1 60.81 45.57" />
      <Polygon className="cls-4" fill="#b9c1c9" points="59.93 46.24 68.89 56.94 68.89 55.4 60.66 45.42 59.93 46.24" />
      <Polygon className="cls-4" fill="#b9c1c9" points="68.89 55.28 68.89 57.86 71.04 57.91 68.89 55.28" />
      <Polyline className="cls-6" fill="none" points="30.4 43.22 25.35 43.22 13.27 56.57" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" />
      <Polyline className="cls-6" fill="none" points="33.12 43.2 45.11 43.2 41.75 45 42.66 47.45 38.03 48.26 34.24 53.09 38.45 50.36 43.99 49.61 45.41 45.69 49.75 43.24 60.67 43.24" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="17.19" x2="17.19" y1="58.48" y2="62.48" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="50.9" x2="50.9" y1="69.93" y2="80.79" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="50.9" x2="50.9" y1="56.48" y2="68.23" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="68.9" x2="68.9" y1="55.8" y2="80.79" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="71.11" x2="68.9" y1="57.83" y2="57.83" />
      <Path className="cls-6" d="M56.42,47.59l3.65-4.1a.77.77,0,0,1,.6-.26.81.81,0,0,1,.59.28L72,56.47a.84.84,0,0,1-.09,1.15.77.77,0,0,1-1.11-.09L60.64,45.27,49.49,57.88a.78.78,0,0,1-.58.26.77.77,0,0,1-.53-.21.83.83,0,0,1-.06-1.15l6.55-7.41" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="55.49" x2="55.85" y1="48.68" y2="48.28" />
      <Path className="cls-6" d="M13.43,56.56s-.53.06-.53.78h0a.79.79,0,0,0,.78.79H49.11" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" />
      <Polyline className="cls-7" fill="#e6eaed" points="55.79 80.94 55.79 63.38 64.52 63.38 64.52 80.94" stroke="#000" strokeMiterlimit="10" strokeWidth="0.68px" />
      <Ellipse className="cls-8" cx="59.99" cy="67.63" fill="#fff" rx="0.38" ry="0.39" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="81.02" x2="4.08" y1="81.28" y2="81.28" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="81.05" x2="81.9" y1="81.28" y2="81.28" />
      <Line className="cls-9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="1.89" x2="2.75" y1="81.3" y2="81.3" />
      <Line className="cls-10" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="48.85" x2="42.75" y1="18.01" y2="25.39" />
      <Line className="cls-10" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="28.18" x2="22.08" y1="21.91" y2="29.29" />
      <Line className="cls-10" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="64.6" x2="58.51" y1="21.7" y2="29.08" />
      <Line className="cls-11" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.6px" x1="64.6" x2="60.15" y1="32.87" y2="38.25" />
      <Line className="cls-10" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="66.15" x2="65.43" y1="31" y2="31.87" />
      <Line className="cls-11" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.6px" x1="41.55" x2="40.83" y1="38.74" y2="39.61" />
      <Line className="cls-10" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="29.6" x2="28.88" y1="20.24" y2="21.11" />
      <Line className="cls-11" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.6px" x1="47.52" x2="42.38" y1="31.51" y2="37.72" />
      <Line className="cls-10" fill="#fff" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="33.73" x2="27.63" y1="29.18" y2="36.56" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="17.19" x2="17.19" y1="64.42" y2="81.13" />
      <Rect className="cls-2" fill="#fff" height="1.02" rx="0.45" width="34.25" x="13.27" y="56.69" />
      <Line className="cls-6" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.68px" x1="13.81" x2="47.26" y1="56.42" y2="56.42" />
    </Svg>
  );
}
