import {
  AddEscrowConfirmationScreen,
  AddEscrowReviewDocScreen,
  AddEscrowScreen,
  AddEscrowSetUpScreen,
  AddEscrowUploadDocScreen,
} from '../../../containers/Mortgage/AddEscrow';
import { MortgageDetailsScreen } from '../../../containers/Mortgage/MortgageOverview';
import EscrowHistoryScreen, { // eslint-disable-line object-curly-newline
  EscrowPaymentDetailScreen,
} from '../../../containers/Mortgage/EscrowHistory';
import { TaxesInsuranceDetailsScreen } from '../../../containers/Mortgage/TaxesInsurance';
import {
  InsuranceConfirmationScreen,
  InsuranceDetailsScreen,
  InsuranceUploadReviewScreen,
  InsuranceUploadScreen,
} from '../../../containers/Mortgage/InsuranceUpload';
import MortgageLoanDetailsExpandableScreen from
'../../../containers/Mortgage/MortgageOverview/MortgageLoanDetailsExpandableScreen';
import HelocDrawScreens from './helocDraw';

export default {
  MortgageDetails: { component: MortgageDetailsScreen },
  MortgageLoanDetailsExpandableScreen: { component: MortgageLoanDetailsExpandableScreen },
  TaxesInsuranceDetails: { component: TaxesInsuranceDetailsScreen },
  // AddEscrow Screens
  AddEscrow: { component: AddEscrowScreen }, // eslint-disable-line sort-keys
  AddEscrowConfirmation: { component: AddEscrowConfirmationScreen },
  AddEscrowReviewDoc: { component: AddEscrowReviewDocScreen },
  AddEscrowSetUp: { component: AddEscrowSetUpScreen },
  AddEscrowUploadDoc: { component: AddEscrowUploadDocScreen },
  // Escrow Screens
  EscrowHistory: { component: EscrowHistoryScreen }, // eslint-disable-line sort-keys
  EscrowPaymentDetail: { component: EscrowPaymentDetailScreen },
  // HelocDraw Screens
  ...HelocDrawScreens,
  // Insurance Screens
  InsuranceConfirmation: { component: InsuranceConfirmationScreen },
  InsuranceDetails: { component: InsuranceDetailsScreen },
  InsuranceUpload: { component: InsuranceUploadScreen },
  InsuranceUploadReview: { component: InsuranceUploadReviewScreen },
};
