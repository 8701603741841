import React from 'react';
import T from 'prop-types';

import { interpolate } from '@dmi/shared/utils/globalHelpers';

import { FormattedMessage } from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  BackButton,
  BoldText,
  Container,
  Modal,
  StyledProgressButton,
  Text,
  Title,
} from './styledComponents';

const Icon = iconDictionary('registerRobot');

const PaymentModal = ({
  amount,
  customerServicePhoneNumber,
  date,
  handleCloseModal,
  handleOnPress,
  isOpen,
  label,
  loading,
  status,
  text,
  title,
}) => {
  const isLoading = loading || status === 'loading';
  const closeHandler = isLoading ? null : handleCloseModal;

  return (
    <Modal handleCloseModal={closeHandler} visible={isOpen}>
      <Container>
        {Icon}
        <Title>{title}</Title>
        <FormattedMessage
          defaultMessage={interpolate(text, { customerServicePhoneNumber })}
          // eslint-disable-next-line react/prop-types
          values={{ amount, b: ({ msg }) => <BoldText>{msg}</BoldText>, date }}
          WrapperComponent={Text}
        />
        <StyledProgressButton
          label={label}
          loading={loading}
          onPress={handleOnPress}
          status={status}
        />
        <BackButton disabled={isLoading} label="Go Back" onPress={handleCloseModal} />
      </Container>
    </Modal>
  );
};

PaymentModal.propTypes = {
  amount: T.string,
  customerServicePhoneNumber: T.string,
  date: T.string,
  handleCloseModal: T.func.isRequired,
  handleOnPress: T.func.isRequired,
  isOpen: T.bool.isRequired,
  label: T.string.isRequired,
  loading: T.bool,
  status: T.string,
  text: T.string.isRequired,
  title: T.string.isRequired,
};

export default PaymentModal;
