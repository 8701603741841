import styled from 'styled-components/native';

import { BorderlessButton } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const Container = styled.View`
  background-color: ${({ theme: { colors: { footerBackground } } }) => footerBackground};
  padding: 0;
  width: 100%;
`;

export const StyledBorderlessButton = styled(BorderlessButton)
  .attrs(({
    theme: {
      colors: { footerLinkText, linkText },
      fontSizes: { f3 },
    },
    titleStyle,
  }) => ({
    titleStyle: [
      titleStyle,
      {
        color: footerLinkText || linkText,
        fontSize: f3,
        paddingVertical: moderateScale(8),
      },
    ],
  }))``;
