import React from 'react';

import { Skeleton } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

const StatusBlockSkeleton = () => (
  <Skeleton
    $borderRadius={`${moderateScale(4)}px`}
    height={`${moderateScale(52)}px`}
    width="100%"
  />
);

export default StatusBlockSkeleton;
