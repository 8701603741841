import React from 'react';
import { Linking } from 'react-native';

import { defaultContactInfo } from '@dmi/shared/redux/LossDraft/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { P2 } from '../../base_ui';
import {
  ContactCard,
  ContactContainer,
  ContactHeader,
  ContactText,
  Row,
  StyledLink,
} from './styledComponents';

const ClockIcon = iconDictionary('pendingCarbon');
const EditIcon = iconDictionary('edit');
const PhoneIcon = iconDictionary('phone');

const ContactInformationCard = () => {
  const {
    email,
    extension,
    heading,
    hours,
    phone,
  } = defaultContactInfo;

  return (
    <ContactCard>
      <ContactHeader>{heading}</ContactHeader>
      <ContactContainer>
        <Row>
          {PhoneIcon}
          <StyledLink
            onPress={() =>
              Linking.openURL(`tel://${phone.replace(/\+|-/g, '')}`)}
          >
            {phone}
          </StyledLink>
          <P2>, {extension}</P2>
        </Row>
        <Row>
          {EditIcon}
          <StyledLink onPress={() => Linking.openURL(`mailto:${email}`)}>
            {email}
          </StyledLink>
        </Row>
        <Row>
          {ClockIcon}
          <ContactText>{hours}</ContactText>
        </Row>
      </ContactContainer>
    </ContactCard>
  );
};

export default ContactInformationCard;
