import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H5, PaymentTextField } from '../../base_ui';

export const BannerContainer = styled.View`
  margin-bottom: ${moderateScale(16)}px;
`;

export const ContentContainer = styled.View`
  flex-grow: 1;
  justify-content: flex-start;
  padding: ${moderateScale(16)}px ${moderateScale(24)}px;
`;

export const HeaderContainer = styled.View`
  align-items: center;
  align-self: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(20)}px;
`;

export const InputLabel = styled(H5)`
  margin: ${moderateScale(20)}px ${moderateScale(12)}px 0 0;
`;

export const PaymentTextFieldContainer = styled.View`
  flex-direction: row;
  height: ${moderateScale(55)}px;
  margin: ${moderateScale(14)}px 0 ${moderateScale(20)}px;
`;

export const StyledPaymentTextField = styled(PaymentTextField).attrs({
  errorStyle: { lineHeight: 12, marginTop: moderateScale(0), width: '100%' },
  inputContainerStyle: { marginBottom: moderateScale(4), width: '100%' },
  labelStyle: { display: 'none' },
})``;

export const TooltipButton = styled.Pressable`
  margin-left: ${moderateScale(8)}px;
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
`;
