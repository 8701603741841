import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import { AlertBanner, H4, StyledScrollView } from '../../base_ui';
import PrimaryDocUploadButton from '../../DocUpload/PrimaryDocPickerButton';
import { EditContainer, FlexView } from '../styledComponents';
import {
  ButtonWrapper,
  IllustrationWrapper,
  StyledH2,
  StyledP2,
} from './styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(210)}px` });

const LegalNameUploadComponent = ({
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  reviewScreenName,
}) => {
  const { subheader, text } = PROFILE_CONTENT_DICTIONARY.editLegalName.uploadView;
  const { divorce, id, marriage } = text;

  return (
    <StyledScrollView>
      <EditContainer>
        <IllustrationWrapper $isUpload>
          {UploadHouse}
        </IllustrationWrapper>
        <StyledH2>{subheader}</StyledH2>
        <H4>{marriage.header}</H4>
        <StyledP2>{marriage.subheader}</StyledP2>
        <H4>{divorce.header}</H4>
        <StyledP2>
          <FormattedMessage id={divorce.subheader} />
        </StyledP2>
        <H4>{id.header}</H4>
        <StyledP2>{id.subheader}</StyledP2>
        <AlertBanner
          boldText="Formats accepted: "
          text="JPEG, JPG, PDF, PNG, TIFF, or GIF (Max size 10 MB)."
        />
        <FlexView />
        <ButtonWrapper>
          <PrimaryDocUploadButton
            chooseDocumentRequestStatus={chooseDocumentRequestStatus}
            dispatchChooseDocument={dispatchChooseDocument}
            reviewScreenName={reviewScreenName}
          />
        </ButtonWrapper>
      </EditContainer>
    </StyledScrollView>
  );
};

LegalNameUploadComponent.propTypes = {
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  reviewScreenName: T.string.isRequired,
};

export default LegalNameUploadComponent;
