/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function LoginSpring(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg enableBackground="new 0 0 287.25 120.25" height={moderateScale(120)} id="Layer_1" viewBox="0 0 287.25 120.25" width={moderateScale(287)} x="0px" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" y="0px" {...props}>
      <G>
        <Path
          d="M269.475,71.609c0-5.51-3.651-10.164-8.666-11.683c-0.559-0.169-0.789-0.808-0.481-1.304
          c0.872-1.408,1.375-3.067,1.375-4.845c0-5.089-4.126-9.215-9.215-9.215c-0.054,0-0.105,0.007-0.159,0.008
          c-0.587-4.345-4.301-7.698-8.807-7.698c-4.512,0-8.23,3.362-8.81,7.715c-0.166-0.009-0.33-0.025-0.498-0.025
          c-5.089,0-9.215,4.126-9.215,9.215c0,1.867,0.559,3.602,1.515,5.053c0.315,0.478,0.112,1.11-0.427,1.305
          c-4.703,1.696-8.069,6.187-8.069,11.475c0,5.155,3.199,9.554,7.716,11.346l-0.012,0.013c0,0,4.881,2.133,14.924,2.195
          c1.003,0.039,2.046,0.063,3.13,0.063c0.993,0,1.961-0.023,2.901-0.061c10.155-0.039,15.086-2.197,15.086-2.197l-0.01-0.011
          C266.273,81.167,269.475,76.766,269.475,71.609z"
          fill="#D8DFE5"
        />
        <Path
          d="M88.306,45.864c2.032,0,3.41-2.056,2.65-3.941c-1.609-3.992-5.012-9.674-11.486-10.345
          c-2.726-0.282-5.26,0.796-7.254,2.126c-0.178,0.119-0.409,0.095-0.566-0.05c-1.376-1.274-3.359-2.075-5.565-2.075
          c-4.154,0-7.522,2.836-7.522,6.335v0.005c0,0.272-0.231,0.486-0.502,0.461c-0.202-0.018-0.406-0.027-0.614-0.027
          c-2.553,0-4.717,1.393-5.486,3.325c-0.096,0.241-0.366,0.353-0.603,0.249c-0.678-0.297-1.447-0.487-2.264-0.466
          c-1.864,0.049-3.08,1.089-3.835,2.146c-0.675,0.945-0.014,2.256,1.147,2.256L88.306,45.864z"
          fill="#D9E1E8"
        />
        <Path
          d="M64.112,83.308c-0.869-0.142-5.319-1.292-6.228-1.292c-5.424,0-10.23,2.635-13.216,6.692
          c-0.598,0.813-1.649,1.112-2.603,0.785c-1.161-0.398-2.407-0.614-3.703-0.614c-3.605,0-6.821,1.668-8.918,4.274
          c0,0-2.286,2.994-2.468,6.909l0.11,2.681l55.076-0.326L81.917,81.45c0,0-1.762-1.259-4.891-1.06
          c-3.129,0.199-5.429,1.728-5.429,1.728s-1.81-1.743-4.043-1.418S64.112,83.308,64.112,83.308z"
          fill="#EFF3F6"
        />
        <Path
          d="M66.965,95.373c0,0,0.302-2.726-1.352-3.597s-4.817-0.551-4.817-0.551s0.638,6.123,5.165,4.991v5.369h1.298
          v-6.31L66.965,95.373z"
          fill="#FFFFFF"
        />
        <Path d="M65.584,91.457c0,0,0.813-3.801,6.326-3.395c0,0,0.754,7.632-4.556,8.067" fill="#FFFFFF" />
        <Path
          d="M54.517,95.373c0,0,0.302-2.726-1.352-3.597s-4.817-0.551-4.817-0.551s0.638,6.123,5.165,4.991v5.369h1.298
          v-6.31L54.517,95.373z"
          fill="#FFFFFF"
        />
        <Path d="M53.136,91.457c0,0,0.813-3.801,6.326-3.395c0,0,0.754,7.632-4.556,8.067" fill="#FFFFFF" />
        <Path
          d="M79.389,95.373c0,0,0.302-2.726-1.352-3.597s-4.817-0.551-4.817-0.551s0.638,6.123,5.165,4.991v5.369h1.298
          v-6.31L79.389,95.373z"
          fill="#FFFFFF"
        />
        <Path
          d="M59.661,83.715l1.137,1.283l3.167,0.885c0,0,0.603,0.293,0.978-0.147s0.815-2.804,2.674-3.408
          c1.859-0.603,3.308,0.98,3.88,1.386c0.897,0.636,2.346-1.104,4.924-1.06c2.87,0.049,5.087,2.12,5.56,2.837v-4.06
          c0,0-2.087-1.386-5.315-1.125c-3.228,0.261-4.418,1.761-5.217,1.435c-0.799-0.326-2.185-1.565-4.076-1.076
          c-1.891,0.489-2.658,2.69-3.196,2.527c-0.538-0.163-3.538-0.897-3.946-0.978C59.824,82.134,59.661,83.715,59.661,83.715z"
          fill="#DCE2E7"
        />
        <Path
          d="M30.532,92.184c0,0,3.491-2.847,9.117-1.559s6.304-0.068,7.999-2.101c1.695-2.034,8.236-4.914,13.15-3.525
          v-2.678c0,0-5.728-1.254-10.541,1.593s-5.491,5.05-6.236,5.355c-0.746,0.305-1.051,0.475-3.355-0.169
          c-2.305-0.644-7.558,0.136-10.236,3.22"
          fill="#DCE2E7"
        />
        <Path
          d="M26.906,100.995c-0.205,0-0.373-0.165-0.375-0.371c-0.024-2.192,0.558-4.339,1.682-6.208
          c0.106-0.178,0.337-0.234,0.515-0.128c0.177,0.106,0.235,0.337,0.128,0.515c-1.053,1.75-1.597,3.76-1.575,5.813
          c0.002,0.207-0.164,0.377-0.371,0.379C26.909,100.995,26.907,100.995,26.906,100.995z"
          fill="#000000"
        />
        <Path
          d="M29.444,93.527c-0.083,0-0.166-0.027-0.235-0.083c-0.162-0.13-0.187-0.365-0.057-0.527
          c2.256-2.805,5.613-4.413,9.209-4.413c1.319,0,2.616,0.217,3.853,0.645c0.788,0.272,1.677-0.028,2.211-0.744
          c3.204-4.299,8.109-6.764,13.458-6.764c0.915,0,1.833,0.074,2.73,0.222c0.091,0.017,2.053,0.384,3.439,1.131
          c0.309-0.723,1.1-1.944,3.03-2.538c2.132-0.656,3.818,0.576,4.446,1.136c0.066,0.06,0.16,0.067,0.231,0.019
          c1.173-0.765,5.381-3.077,10.271-0.692c0.187,0.091,0.264,0.314,0.173,0.501c-0.09,0.187-0.313,0.267-0.501,0.173
          c-4.537-2.208-8.443-0.063-9.533,0.647c-0.353,0.231-0.823,0.197-1.141-0.088c-0.662-0.589-2.027-1.503-3.726-0.979
          c-1.698,0.522-2.348,1.585-2.585,2.173c-0.073,0.182-0.219,0.323-0.401,0.387c-0.186,0.067-0.394,0.047-0.571-0.052
          c-1.28-0.707-3.242-1.075-3.261-1.078c-0.85-0.14-1.728-0.211-2.602-0.211c-5.11,0-9.796,2.355-12.856,6.461
          c-0.73,0.98-1.959,1.383-3.058,1.006c-1.159-0.4-2.373-0.604-3.608-0.604c-3.368,0-6.512,1.507-8.625,4.134
          C29.662,93.479,29.553,93.527,29.444,93.527z M64.111,83.024L64.111,83.024L64.111,83.024L64.111,83.024z"
          fill="#000000"
        />
        <Path
          d="M229.088,103.09c0.01-0.136,0.015-0.274,0.015-0.412c0-3.092-2.506-5.598-5.598-5.598
          c-0.116,0-0.232,0.005-0.346,0.012c-1.84-3.137-5.246-5.245-9.146-5.245c-0.251,0-0.501,0.009-0.748,0.026
          c-1.035,0.073-2.028-0.435-2.55-1.332c-1.836-3.154-5.252-5.275-9.165-5.275v17.775"
          fill="#EFF3F6"
        />
        <Path
          d="M229.103,103.1c-0.062,0-0.12-0.017-0.172-0.045c-0.138-0.06-0.229-0.195-0.217-0.354c0.002-0.025,0.006-0.051,0.013-0.076
          c-0.043-2.87-2.369-5.169-5.222-5.169c-0.108,0-0.215,0.004-0.321,0.011c-0.143,0.014-0.277-0.062-0.349-0.185
          c-1.831-3.121-5.212-5.061-8.823-5.061c-0.169,0-0.337,0.005-0.504,0.013c-1.31,0.065-2.552-0.583-3.229-1.692
          c-1.875-3.068-5.139-4.9-8.729-4.9c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375c3.854,0,7.356,1.966,9.37,5.26
          c0.534,0.874,1.495,1.396,2.554,1.333c0.179-0.008,0.358-0.013,0.539-0.013c3.801,0,7.363,2.001,9.353,5.236
          c3.362-0.056,6.113,2.631,6.113,5.971c0,0.199-0.157,0.411-0.356,0.422C229.115,103.1,229.108,103.1,229.103,103.1z"
          fill="#000000"
        />
        <Path
          d="M220.685,98.365c-0.088,0-0.174-0.047-0.219-0.129c-0.257-0.465-0.558-0.898-0.895-1.289
          c-0.09-0.104-0.078-0.262,0.026-0.353c0.105-0.09,0.263-0.078,0.353,0.026c0.359,0.416,0.681,0.879,0.953,1.373
          c0.066,0.121,0.022,0.273-0.098,0.34C220.767,98.355,220.726,98.365,220.685,98.365z"
          fill="#000000"
        />
        <Path
          d="M218.548,95.906c-0.052,0-0.104-0.016-0.149-0.05c-2.041-1.52-4.318-1.596-4.342-1.597
          c-0.138-0.004-0.247-0.118-0.243-0.257c0.004-0.136,0.115-0.243,0.25-0.243c0.002,0,0.004,0,0.007,0
          c0.1,0.003,2.46,0.082,4.627,1.696c0.11,0.082,0.134,0.239,0.051,0.35C218.699,95.871,218.624,95.906,218.548,95.906z"
          fill="#000000"
        />
        <Rect fill="#E6EBEF" height="2.108" width="8.35" x="154.598" y="21.425" />
        <G>
          <Polyline fill={svgHighlight} points="114.056,102.749 114.056,71.377 131.199,71.377 131.199,102.749" />
          <Path
            d="M131.199,103.124c-0.207,0-0.375-0.168-0.375-0.375V71.752h-16.393v30.997c0,0.207-0.168,0.375-0.375,0.375
            s-0.375-0.168-0.375-0.375V71.377c0-0.207,0.168-0.375,0.375-0.375h17.143c0.207,0,0.375,0.168,0.375,0.375v31.372
            C131.574,102.956,131.406,103.124,131.199,103.124z"
            fill="#000000"
          />
        </G>
        <G>
          <Circle cx="122.3" cy="79.29" fill="#FFFFFF" r="0.74" />
          <Path
            d="M122.3,80.28c-0.546,0-0.99-0.444-0.99-0.99s0.444-0.989,0.99-0.989s0.99,0.443,0.99,0.989S122.846,80.28,122.3,80.28z
            M122.3,78.801c-0.27,0-0.49,0.22-0.49,0.489c0,0.271,0.22,0.49,0.49,0.49c0.271,0,0.49-0.22,0.49-0.49
            C122.79,79.021,122.57,78.801,122.3,78.801z"
            fill="#000000"
          />
        </G>
        <Path
          d="M162.243,61.229h-80.34v-1.822l80.811-0.26l3.047-3.123l0.076,2.516l-1.999,2.023
          C163.417,60.989,162.843,61.229,162.243,61.229z"
          fill="#BAC2CA"
        />
        <Polygon fill="#BAC2CA" points="76.699,55.677 99.79,30.522 184.563,30.522 161.021,56.167 123.54,56.167" />
        <Rect fill="#FFFFFF" height="5.378" width="8.298" x="154.681" y="29.597" />
        <Path
          d="M163.528,19.375v1.952l-0.434,0.087v13.749l4.294-4.684v-9.151c0,0,0.607-0.477,0.564-0.651
          c-0.043-0.173-0.217-1.128-0.217-1.128l-0.304-0.304L163.528,19.375z"
          fill="#DCE2E7"
        />
        <Path d="M155.326,89.55h-1.349c-0.508,0-0.921,0.412-0.921,0.921v0.241c0,0.508,0.412,0.921,0.921,0.921h1.349" fill="#E0E0E0" />
        <Path d="M104.669,89.55h-1.349c-0.508,0-0.921,0.412-0.921,0.921v0.241c0,0.508,0.412,0.921,0.921,0.921h1.349" fill="#E0E0E0" />
        <Rect fill="#E6EBEF" height="19.517" width="12.684" x="142.354" y="69.816" />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="147.698,89.333 141.843,89.333 150.344,69.816
          156.199,69.816"
        />
        <Rect fill="#E6EBEF" height="19.517" width="12.684" x="91.609" y="69.816" />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="88.453,89.333 82.598,89.333 91.099,69.816
          96.954,69.816"
        />
        <Polygon
          enableBackground="new    "
          fill="#FFFFFF"
          opacity="0.7"
          points="98.558,89.333 94.915,89.333 103.416,69.816
          107.059,69.816"
        />
        <Path
          d="M176.583,39.35l7.395-8.137c0.3-0.33,0.74-0.516,1.173-0.508c0.446,0.007,0.867,0.205,1.156,0.545
          l21.107,24.755c0.556,0.652,0.478,1.632-0.174,2.188c-0.653,0.556-1.632,0.478-2.188-0.174L185.09,34.605l-21.888,24.082
          c-0.306,0.337-0.727,0.508-1.149,0.508c-0.372,0-0.746-0.133-1.043-0.404c-0.634-0.576-0.681-1.558-0.105-2.192l13.462-14.812"
          fill="#DCE2E7"
        />
        <Polygon
          fill="#E6EBEF"
          points="185.127,34.685 166.044,55.677 166.044,102.735 201.088,102.735 201.088,58.626 205.425,58.626
          "
        />
        <Polygon fill="#BAC2CA" points="183.697,36.449 201.294,56.902 201.294,53.953 185.134,34.888" />
        <Polygon fill="#BAC2CA" points="201.305,53.725 201.305,58.648 205.533,58.756" />
        <Ellipse cx="184.76" cy="52.121" fill="#EFF1EF" rx="1.929" ry="3.318" />
        <Path
          d="M163.037,35.5h-8.377c-0.207,0-0.375-0.168-0.375-0.375V21.427c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
          V34.75h7.842l4.176-4.375v-8.877c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.028c0,0.097-0.037,0.189-0.104,0.259
          l-4.391,4.6C163.237,35.458,163.14,35.5,163.037,35.5z"
          fill="#000000"
        />
        <Path
          d="M163.018,29.384c-0.138,0-0.25-0.112-0.25-0.25v-7.762c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v7.762
          C163.268,29.272,163.155,29.384,163.018,29.384z"
          fill="#000000"
        />
        <Path
          d="M163.018,32.908c-0.138,0-0.25-0.112-0.25-0.25v-2.31c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v2.31
          C163.268,32.796,163.155,32.908,163.018,32.908z"
          fill="#000000"
        />
        <Path
          d="M167.245,21.665h-12.792c-0.615,0-1.116-0.5-1.116-1.116v-0.554c0-0.616,0.501-1.116,1.116-1.116h12.792
          c0.615,0,1.116,0.5,1.116,1.116v0.554C168.361,21.165,167.86,21.665,167.245,21.665z M154.453,19.629
          c-0.202,0-0.366,0.164-0.366,0.366v0.554c0,0.202,0.164,0.366,0.366,0.366h12.792c0.202,0,0.366-0.164,0.366-0.366v-0.554
          c0-0.202-0.164-0.366-0.366-0.366H154.453z"
          fill="#000000"
        />
        <Path
          d="M163.465,21.336c-0.138,0-0.25-0.112-0.25-0.25v-1.71c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.71
          C163.715,21.225,163.603,21.336,163.465,21.336z"
          fill="#000000"
        />
        <Path
          d="M185.185,30.958h-15.886c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h15.886
          c0.207,0,0.375,0.168,0.375,0.375S185.392,30.958,185.185,30.958z"
          fill="#000000"
        />
        <Path
          d="M120.504,56.542H76.317c-0.148,0-0.283-0.088-0.343-0.224c-0.06-0.136-0.034-0.295,0.067-0.405l23.473-25.645
          c0.071-0.077,0.172-0.122,0.277-0.122h9.919c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-9.754L77.168,55.792
          h43.336c0.207,0,0.375,0.168,0.375,0.375S120.711,56.542,120.504,56.542z"
          fill="#000000"
        />
        <Path
          d="M113.362,30.897h-1.446c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h1.446c0.207,0,0.375,0.168,0.375,0.375
          S113.569,30.897,113.362,30.897z"
          fill="#000000"
        />
        <Path
          d="M154.617,30.897h-39.203c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h39.203
          c0.207,0,0.375,0.168,0.375,0.375S154.824,30.897,154.617,30.897z"
          fill="#000000"
        />
        <Path
          d="M162.456,59.558H76.808c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h85.648c0.207,0,0.375,0.168,0.375,0.375
          S162.663,59.558,162.456,59.558z"
          fill="#000000"
        />
        <Path
          d="M82.135,102.823c-0.207,0-0.375-0.168-0.375-0.375V69.613c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v32.835
          C82.51,102.655,82.342,102.823,82.135,102.823z"
          fill="#000000"
        />
        <Path
          d="M82.135,67.861c-0.207,0-0.375-0.168-0.375-0.375v-7.643c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v7.643
          C82.51,67.693,82.342,67.861,82.135,67.861z"
          fill="#000000"
        />
        <Path
          d="M165.959,102.823c-0.207,0-0.375-0.168-0.375-0.375V81.7c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v20.748
          C166.334,102.655,166.166,102.823,165.959,102.823z"
          fill="#000000"
        />
        <Path
          d="M165.959,78.836c-0.207,0-0.375-0.168-0.375-0.375V55.543c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375V78.46
          C166.334,78.668,166.166,78.836,165.959,78.836z"
          fill="#000000"
        />
        <Path
          d="M201.313,102.823c-0.207,0-0.375-0.168-0.375-0.375V54.711c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
          v47.737C201.688,102.655,201.521,102.823,201.313,102.823z"
          fill="#000000"
        />
        <Path
          d="M205.658,58.969h-4.345c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h4.345c0.207,0,0.375,0.168,0.375,0.375
          S205.865,58.969,205.658,58.969z"
          fill="#000000"
        />
        <Path
          d="M253.966,103.165H15.955c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h238.011
          c0.207,0,0.375,0.168,0.375,0.375S254.173,103.165,253.966,103.165z"
          fill="#000000"
        />
        <G>
          <Polyline fill="#FFFFFF" points="49.693,108.324 48.41,100.625 86.253,100.625 84.97,108.073" />
          <Polygon
            fill="#000000"
            points="49.446,108.365 48.115,100.375 86.55,100.375 85.217,108.115 84.724,108.031 85.957,100.875 48.705,100.875
            49.939,108.283"
          />
        </G>
        <Path
          d="M268.642,103.165h-10.825c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h10.825
          c0.207,0,0.375,0.168,0.375,0.375S268.849,103.165,268.642,103.165z"
          fill="#000000"
        />
        <Path
          d="M104.51,89.886H90.352c-0.207,0-0.375-0.168-0.375-0.375V68.598c0-0.207,0.168-0.375,0.375-0.375h14.158
          c0.207,0,0.375,0.168,0.375,0.375v20.913C104.885,89.718,104.717,89.886,104.51,89.886z M90.727,89.136h13.408V68.973H90.727
          V89.136z"
          fill="#000000"
        />
        <Path
          d="M91.628,88.391c-0.138,0-0.25-0.112-0.25-0.25V69.835c0-0.138,0.112-0.25,0.25-0.25h12.987c0.138,0,0.25,0.112,0.25,0.25
          s-0.112,0.25-0.25,0.25H91.878v18.056C91.878,88.278,91.767,88.391,91.628,88.391z"
          fill="#000000"
        />
        <Path
          d="M104.597,92.007H94.221c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h10v-1.332h-14.77
          c-0.301,0-0.545,0.245-0.545,0.546v0.24c0,0.301,0.245,0.546,0.545,0.546h3.296c0.207,0,0.375,0.168,0.375,0.375
          s-0.168,0.375-0.375,0.375h-3.296c-0.714,0-1.295-0.581-1.295-1.296v-0.24c0-0.715,0.581-1.296,1.295-1.296h15.146
          c0.207,0,0.375,0.168,0.375,0.375v2.082C104.972,91.839,104.804,92.007,104.597,92.007z"
          fill="#000000"
        />
        <Path
          d="M104.553,91.882h-1.349c-0.646,0-1.17-0.525-1.17-1.171v-0.24c0-0.646,0.525-1.171,1.17-1.171h1.349
          c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-1.349c-0.37,0-0.67,0.301-0.67,0.671v0.24c0,0.37,0.301,0.671,0.67,0.671
          h1.349c0.138,0,0.25,0.112,0.25,0.25S104.691,91.882,104.553,91.882z"
          fill="#000000"
        />
        <Path
          d="M97.691,88.098c-0.207,0-0.375-0.168-0.375-0.375v-9.688c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.688
          C98.066,87.93,97.898,88.098,97.691,88.098z"
          fill="#000000"
        />
        <Path
          d="M97.691,76.394c-0.207,0-0.375-0.168-0.375-0.375v-5.008c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v5.008
          C98.066,76.226,97.898,76.394,97.691,76.394z"
          fill="#000000"
        />
        <Path
          d="M104.496,79.828h-11.72c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h11.72c0.207,0,0.375,0.168,0.375,0.375
          S104.703,79.828,104.496,79.828z"
          fill="#000000"
        />
        <Path
          d="M155.283,89.886h-14.159c-0.207,0-0.375-0.168-0.375-0.375V82.51c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
          v6.626h13.409V68.973H141.5v12.033c0,0.207-0.168,0.375-0.375,0.375s-0.375-0.168-0.375-0.375V68.598
          c0-0.207,0.168-0.375,0.375-0.375h14.159c0.207,0,0.375,0.168,0.375,0.375v20.913C155.658,89.718,155.49,89.886,155.283,89.886z"
          fill="#000000"
        />
        <Path
          d="M142.401,88.458c-0.138,0-0.25-0.112-0.25-0.25V69.869c0-0.138,0.112-0.25,0.25-0.25h11.734c0.138,0,0.25,0.112,0.25,0.25
          s-0.112,0.25-0.25,0.25h-11.484v18.089C142.651,88.346,142.54,88.458,142.401,88.458z"
          fill="#000000"
        />
        <Path
          d="M155.37,92.007h-15.146c-0.714,0-1.295-0.581-1.295-1.296v-0.24c0-0.715,0.581-1.296,1.295-1.296h15.146
          c0.207,0,0.375,0.168,0.375,0.375v2.082C155.745,91.839,155.577,92.007,155.37,92.007z M140.224,89.925
          c-0.301,0-0.545,0.245-0.545,0.546v0.24c0,0.301,0.245,0.546,0.545,0.546h14.771v-1.332
          C154.995,89.925,140.224,89.925,140.224,89.925z"
          fill="#000000"
        />
        <Path
          d="M155.326,91.882h-1.349c-0.646,0-1.171-0.525-1.171-1.171v-0.24c0-0.646,0.525-1.171,1.171-1.171h1.349
          c0.138,0,0.25,0.112,0.25,0.25s-0.112,0.25-0.25,0.25h-1.349c-0.37,0-0.671,0.301-0.671,0.671v0.24c0,0.37,0.301,0.671,0.671,0.671
          h1.349c0.138,0,0.25,0.112,0.25,0.25S155.464,91.882,155.326,91.882z"
          fill="#000000"
        />
        <Path
          d="M148.464,88.098c-0.207,0-0.375-0.168-0.375-0.375V82.82c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v4.902
          C148.839,87.93,148.671,88.098,148.464,88.098z"
          fill="#000000"
        />
        <Path
          d="M148.464,81.601c-0.207,0-0.375-0.168-0.375-0.375V71.4c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v9.825
          C148.839,81.433,148.671,81.601,148.464,81.601z"
          fill="#000000"
        />
        <Path
          d="M155.269,79.828h-11.388c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h11.388
          c0.207,0,0.375,0.168,0.375,0.375S155.476,79.828,155.269,79.828z"
          fill="#000000"
        />
        <Path
          d="M162.054,59.57c-0.479,0-0.939-0.178-1.296-0.5c-0.381-0.346-0.604-0.82-0.629-1.334s0.153-1.007,0.499-1.388
          l12.862-14.156c0.141-0.154,0.378-0.164,0.529-0.025c0.153,0.139,0.165,0.376,0.025,0.529l-12.862,14.157
          c-0.211,0.233-0.319,0.534-0.305,0.848c0.016,0.314,0.151,0.604,0.384,0.815c0.463,0.419,1.242,0.382,1.663-0.08l21.888-24.083
          c0.072-0.08,0.184-0.117,0.283-0.123c0.107,0.002,0.21,0.05,0.279,0.132l19.964,23.414c0.204,0.239,0.488,0.385,0.802,0.41
          c0.312,0.022,0.618-0.073,0.857-0.278c0.239-0.204,0.385-0.489,0.409-0.802c0.025-0.313-0.073-0.618-0.277-0.856l-21.107-24.755
          c-0.22-0.257-0.539-0.408-0.877-0.413c-0.007,0-0.014,0-0.02,0c-0.327,0-0.65,0.143-0.87,0.385l-7.171,7.83
          c-0.141,0.152-0.378,0.163-0.529,0.023c-0.153-0.14-0.163-0.377-0.023-0.53l7.17-7.829c0.359-0.396,0.89-0.629,1.423-0.629
          c0.012,0,0.022,0,0.033,0c0.553,0.008,1.076,0.255,1.435,0.677L207.7,55.761c0.334,0.392,0.496,0.89,0.455,1.403
          s-0.279,0.979-0.671,1.313c-0.392,0.333-0.885,0.494-1.403,0.455c-0.513-0.041-0.979-0.279-1.312-0.671l-19.688-23.089
          L163.479,58.94C163.114,59.34,162.595,59.57,162.054,59.57z"
          fill="#000000"
        />
        <Path
          d="M174.981,41.488c-0.09,0-0.181-0.032-0.252-0.097c-0.153-0.139-0.165-0.376-0.025-0.53l0.697-0.768
          c0.139-0.153,0.378-0.164,0.529-0.026c0.153,0.139,0.165,0.376,0.025,0.53l-0.697,0.768
          C175.186,41.446,175.083,41.488,174.981,41.488z"
          fill="#000000"
        />
        <Path
          d="M79.171,59.543h-1.995c-1.043,0-1.893-0.849-1.893-1.893s0.849-1.893,1.893-1.893h1.995c0.207,0,0.375,0.168,0.375,0.375
          s-0.168,0.375-0.375,0.375h-1.995c-0.63,0-1.143,0.513-1.143,1.143s0.513,1.143,1.143,1.143h1.995c0.207,0,0.375,0.168,0.375,0.375
          S79.378,59.543,79.171,59.543z"
          fill="#000000"
        />
        <Path
          d="M185.257,56.139c-1.665,0-3.021-1.841-3.021-4.105s1.355-4.105,3.021-4.105s3.021,1.841,3.021,4.105
          S186.923,56.139,185.257,56.139z M185.257,48.679c-1.252,0-2.271,1.505-2.271,3.355s1.019,3.355,2.271,3.355
          c1.253,0,2.271-1.505,2.271-3.355S186.51,48.679,185.257,48.679z"
          fill="#000000"
        />
        <Path
          d="M184.763,55.969c-0.107,0-0.207-0.07-0.239-0.178c-0.04-0.132,0.035-0.271,0.168-0.311
          c1.109-0.333,1.914-1.766,1.914-3.409c0-1.174-0.407-2.263-1.089-2.912c-0.101-0.095-0.104-0.253-0.009-0.354
          c0.095-0.1,0.253-0.104,0.353-0.009c0.78,0.742,1.245,1.965,1.245,3.274c0,1.888-0.934,3.487-2.271,3.887
          C184.811,55.966,184.786,55.969,184.763,55.969z"
          fill="#000000"
        />
        <Path
          d="M186.237,103.199c0-1.572-1.295-2.94-2.796-2.94c-1.169,0-2.162,0.774-2.546,1.859
          c-0.41-0.608-1.083-1.008-1.848-1.008c-1.17,0-2.132,0.928-2.253,2.12"
          fill="#FFFFFF"
        />
        <Path
          d="M176.794,103.259c-0.013,0-0.026-0.001-0.04-0.002c-0.206-0.022-0.354-0.207-0.333-0.413
          c0.144-1.345,1.272-2.358,2.626-2.358c0.652,0,1.276,0.244,1.756,0.673c0.554-0.907,1.554-1.485,2.639-1.485
          c1.719,0,3.17,1.457,3.17,3.182c0,0.207-0.168,0.375-0.375,0.375s-0.375-0.168-0.375-0.375c0-1.295-1.131-2.432-2.42-2.432
          c-0.974,0-1.855,0.615-2.194,1.529c-0.048,0.131-0.165,0.224-0.303,0.242c-0.146,0.016-0.275-0.043-0.355-0.155
          c-0.355-0.503-0.932-0.804-1.542-0.804c-0.97,0-1.777,0.726-1.88,1.688C177.146,103.116,176.983,103.259,176.794,103.259z"
          fill="#000000"
        />
        <Path
          d="M161.129,56.572h-38.051c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h38.051
          c0.207,0,0.375,0.168,0.375,0.375S161.336,56.572,161.129,56.572z"
          fill="#000000"
        />
        <Polygon fill="#E6EBEF" points="80.689,100.709 81.471,108.351 85.079,108.351 85.961,100.646" />
        <Path
          d="M84.885,108.597c-0.015,0-0.029-0.001-0.044-0.004c-0.136-0.024-0.227-0.153-0.203-0.29l1.317-7.428H48.626l1.235,7.408
          c0.023,0.136-0.069,0.265-0.206,0.288c-0.132,0.019-0.265-0.07-0.288-0.206l-1.283-7.699c-0.012-0.072,0.008-0.146,0.056-0.202
          c0.048-0.057,0.117-0.089,0.191-0.089h37.922c0.074,0,0.144,0.033,0.191,0.09c0.048,0.057,0.068,0.131,0.055,0.204l-1.369,7.722
          C85.109,108.512,85.003,108.597,84.885,108.597z"
          fill="#000000"
        />
        <Path
          d="M81.476,108.527c-0.114,0-0.211-0.086-0.223-0.201l-0.788-7.594c-0.013-0.124,0.077-0.234,0.2-0.247
          c0.131-0.012,0.235,0.077,0.247,0.2l0.788,7.594c0.013,0.124-0.077,0.234-0.2,0.247C81.491,108.527,81.483,108.527,81.476,108.527z
          "
          fill="#000000"
        />
        <G>
          <Path
            d="M53.484,100.654c-0.138,0-0.25-0.112-0.25-0.25v-3.878c-1.071,0.199-2.015,0.02-2.812-0.539
            c-1.981-1.388-2.338-4.601-2.352-4.736c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.169-0.091
            c0.131-0.013,3.257-0.317,4.958,0.58c1.788,0.939,1.508,3.231,1.496,3.328c-0.019,0.137-0.144,0.227-0.28,0.216
            c-0.137-0.019-0.233-0.144-0.216-0.28c0.003-0.021,0.245-2.045-1.232-2.821c-1.301-0.687-3.639-0.598-4.416-0.545
            c0.125,0.77,0.615,3.082,2.107,4.126c0.749,0.523,1.662,0.654,2.713,0.395c0.073-0.019,0.153-0.002,0.214,0.045
            c0.061,0.048,0.096,0.12,0.096,0.197v4.188C53.734,100.542,53.623,100.654,53.484,100.654z"
            fill="#000000"
          />
          <Path
            d="M54.876,96.379c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
            c1.176-0.097,2.132-0.577,2.843-1.428c1.622-1.943,1.549-5.286,1.5-6.155c-5.041-0.288-5.84,3.178-5.848,3.213
            c-0.029,0.135-0.162,0.223-0.297,0.191c-0.135-0.028-0.221-0.161-0.192-0.297c0.009-0.04,0.923-4.026,6.589-3.592
            c0.121,0.009,0.218,0.104,0.23,0.225c0.017,0.176,0.403,4.335-1.598,6.734c-0.799,0.958-1.872,1.499-3.187,1.606
            C54.891,96.379,54.883,96.379,54.876,96.379z"
            fill="#000000"
          />
          <Path
            d="M54.529,93.543c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266c0.046-0.099,1.15-2.419,3.067-3.569
            c0.095-0.055,0.217-0.025,0.274,0.069s0.026,0.217-0.068,0.274c-1.811,1.086-2.9,3.373-2.911,3.396
            C54.676,93.5,54.604,93.543,54.529,93.543z"
            fill="#000000"
          />
          <Path
            d="M54.81,100.79c-0.138,0-0.25-0.112-0.25-0.25v-4.431c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v4.431
            C55.06,100.678,54.948,100.79,54.81,100.79z"
            fill="#000000"
          />
          <Path
            d="M54.444,95.282c-0.067,0-0.133-0.034-0.17-0.096c-0.543-0.888-3.044-2.099-3.07-2.11
            c-0.099-0.049-0.141-0.168-0.093-0.268c0.047-0.1,0.167-0.139,0.267-0.094c0.107,0.052,2.633,1.275,3.237,2.263
            c0.058,0.095,0.028,0.218-0.066,0.275C54.516,95.272,54.479,95.282,54.444,95.282z"
            fill="#000000"
          />
        </G>
        <G>
          <Path
            d="M66.013,100.654c-0.138,0-0.25-0.112-0.25-0.25v-3.878c-1.073,0.199-2.015,0.02-2.812-0.539
            c-1.981-1.388-2.338-4.601-2.352-4.736c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.169-0.091
            c0.131-0.013,3.257-0.316,4.959,0.579c1.787,0.94,1.508,3.232,1.495,3.329c-0.018,0.137-0.148,0.227-0.28,0.216
            c-0.137-0.019-0.233-0.144-0.216-0.28c0.003-0.021,0.245-2.045-1.232-2.821c-1.302-0.687-3.639-0.598-4.417-0.545
            c0.125,0.77,0.615,3.082,2.107,4.126c0.75,0.523,1.663,0.654,2.713,0.395c0.075-0.019,0.153-0.002,0.214,0.045
            c0.061,0.048,0.096,0.12,0.096,0.197v4.188C66.263,100.542,66.151,100.654,66.013,100.654z"
            fill="#000000"
          />
          <Path
            d="M67.405,96.379c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
            c1.176-0.097,2.132-0.577,2.843-1.428c1.622-1.943,1.549-5.286,1.5-6.155c-5.031-0.288-5.84,3.178-5.848,3.213
            c-0.029,0.135-0.165,0.223-0.297,0.191c-0.135-0.028-0.221-0.161-0.192-0.297c0.009-0.04,0.922-4.026,6.589-3.592
            c0.121,0.009,0.218,0.104,0.23,0.225c0.017,0.176,0.403,4.335-1.598,6.734c-0.799,0.958-1.872,1.499-3.187,1.606
            C67.419,96.379,67.412,96.379,67.405,96.379z"
            fill="#000000"
          />
          <Path
            d="M67.057,93.543c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266c0.046-0.099,1.151-2.419,3.067-3.569
            c0.095-0.055,0.218-0.025,0.274,0.069c0.057,0.095,0.026,0.217-0.068,0.274c-1.811,1.086-2.9,3.373-2.911,3.396
            C67.204,93.5,67.132,93.543,67.057,93.543z"
            fill="#000000"
          />
          <Path
            d="M67.338,100.79c-0.138,0-0.25-0.112-0.25-0.25v-4.431c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v4.431
            C67.588,100.678,67.476,100.79,67.338,100.79z"
            fill="#000000"
          />
          <Path
            d="M66.972,95.282c-0.067,0-0.133-0.034-0.17-0.096c-0.543-0.888-3.044-2.099-3.07-2.11
            c-0.099-0.049-0.141-0.168-0.093-0.268c0.047-0.1,0.167-0.139,0.267-0.094c0.107,0.052,2.633,1.275,3.237,2.263
            c0.058,0.095,0.028,0.218-0.066,0.275C67.044,95.272,67.008,95.282,66.972,95.282z"
            fill="#000000"
          />
        </G>
        <Path d="M78.008,91.457c0,0,0.813-3.801,6.326-3.395c0,0,0.754,7.632-4.556,8.067" fill="#FFFFFF" />
        <G>
          <Path
            d="M78.385,100.654c-0.138,0-0.25-0.112-0.25-0.25v-3.878c-1.073,0.199-2.015,0.02-2.812-0.539
          c-1.982-1.388-2.338-4.601-2.352-4.736c-0.007-0.066,0.013-0.133,0.055-0.185c0.042-0.051,0.103-0.084,0.169-0.091
          c0.131-0.013,3.257-0.316,4.959,0.579c1.787,0.94,1.508,3.232,1.495,3.329c-0.018,0.137-0.149,0.227-0.28,0.216
          c-0.137-0.019-0.233-0.144-0.216-0.28c0.003-0.021,0.245-2.045-1.232-2.821c-1.302-0.687-3.639-0.598-4.417-0.545
          c0.125,0.77,0.615,3.082,2.107,4.126c0.749,0.523,1.662,0.654,2.713,0.395c0.075-0.019,0.153-0.002,0.214,0.045
          c0.061,0.048,0.096,0.12,0.096,0.197v4.188C78.635,100.542,78.523,100.654,78.385,100.654z"
            fill="#000000"
          />
          <Path
            d="M79.777,96.379c-0.128,0-0.238-0.099-0.249-0.229c-0.011-0.138,0.091-0.259,0.229-0.27
          c1.176-0.097,2.132-0.577,2.843-1.428c1.622-1.943,1.549-5.286,1.5-6.155c-5.032-0.288-5.84,3.178-5.848,3.213
          c-0.029,0.135-0.164,0.223-0.297,0.191c-0.135-0.028-0.221-0.161-0.192-0.297c0.008-0.04,0.931-4.026,6.589-3.592
          c0.121,0.009,0.218,0.104,0.23,0.225c0.017,0.176,0.403,4.335-1.598,6.734c-0.799,0.958-1.872,1.499-3.187,1.606
          C79.792,96.379,79.784,96.379,79.777,96.379z"
            fill="#000000"
          />
          <Path
            d="M79.43,93.543c-0.029,0-0.058-0.006-0.085-0.02c-0.1-0.047-0.143-0.166-0.096-0.266c0.046-0.099,1.151-2.419,3.067-3.569
          c0.095-0.055,0.218-0.025,0.274,0.069c0.057,0.095,0.026,0.217-0.068,0.274c-1.81,1.086-2.9,3.373-2.911,3.396
          C79.577,93.5,79.505,93.543,79.43,93.543z"
            fill="#000000"
          />
          <Path
            d="M79.71,100.79c-0.138,0-0.25-0.112-0.25-0.25v-4.431c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v4.431
          C79.96,100.678,79.849,100.79,79.71,100.79z"
            fill="#000000"
          />
          <Path
            d="M79.345,95.282c-0.067,0-0.133-0.034-0.17-0.096c-0.543-0.888-3.044-2.099-3.07-2.11
          c-0.099-0.049-0.141-0.168-0.093-0.268c0.047-0.1,0.168-0.139,0.267-0.094c0.107,0.052,2.633,1.275,3.237,2.263
          c0.058,0.095,0.028,0.218-0.066,0.275C79.417,95.272,79.38,95.282,79.345,95.282z"
            fill="#000000"
          />
        </G>
        <Path
          d="M87.686,108.763H45.965c-0.138,0-0.25-0.112-0.25-0.25s0.112-0.25,0.25-0.25h41.721c0.138,0,0.25,0.112,0.25,0.25
        S87.824,108.763,87.686,108.763z"
          fill="#000000"
        />
        <G>
          <Path
            d="M206.147,94.626c0.88,2.732-1.093,3.673-1.093,3.673l0.012,0.082c-0.51-2.009-1.898-3.715-3.822-4.605
          l-0.119-0.057c0.028-0.1,0.06-0.196,0.08-0.303c0.068-0.36,0.092-0.713,0.087-1.057l0.222,0.234c0,0,1.343,0.205,1.708-0.842
          s-0.091-2.368-2.004-4.053c-1.913-1.685-3.074-1.138-3.074-1.138s-0.474-0.376-1.203-0.376s-1.127,0.398-1.127,0.398l-0.072,0.004
          l0.015-0.027c0,0-1.161-0.546-3.074,1.138c-1.913,1.685-2.368,3.005-2.004,4.053s1.708,0.842,1.708,0.842l0.23-0.412
          c-0.019,0.4-0.001,0.812,0.079,1.235c0.109,0.582,0.334,1.05,0.624,1.429l-0.001,0.073c-0.012,0.014-0.533,0.634-0.556,1.58
          c-0.015,0.631-0.01,3.563-0.005,5.488c0.002,0.951,0.774,1.719,1.725,1.719h0.256c0.952,0,1.725-0.772,1.725-1.725v-2.055
          l-0.082-0.131c0.362,0.086,0.784,0.115,1.232,0.033l0.043,0.119l-0.055,0.036v2.032c0,0.953,0.772,1.725,1.725,1.725h0.211
          c0.953,0,1.725-0.772,1.725-1.725v-0.015h0.923c-0.019,0.088-0.029,0.178-0.029,0.271c0,0.723,0.586,1.309,1.309,1.309h0.829
          c0.559,0,1.011-0.456,1.006-1.015l-0.026-2.812l0.07,0.461c1.396-0.82,1.791-2.11,1.791-2.11
          C207.847,96.023,206.147,94.626,206.147,94.626z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              d="M194.78,104.078h-0.256c-1.154,0-2.096-0.938-2.1-2.093c-0.006-2.173-0.009-4.89,0.005-5.497
            c0.026-1.077,0.623-1.787,0.648-1.817c0.135-0.156,0.37-0.177,0.529-0.041c0.156,0.135,0.176,0.37,0.042,0.528
            c-0.017,0.02-0.45,0.558-0.47,1.348c-0.014,0.6-0.011,3.31-0.005,5.478c0.002,0.741,0.607,1.345,1.35,1.345h0.256
            c0.744,0,1.35-0.605,1.35-1.35v-2.055c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375v2.055
            C196.88,103.137,195.938,104.078,194.78,104.078z"
              fill="#000000"
            />
            <Path
              d="M197.139,100.251c-0.919,0-1.718-0.37-2.144-0.687c-0.166-0.123-0.2-0.357-0.077-0.523
            c0.122-0.166,0.357-0.201,0.523-0.079c0.068,0.051,1.67,1.202,3.263,0.002c0.163-0.123,0.399-0.092,0.525,0.074
            c0.124,0.165,0.091,0.4-0.074,0.525C198.482,100.07,197.781,100.251,197.139,100.251z"
              fill="#000000"
            />
            <Path
              d="M199.579,104.112h-0.211c-1.158,0-2.1-0.941-2.1-2.1V99.98c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
            v2.032c0,0.744,0.605,1.35,1.35,1.35h0.211c0.744,0,1.35-0.605,1.35-1.35v-4.411c0-0.207,0.168-0.375,0.375-0.375
            s0.375,0.168,0.375,0.375v4.411C201.679,103.171,200.737,104.112,199.579,104.112z"
              fill="#000000"
            />
            <Path
              d="M202.163,102.394h-0.823c-0.207,0-0.375-0.168-0.375-0.375s0.168-0.375,0.375-0.375h0.823
            c0.207,0,0.375,0.168,0.375,0.375S202.37,102.394,202.163,102.394z"
              fill="#000000"
            />
            <Path
              d="M204.335,103.953h-0.829c-0.929,0-1.684-0.756-1.684-1.685s0.755-1.684,1.684-1.684h0.171
            c0.207,0,0.375,0.168,0.375,0.375s-0.168,0.375-0.375,0.375h-0.171c-0.515,0-0.934,0.419-0.934,0.934
            c0,0.516,0.419,0.935,0.934,0.935h0.829c0.17,0,0.329-0.066,0.449-0.188c0.119-0.12,0.184-0.279,0.182-0.449l-0.025-2.826
            c-0.126-2.434-1.586-4.594-3.812-5.623c-0.188-0.088-0.271-0.311-0.184-0.498c0.088-0.187,0.312-0.27,0.498-0.184
            c2.479,1.147,4.106,3.554,4.246,6.282l0.026,2.842c0.004,0.37-0.138,0.72-0.399,0.984
            C205.056,103.808,204.707,103.953,204.335,103.953z"
              fill="#000000"
            />
            <Path
              d="M205.385,100.587c-0.128,0-0.254-0.066-0.323-0.186c-0.105-0.179-0.045-0.408,0.134-0.513
            c1.243-0.729,1.618-1.886,1.622-1.897c0.254-0.739,0.166-1.38-0.031-1.882c-0.083,1.813-1.51,2.521-1.528,2.529
            c-0.184,0.088-0.409,0.01-0.5-0.178c-0.089-0.187-0.01-0.409,0.176-0.499c0.08-0.04,1.659-0.86,0.898-3.221
            c-0.051-0.158,0.009-0.332,0.146-0.426c0.138-0.092,0.32-0.084,0.449,0.021c0.077,0.064,1.892,1.589,1.103,3.889
            c-0.014,0.046-0.45,1.428-1.956,2.311C205.515,100.57,205.449,100.587,205.385,100.587z"
              fill="#000000"
            />
            <Path
              d="M197.184,94.77c-1.173,0-1.988-0.855-2-0.869c-0.095-0.101-0.089-0.258,0.012-0.353c0.102-0.094,0.26-0.088,0.354,0.012
            c0.061,0.065,1.511,1.571,3.271-0.015c0.1-0.093,0.261-0.086,0.353,0.019c0.093,0.102,0.084,0.26-0.019,0.353
            C198.445,94.556,197.77,94.77,197.184,94.77z"
              fill="#000000"
            />
            <Path
              d="M197.12,94.709c-0.138,0-0.25-0.112-0.25-0.25v-1.536c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v1.536
            C197.37,94.597,197.258,94.709,197.12,94.709z"
              fill="#000000"
            />
            <Circle cx="198.714" cy="90.304" fill="#000000" r="0.305" />
            <Circle cx="195.651" cy="90.304" fill="#000000" r="0.305" />
            <Ellipse cx="197.125" cy="91.784" fill="#000000" rx="1.333" ry="0.619" />
            <Path d="M196.038,91.727l-0.246,0.057c0,0,0.115,1.059,1.299,1.229l0.085,0.017l0.131-1.07L196.038,91.727z" fill="#000000" />
            <Path d="M198.217,91.727l0.246,0.057c0,0-0.115,1.059-1.299,1.229l-0.085,0.017l-0.131-1.07L198.217,91.727z" fill="#000000" />
            <Path
              d="M192.16,92.985c-0.612,0-1.484-0.227-1.793-1.112c-0.459-1.32,0.251-2.82,2.11-4.457
            c2.073-1.827,3.424-1.223,3.481-1.196c0.187,0.088,0.268,0.312,0.179,0.499c-0.087,0.186-0.307,0.264-0.492,0.183
            c-0.033-0.015-1.023-0.377-2.672,1.077c-1.608,1.416-2.247,2.644-1.897,3.648c0.26,0.745,1.287,0.597,1.297,0.594
            c0.213-0.031,0.396,0.11,0.428,0.314c0.031,0.205-0.109,0.396-0.314,0.428C192.392,92.977,192.281,92.985,192.16,92.985z"
              fill="#000000"
            />
            <Path
              d="M196.926,96.805c-0.039,0-3.939-0.038-4.556-3.319c-0.594-3.16,1.729-5.793,1.828-5.903
            c0.139-0.155,0.377-0.168,0.529-0.029c0.154,0.138,0.167,0.375,0.029,0.529c-0.021,0.024-2.175,2.474-1.65,5.265
            c0.502,2.667,3.685,2.708,3.82,2.708c0.206,0.001,0.374,0.169,0.374,0.376C197.3,96.637,197.133,96.805,196.926,96.805z"
              fill="#000000"
            />
            <Path
              d="M201.825,92.985c-0.121,0-0.231-0.009-0.326-0.022c-0.204-0.031-0.344-0.222-0.313-0.426s0.226-0.348,0.425-0.316
            c0.046,0.007,1.044,0.142,1.299-0.594c0.35-1.004-0.289-2.231-1.897-3.648c-1.668-1.469-2.662-1.082-2.674-1.077
            c-0.126,0.051-0.278,0.036-0.386-0.049c-0.001-0.001-0.39-0.295-0.969-0.295c-0.551,0-0.853,0.28-0.865,0.292
            c-0.15,0.142-0.385,0.139-0.528-0.009c-0.144-0.146-0.144-0.38,0.001-0.525c0.053-0.052,0.533-0.508,1.393-0.508
            c0.583,0,1.031,0.207,1.262,0.342c0.468-0.104,1.646-0.157,3.263,1.267c1.858,1.638,2.569,3.138,2.11,4.457
            C203.31,92.759,202.438,92.985,201.825,92.985z"
              fill="#000000"
            />
            <Path
              d="M197.06,96.805c-0.207,0-0.374-0.168-0.375-0.374c0-0.207,0.168-0.375,0.374-0.376c0.136,0,3.318-0.041,3.82-2.708
            c0.524-2.791-1.629-5.24-1.65-5.265c-0.138-0.154-0.125-0.392,0.029-0.529c0.153-0.139,0.392-0.126,0.529,0.029
            c0.099,0.11,2.422,2.743,1.828,5.903C200.999,96.767,197.099,96.805,197.06,96.805z"
              fill="#000000"
            />
          </G>
        </G>
        <Path
          d="M243.777,102.918c-0.207,0-0.375-0.168-0.375-0.375V57.052c0-0.207,0.168-0.375,0.375-0.375s0.375,0.168,0.375,0.375
        v45.491C244.152,102.75,243.984,102.918,243.777,102.918z"
          fill="#000000"
        />
        <Path
          d="M243.599,74.933c-0.069,0-0.14-0.02-0.203-0.06l-9.374-6.027c-0.174-0.112-0.225-0.344-0.112-0.519
        c0.111-0.175,0.343-0.224,0.519-0.112l9.374,6.027c0.174,0.112,0.225,0.344,0.112,0.519
        C243.843,74.872,243.722,74.933,243.599,74.933z"
          fill="#000000"
        />
        <Path
          d="M243.746,65.802c-0.122,0-0.241-0.06-0.313-0.169c-0.113-0.173-0.065-0.405,0.107-0.52l4.696-3.092
        c0.172-0.114,0.405-0.066,0.52,0.107c0.113,0.173,0.065,0.405-0.107,0.52l-4.696,3.092
        C243.889,65.782,243.817,65.802,243.746,65.802z"
          fill="#000000"
        />
        <Path
          d="M243.929,79.96c-0.128,0-0.253-0.065-0.323-0.185c-0.104-0.178-0.046-0.408,0.133-0.514l9.209-5.445
        c0.177-0.104,0.407-0.047,0.514,0.133c0.104,0.178,0.046,0.408-0.133,0.514l-9.209,5.445
        C244.06,79.943,243.994,79.96,243.929,79.96z"
          fill="#000000"
        />
        <Path
          d="M158.974,14.884c-0.108,0-0.205-0.075-0.23-0.185c-0.028-0.127,0.052-0.253,0.179-0.282l1.497-0.332l-1.492-0.314
        c-0.128-0.027-0.209-0.152-0.183-0.279c0.027-0.127,0.148-0.21,0.279-0.182l2.058,0.432c0.162,0.033,0.275,0.172,0.276,0.337
        c0.001,0.165-0.11,0.305-0.271,0.341l-2.062,0.458C159.007,14.882,158.99,14.884,158.974,14.884z M160.986,14.204h0.006H160.986z"
          fill="#000000"
        />
        <Path
          d="M151.741,17.289c-0.224,0-0.444-0.023-0.657-0.077c-0.097-0.025-0.167-0.107-0.176-0.207
        c-0.002-0.02-0.033-0.392,0.241-0.888c-0.314-0.013-0.722-0.102-1.1-0.401c-0.069-0.054-0.102-0.142-0.086-0.227
        c0.015-0.086,0.077-0.156,0.16-0.182c0.015-0.005,1.476-0.475,2.464-1.251c0.34-0.268,0.629-0.571,0.91-0.862
        c0.528-0.551,1.073-1.121,1.982-1.514c0.12-0.052,0.258,0.004,0.31,0.123c0.052,0.12-0.003,0.258-0.123,0.31
        c-0.822,0.356-1.311,0.866-1.828,1.407c-0.291,0.304-0.592,0.619-0.958,0.907c-0.69,0.544-1.569,0.936-2.112,1.149
        c0.415,0.136,0.778,0.042,0.799,0.037c0.097-0.026,0.205,0.014,0.26,0.102c0.055,0.087,0.048,0.2-0.018,0.279
        c-0.285,0.336-0.383,0.627-0.414,0.801c1.226,0.179,2.805-0.714,2.822-0.724c0.081-0.047,0.182-0.042,0.257,0.014
        c0.751,0.546,1.602,0.745,2.39,0.559c0.624-0.147,1.163-0.526,1.405-0.992c0.546-1.046,0.533-2.127-0.036-2.967
        c-0.581-0.859-2.038-0.704-2.054-0.703c-0.127,0.015-0.247-0.078-0.261-0.208c-0.015-0.13,0.078-0.246,0.208-0.261
        c0.069-0.008,1.757-0.185,2.497,0.907c0.669,0.987,0.692,2.244,0.063,3.449c-0.304,0.584-0.961,1.056-1.715,1.233
        c-0.884,0.208-1.818,0.012-2.652-0.55C153.938,16.753,152.819,17.289,151.741,17.289z"
          fill="#000000"
        />
        <Path
          d="M154.929,15.719c-0.099,0-0.202-0.009-0.309-0.029c-0.014-0.001-0.494-0.073-0.867-0.449
        c-0.491,0.232-1.325,0.396-1.866-0.266c-0.082-0.101-0.068-0.25,0.033-0.332c0.102-0.082,0.25-0.068,0.332,0.033
        c0.507,0.622,1.422,0.071,1.432,0.065c0.105-0.064,0.24-0.038,0.314,0.06c0.259,0.351,0.695,0.42,0.7,0.421
        c1.02,0.195,1.629-1.026,1.635-1.039c0.056-0.118,0.198-0.166,0.316-0.109c0.117,0.057,0.166,0.198,0.108,0.315
        C156.727,14.451,156.095,15.719,154.929,15.719z"
          fill="#000000"
        />
        <Circle cx="157.244" cy="13.339" r="0.216" />
        <Path
          d="M155.447,18.894c-0.076,0-0.152-0.038-0.197-0.106l-0.679-1.033c-0.036-0.055-0.047-0.123-0.032-0.187l0.241-0.961
        c0.032-0.126,0.16-0.203,0.287-0.171c0.126,0.032,0.203,0.16,0.171,0.287l-0.216,0.861l0.622,0.947
        c0.072,0.109,0.041,0.256-0.068,0.327C155.536,18.881,155.492,18.894,155.447,18.894z"
          fill="#000000"
        />
        <Path
          d="M156.51,18.99c-0.076,0-0.152-0.038-0.197-0.106l-0.679-1.033c-0.037-0.056-0.048-0.124-0.031-0.189l0.208-0.801
        c0.033-0.126,0.161-0.203,0.287-0.168c0.126,0.033,0.201,0.162,0.168,0.287l-0.182,0.698l0.621,0.945
        c0.072,0.109,0.041,0.256-0.068,0.327C156.6,18.977,156.555,18.99,156.51,18.99z"
          fill="#000000"
        />
        <Path
          d="M243.066,31.383c1.102,0,1.695-1.294,0.977-2.13c-1.21-1.41-3.636-2.373-6.431-2.373
        c-0.455,0-0.899,0.027-1.33,0.077c-0.473,0.055-0.884-0.326-0.883-0.802v-0.01c0-2.436-3.082-4.411-6.883-4.411
        c-1.059,0-2.061,0.154-2.956,0.428c-0.419,0.128-0.871-0.123-0.966-0.551c-0.799-3.62-4.675-6.367-9.348-6.367
        c-5.252,0-9.51,3.469-9.51,7.748c0,0.153,0.017,0.302,0.028,0.453c-0.219-0.034-0.442-0.056-0.671-0.056
        c-1.964,0-3.625,1.284-4.196,3.058c-0.128,0.399-0.544,0.625-0.946,0.51c-0.381-0.109-0.783-0.168-1.199-0.168
        c-1.399,0-2.646,0.651-3.454,1.667c-0.939,1.181-0.09,2.927,1.419,2.927C196.717,31.383,243.066,31.383,243.066,31.383z"
          fill="#D9E1E8"
        />
        <Path
          d="M243.776,85.602c-1.027,0-2.086-0.021-3.146-0.063c-9.993-0.062-14.857-2.139-15.06-2.227
        c-0.031-0.014-0.06-0.031-0.086-0.052c-4.768-1.942-7.842-6.503-7.842-11.651c0-5.28,3.343-10.033,8.317-11.827
        c0.147-0.054,0.255-0.163,0.303-0.307c0.049-0.146,0.025-0.305-0.062-0.438c-1.031-1.566-1.577-3.385-1.577-5.259
        c0-5.288,4.303-9.59,9.591-9.59c0.061,0,0.12,0.002,0.179,0.005c0.758-4.411,4.622-7.695,9.129-7.695
        c4.503,0,8.369,3.284,9.128,7.691c5.214,0.087,9.429,4.355,9.429,9.589c0,1.786-0.495,3.53-1.433,5.042
        c-0.087,0.141-0.104,0.306-0.049,0.454c0.055,0.144,0.168,0.249,0.32,0.295c5.343,1.619,8.932,6.457,8.932,12.041
        c0,5.157-3.083,9.723-7.863,11.659c-0.022,0.018-0.047,0.032-0.073,0.044c-0.204,0.089-5.118,2.189-15.234,2.229
        C245.747,85.578,244.773,85.602,243.776,85.602z M225.972,82.665c0.743,0.293,5.614,2.067,14.676,2.123
        c1.936,0.077,4.058,0.08,6.015,0.003c9.246-0.036,14.173-1.856,14.866-2.132c0.025-0.021,0.055-0.038,0.087-0.051
        c4.547-1.801,7.484-6.118,7.484-11c0-5.251-3.375-9.801-8.398-11.323c-0.375-0.113-0.669-0.386-0.806-0.748
        c-0.139-0.367-0.097-0.773,0.114-1.113c0.863-1.394,1.319-3.001,1.319-4.647c0-4.875-3.966-8.84-8.841-8.84l-0.151,0.008
        c-0.176-0.005-0.354-0.137-0.379-0.325c-0.567-4.204-4.194-7.374-8.436-7.374c-4.25,0-7.878,3.177-8.438,7.39
        c-0.026,0.194-0.201,0.346-0.393,0.325l-0.153-0.01c-0.107-0.007-0.215-0.015-0.324-0.015c-4.875,0-8.841,3.966-8.841,8.84
        c0,1.728,0.503,3.403,1.454,4.846c0.215,0.326,0.269,0.723,0.147,1.087c-0.12,0.362-0.397,0.645-0.761,0.776
        c-4.678,1.688-7.821,6.157-7.821,11.122c0,4.879,2.936,9.196,7.479,10.998C225.909,82.621,225.942,82.641,225.972,82.665z"
          fill="#000000"
        />
        <G>
          <Path
            d="M262.594,75.309c0-0.546-0.404-0.994-0.929-1.071c-0.071-0.532-0.521-0.944-1.073-0.944
          c-0.548,0-0.996,0.406-1.072,0.933c-0.578,0.026-1.039,0.498-1.039,1.082c0,0.596,0.479,1.078,1.073,1.086
          c0.133,0.449,0.545,0.779,1.038,0.779c0.497,0,0.911-0.335,1.041-0.791C262.174,76.32,262.594,75.866,262.594,75.309z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M233.373,51.155c0-0.372-0.275-0.677-0.633-0.73c-0.048-0.362-0.355-0.643-0.731-0.643
          c-0.373,0-0.678,0.277-0.73,0.635c-0.393,0.017-0.708,0.339-0.708,0.737c0,0.406,0.327,0.734,0.731,0.739
          c0.091,0.306,0.371,0.531,0.707,0.531c0.338,0,0.621-0.228,0.709-0.539C233.087,51.843,233.373,51.534,233.373,51.155z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M251.635,70.024c0-0.403-0.299-0.734-0.686-0.791c-0.052-0.393-0.385-0.697-0.792-0.697
          c-0.405,0-0.736,0.3-0.792,0.689c-0.427,0.019-0.768,0.368-0.768,0.8c0,0.44,0.354,0.796,0.793,0.802
          c0.099,0.332,0.402,0.576,0.766,0.576c0.367,0,0.673-0.248,0.769-0.584C251.325,70.771,251.635,70.435,251.635,70.024z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M225.029,75.874c0-0.266-0.196-0.483-0.452-0.521c-0.034-0.259-0.254-0.459-0.522-0.459
          c-0.266,0-0.484,0.198-0.521,0.454c-0.281,0.012-0.505,0.242-0.505,0.526c0,0.29,0.233,0.524,0.522,0.528
          c0.065,0.219,0.265,0.379,0.505,0.379c0.242,0,0.443-0.163,0.506-0.384C224.825,76.366,225.029,76.145,225.029,75.874z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M258.746,64.07c0-0.266-0.197-0.483-0.452-0.521c-0.034-0.259-0.254-0.459-0.522-0.459
          c-0.266,0-0.484,0.198-0.521,0.454c-0.281,0.012-0.505,0.242-0.505,0.526c0,0.29,0.233,0.524,0.522,0.528
          c0.065,0.219,0.265,0.379,0.505,0.379c0.242,0,0.443-0.163,0.506-0.384C258.542,64.562,258.746,64.341,258.746,64.07z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M253.203,80.244c0-0.266-0.196-0.483-0.452-0.521c-0.034-0.259-0.254-0.459-0.522-0.459
          c-0.266,0-0.484,0.198-0.521,0.454c-0.281,0.012-0.505,0.242-0.505,0.526c0,0.29,0.233,0.524,0.522,0.528
          c0.065,0.219,0.265,0.379,0.505,0.379c0.242,0,0.443-0.163,0.506-0.384C252.999,80.736,253.203,80.515,253.203,80.244z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M264.486,67.937c0-0.404-0.299-0.736-0.687-0.793c-0.052-0.394-0.386-0.699-0.794-0.699
          c-0.405,0-0.737,0.301-0.793,0.69c-0.427,0.019-0.769,0.369-0.769,0.801c0,0.441,0.355,0.798,0.794,0.803
          c0.099,0.333,0.403,0.577,0.768,0.577c0.368,0,0.674-0.248,0.77-0.585C264.174,68.685,264.486,68.349,264.486,67.937z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M254.964,59.002c0-0.404-0.299-0.736-0.687-0.793c-0.052-0.394-0.386-0.699-0.794-0.699
          c-0.405,0-0.737,0.301-0.793,0.69c-0.427,0.019-0.769,0.369-0.769,0.801c0,0.441,0.355,0.798,0.794,0.803
          c0.099,0.333,0.403,0.577,0.768,0.577c0.368,0,0.674-0.248,0.77-0.585C254.653,59.75,254.964,59.414,254.964,59.002z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M239.638,55.657c0-0.266-0.197-0.483-0.452-0.521c-0.034-0.259-0.254-0.459-0.522-0.459
          c-0.266,0-0.484,0.198-0.521,0.454c-0.281,0.012-0.505,0.242-0.505,0.526c0,0.29,0.233,0.524,0.522,0.528
          c0.065,0.219,0.265,0.379,0.505,0.379c0.242,0,0.443-0.163,0.506-0.384C239.433,56.149,239.638,55.928,239.638,55.657z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M246.551,43.224c0-0.439-0.325-0.799-0.746-0.861c-0.057-0.427-0.419-0.758-0.862-0.758
          c-0.44,0-0.8,0.326-0.861,0.75c-0.464,0.021-0.835,0.4-0.835,0.87c0,0.479,0.385,0.866,0.862,0.872
          c0.107,0.361,0.438,0.626,0.834,0.626c0.399,0,0.732-0.269,0.836-0.635C246.213,44.037,246.551,43.672,246.551,43.224z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M231.551,72.637c0-0.439-0.325-0.799-0.746-0.861c-0.057-0.427-0.419-0.758-0.862-0.758
          c-0.44,0-0.8,0.326-0.861,0.75c-0.464,0.021-0.835,0.4-0.835,0.87c0,0.479,0.385,0.866,0.862,0.872
          c0.107,0.361,0.438,0.626,0.834,0.626c0.399,0,0.732-0.269,0.836-0.635C231.213,73.45,231.551,73.085,231.551,72.637z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M241.79,48.449c0-0.491-0.363-0.893-0.835-0.963c-0.064-0.478-0.468-0.848-0.964-0.848
          c-0.492,0-0.895,0.365-0.963,0.838c-0.519,0.023-0.934,0.448-0.934,0.973c0,0.535,0.431,0.969,0.964,0.976
          c0.12,0.404,0.49,0.7,0.932,0.7c0.447,0,0.819-0.301,0.935-0.711C241.412,49.357,241.79,48.949,241.79,48.449z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M238.628,62.022c0-0.552-0.408-1.004-0.938-1.082c-0.071-0.537-0.527-0.953-1.083-0.953
          c-0.553,0-1.006,0.41-1.082,0.942c-0.583,0.026-1.049,0.503-1.049,1.093c0,0.602,0.484,1.089,1.084,1.097
          c0.135,0.454,0.55,0.787,1.048,0.787c0.502,0,0.92-0.339,1.051-0.799C238.203,63.043,238.628,62.585,238.628,62.022z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M237.092,78.274c0-0.403-0.299-0.734-0.686-0.791c-0.052-0.393-0.385-0.697-0.792-0.697
          c-0.405,0-0.736,0.3-0.792,0.689c-0.427,0.019-0.768,0.368-0.768,0.8c0,0.44,0.354,0.796,0.793,0.802
          c0.099,0.332,0.402,0.576,0.766,0.576c0.367,0,0.673-0.248,0.769-0.584C236.781,79.021,237.092,78.685,237.092,78.274z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M229.82,65.719c0-0.403-0.299-0.734-0.686-0.791c-0.052-0.393-0.385-0.697-0.792-0.697
          c-0.405,0-0.736,0.3-0.792,0.689c-0.427,0.019-0.768,0.368-0.768,0.8c0,0.44,0.354,0.796,0.793,0.802
          c0.099,0.332,0.402,0.576,0.766,0.576c0.367,0,0.673-0.248,0.769-0.584C229.509,66.466,229.82,66.131,229.82,65.719z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M253.333,51.119c0-0.465-0.344-0.846-0.79-0.912c-0.06-0.453-0.444-0.803-0.913-0.803
          c-0.466,0-0.848,0.346-0.912,0.794c-0.492,0.022-0.884,0.424-0.884,0.921c0,0.507,0.408,0.917,0.913,0.924
          c0.114,0.382,0.464,0.663,0.883,0.663c0.423,0,0.776-0.285,0.886-0.673C252.976,51.98,253.333,51.593,253.333,51.119z"
            fill="#FFFFFF"
          />
        </G>
      </G>
    </Svg>
  );
}
