/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';

export default function DesktopSelectLoanSummer(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 287 120"
      x="0px"
      xmlns="http://www.w3.org/2000/Svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Rect fill="#E7EBEE" height="1.9" width="6.5" x="109.1" y="24.5" />
        <Path d="M116.2,22.6v1.8l-0.4,0.1v21.4l3.9-4.3V24.4c0,0,0.6-0.4,0.5-0.6s-0.2-1-0.2-1l-0.3-0.3L116.2,22.6z" fill="#BAC2CA" />
        <Path
          d="M115.8,46.2H109c-0.2,0-0.3-0.2-0.3-0.3V24.5c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v21h6.3l3.8-4V24.6 c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v17.1c0,0.1,0,0.2-0.1,0.2l-4,4.2C115.9,46.1,115.9,46.2,115.8,46.2z"
          fill="#010101"
        />
        <Path
          d="M115.7,40.6c-0.1,0-0.2-0.1-0.2-0.2V24.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2v15.9 C116,40.5,115.9,40.6,115.7,40.6z"
          fill="#010101"
        />
        <Path
          d="M119.7,24.7h-10.9c-0.5,0-0.9-0.4-0.9-0.9v-0.7c0-0.5,0.4-0.9,0.9-0.9h10.9c0.5,0,0.9,0.4,0.9,0.9v0.7 C120.6,24.3,120.2,24.7,119.7,24.7z M108.8,22.9c-0.1,0-0.3,0.1-0.3,0.3v0.7c0,0.1,0.1,0.3,0.3,0.3h10.9c0.1,0,0.3-0.1,0.3-0.3 v-0.7c0-0.1-0.1-0.3-0.3-0.3H108.8z"
          fill="#010101"
        />
        <Path
          d="M116.2,24.4c-0.1,0-0.2-0.1-0.2-0.2v-1.6c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v1.6 C116.4,24.3,116.3,24.4,116.2,24.4z"
          fill="#010101"
        />
        <Polygon fill="#FFFFFF" points="176.1,112.5 176.1,52.3 136.4,22 100,50.7 100,112.6 " />
        <Rect fill="#E7EBEE" height="59.5" width="23.1" x="175.2" y="52.9" />
        <Path d="M179.1,60h19.6v-1.7L178.6,58l-3.2-3l-0.1,2.4l2.1,1.9C177.9,59.8,178.5,60,179.1,60z" fill="#BAC2CA" />
        <Path d="M183.3,82.3c0,0-2.9,6,1,11.4c3.9,5.4,13.9,6.4,13.9,6.4v-9L183.3,82.3z" fill="#BAC2CA" />
        <Path
          d="M198.1,112.4c-0.2,0-0.3-0.2-0.3-0.3V50.3c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3V112 C198.5,112.2,198.3,112.4,198.1,112.4z"
          fill="#010101"
        />
        <Path d="M204.6,58.1h-23.5v-3h23.5c0.7,0,1.3,0.6,1.3,1.3v0.4C205.9,57.5,205.3,58.1,204.6,58.1z" fill="#E7EBEE" />
        <Path
          d="M204.6,58.4h-25.1c-0.2,0-0.3-0.2-0.3-0.3s0.2-0.3,0.3-0.3h25.1c0.2,0,0.3,0.2,0.3,0.3S204.8,58.4,204.6,58.4z "
          fill="#010101"
        />
        <Path
          d="M204.2,58.4h-2.1c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h2.1c0.7,0,1.2-0.5,1.2-1.1 c0-0.6-0.6-1.1-1.2-1.1h-2.1c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h2.1c1.1,0,1.9,0.8,1.9,1.8 C206.2,57.6,205.3,58.4,204.2,58.4z"
          fill="#010101"
        />
        <G>
          <Polygon fill="#BAC2CA" points="204.5,54.7 161,20.5 136.8,20.5 180.8,55.1 176.9,55.1 " />
          <Path
            d="M137.4,21.1c-0.3-0.3-0.8-0.5-1.2-0.5c-0.5,0-0.9,0.2-1.2,0.5L92.7,55.6c-0.6,0.6-0.5,1.6,0.2,2.1 c0.7,0.5,1.7,0.5,2.3-0.2l41-33.2l42.3,33.2c0.3,0.3,0.8,0.5,1.2,0.5c0.4,0,0.8-0.1,1.1-0.4c0.7-0.6,0.7-1.5,0.1-2.1L137.4,21.1z"
            fill="#FFFFFF"
          />
          <Polygon display="none" fill="#FFFFFF" points="136.1,24.4 161.8,50.4 163.8,81.9 109.6,81.9 109.6,58.2 105.1,58.2 " />
          <Polygon fill="#BAC2CA" points="99.4,53.4 99.4,58.1 95,58.2 " />
          <Path
            d="M99.4,58.4h-4.5c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h4.5c0.2,0,0.3,0.2,0.3,0.3 C99.7,58.3,99.6,58.4,99.4,58.4z"
            fill="#010101"
          />
          <Polygon fill="#BAC2CA" points="137.9,26 99.8,56.6 99.8,53.8 136.2,24.6 " />
          <Path
            d="M204.9,55.5h-24c-0.2,0-0.3-0.2-0.3-0.3s0.2-0.3,0.3-0.3h23l-43.1-34h-10.2c-0.2,0-0.3-0.2-0.3-0.3 c0-0.2,0.2-0.3,0.3-0.3H161c0.1,0,0.2,0,0.2,0.1l43.9,34.7c0.1,0.1,0.2,0.2,0.1,0.4C205.2,55.4,205.1,55.5,204.9,55.5z"
            fill="#010101"
          />
          <Path
            d="M148.4,20.8h-1.5c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h1.5c0.2,0,0.3,0.2,0.3,0.3 C148.7,20.7,148.6,20.8,148.4,20.8z"
            fill="#010101"
          />
          <Path
            d="M136.1,20.9c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3l8.6-0.1c0,0,0,0,0,0c0.2,0,0.3,0.2,0.3,0.3 c0,0.2-0.2,0.3-0.3,0.3L136.1,20.9C136.1,20.9,136.1,20.9,136.1,20.9z"
            fill="#010101"
          />
          <Path
            d="M94,58.4c-0.4,0-0.9-0.1-1.3-0.4c-0.4-0.3-0.7-0.8-0.7-1.3c0-0.5,0.1-1,0.5-1.3l42.3-34.5 c0.3-0.4,0.9-0.6,1.4-0.6c0.6,0,1.1,0.2,1.5,0.6l43.5,34.4c0.4,0.4,0.6,0.9,0.5,1.3c0,0.5-0.3,0.9-0.6,1.3c-0.8,0.6-2,0.6-2.7-0.1 l-42.1-33l-40.8,33C95,58.2,94.5,58.4,94,58.4z M136.2,21C136.2,21,136.2,21,136.2,21c-0.4,0-0.7,0.2-1,0.4L92.9,55.9 c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.2,0.6,0.4,0.8c0.5,0.4,1.4,0.4,1.8-0.1L136,24.1c0.1-0.1,0.3-0.1,0.4,0l42.3,33.2 c0.5,0.5,1.3,0.5,1.8,0.1c0.2-0.2,0.4-0.5,0.4-0.8c0-0.3-0.1-0.6-0.3-0.8l-43.5-34.4c0,0,0,0,0,0C136.9,21.1,136.5,21,136.2,21z"
            fill="#010101"
          />
        </G>
        <Path
          d="M99.3,97.6c-0.7-0.1-1.4-0.2-2.2-0.2c-4.4,0-8.3,2.1-10.8,5.5c-0.5,0.7-1.3,0.9-2.1,0.6c-0.9-0.3-2-0.5-3-0.5 c-2.9,0-5.6,1.4-7.3,3.5c0,0-1.9,2.4-2,5.6h27.4L99.3,97.6z"
          fill="#FFFFFF"
        />
        <Path
          d="M74.8,105.7c0,0,2.8-2.3,7.4-1.3c4.6,1,5.1-0.1,6.5-1.7s6.7-4,10.7-2.9v-2.2c0,0-4.7-1-8.6,1.3 s-4.5,4.1-5.1,4.4c-0.6,0.2-0.9,0.4-2.7-0.1s-6.2,0.1-8.3,2.6"
          fill="#DCE2E7"
        />
        <Path
          d="M71.7,112.5C71.7,112.5,71.7,112.5,71.7,112.5c-0.2,0-0.3-0.2-0.3-0.4c0.1-2.7,2.2-5.7,2.3-5.9 c1.9-2.3,4.6-3.6,7.5-3.6c1.1,0,2.1,0.2,3.1,0.5c0.6,0.2,1.3,0,1.7-0.5c2.6-3.5,6.7-5.6,11-5.6c0.7,0,1.5,0.1,2.2,0.2 c0.2,0,0.3,0.2,0.3,0.4c0,0.2-0.2,0.3-0.4,0.3c-0.7-0.1-1.4-0.2-2.1-0.2c-4.1,0-8,2-10.5,5.3c-0.6,0.8-1.6,1.1-2.5,0.8 c-0.9-0.3-1.9-0.5-2.9-0.5c-2.7,0-5.3,1.2-7,3.4c0,0-2.1,3-2.2,5.5C72,112.4,71.9,112.5,71.7,112.5z"
          fill="#010101"
        />
        <Path
          d="M99.5,102.2c-0.2,0-0.3-0.2-0.3-0.3V77.3c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v24.6 C99.8,102.1,99.7,102.2,99.5,102.2z"
          fill="#010101"
        />
        <Path
          d="M99.5,75c-0.2,0-0.3-0.2-0.3-0.3V54.3c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v20.4C99.8,74.9,99.7,75,99.5,75z "
          fill="#010101"
        />
        <Path
          d="M175.1,112.5c-0.2,0-0.3-0.2-0.3-0.3V54.9c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v57.2 C175.5,112.3,175.3,112.5,175.1,112.5z"
          fill="#010101"
        />
        <Path
          d="M235.5,78.8c-0.5-7.3-5.7-10.7-9.8-12.3c-2.5-0.9-4-3.4-3.8-6c0.1-0.6,0.1-1.2,0-1.9 c-0.3-6.9-6-12.6-12.9-12.9c-7.8-0.3-14.2,5.9-14.2,13.6c0,0.1,0,0.2,0,0.3c0.1,3.2-1.8,6-4.6,7.4c-3.7,1.8-7.8,5.2-7.9,11.8 c0,3.5,1.4,6.8,3.6,9.2c2.3,3.8,11.7,6.6,23,6.6c10.4,0,19.3-2.4,22.4-5.8C233.8,86.3,235.8,82.8,235.5,78.8z"
          fill="#F0F3F5"
        />
        <Path
          d="M225.7,66.5c-2.5-0.9-4-3.4-3.8-6c0.1-0.6,0.1-1.2,0-1.9c-0.3-6.9-6-12.6-12.9-12.9c-1.4-0.1-2.7,0.1-4,0.4 l0,0.5c0,0,10.5,1.2,11.3,8.7s-1.1,11.4,2,16.9c3.1,5.5,1.9,22.5-16.1,21.7l0,0.3c2.1,0.2,4.3,0.3,6.6,0.3 c10.4,0,19.3-2.4,22.4-5.8c2.7-2.5,4.6-6,4.4-10C235,71.5,229.8,68.1,225.7,66.5z"
          fill="#DCE2E7"
        />
        <Path
          d="M208.8,94.9c-11.2,0-20.8-2.8-23.3-6.7c-2.4-2.6-3.7-5.9-3.6-9.4c0.1-6.9,4.5-10.4,8.1-12.1 c2.8-1.3,4.5-4.1,4.4-7.1l0-0.3c0-3.8,1.5-7.4,4.3-10.1c2.8-2.7,6.4-4,10.3-3.8c7.1,0.3,12.9,6.1,13.3,13.2c0,0.6,0,1.3,0,1.9 c-0.2,2.5,1.2,4.8,3.6,5.7c4.3,1.6,9.5,5.1,10,12.6l0,0c0.2,3.7-1.4,7.4-4.5,10.2C228.1,92.6,219.1,94.9,208.8,94.9z M208.4,46.1 c-3.4,0-6.7,1.3-9.1,3.7c-2.6,2.5-4.1,5.9-4.1,9.6l0,0.2c0.1,3.3-1.8,6.3-4.8,7.7c-3.5,1.6-7.6,5-7.7,11.5c0,3.3,1.2,6.4,3.5,8.9 c2.4,3.8,11.9,6.5,22.7,6.5c10.1,0,19-2.3,22.1-5.7c2.9-2.7,4.5-6.3,4.3-9.7l0,0c-0.5-7.1-5.5-10.4-9.6-12c-2.6-1-4.2-3.6-4-6.4 c0.1-0.6,0.1-1.2,0-1.8c-0.3-6.7-5.9-12.3-12.6-12.6C208.8,46.1,208.6,46.1,208.4,46.1z"
          fill="#010101"
        />
        <Path
          d="M95.9,104c0,0,5.1-1.3,7.9,0.2c2.8,1.5,3.3,3.7,4.8,4.4s2.7,0.4,4.1-0.5s6.2-0.5,7.4,3.2c0,0-1-5.2-5.8-5 c-4.8,0.3-3.1,2.2-5.3-0.5S102.5,99.5,95.9,104z"
          fill="#DCE2E7"
        />
        <Path
          d="M120.2,112.7c-0.1,0-0.2,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.1-0.3c0-1.6-0.5-3-1.6-4.1c-1-1.1-2.4-1.6-3.8-1.6 c-1.1,0-2.1,0.3-3,1c-0.3,0.2-0.8,0.3-1.2,0.2c-0.4-0.1-0.8-0.5-1-0.9c-1.1-2.8-4.2-4.6-7.7-4.6c-2.7,0-5.2,1.1-6.7,3 c-0.6,0.7-1.5,1-2.3,0.7c-0.8-0.2-1.6-0.4-2.4-0.4c-0.2,0-0.3-0.2-0.3-0.3s0.2-0.3,0.3-0.3c0.9,0,1.7,0.1,2.6,0.4 c0.6,0.2,1.2,0,1.6-0.5c1.6-2.1,4.4-3.3,7.3-3.3c3.8,0,7.1,2,8.3,5.1c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0,0.6-0.1 c1-0.7,2.2-1.1,3.4-1.1c1.6,0,3.2,0.7,4.3,1.9c1.2,1.2,1.8,2.9,1.8,4.7c0,0.1,0,0.2-0.1,0.2C120.4,112.7,120.3,112.7,120.2,112.7z"
          fill="#010101"
        />
        <G>
          <Path d="M90.1,105.2c-0.4,0-0.8,0-1.2,0.1" fill="#FFFFFF" />
          <Path
            d="M88.9,105.7c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.4,0.3-0.4c0.4-0.1,0.8-0.1,1.2-0.1c0.2,0,0.3,0.2,0.3,0.3 s-0.2,0.3-0.3,0.3C89.8,105.6,89.4,105.6,88.9,105.7C89,105.7,89,105.7,88.9,105.7z"
            fill="#010101"
          />
        </G>
        <Path
          d="M171.8,56.7h-69.7c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h69.7c0.1,0,0.2,0.1,0.2,0.2S171.9,56.7,171.8,56.7z "
          fill="#010101"
        />
        <Path d="M173.8,54.3H155c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h18.8c0.1,0,0.2,0.1,0.2,0.2S174,54.3,173.8,54.3z" fill="#010101" />
        <Path
          d="M152.6,54.3h-46.7c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h46.7c0.1,0,0.2,0.1,0.2,0.2S152.8,54.3,152.6,54.3z "
          fill="#010101"
        />
        <Path
          d="M79.4,53.5c1.1,0,1.6-1.2,0.9-2.1c-1.2-1.4-3.5-2.3-6.2-2.3c-0.4,0-0.9,0-1.3,0.1C72.4,49.3,72,49,72,48.5l0,0 c0-2.3-3-4.2-6.6-4.2c-1,0-2,0.1-2.8,0.4c-0.4,0.1-0.8-0.1-0.9-0.5c-0.8-3.5-4.5-6.1-9-6.1c-5.1,0-9.2,3.3-9.2,7.5 c0,0.1,0,0.3,0,0.4c-0.2,0-0.4-0.1-0.6-0.1c-1.9,0-3.5,1.2-4,2.9c-0.1,0.4-0.5,0.6-0.9,0.5c-0.4-0.1-0.8-0.2-1.2-0.2 c-1.3,0-2.5,0.6-3.3,1.6c-0.9,1.1-0.1,2.8,1.4,2.8H79.4z"
          fill="#E7EBEE"
        />
        <Path
          d="M198.8,24.2c-1.3,0-1.9-1.5-1.1-2.4c1.4-1.6,4.2-2.7,7.4-2.7c0.5,0,1,0,1.5,0.1c0.5,0.1,1-0.4,1-0.9l0,0 c0-2.8,3.5-5.1,7.9-5.1c1.2,0,2.4,0.2,3.4,0.5c0.5,0.1,1-0.1,1.1-0.6c0.9-4.1,5.4-7.3,10.7-7.3c6,0,10.9,4,10.9,8.9 c0,0.2,0,0.3,0,0.5c0.3,0,0.5-0.1,0.8-0.1c2.3,0,4.2,1.5,4.8,3.5c0.1,0.5,0.6,0.7,1.1,0.6c0.4-0.1,0.9-0.2,1.4-0.2 c1.6,0,3,0.7,4,1.9c1.1,1.4,0.1,3.4-1.6,3.4H198.8z"
          fill="#E7EBEE"
        />
        <Path
          d="M208.3,112.6c-0.2,0-0.3-0.2-0.3-0.3V72.3c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v39.9 C208.6,112.4,208.5,112.6,208.3,112.6z"
          fill="#010101"
        />
        <Path
          d="M82.6,112.4c-0.2,0-0.3-0.2-0.3-0.3V89.4c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v22.7 C83,112.3,82.8,112.4,82.6,112.4z"
          fill="#010101"
        />
        <Path
          d="M208.1,85.3c-0.1,0-0.1,0-0.2-0.1l-9.1-6.4c-0.2-0.1-0.2-0.3-0.1-0.5c0.1-0.2,0.3-0.2,0.5-0.1l9.1,6.4 c0.2,0.1,0.2,0.3,0.1,0.5C208.3,85.3,208.2,85.3,208.1,85.3z"
          fill="#010101"
        />
        <Path
          d="M208.4,90.1c-0.1,0-0.2,0-0.3-0.1c-0.1-0.2-0.1-0.4,0.1-0.5l7.2-5.2c0.2-0.1,0.4-0.1,0.5,0.1 c0.1,0.2,0.1,0.4-0.1,0.5l-7.2,5.2C208.6,90.1,208.5,90.1,208.4,90.1z"
          fill="#010101"
        />
        <Path d="M62.1,112.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.6853" />
        <Path d="M239.4,112.5" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5715" />
        <Ellipse cx="137" cy="42.1" fill="#F0F1F0" rx="3.8" ry="4.4" />
        <Path
          d="M136.3,47.3c-2.9,0-5.2-2.4-5.2-5.3c0-2.9,2.3-5.3,5.2-5.3c2.9,0,5.2,2.4,5.2,5.3 C141.5,44.9,139.2,47.3,136.3,47.3z M136.3,37.4c-2.5,0-4.5,2.1-4.5,4.6s2,4.6,4.5,4.6c2.5,0,4.5-2.1,4.5-4.6 S138.8,37.4,136.3,37.4z"
          fill="#010101"
        />
        <Path
          d="M137,47.1C137,47.1,137,47.1,137,47.1c-2.5-0.5-4.3-2.6-4.3-5.1c0-1.7,0.9-3.3,2.4-4.3c0.1-0.1,0.2,0,0.3,0.1 c0.1,0.1,0,0.2-0.1,0.3c-1.4,0.9-2.2,2.4-2.2,3.9c0,2.2,1.6,4.2,3.9,4.6c0.1,0,0.2,0.1,0.2,0.3C137.2,47.1,137.1,47.1,137,47.1z"
          fill="#010101"
        />
        <G>
          <polyline fill={svgHighlight} points="145.7,103 145.7,74.3 161.3,74.3 161.3,103 " />
          <Path
            d="M161.3,103.3c-0.2,0-0.3-0.2-0.3-0.3V74.7h-15V103c0,0.2-0.2,0.3-0.3,0.3s-0.3-0.2-0.3-0.3V74.3 c0-0.2,0.2-0.3,0.3-0.3h15.7c0.2,0,0.3,0.2,0.3,0.3V103C161.7,103.2,161.5,103.3,161.3,103.3z"
            fill="#010101"
          />
        </G>
        <Path
          d="M160.8,112.6h-24.4c-0.2,0-0.3-0.2-0.3-0.3v-4.7c0-0.2,0.2-0.3,0.3-0.3h4.1V103c0-0.2,0.2-0.3,0.3-0.3h19.9 c0.2,0,0.3,0.2,0.3,0.3v9.2C161.2,112.4,161,112.6,160.8,112.6z M136.8,111.9h23.7v-8.6h-19.3v4.2c0,0.2-0.2,0.3-0.3,0.3h-4.1 V111.9z"
          fill="#010101"
        />
        <G>
          <Polygon fill="#BAC2CA" points="158.5,103 158.5,107.6 154.1,107.6 154.1,112.2 158.5,112.2 159,112.2 164.1,112.2 164.1,103 " />
          <Path
            d="M164.1,112.6h-10c-0.2,0-0.3-0.2-0.3-0.3v-4.7c0-0.2,0.2-0.3,0.3-0.3h4.1V103c0-0.2,0.2-0.3,0.3-0.3h5.6 c0.2,0,0.3,0.2,0.3,0.3v9.2C164.5,112.4,164.3,112.6,164.1,112.6z M154.4,111.9h9.3v-8.6h-4.9v4.2c0,0.2-0.2,0.3-0.3,0.3h-4.1 V111.9z"
            fill="#010101"
          />
        </G>
        <Path
          d="M137,45.3c-0.1,0-0.2-0.1-0.2-0.2v-6.7c0-0.1,0.1-0.2,0.2-0.2s0.2,0.1,0.2,0.2v6.7 C137.2,45.2,137.1,45.3,137,45.3z"
          fill="#010101"
        />
        <Path d="M141,42h-7c-0.1,0-0.2-0.1-0.2-0.2s0.1-0.2,0.2-0.2h7c0.1,0,0.2,0.1,0.2,0.2S141.1,42,141,42z" fill="#010101" />
        <Rect fill="#E7EBEE" height="20.6" width="24.4" x="109.3" y="67.6" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="108.3,83.9 114.2,67.6 108.9,67.6 108.3,68.9 " />
        <Polygon fill="#FFFFFF" opacity="0.7" points="115.7,88.1 112.4,88.1 120.1,67.6 123.5,67.6 " />
        <Path
          d="M133.9,88.6h-25.7c-0.2,0-0.3-0.2-0.3-0.3V66.4c0-0.2,0.2-0.3,0.3-0.3h25.7c0.2,0,0.3,0.2,0.3,0.3v21.8 C134.3,88.5,134.1,88.6,133.9,88.6z M108.5,88h25V66.8h-25V88z"
          fill="#010101"
        />
        <Path
          d="M109.4,87.3c-0.1,0-0.2-0.1-0.2-0.2V67.6c0-0.1,0.1-0.2,0.2-0.2H134c0.1,0,0.2,0.1,0.2,0.2 c0,0.1-0.1,0.2-0.2,0.2h-24.4V87C109.6,87.2,109.5,87.3,109.4,87.3z"
          fill="#010101"
        />
        <Path
          d="M117,87c-0.2,0-0.3-0.2-0.3-0.3V76c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v10.7C117.3,86.9,117.2,87,117,87z"
          fill="#010101"
        />
        <Path
          d="M117,74.5c-0.2,0-0.3-0.2-0.3-0.3v-5c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v5C117.3,74.3,117.2,74.5,117,74.5 z"
          fill="#010101"
        />
        <Path
          d="M125.1,81.3c-0.2,0-0.3-0.2-0.3-0.3V69.2c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3V81 C125.4,81.1,125.3,81.3,125.1,81.3z"
          fill="#010101"
        />
        <Path
          d="M125.1,87c-0.2,0-0.3-0.2-0.3-0.3v-3.9c0-0.2,0.2-0.3,0.3-0.3s0.3,0.2,0.3,0.3v3.9 C125.4,86.9,125.3,87,125.1,87z"
          fill="#010101"
        />
        <Path
          d="M133.9,77.9h-23c-0.2,0-0.3-0.2-0.3-0.3c0-0.2,0.2-0.3,0.3-0.3h23c0.2,0,0.3,0.2,0.3,0.3 C134.3,77.7,134.1,77.9,133.9,77.9z"
          fill="#010101"
        />
        <G>
          <Path d="M155.2,76h-16l1.1-2.4c0.2-0.5,0.4-0.9,0.7-1.3c1.2-1.8,3.2-3,5.3-3.3l0,0l15,0L155.2,76z" fill="#E7EBEE" />
          <Path
            d="M155.2,76.3h-16c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2,0-0.3l1.1-2.4c0.2-0.5,0.5-0.9,0.7-1.4 c1.2-1.8,3.2-3.1,5.6-3.5l15.1,0c0,0,0,0,0,0c0.1,0,0.3,0.1,0.3,0.2c0.1,0.1,0,0.3-0.1,0.4l-6.1,7.1 C155.4,76.3,155.3,76.3,155.2,76.3z M139.7,75.7H155l5.5-6.4l-14.3,0c-2.1,0.3-4,1.5-5,3.2c-0.3,0.4-0.5,0.8-0.7,1.3L139.7,75.7z"
            fill="#010101"
          />
        </G>
        <G>
          <Path d="M161.3,76h-7.1l1.1-2.4c0.4-0.9,1-1.8,1.7-2.6c1-1.1,2.4-1.8,3.9-2l0.5-0.1L161.3,76z" fill="#BAC2CA" />
          <Path
            d="M161.3,76.3h-7.1c-0.1,0-0.2-0.1-0.3-0.2c-0.1-0.1-0.1-0.2,0-0.3l1.1-2.4c0.4-1,1-1.9,1.7-2.7 c1-1.1,2.5-1.9,4.1-2.1l0.5-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0.1,0.1,0.2,0.1,0.3l0,7.1c0,0.1,0,0.2-0.1,0.2 C161.5,76.3,161.4,76.3,161.3,76.3z M154.7,75.7h6.2l0-6.3l-0.1,0c-1.4,0.2-2.7,0.9-3.7,1.9c-0.7,0.7-1.2,1.6-1.6,2.5L154.7,75.7z "
            fill="#010101"
          />
        </G>
        <Path d="M118.5,88c0-2.2-1.8-4-3.9-4c-1.6,0-3,1.1-3.5,2.6c-0.6-0.8-1.5-1.4-2.5-1.4c-1.6,0-2.9,1.3-3.1,2.9" fill="#FFFFFF" />
        <Path
          d="M105.5,88C105.5,88,105.5,88,105.5,88c-0.2,0-0.4-0.2-0.3-0.4c0.2-1.8,1.7-3.1,3.4-3.1c0.9,0,1.8,0.4,2.5,1 c0.7-1.3,2.1-2.2,3.6-2.2c2.2,0,4.2,2,4.2,4.2c0,0.2-0.2,0.3-0.3,0.3s-0.3-0.2-0.3-0.3c0-1.9-1.6-3.5-3.5-3.5 c-1.4,0-2.7,0.9-3.2,2.2c0,0.1-0.2,0.2-0.3,0.2c-0.1,0-0.3,0-0.3-0.1c-0.5-0.7-1.4-1.2-2.3-1.2c-1.4,0-2.6,1.1-2.8,2.5 C105.8,87.9,105.7,88,105.5,88z"
          fill="#010101"
        />
        <Path d="M116.9,88c0-2,1.6-3.7,3.6-3.7c1.5,0,2.8,1,3.2,2.4c0.5-0.8,1.4-1.3,2.4-1.3c1.5,0,2.7,1.2,2.9,2.7" fill="#FFFFFF" />
        <Path
          d="M129,88c-0.2,0-0.3-0.1-0.3-0.3c-0.1-1.3-1.2-2.3-2.5-2.3c-0.8,0-1.6,0.4-2.1,1.1c-0.1,0.1-0.2,0.2-0.3,0.1 c-0.1,0-0.2-0.1-0.3-0.2c-0.5-1.2-1.6-2-2.9-2c-1.7,0-3.2,1.5-3.2,3.2c0,0.2-0.2,0.3-0.3,0.3s-0.3-0.2-0.3-0.3 c0-2.1,1.8-3.9,3.9-3.9c1.4,0,2.7,0.8,3.3,2c0.6-0.6,1.4-0.9,2.3-0.9c1.7,0,3,1.2,3.2,2.9C129.3,87.8,129.2,88,129,88 C129,88,129,88,129,88z"
          fill="#010101"
        />
        <G>
          <Path d="M133.2,93.8H107c-1.5,0-2.8-1.3-2.8-2.8v-2.7h28.9V93.8z" fill="#E7EBEE" />
          <Path
            d="M133.2,94.2h-26.7c-1.4,0-2.6-1.2-2.6-2.6v-3.3c0-0.2,0.2-0.3,0.3-0.3h28.9c0.2,0,0.3,0.2,0.3,0.3v5.5 C133.5,94,133.3,94.2,133.2,94.2z M104.6,88.6v2.9c0,1.1,0.9,1.9,1.9,1.9h26.3v-4.9H104.6z"
            fill="#010101"
          />
        </G>
        <G>
          <Path d="M133.1,93.8h-0.7c-1.2,0-2.2-1-2.2-2.2v-3.3h5.1v3.3C135.3,92.8,134.3,93.8,133.1,93.8z" fill="#BAC2CA" />
          <Path
            d="M133.2,94.2h-0.9c-1.4,0-2.5-1.1-2.5-2.5v-3.4c0-0.2,0.2-0.3,0.3-0.3h5.1c0.2,0,0.3,0.2,0.3,0.3v3.4 C135.7,93,134.6,94.2,133.2,94.2z M130.5,88.6v3.1c0,1,0.8,1.8,1.8,1.8h0.9c1,0,1.8-0.8,1.8-1.8v-3.1H130.5z"
            fill="#010101"
          />
        </G>
        <Path
          d="M151.5,107.8h-10.4c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2h10.4c0.1,0,0.2,0.1,0.2,0.2 C151.7,107.7,151.6,107.8,151.5,107.8z"
          fill="#010101"
        />
        <G>
          <Circle cx="153.3" cy="81.5" fill="#FFFFFF" r="0.7" />
          <Path
            d="M153.3,82.4c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9S153.8,82.4,153.3,82.4z M153.3,81.1 c-0.2,0-0.4,0.2-0.4,0.4s0.2,0.4,0.4,0.4s0.4-0.2,0.4-0.4S153.5,81.1,153.3,81.1z"
            fill="#010101"
          />
        </G>
        <Path
          d="M87.2,89.5H78c-0.2,0-0.3-0.2-0.3-0.3v-4c0-1.2,1-2.1,2.1-2.1h7.4c0.2,0,0.3,0.2,0.3,0.3v5.8 C87.5,89.3,87.4,89.5,87.2,89.5z M78.3,88.8h8.5v-5.1h-7c-0.8,0-1.5,0.7-1.5,1.5V88.8z"
          fill="#010101"
        />
        <G>
          <Path d="M88.9,89.2h-4.1v-4c0-1,0.8-1.8,1.8-1.8h0.5c1,0,1.8,0.8,1.8,1.8V89.2z" fill="#BAC2CA" />
          <Path
            d="M88.9,89.5h-4.1c-0.2,0-0.3-0.2-0.3-0.3V85c0-1.1,0.9-2,2-2h0.7c1.1,0,2,0.9,2,2v4.1 C89.3,89.3,89.1,89.5,88.9,89.5z M85.2,88.8h3.4V85c0-0.7-0.6-1.4-1.4-1.4h-0.7c-0.7,0-1.4,0.6-1.4,1.4V88.8z"
            fill="#010101"
          />
        </G>
        <Path
          d="M83,86c-0.1,0-0.3-0.1-0.3-0.2l-1.2-3.9L80.8,82c-0.2,0.1-0.4,0-0.4-0.2c-0.1-0.2,0-0.4,0.2-0.4l1-0.4 c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.2l1.3,4.3c0.1,0.2,0,0.4-0.2,0.4C83,86,83,86,83,86z"
          fill="#010101"
        />
        <Path
          d="M106.7,108.5c-0.1,0-0.2,0-0.2-0.1c-0.2-0.4-0.5-0.8-0.8-1.2c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3,0 c0.3,0.4,0.6,0.8,0.9,1.3c0.1,0.1,0,0.2-0.1,0.3C106.8,108.5,106.8,108.5,106.7,108.5z"
          fill="#010101"
        />
        <Path
          d="M104.8,106.2c0,0-0.1,0-0.1,0c-1.9-1.4-3.9-1.5-4-1.5c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2 c0.1,0,2.2,0.1,4.2,1.5c0.1,0.1,0.1,0.2,0,0.3C104.9,106.2,104.9,106.2,104.8,106.2z"
          fill="#010101"
        />
        <Path
          d="M198.1,59.4C198,59.4,198,59.4,198.1,59.4c-0.1,0-0.2-0.1-0.2-0.3c0.8-8.4,7.7-10.4,7.8-10.4 c0.1,0,0.2,0,0.3,0.2c0,0.1,0,0.2-0.2,0.3c-0.1,0-6.7,1.9-7.5,10C198.3,59.3,198.2,59.4,198.1,59.4z"
          fill="#010101"
        />
        <Path
          d="M197.3,63.5c0,0-0.1,0-0.1,0c-0.1-0.1-0.1-0.2-0.1-0.3c0.3-0.5,0.5-1.8,0.5-2.2c0-0.1,0.1-0.2,0.2-0.2 c0.1,0,0.2,0.1,0.2,0.2c0,0.1-0.1,1.7-0.6,2.5C197.4,63.5,197.4,63.5,197.3,63.5z"
          fill="#010101"
        />
        <G>
          <Path
            d="M146,94.8c0,0-1.2,0-3.5,1.3c-2.3,1.3-3.5,4.2-2,6.1c1.5,1.9,3.8,0.7,3.8,0.7l6.2,0.1c0,0,2.7-0.4,2.7-2.7 c0-2.2-0.8-5.4-0.8-5.4L146,94.8z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              d="M154,93.3c0.8-2.7,0.2-3.7,0.2-3.7c-1.9,0-3,1.2-3,1.2c-1.3-0.5-2.8,0-2.8,0s-1.1-1.1-3-1.2 c0,0-0.6,1,0.2,3.7c0,0-1.1,2.7,1.5,3.7c2.6,1,4.4,0.4,5.6-0.1c1.2-0.4,2.1-1.8,1.6-3.1L154,93.3z"
              fill="#FFFFFF"
            />
            <Path
              d="M149.7,97.9c-0.8,0-1.7-0.1-2.8-0.6c-0.9-0.3-1.4-0.9-1.7-1.6c-0.4-1-0.1-2.1,0-2.5 c-0.7-2.7-0.2-3.7-0.1-3.8c0.1-0.1,0.2-0.2,0.3-0.2c1.6,0,2.7,0.8,3.1,1.1c0.4-0.1,1.6-0.3,2.7,0c0.4-0.3,1.5-1.1,3.1-1.1 c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.6,1.2-0.1,3.8l0.2,0.5c0.5,1.4-0.4,3-1.8,3.5l-0.1,0C151.9,97.6,150.9,97.9,149.7,97.9z M145.5,90c-0.1,0.4-0.3,1.4,0.3,3.2c0,0.1,0,0.2,0,0.2c0,0-0.4,1.1,0,2.1c0.2,0.5,0.7,0.9,1.4,1.2c2.5,1,4.1,0.4,5.3,0l0.1,0 c1-0.4,1.8-1.5,1.4-2.7l-0.2-0.5c0-0.1,0-0.1,0-0.2c0.5-1.9,0.4-2.9,0.3-3.2c-1.6,0.1-2.5,1.1-2.5,1.1c-0.1,0.1-0.2,0.1-0.4,0.1 c-1.2-0.4-2.6,0-2.6,0c-0.1,0-0.3,0-0.3-0.1C148.1,91,147.1,90.1,145.5,90z"
              fill="#010101"
            />
          </G>
          <Path
            d="M145.5,93.6C145.5,93.6,145.5,93.6,145.5,93.6c-0.2-0.1-0.3-0.2-0.2-0.3c0.4-0.9,1.2-1.5,1.5-1.8 c-0.6-0.8-1.5-1.5-1.5-1.5c-0.1-0.1-0.1-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3,0c0,0,1.1,0.9,1.8,1.8c0,0.1,0,0.1,0,0.2 c0,0.1-0.1,0.1-0.1,0.1c0,0-1,0.6-1.5,1.7C145.7,93.5,145.6,93.6,145.5,93.6z"
            fill="#010101"
          />
          <Path
            d="M153.9,93.6c-0.1,0-0.2,0-0.2-0.1c-0.5-1.1-1.5-1.7-1.5-1.7c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2 c0.6-0.9,1.7-1.8,1.8-1.8c0.1-0.1,0.2-0.1,0.3,0c0.1,0.1,0.1,0.2,0,0.3c0,0-0.9,0.7-1.5,1.5c0.4,0.2,1.1,0.8,1.5,1.8 C154.2,93.4,154.1,93.5,153.9,93.6C154,93.6,154,93.6,153.9,93.6z"
            fill="#010101"
          />
          <Path
            d="M150.2,95.5h-0.7c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.2,0.2-0.2h0.7c0.1,0,0.2,0.1,0.2,0.2 C150.4,95.4,150.3,95.5,150.2,95.5z"
            fill="#010101"
          />
          <Path
            d="M149.3,96.5C149.3,96.5,149.3,96.5,149.3,96.5c-0.6,0-0.8-0.5-0.8-0.7c0-0.1,0.1-0.2,0.2-0.3 c0.1,0,0.2,0.1,0.3,0.2c0,0.1,0.1,0.3,0.4,0.3c0,0,0,0,0,0c0.3,0,0.3-0.6,0.3-0.7c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2 C150,95.8,149.9,96.5,149.3,96.5z"
            fill="#010101"
          />
          <Path
            d="M150.3,96.5c-0.6,0-0.8-0.7-0.8-1.1c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0.7,0.3,0.7 c0,0,0,0,0,0c0.3,0,0.3-0.2,0.4-0.3c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.2,0.1,0.2,0.2C151.1,96,150.9,96.4,150.3,96.5 C150.3,96.5,150.3,96.5,150.3,96.5z"
            fill="#010101"
          />
          <Circle cx="147.6" cy="94.3" fill="#010101" r="0.3" />
          <Circle cx="151.9" cy="94.2" fill="#010101" r="0.3" />
          <Path
            d="M142.9,103.5C142.9,103.5,142.8,103.5,142.9,103.5c-1.4,0-2.4-0.5-3-1.2c-0.6-0.7-0.7-1.7-0.3-2.9 c1.1-4.1,5.4-4.8,5.6-4.8c0.2,0,0.4,0.1,0.4,0.3c0,0.2-0.1,0.4-0.3,0.4c0,0-4,0.7-5,4.3c-0.3,1-0.2,1.8,0.2,2.3 c0.4,0.6,1.3,0.9,2.4,1c0.9,0,1.6-0.3,2.6-1.1l0.3-0.3c0.1-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.3-0.1-0.5,0 c-1.3,1.2-2.7,1.3-3.5,0.9c-0.9-0.4-1.1-1.2-1-1.6c0-0.2,0.2-0.3,0.4-0.3c0.2,0,0.3,0.2,0.3,0.4c0,0.1,0,0.7,0.6,0.9 c0.6,0.3,1.7,0.2,2.8-0.8c0.4-0.4,1-0.4,1.4,0c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7l-0.3,0.3 C144.9,103,144.1,103.5,142.9,103.5z"
            fill="#010101"
          />
          <Path
            d="M149.2,103.3c-2.2,0-4.9,0-4.9,0l0-0.7c0,0,2.7,0,4.9,0c0.5,0,0.9,0,1.3,0c0.6,0,1-0.5,1-1 c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3c-0.9,0-1.7,0-2.2-0.1c-0.2,0-0.3-0.2-0.3-0.4c0-0.2,0.2-0.3,0.4-0.3 c0.5,0.1,1.2,0.1,2.1,0.1c0.5,0,0.9,0.2,1.2,0.5c0.3,0.3,0.5,0.8,0.5,1.2c0,0.9-0.7,1.7-1.7,1.7 C150.1,103.3,149.7,103.3,149.2,103.3z"
            fill="#010101"
          />
          <Path
            d="M151,103.2c-0.2,0-0.3-0.1-0.3-0.3c0-0.2,0.1-0.4,0.3-0.4c0.6-0.1,1-0.3,1.3-0.8c1.1-1.5,0.3-4.7,0.3-4.7 c0-0.2,0.1-0.4,0.3-0.4c0.2,0,0.4,0.1,0.4,0.3c0,0.1,0.8,3.5-0.4,5.3C152.4,102.7,151.8,103.1,151,103.2 C151,103.2,151,103.2,151,103.2z"
            fill="#010101"
          />
        </G>
        <Path
          d="M52.7,112.9h-2.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2.4c0.2,0,0.4,0.2,0.4,0.4 C53.1,112.7,52.9,112.9,52.7,112.9z"
          fill="#010101"
        />
        <Path
          d="M245.4,112.5c0,0.2-0.2,0.4-0.4,0.4H54.8c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h190.2 C245.3,112.1,245.4,112.3,245.4,112.5z"
          fill="#010101"
        />
        <G>
          <Path
            d="M250.3,112.8h-2.4c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4h2.4c0.2,0,0.4,0.2,0.4,0.4 C250.6,112.6,250.5,112.8,250.3,112.8z"
            fill="#010101"
          />
        </G>
      </G>
    </Svg>
  );
}
