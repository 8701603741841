import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polygon,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
const PayoffHouse = (props) => {
  const { colors: { primary, svgHighlight } } = useTheme();

  return (
    <Svg fill="#000" height={moderateScale(120)} viewBox="0 0 287 120" width={moderateScale(287)} {...props}>
      <Path fill="#E6EBEF" d="M250.43,33.68a2.52,2.52,0,0,0,1.86-4.15C250,26.78,245.35,24.9,240,24.9a21,21,0,0,0-2.54.15,1.54,1.54,0,0,1-1.69-1.56v0c0-4.75-5.88-8.6-13.14-8.6a19.11,19.11,0,0,0-5.64.83,1.46,1.46,0,0,1-1.85-1.07c-1.52-7.06-8.92-12.41-17.84-12.41-10,0-18.16,6.76-18.16,15.1,0,.3,0,.59.06.88a9.08,9.08,0,0,0-1.28-.11,8.44,8.44,0,0,0-8,6,1.48,1.48,0,0,1-1.81,1,8.23,8.23,0,0,0-2.29-.33A8.33,8.33,0,0,0,159.24,28,3.53,3.53,0,0,0,162,33.68Z" />
      <Path fill="#EFF3F6" d="M121.52,49.66h0c-3.83-23.17-9.22-22.13-9.22-22.13v0s-5.16-1.06-9,22.11h0a14.27,14.27,0,0,0-.18,2.19c0,6.25,4.16,11.32,9.29,11.32s9.28-5.07,9.28-11.32A14.28,14.28,0,0,0,121.52,49.66Z" />
      <Path fill="#DCE2E7" d="M121.69,51.85a14.28,14.28,0,0,0-.17-2.19h0c-3.83-23.17-9.22-22.13-9.22-22.13v0a1.49,1.49,0,0,0-.88.2c1.37,2.79,5.7,12.23,6.13,21,.39,8.07-2.28,12.24-5.2,14.38h.08C117.54,63.17,121.69,58.1,121.69,51.85Z" />
      <Path d="M112.41,63.55c-5.33,0-9.66-5.25-9.66-11.7a14,14,0,0,1,.16-2.13.24.24,0,0,1,0-.12c2.55-15.39,5.68-20.41,7.87-21.91a2.37,2.37,0,0,1,1.43-.52h0a2.45,2.45,0,0,1,1.7.56c2.24,1.56,5.44,6.62,8,21.88a.17.17,0,0,1,0,.07,14.66,14.66,0,0,1,.17,2.18C122.07,58.3,117.73,63.55,112.41,63.55Zm-8.74-13.94a.4.4,0,0,1,0,.11,14,14,0,0,0-.17,2.13c0,6,4,10.95,8.91,10.95s8.91-4.91,8.91-10.95a14,14,0,0,0-.17-2.13.17.17,0,0,1,0-.07c-2.61-15.73-5.91-20.11-7.64-21.31a2.12,2.12,0,0,0-1-.44.32.32,0,0,1-.16,0h0l-.17,0C111.51,28,107.09,29,103.67,49.61Z" />
      <Path d="M112.29,74.14a.38.38,0,0,1-.37-.37v-22a.38.38,0,1,1,.75,0v22A.38.38,0,0,1,112.29,74.14Z" />
      <Path d="M112.19,56.76a.38.38,0,0,1-.22-.07l-3.38-2.41a.37.37,0,1,1,.43-.61l3.39,2.41a.38.38,0,0,1,.09.53A.39.39,0,0,1,112.19,56.76Z" />
      <Path d="M112.38,59.59a.37.37,0,0,1-.31-.15.38.38,0,0,1,.09-.53l3.39-2.41a.37.37,0,1,1,.43.61l-3.39,2.41A.33.33,0,0,1,112.38,59.59Z" />
      <Path d="M106.2,44.17h0a.25.25,0,0,1-.2-.29,46.55,46.55,0,0,1,2.42-8.76.25.25,0,0,1,.32-.14.25.25,0,0,1,.15.32,46.42,46.42,0,0,0-2.4,8.66A.26.26,0,0,1,106.2,44.17Z" />
      <Path d="M109.11,34.18a.15.15,0,0,1-.09,0,.24.24,0,0,1-.14-.32c.59-1.48,1-2.42,1-2.42a.25.25,0,0,1,.34-.12.25.25,0,0,1,.11.33s-.44.94-1,2.4A.25.25,0,0,1,109.11,34.18Z" />
      <Path fill="#E6EBEF" d="M94.72,53.73a4,4,0,0,0,3.71-5.52c-2.25-5.58-7-13.53-16.07-14.47a15.62,15.62,0,0,0-10.15,3,.66.66,0,0,1-.8-.07,11.4,11.4,0,0,0-7.78-2.91c-5.81,0-10.53,4-10.53,8.87h0a.65.65,0,0,1-.7.65,8.12,8.12,0,0,0-.86,0c-3.57,0-6.6,2-7.67,4.65a.63.63,0,0,1-.85.35,7.49,7.49,0,0,0-3.17-.65,6.66,6.66,0,0,0-5.36,3,2,2,0,0,0,1.6,3.16Z" />
      <Path fill="#FFF" d="M200.29,25.38a.28.28,0,0,0-.36-.29c-1.13.48-4.51,1.86-7.39,1.56-3.48-.36-4.44-2-7.83-2.34-3.65-.37-7.38,1.19-8.3,1.52a.25.25,0,0,0-.15.23V41.17a.17.17,0,0,0,.22.17c1.09-.41,5.08-1.81,8.23-1.49s4.35,2,7.83,2.33c3.11.33,6.88-1.26,7.65-1.6a.24.24,0,0,0,.14-.23Z" />
      <Path d="M193.39,42.6a8.7,8.7,0,0,1-.89,0,12.73,12.73,0,0,1-4.22-1.25,11.28,11.28,0,0,0-3.61-1.09c-3.06-.32-6.94,1.05-8.06,1.47a.49.49,0,0,1-.48-.06.54.54,0,0,1-.25-.46V26.06a.62.62,0,0,1,.4-.58l.27-.1a18.9,18.9,0,0,1,8.2-1.44,11.6,11.6,0,0,1,4,1.19,11.39,11.39,0,0,0,3.85,1.15c1.32.13,3.63,0,7.21-1.53a.6.6,0,0,1,.58.06.68.68,0,0,1,.31.56l0,15a.61.61,0,0,1-.37.57A19.38,19.38,0,0,1,193.39,42.6Zm-9.66-3.17a8.5,8.5,0,0,1,1,0,11.47,11.47,0,0,1,3.83,1.15,12.34,12.34,0,0,0,4,1.18c2.84.29,6.27-1.06,7.39-1.54l0-14.77A15.78,15.78,0,0,1,192.5,27a12.13,12.13,0,0,1-4.08-1.21,10.86,10.86,0,0,0-3.75-1.12,18.13,18.13,0,0,0-7.85,1.39l-.19.07V40.89A22.79,22.79,0,0,1,183.73,39.43Zm-7.19-13.25h0Zm24.13-.8h0Z" />
      <Path fill="#E6EBEF" d="M250.79,123.73,225.21,98.15a114.61,114.61,0,0,0-162.09,0L37.54,123.73Z" />
      <Path fill="#BAC2CA" d="M162.21,65.85a115.41,115.41,0,0,0-41.79,1.42v9.56h41.79Z" />
      <Path d="M37.54,124.11a.4.4,0,0,1-.27-.11.39.39,0,0,1,0-.53L62.85,97.89a.37.37,0,0,1,.53.53L37.8,124A.38.38,0,0,1,37.54,124.11Z" />
      <Path d="M64.53,97.14a.39.39,0,0,1-.27-.11.38.38,0,0,1,0-.53q.66-.65,1.32-1.26a.37.37,0,0,1,.53,0,.38.38,0,0,1,0,.53L64.79,97A.34.34,0,0,1,64.53,97.14Z" />
      <Path d="M250.79,124.11a.38.38,0,0,1-.26-.11L225,98.42a114.12,114.12,0,0,0-157.38-4,.38.38,0,0,1-.53,0,.37.37,0,0,1,0-.53,114.85,114.85,0,0,1,158.41,4l25.58,25.58a.39.39,0,0,1,0,.53A.4.4,0,0,1,250.79,124.11Z" />
      <Path fill="#FFF" d="M158.51,77.19s-10,2.23-10,4.42c0,4,21.45,3.31,21.45,12.06,0,8.53-25.64,8.75-25.64,18.6,0,7.43,9.82,11.46,9.82,11.46H130.94s-5.58-5.34-5.58-11.46c0-12.28,33.2-14.73,33.2-19.6,0-4.6-19.09-5.93-19.09-10,0-3.82,7.52-5.5,7.52-5.5Z" />
      <Polygon fill="#FFF" points="123.3 76.83 165.08 76.83 165.08 49.77 144.3 31.43 123.3 49.77 123.3 76.83" />
      <Line fill="#FFF" x1="133.04" y1="41.6" x2="128.43" y2="41.6" />
      <Path fill="#BAC2CA" d="M121.25,52.53a1.86,1.86,0,0,0,1.25-.5l21.8-20.6L165.61,52a1.84,1.84,0,0,0,2.58-.05,1.81,1.81,0,0,0-.05-2.57L145.58,27.6a1.82,1.82,0,0,0-2.51,0L120,49.38a1.82,1.82,0,0,0,1.25,3.15Z" />
      <Path d="M121.25,52.9a2.19,2.19,0,0,1-1.51-3.79l23.07-21.79a2.19,2.19,0,0,1,3,0l10.35,10a.37.37,0,0,1-.52.54l-10.35-10a1.44,1.44,0,0,0-2,0L120.25,49.65a1.46,1.46,0,0,0-.06,2.05,1.49,1.49,0,0,0,2,.06l22.07-20.85,21.56,20.84a1.46,1.46,0,0,0,2.46-1.07,1.42,1.42,0,0,0-.45-1L156.72,38.88a.37.37,0,0,1,0-.53.38.38,0,0,1,.53,0l11.17,10.79a2.16,2.16,0,0,1,.66,1.54,2.12,2.12,0,0,1-.61,1.56,2.21,2.21,0,0,1-3.11.06L144.3,32,122.75,52.3A2.16,2.16,0,0,1,121.25,52.9Z" />
      <Rect fill={svgHighlight} x="146.77" y="52.53" width="12.11" height="24.34" />
      <Path fill="#2C2627" d="M158.89,77.09a.38.38,0,0,1-.38-.38v-24H147.15v24a.38.38,0,1,1-.75,0V52.54a.54.54,0,0,1,.53-.54h11.8a.54.54,0,0,1,.53.54V76.71A.38.38,0,0,1,158.89,77.09Z" />
      <Path fill="#2C2627" d="M170.84,77.21h-40a.38.38,0,1,1,0-.75h40a.38.38,0,1,1,0,.75Z" />
      <Path fill="#2C2627" d="M129,77.21H117.38a.38.38,0,1,1,0-.75H129a.38.38,0,0,1,0,.75Z" />
      <Path fill="#2C2627" d="M181.78,81.4h-8.7a.38.38,0,0,1,0-.75h8.7a.38.38,0,1,1,0,.75Z" />
      <Rect fill="#E6EBEF" x="130.89" y="55.19" width="8.37" height="12.87" />
      <Polygon fill="#FFF" opacity="0.7" points="134.41 68.07 130.55 68.07 136.16 55.19 140.02 55.19 134.41 68.07" />
      <Path d="M139.41,68.56h-9.33a.38.38,0,0,1-.38-.38v-2.6a.38.38,0,0,1,.75,0v2.23H139V54.76h-8.59v9.4a.38.38,0,0,1-.75,0V54.39a.38.38,0,0,1,.38-.38h9.33a.38.38,0,0,1,.38.38V68.18A.38.38,0,0,1,139.41,68.56Z" />
      <Path d="M134.92,67.25a.25.25,0,0,1-.25-.25V63.77a.25.25,0,0,1,.25-.25.25.25,0,0,1,.25.25V67A.25.25,0,0,1,134.92,67.25Z" />
      <Path d="M134.92,63a.25.25,0,0,1-.25-.25V54.39a.25.25,0,0,1,.25-.25.25.25,0,0,1,.25.25v8.33A.25.25,0,0,1,134.92,63Z" />
      <Path d="M139.4,61.8h-7.51a.25.25,0,0,1,0-.5h7.51a.25.25,0,0,1,0,.5Z" />
      <Path d="M156.19,60h-6.72v-.25a3.36,3.36,0,1,1,6.72,0Zm-6.21-.5h5.7a2.86,2.86,0,0,0-5.7,0Z" />
      <Path d="M123.3,77.21a.38.38,0,0,1-.38-.38V51.27a.38.38,0,0,1,.75,0V76.83A.38.38,0,0,1,123.3,77.21Z" />
      <Path d="M165.08,55.57a.38.38,0,0,1-.37-.38V51.81a.38.38,0,1,1,.75,0v3.38A.38.38,0,0,1,165.08,55.57Z" />
      <Path d="M165.08,77.21a.38.38,0,0,1-.37-.38V57.16a.38.38,0,1,1,.75,0V76.83A.38.38,0,0,1,165.08,77.21Z" />
      <Path fill="#2C2627" d="M176.26,81.4a.38.38,0,0,1-.38-.37V27.52a.38.38,0,0,1,.38-.37.37.37,0,0,1,.37.37V81A.37.37,0,0,1,176.26,81.4Z" />
      <Circle fill={primary} cx="188.48" cy="33.22" r="5.22" />
      <Path fillRule="evenodd" fill="#FFF" d="M191.14,31.37a.15.15,0,0,1,0,.23l-3.47,3.48a.21.21,0,0,1-.13.05.17.17,0,0,1-.12-.06l-1.6-1.73a.17.17,0,0,1,.25-.23l1.48,1.61,3.36-3.35A.15.15,0,0,1,191.14,31.37Z" />
      <Path fill="#FFF" d="M187.55,35.29h0a.32.32,0,0,1-.24-.11l-1.6-1.73a.34.34,0,0,1,.26-.56.36.36,0,0,1,.23.11l1.37,1.48,3.23-3.23a.33.33,0,0,1,.47,0h0a.33.33,0,0,1,0,.47l-3.48,3.47A.3.3,0,0,1,187.55,35.29ZM186,33.22,187.54,35l3.6-3.6-.11.12h0l-3.35,3.35a.11.11,0,0,1-.12.05.17.17,0,0,1-.12-.05Z" />
    </Svg>
  );
};

export default PayoffHouse;
