import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H2, P2 } from '../../../base_ui';

export const ContentContainer = styled.View`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  padding-horizontal: ${moderateScale(24)}px;
`;

export const DocUploadButtonWrapper = styled.View`
  align-items: center;
  margin: ${moderateScale(16)}px 0 ${moderateScale(32)}px;
`;

export const DocUploadContainer = styled.View`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const DocUploadIllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(24)}px;
`;

export const AlertWrapper = styled.View`
  margin-top: ${moderateScale(20)}px;
`;

export const FlexView = styled.View`
  flex: 1;
`;

export const StyledH2 = styled(H2)`
  font-size: ${({ theme: { fontSizes: { f7 } } }) => f7}px;
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const StyledP2 = styled(P2)`
  margin-bottom: ${moderateScale(14)}px;
  text-align: center;
`;
