/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function Piggybank(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(48)} viewBox="0 0 48 48" width={moderateScale(48)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M12.49,14.78V8.48h.92a4.83,4.83,0,0,1,4.83,4.83v1.58" fill="#bac2ca" />
      <Path d="M18.24,15.22a.33.33,0,0,1-.33-.33V13.31a4.51,4.51,0,0,0-4.5-4.5h-.59v3.08a.33.33,0,0,1-.66,0V8.48a.33.33,0,0,1,.33-.33h.92a5.17,5.17,0,0,1,5.16,5.16v1.58A.33.33,0,0,1,18.24,15.22Z" fill="#010101" />
      <Path d="M19.29,44.62H16.53a1.07,1.07,0,0,1-1.07-1.07V34.64" fill="#bac2ca" />
      <Path d="M19.29,45H16.53a1.4,1.4,0,0,1-1.4-1.4V38.33a.33.33,0,1,1,.66,0v5.22a.74.74,0,0,0,.74.74h2.76a.33.33,0,1,1,0,.66Z" fill="#010101" />
      <Path d="M33.52,44.62H30.76a1.07,1.07,0,0,1-1.07-1.07V34.64" fill="#bac2ca" />
      <Path d="M33.52,45H30.76a1.4,1.4,0,0,1-1.4-1.4V38.33a.33.33,0,0,1,.66,0v5.22a.74.74,0,0,0,.74.74h2.76a.33.33,0,0,1,0,.66Z" fill="#010101" />
      <Path d="M31.7,11.17H19.36A11.9,11.9,0,0,0,7.88,20l0,0a2.79,2.79,0,0,1-2.62,1.83H5a1.28,1.28,0,0,0-1.28,1.28v4.45A1.28,1.28,0,0,0,5,28.79h.21a2.78,2.78,0,0,1,2.57,1.72,11.91,11.91,0,0,0,8.83,8.77c.11,0,.83.12.83.23v3.82a1.28,1.28,0,0,0,1.28,1.28h1.94A1.28,1.28,0,0,0,22,43.33V41.09a1.49,1.49,0,0,1,1.48-1.49h7a1.49,1.49,0,0,1,1.48,1.49v2.24a1.28,1.28,0,0,0,1.28,1.28H35a1.28,1.28,0,0,0,1.28-1.28v-3.9a.26.26,0,0,1,.18-.24c5.1-1.34,8.15-6,8.15-11.49V24.05A12.89,12.89,0,0,0,31.7,11.17Z" fill="#fff" />
      <Path d="M15.81,35.75a13.78,13.78,0,0,1-1.87-1l-.1,0a18.77,18.77,0,0,1-4.38-3.69c-.39-.38-1-.91-1.56-1.35.84,4.05,3.25,8.5,9.64,9.84L17.41,38A2.58,2.58,0,0,0,15.81,35.75Z" fill="#e7ebee" />
      <Path d="M37.51,35c2.44-1.38,7.2-5.13,6.59-13.18l-.33-1.26S48.42,34.77,36.28,39l-.08-1.59A2.58,2.58,0,0,1,37.51,35Z" fill="#e7ebee" />
      <Rect fill="#fff" height="6.98" rx="1.12" width="3.17" x="3.08" y="21.79" />
      <Circle cx="12.44" cy="19.52" fill="#010101" r="0.78" />
      <Path d="M35,44.94H33.2a1.62,1.62,0,0,1-1.62-1.61V41.09a1.15,1.15,0,0,0-1.15-1.16H27.56a.33.33,0,1,1,0-.66h2.87a1.82,1.82,0,0,1,1.81,1.82v2.24a1,1,0,0,0,1,1H35a1,1,0,0,0,.95-1v-3.9a.33.33,0,0,1,.33-.33.32.32,0,0,1,.33.33v3.9A1.61,1.61,0,0,1,35,44.94Z" fill="#010101" />
      <Path d="M20.64,44.94h-1.8a1.61,1.61,0,0,1-1.61-1.61V39.51a.33.33,0,1,1,.66,0v3.82a1,1,0,0,0,.95,1h1.8a1,1,0,0,0,.95-1V41.09a1.82,1.82,0,0,1,1.81-1.82H26a.33.33,0,0,1,0,.66H23.4a1.16,1.16,0,0,0-1.15,1.16v2.24A1.61,1.61,0,0,1,20.64,44.94Z" fill="#010101" />
      <Path d="M16.28,39.47H16.2a12,12,0,0,1-8.71-8.87,2.46,2.46,0,0,0-2.25-1.47H5a1.61,1.61,0,0,1-1.61-1.61V23.06A1.62,1.62,0,0,1,5,21.45h.21a2.45,2.45,0,0,0,2.31-1.61.31.31,0,0,1,0-.1,12.25,12.25,0,0,1,11.76-8.9H31.7a13.2,13.2,0,0,1,11,5.94.33.33,0,0,1-.1.46.32.32,0,0,1-.45-.1A12.56,12.56,0,0,0,31.7,11.5H19.36A11.59,11.59,0,0,0,8.2,20.06a.37.37,0,0,1-.09.16,3.14,3.14,0,0,1-2.87,1.9H5a1,1,0,0,0-1,.94v4.45a1,1,0,0,0,1,.95h.21a3.09,3.09,0,0,1,2.87,1.92,11.28,11.28,0,0,0,8.25,8.44.32.32,0,0,1,.24.4A.33.33,0,0,1,16.28,39.47Z" fill="#010101" />
      <Path d="M37.66,38.83a.34.34,0,0,1-.3-.19.33.33,0,0,1,.16-.44,11.61,11.61,0,0,0,6.73-10.5V24.05a12.4,12.4,0,0,0-1.38-5.74.33.33,0,1,1,.58-.31,13.24,13.24,0,0,1,1.47,6.05V27.7A12.27,12.27,0,0,1,37.8,38.8.35.35,0,0,1,37.66,38.83Z" fill="#010101" />
      <Path d="M17.54,40a.33.33,0,0,1-.33-.33V37.43a.33.33,0,1,1,.66,0v2.21A.33.33,0,0,1,17.54,40Z" fill="#010101" />
      <Path d="M36.28,40a.33.33,0,0,1-.33-.33V37.43a.33.33,0,0,1,.66,0v2.21A.33.33,0,0,1,36.28,40Z" fill="#010101" />
      <Path d="M5.19,28.89a.21.21,0,1,1,0-.41,1.14,1.14,0,0,0,1.13-1.13V23.21a1.27,1.27,0,0,0-.05-.34.2.2,0,0,1,.13-.26.21.21,0,0,1,.27.13,1.84,1.84,0,0,1,.07.47v4.14A1.54,1.54,0,0,1,5.19,28.89Z" fill="#010101" />
      <Path d="M4.64,26a.21.21,0,0,1-.21-.21V24.6a.22.22,0,0,1,.21-.21.21.21,0,0,1,.21.21v1.23A.21.21,0,0,1,4.64,26Z" fill="#010101" />
      <Path d="M5.55,26a.21.21,0,0,1-.21-.21V24.6a.21.21,0,1,1,.41,0v1.23A.2.2,0,0,1,5.55,26Z" fill="#010101" />
      <Path d="M15.22,13.77V7.48h.92A4.83,4.83,0,0,1,21,12.31v1.57" fill="#fff" />
      <Path d="M21,14.21a.33.33,0,0,1-.33-.33V12.31a4.51,4.51,0,0,0-4.5-4.5h-.59v6a.32.32,0,0,1-.33.33.33.33,0,0,1-.33-.33V7.48a.33.33,0,0,1,.33-.33h.92a5.16,5.16,0,0,1,5.16,5.16v1.57A.32.32,0,0,1,21,14.21Z" fill="#010101" />
      <Rect fill="#9b88b5" height="1.27" rx="0.45" width="8.97" x="23.61" y="13.27" />
      <Path d="M32,14.75H24.21a.81.81,0,0,1-.81-.81v-.07a.81.81,0,0,1,.81-.81H32a.81.81,0,0,1,.81.81v.07A.81.81,0,0,1,32,14.75Zm-7.77-1.27a.39.39,0,0,0-.39.39v.07a.39.39,0,0,0,.39.39H32a.39.39,0,0,0,.39-.39v-.07a.39.39,0,0,0-.39-.39Z" fill="#010101" />
      <Circle cx="28.02" cy="7.7" fill={svgHighlight} r="4.32" />
      <Path d="M28,12.35A4.65,4.65,0,0,1,23.37,7.7a.33.33,0,0,1,.66,0,4,4,0,1,0,.21-1.26.33.33,0,0,1-.63-.21A4.65,4.65,0,1,1,28,12.35Z" fill="#010101" />
    </Svg>
  );
}
