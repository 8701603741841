/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import T from 'prop-types';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function CheckmarkIcon(props) {
  const { colors } = useTheme();
  const { themeColorKey, ...restProps } = props;
  const fillColor = colors[themeColorKey || 'primary'];

  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...restProps}>
      <Path d="M12.0002 23.7999C18.5172 23.7999 23.8002 18.5169 23.8002 12C23.8002 5.48299 18.5172 0.199951 12.0002 0.199951C5.48324 0.199951 0.200195 5.48299 0.200195 12C0.200195 18.5169 5.48324 23.7999 12.0002 23.7999Z" fill={fillColor} />
      <Path d="M9.89941 16.7999C9.59941 16.7999 9.39941 16.6999 9.19941 16.4999L5.49941 12.5999C5.19941 12.1999 5.19941 11.6999 5.49941 11.2999C5.99941 10.9999 6.59941 10.9999 6.89941 11.3999L9.89941 14.5999L16.9994 7.29995C17.3994 6.99995 17.9994 6.99995 18.2994 7.29995C18.6994 7.59995 18.6994 8.19995 18.2994 8.59995L10.4994 16.4999C10.3994 16.6999 10.1994 16.7999 9.89941 16.7999Z" fill="white" />
    </Svg>
  );
}

CheckmarkIcon.propTypes = { themeColorKey: T.string };
