import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../../../base_ui';
import ListItem from './ListItem';
import { ListContainer } from './styledComponents';

/* eslint-disable react/no-array-index-key */
const List = ({ handleNavigateLink, isOrderedList, listItems }) => (
  <ListContainer>
    {listItems.map(({ inlineBlocks, type }, i) => (
      <ConditionalRender
        key={i}
        Component={(
          <ListItem
            handleNavigateLink={handleNavigateLink}
            inlineBlocks={inlineBlocks}
            itemNumber={isOrderedList ? i + 1 : null}
          />
        )}
        shouldRender={type === 'list-item'}
      />
    ))}
  </ListContainer>
);

List.propTypes = {
  handleNavigateLink: T.func.isRequired,
  isOrderedList: T.bool.isRequired,
  listItems: T.array.isRequired,
};

export default List;
