import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';

import { Piggy } from '../../../../utils/svg/Illustrations';
import { AlertBanner, LargeProgressButton, StyledScrollView } from '../../../base_ui';
import {
  AddEscrowButtonWrapper,
  AddEscrowContainer,
  AddEscrowHeaderContainer,
  AddEscrowIllustrationWrapper,
  AlertWrapper,
  StyledH2,
} from '../styledComponents';
import SetUpOptions from './SetUpOptions';

const SetUpEscrow = ({
  addEscrowOption,
  setAddEscrowOption,
  submitPressHandler,
}) => {
  const {
    buttonText,
    notification,
    options,
    secondaryHeader,
  } = ADD_ESCROW_DICT.setUpOptions;

  const optionPressHandler = (option) => {
    setAddEscrowOption(option);
  };

  return (
    <StyledScrollView>
      <AddEscrowContainer $isDocView>
        <View>
          <AddEscrowIllustrationWrapper>
            <Piggy />
          </AddEscrowIllustrationWrapper>
          <AddEscrowHeaderContainer>
            <StyledH2>{secondaryHeader}</StyledH2>
          </AddEscrowHeaderContainer>
          <SetUpOptions
            addEscrowOption={addEscrowOption}
            optionPressHandler={optionPressHandler}
            options={options}
          />
          <AlertWrapper>
            <AlertBanner text={notification} />
          </AlertWrapper>
        </View>
        <AddEscrowButtonWrapper>
          <LargeProgressButton
            disabled={addEscrowOption === ''}
            label={buttonText}
            onPress={() => submitPressHandler(3, 'AddEscrowUploadDoc')}
          />
        </AddEscrowButtonWrapper>
      </AddEscrowContainer>
    </StyledScrollView>
  );
};

SetUpEscrow.propTypes = {
  addEscrowOption: T.string.isRequired,
  setAddEscrowOption: T.func.isRequired,
  submitPressHandler: T.func.isRequired,
};

export default SetUpEscrow;
