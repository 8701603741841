import React from 'react';

import { Skeleton } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';
import iconDictionary from '../../../../../utils/iconDictionary';
import { CardHeaderContainer } from './styledComponents';

const LoanIcon = iconDictionary('navMortgageDefaultM');

const CardHeaderSkeleton = () => (
  <CardHeaderContainer>
    {LoanIcon}
    <Skeleton
      $bottomMargin={`${moderateScale(3)}px`}
      $leftMargin={`${moderateScale(8)}px`}
      $topMargin={`${moderateScale(3)}px`}
      height={`${moderateScale(16)}px`}
      width={`${moderateScale(263)}px`}
    />
  </CardHeaderContainer>
);

export default CardHeaderSkeleton;
