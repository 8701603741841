import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  clearNewInfo,
  resetBannerErrors,
  resetState as resetSources,
  setInput,
  setInputError,
  setUseExisting,
  setValidateRoutingError,
  validateRouting,
} from '@dmi/shared/redux/Payments/Sources/actions';

import makeSelectPaymentSources, {
  makeSelectFormValues,
  makeSelectIsExistingInfo,
  makeSelectIsNewInfo,
  makeSelectPaymentSourcesNotifications,
  makeSelectUseAccountInfoExistingByPaymentType,
} from '@dmi/shared/redux/Payments/Sources/selectors';

import SourceFormContainer from '../../../components/Payments/Sources/FormContainer';

const EditPaymentSource = ({
  bannerError,
  dispatchClearNewInfo,
  dispatchResetBannerErrors,
  dispatchResetSources,
  dispatchSetInput,
  dispatchSetInputError,
  dispatchSetUseExisting,
  dispatchSetValidateRoutingError,
  dispatchValidateRouting,
  formState,
  isExistingInfo,
  isNewInfo,
  navigation,
  notifications,
  useExistingInfo,
  useNewInfo,
  validateRoutingError,
  validateRoutingLoading,
  validateRoutingSuccess,
}) => (
  <SourceFormContainer
    bannerError={bannerError}
    dispatchClearNewInfo={dispatchClearNewInfo}
    dispatchResetBannerErrors={dispatchResetBannerErrors}
    dispatchResetSources={dispatchResetSources}
    dispatchSetInput={dispatchSetInput}
    dispatchSetInputError={dispatchSetInputError}
    dispatchSetUseExisting={dispatchSetUseExisting}
    dispatchSetValidateRoutingError={dispatchSetValidateRoutingError}
    dispatchValidateRouting={dispatchValidateRouting}
    formState={formState}
    isExistingInfo={isExistingInfo}
    isNewInfo={isNewInfo}
    isOtp
    navigation={navigation}
    notifications={notifications}
    useExistingInfo={useExistingInfo}
    useNewInfo={useNewInfo}
    validateRoutingError={validateRoutingError}
    validateRoutingLoading={validateRoutingLoading}
    validateRoutingSuccess={validateRoutingSuccess}
  />
);

EditPaymentSource.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchClearNewInfo: T.func.isRequired,
  dispatchResetBannerErrors: T.func.isRequired,
  dispatchResetSources: T.func.isRequired,
  dispatchSetInput: T.func.isRequired,
  dispatchSetInputError: T.func.isRequired,
  dispatchSetUseExisting: T.func.isRequired,
  dispatchSetValidateRoutingError: T.func.isRequired,
  dispatchValidateRouting: T.func.isRequired,
  formState: T.object.isRequired,
  isExistingInfo: T.bool.isRequired,
  isNewInfo: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  useExistingInfo: T.bool.isRequired,
  useNewInfo: T.bool.isRequired,
  validateRoutingError: T.oneOfType([T.bool, T.string]).isRequired,
  validateRoutingLoading: T.bool.isRequired,
  validateRoutingSuccess: T.bool.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Payment Sources
   */
  bannerError: makeSelectPaymentSources('bannerError'),
  formState: makeSelectFormValues('otp'),
  isExistingInfo: makeSelectIsExistingInfo('otp'),
  isNewInfo: makeSelectIsNewInfo(),
  notifications: makeSelectPaymentSourcesNotifications(),
  useExistingInfo: makeSelectUseAccountInfoExistingByPaymentType('otp'),
  useNewInfo: makeSelectPaymentSources('useAccountInfoNew'),
  validateRoutingError: makeSelectPaymentSources('validateRoutingError'),
  validateRoutingLoading: makeSelectPaymentSources('validateRoutingLoading'),
  validateRoutingSuccess: makeSelectPaymentSources('validateRoutingSuccess'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Payment Sources
   */
  dispatchClearNewInfo: () => dispatch(clearNewInfo()),
  dispatchResetBannerErrors: () => dispatch(resetBannerErrors()),
  dispatchResetSources: () => dispatch(resetSources()),
  dispatchSetInput: (payload) => dispatch(setInput(payload)),
  dispatchSetInputError: (payload) => dispatch(setInputError(payload)),
  dispatchSetUseExisting: (payload) => dispatch(setUseExisting(payload)),
  dispatchSetValidateRoutingError: (payload) => dispatch(setValidateRoutingError(payload)),
  dispatchValidateRouting: (payload) => dispatch(validateRouting(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EditPaymentSource);
