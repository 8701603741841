import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { resetHelocDrawState } from '@dmi/shared/redux/Mortgage/actions';
import { HELOC_DRAW_PROPS_DICTIONARY } from '@dmi/shared/redux/Mortgage/constants';
import { selectHelocDrawConfirmationProps } from '@dmi/shared/redux/Mortgage/selectors';

import { ConditionalRender } from '../../../components/base_ui';
import {
  HelocConfirmation as HelocConfirmationScreen,
  HelocError,
} from '../../../components/Mortgage/HelocDraw';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { StyledView } from '../styledComponents';

export const HelocConfirmation = ({
  dispatchResetHelocDraw,
  helocDrawConfirmationProps,
  navigation,
}) => {
  const { error: { pageHeading } } = HELOC_DRAW_PROPS_DICTIONARY;
  const {
    confirmationConstants: { header },
    confirmationNumber,
  } = helocDrawConfirmationProps;

  useEffect(() => () => {
    dispatchResetHelocDraw();
  }, [dispatchResetHelocDraw]);

  return (
    <StyledView>
      <SecondaryHeader
        {...confirmationNumber
          ? { handleDone: () => navigation.navigate('Mortgage'), title: header }
          : { title: pageHeading }
        }
      />
      <ConditionalRender
        Component={HelocConfirmationScreen}
        FallbackComponent={HelocError}
        propsToPassDown={{ navigation, ...helocDrawConfirmationProps }}
        shouldRender={!!confirmationNumber}
      />
    </StyledView>
  );
};

HelocConfirmation.propTypes = {
  dispatchResetHelocDraw: T.func.isRequired,
  helocDrawConfirmationProps: T.object.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  helocDrawConfirmationProps: selectHelocDrawConfirmationProps(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Mortgage
   */
  dispatchResetHelocDraw: () => dispatch(resetHelocDrawState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(HelocConfirmation);
