import React, { Fragment } from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { ConditionalRender, H5 } from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  IconWrapper,
  PressableRow,
  StyledScrollView,
} from './styledComponents';

const CheckedIcon = iconDictionary('approved');
const UncheckedIcon = iconDictionary('unapproved');

const ClaimSelectComponent = ({
  dispatchChangeSelectedClaimNumber,
  dispatchChangeStep,
  dropdownClaimNumberItems,
  dropdownDefaultClaimNumber,
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={() => dispatchChangeStep(1)}
      title="Select Claim"
    />
    <StyledScrollView showsVerticalScrollIndicator={false}>
      <View>
        {dropdownClaimNumberItems.map(({ display, value }) => (
          <PressableRow
            key={value}
            aria-label={`Select claim ${value}`}
            onPress={() => {
              dispatchChangeSelectedClaimNumber({ claimNumber: value });
              setTimeout(() => {
                dispatchChangeStep(1);
              }, 1000);
            }}
            role="button"
          >
            <IconWrapper>
              <ConditionalRender
                Component={CheckedIcon}
                FallbackComponent={UncheckedIcon}
                shouldRender={dropdownDefaultClaimNumber === value}
              />
            </IconWrapper>
            <H5>{display}</H5>
          </PressableRow>
        ))}
      </View>
    </StyledScrollView>
  </Fragment>
);

ClaimSelectComponent.propTypes = {
  dispatchChangeSelectedClaimNumber: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dropdownClaimNumberItems: T.arrayOf(T.shape({
    display: T.string.isRequired,
    value: T.string.isRequired,
  })).isRequired,
  dropdownDefaultClaimNumber: T.string.isRequired,
};

export default ClaimSelectComponent;
