/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  Line,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

export default function HardshipWarning() {
  return (
    <Svg id="Layer_1" data-name="Layer 1" height={moderateScale(85)} width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 85 85">
      <Path className="cls-1" fill="#fff" strokeMiterlimit="10" strokeWidth="1.09" stroke="#000" d="M17.67,47.11,6.32,64.33A4.4,4.4,0,0,0,10,71.15H75a4.4,4.4,0,0,0,3.71-6.77l-29.36-46a4.37,4.37,0,0,0-3.58-2H42.34l-1.08,0c-1.85-.07-3.57.32-4.64,1.94L19,45.09" />
      <Path className="cls-2" fill="#bac2ca" d="M49.35,18.41a4.37,4.37,0,0,0-3.58-2H42.09a15.11,15.11,0,0,1,2.38,2.71L72.7,63.21s3.66,5.17-1.62,7.94H75a4.4,4.4,0,0,0,3.71-6.77Z" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M70.28,59.55l3.09,4.83a4.4,4.4,0,0,1-1.85,6.36" />
      <Line className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" x1="44" y1="18.41" x2="68.65" y2="56.99" />
      <Rect className="cls-4" fill="#d5dde4" x="36.24" y="58.24" width="8.87" height="8.87" rx="4.08" />
      <Path className="cls-4" fill="#d5dde4" d="M41.62,55.64H39.74a1.9,1.9,0,0,1-1.9-1.75L36.19,32.62a4.38,4.38,0,0,1,4.36-4.72h.25a4.39,4.39,0,0,1,4.37,4.72L43.51,53.89A1.89,1.89,0,0,1,41.62,55.64Z" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M45,63.88a4.76,4.76,0,0,0,.16-1.2h0a4.43,4.43,0,0,0-4.43-4.44h0a4.44,4.44,0,0,0-4.44,4.44h0a4.43,4.43,0,0,0,4.44,4.43h0a4.4,4.4,0,0,0,3.22-1.39" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M41.62,55.64H39.74a1.9,1.9,0,0,1-1.9-1.75L36.19,32.62a4.38,4.38,0,0,1,4.36-4.72h.25a4.39,4.39,0,0,1,4.37,4.72L43.51,53.89A1.89,1.89,0,0,1,41.62,55.64Z" />
      <Line className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" x1="42.59" y1="10.44" x2="42.59" y2="3.57" />
      <Line className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" x1="35.61" y1="12.99" x2="33.35" y2="10.47" />
      <Line className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" x1="49.56" y1="12.99" x2="51.83" y2="10.47" />
      <Line className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" x1="15.98" y1="54.7" x2="27.97" y2="36.09" />
      <Line className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" x1="13.84" y1="58.02" x2="14.83" y2="56.48" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M13.28,34.77A2.25,2.25,0,1,0,12,36.12" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M54.08,78.89a1.48,1.48,0,0,0-.46,1.44,1.45,1.45,0,0,0,2.81-.69,1.46,1.46,0,0,0-.87-1" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M74.44,20.29a2.83,2.83,0,0,0-5.58.32" />
      <Path className="cls-3" stroke="#000" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.81" d="M69.54,22.77a2.83,2.83,0,0,0,4.71-.68" />
    </Svg>
  );
}
