import styled, { css } from 'styled-components/native';
import { Platform } from 'react-native';

import {
  BorderlessButton,
  H3,
  LargePrimaryButton,
  MultilineTextField,
  P2,
  PrimaryDivider,
  StatusBanner,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

const topBorder = css`
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-top-width: ${moderateScale(1)}px;
`;

export const AddAttachmentContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const AlertContainer = styled.View`
  margin: ${moderateScale(24)}px;
`;

export const AttachmentContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-bottom: ${moderateScale(18)}px;
`;

export const AttachmentIcon = styled.View`
  margin-right: ${moderateScale(5)}px;
  width: ${moderateScale(16)}px;
`;

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ReplyBoxButtonContainer = styled.View`
  ${topBorder}
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(10)}px ${moderateScale(24)}px;
`;

export const ReplyBoxContainer = styled.View`
  ${topBorder}
  padding-top: ${moderateScale(15)}px;
`;

export const ReplyTitle = styled(H3)`
  margin-left: ${moderateScale(8)}px;
`;

export const StyledBody = styled(P2)`
  padding-bottom: ${moderateScale(8)}px;
`;

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({
  theme: {
    colors: { linkText },
    fontSizes: { f4 },
  },
}) => ({
  titleStyle: [{
    color: linkText,
    fontSize: f4,
  }],
}))`
  padding-right: ${moderateScale(20)}px;
  ${Platform.OS === 'web' ? `
    width: fit-content;
    word-break: break-all;
    `
    : ''}
`;

export const StyledButtonView = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  justify-content: center;
  padding: ${moderateScale(16)}px ${moderateScale(57)}px ${moderateScale(35)}px;
`;

export const StyledLargePrimaryButton = styled(LargePrimaryButton)`
  margin: ${moderateScale(24)}px auto ${moderateScale(36)}px;
  width: 70%;
`;

export const StyledMessageHeader = styled.View`
  flex-direction: row;
  justify-content: space-between;
  padding-top: ${moderateScale(14)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  align-items: center;
  justify-content: center;
  max-width: 100%;
  width: 100%;
`;

export const StyledReplyView = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-direction: row;
  margin-bottom: ${moderateScale(8)}px;
  padding: 0 ${moderateScale(24)}px;
`;

export const StyledStatusBanner = styled(StatusBanner)`
  margin: ${moderateScale(8)}px ${moderateScale(24)}px;
`;

export const StyledTextInput = styled(MultilineTextField)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  height: ${moderateScale(150)}px;
  margin: 0;
  overflow: scroll;
  padding: 0 ${moderateScale(24)}px;
`;

export const StyledTitle = styled(H3)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  margin-bottom: ${moderateScale(8)}px; 
  padding: 0 ${moderateScale(24)}px;
`;
