import { moderateScale } from '../../../../utils/scaleHelpers';

/*
  Input Constants to manually set the height of the TextField/Input. These get
  utilized in StyledLoginRegisterInput's inputStyle attr prop.
*/
export const PADDING_TOP = moderateScale(21.5);
export const PADDING_BOTTOM = moderateScale(7.5);
export const INPUT_LINE_HEIGHT = moderateScale(22);
export const BASE_HEIGHT = PADDING_TOP + PADDING_BOTTOM + INPUT_LINE_HEIGHT;

/*
  Label Constants used in StyledAnimatedText and in the Animation Constants calculations.
  LABEL_LINE_HEIGHT should be the same as the grown label's font size.
*/
export const LABEL_LINE_HEIGHT = moderateScale(16);

/*
  Animation Constants
*/
export const INITIAL_TRANSLATE_X = 0;
// shrunkLabelFontSize = (grownLabelFontSize * SCALE_FACTOR_SHRUNK) = ~moderateScale(10)
export const SCALE_FACTOR_SHRUNK = (10 / 16);
export const SCALE_FACTOR_GROWN = 1;
export const TRANSITION_DURATION = 200;
export const BOLD_TIMING = TRANSITION_DURATION / 2;
export const TRANSLATE_Y_SHRUNK = moderateScale(6);
/*
  TransformOriginY is 'center', so the label's translateY starting point is the center of its
  own content height (determined by its lineHeight), and its target is the center of the
  input's content height (determined by its lineHeight).
*/
export const TRANSLATE_Y_GROWN = PADDING_TOP + (INPUT_LINE_HEIGHT / 2) - (LABEL_LINE_HEIGHT / 2);
