import React from 'react';

import { INTEREST_STATEMENT_BANNER_COPY } from '../../../../../shared/redux/Login/constants';
import iconDictionary from '../../../utils/iconDictionary';
import { P2 } from '../Paragraphs';
import {
  AlertBannerContainer,
  AlertHeading,
  AlertTextContainer,
} from './styledComponents';

const WarningIcon = iconDictionary('warning', { height: 48, width: 48 });
const { heading, text } = INTEREST_STATEMENT_BANNER_COPY;

const InterestStatementBanner = () => (
  <AlertBannerContainer>
    {WarningIcon}
    <AlertTextContainer>
      <AlertHeading>{heading}</AlertHeading>
      <P2>{text}</P2>
    </AlertTextContainer>
  </AlertBannerContainer>
);

export default InterestStatementBanner;
