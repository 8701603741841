import React from 'react';
import T from 'prop-types';

import {
  Circle,
  Container,
  Row,
  Text,
} from './styledComponents';

const Legend = ({ dueDateStr }) => (
  <Container>
    <Row>
      <Circle />
      <Text>{`Payment due date is ${dueDateStr}`}</Text>
    </Row>
    <Row>
      <Circle $filled />
      <Text>Payment will be scheduled for selected date</Text>
    </Row>
  </Container>
);

Legend.propTypes = { dueDateStr: T.string.isRequired };

export default Legend;
