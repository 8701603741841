import React, { Fragment } from 'react';
import T from 'prop-types';
import { Text, View } from 'react-native';

import iconDictionary from '../../../../utils/iconDictionary';
import {
  ConditionalRender,
  H2,
  H5,
  P4,
  SmallProgressButton,
} from '../../../base_ui';
import { Footer, StyledCheckboxWithText } from './styledComponents';
import {
  Container,
  Content,
  DisclaimerText,
  Header,
  IllustrationWrapper,
  Row,
  Subheader,
} from '../styledComponents';

const ReviewIllustration = iconDictionary('magnifyingGlass');

const HelocDrawReview = ({
  accountNumber,
  accountType,
  amount,
  dispatchSubmitHelocDraw,
  dispatchUpdateFormValue,
  formattedAmount,
  isAccountRequired,
  isHelocDrawConfirmChecked,
  loanNumber,
  maskedAccountNumber,
  nameOnAccount,
  requestDate,
  reviewConstants: {
    buttonLabel,
    cardHeader,
    cardSubheader,
    disclaimer,
    helocDrawCheckboxLabel,
  },
  routingNumber,
  status,
}) => (
  <Fragment>
    <Container>
      <Content>
        <IllustrationWrapper>{ReviewIllustration}</IllustrationWrapper>
        <Header>{cardHeader}</Header>
        <Subheader>{cardSubheader}</Subheader>
        <Row>
          <H5>Advance Amount</H5>
          <Text>{formattedAmount}</Text>
        </Row>
        <Row>
          <H5>Request Date</H5>
          <Text>{requestDate}</Text>
        </Row>
        <ConditionalRender
          Component={(
            <Row>
              <H5>Bank Account</H5>
              <Text>{maskedAccountNumber}</Text>
            </Row>
          )}
          shouldRender={isAccountRequired}
        />
        <DisclaimerText>{disclaimer}</DisclaimerText>
        <StyledCheckboxWithText
          checked={isHelocDrawConfirmChecked}
          onPress={() => {
            dispatchUpdateFormValue({
              field: 'isHelocDrawConfirmChecked',
              form: 'review',
              value: !isHelocDrawConfirmChecked,
            });
          }}
          title={helocDrawCheckboxLabel}
        />
      </Content>
    </Container>
    <Footer>
      <View>
        <H2>{formattedAmount}</H2>
        <P4>Requested {requestDate}</P4>
      </View>
      <SmallProgressButton
        disabled={!isHelocDrawConfirmChecked}
        label={buttonLabel}
        onPress={() => {
          dispatchSubmitHelocDraw({
            accountNumber,
            accountType,
            amount,
            loanNumber,
            nameOnAccount,
            requestDate: new Date(),
            routingNumber,
          });
        }}
        status={status}
      />
    </Footer>
  </Fragment>
);

HelocDrawReview.propTypes = {
  accountNumber: T.string,
  accountType: T.string,
  amount: T.number.isRequired,
  dispatchSubmitHelocDraw: T.func.isRequired,
  dispatchUpdateFormValue: T.func.isRequired,
  formattedAmount: T.string.isRequired,
  isAccountRequired: T.bool.isRequired,
  isHelocDrawConfirmChecked: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  maskedAccountNumber: T.string,
  nameOnAccount: T.string,
  requestDate: T.string.isRequired,
  reviewConstants: T.shape({
    buttonLabel: T.string.isRequired,
    cardHeader: T.string.isRequired,
    cardSubheader: T.string.isRequired,
    disclaimer: T.string.isRequired,
    helocDrawCheckboxLabel: T.string.isRequired,
  }).isRequired,
  routingNumber: T.string,
  status: T.string.isRequired,
};

export default HelocDrawReview;
