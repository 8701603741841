import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import { ConditionalRender } from '../../base_ui';
import { getProperBiometricIcon } from '../Login/helpers';
import { ButtonGroupWrapper, StyledLoginButton } from './styledComponents';

const arrowRightIcon = iconDictionary(
  'arrowRightCarbonS',
  { height: moderateScale(11), width: moderateScale(11) },
);

const ButtonGroup = ({
  clientAuthentication,
  clientName,
  handleBiometricPinLogin,
  handlePasswordLogin,
  isBiometricLoginEnabled,
  isPinEnabled,
  refreshToken,
  supportedBiometricAuthenticationTypes,
}) => {
  const biometricIcon = getProperBiometricIcon(supportedBiometricAuthenticationTypes);
  const isClientSso = clientAuthentication.grantType === 'sso_oidc';
  return (
    <ButtonGroupWrapper>
      <StyledLoginButton
        Icon={arrowRightIcon}
        iconPosition="right"
        label="Password Login"
        onPress={() => handlePasswordLogin({ ...clientAuthentication, clientName })}
      />
      <ConditionalRender
        Component={(
          <StyledLoginButton
            Icon={arrowRightIcon}
            iconPosition="right"
            label="PIN"
            onPress={() => handleBiometricPinLogin({
              ...clientAuthentication,
              clientName,
              refreshToken,
              type: 'pin',
            })}
          />
        )}
        shouldRender={isPinEnabled && !!refreshToken && isClientSso}
      />
      <ConditionalRender
        Component={(
          <StyledLoginButton
            aria-label="Login with biometrics"
            Icon={biometricIcon}
            onPress={() => handleBiometricPinLogin({
              ...clientAuthentication,
              clientName,
              refreshToken,
              type: 'biometric',
            })}
          />
        )}
        shouldRender={isBiometricLoginEnabled && !!refreshToken && isClientSso}
      />
    </ButtonGroupWrapper>
  );
};

ButtonGroup.propTypes = {
  clientAuthentication: T.shape({
    connectionInformation: T.object,
    grantType: T.string.isRequired,
  }).isRequired,
  clientName: T.string.isRequired,
  handleBiometricPinLogin: T.func.isRequired,
  handlePasswordLogin: T.func.isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  refreshToken: T.string,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
};

export default ButtonGroup;
