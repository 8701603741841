/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

const DesktopSelectedLoanSpring = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 287 120"
      x="0px"
      xmlns="http://www.w3.org/2000/Svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <G>
        <Path
          d="M91.572,45.614c1.707,0,2.864-1.727,2.226-3.31c-1.351-3.354-4.21-8.126-9.649-8.69
				c-2.29-0.237-4.419,0.669-6.093,1.786c-0.15,0.1-0.344,0.08-0.476-0.042c-1.156-1.07-2.821-1.743-4.674-1.743
				c-3.49,0-6.319,2.382-6.319,5.321l0,0.005c0,0.228-0.194,0.408-0.422,0.388c-0.169-0.015-0.341-0.023-0.515-0.023
				c-2.145,0-3.962,1.17-4.609,2.793c-0.081,0.202-0.307,0.296-0.507,0.209c-0.569-0.25-1.216-0.409-1.902-0.391
				c-1.566,0.041-2.587,0.915-3.222,1.802c-0.567,0.794-0.012,1.895,0.964,1.895L91.572,45.614z"
          fill="#DAE1E8"
        />
        <Rect fill="#E7EBEE" height="1.845" width="6.267" x="107.603" y="27.837" />
        <Path
          d="M114.377,26.005v1.709l-0.38,0.076v20.476l3.759-4.101V27.713c0,0,0.532-0.418,0.494-0.57
				s-0.19-0.987-0.19-0.987l-0.266-0.266L114.377,26.005z"
          fill="#BAC2CA"
        />
        <Path
          d="M113.947,48.56h-6.458c-0.181,0-0.328-0.147-0.328-0.328V27.801c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v20.103h5.989l3.656-3.83V27.862c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v16.343c0,0.084-0.033,0.166-0.091,0.226l-3.844,4.027
				C114.123,48.524,114.037,48.56,113.947,48.56z"
          fill="#010101"
        />
        <Path
          d="M113.93,43.206c-0.121,0-0.219-0.098-0.219-0.219V27.752c0-0.121,0.098-0.219,0.219-0.219
				c0.121,0,0.219,0.098,0.219,0.219v15.235C114.149,43.108,114.05,43.206,113.93,43.206z"
          fill="#010101"
        />
        <Path
          d="M117.685,28.009h-10.431c-0.509,0-0.923-0.414-0.923-0.923v-0.593c0-0.509,0.414-0.923,0.923-0.923h10.431
				c0.509,0,0.923,0.414,0.923,0.923v0.593C118.609,27.595,118.194,28.009,117.685,28.009z M107.254,26.226
				c-0.147,0-0.266,0.12-0.266,0.266v0.593c0,0.147,0.12,0.266,0.266,0.266h10.431c0.147,0,0.266-0.12,0.266-0.266v-0.593
				c0-0.147-0.12-0.266-0.266-0.266H107.254z"
          fill="#010101"
        />
        <Path
          d="M114.322,27.722c-0.121,0-0.219-0.098-0.219-0.219v-1.498c0-0.121,0.098-0.219,0.219-0.219
				c0.121,0,0.219,0.098,0.219,0.219v1.498C114.541,27.623,114.443,27.722,114.322,27.722z"
          fill="#010101"
        />
        <Polygon fill="#FFFFFF" points="171.769,112.093 171.769,54.439 133.744,25.363 98.802,52.865 98.802,112.176 	" />
        <Rect fill="#E7EBEE" height="56.981" width="22.153" x="170.913" y="54.996" />
        <Path
          d="M174.623,61.843h18.766v-1.666l-19.236-0.238l-3.033-2.856l-0.076,2.301l1.99,1.85
				C173.455,61.624,174.027,61.843,174.623,61.843z"
          fill="#BAC2CA"
        />
        <Path
          d="M178.368,78.328c0,0-2.798,5.709,0.914,10.905c3.711,5.196,13.303,6.109,13.303,6.109v-8.621
				L178.368,78.328z"
          fill="#BAC2CA"
        />
        <Path
          d="M192.876,112.011c-0.181,0-0.328-0.147-0.328-0.328V52.499c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v59.183C193.204,111.864,193.057,112.011,192.876,112.011z"
          fill="#010101"
        />
        <Path
          d="M198.864,60.004h-22.327v-2.855h22.327c0.788,0,1.427,0.639,1.427,1.427v0
				C200.291,59.365,199.652,60.004,198.864,60.004z"
          fill="#E7EBEE"
        />
        <Path
          d="M199.095,60.266h-24.05c-0.181,0-0.328-0.147-0.328-0.328c0-0.181,0.147-0.328,0.328-0.328h24.05
				c0.181,0,0.328,0.147,0.328,0.328C199.423,60.119,199.276,60.266,199.095,60.266z"
          fill="#010101"
        />
        <Path
          d="M198.728,60.253h-1.986c-0.181,0-0.328-0.147-0.328-0.328c0-0.181,0.147-0.328,0.328-0.328h1.986
				c0.652,0,1.183-0.475,1.183-1.06s-0.531-1.06-1.183-1.06h-1.986c-0.181,0-0.328-0.147-0.328-0.328s0.147-0.328,0.328-0.328h1.986
				c1.014,0,1.839,0.77,1.839,1.717C200.567,59.483,199.742,60.253,198.728,60.253z"
          fill="#010101"
        />
        <G>
          <Polygon fill="#BAC2CA" points="198.984,56.701 157.259,23.948 134.08,23.948 176.254,57.148 172.494,57.148 		" />
          <Path
            d="M134.662,24.58c-0.298-0.302-0.737-0.472-1.167-0.465c-0.444,0.006-0.863,0.188-1.151,0.498l-40.47,32.994
					c-0.554,0.597-0.476,1.492,0.173,2.001c0.65,0.508,1.625,0.438,2.178-0.159l39.331-31.767l40.528,31.772
					c0.305,0.308,0.723,0.465,1.143,0.465c0.371,0,0.743-0.122,1.038-0.369c0.631-0.527,0.678-1.425,0.104-2.005L134.662,24.58z"
            fill="#FFFFFF"
          />
          <Polygon
            display="none"
            fill="#FFFFFF"
            points="133.429,27.742 158.096,52.59 160.008,82.81 108.011,82.81 108.011,60.073
					103.693,60.073 		"
          />
          <Polygon fill="#BAC2CA" points="98.252,55.524 98.252,60.026 94.042,60.126 		" />
          <Path
            d="M98.242,60.305h-4.325c-0.181,0-0.328-0.147-0.328-0.328c0-0.181,0.147-0.328,0.328-0.328h4.325
					c0.181,0,0.328,0.147,0.328,0.328C98.571,60.158,98.424,60.305,98.242,60.305z"
            fill="#010101"
          />
          <Polygon fill="#BAC2CA" points="135.157,29.246 98.621,58.536 98.621,55.838 133.512,27.941 		" />
          <Path
            d="M199.365,57.477h-23.012c-0.181,0-0.328-0.147-0.328-0.328s0.147-0.328,0.328-0.328h22.065l-41.273-32.544
					h-9.76c-0.181,0-0.328-0.147-0.328-0.328s0.147-0.328,0.328-0.328h9.874c0.074,0,0.145,0.025,0.203,0.071l42.106,33.201
					c0.11,0.087,0.153,0.233,0.107,0.366C199.629,57.388,199.505,57.477,199.365,57.477z"
            fill="#010101"
          />
          <Path
            d="M145.188,24.276h-1.439c-0.181,0-0.328-0.147-0.328-0.328s0.147-0.328,0.328-0.328h1.439
					c0.181,0,0.328,0.147,0.328,0.328S145.37,24.276,145.188,24.276z"
            fill="#010101"
          />
          <Path
            d="M133.461,24.332c-0.18,0-0.327-0.146-0.328-0.326c-0.001-0.181,0.145-0.33,0.326-0.331l8.246-0.056
					c0.001,0,0.002,0,0.002,0c0.18,0,0.327,0.146,0.328,0.326c0.001,0.181-0.145,0.33-0.326,0.331l-8.246,0.056
					C133.463,24.332,133.462,24.332,133.461,24.332z"
            fill="#010101"
          />
          <Path
            d="M93.059,60.276c-0.428,0-0.861-0.134-1.214-0.41c-0.39-0.305-0.626-0.734-0.663-1.208
					c-0.037-0.467,0.123-0.919,0.452-1.274l40.503-33.025c0.315-0.344,0.82-0.564,1.353-0.573c0.539,0.002,1.035,0.196,1.391,0.547
					l41.692,32.953c0.371,0.371,0.547,0.82,0.525,1.29c-0.023,0.474-0.244,0.909-0.622,1.225c-0.731,0.612-1.961,0.556-2.625-0.118
					l-40.291-31.582L94.431,59.705C94.086,60.08,93.577,60.276,93.059,60.276z M133.521,24.443c-0.007,0-0.014,0-0.021,0
					c-0.355,0.005-0.689,0.149-0.916,0.393L92.081,57.862c-0.169,0.187-0.268,0.462-0.245,0.745c0.023,0.288,0.17,0.553,0.413,0.743
					c0.518,0.406,1.295,0.35,1.735-0.123l39.365-31.8c0.119-0.096,0.288-0.097,0.409-0.003l40.528,31.771
					c0.493,0.492,1.288,0.503,1.769,0.103c0.236-0.197,0.373-0.464,0.387-0.752c0.013-0.284-0.096-0.557-0.306-0.77l-41.677-32.938
					c-0.011-0.008-0.021-0.017-0.03-0.027C134.2,24.58,133.862,24.443,133.521,24.443z"
            fill="#010101"
          />
        </G>
        <Path
          d="M98.158,97.836c-0.678-0.111-1.374-0.169-2.083-0.169c-4.232,0-7.983,2.056-10.312,5.222
				c-0.467,0.634-1.287,0.868-2.031,0.612c-0.906-0.31-1.878-0.479-2.889-0.479c-2.813,0-5.322,1.301-6.958,3.335
				c0,0-1.783,2.336-1.926,5.391h26.292L98.158,97.836z"
          fill="#FFFFFF"
        />
        <Path
          d="M74.732,105.601c0,0,2.724-2.221,7.114-1.217c4.39,1.005,4.919-0.053,6.241-1.64s6.426-3.835,10.261-2.75
				v-2.089c0,0-4.469-0.979-8.225,1.243c-3.755,2.221-4.284,3.94-4.866,4.178c-0.582,0.238-0.82,0.37-2.618-0.132
				c-1.798-0.502-5.897,0.106-7.987,2.512"
          fill="#DCE2E7"
        />
        <Path
          d="M71.739,112.119c-0.004,0-0.008,0-0.011,0c-0.181-0.006-0.323-0.158-0.317-0.339
				c0.088-2.596,2.118-5.49,2.205-5.612c1.78-2.213,4.409-3.473,7.226-3.473c1.026,0,2.034,0.168,2.996,0.498
				c0.619,0.212,1.286,0.011,1.66-0.497c2.468-3.354,6.421-5.356,10.577-5.356c0.714,0,1.433,0.058,2.136,0.173
				c0.179,0.029,0.3,0.198,0.271,0.376c-0.029,0.179-0.199,0.3-0.377,0.271c-0.669-0.109-1.352-0.164-2.031-0.164
				c-3.947,0-7.704,1.902-10.048,5.088c-0.546,0.741-1.511,1.035-2.402,0.729c-0.893-0.306-1.829-0.462-2.783-0.462
				c-2.617,0-5.06,1.171-6.702,3.212c-0.008,0.012-1.991,2.842-2.073,5.238C72.061,111.98,71.915,112.119,71.739,112.119z"
          fill="#010101"
        />
        <Path
          d="M98.364,102.295c-0.181,0-0.328-0.147-0.328-0.328V78.405c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v23.562C98.692,102.147,98.545,102.295,98.364,102.295z"
          fill="#010101"
        />
        <Path
          d="M98.364,76.221c-0.181,0-0.328-0.147-0.328-0.328V56.366c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v19.527C98.692,76.074,98.545,76.221,98.364,76.221z"
          fill="#010101"
        />
        <Path
          d="M170.817,112.076c-0.181,0-0.328-0.147-0.328-0.328V56.937c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v54.811C171.146,111.929,170.998,112.076,170.817,112.076z"
          fill="#010101"
        />
        <Path
          d="M94.908,103.996c0,0,4.921-1.217,7.558,0.203c2.638,1.42,3.196,3.551,4.566,4.21
				c1.37,0.659,2.587,0.406,3.906-0.457c1.319-0.862,5.986-0.457,7.051,3.044c0,0-0.964-5.022-5.529-4.768
				c-4.566,0.254-2.942,2.08-5.073-0.507C105.257,103.133,101.198,99.684,94.908,103.996z"
          fill="#DCE2E7"
        />
        <Path
          d="M118.229,112.357c-0.084,0-0.167-0.031-0.23-0.095c-0.082-0.081-0.112-0.193-0.092-0.297
				c0.034-1.497-0.51-2.901-1.533-3.957c-0.984-1.015-2.29-1.574-3.678-1.574c-1.045,0-2.055,0.32-2.918,0.926
				c-0.333,0.233-0.75,0.299-1.144,0.183c-0.419-0.124-0.769-0.443-0.935-0.854c-1.073-2.651-4.032-4.433-7.363-4.433
				c-2.595,0-5.011,1.085-6.461,2.904c-0.527,0.661-1.391,0.926-2.202,0.675c-0.73-0.226-1.496-0.342-2.274-0.342
				c-0.181,0-0.328-0.147-0.328-0.328c0-0.181,0.147-0.328,0.328-0.328c0.844,0,1.675,0.125,2.469,0.371
				c0.55,0.17,1.136-0.009,1.494-0.457c1.574-1.973,4.182-3.152,6.975-3.152c3.595,0,6.798,1.947,7.972,4.843
				c0.092,0.227,0.283,0.403,0.513,0.471c0.204,0.06,0.411,0.027,0.581-0.092c0.974-0.683,2.114-1.045,3.295-1.045
				c1.567,0,3.041,0.63,4.15,1.774c1.16,1.198,1.77,2.791,1.717,4.486c-0.002,0.084-0.037,0.163-0.096,0.222
				C118.401,112.324,118.315,112.357,118.229,112.357z"
          fill="#010101"
        />
        <G>
          <Path d="M89.397,105.165c-0.39,0-0.773,0.028-1.147,0.081" fill="#FFFFFF" />
          <Path
            d="M88.25,105.575c-0.161,0-0.301-0.118-0.324-0.281c-0.026-0.179,0.098-0.346,0.278-0.372
					c0.39-0.056,0.791-0.085,1.194-0.085c0.181,0,0.328,0.147,0.328,0.328c0,0.181-0.147,0.328-0.328,0.328
					c-0.371,0-0.742,0.026-1.1,0.078C88.281,105.575,88.266,105.575,88.25,105.575z"
            fill="#010101"
          />
        </G>
        <Path
          d="M167.658,58.684h-66.801c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h66.801
				c0.121,0,0.219,0.098,0.219,0.219C167.877,58.586,167.779,58.684,167.658,58.684z"
          fill="#010101"
        />
        <Path
          d="M169.599,56.357h-18.042c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h18.042
				c0.121,0,0.219,0.098,0.219,0.219C169.818,56.258,169.72,56.357,169.599,56.357z"
          fill="#010101"
        />
        <Path
          d="M149.274,56.357h-44.762c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h44.762
				c0.121,0,0.219,0.098,0.219,0.219C149.493,56.258,149.394,56.357,149.274,56.357z"
          fill="#010101"
        />
        <Path
          d="M178.476,32.774c-1.451,0-2.232-1.703-1.286-2.805c1.593-1.857,4.787-3.124,8.468-3.124
				c0.599,0,1.184,0.035,1.751,0.101c0.623,0.072,1.164-0.429,1.163-1.056l0-0.013c0-3.208,4.058-5.808,9.063-5.808
				c1.394,0,2.713,0.203,3.892,0.564c0.552,0.169,1.147-0.162,1.272-0.726c1.052-4.766,6.156-8.385,12.31-8.385
				c6.916,0,12.523,4.568,12.523,10.202c0,0.201-0.023,0.398-0.037,0.597c0.289-0.044,0.583-0.074,0.884-0.074
				c2.586,0,4.773,1.691,5.525,4.027c0.169,0.525,0.716,0.823,1.246,0.671c0.501-0.143,1.03-0.221,1.578-0.221
				c1.842,0,3.484,0.858,4.548,2.196c1.237,1.555,0.118,3.854-1.869,3.854H178.476z"
          fill="#E7EBEE"
        />
        <Path
          d="M82.195,112.553c-0.181,0-0.329-0.147-0.329-0.328V90.423c0-0.182,0.147-0.328,0.329-0.328
				c0.181,0,0.329,0.147,0.329,0.328v21.802C82.523,112.406,82.376,112.553,82.195,112.553z"
          fill="#010101"
        />
        <Path
          d="M239.515,112.499h-2.184c-0.181,0-0.328-0.147-0.328-0.328c0-0.181,0.147-0.328,0.328-0.328h2.184
				c0.181,0,0.328,0.147,0.328,0.328C239.844,112.352,239.697,112.499,239.515,112.499z"
          fill="#010101"
        />
        <Path
          d="M235.119,112.456H44.92c-0.158,0-0.285-0.128-0.285-0.285c0-0.158,0.127-0.285,0.285-0.285h190.199
				c0.158,0,0.285,0.128,0.285,0.285C235.404,112.329,235.277,112.456,235.119,112.456z"
          fill="#010101"
        />
        <Ellipse cx="134.314" cy="44.676" fill="#F0F1F0" rx="3.596" ry="4.234" />
        <Path
          d="M133.644,49.653c-2.746,0-4.979-2.283-4.979-5.088s2.234-5.088,4.979-5.088
				c2.745,0,4.979,2.283,4.979,5.088S136.39,49.653,133.644,49.653z M133.644,40.133c-2.384,0-4.323,1.988-4.323,4.432
				c0,2.444,1.939,4.432,4.323,4.432c2.383,0,4.322-1.988,4.322-4.432C137.966,42.121,136.027,40.133,133.644,40.133z"
          fill="#010101"
        />
        <Path
          d="M134.308,49.487c-0.015,0-0.029-0.002-0.044-0.005c-2.361-0.485-4.075-2.533-4.075-4.87
				c0-1.658,0.85-3.202,2.274-4.131c0.102-0.065,0.237-0.037,0.303,0.064c0.066,0.101,0.037,0.237-0.064,0.303
				c-1.299,0.847-2.075,2.254-2.075,3.764c0,2.13,1.566,3.998,3.725,4.441c0.119,0.024,0.195,0.14,0.17,0.258
				C134.5,49.416,134.409,49.487,134.308,49.487z"
          fill="#010101"
        />
        <G>
          <Polyline fill={svgHighlight} points="142.607,102.999 142.607,75.534 157.615,75.534 157.615,102.999 		" />
          <Path
            d="M157.615,103.328c-0.181,0-0.328-0.147-0.328-0.328V75.863h-14.351v27.136
					c0,0.181-0.147,0.328-0.328,0.328s-0.328-0.147-0.328-0.328V75.535c0-0.181,0.147-0.328,0.328-0.328h15.007
					c0.181,0,0.328,0.147,0.328,0.328v27.465C157.943,103.18,157.796,103.328,157.615,103.328z"
            fill="#010101"
          />
        </G>
        <Path
          d="M157.119,112.191h-23.353c-0.181,0-0.328-0.147-0.328-0.328v-4.47c0-0.181,0.147-0.328,0.328-0.328h3.915
				v-4.052c0-0.181,0.147-0.328,0.328-0.328h19.109c0.181,0,0.328,0.147,0.328,0.328v8.85
				C157.448,112.043,157.3,112.191,157.119,112.191z M134.094,111.534h22.697v-8.193h-18.453v4.052c0,0.181-0.147,0.328-0.328,0.328
				h-3.915V111.534z"
          fill="#010101"
        />
        <G>
          <Polygon
            fill="#BAC2CA"
            points="154.931,103.012 154.931,107.393 150.687,107.393 150.687,111.862 154.931,111.862
					155.418,111.862 160.28,111.862 160.28,103.012 		"
          />
          <Path
            d="M160.28,112.191h-9.593c-0.181,0-0.328-0.147-0.328-0.328v-4.47c0-0.181,0.147-0.328,0.328-0.328h3.915
					v-4.052c0-0.181,0.147-0.328,0.328-0.328h5.349c0.181,0,0.328,0.147,0.328,0.328v8.85
					C160.608,112.043,160.461,112.191,160.28,112.191z M151.016,111.534h8.936v-8.193h-4.692v4.052c0,0.181-0.147,0.328-0.328,0.328
					h-3.915V111.534z"
            fill="#010101"
          />
        </G>
        <Path
          d="M134.263,47.772c-0.121,0-0.219-0.098-0.219-0.219v-6.378c0-0.121,0.098-0.219,0.219-0.219
				s0.219,0.098,0.219,0.219v6.378C134.482,47.674,134.384,47.772,134.263,47.772z"
          fill="#010101"
        />
        <Path
          d="M138.15,44.583h-6.664c-0.121,0-0.219-0.098-0.219-0.219s0.098-0.219,0.219-0.219h6.664
				c0.121,0,0.219,0.098,0.219,0.219S138.271,44.583,138.15,44.583z"
          fill="#010101"
        />
        <Rect fill="#E7EBEE" height="19.713" width="23.361" x="107.802" y="69.06" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="106.795,84.741 112.481,69.06 107.355,69.06 106.795,70.346 	" />
        <Polygon fill="#FFFFFF" opacity="0.7" points="113.886,88.773 110.696,88.773 118.138,69.06 121.328,69.06 	" />
        <Path
          d="M131.353,89.257h-24.652c-0.181,0-0.328-0.147-0.328-0.328V67.994c0-0.181,0.147-0.328,0.328-0.328h24.652
				c0.181,0,0.328,0.147,0.328,0.328v20.935C131.681,89.109,131.534,89.257,131.353,89.257z M107.029,88.6h23.995V68.322h-23.995V88.6
				z"
          fill="#010101"
        />
        <Path
          d="M107.819,87.947c-0.121,0-0.219-0.098-0.219-0.219V69.077c0-0.121,0.098-0.219,0.219-0.219h23.626
				c0.121,0,0.219,0.098,0.219,0.219s-0.098,0.219-0.219,0.219h-23.407v18.433C108.038,87.849,107.939,87.947,107.819,87.947z"
          fill="#010101"
        />
        <Path
          d="M115.139,87.692c-0.181,0-0.328-0.147-0.328-0.328V77.148c0-0.181,0.147-0.328,0.328-0.328
				s0.328,0.147,0.328,0.328v10.215C115.467,87.544,115.32,87.692,115.139,87.692z"
          fill="#010101"
        />
        <Path
          d="M115.139,75.712c-0.181,0-0.328-0.147-0.328-0.328v-4.744c0-0.181,0.147-0.328,0.328-0.328
				s0.328,0.147,0.328,0.328v4.744C115.467,75.564,115.32,75.712,115.139,75.712z"
          fill="#010101"
        />
        <Path
          d="M122.89,82.22c-0.181,0-0.328-0.147-0.328-0.328V70.639c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v11.253C123.218,82.073,123.071,82.22,122.89,82.22z"
          fill="#010101"
        />
        <Path
          d="M122.89,87.692c-0.181,0-0.328-0.147-0.328-0.328v-3.706c0-0.181,0.147-0.328,0.328-0.328
				c0.181,0,0.328,0.147,0.328,0.328v3.706C123.218,87.544,123.071,87.692,122.89,87.692z"
          fill="#010101"
        />
        <Path
          d="M131.34,78.953h-22.047c-0.181,0-0.328-0.147-0.328-0.328c0-0.181,0.147-0.328,0.328-0.328h22.047
				c0.181,0,0.328,0.147,0.328,0.328C131.668,78.806,131.521,78.953,131.34,78.953z"
          fill="#010101"
        />
        <G>
          <Path
            d="M151.695,77.148h-15.344l1.014-2.322c0.192-0.441,0.419-0.863,0.677-1.263
					c1.131-1.756,3.038-2.865,5.103-3.18h0l14.395-0.043L151.695,77.148z"
            fill="#E7EBEE"
          />
          <Path
            d="M151.695,77.477h-15.344c-0.111,0-0.214-0.056-0.275-0.149c-0.061-0.092-0.071-0.209-0.026-0.311
					l1.014-2.321c0.196-0.45,0.433-0.891,0.702-1.31c1.141-1.771,3.084-2.984,5.33-3.328l14.444-0.046h0.001
					c0.128,0,0.245,0.074,0.298,0.191c0.054,0.116,0.035,0.254-0.049,0.351l-5.845,6.808
					C151.882,77.435,151.791,77.477,151.695,77.477z M136.853,76.82h14.692l5.28-6.149l-13.677,0.04
					c-2.011,0.311-3.789,1.417-4.828,3.03c-0.25,0.39-0.47,0.799-0.652,1.217L136.853,76.82z"
            fill="#010101"
          />
        </G>
        <G>
          <Path
            d="M157.583,77.148h-6.801l1.014-2.322c0.395-0.904,0.933-1.73,1.59-2.45
					c0.961-1.053,2.287-1.703,3.696-1.919l0.494-0.075L157.583,77.148z"
            fill="#BAC2CA"
          />
          <Path
            d="M157.583,77.477h-6.802c-0.111,0-0.214-0.056-0.275-0.149c-0.061-0.092-0.071-0.209-0.026-0.311
					l1.015-2.321c0.407-0.933,0.962-1.787,1.649-2.539c0.977-1.071,2.357-1.789,3.889-2.022l0.493-0.076
					c0.096-0.013,0.191,0.013,0.264,0.075c0.072,0.062,0.114,0.153,0.114,0.249l0.007,6.766c0,0.087-0.034,0.17-0.096,0.232
					C157.754,77.442,157.67,77.477,157.583,77.477z M151.283,76.82h5.972l-0.006-6.055l-0.116,0.018
					c-1.384,0.211-2.628,0.856-3.504,1.815c-0.638,0.7-1.154,1.494-1.531,2.361L151.283,76.82z"
            fill="#010101"
          />
        </G>
        <Path
          d="M116.602,88.629c0-2.075-1.709-3.88-3.69-3.88c-1.542,0-2.853,1.022-3.36,2.453
				c-0.541-0.802-1.43-1.33-2.439-1.33c-1.544,0-2.814,1.225-2.973,2.798"
          fill="#FFFFFF"
        />
        <Path
          d="M104.141,88.627c-0.011,0-0.023-0.001-0.035-0.002c-0.18-0.02-0.311-0.181-0.292-0.361
				c0.179-1.69,1.598-2.965,3.299-2.965c0.889,0,1.736,0.362,2.355,0.988c0.676-1.251,1.999-2.06,3.444-2.06
				c2.14,0,4.018,1.884,4.018,4.032c0,0.181-0.147,0.328-0.328,0.328c-0.181,0-0.328-0.147-0.328-0.328
				c0-1.798-1.571-3.375-3.361-3.375c-1.354,0-2.58,0.855-3.052,2.128c-0.043,0.114-0.145,0.195-0.265,0.211
				c-0.124,0.02-0.241-0.036-0.311-0.136c-0.5-0.708-1.312-1.131-2.171-1.131c-1.365,0-2.503,1.022-2.647,2.377
				C104.449,88.502,104.306,88.627,104.141,88.627z"
          fill="#010101"
        />
        <Path
          d="M115.069,88.633c0-1.919,1.581-3.589,3.413-3.589c1.427,0,2.639,0.945,3.108,2.269
				c0.501-0.742,1.322-1.23,2.256-1.23c1.428,0,2.603,1.133,2.75,2.588"
          fill="#FFFFFF"
        />
        <Path
          d="M126.595,88.656c-0.166,0-0.308-0.125-0.326-0.294c-0.132-1.241-1.174-2.176-2.424-2.176
				c-0.786,0-1.53,0.387-1.988,1.035c-0.07,0.099-0.19,0.154-0.311,0.136c-0.121-0.016-0.223-0.097-0.265-0.211
				c-0.433-1.168-1.558-1.952-2.799-1.952c-1.643,0-3.085,1.448-3.085,3.097c0,0.181-0.147,0.328-0.328,0.328
				c-0.181,0-0.328-0.147-0.328-0.328c0-2,1.748-3.754,3.741-3.754c1.334,0,2.557,0.741,3.191,1.89
				c0.575-0.57,1.355-0.898,2.173-0.898c1.587,0,2.91,1.188,3.076,2.764c0.019,0.18-0.111,0.342-0.292,0.361
				C126.619,88.655,126.607,88.656,126.595,88.656z"
          fill="#010101"
        />
        <G>
          <Path d="M130.618,94.22h-24.915c-1.549,0-2.804-1.256-2.804-2.804V88.91h27.72V94.22z" fill="#E7EBEE" />
          <Path
            d="M130.618,94.548h-25.469c-1.422,0-2.579-1.157-2.579-2.579V88.91c0-0.181,0.147-0.328,0.328-0.328h27.72
					c0.181,0,0.328,0.147,0.328,0.328v5.31C130.946,94.401,130.799,94.548,130.618,94.548z M103.226,89.239v2.73
					c0,1.06,0.862,1.922,1.922,1.922h25.141v-4.653H103.226z"
            fill="#010101"
          />
        </G>
        <G>
          <Path
            d="M130.232,94.22L130.232,94.22c-1.356,0-2.455-1.099-2.455-2.455V88.91h4.91v2.855
					C132.688,93.121,131.588,94.22,130.232,94.22z"
            fill="#BAC2CA"
          />
          <Path
            d="M130.437,94.548h-0.409c-1.422,0-2.579-1.157-2.579-2.579V88.91c0-0.181,0.147-0.328,0.328-0.328h4.91
					c0.181,0,0.328,0.147,0.328,0.328v3.059C133.016,93.392,131.859,94.548,130.437,94.548z M128.106,89.239v2.73
					c0,1.06,0.862,1.922,1.922,1.922h0.409c1.06,0,1.922-0.862,1.922-1.922v-2.73H128.106z"
            fill="#010101"
          />
        </G>
        <Path
          d="M148.156,107.609h-9.973c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h9.973
				c0.121,0,0.219,0.098,0.219,0.219C148.375,107.511,148.277,107.609,148.156,107.609z"
          fill="#010101"
        />
        <G>
          <Circle cx="149.881" cy="82.429" fill="#FFFFFF" r="0.648" />
          <Path
            d="M149.881,83.296c-0.478,0-0.867-0.389-0.867-0.866c0-0.478,0.389-0.867,0.867-0.867
					c0.478,0,0.867,0.389,0.867,0.867C150.748,82.907,150.359,83.296,149.881,83.296z M149.881,82c-0.237,0-0.429,0.193-0.429,0.429
					c0,0.236,0.192,0.429,0.429,0.429c0.236,0,0.429-0.193,0.429-0.429C150.31,82.193,150.117,82,149.881,82z"
            fill="#010101"
          />
        </G>
        <Path
          d="M86.555,90.537h-8.793c-0.181,0-0.328-0.147-0.328-0.328v-3.781c0-1.166,0.949-2.114,2.114-2.114h7.006
				c0.181,0,0.328,0.147,0.328,0.328v5.567C86.883,90.39,86.736,90.537,86.555,90.537z M78.091,89.881h8.136v-4.91h-6.678
				c-0.804,0-1.458,0.654-1.458,1.458V89.881z"
          fill="#010101"
        />
        <G>
          <Path
            d="M88.225,90.209h-3.897V86.59c0-1.076,0.872-1.948,1.948-1.948l0,0c1.076,0,1.948,0.872,1.948,1.948V90.209
					z"
            fill="#BAC2CA"
          />
          <Path
            d="M88.225,90.537h-3.897c-0.181,0-0.328-0.147-0.328-0.328v-3.781c0-1.166,0.949-2.114,2.114-2.114h0.324
					c1.166,0,2.114,0.948,2.114,2.114v3.781C88.553,90.39,88.406,90.537,88.225,90.537z M84.657,89.881h3.24v-3.452
					c0-0.804-0.654-1.458-1.458-1.458h-0.324c-0.804,0-1.458,0.654-1.458,1.458V89.881z"
            fill="#010101"
          />
        </G>
        <Path
          d="M82.53,87.155c-0.14,0-0.27-0.09-0.313-0.23l-1.182-3.783l-0.619,0.225
				c-0.169,0.061-0.359-0.027-0.42-0.197c-0.062-0.17,0.026-0.359,0.196-0.421l0.942-0.342c0.084-0.031,0.177-0.025,0.258,0.015
				c0.08,0.039,0.141,0.111,0.168,0.196l1.285,4.111c0.054,0.173-0.043,0.357-0.216,0.411C82.595,87.15,82.562,87.155,82.53,87.155z"
          fill="#010101"
        />
        <Path
          d="M105.297,108.268c-0.077,0-0.152-0.041-0.192-0.113c-0.225-0.407-0.488-0.787-0.783-1.129
				c-0.079-0.091-0.069-0.23,0.023-0.308c0.093-0.079,0.23-0.069,0.309,0.023c0.315,0.364,0.595,0.769,0.834,1.202
				c0.058,0.106,0.02,0.239-0.086,0.298C105.369,108.26,105.333,108.268,105.297,108.268z"
          fill="#010101"
        />
        <Path
          d="M103.427,106.115c-0.045,0-0.091-0.014-0.131-0.043c-1.781-1.327-3.781-1.397-3.801-1.398
				c-0.121-0.003-0.216-0.104-0.213-0.225c0.003-0.121,0.125-0.207,0.225-0.212c0.087,0.002,2.154,0.072,4.051,1.484
				c0.097,0.072,0.117,0.209,0.045,0.306C103.559,106.085,103.493,106.115,103.427,106.115z"
          fill="#010101"
        />
        <Path
          d="M192.066,65.163c-0.041,0-0.082-0.011-0.119-0.034c-0.101-0.066-0.13-0.201-0.065-0.303
				c0.325-0.503,0.457-1.716,0.48-2.14c0.006-0.121,0.108-0.215,0.23-0.207c0.121,0.007,0.213,0.11,0.207,0.23
				c-0.004,0.067-0.094,1.65-0.549,2.355C192.208,65.128,192.138,65.163,192.066,65.163z"
          fill="#010101"
        />
        <G>
          <Path
            d="M142.912,95.128c0,0-1.17-0.029-3.397,1.256s-3.312,4.025-1.913,5.824
					c1.399,1.798,3.626,0.628,3.626,0.628l5.909,0.057c0,0,2.598-0.4,2.626-2.541s-0.799-5.139-0.799-5.139L142.912,95.128z"
            fill="#FFFFFF"
          />
          <G>
            <Path
              d="M150.599,93.714c0.725-2.563,0.144-3.528,0.144-3.528c-1.798,0.043-2.88,1.135-2.88,1.135
						c-1.243-0.467-2.728,0-2.728,0s-1.081-1.092-2.88-1.135c0,0-0.581,0.965,0.144,3.528c0,0-1.064,2.587,1.467,3.571
						c2.531,0.983,4.175,0.347,5.352-0.073s1.991-1.717,1.572-2.966L150.599,93.714z"
              fill="#FFFFFF"
            />
            <Path
              d="M146.444,98.131c-0.778,0-1.671-0.142-2.695-0.54c-0.819-0.318-1.382-0.825-1.675-1.508
						c-0.426-0.993-0.134-2.042-0.018-2.382c-0.699-2.549-0.147-3.575-0.081-3.685c0.061-0.101,0.173-0.155,0.289-0.159
						c1.542,0.037,2.588,0.787,2.958,1.1c0.431-0.109,1.536-0.322,2.565-0.009c0.379-0.317,1.42-1.054,2.948-1.091
						c0.108,0,0.228,0.058,0.289,0.159c0.066,0.11,0.619,1.136-0.081,3.685l0.157,0.433c0.457,1.361-0.339,2.878-1.77,3.388
						l-0.091,0.032C148.54,97.804,147.626,98.131,146.444,98.131z M142.475,90.526c-0.106,0.361-0.262,1.319,0.242,3.1
						c0.02,0.071,0.016,0.146-0.012,0.213c-0.004,0.011-0.424,1.067-0.025,1.99c0.221,0.513,0.661,0.9,1.307,1.151
						c2.351,0.913,3.901,0.36,5.032-0.044l0.091-0.033c0.96-0.342,1.741-1.449,1.371-2.553l-0.19-0.524
						c-0.023-0.065-0.026-0.136-0.007-0.201c0.503-1.777,0.348-2.735,0.241-3.1c-1.501,0.118-2.419,1.017-2.428,1.027
						c-0.091,0.09-0.227,0.121-0.348,0.075c-1.12-0.422-2.5,0.002-2.514,0.006c-0.116,0.034-0.245,0.004-0.33-0.082
						C144.894,91.543,143.976,90.644,142.475,90.526z"
              fill="#010101"
            />
          </G>
          <Path
            d="M142.481,93.99c-0.032,0-0.064-0.007-0.095-0.021c-0.109-0.053-0.155-0.183-0.102-0.292
					c0.424-0.882,1.104-1.44,1.44-1.677c-0.573-0.773-1.442-1.44-1.451-1.448c-0.096-0.074-0.114-0.211-0.041-0.306
					c0.074-0.096,0.212-0.114,0.307-0.042c0.044,0.033,1.074,0.825,1.677,1.734c0.033,0.05,0.044,0.112,0.03,0.171
					c-0.014,0.059-0.051,0.109-0.103,0.139c-0.009,0.005-0.956,0.562-1.464,1.618C142.641,93.945,142.563,93.99,142.481,93.99z"
            fill="#010101"
          />
          <Path
            d="M150.521,93.99c-0.081,0-0.159-0.045-0.197-0.124c-0.511-1.06-1.455-1.612-1.464-1.618
					c-0.053-0.03-0.09-0.081-0.104-0.139c-0.014-0.059-0.003-0.121,0.031-0.171c0.603-0.909,1.634-1.701,1.677-1.734
					c0.096-0.073,0.234-0.054,0.307,0.042c0.073,0.096,0.055,0.233-0.041,0.306c-0.009,0.007-0.878,0.675-1.451,1.448
					c0.336,0.237,1.016,0.795,1.44,1.677c0.053,0.109,0.007,0.24-0.102,0.292C150.585,93.983,150.553,93.99,150.521,93.99z"
            fill="#010101"
          />
          <Path
            d="M146.902,95.864h-0.642c-0.121,0-0.219-0.098-0.219-0.219c0-0.121,0.098-0.219,0.219-0.219h0.642
					c0.121,0,0.219,0.098,0.219,0.219C147.121,95.766,147.023,95.864,146.902,95.864z"
            fill="#010101"
          />
          <Path
            d="M146.06,96.746c-0.004,0-0.007,0-0.011,0c-0.555-0.009-0.746-0.437-0.77-0.663
					c-0.013-0.12,0.074-0.228,0.195-0.241c0.11-0.013,0.227,0.074,0.24,0.193c0.009,0.063,0.058,0.269,0.342,0.274
					c0.001,0,0.002,0,0.003,0c0.269,0,0.299-0.618,0.299-0.625c0.003-0.121,0.109-0.214,0.223-0.214
					c0.121,0.002,0.217,0.103,0.214,0.223C146.787,96.086,146.622,96.746,146.06,96.746z"
            fill="#010101"
          />
          <Path
            d="M147.036,96.746c-0.562,0-0.727-0.66-0.735-1.053c-0.003-0.121,0.093-0.22,0.214-0.223
					c0.135,0.013,0.221,0.093,0.224,0.214c0,0.006,0.033,0.625,0.299,0.625c0.001,0,0.002,0,0.003,0
					c0.284-0.005,0.334-0.211,0.342-0.274c0.016-0.118,0.126-0.212,0.243-0.19c0.119,0.014,0.204,0.119,0.192,0.238
					c-0.024,0.226-0.215,0.654-0.77,0.663C147.043,96.746,147.039,96.746,147.036,96.746z"
            fill="#010101"
          />
          <Circle cx="144.451" cy="94.703" fill="#010101" r="0.267" />
          <Circle cx="148.583" cy="94.618" fill="#010101" r="0.267" />
          <Path
            d="M139.944,103.456c-0.029,0-0.059,0-0.089-0.001c-1.217-0.027-2.228-0.462-2.772-1.193
					c-0.528-0.71-0.633-1.658-0.312-2.819c1.074-3.888,5.189-4.57,5.363-4.597c0.182-0.024,0.346,0.095,0.375,0.274
					c0.028,0.179-0.094,0.347-0.273,0.375c-0.039,0.006-3.872,0.65-4.831,4.123c-0.264,0.956-0.195,1.713,0.206,2.252
					c0.422,0.568,1.246,0.906,2.259,0.929c0.846,0.019,1.487-0.246,2.471-1.049l0.279-0.292c0.061-0.063,0.094-0.147,0.092-0.234
					c-0.002-0.088-0.037-0.169-0.101-0.23c-0.122-0.118-0.318-0.119-0.444-0.005c-1.285,1.169-2.591,1.234-3.385,0.904
					c-0.873-0.362-1.031-1.191-0.992-1.579c0.018-0.18,0.178-0.306,0.359-0.294c0.18,0.018,0.311,0.178,0.294,0.357
					c-0.005,0.068-0.033,0.651,0.59,0.91c0.579,0.241,1.633,0.179,2.692-0.784c0.382-0.346,0.972-0.339,1.341,0.017
					c0.19,0.182,0.297,0.427,0.303,0.691c0.005,0.263-0.093,0.513-0.277,0.704l-0.308,0.317
					C141.852,102.995,141.061,103.456,139.944,103.456z"
            fill="#010101"
          />
          <Path
            d="M145.98,103.32c-2.069,0-4.698-0.025-4.698-0.025l0.006-0.657c0,0,2.626,0.025,4.692,0.025
					c0.497,0,0.899-0.007,1.223-0.017c0.534-0.016,0.953-0.451,0.953-0.99c0-0.267-0.104-0.517-0.293-0.705
					c-0.187-0.186-0.44-0.274-0.697-0.285c-0.899,0-1.606-0.031-2.089-0.108c-0.179-0.029-0.301-0.198-0.272-0.376
					c0.029-0.179,0.194-0.299,0.376-0.273c0.441,0.071,1.126,0.114,1.981,0.1c0.457,0.003,0.852,0.166,1.163,0.476
					c0.314,0.312,0.487,0.728,0.487,1.171c0,0.895-0.698,1.618-1.589,1.647C146.894,103.313,146.486,103.32,145.98,103.32z"
            fill="#010101"
          />
          <Path
            d="M147.703,103.182c-0.162,0-0.303-0.12-0.325-0.285c-0.024-0.179,0.102-0.345,0.282-0.368
					c0.55-0.074,0.96-0.32,1.256-0.752c1.007-1.475,0.279-4.505,0.272-4.535c-0.043-0.176,0.065-0.354,0.241-0.397
					c0.176-0.042,0.354,0.065,0.397,0.241c0.033,0.136,0.804,3.344-0.366,5.061c-0.401,0.588-0.977,0.936-1.712,1.034
					C147.732,103.181,147.717,103.182,147.703,103.182z"
            fill="#010101"
          />
        </G>
        <Path
          d="M241.901,73.056c0-6.825-4.523-12.589-10.734-14.471c-0.692-0.21-0.977-1.001-0.596-1.616
				c1.08-1.744,1.704-3.799,1.704-6.001c0-6.304-5.11-11.415-11.415-11.415c-0.066,0-0.13,0.009-0.196,0.01
				c-0.727-5.382-5.328-9.535-10.909-9.535c-5.589,0-10.194,4.164-10.912,9.556c-0.206-0.011-0.409-0.031-0.617-0.031
				c-6.304,0-11.415,5.11-11.415,11.415c0,2.313,0.693,4.462,1.877,6.258c0.39,0.592,0.139,1.375-0.529,1.616
				c-5.826,2.101-9.995,7.664-9.995,14.214c0,6.385,3.962,11.835,9.557,14.054l-0.015,0.017c0,0,6.045,2.643,18.486,2.719
				c1.242,0.049,2.534,0.078,3.877,0.078c1.23,0,2.429-0.028,3.593-0.075c12.579-0.049,18.687-2.722,18.687-2.722l-0.013-0.014
				C237.935,84.896,241.901,79.444,241.901,73.056z"
          fill="#D9DFE6"
        />
        <Path
          d="M210.07,112.224c-0.256,0-0.465-0.209-0.465-0.468V55.029c0-0.258,0.208-0.468,0.465-0.468
				s0.464,0.209,0.464,0.468v56.728C210.535,112.015,210.327,112.224,210.07,112.224z"
          fill="#010101"
        />
        <Path
          d="M209.849,77.173c-0.086,0-0.173-0.024-0.252-0.074l-11.611-7.466c-0.215-0.139-0.278-0.426-0.139-0.642
				c0.138-0.217,0.425-0.277,0.642-0.139l11.611,7.466c0.215,0.139,0.278,0.426,0.139,0.642
				C210.151,77.098,210.001,77.173,209.849,77.173z"
          fill="#010101"
        />
        <Path
          d="M210.032,65.863c-0.151,0-0.299-0.074-0.388-0.209c-0.14-0.214-0.081-0.502,0.133-0.644l5.817-3.83
				c0.213-0.142,0.502-0.082,0.644,0.133c0.14,0.214,0.081,0.502-0.133,0.644l-5.817,3.83
				C210.208,65.839,210.12,65.863,210.032,65.863z"
          fill="#010101"
        />
        <Path
          d="M210.258,83.4c-0.158,0-0.313-0.081-0.4-0.229c-0.129-0.22-0.057-0.506,0.165-0.636l11.407-6.745
				c0.219-0.128,0.504-0.058,0.636,0.165c0.129,0.22,0.057,0.506-0.165,0.636l-11.407,6.745C210.42,83.38,210.339,83.4,210.258,83.4z"
          fill="#010101"
        />
        <Path
          d="M210.069,90.389c-1.273,0-2.584-0.027-3.896-0.079c-12.378-0.076-18.403-2.649-18.654-2.758
				c-0.039-0.017-0.074-0.039-0.106-0.064c-5.905-2.406-9.713-8.055-9.713-14.432c0-6.54,4.141-12.428,10.302-14.65
				c0.183-0.067,0.316-0.201,0.375-0.38c0.06-0.18,0.031-0.378-0.077-0.543c-1.277-1.94-1.954-4.193-1.954-6.514
				c0-6.55,5.33-11.879,11.88-11.879c0.075,0,0.149,0.002,0.221,0.006c0.939-5.464,5.725-9.531,11.308-9.531
				c5.578,0,10.367,4.067,11.306,9.527c6.458,0.108,11.679,5.394,11.679,11.877c0,2.212-0.613,4.372-1.775,6.245
				c-0.108,0.174-0.129,0.379-0.06,0.562c0.068,0.178,0.208,0.308,0.397,0.365c6.618,2.005,11.063,7.998,11.063,14.915
				c0,6.388-3.819,12.043-9.74,14.442c-0.028,0.022-0.058,0.04-0.091,0.054c-0.253,0.11-6.34,2.712-18.87,2.762
				C212.51,90.36,211.304,90.389,210.069,90.389z M188.015,86.751c0.921,0.363,6.954,2.561,18.178,2.63
				c2.397,0.096,5.026,0.099,7.45,0.004c11.453-0.045,17.555-2.3,18.414-2.641c0.031-0.027,0.068-0.047,0.108-0.063
				c5.632-2.231,9.271-7.578,9.271-13.625c0-6.504-4.18-12.14-10.403-14.026c-0.464-0.14-0.829-0.478-0.998-0.926
				c-0.172-0.455-0.12-0.958,0.142-1.379c1.069-1.727,1.634-3.717,1.634-5.757c0-6.038-4.912-10.95-10.951-10.95l-0.188,0.01
				c-0.218-0.006-0.438-0.169-0.469-0.402c-0.703-5.207-5.195-9.133-10.449-9.133c-5.264,0-9.758,3.935-10.451,9.153
				c-0.033,0.241-0.249,0.429-0.486,0.403l-0.19-0.012c-0.133-0.009-0.266-0.019-0.402-0.019c-6.038,0-10.951,4.912-10.951,10.95
				c0,2.14,0.623,4.216,1.801,6.003c0.266,0.404,0.333,0.895,0.183,1.347c-0.149,0.449-0.492,0.799-0.942,0.961
				c-5.794,2.09-9.688,7.627-9.688,13.777c0,6.043,3.636,11.391,9.265,13.623C187.938,86.697,187.979,86.721,188.015,86.751z"
          fill="#010101"
        />
        <G>
          <Path
            d="M233.378,77.64c0-0.677-0.501-1.231-1.15-1.327c-0.088-0.659-0.646-1.169-1.329-1.169
					c-0.678,0-1.234,0.503-1.327,1.156c-0.715,0.032-1.287,0.617-1.287,1.341c0,0.738,0.594,1.335,1.329,1.345
					c0.165,0.557,0.675,0.965,1.285,0.965c0.616,0,1.129-0.415,1.289-0.979C232.857,78.892,233.378,78.33,233.378,77.64z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M197.183,47.721c0-0.461-0.341-0.839-0.784-0.904c-0.06-0.449-0.44-0.796-0.905-0.796
					c-0.462,0-0.84,0.343-0.904,0.787c-0.487,0.022-0.877,0.42-0.877,0.913c0,0.503,0.404,0.909,0.905,0.916
					c0.113,0.379,0.46,0.657,0.875,0.657c0.419,0,0.769-0.283,0.878-0.667C196.829,48.573,197.183,48.191,197.183,47.721z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M219.804,71.093c0-0.5-0.37-0.91-0.85-0.98c-0.065-0.487-0.477-0.864-0.982-0.864
					c-0.501,0-0.911,0.372-0.981,0.854c-0.529,0.023-0.951,0.456-0.951,0.99c0,0.545,0.439,0.986,0.982,0.994
					c0.122,0.411,0.499,0.713,0.949,0.713c0.455,0,0.834-0.307,0.953-0.724C219.419,72.018,219.804,71.603,219.804,71.093z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M186.848,78.34c0-0.329-0.243-0.599-0.559-0.645c-0.043-0.32-0.314-0.569-0.646-0.569
					c-0.33,0-0.6,0.245-0.645,0.562c-0.348,0.015-0.626,0.3-0.626,0.652c0,0.359,0.289,0.649,0.646,0.654
					c0.08,0.271,0.328,0.469,0.625,0.469c0.299,0,0.549-0.202,0.627-0.476C186.594,78.949,186.848,78.675,186.848,78.34z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M228.612,63.718c0-0.329-0.243-0.599-0.559-0.645c-0.043-0.32-0.314-0.569-0.646-0.569
					c-0.33,0-0.6,0.245-0.645,0.562c-0.348,0.015-0.626,0.3-0.626,0.652c0,0.359,0.289,0.649,0.646,0.654
					c0.08,0.271,0.328,0.469,0.625,0.469c0.299,0,0.549-0.202,0.627-0.476C228.359,64.327,228.612,64.054,228.612,63.718z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M221.746,83.752c0-0.329-0.243-0.599-0.559-0.645c-0.043-0.32-0.314-0.569-0.646-0.569
					c-0.33,0-0.6,0.245-0.645,0.562c-0.348,0.015-0.626,0.3-0.626,0.652c0,0.359,0.289,0.649,0.646,0.654
					c0.08,0.271,0.328,0.469,0.625,0.469c0.299,0,0.549-0.202,0.627-0.476C221.492,84.361,221.746,84.088,221.746,83.752z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M235.721,68.508c0-0.501-0.37-0.911-0.851-0.982c-0.065-0.488-0.478-0.865-0.983-0.865
					c-0.502,0-0.913,0.372-0.982,0.855c-0.529,0.024-0.952,0.457-0.952,0.992c0,0.546,0.439,0.988,0.984,0.995
					c0.122,0.412,0.499,0.714,0.951,0.714c0.455,0,0.835-0.307,0.954-0.725C235.336,69.435,235.721,69.019,235.721,68.508z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M223.927,57.441c0-0.501-0.37-0.911-0.851-0.982c-0.065-0.488-0.478-0.865-0.983-0.865
					c-0.502,0-0.913,0.372-0.982,0.855c-0.529,0.024-0.952,0.457-0.952,0.992c0,0.546,0.439,0.988,0.984,0.995
					c0.122,0.412,0.499,0.714,0.951,0.714c0.455,0,0.835-0.307,0.954-0.725C223.541,58.367,223.927,57.951,223.927,57.441z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M204.943,53.297c0-0.329-0.243-0.599-0.559-0.645c-0.043-0.32-0.314-0.569-0.646-0.569
					c-0.33,0-0.6,0.245-0.645,0.562c-0.348,0.015-0.626,0.3-0.626,0.652c0,0.359,0.289,0.649,0.646,0.654
					c0.08,0.271,0.328,0.469,0.625,0.469c0.299,0,0.549-0.202,0.627-0.476C204.69,53.906,204.943,53.633,204.943,53.297z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M213.506,37.897c0-0.544-0.402-0.989-0.924-1.066c-0.07-0.529-0.519-0.939-1.068-0.939
					c-0.545,0-0.991,0.404-1.066,0.928c-0.575,0.026-1.034,0.496-1.034,1.077c0,0.593,0.477,1.073,1.068,1.081
					c0.133,0.447,0.542,0.776,1.033,0.776c0.495,0,0.907-0.334,1.036-0.787C213.087,38.903,213.506,38.452,213.506,37.897z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M194.926,74.33c0-0.544-0.402-0.989-0.924-1.066c-0.07-0.529-0.519-0.939-1.068-0.939
					c-0.545,0-0.991,0.404-1.066,0.928c-0.575,0.026-1.034,0.496-1.034,1.077c0,0.593,0.477,1.073,1.068,1.081
					c0.133,0.447,0.542,0.776,1.033,0.776c0.495,0,0.907-0.334,1.036-0.787C194.507,75.336,194.926,74.885,194.926,74.33z"
            fill="#BAC2CA"
          />
        </G>
        <G>
          <Path
            d="M207.609,44.369c0-0.608-0.45-1.106-1.034-1.192c-0.079-0.592-0.58-1.051-1.194-1.051
					c-0.609,0-1.109,0.452-1.193,1.038c-0.643,0.029-1.157,0.555-1.157,1.205c0,0.663,0.534,1.2,1.195,1.209
					c0.148,0.5,0.606,0.867,1.155,0.867c0.553,0,1.014-0.373,1.159-0.88C207.141,45.494,207.609,44.989,207.609,44.369z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M203.692,61.182c0-0.683-0.506-1.243-1.162-1.34c-0.088-0.666-0.652-1.181-1.342-1.181
					c-0.685,0-1.246,0.508-1.341,1.167c-0.723,0.032-1.3,0.623-1.3,1.354c0,0.745,0.6,1.349,1.343,1.358
					c0.167,0.562,0.682,0.975,1.298,0.975c0.622,0,1.14-0.42,1.302-0.989C203.166,62.447,203.692,61.879,203.692,61.182z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M201.789,81.312c0-0.5-0.37-0.91-0.85-0.98c-0.065-0.487-0.477-0.864-0.982-0.864
					c-0.501,0-0.911,0.372-0.981,0.854c-0.529,0.023-0.951,0.456-0.951,0.99c0,0.545,0.439,0.986,0.982,0.994
					c0.122,0.411,0.499,0.713,0.949,0.713c0.455,0,0.834-0.307,0.953-0.724C201.404,82.237,201.789,81.822,201.789,81.312z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M192.782,65.761c0-0.5-0.37-0.91-0.85-0.98c-0.065-0.487-0.477-0.864-0.982-0.864
					c-0.501,0-0.911,0.372-0.981,0.854c-0.529,0.023-0.951,0.456-0.951,0.99c0,0.545,0.439,0.986,0.982,0.994
					c0.122,0.411,0.499,0.713,0.949,0.713c0.455,0,0.834-0.307,0.953-0.724C192.397,66.686,192.782,66.271,192.782,65.761z"
            fill="#FFFFFF"
          />
        </G>
        <G>
          <Path
            d="M221.907,47.676c0-0.576-0.426-1.048-0.979-1.129c-0.075-0.561-0.55-0.995-1.131-0.995
					c-0.577,0-1.05,0.428-1.13,0.983c-0.609,0.027-1.095,0.525-1.095,1.141c0,0.628,0.505,1.136,1.131,1.145
					c0.141,0.474,0.574,0.821,1.094,0.821c0.524,0,0.961-0.354,1.097-0.833C221.464,48.742,221.907,48.264,221.907,47.676z"
            fill="#FFFFFF"
          />
        </G>
        <Path
          d="M110.457,22.72c-0.085,0-0.162-0.059-0.181-0.146c-0.022-0.1,0.041-0.199,0.141-0.221l1.177-0.262
				l-1.173-0.246c-0.1-0.021-0.164-0.119-0.144-0.22c0.021-0.1,0.117-0.165,0.22-0.143l1.618,0.34
				c0.127,0.026,0.216,0.135,0.217,0.265c0.001,0.13-0.087,0.24-0.214,0.268l-1.622,0.36C110.483,22.719,110.47,22.72,110.457,22.72z
				M112.039,22.185h0.004H112.039z"
          fill="#010101"
        />
        <Path
          d="M104.769,24.612c-0.176,0-0.35-0.018-0.517-0.06c-0.076-0.019-0.132-0.084-0.139-0.163
				c-0.002-0.015-0.026-0.308,0.19-0.699c-0.247-0.01-0.568-0.08-0.865-0.315c-0.054-0.043-0.08-0.111-0.067-0.179
				c0.012-0.067,0.061-0.123,0.126-0.143c0.012-0.004,1.161-0.373,1.937-0.984c0.268-0.211,0.495-0.448,0.715-0.678
				c0.415-0.433,0.843-0.881,1.559-1.191c0.095-0.04,0.203,0.003,0.244,0.097c0.041,0.094-0.002,0.203-0.096,0.244
				c-0.646,0.28-1.031,0.681-1.438,1.107c-0.229,0.239-0.466,0.487-0.754,0.714c-0.543,0.427-1.234,0.736-1.661,0.904
				c0.327,0.107,0.612,0.033,0.628,0.029c0.076-0.021,0.161,0.011,0.205,0.08c0.043,0.069,0.038,0.157-0.014,0.219
				c-0.224,0.265-0.301,0.493-0.326,0.63c0.964,0.141,2.207-0.562,2.219-0.569c0.064-0.037,0.143-0.033,0.202,0.011
				c0.591,0.429,1.26,0.586,1.88,0.439c0.491-0.115,0.914-0.414,1.105-0.78c0.429-0.823,0.419-1.673-0.028-2.333
				c-0.457-0.675-1.603-0.554-1.616-0.553c-0.1,0.012-0.194-0.062-0.205-0.163c-0.011-0.102,0.062-0.194,0.164-0.205
				c0.054-0.006,1.382-0.146,1.964,0.714c0.526,0.777,0.544,1.765,0.05,2.713c-0.239,0.459-0.756,0.83-1.349,0.969
				c-0.695,0.163-1.43,0.009-2.086-0.433C106.496,24.19,105.616,24.612,104.769,24.612z"
          fill="#010101"
        />
        <Path
          d="M107.276,23.377c-0.078,0-0.159-0.007-0.243-0.023c-0.011-0.001-0.388-0.057-0.682-0.353
				c-0.386,0.183-1.042,0.312-1.468-0.209c-0.065-0.079-0.053-0.196,0.026-0.261c0.08-0.064,0.197-0.053,0.261,0.026
				c0.399,0.489,1.118,0.056,1.126,0.052c0.082-0.05,0.189-0.03,0.246,0.048c0.204,0.276,0.546,0.33,0.55,0.331
				c0.802,0.153,1.281-0.807,1.286-0.817c0.044-0.093,0.156-0.131,0.248-0.086c0.092,0.045,0.13,0.156,0.085,0.248
				C108.69,22.38,108.193,23.377,107.276,23.377z"
          fill="#010101"
        />
        <Circle cx="109.097" cy="21.506" fill="#010101" r="0.17" />
        <Path
          d="M107.683,25.874c-0.06,0-0.119-0.03-0.155-0.084l-0.534-0.813c-0.028-0.043-0.037-0.097-0.025-0.147
				l0.19-0.756c0.025-0.1,0.125-0.159,0.225-0.135c0.099,0.025,0.16,0.126,0.135,0.225l-0.17,0.677l0.489,0.744
				c0.056,0.086,0.032,0.201-0.053,0.257C107.753,25.864,107.718,25.874,107.683,25.874z"
          fill="#010101"
        />
        <Path
          d="M108.519,25.95c-0.06,0-0.119-0.03-0.155-0.084l-0.534-0.812c-0.029-0.044-0.038-0.098-0.025-0.149
				l0.164-0.629c0.026-0.099,0.127-0.159,0.226-0.133c0.099,0.026,0.158,0.127,0.133,0.226l-0.143,0.549l0.489,0.743
				c0.056,0.086,0.032,0.201-0.053,0.257C108.59,25.94,108.554,25.95,108.519,25.95z"
          fill="#010101"
        />
        <Path
          d="M88.513,19.978c-3.364,0-6.1-2.736-6.1-6.1s2.736-6.1,6.1-6.1c3.364,0,6.1,2.736,6.1,6.1
				S91.877,19.978,88.513,19.978z M88.513,8.334c-3.057,0-5.544,2.487-5.544,5.544s2.487,5.544,5.544,5.544
				c3.057,0,5.544-2.487,5.544-5.544S91.57,8.334,88.513,8.334z"
          fill="#010101"
        />
        <Path
          d="M88.282,6.068c-0.154,0-0.278-0.125-0.278-0.278V3.358c0-0.154,0.125-0.278,0.278-0.278
				s0.278,0.125,0.278,0.278V5.79C88.56,5.943,88.435,6.068,88.282,6.068z"
          fill="#010101"
        />
        <Path
          d="M88.282,24.526c-0.154,0-0.278-0.125-0.278-0.278v-2.431c0-0.154,0.125-0.278,0.278-0.278
				s0.278,0.125,0.278,0.278v2.431C88.56,24.402,88.435,24.526,88.282,24.526z"
          fill="#010101"
        />
        <Path
          d="M93.948,8.415c-0.071,0-0.142-0.027-0.197-0.081c-0.109-0.109-0.109-0.285,0-0.394l1.719-1.719
				c0.108-0.109,0.284-0.108,0.393,0c0.109,0.109,0.109,0.285,0,0.394l-1.719,1.719C94.091,8.388,94.019,8.415,93.948,8.415z"
          fill="#010101"
        />
        <Path
          d="M80.896,21.467c-0.071,0-0.142-0.027-0.197-0.082c-0.109-0.108-0.109-0.285,0-0.393l1.719-1.719
				c0.109-0.109,0.284-0.109,0.393,0c0.109,0.108,0.109,0.285,0,0.393l-1.719,1.719C81.038,21.44,80.967,21.467,80.896,21.467z"
          fill="#010101"
        />
        <Path
          d="M99.005,13.803c0,0.154-0.125,0.278-0.278,0.278h-2.431c-0.154,0-0.278-0.125-0.278-0.278
				c0-0.154,0.125-0.278,0.278-0.278h2.431C98.88,13.525,99.005,13.65,99.005,13.803"
          fill="#010101"
        />
        <Path
          d="M80.268,14.082h-2.431c-0.154,0-0.278-0.125-0.278-0.278c0-0.154,0.125-0.278,0.278-0.278h2.431
				c0.154,0,0.278,0.125,0.278,0.278C80.546,13.957,80.422,14.082,80.268,14.082z"
          fill="#010101"
        />
        <Path
          d="M95.667,21.467c-0.071,0-0.142-0.027-0.197-0.082l-1.719-1.719c-0.109-0.108-0.109-0.285,0-0.393
				c0.109-0.109,0.284-0.109,0.393,0l1.719,1.719c0.109,0.108,0.109,0.285,0,0.393C95.809,21.44,95.738,21.467,95.667,21.467z"
          fill="#010101"
        />
        <Path
          d="M82.615,8.415c-0.071,0-0.143-0.027-0.197-0.082l-1.719-1.719c-0.108-0.109-0.108-0.285,0-0.394
				c0.109-0.108,0.285-0.108,0.393,0l1.719,1.719c0.108,0.109,0.108,0.285,0,0.394C82.757,8.388,82.686,8.415,82.615,8.415z"
          fill="#010101"
        />
        <G>
          <G>
            <G>
              <Path d="M77.947,116.389c1.51-3.061,1.582-4.961,1.89-9.411c0,0-4.414,4.097-6.829,8.456" fill="#E7EBEE" />
              <Path
                d="M77.947,116.541c-0.022,0-0.045-0.005-0.067-0.016c-0.075-0.037-0.107-0.128-0.069-0.203
							c1.432-2.904,1.559-4.752,1.837-8.801l0.014-0.209c-0.714,0.592-2.759,2.466-4.682,5.937c-0.041,0.074-0.133,0.099-0.207,0.059
							c-0.073-0.041-0.1-0.133-0.059-0.207c2.404-4.339,5.009-6.228,5.034-6.246c0.048-0.034,0.112-0.038,0.163-0.009
							c0.051,0.028,0.082,0.085,0.077,0.143l-0.038,0.552c-0.281,4.09-0.409,5.957-1.868,8.915
							C78.057,116.51,78.003,116.541,77.947,116.541z"
                fill="#010101"
              />
            </G>
            <G>
              <Path d="M71.142,116.389c-0.051-3.74,0.569-6.871,2.098-11.93c0,0,2.747,5.504,2.618,12.198" fill="#E7EBEE" />
              <Path
                d="M75.857,116.809c-0.001,0-0.002,0-0.003,0c-0.084-0.002-0.151-0.071-0.149-0.155
							c0.109-5.615-1.845-10.441-2.435-11.767c-1.522,5.096-2.023,8.035-1.976,11.499c0.001,0.084-0.066,0.153-0.15,0.155
							c-0.001,0-0.001,0-0.002,0c-0.083,0-0.151-0.067-0.152-0.15c-0.049-3.586,0.482-6.608,2.104-11.976
							c0.018-0.06,0.071-0.102,0.133-0.108c0.062,0.001,0.121,0.028,0.149,0.084c0.027,0.056,2.762,5.615,2.633,12.269
							C76.007,116.743,75.94,116.809,75.857,116.809z"
                fill="#010101"
              />
            </G>
            <G>
              <Path d="M71.999,116.818c-0.833-3.646-2.411-7.18-5.198-11.252c0,0-1.072,4.287,0.634,10.76" fill="#E7EBEE" />
              <Path
                d="M71.999,116.97c-0.069,0-0.132-0.048-0.148-0.118c-0.829-3.63-2.412-7.102-4.972-10.901
							c-0.204,1.144-0.699,5.015,0.703,10.336c0.021,0.081-0.027,0.165-0.108,0.186c-0.082,0.02-0.164-0.027-0.186-0.108
							c-1.696-6.437-0.645-10.793-0.634-10.836c0.015-0.058,0.062-0.102,0.121-0.113c0.061-0.01,0.119,0.014,0.152,0.064
							c2.702,3.949,4.361,7.541,5.22,11.305c0.019,0.082-0.032,0.164-0.115,0.182C72.021,116.968,72.01,116.97,71.999,116.97z"
                fill="#010101"
              />
            </G>
            <G>
              <Path d="M69.126,116.684c-2.147-2.653-4.736-4.956-8.647-7.1c0,0,0.589,3.108,3.536,7.127" fill="#E7EBEE" />
              <Path
                d="M64.015,116.863c-0.047,0-0.093-0.021-0.123-0.062c-2.933-4-3.557-7.157-3.563-7.188
							c-0.011-0.058,0.012-0.117,0.06-0.151c0.048-0.035,0.111-0.038,0.163-0.011c3.624,1.986,6.304,4.187,8.692,7.138
							c0.053,0.065,0.043,0.161-0.022,0.214c-0.065,0.053-0.162,0.043-0.214-0.023c-2.296-2.836-4.866-4.972-8.3-6.895
							c0.239,0.898,1.086,3.539,3.43,6.736c0.05,0.068,0.035,0.163-0.033,0.213C64.078,116.853,64.046,116.863,64.015,116.863z"
                fill="#010101"
              />
            </G>
            <G>
              <Path d="M75.803,116.684c2.147-2.653,4.736-4.956,8.647-7.1c0,0-0.589,3.108-3.536,7.127" fill="#E7EBEE" />
              <Path
                d="M80.914,116.863c-0.031,0-0.063-0.01-0.09-0.03c-0.068-0.049-0.082-0.145-0.033-0.213
							c2.345-3.197,3.191-5.838,3.43-6.736c-3.435,1.922-6.005,4.059-8.3,6.895c-0.053,0.066-0.149,0.075-0.214,0.023
							c-0.065-0.053-0.075-0.149-0.022-0.214c2.388-2.951,5.069-5.152,8.692-7.138c0.051-0.028,0.115-0.024,0.163,0.011
							c0.048,0.034,0.071,0.093,0.06,0.151c-0.006,0.032-0.63,3.188-3.563,7.188C81.007,116.841,80.961,116.863,80.914,116.863z"
                fill="#010101"
              />
            </G>
          </G>
          <Path
            d="M73.796,117H57.75c-0.097,0-0.176-0.079-0.176-0.176c0-0.097,0.079-0.176,0.176-0.176h16.047
					c0.097,0,0.176,0.079,0.176,0.176C73.972,116.921,73.893,117,73.796,117z"
            fill="#010101"
          />
          <Path
            d="M86.476,117h-6.789c-0.097,0-0.176-0.079-0.176-0.176c0-0.097,0.079-0.176,0.176-0.176h6.789
					c0.097,0,0.176,0.079,0.176,0.176C86.651,116.921,86.573,117,86.476,117z"
            fill="#010101"
          />
        </G>
      </G>
    </Svg>
  );
};

export default DesktopSelectedLoanSpring;
