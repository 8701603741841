import styled from 'styled-components/native';
import HTML from 'react-native-render-html';

import { H4, P2 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const DetailHeader = styled(H4)`
  align-self: center;
  margin-bottom: ${moderateScale(14)}px;
`;

export const IconWrapper = styled.View`
  align-self: center;
  max-width: 5%;
`;

export const Row = styled.Pressable`
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(16)}px ${moderateScale(24)}px;
`;

export const RowText = styled(P2)`
  max-width: 90%;
`;

export const SkeletonRow = styled.View`
  padding: ${moderateScale(16)}px ${moderateScale(24)}px;
  width: 100%;
`;

export const StyledHTML = styled(HTML).attrs(({
  theme: {
    colors: { primaryCarbon },
    fontFamilies: { secondaryFont },
    fontSizes: { f4 },
  },
}) => ({
  baseFontStyle: {
    color: primaryCarbon,
    fontFamily: secondaryFont,
    fontSize: f4,
    lineHeight: moderateScale(22),
  },
}))``;
