import React from 'react';
import T from 'prop-types';

import { MultiDocHeaderWrapper, StyledH2 } from '../styledComponents';

const MultiDocHeader = ({ uploadIndex }) => (
  <MultiDocHeaderWrapper>
    <StyledH2 $isUpload>{`Step ${uploadIndex + 1} of 2`}</StyledH2>
  </MultiDocHeaderWrapper>
);

MultiDocHeader.propTypes = { uploadIndex: T.number.isRequired };

export default MultiDocHeader;
