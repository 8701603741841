import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { P3 } from '../../base_ui';

export const AttachmentTextWrapper = styled.View`
  display: flex;
  flex-direction: row;
`;

export const EditText = styled(P3)`
  color: ${({ theme: { colors: { primary } } }) => primary};
  margin-left: ${(moderateScale(8))}px;
`;

export const IconWrapper = styled.View`
  margin-right: ${(moderateScale(6))}px;
`;

export const FileRowButtonContainer = styled.View`
  align-items: center;
  ${({ $isReply }) => $isReply
    ? ''
    : `
    border-bottom-width: ${(moderateScale(1))}px;
    border-top-width: ${(moderateScale(1))}px;
    height: ${(moderateScale(48))}px;
    justify-content: flex-start;
    margin-bottom: ${(moderateScale(8))}px;
    padding: ${(moderateScale(15))}px ${(moderateScale(24))}px;
  `
};
  border-bottom-color: ${({ $isReply, theme: { colors: { dmiWhite, hoverGray } } }) =>
    $isReply ? dmiWhite : hoverGray};
  border-top-color: ${({ $isReply, theme: { colors: { dmiWhite, hoverGray } } }) =>
    $isReply ? dmiWhite : hoverGray};
  color: ${({ theme: { colors: { primary } } }) => primary};
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const StyledP3 = styled(P3)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const TextWrapper = styled.View`
  opacity: ${({ $disabled }) => $disabled ? 0.5 : 1};
`;
