import styled from 'styled-components/native';
import { WebView } from 'react-native-webview';

export const StyledWebView = styled(WebView)`
  flex: 1;
  height: ${({ $deviceHeight }) => $deviceHeight}px;
  width: ${({ $deviceWidth }) => $deviceWidth}px;
`;

export const WebViewContainer = styled.View`
  flex: 1;
  height: 100%;
`;
