import React from 'react';
import { View } from 'react-native';
import * as WebBrowser from 'expo-web-browser';

import { GENERAL_DOCS_LIST } from '@dmi/shared/redux/Documents/constants';

import { P2, StyledScrollView } from '../../base_ui';
import {
  GeneralDocsContainer,
  PressableRow,
  StyledIllustration,
} from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';

const ArrowIcon = iconDictionary('arrowRightCarbonS');
const Illustration = iconDictionary('formsTownscape', { height: '100%', width: '100%' });

const GeneralDocsView = () => {
  const handleOpenWithWebBrowser = (href) => {
    WebBrowser.openBrowserAsync(href);
  };

  return (
    <StyledScrollView showsVerticalScrollIndicator={false}>
      <GeneralDocsContainer>
        <View>
          { GENERAL_DOCS_LIST.map(({
            documentId,
            documentName,
            documentUrl,
          }) => (
            <PressableRow
              key={documentId}
              aria-label={`open ${documentName} in a new browser window`}
              onPress={() => handleOpenWithWebBrowser(documentUrl)}
              role="button"
            >
              <P2>{documentName}</P2>
              {ArrowIcon}
            </PressableRow>
          ))}
        </View>
        <StyledIllustration>
          {Illustration}
        </StyledIllustration>
      </GeneralDocsContainer>
    </StyledScrollView>
  );
};

export default GeneralDocsView;
