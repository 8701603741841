import styled from 'styled-components/native';

import { H1, MediumPrimaryButton } from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

export const ContentWrapper = styled.View`
  flex-shrink: 1;
  justify-content: space-between;
  max-width: ${moderateScale(207)}px;
`;

export const HelpHomeContainer = styled.View`
  height: 100%;
  width: 100%;
`;

export const IllustrationWrapper = styled.View`
  padding-right: ${moderateScale(20)}px;
`;

export const StyledButton = styled(MediumPrimaryButton)`
  margin-top: ${moderateScale(16)}px;
  max-width: ${moderateScale(173)}px;
`;

export const StyledH1 = styled(H1)`
  padding: 0 ${moderateScale(24)}px;
`;

export const TopCard = styled.View`
  align-self: ${({ $center }) => $center ? 'center' : 'flex-start'};
  align-items: center;
  flex-direction: row;
  padding: ${moderateScale(22)}px ${moderateScale(24)}px ${moderateScale(30)}px;
  width:  ${({ $center }) => $center ? `${moderateScale(375)}px` : '100%'};
`;
