import React, {
  Fragment,
  useEffect,
} from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getMainClientAuthProps, getSsoType } from '@dmi/shared/redux/Main/selectors';
import { SSO_IDP_ERROR_MESSAGE, SSO_LOGIN_ERROR_MESSAGE } from '@dmi/shared/redux/Login/constants';
import { getQueryParam } from '@dmi/shared/utils/globalHelpers';

import LoadingDog from '../../../components/LoadingDog';
import AsyncRender from '../../../components/AsyncRender';
import { StyledScrollView } from '../../../components/base_ui';

const SsoSaml = ({
  idpTargetUrl,
  SAMLRequest,
  ssoRedirectUrl,
  ssoType,
}) => {
  const isSamlIDP = ssoType === 'samlIDP';
  const error = !!getQueryParam('error', null) || isSamlIDP;
  const shouldRedirect = getQueryParam('redirect', null);
  /**
   * TODO (Future Refactor): Currently if user navigates directly to YMO for a
   * samlIDP client, postAuthRequestSaga sets error=49 query param on Desktop but
   * on Mobile doesn't set any query param. Ideally we should refactor Mobile to have
   * error=49 as well to pattern match, though it's not strictly necessary.
   * Currently an undefined error queryParam can be treated the same as error=49.
   */
  const errorCode = getQueryParam('error', null) || '49';

  useEffect(() => {
    if (shouldRedirect && ssoRedirectUrl) {
      window.location.href = ssoRedirectUrl;
    } else if (!error && !shouldRedirect && ssoType === 'samlSP') {
      document.getElementById('idpRequestForm').submit();
    }
  }, [
    error,
    shouldRedirect,
    ssoRedirectUrl,
    ssoType,
  ]);

  return (
    <StyledScrollView>
      <AsyncRender
        Component={() => null}
        error={error}
        errorComponentProps={{
          body: (isSamlIDP && errorCode === '49') ? SSO_IDP_ERROR_MESSAGE : SSO_LOGIN_ERROR_MESSAGE,
          link: {},
          title: "You've encountered an error.", // Matches same title as Desktop
        }}
        loading
        LoadingComponent={(
          <Fragment>
            <LoadingDog />
            <form
              action={idpTargetUrl}
              encType="application/x-www-form-urlencoded"
              id="idpRequestForm"
              method="POST"
            >
              <input name="SAMLRequest" type="hidden" value={SAMLRequest} />
            </form>
          </Fragment>
        )}
      />
    </StyledScrollView>
  );
};

SsoSaml.propTypes = {
  idpTargetUrl: T.string, // Only required for main, non-error case
  SAMLRequest: T.string, // Only required for main, non-error case
  ssoRedirectUrl: T.string, // Only required for a fallback SingleLogout case
  ssoType: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  idpTargetUrl: getMainClientAuthProps('targetUrl'),
  SAMLRequest: getMainClientAuthProps('samlRequest'),
  ssoRedirectUrl: getMainClientAuthProps('ssoRedirectUrl'),
  ssoType: getSsoType(),
});

const withConnect = connect(mapStateToProps, null);

export default compose(withConnect)(SsoSaml);
