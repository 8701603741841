/* eslint-disable max-len */
import React from 'react';
import Svg, { Line, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function TaxesIcon(props) {
  const { colors: { iconStatic } } = useTheme();

  return (
    <Svg
      height={moderateScale(18)}
      viewBox="0 0 18 18"
      width={moderateScale(18)}
      {...props}
    >
      <Path d="M8.24,8.28a1.64,1.64,0,0,1,.43,1.13,1.62,1.62,0,0,1-.44,1.13A1.42,1.42,0,0,1,7.15,11a1.4,1.4,0,0,1-1.07-.47,1.57,1.57,0,0,1-.43-1.12,1.6,1.6,0,0,1,.43-1.13,1.39,1.39,0,0,1,1.08-.47A1.41,1.41,0,0,1,8.24,8.28ZM12.31,8,7.19,15H5.9L11,8ZM7.57,9.93a.86.86,0,0,0,0-1,.55.55,0,0,0-.42-.2.51.51,0,0,0-.4.2.8.8,0,0,0-.16.51.76.76,0,0,0,.17.5.51.51,0,0,0,.41.21A.49.49,0,0,0,7.57,9.93Zm4.64,2.4a1.67,1.67,0,0,1,0,2.25,1.48,1.48,0,0,1-2.15,0,1.6,1.6,0,0,1-.43-1.13,1.57,1.57,0,0,1,.43-1.12,1.39,1.39,0,0,1,1.08-.47A1.41,1.41,0,0,1,12.21,12.33ZM11.56,14a.86.86,0,0,0,.16-.51.78.78,0,0,0-.17-.5.54.54,0,0,0-.42-.21.48.48,0,0,0-.4.21.77.77,0,0,0-.16.5.75.75,0,0,0,.17.51.52.52,0,0,0,.82,0Z" fill="#000" />
      <Path d="M10.7,4.28s5.46,4.81,5.14,8.92-6.13,3.85-6.13,3.85h-1s-5.82.25-6.14-3.85S7.76,4.28,7.76,4.28" fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Path d="M10.65,4l1.6-2.13A.35.35,0,0,0,12,1.31H6.48a.35.35,0,0,0-.27.56L7.82,4" fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" />
      <Line fill="none" stroke={iconStatic} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="7.63" x2="10.81" y1="4.26" y2="4.26" />
    </Svg>
  );
}
