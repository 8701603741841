import React from 'react';
import T from 'prop-types';

import {
  CONSENT_BUTTON_LABEL_DICT,
  CONSENT_MESSAGE_DICT,
} from '@dmi/shared/redux/Register/Main/constants';

import { FormattedMessage } from '../base_ui';
import { StyledButton } from './styledComponents';

const ConsentFormattedMessage = ({ pressHandler, textId, WrapperComponent }) => {
  const defaultMessage = CONSENT_MESSAGE_DICT[textId];

  return (
    <FormattedMessage
      defaultMessage={defaultMessage}
      values={{
        // eslint-disable-next-line react/prop-types
        button: ({ msg: buttonId }) => {
          const buttonLabel = CONSENT_BUTTON_LABEL_DICT[buttonId];
          return (
            <StyledButton
              onPress={() => pressHandler(buttonId)}
              role="button"
            >
              {buttonLabel}
            </StyledButton>
          );
        },
      }}
      WrapperComponent={WrapperComponent}
    />
  );
};

ConsentFormattedMessage.propTypes = {
  pressHandler: T.func.isRequired,
  textId: T.string.isRequired,
  WrapperComponent: T.elementType.isRequired,
};

export default ConsentFormattedMessage;
