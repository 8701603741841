import React from 'react';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';

import { StyledAnimation } from '../styledComponents';
import {
  ConfirmationContent,
  EditEmailContainer,
  StyledH2,
} from './styledComponents';

const ConfirmationComponent = () => {
  const { subheader } = PROFILE_CONTENT_DICTIONARY.editEmail.confirmationView.mobile;

  return (
    <EditEmailContainer $isConfirmation>
      <StyledAnimation name="robotMobile" />
      <ConfirmationContent>
        <StyledH2>{subheader}</StyledH2>
      </ConfirmationContent>
    </EditEmailContainer>
  );
};

export default ConfirmationComponent;
