import React, { Fragment } from 'react';
import T from 'prop-types';
import { FlatList } from 'react-native';
import { useIntl } from 'react-intl';

import { ConditionalRender } from '../../../../base_ui';
import Item from './Item';
import MegaphoneLg from '../../../../../utils/svg/Icons/Detailed-Icons/megaphone-85x85';
import Message from '../../../../Message';
import { StyledMessageText, StyledMessageView } from './styledComponents';
import { noDataMessageProps } from './constants';

const InboxList = ({
  dispatchChangeSelectedMessageId,
  messages,
  navigation,
}) => {
  const { formatMessage } = useIntl();

  const { body, ...restNoDataMessageProps } = noDataMessageProps;
  const handleRowPress = (messageId) => {
    dispatchChangeSelectedMessageId({ messageId });
    navigation.navigate('MessageDetails');
  };

  const renderItem = ({ item }) => (
    <Item handleRowPress={handleRowPress} isSkeletonData={false} {...item} />
  );

  const MessageView = () => {
    if (messages.length > 0) {
      return (
        <StyledMessageView>
          <MegaphoneLg />
          <StyledMessageText>Send and receive secure messages here.</StyledMessageText>
          <StyledMessageText>All inquiries will be reviewed as soon as possible.</StyledMessageText>
        </StyledMessageView>
      );
    }
    return null;
  };

  return (
    <Fragment>
      <MessageView />
      <ConditionalRender
        Component={FlatList}
        FallbackComponent={Message}
        propsToPassDown={{
          body: formatMessage({ id: body }),
          data: messages,
          keyExtractor: ({ messageId }, index) => `${messageId}-${index}`,
          onEndReachedThreshold: 0.3,
          renderItem,
          showsHorizontalScrollIndicator: true,
          showsVerticalScrollIndicator: true,
          ...restNoDataMessageProps,
        }}
        shouldRender={!!messages.length}
      />
    </Fragment>
  );
};

InboxList.propTypes = {
  dispatchChangeSelectedMessageId: T.func.isRequired,
  messages: T.arrayOf(T.shape({
    author: T.string,
    created: T.string,
    messageId: T.string,
    subject: T.string,
  })).isRequired,
  navigation: T.object.isRequired,
};

export default InboxList;
