/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../scaleHelpers';

export default function IconArrowRightPurple(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 16 18" width={moderateScale(16)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M8 1L15.1111 9L8 17" stroke={primary} strokeLinecap="round" />
    </Svg>
  );
}
