import styled from 'styled-components/native';

import { H4 } from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

export const AppleButton = styled.Pressable`
  height: ${moderateScale(41)}px;
  width: ${moderateScale(123)}px;
`;

export const ContentWrapper = styled.View`
  align-items: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(10)}px;
  padding: ${moderateScale(10)}px;
`;

export const GoogleButton = styled.Pressable`
  height: ${moderateScale(41)}px;
  margin-left: ${moderateScale(10)}px;
  width: ${moderateScale(138)}px;
`;

export const StoreLinksContainer = styled.View`
  padding-vertical: ${moderateScale(10)}px;
`;

export const StyledImage = styled.Image`
  flex: 1;
`;

export const Title = styled(H4)`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
  text-align: center;
`;
