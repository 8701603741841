import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Payments/messages';

import iconDictionary from '../../../../utils/iconDictionary';
import { P4 } from '../../../base_ui';
import {
  HeaderContainer,
  HeaderContent,
  SmallHeader,
  StyledCircle,
  TextWrapper,
} from './styledComponents';

const LoanDetailIcon = iconDictionary('loanDetail');

const SchedulePaymentHeader = ({ address, dueDate, loanNumber }) => (
  <HeaderContainer>
    {LoanDetailIcon}
    <HeaderContent>
      <SmallHeader>{address}</SmallHeader>
      <TextWrapper>
        <P4>
          <FormattedMessage id={INTL_IDS.HOME_CARD_LOAN} /> (...{loanNumber.slice(-4)})
        </P4>
        <StyledCircle>&#9679;</StyledCircle>
        <P4>Due {dueDate}</P4>
      </TextWrapper>
    </HeaderContent>
  </HeaderContainer>
);

SchedulePaymentHeader.propTypes = {
  address: T.string.isRequired,
  dueDate: T.string.isRequired,
  loanNumber: T.string.isRequired,
};

export default SchedulePaymentHeader;
