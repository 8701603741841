import React from 'react';
import styled from 'styled-components/native';
import {
  Platform,
  Text,
  View,
} from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { getFontSize } from './helpers';

export const ContainerWrapper = styled(View)`
  align-items: flex-start;
  display: flex;
  justify-content: center;
`;

export const DisclosureLinkContainer = styled.Text`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const DisclosureStyledText = styled.Text`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  ${({ $hasUnderline }) => $hasUnderline ? 'text-decoration: underline;' : ''}
  ${Platform.OS === 'web' ? 'display: inline;' : ''}
`;

export const LinkContainer = styled.Pressable`
  align-items: center;
  background-color: ${({
    isFooterLink,
    theme: { colors: { dmiWhite, footerBackground } },
  }) => isFooterLink ? footerBackground : dmiWhite};
  display: flex;
  flex-direction: ${({ isRightIcon }) => isRightIcon ? 'row-reverse' : 'row'};
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
  padding: 0;
`;

export const StyledIcon = styled(({ Icon, ...restProps }) => (
  <View {...restProps}>{Icon}</View>
))`
  padding: ${({ isRightIcon }) => isRightIcon ? '0 0 0 4px' : '0 4px 0 0'};
`;

export const StyledText = styled(Text)`
  color: ${({
    isFooterLink,
    theme: { colors: { footerLinkText, linkText } },
  }) => isFooterLink ? footerLinkText || linkText : linkText};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ size, theme: { fontSizes } }) => getFontSize(size, fontSizes)}px;
  line-height: ${({ size }) =>
    size === 'small' ? `${moderateScale(18)}px` : `${moderateScale(22)}px`};
`;
