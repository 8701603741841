import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { NestedStyledScrollView as ScrollView } from '../../../base_ui';

export const IconWrapper = styled.View`
  margin-right: ${(moderateScale(20))}px;
`;

export const PressableRow = styled.Pressable`
  align-items: center;
  border-bottom-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-bottom-width: ${(moderateScale(1))}px;
  flex-direction: row;
  height: ${(moderateScale(73))}px;
  justify-content: flex-start;
  padding: ${(moderateScale(14))}px ${(moderateScale(24))}px;
`;

export const StyledScrollView = styled(ScrollView)`
  border-top-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-top-width: ${(moderateScale(1))}px;
`;
