import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  getSelectedLoanAddress,
  getSelectedLoanMaskedLoanNumber,
} from '@dmi/shared/redux/Main/selectors';
import {
  changeScheduledDate,
  changeScheduledDateOnDeck,
  resetBannerError,
  resetConfirmation,
  resetOneTimePaymentState,
  setIsModalOpen,
} from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import { resetState as resetSources } from '@dmi/shared/redux/Payments/Sources/actions';
import makeSelectOtp, {
  selectFormattedBannerError,
  selectIsModalOpen,
  selectOtp,
  selectOtpDueDate,
  selectOtpRequestStatus,
  selectOtpScheduledDate,
  selectOtpServiceError,
  selectTotalPayment,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';
import { makeSelectPayFromButtonProps } from '@dmi/shared/redux/Payments/Sources/selectors';

import AsyncRender from '../../../components/AsyncRender';
import { SchedulePayment } from '../../../components/Payments/OneTimePayment';

const SchedulePaymentScreen = ({
  address,
  bannerError,
  dispatchChangeScheduledDate,
  dispatchChangeScheduledDateOnDeck,
  dispatchResetBannerError,
  dispatchResetConfirmation,
  dispatchResetOneTimePaymentState,
  dispatchResetSources,
  dispatchSetIsModalOpen,
  dueDate,
  isInPaymentFlow,
  isWarningModalOpen,
  maskedLoanNumber,
  navigation,
  payFromButtonProps,
  paymentHomeError,
  paymentHomeLoading,
  route,
  scheduledDate,
  scheduledDateOnDeck,
  totalPayment,
}) => {
  useEffect(() => {
    dispatchResetConfirmation();
    return () => {
      dispatchResetSources();
      dispatchResetOneTimePaymentState();
    };
  }, [dispatchResetConfirmation, dispatchResetOneTimePaymentState, dispatchResetSources]);

  useEffect(() => {
    if (!isInPaymentFlow && ['idle', 'loading'].includes(paymentHomeLoading)) {
      navigation.navigate('Payments');
    }
  }, [isInPaymentFlow, navigation, paymentHomeLoading]);

  return (
    <AsyncRender
      Component={SchedulePayment}
      error={paymentHomeError}
      loading={['idle', 'loading'].includes(paymentHomeLoading)}
      propsToPassDown={{
        address,
        bannerError,
        dispatchChangeScheduledDate,
        dispatchChangeScheduledDateOnDeck,
        dispatchResetBannerError,
        dispatchSetIsModalOpen,
        dueDate,
        isWarningModalOpen,
        loanNumber: maskedLoanNumber,
        navigation,
        payFromButtonProps,
        route,
        scheduledDate,
        scheduledDateOnDeck,
        totalPayment,
      }}
    />
  );
};

SchedulePaymentScreen.propTypes = {
  address: T.string.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeScheduledDate: T.func.isRequired,
  dispatchChangeScheduledDateOnDeck: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetConfirmation: T.func.isRequired,
  dispatchResetOneTimePaymentState: T.func.isRequired,
  dispatchResetSources: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  dueDate: T.string.isRequired,
  isInPaymentFlow: T.bool.isRequired,
  isWarningModalOpen: T.bool.isRequired,
  maskedLoanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  payFromButtonProps: T.object.isRequired,
  paymentHomeError: T.bool.isRequired,
  paymentHomeLoading: T.string.isRequired,
  route: T.object.isRequired,
  scheduledDate: T.string.isRequired,
  scheduledDateOnDeck: T.string.isRequired,
  totalPayment: T.string.isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  address: getSelectedLoanAddress(),
  maskedLoanNumber: getSelectedLoanMaskedLoanNumber(),
  /**
   * Store: One Time Payment
   */
  bannerError: selectFormattedBannerError(),
  dueDate: selectOtpDueDate(),
  isInPaymentFlow: makeSelectOtp('isInPaymentFlow'),
  isWarningModalOpen: selectIsModalOpen('latePaymentWarning'),
  paymentHomeError: selectOtpServiceError('paymentHome'),
  paymentHomeLoading: selectOtpRequestStatus('paymentHome'),
  scheduledDate: selectOtpScheduledDate(),
  scheduledDateOnDeck: selectOtp('scheduledDateOnDeck'),
  totalPayment: selectTotalPayment(),
  /**
   * Store: Payment Sources
   */
  payFromButtonProps: makeSelectPayFromButtonProps('otp'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: One Time Payment
   */
  dispatchChangeScheduledDate: (...args) => dispatch(changeScheduledDate(...args)),
  dispatchChangeScheduledDateOnDeck: (payload) => dispatch(changeScheduledDateOnDeck(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetConfirmation: () => dispatch(resetConfirmation()),
  dispatchResetOneTimePaymentState: () => dispatch(resetOneTimePaymentState()),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
  /**
   * Store: Payment Sources
   */
  dispatchResetSources: () => dispatch(resetSources()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SchedulePaymentScreen);
