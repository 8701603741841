/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState } from 'react';
import T from 'prop-types';

/* eslint-disable object-curly-newline */
import {
  SHARED_USERNAME_AND_PASSWORD_TOOLTIP_DATA,
} from '@dmi/shared/redux/Register/Main/constants';

import { ConditionalRender, Tooltip } from '../../base_ui';
import { TooltipLink } from './styledComponents';
import TooltipContentComponent from './TooltipContentComponent';

const PasswordTooltip = ({ displayUsernameCriteria }) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { passwordLabel, usernameAndPasswordLabel } = SHARED_USERNAME_AND_PASSWORD_TOOLTIP_DATA;
  const labelToDisplay = displayUsernameCriteria ? usernameAndPasswordLabel : passwordLabel;

  return (
    <Fragment>
      <TooltipLink onPress={() => setIsTooltipOpen(true)}>
        {labelToDisplay}
      </TooltipLink>
      <ConditionalRender
        Component={Tooltip}
        propsToPassDown={{
          children: (
            <TooltipContentComponent
              displayUsernameCriteria={displayUsernameCriteria}
            />),
          handleCloseModal: () => setIsTooltipOpen(false),
          positionAbsolute: { top: 200 },
          visible: isTooltipOpen,
        }}
        shouldRender={isTooltipOpen}
      />
    </Fragment>
  );
};

PasswordTooltip.propTypes = { displayUsernameCriteria: T.bool };

export default PasswordTooltip;
