import styled from 'styled-components/native';

import { H5, P2 } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';

export const Container = styled.View`
  align-items: center;
  flex-direction: row;
  height: ${moderateScale(55)}px;
  justify-content: space-between;
  padding: 0 ${moderateScale(25)}px;
`;

export const Label = styled(H5)`
  flex-shrink: 1;
  opacity: ${({ $disabled }) => $disabled ? 0.5 : 1};
  padding: 0 ${moderateScale(8)}px;
  padding-left: ${({ $visible }) => $visible ? `${moderateScale(18)}px` : `${moderateScale(51)}px`}
`;

export const StyledP2 = styled(P2)`
  color: ${({
    $disabled,
    theme: { colors: { primaryCarbon, primaryGray } },
  }) => $disabled ? primaryGray : primaryCarbon};
`;

export const ToggleContainer = styled.View`
  align-items: center;
  flex-direction: row;
  flex-shrink: 1;
  padding-right: ${moderateScale(24)}px;
`;
