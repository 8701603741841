import React from 'react';
import T from 'prop-types';

// eslint-disable-next-line max-len
import { LATE_PAYMENT_WARNING_MODAL_PROPS } from '@dmi/shared/redux/Payments/OneTimePayment/constants';

import iconDictionary from '../../../../../utils/iconDictionary';

import {
  BackButton,
  Container,
  Header,
  Modal,
  StyledPrimaryButton,
  Text,
} from './styledComponents';

const Icon = iconDictionary('hardshipWarning');

const LatePaymentWarningModal = ({
  handleCloseModal,
  handleOnPress,
  isOpen,
}) => {
  const {
    header,
    proceedLabel,
    text,
    updateLabel,
  } = LATE_PAYMENT_WARNING_MODAL_PROPS;

  return (
    <Modal visible={isOpen}>
      <Container>
        {Icon}
        <Header>{header}</Header>
        <Text>{text}</Text>
        <StyledPrimaryButton label={updateLabel} onPress={handleOnPress} />
        <BackButton label={proceedLabel} onPress={handleCloseModal} />
      </Container>
    </Modal>
  );
};

LatePaymentWarningModal.propTypes = {
  handleCloseModal: T.func.isRequired,
  handleOnPress: T.func.isRequired,
  isOpen: T.bool.isRequired,
};

export default LatePaymentWarningModal;
