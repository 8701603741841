/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function OutlinedHouseWinter(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg enableBackground="new 0 0 96 40" height={moderateScale(40)} id="Layer_1" version="1.1" viewBox="0 0 96 40" width={moderateScale(96)} x="0px" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" y="0px" {...props}>
      <G>
        <Path d="M18.262,39.076c0,0-11.641-2.584-11.718-11.975s9.733-13.4,13.565-14.889 c4.189-1.628,6.032-1.246,11.112-6.172S43.537-0.734,50.08,0.344S60.548,6.04,66.244,7.733s20.424,4.829,20.578,16.914 S75.684,38.614,73.298,38.999C70.912,39.383,18.262,39.076,18.262,39.076z" fill="#E6EBEF" />
        <Polygon fill="#BAC2CA" points="61.458,23.844 64.056,23.844 61.356,20.698" />
        <Polyline fill="#FFFFFF" points="29.245,39.064 29.245,20.561 48.45,20.561 48.45,39.064" />
        <Rect fill="#BAC2CA" height="7.168" width="3.743" x="32.463" y="27.67" />
        <Polygon fill="#BAC2CA" points="61.356,39.007 48.569,39.007 48.569,13.797 55.151,13.797 61.356,20.698" />
        <Rect fill={svgHighlight} height="11.339" width="4.59" x="39.61" y="27.51" />
        <Polygon fill="#FFFFFF" points="45.779,23.763 26.186,23.763 35.53,13.651 55.124,13.651" />
        <Path d="M45.779,24.04H26.186c-0.11,0-0.21-0.065-0.254-0.166s-0.024-0.219,0.051-0.3l9.345-10.112 c0.052-0.056,0.126-0.089,0.204-0.089h6.145c0.153,0,0.277,0.124,0.277,0.277s-0.124,0.277-0.277,0.277h-6.024l-8.832,9.558h18.838 l8.832-9.558H43.708c-0.153,0-0.277-0.124-0.277-0.277s0.124-0.277,0.277-0.277h11.416c0.11,0,0.21,0.065,0.254,0.166 c0.044,0.101,0.025,0.219-0.051,0.3l-9.345,10.112C45.93,24.008,45.856,24.04,45.779,24.04z" fill="#000000" />
        <Path d="M32.338,23.485v2.239c0,0.296-0.223,0.536-0.498,0.536l0,0c-0.275,0-0.498-0.24-0.498-0.536v-0.598 c0-0.272-0.205-0.493-0.459-0.493l0,0c-0.253,0-0.459,0.221-0.459,0.493v2.623c0,0.308-0.233,0.559-0.52,0.559l0,0 c-0.287,0-0.52-0.25-0.52-0.559v-4.366" fill="#FFFFFF" />
        <Path d="M63.989,24.04h-1.786c-0.153,0-0.277-0.124-0.277-0.277s0.124-0.277,0.277-0.277h1.174l-8.453-9.653 c-0.101-0.115-0.089-0.29,0.026-0.391s0.29-0.089,0.391,0.026l8.856,10.112c0.071,0.082,0.089,0.198,0.044,0.297 C64.196,23.977,64.098,24.04,63.989,24.04z" fill="#000000" />
        <Path d="M61.353,39.284H29.175c-0.153,0-0.277-0.124-0.277-0.277V24.102c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277 V38.73h31.624v-17.9c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277v18.177C61.63,39.16,61.506,39.284,61.353,39.284z" fill="#000000" />
        <Path d="M23.534,39.268c0,0,2.271-0.539,3.31-0.885s2.694-0.346,3.618-0.038c0.924,0.308,2.29,0.731,3.445,0.789 v0.52H23.592L23.534,39.268z" fill="#FFFFFF" />
        <Path d="M48.548,38.98c-0.102,0-0.185-0.083-0.185-0.185v-4.772c0-0.102,0.083-0.185,0.185-0.185s0.185,0.083,0.185,0.185v4.772 C48.732,38.897,48.65,38.98,48.548,38.98z" fill="#000000" />
        <Path d="M48.548,32.311c-0.102,0-0.185-0.083-0.185-0.185V22.76c0-0.102,0.083-0.185,0.185-0.185s0.185,0.083,0.185,0.185v9.366 C48.732,32.228,48.65,32.311,48.548,32.311z" fill="#000000" />
        <Path d="M44.216,39.083c-0.133,0-0.24-0.108-0.24-0.24V27.79h-4.133v9.829c0,0.133-0.108,0.24-0.24,0.24 c-0.133,0-0.24-0.108-0.24-0.24V27.55c0-0.133,0.108-0.24,0.24-0.24h4.614c0.133,0,0.24,0.108,0.24,0.24v11.293 C44.457,38.976,44.349,39.083,44.216,39.083z" fill="#000000" />
        <Path d="M36.423,34.892h-4.082c-0.133,0-0.24-0.108-0.24-0.24v-7.054c0-0.133,0.108-0.24,0.24-0.24h4.082 c0.133,0,0.24,0.108,0.24,0.24v7.054C36.663,34.784,36.556,34.892,36.423,34.892z M32.581,34.411h3.601v-6.574h-3.601V34.411z" fill="#000000" />
        <G>
          <Ellipse cx="55.36" cy="21.425" fill="#E6EBEF" rx="1.163" ry="1.68" />
          <Path d="M55.36,23.345c-0.787,0-1.404-0.844-1.404-1.921c0-1.059,0.63-1.921,1.404-1.921s1.404,0.862,1.404,1.921 C56.764,22.502,56.147,23.345,55.36,23.345z M55.36,19.985c-0.5,0-0.923,0.659-0.923,1.44s0.423,1.44,0.923,1.44 s0.923-0.659,0.923-1.44S55.86,19.985,55.36,19.985z" fill="#000000" />
        </G>
        <Path d="M55.36,23.29c-0.743,0-1.348-0.837-1.348-1.865s0.605-1.865,1.348-1.865s1.348,0.837,1.348,1.865S56.103,23.29,55.36,23.29 z M55.36,19.929c-0.53,0-0.979,0.685-0.979,1.495c0,0.825,0.439,1.495,0.979,1.495s0.979-0.671,0.979-1.495 C56.339,20.614,55.89,19.929,55.36,19.929z" fill="#000000" />
        <Path d="M80.659,39.308h-3.241c-0.153,0-0.277-0.124-0.277-0.277s0.124-0.277,0.277-0.277h3.241c0.153,0,0.277,0.124,0.277,0.277 S80.812,39.308,80.659,39.308z" fill="#000000" />
        <Path d="M75.193,39.308H33.442c-0.471,0-0.94-0.066-1.397-0.196l-2.174-0.62c-0.826-0.235-1.7-0.232-2.525,0.01L25.308,39.1 c-0.468,0.138-0.952,0.207-1.436,0.207h-7.315c-0.153,0-0.277-0.124-0.277-0.277s0.124-0.277,0.277-0.277h7.315 c0.432,0,0.863-0.062,1.28-0.185l2.037-0.598c0.926-0.273,1.907-0.277,2.834-0.012l2.174,0.62c0.407,0.115,0.826,0.174,1.245,0.174 h41.751c0.153,0,0.277,0.124,0.277,0.277S75.346,39.308,75.193,39.308z" fill="#000000" />
        <Path d="M14.704,39.401h-1.148c-0.204,0-0.37-0.165-0.37-0.37c0-0.204,0.165-0.37,0.37-0.37h1.148c0.204,0,0.37,0.165,0.37,0.37 C15.073,39.235,14.908,39.401,14.704,39.401z" fill="#000000" />
        <Path d="M72.637,39.391c-0.153,0-0.277-0.124-0.277-0.277V28.369c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277v10.745 C72.914,39.266,72.79,39.391,72.637,39.391z" fill="#000000" />
        <Polygon fill="#BAC2CA" points="79.896,35.364 77.912,29.123 79.196,29.123 76.454,21.832 77.796,21.832 72.779,9.349 72.486,9.349 67.469,21.832 68.811,21.832 66.07,29.123 67.353,29.123 65.37,35.364" />
        <Path d="M71.027,12.778c0,0,1.12,2.595,4.492,3.543l-2.74-6.972c0,0-0.312-0.023-0.346,0.069 C72.399,9.509,71.027,12.778,71.027,12.778z" fill="#FFFFFF" />
        <Path d="M66.307,28.598c0,0,0.892-1.006,2.469-0.96c1.577,0.046,1.6,1.029,3.223,0.503s2.583-0.183,3.086,0.114 s1.394,0.732,1.394,0.732H66.147L66.307,28.598z" fill="#FFFFFF" />
        <Path d="M79.574,34.762c0,0-1.225-1.382-3.392-1.319s-2.199,1.413-4.428,0.691s-3.549-0.251-4.24,0.157 c-0.691,0.408-1.916,1.005-1.916,1.005h14.196L79.574,34.762z" fill="#FFFFFF" />
        <Path d="M77.691,21.808h-6.858c0,0,1.486-0.16,2.423-0.48s1.44-0.709,2.469-0.549s1.829,0.617,1.829,0.617 L77.691,21.808z" fill="#FFFFFF" />
        <Path d="M79.895,35.641H65.37c-0.088,0-0.172-0.042-0.224-0.113s-0.067-0.164-0.04-0.248l1.868-5.88H66.07 c-0.091,0-0.176-0.045-0.228-0.119c-0.051-0.075-0.064-0.17-0.031-0.256l2.6-6.917H67.47c-0.092,0-0.178-0.045-0.23-0.122 s-0.062-0.173-0.027-0.258l5.016-12.482c0.042-0.105,0.144-0.174,0.257-0.174h0.294c0.113,0,0.215,0.069,0.257,0.174l5.016,12.482 c0.035,0.085,0.025,0.182-0.027,0.258c-0.051,0.077-0.137,0.122-0.23,0.122h-0.941l2.6,6.917c0.032,0.085,0.02,0.18-0.031,0.256 c-0.052,0.074-0.137,0.119-0.228,0.119h-0.904l1.868,5.88c0.027,0.084,0.012,0.176-0.04,0.248 C80.067,35.599,79.983,35.641,79.895,35.641z M65.749,35.087h13.767l-1.868-5.88c-0.027-0.084-0.012-0.176,0.04-0.248 c0.052-0.071,0.136-0.113,0.224-0.113h0.883l-2.6-6.917c-0.032-0.085-0.02-0.18,0.031-0.256c0.052-0.074,0.137-0.119,0.228-0.119 h0.932L72.632,9.727l-4.753,11.827h0.932c0.091,0,0.176,0.045,0.228,0.119c0.051,0.075,0.064,0.17,0.031,0.256l-2.6,6.917h0.883 c0.088,0,0.172,0.042,0.224,0.113c0.052,0.071,0.067,0.164,0.04,0.248L65.749,35.087z" fill="#000000" />
        <Path d="M76.496,21.978h-3.257c-0.102,0-0.185-0.083-0.185-0.185s0.083-0.185,0.185-0.185h3.257c0.102,0,0.185,0.083,0.185,0.185 S76.598,21.978,76.496,21.978z" fill="#000000" />
        <Path d="M70.568,29.268h-3.257c-0.102,0-0.185-0.083-0.185-0.185s0.083-0.185,0.185-0.185h3.257c0.102,0,0.185,0.083,0.185,0.185 C70.753,29.185,70.67,29.268,70.568,29.268z" fill="#000000" />
        <Path d="M73.119,29.268h-1.962c-0.102,0-0.185-0.083-0.185-0.185s0.083-0.185,0.185-0.185h1.962c0.102,0,0.185,0.083,0.185,0.185 S73.221,29.268,73.119,29.268z" fill="#000000" />
        <Path d="M73.042,17.31L73.042,17.31c-0.34,0-0.616-0.276-0.616-0.616v-2.571h1.233v2.571 C73.658,17.034,73.382,17.31,73.042,17.31z" fill="#FFFFFF" />
        <Path d="M20.173,35.338L20.173,35.338c-2.125,0-3.848-1.723-3.848-3.848V20.756c0-2.125,1.723-3.848,3.848-3.848 l0,0c2.125,0,3.848,1.723,3.848,3.848V31.49C24.02,33.615,22.298,35.338,20.173,35.338z" fill="#E6EBEF" />
        <Path d="M21.968,17.354c0.458,0.633,0.731,1.409,0.731,2.25v10.734c0,2.125-1.723,3.848-3.848,3.848l0,0 c-0.649,0-1.259-0.162-1.796-0.446c0.699,0.966,1.833,1.598,3.117,1.598c2.125,0,3.848-1.723,3.848-3.848V20.756 C24.02,19.28,23.188,17.999,21.968,17.354z" fill="#BAC2CA" />
        <Path d="M20.173,35.615c-2.275,0-4.125-1.851-4.125-4.125V20.756c0-2.274,1.851-4.125,4.125-4.125s4.125,1.851,4.125,4.125V31.49 C24.297,33.765,22.447,35.615,20.173,35.615z M20.173,17.185c-1.969,0-3.571,1.602-3.571,3.571V31.49 c0,1.969,1.602,3.571,3.571,3.571s3.571-1.602,3.571-3.571V20.756C23.743,18.787,22.142,17.185,20.173,17.185z" fill="#000000" />
        <Path d="M20.117,39.072c-0.153,0-0.277-0.124-0.277-0.277V26.446c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277v12.349 C20.394,38.948,20.27,39.072,20.117,39.072z" fill="#000000" />
        <Path d="M20.069,31.559c-0.056,0-0.112-0.017-0.161-0.051l-1.901-1.354c-0.125-0.089-0.154-0.262-0.065-0.387 c0.089-0.125,0.261-0.154,0.387-0.065l1.901,1.354c0.125,0.089,0.154,0.262,0.065,0.387C20.241,31.518,20.156,31.559,20.069,31.559 z" fill="#000000" />
        <Path d="M44.125,39.518c0,0,5.306-2.031,9-2.415c3.694-0.384,7.195-0.624,12.136,0.624 c4.941,1.247,8.249,1.642,8.249,1.642" fill="#FFFFFF" />
        <Path d="M45.345,39.14c0,0,5.412,0.174,8.046-0.426c2.633-0.6,6.154-1.973,15.287-0.107c0,0-7.966-2.333-12.3-1.8 C52.045,37.34,47.945,37.807,45.345,39.14z" fill="#DCE2E7" />
        <Path d="M45.079,39.351c-0.113,0-0.219-0.07-0.261-0.183c-0.052-0.144,0.022-0.302,0.166-0.355 c0.044-0.016,4.428-1.602,8.112-1.986c3.774-0.391,7.298-0.615,12.233,0.631c4.84,1.222,7.016,1.286,7.038,1.287 c0.153,0.004,0.274,0.131,0.271,0.284c-0.004,0.153-0.12,0.271-0.284,0.271c-0.089-0.002-2.246-0.064-7.161-1.304 c-4.849-1.223-8.322-1.003-12.04-0.616c-3.616,0.376-7.937,1.939-7.98,1.955C45.142,39.346,45.11,39.351,45.079,39.351z" fill="#000000" />
        <Path d="M44.89,23.064v1.913c0,0.32-0.259,0.579-0.579,0.579l0,0c-0.32,0-0.579-0.259-0.579-0.579v-0.025 c0-0.308-0.25-0.558-0.558-0.558l0,0c-0.308,0-0.558,0.25-0.558,0.558v0.683c0,0.329-0.267,0.596-0.596,0.596l0,0 c-0.329,0-0.596-0.267-0.596-0.596V24.63c0-0.307-0.249-0.556-0.556-0.556l0,0c-0.307,0-0.556,0.249-0.556,0.556v0.253 c0,0.316-0.257,0.573-0.573,0.573l0,0c-0.316,0-0.573-0.257-0.573-0.573v-1.764" fill="#FFFFFF" />
        <Path d="M42.019,26.508c-0.481,0-0.873-0.392-0.873-0.874v-1.005c0-0.154-0.125-0.279-0.279-0.279s-0.279,0.126-0.279,0.279v0.253 c0,0.469-0.381,0.85-0.85,0.85c-0.469,0-0.85-0.381-0.85-0.85v-1.154c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277 v1.154c0,0.163,0.133,0.296,0.296,0.296s0.296-0.133,0.296-0.296v-0.253c0-0.46,0.374-0.834,0.833-0.834S41.7,24.17,41.7,24.629 v1.005c0,0.176,0.143,0.32,0.319,0.32s0.319-0.144,0.319-0.32v-0.682c0-0.461,0.375-0.836,0.835-0.836s0.835,0.375,0.835,0.836 c0,0.191,0.135,0.327,0.302,0.327s0.302-0.136,0.302-0.302V23.83c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277v1.148 c0,0.472-0.384,0.856-0.857,0.856c-0.472,0-0.856-0.384-0.856-0.856c0-0.18-0.126-0.307-0.281-0.307s-0.281,0.126-0.281,0.281 v0.682C42.892,26.116,42.5,26.508,42.019,26.508z" fill="#000000" />
        <Path d="M45.241,24.045h-1.039c-0.153,0-0.277-0.124-0.277-0.277s0.124-0.277,0.277-0.277h1.039c0.153,0,0.277,0.124,0.277,0.277 S45.394,24.045,45.241,24.045z" fill="#000000" />
        <Path d="M29.731,28.812c-0.461,0-0.836-0.375-0.836-0.836v-0.598c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277v0.598 c0,0.155,0.126,0.281,0.281,0.281s0.281-0.126,0.281-0.281v-2.622c0-0.425,0.346-0.771,0.77-0.771c0.425,0,0.77,0.346,0.77,0.771 v0.598c0,0.142,0.116,0.258,0.259,0.258s0.258-0.116,0.258-0.258v-2.24c0-0.153,0.124-0.277,0.277-0.277s0.277,0.124,0.277,0.277 v2.24c0,0.448-0.364,0.813-0.813,0.813s-0.813-0.364-0.813-0.813v-0.598c0-0.119-0.097-0.217-0.216-0.217s-0.216,0.097-0.216,0.217 v2.622C30.567,28.437,30.192,28.812,29.731,28.812z" fill="#000000" />
        <Circle cx="49.143" cy="4.99" fill="#FFFFFF" r="1.238" />
        <Circle cx="23.656" cy="13.78" fill="#FFFFFF" r="1.238" />
        <Circle cx="36.467" cy="6.863" fill="#FFFFFF" r="0.707" />
        <Circle cx="63.862" cy="16.46" fill="#FFFFFF" r="0.707" />
        <Circle cx="28.646" cy="16.518" fill="#FFFFFF" r="0.707" />
        <Circle cx="63.688" cy="28.237" fill="#FFFFFF" r="0.707" />
        <Circle cx="14.098" cy="25.813" fill="#FFFFFF" r="0.707" />
        <Circle cx="26.517" cy="27.741" fill="#FFFFFF" r="0.707" />
        <Circle cx="42.506" cy="6.158" fill="#FFFFFF" r="0.52" />
        <Circle cx="79.109" cy="18.216" fill="#FFFFFF" r="0.719" />
        <Circle cx="45.681" cy="9.684" fill="#FFFFFF" r="0.9" />
        <Circle cx="60.347" cy="10.018" fill="#FFFFFF" r="0.9" />
        <Circle cx="51.639" cy="25.733" fill="#FFFFFF" r="0.695" />
        <Circle cx="73.519" cy="32.084" fill="#FFFFFF" r="0.695" />
        <Circle cx="56.936" cy="30.05" fill="#FFFFFF" r="1.094" />
        <Circle cx="52.669" cy="33.341" fill="#FFFFFF" r="0.511" />
        <Circle cx="71.374" cy="24.912" fill="#FFFFFF" r="0.511" />
      </G>
    </Svg>
  );
}
