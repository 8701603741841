import styled from 'styled-components/native';

import { H5, P2 } from '../../../../base_ui';
import { moderateScale } from '../../../../../utils/scaleHelpers';

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const LinkText = styled(P2)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  padding-top: ${moderateScale(4)}px;
`;

export const NoteContainer = styled.View`
  padding-vertical: ${moderateScale(10)}px;
`;

export const StyledH5 = styled(H5)`
  padding-bottom: ${moderateScale(4)}px;
`;
