import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import ErrorMessage from '../../ErrorMessage';

const ErrorPorchlight = iconDictionary('errorPorchlight');

const DisabledFeature = ({ customerServicePhoneNumber, navigation }) => (
  <View>
    <ErrorMessage
      body={`For assistance, contact ${customerServicePhoneNumber}.`}
      icon={ErrorPorchlight}
      link={{
        label: 'Return to Settings',
        onPress: () => navigation.navigate('Settings'),
      }}
      title="Feature coming soon"
    />
  </View>
);

DisabledFeature.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  navigation: T.object.isRequired,
};

export default DisabledFeature;
