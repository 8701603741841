import styled from 'styled-components/native';

import { H5 } from '../../Heading';
import { BorderlessButton } from '../../Buttons';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  margin-bottom: ${({ $hasError }) => $hasError ?
    `${moderateScale(9)}px;` :
    `${moderateScale(25)}px;`};
  padding: ${moderateScale(8)}px ${moderateScale(15)}px;
`;

export const BoldText = styled(H5)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  margin-bottom: ${moderateScale(3)}px;
`;

export const IllustrationWrapper = styled.View`
  justify-content: center;
  margin-right: ${moderateScale(10)}px;
`;

export const MessageContainer = styled.View`
  align-self: center;
  flex: 1;
`;

export const StyledTextButton = styled(BorderlessButton).attrs(({
  theme: { colors: { primary }, fontFamilies: { regular }, fontSizes: { f3 } },
  titleStyle,
}) => ({
  titleStyle: [...titleStyle, {
    color: primary,
    fontFamily: regular,
    fontSize: f3,
    paddingVertical: 5,
  }],
}))`
  align-items: baseline;
`;
