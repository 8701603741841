/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

const PaymentWarningL = (props) => {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  const { colors: { accentRed } } = useTheme();

  return (
    <Svg
      fill="none"
      height={24}
      viewBox="0 0 24 24"
      width={24}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath={clipPath}>
        <Path
          d="M23.26 12c0 6.219-5.041 11.26-11.26 11.26C5.782 23.26.74 18.219.74 12 .74 5.781 5.782.74 12 .74 18.22.74 23.26 5.781 23.26 12z"
          stroke={accentRed}
        />
        <Path
          d="M12 13.46a.9.9 0 01-.9-.9V5.81a.9.9 0 111.8 0v6.75a.9.9 0 01-.9.9zM12 18.7a1.53 1.53 0 100-3.06 1.53 1.53 0 000 3.06z"
          fill={accentRed}
        />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Path d="M0 0H24V24H0z" fill="#fff" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default PaymentWarningL;
