/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavAccountActiveLIcon(props) {
  const { colors: { navigationMenuActive, primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M12.0002 1.56006C9.90721 1.55809 7.86069 2.17691 6.11951 3.33825C4.37832 4.49959 3.0207 6.15127 2.21839 8.08434C1.41608 10.0174 1.20513 12.145 1.61223 14.198C2.01934 16.251 3.02619 18.1371 4.50543 19.6177C5.98468 21.0984 7.86983 22.107 9.92243 22.516C11.975 22.9251 14.1028 22.7161 16.0366 21.9157C17.9705 21.1152 19.6234 19.7591 20.7864 18.019C21.9494 16.2789 22.5702 14.233 22.5702 12.1401C22.5675 9.33662 21.4534 6.6486 19.472 4.66533C17.4906 2.68205 14.8036 1.56536 12.0002 1.56006ZM18.6502 18.6701C18.1031 17.3586 17.1802 16.2384 15.9978 15.4505C14.8153 14.6625 13.4261 14.2421 12.0052 14.2421C10.5842 14.2421 9.19503 14.6625 8.01257 15.4505C6.8301 16.2384 5.90723 17.3586 5.36016 18.6701C4.06889 17.3599 3.19372 15.6973 2.84466 13.8912C2.49561 12.0851 2.68825 10.2161 3.39837 8.51911C4.10849 6.82217 5.3044 5.37301 6.83577 4.3538C8.36714 3.33458 10.1656 2.7908 12.0052 2.7908C13.8447 2.7908 15.6432 3.33458 17.1746 4.3538C18.7059 5.37301 19.9018 6.82217 20.6119 8.51911C21.3221 10.2161 21.5147 12.0851 21.1657 13.8912C20.8166 15.6973 19.9414 17.3599 18.6502 18.6701Z" fill={navigationMenuActive || primary} />
      <Path d="M11.9903 12.7501C14.1773 12.7501 15.9503 10.9771 15.9503 8.79008C15.9503 6.60303 14.1773 4.83008 11.9903 4.83008C9.80323 4.83008 8.03027 6.60303 8.03027 8.79008C8.03027 10.9771 9.80323 12.7501 11.9903 12.7501Z" fill={navigationMenuActive || primary} />
    </Svg>
  );
}
