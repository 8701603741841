import { useRef } from 'react';
import { Animated } from 'react-native';

import {
  INITIAL_TRANSLATE_X,
  SCALE_FACTOR_GROWN,
  SCALE_FACTOR_SHRUNK,
  TRANSLATE_Y_GROWN,
  TRANSLATE_Y_SHRUNK,
} from '../constants';

const useAnimatedValues = ({ labelIsShrunk }) => {
  const { current: scaleAnim } = useRef(
    new Animated.Value(labelIsShrunk ? SCALE_FACTOR_SHRUNK : SCALE_FACTOR_GROWN),
  );
  const { current: translateYAnim } = useRef(
    new Animated.Value(labelIsShrunk ? TRANSLATE_Y_SHRUNK : TRANSLATE_Y_GROWN),
  );
  const { current: translateXAnim } = useRef(
    new Animated.Value(INITIAL_TRANSLATE_X),
  );

  return { scaleAnim, translateXAnim, translateYAnim };
};

export default useAnimatedValues;
