/*
 * Escrow History
 *
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  fetchTransactions,
  resetEscrowHistoryState,
  updateSelectedTransaction,
} from '@dmi/shared/redux/Mortgage/actions';
import makeSelectMortgage, {
  makeSelectFlatListTransactions,
  makeSelectMortgageNotifications,
  makeSelectMortgageServiceError,
} from '@dmi/shared/redux/Mortgage/selectors';

import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';
import {
  EscrowHistoryList,
  EscrowHistoryListSkeleton,
} from '../../../components/Mortgage/EscrowHistory';
import AsyncRender from '../../../components/AsyncRender';
import { StyledSecondaryHeader, StyledView } from './styledComponents';

const EscrowHistoryScreen = ({
  dispatchFetchTransactions,
  dispatchResetEscrowHistoryState,
  dispatchUpdateSelectedTransaction,
  flatListTransactions,
  itemsPerPage,
  loading: { transactions: transactionsLoading },
  navigation,
  notifications,
  totalPages,
  transactionsError,
}) => {
  useEffect(() => dispatchResetEscrowHistoryState, [dispatchResetEscrowHistoryState]);

  useEffect(() => {
    dispatchFetchTransactions();
  }, [dispatchFetchTransactions]);

  const isInitialRender = totalPages === -1 && !transactionsError;

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <StyledSecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Escrow History"
      />
      <StyledView>
        <AsyncRender
          Component={EscrowHistoryList}
          error={transactionsError}
          errorComponentType="cardLevel"
          loading={isInitialRender}
          LoadingComponent={EscrowHistoryListSkeleton}
          propsToPassDown={{
            dispatchFetchTransactions,
            dispatchUpdateSelectedTransaction,
            flatListTransactions,
            itemsPerPage,
            navigation,
            scrollLoading: !isInitialRender && transactionsLoading,
          }}
        />
      </StyledView>
    </Fragment>
  );
};

EscrowHistoryScreen.propTypes = {
  dispatchFetchTransactions: T.func.isRequired,
  dispatchResetEscrowHistoryState: T.func.isRequired,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  flatListTransactions: T.array.isRequired,
  itemsPerPage: T.number.isRequired,
  loading: T.shape({ transactions: T.bool.isRequired }).isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  totalPages: T.number.isRequired,
  transactionsError: T.oneOfType([T.bool, T.string]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  // eslint-disable-next-line sort-keys
  flatListTransactions: makeSelectFlatListTransactions(),
  itemsPerPage: makeSelectMortgage('itemsPerPage'),
  loading: makeSelectMortgage('loading'),
  notifications: makeSelectMortgageNotifications(),
  totalPages: makeSelectMortgage('totalPages'),
  transactionsError: makeSelectMortgageServiceError('transactions'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store : Mortgage
  */
  dispatchFetchTransactions: () => dispatch(fetchTransactions()),
  dispatchResetEscrowHistoryState: () => dispatch(resetEscrowHistoryState()),
  dispatchUpdateSelectedTransaction: (id) => dispatch(updateSelectedTransaction(id)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(EscrowHistoryScreen);
