/* eslint-disable max-len */
import {
  EscrowStatementImg,
  InsuranceClaimImg,
  LenderInsuranceImg,
  MortgageStatementPaperlessBillingImageImg,
  PaymentAssistanceImg,
  YearEndTaxImg,
} from '../../../../../../assets/images/LearningCenter';

export const IMAGE_DICT = {
  'How To Read Your Escrow Statement': EscrowStatementImg,
  'Mortgage Statement Overview & Paperless Billing': MortgageStatementPaperlessBillingImageImg,
  'Payment Assistance Options': PaymentAssistanceImg,
  'Understanding Insurance Claims': InsuranceClaimImg,
  'Understanding Lender Placed Insurance': LenderInsuranceImg,
  'Understanding Year End Tax Documents': YearEndTaxImg,
};
