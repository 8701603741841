/* eslint-disable object-curly-newline */
import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import {
  cancel,
  resetRequestStatus,
  setIsInAutopayFlow,
  setIsModalOpen,
} from '@dmi/shared/redux/Payments/Autopay/actions';
import {
  CANCEL_AUTOPAY_DICTIONARY,
  INFO_COPY_DICT,
} from '@dmi/shared/redux/Payments/Autopay/constants';
import { generateCancelAutopayModalProps } from '@dmi/shared/redux/Payments/Autopay/helpers';
import makeSelectAutopay, {
  selectCancelConfirmation,
  selectError,
  selectIsModalOpen,
  selectStatusDataProp,
  selectStatusProp,
} from '@dmi/shared/redux/Payments/Autopay/selectors';

import CancelConfirmation from '../../../components/Payments/CancelConfirmation';

const CancelOrStopConfirmationScreen = ({
  autopayStatus,
  confirmationData,
  confirmationType,
  customerServicePhoneNumber,
  dispatchCancelAutopay,
  dispatchResetRequestStatus,
  dispatchSetIsInAutopayFlow,
  dispatchSetIsModalOpen,
  isOpen,
  navigation,
  serviceError,
  status,
}) => {
  useEffect(() => {
    dispatchResetRequestStatus();
    return () => {
      dispatchSetIsInAutopayFlow({ value: false });
    };
  }, [
    dispatchResetRequestStatus,
    dispatchSetIsInAutopayFlow,
  ]);

  const cancelAutopayModalProps = generateCancelAutopayModalProps({
    autopayStatus,
    dispatchCancelAutopay,
    dispatchSetIsModalOpen,
    isOpen,
    status,
  });

  return (
    <CancelConfirmation
      confirmationDictionary={CANCEL_AUTOPAY_DICTIONARY}
      confirmationType={confirmationType}
      customerServicePhoneNumber={customerServicePhoneNumber}
      dispatchSetIsInAutopayFlow={dispatchSetIsInAutopayFlow}
      dispatchSetIsModalOpen={dispatchSetIsModalOpen}
      infoSectionData={INFO_COPY_DICT.confirmation[confirmationType]}
      modalProps={cancelAutopayModalProps}
      navigation={navigation}
      serviceError={serviceError}
      title="Request Confirmation"
      {...confirmationData}
    />
  );
};

CancelOrStopConfirmationScreen.propTypes = {
  autopayStatus: T.string,
  confirmationData: T.oneOfType([
    T.bool,
    T.shape({
      amount: T.string.isRequired,
      confirmationNumber: T.string.isRequired,
      date: T.string.isRequired,
    }),
  ]).isRequired,
  confirmationType: T.string,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchCancelAutopay: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  dispatchSetIsInAutopayFlow: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  isOpen: T.bool.isRequired,
  navigation: T.object.isRequired,
  serviceError: T.object.isRequired,
  status: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  autopayStatus: selectStatusDataProp('status'),
  confirmationData: selectCancelConfirmation(),
  confirmationType: makeSelectAutopay('confirmationType'),
  isOpen: selectIsModalOpen('cancel'),
  serviceError: selectError('serviceError'),
  status: selectStatusProp('cancel'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchCancelAutopay: () => dispatch(cancel({ navigationType: 'replace' })),
  dispatchResetRequestStatus: () => dispatch(resetRequestStatus({ statusType: 'cancel' })),
  dispatchSetIsInAutopayFlow: (payload) => dispatch(setIsInAutopayFlow(payload)),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(CancelOrStopConfirmationScreen);
