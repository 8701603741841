import React from 'react';
import T from 'prop-types';

import { H5, WebAnimation } from '../../base_ui';
import {
  Container,
  Content,
  Header,
  IllustrationWrapper,
  Row,
  RowValue,
  Subheader,
} from './styledComponents';

const HelocConfirmation = ({
  confirmationConstants: {
    subHeader,
    successHeader,
  },
  confirmationNumber,
  requestAmount,
  requestDate,
}) => (
  <Container>
    <Content>
      <IllustrationWrapper>
        <WebAnimation
          aria-label="flying money image"
          name="flyingMoneyMobile"
          role="img"
        />
      </IllustrationWrapper>
      <Header>{successHeader}</Header>
      <Subheader>{subHeader}</Subheader>
      <Row>
        <H5>Confirmation Number</H5>
        <RowValue>{confirmationNumber}</RowValue>
      </Row>
      <Row>
        <H5>Advance Amount</H5>
        <RowValue>{requestAmount}</RowValue>
      </Row>
      <Row>
        <H5>Request Date</H5>
        <RowValue>{requestDate}</RowValue>
      </Row>
    </Content>
  </Container>
);

HelocConfirmation.propTypes = {
  confirmationConstants: T.shape({
    header: T.string.isRequired,
    linkLabel: T.string.isRequired,
    subHeader: T.string.isRequired,
    successHeader: T.string.isRequired,
  }).isRequired,
  confirmationNumber: T.string.isRequired,
  requestAmount: T.string.isRequired,
  requestDate: T.string.isRequired,
};

export default HelocConfirmation;
