import styled from 'styled-components/native';
import { Platform } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';

const calculateHeight = (
  $initialContainerHeight,
  $isBannerContainerOpen,
  $isSingleBanner,
  $shouldHideBanners,
) => {
  if ($isSingleBanner || ($isBannerContainerOpen && !$shouldHideBanners)) {
    return 'auto';
  }
  if ($shouldHideBanners) {
    return '0';
  }
  return `${moderateScale($initialContainerHeight)}px`;
};

export const BottomBannersContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  bottom: 0;
  height: ${({
    $initialContainerHeight,
    $isBannerContainerOpen,
    $isSingleBanner,
    $shouldHideBanners,
  }) =>
    calculateHeight(
      $initialContainerHeight,
      $isBannerContainerOpen,
      $isSingleBanner,
      $shouldHideBanners,
    )};
  padding-bottom: ${Platform.OS === 'web' ? moderateScale(1) : 0}px;
  position: ${Platform.OS === 'web' ? 'fixed' : 'absolute'};
  width: 100%;
  z-index: 10;
`;

export const TopBannersContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  padding-bottom: ${({ $hasBanner }) => $hasBanner ? moderateScale(8) : 0}px;
  padding-top: ${Platform.OS === 'web' ? moderateScale(1) : 0}px;
  position: ${Platform.OS === 'web' ? 'sticky' : 'relative'};
  top: 0;
  z-index: 10;
`;
