import styled from 'styled-components/native';

/**
 * Common ScrollView used as main wrapper around all of a screen's content.
 * Key styles:
 * 1) force the height to grow all the way to the footer or bottom of the screen.
 * 2) Make the background white, including if the user over-scrolls.
 */
export const StyledScrollView = styled.ScrollView.attrs({
  contentContainerStyle: {
    flexGrow: 1, // needed for Safari web
    minHeight: '100%', // needed for iOS native
  },
  keyboardShouldPersistTaps: 'handled',
})`
  // Needed here instead of in contentContainerStyle to keep background white in over-scroll region
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

/**
 * Like StyledScrollView, but excludes minHeight of 100%, since that seems to mess things up in
 * subtle ways, typically when the ScrollView is a bit nested in the component hierarchy.
 */
export const NestedStyledScrollView = styled.ScrollView.attrs({ // eslint-disable-line object-curly-newline, max-len
  contentContainerStyle: { flexGrow: 1 },
  keyboardShouldPersistTaps: 'handled',
})`
  // Needed here instead of in contentContainerStyle to keep background white in over-scroll region
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;
