/*
 * Add Escrow Set Up Screen
 *
 */

import React, { Fragment, useEffect, useState } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeAddEscrowOption,
  changeStep,
  resetBannerError,
  resetFormState,
} from '@dmi/shared/redux/Mortgage/actions';
import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';
import makeSelectMortgage from '@dmi/shared/redux/Mortgage/selectors';

import SetUpEscrow from '../../../components/Mortgage/AddEscrow/SetUpEscrow';
import AsyncRender from '../../../components/AsyncRender';

import { StyledSecondaryHeader, StyledView } from './styledComponents';

const AddEscrowSetUpScreen = ({
  dispatchChangeAddEscrowOption,
  dispatchChangeStep,
  dispatchResetBannerError,
  dispatchResetFormState,
  navigation,
}) => {
  const [addEscrowOption, setAddEscrowOption] = useState('');
  const { header } = ADD_ESCROW_DICT.setUpOptions;

  useEffect(() => {
    dispatchResetBannerError();
    dispatchResetFormState({ form: 'uploadDocument' });
    dispatchResetFormState({ form: 'secondFileInput' });
  }, [dispatchResetBannerError, dispatchResetFormState]);

  const backNavigationHandler = () => {
    dispatchChangeStep(1);
    navigation.goBack();
  };

  const submitPressHandler = (newStep, path) => {
    dispatchChangeStep(newStep);
    dispatchChangeAddEscrowOption(addEscrowOption);
    navigation.navigate(path);
  };

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={backNavigationHandler}
        title={header}
      />
      <StyledView>
        <AsyncRender
          Component={SetUpEscrow}
          error={false}
          loading={false}
          propsToPassDown={{
            addEscrowOption,
            navigation,
            setAddEscrowOption,
            submitPressHandler,
          }}
        />
      </StyledView>
    </Fragment>
  );
};

AddEscrowSetUpScreen.propTypes = {
  dispatchChangeAddEscrowOption: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({ step: makeSelectMortgage('step') });

const mapDispatchToProps = (dispatch) => ({
  /**
  * Reducer : Mortgage
  */
  dispatchChangeAddEscrowOption: (payload) => dispatch(changeAddEscrowOption(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchResetBannerError: () => dispatch(resetBannerError()),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(
  withConnect,
)(AddEscrowSetUpScreen);
