import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

const BaseParagraph = styled.Text`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
`;

export const P1 = styled(BaseParagraph)`
  font-size: ${({ theme: { fontSizes: { f5 } } }) => `${f5}px`};
  line-height: ${moderateScale(22)}px;
`;

export const P2 = styled(BaseParagraph)`
  font-size: ${({ theme: { fontSizes: { f4 } } }) => `${f4}px`};
  line-height: ${moderateScale(22)}px;
`;

export const P3 = styled(BaseParagraph)`
  font-size: ${({ theme: { fontSizes: { f3 } } }) => `${f3}px`};
  line-height: ${moderateScale(18)}px;
`;

export const P4 = styled(BaseParagraph)`
  font-size: ${({ theme: { fontSizes: { f2 } } }) => `${f2}px`};
  line-height: ${moderateScale(15)}px;
`;

export const P5 = styled(BaseParagraph)`
  font-size: ${({ theme: { fontSizes: { f1 } } }) => `${f1}px`};
  line-height: ${moderateScale(13)}px;
`;
