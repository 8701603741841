import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';

import Row from './Row';
import { Container, LinkText } from './styledComponents';

const InfoSection = ({ customerServicePhoneNumber, data }) => {
  const onPhoneNumberPress = () => {
    Linking.openURL(`tel://${customerServicePhoneNumber.replace(/-/g, '')}`);
  };

  const messageValues = {
    // eslint-disable-next-line react/prop-types
    a: ({ msg }) => <LinkText onPress={onPhoneNumberPress}>{msg}</LinkText>,
    customerServicePhoneNumber,
  };

  return (
    <Container>
      {data.map(({ text, variant }, i) => (
        <Row
          key={text.slice(0, 10)}
          messageValues={messageValues}
          noBottomPadding={i === data.length - 1}
          text={text}
          variant={variant}
        />
      ))}
    </Container>
  );
};

InfoSection.propTypes = {
  customerServicePhoneNumber: T.string,
  data: T.arrayOf(T.shape({ text: T.string, variant: T.string })).isRequired,
};

export default InfoSection;
