import React from 'react';
import Helmet from 'react-helmet';

import { PROD_HOSTNAMES } from '@dmi/shared/utils/constants';

/* Prevents SEO indexing of Non-Prod sites */
const { hostname } = window.location;
const preventIndexing = !PROD_HOSTNAMES.includes(hostname);

const HelmetComponent = () => {
  // For WCAG Compliance
  document.querySelector('meta[name=viewport]').content =
    'width=device-width, initial-scale=1, minimum-scale=1, viewport-fit=cover';

  return (
    <Helmet>
      {preventIndexing && <meta content="noindex, nofollow" name="robots" />}
    </Helmet>
  );
};

export default HelmetComponent;
