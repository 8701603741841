import React, { Fragment } from 'react';
import T from 'prop-types';

import { INFORMATION_NOT_AVAILABLE } from '@dmi/shared/utils/constants';

import { ConditionalRender, P3 } from '../../../../base_ui';
import { AddressContainer, AddressLabel } from './styledComponents';

const PropertyAddress = ({
  isActive,
  propertyAddress: {
    addressFirstLine,
    addressSecondLine,
  },
}) => (
  <AddressContainer>
    <ConditionalRender
      Component={(
        <Fragment>
          <AddressLabel>Property</AddressLabel>
          <P3>{addressFirstLine}</P3>
          <P3>{addressSecondLine}</P3>
        </Fragment>
      )}
      FallbackComponent={(
        <Fragment>
          <AddressLabel>Property</AddressLabel>
          <P3>{INFORMATION_NOT_AVAILABLE}</P3>
          <P3>&nbsp;</P3>
        </Fragment>
      )}
      shouldRender={isActive}
    />
  </AddressContainer>
);

PropertyAddress.propTypes = {
  isActive: T.bool,
  propertyAddress: T.shape({
    addressFirstLine: T.string,
    addressSecondLine: T.string,
  }).isRequired,
};

export default PropertyAddress;
