/**
* FilterGroup
* @description: Group of filter buttons
* Use Case: Use to filter data and display selected filters
* Component Props: {
*   handleOnPress (func) function that controls state of the selected filter
*   isStatementsFilter (bool) boolean indicating if the filter is from statements view
*   labels (array) array of strings, strings are used as the labels for individual filters
*   selectedFilter (string) selected filter
* }
*/

import React from 'react';
import T from 'prop-types';

import Filter from './baseFilter';
import { StyledScrollView } from './styledComponents';

const FilterGroup = ({
  handleOnPress,
  isStatementsFilter = false,
  labels,
  selectedFilter,
  showsHorizontalScrollIndicator = true,
  ...restProps
}) => (
  <StyledScrollView
    horizontal
    role="tablist"
    showsHorizontalScrollIndicator={showsHorizontalScrollIndicator}
  >
    {
      labels.map((label) => (
        <Filter
          key={label}
          isSelected={selectedFilter === label}
          isStatementsFilter={isStatementsFilter}
          label={label}
          onPress={() => handleOnPress(label)}
          {...restProps}
        />
      ))
    }
  </StyledScrollView>
);

FilterGroup.propTypes = {
  handleOnPress: T.func.isRequired,
  isStatementsFilter: T.bool,
  labels: T.array.isRequired,
  selectedFilter: T.string.isRequired,
  showsHorizontalScrollIndicator: T.bool,
};

export default FilterGroup;
