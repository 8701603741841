import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { chooseDocument } from '@dmi/shared/redux/SecureMessaging/actions';
import { selectRequestStatus } from '@dmi/shared/redux/SecureMessaging/selectors';
import { SecureMessageDocUpload } from '../../../components/SecureMessaging/Shared';

import { MessageContainer } from '../styledComponents';

const NewMessageDocUploadScreen = ({
  dispatchChooseDocument,
  navigation,
  requestStatusChooseDoc,
}) => (
  <MessageContainer>
    <SecureMessageDocUpload
      dispatchChooseDocument={dispatchChooseDocument}
      handleBack={navigation.goBack}
      navigation={navigation}
      requestStatusProcessDoc={requestStatusChooseDoc}
      reviewScreenName="NewMessageDocReview"
    />
  </MessageContainer>
);

NewMessageDocUploadScreen.propTypes = {
  dispatchChooseDocument: T.func.isRequired,
  navigation: T.object.isRequired,
  requestStatusChooseDoc: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: SecureMessaging
   */
  requestStatusChooseDoc: selectRequestStatus('chooseDocument'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: SecureMessaging
   */
  dispatchChooseDocument: (payload) => dispatch(chooseDocument(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessageDocUploadScreen);
