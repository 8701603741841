import { INTL_IDS } from '@dmi/shared/redux/Main/messages';

import iconDictionary from '../../../utils/iconDictionary';

const AccountIconDefault = iconDictionary('navAccountDefaultL');
const CalculatorIconDefault = iconDictionary('navCalculatorDefaultL');
const DocumentsIconDefault = iconDictionary('navDocumentsDefaultL');
const HelpIconDefault = iconDictionary('navHelpDefaultL');
const LogoutIconDefault = iconDictionary('navLogoutDefaultL');
const MortgageIconDefault = iconDictionary('navMortgageDefaultL');
const PaymentsIconDefault = iconDictionary('navPaymentsDefaultL');

const AccountIconActive = iconDictionary('navAccountActiveL', { iconState: 'active' });
const CalculatorIconActive = iconDictionary('navCalculatorActiveL', { iconState: 'active' });
const DocumentsIconActive = iconDictionary('navDocumentsActiveL', { iconState: 'active' });
const HelpIconActive = iconDictionary('navHelpActiveL', { iconState: 'active' });
const LogoutIconActive = iconDictionary('navLogoutActiveL', { iconState: 'active' });
const MortgageIconActive = iconDictionary('navMortgageActiveL', { iconState: 'active' });
const PaymentsIconActive = iconDictionary('navPaymentsActiveL', { iconState: 'active' });

export const MOBILE_MENU_ITEMS = [
  {
    iconActive: CalculatorIconActive,
    iconDefault: CalculatorIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_CALCULATORS,
    path: 'Calculators',
  },
  {
    iconActive: HelpIconActive,
    iconDefault: HelpIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_HELP,
    path: 'Help',
  },
  {
    iconActive: AccountIconActive,
    iconDefault: AccountIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_SETTINGS,
    path: 'Settings',
  },
  {
    iconActive: LogoutIconActive,
    iconDefault: LogoutIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_LOGOUT,
  },
];

export const MOBILE_WEB_MENU_ITEMS = [
  {
    iconActive: PaymentsIconActive,
    iconDefault: PaymentsIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_PAYMENTS,
    path: 'Payments',
  },
  {
    iconActive: MortgageIconActive,
    iconDefault: MortgageIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_MORTGAGE,
    path: 'Mortgage',
  },
  {
    iconActive: DocumentsIconActive,
    iconDefault: DocumentsIconDefault,
    label: INTL_IDS.NAVBAR_LINK_LABEL_DOCUMENTS,
    path: 'Documents',
  },
  ...MOBILE_MENU_ITEMS,
];
