import styled from 'styled-components/native';

import {
  H2,
  H3,
  H5,
  P2,
  P3,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

// Page-level components
export const Title = styled(H2)`
  margin: ${moderateScale(13)}px 0 ${moderateScale(8)}px;
  padding: 0 ${moderateScale(20)}px;
`;

export const SubTitle = styled(P2)`
  margin-bottom: ${moderateScale(32)}px;
  padding: 0 ${moderateScale(20)}px;
  text-align: center;
`;

export const ContentWrapper = styled.View`
  align-items: center;
  flex-direction: column;
  padding: 0 ${moderateScale(24)}px;
`;

// Card-level components
export const Card = styled.View`
  align-items: center;
  border-radius: ${moderateScale(8)}px;
  border: ${({ theme: { colors: { primaryGray } } }) => (
    `${moderateScale(1)}px solid ${primaryGray};`
  )};
  margin-bottom: ${moderateScale(22)}px;
  padding: ${moderateScale(20)}px ${moderateScale(20)}px ${moderateScale(16)}px;
  width: 100%;
`;

export const CardTitle = styled(H3)`
  margin: ${moderateScale(16)}px 0 ${moderateScale(8)}px;
  text-align: center;
`;

export const CardText = styled(P3)`
  margin-bottom: ${moderateScale(16)}px;
  text-align: center;
`;

export const EmptyCovidLink = styled.View`
  height: ${moderateScale(8)}px;
  width: ${moderateScale(8)}px;
`;

export const LinkContainer = styled.View`
  height: ${moderateScale(34)}px;
  justify-content: center;
`;

export const LinkText = styled(P3)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
`;

// Tooltip-level components
export const ButtonText = styled(P2)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  padding-top: ${moderateScale(4)}px;
`;

export const StyledH5 = styled(H5)`
  padding-bottom: ${moderateScale(4)}px;
`;
