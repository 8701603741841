/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

const DesktopSelectedLoanFall = (props) => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      id="Layer_1"
      version="1.1"
      viewBox="0 0 287 120"
      x="0px"
      xmlns="http://www.w3.org/2000/Svg"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <Path className="cls-1" d="M77.13,30a3,3,0,0,0,2.78-4.12c-1.69-4.18-5.25-10.13-12-10.83a11.7,11.7,0,0,0-7.59,2.22.47.47,0,0,1-.59-.05,8.6,8.6,0,0,0-5.83-2.17c-4.34,0-7.87,3-7.87,6.63h0a.49.49,0,0,1-.53.49c-.21,0-.42,0-.64,0a6.06,6.06,0,0,0-5.74,3.48.47.47,0,0,1-.63.26,5.62,5.62,0,0,0-2.37-.49,5,5,0,0,0-4,2.25A1.49,1.49,0,0,0,33.28,30Z" fill="#dae1e8" />
      <Rect className="cls-2" fill="#e7ebee" height="2.3" width="7.81" x="97.11" y="7.85" />
      <Path className="cls-3" d="M105.55,5.57V7.69l-.47.1V33.3l4.68-5.11V7.69s.66-.52.61-.71-.23-1.23-.23-1.23l-.33-.33Z" fill="#bac2ca" />
      <Path className="cls-4" d="M105,33.67H97a.41.41,0,0,1-.41-.41V7.8A.41.41,0,0,1,97,7.39a.42.42,0,0,1,.41.41V32.85h7.46l4.55-4.77V7.88a.41.41,0,0,1,.41-.41.41.41,0,0,1,.41.41V28.24a.44.44,0,0,1-.11.29l-4.79,5A.43.43,0,0,1,105,33.67Z" fill="#010101" />
      <Path className="cls-4" d="M105,27a.27.27,0,0,1-.27-.27v-19a.27.27,0,1,1,.54,0v19A.27.27,0,0,1,105,27Z" fill="#010101" />
      <Path className="cls-4" d="M109.67,8.06h-13a1.15,1.15,0,0,1-1.15-1.15V6.17A1.16,1.16,0,0,1,96.67,5h13a1.16,1.16,0,0,1,1.15,1.15v.74A1.15,1.15,0,0,1,109.67,8.06Zm-13-2.22a.33.33,0,0,0-.33.33v.74a.33.33,0,0,0,.33.33h13a.33.33,0,0,0,.33-.33V6.17a.33.33,0,0,0-.33-.33Z" fill="#010101" />
      <Path className="cls-4" d="M105.48,7.7a.27.27,0,0,1-.27-.27V5.57a.27.27,0,1,1,.54,0V7.43A.27.27,0,0,1,105.48,7.7Z" fill="#010101" />
      <Polygon className="cls-5" fill="#fff" points="177.06 112.84 177.06 41 129.68 4.77 86.14 39.03 86.14 112.94 177.06 112.84" />
      <Rect className="cls-2" fill="#e7ebee" height="71" width="27.6" x="176" y="41.69" />
      <Path className="cls-3" d="M180.62,50.22H204V48.15l-24-.3-3.78-3.56-.09,2.87,2.48,2.3A2.9,2.9,0,0,0,180.62,50.22Z" fill="#bac2ca" />
      <Path className="cls-4" d="M203.36,112.73a.41.41,0,0,1-.41-.4V38.58a.41.41,0,0,1,.41-.41.41.41,0,0,1,.41.41v73.75A.4.4,0,0,1,203.36,112.73Z" fill="#010101" />
      <Path className="cls-2" d="M183,44.37h28A1.63,1.63,0,0,1,212.6,46v.3A1.63,1.63,0,0,1,211,47.93H183a0,0,0,0,1,0,0V44.37a0,0,0,0,1,0,0Z" fill="#e7ebee" />
      <Path className="cls-4" d="M211.11,48.26h-30a.42.42,0,0,1-.41-.41.41.41,0,0,1,.41-.41h30a.41.41,0,0,1,.41.41A.41.41,0,0,1,211.11,48.26Z" fill="#010101" />
      <Path className="cls-4" d="M210.65,48.24h-2.47a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41h2.47a1.33,1.33,0,1,0,0-2.64h-2.47a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41h2.47a2.15,2.15,0,1,1,0,4.28Z" fill="#010101" />
      <Polygon className="cls-3" fill="#bac2ca" points="210.97 43.81 158.98 3 130.1 3 182.65 44.37 177.97 44.37 210.97 43.81" />
      <Path className="cls-5" d="M130.82,3.79a2,2,0,0,0-1.45-.58,2,2,0,0,0-1.43.62L77.51,44.94a1.68,1.68,0,0,0,.21,2.5,2.06,2.06,0,0,0,2.72-.2l49-39.59L180,47.24a2,2,0,0,0,2.71.12,1.65,1.65,0,0,0,.13-2.49Z" fill="#fff" />
      <Polygon className="cls-3" fill="#bac2ca" points="85.45 42.35 85.45 47.96 80.21 48.08 85.45 42.35" />
      <Path className="cls-4" d="M85.44,48.31H80.05a.41.41,0,0,1-.4-.41.4.4,0,0,1,.4-.41h5.39a.41.41,0,0,1,.41.41A.41.41,0,0,1,85.44,48.31Z" fill="#010101" />
      <Polygon className="cls-3" fill="#bac2ca" points="131.44 9.6 85.92 46.1 85.92 42.74 129.39 7.98 131.44 9.6" />
      <Path className="cls-4" d="M211.45,44.78H182.77a.4.4,0,0,1-.4-.41.41.41,0,0,1,.4-.41h27.5L158.84,3.41H146.68a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41H159a.38.38,0,0,1,.25.09L211.7,44.05a.4.4,0,0,1,.13.46A.4.4,0,0,1,211.45,44.78Z" fill="#010101" />
      <Path className="cls-4" d="M143.94,3.41h-1.79a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41h1.79a.41.41,0,0,1,.41.41A.41.41,0,0,1,143.94,3.41Z" fill="#010101" />
      <Path className="cls-4" d="M129.33,3.48a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41l10.27-.07h0a.41.41,0,0,1,0,.82l-10.28.07Z" fill="#010101" />
      <Path className="cls-4" d="M79,48.27a2.48,2.48,0,0,1-1.51-.51,2.11,2.11,0,0,1-.82-1.51,2,2,0,0,1,.56-1.58L127.68,3.51a2.38,2.38,0,0,1,1.68-.71,2.49,2.49,0,0,1,1.74.68l52,41.06a2.09,2.09,0,0,1-.12,3.14,2.53,2.53,0,0,1-3.27-.15L129.45,8.18,80.69,47.56A2.3,2.3,0,0,1,79,48.27ZM129.4,3.62h0a1.56,1.56,0,0,0-1.14.49L77.77,45.26A1.25,1.25,0,0,0,78,47.11,1.62,1.62,0,0,0,80.14,47L129.19,7.34a.42.42,0,0,1,.51,0l50.5,39.59a1.66,1.66,0,0,0,2.2.13,1.26,1.26,0,0,0,.48-.94,1.24,1.24,0,0,0-.38-1l-51.93-41,0,0A1.59,1.59,0,0,0,129.4,3.62Z" fill="#010101" />
      <Path className="cls-5" d="M85.34,95.07a16,16,0,0,0-15.45,6.3,2.22,2.22,0,0,1-2.53.76,11,11,0,0,0-3.6-.6,11.13,11.13,0,0,0-8.67,4.16,13.3,13.3,0,0,0-2.4,6.72H85.45Z" fill="#fff" />
      <Path className="cls-6" d="M56.15,104.75A10.74,10.74,0,0,1,65,103.23c5.47,1.25,6.13-.06,7.78-2s8-4.78,12.79-3.43v-2.6S80,93.94,75.33,96.71s-5.34,4.91-6.07,5.2-1,.46-3.26-.16-7.35.13-10,3.13" fill="#dce2e7" />
      <Path className="cls-4" d="M52.42,112.87h0a.41.41,0,0,1-.39-.42c.11-3.24,2.64-6.84,2.75-7a11.5,11.5,0,0,1,9-4.33,11.2,11.2,0,0,1,3.73.63,1.83,1.83,0,0,0,2.07-.62,16.44,16.44,0,0,1,13.18-6.68,15.74,15.74,0,0,1,2.66.22.4.4,0,0,1,.34.47.4.4,0,0,1-.47.33,16.39,16.39,0,0,0-2.53-.2,15.6,15.6,0,0,0-12.52,6.34,2.64,2.64,0,0,1-3,.91A10.69,10.69,0,0,0,55.41,106s-2.48,3.54-2.58,6.52A.41.41,0,0,1,52.42,112.87Z" fill="#010101" />
      <Path className="cls-4" d="M85.59,100.63a.41.41,0,0,1-.4-.41V70.86a.41.41,0,1,1,.81,0v29.36A.41.41,0,0,1,85.59,100.63Z" fill="#010101" />
      <Path className="cls-4" d="M85.59,68.14a.41.41,0,0,1-.4-.41V43.4a.41.41,0,1,1,.81,0V67.73A.41.41,0,0,1,85.59,68.14Z" fill="#010101" />
      <Path className="cls-4" d="M175.88,112.82a.41.41,0,0,1-.41-.41V44.11a.41.41,0,0,1,.41-.41.41.41,0,0,1,.41.41v68.3A.42.42,0,0,1,175.88,112.82Z" fill="#010101" />
      <Path className="cls-6" d="M81.29,102.75s6.13-1.52,9.42.25,4,4.42,5.69,5.25a4.59,4.59,0,0,0,4.86-.57c1.65-1.08,7.46-.57,8.79,3.79,0,0-1.2-6.26-6.89-5.94s-3.67,2.59-6.32-.63S89.13,97.37,81.29,102.75Z" fill="#dce2e7" />
      <Path className="cls-4" d="M110.35,113.17a.41.41,0,0,1-.4-.49,6.81,6.81,0,0,0-1.91-4.93,6.34,6.34,0,0,0-4.59-2,6.24,6.24,0,0,0-3.63,1.15,1.69,1.69,0,0,1-1.43.23,1.81,1.81,0,0,1-1.16-1.07c-1.34-3.3-5-5.52-9.18-5.52a10.28,10.28,0,0,0-8,3.62,2.54,2.54,0,0,1-2.74.84,9.68,9.68,0,0,0-2.84-.43.41.41,0,0,1-.41-.41.42.42,0,0,1,.41-.41,10.31,10.31,0,0,1,3.08.47,1.72,1.72,0,0,0,1.86-.57,11.1,11.1,0,0,1,8.69-3.93c4.48,0,8.47,2.43,9.93,6a1,1,0,0,0,.64.58.82.82,0,0,0,.73-.11,7.14,7.14,0,0,1,9.27.91,7.57,7.57,0,0,1,2.14,5.59.36.36,0,0,1-.12.27A.43.43,0,0,1,110.35,113.17Z" fill="#010101" />
      <Path className="cls-5" d="M74.42,104.2a9.73,9.73,0,0,0-1.43.11" fill="#fff" />
      <Path className="cls-4" d="M73,104.71a.4.4,0,0,1-.4-.35.41.41,0,0,1,.34-.46,10.56,10.56,0,0,1,1.49-.11.41.41,0,0,1,.41.41.41.41,0,0,1-.41.41,10.4,10.4,0,0,0-1.37.1Z" fill="#010101" />
      <Path className="cls-4" d="M171.94,46.29H88.7a.28.28,0,0,1-.27-.28.27.27,0,0,1,.27-.27h83.24a.27.27,0,0,1,.27.27A.28.28,0,0,1,171.94,46.29Z" fill="#010101" />
      <Path className="cls-4" d="M174.36,43.39H151.88a.29.29,0,0,1-.28-.28.28.28,0,0,1,.28-.27h22.48a.27.27,0,0,1,.27.27A.28.28,0,0,1,174.36,43.39Z" fill="#010101" />
      <Path className="cls-4" d="M149,43.39H93.25a.28.28,0,0,1-.27-.28.27.27,0,0,1,.27-.27H149a.27.27,0,0,1,.27.27A.28.28,0,0,1,149,43.39Z" fill="#010101" />
      <Path className="cls-2" d="M204.4,23a1.28,1.28,0,0,1-1-2.11c1.2-1.4,3.6-2.35,6.38-2.35a11.14,11.14,0,0,1,1.31.07.79.79,0,0,0,.88-.79h0c0-2.42,3.06-4.38,6.82-4.38a10.06,10.06,0,0,1,2.93.43.75.75,0,0,0,1-.55C223.5,9.72,227.35,7,232,7c5.21,0,9.43,3.44,9.43,7.68,0,.15,0,.3,0,.45a4.91,4.91,0,0,1,.67-.06,4.37,4.37,0,0,1,4.15,3,.76.76,0,0,0,.94.5,4.27,4.27,0,0,1,1.19-.16,4.37,4.37,0,0,1,3.43,1.65,1.79,1.79,0,0,1-1.41,2.9Z" fill="#e7ebee" />
      <Path className="cls-4" d="M65.45,113.41A.41.41,0,0,1,65,113V85.83a.41.41,0,0,1,.41-.41.42.42,0,0,1,.41.41V113A.41.41,0,0,1,65.45,113.41Z" fill="#010101" />
      <Path className="cls-4" d="M261.48,113.34h-2.72a.41.41,0,0,1-.41-.41.41.41,0,0,1,.41-.41h2.72a.42.42,0,0,1,.41.41A.41.41,0,0,1,261.48,113.34Z" fill="#010101" />
      <Path className="cls-4" d="M256,113.29H19a.36.36,0,0,1,0-.71H256a.36.36,0,1,1,0,.71Z" fill="#010101" />
      <Ellipse className="cls-7" cx="130.39" cy="28.83" fill="#f0f1f0" rx="4.48" ry="5.28" />
      <Path className="cls-4" d="M129.56,35a6.34,6.34,0,1,1,6.2-6.34A6.28,6.28,0,0,1,129.56,35Zm0-11.86a5.52,5.52,0,1,0,5.38,5.52A5.47,5.47,0,0,0,129.56,23.17Z" fill="#010101" />
      <Path className="cls-4" d="M130.38,34.83h0a6.29,6.29,0,0,1-5.08-6.07,6.17,6.17,0,0,1,2.83-5.15.28.28,0,0,1,.38.08.27.27,0,0,1-.08.38,5.6,5.6,0,0,0-2.58,4.69,5.74,5.74,0,0,0,4.64,5.54.27.27,0,0,1,.21.32A.28.28,0,0,1,130.38,34.83Z" fill="#010101" />
      <Polyline className="cls-8" fill={svgHighlight} points="140.72 101.5 140.72 67.28 159.43 67.28 159.43 101.5" />
      <Path className="cls-4" d="M159.42,101.91a.4.4,0,0,1-.4-.41V67.69H141.13V101.5a.41.41,0,1,1-.81,0V67.28a.41.41,0,0,1,.4-.41h18.7a.41.41,0,0,1,.41.41V101.5A.41.41,0,0,1,159.42,101.91Z" fill="#010101" />
      <Path className="cls-4" d="M158.81,113h-29.1a.41.41,0,0,1-.41-.41V107a.41.41,0,0,1,.41-.41h4.88v-5a.41.41,0,0,1,.41-.41h23.81a.42.42,0,0,1,.41.41v11A.42.42,0,0,1,158.81,113Zm-28.69-.82H158.4V101.93h-23v5a.41.41,0,0,1-.41.41h-4.88Z" fill="#010101" />
      <Polygon className="cls-3" fill="#bac2ca" points="156.08 101.52 156.08 106.98 150.79 106.98 150.79 112.55 156.08 112.55 156.69 112.55 162.75 112.55 162.75 101.52 156.08 101.52" />
      <Path className="cls-4" d="M162.75,113h-12a.42.42,0,0,1-.41-.41V107a.41.41,0,0,1,.41-.41h4.88v-5a.42.42,0,0,1,.41-.41h6.67a.42.42,0,0,1,.41.41v11A.42.42,0,0,1,162.75,113Zm-11.55-.82h11.14V101.93h-5.85v5a.41.41,0,0,1-.41.41H151.2Z" fill="#010101" />
      <Path className="cls-4" d="M130.33,32.69a.28.28,0,0,1-.28-.27v-8a.28.28,0,0,1,.28-.27.27.27,0,0,1,.27.27v8A.27.27,0,0,1,130.33,32.69Z" fill="#010101" />
      <Path className="cls-4" d="M135.17,28.71h-8.3a.27.27,0,1,1,0-.54h8.3a.27.27,0,1,1,0,.54Z" fill="#010101" />
      <Rect className="cls-2" fill="#e7ebee" height="24.56" width="29.11" x="97.36" y="59.21" />
      <Polygon className="cls-9" fill="#fff" opacity="0.7" points="96.1 78.75 103.19 59.22 96.8 59.22 96.1 60.82 96.1 78.75" />
      <Polygon className="cls-9" fill="#fff" opacity="0.7" points="104.94 83.78 100.96 83.78 110.23 59.22 114.21 59.22 104.94 83.78" />
      <Path className="cls-4" d="M126.7,84.38H96a.41.41,0,0,1-.41-.41V57.89a.41.41,0,0,1,.41-.41H126.7a.41.41,0,0,1,.41.41V84A.41.41,0,0,1,126.7,84.38Zm-30.31-.82h29.9V58.29H96.39Z" fill="#010101" />
      <Path className="cls-4" d="M97.38,82.75a.28.28,0,0,1-.28-.27V59.24a.28.28,0,0,1,.28-.28h29.44a.28.28,0,0,1,0,.55H97.65v23A.27.27,0,0,1,97.38,82.75Z" fill="#010101" />
      <Path className="cls-4" d="M106.5,82.43a.41.41,0,0,1-.41-.41V69.29a.41.41,0,0,1,.41-.41.42.42,0,0,1,.41.41V82A.41.41,0,0,1,106.5,82.43Z" fill="#010101" />
      <Path className="cls-4" d="M106.5,67.5a.41.41,0,0,1-.41-.41V61.18a.41.41,0,0,1,.41-.41.42.42,0,0,1,.41.41v5.91A.41.41,0,0,1,106.5,67.5Z" fill="#010101" />
      <Path className="cls-4" d="M116.16,75.61a.41.41,0,0,1-.41-.41v-14a.41.41,0,1,1,.81,0v14A.4.4,0,0,1,116.16,75.61Z" fill="#010101" />
      <Path className="cls-4" d="M116.16,82.43a.41.41,0,0,1-.41-.41V77.4a.41.41,0,1,1,.81,0V82A.4.4,0,0,1,116.16,82.43Z" fill="#010101" />
      <Path className="cls-4" d="M126.69,71.54H99.21a.41.41,0,0,1-.41-.41.42.42,0,0,1,.41-.41h27.48a.41.41,0,0,1,.4.41A.4.4,0,0,1,126.69,71.54Z" fill="#010101" />
      <Path className="cls-2" d="M152.05,69.29H132.93l1.26-2.89a10.76,10.76,0,0,1,.85-1.57,9.16,9.16,0,0,1,6.36-4h0l17.93,0Z" fill="#e7ebee" />
      <Path className="cls-4" d="M152.05,69.7H132.93a.4.4,0,0,1-.34-.18.42.42,0,0,1,0-.39l1.27-2.89a12.3,12.3,0,0,1,.87-1.64,9.56,9.56,0,0,1,6.64-4.14l18-.06h0a.41.41,0,0,1,.31.68l-7.28,8.48A.41.41,0,0,1,152.05,69.7Zm-18.5-.82h18.31l6.58-7.66-17,.05a8.71,8.71,0,0,0-6,3.78,10,10,0,0,0-.81,1.51Z" fill="#010101" />
      <Path className="cls-3" d="M159.39,69.29h-8.48l1.26-2.89a11.15,11.15,0,0,1,2-3.05,7.7,7.7,0,0,1,4.6-2.39l.62-.1Z" fill="#bac2ca" />
      <Path className="cls-4" d="M159.39,69.7h-8.48a.4.4,0,0,1-.34-.18.39.39,0,0,1,0-.39l1.26-2.89a11.65,11.65,0,0,1,2-3.17,8.25,8.25,0,0,1,4.85-2.52l.61-.09a.39.39,0,0,1,.33.09.42.42,0,0,1,.15.31v8.43a.4.4,0,0,1-.12.29A.38.38,0,0,1,159.39,69.7Zm-7.85-.82H159V61.34l-.15,0a7.41,7.41,0,0,0-4.36,2.26,10.7,10.7,0,0,0-1.91,2.94Z" fill="#010101" />
      <Path className="cls-2" d="M91.24,83.95h34.54a0,0,0,0,1,0,0v6.62a0,0,0,0,1,0,0H94a2.8,2.8,0,0,1-2.8-2.8V83.95A0,0,0,0,1,91.24,83.95Z" fill="#e7ebee" />
      <Path className="cls-4" d="M125.79,91H94.05a3.21,3.21,0,0,1-3.21-3.21V84a.4.4,0,0,1,.4-.41h34.55a.4.4,0,0,1,.4.41v6.62A.4.4,0,0,1,125.79,91ZM91.65,84.36v3.4a2.41,2.41,0,0,0,2.4,2.4h31.33v-5.8Z" fill="#010101" />
      <Path className="cls-3" d="M122.25,83.95h6.12a0,0,0,0,1,0,0v3.81a2.8,2.8,0,0,1-2.8,2.8h-.51a2.8,2.8,0,0,1-2.8-2.8V83.95a0,0,0,0,1,0,0Z" fill="#bac2ca" />
      <Path className="cls-4" d="M125.56,91h-.51a3.21,3.21,0,0,1-3.21-3.21V84a.41.41,0,0,1,.41-.41h6.11a.41.41,0,0,1,.41.41v3.81A3.21,3.21,0,0,1,125.56,91Zm-2.91-6.61v3.4a2.41,2.41,0,0,0,2.4,2.4h.51a2.41,2.41,0,0,0,2.4-2.4v-3.4Z" fill="#010101" />
      <Path className="cls-4" d="M147.64,107.25H135.21a.27.27,0,0,1-.27-.27.28.28,0,0,1,.27-.28h12.43a.28.28,0,0,1,.27.28A.27.27,0,0,1,147.64,107.25Z" fill="#010101" />
      <Circle className="cls-5" cx="149.79" cy="75.87" fill="#fff" r="0.81" />
      <Path className="cls-4" d="M149.79,77a1.08,1.08,0,1,1,0-2.16,1.08,1.08,0,1,1,0,2.16Zm0-1.61a.54.54,0,1,0,.53.53A.54.54,0,0,0,149.79,75.34Z" fill="#010101" />
      <Path className="cls-4" d="M70.88,86h-11a.42.42,0,0,1-.41-.41V80.86a2.64,2.64,0,0,1,2.64-2.64h8.73a.41.41,0,0,1,.41.41v6.94A.41.41,0,0,1,70.88,86Zm-10.55-.82H70.47V79H62.15a1.82,1.82,0,0,0-1.82,1.82Z" fill="#010101" />
      <Path className="cls-3" d="M70.33,78.63h.4A2.23,2.23,0,0,1,73,80.86v4.71a0,0,0,0,1,0,0H68.11a0,0,0,0,1,0,0V80.86A2.23,2.23,0,0,1,70.33,78.63Z" fill="#bac2ca" />
      <Path className="cls-4" d="M73,86H68.11a.41.41,0,0,1-.41-.41V80.86a2.64,2.64,0,0,1,2.63-2.64h.41a2.64,2.64,0,0,1,2.63,2.64v4.71A.41.41,0,0,1,73,86Zm-4.45-.82h4v-4.3A1.81,1.81,0,0,0,70.74,79h-.41a1.82,1.82,0,0,0-1.82,1.82Z" fill="#010101" />
      <Path className="cls-4" d="M65.86,81.76a.41.41,0,0,1-.39-.29L64,76.76l-.77.28a.4.4,0,0,1-.52-.24.41.41,0,0,1,.24-.53l1.17-.43a.47.47,0,0,1,.33,0,.39.39,0,0,1,.2.25l1.6,5.12a.4.4,0,0,1-.26.51A.3.3,0,0,1,65.86,81.76Z" fill="#010101" />
      <Path className="cls-4" d="M94.23,108.07a.25.25,0,0,1-.23-.14,8.24,8.24,0,0,0-1-1.41.27.27,0,1,1,.41-.35,8.35,8.35,0,0,1,1,1.5.27.27,0,0,1-.1.37A.35.35,0,0,1,94.23,108.07Z" fill="#010101" />
      <Path className="cls-4" d="M91.9,105.39a.27.27,0,0,1-.16-.06A9.28,9.28,0,0,0,87,103.59a.26.26,0,0,1-.26-.28.28.28,0,0,1,.28-.26,9.64,9.64,0,0,1,5,1.85.27.27,0,0,1,.05.38A.27.27,0,0,1,91.9,105.39Z" fill="#010101" />
      <Path className="cls-5" d="M141.1,91.7s-1.45,0-4.23,1.56-4.13,5-2.38,7.26,4.52.78,4.52.78l7.36.07s3.24-.5,3.27-3.16a28.56,28.56,0,0,0-1-6.41Z" fill="#fff" />
      <Path className="cls-5" d="M150.68,89.94c.91-3.2.18-4.4.18-4.4A5.55,5.55,0,0,0,147.27,87a6,6,0,0,0-3.39,0,5.55,5.55,0,0,0-3.59-1.41s-.73,1.2.18,4.4c0,0-1.33,3.22,1.83,4.44a8.36,8.36,0,0,0,6.66-.09,3,3,0,0,0,2-3.69Z" fill="#fff" />
      <Path className="cls-4" d="M145.51,95.44a9.29,9.29,0,0,1-3.36-.67,3.53,3.53,0,0,1-2.09-1.88,4.31,4.31,0,0,1,0-3c-.87-3.18-.19-4.46-.1-4.59a.4.4,0,0,1,.36-.2A6,6,0,0,1,144,86.5a6.33,6.33,0,0,1,3.2,0,6,6,0,0,1,3.67-1.36.41.41,0,0,1,.36.2c.09.13.77,1.41-.1,4.59l.2.54a3.42,3.42,0,0,1-2.21,4.22l-.11,0A9.86,9.86,0,0,1,145.51,95.44ZM140.56,86a7.2,7.2,0,0,0,.3,3.86.41.41,0,0,1,0,.27,3.62,3.62,0,0,0,0,2.48A2.75,2.75,0,0,0,142.44,94a8,8,0,0,0,6.27,0l.12,0a2.64,2.64,0,0,0,1.71-3.18l-.24-.65a.46.46,0,0,1,0-.26,7.2,7.2,0,0,0,.3-3.86,5.27,5.27,0,0,0-3,1.28.4.4,0,0,1-.43.09,5.67,5.67,0,0,0-3.13,0,.41.41,0,0,1-.41-.1A5.27,5.27,0,0,0,140.56,86Z" fill="#010101" />
      <Path className="cls-4" d="M140.57,90.28l-.12,0a.27.27,0,0,1-.13-.36,5.59,5.59,0,0,1,1.8-2.09,10.66,10.66,0,0,0-1.81-1.8.28.28,0,0,1-.05-.39.27.27,0,0,1,.38,0,10.69,10.69,0,0,1,2.09,2.16.34.34,0,0,1,0,.22.31.31,0,0,1-.13.17,5.24,5.24,0,0,0-1.83,2A.26.26,0,0,1,140.57,90.28Z" fill="#010101" />
      <Path className="cls-4" d="M150.59,90.28a.27.27,0,0,1-.25-.16,5.16,5.16,0,0,0-1.82-2,.24.24,0,0,1-.13-.17.28.28,0,0,1,0-.22,10.69,10.69,0,0,1,2.09-2.16.28.28,0,1,1,.33.44,10.39,10.39,0,0,0-1.8,1.8,5.58,5.58,0,0,1,1.79,2.09.26.26,0,0,1-.13.36A.2.2,0,0,1,150.59,90.28Z" fill="#010101" />
      <Path className="cls-4" d="M146.08,92.61h-.8a.27.27,0,1,1,0-.54h.8a.27.27,0,1,1,0,.54Z" fill="#010101" />
      <Path className="cls-4" d="M145,93.71h0a.93.93,0,0,1-1-.82.28.28,0,0,1,.55-.06.39.39,0,0,0,.42.34h0c.34,0,.38-.77.38-.78a.28.28,0,0,1,.28-.27.27.27,0,0,1,.26.28C145.93,92.89,145.73,93.71,145,93.71Z" fill="#010101" />
      <Path className="cls-4" d="M146.24,93.71c-.7,0-.9-.82-.91-1.31a.27.27,0,0,1,.26-.28.28.28,0,0,1,.28.27s0,.78.37.78h0a.39.39,0,0,0,.42-.34.27.27,0,0,1,.31-.24.28.28,0,0,1,.24.3.93.93,0,0,1-1,.82Z" fill="#010101" />
      <Circle className="cls-4" cx="143.02" cy="91.17" fill="#010101" r="0.33" />
      <Circle className="cls-4" cx="148.17" cy="91.06" fill="#010101" r="0.33" />
      <Path className="cls-4" d="M137.41,102.07h-.12a4.32,4.32,0,0,1-3.45-1.48,3.93,3.93,0,0,1-.39-3.52c1.34-4.84,6.47-5.69,6.68-5.72a.4.4,0,0,1,.47.34.4.4,0,0,1-.34.46s-4.82.81-6,5.14a3.16,3.16,0,0,0,.26,2.81,3.48,3.48,0,0,0,2.81,1.15,4.31,4.31,0,0,0,3.08-1.3l.35-.37a.34.34,0,0,0,.11-.29.36.36,0,0,0-.12-.28.4.4,0,0,0-.55,0,4.06,4.06,0,0,1-4.22,1.13,2,2,0,0,1-1.24-2,.41.41,0,0,1,.45-.37.42.42,0,0,1,.37.45,1.13,1.13,0,0,0,.73,1.13,3.3,3.3,0,0,0,3.35-1,1.23,1.23,0,0,1,1.68,0,1.19,1.19,0,0,1,.37.86,1.17,1.17,0,0,1-.34.87l-.39.4A5.29,5.29,0,0,1,137.41,102.07Z" fill="#010101" />
      <Path className="cls-4" d="M144.93,101.9c-2.58,0-5.86,0-5.86,0v-.82s3.27,0,5.85,0c.62,0,1.12,0,1.52,0a1.22,1.22,0,0,0,1.19-1.23,1.23,1.23,0,0,0-.37-.88,1.25,1.25,0,0,0-.86-.35,17.56,17.56,0,0,1-2.61-.14.41.41,0,0,1-.34-.47.42.42,0,0,1,.47-.34,14.6,14.6,0,0,0,2.47.13,2,2,0,0,1,1.45.59,2.06,2.06,0,0,1,.61,1.46,2,2,0,0,1-2,2C146.07,101.9,145.56,101.9,144.93,101.9Z" fill="#010101" />
      <Path className="cls-4" d="M147.07,101.73a.41.41,0,0,1,0-.81,2.13,2.13,0,0,0,1.57-.94c1.25-1.84.34-5.61.33-5.65a.42.42,0,0,1,.3-.5.43.43,0,0,1,.5.3c0,.17,1,4.17-.46,6.31a3,3,0,0,1-2.13,1.29Z" fill="#010101" />
      <Path className="cls-5" d="M208.73,32.62a1.13,1.13,0,0,0-1-1.12,1.13,1.13,0,0,0-2.25,0,1.13,1.13,0,0,0-1.09,1.13,1.15,1.15,0,0,0,1.13,1.15,1.13,1.13,0,0,0,2.18,0A1.13,1.13,0,0,0,208.73,32.62Z" fill="#fff" />
      <Path className="cls-5" d="M216.84,49.4a1.69,1.69,0,0,0-1.45-1.67,1.69,1.69,0,0,0-3.34,0,1.69,1.69,0,0,0,0,3.38,1.68,1.68,0,0,0,3.24,0A1.69,1.69,0,0,0,216.84,49.4Z" fill="#fff" />
      <Path className="cls-5" d="M214.47,74.48a1.24,1.24,0,0,0-1.06-1.22,1.23,1.23,0,0,0-1.22-1.08A1.25,1.25,0,0,0,211,73.25a1.24,1.24,0,0,0,0,2.47,1.25,1.25,0,0,0,1.19.89,1.22,1.22,0,0,0,1.18-.9A1.24,1.24,0,0,0,214.47,74.48Z" fill="#fff" />
      <Path className="cls-5" d="M180.21,106.4c-1.72-3.38-6-2.84-6-2.84l-.8,0h-.59l-.79,0s-4.23-.54-6,2.84.87,6.29,2.89,7a9,9,0,0,0,3.13.44h2a9,9,0,0,0,3.13-.44C179.34,112.69,181.94,109.78,180.21,106.4Z" fill="#fff" />
      <Path className="cls-3" d="M180.21,106.4a5.45,5.45,0,0,0-4.49-2.83,4.84,4.84,0,0,1,2.29,2.22c1.72,3.37-.88,6.29-2.9,7a8.91,8.91,0,0,1-3.13.44h-2a10.78,10.78,0,0,1-1.5-.09,3,3,0,0,0,.58.26,9,9,0,0,0,3.13.44h2a9,9,0,0,0,3.13-.44C179.34,112.69,181.94,109.77,180.21,106.4Z" fill="#bac2ca" />
      <Path className="cls-10" d="M180.21,106.4c-1.72-3.38-6-2.84-6-2.84l-.8,0h-.59l-.79,0s-4.23-.54-6,2.84.87,6.29,2.89,7a9,9,0,0,0,3.13.44h2a9,9,0,0,0,3.13-.44C179.34,112.69,181.94,109.78,180.21,106.4Z" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M172.55,103.65A4.67,4.67,0,0,0,169,108.6a5.17,5.17,0,0,0,3.74,5.21" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M173.34,103.65s3.5.63,3.5,4.95a5.13,5.13,0,0,1-3.65,5.21" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M172.1,101.72s.72.26.85,1.59" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M126.54,108.48c-1.4-2.76-4.86-2.32-4.86-2.32H119.9s-3.45-.44-4.86,2.32a4.15,4.15,0,0,0,2.36,5.72,7.31,7.31,0,0,0,2.56.36h1.66a7.31,7.31,0,0,0,2.56-.36A4.15,4.15,0,0,0,126.54,108.48Z" fill="#fff" />
      <Path className="cls-3" d="M126.55,108.48a4.47,4.47,0,0,0-3.67-2.31,4,4,0,0,1,1.86,1.81,4.15,4.15,0,0,1-2.36,5.72,7.31,7.31,0,0,1-2.56.36h-1.66a10.5,10.5,0,0,1-1.23-.07,3.23,3.23,0,0,0,.47.21,7.31,7.31,0,0,0,2.56.36h1.66a7.31,7.31,0,0,0,2.56-.36A4.16,4.16,0,0,0,126.55,108.48Z" fill="#bac2ca" />
      <Path className="cls-10" d="M126.54,108.48c-1.4-2.76-4.86-2.32-4.86-2.32H119.9s-3.45-.44-4.86,2.32a4.15,4.15,0,0,0,2.36,5.72,7.31,7.31,0,0,0,2.56.36h1.66a7.31,7.31,0,0,0,2.56-.36A4.15,4.15,0,0,0,126.54,108.48Z" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M117.83,112.44a4.26,4.26,0,0,0,2.58,2.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M120.28,106.24a3.7,3.7,0,0,0-2.8,2.86" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M120.93,106.24a3.79,3.79,0,0,1,2.86,4,4.2,4.2,0,0,1-3,4.26" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M121.17,104.66s-.58.21-.69,1.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M192,108.05c-1.41-2.76-4.86-2.33-4.86-2.33h-1.79s-3.45-.43-4.86,2.33a4.14,4.14,0,0,0,2.36,5.71,7.37,7.37,0,0,0,2.56.36h1.67a7.37,7.37,0,0,0,2.56-.36A4.14,4.14,0,0,0,192,108.05Z" fill="#fff" />
      <Path className="cls-3" d="M192,108.05a4.42,4.42,0,0,0-3.67-2.31,4,4,0,0,1,1.87,1.81,4.14,4.14,0,0,1-2.36,5.71,7.41,7.41,0,0,1-2.57.36h-1.66a10.64,10.64,0,0,1-1.23-.07,3.79,3.79,0,0,0,.47.21,7.37,7.37,0,0,0,2.56.36h1.67a7.37,7.37,0,0,0,2.56-.36A4.14,4.14,0,0,0,192,108.05Z" fill="#bac2ca" />
      <Path className="cls-10" d="M192,108.05c-1.41-2.76-4.86-2.33-4.86-2.33h-1.79s-3.45-.43-4.86,2.33a4.14,4.14,0,0,0,2.36,5.71,7.37,7.37,0,0,0,2.56.36h1.67a7.37,7.37,0,0,0,2.56-.36A4.14,4.14,0,0,0,192,108.05Z" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M183.28,112a4.3,4.3,0,0,0,2.58,2.11" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M185.74,105.8a3.73,3.73,0,0,0-2.81,2.86" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M186.39,105.8a3.79,3.79,0,0,1,2.85,4,4.2,4.2,0,0,1-3,4.26" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M186.63,104.22s-.59.22-.69,1.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-6" d="M185.61,43.92A3.21,3.21,0,0,1,186.85,41a4,4,0,0,1,3.33-.58l.15.19a4,4,0,0,1-1.46,3.06,3.23,3.23,0,0,1-3.12.41Z" fill="#dce2e7" />
      <Path className="cls-11" d="M190.22,40.5a4,4,0,0,0-3.34.58A3.22,3.22,0,0,0,185.65,44" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M190.36,40.69a4,4,0,0,1-1.46,3.06,3.21,3.21,0,0,1-3.11.41" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M202.73,42.78a2.85,2.85,0,0,0,2,1.9,3.54,3.54,0,0,0,2.9-.71l0-.21a3.54,3.54,0,0,0-2.26-1.95,2.85,2.85,0,0,0-2.66.76Z" fill="#e7ebee" />
      <Path className="cls-11" d="M207.66,43.92a3.55,3.55,0,0,1-2.9.7,2.81,2.81,0,0,1-2-1.9" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M207.71,43.71a3.51,3.51,0,0,0-2.25-2,2.83,2.83,0,0,0-2.67.76" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M204.1,40.85a2.83,2.83,0,0,0-2.7.59,3.53,3.53,0,0,0-1.06,2.79l.14.15a3.5,3.5,0,0,0,2.89-.75,2.83,2.83,0,0,0,.88-2.62Z" fill="#e7ebee" />
      <Path className="cls-11" d="M200.37,44.27a3.53,3.53,0,0,1,1.06-2.79,2.85,2.85,0,0,1,2.71-.59" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M200.52,44.43a3.51,3.51,0,0,0,2.88-.76,2.83,2.83,0,0,0,.88-2.62" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M191.05,43a2.52,2.52,0,0,0,2,1.38,3.16,3.16,0,0,0,2.44-1v-.19a3.13,3.13,0,0,0-2.25-1.39,2.52,2.52,0,0,0-2.22,1Z" fill="#e7ebee" />
      <Path className="cls-11" d="M195.52,43.3a3.09,3.09,0,0,1-2.43,1,2.5,2.5,0,0,1-2-1.38" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M195.54,43.12a3.16,3.16,0,0,0-2.25-1.4,2.55,2.55,0,0,0-2.23,1.05" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M200,45.6a3,3,0,0,0-.25-2.92,3.76,3.76,0,0,0-2.79-1.49l-.18.13a3.74,3.74,0,0,0,.4,3.13,3,3,0,0,0,2.63,1.28Z" fill="#e7ebee" />
      <Path className="cls-11" d="M196.89,41.22a3.74,3.74,0,0,1,2.78,1.49,3,3,0,0,1,.25,2.92" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M196.7,41.35a3.74,3.74,0,0,0,.4,3.13,3,3,0,0,0,2.64,1.28" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-12" d="M246.07,67.38a2.28,2.28,0,0,1-.55-2.93A12.32,12.32,0,0,0,247.36,58a13.11,13.11,0,0,0-6.15-10.88,1.27,1.27,0,0,1-.55-1.43,7,7,0,0,0,.3-2c0-4.34-4-7.85-8.81-7.85s-8.8,3.51-8.8,7.85a6.7,6.7,0,0,0,.3,2,1.28,1.28,0,0,1-.56,1.43A13.11,13.11,0,0,0,216.94,58a12.33,12.33,0,0,0,1.85,6.45,2.29,2.29,0,0,1-.55,2.93,17.07,17.07,0,0,0-6.3,13.05c0,10,9.05,18,20.21,18s20.22-8.07,20.22-18A17.11,17.11,0,0,0,246.07,67.38Z" fill="#d9dfe6" />
      <Line className="cls-10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="231.66" x2="231.66" y1="98.51" y2="112.93" />
      <Line className="cls-10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="231.66" x2="231.66" y1="60.35" y2="87.75" />
      <Line className="cls-10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="222.26" x2="231.43" y1="74.83" y2="80.85" />
      <Line className="cls-10" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="236.51" x2="231.62" y1="67.94" y2="71.18" />
      <Path className="cls-10" d="M246.07,67.38a2.28,2.28,0,0,1-.55-2.93A12.32,12.32,0,0,0,247.36,58a13.11,13.11,0,0,0-6.15-10.88,1.27,1.27,0,0,1-.55-1.43,7,7,0,0,0,.3-2c0-4.34-4-7.85-8.81-7.85s-8.8,3.51-8.8,7.85a6.7,6.7,0,0,0,.3,2,1.28,1.28,0,0,1-.56,1.43A13.11,13.11,0,0,0,216.94,58a12.33,12.33,0,0,0,1.85,6.45,2.29,2.29,0,0,1-.55,2.93,17.07,17.07,0,0,0-6.3,13.05c0,10,9.05,18,20.21,18s20.22-8.07,20.22-18A17.11,17.11,0,0,0,246.07,67.38Z" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-2" d="M247.82,94.9a2.24,2.24,0,0,1-2.2-.08,2.8,2.8,0,0,1-1.23-2l.09-.15a2.82,2.82,0,0,1,2.36.18,2.25,2.25,0,0,1,1.07,1.92Z" fill="#e7ebee" />
      <Path className="cls-11" d="M244.42,92.76a2.78,2.78,0,0,0,1.22,2,2.25,2.25,0,0,0,2.2.07" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M244.5,92.62a2.82,2.82,0,0,1,2.37.17,2.24,2.24,0,0,1,1.06,1.93" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M215.74,93a2.26,2.26,0,0,1-.83,2,2.83,2.83,0,0,1-2.33.46l-.1-.14a2.81,2.81,0,0,1,1-2.16,2.24,2.24,0,0,1,2.17-.33Z" fill="#e7ebee" />
      <Path className="cls-11" d="M212.56,95.41a2.79,2.79,0,0,0,2.32-.45,2.26,2.26,0,0,0,.83-2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M212.45,95.28a2.81,2.81,0,0,1,1-2.16,2.28,2.28,0,0,1,2.18-.34" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M242.41,79.38a2.26,2.26,0,0,1-.82,2,2.79,2.79,0,0,1-2.33.46l-.1-.13a2.78,2.78,0,0,1,1-2.16,2.28,2.28,0,0,1,2.18-.34Z" fill="#fff" />
      <Path className="cls-11" d="M239.23,81.84a2.81,2.81,0,0,0,2.33-.45,2.23,2.23,0,0,0,.82-2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M239.13,81.71a2.81,2.81,0,0,1,1-2.16,2.24,2.24,0,0,1,2.17-.33" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M252.36,101.16a2.26,2.26,0,0,1-.82,2,2.81,2.81,0,0,1-2.33.45l-.1-.13a2.78,2.78,0,0,1,1-2.16,2.26,2.26,0,0,1,2.18-.33Z" fill="#e7ebee" />
      <Path className="cls-11" d="M249.18,103.62a2.81,2.81,0,0,0,2.33-.45,2.26,2.26,0,0,0,.83-2" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M249.08,103.49a2.81,2.81,0,0,1,1-2.16,2.26,2.26,0,0,1,2.17-.34" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M231.82,60.56a2.24,2.24,0,0,1-2.19-.07,2.81,2.81,0,0,1-1.23-2l.08-.14a2.82,2.82,0,0,1,2.37.17,2.24,2.24,0,0,1,1.06,1.93Z" fill="#e7ebee" />
      <Path className="cls-11" d="M228.42,58.42a2.81,2.81,0,0,0,1.23,2,2.27,2.27,0,0,0,2.2.08" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M228.51,58.28a2.77,2.77,0,0,1,2.36.18,2.25,2.25,0,0,1,1.07,1.92" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M210.24,84.61a2.89,2.89,0,0,1,1-2.61,3.6,3.6,0,0,1,3-.6l.13.17a3.59,3.59,0,0,1-1.22,2.76,2.9,2.9,0,0,1-2.77.45Z" fill="#e7ebee" />
      <Path className="cls-11" d="M214.26,81.44a3.58,3.58,0,0,0-3,.61,2.87,2.87,0,0,0-1,2.6" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M214.4,81.61a3.59,3.59,0,0,1-1.22,2.76,2.87,2.87,0,0,1-2.77.45" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M217.2,103.36a2.87,2.87,0,0,1-2.56-1.13,3.56,3.56,0,0,1-.48-3l.17-.12a3.6,3.6,0,0,1,2.71,1.33,2.88,2.88,0,0,1,.33,2.79Z" fill="#fff" />
      <Path className="cls-11" d="M214.2,99.21a3.58,3.58,0,0,0,.48,3,2.86,2.86,0,0,0,2.56,1.14" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M214.37,99.08a3.57,3.57,0,0,1,2.71,1.34,2.88,2.88,0,0,1,.34,2.78" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M254.61,68.47a2.86,2.86,0,0,1-2.56-1.14,3.58,3.58,0,0,1-.48-3l.17-.12a3.59,3.59,0,0,1,2.71,1.33,2.9,2.9,0,0,1,.34,2.79Z" fill="#fff" />
      <Path className="cls-11" d="M251.62,64.32a3.58,3.58,0,0,0,.48,3,2.86,2.86,0,0,0,2.56,1.14" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M251.79,64.19a3.57,3.57,0,0,1,2.71,1.34,2.86,2.86,0,0,1,.33,2.78" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M244.67,56.17A2.87,2.87,0,0,1,246.38,54a3.58,3.58,0,0,1,3,.24l.09.2a3.58,3.58,0,0,1-1.95,2.31,2.87,2.87,0,0,1-2.78-.34Z" fill="#e7ebee" />
      <Path className="cls-11" d="M249.41,54.25a3.58,3.58,0,0,0-3-.24,2.88,2.88,0,0,0-1.71,2.22" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M249.5,54.45a3.59,3.59,0,0,1-1.94,2.31,2.88,2.88,0,0,1-2.79-.34" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-5" d="M225.82,76.86a2.89,2.89,0,0,1-1.16-2.56,3.57,3.57,0,0,1,1.63-2.54l.21,0a3.59,3.59,0,0,1,1.14,2.8,2.88,2.88,0,0,1-1.61,2.3Z" fill="#fff" />
      <Path className="cls-11" d="M226.34,71.76a3.58,3.58,0,0,0-1.62,2.55,2.84,2.84,0,0,0,1.16,2.55" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M226.56,71.78a3.56,3.56,0,0,1,1.13,2.8,2.86,2.86,0,0,1-1.6,2.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-10" d="M233.77,90.81a6.19,6.19,0,0,0-9.55-3.18" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-10" d="M239.22,90.92a4.23,4.23,0,0,0-5.84-1.77" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" />
      <Path className="cls-5" d="M213.31,88.08a.5.5,0,0,0-.43-.49.5.5,0,0,0-.49-.43.49.49,0,0,0-.49.43.72.72,0,1,0,1,1A.51.51,0,0,0,213.31,88.08Z" fill="#fff" />
      <Path className="cls-2" d="M225.13,114.75a2.46,2.46,0,0,1-2.4-.07,3.08,3.08,0,0,1-1.33-2.21l.1-.15a3,3,0,0,1,2.56.18,2.46,2.46,0,0,1,1.16,2.1Z" fill="#e7ebee" />
      <Path className="cls-11" d="M221.42,112.43a3,3,0,0,0,1.34,2.2,2.42,2.42,0,0,0,2.39.08" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M221.52,112.28a3,3,0,0,1,2.57.18,2.46,2.46,0,0,1,1.16,2.1" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M233.27,117.52a2.44,2.44,0,0,1,.6-2.31,3,3,0,0,1,2.44-.83l.13.13a3.05,3.05,0,0,1-.74,2.47,2.45,2.45,0,0,1-2.29.67Z" fill="#e7ebee" />
      <Path className="cls-11" d="M236.34,114.41a3.08,3.08,0,0,0-2.44.83,2.44,2.44,0,0,0-.59,2.32" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M236.47,114.54a3.05,3.05,0,0,1-.74,2.47,2.44,2.44,0,0,1-2.29.67" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M230.25,113a2.44,2.44,0,0,1-2.32.58,3.05,3.05,0,0,1-1.89-1.76l.05-.18a3.07,3.07,0,0,1,2.53-.51,2.44,2.44,0,0,1,1.68,1.7Z" fill="#e7ebee" />
      <Path className="cls-11" d="M226.06,111.74a3.06,3.06,0,0,0,1.88,1.76,2.44,2.44,0,0,0,2.32-.57" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M226.11,111.57a3,3,0,0,1,2.52-.52,2.43,2.43,0,0,1,1.68,1.7" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M236.63,113.66a2.76,2.76,0,0,1-2.68.44,3.5,3.5,0,0,1-2-2.16l.08-.2a3.45,3.45,0,0,1,2.9-.35,2.78,2.78,0,0,1,1.76,2.08Z" fill="#e7ebee" />
      <Path className="cls-11" d="M232,111.89a3.5,3.5,0,0,0,2,2.16,2.77,2.77,0,0,0,2.68-.44" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M232.07,111.69a3.45,3.45,0,0,1,2.9-.35,2.77,2.77,0,0,1,1.76,2.07" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M229.23,114.43a2.47,2.47,0,0,1,1.48-1.89,3.08,3.08,0,0,1,2.57.23l.07.17a3,3,0,0,1-1.68,2,2.43,2.43,0,0,1-2.37-.31Z" fill="#e7ebee" />
      <Path className="cls-11" d="M233.3,112.82a3,3,0,0,0-2.57-.23,2.45,2.45,0,0,0-1.48,1.88" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M233.36,113a3,3,0,0,1-1.67,2,2.44,2.44,0,0,1-2.37-.3" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M218.75,116a2.76,2.76,0,0,1-2,1.85,3.45,3.45,0,0,1-2.82-.69l-.05-.2a3.44,3.44,0,0,1,2.2-1.9,2.77,2.77,0,0,1,2.59.74Z" fill="#e7ebee" />
      <Path className="cls-11" d="M214,117.12a3.43,3.43,0,0,0,2.82.69,2.78,2.78,0,0,0,2-1.85" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M213.9,116.92a3.44,3.44,0,0,1,2.19-1.9,2.76,2.76,0,0,1,2.6.74" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M255.16,116.26a2.79,2.79,0,0,1-2.11,1.69,3.47,3.47,0,0,1-2.76-.91l0-.2a3.46,3.46,0,0,1,2.34-1.73,2.76,2.76,0,0,1,2.53,1Z" fill="#e7ebee" />
      <Path className="cls-11" d="M250.29,117a3.42,3.42,0,0,0,2.76.9,2.74,2.74,0,0,0,2.1-1.68" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M250.25,116.78a3.46,3.46,0,0,1,2.34-1.72,2.77,2.77,0,0,1,2.53.94" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M243.11,117.06a2.45,2.45,0,0,1-2.23.87,3.05,3.05,0,0,1-2.1-1.51l0-.18a3.07,3.07,0,0,1,2.44-.83,2.44,2.44,0,0,1,1.88,1.47Z" fill="#e7ebee" />
      <Path className="cls-11" d="M238.79,116.37a3.05,3.05,0,0,0,2.09,1.51,2.44,2.44,0,0,0,2.23-.87" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M238.82,116.19a3,3,0,0,1,2.44-.83,2.44,2.44,0,0,1,1.88,1.47" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M203.39,116.44a2.46,2.46,0,0,1-1.93,1.42,3,3,0,0,1-2.41-.9l0-.18a3.06,3.06,0,0,1,2.13-1.45,2.43,2.43,0,0,1,2.2.93Z" fill="#e7ebee" />
      <Path className="cls-11" d="M199,116.92a3.07,3.07,0,0,0,2.42.9,2.45,2.45,0,0,0,1.92-1.43" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M199,116.74a3,3,0,0,1,2.13-1.45,2.46,2.46,0,0,1,2.21.92" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M225.31,116.05a2.92,2.92,0,0,1,2.61-1.15,3.64,3.64,0,0,1,2.57,1.67l0,.22a3.64,3.64,0,0,1-2.86,1.13,2.94,2.94,0,0,1-2.33-1.65Z" fill="#e7ebee" />
      <Path className="cls-11" d="M230.49,116.63a3.64,3.64,0,0,0-2.58-1.68,2.92,2.92,0,0,0-2.61,1.16" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M230.47,116.85a3.64,3.64,0,0,1-2.86,1.13,2.92,2.92,0,0,1-2.33-1.65" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-2" d="M242.4,113a2.92,2.92,0,0,1,2.61-1.15,3.64,3.64,0,0,1,2.57,1.67l0,.22a3.64,3.64,0,0,1-2.86,1.13,2.94,2.94,0,0,1-2.33-1.65Z" fill="#e7ebee" />
      <Path className="cls-11" d="M247.58,113.61a3.64,3.64,0,0,0-2.58-1.68,2.92,2.92,0,0,0-2.61,1.16" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
      <Path className="cls-11" d="M247.56,113.82A3.64,3.64,0,0,1,244.7,115a2.92,2.92,0,0,1-2.33-1.65" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.55px" />
    </Svg>
  );
};

export default DesktopSelectedLoanFall;
