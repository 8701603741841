/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Ellipse,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function LossDraftInbox(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      fill="#000"
      height={moderateScale(120)}
      viewBox="0 0 287 120"
      width={moderateScale(287)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M237.65,104.48a.19.19,0,0,1-.19-.2V85.57a.19.19,0,0,1,.19-.2.2.2,0,0,1,.2.2v18.71A.2.2,0,0,1,237.65,104.48Z" />
      <Path d="M241.26,85.59H234a.2.2,0,0,1-.2-.2v-3a1.81,1.81,0,0,1,1.81-1.81h5.66a.19.19,0,0,1,.19.2v4.6A.19.19,0,0,1,241.26,85.59Zm-7.07-.39h6.87V81H235.6a1.41,1.41,0,0,0-1.41,1.41Z" />
      <Path d="M241,80.79h0a1.61,1.61,0,0,1,1.61,1.61v3a0,0,0,0,1,0,0h-3.22a0,0,0,0,1,0,0v-3A1.61,1.61,0,0,1,241,80.79Z" fill="#bac2ca" />
      <Path d="M242.64,85.59h-3.22a.2.2,0,0,1-.2-.2v-3a1.81,1.81,0,0,1,3.62,0v3A.2.2,0,0,1,242.64,85.59Zm-3-.39h2.83V82.4a1.42,1.42,0,0,0-2.83,0Z" />
      <Path d="M237.93,82.79a.2.2,0,0,1-.19-.14l-1-3.2-.58.21a.19.19,0,0,1-.14-.36l.78-.29a.22.22,0,0,1,.16,0,.24.24,0,0,1,.1.12l1.06,3.4a.2.2,0,0,1-.13.24Z" />
      <Path d="M199.93,65.43V62.38a2.18,2.18,0,0,1,.5-1.62l4.89,4.64Z" fill="#bac2ca" />
      <Polygon fill="#e6ebef" points="153.83 13.03 208.64 65.12 212.13 65.36 210.61 59.49 153.93 10.82 153.83 13.03" />
      <Path d="M209.2,65.43V62.38a3.26,3.26,0,0,1,3.27-3.27h0a3.26,3.26,0,0,1,3.27,3.27v3.05" fill="#e6ebef" />
      <Polygon fill="#e6ebef" points="204.01 68.09 192.81 80.72 191.98 83.11 194.75 83.16 207.92 68.14 204.01 68.09" />
      <Path d="M217.94,65.61l-5.51,0a3.66,3.66,0,0,0-3.59,2.68l-4.29,15.08V90l13.57.07Z" fill="#e6ebef" />
      <Path d="M191.29,90.41h-4.76V95.6S187.88,91.64,191.29,90.41Z" fill="#bac2ca" />
      <Path d="M186.61,99l-.08,5.11H191A14,14,0,0,1,188.3,102,17.77,17.77,0,0,1,186.61,99Z" fill="#bac2ca" />
      <Path d="M79.45,44.24a2.22,2.22,0,0,0,2-3.05c-1.25-3.08-3.88-7.48-8.89-8A8.63,8.63,0,0,0,67,34.84a.36.36,0,0,1-.44,0,6.29,6.29,0,0,0-4.3-1.61c-3.21,0-5.82,2.2-5.82,4.9h0a.35.35,0,0,1-.39.35l-.47,0A4.47,4.47,0,0,0,51.34,41a.36.36,0,0,1-.47.2,4.06,4.06,0,0,0-1.75-.36,3.68,3.68,0,0,0-3,1.66A1.1,1.1,0,0,0,47,44.24Z" fill="#e6ebef" />
      <Polygon fill="#e6ebef" points="186.3 104.31 159.09 104.31 159.09 56.1 172.44 51.02 178.05 51.99 186.3 61.01 186.3 104.31" />
      <Path d="M238.93,41.8a1,1,0,0,0,.78-1.69,6.87,6.87,0,0,0-5.13-1.89,9.28,9.28,0,0,0-1.06.06.64.64,0,0,1-.7-.64h0c0-1.94-2.46-3.51-5.49-3.51a8,8,0,0,0-2.35.34.61.61,0,0,1-.77-.44c-.64-2.89-3.73-5.08-7.45-5.08-4.18,0-7.58,2.77-7.58,6.18,0,.12,0,.24,0,.36a3.29,3.29,0,0,0-.54,0,3.52,3.52,0,0,0-3.34,2.44.63.63,0,0,1-.76.41,3.31,3.31,0,0,0-.95-.14,3.49,3.49,0,0,0-2.75,1.33A1.44,1.44,0,0,0,202,41.8Z" fill="#e6ebef" />
      <Path d="M253.8,104.47h-4a.19.19,0,0,1-.2-.19.2.2,0,0,1,.2-.2h4a.2.2,0,0,1,.2.2A.19.19,0,0,1,253.8,104.47Z" />
      <Path d="M61.47,83.09h0c-3.41-20.63-8.21-19.71-8.21-19.71v0s-4.6-.94-8,19.69h0a12.41,12.41,0,0,0-.16,2c0,5.57,3.7,10.08,8.27,10.08S61.63,90.61,61.63,85A12.41,12.41,0,0,0,61.47,83.09Z" fill="#eff3f6" />
      <Path d="M61.63,85a12.41,12.41,0,0,0-.16-2h0c-3.41-20.63-8.21-19.71-8.21-19.71v0a1.36,1.36,0,0,0-.79.18c1.22,2.48,5.08,10.89,5.46,18.73.35,7.19-2,10.91-4.63,12.81h.07C57.92,95.12,61.63,90.61,61.63,85Z" fill="#dce2e7" />
      <Path d="M53.36,95.32c-4.67,0-8.47-4.61-8.47-10.28a12.16,12.16,0,0,1,.15-1.9.22.22,0,0,1,0-.08c2.38-14.37,5.38-18.34,7-19.43a1.81,1.81,0,0,1,1.27-.44,2,2,0,0,1,1.37.48c1.62,1.12,4.68,5.13,7,19.39v0A12.55,12.55,0,0,1,61.82,85C61.82,90.71,58,95.32,53.36,95.32ZM45.43,83a.12.12,0,0,1,0,.08A12.52,12.52,0,0,0,45.29,85c0,5.45,3.62,9.88,8.07,9.88s8.07-4.43,8.07-9.88a11.39,11.39,0,0,0-.16-1.92v-.05c-3.31-20-7.93-19.49-8-19.49h-.07C53.17,63.58,48.75,63.09,45.43,83Z" />
      <Path d="M53.25,104.42a.2.2,0,0,1-.19-.2V84.93a.19.19,0,0,1,.19-.19.19.19,0,0,1,.2.19v19.29A.2.2,0,0,1,53.25,104.42Z" />
      <Path d="M53.16,89.28a.17.17,0,0,1-.11,0l-3-2.15a.18.18,0,0,1,0-.27.19.19,0,0,1,.27,0l3,2.15a.18.18,0,0,1,0,.27A.17.17,0,0,1,53.16,89.28Z" />
      <Path d="M53.33,91.8a.21.21,0,0,1-.16-.08.2.2,0,0,1,0-.28l3-2.15a.21.21,0,0,1,.28,0,.19.19,0,0,1,0,.27l-3,2.15A.15.15,0,0,1,53.33,91.8Z" />
      <Path d="M48.27,78.55h0a.21.21,0,0,1-.17-.23,42,42,0,0,1,2.16-7.79.19.19,0,0,1,.25-.11.19.19,0,0,1,.12.25,42.12,42.12,0,0,0-2.14,7.71A.19.19,0,0,1,48.27,78.55Z" />
      <Path d="M50.86,69.66l-.07,0a.18.18,0,0,1-.11-.25c.52-1.32.92-2.15.92-2.16a.2.2,0,0,1,.27-.09.19.19,0,0,1,.09.26s-.4.83-.92,2.13A.19.19,0,0,1,50.86,69.66Z" />
      <Path d="M34.18,104.46h-3.4a.18.18,0,0,1-.19-.19.19.19,0,0,1,.19-.2h3.4a.2.2,0,0,1,.2.2A.19.19,0,0,1,34.18,104.46Z" />
      <Polygon fill="#bac2ca" points="87.19 63.4 100 48.33 173.82 48.33 158.29 63.69 117.81 63.69 87.19 63.4" />
      <Polygon fill="#6d7d8e" points="165.69 48.32 130.16 48.32 127.56 50.89 163.02 50.89 165.69 48.32" />
      <Polygon fill="#fff" points="129.66 48.4 127.29 50.97 129.02 51.09 131.5 48.47 129.66 48.4" />
      <Line fill={svgHighlight} x1="129.37" x2="131.68" y1="50.98" y2="48.52" />
      <Path d="M129.37,51.18a.24.24,0,0,1-.13,0,.2.2,0,0,1,0-.28l2.3-2.46a.19.19,0,0,1,.28,0,.19.19,0,0,1,0,.27l-2.3,2.47A.2.2,0,0,1,129.37,51.18Z" />
      <Polygon fill="#6d7d8e" points="168.01 50.81 132.49 50.81 129.74 53.6 165.21 53.6 168.01 50.81" />
      <Polygon fill="#fff" points="132.1 51.02 129.73 53.6 131.03 53.72 132.85 52.15 132.1 51.02" />
      <Path d="M131.48,53.81a.2.2,0,0,1-.14-.06.19.19,0,0,1,0-.28l1.5-1.42-.66-.85a.2.2,0,1,1,.31-.24l.77,1a.21.21,0,0,1,0,.27l-1.63,1.54A.19.19,0,0,1,131.48,53.81Z" />
      <Polygon fill="#6d7d8e" points="170.64 53.39 135.12 53.39 132.51 56.1 167.97 56.1 170.64 53.39" />
      <Polygon fill="#fff" points="134.87 53.58 132.5 56.16 133.8 56.28 135.65 54.71 134.87 53.58" />
      <Polygon fill="#6d7d8e" points="172.56 48.63 157.45 63.76 130.83 63.76 146.26 48.39 172.56 48.63" />
      <Polygon fill={svgHighlight} points="162.43 56.08 138.78 56.08 131.13 63.68 154.72 63.68 162.43 56.08" />
      <Line fill="#fff" x1="170.72" x2="154.97" y1="48.49" y2="63.68" />
      <Path d="M167.89,53.62l5-4.88a1.15,1.15,0,0,1,1.57,0L188.66,63.6a.86.86,0,0,1-.12,1.31,1.14,1.14,0,0,1-1.47-.11l-13.45-14L158.88,65.2a1.14,1.14,0,0,1-1.48.07.86.86,0,0,1-.07-1.32l9.07-8.87" fill="#dce2e7" />
      <Path d="M159.35,66.33l.31.6a1,1,0,0,1-.73.35H121.44V65.51l36.83.08,1.08-.71Z" fill="#bac2ca" />
      <Polygon fill="#bac2ca" points="172.68 51.88 186.3 66.01 186.22 64.16 173.65 50.94 172.68 51.88" />
      <Polygon fill={svgHighlight} points="154.97 63.68 170.72 48.49 172.92 48.49 157.45 63.76 154.97 63.68" />
      <Path d="M157.33,64.15a.2.2,0,0,1-.14-.06.19.19,0,0,1,0-.28L167.9,53.29l4.83-4.69a1.32,1.32,0,0,1,.91-.36h0a.2.2,0,0,1,.2.2.19.19,0,0,1-.2.19h0a.94.94,0,0,0-.63.25l-4.83,4.69-2.05,2-8.66,8.48A.2.2,0,0,1,157.33,64.15Z" />
      <Path d="M158.1,65.7a1.33,1.33,0,0,1-.82-.28,1.05,1.05,0,0,1-.09-1.61.21.21,0,0,1,.28,0,.19.19,0,0,1,0,.28.67.67,0,0,0-.21.52.69.69,0,0,0,.26.5,1,1,0,0,0,1.22,0l14.74-14.43a.24.24,0,0,1,.14-.05.14.14,0,0,1,.14.06l13.45,14a.94.94,0,0,0,1.21.08.73.73,0,0,0,.29-.5.69.69,0,0,0-.19-.52L174.3,48.9a.92.92,0,0,0-.64-.27.2.2,0,0,1-.2-.2.21.21,0,0,1,.2-.19h0a1.32,1.32,0,0,1,.92.39L188.8,63.46a1,1,0,0,1,.3.82,1,1,0,0,1-.44.78,1.33,1.33,0,0,1-1.73-.12L173.62,51.05,159,65.34A1.29,1.29,0,0,1,158.1,65.7Z" />
      <Path d="M173.26,48.53H106.52a.19.19,0,0,1-.2-.19.2.2,0,0,1,.2-.2h66.74a.2.2,0,0,1,.19.2A.19.19,0,0,1,173.26,48.53Z" />
      <Path d="M159.35,104.34a.19.19,0,0,1-.19-.2V64.88a.19.19,0,0,1,.19-.2.2.2,0,0,1,.2.2v39.26A.2.2,0,0,1,159.35,104.34Z" />
      <Rect fill="#e6ebef" height="13.81" rx="6.91" width="30.86" x="193.56" y="90.2" />
      <Path d="M217.81,104.2H200.16a6.8,6.8,0,0,1-6.8-6.79V96.8a6.81,6.81,0,0,1,6.8-6.8h17.65a6.81,6.81,0,0,1,6.8,6.8v.61A6.8,6.8,0,0,1,217.81,104.2ZM200.16,90.4a6.41,6.41,0,0,0-6.41,6.4v.61a6.41,6.41,0,0,0,6.41,6.4h17.65a6.41,6.41,0,0,0,6.41-6.4V96.8a6.41,6.41,0,0,0-6.41-6.4Z" />
      <Path d="M193.25,104.47a6.87,6.87,0,0,1-6.8-6.93v-.61a6.74,6.74,0,0,1,6.8-6.83.2.2,0,0,1,0,.4,6.35,6.35,0,0,0-6.41,6.43v.61a6.48,6.48,0,0,0,6.41,6.53.2.2,0,0,1,0,.4Z" />
      <Path d="M217.91,102.57H200a5.34,5.34,0,0,1-5.33-5.33v-.47A5.34,5.34,0,0,1,200,91.44h17.88a5.33,5.33,0,0,1,5.33,5.33v.47A5.33,5.33,0,0,1,217.91,102.57ZM200,91.83a4.94,4.94,0,0,0-4.93,4.94v.47a4.93,4.93,0,0,0,4.93,4.93h17.88a4.94,4.94,0,0,0,4.94-4.93v-.47a5,5,0,0,0-4.94-4.94Z" />
      <Ellipse cx="201.62" cy="96.98" fill="#bac2ca" rx="1.39" ry="1.52" />
      <Path d="M186.37,104.33a.2.2,0,0,1-.2-.2V64.31a.2.2,0,1,1,.4,0v39.82A.2.2,0,0,1,186.37,104.33Z" />
      <Path d="M157.79,65.7H122.08a.19.19,0,0,1-.2-.19.2.2,0,0,1,.2-.2h35.71a.2.2,0,0,1,.2.2A.19.19,0,0,1,157.79,65.7Z" />
      <Path d="M157.45,64H121.32a.19.19,0,0,1-.19-.2.19.19,0,0,1,.19-.19h36.13a.2.2,0,1,1,0,.39Z" />
      <Polygon fill="#e6ebef" points="122.28 104.27 113.39 104.27 113.39 57.18 122.28 61.98 122.28 104.27" />
      <Polygon fill="#bac2ca" points="125.09 63.6 103.55 39.83 95.85 39.83 117.58 63.73 115.41 63.73 125.09 63.6" />
      <Path d="M100.44,44.9l-4.26-4.68a.9.9,0,0,0-1.34,0L74.72,64.31a.89.89,0,0,0,1.36,1.15L95.54,42.17l20.79,23a.84.84,0,0,0,.66.29.87.87,0,0,0,.6-.23.9.9,0,0,0,.06-1.26L101.71,46.3" fill="#fff" />
      <Polygon fill="#fff" points="95.47 42.21 113.42 61.98 113.42 105.63 79.29 105.63 79.29 62.52 78.28 62.52 95.47 42.21" />
      <Path d="M97.41,56.33h0a4.27,4.27,0,0,1,4.27,4.27v5.58a0,0,0,0,1,0,0H93.15a0,0,0,0,1,0,0V60.6A4.27,4.27,0,0,1,97.41,56.33Z" fill="#6d7d8e" />
      <Path d="M113.67,64.66l1.75,1.93a.93.93,0,0,0,.67.32H122V65.28l-5.3.08-3-3.2Z" fill="#bac2ca" />
      <Polygon fill="#bac2ca" points="79.17 61.98 79.17 65.85 75.98 65.89 79.17 61.98" />
      <Path d="M79,66H75.51a.19.19,0,0,1-.2-.19.2.2,0,0,1,.2-.2H79a.19.19,0,0,1,.19.2A.18.18,0,0,1,79,66Z" />
      <Polygon fill="#bac2ca" points="96.56 43.47 78.11 65.43 78.11 63.26 95.52 42.34 96.56 43.47" />
      <Path d="M125.25,63.92h-7.61a.2.2,0,1,1,0-.39h7.16L103.46,40H98.61l-3.12,0h0a.2.2,0,0,1,0-.4l3.11,0h5a.18.18,0,0,1,.14.06l21.7,23.9a.18.18,0,0,1,0,.21A.18.18,0,0,1,125.25,63.92Z" />
      <Path d="M117.1,65.52h7.84a.86.86,0,0,0,.83-.9h0a1.19,1.19,0,0,0-.43-.9h-7" fill="#dce2e7" />
      <Path d="M124.94,65.72H117.1a.2.2,0,0,1,0-.4h7.84a.66.66,0,0,0,.63-.7,1.12,1.12,0,0,0-.29-.7h-7a.19.19,0,0,1-.19-.2.19.19,0,0,1,.19-.19h7c.23,0,.63.55.63,1.09A1.06,1.06,0,0,1,124.94,65.72Z" />
      <Path d="M117.65,64.17a.2.2,0,0,1-.15-.06l-15.56-17.3a.19.19,0,0,1,0-.27.19.19,0,0,1,.28,0l15.56,17.29a.19.19,0,0,1,0,.28A.21.21,0,0,1,117.65,64.17Z" />
      <Path d="M75.4,66a1.08,1.08,0,0,1-.71-.26,1.1,1.1,0,0,1-.12-1.53L94.69,40.12a1.12,1.12,0,0,1,.81-.39h.15a.19.19,0,0,1,.17.22.19.19,0,0,1-.22.17h-.09a.69.69,0,0,0-.52.24L74.87,64.43a.72.72,0,0,0,.08,1,.71.71,0,0,0,1-.08L95.39,42.05a.19.19,0,0,1,.15-.07.2.2,0,0,1,.15.06l20.78,23a.73.73,0,0,0,1,0,.74.74,0,0,0,.22-.48.65.65,0,0,0-.18-.5.2.2,0,0,1,0-.28.18.18,0,0,1,.27,0,1.08,1.08,0,0,1,.29.78,1.1,1.1,0,0,1-.36.76,1.13,1.13,0,0,1-1.54-.07L95.55,42.47,76.23,65.59A1.08,1.08,0,0,1,75.4,66Z" />
      <Path d="M100.29,44.91a.2.2,0,0,1-.15-.06L96,40.36a.68.68,0,0,0-.44-.23.2.2,0,0,1,.05-.39,1.1,1.1,0,0,1,.68.35l4.1,4.49a.19.19,0,0,1,0,.28A.24.24,0,0,1,100.29,44.91Z" />
      <Path d="M79.13,104.28a.2.2,0,0,1-.2-.19V61.92a.2.2,0,1,1,.4,0v42.17A.2.2,0,0,1,79.13,104.28Z" />
      <Path d="M113.43,104.46a.2.2,0,0,1-.2-.19V83.46a.2.2,0,1,1,.4,0v20.81A.2.2,0,0,1,113.43,104.46Z" />
      <Path d="M113.43,81.8a.2.2,0,0,1-.2-.2V62.16a.2.2,0,0,1,.4,0V81.6A.2.2,0,0,1,113.43,81.8Z" />
      <Path d="M122.2,104.22A.19.19,0,0,1,122,104V65.62a.19.19,0,0,1,.19-.2.2.2,0,0,1,.2.2V104A.2.2,0,0,1,122.2,104.22Z" />
      <Path d="M101.47,66.32h-10a.2.2,0,0,1-.2-.2V59.9a5.13,5.13,0,0,1,5.12-5.12h.13a5.13,5.13,0,0,1,5.12,5.12v6.22A.2.2,0,0,1,101.47,66.32Zm-9.77-.4h9.58v-6a4.73,4.73,0,0,0-4.73-4.73h-.13A4.73,4.73,0,0,0,91.7,59.9Z" />
      <Path d="M93.15,66.26a.2.2,0,0,1-.2-.2V60.5a4.29,4.29,0,0,1,4.19-4.37h.5a4.7,4.7,0,0,1,3.63,1.77.2.2,0,0,1,0,.28.21.21,0,0,1-.28,0,4.4,4.4,0,0,0-3.32-1.62h-.5a3.91,3.91,0,0,0-3.81,4v5.56A.2.2,0,0,1,93.15,66.26Z" />
      <Path d="M101.32,91.47h-1a.66.66,0,0,0-.66.67v.17a.66.66,0,0,0,.66.67h1" fill="#e0e0e0" />
      <Rect fill="#e6ebef" height="10.88" width="8.77" x="92.28" y="80.43" />
      <Polygon fill="#fff" opacity="0.7" points="89.55 94.18 85.3 94.18 91.47 80.02 95.72 80.02 89.55 94.18" />
      <Polygon fill="#fff" opacity="0.7" points="96.89 94.18 94.24 94.18 100.41 80.02 103.06 80.02 96.89 94.18" />
      <Path d="M101.21,91.64H90.93a.2.2,0,0,1-.2-.2V79.13a.2.2,0,0,1,.2-.2h10.28a.19.19,0,0,1,.19.2V91.44A.19.19,0,0,1,101.21,91.64Zm-10.08-.4H101V79.33H91.13Z" />
      <Path d="M92.38,91.51a.19.19,0,0,1-.19-.2V80.54a.19.19,0,0,1,.19-.19h8.8a.2.2,0,1,1,0,.39h-8.6V91.31A.2.2,0,0,1,92.38,91.51Z" />
      <Path d="M101.27,93.4h-11a.92.92,0,0,1-.87-1v-.2a.92.92,0,0,1,.87-1h11a.2.2,0,0,1,.2.2V93.2A.2.2,0,0,1,101.27,93.4Zm-11-1.74a.54.54,0,0,0-.48.58v.2a.54.54,0,0,0,.48.57h10.79V91.66Z" />
      <Path d="M101.24,93.4h-1a.92.92,0,0,1-.87-1v-.2a.92.92,0,0,1,.87-1h1a.2.2,0,0,1,.2.2.19.19,0,0,1-.2.19h-1a.53.53,0,0,0-.47.58v.2a.53.53,0,0,0,.47.57h1a.19.19,0,0,1,.2.19A.2.2,0,0,1,101.24,93.4Z" />
      <Polyline fill={svgHighlight} points="133.1 104.27 133.1 79.12 146.82 79.12 146.82 104.03" />
      <Path d="M133.1,104.46a.2.2,0,0,1-.2-.19V79.12a.2.2,0,0,1,.2-.2h13.72a.19.19,0,0,1,.19.2V104a.19.19,0,0,1-.19.19.2.2,0,0,1-.2-.19V79.31H133.3v25A.2.2,0,0,1,133.1,104.46Z" />
      <Ellipse cx="139.87" cy="84.85" fill="#fff" rx="0.5" ry="0.49" />
      <Path d="M139.87,85.54a.69.69,0,1,1,.7-.69A.7.7,0,0,1,139.87,85.54Zm0-1a.3.3,0,1,0,.3.3A.3.3,0,0,0,139.87,84.55Z" />
      <Path d="M166.82,75.4h11.09a0,0,0,0,1,0,0V86a5.54,5.54,0,0,1-5.54,5.54h0A5.54,5.54,0,0,1,166.82,86V75.4A0,0,0,0,1,166.82,75.4Z" fill="#6d7d8e" transform="translate(344.74 166.99) rotate(180)" />
      <Path d="M178,92a.2.2,0,0,1-.2-.2V81.36a5.66,5.66,0,0,0-5.61-5.68,5.09,5.09,0,0,0-4.93,3.95.19.19,0,0,1-.25.13.2.2,0,0,1-.13-.25,5.53,5.53,0,0,1,5.31-4.23,6.06,6.06,0,0,1,6,6.08V91.78A.2.2,0,0,1,178,92Z" />
      <Path d="M179.6,91.91H167a.19.19,0,0,1-.2-.19V80.82a6.53,6.53,0,1,1,13.05,0v10.9A.19.19,0,0,1,179.6,91.91Zm-12.45-.39H179.4V80.82a6.13,6.13,0,1,0-12.25,0Z" />
      <Path d="M170.1,91.78h12.72a0,0,0,0,1,0,0v3.31a0,0,0,0,1,0,0H168.26a0,0,0,0,1,0,0V93.62A1.84,1.84,0,0,1,170.1,91.78Z" fill="#e6ebef" transform="translate(351.09 186.87) rotate(-180)" />
      <Path d="M181.29,95.29h-13a.19.19,0,0,1-.19-.2V91.78a.19.19,0,0,1,.19-.2h14.57a.19.19,0,0,1,.19.2v1.78A1.73,1.73,0,0,1,181.29,95.29Zm-12.83-.4h12.83a1.33,1.33,0,0,0,1.34-1.33V92H168.46Z" />
      <Path d="M168.5,91.78h0A1.53,1.53,0,0,1,170,93.31v1.78a0,0,0,0,1,0,0H167a0,0,0,0,1,0,0V93.31A1.53,1.53,0,0,1,168.5,91.78Z" fill="#bac2ca" transform="translate(337.01 186.87) rotate(-180)" />
      <Path d="M168.5,95.29a1.74,1.74,0,0,1-1.73-1.73V91.78a.2.2,0,0,1,.2-.2H170a.2.2,0,0,1,.2.2v1.78A1.73,1.73,0,0,1,168.5,95.29ZM167.17,92v1.58a1.34,1.34,0,0,0,2.67,0V92Z" />
      <Path d="M174.22,63.57c0,1.12-.53,2-1.18,2s-1.19-.92-1.19-2,.54-2.31,1.19-2.31S174.22,62.44,174.22,63.57Z" fill="#6d7d8e" />
      <Path d="M173.34,66c-1,0-1.82-1.11-1.82-2.49s.82-2.49,1.82-2.49,1.83,1.12,1.83,2.49S174.35,66,173.34,66Zm0-4.59c-.78,0-1.43,1-1.43,2.1s.65,2.1,1.43,2.1,1.44-.94,1.44-2.1S174.13,61.41,173.34,61.41Z" />
      <Path d="M173,66a.21.21,0,0,1-.19-.15.2.2,0,0,1,.13-.24,2.06,2.06,0,0,0,1.15-2.05,2.23,2.23,0,0,0-1-2,.2.2,0,1,1,.2-.34,2.56,2.56,0,0,1,1.15,2.3A2.42,2.42,0,0,1,173.1,66Z" />
      <Path d="M246.28,104.48H37.55a.19.19,0,0,1-.19-.2.19.19,0,0,1,.19-.19H246.28a.2.2,0,0,1,.19.19A.2.2,0,0,1,246.28,104.48Z" />
      <Path d="M154.55,63.87a.2.2,0,0,1-.14-.06.2.2,0,0,1,0-.28l15.75-15.18a.19.19,0,1,1,.27.28L154.69,63.82A.24.24,0,0,1,154.55,63.87Z" />
      <Path d="M130.77,63.87a.18.18,0,0,1-.14-.06.19.19,0,0,1,0-.28l7.72-7.41a.2.2,0,0,1,.27.29l-7.72,7.41A.19.19,0,0,1,130.77,63.87Z" />
      <Path d="M156.62,61.61H133.43a.2.2,0,1,1,0-.4h23.19a.2.2,0,0,1,0,.4Z" />
      <Path d="M159.62,59H135.94a.19.19,0,0,1-.19-.2.19.19,0,0,1,.19-.19h23.68a.2.2,0,1,1,0,.39Z" />
      <Path d="M162.19,56.48H132.24a.19.19,0,0,1-.18-.13.18.18,0,0,1,0-.21l2.61-2.57a.19.19,0,0,1,.27,0,.18.18,0,0,1,0,.27l-2.26,2.23h29.47a.2.2,0,1,1,0,.4Z" />
      <Path d="M134.65,53.83h-5a.2.2,0,0,1-.18-.12.2.2,0,0,1,0-.21l2.61-2.57a.2.2,0,0,1,.28,0,.21.21,0,0,1,0,.28l-2.27,2.23h4.54a.2.2,0,1,1,0,.39Z" />
      <Polygon fill={svgHighlight} points="168.77 38.13 133.24 38.13 130.64 40.7 166.1 40.7 168.77 38.13" />
      <Path d="M166.1,40.89H130.64a.2.2,0,0,1-.19-.12.19.19,0,0,1,.05-.21L133.1,38a.25.25,0,0,1,.14-.06h35.53a.19.19,0,0,1,.18.13.19.19,0,0,1,0,.21l-2.66,2.57A.24.24,0,0,1,166.1,40.89Zm-35-.39H166l2.26-2.17h-35Z" />
      <Path d="M132.24,51.27h-5a.2.2,0,0,1-.18-.12.2.2,0,0,1,0-.22l2.51-2.62a.19.19,0,0,1,.27,0,.19.19,0,0,1,0,.28l-2.19,2.28h4.55a.2.2,0,0,1,0,.4Z" />
      <Path d="M166.88,42.35H131.22a.2.2,0,0,1-.18-.12l-.59-1.46a.2.2,0,1,1,.37-.15l.53,1.34H166.8l2.33-2.34-.55-1.21a.2.2,0,0,1,.1-.26.2.2,0,0,1,.26.1l.61,1.34a.2.2,0,0,1,0,.22L167,42.29A.2.2,0,0,1,166.88,42.35Z" />
      <Path d="M166.88,42.35a.19.19,0,0,1-.17-.1l-.78-1.46a.19.19,0,1,1,.34-.19l.78,1.46a.19.19,0,0,1-.08.27Z" />
      <Path d="M134.11,56.45a.2.2,0,0,1-.14-.06.2.2,0,0,1,0-.28l1.45-1.41-.59-.88a.2.2,0,0,1,.06-.27.19.19,0,0,1,.27.05l.68,1a.18.18,0,0,1,0,.25l-1.57,1.53A.2.2,0,0,1,134.11,56.45Z" />
      <Path d="M87.47,87.39a.19.19,0,0,1-.1,0L69.67,75.84a.2.2,0,0,1-.08-.22.21.21,0,0,1,.19-.14H87.54a.2.2,0,0,1,0,.4H70.45L87.58,87a.2.2,0,0,1-.11.36Z" />
      <Path d="M87.54,87.69H69.78a.18.18,0,0,1-.19-.19.19.19,0,0,1,.19-.2H87.54a.2.2,0,0,1,.2.2A.19.19,0,0,1,87.54,87.69Z" />
      <Path d="M87.54,99.44H69.78a.19.19,0,0,1-.19-.19.19.19,0,0,1,.19-.2H87.54a.2.2,0,1,1,0,.39Z" />
      <Path d="M69.51,104.42a.2.2,0,0,1-.2-.2V73.4a.2.2,0,1,1,.39,0v30.82A.2.2,0,0,1,69.51,104.42Z" />
      <Path d="M87.82,104.42a.2.2,0,0,1-.2-.2V73.4a.2.2,0,1,1,.4,0v30.82A.2.2,0,0,1,87.82,104.42Z" />
      <Path d="M87.38,99.1a.19.19,0,0,1-.1,0L69.77,88a.2.2,0,1,1,.21-.33l17.51,11a.2.2,0,0,1,.06.27A.21.21,0,0,1,87.38,99.1Z" />
      <Path d="M69.87,99.1a.19.19,0,0,1-.16-.1.2.2,0,0,1,.06-.27L87.59,87.48a.2.2,0,1,1,.21.33L70,99.07A.2.2,0,0,1,69.87,99.1Z" />
      <Path d="M69.87,87.39a.18.18,0,0,1-.16-.09.2.2,0,0,1,.06-.27L87.59,75.78a.2.2,0,0,1,.21.33L70,87.36A.2.2,0,0,1,69.87,87.39Z" />
      <Path d="M133.14,38.26a.19.19,0,0,1-.12-.35l18-13.1a.21.21,0,0,1,.24,0l17,13.1a.2.2,0,0,1,0,.28.2.2,0,0,1-.28,0l-16.92-13-17.93,13A.15.15,0,0,1,133.14,38.26Z" />
      <Path d="M151.25,25.6A2.27,2.27,0,0,1,149,23.33a.19.19,0,0,1,.2-.19.19.19,0,0,1,.19.19,1.88,1.88,0,1,0,1.88-1.87.2.2,0,0,1-.2-.2V19.67a.2.2,0,1,1,.4,0v1.4a2.27,2.27,0,0,1-.2,4.53Z" />
      <Path d="M152.72,18.22h-1.47a.19.19,0,0,1-.2-.19.2.2,0,0,1,.2-.2h1.47a.92.92,0,0,0,.92-.92V10.5a.92.92,0,0,0-.92-.92h-1.47a.19.19,0,0,1-.2-.19.2.2,0,0,1,.2-.2h1.47A1.32,1.32,0,0,1,154,10.5v6.41A1.31,1.31,0,0,1,152.72,18.22Z" />
      <Path d="M151.25,18.22a1.31,1.31,0,0,1-1.31-1.31V15.33a.19.19,0,0,1,.2-.19.18.18,0,0,1,.19.19v1.58a.92.92,0,0,0,.92.92.2.2,0,0,1,.2.2A.19.19,0,0,1,151.25,18.22Z" />
      <Path d="M150.14,14.24a.2.2,0,0,1-.2-.2V10.5a1.31,1.31,0,0,1,1.31-1.31.2.2,0,0,1,.2.2.19.19,0,0,1-.2.19.92.92,0,0,0-.92.92V14A.19.19,0,0,1,150.14,14.24Z" />
      <Path d="M151.14,18.22h-1.71a1.31,1.31,0,0,1-1.31-1.31V10.5a1.31,1.31,0,0,1,1.31-1.31h1.67a.2.2,0,0,1,.2.2.19.19,0,0,1-.2.19h-1.67a.92.92,0,0,0-.91.92v6.41a.92.92,0,0,0,.91.92h1.71a.2.2,0,0,1,.2.2A.19.19,0,0,1,151.14,18.22Z" />
      <Rect height="1.93" width="0.39" x="151.06" y="17.96" />
      <Circle cx="152.05" cy="11.42" r="0.44" />
      <Circle cx="152.04" cy="15.91" r="0.44" />
      <Path d="M218,83.51h0l-26.26,0a.24.24,0,0,1-.16-.07.23.23,0,0,1,0-.18l4.16-15.11a3.86,3.86,0,0,1,3.71-2.83l18.58,0a.19.19,0,0,1,.2.19v17.8a.21.21,0,0,1-.2.2Zm-26-.41h25.8V65.71l-18.38,0a3.47,3.47,0,0,0-3.33,2.55Z" />
      <Path d="M204.76,83.49h0a.2.2,0,0,1-.14-.25l4.16-15.11a3.88,3.88,0,0,1,3.72-2.83.19.19,0,0,1,.19.2.18.18,0,0,1-.19.19,3.47,3.47,0,0,0-3.34,2.55L205,83.35A.18.18,0,0,1,204.76,83.49Z" />
      <Path d="M199,90.5H186.67a.2.2,0,1,1,0-.4H199a.2.2,0,0,1,0,.4Z" />
      <Path d="M191.77,90.4a.2.2,0,0,1-.2-.2V83.3a.2.2,0,1,1,.4,0v6.9A.2.2,0,0,1,191.77,90.4Z" />
      <Path d="M218,90.4a.2.2,0,0,1-.2-.2V83.3a.2.2,0,1,1,.4,0v6.9A.2.2,0,0,1,218,90.4Z" />
      <Path d="M204.75,90.23a.19.19,0,0,1-.19-.2V84.3a.19.19,0,0,1,.19-.2.2.2,0,0,1,.2.2V90A.2.2,0,0,1,204.75,90.23Z" />
      <Path d="M216.09,85.71h-4.46a.2.2,0,0,1,0-.4h4.46a.2.2,0,0,1,0,.4Z" />
      <Path d="M216.09,87.12h-4.46a.2.2,0,1,1,0-.39h4.46a.2.2,0,0,1,.19.2A.2.2,0,0,1,216.09,87.12Z" />
      <Path d="M216.09,88.54h-4.46a.2.2,0,0,1-.2-.2.19.19,0,0,1,.2-.19h4.46a.19.19,0,0,1,.19.19A.2.2,0,0,1,216.09,88.54Z" />
      <Path d="M194.87,92.89h-6.43a.19.19,0,0,1-.19-.19.19.19,0,0,1,.19-.2h6.43a.2.2,0,1,1,0,.39Z" />
      <Path d="M193.45,95.69h-6.78a.2.2,0,1,1,0-.39h6.78a.2.2,0,1,1,0,.39Z" />
      <Path d="M193.59,98.49h-6.9a.2.2,0,0,1-.2-.2.19.19,0,0,1,.2-.19h6.9a.19.19,0,0,1,.2.19A.2.2,0,0,1,193.59,98.49Z" />
      <Path d="M194.58,101.29h-6.92a.2.2,0,0,1-.2-.2.19.19,0,0,1,.2-.19h6.92a.19.19,0,0,1,.2.19A.2.2,0,0,1,194.58,101.29Z" />
      <Ellipse cx="217.96" cy="96.98" fill="#bac2ca" rx="1.39" ry="1.52" />
      <Ellipse cx="209.79" cy="96.98" fill="#bac2ca" rx="1.39" ry="1.52" />
      <Path d="M214.12,83.32a.2.2,0,0,1-.2-.2V69.82a1.67,1.67,0,0,0-1.67-1.67H196.08a.2.2,0,1,1,0-.39h16.17a2.06,2.06,0,0,1,2.06,2.06v13.3A.19.19,0,0,1,214.12,83.32Z" />
      <Path d="M205.18,65.56a.18.18,0,0,1-.13-.06L154,16.05a.19.19,0,0,1,0-.28.18.18,0,0,1,.27,0l51.1,49.45a.2.2,0,0,1-.14.34Z" />
      <Path d="M210.78,59.66a.21.21,0,0,1-.13,0l-56.81-49a.19.19,0,0,1,.25-.29l56.82,49a.2.2,0,0,1,0,.28A.23.23,0,0,1,210.78,59.66Z" />
      <Path d="M215.74,65.63a.2.2,0,0,1-.2-.2V62.38a3.08,3.08,0,1,0-6.15,0v3.05a.19.19,0,0,1-.19.2.2.2,0,0,1-.2-.2V62.38a3.47,3.47,0,1,1,6.94,0v3.05A.2.2,0,0,1,215.74,65.63Z" />
      <Path d="M200,65.63a.2.2,0,0,1-.19-.2V62.38a2.35,2.35,0,0,1,.5-1.74.22.22,0,0,1,.28,0,.2.2,0,0,1,0,.28,2,2,0,0,0-.43,1.5v3.05A.2.2,0,0,1,200,65.63Z" />
      <Line fill="#e6ebef" x1="153.83" x2="208.64" y1="13.03" y2="65.12" />
      <Path d="M208.85,65.59a.19.19,0,0,1-.14,0l-55-52.3a.19.19,0,0,1,0-.28.2.2,0,0,1,.28,0l55,52.31a.19.19,0,0,1,0,.27A.2.2,0,0,1,208.85,65.59Z" />
      <Path d="M165.55,40.75a.2.2,0,0,1-.14-.06L151.55,26.56a.21.21,0,0,1,0-.28.19.19,0,0,1,.28,0l13.86,14.14a.2.2,0,0,1,0,.27A.2.2,0,0,1,165.55,40.75Z" />
    </Svg>
  );
}
