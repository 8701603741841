import React from 'react';
import T from 'prop-types';
import { StatusBar } from 'react-native';

import InactivityTimer from '../InactivityTimer';
import Navigation from '../../navigation';

const AppContent = ({
  isGoldMigrationPLGeneralLogin,
  isGoldMigrationPLPreLogin,
  isGoldMigrationPLProfileTransfer,
  isLoggedIn,
  isLoggedOutViaSsoGeneric,
  isMurabahaAccount,
  isSslAuth,
  isSso,
}) => (
  <InactivityTimer isLoggedIn={isLoggedIn}>
    <Navigation
      isGoldMigrationPLGeneralLogin={isGoldMigrationPLGeneralLogin}
      isGoldMigrationPLPreLogin={isGoldMigrationPLPreLogin}
      isGoldMigrationPLProfileTransfer={isGoldMigrationPLProfileTransfer}
      isLoggedIn={isLoggedIn}
      isLoggedOutViaSsoGeneric={isLoggedOutViaSsoGeneric}
      isMurabahaAccount={isMurabahaAccount}
      isSslAuth={isSslAuth}
      isSso={isSso}
    />
    <StatusBar
      backgroundColor="white"
      barStyle="dark-content"
      translucent
    />
  </InactivityTimer>
);

AppContent.propTypes = {
  isGoldMigrationPLGeneralLogin: T.bool.isRequired,
  isGoldMigrationPLPreLogin: T.bool.isRequired,
  isGoldMigrationPLProfileTransfer: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  isLoggedOutViaSsoGeneric: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
};

export default AppContent;
