import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import iconDictionary from '../../../utils/iconDictionary';
import ConditionalRender from '../ConditionalRender';
import {
  AlertBannerContainer,
  AlertHeading,
  AlertLinkText,
  AlertTextContainer,
} from './styledComponents';

const WarningIcon = iconDictionary('warning', { height: 48, width: 48 });

const CovidAlertBanner = ({
  handleOpenDrawer,
  heading = 'Coronavirus Disease (Covid-19)',
  linkText = 'Learn more about Hardship Assistance',
}) => (
  <AlertBannerContainer>
    {WarningIcon}
    <AlertTextContainer>
      <ConditionalRender
        Component={<AlertHeading>{heading}</AlertHeading>}
        shouldRender={!!heading}
      />
      <Pressable onPress={() => handleOpenDrawer('hardshipAssistance')}>
        <AlertLinkText>{linkText}</AlertLinkText>
      </Pressable>
    </AlertTextContainer>
  </AlertBannerContainer>
);

CovidAlertBanner.propTypes = {
  handleOpenDrawer: T.func.isRequired,
  heading: T.string,
  linkText: T.string,
};

export default CovidAlertBanner;
