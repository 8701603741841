/*
 * Confirmation Screen
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import { resetSelectedLoan, resetStatusState } from '@dmi/shared/redux/Settings/Loans/actions';
import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import {
  selectPropertyAddress,
  selectRequestStatus,
  selectSelectedLoan,
} from '@dmi/shared/redux/Settings/Loans/selectors';

import { ConditionalRender } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { ConfirmationComponent } from '../../../components/Settings/ChangeMailingAddress';
import ConfirmationErrorScene from '../../../components/DocUpload/ConfirmationErrorScene';
import { StyledView } from './styledComponents';

const ConfirmationScreen = ({
  changeMailingAddressRequestStatus,
  customerServicePhoneNumber,
  dispatchResetSelectedLoan,
  dispatchResetStatusState,
  navigation,
  unmaskedSelectedLoan,
  uploadDocumentRequestStatus,
}) => {
  const {
    errorText,
    header,
  } = LOANS_CONTENT_DICTIONARY.changeMailingAddress.confirmationView.mobile;

  useEffect(() => () => {
    dispatchResetSelectedLoan();
    dispatchResetStatusState([
      'changeMailingAddress',
      'chooseDocument',
      'processDocument',
      'uploadDocument',
    ]);
  }, [dispatchResetSelectedLoan, dispatchResetStatusState]);
  return (
    <Fragment>
      <ConditionalRender
        Component={(
          <Fragment>
            <SecondaryHeader
              handleDone={() => navigation.navigate('Settings')}
              title={header}
            />
            <StyledView>
              <ConfirmationComponent
                unmaskedSelectedLoan={unmaskedSelectedLoan}
              />
            </StyledView>
          </Fragment>
        )}
        FallbackComponent={(
          <StyledView>
            <ConfirmationErrorScene
              customerServicePhoneNumber={customerServicePhoneNumber}
              label={'Return to Settings'}
              onPress={() => navigation.navigate('Settings')}
              route={'Settings'}
              text={errorText}
            />
          </StyledView>
        )}
        shouldRender={(
          uploadDocumentRequestStatus === 'succeeded' ||
          changeMailingAddressRequestStatus === 'succeeded')}
      />
    </Fragment>
  );
};

ConfirmationScreen.propTypes = {
  changeMailingAddressRequestStatus: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchResetSelectedLoan: T.func.isRequired,
  dispatchResetStatusState: T.func.isRequired,
  navigation: T.object.isRequired,
  unmaskedSelectedLoan: T.string.isRequired,
  uploadDocumentRequestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  changeMailingAddressRequestStatus: selectRequestStatus('changeMailingAddress'),
  propertyAddress: selectPropertyAddress(true),
  unmaskedSelectedLoan: selectSelectedLoan('unmaskedLoanNumber'),
  uploadDocumentRequestStatus: selectRequestStatus('uploadDocument'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loans
   */
  dispatchResetSelectedLoan: () => dispatch(resetSelectedLoan()),
  dispatchResetStatusState: (payload) => dispatch(resetStatusState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConfirmationScreen);
