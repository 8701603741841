/*
 * Change Email Step Two Screen
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeEmailStepTwo, resetFormState } from '@dmi/shared/redux/Register/ChangeEmail/actions';
import makeSelectChangeEmail, {
  getChangeEmailForm,
  getChangeEmailFormErrors,
} from '@dmi/shared/redux/Register/ChangeEmail/selectors';

import { ChangeEmailStepTwoFormInput } from '../../../components/Register/FormInputs';
import { CHANGE_EMAIL_STEP_TWO_CARD_PROPS } from './constants';
import SharedView from './SharedView';

const ChangeEmailStepTwoView = ({
  dispatchChangeEmailStepTwo,
  dispatchResetFormState,
  formErrors,
  formValues,
  navigation,
  token,
}) => {
  useEffect(() => () => dispatchResetFormState({ form: 'changeEmailStepTwo' }),
    [dispatchResetFormState]);

  return (
    <SharedView
      dispatchChangeEmail={dispatchChangeEmailStepTwo}
      formErrors={formErrors}
      formValues={formValues}
      handleNextStep={() => navigation.navigate('ChangeEmailConfirmation')}
      navigation={navigation}
      token={token}
      viewCardProps={CHANGE_EMAIL_STEP_TWO_CARD_PROPS}
      ViewFormInput={ChangeEmailStepTwoFormInput}
    />
  );
};

ChangeEmailStepTwoView.propTypes = {
  dispatchChangeEmailStepTwo: T.func.isRequired,
  dispatchResetFormState: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  navigation: T.object.isRequired,
  token: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  formErrors: getChangeEmailFormErrors('changeEmailStepTwo'),
  formValues: getChangeEmailForm('changeEmailStepTwo'),
  token: makeSelectChangeEmail('token'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchChangeEmailStepTwo: (requestBody) => dispatch(
    changeEmailStepTwo({ navigationType: 'replace', requestBody }),
  ),
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ChangeEmailStepTwoView);
