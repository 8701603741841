import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';

import iconDictionary from '../../../utils/iconDictionary';
import { Card, CardLink } from './Card';
import { ConditionalRender } from '../../base_ui';

const LoansAddressesIcon = iconDictionary('navMortgageDefaultM');
const PendingIcon = iconDictionary('outlinedPendingPurple');

const LoansAddressesCard = ({
  isLoanMancodeY,
  isSslAuth,
  isSso,
  navigation,
  pendingAddressesCount,
  registeredLoansCount,
  requestStatus,
}) => {
  const { formatMessage } = useIntl();

  const addressesDetails = pendingAddressesCount > 0 ?
    `${pendingAddressesCount === 1 ? '' : `${pendingAddressesCount} `}Pending` :
    '';

  return (
    <Card
      HeaderIcon={LoansAddressesIcon}
      headerText={formatMessage({ id: INTL_IDS.HOME_CONTAINER_HEADING })}
    >
      <View>
        <CardLink
          details={formatMessage(
            { id: INTL_IDS.HOME_CARDLINK_REGISTEREDLOANS_DETAILS }, { registeredLoansCount },
          )}
          handlePress={() => navigation.navigate('Loans')}
          label={formatMessage({ id: INTL_IDS.HOME_CARDLINK_REGISTEREDLOANS_LABEL })}
        />
        <CardLink
          details={addressesDetails}
          DetailsIcon={pendingAddressesCount > 0 ? PendingIcon : null}
          handlePress={() => navigation.navigate('SelectMailingAddress')}
          isDetailsLoading={requestStatus === 'loading'}
          isDisabled={isLoanMancodeY && registeredLoansCount === 1}
          isLoanMancodeY={isLoanMancodeY && registeredLoansCount === 1}
          label="Edit Mailing Addresses"
        />
        <ConditionalRender
          Component={CardLink}
          propsToPassDown={{
            handlePress: () => navigation.navigate('AddLoan'),
            label: formatMessage({ id: INTL_IDS.HOME_CARDLINK_ADDLOAN_LABEL }),
          }}
          shouldRender={!(isSslAuth || isSso)}
        />
      </View>
    </Card>
  );
};

LoansAddressesCard.propTypes = {
  isLoanMancodeY: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  navigation: T.object.isRequired,
  pendingAddressesCount: T.number.isRequired,
  registeredLoansCount: T.number.isRequired,
  requestStatus: T.string.isRequired,
};

export default LoansAddressesCard;
