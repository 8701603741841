/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

const MobilePaperlessSm = (props) => (
  <Svg height={moderateScale(219)} viewBox="0 0 165 219" width={moderateScale(165)} {...props}>
    <Path d="M38.4464 85.0598C37.6161 84.9675 36.8781 84.8753 36.0478 84.8753C31.1585 84.8753 26.7304 87.2738 24.0551 90.9639C23.5016 91.7019 22.5791 91.9786 21.6566 91.7019C20.6418 91.3329 19.4425 91.1484 18.2433 91.1484C14.9222 91.1484 12.0624 92.6244 10.1251 95.0229C10.1251 95.0229 8.00336 97.7905 7.91111 101.296H38.5386L38.4464 85.0598Z" fill="#EFF3F6" />
    <Path d="M11.1399 94.1004C11.1399 94.1004 14.2765 91.5174 19.4425 92.7166C24.6086 93.9159 25.1621 92.6244 26.7304 90.7794C28.2987 88.9343 34.2028 86.3513 38.7231 87.5506V85.152C38.7231 85.152 33.557 84.045 29.129 86.6281C24.7009 89.2111 24.1474 91.2406 23.5016 91.5174C22.8559 91.7941 22.5791 91.9786 20.4573 91.3329C18.3355 90.6871 13.5385 91.4251 11.1399 94.2849" fill="#DCE2E7" />
    <Path d="M10.2174 95.2997C10.1251 95.2997 10.0329 95.2997 10.0329 95.2074C9.84839 95.1152 9.8484 94.8384 9.94065 94.7462C11.9702 92.1631 15.0145 90.6871 18.3355 90.6871C19.5348 90.6871 20.7341 90.8716 21.8411 91.2406C22.5791 91.5174 23.4094 91.2406 23.7784 90.6871C26.6382 86.8126 31.2507 84.414 36.0478 84.414C36.8781 84.414 37.7083 84.5063 38.5386 84.5985C38.7231 84.5985 38.8154 84.783 38.8154 84.9675C38.8154 85.152 38.6309 85.2443 38.4464 85.2443C37.7083 85.152 36.8781 85.0598 36.0478 85.0598C31.4352 85.0598 27.0072 87.2738 24.3319 90.9639C23.6861 91.7941 22.5791 92.1631 21.5643 91.7941C20.5496 91.4251 19.4425 91.2406 18.3355 91.2406C15.2912 91.2406 12.4314 92.6244 10.4942 95.0229C10.4019 95.2997 10.3097 95.2997 10.2174 95.2997Z" fill="black" />
    <Path d="M158.65 101.388C158.65 101.204 158.65 101.112 158.65 100.927C158.65 97.7905 156.067 95.2074 152.93 95.2074C152.838 95.2074 152.654 95.2074 152.561 95.2074C150.716 91.9786 147.211 89.8569 143.244 89.8569C142.967 89.8569 142.691 89.8569 142.506 89.8569C141.491 89.9491 140.477 89.3956 139.923 88.4731C138.078 85.2443 134.572 83.1225 130.606 83.1225V101.296" fill="#E6EBEF" />
    <Path d="M134.849 94.0082H43.3357V101.481H134.849V94.0082Z" fill="#E6EBEF" />
    <Path d="M139.277 93.9159H132.82V101.388H139.277V93.9159Z" fill="#8A97A4" />
    <Path d="M102.746 93.9159H96.288V101.388H102.746V93.9159Z" fill="#BAC2CA" />
    <Path d="M75.9005 93.9159H69.4429V101.388H75.9005V93.9159Z" fill="#8A97A4" />
    <Path d="M45.273 93.9159H38.8154V101.388H45.273V93.9159Z" fill="#BAC2CA" />
    <Path d="M45.273 93.9159H38.8154V95.7609H45.273V93.9159Z" fill="#8A97A4" />
    <Path d="M102.653 93.9159H96.1958V95.7609H102.653V93.9159Z" fill="#8A97A4" />
    <Path d="M158.65 101.481C158.466 101.481 158.281 101.296 158.281 101.112V101.019C158.281 98.0672 155.883 95.6687 152.93 95.6687C152.838 95.6687 152.746 95.6687 152.561 95.6687C152.469 95.6687 152.285 95.5764 152.285 95.4842C150.44 92.2554 146.934 90.3181 143.244 90.3181C143.06 90.3181 142.875 90.3181 142.691 90.3181C141.399 90.4104 140.108 89.7646 139.462 88.5653C137.524 85.4288 134.203 83.5837 130.513 83.5837C130.329 83.5837 130.144 83.3992 130.144 83.2147C130.144 83.0302 130.329 82.8457 130.513 82.8457C134.388 82.8457 137.986 84.8753 140.015 88.1963C140.569 89.1188 141.584 89.6723 142.691 89.5801C142.875 89.5801 143.06 89.5801 143.244 89.5801C147.119 89.5801 150.716 91.6096 152.746 94.9307C152.838 94.9307 152.838 94.9307 152.93 94.9307C156.252 94.9307 159.019 97.606 159.019 101.019C159.019 101.112 159.019 101.204 159.019 101.204C159.019 101.296 158.835 101.481 158.65 101.481Z" fill="black" />
    <Path d="M150.071 96.499C149.978 96.499 149.886 96.499 149.886 96.4067C149.609 95.9455 149.333 95.4842 148.964 95.1152C148.871 95.0229 148.871 94.8384 148.964 94.7462C149.056 94.6539 149.24 94.6539 149.333 94.7462C149.702 95.2074 150.071 95.6687 150.347 96.13C150.44 96.2222 150.347 96.4067 150.255 96.4067C150.163 96.499 150.163 96.499 150.071 96.499Z" fill="black" />
    <Path d="M147.949 94.0082C147.857 94.0082 147.857 94.0082 147.857 94.0082C145.735 92.4399 143.429 92.3477 143.429 92.3477C143.336 92.3477 143.244 92.2554 143.244 92.0709C143.244 91.9786 143.336 91.7941 143.521 91.8864C143.613 91.8864 146.012 91.9786 148.226 93.6392C148.318 93.7314 148.318 93.8237 148.318 93.9159C148.041 94.0082 147.949 94.0082 147.949 94.0082Z" fill="black" />
    <Path d="M95.2733 54.6168H38.5386V52.7718L95.7345 52.495L99.1478 49.2662L99.2401 51.8492L97.026 53.8788C96.5648 54.34 95.919 54.6168 95.2733 54.6168Z" fill="#BAC2CA" />
    <Path d="M32.3578 49.3585L58.6494 23.2513H120.366L93.8895 49.4507H85.4024L32.3578 49.3585Z" fill="#BAC2CA" />
    <Path d="M120.366 23.6203H73.6864C73.5019 23.6203 73.3174 23.4358 73.3174 23.2513C73.3174 23.0668 73.5019 22.8823 73.6864 22.8823H120.366C120.55 22.8823 120.735 23.0668 120.735 23.2513C120.735 23.4358 120.55 23.6203 120.366 23.6203Z" fill="black" />
    <Path d="M111.417 32.2919L119.72 23.9893C120.089 23.6203 120.55 23.4358 121.011 23.4358C121.473 23.4358 122.026 23.6203 122.303 23.9893L146.104 49.2662C146.75 49.912 146.657 50.9267 145.919 51.4802C145.181 52.0338 144.074 51.9415 143.429 51.2957L120.919 27.4026L96.288 51.9415C95.919 52.3105 95.4578 52.495 94.9965 52.495C94.5353 52.495 94.1663 52.4028 93.7973 52.126C93.0592 51.5725 93.0592 50.5577 93.705 49.912L108.926 34.7827" fill="#DCE2E7" />
    <Path d="M121.011 27.4948L99.5168 48.9894V94.0082H138.908V51.9415H143.798L121.011 27.4948Z" fill="#E6EBEF" />
    <Path d="M108.004 93.9159V53.971H131.805V93.8237" fill="#BAC2CA" />
    <Path d="M128.945 56.4618H110.864V93.8237H128.945V56.4618Z" fill="#8A97A4" />
    <Path d="M119.351 29.3399L139.185 50.1887V47.1444L121.011 27.7716L119.351 29.3399Z" fill="#BAC2CA" />
    <Path d="M139.185 46.9599V51.9415L143.982 52.126L139.185 46.9599Z" fill="#BAC2CA" />
    <Path d="M81.9891 49.8197H32.2655C32.081 49.8197 31.9888 49.7275 31.9888 49.6352C31.8965 49.543 31.9888 49.3585 32.081 49.2662L58.4649 23.0668C58.5572 22.9745 58.6494 22.9745 58.7417 22.9745H69.9041C70.0886 22.9745 70.2731 23.159 70.2731 23.3435C70.2731 23.528 70.0886 23.7125 69.9041 23.7125H58.8339L33.0958 49.0817H81.9891C82.1736 49.0817 82.3581 49.2662 82.3581 49.4507C82.3581 49.6352 82.1736 49.8197 81.9891 49.8197Z" fill="black" />
    <Path d="M95.4578 52.864H32.819C32.6345 52.864 32.45 52.6795 32.45 52.495C32.45 52.3105 32.6345 52.126 32.819 52.126H95.4578C95.6423 52.126 95.8268 52.3105 95.8268 52.495C95.8268 52.6795 95.6423 52.864 95.4578 52.864Z" fill="black" />
    <Path d="M38.8154 94.0082C38.6309 94.0082 38.4464 93.8237 38.4464 93.6392V74.3586C38.4464 74.1741 38.6309 73.9896 38.8154 73.9896C38.9999 73.9896 39.1844 74.1741 39.1844 74.3586V93.6392C39.1844 93.8237 38.9999 94.0082 38.8154 94.0082Z" fill="black" />
    <Path d="M38.8154 72.6058C38.6309 72.6058 38.4464 72.4213 38.4464 72.2368V53.233C38.4464 53.0485 38.6309 52.864 38.8154 52.864C38.9999 52.864 39.1844 53.0485 39.1844 53.233V72.2368C39.1844 72.4213 38.9999 72.6058 38.8154 72.6058Z" fill="black" />
    <Path d="M99.5168 101.481C99.4246 101.481 99.3323 101.388 99.3323 101.296V94.0082C99.3323 93.9159 99.4246 93.8237 99.5168 93.8237C99.6091 93.8237 99.7013 93.9159 99.7013 94.0082V101.296C99.7013 101.388 99.6091 101.481 99.5168 101.481Z" fill="black" />
    <Path d="M135.772 101.481C135.679 101.481 135.587 101.388 135.587 101.296V94.0082C135.587 93.9159 135.679 93.8237 135.772 93.8237C135.864 93.8237 135.956 93.9159 135.956 94.0082V101.296C136.048 101.388 135.956 101.481 135.772 101.481Z" fill="black" />
    <Path d="M99.5168 94.2849C99.3323 94.2849 99.1478 94.1004 99.1478 93.9159V75.4656C99.1478 75.2811 99.3323 75.0966 99.5168 75.0966C99.7013 75.0966 99.8858 75.2811 99.8858 75.4656V93.9159C99.8858 94.1927 99.7013 94.2849 99.5168 94.2849Z" fill="black" />
    <Path d="M99.4246 72.5136C99.2401 72.5136 99.0556 72.3291 99.0556 72.1446V48.8049C99.0556 48.6204 99.2401 48.4359 99.4246 48.4359C99.6091 48.4359 99.7936 48.6204 99.7936 48.8049V72.2368C99.7936 72.4213 99.6091 72.5136 99.4246 72.5136Z" fill="black" />
    <Path d="M139.185 94.0082C139 94.0082 138.816 93.8237 138.816 93.6392V47.9747C138.816 47.7902 139 47.6057 139.185 47.6057C139.369 47.6057 139.554 47.7902 139.554 47.9747V93.7314C139.554 93.8237 139.369 94.0082 139.185 94.0082Z" fill="black" />
    <Path d="M144.074 52.3105H139.185C139 52.3105 138.816 52.126 138.816 51.9415C138.816 51.757 139 51.5725 139.185 51.5725H144.074C144.259 51.5725 144.443 51.757 144.443 51.9415C144.443 52.126 144.259 52.3105 144.074 52.3105Z" fill="black" />
    <Path d="M165.108 101.85H1.26901C1.0845 101.85 0.900002 101.665 0.900002 101.481C0.900002 101.296 1.0845 101.112 1.26901 101.112H165.2C165.384 101.112 165.569 101.296 165.569 101.481C165.569 101.665 165.384 101.85 165.108 101.85Z" fill="black" />
    <Path d="M94.9965 52.864C94.443 52.864 93.9818 52.6795 93.6127 52.4027C93.1515 52.0337 92.967 51.5725 92.8747 51.019C92.8747 50.4655 93.0592 50.0042 93.4282 49.6352L108.004 35.1517C108.096 35.0595 108.373 35.0595 108.465 35.1517C108.557 35.244 108.557 35.5207 108.465 35.613L93.9818 50.0965C93.705 50.3732 93.6127 50.65 93.6127 50.9267C93.6127 51.2957 93.7973 51.5725 94.074 51.757C94.6275 52.2182 95.55 52.2182 96.1035 51.6647L120.735 27.1258C120.827 27.0336 120.919 27.0336 121.011 27.0336C121.104 27.0336 121.196 27.0336 121.288 27.1258L143.798 51.019C144.259 51.5725 145.181 51.6647 145.827 51.2035C146.104 51.019 146.288 50.65 146.288 50.3732C146.288 50.0965 146.196 49.7275 146.012 49.4507L122.211 24.1738C121.934 23.897 121.565 23.7125 121.196 23.7125C120.827 23.7125 120.366 23.897 120.089 24.1738L112.063 32.1997C111.971 32.2919 111.694 32.2919 111.602 32.1997C111.51 32.1074 111.51 31.8307 111.602 31.7384L119.628 23.7125C119.997 23.3435 120.55 23.0668 121.196 23.0668C121.749 23.0668 122.395 23.3435 122.764 23.7125L146.565 48.9894C146.934 49.3584 147.119 49.912 147.026 50.3732C147.026 50.9267 146.75 51.388 146.288 51.757C145.458 52.4027 144.074 52.3105 143.336 51.5725L120.919 27.9561L96.5648 52.3105C96.1958 52.6795 95.6423 52.864 94.9965 52.864Z" fill="black" />
    <Path d="M35.4943 52.864H33.2803C32.1733 52.864 31.2507 52.0337 31.2507 50.9267C31.2507 49.8197 32.1733 48.9894 33.2803 48.9894H35.4943C35.6788 48.9894 35.8633 49.1739 35.8633 49.3584C35.8633 49.543 35.6788 49.7275 35.4943 49.7275H33.2803C32.5423 49.7275 31.8965 50.281 31.8965 50.9267C31.8965 51.5725 32.5423 52.126 33.2803 52.126H35.4943C35.6788 52.126 35.8633 52.3105 35.8633 52.495C35.8633 52.6795 35.6788 52.864 35.4943 52.864Z" fill="black" />
    <Path d="M18.7045 101.85C18.7045 100.281 19.9961 98.8053 21.5643 98.8053C22.7636 98.8053 23.7784 99.6355 24.1474 100.743C24.6086 100.097 25.2544 99.7278 25.9924 99.7278C27.1917 99.7278 28.2064 100.65 28.2987 101.85" fill="white" />
    <Path d="M28.3909 101.942C28.2064 101.942 28.0219 101.85 28.0219 101.665C27.9297 100.65 27.0994 99.9123 26.0847 99.9123C25.4389 99.9123 24.8854 100.189 24.5164 100.743C24.4241 100.835 24.3319 100.927 24.1474 100.927C24.0551 100.927 23.8706 100.835 23.8706 100.743C23.5016 99.82 22.5791 99.1742 21.5643 99.1742C20.2728 99.1742 19.0735 100.374 19.0735 101.665C19.0735 101.85 18.889 102.034 18.7045 102.034C18.52 102.034 18.3355 101.85 18.3355 101.665C18.3355 99.9123 19.8116 98.4362 21.5643 98.4362C22.6714 98.4362 23.6861 99.082 24.2396 100.005C24.7009 99.5433 25.3466 99.2665 26.0847 99.2665C27.4684 99.2665 28.5754 100.281 28.76 101.665C28.76 101.757 28.5754 101.942 28.3909 101.942Z" fill="black" />
    <Path d="M93.9818 49.8197H84.8489C84.6644 49.8197 84.4799 49.6352 84.4799 49.4507C84.4799 49.2662 84.6644 49.0817 84.8489 49.0817H93.9818C94.1663 49.0817 94.3508 49.2662 94.3508 49.4507C94.3508 49.6352 94.1663 49.8197 93.9818 49.8197Z" fill="black" />
    <Path d="M99.1478 60.4286H68.7048C68.6126 60.4286 68.5203 60.3364 68.5203 60.2441C68.5203 60.1519 68.6126 60.0596 68.7048 60.0596H99.1478C99.2401 60.0596 99.3323 60.1519 99.3323 60.2441C99.3323 60.3364 99.3323 60.4286 99.1478 60.4286Z" fill="black" />
    <Path d="M66.4908 60.4286H43.4279C43.3357 60.4286 43.2434 60.3364 43.2434 60.2441C43.2434 60.1519 43.2434 59.9674 43.4279 59.9674H66.4908C66.5831 59.9674 66.6753 60.0596 66.6753 60.1519C66.6753 60.2441 66.5831 60.4286 66.4908 60.4286Z" fill="black" />
    <Path d="M99.1478 77.0339H62.3395C62.2472 77.0339 62.155 76.9416 62.155 76.8494C62.155 76.7571 62.2472 76.6649 62.3395 76.6649H99.1478C99.2401 76.6649 99.3323 76.7571 99.3323 76.8494C99.3323 76.9416 99.3323 77.0339 99.1478 77.0339Z" fill="black" />
    <Path d="M58.5572 77.0339H43.4279C43.3357 77.0339 43.2434 76.9416 43.2434 76.8494C43.2434 76.7571 43.2434 76.5726 43.4279 76.5726H58.5572C58.6494 76.5726 58.7417 76.6649 58.7417 76.7571C58.7417 76.8494 58.6494 77.0339 58.5572 77.0339Z" fill="black" />
    <Path d="M99.1478 85.7978H82.1736C82.0813 85.7978 81.9891 85.7055 81.9891 85.6133C81.9891 85.521 82.0813 85.4288 82.1736 85.4288H99.1478C99.2401 85.4288 99.3323 85.521 99.3323 85.6133C99.3323 85.7055 99.3323 85.7978 99.1478 85.7978Z" fill="black" />
    <Path d="M79.1293 85.7978H38.8154C38.7231 85.7978 38.6309 85.7055 38.6309 85.6133C38.6309 85.521 38.7231 85.4288 38.8154 85.4288H79.037C79.1293 85.4288 79.2215 85.521 79.2215 85.6133C79.2215 85.7055 79.2215 85.7978 79.1293 85.7978Z" fill="black" />
    <Path d="M94.9043 68.639H87.4319C87.3396 68.639 87.2474 68.5468 87.2474 68.4545C87.2474 68.3623 87.3396 68.27 87.4319 68.27H94.9043C94.9965 68.27 95.0888 68.3623 95.0888 68.4545C95.0888 68.5468 95.0888 68.639 94.9043 68.639Z" fill="black" />
    <Path d="M85.2179 68.639H39.0921C38.9999 68.639 38.9076 68.5468 38.9076 68.4545C38.9076 68.3623 38.9999 68.27 39.0921 68.27H85.2179C85.3101 68.27 85.4024 68.3623 85.4024 68.4545C85.4024 68.5468 85.4024 68.639 85.2179 68.639Z" fill="black" />
    <Path d="M108.004 94.2849C107.819 94.2849 107.635 94.1004 107.635 93.9159V53.971C107.635 53.7865 107.819 53.602 108.004 53.602H131.805C131.989 53.602 132.174 53.7865 132.174 53.971V93.8237C132.174 94.0082 131.989 94.1927 131.805 94.1927C131.62 94.1927 131.436 94.0082 131.436 93.8237V54.2478H108.373V93.8237C108.373 94.1004 108.188 94.2849 108.004 94.2849Z" fill="black" />
    <Path d="M128.945 94.0082C128.853 94.0082 128.761 93.9159 128.761 93.8237V56.8308H111.141V93.7314C111.141 93.8237 111.048 93.9159 110.956 93.9159C110.864 93.9159 110.772 93.8237 110.772 93.7314V56.5541C110.772 56.4618 110.864 56.3696 110.956 56.3696H129.037C129.13 56.3696 129.222 56.4618 129.222 56.5541V93.7314C129.222 93.9159 129.13 94.0082 128.945 94.0082Z" fill="black" />
    <Path d="M139.277 94.2849H38.8154C38.6309 94.2849 38.4464 94.1004 38.4464 93.9159C38.4464 93.7314 38.6309 93.5469 38.8154 93.5469H139.277C139.462 93.5469 139.646 93.7314 139.646 93.9159C139.646 94.1004 139.462 94.2849 139.277 94.2849Z" fill="black" />
    <Path d="M102.746 101.573C102.561 101.573 102.377 101.388 102.377 101.204V94.1004C102.377 93.9159 102.561 93.7314 102.746 93.7314C102.93 93.7314 103.115 93.9159 103.115 94.1004V101.296C103.115 101.481 102.93 101.573 102.746 101.573Z" fill="black" />
    <Path d="M96.3803 101.573C96.1958 101.573 96.0113 101.388 96.0113 101.204V94.1004C96.0113 93.9159 96.1958 93.7314 96.3803 93.7314C96.5648 93.7314 96.7493 93.9159 96.7493 94.1004V101.296C96.657 101.481 96.5648 101.573 96.3803 101.573Z" fill="black" />
    <Path d="M42.0442 101.481C41.9519 101.481 41.8597 101.388 41.8597 101.296V94.0082C41.8597 93.9159 41.9519 93.8237 42.0442 93.8237C42.1364 93.8237 42.2287 93.9159 42.2287 94.0082V101.296C42.2287 101.388 42.1364 101.481 42.0442 101.481Z" fill="black" />
    <Path d="M45.273 101.573C45.0885 101.573 44.904 101.388 44.904 101.204V94.1004C44.904 93.9159 45.0885 93.7314 45.273 93.7314C45.4575 93.7314 45.642 93.9159 45.642 94.1004V101.296C45.642 101.481 45.4575 101.573 45.273 101.573Z" fill="black" />
    <Path d="M38.8154 101.573C38.6309 101.573 38.4464 101.388 38.4464 101.204V94.1004C38.4464 93.9159 38.6309 93.7314 38.8154 93.7314C38.9999 93.7314 39.1844 93.9159 39.1844 94.1004V101.296C39.1844 101.481 38.9999 101.573 38.8154 101.573Z" fill="black" />
    <Path d="M72.5794 101.481C72.4872 101.481 72.3949 101.388 72.3949 101.296V94.0082C72.3949 93.9159 72.4872 93.8237 72.5794 93.8237C72.6717 93.8237 72.7639 93.9159 72.7639 94.0082V101.296C72.8562 101.388 72.6717 101.481 72.5794 101.481Z" fill="black" />
    <Path d="M75.8082 101.573C75.6237 101.573 75.4392 101.388 75.4392 101.204V94.1004C75.4392 93.9159 75.6237 93.7314 75.8082 93.7314C75.9927 93.7314 76.1772 93.9159 76.1772 94.1004V101.296C76.1772 101.481 75.9927 101.573 75.8082 101.573Z" fill="black" />
    <Path d="M69.4429 101.573C69.2583 101.573 69.0739 101.388 69.0739 101.204V94.1004C69.0739 93.9159 69.2583 93.7314 69.4429 93.7314C69.6274 93.7314 69.8119 93.9159 69.8119 94.1004V101.296C69.8119 101.481 69.6274 101.573 69.4429 101.573Z" fill="black" />
    <Path d="M139.185 101.573C139 101.573 138.816 101.388 138.816 101.204V94.1004C138.816 93.9159 139 93.7314 139.185 93.7314C139.369 93.7314 139.554 93.9159 139.554 94.1004V101.296C139.554 101.481 139.369 101.573 139.185 101.573Z" fill="black" />
    <Path d="M132.82 101.573C132.635 101.573 132.451 101.388 132.451 101.204V94.1004C132.451 93.9159 132.635 93.7314 132.82 93.7314C133.004 93.7314 133.189 93.9159 133.189 94.1004V101.296C133.189 101.481 133.004 101.573 132.82 101.573Z" fill="black" />
    <Path d="M112.524 121.038C112.524 121.038 120.55 120.577 121.473 114.027C122.303 107.477 118.059 100.374 104.867 92.9011C91.6755 85.4288 85.5869 90.0413 85.5869 90.0413C85.5869 90.0413 82.3581 88.4731 78.1145 89.3033C73.8709 90.2258 72.0259 92.9934 72.0259 92.9934C72.0259 92.9934 67.5978 92.5321 64.369 94.6539C61.1402 96.7757 55.9741 101.112 52.0996 107.569C48.225 114.027 46.749 119.008 46.6567 122.145C46.5645 125.374 47.3025 130.263 50.9003 131.739C54.4981 133.215 59.4797 131.831 61.6015 131.186C63.7233 130.632 112.524 121.038 112.524 121.038Z" fill="white" />
    <Path d="M165.108 25.6498C165.753 25.6498 166.122 24.8195 165.753 24.3583C165.015 23.4358 163.447 22.8823 161.694 22.8823C161.418 22.8823 161.141 22.8823 160.864 22.9745C160.587 22.9745 160.311 22.79 160.311 22.5133C160.311 21.0372 158.373 19.7457 155.975 19.7457C155.329 19.7457 154.683 19.838 154.13 20.0225C153.853 20.1147 153.576 19.9302 153.484 19.6535C153.023 17.4394 150.532 15.6866 147.672 15.6866C144.351 15.6866 141.768 17.8084 141.768 20.4837C141.768 20.576 141.768 20.6682 141.768 20.7605C141.676 20.7605 141.491 20.7605 141.307 20.7605C140.107 20.7605 139 21.5907 138.724 22.6978C138.631 22.9745 138.355 23.0668 138.17 22.9745C137.893 22.8823 137.709 22.8823 137.432 22.8823C136.602 22.8823 135.772 23.2513 135.31 23.897C134.757 24.635 135.218 25.7421 136.233 25.7421H165.108V25.6498Z" fill="#E6EBEF" />
    <Path d="M55.8819 13.7494C57.8192 13.7494 59.1107 11.8121 58.3727 9.96706C56.8044 6.18475 53.5756 0.741912 47.3948 0.0961514C44.8117 -0.180603 42.4132 0.834163 40.4759 2.12568C40.2914 2.21794 40.1069 2.21794 39.9224 2.03343C38.6309 0.834163 36.6936 0.0961514 34.5718 0.0961514C30.605 0.0961514 27.3762 2.77145 27.3762 6.18475C27.3762 6.4615 27.1917 6.64601 26.9149 6.64601C26.7304 6.64601 26.5459 6.64601 26.3614 6.64601C23.9629 6.64601 21.8411 7.93753 21.1031 9.78256C21.0108 9.96706 20.7341 10.1516 20.5496 10.0593C19.9038 9.78256 19.1658 9.59805 18.4278 9.59805C16.675 9.69031 15.4757 10.6128 14.7377 11.6276C14.092 12.5501 14.7377 13.7494 15.8447 13.7494H55.8819Z" fill="#E6EBEF" />
    <Path d="M66.3986 160.983C66.3986 160.983 62.893 159.876 59.7564 162.736C56.6199 165.688 55.9741 172.33 58.3727 179.156L60.0332 184.599C60.9557 187.551 63.631 189.488 66.6753 189.488L67.5978 189.396" fill="#DCE2E7" />
    <Path d="M65.1993 102.403C65.1993 102.403 59.1107 110.706 57.1734 116.056C55.2361 121.407 54.9594 131.001 55.6974 132.477C55.6974 132.477 60.1255 132.016 60.4022 131.278C60.679 130.54 57.3579 113.381 65.1993 102.403Z" fill="#DCE2E7" />
    <Path d="M95.181 96.2222C95.181 96.2222 104.129 101.296 108.096 105.263C112.063 109.322 116.307 117.901 116.214 119.654C116.214 119.654 111.971 121.038 111.417 120.484C110.956 120.023 106.897 102.957 95.181 96.2222Z" fill="#DCE2E7" />
    <Path d="M42.0442 166.979C42.0442 166.979 43.7047 174.636 50.1623 177.035C56.6199 179.433 58.3727 179.156 58.3727 179.156L60.9557 186.444C60.9557 186.444 52.6531 183.4 47.7638 178.972C42.7822 174.451 42.0442 166.979 42.0442 166.979Z" fill="#DCE2E7" />
    <Path d="M84.6644 168.455C84.6644 168.455 85.2179 183.4 95.919 185.245L96.7493 187.274C96.7493 187.274 93.705 188.935 89.3692 188.381C89.3692 188.381 85.2179 187.551 84.4799 187.09C83.6496 186.721 84.6644 168.455 84.6644 168.455Z" fill="#DCE2E7" />
    <Path d="M80.0518 160.429L81.9891 164.673L101.639 154.156L99.7013 150.558L104.222 150.189L102.469 147.699C102.469 147.699 107.45 143.27 108.373 141.149L106.528 139.673L106.251 133.676L105.421 133.584L104.129 135.706L101.362 134.322L98.5943 143.639L95.0888 144.839L96.8415 148.437L92.4135 149.175L95.3655 153.049L80.0518 160.429Z" fill="#DCE2E7" />
    <Path d="M87.2474 132.477C87.0629 132.477 86.9706 132.385 86.8784 132.2L85.0334 123.252C85.0334 123.067 85.1256 122.883 85.3101 122.883C85.4946 122.883 85.6791 122.975 85.6791 123.16L87.5242 132.108C87.6164 132.293 87.5241 132.477 87.2474 132.477C87.2474 132.477 87.3396 132.477 87.2474 132.477Z" fill="black" />
    <Path d="M91.7677 114.949C91.3065 112.92 87.5241 112.089 83.2806 113.012C79.037 113.934 75.9005 116.241 76.2695 118.27C76.2695 118.455 76.3617 118.639 76.454 118.824C77.0997 120.3 79.4983 124.174 85.2179 123.898V123.99L85.4946 123.898H85.7714V123.805C92.4135 121.222 91.7677 114.949 91.7677 114.949Z" fill="black" />
    <Path d="M55.2361 133.215C52.7453 133.215 49.8855 132.477 48.0405 129.433C44.166 122.975 46.4722 114.027 54.7749 102.864C63.9078 90.5949 71.4724 92.2554 71.7491 92.3476C72.0259 92.4399 72.1181 92.6244 72.1181 92.9011C72.0259 93.1779 71.8414 93.2702 71.5646 93.2702C71.4724 93.2702 64.2768 91.7941 55.5129 103.418C47.3948 114.211 45.1807 122.791 48.7785 128.879C51.9151 134.138 58.9262 131.647 59.2029 131.555C59.4797 131.462 59.6642 131.555 59.7564 131.831C59.8487 132.108 59.7565 132.293 59.4797 132.385C59.4797 132.569 57.5424 133.215 55.2361 133.215Z" fill="black" />
    <Path d="M84.2953 149.913C77.3765 149.913 66.3986 147.975 61.6937 136.721C54.6826 119.839 64.9225 101.85 65.0148 101.757C65.107 101.573 65.3838 101.481 65.6605 101.573C65.845 101.665 65.9373 101.942 65.845 102.219C65.7528 102.403 55.7896 119.931 62.524 136.444C69.2583 152.772 89.8304 148.621 90.0149 148.621C90.2917 148.529 90.4762 148.713 90.5684 148.99C90.6607 149.267 90.4762 149.451 90.1994 149.544C90.1994 149.451 87.8009 149.913 84.2953 149.913Z" fill="black" />
    <Path d="M112.524 121.499C112.248 121.499 112.063 121.315 112.063 121.038C112.063 120.761 112.248 120.577 112.524 120.577C112.801 120.577 120.181 120.115 121.011 114.027C121.934 107.016 116.399 100.005 104.683 93.3624C91.9522 86.1668 85.9559 90.4104 85.9559 90.4104C85.7714 90.5026 85.5869 90.5026 85.4946 90.4104C85.4946 90.4104 82.3581 88.9343 78.299 89.7646C74.3322 90.5949 72.5794 93.2702 72.4872 93.2702C72.3027 93.4547 72.0259 93.5469 71.8414 93.3624C71.6569 93.1779 71.5646 92.9012 71.7491 92.7166C71.8414 92.6244 73.7787 89.7646 78.1145 88.8421C81.8046 88.1041 84.7566 89.1188 85.5869 89.4878C86.8784 88.7498 93.0592 85.7055 105.144 92.5321C117.229 99.451 122.949 106.647 121.934 114.119C121.011 120.946 112.617 121.499 112.524 121.499Z" fill="black" />
    <Path d="M91.0297 149.267C90.8452 149.267 90.6607 149.082 90.5684 148.898C90.4762 148.621 90.6607 148.437 90.9375 148.344C91.122 148.344 111.602 143.824 111.233 126.204C110.864 108.399 94.7198 96.3145 94.5353 96.2222C94.3508 96.0377 94.2585 95.7609 94.443 95.5764C94.6275 95.3919 94.9043 95.2997 95.0888 95.4842C95.2733 95.5764 111.786 107.938 112.155 126.204C112.524 144.562 91.3065 149.267 91.122 149.267C91.0297 149.267 91.0297 149.267 91.0297 149.267Z" fill="black" />
    <Path d="M76.5462 192.533H74.5167C69.9041 192.533 66.2141 188.843 66.2141 184.23V153.695C66.2141 153.51 66.1218 148.529 69.3506 145.669C69.5351 145.484 69.8119 145.484 69.9964 145.761C70.1809 145.946 70.1809 146.223 69.9041 146.407C66.9521 148.898 67.0443 153.603 67.0443 153.695V184.322C67.0443 188.381 70.3654 191.702 74.4244 191.702H76.454C80.513 191.702 83.8341 188.381 83.8341 184.322V166.702C83.8341 166.426 84.0186 166.241 84.2953 166.241C84.5721 166.241 84.7566 166.426 84.7566 166.702V184.322C84.8489 188.843 81.0665 192.533 76.5462 192.533Z" fill="black" />
    <Path d="M105.698 192.533H103.668C99.0556 192.533 95.3655 188.843 95.3655 184.23V166.61C95.3655 166.333 95.55 166.149 95.8268 166.149C96.1035 166.149 96.288 166.333 96.288 166.61V184.23C96.288 188.289 99.6091 191.61 103.668 191.61H105.698C109.757 191.61 113.078 188.289 113.078 184.23V153.695C113.078 153.603 113.539 145.761 106.62 140.042C106.436 139.857 106.436 139.58 106.528 139.396C106.712 139.211 106.989 139.211 107.174 139.304C114.462 145.3 114 153.326 114 153.695V184.23C114 188.843 110.218 192.533 105.698 192.533Z" fill="black" />
    <Path d="M68.7971 189.95H66.6753C63.3543 189.95 60.4945 187.828 59.5719 184.691L57.9114 179.249C55.5129 172.237 56.0664 165.411 59.3874 162.367C62.7085 159.322 66.3986 160.429 66.4908 160.521C66.7676 160.614 66.8598 160.891 66.7676 161.075C66.6753 161.26 66.4908 161.444 66.2141 161.444C66.1218 161.444 62.893 160.429 60.0332 163.105C56.9889 165.964 56.5276 172.514 58.8339 179.064L60.4945 184.507C61.3247 187.274 63.9078 189.119 66.7676 189.119H68.8893C69.1661 189.119 69.3506 189.304 69.3506 189.581C69.3506 189.857 69.0739 189.95 68.7971 189.95Z" fill="black" />
    <Path d="M113.724 189.95H111.51C111.233 189.95 111.048 189.765 111.048 189.488C111.048 189.212 111.233 189.027 111.51 189.027H113.724C116.583 189.027 119.074 187.182 119.997 184.415L121.657 178.972C123.963 172.422 123.41 165.78 120.458 163.012C117.598 160.337 114.277 161.352 114.277 161.352C114 161.444 113.816 161.26 113.724 161.075C113.631 160.798 113.816 160.614 114 160.521C114.185 160.429 117.875 159.322 121.104 162.367C124.425 165.503 125.071 172.237 122.58 179.341L120.919 184.691C119.812 187.828 116.952 189.95 113.724 189.95Z" fill="black" />
    <Path d="M90.5685 189.304C86.6016 189.304 83.5573 187.92 83.3728 187.828C83.1883 187.736 83.0038 187.459 83.1883 187.182C83.3728 186.905 83.5573 186.813 83.8341 186.998C83.9263 186.998 90.1072 189.857 96.5648 187.182C96.8416 187.09 97.0261 187.182 97.2106 187.459C97.3028 187.736 97.2106 187.92 96.9338 188.105C94.7198 188.935 92.5057 189.304 90.5685 189.304Z" fill="black" />
    <Path d="M60.4945 186.26H60.4022C36.0478 179.894 41.3062 157.477 41.3984 157.293C41.4907 157.108 41.5829 156.924 41.7674 156.924C41.9519 156.924 42.1364 157.016 42.2287 157.2C42.3209 157.293 48.1328 169.378 56.2509 171.223C56.5276 171.315 56.6199 171.499 56.6199 171.776C56.5276 172.053 56.3431 172.145 56.0664 172.145C48.963 170.577 43.7047 162.09 41.9519 158.861C41.6752 160.89 41.3062 164.857 42.5054 169.193C44.6272 177.404 50.7158 182.846 60.4945 185.429C60.7712 185.522 60.8635 185.706 60.8635 185.983C60.8635 186.167 60.679 186.26 60.4945 186.26Z" fill="black" />
    <Path d="M69.1661 113.842C68.9816 113.842 68.7971 113.658 68.7971 113.473C68.7971 112.735 69.2584 111.167 71.0111 110.613C73.2252 109.968 74.4244 111.351 74.5167 111.444C74.6089 111.628 74.6089 111.813 74.5167 111.905C74.3322 111.997 74.1477 111.997 74.0554 111.905C74.0554 111.905 73.0407 110.798 71.2879 111.259C69.5351 111.813 69.5351 113.381 69.5351 113.473C69.5351 113.75 69.3506 113.842 69.1661 113.842Z" fill="black" />
    <Path d="M90.6607 109.599C90.6607 109.599 90.5684 109.599 90.6607 109.599C90.3839 109.599 90.2917 109.414 90.2917 109.23C90.3839 108.492 91.0297 107.016 92.8747 106.739C95.0888 106.37 96.1958 107.938 96.1958 108.03C96.288 108.215 96.288 108.399 96.1035 108.492C95.919 108.584 95.7345 108.584 95.6423 108.399C95.6423 108.307 94.812 107.108 92.967 107.385C91.2142 107.661 90.9375 109.322 90.9375 109.322C90.9375 109.414 90.8452 109.599 90.6607 109.599Z" fill="black" />
    <Path d="M103.207 122.514L67.0443 139.119L77.2842 161.536L95.2733 153.234L92.7825 149.082L96.5648 148.344L95.55 144.746L98.1331 143.916L101.362 134.322L104.222 135.983L105.144 133.676H108.373L103.207 122.514Z" fill="white" />
    <Path d="M77.2842 161.998C77.0997 161.998 76.9152 161.905 76.823 161.721L66.5831 139.304C66.4908 139.211 66.4908 139.027 66.5831 138.935C66.5831 138.842 66.6753 138.75 66.8598 138.658L103.115 122.053C103.391 121.96 103.576 122.053 103.76 122.237L108.926 133.4C109.019 133.584 109.019 133.676 108.926 133.861C108.834 133.953 108.742 134.045 108.557 134.045L105.698 133.953L104.867 136.075C104.775 136.167 104.683 136.259 104.591 136.352C104.498 136.352 104.314 136.352 104.222 136.352L101.823 134.968L98.7788 143.916C98.6866 144.008 98.5943 144.193 98.5021 144.193L96.288 144.931L97.2106 148.068C97.2106 148.16 97.2106 148.344 97.1183 148.437C97.026 148.529 96.9338 148.621 96.8415 148.621L93.705 149.267L95.8268 152.865C95.919 152.957 95.919 153.141 95.8268 153.234C95.8268 153.326 95.7345 153.418 95.55 153.51L77.561 161.721C77.3765 161.905 77.2842 161.998 77.2842 161.998ZM67.5978 139.396L77.4687 160.89L94.5353 153.049L92.3212 149.359C92.229 149.267 92.229 149.082 92.3212 148.898C92.4135 148.713 92.5057 148.621 92.6902 148.621L96.0113 147.883L95.0888 144.746C94.9965 144.47 95.181 144.285 95.3655 144.193L97.7641 143.363L100.993 134.045C100.993 133.953 101.177 133.769 101.27 133.769C101.362 133.676 101.546 133.769 101.639 133.769L104.037 135.152L104.775 133.215C104.867 133.031 105.052 132.938 105.236 132.938L107.727 133.031L103.115 122.975L67.5978 139.396Z" fill="black" />
    <Path d="M70.9189 139.949L92.229 142.163L100.07 129.617" fill="white" />
    <Path d="M92.229 142.532L70.8266 140.226C70.6421 140.226 70.4576 140.042 70.5499 139.857C70.6421 139.673 70.7344 139.58 70.9189 139.58L92.0445 141.794L99.7013 129.433C99.7936 129.248 99.9781 129.248 100.163 129.34C100.347 129.433 100.347 129.617 100.255 129.802L92.4135 142.348C92.5057 142.44 92.4135 142.532 92.229 142.532Z" fill="black" />
    <Path d="M67.5056 128.233C67.5056 128.233 67.5978 132.2 74.4244 133.307C81.251 134.414 87.3396 132.846 86.6016 128.787" fill="white" />
    <Path d="M78.5758 134.045C77.2842 134.045 75.8082 133.953 74.3322 133.676C67.3211 132.477 67.1366 128.418 67.1366 128.233C67.1366 128.049 67.3211 127.864 67.5056 127.864C67.6901 127.864 67.8746 128.049 67.8746 128.233C67.8746 128.418 68.0591 131.924 74.4244 133.031C79.3138 133.861 83.6496 133.215 85.4024 131.462C86.0481 130.724 86.3249 129.894 86.1404 128.879C86.1404 128.695 86.2326 128.51 86.4171 128.51C86.6016 128.51 86.7861 128.602 86.7861 128.787C86.9706 129.986 86.6939 131.093 85.8636 131.924C84.5721 133.215 81.8968 134.045 78.5758 134.045Z" fill="black" />
    <Path d="M103.945 120.853C103.945 120.853 105.421 124.543 99.5168 128.233C93.6127 131.924 87.4319 132.754 86.5094 128.695" fill="white" />
    <Path d="M90.5684 131.739C89.7382 131.739 89.0002 131.647 88.3544 131.37C87.2474 130.909 86.5094 129.986 86.2326 128.787C86.2326 128.602 86.3249 128.418 86.5094 128.418C86.6939 128.418 86.8784 128.51 86.8784 128.695C87.0629 129.709 87.6164 130.355 88.5389 130.724C90.8452 131.739 95.0888 130.632 99.3323 127.957C104.867 124.451 103.576 121.038 103.576 121.038C103.484 120.853 103.576 120.669 103.76 120.577C103.945 120.484 104.129 120.577 104.222 120.761C104.314 120.946 105.698 124.728 99.7013 128.51C96.3803 130.54 93.0592 131.739 90.5684 131.739Z" fill="black" />
    <Path d="M131.251 189.95L137.986 199.544L117.967 200.374L121.38 198.068L115.292 195.854L123.041 194.562L122.303 192.625L127.561 192.348L131.251 189.95Z" fill="white" />
    <Path d="M117.967 200.743C117.783 200.743 117.69 200.651 117.598 200.466C117.506 200.282 117.598 200.19 117.69 200.097L120.55 198.16L115.107 196.223C114.923 196.131 114.831 196.038 114.923 195.854C114.923 195.669 115.015 195.577 115.2 195.577L122.58 194.378L122.026 192.902C122.026 192.81 122.026 192.717 122.026 192.625C122.118 192.533 122.211 192.441 122.303 192.441L127.377 192.164L130.975 189.857C131.159 189.765 131.344 189.765 131.436 189.95L138.262 199.544C138.355 199.636 138.355 199.821 138.262 199.913C138.17 200.005 138.078 200.097 137.986 200.097L117.967 200.743ZM116.583 195.946L121.38 197.699C121.473 197.699 121.565 197.883 121.565 197.976C121.565 198.068 121.565 198.252 121.38 198.345L118.982 200.005L137.248 199.267L130.975 190.503L127.561 192.717C127.469 192.717 127.469 192.81 127.377 192.81L122.672 193.086L123.225 194.562C123.225 194.655 123.225 194.747 123.225 194.839C123.225 194.931 123.041 195.024 122.949 195.024L116.583 195.946Z" fill="black" />
    <Path d="M36.2323 186.26L19.0735 191.979L33.6493 197.976L38.4464 196.315L41.1217 201.389L44.0737 202.311L57.0812 195.116L49.5165 194.193L44.166 193.64L38.5386 193.086L37.7083 190.319L36.2323 186.26Z" fill="white" />
    <Path d="M44.0737 202.68H43.9814L41.0294 201.758C40.9372 201.758 40.8449 201.666 40.8449 201.573L38.3541 196.776L33.8338 198.252C33.7415 198.252 33.6493 198.252 33.557 198.252L18.889 192.256C18.7968 192.164 18.7045 192.072 18.7045 191.887C18.7045 191.702 18.7968 191.61 18.9813 191.61L36.1401 185.891C36.3246 185.798 36.5091 185.891 36.6013 186.075L38.0774 190.134L38.9076 192.717L44.2582 193.271L49.6088 193.824L57.1734 194.747C57.3579 194.747 57.4502 194.839 57.4502 195.024C57.4502 195.208 57.4502 195.3 57.2657 195.393L44.2582 202.588C44.166 202.68 44.166 202.68 44.0737 202.68ZM41.3984 201.112L44.0737 201.942L56.0664 195.393L49.5165 194.562L44.166 194.009L38.5386 193.455C38.4464 193.455 38.2619 193.363 38.2619 193.179L37.4316 190.411L36.0478 186.721L20.0883 192.071L33.7415 197.699L38.4464 196.131C38.6309 196.038 38.8154 196.131 38.9076 196.315L41.3984 201.112Z" fill="black" />
    <Path d="M23.5016 194.931L17.5975 198.345L40.7526 201.204" fill="white" />
    <Path d="M40.7526 201.573L17.5975 198.714C17.413 198.714 17.3208 198.621 17.3208 198.437C17.3208 198.252 17.3208 198.16 17.5053 198.068L23.4094 194.655C23.5939 194.562 23.7784 194.655 23.8706 194.747C23.9629 194.931 23.8706 195.116 23.7784 195.208L18.7968 198.068L40.9372 200.835C41.1217 200.835 41.3062 201.02 41.2139 201.204C41.0294 201.481 40.9371 201.573 40.7526 201.573Z" fill="black" />
    <Path d="M29.867 180.54L22.9481 183.953L22.0256 180.909L13.0772 181.186L18.151 179.156L11.6012 176.297L32.9113 178.88L29.867 180.54Z" fill="#DCE2E7" />
    <Path d="M22.9481 184.322C22.8559 184.322 22.8559 184.322 22.8559 184.322C22.7636 184.322 22.6714 184.23 22.6714 184.138L21.8411 181.37L13.1695 181.647C12.9849 181.647 12.8927 181.555 12.8004 181.37C12.8004 181.186 12.8927 181.001 12.9849 181.001L17.3208 179.341L11.5089 176.758C11.3244 176.666 11.2322 176.481 11.3244 176.389C11.4167 176.204 11.5089 176.112 11.6934 176.112L33.0035 178.787C33.188 178.787 33.2803 178.88 33.2803 179.064C33.2803 179.249 33.188 179.341 33.0958 179.433L30.0515 181.001L23.1326 184.415C23.0404 184.322 22.9481 184.322 22.9481 184.322ZM22.0256 180.54C22.2101 180.54 22.3023 180.632 22.3946 180.817L23.2249 183.492L31.8043 179.156L13.9075 176.85L18.3355 178.787C18.4278 178.88 18.52 178.972 18.52 179.156C18.52 179.341 18.4278 179.433 18.3355 179.433L15.1067 180.725L22.0256 180.54Z" fill="black" />
    <Path d="M37.0626 186.075C37.0626 186.075 42.0442 189.119 45.1807 190.688C48.3173 192.256 56.7122 194.931 56.7122 194.931" fill="white" />
    <Path d="M56.7121 195.3H56.6199C56.2509 195.208 48.1328 192.533 44.9962 191.057C41.8597 189.581 36.8781 186.444 36.8781 186.444C36.6936 186.352 36.6936 186.167 36.7858 185.983C36.8781 185.798 37.0626 185.798 37.2471 185.891C37.3393 185.891 42.2287 188.935 45.3652 190.503C48.4095 191.979 56.8044 194.747 56.8966 194.747C57.0812 194.839 57.1734 195.024 57.0812 195.208C56.9889 195.208 56.8044 195.3 56.7121 195.3Z" fill="black" />
    <Path d="M66.3986 195.946L60.4022 200.097L66.4908 199.728L67.1366 201.942L69.9964 198.898L71.1956 200.005L77.6532 198.16L72.0259 197.422L68.2436 194.378L66.3986 195.946Z" fill="white" />
    <Path d="M67.1366 202.311C66.9521 202.311 66.8598 202.219 66.7676 202.035L66.1218 200.097L60.31 200.466C60.1255 200.466 60.0332 200.374 59.941 200.282C59.8487 200.097 59.941 200.005 60.0332 199.913L66.0296 195.762L67.8746 194.193C67.9668 194.101 68.1513 194.101 68.3358 194.193L72.0259 197.238L77.4687 197.883C77.6532 197.883 77.7455 198.068 77.7455 198.16C77.7455 198.345 77.6532 198.437 77.4687 198.529L71.0111 200.466C70.9189 200.466 70.7344 200.466 70.6421 200.374L69.7196 199.544L67.1366 202.311C67.3211 202.219 67.2288 202.311 67.1366 202.311ZM66.4908 199.359C66.6753 199.359 66.7676 199.452 66.8598 199.636L67.4133 201.297L69.8119 198.714C69.9041 198.529 70.1809 198.529 70.2731 198.714L71.2879 199.636L75.9927 198.252L72.0259 197.791C71.9336 197.791 71.9337 197.791 71.8414 197.699L68.2436 194.747L66.5831 196.131L61.6015 199.636L66.4908 199.359Z" fill="black" />
    <Path d="M98.1331 196.407C97.3951 196.592 92.967 197.883 91.3065 197.883L95.3655 200.835L98.9633 198.529H103.945L98.1331 196.407Z" fill="#DCE2E7" />
    <Path d="M95.3655 201.297C95.2733 201.297 95.181 201.297 95.181 201.204L91.122 198.252C91.0297 198.16 90.9375 197.976 91.0297 197.883C91.122 197.791 91.2142 197.607 91.3987 197.607C92.3212 197.607 94.7198 197.053 98.0408 196.131C98.1331 196.131 98.2253 196.131 98.3176 196.131L104.222 198.345C104.406 198.437 104.498 198.529 104.406 198.714C104.314 198.898 104.222 198.99 104.037 198.99H99.1478L95.6423 201.204C95.55 201.297 95.4578 201.297 95.3655 201.297ZM92.229 198.16L95.3655 200.466L98.7788 198.345C98.8711 198.345 98.8711 198.252 98.9633 198.252H102.008L98.0408 196.776C96.1958 197.33 93.7973 197.976 92.229 198.16ZM98.1331 196.407L98.2253 196.776L98.1331 196.407Z" fill="black" />
    <Path d="M144.351 179.249H132.174L136.602 180.725L127.654 183.031L144.259 184.876L145.458 186.629L150.532 182.108L144.351 179.249Z" fill="#DCE2E7" />
    <Path d="M145.458 186.998C145.274 186.998 145.181 186.905 145.181 186.813L144.074 185.153L127.561 183.4C127.377 183.4 127.285 183.215 127.285 183.123C127.285 182.939 127.377 182.846 127.561 182.754L135.403 180.632L132.174 179.618C131.989 179.525 131.897 179.433 131.897 179.249C131.897 179.064 132.082 178.972 132.266 178.972H144.443C144.536 178.972 144.536 178.972 144.628 178.972L150.809 181.739C150.901 181.832 150.993 181.924 150.993 182.016C150.993 182.108 150.993 182.293 150.901 182.293L145.827 186.905C145.643 186.998 145.55 186.998 145.458 186.998ZM129.499 182.939L144.351 184.507C144.443 184.507 144.536 184.599 144.628 184.691L145.643 186.167L150.071 182.201L144.443 179.618H134.572L136.879 180.356C137.063 180.448 137.155 180.54 137.155 180.725C137.155 180.909 137.063 181.001 136.879 181.094L129.499 182.939Z" fill="black" />
    <Path d="M19.6271 133.769H14.2765C14.092 133.769 13.9075 133.584 13.9075 133.4C13.9075 133.215 14.092 133.031 14.2765 133.031H19.6271C19.8116 133.031 19.9961 133.215 19.9961 133.4C19.9961 133.584 19.8116 133.769 19.6271 133.769Z" fill="black" />
    <Path d="M36.0478 133.769H23.6861C23.5016 133.769 23.3171 133.584 23.3171 133.4C23.3171 133.215 23.5016 133.031 23.6861 133.031H36.0478C36.2323 133.031 36.4168 133.215 36.4168 133.4C36.4168 133.584 36.2323 133.769 36.0478 133.769Z" fill="black" />
    <Path d="M136.325 218.455H132.82C132.635 218.455 132.451 218.271 132.451 218.086C132.451 217.902 132.635 217.717 132.82 217.717H136.325C136.51 217.717 136.694 217.902 136.694 218.086C136.694 218.271 136.51 218.455 136.325 218.455Z" fill="black" />
    <Path d="M128.761 218.455H116.952C116.768 218.455 116.583 218.271 116.583 218.086C116.583 217.902 116.768 217.717 116.952 217.717H128.761C128.945 217.717 129.13 217.902 129.13 218.086C129.13 218.271 128.945 218.455 128.761 218.455Z" fill="black" />
    <Path d="M154.222 124.912H150.163C149.978 124.912 149.794 124.82 149.794 124.636C149.702 123.344 148.595 122.329 147.303 122.329C146.473 122.329 145.735 122.698 145.181 123.436C145.089 123.529 144.997 123.621 144.812 123.621C144.72 123.621 144.536 123.529 144.536 123.436C144.074 122.237 142.875 121.407 141.584 121.407C139.831 121.407 138.355 122.883 138.355 124.636C138.355 124.82 138.17 125.005 137.986 125.005H128.576C128.392 125.005 128.207 124.82 128.207 124.636C128.207 124.451 128.392 124.267 128.576 124.267H137.709C137.893 122.329 139.646 120.669 141.584 120.669C142.967 120.669 144.259 121.407 144.905 122.606C145.55 122.053 146.288 121.684 147.211 121.684C148.779 121.684 150.071 122.791 150.347 124.267H154.13C154.314 124.267 154.499 124.451 154.499 124.636C154.499 124.82 154.406 124.912 154.222 124.912Z" fill="black" />
  </Svg>
);

export default MobilePaperlessSm;
