import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../base_ui';
import { StyledFlatList } from './styledComponents';
import TableRow from './TableRow';
import { EmptyCard } from '../Cards';

const Table = ({
  messages,
  onPressTableRow,
  ...restProps
}) => {
  const renderItem = ({ item }) => (
    <TableRow onPressTableRow={onPressTableRow} {...item} />
  );

  return (
    <ConditionalRender
      Component={StyledFlatList}
      FallbackComponent={EmptyCard}
      propsToPassDown={{
        data: messages,
        keyExtractor: ({ messageId }, index) => `${messageId}-${index}`,
        onEndReachedThreshold: 0.3,
        renderItem,
        showsHorizontalScrollIndicator: true,
        showsVerticalScrollIndicator: true,
        ...restProps,
      }}
      shouldRender={!!messages.length}
    />
  );
};

Table.propTypes = {
  messages: T.arrayOf(T.shape({
    author: T.string,
    created: T.string,
    messageId: T.string,
    subject: T.string,
  })).isRequired,
  onPressTableRow: T.func.isRequired,
};

export default Table;
