import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import { ConditionalRender } from '../../base_ui';
import NavButtons from './NavButtons';
import { NavMenuContainer, StyledNavButton } from './styledComponents';

const LogoutIconDefault = iconDictionary('navLogoutDefaultL');

const NavMenu = ({
  clientMobileFeatures: { showLogoutButton },
  handleLogout,
  isLoanAvailable,
  isMurabahaAccount,
  isSslAuth,
  navigation,
  navigationState,
}) => (
  <NavMenuContainer>
    <ConditionalRender
      Component={NavButtons}
      FallbackComponent={(
        <ConditionalRender
          Component={(
            <StyledNavButton
              Icon={LogoutIconDefault}
              label="Logout"
              onPress={handleLogout}
            />
          )}
          shouldRender={!isSslAuth}
        />
      )}
      propsToPassDown={{
        handleLogout,
        isMurabahaAccount,
        navigation,
        navigationState,
        showLogoutButton,
      }}
      shouldRender={isLoanAvailable}
    />
  </NavMenuContainer>
);

NavMenu.propTypes = {
  clientMobileFeatures: T.shape({ showLogoutButton: T.bool.isRequired }),
  handleLogout: T.func.isRequired,
  isLoanAvailable: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  navigation: T.object.isRequired,
  navigationState: T.object.isRequired,
};

export default NavMenu;
