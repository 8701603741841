import React from 'react';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { Skeleton } from '../../../../base_ui';
import { RowView } from '../styledComponents';
import { SkeletonView } from './styledComponents';

const SkeletonItem = () => (
  <RowView>
    <SkeletonView $widthPercent={28}>
      <Skeleton
        $bottomMargin={`${moderateScale(15)}px`}
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(69)}px`}
      />
    </SkeletonView>
    <SkeletonView $widthPercent={48}>
      <Skeleton
        $bottomMargin={`${moderateScale(15)}px`}
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(116)}px`}
      />
    </SkeletonView>
    <SkeletonView $widthPercent={26}>
      <Skeleton
        $bottomMargin={`${moderateScale(15)}px`}
        height={`${moderateScale(12)}px`}
        width={`${moderateScale(52)}px`}
      />
    </SkeletonView>
  </RowView>
);

export default SkeletonItem;
