/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  G,
  Path,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

const EditIcon = (props) => {
  const { colors: { primaryCarbon } } = useTheme();
  return (
    <Svg
      fill="none"
      height={moderateScale(16)}
      viewBox="0 0 16 16"
      width={moderateScale(16)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <G clipPath="url(#clip0_3821_5055)">
        <Path d="M11 15.2H0.6C0.3 15.2 0 14.9 0 14.6V0.6C0 0.3 0.3 0 0.6 0H11C11.3 0 11.6 0.3 11.6 0.6V2.3C11.6 2.6 11.3 2.9 11 2.9C10.7 2.9 10.4 2.6 10.4 2.3V1.2H1.2V14H10.4V7.5C10.4 7.2 10.7 6.9 11 6.9C11.3 6.9 11.6 7.2 11.6 7.5V14.6C11.6 14.9 11.3 15.2 11 15.2Z" fill={primaryCarbon} />
        <Path d="M6.00039 11.4996C5.80039 11.4996 5.70039 11.3996 5.60039 11.2996C5.50039 11.1996 5.40039 10.9996 5.40039 10.7996L5.60039 9.29961C5.60039 9.19961 5.70039 8.99961 5.80039 8.99961L13.3004 1.49961C13.5004 1.29961 13.9004 1.29961 14.2004 1.49961L15.5004 2.79961C15.6004 2.89961 15.7004 3.09961 15.7004 3.19961C15.7004 3.39961 15.6004 3.49961 15.5004 3.59961L8.00039 10.9996C7.90039 11.0996 7.80039 11.1996 7.70039 11.1996L6.00039 11.4996C6.10039 11.4996 6.00039 11.4996 6.00039 11.4996ZM6.80039 9.69961L6.70039 10.1996L7.20039 10.0996L14.1004 3.19961L13.7004 2.79961L6.80039 9.69961Z" fill={primaryCarbon} />
        <Path d="M13.3004 5.3998C13.1004 5.3998 13.0004 5.2998 12.9004 5.1998L11.8004 4.0998C11.6004 3.8998 11.6004 3.4998 11.8004 3.1998C12.0004 2.9998 12.4004 2.9998 12.7004 3.1998L13.8004 4.2998C14.0004 4.4998 14.0004 4.8998 13.8004 5.1998C13.6004 5.2998 13.5004 5.3998 13.3004 5.3998Z" fill={primaryCarbon} />
        <Path d="M8 5.29961H3.1C2.8 5.29961 2.5 4.99961 2.5 4.69961C2.5 4.39961 2.8 4.09961 3.1 4.09961H8C8.3 4.09961 8.6 4.39961 8.6 4.69961C8.6 4.99961 8.3 5.29961 8 5.29961Z" fill={primaryCarbon} />
        <Path d="M5.5 8.29961H3.1C2.8 8.29961 2.5 7.99961 2.5 7.69961C2.5 7.39961 2.8 7.09961 3.1 7.09961H5.5C5.8 7.09961 6.1 7.39961 6.1 7.69961C6.1 7.99961 5.8 8.29961 5.5 8.29961Z" fill={primaryCarbon} />
        <Path d="M3.9 11.2996H3.1C2.8 11.2996 2.5 10.9996 2.5 10.6996C2.5 10.3996 2.8 10.0996 3.1 10.0996H3.9C4.2 10.0996 4.5 10.3996 4.5 10.6996C4.5 10.9996 4.2 11.2996 3.9 11.2996Z" fill={primaryCarbon} />
      </G>
    </Svg>
  );
};

export default EditIcon;
