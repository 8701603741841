import {
  Button,
  Header,
  Link,
  List,
  ListItem,
  Paragraph,
  Span,
} from './ElementComponents';

/* eslint-disable quote-props */
const elementDictionary = {
  'button': Button,
  'header-four': Header,
  'header-one': Header,
  'header-three': Header,
  'header-two': Header,
  'link': Link,
  'list-item': ListItem,
  'ordered-list': List,
  'paragraph': Paragraph,
  'span': Span,
  'unordered-list': List,
};

export default elementDictionary;
