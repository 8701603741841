import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { P3, PaymentTextField, PrimaryDivider } from '../../../base_ui';

export const AdditionalPrincipalContainer = styled.View`
  flex-direction: row;
  padding: 0 ${moderateScale(20)}px ${moderateScale(33)}px;
`;

export const ContentContainer = styled.View`
  flex-grow: 1;
  margin-top: ${moderateScale(24)}px;
`;

export const InfoSectionContainer = styled.View`
  margin: ${moderateScale(16)}px ${moderateScale(24)}px 0;
`;

export const RegularMonthlyPaymentContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(33)}px ${moderateScale(24)}px;
`;

export const RegularMonthlyPaymentLabel = styled(P3)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const StyledDivider = styled(PrimaryDivider)`
  max-width: auto;
`;

export const StyledPaymentTextField = styled(PaymentTextField).attrs(({
  $isInputEnabled,
  $isTablet,
  theme: {
    fontFamilies: { secondaryFont },
    fontSizes: { f3 },
  },
}) =>
  ({
    containerStyle: {
      height: moderateScale(33),
      marginLeft: $isTablet ? moderateScale(10) : moderateScale(3),
    },
    errorStyle: {
      fontFamily: secondaryFont,
      fontSize: f3,
      left: 0,
      lineHeight: moderateScale(15),
      position: 'absolute',
      top: moderateScale(31),
    },
    inputStyle: { textAlign: 'right' },
    labelStyle: { opacity: $isInputEnabled ? 1 : 0.5 },
  }))``;
