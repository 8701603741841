import React, { Fragment, useState } from 'react';
import { Pressable } from 'react-native';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import {
  PAYDOWN_MESSAGE,
  PAYOFF_PAYDOWN_STEP_DICTIONARY,
} from '@dmi/shared/redux/Payoff/constants';
import { INTL_IDS } from '@dmi/shared/redux/Payoff/messages';

import {
  ConditionalRender,
  MediumPrimaryButton,
  P2,
} from '../../base_ui';
import DatePicker from './DatePicker';
import PayoffReason from './PayoffReason';
import {
  PaydownRadioWrapper,
  PayoffRadioWrapper,
  RadioButton,
  StyledCancelLink,
  StyledNextButton,
  StyledPaydownText,
} from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';

const CheckedIcon =
  iconDictionary('approved', { height: moderateScale(16), width: moderateScale(16) });
const EmptyIcon =
  iconDictionary('unapproved', { height: moderateScale(16), width: moderateScale(16) });

const PaydownView = ({
  availablePayOffReasons,
  dateSelectorIsDisabled,
  dispatchChangePayoffStep,
  dispatchCreatePayoffStatement,
  dispatchUpdateRequestType,
  dispatchUpdateSelectedDate,
  dispatchUpdateSelectedPayOffReason,
  loanNumber,
  navigation,
  payoffRequestBody,
  payoffRequestBody: { payoffReason },
  payoffStep,
  requestType,
  selectedDate,
}) => {
  const [selectedPayOffReason, setSelectedPayOffReason] = useState(payoffReason);

  const isPayoff = requestType === 'payoff';
  const isPaydown = requestType === 'paydown';
  const {
    disableSubmitButton,
    showCancel,
    showDatePicker,
    showNextButton,
    showPaydown,
    showPayoff,
    showSubmitButton,
  } = PAYOFF_PAYDOWN_STEP_DICTIONARY[payoffStep];
  const isSubmitButtonDisabled = (!selectedDate || !selectedPayOffReason) && disableSubmitButton;
  const payOffRadioButtonProps = isPayoff ? {
    onPressHandler: () => updateStateValues({
      date: '',
      reason: '',
      request: '',
      step: 0,
    }),
    radioIcon: CheckedIcon,
  } : {
    onPressHandler: () => updateStateValues({
      date: '',
      reason: '',
      request: 'payoff',
      step: 1,
    }),
    radioIcon: EmptyIcon,
  };
  const payDownRadioButtonProps = isPaydown ? {
    onPressHandler: () => updateStateValues({
      date: '',
      reason: '',
      request: '',
      step: 0,
    }),
    radioIcon: CheckedIcon,
  } : {
    onPressHandler: () => updateStateValues({
      date: '',
      reason: '',
      request: 'paydown',
      step: 3,
    }),
    radioIcon: EmptyIcon,
  };

  const updateStateValues = ({
    date,
    reason,
    request,
    step,
  }) => {
    dispatchUpdateRequestType(request);
    dispatchUpdateSelectedDate(date);
    dispatchChangePayoffStep(step);
    setSelectedPayOffReason(reason);
  };

  const handleNext = () => updateStateValues({
    date: '',
    reason: '',
    request: 'payoff',
    step: 2,
  });

  const handleCancel = () => {
    dispatchUpdateSelectedPayOffReason('');
    updateStateValues({
      date: '',
      reason: '',
      request: '',
      step: 0,
    });
  };

  return (
    <Fragment>
      <ConditionalRender
        Component={(
          <Fragment>
            <PayoffRadioWrapper>
              <RadioButton
                hitSlop={{
                  bottom: 4,
                  left: 4,
                  right: 4,
                  top: 4,
                }}
                onPress={payOffRadioButtonProps.onPressHandler}
              >
                {payOffRadioButtonProps.radioIcon}
              </RadioButton>
              <P2>
                <FormattedMessage id={INTL_IDS.PAYOFFOPTIONS_PAYOFF_TEXT} />
              </P2>
            </PayoffRadioWrapper>
          </Fragment>
        )}
        shouldRender={showPayoff}
      />
      <ConditionalRender
        Component={DatePicker}
        propsToPassDown={{
          dateSelectorIsDisabled,
          dispatchUpdateSelectedPayOffReason,
          isPaydown: true,
          navigation,
          selectedDate,
          selectedPayOffReason,
        }}
        shouldRender={showDatePicker}
      />
      <ConditionalRender
        Component={PayoffReason}
        propsToPassDown={{
          availablePayOffReasons,
          dispatchUpdateSelectedPayOffReason,
          selectedPayOffReason,
          setSelectedPayOffReason,
        }}
        shouldRender={!!selectedDate && payoffStep === 2}
      />
      <ConditionalRender
        Component={(
          <Fragment>
            <PaydownRadioWrapper>
              <RadioButton
                hitSlop={{
                  bottom: 4,
                  left: 4,
                  right: 4,
                  top: 4,
                }}
                onPress={payDownRadioButtonProps.onPressHandler}
              >
                {payDownRadioButtonProps.radioIcon}
              </RadioButton>
              <P2>
                <FormattedMessage id={INTL_IDS.PAYOFFOPTIONS_PAYDOWN_TEXT} />
              </P2>
            </PaydownRadioWrapper>
            <ConditionalRender
              Component={(
                <StyledPaydownText>{PAYDOWN_MESSAGE}</StyledPaydownText>
              )}
              shouldRender={isPaydown}
            />
          </Fragment>
        )}
        shouldRender={showPaydown}
      />
      <ConditionalRender
        Component={MediumPrimaryButton}
        propsToPassDown={{
          disabled: isSubmitButtonDisabled,
          label: 'Submit Request',
          onPress: () => dispatchCreatePayoffStatement({
            ...payoffRequestBody,
            loanNumber,
            payoffReason: selectedPayOffReason,
          }),
        }}
        shouldRender={showSubmitButton}
      />
      <ConditionalRender
        Component={StyledNextButton}
        propsToPassDown={{
          label: 'Next',
          onPress: handleNext,
        }}
        shouldRender={showNextButton}
      />
      <ConditionalRender
        Component={(
          <Pressable
            aria-label="Cancel Button"
            onPress={handleCancel}
            role="link"
          >
            <StyledCancelLink>Cancel</StyledCancelLink>
          </Pressable>
        )}
        shouldRender={showCancel}
      />
    </Fragment>
  );
};

PaydownView.propTypes = {
  availablePayOffReasons: T.array.isRequired,
  dateSelectorIsDisabled: T.bool.isRequired,
  dispatchChangePayoffStep: T.func.isRequired,
  dispatchCreatePayoffStatement: T.func.isRequired,
  dispatchUpdateRequestType: T.func.isRequired,
  dispatchUpdateSelectedDate: T.func.isRequired,
  dispatchUpdateSelectedPayOffReason: T.func.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  payoffRequestBody: T.shape({
    payoffDate: T.string.isRequired,
    payoffReason: T.string,
    requestType: T.string.isRequired,
  }).isRequired,
  payoffStep: T.number.isRequired,
  requestType: T.string.isRequired,
  selectedDate: T.string.isRequired,
};

export default PaydownView;
