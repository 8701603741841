import React, { useState } from 'react';
import T from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';

import iconDictionary from '../../../../../utils/iconDictionary';
import { BaseToggle } from '../../../../base_ui';
import {
  StyledH5,
  StyledPressable,
  ToggleContainer,
  ToggleLabel,
  ToggleLabelWrapper,
  ToggleWrapper,
} from './styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');

const PrimaryLoanToggle = ({
  handleChangePrimaryLoan,
  isPrimaryLoan,
  setIsTooltipOpen,
  width,
}) => {
  const { formatMessage } = useIntl();
  const [isPressed, setIsPressed] = useState(false);
  const primaryLoanLabel = isPrimaryLoan ? 'On' : 'Off';

  return (
    <ToggleContainer $width={width}>
      <ToggleLabelWrapper>
        <ToggleLabel>
          <FormattedMessage id={INTL_IDS.HOME_LOANCARD_PRIMARYLOAN_BUTTON_LABEL} />
        </ToggleLabel>
        <StyledPressable
          $pressed={isPressed}
          onPress={() => setIsTooltipOpen(true)}
          onPressIn={() => setIsPressed(true)}
          onPressOut={() => setIsPressed(false)}
        >
          {InfoIcon}
        </StyledPressable>
      </ToggleLabelWrapper>
      <ToggleWrapper>
        <StyledH5>{primaryLoanLabel}</StyledH5>
        <BaseToggle
          ariaLabel={formatMessage({ id: INTL_IDS.HOME_LOANCARD_PRIMARYLOAN_BUTTON_ARIALABEL })}
          isActive={isPrimaryLoan}
          onPress={handleChangePrimaryLoan}
        />
      </ToggleWrapper>
    </ToggleContainer>
  );
};

PrimaryLoanToggle.propTypes = {
  handleChangePrimaryLoan: T.func.isRequired,
  isPrimaryLoan: T.bool.isRequired,
  setIsTooltipOpen: T.func.isRequired,
  width: T.string,
};

export default PrimaryLoanToggle;
