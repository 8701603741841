/*
 * Register
 */

import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { resetState } from '@dmi/shared/redux/Register/Main/actions';
import { getRegisterPageView } from '@dmi/shared/redux/Register/Main/selectors';

import { ConditionalRender, KeyboardAvoidingScrollView } from '../../../components/base_ui';
import NotFoundScreen from '../../NotFoundScreen';
import RegisterView from './RegisterView';
import RegisterVerifyView from './RegisterVerifyView';

const Register = ({
  dispatchResetState,
  navigation,
  view,
  ...restProps
}) => {
  useEffect(() => dispatchResetState, [dispatchResetState]);

  const ViewToRender = view === 'verify' ? RegisterVerifyView : RegisterView;

  return (
    <KeyboardAvoidingScrollView>
      <ConditionalRender
        Component={<ViewToRender navigation={navigation} {...restProps} />}
        FallbackComponent={<NotFoundScreen navigation={navigation} />}
        shouldRender={['init', 'main', 'verify'].includes(view)}
      />
    </KeyboardAvoidingScrollView>
  );
};

Register.propTypes = {
  dispatchResetState: T.func.isRequired,
  navigation: T.object.isRequired,
  view: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  view: getRegisterPageView(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchResetState: () => dispatch(resetState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Register);
