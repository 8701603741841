import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const ContentWrapper = styled.View`
  height: 100%;
`;

export const MainContainer = styled.View`
  background-color: ${({ theme: { colors: { primaryCarbon } } }) => `${primaryCarbon}CC`};
  flex: 1;
`;

export const ModalContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  height: ${moderateScale(436)}px;
  max-height: ${moderateScale(436)}px;
  shadow-color: rgba(0, 0, 0, 0.1);
  shadow-offset: 0;
  shadow-radius: 4px;
  width: ${moderateScale(327)}px;
`;

export const ModalWrapper = styled.View`
  align-items: ${({ align }) => align};
  flex: 1;
  flex-direction: column;
  justify-content: ${({ justify }) => justify};
`;

export const StyledView = styled.View`
  height: 100%;
`;
