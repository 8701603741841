export const FAQ_BASE_URL = 'https://yourmortgageonline.com';

// Custom list of html tags to ignore as the default list ignores table
// tags which are required for the FAQ to render properly
export const FAQ_IGNORED_TAGS = [
  'audio',
  'button',
  'canvas',
  'caption',
  'col',
  'colgroup',
  'datalist',
  'details',
  'dialog',
  'embed',
  'fieldset',
  'form',
  'head',
  'input',
  'label',
  'legend',
  'menu',
  'menuitem',
  'meter',
  'noscript',
  'object',
  'optgroup',
  'option',
  'output',
  'param',
  'progress',
  'scripts',
  'select',
  'source',
  'summary',
  'textarea',
  'track',
  'video',
];
