import React from 'react';
import T from 'prop-types';
import {
  Linking,
  Platform,
  Text,
  View,
} from 'react-native';

import { ACCOUNT_DELETION_PROPS } from '@dmi/shared/redux/AccountDeletion/constants';
import { GENERIC_CUSTOMER_SERVICE_PHONE_NUMBER } from '@dmi/shared/redux/Main/constants';

import iconDictionary from '../../utils/iconDictionary';
import { moderateScale } from '../../utils/scaleHelpers';
import { StyledScrollView } from '../base_ui';

import {
  Card,
  CardTitle,
  ContainerTitle,
  Content,
  HeaderText,
  HoursText,
  Illustration,
  StyledLargePrimaryButton,
  StyledPhoneLinkText,
} from './styledComponents';

const DogSmartPhone = iconDictionary('dogSmartphone', {
  height: `${moderateScale(210)}px`,
  width: `${moderateScale(165)}px`,
});

const AccountDeletion = ({ customerServicePhoneNumber, isLoggedIn, navigation }) => {
  const {
    cardTitle,
    headerText,
    hoursOfOperation,
    title,
  } = ACCOUNT_DELETION_PROPS;

  const phoneNumber = customerServicePhoneNumber.trim() || GENERIC_CUSTOMER_SERVICE_PHONE_NUMBER;
  const navigationArgs = isLoggedIn ?
    ['PostAuthNavigator', { screen: Platform.OS === 'web' ? 'Payments' : 'TabNavigator' }] :
    ['PreAuthNavigator', { screen: 'Login' }];
  const buttonProps = isLoggedIn ? {
    label: 'Return to Dashboard',
    onPress: () => navigation.replace(...navigationArgs),
  } : {
    label: 'Login to Account',
    onPress: () => navigation.replace(...navigationArgs),
  };

  return (
    <StyledScrollView>
      <ContainerTitle>{title}</ContainerTitle>
      <Content>
        <Card>
          <View>
            <HeaderText>{headerText}</HeaderText>
            <CardTitle>{cardTitle}</CardTitle>
            <StyledPhoneLinkText
              onPress={() => Linking.openURL(`tel://${phoneNumber.replace(/\+|-/g, '')}`)}
            >
              {phoneNumber}
            </StyledPhoneLinkText>
            <Text>
              {'TDD: '}
              <StyledPhoneLinkText
                onPress={() => Linking.openURL(`tel://${'1-866-352-3684'.replace(/\+|-/g, '')}`)}
              >
                1-866-352-3684
              </StyledPhoneLinkText>
            </Text>
            <HoursText>{hoursOfOperation}</HoursText>
          </View>
        </Card>
        <Illustration>{DogSmartPhone}</Illustration>
        <StyledLargePrimaryButton {...buttonProps} />
      </Content>
    </StyledScrollView>
  );
};

AccountDeletion.propTypes = {
  customerServicePhoneNumber: T.string,
  isLoggedIn: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default AccountDeletion;
