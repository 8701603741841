import React from 'react';
import { View } from 'react-native';

import {
  AddressSkeleton,
  CardHeaderSkeleton,
} from './Sections';

const LoanCardSkeleton = () => (
  <View>
    <CardHeaderSkeleton />
    <AddressSkeleton label="Property" />
    <AddressSkeleton label="Mailing Address" />
  </View>
);

export default LoanCardSkeleton;
