import React from 'react';
import T from 'prop-types';

import WebView from '../WebView';
import { StyledSecondaryHeader, ViewStatementContainer } from './styledComponents';

const ViewStatement = ({
  navigation,
  route,
}) => {
  const { params: { requestType } } = route;
  const title = requestType === 'paydown' ? 'Paydown Statement' : 'Payoff Statement';

  return (
    <ViewStatementContainer>
      <StyledSecondaryHeader
        handleBack={() => navigation.goBack()}
        title={title}
      />
      <WebView route={route} />
    </ViewStatementContainer>
  );
};

ViewStatement.propTypes = {
  navigation: T.object.isRequired,
  route: T.object.isRequired,
};

export default ViewStatement;
