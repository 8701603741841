import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H2 } from '../../../base_ui';

export const Container = styled.View`
  align-items: center;
  padding-horizontal: ${moderateScale(44)}px;
  padding-top: ${moderateScale(104)}px;
`;

export const Heading = styled(H2)`
  margin-top: ${moderateScale(22)}px;
  text-align: center;
`;
