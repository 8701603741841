import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import BaseInput from '../BaseInput';
import { P3 } from '../../Paragraphs';

export const DisabledMessageContainer = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  padding: ${moderateScale(16)}px 0;
`;

export const LabelContainer = styled.View`
  flex: 1;
  padding-top: ${moderateScale(7)}px;
`;

export const LabelStyle = styled(P3)`
  left: 0;
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  line-height: ${moderateScale(18)}px;
  padding-bottom: ${moderateScale(15)}px;
  padding-top: ${moderateScale(10)}px;
  width: auto;
`;

export const LabelSubText = styled(P3)`
  color: ${({ theme: { colors: { accentRed } } }) => accentRed};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  left: 0;
  line-height: ${moderateScale(15)}px;
  margin: 0;
  margin-top: ${-moderateScale(12)}px;
  width: 67%;
`;

export const MainWrapper = styled.View`
  flex-direction: row;
  flex: 1;
  min-height: ${moderateScale(54)}px;
  padding-top: ${moderateScale(8)}px;
  width: ${({ $hasFluidWidth }) => $hasFluidWidth ? '100%' : `${moderateScale(287)}px`};
  `;

export const StyledDisabledText = styled(P3)`
  flex: 1;
  height: 100%;
  max-width: 50%;
  text-align: right;
`;

export const StyledPaymentInput = styled(BaseInput).attrs(({
  $isInputDisabled,
  containerStyle = {},
  errorStyle = {},
  inputContainerStyle = {},
  inputStyle = {},
  labelStyle = {},
  theme: {
    fontFamilies: { secondaryFontBold },
    fontSizes: { f1, f4 },
  },
}) => {
  const disabledStyles = {
    borderWidth: 0, // Android bug still displays border when display is 'none'
    display: 'none',
  };

  return {
    containerStyle: {
      alignItems: 'center',
      flex: 1,
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      ...containerStyle,
    },
    errorStyle: {
      fontFamily: secondaryFontBold,
      fontSize: f1,
      lineHeight: moderateScale(20),
      margin: 0,
      marginTop: -moderateScale(6),
      width: '67%',
      ...errorStyle,
    },
    inputContainerStyle: {
      height: moderateScale(32),
      paddingRight: moderateScale(6),
      width: '30%',
      ...($isInputDisabled && disabledStyles),
      ...inputContainerStyle,
    },
    inputStyle: {
      fontSize: f4,
      ...inputStyle,
    },
    labelStyle: {
      fontFamily: secondaryFontBold,
      lineHeight: moderateScale(20),
      paddingRight: moderateScale(3),
      paddingVertical: moderateScale(6),
      width: '70%',
      ...labelStyle,
    },
    leftIconContainerStyle: {
      height: '100%',
      marginBottom: 0,
      marginTop: 0,
      paddingLeft: moderateScale(6),
      paddingRight: moderateScale(2),
    },
  };
})``;

/*
  The height here should correspond to the height adjacent line / textInput.
  This wrapper is necessary as part of the broader attempt to ensure the toggle
  stays aligned with the main/top line of label text and textInput even if the label
  text and/or error message span multiple lines (due to either inherently long messages
  or due to a user's higher font size setting.)
*/
export const ToggleWrapper = styled.View`
  height: ${moderateScale(32)}px;
  justify-content: center;
`;
