import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { Platform } from 'react-native';

import {
  EditPaymentAmountScreen,
  EditPaymentSourceScreen,
  OtpDisclosureScreen,
  PaymentDateScreen,
  ReviewPaymentDetailsScreen,
  SchedulePaymentScreen,
} from '../../../../containers/Payments/OneTimePayment';
import Nacha from '../../../../components/Payments/Nacha';

const Stack = createStackNavigator();

const OtpPreConfirmationStack = () => (
  <Stack.Navigator
    initialRouteName="SchedulePayment"
    screenOptions={{
      cardStyle: { ...Platform.select({ web: { height: '-webkit-fill-available' } }) },
      headerShown: false,
    }}
  >
    <Stack.Screen component={SchedulePaymentScreen} name="SchedulePayment" />
    <Stack.Screen component={EditPaymentAmountScreen} name="EditPayment" />
    <Stack.Screen component={EditPaymentSourceScreen} name="PaymentSourceForm" />
    <Stack.Screen component={PaymentDateScreen} name="PaymentDate" />
    <Stack.Screen component={OtpDisclosureScreen} name="OtpDisclosure" />
    <Stack.Screen component={ReviewPaymentDetailsScreen} name="ReviewPaymentDetails" />
    <Stack.Screen component={Nacha} name="Nacha" />
  </Stack.Navigator>
);

export default OtpPreConfirmationStack;
