/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Circle,
  Ellipse,
  G,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function SelectLoanWinter(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg enableBackground="new 0 0 327 150" height={moderateScale(150)} id="Layer_1" version="1.1" viewBox="0 0 327 150" width={moderateScale(327)} x="0px" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" y="0px" {...props}>
      <G>
        <Path d="M44.982,134.483c0,0-14.903-3.053-14.903-23.477c0-25.111,28.854-41.296,40.262-57.186 C87.49,29.934,95.148,22.085,117.4,15.144s55.017-4.808,72.574,3.154c7.82,3.546,23.273,11.841,39.197,13.066 c15.924,1.225,54.056,16.764,71,37.383c17.213,20.946,11.637,60.021-21.844,65.533C278.327,134.279,44.675,134.177,44.982,134.483z" fill="#E7EBEE" />
        <Circle cx="111.192" cy="63.822" fill="#FFFFFF" r="2.053" />
        <Circle cx="53.41" cy="104.648" fill="#FFFFFF" r="2.053" />
        <Circle cx="257.526" cy="124.165" fill="#FFFFFF" r="2.053" />
        <Circle cx="112.627" cy="94.083" fill="#FFFFFF" r="2.053" />
        <Circle cx="219.011" cy="113.041" fill="#FFFFFF" r="2.053" />
        <Circle cx="115.986" cy="77.714" fill="#FFFFFF" r="1.589" />
        <Circle cx="301.839" cy="94.902" fill="#FFFFFF" r="1.589" />
        <Circle cx="292.841" cy="113.228" fill="#FFFFFF" r="2.053" />
        <Circle cx="282.894" cy="124.046" fill="#FFFFFF" r="1.589" />
        <Circle cx="52.595" cy="124.67" fill="#FFFFFF" r="1.402" />
        <Circle cx="53.508" cy="87.235" fill="#FFFFFF" r="1.589" />
        <Circle cx="128.05" cy="64.43" fill="#FFFFFF" r="0.881" />
        <Circle cx="41.62" cy="99.657" fill="#FFFFFF" r="0.881" />
        <Circle cx="98.359" cy="124.831" fill="#FFFFFF" r="0.881" />
        <Circle cx="118.757" cy="53.376" fill="#FFFFFF" r="0.881" />
        <Circle cx="292.959" cy="90.302" fill="#FFFFFF" r="0.881" />
        <Circle cx="248.872" cy="59.52" fill="#FFFFFF" r="0.881" />
        <Circle cx="99.342" cy="53.361" fill="#FFFFFF" r="1.779" />
        <Circle cx="289.313" cy="71.624" fill="#FFFFFF" r="1.779" />
        <Circle cx="41.951" cy="117.273" fill="#FFFFFF" r="1.779" />
        <Circle cx="121.125" cy="108.013" fill="#FFFFFF" r="1.779" />
        <Circle cx="146.886" cy="27.926" fill="#FFFFFF" r="1.779" />
        <Circle cx="243.225" cy="82.947" fill="#FFFFFF" r="1.789" />
        <Circle cx="222.307" cy="59.75" fill="#FFFFFF" r="2.477" />
        <Circle cx="227.605" cy="91.983" fill="#FFFFFF" r="1.184" />
        <Circle cx="237.105" cy="65.991" fill="#FFFFFF" r="1.598" />
        <Circle cx="221.876" cy="77.232" fill="#FFFFFF" r="2.11" />
      </G>
      <Rect fill="#E7EBEE" height="2.374" width="8.063" x="117.907" y="25.752" />
      <Path d="M126.622,23.395v2.198l-0.488,0.098v26.342l4.836-5.276V25.593c0,0,0.684-0.537,0.635-0.733 c-0.049-0.195-0.244-1.27-0.244-1.27l-0.342-0.342L126.622,23.395z" fill="#BAC2CA" />
      <Path d="M126.069,52.413h-8.308c-0.233,0-0.422-0.189-0.422-0.422V25.706c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v25.862h7.705l4.704-4.928V25.784c0-0.233,0.189-0.422,0.422-0.422s0.422,0.189,0.422,0.422V46.81 c0,0.108-0.042,0.213-0.117,0.292l-4.946,5.181C126.295,52.365,126.184,52.413,126.069,52.413z" fill="#010101" />
      <Path d="M126.047,45.525c-0.155,0-0.282-0.126-0.282-0.282v-19.6c0-0.155,0.126-0.282,0.282-0.282 s0.282,0.126,0.282,0.282v19.6C126.328,45.398,126.202,45.525,126.047,45.525z" fill="#010101" />
      <Path d="M130.902,25.974h-13.468c-0.642,0-1.164-0.522-1.164-1.164v-0.812c0-0.642,0.522-1.164,1.164-1.164h13.468 c0.642,0,1.164,0.522,1.164,1.164v0.812C132.066,25.452,131.544,25.974,130.902,25.974z M117.434,23.68 c-0.176,0-0.319,0.143-0.319,0.319v0.812c0,0.176,0.143,0.319,0.319,0.319h13.468c0.176,0,0.319-0.143,0.319-0.319v-0.812 c0-0.176-0.143-0.319-0.319-0.319H117.434z" fill="#010101" />
      <Path d="M126.551,25.604c-0.155,0-0.282-0.126-0.282-0.282v-1.927c0-0.155,0.126-0.282,0.282-0.282 s0.282,0.126,0.282,0.282v1.927C126.833,25.477,126.706,25.604,126.551,25.604z" fill="#010101" />
      <Polygon fill="#FFFFFF" points="200.457,134.148 200.457,59.976 151.537,22.57 106.584,57.951 106.584,134.255 " />
      <Rect fill="#E7EBEE" height="73.306" width="28.5" x="199.355" y="60.692" />
      <Path d="M204.129,69.501h24.143v-2.143l-24.747-0.306l-3.902-3.674l-0.097,2.96l2.56,2.38 C202.626,69.219,203.361,69.501,204.129,69.501z" fill="#BAC2CA" />
      <Path d="M227.61,134.042c-0.233,0-0.422-0.189-0.422-0.422V57.48c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v76.14C228.032,133.853,227.843,134.042,227.61,134.042z" fill="#010101" />
      <Path d="M235.619,67.135H206.59v-3.673h29.029c0.846,0,1.532,0.686,1.532,1.532v0.61 C237.15,66.449,236.464,67.135,235.619,67.135z" fill="#E7EBEE" />
      <Path d="M235.611,67.472H204.67c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h30.941 c0.233,0,0.422,0.189,0.422,0.422S235.844,67.472,235.611,67.472z" fill="#010101" />
      <Path d="M235.139,67.456h-2.555c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h2.555 c0.839,0,1.521-0.612,1.521-1.364c0-0.752-0.683-1.364-1.521-1.364h-2.555c-0.233,0-0.422-0.189-0.422-0.422 s0.189-0.422,0.422-0.422h2.555c1.305,0,2.366,0.991,2.366,2.209C237.505,66.465,236.444,67.456,235.139,67.456z" fill="#010101" />
      <G>
        <Polygon fill="#BAC2CA" points="235.469,62.885 181.79,20.748 151.969,20.748 206.227,63.461 201.389,63.461" />
        <Path d="M152.718,21.562c-0.384-0.388-0.948-0.607-1.502-0.598c-0.571,0.008-1.11,0.242-1.481,0.641L97.671,64.052 c-0.712,0.767-0.612,1.92,0.223,2.574c0.836,0.654,2.09,0.563,2.801-0.205l50.6-40.869l52.139,40.874 c0.392,0.396,0.931,0.598,1.471,0.598c0.477,0,0.955-0.157,1.336-0.475c0.812-0.678,0.872-1.833,0.134-2.579L152.718,21.562z" fill="#FFFFFF" />
        <Polygon display="none" fill="#FFFFFF" points="151.133,25.629 182.867,57.597 185.326,96.475 118.431,96.475 118.431,67.224  112.877,67.224" />
        <Polygon fill="#BAC2CA" points="105.876,61.371 105.876,67.164 100.461,67.291" />
        <Path d="M105.864,67.523h-5.563c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h5.563 c0.233,0,0.422,0.189,0.422,0.422S106.098,67.523,105.864,67.523z" fill="#010101" />
        <Polygon fill="#BAC2CA" points="153.355,27.564 106.351,65.246 106.351,61.775 151.238,25.886" />
        <Path d="M235.959,63.884h-29.604c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h28.387l-53.098-41.868 h-12.557c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h12.703c0.095,0,0.187,0.032,0.262,0.091l54.169,42.713 c0.141,0.111,0.196,0.3,0.137,0.47C236.299,63.77,236.139,63.884,235.959,63.884z" fill="#010101" />
        <Path d="M166.26,21.171h-1.852c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h1.852 c0.233,0,0.422,0.189,0.422,0.422S166.494,21.171,166.26,21.171z" fill="#010101" />
        <Path d="M151.174,21.243c-0.232,0-0.421-0.187-0.422-0.419c-0.001-0.233,0.186-0.424,0.419-0.425l10.608-0.072 c0.001,0,0.002,0,0.003,0c0.232,0,0.421,0.187,0.422,0.419c0.001,0.233-0.186,0.424-0.419,0.425l-10.608,0.072 C151.175,21.243,151.174,21.243,151.174,21.243z" fill="#010101" />
        <Path d="M99.195,67.485c-0.55,0-1.107-0.172-1.561-0.527c-0.502-0.393-0.805-0.945-0.854-1.556 c-0.047-0.601,0.159-1.183,0.582-1.638l52.106-42.487c0.407-0.443,1.057-0.727,1.741-0.736c0.689,0.001,1.332,0.252,1.79,0.704 l53.637,42.394c0.478,0.479,0.704,1.056,0.675,1.66c-0.029,0.609-0.313,1.169-0.8,1.575c-0.942,0.786-2.523,0.715-3.377-0.15 l-51.835-40.631L100.961,66.75C100.516,67.233,99.861,67.485,99.195,67.485z M151.249,21.386c-0.009,0-0.017,0-0.026,0 c-0.456,0.006-0.885,0.191-1.178,0.506L97.939,64.379c-0.217,0.24-0.345,0.594-0.316,0.957c0.029,0.372,0.218,0.711,0.532,0.957 c0.666,0.52,1.667,0.449,2.231-0.159l50.644-40.91c0.152-0.124,0.37-0.126,0.526-0.004l52.139,40.874 c0.634,0.633,1.657,0.648,2.275,0.131c0.304-0.253,0.48-0.597,0.498-0.967c0.017-0.365-0.123-0.717-0.394-0.991l-53.618-42.375 c-0.014-0.011-0.026-0.022-0.039-0.035C152.124,21.561,151.689,21.386,151.249,21.386z" fill="#010101" />
      </G>
      <Path d="M106.882,116.211c-0.872-0.143-1.768-0.217-2.68-0.217c-5.445,0-10.27,2.645-13.267,6.718 c-0.6,0.816-1.655,1.116-2.613,0.788c-1.166-0.399-2.416-0.617-3.717-0.617c-3.619,0-6.847,1.674-8.952,4.29 c0,0-2.294,3.006-2.478,6.935H107L106.882,116.211z" fill="#FFFFFF" />
      <Path d="M75.619,125.796c0,0,3.504-2.858,9.152-1.565c5.648,1.293,6.328-0.068,8.029-2.109 c1.701-2.041,8.268-4.933,13.201-3.538v-2.688c0,0-5.75-1.259-10.581,1.599s-5.512,5.069-6.26,5.376 c-0.749,0.306-1.055,0.476-3.368-0.17s-7.587,0.136-10.275,3.232" fill="#DCE2E7" />
      <Path d="M71.768,134.181c-0.005,0-0.01,0-0.015,0c-0.233-0.008-0.416-0.203-0.408-0.436 c0.113-3.339,2.725-7.063,2.836-7.22c2.29-2.847,5.673-4.468,9.297-4.468c1.319,0,2.615,0.215,3.854,0.64 c0.797,0.271,1.655,0.015,2.136-0.639c3.174-4.314,8.261-6.89,13.607-6.89c0.921,0,1.845,0.075,2.748,0.222 c0.23,0.038,0.387,0.255,0.349,0.485c-0.038,0.23-0.252,0.382-0.485,0.349c-0.858-0.14-1.737-0.211-2.612-0.211 c-5.079,0-9.911,2.447-12.927,6.546c-0.701,0.953-1.944,1.33-3.09,0.937c-1.15-0.394-2.354-0.594-3.58-0.594 c-3.367,0-6.51,1.506-8.623,4.132c-0.01,0.016-2.561,3.656-2.666,6.74C72.182,134.001,71.994,134.181,71.768,134.181z" fill="#010101" />
      <Path d="M106.021,121.542c-0.233,0-0.422-0.189-0.422-0.422V90.807c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v30.312C106.444,121.353,106.255,121.542,106.021,121.542z" fill="#010101" />
      <Path d="M106.021,87.998c-0.233,0-0.422-0.189-0.422-0.422V62.455c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v25.121C106.444,87.809,106.255,87.998,106.021,87.998z" fill="#010101" />
      <Path d="M199.233,134.126c-0.233,0-0.422-0.189-0.422-0.422V63.189c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v70.515C199.655,133.937,199.466,134.126,199.233,134.126z" fill="#010101" />
      <Path d="M101.575,123.73c0,0,6.33-1.566,9.724,0.261c3.394,1.827,4.111,4.568,5.874,5.417s3.328,0.522,5.025-0.587 c1.697-1.109,7.701-0.587,9.071,3.916c0,0-1.24-6.461-7.114-6.135c-5.874,0.326-3.785,2.676-6.526-0.653 C114.888,122.621,109.668,118.183,101.575,123.73z" fill="#DCE2E7" />
      <Path d="M131.581,134.469c-0.106,0-0.211-0.038-0.29-0.116c-0.098-0.098-0.143-0.226-0.129-0.349 c0.049-1.934-0.65-3.748-1.973-5.112c-1.266-1.306-2.946-2.025-4.732-2.025c-1.346,0-2.644,0.412-3.753,1.19 c-0.428,0.299-0.964,0.385-1.472,0.236c-0.54-0.159-0.989-0.57-1.204-1.099c-1.381-3.411-5.188-5.703-9.472-5.703 c-3.339,0-6.446,1.397-8.312,3.737c-0.678,0.852-1.791,1.192-2.833,0.868c-0.94-0.292-1.924-0.439-2.926-0.439 c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422c1.086,0,2.155,0.161,3.176,0.478c0.711,0.221,1.463-0.011,1.922-0.587 c2.025-2.54,5.379-4.056,8.973-4.056c4.625,0,8.747,2.504,10.255,6.23c0.118,0.292,0.365,0.52,0.66,0.606 c0.262,0.076,0.528,0.036,0.747-0.118c1.252-0.879,2.718-1.344,4.239-1.344c2.017,0,3.913,0.811,5.339,2.282 c1.493,1.54,2.277,3.59,2.209,5.771c-0.003,0.096-0.039,0.188-0.102,0.262C131.818,134.424,131.698,134.469,131.581,134.469z" fill="#010101" />
      <G>
        <Path d="M94.486,125.235c-0.502,0-0.995,0.036-1.476,0.104" fill="#FFFFFF" />
        <Path d="M93.009,125.762c-0.207,0-0.388-0.152-0.417-0.363c-0.033-0.231,0.127-0.445,0.358-0.478 c0.505-0.072,1.022-0.108,1.535-0.108c0.233,0,0.422,0.189,0.422,0.422s-0.189,0.422-0.422,0.422c-0.474,0-0.95,0.034-1.416,0.1 C93.049,125.76,93.029,125.762,93.009,125.762z" fill="#010101" />
      </G>
      <Path d="M195.168,65.436h-85.94c-0.155,0-0.282-0.126-0.282-0.282s0.126-0.282,0.282-0.282h85.94 c0.155,0,0.282,0.126,0.282,0.282S195.323,65.436,195.168,65.436z" fill="#010101" />
      <Path d="M197.665,62.442h-23.211c-0.155,0-0.282-0.126-0.282-0.282s0.126-0.282,0.282-0.282h23.211 c0.155,0,0.282,0.126,0.282,0.282S197.82,62.442,197.665,62.442z" fill="#010101" />
      <Path d="M171.516,62.442h-57.587c-0.155,0-0.282-0.126-0.282-0.282s0.126-0.282,0.282-0.282h57.587 c0.155,0,0.282,0.126,0.282,0.282S171.672,62.442,171.516,62.442z" fill="#010101" />
      <Path d="M85.219,134.086c-0.233,0-0.422-0.189-0.422-0.422v-27.985c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v27.985C85.641,133.897,85.452,134.086,85.219,134.086z" fill="#010101" />
      <Path d="M59.978,134.248" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8447" />
      <Path d="M278.474,134.172" fill="none" stroke="#010101" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.7045" />
      <Ellipse cx="152.271" cy="47.415" fill="#F0F1F0" rx="4.626" ry="5.447" />
      <Path d="M151.409,53.819c-3.532,0-6.406-2.937-6.406-6.546s2.874-6.546,6.406-6.546c3.532,0,6.405,2.937,6.405,6.546 S154.941,53.819,151.409,53.819z M151.409,41.572c-3.066,0-5.561,2.558-5.561,5.701s2.495,5.701,5.561,5.701 c3.066,0,5.561-2.558,5.561-5.701S154.475,41.572,151.409,41.572z" fill="#010101" />
      <Path d="M152.262,53.605c-0.019,0-0.038-0.002-0.057-0.006c-3.038-0.625-5.242-3.26-5.242-6.266 c0-2.133,1.094-4.119,2.925-5.313c0.13-0.083,0.305-0.048,0.39,0.082c0.085,0.13,0.048,0.305-0.082,0.39 c-1.672,1.09-2.669,2.9-2.669,4.842c0,2.74,2.015,5.143,4.792,5.714c0.152,0.031,0.25,0.18,0.219,0.333 C152.51,53.514,152.393,53.605,152.262,53.605z" fill="#010101" />
      <G>
        <Polyline fill={svgHighlight} points="162.94,122.448 162.94,87.115 182.247,87.115 182.247,122.448" />
        <Path d="M182.247,122.871c-0.233,0-0.422-0.189-0.422-0.422V87.538h-18.462v34.911c0,0.233-0.189,0.422-0.422,0.422 s-0.422-0.189-0.422-0.422V87.115c0-0.233,0.189-0.422,0.422-0.422h19.307c0.233,0,0.422,0.189,0.422,0.422v35.333 C182.67,122.682,182.481,122.871,182.247,122.871z" fill="#010101" />
      </G>
      <Path d="M181.61,134.273h-30.044c-0.233,0-0.422-0.189-0.422-0.422v-5.75c0-0.233,0.189-0.422,0.422-0.422h5.038 v-5.213c0-0.233,0.189-0.422,0.422-0.422h24.584c0.233,0,0.422,0.189,0.422,0.422v11.385 C182.032,134.084,181.843,134.273,181.61,134.273z M151.988,133.428h29.199v-10.541h-23.739v5.213c0,0.233-0.189,0.422-0.422,0.422 h-5.038V133.428z" fill="#010101" />
      <G>
        <Polygon fill="#BAC2CA" points="178.795,122.465 178.795,128.101 173.335,128.101 173.335,133.85 178.795,133.85 179.42,133.85  185.676,133.85 185.676,122.465" />
        <Path d="M185.676,134.273h-12.341c-0.233,0-0.422-0.189-0.422-0.422v-5.75c0-0.233,0.189-0.422,0.422-0.422h5.038 v-5.213c0-0.233,0.189-0.422,0.422-0.422h6.881c0.233,0,0.422,0.189,0.422,0.422v11.385 C186.098,134.084,185.909,134.273,185.676,134.273z M173.757,133.428h11.497v-10.541h-6.037v5.213c0,0.233-0.189,0.422-0.422,0.422 h-5.038V133.428z" fill="#010101" />
      </G>
      <Path d="M152.205,51.399c-0.155,0-0.282-0.126-0.282-0.282v-8.206c0-0.155,0.126-0.282,0.282-0.282 s0.282,0.126,0.282,0.282v8.206C152.486,51.272,152.36,51.399,152.205,51.399z" fill="#010101" />
      <Path d="M157.206,47.296h-8.573c-0.155,0-0.282-0.126-0.282-0.282s0.126-0.282,0.282-0.282h8.573 c0.155,0,0.282,0.126,0.282,0.282S157.361,47.296,157.206,47.296z" fill="#010101" />
      <Rect fill="#E7EBEE" height="25.36" width="30.053" x="118.163" y="78.786" />
      <Polygon fill="#FFFFFF" opacity="0.7" points="116.867,98.959 124.182,78.786 117.588,78.786 116.867,80.44 " />
      <Polygon fill="#FFFFFF" opacity="0.7" points="125.99,104.146 121.886,104.146 131.461,78.786 135.564,78.786 " />
      <Path d="M148.461,104.769h-31.714c-0.233,0-0.422-0.189-0.422-0.422V77.414c0-0.233,0.189-0.422,0.422-0.422h31.714 c0.233,0,0.422,0.189,0.422,0.422v26.933C148.883,104.58,148.694,104.769,148.461,104.769z M117.169,103.924h30.869V77.836h-30.869 V103.924z" fill="#010101" />
      <Path d="M118.185,103.084c-0.155,0-0.282-0.126-0.282-0.282V78.807c0-0.155,0.126-0.282,0.282-0.282h30.395 c0.155,0,0.282,0.126,0.282,0.282s-0.126,0.282-0.282,0.282h-30.113v23.714C118.466,102.958,118.34,103.084,118.185,103.084z" fill="#010101" />
      <Path d="M127.602,102.755c-0.233,0-0.422-0.189-0.422-0.422V89.191c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v13.141C128.024,102.566,127.835,102.755,127.602,102.755z" fill="#010101" />
      <Path d="M127.602,87.343c-0.233,0-0.422-0.189-0.422-0.422v-6.103c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v6.103C128.024,87.154,127.835,87.343,127.602,87.343z" fill="#010101" />
      <Path d="M137.573,95.716c-0.233,0-0.422-0.189-0.422-0.422V80.818c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v14.476C137.995,95.527,137.806,95.716,137.573,95.716z" fill="#010101" />
      <Path d="M137.573,102.755c-0.233,0-0.422-0.189-0.422-0.422v-4.768c0-0.233,0.189-0.422,0.422-0.422 s0.422,0.189,0.422,0.422v4.768C137.995,102.566,137.806,102.755,137.573,102.755z" fill="#010101" />
      <Path d="M148.445,91.513h-28.363c-0.233,0-0.422-0.189-0.422-0.422s0.189-0.422,0.422-0.422h28.363 c0.233,0,0.422,0.189,0.422,0.422S148.678,91.513,148.445,91.513z" fill="#010101" />
      <G>
        <Path d="M174.632,89.191h-19.74l1.305-2.987c0.248-0.567,0.539-1.11,0.871-1.625 c1.455-2.26,3.908-3.686,6.565-4.092l0,0l18.519-0.055L174.632,89.191z" fill="#E7EBEE" />
        <Path d="M174.632,89.614h-19.741c-0.143,0-0.275-0.072-0.354-0.191c-0.078-0.12-0.09-0.27-0.034-0.4l1.305-2.987 c0.255-0.583,0.559-1.15,0.903-1.685c1.468-2.28,3.967-3.84,6.856-4.28l18.581-0.06c0,0,0.001,0,0.001,0 c0.165,0,0.314,0.096,0.383,0.246c0.069,0.15,0.045,0.327-0.063,0.452l-7.52,8.759C174.872,89.56,174.755,89.614,174.632,89.614z M155.537,88.769h18.901l6.792-7.912l-17.596,0.052c-2.588,0.399-4.875,1.822-6.211,3.897c-0.32,0.498-0.603,1.024-0.839,1.566 L155.537,88.769z" fill="#010101" />
      </G>
      <G>
        <Path d="M182.207,89.191h-8.75l1.305-2.987c0.508-1.163,1.201-2.226,2.046-3.152 c1.236-1.355,2.942-2.191,4.755-2.468l0.636-0.097L182.207,89.191z" fill="#BAC2CA" />
        <Path d="M182.207,89.614h-8.75c-0.143,0-0.275-0.072-0.354-0.191c-0.078-0.12-0.09-0.27-0.034-0.4l1.305-2.987 c0.524-1.201,1.238-2.3,2.121-3.268c1.255-1.376,3.032-2.3,5.003-2.601l0.636-0.097c0.121-0.019,0.245,0.017,0.338,0.097 s0.147,0.197,0.147,0.32l0.009,8.704c0,0.112-0.044,0.22-0.124,0.299C182.426,89.569,182.319,89.614,182.207,89.614z M174.102,88.769h7.682l-0.008-7.791l-0.15,0.023c-1.78,0.272-3.381,1.101-4.506,2.335c-0.82,0.899-1.483,1.921-1.971,3.036 L174.102,88.769z" fill="#010101" />
      </G>
      <Path d="M129.484,103.962c0-2.669-2.199-4.992-4.747-4.992c-1.984,0-3.671,1.315-4.322,3.156 c-0.697-1.032-1.839-1.711-3.138-1.711c-1.986,0-3.62,1.576-3.825,3.599" fill="#FFFFFF" />
      <Path d="M113.452,103.959c-0.015,0-0.03-0.001-0.045-0.002c-0.232-0.024-0.4-0.232-0.375-0.464 c0.231-2.175,2.056-3.814,4.245-3.814c1.144,0,2.234,0.466,3.03,1.271c0.869-1.609,2.572-2.651,4.43-2.651 c2.753,0,5.169,2.424,5.169,5.187c0,0.233-0.189,0.422-0.422,0.422s-0.422-0.189-0.422-0.422c0-2.313-2.021-4.342-4.324-4.342 c-1.742,0-3.32,1.1-3.927,2.737c-0.054,0.147-0.186,0.251-0.341,0.272c-0.152,0.02-0.31-0.047-0.399-0.175 c-0.644-0.911-1.688-1.455-2.793-1.455c-1.756,0-3.22,1.315-3.405,3.059C113.849,103.798,113.666,103.959,113.452,103.959z" fill="#010101" />
      <Path d="M127.512,103.966c0-2.469,2.034-4.617,4.391-4.617c1.835,0,3.395,1.216,3.998,2.919 c0.644-0.955,1.701-1.582,2.902-1.582c1.837,0,3.348,1.458,3.538,3.329" fill="#FFFFFF" />
      <Path d="M142.34,103.995c-0.213,0-0.396-0.161-0.419-0.378c-0.169-1.596-1.51-2.8-3.118-2.8 c-1.012,0-1.968,0.498-2.558,1.332c-0.09,0.128-0.243,0.199-0.399,0.175c-0.155-0.021-0.287-0.125-0.341-0.272 c-0.557-1.502-2.004-2.511-3.602-2.511c-2.114,0-3.968,1.862-3.968,3.985c0,0.233-0.189,0.422-0.422,0.422s-0.422-0.189-0.422-0.422 c0-2.573,2.249-4.83,4.813-4.83c1.716,0,3.29,0.954,4.105,2.431c0.74-0.732,1.744-1.155,2.795-1.155 c2.041,0,3.743,1.529,3.958,3.556c0.025,0.232-0.144,0.44-0.375,0.464C142.37,103.994,142.355,103.995,142.34,103.995z" fill="#010101" />
      <G>
        <Path d="M147.516,111.154h-32.857c-1.549,0-2.804-1.256-2.804-2.804v-4.027h35.661V111.154z" fill="#E7EBEE" />
        <Path d="M147.516,111.576h-32.857c-1.779,0-3.227-1.448-3.227-3.227v-4.026c0-0.233,0.189-0.422,0.422-0.422h35.662 c0.233,0,0.422,0.189,0.422,0.422v6.831C147.938,111.387,147.749,111.576,147.516,111.576z M112.277,104.745v3.604 c0,1.313,1.069,2.382,2.382,2.382h32.435v-5.986H112.277z" fill="#010101" />
      </G>
      <G>
        <Path d="M147.544,111.154h-1.048c-1.455,0-2.634-1.179-2.634-2.634v-4.197h6.317v4.197 C150.178,109.974,148.999,111.154,147.544,111.154z" fill="#BAC2CA" />
        <Path d="M147.544,111.576h-1.048c-1.686,0-3.057-1.371-3.057-3.057v-4.197c0-0.233,0.189-0.422,0.422-0.422h6.317 c0.233,0,0.422,0.189,0.422,0.422v4.197C150.601,110.205,149.23,111.576,147.544,111.576z M144.284,104.745v3.774 c0,1.22,0.992,2.212,2.212,2.212h1.048c1.22,0,2.212-0.992,2.212-2.212v-3.774H144.284z" fill="#010101" />
      </G>
      <Path d="M170.078,128.379h-12.831c-0.155,0-0.282-0.126-0.282-0.282s0.126-0.282,0.282-0.282h12.831 c0.155,0,0.282,0.126,0.282,0.282S170.234,128.379,170.078,128.379z" fill="#010101" />
      <G>
        <Circle cx="172.297" cy="95.985" fill="#FFFFFF" r="0.833" />
        <Path d="M172.297,97.1c-0.615,0-1.115-0.5-1.115-1.115s0.5-1.115,1.115-1.115s1.115,0.5,1.115,1.115 S172.912,97.1,172.297,97.1z M172.297,95.434c-0.304,0-0.551,0.248-0.551,0.552s0.247,0.551,0.551,0.551s0.552-0.247,0.552-0.551 S172.601,95.434,172.297,95.434z" fill="#010101" />
      </G>
      <Path d="M90.828,105.825H79.517c-0.233,0-0.422-0.189-0.422-0.422v-4.937c0-1.46,1.188-2.647,2.648-2.647h9.086 c0.233,0,0.422,0.189,0.422,0.422v7.162C91.251,105.636,91.062,105.825,90.828,105.825z M79.939,104.98h10.467v-6.317h-8.664 c-0.994,0-1.803,0.809-1.803,1.803V104.98z" fill="#010101" />
      <G>
        <Path d="M92.977,105.403h-5.013v-5.071c0-1.155,0.936-2.091,2.091-2.091h0.832c1.155,0,2.091,0.936,2.091,2.091 V105.403z" fill="#BAC2CA" />
        <Path d="M92.977,105.825h-5.013c-0.233,0-0.422-0.189-0.422-0.422v-5.071c0-1.385,1.127-2.513,2.513-2.513h0.832 c1.385,0,2.513,1.127,2.513,2.513v5.071C93.399,105.636,93.21,105.825,92.977,105.825z M88.386,104.98h4.168v-4.649 c0-0.92-0.748-1.668-1.668-1.668h-0.832c-0.92,0-1.668,0.748-1.668,1.668V104.98z" fill="#010101" />
      </G>
      <Path d="M85.65,101.473c-0.18,0-0.347-0.116-0.403-0.296l-1.521-4.867l-0.796,0.29 c-0.217,0.08-0.461-0.033-0.541-0.253c-0.08-0.219,0.034-0.461,0.253-0.541l1.212-0.44c0.108-0.039,0.228-0.033,0.332,0.019 c0.103,0.051,0.181,0.142,0.216,0.252l1.653,5.289c0.069,0.223-0.055,0.459-0.277,0.529 C85.734,101.467,85.692,101.473,85.65,101.473z" fill="#010101" />
      <Path d="M114.941,129.227c-0.1,0-0.196-0.053-0.247-0.146c-0.287-0.522-0.626-1.01-1.006-1.451 c-0.102-0.118-0.089-0.295,0.029-0.397c0.118-0.102,0.295-0.089,0.397,0.029c0.407,0.471,0.768,0.992,1.074,1.548 c0.075,0.136,0.025,0.308-0.111,0.382C115.033,129.216,114.986,129.227,114.941,129.227z" fill="#010101" />
      <Path d="M112.534,126.458c-0.059,0-0.118-0.018-0.168-0.056c-2.292-1.707-4.865-1.798-4.891-1.799 c-0.155-0.004-0.278-0.133-0.273-0.289c0.004-0.155,0.125-0.29,0.289-0.273c0.112,0.003,2.771,0.092,5.211,1.91 c0.125,0.093,0.151,0.269,0.058,0.394C112.704,126.418,112.62,126.458,112.534,126.458z" fill="#010101" />
      <G>
        <Path d="M163.332,112.322c0,0-1.506-0.037-4.37,1.616c-2.865,1.653-4.26,5.178-2.461,7.492s4.664,0.808,4.664,0.808 l7.602,0.073c0,0,3.342-0.514,3.379-3.269c0.037-2.754-1.028-6.611-1.028-6.611L163.332,112.322z" fill="#FFFFFF" />
        <G>
          <Path d="M173.222,110.503c0.933-3.298,0.186-4.539,0.186-4.539c-2.314,0.055-3.705,1.46-3.705,1.46 c-1.599-0.601-3.509,0-3.509,0s-1.391-1.405-3.705-1.46c0,0-0.748,1.242,0.186,4.539c0,0-1.369,3.329,1.887,4.594 s5.372,0.446,6.886-0.093c1.514-0.539,2.561-2.209,2.022-3.816L173.222,110.503z" fill="#FFFFFF" />
          <Path d="M167.876,116.185c-1.001,0-2.15-0.182-3.468-0.694c-1.053-0.409-1.778-1.062-2.155-1.94 c-0.547-1.277-0.172-2.628-0.023-3.065c-0.899-3.279-0.189-4.599-0.104-4.74c0.078-0.13,0.22-0.218,0.372-0.205 c1.984,0.047,3.33,1.012,3.806,1.414c0.555-0.138,1.977-0.416,3.3-0.011c0.487-0.408,1.827-1.356,3.793-1.403 c0.148-0.017,0.293,0.075,0.372,0.205c0.085,0.141,0.795,1.461-0.104,4.741l0.202,0.557c0.587,1.75-0.436,3.701-2.278,4.358 l-0.117,0.042C170.572,115.765,169.396,116.185,167.876,116.185z M162.769,106.401c-0.136,0.464-0.337,1.697,0.311,3.987 c0.026,0.091,0.02,0.188-0.015,0.275c-0.006,0.014-0.545,1.373-0.033,2.56c0.284,0.659,0.85,1.157,1.682,1.48 c3.025,1.175,5.017,0.464,6.473-0.056l0.118-0.042c1.236-0.44,2.239-1.865,1.763-3.284l-0.244-0.675 c-0.03-0.083-0.033-0.173-0.009-0.259c0.646-2.286,0.448-3.518,0.31-3.987c-1.931,0.152-3.112,1.309-3.124,1.321 c-0.118,0.118-0.293,0.156-0.448,0.098c-1.437-0.54-3.216,0.003-3.234,0.007c-0.15,0.047-0.315,0.005-0.426-0.105 C165.882,107.709,164.701,106.553,162.769,106.401z" fill="#010101" />
        </G>
        <Path d="M162.778,110.859c-0.041,0-0.083-0.009-0.122-0.028c-0.14-0.067-0.199-0.236-0.132-0.376 c0.546-1.135,1.42-1.853,1.853-2.158c-0.738-0.996-1.854-1.853-1.867-1.862c-0.124-0.095-0.147-0.271-0.053-0.395 c0.094-0.124,0.271-0.147,0.395-0.053c0.056,0.042,1.382,1.061,2.157,2.23c0.043,0.064,0.057,0.144,0.039,0.22 s-0.066,0.141-0.133,0.18c-0.012,0.007-1.23,0.723-1.883,2.082C162.983,110.8,162.882,110.859,162.778,110.859z" fill="#010101" />
        <Path d="M173.121,110.859c-0.104,0-0.205-0.059-0.254-0.16c-0.653-1.359-1.872-2.075-1.884-2.082 c-0.067-0.039-0.116-0.104-0.133-0.18s-0.004-0.155,0.039-0.22c0.775-1.169,2.102-2.188,2.158-2.23 c0.124-0.095,0.301-0.071,0.395,0.053c0.095,0.124,0.071,0.3-0.053,0.395c-0.012,0.009-1.129,0.868-1.867,1.862 c0.433,0.305,1.308,1.023,1.854,2.158c0.067,0.14,0.008,0.309-0.132,0.376C173.203,110.85,173.162,110.859,173.121,110.859z" fill="#010101" />
        <Path d="M168.466,113.269h-0.827c-0.155,0-0.282-0.126-0.282-0.282s0.126-0.282,0.282-0.282h0.827 c0.155,0,0.282,0.126,0.282,0.282S168.621,113.269,168.466,113.269z" fill="#010101" />
        <Path d="M167.382,114.404c-0.005,0-0.01,0-0.016,0c-0.713-0.011-0.96-0.563-0.991-0.853 c-0.017-0.155,0.096-0.293,0.25-0.31c0.15-0.025,0.291,0.093,0.31,0.247c0.011,0.082,0.075,0.347,0.44,0.353 c0.001,0,0.002,0,0.004,0c0.342,0,0.384-0.795,0.384-0.803c0.004-0.155,0.139-0.255,0.288-0.275 c0.155,0.003,0.278,0.132,0.275,0.287C168.317,113.555,168.105,114.404,167.382,114.404z" fill="#010101" />
        <Path d="M168.636,114.404c-0.723,0-0.935-0.849-0.945-1.354c-0.003-0.155,0.12-0.284,0.275-0.287 c0.159,0.011,0.284,0.12,0.288,0.275c0,0.008,0.042,0.803,0.384,0.803c0.001,0,0.002,0,0.003,0c0.366-0.005,0.43-0.271,0.44-0.353 c0.021-0.152,0.16-0.262,0.313-0.244c0.153,0.018,0.263,0.154,0.247,0.307c-0.031,0.291-0.277,0.842-0.991,0.854 C168.647,114.404,168.641,114.404,168.636,114.404z" fill="#010101" />
        <Circle cx="165.312" cy="111.776" fill="#010101" r="0.344" />
        <Circle cx="170.628" cy="111.666" fill="#010101" r="0.344" />
        <Path d="M159.517,123.036c-0.039,0-0.079,0-0.119-0.001c-1.566-0.035-2.866-0.594-3.565-1.535 c-0.679-0.913-0.814-2.132-0.402-3.625c1.381-5.002,6.675-5.88,6.899-5.915c0.23-0.038,0.446,0.122,0.482,0.352 c0.036,0.23-0.122,0.446-0.352,0.483c-0.05,0.008-4.982,0.836-6.215,5.305c-0.339,1.229-0.25,2.204,0.265,2.896 c0.543,0.73,1.603,1.166,2.906,1.194c1.125,0.039,1.914-0.315,3.18-1.349l0.358-0.375c0.079-0.081,0.121-0.188,0.119-0.301 c-0.002-0.113-0.048-0.219-0.13-0.297c-0.157-0.151-0.408-0.153-0.571-0.005c-1.653,1.503-3.334,1.586-4.355,1.163 c-1.124-0.465-1.326-1.532-1.276-2.031c0.024-0.232,0.233-0.397,0.462-0.378c0.231,0.023,0.4,0.229,0.378,0.459 c-0.007,0.087-0.042,0.838,0.759,1.17c0.746,0.309,2.102,0.231,3.463-1.007c0.491-0.445,1.249-0.437,1.725,0.021 c0.245,0.235,0.383,0.551,0.389,0.891s-0.12,0.661-0.356,0.905l-0.395,0.408C161.969,122.444,160.95,123.036,159.517,123.036z" fill="#010101" />
        <Path d="M167.279,122.86c-2.663,0-6.044-0.032-6.044-0.032l0.008-0.845c0,0,3.378,0.032,6.037,0.032 c0.64,0,1.157-0.009,1.574-0.022c0.688-0.021,1.226-0.581,1.226-1.273c0-0.343-0.134-0.665-0.376-0.907 c-0.241-0.239-0.609-0.377-0.896-0.367c-1.157,0.005-2.067-0.041-2.687-0.139c-0.23-0.037-0.387-0.253-0.351-0.483 c0.037-0.23,0.251-0.39,0.483-0.351c0.566,0.09,1.451,0.142,2.549,0.128c0.559,0.017,1.096,0.214,1.497,0.612 c0.404,0.401,0.626,0.936,0.626,1.506c0,1.151-0.898,2.082-2.044,2.118C168.455,122.852,167.929,122.86,167.279,122.86z" fill="#010101" />
        <Path d="M169.496,122.683c-0.208,0-0.39-0.154-0.418-0.367c-0.031-0.231,0.131-0.443,0.363-0.474 c0.707-0.094,1.235-0.411,1.615-0.967c1.295-1.897,0.359-5.796,0.35-5.835c-0.056-0.226,0.083-0.455,0.31-0.511 c0.222-0.056,0.455,0.083,0.511,0.309c0.043,0.175,1.036,4.302-0.471,6.512c-0.516,0.756-1.257,1.204-2.203,1.33 C169.533,122.682,169.514,122.683,169.496,122.683z" fill="#010101" />
      </G>
      <Path d="M48.364,134.631h-2.991c-0.249,0-0.45-0.201-0.45-0.45s0.201-0.45,0.45-0.45h2.991 c0.249,0,0.45,0.201,0.45,0.45S48.613,134.631,48.364,134.631z" fill="#010101" />
      <Path d="M285.906,134.176c0,0.25-0.2,0.46-0.45,0.46H50.946c-0.25,0-0.45-0.21-0.45-0.46s0.2-0.45,0.45-0.45h234.51 C285.706,133.726,285.906,133.926,285.906,134.176z" fill="#010101" />
      <G>
        <Path d="M291.853,134.53h-2.991c-0.249,0-0.45-0.201-0.45-0.45s0.201-0.45,0.45-0.45h2.991 c0.249,0,0.45,0.201,0.45,0.45S292.102,134.53,291.853,134.53z" fill="#010101" />
      </G>
      <G>
        <Path d="M133.502,110.46v2.192c0,0.3,0.164,1.592-0.542,1.59l0,0c-0.601-0.008-0.542-1.29-0.542-1.59v-0.024 c0-0.289-0.234-0.523-0.523-0.523l0,0c-0.289,0-0.523,0.234-0.523,0.523v2c0,0.308-0.25,0.558-0.558,0.558l0,0 c-0.308,0-0.558-0.25-0.558-0.558v-2.302c0-0.288-0.233-0.521-0.521-0.521l0,0c-0.288,0-0.521,0.233-0.521,0.521v0.237 c0,0.296-0.24,0.537-0.537,0.537l0,0c-0.296,0-0.537-0.24-0.537-0.537v-2.925" fill="#E7EBEE" />
        <Path d="M130.813,115.436c-0.446,0-0.809-0.362-0.809-0.808v-2.302c0-0.149-0.121-0.271-0.27-0.271 s-0.271,0.122-0.271,0.271v0.237c0,0.434-0.354,0.786-0.787,0.786s-0.786-0.353-0.786-0.786v-1.568c0-0.138,0.112-0.25,0.25-0.25 s0.25,0.112,0.25,0.25v1.568c0,0.158,0.128,0.286,0.286,0.286s0.287-0.128,0.287-0.286v-0.237c0-0.425,0.346-0.771,0.771-0.771 s0.77,0.346,0.77,0.771v2.302c0,0.17,0.139,0.308,0.309,0.308s0.309-0.138,0.309-0.308v-2c0-0.426,0.347-0.773,0.772-0.773 s0.772,0.347,0.772,0.773v1.071c0,0.161,0.132,0.292,0.293,0.292s0.292-0.131,0.292-0.292v-2.741c0-0.138,0.112-0.25,0.25-0.25 s0.25,0.112,0.25,0.25v2.741c0,0.437-0.355,0.792-0.792,0.792c-0.438,0-0.793-0.355-0.793-0.792v-1.071 c0-0.15-0.122-0.273-0.272-0.273s-0.272,0.123-0.272,0.273v2C131.622,115.074,131.259,115.436,130.813,115.436z" fill="#010101" />
      </G>
      <G>
        <Path d="M213.54,65.995v3.334c0,0.558-0.305,2.963,1.01,2.959l0,0c1.119-0.015,1.01-2.402,1.01-2.959v-0.044 c0-0.537,0.436-0.973,0.973-0.973l0,0c0.537,0,0.973,0.436,0.973,0.973v3.723c0,0.574,0.465,1.039,1.039,1.039l0,0 c0.574,0,1.039-0.465,1.039-1.039v-4.285c0-0.535,0.434-0.969,0.969-0.969l0,0c0.535,0,0.969,0.434,0.969,0.969v0.441 c0,0.552,0.447,0.999,0.999,0.999l0,0c0.552,0,0.999-0.447,0.999-0.999v-3.674" fill="#E7EBEE" />
        <Path d="M218.545,74.513c-0.83,0-1.504-0.675-1.504-1.505v-3.723c0-0.28-0.228-0.507-0.508-0.507 c-0.28,0-0.507,0.227-0.507,0.507v1.994c0,0.814-0.662,1.475-1.475,1.475s-1.475-0.662-1.475-1.475V67.09 c0-0.257,0.208-0.465,0.465-0.465s0.465,0.208,0.465,0.465v4.189c0,0.3,0.245,0.544,0.544,0.544c0.3,0,0.544-0.245,0.544-0.544 v-1.994c0-0.793,0.645-1.438,1.438-1.438c0.794,0,1.439,0.645,1.439,1.438v3.723c0,0.316,0.257,0.574,0.574,0.574 s0.574-0.258,0.574-0.574v-4.285c0-0.791,0.644-1.434,1.434-1.434c0.792,0,1.435,0.644,1.435,1.434v0.441 c0,0.295,0.239,0.534,0.534,0.534c0.294,0,0.533-0.239,0.533-0.534v-2.146c0-0.257,0.208-0.465,0.465-0.465 s0.465,0.208,0.465,0.465v2.146c0,0.807-0.656,1.464-1.463,1.464s-1.464-0.657-1.464-1.464v-0.441c0-0.277-0.226-0.504-0.504-0.504 c-0.277,0-0.504,0.226-0.504,0.504v4.285C220.05,73.838,219.375,74.513,218.545,74.513z" fill="#010101" />
      </G>
      <G>
        <Path d="M124.074,46.356v6.098c0,0.305-0.247,0.553-0.553,0.553l0,0c-0.305,0-0.553-0.247-0.553-0.553v-0.904 c0-0.281-0.228-0.509-0.509-0.509l0,0c-0.281,0-0.509,0.228-0.509,0.509v2.705c0,0.318-0.258,0.576-0.576,0.576h0 c-0.318,0-0.576-0.258-0.576-0.576v-5.167" fill="#FFFFFF" />
        <Path d="M121.374,55.082c-0.456,0-0.826-0.371-0.826-0.826v-5.089c0-0.138,0.112-0.25,0.25-0.25 s0.25,0.112,0.25,0.25v5.089c0,0.18,0.146,0.326,0.326,0.326s0.326-0.146,0.326-0.326v-2.705c0-0.418,0.34-0.759,0.759-0.759 s0.759,0.34,0.759,0.759v0.904c0,0.167,0.136,0.303,0.303,0.303s0.303-0.136,0.303-0.303v-5.489c0-0.138,0.112-0.25,0.25-0.25 s0.25,0.112,0.25,0.25v5.489c0,0.442-0.36,0.803-0.803,0.803s-0.803-0.36-0.803-0.803v-0.904c0-0.143-0.116-0.259-0.259-0.259 s-0.259,0.116-0.259,0.259v2.705C122.201,54.712,121.83,55.082,121.374,55.082z" fill="#010101" />
      </G>
      <G>
        <Path d="M187.858,53.111v4.212c0,0.3-0.164,0.625,0.542,0.623l0,0c0.601-0.008,0.542-0.831,0.542-1.131v-0.024 c0-0.289,0.234-0.523,0.523-0.523l0,0c0.289,0,0.523,0.234,0.523,0.523v2.508c0,0.308,0.25,0.558,0.558,0.558h0 c0.308,0,0.558-0.25,0.558-0.558v-1.466c0-0.288,0.233-0.521,0.521-0.521l0,0c0.288,0,0.521,0.233,0.521,0.521v0.999 c0,0.296,0.24,0.537,0.537,0.537l0,0c0.296,0,0.537-0.24,0.537-0.537v-2.669" fill="#FFFFFF" />
        <Path d="M190.547,60.108c-0.446,0-0.809-0.362-0.809-0.808v-2.508c0-0.15-0.122-0.272-0.272-0.272 s-0.272,0.122-0.272,0.272v0.612c0,0.437-0.355,0.792-0.793,0.792c-0.437,0-0.792-0.355-0.792-0.792v-3.425 c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v3.425c0,0.161,0.131,0.292,0.292,0.292s0.293-0.131,0.293-0.292v-0.612 c0-0.426,0.347-0.772,0.772-0.772s0.772,0.347,0.772,0.772v2.508c0,0.17,0.139,0.308,0.309,0.308s0.309-0.138,0.309-0.308v-1.466 c0-0.425,0.346-0.771,0.771-0.771c0.425,0,0.771,0.346,0.771,0.771v0.999c0,0.158,0.129,0.287,0.287,0.287s0.286-0.128,0.286-0.287 v-0.976c0-0.138,0.112-0.25,0.25-0.25s0.25,0.112,0.25,0.25v0.976c0,0.434-0.353,0.787-0.786,0.787s-0.787-0.353-0.787-0.787 v-0.999c0-0.149-0.121-0.271-0.271-0.271s-0.271,0.122-0.271,0.271v1.466C191.355,59.745,190.993,60.108,190.547,60.108z" fill="#010101" />
      </G>
      <Circle cx="81.563" cy="54.257" fill="#FFFFFF" r="1.624" />
      <G>
        <Path d="M270.658,97.821l0.012,0c-6.763-40.829-16.253-39-16.253-39l-0.015,0.139l-0.012-0.105 c0,0-9.108-1.862-15.871,38.967l0.039-0.001c-0.201,1.249-0.312,2.537-0.312,3.858c0,11.018,7.325,19.949,16.362,19.949 s16.362-8.931,16.362-19.949C270.97,100.358,270.859,99.07,270.658,97.821z" fill="#DCE2E7" />
        <Path d="M270.97,101.678c0-1.32-0.111-2.608-0.312-3.857l0.012,0c-6.763-40.829-16.253-39-16.253-39l-0.015,0.139 l-0.012-0.105c0,0-0.583-0.118-1.563,0.364c2.412,4.904,10.051,21.545,10.808,37.062c0.694,14.23-4.013,21.576-9.167,25.338 c0.047,0,0.093,0.009,0.141,0.009C263.645,121.627,270.97,112.696,270.97,101.678z" fill="#BAC2CA" />
        <Path d="M246.734,67.188c0,0,1.051,4.77,6.387,5.093c5.336,0.323,12.369,4.608,14.309,10.105 c0,0-3.234-14.956-8.569-20.534c-5.336-5.578-7.842-1.051-8.569-0.081C249.563,62.741,246.734,67.188,246.734,67.188z" fill="#FFFFFF" />
        <Path d="M254.609,122.002c-9.229,0-16.737-9.117-16.737-20.324c0-1.241,0.099-2.506,0.292-3.766 c-0.017-0.049-0.023-0.101-0.015-0.152c4.712-28.449,10.634-36.285,13.744-38.425c1.189-0.818,2.041-0.885,2.399-0.868 c0.018-0.006,0.035-0.011,0.054-0.015c0.115-0.022,1.163-0.183,2.777,0.94c3.192,2.222,9.245,10.162,13.917,38.368 c0.005,0.032,0.006,0.063,0.003,0.095c0.2,1.278,0.302,2.563,0.302,3.822C271.345,112.885,263.837,122.002,254.609,122.002z M238.913,97.732c0.017,0.048,0.022,0.098,0.015,0.148c-0.203,1.27-0.307,2.547-0.307,3.798c0,10.793,7.172,19.574,15.987,19.574 c8.814,0,15.986-8.78,15.986-19.574c0-1.25-0.104-2.528-0.307-3.797c-0.005-0.03-0.006-0.061-0.004-0.091 c-6.012-36.214-14.164-38.486-15.587-38.599c-0.068,0.087-0.174,0.143-0.292,0.144c-0.121-0.022-0.205-0.044-0.274-0.116 c-0.307,0.017-0.981,0.14-1.909,0.803C249.236,62.154,243.541,69.888,238.913,97.732z" fill="#010101" />
        <Path d="M254.404,134.641c-0.207,0-0.375-0.168-0.375-0.375v-32.801c0-0.207,0.168-0.375,0.375-0.375 s0.375,0.168,0.375,0.375v32.801C254.779,134.473,254.611,134.641,254.404,134.641z" fill="#010101" />
        <Path d="M254.227,110.049c-0.075,0-0.151-0.022-0.218-0.069l-5.973-4.254c-0.169-0.12-0.208-0.354-0.088-0.523 c0.12-0.168,0.354-0.207,0.523-0.088l5.973,4.254c0.169,0.12,0.208,0.354,0.088,0.523 C254.459,109.994,254.344,110.049,254.227,110.049z" fill="#010101" />
        <Path d="M254.554,115.039c-0.117,0-0.232-0.055-0.306-0.157c-0.12-0.169-0.081-0.403,0.088-0.523l5.972-4.254 c0.169-0.12,0.402-0.08,0.523,0.088c0.12,0.169,0.081,0.403-0.088,0.523l-5.972,4.254 C254.705,115.017,254.629,115.039,254.554,115.039z" fill="#010101" />
        <Path d="M243.673,87.953c-0.013,0-0.025-0.001-0.039-0.003c-0.137-0.021-0.229-0.15-0.208-0.286 c0.91-5.762,2.82-11.551,4.263-15.392c0.049-0.129,0.19-0.197,0.322-0.146c0.129,0.048,0.194,0.192,0.146,0.322 c-1.435,3.819-3.334,9.574-4.237,15.294C243.901,87.865,243.794,87.953,243.673,87.953z" fill="#010101" />
        <Path d="M253.366,79.141L253.366,79.141c-0.781,0-1.415-0.633-1.415-1.415v-5.902h2.83v5.902 C254.78,78.507,254.147,79.141,253.366,79.141z" fill="#FFFFFF" />
        <Path d="M257.408,76.699L257.408,76.699c-0.51,0-0.924-0.414-0.924-0.924v-3.853h1.847v3.853 C258.332,76.285,257.918,76.699,257.408,76.699z" fill="#FFFFFF" />
      </G>
      <Circle cx="280.057" cy="93.313" fill="#FFFFFF" r="2.715" />
      <Circle cx="82.363" cy="76.124" fill="#FFFFFF" r="2.053" />
      <Circle cx="74.007" cy="88.056" fill="#FFFFFF" r="2.053" />
      <Circle cx="98.359" cy="44.746" fill="#FFFFFF" r="1.589" />
      <Circle cx="206.469" cy="32.032" fill="#FFFFFF" r="1.589" />
      <Circle cx="103.458" cy="33.39" fill="#FFFFFF" r="2.053" />
      <Circle cx="113.245" cy="41.572" fill="#FFFFFF" r="1.589" />
      <Circle cx="67.075" cy="115.494" fill="#FFFFFF" r="0.881" />
      <Circle cx="95.598" cy="85.235" fill="#FFFFFF" r="0.881" />
      <Circle cx="212.615" cy="37.641" fill="#FFFFFF" r="0.881" />
      <Circle cx="70.534" cy="73.594" fill="#FFFFFF" r="0.881" />
      <Circle cx="234.833" cy="80.432" fill="#FFFFFF" r="0.881" />
      <Circle cx="266.169" cy="57.273" fill="#FFFFFF" r="0.881" />
      <Circle cx="229.8" cy="37.641" fill="#FFFFFF" r="1.789" />
      <Circle cx="238.702" cy="49.776" fill="#FFFFFF" r="2.477" />
      <Circle cx="152.271" cy="14.383" fill="#FFFFFF" r="1.184" />
      <Circle cx="232.426" cy="118.468" fill="#FFFFFF" r="1.598" />
      <Circle cx="138.664" cy="19.061" fill="#FFFFFF" r="2.11" />
      <G>
        <Path d="M122,135.108c0,0-26.875-5.064-33.769-5.78c-6.894-0.716-13.545-1.583-22.651,1.164 c-11.846,3.573-18.712,4.33-18.712,4.33l-0.619,2.58h75.214L122,135.108z" fill="#FFFFFF" />
        <Path d="M120.221,134.597c0,0-26.195-0.907-31.048-2.267c-9.621-2.696-13.071-4.563-28.296-0.469 c0,0,14.156-4.091,22.244-3.095C91.209,129.761,116.102,133.9,120.221,134.597z" fill="#DCE2E7" />
        <Path d="M120.238,135.012c-0.025,0-0.051-0.002-0.077-0.008c-0.243-0.045-24.38-4.547-31.179-5.254l-0.897-0.094 c-6.665-0.7-12.962-1.362-21.591,1.243c-11.787,3.554-15.777,3.344-15.941,3.339c-0.228-0.014-0.401-0.211-0.387-0.439 c0.014-0.229,0.197-0.43,0.439-0.389c0.039,0.001,4.068,0.188,15.649-3.306c8.789-2.652,15.463-1.95,21.918-1.273l0.895,0.094 c6.832,0.71,31.002,5.218,31.246,5.265c0.225,0.041,0.374,0.258,0.332,0.484C120.608,134.873,120.433,135.012,120.238,135.012z" fill="#010101" />
      </G>
      <G>
        <Path d="M138.968,135.884c0,0,5.527-2.177,9.826-2.624c4.299-0.447,8.374-0.726,14.125,0.726 s9.601,1.911,9.601,1.911" fill="#FFFFFF" />
        <Path d="M136.102,136.814c0,0,9.936-0.981,13.001-1.679c3.065-0.698,7.162-2.297,17.792-0.124 c0,0-9.272-2.716-14.315-2.095S139.128,135.262,136.102,136.814z" fill="#DCE2E7" />
        <Path d="M135.789,137.064c-0.103,0-0.199-0.063-0.235-0.166c-0.046-0.13,0.021-0.273,0.151-0.319 c0.356-0.127,8.783-3.122,13.062-3.567l0.564-0.059c4.021-0.423,8.182-0.86,13.659,0.794c7.223,2.179,9.736,2.072,9.761,2.062 c0.143,0.006,0.256,0.098,0.264,0.234c0.009,0.138-0.096,0.257-0.233,0.265c-0.102,0.008-2.593,0.133-9.937-2.082 c-5.38-1.624-9.306-1.21-13.462-0.775l-0.564,0.06c-4.22,0.438-12.859,3.509-12.946,3.54 C135.846,137.059,135.817,137.064,135.789,137.064z" fill="#010101" />
      </G>
      <Circle cx="263.215" cy="95.343" fill="#FFFFFF" r="1.459" />
      <Circle cx="244.266" cy="98.907" fill="#FFFFFF" r="2.715" />
      <Circle cx="250.974" cy="86.308" fill="#FFFFFF" r="1.073" />
      <G>
        <Path d="M273.364,136.328c0,0-21.971-4.105-28.2-4.752c-6.229-0.647-12.238-1.43-20.465,1.052 c-10.703,3.229-17.465,3.407-17.465,3.407" fill="#FFFFFF" />
        <Path d="M273.35,136.328c0,0-23.667-0.82-28.052-2.048c-8.693-2.436-11.81-4.123-25.565-0.424 c0,0,12.79-3.696,20.097-2.797C247.137,131.958,269.628,135.698,273.35,136.328z" fill="#DCE2E7" />
        <Path d="M273.365,136.703c-0.022,0-0.046-0.002-0.069-0.007c-0.219-0.041-22.027-4.109-28.17-4.747l-0.81-0.085 c-6.024-0.632-11.712-1.229-19.508,1.123c-10.648,3.211-14.259,3.024-14.401,3.017c-0.207-0.013-0.363-0.19-0.351-0.396 c0.014-0.206,0.198-0.383,0.396-0.352c0.038,0.001,3.681,0.168,14.139-2.987c7.941-2.396,13.972-1.762,19.803-1.15l0.81,0.085 c6.172,0.641,28.01,4.715,28.23,4.757c0.203,0.037,0.337,0.233,0.299,0.438C273.699,136.577,273.542,136.703,273.365,136.703z" fill="#010101" />
      </G>
      <Circle cx="265.775" cy="81.556" fill="#FFFFFF" r="1.459" />
      <Circle cx="253.535" cy="72.521" fill="#FFFFFF" r="1.073" />
    </Svg>
  );
}
