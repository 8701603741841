/*
 * Reset Password Form Input
 */

import React, { Fragment, useRef } from 'react';
import T from 'prop-types';

import { PasswordTextField } from '../../base_ui';
import {
  RegisterInputContainer,
  StyledLargeProgressButton,
  StyledLoginLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const ResetPasswordFormInput = ({
  dispatchChangeInput,
  dispatchResetPassword,
  formErrors,
  formValues,
  handleNextClick,
  linkQueryString,
  status,
}) => {
  const confirmPasswordRef = useRef(null);

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <PasswordTextField
            error={formErrors.password}
            label="New Password"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'password',
              form: 'resetPassword',
              value: newValue,
            })}
            onSubmitEditing={() => confirmPasswordRef.current.focus()}
            returnKeyType="next"
            value={formValues.password}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <PasswordTextField
            ref={confirmPasswordRef}
            error={formErrors.confirmPassword}
            label="Confirm Password"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'confirmPassword',
              form: 'resetPassword',
              value: newValue,
            })}
            returnKeyType="done"
            value={formValues.confirmPassword}
          />
        </TextFieldWrapper>
      </RegisterInputContainer>
      <StyledPrimaryDivider />
      <StyledLargeProgressButton
        disabled={!formValues.password && !formValues.confirmPassword}
        label="Next Step"
        onPress={() => handleNextClick({
          field: ['password', 'confirmPassword'],
          form: 'resetPassword',
          formValues,
          handleSubmitStep: () => dispatchResetPassword({
            linkQueryString,
            newPassword: formValues.password,
          }),
        })}
        status={status.forgotPasswordVerify}
      />
      <StyledLoginLink
        label="Return to Login"
        path="Login"
        size="medium"
      />
    </Fragment>
  );
};

ResetPasswordFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchResetPassword: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleNextClick: T.func.isRequired,
  linkQueryString: T.string.isRequired,
  status: T.object.isRequired,
};

export default ResetPasswordFormInput;
