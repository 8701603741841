import styled from 'styled-components/native';

import { H3, P2 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const SectionHeader = styled(H3)`
  margin-bottom: ${moderateScale(8)}px;
  margin-top: ${moderateScale(24)}px;
`;

export const StyledLinkText = styled(P2)`
color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledView = styled.View`
  padding: ${moderateScale(12)}px ${moderateScale(24)}px 0;
  width: 100%;
`;
