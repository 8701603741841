/* eslint-disable max-len */
import React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function HeaderAccountDefaultIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(40)} viewBox="0 0 40 40" width={moderateScale(40)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="white" height="40" width="40" />
      <Path d="M27.1389 28.0781C29.217 26.2446 30.4899 23.6667 30.6822 20.9021C30.8745 18.1374 29.9708 15.4081 28.1665 13.3045C26.3623 11.2009 23.8024 9.89215 21.0407 9.66121C18.279 9.43028 15.5373 10.2958 13.4087 12.0705C11.2802 13.8452 9.93573 16.3865 9.66622 19.1447C9.39672 21.9029 10.2238 24.6564 11.9686 26.8096C13.7134 28.9628 16.2357 30.3426 18.9899 30.6506C21.7441 30.9586 24.5088 30.1701 26.6862 28.4556L27.1389 28.0781ZM10.843 20.1369C10.8411 18.6054 11.2163 17.0969 11.9354 15.7448C12.6545 14.3926 13.6954 13.2382 14.9663 12.3836C16.2371 11.529 17.6989 11.0004 19.2224 10.8444C20.746 10.6885 22.2845 10.91 23.7021 11.4894C25.1198 12.0689 26.373 12.9884 27.3511 14.1669C28.3292 15.3453 29.0022 16.7465 29.3106 18.2466C29.619 19.7467 29.5533 21.2997 29.1194 22.7685C28.6855 24.2372 27.8967 25.5766 26.8226 26.6683C26.273 25.3587 25.3486 24.2407 24.1656 23.4548C22.9826 22.6689 21.5937 22.2501 20.1735 22.2511C18.7532 22.2521 17.3649 22.6727 16.1829 23.4602C15.001 24.2478 14.0782 25.367 13.5303 26.6774C11.808 24.9363 10.8422 22.5859 10.843 20.1369V20.1369ZM14.531 27.5496C14.9293 26.3694 15.6878 25.3439 16.6997 24.6176C17.7116 23.8913 18.9259 23.5008 20.1715 23.5011C21.4171 23.5014 22.6312 23.8925 23.6427 24.6193C24.6543 25.3461 25.4123 26.372 25.81 27.5524C24.192 28.7938 22.2094 29.4665 20.17 29.466C18.1306 29.4655 16.1484 28.7918 14.531 27.5496V27.5496Z" fill="#282828" />
      <Path d="M20.1719 20.9995C21.0044 20.9995 21.8181 20.7527 22.5103 20.2902C23.2025 19.8277 23.7419 19.1704 24.0605 18.4013C24.3791 17.6322 24.4624 16.7859 24.3 15.9694C24.1376 15.1529 23.7367 14.403 23.1481 13.8143C22.5595 13.2257 21.8095 12.8248 20.993 12.6624C20.1766 12.5 19.3303 12.5834 18.5612 12.9019C17.7921 13.2205 17.1347 13.76 16.6722 14.4521C16.2097 15.1443 15.9629 15.9581 15.9629 16.7905C15.9642 17.9064 16.4081 18.9763 17.1971 19.7653C17.9862 20.5544 19.056 20.9982 20.1719 20.9995V20.9995ZM20.1719 13.8315C20.7571 13.8315 21.3292 14.0051 21.8158 14.3302C22.3024 14.6554 22.6817 15.1175 22.9057 15.6582C23.1296 16.1989 23.1882 16.7938 23.074 17.3678C22.9599 17.9418 22.678 18.469 22.2642 18.8829C21.8504 19.2967 21.3232 19.5785 20.7492 19.6927C20.1752 19.8069 19.5802 19.7483 19.0395 19.5243C18.4988 19.3003 18.0367 18.9211 17.7116 18.4345C17.3864 17.9479 17.2129 17.3758 17.2129 16.7905C17.2139 16.0061 17.526 15.254 18.0807 14.6993C18.6354 14.1446 19.3874 13.8326 20.1719 13.8316V13.8315Z" fill="#282828" />
    </Svg>
  );
}
