/*
* Zip Code Form Input
*/

import React, { Fragment } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import {
  ConditionalRender,
  LargePrimaryButton,
  LargeProgressButton,
  LoginRegisterTextField,
} from '../../base_ui';
import {
  ButtonsContainer,
  RegisterInputContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const ZipCodeFormInput = ({
  dispatchChangeInput,
  formErrors,
  formValues,
  handleBack,
  handleNextClick,
  handleSubmit,
  status,
}) => (
  <Fragment>
    <RegisterInputContainer>
      <TextFieldWrapper>
        <LoginRegisterTextField
          error={formErrors.zipCode}
          keyboardType="number-pad"
          label="Property Zip Code"
          onChangeText={(newValue) => dispatchChangeInput({
            field: 'zipCode',
            form: 'initial',
            value: newValue,
          })}
          onSubmitEditing={() => handleNextClick({
            field: 'zipCode',
            form: 'initial',
            formValues,
            handleSubmitStep: Platform.OS !== 'web' ? () => handleSubmit(1, formValues) : null,
            skipStep: Platform.OS !== 'web',
          })}
          returnKeyType="done"
          type="zipCode"
          value={formValues.zipCode}
        />
      </TextFieldWrapper>
    </RegisterInputContainer>
    <StyledPrimaryDivider />
    <ButtonsContainer>
      <ConditionalRender
        Component={LargeProgressButton}
        FallbackComponent={LargePrimaryButton}
        propsToPassDown={{
          disabled: !formValues.zipCode,
          label: 'Next Step',
          onPress: () => handleNextClick({
            field: 'zipCode',
            form: 'initial',
            formValues: trimFormValues(formValues),
            handleSubmitStep: Platform.OS !== 'web' ?
              () => handleSubmit(1, trimFormValues(formValues)) :
              null,
            skipStep: Platform.OS !== 'web',
          }),
          status: status.stepOne,
        }}
        shouldRender={Platform.OS !== 'web'}
      />
      <StyledBaseInternalLink label="Back" onPressFunc={handleBack} size="medium" />
    </ButtonsContainer>
  </Fragment>
);

ZipCodeFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  status: T.object.isRequired,
};

export default ZipCodeFormInput;
