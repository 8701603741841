import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { BorderlessButton, PrimaryDivider } from '../../../base_ui';

export const BannerContainer = styled.View`
  margin-bottom: ${moderateScale(16)}px;
`;

export const ContentContainer = styled.View`
  flex-grow: 1;
  justify-content: flex-start;
  padding: ${moderateScale(16)}px ${moderateScale(24)}px;
`;

export const DownloadButton = styled(BorderlessButton).attrs(({ titleStyle }) => ({
  titleStyle: [
    titleStyle,
    {
      paddingLeft: moderateScale(4),
      paddingVertical: 0,
    },
  ],
}))``;

export const HeaderContainer = styled.View`
  align-items: center;
  align-self: center;
  flex-direction: row;
  margin-bottom: ${moderateScale(32)}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  margin: ${moderateScale(24)}px 0 ${moderateScale(10)}px;
  max-width: 100%;
  width: 100%;
`;

export const TooltipButton = styled.Pressable`
  margin-left: ${moderateScale(8)}px;
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
`;
