import React, { useEffect } from 'react';
import { AccessibilityInfo, Platform } from 'react-native';
import T from 'prop-types';

import { NotificationsWrapper } from './styledComponents';
import { ConditionalRender } from '../base_ui';

const ScreenReaderNotifications = ({ notifications = [] }) => {
  // iOS Native workaround for `accessibilityLiveRegion` [see PR-98]
  useEffect(() => {
    if (Platform.OS === 'ios') {
      AccessibilityInfo.announceForAccessibility(notifications.join(' '));
    }
  }, [notifications]);

  // On web platforms, setting both accessibility props cause bugs in some browsers [see PR-98]
  const accessibilityProps = {
    ariaLive: 'assertive',
    role: Platform.OS !== 'web' ? 'alert' : null,
  };

  // Only render component on Android and web platforms
  return (
    <ConditionalRender
      Component={(
        <NotificationsWrapper {...accessibilityProps}>
          {notifications}
        </NotificationsWrapper>
      )}
      shouldRender={Platform.OS !== 'ios'}
    />
  );
};

ScreenReaderNotifications.propTypes = { notifications: T.array };

export default ScreenReaderNotifications;
