import React, { Fragment } from 'react';
import T from 'prop-types';
import * as Sharing from 'expo-sharing';

import Bugsnag from '../../../bugsnag';
import { BottomDrawer } from '../base_ui';
import { TertiaryHeader } from '../ScreenHeaders';
import WebView from '../WebView';

const PDFDisplay = ({
  dispatchSetFile,
  dispatchToggleDrawer,
  file: { fileName, uri },
  isDrawerOpen,
}) => {
  const handleDownload = async () => {
    try {
      await Sharing.shareAsync(uri, { mimeType: 'application/pdf' });
    } catch (error) {
      /**
       * Ignore the error where someone clicks the download button while the native
       * Sharing tooltip/modal is open or twice in quick succession before the tooltip/
       * modal has had time to open.
       */
      if (!error.message.startsWith('Another item is being shared')) {
        Bugsnag.notify(error);
      }
    }
  };
  return (

    <BottomDrawer
      handleClose={() => dispatchToggleDrawer(false)}
      isVisible={isDrawerOpen}
      onModalHide={dispatchSetFile}
      style={{ margin: 0 }}
    >
      <Fragment>
        <TertiaryHeader
          handleBack={() => dispatchToggleDrawer(false)}
          handleDownload={handleDownload}
          title={fileName}
        />
        <WebView uri={uri} />
      </Fragment>
    </BottomDrawer>
  );
};

PDFDisplay.propTypes = {
  dispatchSetFile: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  file: T.object.isRequired,
  isDrawerOpen: T.bool.isRequired,
};

export default PDFDisplay;
