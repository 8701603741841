import React, { forwardRef } from 'react';
import T from 'prop-types';

import BaseInput from '../BaseInput';

const MultilineTextField = forwardRef(({
  onChangeText,
  onSubmitEditing,
  value,
  ...restProps
}, ref) => (
  <BaseInput
    ref={ref}
    multiline
    onChangeText={onChangeText}
    onSubmitEditing={onSubmitEditing}
    value={value}
    {...restProps}
  />
));

MultilineTextField.propTypes = {
  onChangeText: T.func.isRequired,
  onSubmitEditing: T.func,
  value: T.string.isRequired,
};

export default MultilineTextField;
