/*
 * Edit Military Status Screen
 */

import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { PROFILE_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Profile/constants';
import makeSelectProfile from '@dmi/shared/redux/Settings/Profile/selectors';

import { EditMilitaryStatusComponent } from '../../../components/Settings/EditMilitaryStatus';
import { StyledSecondaryHeader, StyledView } from './styledComponents';

const EditMilitaryStatusComponentScreen = ({
  militaryServiceStatus,
  navigation,
}) => {
  const { header } = PROFILE_CONTENT_DICTIONARY.editMilitaryStatus.editMilitaryStatusView;

  return (
    <Fragment>
      <StyledSecondaryHeader
        handleBack={navigation.goBack}
        title={header}
      />
      <StyledView>
        <EditMilitaryStatusComponent
          militaryServiceStatus={militaryServiceStatus}
          navigation={navigation}
        />
      </StyledView>
    </Fragment>
  );
};

EditMilitaryStatusComponentScreen.propTypes = {
  militaryServiceStatus: T.string.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Profile
   */
  militaryServiceStatus: makeSelectProfile('militaryServiceStatus'),
});

const withConnect = connect(mapStateToProps);

export default withConnect(EditMilitaryStatusComponentScreen);
