/**
 * Checks to see if the virtual keyboard is overlapping the bottom of a form that
 * is in a ScrollView. If so, then it scrolls up just enough so that the bottom of the form
 * rests just above the top of the keyboard.
 */
export const scrollFormIntoView = async ({
  formRef,
  keyboardTopPageYOffset,
  scrollViewRef,
}) => {
  try {
    // Start async measurements
    const gettingFormVsScrollViewMeasurements = new Promise((resolve) => {
      formRef.current.measureLayout(
        scrollViewRef.current,
        (...diffMeasurements) => resolve(diffMeasurements),
      );
    });
    const gettingScrollViewMeasurements = new Promise((resolve) => {
      scrollViewRef.current.measure(
        (...scrollViewMeasurements) => resolve(scrollViewMeasurements),
      );
    });
    const gettingFormMeasurements = new Promise((resolve) => {
      formRef.current.measure(
        (...formMeasurements) => resolve(formMeasurements),
      );
    });

    // Wait for measuring to finish
    const [
      formVsScrollViewMeasurements,
      scrollViewMeasurements,
      formMeasurements,
    ] = await Promise.all([
      gettingFormVsScrollViewMeasurements,
      gettingScrollViewMeasurements,
      gettingFormMeasurements,
    ]);

    // Perform scroll if necessary
    /**
     * Note on y-offset variable naming convention/meanings:
     *  - <element1><element2>YOffset
     *    - meaning: the y-offset of element1 from element2, ie, the distance from the top of
     *      element1 to the top of element2.
     *  - "FullScrollView" = not just what is seen on the screen, but the full size of the content
     * of the ScrollView.
     *  - vs "VisibleScrollView" = just what is seen on the screen
     *  - "page" = the entire visible screen
     */
    const formHeight = formMeasurements[3];
    const formTopPageYOffset = formMeasurements[5];
    const yThreshold = formHeight + formTopPageYOffset;
    const keyboardFormOverlap = yThreshold - keyboardTopPageYOffset;
    const formTopFullScrollViewYOffset = formVsScrollViewMeasurements[1];
    const visibleScrollViewTopPageYOffset = scrollViewMeasurements[5];
    const formVisibleScrollViewYOffset = formTopPageYOffset - visibleScrollViewTopPageYOffset;
    const currentScrollPosition = formTopFullScrollViewYOffset - formVisibleScrollViewYOffset;
    if (keyboardFormOverlap > 0) {
      const newScrollPosition = currentScrollPosition + keyboardFormOverlap;
      scrollViewRef.current.scrollTo({ animated: true, x: 0, y: newScrollPosition });
    }
  } catch {
    /**
     *  If reached here, likely it's because the async functions took too long and at some point
     * a 'ref.current' became undefined/null, so calling '.measure' threw an error. That's fine.
     * We just don't end up performing the .scrollTo() action, which is probably for the best since
     * it likely would no longer be relevant anyway.
     */
  }
};
