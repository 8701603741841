import React from 'react';

import { PAYOFF_DICTIONARY } from '@dmi/shared/redux/Payoff/constants';

import { Animation } from '../base_ui';
import {
  RetrievingAnimationContainer,
  RetrievingStatementContainer,
  StyledRetrievingHeader,
  StyledRetrievingText,
} from './styledComponents';

const { retrieving: { default: { text, title } } } = PAYOFF_DICTIONARY;

const RetrievingStatement = () => (
  <RetrievingStatementContainer>
    <RetrievingAnimationContainer
      accessible
      aria-label="Loading indicator"
      role="img"
    >
      <Animation lottieProps={{ loop: true }} name="dogMobile" />
    </RetrievingAnimationContainer>
    <StyledRetrievingHeader>{title}</StyledRetrievingHeader>
    <StyledRetrievingText>{text}</StyledRetrievingText>
  </RetrievingStatementContainer>
);

export default RetrievingStatement;
