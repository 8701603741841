/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath,
  Defs,
  G,
  Path,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

const PaymentWarning = (props) => {
  const { colors: { primary } } = useTheme();
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';

  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M23.2602 12C23.2602 18.2187 18.219 23.26 12.0002 23.26C5.78151 23.26 0.740234 18.2187 0.740234 12C0.740234 5.78126 5.78151 0.73999 12.0002 0.73999C18.219 0.73999 23.2602 5.78126 23.2602 12Z" stroke={primary} />
        <Path d="M11.9996 13.4599C11.7609 13.4599 11.532 13.3651 11.3632 13.1963C11.1944 13.0275 11.0996 12.7986 11.0996 12.5599V5.80991C11.0996 5.57122 11.1944 5.3423 11.3632 5.17352C11.532 5.00473 11.7609 4.90991 11.9996 4.90991C12.2383 4.90991 12.4672 5.00473 12.636 5.17352C12.8048 5.3423 12.8996 5.57122 12.8996 5.80991V12.5599C12.8996 12.6781 12.8763 12.7951 12.8311 12.9043C12.7859 13.0135 12.7196 13.1127 12.636 13.1963C12.5524 13.2799 12.4532 13.3462 12.344 13.3914C12.2348 13.4366 12.1178 13.4599 11.9996 13.4599Z" fill={primary} />
        <Path d="M11.9997 18.6999C12.8447 18.6999 13.5297 18.0149 13.5297 17.1699C13.5297 16.3249 12.8447 15.6399 11.9997 15.6399C11.1547 15.6399 10.4697 16.3249 10.4697 17.1699C10.4697 18.0149 11.1547 18.6999 11.9997 18.6999Z" fill={primary} />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="24" width="24" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};

export default PaymentWarning;
