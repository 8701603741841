import React, { useRef } from 'react';
import T from 'prop-types';
import { Dimensions } from 'react-native';

import { formatPhoneNumber } from '@dmi/shared/utils/globalHelpers';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  ConditionalRender,
  H4,
  KeyboardAvoidingScrollView,
  MediumProgressButton,
  Skeleton,
} from '../../base_ui';
import {
  AlertIcon,
  AlertWrapper,
  ButtonWrapper,
  EditContainer,
  IllustrationWrapper,
  StyledBaseTextField,
  StyledP2,
  StyledP3,
  StyledStatusBanner,
  TextFieldGroup,
} from './styledComponents';

const DetailedMegaphoneIcon = iconDictionary('megaphoneS');
const DogSmartphoneIllustration = iconDictionary(
  'dogSmartphone', { height: `${moderateScale(210)}px` },
);

const EditMobileNumberComponent = ({
  bannerError,
  dispatchChangeInput,
  dispatchResetFormErrorsState,
  fetchProfileStatus,
  form,
  formErrors,
  handleEditMobileNumber,
  isSubmitDisabled,
  mobileNumber,
  status,
}) => {
  const confirmMobileNumberRef = useRef();
  const windowHeight = Dimensions.get('window').height;

  const handleChangeText = ({ field, value }) => {
    const formName = 'mobileNumber';
    if (formErrors[field]) {
      dispatchResetFormErrorsState({ field, form: formName });
    }
    if (field === 'newMobileNumber' && formErrors.confirmMobileNumber) {
      dispatchResetFormErrorsState({ field: 'confirmMobileNumber', form: formName });
    }
    dispatchChangeInput({ field, form: formName, value });
  };

  return (
    <KeyboardAvoidingScrollView
      bannerError={bannerError}
      scrollViewProps={{ showsVerticalScrollIndicator: false }}
    >
      <EditContainer>
        <StyledStatusBanner error={bannerError} />
        <H4>Mobile Number on File</H4>
        <ConditionalRender
          Component={<StyledP2>{formatPhoneNumber(mobileNumber) || 'None Provided'}</StyledP2>}
          FallbackComponent={(
            <Skeleton
              $bottomMargin={`${moderateScale(38)}px`}
              $topMargin={`${moderateScale(8)}px`}
              height={`${moderateScale(22)}px`}
              width={`${moderateScale(150)}px`}
            />
          )}
          shouldRender={fetchProfileStatus !== 'loading'}
        />
        <H4>Edit Mobile Number</H4>
        <TextFieldGroup $isFlexGrow={windowHeight <= 800}>
          <StyledBaseTextField
            errorMessage={formErrors.newMobileNumber}
            keyboardType="phone-pad"
            label="Mobile Number"
            onChangeText={(value) => handleChangeText({
              field: 'newMobileNumber',
              value,
            })}
            onSubmitEditing={() => confirmMobileNumberRef.current.focus()}
            placeholder="Required"
            /**
           * returnKeyType of 'next' doesn't work well in phone-pad case, and including a 'Done'
           * button for iOS makes the next phone-pad keyboard load poorly
           */
            value={form.newMobileNumber}
          />
          <StyledBaseTextField
            ref={confirmMobileNumberRef}
            errorMessage={formErrors.confirmMobileNumber}
            keyboardType="phone-pad"
            label="Confirm Mobile Number"
            onChangeText={(value) => handleChangeText({
              field: 'confirmMobileNumber',
              value,
            })}
            onSubmitEditing={isSubmitDisabled ? undefined : handleEditMobileNumber}
            placeholder="Required"
            returnKeyType="done"
            value={form.confirmMobileNumber}
          />
        </TextFieldGroup>
        <AlertWrapper>
          <AlertIcon>{DetailedMegaphoneIcon}</AlertIcon>
          <StyledP3>
            You will need your mobile device for the next step. After submitting your new number,
            a 6-digit verification PIN will be sent to that number. You will have 30 minutes
            to enter the PIN. Message and data rates may apply.
          </StyledP3>
        </AlertWrapper>
        <ConditionalRender
          Component={(
            <IllustrationWrapper>
              {DogSmartphoneIllustration}
            </IllustrationWrapper>
          )}
          shouldRender={windowHeight > 800}
        />
        <ButtonWrapper>
          <MediumProgressButton
            disabled={isSubmitDisabled}
            label="Next"
            onPress={handleEditMobileNumber}
            status={status}
          />
        </ButtonWrapper>
      </EditContainer>
    </KeyboardAvoidingScrollView>
  );
};

EditMobileNumberComponent.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetFormErrorsState: T.func.isRequired,
  fetchProfileStatus: T.string.isRequired,
  form: T.shape({
    confirmMobileNumber: T.string.isRequired,
    newMobileNumber: T.string.isRequired,
  }).isRequired,
  formErrors: T.shape({
    confirmMobileNumber: T.string.isRequired,
    newMobileNumber: T.string.isRequired,
  }).isRequired,
  handleEditMobileNumber: T.func.isRequired,
  isSubmitDisabled: T.bool.isRequired,
  mobileNumber: T.string.isRequired,
  status: T.string.isRequired,
};

export default EditMobileNumberComponent;
