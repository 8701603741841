import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import iconDictionary from '../../../../../utils/iconDictionary';
import { BaseToggle, ConditionalRender } from '../../../../base_ui';
import {
  Container,
  Label,
  StyledP2,
  ToggleContainer,
} from './styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');

const RegularPaymentRow = ({
  canMakeAdditionalPayment,
  canMakeMonthlyPayment,
  handleOpen,
  handleTogglePress,
  monthlyToggle,
  regularMonthlyPayment,
}) => (
  <Container>
    <ToggleContainer>
      <ConditionalRender
        Component={(
          <BaseToggle
            ariaLabel="regular monthly payment"
            disabled={!canMakeMonthlyPayment}
            isActive={monthlyToggle}
            onPress={handleTogglePress}
          />
        )}
        shouldRender={canMakeAdditionalPayment}
      />
      <Label
        $disabled={!monthlyToggle}
        $visible={canMakeAdditionalPayment}
      >
        Regular Monthly Payment
      </Label>
      <Pressable
        onPress={() => handleOpen('regularMonthlyPayment')}
        style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
      >
        {InfoIcon}
      </Pressable>
    </ToggleContainer>
    <StyledP2 $disabled={!monthlyToggle}>{regularMonthlyPayment}</StyledP2>
  </Container>
);

RegularPaymentRow.propTypes = {
  canMakeAdditionalPayment: T.bool.isRequired,
  canMakeMonthlyPayment: T.bool.isRequired,
  handleOpen: T.func.isRequired,
  handleTogglePress: T.func.isRequired,
  monthlyToggle: T.bool.isRequired,
  regularMonthlyPayment: T.string.isRequired,
};

export default RegularPaymentRow;
