/**
 * StatusBannerV2 constants
 */
import iconDictionary from '../../../../utils/iconDictionary';

const SuccessIcon = iconDictionary('outlinedCompleteGreen');
const PendingIcon = iconDictionary('outlinedPendingPurpleL');
const ErrorIcon = iconDictionary('paymentWarningL');

export const predefinedSchemes = {
  error: {
    color: 'accentRed',
    IconToRender: ErrorIcon,
  },
  pending: {
    color: 'primary',
    IconToRender: PendingIcon,
  },
  success: {
    color: 'accentGreen',
    IconToRender: SuccessIcon,
  },
};
