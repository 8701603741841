import React from 'react';
import T from 'prop-types';
import { Dimensions, StyleSheet } from 'react-native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { BaseDropDown } from '../../../../base_ui';
import { AbsoluteContainer } from './styledComponents';

const GhostDropDown = ({ style, ...restProps }) => (
  <AbsoluteContainer>
    <BaseDropDown style={pickerSelectStyles(style)} {...restProps} />
  </AbsoluteContainer>
);

const pickerSelectStyles = (style) => {
  const deviceWidth = Dimensions.get('window').width;
  const inputStyles = { height: moderateScale(74), opacity: 0, width: deviceWidth };

  return StyleSheet.create({
    inputAndroid: inputStyles,
    inputIOS: inputStyles,
    inputWeb: inputStyles,
    ...style,
  });
};

GhostDropDown.propTypes = { style: T.object, theme: T.object };

export default GhostDropDown;
