import ProfileTransferConfirmation from './ProfileTransferConfirmation';
import ProfileTransferForm from './ProfileTransferForm';
import ProfileTransferRedirect from './ProfileTransferRedirect';
import ProfileTranserError from './ProfileTransferError';

export default {
  1: { Component: ProfileTransferForm },
  2: { Component: ProfileTransferConfirmation },
  3: { Component: ProfileTransferRedirect },
  4: { Component: ProfileTranserError },
};
