import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { parseDocUploadAttachments } from '@dmi/shared/utils/globalHelpers';
import { validate } from '@dmi/shared/utils/validate';

import iconDictionary from '../../utils/iconDictionary';
import { XSmallProgressButton } from '../base_ui';
import FileRowButton from './FileRowButton';
import {
  ReplyAttachmentContainer,
  ReplyBoxContainer,
  ReplyButtonContainer,
  ReplyTextInput,
  ReplyTitle,
  ReplyView,
} from './styledComponents';

const ChatBubble = iconDictionary('chatBubble');

const MessagesReplyComponent = ({
  dispatchChangeFormFieldValue,
  dispatchPostReply,
  formAttachments,
  formReply: { body },
  formUploadError,
  handlePressFileRowButton,
  onBlur,
  requestStatusPostReply,
  selectedMessageId,
  setIsKeyboardVisible,
}) => {
  const textareaRef = useRef();

  useEffect(() => {
    // Fixes Android Native not prompting keyboard on initial mount/focus.
    // eslint-disable-next-line no-unused-expressions
    Platform.OS === 'ios'
      ? textareaRef.current.focus()
      : setTimeout(() => textareaRef.current.focus(), 25);
  }, [textareaRef]);

  const isBodyInvalid = validate({
    maxLength: 3000,
    type: 'message',
    value: body,
  });

  const hasAttachmentError = formAttachments.some(({ bannerError, removed }) =>
    !!bannerError && !removed) || !!formUploadError;
  const isSendDisabled = hasAttachmentError || isBodyInvalid;

  return (
    <ReplyBoxContainer>
      <ReplyView>
        {ChatBubble}
        <ReplyTitle>Reply</ReplyTitle>
      </ReplyView>
      <ReplyTextInput
        ref={textareaRef}
        inputContainerStyle={{
          borderBottomWidth: 0,
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
        }}
        maxLength={3000}
        multiline
        onBlur={onBlur}
        onChangeText={
          (value) =>
            dispatchChangeFormFieldValue({ field: 'body', form: 'reply', value })
        }
        onFocus={() => setIsKeyboardVisible(true)}
        placeholder="Type your message here."
        value={body}
      />
      <ReplyButtonContainer>
        <ReplyAttachmentContainer>
          <FileRowButton
            handlePress={handlePressFileRowButton}
            isReply
            label="Add Attachment"
            numberOfAttachments={formAttachments.length}
          />
        </ReplyAttachmentContainer>
        <XSmallProgressButton
          disabled={isSendDisabled}
          label="Send"
          onPress={() => dispatchPostReply({
            attachments: parseDocUploadAttachments(formAttachments),
            body,
            messageId: selectedMessageId,
          })}
          status={requestStatusPostReply}
        />
      </ReplyButtonContainer>
    </ReplyBoxContainer>
  );
};

MessagesReplyComponent.propTypes = {
  dispatchChangeFormFieldValue: T.func.isRequired,
  dispatchPostReply: T.func.isRequired,
  formAttachments: T.array.isRequired,
  formReply: T.shape({ body: T.string.isRequired }).isRequired,
  formUploadError: T.string.isRequired,
  handlePressFileRowButton: T.func.isRequired,
  onBlur: T.func.isRequired,
  requestStatusPostReply: T.string.isRequired,
  selectedMessageId: T.string.isRequired,
  setIsKeyboardVisible: T.func.isRequired,
};

export default MessagesReplyComponent;
