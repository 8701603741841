import React, { useEffect } from 'react';
import T from 'prop-types';

import { VIDEO_CARD_LOOKUP } from '@dmi/shared/redux/Help/constants';

import { ConditionalRender } from '../../../base_ui';
import Card from './Card';
import VideoModal from './VideoModal';
import { Container, Heading } from './styledComponents';

const VideoLibrary = ({
  dispatchSetVideoModalData,
  dispatchSetVideoRemainingDuration,
  videoModalData,
  videoRemainingDuration,
}) => {
  useEffect(() => () => {
    dispatchSetVideoModalData(null);
  }, [dispatchSetVideoModalData]);

  return (
    <Container>
      <Heading>Video Library</Heading>
      {VIDEO_CARD_LOOKUP.map(({ heading, text }) => (
        <Card
          key={heading}
          dispatchSetVideoModalData={dispatchSetVideoModalData}
          heading={heading}
          text={text}
        />
      ))}
      <ConditionalRender
        Component={VideoModal}
        propsToPassDown={{
          data: videoModalData,
          dispatchSetVideoRemainingDuration,
          handleCloseModal: () => dispatchSetVideoModalData(null),
          videoRemainingDuration,
        }}
        shouldRender={videoModalData !== null}
      />
    </Container>
  );
};

VideoLibrary.propTypes = {
  dispatchSetVideoModalData: T.func.isRequired,
  dispatchSetVideoRemainingDuration: T.func.isRequired,
  videoModalData: T.object,
  videoRemainingDuration: T.number.isRequired,
};

export default VideoLibrary;
