import React from 'react';
import T from 'prop-types';

import {
  PROCEED_DICTIONARY,
  PROCEED_DICTIONARY_AUTOPAY_SETUP,
} from '@dmi/shared/redux/Calculators/Amortize/constants';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import { HeaderText, ProceedWrapper } from './styledComponents';
import { ContentContainer } from '../styledComponents';
import { FooterSummary } from '../Shared/FooterSummary';

const MagnifyingGlass = iconDictionary('magnifyingGlass');

const ProceedView = ({
  formattedFormValues,
  handleClickButton,
  isAutopaySetUp,
  navigation,
  selectedAdjustmentFilter,
  summaryBarProps: { summaryLabel, summarySubText, summaryText },
}) => {
  const { header } = isAutopaySetUp && selectedAdjustmentFilter === 'payMoreMonthly' ?
    PROCEED_DICTIONARY_AUTOPAY_SETUP[selectedAdjustmentFilter] :
    PROCEED_DICTIONARY[selectedAdjustmentFilter];

  return (
    <ProceedWrapper>
      <SecondaryHeader
        handleBack={navigation.goBack}
        title="Proceed to Payment"
      />
      <ContentContainer>
        {MagnifyingGlass}
        <HeaderText>{interpolate(header, { ...formattedFormValues })}</HeaderText>
      </ContentContainer>
      <FooterSummary
        label={summaryLabel}
        onPress={handleClickButton}
        subText={summarySubText}
        text={summaryText}
      />
    </ProceedWrapper>
  );
};

ProceedView.propTypes = {
  formattedFormValues: T.object.isRequired,
  handleClickButton: T.func.isRequired,
  isAutopaySetUp: T.bool.isRequired,
  navigation: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
  summaryBarProps: T.shape({
    summaryLabel: T.string.isRequired,
    summarySubText: T.string.isRequired,
    summaryText: T.string.isRequired,
  }).isRequired,
};

export default ProceedView;
