import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { H1, P1 } from '../../base_ui';

export const ButtonContainer = styled.View`
  flex: 1;
  justify-content: flex-end;
  margin: ${moderateScale(26)}px 0 ${moderateScale(14)}px;
`;

export const FlexContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  margin-top: ${moderateScale(50)}px;
  text-align: center;
`;

export const Heading = styled(H1)`
  margin: ${moderateScale(12)}px 0 ${moderateScale(28)}px;
  text-align: center;
`;

export const IllustrationWrapper = styled.View`
  align-self: center;
`;

export const Text = styled(P1)`
  margin-bottom: ${moderateScale(16)}px;
`;
