/* eslint-disable react/no-array-index-key */
/**
* LoadingIndicator
* @description: Circular, rotating loading indicator
* Use Case: For when the client is waiting for async data to finish loading
*/

import React from 'react';
import T from 'prop-types';

import { LoadingSection, Wrapper } from './styledComponents';
import Circle from './Circle';
import useAnimatedOpacities from './useAnimatedOpacities';

const LoadingIndicator = ({ isCentered = false }) => {
  const animatedOpacities = useAnimatedOpacities();

  return (
    <Wrapper $isCentered={isCentered}>
      <LoadingSection>
        {animatedOpacities.map((opacity, index) => (
          <Circle key={index} opacity={opacity} rotate={index * 30} />
        ))}
      </LoadingSection>
    </Wrapper>
  );
};

LoadingIndicator.propTypes = { isCentered: T.bool };

export default LoadingIndicator;
