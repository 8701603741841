import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeInputError,
  forgotPasswordInitiate,
  forgotPasswordVerify,
  resetInputErrors,
  resetState,
} from '@dmi/shared/redux/Register/ForgotPassword/actions';
import reducer from '@dmi/shared/redux/Register/ForgotPassword/reducer';
import saga from '@dmi/shared/redux/Register/ForgotPassword/saga';
import makeSelectForgotPassword, {
  getForgotPasswordConfirmationPageProps,
  getForgotPasswordError,
  getForgotPasswordForm,
  getForgotPasswordFormErrors,
  getForgotPasswordPageView,
  getForgotPasswordQueryString,
  getForgotPasswordStep,
} from '@dmi/shared/redux/Register/ForgotPassword/selectors';
import { handleNext } from '@dmi/shared/redux/Register/Shared/helpers';

import injectReducer from '../../../utils/injectReducer';
import injectSaga from '../../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../../utils/globalHelpers';
import { KeyboardAvoidingScrollView } from '../../../components/base_ui';
import viewDictionary from './viewDictionary';
import NotFoundScreen from '../../NotFoundScreen';

const ForgotPassword = ({
  bannerError,
  confirmationPageProps,
  dispatchChangeInput,
  dispatchChangeInputError,
  dispatchForgotPassword,
  dispatchResetInputErrors,
  dispatchResetPassword,
  dispatchResetState,
  formErrors,
  formValues,
  linkQueryString,
  navigation,
  status,
  step,
  view,
}) => {
  const scrollViewRef = useRef();
  const {
    viewCardProps,
    ViewComponent,
    ViewFormInput,
  } = view ? viewDictionary[view][step] : { ViewComponent: NotFoundScreen };

  useEffect(() => dispatchResetState, [dispatchResetState]);

  const handleNextClick = ({
    field,
    form,
    formValues: values,
    handleSubmitStep,
    skipStep,
  }) => {
    handleNext({
      dispatchError: dispatchChangeInputError,
      dispatchNext: () => null,
      dispatchResetInputErrors,
      field,
      form,
      formValues: values,
      handleSubmitStep,
      skipStep,
      step,
    });
  };

  const handleScrollToBanner = () => {
    scrollViewRef.current.scrollTo({ animated: true, x: 0, y: 0 });
  };

  const formInputProps = {
    dispatchChangeInput,
    dispatchForgotPassword,
    dispatchResetPassword,
    formErrors,
    formValues,
    handleNextClick,
    linkQueryString,
    navigation,
    status,
    step,
  };
  return (
    <KeyboardAvoidingScrollView scrollViewRef={scrollViewRef}>
      <ViewComponent
        bannerError={bannerError}
        formInputProps={formInputProps}
        handlePress={() => navigation.replace('Login')}
        handleScrollToBanner={handleScrollToBanner}
        navigation={navigation}
        viewCardProps={viewCardProps}
        ViewFormInput={ViewFormInput}
        {...confirmationPageProps}
      />
    </KeyboardAvoidingScrollView>
  );
};

ForgotPassword.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  confirmationPageProps: T.object.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeInputError: T.func.isRequired,
  dispatchForgotPassword: T.func.isRequired,
  dispatchResetInputErrors: T.func.isRequired,
  dispatchResetPassword: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  formErrors: T.object,
  formValues: T.object,
  linkQueryString: T.string.isRequired,
  navigation: T.object.isRequired,
  route: T.object.isRequired,
  status: T.object.isRequired,
  step: T.number.isRequired,
  view: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: ForgotPassword
   */
  bannerError: getForgotPasswordError('bannerError'),
  confirmationPageProps: getForgotPasswordConfirmationPageProps(),
  formErrors: getForgotPasswordFormErrors(),
  formValues: getForgotPasswordForm(),
  linkQueryString: getForgotPasswordQueryString(),
  status: makeSelectForgotPassword('status'),
  step: getForgotPasswordStep(),
  view: getForgotPasswordPageView(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: ForgotPassword
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeInputError: (payload) => dispatch(changeInputError(payload)),
  dispatchForgotPassword: (payload) => dispatch(forgotPasswordInitiate(payload)),
  dispatchResetInputErrors: (payload) => dispatch(resetInputErrors(payload)),
  dispatchResetPassword: (payload) => dispatch(forgotPasswordVerify(payload)),
  dispatchResetState: () => dispatch(resetState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'forgotPassword', reducer });
const withSaga = injectSaga({
  key: 'forgotPassword',
  saga: saga({ baseUrl: getBaseApplicationServerUrl() }),
});

export default compose(
  withConnect,
  withReducer,
  withSaga,
)(ForgotPassword);
