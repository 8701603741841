import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { IconButton, P2, TertiaryButton } from '../../base_ui';

export const HeaderContainer = styled.View`
  align-items: center;
  border-bottom-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  border-bottom-width: ${moderateScale(1)}px;
  padding: 0 0 ${moderateScale(20)}px;
  width: 100%;
`;

export const StyledAddress = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  padding: ${moderateScale(40)}px ${moderateScale(20)}px ${moderateScale(20)}px;
  text-align: center;
`;

export const StyledButton = styled(TertiaryButton).attrs(({
  theme: { fontSizes: { f4 } },
  titleStyle,
}) => ({ titleStyle: [titleStyle, { fontSize: f4 }] }))`
  align-self: center;
  border-color: ${({ theme: { colors: { primary } } }) => primary};
  height: ${moderateScale(36)}px;
  margin-bottom: ${moderateScale(5)}px;
  padding: 0 ${moderateScale(32)}px;
`;

export const StyledIconButton = styled(IconButton)`
  align-self: center;
  height: ${moderateScale(100)}px;
  width: ${moderateScale(200)}px;
`;

export const StyledIconContainer = styled.View`
  background:
    ${({ theme: { colors: { headerBackground } } }) => headerBackground};
  padding: ${moderateScale(24)}px ${moderateScale(40)}px ${moderateScale(24)}px;
  width: 100%;
`;
