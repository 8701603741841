/* eslint-disable max-len, no-tabs */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function PaymentHouseFall(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      fill="none"
      height={moderateScale(70)}
      viewBox="0 0 100 70"
      width={moderateScale(100)}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <G>
        <G>
          <Path
            className="st0"
            d="M7.8,39.7c0,0,0.2,1.4-1,2.4c-1.2,1-2.7,0.5-2.7,0.5L4,42.5c0,0-0.1-1.6,1.1-2.5s2.6-0.4,2.6-0.4L7.8,39.7z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M4.1,42.6c0,0,1.5,0.4,2.7-0.5c1.2-1,1-2.4,1-2.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M4,42.4c0,0-0.1-1.6,1.1-2.5s2.6-0.4,2.6-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M65.2,22.7c0,0,0.9-0.3,1.7,0.5c0.7,0.8,0.5,1.8,0.5,1.8l-0.1,0.1c0,0-1,0.2-1.8-0.6
			C64.8,23.7,65.1,22.8,65.2,22.7L65.2,22.7z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M67.3,25c0,0,0.2-1-0.5-1.8c-0.7-0.8-1.7-0.5-1.7-0.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M67.2,25.1c0,0-1,0.2-1.8-0.6c-0.7-0.8-0.4-1.7-0.4-1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M78.7,60.8c0,0,1,0.6,2-0.1c1-0.6,1.1-1.8,1.1-1.8l-0.1-0.1c0,0-1.1-0.5-2.1,0.2c-1,0.6-1,1.7-1,1.7
			L78.7,60.8z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M81.8,58.8c0,0-0.1,1.2-1.1,1.8c-1,0.6-2,0.1-2,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M81.7,58.7c0,0-1.1-0.5-2.1,0.2c-1,0.6-1,1.7-1,1.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M85.8,60.6c0,0-0.5-1-1.7-1s-1.9,0.9-1.9,0.9l0,0.2c0,0,0.7,1,1.9,1s1.8-0.9,1.8-0.9L85.8,60.6z" fill="#E6EBEF" />
          <Path className="st1" d="M82.2,60.5c0,0,0.7-1,1.9-0.9c1.2,0,1.7,1,1.7,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M82.2,60.7c0,0,0.7,1,1.9,1s1.8-0.9,1.8-0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M74.8,57.7c0,0-0.6,1.4,0.4,2.6c1,1.3,2.6,1.2,2.6,1.2l0.2-0.1c0,0,0.5-1.5-0.5-2.8c-1-1.3-2.4-1-2.4-1
			L74.8,57.7z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M77.8,61.5c0,0-1.6,0.1-2.6-1.2c-1-1.3-0.4-2.6-0.4-2.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M78,61.4c0,0,0.5-1.5-0.5-2.8c-1-1.3-2.4-1-2.4-1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M66.3,61.9c0,0,0.6,0.9,1.8,0.8c1.2-0.1,1.8-1.1,1.8-1.1l0-0.2c0,0-0.8-0.9-2-0.8s-1.6,1.1-1.6,1.1L66.3,61.9
			z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M69.9,61.6c0,0-0.6,1-1.8,1.1c-1.2,0.1-1.8-0.8-1.8-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M69.9,61.5c0,0-0.8-0.9-2-0.8c-1.2,0.1-1.6,1.1-1.6,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M86.7,62.9c0,0,0.8,0.7,2,0.3c1.1-0.4,1.5-1.6,1.5-1.6L90,61.6c0,0-1-0.7-2.1-0.3c-1.1,0.4-1.3,1.5-1.3,1.5
			L86.7,62.9z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M90,61.7c0,0-0.3,1.2-1.5,1.6c-1.1,0.4-2-0.3-2-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M90,61.5c0,0-1-0.7-2.1-0.3c-1.1,0.4-1.3,1.5-1.3,1.5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M57.1,61.7c0,0,1,0.4,2-0.4s0.8-2,0.8-2l-0.1-0.1c0,0-1.2-0.3-2.1,0.5S57,61.6,57,61.6L57.1,61.7z" fill="#E6EBEF" />
          <Path className="st1" d="M59.8,59.3c0,0,0.1,1.2-0.8,2s-2,0.4-2,0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M59.7,59.2c0,0-1.2-0.3-2.1,0.5s-0.7,1.9-0.7,1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M75.6,60.1c0,0-0.5-1-1.7-1c-1.2,0-1.9,1-1.9,1l0,0.2c0,0,0.7,1,1.9,1c1.2,0,1.7-1,1.7-1L75.6,60.1z" fill="#E6EBEF" />
          <Path className="st1" d="M72,60.1c0,0,0.7-1,1.9-1c1.2,0,1.7,1,1.7,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M72,60.3c0,0,0.7,1,1.9,1c1.2,0,1.7-1,1.7-1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M80.6,61.2c0,0-0.7-1.1-2.2-1s-2.1,1.4-2.1,1.4l0,0.2c0,0,1,1.1,2.4,0.9c1.4-0.1,1.9-1.4,1.9-1.4L80.6,61.2z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M76.3,61.7c0,0,0.7-1.2,2.1-1.4s2.2,1,2.2,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M76.3,61.9c0,0,1,1.1,2.4,0.9s1.9-1.4,1.9-1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M32.3,59.3c0,0-0.4,1.3,0.5,2.3c1,1.1,2.4,0.9,2.4,0.9l0.1-0.1c0,0,0.3-1.4-0.7-2.5c-1-1.1-2.3-0.8-2.3-0.8
			L32.3,59.3z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M35.2,62.5c0,0-1.4,0.2-2.4-0.9c-1-1.1-0.5-2.3-0.5-2.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M35.3,62.4c0,0,0.3-1.4-0.7-2.5c-1-1.1-2.3-0.8-2.3-0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M11.7,62.8c0,0,1,0.6,2.1-0.1c1.1-0.6,1.1-1.9,1.1-1.9l-0.1-0.1c0,0-1.2-0.5-2.2,0.2s-1,1.8-1,1.8L11.7,62.8z
			"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M14.8,60.8c0,0-0.1,1.2-1.1,1.9s-2.1,0.1-2.1,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M14.8,60.7c0,0-1.2-0.5-2.2,0.2s-1,1.8-1,1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M19,62.7c0,0-0.5-1-1.8-1.1c-1.2,0-2,0.9-2,0.9l0,0.2c0,0,0.7,1,1.9,1.1c1.2,0,1.8-1,1.8-1L19,62.7z" fill="#E6EBEF" />
          <Path className="st1" d="M15.3,62.6c0,0,0.8-1,2-0.9c1.2,0,1.8,1.1,1.8,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M15.3,62.7c0,0,0.7,1,1.9,1.1s1.8-1,1.8-1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M7.6,59.7c0,0-0.6,1.4,0.4,2.7c1,1.3,2.7,1.3,2.7,1.3l0.2-0.1c0,0,0.5-1.6-0.5-2.9c-1-1.3-2.5-1.1-2.5-1.1
			L7.6,59.7z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M10.7,63.6c0,0-1.7,0.1-2.7-1.3c-1-1.3-0.4-2.7-0.4-2.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M10.8,63.5c0,0,0.5-1.6-0.5-2.9c-1-1.3-2.5-1.1-2.5-1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M19.9,65.1c0,0,0.9,0.8,2,0.4c1.2-0.4,1.5-1.6,1.5-1.6l-0.1-0.1c0,0-1-0.7-2.2-0.3C20,63.8,19.8,65,19.8,65
			L19.9,65.1z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M23.4,63.8c0,0-0.3,1.2-1.5,1.6c-1.2,0.4-2-0.4-2-0.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M23.3,63.6c0,0-1-0.7-2.2-0.3c-1.2,0.4-1.3,1.6-1.3,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st0" d="M13.6,63.3c0,0-0.8-1.1-2.2-1s-2.2,1.4-2.2,1.4l0,0.2c0,0,1,1.1,2.5,1c1.5-0.1,2-1.4,2-1.4L13.6,63.3z" fill="#E6EBEF" />
          <Path className="st1" d="M9.2,63.8c0,0,0.7-1.3,2.2-1.4s2.2,1,2.2,1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M9.2,64c0,0,1,1.1,2.5,1c1.5-0.1,2-1.4,2-1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <Polyline className="st0" fill="#E6EBEF" points="16,55.3 16,29.1 42.2,29.1 42.2,55.3 	" />
        <Rect className="st2" fill="#BAC2CA" height="10.8" width="5.3" x="20.6" y="38.6" />
        <Rect className="st3" fill={svgHighlight} height="17" width="6.5" x="30.7" y="38.3" />
        <Polygon className="st2" fill="#BAC2CA" points="38.4,34 11.7,34 24.9,19.7 51.7,19.7 	" />
        <Polyline className="st4" fill="none" points="36.5,19.7 51.7,19.7 38.4,34 11.7,34 24.9,19.7 33.6,19.7 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Polyline className="st4" fill="none" points="51.7,19.7 64.2,34 61.7,34 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Polyline className="st4" fill="none" points="15.9,34.5 15.9,55.6 60.5,55.6 60.5,29.8 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" x1="42.3" x2="42.3" y1="32.6" y2="47.4" />
        <Polyline className="st6" fill="none" points="37.2,55.4 37.2,38.4 30.7,38.4 30.7,53.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.65" />
        <Rect className="st6" fill="none" height="10.9" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.65" width="5.8" x="20.4" y="38.5" />
        <Ellipse className="st7" cx="52" cy="30.7" fill="#E6EBEF" rx="1.6" ry="2.4" stroke="#000000" strokeMiterlimit="10" strokeWidth="0.65" />
        <Ellipse className="st5" cx="52" cy="30.7" fill="none" rx="1.6" ry="2.4" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
        <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="3.9" x2="94.8" y1="55.6" y2="55.6" />
        <G>
          <Path
            className="st8"
            d="M89.8,27.6c-0.5-0.4-0.7-1.2-0.3-1.7c0.7-1.1,1.1-2.4,1.1-3.8c0-2.6-1.4-5-3.6-6.4c-0.3-0.2-0.4-0.5-0.3-0.8
			c0.1-0.4,0.2-0.8,0.2-1.2c0-2.6-2.3-4.6-5.2-4.6c-2.9,0-5.2,2.1-5.2,4.6c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0,0.7-0.3,0.8
			c-2.2,1.5-3.6,3.8-3.6,6.4c0,1.4,0.4,2.7,1.1,3.8c0.3,0.6,0.2,1.3-0.3,1.7c-2.3,1.9-3.7,4.7-3.7,7.7c0,5.9,5.3,10.6,11.9,10.6
			s11.9-4.8,11.9-10.6C93.5,32.3,92.1,29.5,89.8,27.6z"
            fill="#D8DFE5"
          />
          <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="81.3" x2="81.3" y1="46.1" y2="55.5" />
          <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="81.3" x2="81.3" y1="22.1" y2="40.2" />
          <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="75.1" x2="81.2" y1="31.7" y2="35.6" />
          <Line className="st4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" x1="84.5" x2="81.3" y1="27.1" y2="29.3" />
          <Path className="st4" d="M82.7,42.2c-0.5-1.7-2.1-2.9-3.9-2.9c-0.9,0-1.7,0.3-2.4,0.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path className="st4" d="M86.3,42.2c-0.3-0.5-0.7-0.9-1.2-1.2c-0.9-0.4-1.8-0.4-2.6,0.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
          <Path
            className="st4"
            d="M89.8,27.6c-0.5-0.4-0.7-1.2-0.3-1.7c0.7-1.1,1.1-2.4,1.1-3.8c0-2.6-1.4-5-3.6-6.4c-0.3-0.2-0.4-0.5-0.3-0.8
			c0.1-0.4,0.2-0.8,0.2-1.2c0-2.6-2.3-4.6-5.2-4.6c-2.9,0-5.2,2.1-5.2,4.6c0,0.4,0.1,0.8,0.2,1.2c0.1,0.3,0,0.7-0.3,0.8
			c-2.2,1.5-3.6,3.8-3.6,6.4c0,1.4,0.4,2.7,1.1,3.8c0.3,0.6,0.2,1.3-0.3,1.7c-2.3,1.9-3.7,4.7-3.7,7.7c0,5.9,5.3,10.6,11.9,10.6
			s11.9-4.8,11.9-10.6C93.5,32.3,92.1,29.5,89.8,27.6z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
        </G>
        <G>
          <Path
            className="st0"
            d="M88.4,50.9c0,0-1-0.3-1.2-1.4c-0.2-1.1,0.6-1.9,0.6-1.9l0.1,0c0,0,1,0.5,1.3,1.6c0.2,1.1-0.6,1.8-0.6,1.8
			L88.4,50.9z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M87.8,47.5c0,0-0.8,0.8-0.6,1.9c0.2,1.1,1.2,1.4,1.2,1.4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M88,47.5c0,0,1,0.5,1.3,1.6c0.2,1.1-0.6,1.8-0.6,1.8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M69.2,44.4c0,0,0.2,1.1-0.8,1.9c-1,0.8-2.2,0.4-2.2,0.4l-0.1-0.1c0,0-0.1-1.2,0.9-2s2-0.3,2-0.3L69.2,44.4z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M66.2,46.7c0,0,1.2,0.3,2.2-0.4s0.8-1.9,0.8-1.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M66.1,46.5c0,0-0.1-1.2,0.9-2c1-0.8,2-0.3,2-0.3" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st0"
            d="M89.9,39.7c0,0,1.2-0.7,2.4,0c1.3,0.7,1.4,2.2,1.4,2.2L93.7,42c0,0-1.3,0.6-2.6-0.1c-1.3-0.7-1.2-2.1-1.2-2.1
			L89.9,39.7z"
            fill="#E6EBEF"
          />
          <Path className="st1" d="M93.7,41.9c0,0-0.2-1.5-1.4-2.2c-1.3-0.7-2.4,0-2.4,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M93.7,42.1c0,0-1.3,0.6-2.6-0.1c-1.3-0.7-1.2-2.1-1.2-2.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st9"
            d="M76.6,51.9c0,0-1.3,0.2-2.1-0.9c-0.8-1.1-0.4-2.4-0.4-2.4l0.1-0.1c0,0,1.4,0,2.2,1.1s0.3,2.2,0.3,2.2
			L76.6,51.9z"
            fill="#FFFFFF"
          />
          <Path className="st1" d="M74.2,48.6c0,0-0.4,1.3,0.4,2.4s2.1,0.9,2.1,0.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M74.3,48.5c0,0,1.4,0,2.2,1.1s0.3,2.2,0.3,2.2" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path className="st9" d="M89,20.4c0,0,0-1,1-1.6s2,0,2,0l0.1,0.1c0,0-0.1,1.1-1.1,1.6c-1,0.5-1.8,0-1.8,0L89,20.4z" fill="#FFFFFF" />
          <Path className="st1" d="M91.9,18.8c0,0-1-0.5-2,0c-1,0.5-1,1.6-1,1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
          <Path className="st1" d="M92,19c0,0-0.1,1.1-1.1,1.6c-1,0.5-1.8,0-1.8,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" />
        </G>
        <G>
          <Path
            className="st9"
            d="M52.3,51.5c-1.2-2.4-4.2-2-4.2-2l-0.6,0l0,0l-0.2,0l-0.2,0l0,0l-0.6,0c0,0-3-0.4-4.2,2
			c-1.2,2.4,0.6,4.4,2,4.9c0,0,0.7,0.3,2.2,0.3h0.2h1.1h0.2c1.4,0,2.2-0.3,2.2-0.3C51.7,55.9,53.5,53.8,52.3,51.5z"
            fill="#FFFFFF"
          />
          <Path
            className="st2"
            d="M52.3,51.5c-0.7-1.5-2.2-1.9-3.1-2c0.6,0.3,1.2,0.8,1.6,1.6c1.2,2.4-0.6,4.4-2,4.9c0,0-0.7,0.3-2.2,0.3h-0.2
			h-1.1h-0.2c-0.4,0-0.8,0-1.1-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0.7,0.3,2.2,0.3h0.2h1.1h0.2c1.4,0,2.2-0.3,2.2-0.3
			C51.7,55.9,53.5,53.8,52.3,51.5z"
            fill="#BAC2CA"
          />
          <Path
            className="st4"
            d="M52.3,51.5c-1.2-2.4-4.2-2-4.2-2l-0.6,0l0,0l-0.2,0l-0.2,0l0,0l-0.6,0c0,0-3-0.4-4.2,2
			c-1.2,2.4,0.6,4.4,2,4.9c0,0,0.7,0.3,2.2,0.3h0.2h1.1h0.2c1.4,0,2.2-0.3,2.2-0.3C51.7,55.9,53.5,53.8,52.3,51.5z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st5" d="M47.5,49.5c0,0,2.5,0.4,2.5,3.5s-2.6,3.7-2.6,3.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path className="st5" d="M46.9,49.5c0,0-2.5,0.4-2.5,3.5s2.6,3.7,2.6,3.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path className="st4" d="M47.7,48.2c0,0-0.5,0.2-0.6,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
        <G>
          <Path
            className="st9"
            d="M19.4,51.5c-1.2-2.4-4.2-2-4.2-2l-0.6,0l0,0l-0.2,0l-0.2,0l0,0l-0.6,0c0,0-3-0.4-4.2,2
			c-1.2,2.4,0.6,4.4,2,4.9c0,0,0.7,0.3,2.2,0.3h0.2H15h0.2c1.4,0,2.2-0.3,2.2-0.3C18.8,55.9,20.6,53.8,19.4,51.5z"
            fill="#FFFFFF"
          />
          <Path
            className="st2"
            d="M19.4,51.5c-0.7-1.5-2.2-1.9-3.1-2c0.6,0.3,1.2,0.8,1.6,1.6c1.2,2.4-0.6,4.4-2,4.9c0,0-0.7,0.3-2.2,0.3h-0.2
			h-1.1h-0.2c-0.4,0-0.8,0-1.1-0.1c0.1,0.1,0.3,0.1,0.4,0.2c0,0,0.7,0.3,2.2,0.3h0.2H15h0.2c1.4,0,2.2-0.3,2.2-0.3
			C18.8,55.9,20.6,53.8,19.4,51.5z"
            fill="#BAC2CA"
          />
          <Path
            className="st4"
            d="M19.4,51.5c-1.2-2.4-4.2-2-4.2-2l-0.6,0l0,0l-0.2,0l-0.2,0l0,0l-0.6,0c0,0-3-0.4-4.2,2
			c-1.2,2.4,0.6,4.4,2,4.9c0,0,0.7,0.3,2.2,0.3h0.2H15h0.2c1.4,0,2.2-0.3,2.2-0.3C18.8,55.9,20.6,53.8,19.4,51.5z"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75"
          />
          <Path className="st5" d="M14.6,49.5c0,0,2.5,0.4,2.5,3.5s-2.6,3.7-2.6,3.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path className="st5" d="M14.1,49.5c0,0-2.5,0.4-2.5,3.5s2.6,3.7,2.6,3.7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5" />
          <Path className="st4" d="M14.8,48.2c0,0-0.5,0.2-0.6,1.1" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75" />
        </G>
      </G>
    </Svg>
  );
}
