/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function CloseXIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(18)} viewBox="0 0 18 18" width={moderateScale(18)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M16 2.43955L15.5605 2L9 8.55889L2.43955 2L2 2.43955L8.55889 9L2 15.5605L2.43955 16L9 9.4411L15.5605 16L16 15.5605L9.4411 9L16 2.43955Z" fill="#282828" stroke="#282828" />
    </Svg>
  );
}
