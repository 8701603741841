import LoginSslError from '../../containers/Login/LoginSslErrorView';
import LoginSso from '../../containers/Login/Sso/LoginSsoView';
import OauthCallback from '../../containers/Login/Sso/OauthCallbackView';

const ssoScreens = {
  LoginSslError: { component: LoginSslError },
  LoginSso: { component: LoginSso },
  OauthCallback: { component: OauthCallback },
};

export default ssoScreens;
