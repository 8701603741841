import React, { useRef } from 'react';
import T from 'prop-types';
import { Dimensions } from 'react-native';

import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  ConditionalRender,
  H4,
  MediumProgressButton,
} from '../../base_ui';
import {
  ButtonWrapper,
  EditEmailContainer,
  IllustrationWrapper,
  StyledBaseTextField,
  StyledP2,
  StyledStatusBanner,
  TextFieldGroup,
} from './styledComponents';

const EditEmailIllustration = iconDictionary('emailChair', { height: `${moderateScale(210)}px` });

const EditEmailComponent = ({
  bannerError,
  dispatchChangeInput,
  dispatchResetFormErrorsState,
  email,
  form,
  formErrors,
  handleEditEmail,
  isSubmitDisabled,
  status,
}) => {
  const confirmEmailRef = useRef();
  const windowHeight = Dimensions.get('window').height;

  const handleChangeText = ({ field, value }) => {
    const formName = 'email';
    if (formErrors[field]) {
      dispatchResetFormErrorsState({ field, form: formName });
    }
    if (field === 'newEmail' && formErrors.confirmEmail) {
      dispatchResetFormErrorsState({ field: 'confirmEmail', form: formName });
    }
    dispatchChangeInput({ field, form: formName, value });
  };

  return (
    <EditEmailContainer>
      <StyledStatusBanner error={bannerError} />
      <H4>Email on File</H4>
      <StyledP2>{email}</StyledP2>
      <H4>Edit Email Address</H4>
      <TextFieldGroup $isFlexGrow={windowHeight <= 800}>
        <StyledBaseTextField
          errorMessage={formErrors.newEmail}
          label="New Email"
          onChangeText={(value) => handleChangeText({
            field: 'newEmail',
            value,
          })}
          onSubmitEditing={() => confirmEmailRef.current.focus()}
          placeholder="Required"
          returnKeyType="next"
          value={form.newEmail}
        />
        <StyledBaseTextField
          ref={confirmEmailRef}
          errorMessage={formErrors.confirmEmail}
          label="Confirm Email"
          onChangeText={(value) => handleChangeText({
            field: 'confirmEmail',
            value,
          })}
          onSubmitEditing={isSubmitDisabled ? undefined : handleEditEmail}
          placeholder="Required"
          returnKeyType="done"
          value={form.confirmEmail}
        />
      </TextFieldGroup>
      <ConditionalRender
        Component={(
          <IllustrationWrapper>
            {EditEmailIllustration}
          </IllustrationWrapper>
        )}
        shouldRender={windowHeight > 800}
      />
      <ButtonWrapper>
        <MediumProgressButton
          disabled={isSubmitDisabled}
          label="Next"
          onPress={handleEditEmail}
          status={status}
        />
      </ButtonWrapper>
    </EditEmailContainer>
  );
};

EditEmailComponent.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetFormErrorsState: T.func.isRequired,
  email: T.string.isRequired,
  form: T.shape({
    confirmEmail: T.string.isRequired,
    newEmail: T.string.isRequired,
  }).isRequired,
  formErrors: T.shape({
    confirmEmail: T.string.isRequired,
    newEmail: T.string.isRequired,
  }).isRequired,
  handleEditEmail: T.func.isRequired,
  isSubmitDisabled: T.bool.isRequired,
  status: T.string.isRequired,
};

export default EditEmailComponent;
