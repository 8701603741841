/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Ellipse,
  Line,
  Path,
  Polyline,
} from 'react-native-svg';

import { moderateScale } from '../../../scaleHelpers';

/* eslint-disable react/jsx-sort-props */
const ThumbtackL = (props) => (
  <Svg fill="none" height={moderateScale(86)} width={moderateScale(86)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 86 86" {...props}>
    <Path fill="#bac2ca" d="M47,52.32A10.23,10.23,0,0,0,52,49l-2.82-4.19-5,3.32Z" />
    <Ellipse fill="#b9accc" cx="42.08" cy="39.78" rx="19.86" ry="9.62" transform="translate(-15.03 30.2) rotate(-33.86)" />
    <Path fill="#9a87b5" d="M25.32,45.81s5.5,4,13,.41l-7.57-7.57A11,11,0,0,0,25.32,45.81Z" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M30.24,53.33a6.3,6.3,0,0,1-4.65-2.48c-3-4.42,2-13,11.13-19.06s18.89-7.49,21.85-3.07-2,12.94-11.13,19.05c-5.31,3.56-10.85,5.52-15.13,5.65" />
    <Path fill="#ddd6e6" d="M51.13,28.75,34.8,12.05,19.5,22.82l8.86,21.23a3.32,3.32,0,0,0,.49,1.18c2.08,3.1,9,2.13,15.36-2.17s9.91-10.3,7.83-13.4A3.42,3.42,0,0,0,51.13,28.75Z" />
    <Path fill="#b9accc" d="M25.4,24.21,36,46.72S31.53,48.9,28.51,44l-7.39-18Z" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" d="M29,45.14c2.08,3.1,9,2.13,15.37-2.16a27.25,27.25,0,0,0,2.5-1.89" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" d="M27.36,41.2,28.51,44A3.46,3.46,0,0,0,29,45.14" />
    <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" points="40.94 18.34 34.8 12.05 19.5 22.82 27.2 41.28" />
    <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" x1="48.91" y1="26.49" x2="42.34" y2="19.77" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" d="M48.63,39.41c3.38-3.56,4.83-7.36,3.31-9.62a7.94,7.94,0,0,0-.92-.91l-2.21-2.26" />
    <Path fill="#b9accc" d="M13.5,23.12,15,25.41c2.15,3.2,9.24,2.2,15.84-2.23s10.21-10.6,8.06-13.8L37.51,7.25" />
    <Ellipse fill="#ddd6e6" cx="25.3" cy="14.74" rx="13.97" ry="7.02" transform="translate(-3.92 16.6) rotate(-33.86)" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M13.5,23.12,15,25.41c2.15,3.2,9.24,2.2,15.84-2.23s10.21-10.6,8.06-13.8L37.51,7.25" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" d="M21.17,24.16c-3.63.9-6.64.48-7.92-1.41" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" d="M37.15,6.71c2.15,3.2-1.46,9.38-8.06,13.81a27.42,27.42,0,0,1-5.94,3" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M13.25,22.75c-2.14-3.2,1.47-9.38,8.07-13.81S35,3.51,37.15,6.71" />
    <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.18px" d="M50.21,46.18,60.84,62a10.11,10.11,0,0,1,1.68,4.77l.3,3.56L59.63,69a10.07,10.07,0,0,1-4.37-3.64L45.06,50.15" />
    <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="65.74" y1="74.54" x2="68.81" y2="79.04" />
    <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="58.82" y1="75.27" x2="58.06" y2="78.12" />
    <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="68.93" y1="68.49" x2="71.85" y2="68.86" />
    <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.75px" x1="69.82" y1="80.54" x2="70.51" y2="81.54" />
  </Svg>
);

export default ThumbtackL;
