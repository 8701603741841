/*
* Email Form Input
*/

import React, { Fragment, useRef } from 'react';
import T from 'prop-types';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import { LargeProgressButton, LoginRegisterTextField } from '../../base_ui';
import {
  ButtonsContainer,
  RegisterInputContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const EmailFormInput = ({
  dispatchChangeInput,
  formErrors,
  formValues,
  handleBack,
  handleNextClick,
  handleSubmit,
  status,
}) => {
  const confirmEmailRef = useRef(null);

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <LoginRegisterTextField
            error={formErrors.email}
            keyboardType="email-address"
            label="Email Address"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'email',
              form: 'account',
              value: newValue,
            })}
            onSubmitEditing={() => confirmEmailRef.current.focus()}
            returnKeyType="next"
            value={formValues.email}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={confirmEmailRef}
            error={formErrors.confirmEmail}
            keyboardType="email-address"
            label="Confirm Email Address"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'confirmEmail',
              form: 'account',
              value: newValue,
            })}
            onSubmitEditing={() => handleNextClick({
              field: ['email', 'confirmEmail'],
              form: 'account',
              formValues,
              handleSubmitStep: () => handleSubmit(3, formValues),
            })}
            returnKeyType="done"
            value={formValues.confirmEmail}
          />
        </TextFieldWrapper>
      </RegisterInputContainer>
      <StyledPrimaryDivider />
      <ButtonsContainer>
        <LargeProgressButton
          disabled={!formValues.confirmEmail || !formValues.email}
          label="Submit"
          onPress={() => handleNextClick({
            field: ['email', 'confirmEmail'],
            form: 'account',
            formValues: trimFormValues(formValues),
            handleSubmitStep: () => handleSubmit(3, trimFormValues(formValues)),
          })}
          status={status.stepTwo}
        />
        <StyledBaseInternalLink label="Back" onPressFunc={handleBack} size="medium" />
      </ButtonsContainer>
    </Fragment>
  );
};

EmailFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  status: T.object.isRequired,
};

export default EmailFormInput;
