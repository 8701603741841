import React from 'react';
import T from 'prop-types';
import { useIntl } from 'react-intl';

import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import { ConditionalRender } from '../base_ui';
import DISCLAIMER_DICTIONARY from '../Disclaimers/disclaimerDictionary';
import {
  SectionContainer,
  StyledExternalLink,
  StyledInternalLink,
  Subheading,
  SubheadingWrapper,
} from './styledComponents';

const MapSection = ({
  handleNavigateLink,
  handleOpenDrawer,
  Icon,
  label,
  links,
}) => {
  const { formatMessage, messages } = useIntl();

  return (
    <SectionContainer>
      <SubheadingWrapper>
        {Icon}
        <Subheading>
          {resolveIntlMessage({ formatMessage, message: label, messages })}
        </Subheading>
      </SubheadingWrapper>
      {links.map(({
        href,
        label: linkLabel,
        mainPath, path,
      }) => (
        <ConditionalRender
          key={path || href}
          Component={(
            <StyledInternalLink
              label={resolveIntlMessage({ formatMessage, message: linkLabel, messages })}
              onPressFunc={DISCLAIMER_DICTIONARY[path] ?
                () => handleOpenDrawer(path) :
                () => handleNavigateLink({ mainPath, path })}
            />
          )}
          FallbackComponent={(
            <StyledExternalLink
              href={href}
              label={resolveIntlMessage({ formatMessage, message: linkLabel, messages })}
            />
          )}
          shouldRender={!!path}
        />
      ))}
    </SectionContainer>
  );
};

MapSection.propTypes = {
  handleNavigateLink: T.func,
  handleOpenDrawer: T.func,
  Icon: T.object.isRequired,
  label: T.string.isRequired,
  links: T.array.isRequired,
};

export default MapSection;
