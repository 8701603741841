import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';
import * as WebBrowser from 'expo-web-browser';

import {
  changeStatementsFilter,
  fetchStatement,
  fetchStatements,
  resetStatementsState,
  setStatementsPagesRendered,
} from '@dmi/shared/redux/Documents/actions';
import {
  selectDocumentsNotifications,
  selectError,
  selectLoading,
  selectStatements,
  selectStatementsToRender,
  selectTotalStatements,
} from '@dmi/shared/redux/Documents/selectors';
import { getMainClientInfo, getPaymentMethod } from '@dmi/shared/redux/Main/selectors';
import makeSelectMobile from '@dmi/shared/redux/Mobile/selectors';

import AsyncRender from '../../components/AsyncRender';
import { ConditionalRender } from '../../components/base_ui';
import { StatementsList, StatementsListSkeleton } from '../../components/Documents/StatementsList';
import { SecondaryHeader } from '../../components/ScreenHeaders';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import { StyledStatusBanner, StyledView } from './styledComponents';
import LoadingDog from '../../components/LoadingDog';

const StatementsScreen = ({
  bannerError,
  customerServicePhoneNumber,
  dispatchChangeFilter,
  dispatchFetchStatement,
  dispatchFetchStatements,
  dispatchResetStatementsState,
  dispatchSetPagesRendered,
  isNativeMobile,
  itemsPerPage,
  loading,
  navigation,
  notifications,
  pagesRendered,
  paymentMethod,
  selectedFilter,
  serviceError,
  statementLoading,
  statementsToRender,
  totalItems,
}) => {
  const isInitialState = pagesRendered === -1;

  useEffect(() => {
    dispatchFetchStatements();
    return dispatchResetStatementsState;
  }, [dispatchFetchStatements, dispatchResetStatementsState]);

  const handleEndReached = () => {
    if (statementsToRender.length < totalItems) {
      dispatchSetPagesRendered(pagesRendered + 1);
    }
  };

  const handleRowPress = (statementId, statementUrl) => {
    dispatchFetchStatement({ statementId, statementUrl });
    if (!isNativeMobile) {
      WebBrowser.openBrowserAsync(statementUrl);
    }
  };

  return (
    <ConditionalRender
      Component={(
        <Fragment>
          <SecondaryHeader
            handleBack={() => navigation.goBack()}
            title="Statements"
          />
          <ScreenReaderNotifications notifications={notifications} />
          <StyledView>
            <StyledStatusBanner error={bannerError} />
            <AsyncRender
              Component={StatementsList}
              error={serviceError}
              errorComponentType="cardLevel"
              loading={!serviceError && (isInitialState || loading)}
              LoadingComponent={StatementsListSkeleton}
              propsToPassDown={{
                customerServicePhoneNumber,
                dispatchChangeFilter,
                handleEndReached,
                handleRowPress,
                itemsPerPage,
                paymentMethod,
                selectedFilter,
                statementsToRender,
              }}
            />
          </StyledView>
        </Fragment>
      )}
      FallbackComponent={LoadingDog}
      shouldRender={!statementLoading}
    />
  );
};

StatementsScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeFilter: T.func.isRequired,
  dispatchFetchStatement: T.func.isRequired,
  dispatchFetchStatements: T.func.isRequired,
  dispatchResetStatementsState: T.func.isRequired,
  dispatchSetPagesRendered: T.func.isRequired,
  isNativeMobile: T.bool.isRequired,
  itemsPerPage: T.number.isRequired,
  loading: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  pagesRendered: T.number.isRequired,
  paymentMethod: T.string.isRequired,
  selectedFilter: T.string.isRequired,
  serviceError: T.oneOfType([T.bool, T.object, T.string]).isRequired,
  statementLoading: T.bool.isRequired,
  statementsToRender: T.arrayOf(T.object).isRequired,
  totalItems: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Documents
   */
  bannerError: selectError('bannerError'),
  isNativeMobile: makeSelectMobile('isNativeMobile'),
  itemsPerPage: selectStatements('itemsPerPage'),
  loading: selectLoading('statements'),
  notifications: selectDocumentsNotifications(),
  pagesRendered: selectStatements('pagesRendered'),
  paymentMethod: getPaymentMethod(),
  selectedFilter: selectStatements('selectedFilter'),
  serviceError: selectError('serviceError'),
  statementLoading: selectLoading('statement'),
  statementsToRender: selectStatementsToRender(),
  totalItems: selectTotalStatements(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Documents
   */
  dispatchChangeFilter: (payload) => dispatch(changeStatementsFilter(payload)),
  dispatchFetchStatement: (payload) => dispatch(fetchStatement(payload)),
  dispatchFetchStatements: () => dispatch(fetchStatements()),
  dispatchResetStatementsState: () => dispatch(resetStatementsState()),
  dispatchSetPagesRendered: (payload) => dispatch(setStatementsPagesRendered(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(StatementsScreen);
