import React from 'react';

import { Animation } from '../../base_ui';
import {
  AnimationWrapper,
  LoadingDogHouse,
  NachaContainer,
  NachaHeader,
} from './styledComponents';

const Nacha = () => (
  <NachaContainer>
    <LoadingDogHouse>
      <AnimationWrapper
        accessible
        aria-label="Loading indicator"
        role="img"
      >
        <Animation
          lottieProps={{ loop: true }}
          name="dogMobile"
        />
      </AnimationWrapper>
    </LoadingDogHouse>
    <NachaHeader>Your payment account is being processed.</NachaHeader>
  </NachaContainer>
);

export default Nacha;
