import React from 'react';
import T from 'prop-types';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';

import { MediumProgressButton } from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import { moderateScale } from '../../../utils/scaleHelpers';
import {
  DrawerButtons,
  DrawerContainer,
  DrawerContent,
  HeaderWrapper,
  IllustrationWrapper,
  StyledBorderlessButton,
  StyledH2,
  StyledP2,
} from './styledComponents';

const DogWindowIcon = iconDictionary('dogWindow',
  { height: `${moderateScale(120)}px`, width: `${moderateScale(287)}px` });

const StepTwoView = ({
  dispatchCancelMailingAddress,
  dispatchChangeStep,
  status,
}) => {
  const { subheader, text } = LOANS_CONTENT_DICTIONARY.cancelMailingAddress.stepTwoView;

  return (
    <DrawerContainer>
      <DrawerContent>
        <IllustrationWrapper>{DogWindowIcon}</IllustrationWrapper>
        <HeaderWrapper>
          <StyledH2>{subheader}</StyledH2>
        </HeaderWrapper>
        <StyledP2>{text}</StyledP2>
      </DrawerContent>
      <DrawerButtons>
        <MediumProgressButton
          label="Yes, Cancel"
          onPress={dispatchCancelMailingAddress}
          status={status}
        />
        <StyledBorderlessButton
          disabled={status === 'loading'}
          isPrimary
          label="Go Back"
          onPress={() => dispatchChangeStep(1)}
        />
      </DrawerButtons>
    </DrawerContainer>
  );
};

StepTwoView.propTypes = {
  dispatchCancelMailingAddress: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  status: T.string.isRequired,
};

export default StepTwoView;
