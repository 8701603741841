import React from 'react';
import T from 'prop-types';

import {
  CardContainer,
  CardContentContainer,
  CardHeader,
  CardHeaderContainer,
} from './styledComponents';

const Card = ({ children, HeaderIcon, headerText }) => (
  <CardContainer>
    <CardHeaderContainer>
      {HeaderIcon}
      <CardHeader>{headerText}</CardHeader>
    </CardHeaderContainer>
    <CardContentContainer>
      {children}
    </CardContentContainer>
  </CardContainer>
);

Card.propTypes = {
  children: T.oneOfType([T.array, T.object]),
  HeaderIcon: T.object.isRequired,
  headerText: T.string.isRequired,
};

export default Card;
