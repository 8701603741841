import React, { Fragment } from 'react';
import T from 'prop-types';

import { CONFIRMATION_DICT } from '@dmi/shared/redux/SecureMessaging/constants';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import Message from '../../../Message';
import iconDictionary from '../../../../utils/iconDictionary';
import { SecondaryHeader } from '../../../ScreenHeaders';
import { ErrorContainer } from './styledComponents';

const errorStorm = iconDictionary('errorStorm');

const ErrorComponent = ({
  customerServicePhoneNumber,
  errorSubHeader,
  errorText,
}) => {
  const { linkLabel, mobilePath } = CONFIRMATION_DICT;

  return (
    <Fragment>
      <SecondaryHeader title="Confirmation" />
      <ErrorContainer>
        <Message
          body={interpolate(errorText, { customerServicePhoneNumber })}
          icon={errorStorm}
          link={{ label: linkLabel, path: mobilePath }}
          title={errorSubHeader}
        />
      </ErrorContainer>
    </Fragment>
  );
};

ErrorComponent.propTypes = {
  customerServicePhoneNumber: T.string,
  errorSubHeader: T.string,
  errorText: T.string,
};

export default ErrorComponent;
