import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../scaleHelpers';

/* eslint-disable max-len, react/jsx-sort-props */
export default function DocumentsBuilding(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(100)} viewBox="0 0 100 100" width={moderateScale(100)} {...props}>
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.38px" x1="4.17" y1="90.72" x2="3.2" y2="90.72" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.38px" x1="5.98" y1="90.73" x2="97.73" y2="90.73" />
      <Polyline fill={svgHighlight} stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="35.12 84.47 35.12 66.89 47.75 66.89 47.75 84.47" />
      <Rect fill="#e6ebef" x="70.31" y="17.78" width="24.11" height="72.69" />
      <Rect fill="#bac2ca" x="70.44" y="25.2" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="85.05" y="25.2" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="91.08" y="29.25" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="70.44" y="34.04" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="91.08" y="38.08" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="78.14" y="38.08" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="70.44" y="42.87" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="91.08" y="46.92" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="70.44" y="51.71" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="91.08" y="55.75" width="3.01" height="2.07" />
      <Rect fill="#bac2ca" x="13.39" y="19.25" width="80.62" height="1.23" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="70.26" y1="70.64" x2="70.26" y2="90.46" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="13.24 90.46 13.24 17.72 70.26 17.72 70.26 67.91" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="94.27 90.49 94.27 17.72 70.21 17.72" />
      <Rect fill="#e6ebef" x="22.85" y="26.81" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="22.28 35.3 25.45 26.81 22.61 26.81 22.28 27.69 22.28 35.3" />
      <Polygon fill="#fff" opacity="0.7" points="26.23 37.85 24.46 37.85 28.59 26.81 30.36 26.81 26.23 37.85" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="22.24" y="26.12" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="23.1 37.74 23.1 27.02 28.23 27.02" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="24.06" y1="32.26" x2="29.12" y2="32.26" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="22.04" y1="39.42" x2="29.1" y2="39.42" />
      <Rect fill="#e6ebef" x="38.56" y="26.81" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="37.98 35.3 41.16 26.81 38.31 26.81 37.98 27.69 37.98 35.3" />
      <Polygon fill="#fff" opacity="0.7" points="41.94 37.85 40.17 37.85 44.3 26.81 46.07 26.81 41.94 37.85" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="37.95" y="26.12" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="38.8 37.74 38.8 27.02 43.94 27.02" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="39.76" y1="32.26" x2="44.82" y2="32.26" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="37.75" y1="39.42" x2="44.81" y2="39.42" />
      <Rect fill="#e6ebef" x="54.18" y="26.81" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="53.6 35.3 56.77 26.81 53.93 26.81 53.6 27.69 53.6 35.3" />
      <Polygon fill="#fff" opacity="0.7" points="57.55 37.85 55.78 37.85 59.91 26.81 61.69 26.81 57.55 37.85" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="53.57" y="26.12" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="54.42 37.74 54.42 27.02 59.55 27.02" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="55.38" y1="32.26" x2="60.44" y2="32.26" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="53.36" y1="39.42" x2="60.42" y2="39.42" />
      <Rect fill="#e6ebef" x="22.85" y="48.47" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="22.28 56.96 25.45 48.47 22.61 48.47 22.28 49.35 22.28 56.96" />
      <Polygon fill="#fff" opacity="0.7" points="26.23 59.52 24.46 59.52 28.59 48.47 30.36 48.47 26.23 59.52" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="22.24" y="47.78" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="23.1 59.4 23.1 48.68 28.23 48.68" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="24.06" y1="53.92" x2="29.12" y2="53.92" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="22.04" y1="61.08" x2="29.1" y2="61.08" />
      <Rect fill="#e6ebef" x="38.56" y="48.47" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="37.98 56.96 41.16 48.47 38.31 48.47 37.98 49.35 37.98 56.96" />
      <Polygon fill="#fff" opacity="0.7" points="41.94 59.52 40.17 59.52 44.3 48.47 46.07 48.47 41.94 59.52" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="37.95" y="47.78" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="38.8 59.4 38.8 48.68 43.94 48.68" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="39.76" y1="53.92" x2="44.82" y2="53.92" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="37.75" y1="61.08" x2="44.81" y2="61.08" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="35.08" y1="65.23" x2="47.85" y2="65.23" />
      <Rect fill="#e6ebef" x="54.18" y="48.47" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="53.6 56.96 56.77 48.47 53.93 48.47 53.6 49.35 53.6 56.96" />
      <Polygon fill="#fff" opacity="0.7" points="57.55 59.52 55.78 59.52 59.91 48.47 61.69 48.47 57.55 59.52" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="53.57" y="47.78" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="54.42 59.4 54.42 48.68 59.55 48.68" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="55.38" y1="53.92" x2="60.44" y2="53.92" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="53.36" y1="61.08" x2="60.42" y2="61.08" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="21.24" y="24.38" width="8.83" height="1.67" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="36.91" y="24.38" width="8.83" height="1.67" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="52.58" y="24.38" width="8.83" height="1.67" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="21.24" y="46.11" width="8.83" height="1.67" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="36.91" y="46.11" width="8.83" height="1.67" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="52.58" y="46.11" width="8.83" height="1.67" />
      <Rect fill="#e6ebef" x="22.85" y="69.07" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="22.28 77.56 25.45 69.07 22.61 69.07 22.28 69.95 22.28 77.56" />
      <Polygon fill="#fff" opacity="0.7" points="26.23 80.12 24.46 80.12 28.59 69.07 30.36 69.07 26.23 80.12" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="22.24" y="68.38" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="23.1 80 23.1 69.28 28.23 69.28" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="24.06" y1="74.52" x2="29.12" y2="74.52" />
      <Rect fill="#e6ebef" x="54.18" y="69.07" width="6.16" height="11.05" />
      <Polygon fill="#fff" opacity="0.7" points="53.6 77.56 56.77 69.07 53.93 69.07 53.6 69.95 53.6 77.56" />
      <Polygon fill="#fff" opacity="0.7" points="57.55 80.12 55.78 80.12 59.91 69.07 61.69 69.07 57.55 80.12" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="53.57" y="68.38" width="6.88" height="11.84" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" points="54.42 80 54.42 69.28 59.55 69.28" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x1="55.38" y1="74.52" x2="60.44" y2="74.52" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="21.24" y="66.71" width="8.83" height="1.67" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="52.58" y="66.71" width="8.83" height="1.67" />
      <Polygon fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="47.67 84.36 32.61 84.36 32.61 86.46 30.43 86.46 30.43 88.48 28.55 88.48 28.55 90.67 31.14 90.67 31.43 90.67 45.76 90.67 45.76 86.69 47.67 86.69 47.67 84.36" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="31.55" y1="88.48" x2="40.43" y2="88.48" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="32.33" y1="86.45" x2="43.69" y2="86.45" />
      <Polygon fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="47.68 86.52 47.68 84.39 45.46 84.39 45.46 86.52 43.68 86.52 43.68 88.48 41.84 88.48 41.84 90.67 44.43 90.67 44.73 90.67 47.69 90.67 47.69 86.52 47.68 86.52" />
      <Rect fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x="37.66" y="69.5" width="2.71" height="7.76" />
      <Rect fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x="42.3" y="69.5" width="2.71" height="7.76" />
      <Path fill="#e6ebef" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" d="M47.1,15.8c-.33-1.15-2.9-2-6-2s-5.69.89-6,2H11.89v3.34H70.28V15.8Z" />
      <Rect fill="#bac2ca" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" x="70.21" y="15.77" width="25.12" height="3.32" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="34.98 77.39 28.58 82.95 28.58 88.48" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" points="47.73 77.39 41.77 82.95 41.77 88.48" />
      <Rect fill="#eff3f6" x="76.45" y="53.06" width="12.82" height="26.84" rx="5.49" />
      <Rect fill="#bac2ca" x="74.16" y="55.02" width="12.82" height="26.84" rx="5.49" />
      <Path fill="#dce2e7" d="M85.7,53.86l.06.42a15.1,15.1,0,0,1,1.37,6.94c0,4.51.72,11.65-1,14s-5.16,3.53-7.65,2.61l-.75-.3a6.41,6.41,0,0,0,11.41-4v-14A6.41,6.41,0,0,0,85.7,53.86Z" />
      <Path fill="#eff3f6" d="M76.45,63V59.47a6.41,6.41,0,0,1,6.41-6.41h0a6.41,6.41,0,0,1,6.41,6.41v14a6.41,6.41,0,0,1-6.41,6.41h0a6.41,6.41,0,0,1-6.41-6.41V61.71" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.45px" d="M76.45,64.72V59.47a6.41,6.41,0,0,1,6.41-6.41h0a6.41,6.41,0,0,1,6.41,6.41v14a6.41,6.41,0,0,1-6.41,6.41h0a6.41,6.41,0,0,1-6.41-6.41V67.25" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.59px" x1="82.81" y1="61.6" x2="82.81" y2="90.49" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.59px" x1="80.07" y1="64.6" x2="82.73" y2="66.91" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.59px" x1="80.07" y1="71.52" x2="82.73" y2="73.83" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.59px" x1="85.56" y1="68.42" x2="82.9" y2="70.74" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" d="M81.46,54.8a5.11,5.11,0,0,0-3.31,4.71" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.3px" x1="78.15" y1="60.5" x2="78.15" y2="61.32" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.59px" d="M10,90.46v-33a3,3,0,0,0-3-2.94h0a3,3,0,0,0-2.95,2.94v.24" />
      <Rect fill="#fff" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.59px" x="2.83" y="57.64" width="2.53" height="3.52" rx="1.08" transform="translate(8.19 118.79) rotate(-180)" />
    </Svg>
  );
}
