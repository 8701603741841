import React, { Fragment } from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../base_ui';
import { SecondaryHeader } from '../../ScreenHeaders';
import { FooterSummary } from '../Shared/FooterSummary';
import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';
import { HomeH1 } from './styledComponents';

const Warning = iconDictionary('warning');

const ErrorView = ({
  footerProps,
  headerProps,
  messageProps,
  navigation,
}) => (
  <Fragment>
    <ConditionalRender
      Component={SecondaryHeader}
      FallbackComponent={<HomeH1>Calculators</HomeH1>}
      propsToPassDown={{
        handleBack: () => navigation.goBack(),
        ...headerProps,
      }}
      shouldRender={!!headerProps}
    />
    <Message
      bodySize="small"
      icon={Warning}
      {...messageProps}
    />
    <ConditionalRender
      Component={FooterSummary}
      propsToPassDown={{ ...footerProps }}
      shouldRender={!!footerProps}
    />
  </Fragment>
);

ErrorView.propTypes = {
  footerProps: T.object,
  headerProps: T.shape({ title: T.string.isRequired }),
  messageProps: T.shape({ title: T.string.isRequired }).isRequired,
  navigation: T.object.isRequired,
};

export default ErrorView;
