import React, { Fragment } from 'react';
import T from 'prop-types';

import { SecondaryHeader } from '../../../ScreenHeaders';
import AsyncRender from '../../../AsyncRender';
import Form from './Form';
import Skeleton from './Skeleton';

const NotificationsForm = ({
  error,
  fetchAlertsLoading,
  fetchErrorCopy,
  navigation,
  ...restProps
}) => (
  <Fragment>
    <SecondaryHeader
      handleBack={error ? undefined : () => navigation.goBack()}
      title={error ? 'Error' : 'Text and Email Notifications'}
    />
    <AsyncRender
      Component={Form}
      error={!!error}
      errorComponentProps={{
        ...fetchErrorCopy,
        link: { label: 'Return to Settings', path: 'Settings' },
      }}
      loading={fetchAlertsLoading}
      LoadingComponent={Skeleton}
      propsToPassDown={{ navigation, ...restProps }}
    />
  </Fragment>
);

NotificationsForm.propTypes = {
  error: T.oneOfType([T.bool, T.string]).isRequired,
  fetchAlertsLoading: T.bool.isRequired,
  fetchErrorCopy: T.object.isRequired,
  navigation: T.object.isRequired,
};

export default NotificationsForm;
