import React from 'react';
import T from 'prop-types';
import { Pressable, View } from 'react-native';

import iconDictionary from '../../../../../utils/iconDictionary';
import { moderateScale } from '../../../../../utils/scaleHelpers';
import { OutlinedDropDown } from '../../../../base_ui';
import { Container, DropDownLabel, LabelContainer } from './styledComponents';

const InfoIcon = iconDictionary('infoTooltipSIcon');

const NumberPaymentsRow = ({
  handleNumPaymentsUpdate,
  handleOpen,
  menuItems,
  numberOfPayments,
}) => (
  <Container>
    <LabelContainer>
      <DropDownLabel id="numberOfPayments">Number of Monthly Payments</DropDownLabel>
      <Pressable
        onPress={() => handleOpen('numberOfMonthlyPayments')}
        style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
      >
        {InfoIcon}
      </Pressable>
    </LabelContainer>
    <View>
      <OutlinedDropDown
        accessibilityName="Number-of-payments"
        menuItems={menuItems}
        onChange={handleNumPaymentsUpdate}
        selected={numberOfPayments}
        style={{
          inputAndroidContainer: {
            height: moderateScale(32),
            marginLeft: moderateScale(24),
            width: moderateScale(80),
          },
          viewContainer: {
            height: moderateScale(32),
            marginLeft: moderateScale(24),
            width: moderateScale(80),
          },
        }}
      />
    </View>
  </Container>
);

NumberPaymentsRow.propTypes = {
  handleNumPaymentsUpdate: T.func.isRequired,
  handleOpen: T.func.isRequired,
  menuItems: T.array.isRequired,
  numberOfPayments: T.number.isRequired,
};

export default NumberPaymentsRow;
