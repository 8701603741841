import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { autopayEnable, change, setIsModalOpen } from '@dmi/shared/redux/Payments/Autopay/actions';
import { AUTOPAY_MODAL_DICTIONARY } from '@dmi/shared/redux/Payments/Autopay/constants';
import makeSelectMain, { getMainClientInfo } from '@dmi/shared/redux/Main/selectors';
import makeSelectAutopay, {
  selectAutopayFlow,
  selectIsModalOpen,
  selectPaymentAmount,
  selectRequestBody,
  selectStatusProp,
  selectSummaryScheduledDateString,
} from '@dmi/shared/redux/Payments/Autopay/selectors';
import { interpolate } from '@dmi/shared/utils/globalHelpers';

import DisclosureScreen from '../../../components/Payments/Disclosure';

const Disclosure = ({
  changeStatus,
  clientName,
  customerServicePhoneNumber,
  dispatchAutopayEnable,
  dispatchChange,
  dispatchSetIsModalOpen,
  enableStatus,
  flow,
  isInAutopayFlow,
  isModalOpen,
  loanNumber,
  navigation,
  paymentAmount,
  requestBody: body,
  summaryScheduledDateString,
}) => {
  const { label, text, title } = flow === 'change' ? AUTOPAY_MODAL_DICTIONARY[flow] : {};
  useEffect(() => {
    if (!isInAutopayFlow) {
      navigation.navigate('Payments');
    }
  }, [isInAutopayFlow, navigation]);

  const editModalProps = {
    handleCloseModal: () => dispatchSetIsModalOpen({ key: 'change', value: false }),
    handleOnPress: () => dispatchChange(body),
    isOpen: isModalOpen,
    label,
    status: changeStatus,
    text: text && interpolate(text, { customerServicePhoneNumber }),
    title,
  };

  const handlePress = () => {
    if (flow === 'change') {
      dispatchSetIsModalOpen({ key: 'change', value: true });
    } else {
      dispatchAutopayEnable({ body, loanNumber });
    }
  };

  return (
    <DisclosureScreen
      clientName={clientName}
      modalProps={editModalProps}
      navigation={navigation}
      summaryProps={{
        error: enableStatus === 'failed',
        hasProgressButton: true,
        label: 'Accept Terms',
        loading: enableStatus === 'loading',
        onPress: handlePress,
        subText: summaryScheduledDateString,
        success: enableStatus === 'succeeded',
        text: paymentAmount,
      }}
      type="autopay"
    />
  );
};

Disclosure.propTypes = {
  changeStatus: T.string.isRequired,
  clientName: T.string.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchAutopayEnable: T.func.isRequired,
  dispatchChange: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  enableStatus: T.string.isRequired,
  flow: T.string.isRequired,
  isInAutopayFlow: T.bool.isRequired,
  isModalOpen: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  navigation: T.object.isRequired,
  paymentAmount: T.string.isRequired,
  requestBody: T.object.isRequired,
  summaryScheduledDateString: T.string.isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  clientName: getMainClientInfo('clientName'),
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  loanNumber: makeSelectMain('selectedLoan'),
  /**
   * Store: Autopay
   */
  changeStatus: selectStatusProp('change'),
  enableStatus: selectStatusProp('enable'),
  flow: selectAutopayFlow(),
  isInAutopayFlow: makeSelectAutopay('isInAutopayFlow'),
  isModalOpen: selectIsModalOpen('change'),
  paymentAmount: selectPaymentAmount(),
  requestBody: selectRequestBody(),
  summaryScheduledDateString: selectSummaryScheduledDateString(),
});

/* eslint-disable object-curly-newline */
const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchAutopayEnable: (payload) => dispatch(autopayEnable(payload)),
  dispatchChange: (requestBody) => dispatch(change({ navigationType: 'replace', requestBody })),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Disclosure);
