import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';

import {
  AddEscrowOptionsTextWrapper,
  AddEscrowOptionsWrapper,
  StyledH3,
  StyledIconButton,
  StyledP3,
} from '../styledComponents';

const CheckedIcon = iconDictionary('approved');
const EmptyIcon = iconDictionary('unapproved');

const EscrowOption = ({
  addEscrowOption,
  description,
  optionHeader,
  optionPressHandler,
}) => (
  <AddEscrowOptionsWrapper>
    <StyledIconButton
      aria-label={`${optionHeader} radio button`}
      Icon={optionHeader === addEscrowOption ? CheckedIcon : EmptyIcon}
      onPress={() => optionPressHandler(optionHeader)}
    />
    <AddEscrowOptionsTextWrapper>
      <StyledH3>{optionHeader}</StyledH3>
      <StyledP3>{description}</StyledP3>
    </AddEscrowOptionsTextWrapper>
  </AddEscrowOptionsWrapper>
);

EscrowOption.propTypes = {
  addEscrowOption: T.string.isRequired,
  description: T.string.isRequired,
  optionHeader: T.string.isRequired,
  optionPressHandler: T.func.isRequired,
};

export default EscrowOption;
