import React, {
  Fragment,
  useEffect,
} from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { oauthLogin } from '@dmi/shared/redux/Main/actions';
import { getError } from '@dmi/shared/redux/Main/selectors';
import { getQueryParam } from '@dmi/shared/utils/globalHelpers';

import LoadingDog from '../../../components/LoadingDog';
import AsyncRender from '../../../components/AsyncRender';
import { StyledScrollView } from '../../../components/base_ui';

const OauthCallbackView = ({
  dispatchOauthLogin,
  error,
}) => {
  useEffect(() => {
    const code = getQueryParam('code');
    dispatchOauthLogin({ code });
  }, [dispatchOauthLogin]);

  return (
    <StyledScrollView>
      <AsyncRender
        Component={() => null}
        error={error}
        errorComponentProps={{
          body: error,
          link: {},
          title: "You've encountered an error.", // Matches same title as Desktop
        }}
        loading
        LoadingComponent={(
          <Fragment>
            <LoadingDog />
          </Fragment>
        )}
      />
    </StyledScrollView>
  );
};

OauthCallbackView.propTypes = {
  dispatchOauthLogin: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  error: getError('main'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Main
   */
  dispatchOauthLogin: (payload) => dispatch(oauthLogin(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(OauthCallbackView);
