/*
* Recaptcha Form Input
*/

import React, { Fragment, useRef } from 'react';
import T from 'prop-types';
import Constants from 'expo-constants';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import { BaseRecaptcha, LargeProgressButton } from '../../base_ui';
import {
  ButtonsContainer,
  RegisterInputContainer,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
} from './styledComponents';

const RecaptchaFormInput = ({
  dispatchChangeInput,
  formValues,
  handleBack,
  handleNextClick,
  handleSubmit,
  status,
}) => {
  const recaptchaRef = useRef(null);

  const handleChange = () => {
    const response = recaptchaRef.current.getValue();
    const { reset: resetRecaptcha } = recaptchaRef.current;
    handleSubmit(2, { resetRecaptcha, response });
  };

  return (
    <Fragment>
      <RegisterInputContainer>
        <BaseRecaptcha
          ref={recaptchaRef}
          onChange={handleChange}
          onExpired={() => dispatchChangeInput({
            field: 'isRecaptchaVerified',
            form: 'initial',
            value: false,
          })}
          sitekey={Constants.expoConfig.extra.RECAPTCHA_SITE_KEY}
        />
      </RegisterInputContainer>
      <StyledPrimaryDivider />
      <ButtonsContainer>
        <LargeProgressButton
          disabled={!formValues.isRecaptchaVerified}
          label="Next Step"
          onPress={() =>
            handleNextClick(
              { handleSubmitStep: () => handleSubmit(1, trimFormValues(formValues)) },
            )}
          status={status.stepOne}
        />
        <StyledBaseInternalLink label="Back" onPressFunc={handleBack} size="medium" />
      </ButtonsContainer>
    </Fragment>
  );
};

RecaptchaFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  status: T.object.isRequired,
};

export default RecaptchaFormInput;
