/* eslint-disable max-len */
import { FORGOT_PASSWORD_CONFIRMATION_DICTIONARY } from '@dmi/shared/redux/Register/ForgotPassword/constants';
import { INTL_IDS } from '@dmi/shared/redux/Register/ForgotPassword/messages';

export const FORGOT_PASSWORD_CARD_PROPS = {
  cardAnimation: {
    mobile: 'registerClipboardMobile',
    web: 'registerClipboardDesktop',
  },
  cardText: INTL_IDS.FORGOT_PASSWORD_FORM_PARAGRAPH,
  cardTitle: INTL_IDS.FORGOT_PASSWORD_FORM_HEADING,
  cardTooltip: false,
};

export const FORGOT_PASSWORD_CONFIRMATION_PROPS = {
  cardAnimation: {
    mobile: 'airplaneMobile',
    web: 'airplaneDesktop',
  },
  cardDictionary: FORGOT_PASSWORD_CONFIRMATION_DICTIONARY,
};

export const RESET_PASSWORD_CARD_PROPS = {
  cardAnimation: {
    mobile: 'registerClipboardMobile',
    web: 'registerClipboardDesktop',
  },
  cardTitle: INTL_IDS.FORGOT_PASSWORD_RESET_PASSWORD_HEADING,
  cardTooltip: true,
};

export const RESET_PASSWORD_CONFIRMATION_PROPS = {
  cardAnimation: {
    mobile: 'passwordLockMobile',
    web: 'passwordLockDesktop',
  },
  cardDictionary: FORGOT_PASSWORD_CONFIRMATION_DICTIONARY,
};
