import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender } from '../../../base_ui';
import BorderlessDocPickerButton from '../BorderlessDocPickerButton';
import {
  Container,
  FileName,
  FileNameWrapper,
  FileWrapper,
  StyledBorderlessButton,
  ValidationErrorText,
} from './styledComponents';

const SuccessIcon = iconDictionary('outlinedCompleteGreen');
const WarningIcon = iconDictionary('paymentWarning');

const FileItem = ({
  dispatchChooseDocument,
  fileName,
  isMultifile = false,
  isSecondFile = false,
  isSecondFileStep = false,
  removeFileHandler,
  renderInlineButton = false,
  validationErrorMessage,
}) => {
  const isNewFileButtonActive =
    (!!validationErrorMessage && !!dispatchChooseDocument) && isSecondFileStep;

  return (
    <Container $multiFile={isMultifile}>
      <FileNameWrapper>
        <FileWrapper $renderDocPicker={isNewFileButtonActive}>
          <ConditionalRender
            Component={SuccessIcon}
            FallbackComponent={WarningIcon}
            shouldRender={!validationErrorMessage}
          />
          <FileName
            $renderDocPicker={isNewFileButtonActive || renderInlineButton}
            ellipsizeMode="middle"
            numberOfLines={1}
          >
            {fileName}
          </FileName>
        </FileWrapper>
        <ConditionalRender
          Component={BorderlessDocPickerButton}
          propsToPassDown={{
            dispatchChooseDocument,
            displayIcon: false,
            isSecondFile,
            label: 'Select New File',
          }}
          shouldRender={isNewFileButtonActive}
        />
        <ConditionalRender
          Component={StyledBorderlessButton}
          propsToPassDown={{
            label: 'Remove',
            onPress: removeFileHandler,
          }}
          shouldRender={!!removeFileHandler}
        />
      </FileNameWrapper>
      <ConditionalRender
        Component={ValidationErrorText}
        propsToPassDown={{ $multiFile: isMultifile, children: validationErrorMessage }}
        shouldRender={!!validationErrorMessage}
      />
    </Container>
  );
};

FileItem.propTypes = {
  dispatchChooseDocument: T.func,
  fileName: T.string.isRequired,
  isMultifile: T.bool,
  isSecondFile: T.bool,
  isSecondFileStep: T.bool,
  removeFileHandler: T.func,
  renderInlineButton: T.bool,
  validationErrorMessage: T.oneOfType([T.bool, T.string]).isRequired,
};

export default FileItem;
