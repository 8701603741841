/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function ChatbotDefaultIcon(props) {
  const { colors: { primary } } = useTheme();
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';

  return (
    <Svg fill="none" height={moderateScale(52)} viewBox="0 0 52 52" width={moderateScale(52)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <G>
          <Path d="M26.2008 46.4001C37.4674 46.4001 46.6008 37.2667 46.6008 26.0001C46.6008 14.7335 37.4674 5.6001 26.2008 5.6001C14.9342 5.6001 5.80078 14.7335 5.80078 26.0001C5.80078 37.2667 14.9342 46.4001 26.2008 46.4001Z" fill={primary} />
          <G>
            <Path d="M26.2004 47.6C14.2004 47.6 4.40039 37.8 4.40039 25.8C4.40039 13.8 14.2004 4 26.2004 4C38.2004 4 47.8004 14 47.8004 26C47.8004 37.8 38.0004 47.6 26.2004 47.6ZM26.2004 6.8C15.6004 6.8 7.00039 15.4 7.00039 26C7.00039 36.6 15.6004 45.2 26.2004 45.2C36.8004 45.2 45.4004 36.6 45.4004 26C45.4004 15.4 36.8004 6.8 26.2004 6.8Z" fill={primary} />
          </G>
        </G>
        <Path d="M13.4004 38.6L16.0004 31.4C15.0004 29.6 14.4004 27.4 14.4004 25.2C14.4004 18.4 19.8004 13 26.6004 13C33.4004 13 38.8004 18.4 38.8004 25.2C38.8004 32 33.4004 37.4 26.6004 37.4C24.6004 37.4 22.8004 37 21.2004 36.2L13.4004 38.6Z" fill="white" />
        <Path d="M32.8008 23.6001H20.8008C20.2008 23.6001 19.8008 23.2001 19.8008 22.6001C19.8008 22.0001 20.2008 21.6001 20.8008 21.6001H32.8008C33.4008 21.6001 33.8008 22.0001 33.8008 22.6001C33.8008 23.2001 33.4008 23.6001 32.8008 23.6001Z" fill={primary} />
        <Path d="M29.2008 28.7998H20.8008C20.2008 28.7998 19.8008 28.3998 19.8008 27.7998C19.8008 27.1998 20.2008 26.7998 20.8008 26.7998H29.4008C30.0008 26.7998 30.4008 27.1998 30.4008 27.7998C30.4008 28.3998 29.8008 28.7998 29.2008 28.7998Z" fill={primary} />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="48" transform="translate(2 2)" width="48" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
