import styled from 'styled-components/native';

import {
  H5,
  IconButton,
  SecondaryCard,
} from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const StyledCard = styled(SecondaryCard)`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: ${moderateScale(16)}px;
  padding: ${moderateScale(20)}px;
`;

export const StyledH5 = styled(H5)`
  margin-bottom: ${moderateScale(8)}px;
`;

export const StyledIconButton = styled(IconButton)`
  margin: auto 0;
  min-height: auto;
  min-width: auto;
`;

export const TextContainer = styled.View`
  flex-shrink: 1;
  margin-left: 12px;
  max-width: ${moderateScale(160)}px;
`;
