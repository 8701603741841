import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H4, P2 } from '../../../base_ui';

export const Heading = styled(H4)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const Text = styled(P2)`
  margin-bottom: ${moderateScale(14)}px;
`;
