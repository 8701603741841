/*
 * RegisterView
 */

import React, { useEffect, useRef } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  changeInputError,
  changeStep,
  registerResendChangeEmail,
  resetInputErrors,
  resetState,
} from '@dmi/shared/redux/Register/Main/actions';
import makeSelectRegister, {
  getRegisterConfirmationPageProps,
  getRegisterCurrentEmail,
  getRegisterError,
  getRegisterForm,
  getRegisterFormErrors,
  getRegisterIsGuided,
  getRegisterLoanNumberQueryParam,
  getRegisterQueryString,
  getRegisterResendChangeEmail,
} from '@dmi/shared/redux/Register/Main/selectors';
import { handleNext } from '@dmi/shared/redux/Register/Shared/helpers';

import AsyncRender from '../../../components/AsyncRender';
import { ConditionalRender, StyledScrollView } from '../../../components/base_ui';
import { GUIDED_ACTION_DICTIONARY, REGISTER_ACTION_DICTIONARY } from './constants';
import { GUIDED_VIEW_DICTIONARY, REGISTER_VIEW_DICTIONARY } from './viewDictionary';

const RegisterView = ({
  bannerError,
  confirmationPageProps,
  currentEmail,
  dispatchChangeEmail,
  dispatchChangeInput,
  dispatchChangeInputError,
  dispatchChangeStep,
  dispatchRegisterAction,
  dispatchResetInputErrors,
  dispatchResetState,
  formErrors,
  formValues,
  isChangeEmail,
  isGuidedRegistration,
  linkQueryString,
  loanNumberQueryParam,
  navigation,
  status,
  step,
}) => {
  const scrollViewRef = useRef();
  const { resetStep } = confirmationPageProps;
  const actionDictionary = isGuidedRegistration ?
    GUIDED_ACTION_DICTIONARY :
    REGISTER_ACTION_DICTIONARY;
  const viewDictionary = isGuidedRegistration ?
    GUIDED_VIEW_DICTIONARY :
    REGISTER_VIEW_DICTIONARY;
  const {
    viewCardProps,
    ViewComponent,
    ViewFormInput,
  } = viewDictionary[step] || {};

  useEffect(() => {
    if (isGuidedRegistration && step === 1) {
      dispatchRegisterAction(actionDictionary[1], linkQueryString);
    }
  }, [
    actionDictionary,
    dispatchRegisterAction,
    isGuidedRegistration,
    linkQueryString,
    step,
  ]);

  const handleBack = () => {
    if (step === 1 || step === 9) {
      dispatchResetState();
      navigation.replace('Login');
    } else {
      dispatchChangeStep(step - 1);
    }
  };

  const handlePress = (path) => {
    if (path === 'Login') {
      navigation.replace(path);
    }
    dispatchChangeStep(resetStep);
  };

  const handleNextClick = ({
    field,
    form,
    formStep,
    formValues: values,
    handleSubmitStep,
    skipStep,
  }) => {
    handleNext({
      dispatchError: dispatchChangeInputError,
      dispatchNext: dispatchChangeStep,
      dispatchResetInputErrors,
      field,
      form,
      formStep,
      formValues: values,
      handleSubmitStep,
      skipStep,
      step,
    });
  };

  const handleScrollToBanner = () => {
    scrollViewRef.current.scrollTo({ animated: true, x: 0, y: 0 });
  };

  const handleSubmit = (registerStep, payload) => {
    const action = actionDictionary[registerStep];
    dispatchRegisterAction(action, payload);
  };

  const formInputProps = {
    dispatchChangeInput,
    formErrors,
    formValues,
    handleBack,
    handleNextClick,
    handleSubmit,
    isChangeEmail,
    isGuidedRegistration,
    loanNumberQueryParam,
    navigation,
    status,
    step,
  };

  const propsToPassDown = {
    bannerError,
    currentEmail,
    formInputProps,
    handleChangeEmail: dispatchChangeEmail,
    handlePress, // used at least by Confirmation error screens
    handleScrollToBanner,
    isChangeEmail,
    viewCardProps,
    ViewFormInput,
    ...confirmationPageProps,
  };

  return (
    <StyledScrollView
      ref={scrollViewRef}
      scrollToOverflowEnabled
    >
      <ConditionalRender
        Component={(
          <AsyncRender
            Component={ViewComponent}
            error={false}
            loading={status.guidedStepThree === 'loading'}
            propsToPassDown={propsToPassDown}
          />
        )}
        FallbackComponent={(
          <ViewComponent {...propsToPassDown} />
        )}
        shouldRender={isGuidedRegistration && step === 1}
      />
    </StyledScrollView>
  );
};

RegisterView.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  confirmationPageProps: T.object.isRequired,
  currentEmail: T.string.isRequired,
  dispatchChangeEmail: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchChangeInputError: T.func.isRequired,
  dispatchChangeStep: T.func.isRequired,
  dispatchRegisterAction: T.func.isRequired,
  dispatchResetInputErrors: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  formErrors: T.object,
  formValues: T.object,
  isChangeEmail: T.bool.isRequired,
  isGuidedRegistration: T.bool.isRequired,
  linkQueryString: T.string,
  loanNumberQueryParam: T.string,
  navigation: T.object.isRequired,
  status: T.object.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Register
   */
  bannerError: getRegisterError('bannerError'),
  confirmationPageProps: getRegisterConfirmationPageProps(),
  currentEmail: getRegisterCurrentEmail(),
  formErrors: getRegisterFormErrors(),
  formValues: getRegisterForm(),
  isChangeEmail: getRegisterResendChangeEmail(),
  isGuidedRegistration: getRegisterIsGuided(),
  linkQueryString: getRegisterQueryString(),
  loanNumberQueryParam: getRegisterLoanNumberQueryParam(),
  status: makeSelectRegister('status'),
  step: makeSelectRegister('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Register
   */
  dispatchChangeEmail: () => dispatch(registerResendChangeEmail()),
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchChangeInputError: (payload) => dispatch(changeInputError(payload)),
  dispatchChangeStep: (payload) => dispatch(changeStep(payload)),
  dispatchRegisterAction: (registrationAction, payload) => dispatch(registrationAction(payload)),
  dispatchResetInputErrors: (payload) => dispatch(resetInputErrors(payload)),
  dispatchResetState: () => dispatch(resetState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(RegisterView);
