import { moderateScale } from './scaleHelpers';

export const fontSizes = Object.freeze({
  f1: moderateScale(9),
  f2: moderateScale(10),
  f3: moderateScale(12),
  f4: moderateScale(14),
  f5: moderateScale(16),
  f6: moderateScale(18),
  f7: moderateScale(20),
  f8: moderateScale(24),
  f9: moderateScale(32),
});

export default { fontSizes };
