import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  resetFormState,
  resetRequestStatus,
  resetState,
  resetStep,
} from '@dmi/shared/redux/LossDraft/actions';
import makeSelectLossDraft,
{ selectLossDraftScreenReaderNotifications }
from '@dmi/shared/redux/LossDraft/selectors';

import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';

import viewDictionary from './viewDictionary';

const NewMessage = ({
  dispatchResetFormState,
  dispatchResetRequestStatus,
  dispatchResetState,
  dispatchResetStep,
  navigation,
  notifications,
  step,
}) => {
  const ComponentToRender = viewDictionary[step];

  const handleResetState = () => {
    dispatchResetRequestStatus({ requestStatus: 'postMessage' });
    dispatchResetState({ stateToReset: 'errors' });
    dispatchResetState({ stateToReset: 'formError' });
    dispatchResetState({ stateToReset: 'forms' });
    dispatchResetState({ stateToReset: 'selectedClaimNumber' });
    dispatchResetFormState({ form: 'message' });
    dispatchResetStep();
  };

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <ComponentToRender handleResetState={handleResetState} navigation={navigation} />
    </Fragment>
  );
};

NewMessage.propTypes = {
  dispatchResetFormState: T.func.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  dispatchResetStep: T.func.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  step: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loss Draft
   */
  notifications: selectLossDraftScreenReaderNotifications(),
  step: makeSelectLossDraft('step'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loss Draft
   */
  dispatchResetFormState: (payload) => dispatch(resetFormState(payload)),
  dispatchResetRequestStatus: (payload) => dispatch(resetRequestStatus(payload)),
  dispatchResetState: (payload) => dispatch(resetState(payload)),
  dispatchResetStep: () => dispatch(resetStep()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(NewMessage);
