import styled from 'styled-components/native';

import { H4 } from '../../Heading';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const BannerContainer = styled.View`
  border-bottom-width: 1px;
  border-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  flex-direction: row;
  padding: ${moderateScale(16)}px;
`;

export const IllustrationWrapper = styled.View`
  justify-content: center;
  margin-right: ${moderateScale(15)}px;
`;

export const MessageContainer = styled.View`
  flex: 1;
`;

export const Title = styled(H4)`
  margin-bottom: ${moderateScale(2)}px;
`;
