import styled from 'styled-components/native';

import { moderateScale } from '../../utils/scaleHelpers';
import { BaseExternalLink, BaseInternalLink, H3 } from '../base_ui';

// Used by SiteMap

export const Panel = styled.View`
  ${({ $isTablet }) => $isTablet ? 'flex: 1' : 'flex-grow: 1'};
`;

export const PanelContainer = styled.View`
  flex-direction: ${({ $isTablet }) => $isTablet ? 'row' : 'column'};
  margin: ${moderateScale(29)}px 0 ${moderateScale(40)}px;;
  padding-horizontal: ${moderateScale(24)}px;
`;

// Used by MapSection

export const SectionContainer = styled.View`
  margin-bottom: ${moderateScale(28)}px;
`;

export const StyledExternalLink = styled(BaseExternalLink)`
  padding-vertical: ${moderateScale(5)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  padding-vertical: ${moderateScale(5)}px;
`;

export const Subheading = styled(H3)`
  margin-left: ${moderateScale(8)};
`;

export const SubheadingWrapper = styled.View`
  align-items: center;
  display: flex;
  flex-direction: row;
  margin-bottom: ${moderateScale(8)};
`;
