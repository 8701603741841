import React from 'react';
import T from 'prop-types';

import {
  CONSTRUCTION_LOAN_DISCLAIMER_DICT,
  HELOC_DISCLAIMER_DICT,
} from '@dmi/shared/redux/Mortgage/constants';

import { ConditionalRender } from '../../base_ui';
import InfoSection from '../Shared/InfoSection';
import { MortgageDetailsRows } from './styledComponents';
import MultipleMortgageRows from './MultipleMortgageRows';

const MultipleMortgageDetails = ({
  detailsData: [principalBalance, ...restDetailsData],
  dispatchSetSelectedMortgageLoan,
  loanDetails: {
    loans,
    shouldRenderConstructionLoanDisclaimer,
    shouldRenderHelocDisclaimer,
  },
  navigation,
}) => (
  <MortgageDetailsRows>
    <MultipleMortgageRows
      balance={principalBalance.value}
      details={restDetailsData}
      subheader={principalBalance.label}
    />
    {loans.map(({ details, subheader, subheaderValue }) => (
      <ConditionalRender
        key={subheader}
        Component={MultipleMortgageRows}
        propsToPassDown={{
          balance: subheaderValue,
          details,
          dispatchSetSelectedMortgageLoan,
          navigation,
          subheader,
        }}
        shouldRender={!!details}
      />
    ))}
    <ConditionalRender
      Component={InfoSection}
      propsToPassDown={{ data: CONSTRUCTION_LOAN_DISCLAIMER_DICT }}
      shouldRender={shouldRenderConstructionLoanDisclaimer}
    />
    <ConditionalRender
      Component={InfoSection}
      propsToPassDown={{ data: HELOC_DISCLAIMER_DICT }}
      shouldRender={shouldRenderHelocDisclaimer}
    />
  </MortgageDetailsRows>
);

MultipleMortgageDetails.propTypes = {
  detailsData: T.arrayOf(T.object).isRequired,
  dispatchSetSelectedMortgageLoan: T.func,
  loanDetails: T.shape({
    hasOtherDetails: T.bool.isRequired,
    loans: T.array,
    shouldRenderConstructionLoanDisclaimer: T.bool.isRequired,
    shouldRenderHelocDisclaimer: T.bool.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
};

export default MultipleMortgageDetails;
