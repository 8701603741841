import styled from 'styled-components/native';

import { FormattedMessageStatusBanner, PrimaryDivider } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BoldText = styled.Text`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ContentContainer = styled.View`
  flex-grow: 1;
`;

export const PhoneNumberWrapper = styled.Text`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledFormattedMessageStatusBanner = styled(FormattedMessageStatusBanner)`
  margin: ${moderateScale(12)}px ${moderateScale(24)}px -${moderateScale(6)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
