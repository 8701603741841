import React, { Fragment } from 'react';
import T from 'prop-types';
import { Text, View } from 'react-native';
import { FormattedMessage, useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Payments/messages';
import { MAKE_PAYMENT_NO_FEE } from '@dmi/shared/utils/constants';

import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../ScreenHeaders';
import { ConditionalRender, StyledScrollView, TertiaryDivider } from '../../base_ui';
import {
  BottomHeader,
  Card,
  Container,
  IllustrationWrapper,
  ListItem,
  StyledButton,
  StyledNoFeeCopy,
  TopHeader,
} from './styledComponents';

const SplashIllustration = iconDictionary('marketing');

const MakePaymentSplash = ({ isEligibleForOtp, navigation }) => {
  const { formatMessage } = useIntl();

  return (
    <StyledScrollView>
      <SecondaryHeader
        handleBack={() => navigation.navigate('Payments')}
        title="Make a Payment"
      />
      <Container>
        <StyledNoFeeCopy>{MAKE_PAYMENT_NO_FEE}</StyledNoFeeCopy>
        <Card>
          <IllustrationWrapper>{SplashIllustration}</IllustrationWrapper>
          <TopHeader>
            <FormattedMessage id={INTL_IDS.SPLASHPAGE_AUTOPAY_TITLE} />
          </TopHeader>
          <View>
            <ListItem>
              <Text>{'\u2022  '}</Text>
              <Text>
                <FormattedMessage id={INTL_IDS.SPLASHPAGE_AUTOPAY_TEXT_MORTGAGEPAYMENTS} />
              </Text>
            </ListItem>
            <ListItem>
              <Text>{'\u2022  '}</Text>
              <Text>
                <FormattedMessage id={INTL_IDS.SPLASHPAGE_AUTOPAY_TEXT_ELIMINATESRISKS} />
              </Text>
            </ListItem>
            <ListItem>
              <Text>{'\u2022  '}</Text>
              <Text>
                <FormattedMessage id={INTL_IDS.SPLASHPAGE_AUTOPAY_TEXT_ABILITYTOREMIT} />
              </Text>
            </ListItem>
            <ListItem>
              <Text>{'\u2022  '}</Text>
              <Text>
                <FormattedMessage id={INTL_IDS.SPLASHPAGE_AUTOPAY_TEXT_ENVIRONMENTALLYFRIENDLY} />
              </Text>
            </ListItem>
          </View>
          <StyledButton
            label={formatMessage({ id: INTL_IDS.SPLASHPAGE_AUTOPAY_BUTTON_LABEL })}
            onPress={() => navigation.replace('AutopayPreConfirmation')}
          />
        </Card>
        <ConditionalRender
          Component={(
            <Fragment>
              <TertiaryDivider />
              <Card>
                <BottomHeader>I am not interested in setting up Autopay today</BottomHeader>
                <StyledButton
                  label="Make a One-Time Payment"
                  onPress={() => navigation.replace('OtpPreConfirmation')}
                />
              </Card>
            </Fragment>
          )}
          shouldRender={isEligibleForOtp}
        />
      </Container>
    </StyledScrollView>
  );
};

MakePaymentSplash.propTypes = {
  isEligibleForOtp: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default MakePaymentSplash;
