import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { BorderlessButton } from '../../../base_ui';

export const StyledBorderlessButton = styled(BorderlessButton).attrs(({ // eslint-disable-line object-curly-newline, max-len
  theme: { colors: { linkText } },
}) => ({
  titleStyle: [{
    color: linkText,
    padding: moderateScale(5),
  }],
}))`
  align-self: center;
  font-size: ${({ theme: { fontSizes: { f4 } } }) => f4}px;
  padding: ${moderateScale(5)}px;
`;
