import React, {
  useCallback,
  useState,
} from 'react';
import { useFocusEffect } from '@react-navigation/core';
import { Keyboard } from 'react-native';
import { useIntl } from 'react-intl';
import T from 'prop-types';

import {
  ADJUSTMENT_FILTER_DICTIONARY,
  SLIDER_LABELS,
} from '@dmi/shared/redux/Calculators/Amortize/constants';
import { INTL_IDS } from '@dmi/shared/redux/Calculators/Amortize/messages';
import { validate } from '@dmi/shared/utils/validate';
import { convertCurrencyStrToNum } from '@dmi/shared/utils/globalHelpers';

import AsyncRender from '../../AsyncRender';
import {
  ConditionalRender,
  H3,
  Slider,
  StyledScrollView,
  Tooltip,
} from '../../base_ui';
import { getReactMessages } from '../../base_ui/FormattedMessage/helpers';
import { SecondaryHeader } from '../../ScreenHeaders';
import CalculatorDisclaimer from '../Shared/CalculatorDisclaimer';
import Filter from '../Shared/Filter';
import Results from '../Shared/Results';
import ResultsError from '../Shared/Results/Error';
import TooltipContent from '../Shared/TooltipContent';
import {
  BannerContainer,
  ContentContainer,
  HeaderContainer,
  InputLabel,
  PaymentTextFieldContainer,
  StyledPaymentTextField,
  TooltipButton,
} from './styledComponents';
import iconDictionary from '../../../utils/iconDictionary';

const InfoIcon = iconDictionary('infoTooltipSIcon');

const PaymentAdjustment = ({
  dispatchAmortizeCalculate,
  dispatchChangeInput,
  dispatchResetCalculatorResults,
  dispatchResetFormError,
  dispatchSetIsSliderFocused,
  dispatchUpdateFormError,
  formErrors,
  formValues: { amount, resultAmount },
  handleChangeAdjustmentFilter,
  handleDownloadCSV,
  isAmortizationScheduleCalculated,
  isCalculatorDataValid,
  isFormValidated,
  isSliderFocused,
  loanNumber,
  maximumPaymentAmount,
  navigation,
  requestBody,
  resultsData,
  resultsSummaryBarProps,
  selectedAdjustmentFilter,
  status: {
    fetchStatus,
    payLumpSum: payLumpSumStatus,
    payMoreMonthly: payMoreMonthlyStatus,
  },
}) => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const { formatMessage } = useIntl();

  const sliderValueRange = selectedAdjustmentFilter === 'payLumpSum' ? [0, 6000] : [0, 300];
  const sliderMarks = selectedAdjustmentFilter === 'payLumpSum'
    ? SLIDER_LABELS.lumpSum
    : SLIDER_LABELS.payMoreMonthly;
  const { errorText, headerText } = ADJUSTMENT_FILTER_DICTIONARY[selectedAdjustmentFilter];
  const calculateStatus = selectedAdjustmentFilter === 'payLumpSum' ?
    payLumpSumStatus : payMoreMonthlyStatus;
  const isLoading = calculateStatus === 'loading' || fetchStatus === 'loading';

  const handleAmountValidation = useCallback((value) => {
    const { message } = validate({
      maxEqual: maximumPaymentAmount,
      type: 'currencyOnChange',
      value: value || '0',
    });
    if (message) {
      dispatchUpdateFormError({
        field: 'amount',
        form: selectedAdjustmentFilter,
        value: message,
      });
    } else {
      dispatchResetFormError({
        field: 'amount',
        form: selectedAdjustmentFilter,
      });
    }
  }, [
    dispatchResetFormError,
    dispatchUpdateFormError,
    maximumPaymentAmount,
    selectedAdjustmentFilter,
  ]);

  useFocusEffect(
    useCallback(() => {
      handleAmountValidation(amount);
    }, [amount, handleAmountValidation]),
  );

  const errorToRender = formErrors.amount ?
    getReactMessages(formErrors.amount, { selectedAdjustment: errorText }) :
    '';

  const handleCalculate = (newValue) => {
    if (isFormValidated) {
      if (Number(newValue) > 0) {
        const bodyToPass = {
          ...requestBody,
          Additional_Payment: {
            ...requestBody.Additional_Payment,
            Payment_Amount: newValue,
          },
        };
        dispatchAmortizeCalculate({
          amount: newValue,
          calculateStatus: selectedAdjustmentFilter,
          loanNumber,
          requestBody: bodyToPass,
        });
      } else {
        dispatchResetCalculatorResults(selectedAdjustmentFilter);
      }
    }
  };

  const handleOnChangeAmount = useCallback((newValue) => {
    const isValidNumber = !Number.isNaN(Number(newValue));
    if (isValidNumber) {
      handleAmountValidation(newValue);
      if (isSliderFocused) {
        dispatchChangeInput({
          field: 'amount',
          form: selectedAdjustmentFilter,
          value: newValue,
        });
      } else if (!isSliderFocused && newValue !== '0') {
        dispatchChangeInput({
          field: 'amount',
          form: selectedAdjustmentFilter,
          value: newValue,
        });
      }
    }
  }, [
    dispatchChangeInput,
    handleAmountValidation,
    isSliderFocused,
    selectedAdjustmentFilter,
  ]);

  const handleOnBlurCalculate = () => {
    if (!formErrors.amount) {
      const newValue = convertCurrencyStrToNum(amount).toFixed(2);
      dispatchChangeInput({
        field: 'amount',
        form: selectedAdjustmentFilter,
        value: newValue,
      });
      handleCalculate(newValue);
    }
  };

  const handleOnFocusAmountInput = () => {
    if (isSliderFocused) {
      dispatchSetIsSliderFocused({
        form: selectedAdjustmentFilter,
        value: false,
      });
    }
    dispatchChangeInput({
      field: 'amount',
      form: selectedAdjustmentFilter,
      value: '',
    });
  };

  const handleOnFocusAmountSlider = () => {
    if (!isSliderFocused) {
      dispatchSetIsSliderFocused({
        form: selectedAdjustmentFilter,
        value: true,
      });
      dispatchChangeInput({
        field: 'amount',
        form: selectedAdjustmentFilter,
        value: '0',
      });
    }
    Keyboard.dismiss();
  };

  const resultsPropsToPassDown = {
    amount: resultAmount,
    handleDownloadCSV,
    isAmortizationScheduleCalculated,
    isLoading,
    navigation,
    resultsData,
    resultsSummaryBarProps,
    selectedAdjustmentFilter,
  };

  return (
    <StyledScrollView>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title="Explore Payment Adjustments"
      />
      <Filter
        handleChangeAdjustmentFilter={handleChangeAdjustmentFilter}
        selectedAdjustmentFilter={selectedAdjustmentFilter}
      />
      <ContentContainer>
        <BannerContainer>
          <CalculatorDisclaimer
            disclaimer={formatMessage({ id: INTL_IDS.CALCULATOR_BANNER_DISCLAIMER })}
          />
        </BannerContainer>
        <HeaderContainer>
          <H3>{headerText}</H3>
          <TooltipButton
            $pressed={isPressed}
            onPress={() => setIsTooltipOpen(true)}
            onPressIn={() => setIsPressed(true)}
            onPressOut={() => setIsPressed(false)}
          >
            {InfoIcon}
          </TooltipButton>
        </HeaderContainer>
        <Slider
          key={selectedAdjustmentFilter}
          aria-label="Amount Slider"
          aria-valuenow={isSliderFocused ? Number(amount) : 0}
          marks={sliderMarks}
          max={sliderValueRange[1]}
          min={sliderValueRange[0]}
          onChange={(value) => handleOnChangeAmount(value.toString())}
          onChangeCommitted={(value) => handleCalculate(value.toString())}
          onSlidingStart={handleOnFocusAmountSlider}
          value={isSliderFocused ? Number(amount) : 0}
        />
        <PaymentTextFieldContainer>
          <InputLabel>Custom Amount</InputLabel>
          <StyledPaymentTextField
            aria-label="Custom Amount"
            errorMessage={errorToRender}
            hasFluidWidth
            keyboardType="decimal-pad"
            label="Custom Amount"
            onBlurSideEffects={handleOnBlurCalculate}
            onChangeText={(newValue) => handleOnChangeAmount(newValue)}
            onFocusSideEffects={handleOnFocusAmountInput}
            rendersCheckbox={false}
            value={isSliderFocused ? '' : amount}
          />
        </PaymentTextFieldContainer>
        <AsyncRender
          Component={Results}
          CustomErrorComponent={ResultsError}
          error={calculateStatus === 'failed' || !isCalculatorDataValid}
          loading={isLoading}
          LoadingComponent={Results}
          loadingComponentProps={{ ...resultsPropsToPassDown }}
          propsToPassDown={{ ...resultsPropsToPassDown }}
        />
      </ContentContainer>
      <ConditionalRender
        Component={(
          <Tooltip
            handleClose={() => setIsTooltipOpen(false)}
            positionAbsolute={{ left: 'auto', top: 'auto' }}
            visible={isTooltipOpen}
          >
            <TooltipContent
              handleClose={() => setIsTooltipOpen(false)}
              selectedAdjustmentFilter={selectedAdjustmentFilter}
            />
          </Tooltip>
        )}
        shouldRender={isTooltipOpen}
      />
    </StyledScrollView>
  );
};

PaymentAdjustment.propTypes = {
  dispatchAmortizeCalculate: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetCalculatorResults: T.func.isRequired,
  dispatchResetFormError: T.func.isRequired,
  dispatchSetIsSliderFocused: T.func.isRequired,
  dispatchUpdateFormError: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleChangeAdjustmentFilter: T.func.isRequired,
  handleDownloadCSV: T.func.isRequired,
  isAmortizationScheduleCalculated: T.bool.isRequired,
  isCalculatorDataValid: T.bool.isRequired,
  isFormValidated: T.bool.isRequired,
  isSliderFocused: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  maximumPaymentAmount: T.number.isRequired,
  navigation: T.object.isRequired,
  requestBody: T.object.isRequired,
  resultsData: T.array.isRequired,
  resultsSummaryBarProps: T.object.isRequired,
  selectedAdjustmentFilter: T.string.isRequired,
  status: T.object.isRequired,
};

export default PaymentAdjustment;
