import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

export const ErrorContainer = styled.View`
  bottom: ${moderateScale(85)}px;
  flex-grow: 1;
  margin-top: ${moderateScale(24)}px;
  min-height: 100%;
`;
