import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';

import { ConditionalRender, SecondaryDivider } from '../../../base_ui';
import MortgageCardSection from './MortgageCardSection';
import HeaderSection from './HeaderSection';
import MortgageOverview from './MortgageOverview';
import { HomeContainer, HomeH1 } from './styledComponents';
import TaxesAndInsuranceOverview from './TaxesAndInsuranceOverview';

const MortgageHome = ({
  dispatchSetNestedScreen,
  dispatchUpdateSelectedInsurance,
  escrow,
  expiredPolicies,
  hasEscrow,
  headerView,
  marketingCards,
  mortgageOverviewData,
  navigation,
}) => (
  <HomeContainer>
    <HomeH1>
      <FormattedMessage id={INTL_IDS.MORTGAGE_HOME_TITLE} />
    </HomeH1>
    <HeaderSection
      dispatchUpdateSelectedInsurance={dispatchUpdateSelectedInsurance}
      expiredPolicies={expiredPolicies}
      headerView={headerView}
      navigation={navigation}
    />
    <SecondaryDivider />
    <MortgageOverview {...mortgageOverviewData} />
    <ConditionalRender
      Component={TaxesAndInsuranceOverview}
      propsToPassDown={{ data: escrow }}
      shouldRender={hasEscrow}
    />
    <MortgageCardSection
      dispatchSetNestedScreen={dispatchSetNestedScreen}
      marketingCards={marketingCards}
      navigation={navigation}
    />
  </HomeContainer>
);

MortgageHome.propTypes = {
  dispatchSetNestedScreen: T.func.isRequired,
  dispatchUpdateSelectedInsurance: T.func.isRequired,
  escrow: T.array.isRequired,
  expiredPolicies: T.array.isRequired,
  hasEscrow: T.bool.isRequired,
  headerView: T.string.isRequired,
  isHelocDrawAvailable: T.bool.isRequired,
  marketingCards: T.array.isRequired,
  mortgageOverviewData: T.object.isRequired,
  navigation: T.object.isRequired,
};

export default MortgageHome;
