import Confirmation from '../../../components/Register/Confirmation';
import {
  ForgotUsernameFormInput,
  VerifyUsernameFormInput,
} from '../../../components/Register/FormInputs';
import SharedForm from '../../../components/Register/SharedForm';
import {
  FORGOT_USERNAME_CARD_PROPS,
  FORGOT_USERNAME_CONFIRMATION_PROPS,
  VERIFY_USERNAME_CARD_PROPS,
  VERIFY_USERNAME_CONFIRMATION_PROPS,
} from './constants';

export default {
  init: {
    1: {
      viewCardProps: FORGOT_USERNAME_CARD_PROPS,
      ViewComponent: SharedForm,
      ViewFormInput: ForgotUsernameFormInput,
    },
    2: {
      viewCardProps: FORGOT_USERNAME_CONFIRMATION_PROPS,
      ViewComponent: Confirmation,
    },
  },
  verify: {
    1: {
      viewCardProps: VERIFY_USERNAME_CARD_PROPS,
      ViewComponent: SharedForm,
      ViewFormInput: VerifyUsernameFormInput,
    },
    2: {
      viewCardProps: VERIFY_USERNAME_CONFIRMATION_PROPS,
      ViewComponent: Confirmation,
    },
  },
};
