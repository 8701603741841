import styled from 'styled-components/native';

import { P2 } from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const ArrowWrapper = styled.View`
  margin: auto 0;
`;

export const Card = styled.View`
  align-self: ${({ $center }) => $center ? 'center' : 'flex-start'};
  flex-direction: row;
  justify-content: space-between;
  padding: ${moderateScale(42)}px ${moderateScale(20)}px ${moderateScale(51)}px;
  width:  ${({ $center }) => $center ? `${moderateScale(375)}px` : '100%'};
`;

export const ContentWrapper = styled.View`
  flex-shrink: 1;
  max-width: ${moderateScale(219)}px;
  padding-right: ${moderateScale(16)}px;
`;

export const IllustrationWrapper = styled.View`
  padding-right: ${moderateScale(12)}px;
`;

export const StyledP2 = styled(P2)`
  margin-top: ${moderateScale(8)}px;
`;
