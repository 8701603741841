import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';
import {
  InteractionManager,
  Keyboard,
  Platform,
  Pressable,
} from 'react-native';
import * as Linking from 'expo-linking';

import {
  biometricLoginRequest,
  cancelLogin,
  dismissBanner,
  dismissUpdateModal,
  loginRequest,
  loginRequestFailure,
  openPinDrawer,
  resetMainError,
  resetMainSuccess,
  resetSsoMigration,
  resetStatus,
  updateShouldBiometricsOpenAutomatically,
} from '@dmi/shared/redux/Main/actions';
import {
  ERROR_MESSAGES,
  NATIVE_APP_STORE_URLS,
} from '@dmi/shared/redux/Main/constants';
import makeSelectMain, {
  getError,
  getMainClientInfo,
  selectIsMurabahaAccount,
  selectMainStatus,
  selectMobilePreLoginBanners,
  selectShouldRenderIfNonSsoOrhasNativeSso,
  selectSsoMigrationModalProps,
} from '@dmi/shared/redux/Main/selectors';
import { selectShowNativeUpdateModal } from '@dmi/shared/redux/Main/mobileSelectors';
import {
  changeInput,
  resetLogin,
  setIsFirstPreAuthVisit,
  toggleDrawer,
} from '@dmi/shared/redux/Login/actions';
import reducer from '@dmi/shared/redux/Login/reducer';
import makeSelectLogin, {
  getLoginNotifications,
  getPassword,
  getUsername,
} from '@dmi/shared/redux/Login/selectors';
import makeSelectSecurity, { // eslint-disable-line object-curly-newline
  selectPrettySupportedBiometricAuthenticationTypes,
} from '@dmi/shared/redux/Settings/Security/selectors';
import { validate } from '@dmi/shared/utils/validate';
import { getFormattedPreauthFees } from '@dmi/shared/redux/Fees/selectors';

import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import {
  ConditionalRender,
  KeyboardAvoidingScrollView,
} from '../../components/base_ui';
import Disclaimer from '../../components/Disclaimers';
import { PinDrawer } from '../../components/Pin';
import UpdateAlert from '../../components/UpdateAlert';
import { useKeyboard } from '../../utils/customHooks';
import injectReducer from '../../utils/injectReducer';
import {
  LoginForm,
  PreLoginBottomBanners,
  PreLoginTopBanners,
} from '../../components/Login';
import { SsoMigrationModal } from '../../components/ModalViews';
import LoginPin from './LoginPin';

const LoginView = ({
  dispatchBiometricLoginRequest,
  dispatchCancelLogin,
  dispatchChangeInput,
  dispatchHandleDismissBanner,
  dispatchHandleDismissUpdateModal,
  dispatchLogin,
  dispatchLoginRequestFailure,
  dispatchOpenPinDrawer,
  dispatchResetLogin,
  dispatchResetMainError,
  dispatchResetMainSuccess,
  dispatchResetSsoMigration,
  dispatchResetStatus,
  dispatchSetIsFirstPreAuthVisit,
  dispatchToggleDrawer,
  dispatchUpdateShouldBiometricsOpenAutomatically,
  error,
  footerIconKeys,
  isBiometricLoginEnabled,
  isDrawerOpen,
  isFirstPreAuthVisit,
  isInitComplete,
  isMurabahaAccount,
  isPinEnabled,
  navigation,
  notifications,
  password,
  preauthFees,
  preLoginBanners: { bottomBanners, topBanners },
  shouldBiometricsOpenAutomatically,
  shouldShowAppButtons,
  showUpdateModal,
  ssoMigrationModalProps,
  status,
  success,
  supportedBiometricAuthenticationTypes,
  username,
}) => {
  const formRef = useRef();
  const statusRef = useRef();
  statusRef.current = status;
  /**
   * Biometric auto-prompt should not happen if we auto-navigate to a separate screen based
   * on initialURL. Track state with an ENUM value:
   *    0 = needs to be fetched/is fetching initialURL
   *    1 = fetched, should navigate
   *    2 = fetched, should NOT navigate
   */
  const [initialURLNavigationEnum, setInitialURLNavigationEnum] = useState(0);

  /**
   * Disclosure Drawer state
   */
  const [isDisclosureDrawerOpen, setIsDisclosureDrawerOpen] = useState(false);
  const [disclosureDrawerType, setDisclosureDrawerType] = useState('');
  const handleOpenDisclosureDrawer = (type) => {
    setDisclosureDrawerType(type);
    setIsDisclosureDrawerOpen(true);
  };

  /**
   * PreLogin Banner state
   */
  const [isBannerContainerOpen, setIsBannerContainerOpen] = useState(false);
  const [shouldHideBanners, setShouldHideBanners] = useState(false);
  const isKeyboardOpen = useKeyboard();

  const handleToggleBannerContainer = () => {
    setIsBannerContainerOpen((previousIsBannerContainerOpen) => !previousIsBannerContainerOpen);
  };

  const handlePress = () => {
    if (isKeyboardOpen) {
      Keyboard.dismiss();
    }
    if (isBannerContainerOpen && !isKeyboardOpen) {
      setIsBannerContainerOpen(false);
    }
  };

  // Avoid banners "flashing" when keyboard opens/closes
  useEffect(() => {
    if (isKeyboardOpen) {
      setTimeout(() => {
        setShouldHideBanners(true);
      }, 100);
    } else {
      setTimeout(() => {
        if (shouldHideBanners) {
          setShouldHideBanners(false);
          setIsBannerContainerOpen(false);
        }
      }, 100);
    }
  }, [isKeyboardOpen, shouldHideBanners]);

  useEffect(() => () => dispatchSetIsFirstPreAuthVisit(false), [dispatchSetIsFirstPreAuthVisit]);

  useEffect(() => {
    if (isFirstPreAuthVisit) {
      (async () => {
        const { path } = await Linking.parseInitialURLAsync();
        const whitelistedURLs = [
          'forgotusername/verify',
          'forgotpassword/verify',
          'register/init',
          'register/verify',
        ];
        if (whitelistedURLs.includes(path)) {
          setInitialURLNavigationEnum(1);
        } else {
          setInitialURLNavigationEnum(2);
        }
      })();
    } else {
      setInitialURLNavigationEnum(2);
    }
  }, [isFirstPreAuthVisit, setInitialURLNavigationEnum]);

  useEffect(() => {
    if (initialURLNavigationEnum === 0) return;
    if (isInitComplete && shouldBiometricsOpenAutomatically) {
      dispatchUpdateShouldBiometricsOpenAutomatically(false);
      if (isBiometricLoginEnabled && (initialURLNavigationEnum === 2)) {
        dispatchBiometricLoginRequest({ shouldResetBanners: false });
      }
    }
  }, [
    dispatchBiometricLoginRequest,
    dispatchUpdateShouldBiometricsOpenAutomatically,
    initialURLNavigationEnum,
    isBiometricLoginEnabled,
    isInitComplete,
    shouldBiometricsOpenAutomatically,
  ]);

  useFocusEffect(
    useCallback(() => {
      if (statusRef.current === 'loading' && isDisclosureDrawerOpen) {
        dispatchCancelLogin();
      }
      return () => {
        if (statusRef.current === 'loading') {
          dispatchCancelLogin();
        }
      };
    }, [dispatchCancelLogin, isDisclosureDrawerOpen]),
  );

  const resetLoginState = useCallback(() => {
    dispatchResetLogin();
    dispatchResetMainError();
    dispatchResetMainSuccess();
    dispatchResetStatus();
  }, [
    dispatchResetLogin,
    dispatchResetMainError,
    dispatchResetMainSuccess,
    dispatchResetStatus,
  ]);

  useFocusEffect(
    useCallback(() => () => {
      // InteractionManager.runAfterInteractions not triggered on web (nor is it needed).
      if (Platform.OS === 'web') {
        resetLoginState();
      } else {
        InteractionManager.runAfterInteractions(() => {
          resetLoginState();
        });
      }
    }, [resetLoginState]),
  );

  const handleLoginPress = () => {
    const hasValidationError = [password, username].some(
      (value) => !!validate({ required: true, type: 'textInput', value }),
    );
    Keyboard.dismiss();
    if (hasValidationError) {
      dispatchLoginRequestFailure(ERROR_MESSAGES[1000]);
    } else {
      dispatchLogin({ password, username });
    }
  };

  const PinBottomDrawerComponent = (
    <PinDrawer
      handleClose={() => dispatchToggleDrawer(false)}
      isVisible={isDrawerOpen}
    >
      <LoginPin dispatchToggleDrawer={dispatchToggleDrawer} />
    </PinDrawer>
  );

  const shouldDisplayBadge = shouldShowAppButtons;

  const storeUrl = NATIVE_APP_STORE_URLS[Platform.OS];

  const LoginFormComponent = (
    <LoginForm
      dispatchBiometricLoginRequest={dispatchBiometricLoginRequest}
      dispatchLoginRequestFailure={dispatchLoginRequestFailure}
      dispatchOpenPinDrawer={dispatchOpenPinDrawer}
      displayAppStoreBadge={shouldDisplayBadge}
      error={error}
      footerIconKeys={footerIconKeys}
      formRef={formRef}
      handleInputChange={dispatchChangeInput}
      handleLoginPress={handleLoginPress}
      handleOpenDisclosureDrawer={handleOpenDisclosureDrawer}
      isBiometricLoginEnabled={isBiometricLoginEnabled}
      isBottomBannerRendered={bottomBanners.length > 0}
      isMurabahaAccount={isMurabahaAccount}
      isPinEnabled={isPinEnabled}
      isPreLoginBannerRendered={topBanners.length > 0}
      password={password}
      preauthFees={preauthFees}
      status={status}
      success={success}
      supportedBiometricAuthenticationTypes={supportedBiometricAuthenticationTypes}
      username={username}
    />
  );

  const LoginComponentToRender = (
    bottomBanners.length > 1 ? (
      <Pressable onPress={handlePress}>
        {LoginFormComponent}
      </Pressable>
    ) : (
      LoginFormComponent
    )
  );

  return (
    <Fragment>
      <ScreenReaderNotifications notifications={notifications} />
      <ConditionalRender
        Component={PreLoginTopBanners}
        propsToPassDown={{
          handleDismissBanner: dispatchHandleDismissBanner,
          handleOpenDisclosureDrawer,
          hasBanner: !!error || !!success,
          topBanners,
        }}
        shouldRender={topBanners.length > 0}
      />
      <KeyboardAvoidingScrollView
        bannerError={error}
        formRef={formRef}
        ownsCurrentKeyboard={!isDrawerOpen}
      >
        {LoginComponentToRender}
      </KeyboardAvoidingScrollView>
      <ConditionalRender
        Component={PinBottomDrawerComponent}
        shouldRender={isPinEnabled}
      />
      <ConditionalRender
        Component={Disclaimer}
        propsToPassDown={{
          drawerType: disclosureDrawerType,
          isDrawerOpen: isDisclosureDrawerOpen,
          setIsDrawerOpen: setIsDisclosureDrawerOpen,
        }}
        shouldRender={!!disclosureDrawerType}
      />
      <ConditionalRender
        Component={PreLoginBottomBanners}
        propsToPassDown={{
          bottomBanners,
          handleDismissBanner: dispatchHandleDismissBanner,
          handleOpenDisclosureDrawer,
          isBannerContainerOpen,
          shouldHideBanners,
          toggleShowBanners: handleToggleBannerContainer,
        }}
        shouldRender={bottomBanners.length > 0}
      />
      <ConditionalRender
        Component={SsoMigrationModal}
        propsToPassDown={{
          dispatchResetSsoMigration,
          navigation,
          ssoMigrationModalProps,
        }}
        shouldRender={ssoMigrationModalProps.isOpen && Platform.OS === 'web'}
      />
      <ConditionalRender
        Component={UpdateAlert}
        propsToPassDown={{
          dispatchHandleDismissUpdateModal,
          storeUrl,
        }}
        shouldRender={showUpdateModal && initialURLNavigationEnum === 0}
      />
    </Fragment>
  );
};

LoginView.propTypes = {
  dispatchBiometricLoginRequest: T.func.isRequired,
  dispatchCancelLogin: T.func.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchHandleDismissBanner: T.func.isRequired,
  dispatchHandleDismissUpdateModal: T.func.isRequired,
  dispatchLogin: T.func.isRequired,
  dispatchLoginRequestFailure: T.func.isRequired,
  dispatchOpenPinDrawer: T.func.isRequired,
  dispatchResetLogin: T.func.isRequired,
  dispatchResetMainError: T.func.isRequired,
  dispatchResetMainSuccess: T.func.isRequired,
  dispatchResetSsoMigration: T.func.isRequired,
  dispatchResetStatus: T.func.isRequired,
  dispatchSetIsFirstPreAuthVisit: T.func.isRequired,
  dispatchToggleDrawer: T.func.isRequired,
  dispatchUpdateShouldBiometricsOpenAutomatically: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  footerIconKeys: T.array.isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isDrawerOpen: T.bool.isRequired,
  isFirstPreAuthVisit: T.bool.isRequired,
  isInitComplete: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  password: T.string.isRequired,
  preauthFees: T.object.isRequired,
  preLoginBanners: T.object.isRequired,
  shouldBiometricsOpenAutomatically: T.bool.isRequired,
  shouldShowAppButtons: T.bool.isRequired,
  showUpdateModal: T.bool.isRequired,
  ssoMigrationModalProps: T.shape({
    currentUserEmail: T.string,
    isOpen: T.bool.isRequired,
    migrationImage: T.object,
    migrationLoginPage: T.object,
    migrationUrl: T.string,
  }).isRequired,
  status: T.string.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
  username: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Login
   */
  isDrawerOpen: makeSelectLogin('isDrawerOpen'),
  isFirstPreAuthVisit: makeSelectLogin('isFirstPreAuthVisit'),
  notifications: getLoginNotifications(),
  password: getPassword(),
  username: getUsername(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  error: getError('main'),
  footerIconKeys: getMainClientInfo('footerIconKeys'),
  isInitComplete: makeSelectMain('isInitComplete'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  preLoginBanners: selectMobilePreLoginBanners(),
  shouldBiometricsOpenAutomatically: makeSelectMain('shouldBiometricsOpenAutomatically'),
  shouldShowAppButtons: selectShouldRenderIfNonSsoOrhasNativeSso(),
  showUpdateModal: selectShowNativeUpdateModal(),
  ssoMigrationModalProps: selectSsoMigrationModalProps(),
  status: selectMainStatus('login'),
  success: makeSelectMain('success'),
  /**
   * Store: Security
   */
  // eslint-disable-next-line sort-keys
  isBiometricLoginEnabled: makeSelectSecurity('isBiometricLoginEnabled'),
  isPinEnabled: makeSelectSecurity('isPinEnabled'),
  supportedBiometricAuthenticationTypes: selectPrettySupportedBiometricAuthenticationTypes(),
  /**
   * Reducer: Fees
   */
  // eslint-disable-next-line sort-keys
  preauthFees: getFormattedPreauthFees(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Login
   */
  dispatchChangeInput: ({ field, value }) => dispatch(changeInput({ field, form: 'login', value })),
  dispatchResetLogin: () => dispatch(resetLogin()),
  dispatchSetIsFirstPreAuthVisit: () => dispatch(setIsFirstPreAuthVisit()),
  dispatchToggleDrawer: (payload) => dispatch(toggleDrawer(payload)),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  dispatchBiometricLoginRequest: (payload) => dispatch(biometricLoginRequest(payload)),
  dispatchCancelLogin: () => dispatch(cancelLogin()),
  dispatchHandleDismissBanner: (payload) => dispatch(dismissBanner(payload)),
  dispatchHandleDismissUpdateModal: () => dispatch(dismissUpdateModal()),
  dispatchLogin: (payload) => dispatch(loginRequest(payload)),
  dispatchLoginRequestFailure: (payload) => dispatch(loginRequestFailure(payload)),
  dispatchOpenPinDrawer: () => dispatch(openPinDrawer()),
  dispatchResetMainError: () => dispatch(resetMainError('main')),
  dispatchResetMainSuccess: () => dispatch(resetMainSuccess()),
  dispatchResetSsoMigration: () => dispatch(resetSsoMigration()),
  dispatchResetStatus: () => dispatch(resetStatus('login')),
  dispatchUpdateShouldBiometricsOpenAutomatically:
    (value) => dispatch(updateShouldBiometricsOpenAutomatically(value)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'login', reducer });

export default compose(withConnect, withReducer)(LoginView);
