import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const ImageContainer = styled.View`
  align-items: center;
  padding: ${moderateScale(18)}px 0;
`;

export const StyledImage = styled.Image`
  height: ${moderateScale(84)}px;
  width: ${moderateScale(327)}px;
`;
