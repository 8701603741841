import React, { Fragment } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { changeSelectedMessageId, fetchMessages } from '@dmi/shared/redux/SecureMessaging/actions';
import {
  selectMessages,
  selectSecureMessagingInboxProps,
  selectSecureMessagingScreenReaderNotifications,
} from '@dmi/shared/redux/SecureMessaging/selectors';

import { InboxComponent } from '../../components/SecureMessaging';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';

const Inbox = ({
  dispatchChangeSelectedMessageId,
  dispatchFetchMessages,
  messages,
  navigation,
  notifications,
  secureMessagingInboxProps,
}) => (
  <Fragment>
    <ScreenReaderNotifications notifications={notifications} />
    <InboxComponent
      dispatchChangeSelectedMessageId={dispatchChangeSelectedMessageId}
      dispatchFetchMessages={dispatchFetchMessages}
      messages={messages}
      navigation={navigation}
      secureMessagingInboxProps={secureMessagingInboxProps}
    />
  </Fragment>
);

Inbox.propTypes = {
  dispatchChangeSelectedMessageId: T.func.isRequired,
  dispatchFetchMessages: T.func.isRequired,
  messages: T.array.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  secureMessagingInboxProps: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: SecureMessaging
   */
  messages: selectMessages(),
  notifications: selectSecureMessagingScreenReaderNotifications(),
  secureMessagingInboxProps: selectSecureMessagingInboxProps(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
    * Store: SecureMessaging
    */
  dispatchChangeSelectedMessageId: (payload) => dispatch(changeSelectedMessageId(payload)),
  dispatchFetchMessages: () => dispatch(fetchMessages()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Inbox);
