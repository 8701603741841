/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../scaleHelpers';

export default function InfoTooltipSIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(16)} viewBox="0 0 16 16" width={moderateScale(17)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M0.499999 8C0.499999 3.85786 3.85786 0.5 8 0.5C12.1421 0.500001 15.5 3.85787 15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.499998 12.1421 0.499999 8Z" stroke={primary} />
      <Path d="M7.83945 6.86641C7.99858 6.86641 8.1512 6.92962 8.26372 7.04214C8.37624 7.15467 8.43945 7.30728 8.43945 7.46641L8.43945 11.9664C8.43945 12.1255 8.37624 12.2781 8.26372 12.3907C8.1512 12.5032 7.99858 12.5664 7.83945 12.5664C7.68032 12.5664 7.52771 12.5032 7.41519 12.3907C7.30267 12.2781 7.23945 12.1255 7.23945 11.9664L7.23945 7.46641C7.23945 7.38761 7.25497 7.30959 7.28513 7.2368C7.31528 7.164 7.35947 7.09786 7.41519 7.04214C7.4709 6.98643 7.53705 6.94223 7.60984 6.91208C7.68264 6.88193 7.76066 6.86641 7.83945 6.86641Z" fill={primary} />
      <Path d="M7.83938 3.37309C7.27604 3.37309 6.81938 3.82976 6.81938 4.39309C6.81938 4.95642 7.27604 5.41309 7.83938 5.41309C8.40271 5.41309 8.85938 4.95642 8.85938 4.39309C8.85938 3.82976 8.40271 3.37309 7.83938 3.37309Z" fill={primary} />
    </Svg>
  );
}
