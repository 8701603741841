import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { SCHEDULE_CHART_LINES } from '@dmi/shared/redux/Calculators/Amortize/constants';
import { DEFAULT_MOBILE_THEME_COLORS } from '@dmi/shared/redux/Main/constants';

import { ConditionalRender, P4 } from '../../base_ui';
import {
  ChartLegendRow,
  ChartLegendWrapper,
  LegendColor,
  RowsGroup,
} from './styledComponents';

const ChartLegend = ({ showNewValues = false }) => (
  <ChartLegendWrapper>
    <RowsGroup $showNewValues={showNewValues}>
      {
        SCHEDULE_CHART_LINES.map(({ mobileLineColor, name }) => (
          <ChartLegendRow key={`${name}-legend`}>
            <LegendColor color={DEFAULT_MOBILE_THEME_COLORS[mobileLineColor]} opacity="0.3" />
            <P4><FormattedMessage id={name} /></P4>
          </ChartLegendRow>
        ))
      }
    </RowsGroup>
    <ConditionalRender
      Component={(
        <RowsGroup $showNewValues={showNewValues}>
          {
            SCHEDULE_CHART_LINES.map(({ mobileCalculatedName, mobileLineColor }) => (
              <ChartLegendRow key={`${mobileCalculatedName}-legend`}>
                <LegendColor color={DEFAULT_MOBILE_THEME_COLORS[mobileLineColor]} />
                <P4><FormattedMessage id={mobileCalculatedName} /></P4>
              </ChartLegendRow>
            ))
          }
        </RowsGroup>
      )}
      shouldRender={showNewValues}
    />
  </ChartLegendWrapper>
);

ChartLegend.propTypes = { showNewValues: T.bool };

export default ChartLegend;
