import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../utils/iconDictionary';
import Message from '../Message';

const errorStorm = iconDictionary('errorStorm');

const ErrorMessage = ({
  link = {
    label: 'Return to Home',
    path: 'Payments',
  },
  ...restProps
}) => (
  <Message
    icon={errorStorm}
    link={link}
    title="Uh oh. Something unexpected occurred."
    {...restProps}
  />
);

ErrorMessage.propTypes = {
  link: T.shape({
    label: T.string,
    path: T.string,
  }),
};

export default ErrorMessage;
