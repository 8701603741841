import React from 'react';
import T from 'prop-types';

import DynamicContent from './DynamicContent';
import {
  NotificationContainer,
  NotificationDate,
  NotificationName,
  StyledDivider,
} from './styledComponents';

export const Content = ({
  dispatchSetNestedScreen,
  navigation,
  notifications,
}) => notifications.map(({
  content,
  date,
  name,
  noMargin,
}, index) => (
  <NotificationContainer
    // eslint-disable-next-line react/no-array-index-key
    key={`${name}-${date}-${index}`}
  >
    <NotificationDate>{date}</NotificationDate>
    <NotificationName $noMargin={noMargin}>{name}</NotificationName>
    <DynamicContent
      content={content}
      dispatchSetNestedScreen={dispatchSetNestedScreen}
      navigation={navigation}
      noMargin={noMargin}
    />
    <StyledDivider />
  </NotificationContainer>
));

Content.propTypes = {
  dispatchSetNestedScreen: T.func.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
};

export default Content;
