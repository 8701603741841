import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  changeInput,
  resetAutopayState,
  resetConfirmation,
  setIsModalOpen,
  stop,
} from '@dmi/shared/redux/Payments/Autopay/actions';
import { AUTOPAY_MODAL_DICTIONARY } from '@dmi/shared/redux/Payments/Autopay/constants';
import makeSelectAutopay, {
  selectAutopayFlow,
  selectAvailableBeginDates,
  selectAvailableDelayDays,
  selectFormErrors,
  selectIsModalOpen,
  selectMaxDelayDays,
  selectPaymentAmount,
  selectSelectedBeginDate,
  selectSelectedDelayDays,
  selectStatusDataProp,
  selectStatusProp,
  selectSummaryScheduledDateString,
} from '@dmi/shared/redux/Payments/Autopay/selectors';
import {
  getMainClientInfo,
  getSelectedLoanAddress,
  getSelectedLoanMaskedLoanNumber,
} from '@dmi/shared/redux/Main/selectors';
import { selectOtpDueDate } from '@dmi/shared/redux/Payments/OneTimePayment/selectors';
import { resetState } from '@dmi/shared/redux/Payments/Sources/actions';
import { makeSelectPayFromButtonProps } from '@dmi/shared/redux/Payments/Sources/selectors';

import { SetupOverview as SetupOverviewScreen } from '../../../components/Payments/Autopay';

const SetupOverview = ({
  accountFormErrors,
  address,
  allowedSetting,
  availableBeginDates,
  availableDelayDays,
  customerServicePhoneNumber,
  dispatchChangeInput,
  dispatchResetAutopayState,
  dispatchResetConfirmation,
  dispatchResetSourcesState,
  dispatchSetIsModalOpen,
  dispatchStopAutopay,
  dueDate,
  flow,
  isInAutopayFlow,
  isStopModalOpen,
  maskedLoanNumber,
  maxDelayDays,
  navigation,
  payFromButtonProps,
  paymentAmount,
  requestStatus,
  selectedBeginDate,
  selectedDelayDays,
  stopStatus,
  summaryScheduledDateString,
}) => {
  useEffect(() => {
    if (!isInAutopayFlow) {
      navigation.navigate('Payments');
    }

    dispatchResetConfirmation();
    return () => {
      dispatchResetAutopayState();
      dispatchResetSourcesState();
    };
  },
  [
    dispatchResetAutopayState,
    dispatchResetConfirmation,
    dispatchResetSourcesState,
    isInAutopayFlow,
    navigation,
  ]);

  const stopModalProps = {
    customerServicePhoneNumber,
    handleCloseModal: () => dispatchSetIsModalOpen({ key: 'stop', value: false }),
    handleOnPress: dispatchStopAutopay,
    isOpen: isStopModalOpen,
    status: stopStatus,
    ...AUTOPAY_MODAL_DICTIONARY.stop,
  };

  return (
    <SetupOverviewScreen
      accountFormErrors={accountFormErrors}
      address={address}
      allowedSetting={allowedSetting}
      availableBeginDates={availableBeginDates}
      availableDelayDays={availableDelayDays}
      customerServicePhoneNumber={customerServicePhoneNumber}
      dispatchChangeInput={dispatchChangeInput}
      dispatchSetIsModalOpen={dispatchSetIsModalOpen}
      dueDate={dueDate}
      flow={flow}
      isInAutopayFlow={isInAutopayFlow}
      loanNumber={maskedLoanNumber}
      maxDelayDays={maxDelayDays}
      modalProps={stopModalProps}
      navigation={navigation}
      payFromButtonProps={payFromButtonProps}
      paymentAmount={paymentAmount}
      requestStatus={requestStatus}
      selectedBeginDate={selectedBeginDate}
      selectedDelayDays={selectedDelayDays}
      summaryScheduledDateString={summaryScheduledDateString}
    />
  );
};

SetupOverview.propTypes = {
  accountFormErrors: T.shape({ beginDate: T.string, delayDays: T.string }).isRequired,
  address: T.string.isRequired,
  allowedSetting: T.object,
  availableBeginDates: T.array,
  availableDelayDays: T.array,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangeInput: T.func.isRequired,
  dispatchResetAutopayState: T.func.isRequired,
  dispatchResetConfirmation: T.func.isRequired,
  dispatchResetSourcesState: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  dispatchStopAutopay: T.func.isRequired,
  dueDate: T.string.isRequired,
  flow: T.string.isRequired,
  isInAutopayFlow: T.bool.isRequired,
  isStopModalOpen: T.bool.isRequired,
  maskedLoanNumber: T.string.isRequired,
  maxDelayDays: T.number.isRequired,
  navigation: T.object.isRequired,
  payFromButtonProps: T.shape({
    noPaymentSource: T.bool.isRequired,
    value: T.string.isRequired,
  }).isRequired,
  paymentAmount: T.string.isRequired,
  requestStatus: T.string.isRequired,
  selectedBeginDate: T.string.isRequired,
  selectedDelayDays: T.number.isRequired,
  stopStatus: T.string.isRequired,
  summaryScheduledDateString: T.string.isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  accountFormErrors: selectFormErrors('account'),
  allowedSetting: selectStatusDataProp('allowedSetting'),
  availableBeginDates: selectAvailableBeginDates(),
  availableDelayDays: selectAvailableDelayDays(),
  flow: selectAutopayFlow(),
  isInAutopayFlow: makeSelectAutopay('isInAutopayFlow'),
  isStopModalOpen: selectIsModalOpen('stop'),
  maxDelayDays: selectMaxDelayDays(),
  paymentAmount: selectPaymentAmount(),
  requestStatus: selectStatusDataProp('status'),
  selectedBeginDate: selectSelectedBeginDate(),
  selectedDelayDays: selectSelectedDelayDays(),
  stopStatus: selectStatusProp('stop'),
  summaryScheduledDateString: selectSummaryScheduledDateString(),
  /**
   * Store: Main
   */
  address: getSelectedLoanAddress(),
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  maskedLoanNumber: getSelectedLoanMaskedLoanNumber(),
  /**
   * Store: One Time Payment
   */
  dueDate: selectOtpDueDate(),
  /**
   * Store: Payment Sources
   */
  payFromButtonProps: makeSelectPayFromButtonProps('autopay'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Autopay
   */
  dispatchChangeInput: (payload) => dispatch(changeInput(payload)),
  dispatchResetAutopayState: () => dispatch(resetAutopayState()),
  dispatchResetConfirmation: () => dispatch(resetConfirmation()),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
  dispatchStopAutopay: () => dispatch(stop({ navigationType: 'replace' })),
  /**
   * Store: Payment Sources
   */
  dispatchResetSourcesState: () => dispatch(resetState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(SetupOverview);
