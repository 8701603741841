import { moderateScale } from '../../../utils/scaleHelpers';

// Button Styles
export const primaryButtonStyles = ({ colors: { primaryButton } }) => `
  background-color: ${primaryButton};
  border-radius: 8px;
`;

export const primaryPressedButtonStyles = ({ colors: { primaryButtonHover } }) =>
  ({ backgroundColor: primaryButtonHover });

export const secondaryButtonStyles = ({ colors: { dmiWhite, primaryGray } }) => `
  background-color: ${dmiWhite};
  border-color: ${primaryGray};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
`;

export const secondaryPressedButtonStyles = (
  { colors: { accentSilver, dmiWhite, footerBackground } },
  isFooterButton,
) => ({
  backgroundColor: isFooterButton && footerBackground !== dmiWhite ?
    `${dmiWhite}30` : accentSilver,
});

export const disabledButtonStyles = ({ colors: { buttonGray } }) => `
  background-color: ${buttonGray};
  border-radius: 8px;
`;

// Button Sizes
export const largeButtonDimensions = () => `
  height: ${moderateScale(45)}px;
  width: ${moderateScale(261)}px;
`;

export const mediumButtonDimensions = () => `
  height: ${moderateScale(45)}px;
  width: ${moderateScale(226)}px;
`;

export const smallButtonDimensions = () => `
  height: ${moderateScale(39)}px;
  width: ${moderateScale(173)}px;
`;

export const xSmallButtonDimensions = () => `
  border-radius: 6px;
  height: ${moderateScale(28)}px;
  width: ${moderateScale(127)}px;
`;

// Title Styles
export const disabledTitleStyles = ({
  colors: { primaryCarbon },
  fontFamilies: { secondaryFontBold },
}) => ({
  color: primaryCarbon,
  fontFamily: secondaryFontBold,
  opacity: 0.5,
});

export const primaryTitleStyles = ({
  colors: { primaryButtonText },
  fontFamilies: { secondaryFontBold },
}) => ({
  color: primaryButtonText,
  fontFamily: secondaryFontBold,
});

export const primaryPressedTitleStyles = ({ colors: { primaryButtonTextHover } }) =>
  ({ color: primaryButtonTextHover });

export const secondaryTitleStyles = ({
  colors: { primaryCarbon },
  fontFamilies: { secondaryFontBold },
}) => ({
  color: primaryCarbon,
  fontFamily: secondaryFontBold,
});

// Title Sizes
export const largeTitleStyles = ({ fontSizes: { f5 } }) => ({ fontSize: f5 });
export const mediumTitleStyles = ({ fontSizes: { f4 } }) => ({ fontSize: f4 });
export const smallTitleStyles = ({ fontSizes: { f4 } }) => ({ fontSize: f4 });
export const xSmallTitleStyles = ({ fontSizes: { f3 } }) => ({ fontSize: f3 });
