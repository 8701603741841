import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../utils/iconDictionary';
import {
  BaseInternalLink,
  ConditionalRender,
} from '../base_ui';
import {
  StyledDialogContent,
  StyledImage,
  StyledModalDialog,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledText,
  StyledTitle,
} from './styledComponents';

const MainModal = ({
  handleCloseModal,
  iconName,
  imageProps,
  linkProps,
  primaryButtonProps,
  secondaryButtonProps,
  text,
  title,
  visible,
  ...restProps
}) => {
  const Icon = iconName ? iconDictionary(iconName) : null;
  const { height: imageHeight, source: imageSource, width: imageWidth } = imageProps || {};

  return (
    <StyledModalDialog
      handleCloseModal={handleCloseModal}
      visible={visible}
      {...restProps}
    >
      <StyledDialogContent>
        <ConditionalRender
          Component={Icon}
          shouldRender={!!Icon}
        />
        <ConditionalRender
          Component={StyledImage}
          propsToPassDown={{
            $height: imageHeight,
            $width: imageWidth,
            source: imageSource,
          }}
          shouldRender={!!imageSource && !!imageHeight && !!imageWidth}
        />
        <StyledTitle>{title}</StyledTitle>
        <StyledText>{text}</StyledText>
        <ConditionalRender
          Component={StyledPrimaryButton}
          propsToPassDown={primaryButtonProps}
          shouldRender={!!primaryButtonProps}
        />
        <ConditionalRender
          Component={StyledSecondaryButton}
          propsToPassDown={secondaryButtonProps}
          shouldRender={!!secondaryButtonProps}
        />
        <ConditionalRender
          Component={BaseInternalLink}
          propsToPassDown={{ size: 'medium', ...linkProps }}
          shouldRender={!!linkProps}
        />
      </StyledDialogContent>
    </StyledModalDialog>
  );
};

MainModal.propTypes = {
  handleCloseModal: T.func.isRequired,
  iconName: T.string,
  imageProps: T.shape({
    height: T.number.isRequired,
    source: T.object.isRequired,
    width: T.number.isRequired,
  }),
  linkProps: T.object,
  primaryButtonProps: T.object,
  secondaryButtonProps: T.object,
  text: T.string.isRequired,
  title: T.string.isRequired,
  visible: T.bool.isRequired,
};

export default MainModal;
