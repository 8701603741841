import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';
import { useIntl } from 'react-intl';

import { resolveIntlMessage } from '@dmi/shared/utils/globalHelpers';

import AddressTypeDropdown from './AddressTypeDropdown';
import { DomesticForm, InternationalForm } from './Form';
import { AlertBanner, ConditionalRender, MediumProgressButton } from '../../base_ui';
import {
  ButtonGroup,
  ChangeMailingAddressContainer,
  StyledStatusBanner,
  TextFieldGroup,
  TextWrapper,
} from './styledComponents';

const ChangeMailingAddressComponent = ({
  alertText,
  allowedCountries,
  allowedProvinces,
  allowedStates,
  bannerError,
  canUpload,
  dispatchChangeMailingAddressType,
  formErrors,
  formType,
  handleChangeInput,
  handleChangeMailingAddress,
  internationalFormErrors,
  internationalRequestBody,
  isAddressCanadian,
  isAddressInternational,
  isSubmitDisabled,
  multiBorrowerText,
  requestBody,
  status,
}) => {
  const { formatMessage, messages } = useIntl();

  return (
    <ChangeMailingAddressContainer>
      <View>
        <StyledStatusBanner
          error={resolveIntlMessage({
            formatMessage,
            message: bannerError,
            messages,
          })}
        />
        <ConditionalRender
          Component={(<TextWrapper>{multiBorrowerText}</TextWrapper>)}
          shouldRender={canUpload}
        />
        <TextFieldGroup>
          <AddressTypeDropdown
            dispatchChangeMailingAddressType={dispatchChangeMailingAddressType}
            selected={formType}
          />
          <ConditionalRender
            Component={(
              <InternationalForm
                allowedCountries={allowedCountries}
                allowedProvinces={allowedProvinces}
                formErrors={internationalFormErrors}
                handleChangeInput={handleChangeInput}
                isAddressCanadian={isAddressCanadian}
                requestBody={internationalRequestBody}
              />
            )}
            FallbackComponent={(
              <DomesticForm
                allowedStates={allowedStates}
                formErrors={formErrors}
                handleChangeInput={handleChangeInput}
                handleChangeMailingAddress={handleChangeMailingAddress}
                isSubmitDisabled={isSubmitDisabled}
                requestBody={requestBody}
              />
            )}
            shouldRender={isAddressInternational}
          />
        </TextFieldGroup>
      </View>
      <View>
        <AlertBanner text={alertText} />
        <ButtonGroup>
          <MediumProgressButton
            disabled={isSubmitDisabled}
            label="Submit"
            onPress={handleChangeMailingAddress}
            status={status}
          />
        </ButtonGroup>
      </View>
    </ChangeMailingAddressContainer>
  );
};

ChangeMailingAddressComponent.propTypes = {
  alertText: T.string.isRequired,
  allowedCountries: T.array.isRequired,
  allowedProvinces: T.array.isRequired,
  allowedStates: T.array.isRequired,
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  canUpload: T.bool.isRequired,
  dispatchChangeMailingAddressType: T.func.isRequired,
  formErrors: T.object.isRequired,
  formType: T.string.isRequired,
  handleChangeInput: T.func.isRequired,
  handleChangeMailingAddress: T.func.isRequired,
  internationalFormErrors: T.object.isRequired,
  internationalRequestBody: T.object.isRequired,
  isAddressCanadian: T.bool.isRequired,
  isAddressInternational: T.bool.isRequired,
  isSubmitDisabled: T.bool.isRequired,
  multiBorrowerText: T.string.isRequired,
  requestBody: T.object.isRequired,
  status: T.string.isRequired,
};

export default ChangeMailingAddressComponent;
