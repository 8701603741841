import React from 'react';

import { Container, WebShadow } from './styledComponents';

const SecondaryDivider = () => (
  <Container>
    <WebShadow />
  </Container>
);

export default SecondaryDivider;
