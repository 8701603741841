import React from 'react';
import T from 'prop-types';
import { FormattedMessage, useIntl } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';

import iconDictionary from '../../../../utils/iconDictionary';
import HomeCard from './HomeCard';
import {
  DataBox,
  DataContainer,
  DataDivider,
  DataLabel,
  DataValue,
} from './styledComponents';

const Icon = iconDictionary('mortgageIcon');

const MortgageOverview = ({
  currentPrincipalBalance,
  effectiveInterestRate,
  loanMaturityDate,
}) => {
  const { formatMessage } = useIntl();
  return (
    <HomeCard
      HeaderIcon={Icon}
      headerText={formatMessage({ id: INTL_IDS.MORTGAGE_HOME_OVERVIEW_TITLE })}
      linkLabel={formatMessage({ id: INTL_IDS.MORTGAGE_HOME_OVERVIEW_LINK_LABEL_MORTGAGE_DETAILS })}
      linkPath="MortgageDetails"
    >
      <DataContainer>
        <DataBox>
          <DataLabel>
            <FormattedMessage id={INTL_IDS.MORTGAGE_HOME_OVERVIEW_LABEL_PRINCIPALBALANCE} />
          </DataLabel>
          <DataValue>{currentPrincipalBalance}</DataValue>
        </DataBox>
        <DataDivider />
        <DataBox>
          <DataLabel>
            <FormattedMessage id={INTL_IDS.MORTGAGE_HOME_OVERVIEW_LABEL_CURRENTINTERESTRATE} />
          </DataLabel>
          <DataValue>{effectiveInterestRate}</DataValue>
        </DataBox>
        <DataDivider />
        <DataBox>
          <DataLabel>Maturity Date</DataLabel>
          <DataValue>{loanMaturityDate}</DataValue>
        </DataBox>
      </DataContainer>
    </HomeCard>
  );
};

MortgageOverview.propTypes = {
  currentPrincipalBalance: T.string.isRequired,
  effectiveInterestRate: T.string.isRequired,
  loanMaturityDate: T.string.isRequired,
};

export default MortgageOverview;
