import styled from 'styled-components/native';

import { BaseInternalLink } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const Content = styled.View`
  margin: ${moderateScale(20)}px ${moderateScale(29)}px 0;
`;

export const ErrorContainer = styled.View`
  align-content: center;
  margin: ${moderateScale(30)}px ${moderateScale(15)}px;
`;

export const IllustrationWrapper = styled.View`
  align-items: center;
  margin-bottom: ${moderateScale(24)}px;
`;

export const StyledInternalLink = styled(BaseInternalLink)`
  align-items: center;
  height: ${moderateScale(34)}px;
`;
