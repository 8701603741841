import React, { useState } from 'react';
import T from 'prop-types';

import { moderateScale } from '../../../utils/scaleHelpers';
import iconDictionary from '../../../utils/iconDictionary';
import { ConditionalRender, Skeleton, StatusBanner } from '../../base_ui';
import ButtonGroup from './ButtonGroup';
import {
  AddAccountButton,
  ClientCard,
  ClientSelectPageContainer,
  IllustrationWrapper,
  StyledH1,
  StyledLogo,
  StyledSubheader,
} from './styledComponents';

const AddCircle = iconDictionary('addCircle');
const DogSmartphoneIllustration = iconDictionary('dogSmartphone');

const ClientSelectView = ({
  clients,
  error,
  handleBiometricPinLogin,
  handleLinkAccountClick,
  handlePasswordLogin,
  isBiometricLoginEnabled,
  isPinEnabled,
  success,
  supportedBiometricAuthenticationTypes,
}) => {
  const [logoLoadingArr, setLogoLoadingArr] = useState(new Array(clients.length).fill(true));
  const handleImageLoad = (index) => setLogoLoadingArr((prevState) => {
    const prevStateClone = Array.from(prevState);
    prevStateClone[index] = false;
    return prevStateClone;
  });
  const isLoading = !logoLoadingArr.every((loading) => loading === false);

  return (
    <ClientSelectPageContainer>
      <ConditionalRender
        Component={StatusBanner}
        propsToPassDown={{ error, success }}
        shouldRender={!!error || !!success}
      />
      <StyledH1>Welcome</StyledH1>
      <StyledSubheader>Select an account:</StyledSubheader>
      {
        clients.map(({
          clientAuthentication,
          clientName,
          logo: { image, type } = {},
          refreshToken,
        }, i) => (
          <ClientCard key={clientName}>
            <ConditionalRender
              Component={Skeleton}
              propsToPassDown={{
                height: `${moderateScale(93)}px`,
                width: `${moderateScale(262)}px`,
              }}
              shouldRender={isLoading}
            />
            <StyledLogo
              isLoading={isLoading}
              onLoadEnd={() => handleImageLoad(i)}
              resizeMode="contain"
              source={{ uri: `data:${type};base64,${image}` }}
            />
            <ButtonGroup
              clientAuthentication={clientAuthentication}
              clientName={clientName}
              handleBiometricPinLogin={handleBiometricPinLogin}
              handlePasswordLogin={handlePasswordLogin}
              isBiometricLoginEnabled={isBiometricLoginEnabled}
              isPinEnabled={isPinEnabled}
              refreshToken={refreshToken}
              supportedBiometricAuthenticationTypes={supportedBiometricAuthenticationTypes}
            />
          </ClientCard>
        ))
      }
      <AddAccountButton
        Icon={AddCircle}
        iconPosition="left"
        label="Add an account"
        onPress={handleLinkAccountClick}
      />
      <ConditionalRender
        Component={(
          <IllustrationWrapper>
            {DogSmartphoneIllustration}
          </IllustrationWrapper>
        )}
        shouldRender={clients.length < 2}
      />
    </ClientSelectPageContainer>
  );
};

ClientSelectView.propTypes = {
  clients: T.array.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  handleBiometricPinLogin: T.func.isRequired,
  handleLinkAccountClick: T.func.isRequired,
  handlePasswordLogin: T.func.isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  success: T.oneOfType([T.bool, T.string]).isRequired,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
};

export default ClientSelectView;
