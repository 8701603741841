import styled from 'styled-components/native';
import { SafeAreaView } from 'react-native-safe-area-context';

import { moderateScale } from '../../utils/scaleHelpers';

export const Container = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-grow: 1;
  padding: ${moderateScale(30)}px;
`;

export const StyledSafeAreaView = styled(SafeAreaView)`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
`;
