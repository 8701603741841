import React, { Fragment } from 'react';
import { Pressable } from 'react-native';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { TOOLTIP_PROPS } from '@dmi/shared/redux/Payments/OneTimePayment/constants';

import { ConditionalRender, P2 } from '../../../../base_ui';
import {
  BoldText,
  LinkText,
  NoteContainer,
  StyledH5,
} from './styledComponents';

const SchedulePaymentTooltip = ({
  handleCloseTooltip,
  maxMonth,
  selectedTooltip,
}) => {
  const {
    content,
    linkText,
    note,
    title,
  } = TOOLTIP_PROPS[selectedTooltip];

  return (
    <Fragment>
      <StyledH5><FormattedMessage id={title} /></StyledH5>
      <P2><FormattedMessage id={content} values={{ maxMonth }} /></P2>
      <ConditionalRender
        Component={(
          <NoteContainer>
            <P2>
              <BoldText>Note: </BoldText>
              {note}
            </P2>
          </NoteContainer>
        )}
        shouldRender={!!note}
      />
      <Pressable onPress={handleCloseTooltip}>
        <LinkText><FormattedMessage id={linkText} /></LinkText>
      </Pressable>
    </Fragment>
  );
};

SchedulePaymentTooltip.propTypes = {
  handleCloseTooltip: T.func.isRequired,
  maxMonth: T.string,
  selectedTooltip: T.string.isRequired,
};

export default SchedulePaymentTooltip;
