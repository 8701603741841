import React, { useState } from 'react';
import T from 'prop-types';
import moment from 'moment';

import { SecondaryHeader } from '../../../ScreenHeaders';
import { Calendar, StyledScrollView } from '../../../base_ui';
import { PaymentSummary } from '../../sharedComponents';
import Banner from './Banner';
import Legend from './Legend';
import { getFormattedDate } from './helpers';
import { ContentContainer } from './styledComponents';

const PaymentDate = ({
  availableDates,
  disableSelect,
  dueDate,
  handleDatePress,
  handleSelectDate,
  maxDelayDays,
  navigation: { goBack },
  scheduledDateForm,
  scheduledDateOtp,
}) => {
  const [isCurrentMonth, setIsCurrentMonth] = useState(true);

  const currentMonth = moment().month();
  const currentYear = moment().year();

  const handleChangeMonth = ({ month, year }) => (
    setIsCurrentMonth(month === currentMonth + 1 && year === currentYear)
  );

  return (
    <StyledScrollView
      showsVerticalScrollIndicator={false}
      stickyHeaderIndices={[0]}
    >
      <SecondaryHeader handleBack={() => goBack()} title="Select a Payment Date" />
      <ContentContainer>
        <Banner maxDelayDays={maxDelayDays} />
        <Calendar
          availableDates={availableDates}
          disabledByDefault
          dueDate={dueDate}
          handleChangeMonth={handleChangeMonth}
          handlePress={handleDatePress}
          isCurrentMonth={isCurrentMonth}
          selected={scheduledDateForm || scheduledDateOtp}
        />
        <Legend dueDateStr={getFormattedDate(dueDate)} />
      </ContentContainer>
      <PaymentSummary
        disabled={disableSelect}
        label="Select Payment Date"
        onPress={handleSelectDate}
        subText={`${scheduledDateForm && 'New '}Scheduled Date`}
        text={getFormattedDate(scheduledDateForm || scheduledDateOtp)}
      />
    </StyledScrollView>
  );
};

PaymentDate.propTypes = {
  availableDates: T.object.isRequired,
  disableSelect: T.bool.isRequired,
  dueDate: T.string.isRequired,
  handleDatePress: T.func.isRequired,
  handleSelectDate: T.func.isRequired,
  maxDelayDays: T.number.isRequired,
  navigation: T.object.isRequired,
  scheduledDateForm: T.string.isRequired,
  scheduledDateOtp: T.string.isRequired,
};

export default PaymentDate;
