import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { H5, P2 } from '../../../base_ui';

export const ItemText = styled(P2)`
  flex: 1;
  padding-bottom: ${moderateScale(10)}px;
`;

export const ListItem = styled.View`
  flex-direction: row;
`;

export const TooltipContainer = styled.View`
  width: 100%;
`;

export const TooltipHeading = styled(H5)`
  max-width: auto;
  margin-bottom: ${moderateScale(10)}px;
`;

export const TooltipLink = styled(P2)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
`;
