import { Platform } from 'react-native';
import styled, { css } from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';

export const Container = styled.View`
  background-color: transparent;
  height: ${moderateScale(12)}px;
  overflow: hidden;
  width: 100%;
`;

export const Shadow = styled.View`
  background-color: rgba(189, 197, 205, 0.15);
  height: ${moderateScale(12)}px;

  ${Platform.select({
    android: css`
      elevation: 7;
      top: -${moderateScale(12)}px;
    `,
    default: css`
      box-shadow: 0 12px 4px #E5E6E7;
      top: -${moderateScale(12)}px;
    `,
    ios: css`
      box-shadow: 0 -8px 4px rgba(100, 100, 100, 1);
    `,
  })}
`;
