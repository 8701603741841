import React from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { ConditionalRender } from '../../base_ui';
import ClaimInfoComponent from './ClaimInfoComponent';
import ContactInformationCard from './ContactCard';
import DisbursementsComponent from './DisbursementsComponent';
import DocumentsComponent from './DocumentsComponent';
import { SecondaryHeader } from '../../ScreenHeaders';
import { ClaimContentContainer } from './styledComponents';

const ClaimDetailsComponent = ({
  claimDetails: {
    claimNumber,
    claimType,
    disbursed,
    disbursements,
    documents,
    pointOfContact,
  },
  navigation,
}) => (
  <View>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Claim Details"
    />
    <ClaimInfoComponent
      claimNumber={claimNumber}
      claimType={claimType}
    />
    <ClaimContentContainer>
      <DisbursementsComponent
        disbursed={disbursed}
        disbursements={disbursements}
      />
      <ConditionalRender
        Component={DocumentsComponent}
        propsToPassDown={{ documents }}
        shouldRender={!!documents.length}
      />
      <ContactInformationCard pointOfContact={pointOfContact} />
    </ClaimContentContainer>
  </View>

);

ClaimDetailsComponent.propTypes = {
  claimDetails: T.shape({
    claimNumber: T.string.isRequired,
    claimType: T.string.isRequired,
    disbursed: T.string.isRequired,
    disbursements: T.array.isRequired,
    documents: T.array.isRequired,
    pointOfContact: T.object.isRequired,
  }).isRequired,
  navigation: T.object.isRequired,
};

export default ClaimDetailsComponent;
