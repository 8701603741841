import React from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import {
  Card,
  ErrorContainer,
  ErrorHeader,
  ErrorText,
  StyledDivider,
  StyledLink,
} from './styledComponents';

const Icon = iconDictionary('errorStorm');

const ProfileTransferError = ({
  header,
  linkLabel,
  mobilePath,
  text,
}) => (
  <Card>
    <ErrorContainer>
      {Icon}
      <ErrorHeader>{header}</ErrorHeader>
      <ErrorText>{text}</ErrorText>
      <StyledDivider />
      <StyledLink label={linkLabel} path={mobilePath} />
    </ErrorContainer>
  </Card>
);

ProfileTransferError.propTypes = {
  header: T.string.isRequired,
  linkLabel: T.string.isRequired,
  mobilePath: T.string.isRequired,
  text: T.string.isRequired,
};

export default ProfileTransferError;
