/* eslint-disable react/no-array-index-key */
import React from 'react';
import { View } from 'react-native';

import { Skeleton } from '../../components/base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

const rowArray = new Array(6).fill(null);

const SkeletonView = () => (
  <View>
    <Skeleton
      $bottomMargin={`${moderateScale(31)}px`}
      height={`${moderateScale(24)}px`}
      width="100%"
    />
    {rowArray.map((val, index) => (
      <Skeleton
        key={index}
        $bottomMargin={`${moderateScale(8)}px`}
        height={`${moderateScale(14)}px`}
        width="100%"
      />
    ))}
    <Skeleton
      $bottomMargin={`${moderateScale(30)}px`}
      height={`${moderateScale(14)}px`}
      width="50%"
    />
    {rowArray.map((val, index) => (
      <Skeleton
        key={index}
        $bottomMargin={`${moderateScale(8)}px`}
        height={`${moderateScale(14)}px`}
        width="100%"
      />
    ))}
    <Skeleton
      $bottomMargin={`${moderateScale(30)}px`}
      height={`${moderateScale(14)}px`}
      width="50%"
    />
    {rowArray.map((val, index) => (
      <Skeleton
        key={index}
        $bottomMargin={`${moderateScale(8)}px`}
        height={`${moderateScale(14)}px`}
        width="100%"
      />
    ))}
    <Skeleton
      $bottomMargin={`${moderateScale(30)}px`}
      height={`${moderateScale(14)}px`}
      width="50%"
    />
  </View>
);

export default SkeletonView;
