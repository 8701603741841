import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';
import { P4 } from '../../base_ui';

/*
  Using this extra wrapper/container instead of styled(CheckboxWithText).attrs(({ containerStyle })
  because of a current bug on react-native-web that makes it impossible to consistently overwrite
  generic styles (eg, 'margin') with specific styles (eg, 'margin-top').
  See https://github.com/necolas/react-native-web/issues/2007.
*/
export const CheckboxWithTextContainer = styled.View`
  margin-top: ${moderateScale(8)}px;
  padding-vertical: ${moderateScale(16)}px;
`;

/*
  StyledScrollView base ui not used here because the hide keyboard
  functionality it implements causes a bug with the disclosure view
  specifically
*/
/* eslint-disable object-curly-newline */
export const ContentContainer = styled.ScrollView.attrs({
  contentContainerStyle: { flexGrow: 1, paddingVertical: 24 },
})`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  padding-horizontal: ${moderateScale(24)}px;
`;

export const StyledP4 = styled(P4)`
  padding-bottom: ${moderateScale(10)}px;
`;
