import React from 'react';
import T from 'prop-types';

import { FormattedMessage } from '../../../../base_ui';
import { ICON_DICT } from './constants';
import { Container, IconWrapper, StyledText } from './styledComponents';

const Row = ({
  messageValues,
  noBottomPadding,
  text,
  variant,
}) => (
  <Container noBottomPadding={noBottomPadding}>
    <IconWrapper>
      {ICON_DICT[variant]}
    </IconWrapper>
    <FormattedMessage
      defaultMessage={text}
      values={messageValues}
      WrapperComponent={StyledText}
    />
  </Container>
);

Row.propTypes = {
  messageValues: T.object.isRequired,
  noBottomPadding: T.bool.isRequired,
  text: T.string.isRequired,
  variant: T.oneOf(['info', 'pending', 'warning']).isRequired,
};

export default Row;
