import React, { useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  fetchDisclosure,
  resetErrorState,
  resetRequestStatusState,
} from '@dmi/shared/redux/Disclosures/actions';
import makeSelectDisclosures, { makeSelectDisclosuresContent }
from '@dmi/shared/redux/Disclosures/selectors';
import { selectLanguage } from '@dmi/shared/redux/Main/selectors';

import { usePrevious } from '../../utils/customHooks';
import AsyncRender from '../../components/AsyncRender';
import EditorContent from '../../components/EditorContent';
import { ConditionalRender } from '../../components/base_ui';
import SkeletonView from './SkeletonView';

const Disclosures = ({
  disclosureName = '',
  disclosures,
  dispatchFetchDisclosure,
  dispatchResetErrorState,
  dispatchResetRequestStatusState,
  error,
  handleCloseDrawer, // Conditionally passed from Disclaimers
  language,
  requestStatus,
}) => {
  const prevLanguage = usePrevious(language);

  useEffect(() => {
    const hasLanguageChanged = prevLanguage !== language;
    const shouldFetchDisclosure = !disclosures[disclosureName] || hasLanguageChanged;
    if (shouldFetchDisclosure) {
      dispatchFetchDisclosure({ disclosureName, language });
    }
  }, [
    disclosureName,
    disclosures,
    dispatchFetchDisclosure,
    language,
    prevLanguage,
  ]);

  useEffect(() => () => {
    dispatchResetErrorState();
    dispatchResetRequestStatusState();
  }, [dispatchResetErrorState, dispatchResetRequestStatusState]);

  const { content } = disclosures[disclosureName] || {};
  return (
    <AsyncRender
      Component={ConditionalRender}
      error={error}
      errorComponentProps={{
        containerStyle: { bottom: 0 },
        link: {},
      }}
      errorComponentType="pageLevel"
      loading={requestStatus === 'loading'}
      LoadingComponent={SkeletonView}
      propsToPassDown={{
        Component: EditorContent,
        propsToPassDown: { content, handleCloseDrawer },
        shouldRender: !!content,
      }}
    />
  );
};

Disclosures.propTypes = {
  disclosureName: T.string,
  disclosures: T.object.isRequired,
  dispatchFetchDisclosure: T.func.isRequired,
  dispatchResetErrorState: T.func.isRequired,
  dispatchResetRequestStatusState: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  handleCloseDrawer: T.func,
  language: T.string.isRequired,
  requestStatus: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Reducer: Disclosures
   */
  disclosures: makeSelectDisclosuresContent(),
  error: makeSelectDisclosures('error'),
  requestStatus: makeSelectDisclosures('requestStatus'),
  /**
   * Reducer: Main
   */
  // eslint-disable-next-line sort-keys
  language: selectLanguage(),
});

function mapDispatchToProps(dispatch) {
  return {
    /**
     * Reducer: Disclosures
     */
    dispatchFetchDisclosure: (payload) => dispatch(fetchDisclosure(payload)),
    dispatchResetErrorState: () => dispatch(resetErrorState()),
    dispatchResetRequestStatusState: () => dispatch(resetRequestStatusState()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(Disclosures);
