/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function Calculator(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect fill="#ffffff" height="76.41" rx="2.44" width="57.68" x="12.91" y="2.59" />
      <Path d="M67.77,2.59h-3.6l2.1,3.07V76l-1.76,3h3.26a2.81,2.81,0,0,0,2.81-2.82V5.4A2.81,2.81,0,0,0,67.77,2.59Z" fill="#e6ebef" />
      <Path d="M12.91,37.63V5.4a2.81,2.81,0,0,1,2.81-2.81H67.77A2.81,2.81,0,0,1,70.58,5.4V76.18A2.81,2.81,0,0,1,67.77,79h-52a2.81,2.81,0,0,1-2.81-2.82V40.94" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.37" />
      <Path d="M66,73.86v2.32A2.81,2.81,0,0,1,63.21,79" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="66.02" x2="66.02" y1="11.84" y2="70.32" />
      <Path d="M63.21,2.59A2.81,2.81,0,0,1,66,5.4V9.29" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" />
      <Rect fill="#e6ebef" height="10.85" width="39.23" x="19.99" y="14.08" />
      <Polyline fill="none" points="24.7 24.93 19.99 24.93 19.99 14.08 59.22 14.08 59.22 24.93 26.65 24.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="52.45" x2="59.33" y1="7.94" y2="7.94" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="19.66" x2="49.36" y1="7.94" y2="7.94" />
      <Circle cx="26.22" cy="19.43" r="0.84" />
      <Circle cx="32.58" cy="19.43" r="0.84" />
      <Circle cx="38.95" cy="19.43" r="0.84" />
      <Circle cx="45.31" cy="19.43" r="0.84" />
      <Circle cx="51.68" cy="19.43" r="0.84" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="19.99" y="33.51" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.63" y="33.51" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="49.26" y="33.51" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="19.99" y="47.67" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.63" y="47.67" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="19.99" y="61.83" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.63" y="61.83" />
      <Path d="M58.23,47.67h-7.9a1.07,1.07,0,0,0-1.07,1.07V69.42a1.07,1.07,0,0,0,1.07,1.07h7.9a1.06,1.06,0,0,0,1.06-1.07V48.74A1.06,1.06,0,0,0,58.23,47.67Z" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="19.99" y="33.51" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.63" y="33.51" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="49.26" y="33.51" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="19.99" y="47.67" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.63" y="47.67" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="19.99" y="61.83" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.63" y="61.83" />
      <Path d="M58.23,47.67h-7.9a1.07,1.07,0,0,0-1.07,1.07V69.42a1.07,1.07,0,0,0,1.07,1.07h7.9a1.06,1.06,0,0,0,1.06-1.07V48.74A1.06,1.06,0,0,0,58.23,47.67Z" fill={svgHighlight} />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="19.99" y="33.51" />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="34.63" y="33.51" />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="49.26" y="33.51" />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="19.99" y="47.67" />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="34.63" y="47.67" />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="19.99" y="61.83" />
      <Rect fill="#ffffff" height="8.66" rx="0.93" width="10.03" x="34.63" y="61.83" />
      <Path d="M58.23,47.67h-7.9a1.07,1.07,0,0,0-1.07,1.07V69.42a1.07,1.07,0,0,0,1.07,1.07h7.9a1.06,1.06,0,0,0,1.06-1.07V48.74A1.06,1.06,0,0,0,58.23,47.67Z" fill="#ffffff" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="49.32" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="61.78" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="61.78" />
      <Path d="M58.28,47.62H50.39a1.07,1.07,0,0,0-1.07,1.07V69.37a1.07,1.07,0,0,0,1.07,1.07h7.89a1.07,1.07,0,0,0,1.07-1.07V48.69A1.07,1.07,0,0,0,58.28,47.62Z" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="49.32" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="61.78" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="61.78" />
      <Path d="M58.28,47.62H50.39a1.07,1.07,0,0,0-1.07,1.07V69.37a1.07,1.07,0,0,0,1.07,1.07h7.89a1.07,1.07,0,0,0,1.07-1.07V48.69A1.07,1.07,0,0,0,58.28,47.62Z" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="49.32" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="61.78" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="61.78" />
      <Path d="M49.32,58.58V69.37a1.07,1.07,0,0,0,1.07,1.07h7.89a1.07,1.07,0,0,0,1.07-1.07V48.69a1.07,1.07,0,0,0-1.07-1.07H50.39a1.07,1.07,0,0,0-1.07,1.07v9.89" fill={svgHighlight} />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="49.32" y="33.46" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="47.62" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="20.05" y="61.78" />
      <Rect fill={svgHighlight} height="8.66" rx="0.93" width="10.03" x="34.68" y="61.78" />
      <Path d="M49.32,59.25V69.37a1.07,1.07,0,0,0,1.07,1.07h7.89a1.07,1.07,0,0,0,1.07-1.07V48.69a1.07,1.07,0,0,0-1.07-1.07H50.39a1.07,1.07,0,0,0-1.07,1.07V59.25" fill="#e6ebef" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="19.99" y="33.51" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="34.63" y="33.51" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="49.26" y="33.51" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="19.99" y="47.67" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="34.63" y="47.67" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="19.99" y="61.83" />
      <Rect fill="none" height="8.66" rx="0.93" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" width="10.03" x="34.63" y="61.83" />
      <Path d="M49.26,61.63v7.79a1.07,1.07,0,0,0,1.07,1.07h7.9a1.06,1.06,0,0,0,1.06-1.07V48.74a1.06,1.06,0,0,0-1.06-1.07h-7.9a1.07,1.07,0,0,0-1.07,1.07V59.17" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="4.65" x2="4.65" y1="79.42" y2="77.55" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="4.65" x2="4.65" y1="83.5" y2="81.63" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="5.75" x2="7.63" y1="80.52" y2="80.52" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="1.67" x2="3.55" y1="80.52" y2="80.52" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="81.05" x2="83.49" y1="12.6" y2="10.16" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="76.53" x2="78.96" y1="17.12" y2="14.68" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="81.05" x2="83.49" y1="14.68" y2="17.12" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.87" x1="76.53" x2="78.96" y1="10.16" y2="12.6" />
    </Svg>
  );
}
