/* eslint-disable consistent-return */
import { MOBILE_CARD_BUTTON_DICTIONARY as DICT }
from '@dmi/shared/utils/marketingTilesProps';

export const cardButtonPropsHelper = ({ isMurabahaAccount, navigation }) => {
  if (isMurabahaAccount) {
    const { screen } = DICT.documents;
    const onPress = () => navigation.navigate(screen);
    return { ...DICT.documents, onPress };
  }
  const { nestedScreen, screen } = DICT.learningCenter;
  const onPress = () => navigation.navigate(screen, { nestedScreen });
  return { ...DICT.learningCenter, onPress };
};
