import React from 'react';
import { FormattedMessage } from 'react-intl';
import { View } from 'react-native';

import { INTL_IDS } from '@dmi/shared/redux/Mortgage/messages';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { ConditionalRender, SecondaryDivider, Skeleton } from '../../../../base_ui';
import {
  CardContainer,
  CardContentContainer,
  CardDivider,
  CardHeaderContainer,
  DataBox,
  DataContainer,
  DataDivider,
  HeaderContainer,
  HomeContainer,
  HomeH1,
  RowDivider,
  TableContainer,
} from '../styledComponents';
import {
  LinkContainer,
  RowContainer,
  RowStatusContainer,
} from './styledComponents';
import Layout from '../../../../../constants/Layout';

const { isTablet } = Layout;

const MortgageHomeLoading = () => (
  <HomeContainer>
    <HomeH1>
      <FormattedMessage id={INTL_IDS.MORTGAGE_HOME_TITLE} />
    </HomeH1>
    <HeaderContainer $center={isTablet}>
      <Skeleton
        $rightMargin={`${moderateScale(20)}px`}
        height={`${moderateScale(100)}px`}
        width={`${moderateScale(100)}px`}
      />
      <View>
        <Skeleton
          $bottomMargin={`${moderateScale(8)}px`}
          height={`${moderateScale(16)}px`}
          width={`${moderateScale(184)}px`}
        />
        <Skeleton
          height={`${moderateScale(16)}px`}
          width={`${moderateScale(184)}px`}
        />
      </View>
    </HeaderContainer>
    <SecondaryDivider />
    <CardContainer>
      <CardHeaderContainer $center={isTablet}>
        <Skeleton
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(18)}px`}
        />
        <Skeleton
          $leftMargin={`${moderateScale(8)}px`}
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(164)}px`}
        />
      </CardHeaderContainer>
      <CardContentContainer $center={isTablet}>
        <DataContainer>
          <DataBox>
            <Skeleton
              height={`${moderateScale(10)}px`}
              width={`${moderateScale(95)}px`}
            />
            <Skeleton
              $topMargin={`${moderateScale(9)}px`}
              height={`${moderateScale(18)}px`}
              width={`${moderateScale(95)}px`}
            />
          </DataBox>
          <DataDivider />
          <DataBox>
            <Skeleton
              height={`${moderateScale(10)}px`}
              width={`${moderateScale(95)}px`}
            />
            <Skeleton
              $topMargin={`${moderateScale(9)}px`}
              height={`${moderateScale(18)}px`}
              width={`${moderateScale(95)}px`}
            />
          </DataBox>
          <DataDivider />
          <DataBox>
            <Skeleton
              height={`${moderateScale(10)}px`}
              width={`${moderateScale(95)}px`}
            />
            <Skeleton
              $topMargin={`${moderateScale(9)}px`}
              height={`${moderateScale(18)}px`}
              width={`${moderateScale(95)}px`}
            />
          </DataBox>
        </DataContainer>
      </CardContentContainer>
      <CardDivider />
      <LinkContainer>
        <Skeleton
          height={`${moderateScale(14)}px`}
          width={`${moderateScale(127)}px`}
        />
      </LinkContainer>
      <SecondaryDivider />
    </CardContainer>
    <CardContainer>
      <CardHeaderContainer $center={isTablet}>
        <Skeleton
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(18)}px`}
        />
        <Skeleton
          $leftMargin={`${moderateScale(8)}px`}
          height={`${moderateScale(18)}px`}
          width={`${moderateScale(235)}px`}
        />
      </CardHeaderContainer>
      <CardContentContainer $center={isTablet}>
        <TableContainer>
          {Array(5).fill(null).map((val, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <View key={`skeleton-${index}`}>
              <RowContainer>
                <View>
                  <Skeleton
                    $bottomMargin={`${moderateScale(6)}px`}
                    height={`${moderateScale(12)}px`}
                    width={`${moderateScale(131)}px`}
                  />
                  <RowStatusContainer>
                    <Skeleton
                      $borderRadius={`${moderateScale(12)}px`}
                      $rightMargin={`${moderateScale(4)}px`}
                      height={`${moderateScale(6)}px`}
                      width={`${moderateScale(6)}px`}
                    />
                    <Skeleton
                      height={`${moderateScale(10)}px`}
                      width={`${moderateScale(121)}px`}
                    />
                  </RowStatusContainer>
                </View>
                <Skeleton
                  height={`${moderateScale(12)}px`}
                  width={`${moderateScale(47)}px`}
                />
              </RowContainer>
              <ConditionalRender
                Component={RowDivider}
                shouldRender={index !== 4}
              />
            </View>
          ))}
        </TableContainer>
      </CardContentContainer>
      <CardDivider />
      <LinkContainer>
        <Skeleton
          height={`${moderateScale(14)}px`}
          width={`${moderateScale(190)}px`}
        />
      </LinkContainer>
      <SecondaryDivider />
    </CardContainer>
  </HomeContainer>
);

export default MortgageHomeLoading;
