import { handleCompleteConfirmationPin } from '@dmi/shared/redux/Settings/Security/helpers';

import { PIN_CARD_PROPS, PIN_CONFIRMATION_CARD_PROPS } from './constants';

export const getPinFormStepBasedProps = ({
  dispatchChangeInput,
  dispatchChangeStep,
  dispatchFocusInput,
  dispatchResetDrawerInputsAndErrors,
  dispatchSetPin,
  dispatchUpdateError,
  formValues,
  formValues: { confirmPin, pin },
  setIsDrawerOpen,
  step,
}) => {
  if (step === 1) {
    return {
      handleBack: () => setIsDrawerOpen(false),
      handleChangeInput: ({ value }) => dispatchChangeInput({ field: 'pin', value }),
      handleCompletePin: () => {
        dispatchChangeStep({ step: step + 1 });
        dispatchFocusInput({ field: 'passcode' });
      },
      pinValue: pin,
      ...PIN_CARD_PROPS,
    };
  }
  return {
    handleBack: () => {
      dispatchChangeStep({ step: step - 1 });
      dispatchFocusInput({ field: 'passcode' });
      dispatchResetDrawerInputsAndErrors();
    },
    handleChangeInput: ({ value }) => dispatchChangeInput({ field: 'confirmPin', value }),
    handleCompletePin: (value) =>
      handleCompleteConfirmationPin({
        dispatchFocusInput,
        dispatchSetPin,
        dispatchUpdateError,
        formValues,
        setIsDrawerOpen,
        value,
      }),
    pinValue: confirmPin,
    ...PIN_CONFIRMATION_CARD_PROPS,
  };
};
