/* eslint-disable max-len */
import React from 'react';
import { Platform } from 'react-native';
import Svg, {
  ClipPath, Defs, G, Path, Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavPaymentsDefaultLIcon(props) {
  const clipPath = Platform.OS === 'ios' ? 'url(#clip0)' : '';
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={clipPath}>
        <Path d="M23.3404 5.69995H0.710352C0.551222 5.69995 0.398609 5.76317 0.286087 5.87569C0.173566 5.98821 0.110352 6.14082 0.110352 6.29995V18.62C0.110352 18.6987 0.125871 18.7768 0.156024 18.8496C0.186177 18.9224 0.230372 18.9885 0.286087 19.0442C0.341803 19.0999 0.407946 19.1441 0.480742 19.1743C0.553537 19.2044 0.631558 19.22 0.710352 19.22H23.3404C23.4187 19.22 23.4962 19.2044 23.5685 19.1741C23.6407 19.1438 23.7062 19.0995 23.7611 19.0436C23.816 18.9878 23.8593 18.9215 23.8883 18.8488C23.9174 18.7761 23.9317 18.6983 23.9304 18.62V6.29995C23.9317 6.22163 23.9174 6.14384 23.8883 6.0711C23.8593 5.99836 23.816 5.93213 23.7611 5.87628C23.7062 5.82043 23.6407 5.77607 23.5685 5.7458C23.4962 5.71553 23.4187 5.69994 23.3404 5.69995ZM22.7404 18H1.30035V6.89995H22.7404V18Z" fill="black" />
        <Path d="M12.0004 16.14C12.7045 16.14 13.3928 15.9312 13.9783 15.5401C14.5637 15.1489 15.02 14.5929 15.2894 13.9424C15.5589 13.2919 15.6294 12.5761 15.492 11.8855C15.3547 11.1949 15.0156 10.5606 14.5177 10.0627C14.0199 9.56485 13.3855 9.22579 12.695 9.08843C12.0044 8.95106 11.2886 9.02156 10.6381 9.29101C9.98757 9.56046 9.43158 10.0168 9.0404 10.6022C8.64922 11.1876 8.44043 11.8759 8.44043 12.58C8.44043 13.0475 8.53251 13.5105 8.71142 13.9424C8.89033 14.3743 9.15255 14.7667 9.48313 15.0973C10.1508 15.765 11.0563 16.14 12.0004 16.14ZM12.0004 10C12.5166 10 13.0213 10.1531 13.4505 10.4399C13.8797 10.7267 14.2142 11.1343 14.4118 11.6112C14.6093 12.0881 14.661 12.6129 14.5603 13.1192C14.4596 13.6255 14.211 14.0906 13.846 14.4556C13.481 14.8206 13.0159 15.0692 12.5096 15.1699C12.0033 15.2706 11.4785 15.2189 11.0016 15.0213C10.5247 14.8238 10.1171 14.4893 9.83029 14.0601C9.5435 13.6308 9.39043 13.1262 9.39043 12.61C9.39043 11.9178 9.66541 11.2539 10.1549 10.7645C10.6444 10.275 11.3082 10 12.0004 10Z" fill="black" />
        <Path d="M5.00031 13.7999C5.23609 13.8019 5.46714 13.7338 5.66414 13.6042C5.86115 13.4747 6.01523 13.2895 6.10683 13.0722C6.19844 12.855 6.22343 12.6154 6.17865 12.3839C6.13387 12.1524 6.02133 11.9394 5.85531 11.772C5.68929 11.6046 5.47728 11.4903 5.24617 11.4435C5.01507 11.3968 4.77529 11.4198 4.55726 11.5095C4.33923 11.5993 4.15279 11.7518 4.02158 11.9477C3.89037 12.1436 3.82032 12.3741 3.82031 12.6099C3.8203 12.9238 3.9443 13.2249 4.16531 13.4478C4.38632 13.6707 4.68644 13.7972 5.00031 13.7999Z" fill="black" />
        <Path d="M19.0599 13.7999C19.2957 13.8019 19.5267 13.7338 19.7237 13.6042C19.9207 13.4747 20.0748 13.2895 20.1664 13.0722C20.258 12.855 20.283 12.6154 20.2382 12.3839C20.1934 12.1524 20.0809 11.9394 19.9149 11.772C19.7489 11.6046 19.5369 11.4903 19.3057 11.4435C19.0746 11.3968 18.8349 11.4198 18.6168 11.5095C18.3988 11.5993 18.2124 11.7518 18.0812 11.9477C17.9499 12.1436 17.8799 12.3741 17.8799 12.6099C17.8799 12.9238 18.0039 13.2249 18.2249 13.4478C18.4459 13.6707 18.746 13.7972 19.0599 13.7999Z" fill="black" />
      </G>
      <Defs>
        <ClipPath id="clip0">
          <Rect fill="white" height="24" width="24" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}
