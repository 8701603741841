import styled from 'styled-components/native';

import { BorderlessButton, H1, H4 } from '../../../base_ui';
import { moderateScale } from '../../../../utils/scaleHelpers';

export const ButtonContainer = styled.View`
  flex-direction: column;
  align-items: center;
`;

export const CardContainer = styled.View`
  align-items: center;
  border: ${({ theme: { colors: { primaryGray } } }) => (
    `${moderateScale(1)}px solid ${primaryGray};`
  )};
  border-radius: ${moderateScale(8)}px;
  flex-direction: row;
  min-height: ${moderateScale(72)}px;
  margin-bottom: ${moderateScale(12)}px;
  padding: ${moderateScale(12)}px 0 ${moderateScale(12)}px ${moderateScale(20)}px;
  width: 100%;
`;

export const CardContent = styled.View`
  flex: 1;
  margin-left: ${moderateScale(16)}px;
  margin-right: ${moderateScale(8)}px;
`;

export const CardText = styled(H1)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const DownloadButton = styled(BorderlessButton).attrs(({ titleStyle }) => ({
  titleStyle: [
    titleStyle,
    {
      paddingLeft: moderateScale(4),
      paddingVertical: 0,
    },
  ],
}))`
  height: ${moderateScale(34)}px;
  margin-bottom: ${moderateScale(11)}px;
`;

export const HeaderText = styled(H4)`
  margin: ${moderateScale(20)}px ${moderateScale(14)}px ${moderateScale(22)}px;
  text-align: center;
`;

export const NoProceedButtonSpacer = styled.View`
  height: ${moderateScale(36)}px;
`;

export const ProceedButton = styled(BorderlessButton).attrs(({ titleStyle }) => ({
  titleStyle: [
    titleStyle,
    { paddingVertical: 0 },
  ],
}))`
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  borderBottomLeftRadius: ${moderateScale(8)}px;
  borderBottomRightRadius: ${moderateScale(8)}px;
  height: ${moderateScale(36)}px;
  width: 100%;
`;

export const ResultsContainer = styled.View`
  padding-horizontal: ${moderateScale(24)}px;
  width: 100%;
`;

export const ResultsContentContainer = styled.View`
  border: ${({ theme: { colors: { primaryGray } } }) => (
    `${moderateScale(1)}px solid ${primaryGray};`
  )};
  border-radius: ${moderateScale(8)}px;
`;
