import styled from 'styled-components/native';

import {
  BaseExternalLink,
  BorderlessButton,
  P3,
  P5,
  PrimaryDivider,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BottomText = styled(P5)`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
  font-size: ${({ theme: { fontSizes: { f1 } } }) => f1}px;
  text-align: center;
`;

export const ChapterSevenBankruptcyContainer = styled.View`
  flex-direction: column;
  margin: 0 auto ${moderateScale(20.5)}px;
`;

export const ChapterSevenHeader = styled.Text`
  font-size: ${moderateScale(10)}px;
  font-weight: 700;
  line-height: ${moderateScale(15)}px;
  margin: 0 auto ${moderateScale(2)}px;
  text-align: center;
`;

export const ChapterSevenText = styled.Text`
  font-size: ${moderateScale(10)}px;
  line-height: ${moderateScale(13)}px;
  text-align: center;
`;

export const DisclosureHeading = styled(P3)`
  color: ${({ theme: { colors: { footerText } } }) => footerText};
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-size: ${({ theme: { fontSizes: { f2 } } }) => f2}px;
  padding: ${moderateScale(8)}px 0 ${moderateScale(12)}px;
  text-align: center;
`;

export const DisclosureLinksContainer = styled.View`
  align-self: center;
  flex-direction: column;
  flex-wrap: wrap;
  /* TODO: V2 - switch back to space-between when disclosures link is added */
  /* justify-content: space-between; */
  justify-content: center;
  padding-bottom: ${moderateScale(12)}px;
`;

export const FooterContainer = styled.View`
  background-color: ${({ theme: { colors: { footerBackground } } }) => footerBackground};
  padding: ${moderateScale(20)}px;
  width: 100%;
`;

export const StyledBaseExternalLink = styled(BaseExternalLink)`
  align-items: center;
  color: ${({ theme: { colors: { footerLinkText, linkText } } }) => footerLinkText || linkText};
  background-color: ${({ theme: { colors: { footerBackground } } }) => footerBackground};
  font-size: ${({ theme: { fontSizes: { f2 } } }) => f2}px;
`;

export const StyledDivider = styled(PrimaryDivider)`
  width: 100%;
  min-width: 100%;
`;

export const StyledFooterButton = styled(BorderlessButton)
  .attrs(({
    theme: {
      colors: { footerLinkText, linkText },
      fontSizes: { f2 },
    },
    titleStyle,
  }) => ({
    titleStyle: [
      titleStyle,
      {
        color: footerLinkText || linkText,
        fontSize: f2,
        paddingVertical: moderateScale(8),
        textAlign: 'center',
      },
    ],
  }))``;
