import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';

import iconDictionary from '../../../../../utils/iconDictionary';
import { CardH4, CardHeaderContainer } from './styledComponents';

const LoanIcon = iconDictionary('navMortgageDefaultM');

const CardHeader = ({ unmaskedLoanNumber }) => (
  <CardHeaderContainer>
    {LoanIcon}
    <CardH4>
      <FormattedMessage
        id={INTL_IDS.HOME_LOANCARD_HEADING}
        values={{ loan: unmaskedLoanNumber }}
      />
    </CardH4>
  </CardHeaderContainer>
);

CardHeader.propTypes = { unmaskedLoanNumber: T.string.isRequired };

export default CardHeader;
