/* eslint-disable no-unused-vars */
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import * as SplashScreen from 'expo-splash-screen';
import * as StoreReview from 'expo-store-review';
import { connectActionSheet } from '@expo/react-native-action-sheet';
import { ThemeProvider } from 'styled-components/native';

import { DAEMON, RESTART_ON_REMOUNT } from '@dmi/shared/utils/constants';
import disclosuresReducer from '@dmi/shared/redux/Disclosures/reducer';
import disclosuresSaga from '@dmi/shared/redux/Disclosures/saga';
import { selectGoldMigrationPLCookiesBoolean } from '@dmi/shared/redux/GoldRedirect/selectors';
import {
  initializeRequest,
  setLanguagePicker,
  setShouldRenderSplashScreen,
} from '@dmi/shared/redux/Main/actions';
import mainReducer from '@dmi/shared/redux/Main/reducer';
import mainSaga from '@dmi/shared/redux/Main/saga';
import makeSelectMain, {
  getClientFontToUse,
  getError,
  getIsSso,
  getMainClientInfo,
  selectIsMurabahaAccount,
  selectMobileThemeColors,
} from '@dmi/shared/redux/Main/selectors';
import { setIsInAppReviewSupported, setIsMobile } from '@dmi/shared/redux/Mobile/actions';
import { resetPostAuthNotifications } from '@dmi/shared/redux/Notifications/actions';
import { checkBiometricsAndPinInfo } from '@dmi/shared/redux/Settings/Security/actions';
import communicationsReducer from '@dmi/shared/redux/Settings/Communications/reducer';
import communicationsSaga from '@dmi/shared/redux/Settings/Communications/saga';
import feesReducer from '@dmi/shared/redux/Fees/reducer';
import feesSaga from '@dmi/shared/redux/Fees/saga';
import goldRedirectReducer from '@dmi/shared/redux/GoldRedirect/reducer';
import lossDraftReducer from '@dmi/shared/redux/LossDraft/reducer';
import lossDraftSaga from '@dmi/shared/redux/LossDraft/saga';
import notificationsReducer from '@dmi/shared/redux/Notifications/reducer';
import notificationsSaga from '@dmi/shared/redux/Notifications/saga';
import payoffReducer from '@dmi/shared/redux/Payoff/reducer';
import payoffSaga from '@dmi/shared/redux/Payoff/saga';
import registerReducer from '@dmi/shared/redux/Register/Main/reducer';
import registerSaga from '@dmi/shared/redux/Register/Main/saga';
import secureMessagingReducer from '@dmi/shared/redux/SecureMessaging/reducer';
import secureMessagingSaga from '@dmi/shared/redux/SecureMessaging/saga';
import securityReducer from '@dmi/shared/redux/Settings/Security/reducer';
import securitySaga from '@dmi/shared/redux/Settings/Security/saga';

import { usePrevious } from '../../utils/customHooks';
import { ConditionalRender } from '../../components/base_ui';
import LanguagePicker from '../../components/LanguagePicker';
import LoadingDog from '../../components/LoadingDog';
import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../utils/globalHelpers';
import { navigate, navigationRef } from '../../utils/rootNavigation';
import { StyledSafeAreaView } from './styledComponents';
import AppContent from './AppContent';
import SplashScreenAnimation from './SplashScreenAnimation';
import appTheme from '../../utils/theme';
import useCachedResources from '../../hooks/useCachedResources';

export const Main = ({
  customerServicePhoneNumber,
  dispatchCheckBiometricsAndPinInfo,
  dispatchInitializeRequest,
  dispatchResetPostAuthNotifications,
  dispatchSetIsInAppReviewSupported,
  dispatchSetIsMobile,
  dispatchSetLanguagePicker,
  dispatchSetShouldRenderSplashScreen,
  error,
  fontToUse: { primaryFont, secondaryFont },
  internationalization: { language },
  isGoldMigrationPLGeneralLogin,
  isGoldMigrationPLPreLogin,
  isGoldMigrationPLProfileTransfer,
  isInactivityModalOpen,
  isInitComplete,
  isLoggedIn,
  isLoggedOutViaSsoGeneric,
  isMurabahaAccount,
  isSslAuth,
  isSso,
  loading,
  selectedLoan,
  setLanguage,
  shouldRenderSplashScreen,
  testFlags: { showLanguagePicker },
  themeColors,
  userEmail,
}) => {
  const prevIsLoggedIn = usePrevious(isLoggedIn);
  const [isFontLoading, setIsFontLoading] = useState(true);

  useEffect(() => {
    const isNativeMobile = Platform.OS !== 'web';
    dispatchSetIsMobile({ isNativeMobile });
    if (isNativeMobile) dispatchCheckBiometricsAndPinInfo();
    (async () => {
      const isInAppReviewSupported = await StoreReview.isAvailableAsync();
      dispatchSetIsInAppReviewSupported(isInAppReviewSupported);
    })();
  }, [
    dispatchCheckBiometricsAndPinInfo,
    dispatchSetIsInAppReviewSupported,
    dispatchSetIsMobile,
  ]);

  useEffect(() => {
    if (!isLoggedIn && !isInitComplete && !error) {
      dispatchInitializeRequest();
    }
  }, [
    dispatchInitializeRequest,
    error,
    isInitComplete,
    isLoggedIn,
  ]);

  useEffect(() => {
    if (!isLoggedIn && prevIsLoggedIn) {
      dispatchResetPostAuthNotifications();
    }
  }, [
    dispatchResetPostAuthNotifications,
    isLoggedIn,
    prevIsLoggedIn,
    selectedLoan,
  ]);

  useEffect(() => {
    if (language) {
      setLanguage(language);
    }
  }, [language, setLanguage]);

  const onLayoutAnimation = useCallback(async () => {
    /**
     * Per docs, the onLayout callback gets called 'immediately once the layout has been calculated,
     * but the new layout may not yet be reflected on the screen at the time the event is received'.
     * So we give a slight delay to let the pixels get painted before hiding the splash screen.
     */
    await new Promise((resolve) => setTimeout(resolve, 100));
    await SplashScreen.hideAsync();
  }, []);

  useCachedResources(primaryFont, secondaryFont, { isInitComplete, setIsFontLoading });

  return (
    <ThemeProvider
      theme={{
        ...appTheme,
        colors: { ...themeColors },
        fontFamilies: {
          primaryFont: primaryFont.name,
          secondaryFont: `${secondaryFont.name}Regular`,
          secondaryFontBold: `${secondaryFont.name}Bold`,
        },
      }}
    >
      <SafeAreaProvider>
        <StyledSafeAreaView>
          <ConditionalRender
            Component={LanguagePicker}
            propsToPassDown={{ dispatchSetLanguagePicker }}
            shouldRender={showLanguagePicker}
          />
          <ConditionalRender
            Component={LoadingDog}
            FallbackComponent={AppContent}
            propsToPassDown={{
              isGoldMigrationPLGeneralLogin,
              isGoldMigrationPLPreLogin,
              isGoldMigrationPLProfileTransfer,
              isLoggedIn,
              isLoggedOutViaSsoGeneric,
              isMurabahaAccount,
              isSslAuth,
              isSso,
            }}
            shouldRender={!isInitComplete && !error && isFontLoading}
          />
        </StyledSafeAreaView>
      </SafeAreaProvider>
      <ConditionalRender
        Component={SplashScreenAnimation}
        propsToPassDown={{
          dispatchSetShouldRenderSplashScreen,
          isInitComplete,
          onLayoutAnimation,
        }}
        shouldRender={shouldRenderSplashScreen}
      />
    </ThemeProvider>
  );
};

Main.propTypes = {
  customerServicePhoneNumber: T.string.isRequired,
  dispatchCheckBiometricsAndPinInfo: T.func.isRequired,
  dispatchInitializeRequest: T.func.isRequired,
  dispatchResetPostAuthNotifications: T.func.isRequired,
  dispatchSetIsInAppReviewSupported: T.func.isRequired,
  dispatchSetIsMobile: T.func.isRequired,
  dispatchSetLanguagePicker: T.func.isRequired,
  dispatchSetShouldRenderSplashScreen: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  fontToUse: T.object.isRequired,
  internationalization: T.shape({ language: T.string.isRequired }).isRequired,
  isGoldMigrationPLGeneralLogin: T.bool.isRequired,
  isGoldMigrationPLPreLogin: T.bool.isRequired,
  isGoldMigrationPLProfileTransfer: T.bool.isRequired,
  isInactivityModalOpen: T.bool.isRequired,
  isInitComplete: T.bool.isRequired,
  isLoggedIn: T.bool.isRequired,
  isLoggedOutViaSsoGeneric: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  loading: T.bool.isRequired,
  selectedLoan: T.string.isRequired,
  setLanguage: T.func.isRequired,
  shouldRenderSplashScreen: T.bool.isRequired,
  testFlags: T.object.isRequired,
  themeColors: T.object.isRequired,
  userEmail: T.string,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  error: getError('main'),
  fontToUse: getClientFontToUse(),
  internationalization: makeSelectMain('internationalization'),
  isInactivityModalOpen: makeSelectMain('isInactivityModalOpen'),
  isInitComplete: makeSelectMain('isInitComplete'),
  isLoggedIn: makeSelectMain('isLoggedIn'),
  isLoggedOutViaSsoGeneric: makeSelectMain('isLoggedOutViaSsoGeneric'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  isSslAuth: makeSelectMain('isSslAuth'),
  isSso: getIsSso(),
  loading: makeSelectMain('loading'),
  selectedLoan: makeSelectMain('selectedLoan'),
  shouldRenderSplashScreen: makeSelectMain('shouldRenderSplashScreen'),
  testFlags: makeSelectMain('testFlags'),
  themeColors: selectMobileThemeColors(),
  userEmail: makeSelectMain('userEmail'),
  /**
   * Store: GoldRedirect
   */
  // eslint-disable-next-line sort-keys
  isGoldMigrationPLGeneralLogin: selectGoldMigrationPLCookiesBoolean('goldMigrationPLGeneralLogin'),
  isGoldMigrationPLPreLogin: selectGoldMigrationPLCookiesBoolean('goldMigrationPLPreLogin'),
  isGoldMigrationPLProfileTransfer: selectGoldMigrationPLCookiesBoolean(
    'goldMigrationPLProfileTransfer',
  ),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Main
   */
  dispatchInitializeRequest: () => dispatch(initializeRequest()),
  dispatchSetLanguagePicker: (payload) => dispatch(setLanguagePicker(payload)),
  dispatchSetShouldRenderSplashScreen: (payload) => dispatch(setShouldRenderSplashScreen(payload)),
  /**
   * Store: Mobile
   */
  // eslint-disable-next-line sort-keys
  dispatchSetIsInAppReviewSupported: (payload) => dispatch(setIsInAppReviewSupported(payload)),
  dispatchSetIsMobile: (payload) => dispatch(setIsMobile(payload)),
  /**
   * Store: Notifications
   */
  // eslint-disable-next-line sort-keys
  dispatchResetPostAuthNotifications: () => dispatch(resetPostAuthNotifications()),
  /**
   * Store: Security
   */
  // eslint-disable-next-line sort-keys
  dispatchCheckBiometricsAndPinInfo: () => dispatch(checkBiometricsAndPinInfo()),
});
const baseUrl = getBaseApplicationServerUrl();
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withReducer = injectReducer({ key: 'main', reducer: mainReducer });
const withSaga = injectSaga({
  key: 'main',
  mode: DAEMON,
  saga: mainSaga({
    baseUrl,
    navigationHandler: navigate,
    navigationObj: navigationRef,
  }),
});
const withCommunicationsReducer = injectReducer({
  key: 'communications',
  reducer: communicationsReducer,
});
const withCommunicationsSaga = injectSaga({
  key: 'communications',
  mode: DAEMON,
  saga: communicationsSaga({ baseUrl }),
});
const withDisclosuresReducer = injectReducer({
  key: 'disclosures',
  reducer: disclosuresReducer,
});
const withDisclosuresSaga = injectSaga({
  key: 'disclosures',
  saga: disclosuresSaga({ baseUrl }),
});
const withFeesReducer = injectReducer({ key: 'fees', reducer: feesReducer });
const withFeesSaga = injectSaga({
  key: 'fees',
  saga: feesSaga({ baseUrl }),
});
const withGoldRedirectReducer = injectReducer({
  key: 'goldRedirect',
  reducer: goldRedirectReducer,
});
const withLossDraftReducer = injectReducer({
  key: 'lossDraft',
  reducer: lossDraftReducer,
});
const withLossDraftSaga = injectSaga({
  key: 'lossDraft',
  saga: lossDraftSaga({ baseUrl, navigationHandler: navigate }),
});
const withNotificationsReducer = injectReducer({
  key: 'notifications',
  reducer: notificationsReducer,
});
const withNotificationsSaga = injectSaga({
  key: 'notifications',
  saga: notificationsSaga({ baseUrl }),
});
const withPayoffReducer = injectReducer({ key: 'payoff', reducer: payoffReducer });
const withPayoffSaga = injectSaga({
  key: 'payoff',
  mode: RESTART_ON_REMOUNT,
  saga: payoffSaga({ baseUrl, navigationHandler: navigate }),
});
const withRegisterReducer = injectReducer({ key: 'register', reducer: registerReducer });
const withRegisterSaga = injectSaga({
  key: 'register',
  saga: registerSaga({ baseUrl }),
});
const withSecureMessagingReducer = injectReducer({
  key: 'secureMessaging',
  reducer: secureMessagingReducer,
});
const withSecureMessagingSaga = injectSaga({
  key: 'secureMessaging',
  saga: secureMessagingSaga({ baseUrl, navigationHandler: navigate }),
});
const withSecurityReducer = injectReducer({ key: 'security', reducer: securityReducer });
const withSecuritySaga = injectSaga({ key: 'security', saga: securitySaga });

export default compose(
  connectActionSheet,
  withConnect,
  withReducer,
  withSaga,
  withCommunicationsReducer,
  withCommunicationsSaga,
  withDisclosuresReducer,
  withDisclosuresSaga,
  withFeesReducer,
  withFeesSaga,
  withGoldRedirectReducer,
  withLossDraftReducer,
  withLossDraftSaga,
  withNotificationsReducer,
  withNotificationsSaga,
  withPayoffReducer,
  withPayoffSaga,
  withRegisterReducer,
  withRegisterSaga,
  withSecureMessagingReducer,
  withSecureMessagingSaga,
  withSecurityReducer,
  withSecuritySaga,
)(Main);
