/**
* BaseCheckbox
* @description: Checkbox button
* Use Case: Use when a single binary yes/no choice is provided or only one
*           option can be selected or deselected and its meaning is obvious
* Component Props: {
*   checked (bool) state determining whether checkbox is currently checked
*   Component (element) the checkbox icon to display
*   onPress (func) function invoked when container is pressed
* }
*/

import React from 'react';
import T from 'prop-types';
import { CheckBox } from 'react-native-elements';

const BaseCheckBox = ({
  checked,
  Component,
  onPress,
  ...restProps
}) => (
  <CheckBox
    activeOpacity={1}
    aria-checked={checked}
    checked={checked}
    Component={Component}
    onIconPress={onPress}
    onPress={onPress}
    role="checkbox"
    {...restProps}
  />
);

BaseCheckBox.propTypes = {
  checked: T.bool.isRequired,
  Component: T.oneOfType([T.element, T.elementType]),
  onPress: T.func.isRequired,
};

export default BaseCheckBox;
