import React from 'react';
import T from 'prop-types';

import { viewDictionary } from './constants';
import { MessageWrapper } from './styledComponents';

const NewMessageComponent = ({
  formMessage,
  handleOptionPress,
  isMurabahaAccount,
  navigation,
  requestStatusPostMessage,
  setViewToRender,
  viewToRender,
  ...restProps
}) => {
  const ComponentToRender = viewDictionary[viewToRender];

  const propsToPassDown = viewToRender === 'Topic' ? {
    handleOptionPress,
    isMurabahaAccount,
    selectedOption: formMessage.topic,
    setViewToRender,
  } : {
    formMessage,
    isMurabahaAccount,
    navigation,
    requestStatusPostMessage,
    setViewToRender,
    viewToRender,
    ...restProps,
  };

  return (
    <MessageWrapper>
      <ComponentToRender
        {...propsToPassDown}
      />
    </MessageWrapper>
  );
};

NewMessageComponent.propTypes = {
  formMessage: T.objectOf(T.string),
  handleOptionPress: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  requestStatusPostMessage: T.string.isRequired,
  setViewToRender: T.func.isRequired,
  viewToRender: T.string.isRequired,
};

export default NewMessageComponent;
