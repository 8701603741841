/*
* SSN/TIN Form Input
*/

import React, { Fragment } from 'react';
import T from 'prop-types';

import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import { PasswordTextField } from '../../base_ui';
import ButtonFooter from './ButtonFooter';
import {
  RegisterInputContainer,
  TextFieldWrapper,
} from './styledComponents';

const SsnTinFormInput = ({
  dispatchChangeInput,
  formErrors,
  formValues,
  handleBack,
  handleNextClick,
}) => (
  <Fragment>
    <RegisterInputContainer>
      <TextFieldWrapper>
        <PasswordTextField
          error={formErrors.ssnTin}
          keyboardType="number-pad"
          label="Last 4 Digits of SSN"
          onChangeText={(newValue) => dispatchChangeInput({
            field: 'ssnTin',
            form: 'initial',
            value: newValue,
          })}
          onSubmitEditing={() => handleNextClick({
            field: 'ssnTin',
            form: 'initial',
            formValues,
          })}
          returnKeyType="done"
          value={formValues.ssnTin}
        />
      </TextFieldWrapper>
    </RegisterInputContainer>
    <ButtonFooter
      disabled={!formValues.ssnTin}
      handleBack={handleBack}
      handleNextClick={() => handleNextClick({
        field: 'ssnTin',
        form: 'initial',
        formValues: trimFormValues(formValues),
      })}
      label="Next Step"
    />
  </Fragment>
);

SsnTinFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleBack: T.func.isRequired,
  handleNextClick: T.func.isRequired,
};

export default SsnTinFormInput;
