import React from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import ChildComponent from './ChildComponent';
import { moderateScale } from '../../../utils/scaleHelpers';
import { StyledModal } from './styledComponents';

const BottomDrawer = ({
  handleClose,
  isVisible,
  onModalHide,
  ...restProps
}) => (
  <StyledModal
    ariaHideApp={false}
    isVisible={isVisible}
    onBackdropPress={handleClose}
    onModalHide={onModalHide}
    onSwipeComplete={handleClose}
    propagateSwipe
    style={Platform.OS === 'web' ? { margin: `${moderateScale(46)}px 0.1px 0.1px` } : {}}
    supportedOrientations={['portrait', 'landscape']}
    swipeDirection={['down']}
  >
    <ChildComponent {...restProps} />
  </StyledModal>
);

BottomDrawer.propTypes = {
  handleClose: T.func.isRequired,
  isVisible: T.bool.isRequired,
  onModalHide: T.func,
};

export default BottomDrawer;
