import React, { useEffect } from 'react';
import T from 'prop-types';

import { CONFIRMATION_DICT } from '@dmi/shared/redux/SecureMessaging/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { ConditionalRender, P2, P4 } from '../../../base_ui';
import { SecondaryHeader } from '../../../ScreenHeaders';
import { cardButtonPropsHelper } from '../helpers';
import {
  CardButtonContainer,
  ConfirmationBodyContainer,
  ConfirmationContainer,
  ConfirmationHeader,
  ConfirmationText,
  DataLabel,
  DataLine,
  InfoMessageWrapper,
  PendingIconWrapper,
  StyledAnimation,
  StyledCardButton,
  TextContainer,
} from '../styledComponents';

const PendingIcon = iconDictionary('outlinedPendingPurple');

const NewMessageConfirmation = ({
  confirmationNumber,
  dispatchResetRequestStatus,
  handleBack,
  isMurabahaAccount,
  navigation,
}) => {
  const cardButtonProps = cardButtonPropsHelper({ isMurabahaAccount, navigation });
  const { success: { body, footerText, header } } = CONFIRMATION_DICT;
  const { label, value } = confirmationNumber[0];

  useEffect(() => () => {
    dispatchResetRequestStatus({ requestStatus: 'postMessage' });
  }, [dispatchResetRequestStatus]);

  return (
    <ConfirmationContainer>
      <SecondaryHeader
        handleDone={handleBack}
        title="Confirmation"
      />
      <ConfirmationBodyContainer>
        <StyledAnimation name="robotMobile" />
        <TextContainer>
          <ConfirmationHeader>{header}</ConfirmationHeader>
          <ConfirmationText>{body}</ConfirmationText>
          <ConditionalRender
            Component={(
              <DataLine>
                <DataLabel>{label}</DataLabel>
                <P2>{value}</P2>
              </DataLine>
            )}
            shouldRender={!!confirmationNumber}
          />
          <InfoMessageWrapper>
            <PendingIconWrapper>
              {PendingIcon}
            </PendingIconWrapper>
            <P4>{footerText}</P4>
          </InfoMessageWrapper>
        </TextContainer>
      </ConfirmationBodyContainer>
      <CardButtonContainer>
        <StyledCardButton {...cardButtonProps} />
      </CardButtonContainer>
    </ConfirmationContainer>
  );
};

NewMessageConfirmation.propTypes = {
  confirmationNumber: T.array.isRequired,
  dispatchResetRequestStatus: T.func.isRequired,
  handleBack: T.func.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
};

export default NewMessageConfirmation;
