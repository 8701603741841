import styled from 'styled-components/native';

import {
  BorderlessButton,
  H2,
  H3,
  P2,
  SecondaryCard,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const BoldText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
`;

export const ContactInformationContainer = styled.View`
  margin-bottom: ${moderateScale(32)}px;
  padding: 0 ${moderateScale(24)}px;
  width: 100%;
`;

export const StyledButton = styled(BorderlessButton).attrs(({ titleStyle }) => ({
  titleStyle: [
    titleStyle,
    { paddingVertical: 0 },
  ],
  underlayColor: 'transparent',
}))`
  margin: ${moderateScale(6)}px 0;
`;

export const StyledCard = styled(SecondaryCard)`
  margin-bottom: ${moderateScale(24)}px;
  padding: ${moderateScale(20)}px;
  width: ${moderateScale(327)}px;
`;

export const StyledCardH3 = styled(H3)`
  margin-bottom: ${moderateScale(8)}px;
  text-align: center;
`;

export const StyledCardP2 = styled(P2)`
  margin: 0 0 ${moderateScale(18)}px;
  text-align: center;
`;

export const StyledH2 = styled(H2)`
  align-self: flex-start;
  margin-bottom: ${moderateScale(24)}px;
`;

export const StyledH3 = styled(H3)`
  margin: ${moderateScale(32)}px 0 ${moderateScale(16)}px;
`;

export const StyledLink = styled(P2)`
  color: ${({ theme: { colors: { linkText } } }) => linkText};
  ${({ $isCard }) => $isCard ? `margin: ${moderateScale(3)}px 0;` : null}
  ${({ $isCard }) => $isCard ? 'text-align: center;' : null}
`;

export const StyledP2 = styled(P2)`
  margin: ${({ $isCard }) =>
    $isCard ? `${moderateScale(3)}px 0 0` : `${moderateScale(8)}px 0 ${moderateScale(12)}px;`}
  ${({ $isCard }) => $isCard ? 'text-align: center;' : null}
`;

export const StyledText = styled.Text`
  margin: ${moderateScale(3)}px 0;
  text-align: center;
`;

export const StyledView = styled.View`
  align-items: center;
`;

export const UnderlinedText = styled.Text`
  text-decoration-line: underline;
`;
