import React, { useEffect, useState } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  disableBiometricLogin,
  disablePin,
  enableBiometricLogin,
  resetState,
  updateError,
} from '@dmi/shared/redux/Settings/Security/actions';
import makeSelectSecurity, {
  selectPrettySupportedBiometricAuthenticationTypes,
  selectSecurityBannerError,
  selectSignInMethodsNotifications,
} from '@dmi/shared/redux/Settings/Security/selectors';

import { PinDrawer } from '../../../components/Pin';
import SignIn from '../../../components/Settings/SignInMethod';
import PinForm from './PinForm';
import { StyledView } from './styledComponents';

const SignInScreen = ({
  dispatchDisableBiometricLogin,
  dispatchDisablePin,
  dispatchEnableBiometricLogin,
  dispatchResetBannerError,
  dispatchResetState,
  error,
  isBiometricLoginEnabled,
  isPinEnabled,
  navigation,
  notifications,
  supportedBiometricAuthenticationTypes,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  useEffect(() => dispatchResetState, [dispatchResetState]);

  return (
    <StyledView>
      <SignIn
        dispatchDisableBiometricLogin={dispatchDisableBiometricLogin}
        dispatchDisablePin={dispatchDisablePin}
        dispatchEnableBiometricLogin={dispatchEnableBiometricLogin}
        dispatchResetBannerError={dispatchResetBannerError}
        error={error}
        isBiometricLoginEnabled={isBiometricLoginEnabled}
        isPinEnabled={isPinEnabled}
        navigation={navigation}
        notifications={notifications}
        setIsDrawerOpen={setIsDrawerOpen}
        supportedBiometricAuthenticationTypes={supportedBiometricAuthenticationTypes}
      />
      <PinDrawer
        handleClose={() => setIsDrawerOpen(false)}
        isVisible={isDrawerOpen}
      >
        <PinForm setIsDrawerOpen={setIsDrawerOpen} />
      </PinDrawer>
    </StyledView>
  );
};

SignInScreen.propTypes = {
  dispatchDisableBiometricLogin: T.func.isRequired,
  dispatchDisablePin: T.func.isRequired,
  dispatchEnableBiometricLogin: T.func.isRequired,
  dispatchResetBannerError: T.func.isRequired,
  dispatchResetState: T.func.isRequired,
  error: T.oneOfType([T.bool, T.string]).isRequired,
  isBiometricLoginEnabled: T.bool.isRequired,
  isPinEnabled: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  supportedBiometricAuthenticationTypes: T.shape({
    face: T.bool.isRequired,
    fingerprint: T.bool.isRequired,
    iris: T.bool.isRequired,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Security
   */
  error: selectSecurityBannerError('signInMethods'),
  isBiometricLoginEnabled: makeSelectSecurity('isBiometricLoginEnabled'),
  isPinEnabled: makeSelectSecurity('isPinEnabled'),
  notifications: selectSignInMethodsNotifications(),
  supportedBiometricAuthenticationTypes: selectPrettySupportedBiometricAuthenticationTypes(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Security
  */
  dispatchDisableBiometricLogin: () => dispatch(disableBiometricLogin()),
  dispatchDisablePin: () => dispatch(disablePin()),
  dispatchEnableBiometricLogin: () => dispatch(enableBiometricLogin()),
  dispatchResetBannerError: () => dispatch(updateError({
    subType: 'signInMethods',
    type: 'bannerError',
    value: false,
  })),
  dispatchResetState: () => dispatch(resetState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(SignInScreen);
