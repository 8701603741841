import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { P3 } from '../../../../base_ui';

export const BannerText = styled(P3)`
  flex: 1;
  margin-left: ${moderateScale(12)}px;
`;

export const Container = styled.View`
  align-items: center;
  background-color: ${({ theme: { colors: { accentSilver } } }) => accentSilver};
  border-radius: ${moderateScale(4)}px;
  flex-direction: row;
  margin-bottom: ${moderateScale(6)}px;
  padding: ${moderateScale(12)}px;
`;

export const IconContainer = styled.View`
  height: ${moderateScale(32)}px;
  width: ${moderateScale(32)}px;
`;
