/*
 * Confirmation Screen
 */

import React, { Fragment, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { // eslint-disable-line object-curly-newline
  resetStatusState as resetCommunicationsStatusState,
} from '@dmi/shared/redux/Settings/Communications/actions';
import { // eslint-disable-line object-curly-newline
  resetStatusState as resetLoansStatusState,
} from '@dmi/shared/redux/Settings/Loans/actions';
import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import { selectBannerError } from '@dmi/shared/redux/Settings/Loans/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { ConfirmationComponent } from '../../../components/Settings/AddLoan';
import { StyledView } from './styledComponents';

const ConfirmationScreen = ({
  bannerError,
  dispatchResetCommunicationsStatusState,
  dispatchResetLoansStatusState,
  navigation,
}) => {
  const { header } = LOANS_CONTENT_DICTIONARY.addLoan.confirmationView.mobile;

  /**
   * Reset relevant requestStatuses
   * - addLoan: so user could enter flow again and see ProgressButton in proper state
   * - fetchMailingAddressAll: to trigger SettingsHome refetch of MailingAddresses using newly
   *      updated loans list.
   * - fetchPaperlessSettingAll: to trigger SettingsHome refetch of PaperlessBilling statuses using
   *      newly updated loans list.
   */
  useEffect(() => () => {
    dispatchResetCommunicationsStatusState();
    dispatchResetLoansStatusState();
  }, [dispatchResetCommunicationsStatusState, dispatchResetLoansStatusState]);

  return (
    <Fragment>
      <SecondaryHeader
        handleDone={() => navigation.navigate('Settings')}
        title={header}
      />
      <StyledView>
        <ConfirmationComponent
          bannerError={bannerError}
        />
      </StyledView>
    </Fragment>
  );
};

ConfirmationScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  dispatchResetCommunicationsStatusState: T.func.isRequired,
  dispatchResetLoansStatusState: T.func.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  bannerError: selectBannerError(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Communications
   */
  dispatchResetCommunicationsStatusState: () => dispatch(resetCommunicationsStatusState()),
  /**
   * Store: Loans
   */
  dispatchResetLoansStatusState: () => dispatch(resetLoansStatusState()),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(ConfirmationScreen);
