/* eslint-disable max-len, react/jsx-sort-props */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

const LearningCenter = () => {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(150)} width={moderateScale(327)} viewBox="0 0 327 150" xmlns="http://www.w3.org/2000/svg">
      <Polyline fill="#bac2ca" points="247.25 123.68 247.25 139.77 254.56 139.77 254.56 123.46" />
      <Polyline fill="#bac2ca" points="77.42 123.68 77.42 139.77 84.74 139.77 84.74 123.46" />
      <Rect fill="#6d7d8e" x="77.45" y="123.54" width="6.85" height="2.93" />
      <Rect fill="#6d7d8e" x="247.44" y="123.54" width="6.85" height="2.93" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="77.42 123.68 77.42 139.77 84.74 139.77 84.74 123.46" />
      <Rect fill="#e6ebef" x="47.51" y="111.99" width="242.9" height="11.18" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="63.27 111.99 290.4 111.99 290.4 123.17 47.51 123.17 47.51 111.99 58.67 111.99" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" points="247.25 123.68 247.25 139.77 254.56 139.77 254.56 123.46" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="47.13" y1="117.54" x2="71.91" y2="117.54" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="75.01" y1="117.54" x2="79.12" y2="117.54" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="287.29" y1="117.54" x2="262.51" y2="117.54" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="181.83" y1="117.54" x2="157.05" y2="117.54" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="290.23" y1="119.98" x2="276.19" y2="119.98" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="189.66" y1="119.98" x2="175.62" y2="119.98" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="259.41" y1="117.54" x2="255.3" y2="117.54" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="47.13" y1="120.18" x2="56.81" y2="120.18" />
      <Rect stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x="191.12" y="29.01" width="15.77" height="82.96" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" x="191.12" y="29.01" width="15.77" height="82.96" />
      <Rect fill="#e6ebef" x="175.29" y="16.67" width="15.77" height="95.3" />
      <Rect fill="#fff" x="175.49" y="29.15" width="15.39" height="5.3" />
      <Rect fill="#bac2ca" x="188.26" y="16.97" width="2.74" height="94.04" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="177.3" y1="29.17" x2="190.32" y2="29.17" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="175.16" y1="34.45" x2="190.32" y2="34.45" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="191.2" y1="49.13" x2="206.37" y2="49.13" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="191.23" y1="60.38" x2="204.14" y2="60.38" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" x="175.29" y="16.67" width="15.77" height="95.3" />
      <Rect fill="#e6ebef" x="206.79" y="24.32" width="15.77" height="87.65" />
      <Rect fill="#bac2ca" x="219.43" y="24.25" width="2.74" height="86.77" />
      <Rect fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" x="206.79" y="24.24" width="15.77" height="87.73" />
      <Rect fill="#bac2ca" x="159.3" y="22.04" width="15.77" height="89.93" />
      <Rect fill="#e6ebef" x="159.57" y="38.17" width="15.39" height="8.15" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" points="159.31 85.16 159.31 22.04 175.07 22.04 175.07 111.97 159.31 111.97 159.31 89.95" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="159.25" y1="38.2" x2="174.41" y2="38.2" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="159.25" y1="46.32" x2="174.41" y2="46.32" />
      <Rect fill="#bac2ca" x="238.19" y="28.81" width="14.49" height="82.65" transform="translate(-8.45 96.54) rotate(-21.89)" />
      <Rect fill="#e6ebef" x="229.95" y="45.25" width="14.14" height="7.49" transform="translate(-1.18 91.88) rotate(-21.89)" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" points="253.61 71.04 267.56 105.78 254.12 111.18 223.31 34.48 236.76 29.09 251.86 66.68" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="228.8" y1="48.29" x2="240.24" y2="43.71" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="231.58" y1="55.22" x2="244.51" y2="50.02" />
      <Line stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" x1="250.56" y1="101.19" x2="263.49" y2="96" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" d="M186.11,95.07a4.21,4.21,0,1,0,1.26,3,4.31,4.31,0,0,0-.23-1.39" />
      <Rect fill="#fff" x="212.16" y="77.82" width="4.79" height="28.86" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" points="216.95 88.92 216.95 106.67 212.16 106.67 212.16 77.82 216.95 77.82 216.95 85.89" />
      <Path fill="#bac2ca" d="M122,69.58a24.79,24.79,0,0,0,3,3.83l1,1.15,1.13-.83s12.23-8.12,11.47-23.08S135,39.37,137.5,27.55a26.65,26.65,0,0,0,.1-10c-1.06-6.26-9.82-7.16-12-1.22a26.85,26.85,0,0,0-1.8,9.78c.17,11.55-1.71,8.25-5.06,20.65l-.3,1.6s6.22,10.58,3.63,21.24" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" d="M122,69.58a24.79,24.79,0,0,0,3,3.83l1,1.15,1.13-.83s12.23-8.12,11.47-23.08S135,39.37,137.5,27.55a26.65,26.65,0,0,0,.1-10c-1.06-6.26-9.82-7.16-12-1.22a26.85,26.85,0,0,0-1.8,9.78c.17,11.55-1.71,8.25-5.06,20.65" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" d="M130.27,29.67C129.1,42.25,127.06,63,126.08,74.5" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" d="M131,22.21s.22-1.83-.32,3.92" />
      <Path fill="#e6ebef" d="M132.9,83.5V98.68a12.62,12.62,0,0,1-12.63,12.63h-5a12.54,12.54,0,0,0,4,.65h6.53a12.62,12.62,0,0,0,12.63-12.63V83.5Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" d="M106.65,83.5h31.79a0,0,0,0,1,0,0V99.33A12.63,12.63,0,0,1,125.81,112h-6.54a12.63,12.63,0,0,1-12.63-12.63V83.5A0,0,0,0,1,106.65,83.5Z" />
      <Polyline stroke="#000" strokeLinecap="round" strokeLinejoin="round" fill={svgHighlight} strokeWidth="0.75px" points="128.37 90.08 138.28 90.08 138.28 94.17 106.91 94.17 106.91 90.08 124.52 90.08" />
      <Path fill="#e6ebef" d="M117.36,47.21c-9.07-11.93-9.33-7.28-13.92-18.46a28.49,28.49,0,0,0-8.25-10.83c2.48,3.94,6.48,10.63,7.21,13.83,1.08,4.69,4.79,7.53,11,15.55,5.77,7.38,7.46,19.72,6.47,26.75l.56.13.44-1.45S126.43,59.13,117.36,47.21Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" d="M87.73,27.8a26.06,26.06,0,0,0,3.65,7.71c7,9.87,2.91,7.59,8.1,21.64s19.63,16.72,19.63,16.72l1.37.31.44-1.45s5.51-13.6-3.56-25.52S108,39.93,103.44,28.75A30,30,0,0,0,92.88,16.12a4.1,4.1,0,0,0-6.47,3.55,35.56,35.56,0,0,0,.7,5.63" />
      <Path fill="#e6ebef" d="M79.92,56.83a64.8,64.8,0,0,0-14.21-1.08,1.19,1.19,0,0,0-1,1.89,13,13,0,0,0,4.82,3.89c4.55,2.19,8.69-.59,11.73,2.53s4.13,12.15,28.36,11.14c0,0-8.17-12.12-27-17.64" />
      <Path fill="#bac2ca" d="M90.11,60.73a32.84,32.84,0,0,0,17.28,12.46A52,52,0,0,0,90.11,60.73Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" d="M79.92,56.83a64.8,64.8,0,0,0-14.21-1.08,1.19,1.19,0,0,0-1,1.89,13,13,0,0,0,4.82,3.89c4.55,2.19,8.69-.59,11.73,2.53s4.13,12.15,28.36,11.14c0,0-8.17-12.12-27-17.64" />
      <Path fill="#e6ebef" d="M131.69,78.09S135.41,64.93,150.81,55c0,0,3.42-2.43,12.23-5.22a1,1,0,0,1,1.32,1.32,11.38,11.38,0,0,1-3,4.56c-3.2,3.08-7.41,1.89-9.09,5.33S152.18,72.3,131.69,78.09Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" d="M131.69,78.09S135.41,64.93,150.81,55c0,0,3.42-2.43,12.23-5.22a1,1,0,0,1,1.32,1.32,11.38,11.38,0,0,1-3,4.56c-3.2,3.08-7.41,1.89-9.09,5.33S152.18,72.3,131.69,78.09Z" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" d="M109.66,75.2s4.42,5.56,6.38,8.49" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" d="M98.11,33.34s10.76,19.11,19.37,35.29" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" d="M120.27,74.24s1.55,5.49,2.14,9.07" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" x1="125.89" y1="74.04" x2="125.3" y2="83.1" />
      <Line fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" x1="131.89" y1="77.82" x2="129.28" y2="83.3" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" points="104.76 58 112.13 59.04 114.74 52.78" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" points="99.47 43.78 104.43 44.82 105.34 39.87" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" points="123.2 48.04 128.01 52.85 134.16 49.59" />
      <Polyline fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" points="126.85 33.6 129.49 36.2 133.21 34.19" />
      <Path fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.5px" d="M133.78,75.8s7.17-10.57,14.8-16.7" />
    </Svg>
  );
};

export default LearningCenter;
