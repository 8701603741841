import React from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';

import { OutlinedDropDown } from '../../../../base_ui';
import {
  Container,
  LabelInputContainer,
  StyledLabel,
} from '../../styledComponents';
import { useSharedDropdownStyle } from '../../helpers';

const StateDropdown = ({
  allowedStates,
  handleChangeInput,
  selected,
}) => {
  const dropdownStyle = useSharedDropdownStyle({ isStateDropdown: true });

  return (
    <Container>
      <LabelInputContainer>
        <StyledLabel>State</StyledLabel>
        <OutlinedDropDown
        /**
         * Fix for iPad specific issue on mobile web where dropdown displays wrong
         * value on consecutive selections, most probably caused by the index
         * being off after the 'Select' placeholder is removed. A re-render of the
         * component after a selection has been made by changing the key value
         * resolves the issue.
         */
          {...(Platform.OS === 'web' ? {
            key: selected
              ? 'dropdown-initial-render'
              : 'dropdown-consecutive-render',
          } : {})}
          accessibilityName="state-dropdown"
          hasLargerFont
          menuItems={allowedStates}
          onChange={(value) => handleChangeInput('state', value)}
          placeholder={!selected ? { label: '', value: '' } : {}}
          selected={selected}
          style={dropdownStyle}
        />
      </LabelInputContainer>
    </Container>
  );
};

StateDropdown.propTypes = {
  allowedStates: T.array.isRequired,
  handleChangeInput: T.func.isRequired,
  selected: T.string.isRequired,
};

export default StateDropdown;
