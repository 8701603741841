import React from 'react';
import T from 'prop-types';

import {
  DISMISSIBLE_BANNER,
  DISMISSIBLE_DISCLOSURE_BANNER,
  NON_DISMISSIBLE_BANNER,
} from '@dmi/shared/redux/Main/constants';

import NonDismissibleBanner from './NonDismissibleBanner';
import { DismissibleBanner, DismissibleDrawerBanner } from './DismissibleBanners';

const PreLoginBanner = ({ bannerType, ...props }) => {
  switch (bannerType) {
    case NON_DISMISSIBLE_BANNER:
      return <NonDismissibleBanner {...props} />;
    case DISMISSIBLE_BANNER:
      return <DismissibleBanner {...props} />;
    case DISMISSIBLE_DISCLOSURE_BANNER:
      return <DismissibleDrawerBanner {...props} />;
    default:
      return null;
  }
};

PreLoginBanner.propTypes = { bannerType: T.string.isRequired };

export default PreLoginBanner;
