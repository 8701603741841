import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { H4 } from '../../../../base_ui';

export const Container = styled.View`
  align-self: center;
  padding-bottom: ${moderateScale(24)}px;
  width: ${moderateScale(327)}px;
`;

export const Heading = styled(H4)`
  margin-vertical: ${moderateScale(10)}px;
`;

export const StyledImage = styled.Image`
  height: ${moderateScale(185)}px;
  width: 100%;
`;
