import styled from 'styled-components/native';

import {
  BaseInternalLink,
  H3,
  H5,
  P3,
  PrimaryDivider,
} from '../base_ui';
import { moderateScale } from '../../utils/scaleHelpers';

export const ButtonsContainer = styled.View`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: ${moderateScale(26)}px auto ${moderateScale(10)}px;
`;

export const CheckboxContainer = styled.View`
  display: ${({ $shouldRender }) => $shouldRender ? 'flex' : 'none'};
  padding: 10px 0 ${({ $addBottomMargin }) => ($addBottomMargin ? '20px' : '10px')};
`;

export const CheckboxLabel = styled.Text`
  font-size: ${moderateScale(12)}px;
  margin-left: ${moderateScale(10)}px;
`;

export const ConsentContainer = styled.View`
  margin: ${moderateScale(30)}px ${moderateScale(24)}px ${moderateScale(0)}px;
  flex-grow: 1;
`;

export const StyledButton = styled(P3)`
  color: ${({ theme: { colors: { primary } } }) => primary};
`;

export const StyledH3 = styled(H3)`
  margin-bottom: ${moderateScale(16)}px;
`;

export const StyledH5 = styled(H5)`
  margin-bottom: ${moderateScale(10)}px;
  text-align: ${({ $center }) => $center ? 'center' : 'left'};
`;

export const StyledBaseInternalLink = styled(BaseInternalLink)`
  margin-top: ${moderateScale(14)}px;
`;

export const StyledP3 = styled(P3)`
  margin-bottom: ${moderateScale(10)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
