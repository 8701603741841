/**
*
* BaseExternalLink
* @description External link used as base
* API Usage: {
  href: URL of the page that the link goes to
  Icon: Optional icon displayed to the left or right of the label
  isRightIcon: Optional boolean, positions icon to the left or right
  label: Text link label
  size: Size of the label text, small or medium
* }
*/

import React, { useCallback, useState } from 'react';
import T from 'prop-types';
import * as WebBrowser from 'expo-web-browser';
import * as Linking from 'expo-linking';

import ConditionalRender from '../ConditionalRender';
import { getUrlScheme } from './helpers';
import {
  ContainerWrapper,
  DisclosureLinkContainer,
  DisclosureStyledText,
  LinkContainer,
  StyledIcon,
  StyledText,
} from './styledComponents';

const BaseExternalLink = ({
  hasUnderline = false,
  href,
  Icon,
  isDisclosureLink = false,
  isFooterLink,
  isRightIcon = true,
  label,
  onPressFunc,
  size = 'medium',
  ...restProps
}) => {
  const [isPressed, setIsPressed] = useState(false);
  const handleOpenWithWebBrowser = useCallback(() => {
    const scheme = getUrlScheme(href);
    if (scheme === 'mailto:' || scheme === 'tel:') {
      Linking.openURL(href);
    } else {
      try {
        WebBrowser.openBrowserAsync(href);
      } catch (error) {
        WebBrowser.dismissBrowser();
      }
    }
    if (onPressFunc) { onPressFunc(); }
  });

  if (isDisclosureLink) {
    /**
     * Due to the Pressable wrapper, the BaseUI external link component does not easily
     * style dynamically within the EditorContent (does not remain inline with text) so a custom
     * link component was created for the Disclosure pages.
     */
    return (
      <DisclosureLinkContainer onPress={handleOpenWithWebBrowser} role="link">
        <DisclosureStyledText $hasUnderline={hasUnderline} {...restProps}>
          {label}
        </DisclosureStyledText>
      </DisclosureLinkContainer>
    );
  }

  return (
    <ContainerWrapper role="link" {...restProps}>
      <LinkContainer
        $pressed={isPressed}
        isFooterLink={isFooterLink}
        isRightIcon={isRightIcon}
        onPress={handleOpenWithWebBrowser}
        onPressIn={() => setIsPressed(true)}
        onPressOut={() => setIsPressed(false)}
      >
        <ConditionalRender
          Component={StyledIcon}
          propsToPassDown={{ Icon, isRightIcon }}
          shouldRender={!!Icon}
        />
        <StyledText
          isFooterLink={isFooterLink}
          size={size}
          {...restProps}
        >
          {label}
        </StyledText>
      </LinkContainer>
    </ContainerWrapper>
  );
};

BaseExternalLink.propTypes = {
  hasUnderline: T.bool,
  href: T.string.isRequired,
  Icon: T.element,
  isDisclosureLink: T.bool,
  isFooterLink: T.bool,
  isRightIcon: T.bool,
  label: T.string.isRequired,
  onPressFunc: T.func,
  size: T.oneOf(['small', 'medium', 'large']),
};

export default BaseExternalLink;
