/* eslint-disable react/jsx-sort-props */
/* eslint-disable max-len, no-tabs */
import React from 'react';
import Svg, {
  Circle,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function MilitaryHome(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg id="Layer_1" height={moderateScale(315)} width={moderateScale(251)} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 251 315" {...props}>
      <Path fill="#E6EBEF" d="M225.07,57.63a4.45,4.45,0,0,0,4.11-6.12c-2.49-6.19-7.78-15-17.82-16-4.23-.44-8.17,1.23-11.26,3.3a.72.72,0,0,1-.88-.08,12.68,12.68,0,0,0-8.64-3.22c-6.44,0-11.67,4.4-11.67,9.83h0a.71.71,0,0,1-.78.71c-.31,0-.63,0-.95,0-4,0-7.32,2.16-8.52,5.16a.69.69,0,0,1-.93.39,8.15,8.15,0,0,0-3.52-.72,7.39,7.39,0,0,0-6,3.33,2.21,2.21,0,0,0,1.78,3.5Z" />
      <Path fill="#E6EBEF" d="M147.05,149.27a1.55,1.55,0,0,0,1.17-2.55c-1.45-1.69-4.36-2.84-7.7-2.84a13.61,13.61,0,0,0-1.59.09,1,1,0,0,1-1.06-1h0c0-2.91-3.69-5.28-8.24-5.28a12.06,12.06,0,0,0-3.54.52.91.91,0,0,1-1.15-.66c-1-4.34-5.6-7.63-11.19-7.63-6.29,0-11.39,4.16-11.39,9.28a4.9,4.9,0,0,0,0,.54,5.21,5.21,0,0,0-5.83,3.59.91.91,0,0,1-1.13.61,5.27,5.27,0,0,0-5.57,1.8,2.16,2.16,0,0,0,1.7,3.5Z" />
      <Line fill="none" stroke="#E6EBEF" strokeWidth="2px" strokeMiterlimit="10" x1="218.94" y1="258.64" x2="218.94" y2="282.66" />
      <Path fill="#E6EBEF" d="M236.1,230.35a8.58,8.58,0,0,1-2-9.18,10.55,10.55,0,0,0,.76-3.91,11.49,11.49,0,0,0-4.11-8.59,6.23,6.23,0,0,1-1.36-6.85,7.07,7.07,0,0,0,.45-2.47c0-4.88-4.94-8.84-11-8.84s-11,4-11,8.84a7.06,7.06,0,0,0,.44,2.47,6.21,6.21,0,0,1-1.36,6.85,11.53,11.53,0,0,0-4.11,8.59,10.55,10.55,0,0,0,.76,3.91,8.57,8.57,0,0,1-2,9.18,15.39,15.39,0,0,0-4.63,10.78c0,9.7,9.81,17.56,21.92,17.56s21.91-7.86,21.91-17.56A15.39,15.39,0,0,0,236.1,230.35Z" />
      <Line fill="none" stroke="#FFF" strokeMiterlimit="10" strokeLinecap="round" x1="218.98" y1="258.61" x2="218.98" y2="219.16" />
      <Line fill="none" stroke="#FFF" strokeMiterlimit="10" strokeLinecap="round" x1="213.45" y1="229.74" x2="218.79" y2="235.25" />
      <Line fill="none" stroke="#FFF" strokeMiterlimit="10" strokeLinecap="round" x1="219.05" y1="229.4" x2="224.12" y2="225.61" />
      <Line fill="none" stroke="#FFF" strokeMiterlimit="10" strokeLinecap="round" x1="219.05" y1="243.84" x2="224.12" y2="240.06" />
      <Rect fill="#E6EBEF" x="102.39" y="162.99" width="7.87" height="2.32" />
      <Path fill="#BAC2CA" d="M110.9,160.69v2.14l-.48.1v25.7l4.72-5.15V162.83s.67-.52.62-.72-.24-1.23-.24-1.23l-.33-.34Z" />
      <Polyline fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" points="102.25 162.94 102.25 188.59 110.36 188.59 115.18 183.53 115.18 163.02" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="110.33" y1="182" x2="110.33" y2="162.88" />
      <Rect fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x="101.21" y="160.55" width="14.59" height="2.24" rx="0.81" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="110.83" y1="160.69" x2="110.83" y2="162.57" />
      <Path fill="#EFF3F6" d="M62.83,219.27a11.07,11.07,0,0,1-2.57-11.84,13.62,13.62,0,0,0,1-5,14.84,14.84,0,0,0-5.3-11.08c-2.46-2.19-2.89-5.74-1.75-8.83a9.34,9.34,0,0,0,.57-3.2c0-6.3-6.37-11.4-14.22-11.4s-14.22,5.1-14.22,11.4a9.34,9.34,0,0,0,.57,3.2c1.13,3.09.71,6.64-1.75,8.83a14.84,14.84,0,0,0-5.3,11.08,13.42,13.42,0,0,0,1,5,11.05,11.05,0,0,1-2.58,11.84,19.88,19.88,0,0,0-6,13.91c0,12.52,12.66,22.66,28.27,22.66s28.27-10.14,28.27-22.66A19.84,19.84,0,0,0,62.83,219.27Z" />
      <Path fill="#DCE2E7" d="M62.83,219.27a11.07,11.07,0,0,1-2.57-11.84,13.62,13.62,0,0,0,1-5,14.84,14.84,0,0,0-5.3-11.08c-2.46-2.19-2.89-5.74-1.75-8.83a9.34,9.34,0,0,0,.57-3.2c0-6.09-5.95-11-13.44-11.37,3.36,2.65,7.42,6.61,7.42,10.33,0,6.35-2.82,9.88-.88,15.35s11.29,7.59,9.88,20.47c0,0-1.23,4.59,1.77,10.41S60,241.41,46.8,246.71c-10.27,4.1-18.09,4.59-25.87,2.79a32.13,32.13,0,0,0,19.61,6.34c15.61,0,28.27-10.14,28.27-22.66A19.84,19.84,0,0,0,62.83,219.27Z" />
      <Path fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" d="M62.83,219.27a11.07,11.07,0,0,1-2.57-11.84,13.62,13.62,0,0,0,1-5,14.84,14.84,0,0,0-5.3-11.08c-2.46-2.19-2.89-5.74-1.75-8.83a9.34,9.34,0,0,0,.57-3.2c0-6.3-6.37-11.4-14.22-11.4s-14.22,5.1-14.22,11.4a9.34,9.34,0,0,0,.57,3.2c1.13,3.09.71,6.64-1.75,8.83a14.84,14.84,0,0,0-5.3,11.08,13.42,13.42,0,0,0,1,5,11.05,11.05,0,0,1-2.58,11.84,19.88,19.88,0,0,0-6,13.91c0,12.52,12.66,22.66,28.27,22.66s28.27-10.14,28.27-22.66A19.84,19.84,0,0,0,62.83,219.27Z" />
      <Polygon fill="#E6EBEF" points="170.23 210.11 170.23 281.82 205.65 281.82 205.65 210.6 170.23 210.11" />
      <Polygon fill="#E6EBEF" points="120.27 209.85 120.27 272.82 126.74 272.82 126.74 210.28 120.27 209.85" />
      <Polygon fill="#FFF" points="66.47 206.84 66.47 272.64 120.38 272.64 120.38 207.29 66.47 206.84" />
      <Polygon fill="#FFF" points="66.04 206.84 66.04 272.76 86.39 272.76 86.39 207.29 66.04 206.84" />
      <Polygon fill="#FFF" points="56.33 210.04 56.33 281.82 59.86 281.82 59.86 210.56 56.33 210.04" />
      <Rect fill="#BAC2CA" x="56.51" y="210.06" width="149.43" height="3.76" />
      <Rect fill="#E6EBEF" x="166.06" y="207.58" width="47" height="2.28" />
      <Rect fill="#FFF" x="49.29" y="207.58" width="116.55" height="2.28" />
      <Polygon fill="#BAC2CA" points="49.19 207.28 78.53 175.31 184.79 175.31 212.84 207.45 108.3 207.45 49.19 207.28" />
      <Polygon fill="#8A97A4" points="185.69 175.74 165.21 207.5 212.49 207.5 185.69 175.74" />
      <Polyline fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" points="199.11 191.26 185.57 175.39 165.66 175.39" />
      <Polyline fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" points="107.72 207.49 155.41 207.49 212.96 207.49 200.32 192.68" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="95.54" y1="175.31" x2="93.73" y2="175.31" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="166.26" y1="175.31" x2="98.12" y2="175.31" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="56.4" y1="210.27" x2="56.4" y2="281.59" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="170.14" y1="210.5" x2="170.14" y2="281.44" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="205.88" y1="210.5" x2="205.88" y2="281.44" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="166.44" y1="205.24" x2="185.57" y2="175.39" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="167.69" y1="228.06" x2="193.73" y2="228.06" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="176.62" y1="221.53" x2="205.87" y2="221.53" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="163.61" y1="221.53" x2="174.45" y2="221.53" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="164.57" y1="241.12" x2="194.36" y2="241.12" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="166.26" y1="247.08" x2="181.67" y2="247.08" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="183.63" y1="247.08" x2="186.33" y2="247.08" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="199.32" y1="258.93" x2="159.15" y2="258.93" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="189.1" y1="264.89" x2="151.66" y2="264.89" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="194.34" y1="264.89" x2="191.87" y2="264.89" />
      <Polyline fill={svgHighlight} stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" points="74.04 272.23 74.04 225.71 95.33 225.71 95.33 272.23" />
      <Circle fill="#FFF" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.5px" cx="84.37" cy="236.47" r="0.93" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="39.96" y1="213.08" x2="39.96" y2="281.86" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="130.19" y1="210.44" x2="130.19" y2="281.86" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="32.82" y1="229.11" x2="39.75" y2="234.05" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="46.91" y1="218.31" x2="39.98" y2="223.25" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="47.07" y1="238.51" x2="40.13" y2="243.45" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="12.77" y1="282.14" x2="9.65" y2="282.14" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="230.47" y1="282.14" x2="16.82" y2="282.14" />
      <Path fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" d="M32,189.73a8.54,8.54,0,0,1-1.8,2.86" />
      <Path fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" d="M36.57,172.36s-4.77,1.35-5.59,5.47c-.67,3.34,1.19,5.42,1.47,7.65a8.91,8.91,0,0,1,.08.94" />
      <Rect fill="#E6EBEF" x="143.05" y="225.52" width="12.25" height="15.77" />
      <Polygon fill="#FFF" opacity="0.7" points="141.94 237.85 148.21 225.52 142.56 225.52 141.94 226.61 141.94 237.85" />
      <Polygon fill="#FFF" opacity="0.7" points="149.76 241.29 146.24 241.29 154.45 225.52 157.97 225.52 149.76 241.29" />
      <Rect fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x="142.73" y="225.55" width="12.77" height="15.87" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="149.01" y1="232.41" x2="149.01" y2="239.05" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="149.01" y1="227.41" x2="149.01" y2="230.91" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="145.49" y1="233.67" x2="155.49" y2="233.67" />
      <Rect fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x="139.89" y="223" width="18.39" height="21.29" />
      <Rect fill="#E6EBEF" x="107.15" y="225.52" width="18.65" height="15.77" />
      <Polygon fill="#FFF" opacity="0.7" points="106.03 237.85 112.31 225.52 106.65 225.52 106.03 226.61 106.03 237.85" />
      <Polygon fill="#FFF" opacity="0.7" points="113.85 241.29 110.34 241.29 118.54 225.52 122.06 225.52 113.85 241.29" />
      <Rect fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x="106.82" y="225.55" width="19.32" height="15.87" />
      <Rect fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x="103.99" y="223" width="22.28" height="21.29" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="169.61" y1="272.65" x2="56.51" y2="272.65" />
      <Path fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" d="M69.87,207.45H49.11V209a1.23,1.23,0,0,0,1.23,1.23h162.8v-1.66" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="165.89" y1="207.58" x2="165.89" y2="209.93" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="59.86" y1="210.35" x2="59.86" y2="272.65" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="66.12" y1="210.35" x2="66.12" y2="272.65" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="126.35" y1="210.18" x2="126.35" y2="272.65" />
      <Polyline fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" points="90.97 175.31 78.53 175.31 49.11 207.45 104.5 207.45" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="117.38" y1="225.88" x2="117.38" y2="241.18" />
      <Polyline fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" points="98.62 272.17 98.62 222.77 70.86 222.77 70.86 272.41" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="170.32" y1="234.71" x2="199.08" y2="234.71" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="198.66" y1="132.84" x2="204.11" y2="162.32" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="194.59" y1="126.16" x2="190.52" y2="138.91" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="199.78" y1="125.67" x2="218.94" y2="148.1" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="202.79" y1="134.73" x2="208.72" y2="146.9" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="210" y1="149.61" x2="210.75" y2="151.18" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="203.39" y1="125.94" x2="208.72" y2="129.55" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="206.82" y1="123.56" x2="232.85" y2="131.58" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="210.98" y1="131.05" x2="216.31" y2="134.66" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="195.1" y1="139.61" x2="194.45" y2="148.13" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="194.2" y1="150.98" x2="193.95" y2="154.29" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="189.44" y1="141.62" x2="187.29" y2="148.18" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="186.13" y1="151.33" x2="184.23" y2="157.15" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="162.84" y1="151.13" x2="182.88" y2="133.4" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="190.04" y1="130.75" x2="184.18" y2="139.81" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="181.13" y1="144.32" x2="179.57" y2="146.73" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="161.54" y1="127.74" x2="187.89" y2="123.04" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="176.52" y1="131.45" x2="188.64" y2="125.44" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="168.15" y1="119.08" x2="181.68" y2="120.28" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="157.03" y1="107.56" x2="187.91" y2="118.95" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="184.08" y1="107.41" x2="192.75" y2="117.38" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="182.2" y1="105.32" x2="177.58" y2="100.1" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="175.82" y1="98.14" x2="171.83" y2="93.6" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="186.67" y1="101.75" x2="191.41" y2="111.18" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="183.07" y1="94.64" x2="184.48" y2="97.39" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="169.09" y1="102.84" x2="171.91" y2="104.9" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="174.24" y1="106.63" x2="181.23" y2="111.44" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="187.39" y1="77.41" x2="192.7" y2="103.7" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="208.07" y1="88.22" x2="198.13" y2="116.25" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="202.86" y1="115.5" x2="228.11" y2="94.29" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="203.82" y1="120.08" x2="214.63" y2="117.78" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="217.55" y1="117.26" x2="224.16" y2="115.95" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="227.13" y1="115.38" x2="233.74" y2="114.07" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="212.52" y1="113.43" x2="220.33" y2="109.79" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="223" y1="108.47" x2="225.82" y2="107.12" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="204.87" y1="109.06" x2="212.38" y2="98.09" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="214.08" y1="95.59" x2="215.29" y2="93.78" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="196.7" y1="112.07" x2="197.6" y2="96.79" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="197.79" y1="93.66" x2="197.94" y2="91.08" />
      <Line fill="none" stroke="#000" strokeLinejoin="round" strokeLinecap="round" strokeWidth="0.75px" x1="218.34" y1="122.32" x2="228.46" y2="122.75" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="26.47" y1="130.04" x2="37.86" y2="130.14" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="62.8" y1="130.04" x2="74.19" y2="130.14" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="39.84" y1="130.04" x2="60.77" y2="130.14" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="50.95" y1="133.52" x2="53.26" y2="143.74" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="47.21" y1="116.2" x2="49.35" y2="126.11" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="35.45" y1="148.28" x2="65.49" y2="111.95" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="55.86" y1="107.22" x2="44.61" y2="153.19" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="50.33" y1="122.25" x2="50.14" y2="138.41" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="50.14" y1="141.49" x2="50.14" y2="147.97" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="53.85" y1="146.65" x2="55.22" y2="153.62" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="44.22" y1="117.29" x2="56.52" y2="143.15" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="57.4" y1="145.39" x2="58.33" y2="147.3" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="41.35" y1="118.81" x2="58.89" y2="141.21" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="59.99" y1="142.62" x2="64.74" y2="148.75" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="40.43" y1="117.61" x2="35.68" y2="111.72" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="42.84" y1="135.82" x2="57.72" y2="124.41" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="44.01" y1="127.07" x2="56.8" y2="133.34" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="59.05" y1="123.4" x2="64.1" y2="119.51" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="42.72" y1="126.52" x2="37" y2="123.59" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="35.14" y1="122.63" x2="29.42" y2="119.7" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="35.72" y1="118.53" x2="36.85" y2="119.41" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="38.27" y1="120.58" x2="41.41" y2="122.94" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="46.63" y1="113.42" x2="45.48" y2="107.19" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="43.14" y1="114.89" x2="42.06" y2="112.59" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="50.37" y1="119.38" x2="50.55" y2="112.72" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="52.9" y1="124.78" x2="54.02" y2="122.37" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="54.82" y1="120.9" x2="56.59" y2="117.34" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="57.6" y1="115.39" x2="58.22" y2="114.19" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="55.88" y1="127.74" x2="63.53" y2="124.02" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="65.71" y1="123.16" x2="71.66" y2="120.33" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="37.29" y1="136" x2="44.78" y2="132.5" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="28.33" y1="139.99" x2="34.81" y2="137.11" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="36.27" y1="140.91" x2="41.38" y2="136.94" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="58.79" y1="134.37" x2="61.62" y2="135.71" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="63.18" y1="136.45" x2="71.26" y2="140.32" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="50.97" y1="130.63" x2="56.85" y2="135.52" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="58.9" y1="137.2" x2="62.73" y2="140.15" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.45px" x1="64.12" y1="141.26" x2="65.19" y2="142.07" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="42.39" y1="145.75" x2="43.1" y2="144.5" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="43.9" y1="142.82" x2="45.93" y2="139.02" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="36.37" y1="132.97" x2="49.86" y2="130.26" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="32.28" y1="133.7" x2="28.88" y2="134.41" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="30.79" y1="125.63" x2="33.72" y2="126.24" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="35.45" y1="126.71" x2="44.16" y2="128.72" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="51.3" y1="129.85" x2="64.01" y2="127.35" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="67.69" y1="126.66" x2="71.52" y2="125.89" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="56.13" y1="131.43" x2="65.16" y2="133.59" />
      <Line fill="none" strokeWidth="0.55px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="67.22" y1="134.11" x2="69.53" y2="134.63" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M102.33,64.23S108.11,67,110,69.32" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M123.48,85.29a29.16,29.16,0,0,1,2,4.45" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M112.35,71.32A62.9,62.9,0,0,1,122,82.83" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M104.7,63.19s11.67,3.3,19.24,8.38" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M128.05,74.17s6.18,4.4,8.9,8.62" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M102,61.11a62.14,62.14,0,0,1,8.67-.17" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M128.67,63.93a49.52,49.52,0,0,1,14.17,6.83" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M113.31,60.94a69.72,69.72,0,0,1,13.18,2.35" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M105.45,59s14.56-5,33.41-1.1" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M103.37,58.16s11-5,20.81-6.12" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M138.43,51.8a39.4,39.4,0,0,1,7.48,1.3" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M128.22,51.67a73,73,0,0,1,8.22,0" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M101.21,57.57s5.7-4.59,9-6.79" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M113.62,48.47s10.59-6.67,20.73-7.32" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M114.26,29.92a36.6,36.6,0,0,1,5.7-5.86" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M100.21,54.92s5.05-12.53,12.08-22.38" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M102.71,38a66.8,66.8,0,0,1,8.48-13.33" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M101,42.27s-2.78,8-2.7,14.45" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M99.86,21.15s-4.7,12.45-3.08,32.29" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M101,18.11a34.46,34.46,0,0,1,2.14-4.28" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M82.16,35.79A85,85,0,0,1,96,55.38" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M67.87,25.93s4.22,1,10.3,6.14" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M67.53,38.86a62.77,62.77,0,0,1,12.83,4.85" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M84.25,46.16s3,2,4.59,3.39" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M91.11,51.59s2.2,2.35,3,3.35" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M63,43.58S71.58,45.35,76,47.32" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M78.71,48.63S89,53.4,95.16,58.76" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M67,54.17s12.83-.54,24.59,4.17" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M81.56,59.8a86.13,86.13,0,0,1,11.67.54" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M58.13,65.5a71.38,71.38,0,0,1,17.34-5.2" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M55.33,81.86a53.3,53.3,0,0,1,5.38-5" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M63.48,74.46s9.31-6,16.59-8.55" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M83.7,64.71a109.78,109.78,0,0,1,11.18-2.87" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M64.15,81.72A55,55,0,0,1,72.37,74" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M75.19,72s9.78-5.5,16.73-7.81" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M85.39,70.86s6.44-5.4,9.4-6.62" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M70.87,88.07s7.85-11.38,12-14.81" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M88.07,77.26S93.71,68.8,96.2,66" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M85.67,81.34a68.15,68.15,0,0,0-5.92,17.58" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M92.53,84.12s2.12-10.48,3.72-15.37" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M92.21,87.78s-.47,4.42-.57,6.58" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M98.54,77.35s-.58-3.52,4.57,21" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M100.43,66.49s3.48,6.58,4.46,9.12" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M106.21,79.32s4.37,10.44,5.22,12.83" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M98.08,75.07s-.47-5.7-.44-7.69" />
      <Path fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" d="M83.83,26.2S91.53,37,93.94,48.37" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="202.27" y1="234.66" x2="205.71" y2="234.66" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="202.39" y1="258.96" x2="205.79" y2="258.96" />
      <Line fill="none" strokeLinecap="round" stroke="#000" strokeLinejoin="round" strokeWidth="0.5px" x1="146.36" y1="258.96" x2="156.23" y2="258.96" />
      <Line fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="103.6" y1="101.38" x2="103.88" y2="103.07" />
      <Line fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="55.87" y1="66.32" x2="54.65" y2="66.98" />
      <Line fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="65.4" y1="25.26" x2="64.37" y2="25.08" />
      <Line fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="122.56" y1="22.26" x2="123.68" y2="21.6" />
      <Line fill="none" strokeWidth="0.85px" strokeLinecap="round" stroke="#000" strokeLinejoin="round" x1="138.37" y1="85.56" x2="139.07" y2="86.93" />
    </Svg>
  );
}
