import styled from 'styled-components/native';
import { Dimensions } from 'react-native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import { BaseExternalLink, H4, ModalDialog } from '../../../../base_ui';

const deviceWidth = Dimensions.get('window').width;

export const ContentContainer = styled.View`
  align-items: center;
  justify-content: center;
  padding: ${moderateScale(30)}px 0 ${moderateScale(26)}px;
`;

export const Heading = styled(H4)`
  padding-horizontal: ${moderateScale(20)}px;
  text-align: center;
`;

export const StyledExternalLink = styled(BaseExternalLink)`
  margin-bottom: ${moderateScale(4)}px;
`;

export const StyledModalDialog = styled(ModalDialog).attrs({
  modalStyles: {
    borderRadius: 0,
    height: 'auto',
    maxHeight: moderateScale(((deviceWidth / 16) * 9) + 226),
    width: '100%',
  },
})``;
