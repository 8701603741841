import React from 'react';
import T from 'prop-types';

import {
  BaseContainer,
  BaseText,
  DisabledText,
  DueText,
  MarkedContainer,
  MarkedText,
  SelectedContainer,
  SelectedText,
} from './styledComponents';

const Day = ({
  ariaLabel,
  children,
  date,
  marking = {},
  onLongPress,
  onPress,
  state,
  testID,
}) => {
  const { disabled, marked, selected } = marking;

  const handleLongPress = () => onLongPress(date);
  const handlePress = () => onPress(date);

  const isDisabled = () => disabled !== undefined ? disabled : state === 'disabled';
  const isMarked = () => marked;
  const isSelected = () => selected;

  const getContainer = () => {
    let ContainerComponent = BaseContainer;

    if (isSelected()) {
      ContainerComponent = SelectedContainer;
    } else if (isMarked()) {
      ContainerComponent = MarkedContainer;
    }

    return ContainerComponent;
  };

  const renderMarking = () => {
    if (isMarked()) {
      return <DueText>DUE</DueText>;
    }
    return null;
  };

  const renderText = () => {
    let TextComponent = BaseText;

    if (isSelected()) {
      TextComponent = SelectedText;
    } else if (isMarked()) {
      TextComponent = MarkedText;
    } else if (isDisabled()) {
      TextComponent = DisabledText;
    }

    return (
      <TextComponent allowFontScaling={false}>
        {String(children)}
      </TextComponent>
    );
  };

  const ContainerComponent = getContainer();

  return (
    <ContainerComponent
      accessible
      aria-label={ariaLabel}
      disabled={isDisabled()}
      onLongPress={!isDisabled() ? handleLongPress : undefined}
      onPress={!isDisabled() ? handlePress : undefined}
      role={isDisabled() ? undefined : 'button'}
      testID={testID}
    >
      {renderMarking()}
      {renderText()}
    </ContainerComponent>
  );
};

Day.propTypes = {
  ariaLabel: T.any,
  children: T.any,
  date: T.object,
  marking: T.any,
  onLongPress: T.func,
  onPress: T.func,
  state: T.oneOf(['disabled', 'today', '']),
  testID: T.any,
};

export default Day;
