import React from 'react';
import T from 'prop-types';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import makeSelectMain from '@dmi/shared/redux/Main/selectors';
import makeSelectMobile from '@dmi/shared/redux/Mobile/selectors';
import { SSO_ACCOUNT_ERROR_DICTIONARY } from '@dmi/shared/redux/Login/constants';

import iconDictionary from '../../../utils/iconDictionary';
import Message from '../../Message';
import { StyledScrollView } from '../../base_ui';

const ErrorIllustration = iconDictionary('borrowerDesk');

const SsoAccountError = ({ isMobileNative, ssoMigrationUrl }) => {
  const {
    button: { loginLabel, migrationLabel },
    heading,
    text,
  } = SSO_ACCOUNT_ERROR_DICTIONARY;
  const buttonProps = ((!isMobileNative && ssoMigrationUrl)
  // eslint-disable-next-line no-return-assign
    ? { label: migrationLabel, onPress: () => window.location.href = ssoMigrationUrl }
    : { label: loginLabel, path: 'Login' }
  );

  return (
    <StyledScrollView>
      <Message
        body={text}
        icon={ErrorIllustration}
        link={buttonProps}
        title={heading}
      />
    </StyledScrollView>
  );
};

SsoAccountError.propTypes = {
  isMobileNative: T.bool.isRequired,
  ssoMigrationUrl: T.string,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  isMobileNative: makeSelectMobile('isNativeMobile'),
  ssoMigrationUrl: makeSelectMain('ssoMigrationUrl'),
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(SsoAccountError);
