import React from 'react';
import T from 'prop-types';

import {
  ConditionalRender,
  FormattedMessage,
  P2,
} from '../../../../base_ui';

import {
  AccountTypeLabel,
  AccountTypeRow,
  AccountTypeSection,
  Container,
  Header,
  StyledBaseTextField,
  StyledCheckboxWithText,
  StyledH3,
  TextfieldGroup,
} from './styledComponents';

const AccountInformationSection = ({
  accountNumberConfirmRef,
  accountNumberRef,
  canUseAnyRoutingNumber,
  clientName,
  disabled,
  dispatchUpdateFormValue,
  formErrors: {
    accountNumber: accountNumberError,
    accountNumberConfirm: accountNumberConfirmError,
    nameOnAccount: nameOnAccountError,
    routingNumber: routingNumberError,
  },
  formValues: {
    accountNumber,
    accountNumberConfirm,
    accountType,
    nameOnAccount,
    routingNumber,
  },
  header,
  nameOnAccountRef,
  routingNumberRef,
  subheader,
  subheaderForRoutingNumberVariant,
}) => (
  <Container>
    <Header>
      <StyledH3>{header}</StyledH3>
      <FormattedMessage
        defaultMessage={canUseAnyRoutingNumber ? subheaderForRoutingNumberVariant : subheader}
        values={{
          // eslint-disable-next-line react/prop-types
          a: ({ msg }) => <P2>{msg}</P2>,
          clientName,
        }}
        WrapperComponent={P2}
      />
    </Header>
    <AccountTypeSection>
      <AccountTypeLabel>Account Type</AccountTypeLabel>
      <AccountTypeRow>
        <StyledCheckboxWithText
          checked={accountType === 'Checking'}
          disabled={disabled}
          isRound
          onPress={() => dispatchUpdateFormValue({
            field: 'accountType',
            form: 'helocDraw',
            value: 'Checking',
          })}
          title="Checking"
        />
        <StyledCheckboxWithText
          checked={accountType === 'Saving'}
          disabled={disabled}
          isRound
          onPress={() => dispatchUpdateFormValue({
            field: 'accountType',
            form: 'helocDraw',
            value: 'Saving',
          })}
          title="Savings"
        />
      </AccountTypeRow>
    </AccountTypeSection>
    <TextfieldGroup>
      <ConditionalRender
        Component={(
          <StyledBaseTextField
            ref={routingNumberRef}
            disabled={disabled}
            errorMessage={routingNumberError}
            isAutoWidth
            label="Routing Number"
            onChangeText={(value) => dispatchUpdateFormValue({
              field: 'routingNumber',
              form: 'helocDraw',
              value,
            })}
            onSubmitEditing={() => accountNumberConfirmRef.current.focus()}
            placeholder="Required"
            returnKeyType="next"
            value={routingNumber}
          />
        )}
        shouldRender={canUseAnyRoutingNumber}
      />
      <StyledBaseTextField
        ref={accountNumberRef}
        disabled={disabled}
        errorMessage={accountNumberError}
        isAutoWidth
        label="Account Number"
        onChangeText={(value) => dispatchUpdateFormValue({
          field: 'accountNumber',
          form: 'helocDraw',
          value,
        })}
        onSubmitEditing={() => accountNumberConfirmRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={accountNumber}
      />
      <StyledBaseTextField
        ref={accountNumberConfirmRef}
        disabled={disabled}
        errorMessage={accountNumberConfirmError}
        label="Confirm Account Number"
        onChangeText={(value) => dispatchUpdateFormValue({
          field: 'accountNumberConfirm',
          form: 'helocDraw',
          value,
        })}
        onSubmitEditing={() => nameOnAccountRef.current.focus()}
        placeholder="Required"
        returnKeyType="next"
        value={accountNumberConfirm}
      />
      <StyledBaseTextField
        ref={nameOnAccountRef}
        disabled={disabled}
        errorMessage={nameOnAccountError}
        hasFluidWidth
        label="Account Holder's Name"
        onChangeText={(value) => dispatchUpdateFormValue({
          field: 'nameOnAccount',
          form: 'helocDraw',
          value,
        })}
        placeholder="Required"
        returnKeyType="done"
        value={nameOnAccount}
      />
    </TextfieldGroup>
  </Container>
);

AccountInformationSection.propTypes = {
  accountNumberConfirmRef: T.oneOfType([T.func, T.object]),
  accountNumberRef: T.oneOfType([T.func, T.object]),
  canUseAnyRoutingNumber: T.bool.isRequired,
  clientName: T.string.isRequired,
  disabled: T.bool.isRequired,
  dispatchUpdateFormValue: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  header: T.string.isRequired,
  nameOnAccountRef: T.oneOfType([T.func, T.object]),
  routingNumberRef: T.oneOfType([T.func, T.object]),
  subheader: T.string.isRequired,
  subheaderForRoutingNumberVariant: T.string.isRequired,
};

export default AccountInformationSection;
