export const getWelcomeMessage = (name) => {
  let message = '';
  const currentHour = new Date().getHours();

  if (currentHour < 12) {
    message = 'Good morning';
  } else if (currentHour < 18) {
    message = 'Good afternoon';
  } else {
    message = 'Good evening';
  }

  if (name) return `${message}, ${name}`;
  return `${message}`;
};
