import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFocusEffect } from '@react-navigation/native';
import { createStructuredSelector } from 'reselect';
import { injectIntl } from 'react-intl';

import { selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';
import {
  fetchTransactionDetail,
  updateSelectedTransaction,
} from '@dmi/shared/redux/Payments/History/actions';
import makeSelectPaymentHistory, {
  makeSelectError,
  makeSelectLoading,
  makeSelectPaymentHistoryNotifications,
  makeSelectTransactionDetails,
} from '@dmi/shared/redux/Payments/History/selectors';
import { selectIsAllowedToScheduleOTP } from '@dmi/shared/redux/Payments/OneTimePayment/selectors';
import { changeFormFieldValue } from '@dmi/shared/redux/SecureMessaging/actions';

import AsyncRender from '../../../components/AsyncRender';
import { ConditionalRender } from '../../../components/base_ui';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import ScreenReaderNotifications from '../../../components/ScreenReaderNotifications';
import { PaymentDetail, PaymentDetailSkeleton } from '../../../components/Payments/History';
import { StyledView } from './styledComponents';

const PaymentDetailScreen = ({
  dispatchFetchTransactionDetail,
  dispatchSecureMessagingChangeFormFieldValue,
  dispatchUpdateSelectedTransaction,
  isAllowedToScheduleOTP,
  isMurabahaAccount,
  loading,
  navigation,
  notifications,
  selectedTransaction,
  serviceError: { transactionDetail: transactionDetailError },
  transaction,
  transaction: { type },
}) => {
  useFocusEffect(
    useCallback(() => {
      dispatchFetchTransactionDetail(selectedTransaction);
    }, [dispatchFetchTransactionDetail, selectedTransaction]),
  );

  useEffect(() => () => {
    dispatchUpdateSelectedTransaction('');
  }, [dispatchUpdateSelectedTransaction]);

  return (
    <ConditionalRender
      Component={(
        <StyledView>
          <ScreenReaderNotifications notifications={notifications} />
          <SecondaryHeader
            handleBack={() => navigation.goBack()}
            title={type ? `${type} Details` : ''}
          />
          <AsyncRender
            Component={PaymentDetail}
            error={transactionDetailError}
            loading={loading}
            LoadingComponent={PaymentDetailSkeleton}
            propsToPassDown={{
              dispatchSecureMessagingChangeFormFieldValue,
              isAllowedToScheduleOTP,
              isMurabahaAccount,
              navigation,
              transaction,
            }}
          />
        </StyledView>
      )}
      shouldRender={!!selectedTransaction}
    />
  );
};

PaymentDetailScreen.propTypes = {
  dispatchFetchTransactionDetail: T.func.isRequired,
  dispatchSecureMessagingChangeFormFieldValue: T.func.isRequired,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  isAllowedToScheduleOTP: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  loading: T.bool.isRequired,
  navigation: T.object.isRequired,
  notifications: T.array.isRequired,
  selectedTransaction: T.string.isRequired,
  serviceError: T.object.isRequired,
  transaction: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Main
   */
  isMurabahaAccount: selectIsMurabahaAccount(),
  /**
   * Store: Payment History
   */
  loading: makeSelectLoading('transactionDetail'),
  notifications: makeSelectPaymentHistoryNotifications(),
  selectedTransaction: makeSelectPaymentHistory('selectedTransaction'),
  serviceError: makeSelectError('serviceError'),
  transaction: makeSelectTransactionDetails(),
  /**
   * Store: OneTimePayment
   */
  // eslint-disable-next-line sort-keys
  isAllowedToScheduleOTP: selectIsAllowedToScheduleOTP(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
  * Store: Payment History
  */
  dispatchFetchTransactionDetail: (payload) => dispatch(fetchTransactionDetail(payload)),
  dispatchUpdateSelectedTransaction: (id) => dispatch(updateSelectedTransaction(id)),
  /**
   * Store: SecureMessaging
   */
  // eslint-disable-next-line sort-keys
  dispatchSecureMessagingChangeFormFieldValue: (payload) => dispatch(changeFormFieldValue(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(injectIntl, withConnect)(PaymentDetailScreen);
