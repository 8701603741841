import styled from 'styled-components/native';

import { moderateScale } from '../../../../../utils/scaleHelpers';
import {
  BaseTextField, CheckboxWithText, H3, P2,
} from '../../../../base_ui';

export const AccountTypeLabel = styled(P2)`
  color: ${({ theme: { colors: { primaryCarbon } } }) => primaryCarbon};
  font-family: ${({ theme: { fontFamilies: { secondaryFont } } }) => secondaryFont};
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  line-height: ${({ theme: { fontSizes: { f6 } } }) => f6}px;
  min-height: ${moderateScale(18)}px;
  width: ${moderateScale(106)}px;
`;

export const AccountTypeRow = styled.View`
  flex-direction: row;
  column-gap: ${moderateScale(16)}px;
`;

export const AccountTypeSection = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding-bottom: ${moderateScale(16)}px;
  padding-right: ${moderateScale(16)}px;
  width: ${moderateScale(327)}px;
`;

export const Container = styled.View`
  margin: ${moderateScale(24)}px 0;
`;

export const Header = styled.View`
  margin-bottom: ${moderateScale(16)}px;
`;

export const StyledBaseTextField =
  styled(BaseTextField).attrs(({ theme: { fontSizes: { f5 } } }) => ({
    inputFontSize: f5,
    placeholderTextColor: 'rgba(40,40,40, 0.5)',
  }))``;

export const StyledCheckboxWithText = styled(CheckboxWithText)
  .attrs(({
    textStyle,
    theme: {
      colors: { primaryCarbon },
      fontSizes: { f4 },
    },
  }) => ({
    textStyle: {
      ...textStyle,
      color: primaryCarbon,
      fontSize: f4,
      fontWeight: 400,
    },
  }))``;

export const StyledH3 = styled(H3)`
  margin-bottom: ${moderateScale(8)}px;
`;

export const TextfieldGroup = styled.View`
  width: ${moderateScale(327)}px;
`;
