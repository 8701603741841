import React from 'react';
import T from 'prop-types';
import { FlatList } from 'react-native';

import iconDictionary from '../../../../../utils/iconDictionary';
import ConditionalRender from '../../../../base_ui/ConditionalRender';
import Message from '../../../../Message';
import Item from './Item';
import ListHeader from './ListHeader';

const KeyIcon = iconDictionary('key');

const EscrowHistoryList = ({
  dispatchFetchTransactions,
  dispatchUpdateSelectedTransaction,
  flatListTransactions,
  itemsPerPage,
  navigation,
  scrollLoading,
}) => {
  const handleEndReached = () => {
    if (!scrollLoading) {
      dispatchFetchTransactions();
    }
  };

  const handleRowPress = (transactionId) => {
    dispatchUpdateSelectedTransaction(transactionId);
    navigation.navigate('EscrowPaymentDetail');
  };

  const renderItem = ({ item }) => (
    <Item handleRowPress={handleRowPress} {...item} />
  );

  const noDataMessageProps = {
    body: 'Please check again after a payment has been made.',
    icon: KeyIcon,
    title: 'No Payment History.',
  };

  return (
    <ConditionalRender
      Component={FlatList}
      FallbackComponent={Message}
      propsToPassDown={{
        data: flatListTransactions,
        initialNumToRender: itemsPerPage,
        keyExtractor: ({ id }) => id,
        ListHeaderComponent: ListHeader,
        onEndReached: handleEndReached,
        onEndReachedThreshold: 0.3,
        renderItem,
        stickyHeaderIndices: [0],
        ...noDataMessageProps,
      }}
      shouldRender={!!flatListTransactions.length}
    />
  );
};

EscrowHistoryList.propTypes = {
  dispatchFetchTransactions: T.func.isRequired,
  dispatchUpdateSelectedTransaction: T.func.isRequired,
  flatListTransactions: T.arrayOf(T.shape({
    amount: T.string,
    date: T.string,
    id: T.string,
    isSkeletonData: T.bool,
    type: T.string,
  })).isRequired,
  itemsPerPage: T.number.isRequired,
  navigation: T.object.isRequired,
  scrollLoading: T.bool.isRequired,
};

export default EscrowHistoryList;
