import { PixelRatio } from 'react-native';

import { moderateScale } from '../../../utils/scaleHelpers';

const ARROW_DIAGONAL = moderateScale(16);
export const ARROW_SIDE = PixelRatio.roundToNearestPixel(ARROW_DIAGONAL / 1.41);
const HALF_SIDE = PixelRatio.roundToNearestPixel(ARROW_SIDE / 2);
const SMALL_ROTATION = 45;
const MEDIUM_ROTATION = 135;
const LARGE_ROTATION = 225;
const CENTERED = '50%';

export const ARROW_STYLES = {
  bottom: {
    bottom: `${-HALF_SIDE}px`,
    left: CENTERED,
    rotate: -SMALL_ROTATION,
    translateX: -HALF_SIDE,
  },
  left: {
    left: `${-HALF_SIDE}px`,
    rotate: SMALL_ROTATION,
    top: CENTERED,
    translateY: -HALF_SIDE,
  },
  right: {
    right: `${-HALF_SIDE}px`,
    rotate: LARGE_ROTATION,
    top: CENTERED,
    translateY: -HALF_SIDE,
  },
  top: {
    left: CENTERED,
    rotate: MEDIUM_ROTATION,
    top: `${-HALF_SIDE}px`,
    translateX: -HALF_SIDE,
  },
  topLeft: {
    left: `${ARROW_DIAGONAL}px`,
    rotate: MEDIUM_ROTATION,
    top: `${-HALF_SIDE}px`,
  },
  topRight: {
    right: `${ARROW_DIAGONAL}px`,
    rotate: MEDIUM_ROTATION,
    top: `${-HALF_SIDE}px`,
  },
};
