import styled from 'styled-components/native';

import { moderateScale } from '../../../../../../utils/scaleHelpers';
import {
  H4,
  P2,
  PrimaryDivider,
} from '../../../../../base_ui';

export const Container = styled.View`
  margin-bottom: ${moderateScale(10)}px;
`;

export const Heading = styled(H4)`
  bottom: 1px;
  margin-left: ${moderateScale(7)}px;
  position: relative;
`;

export const HeadingContainer = styled.View`
  flex-direction: row;
  padding-bottom: ${moderateScale(10)}px;
`;

export const PaperlessHeaderContainer = styled.View`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
`;

export const PaperlessHeading = styled(H4)`
  font-size: ${({ theme: { fontSizes: { f5 } } }) => f5}px;
  font-weight: 700;
  padding-right: ${moderateScale(8)}px;
`;

export const PaperlessHeadingContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const StyledPressable = styled.Pressable`
  opacity: ${({ $pressed }) => $pressed ? 0.2 : 1};
  overflow: visible;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  margin-bottom: ${moderateScale(4)}px;
  margin-top: ${moderateScale(16)}px;
  max-width: 100%;
`;

export const ToggleContainer = styled.View`
  align-items: center;
  flex-direction: row;
`;

export const ToggleLabel = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  font-weight: 700;
  margin-right: ${moderateScale(4)}px;
`;

export const UnavailableContainer = styled.View`
  display: flex;
  flex-direction: column;
  height: ${moderateScale(36)}px;
  justify-content: center;
`;
