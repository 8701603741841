/* eslint-disable max-len */
import React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../scaleHelpers';

export default function HeaderAccountActiveIcon(props) {
  const { colors: { primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(40)} viewBox="0 0 40 40" width={moderateScale(40)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx="20" cy="20" fill="white" r="20" />
      <Path d="M13.5996 27.5998C14.3996 24.7998 16.8996 22.7998 19.8996 22.7998C22.9996 22.7998 25.5996 24.8998 26.2996 27.7998C26.2996 27.7998 23.6996 29.9998 20.0996 29.8998C16.4996 29.8998 13.5996 27.5998 13.5996 27.5998Z" fill={primary} />
      <Path d="M20.0004 30.7C14.2004 30.7 9.40039 26 9.40039 20.1C9.40039 14.2 14.1004 9.5 20.0004 9.5C25.9004 9.5 30.6004 14.2 30.6004 20.1C30.6004 26 25.8004 30.7 20.0004 30.7ZM20.0004 10.8C14.9004 10.8 10.7004 15 10.7004 20.1C10.7004 25.2 14.9004 29.4 20.0004 29.4C25.1004 29.4 29.3004 25.2 29.3004 20.1C29.3004 15 25.1004 10.8 20.0004 10.8Z" fill={primary} />
      <Path d="M20.0004 20.4002C21.9886 20.4002 23.6004 18.7884 23.6004 16.8002C23.6004 14.812 21.9886 13.2002 20.0004 13.2002C18.0122 13.2002 16.4004 14.812 16.4004 16.8002C16.4004 18.7884 18.0122 20.4002 20.0004 20.4002Z" fill={primary} />
      <Path d="M20 20.7002C17.8 20.7002 16 18.9002 16 16.7002C16 14.5002 17.8 12.7002 20 12.7002C22.2 12.7002 24 14.5002 24 16.7002C24 18.9002 22.2 20.7002 20 20.7002ZM20 13.5002C18.2 13.5002 16.8 14.9002 16.8 16.7002C16.8 18.5002 18.2 20.0002 20 20.0002C21.8 20.0002 23.2 18.6002 23.2 16.8002C23.2 15.0002 21.7 13.5002 20 13.5002Z" fill={primary} />
      <Path d="M26.4002 28.4001C26.1002 28.4001 25.9002 28.2001 25.8002 27.9001C25.1002 25.3001 22.7002 23.4001 20.0002 23.4001C17.4002 23.4001 15.0002 25.2001 14.3002 27.7001C14.2002 28.0001 13.9002 28.2001 13.5002 28.1001C13.2002 28.0001 13.0002 27.7001 13.1002 27.3001C14.0002 24.2001 16.8002 22.1001 20.0002 22.1001C23.3002 22.1001 26.2002 24.3001 27.0002 27.5001C27.1002 27.8001 26.9002 28.2001 26.5002 28.3001C26.5002 28.4001 26.4002 28.4001 26.4002 28.4001Z" fill={primary} />
    </Svg>
  );
}
