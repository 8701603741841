/* eslint-disable max-len */
import React from 'react';
import Svg, {
  Line,
  Path,
  Polygon,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function DisasterForms(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg height={moderateScale(85)} viewBox="0 0 85 85" width={moderateScale(85)} {...props}>
      <Path className="cls-1" d="M43.17,4.75,11.52,4.68A4.3,4.3,0,0,0,7.22,9l0,11.6,0,3.12L7,66.69A4.36,4.36,0,0,0,11.37,71l44,.11a4.31,4.31,0,0,0,4.33-4.28h0L59.8,21.5Z" fill="#bac2ca" />
      <Path className="cls-2" d="M41.09,3,9.44,3a4.3,4.3,0,0,0-4.3,4.28L5,65a4.32,4.32,0,0,0,4.3,4.32l44,.12a4.31,4.31,0,0,0,4.33-4.29h0l.11-45.35Z" fill="#fff" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="5.08" x2="5.08" y1="17.76" y2="20.18" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="17.39" x2="44.74" y1="36.23" y2="36.3" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="17.44" x2="29.24" y1="18.81" y2="18.83" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="17.38" x2="44.73" y1="40.31" y2="40.39" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="17.37" x2="44.71" y1="44.74" y2="44.81" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="17.36" x2="44.7" y1="48.83" y2="48.9" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="17.36" x2="44.7" y1="52.92" y2="52.99" />
      <Path className="cls-3" d="M5.07,22,5,65a4.32,4.32,0,0,0,4.3,4.32l44,.12a4.31,4.31,0,0,0,4.33-4.29h0l.11-45.35L41.09,3,9.44,3a4.3,4.3,0,0,0-4.3,4.28v9" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" />
      <Path className="cls-4" d="M57.69,19.77H45.36a4.32,4.32,0,0,1-4.3-4.32V3Z" fill="#e6ebef" />
      <Path className="cls-5" d="M57.69,19.77H45.36a4.32,4.32,0,0,1-4.3-4.32V3" fill="none" stroke="#000" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="0.66px" />
      <Path className="cls-6" d="M5.07,22,5,65a4.32,4.32,0,0,0,4.3,4.32l44,.12a4.31,4.31,0,0,0,4.33-4.29h0l.11-45.35L41.09,3,9.44,3a4.3,4.3,0,0,0-4.3,4.28v9" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.1px" />
      <Line className="cls-3" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" x1="51.01" x2="51.01" y1="49.5" y2="52.28" />
      <Path className="cls-1" d="M79.54,47.78C66.21,51.16,62,39.91,62,39.91s-4.17,11.22-17.55,7.87c0,0-5.13,25.48,17.51,34.27C84.68,73.23,79.54,47.78,79.54,47.78Z" fill="#bac2ca" />
      <Path className="cls-7" d="M59.8,38.84s-4.17,11.25-17.5,7.9c0,0-5.1,25.45,17.5,34.24" fill="#fff" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" />
      <Path className="cls-8" d="M59.8,38.84S64,50.09,77.31,46.74c0,0,5.13,25.45-17.51,34.24" fill="#e6ebef" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" />
      <Path className="cls-9" d="M77.31,46.74C64,50.12,59.8,38.87,59.8,38.87s-4.17,11.22-17.5,7.87c0,0-5.13,25.48,17.5,34.27C82.44,72.19,77.31,46.74,77.31,46.74Z" fill="none" stroke="#2c2627" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.1px" />
      <Polygon className="cls-10" fill={svgHighlight} points="65.93 59.29 65.93 62.68 61.5 62.68 61.5 67.11 58.11 67.11 58.11 62.68 53.67 62.68 53.67 59.29 58.11 59.29 58.11 54.85 61.5 54.85 61.5 59.29 65.93 59.29" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.66px" />
    </Svg>
  );
}
