import React, { Fragment } from 'react';
import T from 'prop-types';

import iconDictionary from '../../../utils/iconDictionary';
import {
  ErrorSuccessBanner,
  LargeProgressButton,
  LoginRegisterTextField,
} from '../../base_ui';
import {
  ButtonsContainer,
  ContentContainer,
  Heading,
  Instructions,
  StyledBaseInternalLink,
  StyledPrimaryDivider,
} from './styledComponents';

const Illustration = iconDictionary('padlock');

const Totp = ({
  error,
  handleCancel,
  handleInputChange,
  handleSubmit,
  passcode,
  selectedMfaOption,
  status,
  validationError,
}) => (
  <Fragment>
    <ErrorSuccessBanner error={error} success={false} />
    <ContentContainer>
      {Illustration}
      <Heading>Secure Login</Heading>
      <Instructions>
        { // eslint-disable-next-line max-len
          `Please enter the six-digit passcode that was sent to your registered ${selectedMfaOption === 'SMS' ? 'mobile number' : 'email'}.`
        }
      </Instructions>
      <LoginRegisterTextField
        error={validationError}
        keyboardType="numeric"
        label="Six-Digit Passcode"
        onChangeText={(value) => handleInputChange(value)}
        value={passcode}
      />
    </ContentContainer>
    <StyledPrimaryDivider />
    <ButtonsContainer>
      <LargeProgressButton
        label="Submit"
        onPress={handleSubmit}
        status={status}
      />
      <StyledBaseInternalLink
        label="Cancel"
        onPressFunc={handleCancel}
        path="Login"
        size="medium"
      />
    </ButtonsContainer>
  </Fragment>
);

Totp.propTypes = {
  error: T.oneOfType([T.bool, T.string]).isRequired,
  handleCancel: T.func.isRequired,
  handleInputChange: T.func.isRequired,
  handleSubmit: T.func.isRequired,
  passcode: T.string.isRequired,
  selectedMfaOption: T.string,
  status: T.string.isRequired,
  validationError: T.oneOfType([T.bool, T.string]).isRequired,
};

export default Totp;
