import React from 'react';
import T from 'prop-types';

import { ConditionalRender } from '../../../base_ui';
import { ScheduleButton, StyledCalculatorFooterWrapper } from './styledComponents';

const CalculatorFooter = ({
  loading,
  onPress,
  renderLink,
  subText,
}) => (
  <StyledCalculatorFooterWrapper>
    <ConditionalRender
      Component={ScheduleButton}
      propsToPassDown={{
        label: subText,
        onPress,
      }}
      shouldRender={!renderLink && !loading}
    />
  </StyledCalculatorFooterWrapper>
);

CalculatorFooter.propTypes = {
  loading: T.bool.isRequired,
  onPress: T.func.isRequired,
  renderLink: T.bool.isRequired,
  subText: T.string.isRequired,
};

export default CalculatorFooter;
