import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectSsoMigrationModalProps } from '@dmi/shared/redux/Main/selectors';
import { MODAL_SSO_MIGRATION_CONTENT } from '@dmi/shared/redux/Main/constants';

import { LargePrimaryButton, P2, StyledScrollView } from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import {
  ButtonWrapper,
  CopyButton,
  CredentialsContainer,
  FlexContainer,
  FormImageWrapper,
  IconWrapper,
  MainHeader,
  Section,
  StyledBaseTextField,
  StyledFormImage,
  StyledImage,
  SubSection,
  SubSectionContent,
  SubSectionHeader,
} from './styledComponents';

const CheckmarkIcon = iconDictionary(
  'outlinedCompletePurple',
  {
    color: '#310078',
    height: 30,
    width: 30,
  },
);

const SsoMigrationModalWeb = ({
  ssoMigrationModalProps: {
    currentUserEmail,
    migrationImage,
    migrationLoginPage,
    migrationUrl,
  },
}) => {
  const {
    web: {
      confirmLabel,
      credentialsHeader,
      formImageHeader,
      formImageParagraph,
      title,
    },
  } = MODAL_SSO_MIGRATION_CONTENT;

  const handleOnPressLink = () => {
    window.location.href = migrationUrl;
  };

  const handleOnPressCopy = () => {
    navigator.clipboard.writeText(currentUserEmail);
  };

  return (
    <StyledScrollView>
      <FlexContainer>
        <StyledImage
          source={{ uri: `data:${migrationImage.type};base64,${migrationImage.image}` }}
        />
        <MainHeader>{title}</MainHeader>
        <Section>
          <SubSection>
            <IconWrapper>
              {CheckmarkIcon}
            </IconWrapper>
            <SubSectionContent>
              <SubSectionHeader>{credentialsHeader}</SubSectionHeader>
            </SubSectionContent>
          </SubSection>
          <CredentialsContainer>
            <StyledBaseTextField
              editable={false}
              label=""
              onChangeText={() => {}}
              value={currentUserEmail}
            />
            <CopyButton
              disableRipple={false}
              label="Copy"
              onPress={handleOnPressCopy}
            />
          </CredentialsContainer>
        </Section>
        <Section>
          <SubSection>
            <IconWrapper>
              {CheckmarkIcon}
            </IconWrapper>
            <SubSectionContent>
              <SubSectionHeader>{formImageHeader}</SubSectionHeader>
              <P2>{formImageParagraph}</P2>
            </SubSectionContent>
          </SubSection>
          <FormImageWrapper>
            <StyledFormImage
              resizeMode="contain"
              source={{ uri: `data:${migrationLoginPage.type};base64,${migrationLoginPage.image}` }}
            />
          </FormImageWrapper>
        </Section>
        <ButtonWrapper>
          <LargePrimaryButton
            label={confirmLabel}
            onPress={handleOnPressLink}
          />
        </ButtonWrapper>
      </FlexContainer>
    </StyledScrollView>
  );
};

SsoMigrationModalWeb.propTypes = {
  ssoMigrationModalProps: T.shape({
    currentUserEmail: T.string.isRequired,
    migrationImage: T.object.isRequired,
    migrationLoginPage: T.object.isRequired,
    migrationUrl: T.string.isRequired,
  }).isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
     * Store: Main
     */
  ssoMigrationModalProps: selectSsoMigrationModalProps(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect)(SsoMigrationModalWeb);
