/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';

import { moderateScale } from '../../../../scaleHelpers';

export default function CloseXIcon(props) {
  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path clipRule="evenodd" d="M15.5922 13.0916C15.6817 13.1125 15.7646 13.159 15.8322 13.2264C15.8998 13.2938 15.9498 13.3797 15.9769 13.4752C16.004 13.5706 16.0073 13.6723 15.9865 13.7696C15.9657 13.867 15.9214 13.9565 15.8584 14.029L12.3635 17.844C12.3635 17.844 12.4236 17.844 12.1831 17.9659C11.9427 18.0877 11.6593 17.844 11.6593 17.844L8.14726 13.9915C7.91541 13.6634 8.00986 13.5791 8.07856 13.4103C8.11179 13.3301 8.16239 13.2598 8.22602 13.2056C8.28964 13.1514 8.36438 13.1149 8.4438 13.0992C8.52322 13.0835 8.60494 13.0891 8.68192 13.1156C8.75891 13.142 8.82885 13.1885 8.88574 13.251L11.5134 16.1193V6.50203C11.5649 6.0896 11.6765 6.09897 11.8397 6.03335C11.9153 6.00301 11.9966 5.99317 12.0765 6.00466C12.1564 6.01616 12.2326 6.04865 12.2984 6.09935C12.3643 6.15005 12.4179 6.21745 12.4547 6.29575C12.4914 6.37405 12.5102 6.46092 12.5094 6.5489V16.1193L15.18 13.2135C15.2301 13.1512 15.296 13.1064 15.3693 13.0848C15.4426 13.0631 15.5201 13.0655 15.5922 13.0916Z" fill="#282828" fillRule="evenodd" />
      <Path d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z" stroke="#282828" strokeMiterlimit="10" strokeWidth="1.2" />
    </Svg>
  );
}
