import React from 'react';
import T from 'prop-types';

import { P3 } from '../../base_ui';
import {
  AmountContainer,
  ContentContainer,
  DescriptionText,
  DescriptionsContainer,
} from './styledComponents';

const FeesRow = ({
  feeAmount,
  feeDescription,
  feeDetailedDescription,
}) => (
  <ContentContainer>
    <DescriptionsContainer>
      <DescriptionText>{feeDescription}</DescriptionText>
      <P3>{feeDetailedDescription}</P3>
    </DescriptionsContainer>
    <AmountContainer>
      <P3>{feeAmount}</P3>
    </AmountContainer>
  </ContentContainer>
);

FeesRow.propTypes = {
  feeAmount: T.string.isRequired,
  feeDescription: T.string.isRequired,
  feeDetailedDescription: T.string.isRequired,
};

export default FeesRow;
