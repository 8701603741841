import styled from 'styled-components/native';

export const MessageContainer = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  height: 100%;
`;

export const StyledView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex: 1;
`;
