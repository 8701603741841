import React from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  setVideoModalData,
  setVideoRemainingDuration,
} from '@dmi/shared/redux/Help/actions';
import selectHelp from '@dmi/shared/redux/Help/selectors';

import LearningCenter from '../../components/Help/LearningCenter';

const LearningCenterScreen = ({
  dispatchSetVideoModalData,
  dispatchSetVideoRemainingDuration,
  navigation,
  videoModalData,
  videoRemainingDuration,
}) => (
  <LearningCenter
    dispatchSetVideoModalData={dispatchSetVideoModalData}
    dispatchSetVideoRemainingDuration={dispatchSetVideoRemainingDuration}
    navigation={navigation}
    videoModalData={videoModalData}
    videoRemainingDuration={videoRemainingDuration}
  />
);

LearningCenterScreen.propTypes = {
  dispatchSetVideoModalData: T.func.isRequired,
  dispatchSetVideoRemainingDuration: T.func.isRequired,
  navigation: T.object.isRequired,
  videoModalData: T.shape({
    heading: T.string.isRequired,
    languages: T.arrayOf(T.shape({
      captionUrl: T.string.isRequired,
      label: T.string.isRequired,
      tag: T.string.isRequired,
    })).isRequired,
    videoUrl: T.string.isRequired,
  }),
  videoRemainingDuration: T.number.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Help
   */
  videoModalData: selectHelp('videoModalData'),
  videoRemainingDuration: selectHelp('videoRemainingDuration'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Help
   */
  dispatchSetVideoModalData: (payload) => dispatch(setVideoModalData(payload)),
  dispatchSetVideoRemainingDuration:
    (payload) => dispatch(setVideoRemainingDuration(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LearningCenterScreen);
