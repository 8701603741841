import React, { useMemo, useState } from 'react';
import T from 'prop-types';
import { Platform } from 'react-native';
import RNPickerSelect from 'react-native-picker-select';
import { useTheme } from 'styled-components/native';

import InputAccessoryView from './InputAccessoryView';

const BaseDropDown = ({
  accessibilityName,
  menuItems,
  onChange,
  placeholder = {},
  selected,
  textInputProps = {},
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { colors: { primary } } = useTheme();

  const selectedItem = menuItems.find(({ value }) => value === selected) || {};

  const nonSelectedColor = Platform.select({ android: '#999999', ios: '#666666' });

  const coloredMenuItems = useMemo(() => menuItems.map(({ label, value }) => ({
    color: value === selected ? primary : nonSelectedColor,
    label,
    value: value.toString(),
  })), [menuItems, nonSelectedColor, primary, selected]);

  // Prevent trigger of onValueChange on every rerender
  const handleValueChange = (newSelectedValue) => {
    if (newSelectedValue) {
      onChange(
        typeof selected === 'number'
          ? +newSelectedValue
          : newSelectedValue,
      );
    }
  };

  return (
    <RNPickerSelect
      InputAccessoryView={() => (
        <InputAccessoryView
          handleDone={() => setIsOpen(false)}
        />
      )}
      items={coloredMenuItems}
      modalProps={{ visible: isOpen }}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      onValueChange={(value) => handleValueChange(value)}
      pickerProps={{ accessibilityLabel: `${accessibilityName}-${selectedItem.label}` }}
      placeholder={placeholder}
      textInputProps={{
        ellipsizeMode: 'tail',
        numberOfLines: 1,
        selection: { end: 0, start: 0 },
        ...textInputProps,
      }}
      touchableWrapperProps={{
        accessibilityHint: 'Double tap to select an option',
        accessibilityLabel: `${accessibilityName}-${selectedItem.label}`,
      }}
      value={selected.toString()}
      {...restProps}
    />
  );
};

BaseDropDown.propTypes = {
  accessibilityName: T.string.isRequired,
  menuItems: T.arrayOf(T.shape({
    label: T.string.isRequired,
    value: T.oneOfType([T.string, T.number]).isRequired,
  })).isRequired,
  onChange: T.func.isRequired,
  placeholder: T.shape({ label: T.string }),
  selected: T.oneOfType([T.string, T.number]).isRequired,
  textInputProps: T.object,
};

export default BaseDropDown;
