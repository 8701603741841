import { memo } from 'react';
import Animation from './animation';

/*
  Legacy exports.

  These allow for components to use `if (Platform.OS === 'web') {...}` to conditionally render
  one or the other.

  However, now a component can just import 'Animation' and not worry about that
  conditional logic. The conditional logic is done under the hood here via the bundler choosing
  to import either `animation.native.js` for native builds, or `animation.js` for other
  (namely, web) builds.

  A component may still want to conditionally pass different animation names based on Platform.OS
  though.
*/
const NativeAnimation = Animation;
const WebAnimation = Animation;

/**
 * Use memoized version to prevent animation from restarting when other unrelated props change
 * in the parent component
 */
const MemoizedAnimation = memo(Animation);
const MemoizedNativeAnimation = memo(Animation);
const MemoizedWebAnimation = memo(Animation);

export {
  Animation,
  MemoizedAnimation,
  MemoizedNativeAnimation,
  MemoizedWebAnimation,
  NativeAnimation,
  WebAnimation,
};
