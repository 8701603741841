import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';

import { COUPON_MOBILE_MESSAGE } from '@dmi/shared/redux/Documents/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import {
  ContactTextWrapper,
  CouponBookFallbackWrapper,
  IconWrapper,
  PhoneNumber,
  StyledScrollView,
  StyledText,
  Title,
} from './styledComponents';

const DocumentsStatementsIllustration = iconDictionary('documentsStatements');

const CouponBookFallBack = ({ customerServicePhoneNumber }) => {
  const { accountText, customerContactTextOne, customerContactTextTwo } = COUPON_MOBILE_MESSAGE;

  return (
    <StyledScrollView>
      <CouponBookFallbackWrapper>
        <IconWrapper>{DocumentsStatementsIllustration}</IconWrapper>
        <Title>{'Monthly Billing Statements Unavailable'}</Title>
        <StyledText>{accountText}</StyledText>
        <ContactTextWrapper>
          <StyledText>{customerContactTextOne}</StyledText>
          <StyledText>
            {customerContactTextTwo}
            <PhoneNumber
              onPress={() =>
                Linking.openURL(`tel://${customerServicePhoneNumber.replace(/\+|-/g, '')}`)}
            >
              {`${customerServicePhoneNumber}.`}
            </PhoneNumber>
          </StyledText>
        </ContactTextWrapper>
      </CouponBookFallbackWrapper>
    </StyledScrollView>
  );
};

CouponBookFallBack.propTypes = { customerServicePhoneNumber: T.string.isRequired };

export default CouponBookFallBack;
