import React, { useState } from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { setDismissedPreLoginBanner } from '@dmi/shared/redux/Main/helpers';

import iconDictionary from '../../../../../utils/iconDictionary';
import {
  BannerHeading,
  ContentContainer,
  DismissibleBannerContainer,
  DismissibleContainer,
  HeadingContainer,
  StyledDismissibleView,
} from '../styledComponents';
import BodyContent from '../BodyContent';

const CloseXIcon = iconDictionary('close', { height: 16, width: 12 });

const DismissibleBanner = ({
  bannerName,
  handleDismissBanner,
  heading,
  isLastBanner = false,
  isTopBanner = false,
  isUrgent = false,
  toggleShowBanners,
  ...restProps
}) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const handleDismiss = () => {
    setDismissedPreLoginBanner(bannerName);
    handleDismissBanner(bannerName);
    setIsDismissed(true);
  };

  return (
    <StyledDismissibleView
      $isDismissed={isDismissed}
      $isTopBanner={isTopBanner}
    >
      <DismissibleContainer
        $isDismissed={isDismissed}
        $isLastBanner={isLastBanner}
        $isTopBanner={isTopBanner}
      >
        <DismissibleBannerContainer $isUrgent={isUrgent}>
          <HeadingContainer onPress={toggleShowBanners}>
            <BannerHeading>{heading}</BannerHeading>
            <Pressable
              aria-label="banner dismiss button"
              onPress={handleDismiss}
              role="button"
              style={({ pressed }) => ({ opacity: pressed ? 0.2 : 1 })}
            >
              {CloseXIcon}
            </Pressable>
          </HeadingContainer>
          <ContentContainer $isDismissible>
            <BodyContent {...restProps} />
          </ContentContainer>
        </DismissibleBannerContainer>
      </DismissibleContainer>
    </StyledDismissibleView>
  );
};

DismissibleBanner.propTypes = {
  bannerName: T.string.isRequired,
  handleDismissBanner: T.func.isRequired,
  heading: T.string.isRequired,
  isLastBanner: T.bool,
  isTopBanner: T.bool,
  isUrgent: T.bool,
  toggleShowBanners: T.func,
};

export default DismissibleBanner;
