import React from 'react';
import T from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { LOANS_CONTENT_DICTIONARY } from '@dmi/shared/redux/Settings/Loans/constants';
import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';

import iconDictionary from '../../../utils/iconDictionary';
import {
  Animation,
  H5,
  P2,
} from '../../base_ui';
import {
  ChangeMailingAddressContainer,
  ConfirmationContent,
  ConfirmationRow,
  IconWrapper,
  StatusWrapper,
  StyledConfirmationP2,
  StyledH2,
  StyledP4,
} from './styledComponents';

const PendingIcon = iconDictionary('outlinedPendingPurple');

const ConfirmationComponent = ({ unmaskedSelectedLoan }) => {
  const {
    subheader,
    text1,
    text2,
  } = LOANS_CONTENT_DICTIONARY.changeMailingAddress.confirmationView.mobile;

  return (
    <ChangeMailingAddressContainer $isConfirmation>
      <Animation name="robotMobile" />
      <ConfirmationContent>
        <StyledH2>{subheader}</StyledH2>
        <StyledConfirmationP2>{text1}</StyledConfirmationP2>
        <ConfirmationRow>
          <H5>
            <FormattedMessage id={INTL_IDS.CONFIRMATION_ROW_LOANNUMBER} />
          </H5>
          <P2>{unmaskedSelectedLoan}</P2>
        </ConfirmationRow>
        <StatusWrapper>
          <IconWrapper>{PendingIcon}</IconWrapper>
          <StyledP4>{text2}</StyledP4>
        </StatusWrapper>
      </ConfirmationContent>
    </ChangeMailingAddressContainer>
  );
};

ConfirmationComponent.propTypes = { unmaskedSelectedLoan: T.string.isRequired };

export default ConfirmationComponent;
