import React from 'react';
import T from 'prop-types';

import Row from './Row';
import { Container } from './styledComponents';

const InfoSection = ({ data: { text, variant } }) => (
  <Container>
    <Row
      key={text.slice(0, 10)}
      text={text}
      variant={variant}
    />
  </Container>
);

InfoSection.propTypes = { data: T.shape({ text: T.string, variant: T.string }).isRequired };

export default InfoSection;
