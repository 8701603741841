import NewSecureMessagingComponent from './NewSecureMessagingComponent';
import SelectComponent from './Select/SelectComponent';
import { NewMessageConfirmation, NewMessageError } from './ConfirmationComponents';

export const viewDictionary = {
  failed: NewMessageError,
  Messaging: NewSecureMessagingComponent,
  Subtopic: SelectComponent,
  succeeded: NewMessageConfirmation,
  Topic: SelectComponent,
};
