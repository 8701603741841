import styled, { css } from 'styled-components/native';

import {
  BaseInternalLink,
  H2,
  PrimaryDivider,
} from '../../base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

const commonWidth = css`
  width: ${moderateScale(287)}px;
`;

export const ButtonsContainer = styled.View`
  align-items: center;
  padding: ${moderateScale(30)}px 0;
`;

export const ContentContainer = styled.View`
  align-items: center;
  flex-grow: 1;
  padding: ${moderateScale(30)}px 0 ${moderateScale(10)}px;
`;

export const Heading = styled(H2)`
  margin: ${moderateScale(20)}px 0;
  text-align: center;
  ${commonWidth}
`;

export const Instructions = styled.Text`
  font-size: ${({ theme: { fontSizes: { f3 } } }) => f3}px;
  margin-bottom: ${moderateScale(30)}px;
  text-align: center;
  ${commonWidth}
`;

export const StyledBaseInternalLink = styled(BaseInternalLink)`
  margin-top: ${moderateScale(14)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
