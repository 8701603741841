import styled from 'styled-components/native';

import { moderateScale } from '../../../utils/scaleHelpers';

const PrimaryDivider = styled.View`
  background-color: ${({ theme: { colors: { buttonGray } } }) => buttonGray};
  height: ${moderateScale(1)}px;
  max-width: ${moderateScale(327)}px;
`;

export default PrimaryDivider;
