import React, { PureComponent } from 'react';
import T from 'prop-types';
import { View } from 'react-native';

import { Heading, Text } from './styledComponents';

// class component used to leverage PureComponent for better performance
class ListItem extends PureComponent {
  render() {
    const { definition, term } = this.props;
    return (
      <View>
        <Heading>{term}</Heading>
        <Text>{definition}</Text>
      </View>
    );
  }
}

ListItem.propTypes = { definition: T.string.isRequired, term: T.string.isRequired };

export default ListItem;
