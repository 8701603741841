import React, { Fragment } from 'react';
import T from 'prop-types';

import GeneralDocsView from '../../components/Documents/GeneralDocs';
import { SecondaryHeader } from '../../components/ScreenHeaders';

const GeneralDocsScreen = ({ navigation }) => (
  <Fragment>
    <SecondaryHeader
      handleBack={() => navigation.goBack()}
      title="Forms and Resources"
    />
    <GeneralDocsView />
  </Fragment>
);

GeneralDocsScreen.propTypes = { navigation: T.object.isRequired };

export default GeneralDocsScreen;
