import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { ConditionalRender, P2, P3 } from '../../../base_ui';
import HomeCard from './HomeCard';
import iconDictionary from '../../../../utils/iconDictionary';
import {
  Bullet,
  BulletContainer,
  DateContainer,
  Row,
  RowDivider,
  TableContainer,
  TaxTypeText,
} from './styledComponents';

const Icon = iconDictionary('mortgageOverview');

const TaxesAndInsuranceOverview = ({ data }) => (
  <HomeCard
    HeaderIcon={Icon}
    headerText="Taxes & Insurance Overview"
    linkLabel="All Taxes and Insurance Details"
    linkPath="TaxesInsuranceDetails"
  >
    <TableContainer>
      {data.map(({
        amount,
        bulletColor,
        date,
        type,
      }, index) => (
        <View key={`${type}-${date}`}>
          <Row>
            <View>
              <TaxTypeText>{type}</TaxTypeText>
              <DateContainer>
                <BulletContainer>
                  <Bullet $bulletColor={bulletColor} />
                </BulletContainer>
                <P3>{date}</P3>
              </DateContainer>
            </View>
            <P2>{amount}</P2>
          </Row>
          <ConditionalRender
            Component={<RowDivider />}
            shouldRender={index !== data.length - 1}
          />
        </View>
      ))}
    </TableContainer>
  </HomeCard>
);

TaxesAndInsuranceOverview.propTypes = {
  data: T.arrayOf(
    T.shape({
      amount: T.string.isRequired,
      date: T.string.isRequired,
      type: T.string.isRequired,
    }).isRequired,
  ).isRequired,
};

export default TaxesAndInsuranceOverview;
