import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { InputAccessoryContainer, StyledText } from './styledComponents';

const InputAccessoryView = ({ handleDone }) => (
  <InputAccessoryContainer>
    <Pressable
      hitSlop={{
        bottom: 4,
        left: 4,
        right: 4,
        top: 4,
      }}
      onPress={handleDone}
    >
      <StyledText>Done</StyledText>
    </Pressable>
  </InputAccessoryContainer>
);

InputAccessoryView.propTypes = { handleDone: T.func.isRequired };

export default InputAccessoryView;
