import React from 'react';
import T from 'prop-types';
import { Linking } from 'react-native';

import { P3 } from '../../base_ui';
import iconDictionary from '../../../utils/iconDictionary';
import {
  DeleteIconWrapper,
  DeleteMessageWrapper,
  PhoneNumberWrapper,
} from './styledComponents';

const IconInfo = iconDictionary('infoTooltipSIcon');

const DeleteAccountBanner = ({ customerServicePhoneNumber }) => (
  <DeleteMessageWrapper>
    <DeleteIconWrapper>{IconInfo}</DeleteIconWrapper>
    <P3>
      If you would like to delete your account, please call customer service.
      {' '}
      <PhoneNumberWrapper
        onPress={() => Linking.openURL(`tel://${customerServicePhoneNumber.replace(/\+|-/g, '')}`)}
      >
        { customerServicePhoneNumber }
      </PhoneNumberWrapper>
    </P3>
  </DeleteMessageWrapper>
);

DeleteAccountBanner.propTypes = { customerServicePhoneNumber: T.string.isRequired };

export default DeleteAccountBanner;
