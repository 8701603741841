import React from 'react';
import T from 'prop-types';

import { ConditionalRender, H2, P4 } from '../../../base_ui';
import {
  StyledFooterSummaryWrapper,
  StyledPrimaryButton,
  StyledProgressButton,
  TextWrapper,
} from './styledComponents';

const FooterSummary = ({
  hasProgressButton = false,
  subText,
  text,
  ...restProps
}) => (
  <StyledFooterSummaryWrapper>
    <TextWrapper>
      <H2>{text}</H2>
      <P4>{subText}</P4>
    </TextWrapper>
    <ConditionalRender
      Component={StyledPrimaryButton}
      FallbackComponent={StyledProgressButton}
      propsToPassDown={restProps}
      shouldRender={!hasProgressButton}
    />
  </StyledFooterSummaryWrapper>
);

FooterSummary.propTypes = {
  hasProgressButton: T.bool,
  subText: T.string.isRequired,
  text: T.string.isRequired,
};

export default FooterSummary;
