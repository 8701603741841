import React from 'react';
import T from 'prop-types';
import { Pressable } from 'react-native';

import { PAPERLESS_TOOLTIP_COPY } from '@dmi/shared/redux/Settings/Communications/constants';

import { P2, Tooltip } from '../../../../../base_ui';
import { LinkText } from './styledComponents';

const CommunicationsTooltip = ({ onClose }) => (
  <Tooltip
    handleClose={onClose}
    positionAbsolute={{ left: 'auto', top: 'auto' }}
    visible
  >
    <P2>{PAPERLESS_TOOLTIP_COPY}</P2>
    <Pressable onPress={onClose}>
      <LinkText>Got it</LinkText>
    </Pressable>
  </Tooltip>
);

CommunicationsTooltip.propTypes = { onClose: T.func.isRequired };

export default CommunicationsTooltip;
