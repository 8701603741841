import styled from 'styled-components/native';

import { moderateScale } from '../../../../utils/scaleHelpers';
import { P2, P3 } from '../../../base_ui';

export const HeaderText = styled(P2)`
  font-family: ${({ theme: { fontFamilies: { secondaryFontBold } } }) => secondaryFontBold};
  padding-right: ${moderateScale(5)}px;
  text-align: ${({ $rightAlign }) => $rightAlign ? 'right' : 'left'};
  width: ${({ $widthPercent }) => $widthPercent ? `${$widthPercent}%` : 'auto'};
`;

export const IconView = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  width: 12%;
`;

export const PressableRow = styled.Pressable`
  background-color: ${(
    ({
      $isPressing,
      theme: { colors: { accentSilver, dmiWhite } },
    }) => $isPressing ? accentSilver : dmiWhite
  )};
  flex-direction: row;
  padding: ${(moderateScale(16))}px ${(moderateScale(24))}px;
`;

export const RowText = styled(P3).attrs({ numberOfLines: 1 })`
  padding-right: ${moderateScale(18)}px;
  width: 31%;
`;

export const RowType = styled(RowText)`
  flex-shrink: 1;
  margin-right: auto;
  width: 43%;
`;

export const RowView = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  flex-direction: row;
  padding: ${moderateScale(8)}px ${moderateScale(24)}px ${moderateScale(16)}px;
`;

export const SkeletonView = styled.View`
  width: ${({ $widthPercent }) => `${$widthPercent}%`};
`;
