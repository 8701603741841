import React from 'react';
import { Linking, View } from 'react-native';
import T from 'prop-types';

import { MANCODE_Y_LOAN_SELECT_TEXT } from '@dmi/shared/redux/Settings/Loans/constants';

import { ConditionalRender, P3 } from '../../base_ui';
import {
  BoldText,
  ContentContainer,
  PhoneNumberWrapper,
  StyledFormattedMessageStatusBanner,
  StyledPrimaryDivider,
} from './styledComponents';
import { LoanCardPending, LoanCardSelect } from '../Shared/LoanCards';

const SelectMailingAddress = ({
  dispatchSetSelectedLoan,
  handleOpenModal,
  hashedSelectedLoan,
  isLoading,
  loanNumbers,
  mailingAddressProps,
  propertyAddresses,
}) => (
  <ContentContainer>
    {propertyAddresses.map(({
      loan,
      onlineAvailabilityStatus,
      property: propertyAddress,
    }, i) => {
      const isActive = onlineAvailabilityStatus === 'Available';
      const {
        canEditOnline: canEditMailingAddress,
        canUpload: hasCoBorrower,
        isLoanMancodeY,
        mailingAddress,
        pending: isPending,
      } = mailingAddressProps[loan] || {};
      const propsToPassDown = {
        canEditMailingAddress,
        dispatchSetSelectedLoan,
        handleOpenModal,
        hasCoBorrower,
        hashedSelectedLoan,
        isActive,
        isLoading,
        isLoanMancodeY,
        isPending,
        loanNumber: loan,
        mailingAddress,
        propertyAddress,
        unmaskedLoanNumber: loanNumbers[loan],
      };

      return (
        <View key={loan}>
          <StyledPrimaryDivider />
          <ConditionalRender
            Component={StyledFormattedMessageStatusBanner}
            propsToPassDown={{
              defaultMessage: MANCODE_Y_LOAN_SELECT_TEXT,
              error: true,
              values: {
                // eslint-disable-next-line react/prop-types
                a: ({ msg }) => (
                  <PhoneNumberWrapper
                    // eslint-disable-next-line react/prop-types
                    onPress={() => Linking.openURL(`tel://${msg.replace(/\+|-/g, '')}`)}
                  >
                    {msg}
                  </PhoneNumberWrapper>
                ),
                // eslint-disable-next-line react/prop-types
                b: ({ msg }) => <BoldText>{msg}</BoldText>,
                mobileNewline: '\n',
              },
              WrapperComponent: P3,
            }}
            shouldRender={!!isLoanMancodeY}
          />
          <ConditionalRender
            Component={LoanCardSelect}
            FallbackComponent={LoanCardPending}
            propsToPassDown={propsToPassDown}
            shouldRender={!isPending}
          />
          <ConditionalRender
            Component={StyledPrimaryDivider}
            shouldRender={i === propertyAddresses.length - 1}
          />
        </View>
      );
    })}
  </ContentContainer>
);

SelectMailingAddress.propTypes = {
  dispatchSetSelectedLoan: T.func.isRequired,
  handleOpenModal: T.func.isRequired,
  hashedSelectedLoan: T.string.isRequired,
  isLoading: T.bool.isRequired,
  loanNumbers: T.object.isRequired,
  mailingAddressProps: T.object.isRequired,
  propertyAddresses: T.array.isRequired,
};

export default SelectMailingAddress;
