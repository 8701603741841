import React, { useEffect } from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import makeSelectMobile from '@dmi/shared/redux/Mobile/selectors';
import { selectIsEligibleForAutopay } from '@dmi/shared/redux/Payments/Autopay/selectors';
import {
  cancelOtp,
  resetConfirmation,
  resetOneTimePaymentState,
  setIsModalOpen,
} from '@dmi/shared/redux/Payments/OneTimePayment/actions';
import { generateCancelOtpModalProps } from '@dmi/shared/redux/Payments/OneTimePayment/helpers';
import {
  selectCancelModalProps,
  selectHasLateCharges,
  selectOtpConfirmation,
} from '@dmi/shared/redux/Payments/OneTimePayment/selectors';
import { getMainClientInfo, selectIsMurabahaAccount } from '@dmi/shared/redux/Main/selectors';

import Confirmation from '../../../components/Payments/Confirmation';
import { StyledScrollView } from '../../../components/base_ui';

const ConfirmationScreen = ({
  cancelModalProps,
  customerServicePhoneNumber,
  dispatchCancelOtp,
  dispatchResetConfirmation,
  dispatchResetOneTimePaymentState,
  dispatchSetIsModalOpen,
  hasLateCharges,
  isEligibleForAutopay,
  isInAppReviewSupported,
  isMurabahaAccount,
  navigation,
  otpConfirmation,
}) => {
  useEffect(() => () => {
    dispatchResetConfirmation();
    dispatchResetOneTimePaymentState();
  }, [dispatchResetConfirmation, dispatchResetOneTimePaymentState]);

  const cancelOtpModalProps = generateCancelOtpModalProps({
    ...cancelModalProps,
    dispatchCancelOtp,
    dispatchSetIsModalOpen,
  });

  return (
    <StyledScrollView>
      <Confirmation
        customerServicePhoneNumber={customerServicePhoneNumber}
        data={otpConfirmation}
        dispatchSetIsModalOpen={dispatchSetIsModalOpen}
        hasLateCharges={hasLateCharges}
        isEligibleForAutopay={isEligibleForAutopay}
        isInAppReviewSupported={isInAppReviewSupported}
        isMurabahaAccount={isMurabahaAccount}
        modalProps={cancelOtpModalProps}
        navigation={navigation}
        type="otp"
      />
    </StyledScrollView>
  );
};

ConfirmationScreen.propTypes = {
  cancelModalProps: T.shape({
    amount: T.string.isRequired,
    date: T.string.isRequired,
    isOpen: T.bool.isRequired,
    loading: T.bool.isRequired,
  }).isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchCancelOtp: T.func.isRequired,
  dispatchResetConfirmation: T.func.isRequired,
  dispatchResetOneTimePaymentState: T.func.isRequired,
  dispatchSetIsModalOpen: T.func.isRequired,
  hasLateCharges: T.bool.isRequired,
  isEligibleForAutopay: T.bool.isRequired,
  isInAppReviewSupported: T.bool.isRequired,
  isMurabahaAccount: T.bool.isRequired,
  navigation: T.object.isRequired,
  otpConfirmation: T.oneOfType([T.bool, T.array]).isRequired,
};

/* eslint-disable sort-keys */
const mapStateToProps = createStructuredSelector({
  /**
   * Store: Autopay
   */
  isEligibleForAutopay: selectIsEligibleForAutopay(),
  /**
   * Store: One Time Payment
   */
  cancelModalProps: selectCancelModalProps(),
  hasLateCharges: selectHasLateCharges(),
  otpConfirmation: selectOtpConfirmation(),
  /**
   * Store: Main
   */
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isMurabahaAccount: selectIsMurabahaAccount(),
  /**
   * Store: Mobile
   */
  isInAppReviewSupported: makeSelectMobile('isInAppReviewSupported'),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: One Time Payment
   */
  dispatchCancelOtp: () => dispatch(cancelOtp({ navigationType: 'replace' })),
  dispatchResetConfirmation: () => dispatch(resetConfirmation()),
  dispatchResetOneTimePaymentState: () => dispatch(resetOneTimePaymentState()),
  dispatchSetIsModalOpen: (payload) => dispatch(setIsModalOpen(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(ConfirmationScreen);
