import React from 'react';
import T from 'prop-types';

import {
  LinkText,
  LoanCardPendingContainer,
  PendingBannerContainer,
  PendingBannerText,
} from './styledComponents';
import LoanCard from './LoanCard';
import iconDictionary from '../../../../utils/iconDictionary';

const PendingIcon = iconDictionary('outlinedPendingPurpleL');

const LoanCardPending = ({
  handleOpenModal,
  isActive,
  loanNumber,
  mailingAddress,
  propertyAddress,
  unmaskedLoanNumber,
}) => (
  <LoanCardPendingContainer>
    <PendingBannerContainer>
      {PendingIcon}
      <PendingBannerText>
        Mailing Address Pending.&nbsp;
      </PendingBannerText>
      <LinkText onPress={() => handleOpenModal({ loanNumber })}>
        View Details
      </LinkText>
    </PendingBannerContainer>
    <LoanCard
      isActive={isActive}
      isPending
      mailingAddress={mailingAddress}
      propertyAddress={propertyAddress}
      unmaskedLoanNumber={unmaskedLoanNumber}
    />
  </LoanCardPendingContainer>
);

LoanCardPending.propTypes = {
  handleOpenModal: T.func.isRequired,
  isActive: T.bool.isRequired,
  loanNumber: T.string.isRequired,
  mailingAddress: T.object,
  propertyAddress: T.object.isRequired,
  unmaskedLoanNumber: T.string.isRequired,
};

export default LoanCardPending;
