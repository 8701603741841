import React from 'react';

import { HeaderText, RowView } from '../styledComponents';

const ListHeader = () => (
  <RowView>
    <HeaderText $widthPercent={31}>Date</HeaderText>
    <HeaderText $widthPercent={37}>Type</HeaderText>
    <HeaderText $rightAlign $widthPercent={20}>Amount</HeaderText>
  </RowView>
);

export default ListHeader;
