/* eslint-disable max-len */
import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../../../../scaleHelpers';

export default function NavDocumentsActiveLIcon(props) {
  const { colors: { navigationMenuActive, primary } } = useTheme();

  return (
    <Svg fill="none" height={moderateScale(24)} viewBox="0 0 24 24" width={moderateScale(24)} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M20.2896 4.99996L16.2096 1.09996C16.1187 1.01093 15.9968 0.960724 15.8696 0.959961H4.80957C4.67776 0.962521 4.55207 1.01602 4.45885 1.10924C4.36563 1.20246 4.31213 1.32815 4.30957 1.45996V22.56C4.31213 22.6918 4.36563 22.8175 4.45885 22.9107C4.55207 23.0039 4.67776 23.0574 4.80957 23.06H19.9996C20.1322 23.06 20.2594 23.0073 20.3531 22.9135C20.4469 22.8197 20.4996 22.6926 20.4996 22.56V5.30996C20.4871 5.24685 20.4621 5.18689 20.426 5.13364C20.39 5.08039 20.3436 5.03493 20.2896 4.99996ZM15.7496 16.87H8.74957C8.61696 16.87 8.48978 16.8173 8.39602 16.7235C8.30225 16.6297 8.24957 16.5026 8.24957 16.37C8.25213 16.2382 8.30563 16.1125 8.39885 16.0192C8.49207 15.926 8.61776 15.8725 8.74957 15.87H15.7496C15.8814 15.8725 16.0071 15.926 16.1003 16.0192C16.1935 16.1125 16.247 16.2382 16.2496 16.37C16.2496 16.5026 16.1969 16.6297 16.1031 16.7235C16.0094 16.8173 15.8822 16.87 15.7496 16.87ZM15.7496 13.09H8.74957C8.61696 13.09 8.48978 13.0373 8.39602 12.9435C8.30225 12.8497 8.24957 12.7226 8.24957 12.59C8.25213 12.4582 8.30563 12.3325 8.39885 12.2392C8.49207 12.146 8.61776 12.0925 8.74957 12.09H15.7496C15.8814 12.0925 16.0071 12.146 16.1003 12.2392C16.1935 12.3325 16.247 12.4582 16.2496 12.59C16.2496 12.7226 16.1969 12.8497 16.1031 12.9435C16.0094 13.0373 15.8822 13.09 15.7496 13.09ZM15.7496 9.31996H8.74957C8.61776 9.3174 8.49207 9.2639 8.39885 9.17068C8.30563 9.07746 8.25213 8.95177 8.24957 8.81996C8.24957 8.68735 8.30225 8.56018 8.39602 8.46641C8.48978 8.37264 8.61696 8.31996 8.74957 8.31996H15.7496C15.8822 8.31996 16.0094 8.37264 16.1031 8.46641C16.1969 8.56018 16.2496 8.68735 16.2496 8.81996C16.247 8.95177 16.1935 9.07746 16.1003 9.17068C16.0071 9.2639 15.8814 9.3174 15.7496 9.31996ZM15.8096 5.15996V2.06996L18.9996 5.15996H15.8096Z" fill={navigationMenuActive || primary} />
    </Svg>
  );
}
