import styled from 'styled-components/native';

import { MediumPrimaryButton, PrimaryDivider } from '../../../components/base_ui';
import { moderateScale } from '../../../utils/scaleHelpers';

export const SelectButtonWrapper = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
`;

export const StyledPrimaryButton = styled(MediumPrimaryButton)`
  height: ${moderateScale(45)}px;
  margin: ${moderateScale(21)}px auto ${moderateScale(35)}px;
  width: ${moderateScale(261)}px;
`;

export const StyledPrimaryDivider = styled(PrimaryDivider)`
  max-width: 100%;
`;
