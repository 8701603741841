/*
 * Verify Username Form Input
 */

import React, { Fragment, useRef } from 'react';
import { useIntl } from 'react-intl';
import T from 'prop-types';
import {
  Button,
  InputAccessoryView,
  Platform,
  View,
} from 'react-native';

import { INTL_IDS } from '@dmi/shared/redux/Register/ForgotUsername/messages';
import { trimFormValues } from '@dmi/shared/utils/globalHelpers';

import { LoginRegisterTextField, PasswordTextField } from '../../base_ui';
import {
  RegisterInputContainer,
  StyledLargeProgressButton,
  StyledLoginLink,
  StyledPrimaryDivider,
  TextFieldWrapper,
} from './styledComponents';

const VerifyUsernameFormInput = ({
  dispatchChangeInput,
  dispatchVerifyUsername,
  formErrors,
  formValues,
  handleNextClick,
  linkQueryString,
  status,
}) => {
  const intl = useIntl();
  const loanNumberRef = useRef(null);
  const ssnTinRef = useRef(null);
  const zipCodeRef = useRef(null);

  const loanNativeId = 'verifyUsernameLoanNativeId';
  const ssnTinNativeId = 'verifyUsernameSsnTinNativeId';

  return (
    <Fragment>
      <RegisterInputContainer>
        <TextFieldWrapper>
          <LoginRegisterTextField
            ref={loanNumberRef}
            error={formErrors.loanNumber}
            inputAccessoryViewID={loanNativeId}
            keyboardType="number-pad"
            label={intl.formatMessage(
              { id: INTL_IDS.FORGOT_USERNAME_VERIFY_FORM_LOAN_NUMBER_LABEL },
            )}
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'loanNumber',
              form: 'verifyUsername',
              value: newValue,
            })}
            onSubmitEditing={() => ssnTinRef.current.focus()}
            returnKeyType="next"
            value={formValues.loanNumber}
          />
          {Platform.OS === 'ios' && (
            <InputAccessoryView
              backgroundColor="rgb(244,245,245)"
              nativeID={loanNativeId}
            >
              <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Button
                  onPress={() => ssnTinRef.current.focus()}
                  title="Next"
                />
              </View>
            </InputAccessoryView>
          )}
          <PasswordTextField
            ref={ssnTinRef}
            error={formErrors.ssnTin}
            inputAccessoryViewID={ssnTinNativeId}
            keyboardType="number-pad"
            label="Last 4 Digits of SSN"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'ssnTin',
              form: 'verifyUsername',
              value: newValue,
            })}
            onSubmitEditing={() => zipCodeRef.current.focus()}
            returnKeyType="next"
            value={formValues.ssnTin}
          />
          {Platform.OS === 'ios' && (
            <InputAccessoryView
              backgroundColor="rgb(244,245,245)"
              nativeID={ssnTinNativeId}
            >
              <View style={{ flex: 1, justifyContent: 'flex-end' }}>
                <Button
                  onPress={() => zipCodeRef.current.focus()}
                  title="Next"
                />
              </View>
            </InputAccessoryView>
          )}
          <LoginRegisterTextField
            ref={zipCodeRef}
            error={formErrors.zipCode}
            keyboardType="number-pad"
            label="Property Zip Code"
            onChangeText={(newValue) => dispatchChangeInput({
              field: 'zipCode',
              form: 'verifyUsername',
              value: newValue,
            })}
            returnKeyType="done"
            value={formValues.zipCode}
          />
        </TextFieldWrapper>
      </RegisterInputContainer>
      <StyledPrimaryDivider />
      <StyledLargeProgressButton
        disabled={!formValues.loanNumber && !formValues.ssn && !formValues.zipcode}
        label="Submit"
        onPress={() => handleNextClick({
          field: ['loanNumber', 'ssnTin', 'zipCode'],
          form: 'verifyUsername',
          formValues: trimFormValues(formValues),
          handleSubmitStep: () => dispatchVerifyUsername({
            linkQueryString,
            ...trimFormValues(formValues),
          }),
        })}
        status={status.forgotUsernameVerify}
      />
      <StyledLoginLink
        label="Return to Login"
        path="Login"
        size="medium"
      />
    </Fragment>
  );
};

VerifyUsernameFormInput.propTypes = {
  dispatchChangeInput: T.func.isRequired,
  dispatchVerifyUsername: T.func.isRequired,
  formErrors: T.object.isRequired,
  formValues: T.object.isRequired,
  handleNextClick: T.func.isRequired,
  linkQueryString: T.string.isRequired,
  status: T.object.isRequired,
};

export default VerifyUsernameFormInput;
