/* eslint-disable max-len */
import React from 'react';
import { useTheme } from 'styled-components/native';
import Svg, {
  G,
  Path,
  Rect,
} from 'react-native-svg';

import { moderateScale } from '../../../../../scaleHelpers';

export default function PaymentHouseSummer(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      fill="none"
      height={moderateScale(70)}
      viewBox="0 0 100 70"
      width={moderateScale(100)}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      {...props}
    >
      <Rect
        fill="white"
        height="70"
        width="100"
      />
      <G>
        <Path d="M23.6475 69.201V41.4199H52.007V69.201" fill="#E6EBEF" />
        <Path
          d="M33.8568 51.4492H28.3594V62.8989H33.8568V51.4492Z"
          fill="#BAC2CA"
        />
        <Path
          d="M45.7243 51.1836H38.918V69.2901H45.7243V51.1836Z"
          fill={svgHighlight}
        />
        <Path
          d="M48.0807 46.5681H19.1104L32.8974 31.3906H61.8678L48.0807 46.5681Z"
          fill="#BAC2CA"
        />
        <Path
          d="M45.0266 31.3906H61.8678L48.0807 46.5681H19.1104L32.8974 31.3906H41.9725"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M61.9551 31.3906L75.0441 46.5681H72.339"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M23.5605 47.1013V69.4681H71.1173V42.1309"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M52.1816 62.0117V69.2011"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Path
          d="M52.1816 45.0586V59.171"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Path
          d="M45.8115 69.2892V51.2715H38.918V67.4253"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.65"
        />
        <Path
          d="M34.2065 51.3613H28.1855V62.8998H34.2065V51.3613Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.65"
        />
        <Path
          d="M62.2169 45.5036C63.1807 45.5036 63.9621 44.391 63.9621 43.0184C63.9621 41.6459 63.1807 40.5332 62.2169 40.5332C61.253 40.5332 60.4717 41.6459 60.4717 43.0184C60.4717 44.391 61.253 45.5036 62.2169 45.5036Z"
          fill="#E6EBEF"
          stroke="black"
          strokeMiterlimit="10"
          strokeWidth="0.65"
        />
        <Path
          d="M62.2169 45.5036C63.1807 45.5036 63.9621 44.391 63.9621 43.0184C63.9621 41.6459 63.1807 40.5332 62.2169 40.5332C61.253 40.5332 60.4717 41.6459 60.4717 43.0184C60.4717 44.391 61.253 45.5036 62.2169 45.5036Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        />
        <Path
          d="M94.8516 69.5566H99.6509"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M4.88672 69.5566H91.5359"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M0.436523 69.5566H2.09446"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
        />
        <Path
          d="M93.5433 49.2312C90.402 29.7045 85.8644 30.5921 85.8644 30.5921V30.6809V30.5921C85.8644 30.5921 81.5887 29.7045 78.3601 49.2312C78.2728 49.8525 78.1855 50.4738 78.1855 51.0951C78.1855 56.3317 81.676 60.6809 85.8644 60.6809C90.1402 60.6809 93.5433 56.4205 93.5433 51.0951C93.6306 50.4738 93.6306 49.8525 93.5433 49.2312Z"
          fill="#E6EBEF"
        />
        <Path
          d="M93.6302 51.0941C93.6302 50.4728 93.5429 49.8515 93.4556 49.2302C90.3143 29.7036 85.7768 30.5912 85.7768 30.5912V30.6799V30.5912C85.7768 30.5912 85.864 30.4137 85.4277 30.6799C86.5621 32.9876 89.878 41.4196 90.5761 49.674C91.1869 56.5971 88.6564 59.7923 85.7768 60.6799H85.864C90.227 60.6799 93.6302 56.4196 93.6302 51.0941Z"
          fill="#BAC2CA"
        />
        <Path
          d="M80.4543 39.5556C79.7562 42.0408 79.0582 45.2361 78.3601 49.2302C78.2728 49.8515 78.1855 50.4728 78.1855 51.0941C78.1855 56.3307 81.6759 60.6799 85.8644 60.6799C90.1402 60.6799 93.5433 56.4195 93.5433 51.0941C93.5433 50.4728 93.456 49.8515 93.3688 49.2302C90.2274 29.7035 85.6899 30.5911 85.6899 30.5911V30.6799V30.5911C85.6899 30.5911 83.6829 30.1473 81.3269 36.2716"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M85.8643 48.6094V69.5561"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M82.9844 52.8691L85.7767 54.9106"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M88.7435 55.2656L85.9512 57.307"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M10.21 63.9645C7.06863 63.9645 4.53809 61.3905 4.53809 58.1953V45.7692C4.53809 42.574 7.06863 40 10.21 40C13.3513 40 15.8819 42.574 15.8819 45.7692V58.1953C15.8819 61.3905 13.3513 63.9645 10.21 63.9645Z"
          fill="#E6EBEF"
        />
        <Path
          d="M12.9145 40.7109C13.6125 41.6873 13.9616 42.8411 13.9616 44.0837V56.5098C13.9616 59.705 11.431 62.279 8.28968 62.279C7.32982 62.279 6.45722 62.0127 5.67188 61.5689C6.719 62.989 8.37694 63.9654 10.2967 63.9654C13.438 63.9654 15.9686 61.3914 15.9686 58.1961V45.7701C15.8813 43.5512 14.6597 41.6873 12.9145 40.7109Z"
          fill="#BAC2CA"
        />
        <Path
          d="M10.21 63.9645C7.06863 63.9645 4.53809 61.3905 4.53809 58.1953V45.7692C4.53809 42.574 7.06863 40 10.21 40C13.3513 40 15.8819 42.574 15.8819 45.7692V58.1953C15.8819 61.3905 13.3513 63.9645 10.21 63.9645Z"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M10.1221 50.6504V69.2007"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
        <Path
          d="M89.7034 20.207C91.0995 20.207 92.0594 18.7869 91.5358 17.3668C90.4015 14.5266 88.0454 10.5325 83.5079 9.99994C81.5882 9.82243 79.843 10.5325 78.4468 11.5088C78.3596 11.5976 78.1851 11.5976 78.0105 11.5088C77.0507 10.6212 75.7418 9.99994 74.1711 9.99994C71.2915 9.99994 68.9355 12.0414 68.9355 14.5266C68.9355 14.7041 68.761 14.8816 68.5864 14.8816C68.4119 14.8816 68.3247 14.8816 68.1501 14.8816C66.4049 14.8816 64.8343 15.8579 64.3107 17.278C64.2234 17.4556 64.0489 17.5443 63.8744 17.4556C63.4381 17.1893 62.8273 17.0118 62.3037 17.1005C60.9948 17.1005 60.1222 17.8993 59.5987 18.6094C59.1624 19.3195 59.5987 20.207 60.384 20.207H89.7034Z"
          fill="#ECEEF1"
        />
        <Path
          d="M37.6967 26.2422C38.482 26.2422 38.9183 25.2659 38.3947 24.6446C37.5221 23.5795 35.7769 22.8695 33.77 22.8695C33.4209 22.8695 33.1591 22.8695 32.8101 22.9582C32.4611 22.9582 32.1993 22.6919 32.1993 22.3369C32.1993 20.5618 29.9305 19.0529 27.2255 19.0529C26.4401 19.0529 25.742 19.1416 25.1312 19.4079C24.8694 19.4967 24.5204 19.3192 24.4331 18.9641C23.7351 16.4789 20.9427 14.4375 17.5396 14.4375C13.7874 14.4375 10.646 17.0115 10.646 20.118C10.646 20.2067 10.646 20.3842 10.646 20.473C10.4715 20.473 10.297 20.473 10.1225 20.473C8.72633 20.473 7.50469 21.4493 7.06839 22.6919C6.98113 22.9582 6.63209 23.1357 6.37031 23.047C6.10853 22.9582 5.84674 22.9582 5.4977 22.9582C4.45058 22.9582 3.57798 23.402 2.96716 24.2008C2.26908 25.0884 2.8799 26.331 4.01428 26.331H37.6967V26.2422Z"
          fill="#ECEEF1"
        />
        <Path
          d="M7.24316 55.8867L10.0355 57.8394"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75"
        />
      </G>
    </Svg>
  );
}
