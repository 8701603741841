import React, { Fragment, useEffect, useRef } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useIntl } from 'react-intl';

import { updatePrimaryLoan } from '@dmi/shared/redux/Main/actions';
import makeSelectMain, {
  getIsSso,
  getLoanAddresses,
  getLoanNumberDictionary,
  getLoansIsPrimaryStatus,
  getMainClientInfo,
} from '@dmi/shared/redux/Main/selectors';
import {
  changePrimaryLoan,
  resetErrorState,
  setSelectedLoan,
} from '@dmi/shared/redux/Settings/Loans/actions';
import { INTL_IDS } from '@dmi/shared/redux/Settings/Loans/messages';
import makeSelectLoans, {
  selectBannerError,
  selectEditableMailingAddress,
  selectRequestStatus,
  selectServiceError,
} from '@dmi/shared/redux/Settings/Loans/selectors';

import { ConditionalRender, StyledScrollView } from '../../../components/base_ui';
import AsyncRender from '../../../components/AsyncRender';
import Loans from '../../../components/Settings/Loans';
import { StyledInternalLink } from '../../../components/Settings/Loans/styledComponents';
import iconDictionary from '../../../utils/iconDictionary';
import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { SelectButtonWrapper } from './styledComponents';

const AddCircleIcon = iconDictionary('addCircle');

const LoansScreen = ({
  bannerError,
  customerServicePhoneNumber,
  dispatchChangePrimaryLoan,
  dispatchResetErrorState,
  dispatchSetSelectedLoan,
  dispatchUpdatePrimaryLoan,
  isSslAuth,
  isSso,
  loanNumbers,
  loansIsPrimaryStatus,
  mailingAddressProps,
  navigation,
  propertyAddresses,
  requestStatusFetchAll,
  selectedLoan,
  serviceError,
  success,
}) => {
  const { formatMessage } = useIntl();
  const selectedLoanRef = useRef(null);
  selectedLoanRef.current = selectedLoan;

  useEffect(() => () => {
    dispatchResetErrorState();
    if (selectedLoanRef.current) dispatchUpdatePrimaryLoan(selectedLoanRef.current);
    dispatchSetSelectedLoan({ loanNumber: '' });
  }, [
    dispatchResetErrorState,
    dispatchSetSelectedLoan,
    dispatchUpdatePrimaryLoan,
  ]);

  const isLoading = requestStatusFetchAll === 'loading' || requestStatusFetchAll === 'idle';
  const propsToPassDown = {
    bannerError,
    dispatchChangePrimaryLoan,
    dispatchSetSelectedLoan,
    isLoading,
    isSslAuth,
    isSso,
    loanNumbers,
    loansIsPrimaryStatus,
    mailingAddressProps: isLoading ? {} : mailingAddressProps,
    navigation,
    propertyAddresses,
    selectedLoan,
    success,
  };

  return (
    <Fragment>
      <SecondaryHeader
        handleBack={() => navigation.goBack()}
        title={formatMessage({ id: INTL_IDS.REGISTEREDLOANS_HEADER_TITLE })}
      />
      <StyledScrollView>
        <AsyncRender
          Component={Loans}
          error={serviceError}
          errorComponentProps={{
            body: `Please try again later. For assistance, contact ${customerServicePhoneNumber}.`,
            link: {
              label: 'Return to Settings',
              path: 'Settings',
            },
          }}
          loading={isLoading}
          LoadingComponent={Loans}
          loadingComponentProps={propsToPassDown}
          propsToPassDown={propsToPassDown}
        />
      </StyledScrollView>
      <SelectButtonWrapper>
        <ConditionalRender
          Component={StyledInternalLink}
          propsToPassDown={{
            Icon: AddCircleIcon,
            isRightIcon: false,
            label: formatMessage({ id: INTL_IDS.REGISTEREDLOANS_ADDLOAN_BUTTON_LABEL }),
            onPressFunc: () => navigation.navigate('AddLoan'),
            size: 'medium',
          }}
          shouldRender={!(isSslAuth || isSso)}
        />
      </SelectButtonWrapper>
    </Fragment>
  );
};

LoansScreen.propTypes = {
  bannerError: T.oneOfType([T.bool, T.string]).isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchChangePrimaryLoan: T.func.isRequired,
  dispatchResetErrorState: T.func.isRequired,
  dispatchSetSelectedLoan: T.func.isRequired,
  dispatchUpdatePrimaryLoan: T.func.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  loanNumbers: T.object.isRequired,
  loansIsPrimaryStatus: T.object.isRequired,
  mailingAddressProps: T.object.isRequired,
  navigation: T.object.isRequired,
  propertyAddresses: T.array.isRequired,
  requestStatusFetchAll: T.string.isRequired,
  selectedLoan: T.string.isRequired,
  serviceError: T.oneOfType([T.bool, T.string]).isRequired,
  success: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Loans
   */
  bannerError: selectBannerError(),
  mailingAddressProps: selectEditableMailingAddress(),
  requestStatusFetchAll: selectRequestStatus('fetchMailingAddressAll'),
  selectedLoan: makeSelectLoans('selectedLoan'),
  serviceError: selectServiceError('mailingAddress'),
  success: makeSelectLoans('success'),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isSslAuth: makeSelectMain('isSslAuth'),
  isSso: getIsSso(),
  loanNumbers: getLoanNumberDictionary(),
  loansIsPrimaryStatus: getLoansIsPrimaryStatus(),
  propertyAddresses: getLoanAddresses(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Loans
   */
  dispatchChangePrimaryLoan: (payload) => dispatch(changePrimaryLoan(payload)),
  dispatchResetErrorState: () => dispatch(resetErrorState()),
  dispatchSetSelectedLoan: (payload) => dispatch(setSelectedLoan(payload)),
  /**
   * Store: Main
   */
  dispatchUpdatePrimaryLoan: (payload) => dispatch(updatePrimaryLoan(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(LoansScreen);
