import React from 'react';
import T from 'prop-types';

import { ConditionalRender, P3 } from '../../../base_ui';
import iconDictionary from '../../../../utils/iconDictionary';
import PaymentStatus from './PaymentStatus';
import {
  CardContainer,
  HeaderContainer,
  HeaderText,
  HeaderWrapper,
  ItemLabel,
  ItemWrapper,
} from './styledComponents';

const cardIcon = iconDictionary('pmiIcon');

const PMICard = ({
  amount,
  companyName,
  date,
  paymentDate,
  paymentIsDue,
}) => (
  <CardContainer>
    <HeaderContainer>
      <HeaderWrapper>
        {cardIcon}<HeaderText>PMI</HeaderText>
      </HeaderWrapper>
      <ConditionalRender
        Component={(
          <PaymentStatus
            paymentDate={paymentDate}
            paymentIsDue={paymentIsDue}
          />
        )}
        shouldRender={!!paymentDate && paymentIsDue !== undefined}
      />
    </HeaderContainer>
    <ItemWrapper>
      <ItemLabel>Company</ItemLabel>
      <P3>{companyName}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Premium Amount</ItemLabel>
      <P3>{amount}</P3>
    </ItemWrapper>
    <ItemWrapper>
      <ItemLabel>Premium Due Date</ItemLabel>
      <P3>{date}</P3>
    </ItemWrapper>
  </CardContainer>
);

PMICard.propTypes = {
  amount: T.string.isRequired,
  companyName: T.string.isRequired,
  date: T.string.isRequired,
  paymentDate: T.string,
  paymentIsDue: T.bool,
};

export default PMICard;
