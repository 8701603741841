import React from 'react';
import T from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { INSURANCE_TYPE_DICT } from '@dmi/shared/redux/Mortgage/constants';
import makeSelectMortgage from '@dmi/shared/redux/Mortgage/selectors';

import { SecondaryHeader } from '../../../components/ScreenHeaders';
import { InsuranceDetailsComponent } from '../../../components/Mortgage/InsuranceUpload';
import { StyledView } from '../styledComponents';

const InsuranceDetailsScreen = ({
  insurance,
  navigation,
}) => (
  <StyledView>
    <SecondaryHeader
      handleBack={navigation.goBack}
      title={INSURANCE_TYPE_DICT[insurance.insuranceType]}
    />
    <InsuranceDetailsComponent insurance={insurance} navigation={navigation} />
  </StyledView>
);

InsuranceDetailsScreen.propTypes = {
  insurance: T.object.isRequired,
  navigation: T.object.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Mortgage
   */
  insurance: makeSelectMortgage('selectedInsurance'),
});

export default connect(mapStateToProps)(InsuranceDetailsScreen);
