import React from 'react';
import Svg, {
  Ellipse,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';

const RedirectDog = (props) => (
  <Svg
    data-name="Layer 1"
    id="Layer_1"
    viewBox="0 0 287 120"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Polyline
      fill="#eff3f6"
      points="39.34 83.2 39.34 36.78 87.52 36.78 87.52 83.2"
    />
    <Rect fill="#e6ebef" height={19.06} width={9.87} x={47.41} y={53.52} />
    <Rect fill="#e6ebef" height={30.15} width={11.51} x={65.34} y={53.09} />
    <Polygon
      fill="#eff3f6"
      points="80.82 45.39 31.66 45.39 55.11 20.02 104.26 20.02 80.82 45.39"
    />
    <Polyline
      fill="none"
      points="75.62 20.02 104.26 20.02 80.82 45.39 31.66 45.39 55.11 20.02 70.53 20.02"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Polyline
      fill="none"
      points="91.15 83.64 119.89 83.64 119.89 38.04"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Polyline fill="none" points="39.16 46.25 39.16 83.64 39.06 83.64" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.66} />
    <Polyline
      fill="none"
      points="76.9 83.22 76.9 53.27 65.32 53.27 65.32 83.02"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.57}
    />
    <Rect
      fill="none"
      height={19.32}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.57}
      width={10.24}
      x={47.1}
      y={53.39}
    />
    <Polyline
      fill="none"
      points="33.18 44.07 80 44.07 101.22 21.11"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Polyline
      fill="none"
      points="48.14 54.05 48.14 71.83 57.34 71.83"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={48.86}
      x2={57.13}
      y1={62.36}
      y2={62.36}
    />
    <Polyline
      fill="none"
      points="104.28 20.02 126.5 45.39 122.03 45.39"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={87.77}
      x2={87.77}
      y1={40.54}
      y2={71.5}
    />
    <Ellipse
      cx={104.86}
      cy={39.52}
      fill="none"
      rx={2.92}
      ry={4.22}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={0.57}
    />
    <Ellipse
      cx={104.86}
      cy={39.52}
      fill="none"
      rx={2.92}
      ry={4.22}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Path
      d="M79.08,88.27s1.4.81,2,1.14A27.77,27.77,0,0,0,86,91.26c2,.5,3.62.86,4.18,1.67s.36,1.29.36,1.29l-5,2.12L79.5,94.72l-1.6-3.8Z"
      fill="#e6ebef"
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={85.15}
      x2={85.15}
      y1={78.34}
      y2={85.07}
    />
    <Path
      d="M53.17,87.23A9.31,9.31,0,0,1,51,86.85a7.63,7.63,0,0,1-4.93-4.72h0s-1.61,5.2,5,8.61"
      fill="#fff"
    />
    <Path
      d="M50.57,103a16.2,16.2,0,0,0-.92,3.27,1.36,1.36,0,0,0,1.44,1.28h.09a2.4,2.4,0,0,0,2.23-1.55c.62-1.16,1-2.77,1.43-3.18s2.78-1.11,4.7-2.65a9.22,9.22,0,0,0,2.65-3.26,4.76,4.76,0,0,0-2.72-.67c-1.12.26-2.44.74-2.84,2s-3.17,3.58-5.59,4.38Z"
      fill="#e6ebef"
    />
    <Path
      d="M68.26,83.57a53.31,53.31,0,0,1-5.44.86c-3.85.48-8.17,1.27-10.54,4.12s-3,9.07-3,9.42-1.83,1.74-2.47,2.31-1.82,3.33-2.22,4.29-.52,2.11.74,2.64a1.93,1.93,0,0,0,2.09-.4s1.36-1.93,2-3a6,6,0,0,1,2.32-1.7c3-.74,4.45-3.27,5.14-4.63s3-1.62,3-1.62a31.67,31.67,0,0,0,4.87,1.35,13.77,13.77,0,0,0,8-1l.46-.21,2.93,3.25,5-2.16a69.68,69.68,0,0,1-3.22-6.25h0L79.69,87,76.4,77,71,79.73Z"
      fill="#fff"
    />
    <Path
      d="M75.74,78.49s-1.19,4.7-3.41,5.41-4.15.13-4.59-.85c-.8-1.71.74-3,1.69-4,2.48-2.85,2.51-3.56,3.87-4.62a6.63,6.63,0,0,1,3.27-1.14,10.56,10.56,0,0,1,4.33.36c2.42.71,3.09,1.81,4.78,1.29s4.12-.67,4.72.57a6.35,6.35,0,0,1-2,7.51c-3.27,2.91-7,2.65-7.8,3.06a3.06,3.06,0,0,0-1.19,1.4"
      fill="#fff"
    />
    <Path
      d="M75.74,78.49s-1.19,4.7-3.41,5.41-4.15.13-4.59-.85c-.8-1.71.74-3,1.69-4,2.48-2.85,2.51-3.56,3.87-4.62a6.63,6.63,0,0,1,3.27-1.14,10.56,10.56,0,0,1,4.33.36c2.42.71,3.09,1.81,4.78,1.29s4.12-.67,4.72.57a6.35,6.35,0,0,1-2,7.51c-3.27,2.91-7,2.65-7.8,3.06a3.06,3.06,0,0,0-1.19,1.4"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Path
      d="M59.9,95.93s-2.23.25-3,1.62-2.23,3.88-5.15,4.63a6.1,6.1,0,0,0-2.31,1.71c-.68,1-2,3-2,3a1.91,1.91,0,0,1-2.08.39c-1.22-.53-1.14-1.68-.75-2.64a21.87,21.87,0,0,1,2.23-4.29c.62-.58,2.48-2,2.47-2.31s.61-6.56,3-9.42S58.94,85,62.8,84.48s5.44-.86,5.44-.86"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Path
      d="M50.39,103.4a13,13,0,0,0-.74,2.82,1.36,1.36,0,0,0,1.44,1.28h.09a2.4,2.4,0,0,0,2.23-1.55c.62-1.16,1-2.77,1.43-3.18s2.78-1.11,4.7-2.65,2.23-2.4,2.23-2.4"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Path d="M60.46,96s6.91,3.21,12.66.14" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.66} />
    <Path d="M77.67,90.94a10.11,10.11,0,0,0,1.73-3.53" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.66} />
    <Path
      d="M79.21,88.5s4.8,2.74,8.17,3.21c0,0,2.65.31,3.1,1.59"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.66} x1={81.42} x2={84.53} y1={95.31} y2={96.09} />
    <Path d="M72.24,94.82a27.29,27.29,0,0,0,4.05,4.51" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.66} />
    <Path d="M77.41,89.83a25.87,25.87,0,0,0,3.83,7.43" fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={0.66} />
    <Path d="M87.79,75.72a.66.66,0,0,0-.23.78,3.17,3.17,0,0,0,1.64,1.86c1.34.5,2.09-.82,1.54-2.82A2.21,2.21,0,0,0,87.79,75.72Z" />
    <Ellipse
      cx={80.08}
      cy={76.05}
      rx={0.45}
      ry={0.64}
      transform="translate(-15.63 20.95) rotate(-13.59)"
    />
    <Path
      d="M74.09,82.69a8.05,8.05,0,0,0,5.55,3.91"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M73.76,84.2A7.82,7.82,0,0,0,79,87.57"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M53.17,87.23A9.31,9.31,0,0,1,51,86.85a7.63,7.63,0,0,1-4.93-4.72h0s-1.61,5.2,5,8.61"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Path
      d="M85.31,81.35a2.74,2.74,0,0,0,3.38,1.2"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Path
      d="M89.61,78.41a6.37,6.37,0,0,1-2.31,4.22"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Polygon
      fill="#dce2e7"

      points="103.67 88.36 57.96 107.91 89.5 107.91 114.29 97.53 103.67 88.36"
    />
    <Path
      d="M231.54,49.79c-.06-13.83-.11-10.4-.08-16.2A2.43,2.43,0,0,0,229,31.14H104.6a2.43,2.43,0,0,0-2.43,2.41V97.42H120"
      fill="#fff"
    />
    <Path
      d="M272.07,89.08v-12a2,2,0,0,0-1.23-1.84L256,69.09a3.64,3.64,0,0,1-1.63-1.35L245,53.36a4.82,4.82,0,0,0-4-2.23H201l-.15,46.64,15.6-.39,22.28-8.17,25.5-.21h7.86"

      fill="#dce2e7"
    />
    <Path
      d="M272,84.79H243.91a1.23,1.23,0,0,1-1.23-1.22V81.34a1.23,1.23,0,0,1,1.23-1.23h28.17"
      fill="#fff"
    />
    <Polygon
      fill="#8a97a4"
      points="266.44 84.71 254.4 84.71 252.75 80.23 268.36 80.23 266.44 84.71"
    />
    <Polygon
      fill="#fff"
      points="245.88 54.19 220.72 54.19 230.41 68.89 255.34 68.79 245.88 54.19"
    />
    <Path
      d="M241.86,54.28,228.39,65.61a22.84,22.84,0,0,0,1.82,2.74c.42.33,4.65.41,4.65.41l12.76-11.27-1.79-3.15Z"
      fill="#e6ebef"
    />
    <Path
      d="M219.62,90.52s1.21-4.45,2.57-5.63a5.36,5.36,0,0,1,4.35-1.49c2,0,8.17.08,8.17.08s2.62.55,3.44,1.58a24.71,24.71,0,0,1,2.08,3.9s-1.49.08-1.12.45,2.15,8.13,2.78,8.17l-24.75-.24Z"
      fill="#8a97a4"
    />
    <Path
      d="M125.14,90.52s1.2-4.45,2.57-5.63a5.35,5.35,0,0,1,4.35-1.49c2,0,8.17.08,8.17.08s2.61.55,3.44,1.58a24.65,24.65,0,0,1,2.07,3.9s-1.45.08-1.12.45,2.16,8.13,2.78,8.17l-24.74-.24Z"
      fill="#8a97a4"
    />
    <Path
      d="M139.37,86.63a8.94,8.94,0,0,1,6.42,2.81c.75,2.47,2.23,6.89,3,7.09h0v.67c0,5.85-4.25,10.58-9.5,10.58s-9.5-4.73-9.5-10.58S134.12,86.63,139.37,86.63Z"
      fill="#8a97a4"
    />
    <Ellipse
      cx={135.77}
      cy={97.2}
      fill="#dce2e7"
      rx={9.5}
      ry={10.58}
    />
    <Ellipse cx={135.77} cy={97.2} fill="#fff" rx={4.57} ry={5.09} />
    <Path
      d="M232.37,86.67a9.68,9.68,0,0,1,6.73,2.86c.75,2.47,1.93,6.85,2.71,7h0c0,.22,0,.44,0,.67,0,5.83-4.25,10.57-9.5,10.57s-9.5-4.74-9.5-10.57S227.12,86.67,232.37,86.67Z"
      fill="#8a97a4"
    />
    <Ellipse
      cx={228.59}
      cy={97.2}
      fill="#dce2e7"
      rx={9.5}
      ry={10.58}
    />
    <Ellipse cx={228.59} cy={97.2} fill="#fff" rx={4.57} ry={5.09} />
    <Path
      d="M204.72,54.34h11.43a1.62,1.62,0,0,1,1.36.74l6.27,9.61a2.57,2.57,0,0,1,.42,1.39v3.11a1,1,0,0,1-1,1H204.72Z"
      fill="#fff"
    />
    <Path
      d="M180.09,97.24a18.87,18.87,0,0,1-1,4.93,9.3,9.3,0,0,1-3.85,4.46,21.9,21.9,0,0,1-3.19,1.28h-6.59s-5-1.48-6.69-4.69S157.2,99,157.2,99H147.14l.38-2.23.49.12Z"
      fill="#8a97a4"
    />
    <Path
      d="M268.28,97.24a19.28,19.28,0,0,1-1,4.93,9.26,9.26,0,0,1-3.85,4.46,21.57,21.57,0,0,1-3.2,1.28h-6.58s-5-1.48-6.69-4.69S245.39,99,245.39,99h-5.83l.37-2.23,1.28.12Z"
      fill="#8a97a4"
    />
    <Polyline
      fill="none"
      points="101.66 89.29 57.44 108.08 61.73 108.08"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Polyline
      fill="none"
      points="61.21 108.08 89.56 108.08 114.14 97.63"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.66}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={101.7}
      x2={64}
      y1={90.88}
      y2={106.9}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={9.29}
      x2={1.35}
      y1={108.08}
      y2={108.08}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={53.4}
      x2={13.68}
      y1={108.08}
      y2={108.08}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={270.47}
      x2={96.01}
      y1={108.08}
      y2={108.08}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={284.81}
      x2={275.44}
      y1={108.08}
      y2={108.08}
    />
    <Ellipse
      cx={228.66}
      cy={97.34}
      fill="none"
      rx={9.5}
      ry={10.58}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M242,97.34c0,5.85-4.25,10.58-9.5,10.58h-3.75"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M229.15,86.77h3.34a8.87,8.87,0,0,1,6.33,2.69"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Ellipse
      cx={228.66}
      cy={97.34}
      fill="none"
      rx={4.57}
      ry={5.09}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M157.19,98.9c.67,5.11,4.62,9,9.4,9,5.2,0,9.41-4.64,9.5-10.39"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M179.85,97.59c0,5.84-4.21,10.33-9.45,10.33h-3.72"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M162.27,99.08a4.64,4.64,0,0,0,4.3,3.35,4.8,4.8,0,0,0,4.56-4.79"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Ellipse
      cx={135.74}
      cy={97.34}
      fill="none"
      rx={9.5}
      ry={10.58}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M149.07,97.34c0,5.85-4.26,10.58-9.5,10.58h-3.71"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M136.23,86.77h3.34a8.87,8.87,0,0,1,6.33,2.69"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Ellipse
      cx={135.74}
      cy={97.34}
      fill="none"
      rx={4.57}
      ry={5.09}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M102,97.61h18.75A2.46,2.46,0,0,0,123,96l3.36-9.18a5.14,5.14,0,0,1,4.82-3.37h8.43a5.76,5.76,0,0,1,5.48,4L147.58,95a3.1,3.1,0,0,0,2.92,2.12h49.63"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.88}
    />
    <Path
      d="M215.93,51.17a4.84,4.84,0,0,1,4,2.23l9.41,14.31A3.59,3.59,0,0,0,231,69.06l14.8,6.14A2,2,0,0,1,247.06,77v12"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M240.25,88.5l-.5-1.1a7.23,7.23,0,0,0-6.58-4.24H227a7.09,7.09,0,0,0-6.75,5l-2.37,7.53a2.77,2.77,0,0,1-2.63,1.94H200.49V51.17h18.68"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.88}
    />
    <Path
      d="M238.89,89.13l2.77,8.29h6.88a1.24,1.24,0,0,0,1.24-1.24h0V90.29a1.07,1.07,0,0,0-1.06-1.06Z"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M219.38,51.17h21.78a4.85,4.85,0,0,1,4.05,2.23l9.4,14.31a3.7,3.7,0,0,0,1.63,1.35l14.81,6.14A2,2,0,0,1,272.29,77v12"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.88}
    />
    <Path
      d="M241.79,97.42h32A1.24,1.24,0,0,0,275,96.18h0V90.29A1.07,1.07,0,0,0,274,89.23h-1.73"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.88}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={126.01}
      x2={121.24}
      y1={97.51}
      y2={97.51}
    />
    <Path
      d="M231.54,49.79c-.06-13.83-.11-10.4-.08-16.2A2.43,2.43,0,0,0,229,31.14H104.6a2.43,2.43,0,0,0-2.43,2.41V97.42"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.88}
    />
    <Polyline
      fill="none"
      points="198.55 37.48 198.55 97.05 200.43 97.05"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={198.55}
      x2={198.55}
      y1={32.75}
      y2={35.69}
    />
    <Polyline
      fill="none"
      points="167.09 97.76 166.37 98.93 149 98.93"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M245.36,98.9c.67,5.11,4.62,9,9.4,9,5.2,0,9.42-4.64,9.5-10.39"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M268.05,97.59c0,5.84-4.21,10.33-9.46,10.33h-3.71"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M250.47,99.08a4.62,4.62,0,0,0,4.29,3.35,4.78,4.78,0,0,0,4.56-4.79"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Polyline
      fill="none"
      points="255.29 97.76 254.56 98.93 241.96 98.93"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M204.58,54.34h11.57a1.62,1.62,0,0,1,1.35.74l6.27,9.61a2.49,2.49,0,0,1,.42,1.39v3.11a1,1,0,0,1-1,1H204.58Z"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Path
      d="M272,84.79H243.91a1.23,1.23,0,0,1-1.23-1.22V81.34a1.23,1.23,0,0,1,1.23-1.23h28.17"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Rect
      fill="#dce2e7"

      height={6.97}
      rx={0.71}
      width={3.22}
      x={218.2}
      y={61.16}
    />
    <Rect
      fill="none"
      height={6.97}
      rx={0.71}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      width={3.22}
      x={218.2}
      y={61.16}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={220.11}
      x2={220.11}
      y1={68.21}
      y2={70.04}
    />
    <Path
      d="M227.31,68.15V79.23A1.36,1.36,0,0,1,226,80.59h0a6.94,6.94,0,0,0-6.56,4.68L217,92.17H201.69"
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
    />
    <Rect
      fill="none"
      height={1.29}
      rx={0.54}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      width={4.05}
      x={207.18}
      y={76.41}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={197.03}
      x2={149.13}
      y1={88.81}
      y2={88.81}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={124.18}
      x2={102.31}
      y1={88.81}
      y2={88.81}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={220.48}
      x2={245.99}
      y1={54.18}
      y2={54.18}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={230.79}
      x2={256.3}
      y1={68.92}
      y2={68.92}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={272.11}
      x2={251.83}
      y1={89.17}
      y2={89.17}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={252.49}
      x2={254.35}
      y1={80.08}
      y2={84.76}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.48}
      x1={268.28}
      x2={266.41}
      y1={80.08}
      y2={84.76}
    />
    <Path
      d="M54.34,12.22a2,2,0,0,0,2-2,2.13,2.13,0,0,0-.15-.75c-1.14-2.82-3.55-6.84-8.12-7.31A7.84,7.84,0,0,0,42.94,3.6a.32.32,0,0,1-.4,0A5.79,5.79,0,0,0,38.6,2.08c-2.93,0-5.32,2-5.32,4.45h0a.32.32,0,0,1-.32.33h0a3.08,3.08,0,0,0-.43,0,4.12,4.12,0,0,0-3.87,2.4.31.31,0,0,1-.41.18h0a3.68,3.68,0,0,0-1.6-.34,3.39,3.39,0,0,0-2.71,1.49A1,1,0,0,0,24.13,12a1.08,1.08,0,0,0,.57.19Z"
      fill="#d9e1e8"
    />
    <Path
      d="M167.16,21.31a1,1,0,0,0,1-.92.9.9,0,0,0-.23-.64A6.27,6.27,0,0,0,163.2,18a7.89,7.89,0,0,0-1,.06.57.57,0,0,1-.64-.51.22.22,0,0,1,0-.08h0c0-1.78-2.23-3.23-5-3.23a7.48,7.48,0,0,0-2.16.32.56.56,0,0,1-.72-.32l0-.09c-.58-2.64-3.42-4.66-6.84-4.66-3.84,0-7,2.54-7,5.67v.34a2.63,2.63,0,0,0-.49,0,3.23,3.23,0,0,0-3.07,2.23.56.56,0,0,1-.69.38,2.87,2.87,0,0,0-.87-.13,3.22,3.22,0,0,0-2.53,1.22,1.33,1.33,0,0,0,1,2.15Z"
      fill="#d9e1e8"
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={103.26}
      x2={111.84}
      y1={21.08}
      y2={31.01}
    />
    <Rect fill="#bac2ca" height={16.51} width={6.85} x={22.73} y={73.79} />
    <Rect fill="#bac2ca" height={8.72} width={3.52} x={28.34} y={71.5} />
    <Polygon
      fill="#bac2ca"
      points="29.56 72.07 29.53 73.7 24.72 73.7 24.72 60.52 31.88 60.52 31.88 72.07 29.56 72.07"
    />
    <Polyline
      fill="#e6ebef"
      points="11.56 73.97 11.56 76.55 15.99 76.55 15.99 73.89"
    />
    <Polyline
      fill="none"
      points="31.87 73.8 5.74 73.8 5.74 90.36 24.56 90.36"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={22.71}
      x2={22.71}
      y1={74.42}
      y2={90.21}
    />
    <Polyline
      fill="none"
      points="14.29 73.77 14.29 60.52 31.86 60.52 31.86 80.24"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={24.69}
      x2={24.69}
      y1={61.35}
      y2={70.98}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={24.71}
      x2={24.71}
      y1={72.15}
      y2={73.64}
    />
    <Rect fill="#fff" height={10.09} width={16.53} x={25.63} y={80.28} />
    <Rect fill="#e6ebef" height={10.04} width={6.78} x={25.63} y={80.32} />
    <Rect
      fill="none"
      height={10.09}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      width={16.53}
      x={25.63}
      y={80.28}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.31}
      x1={32.41}
      x2={32.41}
      y1={85.31}
      y2={90.36}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.31}
      x1={32.41}
      x2={32.41}
      y1={80.79}
      y2={84.58}
    />
    <Rect
      fill="#8a97a4"
      height={0.6}
      rx={0.25}
      width={4.07}
      x={35.33}
      y={81.64}
    />
    <Rect fill="#fff" height={2.2} width={18.98} x={13.57} y={59.47} />
    <Rect fill="#bac2ca" height={2.2} width={8.64} x={23.9} y={59.47} />
    <Rect
      fill="none"
      height={2.2}
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      width={18.98}
      x={13.57}
      y={59.47}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={23.91}
      x2={23.91}
      y1={59.54}
      y2={61.09}
    />
    <Rect
      fill="#8a97a4"
      height={0.74}
      rx={0.31}
      width={5}
      x={16.94}
      y={63.09}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={42.21}
      x2={66.02}
      y1={83.13}
      y2={83.13}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={3.55}
      x2={5.74}
      y1={90.32}
      y2={90.32}
    />
    <Line
      fill="none"
      stroke="#000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.44}
      x1={42.28}
      x2={44.48}
      y1={90.32}
      y2={90.32}
    />
  </Svg>
);
export default RedirectDog;
