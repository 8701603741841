import React, { Fragment } from 'react';
import { Linking, View } from 'react-native';
import T from 'prop-types';

import {
  MANCODE_Y_MULTILOAN_TEXT,
  MANCODE_Y_TEXT,
} from '@dmi/shared/redux/Settings/Loans/constants';

import iconDictionary from '../../../utils/iconDictionary';
import {
  ConditionalRender,
  P3,
  TertiaryDivider,
} from '../../base_ui';
import CommunicationsCard from './CommunicationsCard';
import LoansAddressesCard from './LoansAddressesCard';
import ProfileCard from './ProfileCard';
import SecurityCard from './SecurityCard';
import {
  BoldText,
  IllustrationWrapper,
  PhoneNumberWrapper,
  StyledFormattedMessageStatusBanner,
} from './styledComponents';
import DeleteAccountBanner from './DeleteAccountBanner';

const Illustration = iconDictionary('settingsTownscape', { width: '100%' });

const SettingsHome = ({
  clientMobileFeatures: { showEditPassword, showSecurityCard },
  customerServicePhoneNumber,
  fetchMailingAddressAllRequestStatus,
  isLoanMancodeY,
  isSslAuth,
  isSso,
  loanAddresses,
  navigation,
  paperlessButtonDetails,
  pendingAddressesCount,
  profileProps,
  signInMethodButtonDetails,
}) => (
  <Fragment>
    <View>
      <ConditionalRender
        Component={StyledFormattedMessageStatusBanner}
        propsToPassDown={{
          defaultMessage: loanAddresses.length > 1
            ? MANCODE_Y_MULTILOAN_TEXT
            : MANCODE_Y_TEXT,
          error: true,
          values: {
            // eslint-disable-next-line react/prop-types
            a: ({ msg }) => (
              <PhoneNumberWrapper
                // eslint-disable-next-line react/prop-types
                onPress={() => Linking.openURL(`tel://${msg.replace(/\+|-/g, '')}`)}
              >
                {msg}
              </PhoneNumberWrapper>
            ),
            // eslint-disable-next-line react/prop-types
            b: ({ msg }) => <BoldText>{msg}</BoldText>,
            mobileNewline: '\n',
          },
          WrapperComponent: P3,
        }}
        shouldRender={isLoanMancodeY}
      />
      <ProfileCard
        isSslAuth={isSslAuth}
        isSso={isSso}
        navigation={navigation}
        {...profileProps}
      />
      <TertiaryDivider />
      <LoansAddressesCard
        isLoanMancodeY={isLoanMancodeY}
        isSslAuth={isSslAuth}
        isSso={isSso}
        navigation={navigation}
        pendingAddressesCount={pendingAddressesCount}
        registeredLoansCount={loanAddresses.length}
        requestStatus={fetchMailingAddressAllRequestStatus}
      />
      <TertiaryDivider />
      <ConditionalRender
        Component={(
          <Fragment>
            <SecurityCard
              navigation={navigation}
              showEditPassword={showEditPassword}
              signInMethodButtonDetails={signInMethodButtonDetails}
            />
            <TertiaryDivider />
          </Fragment>
        )}
        shouldRender={showSecurityCard}
      />
      <CommunicationsCard
        navigation={navigation}
        paperlessButtonDetails={paperlessButtonDetails}
      />
      <TertiaryDivider />
    </View>
    <DeleteAccountBanner customerServicePhoneNumber={customerServicePhoneNumber} />
    <IllustrationWrapper>
      {Illustration}
    </IllustrationWrapper>
  </Fragment>
);

SettingsHome.propTypes = {
  clientMobileFeatures: T.shape({
    showEditPassword: T.bool.isRequired,
    showSecurityCard: T.bool.isRequired,
  }).isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  fetchMailingAddressAllRequestStatus: T.string.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  loanAddresses: T.array.isRequired,
  navigation: T.object.isRequired,
  paperlessButtonDetails: T.string.isRequired,
  pendingAddressesCount: T.number.isRequired,
  profileProps: T.object.isRequired,
  signInMethodButtonDetails: T.string.isRequired,
  ssoType: T.string.isRequired,
};

export default SettingsHome;
