/* eslint-disable max-len, no-tabs */
import React from 'react';
import Svg, {
  Circle,
  Ellipse,
  G,
  Line,
  Path,
  Polygon,
  Polyline,
  Rect,
} from 'react-native-svg';
import { useTheme } from 'styled-components/native';

import { moderateScale } from '../../../scaleHelpers';

export default function LoansSoldHome(props) {
  const { colors: { svgHighlight } } = useTheme();

  return (
    <Svg
      enableBackground="new 0 0 251 315"
      fill="#000000"
      height={moderateScale(210)}
      id="Layer_1"
      version="1.1"
      viewBox="0 0 251 315"
      width={moderateScale(165)}
      x="0px"
      xmlSpace="preserve"
      y="0px"
      {...props}
    >
      <Path className="st0" d="M-1264.3-181.8h15v-2l-15.6-0.3l-3.9-4l0.1,3.4l2.4,2.3C-1265.7-182.1-1265-181.8-1264.3-181.8z" display="none" fill="#BAC2CA" />
      <G>
        <Polyline className="st1" fill="#BAC2CA" points="36.2,23 38.7,23 43.3,27.6 43.3,297 41.5,297 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Polyline className="st2" fill="#FFFFFF" points="36,23.1 36.1,23 40.6,27.6 40.6,297 	" />
        <Polyline className="st1" fill="#BAC2CA" points="137,58.5 140.5,58.5 147.7,48.4 144.1,48.4 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Path className="st2" d="M148,226.6H72.7c-0.8,0-1.5-0.7-1.5-1.5l0,0c0-0.8,0.7-1.5,1.5-1.5H148V226.6z" fill="#FFFFFF" />
        <Rect className="st2" fill="#FFFFFF" height="45.8" width="69.7" x="78.6" y="227.8" />
        <Rect className="st3" fill="#BAC2CA" height="2.7" width="69.6" x="78.8" y="226.8" />
        <Rect className="st3" fill="#BAC2CA" height="50.2" width="4.7" x="148.9" y="223.4" />
        <Polygon className="st3" fill="#BAC2CA" points="72,222.8 99.9,195.8 175.6,195.8 147.2,223.4 128.5,223.4 	" />
        <G>
          <Path
            className="st4"
            d="M166.5,205l8.9-8.7c0.4-0.4,0.9-0.6,1.4-0.5c0.5,0,1,0.2,1.4,0.6l25.5,26.6c0.7,0.7,0.6,1.8-0.2,2.4
			c-0.8,0.6-2,0.5-2.6-0.2l-24.1-25.2l-26.4,25.9c-0.4,0.4-0.9,0.5-1.4,0.5c-0.4,0-0.9-0.1-1.3-0.4c-0.8-0.6-0.8-1.7-0.1-2.4
			l16.2-15.9"
            fill="#DCE2E7"
          />
          <Path
            className="st5"
            d="M166.7,204.6l8.7-8.4c0.4-0.4,0.9-0.6,1.4-0.5c0.5,0,1,0.2,1.4,0.6l25.5,26.6c0.7,0.7,0.6,1.8-0.2,2.4
			c-0.8,0.6-2,0.5-2.6-0.2l-24.1-25.2l-26.4,25.9c-0.4,0.4-0.9,0.5-1.4,0.5c-0.4,0-0.9-0.1-1.3-0.4c-0.8-0.6-0.8-1.7-0.1-2.4
			l15.5-15.2"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75px"
          />
        </G>
        <Polyline className="st6" fill={svgHighlight} points="87,273.6 87,235.8 140,235.8 140,273.6" />
        <Polyline className="st5" fill="none" points="87,273.6 87,235.8 140,235.8 140,273.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Polyline className="st5" fill="none" points="153.7,195.8 118.7,195.8 111.9,195.8 99.9,195.8 71.6,223.4 124.9,223.4" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="149" x2="72.2" y1="226.6" y2="226.6" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="78.6" x2="78.6" y1="227" y2="273.6" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="148.7" x2="148.7" y1="226.5" y2="273.6" />
        <Path className="st5" d="M75,223.3h-2.4c-1,0-1.8,0.7-1.8,1.6l0,0c0,0.9,0.8,1.6,1.8,1.6H75" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="128" x2="147.4" y1="223.4" y2="223.4" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="110.8" x2="139.6" y1="243.6" y2="243.6" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="107.2" x2="108.9" y1="243.6" y2="243.6" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="88.9" x2="104.3" y1="243.6" y2="243.6" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="123.8" x2="139.6" y1="250.8" y2="250.8" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="88.9" x2="121.9" y1="250.8" y2="250.8" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="99.5" x2="139.6" y1="258.1" y2="258.1" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="88.9" x2="97.2" y1="258.1" y2="258.1" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="88.9" x2="139.6" y1="265.3" y2="265.3" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="88.7" x2="88.7" y1="236.3" y2="273.5" />
        <G>
          <Polygon className="st3" fill="#BAC2CA" points="201,164.5 159.7,123 129.1,123 171,165.1 166,165.1" />
          <Path
            className="st2"
            d="M139.6,133.6l-9.7-9.8c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.7l-40.7,43.5c-0.7,0.8-0.6,2,0.2,2.6
			c0.9,0.7,2.1,0.6,2.9-0.2l39.2-41.9l39.7,40.2c0.4,0.4,1,0.6,1.5,0.6c0.5,0,1-0.2,1.4-0.5c0.8-0.7,0.9-1.9,0.1-2.6l-28.7-29"
            fill="#FFFFFF"
          />
          <Polygon className="st2" fill="#FFFFFF" points="128.2,128 160.8,160.8 163.3,200.6 94.7,200.6 94.7,170.6 89,170.6 		" />
          <Polygon className="st3" fill="#BAC2CA" points="94.5,164.6 94.5,170.6 89,170.7 		" />
          <Line className="st8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8661px" x1="88.8" x2="94.5" y1="170.5" y2="170.5" />
          <Polygon className="st3" fill="#BAC2CA" points="130.2,130.1 95,167.4 95,163.8 128.3,128.3 		" />
          <Polyline className="st5" fill="none" points="146.6,123 159.7,123 201.5,165.1 171.1,165.1 		" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
          <Line className="st8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8661px" x1="141.8" x2="143.7" y1="123" y2="123" />
          <Line className="st8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8661px" x1="128.3" x2="139.2" y1="123.1" y2="123" />
          <Path
            className="st5"
            d="M139.2,133.3l-9.4-9.4c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.7l-40.7,43.5c-0.7,0.8-0.6,2,0.2,2.6
			c0.9,0.7,2.1,0.6,2.9-0.2l39.2-41.9l39.7,40.2c0.4,0.4,1,0.6,1.5,0.6c0.5,0,1-0.2,1.4-0.5c0.8-0.7,0.9-1.9,0.1-2.6l-27.8-28.2"
            fill="none"
            stroke="#000000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="0.75px"
          />
        </G>
        <Rect className="st9" fill="#E6EBEF" height="96.7" width="29.2" x="200.7" y="176.9" />
        <Path className="st3" d="M205.2,183.6H230v-2.2l-25.4-0.3l-4-3.8l-0.1,3l2.6,2.4C203.7,183.3,204.5,183.6,205.2,183.6z" fill="#BAC2CA" />
        <Path className="st9" d="M236.4,181.2h-8.1v-3.8h8.1c1,0,1.9,0.8,1.9,1.9l0,0C238.3,180.4,237.4,181.2,236.4,181.2z" fill="#E6EBEF" />
        <Polyline className="st6" fill={svgHighlight} points="166,273.6 166,235.8 186.7,235.8 186.7,273.6 	" />
        <Polyline className="st8" fill="none" points="166,273.6 166,235.8 186.7,235.8 186.7,273.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8661px" />
        <Circle className="st10" cx="175.9" cy="245.3" fill="#FFFFFF" r="0.9" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Polygon className="st2" fill="#FFFFFF" points="175.2,151.6 200.3,176.9 200.3,273.8 154.2,273.8 154.2,180.5 148.5,180.5 	" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="229.7" x2="229.7" y1="181.2" y2="273.2" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="200.4" x2="200.4" y1="176.8" y2="273.2" />
        <Polygon className="st3" fill="#BAC2CA" points="236.8,176.9 206.5,146.6 175.9,146.6 206.8,177.5 201.9,177.5 	" />
        <Path
          className="st2"
          d="M186.4,157.2l-9.7-9.8c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.7l-27.7,29.9c-0.7,0.8-0.6,2,0.2,2.6
		c0.9,0.7,2.1,0.6,2.9-0.2l26.2-28.2l28.7,29.1c0.4,0.4,1,0.6,1.5,0.6c0.5,0,1-0.2,1.4-0.5c0.8-0.7,0.9-1.9,0.1-2.6l-17.7-17.9"
          fill="#FFFFFF"
        />
        <Polygon className="st3" fill="#BAC2CA" points="177.1,153.7 154,178.4 154,174.8 175.2,151.8 	" />
        <Polygon className="st3" fill="#BAC2CA" points="154,174.6 154,180.5 148.4,180.6 	" />
        <Polyline className="st5" fill="none" points="200,146.6 206.5,146.6 237.3,177.5 207,177.5 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Polyline className="st5" fill="none" points="175.1,146.6 186,146.6 188.7,146.6 197.1,146.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="205" x2="236.7" y1="181.1" y2="181.1" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="148.2" x2="153.9" y1="180.4" y2="180.4" />
        <Path
          className="st5"
          d="M186.1,156.8l-9.4-9.4c-0.4-0.4-1-0.6-1.5-0.6c-0.6,0-1.1,0.2-1.5,0.7l-27.7,29.9c-0.7,0.8-0.6,2,0.2,2.6
		c0.9,0.7,2.1,0.6,2.9-0.2l26.2-28.2l28.7,29.1c0.4,0.4,1,0.6,1.5,0.6c0.5,0,1-0.2,1.4-0.5c0.8-0.7,0.9-1.9,0.1-2.6L190.2,161"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Line className="st8" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.8661px" x1="188.5" x2="187.6" y1="159.3" y2="158.4" />
        <Path className="st5" d="M233.6,177.5h2.6c1.1,0,2,0.8,2,1.8l0,0c0,1-0.9,1.8-2,1.8h-2.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="153.9" x2="153.9" y1="175.8" y2="273.4" />
        <Polyline className="st6" fill={svgHighlight} points="165.6,273.6 165.6,235.8 186.3,235.8 186.3,273.6 	" />
        <Polyline className="st5" fill="none" points="165.6,273.6 165.6,235.8 186.3,235.8 186.3,273.6 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="156.5" x2="229.8" y1="220.8" y2="220.8" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="211.8" x2="229.2" y1="217.7" y2="217.7" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="154.2" x2="209" y1="217.7" y2="217.7" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="100" x2="156.7" y1="165.5" y2="165.5" />
        <Line className="st7" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" x1="97.7" x2="159.3" y1="162.4" y2="162.4" />
        <Path className="st11" d="M184.8,202.1h-1.6c-0.6,0-1.1,0.5-1.1,1.1v0.3c0,0.6,0.5,1.1,1.1,1.1h1.6" fill="#E0E0E0" />
        <Path className="st9" d="M184.4,201.8H169v-16.5c0-3.9,3.1-7,7-7h1.3c3.9,0,7,3.1,7,7V201.8z" fill="#E6EBEF" />
        <Polygon className="st12" fill="#FFFFFF" opacity="0.7" points="165.2,201.8 158.2,201.8 168.4,178.2 175.5,178.2 	" />
        <Polygon className="st12" fill="#FFFFFF" opacity="0.7" points="177.4,201.8 173,201.8 183.3,178.2 187.7,178.2 	" />
        <Path className="st5" d="M184.6,202h-17.1v-16.8c0-4.7,3.8-8.4,8.4-8.4h0.2c4.7,0,8.4,3.8,8.4,8.4V202z" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Path className="st7" d="M169.1,200.4v-14.9c0-4,3.2-7.2,7.2-7.2l0.9,0c0,0,3.5-0.2,6.2,2.9" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Path className="st5" d="M172.2,204.6h12.5v-2.5h-18.3c-0.6,0-1.1,0.5-1.1,1.1v0.3c0,0.6,0.5,1.1,1.1,1.1h4" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Path className="st7" d="M184.7,202.1H183c-0.6,0-1.1,0.5-1.1,1.1v0.3c0,0.6,0.5,1.1,1.1,1.1h1.6" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="176.4" x2="176.4" y1="188.2" y2="199.8" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="176.4" x2="176.4" y1="179.7" y2="185.7" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="170.5" x2="184.6" y1="189.9" y2="189.9" />
        <Polyline className="st7" fill="none" points="176,239.7 182.3,239.7 182.3,253.4 169.5,253.4 169.5,239.7 174,239.7 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Polyline className="st7" fill="none" points="178.4,270 169.5,270 169.5,255.8 182.3,255.8 182.3,270 180.5,270 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Polyline className="st5" fill="none" points="94.9,163.8 94.9,200.7 153.8,200.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Rect className="st9" fill="#E6EBEF" height="16.8" width="10.9" x="107.6" y="174.1" />
        <Polygon className="st12" fill="#FFFFFF" opacity="0.7" points="106.6,187 112.2,174.1 107.2,174.1 106.6,175.5" />
        <Polygon className="st12" fill="#FFFFFF" opacity="0.7" points="113.6,190.9 110.5,190.9 117.8,174.1 120.9,174.1" />
        <Rect className="st5" fill="none" height="18" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" width="12.2" x="106.6" y="173.1" />
        <Polyline className="st7" fill="none" points="108.1,190.7 108.1,174.5 117.1,174.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Polyline className="st5" fill="none" points="113.6,175.8 113.6,179.9 113.6,181.6 113.6,189.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="109.8" x2="118.7" y1="182.4" y2="182.4" />
        <Rect className="st9" fill="#E6EBEF" height="16.8" width="10.9" x="130.2" y="174.1" />
        <Polygon className="st12" fill="#FFFFFF" opacity="0.7" points="129.2,187 134.8,174.1 129.7,174.1 129.2,175.5" />
        <Polygon className="st12" fill="#FFFFFF" opacity="0.7" points="136.1,190.9 133,190.9 140.3,174.1 143.4,174.1" />
        <Rect className="st5" fill="none" height="18" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" width="12.2" x="129.1" y="173.1" />
        <Polyline className="st7" fill="none" points="130.6,190.7 130.6,174.5 139.7,174.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5774px" />
        <Polyline className="st5" fill="none" points="136.2,175.8 136.2,179.9 136.2,181.6 136.2,189.5" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="132.3" x2="141.2" y1="182.4" y2="182.4" />
        <Polygon className="st9" fill="#E6EBEF" points="153.7,217.1 149.3,221.1 149.3,180.4 153.7,180.4" />
        <Polyline className="st13" fill="#FFFFFF" points="31.2,48.4 17.6,48.4 21,58.5 31.1,58.5 	" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Polyline className="st5" fill="none" points="40.6,297 31.3,297 31.3,27.6 36,23.1" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st14" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55px" x1="40.6" x2="40.6" y1="150.7" y2="297" />
        <Polyline className="st14" fill="#FFFFFF" points="36,23.1 36.1,23 40.6,27.6 40.6,148.2" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55px" />
        <Polygon className="st13" fill="#FFFFFF" points="42.2,48.4 42.2,58.5 137,58.5 144.2,48.4" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Polyline className="st13" fill="#FFFFFF" points="137.9,79.6 139.1,79.6 139.1,149.6 137.5,149.6" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Polyline className="st13" fill="#FFFFFF" points="137.5,149.5 50.9,149.5 50.9,79.7 137.5,79.7" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" />
        <Line className="st15" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="137.5" x2="137.5" y1="99.3" y2="149.5" />
        <Line className="st13" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="20.8" x2="69.6" y1="297" y2="297" />
        <Line className="st13" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="11.3" x2="17.2" y1="297" y2="297" />
        <Line className="st13" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="69.1" x2="242.2" y1="273.7" y2="273.7" />
        <Path
          className="st5"
          d="M57.7,77.7h-0.4c-0.8,0-1.4-0.6-1.4-1.4V75c0-0.8,0.6-1.4,1.4-1.4h0.4c0.8,0,1.4,0.6,1.4,1.4v1.4
		C59.1,77.1,58.5,77.7,57.7,77.7z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Path
          className="st5"
          d="M57.7,71.1h-0.4c-0.8,0-1.4-0.6-1.4-1.4v-1.4c0-0.8,0.6-1.4,1.4-1.4h0.4c0.8,0,1.4,0.6,1.4,1.4v1.4
		C59.1,70.5,58.5,71.1,57.7,71.1z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="57.5" x2="57.5" y1="70.4" y2="74.4" />
        <Path
          className="st5"
          d="M57.7,64.7h-0.4c-0.8,0-1.4-0.6-1.4-1.4v-1.4c0-0.8,0.6-1.4,1.4-1.4h0.4c0.8,0,1.4,0.6,1.4,1.4v1.4
		C59.1,64,58.5,64.7,57.7,64.7z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="57.5" x2="57.5" y1="64" y2="67.9" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="57.5" x2="57.5" y1="77" y2="80.9" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="57.5" x2="57.5" y1="61.5" y2="58.6" />
        <Path
          className="st5"
          d="M130,77.7h-0.4c-0.8,0-1.4-0.6-1.4-1.4V75c0-0.8,0.6-1.4,1.4-1.4h0.4c0.8,0,1.4,0.6,1.4,1.4v1.4
		C131.4,77.1,130.8,77.7,130,77.7z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Path
          className="st5"
          d="M130,71.1h-0.4c-0.8,0-1.4-0.6-1.4-1.4v-1.4c0-0.8,0.6-1.4,1.4-1.4h0.4c0.8,0,1.4,0.6,1.4,1.4v1.4
		C131.4,70.5,130.8,71.1,130,71.1z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="129.8" x2="129.8" y1="70.4" y2="74.4" />
        <Path
          className="st5"
          d="M130,64.7h-0.4c-0.8,0-1.4-0.6-1.4-1.4v-1.4c0-0.8,0.6-1.4,1.4-1.4h0.4c0.8,0,1.4,0.6,1.4,1.4v1.4
		C131.4,64,130.8,64.7,130,64.7z"
          fill="none"
          stroke="#000000"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="0.75px"
        />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="129.8" x2="129.8" y1="64" y2="67.9" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="129.8" x2="129.8" y1="77" y2="82.9" />
        <Line className="st5" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="129.8" x2="129.8" y1="61.5" y2="58.6" />
        <Rect className="st6" fill={svgHighlight} height="59" width="77.2" x="55.5" y="85.1" />
        <Rect className="st16" fill="none" height="59" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" width="77.2" x="55.5" y="85.1" />
        <G>
          <Path d="M69.7,91.8h12.7v3.3h-9v3.6h7.9v3.3h-7.9v6.4h-3.7V91.8z" />
          <Path
            d="M93.4,108.7c-1.3,0-2.5-0.2-3.5-0.7s-2-1.1-2.8-1.8c-0.8-0.8-1.4-1.7-1.8-2.7c-0.4-1-0.7-2.2-0.7-3.3v0
			c0-1.2,0.2-2.3,0.7-3.3s1.1-2,1.8-2.7c0.8-0.8,1.7-1.4,2.8-1.9c1.1-0.5,2.3-0.7,3.6-0.7c1.3,0,2.5,0.2,3.5,0.7
			c1.1,0.5,2,1.1,2.8,1.8c0.8,0.8,1.4,1.7,1.8,2.7c0.4,1,0.7,2.2,0.7,3.3v0c0,1.2-0.2,2.3-0.7,3.3c-0.4,1-1.1,2-1.8,2.7
			s-1.7,1.4-2.8,1.9C95.9,108.5,94.7,108.7,93.4,108.7z M93.5,105.3c0.7,0,1.4-0.1,2-0.4c0.6-0.3,1.1-0.6,1.6-1.1
			c0.4-0.5,0.8-1,1-1.7c0.2-0.6,0.4-1.3,0.4-2v0c0-0.7-0.1-1.4-0.4-2c-0.2-0.6-0.6-1.2-1-1.7s-1-0.9-1.6-1.1c-0.6-0.3-1.3-0.4-2-0.4
			c-0.7,0-1.4,0.1-2,0.4c-0.6,0.3-1.1,0.6-1.6,1.1c-0.4,0.5-0.8,1-1,1.7c-0.2,0.6-0.4,1.3-0.4,2v0c0,0.7,0.1,1.4,0.4,2
			c0.2,0.6,0.6,1.2,1,1.7c0.5,0.5,1,0.9,1.6,1.1S92.7,105.3,93.5,105.3z"
          />
          <Path
            d="M105.6,91.8h7.6c2.1,0,3.7,0.6,4.9,1.7c1,1,1.4,2.2,1.4,3.8v0c0,1.3-0.3,2.4-1,3.3c-0.7,0.8-1.5,1.5-2.6,1.9l4.1,5.9h-4.3
			l-3.6-5.3h0h-2.8v5.3h-3.7V91.8z M112.9,99.9c0.9,0,1.6-0.2,2.1-0.6c0.5-0.4,0.7-1,0.7-1.7v0c0-0.8-0.3-1.4-0.8-1.8
			s-1.2-0.6-2.1-0.6h-3.6v4.8H112.9z"
          />
        </G>
        <G>
          <Path
            d="M68.7,136.1c-1.3,0-2.6-0.2-3.9-0.7s-2.4-1.2-3.5-2.1l2.3-2.7c0.8,0.6,1.6,1.2,2.5,1.5s1.8,0.6,2.8,0.6
			c0.8,0,1.4-0.1,1.9-0.4s0.7-0.7,0.7-1.2v0c0-0.2,0-0.5-0.1-0.7s-0.3-0.4-0.5-0.5s-0.6-0.3-1.1-0.5s-1.1-0.3-1.8-0.5
			c-0.9-0.2-1.7-0.5-2.4-0.7c-0.7-0.3-1.3-0.6-1.8-1c-0.5-0.4-0.9-0.9-1.2-1.5c-0.3-0.6-0.4-1.3-0.4-2.2v0c0-0.8,0.1-1.5,0.4-2.2
			c0.3-0.6,0.7-1.2,1.2-1.7s1.2-0.8,1.9-1.1s1.6-0.4,2.5-0.4c1.3,0,2.5,0.2,3.5,0.6s2.1,0.9,3,1.6l-2,2.9c-0.8-0.5-1.5-0.9-2.3-1.3
			c-0.7-0.3-1.5-0.5-2.2-0.5c-0.7,0-1.3,0.1-1.7,0.4c-0.4,0.3-0.6,0.7-0.6,1.1v0c0,0.3,0.1,0.5,0.2,0.7c0.1,0.2,0.3,0.4,0.6,0.6
			s0.7,0.3,1.2,0.5c0.5,0.1,1.1,0.3,1.9,0.5c0.9,0.2,1.7,0.5,2.4,0.8c0.7,0.3,1.3,0.6,1.7,1c0.5,0.4,0.8,0.9,1.1,1.4s0.4,1.2,0.4,2
			v0c0,0.9-0.2,1.6-0.5,2.3s-0.8,1.2-1.3,1.7c-0.6,0.5-1.2,0.8-2,1C70.5,135.9,69.7,136.1,68.7,136.1z"
          />
          <Path d="M84.4,118.3h3.5l7.5,17.6h-4l-1.6-3.9h-7.4l-1.6,3.9h-3.9L84.4,118.3z M88.4,128.5l-2.3-5.7l-2.3,5.7H88.4z" />
          <Path d="M98.1,118.4h3.8v14h8.7v3.5H98.1V118.4z" />
          <Path d="M113.5,118.4h13.1v3.4h-9.3v3.5h8.2v3.4h-8.2v3.7h9.5v3.4h-13.2V118.4z" />
        </G>
        <G>

          <Rect className="st17" fill="#FFFFFF" height="17" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.45px" transform="matrix(0.9883 -0.1522 0.1522 0.9883 -16.4581 15.6601)" width="53.4" x="67.4" y="106.8" />
          <G>
            <Path
              d="M77.8,123.3c-0.8,0.1-1.7,0.1-2.5-0.1c-0.8-0.2-1.6-0.5-2.4-1l1.1-1.9c0.6,0.3,1.1,0.6,1.7,0.7c0.6,0.2,1.1,0.2,1.8,0.1
				c0.5-0.1,0.9-0.2,1.1-0.4c0.2-0.2,0.3-0.5,0.3-0.8l0,0c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.2-0.1-0.4-0.1-0.7-0.2
				c-0.3-0.1-0.7-0.1-1.2-0.2c-0.6,0-1.1-0.1-1.6-0.2c-0.5-0.1-0.9-0.2-1.2-0.4c-0.3-0.2-0.6-0.5-0.9-0.8c-0.2-0.3-0.4-0.8-0.5-1.3
				l0,0c-0.1-0.5-0.1-1,0.1-1.4c0.1-0.4,0.3-0.8,0.6-1.1c0.3-0.3,0.7-0.6,1.1-0.8c0.4-0.2,0.9-0.4,1.5-0.5c0.8-0.1,1.5-0.1,2.2,0
				s1.4,0.4,2,0.7l-1,2c-0.5-0.3-1-0.4-1.5-0.6c-0.5-0.1-1-0.1-1.4-0.1s-0.8,0.2-1,0.4c-0.2,0.2-0.3,0.5-0.2,0.7l0,0
				c0,0.2,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.2,0.1,0.4,0.1,0.8,0.2c0.3,0,0.7,0.1,1.2,0.1c0.6,0.1,1.1,0.1,1.5,0.3
				c0.5,0.1,0.8,0.3,1.2,0.5c0.3,0.2,0.6,0.5,0.8,0.8s0.3,0.7,0.4,1.2l0,0c0.1,0.5,0.1,1-0.1,1.5c-0.1,0.4-0.3,0.8-0.7,1.2
				c-0.3,0.3-0.7,0.6-1.1,0.8C79,123,78.4,123.2,77.8,123.3z"
            />
            <Path
              d="M89.1,121.6c-0.8,0.1-1.6,0.1-2.4-0.1c-0.7-0.2-1.4-0.5-2-0.9c-0.6-0.4-1.1-0.9-1.5-1.6c-0.4-0.6-0.6-1.3-0.8-2.1l0,0
				c-0.1-0.8-0.1-1.5,0.1-2.2c0.2-0.7,0.5-1.4,0.9-2c0.4-0.6,1-1.1,1.6-1.5c0.7-0.4,1.4-0.7,2.2-0.8c0.8-0.1,1.6-0.1,2.4,0.1
				c0.7,0.2,1.4,0.5,2,0.9c0.6,0.4,1.1,0.9,1.5,1.6c0.4,0.6,0.6,1.3,0.8,2.1l0,0c0.1,0.8,0.1,1.5-0.1,2.2c-0.2,0.7-0.5,1.4-0.9,2
				c-0.4,0.6-1,1.1-1.6,1.5C90.7,121.2,89.9,121.5,89.1,121.6z M88.8,119.4c0.5-0.1,0.9-0.2,1.3-0.5c0.4-0.2,0.7-0.5,0.9-0.9
				c0.2-0.4,0.4-0.7,0.5-1.2s0.1-0.9,0-1.3l0,0c-0.1-0.5-0.2-0.9-0.4-1.3c-0.2-0.4-0.5-0.7-0.8-1c-0.3-0.3-0.7-0.5-1.2-0.6
				c-0.4-0.1-0.9-0.1-1.4-0.1c-0.5,0.1-0.9,0.2-1.3,0.5c-0.4,0.2-0.7,0.5-0.9,0.9c-0.2,0.4-0.4,0.7-0.5,1.2c-0.1,0.4-0.1,0.9,0,1.3
				l0,0c0.1,0.5,0.2,0.9,0.4,1.3c0.2,0.4,0.5,0.7,0.8,1s0.7,0.5,1.1,0.6C87.9,119.4,88.3,119.5,88.8,119.4z"
            />
            <Path d="M95.3,109.4l2.4-0.4l1.3,8.7l5.4-0.8l0.3,2.1l-7.8,1.2L95.3,109.4z" />
            <Path
              d="M104.9,107.9l4.2-0.6c0.8-0.1,1.6-0.1,2.4,0.1c0.7,0.2,1.4,0.4,2,0.9s1.1,0.9,1.4,1.5c0.4,0.6,0.6,1.3,0.7,2.1l0,0
				c0.1,0.8,0.1,1.5-0.1,2.2c-0.2,0.7-0.5,1.3-0.9,1.9c-0.4,0.6-1,1-1.6,1.4c-0.7,0.4-1.4,0.6-2.3,0.8l-4.2,0.6L104.9,107.9z
				M110.4,115.9c0.5-0.1,0.9-0.2,1.3-0.4c0.4-0.2,0.7-0.5,0.9-0.8c0.2-0.3,0.4-0.7,0.5-1.1c0.1-0.4,0.1-0.9,0-1.3l0,0
				c-0.1-0.5-0.2-0.9-0.4-1.3c-0.2-0.4-0.5-0.7-0.8-0.9c-0.3-0.2-0.7-0.4-1.1-0.5c-0.4-0.1-0.9-0.1-1.4,0l-1.8,0.3l1,6.5
				L110.4,115.9z"
            />
          </G>
        </G>
        <Line className="st15" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="137.5" x2="137.5" y1="79.7" y2="95.1" />
        <Ellipse className="st18" cx="129.8" cy="82.2" rx="0.8" ry="0.8" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5px" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="31.1" x2="24.3" y1="51.2" y2="51.2" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="31.1" x2="28" y1="54.9" y2="54.9" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="45.2" x2="42.2" y1="52.2" y2="52.2" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="48.3" x2="43.9" y1="54" y2="54" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="55.8" x2="47" y1="52.2" y2="52.2" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="59.8" x2="57.5" y1="52.2" y2="52.2" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="140" x2="135.6" y1="54" y2="54" />
        <Line className="st16" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.55" x1="139" x2="130.1" y1="52.2" y2="52.2" />
        <Path
          className="st9"
          d="M175.6,39.3c1.9,0,2.9-2.2,1.7-3.7c-2.1-2.4-6.3-4.1-11.1-4.1c-0.8,0-1.6,0-2.3,0.1c-0.8,0.1-1.5-0.6-1.5-1.4
		l0,0c0-4.2-5.3-7.6-11.9-7.6c-1.8,0-3.6,0.3-5.1,0.7c-0.7,0.2-1.5-0.2-1.7-1c-1.4-6.3-8.1-11-16.2-11c-9.1,0-16.4,6-16.4,13.4
		c0,0.3,0,0.5,0,0.8c-0.4-0.1-0.8-0.1-1.2-0.1c-3.4,0-6.3,2.2-7.2,5.3c-0.2,0.7-0.9,1.1-1.6,0.9c-0.7-0.2-1.4-0.3-2.1-0.3
		c-2.4,0-4.6,1.1-6,2.9c-1.6,2-0.2,5.1,2.5,5.1H175.6z"
          fill="#E6EBEF"
        />
        <Path
          className="st9"
          d="M215.3,104.9c2.7,0,4.5-2.7,3.5-5.3c-2.1-5.3-6.7-12.9-15.3-13.8c-3.6-0.4-7,1.1-9.7,2.8
		c-0.2,0.2-0.5,0.1-0.8-0.1c-1.8-1.7-4.5-2.8-7.4-2.8c-5.5,0-10,3.8-10,8.4v0c0,0.4-0.3,0.6-0.7,0.6c-0.3,0-0.5,0-0.8,0
		c-3.4,0-6.3,1.9-7.3,4.4c-0.1,0.3-0.5,0.5-0.8,0.3c-0.9-0.4-1.9-0.6-3-0.6c-2.5,0.1-4.1,1.5-5.1,2.9c-0.9,1.3,0,3,1.5,3
		L215.3,104.9z"
          fill="#E6EBEF"
        />
        <Line className="st13" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.75px" x1="66.3" x2="63.1" y1="273.8" y2="273.8" />
        <Line className="st15" fill="#FFFFFF" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="0.5px" x1="56.6" x2="56.6" y1="86.1" y2="144.2" />
      </G>
    </Svg>
  );
}
