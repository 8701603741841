import React from 'react';
import T from 'prop-types';

import { P2 } from '../../../base_ui';
import { ValueWrapper } from './styledComponents';

const RowButtonValue = ({ value }) => (
  <ValueWrapper>
    <P2>{value}</P2>
  </ValueWrapper>
);

RowButtonValue.propTypes = { value: T.string.isRequired };

export default RowButtonValue;
