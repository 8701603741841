import styled from 'styled-components/native';

export const SecondaryCard = styled.View`
  background-color: ${({ theme: { colors: { dmiWhite } } }) => dmiWhite};
  border-color: ${({ theme: { colors: { primaryGray } } }) => primaryGray};
  border-radius: 8px;
  border-style: solid;
  border-width: 1px;
  margin: 0;
  padding: 0;
`;

export const PrimaryCard = styled(SecondaryCard)`
  elevation: 2;
  shadow-color: rgba(0, 0, 0, 0.1);
  shadow-offset: 0;
  shadow-opacity: 1;
  shadow-radius: 4px;
`;
