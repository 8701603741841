import React, { useCallback, useEffect } from 'react';
import T from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useFocusEffect } from '@react-navigation/native';
import { injectIntl } from 'react-intl';

import makeSelectMain, {
  getIsSso,
  getLoanAddresses,
  getMainClientInfo,
  getSsoType,
  selectClientMobileFeatures,
} from '@dmi/shared/redux/Main/selectors';
import { fetchPaperlessSettingAll } from '@dmi/shared/redux/Settings/Communications/actions';
import {
  selectRequestStatus as selectCommunicationsRequestStatus,
  selectSettingsPaperlessButtonDetails,
} from '@dmi/shared/redux/Settings/Communications/selectors';
import {
  fetchMailingAddress,
  fetchMailingAddressAll,
} from '@dmi/shared/redux/Settings/Loans/actions';
import loansReducer from '@dmi/shared/redux/Settings/Loans/reducer';
import loansSaga from '@dmi/shared/redux/Settings/Loans/saga';
import makeSelectLoans, {
  selectIsAnyLoanMancodeY,
  selectRequestStatus as selectLoansRequestStatus,
  selectPendingAddressesCount,
} from '@dmi/shared/redux/Settings/Loans/selectors';
import { setNestedScreen } from '@dmi/shared/redux/Mobile/actions';
import { selectNestedScreen } from '@dmi/shared/redux/Mobile/selectors';
import {
  fetchLegalNameMilitaryStatus,
  fetchProfile,
} from '@dmi/shared/redux/Settings/Profile/actions';
import profileReducer from '@dmi/shared/redux/Settings/Profile/reducer';
import profileSaga from '@dmi/shared/redux/Settings/Profile/saga';
import {
  selectMobileProfileProps,
  selectRequestStatus,
  selectSettingsHomeFetchError,
} from '@dmi/shared/redux/Settings/Profile/selectors';
import { selectSignInMethodButtonDetails } from '@dmi/shared/redux/Settings/Security/selectors';
import { selectSettingsScreenReaderNotifications }
from '@dmi/shared/redux/Settings/Shared/selectors';

import injectReducer from '../../utils/injectReducer';
import injectSaga from '../../utils/injectSaga';
import { getBaseApplicationServerUrl } from '../../utils/globalHelpers';
import { navigate } from '../../utils/rootNavigation';
import { StyledScrollView } from '../../components/base_ui';
import AsyncRender from '../../components/AsyncRender';
import ScreenReaderNotifications from '../../components/ScreenReaderNotifications';
import SettingsHomeView from '../../components/Settings/SettingsHome';

const SettingsHome = ({
  clientMobileFeatures,
  customerServicePhoneNumber,
  dispatchFetchLegalNameMilitaryStatus,
  dispatchFetchMailingAddress,
  dispatchFetchMailingAddressAll,
  dispatchFetchPaperlessSettingAll,
  dispatchFetchProfile,
  dispatchSetNestedScreen,
  fetchLegalNameMilitaryStatusStatus,
  fetchMailingAddressAllRequestStatus,
  fetchPaperlessStatus,
  fetchProfileStatus,
  isLoanMancodeY,
  isSslAuth,
  isSso,
  loanAddresses,
  loanToRefetchMailingAddress,
  navigation,
  nestedScreen,
  notifications,
  paperlessButtonDetails,
  pendingAddressesCount,
  profileProps,
  settingsHomeFetchError,
  signInMethodButtonDetails,
  ssoType,
}) => {
  /*
    Right now we're just fetching paperless data but we'll eventually need to
    fetch quite a bit of data for this screen. It may make sense to have a
    single action/saga that handles this fetch.
  */
  useEffect(() => {
    if (fetchPaperlessStatus === 'idle') {
      dispatchFetchPaperlessSettingAll();
    }
  }, [dispatchFetchPaperlessSettingAll, fetchPaperlessStatus]);

  // TODO: ensure mailing address(es) are refetched after user submits Change/Cancel request
  useEffect(() => {
    if (fetchMailingAddressAllRequestStatus === 'idle') {
      dispatchFetchMailingAddressAll();
    }
  }, [dispatchFetchMailingAddressAll, fetchMailingAddressAllRequestStatus]);

  useEffect(() => {
    if (loanToRefetchMailingAddress) {
      dispatchFetchMailingAddress(loanToRefetchMailingAddress);
    }
  }, [dispatchFetchMailingAddress, loanToRefetchMailingAddress]);

  useEffect(() => {
    if (fetchLegalNameMilitaryStatusStatus === 'idle') {
      dispatchFetchLegalNameMilitaryStatus();
    }
  }, [dispatchFetchLegalNameMilitaryStatus, fetchLegalNameMilitaryStatusStatus]);

  useEffect(() => {
    if (fetchProfileStatus === 'idle') {
      dispatchFetchProfile();
    }
  }, [dispatchFetchProfile, fetchProfileStatus]);

  useEffect(() => {
    if (nestedScreen) {
      navigation.navigate(nestedScreen);
    }
  }, [navigation, nestedScreen]);

  useFocusEffect(
    useCallback(() => () => {
      dispatchSetNestedScreen({ mainScreen: 'settings', nestedScreen: '' });
    }, [dispatchSetNestedScreen]),
  );

  return (
    <StyledScrollView>
      <ScreenReaderNotifications notifications={notifications} />
      <AsyncRender
        Component={SettingsHomeView}
        error={settingsHomeFetchError}
        loading={false}
        propsToPassDown={{
          clientMobileFeatures,
          customerServicePhoneNumber,
          fetchMailingAddressAllRequestStatus,
          isLoanMancodeY,
          isSslAuth,
          isSso,
          loanAddresses,
          navigation,
          paperlessButtonDetails,
          pendingAddressesCount,
          profileProps,
          signInMethodButtonDetails,
          ssoType,
        }}
      />
    </StyledScrollView>
  );
};

SettingsHome.propTypes = {
  clientMobileFeatures: T.object.isRequired,
  customerServicePhoneNumber: T.string.isRequired,
  dispatchFetchLegalNameMilitaryStatus: T.func.isRequired,
  dispatchFetchMailingAddress: T.func.isRequired,
  dispatchFetchMailingAddressAll: T.func.isRequired,
  dispatchFetchPaperlessSettingAll: T.func.isRequired,
  dispatchFetchProfile: T.func.isRequired,
  dispatchSetNestedScreen: T.func.isRequired,
  fetchLegalNameMilitaryStatusStatus: T.string.isRequired,
  fetchMailingAddressAllRequestStatus: T.string.isRequired,
  fetchPaperlessStatus: T.string.isRequired,
  fetchProfileStatus: T.string.isRequired,
  isLoanMancodeY: T.bool.isRequired,
  isSslAuth: T.bool.isRequired,
  isSso: T.bool.isRequired,
  loanAddresses: T.array.isRequired,
  loanToRefetchMailingAddress: T.string.isRequired,
  navigation: T.object.isRequired,
  nestedScreen: T.string,
  notifications: T.array.isRequired,
  paperlessButtonDetails: T.string.isRequired,
  pendingAddressesCount: T.number.isRequired,
  profileProps: T.shape({
    rowsData: T.arrayOf(T.shape({
      details: T.string.isRequired,
      isLoading: T.bool.isRequired,
      label: T.string.isRequired,
      pressProps: T.shape({
        isDisabled: T.bool.isRequired,
        screen: T.string.isRequired,
      }).isRequired,
    })).isRequired,
    username: T.string.isRequired,
  }).isRequired,
  settingsHomeFetchError: T.bool.isRequired,
  signInMethodButtonDetails: T.string.isRequired,
  ssoType: T.string.isRequired,
};

const mapStateToProps = createStructuredSelector({
  /**
   * Store: Communications
   */
  fetchPaperlessStatus: selectCommunicationsRequestStatus('fetchPaperlessSettingAll'),
  paperlessButtonDetails: selectSettingsPaperlessButtonDetails(),
  /**
   * Store: Loans
   */
  // eslint-disable-next-line sort-keys
  fetchMailingAddressAllRequestStatus: selectLoansRequestStatus('fetchMailingAddressAll'),
  isLoanMancodeY: selectIsAnyLoanMancodeY(),
  loanToRefetchMailingAddress: makeSelectLoans('loanToRefetchMailingAddress'),
  pendingAddressesCount: selectPendingAddressesCount(),
  /**
   * Store: Main
   */
  // eslint-disable-next-line sort-keys
  clientMobileFeatures: selectClientMobileFeatures(),
  customerServicePhoneNumber: getMainClientInfo('customerServicePhoneNumber'),
  isSslAuth: makeSelectMain('isSslAuth'),
  isSso: getIsSso(),
  loanAddresses: getLoanAddresses(),
  ssoType: getSsoType(),
  /**
   * Store: Mobile
   */
  // eslint-disable-next-line sort-keys
  nestedScreen: selectNestedScreen('settings'),
  /**
   * Store: Profile
   */
  // eslint-disable-next-line sort-keys
  fetchLegalNameMilitaryStatusStatus: selectRequestStatus('fetchLegalNameMilitaryStatus'),
  fetchProfileStatus: selectRequestStatus('fetchProfile'),
  profileProps: selectMobileProfileProps(),
  settingsHomeFetchError: selectSettingsHomeFetchError(),
  /**
   * Store: Security
   */
  signInMethodButtonDetails: selectSignInMethodButtonDetails(),
  /**
   * Store: Settings/Shared
   */
  // eslint-disable-next-line sort-keys
  notifications: selectSettingsScreenReaderNotifications(),
});

const mapDispatchToProps = (dispatch) => ({
  /**
   * Store: Communications
   */
  dispatchFetchPaperlessSettingAll: () => dispatch(fetchPaperlessSettingAll()),
  /**
   * Store : Loans
   */
  // eslint-disable-next-line sort-keys
  dispatchFetchMailingAddress: (payload) => dispatch(fetchMailingAddress(payload)),
  dispatchFetchMailingAddressAll: () => dispatch(fetchMailingAddressAll()),
  /**
   * Store: Mobile
   */
  dispatchSetNestedScreen: (payload) => dispatch(setNestedScreen(payload)),
  /**
   * Store : Profile
   */
  // eslint-disable-next-line sort-keys
  dispatchFetchLegalNameMilitaryStatus: () => dispatch(fetchLegalNameMilitaryStatus()),
  dispatchFetchProfile: () => dispatch(fetchProfile()),

});

const baseUrl = getBaseApplicationServerUrl();
const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withLoansReducer = injectReducer({ key: 'loans', reducer: loansReducer });
const withLoansSaga = injectSaga({
  key: 'loans',
  saga: loansSaga({ baseUrl, navigationHandler: navigate }),
});
const withProfileReducer = injectReducer({ key: 'profile', reducer: profileReducer });
const withProfileSaga = injectSaga({
  key: 'profile',
  saga: profileSaga({ baseUrl, navigationHandler: navigate }),
});

export default compose(
  injectIntl,
  withConnect,
  withLoansReducer,
  withLoansSaga,
  withProfileReducer,
  withProfileSaga,
)(SettingsHome);
