import React, { useEffect } from 'react';
import T from 'prop-types';

import Player from './Player'; // eslint-disable-line import/no-unresolved, import/extensions
import {
  ContentContainer,
  Heading,
  StyledExternalLink,
  StyledModalDialog,
} from './styledComponents';

const VideoModal = ({
  data: {
    heading,
    languages,
    videoUrl,
  },
  dispatchSetVideoRemainingDuration,
  handleCloseModal,
  videoRemainingDuration,
}) => {
  useEffect(() => () => {
    if (videoRemainingDuration) {
      dispatchSetVideoRemainingDuration(0);
    }
  });

  return (
    <StyledModalDialog handleCloseModal={handleCloseModal} visible>
      <ContentContainer>
        <Heading>{heading}</Heading>
        <Player
          dispatchSetVideoRemainingDuration={dispatchSetVideoRemainingDuration}
          languages={languages}
          videoRemainingDuration={videoRemainingDuration}
          videoUrl={videoUrl}
        />
        {languages.map(({ label, transcriptUrl }) => (
          <StyledExternalLink
            key={label}
            href={transcriptUrl}
            label={`Video Transcript (${label})`}
          />
        ))}
      </ContentContainer>
    </StyledModalDialog>
  );
};

VideoModal.propTypes = {
  data: T.shape({
    heading: T.string.isRequired,
    languages: T.arrayOf(T.shape({
      captionUrl: T.string.isRequired,
      label: T.string.isRequired,
      tag: T.string.isRequired,
      transcriptUrl: T.string.isRequired,
    })).isRequired,
    videoUrl: T.string.isRequired,
  }).isRequired,
  dispatchSetVideoRemainingDuration: T.func.isRequired,
  handleCloseModal: T.func.isRequired,
  videoRemainingDuration: T.number.isRequired,
};

export default VideoModal;
