import React from 'react';
import { View } from 'react-native';
import T from 'prop-types';

import { ADD_ESCROW_DICT } from '@dmi/shared/redux/Mortgage/constants';

import iconDictionary from '../../../../utils/iconDictionary';
import { moderateScale } from '../../../../utils/scaleHelpers';
import {
  AlertBanner,
  ConditionalRender,
  StyledScrollView,
} from '../../../base_ui';
import PrimaryDocPickerButton from '../../../DocUpload/PrimaryDocPickerButton';
import MultiDocHeader from './MultiDocHeader';
import {
  AddEscrowButtonWrapper,
  AddEscrowContainer,
  AddEscrowIllustrationWrapper,
  AlertWrapper,
  FlexView,
  StyledH2,
  StyledP2,
} from '../styledComponents';

const UploadHouse = iconDictionary('uploadHouse', { height: `${moderateScale(150)}px` });

const EscrowUploadDoc = ({
  addEscrowOption,
  chooseDocumentRequestStatus,
  dispatchChooseDocument,
  isFirstFileChosen,
  reviewScreenName,
  uploadIndex,
}) => {
  const { description, secondaryHeader } = ADD_ESCROW_DICT.docUpload[addEscrowOption];
  const needSecondDocUpload = addEscrowOption === 'Both' && isFirstFileChosen;
  const buttonLabelToUse = (uploadIndex > 0 && addEscrowOption === 'Both')
    ? 'Next Step' : 'Get Started';

  return (
    <StyledScrollView>
      <AddEscrowContainer $isDocView>
        <View>
          <AddEscrowIllustrationWrapper $isUpload>
            {UploadHouse}
          </AddEscrowIllustrationWrapper>
          <ConditionalRender
            Component={MultiDocHeader}
            propsToPassDown={{ uploadIndex }}
            shouldRender={addEscrowOption === 'Both'}
          />
          <StyledH2 $isUpload>
            {secondaryHeader[uploadIndex] ? secondaryHeader[uploadIndex] : ' '}
          </StyledH2>
          <StyledP2>
            {description}
          </StyledP2>
          <AlertWrapper $isUpload>
            <AlertBanner
              boldText="Formats accepted: "
              text="JPEG, JPG, PDF, PNG, TIF, TIFF, or GIF (Max size 10 MB)."
            />
          </AlertWrapper>
          <FlexView />
        </View>
        <AddEscrowButtonWrapper>
          <PrimaryDocPickerButton
            chooseDocumentRequestStatus={chooseDocumentRequestStatus}
            dispatchChooseDocument={dispatchChooseDocument}
            isSecondFile={needSecondDocUpload}
            label={buttonLabelToUse}
            reviewScreenName={reviewScreenName}
          />
        </AddEscrowButtonWrapper>
      </AddEscrowContainer>
    </StyledScrollView>
  );
};

EscrowUploadDoc.propTypes = {
  addEscrowOption: T.string.isRequired,
  chooseDocumentRequestStatus: T.string.isRequired,
  dispatchChooseDocument: T.func.isRequired,
  isFirstFileChosen: T.bool.isRequired,
  reviewScreenName: T.string.isRequired,
  uploadIndex: T.number.isRequired,
};

export default EscrowUploadDoc;
