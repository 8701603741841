import React from 'react';
import T from 'prop-types';
import { Text } from 'react-native';

const BaseHeading = ({ children, ...restProps }) => (
  <Text
    role="heading"
    {...restProps}
  >
    {children}
  </Text>
);

/* eslint-disable object-curly-newline */
BaseHeading.propTypes = {
  children: T.oneOfType([T.string, T.element]).isRequired,
};
/* eslint-disable object-curly-newline */

export default BaseHeading;
